import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISearchTransferLocalParams } from "core/http/apis/transfer-local/types";
import { logOutSuccess } from "modules/auth/redux";
// import { TRANSFER_LOCAL_TABS } from "./constants";
import { cloneDeep } from "lodash";
import {
  IfindParameterBankDetail,
  IGetAccountTransfer,
} from "core/http/apis/transfer-internal/types";
import {
  IParamGetDeposit,
  IParamsGetSubAccoTo,
  ITransferLocal,
  ITransferLocalResponse,
} from "./types";
import { EOTPTypeSubmit } from "helper/consts";

export interface ITransferLocalFilter {
  fromSubAccoNo?: string;
  toSubAccoNo?: string;
  status?: number;
  fromDate?: number;
  toDate?: number;
  typeTranfer?: string;
  page?: number;
  size?: number;
  rowPerPage?: number;
  subAccoNoFrom?: string;
}

export interface FormSubmit {
  [key: string]: any;

  alloDate?: number;
  refNo?: number;
  subAccoNo?: string | null;
  subAccoTo?: string | null;

  subAccoCd?: number | undefined;
  toSubAccoCd?: number | undefined;
  amount?: any;
  remarks?: string;
  password?: string | null;
  timeStamp?: string;
  mode?: number;
  otpCode?: string | null;
  srcChannel?: number;
  otpType: EOTPTypeSubmit;
}

export interface ReduxData {
  typeTranfer: string;
  currentPage: number;
  limit: number;
  enableInfinity: boolean;
  transferInternal: {
    loading: boolean;
    filter: {
      [key: string]: any;
      fromSubAccoNo?: string;
      toSubAccoNo?: string;
      status?: string;
      m_fromDate?: Date;
      m_toDate?: Date;
      typeTranfer?: string;
    };
    list: ITransferLocal[];
    detai: {
      loading: boolean;
      acountTransfer?: any;
      accountGet?: any;
      isVisible: boolean;
      form: FormSubmit;
      listAccoTo: any[];
    };
  };
  transferBank: {
    loading: boolean;
    filter: {
      fromSubAccoNo: string;
      toSubAccoNo: string;
      status: string;
      m_fromDate: Date;
      m_toDate: Date;
      typeTranfer: string;
    };
    list: ITransferLocal[];
    detai: {
      loading: boolean;
      acountTransfer?: any;
      accountGet?: any;
      isVisible: boolean;
      subAccoToList: { bankAccNumber: string }[];
      bankDetai: any;
    };
  };
  deposit: {
    data: any[];
  };
  withdrawal: {
    data: any[];
  };

  checkModal: {
    transferBank: boolean;
    transferInternal: boolean;
    depoist: boolean;
    withdrawal: boolean;
  };
}
const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

const initialState: ReduxData = {
  typeTranfer: "0",
  currentPage: 0,
  enableInfinity: true,
  limit: 30,
  transferInternal: {
    loading: false,
    filter: {
      fromSubAccoNo: "",
      toSubAccoNo: "",
      status: "",
      m_fromDate: firstDay,
      m_toDate: date,
      typeTranfer: "0",
    },
    list: [],
    detai: {
      loading: false,
      acountTransfer: {},
      accountGet: {},
      listAccoTo: [],

      isVisible: false,
      form: {
        alloDate: 0,
        refNo: 0,
        subAccoNo: "",
        subAccoTo: "",
        subAccoCd: 0,
        toSubAccoCd: 0,
        amount: 0,
        remarks: "",
        password: null,
        timeStamp: "",
        mode: 1,
        otpCode: "",
        srcChannel: 2,
        otpType: EOTPTypeSubmit.OTP,
      },
    },
  },
  transferBank: {
    loading: false,
    filter: {
      fromSubAccoNo: "",
      toSubAccoNo: "",
      status: "",
      m_fromDate: firstDay,
      m_toDate: date,
      typeTranfer: "0",
    },
    list: [],
    detai: {
      loading: false,
      acountTransfer: {},
      accountGet: {},
      isVisible: false,
      subAccoToList: [],
      bankDetai: {},
    },
  },
  deposit: {
    data: [],
  },
  withdrawal: {
    data: [],
  },
  checkModal: {
    transferBank: false,
    transferInternal: false,
    depoist: false,
    withdrawal: false,
  },
};

const transferLocalSlice = createSlice({
  name: "transferLocalSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    updateTypeTranfer: (state, action: PayloadAction<{ type: string }>) => {
      state.typeTranfer = action.payload.type;
    },
    updateTransferLocalFilter: (
      state,
      action: PayloadAction<{ key: any; value: any }>
    ) => {
      state.transferInternal.filter = {
        ...state.transferInternal.filter,
        [action.payload.key]: action.payload.value,
      };
    },

    searchTransferLocalRequest: (
      state,
      action: PayloadAction<ISearchTransferLocalParams>
    ) => {
      state.transferInternal.loading = true;
    },
    searchTransferLocalSuccess: (
      state,
      action: PayloadAction<ITransferLocalResponse>
    ) => {
      state.transferInternal.loading = false;
      state.transferInternal.list = action.payload.data ?? [];
      state.currentPage = 0;
      state.enableInfinity = true;
    },
    searchTransferLocalFailure: (state) => {
      state.transferInternal.loading = false;
    },

    getAccountTransfer: (state, action: PayloadAction<IGetAccountTransfer>) => {
      state.transferInternal.detai.loading = true;
    },
    getAccountTransferSuccess: (state, action: PayloadAction<any>) => {
      state.transferInternal.detai.loading = false;
      state.transferInternal.detai.acountTransfer = action.payload;
    },

    getAccoToLocalSuccessL: (state, action: PayloadAction<any>) => {
      state.transferInternal.detai.loading = false;
      state.transferInternal.detai.listAccoTo = action.payload;
    },

    getAccountTransferfail: (state) => {
      state.transferInternal.detai.loading = false;
    },

    getAccountGet: (state, action: PayloadAction<IGetAccountTransfer>) => {
      state.transferInternal.detai.loading = true;
    },
    getAccountGetSuccess: (state, action: PayloadAction<any>) => {
      state.transferInternal.detai.loading = false;
      state.transferInternal.detai.accountGet = action.payload;
    },
    getAccountGetfail: (state) => {
      state.transferInternal.detai.loading = false;
    },

    getAccToTransferInternal: (
      state,
      action: PayloadAction<{
        subAccCd?: number;
        toSubAccountNo?: string;
        checkContract?: number;
      }>
    ) => {},

    getAccToTransferInternalSuccess: (state, action: PayloadAction<any>) => {
      state.transferInternal.detai.accountGet = action.payload;
    },

    updateModalStatusRequest: (state, action: PayloadAction<FormSubmit>) => {
      state.transferInternal.detai.isVisible = true;
      state.transferInternal.detai.form = action.payload;
    },
    closeModalStatusRequest: (state) => {
      state.transferInternal.detai.isVisible = false;
    },

    addTransferInternal: (state, action: PayloadAction<FormSubmit>) => {},

    deletTransferInternal: (state, action: PayloadAction<FormSubmit>) => {
      state.transferInternal.loading = true;
    },
    deletTransferInternalSuccess: (state) => {
      state.transferInternal.loading = false;
    },
    deletTransferInternalFail: (state) => {
      state.transferInternal.loading = false;
    },

    deletTransferBank: (state, action: PayloadAction<FormSubmit>) => {
      state.transferInternal.loading = true;
    },
    deletTransferBankSuccess: (state) => {
      state.transferInternal.loading = false;
    },

    getListTransferBank: (
      state,
      action: PayloadAction<ITransferLocalFilter>
    ) => {
      state.transferInternal.loading = true;
    },
    getListTransferBankSuccess: (state, action) => {
      state.transferInternal.loading = false;
      state.transferBank.list = action.payload;
      state.currentPage = 0;
      state.enableInfinity = true;
    },
    getListTransferBankFail: (state, action) => {
      state.transferInternal.loading = false;
    },

    getlistSubAccoToList: (
      state,
      action: PayloadAction<{ subAccoNo: string }>
    ) => {},
    getlistSubAccoToListSuccess: (
      state,
      action: PayloadAction<{ bankAccNumber: string }[]>
    ) => {
      state.transferBank.detai.subAccoToList = action.payload;
    },
    getlistSubAccoToListFail: (state) => {},

    getSubAccoToList: (
      state,
      action: PayloadAction<{ subAccCd?: number; toSubAccountNo: string }>
    ) => {},
    getSubAccoToListSuccess: (
      state,
      action: PayloadAction<IParamsGetSubAccoTo>
    ) => {
      state.transferBank.detai.accountGet = action.payload;
    },
    getSubAccoToListFail: (state) => {},

    getSubAccoTransferBank: (
      state,
      action: PayloadAction<{ subAccoNo: string }>
    ) => {},
    getSubAccoTransferBankSuccess: (
      state,
      action: PayloadAction<IParamsGetSubAccoTo>
    ) => {
      state.transferBank.detai.acountTransfer = action.payload;
    },
    getSubAccoTransferBankFail: (state) => {},

    getBankDetail: (
      state,
      action: PayloadAction<IfindParameterBankDetail>
    ) => {},
    getBankDetailSuccess: (state, action: PayloadAction<any>) => {
      state.transferBank.detai.bankDetai = action.payload;
    },

    getDataDeposit: (state, action: PayloadAction<IParamGetDeposit>) => {
      state.transferInternal.loading = true;
    },
    getDataDepositSuccess: (state, action: PayloadAction<any>) => {
      state.transferInternal.loading = false;
      state.deposit.data = action.payload;
      state.currentPage = 0;
      state.enableInfinity = true;
    },
    getDatawithdrawalSuccess: (state, action: PayloadAction<any>) => {
      state.transferInternal.loading = false;
      state.withdrawal.data = action.payload;
      state.currentPage = 0;
      state.enableInfinity = true;
    },
    getDataDepositFail: (state) => {
      state.transferInternal.loading = false;
    },

    resetDetailTransferInternal: (state) => {
      state.transferInternal.detai.acountTransfer = {};
      state.transferInternal.detai.accountGet = {};
    },

    resetDetailTransferBank: (state) => {
      state.transferBank.detai.accountGet = {};
      state.transferBank.detai.acountTransfer = {};
    },

    editCheckModal: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      state.checkModal = {
        ...state.checkModal,
        [action.payload.key]: action.payload.value,
      };
    },
    updateCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    updateEnableInfinity: (state, action: PayloadAction<boolean>) => {
      state.enableInfinity = action.payload;
    },

    fetchModeTransferBank: (
      state,
      action: PayloadAction<ITransferLocalFilter>
    ) => {},

    fetchModeTransferBankSuccess: (state, action: PayloadAction<any>) => {
      const data = [...state.transferBank.list];
      state.transferBank.list = data.concat(action.payload);
    },

    fetchModeTransferInterbal: (
      state,
      action: PayloadAction<ITransferLocalFilter>
    ) => {},

    fetchModeTransferInterbalSuccess: (state, action: PayloadAction<any>) => {
      const data = [...state.transferInternal.list];
      state.transferInternal.list = data.concat(action.payload);
    },

    fetchModeTransferDepoist: (
      state,
      action: PayloadAction<IParamGetDeposit>
    ) => {},

    fetchModeTransferDepoistSuccess: (state, action: PayloadAction<any>) => {
      const data = [...state.deposit.data];
      state.deposit.data = data.concat(action.payload);
    },
    fetchModeTransferWithdrawalSuccess: (state, action: PayloadAction<any>) => {
      const data = [...state.withdrawal.data];
      state.withdrawal.data = data.concat(action.payload);
    },
  },
});

export const {
  reset,
  updateTransferLocalFilter,
  updateTypeTranfer,
  closeModalStatusRequest,

  searchTransferLocalRequest,
  searchTransferLocalSuccess,
  searchTransferLocalFailure,

  getAccountTransfer,
  getAccountTransferSuccess,
  getAccountTransferfail,

  getAccountGet,
  getAccountGetSuccess,
  getAccountGetfail,

  updateModalStatusRequest,
  addTransferInternal,

  deletTransferInternal,
  deletTransferInternalSuccess,
  deletTransferInternalFail,

  getListTransferBank,
  getListTransferBankSuccess,
  getListTransferBankFail,

  getlistSubAccoToList,
  getlistSubAccoToListSuccess,
  getlistSubAccoToListFail,

  getSubAccoToList,
  getSubAccoToListSuccess,
  getSubAccoToListFail,

  getSubAccoTransferBank,
  getSubAccoTransferBankSuccess,
  getSubAccoTransferBankFail,

  getAccoToLocalSuccessL,

  getBankDetail,
  getBankDetailSuccess,

  getAccToTransferInternal,
  getAccToTransferInternalSuccess,

  deletTransferBank,
  deletTransferBankSuccess,

  getDataDeposit,
  getDataDepositSuccess,
  getDatawithdrawalSuccess,
  getDataDepositFail,
  // searchTransferBankRequest,
  // searchTransferBankSuccess,
  // searchTransferBankFailure,

  // closeModal,
  resetDetailTransferBank,
  resetDetailTransferInternal,
  editCheckModal,

  updateCurrentPage,
  updateEnableInfinity,

  fetchModeTransferBank,
  fetchModeTransferBankSuccess,

  fetchModeTransferInterbal,
  fetchModeTransferInterbalSuccess,

  fetchModeTransferDepoist,
  fetchModeTransferDepoistSuccess,
  fetchModeTransferWithdrawalSuccess,
} = transferLocalSlice.actions;

export default transferLocalSlice.reducer;
