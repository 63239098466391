import styled from "styled-components/macro";

export const Wrap: any = styled.div<{ width?: string }>`
  display: flex;
  gap: 8px;
  height: 6%;
  width: ${({ width }) => (width ? width : "20%")};
  @media (max-width: 1360px) {
    height: 9%;
  }
`;

export const Label: any = styled.div`
  margin: auto;
  width: 30%;
`;

export const WrapSelect: any = styled.div`
  width: 70%;
`;
