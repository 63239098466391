import { WatchlistGroupType } from "helper/consts";
import { cloneDeep } from "lodash";
import { WATCH_LIST_FIXED_TABS } from "../constant";
import { ListType } from "../types";

export const getTickersList = (
  list: ListType[],
  currentListCode: string,
  selectedListedType: string,
  selectedIndustryType: string,
  selectedTypeDerivative: string,
  selectedOddLotType: string
) => {
  if (currentListCode === WATCH_LIST_FIXED_TABS.DERIVATIVE) {
    return list?.find((list) => list.name === selectedTypeDerivative);
  }
  if (currentListCode === WATCH_LIST_FIXED_TABS.COVER_WARRANT) {
    return list?.find((list) => list.groupType === WatchlistGroupType.CW);
  }
  if (currentListCode === WATCH_LIST_FIXED_TABS.LISTED) {
    return list.find((list) => list.code === selectedListedType);
  }
  if (currentListCode === WATCH_LIST_FIXED_TABS.INDUSTRY) {
    return list?.find((list) => list.code === selectedIndustryType);
  }
  if (currentListCode === WATCH_LIST_FIXED_TABS.ODDLOT) {
    return list?.find((list) => list.code === selectedOddLotType);
  }
  const listReverse = cloneDeep(list).reverse();
  console.log("listReverse", listReverse, list);
  return listReverse?.find((list) => list.name === currentListCode);
};
