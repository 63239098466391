import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { FormattedMessage } from "react-intl";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";

const sellforeignqtySelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.sellforeignqty
);

const SellforeignqtyCell = () => {
  const sellforeignqty = useSelector(sellforeignqtySelector);
  return (
    <div>
      <div className="info-text">
        <FormattedMessage id="widgets.tickerInfo.totalSellQty" />
      </div>
      <div className="info-value">
        <VolumeChange
          value={numeral(sellforeignqty).multiply(10).format("0,0")}
        />
      </div>
    </div>
  );
};

export default SellforeignqtyCell;
