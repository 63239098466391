import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const ContentContainer = styled.div`
  overflow-y: auto;
  background: ${({ theme }) => theme.background1};
  border-radius: 8px;
  height: 100%;
  table {
    margin-bottom: 0px;
  }
  border: 1px solid ${({ theme }) => theme.border2};
`;

export const Table = styled.div`
  height: 100%;
  overflow: auto;
  table {
    .header-widget-table {
      tr > th {
        box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
      }
    }
    .bodyWidgetTable {
      tr {
        :last-child {
          box-shadow: none;
        }
      }
    }
  }
`;

export const TableCellContent = styled.div`
  white-space: nowrap;
  height: 36px;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textcolorWhite};
  div {
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const HeaderCellChild = styled.div<{
  align?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  height: 100%;
`;

export const Icon = styled.button`
  background: transparent;
  padding: 0px;
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 4px;
    padding: 8px 12px;
    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
`;

export const TextNowrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
`;
