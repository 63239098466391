import styled from "styled-components/macro";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.grey100};
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  height: calc(100% - 86px);
  table {
    margin-bottom: 0px;
    tbody {
      overflow: hidden;
    }
  }
  .header-widget-table > tr {
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};

    & > th {
      background: ${({ theme }) => theme.bgElevated4};
    }
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
    overflow-y: hidden;
  }

  .infinite-scroll-component {
    overflow-y: hidden;
  }
`;

export const FooterTable = styled.div`
  height: 48px;
  background-color: ${({ theme }) => theme.background2};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`;
