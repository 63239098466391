import HttpManagerDevice from "core/http/singleton/manager-device";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IManagerDevice } from "./type";
import {
  deleteDeviceApi,
  getListDeviceApi,
  getSmsOtpUrl,
  verifyTwoPhaseAuthenUrl,
} from "./urls";

export const getListDevice = async (param: any): Promise<IManagerDevice> => {
  const res = await HttpManagerDevice.get<IBasePairValueResponse>(
    getListDeviceApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteDevice = async (param: any): Promise<IManagerDevice> => {
  const res = await HttpManagerDevice.post<IBasePairValueResponse>(
    deleteDeviceApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createSMSOTPApi = async (params: any): Promise<any> => {
  const res = await HttpManagerDevice.post<any>(
    getSmsOtpUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const verifyTwoPhaseAuthen = async (params: any): Promise<any> => {
  const res = await HttpManagerDevice.post<any>(
    verifyTwoPhaseAuthenUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
