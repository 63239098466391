import styled from "styled-components/macro";

export const Wrap = styled.div`
  border-radius: 8px;
  height: 100%;
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.background3};
  /* padding: 10px 16px; */
  height: ${({ theme }) => theme.cardHeader.height};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    /* padding: 0; */
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
    padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  }
`;
export const Body = styled.div`
  background: ${({ theme }) => theme.background1};
  height: calc(100% - ${({ theme }) => theme.cardHeader.height});
`;
