import styled from "styled-components/macro";

export const TickerCode = styled.div<{ isFontMin?: boolean }>`
  cursor: pointer;

  .is-bold {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;
