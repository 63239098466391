import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MarketDepthResponse } from "domain/protoNew/auto_trading_pb";

export type ChartData = {
  labels: string[];
  values: number[];
  colors: number[];
};

export type AdIndexState = {
  data: ChartData;
  totalDecline: number | undefined;
  totalNoChange: number | undefined;
  totalAdvances: number | undefined;
  totalTickers: number | undefined;
  loading: boolean;
  error: string | undefined;
};

const initialState: AdIndexState = {
  data: {
    values: [],
    colors: [],
    labels: [],
  },
  loading: false,
  error: undefined,
  totalDecline: 0,
  totalNoChange: 0,
  totalAdvances: 0,
  totalTickers: 0,
};

const adIndexSlice = createSlice({
  name: "adIndexSlice",
  initialState,
  reducers: {
    fetchMarketDepth: (state) => {
      state.loading = true;
    },
    fetchMarketDepthSuccess: (
      state,
      action: PayloadAction<MarketDepthResponse.AsObject | null>
    ) => {
      const { payload } = action;
      state.loading = false;
      if (!payload) return;
      state.totalDecline =
        payload.marketdepthinfo?.down01! +
        payload.marketdepthinfo?.down13! +
        payload.marketdepthinfo?.down35! +
        payload.marketdepthinfo?.down57! +
        payload.marketdepthinfo?.down7!;
      state.totalNoChange = payload.marketdepthinfo?.nochange;
      state.totalAdvances =
        payload.marketdepthinfo?.up01! +
        payload.marketdepthinfo?.up13! +
        payload.marketdepthinfo?.up35! +
        payload.marketdepthinfo?.up57! +
        payload.marketdepthinfo?.up7!;
      state.totalTickers =
        payload.marketdepthinfo?.down7! +
        payload.marketdepthinfo?.down57! +
        payload.marketdepthinfo?.down35! +
        payload.marketdepthinfo?.down13! +
        payload.marketdepthinfo?.down01! +
        payload.marketdepthinfo?.nochange! +
        payload.marketdepthinfo?.up01! +
        payload.marketdepthinfo?.up13! +
        payload.marketdepthinfo?.up35! +
        payload.marketdepthinfo?.up57! +
        payload.marketdepthinfo?.up7!;
      state.data.labels = [
        "<=-7%",
        "-7~-5%",
        "-5~-3%",
        "-3~-1%",
        "-1~0%",
        "0%",
        "0~1%",
        "1~3%",
        "3~5%",
        "5~7%",
        ">=7%",
      ];

      state.data.values = [
        payload.marketdepthinfo?.down7!,
        payload.marketdepthinfo?.down57!,
        payload.marketdepthinfo?.down35!,
        payload.marketdepthinfo?.down13!,
        payload.marketdepthinfo?.down01!,
        payload.marketdepthinfo?.nochange!,
        payload.marketdepthinfo?.up01!,
        payload.marketdepthinfo?.up13!,
        payload.marketdepthinfo?.up35!,
        payload.marketdepthinfo?.up57!,
        payload.marketdepthinfo?.up7!,
      ];
      state.data.colors = [-1, -1, -1, -1, -1, 0, 1, 1, 1, 1, 1];
    },
    fetchMarketDepthFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMarketDepth,
  fetchMarketDepthSuccess,
  fetchMarketDepthFailure,
} = adIndexSlice.actions;

export default adIndexSlice.reducer;
