import { useState } from "react";
import { useIntl } from "react-intl";
import { SERVICE } from "../constants";
import { Tab, Wrap } from "./styles";

type Props = {
  onClick?: (values: any) => void;
};

const Tabs = (props: Props) => {
  const [active, setActive] = useState(SERVICE.SMS);
  const { onClick } = props;

  const intl = useIntl();

  const handleChangeTab1 = () => {
    if (onClick) {
      onClick(SERVICE.SMS);
    }
    setActive(SERVICE.SMS);
  };
  const handleChangeTab = () => {
    if (onClick) {
      onClick(SERVICE.EMAIL);
    }
    setActive(SERVICE.EMAIL);
  };
  return (
    <Wrap>
      <div className="header-left">
        <Tab isActive={active === SERVICE.SMS} onClick={handleChangeTab1}>
          {intl.formatMessage({
            id: "auth.seviceEmailSms.titleSms",
          })}
        </Tab>
        <Tab isActive={active === SERVICE.EMAIL} onClick={handleChangeTab}>
          {intl.formatMessage({
            id: "auth.seviceEmailSms.titleEmail",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
