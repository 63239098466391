import CSelect from "components/commons/Select";
import {
  buyInfor,
  calInvestmentValue,
  findCbotBondInfoRequest,
  updateBondCdInfo,
  updateListPeriod,
} from "modules/place-order-retail-bonds/redux";
import {
  getBondCdListFullSelector,
  getListOrderBookBondCdInfoSelector,
  getListOrderBookBondCdSelector,
} from "modules/place-order-retail-bonds/selectors";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { LabelCell } from "../InfomationOrder/ContainerTop/styles";
import { WrapperListBond } from "../InfomationOrder/styles";
import {
  Body,
  BodyContainer,
  ButtonBuy,
  ColorGray,
  ColorGreen,
  ColorRed,
  ColorYellow,
  ContainerTable,
  Header,
  Width120,
  Width140,
  Width160,
  Width200,
  Width268,
} from "./styles";

const OtherItem = ({ period, bondCd, mergeData }: any) => {
  const dispatch = useDispatch();
  const listBondsFull = useSelector(getBondCdListFullSelector);

  const renderOtherItem = mergeData?.find(
    (item: any) => item?.bondCd === bondCd && item?.period === period?.value
  );

  const handleClickBuy = (item: any) => {
    const findPeriod = listBondsFull?.filter(
      (v: any) => item.bondCd === v?.bondCd
    );

    const periodItem = findPeriod?.map((item: any) => {
      return { label: item?.period, value: item?.period };
    });
    const bondCd = { label: item?.bondCd, value: item?.bondCd };
    const period = { label: item?.period, value: item?.period };
    dispatch(
      buyInfor({
        key: "bondCd",
        value: bondCd,
      })
    );
    dispatch(
      buyInfor({
        key: "period",
        value: period ? period : "",
      })
    );
    dispatch(
      buyInfor({
        key: "qty",
        value: "",
      })
    );
    dispatch(
      findCbotBondInfoRequest({
        bondCd: item?.bondCd,
      })
    );
    dispatch(updateListPeriod({ listPeriod: periodItem }));
    dispatch(updateBondCdInfo({ bondInfo: {} }));
    dispatch(
      calInvestmentValue({
        calInvestmentValue: "",
      })
    );
  };

  return (
    <>
      <Width268 background="none" color="#FFFFFF" height="43px">
        {Math.round(renderOtherItem?.interestRate * 100 * 100) / 100}
      </Width268>
      <Width268 background="none" color="#FFFFFF" height="43px">
        {renderOtherItem?.remainQty}
      </Width268>
      <Width268 background="none" color="#FFFFFF" height="43px">
        {renderOtherItem?.qty}
      </Width268>
      <Width160 background="none" color="#FFFFFF" height="43px">
        {renderOtherItem?.status === 0 ? (
          <ColorYellow>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.delay" />
          </ColorYellow>
        ) : renderOtherItem?.status === 1 ? (
          <ColorGreen>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.accept" />
          </ColorGreen>
        ) : renderOtherItem?.status === 2 ? (
          <ColorRed>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.deny" />
          </ColorRed>
        ) : (
          <ColorGray>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.canceled" />
          </ColorGray>
        )}
      </Width160>
      <Width200 background="none" color="#FFFFFF" height="43px">
        <ButtonBuy onClick={() => handleClickBuy(renderOtherItem)}>
          <FormattedMessage id="placeOrderRetailBonds.infomationOrder.buy" />
        </ButtonBuy>
      </Width200>
    </>
  );
};
const LoadPeriod = ({ mergeData, item }: any) => {
  const [period, setPeriod] = useState<any>();
  const findPeriod = mergeData?.filter((v: any) => v?.bondCd === item?.bondCd);
  const newPeriod = findPeriod?.map((item: any) => {
    return { label: item?.period, value: item?.period };
  });

  const handleChangePeriod = (e: any) => {
    setPeriod(e);
  };

  useEffect(() => {
    setPeriod(newPeriod?.[0]);
  }, []);

  return (
    <>
      <Width140 background="none" color="#FFFFFF" height="43px">
        <CSelect
          options={newPeriod ? newPeriod : []}
          value={period}
          onChange={handleChangePeriod}
        ></CSelect>
      </Width140>
      <OtherItem period={period} bondCd={item?.bondCd} mergeData={mergeData} />
    </>
  );
};

function ListBonds() {
  const intl = useIntl();

  const listOrderBookBondCd = useSelector(getListOrderBookBondCdSelector);
  const listOrderBookBondCdInfo = useSelector(
    getListOrderBookBondCdInfoSelector
  );

  const listBondsFull = useSelector(getBondCdListFullSelector);

  const [newListBondCd, setNewBondList] = useState<any>();

  const mergeData = listOrderBookBondCd?.reduce((totalData: any, item: any) => {
    let newItem = { ...item };
    const itemHas = listOrderBookBondCdInfo?.find(
      (v: any) => v.bondCd === item?.bondCd && v.period === item?.period
    );

    if (itemHas) {
      newItem = { ...newItem, ...itemHas };
    }
    totalData.push(newItem);
    return totalData;
  }, []);

  useEffect(() => {
    if (listBondsFull) {
      const mapListBondCd = listBondsFull?.filter(
        (a: any, i: any) =>
          listBondsFull?.findIndex((s: any) => a.bondCd === s.bondCd) === i
      );
      setNewBondList(mapListBondCd);
    }
  }, [listBondsFull]);

  return (
    <WrapperListBond>
      <LabelCell>
        <FormattedMessage id="placeOrderRetailBonds.listBonds.title" />
      </LabelCell>
      <ContainerTable>
        <Header>
          <Width120>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.bondCd",
            })}
          </Width120>
          <Width140>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.period",
            })}
          </Width140>
          <Width268>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.commitedRate",
            })}
          </Width268>
          <Width268>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.remainingQuantity",
            })}
          </Width268>
          <Width268>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.quantityMin",
            })}
          </Width268>
          <Width160>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.status",
            })}
          </Width160>
          <Width200>
            {intl.formatMessage({
              id: "placeOrderRetailBonds.listBonds.action",
            })}
          </Width200>
        </Header>
        <Body>
          {newListBondCd?.map((item: any) => {
            return (
              <BodyContainer key={item?.id}>
                <Width120 background="none" color="#FFFFFF" height="43px">
                  {item?.bondCd}
                </Width120>
                <LoadPeriod mergeData={mergeData} item={item} />
              </BodyContainer>
            );
          })}
        </Body>
      </ContainerTable>
    </WrapperListBond>
  );
}

export default ListBonds;
