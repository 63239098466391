import { CSSProperties, memo, useEffect } from "react";
import { BodyRowWrapper, TableCellContent, CellValue } from "./styles";
import { COLUMNS_VALUE_BUY, FLEX } from "./constants";
import {
  ChangePriceAmountCell,
  ChangePricePercentageCell,
  PriceCell,
  StockNameCell,
  TopValueCell,
  TickerCell,
} from "./Cells";
import { useDispatch, useSelector } from "react-redux";
import { marketTopForeignSelector } from "modules/market/redux/selectors";
import {
  requestLoadMore,
  TopForeignState,
} from "modules/market/redux/topForeign";
import { createSelector } from "@reduxjs/toolkit";
export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
}

export interface Props {
  style: CSSProperties;
  ticker: string;
  rowInd: number;
  isScrolling?: boolean;
}

const currentTotalSelector = createSelector(
  marketTopForeignSelector,
  (topForeignSelector: TopForeignState) => topForeignSelector.tickerList.length
);

function TableBodyRow({ style, ticker, rowInd }: Props) {
  const dispatch = useDispatch();
  const currentTotal = useSelector(currentTotalSelector);
  useEffect(() => {
    if (rowInd && rowInd + 5 === currentTotal) {
      dispatch(requestLoadMore());
    }
  }, [rowInd]);

  return (
    <BodyRowWrapper style={style} bgColor={rowInd + 1 || 1}>
      <TableCellContent
        className="market-ticker-name"
        minWidth={COLUMNS_VALUE_BUY?.[0]?.width}
        align={
          COLUMNS_VALUE_BUY[0].position
            ? FLEX[COLUMNS_VALUE_BUY?.[0]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <TickerCell ticker={ticker} />
        <StockNameCell ticker={ticker} />
      </TableCellContent>

      <CellValue
        minWidth={COLUMNS_VALUE_BUY?.[1]?.width}
        align={
          COLUMNS_VALUE_BUY?.[1]?.position
            ? FLEX[COLUMNS_VALUE_BUY?.[1]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <TopValueCell ticker={ticker} />
      </CellValue>

      <CellValue
        minWidth={COLUMNS_VALUE_BUY?.[2]?.width}
        align={
          COLUMNS_VALUE_BUY?.[2]?.position
            ? FLEX[COLUMNS_VALUE_BUY?.[2]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <PriceCell ticker={ticker} />
      </CellValue>

      <CellValue
        minWidth={COLUMNS_VALUE_BUY?.[3]?.width}
        align={
          COLUMNS_VALUE_BUY?.[3]?.position
            ? FLEX[COLUMNS_VALUE_BUY?.[3]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <ChangePriceAmountCell ticker={ticker} />
      </CellValue>

      <CellValue
        minWidth={COLUMNS_VALUE_BUY?.[4]?.width}
        align={
          COLUMNS_VALUE_BUY?.[4]?.position
            ? FLEX[COLUMNS_VALUE_BUY?.[4]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <ChangePricePercentageCell ticker={ticker} />
      </CellValue>
    </BodyRowWrapper>
  );
}

export default memo(TableBodyRow) as React.FC<Props>;
