/* eslint-disable react-hooks/exhaustive-deps */
import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  WrapInput,
  WrapLeft,
  WrapRight,
  WrapTextFirst,
  TextLabel,
  TextNote,
} from "./style";
import { Button, Select, Input } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import { updateStep, updateParams } from "modules/auth/redux/modalRegister";
import CheckBox from "./CheckboxTransfer";
import { useCallback, useMemo, useState } from "react";
import { selectDataCreateUser } from "modules/auth/selectorsModalRegister";
import {
  checkCustNoExistApi,
  getListCortCustomerRecommend2Api,
  getListCortCustomerRecommendApi,
} from "core/http/apis/modal-register";
import { toast } from "components/commons/Toast";
import { selectParams } from "modules/auth/selectorsModalRegister";
import moment from "moment";
const Step5 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataCreate = useSelector(selectDataCreateUser);
  const paramsForm = useSelector(selectParams);

  const [checkValue, setcheckValue] = useState("1");
  const [optionHelp, setOptionHelp] = useState([]);

  const conpanyCode = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "COMPANY_CODE"
      )
    : {};

  const handleHelpNumber = async (value: any) => {
    // setErrors(false);
    setcheckValue(value);
    if (conpanyCode?.value === "045") {
      const res: any = await getListCortCustomerRecommend2Api({
        transactionCd: paramsForm?.transactionCd,
        phoneNo: paramsForm?.mobileNo,
        issueNo: paramsForm?.idNumber,
        birthNo: moment(paramsForm?.issueDate, "DD/MM/YYYY").format("YYYYMMDD"),
      });
      if (res?.statusCode === 0) {
        setOptionHelp(res?.data ? res?.data : []);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } else {
      const phone =
        conpanyCode?.value +
        paramsForm?.mobileNo.substr(paramsForm?.mobileNo.length - 6);
      const idnum =
        conpanyCode?.value +
        paramsForm?.idNumber.substr(paramsForm?.idNumber.length - 6);
      const res: any = await getListCortCustomerRecommendApi({
        transactionCd: paramsForm?.transactionCd,
        phoneNo: phone,
        issueNo: idnum,
        birthNo: moment(paramsForm?.issueDate, "DD/MM/YYYY").format("YYYYMMDD"),
      });
      if (res?.statusCode === 0) {
        setOptionHelp(res?.data ? res?.data : []);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    }
  };

  const [errors, setErrors] = useState<boolean>(false);

  const checkNumber = async (value: any) => {
    if (value !== "" && checkValue === "3") {
      const partern = `${conpanyCode?.value}C000000`;
      const firstText = "000000";
      const values = partern
        .slice(0, partern.length - value.length)
        .concat(value);
      const valueInput = firstText
        .slice(0, firstText.length - value.length)
        .concat(value);
      setNumberChange(valueInput);
      const res: any = await checkCustNoExistApi({
        custNo: values,
        transactionCd: paramsForm?.transactionCd,
      });
      if (res?.statusCode === 0) {
        if (res?.data) {
          setErrors(false);
        } else {
          setErrors(true);
          toast("auth.register.step6.checkCode", "error");
        }
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    }
  };

  const [numberHelp, setnumberHelp] = useState<string>("");
  const handleClickNumber = (e: any) => {
    setnumberHelp(e?.custNo);
  };

  const valueOption = useMemo(() => {
    return optionHelp.filter((e: any) => e.custNo === numberHelp);
  }, [numberHelp]);

  const [numberChange, setNumberChange] = useState<string>("");
  const changeNumber = (e: any) => {
    const text = e?.target?.value;
    if (text) {
      if (text.length <= 6) {
        const isNum = /^\d+$/.test(e?.target?.value);
        if (isNum) {
          setNumberChange(e?.target?.value);
        }
      }
    } else {
      setNumberChange("");
    }
  };

  const clearform = () => {
    setnumberHelp("");
    setNumberChange("");
  };

  const checkError = useCallback(() => {
    if (checkValue === "") {
      return false;
    }
    if (checkValue === "2" && numberHelp === "") {
      return false;
    }
    if (checkValue === "3" && (numberChange === "" || errors !== false)) {
      return false;
    }
    return true;
  }, [checkValue, numberHelp, numberChange]);

  const submitFormNext = () => {
    let value = null;
    if (checkValue === "2") {
      value = numberHelp;
    }
    if (checkValue === "3") {
      value = `${conpanyCode?.value ? conpanyCode?.value : ""}C${numberChange}`;
    }
    dispatch(
      updateParams({
        custNo: value,
      })
    );
    dispatch(updateStep(STEPS.STEPS_8));
  };

  return (
    <Container>
      <Wrap>
        <TextLabel>
          <FormattedMessage id={"auth.register.step6.label"} />
        </TextLabel>
        <WrapInput>
          <WrapLeft>
            <CheckBox
              values={"1"}
              onClick={(e: any) => {
                // setErrors(false);
                clearform();
                setcheckValue(e);
              }}
              checked={checkValue === "1"}
            />
            <FormattedMessage id={"auth.register.step6.question1.label"} />
          </WrapLeft>
          <WrapRight></WrapRight>
        </WrapInput>

        <WrapInput>
          <WrapLeft>
            <CheckBox
              values={"2"}
              onClick={(e: any) => handleHelpNumber(e)}
              checked={checkValue === "2"}
            />
            <FormattedMessage id={"auth.register.step6.question2.label"} />
          </WrapLeft>
          <WrapRight>
            <Select
              options={optionHelp}
              getValue={"custNo"}
              getLabel={"custNo"}
              isDisabled={checkValue === "2" ? false : true}
              onChange={handleClickNumber}
              value={valueOption}
            />
          </WrapRight>
        </WrapInput>

        <WrapInput>
          <WrapLeft>
            <CheckBox
              values={"3"}
              onClick={(e: any) => {
                setnumberHelp("");
                setcheckValue(e);
              }}
              checked={checkValue === "3"}
            />
            <FormattedMessage id={"auth.register.step6.question3.label"} />
          </WrapLeft>
          <WrapRight>
            <WrapTextFirst>{conpanyCode?.value}C</WrapTextFirst>
            <Input
              onBlur={(e: any) => checkNumber(e?.target?.value)}
              onChange={changeNumber}
              value={numberChange}
              disabled={checkValue === "3" ? false : true}
            />
          </WrapRight>
        </WrapInput>
        <TextNote>
          <FormattedMessage id={"auth.register.step6.note"} />
        </TextNote>
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_6))}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        {checkError() && (
          <Button
            color="gradientaAccept"
            className="button-filter"
            width="120px"
            onClick={() => submitFormNext()}
          >
            {intl.formatMessage({
              id: "auth.register.btnNext",
            })}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Step5;
