import { MRG_CONTRACT_DEBIT_TABS } from "modules/mrg-contract-debit/constants";
import { activeTabSelector } from "modules/mrg-contract-debit/selectors";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";

const Tabs: FC = () => {
  const intl = useIntl();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab
          isActive={activeTab === MRG_CONTRACT_DEBIT_TABS.MRG_CONTRACT_DEBIT}
        >
          {intl.formatMessage({
            id: "mrgContractDebit.tabs.mrgContractDebitTab",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
