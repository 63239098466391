import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  loadingSearch: boolean;
  loadingCheckTime: boolean;
  account: object | any;
  subAccoNo: any;
  bondList: any;
  bonListFull: any;
  bondsAvailSell: any;
  listTransferBond: any;
  calculateBondFee: number;
  statusCodeCheck: number;
  statusCode: number;
}

const initialState: ReduxData = {
  loadingSearch: false,
  loadingCheckTime: false,
  account: {},
  subAccoNo: {},
  bondList: {},
  bonListFull: {},
  bondsAvailSell: {},
  listTransferBond: [],
  calculateBondFee: 0,
  statusCodeCheck: -1,
  statusCode: -1,
};

const placeOrderTransferBondsSlice = createSlice({
  name: "placeOrderTransferBondsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    //lấy thông tin tài khoản
    getAccountRequest: (state, action: PayloadAction<any>) => {},
    getAccountSuccess: (state, action: PayloadAction<any>) => {
      state.account = action.payload.data;
    },
    getAccountFailure: (state) => {},

    //lấy thông tin tiểu khoản
    getSubAccoNoRequest: (state, action: PayloadAction<any>) => {},
    getSubAccoNoSuccess: (state, action: PayloadAction<any>) => {
      state.subAccoNo = action.payload.data.currentCustomer;
    },
    getSubAccoNoFailure: (state) => {},

    //Khối lượng có thể chuyển
    getBondsAvailSellRequest: (state, action: PayloadAction<any>) => {},
    getBondsAvailSellSuccess: (state, action: PayloadAction<any>) => {
      state.bondsAvailSell = action.payload.data?.[0];
    },
    getBondsAvailSellFailure: (state) => {},

    //lấy danh sách chuyển nhượng trái phiếu
    getListTransferBondRequest: (state, action: PayloadAction<any>) => {},
    getListTransferBondSuccess: (state, action: PayloadAction<any>) => {
      state.listTransferBond = action.payload.data;
    },
    getListTransferBondFailure: (state) => {},

    //tìm chuyển nhượng trái phiếu
    searchTransferBondRequest: (state, action: PayloadAction<any>) => {
      state.loadingSearch = true;
    },
    searchTransferBondSuccess: (state, action: PayloadAction<any>) => {
      state.loadingSearch = false;
      state.listTransferBond = action.payload.data;
    },
    searchTransferBondFailure: (state) => {
      state.loadingSearch = false;
    },

    //tính phí
    calculateBondFeeRequest: (state, action: PayloadAction<any>) => {},
    calculateBondFeeSuccess: (state, action: PayloadAction<any>) => {
      state.calculateBondFee = action.payload.data;
    },
    calculateBondFeeFailure: (state) => {},

    //check execute Time
    checkTimeExecuteRequest: (state, action: PayloadAction<any>) => {
      state.loadingCheckTime = true;
    },
    checkTimeExecuteSuccess: (state, action: PayloadAction<any>) => {
      state.statusCode = action.payload.statusCode;
      state.loadingCheckTime = false;
    },
    checkTimeExecuteFailure: (state) => {
      state.loadingCheckTime = false;
    },

    updateBondFee: (state, action: PayloadAction<any>) => {
      state.calculateBondFee = action.payload;
    },

    updateStatusCode: (state, action: PayloadAction<any>) => {
      state.statusCode = action.payload.statusCode;
    },
  },
});

export const {
  getAccountRequest,
  getAccountSuccess,
  getAccountFailure,

  getSubAccoNoRequest,
  getSubAccoNoSuccess,
  getSubAccoNoFailure,

  getBondsAvailSellRequest,
  getBondsAvailSellSuccess,
  getBondsAvailSellFailure,

  getListTransferBondRequest,
  getListTransferBondSuccess,
  getListTransferBondFailure,

  searchTransferBondRequest,
  searchTransferBondSuccess,
  searchTransferBondFailure,

  calculateBondFeeRequest,
  calculateBondFeeSuccess,
  calculateBondFeeFailure,

  checkTimeExecuteRequest,
  checkTimeExecuteSuccess,
  checkTimeExecuteFailure,

  updateBondFee,
  updateStatusCode,
} = placeOrderTransferBondsSlice.actions;

export default placeOrderTransferBondsSlice.reducer;
