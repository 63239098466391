import { FC } from "react";

import BidAskBondContent from "./components/BidAskBondContent/index";

import { Container } from "./styles";

const BidAskBond: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <BidAskBondContent />
    </Container>
  );
};

export default BidAskBond;
