import { FC } from "react";
import { PriceCell as PriceCellWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { TopStatisticalState } from "modules/market/redux/topStatistical";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { marketTopStatisticalSelector } from "modules/market/redux/selectors";

const priceSelector = (tickerName: string) => {
  return createSelector(
    marketTopStatisticalSelector,
    (topStatisticalState: TopStatisticalState) =>
      topStatisticalState?.data?.[tickerName]?.closeprice
  );
};

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopStatisticalSelector,
    (topStatisticalState: TopStatisticalState) =>
      topStatisticalState?.data?.[tickerName]?.colorcode
  );
};

interface Props {
  dataRow: string;
}

const PriceCell: FC<Props> = ({ dataRow }) => {
  const price = useSelector(priceSelector(dataRow));
  const textColor = useSelector(textColorSelector(dataRow));

  return (
    <PriceCellWrapper textcolor={textColor}>
      <BlinkableCell value={numeral(price).format("0,0.00")} />
    </PriceCellWrapper>
  );
};

export { PriceCell };
