import queryString from "query-string";
import { IFindArticleCategorysParams, IFindArticlesParams } from "./types";

export const findArticleCategorysUrl = (
  params: IFindArticleCategorysParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findArticleCategorys${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findArticlesUrl = (params: IFindArticlesParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findArticles${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
