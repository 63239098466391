import queryString from "query-string";
import {
  IIsRegisterPayAdvanceParams,
  // ICancelPayAdvanceAutoParams,
} from "./types";

export const isRegisterPayAdvanceAutoApi = (
  params: IIsRegisterPayAdvanceParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/isRegisterPayAdvanceAuto${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const registerPayAdvanceAutoApi = "/registerPayAdvanceAuto";
// export const cancelPayAdvanceAutoApi = "/cancelPayAdvanceAuto";
export const cancelPayAdvanceAutoApi = () =>
  // params: ICancelPayAdvanceAutoParams
  {
    // const paramsFormatter = queryString.stringify(params, {
    //   skipNull: true,
    // });
    // return `/cancelPayAdvanceAuto${paramsFormatter ? `?${paramsFormatter}` : ""}`;

    return `/cancelPayAdvanceAuto`;
  };
