import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const assetsSelector = (state: RootState) => state.assets;

export const transferLocalFilterSelector = createSelector(
  assetsSelector,
  (state) => state
);

export const selectTab = createSelector(assetsSelector, (state) => state.tab);

export const selectTabIncurred = createSelector(
  assetsSelector,
  (state) => state.assetsIncurred.tab
);

export const selectAssetsInformation = createSelector(
  assetsSelector,
  (state) => state.assets.information
);

export const selectAssetsTable = createSelector(
  assetsSelector,
  (state) => state.assets.table
);

export const selectSubAcco = createSelector(
  assetsSelector,
  (state) => state.subAccoNo
);

export const selectFilteAssetsEffect = createSelector(
  assetsSelector,
  (state) => state.assetsEffect
);
