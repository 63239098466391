import { createSelector } from "@reduxjs/toolkit";
import { WatchlistGroupType } from "helper/consts";
import { getColorPrice } from "helper/utils";
import {
  categoriesInfoSelector,
  floorpriceSelector,
  ceilingpriceSelector,
  basicpriceSelector,
  lastpriceSelector,
  tickersListSelectedSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
}

const lastqtySelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.lastqty
  );
};

function LastqtyCell({ ticker }: Props) {
  const lastqty = useSelector(lastqtySelector(ticker)) || 0;
  const floorprice = useSelector(floorpriceSelector(ticker)) || 0;
  const ceilingprice = useSelector(ceilingpriceSelector(ticker)) || 0;
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;
  const lastprice = useSelector(lastpriceSelector(ticker)) || 0;
  const { type } = useSelector(tickersListSelectedSelector);
  return (
    <VolumeCell
      className="bg-overlay-col last-sale-amount"
      keepRawValue={type === WatchlistGroupType.ODDLOT}
      volume={lastqty}
      color={getColorPrice(lastprice, floorprice, ceilingprice, basicprice)}
    />
  );
}

export default LastqtyCell;
