import { useMemo } from "react";

import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateModalStatusRequest } from "modules/order-history/redux";
import {
  isShowConfirmModalSelector,
  listOrderDetailModalSelector,
} from "modules/order-history/selectors";

import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { requestCondModel } from "domain/models/OrderHistory";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  Container,
  HeaderCell,
  TableCellContent,
} from "./styles";

import { convertNumberToDateFormat } from "helper/utils";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
// function getColor2(value: string, value2: number) {
//   if (value2 === 1 || value2 === 6 || value2 === 7 || value2 === 8) {
//     return <TextCell color="#F04B41">{value}</TextCell>;
//   } else if (value2 === 4 || value2 === 5) {
//     return <TextCell color="#1DBB75">{value}</TextCell>;
//   } else if (value2 === 2 || value2 === 3) {
//     return <TextCell color="#EDE51D">{value}</TextCell>;
//   }

//   return value;
// }

interface Props {
  itemDetailRow: requestCondModel;
}

function DetailModalCond({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowConfirmModalSelector);
  const data = useSelector(listOrderDetailModalSelector);
  // const { orderType, tradeType } = useSelector(filterOrderHistorySelector);
  const closeModalHandler = () => {
    dispatch(updateModalStatusRequest({ isVisible: false }));
  };
  const themeType = useSelector(themeTypeSelector);
  const fetchMoreData = () => {};
  const center = "center";
  function getColor3(value: string, value2: number) {
    if (value2 === 9 || value2 === 2) {
      return <TextCell color={themes[themeType].red50}>{value}</TextCell>;
    } else if (value2 === 1) {
      return <TextCell color={themes[themeType].green50}>{value}</TextCell>;
    } else if (value2 === 3) {
      return <TextCell color={themes[themeType].blue50}>{value}</TextCell>;
    }
    // else if (value2 === 3) {
    //   return <TextCell color="#29ABE2">{value}</TextCell>;
    // }

    return value;
  }
  const condColumns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.volume" />
          </HeaderCell>
        ),
        accessor: "ordQty",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.price" />
          </HeaderCell>
        ),
        accessor: "ordPrice",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderVolumeFilled" />
          </HeaderCell>
        ),
        accessor: "matQty",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderPriceFilled" />
          </HeaderCell>
        ),
        accessor: "matAmt",

        Cell: (props: any) => {
          const row = props?.row?.original;

          let matQty = row?.matQty ?? 0;
          let matQty2 = matQty > 0 ? matQty * 1000 : 0;
          let matPriceAvg = matQty2 > 0 ? props.value / matQty2 : 0;

          return (
            <TableCellContent align={center}>
              {numeral(matPriceAvg).format("0.00")}{" "}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.time" />
          </HeaderCell>
        ),
        accessor: "regDateTime",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {moment(new Date(props.value).toString()).format("DD/MM/YYYY")}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const getDisplay = (status: number) => {
    switch (status) {
      case 1:
        return "Back";
      case 2:
        return "Web";
      case 3:
        return "Front";
      case 4:
        return "Webmobile";
      case 5:
        return "Mobile";
      case 6:
        return "Home";
      case 7:
        return "Floor";
      case 8:
        return "SMS";
      case 9:
        return "Tel";
    }
  };
  const getDisplay3 = (status: number) => {
    switch (status) {
      case 1:
        return "matMethod1";
      case 2:
        return "matMethod2";
    }
  };
  const getDisplay5 = (status: number) => {
    switch (status) {
      case 1:
        return "takeEffect";
      case 2:
        return "expire";
      case 3:
        return "complete";
      case 9:
        return "cancel";
    }
  };
  const getDisplay7 = (status: number) => {
    switch (status) {
      case 1:
        return "sell";
      case 2:
        return "buy";
    }
  };
  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            {intl.formatMessage({
              id:
                "orderHistory.tradeType." +
                getDisplay7(itemDetailRow.tradeType ?? 0),
            })}
            {" ("}
            {itemDetailRow.condType}
            {") "}
            {itemDetailRow.secCd}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-2">
            <LabelCell>
              <FormattedMessage id="orderHistory.table.condType" />
            </LabelCell>
            <TextCell>
              {intl.formatMessage({
                id: "orderHistory.condType." + itemDetailRow.condType,
              })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.subAccount" />
            </LabelCell>
            <TextCell>{itemDetailRow?.subAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.matMethod" />
            </LabelCell>
            <TextCell>
              {" "}
              {intl.formatMessage({
                id:
                  "orderHistory.matMethod." +
                  getDisplay3(itemDetailRow.matMethod ?? 0),
              })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.status" />
            </LabelCell>

            {itemDetailRow?.status &&
              getColor3(
                intl.formatMessage({
                  id:
                    "orderHistory.extStatusCond." +
                    getDisplay5(itemDetailRow?.status ?? 0),
                }),
                itemDetailRow?.status
              )}
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.orderVolume" />
            </LabelCell>

            <TextCell> {numeral(itemDetailRow?.ordQty).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.orderPrice" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.ordPrice).format("0.00")}
            </TextCell>
          </BodyModalRow>

          {itemDetailRow.condType === "TCO" && (
            <BodyModalRow>
              <LabelCell>
                <FormattedMessage id="orderHistory.table.referencePrice" />
              </LabelCell>

              <TextCell>
                {itemDetailRow?.optType === null
                  ? `${intl.formatMessage({
                      id: "widgets.placeOrder.conditionalOrder.conditionsList.noCondition",
                    })}`
                  : itemDetailRow?.optType === 1
                  ? `TC >= ${numeral(itemDetailRow?.optVal ?? 0).format(
                      "0.00"
                    )}`
                  : itemDetailRow?.optType === 2
                  ? `TC <= ${numeral(itemDetailRow?.optVal ?? 0).format(
                      "0.00"
                    )}`
                  : `${intl.formatMessage({
                      id: "widgets.placeOrder.conditionalOrder.conditionsList.noCondition",
                    })}`}
              </TextCell>
            </BodyModalRow>
          )}
          {(itemDetailRow.condType === "SP" ||
            itemDetailRow.condType === "CL") && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.difference" />
                </LabelCell>
                <TextCell>
                  {itemDetailRow?.optValType === 1
                    ? `${numeral(itemDetailRow?.optVal ?? 0).format("0.00")}`
                    : itemDetailRow?.optValType === 2
                    ? `${numeral(
                        itemDetailRow?.optVal ? itemDetailRow?.optVal * 100 : 0
                      ).format("0.00")} %`
                    : ""}
                </TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.cond" />
                </LabelCell>
                <TextCell>
                  {itemDetailRow?.condition === null ||
                  itemDetailRow?.condition === "" ||
                  itemDetailRow?.condition === undefined
                    ? `${intl.formatMessage({
                        id: "widgets.placeOrder.conditionalOrder.conditionsList.noCondition",
                      })}`
                    : itemDetailRow?.condition
                        .replace(" ", "")
                        .split("-")[0]
                        .replace(" ", "") === "MAT_PRICE"
                    ? `Giá TT >= ${
                        itemDetailRow?.condition.replace(" ", "").split("-")[1]
                      }`
                    : itemDetailRow?.condition
                        .replace(" ", "")
                        .split("-")[1]
                        .replace(" ", "") === "MAT_PRICE"
                    ? ` Giá TT <= ${
                        itemDetailRow?.condition.replace(" ", "").split("-")[0]
                      }`
                    : ""}
                </TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.limitOffset).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          {itemDetailRow.condType === "TS" && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.pause" />
                </LabelCell>
                <TextCell>
                  {itemDetailRow?.optValType === 1
                    ? `${numeral(itemDetailRow?.optVal ?? 0).format("0.00")}`
                    : itemDetailRow?.optValType === 2
                    ? `${numeral(
                        itemDetailRow?.optVal ? itemDetailRow?.optVal * 100 : 0
                      ).format("0.00")} %`
                    : ""}
                </TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {numeral(itemDetailRow?.limitOffset).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.availableDateTime" />
            </LabelCell>
            <TextCell>
              {" "}
              {convertNumberToDateFormat({
                date: itemDetailRow?.fromDate,
              })}{" "}
              -{" "}
              {convertNumberToDateFormat({
                date: itemDetailRow?.toDate,
              })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.channel" />
            </LabelCell>
            <TextCell>
              {intl.formatMessage({
                id:
                  "orderHistory.ordChannel." +
                  getDisplay(+itemDetailRow?.channel ?? 0),
              })}
            </TextCell>
          </BodyModalRow>
          <Container id="orderHistoryModalTable">
            <CommonTableInfinity
              fetchMoreData={fetchMoreData}
              columns={condColumns}
              data={data ?? []}
              enableInfinity={true}
              scrollableTarget={"orderHistoryModalTable"}
              isHasFooter={false}
            />
          </Container>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailModalCond;
