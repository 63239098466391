import { createSelector } from "@reduxjs/toolkit";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
}

const totalamtSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.totalamt
  );
};

function TotalamtCell({ ticker }: Props) {
  const totalamt = useSelector(totalamtSelector(ticker)) || 0;

  return <VolumeCell volume={totalamt / 1000} keepRawValue={true} />;
}

export default TotalamtCell;
