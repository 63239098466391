import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { logOutSuccess } from "modules/auth/redux";

interface IPositionFilter {
  secCd: string;
  fromDate: Date;
  toDate: Date;
}
export interface ReduxData {
  listPositionOpen: [];
  listPositionClose: [];
  loading: boolean;
  isReloadPositionOpen: boolean;
  isReloadPositionClose: boolean;
  positionOpenFilter: {
    filter: IPositionFilter;
  };
  positionCloseFilter: {
    filter: IPositionFilter;
  };
}

const initialState: ReduxData = {
  listPositionOpen: [],
  listPositionClose: [],
  loading: false,
  isReloadPositionOpen: false,
  isReloadPositionClose: false,
  positionOpenFilter: {
    filter: {
      secCd: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
    },
  },
  positionCloseFilter: {
    filter: {
      secCd: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
    },
  },
};

const PositonManagementSlice = createSlice({
  name: "positionManagementSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },

  reducers: {
    getListPositionOpenRequest: (state, action: PayloadAction<any>) => {},
    getListPositionOpenSuccess: (state, action: PayloadAction<any>) => {
      state.listPositionOpen = action.payload?.data;
    },
    getListPositionOpenFailure: (state) => {},

    searchPositionOpenRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    searchPositionOpenSuccess: (state, action: PayloadAction<any>) => {
      state.listPositionOpen = action.payload?.data;
      state.loading = false;
    },
    searchPositionOpenFailure: (state) => {
      state.loading = false;
    },

    getListPositionCloseRequest: (state, action: PayloadAction<any>) => {},
    getListPositionCloseSuccess: (state, action: PayloadAction<any>) => {
      state.listPositionClose = action.payload?.data;
    },
    getListPositionCloseFailure: (state) => {},

    searchPositionCloseRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    searchPositionCloseSuccess: (state, action: PayloadAction<any>) => {
      state.listPositionClose = action.payload?.data;
      state.loading = false;
    },
    searchPositionCloseFailure: (state) => {
      state.loading = false;
    },
    onLoadListClosePosition: (state, action: PayloadAction<boolean>) => {
      state.isReloadPositionClose = action.payload;
    },
    onLoadListOpenPosition: (state, action: PayloadAction<boolean>) => {
      state.isReloadPositionOpen = action.payload;
    },

    updatePositionOpenFilter: (
      state,
      action: PayloadAction<{ key: keyof IPositionFilter; value: any }>
    ) => {
      state.positionOpenFilter.filter = {
        ...state.positionOpenFilter.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updatePositionCloseFilter: (
      state,
      action: PayloadAction<{ key: keyof IPositionFilter; value: any }>
    ) => {
      state.positionCloseFilter.filter = {
        ...state.positionCloseFilter.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  getListPositionOpenRequest,
  getListPositionOpenSuccess,
  getListPositionOpenFailure,

  getListPositionCloseRequest,
  getListPositionCloseSuccess,
  getListPositionCloseFailure,

  searchPositionOpenRequest,
  searchPositionOpenSuccess,
  searchPositionOpenFailure,

  searchPositionCloseRequest,
  searchPositionCloseSuccess,
  searchPositionCloseFailure,

  onLoadListClosePosition,
  onLoadListOpenPosition,

  updatePositionOpenFilter,
  updatePositionCloseFilter,
} = PositonManagementSlice.actions;
export default PositonManagementSlice.reducer;
