import queryString from "query-string";

export const getServiceStatusOfCustomer2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getServiceOfCustomerForRegister${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const getCustomer2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomer2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const registerServiceApi = `/registerServiceOnline`;
