import { toast } from "components/commons/Toast";
import {
  getFindCbotBondPriceEst,
  getFindDbondAdv,
  postUpdateDbondAdv,
} from "core/http/apis/placeOrderBondAdv";
import {
  findCbotBondPriceEstItem,
  findCbotBondPriceEstRequest,
  findDbondAdvRequest,
  findDbondAdvItem,
  updateDbondAdvDataList,
} from "core/http/apis/placeOrderBondAdv/types";
import { ResponseData } from "domain/models/User";
import { tradeDateSelector } from "modules/auth/selectors";
import moment from "moment";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getFindCbotBondPriceEstStart,
  getFindCbotBondPriceEstSuccess,
  searchRequest,
  getListDbondAdvItemSuccess,
  getListDbondAdvItemFail,
  updateDbondAdvDataListStart,
  updateDbondAdvDataListDone,
  resetUpdateDbondAdvDataList,
  cancelDbondAdvDataListStart,
} from "./redux";
import {
  idCancelSelector,
  listDbondAdvItemSelector,
  searchParamsSelector,
  updateDbondAdvDataListSelector,
} from "./selectors";

function* findCbotBondPriceEstWorker() {
  try {
    const tradeDate: string = yield select(tradeDateSelector);
    const params: findCbotBondPriceEstRequest = {
      tradeDate,
      bondCd: "",
    };
    const response: ResponseData<findCbotBondPriceEstItem[]> = yield call(
      getFindCbotBondPriceEst,
      params
    );
    yield put(getFindCbotBondPriceEstSuccess(response?.data || []));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchRequesttWorker() {
  try {
    const searchParams: findDbondAdvRequest = yield select(
      searchParamsSelector
    );

    const response: ResponseData<findDbondAdvItem[]> = yield call(
      getFindDbondAdv,
      searchParams
    );
    yield put(getListDbondAdvItemSuccess(response?.data || []));
  } catch (error: any) {
    yield put(getListDbondAdvItemFail());
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateDbondAdvDataListWorker() {
  try {
    const tradeDate: string = yield select(tradeDateSelector);
    const params: updateDbondAdvDataList = yield select(
      updateDbondAdvDataListSelector
    );
    const paramsConvert: updateDbondAdvDataList = {
      ...params,
      tradeDate,
      ordPrice: params.ordPrice?.replace(/,/g, ""),
      ordQty: params.ordQty?.replace(/,/g, ""),
      minQty: params.minQty?.replace(/,/g, ""),
      expDate: moment(params.expDate).format("YYYYMMDD"),
      action: "create",
    };
    const response: ResponseData<any> = yield call(postUpdateDbondAdv, [
      paramsConvert,
    ]);
    yield put(updateDbondAdvDataListDone());
    if (response.statusCode === 0) {
      toast("placeOrderBondAdv.addSuccess", "success");
      yield put(resetUpdateDbondAdvDataList());
      yield put(searchRequest());
    } else {
      toast("error.defaultErr", "error");
    }
  } catch (error: any) {
    yield put(updateDbondAdvDataListDone());
    toast(error?.code, "error", error?.description, true);
  }
}

function* cancelDbondAdvDataListWorker() {
  try {
    const tradeDate: string = yield select(tradeDateSelector);
    const idCancel: number = yield select(idCancelSelector);
    const listDbondAdv: findDbondAdvItem[] = yield select(
      listDbondAdvItemSelector
    );
    const paramsConvert: any = {
      ...listDbondAdv?.filter((item) => item.id === idCancel)?.[0],
      tradeDate,
      action: "cancel",
    };
    const response: ResponseData<any> = yield call(postUpdateDbondAdv, [
      paramsConvert,
    ]);

    if (response.statusCode === 0) {
      toast("placeOrderBondAdv.cancelSuccess", "success");
      yield put(searchRequest());
    } else {
      toast("error.defaultErr", "error");
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* findCbotBondPriceEstWatcher() {
  yield takeLatest(
    getFindCbotBondPriceEstStart.type,
    findCbotBondPriceEstWorker
  );
}
function* searchRequestWatcher() {
  yield takeLatest(searchRequest.type, searchRequesttWorker);
}
function* updateDbondAdvDataListWatcher() {
  yield takeLatest(
    updateDbondAdvDataListStart.type,
    updateDbondAdvDataListWorker
  );
}
function* cancelDbondAdvDataListWatcher() {
  yield takeLatest(
    cancelDbondAdvDataListStart.type,
    cancelDbondAdvDataListWorker
  );
}

export default function* placeOrderBondAdvSaga() {
  yield all([
    findCbotBondPriceEstWatcher(),
    searchRequestWatcher(),
    updateDbondAdvDataListWatcher(),
    cancelDbondAdvDataListWatcher(),
  ]);
}
