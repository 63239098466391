import { FC } from "react";
import Container from "components/commons/Container";
import AdvanceContent from "./components";
import RequestDetail from "./components/AdvanceContent/RequestDetail";
import { useIntl } from "react-intl";

const Advance: FC = () => {
  const intl = useIntl();
  const arrayForm = [AdvanceContent, RequestDetail];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "advance.advanceHistory",
      })}
      headerForm={intl.formatMessage({
        id: "advance.advanceMoney",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default Advance;
