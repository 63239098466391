import { Container, Content, Wrap } from "./styles";
import { FormattedMessage } from "react-intl";
import Table from "./table";

const ContentBottomAssets = () => {
  return (
    <Container>
      <Content>
        <div>
          <FormattedMessage id={"assets.assets.table.title"} />
        </div>
      </Content>
      <Wrap>
        <Table />
      </Wrap>
    </Container>
  );
};

export default ContentBottomAssets;
