import styled from "styled-components/macro";

export const NetflowContainer = styled.div`
  padding: 12px 16px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  flex: 0 0 24px;
  padding-bottom: 4px;
  .header-wrapper {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }

  div:first-child {
    margin-left: 0;
  }
`;

export const NetflowMainContent = styled.div`
  flex: 1 1 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const NetflowLegendContainer = styled.div`
  margin-top: 8px;
  flex: 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .flex-1 {
    flex: 1;
  }
  .flex-5 {
    flex: 5;
  }
  .positive,
  .negative {
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    color: ${({ theme }) => theme.grey50};
    display: flex;
    justify-content: flex-start;
  }
`;

export const LegendBlock = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background: ${({ color, theme }) => theme[color]};
  border-radius: 2px;
  margin-right: 4px;
`;

export const ChartContainer = styled.div`
  margin-top: 4px;
  position: relative;
  flex: 1 1 0;
  overflow: hidden;
`;
