import { FC } from "react";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import TableRightInfo from "./Table";
import FilterRightInfo from "./Filters";
import { ActiveTabRightInfo } from "helper/consts";
import { useSelector } from "react-redux";

import { activeTabSelector } from "modules/right-info/selectors";

const RightInfoTable: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === ActiveTabRightInfo.RIGHT_INFO && (
        <>
          <FilterRightInfo />
          <TableRightInfo />
        </>
      )}
    </Wrap>
  );
};
export default RightInfoTable;
