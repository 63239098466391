import React, { useImperativeHandle, useRef } from "react";
import { Checkbox } from "../CommonTable/styles";

export type ICheckboxProps = {
  checked?: boolean;
  rowData?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  ICheckboxProps
>((props, inRef) => {
  const { checked = false, rowData, ...rest } = props;

  const ref = useRef<HTMLInputElement>(null);
  useImperativeHandle(inRef, () => ref.current!, [ref]);

  const isAllowCheck = !rowData || rowData?.original?.visibleCheckbox;

  return (
    <Checkbox
      type="checkbox"
      checked={!isAllowCheck ? false : checked}
      ref={ref}
      {...rest}
      disabled={!isAllowCheck}
    ></Checkbox>
  );
});
export default IndeterminateCheckbox;
