import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Dictionary } from "lodash";
import numeral from "numeral";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { portfolioRealTimeListSelector } from "modules/stock-order/redux/selectors";
import { PortfolioRealTime } from "domain/models/Portfolio";

const stockValueSelector = (orderId: string) => {
  return createSelector(
    portfolioRealTimeListSelector,
    (state?: Dictionary<PortfolioRealTime>) => state?.[orderId]?.stockValue
  );
};

interface Props {
  orderId: string;
}

const StockValueCell = ({ orderId }: Props) => {
  const stockValue = useSelector(stockValueSelector(orderId));
  const formatterValue = numeral(stockValue).format("0,0");
  return (
    <>
      <TableCellContent align="flex-end">
        {formatterValue || "-"}
      </TableCellContent>
    </>
  );
};

export default StockValueCell;
