import styled from "styled-components/macro";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.background2};
  padding: 4px 12px;
  box-shadow: inset 0px 1px 0px ${({ theme }) => theme.shadowInner3Up};
  button {
    height: 32px;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
  }
`;
