import HttpClient from "../../index";
import { RESTFUL_SCREENER_URL } from "AppConfigNew";

export default class HttpScreener extends HttpClient {
  private static classInstance?: HttpScreener;
  private constructor() {
    super(RESTFUL_SCREENER_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpScreener();
    }

    return this.classInstance;
  }
}
