import {
  ScreenerInput,
  ScreenerResponse,
} from "domain/protoNew/auto_trading_pb";
import { MarketApiClient } from "domain/protoNew/auto_trading_pb_service";

const screenersHost = `${process.env.REACT_APP_GRPC_SCREENERS_URL}:${process.env.REACT_APP_GRPC_SCREENERS_PORT}`;

export const screenerClient = new MarketApiClient(screenersHost);

export const doFilter = (
  filter: ScreenerInput
): Promise<ScreenerResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    const currentLimit = filter.getLimit();
    if (!currentLimit) {
      filter.setLimit(50);
    }

    let results: ScreenerResponse.AsObject = {
      status: 0,
      error: "",
      message: "",
      totalrecords: 0,
      secdetailinfoList: [],
    };

    screenerClient
      .findIndicatorScreener(filter)
      .on("data", (data) => {
        results = data?.toObject();
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};
