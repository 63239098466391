// import { ETradingSide } from "domain/proto/common/data_common_pb";
// import {
//   CWPriceInfo,
//   PriceInfoBasic,
//   PriceInfoForeignRoom,
//   PriceInfoLastSale,
//   PriceInfoQuote,
//   PriceInfoQuotes,
//   PriceInfoSummary,
//   PriceInfoUnderlying,
// } from "domain/proto/common/data_priceinfo_pb";
import emitter, { EMMIT_ACTION_TYPE } from "./emitterWss";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

// import IndexInfoInterface from "../domain/models/IndexInfo";
import React from "react";
// import { useEffectOnce } from "react-use";
// import { QRCodeMessage } from "domain/proto/common/data_qr_code_pb";
import { useSelector } from "react-redux";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { LastSale, ProjectOpen } from "domain/protoNew/auto_trading_pb";
import { getSecQuotesDetail } from "core/grpc";
import { getColorPriceInfoLastSale } from "./utils";
import { QRCodeMessage } from "domain/models/QRCodeMessage";

// Get Price Info Full
// export type State = {
//   dataChart: number[];
//   timeListArray?: number[];
//   referencePrice: number;
//   dataInfoLastSale: PriceInfoLastSale;
//   dataInfoQuotes: PriceInfoQuotes;
//   dataInfoBasic: PriceInfoBasic;
//   dataInfoSummary: PriceInfoSummary;
//   dataInfoForeignRoom: PriceInfoForeignRoom;
//   dataInfoUnderlying: PriceInfoUnderlying;
//   dataInfoCWPrice: CWPriceInfo;
// };

// Get Price Info Last Sale
export const useInfoLastSale = (ticker: string) => {
  const [data, setData] = useState(new LastSale().toObject());
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);

  const getDataLastSale = async () => {
    try {
      if (ticker) {
        const res = await getSecQuotesDetail(ticker);
        if (res.status === 0 && res.secdetailinfo != null) {
          const data = res.secdetailinfo;
          const colorCode = getColorPriceInfoLastSale(
            data.lastprice,
            data.ceilingprice,
            data.floorprice,
            data.basicprice
          );

          const lastSaleData = new LastSale();
          lastSaleData.setMarketcd(data.marketcd);
          lastSaleData.setSeccd(data.seccd);
          lastSaleData.setChangepoint(data.changepoint);
          lastSaleData.setChangepercent(data.changepercent);

          lastSaleData.setLastprice(data.lastprice);
          lastSaleData.setLastqty(data.lastqty);
          lastSaleData.setLastamt(0); // TODO
          lastSaleData.setTotalqty(data.totalqty);
          lastSaleData.setTotalamt(data.totalamt);
          lastSaleData.setHightprice(data.highestprice);
          lastSaleData.setLowprice(data.lowestprice);
          lastSaleData.setAvgprice(data.avgprice);
          lastSaleData.setColorcode(colorCode);
          lastSaleData.setMattime(0);

          setData(lastSaleData.toObject());
        }
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    getDataLastSale();
    const eventListenerLastSale = (emittedData: LastSale.AsObject) => {
      if (ticker === emittedData?.seccd) {
        setData(emittedData);
      }
    };
    const eventListenerProjectOpen = (emittedData: ProjectOpen.AsObject) => {
      if (ticker === emittedData?.seccd) {
        const batchTickerInfo = {
          changepoint: emittedData.changepoint,
          changepercent: emittedData.changepercent,
          lastprice: emittedData.price,
          lastqty: emittedData.qty,
        };
        setData(Object.assign(data, batchTickerInfo) as LastSale.AsObject);
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_ProjectOpen, eventListenerProjectOpen);

    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_ProjectOpen,
        eventListenerProjectOpen
      );
    };
  }, [ticker]);

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      getDataLastSale();
    }
  }, [isVisibilityPage]);

  return data;
};

// //TODO: Naming hook
// export const useInfoIndex = (
//   initData: IndexInfoInterface
// ): IndexInfoInterface => {
//   const [data, setData] = useState(initData);
//   useEffectOnce(() => {
//     const eventHandler = (dataEmmit: IndexInfoInterface) => {
//       console.log("dataEmmit", dataEmmit);
//       const {
//         ticker,
//         indexvalue,
//         changedindexpercentage,
//         changedindexvalue,
//         totalsharestraded,
//         totalvaluestraded,
//         timeValue,
//         open,
//       } = data;
//       if (
//         ticker === dataEmmit.ticker &&
//         (indexvalue !== dataEmmit.indexvalue ||
//           changedindexpercentage !== dataEmmit.changedindexpercentage ||
//           changedindexvalue !== dataEmmit.changedindexvalue ||
//           totalsharestraded !== dataEmmit.totalsharestraded ||
//           totalvaluestraded !== dataEmmit.totalvaluestraded ||
//           timeValue !== dataEmmit.timeValue ||
//           open !== dataEmmit.open)
//       ) {
//         const indexvalue = dataEmmit?.indexvalue ?? 0;
//         const changedindexpercentage = dataEmmit?.changedindexpercentage ?? 0;
//         const changedindexvalue = dataEmmit?.changedindexvalue ?? 0;
//         const color = dataEmmit.color;
//         const totalsharestraded = dataEmmit?.totalsharestraded ?? 0;
//         const totalvaluestraded = dataEmmit?.totalvaluestraded ?? 0;
//         const timeValue = dataEmmit?.timeValue ?? 0;
//         const open = dataEmmit?.open ?? 0;

//         const newData: IndexInfoInterface = {
//           ticker: dataEmmit.ticker,
//           indexvalue: indexvalue,
//           changedindexpercentage: changedindexpercentage,
//           changedindexvalue: changedindexvalue,
//           color: color,
//           totalsharestraded: totalsharestraded,
//           totalvaluestraded: totalvaluestraded,
//           timeValue: timeValue,
//           open: open,
//         };

//         setData(newData);
//       }
//     };
//     emitter.on(EMMIT_ACTION_TYPE.EMMIT_INDEX_INFO, eventHandler);

//     return () => {
//       emitter.off(EMMIT_ACTION_TYPE.EMMIT_INDEX_INFO, eventHandler);
//     };
//   });

//   return data;
// };

export function usePrevious<T extends object>(value: T) {
  const ref = React.useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useOTPInput<T>(value: T) {
  const ref = React.useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export const useDoubleClick = (
  doubleClick: any,
  click?: any,
  options?: any
) => {
  options = {
    timeout: 200,
    ...options,
  };

  const clickTimeout = useRef<any>();

  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  };

  return useCallback(
    (event) => {
      clearClickTimeout();
      if (click && event.detail === 1) {
        clickTimeout.current = setTimeout(() => {
          click(event);
        }, options.timeout);
      }
      if (event.detail % 2 === 0) {
        doubleClick(event);
      }
    },
    [click, doubleClick, options.timeout]
  );
};

export const useOutsideClick = (
  ref: RefObject<HTMLDivElement> | RefObject<HTMLLIElement>,
  callback: () => void
) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Element)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export function useRoveFocus(
  size: number,
  setIsChangeArrow: (value: boolean) => void,
  isChangeArrow: boolean
) {
  const [currentFocus, setCurrentFocus] = useState(-1);

  useEffect(() => {
    if (isChangeArrow) {
      setCurrentFocus(0);
    } else {
      setCurrentFocus(-1);
    }
  }, [isChangeArrow]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 40) {
        // Down arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
        setIsChangeArrow(true);
      } else if (e.keyCode === 38) {
        // Up arrow
        e.preventDefault();
        setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
        setIsChangeArrow(true);
      }
    },
    [size, currentFocus, setCurrentFocus]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return [currentFocus, setCurrentFocus];
}

export const useCanvas = (
  draw: (ctx: CanvasRenderingContext2D | null, frame: number) => void
) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      let frameCount = 0;
      let animationFrameId: number;

      const render = () => {
        frameCount++;
        draw(context, frameCount);
        animationFrameId = window.requestAnimationFrame(render);
      };
      render();

      return () => {
        window.cancelAnimationFrame(animationFrameId);
      };
    }
  }, [draw]);

  return canvasRef;
};

// QR code
export const useQRCodeAuthen = () => {
  const [data, setData] = useState<QRCodeMessage>();
  useEffect(() => {
    const eventListener = (emittedData: QRCodeMessage) => {
      setData(emittedData);
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_DATA_QR_CODE, eventListener);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_DATA_QR_CODE, eventListener);
    };
  }, []);
  return data;
};
