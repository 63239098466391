import VgaiaHttp from "core/http/singleton/vgaia";
import { ISubscribeResponse } from "domain/models/SubscribeBuy";
import { ICreateCapitalIncrease, IGetRemainQuantity } from "./types";
import {
  createCapitalIncreaseUrl,
  deleteCapitalIncreaseAppUrl,
  findCapitalIncreaseUrl,
  getRemainQuantityUrl,
  getSearchSubscribeBuyUrl,
  getSubscribeBuyUrl,
  inquiryCapitalIncreseUrl,
} from "./urls";

export const getDataSubscribeApi = async (params: {
  subAccoNo?: string;
  SecCd?: string;
}) => {
  const res = await VgaiaHttp.get<ISubscribeResponse>(
    getSubscribeBuyUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getDataSearachSubscribeApi = async (params: {
  secCd?: string;
  rightType?: string;
  ownerFixDate?: string;
}) => {
  const res = await VgaiaHttp.get<ISubscribeResponse>(
    getSearchSubscribeBuyUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getInquiryCapitalIncrese = async (params: {
  subAccoNo: string | null;
}) => {
  const res = await VgaiaHttp.get<ISubscribeResponse>(
    inquiryCapitalIncreseUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getRemainQuantity = async (params: IGetRemainQuantity) => {
  const res = await VgaiaHttp.get<ISubscribeResponse>(
    getRemainQuantityUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createCapitalIncrease = async (
  params: ICreateCapitalIncrease
): Promise<ISubscribeResponse> => {
  const res = await VgaiaHttp.post<ISubscribeResponse>(
    createCapitalIncreaseUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findCapitalIncrease = async (params: { subAccoNo: string }) => {
  const res = await VgaiaHttp.get<ISubscribeResponse>(
    findCapitalIncreaseUrl({
      subAccNo: params.subAccoNo,
    })
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteCapitalIncreaseApp = async (params: {
  applyId: number;
  lastUpdateTime: string;
}): Promise<ISubscribeResponse> => {
  const res = await VgaiaHttp.post<ISubscribeResponse>(
    deleteCapitalIncreaseAppUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
