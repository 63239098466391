import { PayloadAction } from "@reduxjs/toolkit";
import {
  all,
  put,
  select,
  delay,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { RootState } from "redux/store";
import {
  closeToast,
  openToast,
  requestCloseToast,
  requestOpenToast,
  ToastMessage,
} from "../redux/toast";

function* openToastWorker({ payload }: PayloadAction<ToastMessage>) {
  yield put(openToast(payload));

  yield put(requestCloseToast(payload.id));
}

function* closeToastWorker(action: PayloadAction<string>) {
  const delayValue: number = yield select(
    (state: RootState) => state.system.toast.delay
  );

  yield delay(delayValue);

  yield put(closeToast(action.payload));
}

export default function* toastSaga() {
  yield all([
    takeEvery(requestOpenToast.type, openToastWorker),
    takeLatest(requestCloseToast.type, closeToastWorker),
  ]);
}
