import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import { OrderBookDerRealTime } from "domain/models/OrderBookDer";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";

const orderPriceDispSelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) =>
      state?.[realTimeId]?.orderPriceDisp
  );
};

interface Props {
  realTimeId: string;
}

const OrderPriceDispCell = ({ realTimeId }: Props) => {
  const orderPriceDisp = useSelector(orderPriceDispSelector(realTimeId));
  return (
    <>
      <BlinkableCell value={orderPriceDisp} />
    </>
  );
};

export default OrderPriceDispCell;
