import queryString from "query-string";
import {
  IfindParameterBankDetail,
  IGetAccountTransfer,
  IParamGetDepositApi,
  ITransferLocalFilter,
} from "./types";

export const inquiryAccountTraApi = (params: IGetAccountTransfer) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryAccountTra${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const addTransferInternalUrl = () => {
  return `/submitTransfer`;
};

export const getTransferBankUrl = (params: ITransferLocalFilter) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findTransferBankHistory${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getListBankUrl = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getBankMasterCombobox${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getListlocationUrl = "/getAllLocation";

export const getListBranchUrl = (params: {
  bankCd: string | null;
  locationCd: string | null;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getBankBranchList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const addAccountUrl = () => {
  return `/updateTransferContractBank`;
};

export const getListAccountBanks = (params: { subAccoNo: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getSubAccToList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findTransferContractBank = (params: {
  subAccCd?: number;
  toSubAccountNo: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findTransferContractBank${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const inquiryAccountTransBankUrl = (params: { subAccoNo: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryAccountTransBank${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const submitTransferBankUrl = () => {
  return `/submitTransferBank`;
};

export const findParameterBankDetailUrl = (
  params: IfindParameterBankDetail
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findParameterBankDetail${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getSubAccToLocalListlUrl = (params: { subAccNo?: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getSubAccToLocalList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findTransferContractLocalUrl = (params: {
  subAccCd?: number;
  toSubAccountNo?: string;
  checkContract?: number;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findTransferContractLocal${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const deleteTransferBankUrl = () => {
  return `/deleteTransferBank`;
};

export const getDerCashTransListApi = (params: IParamGetDepositApi) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getDerCashTransList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const createDerTTransferCashkUrlUrl = () => {
  return `/createDerTTransferCashkUrl`;
};

export const calculateTransferCashFeeAmtApi = (params: {
  amount: string | null;
  feeType: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/calculateTransferCashFeeAmt${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getTransferAvailDertApi = (params: {
  subAccoNo: string | null;
  tradeType: number;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryDertTransfer${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const createDerTTransferCashUrl = () => {
  return `/createDerTTransferCash`;
};

export const updateDerTTransferCashhUrl = () => {
  return `/updateDerTTransferCash`;
};

export const getTransferBankFeeAmtUrl = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getTransferBankFeeAmt${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
