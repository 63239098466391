import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const Wrap: any = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  height: 53%;
`;

export const WrapItemLeft: any = styled.div`
  width: 50%;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  height: 100%;
  padding: 20px;
`;

export const WrapItemRight: any = styled.div`
  width: 50%;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  height: 100%;
  padding: 20px;
`;

export const BodyModalRow = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BodyModalRowright = styled.div`
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelCell = styled.div`
  flex: 1;
  padding-left: 20px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
`;

export const LabelCellRight = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
`;

export const LabelCellBig = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const TextCell = styled.div<{
  color?: string;
}>`
  flex: 1;
  text-align: right;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, color }) => (color ? color : theme.textcolorWhite)};
`;

export const Title = styled.div`
  padding: 5px 0px;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TabsContainer = styled.div`
  padding: 12px 16px 4px 0px;
  .tabs {
    display: flex;
    flex-wrap: wrap;
  }
  .description {
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    padding: 10px 0 0 4px;
  }
`;

export const ClickableBox = styled.div<{
  active: boolean;
  size?: "small" | "medium";
}>`
  cursor: pointer;
  min-width: 80px;
  color: ${({ theme }) => theme.grey50};
  ${({ active, theme }) =>
    active &&
    `border: 1px solid ${theme.greyG7};
    color: ${theme.textcolorWhite};`}
  background: ${({ active, theme }) =>
    active ? theme.grey60 : theme.background2};
  box-sizing: border-box;
  border-radius: 4px;
  height: ${({ theme }) => theme.headerButton.height};
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.header.weight.regular};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ size, theme }) =>
    size === "small" &&
    `font-size: ${theme.typeFaces.caption12.size};
    padding: 4px;
    height: 24px;
    line-height: ${theme.typeFaces.caption12.lineHeight};
    color: ${theme.textcolorWhite};`}
  ${({ active, theme }) =>
    active &&
    `border: 1px solid ${theme.solidBlue};
  color: ${theme.solidBlue};`}
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(40, 54, 72, 0.8);
    max-width: 276px;
    border-radius: 4px;
    padding: 8px 12px;
    text-align: left;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .arrow {
    transform: translate(20px, 0px) !important;
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;
