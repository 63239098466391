import { LanguageType } from "languages/redux";
// import { TickerInfo } from "domain/proto/common/data_ticker_info_pb";
// import {
//   EMarketCode,
//   EMarketCodeMap,
//   ESecuritiesTypeMap,
//   ETickerTypeMap,
// } from "domain/proto/common/data_common_pb";
import { ColManage, TickerOption } from "../types";
import get from "lodash/get";
import {
  columnsDataDefault,
  columnsCoverWarrant,
  columnsCoverWarrantHead,
} from "../constant";
import { produce } from "immer";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { ETickerTypeMapNew } from "helper/consts";

export function formatTickerOptionsNew(
  tickers: MrktSecInfoItem.AsObject[],
  languageType: LanguageType
): TickerOption[] {
  const labelField = languageType === LanguageType.VI ? "secname" : "secnameen";
  return tickers.map((ticker: MrktSecInfoItem.AsObject) => ({
    value: ticker.seccd,
    label: `${ticker[labelField]}---${ticker.marketcd}`,
  }));
}

// export function formatTickerOptions(
//   tickers: TickerInfo.AsObject[],
//   languageType: LanguageType
// ): TickerOption[] {
//   const labelField = languageType === LanguageType.VI ? "namevi" : "nameen";
//   return tickers.map((ticker: TickerInfo.AsObject) => ({
//     value: ticker.ticker,
//     label: `${ticker[labelField]}---${formatMarketCode(ticker.marketcode)}`,
//   }));
// }

// export function formatMarketCodeNew(
//   marketCode: EMarketCodeMap[keyof EMarketCodeMap]
// ): string {
//   switch (marketCode) {
//     case EMarketCode.EMC_UNKNOWN:
//       return "KXĐ";
//     case EMarketCode.HSX_ALL:
//       return "HSX_ALL";
//     case EMarketCode.HSX_LISTED:
//       return "HSX";
//     case EMarketCode.HNX_ALL:
//       return "HNX_ALL";
//     case EMarketCode.HNX_LISTED:
//       return "HNX";
//     case EMarketCode.HNX_UPCOM:
//       return "UPCOM";
//     case EMarketCode.HNX_DERIVATIVE:
//       return "DERIVATIVE";
//     case EMarketCode.HNX_BOND_LISTED:
//       return "BOND";
//     default:
//       return "";
//   }
// }

// export function formatMarketCode(
//   marketCode: EMarketCodeMap[keyof EMarketCodeMap]
// ): string {
//   switch (marketCode) {
//     case EMarketCode.EMC_UNKNOWN:
//       return "KXĐ";
//     case EMarketCode.HSX_ALL:
//       return "HSX_ALL";
//     case EMarketCode.HSX_LISTED:
//       return "HSX";
//     case EMarketCode.HNX_ALL:
//       return "HNX_ALL";
//     case EMarketCode.HNX_LISTED:
//       return "HNX";
//     case EMarketCode.HNX_UPCOM:
//       return "UPCOM";
//     case EMarketCode.HNX_DERIVATIVE:
//       return "DERIVATIVE";
//     case EMarketCode.HNX_BOND_LISTED:
//       return "BOND";
//     default:
//       return "";
//   }
// }

export function formatTableAttribute(
  columns: ColManage,
  calculatorBy: "minWidth" | "smallDeviceMinWidth"
) {
  return {
    sumWidth: sumCalc(columns, calculatorBy),
    askWidth: calcWidth(columns.ASK.children!, calculatorBy),
    bidWidth: calcWidth(columns.BID.children!, calculatorBy),
    priceWidth: calcWidth(columns.PRICE?.children!, calculatorBy),
    lastSaleWidth: calcWidth(columns.LAST_SALE.children!, calculatorBy),
    foreignWidth: calcWidth(columns.FOREIGN?.children!, calculatorBy),
    coverWarrantWidth: calcWidth(
      columns.COVER_WARRANT?.children!,
      calculatorBy
    ),
    coverWarrantHeadWidth: calcWidth(
      columns.COVER_WARRANT_HEAD?.children!,
      calculatorBy
    ),
  };
}

const sumCalc = (
  columns: ColManage,
  calculatorBy: "minWidth" | "smallDeviceMinWidth"
) => {
  let result = 0;
  Object.keys(columns).forEach((col) => {
    if (columns[col]?.visible && columns[col][calculatorBy]) {
      result += columns[col][calculatorBy]!;
    }
    if (columns[col].children) {
      result += sumCalc(columns[col].children!, calculatorBy);
    }
  });
  return result;
};

const calcWidth = (
  data: ColManage,
  calculatorBy: "minWidth" | "smallDeviceMinWidth"
) => {
  if (!data) return 0;
  return Object.keys(data).reduce((cur, next) => {
    const item = data[next];
    if (item!.visible!) {
      return cur + item[calculatorBy]!;
    }
    return cur;
  }, 0);
};

export const tickerFilterNew = (
  listTicker: MrktSecInfoItem.AsObject[],
  type: ETickerTypeMapNew[keyof ETickerTypeMapNew]
  // securitiesType: ESecuritiesTypeMap[keyof ESecuritiesTypeMap]
) => {
  return listTicker
    .filter((ticker) => ticker.sectype === type)
    .map((el) => el.seccd);
};

// export const tickerFilter = (
//   listTicker: TickerInfo.AsObject[],
//   type: ETickerTypeMap[keyof ETickerTypeMap],
//   securitiesType: ESecuritiesTypeMap[keyof ESecuritiesTypeMap]
// ) => {
//   return listTicker
//     .filter(
//       (ticker) =>
//         ticker.tickertype === type && ticker.securitiestype === securitiesType
//     )
//     .map((el) => el.ticker);
// };

export function getWidthRatio(colWidth?: number, totalWidth?: number): number {
  if (!colWidth || !totalWidth) {
    return 0;
  }
  return (colWidth / totalWidth) * 100;
}

export function getWidthForOptionalColumn(
  columns: ColManage,
  group: string,
  field: string
) {
  return get(columns, `${group}.children.${field}.minWidth`, 0) as number;
}

export function updatedMinWidthColumns(dehydratedColumns: ColManage) {
  const originalColumnConfig = {
    ...columnsDataDefault,
    ...columnsCoverWarrant,
    ...columnsCoverWarrantHead,
  };

  if (!dehydratedColumns) return {};

  return produce(dehydratedColumns, (columns) => {
    Object.keys(columns).forEach((columnGroupKey) => {
      if (!columns[columnGroupKey]) return;

      updateMinWidthForColumnGroup(
        originalColumnConfig[columnGroupKey],
        columns[columnGroupKey]
      );
    });
  });
}

function updateMinWidthForColumnGroup(
  originalColumnConfig?: any,
  columnGroup?: any
) {
  if (!columnGroup || !originalColumnConfig) return;

  if (columnGroup.children == null) {
    columnGroup.minWidth = originalColumnConfig.minWidth;
    return;
  }

  Object.keys(columnGroup.children).forEach((columnItemName) => {
    updateMinWidthForColumnGroup(
      originalColumnConfig.children[columnItemName],
      columnGroup.children[columnItemName]
    );
  });
}
