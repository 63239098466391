import queryString from "query-string";
import {
  IFindMarginContractDebitWatchParams,
  IGetMrgDebitInfo2Params,
  IFindListMarginAttrRequestParams,
  IGetMarginDebitExtendFeeInfoParams,
  IGetListDebitPayExParams,
} from "./types";

export const findMarginContractDebitWatchApi = (
  params: IFindMarginContractDebitWatchParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findMarginContractDebitWatch${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getListDebitPayExApi = (params: IGetListDebitPayExParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getListDebitPayEx${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getMrgDebitInfo2Api = (params: IGetMrgDebitInfo2Params) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getMrgDebitInfo2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getMarginDebitExtendFeeInfoApi = (
  params: IGetMarginDebitExtendFeeInfoParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getMarginDebitExtendFeeInfo${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const getListMarginAttrApi = (
  params: IFindListMarginAttrRequestParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findListMarginAttr${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const updateMrgContractPaymentApi = "/updateMrgContractPayment";
export const marginDebitExtendDaysApi = "/marginDebitExtendDays";
