import Categories from "./pages/Categories";
import MainLayout from "components/layouts/Main";
import { RouterConfig } from "router/AppRoute";
import paths from "helper/pathRoutes";

const routes: RouterConfig[] = [
  {
    path: paths.categories,
    page: Categories,
    exact: true,
    layout: MainLayout,
    checkLogin: false,
  },
];

export default routes;
