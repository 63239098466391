import {
  putThroughSelector,
  tickersListSelectedSelector,
} from "modules/categories/redux/selectors";
import { memo, useEffect } from "react";
import BidAskTable from "./BidAskTable";
import MatchedTable from "./MatchedTable";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Header, Wapper, PutThroughWapper } from "./style";
import numeral from "numeral";
import {
  TIME_REFETCH_INTERVAL_PUTTHROUGH,
  WatchlistGroupType,
} from "helper/consts";
import {
  changeTypePutThrough,
  fetchListPriceInfoPutThrough,
} from "modules/categories/redux/putThrough";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import { sumBy } from "lodash";
import { putThrough } from "modules/categories/constant";
import { useInterval } from "react-use";

function PutThrough() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const tickersListSelected = useSelector(tickersListSelectedSelector);
  const { priceInfoPutThroughList, selectedTypePutThrough } =
    useSelector(putThroughSelector);

  const getPutThroughName = () => {
    const curr = putThrough.find(
      (item) => item.name === selectedTypePutThrough
    );
    return curr?.displayName;
  };

  useInterval(() => {
    if (selectedTypePutThrough !== "") {
      dispatch(fetchListPriceInfoPutThrough(selectedTypePutThrough));
    }
  }, TIME_REFETCH_INTERVAL_PUTTHROUGH);

  useEffect(() => {
    if (tickersListSelected.type === WatchlistGroupType.PUT_THROUGH) {
      dispatch(changeTypePutThrough(tickersListSelected.name));
    }
  }, []);

  useEffect(() => {
    if (selectedTypePutThrough !== "") {
      dispatch(fetchListPriceInfoPutThrough(selectedTypePutThrough));
    }
  }, [isAuthenticated, selectedTypePutThrough]);

  return (
    <PutThroughWapper>
      <Header>
        {getPutThroughName()}:{" "}
        <FormattedMessage id="category.putThrough.totalSharesTradedPT" />:{" "}
        {numeral(
          sumBy(priceInfoPutThroughList.matchptlistList, "matqty")
        ).format("0,0")}{" "}
        <FormattedMessage id="common.CP" />
        -
        <FormattedMessage id="category.putThrough.totalValuesTradedPT" />:{" "}
        {numeral(
          sumBy(priceInfoPutThroughList.matchptlistList, function (o) {
            return o.matqty * o.matprice;
          })
        )
          .multiply(1000)
          .format("0,0")}{" "}
        <FormattedMessage id="category.putThrough.billionVnd" />
      </Header>
      <Wapper>
        <BidAskTable
          isBid
          putThroughOfferList={priceInfoPutThroughList.buylistList}
        />
        <MatchedTable
          putThroughDealList={priceInfoPutThroughList.matchptlistList}
        />
        <BidAskTable
          putThroughOfferList={priceInfoPutThroughList.selllistList}
        />
      </Wapper>
    </PutThroughWapper>
  );
}
export default memo(PutThrough);
