/* eslint-disable react-hooks/exhaustive-deps */
import { ContentCell, ModalConfirm } from "components/commons";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { ContentContainer } from "components/commons/FormStyle/Table/styles";
import { convertCodeToText } from "helper/utils";
import { statusList } from "modules/transfer-share/constants";
import {
  listDataHistoryTransferShareSelector,
  searchListHistoryParamsSelector,
  statusCurrentPage,
} from "modules/transfer-share/selectors";
import moment from "moment";
import numeral from "numeral";
import { memo, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IconWrapper } from "../styles";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { ITransferSec } from "domain/models/TransferShare";
import {
  deleteTransferSecRequest,
  fetchModelistHistoryTransferShare,
  updateCurrentPage,
} from "modules/transfer-share/redux/listHistory";
import { isEmpty } from "lodash";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { findTransferSecHistory } from "core/http/apis/transfer-share";
import { toast } from "components/commons/Toast";

function Table() {
  const dispatch = useDispatch();
  const data: any = useSelector(listDataHistoryTransferShareSelector);
  const currentPage = useSelector(statusCurrentPage);

  const intl = useIntl();
  // const { account, fromDate, toDate, status, pageNum, pageSize, shareCode } =
  //   useSelector(searchListHistoryParamsSelector);
  const flexStart = "flex-start";
  const flexEnd = "flex-end";
  const center = "center";

  const [enableInfinity, setEnableInfinity] = useState<boolean>(true);

  const clickDeleteHandler = (record: ITransferSec) => {
    if (isEmpty(record)) {
      return;
    }

    dispatch(
      deleteTransferSecRequest({
        alloDate: record?.alloDate || 0,
        refNo: record?.refNo || 0,
        timeStamp: record?.timeStamp || "",
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={center}>
            <FormattedMessage id="transferShare.table.action" />
          </TableHeaderCellContent>
        ),
        minWidth: 20,
        accessor: "id",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={flexStart}>
              {currentRow?.status === 0 && (
                <IconWrapper
                  onClick={() => setVisible({ status: true, item: currentRow })}
                >
                  <IconTrash />
                </IconWrapper>
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={center}>
            <FormattedMessage id="transferShare.table.transactionDate" />
          </TableHeaderCellContent>
        ),
        minWidth: 60,
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.table.accountOut" />
          </TableHeaderCellContent>
        ),
        minWidth: 70,
        accessor: "subAccoNoFrom",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.table.accountIn" />
          </TableHeaderCellContent>
        ),
        minWidth: 70,
        accessor: "subAccoNoTo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={center}>
            <FormattedMessage id="transferShare.table.shareCode" />
          </TableHeaderCellContent>
        ),
        minWidth: 45,
        accessor: "secCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexEnd}>
            <FormattedMessage id="transferShare.table.shareVolume" />
          </TableHeaderCellContent>
        ),
        minWidth: 45,
        accessor: "quantity",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.table.content" />
          </TableHeaderCellContent>
        ),
        minWidth: 185,
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              <ContentCell textOverflow content={props.value} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.table.status" />
          </TableHeaderCellContent>
        ),
        minWidth: 65,
        accessor: "status",
        Cell: (props: any) => {
          let color = "white";
          if (props.value === 0) {
            color = "solidYellow";
          } else if (props.value === 1) {
            color = "solidGreen";
          } else {
            color = "solidRed";
          }
          return (
            <TableCellContent align={flexStart} color={color}>
              {convertCodeToText(statusList(intl), props.value)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.table.updUserId" />
          </TableHeaderCellContent>
        ),
        minWidth: 70,
        accessor: "updUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.table.updDateTime" />
          </TableHeaderCellContent>
        ),
        minWidth: 70,
        accessor: "updDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {moment(props.value).format("DD/MM/YYYY HH:mm")}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const { subAccoNoFrom, fromDate, toDate, secCd, status } = useSelector(
    searchListHistoryParamsSelector
  );

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    list.unshift({
      label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
      value: "",
    });
    return list;
  }, [listAcc, intl]);

  const fetchMoreData = async () => {
    if (data.length < (currentPage + 1) * 30) {
      return setEnableInfinity(false);
    }
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const res: any = await findTransferSecHistory({
      subAccoNoFrom: subAccoNoFrom
        ? subAccoNoFrom
        : parseOptionAccount[0].value,
      secCd: secCd,
      status: status ? +status : undefined,
      subAccoNoTo: null,
      fromDate: +moment(fromDate ? fromDate : firstDay).format("YYYYMMDD"),
      toDate: +moment(toDate ? toDate : date).format("YYYYMMDD"),
      page: currentPage + 1,
      rowPerPage: 30,
    });
    if (res?.statusCode === 0) {
      dispatch(fetchModelistHistoryTransferShare(res?.data));
      dispatch(updateCurrentPage(currentPage + 1));
    } else {
      toast(res?.code, "error", res?.description, true);
    }
  };

  const [filterHeight, setFilterHeight] = useState<number | undefined>();

  useEffect(() => {
    const handleResize = () =>
      setFilterHeight(
        document.getElementById("transfer-share-filter")?.clientHeight || 79
      );
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (currentPage === 0) {
      setEnableInfinity(true);
    }
  }, [currentPage]);

  const [visible, setVisible] = useState<{
    status: boolean;
    item: ITransferSec;
  }>({
    status: false,
    item: {} as ITransferSec,
  });

  return (
    <ContentContainer
      filterHeight={
        filterHeight ||
        document.getElementById("transfer-share-filter")?.clientHeight ||
        79
      }
      id="transferShareHistoryContainer"
      isUseTable={false}
    >
      <CommonTableInfinity
        columns={columns}
        data={data}
        fetchMoreData={() => fetchMoreData()}
        enableInfinity={enableInfinity}
        scrollableTarget={"transferShareHistoryContainer"}
        isHasFooter={false}
      />
      <ModalConfirm
        visibleModalConfirm={visible.status}
        setVisibleModalConfirm={(e: boolean) =>
          setVisible({
            status: e,
            item: {} as ITransferSec,
          })
        }
        setConfirm={() => clickDeleteHandler(visible.item)}
        title={intl.formatMessage({
          id: "transferShare.modalConfirmDelete.title",
        })}
        content={intl.formatMessage(
          {
            id: "transferShare.modalConfirmDelete.content",
          },
          {
            secCd: visible?.item?.secCd,
            volume: numeral(visible?.item?.quantity).format("0,0"),
          }
        )}
      />
    </ContentContainer>
  );
}

export default memo(Table);
