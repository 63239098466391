/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  DetailRow,
  DetailLabel,
  WrapLabel,
  DetailInput,
} from "./style";
import { Button, DatePicker, Select, Input } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import {
  updateFormSubmit,
  updateParams,
  updateStep,
} from "modules/auth/redux/modalRegister";
import { useMemo } from "react";
import { dataButton } from "./../Step_2/constants";
import {
  selectParams,
  selectInformationExtraction,
  selectDataCreateUser,
  selectFormSubmit,
} from "modules/auth/selectorsModalRegister";
import moment from "moment";

const Step5 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataButtons = useMemo(() => dataButton(intl), [intl]);
  const params = useSelector(selectParams);
  const information = useSelector(selectInformationExtraction);
  const dataCreate = useSelector(selectDataCreateUser);
  const formSubmit = useSelector(selectFormSubmit);

  const userEkyc = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "IS_USE_EKYC"
      )
    : {};

  const valueTypeExhibit = useMemo(() => {
    return dataButtons.find((e) => e.value === params.approvalCd);
  }, [dataButtons]);

  // const valuecodeList = useMemo(() => {
  //   return dataCreate?.codeList.find((e: any) => {
  //     const key =
  //       formSubmit.issueLocationCd !== ""
  //         ? formSubmit.issueLocationCd
  //         : information?.issueLocationCd;
  //     return e.codeValue1 === key;
  //   });
  // }, [dataCreate]);

  const valuecodeList = useMemo(() => {
    if (formSubmit.issueLocationCd !== "") {
      return dataCreate?.codeList.find(
        (e: any) => e?.codeValue1 === formSubmit.issueLocationCd
      );
    } else {
      return dataCreate?.codeList.find(
        (e: any) => e?.codeValue1 === information?.issueLocationCd
      );
    }
  }, [dataCreate, information?.issueLocationCd, formSubmit.issueLocationCd]);

  const onChangeForm = (value: any, key: string) => {
    dispatch(updateFormSubmit({ value, key }));
  };

  const dateissues =
    formSubmit.issueDate !== ""
      ? formSubmit.issueDate
        ? moment(formSubmit.issueDate, "DD/MM/YYYY").toDate()
        : null
      : information?.issueDate
      ? moment(information?.issueDate, "DD/MM/YYYY").toDate()
      : null;

  const dateexpire =
    formSubmit.expiredDate !== ""
      ? formSubmit.expiredDate
        ? moment(formSubmit.expiredDate, "DD/MM/YYYY").toDate()
        : null
      : information?.issueDate && params.approvalCd === "1"
      ? moment(information?.issueDate, "DD/MM/YYYY").add(15, "years").toDate()
      : information?.expiredDate
      ? moment(information?.expiredDate, "DD/MM/YYYY").toDate()
      : null;

  const updateFormNext = () => {
    dispatch(
      updateFormSubmit({
        value: moment(dateissues).format("DD/MM/YYYY"),
        key: "issueDate",
      })
    );
    dispatch(
      updateParams({
        idNumber:
          formSubmit.idNumber !== ""
            ? formSubmit.idNumber
            : information?.idNumber,
        issueLocationCd: valuecodeList?.codeValue1,
        issueDate: moment(dateissues).format("DD/MM/YYYY"),
        expiredDate: moment(dateexpire).format("DD/MM/YYYY"),
      })
    );
    dispatch(updateStep(STEPS.STEPS_6));
  };

  const presStep = () => {
    if (userEkyc?.value === "false") {
      dispatch(updateStep(STEPS.STEPS_3));
    } else {
      dispatch(updateStep(STEPS.STEPS_4));
    }
  };

  return (
    <Container>
      <Wrap>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="auth.register.step5.label" />
          </DetailLabel>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5.exhibit.label" />
              </WrapLabel>
              <Select
                options={dataButtons}
                getValue="value"
                getLabel="label"
                value={valueTypeExhibit}
                isDisabled
              />
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5.CMND.label" />
              </WrapLabel>
              <Input
                defaultValue={information?.idNumber}
                onChange={(e: any) =>
                  onChangeForm(e?.target?.value, "idNumber")
                }
              />
            </div>
          </DetailInput>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5.address.label" />
              </WrapLabel>
              <Select
                options={dataCreate?.codeList}
                getValue="code"
                getLabel="codeValue1"
                value={valuecodeList}
                onChange={(e: any) =>
                  onChangeForm(e.codeValue1, "issueLocationCd")
                }
              />
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5.dateRange.label" />
              </WrapLabel>
              <DatePicker
                selected={dateissues}
                onChange={(e: any) => {
                  onChangeForm(e, "issueDate");
                  if (params.approvalCd === "1" && e) {
                    onChangeForm(
                      moment(e, "DD/MM/YYYY").add(15, "years").toDate(),
                      "expiredDate"
                    );
                  }
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </DetailInput>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5.expirationDate.label" />
              </WrapLabel>
              <DatePicker
                selected={dateexpire}
                onChange={(e: any) => onChangeForm(e, "expiredDate")}
                popperPlacement="bottom-start"
              />
            </div>
            <div className="w-50" />
          </DetailInput>
        </DetailRow>
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => presStep()}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter"
          width="120px"
          onClick={() => updateFormNext()}
        >
          {intl.formatMessage({
            id: "auth.register.btnNext",
          })}
        </Button>
      </div>
    </Container>
  );
};

export default Step5;
