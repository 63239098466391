import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(37, 49, 71);
    border-radius: 4px;
    padding: 8px 12px;
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    z-index: 1px;
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export const ContentText = styled.div<{
  textOverflow?: boolean;
}>`
  color: ${({ theme }) => theme.textcolorWhite};

  ${({ textOverflow }) =>
    textOverflow &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
