import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button } from "components/commons";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  HeaderModal,
  ModalTitle,
  Icon,
  ModalCloseButton,
  BodyModal,
  ActionsRow,
  ButtonCell,
} from "./styles";

interface PROPS {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ConfirmModal = (props: PROPS) => {
  const { isOpen, onClose, onSuccess } = props;
  return (
    <Modal
      show={isOpen}
      centered
      backdrop
      keyboard={false}
      onHide={onClose}
      dialogClassName="modal-450"
    >
      <HeaderModal>
        <ModalTitle>
          <FormattedMessage id="transferShare.transferForm.modal.title" />
        </ModalTitle>
        <ModalCloseButton>
          <Icon onClick={onClose}>
            <IconExit />
          </Icon>
        </ModalCloseButton>
      </HeaderModal>
      <BodyModal>
        <FormattedMessage id="transferShare.transferForm.modal.content" />
        <ActionsRow>
          <ButtonCell>
            <Button
              className="button-confirm"
              color="accept"
              onClick={onClose}
              width="100%"
            >
              <FormattedMessage id="transferShare.transferForm.modal.cancel" />
            </Button>
          </ButtonCell>

          <ButtonCell>
            <Button
              className="button-confirm"
              color="gradientaAccept"
              onClick={onSuccess}
              width="100%"
            >
              <FormattedMessage id="transferShare.transferForm.modal.confirm" />
            </Button>
          </ButtonCell>
        </ActionsRow>
      </BodyModal>
    </Modal>
  );
};

export default ConfirmModal;
