import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";

interface State {
  price: number;
  volumn: number;

  changePriceByEnter: boolean;
  changeVolumeByEnter: boolean;
}
const initialState: State = {
  price: 0,
  volumn: 0,

  changePriceByEnter: false,
  changeVolumeByEnter: false,
};
const orderBookSlice = createSlice({
  name: "orderBookSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    onPrice: (state, action) => {
      state.price = action.payload;
    },
    onVolumn: (state, action) => {
      state.volumn = action.payload;
    },

    onChangePriceOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changePriceByEnter = action.payload;
    },
    onChangeVolumeOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changeVolumeByEnter = action.payload;
    },
  },
});

export const {
  onPrice,
  onVolumn,
  onChangePriceOrderBookEditByEnter,
  onChangeVolumeOrderBookEditByEnter,
} = orderBookSlice.actions;
export default orderBookSlice.reducer;
