import React, { useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { ContentText, StyledTooltip } from "./styles";

interface ContentProps {
  content: string;
  textOverflow?: boolean;
}

const ContentCell = ({ content, textOverflow }: ContentProps) => {
  const [isHover, setHover] = useState(false);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <StyledTooltip className={{ "d-none": !isHover }} id="tooltip">
          {content}
        </StyledTooltip>
      }
    >
      <ContentText
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        textOverflow={textOverflow}
      >
        {content}
      </ContentText>
    </OverlayTrigger>
  );
};

export default ContentCell;
