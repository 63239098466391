import { IntlShape } from "react-intl";

export enum ASSETS_TAB {
  ASSETS = "ASSETS",
  ASSETS_INCURRED = "ASSETS_INCURRED",
  ASSETS_SUM = "ASSETS_SUM",
}

export enum ASSETS_INCURRED_TAB {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}

export const listTypeAccount = (intl: IntlShape) => [
  {
    value: "0",
    label: "Tài khoản",
  },
  {
    value: "1",
    label: "Tiểu khoản",
  },
];
