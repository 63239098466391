import styled from "styled-components/macro";

export const DetailWrapper = styled.div`
  display: flex;
  padding: 16px;
  height: 100%;
  overflow-x: auto;
  flex-direction: column;
  color: ${({ theme }) => theme.grey0};
`;

export const DetailCol = styled.div`
  padding: 16px 0;
`;
export const DetailColFlexEnd = styled.div`
  margin-top: auto;
  display: flex;
  gap: 12px;
`;
