import { FC } from "react";
import { ChangePricePercent as ChangePricePercentWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { RootState } from "redux/store";
import { WatchListState } from "modules/stock-order/redux/watchList";
import { createSelector } from "@reduxjs/toolkit";
// import { convertDictionaryToArray } from "helper/utils";
import { useSelector } from "react-redux";
import numeral from "numeral";

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const changePricePercentageSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.secdetailinfo?.changepercent
  );
};

const textColorSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.colorcode
  );
};

interface Props {
  ticker: string;
}

const ChangePricePercentCell: FC<Props> = ({ ticker }) => {
  const changePricePercentage =
    useSelector(changePricePercentageSelector(ticker)) || 0;
  const textColor = useSelector(textColorSelector(ticker));

  return (
    <ChangePricePercentWrapper textcolor={textColor}>
      <BlinkableCell
        value={numeral(changePricePercentage).format("+0,0.00")}
        unit={"%"}
      />
    </ChangePricePercentWrapper>
  );
};

export { ChangePricePercentCell };
