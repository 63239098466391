import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import MarginSecuritiesPortfolio from "./pages";

const routes: RouterConfig[] = [
  {
    page: MarginSecuritiesPortfolio,
    path: paths.marginSecuritiesPortfolio,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
