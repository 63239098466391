import { MarketWatchWrapper } from "./styles";
import Tabs from "./Tabs";
import Content from "./Content";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { changeActiveTab } from "modules/market/redux/marketWatch";
import { MarketWatchTab } from "./types";
import { memo } from "react";

const marketWatchSelector = (state: RootState) => state.market.marketWatch.root;
function MarketWatch() {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(marketWatchSelector);

  const handleChangeTab = (tab: MarketWatchTab) => {
    dispatch(changeActiveTab(tab));
  };

  return (
    <MarketWatchWrapper>
      <Tabs activeTab={activeTab} onChangeTabMarketWatch={handleChangeTab} />
      <Content activeTab={activeTab} />
    </MarketWatchWrapper>
  );
}
export default memo(MarketWatch);
