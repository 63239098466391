import styled from "styled-components/macro";
import { Modal } from "react-bootstrap";

export const ModalContainer = styled(Modal)`
  .modal-title {
    cursor: move;
    width: 100%;
  }
  .modal-wrapper {
    background-color: ${({ theme }) => theme.grey70};
    border-radius: 8px;
    height: 1000px;
    max-height: calc(100vh - 100px);
    margin: 50px;
    min-width: calc(100% - 100px);

    .modal-content {
      height: 100%;
    }

    &.react-draggable-dragging {
      & .modal-header {
        cursor: move;
      }
    }
  }

  &.show {
    display: flex !important;
    align-items: center;
  }

  .modal-content {
    background-color: ${({ theme }) => theme.background2};
    .modal-header {
      background-color: ${({ theme }) => theme.background3};
      height: 40px;
      box-shadow: ${({ theme }) => theme.shadowLineDown};
      align-items: center;
      padding: 0 !important;
      font-size: ${({ theme }) => theme.typeFaces.headline.size};
      line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
      color: ${({ theme }) => theme.textcolorWhite};
      position: relative;
    }
    .custom-modal-header {
      background-color: ${({ theme }) => theme.grey70};
      border-radius: 8px;
      padding-left: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      & > div {
        font-size: ${({ theme }) => theme.typeFaces.caption12.size};
        font-weight: ${({ theme }) =>
          theme.typeFaces.caption12.weight.semiBold};
        line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
        color: ${({ theme }) => theme.textcolorWhite};
      }
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
