import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const bidAskBondSelector = (state: RootState) => state.bidAskBond;

export const activeTabSelector = createSelector(
  bidAskBondSelector,
  (state) => state.activeTab
);

export const listBondOrderSelector = createSelector(
  bidAskBondSelector,
  (state) => state.list
);
export const listBondSelector = createSelector(
  bidAskBondSelector,
  (state) => state.listBond
);

export const loadingSearchDataSelector = createSelector(
  bidAskBondSelector,
  (state) => state.loading
);

export const filterBondOrderSelector = createSelector(
  bidAskBondSelector,
  (state) => state.filter
);

export const itemDetailSelector = createSelector(
  bidAskBondSelector,
  (state) => state.itemDetail
);

export const isShowConfirmModalSelector = createSelector(
  bidAskBondSelector,
  (state) => state.isShowConfirmModal
);
export const statusEnterSelector = createSelector(
  bidAskBondSelector,
  (state) => state.statusEnter
);

export const statusRejectSelector = createSelector(
  bidAskBondSelector,
  (state) => state.statusReject
);
export const listCbotBondPriceEstSelector = createSelector(
  bidAskBondSelector,
  (state) => state.listCbotBondPriceEst
);
