import { Spacer } from "components/commons";
import parseInt from "lodash/parseInt";
import { findDertOrderDetail } from "modules/stock-order-der/redux/OrderBookDer";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  PopConfirmOrderBody,
  PopConfirmOrderContainer,
  PopConfirmOrderTitle,
  TextColor,
  StatusCell,
  TitleContent,
  ButtonExit,
  RejectReason,
} from "../styles";
import DetailOrderDerTable from "./DetailOrderDerTable";
import themes from "themes/abstracts/_themes";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { themeTypeSelector } from "themes/redux";
import { IFindDertOrderDetailParams } from "core/http/apis/tradeinfo/types";

interface Props {
  showModal: boolean;
  setShowModal: any;
  dataRow?: any;
}

const OrderDetailModal: FC<Props> = ({ showModal, setShowModal, dataRow }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const themeType = useSelector(themeTypeSelector);

  const detailClone = useMemo(() => {
    return {
      ...dataRow?.original,
    };
  }, [dataRow, intl]);

  const onLoadData = async () => {
    try {
      if (dataRow?.original?.orderNo) {
        const params: IFindDertOrderDetailParams = {
          orgOrderNo: dataRow?.original?.orgOrderNo,
          orderNo: dataRow?.original?.orderNo,
          tradeDate: dataRow?.original?.tradeDate?.toString(),
        };

        dispatch(findDertOrderDetail(params));
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    onLoadData();
  }, [dataRow]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="modal-437"
      >
        <PopConfirmOrderContainer>
          <PopConfirmOrderTitle>
            <div className="flex-1"></div>
            <TitleContent side={detailClone?.tradeTypeDisplay}>
              <FormattedMessage id="OrderBookDerDetail.title" />
              &nbsp;
              {detailClone &&
                intl
                  .formatMessage({ id: detailClone?.tradeTypeDisplay })
                  .toUpperCase()}
              &nbsp;
              {detailClone?.secCd}
            </TitleContent>
            <ButtonExit>
              <IconExit onClick={() => setShowModal(false)} />
            </ButtonExit>
          </PopConfirmOrderTitle>
          <PopConfirmOrderBody className="pl-0 pr-0">
            <div className="order-info pl-3 pr-3">
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.tradeNumber" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone && detailClone.orderNo
                    ? detailClone.orderNo
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.account" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone?.subAccoNo}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.status" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  <StatusCell
                    textColor={
                      detailClone?.orderStatusColor === "reject"
                        ? themes[themeType].solidRed
                        : detailClone?.orderStatusColor === "success"
                        ? themes[themeType].solidGreen
                        : detailClone?.orderStatusColor === "pending"
                        ? themes[themeType].solidBlue
                        : themes[themeType].solidBlue
                    }
                  >
                    {detailClone &&
                      intl.formatMessage({
                        id: detailClone?.orderStatusDisplay,
                      })}
                  </StatusCell>
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.tradeType" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  <TextColor
                    color={detailClone?.tradeType === 2 ? "green" : "red"}
                  >
                    {detailClone ? detailClone.ordType : "---"}
                  </TextColor>
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.orderVolumn" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone ? detailClone.ordQty : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.orderPrice" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone ? detailClone.orderPriceDisp : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.matchingVolume" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone
                    ? numeral(detailClone.matQty).format("0,0")
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.matchingValue" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone?.avgPrice && detailClone.avgPrice > 0
                    ? numeral(detailClone.avgPrice).format("0,0")
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.pubQty" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone
                    ? numeral(detailClone.pubQty).format("0,0")
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.pubPrice" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone?.pubPrice && detailClone.pubPrice > 0
                    ? numeral(detailClone.pubPrice).format("0,0")
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.orderTime" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone
                    ? moment(
                        new Date(parseInt(detailClone.reqTime?.toString()))
                      ).format("DD/MM/YYYY HH:mm:ss")
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left label">
                  <FormattedMessage id="OrderBookDerDetail.chanel" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone ? detailClone.channelDisplay : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              {detailClone?.notes && (
                <Row>
                  <Col xs={4}>
                    {intl.formatMessage({
                      id: "widgets.orderBookDetail.rejectReason",
                    })}
                  </Col>
                  <Col xs={8} className="text-right text-color-light">
                    <RejectReason>
                      {detailClone?.notes ? (
                        <FormattedMessage
                          id={"error." + detailClone?.notes.split(":")[0]}
                        />
                      ) : (
                        "---"
                      )}
                    </RejectReason>
                  </Col>
                </Row>
              )}
            </div>

            <div className="footer">
              <DetailOrderDerTable />
            </div>
          </PopConfirmOrderBody>
        </PopConfirmOrderContainer>
      </Modal>
    </>
  );
};
export default OrderDetailModal;
