import FormAdd from "./formAdd";

const ManageContentLeft = () => {
  return (
    <>
      <FormAdd />
    </>
  );
};

export default ManageContentLeft;
