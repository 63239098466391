import styled from "styled-components";

export const Container = styled.div`
  padding: 16px 24px;
  box-shadow: ${({ theme }) => theme.shadowInnerDivider};
`;

export const ContentTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: ${({ theme }) => theme.shadowInnerDivider};
`;

export const ContentBottom = styled.div<{
  isVisibleSideCard: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectBox = styled.div`
  width: 170px;
  height: 32px;
  display: flex;
  flex: none;
  margin-right: 12px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.grey20};

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  margin-right: 8px;
  width: 72px;
  display: flex;
  flex: none;
`;
export const LabelRight = styled.div`
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  display: flex;
  flex: none;
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.brandPrimary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 100px;
  height: 32px;
  background-color: ${({ theme }) => theme.background3};

  border: 1px solid ${({ theme }) => theme.brandPrimary};
  border-radius: 4px;
`;

export const BtnUpdate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 12px;
`;
