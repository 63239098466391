import VgaiaHttp from "core/http/singleton/vgaia";
import { ISessionModelResponse } from "domain/models/SessionModel";
import { getInitBrokerApi } from "./urls";

export const getInitBroker = async (): Promise<ISessionModelResponse> => {
  const res = await VgaiaHttp.post<ISessionModelResponse>(getInitBrokerApi);

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
