// import { ReactComponent as IconResetWhite } from "assets/image/svg/ic_reset_white.svg";
import {
  Spacer,
  VerticalDivider,
  Button,
  // Select,
  OTPByBusinessCd,
  SelectPlaceOrder,
  ModalConfirm,
} from "components/commons";
import InputPrice from "components/commons/InputPrice";
import InputVolume from "components/commons/InputVolume";
import {
  EMarketCodeNew,
  UNIT_NUMBER,
  VolumeLot,
  EBusinessCd,
  SecType,
  GetAccountType,
  // EOTPType,
  // EAccountType,
} from "helper/consts";
import {
  // checkPlaceOrder,
  checkPriceStep,
  checkVolumeLot,
  getSuggestionOrderTypes,
} from "helper/tradingHelper";
import {
  onConditionalOrder,
  getAvailableTrade,
  onBuySellType,
  onFilter,
  onUpdateVolumn,
  requestPostSingleOrder,
  postSingleOrderClear,
  onClearDataCondition,
  requestPostOrderCondition,
  setErrorValidateOtpVal,
  setErrorValidateLimitOffset,
  setErrorValidateMinBuyPrice,
  setErrorValidateMaxSellPrice,
  getOrderLimitedQty,
  prepareSubmitOrder,
  requestPostSingleOrderPending,
  getCustomerRestriction,
  validateCustomerInsider,
  findPortfolio,
  getPriceWithStepPrice,
} from "modules/stock-order/redux/placeOrder";
import numeral from "numeral";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  // IntlShape,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import AccountSelect from "./AccountSelect";
import ConditionCommandForm from "./ConditionCommandForm";
import ModalSubmitOrder from "./ModalSubmitOrder";
import {
  // BuySellContainer,
  Container,
  Content,
  FormControl,
  FormTitle,
  Label,
  // IconReset,
  RowButton,
  BuySellButtonRow,
  BuyButton,
  SellButton,
  ButtonItem,
  LabelBuySell,
} from "./styles";
import TickerSearch from "./TickerSearch";
import {
  isAuthenticatedSelector,
  currentAccountSelector,
  foreignTypeSelector,
  tradeDateSelector,
} from "modules/auth/selectors";
import Storage from "helper/storage";
import {
  IFindPortfolioParams,
  IGetCustomerRestrictionParams,
  IGetPriceWithStepPriceParams,
  IPlaceOrdersConditionParams,
  IPrepareSubmitOrderParam,
  IValidateCustomerInsiderParams,
  PlaceOrdersParam,
  PlaceOrdersPendingParam,
} from "core/http/apis/orderexec/types";
import { toast } from "components/commons/Toast";
import {
  onChangePriceByEnter,
  onChangeTickerByEnter,
  onChangeVolumeByEnter,
} from "modules/stock-order/redux";
import GlobalData from "helper/globalData";
import { listHotKeysSelector } from "themes/redux";
import { TimeDelayCancelOrder } from "AppConfig";
import { onReloadPlaceOrder } from "modules/order-book/redux/index";
import { isEditCancelOrderSelector } from "modules/order-book/selectors";
import {
  rootStockOrderSelector,
  placeOrderSelector,
} from "modules/stock-order/selectors";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "components/commons/CommonTable/styles";
import { GetStockPriceInterface } from "domain/models/SecuritiesStatusInterface";
import { getStockPrice } from "core/http/apis/tradeinfo";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
// import {
//   changeIsRegisterAction,
//   requestChangeVisibleModalTradingAccount,
// } from "modules/auth/redux/tradingAccount";
import ModalConfirmRegisterOtp from "./ModalConfirmRegisterOtp";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { ForeignRoom, INDEX_STATEMap } from "domain/protoNew/auto_trading_pb";
import {
  fetchOrganizationFundamental,
  updateForeignRoom,
} from "modules/tickerDetails/redux/tickerInfo";
// import { currentRoomSelector } from "modules/tickerDetails/redux/selectors";
import moment from "moment";
import { valueHeaderCardSelector } from "modules/system/selectors";
import { getMarketSessionCd } from "helper/utils";
import { GetParameters } from "helper/sessionData";
import { toggleModalLogin } from "modules/auth/redux";
import storage from "helper/storage";
import { RealtimeConst } from "helper/constFirebase";

const regPrice = /^\d+(\.\d{0,9})?$/;
const regVolumn = /^\d+$/;
const blockTickers = ["HALT", "HALT_AT", "HAL_PT"];

const getConditionalOrderList = (intl: IntlShape) => {
  return [
    {
      value: "01",
      label: intl.formatMessage({
        id: "widgets.placeOrder.conditionalOrder.normal",
      }),
    },
    {
      value: "TCO",
      label: intl.formatMessage({
        id: "widgets.placeOrder.conditionalOrder.timeConditionOrder",
      }),
    },
    {
      value: "TS",
      label: intl.formatMessage({
        id: "widgets.placeOrder.conditionalOrder.trailingStopOrder",
      }),
    },
    {
      value: "PRO",
      label: intl.formatMessage({
        id: "widgets.placeOrder.conditionalOrder.priorityOrder",
      }),
    },
    {
      value: "SP",
      label: intl.formatMessage({
        id: "widgets.placeOrder.conditionalOrder.takeProfit",
      }),
    },
    {
      value: "CL",
      label: intl.formatMessage({
        id: "widgets.placeOrder.conditionalOrder.cutLoss",
      }),
    },
  ];
};

const PlaceOrder: React.FC = memo(() => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const conditionalOrderList = useMemo(
    () => getConditionalOrderList(intl),
    [intl]
  );
  const refInputPrice = useRef<HTMLInputElement>(null);
  const refInputVolume = useRef<HTMLInputElement>(null);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const masterAccount = Storage.getMasterAccount();
  // const userAuthenList = Storage.getUserAuthenList();
  const currentAccount = useSelector(currentAccountSelector);
  const foreignType = useSelector(foreignTypeSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const isReloadPlaceOrder = useSelector(isEditCancelOrderSelector);
  const ordTradeTypeDefault = GetParameters("OD00", "ORD_TRADE_TYPE_DEFAULT");
  const ordReturnToDefault = GetParameters("OD00", "ORD_RETURN_TO_DEFAULT");
  // const currentRoom = useSelector(currentRoomSelector);

  const {
    currentTicker,
    floorPrice,
    ceilingPrice,
    currentRoom,
    secType,
    curentTickerStatus,
    tradingSessionId,
    changeTickerByEnter,
    changePriceByEnter,
  } = useSelector(rootStockOrderSelector);

  const currentTradingSession = useSelector(
    valueHeaderCardSelector(tradingSessionId?.toString(), "state")
  ) as INDEX_STATEMap[keyof INDEX_STATEMap];

  const {
    dataCash,
    orderLimitedQty,
    statusPrepare,
    statusCustomerRestriction,
    statusCustomerInsider,
    errorPrepare,
    prepareSubmitOrderReponse,
    isCustomerRestriction,
    customerInsiderData,
    filters: { price },
    buySellType,
    volumn,
    listOrderQtySuccess,
    singleOrderStatus,
    loadingPlaceOrder,
    errorSingleOrder,
    conditionalOrder,
    optVal,
    optType,
    matMethod,
    expiredDate,
    limitOffset,
    optValType,
    avgPrice,
    condition,
    minBuyPrice,
    maxSellPrice,
  } = useSelector(placeOrderSelector);

  const [errorSession, setErrorSession] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const [errorTicker, setErrorTicker] = useState("");
  const [errorTradeType, setErrorTradeType] = useState("");
  const [errorAccount, setErrorAccount] = useState("");
  const [errorVolumn, setErrorVolumn] = useState("");
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [contentConfirm, setContentConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleSubmitOrder, setVisibleSubmitOrder] = useState(false);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [visibleSubmitOrderModal, setVisibleSubmitOrderModal] = useState(false);
  // const [isVisibleModalRegisterOtp, setIsVisibleModalRegisterOtp] =
  //   useState(false);
  const [visibleModalConfirmRegisterOtp, setVisibleModalConfirmRegisterOtp] =
    useState(false);

  const listHotKeyStorage = useSelector(listHotKeysSelector);

  const validateTicker = () => {
    setErrorTicker("");

    if (!currentTicker || currentTicker === "") {
      setErrorTicker(intl.formatMessage({ id: "validation.emptyTicker" }));
      return false;
    }

    if (blockTickers.indexOf(curentTickerStatus) > -1) {
      setErrorTicker(
        intl.formatMessage({ id: "validation.restrictedTrading" })
      );
      return false;
    }

    return true;
  };

  const validatePrice = async (symbolData: GetStockPriceInterface) => {
    setErrorPrice("");

    // Check giá
    if (conditionalOrder.value === "01" || conditionalOrder.value === "TCO") {
      if (!price) {
        setErrorPrice(intl.formatMessage({ id: "validation.emptyPrice" }));
        return false;
      }
    }

    // Check giá nhập dạng text có trong danh sách gợi ý giá
    if (conditionalOrder.value === "01") {
      if (!regPrice.test(price as string)) {
        const listPriceSuggestions = getPriceSuggestions();
        const priceSuggestion = listPriceSuggestions.find(
          (item) => item === price
        );
        if (!priceSuggestion) {
          onChangePrice("");
          setErrorPrice(
            intl.formatMessage({ id: "widgets.placeOrder.suggestionFail" })
          );
          return false;
        }
      }
    }

    // Check giá phải nằm trong khoảng từ Sàn đến Trần
    if (conditionalOrder.value === "01") {
      if (
        regPrice.test(price) &&
        (price < floorPrice || price > ceilingPrice)
      ) {
        setErrorPrice(intl.formatMessage({ id: "validation.rangePrice" }));
        return false;
      }
    }

    if (conditionalOrder.value === "TCO") {
      if (!regPrice.test(price)) {
        toast("widgets.placeOrder.conditionalOrder.errorPriceTCOLO", "error");
        return false;
      }
    }

    // Bước giá
    if (conditionalOrder.value === "01" || conditionalOrder.value === "TCO") {
      if (regPrice.test(price)) {
        const resultPrice: number = Number(price * UNIT_NUMBER.ONE_THOUSAND);
        const equityPriceToCheck =
          Math.round((resultPrice + Number.EPSILON) * 100) / 100;
        const error = await checkPriceStep(
          symbolData,
          equityPriceToCheck,
          intl
        );
        if (error) {
          const errorMsg = error;

          setErrorPrice(errorMsg);
          return false;
        }
      }
    }

    return true;
  };

  const validateVolume = async (symbolData: GetStockPriceInterface) => {
    setErrorVolumn("");

    if (!volumn) {
      setErrorVolumn(intl.formatMessage({ id: "validation.emptyVolume" }));
      return false;
    }

    const volumeVal = volumn.replaceAll(",", "");

    if (volumeVal > (orderLimitedQty ?? 0)) {
      if (buySellType === "buy") {
        // KL mua phải nhỏ hơn KL tối đa có thể mua
        setErrorVolumn(intl.formatMessage({ id: "validation.maxVolumeBuy" }));
        return false;
      } else if (buySellType === "sell") {
        // KL phải nhỏ hơn KL tối đa có thể bán
        setErrorVolumn(intl.formatMessage({ id: "validation.maxVolumeSell" }));
        return false;
      }
    }

    // Tròn lô
    const error = await checkVolumeLot(symbolData, volumeVal, intl);
    if (error) {
      const errorMsg = error;

      setErrorVolumn(errorMsg);
      return false;
    }

    // Lô lẻ  Chỉ được đặt LO
    const isOddLot: boolean = volumeVal < 100;
    if (isOddLot) {
      if (isNaN(price)) {
        setErrorVolumn(
          intl.formatMessage({
            id: "tradingHelper.invalidMarketState12",
          })
        );
        return false;
      }
    }

    return true;
  };

  //Validate otp val
  const validateOtpVal = async () => {
    dispatch(setErrorValidateOtpVal(""));

    if (!optVal) {
      dispatch(
        setErrorValidateOtpVal(
          intl.formatMessage({ id: "validation.emptyOtpVal" })
        )
      );
      return false;
    }
    return true;
  };

  //Validate limit offset
  const validateLimitOffset = async () => {
    dispatch(setErrorValidateLimitOffset(""));

    if (!limitOffset) {
      dispatch(
        setErrorValidateLimitOffset(
          intl.formatMessage({ id: "validation.emptyLimitOffset" })
        )
      );
      return false;
    }
    return true;
  };

  //Validate min buy price
  const validateMinBuyPrice = async () => {
    dispatch(setErrorValidateMinBuyPrice(""));

    if (!minBuyPrice) {
      dispatch(
        setErrorValidateMinBuyPrice(
          intl.formatMessage({ id: "validation.emptyMinBuyPrice" })
        )
      );
      return false;
    }
    return true;
  };

  //Validate max sell price
  const validateMaxSellPrice = async () => {
    dispatch(setErrorValidateMaxSellPrice(""));

    if (!maxSellPrice) {
      dispatch(
        setErrorValidateMaxSellPrice(
          intl.formatMessage({ id: "validation.emptyMaxSellPrice" })
        )
      );
      return false;
    }
    return true;
  };

  const validate = async () => {
    // 1 - Check Mã CK
    if (!validateTicker()) {
      return false;
    }

    // 2 - Check loại giao dịch
    if (buySellType !== "buy" && buySellType !== "sell") {
      setErrorTradeType(
        intl.formatMessage({ id: "validation.emptyTradeType" })
      );
      return false;
    }

    // 3 - Check tài khoản
    if (!currentAccount?.subAccoNo) {
      setErrorAccount(intl.formatMessage({ id: "validation.emptyAccount" }));

      //TODO - Open Login Dialog

      return false;
    }

    // Call api lay Symbol Data
    const symbolData = await getStockPrice(currentTicker);

    if (symbolData.statusCode !== 0) {
      toast(
        symbolData?.errorCode ? "error." + symbolData?.errorCode : "",
        "error",
        symbolData.message,
        true
      );
      return false;
    }

    // 3 - Check giá đặt
    const isValidatePrice = await validatePrice(symbolData.data);
    if (!isValidatePrice) {
      return false;
    }

    // 4 - Check khối lượng
    const isValidateVolume = await validateVolume(symbolData.data);
    if (!isValidateVolume) {
      return false;
    }

    // 5 - Check otp val
    if (
      (conditionalOrder.value === "TCO" && condition === "02") ||
      conditionalOrder.value === "TS" ||
      conditionalOrder.value === "SP" ||
      conditionalOrder.value === "CP"
    ) {
      const isValidateOtpVal = await validateOtpVal();
      if (!isValidateOtpVal) {
        return false;
      }
    }

    // 6 - Check limit offset
    if (
      conditionalOrder.value === "TS" ||
      conditionalOrder.value === "SP" ||
      conditionalOrder.value === "CL"
    ) {
      const isValidateLimitOffset = await validateLimitOffset();
      if (!isValidateLimitOffset) {
        return false;
      }
    }

    // 6 - Check minBuy/maxSell price
    if (conditionalOrder.value === "TS") {
      if (buySellType === "buy") {
        const isVvalidateMinBuyPrice = await validateMinBuyPrice();
        if (!isVvalidateMinBuyPrice) {
          return false;
        }
      } else {
        const isVvalidateMaxSellPrice = await validateMaxSellPrice();
        if (!isVvalidateMaxSellPrice) {
          return false;
        }
      }
    }

    // Clear message
    setErrorTicker("");
    setErrorAccount("");
    setErrorPrice("");
    setErrorVolumn("");
    setErrorSession("");
    dispatch(setErrorValidateOtpVal(""));
    dispatch(setErrorValidateLimitOffset(""));
    dispatch(setErrorValidateMinBuyPrice(""));
    dispatch(setErrorValidateMaxSellPrice(""));

    return true;
  };

  // Dispatch switch button mua/bán
  const onChangeType = (type: string) => () => {
    if (conditionalOrder.value === "CL" || conditionalOrder.value === "SP") {
      if (type === "buy") {
        if (conditionalOrder.value === "CL") {
          toast(
            "widgets.placeOrder.conditionalOrder.errorPlaceSellOrderCL",
            "error"
          );
        }

        if (conditionalOrder.value === "SP") {
          toast(
            "widgets.placeOrder.conditionalOrder.errorPlaceSellOrderSP",
            "error"
          );
        }

        return;
      }
    }
    dispatch(onBuySellType(type));
  };

  // Dispatch Giá
  const onChangePrice = (price: string | number, type?: string) => {
    setErrorPrice("");
    dispatch(onFilter({ price }));
  };

  // Dispatch khối lượng
  const onChangeVolumn = (volume: number | string, type?: string) => {
    setErrorVolumn("");
    if (volume) {
      dispatch(onUpdateVolumn({ volumn: numeral(volume).format("0,000") }));
    } else {
      dispatch(onUpdateVolumn({ volumn: "" }));
    }
  };

  // Dispatch lệnh điều kiện
  const onChangeConditionalOrder = (
    conditionalOrder: { label: string; value: string } | null
  ) => {
    dispatch(onConditionalOrder(conditionalOrder));
    dispatch(onClearDataCondition());
  };

  useEffect(() => {
    if (
      conditionalOrder &&
      (conditionalOrder.value === "SP" || conditionalOrder.value === "CL")
    ) {
      dispatch(onBuySellType("sell"));
      // Lấy giá bình quân
      const paramsGetAvgPrice: IFindPortfolioParams = {
        custNo: "",
        subAccoNo: currentAccount?.subAccoNo,
        alloDate: Number(tradeDate),
        secCd: currentTicker,
        page: 0,
        rowPerPage: 30,
      };
      dispatch(findPortfolio(paramsGetAvgPrice));
    }
  }, [currentTicker, conditionalOrder, currentAccount]);

  // useEffect(() => {
  //   if (userAuthenList) {
  //     const otpTypeOrder = userAuthenList.find(
  //       (item) => item.businessCd === EBusinessCd.ORDER
  //     );
  //     if (!otpTypeOrder || otpTypeOrder?.otpType === EOTPType.NONE) {
  //       setIsVisibleModalRegisterOtp(true);
  //     } else {
  //       setIsVisibleModalRegisterOtp(false);
  //     }
  //   }
  // }, [userAuthenList]);

  // Submit nút đặt lệnh
  const onSubmit = async () => {
    setLoading(true);
    if (!isAuthenticated) {
      setLoading(false);
      dispatch(toggleModalLogin(true));
      return;
    }

    if (!masterAccount || masterAccount === "") {
      setLoading(false);
      Storage.logOut();
    }

    // if (isVisibleModalRegisterOtp) {
    //   setVisibleModalConfirmRegisterOtp(true);
    //   setLoading(false);
    //   return;
    // }

    if (conditionalOrder.value === "CL" || conditionalOrder.value === "SP") {
      if (buySellType === "buy") {
        toast(
          "widgets.placeOrder.conditionalOrder.errorPlaceSellOrderSPCL",
          "error"
        );
        dispatch(
          onFilter({
            price: price,
          })
        );
        setLoading(false);
        return;
      }
    }

    const isValidate = await validate();

    if (isValidate) {
      if (conditionalOrder.value === "01") {
        const resultPrice: number = Number(price);
        const equityPriceToCheck =
          Math.round((resultPrice + Number.EPSILON) * 100) / 100;

        const prepareParams: IPrepareSubmitOrderParam = {
          subAccoNo: currentAccount?.subAccoNo,
          tradeType: buySellType === "buy" ? 2 : 1,
          secCd: currentTicker,
          marketCd: tradingSessionId,
          order_type: !isNaN(equityPriceToCheck) ? "LO" : price,
          order_price: !isNaN(equityPriceToCheck)
            ? equityPriceToCheck
            : buySellType === "buy"
            ? ceilingPrice
            : floorPrice,
          order_qty: volumn?.replaceAll(",", ""),
          marginRate: 0,
          split_type: 0,
          split_num: 1,
          mortContractNo: "",
          interval: "0",
          srcChannel: 2,
          resolvedFlag: 0,
          sideType: 0,
        };
        dispatch(prepareSubmitOrder(prepareParams));
      } else {
        if (
          conditionalOrder.value === "SP" ||
          conditionalOrder.value === "CL"
        ) {
          // Tính giá đặt
          const paramsGetPrice: IGetPriceWithStepPriceParams = {
            secCd: currentTicker,
            price: getPrice(conditionalOrder.value, optValType),
          };

          dispatch(getPriceWithStepPrice(paramsGetPrice));
        }

        const paramsRestriction: IGetCustomerRestrictionParams = {
          subAccoCd: currentAccount?.subAccoCd,
          subAccoNo: currentAccount?.subAccoNo,
          restrictionCd: buySellType === "buy" ? 1 : 2,
        };

        dispatch(getCustomerRestriction(paramsRestriction));
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (statusPrepare?.status === "success") {
      dispatch(postSingleOrderClear());
      // check step error prepare
      if (!prepareSubmitOrderReponse) {
        const codeError = errorPrepare?.code;
        if (codeError) {
          const msg = `${errorPrepare?.code?.toString().replaceAll("-", "_")}`;
          const msgFinal = msg
            ? "error." + msg
            : "widgets.placeOrder.orderError";
          toast(msgFinal, "error");
        } else {
          toast("error.defaultErr", "error");
        }
        setLoading(false);
        return;
      }

      // check step error accout blockage
      if (prepareSubmitOrderReponse?.restrictionLevel !== 0) {
        toast("widgets.placeOrder.accountBlockage", "error");
        setLoading(false);
        return;
      }

      // check big Shareholder, local Shareholder
      if (prepareSubmitOrderReponse?.customerInsiderData) {
        // check bị chạm tỷ lệ chào mua công khai
        if (prepareSubmitOrderReponse?.customerInsiderData?.maxRateWarning) {
          setContentConfirm(
            intl.formatMessage(
              { id: "widgets.placeOrder.maxRateWarning" },
              {
                maxRate:
                  prepareSubmitOrderReponse?.customerInsiderData?.maxRate,
              }
            )
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        // check bị cảnh báo cổ dông lớn
        if (prepareSubmitOrderReponse?.customerInsiderData?.bigShareholder) {
          setContentConfirm(
            intl.formatMessage({ id: "widgets.placeOrder.bigShareholder" })
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        // check bị cảnh báo cổ đông nội bộ
        if (
          prepareSubmitOrderReponse?.customerInsiderData?.insiderShareholder
        ) {
          setContentConfirm(
            intl.formatMessage({ id: "widgets.placeOrder.insiderShareholder" })
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        // check bị cảnh báo liên quan đợt mua bán tự doanh
        if (
          prepareSubmitOrderReponse?.customerInsiderData?.privateTradingBond &&
          prepareSubmitOrderReponse?.customerInsiderData?.privateRemainQty &&
          prepareSubmitOrderReponse?.customerInsiderData?.privateRemainQty > 0
        ) {
          setContentConfirm(
            intl.formatMessage(
              { id: "widgets.placeOrder.privateTradingBond" },
              {
                privateRemainQty:
                  prepareSubmitOrderReponse?.customerInsiderData
                    ?.privateRemainQty,
              }
            )
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        if (prepareSubmitOrderReponse?.dataType !== 1) {
          setContentConfirm(
            intl.formatMessage({ id: "widgets.placeOrder.submitOrderPending" })
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        handlePlaceOrder();
      } else {
        handlePlaceOrder();
      }
    }

    if (statusPrepare?.status === "failed") {
      const codeError = errorPrepare?.code;
      if (codeError) {
        const msg = `${errorPrepare?.code?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg ? "error." + msg : "widgets.placeOrder.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }
      dispatch(postSingleOrderClear());
      setLoading(false);
    }
  }, [statusPrepare, prepareSubmitOrderReponse, errorPrepare]);

  useEffect(() => {
    if (statusCustomerRestriction === "SUCCESSFUL") {
      dispatch(postSingleOrderClear());
      // check step error accout blockage
      if (!isCustomerRestriction) {
        toast("widgets.placeOrder.accountBlockage", "error");
        setLoading(false);
        return;
      } else {
        const resultPrice: number = Number(price);
        const equityPriceToCheck =
          Math.round((resultPrice + Number.EPSILON) * 100) / 100;

        const paramsInsider: IValidateCustomerInsiderParams = {
          subAccoNo: currentAccount?.subAccoNo,
          secCd: currentTicker,
          tradeType: buySellType === "buy" ? 2 : 1,
          ordQty: volumn?.replaceAll(",", ""),
          ordPrice: !isNaN(equityPriceToCheck)
            ? equityPriceToCheck
            : buySellType === "buy"
            ? ceilingPrice
            : floorPrice,
        };

        dispatch(validateCustomerInsider(paramsInsider));
      }
    }

    if (statusCustomerRestriction === "FAILURE") {
      const codeError = errorPrepare?.code;
      if (codeError) {
        const msg = `${errorPrepare?.code?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg ? "error." + msg : "widgets.placeOrder.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }
      dispatch(postSingleOrderClear());
      setLoading(false);
    }
  }, [statusCustomerRestriction, isCustomerRestriction]);

  useEffect(() => {
    if (statusCustomerInsider === "SUCCESSFUL") {
      dispatch(postSingleOrderClear());
      // check big Shareholder, local Shareholder
      if (customerInsiderData) {
        // check bị chạm tỷ lệ chào mua công khai
        if (customerInsiderData?.isMaxRateWarning) {
          setContentConfirm(
            intl.formatMessage(
              { id: "widgets.placeOrder.maxRateWarning" },
              {
                maxRate: customerInsiderData?.maxRate,
              }
            )
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        // check bị cảnh báo cổ dông lớn
        if (customerInsiderData?.isBigShareholder) {
          setContentConfirm(
            intl.formatMessage({ id: "widgets.placeOrder.bigShareholder" })
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        // check bị cảnh báo cổ đông nội bộ
        if (customerInsiderData?.isInsiderShareholder) {
          setContentConfirm(
            intl.formatMessage({ id: "widgets.placeOrder.insiderShareholder" })
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        // check bị cảnh báo liên quan đợt mua bán tự doanh
        if (
          customerInsiderData?.isPrivateTradingBond &&
          customerInsiderData?.privateRemainQty &&
          customerInsiderData?.privateRemainQty > 0
        ) {
          setContentConfirm(
            intl.formatMessage(
              { id: "widgets.placeOrder.privateTradingBond" },
              {
                privateRemainQty: customerInsiderData?.privateRemainQty,
              }
            )
          );
          setVisibleModalConfirm(true);
          setLoading(false);
          return;
        }

        handlePlaceOrder();
      } else {
        handlePlaceOrder();
      }
    }

    if (statusCustomerInsider === "FAILURE") {
      const codeError = errorPrepare?.code;
      if (codeError) {
        const msg = `${errorPrepare?.code?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg ? "error." + msg : "widgets.placeOrder.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }
      dispatch(postSingleOrderClear());
      setLoading(false);
    }
  }, [statusCustomerInsider, customerInsiderData]);

  const setConfirm = () => {
    handlePlaceOrder();
  };

  const handlePlaceOrder = () => {
    if (listHotKeyStorage.visiblePopupConfirm) {
      setLoading(false);
      onShowSubmitOrder(true);
      setVisibleSubmitOrderModal(true);
    } else {
      setVisibleModalOTP(true);
    }
  };

  const setConfirmRegisterOtp = () => {
    // dispatch(changeIsRegisterAction(true));
    // dispatch(requestChangeVisibleModalTradingAccount(true));
  };

  // Show hide modal xác nhận lệnh mua/bán
  const onShowSubmitOrder = (visible: boolean) => {
    setVisibleSubmitOrder(visible);
  };

  /**
   * Submit nút xác nhận mua/bán
   * Show modal smartOtp/QRCode
   */
  const onSubmitsuccess = () => {
    if (ordReturnToDefault && ordReturnToDefault === "true") {
      if (ordTradeTypeDefault !== "") {
        if (ordTradeTypeDefault === "0") {
          dispatch(onBuySellType(""));
        }

        if (ordTradeTypeDefault === "1") {
          dispatch(onBuySellType("sell"));
        }

        if (ordTradeTypeDefault === "2") {
          dispatch(onBuySellType("buy"));
        }
      } else {
        dispatch(onBuySellType(buySellType ? buySellType : "buy"));
      }
    }
    onChangePrice("");
    onChangeVolumn("");
    onChangeConditionalOrder(conditionalOrderList[0]);
    dispatch(onReloadPlaceOrder(true));
  };

  // Lifecycle hooks one
  useEffectOnce(() => {
    if (ordTradeTypeDefault !== "") {
      if (ordTradeTypeDefault === "0") {
        dispatch(onBuySellType(""));
      }

      if (ordTradeTypeDefault === "1") {
        dispatch(onBuySellType("sell"));
      }

      if (ordTradeTypeDefault === "2") {
        dispatch(onBuySellType("buy"));
      }
    } else {
      dispatch(onBuySellType(buySellType ? buySellType : "buy"));
    }
    dispatch(onConditionalOrder(conditionalOrderList[0]));
  });

  const getPriceParamAvailableTrade = (
    price: any,
    ceilingPrice: number,
    floorPrice: number,
    buySellType: string,
    conditionalOrder: any
  ) => {
    if (!isNaN(Number(price))) {
      if (price === "") {
        return 0;
      } else {
        if (
          conditionalOrder.value === "TS" ||
          conditionalOrder.value === "PRO"
        ) {
          return ceilingPrice;
        } else {
          return price;
        }
      }
    } else {
      if (buySellType === "buy") {
        return ceilingPrice;
      } else {
        return floorPrice;
      }
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    // Return nếu không có account
    if (!currentAccount?.subAccoNo) return;

    dispatch(
      getAvailableTrade({
        subAccoNo: currentAccount?.subAccoNo,
        secCd: currentTicker,
      })
    );
  }, [currentAccount?.subAccoNo, currentTicker]);

  useEffect(() => {
    // Return nếu không có account
    if (!currentAccount?.subAccoNo) return;

    // Return nếu không có data cash
    if (!dataCash) return;

    dispatch(
      getOrderLimitedQty({
        subAccoNo: currentAccount?.subAccoNo,
        secCd: currentTicker,
        tradeType: buySellType === "buy" ? 2 : 1,
        price: getPriceParamAvailableTrade(
          price,
          ceilingPrice,
          floorPrice,
          buySellType,
          conditionalOrder
        ),
        buyingPower: dataCash?.buyCredit || 0,
        marginRoom: dataCash?.remainRoomVolume || 0,
      })
    );
  }, [
    dataCash,
    price,
    buySellType,
    currentAccount?.subAccoNo,
    currentTicker,
    conditionalOrder?.value,
  ]);

  useEffect(() => {
    // Return nếu không có account
    if (!currentAccount?.subAccoNo || !isReloadPlaceOrder) return;
    const time = setTimeout(() => {
      dispatch(
        getAvailableTrade({
          subAccoNo: currentAccount?.subAccoNo!,
          secCd: currentTicker,
        })
      );
      dispatch(onReloadPlaceOrder(false));
    }, TimeDelayCancelOrder * 1000);
    return () => {
      if (time) clearTimeout(time);
    };
  }, [isReloadPlaceOrder]);

  const getPriceSuggestions = () => {
    if (conditionalOrder?.value !== "01") return [];
    if (currentTradingSession) {
      const types = getSuggestionOrderTypes(
        tradingSessionId, // Sàn
        getMarketSessionCd(currentTradingSession) // Phiên
      );
      return types;
    }

    // Params currentTicker
    return [];
  };

  const getVolumnSuggestions = () => {
    const suggestions = listOrderQtySuccess
      ? listOrderQtySuccess
          .slice()
          .sort((firstItem, secondItem) => secondItem.value - firstItem.value)
      : listOrderQtySuccess;
    return listOrderQtySuccess ? suggestions.slice(0, 4) : listOrderQtySuccess;
  };

  const getPriceRound = (price: number, symbol: string) => {
    const priceNumber = parseFloat(price?.toString());
    if (symbol !== "") {
      const ticker = GlobalData.getTickerInfoNew(symbol);
      if (
        ticker !== null &&
        ticker.marketcd === EMarketCodeNew.HSX_IDX &&
        ticker.sectype === SecType.S
      ) {
        if (priceNumber <= 10.0) {
          return Math.round((priceNumber + Number.EPSILON) * 100) / 100;
        } else if (priceNumber > 10.0 && priceNumber <= 50) {
          return Math.round((priceNumber + Number.EPSILON) * 20) / 20;
        } else {
          return Math.round((priceNumber + Number.EPSILON) * 10) / 10;
        }
      } else if (ticker.sectype === SecType.CW) {
        return Math.round((priceNumber + Number.EPSILON) * 100) / 100;
      } else {
        return Math.round((priceNumber + Number.EPSILON) * 10) / 10;
      }
    } else {
      return Math.round((priceNumber + Number.EPSILON) * 10) / 10;
    }
  };

  const getPrice = (conditionalOrder: string, optValType: number): number => {
    switch (conditionalOrder) {
      case "01":
      case "TCO":
        if (!isNaN(price)) {
          return price;
        } else {
          if (buySellType === "buy") {
            return ceilingPrice * 1000;
          } else {
            return floorPrice * 1000;
          }
        }
      case "PRO":
        return 0;
      case "TS":
        return ceilingPrice * 1000;
      case "SP":
        if (optValType === 2) {
          return (
            parseFloat(avgPrice) * (1 + parseFloat(optVal) / 100) -
            parseFloat(limitOffset)
          );
        } else {
          return (
            parseFloat(avgPrice) + parseFloat(optVal) - parseFloat(limitOffset)
          );
        }
      case "CL":
        if (optValType === 2) {
          return (
            parseFloat(avgPrice) * (1 - parseFloat(optVal) / 100) -
            parseFloat(limitOffset)
          );
        } else {
          return (
            parseFloat(avgPrice) - parseFloat(optVal) - parseFloat(limitOffset)
          );
        }
      default:
        return price;
    }
  };

  const handleModal = () => {
    setVisibleModalOTP(false);
    setLoading(false);
  };

  const doSubmitPlaceOrder = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);

    if (!currentAccount?.subAccoNo) {
      setLoading(false);
      return;
    }

    // Neu lenh thuong
    if (conditionalOrder.value === "01") {
      // Validate
      // try {
      //   const error = await checkPlaceOrder(params, intl);
      //   if (error) {
      //     const errorMsg = error;
      //     toast(errorMsg, "error");
      //     setLoading(false);
      //     return;
      //   }
      // } catch (error: any) {
      //   const errorMsg = intl.formatMessage({
      //     id: error.code,
      //     defaultMessage: error.description,
      //   });
      //   toast(errorMsg, "error");
      //   setLoading(false);
      //   return;
      // }

      // Múc
      const tickerInfo = GlobalData.getTickerInfoNew(currentTicker);
      if (!loadingPlaceOrder) {
        if (prepareSubmitOrderReponse?.dataType !== 1) {
          const paramsPending: PlaceOrdersPendingParam = {
            requestList: prepareSubmitOrderReponse?.dataList || "",
            otpCode: response?.otp,
            otpType: response?.otpTypeSubmit!,
          };
          dispatch(
            requestPostSingleOrderPending({
              params: paramsPending,
              tickerInfo: tickerInfo,
            })
          );
          const resLogData = {
            event: RealtimeConst.submitPlaceOrder,
            message: JSON.stringify(paramsPending),
          };
          storage.orderFirebase(resLogData);
        } else {
          const params: PlaceOrdersParam = {
            list: prepareSubmitOrderReponse?.dataList || "",
            otpCode: response?.otp,
            otpType: response?.otpTypeSubmit!,
          };
          dispatch(
            requestPostSingleOrder({
              params: params,
              tickerInfo: tickerInfo,
            })
          );
          const resLogData = {
            event: RealtimeConst.submitPlaceOrder,
            message: JSON.stringify(params),
          };
          storage.orderFirebase(resLogData);
        }
      }
    } else {
      // Else lenh dk
      const paramsCondition: IPlaceOrdersConditionParams = {
        secCd: currentTicker,
        custNo: Storage.getMasterAccount(),
        subAccoNo: currentAccount?.subAccoNo,
        tradeType: buySellType === "buy" ? 2 : 1,
        ordQty: volumn?.replaceAll(",", ""),
        minPrice: buySellType === "buy" ? minBuyPrice : 0,
        maxPrice: buySellType === "sell" ? maxSellPrice : 0,
        ordPrice: getPriceRound(
          getPrice(conditionalOrder.value, optValType),
          currentTicker
        ),
        condType: conditionalOrder.value,
        matMethod: conditionalOrder.value === "PRO" ? 2 : parseInt(matMethod),
        optType:
          conditionalOrder.value === "TCO"
            ? condition === "01"
              ? 0
              : parseFloat(optType.value)
            : "",
        optVal:
          conditionalOrder.value !== "PRO"
            ? optValType === 2 && conditionalOrder.value !== "TCO"
              ? parseFloat(optVal) / 100
              : parseFloat(optVal)
            : "",
        fromDate: Number(moment(tradeDate).format("yyyyMMDD")),
        toDate: Number(moment(expiredDate).format("yyyyMMDD")),
        traderId: "",
        secType: secType,
        optValType:
          conditionalOrder.value !== "TCO" && conditionalOrder.value !== "PRO"
            ? optValType
            : "",
        limitOffset:
          conditionalOrder.value !== "TCO" && conditionalOrder.value !== "PRO"
            ? parseFloat(limitOffset)
            : "",
        clientInfo: "",
        channel: "2",
        otpCode: response?.otp,
        otpType: response?.otpTypeSubmit!,
      };

      // Múc
      const tickerInfo = GlobalData.getTickerInfoNew(currentTicker);

      dispatch(
        requestPostOrderCondition({
          params: paramsCondition,
          tickerInfo: tickerInfo,
        })
      );
      const resLogData = {
        event: RealtimeConst.submitPlaceOrder,
        message: JSON.stringify(paramsCondition),
      };
      storage.orderFirebase(resLogData);
    }
  };

  useEffect(() => {
    if (listHotKeyStorage.visiblePopupConfirm) return;
    if (singleOrderStatus?.status === "success") {
      setLoading(false);
      toast(
        intl.formatMessage({ id: "widgets.placeOrder.orderSuccess" }),
        "success"
      );
      dispatch(postSingleOrderClear());
      onSubmitsuccess();
    }

    if (singleOrderStatus?.status === "failed") {
      setLoading(false);
      const codeError = errorSingleOrder?.code;
      if (codeError) {
        const msg = `${errorSingleOrder?.code
          ?.toString()
          .replaceAll("-", "_")}`;
        const msgFinal = msg ? "error." + msg : "widgets.placeOrder.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }

      dispatch(postSingleOrderClear());
    }
  }, [singleOrderStatus, listHotKeyStorage.visiblePopupConfirm]);

  useEffect(() => {
    if (changeTickerByEnter) {
      refInputPrice.current?.focus();
    }
  }, [changeTickerByEnter]);

  useEffect(() => {
    if (changePriceByEnter) {
      refInputVolume.current?.focus();
    }
  }, [changePriceByEnter]);

  const onBlurEnter = () => {
    dispatch(onChangeTickerByEnter(false));
    dispatch(onChangePriceByEnter(false));
  };

  const handleKeyDown = async (e: any, typeInput: string) => {
    if ((e.key === "Enter" || e.key === "Tab") && typeInput === "price") {
      if (currentTicker && currentTicker !== "") {
        const symbolData = await getStockPrice(currentTicker);
        if (symbolData.statusCode !== 0) {
          toast(
            symbolData?.errorCode ? "error." + symbolData?.errorCode : "",
            "error",
            symbolData.message,
            true
          );
          return false;
        }
        await validatePrice(symbolData.data);
        dispatch(onChangePriceByEnter(true));
      } else {
        setErrorTicker(intl.formatMessage({ id: "validation.emptyTicker" }));
      }
    }
    if (
      e.key === "Backspace" &&
      typeInput === "price" &&
      !regPrice.test(price as string)
    ) {
      onChangePrice("");
    }
    if ((e.key === "Enter" || e.key === "Tab") && typeInput === "volume") {
      refInputVolume.current?.blur();
      if (currentTicker && currentTicker !== "") {
        const symbolData = await getStockPrice(currentTicker);
        if (symbolData.statusCode !== 0) {
          toast(
            symbolData?.errorCode ? "error." + symbolData?.errorCode : "",
            "error",
            symbolData.message,
            true
          );
          return false;
        }
        const isValidateVolume = await validateVolume(symbolData.data);
        dispatch(onChangeVolumeByEnter(true));
        if (!isValidateVolume) {
          return;
        } else {
          if (
            conditionalOrder?.value === "01" ||
            conditionalOrder?.value === "TCO" ||
            conditionalOrder?.value === "PRO"
          ) {
            onSubmit();
          }
        }
      } else {
        setErrorTicker(intl.formatMessage({ id: "validation.emptyTicker" }));
      }
    }
  };

  const handleChangeSuggestionsPrice = () => {
    dispatch(onChangePriceByEnter(true));
  };

  const handleFillVolumeMax = (volume?: number) => {
    setErrorVolumn("");
    if (volume) {
      if (tradingSessionId === EMarketCodeNew.HSX_IDX) {
        if (volume % VolumeLot.HSX_VOLUME_LOT !== 0) {
          const volumeLot = volume / 100;
          dispatch(
            onUpdateVolumn({
              volumn: numeral(parseInt(volumeLot?.toString()) * 100).format(
                "0,000"
              ),
            })
          );
        } else {
          dispatch(onUpdateVolumn({ volumn: numeral(volume).format("0,000") }));
        }
      } else if (
        tradingSessionId === EMarketCodeNew.HNX_IDX ||
        tradingSessionId === EMarketCodeNew.UPCOM_IDX
      ) {
        if (volume > VolumeLot.HNX_VOLUME_LOT) {
          if (volume % VolumeLot.HNX_VOLUME_LOT !== 0) {
            const volumeLot = volume / 100;
            dispatch(
              onUpdateVolumn({
                volumn: numeral(parseInt(volumeLot?.toString()) * 100).format(
                  "0,000"
                ),
              })
            );
          } else {
            dispatch(
              onUpdateVolumn({ volumn: numeral(volume).format("0,000") })
            );
          }
        } else {
          dispatch(onUpdateVolumn({ volumn: numeral(volume).format("0,000") }));
        }
      }
    } else {
      dispatch(onUpdateVolumn({ volumn: "" }));
    }
  };

  // const handleRetype = () => {
  //   onChangePrice("");
  //   onChangeVolumn("");
  // };

  useEffect(() => {
    // console.log("currentTicker", currentTicker);
    if (currentTicker) {
      dispatch(fetchOrganizationFundamental(currentTicker));
    } else {
      const foreignRoomData = new ForeignRoom();
      foreignRoomData.setSeccd("");
      foreignRoomData.setTotalroom(0);
      foreignRoomData.setCurrentroom(0);
      foreignRoomData.setBuyforeignqty(0);
      foreignRoomData.setBuyforeignamt(0);
      foreignRoomData.setSellforeignqty(0);
      foreignRoomData.setSellforeignamt(0);
      dispatch(updateForeignRoom(foreignRoomData.toObject()));
    }

    const eventListenerForeignRoom = (emittedData: ForeignRoom.AsObject) => {
      if (emittedData.seccd === currentTicker) {
        dispatch(updateForeignRoom(emittedData));
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM, eventListenerForeignRoom);
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM,
        eventListenerForeignRoom
      );
    };
  }, [currentTicker]);

  return (
    <Container className="place-order-container" id="place-order-container">
      <Content>
        <div>
          <div>
            <TickerSearch
              setErrorTicker={setErrorTicker}
              errorTicker={errorTicker}
              setErrorPrice={setErrorPrice}
            />
            <BuySellButtonRow>
              <ButtonItem className="mr-1" onClick={onChangeType("buy")}>
                <BuyButton isActive={buySellType === "buy"}></BuyButton>
                <LabelBuySell isActive={buySellType === "buy"}>
                  <FormattedMessage id="common.buyUp" />
                </LabelBuySell>
              </ButtonItem>
              <ButtonItem onClick={onChangeType("sell")}>
                <SellButton isActive={buySellType === "sell"}></SellButton>
                <LabelBuySell isActive={buySellType === "sell"}>
                  <FormattedMessage id="common.sellUp" />
                </LabelBuySell>
              </ButtonItem>
            </BuySellButtonRow>
            {errorTradeType ? (
              <>
                <Spacer size="s" />
                <div className="px-3 text-right">
                  <span style={{ color: "red" }}>{errorTradeType}</span>
                </div>
              </>
            ) : (
              <div></div>
            )}
            <Spacer size="m" />
            <div className="d-flex align-items-center px-3">
              <FormTitle>
                <FormattedMessage id="widgets.placeOrder.accountNumber" />
              </FormTitle>
              <FormControl>
                <AccountSelect />
              </FormControl>
            </div>
            {errorAccount ? (
              <>
                <Spacer size="s" />
                <div className="px-3 text-right">
                  <span style={{ color: "red" }}>{errorAccount}</span>
                </div>
              </>
            ) : (
              <div></div>
            )}
            <Spacer size="s" />
            <div className="d-flex align-items-center px-3">
              <FormTitle>
                <FormattedMessage id="widgets.placeOrder.conditionalOrder.title" />
              </FormTitle>
              <FormControl>
                <SelectPlaceOrder
                  className="flex-fill"
                  options={conditionalOrderList}
                  value={conditionalOrder}
                  onChange={onChangeConditionalOrder}
                />
              </FormControl>
            </div>

            <Spacer size="s" />
            <div className="px-3">
              {(conditionalOrder.value === "01" ||
                conditionalOrder.value === "TCO") && (
                <InputPrice
                  innerRef={refInputPrice}
                  name="input-price-place-order"
                  suggestions={getPriceSuggestions()}
                  handleChangeSuggestionsPriceNextFocus={
                    handleChangeSuggestionsPrice
                  }
                  value={price}
                  onChange={onChangePrice}
                  onBlurEnter={onBlurEnter}
                  label={intl.formatMessage({ id: "widgets.placeOrder.price" })}
                  max={ceilingPrice}
                  min={floorPrice}
                  partern={regPrice}
                  decimal
                  error={errorPrice}
                  placeholder={intl.formatMessage({
                    id: "formPlaceholder.inputPrice",
                  })}
                  ticker={currentTicker}
                  onKeyDown={(e) => handleKeyDown(e, "price")}
                  containerId="place-order-container"
                  isPlaceOrder
                />
              )}
              <InputVolume
                innerRef={refInputVolume}
                name="input-volume-place-order"
                suggestions={getVolumnSuggestions()}
                value={volumn}
                onChange={onChangeVolumn}
                onBlurEnter={onBlurEnter}
                label={intl.formatMessage({ id: "widgets.placeOrder.volume" })}
                partern={regVolumn}
                error={errorVolumn}
                min={1}
                maxVolume={orderLimitedQty}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputVolumne",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "volume")}
                containerId="place-order-container"
              />
            </div>
            <ConditionCommandForm
              conditionalCommandType={conditionalOrder.value}
              ticker={currentTicker}
              onSubmit={onSubmit}
            />
          </div>
          <div className="px-3">
            <VerticalDivider className="mb-3 px-3" />
            <div className="row">
              <div className="col-12 d-flex justify-content-between mb-1">
                <Label>
                  <FormattedMessage id="widgets.placeOrder.orderLimitedQty" />
                </Label>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <StyledTooltip id="tooltip">
                      <FormattedMessage id="widgets.placeOrder.maxVolume.tooltip" />
                    </StyledTooltip>
                  }
                >
                  <Label
                    isCursor
                    colorKey="textcolorWhite"
                    onClick={() => handleFillVolumeMax(orderLimitedQty)}
                  >
                    {numeral(Math.floor(orderLimitedQty!)).format("0,0")}
                  </Label>
                </OverlayTrigger>
              </div>

              {buySellType === "buy" && (
                <div className="col-12 d-flex justify-content-between mb-1">
                  <Label>
                    <FormattedMessage id="widgets.placeOrder.purchasingAbility" />
                  </Label>
                  <Label colorKey="textcolorWhite">
                    {numeral(dataCash?.buyCredit!).format("0,000")}
                  </Label>
                </div>
              )}

              {currentAccount?.subAccoNo.slice(-2, -1) ===
                GetAccountType.MARGIN && (
                <div className="col-12 d-flex justify-content-between mb-1">
                  <Label>
                    <FormattedMessage id="widgets.placeOrder.marginRate" />
                  </Label>
                  <Label colorKey="textcolorWhite">
                    {numeral(dataCash?.marginRatio!).format("0,0.00")}
                  </Label>
                </div>
              )}

              {foreignType === 2 && (
                <div className="col-12 d-flex justify-content-between mb-1">
                  <Label>
                    <FormattedMessage id="widgets.placeOrder.currentRoom" />
                  </Label>
                  <Label colorKey="textcolorWhite">
                    {numeral(currentRoom!).format("0,0")}
                  </Label>
                </div>
              )}
            </div>
            <Spacer size="xl" />
            {errorSession ? (
              <div className="text-center">
                <span style={{ color: "red" }}>{errorSession}</span>
                <Spacer size="m" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <RowButton>
          <Button
            className={
              buySellType === "buy"
                ? "btn-place-order btn-place-order-buy"
                : "btn-place-order btn-place-order-sell"
            }
            onClick={() => onSubmit()}
            disabled={loading}
            loading={loading}
          >
            {buySellType === "buy"
              ? intl.formatMessage({ id: "widgets.placeOrder.buy_command" })
              : intl.formatMessage({ id: "widgets.placeOrder.sell_command" })}
          </Button>
        </RowButton>

        {listHotKeyStorage.visiblePopupConfirm
          ? visibleSubmitOrderModal && (
              <ModalSubmitOrder
                visible={visibleSubmitOrder}
                setVisible={setVisibleSubmitOrder}
                type={buySellType}
                handleCloseModal={() => onShowSubmitOrder(false)}
                handleSubmitSuccess={() => {
                  onSubmitsuccess();
                }}
                visibleModalOTP={visibleModalOTP}
                setVisibleModalOTP={setVisibleModalOTP}
                conditionalOrder={conditionalOrder}
                tradingSessionId={tradingSessionId}
              />
            )
          : visibleModalOTP && (
              <OTPByBusinessCd
                handleClose={handleModal}
                getResult={doSubmitPlaceOrder}
                businessCd={EBusinessCd.TRADING}
              />
            )}
        {visibleModalConfirmRegisterOtp && (
          <ModalConfirmRegisterOtp
            visibleModalConfirm={visibleModalConfirmRegisterOtp}
            setVisibleModalConfirm={setVisibleModalConfirmRegisterOtp}
            setConfirm={setConfirmRegisterOtp}
          />
        )}
        {visibleModalConfirm && (
          <ModalConfirm
            visibleModalConfirm={visibleModalConfirm}
            setVisibleModalConfirm={setVisibleModalConfirm}
            setConfirm={setConfirm}
            title={intl.formatMessage({ id: "common.confirm" })}
            content={contentConfirm}
          />
        )}
      </Content>
    </Container>
  );
});

export default injectIntl(PlaceOrder);
