import { Body, Container, Title, Footer } from "./styles";
import { memo } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "components/commons";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  setVisibleModalChangePasswordSuccess,
  toggleModalLogin,
} from "modules/auth/redux";
import { FormattedMessage, useIntl } from "react-intl";
import { visibleModalChangePasswordSuccessSelector } from "modules/auth/selectors";

const ModalChangePasswordSuccess = memo(() => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const statusModalSuccess = useSelector(
    visibleModalChangePasswordSuccessSelector
  );
  const RADIUS_4 = 4;
  return (
    <>
      <Modal
        size="sm"
        show={statusModalSuccess}
        centered
        keyboard={false}
        dialogClassName="modal-284"
      >
        <Container>
          <Title>
            <FormattedMessage id="common.titleAlert" />
          </Title>
          <div className="footer">
            <Body>
              {intl.formatMessage({
                id: "auth.changePasswordSuccess",
              })}
            </Body>
            <Footer>
              <Button
                size="l"
                style={{ flex: 1 }}
                color="accept"
                fill={true}
                className="flex-1"
                radius={RADIUS_4}
                onClick={() =>
                  batch(() => {
                    dispatch(setVisibleModalChangePasswordSuccess(false));
                    dispatch(toggleModalLogin(true));
                  })
                }
              >
                <FormattedMessage id="common.buttonClose" />
              </Button>
            </Footer>
          </div>
        </Container>
      </Modal>
    </>
  );
});

export default ModalChangePasswordSuccess;
