import { FC } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { TopForeignState } from "modules/market/redux/topForeign";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";
import { CellWrapper } from "./styles";
import {
  activeTabTopForeignSelector,
  marketTopForeignSelector,
} from "modules/market/redux/selectors";
import { TopForeignMenu } from "../types";
import { InternalMS } from "domain/protoNew/auto_trading_pb";
import { UNIT_NUMBER } from "helper/consts";

function getFieldValue(value: TopForeignMenu) {
  switch (value) {
    case "foreignNetValuesBuy":
    case "foreignNetValuesSell":
      return "netforeignamt";
    case "foreignBuy":
      return "buyforeignqty";
    case "foreignSell":
      return "sellforeignqty";
    case "foreignBuyValue":
      return "buyforeignamt";
    case "foreignSellValue":
      return "sellforeignamt";
    default:
      return "netforeignamt";
  }
}

function getFormatValue(value: TopForeignMenu) {
  switch (value) {
    case "foreignNetValuesBuy":
    case "foreignNetValuesSell":
    case "foreignBuyValue":
    case "foreignSellValue":
      return UNIT_NUMBER.ONE_THOUSAND;
    case "foreignBuy":
    case "foreignSell":
      return UNIT_NUMBER.ONE_HUNDRED;
    default:
      return UNIT_NUMBER.ONE_THOUSAND;
  }
}

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector?.data?.[tickerName]?.colorcode
  );
};

const topValueSelector = (
  tickerName: string,
  fieldValue: keyof InternalMS.AsObject
) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector?.data?.[tickerName]?.[fieldValue]
  );
};

interface Props {
  ticker: string;
}

const TopValueCell: FC<Props> = ({ ticker }) => {
  const activeTab = useSelector(activeTabTopForeignSelector);
  const textColor = useSelector(textColorSelector(ticker));
  const topValue = useSelector(
    topValueSelector(ticker, getFieldValue(activeTab))
  ) as number;

  return (
    <CellWrapper
      className="market-price-cell is-bold center-for-value"
      textcolor={textColor}
    >
      <BlinkableCell
        value={numeral(topValue / getFormatValue(activeTab)).format("0,0.00")}
      />
    </CellWrapper>
  );
};

export { TopValueCell };
