/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, HeaderCell, TableCellContent } from "./styles";
import numeral from "numeral";
import moment from "moment";

interface PROPS {
  data: any;
}

const RequestTable = (props: PROPS) => {
  const { data } = props;
  const intl = useIntl();
  const center = "center";
  const flexEnd = "flex-end";

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advertisingOrder.table.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advertisingOrder.table.TradeType" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              <FormattedMessage
                id={`advertisingOrder.tradeTypeOptions.value${props.value}`}
              />
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advertisingOrder.table.ordPrice" />
          </HeaderCell>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="advertisingOrder.table.ordQty" />
          </HeaderCell>
        ),
        accessor: "ordQty",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="advertisingOrder.table.minQty" />
          </HeaderCell>
        ),
        accessor: "minQty",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advertisingOrder.table.expDate" />
          </HeaderCell>
        ),
        accessor: "expDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};
  return (
    <Container id="bidAskBondTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? data : []}
        enableInfinity={true}
        scrollableTarget={"bidAskBondTable"}
        isHasFooter={false}
      />
    </Container>
  );
};

export default memo(RequestTable);
