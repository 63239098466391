import numeral from "numeral";
import { PriceText } from "./styles";
interface PriceProps {
  price: number | string;
  color: string;
  handleClickPrice: (price: number | string) => void;
}

function Price({ price, color, handleClickPrice }: PriceProps) {
  if (!price) return null;
  return (
    <PriceText
      className="price-text"
      priceColor={color}
      onClick={() => handleClickPrice(price)}
    >
      {!parseFloat(price.toString()) && price !== 0
        ? price
        : numeral(price).format("0.00")}
    </PriceText>
  );
}

export default Price;
