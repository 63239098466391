import styled from "styled-components/macro";

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
`;

export const ButtonExit = styled.button`
  display: flex;
  background: transparent;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 14px;
  height: 14px;
`;

export const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  color: ${({ theme }) => theme.grey0};
  text-align: center;
  flex: 1;
  text-transform: uppercase;
`;

export const ModalHeader = styled.div`
  height: 40px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  background: ${({ theme }) => theme.background3};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
  padding: 0px 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const CardTable = styled.div`
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background2};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  #qrcode {
    border-radius: 8px;
    margin: 16px;
  }
  .inputOTP {
    /* min-height: 170px; */
  }
  .inputOTPHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.grey30};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  }
  .blue {
    color: ${({ theme }) => theme.blue50};
    text-decoration: underline;
    cursor: pointer;
  }
  .title {
    color: ${({ theme }) => theme.textcolorWhite};
    .sendCode {
      width: 40%;
      text-align: end;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.grey50};
    }
  }
  .inputSmsOTPContent {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .inputOTPContent {
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    background: ${({ theme }) => theme.background2};
    justify-content: center;
    .otpContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .otpInput {
        width: 44px;
        height: 44px;
        margin: 6px;
        text-align: center;
        background: ${({ theme }) => theme.background1};
        color: ${({ theme }) => theme.textcolorWhite};
        border: 1px solid ${({ theme }) => theme.border2};
        border-radius: 4px;
        font-size: ${({ theme }) => theme.typeFaces.headline.size};
        font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
        line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};

        &:focus {
          outline: none;
          border-color: ${({ theme }) => theme.brandPrimary};
        }
      }
    }

    &.hasError {
      /* border: 1px solid ${({ theme }) => theme.red50}; */
      .otpInput {
        border-color: ${({ theme }) => theme.red50};

        &:focus {
          border-color: ${({ theme }) => theme.red50};
        }
      }
    }
  }
`;
export const ErrorVerify = styled.div`
  color: ${({ theme }) => theme.redR1};
  text-align: center;
  margin-top: 4px;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;

export const AlertText = styled.div`
  margin: 16px;
  text-align: center;
`;

export const BodyQRcode = styled.div<{ disabled?: boolean }>`
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  ${({ disabled }) => disabled && `opacity: 0.2;`}
`;

export const QRcodeExpired = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -33px;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const LoadingBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: 16px;
  .loader {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgElevated3};
  height: 40px;
  align-items: center;
  z-index: 4;

  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.grey70};
    }
  }

  .header-right {
    padding: 4px 8px;
    min-width: 300px;
    display: flex;
    align-items: center;

    .input {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
`;

export const SaveOTPWrapper = styled.div<{
  allowSaveOtp?: boolean;
}>`
  margin-top: 0px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ allowSaveOtp }) =>
    !allowSaveOtp &&
    `
      visibility: hidden;
      user-select: none;
  `}
`;

export const TextSave = styled.div`
  margin-right: 20px;
`;

export const SwitchSave = styled.div``;

export const Container = styled.div`
  /* border: 1px solid ${({ theme }) => theme.border1}; */
  border-radius: 8px;
`;

export const ModalTab = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.background3};
`;

export const Tab = styled.div<{
  isActive?: boolean;
}>`
  flex: 1;
  text-align: center;
  padding: 8px 0;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey40};
  cursor: pointer;
  user-select: none;

  ${({ theme, isActive }) =>
    isActive &&
    `
    box-shadow: inset 0px -1px 0px ${theme.brandPrimary};
    color: ${theme.brandPrimary};
    font-weight: ${theme.typeFaces.headline.weight.bold};
  `}
`;
