import { CONTRACT_DEBIT_TABS } from "modules/contract-debit/constants";
import { activeTabSelector } from "modules/contract-debit/selectors";
import { updateActiveTab } from "modules/contract-debit/redux";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";

const Tabs: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab
          isActive={activeTab === CONTRACT_DEBIT_TABS.LOAN_TRACKING}
          onClick={() =>
            dispatch(updateActiveTab(CONTRACT_DEBIT_TABS.LOAN_TRACKING))
          }
        >
          {intl.formatMessage({
            id: "contractDebit.tabs.loanTrackingTab",
          })}
        </Tab>
        <Tab
          isActive={activeTab === CONTRACT_DEBIT_TABS.PAYMENT_HISTORY}
          onClick={() =>
            dispatch(updateActiveTab(CONTRACT_DEBIT_TABS.PAYMENT_HISTORY))
          }
        >
          {intl.formatMessage({
            id: "contractDebit.tabs.paymentHistoryTab",
          })}
        </Tab>
        <Tab
          isActive={activeTab === CONTRACT_DEBIT_TABS.EXTEND_HISTORY}
          onClick={() =>
            dispatch(updateActiveTab(CONTRACT_DEBIT_TABS.EXTEND_HISTORY))
          }
        >
          {intl.formatMessage({
            id: "contractDebit.tabs.extendHistoryTab",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
