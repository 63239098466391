import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: auto;
`;
export const LabelCell = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  background-color: ${({ theme }) => theme.background3};
`;

export const Content = styled.div`
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const DetailRowPaddingTB = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DetailLabel = styled.div`
  width: 50%;
  color: ${({ theme }) => theme.grey20};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  @media (max-width: 1200px) {
    width: 40%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const DetailInputWrapper = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: right;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top: 12px;
  }
`;

export const DetailRowPaddingBorder = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 16px 0px;
  border-bottom: 1px dashed #2b3030;
  margin-bottom: 16px;
`;
