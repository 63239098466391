import VgaiaHttp from "core/http/singleton/vgaia";
import { IAccountStatmentResponse } from "domain/models/AccountStatement";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IFindPositionClose, IFindPositionOpen } from "./type";
import { getPositionCloseApi, getPositionOpenApi } from "./urls";

export const getPositionOpen = async (
  param: IFindPositionOpen
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getPositionOpenApi(param)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getPositionClose = async (
  param: IFindPositionClose
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getPositionCloseApi(param)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
