import queryString from "query-string";

export const getCustomer2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomer2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const getListDepositApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getPMoneyDeposit${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
