import { IntlShape } from "react-intl";

// export const statusTypeTransfer = (intl: IntlShape) => [
//   {
//     value: "0",
//     label: intl.formatMessage({
//       id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status0",
//     }),
//   },
//   {
//     value: "1",
//     label: intl.formatMessage({
//       id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status1",
//     }),
//   },
//   {
//     value: "2",
//     label: intl.formatMessage({
//       id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status2",
//     }),
//   },
//   {
//     value: "3",
//     label: intl.formatMessage({
//       id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status3",
//     }),
//   },
// ];

export enum SUBSCRIBE_TABS {
  SUBSCRIBE = "SUBSCRIBE",
  HISTORY_SUBSCRIBE = "HISTORY_SUBSCRIBE",
  SEARCH_SUBSCRIBE = "SEARCH_SUBSCRIBE",
}

export enum FORM_LEFT {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export const optiosForm = (intl: IntlShape) => [
  {
    value: FORM_LEFT.ADD,
    label: intl.formatMessage({ id: "subscribe.typeForm.typeAdd" }),
  },
  {
    value: FORM_LEFT.REMOVE,
    label: intl.formatMessage({ id: "subscribe.typeForm.typeRemove" }),
  },
];

export const optioTypes = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({ id: "subscribe.statusThq.valueAll" }),
  },
  {
    value: "1",
    label: intl.formatMessage({ id: "subscribe.statusThq.value1" }),
  },
  {
    value: "2",
    label: intl.formatMessage({ id: "subscribe.statusThq.value2" }),
  },
  {
    value: "3",
    label: intl.formatMessage({ id: "subscribe.statusThq.value3" }),
  },
  {
    value: "4",
    label: intl.formatMessage({ id: "subscribe.statusThq.value4" }),
  },
  {
    value: "5",
    label: intl.formatMessage({ id: "subscribe.statusThq.value5" }),
  },
  {
    value: "6",
    label: intl.formatMessage({ id: "subscribe.statusThq.value6" }),
  },
  {
    value: "7",
    label: intl.formatMessage({ id: "subscribe.statusThq.value7" }),
  },
  {
    value: "8",
    label: intl.formatMessage({ id: "subscribe.statusThq.value8" }),
  },
  {
    value: "9",
    label: intl.formatMessage({ id: "subscribe.statusThq.value9" }),
  },
  {
    value: "10",
    label: intl.formatMessage({ id: "subscribe.statusThq.value10" }),
  },
  {
    value: "11",
    label: intl.formatMessage({ id: "subscribe.statusThq.value11" }),
  },
  {
    value: "12",
    label: intl.formatMessage({ id: "subscribe.statusThq.value12" }),
  },
  {
    value: "13",
    label: intl.formatMessage({ id: "subscribe.statusThq.value13" }),
  },
  {
    value: "14",
    label: intl.formatMessage({ id: "subscribe.statusThq.value14" }),
  },
  {
    value: "15",
    label: intl.formatMessage({ id: "subscribe.statusThq.value15" }),
  },
];
