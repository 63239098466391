import styled from "styled-components";

export const Wrap = styled.div`
  height: 400px;
  padding: 26px 24px 16px 24px;
  .button-filter {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
    @media (min-width: 1800px) {
      margin-left: auto;
    }
  }
`;

export const ContainerSms = styled.div`
  display: flex;
  margin-bottom: 18px;
  gap: 16px;
`;

export const Icon = styled.div``;

export const Label = styled.div`
  color: ${({ theme }) => theme.grey20};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  display: flex;
  flex: none;
  width: 132px;
`;
export const LabelSms = styled.div`
  color: ${({ theme }) => theme.grey20};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  display: flex;
  flex: none;
  width: 132px;
`;
export const LabelContainer = styled.div`
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  display: flex;
  flex: none;
  width: 162px;
`;
