import { toast } from "components/commons/Toast";
import {
  findCapitalIncrease,
  getDataSearachSubscribeApi,
  getDataSubscribeApi,
} from "core/http/apis/subscribeBuy";
import { ISubscribeResponse } from "domain/models/SubscribeBuy";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDataHistorySubscribe,
  getDataHistorySubscribeFail,
  getDataHistorySubscribeSuccess,
  getDataSearchSubscribe,
  getDataSearchSubscribeFail,
  getDataSearchSubscribeSuccess,
  getDataSubscribe,
  getDataSubscribeFail,
  getDataSubscribeSuccess,
} from "./redux";

// lấy data đăng ký mua
function* getDataSubscribeAction() {
  yield takeLatest(getDataSubscribe.type, getDataSubscribeSaga);
}
function* getDataSubscribeSaga(action: ReturnType<typeof getDataSubscribe>) {
  try {
    const params: { subAccoNo?: string; SecCd?: string } = action.payload;
    const response: ISubscribeResponse = yield call(
      getDataSubscribeApi,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getDataSubscribeSuccess(response.data));
    } else {
      yield put(getDataSubscribeFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDataSubscribeFail());
    toast(error?.code, "error", error?.description, true);
  }
}

// Tra cứu thông tin quyền
function* getDataSearchSubscribeAction() {
  yield takeLatest(getDataSearchSubscribe.type, getDataSearchSubscribeSaga);
}
function* getDataSearchSubscribeSaga(
  action: ReturnType<typeof getDataSearchSubscribe>
) {
  try {
    const params: {
      secCd?: string;
      rightType?: string;
      ownerFixDate?: string;
    } = action.payload;
    const response: ISubscribeResponse = yield call(
      getDataSearachSubscribeApi,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getDataSearchSubscribeSuccess(response.data));
    } else {
      yield put(getDataSearchSubscribeFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDataSubscribeFail());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getDataHistorySubscribeAction() {
  yield takeLatest(getDataHistorySubscribe.type, getDataHistorySubscribeSaga);
}
function* getDataHistorySubscribeSaga(
  action: ReturnType<typeof getDataHistorySubscribe>
) {
  try {
    const params: {
      subAccoNo: string;
    } = action.payload;
    const response: ISubscribeResponse = yield call(
      findCapitalIncrease,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getDataHistorySubscribeSuccess(response.data));
    } else {
      yield put(getDataHistorySubscribeFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDataSubscribeFail());
    toast(error?.code, "error", error?.description, true);
  }
}

export default function* transferLocalSaga() {
  yield all([
    getDataSubscribeAction(),
    getDataSearchSubscribeAction(),
    getDataHistorySubscribeAction(),
  ]);
}
