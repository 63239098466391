import { useEffect, useState } from "react";
import { Button } from "components/commons";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import {
  updateModalLoanExtendRequest,
  marginDebitExtendDaysRequest,
  findMarginContractDebitWatchRequest,
  updateLoanTrackingFilter,
} from "modules/contract-debit/redux";
import {
  isShowLoanExtendModalSelector,
  loanTrackingStatusMarginDebitExtendDaysSelector,
  loanTrackingDetailSelector,
  loanTrackingExtendFeeSelector,
  loanTrackingLoanExtendSelector,
  loanTrackingFilterSelector,
} from "modules/contract-debit/selectors";
import {
  IMarginDebitExtendDaysParams,
  IFindMarginContractDebitWatchParams,
} from "core/http/apis/contract-debit/types";
import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { MarginDebitContractWatch } from "domain/models/ContractDebit";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  DetailRowButton,
} from "./styles";
import { toast } from "components/commons/Toast";

import { convertNumberToDateFormat } from "helper/utils";
import moment from "moment";

import { updateModalOtpRequest } from "modules/advance/redux";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { EBusinessCd } from "helper/consts";

import { OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
interface Props {
  itemDetailRow?: MarginDebitContractWatch;
}

function LoanExtendModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const isVisible = useSelector(isShowLoanExtendModalSelector);
  const isVisibleOtp = useSelector(isShowOtpModalSelector);
  const themeType = useSelector(themeTypeSelector);
  const extendDays = useSelector(loanTrackingLoanExtendSelector);
  const extendFee = useSelector(loanTrackingExtendFeeSelector);
  const loanTrackingDetail = useSelector(loanTrackingDetailSelector);
  const statusMarginDebitExtendDays = useSelector(
    loanTrackingStatusMarginDebitExtendDaysSelector
  );
  const { fromDate, toDate, subAccoNo } = useSelector(
    loanTrackingFilterSelector
  );

  const closeModalHandler = () => {
    dispatch(updateModalLoanExtendRequest({ isVisible: false }));
  };

  const clickAcceptHandler = () => {
    setVisibleModalConfirm(true);
  };
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    const submitData: IMarginDebitExtendDaysParams = {
      debtContractData: loanTrackingDetail
        ? JSON.stringify(loanTrackingDetail)
        : "",
      extendDays: extendDays ? +extendDays : 0,
      interestAmt: 0,
      manageFeeAmt: 0,
      collectInterest: false,
      extendFeeAmt: extendFee ? +extendFee : 0,
      extendFeeType: 1,
      actionMode: 2,
      otpCode: response?.otp ?? "",
      otpType: response?.otpTypeSubmit!,
    };

    dispatch(marginDebitExtendDaysRequest(submitData));
  };
  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const setConfirm = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));

    setVisibleModalConfirm(false);
  };
  useEffect(() => {
    if (statusMarginDebitExtendDays) {
      const submitData2: IFindMarginContractDebitWatchParams = {
        branchCd: null,
        accoNo: subAccoNo ?? "",
        debitType: 0,
        status: 1,
        brokerCd: null,
        alloDate: 0,
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
      };
      dispatch(findMarginContractDebitWatchRequest(submitData2));
      dispatch(
        updateLoanTrackingFilter({
          key: "fromDate",
          value: fromDate,
        })
      );
      dispatch(
        updateLoanTrackingFilter({
          key: "toDate",
          value: toDate,
        })
      );
      dispatch(
        updateLoanTrackingFilter({
          key: "subAccoNo",
          value: subAccoNo,
        })
      );
      dispatch(
        updateLoanTrackingFilter({
          key: "status",
          value: 1,
        })
      );
      toast("contractDebit.message.loanExtendSuccess", "success");
      closeModalHandler();
    }
  }, [statusMarginDebitExtendDays]);
  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            {intl.formatMessage({
              id: "contractDebit.loanTrackingTab.confirmModal.title2",
            })}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-3">
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.subAccount" />
            </LabelCell>
            <TextCell>{itemDetailRow?.subAccoNo}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.contrDateFrom" />
            </LabelCell>
            <TextCell>
              {convertNumberToDateFormat({
                date: itemDetailRow?.contrDateFrom,
              })}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.contrDateTo" />
            </LabelCell>
            <TextCell>
              {convertNumberToDateFormat({ date: itemDetailRow?.contrDateTo })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.totalLoanBLN" />
            </LabelCell>
            <TextCell>{numeral(itemDetailRow?.amount).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.currentDebit" />
            </LabelCell>
            <TextCell>
              {" "}
              {numeral(itemDetailRow?.currentDebit).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.fee2" />
            </LabelCell>
            <TextCell>
              {extendFee ? numeral(extendFee).format("0,0") : "0"}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.extendDays" />
            </LabelCell>
            <TextCell>{extendDays ? extendDays : "0"}</TextCell>
          </BodyModalRow>

          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={closeModalHandler}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "contractDebit.loanTrackingTab.confirmModal.cancel",
              })}
            </Button>
            <Button
              color="accept"
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
              style={{
                background: `${themes[themeType].buttonGradientColor}`,
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "contractDebit.loanTrackingTab.confirmModal.loanExtend",
              })}
            </Button>
          </DetailRowButton>
        </BodyModal>
      </Wrapper>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "contractDebit.loanTrackingTab.confirmModal.contentConfirm2",
        })}
      />

      {isVisibleOtp && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.MARGIN}
        />
      )}
    </Modal>
  );
}

export default LoanExtendModal;
