/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { Button } from "components/commons";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateModalDerStatusRequest } from "modules/order-history/redux";
import {
  isShowConfirmModalDerSelector,
  listOrderDerDetailModalSelector,
} from "modules/order-history/selectors";

import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { OrderHistoryDerCond } from "domain/models/OrderHistory";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  Container,
  HeaderCell,
  TableCellContent,
  TableCellContentColor,
  ActionsRow,
  ButtonCell,
} from "./styles";

// import { convertNumberToDateFormat } from "helper/utils";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
// function getColor2(value: string, value2: number) {
//   if (value2 === 1 || value2 === 6 || value2 === 7 || value2 === 8) {
//     return <TextCell color="#F04B41">{value}</TextCell>;
//   } else if (value2 === 4 || value2 === 5) {
//     return <TextCell color="#1DBB75">{value}</TextCell>;
//   } else if (value2 === 2 || value2 === 3) {
//     return <TextCell color="#EDE51D">{value}</TextCell>;
//   }

//   return value;
// }

interface Props {
  itemDetailRow: OrderHistoryDerCond;
}

function DetailModalCond({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowConfirmModalDerSelector);
  const data = useSelector(listOrderDerDetailModalSelector);
  // const { orderType, tradeType } = useSelector(filterOrderHistorySelector);
  const themeType = useSelector(themeTypeSelector);
  const closeModalHandler = () => {
    dispatch(updateModalDerStatusRequest({ isVisible: false }));
  };
  const fetchMoreData = () => {};

  const center = "center";
  const flexEnd = "flex-end";
  function getColor3(value: string, value2: number) {
    if (value2 === 9 || value2 === 3) {
      return <TextCell color={themes[themeType].red50}>{value}</TextCell>;
    } else if (value2 === 2) {
      return <TextCell color={themes[themeType].green50}>{value}</TextCell>;
    } else if (value2 === 1) {
      return <TextCell color={themes[themeType].yellow50}>{value}</TextCell>;
    }

    return value;
  }
  function getColor(value: string, value2: number) {
    if (value2 === 1) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].red50}
          textTransform="uppercase"
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 2) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].green50}
          textTransform="uppercase"
        >
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  const condColumns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderType" />
          </HeaderCell>
        ),
        accessor: "tradeType",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value === 0 || props.value === "0"
                ? 0
                : getColor(
                    intl.formatMessage({
                      id:
                        "orderHistory.tradeTypeDer." + getDisplay8(props.value),
                    }),
                    props.value
                  )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderVolume2" />
          </HeaderCell>
        ),
        accessor: "ordQty",

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderPrice2" />
          </HeaderCell>
        ),
        accessor: "ordPrice",

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderVolumeFilled2" />
          </HeaderCell>
        ),
        accessor: "matQty",

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderPriceFilled2" />
          </HeaderCell>
        ),
        accessor: "avgPrice",

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.time" />
          </HeaderCell>
        ),
        accessor: "regDateTime",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {moment(new Date(props.value).toString()).format("DD/MM/YYYY")}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const getDisplay = (status: any) => {
    switch (status) {
      case "1":
        return "Back";
      case "2":
        return "Web";
      case "3":
        return "Front";
      case "4":
        return "Webmobile";
      case "5":
        return "Mobile";
      case "6":
        return "Home";
      case "7":
        return "Floor";
      case "8":
        return "SMS";
      case "9":
        return "Tel";
    }
  };
  const getDisplay6 = (status: number) => {
    switch (status) {
      case 1:
        return "waitActive";
      case 2:
        return "end";
      case 3:
        return "expire";
      case 9:
        return "cancel";
    }
  };
  const getDisplay8 = (status: number) => {
    switch (status) {
      case 1:
        return "short";
      case 2:
        return "long";
    }
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle
            color={
              itemDetailRow.tradeType === 1
                ? `${themes[themeType].red50}`
                : itemDetailRow.tradeType === 2
                ? `${themes[themeType].green50}`
                : "inherit"
            }
          >
            {itemDetailRow.tradeType !== 0
              ? intl.formatMessage({
                  id:
                    "orderHistory.tradeTypeDer." +
                    getDisplay8(itemDetailRow.tradeType ?? 0),
                })
              : ""}
            {" ("}

            {intl
              .formatMessage({
                id:
                  "orderHistory.orderHistoryDerTab.filter.condList." +
                  itemDetailRow.condType,
              })
              .includes("xu hướng")
              ? "Trailing stop"
              : intl
                  .formatMessage({
                    id:
                      "orderHistory.orderHistoryDerTab.filter.condList." +
                      itemDetailRow.condType,
                  })
                  .includes("Lệnh")
              ? intl
                  .formatMessage({
                    id:
                      "orderHistory.orderHistoryDerTab.filter.condList." +
                      itemDetailRow.condType,
                  })
                  .slice(5)
              : intl.formatMessage({
                  id:
                    "orderHistory.orderHistoryDerTab.filter.condList." +
                    itemDetailRow.condType,
                })}
            {") "}
            {itemDetailRow.secCd}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-2">
            <LabelCell>
              <FormattedMessage id="orderHistory.table.condType" />
            </LabelCell>
            <TextCell style={{ textTransform: "uppercase" }}>
              {intl
                .formatMessage({
                  id:
                    "orderHistory.orderHistoryDerTab.filter.condList." +
                    itemDetailRow.condType,
                })
                .includes("xu hướng")
                ? "Trailing stop"
                : intl
                    .formatMessage({
                      id:
                        "orderHistory.orderHistoryDerTab.filter.condList." +
                        itemDetailRow.condType,
                    })
                    .includes("Lệnh")
                ? intl
                    .formatMessage({
                      id:
                        "orderHistory.orderHistoryDerTab.filter.condList." +
                        itemDetailRow.condType,
                    })
                    .slice(5)
                : intl.formatMessage({
                    id:
                      "orderHistory.orderHistoryDerTab.filter.condList." +
                      itemDetailRow.condType,
                  })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.subAccount" />
            </LabelCell>
            <TextCell>{itemDetailRow?.custNo}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.status" />
            </LabelCell>
            <TextCell>
              {itemDetailRow?.status &&
                getColor3(
                  intl.formatMessage({
                    id:
                      "orderHistory.extStatusCond2." +
                      getDisplay6(itemDetailRow?.status ?? 0),
                  }),
                  itemDetailRow?.status
                )}
            </TextCell>
          </BodyModalRow>
          {(itemDetailRow.condType === "UO" ||
            itemDetailRow.condType === "DO") && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.activePrice" />
                </LabelCell>

                <TextCell>
                  {itemDetailRow.condType === "UO"
                    ? "<= "
                    : itemDetailRow.condType === "DO"
                    ? ">= "
                    : ""}
                  {numeral(
                    itemDetailRow.condType === "UO"
                      ? itemDetailRow.priceUp ?? 0
                      : itemDetailRow.condType === "DO"
                      ? itemDetailRow.priceDown ?? 0
                      : 0
                  ).format("0,0")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.orderVolume" />
            </LabelCell>

            <TextCell>
              {" "}
              {numeral(itemDetailRow?.ordQty ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.orderPrice" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.ordPrice ?? 0).format("0.00")}
            </TextCell>
          </BodyModalRow>

          {itemDetailRow.condType === "CL" && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopLoss" />
                </LabelCell>
                <TextCell>{itemDetailRow?.cutLostAmp ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.limitOffset ?? 0).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          {itemDetailRow.condType === "SP" && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.takeProfit" />
                </LabelCell>
                <TextCell>{itemDetailRow?.grossAmp ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.limitOffset ?? 0).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          {itemDetailRow.condType === "OC" && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopLossAmount" />
                </LabelCell>
                <TextCell>{itemDetailRow?.cutLostPrice ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.limitOffset ?? 0).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          {itemDetailRow.condType === "BB" && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopLossAmount" />
                </LabelCell>
                <TextCell>{itemDetailRow?.cutLostPrice ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.takeProfit" />
                </LabelCell>
                <TextCell>{itemDetailRow?.grossAmp ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopLoss" />
                </LabelCell>
                <TextCell>{itemDetailRow?.cutLostAmp ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.limitOffset ?? 0).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          {itemDetailRow.condType === "TS" && (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopInterval" />
                </LabelCell>
                <TextCell>{itemDetailRow?.rangeStop ?? 0}</TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.slipMargin" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.limitOffset ?? 0).format("0.00")}
                </TextCell>
              </BodyModalRow>
            </>
          )}
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.channel" />
            </LabelCell>
            <TextCell>
              {intl.formatMessage({
                id:
                  "orderHistory.ordChannel." +
                  getDisplay(itemDetailRow?.ordChanel ?? 0),
              })}
            </TextCell>
          </BodyModalRow>
          <Container id="orderHistoryModalTable">
            <CommonTableInfinity
              fetchMoreData={fetchMoreData}
              columns={condColumns}
              data={data ?? []}
              enableInfinity={true}
              scrollableTarget={"orderHistoryModalTable"}
              isHasFooter={false}
            />
          </Container>
          <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={closeModalHandler}
                width="100%"
              >
                <FormattedMessage id="holdUnholdBankAccount.confirmModal.cancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                className="button-confirm"
                style={
                  itemDetailRow.tradeType === 2
                    ? {
                        background: `${themes[themeType].green50}`,
                        fontSize: `${themes[themeType].typeFaces.headline.size}`,
                        padding: "18px 0px",
                      }
                    : itemDetailRow.tradeType === 1
                    ? {
                        background: `${themes[themeType].red50}`,
                        fontSize: `${themes[themeType].typeFaces.headline.size}`,
                        padding: "18px 0px",
                      }
                    : {
                        background: `${themes[themeType].brandPrimary}`,
                        fontSize: `${themes[themeType].typeFaces.headline.size}`,
                        padding: "18px 0px",
                      }
                }
                fill={true}
                // onClick={clickAgreeButtonHandler}
                width="100%"
              >
                {itemDetailRow.tradeType === 2 ? (
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.long" />
                ) : itemDetailRow.tradeType === 1 ? (
                  <FormattedMessage id="orderHistory.orderHistoryDerTab.table.short" />
                ) : (
                  "Xác nhận"
                )}
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailModalCond;
