import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFindPortTransactionParams,
  IGetPflTPortTransactionParams,
} from "core/http/apis/profit-loss/types";
import {
  PortTransaction,
  IFindPortTransactionResponse,
  IGetPflTPortTransactionResponse,
} from "domain/models/ProfitLoss";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
  secCd: string;
}

export interface ReduxData {
  currentPage: number;
  limit: number;
  enableInfinity: boolean;
  filter: IFilter;
  list: PortTransaction[];
  loading: boolean;
  activeTab: string;
  chart: {
    labels: string[];
    values: number[];
    colors: number[];
  };
}

const initialState: ReduxData = {
  currentPage: 0,
  limit: 30,
  enableInfinity: true,
  filter: {
    subAccoNo: "",
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
    secCd: "",
  },
  list: [],
  loading: false,
  activeTab: "PROFIT_LOSS",
  chart: {
    values: [],
    colors: [],
    labels: [],
  },
};

const profitLossSlice = createSlice({
  name: "profitLossSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchProfitLossRequest: (
      state,
      action: PayloadAction<IFindPortTransactionParams>
    ) => {
      state.loading = true;
    },
    searchProfitLossSuccess: (
      state,
      action: PayloadAction<IFindPortTransactionResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
      state.enableInfinity = true;
      state.currentPage = 0;
    },
    searchProfitLossFailure: (state) => {
      state.loading = false;
    },

    getPflTPortTransactionRequest: (
      state,
      action: PayloadAction<IGetPflTPortTransactionParams>
    ) => {},
    getPflTPortTransactionSuccess: (
      state,
      action: PayloadAction<IGetPflTPortTransactionResponse>
    ) => {
      if (action.payload.data) {
        state.chart = {
          labels: [],
          values: [],
          colors: [],
        };
        if (action.payload.data.length > 1) {
          action.payload.data.forEach((item: any) => {
            state.chart.labels.push(item[0]);
            state.chart.values.push(item[1]);
            state.chart.colors.push(item[1] >= 0 ? 1 : -1);
          });
        } else if (action.payload.data.length === 1) {
          state.chart.labels.push(action.payload.data[0][0]);
          state.chart.values.push(action.payload.data[0][1]);
          state.chart.colors.push(action.payload.data[0][1] >= 0 ? 1 : -1);
          state.chart.labels.push(action.payload.data[0][0]);
          state.chart.values.push(action.payload.data[0][1]);
          state.chart.colors.push(action.payload.data[0][1] >= 0 ? 1 : -1);
        }
      } else {
        state.chart = {
          labels: [],
          values: [],
          colors: [],
        };
      }
    },
    getPflTPortTransactionFailure: (state) => {},

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateEnableInfinity: (state, action: PayloadAction<boolean>) => {
      state.enableInfinity = action.payload;
    },

    fetchMoreProfitLossRequest: (
      state,
      action: PayloadAction<IFindPortTransactionParams>
    ) => {},

    fetchMoreProfitLossSuccess: (
      state,
      action: PayloadAction<IFindPortTransactionResponse>
    ) => {
      const data = state.list.concat(action.payload.data);
      state.list = data ?? [];
    },
  },
});

export const {
  searchProfitLossFailure,
  searchProfitLossRequest,
  searchProfitLossSuccess,
  getPflTPortTransactionRequest,
  getPflTPortTransactionSuccess,
  getPflTPortTransactionFailure,
  updateFilter,

  updatePage,
  updateEnableInfinity,
  fetchMoreProfitLossRequest,
  fetchMoreProfitLossSuccess,
} = profitLossSlice.actions;

export default profitLossSlice.reducer;
