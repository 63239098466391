import { MasterAccount } from "domain/models/User";
import { regPriceOneDecimal } from "helper/consts";
import isEmpty from "lodash/isEmpty";
import { DerTradeTypeEnum } from "modules/stock-order-der/redux/PlaceOrderDer";
import { ConditionalCommandTypeEnum } from "./ConditionalCommandType";

export const validateForm = (
  currentTicker: string,
  account: MasterAccount,
  price: number | string,
  volume: number | string,
  tickerInfoBasic: any,
  conditionalCommandType: any,
  priceUp: number | string,
  priceDown: number | string,
  cutLostAmp: number | string,
  grossAmp: number | string,
  cutLostPrice: number | string,
  limitOffset: number | string,
  rangeStop: number | string,
  type: DerTradeTypeEnum,
  maxLong?: number | string,
  maxShort?: number | string
) => {
  let error = validateCommonForm(
    currentTicker,
    account,
    price,
    volume,
    tickerInfoBasic,
    type,
    conditionalCommandType,
    maxLong,
    maxShort
  );

  if (
    !error &&
    [ConditionalCommandTypeEnum.UP, ConditionalCommandTypeEnum.DOWN].find(
      (x) => x === conditionalCommandType?.value
    )
  ) {
    error = validateActivePrice(
      conditionalCommandType?.value === ConditionalCommandTypeEnum.UP
        ? priceUp
        : priceDown
    );
  }

  if (
    error === null &&
    conditionalCommandType?.value === ConditionalCommandTypeEnum.BULLBEAR
  ) {
    error = validateBullBear(cutLostAmp, grossAmp, limitOffset);
  }
  if (
    error === null &&
    [
      ConditionalCommandTypeEnum.IFDONESTOP,
      ConditionalCommandTypeEnum.IFDONEGAIN,
      ConditionalCommandTypeEnum.OCO,
      ConditionalCommandTypeEnum.TRAILINGSTOP,
    ].find((x) => x === conditionalCommandType?.value)
  ) {
    if (
      conditionalCommandType?.value === ConditionalCommandTypeEnum.IFDONESTOP
    ) {
      error = validateOCO(cutLostAmp, "CutLostAmp", limitOffset);
    }

    if (
      conditionalCommandType?.value === ConditionalCommandTypeEnum.IFDONEGAIN
    ) {
      error = validateOCO(grossAmp, "GrossAmp", limitOffset);
    }

    if (conditionalCommandType?.value === ConditionalCommandTypeEnum.OCO) {
      error = validateOCO(cutLostPrice, "CutLostPrice", limitOffset);
    }

    if (
      conditionalCommandType?.value === ConditionalCommandTypeEnum.TRAILINGSTOP
    ) {
      error = validateOCO(rangeStop, "RangeStop", limitOffset);
    }
  }
  return error;
};

export const validateCommonForm = (
  currentTicker: string,
  account: MasterAccount,
  price: number | string,
  volume: number | string,
  tickerInfoBasic: any,
  type: DerTradeTypeEnum,
  conditionalCommandType: any,
  maxLong?: number | string,
  maxShort?: number | string
): { key: string; message: string } | null => {
  if (!currentTicker) {
    return {
      key: "errorCurrentTicker",
      message: "validation.emptyTicker",
    };
  }

  if (!account || isEmpty(account)) {
    return {
      key: "errorAccount",
      message: "validation.emptyAccount",
    };
  }

  if (
    conditionalCommandType?.value === ConditionalCommandTypeEnum.TRAILINGSTOP
  ) {
    return validateVolumn(volume, type, maxLong, maxShort);
  }
  return (
    validatePrice(
      price,
      tickerInfoBasic?.floorPrice,
      tickerInfoBasic?.ceilingPrice
    ) || validateVolumn(volume, type, maxLong, maxShort)
  );
};

export const validateActivePrice = (
  price: string | number | undefined
): { key: string; message: string } | null => {
  if (!price) {
    return {
      key: "errorActivePrice",
      message: "validation.emptyActivePrice",
    };
  }
  return null;
};

const validatePrice = (
  price: string | number | undefined,
  floorPrice?: number,
  ceilingPrice?: number
): { key: string; message: string } | null => {
  if (!price) {
    return {
      key: "errorPrice",
      message: "validation.emptyPrice",
    };
  }

  if (
    !isNaN(price as number) &&
    ((floorPrice !== undefined && price < floorPrice) ||
      (ceilingPrice !== undefined && price > ceilingPrice))
  ) {
    return {
      key: "errorPrice",
      message: "validation.rangePrice",
    };
  }

  if (
    typeof price !== "string" &&
    !regPriceOneDecimal.test(price?.toString())
  ) {
    return {
      key: "errorPrice",
      message: "validation.patternPrice",
    };
  }
  return null;
};

const validateVolumn = (
  volumn: number | string,
  type: DerTradeTypeEnum,
  maxLong?: number | string,
  maxShort?: number | string
) => {
  if (!volumn) {
    return {
      key: "errorVolume",
      message: "validation.emptyVolume",
    };
  }

  if (type === DerTradeTypeEnum.L) {
    if (maxLong !== undefined && Number(volumn) > maxLong) {
      return {
        key: "errorVolume",
        message: "validation.patternMaxLongVolume",
      };
    }
  }

  if (type === DerTradeTypeEnum.S) {
    if (maxShort !== undefined && Number(volumn) > maxShort) {
      return {
        key: "errorVolume",
        message: "validation.patternMaxShortVolume",
      };
    }
  }

  return null;
};

export const validateBullBear = (
  cutLostAmp: number | string,
  grossAmp: number | string,
  limitOffset: number | string
): { key: string; message: string } | null => {
  if (!grossAmp || grossAmp === "") {
    return {
      key: "errorGrossAmp",
      message: "validation.emptyGrossAmp",
    };
  }

  if (!cutLostAmp || cutLostAmp === "") {
    return {
      key: "errorCutLostAmp",
      message: "validation.emptyCutLostAmp",
    };
  }

  if (!limitOffset || limitOffset === "") {
    return {
      key: "errorLimitOffset",
      message: "validation.emptyMargin",
    };
  }
  return null;
};

export const validateOCO = (
  stopPrice: number | string,
  key: string,
  margin: number | string
) => {
  if (!stopPrice || stopPrice === "") {
    return {
      key: "error" + key,
      message: "validation.empty" + key,
    };
  }

  if (!margin || margin === "") {
    return {
      key: "errorLimitOffset",
      message: "validation.emptyMargin",
    };
  }

  return null;
};
