import React, { useEffect, useState } from "react";
import { useTable, useRowSelect, Column, useSortBy } from "react-table";
import { ReactComponent as IconSortUp } from "assets/image/svg/ic_sort_up.svg";
import { ReactComponent as IconSortDown } from "assets/image/svg/ic_sort_down.svg";
import { ReactComponent as IconSortDefault } from "assets/image/svg/ic_sort_default.svg";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import InfiniteScroll from "react-infinite-scroll-component";
import { OverlayTrigger } from "react-bootstrap";
import {
  Body,
  Header,
  Row,
  StyledTooltip,
  Table,
  TableCellContent,
  TableHeaderCellContent,
  Summary,
  Footer,
} from "../CommonTable/styles";
import NoData from "components/commons/CommonTable/NoData";

export type SelectTableProps<T extends object> = {
  columns: Array<Column<object>>;
  data: T[];
  loading?: boolean;
  setDataRow: any;
  setShowModal?: any;
  setSelectedRows?: (item: any) => void;
  fetchMoreData: () => void;
  enableInfinity: boolean;
  scrollableTarget?: string;
  wrapperLoadMoreHeight?: number;
  isHasFooter?: boolean;
  hiddenCheckbox?: boolean;
};

function SelectTable<T extends object>({
  columns,
  data,
  setDataRow,
  setShowModal,
  setSelectedRows,
  fetchMoreData,
  enableInfinity,
  scrollableTarget,
  wrapperLoadMoreHeight,
  isHasFooter,
  hiddenCheckbox = false,
}: SelectTableProps<T>) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns
          .filter((col: any) => col.hidden === true)
          .map((col) => col.id || col.accessor) as any,
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        hiddenCheckbox
          ? [...columns]
          : [
              // Let's make a column for selection
              {
                id: "selection",
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox all
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <TableHeaderCellContent align={"center"}>
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </TableHeaderCellContent>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                minWidth: 46,
                Cell: ({ row }) => (
                  <TableCellContent align={"flex-end"}>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      rowData={row}
                    />
                  </TableCellContent>
                ),
                Footer: (
                  <TableHeaderCellContent
                    className="portfolio-checkbox"
                    isFooter
                  ></TableHeaderCellContent>
                ),
              },
              ...columns,
            ]
      );
    }
  );
  React.useEffect(() => {
    // Bubble up the selection to the parent component
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map((row) => row.original));
    }
  }, [setSelectedRows, selectedFlatRows]);
  // Render the UI for your table

  const showModal = (row: any) => {
    setDataRow(row);
    if (setShowModal) {
      setShowModal(true);
    }
  };

  const className = (c: any) => {
    return c?.className ?? "";
  };

  const tooltip = (c: any) => {
    return c?.tooltip ?? false;
  };

  const [offsetX, setOffsetX] = useState(0);
  const handleScroll = () => {
    const currentOffsetX =
      document.getElementById("selecttable")?.parentElement?.scrollLeft || 0;
    setOffsetX(currentOffsetX);
  };
  // Sự liện scroll
  useEffect(() => {
    // const width =
    //   document.getElementById("commonTableInfinity")?.offsetWidth || 0;
    // setSummaryWidth(width);
    let scrollBox = document.getElementById("selecttable")?.parentElement;
    scrollBox?.addEventListener("scroll", handleScroll);
    return () => scrollBox?.removeEventListener("scroll", handleScroll);
  }, [document.getElementById("selecttable")?.offsetWidth]);

  return (
    <React.Fragment>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={enableInfinity ?? true}
        scrollableTarget={scrollableTarget}
        height={wrapperLoadMoreHeight || "100%"}
        loader={""}
        endMessage={""}
      >
        <Table
          id="selecttable"
          striped={false}
          hover={false}
          size="sm"
          {...getTableProps()}
        >
          <Header>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const SortIcon = (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IconSortDown />
                        ) : (
                          <IconSortUp />
                        )
                      ) : (
                        <IconSortDefault />
                      )}
                    </span>
                  );
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={className(column)}
                      style={{
                        minWidth: column?.minWidth,
                      }}
                    >
                      {column.render("Header", { SortIcon })}
                    </th>
                  );
                })}
              </tr>
            ))}
          </Header>
          <Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Row
                  {...row.getRowProps()}
                  onDoubleClick={() => showModal(row)}
                >
                  {row.cells.map((cell) => {
                    if (tooltip(cell?.column)) {
                      return (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <StyledTooltip id="tooltip">
                              {tooltip(cell?.column)}
                            </StyledTooltip>
                          }
                        >
                          <td
                            {...cell.getCellProps()}
                            className={className(cell?.column)}
                          >
                            {cell.render("Cell")}
                          </td>
                        </OverlayTrigger>
                      );
                    } else {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={className(cell?.column)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </Row>
              );
            })}
          </Body>
        </Table>
        {data.length === 0 && <NoData />}
        {isHasFooter && (
          <Summary
            style={{ width: "100%", left: 0 - offsetX }}
            striped={false}
            hover={false}
            size="sm"
            {...getTableProps()}
          >
            <Footer>
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={className(column)}
                        style={{
                          minWidth: column?.minWidth,
                          maxWidth: column?.maxWidth,
                        }}
                      >
                        {column.render("Footer")}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </Footer>
          </Summary>
        )}
      </InfiniteScroll>
    </React.Fragment>
  );
}
export default React.memo(SelectTable);
