import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISearchTransferBondParams,
  ICancelTransferBondParams,
} from "core/http/apis/transfer-bond/types";
import {
  TransferBond,
  ISearchTransferBondResponse,
  ICancelTransferBondResponse,
  IValidateTimeToTransferBondResponse,
} from "domain/models/TransferBond";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  status: number;
  fromDate: Date;
  toDate: Date;
}

export interface ReduxData {
  filter: IFilter;
  list: TransferBond[];
  loading: boolean;
  activeTab: string;
  statusCancel: boolean;
  statusValidateTime: boolean;
  isShowConfirmModal: boolean;
  itemDetail?: TransferBond;
}

const initialState: ReduxData = {
  filter: {
    status: 0,
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
  },
  list: [],
  loading: false,
  activeTab: "TRANSFER_BOND",
  statusCancel: false,
  statusValidateTime: false,
  isShowConfirmModal: false,
  //   //   isShowConfirm isShowConfirmModal: false,Modal: fa isShowConfirmModal: false,lse,
};

const transferBondSlice = createSlice({
  name: "transferBondSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchTransferBondRequest: (
      state,
      action: PayloadAction<ISearchTransferBondParams>
    ) => {
      state.loading = true;
    },
    searchTransferBondSuccess: (
      state,
      action: PayloadAction<ISearchTransferBondResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchTransferBondFailure: (state) => {
      state.loading = false;
    },

    cancelTransferBondRequest: (
      state,
      action: PayloadAction<ICancelTransferBondParams>
    ) => {
      state.statusCancel = false;
    },
    cancelTransferBondSuccess: (
      state,
      action: PayloadAction<ICancelTransferBondResponse>
    ) => {
      state.statusCancel = true;
    },
    cancelTransferBondFailure: (state) => {
      state.statusCancel = false;
    },

    validateTimeToTransferBondRequest: (state) => {
      state.statusValidateTime = false;
    },
    validateTimeToTransferBondSuccess: (
      state,
      action: PayloadAction<IValidateTimeToTransferBondResponse>
    ) => {
      state.statusValidateTime = true;
    },
    validateTimeToTransferBondFailure: (state) => {
      state.statusValidateTime = false;
    },

    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: TransferBond;
      }>
    ) => {},
    updateModalStatusSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: TransferBond;
      }>
    ) => {
      state.itemDetail = action.payload.itemDetail && action.payload.itemDetail;
      state.isShowConfirmModal = action.payload.isVisible;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchTransferBondFailure,
  searchTransferBondRequest,
  searchTransferBondSuccess,

  cancelTransferBondRequest,
  cancelTransferBondSuccess,
  cancelTransferBondFailure,
  validateTimeToTransferBondRequest,
  validateTimeToTransferBondSuccess,
  validateTimeToTransferBondFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,

  updateFilter,
} = transferBondSlice.actions;

export default transferBondSlice.reducer;
