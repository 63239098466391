import React, { useRef, useLayoutEffect, memo, useEffect } from "react";
import Header from "./Header";
import Content from "./ContentPriceLeft";
import { useSelector, useDispatch } from "react-redux";
import {
  requestChangePeriod,
  updateTicker,
  changeWidgetContainerHeight,
  cleanData,
  requestTopPriceLeft,
} from "modules/market/redux/topPriceLeft";
import { TopPricePeriod } from "./types";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { Widgetcontainer } from "./styles";
import { useEffectOnce, useInterval } from "react-use";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { activeTabTopPriceLeftSelector } from "modules/market/redux/selectors";

import { LastSale, SystemState } from "domain/protoNew/auto_trading_pb";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

function TopPriceLeft(): React.ReactElement {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabTopPriceLeftSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);
  const widgetContainerRef = useRef<HTMLDivElement>(null);
  function handleChangePeriod(period: TopPricePeriod) {
    dispatch(requestChangePeriod(period));
  }

  useLayoutEffect(() => {
    if (widgetContainerRef?.current?.getBoundingClientRect().height) {
      dispatch(
        changeWidgetContainerHeight(
          widgetContainerRef?.current?.getBoundingClientRect().height
        )
      );
    }
  });

  useInterval(() => {
    if (activeTab !== "today") return;
    dispatch(requestTopPriceLeft());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    const eventListenerLastSale = (data: LastSale.AsObject) => {
      dispatch(updateTicker(data));
    };
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(requestTopPriceLeft());
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    dispatch(requestChangePeriod(activeTab));

    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
      dispatch(cleanData());
    };
  });

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      dispatch(requestChangePeriod(activeTab));
    }
  }, [isVisibilityPage]);

  return (
    <Widgetcontainer ref={widgetContainerRef}>
      <Header
        activeTab={activeTab}
        onChangePeriod={handleChangePeriod}
      ></Header>
      <Content />
    </Widgetcontainer>
  );
}

export default memo(TopPriceLeft);
