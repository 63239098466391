import { RouterConfig } from "./AppRoute";
// import authRoutes from "modules/auth/routes";
import categorieRoutes from "modules/categories/routes";
import marketRoutes from "modules/market/routes";
import marginContractRoutes from "modules/margin-contract/routes";
import marginSecuritiesRoutes from "modules/margin-securities/routes";
import rightInfoRoutes from "modules/right-info/routes";
import statisticOnday from "modules/statistic-onday/routes";
import statisticPrice from "modules/statistic-price/routes";
import statisticUpdown from "modules/statistic-updown/routes";
import statisticHighestUpdown from "modules/statistic-highest-updown/routes";
import mrgContractDebit from "modules/mrg-contract-debit/routes";
import marginContractWatch from "modules/margin-contract-watch/routes";
import transferLocal from "modules/transfer-local/routes";
import stockOrderRoutes from "modules/stock-order/routes";
import stockOrderDerRoutes from "modules/stock-order-der/routes";
import transferInternal from "modules/transfer-internal/routes";
import orderHistory from "modules/order-history/routes";
import transferShare from "modules/transfer-share/routes";
import screenersRoutes from "modules/screeners/routes";
import holdUnholdBankAccount from "modules/hold-unhold-bank-account/routes";
import advanceRoutes from "modules/advance/routes";
import profitLoss from "modules/profit-loss/routes";
import contractDebit from "modules/contract-debit/routes";
import accountStatement from "modules/account-statement/routes";
import depositPower from "modules/deposit-power/routes";
import createContract from "modules/create-contract/routes";
import depositContract from "modules/deposit-contract/routes";
import confirmation from "modules/confirmation/routes";
import marginSecuritiesPortfolio from "modules/margin-securities-portfolio/routes";
import manegerMoney from "modules/managerMoney/routes";
import SubscribeBuy from "modules/subscribeBuy/routes";
import depositList from "modules/deposit-list/routes";
import addFrameworkContractForm from "modules/add-framework-constract-form/routes";
import profitMoney from "modules/profitMoney/routes";
import subAccoRegister from "modules/subAccoregister/routes";
import transferHistory from "modules/transfer-history/routes";
import transferBond from "modules/transfer-bond/routes";
import bidAskBond from "modules/bid-ask-bond/routes";
import assetsIncurred from "modules/assetsIncurred/routes";
import bidAskBondOrder from "modules/bid-ask-bond-order/routes";
import registerToTransferBonds from "modules/register-to-transfer-bonds/routes";
import placeOrderTransferBonds from "modules/place-order-transfer-bonds/routes";
import changeLimit from "modules/change-limit/routes";
import professionalInvestor from "modules/professional-investor/routes";
import placeOrderRetailBonds from "modules/place-order-retail-bonds/routes";
import bidAskDBondOrder from "modules/bid-ask-d-bond-order/routes";
import placeOrderBondAdv from "modules/placeOrderBondAdv/routes";
import advertisingOrder from "modules/advertisingOrder/routes";
import guideVideo from "modules/guideVideo/routes";
import TechAnalysis from "modules/techAnalysis/routes";

// const routes: RouterConfig[] = [...authRoutes];
const routes: RouterConfig[] = [
  ...categorieRoutes,
  ...stockOrderRoutes,
  ...stockOrderDerRoutes,
  ...marketRoutes,
  ...marginContractRoutes,
  ...marginSecuritiesRoutes,
  ...rightInfoRoutes,
  ...statisticOnday,
  ...statisticPrice,
  ...statisticUpdown,
  ...statisticHighestUpdown,
  ...mrgContractDebit,
  ...marginContractWatch,
  ...transferLocal,
  ...orderHistory,
  ...profitLoss,
  ...advanceRoutes,
  ...holdUnholdBankAccount,
  ...screenersRoutes,
  ...transferShare,
  ...accountStatement,
  ...transferInternal,
  ...depositPower,
  ...createContract,
  ...depositContract,
  ...marginSecuritiesPortfolio,
  ...confirmation,
  ...depositList,
  ...manegerMoney,
  ...SubscribeBuy,
  ...addFrameworkContractForm,
  ...registerToTransferBonds,
  ...placeOrderTransferBonds,
  ...profitMoney,
  ...subAccoRegister,
  ...transferHistory,
  ...transferBond,
  ...bidAskBond,
  ...assetsIncurred,
  ...changeLimit,
  ...bidAskBondOrder,
  ...professionalInvestor,
  ...contractDebit,
  ...placeOrderRetailBonds,
  ...bidAskDBondOrder,
  ...placeOrderBondAdv,
  ...advertisingOrder,
  ...guideVideo,
  ...TechAnalysis,
];

export default routes;
