import React, { memo } from "react";
import { HorizontalBar } from "react-chartjs-2";
import cloneDeep from "lodash/cloneDeep";
import { ChartOptions, ChartPluginsOptions } from "chart.js";

import { ChartData } from "modules/tickerDetails/redux/VN30StatBasketContribution";

function HorizontalBarChart({
  dataChart,
  option,
  plugins,
}: {
  dataChart: ChartData;
  option: ChartOptions;
  plugins: ChartPluginsOptions[];
}) {
  return (
    <HorizontalBar
      data={{
        labels: dataChart.labels,
        datasets: cloneDeep(dataChart.datasets),
      }}
      options={option}
      plugins={plugins}
    />
  );
}

export default memo(HorizontalBarChart);
