import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
  height: 100%;
`;

export const ContentTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.grey0};

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.brandPrimary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 100px;
  height: 32px;
  background-color: ${({ theme }) => theme.background3};

  border: 1px solid ${({ theme }) => theme.brandPrimary};
  border-radius: 4px;
`;
