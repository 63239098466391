import { FC } from "react";
import { useSelector } from "react-redux";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import Table from "./Table";
import Filter from "./Filters";

import { activeTabSelector } from "modules/statistic-updown/selectors";
import { STATISTIC_UPDOWN_TABS } from "modules/statistic-updown/constants";

const StatisticUpdownContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === STATISTIC_UPDOWN_TABS.STATISTIC_UPDOWN && (
        <>
          <Filter />
          <Table />
        </>
      )}
    </Wrap>
  );
};
export default StatisticUpdownContent;
