import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Wrap,
  WrapLabel,
  WrapContent,
  WrapItem,
  LabelItem,
  ContentItem,
  WrapIcons,
  WrapIconMenu,
} from "./styles";
import widgets from "components/widgets/TechAnalysisWidgets";
import { Widget } from "components/widgets/types";
import { ReactComponent as IconTop } from "assets/image/svg/techAnalysisTop.svg";
import { ReactComponent as IconDown } from "assets/image/svg/techAnalysisDown.svg";
import { ReactComponent as IconClose } from "assets/image/svg/techAnalysisClose.svg";
import { ReactComponent as IconMenu } from "assets/image/svg/techAnalysisMenu.svg";
import { Dropdown } from "react-bootstrap";

const ContentRight: FC = () => {
  const dataWidget: Widget[] = [...widgets];
  dataWidget.shift();
  const [data, setData] = useState<Widget[]>(dataWidget);

  const moveUp = (id: any) => {
    const index = data.findIndex((e: Widget) => e.id === id);
    if (index > 0) {
      const dataSet = [...data];
      const el = dataSet[index];
      dataSet[index] = dataSet[index - 1];
      dataSet[index - 1] = el;
      setData(dataSet);
    }
  };

  const moveDown = (id: any) => {
    const index = data.findIndex((e: Widget) => e.id === id);
    if (index !== -1 && index < data.length - 1) {
      const dataSet = [...data];
      const el = dataSet[index];
      dataSet[index] = dataSet[index + 1];
      dataSet[index + 1] = el;
      setData(dataSet);
    }
  };

  const removeItem = (id: any) => {
    const dataSet = data.filter((e: Widget) => e?.id !== id);
    setData(dataSet);
  };

  const renderItem = () => {
    const render = data
      ? data.map((e: Widget, index: any) => {
          const Component = e.component;
          return (
            <WrapItem>
              <LabelItem>
                <FormattedMessage id={e.name} />
                <WrapIcons>
                  {index !== 0 && <IconTop onClick={() => moveUp(e?.id)} />}
                  {index !== data.length - 1 && (
                    <IconDown onClick={() => moveDown(e?.id)} />
                  )}
                  <IconClose onClick={() => removeItem(e?.id)} />
                </WrapIcons>
              </LabelItem>
              <ContentItem>
                <Component />
              </ContentItem>
            </WrapItem>
          );
        })
      : "";
    return render;
  };

  const addItem = (item: Widget) => {
    const index = data.findIndex((e: Widget) => e.id === item.id);
    if (index < 0) {
      const dataSet = [...data, item];
      setData(dataSet);
    }
  };

  const renderDropDown = () => {
    const content =
      dataWidget &&
      dataWidget.map((e: Widget) => (
        <Dropdown.Item onClick={() => addItem(e)}>
          <FormattedMessage id={e.name} />
        </Dropdown.Item>
      ));
    return content;
  };

  return (
    <Wrap>
      <WrapLabel>
        <FormattedMessage id="techAnalysis.labelRight" />
        <WrapIconMenu>
          {/* <IconMenu
            onMouseOver={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          /> */}
          <Dropdown>
            <Dropdown.Toggle as={IconMenu} />
            <Dropdown.Menu>
              <Dropdown.Header>
                <FormattedMessage id="techAnalysis.labelDropdown" />
              </Dropdown.Header>
              {renderDropDown()}
            </Dropdown.Menu>
          </Dropdown>
        </WrapIconMenu>
      </WrapLabel>
      <WrapContent>{renderItem()}</WrapContent>
    </Wrap>
  );
};

export default ContentRight;
