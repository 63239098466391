import { TRADING_VIEW_URL } from "AppConfig";
import storage from "helper/storage";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";
import { TickerGraph } from "./styles";
import { changeSelectedTicker } from "modules/market/redux";
import GlobalData from "helper/globalData";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import { EMarketCodeNew, SecType } from "helper/consts";
import { ThemeType } from "themes/types";
import { updateCurrentTicker } from "modules/stock-order-der/redux/PlaceOrderDer";

// TODO: Should implement component behavior when TRADING_VIEW_URL is missing from ENV
const url = new URL(TRADING_VIEW_URL!);

export type TickerGraphProps = {
  selectedTicker?: string | null;
};

function TickerGraphWidget({ selectedTicker }: TickerGraphProps) {
  const themeType = useSelector(themeTypeSelector);

  const dispatch = useDispatch();
  const [isRender, setIsRender] = useState(true);

  if (isRender) {
    setIsRender(false);
    url.searchParams.set("sym", selectedTicker!);
  }

  const themeChart = (themeType: ThemeType) => {
    switch (themeType) {
      case ThemeType.BLACK:
        return "dark";
      case ThemeType.LIGHT:
        return "light";
      default:
        return "dark";
    }
  };

  url.searchParams.set("theme", themeChart(themeType));
  url.searchParams.set("token", storage.getAccessToken());

  useEffect(() => {
    setIsRender(true);
  }, [themeType]);

  useEffect(() => {
    var iframe: any = document.querySelectorAll("[id='tradingView']");
    if (!iframe) return;
    // Bắn event cho thằng trading view
    for (var i = 0; i < iframe.length; i++) {
      var content = iframe[i].contentWindow || iframe[i].contentDocument;
      content.postMessage(
        {
          name: "parent-set-symbol",
          data: {
            symbol: selectedTicker,
          },
        },
        "*"
      );
    }
  }, [selectedTicker]);

  useEffect(() => {
    const handleChangeTicker = (tickerCode: any) => {
      if (tickerCode?.name !== "tv-changed-symbol") return;
      const arr = tickerCode?.data?.symbol.split(":");
      const tickerName = arr.length < 2 ? arr[0] : arr[1];
      dispatch(changeSelectedTicker(tickerName));
      const ticker = GlobalData.getTickerInfoNew(tickerName);
      const secType = ticker?.sectype;
      const marketCd = ticker?.marketcd;
      if (
        (secType === SecType.S ||
          secType === SecType.CW ||
          secType === SecType.TP) &&
        marketCd !== EMarketCodeNew.DER_IDX
      ) {
        dispatch(onChangeCurrentTicker(tickerName));
      }

      if (marketCd === EMarketCodeNew.DER_IDX) {
        dispatch(updateCurrentTicker(tickerName));
      }
    };

    window.addEventListener("message", (e) => handleChangeTicker(e.data));
    return () => {
      window.removeEventListener("message", (e) => handleChangeTicker(e.data));
    };
  }, []);

  return (
    <TickerGraph>
      <iframe
        src={url.toString()}
        width="100%"
        height="100%"
        id="tradingView"
      />
    </TickerGraph>
  );
}

export default memo(TickerGraphWidget);
