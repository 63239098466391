import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Header = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.grey90};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  padding: 0 12px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
  text-align: center;
  flex: 1;
`;

export const Body = styled.div`
  /* background-color: ${({ theme }) => theme.grey100}; */
  height: 100%;
  padding: 24px;
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .flex-fill {
    flex: 1;
  }
  margin-bottom: 16px;
  .table {
    border-radius: 8px;
  }
  .flex-3 {
    flex: 3;
  }
  .flex-2 {
    flex: 2;
  }
`;

export const ContentParent = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.bgElevated4} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
`;

export const TitleContent = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  margin: 24px 0;
  &:first-child {
    margin: 0 0 24px 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Label = styled.div<{ isTextRight?: boolean }>`
  color: ${({ theme }) => theme.grey30};
  flex: 1;
  text-align: ${({ isTextRight }) => (isTextRight ? `right` : `left`)};
`;

export const Value = styled.div`
  flex: 3;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Space = styled.div`
  flex: 2;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 230px);
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 14px;
  height: 14px;
`;

export const ButtonExit = styled.button`
  background: transparent;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
`;

export const ContentItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const TextOtp = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
`;
