import { FC } from "react";
import { VolumeCell as VolumeCellWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { RootState } from "redux/store";
import { WatchListState } from "modules/stock-order/redux/watchList";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import numeral from "numeral";

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const volumeSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.secdetailinfo?.totalqty
  );
};

interface Props {
  ticker: string;
}

const VolumeCell: FC<Props> = ({ ticker }) => {
  const volume = useSelector(volumeSelector(ticker));

  return (
    <VolumeCellWrapper>
      <BlinkableCell value={volume ? numeral(volume).format("0,0") : "-"} />
    </VolumeCellWrapper>
  );
};

export { VolumeCell };
