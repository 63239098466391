import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BubbleChartProps } from "components/widgets/generals/MarketStats/MarketBubbleChart/types";

export type StatBasketContributionState = {
  chartData: BubbleChartProps;
  loading: boolean;
  error: string | undefined;
};

const initialState: StatBasketContributionState = {
  chartData: {
    scaleData: [],
    colors: [],
  },
  loading: false,
  error: undefined,
};

const marketStatsSlice = createSlice({
  name: "marketStatsSlice",
  initialState,
  reducers: {
    fetchMarketStatsRequest: (state) => {
      state.loading = true;
    },
    fetchMarketStatsSuccess: (
      state,
      action: PayloadAction<BubbleChartProps>
    ) => {
      state.chartData = action.payload;
      state.loading = false;
    },
    fetchMarketStatsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchMarketStatsRequest,
  fetchMarketStatsSuccess,
  fetchMarketStatsFailure,
} = marketStatsSlice.actions;

export default marketStatsSlice.reducer;
