import { SelectPlaceOrder, Spacer } from "components/commons";
import React, { memo } from "react";
import { FormControl, FormTitle } from "../styles";

interface Props {
  value: any;
  onChange: (item: any) => void;
  label: string;
  error?: string;
  listItem: any;
}
const InputConditionTradeType: React.FC<Props> = memo(
  ({ value, onChange, label, listItem, error }) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <FormTitle>{label}</FormTitle>
          <FormControl>
            <SelectPlaceOrder
              name="conditionTradeType"
              className="flex-fill"
              options={listItem}
              value={value}
              onChange={onChange}
            />
            <div>
              {error ? (
                <div>
                  <span className="text-text-danger">{error}</span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </FormControl>
        </div>
        <Spacer size="s" />
      </>
    );
  }
);
export default InputConditionTradeType;
