import { Container } from "./styles";
import { FC } from "react";
import GroupBtn from "./groupBtn";
import ListVideo from "./listVideo";

const GuideVideoContent: FC = () => {
  return (
    <Container>
      <GroupBtn />
      <ListVideo />
    </Container>
  );
};
export default GuideVideoContent;
