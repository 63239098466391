import { Body, Container, Title, Footer } from "./styles";
import { memo } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalSuccess } from "modules/auth/redux";
import { FormattedMessage, useIntl } from "react-intl";
import { createSelector } from "@reduxjs/toolkit";
import { authState } from "modules/auth/selectors";

const authSelector = createSelector(authState, (state) => ({
  hasIntlKey: state.hasIntlKeyModalSuccess,
  statusModalSuccess: state.statusModalSuccess,
  contentModalSuccess: state.contentModalSuccess,
}));

const ModalSuccess = memo(() => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { hasIntlKey, statusModalSuccess, contentModalSuccess } =
    useSelector(authSelector);
  const RADIUS_4 = 4;
  return (
    <>
      <Modal
        size="sm"
        show={statusModalSuccess}
        centered
        keyboard={false}
        dialogClassName="modal-284"
      >
        <Container>
          <Title>
            <FormattedMessage id="common.titleAlert" />
          </Title>
          <div className="footer">
            {hasIntlKey ? (
              <Body>
                {intl.formatMessage({
                  id: contentModalSuccess,
                })}
              </Body>
            ) : (
              <Body>{contentModalSuccess}</Body>
            )}
            <Footer>
              <Button
                size="l"
                style={{ flex: 1 }}
                color="accept"
                fill={true}
                className="flex-1"
                radius={RADIUS_4}
                onClick={() =>
                  dispatch(
                    toggleModalSuccess({
                      content: "",
                      hasIntlKey: false,
                    })
                  )
                }
              >
                <FormattedMessage id="common.buttonClose" />
              </Button>
            </Footer>
          </div>
        </Container>
      </Modal>
    </>
  );
});

export default ModalSuccess;
