import styled from "styled-components/macro";
import { convertHexToRGBA } from "helper/utils";

export const FooterWatchList = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.background3};
  align-items: center;
  height: 40px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.border1};
`;

export const ConsoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 480px;
  border-radius: 0;
  overflow: hidden;
`;

export const CardTable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .card-content {
    scrollbar-color: rgb(94, 102, 115) #101b27;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 6px;
      background-color: ${({ theme }) => theme.grey70};
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(94, 102, 115);
      border-radius: 3px;
      height: 90px;
    }
    scroll-behavior: smooth;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const TableBody = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.grey100};
`;

export const WatchListWrapper = styled.div<{ isShowPlaceOrder: boolean }>`
  display: flex;
  flex: 1;
  border-radius: 8px;
  /* grid-template-rows: 3fr minmax(400px, 1fr); */
  ${({ isShowPlaceOrder }) =>
    isShowPlaceOrder &&
    `
    display: grid;
    grid-template-columns: 3fr minmax(400px, 1fr);
    gap: 16px;`}
`;

export const SetCommandWrapper = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.grey100};
  position: relative;
  .icon-exit {
    width: 14px;
    height: 14px;
    margin: 10px;
    cursor: pointer;
  }
  .ticker-info-wrapper {
    height: auto;
    padding: 8px 0;
  }
  .volume-and-time-wrapper {
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 30px);
  position: absolute;
  width: 100%;
  overflow: auto;
  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
    background: ${({ theme }) => theme.background1};
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }
`;

export const SetCommandHeader = styled.div`
  padding: 6px 16px;
  background: ${({ theme }) => theme.grey90};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  .icon-exit {
    width: 8px;
    height: 8px;
  }
`;
export const SubTitle = styled.div`
  padding: 16px 0 0 16px;
  font-size: ${({ theme }) => theme.typeFaces.title.size};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
  border-top: 1px solid ${({ theme }) => theme.bgElevated4};
  text-transform: uppercase;
`;

export const BtnGroup = styled.div`
  height: ${({ theme }) => theme.tables.content.height};
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
  margin: 0 16px;
  display: flex;
  filter: drop-shadow(0px 8px 12px rgba(13, 17, 21, 0.5));
  border-radius: 8px;
  overflow: hidden;
`;
export const ButtonBuySell = styled.div<{
  color: "bgGreen" | "bgRed";
}>`
  background-color: ${({ theme, color }) => theme[color]};
  color: ${({ theme }) => theme.textcolorWhite};
  padding: 8px;
  height: ${({ theme }) => theme.tables.content.height};
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: 100;
  font-size: 15px;
  flex: 1;
  :hover {
    background: ${({ theme, color }) => convertHexToRGBA(theme[color], 0.7)};
  }
`;

export const ConsoleOverlay = styled.div`
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 999;
`;
