import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";

export type InfoQuoteState = {
  percentBuy: number;
  sumAsk: number;
  sumBid: number;
  detailInfo: SecQuotesDetailResponse.SecDetailInfo.AsObject;
  loading: boolean;
  error: string | undefined;
};

const initialState: InfoQuoteState = {
  percentBuy: 50,
  sumAsk: 0,
  sumBid: 0,
  detailInfo: new SecQuotesDetailResponse.SecDetailInfo().toObject(),
  loading: false,
  error: undefined,
};

const infoQuote = createSlice({
  name: "infoQuoteStockOderSlice",
  initialState,
  reducers: {
    getInfoQuote: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.percentBuy = initialState.percentBuy;
      state.sumAsk = initialState.sumAsk;
      state.sumBid = initialState.sumBid;
      state.detailInfo = initialState.detailInfo;
      state.error = undefined;
    },
    getInfoQuoteSuccess: (
      state,
      action: PayloadAction<SecQuotesDetailResponse.SecDetailInfo.AsObject>
    ) => {
      state.loading = false;
      let dataDetailInfo = action.payload;
      state.detailInfo = dataDetailInfo;

      const sumAsk =
        dataDetailInfo.best1offerqty +
        dataDetailInfo.best2offerqty +
        dataDetailInfo.best3offerqty;
      const sumBid =
        dataDetailInfo.best1bidqty +
        dataDetailInfo.best2bidqty +
        dataDetailInfo.best3bidqty;
      state.sumAsk = sumAsk;
      state.sumBid = sumBid;
      if (sumBid !== 0 || sumAsk !== 0) {
        state.percentBuy = (sumBid / (sumAsk + sumBid)) * 100;
      } else {
        state.percentBuy = 50;
      }
    },
    getInfoQuoteFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearData: (state) => {
      state.percentBuy = 50;
      state.sumAsk = 0;
      state.sumBid = 0;
      state.detailInfo = new SecQuotesDetailResponse.SecDetailInfo().toObject();
    },
    getInfoQuoteUpdate: (state, action: PayloadAction<any>) => {
      Object.assign(state.detailInfo, action.payload);
      const sumAsk =
        state.detailInfo.best1offerqty +
        state.detailInfo.best2offerqty +
        state.detailInfo.best3offerqty;
      const sumBid =
        state.detailInfo.best1bidqty +
        state.detailInfo.best2bidqty +
        state.detailInfo.best3bidqty;
      state.sumAsk = sumAsk;
      state.sumBid = sumBid;
      if (sumBid !== 0 || sumAsk !== 0) {
        state.percentBuy = (sumBid / (sumAsk + sumBid)) * 100;
      } else {
        state.percentBuy = 50;
      }
    },
  },
});

export const {
  getInfoQuote,
  getInfoQuoteSuccess,
  getInfoQuoteUpdate,
  getInfoQuoteFailure,
  clearData,
} = infoQuote.actions;

export default infoQuote.reducer;
