import styled from "styled-components/macro";

export const Wrapper = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background1};
  border: 1px solid ${({ theme }) => theme.border1};
`;

export const HeaderModal = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  background-color: ${({ theme }) => theme.background3};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ModalTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const BodyModal = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.background2};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const BodyModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelCell = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
`;

export const TextCell = styled.div`
  flex: 1;
  text-align: right;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ConfirmRow = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0 8px;
`;

export const CheckBoxConfirmCell = styled.div`
  user-select: none;
`;

export const TextConfirmCell = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.textcolorWhite};
  user-select: none;
  cursor: pointer;
`;

export const ActionsRow = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonCell = styled.div`
  flex: 1;
`;

export const DivCheckbox = styled.div`
  display: flex;
  line-height: 24px;
`;

export const CustomHr = styled.hr`
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.border1};
`;
