import { FC } from "react";
import { ReactComponent as SelectedIcon } from "assets/image/svg/checkBank.svg";
import { ReactComponent as UnSelectedIcon } from "assets/image/svg/unCheckBank.svg";
import { StyledCheckBox, Container } from "./styles";
import { FormattedMessage } from "react-intl";

interface IProps {
  checked?: boolean;
  disabled?: boolean;
  values: number;
  onChange?: () => void;
  onClick: (values: number | null) => void;
  text: string;
}

const CheckBox: FC<IProps> = ({ checked, values, disabled, onClick, text }) => {
  //   const inputRef = useRef<HTMLInputElement>(null);

  const clickLabelHandler = () => {
    const value = !checked ? values : null;
    onClick(value);
  };

  return (
    <Container onClick={() => clickLabelHandler()}>
      <StyledCheckBox disabled={disabled}>
        <div>{checked ? <SelectedIcon /> : <UnSelectedIcon />}</div>
      </StyledCheckBox>
      {` `}
      <FormattedMessage id={`${text}`} />
    </Container>
  );
};

export default CheckBox;
