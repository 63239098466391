import {
  FC,
  ReactNode,
  useState,
  useRef,
  KeyboardEvent,
  ChangeEvent,
} from "react";
import { Range, Handle } from "rc-slider";
import { NumberInput } from "components/commons";
import { useOutsideClick } from "helper/cusHooks";
import {
  Container,
  InputRow,
  LabelCell,
  InputCell,
  Space,
  RangeRow,
} from "./styles";
import numeral from "numeral";

interface IProps {
  label?: string | ReactNode;
  placeholder?: string[];
  unit?: string;
  min?: number;
  max?: number;
  name: string;
  getRangeValue: (values: number[], name: string) => void;
}

const InputRange: FC<IProps> = ({
  label,
  placeholder = ["", ""],
  unit,
  min = 0,
  max = 100,
  name,
  getRangeValue,
}) => {
  const [visibleRange, setVisibleRange] = useState<boolean>(false);
  const inputRangeRef = useRef<HTMLDivElement | null>(null);
  const [rangeValue, setRangeValue] = useState<number[]>([
    min || 0,
    +Math.floor(max * 100) / 100 || 100,
  ]);
  const [firstInputValue, setFirstInputValue] = useState<number | string>(
    rangeValue[0] || 0
  );
  const [secondInputValue, setSecondInputValue] = useState<number | string>(
    rangeValue[1] || 0
  );

  const changeRangeHandler = (value: number[]) => {
    setRangeValue(value);

    setFirstInputValue(value[0]);
    setSecondInputValue(value[1]);
  };

  const blurRangeHandler = (values: number[]) => {
    getRangeValue(values, name);
  };

  const rangeHandle = (handleProps: any) => {
    const { value, ...rest } = handleProps;
    return (
      <Handle value={value} {...rest} tabIndex="-1">
        {numeral(value).format("0,0.[00]a")} {unit}
      </Handle>
    );
  };

  const changeFirstInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setFirstInputValue(value);
  };

  const changeSecondInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setSecondInputValue(value);
  };

  const focusFirstInputHandler = () => {
    setFirstInputValue(rangeValue[0] || "");
    setVisibleRange(true);
  };

  const focusSecondInputHandler = () => {
    setSecondInputValue(rangeValue[1] || "");
    setVisibleRange(true);
  };

  const blurInputHandler = () => {
    const firstValueRefFormatter = (
      typeof numeral(firstInputValue).value() === "number"
        ? numeral(firstInputValue).value()
        : rangeValue[0]
    ) as number;
    const secondValueRefFormatter =
      numeral(secondInputValue).value() || rangeValue[1];

    if (firstValueRefFormatter >= secondValueRefFormatter) {
      setFirstInputValue(rangeValue[0]);
      setSecondInputValue(rangeValue[1]);
      return;
    }

    if (firstValueRefFormatter < min || secondValueRefFormatter > max) {
      const newFirstValue =
        firstValueRefFormatter < min ? min : firstValueRefFormatter;
      const newSecondValue =
        secondValueRefFormatter > max ? max : secondValueRefFormatter;

      setFirstInputValue(numeral(newFirstValue).format("0,0.[00]"));
      setSecondInputValue(numeral(newSecondValue).format("0,0.[00]"));
      setRangeValue([newFirstValue, newSecondValue]);
      getRangeValue([newFirstValue, newSecondValue], name);
      return;
    }

    if (
      firstValueRefFormatter !== rangeValue[0] ||
      secondValueRefFormatter !== rangeValue[1]
    ) {
      getRangeValue([firstValueRefFormatter, secondValueRefFormatter], name);
    }
    setFirstInputValue(numeral(firstValueRefFormatter).format("0,0.[00]"));
    setSecondInputValue(numeral(secondValueRefFormatter).format("0,0.[00]"));
    setRangeValue([firstValueRefFormatter, secondValueRefFormatter]);
  };

  const handleKeyDownSecondInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      setVisibleRange(false);
      return;
    }

    if (e.key === "Enter") {
      blurInputHandler();
    }
  };

  const handleKeyDownFirstInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      blurInputHandler();
    }
  };

  useOutsideClick(inputRangeRef, () => {
    setVisibleRange(false);
  });

  return (
    <Container ref={inputRangeRef}>
      <InputRow>
        <LabelCell>{label}</LabelCell>
        <InputCell>
          <NumberInput
            placeholder={placeholder[0]}
            unit={unit}
            value={firstInputValue}
            onChange={changeFirstInputHandler}
            onFocus={focusFirstInputHandler}
            onBlur={blurInputHandler}
            onKeyDown={handleKeyDownFirstInput}
            fullWidth
            thousandSeparator={true}
            decimalScale={2}
          />
          <Space>-</Space>
          <NumberInput
            placeholder={placeholder[1]}
            unit={unit}
            value={secondInputValue}
            onChange={changeSecondInputHandler}
            onFocus={focusSecondInputHandler}
            onBlur={blurInputHandler}
            onKeyDown={handleKeyDownSecondInput}
            fullWidth
            thousandSeparator={true}
            decimalScale={2}
          />
        </InputCell>
      </InputRow>
      <RangeRow visibleRange={visibleRange}>
        <Range
          allowCross={false}
          value={rangeValue}
          onChange={changeRangeHandler}
          handle={rangeHandle}
          min={min}
          max={max}
          onAfterChange={blurRangeHandler}
          step={0.01}
        />
      </RangeRow>
    </Container>
  );
};

export default InputRange;
