import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { SystemState } from "modules/system/redux";
import storageLocal from "helper/storage";
import {
  MarketIndexInfo,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";

export const systemSelector = (state: RootState) => state.system;

export const visibleModalConfirmOpenLoginSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleModalConfirmOpenLogin
);

export const visibleModalPinCodeSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleModalPinCode
);

export const visibleModalSetPasswordSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleModalSetPassword
);

export const visibleModalSetPasswordSuccessSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.common.visibleModalSetPasswordSuccess
);

export const totalNotificationSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.totalNotification
);

export const visibleModalConfirmCleanAllSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleModalConfirmCleanAll
);

export const visibleSideBarSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleSideBar
);

export const visibleModalSupportSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleModalSupport
);

export const visibleModalVSDSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleModalVSD
);

export const titleTabBrowserSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.titleTabBrowser
);

export const statusInternetSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.statusInternet
);

export const tabOtpSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.common.tabOtp[storageLocal.getMasterAccount()]?.tab
);

export const isVisibilityPageSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.isVisibilityPage
);

export const hasMqttOfflineSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.hasMqttOffline
);

export const menuDotRedSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.menuDotRed
);

export const currentDateSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.currentDate
);

export const stateOTPnewSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.stateOTPnew
);

export const listTickerScribleSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.listTickerScrible
);

export const listIndexScribleSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.listIndexScrible
);

export const valueHeaderCardSelector = (
  indexCd: string,
  value: keyof MarketIndexInfo.AsObject
) =>
  createSelector(
    systemSelector,
    (systemState: SystemState) => systemState.headerCard.data[indexCd]?.[value]
  );
export const colorHeaderCardSelector = (indexCd: string) =>
  createSelector(
    systemSelector,
    (systemState: SystemState) =>
      systemState.headerCard.data[indexCd]?.colorcode
  );

export const selectedIndexCardSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.headerCard.selectedIndex
);
export const valueHeaderCardDerSelector = (
  value: keyof SymbolTotalInfo.SecDetailInfo.AsObject
) =>
  createSelector(secdetailinfoTickerDerIndexSelector, (data) => data?.[value]);
export const secdetailinfoTickerDerIndexSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.headerCard.tickerDer.secdetailinfo
);
export const priceinfolistListTickerDerIndexSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.headerCard.tickerDer.priceinfolistList
);
export const colorcodeTickerDerIndexSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.headerCard.tickerDer.colorcode
);

//Ekyc
export const visibleModalEkycSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.visibleModalEkyc
);

export const stepEkycSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.modalRegisterEkycSlide.step
);

export const sendOTPGlobalMindRequestSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.sendOTPGlobalMindRequest
);

export const counterOTPEkycSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.counterOTPEkyc
);

export const verifyOTPGlobalMindRequestSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.verifyOTPGlobalMindRequest
);

export const imageEkycSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.modalRegisterEkycSlide.image
);

export const accountInfoRequestSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.postAccountInfoRequest
);

export const searchMsttProvinceSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.searchMsttProvince
);

export const dataMsttProvinceSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.dataMsttProvince
);

export const checkBrokerIdResponseSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.checkBrokerIdResponse
);

export const checkCustodyCdResponseSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.checkCustodyCdResponse
);

export const postServiceRegistrationRequestSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.postServiceRegistrationRequest
);

export const hasBrokerIdSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.modalRegisterEkycSlide.hasBrokerId
);

export const registerBankRequestSelector = createSelector(
  systemSelector,
  (systemState: SystemState) =>
    systemState.modalRegisterEkycSlide.postRegisterBankRequest
);

export const itemRegisterBankRequestSelector = (index: number) =>
  createSelector(
    systemSelector,
    (systemState: SystemState) =>
      systemState.modalRegisterEkycSlide.postRegisterBankRequest[index]
  );

export const bankListSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.modalRegisterEkycSlide.bankList
);

export const loadingEkycSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.modalRegisterEkycSlide.loading
);

export const isOpenExtraSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.isOpenExtra
);

export const visibleIconExtraSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleIconExtra
);

export const visibleContainerExtraSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.visibleContainerExtra
);

export const tabExtraActiveSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.tabExtraActive
);

export const logDataSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.logData
);

export const queueLogDataSelector = createSelector(
  systemSelector,
  (systemState: SystemState) => systemState.common.queueLogData
);
