import { FC } from "react";
import Container from "components/commons/Container";
import { useIntl } from "react-intl";
import SubAccoregisterCotent from "./pages/SubAccoregisterContent";

const SubAccoregister: FC = () => {
  const intl = useIntl();
  const arrayForm = [SubAccoregisterCotent];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "subAccoRegister.formLeft.title",
      })}
      headerForm={intl.formatMessage({
        id: "subAccoRegister.formLeft.title",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default SubAccoregister;
