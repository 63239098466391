import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { getStatBasketContributionData } from "modules/tickerDetails/redux/VN30StatBasketContribution";
import VN30StatBasketContribution from "components/widgets/details/VN30StatBasketContribution";
import { statBasketContributionSelector } from "modules/tickerDetails/redux/selectors";

function TickerDetailsBasketContribution() {
  const dispatch = useDispatch();

  const { statBasketContributionData } = useSelector(
    statBasketContributionSelector
  );

  useEffectOnce(() => {
    dispatch(getStatBasketContributionData());
  });

  return <VN30StatBasketContribution data={statBasketContributionData} />;
}

export default TickerDetailsBasketContribution;
