import { all, call, put, takeLatest } from "redux-saga/effects";
import { getPortfolio } from "core/http/apis/account";
// import { RootState } from "redux/store";
import {
  DataPortfolio,
  ISecuritiesPortfolioItem,
  ISecuritiesPortfolioResponse,
  PortfolioItem,
  SellAllPortfolioResponse,
} from "domain/models/Portfolio";
import {
  requestPortfolio,
  responsePortfolioSuccess,
  responsePortfolioFailure,
  requestSellAllPortfolio,
  reponseSellAllPortfolioSuccess,
  reponseSellAllPortfolioFailure,
} from "modules/stock-order/redux/portfolio";
import { sellAllPortfolio } from "core/http/apis/orderexec";
import { getStreamPriceInfoFulls } from "core/grpc";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
// import { toast } from "components/commons/Toast";
import { sumBy } from "lodash";
import { toast } from "components/commons/Toast";

function convertPortfolio(
  data: ISecuritiesPortfolioItem[],
  // sumMarketValue?: number,
  tickers?: SymbolTotalInfo.AsObject[] | undefined
): DataPortfolio {
  const items: PortfolioItem[] = data.map((item, index) => {
    const availQtyRaw = item?.availQty ?? 0;
    const avgPriceRaw = item?.avgPrice ?? 0;
    const basicPriceRaw = item?.basicPrice ?? 0;
    const ceilingPriceRaw = item?.ceilingPrice ?? 0;
    const changePercentRaw = item?.changePercent ?? 0;
    const changePointRaw = item?.changePoint ?? 0;
    const currentPriceRaw = item?.currentPrice ?? 0;
    const custNameRaw = item?.custName ?? "";
    const floorPriceRaw = item?.floorPrice ?? 0;
    const investmentAmtRaw = item?.investmentAmt ?? 0;
    const investmentAmtIndayRaw = item?.investmentAmtInday ?? 0;
    const investmentValueIndayRaw = item?.investmentValueInday ?? 0;
    const lastPriceRaw = item?.lastPrice ?? 0;
    const marginInterestRaw = item?.marginInterest ?? 0;
    const profitAmtAcmRaw = item?.profitAmtAcm ?? 0;
    const profitAmtIndayRaw = item?.profitAmtInday ?? 0;
    const profitPercentAcmRaw = item?.profitPercentAcm ?? 0;
    const profitValueAcmRaw = item?.profitValueAcm ?? 0;
    const profitValueIndayRaw = item?.profitValueInday ?? 0;
    const remainQtyRaw = item?.remainQty ?? 0;
    const rightAmtRaw = item?.rightAmt ?? 0;
    const rightQtyRaw = item?.rightQty ?? 0;
    const secCdRaw = item?.secCd ?? "";
    const secClassIdRaw = item?.secClassId ?? 0;
    const secClassNameRaw = item?.secClassName ?? "";
    const sellAvailQtyRaw = item?.sellAvailQty ?? 0;
    const subAccoCdRaw = item?.subAccoCd ?? 0;
    const subAccountNoRaw = item?.subAccountNo ?? "";
    const transDateRaw = item?.transDate ?? 0;

    const tickersInfo: SymbolTotalInfo.AsObject | undefined = tickers?.find(
      (ticker) => ticker.secdetailinfo?.seccd === secCdRaw
    );

    console.warn("tickersInfo", tickersInfo?.colorcode);

    // const lastprice =
    //   (tickersInfo?.secdetailinfo?.lastprice
    //     ? tickersInfo?.secdetailinfo?.lastprice * 1000
    //     : currentPriceRaw) || 0;

    const lastprice = currentPriceRaw;

    const unrealizedProfit = (lastprice - avgPriceRaw) * remainQtyRaw * 1000;

    const unrealizedProfitRate = investmentAmtRaw
      ? (unrealizedProfit / investmentAmtRaw) * 100
      : 100;

    const stockValue = lastprice * remainQtyRaw * 1000;

    const initialValue = avgPriceRaw * remainQtyRaw * 1000;

    return {
      orderId: subAccountNoRaw + secCdRaw,
      availQty: availQtyRaw,
      avgPrice: avgPriceRaw,
      basicPrice: basicPriceRaw,
      ceilingPrice: ceilingPriceRaw,
      changePercent: changePercentRaw,
      changePoint: changePointRaw,
      currentPrice: currentPriceRaw,
      custName: custNameRaw,
      floorPrice: floorPriceRaw,
      investmentAmt: investmentAmtRaw,
      investmentAmtInday: investmentAmtIndayRaw,
      investmentValueInday: investmentValueIndayRaw,
      lastPrice: lastPriceRaw,
      marginInterest: marginInterestRaw,
      profitAmtAcm: profitAmtAcmRaw,
      profitAmtInday: profitAmtIndayRaw,
      profitPercentAcm: profitPercentAcmRaw,
      profitValueAcm: profitValueAcmRaw,
      profitValueInday: profitValueIndayRaw,
      remainQty: remainQtyRaw,
      rightAmt: rightAmtRaw,
      rightQty: rightQtyRaw,
      secCd: secCdRaw,
      secClassId: secClassIdRaw,
      secClassName: secClassNameRaw,
      sellAvailQty: sellAvailQtyRaw,
      subAccoCd: subAccoCdRaw,
      subAccountNo: subAccountNoRaw,
      transDate: transDateRaw,

      stockValue: stockValue,
      initialValue: initialValue,
      marketPrice: lastprice,
      unrealizedProfit: unrealizedProfit,
      unrealizedProfitRateNum: unrealizedProfitRate,
      rowNum: index + 1,
      colorCode: tickersInfo?.colorcode ?? 0,
      // waitReceipt: receivingt0Raw + receivingt1Raw + receivingt2Raw,
    };
  });

  const sumInitialValue = sumBy(items, "investmentAmt");
  const sumStockValue = sumBy(items, "stockValue");
  const sumUnrealizedProfit = sumBy(items, "unrealizedProfit");
  let sumUnrealizedProfitRate = 0;
  if (sumInitialValue > 0) {
    sumUnrealizedProfitRate = sumUnrealizedProfit / sumInitialValue;
  } else {
    sumUnrealizedProfitRate = 1;
  }

  const summary = {
    totalInitialValue: sumInitialValue,
    totalStockValue: sumStockValue,
    totalUnrealizedprofit: sumUnrealizedProfit,
    totalUnrealizedprofitrate: sumUnrealizedProfitRate,
  };
  const newItems: PortfolioItem[] = items.map((item) => ({
    ...item,
    ratio:
      summary.totalStockValue > 0
        ? item.stockValue / summary.totalStockValue
        : 1,
  }));
  return {
    items: newItems,
    summary: summary,
    total: data.length,
  };
}

function* fetchPortfolioWorker(action: ReturnType<typeof requestPortfolio>) {
  try {
    const res: ISecuritiesPortfolioResponse = yield call(
      getPortfolio,
      action.payload
    );

    const resultWatchlist: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      res.data?.map((item) => item.secCd)?.join(",") ?? ""
    );

    // const tickers: TickerInfo.AsObject[] = yield select(
    //   (state: RootState) => state.categories.root.tickers
    // );
    // get ticker in type CW and ETF
    // const tickersCWAndETF = tickers.filter(
    //   (item) => item.tickertype === 3 || item.tickertype === 8
    // );

    const dataResult = convertPortfolio(res.data, resultWatchlist);

    yield put({ type: responsePortfolioSuccess.type, payload: dataResult });
  } catch (error: any) {
    yield put({ type: responsePortfolioFailure.type, payload: error });
  }
}

function* fetchPortfolioSellAllWorker(
  action: ReturnType<typeof requestSellAllPortfolio>
) {
  try {
    const res: SellAllPortfolioResponse = yield call(
      sellAllPortfolio,
      action.payload.params
    );

    if (res.statusCode === 0) {
      yield put(reponseSellAllPortfolioSuccess(res));

      // Huy thanh cong 3/5
      let content = `${action.payload.intl.formatMessage(
        {
          id: "widgets.portfolio.sellAll.orderSuccess",
        },
        {
          count: res.data.success || 0,
          total: (res.data.success || 0) + (res.data.failed || 0),
        }
      )}`;

      toast(content, "success");
    } else {
      toast(
        res?.errorCode ? "error." + res?.errorCode : "",
        "error",
        res.message,
        true
      );
      yield put(
        reponseSellAllPortfolioFailure({
          code: 0,
          description: res?.message || "error.defaultErr",
        })
      );
    }
  } catch (error: any) {
    yield put({ type: reponseSellAllPortfolioFailure.type, payload: error });
  }
}

function* fetchPortfolioWatcher() {
  yield takeLatest(requestPortfolio.type, fetchPortfolioWorker);
}

function* fetchPortfolioSellAllWatcher() {
  yield takeLatest(requestSellAllPortfolio.type, fetchPortfolioSellAllWorker);
}

export default function* topPriceRightWidget() {
  yield all([fetchPortfolioWatcher(), fetchPortfolioSellAllWatcher()]);
}
