import { Select, Button, DatePicker, InputSymbol } from "components/commons";
import { memo, useMemo } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  searchProfitLossRequest,
  getPflTPortTransactionRequest,
} from "modules/profit-loss/redux";
import {
  loadingSearchDataSelector,
  filterProfitLossSelector,
  selectLimit,
} from "modules/profit-loss/selectors";
import {
  IFindPortTransactionParams,
  IGetPflTPortTransactionParams,
} from "core/http/apis/profit-loss/types";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";

import moment from "moment";
import { toast } from "components/commons/Toast";
import { Row } from "react-bootstrap";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { fromDate, toDate, subAccoNo, secCd } = useSelector(
    filterProfitLossSelector
  );
  const limit = useSelector(selectLimit);
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.All
  );

  const loading = useSelector(loadingSearchDataSelector);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc]);

  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      return toast("common.warningInvalidDay", "error");
    }
    const submitData: IFindPortTransactionParams = {
      subAccoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      tradeType: 1,
      secCd: secCd ? secCd : "",
      page: 0,
      rowPerPage: limit,
    };
    dispatch(searchProfitLossRequest(submitData));

    const resLogData = {
      eventGroup: GroupEvent.profitLoss,
      event: RealtimeConst.getDataProfitLoss,
      message: JSON.stringify(submitData),
    };

    storage.commonPushLogFirebase(resLogData);

    const submitData2: IGetPflTPortTransactionParams = {
      subAccoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      custNo: storage.getMasterAccount() ?? "",
      secCd: secCd ? secCd : "",
    };
    dispatch(getPflTPortTransactionRequest(submitData2));

    const resLogData2 = {
      eventGroup: GroupEvent.profitLoss,
      event: RealtimeConst.getDataProfitLossChart,
      message: JSON.stringify(submitData2),
    };

    storage.commonPushLogFirebase(resLogData2);
  };
  // useEffect(() => {
  //   const submitData2: IGetPflTPortTransactionParams = {
  //     subAccoNo: subAccoNo
  //       ? subAccoNo
  //       : parseOptionAccount[0].value
  //       ? parseOptionAccount[0].value
  //       : "",
  //     fromDate:
  //       fromDate && Number(moment(fromDate).format("YYYYMMDD"))
  //         ? Number(moment(fromDate).format("YYYYMMDD"))
  //         : 0,
  //     toDate:
  //       toDate && Number(moment(toDate).format("YYYYMMDD"))
  //         ? Number(moment(toDate).format("YYYYMMDD"))
  //         : 0,
  //     custNo: storage.getMasterAccount() ?? "",
  //   };
  //   dispatch(getPflTPortTransactionRequest(submitData2));
  // }, []);
  // effect

  // render
  return (
    <Wrap
      className="d-flex justify-content-between align-items-center"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <Row className=" d-flex align-content-center w-100 pl-3">
          <FilterItem
            filterName={intl.formatMessage({
              id: "profitLoss.filter.fromDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={fromDate}
                onChange={(e: any) => {
                  dispatch(
                    updateFilter({
                      key: "fromDate",
                      value: e,
                    })
                  );
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "profitLoss.filter.toDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={toDate}
                onChange={(e) =>
                  dispatch(
                    updateFilter({
                      key: "toDate",
                      value: e,
                    })
                  )
                }
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "profitLoss.filter.account",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={parseOptionAccount[0]}
                options={parseOptionAccount}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "subAccoNo",
                      value: e?.value ? e.value : "",
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
          <FilterItem
            filterName={intl.formatMessage({
              id: "profitLoss.filter.ticker",
            })}
            className="marginRight2 text-grey"
          >
            <div className="text-white">
              <InputSymbol
                value={secCd}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "secCd",
                      value: e ? e : "",
                    })
                  );
                }}
                isOnlyShareCode
              />
            </div>
          </FilterItem>

          <Button
            color="accept"
            className="button-filter1 mr-4"
            loading={loading}
            onClick={handleSearch}
          >
            {intl.formatMessage({
              id: "profitLoss.filter.search",
            })}
          </Button>
        </Row>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
