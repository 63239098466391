import { FC, memo } from "react";
import { WatchlistCard } from "./styles";
import { ReactComponent as ArrowDown } from "assets/image/svg/ic_down_bold.svg";

interface Props {
  isActive: boolean;
  isGroupItem?: boolean;
  handleOnClick?: () => void;
  label: string;
  isShowIcon?: boolean;
}

function WatchlistCardWidget({
  isActive,
  isGroupItem,
  handleOnClick,
  label,
  isShowIcon,
}: Props) {
  return (
    <WatchlistCard
      onClick={handleOnClick}
      isActive={isActive}
      isGroupItem={isGroupItem}
    >
      <div className="watch-list-name">{label}</div>
      {isShowIcon && <ArrowDown className="arrow-down" />}
    </WatchlistCard>
  );
}

export default memo(WatchlistCardWidget) as FC<Props>;
