import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Legend, Wrapper } from "./styles";

const OtherField: FC = () => {
  return (
    <Wrapper hide={true}>
      <Legend>
        <FormattedMessage id="screeners.tabs.screener.other.legend" />
      </Legend>
    </Wrapper>
  );
};

export default OtherField;
