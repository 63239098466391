import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateModalStatusRequest } from "modules/transfer-bond/redux";
import { isShowConfirmModalSelector } from "modules/transfer-bond/selectors";

import numeral from "numeral";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { TransferBond } from "domain/models/TransferBond";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  DetailRowButton,
} from "./styles";
import { Button } from "components/commons";
import {
  updateModalOtpRequest,
  // createAdvanceContractRequest,
} from "modules/advance/redux";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
interface Props {
  itemDetailRow: TransferBond;
}

function DetailModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowConfirmModalSelector);
  const themeType = useSelector(themeTypeSelector);
  const closeModalHandler = () => {
    dispatch(updateModalStatusRequest({ isVisible: false }));
  };

  const clickAcceptHandler = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));

    closeModalHandler();
  };

  const amount =
    (itemDetailRow &&
      itemDetailRow.transferAmt &&
      itemDetailRow.quantity &&
      itemDetailRow?.transferAmt / itemDetailRow?.quantity) ??
    0;

  const getDisplay2 = (status: number) => {
    switch (status) {
      case 1:
        return "transfer";
      case 2:
        return "receive";
    }
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="transferBond.confirmModal.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-3">
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.fromSubAccoNo" />
            </LabelCell>
            <TextCell>{itemDetailRow?.fromSubAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.fromCustName" />
            </LabelCell>
            <TextCell>{itemDetailRow?.fromCustName}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.toSubAccoNo" />
            </LabelCell>
            <TextCell>{itemDetailRow?.toSubAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.toCustName" />
            </LabelCell>
            <TextCell>{itemDetailRow?.toCustName}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.bondCode" />
            </LabelCell>
            <TextCell>
              {itemDetailRow?.bondCd}
              {" | "}
              {itemDetailRow?.bondName}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.qty" />
            </LabelCell>
            <TextCell>
              <TextCell>{itemDetailRow?.quantity}</TextCell>
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.amount" />
            </LabelCell>
            <TextCell>{numeral(amount).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.amountByPrice" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.parAmt ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.transferAmount" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.transferAmt ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.fee" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.feeAmt ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.feePay" />
            </LabelCell>
            <TextCell>
              <TextCell>
                {intl.formatMessage({
                  id:
                    "transferBond.table.payer2." +
                    getDisplay2(itemDetailRow?.feePayer ?? 0),
                })}
              </TextCell>
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.taxValue" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.taxAmt ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferBond.confirmModal.taxPay" />
            </LabelCell>
            <TextCell>
              <TextCell>
                {intl.formatMessage({
                  id:
                    "transferBond.table.payer2." +
                    getDisplay2(itemDetailRow?.taxPayer ?? 0),
                })}
              </TextCell>
            </TextCell>
          </BodyModalRow>
          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={closeModalHandler}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "transferBond.confirmModal.cancel",
              })}
            </Button>
            <Button
              style={{
                background: `${themes[themeType].buttonGradientColor}`,

                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "transferBond.confirmModal.confirm",
              })}
            </Button>
          </DetailRowButton>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailModal;
