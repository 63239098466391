import { FC } from "react";
import TabHeader from "./TabHeader";
import TabPanel from "./TabPanel";
import { Container } from "./styles";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "react-use";
import { getListScreenerRequest } from "modules/screeners/redux";

const Tabs: FC = () => {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch(getListScreenerRequest());
  });

  return (
    <Container>
      <TabHeader />
      <TabPanel />
    </Container>
  );
};

export default Tabs;
