import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  z-index: 4;

  flex: 1;
  width: 100%;
`;

export const InputLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.textcolorBlack};
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border: 1px solid ${({ theme }) => theme.borderColor};
  :hover {
    border: 1px solid ${({ theme }) => theme.grey0};
  }
`;

export const TickerContainer = styled.ul`
  padding: 0px;
  background: ${({ theme }) => theme.background2};
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: 4px;
  max-height: 180px;
  overflow: auto;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner3};
    div {
      .share-code {
        color: ${({ theme }) => theme.grey0};
      }
    }
    :hover {
      background: ${({ theme }) => theme.brandSecondary};
      div {
        color: ${({ theme }) => theme.brandPrimary};
        span {
          color: ${({ theme }) => theme.brandPrimary};
        }
      }
    }
    :focus {
      background: ${({ theme }) => theme.brandSecondary};
      div {
        color: ${({ theme }) => theme.brandPrimary};
        span {
          color: ${({ theme }) => theme.brandPrimary};
        }
      }
    }
  }
`;

export const TextItem = styled.li`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner3};
  div {
    .share-code {
      color: ${({ theme }) => theme.grey0};
    }
  }
  :hover {
    background: ${({ theme }) => theme.brandSecondary};
    div {
      color: ${({ theme }) => theme.brandPrimary};
      span {
        color: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
  :focus {
    background: ${({ theme }) => theme.brandSecondary};
    div {
      color: ${({ theme }) => theme.brandPrimary};
      span {
        color: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
  :focus-visible {
    outline: none;
  }
`;
