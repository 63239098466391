import { createSelector } from "@reduxjs/toolkit";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
import { getColorPrice } from "helper/utils";
import {
  categoriesInfoSelector,
  floorpriceSelector,
  ceilingpriceSelector,
  basicpriceSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
  key: string;
  className: string;
  priceName: keyof SymbolTotalInfo.SecDetailInfo.AsObject;
  isAsk?: boolean;
}

const priceSelector = (
  ticker: string,
  priceName: keyof SymbolTotalInfo.SecDetailInfo.AsObject
) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.[priceName]
  );
};

function BestpriceCell({ ticker, key, className, priceName, isAsk }: Props) {
  const price =
    (useSelector(priceSelector(ticker, priceName)) as string | number) || 0;
  const floorprice = useSelector(floorpriceSelector(ticker)) || 0;
  const ceilingprice = useSelector(ceilingpriceSelector(ticker)) || 0;
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;

  return (
    <PriceCell
      ticker={ticker}
      key={key}
      className={className}
      price={price}
      color={getColorPrice(price, floorprice, ceilingprice, basicprice)}
      isAsk={isAsk}
    />
  );
}

export default BestpriceCell;
