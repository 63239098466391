import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import StatisticUpdown from "./pages/StatisticUpdown";

const routes: RouterConfig[] = [
  {
    page: StatisticUpdown,
    path: paths.statisticUpdown,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
