import styled from "styled-components/macro";

export const TabCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemTabCard = styled.div<{ isActive: boolean }>`
  flex: 1 1 auto;
  text-align: center;
  cursor: pointer;
  padding: 8px 0px;
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};

  border-bottom-style: ${({ isActive }) => isActive && "solid"};
  border-bottom-width: ${({ isActive }) => isActive && "2px"};
  color: ${({ theme }) => theme.neutral3};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive, theme }) =>
    isActive &&
    `border-bottom: 2px solid ${theme.solidBlue};
    color: ${theme.solidBlue};
    background-color: ${theme.grey70};
    `}

  :hover {
    color: ${({ theme }) => theme.solidBlue};
  }
`;
