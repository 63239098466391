import TreemapChart from "components/commons/Charts/TreemapChart";
import SelectFilterMarketWatch from "components/commons/SelectFilterMarketWatch";
import { DEFAULT_WIDGET_REFETCH_INTERVAL, UNIT_NUMBER } from "helper/consts";
import { getColorChart } from "helper/utils";
import sortBy from "lodash/sortBy";
import { VALUE_TYPES } from "modules/market/redux/marketWatch";
import {
  transactionValueVolumeSelector,
  filterTransactionValueVolumeSelector,
} from "modules/market/redux/selectors";
import {
  changeTransactionFilter,
  fetchTransactionValueVolume,
} from "modules/market/redux/transactionValueVolume";
import numeral from "numeral";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce, useInterval } from "react-use";
import { themeTypeSelector } from "themes/redux";
import { ContentContainer, Header } from "./styles";
import { ValueVolumeDistributionData } from "./types";

function TransactionValueVolume() {
  const dispatch = useDispatch();
  const filterTransaction = useSelector(filterTransactionValueVolumeSelector);
  const { valueData, volumeData } = useSelector(transactionValueVolumeSelector);
  const themeType = useSelector(themeTypeSelector);
  const intl = useIntl();

  useInterval(() => {
    dispatch(fetchTransactionValueVolume());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    dispatch(fetchTransactionValueVolume());
  });

  const generateData = () => {
    let chartData: number[] = [];
    let chartDataMT: number[] = [];
    let chartTooltips: string[] = [];
    let chartTooltipsMT: string[] = [];
    let chartColors: string[] = [];
    let chartLabels: string[] = [];
    let chartPercentLabels: string[] = [];
    let chartLongLabels: string[] = [];
    const isValue = filterTransaction.valueType.value === "2";
    const isMatchingTransaction =
      filterTransaction.transactionType.value === "EFIELDTYPE_VALUE";
    const transactionData = isValue ? valueData : volumeData;

    const sortedTransactionData = sortBy(transactionData, [
      function (o) {
        if (isMatchingTransaction) {
          return o.totalTradedMT;
        }
        return o.totalTraded;
      },
    ]).reverse();
    sortedTransactionData.forEach((item: ValueVolumeDistributionData) => {
      chartData.push(item.totalTraded!);
      chartDataMT.push(item.totalTradedMT!);
      chartTooltips.push(
        `${numeral(
          item.totalTraded! /
            (isValue
              ? UNIT_NUMBER.ONE_MILLION
              : UNIT_NUMBER.ONE_HUNDRED_THOUSAND)
        ).format("0,0.000")}${
          isValue
            ? intl.formatMessage({
                id: "common.unit",
              })
            : intl.formatMessage({
                id: "common.million",
              })
        }`
      );
      chartTooltipsMT.push(
        `${numeral(
          item.totalTradedMT! /
            (isValue
              ? UNIT_NUMBER.ONE_MILLION
              : UNIT_NUMBER.ONE_HUNDRED_THOUSAND)
        ).format("0,0.000")}${
          isValue
            ? intl.formatMessage({
                id: "common.unit",
              })
            : intl.formatMessage({
                id: "common.million",
              })
        }`
      );
      chartColors.push(getColorChart(item.color!, themeType));
      chartLabels.push(item.ticker);
      chartLongLabels.push(`${item.ticker} - ${item.stockName}`);
      chartPercentLabels.push(
        `${numeral(item.changedPercentage ? item.changedPercentage : 0).format(
          "0.00"
        )}%`
      );
    });

    // Nếu giao dịch khớp lệnh thì gán lại
    if (isMatchingTransaction) {
      chartData = chartDataMT;
      chartTooltips = chartTooltipsMT;
    }
    return {
      chartData,
      chartColors,
      chartLabels,
      chartTooltips,
      chartPercentLabels,
      chartLongLabels,
    };
  };

  const {
    chartData,
    chartColors,
    chartLabels,
    chartTooltips,
    chartPercentLabels,
    chartLongLabels,
  } = generateData();

  const handleChangeFilter = (option: any, type: string) => {
    dispatch(changeTransactionFilter({ ...filterTransaction, [type]: option }));
    dispatch(fetchTransactionValueVolume());
  };
  return (
    <ContentContainer>
      <Header>
        <div className="item-selection">
          <div className="label">
            <FormattedMessage id="widgets.marketWatch.value" />
          </div>
          <div className="selection">
            <SelectFilterMarketWatch
              selectedOption={filterTransaction.valueType}
              onSelect={(option) => handleChangeFilter(option, "valueType")}
              options={VALUE_TYPES}
            />
          </div>
        </div>
      </Header>
      <div className="chart">
        <TreemapChart
          values={chartData}
          tooltipValues={chartTooltips}
          colors={chartColors}
          treeLabels={chartLabels}
          treeLongLabels={chartLongLabels}
          valuesInPercents={chartPercentLabels}
        />
      </div>
    </ContentContainer>
  );
}

export default TransactionValueVolume;
