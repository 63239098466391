/* eslint-disable @typescript-eslint/no-unused-vars */
import { useIntl } from "react-intl";
import { Wrap, Container, WrapCamera, WrapIconCamera } from "./style";
import { Button } from "components/commons";
import Webcam from "react-webcam";
import { useRef, useState } from "react";
import { ReactComponent as CameraIcon } from "assets/image/svg/CameraIcon.svg";
import { ReactComponent as BackCameraFace } from "assets/image/svg/backCameraFace.svg";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import {
  updateFormSubmit,
  updateParams,
  updateStep,
} from "modules/auth/redux/modalRegister";
import { faceCompareApi } from "core/http/apis/modal-register";
import {
  selectFormSubmit,
  selectInformationExtraction,
  selectParams,
} from "modules/auth/selectorsModalRegister";
import { toast } from "components/commons/Toast";

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: "image/jpeg" });
}

const Step4 = () => {
  const intl = useIntl();
  const webcamRef = useRef<any>(null);
  const { changeImageFaceFront, imageFaceFront } =
    useSelector(selectFormSubmit);
  // const [isPermissionDenied, setPermissionDenied] = useState<boolean>(false);
  const dispatch = useDispatch();
  const information = useSelector(selectInformationExtraction);
  const videoConstraints = {
    facingMode: "user",
  };

  const [imageUrl, setimageUrl] = useState<any>();

  const capture = () => {
    if (imageFaceFront !== "" && changeImageFaceFront === false) {
      dispatch(updateFormSubmit({ value: true, key: "changeImageFaceFront" }));
    }

    const imageSrc = webcamRef?.current?.getScreenshot({
      width: 1500,
      height: 1080,
    });
    dispatch(updateFormSubmit({ value: imageSrc, key: "imageFaceFront" }));
    // dispatch(updateParams({ imageFaceFront: imageSrc }));
    setimageUrl(imageSrc);
  };

  const [loading, setloading] = useState<boolean>(false);

  const uploadFaceCompare = async () => {
    setloading(true);
    if (changeImageFaceFront === true) {
      dispatch(updateFormSubmit({ value: false, key: "changeImageFaceFront" }));
      dispatch(updateStep(STEPS.STEPS_5));
      setloading(false);
      return;
    }
    const frontImageFile: any = new FormData();
    frontImageFile.append("faceImage", dataURLtoFile(imageUrl, "faceImage"));
    frontImageFile.append("hashKey", information?.hashKey);
    frontImageFile.append("clientId", information?.clientId);

    const res: any = await faceCompareApi(frontImageFile);

    if (res?.statusCode === 0) {
      dispatch(
        updateParams({
          imageFaceFront: res?.data?.imageFaceFront,
        })
      );
      dispatch(updateFormSubmit({ value: false, key: "changeImageFaceFront" }));
      dispatch(updateStep(STEPS.STEPS_5));
      setloading(false);
    } else {
      setimageUrl(null);
      dispatch(updateFormSubmit({ value: "", key: "imageFaceFront" }));
      toast(res?.errorCode, "error", res?.message, true);
      setloading(false);
    }
    //
    // dispatch(updateStep(STEPS.STEPS_5));
  };

  const x = useSelector(selectParams);

  return (
    <Container>
      <Wrap>
        <WrapCamera>
          {!imageUrl && (
            <Webcam
              audio={false}
              height={357}
              width={636}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              // onUserMediaError={(e) => setPermissionDenied(true)}
              // onUserMedia={(e) => setPermissionDenied(false)}
            />
          )}
          {imageUrl && (
            <img
              src={imageUrl}
              alt=""
              style={{ width: "476px", height: "357px" }}
            />
          )}
        </WrapCamera>
        {!imageUrl && (
          <WrapIconCamera onClick={() => capture()}>
            <CameraIcon />
          </WrapIconCamera>
        )}

        {imageUrl && (
          <WrapIconCamera onClick={() => setimageUrl("")}>
            <BackCameraFace />
          </WrapIconCamera>
        )}
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_3))}
          loading={loading}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        {imageUrl && (
          <Button
            color="gradientaAccept"
            className="button-filter"
            width="120px"
            onClick={() => uploadFaceCompare()}
            loading={loading}
          >
            {intl.formatMessage({
              id: "auth.register.btnNext",
            })}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Step4;
