import { toast } from "components/commons/Toast";
import {
  getCbot,
  getCustomer2,
} from "core/http/apis/register-To-Transfer-Bonds";
import { IDepositList } from "domain/models/DepositList";

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getCbotFailure,
  getCbotRequest,
  getCbotSuccess,
  getcustomer2Failure,
  getcustomer2Request,
  getcustomer2Success,
} from "./redux";

function* getCustomer2Worker(action: ReturnType<typeof getcustomer2Request>) {
  try {
    const params: any = action.payload;

    const response: IDepositList = yield call(getCustomer2, params);

    if (response?.statusCode === 0) {
      yield put(getcustomer2Success(response));
    } else {
      yield put(getcustomer2Failure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getcustomer2Failure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getCbotWorker(action: ReturnType<typeof getCbotRequest>) {
  try {
    const params: any = action.payload;

    const response: IDepositList = yield call(getCbot, params);

    if (response?.statusCode === 0) {
      yield put(getCbotSuccess(response));
    } else {
      yield put(getCbotFailure());
    }
  } catch (error: any) {
    yield put(getCbotFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getCustomer2Watcher() {
  yield takeLatest(getcustomer2Request.type, getCustomer2Worker);
}

function* getCbotWatcher() {
  yield takeLatest(getCbotRequest.type, getCbotWorker);
}

export default function* registerToTransferBondsSaga() {
  yield all([getCustomer2Watcher(), getCbotWatcher()]);
}
