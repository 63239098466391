import { Body, Container, Title, Footer } from "./styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";

interface Props {
  visibleModalConfirm: boolean;
  setVisibleModalConfirm: (visible: boolean) => void;
  setConfirm: () => void;
}

function ModalConfirmRegisterOtp({
  visibleModalConfirm,
  setVisibleModalConfirm,
  setConfirm,
}: Props) {
  const closeModalConfirm = () => {
    setVisibleModalConfirm(false);
  };

  const handleConfirm = () => {
    setConfirm();
    closeModalConfirm();
  };

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-434"
      >
        <Container>
          <Title>
            <FormattedMessage id="widgets.placeOrder.titleModalConfirm" />
          </Title>
          <Body>
            <FormattedMessage id="widgets.placeOrder.contentModalConfirm" />
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={handleConfirm}
              className="flex-1"
              fill
              color="accept"
            >
              <FormattedMessage id="common.register" />
            </Button>
          </Footer>
        </Container>
      </Modal>
    </>
  );
}

export default ModalConfirmRegisterOtp;
