import React, { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import { CellProps } from "react-table";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import {
  Button,
  StatusContainer,
  TableContainer,
  TextTradeType,
} from "./style";
import SelectTable from "components/commons/SelectTable";
import ModalDetailOrderCondition from "components/widgets/generals/OrderBook/modal/ModalDetailOrderCondition";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentAccount } from "modules/auth/redux";
import {
  onBuySellType,
  onConditionalOrder,
  onUpdateVolumn,
  onChangeMatMethod,
  onChangeOptValType,
  onChangeOptVal,
  onChangeLimitOffset,
  onChangeMinMaxPrice,
  onChangeExpiredDate,
  onChangeOptType,
  onChangeCondition,
  onFilter,
  handleUsingPriceSelected,
} from "modules/stock-order/redux/placeOrder";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import {
  addStockOrderWidgetName,
  changeSelectedRowsCondition,
} from "modules/order-book/redux/index";
import { IOrderBookCondItemDisplay } from "domain/models/OrderBook";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { Account } from "domain/models/User";
import moment from "moment";
import { getValueRound } from "helper/utils";
import { enableInfinitySelector } from "modules/order-book/selectors";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";

interface WidgetContentProps {
  data: IOrderBookCondItemDisplay[];
  fetchMoreData: () => void;
  heightFooter: number;
}

const getConditionalOrderList = (intl: IntlShape) => [
  {
    value: "01",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.normal",
    }),
  },
  {
    value: "TCO",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.timeConditionOrder",
    }),
  },
  {
    value: "TS",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.trailingStopOrder",
    }),
  },
  {
    value: "PRO",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.priorityOrder",
    }),
  },
  {
    value: "SP",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.takeProfit",
    }),
  },
  {
    value: "CL",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.cutLoss",
    }),
  },
];

const timeConditionOrderConditionsList = (intl: IntlShape) => [
  {
    value: "01",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.conditionsList.noCondition",
    }),
  },
  {
    value: "02",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.conditionsList.referencePrices",
    }),
  },
];

const PriceTCList = [
  { value: "01", label: ">=" },
  { value: "02", label: "<=" },
];

function OrderBookConditionTable({
  data,
  fetchMoreData,
  heightFooter,
}: WidgetContentProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const conditionalOrderList = getConditionalOrderList(intl);
  const enableInfinity = useSelector(enableInfinitySelector);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const [selectedRows, setSelectedRows] = useState<IOrderBookCondItemDisplay[]>(
    []
  );
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState<IOrderBookCondItemDisplay>();

  useEffect(() => {
    if (selectedRows) {
      const selectedRowsNew: IOrderBookCondItemDisplay[] = selectedRows.filter(
        (item) => item.visibleCheckbox === true
      );
      dispatch(changeSelectedRowsCondition(selectedRowsNew));
    }
  }, [selectedRows]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tableCondition.secCd" />
          </TableHeaderCellContent>
        ),
        accessor: "secCd",
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        minWidth: 65,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tableCondition.accoNo" />
          </TableHeaderCellContent>
        ),
        accessor: "subAccoNo",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookCondItemDisplay>) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tableCondition.condType" />
          </TableHeaderCellContent>
        ),
        accessor: "condTypeDisplay",
        minWidth: 104,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              <FormattedMessage id={props.value} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.tableCondition.side" />
          </TableHeaderCellContent>
        ),
        accessor: "tradeTypeDisplay",
        minWidth: 75,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookCondItemDisplay>) => {
          const tradeTypeDisplay = props?.row?.original?.tradeTypeDisplay || "";
          return (
            <TableCellContent align={"center"}>
              <TextTradeType side={tradeTypeDisplay}>
                {tradeTypeDisplay &&
                  intl.formatMessage({ id: tradeTypeDisplay })}
              </TextTradeType>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tableCondition.status" />
          </TableHeaderCellContent>
        ),
        accessor: "statusDisplay",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookCondItemDisplay>) => {
          const orderStatusDisplay = props?.row?.original?.statusDisplay;
          const orderStatusColor = props?.row?.original?.orderStatusColor;
          const orderStatusPercent = props?.row?.original?.orderStatusPercent;
          return (
            <StatusContainer
              status={orderStatusColor}
              progress={orderStatusPercent}
            >
              <TableCellContent align={"center"}>
                <FormattedMessage id={orderStatusDisplay} />
              </TableCellContent>
            </StatusContainer>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tableCondition.ordQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 75,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const formatterValue = numeral(props.value).format("0,0");
          return (
            <TableCellContent align={"flex-end"}>
              {formatterValue}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tableCondition.ordPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "ordPrice",
        minWidth: 75,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookCondItemDisplay>) => {
          const formatterValue = numeral(props?.row?.original?.ordPrice).format(
            "0,0.00"
          );
          const condType = props?.row?.original?.condType;
          return (
            <TableCellContent align={"flex-end"}>
              {condType === "TS" || condType === "PRO"
                ? "- - - -"
                : formatterValue}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tableCondition.matQty" />
          </TableHeaderCellContent>
        ),
        accessor: "matQty",
        minWidth: 75,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const formatterValue = numeral(props.value).format("0,0");
          return (
            <TableCellContent align={"flex-end"}>
              {formatterValue}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tableCondition.matMethod" />
          </TableHeaderCellContent>
        ),
        accessor: "matMethodDisplay",
        minWidth: 115,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              <FormattedMessage id={props.value} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tableCondition.effectDate" />
          </TableHeaderCellContent>
        ),
        accessor: "effectDateDisplay",
        minWidth: 110,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tableCondition.expiredDate" />
          </TableHeaderCellContent>
        ),
        accessor: "expiredDateDisplay",
        minWidth: 110,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tableCondition.limitOffset" />
          </TableHeaderCellContent>
        ),
        accessor: "limitOffset",
        minWidth: 85,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.tableCondition.reqTime" />
          </TableHeaderCellContent>
        ),
        accessor: "reqTime",
        minWidth: 140,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value ? props.value : "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.tableCondition.updDateType" />
          </TableHeaderCellContent>
        ),
        accessor: "updateDateDisplay",
        minWidth: 140,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookCondItemDisplay>) => {
          const updateDate =
            (props.row.original.status === 3 ||
              props.row.original.status === 9) &&
            props.value
              ? props.value
              : "- - - -";
          return (
            <TableCellContent align={"center"}>{updateDate}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tableCondition.channel" />
          </TableHeaderCellContent>
        ),
        accessor: "channelDisplay",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: CellProps<IOrderBookCondItemDisplay>) => {
          return (
            <div className="btn_action_hover">
              <Button
                side={props?.row?.original?.tradeType === 2 ? "buy" : "sell"}
                onClick={() => onBuySell(props?.row?.original, listAcc)}
                onDoubleClick={(e) => e.stopPropagation()}
              >
                <FormattedMessage
                  id={
                    props?.row?.original?.tradeType === 2
                      ? "common.buyPlus"
                      : "common.sellPlus"
                  }
                />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const onBuySell = (data: IOrderBookCondItemDisplay, listing: Account[]) => {
    // Dispatch mã ck
    const symbol = data?.secCd as string;
    dispatch(onChangeCurrentTicker(symbol));
    // Dispatch account
    const accountChange = listing?.find(
      (item) => item.subAccoNo === data?.subAccoNo
    );
    dispatch(changeCurrentAccount(accountChange!));
    // Dispatch switch button mua/bán
    const buySell = data?.tradeType === 2 ? "buy" : "sell";
    dispatch(onBuySellType(buySell));

    // Dispatch Giá
    dispatch(handleUsingPriceSelected(true));
    // const price = numeral(data?.ordPrice).value();
    const price = Math.round((data?.ordPrice + Number.EPSILON) * 100) / 100;
    dispatch(onFilter({ price }));

    // Dispatch khối lượng
    const volumn = numeral(data?.ordQty).format("0,0");
    dispatch(onUpdateVolumn({ volumn }));
    // Dispatch lệnh điều kiện
    const conOrder = conditionalOrderList.find(
      (f) => f?.value === data?.condType
    );
    dispatch(onConditionalOrder(conOrder));
    // Dispatch condition Mat Method
    dispatch(onChangeMatMethod(data?.matMethod?.toString()));
    // Dispatch condition Opt Val Type
    dispatch(onChangeOptValType(data?.optValType));
    // Dispatch condition
    const condition =
      timeConditionOrderConditionsList(intl)[data?.optVal === 0 ? 0 : 1];
    dispatch(onChangeCondition(condition.value));
    // Dispatch condition Opt Type
    const optType = PriceTCList.find(
      (item) => parseInt(item.value) === data?.optType
    );
    dispatch(onChangeOptType(optType ? optType : PriceTCList[0]));
    // Dispatch condition Opt Val
    dispatch(
      onChangeOptVal(
        numeral(
          data?.optValType === 1
            ? data?.optVal
            : data?.condType !== "TCO"
            ? data?.optVal * 100
            : data?.optVal
        ).format("0,0.00")
      )
    );
    // Dispatch condition Limit Offset
    dispatch(onChangeLimitOffset(getValueRound(data?.limitOffset)));
    // Dispatch condition Min Max Price
    dispatch(
      onChangeMinMaxPrice({
        price: getValueRound(data.ordPrice),
        type: data.tradeType === 2 ? "buy" : "sell",
      })
    );
    // Dispatch Expire Date
    const date = moment(data?.toDate?.toString(), "YYYYMMDD").format(
      "YYYY-MM-DD"
    );
    dispatch(onChangeExpiredDate(new Date(date)));

    dispatch(addStockOrderWidgetName("widgets.stockOrder.name"));
  };

  return (
    <TableContainer
      heighFooter={heightFooter}
      id="table-infinity-order-book-condition"
    >
      <SelectTable
        columns={columns}
        data={data}
        setShowModal={setShowModalDetail}
        setDataRow={setDataRow}
        setSelectedRows={setSelectedRows}
        fetchMoreData={fetchMoreData}
        enableInfinity={enableInfinity}
        scrollableTarget="table-infinity-order-book-condition"
      />
      <ModalDetailOrderCondition
        showModal={showModalDetail}
        setShowModal={setShowModalDetail}
        dataRow={dataRow}
      />
    </TableContainer>
  );
}

export default React.memo(OrderBookConditionTable);
