import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { TOP_PRICE_LOADING_LIMIT } from "AppConfig";
import {
  requestChangePeriod,
  changePeriodSuccess,
  requestLoadMore,
  requestLoadMoreSuccess,
  requestTopPriceLeft,
  requestTopPriceLeftSuccess,
  changeActiveSort,
} from "../redux/topPriceLeft";
import { getEPeriod } from "../helpers/topPrice";
import {
  TopSecUpDownInput,
  TopSecUpDownResponse,
} from "domain/protoNew/auto_trading_pb";
import { getTopSecUpDown } from "core/grpc";
import { AuthenCodeGrpc } from "AppConfig";
import {
  activeSortTopPriceLeftSelector,
  activeTabTopPriceLeftSelector,
  marketTopPriceLeftSelector,
  currentMarketCodeSelector,
} from "modules/market/redux/selectors";
import { TopPricePeriod } from "components/widgets/generals/TopPrice/types";
import { setCurrentMarketCode } from "../redux";
import { EMarketCodeNew } from "helper/consts";

function* fetchTickersWorker() {
  const activeTab: TopPricePeriod = yield select(activeTabTopPriceLeftSelector);
  const { sortBy, order } = yield select(activeSortTopPriceLeftSelector);
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  const request = new TopSecUpDownInput();
  request.setUserid(uuidv4());
  request.setToptype(1);
  request.setMarketcd(currentMarketCode.toString());
  request.setIndustries("");
  request.setPeriodtype(getEPeriod(activeTab));
  request.setOffset(0);
  request.setOrderfieldtype(sortBy);
  request.setOrdertype(order);
  request.setLimit(TOP_PRICE_LOADING_LIMIT);
  request.setAuthencode(AuthenCodeGrpc);
  try {
    const response: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] =
      yield call(getTopSecUpDown, request);

    yield put(changePeriodSuccess(response));
  } catch (e) {}
}

function* fetchPriceInfoLastSaleWorker() {
  try {
    const { limit, activeTab } = yield select(marketTopPriceLeftSelector);
    const { sortBy, order } = yield select(activeSortTopPriceLeftSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const request = new TopSecUpDownInput();
    request.setUserid(uuidv4());
    request.setToptype(1);
    request.setMarketcd(currentMarketCode.toString());
    request.setIndustries("");
    request.setPeriodtype(getEPeriod(activeTab));
    request.setOffset(0);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(limit);
    request.setAuthencode(AuthenCodeGrpc);

    const response: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] =
      yield call(getTopSecUpDown, request);

    yield put(requestTopPriceLeftSuccess(response));
  } catch (e) {}
}

function* fetchPriceInfoLastSaleLoadMoreWorker() {
  try {
    const { limit, activeTab, enableLoadMore } = yield select(
      marketTopPriceLeftSelector
    );
    const { sortBy, order } = yield select(activeSortTopPriceLeftSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    if (enableLoadMore === false) return;
    const request = new TopSecUpDownInput();
    request.setUserid(uuidv4());
    request.setToptype(1);
    request.setMarketcd(currentMarketCode.toString());
    request.setIndustries("");
    request.setPeriodtype(getEPeriod(activeTab));
    request.setOffset(limit);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(TOP_PRICE_LOADING_LIMIT);
    request.setAuthencode(AuthenCodeGrpc);
    const response: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] =
      yield call(getTopSecUpDown, request);
    yield put(requestLoadMoreSuccess(response));
  } catch (e) {}
}

function* fetchPriceInfoLastSaleLoadMoreWatcher() {
  yield takeLatest(requestLoadMore.type, fetchPriceInfoLastSaleLoadMoreWorker);
}

function* fetchTickersByPeriodWatcher() {
  yield takeLatest(requestChangePeriod.type, fetchTickersWorker);
}

function* fetchTickersByChangeActiveSortWatcher() {
  yield takeLatest(changeActiveSort.type, fetchTickersWorker);
}

function* fetchTopPriceLeftWatcher() {
  yield takeLatest(requestTopPriceLeft.type, fetchPriceInfoLastSaleWorker);
}

function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchPriceInfoLastSaleWorker);
}

export default function* topPriceLeftWidget() {
  yield all([
    fetchTickersByPeriodWatcher(),
    fetchTickersByChangeActiveSortWatcher(),
    fetchPriceInfoLastSaleLoadMoreWatcher(),
    fetchTopPriceLeftWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
