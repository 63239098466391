import get from "lodash/get";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import { convertHexToRGBA } from "./utils";

export const createGradientPlugin = (color: string) => {
  return {
    beforeDraw: function (chart: any, args: any, options: any) {
      const ctx = chart.ctx;
      const canvas = chart.canvas;
      const chartArea = chart.chartArea;

      // Chart background
      var gradientBack = canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 400);
      gradientBack.addColorStop(0, "rgba(34, 43, 54, 0.0001)");
      gradientBack.addColorStop(1, color);

      ctx.fillStyle = gradientBack;
      ctx.fillRect(
        chartArea.left,
        chartArea.bottom,
        chartArea.right - chartArea.left,
        chartArea.top - chartArea.bottom
      );
    },
  };
};

export const DerivativeLineColorPlugin = {
  beforeRender: (x: any, options: any) => {
    const c = x.chart;
    if (c.height === 0) return;
    const dataset = x.data.datasets[0];
    if (!dataset) return;
    const positive = x.data.lineColor.positive;
    const negative = x.data.lineColor.negative;
    const yScale = x.scales["closedLine"];
    if (x.data.thresholdValue == null) return;
    const yPos = yScale.getPixelForValue(x.data.thresholdValue);

    const gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
    // We can extract this logic to another function to improve readability
    // Case 1: min value of dataset have greater value than thresholdValue
    if (yPos / c.height > 1) {
      gradientFill.addColorStop(0, convertHexToRGBA(positive, 1));
      gradientFill.addColorStop(1, convertHexToRGBA(positive, 1));
    } else if (yPos / c.height < 0) {
      // Case 2: all red
      gradientFill.addColorStop(0, convertHexToRGBA(negative, 1));
      gradientFill.addColorStop(1, convertHexToRGBA(negative, 1));
    } else {
      // Normal happy case, thresholdValue in the data range
      gradientFill.addColorStop(0, convertHexToRGBA(positive, 1));
      gradientFill.addColorStop(yPos / c.height, convertHexToRGBA(positive, 1));
      gradientFill.addColorStop(yPos / c.height, convertHexToRGBA(negative, 1));
      gradientFill.addColorStop(1, convertHexToRGBA(negative, 1));
    }

    const model =
      x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
    model.borderColor = gradientFill;
  },
};

export const NetflowGradientPlugin = {
  beforeRender: (x: any, options: any) => {
    const c = x.chart;
    if (c.height === 0) return;
    const dataset = x.data.datasets[0];
    const yScale = x.scales["closedLine"];
    const thresholdValue = x.data.thresholdValue;
    if (thresholdValue == null) return;
    // 0 is threshold value for netflow, change bellow value if we have to update the threshold

    const yPos = yScale.getPixelForValue(0) || 0;

    const backgroundGradientFill = c.ctx.createLinearGradient(
      0,
      0,
      0,
      c.height
    );
    const green = "#08CA98";
    const red = "#DD3640";
    // Background gradient
    backgroundGradientFill.addColorStop(0, convertHexToRGBA(green, 0.8));
    if (yPos / c.height > 1) {
      backgroundGradientFill.addColorStop(0, convertHexToRGBA(green, 0.001));
      backgroundGradientFill.addColorStop(1, convertHexToRGBA(green, 0.001));
    } else if (yPos / c.height < 0) {
      backgroundGradientFill.addColorStop(0, convertHexToRGBA(red, 0.001));
      backgroundGradientFill.addColorStop(1, convertHexToRGBA(red, 0.001));
    } else {
      backgroundGradientFill.addColorStop(
        yPos / c.height,
        convertHexToRGBA(green, 0.001)
      );
      backgroundGradientFill.addColorStop(
        yPos / c.height,
        convertHexToRGBA(red, 0.001)
      );
    }

    backgroundGradientFill.addColorStop(1, convertHexToRGBA(red, 0.8));

    // Line gradient, this snippets allow dots connect together into a line
    const lineGradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);

    if (yPos / c.height >= 1) {
      lineGradientFill.addColorStop(0, convertHexToRGBA(green, 1));
      lineGradientFill.addColorStop(1, convertHexToRGBA(green, 1));
    } else if (yPos / c.height <= 0) {
      // Case 2: all red
      lineGradientFill.addColorStop(0, convertHexToRGBA(red, 1));
      lineGradientFill.addColorStop(1, convertHexToRGBA(red, 1));
    } else {
      // Normal happy case, thresholdValue in the data range
      lineGradientFill.addColorStop(0, convertHexToRGBA(green, 1));
      lineGradientFill.addColorStop(
        yPos / c.height,
        convertHexToRGBA(green, 1)
      );
      lineGradientFill.addColorStop(yPos / c.height, convertHexToRGBA(red, 1));
      lineGradientFill.addColorStop(1, convertHexToRGBA(red, 1));
    }

    const model =
      x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
    model.borderColor = lineGradientFill;
    model.backgroundColor = backgroundGradientFill;
  },
};

export const ChartHeaderGradientPlugin = {
  beforeRender: (x: any, options: any) => {
    const c = x.chart;
    if (c.height === 0) return;
    const dataset = x.data.datasets[0];
    const yScale = x.scales["closedLine"];
    const thresholdValue = x.data.thresholdValue;
    if (thresholdValue == null) return;
    // 0 is threshold value for netflow, change bellow value if we have to update the threshold

    const yPos = yScale.getPixelForValue(0) || 0;

    const backgroundGradientFill = c.ctx.createLinearGradient(
      0,
      0,
      0,
      c.height
    );
    const green = "#0EE84B";
    const red = "#FC3206";
    // Background gradient
    backgroundGradientFill.addColorStop(0, convertHexToRGBA(green, 0.8));
    if (yPos / c.height > 1) {
      backgroundGradientFill.addColorStop(0, convertHexToRGBA(green, 0.001));
      backgroundGradientFill.addColorStop(1, convertHexToRGBA(green, 0.001));
    } else if (yPos / c.height < 0) {
      backgroundGradientFill.addColorStop(0, convertHexToRGBA(red, 0.001));
      backgroundGradientFill.addColorStop(1, convertHexToRGBA(red, 0.001));
    } else {
      backgroundGradientFill.addColorStop(
        yPos / c.height,
        convertHexToRGBA(green, 0.001)
      );
      backgroundGradientFill.addColorStop(
        yPos / c.height,
        convertHexToRGBA(red, 0.001)
      );
    }

    backgroundGradientFill.addColorStop(1, convertHexToRGBA(red, 0.8));

    // Line gradient, this snippets allow dots connect together into a line
    const lineGradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);

    if (yPos / c.height >= 1) {
      lineGradientFill.addColorStop(0, convertHexToRGBA(green, 1));
      lineGradientFill.addColorStop(1, convertHexToRGBA(green, 1));
    } else if (yPos / c.height <= 0) {
      // Case 2: all red
      lineGradientFill.addColorStop(0, convertHexToRGBA(red, 1));
      lineGradientFill.addColorStop(1, convertHexToRGBA(red, 1));
    } else {
      // Normal happy case, thresholdValue in the data range
      lineGradientFill.addColorStop(0, convertHexToRGBA(green, 1));
      lineGradientFill.addColorStop(
        yPos / c.height,
        convertHexToRGBA(green, 1)
      );
      lineGradientFill.addColorStop(yPos / c.height, convertHexToRGBA(red, 1));
      lineGradientFill.addColorStop(1, convertHexToRGBA(red, 1));
    }

    const model =
      x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model;
    model.borderColor = lineGradientFill;
    model.backgroundColor = backgroundGradientFill;
  },
};

const renderTicksColorByValue = (value: number) => {
  if (value > 0) {
    return "gradientGreen";
  }
  if (value < 0) {
    return "gradientRed";
  }
  return "textcolorWhite";
};

export const ChartAreaBorderPlugin = {
  id: "chartAreaBorder",
  beforeDraw(chart: any, args: any, options: any) {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.strokeRect(
      chartArea.left,
      chartArea.bottom,
      chartArea.right - chartArea.left,
      chartArea.top - chartArea.bottom
    );
    ctx.restore();
  },
};
export const DrawVerticalLineOnHoverPlugin = {
  afterDraw: function (chart: any, args: any, options: any) {
    const ctx = chart.ctx;

    if (chart.tooltip._active && chart.tooltip._active.length) {
      var activePoint = chart.tooltip._active[0],
        x = activePoint.tooltipPosition().x,
        topY = chart.legend.bottom,
        bottomY = chart.chartArea.bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = chart.data.lineColor.vertical;
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const DrawHorizontalLinePlugin = {
  afterDraw: function (chart: any, args: any, options: any) {
    const ctx = chart.ctx;
    const { thresholdValue, thresholdWidth } = chart.data;
    const lineDataset = chart.data.datasets[0];
    // Skip draw if dataset is empty
    if (!lineDataset) return;
    const yScale = chart.scales["closedLine"];
    const xScale = chart.scales["x-axis-0"];
    if (thresholdValue == null) return;
    const yPos = yScale.getPixelForValue(thresholdValue);
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = thresholdWidth ? thresholdWidth : 1;
    ctx.setLineDash([3, 3]);
    ctx.moveTo(xScale.left, yPos);
    ctx.strokeStyle = chart.data.lineColor.horizontal;
    ctx.lineTo(xScale.right, yPos);
    ctx.stroke();
    ctx.restore();
  },
};

export const createVN30IndexAxisPlugin = (themes: any) => {
  return {
    afterDraw: function (chart: any) {
      var ctx = chart.ctx;
      var xAxis = chart.scales["x-axis-0"];
      // loop through ticks array
      xAxis.ticks.forEach((tick: any, index: number, value: number[]) => {
        if (
          index === xAxis.ticks.length - 1 ||
          index === 0 ||
          Number(value[index]) === 0
        ) {
          var yPos = xAxis.top + 15;
          var xPos =
            index === xAxis.ticks.length - 1
              ? xAxis.getPixelForTick(index) - 30
              : xAxis.getPixelForTick(index) - 5;
          // draw tick
          ctx.save();
          ctx.font = "10px " + themes.fontFamily;
          ctx.fillStyle = themes[renderTicksColorByValue(value[index])];
          ctx.fillText(tick + "%", xPos, yPos);
          ctx.restore();
          return;
        }
        return;
      });
    },
  };
};

export const NetflowYAxisOffsetPlugin = (themes: any) => {
  return {
    afterDraw: function (chart: any) {
      var ctx = chart.ctx;
      var yAxis = chart.scales["closedLine"];
      // loop through ticks array
      yAxis.ticks.forEach((tick: any, index: number) => {
        if (index === yAxis.ticks.length - 1) return;
        var xPos = yAxis.right;
        var yPos = yAxis.getPixelForTick(index);
        // draw tick
        ctx.save();
        ctx.font = "10px " + themes.fontFamily;
        ctx.textBaseline = "middle";
        ctx.textAlign = "left";
        ctx.fillStyle = "#ADB3C0";
        ctx.fillText(tick, xPos, yPos);
        ctx.restore();
      });
    },
  };
};

export const FinanceLabelOnAxisPlugin = (themes: any) => {
  return {
    afterDraw: (chart: any, args: any, options: any) => {
      var ctx = chart.chart.ctx;
      var xAxis = chart.scales["xAxis1"];
      var yAxis = chart.scales["yAxis1"];
      ctx.save();
      ctx.textAlign = "center";

      if (!chart.data.datasets || !chart.data.datasets.length) return;
      // Draw labels
      chart.data.labels.forEach((label: any) => {
        var x = xAxis.getPixelForValue(label) - 4;
        var y = yAxis.bottom + 20;
        ctx.font = "12px " + themes.fontFamily;
        ctx.fillStyle = "#FFFFFF";
        ctx.fillText(label, x, y);
      });
      ctx.font = "10px " + themes.fontFamily;

      // Draw all dataset
      chart.data.datasets.forEach((dataset: any, dsIndex: any) => {
        chart.data.labels.forEach((label: any, labelIndex: any) => {
          let value = dataset.data[labelIndex];
          var x = xAxis.getPixelForValue(label) - 4;
          var y = yAxis.bottom + 20 * (dsIndex + 2);
          let fillStyle = "#FFFFFF";
          if (dataset.labelColor && isFunction(dataset.labelColor)) {
            fillStyle = dataset.labelColor(value);
          }

          if (dataset.labelColor && isString(dataset.labelColor)) {
            fillStyle = dataset.labelColor;
          }

          if (
            get(dataset, "formatter") &&
            isFunction(get(dataset, "formatter"))
          ) {
            value = get(dataset, "formatter")(value);
          }

          ctx.fillStyle = fillStyle;
          ctx.fillText(value, x, y);
        });
      });
      ctx.restore();
    },
  };
};

const getSuitableY = (
  y: number,
  yArray: number[] = [],
  direction: "left" | "right"
) => {
  let result = y;
  yArray.forEach((existedY) => {
    if (existedY - 14 < result && existedY + 14 > result) {
      if (direction === "right") {
        result = existedY + 14;
      } else {
        result = existedY - 14;
      }
    }
  });

  return result;
};

export const PieChartSingleLayerPolylinePlugin = (themes: any) => {
  return {
    afterDraw: (chart: any) => {
      const ctx = chart.chart.ctx;
      ctx.save();
      ctx.font = "10px " + themes.fontFamily;
      const leftLabelCoordinates: number[] = [];
      const rightLabelCoordinates: number[] = [];
      const chartCenterPoint = {
        x:
          (chart.chartArea.right - chart.chartArea.left) / 2 +
          chart.chartArea.left,
        y:
          (chart.chartArea.bottom - chart.chartArea.top) / 2 +
          chart.chartArea.top,
      };
      chart.config.data.labels.forEach((label: string, i: number) => {
        const meta = chart.getDatasetMeta(0);
        const arc = meta.data[i];
        const dataset = chart.config.data.datasets[0];

        // Prepare data to draw
        // important point 1
        const centerPoint = arc.getCenterPoint();
        const model = arc._model;
        let color = model.borderColor;
        let labelColor = model.borderColor;
        if (dataset.polyline && dataset.polyline.color) {
          color = dataset.polyline.color;
        }

        if (dataset.polyline && dataset.polyline.labelColor) {
          labelColor = dataset.polyline.labelColor;
        }

        const angle = Math.atan2(
          centerPoint.y - chartCenterPoint.y,
          centerPoint.x - chartCenterPoint.x
        );
        // important point 2
        const point2X =
          chartCenterPoint.x + Math.cos(angle) * (model.outerRadius + 5);
        let point2Y =
          chartCenterPoint.y + Math.sin(angle) * (model.outerRadius + 5);

        let suitableY;
        if (point2X < chartCenterPoint.x) {
          // on the left
          suitableY = getSuitableY(point2Y, leftLabelCoordinates, "left");
        } else {
          // on the right
          suitableY = getSuitableY(point2Y, rightLabelCoordinates, "right");
        }

        point2Y = suitableY;

        let value = dataset.data[i];
        if (dataset.polyline && dataset.polyline.formatter) {
          value = dataset.polyline.formatter(value);
        }
        let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

        if (point2X < chartCenterPoint.x) {
          leftLabelCoordinates.push(point2Y);
        } else {
          rightLabelCoordinates.push(point2Y);
        }
        //DRAW CODE
        // first line: connect between arc's center point and outside point
        ctx.globalCompositeOperation = "destination-over";
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.moveTo(centerPoint.x, centerPoint.y);
        ctx.lineTo(point2X, point2Y);
        ctx.stroke();
        // second line: connect between outside point and chart's edge
        ctx.beginPath();
        ctx.moveTo(point2X, point2Y);
        ctx.lineTo(edgePointX, point2Y);
        ctx.stroke();
        //fill custom label
        const labelAlignStyle =
          edgePointX < chartCenterPoint.x ? "left" : "right";
        const labelX = edgePointX;
        const labelY = point2Y;
        ctx.textAlign = labelAlignStyle;
        ctx.textBaseline = "bottom";

        ctx.fillStyle = labelColor;
        ctx.fillText(value, labelX, labelY);
      });
      ctx.restore();
    },
  };
};
