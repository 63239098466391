import { FC, MouseEvent, useRef } from "react";
import { ReactComponent as SelectedIcon } from "assets/image/svg/ic_checkbox_selected.svg";
import { ReactComponent as UnSelectedIcon } from "assets/image/svg/ic_checkbox_unselected.svg";
import { StyledCheckBox, HidenCheckBox, Container } from "./styles";

interface IProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const CheckBox: FC<IProps> = ({ checked, disabled, onClick, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const clickLabelHandler = (e: MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      if (inputRef !== null && inputRef.current !== null) {
        inputRef.current.click();
      }
      onClick?.(e);
    }
  };

  return (
    <Container onClick={clickLabelHandler}>
      <StyledCheckBox disabled={disabled}>
        {checked ? <SelectedIcon /> : <UnSelectedIcon />}
      </StyledCheckBox>
      <HidenCheckBox
        ref={inputRef}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        {...rest}
      />
    </Container>
  );
};

export default CheckBox;
