import { toast } from "components/commons/Toast";
import { getListMarginContract } from "core/http/apis/margin-contract";
import { IGetMarginContractParams } from "core/http/apis/margin-contract/types";
import { IMarginContractResult } from "domain/models/MarginContract";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  searchMarginContractRequest,
  searchMarginContractSuccess,
  searchMarginContractFailure,
} from "./redux";

function* searchMarginContractWorker(
  action: ReturnType<typeof searchMarginContractRequest>
) {
  try {
    const params: IGetMarginContractParams = action.payload;
    const response: IMarginContractResult = yield call(
      getListMarginContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchMarginContractSuccess(response));
    } else {
      yield put(searchMarginContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchMarginContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchMarginContractWatcher() {
  yield takeLatest(
    searchMarginContractRequest.type,
    searchMarginContractWorker
  );
}

export default function* marginLimitSaga() {
  yield all([searchMarginContractWatcher()]);
}
