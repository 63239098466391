import { memo } from "react";
import {
  NetflowContainer,
  NetflowLegendContainer,
  ChartContainer,
  LegendBlock,
  NetflowMainContent,
} from "./styles";
import NetflowChart from "./NetflowChart";
import Header from "./Header";
import { FormattedMessage } from "react-intl";

function NetflowWidget() {
  return (
    <NetflowContainer>
      <Header />
      <NetflowMainContent>
        <NetflowLegendContainer>
          <div className="flex-1"></div>
          <div className="row flex-5">
            <div className="col-sm-12 col-md-6 positive">
              <LegendBlock color="solidGreen" />
              <FormattedMessage id={"widgets.netflow.positive"} />
            </div>
            <div className="col-sm-12 col-md-6 negative">
              <LegendBlock color="solidRed" />
              <FormattedMessage id={"widgets.netflow.negative"} />
            </div>
          </div>
          <div className="flex-1"></div>
        </NetflowLegendContainer>
        <ChartContainer>
          <NetflowChart />
        </ChartContainer>
      </NetflowMainContent>
    </NetflowContainer>
  );
}

export default memo(NetflowWidget);
