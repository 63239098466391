import styled from "styled-components";

export const Container = styled.div`
  height: calc(100% - 35px);
  width: 100%;
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% - 30px) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.red;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green;
      case "violet":
        return theme.violet;
      case "orange":
        return theme.solidOrange;
      default:
        return color;
    }
  }};

  opacity: 0.4px;
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.grey20};
  height: 28px;
  display: flex;
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal9.lineHeight};
  padding: 0px 8px;
  gap: 4px;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonDelete = styled.button`
  background: transparent;
  padding: 0px;
`;
