import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { StatusContainer } from "../style";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
import { FormattedMessage } from "react-intl";

const colorStatusSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) =>
      state?.[realTimeId]?.orderStatusColor
  );
};

const orderStatusPercentSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) =>
      state?.[realTimeId]?.orderStatusPercent
  );
};

const orderStatusDisplaySelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) =>
      state?.[realTimeId]?.orderStatusDisplay
  );
};

interface Props {
  realTimeId: string;
}

const StatusCell = ({ realTimeId }: Props) => {
  const orderStatusColor = useSelector(colorStatusSelector(realTimeId));
  const orderStatusPercent = useSelector(
    orderStatusPercentSelector(realTimeId)
  );
  const orderStatusDisplay = useSelector(
    orderStatusDisplaySelector(realTimeId)
  );
  return (
    <>
      <StatusContainer status={orderStatusColor} progress={orderStatusPercent}>
        <TableCellContent align={"center"}>
          <FormattedMessage id={orderStatusDisplay} />
        </TableCellContent>
      </StatusContainer>
    </>
  );
};

export default StatusCell;
