import {
  getOrderHistory,
  getDealOfOrder,
  getConditionHistory,
  getConditionDetail,
  findOrderByFilterApi,
  findRequestCondApi,
  findOrderDetailApi,
  findOrderByRequestCondApi,
  findDerOrderByFilterApi,
  findDertRequestCondApi,
  findOrderDetailDerApi,
  findDertOrderCondApi,
  findDerOrderDetailApi,
} from "./urls";
import storage from "helper/storage";
import HttpOrderHistory from "../../singleton/order-history";
import {
  OrderHistoryCSInterface,
  DealOfOrderCSInterface,
  OrderHistoryFSInterface,
  DealOfOrderFSInterface,
  ConditionHistoryInterface,
  ConditionDetailInterface,
  IFindOrderByFilterResponse,
  IFindRequestCondResponse,
  IFindOrderDetailResponse,
  IFindOrderByRequestCondResponse,
  IFindDertOrderByFilterResponse,
  IFindDertRequestCondResponse,
  IFindOrderDetailDerResponse,
  IFindDertOrderCondResponse,
  IFindDerOrderDetailResponse,
} from "domain/models/OrderHistory";
import {
  OrderHistoryParams,
  DealOfOrderParams,
  ConditionHistoryParams,
  ConditionDetailParams,
  IFindOrderByFilterParams,
  IFindRequestCondParams,
  IFindOrderDetailParams,
  IFindOrderByRequestCondParams,
  IFindDertOrderByFilterParams,
  IFindDertRequestCondParams,
  IFindOrderDetailDerParams,
  IFindDertOrderCondParams,
  IFindDerOrderDetailParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";
/**
 * Lấy lịch sử đặt lệnh
 */
export const getOrderHistoryCSApi = async (
  params: OrderHistoryParams
): Promise<OrderHistoryCSInterface> => {
  const res = await HttpOrderHistory.get<OrderHistoryCSInterface>(
    getOrderHistory(storage.getMasterAccount()),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

/**
 * Lấy chi tiết khớp lệnh
 */
export const getDealOfOrderCSApi = async (
  params: DealOfOrderParams
): Promise<DealOfOrderCSInterface> => {
  const res = await HttpOrderHistory.get<DealOfOrderCSInterface>(
    getDealOfOrder(storage.getMasterAccount()),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

/**
 * Lấy lịch sử đặt lệnh phái sinh
 */
export const getOrderHistoryFSApi = async (
  params: OrderHistoryParams
): Promise<OrderHistoryFSInterface> => {
  const res = await HttpOrderHistory.get<OrderHistoryFSInterface>(
    getOrderHistory(storage.getMasterAccount()),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

/**
 * Lấy chi tiết khớp lệnh phái sinh
 */
export const getDealOfOrderFSApi = async (
  params: DealOfOrderParams
): Promise<DealOfOrderFSInterface> => {
  const res = await HttpOrderHistory.get<DealOfOrderFSInterface>(
    getDealOfOrder(storage.getMasterAccount()),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

/**
 * Lấy lịch sử lệnh điều kiện
 */
export const getConditionHistoryApi = async (
  params: ConditionHistoryParams
): Promise<ConditionHistoryInterface> => {
  const res = await HttpOrderHistory.get<ConditionHistoryInterface>(
    getConditionHistory(),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
/**
 * Lấy chi tiết khớp lệnh điều kiện
 */
export const getConditionDetailApi = async (
  params: ConditionDetailParams
): Promise<ConditionDetailInterface> => {
  const res = await HttpOrderHistory.get<ConditionDetailInterface>(
    getConditionDetail(),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findOrderByFilter = async (
  params: IFindOrderByFilterParams
): Promise<IFindOrderByFilterResponse> => {
  const res = await VgaiaHttp.get<IFindOrderByFilterResponse>(
    findOrderByFilterApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDerOrderByFilter = async (
  params: IFindDertOrderByFilterParams
): Promise<IFindDertOrderByFilterResponse> => {
  const res = await VgaiaHttp.get<IFindDertOrderByFilterResponse>(
    findDerOrderByFilterApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findRequestCond = async (
  params: IFindRequestCondParams
): Promise<IFindRequestCondResponse> => {
  const res = await VgaiaHttp.get<IFindRequestCondResponse>(
    findRequestCondApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDertRequestCond = async (
  params: IFindDertRequestCondParams
): Promise<IFindDertRequestCondResponse> => {
  const res = await VgaiaHttp.get<IFindDertRequestCondResponse>(
    findDertRequestCondApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findOrderDetail = async (
  params: IFindOrderDetailParams
): Promise<IFindOrderDetailResponse> => {
  const res = await VgaiaHttp.get<IFindOrderDetailResponse>(
    findOrderDetailApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findOrderDetailDer = async (
  params: IFindOrderDetailDerParams
): Promise<IFindOrderDetailDerResponse> => {
  const res = await VgaiaHttp.get<IFindOrderDetailDerResponse>(
    findOrderDetailDerApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDerOrderDetail = async (
  params: IFindDerOrderDetailParams
): Promise<IFindDerOrderDetailResponse> => {
  const res = await VgaiaHttp.get<IFindDerOrderDetailResponse>(
    findDerOrderDetailApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findOrderByRequestCond = async (
  params: IFindOrderByRequestCondParams
): Promise<IFindOrderByRequestCondResponse> => {
  const res = await VgaiaHttp.get<IFindOrderByRequestCondResponse>(
    findOrderByRequestCondApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDertOrderCond = async (
  params: IFindDertOrderCondParams
): Promise<IFindDertOrderCondResponse> => {
  const res = await VgaiaHttp.get<IFindDertOrderCondResponse>(
    findDertOrderCondApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
