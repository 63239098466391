import { BlinkTime } from "AppConfig";
import { MarketIndexInfo } from "domain/protoNew/auto_trading_pb";
import {
  colorHeaderCardSelector,
  valueHeaderCardSelector,
} from "modules/system/selectors";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TextColor } from "../styles";
interface Props {
  ticker: string;
  fieldValue: keyof MarketIndexInfo.AsObject;
  isPercentage?: boolean;
  className?: string;
}

function NumberChange({
  ticker,
  fieldValue,
  isPercentage = false,

  className,
}: Props) {
  const oldData = useRef(0);
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const data = useSelector(
    valueHeaderCardSelector(ticker, fieldValue)
  ) as number;
  const color = useSelector(colorHeaderCardSelector(ticker));

  useEffect(() => {
    const _oldData = oldData.current;
    oldData.current = data;
    if (_oldData !== data) {
      setBackgroundColor("solidFlash");
    }
    const timeout = setTimeout(() => setBackgroundColor(""), BlinkTime);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [numeral(data).format("0.00")]);
  return (
    <TextColor
      className={className}
      backgroundColor={backgroundColor}
      textcolor={color}
    >
      {fieldValue === "changeindex" && "("}
      {numeral(data).format(isPercentage ? "+0.00" : "0.00")}
      {isPercentage && "%)"}
    </TextColor>
  );
}

export default NumberChange;
