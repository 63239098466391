import queryString from "query-string";
import {
  ISearchBondOrderParams,
  IGetBondListParams,
  // IRejectBondOrderParams,
} from "./types";

export const searchBondOrderApi = (params: ISearchBondOrderParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getBondOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getBondListApi = (params: IGetBondListParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findBondList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const enterBondOrderApi = "/enterBondOrder";

// export const rejectBondOrderApi = (params: IRejectBondOrderParams) => {
//   const paramsFormatter = queryString.stringify(params, {
//     skipNull: true,
//   });
//   return `/rejectBondOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
// };
export const rejectBondOrderApi = "/rejectBondOrder";
