import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetNotificationListParams,
  GetNotificationTotalUnreadParams,
  DeleteNotificationParams,
  NotificationCheckReadParams,
  NotificationCheckReadAllParams,
  NotificationDetailParams,
} from "core/http/apis/trans/types";
import {
  NotificationTotalUnread,
  NotificationDetail,
  ResultDeleteNotification,
  ResultCheckReadNotification,
  ResultCheckReadAllNotification,
} from "domain/models/Notification";
import {
  NotificationListData,
  NotificationGroupByDate,
  NotificationItem,
} from "../types";

export type NotificationState = {
  unreadNotificationParams: GetNotificationTotalUnreadParams;
  notificationTotalUnread: NotificationTotalUnread;
  notificationParams: GetNotificationListParams;
  notification: NotificationListData;
  visibleModalNotification: boolean;
  notificationDetailParams: NotificationDetailParams;
  notificationDetail: NotificationDetail;
  notificationDeleteParams: DeleteNotificationParams;
  resultDeleteNotification: ResultDeleteNotification;
  isVisibleNotificationDetail: boolean;
  isLoadingDetailNotification: boolean;
  notificationCheckReadParams: NotificationCheckReadParams;
  notificationCheckReadAllParams: NotificationCheckReadAllParams;
  resultCheckReadNotification: ResultCheckReadNotification;
  resultCheckReadAllNotification: ResultCheckReadAllNotification;
  changeTabNotification: boolean;
  isLoadingNotification: boolean;
  isLoadingFetchMore: boolean;
  countDeleteNorification: number;
  isFetchMoreDelete: boolean;
  totalNotificationUnread: number;
};

const initialState: NotificationState = {
  notificationTotalUnread: {
    data: [],
    status: "",
  },
  unreadNotificationParams: {
    masterAccount: "",
  },
  notificationParams: {
    type: 1,
    offset: 0,
    limit: 20,
  },
  notification: {
    items: [],
  },
  visibleModalNotification: false,
  changeTabNotification: false,
  notificationDetailParams: {
    businessDate: 0,
    noticeId: "",
  },
  notificationDetail: {},
  notificationDeleteParams: {
    masterAccount: "",
    date: "",
    noticeId: "",
  },
  resultDeleteNotification: {
    result: 0,
    error: "",
    message: "",
  },
  notificationCheckReadParams: {
    masterAccount: "",
    date: "",
    noticeId: "",
    actionType: 0,
  },
  notificationCheckReadAllParams: {
    masterAccount: "",
    noticeType: 0,
  },
  resultCheckReadNotification: {
    result: 0,
    error: "",
    message: "",
  },
  resultCheckReadAllNotification: {
    result: 0,
    error: "",
    message: "",
  },
  isVisibleNotificationDetail: false,
  isLoadingDetailNotification: false,
  isLoadingNotification: false,
  isLoadingFetchMore: false,
  countDeleteNorification: 0,
  isFetchMoreDelete: false,
  totalNotificationUnread: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotificationTotalUnreadStart: (state) => {},
    getNotificationTotalUnreadSuccess: (
      state,
      action: PayloadAction<NotificationTotalUnread>
    ) => {
      state.notificationTotalUnread = action.payload;
      let totalNotificationUnread = 0;

      if (action.payload) {
        action.payload.data.forEach((item) => {
          totalNotificationUnread = totalNotificationUnread + item.countUnread;
        });
      }
      state.totalNotificationUnread = totalNotificationUnread;
    },
    getNotificationTotalUnreadFailed: (state) => {},
    getNotification: (
      state,
      action: PayloadAction<{
        params: GetNotificationListParams;
        isChangeTab?: boolean;
      }>
    ) => {
      if (!action.payload.isChangeTab) {
        state.changeTabNotification = false;
        state.isLoadingFetchMore = true;
        state.isLoadingNotification = false;
      } else {
        state.changeTabNotification = true;
        state.isLoadingFetchMore = false;
        state.isLoadingNotification = true;
      }
      state.notificationParams = action.payload.params;
    },
    getNotificationSuccess: (
      state,
      action: PayloadAction<NotificationListData>
    ) => {
      if (state.changeTabNotification) {
        state.notification = action.payload;
        state.isLoadingNotification = false;
        state.isLoadingFetchMore = false;
      } else {
        if (
          state.notification.items[state.notification.items.length - 1] &&
          action.payload.items[0] &&
          state.notification.items[state.notification.items.length - 1].date ===
            action.payload.items[0].date
        ) {
          const mergeItem: NotificationItem[] = state.notification.items[
            state.notification.items.length - 1
          ].items.concat(action.payload.items[0].items);
          const mergeItemDate: NotificationGroupByDate = {
            date: action.payload.items[0].date,
            items: mergeItem,
          };
          state.notification.items.pop();
          state.notification.items.push(mergeItemDate);
          action.payload.items.shift();
        }
        const notification: NotificationListData = {
          items: state.notification.items.concat(action.payload.items),
        };
        state.notification = notification;
        state.isLoadingNotification = false;
        state.isLoadingFetchMore = false;
        state.countDeleteNorification = 0;
        state.isFetchMoreDelete = false;
      }
    },
    getNotificationFailed: (state, action: PayloadAction<Error>) => {
      state.isLoadingNotification = false;
      state.isLoadingFetchMore = false;
    },
    getNotificationDetailParams: (
      state,
      action: PayloadAction<NotificationDetailParams>
    ) => {
      state.notificationDetailParams = action.payload;
    },
    getNotificationDetailSuccess: (
      state,
      action: PayloadAction<NotificationDetail>
    ) => {
      state.notificationDetail = action.payload;
      state.isLoadingDetailNotification = false;
    },
    getNotificationDetailFailed: (state, action: PayloadAction<Error>) => {
      state.isLoadingDetailNotification = false;
    },

    deleteNotificationParams: (
      state,
      action: PayloadAction<{
        params: DeleteNotificationParams;
        notificationList: NotificationListData;
      }>
    ) => {
      state.notificationDeleteParams = action.payload.params;
    },
    deleteNotificationSuccess: (
      state,
      action: PayloadAction<{
        result: ResultDeleteNotification;
        noticeId: string;
        notificationList: NotificationListData;
      }>
    ) => {
      state.resultDeleteNotification = action.payload.result;
      const notificationDate: NotificationGroupByDate[] =
        action.payload.notificationList.items.map((item) => {
          const notificatinoItems: NotificationItem[] = item.items.filter(
            (notification) =>
              notification.noticeId?.toString() !== action.payload.noticeId
          );
          return {
            date: item.date,
            items: notificatinoItems,
          };
        });
      const notificationDateNew: NotificationGroupByDate[] =
        notificationDate.filter((item) => item.items.length > 0);
      const notification: NotificationListData = {
        items: notificationDateNew,
      };
      state.notification = notification;
      state.countDeleteNorification += 1;
      if (state.countDeleteNorification === 10) {
        state.isFetchMoreDelete = true;
      }
    },
    deleteNotificationFailed: (state, action: PayloadAction<Error>) => {},
    checkReadNotificationParams: (
      state,
      action: PayloadAction<NotificationCheckReadParams>
    ) => {
      state.notificationCheckReadParams = action.payload;
    },
    checkReadNotificationSuccess: (
      state,
      action: PayloadAction<{
        params: ResultCheckReadNotification;
        noticeId: string;
      }>
    ) => {
      state.resultCheckReadNotification = action.payload.params;
      const notificationDate: NotificationGroupByDate[] =
        state.notification.items.map((item) => {
          const notificatinoItems: NotificationItem[] = item.items.map(
            (notification) => {
              if (
                notification.noticeId?.toString() === action.payload.noticeId
              ) {
                notification.readState = 1;
              }
              return notification;
            }
          );
          return {
            date: item.date,
            items: notificatinoItems,
          };
        });
      const notification: NotificationListData = {
        items: notificationDate,
      };
      state.notification = notification;
    },
    checkReadNotificationFailed: (state, action: PayloadAction<Error>) => {},
    checkReadAllNotificationParams: (
      state,
      action: PayloadAction<NotificationCheckReadAllParams>
    ) => {
      state.notificationCheckReadAllParams = action.payload;
    },
    checkReadAllNotificationSuccess: (
      state,
      action: PayloadAction<ResultCheckReadAllNotification>
    ) => {
      state.resultCheckReadAllNotification = action.payload;
      if (action.payload.result === 1) return;
      const notificationDate: NotificationGroupByDate[] =
        state.notification.items.map((item) => {
          const notificatinoItems: NotificationItem[] = item.items.map(
            (notification) => {
              notification.readState = 1;
              return notification;
            }
          );
          return {
            date: item.date,
            items: notificatinoItems,
          };
        });
      const notification: NotificationListData = {
        items: notificationDate,
      };
      state.notification = notification;
    },
    checkReadAllNotificationFailed: (state, action: PayloadAction<Error>) => {},
    requestChangeVisibleModalNotification: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.visibleModalNotification = action.payload;
    },
    changeVisibleNotificationDetail: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingDetailNotification = true;
      state.isVisibleNotificationDetail = action.payload;
    },
    changeTotalUnreadNoti: (state) => {
      state.totalNotificationUnread++;
    },
  },
});

export const {
  getNotificationTotalUnreadStart,
  getNotificationTotalUnreadSuccess,
  getNotification,
  getNotificationSuccess,
  requestChangeVisibleModalNotification,
  changeVisibleNotificationDetail,
  getNotificationDetailParams,
  getNotificationDetailSuccess,
  getNotificationTotalUnreadFailed,
  getNotificationFailed,
  getNotificationDetailFailed,
  deleteNotificationParams,
  deleteNotificationSuccess,
  deleteNotificationFailed,
  checkReadNotificationParams,
  checkReadNotificationSuccess,
  checkReadNotificationFailed,
  checkReadAllNotificationParams,
  checkReadAllNotificationSuccess,
  checkReadAllNotificationFailed,
  changeTotalUnreadNoti,
} = notificationSlice.actions;

export default notificationSlice.reducer;
