import { STATISTIC_ONDAY_TABS } from "modules/statistic-onday/constants";
import { activeTabSelector } from "modules/statistic-onday/selectors";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";

const Tabs: FC = () => {
  const intl = useIntl();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab isActive={activeTab === STATISTIC_ONDAY_TABS.STATISTIC_ONDAY}>
          {intl.formatMessage({ id: "statisticOnday.tabs.statisticOndayTab" })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
