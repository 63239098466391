import queryString from "query-string";
import { ISearchMrgContractDebitParams } from "./types";

export const findMarginContractDebitWatchApi = (
  params: ISearchMrgContractDebitParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return `/findMarginContractDebitWatch${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
