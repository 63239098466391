import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import PriceList from "components/widgets/details/PriceList";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { TopPrice, TopPriceOdd } from "domain/protoNew/auto_trading_pb";
import {
  clearData,
  getInfoQuote,
  getInfoQuoteUpdate,
} from "../redux/priceList";
import { tickersListSelectedSelector } from "modules/categories/redux/selectors";
import { WatchlistGroupType } from "helper/consts";

interface MarketPriceListProps {
  isEnableHeader?: boolean;
}

// TODO
function MarketPriceList({ isEnableHeader }: MarketPriceListProps) {
  const dispatch = useDispatch();
  const selectedTicker = useSelector(selectedTickerSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);
  const { type } = useSelector(tickersListSelectedSelector);

  useEffect(() => {
    if (selectedTicker) {
      subscribeTickers(selectedTicker!);
      dispatch(getInfoQuote(selectedTicker));
    } else {
      dispatch(clearData());
    }

    return () => {
      if (!!selectedTicker) {
        unSubscribeTickers(selectedTicker!);
      }
    };
  }, [selectedTicker]);

  useEffect(() => {
    const topPriceEventHandler = (dataEmmit: TopPrice.AsObject) => {
      if (type === WatchlistGroupType.ODDLOT) {
        return;
      }
      if (dataEmmit.seccd === selectedTicker) {
        const toppricebidList = (top: number) =>
          dataEmmit.toppricebidList.filter((value) => value.top === top)[0];

        const toppriceofferList = (top: number) =>
          dataEmmit.toppriceofferList.filter((value) => value.top === top)[0];

        const batchTickerInfo: any = {
          best1bidpricestr: toppricebidList(1)?.price,
          best1bidqty: toppricebidList(1)?.qty,
          best2bidprice: toppricebidList(2)?.price,
          best2bidqty: toppricebidList(2)?.qty,
          best3bidprice: toppricebidList(3)?.price,
          best3bidqty: toppricebidList(3)?.qty,
          best1offerpricestr: toppriceofferList(1)?.price,
          best1offerqty: toppriceofferList(1)?.qty,
          best2offerprice: toppriceofferList(2)?.price,
          best2offerqty: toppriceofferList(2)?.qty,
          best3offerprice: toppriceofferList(3)?.price,
          best3offerqty: toppriceofferList(3)?.qty,
        };
        Object.keys(batchTickerInfo).forEach(
          (key) =>
            batchTickerInfo[key] === undefined && delete batchTickerInfo[key]
        );

        dispatch(getInfoQuoteUpdate(batchTickerInfo));
      }
    };
    const topPriceOddEventHandler = (dataEmmit: TopPriceOdd.AsObject) => {
      if (type !== WatchlistGroupType.ODDLOT) {
        return;
      }
      if (dataEmmit.seccd === selectedTicker) {
        const toppricebidList = (top: number) =>
          dataEmmit.toppricebidList.filter((value) => value.top === top)[0];

        const toppriceofferList = (top: number) =>
          dataEmmit.toppriceofferList.filter((value) => value.top === top)[0];

        const batchTickerInfo: any = {
          best1bidpricestr: toppricebidList(1)?.price,
          best1bidqty: toppricebidList(1)?.qty,
          best2bidprice: toppricebidList(2)?.price,
          best2bidqty: toppricebidList(2)?.qty,
          best3bidprice: toppricebidList(3)?.price,
          best3bidqty: toppricebidList(3)?.qty,
          best1offerpricestr: toppriceofferList(1)?.price,
          best1offerqty: toppriceofferList(1)?.qty,
          best2offerprice: toppriceofferList(2)?.price,
          best2offerqty: toppriceofferList(2)?.qty,
          best3offerprice: toppriceofferList(3)?.price,
          best3offerqty: toppriceofferList(3)?.qty,
        };
        Object.keys(batchTickerInfo).forEach(
          (key) =>
            batchTickerInfo[key] === undefined && delete batchTickerInfo[key]
        );

        dispatch(getInfoQuoteUpdate(batchTickerInfo));
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, topPriceEventHandler);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_TopPriceOdd, topPriceOddEventHandler);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, topPriceEventHandler);
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_TopPriceOdd, topPriceOddEventHandler);
    };
  }, [selectedTicker]);

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      if (!!selectedTicker) {
        dispatch(getInfoQuote(selectedTicker!));
      }
    }
  }, [isVisibilityPage]);

  return <PriceList isEnableHeader={isEnableHeader} />;
}

export default memo(MarketPriceList) as React.FC<MarketPriceListProps>;
