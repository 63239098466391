import { BlinkTime } from "AppConfig";
import { MarketIndexInfo } from "domain/protoNew/auto_trading_pb";
import { valueHeaderCardSelector } from "modules/system/selectors";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TextColorMini } from "../styles";

interface Props {
  ticker: string;
  fieldValue: keyof MarketIndexInfo.AsObject;
  format?: string;
}

function ValueChange({ ticker, fieldValue, format = "0,0" }: Props) {
  const data = useSelector(
    valueHeaderCardSelector(ticker, fieldValue)
  ) as number;

  const oldData = useRef(0);
  const [backgroundColor, setBackgroundColor] = useState<string>("");

  useEffect(() => {
    const _oldData = oldData.current;
    oldData.current = numeral(data).value() || 0;
    if (_oldData !== data) {
      setBackgroundColor("primaryP5");
    }
    const timeout = setTimeout(() => setBackgroundColor(""), BlinkTime);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [numeral(data).format(format)]);
  return (
    <TextColorMini backgroundColor={backgroundColor}>
      {numeral(data).format(format)}
    </TextColorMini>
  );
}
export default ValueChange;
