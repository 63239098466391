import { IFindMarginSecuritiesParams } from "./types";
import queryString from "query-string";

export const findMarginSecuritiesApi = (
  params: IFindMarginSecuritiesParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return `/findMarginSecrities${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
