import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const advanceSelector = (state: RootState) => state.advance;

export const activeTabSelector = createSelector(
  advanceSelector,
  (state) => state.activeTab
);

export const listAdvanceSelector = createSelector(
  advanceSelector,
  (state) => state.list
);

export const loadingSearchDataSelector = createSelector(
  advanceSelector,
  (state) => state.loading
);

export const filterAdvanceSelector = createSelector(
  advanceSelector,
  (state) => state.filter
);

export const advanceEditSelector = createSelector(
  advanceSelector,
  (state) => state.advance
);
export const isShowConfirmModalSelector = createSelector(
  advanceSelector,
  (state) => state.isShowConfirmModal
);
export const isShowOtpModalSelector = createSelector(
  advanceSelector,
  (state) => state.isShowOtpModal
);
export const isShowOtpModalLiquidSelector = createSelector(
  advanceSelector,
  (state) => state.isShowOtpModalLiquid
);

export const statusCreateContractSelector = createSelector(
  advanceSelector,
  (state) => state.statusCreateContract
);
export const statusDeleteSelector = createSelector(
  advanceSelector,
  (state) => state.statusDelete
);

export const statusCheckSelector = createSelector(
  advanceSelector,
  (state) => state.statusCheck
);

export const getBankBrancdCdSelector = createSelector(
  advanceSelector,
  (state) => state.bankBrandCd
);
export const getBankBrancdCd2Selector = createSelector(
  advanceSelector,
  (state) => state.bankBrandCd2
);
export const getStatusCodeSelector = createSelector(
  advanceSelector,
  (state) => state.statusCode
);

export const getCurrentPage = createSelector(
  advanceSelector,
  (state) => state.pageCurrent
);
