/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select, InputTextarea } from "components/commons";
import { OptionSelectType } from "components/commons/Select";
import {
  EntrustBusinessCode,
  EOTPTypeSubmit,
  GetAccountType,
} from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { statusTypeTransfer } from "modules/transfer-internal/constants";
import {
  FormSubmit,
  getAccountTransfer,
  getAccToTransferInternal,
  resetDetailTransferInternal,
  updateModalStatusRequest,
  updateTypeTranfer,
  searchTransferLocalRequest,
} from "modules/transfer-internal/redux";
import {
  getListAccoToLocal,
  getTypeTransferSelector,
  loadingGetAccount,
  statusModal,
  transferDEtaiAccountGet,
  transferDEtaiAccountTransfer,
  transferLocalFilterSelector,
} from "modules/transfer-internal/selectors";
import moment from "moment";

import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "./ConfirmModal";
import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailRow,
  DetailTextareaRow,
  DetailWrapper,
  TextDetail,
  DetailContentWrapper,
} from "./styles";
import themes from "themes/abstracts/_themes";
import { RootState } from "redux/store";

const RequestDetail = () => {
  const typeTranfer = useSelector(getTypeTransferSelector);
  const isVisible = useSelector(statusModal);
  const intl = useIntl();
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const { fromSubAccoNo, toSubAccoNo, status, m_fromDate, m_toDate } =
    useSelector(transferLocalFilterSelector);
  const dispatch = useDispatch();
  const listAcc = GetAccoList(
    GetAccountType.NONE_BANK_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );
  const typeTransfeOptions = useMemo(() => statusTypeTransfer(intl), [intl]);
  const ListAccoToLocal = useSelector(getListAccoToLocal);

  // const changeHandler = (e: OptionSelectType | null, key: any) => {
  //   dispatch(updateTransferLocalFilter({ key, value: e?.value }));
  // };

  const accoToOptions = useMemo(() => {
    let list = [];
    list = ListAccoToLocal.map((account: any) => ({
      label: account.key!,
      value: account.value!,
    }));
    return list;
  }, [ListAccoToLocal]);

  const accountOptions = useMemo(() => {
    // let list = [];
    // list = listAcc.map((account: any) => ({
    //   label: account?.subAccoNo!,
    //   value: account?.subAccoNo!,
    // }));
    // let list: OptionSelectType[] = [];
    const list: any = listAcc
      ? listAcc
          .map((account: any) => {
            if (!account?.subAccoNo.includes(".51"))
              return {
                label: account?.subAccoNo ?? "",
                value: account?.subAccoNo ?? "",
              };
          })
          .filter((e: any) => e)
      : [];
    return list;
  }, [listAcc]);

  const changeAccountTransferHandler = async (
    item: OptionSelectType | null
  ) => {
    changeForm(item?.value, "subAccoNo");
    dispatch(getAccountTransfer({ subAccoNo: item?.value }));
  };

  const changeAccountGetHandler = async (item: OptionSelectType | null) => {
    changeForm(item?.value, "subAccoTo");
    dispatch(
      getAccToTransferInternal({
        subAccCd: accountTransfer?.subAccoCd,
        toSubAccountNo: item?.value,
        checkContract: accountTransfer?.checkLocalList,
      })
    );
  };

  const loadding = useSelector(loadingGetAccount);
  const accountTransfer = useSelector(transferDEtaiAccountTransfer);
  const accountGet = useSelector(transferDEtaiAccountGet);

  const defaultForm = {
    alloDate: Number(moment(new Date()).format("YYYYMMDD")),
    refNo: 0,
    subAccoNo: "",
    subAccoTo: accountGet?.subAccoNo,

    subAccoCd: accountTransfer?.subAccoCd,
    toSubAccoCd: accountGet?.subAccoCd,
    amount: 0,
    remarks: intl.formatMessage(
      {
        id: "transferInternal.transferInternal.detail.form.placeholderContent",
      },
      {
        subAccFrom: accountTransfer?.subAccoNo,
        subAccTo: accountGet?.subAccoNo,
      }
    ),
    password: null,
    timeStamp: "",
    mode: 1,
    otpCode: "",
    srcChannel: 2,
    otpType: EOTPTypeSubmit.OTP,
  };

  const [form, setform] = useState<FormSubmit>(defaultForm);

  const changeForm = (value: any, key: string) => {
    setform({
      ...form,
      [key]: value,
    });
  };

  const [error, setError] = useState<string | undefined>("");

  const checkError = () => {
    const value = Object.keys(form).find(
      (key) =>
        (form[key] === "" ||
          form[key] === 0 ||
          form[key] === null ||
          form[key] === undefined) &&
        key !== "otpCode" &&
        key !== "refNo" &&
        key !== "password" &&
        key !== "subAccoCd" &&
        key !== "toSubAccoCd" &&
        key !== "timeStamp"
    );
    if (!!value) {
      setError(value);
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const clickAcceptHandler = () => {
    const check = checkError();
    if (check) {
      dispatch(
        updateModalStatusRequest({
          ...form,
          amount: form?.amount ? numeral(form.amount).value() : 0,
        })
      );
    }
  };

  const valueTypeTransfer = useMemo(() => {
    return typeTransfeOptions.filter((e) => e.value === typeTranfer);
  }, [typeTranfer]);

  const clickResetHandler = () => {
    setform({
      ...defaultForm,
      subAccoNo: null,
      subAccoTo: null,
    });
    dispatch(resetDetailTransferInternal());
    dispatch(
      searchTransferLocalRequest({
        fromSubAccNo: fromSubAccoNo ?? "",
        toSubAccoNo: toSubAccoNo ?? "",
        status: status ? +status : -1,
        m_fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        m_toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        page: 0,
        rowPerPage: 30,
      })
    );
  };

  useEffect(() => {
    setform({
      ...form,
      remarks: intl.formatMessage(
        {
          id: "transferInternal.transferInternal.detail.form.placeholderContent",
        },
        {
          subAccFrom: accountTransfer?.subAccoNo,
          subAccTo: form?.subAccoTo,
        }
      ),
    });
  }, [accountTransfer, form?.subAccoTo]);
  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.typeMoneyTransfer" />
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              className="flex-fill"
              value={valueTypeTransfer}
              options={typeTransfeOptions}
              onChange={(e: any) =>
                dispatch(updateTypeTranfer({ type: e.value }))
              }
            />
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.accountFrom" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              value={
                accountOptions.find(
                  (item: any) => item.value === form?.subAccoNo
                )
                  ? accountOptions.find(
                      (item: any) => item.value === form?.subAccoNo
                    )
                  : { label: "", value: "" }
              }
              options={accountOptions}
              onChange={changeAccountTransferHandler}
            />
            {error === "subAccoNo" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.accountTo" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              value={
                accountOptions.find(
                  (item: any) => item.value === form?.subAccoTo
                )
                  ? accountOptions.find(
                      (item: any) => item.value === form?.subAccoTo
                    )
                  : { label: "", value: "" }
              }
              options={accoToOptions}
              onChange={changeAccountGetHandler}
            />
            {error === "subAccoTo" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.nameAccountFrom" />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            <TextDetail>{accountGet?.[0]}</TextDetail>
          </DetailContentWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.transferAvailability" />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            <TextDetail>
              {numeral(accountTransfer?.transferAvail).format("0,0")}
            </TextDetail>
          </DetailContentWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.amount" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper>
            <Input
              placeholder={intl.formatMessage({
                id: "transferInternal.transferInternal.detail.form.amount",
              })}
              rules={{ required: true }}
              value={numeral(form?.amount).format("0,0")}
              onChange={(e) => changeForm(e.target.value, "amount")}
            />
            {error === "amount" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailTextareaRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.content" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <InputTextarea
              textArea={true}
              onChange={(e: any) => changeForm(e.target.value, "remarks")}
              value={form?.remarks}
            />
            {error === "remarks" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailTextareaRow>

        <DetailRow>
          <p style={{ color: themes[themeType].textColorBlue }}>
            <FormattedMessage id="transferInternal.transferInternal.detail.form.note" />
          </p>
        </DetailRow>
      </DetailCol>
      <div className="d-flex justify-content-center p-2">
        <Button
          color="accept"
          className="button-filter mr-2 w-50"
          onClick={clickResetHandler}
          loading={loadding}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.cancel",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter w-50"
          loading={loadding}
          onClick={clickAcceptHandler}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.accept",
          })}
        </Button>
      </div>
      {isVisible && <ConfirmModal clickResetHandler={clickResetHandler} />}
    </DetailWrapper>
  );
};

export default RequestDetail;
