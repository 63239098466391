import { RootState } from "redux/store";
import { useSelector } from "react-redux";

import { Account, EntrustListItem } from "domain/models/User";
import { GetAccountType, EntrustBusinessCode } from "./consts";
import { accountInfoSelector } from "modules/auth/selectors";
import moment from "moment";
import { concat } from "lodash";

const nowDate = parseInt(moment(new Date()).format("YYYYMMDD"));
// Hàm check xem user đăng nhập chưa
export const IsAuthenticated = (): boolean => {
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.auth.root.data
  );
  return isAuthenticated;
};

const getListEntrustAcco = (
  entrustList: EntrustListItem[] | null,
  isEntrustAll: boolean,
  businessCd?: EntrustBusinessCode
) => {
  if (entrustList) {
    const newEntrustList = entrustList
      ?.filter(
        (item: EntrustListItem) =>
          item.startDate < nowDate &&
          (item.endDate === null || nowDate < item.endDate) &&
          (businessCd === undefined ||
            item.businessCd === businessCd ||
            (isEntrustAll && item.businessCd === EntrustBusinessCode.All))
      )
      .map((item: EntrustListItem) => ({
        subAccoCd: item.subAccoCd,
        subAccoNo: item.subAccoNo,
      }));
    return newEntrustList;
  } else {
    return [];
  }
};

// Lấy danh sách tài khoản cho các nghiệp vụ khác nhau
export const GetAccoList = (
  type?: GetAccountType,
  businessCd?: EntrustBusinessCode
): Account[] => {
  const { subAccoList, entrustList } = useSelector(accountInfoSelector);

  if (subAccoList === null && entrustList === null) {
    return [];
  }

  const list: Account[] = [];
  const newSubAccoList = subAccoList?.map((item: string) => ({
    subAccoCd: parseInt(item.split("|")[0]),
    subAccoNo: item.split("|")[1],
  }));
  //sort a-z newSubAccoList
  const newSubAccoListSort = newSubAccoList?.sort((firstItem, secondItem) =>
    secondItem.subAccoNo < firstItem.subAccoNo
      ? 1
      : firstItem.subAccoNo < secondItem.subAccoNo
      ? -1
      : 0
  );

  let newEntrustList: Account[] = [];

  switch (businessCd) {
    case EntrustBusinessCode.All:
      newEntrustList = getListEntrustAcco(entrustList, false, businessCd);
      break;
    case EntrustBusinessCode.Owner:
      break;
    default:
      newEntrustList = getListEntrustAcco(entrustList, true, businessCd);
      break;
  }

  //sort a-z newSubAccoList
  const newEntrustListSort = newEntrustList?.sort((firstItem, secondItem) =>
    secondItem.subAccoNo < firstItem.subAccoNo
      ? 1
      : firstItem.subAccoNo < secondItem.subAccoNo
      ? -1
      : 0
  );

  concat(newSubAccoListSort, newEntrustListSort).forEach((item: any) => {
    switch (type) {
      case GetAccountType.CASH:
        if (item?.subAccoNo.slice(-2, -1) === GetAccountType.CASH) {
          list.push(item);
        }
        break;
      case GetAccountType.MARGIN:
        if (item?.subAccoNo.slice(-2, -1) === GetAccountType.MARGIN) {
          list.push(item);
        }
        break;
      case GetAccountType.BANK:
        if (item?.subAccoNo.slice(-2, -1) === GetAccountType.BANK) {
          list.push(item);
        }
        break;
      case GetAccountType.DER:
        if (item?.subAccoNo.slice(-2, -1) === GetAccountType.DER) {
          list.push(item);
        }
        break;
      case GetAccountType.BOND:
        if (item?.subAccoNo.slice(-2, -1) === GetAccountType.BOND) {
          list.push(item);
        }
        break;
      case GetAccountType.NONE_DERT_SUB_ACCOUNT:
        if (item?.subAccoNo.slice(-2, -1) !== GetAccountType.DER) {
          list.push(item);
        }
        break;
      case GetAccountType.NONE_BOND_SUB_ACCOUNT:
        if (item?.subAccoNo.slice(-2, -1) !== GetAccountType.BOND) {
          list.push(item);
        }
        break;
      case GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT:
        if (
          item?.subAccoNo.slice(-2, -1) !== GetAccountType.DER &&
          item?.subAccoNo.slice(-2, -1) !== GetAccountType.BOND
        ) {
          list.push(item);
        }
        break;

      case GetAccountType.NONE_DERT_AND_BANK_SUB_ACCOUNT:
        if (
          item?.subAccoNo.slice(-2, -1) !== GetAccountType.DER &&
          item?.subAccoNo.slice(-2, -1) !== GetAccountType.BANK
        ) {
          list.push(item);
        }
        break;
      case GetAccountType.NONE_BANK_SUB_ACCOUNT:
        if (item?.subAccoNo.slice(-2, -1) !== GetAccountType.BANK) {
          list.push(item);
        }
        break;
      default:
        list.push(item);
        break;
    }
  });

  const uniqueIds: string[] = [];

  const uniqueList = list.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.subAccoNo);

    if (!isDuplicate) {
      uniqueIds.push(element.subAccoNo);

      return true;
    }

    return false;
  });

  return uniqueList;
};

export const GetParameters = (
  paramGroup: string,
  paramName: string
): string => {
  const { adfParamList, parameterList } = useSelector(accountInfoSelector);

  if (parameterList) {
    const parameter = parameterList.find(
      (item) => item.paramGroup === paramGroup && item.paramName === paramName
    );
    if (parameter) {
      return parameter.value;
    } else {
      if (adfParamList) {
        const parameterAdf = adfParamList.find(
          (item) =>
            item.paramGroup === paramGroup && item.paramName === paramName
        );
        if (parameterAdf) {
          return parameterAdf.value;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  } else {
    if (adfParamList) {
      const parameterAdf = adfParamList.find(
        (item) => item.paramGroup === paramGroup && item.paramName === paramName
      );
      if (parameterAdf) {
        return parameterAdf.value;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
};
