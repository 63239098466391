import MainLayout from "components/layouts/Main";
import { RouterConfig } from "router/AppRoute";
import StockOrder from "./pages/StockOrder";
import paths from "helper/pathRoutes";

const routes: RouterConfig[] = [
  {
    path: paths.stockOrder,
    page: StockOrder,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
