import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const professionalInvestorSelector = (state: RootState) =>
  state.professionalInvestor;

export const getProfessionalInvestorSelector = createSelector(
  professionalInvestorSelector,
  (state) => state.professionalInvestor
);

export const isShowConfirmModalSelector = createSelector(
  professionalInvestorSelector,
  (state) => state.isShowConfirmModal
);
