import { memo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { FormattedMessage } from "react-intl";
import { RootState } from "redux/store";
import { State as LanguageState } from "languages/redux";
import { ListType, WatchListReadOnlyProps } from "modules/categories/types";
import { ItemCustom, WatchListReadOnlyWrapper } from "./styles";

const languageSelector = (state: RootState) => state.language;

const languageTypeSelector = createSelector(
  languageSelector,
  (languageState: LanguageState) => languageState.lang
);

function WatchListReadOnly({
  listCode,
  selectedCode,
  isOpen,
  twoCol,
  onChangeList,
  useCodeAsKey,
}: WatchListReadOnlyProps) {
  const languageType = useSelector(languageTypeSelector);

  if (!isOpen) return null;

  const getNameByLanguage = (item: ListType) => {
    if (languageType === "vi") {
      return item.nameVi;
    }
    return item.nameEn;
  };

  return (
    <WatchListReadOnlyWrapper
      twoCol={twoCol}
      className="menu"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="custom">
        {listCode.map((item: ListType) => (
          <ItemCustom
            isActive={(useCodeAsKey ? item.code : item.name) === selectedCode}
            key={item.name}
            onClick={onChangeList(
              useCodeAsKey && item.code ? item.code : item.name
            )}
          >
            <div className="name-item">
              {useCodeAsKey ? (
                getNameByLanguage(item)
              ) : (
                <FormattedMessage id={item.name} />
              )}
            </div>
          </ItemCustom>
        ))}
      </div>
    </WatchListReadOnlyWrapper>
  );
}

export default memo(WatchListReadOnly);
