import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const listDepositSelector = (state: RootState) => state.depositList;

export const getListDeposit = createSelector(
  listDepositSelector,
  (state) => state.listDeposit
);
export const loadingSearchDataSelector = createSelector(
  listDepositSelector,
  (state) => state.loading
);
