/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Wrap, WrapTypeTransfer } from "./styles";
import { OptionSelectType } from "components/commons/Select";
import {
  getDataDeposit,
  getListTransferBank,
  ITransferLocalFilter,
  searchTransferLocalRequest,
  updateTransferLocalFilter,
  updateTypeTranfer,
} from "modules/transfer-internal/redux";
import {
  transferLocalLoadingSelector,
  transferLocalFilterSelector,
  getTypeTransferSelector,
} from "modules/transfer-internal/selectors";
import { useEffect, useMemo } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { statusTypeTransfer } from "modules/transfer-internal/constants";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import moment from "moment";
import storage from "helper/storage";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";

const statusOptionsIntl = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.status.all",
    }),
  },
  {
    value: "0",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.status.status0",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.status.status1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.status.status2",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.status.status3",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.status.status9",
    }),
  },
];

const Filter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const typeTranfer = useSelector(getTypeTransferSelector);
  // selector
  const { fromSubAccoNo, toSubAccoNo, status, m_fromDate, m_toDate } =
    useSelector(transferLocalFilterSelector);

  const loading = useSelector(transferLocalLoadingSelector);

  const statusOptions = useMemo(() => statusOptionsIntl(intl), [intl]);
  const typeTransfeOptions = useMemo(() => statusTypeTransfer(intl), [intl]);

  const changeToSubAccNoHandler = (
    e: string,
    key: keyof ITransferLocalFilter
  ) => {
    dispatch(updateTransferLocalFilter({ key, value: e ?? "" }));
  };

  const changeHandler = (
    e: OptionSelectType | null,
    key: keyof ITransferLocalFilter
  ) => {
    dispatch(updateTransferLocalFilter({ key, value: e?.value }));
  };

  // const filterBank = useSelector(transferLocalBankFilterSelector);

  const handleSearch = () => {
    if (typeTranfer === "1") {
      const params = {
        fromSubAccNo: fromSubAccoNo ?? "",
        toSubAccoNo: toSubAccoNo ?? "",
        status: status ? +status : -1,
        m_fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        m_toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        page: 0,
        rowPerPage: 30,
      };
      dispatch(searchTransferLocalRequest(params));

      const resLogData = {
        eventGroup: GroupEvent.moneyTransfer,
        event: RealtimeConst.getDataMoneyTransferHistory,
        data: params.toString(),
      };

      storage.commonPushLogFirebase(resLogData);
    } else if (typeTranfer === "2") {
      const params = {
        tradeType: Number(2),
        subAccoNoFrom: "",
        status: status ? +status : -1,
        fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        vsdStatus: -1,
        page: 0,
        rowPerPage: 30,
      };

      dispatch(getDataDeposit(params));

      const resLogData = {
        eventGroup: GroupEvent.moneyTransfer,
        event: RealtimeConst.getDataMoneyTransferHistory,
        data: params.toString(),
      };

      storage.commonPushLogFirebase(resLogData);
    } else if (typeTranfer === "3") {
      const params = {
        tradeType: Number(1),
        subAccoNoFrom: "",
        status: status ? +status : -1,
        fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        vsdStatus: -1,
        page: 0,
        rowPerPage: 30,
      };

      dispatch(getDataDeposit(params));

      const resLogData = {
        eventGroup: GroupEvent.moneyTransfer,
        event: RealtimeConst.getDataMoneyTransferHistory,
        data: params.toString(),
      };

      storage.commonPushLogFirebase(resLogData);
    } else {
      const params = {
        subAccoNoFrom: fromSubAccoNo ?? "",
        toSubAccoNo: toSubAccoNo ?? "",
        status: status ? +status : -1,
        page: 0,
        size: 30,
        fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        toDate: Number(moment(m_toDate).format("YYYYMMDD")),
      };

      dispatch(getListTransferBank(params));

      const resLogData = {
        eventGroup: GroupEvent.moneyTransfer,
        event: RealtimeConst.getDataMoneyTransferHistory,
        data: params.toString(),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [typeTranfer]);

  const valueTypeTransfer = useMemo(() => {
    return typeTransfeOptions.filter((e) => e.value === typeTranfer);
  }, [typeTranfer]);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BANK_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return [
      {
        value: "",
        label: intl.formatMessage({
          id: "transferInternal.historyTransfer.filter.valueAll",
        }),
      },
      ...list,
    ];
  }, [listAcc]);

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <WrapTypeTransfer className="mr-4">
          <p>
            <FormattedMessage
              id={
                "transferInternal.historyTransfer.filter.typeMoneyTransfer.label"
              }
            />
          </p>
          <Select
            className="flex-fill"
            value={valueTypeTransfer}
            options={typeTransfeOptions}
            onChange={(e: any) =>
              dispatch(updateTypeTranfer({ type: e.value }))
            }
          />
        </WrapTypeTransfer>
        <FilterItem
          filterName={intl.formatMessage({
            id: "transferInternal.historyTransfer.filter.fromDate.label",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={m_fromDate}
            onChange={(e: any) =>
              dispatch(
                updateTransferLocalFilter({
                  key: "m_fromDate",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "transferInternal.historyTransfer.filter.toDate.label",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={m_toDate}
            onChange={(e: any) =>
              dispatch(
                updateTransferLocalFilter({
                  key: "m_toDate",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>
        {(typeTranfer === "0" || typeTranfer === "1") && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "transferInternal.historyTransfer.filter.fromSubAccoNo.label",
            })}
            className="mr-4"
          >
            <Select
              defaultValue={accountOptions[0]}
              options={accountOptions}
              onChange={(e: any) => {
                changeToSubAccNoHandler(e?.value, "fromSubAccoNo");
              }}
            />
          </FilterItem>
        )}

        {(typeTranfer === "0" || typeTranfer === "1") && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "transferInternal.historyTransfer.filter.status.label",
            })}
            className="mr-4"
          >
            <Select
              className="flex-fill"
              defaultValue={statusOptions[0]}
              options={statusOptions}
              onChange={(e) => changeHandler(e, "status")}
            />
          </FilterItem>
        )}

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={() => handleSearch()}
        >
          {intl.formatMessage({
            id: "transferInternal.historyTransfer.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
};

export default Filter;
