import { Button, Input, Select, DatePicker } from "components/commons";
import { OptionSelectType } from "components/commons/Select";
import {
  updateItemEdit,
  getCustomer2Request,
  findCbotBondPriceEstRequest,
  getBondListRequest,
  inquiryBuyingPowerRequest,
  getBondAvaiSellRequest,
  findCbotBondInfoDetailRequest,
  calculateBondFeeRequest,
  calculateTaxAmtRequest,
  placeBondOrderRequest,
  updateFilter,
  getBondOrderRequest,
  updateButtonActive,
} from "modules/bid-ask-bond-order/redux";
import {
  IPlaceBondOrderParams,
  IGetBondOrderParams,
} from "core/http/apis/bid-ask-bond-order/types";
import { accountInfoSelector } from "modules/auth/selectors";
import {
  listBondSelector,
  bidAskBondOrderEditSelector,
  listCbotBondPriceEstSelector,
  statusPlaceBondOrderSelector,
  activeButtonSelector,
} from "modules/bid-ask-bond-order/selectors";
import { tradeDateSelector } from "modules/auth/selectors";
import moment from "moment";

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailWrapper,
  DetailRowPaddingTB,
  Devided,
  DetailRowPaddingTBOption,
  BuySellButtonRow,
  BuyButton,
  ButtonItem,
  LabelBuySell,
  SellButton,
  DetailColFlexEnd,
  DetailRowPaddingSelect,
  DetailInputWrapperNoMargin,
} from "./styles";
import storage from "helper/storage";

import {
  GetAccountType,
  EntrustBusinessCode,
  regPrice,
  regVolumn,
} from "helper/consts";
import { GetAccoList } from "helper/sessionData";

import InputPrice from "components/commons/InputPrice";
import InputSymbol from "modules/bid-ask-bond/pages/BidAskBond/components/BidAskBondContent/RequestFilters/InputSymbol";
import InputVolume from "components/commons/InputVolume";
import { convertNumberToDateFormat } from "helper/utils";

import numeral from "numeral";
import { toast } from "components/commons/Toast";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { updateModalOtpRequest } from "modules/advance/redux";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { Row, Col } from "react-bootstrap";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { selectDataCreateUser } from "modules/auth/selectorsModalRegister";
const payerOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "bidAskBondOrder.create.payer.sell",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "bidAskBondOrder.create.payer.buy",
    }),
  },
];
function RequestDetail() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { currentUser } = useSelector(accountInfoSelector);
  const bondList = useSelector(listBondSelector);
  const listCbotBondPriceEst = useSelector(listCbotBondPriceEstSelector);
  const itemEdit = useSelector(bidAskBondOrderEditSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const statusPlaceBondOrder = useSelector(statusPlaceBondOrderSelector);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const isVisible = useSelector(isShowOtpModalSelector);
  const listAcc = GetAccoList(GetAccountType.BOND, EntrustBusinessCode.All);
  const themeType = useSelector(themeTypeSelector);
  const listAccFilter = listAcc.filter((i) => {
    return i.subAccoNo.includes(storage.getMasterAccount());
  });
  const activeButton = useSelector(activeButtonSelector);
  const dataCreate = useSelector(selectDataCreateUser);

  const disabled_custType = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "COMPANY_CODE"
      )
    : {};
  const clickBuyButtonHandler = () => {
    dispatch(updateButtonActive("2"));

    if (itemEdit) {
      let check = listCbotBondPriceEst.find(
        (i) => i.bondCd === itemEdit.secCdBond
      );
      if (check) {
        let amount = itemEdit && itemEdit.amount ? itemEdit.amount : 0;
        let purchaseAbility = itemEdit ? itemEdit.purchaseAbility : 0;

        if (amount > 0 && purchaseAbility) {
          let value = purchaseAbility / amount;
          dispatch(
            updateItemEdit({
              maxPrice: value,
            })
          );
        } else if (amount === 0) {
          dispatch(
            updateItemEdit({
              maxPrice: 0,
            })
          );
        }
      } else {
        let amount = itemEdit && itemEdit.amount ? itemEdit.amount : 0;
        let purchaseAbility = itemEdit ? itemEdit.purchaseAbility : 0;

        if (amount > 0 && purchaseAbility) {
          let value = purchaseAbility / amount;
          dispatch(
            updateItemEdit({
              maxPrice: value,
            })
          );
        } else if (amount === 0) {
          dispatch(
            updateItemEdit({
              maxPrice: 0,
            })
          );
        }
        dispatch(
          updateItemEdit({
            secCdBond: "",
            // maxPrice: 0,
            referencePrice: 0,
            // contraAccount: "",
            // contraAccountName: "",
            // amount: 0,
            // qty: 0,
            // feePayer: 1,
            // taxPayer: 1,
            // expiratedDate: new Date(),

            // orderPrice: 0,
            issuerBond: "",
            price: 0,
            releaseDate: 0,
            coupon: 0,
            expiratedDate2: 0,
            period: 0,
            description: "",
            // fee: 0,
            // tax: 0,
          })
        );
      }
    }
    dispatch(
      findCbotBondPriceEstRequest({
        tradeDate: tradeDate ?? "",
        bondCd: null,
      })
    );
  };

  const clickSellButtonHandler = () => {
    dispatch(updateButtonActive("1"));
    if (itemEdit) {
      let check = bondList.find((i) => i.bondCd === itemEdit.secCdBond);
      if (check) {
        dispatch(
          getBondAvaiSellRequest({
            bondCd: itemEdit.secCdBond ?? "",
            custCd: 0,
            custNo: currentUser.userId ? currentUser.userId : "",
            type: 1,
          })
        );
      } else {
        dispatch(
          updateItemEdit({
            secCdBond: "",
            maxPrice: 0,
            referencePrice: 0,
            // contraAccount: "",
            // contraAccountName: "",
            // amount: 0,
            // qty: 0,
            // feePayer: 1,
            // taxPayer: 1,
            // expiratedDate: new Date(),

            // orderPrice: 0,
            issuerBond: "",
            price: 0,
            releaseDate: 0,
            coupon: 0,
            expiratedDate2: 0,
            period: 0,
            description: "",
            // fee: 0,
            // tax: 0,
          })
        );
      }
    }

    dispatch(
      getBondListRequest({
        subAccoNo:
          listAccFilter && listAccFilter[0] ? listAccFilter[0].subAccoNo : "",
      })
    );
  };
  const payerOptions = useMemo(() => payerOption(intl), [intl]);
  const changeInputHandler = (value: number) => {
    if (itemEdit !== undefined && itemEdit.qty !== undefined) {
      dispatch(updateItemEdit({ orderPrice: value * itemEdit.qty ?? 0 }));
    }

    let amount = value ? value : 0;
    let purchaseAbility = itemEdit ? itemEdit.purchaseAbility : 0;

    if (amount > 0 && purchaseAbility && activeButton === "2") {
      let value = purchaseAbility / amount;
      dispatch(
        updateItemEdit({
          maxPrice: value,
        })
      );
    } else if (amount === 0 && purchaseAbility && activeButton === "2") {
      dispatch(
        updateItemEdit({
          maxPrice: 0,
        })
      );
    }

    dispatch(updateItemEdit({ amount: value ?? 0 }));
  };
  const changeInputHandlerVolume = (value: number) => {
    if (itemEdit !== undefined && itemEdit.amount !== undefined) {
      dispatch(updateItemEdit({ orderPrice: value * itemEdit.amount ?? 0 }));
    }

    dispatch(updateItemEdit({ qty: value ?? 0 }));
  };

  useEffect(() => {
    dispatch(updateButtonActive("2"));
    dispatch(
      findCbotBondPriceEstRequest({
        tradeDate: tradeDate ?? "",
        bondCd: null,
      })
    );
    dispatch(
      getBondListRequest({
        subAccoNo:
          listAccFilter && listAccFilter[0] ? listAccFilter[0].subAccoNo : "",
      })
    );
    dispatch(
      inquiryBuyingPowerRequest({
        subAccoNo:
          currentUser && currentUser.userId ? currentUser.userId + ".60" : "",
        subAccoCd: null,
        secCd: null,
      })
    );
    dispatch(
      updateItemEdit({
        amount: 0,
        qty: 0,
        feePayer: 1,
        taxPayer: 1,
        expiratedDate: new Date(),
        maxPrice: 0,
        orderPrice: 0,
        referencePrice: 0,
        fee: 0,
        tax: 0,
        issuerBond: "",
        price: 0,
        releaseDate: 0,
        coupon: 0,
        expiratedDate2: 0,
        period: 0,
        description: "",
        secCdBond: "",
        contraAccount: "",
        contraAccountName: "",
      })
    );
  }, []);

  useEffect(() => {
    const payerFee = itemEdit && itemEdit.feePayer ? itemEdit.feePayer : 1;
    const contraAccount =
      itemEdit && itemEdit.contraAccount ? itemEdit.contraAccount : "";
    const qty = itemEdit && itemEdit.qty ? itemEdit.qty : 0;
    const price = itemEdit && itemEdit.amount ? itemEdit.amount : 0;
    const bondCd = itemEdit && itemEdit.secCdBond ? itemEdit.secCdBond : "";

    if (payerFee === 2 && qty && price) {
      dispatch(
        calculateBondFeeRequest({
          bondCd: bondCd,
          feeType: 1,
          parAmt: itemEdit && itemEdit.orderPrice ? itemEdit.orderPrice : 0,
          custNo: null,
          tradeType: parseInt(activeButton),
          feePayer: payerFee,
          subAccoNo:
            parseInt(activeButton) === 2
              ? currentUser.userId + ".60"
              : contraAccount + ".60",
        })
      );

      dispatch(
        calculateTaxAmtRequest({
          type: 2,
          tradeType: parseInt(activeButton),
          bondCd: bondCd,
          qty: qty,
          price: price,
          custCd: null,
          custNo: currentUser.userId ? currentUser.userId : "",
          fromCustCd: null,
          fromCustNo:
            itemEdit && itemEdit.contraAccount ? itemEdit.contraAccount : "",
        })
      );
    } else if (payerFee === 1 && qty && price) {
      dispatch(
        calculateBondFeeRequest({
          bondCd: bondCd,
          feeType: 1,
          parAmt: itemEdit && itemEdit.orderPrice ? itemEdit.orderPrice : 0,
          custNo: null,
          tradeType: parseInt(activeButton),
          feePayer: payerFee,
          subAccoNo:
            parseInt(activeButton) === 1
              ? currentUser.userId + ".60"
              : contraAccount + ".60",
        })
      );
      if (qty && price && bondCd.length > 0) {
        dispatch(
          calculateTaxAmtRequest({
            type: 2,
            tradeType: parseInt(activeButton),
            bondCd: bondCd,
            qty: qty,
            price: price,
            custCd: null,
            custNo: currentUser.userId ? currentUser.userId : "",
            fromCustCd: null,
            fromCustNo:
              itemEdit && itemEdit.contraAccount ? itemEdit.contraAccount : "",
          })
        );
      }
    } else if (qty === 0 || price === 0) {
      dispatch(
        updateItemEdit({
          tax: 0,
          fee: 0,
        })
      );
    } else {
      dispatch(
        updateItemEdit({
          fee: 0,
        })
      );
    }
  }, [
    itemEdit?.secCdBond,
    activeButton,
    itemEdit?.feePayer,
    itemEdit?.taxPayer,
    itemEdit?.amount,
    itemEdit?.qty,
    itemEdit?.contraAccount,
  ]);

  const changeContraAccountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value ?? "";

    if (value.length <= 10) {
      dispatch(updateItemEdit({ contraAccount: value ?? "" }));
    } else {
      return toast("bidAskBondOrder.message.maxLengthCheck", "error");
    }
  };
  const blurContraAccountHandler = (e: any) => {
    let pattern = `${disabled_custType?.value}C000000`;
    let contraAccountValue =
      itemEdit && itemEdit.contraAccount ? itemEdit.contraAccount : "";
    if (contraAccountValue) {
      dispatch(
        updateItemEdit({
          contraAccount: pattern
            .slice(0, pattern.length - contraAccountValue.length)
            .concat(contraAccountValue),
        })
      );

      if (
        pattern
          .slice(0, pattern.length - contraAccountValue.length)
          .concat(contraAccountValue) === currentUser.userId
      ) {
        dispatch(
          updateItemEdit({
            contraAccountName: "",
          })
        );
        toast("bidAskBondOrder.message.checkErr11", "error");
      } else {
        dispatch(
          getCustomer2Request({
            custCd: 0,
            custNo: pattern
              .slice(0, pattern.length - contraAccountValue.length)
              .concat(contraAccountValue),
          })
        );
      }
    } else {
      dispatch(
        updateItemEdit({
          contraAccountName: "",
        })
      );
    }
  };
  const handleKeyDownContraAccount = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.stopPropagation();
      let pattern = `${disabled_custType?.value}C000000`;
      let contraAccountValue =
        itemEdit && itemEdit.contraAccount ? itemEdit.contraAccount : "";
      if (contraAccountValue) {
        dispatch(
          updateItemEdit({
            contraAccount: pattern
              .slice(0, pattern.length - contraAccountValue.length)
              .concat(contraAccountValue),
          })
        );

        if (
          pattern
            .slice(0, pattern.length - contraAccountValue.length)
            .concat(contraAccountValue) === currentUser.userId
        ) {
          dispatch(
            updateItemEdit({
              contraAccountName: "",
            })
          );
          toast("bidAskBondOrder.message.checkErr11", "error");
        } else {
          dispatch(
            getCustomer2Request({
              custCd: 0,
              custNo: pattern
                .slice(0, pattern.length - contraAccountValue.length)
                .concat(contraAccountValue),
            })
          );
        }
      } else {
        dispatch(
          updateItemEdit({
            contraAccountName: "",
          })
        );
      }
    }
  };
  const changeFeePayerHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemEdit({ feePayer: item && item.value ? +item.value : 1 })
    );
  };
  const changeTaxPayerHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemEdit({ taxPayer: item && item.value ? +item.value : 1 })
    );
  };
  const clickResetHandler = () => {
    dispatch(
      updateItemEdit({
        amount: 0,
        qty: 0,
        feePayer: 1,
        taxPayer: 1,
        expiratedDate: new Date(),
        maxPrice: 0,
        referencePrice: 0,
        orderPrice: 0,
        fee: 0,
        tax: 0,
        issuerBond: "",
        price: 0,
        releaseDate: 0,
        coupon: 0,
        expiratedDate2: 0,
        period: 0,
        description: "",
        secCdBond: "",
        contraAccount: "",
        contraAccountName: "",
      })
    );

    // dispatch(updateButtonActive("2"));
    dispatch(
      findCbotBondPriceEstRequest({
        tradeDate: tradeDate ?? "",
        bondCd: null,
      })
    );
    dispatch(
      getBondListRequest({
        subAccoNo:
          listAccFilter && listAccFilter[0] ? listAccFilter[0].subAccoNo : "",
      })
    );
  };
  const clickAcceptHandler = () => {
    if (itemEdit && Object.keys(itemEdit).length > 0) {
      if (
        itemEdit.contraAccount === null ||
        itemEdit.contraAccount === undefined
      ) {
        return toast("bidAskBondOrder.message.checkErr1", "error");
      } else if (itemEdit.contraAccount === "") {
        return toast("bidAskBondOrder.message.checkErr2", "error");
      } else if (itemEdit.contraAccount === currentUser.userId) {
        return toast("bidAskBondOrder.message.checkErr11", "error");
      } else if (
        itemEdit.secCdBond === null ||
        itemEdit.secCdBond === undefined
      ) {
        return toast("bidAskBondOrder.message.checkErr3", "error");
      } else if (itemEdit.secCdBond === "") {
        return toast("bidAskBondOrder.message.checkErr4", "error");
      } else if (itemEdit.amount === null || itemEdit.amount === undefined) {
        return toast("bidAskBondOrder.message.checkErr5", "error");
      } else if (itemEdit.amount === 0) {
        return toast("bidAskBondOrder.message.checkErr6", "error");
      } else if (itemEdit.qty === null || itemEdit.qty === undefined) {
        return toast("bidAskBondOrder.message.checkErr7", "error");
      } else if (itemEdit.qty === 0) {
        return toast("bidAskBondOrder.message.checkErr8", "error");
      } else if (
        itemEdit.maxPrice !== undefined &&
        itemEdit.qty > itemEdit.maxPrice
      ) {
        return toast("bidAskBondOrder.message.checkErr10", "error");
      } else if (
        (itemEdit.expiratedDate && itemEdit.expiratedDate === null) ||
        (itemEdit.expiratedDate && itemEdit.expiratedDate === undefined)
      ) {
        return toast("bidAskBondOrder.message.checkErr9", "error");
      }
      setVisibleModalConfirm(true);
    }
  };

  const setConfirm = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
  };
  const handleCloseOTPModal2 = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };

  const doSubmitAdvance2 = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    const submitData: IPlaceBondOrderParams = {
      custd: "",
      custNo: currentUser.userId ? currentUser.userId : "",
      fromCustCd: "",
      bondCd: itemEdit && itemEdit.secCdBond ? itemEdit.secCdBond : "",
      qty: itemEdit && itemEdit.qty ? itemEdit.qty : 0,
      price: itemEdit && itemEdit.amount ? itemEdit.amount : 0,
      type: 2,
      expDate: moment(
        itemEdit && itemEdit.expiratedDate ? itemEdit.expiratedDate : new Date()
      ).format("YYYYMMDD"),
      tradeType: parseInt(activeButton),
      fromCustNo:
        itemEdit && itemEdit.contraAccount ? itemEdit.contraAccount : "",

      feePayer: itemEdit && itemEdit.feePayer ? itemEdit.feePayer : 1,
      taxPayer: itemEdit && itemEdit.taxPayer ? itemEdit.taxPayer : 1,

      otpCode: response?.otp ?? "",
      otpType: response?.otpTypeSubmit!,
    };

    dispatch(placeBondOrderRequest(submitData));
  };
  useEffect(() => {
    if (statusPlaceBondOrder) {
      toast("bidAskBondOrder.message.success", "success");

      if (activeButton === "1") {
        dispatch(
          getBondAvaiSellRequest({
            bondCd: itemEdit && itemEdit.secCdBond ? itemEdit.secCdBond : "",
            custCd: 0,
            custNo: currentUser.userId ? currentUser.userId : "",
            type: 1,
          })
        );
      } else if (activeButton === "2") {
        dispatch(
          inquiryBuyingPowerRequest({
            subAccoNo:
              currentUser && currentUser.userId
                ? currentUser.userId + ".60"
                : "",
            subAccoCd: null,
            secCd: null,
          })
        );
      }

      const submitData: IGetBondOrderParams = {
        status: -1,

        bondCd: "",
        custCd: 0,
        custNo: null,
        fromDate: null,
        toDate: null,
        type: 2,
        tradeType: null,
        getDataType: 1,
      };
      dispatch(getBondOrderRequest(submitData));
      dispatch(
        updateFilter({
          key: "secCdBond",
          value: "",
        })
      );
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
      setVisibleModalConfirm(false);
      clickResetHandler();
    }
  }, [statusPlaceBondOrder]);

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.account" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{currentUser.userId}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <BuySellButtonRow>
          <ButtonItem className="mr-1" onClick={clickBuyButtonHandler}>
            <BuyButton
              isActive={activeButton === "2" ? true : false}
            ></BuyButton>
            <LabelBuySell isActive={activeButton === "2" ? true : false}>
              {intl.formatMessage({
                id: "bidAskBondOrder.create.buy",
              })}
            </LabelBuySell>
          </ButtonItem>
          <ButtonItem onClick={clickSellButtonHandler}>
            <SellButton
              isActive={activeButton === "1" ? true : false}
            ></SellButton>
            <LabelBuySell isActive={activeButton === "1" ? true : false}>
              {intl.formatMessage({
                id: "bidAskBondOrder.create.sell",
              })}
            </LabelBuySell>
          </ButtonItem>
        </BuySellButtonRow>

        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.contraAccount" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <Input
                    value={itemEdit?.contraAccount}
                    onChange={changeContraAccountHandler}
                    onKeyUp={handleKeyDownContraAccount}
                    onBlur={blurContraAccountHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.contraAccountName" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{(itemEdit && itemEdit.contraAccountName) ?? ""}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1", marginTop: "-8px" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.bondCd" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white input-sec">
                  {activeButton === "1" ? (
                    <InputSymbol
                      list={bondList}
                      showPlaceholder={false}
                      value={itemEdit?.secCdBond}
                      onChange={(e) => {
                        if (e) {
                          dispatch(
                            getBondAvaiSellRequest({
                              bondCd: e ? e : "",
                              custCd: 0,
                              custNo: currentUser.userId
                                ? currentUser.userId
                                : "",
                              type: 1,
                            })
                          );
                          dispatch(
                            findCbotBondInfoDetailRequest({
                              bondCd: e ? e : "",
                            })
                          );
                          const refPrice =
                            listCbotBondPriceEst &&
                            listCbotBondPriceEst.find((i) => i.bondCd === e) &&
                            listCbotBondPriceEst.find((i) => i.bondCd === e)
                              ?.priceEst
                              ? listCbotBondPriceEst.find((i) => i.bondCd === e)
                                  ?.priceEst
                              : 0;
                          dispatch(
                            updateItemEdit({
                              secCdBond: e ? e : "",
                              referencePrice: refPrice ?? 0,
                            })
                          );
                        } else {
                          dispatch(
                            updateItemEdit({
                              secCdBond: "",
                              referencePrice: 0,
                              maxPrice: 0,
                              issuerBond: "",
                              price: 0,
                              releaseDate: 0,
                              expiratedDate2: 0,
                              coupon: 0,
                              period: 0,
                              description: "",
                            })
                          );
                        }
                      }}
                    />
                  ) : (
                    <InputSymbol
                      list={listCbotBondPriceEst}
                      showPlaceholder={false}
                      value={itemEdit?.secCdBond}
                      onChange={(e) => {
                        if (e) {
                          let amount =
                            itemEdit && itemEdit.amount ? itemEdit.amount : 0;
                          let purchaseAbility = itemEdit
                            ? itemEdit.purchaseAbility
                            : 0;

                          if (amount > 0 && purchaseAbility) {
                            let value = purchaseAbility / amount;
                            dispatch(
                              updateItemEdit({
                                maxPrice: value,
                              })
                            );
                          } else if (amount === 0) {
                            dispatch(
                              updateItemEdit({
                                maxPrice: 0,
                              })
                            );
                          }

                          dispatch(
                            findCbotBondInfoDetailRequest({
                              bondCd: e ? e : "",
                            })
                          );
                          const refPrice =
                            listCbotBondPriceEst &&
                            listCbotBondPriceEst.find((i) => i.bondCd === e) &&
                            listCbotBondPriceEst.find((i) => i.bondCd === e)
                              ?.priceEst
                              ? listCbotBondPriceEst.find((i) => i.bondCd === e)
                                  ?.priceEst
                              : 0;
                          dispatch(
                            updateItemEdit({
                              secCdBond: e ? e : "",
                              referencePrice: refPrice ?? 0,
                            })
                          );
                        } else {
                          let amount =
                            itemEdit && itemEdit.amount ? itemEdit.amount : 0;
                          let purchaseAbility = itemEdit
                            ? itemEdit.purchaseAbility
                            : 0;

                          if (amount > 0 && purchaseAbility) {
                            let value = purchaseAbility / amount;
                            dispatch(
                              updateItemEdit({
                                maxPrice: value,
                              })
                            );
                          } else if (amount === 0) {
                            dispatch(
                              updateItemEdit({
                                maxPrice: 0,
                              })
                            );
                          }
                          dispatch(
                            updateItemEdit({
                              secCdBond: "",
                              referencePrice: 0,

                              issuerBond: "",
                              price: 0,
                              releaseDate: 0,
                              expiratedDate2: 0,
                              coupon: 0,
                              period: 0,
                              description: "",
                            })
                          );
                        }
                      }}
                    />
                  )}
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.amount" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <InputPrice
                    value={
                      itemEdit && itemEdit.amount
                        ? itemEdit.amount.toString()
                        : "0"
                    }
                    onChange={(num) => changeInputHandler(+num)}
                    onClickButton={(num) => changeInputHandler(+num)}
                    placeholder={intl.formatMessage({
                      id: "formPlaceholder.inputPrice",
                    })}
                    partern={regPrice}
                    decimal
                    min={0}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.qty" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <InputVolume
                    value={
                      itemEdit && itemEdit.qty ? itemEdit.qty.toString() : "0"
                    }
                    onChange={(num) => changeInputHandlerVolume(+num)}
                    onClickButton={(num) => changeInputHandlerVolume(+num)}
                    placeholder={intl.formatMessage({
                      id: "formPlaceholder.inputVolumne",
                    })}
                    partern={regVolumn}
                    min={0}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingTBOption>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1", marginTop: "0px" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.feePayer" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={payerOptions[0]}
                    options={payerOptions}
                    value={payerOptions.find(
                      (item) => +item.value === itemEdit?.feePayer
                    )}
                    onChange={changeFeePayerHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTBOption>
        <DetailRowPaddingTBOption>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.taxPayer" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={payerOptions[0]}
                    options={payerOptions}
                    value={payerOptions.find(
                      (item) => +item.value === itemEdit?.taxPayer
                    )}
                    onChange={changeTaxPayerHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTBOption>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100 mb-3"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.expiratedDate" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <DatePicker
                    selected={itemEdit?.expiratedDate}
                    onChange={(e: any) => {
                      dispatch(
                        updateItemEdit({
                          expiratedDate: e ?? new Date(),
                        })
                      );
                    }}
                    popperPlacement="bottom-start"
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <Devided />

        <Row className="mt-3 pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.maxPrice" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.maxPrice : 0).format("0,0")}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.purchaseAbility" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.purchaseAbility : 0).format(
                      "0,0"
                    )}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className=" pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.referencePrice" />
                </DetailLabel>
              </Col>

              <Col xs={12} lg={7} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.referencePrice : 0).format(
                      "0,0"
                    )}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.fee" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>{numeral(itemEdit ? itemEdit.fee : 0).format("0,0")}</b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className=" pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.orderPrice" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.orderPrice : 0).format("0,0")}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.tax" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>{numeral(itemEdit ? itemEdit.tax : 0).format("0,0")}</b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4 pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={8} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.issuerBond" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={4} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>{itemEdit ? itemEdit.issuerBond : ""}</b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={8} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.price" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={4} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>{numeral(itemEdit ? itemEdit.price : 0).format("0,0")}</b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className=" pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={8} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.releaseDate" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={4} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {" "}
                    {convertNumberToDateFormat({
                      date: itemEdit ? itemEdit.releaseDate : 0,
                    })}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={8} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.coupon" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={4} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.coupon : 0).format("0,0")}
                    %/Năm
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-2 pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={8} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.expiratedDate2" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={4} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {" "}
                    {convertNumberToDateFormat({
                      date: itemEdit ? itemEdit.expiratedDate2 : 0,
                    })}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={8} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskBondOrder.create.period" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={4} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>{itemEdit ? itemEdit.period : 0} tháng</b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className=" d-flex align-content-center pl-3 pr-4">
            <DetailLabel>
              <FormattedMessage id="bidAskBondOrder.create.description" />
            </DetailLabel>
            <DetailInputWrapper>
              <b>{itemEdit ? itemEdit.description : ""}</b>
            </DetailInputWrapper>
          </Col>
        </Row>
      </DetailCol>
      <DetailColFlexEnd>
        <Row className=" d-flex align-content-center w-100 justify-content-center mx-auto">
          <Col sm={12} lg={6} className="w-100 p-0 px-1">
            <Button
              color="accept"
              className="btn-place-order button-filter w-100 m-1 mx-auto"
              onClick={clickResetHandler}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "bidAskBondOrder.create.reset",
              })}
            </Button>
          </Col>
          <Col sm={12} lg={6} className="w-100 p-0 px-0 pr-2">
            <Button
              color="accept"
              className={
                activeButton === "2"
                  ? "btn-place-order btn-place-order-buy button-filter m-1 w-100"
                  : "btn-place-order btn-place-order-sell button-filter m-1 w-100"
              }
              onClick={clickAcceptHandler}
              fill={true}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {activeButton === "2"
                ? intl.formatMessage({
                    id: "bidAskBondOrder.create.buy",
                  })
                : intl.formatMessage({
                    id: "bidAskBondOrder.create.sell",
                  })}
            </Button>
          </Col>
        </Row>
      </DetailColFlexEnd>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "bidAskBondOrder.message.confirmContent",
        })}
      />
      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal2}
          getResult={doSubmitAdvance2}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </DetailWrapper>
  );
}

export default RequestDetail;
