import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import {
  getDataMarketIndex,
  getDataMarketIndexSuccess,
  getDataTickerDerStart,
  getDataTickerDerSuccess,
} from "modules/system/redux/headerCard";
import { findMarketIndex, getStreamPriceInfoFulls } from "core/grpc";
import {
  MarketIndexInfo,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";
import { subscribeTickers } from "core/wss";

function* getDataMarketIndexSaga(
  action: ReturnType<typeof getDataMarketIndex>
) {
  try {
    const res: MarketIndexInfo.AsObject[] = yield call(
      findMarketIndex,
      action.payload
    );
    yield put(getDataMarketIndexSuccess(res));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function* getDataTickerDerWorker(
  action: ReturnType<typeof getDataTickerDerStart>
) {
  try {
    subscribeTickers(action.payload);
    const response: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      action.payload,
      true
    );
    if (response[0]) {
      yield put(getDataTickerDerSuccess(response[0]));
    }
  } catch (e: any) {}
}

export default function* headerCardSaga() {
  yield all([
    takeLatest(getDataMarketIndex.type, getDataMarketIndexSaga),
    takeLatest(getDataTickerDerStart.type, getDataTickerDerWorker),
  ]);
}
