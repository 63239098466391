import styled from "styled-components/macro";

const AskBidContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const BuySellContainer = styled.div`
  flex: 1;
`;

const VolumeContainer = styled.div<{ isReverse?: boolean }>`
  position: sticky;
  top: 39px;
  background-color: ${({ theme }) => theme.background2};
  color: ${({ theme }) => theme.grey40};
  height: 32px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ isReverse }) =>
    isReverse ? `0 4px 4px 0` : `4px 0 0 4px`};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  ${({ isReverse }) => isReverse && `flex-direction: row-reverse;`}
  z-index: 1;

  .text {
    color: ${({ isReverse, theme }) =>
      isReverse ? theme.red50 : theme.green50};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
  }
`;

const RowContainer = styled.div<{ isReverse?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.background1};
  ${({ isReverse }) => isReverse && `flex-direction: row-reverse;`}
  height: 24px;

  .position {
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    border-radius: 4px;
    width: 0px;
    height: 24px;
    min-width: 0px;
    min-height: 24px;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .volume {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.textcolorWhite};
    margin: 2px 8px;
    text-align: ${({ isReverse }) => (isReverse ? "end" : "start")};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
`;

const PriceContainer = styled.div<{
  isReverse: boolean;
}>`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: ${({ isReverse }) =>
    isReverse ? "flex-start" : "flex-end"};
  align-items: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  .price-text {
    text-align: ${({ isReverse }) => (isReverse ? "start" : "end")};
  }
`;
const PriceText = styled.div<{ priceColor: string }>`
  color: ${({ priceColor, theme }) => theme[priceColor]};

  position: absolute;
  padding: 0px 4px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;

export const ProgressPrice = styled.div<{
  percent: number;
  isReverse: boolean;
}>`
  display: flex;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background-image: ${({ theme, percent, isReverse }) =>
    isReverse
      ? `linear-gradient(90deg, ${theme.red50} 0%, ${theme.red50} ${percent}%,  transparent  ${percent}%, transparent 100%)`
      : `linear-gradient(90deg, transparent 0%, transparent  ${
          100 - percent
        }%, ${theme.green50} ${100 - percent}%, ${theme.green50} 100%)`};
`;

export {
  AskBidContainer,
  BuySellContainer,
  VolumeContainer,
  RowContainer,
  PriceContainer,
  PriceText,
};
