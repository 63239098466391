import VgaiaHttp from "core/http/singleton/vgaia";
import {
  BankListResponse,
  CheckBrokerIdResponse,
  CheckCustodyCdResponse,
  CheckEmailResponse,
  CheckIdCodeResponse,
  CheckNumberPhoneResponse,
  verifyBankAccountRequest,
  verifyBankAccountResponse,
} from "./types";

export const getCheckNumberPhone = async (
  numberPhone: string
): Promise<CheckNumberPhoneResponse> => {
  const res = await VgaiaHttp.get<CheckNumberPhoneResponse>(`/accounts/phone`, {
    phone: numberPhone,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCheckEmail = async (
  email: string
): Promise<CheckEmailResponse> => {
  const res = await VgaiaHttp.get<CheckEmailResponse>(`/accounts/email`, {
    email,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCheckIdCode = async (
  idCode: string
): Promise<CheckIdCodeResponse> => {
  const res = await VgaiaHttp.get<CheckIdCodeResponse>(`/accounts/idcode`, {
    idCode,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCheckCustodycd = async (
  custodyCd: string
): Promise<CheckCustodyCdResponse> => {
  const res = await VgaiaHttp.get<CheckCustodyCdResponse>(
    `/accounts/custodycd`,
    {
      custodyCd,
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCheckBrokerId = async (
  brokerId: string
): Promise<CheckBrokerIdResponse> => {
  const res = await VgaiaHttp.get<CheckBrokerIdResponse>(`/accounts/broker`, {
    brokerId,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankList = async (): Promise<BankListResponse> => {
  const res = await VgaiaHttp.get<BankListResponse>(`/banks/list`);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postVerifyBankAccount = async (
  params: verifyBankAccountRequest
): Promise<verifyBankAccountResponse> => {
  const res = await VgaiaHttp.post<verifyBankAccountResponse>(
    `banks/verifyBankAccount`,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res?.data) throw new Error("Opps");
  return res.data;
};
