import { ORDER_HISTORY_TABS } from "modules/order-history/constants";
import { activeTabSelector } from "modules/order-history/selectors";
import { updateActiveTab } from "modules/order-history/redux";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";

const Tabs: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab
          isActive={activeTab === ORDER_HISTORY_TABS.ORDER_HISTORY}
          onClick={() =>
            dispatch(updateActiveTab(ORDER_HISTORY_TABS.ORDER_HISTORY))
          }
        >
          {intl.formatMessage({
            id: "orderHistory.tabs.orderHistoryTab",
          })}
        </Tab>
        <Tab
          isActive={activeTab === ORDER_HISTORY_TABS.ORDER_HISTORY_DER}
          onClick={() =>
            dispatch(updateActiveTab(ORDER_HISTORY_TABS.ORDER_HISTORY_DER))
          }
        >
          {intl.formatMessage({
            id: "orderHistory.tabs.orderHistoryDerTab",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
