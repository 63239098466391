import { toast } from "components/commons/Toast";

import {
  IFindMarginContractDebitWatchParams,
  IGetMrgDebitInfo2Params,
  IUpdateMrgContractPaymentParams,
  IFindListMarginAttrRequestParams,
  IGetMarginDebitExtendFeeInfoParams,
  IMarginDebitExtendDaysParams,
  IGetListDebitPayExParams,
} from "core/http/apis/contract-debit/types";
import {
  IFindMarginContractDebitWatchResponse,
  MarginDebitContractWatch,
  IGetMrgDebitInfo2Response,
  IUpdateMrgContractPaymentResponse,
  IFindListMarginAttrRequestResponse,
  IGetMarginDebitExtendFeeInfoResponse,
  IMarginDebitExtendDaysResponse,
  IGetListDebitPayExResponse,
  IGetListDebitPayEx2Response,
} from "domain/models/ContractDebit";
import {
  findMarginContractDebitWatch,
  getMrgDebitInfo2,
  updateMrgContractPayment,
  getListMarginAttr,
  getMarginDebitExtendFeeInfo,
  marginDebitExtendDays,
  getListDebitPayEx,
} from "core/http/apis/contract-debit";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  findMarginContractDebitWatchRequest,
  findMarginContractDebitWatchSuccess,
  findMarginContractDebitWatchFailure,
  getListDebitPayExRequest,
  getListDebitPayExSuccess,
  getListDebitPayExFailure,
  getListDebitPayEx2Request,
  getListDebitPayEx2Success,
  getListDebitPayEx2Failure,
  updateModalPaymentRequest,
  updateModalPaymentSuccess,
  updateModalConfirmRequest,
  updateModalConfirmSuccess,
  updateModalLoanExtendRequest,
  updateModalLoanExtendSuccess,
  getMrgDebitInfo2Request,
  getMrgDebitInfo2Success,
  getMrgDebitInfo2Failure,
  getMarginDebitExtendFeeInfoRequest,
  getMarginDebitExtendFeeInfoSuccess,
  getMarginDebitExtendFeeInfoFailure,
  findListMarginAttrRequest,
  findListMarginAttrSuccess,
  findListMarginAttrFailure,
  updateMrgContractPaymentRequest,
  updateMrgContractPaymentSuccess,
  updateMrgContractPaymentFailure,
  marginDebitExtendDaysRequest,
  marginDebitExtendDaysSuccess,
  marginDebitExtendDaysFailure,
  fetchMoreListDebitPayExRequest,
  fetchMoreListDebitPayExSuccess,
  updatePagePayMenHistory,
  fetchMoreExtendHistoryRequest,
  fetchMoreExtendHistorySuccess,
  fetchMoreDebitWatchRequest,
  fetchMoreDebitWatchSuccess,
  updatePageLoanTracking,
} from "./redux";

function* findMarginContractDebitWatchWorker(
  action: ReturnType<typeof findMarginContractDebitWatchRequest>
) {
  try {
    const params: IFindMarginContractDebitWatchParams = action.payload;
    const response: IFindMarginContractDebitWatchResponse = yield call(
      findMarginContractDebitWatch,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findMarginContractDebitWatchSuccess(response));
    } else {
      yield put(findMarginContractDebitWatchFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findMarginContractDebitWatchFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListDebitPayExWorker(
  action: ReturnType<typeof getListDebitPayExRequest>
) {
  try {
    const params: IGetListDebitPayExParams = action.payload;
    const response: IGetListDebitPayExResponse = yield call(
      getListDebitPayEx,
      params
    );

    if (response?.statusCode === 0) {
      yield put(
        getListDebitPayExSuccess({
          ...response,
          data: response.data.map((i) => {
            return {
              ...i,
              subAccoNo: params.subAccoNo ?? "",
            };
          }),
        })
      );
    } else {
      yield put(getListDebitPayExFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListDebitPayExFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListDebitPayEx2Worker(
  action: ReturnType<typeof getListDebitPayEx2Request>
) {
  try {
    const params: IGetListDebitPayExParams = action.payload;
    const response: IGetListDebitPayEx2Response = yield call(
      getListDebitPayEx,
      params
    );

    if (response?.statusCode === 0) {
      yield put(
        getListDebitPayEx2Success({
          ...response,
          data: response.data.map((i) => {
            return {
              ...i,
              subAccoNo: params.subAccoNo ?? "",
            };
          }),
        })
      );
    } else {
      yield put(getListDebitPayEx2Failure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListDebitPayExFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getMrgDebitInfo2Worker(
  action: ReturnType<typeof getMrgDebitInfo2Request>
) {
  try {
    const params: IGetMrgDebitInfo2Params = action.payload;
    const response: IGetMrgDebitInfo2Response = yield call(
      getMrgDebitInfo2,
      params
    );

    if (response?.statusCode === 0) {
      if (params.isAuto === true) {
        yield put(getMrgDebitInfo2Success({ response, check: true }));
      } else {
        yield put(getMrgDebitInfo2Success({ response, check: false }));
      }
    } else {
      yield put(getMrgDebitInfo2Failure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getMrgDebitInfo2Failure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getMarginDebitExtendFeeInfoWorker(
  action: ReturnType<typeof getMarginDebitExtendFeeInfoRequest>
) {
  try {
    const params: IGetMarginDebitExtendFeeInfoParams = action.payload;
    const response: IGetMarginDebitExtendFeeInfoResponse = yield call(
      getMarginDebitExtendFeeInfo,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getMarginDebitExtendFeeInfoSuccess(response));
    } else {
      yield put(getMarginDebitExtendFeeInfoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getMarginDebitExtendFeeInfoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findListMarginAttrWorker(
  action: ReturnType<typeof findListMarginAttrRequest>
) {
  try {
    const params: IFindListMarginAttrRequestParams = action.payload;
    const response: IFindListMarginAttrRequestResponse = yield call(
      getListMarginAttr,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findListMarginAttrSuccess(response));
    } else {
      yield put(findListMarginAttrFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findListMarginAttrFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalPaymentWorker(
  action: ReturnType<typeof updateModalPaymentRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
    }: {
      isVisible: boolean;
      itemDetail?: MarginDebitContractWatch;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateModalPaymentSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateModalPaymentSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalLoanExtendWorker(
  action: ReturnType<typeof updateModalLoanExtendRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
    }: {
      isVisible: boolean;
      itemDetail?: MarginDebitContractWatch;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateModalLoanExtendSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateModalLoanExtendSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateModalConfirmWorker(
  action: ReturnType<typeof updateModalConfirmRequest>
) {
  try {
    const {
      isVisible,
      amount,
      itemDetailRow,
    }: {
      isVisible: boolean;

      amount?: number;
      itemDetailRow?: MarginDebitContractWatch;
    } = action.payload;
    if (isVisible) {
      if (amount === null || amount === undefined) {
        return toast("contractDebit.message.amountCheck1", "error");
      } else if (amount === 0 || amount < 0) {
        return toast("contractDebit.message.amountCheck2", "error");
      }
    }

    if (itemDetailRow) {
      yield put(updateModalConfirmSuccess({ isVisible, itemDetailRow }));
    } else {
      yield put(updateModalConfirmSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateMrgContractPaymentWorker(
  action: ReturnType<typeof updateMrgContractPaymentRequest>
) {
  try {
    const params: IUpdateMrgContractPaymentParams = action.payload;
    const response: IUpdateMrgContractPaymentResponse = yield call(
      updateMrgContractPayment,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updateMrgContractPaymentSuccess(response));
    } else {
      yield put(updateMrgContractPaymentFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(updateMrgContractPaymentFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* marginDebitExtendDaysWorker(
  action: ReturnType<typeof marginDebitExtendDaysRequest>
) {
  try {
    const params: IMarginDebitExtendDaysParams = action.payload;
    const response: IMarginDebitExtendDaysResponse = yield call(
      marginDebitExtendDays,
      params
    );

    if (response?.statusCode === 0) {
      yield put(marginDebitExtendDaysSuccess(response));
    } else {
      yield put(marginDebitExtendDaysFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(marginDebitExtendDaysFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findMarginContractDebitWatchWatcher() {
  yield takeLatest(
    findMarginContractDebitWatchRequest.type,
    findMarginContractDebitWatchWorker
  );
}
function* getListDebitPayExWatcher() {
  yield takeLatest(getListDebitPayExRequest.type, getListDebitPayExWorker);
}
function* getListDebitPayEx2Watcher() {
  yield takeLatest(getListDebitPayEx2Request.type, getListDebitPayEx2Worker);
}
function* updateModalPaymentWatcher() {
  yield takeLatest(updateModalPaymentRequest.type, updateModalPaymentWorker);
}
function* updateModalLoanExtendWatcher() {
  yield takeLatest(
    updateModalLoanExtendRequest.type,
    updateModalLoanExtendWorker
  );
}
function* updateModalConfirmWatcher() {
  yield takeLatest(updateModalConfirmRequest.type, updateModalConfirmWorker);
}

function* getMrgDebitInfo2Watcher() {
  yield takeLatest(getMrgDebitInfo2Request.type, getMrgDebitInfo2Worker);
}
function* findListMarginAttrWatcher() {
  yield takeLatest(findListMarginAttrRequest.type, findListMarginAttrWorker);
}

function* getMarginDebitExtendFeeInfoWatcher() {
  yield takeLatest(
    getMarginDebitExtendFeeInfoRequest.type,
    getMarginDebitExtendFeeInfoWorker
  );
}
function* updateMrgContractPaymentWatcher() {
  yield takeLatest(
    updateMrgContractPaymentRequest.type,
    updateMrgContractPaymentWorker
  );
}
function* marginDebitExtendDaysWatcher() {
  yield takeLatest(
    marginDebitExtendDaysRequest.type,
    marginDebitExtendDaysWorker
  );
}

function* fetchMoreListDebitPayExWatcher() {
  yield takeLatest(
    fetchMoreListDebitPayExRequest.type,
    fetchMoreListDebitPayExWorker
  );
}

function* fetchMoreListDebitPayExWorker(
  action: ReturnType<typeof getListDebitPayExRequest>
) {
  try {
    const params: IGetListDebitPayExParams = action.payload;
    const response: IGetListDebitPayExResponse = yield call(
      getListDebitPayEx,
      params
    );

    if (response?.statusCode === 0) {
      yield put(
        fetchMoreListDebitPayExSuccess({
          ...response,
          data: response.data.map((i) => {
            return {
              ...i,
              subAccoNo: params.subAccoNo ?? "",
            };
          }),
        })
      );
      yield put(updatePagePayMenHistory(params?.page ? params?.page + 1 : 0));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDebitPayEx2Watcher() {
  yield takeLatest(
    fetchMoreExtendHistoryRequest.type,
    fetchMoreDebitPayEx2Worker
  );
}

function* fetchMoreDebitPayEx2Worker(
  action: ReturnType<typeof fetchMoreExtendHistoryRequest>
) {
  try {
    const params: IGetListDebitPayExParams = action.payload;
    const response: IGetListDebitPayEx2Response = yield call(
      getListDebitPayEx,
      params
    );

    if (response?.statusCode === 0) {
      yield put(
        fetchMoreExtendHistorySuccess({
          ...response,
          data: response.data.map((i) => {
            return {
              ...i,
              subAccoNo: params.subAccoNo ?? "",
            };
          }),
        })
      );
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListDebitPayExFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDebitWatchWorker(
  action: ReturnType<typeof fetchMoreDebitWatchRequest>
) {
  try {
    const params: IFindMarginContractDebitWatchParams = action.payload;
    const response: IFindMarginContractDebitWatchResponse = yield call(
      findMarginContractDebitWatch,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updatePageLoanTracking(params?.page ? params?.page : 0));
      yield put(fetchMoreDebitWatchSuccess(response));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDebitWatchWatcher() {
  yield takeLatest(fetchMoreDebitWatchRequest.type, fetchMoreDebitWatchWorker);
}

export default function* advanceLimitSaga() {
  yield all([
    findMarginContractDebitWatchWatcher(),
    updateModalPaymentWatcher(),
    updateModalConfirmWatcher(),
    getMrgDebitInfo2Watcher(),
    updateMrgContractPaymentWatcher(),
    updateModalLoanExtendWatcher(),
    findListMarginAttrWatcher(),
    getMarginDebitExtendFeeInfoWatcher(),
    marginDebitExtendDaysWatcher(),
    getListDebitPayExWatcher(),
    getListDebitPayEx2Watcher(),
    fetchMoreListDebitPayExWatcher(),
    fetchMoreDebitPayEx2Watcher(),
    fetchMoreDebitWatchWatcher(),
  ]);
}
