import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ETradingDateMapNetFlow, UNIT_NUMBER } from "helper/consts";
import { MarketNetFlowResponse } from "domain/protoNew/auto_trading_pb";

export type NetflowState = {
  labels: string[];
  values: number[];
  colors: number[];
  loading: boolean;
  error: string | undefined;
  tradingDates: ETradingDateMapNetFlow;
};

const initialState: NetflowState = {
  values: [],
  colors: [],
  labels: [],
  loading: false,
  error: undefined,
  tradingDates: ETradingDateMapNetFlow.PRESENT,
};

const netflowSlice = createSlice({
  name: "netflowSlice",
  initialState,
  reducers: {
    fetchNetflowData: (state) => {
      state.loading = true;
    },
    fetchNetflowDataSuccess: (
      state,
      action: PayloadAction<{
        response: MarketNetFlowResponse.AsObject;
      }>
    ) => {
      const { payload } = action;
      state.loading = false;

      state.values = [];
      state.colors = [];
      state.labels = [];
      payload.response.marketnetflowlistList.forEach((marketnetflow) => {
        state.labels.push(moment(marketnetflow.time).format("HH:mm:ss"));
        state.values.push(marketnetflow.netamt / UNIT_NUMBER.ONE_MILLION);
        state.colors.push(marketnetflow.netamt > 0 ? 1 : -1);
      });
    },
    fetchNetflowDataFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
    },
    changeTradingDates: (
      state,
      action: PayloadAction<ETradingDateMapNetFlow>
    ) => {
      state.tradingDates = action.payload;
    },
  },
});

export const {
  fetchNetflowData,
  fetchNetflowDataSuccess,
  fetchNetflowDataFailure,
  changeTradingDates,
} = netflowSlice.actions;

export default netflowSlice.reducer;
