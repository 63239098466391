import {
  InputPlaceOrder,
  Spacer,
  // Switch
} from "components/commons";
import numeral from "numeral";
import { ChangeEvent, FC, memo, useState } from "react";
import {
  Body,
  SuggestionContainer,
  Title,
  Empty,
  Error,
  // YesNoButton,
} from "./styles";
import { ReactComponent as IconDecrement } from "assets/image/svg/ic_decrement.svg";
import { ReactComponent as IconIncrement } from "assets/image/svg/ic_increment.svg";
import { incrementPrice, decrementPrice } from "helper/tradingHelper";
// import { ListKeyShortcuts } from "themes/types";
// import {
//   isPriceInputSelector,
//   setListKeyShort
// } from "themes/redux";
// import { useSelector, useDispatch } from "react-redux";
import { IconContainer } from "components/commons/InputVolume/styles";

interface Props {
  value: string | number;
  onChange: (num: number | string, type?: string) => void;
  suggestions?: any[];
  label?: string;
  ticker?: string;
  max?: number;
  min?: number;
  partern: RegExp;
  decimal?: boolean;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlurEnter?: () => void;
  handleChangeSuggestionsPriceNextFocus?: () => void;
  isOneNumberAfterComma?: boolean;
  onClickButton?: (newValue: number) => void;
  containerId?: string;
  isShowSwitch?: boolean;
  onChangeSwitch?: (value: boolean) => void;
  isPlaceOrder?: boolean;
}
const InputPrice: FC<Props> = memo(
  ({
    value,
    onChange,
    suggestions,
    label,
    ticker,
    max,
    min,
    partern,
    decimal,
    error,
    placeholder,
    disabled = false,
    name,
    innerRef,
    onKeyDown,
    onBlurEnter,
    handleChangeSuggestionsPriceNextFocus,
    isOneNumberAfterComma,
    onClickButton,
    containerId,
    isShowSwitch,
    onChangeSwitch,
    isPlaceOrder = false,
  }) => {
    // const dispatch = useDispatch();
    const [focus, setFocus] = useState(false);
    // const isPriceInput = useSelector(isPriceInputSelector);
    // const [loading, showLoading] = useState(false);
    // const [disabledSwitch, setDisabledSwitch] = useState(false);

    const onText = (e: ChangeEvent<HTMLInputElement>) => {
      let text: string | number | null = 0;
      if (decimal) {
        if (isOneNumberAfterComma) {
          const textAfterComma = e.target.value.split(".");
          const lenghtText = textAfterComma[1]?.length;
          if (lenghtText > 1) {
            text = value;
          } else {
            text = e.target.value;
          }
        } else {
          text = e.target.value;
        }
      } else {
        text = numeral(e.target.value).value();
      }

      if (text === null || text === "") {
        return onChange("");
      }
      if (!partern.test(text as string)) {
        if (isPlaceOrder) {
          onChange(text.toString().toLocaleUpperCase().trim());
          return;
        } else {
          return;
        }
      }
      if (Number(text) >= 1) {
        onChange(text.toString().replace(/^0+/, ""));
        return;
      }
      onChange(text);
    };

    const onClickSuggest = (value: string | number) => () => {
      if (handleChangeSuggestionsPriceNextFocus) {
        setTimeout(() => handleChangeSuggestionsPriceNextFocus(), 150);
      }
      onChange(value);
    };

    const onBlur = () => {
      if (onBlurEnter) {
        onBlurEnter();
      }
      setTimeout(() => setFocus(false), 150);
    };

    const onFocus = () => setFocus(true);

    const handleWheel = (event: any) => {
      event.stopPropagation();
      if (event.deltaY < 0) {
        if (!disabled) {
          const priceChange = incrementPrice(
            ticker ?? "",
            value,
            max,
            min,
            isOneNumberAfterComma
          );
          onChange(priceChange, "increment");
        }
      } else if (event.deltaY > 0) {
        if (!disabled) {
          const priceChange = decrementPrice(
            ticker ?? "",
            value,
            min,
            isOneNumberAfterComma
          );
          onChange(priceChange, "decrement");
        }
      }
    };

    const onMouseOverHandler = () => {
      if (containerId) {
        const containerEle = document.getElementById(containerId);
        if (!!containerEle) {
          containerEle.style.overflowY = "hidden";
        }
      }
    };

    const onMouseOutHandler = () => {
      if (containerId) {
        const containerEle = document.getElementById(containerId);
        if (!!containerEle) {
          containerEle.style.overflowY = "auto";
        }
      }
    };

    // const handleSwitch = () => {
    //   if (isPriceInput) {
    //     onChange("");
    //   }
    //   if (onChangeSwitch) {
    //     onChangeSwitch(!isPriceInput);
    //   }
    //   const listKeyShortcut: ListKeyShortcuts = {
    //     isPriceInput: !isPriceInput,
    //   };
    //   dispatch(setListKeyShort(listKeyShortcut));
    //   showLoading(false);
    //   setDisabledSwitch(false);
    // };

    return (
      <>
        <div className="d-flex align-items-center">
          {label && <Title>{label}</Title>}
          <Body>
            <InputPlaceOrder
              name={name}
              innerRef={innerRef}
              onFocus={onFocus}
              onBlur={onBlur}
              value={value}
              onChange={onText}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onWheel={handleWheel}
              onMouseOver={onMouseOverHandler}
              onMouseOut={onMouseOutHandler}
              prepend={
                <IconContainer
                  isBorder={true}
                  onClick={() => {
                    if (!disabled) {
                      const priceChange = decrementPrice(
                        ticker ?? "",
                        value,
                        min,
                        isOneNumberAfterComma
                      );
                      onChange(priceChange, "decrement");
                      const newValue = +(+value - 0.1).toFixed(2);
                      if (
                        (typeof min === "number" && newValue >= min) ||
                        typeof min === "undefined"
                      ) {
                        onClickButton?.(newValue);
                      }
                    }
                  }}
                >
                  <IconDecrement />
                </IconContainer>
              }
              append={
                <IconContainer
                  onClick={() => {
                    if (!disabled) {
                      const priceChange = incrementPrice(
                        ticker ?? "",
                        value,
                        max,
                        min,
                        isOneNumberAfterComma
                      );
                      onChange(priceChange, "increment");
                      const newValue = +(+value + 0.1).toFixed(2);
                      if (
                        (typeof max === "number" && newValue <= max) ||
                        typeof max === "undefined"
                      ) {
                        onClickButton?.(newValue);
                      }
                    }
                  }}
                >
                  <IconIncrement />
                </IconContainer>
              }
              isBorderActive={focus}
              isTextCenter
              placeholder={placeholder}
            />
            {/* {isShowSwitch && (
              <YesNoButton>
                <Switch
                  checked={isPriceInput}
                  onChange={() => handleSwitch()}
                  disabled={disabledSwitch}
                  loading={loading}
                />
              </YesNoButton>
            )} */}
          </Body>
        </div>
        {error && !focus ? (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Empty></Empty>
            <Error>
              <span className="text-danger flex-1">{error}</span>
            </Error>
          </div>
        ) : (
          <div></div>
        )}

        {label && <Spacer size="s" />}

        {focus && suggestions && suggestions.length > 0 && (
          <>
            <SuggestionContainer>
              {suggestions.map((item, index) => (
                <div
                  className="item"
                  key={index}
                  onMouseDown={onClickSuggest(item)}
                >
                  {decimal ? item : numeral(item).format("0,000")}
                </div>
              ))}
            </SuggestionContainer>
            <Spacer size="s" />
          </>
        )}
      </>
    );
  }
);

export default InputPrice;
