import VgaiaHttp from "core/http/singleton/vgaia";
import { IMarginSecuritiesResponse } from "domain/models/MarginSecurities";
import { IFindMarginContractWatchParams } from "./types";
import { findMarginContractWatchApi } from "./urls";

export const getMarginContractWatch = async (
  params: IFindMarginContractWatchParams
): Promise<IMarginSecuritiesResponse> => {
  const res = await VgaiaHttp.post<IMarginSecuritiesResponse>(
    findMarginContractWatchApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
