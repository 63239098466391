import styled from "styled-components/macro";

export const CardHeader = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  max-height: 40px;
  align-items: center;
  z-index: 4;
  padding-right: 8px;
  .btn-edit-col {
    min-width: 114px;
  }
  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    height: 32px;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.grey70};
    }
  }

  .header-right {
    min-width: 234px;
    background-color: ${({ theme }) => theme.background3};
    box-shadow: ${({ theme }) => theme.shadowLineDown};
    padding: 4px 8px;
    display: flex;
    align-items: center;
    .input {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }

  .header-item {
    position: relative;
    height: 100%;
    :hover {
      .menu {
        display: block;
      }
    }
  }
`;

export const WatchlistCard = styled.div<{
  isActive?: boolean;
  isGroupItem?: boolean;
}>`
  padding: 0 16px;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  color: ${({ theme }) => theme.grey50};
  background-color: ${({ theme }) => theme.blackB4};
  cursor: pointer;
  white-space: nowrap;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    opacity: 0.8;
  }

  .arrow-down {
    margin-left: 8px;
  }

  ${({ isGroupItem }) =>
    isGroupItem &&
    `display: flex;
    align-items: center;`}

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-bottom: 2px solid ${theme.brandPrimary};
    .arrow-down {
      path {
        fill: ${theme.textcolorWhite}
      }
    }
    `}

    .watch-list-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    font-weight: ${({ theme }) =>
      theme.typeFaces.watchlist.menu.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.watchlist.menu.size};
    color: ${({ theme }) => theme.grey50};
    ${({ isActive, theme }) =>
      isActive &&
      `color: ${theme.textcolorWhite};
    `}
  }
`;

export const SelectTickerWrapper = styled.div`
  width: 100%;
  .ml-12 {
    margin-left: 12px;
  }
  .option-div {
    display: flex;
    justify-content: space-between;
  }

  .option-left {
    display: flex;

    .ticker {
      font-style: normal;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.textcolorWhite};
    }

    .name {
      font-style: normal;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    }
  }

  .option-right {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    color: ${({ theme }) => theme.grey30};
  }
`;

export const SearchTicker = styled.div`
  position: relative;
  z-index: 4;
  .pl-3 {
    background: ${({ theme }) => theme.grey100};
  }
  .flex-fill {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;
  }
`;

export const TextSearch = styled.div`
  width: 400px;
  background: ${({ theme }) => theme.grey70};
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: 4px;
  max-height: 450px;
  overflow: auto;
`;

export const TextItem = styled.div`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.grey60};
  div {
    .share-code {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    color: ${({ theme }) => theme.textcolorWhite};
  }

  :hover {
    background: ${({ theme }) => theme.greyG6};
  }
`;
