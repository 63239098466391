/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { ReactComponent as ImageLogin } from "assets/image/svg/image-login.svg";
import { Button, CheckBox, Input, Spacer } from "components/commons";
// import crypto from "crypto";
import { ADDRESS_IP } from "helper/consts";
import storage from "helper/storage";
import {
  loginStart,
  setvisibleModalRegister,
  toggleModalForgetPassword,
  toggleModalLogin,
} from "modules/auth/redux";
import {
  errorLoginSelector,
  isAuthenticatedSelector,
  isLoginErrorSelector,
  statusModalLoginSelector,
  statusSelector,
  visibleModalForgetPasswordSelector,
} from "modules/auth/selectors";
import { visibleModalConfirmOpenLoginSelector } from "modules/system/selectors";
import { memo, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReduxStateType } from "redux/types";
import {
  AcceptBox,
  AcceptTermRow,
  AcceptText,
  Body,
  ButtonExit,
  Container,
  ErrorVerify,
  Footer,
  Icon,
  Title,
  WrapBoder,
  WrapImage,
} from "./styles";
import { LoginRequired, VersionWeb } from "AppConfig";
import { selectDataCreateUser } from "modules/auth/selectorsModalRegister";
import { getDataCreateUser } from "core/http/apis/modal-register";
import { updateDataCreateUser } from "modules/auth/redux/modalRegister";
import { toast } from "components/commons/Toast";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const publicIp = require("public-ip");

interface Inputs {
  username: string;
  password: string;
}

function ModalLogin() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const statusModalLogin = useSelector(statusModalLoginSelector);
  const visibleModalConfirmOpenLogin = useSelector(
    visibleModalConfirmOpenLoginSelector
  );
  const visibleForgetPassword = useSelector(visibleModalForgetPasswordSelector);
  const status = useSelector(statusSelector);
  const errorLogin = useSelector(errorLoginSelector);
  const isLoginError = useSelector(isLoginErrorSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const [checked, setChecked] = useState(false);

  const [statusModalMBS, setStatusModalMBS] = useState(false);
  const usernameRef = useRef<HTMLInputElement>(null);
  const dataCreate = useSelector(selectDataCreateUser);

  const disabled_custType = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "COMPANY_CODE"
      )
    : {};

  const passwordRef = useRef<HTMLInputElement>(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: { username: "", password: "" },
  });

  useEffect(() => {
    const autoFillUser = () => {
      const user = getValues("username");
      if (user.length <= 6) {
        const partern = `${disabled_custType?.value ?? ""}C000000`;
        const value = partern
          .slice(0, partern.length - user.length)
          .concat(user);
        setValue("username", value);
      }
    };
    usernameRef.current?.addEventListener("blur", autoFillUser);
    usernameRef.current?.focus();
  }, [statusModalLogin]);

  useEffect(() => {
    // xóa trạng thái khi F5
    if (!statusModalLogin) return reset();
    setValue("username", storage.getRememberAccount());
    setValue("password", "");
    if (storage.getRememberAccount() !== "") setChecked(true);
  }, [statusModalLogin]);

  const handleOpenModalForgetPassword = () => {
    dispatch(toggleModalForgetPassword(watch("username"))); // mở quên mk và fill tài khoản
    dispatch(toggleModalLogin(false));
  };

  const onSubmit = async ({ password, username }: Inputs) => {
    if (checked === true) {
      // check remember account
      storage.setRememberAccount(username);
    } else {
      storage.setRememberAccount("");
    }

    let address = "web";
    try {
      address = await publicIp.v4();
      storage.set(ADDRESS_IP, address);
    } catch (error: any) {
      console.warn(error);
    }

    // const randomNumber = crypto.randomBytes(64);

    dispatch(
      loginStart({
        username,
        password,
        // product: "TRACKING",
        // device_id: address,
        // code_challenge: challenge(randomNumber),
        // verifier: verifier(randomNumber),
      })
    );

    const resLogData = {
      eventGroup: GroupEvent.login,
      event: RealtimeConst.submitLogin,
      deviceInfo: { ipAddress: address },
      appVersion: VersionWeb,
    };
    storage.commonPushLogFirebase(resLogData);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(toggleModalLogin(false));
    } else {
      const hasRememberAccount = storage.getRememberAccount();
      if (hasRememberAccount !== "") {
        setValue("username", storage.getRememberAccount());
        setValue("password", "");
        setChecked(true);
        passwordRef.current?.focus();
      } else {
        usernameRef.current?.focus();
      }
    }
  }, [isAuthenticated]);

  const closeLogin = () => {
    history.push("/");
    dispatch(toggleModalLogin(false));
  };

  const handleOpenModalRegister = () => {
    dispatch(setvisibleModalRegister());
    // const resLogData = {
    //   eventGroup: GroupEvent.ekyc,
    //   event: RealtimeConst.,
    //   message: "register account",
    // };
    // storage.commonPushLogFirebase(resLogData);
  };

  const dataCreateUser = async () => {
    const res: any = await getDataCreateUser();
    if (res?.statusCode === 0) {
      dispatch(updateDataCreateUser(res?.data));
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    dataCreateUser();
  }, []);

  return (
    <>
      <Modal
        show={
          statusModalLogin && !visibleModalConfirmOpenLogin && !isAuthenticated
        }
        centered
        backdrop="static"
        keyboard={false}
        size={"lg"}
        onHide={() => closeLogin()}
      >
        <WrapBoder className="d-flex">
          <WrapImage>
            <ImageLogin style={{ width: "100%" }} />
          </WrapImage>
          <Container blur={visibleForgetPassword}>
            <div className="body">
              {LoginRequired === false && (
                <ButtonExit onClick={closeLogin}>
                  <Icon>
                    <IconExit />
                  </Icon>
                </ButtonExit>
              )}

              <ErrorVerify>
                {(status === ReduxStateType.ERROR ||
                  errorLogin ||
                  isLoginError) &&
                  intl.formatMessage({ id: errorLogin || "common.errorlogin" })}
              </ErrorVerify>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pb-2">
                  <Title className="pb-2">
                    <FormattedMessage id="auth.login.login" />
                  </Title>
                  <p className="d-flex">
                    <FormattedMessage id="auth.login.questionRegister" />
                    <div
                      className="textRegister pl-1"
                      onClick={handleOpenModalRegister}
                    >
                      <FormattedMessage id="auth.login.linkRegisterL" />
                    </div>
                  </p>
                </div>
                <div className="d-flex mb-1">
                  {intl.formatMessage({ id: "auth.login.username" })}
                  <p className="text-danger pl-1 mb-0">*</p>
                </div>
                <Input
                  name="username"
                  maxLength={20}
                  rules={{ required: true }}
                  control={control}
                  // title={intl.formatMessage({ id: "auth.login.username" })}
                  placeholder={intl.formatMessage({
                    id: "auth.login.enterUsername",
                  })}
                  onClearInput={() => {
                    setValue("username", "");
                    usernameRef?.current?.focus();
                  }}
                  canClear
                  innerRef={usernameRef}
                  isRequired
                />
                {errors.username?.type === "required" && (
                  <span className="text-error">
                    {intl.formatMessage({ id: "auth.errors.emptyUsername" })}
                  </span>
                )}
                <Spacer size="m" />
                <div className="d-flex mb-1">
                  {intl.formatMessage({ id: "common.password" })}
                  <p className="text-danger pl-1 mb-0">*</p>
                </div>
                <Input
                  type="password"
                  // title={intl.formatMessage({ id: "common.password" })}
                  maxLength={20}
                  placeholder={intl.formatMessage({
                    id: "common.enterPassword",
                  })}
                  name="password"
                  rules={{ required: true }}
                  control={control}
                  onClearInput={() => setValue("password", "")}
                  canClear
                  // isRequired
                />

                {errors.password?.type === "required" && (
                  <span className="text-error">
                    {intl.formatMessage({ id: "auth.errors.emptyPassword" })}
                  </span>
                )}
                <Spacer size="l" />
                <AcceptTermRow>
                  <AcceptBox>
                    <CheckBox
                      onChange={() => setChecked(!checked)}
                      checked={checked}
                    />
                  </AcceptBox>
                  <AcceptText>
                    <FormattedMessage id="auth.login.rememberMe" />
                  </AcceptText>
                </AcceptTermRow>
                <Button
                  type="submit"
                  className="block"
                  color="accept"
                  fill
                  disabled={!!errors.password || !!errors.username}
                  loading={status === ReduxStateType.LOADING}
                >
                  {intl.formatMessage({ id: "common.login" })}
                </Button>
                <div className="forgot-password mt-2">
                  <div
                    style={{ textAlign: "center" }}
                    onClick={handleOpenModalForgetPassword}
                  >
                    <div className="textRegister">
                      <FormattedMessage id="auth.login.forgotPass" />
                    </div>
                  </div>
                </div>
              </form>
              <Spacer size="l" />

              <div className="d-flex">
                <div className="w-25 text-center mb-0">
                  <FormattedMessage id="auth.login.risk" />
                </div>
                <div className="w-25 text-center mb-0">
                  <FormattedMessage id="auth.login.Contact" />
                </div>
                <div className="w-25 text-center mb-0">
                  <FormattedMessage id="auth.login.Hotline" />
                </div>
                <div className="w-25 text-center mb-0">
                  <FormattedMessage id="auth.login.Tutorial" />
                </div>
              </div>
            </div>
          </Container>
        </WrapBoder>

        <Modal
          show={statusModalMBS}
          centered
          keyboard={false}
          onHide={() => setStatusModalMBS(false)}
          dialogClassName="modal-284"
        >
          <Container>
            <div className="title">
              <FormattedMessage id="auth.login.callMBS" />
            </div>
            <Body padding={24}>
              <div className="footer">
                <Body>
                  <FormattedMessage id="auth.login.numberMBS" />
                </Body>
                <Footer>
                  <Button
                    fill={true}
                    color="accept"
                    className="flex-1"
                    radius={4}
                    onClick={() => setStatusModalMBS(false)}
                  >
                    <FormattedMessage id="common.buttonClose" />
                  </Button>
                </Footer>
              </div>
            </Body>
          </Container>
        </Modal>
      </Modal>
    </>
  );
}

export default memo(ModalLogin);
