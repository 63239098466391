import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CoverWarrantState = {
  isOpenDropdownCoverWarrant: boolean;
  selectedTypeCoverWarrant: string;
};

const initialState: CoverWarrantState = {
  isOpenDropdownCoverWarrant: false,
  selectedTypeCoverWarrant: "",
};

const coverWarrantSlice = createSlice({
  name: "coverWarrantSlice",
  initialState,
  reducers: {
    toggleDropdownCoverWarrant: (state) => {
      state.isOpenDropdownCoverWarrant = !state.isOpenDropdownCoverWarrant;
    },
    changeTypeCoverWarrant: (state, action: PayloadAction<string>) => {
      state.selectedTypeCoverWarrant = action.payload;
    },
  },
});
export const { toggleDropdownCoverWarrant, changeTypeCoverWarrant } =
  coverWarrantSlice.actions;

export default coverWarrantSlice.reducer;
