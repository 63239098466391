import { toast } from "components/commons/Toast";
import {
  searchBondOrder,
  getBondList,
  enterBondOrder,
  rejectBondOrder,
} from "core/http/apis/bid-ask-bond";
import { findCbotBondPriceEst } from "core/http/apis/bid-ask-bond-order";
import {
  ISearchBondOrderParams,
  IGetBondListParams,
  IEnterBondOrderParams,
  IRejectBondOrderParams,
} from "core/http/apis/bid-ask-bond/types";
import { IFindCbotBondPriceEstParams } from "core/http/apis/bid-ask-bond-order/types";
import {
  ISearchBondOrderResponse,
  IGetBondListResponse,
  BondOrder,
  IEnterBondOrderResponse,
  IRejectBondOrderResponse,
} from "domain/models/BidAskBond";
import { IFindCbotBondPriceEstResponse } from "domain/models/BidAskBondOrder";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  searchBondOrderFailure,
  searchBondOrderRequest,
  searchBondOrderSuccess,
  getBondListRequest,
  getBondListSuccess,
  getBondListFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  enterBondOrderRequest,
  enterBondOrderSuccess,
  enterBondOrderFailure,
  rejectBondOrderRequest,
  rejectBondOrderSuccess,
  rejectBondOrderFailure,
  findCbotBondPriceEstRequest,
  findCbotBondPriceEstSuccess,
  findCbotBondPriceEstFailure,
} from "./redux";

function* searchBondOrderWorker(
  action: ReturnType<typeof searchBondOrderRequest>
) {
  try {
    const params: ISearchBondOrderParams = action.payload;
    const response: ISearchBondOrderResponse = yield call(
      searchBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchBondOrderSuccess(response));
    } else {
      yield put(searchBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBondListWorker(action: ReturnType<typeof getBondListRequest>) {
  try {
    const params: IGetBondListParams = action.payload;
    const response: IGetBondListResponse = yield call(getBondList, params);

    if (response?.statusCode === 0) {
      yield put(getBondListSuccess(response));
    } else {
      yield put(getBondListFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBondListFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findCbotBondPriceEstWorker(
  action: ReturnType<typeof findCbotBondPriceEstRequest>
) {
  try {
    const params: IFindCbotBondPriceEstParams = action.payload;
    const response: IFindCbotBondPriceEstResponse = yield call(
      findCbotBondPriceEst,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotBondPriceEstSuccess(response));
    } else {
      yield put(findCbotBondPriceEstFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotBondPriceEstFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
    }: {
      isVisible: boolean;
      itemDetail?: BondOrder;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateModalStatusSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateModalStatusSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* enterBondOrderWorker(
  action: ReturnType<typeof enterBondOrderRequest>
) {
  try {
    const params: IEnterBondOrderParams = action.payload;
    const response: IEnterBondOrderResponse = yield call(
      enterBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(enterBondOrderSuccess(response));
    } else {
      yield put(enterBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(enterBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* rejectBondOrderWorker(
  action: ReturnType<typeof rejectBondOrderRequest>
) {
  try {
    const params: IRejectBondOrderParams = action.payload;
    const response: IRejectBondOrderResponse = yield call(
      rejectBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(rejectBondOrderSuccess(response));
    } else {
      yield put(rejectBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(rejectBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchBondOrderWatcher() {
  yield takeLatest(searchBondOrderRequest.type, searchBondOrderWorker);
}
function* getBondListWatcher() {
  yield takeLatest(getBondListRequest.type, getBondListWorker);
}

function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}
function* enterBondOrderWatcher() {
  yield takeLatest(enterBondOrderRequest.type, enterBondOrderWorker);
}
function* rejectBondOrderWatcher() {
  yield takeLatest(rejectBondOrderRequest.type, rejectBondOrderWorker);
}
function* findCbotBondPriceEstWatcher() {
  yield takeLatest(
    findCbotBondPriceEstRequest.type,
    findCbotBondPriceEstWorker
  );
}

export default function* advanceLimitSaga() {
  yield all([
    searchBondOrderWatcher(),
    getBondListWatcher(),
    findCbotBondPriceEstWatcher(),
    updateModalStatusWatcher(),
    enterBondOrderWatcher(),
    rejectBondOrderWatcher(),
  ]);
}
