import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ICancelDertRequestCondParams,
  IDertCancelMultiOrderParams,
  IDertEditOrderParams,
  IFindDertOrderByFilterParams,
  IFindDertOrderCondParams,
  IFindDertOrderDetailParams,
  IFindDertRequestCondParams,
} from "core/http/apis/tradeinfo/types";
import {
  IDertEditOrderResponse,
  IDertOrderCond,
  IDertOrderDetail,
  OrderBookDerCondDisplay,
  OrderBookDerDisplay,
  OrderBookDerItem,
  OrderBookDerRealTime,
} from "domain/models/OrderBookDer";
import { DEFAULT_PAGE_SIZE } from "helper/consts";
import {
  getOrderPriceDisp,
  getOrderStatusColor,
  getOrderStatusPercent,
} from "helper/orderBookHelper";
import { getOrderStatusDisp } from "helper/tradingHelper";
import { Dictionary } from "lodash";
import { logOutSuccess } from "modules/auth/redux";
import moment from "moment";
import { IntlShape } from "react-intl";

export type OrderBookOption = {
  label: string;
  value: string;
};
export type OrderBookDerWidgetState = {
  orderBookDerList: OrderBookDerDisplay[];
  orderBookDerRealTime: Dictionary<OrderBookDerRealTime>;
  dertDetailList: IDertOrderDetail[];
  selectedRows: OrderBookDerDisplay[];
  orderBookDerCondList: OrderBookDerCondDisplay[];
  dertCondDetailList: IDertOrderCond[];
  selectedCondRows: OrderBookDerCondDisplay[];
  loading: boolean;
  error: string | undefined;
  filters: {
    limit: number;
    offset: number;
    currentTicker: string;
    tradeType: { value: string; label: string };
    orderStatus: { value: string; label: string };
    orderStatusCond: { value: string; label: string };
    condType: { value: string; label: string };
  };
  enableInfinity: boolean;
  price: number;
  volume: number;
  profit: number;
  stopLoss: number;
  margin: number;
  loadList: number;
  singleOrderStatus: { status: string; error?: string };
  widgetName: string;
  changePriceByEnter: boolean;
  changeVolumeByEnter: boolean;
  changeProfitByEnter: boolean;
  changeStopLossByEnter: boolean;
  changeMarginByEnter: boolean;
  loadingEdit: boolean;
  isReloadPlaceOrderDer: boolean;
  condition: { value: string; label: string };
  cancelStatus: { status: string; error?: string };
  isRefestData: boolean | undefined;
  isCheckAll: boolean;
};

const initialState: OrderBookDerWidgetState = {
  orderBookDerList: [],
  orderBookDerRealTime: {},
  dertDetailList: [],
  selectedRows: [],
  orderBookDerCondList: [],
  dertCondDetailList: [],
  selectedCondRows: [],
  loading: false,
  error: undefined,
  enableInfinity: false,
  filters: {
    limit: DEFAULT_PAGE_SIZE,
    offset: 0,
    currentTicker: "",
    tradeType: { value: "", label: "Tất cả" },
    orderStatus: { value: "", label: "Tất cả" },
    orderStatusCond: { value: "", label: "Tất cả" },
    condType: { value: "", label: "Tất cả" },
  },
  price: 0,
  volume: 0,
  profit: 0,
  stopLoss: 0,
  margin: 0,
  loadList: 0,
  singleOrderStatus: { status: "", error: "" },
  widgetName: "",
  changePriceByEnter: false,
  changeVolumeByEnter: false,
  changeProfitByEnter: false,
  changeStopLossByEnter: false,
  changeMarginByEnter: false,
  loadingEdit: false,
  isReloadPlaceOrderDer: false,
  condition: { value: "01", label: "Lệnh thường" },
  cancelStatus: { status: "", error: "" },
  isRefestData: false,
  isCheckAll: false,
};

const orderBookDerWidgetSlice = createSlice({
  name: "orderBookDerWidgetSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    updateFilter: (state, action: any) => {
      state.filters = action.payload;
      state.loading = false;
    },
    changeCondition: (state, action: any) => {
      state.condition = action.payload;
    },
    changeCurrentTicker: (state, action: any) => {
      state.filters.currentTicker = action.payload;
    },
    changeTradeType: (state, action: any) => {
      state.filters.tradeType = action.payload;
    },
    changeOrderStatus: (state, action: any) => {
      state.filters.orderStatus = action.payload;
    },
    changeOrderStatusCond: (state, action: any) => {
      state.filters.orderStatusCond = action.payload;
    },
    changeCondType: (state, action: any) => {
      state.filters.condType = action.payload;
    },
    changeSelectedRows: (state, action: any) => {
      state.selectedRows = action.payload;
    },
    changeSelectedCondRows: (state, action: any) => {
      state.selectedCondRows = action.payload;
    },
    onCheckAll: (state, action: PayloadAction<boolean>) => {
      state.isCheckAll = action.payload;
    },
    onPrice: (state, action: any) => {
      state.price = action.payload;
    },
    onVolume: (state, action: any) => {
      state.volume = action.payload;
    },
    onProfit: (state, action: any) => {
      state.profit = action.payload;
    },
    onStopLoss: (state, action: any) => {
      state.stopLoss = action.payload;
    },
    onMargin: (state, action: any) => {
      state.margin = action.payload;
    },
    onLoadList: (state, action) => {
      state.loadList = action.payload;
      state.singleOrderStatus = { status: "" };
    },
    onLoadEdit: (state, action: PayloadAction<boolean>) => {
      state.loadingEdit = action.payload;
    },

    addWidgetName(state, action: PayloadAction<string>) {
      state.widgetName = action.payload;
    },
    onChangePriceOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changePriceByEnter = action.payload;
    },
    onChangeVolumeOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changeVolumeByEnter = action.payload;
    },
    onChangeProfitOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changeProfitByEnter = action.payload;
    },
    onChangeStopLossOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changeStopLossByEnter = action.payload;
    },
    onChangeMarginOrderBookEditByEnter: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changeMarginByEnter = action.payload;
    },
    onReloadPlaceOrderDer: (state, action: PayloadAction<boolean>) => {
      state.isReloadPlaceOrderDer = action.payload;
    },
    editSingleOrderStatusDerClear: (state) => {
      state.singleOrderStatus = { status: "" };
    },

    clearFilter: (state) => {
      state.filters = {
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
        currentTicker: "",
        tradeType: { value: "", label: "Tất cả" },
        orderStatus: { value: "", label: "Tất cả" },
        orderStatusCond: { value: "", label: "Tất cả" },
        condType: { value: "", label: "Tất cả" },
      };
    },

    // OrderbookDer redux --- start ---

    findDertOrderByFilter: (
      state,
      action: PayloadAction<{
        params: IFindDertOrderByFilterParams;
        intl: IntlShape;
        isRefestData?: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
      state.isRefestData = action.payload.isRefestData;
    },
    findDertOrderByFilterSuccess: (
      state,
      action: PayloadAction<{ items: any[]; offset: number }>
    ) => {
      state.orderBookDerList =
        action.payload.offset === 0
          ? action.payload.items
          : state.orderBookDerList.concat(action.payload.items);

      if (action.payload.offset === 0) {
        state.orderBookDerRealTime = action.payload.items.reduce(
          (obj, cur) => ({
            ...obj,
            [cur.realTimeId]: {
              orderStatusDisplay: cur.orderStatusDisplay,
              orderStatusColor: cur.orderStatusColor,
              orderStatusPercent: cur.orderStatusPercent,
              ordQty: cur.ordQty,
              orderPriceDisp: cur.orderPriceDisp,
              matQty: cur.matQty,
              matPriceAvg: cur.matPriceAvg,
              pubQty: cur.pubQty,
              pubPrice: cur.pubPrice,
              lastMatchTimeDisp: cur.lastMatchTimeDisp,
              iscancel: cur.iscancel,
              isadmend: cur.isadmend,
            },
          }),
          {}
        );
      } else {
        state.orderBookDerRealTime = {
          ...state.orderBookDerRealTime,
          ...action.payload.items.reduce(
            (obj, cur) => ({
              ...obj,
              [cur.realTimeId]: {
                orderStatusDisplay: cur.orderStatusDisplay,
                orderStatusColor: cur.orderStatusColor,
                orderStatusPercent: cur.orderStatusPercent,
                ordQty: cur.ordQty,
                orderPriceDisp: cur.orderPriceDisp,
                matQty: cur.matQty,
                matPriceAvg: cur.matPriceAvg,
                pubQty: cur.pubQty,
                pubPrice: cur.pubPrice,
                lastMatchTimeDisp: cur.lastMatchTimeDisp,
                iscancel: cur.iscancel,
                isadmend: cur.isadmend,
              },
            }),
            {}
          ),
        };
      }

      if (action.payload.items.length < state.filters.limit) {
        state.enableInfinity = false;
        state.filters.offset = 0;
      } else {
        if (state.isRefestData === true) {
          state.enableInfinity = true;
          state.filters.offset = 1;
        } else {
          state.enableInfinity = true;
          state.filters.offset = state.filters.offset + 1;
        }
      }

      if (state.isRefestData === true) {
        state.selectedRows = [];
      }

      state.isRefestData = false;
      state.loading = false;
      if (state.isCheckAll) {
        const selectedRowsNew = action.payload.items.filter(
          (item) => item.visibleCheckbox === true
        );
        state.selectedRows = state.selectedRows.concat(selectedRowsNew);
      }
    },
    findDertOrderByFilterFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    updateOrderBookDerRealTime: (
      state,
      action: PayloadAction<OrderBookDerItem>
    ) => {
      const item = action.payload;
      if (state.orderBookDerRealTime[item.orgOrderNo]) {
        Object.assign(state.orderBookDerRealTime[item.orgOrderNo], {
          orderStatusDisplay: getOrderStatusDisp(item),
          orderStatusColor: getOrderStatusColor(item),
          orderStatusPercent: getOrderStatusPercent(item),
          ordQty: item.ordQty,
          orderPriceDisp: getOrderPriceDisp(item),
          matQty: item.matQty,
          matPriceAvg: item.avgPrice,
          pubQty: item.pubQty,
          pubPrice: item.pubPrice,
          lastMatchTimeDisp: moment(item.updDateTime).format(
            "DD/MM/yyyy HH:mm:ss"
          ),
          isadmend: item.status === "O" && item.ordType !== "PT",
          iscancel:
            (item.status === "O" || item.status === "E") &&
            item.ordType !== "PT",
        });
        return;
      } else {
        //reLoad lại sổ lệnh
        state.loadList = Math.random();
      }
    },

    findDertOrderDetail: (
      state,
      action: PayloadAction<IFindDertOrderDetailParams>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    findDertOrderDetailSuccess: (
      state,
      action: PayloadAction<{ items: any }>
    ) => {
      state.dertDetailList = action.payload.items;
      state.loading = false;
    },
    findDertOrderDetailFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    dertEditOrder: (state, action: PayloadAction<IDertEditOrderParams>) => {
      state.loading = true;
      state.error = undefined;
    },
    dertEditOrderSuccess: (
      state,
      action: PayloadAction<IDertEditOrderResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.singleOrderStatus = { status: "success" };
      } else {
        state.singleOrderStatus = {
          status: "failed",
          error: action.payload.errorCode,
        };
      }
      state.loading = false;
    },
    dertEditOrderFailure: (state, action: PayloadAction<string>) => {
      state.singleOrderStatus = { status: "failed", error: action.payload };
    },

    dertCancelMultiOrder: (
      state,
      action: PayloadAction<{
        params: IDertCancelMultiOrderParams;
        intl: IntlShape;
        content: string;
      }>
    ) => {
      state.loading = true;
    },
    dertCancelMultiOrderSuccess: (
      state,
      action: PayloadAction<IDertCancelMultiOrderParams>
    ) => {
      const listOrder = action.payload.input;
      listOrder.map((item) => {
        const itemChild = item.split(";");
        if (itemChild && itemChild.length > 1) {
          const indexFound = state.orderBookDerList.findIndex(
            (item) =>
              item.tradeDate.toString() === itemChild[0] &&
              item.orgOrderNo.toString() === itemChild[1]
          );
          if (indexFound !== -1) {
            state.orderBookDerList[indexFound].visibleCheckbox = false;
          }
        }

        return item;
      });
      state.cancelStatus = { status: "success" };
      state.loading = false;
    },
    dertCancelMultiOrderFailure: (state, action: PayloadAction<string>) => {
      state.cancelStatus = { status: "failed", error: action.payload };
    },
    cancelOrderListStatusClear: (state) => {
      state.cancelStatus = { status: "" };
    },

    findDertRequestCond: (
      state,
      action: PayloadAction<{
        params: IFindDertRequestCondParams;
        intl: IntlShape;
        isRefestData?: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
      state.isRefestData = action.payload.isRefestData;
    },
    findDertRequestCondSuccess: (
      state,
      action: PayloadAction<{ items: any[]; offset: number }>
    ) => {
      state.orderBookDerCondList =
        action.payload.offset === 0
          ? action.payload.items
          : state.orderBookDerCondList.concat(action.payload.items);

      if (action.payload.items.length < state.filters.limit) {
        state.enableInfinity = false;
        state.filters.offset = 0;
      } else {
        if (state.isRefestData === true) {
          state.enableInfinity = true;
          state.filters.offset = 1;
        } else {
          state.enableInfinity = true;
          state.filters.offset = state.filters.offset + 1;
        }
      }

      if (state.isRefestData === true) {
        state.selectedCondRows = [];
      }

      state.isRefestData = false;
      state.loading = false;
    },
    findDertRequestCondFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    findDertOrderCond: (
      state,
      action: PayloadAction<IFindDertOrderCondParams>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    findDertOrderCondSuccess: (
      state,
      action: PayloadAction<{ items: any }>
    ) => {
      state.dertCondDetailList = action.payload.items;
      state.loading = false;
    },
    findDertOrderCondFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    cancelDertRequestCond: (
      state,
      action: PayloadAction<{
        params: ICancelDertRequestCondParams;
        intl: IntlShape;
        content: string;
      }>
    ) => {
      state.loading = true;
    },

    // OrderbookDer redux --- end ---
  },
});

export const {
  findDertOrderByFilter,
  findDertOrderByFilterSuccess,
  findDertOrderByFilterFailure,
  updateOrderBookDerRealTime,

  findDertOrderDetail,
  findDertOrderDetailSuccess,
  findDertOrderDetailFailure,

  dertEditOrder,
  dertEditOrderSuccess,
  dertEditOrderFailure,

  dertCancelMultiOrder,
  dertCancelMultiOrderSuccess,
  dertCancelMultiOrderFailure,
  cancelOrderListStatusClear,

  findDertRequestCond,
  findDertRequestCondSuccess,
  findDertRequestCondFailure,

  findDertOrderCond,
  findDertOrderCondSuccess,
  findDertOrderCondFailure,

  cancelDertRequestCond,

  updateFilter,
  changeCondition,
  changeCurrentTicker,
  changeTradeType,
  changeOrderStatus,
  changeOrderStatusCond,
  changeCondType,
  changeSelectedRows,
  changeSelectedCondRows,
  onCheckAll,
  onPrice,
  onVolume,
  onProfit,
  onStopLoss,
  onMargin,
  onLoadList,
  onLoadEdit,
  addWidgetName,
  onChangePriceOrderBookEditByEnter,
  onChangeVolumeOrderBookEditByEnter,
  onChangeProfitOrderBookEditByEnter,
  onChangeStopLossOrderBookEditByEnter,
  onChangeMarginOrderBookEditByEnter,
  onReloadPlaceOrderDer,
  editSingleOrderStatusDerClear,
  clearFilter,
} = orderBookDerWidgetSlice.actions;
export default orderBookDerWidgetSlice.reducer;
