import { FC } from "react";

import ProfitLossContent from "./components/ProfitLossContent/index";

import { Container } from "./styles";

const ProfitLoss: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <ProfitLossContent />
    </Container>
  );
};

export default ProfitLoss;
