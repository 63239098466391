import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IRegisterTransferBonds } from "domain/models/RegisterTransferBonds";
import { IGetCustomer2 } from "./type";
import {
  cancelRegisterApi,
  getCbotApi,
  getCustomer2Api,
  registerApi,
} from "./urls";

export const getCustomer2 = async (
  param: IGetCustomer2
): Promise<IRegisterTransferBonds> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getCustomer2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCbot = async (param: any): Promise<IRegisterTransferBonds> => {
  const res = await VgaiaHttp.get<any>(getCbotApi(param));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const register = async (param: any): Promise<IRegisterTransferBonds> => {
  const res = await VgaiaHttp.post<any>(registerApi, JSON.stringify(param), {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cancelRegister = async (
  param: any
): Promise<IRegisterTransferBonds> => {
  const res = await VgaiaHttp.post<any>(cancelRegisterApi(param));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
