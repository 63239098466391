import styled from "styled-components/macro";

export const ChartContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .stack-holder-info {
    height: fit-content;
    align-self: baseline;
    padding-right: 0 !important;
  }
  .pie-chart {
    height: 120px;
  }
`;

export const OrgInfoWrapper = styled.div`
  left: 0;
  top: 96px;
  border-radius: 0;
  padding: 16px;
  /* height: 768px; */
  .org-name {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.grey0};
  }
  .org-des {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }

  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.bgElevated1};
  scrollbar-width: thin;

  overflow-y: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }

  .legend-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
  }

  .legend-item {
    display: grid;
    grid-template-columns: 1fr 10fr;
    grid-gap: 10px;
    justify-content: center;
    align-items: flex-start;
  }
  .org-ratio-stake-holder {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.grey0};
  }
`;

export const LegendOrgChart = styled.div<{ color: string }>`
  border: 2px solid;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  position: static;
  padding: 0;
  background: ${(props) => props.color};
  color: ${(props) => props.color};
`;

export const LegendOrgName = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;
