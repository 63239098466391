import styled from "styled-components/macro";

export const ProgressCard = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
  height: 12px;
  width: ${({ width }) => width}%;
`;
export const Buy = styled.div<{ value: number }>`
  background-color: ${({ theme }) => theme.green50};
  width: ${({ value }) => value}%;
`;

export const Sell = styled.div<{ value: number }>`
  background-color: ${({ theme }) => theme.red50};
  width: ${({ value }) => value}%;
`;

export const Neutral = styled.div<{ value: number }>`
  background-color: ${({ theme }) => theme.grey20};
  width: ${({ value }) => value}%;
`;
