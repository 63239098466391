import { selectedTickerSelector as selectedTickerStockOrderSelector } from "modules/stock-order/redux/selectors";
import { selectedTickerSelector as selectedTickerMarketSelector } from "modules/market/redux/selectors";
import {
  titleTabBrowserSelector,
  totalNotificationSelector,
} from "modules/system/selectors";
import pathRoutes from "helper/pathRoutes";
import { useInfoLastSale } from "helper/cusHooks";
import numeral from "numeral";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import { valueHeaderCardSelector } from "modules/system/selectors";

function checkArrow(value: number) {
  if (value > 0) return " ▲ ";
  if (value < 0) return " ▼ ";
  return " ";
}

const SetTitleRealTime = () => {
  const totalNotification = useSelector(totalNotificationSelector);
  const titleTabBrowser = useSelector(titleTabBrowserSelector);
  const selectedTickerMarket = useSelector(selectedTickerMarketSelector);
  const [title, setTitle] = useState("");
  const currentTickerStockOrder = useSelector(selectedTickerStockOrderSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const dataLastSale = useInfoLastSale(title);
  const dataMarketindexVNIndex = useSelector(
    valueHeaderCardSelector("100", "marketindex")
  ) as number;
  const dataChangepercentVNIndex = useSelector(
    valueHeaderCardSelector("100", "changepercent")
  ) as number;

  // data dataLastSale
  var titleLastSale = useMemo(
    () =>
      numeral(dataLastSale.lastprice).format("0,0.00") +
      checkArrow(numeral(dataLastSale.changepercent).value() || 0) +
      numeral(dataLastSale.changepercent).format("+0,0.00") +
      "%",
    [dataLastSale]
  );

  // data VNIndex
  var titleVNIndex = useMemo(
    () =>
      "VNIndex " +
      numeral(dataMarketindexVNIndex).format("0.00") +
      checkArrow(numeral(dataChangepercentVNIndex || 0).value() || 0) +
      numeral(dataChangepercentVNIndex || 0).format("+0,0.00") +
      "%",
    [dataMarketindexVNIndex]
  );

  useEffect(() => {
    if (titleTabBrowser === pathRoutes.stockOrder) {
      return setTitle(currentTickerStockOrder);
    }

    if (selectedTickerMarket === null) return setTitle("");

    return setTitle(selectedTickerMarket);
  }, [titleTabBrowser, currentTickerStockOrder, selectedTickerMarket]);

  useLayoutEffect(() => {
    var newTitle = title === "" ? titleVNIndex : titleLastSale;
    var iconNew = "./logoTab.svg";
    if (totalNotification > 0 && isAuthenticated) {
      iconNew = "./logoTabNoti.png";
    }
    (document.getElementById("favicon")! as HTMLAnchorElement).href = iconNew;
    document.title = title + " " + newTitle;
  }, [totalNotification, isAuthenticated, titleLastSale, titleVNIndex, title]);
  return <></>;
};

export default SetTitleRealTime;
