import { Body, Container, Title, Footer } from "./styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";

interface Props {
  visibleModalConfirm: boolean;
  onClose: () => void;
  setConfirm: () => void;
  title: string;
  content: any;
  error: boolean;
}

const ModalConfirm = (props: Props) => {
  const { visibleModalConfirm, onClose, setConfirm, title, content, error } =
    props;
  const closeModalConfirm = () => {
    onClose();
  };

  const handleConfirm = () => {
    setConfirm();
  };

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-284"
      >
        <Container>
          <Title>{title}</Title>
          <Body>
            <div>{content}</div>
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            {error && (
              <Button
                onClick={handleConfirm}
                className="flex-1"
                fill
                color="accept"
              >
                <FormattedMessage id="common.accept" />
              </Button>
            )}
          </Footer>
        </Container>
      </Modal>
    </>
  );
};

export default ModalConfirm;
