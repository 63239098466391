/* eslint-disable react-hooks/exhaustive-deps */
import { createSelector } from "@reduxjs/toolkit";
import { Button, DatePicker, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import InputSymbol from "components/commons/InputSymbol";
import { toast } from "components/commons/Toast";
import { IFindTransferSecHistoryParam } from "core/http/apis/transfer-share/types";
import { EntrustBusinessCode, GetAccountType, RANGE_DAY } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { checkDateFilter } from "helper/utils";
import { statusList } from "modules/transfer-share/constants";
import {
  changeAccountSearch,
  changeFromDateSearch,
  changeShareCodeSearch,
  changeStatusSearch,
  changeToDateSearch,
  clearDataFilterGetListParamsTransferShare,
  requestListHistoryTransferShare,
  updateCurrentPage,
} from "modules/transfer-share/redux/listHistory";
import { onLoadList } from "modules/transfer-share/redux/transferForm";
import {
  listHistoryTransferShareSelector,
  onLoadListSelector,
  searchListHistoryParamsSelector,
} from "modules/transfer-share/selectors";
import moment from "moment";
import { memo, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStateType } from "redux/types";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const loadingSelector = createSelector(
  listHistoryTransferShareSelector,
  (state) => state.status
);

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearDataFilterGetListParamsTransferShare());
    };
  }, []);

  const { subAccoNoFrom, fromDate, toDate, secCd, status } = useSelector(
    searchListHistoryParamsSelector
  );

  const loading = useSelector(loadingSelector);
  // const listAcc = GetAccoList(
  //   GetAccountType.MARGIN,
  //   EntrustBusinessCode.All
  // );
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );
  const statusOptions = statusList(intl);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    list.unshift({
      label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
      value: "",
    });
    return list;
  }, [listAcc, intl]);

  // const currentAccount = useSelector(currentAccountSelector);
  const isLoadList = useSelector(onLoadListSelector);

  useEffect(() => {
    if (!isLoadList) return;
    dispatch(onLoadList(false));
    handleSearch();
  }, [isLoadList]);

  // useEffect(() => {
  //   handleChangeAccount(currentAccount?.account || "ALL");
  // }, [currentAccount?.account]);

  const handleSearch = (
    accountSelect?: string,
    fromDateSelect?: Date,
    toDateSelect?: Date,
    statusSelect?: string,
    shareCodeSelect?: string
  ) => {
    if (parseOptionAccount.length > 0) {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const errorType = checkDateFilter(
        fromDateSelect ? fromDateSelect : fromDate ? fromDate : firstDay,
        toDateSelect ? toDateSelect : toDate ? toDate : date,
        RANGE_DAY.RANGE_30
      );
      if (errorType === "invalidFromDate") {
        toast(
          intl.formatMessage({
            id: "common.warningInvalidDay",
          }),
          "error"
        );
        return;
      }

      let statusSelected = undefined;

      if (statusSelect === undefined) {
        statusSelected = status ? +status : undefined;
      } else {
        statusSelected = statusSelect ? +statusSelect : undefined;
      }

      let secCodeSelected = undefined;

      if (shareCodeSelect === undefined) {
        secCodeSelected = secCd || undefined;
      } else {
        secCodeSelected = shareCodeSelect || undefined;
      }

      const submitData: IFindTransferSecHistoryParam = {
        subAccoNoFrom: accountSelect
          ? accountSelect
          : subAccoNoFrom !== ""
          ? subAccoNoFrom
          : parseOptionAccount[0].value,
        secCd: secCodeSelected,
        status: statusSelected,
        subAccoNoTo: null,
        fromDate: +moment(fromDateSelect ? fromDateSelect : fromDate).format(
          "YYYYMMDD"
        ),
        toDate: +moment(toDateSelect ? toDateSelect : toDate).format(
          "YYYYMMDD"
        ),
        page: 0,
        rowPerPage: 30,
      };

      dispatch(requestListHistoryTransferShare(submitData));
      dispatch(updateCurrentPage(0));

      const resLogData = {
        eventGroup: GroupEvent.securities,
        event: RealtimeConst.getDataSecuritiesHistory,
        message: JSON.stringify(submitData),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };

  const handleFromDateChange = (value: Date) => {
    dispatch(changeFromDateSearch(value));
    if (value !== null) {
      handleSearch(undefined, value);
    } else {
      toast("common.nullInputFromDate", "error");
    }
  };

  const handleToDateChange = (value: Date) => {
    dispatch(changeToDateSearch(value));

    if (value !== null) {
      handleSearch(undefined, undefined, value);
    } else {
      toast("common.nullInputToDate", "error");
    }
  };

  const handleChangeAccount = (value?: string) => {
    if (parseOptionAccount.length > 0) {
      dispatch(
        changeAccountSearch(value ? value : parseOptionAccount[0].value)
      );
      handleSearch(value);
    }
  };

  const handleChangeShareCode = (value: string) => {
    dispatch(changeShareCodeSearch(value));
    handleSearch(undefined, undefined, undefined, undefined, value);
  };

  const handleChangeStatus = (value?: string) => {
    if (statusOptions.length > 0) {
      dispatch(changeStatusSearch(value ? value : statusOptions[0].value));
      handleSearch(undefined, undefined, undefined, value);
    }
  };

  return (
    <Wrap
      id="transfer-share-filter"
      className="d-flex justify-content-between align-items-center"
    >
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "transferShare.filter.fromDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={fromDate}
            onChange={handleFromDateChange}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "transferShare.filter.toDate",
          })}
          className="mr-4"
        >
          <DatePicker selected={toDate} onChange={handleToDateChange} />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "transferShare.filter.account",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            value={parseOptionAccount.find(
              (item) => item.value === subAccoNoFrom
            )}
            options={parseOptionAccount}
            onChange={(e) => {
              handleChangeAccount(e?.value);
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "transferShare.filter.shareCode",
          })}
          className="mr-4"
        >
          <InputSymbol
            value={secCd}
            onChange={handleChangeShareCode}
            isOnlyShareCode
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "transferShare.filter.status",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            value={statusOptions.find((item) => item.value === status)}
            options={statusOptions}
            onChange={(e) => {
              handleChangeStatus(e?.value);
            }}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter"
          loading={loading === ReduxStateType.LOADING}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "transferShare.filter.search" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
