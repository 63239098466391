import styled from "styled-components/macro";
import BTable from "react-bootstrap/Table";

export const Box = styled.div<{ bordered: boolean }>`
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  ${({ bordered, theme }) =>
    bordered && `border: 1px solid ${theme.borderColor};`}
`;

export const Header = styled.thead`
  border-top-left-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  color: ${({ theme }) => theme.textcolorBlack};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  margin: 0px 4px;
  height: ${({ theme }) => theme.tables.header.height};
  background: ${({ theme }) => theme.bgElevated3};
  padding: 0px 8px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  tr {
    background: ${({ theme }) => theme.background2};
    height: ${({ theme }) => theme.tables.header.height};
    & > th {
      background: ${({ theme }) => theme.background2};
      border: none;
      padding: 4px 8px;
      vertical-align: middle;
      :first-child {
        padding-left: 16px;
      }
      white-space: nowrap;
    }
  }
`;

export const Body = styled.tbody`
  color: ${({ theme }) => theme.textcolorWhite};
  tr {
    &:hover {
      color: ${({ theme }) => theme.textcolorWhite} !important;
    }
    &:nth-child(odd) {
      background: ${({ theme }) => theme.background1};
      &:hover {
        background: ${({ theme }) => theme.brandSecondary};
      }
    }

    &:nth-child(even) {
      background: ${({ theme }) => theme.background2};
      &:hover {
        background: ${({ theme }) => theme.brandSecondary};
      }
    }
    & > td {
      vertical-align: middle;
      border: none;
      padding: none;
    }
  }
`;

export const Row = styled.tr<{ isLarge?: boolean }>`
  height: ${({ theme, isLarge }) =>
    isLarge ? theme.tables.contentLarge.height : theme.tables.content.height};
  &:hover {
    & > .last-child {
      & > div {
        & .percent {
          display: none;
        }

        & .profit {
          display: none;
        }

        & .button {
          display: flex;
        }
      }
    }
  }
  @keyframes blink-up {
    from {
      background: ${({ theme }) => theme.secondaryGreenDisable};
    }
    to {
      background: none;
    }
  }

  @keyframes blink-down {
    from {
      background: ${({ theme }) => theme.secondaryRedDisable};
    }
    to {
      background: none;
    }
  }

  .cell-blink-up {
    color: inherit;
    text-decoration: ${({ theme }) => theme.secondaryBlueDisable};
    animation-name: blink-up;
    -webkit-animation-name: blink-up;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
  }

  .cell-blink-down {
    color: inherit;
    text-decoration: ${({ theme }) => theme.secondaryBlueDisable};
    animation-name: blink-down;
    -webkit-animation-name: blink-down;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
  }
`;

export const HeaderStyle = styled.div`
  span.text {
    padding-top: 3px;
    font-size: ${({ theme }) => theme.typeFaces.caption10.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
    color: ${({ theme }) => theme.textcolorBlack};
  }
`;

export const TextHeader = styled(HeaderStyle)<{ align?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  font-size: inherit;
`;

export const NumberHeader = styled(HeaderStyle)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: inherit;
`;

export const Table = styled(BTable)<{ bordered: boolean; isRoot?: boolean }>`
  position: relative;
  ${({ bordered, theme }) =>
    bordered && `border: 1px solid ${theme.borderColor};`}
  z-index: 0;
  td {
    padding: 0 !important;
  }
  th {
    padding: ${({ isRoot }) => (isRoot ? "0 !important" : "0 4px !important")};
    :first-child {
      ${({ isRoot }) => (isRoot ? "" : "padding-left: 12px !important;")}
    }
  }
  &.table-fixed-layout {
    .market-header-table {
      tr {
        ${({ theme }) =>
          `box-shadow: inset 0px -1px 0px ${theme.gradientLinearGray}`};
        & > th {
          :first-child {
            width: 50%;
          }
        }
      }
    }
    table-layout: fixed;
  }
  &.market-table {
    .ticker-code {
      height: 20px;
    }
    tr {
      ${({ theme }) => `box-shadow: inset 0px -1px 0px ${theme.shadowInner3}`};
    }
    td {
      padding: 0 4px;
    }
    th {
      padding: 0 8px;
    }
  }
`;

export const TextColor = styled.span<{
  color?: string;
}>`
  color: ${({ color }) => (color ? color : "inherit")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
`;

export const TableCellContent = styled.div<{ color?: string; align?: string }>`
  padding: 8px;
  color: ${({ color }) => (color ? color : "inherit")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  &.market-ticker-name {
    padding: 0 12px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.full-company-name {
    height: 36px;
  }
`;

export const TableHeaderCellContent = styled.div<{
  color?: string;
  align?: string;
}>`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ color }) => (color ? color : "inherit")};
  text-transform: uppercase;
  color: ${({ theme }) => theme.textcolorBlack};
  background: ${({ theme }) => theme.bgElevated3};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  border: none;
  height: ${({ theme }) => theme.tables.header.height};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
`;

export const PriceStatusCell = styled.div<{ priceColor: number | undefined }>`
  ${({ priceColor, theme }) => getColor(priceColor, theme)};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  text-align: right;
  height: 100%;
  &.market-price-cell {
    div {
      padding: 8px;
      height: ${({ theme }) => theme.tables.content.height};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      box-sizing: border-box;
    }
  }

  &.center-for-value {
    div {
      display: inline-flex;
      align-items: center;
    }
  }

  &.small-text {
    div {
      font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    }
  }

  &.is-bold {
    div {
      font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
    }
  }
`;

export const NumberCell = styled.div<{ textColor?: number | undefined }>`
  ${({ textColor, theme }) =>
    getTextColor(textColor, theme.textcolorWhite, theme)};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  text-align: right;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding: 12px 8px;
  height: ${({ theme }) => theme.tables.content.height};
  box-sizing: border-box;
  & > * {
    color: unset;
  }
`;

export const StringCell = styled.div<{ textColor?: number | undefined }>`
  ${({ textColor, theme }) =>
    getTextColor(textColor, theme.textcolorWhite, theme)};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  text-align: left;
  display: flex;
  text-align: right;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 12px 8px;
  box-sizing: border-box;
  height: ${({ theme }) => theme.tables.content.height};
`;

export const NoDataWrapper = styled.td`
  border-top-width: 0 !important;
`;

function getTextColor(
  color: number | undefined,
  defaultColor: string,
  theme: any
) {
  switch (color) {
    case 1:
      return `color: ${theme.textColorGreen};`;
    case 0:
      return `color: ${theme.textColorYellow};`;
    case -1:
      return `color: ${theme.textColorRed};`;
    default:
      return `color: ${defaultColor};`;
  }
}

export function getColor(color: number | undefined, theme: any) {
  switch (color) {
    case 1:
      return `color: ${theme.pinkP1};`;
    case 3:
      return `color: ${theme.green50};`;
    case 4:
      return `color: ${theme.red50};`;
    case 2:
      return `color: ${theme.blue50};`;
    default:
      return `color: ${theme.yellow50};`;
  }
}
