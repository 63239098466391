import { Spacer } from "components/commons";
import { Container, VolumeAndTimeWrapper } from "./styles";
import HistoryList from "./HistoryList";
import VolumeAnalysisList from "./VolumeAnalysisList";

function VolumeAndTime() {
  return (
    <VolumeAndTimeWrapper className="volume-and-time-wrapper">
      <Container>
        <HistoryList />
        <Spacer />
        <VolumeAnalysisList />
      </Container>
    </VolumeAndTimeWrapper>
  );
}

export default VolumeAndTime;
