import HttpRightInfo from "core/http/singleton/margin-contract";
import { IRightInfoResult } from "domain/models/RightInfo";
import { IGetRightInfoParams } from "./types";
import { getListRightInfoUrl } from "./urls";

export const getListRightInfo = async (
  data: IGetRightInfoParams
): Promise<IRightInfoResult> => {
  const res = await HttpRightInfo.get<IRightInfoResult>(
    getListRightInfoUrl(
      data.secCd,
      data.rightType,
      data.ownerFixDate,
      data.rightExcDate,
      data.beginTradeDate,
      data.status
    )
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
