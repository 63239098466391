import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import StatisticPrice from "./pages/StatisticPrice";

const routes: RouterConfig[] = [
  {
    page: StatisticPrice,
    path: paths.statisticPrice,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
