import React from "react";
import { useSelector } from "react-redux";
import {
  ContentContainer,
  GroupTitle,
  LabelWrapper,
  LabelColorBox,
  LabelName,
  FinanceChartContainer,
} from "./styles";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FormattedMessage } from "react-intl";
import {
  createGradientPlugin,
  FinanceLabelOnAxisPlugin,
} from "helper/chartPlugins";
import FinanceChart from "./FinanceChart";
import { ChartOptions } from "chart.js";
import { dataChartBalanceSelector } from "modules/tickerDetails/redux/selectors";
import min from "lodash/min";
import max from "lodash/max";
import get from "lodash/get";
import { getMinTickForChart } from "modules/tickerDetails/helpers/utils";
import numeral from "numeral";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";

const GradientBgPlugin: any = createGradientPlugin("rgba(65, 188, 228, 0.2)");

// TODO
function BalanceSheet(): React.ReactElement {
  const themeType = useSelector(themeTypeSelector);

  const plugins = [
    FinanceLabelOnAxisPlugin(themes[themeType]),
    ChartDataLabels,
    GradientBgPlugin,
  ];
  const chartData = useSelector(dataChartBalanceSelector);

  const option: ChartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 90,
        top: 30,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          id: "yAxis2",
          display: false,
          ticks: {
            suggestedMin: getMinTickForChart(
              min(get(chartData, "datasets[2].data", [0])) as number
            ),
            suggestedMax: max(get(chartData, "datasets[2].data", [0])),
          },
        },
        {
          id: "yAxis1",
          display: false,
          ticks: {
            suggestedMin: getMinTickForChart(
              min(get(chartData, "datasets[0].data", [0])) as number
            ),
            suggestedMax: max(get(chartData, "datasets[0].data", [0])),
          },
        },
      ],
      xAxes: [
        {
          id: "xAxis1",
          type: "category",
          ticks: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          if (
            parseInt(tooltipItem.yLabel) > 10000 ||
            parseInt(tooltipItem.yLabel) < -10000
          ) {
            return numeral(tooltipItem.yLabel).format("0,0ab");
          } else {
            return numeral(tooltipItem.yLabel).format("0.0");
          }
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <ContentContainer>
      <h2>
        <FormattedMessage id="widgets.finance.balanceSheet.label" />
      </h2>
      <GroupTitle>
        <LabelWrapper>
          <LabelColorBox color="solidBlue" />
          <LabelName>
            <FormattedMessage id="widgets.finance.balanceSheet.totalAssets" />
          </LabelName>
        </LabelWrapper>
        <LabelWrapper>
          <LabelColorBox color="solidViolet" />
          <LabelName>
            <FormattedMessage id="widgets.finance.balanceSheet.totalLiabilities" />
          </LabelName>
        </LabelWrapper>
        <LabelWrapper>
          <LabelColorBox color="solidYellow" />
          <LabelName>
            <FormattedMessage id="widgets.finance.balanceSheet.solvencyDebtAssets" />
          </LabelName>
        </LabelWrapper>
      </GroupTitle>
      {!!chartData && (
        <FinanceChartContainer height={252}>
          <FinanceChart
            labels={chartData.labels}
            plugins={plugins}
            datasets={chartData.datasets}
            configs={option}
          />
        </FinanceChartContainer>
      )}
    </ContentContainer>
  );
}

export default BalanceSheet;
