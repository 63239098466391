/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Wrap } from "./styles";
import { Button, Select } from "components/commons";
import { useIntl } from "react-intl";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { useDispatch, useSelector } from "react-redux";
import { selectSearchHistorySubscribe } from "modules/subscribeBuy/selectors";
import {
  getDataHistorySubscribe,
  searchDataHistorySubscribe,
} from "modules/subscribeBuy/redux";

const Search = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return [
      {
        label: intl.formatMessage({
          id: "subscribe.content.historySubscribe.search.option.valueAll",
        }),
        value: "",
      },
      ...list,
    ];
  }, [listAcc]);

  const { subAccoNo, SecCd } = useSelector(selectSearchHistorySubscribe);

  const handleSearch = (subAccoNo: string, SecCd: string) => {
    const params = {
      subAccoNo: subAccoNo ? subAccoNo : accountOptions[0].value,
    };

    dispatch(getDataHistorySubscribe(params));
  };

  useEffect(() => {
    handleSearch(subAccoNo !== "" ? subAccoNo : accountOptions[0].value, SecCd);
  }, []);

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "subscribe.content.historySubscribe.search.Subacc",
          })}
          className="mr-4"
        >
          <Select
            defaultValue={accountOptions[0]}
            options={accountOptions}
            onChange={(e: any) => {
              dispatch(
                searchDataHistorySubscribe({
                  value: e?.value,
                  key: "subAccoNo",
                })
              );
            }}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter"
          style={{ marginLeft: "0px" }}
          // loading={loading}
          onClick={() => handleSearch(subAccoNo, SecCd)}
        >
          {intl.formatMessage({
            id: "transferInternal.historyTransfer.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
};

export default Search;
