import { FooterWatchList } from "./style";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { WatchlistGroupType } from "helper/consts";
import { tickersListSelectedSelector } from "modules/categories/redux/selectors";

function TableFooter() {
  const { type } = useSelector(tickersListSelectedSelector);
  return (
    <FooterWatchList>
      {type === WatchlistGroupType.ODDLOT ? (
        <FormattedMessage id="category.table.footerOdd" />
      ) : (
        <FormattedMessage id="category.table.footer" />
      )}
    </FooterWatchList>
  );
}

export default TableFooter;
