export const FLEX = {
  START: "flex-start",
  CENTER: "center",
  END: "flex-end",
};

export enum SORT_BY {
  TICKER = 1, // 1:Mã CK, 2: khoi luong 3:Giá tri, 4:Gia khớp, 5: change point, 6: change_percent, 7: high_low
  TOP_VALUE_MASS = 2,
  TOP_VALUE = 3,
  PRICE = 4,
  CHANGE_PRICE = 5,
  CHANGE_PRICE_PERCENTAGE = 6,
  HIGH_LOW = 7,
}

export const COLUMNS_TOP_VOLUME = [
  {
    width: 35,
    name: "widgets.topStatistical.table.stockId",
    position: "START",
    sortBy: SORT_BY.TICKER,
  },
  {
    width: 20,
    name: "widgets.topStatistical.table.topValueMass",
    position: "END",
    sortBy: SORT_BY.TOP_VALUE_MASS,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGE_PRICE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGE_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_TOP_VALUE = [
  {
    width: 35,
    name: "widgets.topStatistical.table.stockId",
    position: "START",
    sortBy: SORT_BY.TICKER,
  },
  {
    width: 20,
    name: "widgets.topStatistical.table.topValue",
    position: "END",
    sortBy: SORT_BY.TOP_VALUE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGE_PRICE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGE_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_TOP_CHANGE = [
  {
    width: 35,
    name: "widgets.topStatistical.table.stockId",
    position: "START",
    sortBy: SORT_BY.TICKER,
  },
  {
    width: 20,
    name: "widgets.topStatistical.table.changeHighLow",
    position: "END",
    sortBy: SORT_BY.HIGH_LOW,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGE_PRICE,
  },
  {
    width: 15,
    name: "widgets.topStatistical.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGE_PRICE_PERCENTAGE,
  },
];

export const TIME_BLINK = 600;
