import { all } from "redux-saga/effects";
import authSaga from "modules/auth/saga";
import categoriesSaga from "modules/categories/saga";
import stockOrderSaga from "modules/stock-order/saga";
import stockOrderDerSaga from "modules/stock-order-der/saga";
import marketSaga from "modules/market/saga";
import systemSaga from "modules/system/saga";
import marginContractSaga from "modules/margin-contract/saga";
import marginSecuritiesSaga from "modules/margin-securities/saga";
import rightInfoSaga from "modules/right-info/saga";
import statisticOndaySaga from "modules/statistic-onday/saga";
import statisticPriceSaga from "modules/statistic-price/saga";
import statisticUpdownSaga from "modules/statistic-updown/saga";
import statisticHighestUpdownSaga from "modules/statistic-highest-updown/saga";
import mrgContractDebitSaga from "modules/mrg-contract-debit/saga";
import marginContractWatchSaga from "modules/margin-contract-watch/saga";
import transferLocalSaga from "modules/transfer-local/saga";
import orderHistorySaga from "modules/order-history/saga";
import profitLossSaga from "modules/profit-loss/saga";
import advanceLimitSaga from "modules/advance/saga";
import holdUnholdBankAccountSaga from "modules/hold-unhold-bank-account/saga";
import screenersSaga from "modules/screeners/saga";
import transferShareSaga from "modules/transfer-share/saga";
import createContractSaga from "modules/create-contract/saga";
import transferInternalSaga from "modules/transfer-internal/saga";
import depositContractSaga from "modules/deposit-contract/saga";
import getListMoneyStatementSaga from "modules/account-statement/saga";
import getListMarginSecuritiesPortfolioSaga from "modules/margin-securities-portfolio/saga";
import depositPowerSaga from "modules/deposit-power/saga";
import getListPositionManagementSaga from "modules/position-management/saga";
import bidAskBondOrderSaga from "modules/bid-ask-bond-order/saga";
import SubscribeBuySaga from "modules/subscribeBuy/saga";
import getListDepositSaga from "modules/deposit-list/saga";
import addFrameworkContractFormSaga from "modules/add-framework-constract-form/saga";
import transferBondSaga from "modules/transfer-bond/saga";
import bidAskBondSaga from "modules/bid-ask-bond/saga";
import orderBookSaga from "modules/order-book/saga";
import registerToTransferBondsSaga from "modules/register-to-transfer-bonds/saga";
import placeOrderTransferBondsSaga from "modules/place-order-transfer-bonds/saga";
import changeLimitSaga from "modules/change-limit/saga";
import contractDebitSaga from "modules/contract-debit/saga";
import advanceAutoSaga from "modules/auth/pages/ModalSetting/MenuItem/AdvanceAuto/saga";
import professionalInvestorSaga from "modules/professional-investor/saga";
import placeOrderRetailBondSaga from "modules/place-order-retail-bonds/saga";
import bidAskDBondOrderSaga from "modules/bid-ask-d-bond-order/saga";
import placeOrderBondAdvSaga from "modules/placeOrderBondAdv/saga";
import guideVideoSaga from "modules/guideVideo/saga";
import tickerDetailsSaga from "modules/tickerDetails/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    categoriesSaga(),
    stockOrderSaga(),
    stockOrderDerSaga(),
    orderBookSaga(),
    marketSaga(),
    systemSaga(),
    marginContractSaga(),
    marginSecuritiesSaga(),
    rightInfoSaga(),
    statisticOndaySaga(),
    statisticPriceSaga(),
    statisticUpdownSaga(),
    statisticHighestUpdownSaga(),
    mrgContractDebitSaga(),
    marginContractWatchSaga(),
    transferLocalSaga(),
    orderHistorySaga(),
    profitLossSaga(),
    advanceLimitSaga(),
    holdUnholdBankAccountSaga(),
    screenersSaga(),
    transferShareSaga(),
    contractDebitSaga(),
    getListMoneyStatementSaga(),
    createContractSaga(),
    transferInternalSaga(),
    depositContractSaga(),
    getListMarginSecuritiesPortfolioSaga(),
    getListPositionManagementSaga(),
    depositPowerSaga(),
    bidAskBondOrderSaga(),
    SubscribeBuySaga(),
    getListDepositSaga(),
    addFrameworkContractFormSaga(),
    transferBondSaga(),
    bidAskBondSaga(),
    registerToTransferBondsSaga(),
    placeOrderTransferBondsSaga(),
    changeLimitSaga(),
    advanceAutoSaga(),
    professionalInvestorSaga(),
    placeOrderRetailBondSaga(),
    bidAskDBondOrderSaga(),
    placeOrderBondAdvSaga(),
    guideVideoSaga(),
    tickerDetailsSaga(),
  ]);
}
