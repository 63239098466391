import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeInfoParams } from "core/http/apis/account-info/types";

export type ModalEditGeneralInfoState = {
  visibleModalEditGeneralInfo: boolean;
  visibleModalFaceVerify: boolean;
  changeInfoParams: ChangeInfoParams;
};

const initialState: ModalEditGeneralInfoState = {
  visibleModalEditGeneralInfo: false,
  visibleModalFaceVerify: false,
  changeInfoParams: {
    custodycd: "",
    requestid: "",
    fullname: "",
    idcode: "",
    iddate: "",
    idexpired: "",
    idplace: "",
    address: "",
  },
};

const modalEditGeneralInfoSlide = createSlice({
  name: "modalEditGeneralInfo",
  initialState,
  reducers: {
    setVisibleModalEditGeneralInfo: (state, action: PayloadAction<boolean>) => {
      state.visibleModalEditGeneralInfo = action.payload;
    },
    updateChangeInfoParams: (state, action: PayloadAction<object>) => {
      state.changeInfoParams =
        { ...state.changeInfoParams, ...action.payload } || {};
    },
    changeGeneralInfoAccount: (
      state,
      action: PayloadAction<ChangeInfoParams>
    ) => {},
    setVisibleModalFaceVerify: (state, action: PayloadAction<boolean>) => {
      state.visibleModalFaceVerify = action.payload;
    },
    startFaceVerify: (state, action: PayloadAction<File>) => {},
  },
});

export const {
  setVisibleModalEditGeneralInfo,
  updateChangeInfoParams,
  changeGeneralInfoAccount,
  setVisibleModalFaceVerify,
  startFaceVerify,
} = modalEditGeneralInfoSlide.actions;

export default modalEditGeneralInfoSlide.reducer;
