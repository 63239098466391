/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, Input, Select, InputTextarea } from "components/commons";
// import numeral from "numeral";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import storage from "helper/storage";
import {
  BodyModal,
  BodyModalRow,
  ButtonCell,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  ActionsRow,
} from "./styles";
import { useEffect, useState } from "react";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import {
  getlistBank,
  getlistBranch,
  getlistLocation,
  postAccount,
} from "core/http/apis/transfer-internal";
import { toast } from "components/commons/Toast";
import { accountInfoSelector } from "modules/auth/selectors";
import { DetailInputWrapper } from "../styles";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { GetParameters } from "helper/sessionData";

interface PROPS {
  isVisible: boolean;
  onClose: () => void;
  getListAccounSubto: () => void;
}

const ModaladdAccount = (props: PROPS) => {
  const { isVisible, onClose, getListAccounSubto } = props;
  // const dispatch = useDispatch();
  const intl = useIntl();

  // const isVisible = useSelector(statusModal);

  const closeModalHandler = () => {
    onClose();
    // dispatch(closeModal());
  };
  const enterBank = GetParameters("CU00", "CU_ALLOW_ENTER_BANK_BRANCH");

  const { currentUser } = useSelector(accountInfoSelector);

  const [optionBank, setOptionBank] = useState([]);
  const [optionLocation, setOptionLocation] = useState([]);
  const [optionBranch, setOptionBranch] = useState([]);
  const [isVisibleOtp, setisVisibleOtp] = useState<boolean>(false);

  const [form, setForm] = useState<any>({
    custCd: storage.getMasterAccount(),
    bankCd: null,
    bankName: "",
    bankLocation: null,
    accoName: currentUser?.displayName,
    accoNumber: 0,
    isOwner: 1,
    srcChannel: 2,
    address: "",
    otpCode: null,
  });

  const changeForm = (value: any, key: any) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const getlistBankApi = async () => {
    try {
      const res: any = await getlistBank();
      if (res?.statusCode === 0) {
        const data = res?.data?.map((e: any) => ({
          label: e?.bankName,
          value: e?.bankCd,
        }));
        setOptionBank(data);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      toast(error?.code, "error", error?.description, true);
    }
  };

  const getlistLocationApi = async () => {
    try {
      const res: any = await getlistLocation();

      if (res?.statusCode === 0) {
        const datas = res?.data?.map((e: any) => ({
          label: e?.codeValue1,
          value: e?.code,
        }));
        setOptionLocation(datas);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      toast(error?.code, "error", error?.description, true);
    }
  };

  const getlistBranchApi = async (valueBankLocation: any, valueBankcd: any) => {
    try {
      const res: any = await getlistBranch({
        bankCd: valueBankcd,
        locationCd: valueBankLocation,
      });
      if (res?.statusCode === 0) {
        const datas = await res?.data?.map((e: any) => ({
          label: e?.bankBranchName,
          value: e?.bankCd,
        }));
        setOptionBranch(datas);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      toast(error?.code, "error", error?.description, true);
    }
  };

  useEffect(() => {
    getlistBankApi();
    getlistLocationApi();
    // getlistBranchApi();
  }, []);
  const [endSlice, setendSlice] = useState(30);
  const [error, setError] = useState<string | undefined>("");

  const checkError = () => {
    const value = Object.keys(form).find(
      (key: any) => form[key] === "" || form[key] === 0 || form[key] === null
    );
    if (value !== "otpCode") {
      setError(value);
    } else {
      setError("");
    }
  };

  const submitForm = async (response: IVerifyResponse) => {
    await checkError();
    if (error === "") {
      try {
        const res: any = await postAccount({
          ...form,
          otpCode: response?.otp ?? "",
          otpType: response?.otpTypeSubmit!,
        });
        if (res?.statusCode === 0) {
          getListAccounSubto();
          closeModalHandler();
          toast(
            intl.formatMessage({
              id: "transferInternal.historyTransferBank.modalAddAcount.messageSuccess",
            }),
            "success"
          );
        } else {
          toast(res?.errorCode, "error", res?.message, true);
        }
      } catch (error: any) {
        toast(error?.code, "error", error?.description, true);
      }
    }
  };

  const valueBankSelect = () => {
    const index = optionBranch.findIndex(
      (e: any) => e?.label === form?.bankName
    );
    if (index === -1) {
      if (enterBank === "true") {
        return { label: form?.bankName, value: form?.bankName };
      } else {
        return null;
      }
    } else {
      return optionBranch[index];
    }
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.nameAccount" />
            </LabelCell>
            <TextCell>{currentUser?.displayName}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.numberAccouny" />
            </LabelCell>
            <DetailInputWrapper className="text-left">
              <Input
                // placeholder={intl.formatMessage({
                //   id: "transferInternal.historyTransferBank.detail.amount",
                // })}
                // rules={{ required: true }}
                type="number"
                onChange={(e) =>
                  changeForm(Number(e.target.value), "accoNumber")
                }
              />
              {error === "accoNumber" && (
                <span className="text-error">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyAccoNumber",
                  })}
                </span>
              )}
            </DetailInputWrapper>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.bank" />
            </LabelCell>
            <DetailInputWrapper className="text-left">
              <Select
                options={optionBank}
                onChange={(e) => {
                  changeForm(e?.value, "bankCd");
                  getlistBranchApi(form?.bankLocation, e?.value);
                }}
              />
              {error === "bankCd" && (
                <span className="text-error">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                  })}
                </span>
              )}
            </DetailInputWrapper>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.country" />
            </LabelCell>
            <DetailInputWrapper className="text-left">
              <Select
                options={optionLocation}
                onChange={(e) => {
                  changeForm(e?.value, "bankLocation");
                  getlistBranchApi(e?.value, form?.bankCd);
                }}
              />
              {error === "bankLocation" && (
                <span className="text-error">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyCountry",
                  })}
                </span>
              )}
            </DetailInputWrapper>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.branch" />
            </LabelCell>
            <DetailInputWrapper
              className="text-left"
              onWheel={() => setendSlice(endSlice + 10)}
            >
              <Select
                value={valueBankSelect()}
                options={!!optionBranch ? optionBranch.slice(0, endSlice) : []}
                onChange={(e) => {
                  // changeForm(e?.value, "address");
                  changeForm(e?.label, "bankName");
                }}
                onKeyDown={(e: any) => {
                  if (enterBank === "true") {
                    changeForm(e.target.value, "bankName");
                  }
                }}
              />
              {error === "bankName" && (
                <span className="text-error">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyBranch",
                  })}
                </span>
              )}
            </DetailInputWrapper>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modalAddAcount.address" />
            </LabelCell>
            <DetailInputWrapper className="text-left">
              <InputTextarea
                textArea={true}
                onChange={(e) => changeForm(e.target.value, "address")}
              />
              {error === "address" && (
                <span className="text-error">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyAddress",
                  })}
                </span>
              )}
            </DetailInputWrapper>
          </BodyModalRow>

          <ActionsRow>
            <ButtonCell>
              <Button color="accept" onClick={closeModalHandler} width="100%">
                <FormattedMessage id="transferLocal.confirmModal.cancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                color="gradientaAccept"
                onClick={() => setisVisibleOtp(true)}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.accept" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
      {isVisibleOtp && (
        <OTPByBusinessCd
          handleClose={() => setisVisibleOtp(false)}
          getResult={submitForm}
          businessCd={EBusinessCd.TRANSFER}
        />
      )}
    </Modal>
  );
};

export default ModaladdAccount;
