import { dataColumns } from "./constants";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { Container } from "./styles";
import { useSelector } from "react-redux";
import { selectDatasearchSubscribe } from "modules/subscribeBuy/selectors";
// import { FormattedMessage, useIntl } from "react-intl";

// import { FormattedMessage } from "react-intl";

const TableData = () => {
  const fetchMoreData = () => {};
  const data = useSelector(selectDatasearchSubscribe);
  return (
    <>
      <Container id="transferLocalTable">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={dataColumns}
          data={data}
          enableInfinity={true}
          scrollableTarget={"transferLocalTable"}
          isHasFooter={false}
        />
      </Container>
    </>
  );
};

export default TableData;
