import { Button } from "components/commons";
import storage from "helper/storage";
import {
  getListDeviceRequest,
  refreshDeviceRequest,
} from "modules/auth/redux/managerDevice";
import { loadingSelector } from "modules/auth/selectors";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Container, ContentTop, Label } from "./styles";
import Table from "./Table";

function ManageDevice() {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);

  const intl = useIntl();
  const accountNo = storage.getMasterAccount();

  const handleRefresh = () => {
    dispatch(
      refreshDeviceRequest({
        masterAccount: accountNo,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getListDeviceRequest({
        masterAccount: accountNo,
      })
    );
  }, [accountNo]);

  return (
    <Container>
      <ContentTop>
        <Label>
          <FormattedMessage id="auth.managerDevice.title" />
        </Label>
        <Button
          color="accept"
          className="button-filter m-1"
          onClick={handleRefresh}
          loading={loading}
          type={"submit"}
        >
          {intl.formatMessage({
            id: "auth.managerDevice.titleBtn",
          })}
        </Button>
      </ContentTop>
      <Table />
    </Container>
  );
}

export default ManageDevice;
