import styled from "styled-components";

export const Wrap = styled.div`
  height: calc(100% - 30px);
  .button-filter {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
    @media (min-width: 1800px) {
      margin-left: auto;
    }
  }
`;
