import { FC } from "react";

import BidAskBondOrderContent from "./components/BidAskBondOrderContent/index";

import { Container } from "./styles";

const BidAskBondOrder: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <BidAskBondOrderContent />
    </Container>
  );
};

export default BidAskBondOrder;
