import styled from "styled-components/macro";

export const Container = styled.div<{
  isVisibleSideCard: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const ContentLeft = styled.div<{
  isVisibleSideCard?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const ContentRight = styled.div<{
  isVisibleSideCard?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: 80%;
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.background1};
`;
export const ContentRight1 = styled.div<{
  isVisibleSideCard?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;

export const LabelCell = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background3};
  color: ${({ theme }) => theme.grey0};

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  align-items: center;
  z-index: 4;
  height: 40px;
  padding: 8px 12px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.background1};
    }
  }
`;
