import React, {
  useEffect,
  useRef,
  useCallback,
  FC,
  memo,
  useState,
} from "react";
import { TextItem } from "./style";
import { useOutsideClick } from "helper/cusHooks";
import { getMarketLabel } from "helper/utils";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
interface Props {
  data: MrktSecInfoItem.AsObject;
  focus: any;
  index: number;
  setFocus: any;
  setVisibleTextSearch: (value: boolean) => void;
  setTextSearch: (value: string) => void;
  setIsChangeArrow: (value: boolean) => void;
  setIsClickOutSide: (value: boolean) => void;
  isOnlyShareCode?: boolean;
  onChange?: (value: string) => void;
}

const SelectItem: FC<Props> = memo(
  ({
    data,
    focus,
    index,
    setFocus,
    setVisibleTextSearch,
    setTextSearch,
    setIsChangeArrow,
    setIsClickOutSide,
    isOnlyShareCode,
    onChange,
  }) => {
    const ref = useRef<HTMLLIElement>(null);
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
      if (focus) {
        setIsFocus(true);
        ref.current?.focus();
      }
    }, [focus]);

    const handleSelect = useCallback(() => {
      setFocus(index);
    }, [data, index, setFocus]);

    const handleKeyDown = (e: any, data: any) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.stopPropagation();
        // unSubscribeTicker(currentTicker);
        setVisibleTextSearch(false);
        setTextSearch("");
        setIsChangeArrow(false);
        onChange?.(data.seccd);
      }
    };

    useOutsideClick(ref, () => {
      if (isFocus) {
        setIsClickOutSide(true);
        setTimeout(() => setIsChangeArrow(false), 150);
      }
    });

    return (
      <TextItem
        tabIndex={focus ? 0 : -1}
        ref={ref}
        className="ticker-item"
        key={data.seccd}
        onMouseDown={(e) => {
          e.stopPropagation();
          setVisibleTextSearch(false);
          setTextSearch("");
          onChange?.(data.seccd);
        }}
        onKeyPress={handleSelect}
        onKeyDown={(e) => handleKeyDown(e, data)}
        onMouseEnter={() => setIsChangeArrow(false)}
      >
        {isOnlyShareCode ? (
          <div>
            <span className="share-code">{data.seccd} </span>
          </div>
        ) : (
          <>
            <div>
              <span className="share-code">{data.seccd} </span>
              {data.secname !== "" || data.secnameen !== "" ? " - " : ""}
              <span>{data.secname || data.secnameen}</span>
            </div>
            <div>{getMarketLabel(data.marketcd)}</div>
          </>
        )}
      </TextItem>
    );
  }
);

export default SelectItem;
