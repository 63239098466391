import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { FinanceState } from "./financeInfo";
import { NewsInfoState } from "./newsInfo";
import { OgrInfoState } from "./orgInfo";
import { InfoQuoteState } from "./priceList";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { TickerInfoState } from "./tickerInfo";
import { State as CategoriesRootState } from "modules/tickerDetails/redux/index";
// TODO
// export const tickerDetailsSelector = (state: RootState) => state.tickerDetails;

// Chi tiet ma -- start --
export const tickerDetailsRootSelector = (state: RootState) =>
  state.tickerDetails.root;

export const activePriceTagSelector = createSelector(
  tickerDetailsRootSelector,
  (state: CategoriesRootState) => state.activePriceTag
);

export const widgetTickerDetailNameSelector = createSelector(
  tickerDetailsRootSelector,
  (state: CategoriesRootState) => state.widgetTickerDetailName
);

export const widgetTickerDetailDerNameSelector = createSelector(
  tickerDetailsRootSelector,
  (state: CategoriesRootState) => state.widgetTickerDetailDerName
);

export const widgetTickerDetailCWNameSelector = createSelector(
  tickerDetailsRootSelector,
  (state: CategoriesRootState) => state.widgetTickerDetailCWName
);
// Chi tiet ma -- end --

// Thong tin ma -- start --
export const tickerInfoSelector = (state: RootState) =>
  state.tickerDetails.tickerInfo;

export const tickerInfoWidgetSelector = createSelector(
  tickerInfoSelector,
  (state: TickerInfoState) => state.organizationFundamental
);

export const currentRoomSelector = createSelector(
  tickerInfoSelector,
  (state: TickerInfoState) => state.organizationFundamental?.currentroom
);
// Thong tin ma -- end --

// export const tickerChartSelector = (state: RootState) =>
//   state.tickerDetails.root.chartDataTicker;

// Thong tin doanh nghiep -- start --
export const orgInfoSelector = (state: RootState) =>
  state.tickerDetails.orgInfo;

export const chartDataStakeHolderSelector = createSelector(
  orgInfoSelector,
  (state: OgrInfoState) => state.chartDataStakeHolder
);

export const chartDataOwnPercentSelector = createSelector(
  orgInfoSelector,
  (state: OgrInfoState) => state.chartDataOwnPercent
);
// Thong tin doanh nghiep -- end --

// Tin tuc -- start --
export const newsInfoSelector = (state: RootState) =>
  state.tickerDetails.newsInfo;

export const newsListSelector = createSelector(
  newsInfoSelector,
  (state: NewsInfoState) => state.newsList
);

export const newsItemDetailSelectedSelector = createSelector(
  newsInfoSelector,
  (state: NewsInfoState) => state.newsItemDetailSelected
);

export const newsItemDetailLoadingSelector = createSelector(
  newsInfoSelector,
  (state: NewsInfoState) => state.loading
);

export const newIdSelector = createSelector(
  newsInfoSelector,
  (state: NewsInfoState) => state.newId
);

export const getNewsParamsSelector = createSelector(
  newsInfoSelector,
  (state: NewsInfoState) => state.getNewsParams
);

export const enableInfinitySelector = createSelector(
  newsInfoSelector,
  (state: NewsInfoState) => state.enableInfinity
);

// Tin tuc -- end --

// Tai chinh -- start --

export const financeInfoSelector = (state: RootState) =>
  state.tickerDetails.financeInfo;

export const timeTypeFinanceInfoSelector = (state: RootState) =>
  state.tickerDetails.financeInfo.timeType;

export const dataChartMainIndicatorSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.dataChartMainIndicator
);

export const indicatorTypeSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.indicatorType
);

export const dataChartRevenueSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.dataChartRevenue
);

export const revenueTypeSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.revenueType
);

export const dataChartCashFlowSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.dataChartCashFlow
);

export const cashFlowTypeSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.cashFlowType
);

export const dataChartBalanceSelector = createSelector(
  financeInfoSelector,
  (state: FinanceState) => state.dataChartBalance
);

// Tai chinh -- end --

// Chi so VN30 -- start --
export const statBasketContributionSelector = (state: RootState) =>
  state.tickerDetails.vn30StatBasketContribution;
// Chi so VN30 -- end --

// Bang gia -- start --
export const priceListSelector = (state: RootState) =>
  state.tickerDetails.priceList;

export const sumAskSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.sumAsk
);

export const sumBidSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.sumBid
);

export const percentBuySelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.percentBuy
);

export const dataDetailInfoSelector = (
  value: keyof SecQuotesDetailResponse.SecDetailInfo.AsObject
) =>
  createSelector(
    priceListSelector,
    (state: InfoQuoteState) => state.detailInfo[value]
  );

export const basicpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.basicprice
);

export const ceilingpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.ceilingprice
);

export const floorpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.floorprice
);
// Bang gia -- end --
