import { FC } from "react";
import Tabs from "./components/Tabs";
import MarginContractContent from "./components/MarginContractContent";
import MarginAccountInfo from "./components/MarginAccountInfo";
import { Container } from "./styles";

const MarginContract: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <Tabs />
      <MarginAccountInfo />
      <MarginContractContent />
    </Container>
  );
};

export default MarginContract;
