import { all, takeEvery } from "redux-saga/effects";
import { onChangeCurrentTicker } from "../redux";
import { RealtimeConst } from "helper/constFirebase";

import Storage from "helper/storage";

function onChangeCurrentTickerLogDataWorker(
  action: ReturnType<typeof onChangeCurrentTicker>
) {
  const resLogData = {
    event: RealtimeConst.changeSecCD,
    message: action.payload,
  };
  Storage.orderFirebase(resLogData);
}

function* onChangeCurrentTickerLogDataWatcher() {
  yield takeEvery(
    onChangeCurrentTicker.type,
    onChangeCurrentTickerLogDataWorker
  );
}

export default function* logTrackingSaga() {
  yield all([onChangeCurrentTickerLogDataWatcher()]);
}
