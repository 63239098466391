import { Select, Button, DatePicker, Input } from "components/commons";
import { memo, useEffect, useMemo } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  findDertRequestCondRequest,
  updateFilterDer,
  findDertOrderByFilterRequest,
} from "modules/order-history/redux";
import {
  loadingSearchDataDerSelector,
  filterOrderHistoryDerSelector,
  selectLimit,
} from "modules/order-history/selectors";
import {
  IFindDertRequestCondParams,
  IFindDertOrderByFilterParams,
} from "core/http/apis/order-history/types";

import moment from "moment";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
import { SrcChannel } from "helper/consts";

const orderOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.type.normalOrder",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.type.conditionalOrder",
    }),
  },
];
const condOption = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.all",
    }),
  },
  {
    value: "SO",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.SO",
    }),
  },
  {
    value: "UO",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.UO",
    }),
  },
  {
    value: "DO",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.DO",
    }),
  },
  {
    value: "CL",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.CL",
    }),
  },
  {
    value: "SP",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.SP",
    }),
  },
  {
    value: "OC",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.OC",
    }),
  },
  {
    value: "BB",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.BB",
    }),
  },
  {
    value: "TS",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.condList.TS",
    }),
  },
];
const tradeOption = (intl: IntlShape, orderType: any) => [
  {
    value: orderType === "2" ? "-1" : "0",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.tradeList.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.tradeList.short",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.tradeList.long",
    }),
  },
];
const statusOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.rejected",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.entered",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.waitApprove",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.partialMatch",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.fullMatch",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.partialCancel",
    }),
  },
  {
    value: "7",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.cancel",
    }),
  },
  {
    value: "8",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusList.rejectedGkck",
    }),
  },
];
const statusCondOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusCondList.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusCondList.waitActive",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusCondList.end",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusCondList.expire",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "orderHistory.orderHistoryDerTab.filter.statusCondList.cancel",
    }),
  },
];
function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const {
    fromDate,
    toDate,
    secCd,
    orderType,
    status,
    tradeType,
    condType,
    statusCond,
  } = useSelector(filterOrderHistoryDerSelector);

  // const listAcc = GetAccoList(
  //   GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
  //   EntrustBusinessCode.All
  // );

  const limit = useSelector(selectLimit);
  const loading = useSelector(loadingSearchDataDerSelector);

  // const parseOptionAccount = useMemo(() => {
  //   let list = [];
  //   list = listAcc.map((account: any) => ({
  //     label: account?.subAccoNo!,
  //     value: account?.subAccoNo!,
  //   }));

  //   return list;
  // }, [listAcc, intl]);
  const orderOptions = useMemo(() => orderOption(intl), [intl]);
  const tradeOptions = useMemo(
    () => tradeOption(intl, orderType),
    [intl, orderType]
  );

  const statusOptions = useMemo(() => statusOption(intl), [intl]);

  const statusCondOptions = useMemo(() => statusCondOption(intl), [intl]);
  const condOptions = useMemo(() => condOption(intl), [intl]);
  useEffect(() => {
    dispatch(
      updateFilterDer({
        key: "orderType",
        value: orderOptions ? orderOptions[0].value : "",
      })
    );

    // dispatch(
    //   updateFilterDer({
    //     key: "subAccoNo",
    //     value: parseOptionAccount ? parseOptionAccount[0].value : "",
    //   })
    // );

    dispatch(
      updateFilterDer({
        key: "tradeType",
        value: tradeOptions ? +tradeOptions[0].value : 0,
      })
    );
    dispatch(
      updateFilterDer({
        key: "status",
        value: statusOptions ? +statusOptions[0].value : 0,
      })
    );
    dispatch(
      updateFilterDer({
        key: "condType",
        value: condOptions ? condOptions[0].value : "",
      })
    );
    dispatch(
      updateFilterDer({
        key: "statusCond",
        value: statusCondOptions ? +statusCondOptions[0].value : 0,
      })
    );
  }, []);
  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      return toast("common.warningInvalidDay", "error");
    }

    // console.log(fromDate, toDate, subAccoNo, secCd,orderType)
    if (orderType === "1") {
      const submitData: IFindDertOrderByFilterParams = {
        branchCd: "",
        transactionCd: "",

        tradeType: tradeType ? tradeType : 0,

        orderType: "",
        secCd: secCd ? secCd : "",
        brokerCd: "",
        custNo: "",

        status: status !== null && status !== undefined ? status : 0,

        regUserId: "",
        ordChanel: SrcChannel.ALL,

        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        page: 0,
        rowPerPage: limit,
      };
      dispatch(findDertOrderByFilterRequest(submitData));

      const resLogData = {
        eventGroup: GroupEvent.order,
        event: RealtimeConst.getDerOrderHistory,
        message: JSON.stringify(submitData),
      };

      storage.commonPushLogFirebase(resLogData);
    } else if (orderType === "2") {
      const submitData: IFindDertRequestCondParams = {
        custNo: "",
        tradeType: tradeType ? (tradeType === 0 ? -1 : tradeType) : -1,
        secCd: secCd ? secCd : "",
        condType: condType ? condType : "",

        regUserId: "",
        status:
          statusCond !== null && statusCond !== undefined ? statusCond : 0,
        ordChanel: null,
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        // page: 0,
        // rowPerPage: 30,
      };
      dispatch(findDertRequestCondRequest(submitData));

      const resLogData = {
        eventGroup: GroupEvent.order,
        event: RealtimeConst.getDerOrderHistory,
        message: JSON.stringify(submitData),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };

  // effect

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center shadow-none">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.orderHistoryDerTab.filter.orderType",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={orderOptions[0]}
              options={orderOptions}
              onChange={(e) => {
                dispatch(
                  updateFilterDer({
                    key: "orderType",
                    value: e?.value ? e.value : "",
                  })
                );
              }}
            />
          </div>
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.orderHistoryDerTab.filter.fromDate",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <DatePicker
              selected={fromDate}
              onChange={(e: any) => {
                dispatch(
                  updateFilterDer({
                    key: "fromDate",
                    value: e,
                  })
                );
              }}
              popperPlacement="bottom-start"
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.orderHistoryDerTab.filter.toDate",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <DatePicker
              selected={toDate}
              onChange={(e) =>
                dispatch(
                  updateFilterDer({
                    key: "toDate",
                    value: e,
                  })
                )
              }
              popperPlacement="bottom-start"
            />
          </div>
        </FilterItem>

        {/* <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.account",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={parseOptionAccount[0]}
            options={parseOptionAccount}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "subAccoNo",
                  value: e?.value ? e.value : "",
                })
              );
            }}
          />
        </FilterItem> */}
        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.orderHistoryDerTab.filter.ticker",
          })}
          className="mr-4"
        >
          <div className="text-white">
            {/* <InputSymbol
                value={secCd}
                onChange={(e) => {
                  dispatch(
                    updateFilterDer({
                      key: "secCd",
                      value: e ? e : "",
                    })
                  );
                }}
                isOnlyShareCode
                isDerivatives
              /> */}
            <Input
              value={secCd ? secCd : ""}
              placeholder={intl.formatMessage({
                id: "orderHistory.orderHistoryDerTab.filter.placeTicker",
              })}
              onChange={(e) => {
                dispatch(
                  updateFilterDer({
                    key: "secCd",
                    value: e?.target?.value
                      ? e?.target?.value.toLocaleUpperCase()
                      : "",
                  })
                );
              }}
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.orderHistoryDerTab.filter.trade",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={tradeOptions[0]}
              options={tradeOptions}
              onChange={(e) => {
                dispatch(
                  updateFilterDer({
                    key: "tradeType",
                    value: e?.value ? +e.value : 0,
                  })
                );
              }}
            />
          </div>
        </FilterItem>

        {orderType === "2" && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "orderHistory.orderHistoryDerTab.filter.condType",
            })}
            className="mr-4"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={condOptions[0]}
                options={condOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilterDer({
                      key: "condType",
                      value: e?.value ? e.value : "",
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
        )}
        {orderType === "1" && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "orderHistory.orderHistoryDerTab.filter.status",
            })}
            className="mr-4"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={statusOptions[0]}
                options={statusOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilterDer({
                      key: "status",
                      value: e?.value ? +e.value : 0,
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
        )}
        {orderType === "2" && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "orderHistory.orderHistoryDerTab.filter.status",
            })}
            className="mr-4"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={statusCondOptions[0]}
                options={statusCondOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilterDer({
                      key: "statusCond",
                      value: e?.value ? +e.value : 0,
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
        )}

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "orderHistory.orderHistoryDerTab.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
