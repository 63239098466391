import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MarketCapDataNew } from "components/widgets/generals/Capitalization/types";

export type CapitalizationState = {
  marketCapData: MarketCapDataNew[];
  loading: boolean;
  error: string | undefined;
};

const initialState: CapitalizationState = {
  marketCapData: [],
  loading: false,
  error: undefined,
};

const topIndexIndustrySlice = createSlice({
  name: "topIndexIndustrySlice",
  initialState,
  reducers: {
    fetchTopIndexIndustry: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    fetchCapitalizationSuccess: (
      state,
      action: PayloadAction<MarketCapDataNew[]>
    ) => {
      state.loading = false;
      state.marketCapData = action.payload;
    },
    fetchCapitalizationFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchTopIndexIndustry,
  fetchCapitalizationSuccess,
  fetchCapitalizationFailure,
} = topIndexIndustrySlice.actions;

export default topIndexIndustrySlice.reducer;
