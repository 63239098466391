import ChartComponent from "react-chartjs-2";
import { ChartDataSets, ChartTooltipModel } from "chart.js";
import render, { div } from "helper/domBuilder";
import { createElementFromHTML, updateDomStyle } from "helper/domBuilder/utils";
import { getChartHtmlTooltipPosition } from "helper/utils";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";

export type TreemapChartDataset = ChartDataSets & {
  percentLabels?: string[];
  treeLabels?: string[];
  treeSubLabels?: string[];
  tree?: number[];
  tooltipValues?: string[];
  tooltipValues2?: string[];
  tooltipValues3?: string[];
  fontColor?: string;
  fontFamily?: string;
  fontSize?: number;
  lineHeight?: number;
  fontStyle?: string;
  treeLongLabels?: string[];
  backgroundColor?: (ctx: any) => string;
};

export type TreemapChartProps = {
  values: number[]; // use to calculate rectangle's size
  tooltipValues?: string[]; // display on the tooltip
  tooltipValues2?: string[];
  tooltipValues3?: string[];
  valuesInPercents?: string[]; // use to render chart's label, string has format "12.12%"
  colors: string[]; // use to render background color;
  treeLabels: string[]; // use to render label on rectangle, example : MBS, BID
  treeSubLabels?: string[]; // use to render sublabel in recangle, example: 100,000tr
  treeLongLabels: string[]; // full description of treeLabels, example: MBB - Ngân hàng Quân đội
};
/**
 *  NOTE: This chart only support 1 dimension (group)
 */

export default function TreemapChart({
  values,
  tooltipValues,
  valuesInPercents,
  treeSubLabels,
  treeLabels,
  treeLongLabels,
  colors,
  tooltipValues2,
  tooltipValues3,
}: TreemapChartProps) {
  // Validation length
  const totalItems =
    values.length + treeLabels.length + treeLongLabels.length + colors.length;
  if (
    totalItems / 4 !== values.length ||
    totalItems / 4 !== treeLabels.length ||
    totalItems / 4 !== treeLongLabels.length ||
    totalItems / 4 !== colors.length
  ) {
    throw new Error("all TreemapChart's data props must have same length");
  }

  const themeType = useSelector(themeTypeSelector);

  const treemapDataset: TreemapChartDataset = {
    label: "ChartData", // well, use for nothing, it's chartjs's label
    tree: values,
    tooltipValues: tooltipValues,
    tooltipValues2: tooltipValues2,
    tooltipValues3: tooltipValues3,
    percentLabels: valuesInPercents,
    treeLabels: treeLabels,
    treeSubLabels: treeSubLabels,
    treeLongLabels: treeLongLabels,
    fontColor: "#fff",
    fontFamily: themes[themeType].fontFamily,
    fontSize: 10,
    fontStyle: "normal",
    lineHeight: 12,
    backgroundColor: function (ctx: any) {
      let colorValue = colors[ctx.dataIndex];
      return colorValue;
    },
  };

  const data = {
    datasets: [treemapDataset] as TreemapChartDataset[],
  };
  const option = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 0,
      },
    },
    onHover: function (evt: any, elements: any) {
      let tooltipEl = document.getElementById("chartjs-tooltip");
      if (elements && elements.length) {
        return;
      }
      if (tooltipEl) {
        updateDomStyle(tooltipEl, {
          opacity: "0",
        });
      }
    },
    tooltips: {
      enabled: false,
      custom: function tooltip(tooltipModel: ChartTooltipModel): void {
        let tooltipEl = document.getElementById("chartjs-tooltip");
        const chart = (this as any)._chart;
        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = createElementFromHTML(
            render(
              div({
                id: "chartjs-tooltip",
                children: div({
                  style: {
                    color: "#ffffff",
                    "background-color": "#283648",
                    border: "px solid black",
                    "min-width": "200px",
                    padding: "12px",
                    "margin-top": "20px",
                    "margin-left": "-20px",
                  },
                  children: "",
                }),
              })
            )
          ) as HTMLElement;
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          updateDomStyle(tooltipEl, {
            opacity: "0",
          });
          return;
        }

        // Remove all class and set x, y align as class
        tooltipEl.classList.remove(
          "market-line-chart-tooltip",
          "above",
          "below",
          "no-transform",
          "top",
          "bottom",
          "center",
          "left",
          "right"
        );
        tooltipEl.className = "";
        tooltipEl.classList.add("treemap-chart");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        if (tooltipModel.xAlign) {
          tooltipEl.classList.add(tooltipModel.xAlign);
        }

        // Set Text
        if (tooltipModel.body) {
          let dataPoint = tooltipModel.dataPoints[0];
          if (!dataPoint) return;
          let item = data.datasets![0] as TreemapChartDataset; //item could be null

          const headerElement = div({
            children:
              dataPoint.index != null
                ? item.treeLongLabels![dataPoint.index]!.toString()
                : "",
            style: {
              "font-family": themes[themeType].fontFamily,
              "font-weight": "700",
              "font-size": "15px",
              "line-height": "20px",
              color: "#ffffff",
            },
          });

          let tooltipText = "";
          let tooltip2Text = "";
          let tooltip3Text = "";
          let tooltipPercent = "";

          if (dataPoint.index != null) {
            if (
              data.datasets[0].tooltipValues &&
              data.datasets[0].tooltipValues[dataPoint.index]
            ) {
              tooltipText =
                data.datasets[0].tooltipValues![dataPoint.index]!.toString();
            } else {
              tooltipText = data.datasets[0].tree![dataPoint.index]!.toString();
            }

            if (
              data.datasets[0].tooltipValues2 &&
              data.datasets[0].tooltipValues2[dataPoint.index]
            ) {
              tooltip2Text =
                data.datasets[0].tooltipValues2![dataPoint.index]!.toString();
            }

            if (
              data.datasets[0].tooltipValues3 &&
              data.datasets[0].tooltipValues3[dataPoint.index]
            ) {
              tooltip3Text =
                data.datasets[0].tooltipValues3![dataPoint.index]!.toString();
            }

            if (
              data.datasets[0].percentLabels &&
              data.datasets[0].percentLabels[dataPoint.index]
            ) {
              tooltipPercent =
                data.datasets[0].percentLabels![dataPoint.index]!.toString();
            }
          }

          const valueElement = div({
            className: "value-1",
            style: {
              "font-family": themes[themeType].fontFamily,
              "font-weight": "400",
              "font-size": "12px",
              "line-height": "16px",
              color: "#ffffff",
            },
            children: tooltipText && `${tooltipText}`,
          });

          const value2Element = div({
            className: "value-2",
            style: {
              "font-family": themes[themeType].fontFamily,
              "font-weight": "400",
              "font-size": "12px",
              "line-height": "16px",
              color: "#ffffff",
            },
            children: tooltip2Text && `${tooltip2Text}`,
          });

          const value3Element = div({
            className: "value-3",
            style: {
              "font-family": themes[themeType].fontFamily,
              "font-weight": "400",
              "font-size": "12px",
              "line-height": "16px",
              color: "#ffffff",
            },
            children: tooltip3Text && `${tooltip3Text}`,
          });

          const percentElement = div({
            style: {
              "font-family": themes[themeType].fontFamily,
              "font-weight": "400",
              "font-size": "12px",
              "line-height": "16px",
              color: "#ffffff",
            },
            children: tooltipPercent && `${tooltipPercent}`,
          });

          const tooltipElement = div({
            style: {},
            children: [
              headerElement,
              valueElement,
              value2Element,
              value3Element,
              percentElement,
            ],
          });

          let root = tooltipEl.children[0];
          root.innerHTML = render(tooltipElement);
        }
        const { top, left } = getChartHtmlTooltipPosition(
          chart,
          tooltipEl,
          tooltipModel,
          24,
          8
        );

        updateDomStyle(tooltipEl, {
          opacity: "1",
          position: "absolute",
          "pointer-events": "none",
          left: left + "px",
          top: top + "px",
          "font-family": themes[themeType].fontFamily,
          "font-size": tooltipModel.bodyFontSize + "px",
          "font-style": tooltipModel._bodyFontStyle,
          padding: tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px",
        });
      },
    },
    animation: {
      duration: 0, // general animation time
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
  };

  return <ChartComponent type="treemap" data={data} options={option} />;
}
