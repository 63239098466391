import { toast } from "components/commons/Toast";
import {
  checkTimeTransfer,
  findParameterBankDetail,
  findTransferContractLocal,
  getCurrencyStrVN,
  getCustomerRestriction,
  getSubAccToLocalList,
  inquiryAccountTra,
  searchTransferLocal,
  searchTransferLocalHis,
  submitTransfer,
} from "core/http/apis/transfer-local";
import {
  IFindParameterBankDetailParams,
  IFindTransferContractLocalParams,
  IGetSubAccToLocalListParams,
  IInquiryAccountTraParams,
  ISearchTransferLocalHisParams,
  ISearchTransferLocalParams,
  ISubmitTransferParams,
} from "core/http/apis/transfer-local/types";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IParameterBankDataResponse } from "domain/models/ParameterBankData";
import { ITransferContractLocalRepsonse } from "domain/models/TransferContractLocal";
import {
  ICurrentcyStrVNResponse,
  ICustomerRestrictionResponse,
  ITimeTransferResponse,
  ITransferLocalResponse,
} from "domain/models/TransferLocal";
import { ITransferLocalModelResponse } from "domain/models/TransferLocalModel";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "redux/store";
import {
  changeSubAccountNoRequest,
  findParameterBankDetailFailure,
  findParameterBankDetailRequest,
  findParameterBankDetailSuccess,
  findTransferContractLocalFailure,
  findTransferContractLocalRequest,
  findTransferContractLocalSuccess,
  getSubAccToLocalListFailure,
  getSubAccToLocalListRequest,
  getSubAccToLocalListSuccess,
  inquiryAccountTraFailure,
  inquiryAccountTraRequest,
  inquiryAccountTraSuccess,
  searchTransferLocalFailure,
  searchTransferLocalHisFailure,
  searchTransferLocalHisRequest,
  searchTransferLocalHisSuccess,
  searchTransferLocalRequest,
  searchTransferLocalSuccess,
  setAccFromHidden,
  setAccToHidden,
  setAmountHidden,
  submitTransferFailure,
  submitTransferRequest,
  submitTransferSuccess,
  updateConfirmationContent,
  updateItemCalculate,
  updateItemEdit,
  updateItemSelected,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  updateMode,
} from "./redux";

function* searchTransferLocalWorker(
  action: ReturnType<typeof searchTransferLocalRequest>
) {
  try {
    const params: ISearchTransferLocalParams = action.payload;
    const response: ITransferLocalResponse = yield call(
      searchTransferLocal,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchTransferLocalSuccess(response));
    } else {
      yield put(searchTransferLocalFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferLocalFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchTransferLocalWatcher() {
  yield takeLatest(searchTransferLocalRequest.type, searchTransferLocalWorker);
}

function* searchTransferLocalHisWorker(
  action: ReturnType<typeof searchTransferLocalHisRequest>
) {
  try {
    const params: ISearchTransferLocalHisParams = action.payload;
    const response: ITransferLocalResponse = yield call(
      searchTransferLocalHis,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchTransferLocalHisSuccess(response));
    } else {
      yield put(searchTransferLocalHisFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferLocalHisFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchTransferLocalHisWatcher() {
  yield takeLatest(
    searchTransferLocalHisRequest.type,
    searchTransferLocalHisWorker
  );
}

function* submitTransferWorker(
  action: ReturnType<typeof submitTransferRequest>
) {
  try {
    const params: ISubmitTransferParams = action.payload;
    const response: ITransferLocalResponse = yield call(submitTransfer, params);

    if (response?.statusCode === 0) {
      yield put(submitTransferSuccess(response));

      yield put(updateItemSelected(undefined));
      yield put(updateItemEdit(undefined));
      yield put(updateItemCalculate(undefined));
      yield put(setAmountHidden(false));
      yield put(setAccToHidden(true));
      yield put(updateMode("1"));
    } else {
      yield put(submitTransferFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(submitTransferFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* submitTransferWatcher() {
  yield takeLatest(submitTransferRequest.type, submitTransferWorker);
}

function* inquiryAccountTraWorker(
  action: ReturnType<typeof inquiryAccountTraRequest>
) {
  try {
    const params: IInquiryAccountTraParams = action.payload;
    const response: ITransferLocalModelResponse = yield call(
      inquiryAccountTra,
      {
        subAccoNo: params.subAccoNo,
      }
    );

    if (response?.statusCode === 0) {
      yield put(inquiryAccountTraSuccess(response));

      yield put(
        findTransferContractLocalRequest({
          subAccCd: response.data?.subAccoCd,
          toSubAccountNo: params?.subAccNoFrom,
          checkContract: response.data?.checkLocalList,
        })
      );

      const paramGroup = "TR00";
      const branchCd = "LOCAL";
      yield put(
        findParameterBankDetailRequest({
          branchCd: response.data.branchCd,
          transactionCd: response.data.transactionCd,
          groupCd: response.data.groupCd,
          paramGroup: paramGroup,
          bankCd: branchCd,
        })
      );

      yield put(setAccFromHidden(true));
      yield put(setAccToHidden(true));
      yield put(setAmountHidden(true));
    } else {
      yield put(inquiryAccountTraFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(inquiryAccountTraFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* inquiryAccountTraWatcher() {
  yield takeLatest(inquiryAccountTraRequest.type, inquiryAccountTraWorker);
}

function* findTransferContractLocalWorker(
  action: ReturnType<typeof findTransferContractLocalRequest>
) {
  try {
    const params: IFindTransferContractLocalParams = action.payload;
    const response: ITransferContractLocalRepsonse = yield call(
      findTransferContractLocal,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findTransferContractLocalSuccess(response));
    } else {
      yield put(findTransferContractLocalFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findTransferContractLocalFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findTransferContractLocalWatcher() {
  yield takeLatest(
    findTransferContractLocalRequest.type,
    findTransferContractLocalWorker
  );
}

function* findParameterBankDetailWorker(
  action: ReturnType<typeof findParameterBankDetailRequest>
) {
  try {
    const params: IFindParameterBankDetailParams = action.payload;
    const response: IParameterBankDataResponse = yield call(
      findParameterBankDetail,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findParameterBankDetailSuccess(response));
    } else {
      yield put(findParameterBankDetailFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findParameterBankDetailFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findParameterBankDetailWatcher() {
  yield takeLatest(
    findParameterBankDetailRequest.type,
    findParameterBankDetailWorker
  );
}

function* getSubAccToLocalListWorker(
  action: ReturnType<typeof getSubAccToLocalListRequest>
) {
  try {
    const params: IGetSubAccToLocalListParams = action.payload;
    const response: IBasePairValueResponse = yield call(
      getSubAccToLocalList,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getSubAccToLocalListSuccess(response));
    } else {
      yield put(getSubAccToLocalListFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getSubAccToLocalListFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getSubAccToLocalListWatcher() {
  yield takeLatest(
    getSubAccToLocalListRequest.type,
    getSubAccToLocalListWorker
  );
}

function* changeSubAccountNoWorker(
  action: ReturnType<typeof changeSubAccountNoRequest>
) {
  try {
    const params: IInquiryAccountTraParams = action.payload;
    const response: ITransferLocalModelResponse = yield call(
      inquiryAccountTra,
      {
        subAccoNo: params.subAccoNo,
      }
    );

    if (response?.statusCode === 0) {
      const checkContract = response.data.checkLocalList;
      const transferLocalAllow = response.data.transferLocalAllow;

      if (checkContract === 1 || !transferLocalAllow) {
        yield put(
          getSubAccToLocalListRequest({
            subAccoNo: params.subAccoNo,
          })
        );

        yield put(setAccToHidden(false));
      } else {
        yield put(setAccToHidden(true));
      }

      yield put(inquiryAccountTraSuccess(response));

      const paramGroup = "TR00";
      const branchCd = "LOCAL";
      yield put(
        findParameterBankDetailRequest({
          branchCd: response.data.branchCd,
          transactionCd: response.data.transactionCd,
          groupCd: response.data.groupCd,
          paramGroup: paramGroup,
          bankCd: branchCd,
        })
      );
    } else {
      yield put(inquiryAccountTraFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(inquiryAccountTraFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* changeSubAccountNoWatcher() {
  yield takeLatest(changeSubAccountNoRequest.type, changeSubAccountNoWorker);
}

function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const { isVisible, listAcc }: { isVisible: boolean; listAcc?: any[] } =
      action.payload;

    if (isVisible) {
      const {
        itemEdit: { subAccoNo, subAccoTo, amount },
        itemCalculate: {
          beginTime,
          endTime,
          transferFree,
          transferLimit,
          minAmount,
          maxAmount,
        },
      } = yield select((state: RootState) => state.transferLocal.transferLocal);

      const childFound = listAcc?.find(
        (child) => child.subAccoNo === subAccoTo
      );

      const response: ITransferContractLocalRepsonse = yield call(
        findTransferContractLocal,
        {
          checkContract: 0,
          subAccCd: childFound ? childFound.subAccoCd : 0,
          toSubAccountNo: subAccoTo,
        }
      );

      if (response.statusCode === 1) {
        return toast(response?.errorCode, "error", response?.message, true);
      }

      const response1: ITimeTransferResponse = yield call(checkTimeTransfer, {
        beginTime,
        endTime,
        isCheck: true,
      });

      if (response1.statusCode !== 0) {
        return toast(response1?.errorCode, "error", response1?.message, true);
      }

      if (amount <= 0) {
        return toast("transferLocal.message.amountCheck2", "error");
      }

      if (typeof transferFree === "number") {
        return toast("transferLocal.message.transferFreeCheck1", "error");
      }

      if (transferLimit <= 0) {
        return toast("transferLocal.message.dialogCheckAvail", "error");
      }

      if (amount < minAmount) {
        return toast("transferLocal.message.amountCheck3", "error");
      }

      if (amount > maxAmount) {
        return toast("transferLocal.message.amountCheck4", "error");
      }

      const response2: ICurrentcyStrVNResponse = yield call(getCurrencyStrVN, {
        curency: amount,
      });

      if (response2.statusCode !== 0) {
        return toast(response1?.errorCode, "error", response1?.message, true);
      }

      yield put(
        updateConfirmationContent({ cashTransferText: response2.data })
      );

      const response3: ICustomerRestrictionResponse = yield call(
        getCustomerRestriction,
        {
          restrictionCd: 6,
          subAccoNo: subAccoNo,
        }
      );

      if (response3.statusCode === 2) {
        return toast(response1?.errorCode, "error", response1?.message, true);
      }
    }

    yield put(updateModalStatusSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}

export default function* transferLocalSaga() {
  yield all([
    searchTransferLocalWatcher(),
    searchTransferLocalHisWatcher(),
    submitTransferWatcher(),
    inquiryAccountTraWatcher(),
    findTransferContractLocalWatcher(),
    findParameterBankDetailWatcher(),
    changeSubAccountNoWatcher(),
    getSubAccToLocalListWatcher(),
    updateModalStatusWatcher(),
  ]);
}
