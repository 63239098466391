import styled from "styled-components/macro";

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  height: auto;
`;

export const TextColor = styled.span<{ color: string }>`
  color: ${({ theme, color }) => theme[color]};
`;

export const VolumeAndTimeWrapper = styled.div`
  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
  scrollbar-width: thin;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  align-content: flex-start;
`;

export const Header = styled.div`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  height: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  background-color: ${({ theme }) => theme.background2};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  color: ${({ theme }) => theme.grey50};
  position: sticky;
  top: 0;
  justify-content: center;
`;

export const Body = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
`;

export const Row = styled.div`
  display: flex;
  padding: 8px 12px;
  height: 32px;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.grey100};
  }
  :nth-child(odd) {
    background-color: ${({ theme }) => theme.grey70};
  }
  :hover {
    background-color: ${({ theme }) => theme.grey60};
  }
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption11.weight.semiBold};
  line-height: ${({ theme }) => theme.typeFaces.caption11.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  min-width: 50px;
  &.progressBar {
    width: 100%;
  }
`;

export const HeaderSection = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  border-bottom: 2px solid ${({ theme }) => theme.lineGreyOn};
  height: 32px;
  padding: 6px 16px;
  background-color: ${({ theme }) => theme.background2};
`;
