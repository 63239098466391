import HttpWatchlists from "../../singleton/watchlists";
import {
  addTickerForWatchlistParams,
  IAddTickersToWatchlistParam,
  IAddTickersToWatchlistResponse,
  putWatchlistParams,
  responseAddTickerForWatchlist,
  WatchlistOwnerData,
} from "./types";
import storage from "helper/storage";

export const getWatchlist = async (
  params: string
): Promise<WatchlistOwnerData> => {
  const res = await HttpWatchlists.get<WatchlistOwnerData>(params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const putWatchlist = async (
  params: putWatchlistParams
): Promise<any> => {
  const res = await HttpWatchlists.put<any>(
    `${storage.getMasterAccount()}/${params.id}`,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data.data;
};

export const postWatchlist = async (
  params: putWatchlistParams
): Promise<any> => {
  const res = await HttpWatchlists.post<any>(
    `${storage.getMasterAccount()}`,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data.data;
};

export const deleteWatchlist = async (params: string): Promise<any> => {
  const res = await HttpWatchlists.delete<any>(
    `${storage.getMasterAccount()}/${params}`
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data.data;
};

export const addTickerForWatchlist = async (
  params: addTickerForWatchlistParams
): Promise<responseAddTickerForWatchlist> => {
  const res = await HttpWatchlists.post<responseAddTickerForWatchlist>(
    `${storage.getMasterAccount()}/${params.id}/details`,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const addTickersToWatchlist = async (
  id: string,
  param: IAddTickersToWatchlistParam
): Promise<IAddTickersToWatchlistResponse> => {
  const res = await HttpWatchlists.put<IAddTickersToWatchlistResponse>(
    `${storage.getMasterAccount()}/${id}/multi-details`,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteTickerForWatchlist = async (
  watchlistId: string,
  id: string
): Promise<any> => {
  const res = await HttpWatchlists.delete<any>(
    `${storage.getMasterAccount()}/${watchlistId}/details/${id}`
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data.data;
};

export const putTickerForWatchlist = async (
  watchlistId: string,
  params: string
): Promise<any> => {
  const res = await HttpWatchlists.put<any>(
    `${storage.getMasterAccount()}/${watchlistId}/multi-details`,
    JSON.stringify({ items: params }),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data.data;
};
