import { FC } from "react";
import Content from "./components/MarginSecuritiesContent";
import Tabs from "./components/Tabs";
import { Container } from "./styles";

const MarginSecurities: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <Tabs />
      <Content />
    </Container>
  );
};

export default MarginSecurities;
