import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const stockOrderDerSelector = (state: RootState) => state.stockOrderDer;

export const orderBookDerSelector = createSelector(
  stockOrderDerSelector,
  (state) => state.orderBookDer
);

export const placeOrderDerSelector = createSelector(
  stockOrderDerSelector,
  (state) => state.placeOrderDer
);

export const dataSelector = createSelector(
  placeOrderDerSelector,
  (state) => state.data
);

export const accountSelectSelector = createSelector(
  dataSelector,
  (state) => state.account
);

export const validateSelector = createSelector(
  placeOrderDerSelector,
  (state) => state.validate
);

export const conditionOrderBookDerSelector = createSelector(
  orderBookDerSelector,
  (state) => state.condition
);

export const filterOrderBookDerSelector = createSelector(
  orderBookDerSelector,
  (state) => state.filters
);

export const isReloadPlaceOrderDerSelector = createSelector(
  orderBookDerSelector,
  (state) => state.isReloadPlaceOrderDer
);

export const selectedRowsSelector = createSelector(
  orderBookDerSelector,
  (state) => state.selectedRows
);

export const loadListSelector = createSelector(
  orderBookDerSelector,
  (state) => state.loadList
);

export const orderBookDerListSelector = createSelector(
  orderBookDerSelector,
  (state) => state.orderBookDerList
);

export const orderBookDerRealTimeListSelector = createSelector(
  orderBookDerSelector,
  (state) => state.orderBookDerRealTime
);

export const listSelectedRowSelector = createSelector(
  orderBookDerSelector,
  (state) => state.selectedRows
);

export const isCheckAllSelector = createSelector(
  orderBookDerSelector,
  (state) => state.isCheckAll
);

export const dertDetailListSelector = createSelector(
  orderBookDerSelector,
  (state) => state.dertDetailList
);

export const orderBookDerCondListSelector = createSelector(
  orderBookDerSelector,
  (state) => state.orderBookDerCondList
);

export const dertCondDetailListSelector = createSelector(
  orderBookDerSelector,
  (state) => state.dertCondDetailList
);

export const selectedCondRowsSelector = createSelector(
  orderBookDerSelector,
  (state) => state.selectedCondRows
);

export const filtersSelector = createSelector(
  orderBookDerSelector,
  (state) => state.filters
);

export const enableInfinitySelector = createSelector(
  orderBookDerSelector,
  (state) => state.enableInfinity
);

export const isCustomerRestrictionSelector = createSelector(
  placeOrderDerSelector,
  (state) => state.isCustomerRestriction
);

export const errorCustomerRestrictionSelector = createSelector(
  placeOrderDerSelector,
  (state) => state.errorCustomerRestriction
);

export const dertTradingGetFeeRateSelector = createSelector(
  placeOrderDerSelector,
  (state) => state.dertTradingGetFeeRate
);

export const validateDertSelector = createSelector(
  placeOrderDerSelector,
  (state) => state.validateDert
);
