import { useSelector } from "react-redux";
import TickerDetail from "./TickerDetail";
import TickerDetailCW from "./TickerDetailCW";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import GlobalData from "helper/globalData";
import { SecType } from "helper/consts";

function TickerInfo() {
  const selectedTicker = useSelector(selectedTickerSelector);
  return (
    <>
      {selectedTicker &&
      GlobalData.getTickerInfoNew(selectedTicker)?.sectype === SecType.CW ? (
        <TickerDetailCW />
      ) : (
        <TickerDetail />
      )}
    </>
  );
}

export default TickerInfo;
