import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const statisticUpdownSelector = (state: RootState) =>
  state.statisticUpdown;

export const statisticUpdownListSelector = createSelector(
  statisticUpdownSelector,
  (state) => state.list
);

export const statisticUpdownLoadingSelector = createSelector(
  statisticUpdownSelector,
  (state) => state.loading
);

export const statisticUpdownFilterSelector = createSelector(
  statisticUpdownSelector,
  (state) => state.filter
);

export const activeTabSelector = createSelector(
  statisticUpdownSelector,
  (state) => state.activeTab
);
