import { BubbleChartProps } from "components/widgets/generals/MarketStats/MarketBubbleChart/types";
import { getMarketBreadth } from "core/grpc";
import { MarketBreadthItem } from "domain/protoNew/auto_trading_pb";
import { EMarketCodeNew } from "helper/consts";
import { getColorGradientChart } from "helper/utils";
import {
  fetchMarketStatsRequest,
  fetchMarketStatsSuccess,
} from "modules/market/redux/marketStats";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { themeTypeSelector } from "themes/redux";
import { ThemeType } from "themes/types";
import { setCurrentMarketCode } from "../redux";
import { currentMarketCodeSelector } from "../redux/selectors";

function getMarketStartChartData(
  marketStats: MarketBreadthItem.AsObject[],
  themeType: ThemeType
) {
  let chartData: BubbleChartProps = { scaleData: [], colors: [] };

  marketStats.forEach((item) => {
    const scaleItemPoint = {
      name: item.seccd,
      changedPercentVolumeWithAvg5d: item.avgamt5d,
      marketPercent: item.marketcapweight,
      contributionRate: item.contributepercent,
      contributionValue: item.contributeval,
    };

    const color = getColorGradientChart(item.colorcode!, themeType);

    chartData.scaleData.push(scaleItemPoint);
    chartData.colors.push(color);
  });

  return chartData;
}

function* fetchMarketStatsWorker() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );

    const response: MarketBreadthItem.AsObject[] = yield call(
      getMarketBreadth,
      currentMarketCode
    );

    const themeType: ThemeType = yield select(themeTypeSelector);

    const chartData = getMarketStartChartData(response, themeType);

    yield put(fetchMarketStatsSuccess(chartData));
  } catch (e) {}
}

export default function* marketStatsSaga() {
  yield all([
    takeLatest(fetchMarketStatsRequest, fetchMarketStatsWorker),
    takeLatest(setCurrentMarketCode, fetchMarketStatsWorker),
  ]);
}
