import { toast } from "components/commons/Toast";
import {
  getListR9008DataSourceSecUrl,
  getMoneyStatementList,
} from "core/http/apis/account-statement";
import { IBasePairValueResponse } from "domain/models/BasePairValue";

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getListMoneyStatementFailure,
  getListMoneyStatementRequest,
  getListMoneyStatementSuccess,
  searchListMoneyStatementFailure,
  searchListMoneyStatementRequest,
  searchListMoneyStatementSuccess,
  searchListSecurityStatementFailure,
  searchListSecurityStatementRequest,
  searchListSecurityStatementSuccess,
} from "./redux";

function* getListMoneyStatementWorker(
  action: ReturnType<typeof getListMoneyStatementRequest>
) {
  try {
    const params: any = action.payload;
    const response: IBasePairValueResponse = yield call(
      getMoneyStatementList,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getListMoneyStatementSuccess(response));
    } else {
      yield put(getListMoneyStatementFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListMoneyStatementFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchListMoneyStatementWorker(
  action: ReturnType<typeof searchListMoneyStatementRequest>
) {
  try {
    const params: any = action.payload;
    const response: IBasePairValueResponse = yield call(
      getMoneyStatementList,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchListMoneyStatementSuccess(response));
    } else {
      yield put(searchListMoneyStatementFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchListMoneyStatementFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchListSecurityStatementWorker(
  action: ReturnType<typeof searchListSecurityStatementRequest>
) {
  try {
    const params: any = action.payload;
    const response: IBasePairValueResponse = yield call(
      getListR9008DataSourceSecUrl,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchListSecurityStatementSuccess(response));
    } else {
      yield put(searchListSecurityStatementFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchListMoneyStatementFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getListMoneyStatementWatcher() {
  yield takeLatest(
    getListMoneyStatementRequest.type,
    getListMoneyStatementWorker
  );
}

function* searchListMoneyStatementWatcher() {
  yield takeLatest(
    searchListMoneyStatementRequest.type,
    searchListMoneyStatementWorker
  );
}
function* searchListSecurityStatementWatcher() {
  yield takeLatest(
    searchListSecurityStatementRequest.type,
    searchListSecurityStatementWorker
  );
}

export default function* getListMoneyStatementSaga() {
  yield all([
    getListMoneyStatementWatcher(),
    searchListMoneyStatementWatcher(),
    searchListSecurityStatementWatcher(),
  ]);
}
