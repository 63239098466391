import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ChartData, ChartValueItem } from "../types";

export type DerivativeState = {
  isOpenDropdownDerivative: boolean;
  selectedTypeDerivative: string;
  previewingTickerCode?: string;
  isShowingVN30Line: boolean;
  chartDataDerivativeTicker: ChartData;
  isVisibleDerivativeSection: boolean;
};

const initialState: DerivativeState = {
  isOpenDropdownDerivative: false,
  selectedTypeDerivative: "",
  previewingTickerCode: undefined,
  isShowingVN30Line: false,
  chartDataDerivativeTicker: {
    labels: [],
    thresholdValue: 0,
    datasets: [],
  },
  isVisibleDerivativeSection: false,
};

const appendChartData = (
  chartData: ChartData,
  appendData: ChartValueItem
): ChartData => {
  const { labels, datasets } = chartData;
  if (
    !appendData.timeValue ||
    !appendData.closedValue ||
    !appendData.volumeValue
  ) {
    return chartData;
  }

  if (moment(appendData.timeValue * 1000).diff(moment(), "days") !== 0) {
    return chartData;
  }
  labels.push(appendData.timeValue * 1000);
  const priceLineDataset = datasets[0];
  const volumeBarDataset = datasets[1];
  const VN30Dataset = datasets[2];

  return {
    thresholdValue: chartData.thresholdValue,
    labels,
    datasets: [
      {
        ...priceLineDataset,
        data: [...priceLineDataset.data, appendData.closedValue],
      },
      {
        ...volumeBarDataset,
        data: [...volumeBarDataset.data, appendData.volumeValue],
      },
      VN30Dataset,
    ],
  };
};

const appendChartDataVN30 = (
  chartData: ChartData,
  appendData: ChartValueItem
): ChartData => {
  const { labels, datasets } = chartData;
  if (!appendData.timeValue || !appendData.closedValue) {
    return chartData;
  }

  if (moment(appendData.timeValue * 1000).diff(moment(), "days") !== 0) {
    return chartData;
  }

  const priceLineDataset = datasets[0];
  const volumeBarDataset = datasets[1];
  const VN30Dataset = datasets[2];

  return {
    thresholdValue: chartData.thresholdValue,
    labels,
    datasets: [
      priceLineDataset,
      volumeBarDataset,
      {
        ...VN30Dataset,
        data: [
          ...VN30Dataset.data,
          { x: appendData.timeValue * 1000, y: appendData.closedValue },
        ],
      },
    ],
  };
};

const changeColumn = createAction("categoriesSlice/changeColumn");

const derivativeSlice = createSlice({
  name: "derivativeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changeColumn, (state, action: any) => {
      state.previewingTickerCode = undefined;
    });
  },
  reducers: {
    toggleDropdownDerivative: (state) => {
      state.isOpenDropdownDerivative = !state.isOpenDropdownDerivative;
    },
    changeTypeDerivative: (state, action: PayloadAction<string>) => {
      state.selectedTypeDerivative = action.payload;
    },
    getChartDataDerivativeTicker: (state, action: PayloadAction<string>) => {
      state.chartDataDerivativeTicker = {
        labels: [],
        thresholdValue: 0,
        datasets: [],
      };
    },
    getChartDataDerivativeTickerSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.chartDataDerivativeTicker = action.payload.data;
    },
    getChartDataDerivativeTickerFailure: (
      state,
      action: PayloadAction<string>
    ) => {},
    updateChartDataDerivative: (
      state,
      action: PayloadAction<ChartValueItem>
    ) => {
      if (!state.chartDataDerivativeTicker.datasets.length) return;
      state.chartDataDerivativeTicker = appendChartData(
        state.chartDataDerivativeTicker,
        action.payload
      );
    },
    updateChartDataVN30: (state, action: PayloadAction<ChartValueItem>) => {
      if (!state.chartDataDerivativeTicker.datasets.length) return;
      state.chartDataDerivativeTicker = appendChartDataVN30(
        state.chartDataDerivativeTicker,
        action.payload
      );
    },
    toggleVN30Line: (state) => {
      state.isShowingVN30Line = !state.isShowingVN30Line;
    },
    changeVisibleDerivative: (state, action: PayloadAction<boolean>) => {
      state.isVisibleDerivativeSection = action.payload;
    },
  },
});
export const {
  toggleDropdownDerivative,
  changeTypeDerivative,
  getChartDataDerivativeTicker,
  getChartDataDerivativeTickerSuccess,
  getChartDataDerivativeTickerFailure,
  updateChartDataDerivative,
  updateChartDataVN30,
  toggleVN30Line,
  changeVisibleDerivative,
} = derivativeSlice.actions;

export default derivativeSlice.reducer;
