import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const stockOrderSelector = (state: RootState) => state.stockOrder;

export const rootStockOrderSelector = createSelector(
  stockOrderSelector,
  (state) => state.root
);

export const placeOrderSelector = createSelector(
  stockOrderSelector,
  (state) => state.placeOrder
);

export const tickerChartSelector = createSelector(
  stockOrderSelector,
  (state) => state.tickerChart.detailInfo.priceinfolistList
);

export const basicpriceTickerChartSelector = createSelector(
  stockOrderSelector,
  (state) => state.tickerChart.detailInfo.secdetailinfo?.basicprice
);
