import {
  FilterTransaction,
  ValueVolumeDistributionData,
} from "components/widgets/generals/TransactionValueVolume/types";
import { getMarketDistribution } from "core/grpc";
import { TopSecChangedResponse } from "domain/protoNew/auto_trading_pb";
import globalData from "helper/globalData";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchTransactionValueVolume,
  fetchTransactionValueVolumeFailure,
  fetchTransactionValueVolumeSuccess,
} from "../redux/transactionValueVolume";
import {
  currentMarketCodeSelector,
  filterTransactionValueVolumeSelector,
} from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";

function getDisplayDataForValueDistribuiton(
  rpgcInput: TopSecChangedResponse.TopSecChangedInfo.AsObject[]
): ValueVolumeDistributionData[] {
  return rpgcInput.map(
    (item: TopSecChangedResponse.TopSecChangedInfo.AsObject) => {
      const displayItem: ValueVolumeDistributionData = {
        ticker: item.seccd,
        stockName: globalData.getTickerInfoNew(item.seccd)?.secname,
        totalTraded: item.totalamt as number | undefined,
        totalTradedMT: item.totalamt as number | undefined,
        totalTradedPT: item.totalamt as number | undefined,
        lastPrice: item.closeprice as number | undefined,
        changedPercentage: item.changepercent as number | undefined,
        changedValue: item.changepoint as number | undefined,
        color: item.colorcode,
      };
      return displayItem;
    }
  );
}

function getDisplayDataForVolumeDistribuiton(
  rpgcInput: TopSecChangedResponse.TopSecChangedInfo.AsObject[]
): ValueVolumeDistributionData[] {
  return rpgcInput.map(
    (item: TopSecChangedResponse.TopSecChangedInfo.AsObject) => {
      const displayItem: ValueVolumeDistributionData = {
        ticker: item.seccd,
        stockName: globalData.getTickerInfoNew(item.seccd)?.secname,
        totalTraded: item.totalqty as number | undefined,
        totalTradedMT: item.totalqty as number | undefined,
        totalTradedPT: item.totalqty as number | undefined,
        lastPrice: item.closeprice as number | undefined,
        changedPercentage: item.changepercent as number | undefined,
        changedValue: item.changepoint as number | undefined,
        color: item.colorcode,
      };
      return displayItem;
    }
  );
}

function* fetchTransactionValueVolumeWorker() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const filterTransaction: FilterTransaction = yield select(
      filterTransactionValueVolumeSelector
    );

    const res: TopSecChangedResponse.AsObject = yield call(
      getMarketDistribution,
      {
        marketType: currentMarketCode.toString(),
        valueType: parseInt(filterTransaction.valueType.value),
      }
    );

    yield put(
      fetchTransactionValueVolumeSuccess({
        valueData: getDisplayDataForValueDistribuiton(res.topsecchangedList),
        volumeData: getDisplayDataForVolumeDistribuiton(res.topsecchangedList),
      })
    );
  } catch (e: any) {
    yield put(fetchTransactionValueVolumeFailure(e));
  }
}

function* fetchTransactionValueVolumeWatcher() {
  yield takeLatest(
    fetchTransactionValueVolume.type,
    fetchTransactionValueVolumeWorker
  );
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(
    setCurrentMarketCode.type,
    fetchTransactionValueVolumeWorker
  );
}

export default function* transactionValueVolumeWidget() {
  yield all([
    fetchTransactionValueVolumeWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
