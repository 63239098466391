import { entryBankUtilityApi, getInquiryBankUtilityApi } from "./urls";
import {
  IGetInquiryBankUtilityResponse,
  IEntryBankUtilityResponse,
} from "domain/models/HoldUnholdBankAccount";

import { IGetInquiryBankUtilityParams, IEntryBankUtilityParams } from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const getInquiryBankUtility = async (
  params: IGetInquiryBankUtilityParams
): Promise<IGetInquiryBankUtilityResponse> => {
  const res = await VgaiaHttp.get<IGetInquiryBankUtilityResponse>(
    getInquiryBankUtilityApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const entryBankUtility = async (
  params: IEntryBankUtilityParams
): Promise<IEntryBankUtilityResponse> => {
  const res = await VgaiaHttp.post<IEntryBankUtilityResponse>(
    entryBankUtilityApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
