import styled from "styled-components/macro";

export const CardTable = styled.div`
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background2};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  #qrcode {
    border-radius: 8px;
    margin: 16px;
  }
  .inputOTP {
    /* min-height: 170px; */
    display: flex;
    align-items: center;
  }
  .inputOTPHeader {
    display: flex;
    justify-content: space-between;
  }
  .blue {
    color: ${({ theme }) => theme.blue50};
    text-decoration: underline;
    cursor: pointer;
  }
  .title {
    color: ${({ theme }) => theme.textcolorWhite};
    .sendCode {
      width: 40%;
      text-align: end;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.grey50};
    }
  }
  .inputSmsOTPContent {
    display: flex;
    align-items: center;
    margin-left: 16px;
    flex: 1;
  }
  .inputOTPContent {
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    background: ${({ theme }) => theme.background2};
    justify-content: center;
    .otpContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .otpInput {
        width: 44px;
        height: 44px;
        margin: 6px;
        text-align: center;
        background: ${({ theme }) => theme.textcolorBlack};
        color: ${({ theme }) => theme.textcolorWhite};
        border: 1px solid ${({ theme }) => theme.bgElevated4};
        border-radius: 8px;
      }
    }
  }
`;

export const BodyQRcode = styled.div<{ disabled?: boolean }>`
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ disabled }) => disabled && `opacity: 0.2;`}
`;

export const ErrorVerify = styled.div`
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;

export const LoadingBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: 16px;
  .loader {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
`;

export const QRcodeExpired = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -33px;
  color: ${({ theme }) => theme.neutral1};
`;

export const ContentText = styled.div`
  text-align: center;
`;
