/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";
import {
  updateModalStatusRequest,
  findOrderDetailRequest,
  findOrderByRequestCondRequest,
  updateEnableInfinityOder,
  fetchMoreCondRequest,
} from "modules/order-history/redux";

import {
  listOrderHistorySelector,
  listOrderHistoryCondSelector,
  filterOrderHistorySelector,
  itemDetailSelector,
  itemDetailCondSelector,
  selectLimit,
  selectPageOder,
  selectEnableInfinityOder,
} from "modules/order-history/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Container, HeaderCell, StatusContainer } from "./styles";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { TableCellContentColor } from "./styles";
import { OrderHistory, requestCondModel } from "domain/models/OrderHistory";
import DetailModal from "./DetailModal/index";
import DetailModalCond from "./DetailModalCond/index";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { IFindRequestCondParams } from "core/http/apis/order-history/types";
import storage from "helper/storage";
import moment from "moment";
import { GetAccoList } from "helper/sessionData";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";

function RequestTable() {
  const intl = useIntl();

  const data = useSelector(listOrderHistorySelector);
  const dataCond = useSelector(listOrderHistoryCondSelector);
  const itemDetailRow = useSelector(itemDetailSelector);
  const itemDetailCondRow = useSelector(itemDetailCondSelector);
  const limit = useSelector(selectLimit);
  const pageOder = useSelector(selectPageOder);
  const enableOder = useSelector(selectEnableInfinityOder);
  const {
    fromDate,
    toDate,
    subAccoNo,
    secCd,
    orderType,
    tradeType,
    condType,
    statusCond,
  } = useSelector(filterOrderHistorySelector);
  const themeType = useSelector(themeTypeSelector);
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );
  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    // list.unshift({
    //   label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
    //   value: "",
    // });
    return list;
  }, [listAcc, intl]);
  const dispatch = useDispatch();

  const center = "center";
  const flexEnd = "flex-end";

  const openDetail = (row: OrderHistory) => {
    dispatch(
      findOrderDetailRequest({
        orgOrderNo: row.orgOrderNo ?? 0,
        orderNo: row.orderNo ?? 0,
        marketCd: row.marketCd ?? 0,
        tradeDate: row.tradeDate ?? 0,
      })
    );

    dispatch(
      updateModalStatusRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
  };
  const openDetailCond = (row: requestCondModel) => {
    dispatch(
      findOrderByRequestCondRequest({
        refDate: row.tradeDate ?? 0,
        refNo: row.id ?? 0,
        custNo: row.custNo ?? "",
        subAccoNo: row.subAccoNo ?? "",
        offset: 0,
        limit: 100,
      })
    );

    dispatch(
      updateModalStatusRequest({
        isVisible: true,
        itemDetailCond: row,
      })
    );
  };
  function getColor(value: string, value2: number) {
    if (value2 === 1) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].red50}
          textTransform="uppercase"
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 2) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].green50}
          textTransform="uppercase"
        >
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  function getColor2(value: string, value2: number) {
    if (value2 === 1 || value2 === 6 || value2 === 7 || value2 === 8) {
      return (
        <StatusContainer status="reject">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorWhite} //red
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    } else if (value2 === 5) {
      return (
        <StatusContainer status="success">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorWhite} //green
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    } else if (value2 === 2 || value2 === 3) {
      return (
        <StatusContainer status="reject-text-red">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorBlack} //black
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    } else if (value2 === 4) {
      return (
        <StatusContainer status="partialMatch">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorWhite}
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    }

    return value;
  }
  function getColor3(value: string, value2: number) {
    if (value2 === 9 || value2 === 2) {
      return (
        <TableCellContentColor align="center" color={themes[themeType].red50}>
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 1) {
      return (
        <TableCellContentColor align="center" color={themes[themeType].green50}>
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 3) {
      return (
        <TableCellContentColor align="center" color={themes[themeType].blue50}>
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }

  const getDisplay = (status: number) => {
    switch (status) {
      case 1:
        return "Back";
      case 2:
        return "Web";
      case 3:
        return "Front";
      case 4:
        return "Webmobile";
      case 5:
        return "Mobile";
      case 6:
        return "Home";
      case 7:
        return "Floor";
      case 8:
        return "SMS";
      case 9:
        return "Tel";
    }
  };
  const getDisplay2 = (status: number) => {
    switch (status) {
      case 1:
        return "Back";
      case 2:
        return "Web";
      case 3:
        return "Front";
      case 4:
        return "Webmobile";
      case 5:
        return "Mobile";
      case 6:
        return "Home";
      case 7:
        return "Floor";
      case 8:
        return "SMS";
      case 9:
        return "Tel";
    }
  };

  const getDisplay3 = (status: number) => {
    switch (status) {
      case 1:
        return "matMethod1";
      case 2:
        return "matMethod2";
    }
  };
  const getDisplay4 = (status: number) => {
    switch (status) {
      case 1:
        return "rejected";
      case 2:
        return "entered";
      case 3:
        return "waitApprove";
      case 4:
        return "partialMatch";
      case 5:
        return "fullMatch";
      case 6:
        return "partialCancel";
      case 7:
        return "cancel";
      case 8:
        return "rejectedGkck";
    }
  };
  const getDisplay5 = (status: number) => {
    switch (status) {
      case 1:
        return "takeEffect";
      case 2:
        return "expire";
      case 3:
        return "complete";
      case 9:
        return "cancel";
    }
  };
  const getDisplay7 = (status: number) => {
    switch (status) {
      case 1:
        return "sell";
      case 2:
        return "buy";
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.tradingDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.account" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderShare" />
          </HeaderCell>
        ),
        accessor: "secCd",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderSide" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor(
                intl.formatMessage({
                  id:
                    "orderHistory.tradeType." + getDisplay7(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderVolume" />
          </HeaderCell>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderPrice" />
          </HeaderCell>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderVolumeFilled" />
          </HeaderCell>
        ),
        accessor: "matQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderPriceFilled" />
          </HeaderCell>
        ),
        accessor: "matPriceAvg",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderStatus" />
          </HeaderCell>
        ),
        accessor: "extStatus",
        minWidth: 92,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <>
              {getColor2(
                intl.formatMessage({
                  id:
                    "orderHistory.extStatus." + getDisplay4(+props.value ?? 0),
                }),
                props.value
              )}
            </>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderNo" />
          </HeaderCell>
        ),
        accessor: "orderNo",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.channel" />
          </HeaderCell>
        ),
        accessor: "ordChanel",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "orderHistory.ordChannel." + getDisplay(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const columnsCond = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.account" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderShare" />
          </HeaderCell>
        ),
        accessor: "secCd",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.condType" />
          </HeaderCell>
        ),
        accessor: "condType",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl
                .formatMessage({
                  id: "orderHistory.condType." + props.value,
                })
                .includes("orderHistory.condType")
                ? ""
                : intl.formatMessage({
                    id: "orderHistory.condType." + props.value,
                  })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderSide" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor(
                intl.formatMessage({
                  id:
                    "orderHistory.tradeType." + getDisplay7(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderStatus" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor3(
                intl.formatMessage({
                  id:
                    "orderHistory.extStatusCond." +
                    getDisplay5(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderVolume" />
          </HeaderCell>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderPrice" />
          </HeaderCell>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.table.orderVolumeFilled" />
          </HeaderCell>
        ),
        accessor: "matQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "reqTime",
        minWidth: 135,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {/* {moment(new Date(props.value).toString()).format(
                "DD/MM/YYYY HH:mm:ss"
              )} */}
              {props.value}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.availableDateTime" />
          </HeaderCell>
        ),
        accessor: "fromDate",
        minWidth: 180,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })} -{" "}
              {convertNumberToDateFormat({
                date: props?.row?.original?.toDate,
              })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.matMethod" />
          </HeaderCell>
        ),
        accessor: "matMethod",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "orderHistory.matMethod." + getDisplay3(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.channel" />
          </HeaderCell>
        ),
        accessor: "channel",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id:
                  "orderHistory.ordChannelCond." +
                  getDisplay2(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const fetchMoreData = () => {};

  const fetchMoreDataOder = () => {
    if (dataCond.length < (pageOder + 1) * limit) {
      return dispatch(updateEnableInfinityOder(false));
    }
    const submitData: IFindRequestCondParams = {
      custNo: storage.getMasterAccount() ?? "",
      subAccoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      secCd: secCd ? secCd : "",
      tradeType: tradeType ? tradeType : 0,

      condType: condType ? condType : "",
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,

      status: statusCond !== null && statusCond !== undefined ? statusCond : 0,
      page: 0,
      rowPerPage: limit,
    };
    dispatch(fetchMoreCondRequest(submitData));
  };

  return (
    <Container id="orderHistoryTable">
      {orderType === "2" && (
        <CommonTableInfinity
          fetchMoreData={fetchMoreDataOder}
          columns={columnsCond}
          data={dataCond ? dataCond : []}
          enableInfinity={enableOder}
          scrollableTarget={"orderHistoryTable"}
          doubleClickRowHandler={(row) => {
            openDetailCond(row.original);
          }}
          isHasFooter={false}
        />
      )}

      {orderType === "1" && (
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={data ? data : []}
          enableInfinity={true}
          scrollableTarget={"orderHistoryTable"}
          doubleClickRowHandler={(row) => {
            openDetail(row.original);
          }}
          isHasFooter={false}
        />
      )}
      {/* <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={orderType === "2" ? columnsCond : columns}
        data={orderType === "2" ? dataCond : data ? data : []}
        enableInfinity={true}
        scrollableTarget={"orderHistoryTable"}
        doubleClickRowHandler={(row) => {
          if (orderType === "1") {
            openDetail(row.original);
          } else if (orderType === "2") {
            openDetailCond(row.original);
          }
        }}
        isHasFooter={false}
      /> */}

      {orderType === "2" && itemDetailCondRow && (
        <DetailModalCond itemDetailRow={itemDetailCondRow} />
      )}
      {orderType === "1" && itemDetailRow && (
        <DetailModal itemDetailRow={itemDetailRow} />
      )}
    </Container>
  );
}

export default memo(RequestTable);
