import VgaiaHttp from "core/http/singleton/vgaia";
import {
  IDeleteTransferSecResponse,
  IFindPortfolioResponse,
  IFindTransferSecHistoryResponse,
  IGetCustomerRestrictionResponse,
  IInquiryAccountTransferSecResponse,
  ISubmitTransferSecResponse,
} from "domain/models/TransferShare";
import {
  IDeleteTransferSecParam,
  IFindPortfolioParam,
  IFindTransferSecHistoryParam,
  IGetCustomerRestrictionParam,
  IInquiryAccountTransferSecParam,
  ISubmitTransferSecParam,
} from "./types";
import {
  deleteTransferSecApi,
  findPortfolioApi,
  findTransferSecHistoryApi,
  getCustomerRestrictionApi,
  inquiryAccountTransferSecApi,
  submitTransferSecApi,
} from "./urls";

export const findTransferSecHistory = async (
  params: IFindTransferSecHistoryParam
): Promise<IFindTransferSecHistoryResponse> => {
  const res = await VgaiaHttp.get<IFindTransferSecHistoryResponse>(
    findTransferSecHistoryApi(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteTransferSec = async (
  body: IDeleteTransferSecParam
): Promise<IDeleteTransferSecResponse> => {
  const res = await VgaiaHttp.post<IDeleteTransferSecResponse>(
    deleteTransferSecApi(null),
    body,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const inquiryAccountTransferSec = async (
  params: IInquiryAccountTransferSecParam
): Promise<IInquiryAccountTransferSecResponse> => {
  const res = await VgaiaHttp.get<IInquiryAccountTransferSecResponse>(
    inquiryAccountTransferSecApi(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCustomerRestriction = async (
  params: IGetCustomerRestrictionParam
): Promise<IGetCustomerRestrictionResponse> => {
  const res = await VgaiaHttp.get<IGetCustomerRestrictionResponse>(
    getCustomerRestrictionApi(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findPortfolio = async (
  params: IFindPortfolioParam
): Promise<IFindPortfolioResponse> => {
  const res = await VgaiaHttp.get<IFindPortfolioResponse>(
    findPortfolioApi(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const submitTransferSec = async (
  params: ISubmitTransferSecParam
): Promise<ISubmitTransferSecResponse> => {
  const res = await VgaiaHttp.post<ISubmitTransferSecResponse>(
    submitTransferSecApi(),
    params,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
