import { Button, CheckBox, Input, OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { EBusinessCd } from "helper/consts";
import { GetParameters } from "helper/sessionData";
import storage from "helper/storage";
import {
  createContractRequest,
  getAccountRequest,
  getContractRequest,
  updateStatusCode,
} from "modules/add-framework-constract-form/redux";
import {
  getAccount,
  getStatusCode,
  loadingSubmitSelector,
} from "modules/add-framework-constract-form/selectors";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ModalNotification from "../ModalNotification";
import {
  ColorGray,
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailRow,
  DetailRowBottom,
  DetailRowButton,
  DetailRowCheckBox,
  DetailRowPaddingTB,
  DetailRowTextTop,
  DetailRowTop,
  DetailWrapper,
  LinkContract,
  TextCheckBox,
} from "./styles";

function CreateContract() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const statusCode = useSelector(getStatusCode);
  const loading = useSelector(loadingSubmitSelector);

  const accountNo = storage.getMasterAccount();
  const paramHd = GetParameters("SYST", "P_MONEY_CONTRACT_PREFIX");

  const [checked, setChecked] = useState(false);

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [data, setData] = useState({
    id: "",
    custNo: "",
    custName: "",
    contractNo: "",
    contractDate: "",
    remarks: "",
    updDateTime: "",
  });

  useEffect(() => {
    setData((state: any) => {
      return {
        ...state,
        id: "",
        custNo: account?.custNo,
        custName: account?.custFamilyName
          ?.concat(" ")
          ?.concat(account?.custGivenName),
        contractNo: paramHd?.concat(account?.custNo),
        contractDate: dateOpen,
        remarks: data?.remarks,
        updDateTime: "",
      };
    });
  }, [account]);

  const dateOpen = new Date();

  useEffect(() => {
    dispatch(
      getAccountRequest({
        custCd: "",
        custNo: accountNo,
      })
    );
  }, [accountNo]);

  useEffect(() => {
    if (statusCode === 0) {
      setVisibleModalConfirm(true);
    }
  }, [statusCode]);

  const handleReset = () => {
    setData((state: any) => {
      return {
        ...state,
        remarks: "",
      };
    });
  };

  const handleSubmit = () => {
    setVisibleModalOTP(true);
  };

  const handleChangeRemarks = (e: any) => {
    setData((state: any) => {
      return {
        ...state,
        remarks: e.target.value,
      };
    });
  };

  const handleCloseModal = () => {
    setVisibleModalConfirm(false);
    dispatch(updateStatusCode({ newStatus: -1 }));
    setData((state: any) => {
      return {
        ...state,
        remarks: "",
      };
    });
    setChecked(false);
    dispatch(
      getContractRequest({
        branchCd: "",
        transactionCd: "",
        custNo: accountNo,
        contractDateFrom: "",
        contractDateTo: "",
        closeDateFrom: "",
        closeDateTo: "",
        contractSts: "",
        srcChannel: "",
      })
    );
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitAdvance = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    dispatch(
      createContractRequest({
        actionMode: "1",
        id: data?.id,
        custNo: data?.custNo,
        contractNo: data?.contractNo,
        contractDate: moment(data?.contractDate).format("YYYYMMDD"),
        remarks: data?.remarks,
        otpCode: response?.otp,
        otpType: response?.otpTypeSubmit!,
      })
    );
  };

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowTop>
          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="addFrameworkContractForm.createContract.account" />
            </DetailLabel>
            <DetailInputWrapper className="text-left">
              <b>{data?.custNo}</b>
            </DetailInputWrapper>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="addFrameworkContractForm.createContract.accountName" />
            </DetailLabel>
            <DetailInputWrapper>
              <b>{data?.custName}</b>
            </DetailInputWrapper>
          </DetailRowPaddingTB>

          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="addFrameworkContractForm.createContract.contractNo" />
            </DetailLabel>
            <DetailInputWrapper>
              <b>{data?.contractNo}</b>
            </DetailInputWrapper>
          </DetailRow>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="addFrameworkContractForm.createContract.dateOpenContract" />
            </DetailLabel>
            <DetailInputWrapper>
              <b>{moment(data?.contractDate).format("DD/MM/YYYY")}</b>
            </DetailInputWrapper>
          </DetailRowPaddingTB>

          <DetailRowTextTop>
            <DetailLabel>
              <FormattedMessage id="addFrameworkContractForm.createContract.remark" />
            </DetailLabel>
            <DetailInputWrapper>
              <Input
                textArea={true}
                placeholder={intl.formatMessage({
                  id: "addFrameworkContractForm.createContract.placeholderRemark",
                })}
                value={data?.remarks}
                onChange={handleChangeRemarks}
              />
            </DetailInputWrapper>
          </DetailRowTextTop>
        </DetailRowTop>

        <DetailRowBottom>
          <DetailRowCheckBox>
            <CheckBox
              checked={checked}
              onChange={() => setChecked(!checked)}
            ></CheckBox>
            <TextCheckBox>
              <ColorGray>
                {intl.formatMessage({
                  id: "addFrameworkContractForm.createContract.textConfirmAfter",
                })}
              </ColorGray>
              <LinkContract href="/">
                {intl.formatMessage({
                  id: "addFrameworkContractForm.createContract.textConfirmBefore",
                })}
              </LinkContract>
            </TextCheckBox>
          </DetailRowCheckBox>
          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={handleReset}
            >
              {intl.formatMessage({
                id: "addFrameworkContractForm.createContract.btnReset",
              })}
            </Button>
            <Button
              color="accept"
              className="button-filter m-1 w-100"
              onClick={handleSubmit}
              loading={loading}
              fill={true}
              disabled={checked === true ? false : true}
            >
              {intl.formatMessage({
                id: "addFrameworkContractForm.createContract.btnSubmit",
              })}
            </Button>
          </DetailRowButton>
        </DetailRowBottom>
      </DetailCol>

      <ModalNotification
        visibleModalConfirm={visibleModalConfirm}
        title={intl.formatMessage({
          id: "addFrameworkContractForm.createContract.titleNoti",
        })}
        handleCloseModal={handleCloseModal}
        size={"sm"}
        content={`${intl.formatMessage({
          id: "addFrameworkContractForm.createContract.notiAfter",
        })} ${account?.custNo} ${intl.formatMessage({
          id: "addFrameworkContractForm.createContract.notiBefor",
        })}`}
      />

      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.DEPOSIT}
        />
      )}
    </DetailWrapper>
  );
}

export default CreateContract;
