import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;
export const Content = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1785px) {
  }
`;
export const ContentRow = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1785px) {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;
export const ContentLeft = styled.div`
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1785px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const ContentRight = styled.div`
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1785px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const Title = styled.div`
  width: 60%;
  color: ${({ theme }) => theme.grey20};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  @media (max-width: 1785px) {
    width: 50%;
  }
`;
export const TitleDes = styled.div`
  width: 50%;
`;
export const ContentValue = styled.div`
  width: 35%;
  display: flex;
  justify-content: right;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;
export const ContentValueDes = styled.div`
  width: 50%;
  display: flex;
  justify-content: right;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;
export const Description = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
