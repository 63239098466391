import { FC } from "react";
import { CompanyName as CompanyNameWrapper } from "../styles";
import GlobalData from "helper/globalData";

interface Props {
  ticker: string;
}

const CompanyNameCell: FC<Props> = ({ ticker }) => {
  const stockName = GlobalData.getTickerInfoNew(ticker!)?.secname;

  return (
    <CompanyNameWrapper title={stockName}>
      {stockName || "-"}
    </CompanyNameWrapper>
  );
};

export { CompanyNameCell };
