import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFindMarginContractDebitWatchParams,
  IGetMrgDebitInfo2Params,
  IUpdateMrgContractPaymentParams,
  IFindListMarginAttrRequestParams,
  IGetMarginDebitExtendFeeInfoParams,
  IMarginDebitExtendDaysParams,
  IGetListDebitPayExParams,
} from "core/http/apis/contract-debit/types";
import {
  MarginDebitContractWatch,
  IPaymentHistory,
  IExtendHistory,
  itemEdit,
  // IPaymentHistory,
  // IExtendHistory
  IFindMarginContractDebitWatchResponse,
  IGetMrgDebitInfo2Response,
  IUpdateMrgContractPaymentResponse,
  IFindListMarginAttrRequestResponse,
  IGetMarginDebitExtendFeeInfoResponse,
  IMarginDebitExtendDaysResponse,
  IGetListDebitPayExResponse,
  IGetListDebitPayEx2Response,
} from "domain/models/ContractDebit";
import { logOutSuccess } from "modules/auth/redux";
import { CONTRACT_DEBIT_TABS } from "./constants";
import { cloneDeep } from "lodash";

interface ILoanTrackingFilter {
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
  status: number;
}
interface IPaymentHistoryFilter {
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
  status: number;
}
interface IExtendHistoryFilter {
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
  status: number;
}
export interface ReduxData {
  activeTab: string;
  limit: number;
  loanTracking: {
    currentPage: number;
    enableInfinity: boolean;
    loading: boolean;
    filter: ILoanTrackingFilter;
    list: MarginDebitContractWatch[];

    isShowPaymentModal?: boolean;
    isShowLoanExtendModal?: boolean;
    isShowConfirmModal?: boolean;
    itemDetail?: MarginDebitContractWatch;
    itemDetailRow?: MarginDebitContractWatch;
    infoMrgDebit: string[];
    extendDays: string | undefined;
    extendFee: string;
    availableBalance: string;
    itemEdit: itemEdit;
    statusUpdateMrgContractPayment: boolean;
    statusMarginDebitExtendDays: boolean;
  };
  paymentHistory: {
    currentPage: number;
    enableInfinity: boolean;
    loading: boolean;
    filter: IPaymentHistoryFilter;
    list: IPaymentHistory[];
  };
  extendHistory: {
    currentPage: number;
    enableInfinity: boolean;
    loading: boolean;
    filter: IExtendHistoryFilter;
    list: IExtendHistory[];
  };
}

const initialState: ReduxData = {
  activeTab: CONTRACT_DEBIT_TABS.LOAN_TRACKING,
  limit: 30,
  loanTracking: {
    currentPage: 0,
    enableInfinity: true,
    loading: false,
    filter: {
      subAccoNo: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      status: 0,
    },
    list: [],
    isShowPaymentModal: false,
    isShowLoanExtendModal: false,
    isShowConfirmModal: false,
    itemEdit: {
      amount: 0,
      content: "",
    },
    infoMrgDebit: [],
    extendDays: "0",
    extendFee: "0",
    availableBalance: "0",
    statusUpdateMrgContractPayment: false,
    statusMarginDebitExtendDays: false,
  },
  paymentHistory: {
    currentPage: 0,
    enableInfinity: true,
    loading: false,
    filter: {
      subAccoNo: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      status: 0,
    },
    list: [],
  },
  extendHistory: {
    currentPage: 0,
    enableInfinity: true,
    loading: false,
    filter: {
      subAccoNo: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      status: 0,
    },
    list: [],
  },
};

const contractDebitSlice = createSlice({
  name: "contractDebitSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    findMarginContractDebitWatchRequest: (
      state,
      action: PayloadAction<IFindMarginContractDebitWatchParams>
    ) => {
      state.loanTracking.loading = true;
    },
    findMarginContractDebitWatchSuccess: (
      state,
      action: PayloadAction<IFindMarginContractDebitWatchResponse>
    ) => {
      state.loanTracking.loading = false;
      state.loanTracking.list = action.payload.data ?? [];
      state.loanTracking.statusMarginDebitExtendDays = false;
      state.loanTracking.statusUpdateMrgContractPayment = false;
      state.loanTracking.currentPage = 0;
      state.loanTracking.enableInfinity = true;
    },
    findMarginContractDebitWatchFailure: (state) => {
      state.loanTracking.loading = false;
    },
    getListDebitPayExRequest: (
      state,
      action: PayloadAction<IGetListDebitPayExParams>
    ) => {
      state.paymentHistory.loading = true;
    },
    getListDebitPayExSuccess: (
      state,
      action: PayloadAction<IGetListDebitPayExResponse>
    ) => {
      state.paymentHistory.loading = false;
      state.paymentHistory.list = action.payload.data ?? [];
      state.paymentHistory.currentPage = 0;
      state.paymentHistory.enableInfinity = true;
    },
    getListDebitPayExFailure: (state) => {
      state.loanTracking.loading = false;
    },
    getListDebitPayEx2Request: (
      state,
      action: PayloadAction<IGetListDebitPayExParams>
    ) => {
      state.extendHistory.loading = true;
    },
    getListDebitPayEx2Success: (
      state,
      action: PayloadAction<IGetListDebitPayEx2Response>
    ) => {
      state.extendHistory.loading = false;
      state.extendHistory.list = action.payload.data ?? [];
      state.extendHistory.currentPage = 0;
      state.extendHistory.enableInfinity = true;
    },
    getListDebitPayEx2Failure: (state) => {
      state.loanTracking.loading = false;
    },

    getMrgDebitInfo2Request: (
      state,
      action: PayloadAction<IGetMrgDebitInfo2Params>
    ) => {},
    getMrgDebitInfo2Success: (
      state,
      action: PayloadAction<{
        response: IGetMrgDebitInfo2Response;
        check: boolean;
      }>
    ) => {
      if (action.payload.check === true) {
        state.loanTracking.itemEdit.amount =
          +action.payload.response.data[1] ?? 0;
        state.loanTracking.infoMrgDebit = action.payload.response.data;
      } else {
        state.loanTracking.infoMrgDebit = action.payload.response.data;
      }
    },
    getMrgDebitInfo2Failure: (state) => {},

    getMarginDebitExtendFeeInfoRequest: (
      state,
      action: PayloadAction<IGetMarginDebitExtendFeeInfoParams>
    ) => {},
    getMarginDebitExtendFeeInfoSuccess: (
      state,
      action: PayloadAction<IGetMarginDebitExtendFeeInfoResponse>
    ) => {
      state.loanTracking.extendFee = action.payload.data.extendFeeAmt
        ? action.payload.data.extendFeeAmt
        : "0";
      state.loanTracking.availableBalance = action.payload.data.paymentAvail
        ? action.payload.data.paymentAvail
        : "0";
    },
    getMarginDebitExtendFeeInfoFailure: (state) => {},

    findListMarginAttrRequest: (
      state,
      action: PayloadAction<IFindListMarginAttrRequestParams>
    ) => {},
    findListMarginAttrSuccess: (
      state,
      action: PayloadAction<IFindListMarginAttrRequestResponse>
    ) => {
      state.loanTracking.extendDays = action.payload.data.find(
        (i) => i.fieldName === "MG00_EXTEND_DAYS"
      )?.fieldValue;
    },
    findListMarginAttrFailure: (state) => {},

    updateMrgContractPaymentRequest: (
      state,

      action: PayloadAction<IUpdateMrgContractPaymentParams>
    ) => {
      state.loanTracking.statusUpdateMrgContractPayment = false;
    },
    updateMrgContractPaymentSuccess: (
      state,
      action: PayloadAction<IUpdateMrgContractPaymentResponse>
    ) => {
      state.loanTracking.statusUpdateMrgContractPayment = true;
    },
    updateMrgContractPaymentFailure: (state) => {
      state.loanTracking.statusUpdateMrgContractPayment = false;
    },

    marginDebitExtendDaysRequest: (
      state,

      action: PayloadAction<IMarginDebitExtendDaysParams>
    ) => {
      state.loanTracking.statusMarginDebitExtendDays = false;
    },
    marginDebitExtendDaysSuccess: (
      state,
      action: PayloadAction<IMarginDebitExtendDaysResponse>
    ) => {
      state.loanTracking.statusMarginDebitExtendDays = true;
    },
    marginDebitExtendDaysFailure: (state) => {
      state.loanTracking.statusMarginDebitExtendDays = false;
    },

    updateActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    updateModalPaymentRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: MarginDebitContractWatch;
      }>
    ) => {},
    updateModalPaymentSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: MarginDebitContractWatch;
      }>
    ) => {
      state.loanTracking.itemDetail =
        action.payload.itemDetail && action.payload.itemDetail;
      state.loanTracking.isShowPaymentModal = action.payload.isVisible;
    },
    updateModalLoanExtendRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: MarginDebitContractWatch;
      }>
    ) => {},
    updateModalLoanExtendSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: MarginDebitContractWatch;
      }>
    ) => {
      state.loanTracking.itemDetail =
        action.payload.itemDetail && action.payload.itemDetail;
      state.loanTracking.isShowLoanExtendModal = action.payload.isVisible;
    },
    updateModalConfirmRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        amount?: number;
        itemDetailRow?: MarginDebitContractWatch;
      }>
    ) => {},
    updateModalConfirmSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetailRow?: MarginDebitContractWatch;
      }>
    ) => {
      state.loanTracking.itemDetailRow =
        action.payload.itemDetailRow && action.payload.itemDetailRow;
      state.loanTracking.isShowConfirmModal = action.payload.isVisible;
    },
    updateLoanTrackingFilter: (
      state,
      action: PayloadAction<{ key: keyof ILoanTrackingFilter; value: any }>
    ) => {
      state.loanTracking.filter = {
        ...state.loanTracking.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updatePaymentHistoryFilter: (
      state,
      action: PayloadAction<{ key: keyof IPaymentHistoryFilter; value: any }>
    ) => {
      state.paymentHistory.filter = {
        ...state.paymentHistory.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateExtendHistoryFilter: (
      state,
      action: PayloadAction<{ key: keyof IExtendHistoryFilter; value: any }>
    ) => {
      state.extendHistory.filter = {
        ...state.extendHistory.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateItemEdit: (state, action: PayloadAction<itemEdit | undefined>) => {
      state.loanTracking.itemEdit = {
        ...state.loanTracking.itemEdit,
        ...action.payload,
      };
    },

    fetchMoreListDebitPayExRequest: (
      state,
      action: PayloadAction<IGetListDebitPayExParams>
    ) => {
      // state.paymentHistory.loading = true;
    },
    fetchMoreListDebitPayExSuccess: (
      state,
      action: PayloadAction<IGetListDebitPayExResponse>
    ) => {
      const data = state.paymentHistory.list.concat(action.payload.data);
      // state.paymentHistory.loading = false;
      state.paymentHistory.list = data;
    },
    updateEnableInfinityPayMenHistory: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.paymentHistory.enableInfinity = action.payload;
    },
    updatePagePayMenHistory: (state, action: PayloadAction<number>) => {
      state.paymentHistory.currentPage = action.payload;
    },

    updateEnableInfinityExtendHistory: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.extendHistory.enableInfinity = action.payload;
    },
    updatePageExtendHistory: (state, action: PayloadAction<number>) => {
      state.extendHistory.currentPage = action.payload;
    },
    fetchMoreExtendHistoryRequest: (
      state,
      action: PayloadAction<IGetListDebitPayExParams>
    ) => {},
    fetchMoreExtendHistorySuccess: (
      state,
      action: PayloadAction<IGetListDebitPayExResponse>
    ) => {
      const data = state.extendHistory.list.concat(action.payload.data);
      state.extendHistory.list = data;
    },

    fetchMoreDebitWatchRequest: (
      state,
      action: PayloadAction<IFindMarginContractDebitWatchParams>
    ) => {},
    fetchMoreDebitWatchSuccess: (
      state,
      action: PayloadAction<IFindMarginContractDebitWatchResponse>
    ) => {
      const data = state.loanTracking.list.concat(action.payload.data);
      state.loanTracking.list = data ?? [];
    },
    updateEnableInfinityLoanTracking: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loanTracking.enableInfinity = action.payload;
    },
    updatePageLoanTracking: (state, action: PayloadAction<number>) => {
      state.loanTracking.currentPage = action.payload;
    },
  },
});

export const {
  reset,
  updateActiveTab,
  updateLoanTrackingFilter,
  updatePaymentHistoryFilter,
  updateExtendHistoryFilter,
  findMarginContractDebitWatchRequest,
  findMarginContractDebitWatchSuccess,
  findMarginContractDebitWatchFailure,
  getListDebitPayExRequest,
  getListDebitPayExSuccess,
  getListDebitPayExFailure,
  getListDebitPayEx2Request,
  getListDebitPayEx2Success,
  getListDebitPayEx2Failure,
  updateModalPaymentRequest,
  updateModalPaymentSuccess,
  updateModalConfirmRequest,
  updateModalConfirmSuccess,
  updateModalLoanExtendRequest,
  updateModalLoanExtendSuccess,
  getMrgDebitInfo2Request,
  getMrgDebitInfo2Success,
  getMrgDebitInfo2Failure,
  findListMarginAttrRequest,
  findListMarginAttrSuccess,
  findListMarginAttrFailure,
  getMarginDebitExtendFeeInfoRequest,
  getMarginDebitExtendFeeInfoSuccess,
  getMarginDebitExtendFeeInfoFailure,
  updateItemEdit,
  updateMrgContractPaymentRequest,
  updateMrgContractPaymentSuccess,
  updateMrgContractPaymentFailure,
  marginDebitExtendDaysRequest,
  marginDebitExtendDaysSuccess,
  marginDebitExtendDaysFailure,

  fetchMoreListDebitPayExRequest,
  fetchMoreListDebitPayExSuccess,
  updateEnableInfinityPayMenHistory,
  updatePagePayMenHistory,

  fetchMoreExtendHistoryRequest,
  fetchMoreExtendHistorySuccess,
  updatePageExtendHistory,
  updateEnableInfinityExtendHistory,

  fetchMoreDebitWatchRequest,
  fetchMoreDebitWatchSuccess,
  updateEnableInfinityLoanTracking,
  updatePageLoanTracking,
} = contractDebitSlice.actions;

export default contractDebitSlice.reducer;
