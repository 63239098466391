import { useDispatch, useSelector } from "react-redux";
import { ContentContainer, MarketLineChartWrapper } from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffectOnce, useInterval } from "react-use";
import MarketLineChart from "components/commons/Charts/MarketLineChart";
import {
  ChartData,
  getStatBasketContributionData,
} from "modules/market/redux/statBasketContribution";
import { ChartTooltipModel } from "chart.js";
import {
  createElementFromHTML,
  div,
  updateDomStyle,
} from "helper/domBuilder/utils";
import render from "helper/domBuilder";
import themes from "themes/abstracts/_themes";
import { getChartHtmlTooltipPosition, isOdd } from "helper/utils";
import numeral from "numeral";
import { BAR_WIDTH } from "./configs";
import { statBasketContributionDataSelector } from "modules/market/selectors";
import { themeTypeSelector } from "themes/redux";
import { LegendBlock } from "./styles";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

function getTotalValues(data: ChartData) {
  let totalIncreased = 0;
  let totalDecreased = 0;
  let percentIncreased = 0;
  let percentDecreased = 0;

  if (!data.datasets[0] || !data.datasets[1])
    return {
      totalIncreased,
      totalDecreased,
      percentIncreased,
      percentDecreased,
    };

  data.datasets[0].data.forEach((item: number) => {
    if (item < 0) {
      percentDecreased += item;
    } else {
      percentIncreased += item;
    }
  });

  data.datasets[1].data.forEach((item: number) => {
    if (item < 0) {
      totalDecreased += item;
    } else {
      totalIncreased += item;
    }
  });
  return {
    totalIncreased,
    totalDecreased,
    percentIncreased,
    percentDecreased,
  };
}

function StatBasketContribution() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const themeType = useSelector(themeTypeSelector);
  const statBasketContributionData = useSelector(
    statBasketContributionDataSelector
  );

  useInterval(() => {
    dispatch(getStatBasketContributionData());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    dispatch(getStatBasketContributionData());
  });

  const styleChildren = {
    color: themes[themeType].textcolorWhite,
    "font-size": "12px",
    "line-height": "16px",
    "font-weight": "normal",
  };

  function formatValueTooltip(
    dataPoint: any,
    index: number,
    dataset: any
  ): string {
    if (dataPoint.index == null) {
      return "";
    }
    if (isOdd(index)) {
      return numeral(dataset.data[dataPoint.index]).format("0.000");
    } else {
      return `${numeral(dataset.data[dataPoint.index]).format("0.000")}%`;
    }
  }

  const option = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: themes[themeType].textcolorWhite,
            fontSize: 10,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2, 4],
            color: "rgba(206, 209, 214, 0.1)",
          },
          ticks: {
            fontColor: themes[themeType].grey50,
            fontSize: 10,
            callback: function (value: any) {
              return numeral(value).format("0.000");
            },
          },
        },
      ],
    },
    onHover: function (evt: any, elements: any) {
      let tooltipEl = document.getElementById("chartjs-tooltip");
      if (elements && elements.length) {
        return;
      }
      if (tooltipEl) {
        updateDomStyle(tooltipEl, {
          opacity: "0",
        });
      }
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel: ChartTooltipModel) {
        let tooltipEl = document.getElementById("chartjs-tooltip");
        const chart = (this as any)._chart;

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = createElementFromHTML(
            render(
              div({
                id: "chartjs-tooltip",
                children: div({
                  style: {
                    "font-family": themes[themeType].fontFamily,
                    color: "#ffffff",
                    background: "rgba(40, 54, 72, 0.8)",
                    "border-radius": "0 4px 4px 4px",
                    border: "px solid black",
                    "min-width": "200px",
                    padding: "12px",
                    "margin-top": "22px",
                    "margin-left": "-20px",
                  },
                  children: "",
                }),
              })
            )
          ) as HTMLElement;
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          updateDomStyle(tooltipEl, {
            opacity: "0",
          });
          return;
        }
        // Remove all class and set x, y align as class
        tooltipEl.classList.remove(
          "market-line-chart-tooltip",
          "above",
          "below",
          "no-transform",
          "top",
          "bottom",
          "center",
          "left",
          "right"
        );
        tooltipEl.className = "";
        tooltipEl.classList.add("market-line-chart-tooltip");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        if (tooltipModel.xAlign) {
          tooltipEl.classList.add(tooltipModel.xAlign);
        }
        // Set Text
        if (tooltipModel.body) {
          let dataPoint = tooltipModel.dataPoints[0];
          if (!dataPoint) return;
          let headerElement = div({
            children: dataPoint.label!,
            style: {
              display: "flex",
              "font-weight": "bold",
              "font-size": "15px",
              color: themes[themeType].textcolorWhite,
              "justify-content": "flex-start",
            },
          });
          const bodyElements = statBasketContributionData?.datasets.map(
            (dataset: any, index: number) => {
              return div({
                style: {
                  display: "flex",
                  "min-width": "260px",
                  "justify-content": "space-between",
                  color: themes[themeType].textcolorWhite,
                  "font-weight": "400",
                  "font-size": "12px",
                },
                children: [
                  div({
                    children: isOdd(index)
                      ? intl.formatMessage({ id: "widgets.topIndex.value" })
                      : intl.formatMessage({ id: "widgets.topIndex.percent" }),
                    style: {
                      ...styleChildren,
                    },
                  }),
                  div({
                    children: formatValueTooltip(dataPoint, index, dataset),
                    style: {
                      ...styleChildren,
                    },
                  }),
                ],
              });
            }
          );

          const tooltipElement = div({
            children: [headerElement, ...bodyElements!],
          });

          let root = tooltipEl.children[0];
          root.innerHTML = render(tooltipElement);
        }

        const { top, left } = getChartHtmlTooltipPosition(
          chart,
          tooltipEl,
          tooltipModel,
          BAR_WIDTH,
          8
        );

        updateDomStyle(tooltipEl, {
          opacity: "1",
          position: "absolute",
          "pointer-events": "none",
          left: `${left}px`,
          top: `${top}px`,
          "font-family": themes[themeType].fontFamily,
          "font-size": tooltipModel.bodyFontSize + "px",
          "font-style": tooltipModel._bodyFontStyle,
          padding: tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px",
        });
      },
    },
  };

  const { totalIncreased, totalDecreased, percentIncreased, percentDecreased } =
    getTotalValues(statBasketContributionData);

  return (
    <ContentContainer>
      <MarketLineChartWrapper>
        <div className="chart">
          <MarketLineChart
            dataChart={statBasketContributionData}
            option={option}
          />
        </div>

        <div className="legend">
          <div className="item">
            <LegendBlock color="green50" />
            <FormattedMessage id="widgets.topIndex.increaseContribute" />{" "}
            {`${numeral(totalIncreased).format("0,0.000")}`}{" "}
            <FormattedMessage id="widgets.topIndex.point" />{" "}
            {`(${numeral(percentIncreased).format("0,0.000")}%)`}
          </div>
          <div className="item">
            <LegendBlock color="red50" />
            <FormattedMessage id="widgets.topIndex.decreaseContribute" />{" "}
            {`${numeral(totalDecreased).format("0,0.000")}`}{" "}
            <FormattedMessage id="widgets.topIndex.point" />{" "}
            {`(${numeral(percentDecreased).format("0,0.000")}%)`}
          </div>
        </div>
      </MarketLineChartWrapper>
    </ContentContainer>
  );
}

export default StatBasketContribution;
