import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getOrgInfo,
  getOrgInfoFailure,
  getOrgInfoSuccess,
  getOwnershipSuccess,
} from "../redux/orgInfo";
import { PayloadAction } from "@reduxjs/toolkit";
import { getSectOwnership, getSectProfile } from "core/grpc";
import {
  SectOwnershipItem,
  SectProfileItem,
} from "domain/protoNew/auto_trading_pb";

function* fetchOrgInfoByTicker(action: PayloadAction<string>) {
  try {
    const response: SectProfileItem.AsObject[] = yield call(
      getSectProfile,
      action.payload
    );

    yield put(getOrgInfoSuccess(response));
  } catch (e: any) {
    yield put(getOrgInfoFailure(e));
  }
}

function* fetchOwnershipByTicker(action: PayloadAction<string>) {
  try {
    const response: SectOwnershipItem.AsObject[] = yield call(
      getSectOwnership,
      action.payload
    );

    yield put(getOwnershipSuccess(response));
  } catch (e: any) {
    yield put(getOrgInfoFailure(e));
  }
}

function* watchOrgInfo() {
  yield takeLatest(getOrgInfo.type, fetchOrgInfoByTicker);
}
function* watchOwnership() {
  yield takeLatest(getOrgInfo.type, fetchOwnershipByTicker);
}

export default function* organizationInfoWidget() {
  yield all([watchOrgInfo(), watchOwnership()]);
}
