import styled from "styled-components/macro";
export const InputContainer = styled.div`
  position: relative;
  z-index: 4;
  max-width: ${({ theme }) => theme.input.width};
`;
export const InputLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.bgBase};
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.neutral1};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border: 1px solid ${({ theme }) => theme.borderColor};
  :hover {
    border: 1px solid ${({ theme }) => theme.neutral1};
  }
`;

export const ButtonSearch = styled.div`
  color: ${({ theme }) => theme.brandSecondary};
  background: ${({ theme }) => theme.brandSecondary};
  height: 32px;
  max-width: 159px;
  min-width: 159px;
  padding: 6px;
  cursor: pointer;
  text-align: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border-radius: 8px;
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  border: 1px solid ${({ theme }) => theme.brandSecondary};
  border-radius: 4px;
`;

export const TextColor = styled.div<{
  color: string;
  size: string;
  backgroundColor: string;
}>`
  color: ${({ theme, color }) => theme[color]};
  background-color: ${(props) => props.theme[props.backgroundColor]};
  font-weight: ${({ theme, size }) =>
    size === "big"
      ? theme.typeFaces.header.weight.semiBold
      : theme.typeFaces.caption12.weight.regular};
  font-size: ${({ theme, size }) =>
    size === "big"
      ? theme.typeFaces.header.size
      : theme.typeFaces.caption12.size};
  line-height: ${({ theme, size }) =>
    size === "big"
      ? theme.typeFaces.header.lineHeight
      : theme.typeFaces.caption12.lineHeight};
  max-width: ${(props) => (props.size === "big" ? "" : "5%")};
`;
export const TickerContainer = styled.div`
  background: ${({ theme }) => theme.bgElevated2};
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: 4px;
  max-height: 450px;
  overflow: auto;
  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner3};
    :hover {
      background: ${({ theme }) => theme.brandSecondary};
      div {
        span {
          color: ${({ theme }) => theme.brandSecondary};
        }
      }
    }
    div {
      span {
        color: ${({ theme }) => theme.neutral1};
      }
    }
  }
`;
export const TickerWrappper = styled.div`
  position: relative;
  z-index: 4;
`;
export const SearchContainer = styled.div<{ isShow: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
  padding: 8px;
  background: ${({ theme }) => theme.bgElevated2};
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  .flex-fill {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & > div {
      min-height: unset !important;
      width: 164px;
    }
  }
`;
// export const Control = styled.div<{
//   align?: string | undefined;
//   width?: string | undefined;
// }>`
//   width: ${({ width = "280px" }) => width};
//   display: flex;
//   align-items: center;
//   justify-content: ${({ align = "left" }) =>
//     align === "right" ? "flex-end" : "start"};
//   .flex-fill-select {
//     width: ${({ theme }) => theme.select.width};
//   }
// `;
// export const Label = styled.div<{
//   size?: string | undefined;
//   marginRight?: string | undefined;
// }>`
//   color: ${({ theme }) => theme.neutral2};
//   font-size: ${({ theme }) => theme.typeFaces.body.size};
//   margin-right: ${({ marginRight = "24px" }) => marginRight};
// `;
