import { FC } from "react";
import { ReactComponent as SelectedIcon } from "assets/image/svg/squareCheckBox.svg";
import { ReactComponent as UnSelectedIcon } from "assets/image/svg/squareUnCheckBox.svg";
import { StyledCheckBox, Container } from "./styles";

interface IProps {
  checked?: boolean;
  disabled?: boolean;
  values: any;
  textCheck: string;
  onChange?: () => void;
  onClick: (values: any | null) => void;
}

const CheckBox: FC<IProps> = ({
  checked,
  values,
  disabled,
  onClick,
  textCheck,
}) => {
  //   const inputRef = useRef<HTMLInputElement>(null);

  const clickLabelHandler = () => {
    onClick(values);
  };

  return (
    <Container onClick={() => clickLabelHandler()}>
      <StyledCheckBox disabled={disabled}>
        <div>{checked ? <SelectedIcon /> : <UnSelectedIcon />}</div>
      </StyledCheckBox>
      {` `}
      {textCheck}
    </Container>
  );
};

export default CheckBox;
