import { useEffect, useRef, useState } from "react";
import ChartComponent, { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { RootState } from "redux/store";
import get from "lodash/get";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import findMin from "lodash/min";
import findMax from "lodash/max";
import { DrawHorizontalLinePlugin } from "helper/chartPlugins";

interface Props {
  data: number[];
  referencePrice: number;
  color: string;
  visibleTooltip?: boolean;
}

const plugins = [DrawHorizontalLinePlugin];

function ChartTable({
  color,
  data,
  referencePrice,
  visibleTooltip = true,
}: Props) {
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const [colorChange, setColorChange] = useState("");
  const [borderColorChange, setBorderColorChange] = useState("");

  const chartRef = useRef<Line>(null);
  useEffect(() => {
    setColorChange(get(themes[themeType], color)); // color là màu ban đầu (string)
    setBorderColorChange(get(themes[themeType], color));
  }, [color]);

  const options: ChartOptions = {
    plugins: {
      decimation: { algorithm: "lttb", enabled: true, samples: 10 },
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      enabled: visibleTooltip,
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          type: undefined,
          time: {
            unit: "second",
          },
          distribution: "linear",
          display: false,
          ticks: {
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          id: "closedLine",
          ticks: {
            source: "labels",
            display: false,
            beginAtZero: true,
            min: findMin(data?.concat([referencePrice * 0.999])),
            max: findMax(data?.concat([referencePrice * 1.001])),
          },
        },
      ],
    },
  };

  const getDataChart = (canvas: any) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 60);
    gradient.addColorStop(
      0,
      colorChange || get(themes[themeType], color, "transparent")
    );
    gradient.addColorStop(0.5, "transparent");
    return {
      labels: data,
      thresholdValue: referencePrice,
      thresholdWidth: "0.3",
      lineColor: {
        positive: themes[themeType].blue50,
        negative: themes[themeType].red50,
        vertical: themes[themeType].textcolorBlack,
        horizontal: themes[themeType].yellow50,
      },
      datasets: [
        {
          label: "Giá",
          data: data,
          pointRadius: 0,
          borderColor: borderColorChange || get(themes[themeType], color),
          fill: "start",
          parsing: false,
          spanGaps: true,
          borderWidth: 1,
          backgroundColor: gradient,
        },
      ],
    };
  };
  return (
    <ChartComponent
      type="line"
      data={getDataChart}
      options={options}
      ref={chartRef}
      plugins={plugins}
    />
  );
}

export default ChartTable;
