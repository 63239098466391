import { PayloadAction } from "@reduxjs/toolkit";
import { getOrderFlowDistribution } from "core/grpc";
import { SecDividendResponse } from "domain/protoNew/auto_trading_pb";
import { UNIT_NUMBER } from "helper/consts";
import { intlSelector } from "languages/selectors";
import sum from "lodash/sum";
import { IntlShape } from "react-intl";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { themeTypeSelector } from "themes/redux";
import { ThemeType } from "themes/types";

import numeral from "numeral";
import themes from "themes/abstracts/_themes";
import {
  fetchOrderFlowDistribution,
  fetchOrderFlowDistributionFailure,
  fetchOrderFlowDistributionSuccess,
  ISecDividendInfoFormatter,
} from "../redux/orderFlowDistribution";

const formatNumber = (value: number, isBillion: boolean): number => {
  const data = isBillion ? value / UNIT_NUMBER.ONE_MILLION : value;

  return numeral(numeral(data).format("0,0.[00]")).value() ?? 0;

  // if (data < 1) return Number(data.toFixed(3));
  // if (data > 1 && data < 1000) return Number(data.toFixed(2));
  // if (data > 1000 && data < 10000) return Number(data.toFixed(1));
  // return Number(data.toFixed(0));
};

const convertSecDividendData = (
  data: SecDividendResponse.AsObject | null,
  themeType: ThemeType,
  intl: IntlShape
): ISecDividendInfoFormatter | null => {
  const secDividendInfoData = data?.secdividendinfo;
  if (secDividendInfoData) {
    const total =
      sum([
        secDividendInfoData.buyamt1,
        secDividendInfoData.buyamt2,
        secDividendInfoData.buyamt3,
        secDividendInfoData.sellamt1,
        secDividendInfoData.sellamt2,
        secDividendInfoData.sellamt3,
      ]) ?? 1;
    const dataFormatter = {
      dataInflow: {
        values: [
          formatNumber(secDividendInfoData.buyamt1, true),
          formatNumber(secDividendInfoData.buyamt2, true),
          formatNumber(secDividendInfoData.buyamt3, true),
        ],
        colors: [
          themes[themeType].green60,
          themes[themeType].green20,
          themes[themeType].green50,
        ],
        labels: [
          intl.formatMessage({ id: "widgets.orderFlowDistribution.small" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.medium" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.large" }),
        ],
      },
      dataOutflow: {
        values: [
          formatNumber(secDividendInfoData.sellamt1, true),
          formatNumber(secDividendInfoData.sellamt2, true),
          formatNumber(secDividendInfoData.sellamt3, true),
        ],
        colors: [
          themes[themeType].red60,
          themes[themeType].red20,
          themes[themeType].red50,
        ],
        labels: [
          intl.formatMessage({ id: "widgets.orderFlowDistribution.small" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.medium" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.large" }),
        ],
      },
      dataPieChartFlow: {
        values: [
          Number(((secDividendInfoData.buyamt1 / total) * 100).toFixed(2)),
          Number(((secDividendInfoData.buyamt2 / total) * 100).toFixed(2)),
          Number(((secDividendInfoData.buyamt3 / total) * 100).toFixed(2)),
          Number(((secDividendInfoData.sellamt1 / total) * 100).toFixed(2)),
          Number(((secDividendInfoData.sellamt2 / total) * 100).toFixed(2)),
          Number(((secDividendInfoData.sellamt3 / total) * 100).toFixed(2)),
        ],
        colors: [
          themes[themeType].green60,
          themes[themeType].green20,
          themes[themeType].green50,
          themes[themeType].red60,
          themes[themeType].red20,
          themes[themeType].red50,
        ],
        labels: [
          intl.formatMessage({ id: "widgets.orderFlowDistribution.small" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.medium" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.large" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.small" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.medium" }),
          intl.formatMessage({ id: "widgets.orderFlowDistribution.large" }),
        ],
      },
      totalInflow: formatNumber(
        sum([
          secDividendInfoData.buyamt1,
          secDividendInfoData.buyamt2,
          secDividendInfoData.buyamt3,
        ]),
        true
      ),
      totalOutflow: formatNumber(
        sum([
          secDividendInfoData.sellamt1,
          secDividendInfoData.sellamt2,
          secDividendInfoData.sellamt3,
        ]),
        true
      ),
    };

    return dataFormatter;
  }

  return null;
};

function* fetchOrderFlowDistributionWorker(action: PayloadAction<string>) {
  const ticker = action.payload;
  try {
    const res: SecDividendResponse.AsObject = yield call(
      getOrderFlowDistribution,
      ticker
    );

    const themeType: ThemeType = yield select(themeTypeSelector);
    const intl: IntlShape = yield select(intlSelector);

    yield put(
      fetchOrderFlowDistributionSuccess(
        convertSecDividendData(res, themeType, intl)
      )
    );
  } catch (e: any) {
    yield put(fetchOrderFlowDistributionFailure(e.message));
  }
}

function* fetchOrderFlowDistributionWatcher() {
  yield takeLatest(
    fetchOrderFlowDistribution.type,
    fetchOrderFlowDistributionWorker
  );
}

export default function* orderFlowDistributionSaga() {
  yield all([fetchOrderFlowDistributionWatcher()]);
}
