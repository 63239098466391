import styled from "styled-components/macro";

export const Container = styled.div`
  padding-top: 11px;
  height: 41%;
  @media (max-width: 1360px) {
    height: 34%;
  };
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
`;

export const Content = styled.div`
  display: flex;
  gap: 11px;
  overflow: auto;

  height: 98%;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: 100%;
`;

export const Wrap = styled.div`
  width: 20%;
`;

export const WrapMulti = styled.div`
  width: 40%;
`;

export const WrapItem = styled.div`
  padding: 8px;
`;

export const Title = styled.div`
  padding: 10px 8px;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TitleChart2 = styled.div`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ChartName = styled.div<{
  invisibleTitle?: boolean;
}>`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  visibility: ${({ invisibleTitle }) =>
    invisibleTitle ? "hidden" : "visible"};
`;

export const ChartWrapper = styled.div<{
  smallChart?: boolean;
}>`
  margin: 16px auto 24px;
`;

export const LegendContainer = styled.div`
  margin-top: 24px;
`;

export const LegendLabel = styled.div<{
  colorLabel?: string;
}>`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${({ colorLabel }) => colorLabel && `${colorLabel}`};
`;

export const LegendName = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};

  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;

export const LegendRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const LabelCell = styled.div`
  display: flex;
`;

export const FigureCell = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};

  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;

export const WrapContent = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-around;
`;

export const WrapChart2Item = styled.div`
  width: 50%;
`;
