import { toast } from "components/commons/Toast";
import {
  getPositionClose,
  getPositionOpen,
} from "core/http/apis/position-management";
import { IBasePairValueResponse } from "domain/models/BasePairValue";

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getListPositionCloseFailure,
  getListPositionCloseRequest,
  getListPositionCloseSuccess,
  getListPositionOpenFailure,
  getListPositionOpenRequest,
  getListPositionOpenSuccess,
  searchPositionCloseFailure,
  searchPositionCloseRequest,
  searchPositionCloseSuccess,
  searchPositionOpenFailure,
  searchPositionOpenRequest,
  searchPositionOpenSuccess,
} from "./redux";
function* getListPositionOpenWorker(
  action: ReturnType<typeof getListPositionOpenRequest>
) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      getPositionOpen,
      params
    );
    if (response?.statusCode === 0) {
      yield put(getListPositionOpenSuccess(response));
    } else {
      yield put(getListPositionOpenFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListPositionOpenFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchPositionOpenWorker(
  action: ReturnType<typeof searchPositionOpenRequest>
) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      getPositionOpen,
      params
    );
    if (response?.statusCode === 0) {
      yield put(searchPositionOpenSuccess(response));
    } else {
      yield put(searchPositionOpenFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchPositionOpenFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchPositionCloseWorker(
  action: ReturnType<typeof searchPositionCloseRequest>
) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      getPositionClose,
      params
    );
    if (response?.statusCode === 0) {
      yield put(searchPositionCloseSuccess(response));
    } else {
      yield put(searchPositionCloseFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchPositionCloseFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListPositionCloseWorker(
  action: ReturnType<typeof getListPositionCloseRequest>
) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      getPositionClose,
      params
    );
    if (response?.statusCode === 0) {
      yield put(getListPositionCloseSuccess(response));
    } else {
      yield put(getListPositionCloseFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListPositionCloseFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListPositionOpenWatcher() {
  yield takeLatest(getListPositionOpenRequest.type, getListPositionOpenWorker);
}
function* getListPositionCloseWatcher() {
  yield takeLatest(
    getListPositionCloseRequest.type,
    getListPositionCloseWorker
  );
}
function* searchPositionOpenWatcher() {
  yield takeLatest(searchPositionOpenRequest.type, searchPositionOpenWorker);
}
function* searchPositionCloseWatcher() {
  yield takeLatest(searchPositionCloseRequest.type, searchPositionCloseWorker);
}

export default function* getListPositionManagementSaga() {
  yield all([
    getListPositionOpenWatcher(),
    getListPositionCloseWatcher(),
    searchPositionCloseWatcher(),
    searchPositionOpenWatcher(),
  ]);
}
