import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const statisticHighestUpdownSelector = (state: RootState) =>
  state.statisticHighestUpdown;

export const statisticHighestUpdownListSelector = createSelector(
  statisticHighestUpdownSelector,
  (state) => state.list
);

export const statisticHighestUpdownLoadingSelector = createSelector(
  statisticHighestUpdownSelector,
  (state) => state.loading
);

export const statisticHighestUpdownFilterSelector = createSelector(
  statisticHighestUpdownSelector,
  (state) => state.filter
);

export const activeTabSelector = createSelector(
  statisticHighestUpdownSelector,
  (state) => state.activeTab
);
