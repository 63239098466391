import { FC, useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { TopForeignState } from "modules/market/redux/topForeign";
import numeral from "numeral";
import BlinkableCell from "./BlinkableCell";
import { CellWrapper } from "./styles";
import { marketTopForeignSelector } from "modules/market/redux/selectors";

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector.data?.[tickerName]?.colorcode
  );
};

const changePriceAmountSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector.data?.[tickerName]?.changepoint
  );
};

interface Props {
  ticker: string;
}

const ChangePriceAmountCell: FC<Props> = ({ ticker }) => {
  const textColor = useSelector(textColorSelector(ticker));
  const changePriceAmount = useSelector(changePriceAmountSelector(ticker));

  const formatterValue = useMemo(() => {
    if (typeof changePriceAmount === "number") {
      return numeral(changePriceAmount).format("0,0.00");
    }

    return "-";
  }, [changePriceAmount]);

  return (
    <CellWrapper
      className="market-price-cell center-for-value small-text"
      textcolor={textColor}
    >
      <BlinkableCell value={formatterValue} />
    </CellWrapper>
  );
};

export { ChangePriceAmountCell };
