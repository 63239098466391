import { HeaderContainer } from "./styles";
import { TopForeignMenu } from "./types";
import { FormattedMessage } from "react-intl";
import HeaderButton from "components/commons/ButtonOptional/HeaderButton";
import { useDispatch, useSelector } from "react-redux";
import { requestChangeTopForeignType } from "modules/market/redux/topForeign";
import { activeTabTopForeignSelector } from "modules/market/redux/selectors";

const topForeignTypes: TopForeignMenu[] = [
  "foreignNetValuesBuy",
  "foreignNetValuesSell",
  "foreignBuy",
  "foreignSell",
  "foreignBuyValue",
  "foreignSellValue",
];

function Header() {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabTopForeignSelector);
  function handleChangeTopForeignType(topForeignType: TopForeignMenu) {
    dispatch(requestChangeTopForeignType(topForeignType));
  }

  return (
    <HeaderContainer>
      <div className="row no-gutters">
        {topForeignTypes.map((topForeignType: TopForeignMenu) => {
          return (
            <div key={topForeignType} className="col-lg-2 col-md-2 col-sm-4">
              <HeaderButton
                active={activeTab === (topForeignType as TopForeignMenu)}
                value={topForeignType}
                onClick={handleChangeTopForeignType}
              >
                <FormattedMessage
                  id={`widgets.topForeign.headers.${topForeignType}`}
                />
              </HeaderButton>
            </div>
          );
        })}
      </div>
    </HeaderContainer>
  );
}

export default Header;
