import { createSelector } from "@reduxjs/toolkit";
import { Button, Input } from "components/commons";
import { toast } from "components/commons/Toast";
import {
  ISaveScreenerBody,
  ISaveScreenerParam,
} from "core/http/apis/screener/types";
import Storage from "helper/storage";
import {
  changeVisibleCreateNameModal,
  ReduxData as ScreenersState,
  resetScreenerFilterList,
  saveScreenerRequest,
} from "modules/screeners/redux";
import { ChangeEvent, FC, KeyboardEvent, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  ActionRow,
  ActionsContainer,
  BodyModal,
  ButtonWrapper,
  ContentWrapper,
  ErrorRow,
  HeaderModal,
  InputRow,
  InputRowWrapper,
  ModalLabel2,
} from "./styles";

const screenersSelector = (state: RootState) => state.screeners;

const visibleCreateNameModalSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.isVisibleCreateNameModal
);

const screenerFilterSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.filterList
);

const listMyScreenerSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) => screenersSelector.dataMyScreener.list
);

const Actions: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isVisibleModal = useSelector(visibleCreateNameModalSelector);
  const screenerFilter = useSelector(screenerFilterSelector);
  const listMyScreener = useSelector(listMyScreenerSelector);
  const [inputValue, setInputValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onFocusInputHandler = () => {
    setErrorMessage("");
  };

  const clickResetHandler = () => {
    if (screenerFilter.length === 0) return;
    dispatch(resetScreenerFilterList());
  };

  const clickSaveHandler = () => {
    if (screenerFilter.length === 0)
      return toast("screeners.tabs.screener.actions.emptyWarning", "error");
    dispatch(changeVisibleCreateNameModal(true));
  };

  const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) setErrorMessage("");
    setInputValue(e.target.value);
  };

  const clickConfirmModalHandler = () => {
    const isExistName = listMyScreener
      .map((item) => item?.screener?.name)
      .includes(inputValue);

    if (isExistName) {
      setErrorMessage(
        intl.formatMessage(
          { id: "screeners.card.modal.errorMessage" },
          { name: inputValue }
        )
      );
      return;
    }

    const masterAccount = Storage.getMasterAccount();

    const saveScreenerParam: ISaveScreenerParam = {
      userId: masterAccount,
    };

    const saveScreenerBody: ISaveScreenerBody = {
      screener: {
        name: inputValue,
        userId: masterAccount,
      },
      screenerFilter: screenerFilter,
    };

    dispatch(
      saveScreenerRequest({
        param: saveScreenerParam,
        body: saveScreenerBody,
      })
    );

    setInputValue("");
  };

  const hideModalHandler = () => {
    setInputValue("");
    setErrorMessage("");
    dispatch(changeVisibleCreateNameModal(false));
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === "Enter" || e?.keyCode === 13) {
      clickConfirmModalHandler();
    }
  };

  return (
    <ActionsContainer>
      <ButtonWrapper>
        <Button
          className="button-confirm"
          color="accept"
          onClick={clickResetHandler}
        >
          {intl.formatMessage({
            id: "screeners.tabs.screener.actions.reset",
          })}
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          className="button-confirm"
          color="gradientaAccept"
          onClick={clickSaveHandler}
          fill
        >
          {intl.formatMessage({
            id: "screeners.tabs.screener.actions.save",
          })}
        </Button>
      </ButtonWrapper>
      <Modal show={isVisibleModal} onHide={hideModalHandler} centered>
        <HeaderModal>
          <FormattedMessage id="screeners.card.modal.title" />
        </HeaderModal>
        <BodyModal>
          <ContentWrapper>
            <ModalLabel2>
              <FormattedMessage id="screeners.card.modal.screenerName" />
            </ModalLabel2>
            <InputRowWrapper>
              <InputRow>
                <Input
                  placeholder={intl.formatMessage({
                    id: "screeners.card.modal.placeholder",
                  })}
                  onChange={changeInputHandler}
                  value={inputValue}
                  onFocus={onFocusInputHandler}
                  onKeyDown={onKeyDownHandler}
                />
              </InputRow>
              {errorMessage && <ErrorRow>{errorMessage}</ErrorRow>}
            </InputRowWrapper>
          </ContentWrapper>
          <ActionRow>
            <ButtonWrapper>
              <Button
                className="button-confirm"
                color="accept"
                onClick={hideModalHandler}
              >
                {intl.formatMessage({
                  id: "screeners.card.modal.cancel",
                })}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                className="button-confirm"
                color="gradientaAccept"
                onClick={clickConfirmModalHandler}
                fill
                disabled={!!errorMessage}
              >
                {intl.formatMessage({
                  id: "screeners.card.modal.save",
                })}
              </Button>
            </ButtonWrapper>
          </ActionRow>
        </BodyModal>
      </Modal>
    </ActionsContainer>
  );
};

export default Actions;
