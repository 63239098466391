import { EMarketCodeNew, WatchlistGroupType } from "helper/consts";
import { ColManage, PutThroughListType } from "./types";

export const LIST_ITEM_HEIGHT = 28;
export const DERIVATIVE_DETAILS_HEIGHT = 516;

export const MAPPING_VALUE_WITH_TICKER_DATA = {
  tickerName: "secdetailinfo.seccd",
  ceilingPrice: "secdetailinfo.ceilingprice",
  floorPrice: "secdetailinfo.floorprice",
  referencePrice: "secdetailinfo.basicprice",
  bid3price: "secdetailinfo.best3bidprice",
  bid3volume: "secdetailinfo.best3bidqty",
  bid2price: "secdetailinfo.best2bidprice",
  bid2volume: "secdetailinfo.best2bidqty",
  bid1price: "secdetailinfo.best1bidpricestr",
  bid1volume: "secdetailinfo.best1bidqty",
  lastSalePrice: "secdetailinfo.lastprice",
  lastSaleChangePriceValue: "secdetailinfo.changepoint",
  lastSaleChangePercentageValue: "secdetailinfo.changepercent",
  lastSaleChangeVolumeValue: "secdetailinfo.lastqty",
  ask1price: "secdetailinfo.best1offerpricestr",
  ask1volume: "secdetailinfo.best1offerqty",
  ask2price: "secdetailinfo.best2offerprice",
  ask2volume: "secdetailinfo.best2offerqty",
  ask3price: "secdetailinfo.best3offerprice",
  ask3volume: "secdetailinfo.best3offerqty",
  summaryTotalSharesTradedmt: "secdetailinfo.totalqty",
  summaryTotalAmt: "secdetailinfo.totalamt",
  summaryHighestPrice: "secdetailinfo.highestprice",
  summaryLowestPrice: "secdetailinfo.lowestprice",
  summaryAveragePrice: "secdetailinfo.avgprice",
  summaryTotalSellQty: "secdetailinfo.totalroom",
  foreignTotalBuyQty: "secdetailinfo.buyforeignqty",
  foreignTotalSellQty: "secdetailinfo.sellforeignqty",
  foreignTotalCurrentRoom: "secdetailinfo.currentRoom",
  basicDILastTradingDate: "dataInfoBasic.dilasttradingdate",
  diPriceInfoUnderlyingBiasPrice: "dataDiPriceInfo.underlying.bias.value",
  summaryDIOpenInterest: "dataInfoSummary.diopeninterest.value",
  basicCWIssuerName: "secdetailinfo.issuer.data",
  basicCWLastTradingDate: "secdetailinfo.lasttradedate",
  basicUnderLyingSymbol: "secdetailinfo.baseseccd.data",
  underLyingLastPrice: "secdetailinfo.baseprice",
  basicCWExercisePrice: "secdetailinfo.execprice",
  basicCWExerciseRatio: "secdetailinfo.execRatio.data",
};

const FOREIGN = {
  children: {
    BID: {
      visible: true,
      minWidth: 60,
      name: "category.table.foreignBought",
      nameCol: "category.table.buy",
      sortName: MAPPING_VALUE_WITH_TICKER_DATA.foreignTotalBuyQty,
      parent: "FOREIGN",
      settingOrderIndex: 1,
      smallDeviceMinWidth: 48,
    },
    ASK: {
      visible: true,
      minWidth: 60,
      name: "category.table.foreignSold",
      nameCol: "category.table.sold",
      sortName: MAPPING_VALUE_WITH_TICKER_DATA.foreignTotalSellQty,
      parent: "FOREIGN",
      settingOrderIndex: 2,
      smallDeviceMinWidth: 48,
    },
    CURRENTROOM: {
      visible: true,
      minWidth: 75,
      name: "category.table.currentRoom",
      nameCol: "category.table.currentRoom",
      sortName: MAPPING_VALUE_WITH_TICKER_DATA.foreignTotalCurrentRoom,
      parent: "FOREIGN",
      fixed: true,
      settingOrderIndex: 3,
      smallDeviceMinWidth: 72,
    },
  },
  settingOrderIndex: 9,
};

const PRICE = {
  children: {
    HIGH: {
      visible: true,
      minWidth: 64,
      name: "category.table.high",
      parent: "PRICE",
      settingOrderIndex: 1,
      smallDeviceMinWidth: 46,
    },
    LOW: {
      visible: true,
      minWidth: 64,
      name: "category.table.low",
      parent: "PRICE",
      settingOrderIndex: 2,
      smallDeviceMinWidth: 46,
    },
    AVERAGE: {
      visible: true,
      minWidth: 64,
      name: "category.table.average",
      parent: "PRICE",
      settingOrderIndex: 3,
      smallDeviceMinWidth: 46,
    },
  },
  settingOrderIndex: 8,
};

export const columnsDataDefault: ColManage = {
  TICKER: {
    visible: true,
    minWidth: 64,
    name: "category.table.ticker",
    disable: true,
    settingOrderIndex: 1,
    smallDeviceMinWidth: 48,
  },
  CHART: {
    visible: false,
    minWidth: 72,
    fixed: true,
    settingOrderIndex: 2,
    smallDeviceMinWidth: 72,
  },
  SUMMARY: {
    children: {
      CEIL: {
        visible: true,
        minWidth: 64,
        name: "category.table.ceiling",
        parent: "SUMMARY",
        settingOrderIndex: 1,
        smallDeviceMinWidth: 46,
      },
      FLOOR: {
        visible: true,
        minWidth: 64,
        name: "category.table.floor",
        parent: "SUMMARY",
        settingOrderIndex: 2,
        smallDeviceMinWidth: 46,
      },
      REFER: {
        visible: true,
        minWidth: 64,
        name: "category.table.reference",
        parent: "SUMMARY",
        settingOrderIndex: 3,
        smallDeviceMinWidth: 46,
      },
    },
    settingOrderIndex: 3,
  },

  BID: {
    children: {
      PRICE3: {
        visible: true,
        minWidth: 64,
        name: "category.table.overBoughtPrice3",
        nameCol: "category.table.price3",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.bid3price,
        parent: "BID",
        settingOrderIndex: 3,
        smallDeviceMinWidth: 48,
      },
      VOLUME3: {
        visible: true,
        minWidth: 64,
        name: "category.table.overBoughtAmount3",
        nameCol: "category.table.amount3",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.bid3volume,
        parent: "BID",
        settingOrderIndex: 6,
        smallDeviceMinWidth: 48,
      },
      PRICE2: {
        visible: true,
        minWidth: 64,
        name: "category.table.overBoughtPrice2",
        nameCol: "category.table.price2",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.bid2price,
        parent: "BID",
        settingOrderIndex: 2,
        smallDeviceMinWidth: 48,
      },
      VOLUME2: {
        visible: true,
        minWidth: 64,
        name: "category.table.overBoughtAmount2",
        nameCol: "category.table.amount2",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.bid2volume,
        parent: "BID",
        settingOrderIndex: 5,
        smallDeviceMinWidth: 48,
      },
      PRICE1: {
        visible: true,
        minWidth: 64,
        name: "category.table.overBoughtPrice1",
        nameCol: "category.table.price1",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.bid1price,
        parent: "BID",
        settingOrderIndex: 1,
        smallDeviceMinWidth: 48,
      },
      VOLUME1: {
        visible: true,
        minWidth: 64,
        name: "category.table.overBoughtAmount1",
        nameCol: "category.table.amount1",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.bid1volume,
        parent: "BID",
        settingOrderIndex: 4,
        smallDeviceMinWidth: 48,
      },
    },
    settingOrderIndex: 4,
  },
  LAST_SALE: {
    children: {
      PRICE: {
        visible: true,
        minWidth: 64,
        name: "category.table.filledPrice",
        parent: "LAST_SALE",
        disable: true,
        settingOrderIndex: 1,
        smallDeviceMinWidth: 46,
      },
      CHANGE_PRICE: {
        visible: true,
        minWidth: 64,
        name: "category.table.filledDiff",
        parent: "LAST_SALE",
        disable: true,
        settingOrderIndex: 2,
        smallDeviceMinWidth: 46,
      },
      CHANGE_PERCENTAGE: {
        visible: false,
        minWidth: 64,
        name: "category.table.changePercentage",
        parent: "LAST_SALE",
        fixed: true,
        settingOrderIndex: 2,
        smallDeviceMinWidth: 46,
      },
      VOLUME: {
        visible: true,
        minWidth: 64,
        name: "category.table.filledAmount",
        parent: "LAST_SALE",
        disable: true,
        settingOrderIndex: 3,
        smallDeviceMinWidth: 46,
      },
    },
    settingOrderIndex: 5,
  },
  ASK: {
    children: {
      PRICE1: {
        visible: true,
        minWidth: 64,
        name: "category.table.overSoldPrice1",
        nameCol: "category.table.price1",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.ask1price,
        parent: "ASK",
        settingOrderIndex: 1,
        smallDeviceMinWidth: 46,
      },
      VOLUME1: {
        visible: true,
        minWidth: 64,
        name: "category.table.overSoldAmount1",
        nameCol: "category.table.amount1",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.ask1volume,
        parent: "ASK",
        settingOrderIndex: 4,
        smallDeviceMinWidth: 46,
      },
      PRICE2: {
        visible: true,
        minWidth: 64,
        name: "category.table.overSoldPrice2",
        nameCol: "category.table.price2",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.ask2price,
        parent: "ASK",
        settingOrderIndex: 2,
        smallDeviceMinWidth: 46,
      },
      VOLUME2: {
        visible: true,
        minWidth: 64,
        name: "category.table.overSoldAmount2",
        nameCol: "category.table.amount2",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.ask2volume,
        parent: "ASK",
        settingOrderIndex: 5,
        smallDeviceMinWidth: 46,
      },
      PRICE3: {
        visible: true,
        minWidth: 64,
        name: "category.table.overSoldPrice3",
        nameCol: "category.table.price3",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.ask3price,
        parent: "ASK",
        settingOrderIndex: 3,
        smallDeviceMinWidth: 46,
      },
      VOLUME3: {
        visible: true,
        minWidth: 64,
        name: "category.table.overSoldAmount3",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.ask3volume,
        nameCol: "category.table.amount3",
        parent: "ASK",
        settingOrderIndex: 6,
        smallDeviceMinWidth: 46,
      },
    },
    settingOrderIndex: 6,
  },
  VOLUME: {
    visible: true,
    minWidth: 75,
    name: "category.table.sumAmount",
    disable: true,
    settingOrderIndex: 7,
    smallDeviceMinWidth: 72,
  },
  AMT: {
    visible: false,
    minWidth: 75,
    name: "widgets.openPosition.valuePositionSummary",
    fixed: true,
    settingOrderIndex: 7,
    smallDeviceMinWidth: 72,
  },
  PRICE,
  FOREIGN,
};

export const columnsPrice: ColManage = {
  PRICE,
};

export const columnsForeign: ColManage = {
  FOREIGN,
};

export const columnsDataPutThrough: ColManage = {
  BID_ASK: {
    children: {
      STOCK: {
        visible: true,
        minWidth: 80,
        name: "category.table.stock",
        fixed: true,
        settingOrderIndex: 1,
      },
      PRICE: {
        visible: true,
        minWidth: 50,
        name: "category.table.price",
        fixed: true,
        settingOrderIndex: 2,
      },
      VOLUME: {
        visible: true,
        minWidth: 80,
        name: "category.table.volume",
        fixed: true,
        settingOrderIndex: 3,
      },
      TIME: {
        visible: true,
        minWidth: 80,
        name: "category.table.time",
        fixed: true,
        settingOrderIndex: 4,
      },
    },
    settingOrderIndex: 1,
  },
  MATCHED: {
    children: {
      STOCK: {
        visible: true,
        minWidth: 80,
        name: "category.table.stock",
        fixed: true,
        settingOrderIndex: 1,
      },
      PRICE: {
        visible: true,
        minWidth: 50,
        name: "category.table.price",
        fixed: true,
        settingOrderIndex: 2,
      },
      VOLUME: {
        visible: true,
        minWidth: 80,
        name: "category.table.volume",
        fixed: true,
        settingOrderIndex: 3,
      },
      TRANSACTION_VALUE: {
        visible: true,
        minWidth: 80,
        name: "category.table.transactionValue",
        fixed: true,
        settingOrderIndex: 4,
      },
      TIME: {
        visible: true,
        minWidth: 80,
        name: "category.table.time",
        fixed: true,
        settingOrderIndex: 7,
      },
    },
    settingOrderIndex: 2,
  },
};

export const STRING_FIELD_COLUMNS = [
  MAPPING_VALUE_WITH_TICKER_DATA.basicUnderLyingSymbol,
  MAPPING_VALUE_WITH_TICKER_DATA.basicCWIssuerName,
];

export const DATE_FIELD_COLUMNS = [
  MAPPING_VALUE_WITH_TICKER_DATA.basicDILastTradingDate,
  MAPPING_VALUE_WITH_TICKER_DATA.basicCWLastTradingDate,
];

export const columnsCoverWarrant: ColManage = {
  COVER_WARRANT: {
    settingOrderIndex: 4,
    children: {
      CKCS: {
        visible: true,
        minWidth: 75,
        name: "category.table.ckcs",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.basicUnderLyingSymbol,
        fixed: true,
        settingOrderIndex: 3,
        smallDeviceMinWidth: 75,
      },
      PRICE_CKCS: {
        visible: true,
        minWidth: 80,
        name: "category.table.price_ckcs",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.underLyingLastPrice,
        fixed: true,
        settingOrderIndex: 4,
        smallDeviceMinWidth: 80,
      },
      BREAK_EVEN_POINT: {
        visible: true,
        minWidth: 90,
        name: "category.table.break_even_point",
        // sortName: MAPPING_VALUE_WITH_TICKER_DATA.underLyingLastPrice,
        fixed: true,
        settingOrderIndex: 5,
        smallDeviceMinWidth: 90,
      },
      TH: {
        visible: true,
        minWidth: 75,
        name: "category.table.th",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.basicCWExercisePrice,
        fixed: true,
        settingOrderIndex: 6,
        smallDeviceMinWidth: 75,
      },
      RATIO: {
        visible: true,
        minWidth: 75,
        name: "category.table.ratio",
        // sortName: MAPPING_VALUE_WITH_TICKER_DATA.basicCWExerciseRatio,
        fixed: true,
        settingOrderIndex: 7,
        smallDeviceMinWidth: 75,
      },
    },
  },
};

export const columnsCoverWarrantHead: ColManage = {
  COVER_WARRANT_HEAD: {
    settingOrderIndex: 4,
    children: {
      TCPH: {
        visible: true,
        minWidth: 75,
        name: "category.table.tcph",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.basicCWIssuerName,
        fixed: true,
        settingOrderIndex: 1,
        smallDeviceMinWidth: 75,
      },
      GDCC: {
        visible: true,
        minWidth: 75,
        name: "category.table.gdcc",
        sortName: MAPPING_VALUE_WITH_TICKER_DATA.basicCWLastTradingDate,
        fixed: true,
        settingOrderIndex: 2,
        smallDeviceMinWidth: 75,
      },
    },
  },
};
export const putThrough: PutThroughListType[] = [
  {
    name: EMarketCodeNew.HSX_IDX.toString(),
    groupType: WatchlistGroupType.PUT_THROUGH,
    displayName: "HSX",
  },
  {
    name: EMarketCodeNew.HNX_IDX.toString(),
    groupType: WatchlistGroupType.PUT_THROUGH,
    displayName: "HNX",
  },
  {
    name: EMarketCodeNew.UPCOM_IDX.toString(),
    groupType: WatchlistGroupType.PUT_THROUGH,
    displayName: "UPCOM",
  },
];

export const WATCH_LIST_FIXED_TABS = {
  DERIVATIVE: "Derivative",
  COVER_WARRANT: "CoverWarrant",
  LISTED: "Listed",
  INDUSTRY: "Industry",
  PUT_THROUGH: "PutThrough",
  ODDLOT: "OddLot",
};

export enum TypeScroll {
  NORMAL = "normal",
  INFINITY = "infinity",
}

export const TimeScroll = 2000; //miliseconds
