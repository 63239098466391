import { Input } from "components/commons";
import { marginAccountInfoSelector } from "modules/margin-contract/selectors";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RowInfo, RowItem, Label, Content, Container } from "./styles";

const MarginAccountInfo: FC = () => {
  // const intl = useIntl();
  const marginAccountInfo = useSelector(marginAccountInfoSelector);
  return (
    <Container>
      <RowInfo>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.subAccoNo" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.subAccoNo} />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.contrNo" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.contrNo} />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.custGroupName" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.custGroupName} />
          </Content>
        </RowItem>
        <RowItem></RowItem>
      </RowInfo>
      <RowInfo>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.retentionRate" />
          </Label>
          <Content>
            <Input
              disabled
              value={
                marginAccountInfo.retentionRate
                  ? numeral(marginAccountInfo.retentionRate).format("0,0.00000")
                  : ""
              }
            />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.loanDays" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.loanDays} />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.warningDays" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.warningDays} />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.contrDateFrom" />
          </Label>
          <Content>
            <Input
              disabled
              value={
                marginAccountInfo.contrDateFrom
                  ? moment(marginAccountInfo.contrDateFrom, "YYYYMMDD").format(
                      "DD/MM/YYYY"
                    )
                  : ""
              }
            />
          </Content>
        </RowItem>
      </RowInfo>
      <RowInfo>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.warningRate" />
          </Label>
          <Content>
            <Input
              disabled
              value={
                marginAccountInfo.warningRate
                  ? numeral(marginAccountInfo.warningRate).format("0,0.00000")
                  : ""
              }
            />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.freeDays" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.freeDays} />
          </Content>
        </RowItem>
        <RowItem></RowItem>
        <RowItem></RowItem>
      </RowInfo>
      <RowInfo>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.callRate" />
          </Label>
          <Content>
            <Input
              disabled
              value={
                marginAccountInfo.callRate
                  ? numeral(marginAccountInfo.callRate).format("0,0.00000")
                  : ""
              }
            />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.tnType" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.tnType} />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.sourceLoan" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.sourceLoan} />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.brokerCd" />
          </Label>
          <Content>
            <Input disabled value={marginAccountInfo.brokerCd} />
          </Content>
        </RowItem>
      </RowInfo>
      <RowInfo>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.marginLimit" />
          </Label>
          <Content>
            <Input
              disabled
              value={
                marginAccountInfo.marginLimit
                  ? numeral(marginAccountInfo.marginLimit).format("0,0")
                  : ""
              }
            />
          </Content>
        </RowItem>
        <RowItem>
          <Label>
            <FormattedMessage id="marginContract.info.marginLimitGrp" />
          </Label>
          <Content>
            <Input
              disabled
              value={
                marginAccountInfo.marginLimitGrp
                  ? numeral(marginAccountInfo.marginLimitGrp).format("0,0")
                  : ""
              }
            />
          </Content>
        </RowItem>
        <RowItem></RowItem>
        <RowItem></RowItem>
      </RowInfo>
    </Container>
  );
};
export default MarginAccountInfo;
