import styled from "styled-components/macro";

export const Container = styled.div`
  padding: 16px 8px;
`;

export const ChartName = styled.div<{
  invisibleTitle?: boolean;
}>`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  visibility: ${({ invisibleTitle }) =>
    invisibleTitle ? "hidden" : "visible"};
`;

export const ChartWrapper = styled.div<{
  smallChart?: boolean;
}>`
  margin: 16px auto 24px;
`;

export const LegendContainer = styled.div``;

export const LegendRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const LegendLabel = styled.div<{
  colorLabel?: string;
}>`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${({ colorLabel }) => colorLabel && `${colorLabel}`};
`;

export const LegendName = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};

  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;

export const LabelCell = styled.div`
  display: flex;
`;

export const FigureCell = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};

  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;
