/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { dataColumns } from "./constants";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { Container, HeaderCell, IconWrapper, TableCellContent } from "./styles";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListHistorySubscribe,
  selectSearchHistorySubscribe,
} from "modules/subscribeBuy/selectors";
import { ModalConfirm } from "components/commons";
import { useState, useMemo } from "react";
import { deleteCapitalIncreaseApp } from "core/http/apis/subscribeBuy";
import { toast } from "components/commons/Toast";
import { getDataHistorySubscribe } from "modules/subscribeBuy/redux";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";

const TableData = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const fetchMoreData = () => {};
  const data = useSelector(selectListHistorySubscribe);
  const [modal, setModal] = useState(false);
  const [itemDelete, setItemDelete] = useState<any>();
  const { subAccoNo } = useSelector(selectSearchHistorySubscribe);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return [
      {
        label: intl.formatMessage({
          id: "subscribe.content.historySubscribe.search.option.valueAll",
        }),
        value: "",
      },
      ...list,
    ];
  }, [listAcc]);

  const columnAction = [
    {
      Header: (
        <HeaderCell align={"center"} width={"30px"}>
          <FormattedMessage id="transferInternal.historyTransfer.table.editDelete" />
        </HeaderCell>
      ),
      accessor: "mFromDate",
      Cell: (props: any) => {
        const currentRow = props?.row?.original;
        return (
          <TableCellContent align={"center"}>
            {props?.row?.original?.status === 0 && (
              <IconWrapper onClick={() => clickDeleteHandler(currentRow)}>
                <IconTrash />
              </IconWrapper>
            )}
          </TableCellContent>
        );
      },
    },

    ...dataColumns,
  ];

  const clickDeleteHandler = (item: any) => {
    setModal(true);
    setItemDelete(item);
  };

  const setConfirm = async () => {
    const res = await deleteCapitalIncreaseApp({
      applyId: itemDelete?.applyId ?? 0,
      lastUpdateTime: itemDelete?.lastUpdateTime ?? "",
    });
    if (res?.statusCode === 0) {
      toast(
        "subscribe.content.historySubscribe.modalDelete.messageSuccess",
        "success"
      );
      dispatch(
        getDataHistorySubscribe({
          subAccoNo: subAccoNo ? subAccoNo : accountOptions[0].value,
        })
      );
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
    setModal(false);
  };

  return (
    <>
      <Container id="">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columnAction}
          data={data ? data : []}
          enableInfinity={true}
          scrollableTarget={"transferLocalTable"}
          isHasFooter={false}
        />
        <ModalConfirm
          visibleModalConfirm={modal}
          setVisibleModalConfirm={setModal}
          setConfirm={setConfirm}
          title={intl.formatMessage({
            id: "subscribe.content.historySubscribe.modalDelete.title",
          })}
          content={intl.formatMessage({
            id: "subscribe.content.historySubscribe.modalDelete.content",
          })}
        />
      </Container>
    </>
  );
};

export default TableData;
