import { FC } from "react";
import { Wrap } from "./styles";
import { ReactComponent as IconInfo } from "assets/image/svg/ic_info.svg";
type InputRowProps = {
  title: string;
  fill?: boolean;
  isRequired?: boolean;
  iconInfo?: boolean;
  mixStyleProInvestor?: boolean;
  handleClick?: Function;
};
const InputRow: FC<InputRowProps> = ({
  title,
  children,
  fill = false,
  isRequired = false,
  iconInfo = false,
  handleClick = () => {},
  mixStyleProInvestor = false,
}) => {
  return (
    <Wrap
      mixStyleProInvestor={mixStyleProInvestor}
      className={mixStyleProInvestor ? "d-flex" : "d-flex align-items-center"}
    >
      <p className={fill ? "flex-fill" : ""}>
        {title} {isRequired && <span>*</span>}
        {iconInfo ? (
          <IconInfo className="icon-info" onClick={() => handleClick()} />
        ) : (
          ""
        )}
      </p>
      <div className="content">{children}</div>
    </Wrap>
  );
};
export default InputRow;
