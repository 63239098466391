import classNames from "classnames";
import { TextColor } from "./style";
import moment from "moment";

interface Props {
  time: number;
  className?: string;
}

function TimeCell({ time, className }: Props) {
  return (
    <TextColor className={classNames(`${className} text-right`)}>
      {moment(time, "Hmmss").format("HH:mm:ss")}
    </TextColor>
  );
}

export default TimeCell;
