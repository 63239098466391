import { HeaderCell, TableCellContent, TableCellContentstatus } from "./styles";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import numeral from "numeral";

const flexEnd = "flex-end";
const flexStart = "flex-start";
const center = "center";

export const dataColumns = [
  {
    Header: (
      <HeaderCell align={center} width={"30px"}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.date" />
      </HeaderCell>
    ),
    accessor: "regDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.Subacc" />
      </HeaderCell>
    ),
    accessor: "subAccountNo",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.secCd" />
      </HeaderCell>
    ),
    accessor: "secCd",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.actionType" />
      </HeaderCell>
    ),
    accessor: "actionType",
    Cell: (props: any) => {
      const currentRow = props?.row?.original;
      return (
        <TableCellContent align={flexStart}>
          <FormattedMessage
            id={
              currentRow?.actionType === 1
                ? "subscribe.typeForm.typeAdd"
                : "subscribe.typeForm.typeRemove"
            }
          />
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.number" />
      </HeaderCell>
    ),
    accessor: "appplyQty",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.price" />
      </HeaderCell>
    ),
    accessor: "price",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value * 1000).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.intoMoney" />
      </HeaderCell>
    ),
    accessor: "amount",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="subscribe.content.historySubscribe.table.status" />
      </HeaderCell>
    ),
    accessor: "status",
    Cell: (props: any) => {
      return (
        <TableCellContentstatus
          align={flexStart}
          color={
            props.value === 1 ? "green" : props.value === 0 ? "yellow" : "red"
          }
        >
          <FormattedMessage
            id={`subscribe.statusHistory.value${props.value}`}
          />
        </TableCellContentstatus>
      );
    },
  },
];
