import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IndustryState = {
  selectedIndustryType: string;
};

const initialState: IndustryState = {
  selectedIndustryType: "",
};

const industrySlice = createSlice({
  name: "industrySlice",
  initialState,
  reducers: {
    changeIndustryType: (state, action: PayloadAction<string>) => {
      state.selectedIndustryType = action.payload;
    },
  },
});

export const { changeIndustryType } = industrySlice.actions;

export default industrySlice.reducer;
