import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const holdUnholdBankAccountSelector = (state: RootState) =>
  state.holdUnholdBankAccount;

export const activeTabSelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.activeTab
);

export const loadingSearchDataSelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.loading
);

export const filterAdvanceSelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.filter
);

export const holdUnholdBankAccountEditSelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.holdUnholdBankAccount
);
export const isShowConfirmModalSelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.isShowConfirmModal
);

export const statusEntryBankUtilitySelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.statusEntryBankUtility
);
export const statusDeleteSelector = createSelector(
  holdUnholdBankAccountSelector,
  (state) => state.statusDelete
);
