import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "components/commons/Toast";
// import { getWatchListsApi, saveWatchlist } from "core/grpc/watchlist";
import {
  getListTickersNew,
  getStreamPriceInfoFulls,
  getWatchListOdd,
  getWatchListsApiNew,
} from "core/grpc";
import {
  addTickerForWatchlist,
  deleteWatchlist,
  getWatchlist,
  postWatchlist,
  putWatchlist,
  putTickerForWatchlist,
  deleteTickerForWatchlist,
  addTickersToWatchlist,
} from "core/http/apis/watchlists";
import {
  addTickerForWatchlistParams,
  IAddTickersToWatchlistResponse,
  ItemWatchlistOwner,
  putWatchlistParams,
  responseAddTickerForWatchlist,
  WatchlistOwnerData,
} from "core/http/apis/watchlists/types";
import {
  MarketInitDataItem,
  MrktSecInfoItem,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";
import { EMarketCodeNew, SecType, WatchlistGroupType } from "helper/consts";
import Storage from "helper/storage";

import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import { loginSuccess } from "modules/auth/redux";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import {
  removeTickerInfo,
  resetTickerListTable,
  setCategoriesInfo,
  setChartVisible,
  setInfoBaseseccd,
} from "modules/categories/redux/categoriesInfo";
import { changeTypeCoverWarrant } from "modules/categories/redux/coverWarrant";
import { changeTypeDerivative } from "modules/categories/redux/derivative";
import { changeIndustryType } from "modules/categories/redux/industry";
import { changeListedType } from "modules/categories/redux/listed";
import { changeTypePutThrough } from "modules/categories/redux/putThrough";
import { ChangeCurrentListPayload, ListType } from "modules/categories/types";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { RootState, store } from "redux/store";
import { WATCH_LIST_FIXED_TABS } from "../constant";
import {
  addTickerStart,
  addTickersToAnonymousList,
  addTickersToListByName,
  addTickerToAnonymousList,
  addTickerToListByName,
  addTickerToUserFavListFailure,
  addTickerToUserFavListSuccess,
  changeColumn,
  changeCurrentList,
  changeFirstLoadPage,
  changeLoading,
  changeTickersListSelected,
  createAnonymousFavoriteList,
  createUserFavoriteListFailure,
  createUserFavoriteListRequest,
  createUserFavoriteListSuccess,
  editAnonymousFavoriteList,
  editUserFavoriteListFailure,
  editUserFavoriteListRequest,
  editUserFavoriteListSuccess,
  getWatchListsFailed,
  getWatchListsOwnerStart,
  getWatchListsOwnerSuccess,
  getWatchListsStart,
  getWatchListsSuccessNew,
  removeAnonymousFavoriteList,
  removeFavoriteListRequest,
  removeTicker,
  removeTickerFromAnonymousList,
  removeTickerFromUserFavListFailure,
  removeTickerFromUserFavListSuccess,
  removeUserFavoriteListSuccess,
  restoreTickersList,
  setTickerFocus,
  sortAnonymousList,
  sortTickerList,
  sortTickerUserFavListFailure,
  sortTickerUserFavListSuccess,
  synchronizedTableWithCurrentListCode,
  syncLocalFavoriteListToServerRequest,
  TickersListSelected,
} from "../redux";
import {
  selectedListedTypeSelector,
  tickerListTableSelector,
  tickersListSelectedSelector,
} from "../redux/selectors";
import putThroughSaga from "./putThroughSaga";
import GlobalData from "helper/globalData";
import { subscribeTickers } from "core/wss";
import { changeOddLotType } from "../redux/oddLot";
import { v4 as uuid } from "uuid";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";
import logTrackingSaga from "./logTrackingSaga";

function* getWatchListSagaNew(action: ReturnType<typeof getWatchListsStart>) {
  try {
    const tickersNew: MrktSecInfoItem.AsObject[] = yield call(
      getListTickersNew
    );

    const hoseTicker: string[] = [];
    const hnxTicker: string[] = [];
    const upcomTicker: string[] = [];
    const etfTicker: string[] = [];
    const allCwTickers: string[] = [];
    const derVN30Ticker: string[] = [];
    const derTPCPTicker: string[] = [];
    const sortedTickers = sortBy(tickersNew, (ticker) => ticker.seccd);

    sortedTickers.forEach((item) => {
      if (
        item.marketcd === EMarketCodeNew.HSX_IDX &&
        item.sectype !== SecType.E &&
        item.sectype !== SecType.CW &&
        item.sectype !== SecType.D
      ) {
        hoseTicker.push(item.seccd);
      }
      if (
        item.marketcd === EMarketCodeNew.HNX_IDX &&
        item.sectype !== SecType.D
      ) {
        hnxTicker.push(item.seccd);
      }
      if (
        item.marketcd === EMarketCodeNew.UPCOM_IDX &&
        item.sectype !== SecType.D
      ) {
        upcomTicker.push(item.seccd);
      }
      if (
        item.marketcd === EMarketCodeNew.DER_IDX &&
        item.sectype === SecType.S
      ) {
        derVN30Ticker.push(item.seccd);
      }
      if (
        item.marketcd === EMarketCodeNew.DER_IDX &&
        item.sectype === SecType.U
      ) {
        derTPCPTicker.push(item.seccd);
      }
      if (item.sectype === SecType.E) {
        etfTicker.push(item.seccd);
      }
      if (item.sectype === SecType.CW) {
        allCwTickers.push(item.seccd);
      }
    });

    const listing100_200_300: ListType[] = [
      {
        groupType: "",
        name: "HOSE",
        nameEn: "HOSE",
        nameVi: "HOSE",
        type: 14,
        code: "100",
        listTickers: hoseTicker,
      },
      {
        groupType: "",
        name: "HNX",
        nameEn: "HNX",
        nameVi: "HNX",
        type: 14,
        code: "200",
        listTickers: hnxTicker,
      },
      {
        groupType: "",
        name: "UPCOM",
        nameEn: "UPCOM",
        nameVi: "UPCOM",
        type: 14,
        code: "300",
        listTickers: upcomTicker,
      },
    ];

    const derList: ListType[] = [
      {
        name: "category.derivative.vn30",
        groupType: WatchlistGroupType.DERIVATIVE,
        type: 12,
        listTickers: derVN30Ticker,
      },
      {
        name: "category.derivative.tpcp",
        groupType: WatchlistGroupType.DERIVATIVE,
        type: 12,
        listTickers: derTPCPTicker,
      },
    ];

    const cwList: ListType[] = [
      {
        name: "category.coverWarrant.full",
        groupType: WatchlistGroupType.CW,
        type: 12,
        listTickers: allCwTickers,
      },
    ];
    const anonymousFavoriteList: ListType[] = [];
    const userFavoriteList: ListType[] = [];
    const ownerList: ListType[] = [];
    const etfList: ListType[] = [
      {
        name: "ETF",
        groupType: WatchlistGroupType.ETF,
        type: 13,
        listTickers: etfTicker,
      },
    ];

    // Call api lấy listed, industries
    // New

    const watchlists: MarketInitDataItem.AsObject = yield call(
      getWatchListsApiNew
    );
    const sortedListing: ListType[] = sortBy(
      watchlists.basketinfolist?.itembasketinfoList,
      ["indexcd"],
      ["asc"]
    )
      .filter((value) => ![100, 200, 300].includes(value.indexcd))
      .map((item) => ({
        groupType: WatchlistGroupType.LISTED,
        name: item.indexname.replace("_IDX", ""),
        nameEn: item.indexname,
        nameVi: item.indexname.replace("_IDX", ""),
        type: 14,
        code: item.indexname.toString(),
        listTickers: orderBy(item.seccdlist.split("; ")),
      }));

    const sortedIndustryList: ListType[] = sortBy(
      watchlists.mrktindustrieslist?.itemmrktindustriesList,
      ["id"],
      ["asc"]
    ).map((item) => ({
      groupType: WatchlistGroupType.INDUSTRY,
      name: item.code,
      nameEn: item.nameen,
      nameVi: item.namevn,
      type: 1,
      code: item.code.toString(),
      listTickers: orderBy(item.seccdlist.split("; ")),
    }));

    yield put(
      getWatchListsSuccessNew({
        tickers: sortedTickers,
        languageType: store.getState().language.type,
        etfList,
        listed: [...listing100_200_300, ...sortedListing],
        derList,
        cwList,
        ownerList,
        anonymousFavoriteList,
        userFavoriteList,
        industryTickersList: sortedIndustryList,
      })
    );

    //Persist selected list ticker form cache
    const tickersListSelected: TickersListSelected = yield select(
      tickersListSelectedSelector
    );
    if (tickersListSelected) {
      //Clear info tickers before restore
      yield put(resetTickerListTable());
      yield put(restoreTickersList(tickersListSelected));
    }

    const isAuthenticated: boolean = yield select(isAuthenticatedSelector);

    if (isAuthenticated) {
      yield put(getWatchListsOwnerStart());
    } else {
      yield put(changeFirstLoadPage(false));
    }
  } catch (err) {
    yield put(changeFirstLoadPage(false));
    yield put(getWatchListsFailed(err));
  }
}

function* getWatchListOwnerSaga() {
  try {
    const userFavoriteList: ListType[] = [];
    const ownerList: ListType[] = [];
    const watchListOwner: WatchlistOwnerData = yield call(
      getWatchlist,
      Storage.getMasterAccount()
    );
    watchListOwner.data.forEach((item) => {
      if (item.type === "OWNER") {
        const arrayDetails = orderBy(item.details, ["secCd"], ["asc"]);
        ownerList.push({
          name: "Owner",
          groupType: WatchlistGroupType.OWNER,
          type: 4,
          listTickers: arrayDetails?.map((value) => value.secCd) || [],
        });
      }
      if (item.type === "CUSTOMER") {
        const arrayDetails = orderBy(item.details, ["priority"], ["desc"]);
        userFavoriteList.push({
          nameEn: item.nameEn,
          nameVi: item.nameVi,
          name: item.id?.toString() || "",
          groupType: WatchlistGroupType.CUSTOM,
          code: item.id?.toString(),
          type: 4,
          listTickers: arrayDetails?.map((value) => value.secCd) || [],
          listId: arrayDetails?.map((value) => value.id || 0) || [],
        });
      }
    });
    yield put(
      getWatchListsOwnerSuccess({
        ownerList,
        userFavoriteList,
      })
    );
    yield put(changeFirstLoadPage(false));
  } catch (err) {
    yield put(changeFirstLoadPage(false));
    yield put(getWatchListsFailed(err));
  }
}

function* createWatchlistSaga(
  action: ReturnType<typeof createUserFavoriteListRequest>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.createWatchList,
    data: { name: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);

  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );

  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );

  const newList: ListType = {
    name: uuid(),
    nameVi: action.payload,
    nameEn: action.payload,
    groupType: WatchlistGroupType.CUSTOM,
    type: 4,
    listTickers: [],
  };

  if (!isAuthenticated) {
    yield put(createAnonymousFavoriteList(newList));
  } else {
    try {
      const params: putWatchlistParams = {
        id: "0",
        nameVi: action.payload,
        nameEn: action.payload,
      };
      const watchListOwner: ItemWatchlistOwner = yield call(
        postWatchlist,
        params
      );
      const newUserFavoriteList = [...userFavoriteList];
      newUserFavoriteList.push({
        nameEn: watchListOwner.nameEn,
        nameVi: watchListOwner.nameVi,
        name: watchListOwner.id?.toString() || "",
        groupType: WatchlistGroupType.LISTED,
        code: watchListOwner.id?.toString(),
        type: 4,
        listTickers: watchListOwner.details?.map((value) => value.secCd) || [],
      });

      yield put(createUserFavoriteListSuccess(newUserFavoriteList));
    } catch (error: any) {
      toast(error.code, "error", error.description, true);
      yield put(createUserFavoriteListFailure());
    }
  }
}

function* editWatchlistSaga(
  action: ReturnType<typeof editUserFavoriteListRequest>
) {
  const payload = action.payload;
  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.editWatchList,
    data: { name: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);
  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );

  if (!isAuthenticated) {
    yield put(editAnonymousFavoriteList(payload));
  } else {
    try {
      const params: putWatchlistParams = {
        id: userFavoriteList[action.payload.index].code!,
        nameVi: action.payload.nameEdited,
        nameEn: action.payload.nameEdited,
      };
      const watchListOwner: ItemWatchlistOwner = yield call(
        putWatchlist,
        params
      );
      const newUserFavoriteList = [...userFavoriteList];
      newUserFavoriteList[action.payload.index] = {
        nameEn: watchListOwner.nameEn,
        nameVi: watchListOwner.nameVi,
        name: watchListOwner.id?.toString() || "",
        groupType: WatchlistGroupType.LISTED,
        code: watchListOwner.id?.toString(),
        type: 4,
        listTickers: watchListOwner.details?.map((value) => value.secCd) || [],
      };

      yield put(editUserFavoriteListSuccess(newUserFavoriteList));
    } catch (error: any) {
      toast(error.code, "error", error.description, true);
      yield put(editUserFavoriteListFailure());
    }
  }
}

function* removeItemFromCustomerListWorker(
  action: ReturnType<typeof removeFavoriteListRequest>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.removeWatchList,
    data: { name: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);

  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );

  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );

  if (!isAuthenticated) {
    yield put(removeAnonymousFavoriteList(action.payload));
  } else {
    try {
      yield call(deleteWatchlist, action.payload.code!);

      const favoriteList = [...userFavoriteList];
      const index = favoriteList.findIndex(
        (item) =>
          item.name === action.payload.name && item.code === action.payload.code
      );

      if (index !== -1) {
        favoriteList.splice(index, 1);
      }

      yield put(removeUserFavoriteListSuccess(favoriteList));
    } catch (e) {}
  }
}

function* addTickerToFavoriteListSaga({
  payload: tickerName,
}: ReturnType<typeof addTickerStart>) {
  const currentListCode: string = yield select(
    (state: RootState) => state.categories.root.currentListCode
  );
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.updateWatchList,
    data: { add: tickerName },
  };
  Storage.commonPushLogFirebase(resLogData);

  const { type } = yield select(tickersListSelectedSelector);
  if (type === WatchlistGroupType.CUSTOM) {
    //trường hợp danh mục yêu thích
    yield put(addTickerToListByName({ tickerName, listName: currentListCode }));

    const resLogData = {
      eventGroup: GroupEvent.market,
      event: RealtimeConst.addTickerToWatchList,
      data: { tickerName: tickerName },
    };
    Storage.commonPushLogFirebase(resLogData);
  } else {
    const resLogData = {
      eventGroup: GroupEvent.market,
      event: RealtimeConst.inputStock,
      data: { tickerName: tickerName },
    };
    Storage.commonPushLogFirebase(resLogData);

    const infoTicker = GlobalData.getTickerInfoNew(tickerName);
    const tickerList: string[] = yield select(tickerListTableSelector);

    if (
      type !== WatchlistGroupType.PUT_THROUGH &&
      tickerList.includes(tickerName)
    ) {
      yield put(setTickerFocus(tickerName));
      return;
    }

    if (infoTicker.sectype === SecType.CW) {
      // trường hợp mã chứng quyền
      if (type !== WatchlistGroupType.CW) {
        yield put(
          changeCurrentList({ name: "CoverWarrant", isDeleteTicker: false })
        );
        yield put(
          changeTickersListSelected({
            name: "CoverWarrant",
            type: WatchlistGroupType.CW,
          })
        );
      }
    } else {
      // trường hợp sàn 100,200,300
      const listedType: string = yield select(selectedListedTypeSelector);
      if (listedType !== infoTicker.marketcd.toString()) {
        yield put(changeLoading(true));
        yield put(changeListedType(infoTicker.marketcd.toString()));
        yield put(
          changeTickersListSelected({
            name: infoTicker.marketcd.toString(),
            type: WatchlistGroupType.LISTED,
          })
        );
      }

      if (type !== WatchlistGroupType.LISTED) {
        yield put(changeCurrentList({ name: "Listed", isDeleteTicker: false }));
      }
    }
    yield put(setTickerFocus(tickerName));
  }
}

function* handleChangeCurrentListSaga(
  action: PayloadAction<ChangeCurrentListPayload>
) {
  const selectedTab = action.payload.name;
  yield put(resetTickerListTable());
  if (!Object.values(WATCH_LIST_FIXED_TABS).includes(selectedTab)) {
    yield put(changeTypeDerivative(""));
    yield put(changeTypeCoverWarrant(""));
    yield put(changeIndustryType(""));
    yield put(changeListedType(""));
    yield put(changeTypePutThrough(""));
    yield put(changeOddLotType(""));
  }
  if (selectedTab === WATCH_LIST_FIXED_TABS.COVER_WARRANT) {
    yield put(changeTypeDerivative(""));
    yield put(changeIndustryType(""));
    yield put(changeListedType(""));
    yield put(changeTypePutThrough(""));
    yield put(changeOddLotType(""));
  }
  if (selectedTab === WATCH_LIST_FIXED_TABS.LISTED) {
    yield put(changeTypeDerivative(""));
    yield put(changeTypeCoverWarrant(""));
    yield put(changeIndustryType(""));
    yield put(changeTypePutThrough(""));
    yield put(changeOddLotType(""));
  }
  if (selectedTab === WATCH_LIST_FIXED_TABS.INDUSTRY) {
    yield put(changeTypeDerivative(""));
    yield put(changeTypeCoverWarrant(""));
    yield put(changeListedType(""));
    yield put(changeTypePutThrough(""));
    yield put(changeOddLotType(""));
  }
  if (selectedTab === WATCH_LIST_FIXED_TABS.PUT_THROUGH) {
    yield put(changeTypeDerivative(""));
    yield put(changeTypeCoverWarrant(""));
    yield put(changeIndustryType(""));
    yield put(changeListedType(""));
    yield put(changeOddLotType(""));
  }
  if (selectedTab === WATCH_LIST_FIXED_TABS.ODDLOT) {
    yield put(changeTypeDerivative(""));
    yield put(changeTypeCoverWarrant(""));
    yield put(changeIndustryType(""));
    yield put(changeListedType(""));
    yield put(changeTypePutThrough(""));
  }
}

function* addTickerToListByNameWorker({
  payload: { tickerName, listName },
}: ReturnType<typeof addTickerToListByName>) {
  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );

  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );

  if (!isAuthenticated) {
    const anonymousFavoriteList: ListType[] = yield select(
      (state: RootState) => state.categories.root.anonymousFavoriteList
    );
    const index = anonymousFavoriteList.findIndex(
      (item) => item.name === listName
    );
    if (index === -1) return;
    if (
      anonymousFavoriteList[index].listTickers.some(
        (item) => item === tickerName
      )
    ) {
      toast("category.custom.addToFavoriteListFailure", "error");
      return;
    }
    yield put(addTickerToAnonymousList({ tickerName, listName }));
    const response: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      tickerName,
      true
    );
    yield put(setCategoriesInfo(response));

    toast("category.custom.addToFavoriteListSuccess", "success");
    return;
  }

  const cloneUserFavoriteList: ListType[] = cloneDeep(userFavoriteList);

  const selectedListIndex = cloneUserFavoriteList.findIndex(
    (item) => item.name === listName
  );

  if (selectedListIndex === -1) return;

  try {
    if (
      cloneUserFavoriteList[selectedListIndex].listTickers.some(
        (item) => item === tickerName
      )
    ) {
      toast("category.custom.addToFavoriteListFailure", "error");
      return;
    }

    const request: addTickerForWatchlistParams = {
      id: cloneUserFavoriteList[selectedListIndex].code!,
      secCd: tickerName,
      priority: "0",
    };
    const responseAddTicker: responseAddTickerForWatchlist = yield call(
      addTickerForWatchlist,
      request
    );
    cloneUserFavoriteList[selectedListIndex].listTickers.unshift(tickerName);
    cloneUserFavoriteList[selectedListIndex].listId?.unshift(
      responseAddTicker.data.id
    );
    yield put(addTickerToUserFavListSuccess(cloneUserFavoriteList));
    const response: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      tickerName,
      true
    );
    yield put(setCategoriesInfo(response));

    toast("category.custom.addToFavoriteListSuccess", "success");
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(addTickerToUserFavListFailure(error));
  }
}

function* addTickersToListByNameWorker({
  payload: { tickers, listName },
}: ReturnType<typeof addTickersToListByName>) {
  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );
  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );
  if (!isAuthenticated) {
    yield put(addTickersToAnonymousList({ tickers, listName }));

    const response: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      tickers.toString()
    );
    yield put(setCategoriesInfo(response));
    toast("category.custom.addToFavoriteListSuccess", "success");
    return;
  }
  const cloneUserFavoriteList: ListType[] = cloneDeep(userFavoriteList);
  const selectedListIndex = cloneUserFavoriteList.findIndex(
    (item) => item.name === listName
  );
  if (selectedListIndex === -1) return;
  try {
    const newTickers = tickers.filter(
      (ticker) =>
        !cloneUserFavoriteList[selectedListIndex].listTickers.includes(ticker)
    );
    if (newTickers.length === 0) {
      return;
    }
    cloneUserFavoriteList[selectedListIndex].listTickers.unshift(...newTickers);

    const responseAddTicker: IAddTickersToWatchlistResponse = yield call(
      addTickersToWatchlist,
      cloneUserFavoriteList[selectedListIndex].code || "",
      { items: tickers.join(",") }
    );
    cloneUserFavoriteList[selectedListIndex].listTickers.unshift(
      ...responseAddTicker.data.map((item) => item.secCd)
    );
    cloneUserFavoriteList[selectedListIndex].listId?.unshift(
      ...responseAddTicker.data.map((item) => item.id)
    );

    yield put(addTickerToUserFavListSuccess(cloneUserFavoriteList));
    const response: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      newTickers.toString()
    );
    yield put(setCategoriesInfo(response));
  } catch (error: any) {
    yield put(addTickerToUserFavListFailure(error));
  }
}

function* sortTickerListWorker({
  payload: { tickers, listName, listId },
}: ReturnType<typeof sortTickerList>) {
  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );

  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );

  if (!isAuthenticated) {
    yield put(sortAnonymousList({ tickers, listName }));
    return;
  }

  const cloneUserFavoriteList: ListType[] = cloneDeep(userFavoriteList);

  const selectedListIndex = cloneUserFavoriteList.findIndex(
    (item) => item.name === listName
  );

  if (selectedListIndex === -1) return;

  try {
    if (!listId) return;
    cloneUserFavoriteList[selectedListIndex].listTickers = tickers;
    cloneUserFavoriteList[selectedListIndex].listId = listId;
    //đảo ngược danh sách mã trước khi call
    yield call(
      putTickerForWatchlist,
      cloneUserFavoriteList[selectedListIndex].code!,
      [...tickers].reverse().toString()
    );

    yield put(sortTickerUserFavListSuccess(cloneUserFavoriteList));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(sortTickerUserFavListFailure());
  }
}

function* removeTickerFromFavoriteListSaga({
  payload: { tickerName, listCode },
}: PayloadAction<{ tickerName: string; listCode?: string }>) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.updateWatchList,
    data: { removeTicker: tickerName },
  };
  Storage.commonPushLogFirebase(resLogData);

  let currentListCode: string = yield select(
    (state: RootState) => state.categories.root.currentListCode
  );
  if (listCode) {
    currentListCode = listCode;
  }
  const userFavoriteList: ListType[] = yield select(
    (state: RootState) => state.categories.root.userFavoriteList
  );

  const isAuthenticated: boolean = yield select(
    (state: RootState) => state.auth.root.data.isAuthenticated
  );
  if (!isAuthenticated) {
    yield put(removeTickerFromAnonymousList(tickerName));
    return;
  }

  const cloneUserFavoriteList: ListType[] = cloneDeep(userFavoriteList);

  const selectedListIndex = cloneUserFavoriteList.findIndex(
    (item) => item.name === currentListCode
  );

  if (selectedListIndex === -1) return;

  try {
    const indexToDelete = cloneUserFavoriteList[
      selectedListIndex
    ].listTickers.findIndex((item) => item === tickerName);

    yield call(
      deleteTickerForWatchlist,
      cloneUserFavoriteList[selectedListIndex].code!,
      cloneUserFavoriteList[selectedListIndex].listId?.[
        indexToDelete
      ].toString()!
    );

    cloneUserFavoriteList[selectedListIndex].listTickers.splice(
      indexToDelete,
      1
    );
    cloneUserFavoriteList[selectedListIndex].listId?.splice(indexToDelete, 1);

    yield put(removeTickerFromUserFavListSuccess(cloneUserFavoriteList));
    yield put(removeTickerInfo(tickerName));
    toast("category.custom.removeFromFavoriteListSuccess", "success");
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(removeTickerFromUserFavListFailure(error));
  }
}

function* removeTickerWatcher() {
  yield takeLatest(removeTicker, removeTickerFromFavoriteListSaga);
}

function* restoreTickersListSaga(action: PayloadAction<TickersListSelected>) {
  switch (action.payload.type) {
    case WatchlistGroupType.CW:
      yield put(changeTypeCoverWarrant(action.payload.name));
      break;
    case WatchlistGroupType.DERIVATIVE:
      yield put(changeTypeDerivative(action.payload.name));
      break;
    case WatchlistGroupType.LISTED:
      yield put(changeListedType(action.payload.name));
      break;
    case WatchlistGroupType.INDUSTRY:
      yield put(changeIndustryType(action.payload.name));
      break;
    case WatchlistGroupType.ODDLOT:
      yield put(changeOddLotType(action.payload.name));
      break;
    default:
      break;
  }
}

function* synchronizedTableWithCurrentListCodeSaga(
  action: ReturnType<typeof synchronizedTableWithCurrentListCode>
) {
  const { hideAbleColumns } = yield select(
    (state: RootState) => state.categories.root
  );

  //Persist selected list ticker form cache
  const tickersListSelected: TickersListSelected = yield select(
    tickersListSelectedSelector
  );

  const tickerList: string[] = yield select(
    (state: RootState) => state.categories.categoriesInfo.tickerListDefault
  );
  //visible chart column
  const chartVisible =
    tickersListSelected.type === WatchlistGroupType.OWNER ||
    tickersListSelected.type === WatchlistGroupType.CUSTOM;

  try {
    let responseListedPrice: SymbolTotalInfo.AsObject[];
    if (tickersListSelected.type === WatchlistGroupType.ODDLOT) {
      const params = {
        tickers: tickerList.toString(),
        getChart: chartVisible,
      };
      responseListedPrice = yield call(getWatchListOdd, params);
    } else {
      responseListedPrice = yield call(
        getStreamPriceInfoFulls,
        tickerList.toString(),
        chartVisible
      );
    }

    yield put(setCategoriesInfo(responseListedPrice));
    yield put(setChartVisible(chartVisible));

    if (tickersListSelected.type === WatchlistGroupType.CW) {
      const dataInfoBaseseccd = responseListedPrice.reduce(
        (obj, cur) => ({
          ...obj,
          [cur.secdetailinfo?.baseseccd?.data!]: {
            baseprice: cur.secdetailinfo?.baseprice,
            basesecpricecolor: cur.secdetailinfo?.basesecpricecolor,
          },
        }),
        {}
      );
      const tickerListBaseseccd = Object.keys(dataInfoBaseseccd)
        .toString()
        .replace(/,/g, ";");
      subscribeTickers(tickerListBaseseccd);
      yield put(setInfoBaseseccd(dataInfoBaseseccd));
    }
  } catch (err: any) {
    console.warn(
      `synchronizedTableWithCurrentListCodeSaga: error when fetch data for setCategoriesInfo: ${err.message}`
    );
  }

  yield put(
    changeColumn({
      colummns: {
        ...hideAbleColumns,
        CHART: {
          ...hideAbleColumns.CHART,
          visible: chartVisible,
        },
      },
    })
  );

  yield put(changeLoading(false));
}

function* syncLocalFavoriteListToServerRequestWorker() {
  // TODO
  // const anonymousFavoriteList: ListType[] = yield select(
  //   (state: RootState) => state.categories.root.anonymousFavoriteList
  // );
  // if (!anonymousFavoriteList.length) return;
  // const request: WatchListSingle[] = anonymousFavoriteList.map(
  //   (favoriteItem, index) => {
  //     const wls = toWatchListSingle(favoriteItem);
  //     wls.setIndex(index);
  //     return wls;
  //   }
  // );
  // try {
  //   const response: WatchLists = yield call(saveWatchlist, request);
  //   const items: ListType[] = response
  //     .getItemsList()
  //     .map((itemList) => toListType(itemList));
  //   yield put(syncLocalFavoriteListToServerSuccess(items));
  // } catch (e) { }
}

function* fetchFullPriceWhenLoginSuccessWorker() {
  // TODO
  // const currentListCode: string = yield select(
  //   (state: RootState) => state.categories.root.currentListCode
  // );
  // const isAuthenticated: boolean = yield select(isAuthenticatedSelector);
  // if (!currentListCode || !isAuthenticated) {
  //   return;
  // }
  // let userFavoriteList: ListType[] = [];
  // const watchlists: WatchLists = yield call(getWatchListsApi);
  // watchlists?.getItemsList().forEach((item: WatchListSingle) => {
  //   const type = item.getType();
  //   const code = item.getCode();
  //   switch (type) {
  //     case EWatchListType.WLT_CUSTOMER:
  //       // custom list UI use code as key, if key isn't present, we have to hide it
  //       if (!code) {
  //         console.warn(
  //           `A item is hidden because it doesn't have code ${item.getNamevi()}`
  //         );
  //         return;
  //       }
  //       userFavoriteList.push(toListType(item));
  //       break;
  //     default:
  //       break;
  //   }
  // });
  // const listTickers = userFavoriteList.find(
  //   ({ name }) => name === currentListCode
  // )?.listTickers;
  // if (listTickers) {
  //   console.log("getStreamPriceInfoFulls9");
  //   const response: SymbolTotalInfo.AsObject[] = yield call(
  //     getStreamPriceInfoFulls,
  //     listTickers?.toString()
  //   );
  //   yield put(setCategoriesInfo(response));
  // }
}

function* watchRestoreTickersListSaga() {
  yield takeEvery(restoreTickersList, restoreTickersListSaga);
}

function* watchGetListSaga() {
  yield takeEvery(getWatchListsStart, getWatchListSagaNew);
}

function* watchGetListOwnerSaga() {
  yield takeLatest(getWatchListsOwnerStart, getWatchListOwnerSaga);
}

function* watchCreateWatchlist() {
  yield takeLatest(createUserFavoriteListRequest, createWatchlistSaga);
}

function* watchEditWatchlist() {
  yield takeLatest(editUserFavoriteListRequest, editWatchlistSaga);
}

function* watchRemoveWatchlist() {
  yield takeLatest(removeFavoriteListRequest, removeItemFromCustomerListWorker);
}

function* watchAddTicker() {
  yield takeLatest(addTickerStart, addTickerToFavoriteListSaga);
}

function* addTickerToListByNameWatcher() {
  yield takeLatest(addTickerToListByName, addTickerToListByNameWorker);
}

function* addTickersToListByNameWatcher() {
  yield takeLatest(addTickersToListByName, addTickersToListByNameWorker);
}

function* handleChangeCurrentListWatcher() {
  yield takeLatest(changeCurrentList, handleChangeCurrentListSaga);
}

function* watchSynchronizedTableWithCurrentListCode() {
  yield takeLatest(
    synchronizedTableWithCurrentListCode,
    synchronizedTableWithCurrentListCodeSaga
  );
}

function* syncLocalFavoriteListToServerRequestWatcher() {
  yield takeLatest(
    syncLocalFavoriteListToServerRequest,
    syncLocalFavoriteListToServerRequestWorker
  );
}

function* fetchFullPriceWhenLoggedIn() {
  yield takeLatest(loginSuccess, fetchFullPriceWhenLoginSuccessWorker);
}

function* sortTickerListWatcher() {
  yield takeLatest(sortTickerList.type, sortTickerListWorker);
}

export default function* categoriesSaga() {
  yield all([
    watchGetListSaga(),
    watchGetListOwnerSaga(),
    watchCreateWatchlist(),
    watchRemoveWatchlist(),
    watchAddTicker(),
    watchSynchronizedTableWithCurrentListCode(),
    removeTickerWatcher(),
    putThroughSaga(),
    syncLocalFavoriteListToServerRequestWatcher(),
    addTickerToListByNameWatcher(),
    addTickersToListByNameWatcher(),
    handleChangeCurrentListWatcher(),
    watchEditWatchlist(),
    fetchFullPriceWhenLoggedIn(),
    watchRestoreTickersListSaga(),
    sortTickerListWatcher(),
    logTrackingSaga(),
  ]);
}
