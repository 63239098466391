import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Cash, { AvailableTrade } from "domain/models/Cash";
import {
  GetAvailableTradeParams,
  GetOrderLimitedQtyParams,
} from "core/http/apis/account/types";
import {
  IFindPortfolioParams,
  IGetConditionParams,
  IGetCustomerRestrictionParams,
  IGetPriceWithStepPriceParams,
  IPlaceOrdersConditionParams,
  IPrepareSubmitOrderParam,
  IValidateCustomerInsiderParams,
  PlaceOrdersParam,
  PlaceOrdersPendingParam,
} from "core/http/apis/orderexec/types";
import {
  CustomerInsiderData,
  IFindPortfolioResponse,
  IGetConditionResponse,
  IGetPriceWithStepPriceResponse,
  IPlaceOrdersConditionResponse,
  IPlaceOrdersPendingResponse,
  IPlaceOrdersResponse,
  IPrepareSubmitOrder,
  IPrepareSubmitOrderReponse,
  IValidateCustomerInsiderResponse,
} from "domain/models/OrderBook";
import { logOutSuccess } from "modules/auth/redux";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { IGetCustomerRestrictionResponse } from "domain/models/OrderBook";
import { EOTPTypeSubmit } from "helper/consts";

interface Options {
  value: string;
  label: string;
}

interface OptionsSuggestions {
  value: number;
  label: string;
}

export interface ErrorSingleOrder {
  code: number | string;
  description: string;
}

export interface State {
  dataCash?: Cash;
  priceStep: any;
  volumn: any;
  volumnStep: any;
  multiOrder: any;
  tickerInfoLastSale: any;
  tickerdataSummary: any;
  filters: {
    price: any;
  };
  buySellType: string;
  conditionalOrder: Options;
  optType: Options;
  optVal: any;
  matMethod: string;
  condition: string;
  expiredDate: Date;
  limitOffset: any;
  optValType: number;
  avgPrice: any;
  minBuyPrice: any;
  maxSellPrice: any;
  loading: boolean;
  loadingPlaceOrder: boolean;
  singleOrderStatus: { status: string; error?: string };
  errorSingleOrder: ErrorSingleOrder;
  isSetPrice: boolean;
  listOrderQtySuccess: OptionsSuggestions[];
  createRequestConditionParams: IPlaceOrdersConditionParams;
  errorValidateOtpVal: string;
  errorValidateLimitOffset: string;
  errorValidateMinBuyPrice: string;
  errorValidateMaxSellPrice: string;
  orderLimitedQty: number;
  loadingPrepare: boolean;
  statusPrepare: { status: string; error?: string };
  errorPrepare: ErrorSingleOrder;
  prepareSubmitOrderReponse: IPrepareSubmitOrder;
  prepareSubmitOrder: IPrepareSubmitOrderParam;
  isCustomerRestriction: boolean;
  statusCustomerRestriction: string;
  customerInsiderData: CustomerInsiderData;
  statusCustomerInsider: string;
  priceByStepPrice: number;
  loadingCondition: boolean;
  statusCondition: { status: string; error?: string };
  conditionSubmitOrderReponse: IGetConditionResponse;
  conditionSubmitOrder: IGetConditionParams;
}
const initialState: State = {
  dataCash: undefined,
  tickerInfoLastSale: undefined,
  tickerdataSummary: undefined,
  priceStep: "",
  conditionalOrder: { value: "01", label: "" },
  optType: { value: "01", label: ">=" },
  optVal: 0,
  matMethod: "1",
  condition: "01",
  expiredDate: new Date(),
  limitOffset: 0,
  optValType: 1,
  avgPrice: 0,
  minBuyPrice: "",
  maxSellPrice: "",
  volumn: "",
  volumnStep: "",
  multiOrder: "",
  filters: { price: "" },
  buySellType: "buy",
  loading: true,
  loadingPlaceOrder: false,
  singleOrderStatus: { status: "", error: "" },
  errorSingleOrder: {
    code: 0,
    description: "",
  },
  isSetPrice: false,
  createRequestConditionParams: {
    tradeType: 0,
    channel: "",
    traderId: "",
    custNo: "",
    subAccoNo: "",
    secCd: "",
    secType: 0,
    ordQty: 0,
    ordPrice: 0,
    minPrice: 0,
    maxPrice: 0,
    condType: "",
    fromDate: 0,
    toDate: 0,
    matMethod: 0,
    optType: "",
    optVal: "",
    optValType: "",
    limitOffset: "",
    clientInfo: "",
    otpCode: "",
    otpType: EOTPTypeSubmit.OTP,
  },
  listOrderQtySuccess: [
    {
      label: "1000",
      value: 1,
    },
    {
      label: "2000",
      value: 1,
    },
    {
      label: "5000",
      value: 1,
    },
    {
      label: "10000",
      value: 1,
    },
  ],
  errorValidateOtpVal: "",
  errorValidateLimitOffset: "",
  errorValidateMinBuyPrice: "",
  errorValidateMaxSellPrice: "",
  orderLimitedQty: 0,
  loadingPrepare: false,
  statusPrepare: { status: "", error: "" },
  errorPrepare: {
    code: 0,
    description: "",
  },
  prepareSubmitOrderReponse: {},
  prepareSubmitOrder: {
    subAccoNo: "",
    tradeType: 0,
    secCd: "",
    marketCd: 0,
    order_type: "",
    order_price: 0,
    order_qty: 0,
    marginRate: 0,
    split_type: 0,
    split_num: 0,
    mortContractNo: "",
    interval: "",
    srcChannel: 0,
    resolvedFlag: 0,
    sideType: 0,
  },
  isCustomerRestriction: false,
  statusCustomerRestriction: "",
  customerInsiderData: {},
  statusCustomerInsider: "",
  priceByStepPrice: 0,
  loadingCondition: false,
  statusCondition: { status: "", error: "" },
  conditionSubmitOrderReponse: {},
  conditionSubmitOrder: {
    tradeType: 0,
    price: 0,
    changeValue: 0,
    operation: "",
    type: 0,
  },
};
const placeOrderSlice = createSlice({
  name: "placeOrderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    getAvailableTrade: (
      state,
      action: PayloadAction<GetAvailableTradeParams>
    ) => {},
    getAvailableTradeSuccess: (
      state,
      action: PayloadAction<AvailableTrade>
    ) => {
      state.loading = false;
      const data = action.payload;

      state.dataCash = {
        buyCredit: data?.buyingPower,
        marginRatio: data?.marginRate,
        remainRoomVolume: data?.marginRoom,
      };
    },
    getAvailableTradeFailed: (state) => {
      state.loading = false;
      state.dataCash = undefined;
    },

    getOrderLimitedQty: (
      state,
      action: PayloadAction<GetOrderLimitedQtyParams>
    ) => {},
    getOrderLimitedQtySuccess: (state, action: PayloadAction<number>) => {
      state.orderLimitedQty = action.payload;
    },
    getOrderLimitedQtyFailed: (state) => {
      state.orderLimitedQty = 0;
    },

    onFilter: (state, action) => {
      state.filters = action.payload;
    },
    onBuySellType: (state, action: PayloadAction<string>) => {
      state.buySellType = action.payload;
    },
    onConditionalOrder: (state, action) => {
      state.conditionalOrder = action.payload;
    },
    onChangeOptType: (state, action: PayloadAction<Options>) => {
      state.optType = action.payload;
    },
    onChangeOptVal: (state, action: PayloadAction<any>) => {
      state.optVal = action.payload;
    },
    onChangeMatMethod: (state, action: PayloadAction<string>) => {
      state.matMethod = action.payload;
    },
    onChangeCondition: (state, action: PayloadAction<string>) => {
      state.condition = action.payload;
      state.optVal = 0;
    },
    onChangeExpiredDate: (state, action: PayloadAction<Date>) => {
      state.expiredDate = action.payload;
    },
    onChangeLimitOffset: (state, action: PayloadAction<any>) => {
      state.limitOffset = action.payload;
    },
    onChangeOptValType: (state, action: PayloadAction<number>) => {
      state.optValType = action.payload;
    },
    onChangeAvgPrice: (state, action: PayloadAction<any>) => {
      state.avgPrice = action.payload;
    },
    onChangeMinMaxPrice: (
      state,
      action: PayloadAction<{ price: any; type: string }>
    ) => {
      if (action.payload.type === "buy") {
        state.minBuyPrice = action.payload.price;
      } else if (action.payload.type === "sell") {
        state.maxSellPrice = action.payload.price;
      }
    },
    onClearDataCondition: (state) => {
      state.optVal = 0;
      state.matMethod = "1";
      state.condition = "01";
      state.expiredDate = new Date();
      state.limitOffset = 0;
      state.optValType = 1;
      state.avgPrice = 0;
      state.minBuyPrice = "";
      state.maxSellPrice = "";
      state.errorValidateOtpVal = "";
      state.errorValidateLimitOffset = "";
      state.errorValidateMinBuyPrice = "";
      state.errorValidateMaxSellPrice = "";
    },
    onUpdateVolumn: (state, action) => {
      state.volumn = action.payload.volumn;
    },
    updateTickerInfoLastSale: (state, action) => {
      state.tickerInfoLastSale = action.payload;
    },
    updateTickerdataSummary: (state, action) => {
      state.tickerdataSummary = action.payload;
    },
    clearDataPlaceOrder: (state) => {
      state.tickerInfoLastSale = [];
      state.tickerdataSummary = [];
    },

    prepareSubmitOrder: (
      state,
      action: PayloadAction<IPrepareSubmitOrderParam>
    ) => {
      state.loadingPrepare = true;
      state.prepareSubmitOrder = action.payload;
    },
    prepareSubmitOrderSuccess: (
      state,
      action: PayloadAction<IPrepareSubmitOrderReponse>
    ) => {
      state.loadingPrepare = false;
      if (action.payload.statusCode === 0) {
        state.statusPrepare = { status: "success" };
        state.prepareSubmitOrderReponse = action.payload.data;
      } else {
        state.statusPrepare = { status: "failed" };
        state.errorPrepare = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
    },
    prepareSubmitOrderFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {
      state.loadingPrepare = false;
      state.statusPrepare = { status: "failed" };
    },

    getCustomerRestriction: (
      state: State,
      action: PayloadAction<IGetCustomerRestrictionParams>
    ) => {
      state.loading = false;
    },
    getCustomerRestrictionSuccess: (
      state: State,
      action: PayloadAction<IGetCustomerRestrictionResponse>
    ) => {
      state.loading = false;
      if (action.payload.statusCode === 0) {
        state.isCustomerRestriction = action.payload.data === 0;
        state.statusCustomerRestriction = "SUCCESSFUL";
      } else {
        state.statusCustomerRestriction = "FAILURE";
        state.errorPrepare = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
    },
    getCustomerRestrictionFailed: (state: State) => {
      state.loading = false;
      state.statusCustomerRestriction = "FAILURE";
    },

    validateCustomerInsider: (
      state: State,
      action: PayloadAction<IValidateCustomerInsiderParams>
    ) => {
      state.loading = false;
    },
    validateCustomerInsiderSuccess: (
      state: State,
      action: PayloadAction<IValidateCustomerInsiderResponse>
    ) => {
      state.loading = false;
      if (action.payload.statusCode === 0) {
        state.customerInsiderData = action.payload.data;
        state.statusCustomerInsider = "SUCCESSFUL";
      } else {
        state.statusCustomerInsider = "FAILURE";
        state.errorPrepare = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
    },
    validateCustomerInsiderFailed: (state: State) => {
      state.loading = false;
      state.statusCustomerInsider = "FAILURE";
    },

    requestPostSingleOrderPending: (
      state,
      action: PayloadAction<{
        params: PlaceOrdersPendingParam;
        tickerInfo: MrktSecInfoItem.AsObject;
      }>
    ) => {
      state.loadingPlaceOrder = true;
    },
    postSingleOrderPendingSuccess: (
      state,
      action: PayloadAction<IPlaceOrdersPendingResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.singleOrderStatus = { status: "success" };
      } else {
        state.singleOrderStatus = { status: "failed" };
        state.errorSingleOrder = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
      if (state.prepareSubmitOrder.order_qty > 100) {
        const checkExist = state.listOrderQtySuccess.find(
          (item) => item.label === state.prepareSubmitOrder.order_qty.toString()
        );
        if (checkExist) {
          state.listOrderQtySuccess.map((item) => {
            if (item.label === state.prepareSubmitOrder.order_qty.toString()) {
              item.value = item.value + 1;
            }
          });
        } else {
          if (state.prepareSubmitOrder.order_qty.toString() !== "") {
            state.listOrderQtySuccess.unshift({
              label: state.prepareSubmitOrder.order_qty.toString(),
              value: 1,
            });
          }
        }
      }
      state.loadingPlaceOrder = false;
    },
    postSingleOrderPendingFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {
      state.loadingPlaceOrder = false;
      state.singleOrderStatus = { status: "failed" };
      state.errorSingleOrder = {
        code: action.payload.code,
        description: action.payload.description,
      };
    },
    requestPostSingleOrder: (
      state,
      action: PayloadAction<{
        params: PlaceOrdersParam;
        tickerInfo: MrktSecInfoItem.AsObject;
      }>
    ) => {
      state.loadingPlaceOrder = true;
    },
    postSingleOrderSuccess: (
      state,
      action: PayloadAction<IPlaceOrdersResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.singleOrderStatus = { status: "success" };
      } else {
        state.singleOrderStatus = { status: "failed" };
        state.errorSingleOrder = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
      if (state.prepareSubmitOrder.order_qty > 100) {
        const checkExist = state.listOrderQtySuccess.find(
          (item) => item.label === state.prepareSubmitOrder.order_qty.toString()
        );
        if (checkExist) {
          state.listOrderQtySuccess.map((item) => {
            if (item.label === state.prepareSubmitOrder.order_qty.toString()) {
              item.value = item.value + 1;
            }
          });
        } else {
          if (state.prepareSubmitOrder.order_qty.toString() !== "") {
            state.listOrderQtySuccess.unshift({
              label: state.prepareSubmitOrder.order_qty.toString(),
              value: 1,
            });
          }
        }
      }
      state.loadingPlaceOrder = false;
    },
    postSingleOrderFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {
      state.loadingPlaceOrder = false;
      state.singleOrderStatus = { status: "failed" };
      state.errorSingleOrder = {
        code: action.payload.code,
        description: action.payload.description,
      };
    },
    findPortfolio: (state, action: PayloadAction<IFindPortfolioParams>) => {},
    findPortfolioSuccess: (
      state,
      action: PayloadAction<IFindPortfolioResponse>
    ) => {
      if (action.payload.data && action.payload.data.length > 0) {
        state.avgPrice = action.payload.data[0].avgPrice || 0;
      } else {
        state.avgPrice = 0;
      }
    },
    findPortfolioFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {},
    getPriceWithStepPrice: (
      state,
      action: PayloadAction<IGetPriceWithStepPriceParams>
    ) => {},
    getPriceWithStepPriceSuccess: (
      state,
      action: PayloadAction<IGetPriceWithStepPriceResponse>
    ) => {
      state.priceByStepPrice = action.payload.data || 0;
    },
    getPriceWithStepPriceFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {},
    getCondition: (state, action: PayloadAction<IGetConditionParams>) => {
      state.loadingCondition = true;
      state.conditionSubmitOrder = action.payload;
    },
    getConditionSuccess: (
      state,
      action: PayloadAction<IGetConditionResponse>
    ) => {
      state.loadingCondition = false;
      state.statusCondition = { status: "success" };
      state.conditionSubmitOrderReponse = action.payload;
    },
    getConditionFailure: (state, action: PayloadAction<ErrorSingleOrder>) => {
      state.loadingCondition = false;
      state.statusCondition = { status: "failed" };
    },
    requestPostOrderCondition: (
      state,
      action: PayloadAction<{
        params: IPlaceOrdersConditionParams;
        tickerInfo: MrktSecInfoItem.AsObject;
      }>
    ) => {
      state.createRequestConditionParams = action.payload.params;
      state.loadingPlaceOrder = true;
    },
    postSingleOrderConditionSuccess: (
      state,
      action: PayloadAction<IPlaceOrdersConditionResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.singleOrderStatus = { status: "success" };
      } else {
        state.singleOrderStatus = { status: "failed" };
        state.errorSingleOrder = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
      if (state.createRequestConditionParams.ordQty > 100) {
        const checkExist = state.listOrderQtySuccess.find(
          (item) =>
            item.label === state.createRequestConditionParams.ordQty.toString()
        );
        if (checkExist) {
          state.listOrderQtySuccess.map((item) => {
            if (
              item.label ===
              state.createRequestConditionParams.ordQty.toString()
            ) {
              item.value = item.value + 1;
            }
          });
        } else {
          if (state.createRequestConditionParams.ordQty.toString() !== "") {
            state.listOrderQtySuccess.unshift({
              label: state.createRequestConditionParams.ordQty.toString(),
              value: 1,
            });
          }
        }
      }
      state.loadingPlaceOrder = false;
    },
    postSingleOrderConditionFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {
      state.loadingPlaceOrder = false;
      state.singleOrderStatus = { status: "failed" };
      state.errorSingleOrder = {
        code: action.payload.code,
        description: action.payload.description,
      };
    },
    postSingleOrderClear: (state) => {
      state.singleOrderStatus = { status: "" };
      state.statusPrepare = { status: "" };
      state.statusCustomerRestriction = "";
      state.statusCustomerInsider = "";
    },
    handleUsingPriceSelected: (state, action: PayloadAction<boolean>) => {
      state.isSetPrice = action.payload;
    },
    clearData: (state) => {
      state.volumn = 0;
    },
    setErrorValidateOtpVal: (state, action: PayloadAction<string>) => {
      state.errorValidateOtpVal = action.payload;
    },
    setErrorValidateLimitOffset: (state, action: PayloadAction<string>) => {
      state.errorValidateLimitOffset = action.payload;
    },
    setErrorValidateMinBuyPrice: (state, action: PayloadAction<string>) => {
      state.errorValidateMinBuyPrice = action.payload;
    },
    setErrorValidateMaxSellPrice: (state, action: PayloadAction<string>) => {
      state.errorValidateMaxSellPrice = action.payload;
    },
  },
});

export const {
  getAvailableTrade,
  getAvailableTradeSuccess,
  getAvailableTradeFailed,
  getOrderLimitedQty,
  getOrderLimitedQtySuccess,
  getOrderLimitedQtyFailed,
  onFilter,
  onBuySellType,
  onConditionalOrder,
  onChangeOptType,
  onChangeOptVal,
  onChangeMatMethod,
  onChangeCondition,
  onChangeExpiredDate,
  onChangeLimitOffset,
  onChangeOptValType,
  onChangeAvgPrice,
  onChangeMinMaxPrice,
  onUpdateVolumn,
  updateTickerInfoLastSale,
  updateTickerdataSummary,
  clearDataPlaceOrder,
  requestPostSingleOrderPending,
  postSingleOrderPendingSuccess,
  postSingleOrderPendingFailure,
  requestPostSingleOrder,
  postSingleOrderSuccess,
  postSingleOrderFailure,
  prepareSubmitOrder,
  prepareSubmitOrderSuccess,
  prepareSubmitOrderFailure,
  getCustomerRestriction,
  getCustomerRestrictionSuccess,
  getCustomerRestrictionFailed,
  validateCustomerInsider,
  validateCustomerInsiderSuccess,
  validateCustomerInsiderFailed,
  findPortfolio,
  findPortfolioSuccess,
  findPortfolioFailure,
  getPriceWithStepPrice,
  getPriceWithStepPriceSuccess,
  getPriceWithStepPriceFailure,
  getCondition,
  getConditionSuccess,
  getConditionFailure,
  requestPostOrderCondition,
  postSingleOrderConditionSuccess,
  postSingleOrderConditionFailure,
  postSingleOrderClear,
  handleUsingPriceSelected,
  clearData,
  onClearDataCondition,
  setErrorValidateOtpVal,
  setErrorValidateLimitOffset,
  setErrorValidateMinBuyPrice,
  setErrorValidateMaxSellPrice,
} = placeOrderSlice.actions;
export default placeOrderSlice.reducer;
