import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";
import { getListPositionClose } from "modules/position-management/selectors";
import numeral from "numeral";
import { memo, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  ColorGreen,
  ColorRed,
  ColorWhile,
  Container,
  HeaderCell,
  TableCellContent,
  TableHeaderCellContent,
} from "./styles";

function PositionCloseTable() {
  const data = useSelector(getListPositionClose);

  const countTotal = (arr: any[]) => {
    return arr.reduce((total: number, item: any) => {
      return total + item.profitAmt;
    }, 0);
  };
  const total = useMemo(() => {
    return countTotal(data);
  }, [data]);

  const totalProfitLoss = useRef(0);
  totalProfitLoss.current = total;

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="positionManagement.positionClose.tradeDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 100,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"} color={"while"}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
        Footer: (props: any) => {
          return (
            <TableHeaderCellContent align={"center"} isFooter>
              <FormattedMessage id="positionManagement.positionOpen.total" />
            </TableHeaderCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="positionManagement.positionClose.subject" />
          </HeaderCell>
        ),
        accessor: "custNo",
        minWidth: 100,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"center"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="positionManagement.positionClose.stockCode" />
          </HeaderCell>
        ),
        accessor: "secCd",
        minWidth: 100,
        Cell: (props: any) => {
          const profitAmt = props?.row?.original?.profitAmt;
          const secCd = props?.row?.original?.secCd;
          return (
            <TableCellContent align={"center"}>
              {profitAmt > 0 ? (
                <ColorGreen>{secCd}</ColorGreen>
              ) : profitAmt < 0 ? (
                <ColorRed>{secCd}</ColorRed>
              ) : (
                <ColorWhile>{secCd}</ColorWhile>
              )}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"center"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionClose.quantity" />
          </HeaderCell>
        ),
        accessor: "quantity",
        minWidth: 100,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>{props.value}</TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"right"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionClose.longAvgPrice" />
          </HeaderCell>
        ),
        accessor: "longAvgPrice",
        minWidth: 100,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"right"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionClose.price" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "price",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"right"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionClose.profitAmt" />
          </HeaderCell>
        ),
        accessor: "profitAmt",
        minWidth: 100,
        Cell: (props: any) => {
          const profitAmt = props?.row?.original?.profitAmt;
          return (
            <TableCellContent align={"right"}>
              {profitAmt > 0 ? (
                <ColorGreen>{numeral(profitAmt).format("0,0")}</ColorGreen>
              ) : profitAmt < 0 ? (
                <ColorRed>{numeral(profitAmt).format("0,0")}</ColorRed>
              ) : (
                <ColorWhile>{numeral(profitAmt).format("0,0")}</ColorWhile>
              )}
            </TableCellContent>
          );
        },
        Footer: () => {
          return (
            <TableHeaderCellContent align={"right"} isFooter>
              {totalProfitLoss?.current > 0 ? (
                <ColorGreen>
                  {numeral(totalProfitLoss?.current).format("0,0")}
                </ColorGreen>
              ) : totalProfitLoss?.current < 0 ? (
                <ColorRed>
                  {numeral(totalProfitLoss?.current).format("0,0")}
                </ColorRed>
              ) : (
                <ColorWhile>
                  {numeral(totalProfitLoss?.current).format("0,0")}
                </ColorWhile>
              )}
            </TableHeaderCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="positionCloseTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? data : []}
        enableInfinity={true}
        scrollableTarget={"positionCloseTable"}
      />
    </Container>
  );
}

export default memo(PositionCloseTable);
