import {
  Select,
  Button,
  DatePicker,
  // Input,
  InputSymbol,
} from "components/commons";
import { memo, useEffect, useMemo } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  findOrderByFilterRequest,
  findRequestCondRequest,
} from "modules/order-history/redux";
import {
  loadingSearchDataSelector,
  filterOrderHistorySelector,
  selectLimit,
} from "modules/order-history/selectors";
import {
  IFindOrderByFilterParams,
  IFindRequestCondParams,
} from "core/http/apis/order-history/types";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";

import moment from "moment";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";

const orderOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.filter.type.normalOrder",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.filter.type.conditionalOrder",
    }),
  },
];
const condOption = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({
      id: "orderHistory.filter.condType.all",
    }),
  },
  {
    value: "TCO",
    label: intl.formatMessage({
      id: "orderHistory.filter.condType.tco",
    }),
  },
  {
    value: "TS",
    label: intl.formatMessage({
      id: "orderHistory.filter.condType.ts",
    }),
  },
  {
    value: "SP",
    label: intl.formatMessage({
      id: "orderHistory.filter.condType.sp",
    }),
  },
  {
    value: "CL",
    label: intl.formatMessage({
      id: "orderHistory.filter.condType.cl",
    }),
  },
  {
    value: "PRO",
    label: intl.formatMessage({
      id: "orderHistory.filter.condType.pro",
    }),
  },
];
const tradeOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "orderHistory.filter.tradeType.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.filter.tradeType.sell",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.filter.tradeType.buy",
    }),
  },
];
const statusOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.rejected",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.entered",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.waitApprove",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.partialMatch",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.fullMatch",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.partialCancel",
    }),
  },
  {
    value: "7",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.cancel",
    }),
  },
  {
    value: "8",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusType.rejectedGkck",
    }),
  },
];
const statusCondOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusCondType.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusCondType.takeEffect",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusCondType.expire",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusCondType.complete",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "orderHistory.filter.statusCondType.cancel",
    }),
  },
];
function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const {
    fromDate,
    toDate,
    subAccoNo,
    secCd,
    orderType,
    status,
    tradeType,
    condType,
    statusCond,
  } = useSelector(filterOrderHistorySelector);
  const limit = useSelector(selectLimit);
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const loading = useSelector(loadingSearchDataSelector);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    // list.unshift({
    //   label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
    //   value: "",
    // });
    return list;
  }, [listAcc, intl]);
  const orderOptions = useMemo(() => orderOption(intl), [intl]);
  const tradeOptions = useMemo(() => tradeOption(intl), [intl]);
  const statusOptions = useMemo(() => statusOption(intl), [intl]);

  const statusCondOptions = useMemo(() => statusCondOption(intl), [intl]);
  const condOptions = useMemo(() => condOption(intl), [intl]);
  useEffect(() => {
    dispatch(
      updateFilter({
        key: "orderType",
        value: orderOptions ? orderOptions[0].value : "",
      })
    );

    dispatch(
      updateFilter({
        key: "subAccoNo",
        value: parseOptionAccount ? parseOptionAccount[0].value : "",
      })
    );

    dispatch(
      updateFilter({
        key: "tradeType",
        value: tradeOptions ? +tradeOptions[0].value : 0,
      })
    );
    dispatch(
      updateFilter({
        key: "status",
        value: statusOptions ? +statusOptions[0].value : 0,
      })
    );
    dispatch(
      updateFilter({
        key: "condType",
        value: condOptions ? condOptions[0].value : "",
      })
    );
    dispatch(
      updateFilter({
        key: "statusCond",
        value: statusCondOptions ? +statusCondOptions[0].value : 0,
      })
    );
  }, []);
  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      return toast("common.warningInvalidDay", "error");
    }
    // console.log(fromDate, toDate, subAccoNo, secCd,orderType)
    if (orderType === "1") {
      const submitData: IFindOrderByFilterParams = {
        branchCd: "",
        transactionCd: "",
        orderNo: "",
        tradeType: tradeType ? tradeType : 0,
        marketCd: "0",
        orderType: "",
        secCd: secCd ? secCd : "",
        brokerCd: "",
        accountNo: subAccoNo
          ? subAccoNo
          : parseOptionAccount[0].value
          ? parseOptionAccount[0].value
          : "",
        extStatus: status !== null && status !== undefined ? status : 0,
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        groupFilterId: "",
        regUserId: "",
        sourceChannel: 0,
        onlineFlag: "",
        ptFlag: "",
      };
      dispatch(findOrderByFilterRequest(submitData));

      const resLogData = {
        eventGroup: GroupEvent.order,
        event: RealtimeConst.getOrderHistoryNormal,
        message: JSON.stringify(submitData),
      };

      storage.commonPushLogFirebase(resLogData);
    } else if (orderType === "2") {
      const submitData: IFindRequestCondParams = {
        custNo: storage.getMasterAccount() ?? "",
        subAccoNo: subAccoNo
          ? subAccoNo
          : parseOptionAccount[0].value
          ? parseOptionAccount[0].value
          : "",
        secCd: secCd ? secCd : "",
        tradeType: tradeType ? tradeType : 0,

        condType: condType ? condType : "",
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,

        status:
          statusCond !== null && statusCond !== undefined ? statusCond : 0,
        page: 0,
        rowPerPage: limit,
      };
      dispatch(findRequestCondRequest(submitData));

      const resLogData = {
        eventGroup: GroupEvent.order,
        event: RealtimeConst.getOrderHistoryNormal,
        message: JSON.stringify(submitData),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };

  // effect

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center shadow-none">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.orderType",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={orderOptions[0]}
              options={orderOptions}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "orderType",
                    value: e?.value ? e.value : "",
                  })
                );
              }}
            />
          </div>
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.fromDate",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <DatePicker
              selected={fromDate}
              onChange={(e: any) => {
                dispatch(
                  updateFilter({
                    key: "fromDate",
                    value: e,
                  })
                );
              }}
              popperPlacement="bottom-start"
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.toDate",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <DatePicker
              selected={toDate}
              onChange={(e) =>
                dispatch(
                  updateFilter({
                    key: "toDate",
                    value: e,
                  })
                )
              }
              popperPlacement="bottom-start"
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.account",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={parseOptionAccount[0]}
              options={parseOptionAccount}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "subAccoNo",
                    value: e?.value ? e.value : "",
                  })
                );
              }}
            />
          </div>
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.ticker",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <InputSymbol
              value={secCd}
              onChange={(e: any) => {
                dispatch(
                  updateFilter({
                    key: "secCd",
                    value: e ? e : "",
                  })
                );
              }}
              isOnlyShareCode
            />
            {/* <Input
              value={secCd ? secCd : ""}
              placeholder={intl.formatMessage({
                id: "orderHistory.filter.placehoderTicker",
              })}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "secCd",
                    value: e?.target?.value
                      ? e?.target?.value.toLocaleUpperCase()
                      : "",
                  })
                );
              }}
            /> */}
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "orderHistory.filter.trade",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={tradeOptions[0]}
              options={tradeOptions}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "tradeType",
                    value: e?.value ? +e.value : 0,
                  })
                );
              }}
            />
          </div>
        </FilterItem>

        {orderType === "2" && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "orderHistory.conditionOrder",
            })}
            className="mr-4"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={condOptions[0]}
                options={condOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "condType",
                      value: e?.value ? e.value : "",
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
        )}
        {orderType === "1" && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "orderHistory.filter.status",
            })}
            className="mr-4"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={statusOptions[0]}
                options={statusOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "status",
                      value: e?.value ? +e.value : 0,
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
        )}
        {orderType === "2" && (
          <FilterItem
            filterName={intl.formatMessage({
              id: "orderHistory.filter.status",
            })}
            className="mr-4"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={statusCondOptions[0]}
                options={statusCondOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "statusCond",
                      value: e?.value ? +e.value : 0,
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
        )}

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "orderHistory.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
