import VgaiaHttp from "core/http/singleton/vgaia";
import { IMarginSecuritiesResponse } from "domain/models/MarginSecurities";
import { IFindMarginSecuritiesParams } from "./types";
import { findMarginSecuritiesApi } from "./urls";

export const getMarginSecuritiesList = async (
  params: IFindMarginSecuritiesParams
): Promise<IMarginSecuritiesResponse> => {
  const res = await VgaiaHttp.get<IMarginSecuritiesResponse>(
    findMarginSecuritiesApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
