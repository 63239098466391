import { convertHexToRGBA } from "helper/utils";
import styled from "styled-components/macro";

export const Container = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .code {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  }

  .text-big {
    font-weight: ${({ theme }) => theme.typeFaces.header.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.header.size};
    line-height: ${({ theme }) => theme.typeFaces.header.lineHeight};
    color: ${({ theme }) => theme.solidGreen};
  }
  .text-small {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    color: ${({ theme }) => theme.solidGreen};
  }

  .tran {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }

  .gia-tran {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    cursor: pointer;
  }

  .violet {
    color: ${({ theme }) => theme.violet50};
  }

  .blue {
    color: ${({ theme }) => theme.blue50};
  }

  .yellow {
    color: ${({ theme }) => theme.yellow50};
  }

  .ticker-container {
    background: ${({ theme }) => theme.bgElevated2};
    position: "absolute";
    left: 0;
    right: 0;
    border-radius: 4px;
    margin: 4;
    max-height: 450;
    overflow: "auto";
    padding: 8;
    padding-right: 8;
    padding-left: 8;
  }

  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  .w-20 {
    width: 20%;
  }
  .w-80 {
    width: 80%;
  }
  .btn-place-order-der {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    flex: 1;
    min-width: 100px;
    max-height: ${({ theme }) => theme.button.height};
    height: ${({ theme }) => theme.button.height};
    cursor: pointer;
    border-radius: 4px;
  }
  .btn-place-order-der-long {
    color: ${({ theme }) => theme.neutral1};
    background-color: ${({ theme }) => theme.green50};
    :hover {
      color: ${({ theme }) => theme.neutral1};
      background-color: ${({ theme }) => convertHexToRGBA(theme.green50, 0.4)};
    }
  }

  .btn-place-order-der-short {
    color: ${({ theme }) => theme.neutral1};
    background-color: ${({ theme }) => theme.red50};
    :hover {
      color: ${({ theme }) => theme.neutral1};
      background-color: ${({ theme }) => convertHexToRGBA(theme.red50, 0.4)};
    }
  }
`;

export const PlaceOrderDerButton = styled.div<{
  isActive?: boolean;
  type: "bgGreen" | "bgRed";
  rounded?: boolean;
}>`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  flex: 1;
  min-width: 100px;
  max-height: ${({ theme }) => theme.button.height};
  height: ${({ theme }) => theme.button.height};
  cursor: pointer;
  border-radius: ${({ rounded }) => rounded && "4px"};
  color: ${({ isActive, theme }) => isActive && theme.neutral1};
  background-color: ${({ theme, isActive, type }) =>
    isActive ? theme[type] : theme.brandSecondary};
  :hover {
    color: ${({ theme }) => theme.neutral1};
    background-color: ${({ type, theme, isActive }) =>
      !isActive && convertHexToRGBA(theme[type], 0.4)};
  }
`;
export const FormTitle = styled.div`
  flex: 2;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.grey20};
`;

export const FormControl = styled.div`
  flex: 3;
`;

export const BuySellButtonRow = styled.div`
  display: flex;
  overflow: hidden;
  height: 48px;
  padding: 8px 16px;
`;

export const ButtonItem = styled.div`
  flex: 1;
  position: relative;
`;

export const LabelBuySell = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  color: ${({ isActive, theme }) =>
    isActive ? theme.textcolorWhite : theme.textcolorWhite};

  :hover {
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const BuyButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 10px);
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.green50 : theme.grey70};
    transform: skewX(345deg);
    height: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: calc(100% - 10px);
    border-radius: 4px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.green50 : theme.grey70};
  }
`;

export const SellButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 10px);
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.red50 : theme.grey70};
    transform: skewX(345deg);
    height: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 30px;
    width: calc(100% - 10px);
    border-radius: 4px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.red50 : theme.grey70};
  }
`;

export const RowButton = styled.div`
  display: flex;
  padding: 16px 14px;
`;

export const ContentPlace = styled.div`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.borderDivider} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;

  padding: 16px;
`;
