import { VisibleIndex } from "helper/consts";
import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.textcolorBlack};
`;

function getHeightIndex(value: number) {
  switch (value) {
    case VisibleIndex.OPEN:
      return "194px";
    case VisibleIndex.HIDDEN_CHART:
      return "116px";
    default:
      return "36px";
  }
}

export const Body = styled.div<{
  maxSize: number;
}>`
  width: 100vw;
  height: calc(100vh - ${({ maxSize }) => getHeightIndex(maxSize)});
`;

export const Article = styled.div`
  float: right;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 8px;
`;

export const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 32px;
  right: 32px;
  z-index: 99;
`;

export const ContainerExtra = styled.div`
  height: calc(100% - 16px);
  width: 470px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  z-index: 99;
  /* animation: moveToRight 1s ease-in-out;
  animation-delay: 10ms;
  @keyframes moveToRight {
    0% {
      transform: translateX(500px);
    }
    100% {
      transform: translateX(0px);
    }
  } */
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  background: ${({ theme }) => theme.background3};
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.grey0};
`;

export const ButtonExit = styled.div``;

export const Content = styled.div`
  height: calc(100% - 32px);
  background: ${({ theme }) => theme.background1};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const MenuExtra = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ItemExtra = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 20px;
  background: ${({ theme }) => theme.grey70};
  color: ${({ theme }) => theme.grey0};
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 16px;
  }
`;

export const OpenExtra = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
