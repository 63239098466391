import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { FormattedMessage } from "react-intl";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";

const totalqtySelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.totalqty
);

const TotalqtyCell = () => {
  const totalqty = useSelector(totalqtySelector);
  return (
    <div className="title-info">
      <div className="info-text">
        <FormattedMessage id="widgets.tickerInfo.totalSharesTradeMt" />
      </div>
      <div className="info-value">
        <VolumeChange value={numeral(totalqty).multiply(10).format("0,0")} />
      </div>
    </div>
  );
};

export default TotalqtyCell;
