import VgaiaHttp from "core/http/singleton/vgaia";
import AssetsTrading from "domain/models/AssetsTrading";
import {
  IAssetInfoResponse,
  IConditionExtendLoanResponse,
  IExtendExpireResponse,
  ILoanExtensionResponse,
  INavAssetDeltaResponse,
  INavAssetReturnResponse,
  INavRateResponse,
} from "domain/models/Care";
import {
  GetAvailableTradeResponse,
  GetOrderLimitedQtyResponse,
  GetTransferAccountResponse,
  IDeleteTransferContractBankResponse,
  ISetDefaultSubAccoNoResponse,
  IUpdateTransferContractBankResponse,
  ListBankBranch,
  ListBankName,
  ListBankProvince,
} from "domain/models/Cash";
// import {
//   GetConfirmOrderOnlineResponse,
//   ResultConfirmOrderOnline,
//   ResultRegisterServiceConfirm,
// } from "domain/models/ConfirmationOrder";
import {
  GetPNLExecutedResponse,
  ISecuritiesPortfolioResponse,
  Portfolio,
  PortfolioInfo,
  Profit,
} from "domain/models/Portfolio";
import { ListStockPortfolio } from "domain/models/StockPortfolio";
// import {
//   GetSecuritiesPortfolioResponse,
//   StockTransferHistResponse,
// } from "domain/models/TransferShare";
import {
  DataTransferStatus,
  DataTransferStatusHistory,
} from "domain/models/TransferStatus";
import User from "domain/models/User"; // RegisterBrokerResponse, // IRegisterOnlineServiceResponse, // IChangePasswordResponse, // GetBrokerListResponse,
// import { APPEND_BUSINESS_SUBMIT } from "helper/consts";
import storage from "helper/storage";
// import {
//   DerivAccountParams,
//   GetHistoryParams,
//   MarginContractParams,
//   ResponseCareService,
//   ResponseFrontuser,
//   ServiceRegisterParams,
//   ServiceSMUTParams,
// } from "modules/auth/pages/ModalOnlineService/types";
// import {
//   ResponseStatementMoney,
//   ResponseStatementStock,
// } from "modules/statement/types";
import qs from "qs";
import { v4 as uuid } from "uuid";
import {
  AccountRightsRegisterParams,
  ChangeAccountInfoParams,
  ChangeIdCardParams,
  ChangeInfoParams,
  // ConfirmOrderListParams,
  GetAvailableTradeParams,
  GetLastestShareParams,
  GetListBankBranchParams,
  GetListBankNameParams,
  GetListBankProvinceParams,
  // GetListBrokerParams,
  GetListParams,
  GetListTransferShareFormParams,
  GetOrderLimitedQtyParams,
  GetPortfolioParams,
  // GetSecuritiesPortfolioParam,
  // GetStatementMoneyListParams,
  // GetStatementStockListParams,
  // GetSubmitConfirmDataParamsConvert,
  GetSubmitStockPortfolioDataParams,
  GetTransferAccountParams,
  IDeleteTransferContractBankParams,
  // IChangeBothPasswordParam,
  // IChangePasswordParam,
  IForgotPasswordParam,
  IGetAssetDeltaParams,
  IGetExtendHistoryParamsConverted,
  IGetLoanExtensionListParamsConverted,
  IGetNavAssetReturnParams,
  IPostExtendExpireDataConverted,
  IPostNavRateRequetBody,
  ISetDefaultSubAccoNoParams,
  IUpdateTransferContractBankParams,
  // IRegisterOnlineServiceParam,
  ListTransferShareFormResult,
  onboardApprovalParams,
  PostTransferStatusParams,
  // RegisterBrokerParams,
  // serviceConfirmOrderOnlineParams,
  serviceSmsEmailParams,
  // StockTransferHistParam,
  StockTransferParam,
  StockTransferResponse,
  TransferStatusHistoryParamsConvert,
} from "./types";
import {
  accountRightsRegister,
  assetInfo,
  assetsTradingUrl,
  careLibApi,
  // careService,
  // careServiceHistory,
  changeIdCardUrl,
  changeInfoAccountUrl,
  // changePassword,
  // confirmOrderOnlineUrl,
  // derivAccount,
  extendExpire,
  extendHistory,
  forgotPassword,
  // frontuser,
  getAvailableTradeUrl,
  // getBrokerListUrl,
  getPNLExecuted,
  getTransferAccountUrl,
  infoEmail,
  infoSms,
  listBankBranch,
  listBankName,
  listBankProvince,
  listTransferShareForm,
  loanExtensionList,
  // marginContract,
  navAssetDeltaApi,
  navAssetReturnApi,
  navRateApi,
  onboardApproval,
  portfolioUrl,
  position,
  profit,
  // registerBrokerUrl,
  // registerOnlineService,
  // postConfirmOrderOnline,
  securitiesPortfolio,
  // serviceInfo,
  // serviceRegister,
  serviceSmsEmail,
  // serviceSMUT,
  shareMargin,
  shareTransferStatus,
  shareTransferStatusHistory,
  // statementMoney,
  // statementStock,
  stockPortfolio,
  stockTransfer,
  // stockTransferHistory,
  accountEditcfmastvsd,
  getOrderLimitedQtyUrl,
  updateTransferContractBankUrl,
  deleteTransferContractBankUrl,
  setDefaultSubAccoNoUrl,
  // changeBothPassword,
} from "./urls";

export const getTotalAssetsTradingApi = async (
  account: string
): Promise<AssetsTrading> => {
  const res = await VgaiaHttp.get<AssetsTrading>(
    assetsTradingUrl(storage.getMasterAccount(), account)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAvailableTradeApi = async (
  params: GetAvailableTradeParams
): Promise<GetAvailableTradeResponse> => {
  const res = await VgaiaHttp.get<GetAvailableTradeResponse>(
    getAvailableTradeUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getOrderLimitedQtyApi = async (
  params: GetOrderLimitedQtyParams
): Promise<GetOrderLimitedQtyResponse> => {
  const res = await VgaiaHttp.get<GetOrderLimitedQtyResponse>(
    getOrderLimitedQtyUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const changeInfoApi = async (
  params: ChangeAccountInfoParams
): Promise<User> => {
  const res = await VgaiaHttp.post<User>(
    changeInfoAccountUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const changeIdCardApi = async (
  data: ChangeIdCardParams
): Promise<any> => {
  const res = await VgaiaHttp.put<any>(changeIdCardUrl, qs.stringify(data));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getRealizationList = async (
  params: GetListParams
): Promise<GetPNLExecutedResponse> => {
  const res = await VgaiaHttp.get<GetPNLExecutedResponse>(
    getPNLExecuted,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getStatementMoneyList = async (
//   params: GetStatementMoneyListParams
// ): Promise<ResponseStatementMoney> => {
//   const res = await VgaiaHttp.get<ResponseStatementMoney>(
//     statementMoney,
//     params
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const getStatementStockList = async (
//   params: GetStatementStockListParams
// ): Promise<ResponseStatementStock> => {
//   const res = await VgaiaHttp.get<ResponseStatementStock>(
//     statementStock,
//     params
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const getCareService = async (): Promise<ResponseCareService> => {
//   const res = await VgaiaHttp.get<ResponseCareService>(
//     careService(storage.getMasterAccount())
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const getHistoryService = async (
//   params: GetHistoryParams
// ): Promise<ResponseCareService> => {
//   const res = await VgaiaHttp.get<ResponseCareService>(
//     careServiceHistory(
//       storage.getMasterAccount(),
//       params.fromDate,
//       params.toDate,
//       params.statusCode
//     )
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const getFrontuser = async (
//   user: string
// ): Promise<ResponseFrontuser> => {
//   const res = await VgaiaHttp.get<ResponseFrontuser>(frontuser(user));
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const apiPostServiceRegister = async (
//   data: ServiceRegisterParams
// ): Promise<number> => {
//   const res = await VgaiaHttp.post<number>(
//     serviceRegister,
//     qs.stringify(data)
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const apiPostServiceSMUT = async (
//   data: ServiceSMUTParams
// ): Promise<number> => {
//   const res = await VgaiaHttp.post<number>(serviceSMUT, qs.stringify(data));
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const apiPostMarginContract = async (
//   data: MarginContractParams
// ): Promise<number> => {
//   const res = await VgaiaHttp.post<number>(
//     marginContract,
//     qs.stringify(data)
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const apiPostDerivAccount = async (
//   data: DerivAccountParams
// ): Promise<number> => {
//   const res = await VgaiaHttp.post<number>(derivAccount, qs.stringify(data));
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const getContractInfo = async (): Promise<ResponseContractInfo> => {
//   const res = await VgaiaHttp.get<ResponseContractInfo>(
//     contractInfo(storage.getMasterAccount())
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const getProfitList = async (
  account: string,
  params: GetListParams
): Promise<Profit> => {
  const res = await VgaiaHttp.get<Profit>(
    profit(storage.getMasterAccount(), account),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getLastestShareApi = async (
//   account: string,
//   params: GetLastestShareParams
// ): Promise<Share> => {
//   const res = await VgaiaHttp.get<any>(
//     lastestShare(storage.getMasterAccount(), account),
//     params
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const getPosition = async (
  account: string,
  params: GetPortfolioParams
): Promise<Portfolio> => {
  const res = await VgaiaHttp.get<any>(
    position(storage.getMasterAccount(), account),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getPortfolio = async (
  params: GetPortfolioParams
): Promise<ISecuritiesPortfolioResponse> => {
  const res = await VgaiaHttp.get<ISecuritiesPortfolioResponse>(
    securitiesPortfolio(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAccountBeneficiary = async (
  params: GetTransferAccountParams
): Promise<GetTransferAccountResponse> => {
  const res = await VgaiaHttp.get<GetTransferAccountResponse>(
    getTransferAccountUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const setDefaultSubAccoNoApi = async (
  params: ISetDefaultSubAccoNoParams
): Promise<ISetDefaultSubAccoNoResponse> => {
  const res = await VgaiaHttp.post<ISetDefaultSubAccoNoResponse>(
    setDefaultSubAccoNoUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const addAccountBeneficiary = async (
  params: IUpdateTransferContractBankParams
): Promise<IUpdateTransferContractBankResponse> => {
  const res = await VgaiaHttp.post<IUpdateTransferContractBankResponse>(
    updateTransferContractBankUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleleAccountBeneficiary = async (
  params: IDeleteTransferContractBankParams
): Promise<IDeleteTransferContractBankResponse> => {
  const res = await VgaiaHttp.post<IDeleteTransferContractBankResponse>(
    deleteTransferContractBankUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getSmsList = async (account: string): Promise<any> => {
  const res = await VgaiaHttp.get<any>(
    infoSms(storage.getMasterAccount(), account)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankNameList = async (
  params: GetListBankNameParams
): Promise<ListBankName> => {
  const res = await VgaiaHttp.get<ListBankName>(listBankName, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getEmailList = async (account: string): Promise<any> => {
  const res = await VgaiaHttp.get<any>(
    infoEmail(storage.getMasterAccount(), account)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankProvinceList = async (
  params: GetListBankProvinceParams
): Promise<ListBankProvince> => {
  const res = await VgaiaHttp.get<ListBankProvince>(listBankProvince, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankBranchList = async (
  params: GetListBankBranchParams
): Promise<ListBankBranch> => {
  const res = await VgaiaHttp.get<ListBankBranch>(listBankBranch, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getBrokerListAPI = async (
//   params: GetListBrokerParams
// ): Promise<GetBrokerListResponse> => {
//   const res = await VgaiaHttp.get<GetBrokerListResponse>(
//     getBrokerListUrl,
//     params
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const registerBrokerAPI = async (
//   params: RegisterBrokerParams
// ): Promise<RegisterBrokerResponse> => {
//   const res = await VgaiaHttp.post<RegisterBrokerResponse>(
//     registerBrokerUrl,
//     JSON.stringify(params),
//     { headers: { "Content-Type": "application/json" } }
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const postserviceSmsEmailApi = async (
  params: serviceSmsEmailParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(
    serviceSmsEmail,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getPorfolioApi = async (
  account: string,
  params: GetLastestShareParams
): Promise<PortfolioInfo> => {
  const res = await VgaiaHttp.get<PortfolioInfo>(
    portfolioUrl(storage.getMasterAccount(), account),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getListConfirmationOrder = async (
//   params: GetSubmitConfirmDataParamsConvert
// ): Promise<GetConfirmOrderOnlineResponse> => {
//   const res = await httpOrder.get<GetConfirmOrderOnlineResponse>(
//     confirmOrderOnlineUrl,
//     params
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const postConfirmationOrder = async (
//   params: ConfirmOrderListParams
// ): Promise<ResultConfirmOrderOnline> => {
//   const res = await httpOrder.post<ResultConfirmOrderOnline>(
//     postConfirmOrderOnline,
//     JSON.stringify(params),
//     { headers: { "Content-Type": "application/json" } }
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const postRegisterServiceConfirm = async (
//   params: serviceConfirmOrderOnlineParams
// ): Promise<ResultRegisterServiceConfirm> => {
//   const res = await VgaiaHttp.post<ResultRegisterServiceConfirm>(
//     serviceInfo,
//     qs.stringify(params)
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const getStockPortfolioList = async (
  params: GetSubmitStockPortfolioDataParams
): Promise<ListStockPortfolio> => {
  const res = await VgaiaHttp.get<ListStockPortfolio>(shareMargin, params);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getLoanExtensionList = async (
  params: IGetLoanExtensionListParamsConverted,
  account: string
): Promise<ILoanExtensionResponse> => {
  const masterAccount = storage.getMasterAccount();
  const res = await VgaiaHttp.get<ILoanExtensionResponse>(
    loanExtensionList(masterAccount, account || masterAccount),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getExtendHistory = async (
  params: IGetExtendHistoryParamsConverted,
  account: string
): Promise<IExtendExpireResponse> => {
  const masterAccount = storage.getMasterAccount();
  const res = await VgaiaHttp.get<IExtendExpireResponse>(
    extendHistory(masterAccount, account || masterAccount),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postExtendExpire = async (
  data: IPostExtendExpireDataConverted
): Promise<IExtendExpireResponse> => {
  const masterAccount = storage.getMasterAccount();
  const res = await VgaiaHttp.post<IExtendExpireResponse>(
    extendExpire,
    { ...data, masterAccount },
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postRightsRegister = async (
  params: AccountRightsRegisterParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(
    accountRightsRegister,
    qs.stringify(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getStockPortfolio = async (
  account: string,
  params: GetPortfolioParams
): Promise<Portfolio> => {
  const res = await VgaiaHttp.get<any>(
    stockPortfolio(storage.getMasterAccount(), account),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAssetInfo = async (): Promise<IAssetInfoResponse> => {
  const masterAccount = storage.getMasterAccount();
  const res = await VgaiaHttp.get<IAssetInfoResponse>(assetInfo, {
    masterAccount,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListTransferShareForm = async (
  params: GetListTransferShareFormParams,
  account: string
): Promise<ListTransferShareFormResult> => {
  const masterAccount = storage.getMasterAccount();
  const res = await VgaiaHttp.get<ListTransferShareFormResult>(
    listTransferShareForm(masterAccount, account),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAssetDelta = async (
  params: IGetAssetDeltaParams
): Promise<INavAssetDeltaResponse> => {
  const masterAccount = storage.getMasterAccount();
  const newParams: IGetAssetDeltaParams = {
    ...params,
    masterAccount,
  };
  const res = await VgaiaHttp.get<INavAssetDeltaResponse>(
    navAssetDeltaApi,
    newParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getNavAssetReturn = async (
  params: IGetNavAssetReturnParams
): Promise<INavAssetReturnResponse> => {
  const masterAccount = storage.getMasterAccount();
  const newParams: IGetNavAssetReturnParams = {
    ...params,
    masterAccount,
  };
  const res = await VgaiaHttp.get<INavAssetReturnResponse>(
    navAssetReturnApi,
    newParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getShareTransferStatusHistory = async (
  account: string,
  params: TransferStatusHistoryParamsConvert
): Promise<DataTransferStatusHistory> => {
  const res = await VgaiaHttp.get<any>(
    shareTransferStatusHistory(storage.getMasterAccount(), account),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getShareTransferStatus = async (
  account: string
): Promise<DataTransferStatus> => {
  const res = await VgaiaHttp.get<any>(
    shareTransferStatus + `${storage.getMasterAccount()}/${account}`
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postShareTransferStatus = async (
  data: PostTransferStatusParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(
    shareTransferStatus,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postNavRate = async (
  data: IPostNavRateRequetBody
): Promise<INavRateResponse> => {
  const masterAccount = storage.getMasterAccount();
  const newData: IPostNavRateRequetBody = {
    ...data,
    masterAccount,
  };
  const res = await VgaiaHttp.post<any>(navRateApi, JSON.stringify(newData), {
    headers: { "Content-Type": "application/json" },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postOnboardApproval = async (
  data: onboardApprovalParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(onboardApproval, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const changePasswordApi = async (
//   data: IChangePasswordParam
// ): Promise<IChangePasswordResponse> => {
//   const res = await VgaiaHttp.post<IChangePasswordResponse>(
//     changePassword,
//     data,
//     { headers: { "Content-Type": "application/json" } }
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const changeBothPasswordApi = async (
//   data: IChangeBothPasswordParam
// ): Promise<IChangePasswordResponse> => {
//   const res = await VgaiaHttp.post<IChangePasswordResponse>(
//     changeBothPassword,
//     data,
//     { headers: { "Content-Type": "application/json" } }
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// export const getSecuritiesPortfolioApi = async (
//   params: GetSecuritiesPortfolioParam
// ): Promise<GetSecuritiesPortfolioResponse> => {
//   const res = await VgaiaHttp.get<GetSecuritiesPortfolioResponse>(
//     securitiesPortfolio,
//     params
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const fetchConditionExtendLoan =
  async (): Promise<IConditionExtendLoanResponse> => {
    const fixedParams = { lib: "CONDITION_EXTEND_LOAN" };
    const res = await VgaiaHttp.get<IConditionExtendLoanResponse>(
      careLibApi,
      fixedParams
    );
    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  };

// export const getStockTransferHistory = async (
//   params: StockTransferHistParam
// ): Promise<StockTransferHistResponse> => {
//   const res = await VgaiaHttp.get<StockTransferHistResponse>(
//     stockTransferHistory,
//     params
//   );

//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const postTransferShareForm = async (
  params: StockTransferParam
): Promise<StockTransferResponse> => {
  const res = await VgaiaHttp.post<StockTransferResponse>(
    stockTransfer,
    {
      ...params,
      requestId: uuid(),
    },
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const registerOnlineServiceApi = async (
//   params: IRegisterOnlineServiceParam
// ): Promise<IRegisterOnlineServiceResponse> => {
//   const res = await VgaiaHttp.post<IRegisterOnlineServiceResponse>(
//     registerOnlineService,
//     { ...params, requestId: uuid(), ...APPEND_BUSINESS_SUBMIT },
//     { headers: { "Content-Type": "application/json" } }
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

// Api quên mật khẩu
export const forgetPasswordApi = async (
  data: IForgotPasswordParam
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(forgotPassword, data, {
    headers: { "Content-Type": "application/json" },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const accountEditcfmastvsdApi = async (
  data: ChangeInfoParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(
    accountEditcfmastvsd,
    qs.stringify(data)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
