import { FC } from "react";
import { StockNameWrapper } from "./styles";
import GlobalData from "helper/globalData";
interface Props {
  ticker: string;
}

const StockNameCell: FC<Props> = ({ ticker }) => {
  const stockName = GlobalData.getTickerInfoNew(ticker!)?.secname || "-";

  return (
    <StockNameWrapper title={stockName}>{stockName || "-"}</StockNameWrapper>
  );
};

export { StockNameCell };
