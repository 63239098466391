import { Select, Button, DatePicker } from "components/commons";
import { memo, useMemo, useEffect } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  searchTransferBondRequest,
} from "modules/transfer-bond/redux";
import {
  loadingSearchDataSelector,
  filterTransferBondSelector,
} from "modules/transfer-bond/selectors";
import { ISearchTransferBondParams } from "core/http/apis/transfer-bond/types";

import moment from "moment";
import { toast } from "components/commons/Toast";
import { Row } from "react-bootstrap";

const statusOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "transferBond.filter.status.all",
    }),
  },
  {
    value: "0",
    label: intl.formatMessage({
      id: "transferBond.filter.status.wait_approve",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "transferBond.filter.status.confirmed",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "transferBond.filter.status.rejected",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "transferBond.filter.status.deleted",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { fromDate, toDate, status } = useSelector(filterTransferBondSelector);

  const loading = useSelector(loadingSearchDataSelector);

  const statusOptions = useMemo(() => statusOption(intl), [intl]);
  useEffect(() => {
    dispatch(
      updateFilter({
        key: "status",
        value: statusOptions ? +statusOptions[0].value : -1,
      })
    );
    const submitData: ISearchTransferBondParams = {
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      status: -1,
    };
    dispatch(searchTransferBondRequest(submitData));
  }, []);

  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      return toast("common.warningInvalidDay", "error");
    }

    const submitData: ISearchTransferBondParams = {
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      status:
        status !== null && status !== undefined
          ? status
          : +statusOptions[0].value
          ? +statusOptions[0].value
          : -1,
    };
    dispatch(searchTransferBondRequest(submitData));
  };

  // effect

  // render
  return (
    <Wrap
      className="d-flex justify-content-between align-items-center"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <Row className=" d-flex align-content-center w-100 pl-3">
          <FilterItem
            filterName={intl.formatMessage({
              id: "transferBond.filter.fromDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={fromDate}
                onChange={(e: any) => {
                  dispatch(
                    updateFilter({
                      key: "fromDate",
                      value: e,
                    })
                  );
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "transferBond.filter.toDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={toDate}
                onChange={(e: any) => {
                  dispatch(
                    updateFilter({
                      key: "toDate",
                      value: e,
                    })
                  );
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>
          <FilterItem
            filterName={intl.formatMessage({
              id: "transferBond.filter.status.label",
            })}
            className="marginRight2 text-grey"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={statusOptions[0]}
                options={statusOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "status",
                      value: e?.value,
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
          <Button
            color="accept"
            className="button-filter1 mr-4"
            loading={loading}
            onClick={handleSearch}
          >
            {intl.formatMessage({
              id: "transferBond.filter.search",
            })}
          </Button>
        </Row>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
