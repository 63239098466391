import { SecType } from "helper/consts";
import { useOutsideClick } from "helper/cusHooks";
import {
  updateCurrentTicker,
  onChangeTickerByEnterDer,
  updateMultipleNum,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import React, {
  useEffect,
  useRef,
  useCallback,
  FC,
  memo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { TextItem } from "./styles";

interface Props {
  data: any;
  focus: any;
  index: number;
  setFocus: any;
  setVisibleTextSearch: (value: boolean) => void;
  setTextSearch: (value: string) => void;
  setTextDisplay: (value: boolean) => void;
  setIsChangeArrow: (value: boolean) => void;
  setIsClickOutSide: (value: boolean) => void;
  setTickerSelect: (value: string) => void;
}

const SelectItem: FC<Props> = memo(
  ({
    data,
    focus,
    index,
    setFocus,
    setVisibleTextSearch,
    setTextSearch,
    setTextDisplay,
    setIsChangeArrow,
    setIsClickOutSide,
    setTickerSelect,
  }) => {
    const ref = useRef<HTMLLIElement>(null);
    const dispatch = useDispatch();
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
      if (focus) {
        setIsFocus(true);
        ref.current?.focus();
      }
    }, [focus]);

    const handleSelect = useCallback(() => {
      setFocus(index);
    }, [data, index, setFocus]);

    const handleKeyDown = (e: any, data: any) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.stopPropagation();
        // unSubscribleTicker(currentTicker);
        setVisibleTextSearch(false);
        setTextSearch("");
        setTextDisplay(true);
        dispatch(updateCurrentTicker(data.seccd));
        dispatch(
          updateMultipleNum(data.sectype === SecType.S ? 100000 : 10000)
        );
        setIsChangeArrow(false);
        dispatch(onChangeTickerByEnterDer(true));
      }
    };

    useOutsideClick(ref, () => {
      if (isFocus) {
        setIsClickOutSide(true);
        setTimeout(() => setIsChangeArrow(false), 150);
      }
    });

    return (
      <TextItem
        tabIndex={focus ? 0 : -1}
        ref={ref}
        className="ticker-item"
        key={data.seccd}
        onMouseDown={(e) => {
          e.stopPropagation();
          // unSubscribleTicker(currentTicker);
          setVisibleTextSearch(false);
          setTextSearch("");
          setTextDisplay(true);
          dispatch(updateCurrentTicker(data.seccd));
          dispatch(
            updateMultipleNum(data.sectype === SecType.S ? 100000 : 10000)
          );
          setTickerSelect(data.seccd);
          dispatch(onChangeTickerByEnterDer(true));
        }}
        onKeyPress={handleSelect}
        onKeyDown={(e) => handleKeyDown(e, data)}
      >
        <div>
          <span className="share-code">{data.seccd} </span>
        </div>
      </TextItem>
    );
  }
);

export default SelectItem;
