import classNames from "classnames";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";
import { FormattedMessage } from "react-intl";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { createSelector } from "reselect";
import { DATE_TIME_FORMAT } from "helper/consts";
import moment from "moment";
import { useEffect } from "react";
import { subscribeTickers } from "core/wss";

const lasttradedateSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.lasttradedate
);
const execpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.execprice
);
const execratioSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.execratio?.data
);
const issuerSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.issuer?.data
);
const baseseccdSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.baseseccd?.data
);
const basepriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.baseprice
);

function TickerDetailCW() {
  const lasttradedate = useSelector(lasttradedateSelector);
  const execprice = useSelector(execpriceSelector);
  const execratio = useSelector(execratioSelector);
  const issuer = useSelector(issuerSelector);
  const baseseccd = useSelector(baseseccdSelector);
  const baseprice = useSelector(basepriceSelector);

  useEffect(() => {
    if (baseseccd) {
      subscribeTickers(baseseccd);
    }
  }, [baseseccd]);

  const listData = [
    {
      text: "lasttradedate",
      value: lasttradedate
        ? moment(lasttradedate, DATE_TIME_FORMAT.YYYYMMDD).format(
            DATE_TIME_FORMAT.DDMMYYYY
          )
        : "",
      unit: "",
    },
    {
      text: "execprice",
      value: numeral(execprice).format("0.00"),
      unit: "",
    },
    {
      text: "execratio",
      value: execratio,
      unit: "",
    },
    {
      text: "issuer",
      value: issuer,
      unit: "",
    },
    {
      text: "baseseccd",
      value: baseseccd,
      unit: "",
    },
    {
      text: "baseprice",
      value: numeral(baseprice).format("0,0.00"),
      unit: "",
    },
  ];
  return (
    <>
      {listData.map((item, idx) => (
        <div
          className={classNames({ "title-info": (idx + 1) % 3 === 0 })}
          key={idx}
        >
          <div className="info-text">
            <FormattedMessage id={`widgets.tickerInfo.${item?.text}`} />
          </div>
          <div className="info-value">
            <VolumeChange value={item?.value} />
            {item?.unit}
          </div>
        </div>
      ))}
    </>
  );
}

export default TickerDetailCW;
