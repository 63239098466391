import { FC } from "react";
import { useIntl } from "react-intl";
import Container from "components/commons/Container";
import HoldUnholdBankAccountContent from "./components/HoldUnholdBankAccountContent";

const HoldUnholdBankAccount: FC = () => {
  const intl = useIntl();

  const arrayForm = [HoldUnholdBankAccountContent];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "holdUnholdBankAccount.utility",
      })}
      headerForm={intl.formatMessage({
        id: "holdUnholdBankAccount.utility",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default HoldUnholdBankAccount;
