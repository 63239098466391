import { MarketIndexInfo } from "domain/protoNew/auto_trading_pb";
import {
  colorHeaderCardSelector,
  valueHeaderCardSelector,
} from "modules/system/selectors";
import { useSelector } from "react-redux";
import { LineChart } from "components/commons";

interface Props {
  ticker: string;
}

function ChartHeader({ ticker }: Props) {
  const color = useSelector(colorHeaderCardSelector(ticker));
  const data = useSelector(
    valueHeaderCardSelector(ticker, "indextimeList")
  ) as MarketIndexInfo.IndexTime.AsObject[];
  const openindex = useSelector(
    valueHeaderCardSelector(ticker, "openindex")
  ) as number;
  return (
    <div className="chart-size">
      <LineChart dataChart={data} color={color} referencePrice={openindex} />
    </div>
  );
}
export default ChartHeader;
