import styled from "styled-components";

export const ContainerTable = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  border-radius: 4px;
  overflow: auto;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: ${({ theme }) => theme.shadowLineDown};

  background: ${({ theme }) => theme.background3};
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.grey20};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;

export const ColorYellow = styled.div`
  color: ${({ theme }) => theme.solidYellow};
`;
export const ColorRed = styled.div`
  color: ${({ theme }) => theme.solidRed};
`;
export const ColorGreen = styled.div`
  color: ${({ theme }) => theme.solidGreen};
`;
export const ColorGray = styled.div`
  color: ${({ theme }) => theme.solidGray};
`;
export const ButtonBuy = styled.div`
  color: ${({ theme }) => theme.solidGreen};
  cursor: pointer;
`;

export const Width120 = styled.div<{
  align?: string;
  color?: string;
  background?: string;
  height?: string;
}>`
  min-width: 120px;
  height: ${({ height }) => (height ? height : "28px")};
  display: flex;
  padding: 6px 8px;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  background: ${({ background, theme }) =>
    background ? background : theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  color: ${({ color, theme }) => (color ? color : theme.grey20)};
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;
export const Width268 = styled.div<{
  align?: string;
  color?: string;
  background?: string;
  height?: string;
}>`
  min-width: 268.67px;
  height: ${({ height }) => (height ? height : "28px")};
  display: flex;
  padding: 6px 8px;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "right")};
  background: ${({ background, theme }) =>
    background ? background : theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  color: ${({ color, theme }) => (color ? color : theme.grey20)};
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;
export const Width140 = styled.div<{
  align?: string;
  color?: string;
  background?: string;
  height?: string;
}>`
  min-width: 140px;
  display: flex;
  padding: 6px 8px;
  height: ${({ height }) => (height ? height : "28px")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  background: ${({ background, theme }) =>
    background ? background : theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  color: ${({ color, theme }) => (color ? color : theme.grey20)};
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;
export const Width160 = styled.div<{
  align?: string;
  color?: string;
  background?: string;
  height?: string;
}>`
  min-width: 160px;
  display: flex;
  padding: 6px 8px;
  height: ${({ height }) => (height ? height : "28px")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  background: ${({ background, theme }) =>
    background ? background : theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  color: ${({ color, theme }) => (color ? color : theme.grey20)};
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;

export const Width200 = styled.div<{
  align?: string;
  color?: string;
  background?: string;
  height?: string;
}>`
  min-width: 200px;
  display: flex;
  padding: 6px 8px;
  height: ${({ height }) => (height ? height : "28px")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  background: ${({ background, theme }) =>
    background ? background : theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  color: ${({ color, theme }) => (color ? color : theme.grey20)};
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 32px);
  border-radius: 4px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;
