import AvgPriceCell from "./CellRealTime/AvgPriceCell";
import HighestPriceCell from "./CellRealTime/HighestPriceCell";
import TotalqtyCell from "./CellRealTime/TotalqtyCell";
import BuyforeignqtyCell from "./CellRealTime/BuyforeignqtyCell";
import TotalroomCell from "./CellRealTime/TotalroomCell";
import SellforeignqtyCell from "./CellRealTime/SellforeignqtyCell";

function TickerDetailRealTime() {
  return (
    <>
      <AvgPriceCell />
      <HighestPriceCell />
      <TotalqtyCell />
      <BuyforeignqtyCell />
      <SellforeignqtyCell />
      <TotalroomCell />
    </>
  );
}

export default TickerDetailRealTime;
