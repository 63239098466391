import { Select, Button, DatePicker } from "components/commons";
import { memo, useMemo, useEffect } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  searchDepositPowerRequest,
} from "modules/deposit-power/redux";
import {
  loadingSearchDataSelector,
  filterDepositPowerSelector,
} from "modules/deposit-power/selectors";
import { ISearchDepositPowerParams } from "core/http/apis/deposit-power/types";

import moment from "moment";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import { Row } from "react-bootstrap";

const contractStsOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "depositPower.filter.status.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "depositPower.filter.status.available",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "depositPower.filter.status.liquidated",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { fromDate, toDate, contractSts } = useSelector(
    filterDepositPowerSelector
  );

  const loading = useSelector(loadingSearchDataSelector);

  const contractStsOptions = useMemo(() => contractStsOption(intl), [intl]);
  useEffect(() => {
    dispatch(
      updateFilter({
        key: "contractSts",
        value: contractStsOptions ? +contractStsOptions[1].value : 1,
      })
    );
    const submitData: ISearchDepositPowerParams = {
      branchCd: "",
      transactionCd: "",
      custNo: storage.getMasterAccount() ?? "",
      fromDate: fromDate ? Number(moment(fromDate).format("YYYYMMDD")) : 0,
      toDate: toDate ? Number(moment(toDate).format("YYYYMMDD")) : 0,
      contractSts: 1,
      status: "",
      srcChannel: "",
    };
    dispatch(searchDepositPowerRequest(submitData));
  }, []);

  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      return toast("common.warningInvalidDay", "error");
    }

    const submitData: ISearchDepositPowerParams = {
      branchCd: "",
      transactionCd: "",
      custNo: storage.getMasterAccount() ?? "",
      fromDate: fromDate ? Number(moment(fromDate).format("YYYYMMDD")) : 0,
      toDate: toDate ? Number(moment(toDate).format("YYYYMMDD")) : 0,
      contractSts:
        contractSts !== undefined && contractSts !== null
          ? contractSts
          : +contractStsOptions[1].value
          ? +contractStsOptions[1].value
          : 1,
      status: "",
      srcChannel: "",
    };
    dispatch(searchDepositPowerRequest(submitData));
  };

  // effect

  // render
  return (
    <Wrap
      className="d-flex justify-content-between align-items-center  w-100"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <Row className=" d-flex align-content-center w-100 pl-3">
          <FilterItem
            filterName={intl.formatMessage({
              id: "depositPower.filter.fromDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={fromDate}
                onChange={(e: any) => {
                  dispatch(
                    updateFilter({
                      key: "fromDate",
                      value: e,
                    })
                  );
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "depositPower.filter.toDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={toDate}
                onChange={(e: any) => {
                  dispatch(
                    updateFilter({
                      key: "toDate",
                      value: e,
                    })
                  );
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>
          <FilterItem
            filterName={intl.formatMessage({
              id: "depositPower.filter.status.label",
            })}
            className="marginRight2 text-grey"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={contractStsOptions[1]}
                options={contractStsOptions}
                onChange={(e) => {
                  dispatch(
                    updateFilter({
                      key: "contractSts",
                      value: e?.value ? +e.value : 1,
                    })
                  );
                }}
              />
            </div>
          </FilterItem>
          <Button
            color="accept"
            className="button-filter1 mr-4"
            loading={loading}
            onClick={handleSearch}
          >
            {intl.formatMessage({
              id: "depositPower.filter.search",
            })}
          </Button>
        </Row>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
