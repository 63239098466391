import { Tab, Wrap } from "./styles";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { activeTabGuideVideoSelector } from "modules/guideVideo/selectors";
import {
  changeActiveTabGuideVideo,
  changeButtonActiveGuideVideo,
} from "modules/guideVideo/redux";
import { ActiveTabGuideVideo } from "modules/confirmation/pages/constants";

const Navigaton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const active = useSelector(activeTabGuideVideoSelector);

  const selectTab = (value: ActiveTabGuideVideo) => {
    dispatch(changeActiveTabGuideVideo(value));
    dispatch(changeButtonActiveGuideVideo(""));
  };

  return (
    <Wrap>
      <div className="header-left">
        <div>
          <Tab
            isActive={active === ActiveTabGuideVideo.TRAINING}
            onClick={() => selectTab(ActiveTabGuideVideo.TRAINING)}
          >
            {intl.formatMessage({
              id: "guideVideo.navigation.training",
            })}
          </Tab>
        </div>
        <div>
          <Tab
            isActive={active === ActiveTabGuideVideo.USER_MANUAL}
            onClick={() => selectTab(ActiveTabGuideVideo.USER_MANUAL)}
          >
            {intl.formatMessage({
              id: "guideVideo.navigation.userManual",
            })}
          </Tab>
        </div>
      </div>
    </Wrap>
  );
};

export default Navigaton;
