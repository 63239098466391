import { GlobalHotKeys, HotKeysProps, KeyMap, configure } from "react-hotkeys";
import { memo, useEffect } from "react";
import Storage from "helper/storage";
import { useState } from "react";
import { ListKeyShortcuts } from "themes/types";
import { useSelector } from "react-redux";
import { listHotKeysSelector } from "themes/redux";
import { useRef } from "react";
import { isEqual } from "lodash";

configure({
  ignoreEventsCondition: (event) => {
    return false;
  },
});

interface IKeyMap extends KeyMap {
  GO_TO_HOME_PAGE: string;
  GO_TO_MARKET_PAGE: string;
  GO_TO_STOCK_ORDER_PAGE: string;
  GO_TO_STOCK_ORDER_DER_PAGE: string;
  GO_TO_STOCK_ORDER_BUY: string | string[];
  GO_TO_STOCK_ORDER_SELL: string | string[];
}

const initialKeyMap: IKeyMap = {
  GO_TO_HOME_PAGE: "F1",
  GO_TO_MARKET_PAGE: "F2",
  GO_TO_STOCK_ORDER_PAGE: "F3",
  GO_TO_STOCK_ORDER_DER_PAGE: "F4",
  GO_TO_STOCK_ORDER_BUY: "ctrl+b",
  GO_TO_STOCK_ORDER_SELL: "ctrl+s",
};

const getAlternativeHotkeys = (key: string) => {
  return [key, key.replace("ctrl+", "command+")];
};

const getKeyMap = (listHotKeyStorage: ListKeyShortcuts) => {
  return {
    GO_TO_HOME_PAGE: listHotKeyStorage?.keyShortcutCategory
      ? listHotKeyStorage?.keyShortcutCategory
      : "F1",
    GO_TO_MARKET_PAGE: listHotKeyStorage?.keyShortcutMarket
      ? listHotKeyStorage?.keyShortcutMarket
      : "F2",
    GO_TO_STOCK_ORDER_PAGE: listHotKeyStorage?.keyShortcutStockOrder
      ? listHotKeyStorage?.keyShortcutStockOrder
      : "F3",
    GO_TO_STOCK_ORDER_DER_PAGE: listHotKeyStorage?.keyShortcutStockOrderDer
      ? listHotKeyStorage?.keyShortcutStockOrderDer
      : "F4",
    GO_TO_STOCK_ORDER_BUY: listHotKeyStorage?.keyShortcutStockOrderBuy
      ? getAlternativeHotkeys(listHotKeyStorage?.keyShortcutStockOrderBuy)
      : "ctrl+b",
    GO_TO_STOCK_ORDER_SELL: listHotKeyStorage?.keyShortcutStockOrderSell
      ? getAlternativeHotkeys(listHotKeyStorage?.keyShortcutStockOrderSell)
      : "ctrl+s",
  };
};

function HotKeysManager({ children, ...props }: HotKeysProps) {
  const listHotKeys = useSelector(listHotKeysSelector);
  const listHotKeysRefs = useRef<ListKeyShortcuts>();

  if (!isEqual(listHotKeys, listHotKeysRefs))
    listHotKeysRefs.current = listHotKeys;

  const rootStorage = Storage.get("persist:root");
  let listHotKeyStorage: ListKeyShortcuts = {};
  if (rootStorage) {
    const rootStorageFormatted = JSON.parse(rootStorage);
    const themeStorage = rootStorageFormatted.theme;
    if (themeStorage) {
      listHotKeyStorage = JSON.parse(themeStorage).listHotKeys;
    }
  }
  const [keyMapChange, setKeyMapChange] = useState(initialKeyMap);

  useEffect(() => {
    const newKeyMap = getKeyMap(listHotKeys || listHotKeyStorage);
    setKeyMapChange(newKeyMap);
  }, [listHotKeysRefs.current]);

  return (
    <GlobalHotKeys keyMap={keyMapChange} {...props}>
      {children}
    </GlobalHotKeys>
  );
}

export default memo(HotKeysManager);
