import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import { searchStatisticOnday } from "core/http/apis/statistic-onday";
import { ISearchStatisticOndayParams } from "core/http/apis/statistic-onday/types";
import { IMarketStatisticResponse } from "domain/models/MarketStatistic";
import {
  searchStatisticOndayFailure,
  searchStatisticOndayRequest,
  searchStatisticOndaySuccess,
} from "./redux";

function* searchStatisticWorker(
  action: ReturnType<typeof searchStatisticOndayRequest>
) {
  try {
    const params: ISearchStatisticOndayParams = action.payload;
    const response: IMarketStatisticResponse = yield call(
      searchStatisticOnday,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchStatisticOndaySuccess(response));
    } else {
      yield put(searchStatisticOndayFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchStatisticOndayFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchStatisticOndayWatcher() {
  yield takeLatest(searchStatisticOndayRequest.type, searchStatisticWorker);
}

export default function* statisticOndaySaga() {
  yield all([searchStatisticOndayWatcher()]);
}
