import {
  CardTable,
  Container,
  WatchListWrapper,
  SetCommandWrapper,
  ContentWrapper,
  SetCommandHeader,
  ConsoleOverlay,
  ConsoleContainer,
  FooterWatchList,
} from "./style";
import { memo, useEffect } from "react";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit.svg";
import { closePlaceOrder } from "modules/categories/redux/placeOrder";
import { createSelector } from "@reduxjs/toolkit";
import { FormattedMessage } from "react-intl";
import Header from "./Header";
import { RootState } from "redux/store";
import TableBody from "./Body/TableBody";
import TableHeader from "./Body/TableHeader";
import { useSelector, useDispatch } from "react-redux";
import { synchronizedTableWithCurrentListCode } from "modules/categories/redux";
import PutThrough from "../PutThrough";
import {
  categoryRootSelector,
  consoleSelector,
  selectedOddLotTypeSelector,
} from "modules/categories/redux/selectors";
import { ReactComponent as IconBug } from "assets/image/svg/bug.svg";
import { SHOW_WATCHLIST_CONSOLE } from "AppConfig";
import { togglePanel } from "modules/categories/redux/console";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { SystemState } from "domain/protoNew/auto_trading_pb";
import TableFooter from "./TableFooter";

const categoriesSelector = (state: RootState) => state.categories;

const placeOrderSelector = createSelector(
  categoriesSelector,
  (state) => state.placeOrder
);

const selectedTypeCoverWarrantSelector = createSelector(
  categoriesSelector,
  (state) => state.coverWarrant.selectedTypeCoverWarrant
);

const selectedListedTypeSelector = createSelector(
  categoriesSelector,
  (state) => state.listed.selectedListedType
);

const selectedTypeDerivativeSelector = createSelector(
  categoriesSelector,
  (state) => state.derivative.selectedTypeDerivative
);

const selectedIndustryTypeSelector = createSelector(
  categoriesSelector,
  (state) => state.industry.selectedIndustryType
);

const firstLoadPageSelector = createSelector(
  categoriesSelector,
  (state) => state.root.firstLoadPage
);

function Table() {
  const { currentListCode, isExistingList } = useSelector(categoryRootSelector);

  const selectedTypeCoverWarrant = useSelector(
    selectedTypeCoverWarrantSelector
  );
  const firstLoadPage = useSelector(firstLoadPageSelector);

  const selectedListedType = useSelector(selectedListedTypeSelector);

  const selectedTypeDerivative = useSelector(selectedTypeDerivativeSelector);

  const selectedIndustryType = useSelector(selectedIndustryTypeSelector);
  const selectedOddLotType = useSelector(selectedOddLotTypeSelector);
  const dispatch = useDispatch();

  const { activeStep, isShowPlaceOrder } = useSelector(placeOrderSelector);

  const watchlistConsole = useSelector(consoleSelector);
  const handleClosePlaceOrder = () => {
    dispatch(closePlaceOrder());
  };

  useEffect(() => {
    if (firstLoadPage) return;

    if (isExistingList) {
      dispatch(synchronizedTableWithCurrentListCode());
    }
  }, [
    currentListCode,
    selectedTypeCoverWarrant,
    selectedListedType,
    selectedTypeDerivative,
    selectedIndustryType,
    selectedOddLotType,
    isExistingList,
    firstLoadPage,
  ]);

  useEffect(() => {
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(synchronizedTableWithCurrentListCode());
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  }, []);

  const renderPlaceOrderByType = () => {
    // return <PlaceOrder />;
    return <div />;
  };

  function handleConsoleOverlayClick() {
    dispatch(togglePanel());
  }

  return (
    <WatchListWrapper isShowPlaceOrder={isShowPlaceOrder}>
      {SHOW_WATCHLIST_CONSOLE && (
        <>
          <ConsoleOverlay
            onClick={handleConsoleOverlayClick}
            title="toggle watchlist console"
          >
            <IconBug />
          </ConsoleOverlay>
        </>
      )}
      <Container>
        <Header />
        <CardTable>
          <div className="card-content">
            {currentListCode === "PutThrough" ? (
              <>
                <PutThrough />
                <FooterWatchList>
                  <FormattedMessage id="category.table.footerPutThrough" />
                </FooterWatchList>
              </>
            ) : (
              <>
                <TableHeader />
                <TableBody />
                <TableFooter />
              </>
            )}
          </div>
        </CardTable>
      </Container>
      {isShowPlaceOrder && (
        <Container>
          <SetCommandWrapper className="card-content">
            <SetCommandHeader>
              <FormattedMessage id="widgets.placeOrderDer.name" />
              <IconExit onClick={handleClosePlaceOrder} className="icon-exit" />
            </SetCommandHeader>
            <ContentWrapper>
              {activeStep === "placeOrder" && renderPlaceOrderByType()}
            </ContentWrapper>
          </SetCommandWrapper>
        </Container>
      )}

      {SHOW_WATCHLIST_CONSOLE && watchlistConsole.isShowingPanel && (
        <ConsoleContainer>
          <SetCommandWrapper className="card-content">
            <SetCommandHeader>
              Watchlist Console
              <IconExit
                onClick={() => dispatch(togglePanel())}
                className="icon-exit"
              />
            </SetCommandHeader>
          </SetCommandWrapper>
        </ConsoleContainer>
      )}
    </WatchListWrapper>
  );
}

export default memo(Table);
