import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketWatchTab } from "components/widgets/generals/MarketWatch/types";
import liquidityReducer from "./liquidity";
import statBasketContributionReducer from "./statBasketContribution";
import transactionValueVolumeReducer from "./transactionValueVolume";
import foreignChartReducer from "./foreignChart";
import capitalizationReducer from "./capitalization";
import marketStatsReducer from "./marketStats";

export type MarketWatchState = {
  activeTab: MarketWatchTab;
  loading: boolean;
  error: string | undefined;
};

export type ChartData = {
  labels: string[] | number[];
  datasets: any[];
};

const initialState: MarketWatchState = {
  activeTab: "topIndex",
  loading: false,
  error: undefined,
};

export const VALUE_TYPES = [
  {
    label: "widgets.marketWatch.valueTypes.volume",
    value: "1",
  },
  {
    label: "widgets.marketWatch.valueTypes.price",
    value: "2",
  },
];

const marketWatchSlice = createSlice({
  name: "marketWatchSlice",
  initialState,
  reducers: {
    changeActiveTab: (state, action: PayloadAction<MarketWatchTab>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { changeActiveTab } = marketWatchSlice.actions;

export default combineReducers({
  root: marketWatchSlice.reducer,
  liquidity: liquidityReducer,
  statBasketContribution: statBasketContributionReducer,
  transactionValueVolume: transactionValueVolumeReducer,
  capitalization: capitalizationReducer,
  foreignChart: foreignChartReducer,
  marketStats: marketStatsReducer,
});
