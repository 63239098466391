/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  DetailRow,
  DetailLabel,
  WrapLabel,
  DetailInput,
  DivImage,
  WrapButtonCam,
} from "./style";
import { Button } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import {
  updateFormSubmit,
  updateInformationExtraction,
  updateParams,
  updateStep,
} from "modules/auth/redux/modalRegister";
import ModalCamera from "./ModalCamera";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useFilePicker } from "use-file-picker";
import {
  uploadIdCardApi,
  validateIdNumberApi,
} from "core/http/apis/modal-register";
import {
  selectBtnSt2,
  selectFormSubmit,
  selectParams,
  selectDataCreateUser,
} from "modules/auth/selectorsModalRegister";
import { toast } from "components/commons/Toast";

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: "image/jpeg" });
}

const Step3 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectBtnSt = useSelector(selectBtnSt2);
  const form: any = useSelector(selectFormSubmit);
  const params = useSelector(selectParams);

  const dataCreate = useSelector(selectDataCreateUser);

  const userEkyc = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "IS_USE_EKYC"
      )
    : {};

  const [modalCam, setModalCam] = useState({
    status: false,
    type: "",
  });

  const handleImageCam = (value: any, key: string) => {
    if (form?.imageUrlFront === "" && form?.imageUrlBack === "") {
      dispatch(updateFormSubmit({ value: true, key: "changePhoto" }));
    }
    if (
      form?.imageUrlFront !== "" &&
      form?.imageUrlBack !== "" &&
      !form?.changePhoto
    ) {
      dispatch(updateFormSubmit({ value: true, key: "changePhoto" }));
    }

    if (key === "font") {
      dispatch(updateFormSubmit({ value: value, key: "imageUrlFront" }));
    } else {
      dispatch(updateFormSubmit({ value: value, key: "imageUrlBack" }));
    }
  };

  const [hoverImageFont, sethoverImageFont] = useState<boolean>(false);
  const [hoverImageBack, sethoverImageBack] = useState<boolean>(false);

  const [openFileSelector, { filesContent, plainFiles }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    maxFileSize: 5,
  });

  const [typeUpload, settypeUpload] = useState("");

  useEffect(() => {
    if (filesContent[0]) {
      if (typeUpload === "font") {
        dispatch(
          updateFormSubmit({
            value: filesContent[0].content,
            key: "imageUrlFront",
          })
        );
      }
      if (typeUpload === "back") {
        dispatch(
          updateFormSubmit({
            value: filesContent[0].content,
            key: "imageUrlBack",
          })
        );
      }
    }
  }, [filesContent]);

  const [loading, setloading] = useState(false);

  const checkCmnd = async (numberCMND: any) => {
    const res: any = await validateIdNumberApi({
      idNumber: numberCMND,
      approvalCd: params.approvalCd,
    });
    if (res?.statusCode === 0) {
      return true;
    } else {
      toast(res?.errorCode, "error", res?.message, true);
      return false;
    }
  };

  const uploadfile = async () => {
    setloading(true);
    if (!form?.changePhoto) {
      setloading(false);
      return dispatch(updateStep(STEPS.STEPS_4));
    }
    const x = new File([form?.imageUrlFront], "frontImage", {
      type: "image/jpeg",
    });
    const y = new File([form?.imageUrlBack], "backImage", {
      type: "image/jpeg",
    });

    const frontImageFile: any = new FormData();
    frontImageFile.append(
      "frontImage",
      dataURLtoFile(form?.imageUrlFront, "frontImage")
    );

    frontImageFile.append(
      "backImage",
      dataURLtoFile(form?.imageUrlBack, "backImage")
    );
    // frontImageFile.append("approveCd", selectBtnSt);
    frontImageFile.append("approveCd", "1");

    const res: any = await uploadIdCardApi(frontImageFile);
    if (res?.statusCode === 0) {
      dispatch(updateFormSubmit({ value: false, key: "changePhoto" }));
      dispatch(
        updateFormSubmit({
          value: res?.data?.custFamilyName,
          key: "custFamilyName",
        })
      );
      dispatch(
        updateFormSubmit({
          value: res?.data?.custGivenName,
          key: "custGivenName",
        })
      );
      dispatch(
        updateFormSubmit({ value: res?.data?.birthDay, key: "birthDay" })
      );

      dispatch(updateInformationExtraction(res?.data));
      dispatch(
        updateParams({
          imageFront: res?.data?.imageFront,
          imageBack: res?.data?.imageBack,
        })
      );
      if (await checkCmnd(res?.data?.idNumber)) {
        if (userEkyc?.value === "false") {
          dispatch(updateStep(STEPS.STEPS_5));
        } else {
          dispatch(updateStep(STEPS.STEPS_4));
        }
      }
      setloading(false);
    } else {
      setloading(false);

      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const changeUploadFile = (key: string) => {
    if (form?.imageUrlFront === "" && form?.imageUrlBack === "") {
      dispatch(updateFormSubmit({ value: true, key: "changePhoto" }));
    }
    if (
      form?.imageUrlFront !== "" &&
      form?.imageUrlBack !== "" &&
      !form?.changePhoto
    ) {
      dispatch(updateFormSubmit({ value: true, key: "changePhoto" }));
    }
    settypeUpload(key);
    openFileSelector();
  };

  return (
    <>
      <Container>
        <Wrap>
          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="auth.register.step3.question" />
            </DetailLabel>
            <DetailInput>
              <div className="w-50">
                <WrapLabel>
                  <FormattedMessage id="auth.register.step3.labelFronSide" />
                </WrapLabel>
                <DivImage
                  url={form?.imageUrlFront}
                  onMouseEnter={() => sethoverImageFont(false)}
                  onMouseLeave={() => sethoverImageFont(true)}
                >
                  <WrapButtonCam
                    isNone={
                      form?.imageUrlFront && hoverImageFont ? true : false
                    }
                  >
                    <Button
                      color="gradientaAccept"
                      className="button-filter mb-2"
                      width="120px"
                      onClick={() =>
                        setModalCam({ status: true, type: "font" })
                      }
                    >
                      {intl.formatMessage({
                        id: "auth.register.step3.btnPhoto",
                      })}
                    </Button>
                    <Button
                      color="gradientaAccept"
                      className="button-filter"
                      width="120px"
                      onClick={() => changeUploadFile("font")}
                    >
                      {intl.formatMessage({
                        id: "auth.register.step3.dowloadPhoto",
                      })}
                    </Button>
                  </WrapButtonCam>
                </DivImage>
              </div>
              <div className="w-50">
                <WrapLabel>
                  <FormattedMessage id="auth.register.step3.labelBackSide" />
                </WrapLabel>
                <DivImage
                  url={form?.imageUrlBack}
                  onMouseEnter={() => sethoverImageBack(false)}
                  onMouseLeave={() => sethoverImageBack(true)}
                >
                  <WrapButtonCam
                    isNone={form?.imageUrlBack && hoverImageBack ? true : false}
                  >
                    <Button
                      color="gradientaAccept"
                      className="button-filter mb-2"
                      width="120px"
                      onClick={() =>
                        setModalCam({ status: true, type: "back" })
                      }
                    >
                      {intl.formatMessage({
                        id: "auth.register.step3.btnPhoto",
                      })}
                    </Button>
                    <Button
                      color="gradientaAccept"
                      className="button-filter"
                      width="120px"
                      onClick={() => changeUploadFile("back")}
                    >
                      {intl.formatMessage({
                        id: "auth.register.step3.dowloadPhoto",
                      })}
                    </Button>
                  </WrapButtonCam>
                </DivImage>
              </div>
            </DetailInput>
          </DetailRow>
        </Wrap>

        <div className="d-flex justify-content-end">
          <Button
            color="accept"
            className="button-filter mr-2"
            width="120px"
            onClick={() => dispatch(updateStep(STEPS.STEPS_2))}
            loading={loading}
          >
            {intl.formatMessage({
              id: "auth.register.btnPrev",
            })}
          </Button>
          <Button
            color="gradientaAccept"
            className="button-filter"
            width="120px"
            onClick={() => uploadfile()}
            loading={loading}
          >
            {intl.formatMessage({
              id: "auth.register.btnNext",
            })}
          </Button>
        </div>
      </Container>
      <Modal
        show={modalCam.status}
        centered
        backdrop
        keyboard={false}
        dialogClassName="modal-704"
      >
        <ModalCamera
          onClose={() => setModalCam({ status: false, type: "" })}
          handleImageCam={handleImageCam}
          type={modalCam?.type}
        />
      </Modal>
    </>
  );
};

export default Step3;
