import {
  findMarginContractDebitWatchApi,
  getMrgDebitInfo2Api,
  updateMrgContractPaymentApi,
  getListMarginAttrApi,
  getMarginDebitExtendFeeInfoApi,
  marginDebitExtendDaysApi,
  getListDebitPayExApi,
} from "./urls";
import {
  IFindMarginContractDebitWatchResponse,
  IGetMrgDebitInfo2Response,
  IUpdateMrgContractPaymentResponse,
  IFindListMarginAttrRequestResponse,
  IGetMarginDebitExtendFeeInfoResponse,
  IMarginDebitExtendDaysResponse,
  IGetListDebitPayExResponse,
} from "domain/models/ContractDebit";

import {
  IFindMarginContractDebitWatchParams,
  IGetMrgDebitInfo2Params,
  IUpdateMrgContractPaymentParams,
  IFindListMarginAttrRequestParams,
  IGetMarginDebitExtendFeeInfoParams,
  IMarginDebitExtendDaysParams,
  IGetListDebitPayExParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const findMarginContractDebitWatch = async (
  params: IFindMarginContractDebitWatchParams
): Promise<IFindMarginContractDebitWatchResponse> => {
  const res = await VgaiaHttp.get<IFindMarginContractDebitWatchResponse>(
    findMarginContractDebitWatchApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListDebitPayEx = async (
  params: IGetListDebitPayExParams
): Promise<IGetListDebitPayExResponse> => {
  const res = await VgaiaHttp.get<IGetListDebitPayExResponse>(
    getListDebitPayExApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getMrgDebitInfo2 = async (
  params: IGetMrgDebitInfo2Params
): Promise<IGetMrgDebitInfo2Response> => {
  const res = await VgaiaHttp.get<IGetMrgDebitInfo2Response>(
    getMrgDebitInfo2Api(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getMarginDebitExtendFeeInfo = async (
  params: IGetMarginDebitExtendFeeInfoParams
): Promise<IGetMarginDebitExtendFeeInfoResponse> => {
  const res = await VgaiaHttp.get<IGetMarginDebitExtendFeeInfoResponse>(
    getMarginDebitExtendFeeInfoApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getListMarginAttr = async (
  params: IFindListMarginAttrRequestParams
): Promise<IFindListMarginAttrRequestResponse> => {
  const res = await VgaiaHttp.get<IFindListMarginAttrRequestResponse>(
    getListMarginAttrApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const updateMrgContractPayment = async (
  params: IUpdateMrgContractPaymentParams
): Promise<IUpdateMrgContractPaymentResponse> => {
  const res = await VgaiaHttp.post<IUpdateMrgContractPaymentResponse>(
    updateMrgContractPaymentApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const marginDebitExtendDays = async (
  params: IMarginDebitExtendDaysParams
): Promise<IMarginDebitExtendDaysResponse> => {
  const res = await VgaiaHttp.post<IMarginDebitExtendDaysResponse>(
    marginDebitExtendDaysApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
