import { getColor } from "components/commons/WidgetTable/styles";
import Tooltip from "react-bootstrap/Tooltip";
import styled from "styled-components/macro";

export const TableWrapper = styled.div`
  height: 100%;
  position: relative;

  thead > tr > th {
    z-index: 0;
    position: sticky;
    top: 0;
  }
`;

export const HeaderRowWrapper = styled.div`
  display: flex;
`;

export const BodyRowWrapper = styled.div<{
  bgColor: number;
}>`
  display: flex;

  & > div {
    box-shadow: 0px -1px 0px inset ${({ theme }) => theme.border1};
    background: ${({ theme, bgColor }) =>
      bgColor % 2 === 0 ? theme.background1 : theme.background1};
  }

  &:hover div {
    background-color: ${({ theme }) => theme.grey90};
  }
`;

export const TableHeaderWrapper = styled.div`
  overflow-x: hidden;
`;

export const HeaderCell = styled.div<{
  align?: string;
  setMinWidth?: number;
  maxWidthCell?: number;
  flexCell?: number;
  canSort?: boolean;
}>`
  user-select: none;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  white-space: nowrap;
  justify-content: ${({ align }) => align};
  background-color: ${({ theme }) => theme.background3};
  div {
    font-size: ${({ theme }) => theme.typeFaces.caption10.size};
    line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.semiBold};
    color: ${({ theme }) => theme.grey30};
  }
  ${({ setMinWidth }) => setMinWidth && `min-width: ${setMinWidth}px`};
  ${({ maxWidthCell }) => maxWidthCell && `max-width: ${maxWidthCell}px`};
  ${({ flexCell }) => flexCell && `flex: ${flexCell};`}
  ${({ canSort }) => canSort && `cursor: pointer;`}

  & > div {
    padding: 1px 4px 0px;
    display: flex;
    align-items: center;
  }

  & span {
    text-transform: uppercase;
  }

  &.ticker-cell {
    position: sticky;
    left: 0;
  }
`;

export const TableBodyWrapper = styled.div`
  height: calc(100% - 25px);

  @keyframes blink-up {
    0% {
      background: ${({ theme }) => theme.grey50};
      color: ${({ theme }) => theme.textColorBlack};
    }
    90% {
      background: ${({ theme }) => theme.grey50};
      color: ${({ theme }) => theme.textColorBlack};
    }
    100% {
      background: none;
    }
  }

  @keyframes blink-down {
    0% {
      background: ${({ theme }) => theme.grey50};
      color: ${({ theme }) => theme.textColorWhite};
    }
    90% {
      background: ${({ theme }) => theme.grey50};
      color: ${({ theme }) => theme.textColorWhite};
    }
    100% {
      background: none;
    }
  }

  .cell-blink-up {
    animation-name: blink-up;
    -webkit-animation-name: blink-up;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  .cell-blink-down {
    animation-name: blink-down;
    -webkit-animation-name: blink-down;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
}>`
  flex: 1;
  width: 100%;
  height: 52px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  & > div {
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  }
`;

export const TableCell = styled.div<{
  widthCell?: number;
  maxWidthCell?: number;
  flexCell?: number;
}>`
  flex: 1;
  display: flex;
  ${({ widthCell }) => widthCell && `min-width: ${widthCell}px;`}
  ${({ maxWidthCell }) => maxWidthCell && `max-width: ${maxWidthCell}px;`}
  ${({ flexCell }) => flexCell && `flex: ${flexCell};`}

  &.ticker-cell {
    position: sticky;
    left: 0;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;

      & .ticker-text,
      & .company-name-text {
        padding-top: 0;
        padding-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        user-select: none;
      }

      & .company-name-text {
        color: ${({ theme }) => theme.grey30};
        font-size: ${({ theme }) => theme.typeFaces.caption11.size};
      }
    }
  }
`;

export const TableCellWrapper = styled.div<{
  align?: string;
  textcolor?: number;
}>`
  flex: 1;
  height: 52px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  color: ${({ theme }) => theme.textcolorWhite};
  div {
    padding: 4px;
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    ${({ textcolor, theme }) => getColor(textcolor, theme)};

    &.ticker-text {
      position: relative;
      cursor: pointer;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(37, 49, 71, 0.8);
    border-radius: 4px;
    padding: 8px 12px;
    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;
