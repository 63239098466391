import styled from "styled-components/macro";

export const TickerInfoWrapper = styled.div`
  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }

  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
`;

export const VolumnTextColor = styled.div<{ backgroundColor: string }>`
  color: ${({ theme }) => theme.textcolorWhite};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  background-color: ${(props) =>
    props.backgroundColor ? props.theme[props.backgroundColor] : "transparent"};
`;

export const TextColor = styled.div<{
  size: string;
}>`
  font-weight: ${(props) =>
    props.size === "big"
      ? props.theme.typeFaces.header.weight.semiBold
      : props.theme.typeFaces.caption12.weight.regular};
  font-size: ${(props) =>
    props.size === "big"
      ? props.theme.typeFaces.header.size
      : props.theme.typeFaces.caption12.size};
  line-height: ${(props) =>
    props.size === "big"
      ? props.theme.typeFaces.header.lineHeight
      : props.theme.typeFaces.caption12.lineHeight};
  max-width: ${(props) => (props.size === "big" ? "" : "40px")};
`;

export const Container = styled.div`
  overflow: auto;
  margin: 0 auto;
  min-width: 330px;

  .code {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }

  .price {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }

  .tran {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }

  .gia-tran {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }

  .violet {
    color: ${({ theme }) => theme.violet50};
  }

  .blue {
    color: ${({ theme }) => theme.blue50};
  }

  .yellow {
    color: ${({ theme }) => theme.yellow50};
  }

  .ticker-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 12px;
  }

  .title-info {
    text-align: right;
  }

  .info-text {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    color: ${({ theme }) => theme.grey60};
    text-transform: uppercase;
  }
  .info-value {
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .price {
    margin: 10px 0;
    text-transform: uppercase;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  }

  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    span {
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ticker-search {
    background: ${({ theme }) => theme.background2};
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 8;
    margin: 4;
    max-height: 450;
    overflow: auto;
    padding: 8;
    padding-left: 8;
    padding-right: 8;
  }

  .input-wrapper {
    position: relative;
    z-index: 4;
    .input-container {
      height: 32px;
      border-radius: 4px;
    }
  }
`;

export const InputLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.background1};
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.bgElevated4};
  height: 32px;
`;

export const BellContainer = styled.div`
  cursor: pointer;
`;

export const StarContainer = styled.div`
  margin-left: 14px;
`;

export const HeaderSearch = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.background1};
`;

export const SearchTicker = styled.div`
  position: relative;
  z-index: 4;
  .pl-3 {
    background: ${({ theme }) => theme.grey100};
  }
  .flex-fill {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;
  }
`;

export const TextSearch = styled.div`
  background: ${({ theme }) => theme.background3};
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: 4px;
  max-height: 450px;
  overflow: auto;
`;

export const TextItem = styled.div`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner3};
  div {
    .share-code {
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }
  :hover {
    background: ${({ theme }) => theme.greyG6};
  }
`;
