import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, Input, Spacer } from "components/commons";
import { storageOTPCodeSelector } from "modules/auth/selectors";
import { memo, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import ResendCode from "./ResendCode";
import {
  ButtonExit,
  CardTable,
  ErrorVerify,
  GetCodeCell,
  HeaderTitle,
  Icon,
  InputCell,
  InputOTPContainer,
  InputWrapper,
  InvisibleText,
  LabelInput,
  LabelWrapper,
  ModalHeader,
  WrapperButton,
} from "./styles";

interface Props {
  handleModal: any;
  changeMasterAccount: any;
  otpEverryTime?: boolean; // otpEverryTime là check mỗi lần mở ra, mặc định = true
  submitOTP?: any;
  errorOTP?: string;
  setErrorOtp?: any;
  handleCreateSmsOTP?: any;
}

const radius4 = 4;

function SmsOTPNewCreate({
  handleModal,
  changeMasterAccount,
  otpEverryTime = true,
  handleCreateSmsOTP,
  submitOTP,
  errorOTP,
  setErrorOtp,
}: Props) {
  const storageOTPCode = useSelector(storageOTPCodeSelector);
  const intl = useIntl();
  const [codeOTP, setCodeOTP] = useState("");
  const inputEl = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCodeOTP("");
    setLoading(false);
  }, []);

  const handelVerifySmsOtp = (scanId?: string) => {
    setErrorOtp("");
    submitOTP(codeOTP);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && loading === false) {
      setLoading(true);
      handelVerifySmsOtp();
    }
  };

  useEffect(() => {
    // Gọi API create Sms OTP
    handleCreateSmsOTP?.();
  }, []);

  // Auto focus input
  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, []);

  return (
    <>
      {(storageOTPCode?.code === "" || otpEverryTime === true) && (
        <Modal
          show={true}
          centered
          // backdrop={true}
          keyboard={false}
          dialogClassName="modal-434"
        >
          <ModalHeader>
            <HeaderTitle>
              <FormattedMessage id="auth.info.smsOTP.title" />
            </HeaderTitle>
            <WrapperButton>
              <ButtonExit onClick={handleModal}>
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            </WrapperButton>
          </ModalHeader>

          <CardTable>
            <LabelWrapper>
              <LabelInput>
                <FormattedMessage id="auth.info.smsOTP.enterOTP" />
              </LabelInput>
              <GetCodeCell>
                <ResendCode handleCreateSmsOTP={handleCreateSmsOTP} />
              </GetCodeCell>
            </LabelWrapper>

            <InputOTPContainer>
              <InputCell>
                <InputWrapper>
                  <Input
                    maxLength={6}
                    placeholder={intl.formatMessage({
                      id: "auth.info.smsOTP.enterOTP",
                    })}
                    value={codeOTP}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => {
                      setCodeOTP(e.target.value);
                      setErrorOtp("");
                    }}
                    innerRef={inputEl}
                  />
                </InputWrapper>
              </InputCell>
            </InputOTPContainer>

            <ErrorVerify>
              {errorOTP !== "" ? (
                intl.formatMessage({
                  id: errorOTP || "error.defaultErr",
                })
              ) : (
                <InvisibleText>.</InvisibleText>
              )}
            </ErrorVerify>

            <div className="footer">
              <div style={{ flex: 7 }}></div>
              <div style={{ flex: 9, display: "flex", flexDirection: "row" }}>
                <Button
                  size="l"
                  style={{ flex: 1 }}
                  color="accept"
                  className="flex-1"
                  radius={radius4}
                  onClick={handleModal}
                >
                  <FormattedMessage id="auth.info.smartOTP.cancel" />
                </Button>
                <Spacer size="m" />
                <Button
                  disabled={codeOTP.length < 6}
                  size="l"
                  fill={true}
                  style={{ flex: 1 }}
                  loading={loading}
                  onClick={handelVerifySmsOtp}
                  className="flex-1"
                  radius={radius4}
                  color="gradientaAccept"
                >
                  <FormattedMessage id="auth.info.editInfo.update" />
                </Button>
              </div>
            </div>
          </CardTable>
        </Modal>
      )}
    </>
  );
}
export default memo(SmsOTPNewCreate);
