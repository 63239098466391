import { BlinkTime } from "AppConfig";
import { stringColorTicker } from "helper/utils";
import {
  baseseccdPriceColorInfoSelector,
  baseseccdPriceInfoSelector,
} from "modules/categories/redux/selectors";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TextColor } from "./styles";

interface Props {
  ticker: string;
}

function BasepriceCell({ ticker }: Props) {
  const [background, setBackground] = useState("");
  const color = useSelector(baseseccdPriceColorInfoSelector(ticker));
  const baseprice = useSelector(baseseccdPriceInfoSelector(ticker));
  const oldData = useRef<number | undefined>(0);

  useEffect(() => {
    if (oldData.current === baseprice) return;
    if (!oldData.current) {
      oldData.current = baseprice;
      return;
    }
    oldData.current = baseprice;
    setBackground("grey50");
    const resetStatus = setTimeout(() => setBackground(""), BlinkTime);
    return () => {
      setBackground("");
      clearTimeout(resetStatus);
    };
  }, [baseprice]);

  return (
    <TextColor
      className="text-right"
      color={stringColorTicker(color)}
      background={background}
    >
      {numeral(baseprice).format("0.00")}
    </TextColor>
  );
}

export default BasepriceCell;
