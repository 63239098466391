import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, OTPByBusinessCd, Spacer } from "components/commons";
import { toast } from "components/commons/Toast";
import {
  ICreateDertRequestCondParams,
  IPlaceOrdersDerInputParams,
  IPlaceOrdersDerParams,
} from "core/http/apis/orderexec/types";
import { EBusinessCd, SrcChannel } from "helper/consts";
// import { checkPlaceOrderDer, getOrderType } from "helper/tradingHelper";
import {
  createDertRequestCond,
  DerTradeTypeEnum,
  onChangeVolumeByEnterDer,
  onLoadingPlaceOrder,
  postSingleOrderDerClear,
  requestCreateOrderDer,
  updatePlaceOrderDerConditionalCommandType,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import numeral from "numeral";
import React, { FC, memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getCashPlaceOrderDerPrice,
  getConditionTradeTypes,
  getOrdType,
} from "..";
import { ConditionalCommandTypeEnum } from "../ConditionalCommandType";
import {
  ButtonExit,
  Icon,
  PopConfirmOrderBody,
  PopConfirmOrderContainer,
  PopConfirmOrderTitle,
  TextColor,
  VisibleModalConfirm,
  Content,
  Label,
  Value,
} from "./styles";
import { getValueRound } from "helper/utils";
import {
  placeOrderDerSelector,
  dataSelector,
} from "modules/stock-order-der/selectors";

import { ReactComponent as IconCheckBoxSelected } from "assets/image/svg/ic_checkbox_selected.svg";
import { ReactComponent as IconCheckBoxUnselected } from "assets/image/svg/ic_checkbox_unselected.svg";
import { setListKeyShort } from "themes/redux";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { tradeDateSelector } from "modules/auth/selectors";
interface Props {
  visible: boolean;
  closeModalInfo: () => void;
  handleSubmitsuccess: () => void;
  setVisibleSubmitOrder: (value: boolean) => void;
  visibleModalOTP: boolean;
  setVisibleModalOTP: (value: boolean) => void;
}

const ModalSubmitOrder: FC<Props> = memo(
  ({
    visible,
    closeModalInfo,
    handleSubmitsuccess,
    setVisibleSubmitOrder,
    visibleModalOTP,
    setVisibleModalOTP,
  }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const conditionTradeTypes = getConditionTradeTypes(intl);
    const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);

    const {
      quotesForDertOrder,
      // tickerInfoLastSale,
      changeVolumeByEnterDer,
      loadingPlaceOrderDer,
      singleOrderStatus,
      errorSingleOrderDer,
      derCustomer,
      dertTradingGetFeeRate,
      multipleNum,
      validateDert,
    } = useSelector(placeOrderDerSelector);
    const {
      currentTicker,
      account,
      type,
      price,
      volume,
      conditionalCommandType,
      priceUp,
      priceDown,
      cutLostAmp,
      grossAmp,
      cutLostPrice,
      limitOffset,
      rangeStop,
    } = useSelector(dataSelector);
    const tradeDate = useSelector(tradeDateSelector);

    const handleModal = () => {
      dispatch(onLoadingPlaceOrder(false));
      setVisibleModalOTP(false);
      setVisibleSubmitOrder(true);
      setLoading(false);
    };

    const doSubmitPlaceOrder = async (response: IVerifyResponse) => {
      setVisibleModalOTP(false);
      if (!account.custNo) {
        setLoading(false);
        return;
      }

      if (conditionalCommandType?.value === ConditionalCommandTypeEnum.Normal) {
        const paramsOrder: IPlaceOrdersDerInputParams = {
          reqType: 1,
          secCd: currentTicker,
          boardCd: "500",
          custCd: derCustomer.custCd,
          custNo: derCustomer.custNo,
          ordQty: Number(volume?.toString().replaceAll(",", "")),
          ordPrice: getCashPlaceOrderDerPrice(
            getValueRound(price),
            type,
            quotesForDertOrder?.ceilingPrice!,
            quotesForDertOrder?.floorPrice!
          ),
          ordChanel: SrcChannel.WEB,
          tradeType: type === DerTradeTypeEnum.L ? 2 : 1,
          tradeDate: Number(tradeDate),
          feeRate: dertTradingGetFeeRate,
          feeAmt: Number(volume) * dertTradingGetFeeRate,
          ordAmt: Number(volume) * dertTradingGetFeeRate * multipleNum,
          ordType: getOrdType(price),
        };

        // Múc
        try {
          const params: IPlaceOrdersDerParams = {
            data: JSON.stringify(paramsOrder),
            otpCode: response.otp,
            otpType: response?.otpTypeSubmit!,
          };
          dispatch(requestCreateOrderDer(params));
        } catch (error: any) {
          const errorMessage: string = intl.formatMessage({
            id: error.code,
            defaultMessage: error.description,
          });

          toast(errorMessage, "error");
        }
      } else {
        const paramsOrderCond: ICreateDertRequestCondParams = {
          custNo: derCustomer.custNo,
          secCd: currentTicker,
          tradeType: type === DerTradeTypeEnum.L ? 2 : 1,
          ordQty: Number(volume?.toString().replaceAll(",", "")),
          ordPrice:
            conditionalCommandType?.value ===
            ConditionalCommandTypeEnum.TRAILINGSTOP
              ? 0
              : getCashPlaceOrderDerPrice(
                  getValueRound(price),
                  type,
                  quotesForDertOrder?.ceilingPrice!,
                  quotesForDertOrder?.floorPrice!
                ),
          condType: conditionalCommandType?.value || "",
          priceStop: null,
          rangeStop:
            conditionalCommandType?.value ===
            ConditionalCommandTypeEnum.TRAILINGSTOP
              ? Number(rangeStop)
              : null,
          grossAmp:
            conditionalCommandType?.value ===
              ConditionalCommandTypeEnum.IFDONEGAIN ||
            conditionalCommandType?.value ===
              ConditionalCommandTypeEnum.BULLBEAR
              ? Number(grossAmp)
              : null,
          cutLostAmp:
            conditionalCommandType?.value ===
              ConditionalCommandTypeEnum.IFDONESTOP ||
            conditionalCommandType?.value ===
              ConditionalCommandTypeEnum.BULLBEAR
              ? Number(cutLostAmp)
              : null,
          cutLostPrice:
            conditionalCommandType?.value === ConditionalCommandTypeEnum.OCO
              ? Number(cutLostPrice)
              : null,
          limitOffset: !(
            conditionalCommandType?.value === ConditionalCommandTypeEnum.UP ||
            conditionalCommandType?.value === ConditionalCommandTypeEnum.DOWN
          )
            ? Number(limitOffset)
            : null,
          validDate: validateDert,
          remarks: "",
          notes: "",
          priceUp:
            conditionalCommandType?.value === ConditionalCommandTypeEnum.UP
              ? Number(priceUp)
              : null,
          priceDown:
            conditionalCommandType?.value === ConditionalCommandTypeEnum.DOWN
              ? Number(priceDown)
              : null,
          ordChanel: SrcChannel.WEB.toString(),
          clientInfo: null,
          otpType: response?.otpTypeSubmit!,
        };

        dispatch(createDertRequestCond(paramsOrderCond));
      }
    };

    useEffect(() => {
      if (singleOrderStatus?.status === "success") {
        toast("widgets.placeOrderDer.orderSuccess", "success");
        setLoading(false);
        dispatch(postSingleOrderDerClear());
        handleSubmitsuccess();
        dispatch(
          updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0])
        );
        dispatch(onLoadingPlaceOrder(false));
        closeModalInfo();
        dispatch(
          setListKeyShort({ visiblePopupConfirm: !visibleModalConfirm })
        );
      }

      if (singleOrderStatus?.status === "failed") {
        const codeError = errorSingleOrderDer?.code;
        if (codeError) {
          const msg = `${errorSingleOrderDer?.code
            ?.toString()
            .replaceAll("-", "_")}`;
          const msgFinal = msg
            ? "error." + msg
            : "widgets.placeOrderDer.orderError";
          toast(msgFinal, "error");
        } else {
          toast("error.defaultErr", "error");
        }
        setLoading(false);
        dispatch(postSingleOrderDerClear());
        dispatch(
          updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0])
        );
        dispatch(onLoadingPlaceOrder(false));
        closeModalInfo();
        // dispatch(
        //   setListKeyShort({ visiblePopupConfirm: !visibleModalConfirm })
        // );
      }
    }, [singleOrderStatus]);

    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        setVisibleSubmitOrder(!visible);
        setVisibleModalOTP(true);
        dispatch(onChangeVolumeByEnterDer(false));
      }
    };

    const onSubmit = () => {
      setLoading(true);
      if (!changeVolumeByEnterDer) {
        setVisibleSubmitOrder(!visible);
        setVisibleModalOTP(true);
      }
    };

    const render = (conditionalCommandType: any) => {
      switch (conditionalCommandType) {
        case ConditionalCommandTypeEnum.UP:
        case ConditionalCommandTypeEnum.DOWN:
          return (
            <div className="d-flex justify-content-between py-2">
              <Label>
                <FormattedMessage id="widgets.placeOrderDer.activePrice" />
              </Label>
              <Value>
                {numeral(
                  conditionalCommandType === ConditionalCommandTypeEnum.UP
                    ? priceUp
                    : priceDown
                ).format("0,0.0")}
              </Value>
            </div>
          );
        case ConditionalCommandTypeEnum.IFDONESTOP:
        case ConditionalCommandTypeEnum.IFDONEGAIN:
          return (
            <>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  {conditionalCommandType ===
                  ConditionalCommandTypeEnum.IFDONESTOP ? (
                    <FormattedMessage id="widgets.placeOrderDer.cutLostAmp" />
                  ) : (
                    <FormattedMessage id="widgets.placeOrderDer.grossAmp" />
                  )}
                </Label>
                <Value>
                  {numeral(
                    conditionalCommandType ===
                      ConditionalCommandTypeEnum.IFDONESTOP
                      ? cutLostAmp
                      : grossAmp
                  ).format("0,0.0")}
                </Value>
              </div>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.limitOffset" />
                </Label>
                <Value>{numeral(limitOffset).format("0,0.0")}</Value>
              </div>
            </>
          );
        case ConditionalCommandTypeEnum.BULLBEAR:
          return (
            <>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.grossAmp" />
                </Label>
                <Value>{numeral(grossAmp).format("0,0.0")}</Value>
              </div>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.cutLostAmp" />
                </Label>
                <Value>{numeral(cutLostAmp).format("0,0.0")}</Value>
              </div>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.limitOffset" />
                </Label>
                <Value>{numeral(limitOffset).format("0,0.0")}</Value>
              </div>
            </>
          );
        case ConditionalCommandTypeEnum.OCO:
          return (
            <>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.cutLostPrice" />
                </Label>
                <Value>{numeral(cutLostPrice).format("0,0.0")}</Value>
              </div>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.limitOffset" />
                </Label>
                <Value>{numeral(limitOffset).format("0,0.0")}</Value>
              </div>
            </>
          );
        case ConditionalCommandTypeEnum.TRAILINGSTOP:
          return (
            <>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.rangeStop" />
                </Label>
                <Value>{numeral(rangeStop).format("0,0.0")}</Value>
              </div>
              <div className="d-flex justify-content-between py-2">
                <Label>
                  <FormattedMessage id="widgets.placeOrderDer.limitOffset" />
                </Label>
                <Value>{numeral(limitOffset).format("0,0.0")}</Value>
              </div>
            </>
          );
        default:
          break;
      }
    };

    return (
      <>
        <Modal
          show={visible}
          centered
          keyboard={false}
          backdrop="static"
          dialogClassName="modal-434"
        >
          <PopConfirmOrderContainer>
            <PopConfirmOrderTitle
              color={type === DerTradeTypeEnum.L ? "green50" : "red50"}
            >
              <div className="flex-grow-1"></div>
              {type === DerTradeTypeEnum.L
                ? intl
                    .formatMessage({
                      id: "widgets.placeOrderDer.comfirmLongTitle",
                    })
                    .toUpperCase()
                : intl
                    .formatMessage({
                      id: "widgets.placeOrderDer.comfirmShortTitle",
                    })
                    .toUpperCase()}
              <ButtonExit
                color={type === DerTradeTypeEnum.L ? "green50" : "red50"}
                onClick={() => {
                  closeModalInfo();
                }}
              >
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            </PopConfirmOrderTitle>
            <PopConfirmOrderBody>
              <div className="order-info">
                <div className="d-flex justify-content-between py-2">
                  <Label>
                    <FormattedMessage id="widgets.placeOrder.accountNumber" />
                  </Label>
                  <Value>{account?.custNo}</Value>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <Label>
                    <FormattedMessage id="widgets.placeOrder.commandType" />
                  </Label>
                  <TextColor
                    color={type === DerTradeTypeEnum.L ? "green50" : "red50"}
                  >
                    {conditionalCommandType?.value ===
                    ConditionalCommandTypeEnum.Normal
                      ? intl.formatMessage({
                          id: "widgets.placeOrderDer.normal",
                        })
                      : conditionalCommandType?.label}
                  </TextColor>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <Label>
                    <FormattedMessage id="widgets.placeOrderDer.tickerCode" />
                  </Label>
                  <Value>{currentTicker}</Value>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <Label>
                    <FormattedMessage id="widgets.placeOrderDer.volume" />
                  </Label>
                  <Value>{numeral(volume).format("0,000")}</Value>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <Label>
                    <FormattedMessage id="widgets.placeOrderDer.price" />
                  </Label>
                  <Value>
                    {!isNaN(price) ? numeral(price).format("0,0.0") : price}
                  </Value>
                </div>
                {render(conditionalCommandType?.value)}
              </div>

              <VisibleModalConfirm>
                {visibleModalConfirm ? (
                  <IconCheckBoxSelected
                    onClick={() => setVisibleModalConfirm(false)}
                  />
                ) : (
                  <IconCheckBoxUnselected
                    onClick={() => setVisibleModalConfirm(true)}
                  />
                )}
                <Content>
                  <FormattedMessage id="widgets.placeOrder.visibleModalConfirm" />
                </Content>
              </VisibleModalConfirm>

              <div className="footer">
                <Button
                  className="flex-1"
                  color="accept"
                  onClick={() => {
                    closeModalInfo();
                  }}
                >
                  <FormattedMessage id="widgets.placeOrder.cancel" />
                </Button>
                <Spacer size="s" />
                <Button
                  className={
                    type === DerTradeTypeEnum.L ? "btn-success" : "btn-danger"
                  }
                  style={{ flex: 1 }}
                  onClick={() => onSubmit()}
                  onKeyDown={handleKeyDown}
                  focusButton={visible || !visibleModalOTP}
                  disabled={loading || loadingPlaceOrderDer}
                  loading={loading || loadingPlaceOrderDer}
                >
                  {type === DerTradeTypeEnum.L ? (
                    <FormattedMessage id="widgets.placeOrderDer.acceptLong" />
                  ) : (
                    <FormattedMessage id="widgets.placeOrderDer.acceptShort" />
                  )}
                </Button>
              </div>
            </PopConfirmOrderBody>
          </PopConfirmOrderContainer>
        </Modal>
        {visibleModalOTP && (
          <OTPByBusinessCd
            handleClose={handleModal}
            getResult={doSubmitPlaceOrder}
            businessCd={EBusinessCd.TRADING}
          />
        )}
      </>
    );
  }
);

export default ModalSubmitOrder;
