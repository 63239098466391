import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import { searchStatisticHighestUpdown } from "core/http/apis/statistic-highest-updown";
import { ISearchStatisticHighestUpdownParams } from "core/http/apis/statistic-highest-updown/types";
import { IMarketStatisticResponse } from "domain/models/MarketStatistic";
import {
  searchStatisticHighestUpdownFailure,
  searchStatisticHighestUpdownRequest,
  searchStatisticHighestUpdownSuccess,
} from "./redux";

function* searchStatisticHighestUpdownWorker(
  action: ReturnType<typeof searchStatisticHighestUpdownRequest>
) {
  try {
    const params: ISearchStatisticHighestUpdownParams = action.payload;
    const response: IMarketStatisticResponse = yield call(
      searchStatisticHighestUpdown,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchStatisticHighestUpdownSuccess(response));
    } else {
      yield put(searchStatisticHighestUpdownFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchStatisticHighestUpdownFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchStatisticHighestUpdownWatcher() {
  yield takeLatest(
    searchStatisticHighestUpdownRequest.type,
    searchStatisticHighestUpdownWorker
  );
}

export default function* statisticHighestUpdownSaga() {
  yield all([searchStatisticHighestUpdownWatcher()]);
}
