import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectPlaceOrder } from "components/commons";
import { changeCurrentAccount } from "modules/auth/redux";
import { onChangeTickerByEnter } from "modules/stock-order/redux";
import {
  isAuthenticatedSelector,
  currentAccountSelector,
} from "modules/auth/selectors";
import { useIntl } from "react-intl";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";

const AccountSelect: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentAccount = useSelector(currentAccountSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const accounts = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc, intl]);

  const onChange = (option: any) => {
    dispatch(onChangeTickerByEnter(true));
    if (
      !currentAccount?.subAccoNo ||
      option.value === currentAccount?.subAccoNo
    )
      return;
    const accountChange = listAcc?.find(
      (item) => item.subAccoNo === option.value
    );
    dispatch(changeCurrentAccount(accountChange!));
  };

  const accValue = () => {
    if (isAuthenticated && currentAccount?.subAccoNo) {
      return accounts.find(
        (item: any) => item.value === currentAccount?.subAccoNo
      );
    } else {
      return null;
    }
  };

  return (
    <SelectPlaceOrder
      className="flex-fill"
      value={accValue()}
      options={accounts}
      onChange={onChange}
    />
  );
});

export default AccountSelect;
