import styled from "styled-components/macro";

export const Container = styled.div`
  background: ${({ theme }) => theme.background1};
  padding: 16px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div<{
  hide?: boolean;
}>`
  margin-bottom: 16px;
  /* box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner3}; */
  padding-bottom: 12px;
  position: relative;
  display: ${({ hide }) => (hide ? "none" : "block")};
`;

export const Legend = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  height: 24px;
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

export const Label = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  flex: 1;
`;

export const InputWrapper = styled.div`
  width: 240px;
`;

export const PlusButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.background1};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  user-select: none;

  svg path {
    fill: #9da1a9;
  }
`;

export const ListGroupContainer = styled.div`
  min-width: 200px;
  max-height: 240px;
  overflow-y: auto;

  .list-group-item {
    background-color: ${({ theme }) => theme.grey90};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-color: ${({ theme }) => theme.grey100};
  }

  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 60px;
  }
`;

export const TagContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  margin: 8px 0;
`;

export const Tag = styled.div<{
  isActive?: boolean;
}>`
  height: 32px;
  background-color: ${({ theme }) => theme.grey80};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  border-radius: 4px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.brandPrimary : theme.grey20};
  border: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.brandPrimary : "transparent")};
  user-select: none;
`;

export const ActionsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 0 6px;
`;

export const ButtonWrapper = styled.div`
  flex: 1;

  .button-confirm {
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    height: ${({ theme }) => theme.button.height};
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Content = styled.div``;

export const HeaderModal = styled.div`
  background-color: ${({ theme }) => theme.background3};
  color: ${({ theme }) => theme.grey20};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 8px;
  max-width: 440px;
`;

export const BodyModal = styled.div`
  max-width: 440px;
  background-color: ${({ theme }) => theme.background2};
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const InputRowWrapper = styled.div`
  flex: 1;
`;

export const ErrorRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.red50};
`;

export const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 24px;
  gap: 6px;
`;

export const ModalLabel = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.textcolorWhite};
  padding-top: 30px;
  padding-left: 24px;
`;

export const ModalLabel2 = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.grey20};
  padding-top: 30px;
  padding-left: 24px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;
