import {
  BoardBox,
  BoardContainer,
  CreateBoardContainer,
  Tab,
  TabContainer,
  TitleContainer,
  ButtonExit,
} from "modules/market/pages/Market/components/CreateBoard/styles";
import { Icon } from "components/commons/Icon/styles";
import React, { FC, useState } from "react";
import { ReactComponent as IconPlus12 } from "assets/image/svg/ic_plus12.svg";
import { ReactComponent as ThumbStandard } from "assets/image/svg/thumb_standard.svg";
import { ReactComponent as ThumbStandard2 } from "assets/image/svg/thumb_standard2.svg";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit.svg";
import { TabInterface } from "../..";
import { useIntl } from "react-intl";
import Storage from "helper/storage";
import { parseDataToSaveStorage } from "helper/flexLayout";

const TICKER_DETAIL_BOARD_STORAGE_KEY = "ticker_detail_board";

interface BoardProps extends Partial<TabInterface> {
  onClick: () => void;
  customized?: boolean;
  customBoards?: TabInterface[];
  setCustomizedBoard: (value: TabInterface[]) => void;
}

const Board: React.FC<BoardProps> = React.memo(
  ({
    name,
    desc,
    onClick,
    image,
    customized,
    customBoards,
    setCustomizedBoard,
  }) => {
    const handleDeleteBoard = () => {
      const customBoardsNew = customBoards
        ? customBoards.filter((item) => item.name !== name)
        : [];
      Storage.set(
        TICKER_DETAIL_BOARD_STORAGE_KEY,
        JSON.stringify(parseDataToSaveStorage(customBoardsNew))
      );
      setCustomizedBoard(customBoardsNew);
    };
    return (
      <BoardBox>
        <div onClick={onClick} className="item-board-thumb">
          {image === "standard1" ? <ThumbStandard /> : null}
          {image === "standard2" ? <ThumbStandard2 /> : null}
          {image === "newBoard" ? <IconPlus12 /> : null}
        </div>
        <div className="content">
          <div className="title-content">
            <div className="item-board-title">{name}</div>
            {customized ? (
              <ButtonExit onClick={handleDeleteBoard}>
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            ) : (
              <div />
            )}
          </div>
          <div className="item-board-desc">{desc}</div>
        </div>
      </BoardBox>
    );
  }
);

interface Props {
  customBoards: TabInterface[];
  onCreateBoard: (type: string, custBoard: Partial<TabInterface>) => void;
  setCustomizedBoard: (value: TabInterface[]) => void;
}

const CreateBoard: FC<Props> = React.memo(
  ({ onCreateBoard, customBoards, setCustomizedBoard }) => {
    const intl = useIntl();
    const [tabActive, setTabActive] = useState("all");
    const onClick = (type: string, custBoard: Partial<TabInterface>) => () => {
      onCreateBoard(type, custBoard);
    };

    const renderCustomBoards = () => {
      if (!customBoards || !customBoards.length) return;
      return customBoards.map((item) => (
        <Board
          {...item}
          key={item.id}
          onClick={onClick("customized", item)}
          name={item.name}
          desc={item?.desc}
          customized
          customBoards={customBoards}
          setCustomizedBoard={setCustomizedBoard}
        />
      ));
    };

    const handleChangeTab = (value: string) => {
      setTabActive(value);
    };

    return (
      <CreateBoardContainer className="container-fluid">
        <TitleContainer>
          {intl.formatMessage({ id: "stockOrderDerBoard.chooseBoard" })}
        </TitleContainer>
        <TabContainer>
          <Tab
            isActive={tabActive === "all"}
            onClick={() => handleChangeTab("all")}
          >
            {intl.formatMessage({ id: "stockOrderDerBoard.all" })}
          </Tab>
          <Tab
            isActive={tabActive === "default"}
            onClick={() => handleChangeTab("default")}
          >
            {intl.formatMessage({ id: "stockOrderDerBoard.default" })}
          </Tab>
          <Tab
            isActive={tabActive === "customized"}
            onClick={() => handleChangeTab("customized")}
          >
            {intl.formatMessage({ id: "stockOrderDerBoard.customized" })}
          </Tab>
        </TabContainer>
        <BoardContainer>
          {tabActive === "all" || tabActive === "default" ? (
            <>
              <Board
                onClick={onClick("blank", {
                  name: intl.formatMessage({ id: "stockOrderDerBoard.blank" }),
                })}
                name={intl.formatMessage({ id: "stockOrderDerBoard.blank" })}
                desc={intl.formatMessage({
                  id: "stockOrderDerBoard.addingWidggets",
                })}
                image="newBoard"
                setCustomizedBoard={setCustomizedBoard}
              />
              <Board
                onClick={onClick(
                  window.innerWidth < 1400 ? "tickerDetail" : "tickerDetail",
                  {
                    name: intl.formatMessage({
                      id: "stockOrderDerBoard.normal",
                    }),
                  }
                )}
                name={intl.formatMessage({ id: "stockOrderDerBoard.normal" })}
                desc={intl.formatMessage({
                  id: "stockOrderDerBoard.DefaultStock",
                })}
                image="standard1"
                setCustomizedBoard={setCustomizedBoard}
              />
            </>
          ) : null}
          {tabActive === "all" || tabActive === "customized"
            ? renderCustomBoards()
            : null}
        </BoardContainer>
      </CreateBoardContainer>
    );
  }
);

export default CreateBoard;
