import { useMemo, useEffect } from "react";
import { FC } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Spacer } from "components/commons";
import numeral from "numeral";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  OrderBookWidgetState,
  findOrderDetail,
} from "modules/order-book/redux/index";
import {
  Container,
  Title,
  ButtonExit,
  TitleContent,
  RejectReason,
} from "./styles";
import { TextTradeType } from "../style";
import { FormattedMessage, useIntl } from "react-intl";
import CommonTable from "components/commons/CommonTable";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import moment from "moment";
import { IFindOrderDetailParams } from "core/http/apis/tradeinfo/types";
interface Props {
  showModal: boolean;
  setShowModal: any;
  dataRow?: any;
}

/**
 * Create Selector
 */
const orderBookSelector = (state: RootState) => state.orderBookWidget.root;

const orderBookDetailListSelector = createSelector(
  orderBookSelector,
  (orderState: OrderBookWidgetState) => orderState.orderBookDetailList
);

/**
 * Modal
 */
const ModalDetail: FC<Props> = ({ showModal, setShowModal, dataRow }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const detailList = useSelector(orderBookDetailListSelector);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <TableHeaderCellContent align={"center"}>
      //       <FormattedMessage id="widgets.orderBookDetail.table.rowNum" />
      //     </TableHeaderCellContent>
      //   ),
      //   accessor: "rowNum",
      //   minWidth: 120,
      //   Cell: (props: any) => {
      //     return (
      //       <TableCellContent align={"center"}>{props?.value}</TableCellContent>
      //     );
      //   },
      // },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 120,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props?.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "ordPrice",
        minWidth: 120,
        Cell: (props: any) => {
          const formatValue = numeral(props?.value || 0).format("0,0.00");
          return (
            <TableCellContent align={"flex-end"}>
              {formatValue}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="widgets.orderBookDetail.table.confirmTime" />
          </TableHeaderCellContent>
        ),
        accessor: "confirmTime",
        minWidth: 180,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props?.value ? moment(props?.value).format("HH:mm:ss") : "---"}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const onLoadData = () => {
    try {
      if (dataRow?.original?.orderNo) {
        const params: IFindOrderDetailParams = {
          orgOrderNo: dataRow?.original?.orgOrderNo,
          orderNo: dataRow?.original?.orderNo,
          marketCd: dataRow?.original?.marketCd,
          tradeDate: Number(dataRow?.original?.tradeDate),
        };

        dispatch(findOrderDetail(params));
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    onLoadData();
  }, [dataRow]);

  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        dialogClassName="modal-502"
      >
        <Container>
          <Row>
            <Col xs={12}>
              <Title>
                <div className="flex-1"></div>
                <TitleContent side={dataRow?.original?.tradeTypeDisplay}>
                  {intl
                    .formatMessage({ id: "widgets.orderBookDetail.title" })
                    .toUpperCase()}{" "}
                  {dataRow?.original?.orderNo}
                </TitleContent>
                <ButtonExit>
                  <IconExit onClick={() => setShowModal(false)} />
                </ButtonExit>
              </Title>
            </Col>
          </Row>
          <Spacer size="m" />
          <div className="px-3">
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "widgets.orderBookDetail.subAccountNo",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.subAccoNo}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.status" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.orderStatusDisplay
                  ? intl.formatMessage({
                      id: dataRow?.original?.orderStatusDisplay,
                    })
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "widgets.orderBookDetail.tradeType",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                <TextTradeType
                  side={dataRow?.original?.tradeTypeDisplay}
                  className={dataRow?.original?.tradeTypeDisplay}
                >
                  {dataRow?.original?.tradeTypeDisplay &&
                    intl.formatMessage({
                      id: dataRow?.original?.tradeTypeDisplay,
                    })}
                </TextTradeType>
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.symbol" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.secCd}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.orderQty" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.ordQty
                  ? numeral(dataRow?.original?.ordQty).format("0,0")
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.price" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.orderPriceDisp}
              </Col>
            </Row>
            <Spacer size="s" />

            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.cumQty" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.matQty
                  ? numeral(dataRow?.original?.matQty).format("0,0")
                  : 0}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "widgets.orderBookDetail.matchAvgPrice",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {(dataRow?.original?.matAmt || 0) > 0
                  ? numeral(dataRow?.original?.matAmt).format("0,0")
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.pubQty" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.pubQty
                  ? numeral(dataRow?.original?.pubQty).format("0,0")
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.pubPrice" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.pubPrice}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.orderNo" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.orderNo}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.channel" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.channelDisplay}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "widgets.orderBookDetail.tradingDate",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {dataRow?.original?.createdTimeDisp
                  ? dataRow?.original?.createdTimeDisp
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            {dataRow?.original?.notes && (
              <Row>
                <Col xs={4}>
                  {intl.formatMessage({
                    id: "widgets.orderBookDetail.rejectReason",
                  })}
                </Col>
                <Col xs={8} className="text-right text-color-light">
                  <RejectReason>
                    {dataRow?.original?.notes ? (
                      <FormattedMessage
                        id={"error." + dataRow?.original?.notes.split(":")[0]}
                      />
                    ) : (
                      "---"
                    )}
                  </RejectReason>
                </Col>
              </Row>
            )}
          </div>
          <Spacer size="l" />
          <CommonTable columns={columns} data={detailList} />
        </Container>
      </Modal>
    </>
  );
};

export default ModalDetail;
