import styled from "styled-components/macro";

export const HeaderSection = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  border-bottom: 2px solid ${({ theme }) => theme.lineGreyOn};
  height: 32px;
  padding: 6px 16px;
  background-color: ${({ theme }) => theme.background2};
`;

export const PriceListWrapper = styled.div`
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
  scrollbar-width: thin;
  padding: 0 16px 16px 16px;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  .list-type {
    color: ${({ theme }) => theme.textcolorWhite};
  }

  .progress-container {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }
`;

export const NameTicker = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-end;
  gap: 4px;
  .seccd {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .marketcd {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    color: ${({ theme }) => theme.grey30};
  }
`;
