import { SizeType } from "../Spacer";
import styled from "styled-components/macro";

const Size: { [key in SizeType]?: number } = {
  l: 32, //size mới là 36 chứ
  m: 32,
  xl: 40,
};

const ButtonColorType: { [key in ButtonType]: string } = {
  danger: "red50",
  success: "green50",
  warning: "yellow50",
  accept: "brandPrimary",
  ghost: "primaryP5",
  gradientaAccept: "grey0",
  gray: "grey20",
};

const ThemeBackground: { [key in ButtonType]: string } = {
  danger: "grey100",
  success: "grey90",
  warning: "primary2",
  accept: "grey100",
  ghost: "grey90",
  gradientaAccept: "buttonGradientColor",
  gray: "grey70",
};

export type ButtonType =
  | "success"
  | "danger"
  | "warning"
  | "accept"
  | "ghost"
  | "gradientaAccept"
  | "gray";

export const Button = styled.button<{
  disabled?: boolean;
  isLoading?: boolean;
  isFill?: boolean;
  size?: SizeType;
  color?: ButtonType;
  radius?: number;
  width?: string;
}>`
  box-sizing: border-box;
  border-radius: ${({ radius }) => (radius ? radius : 4)}px;
  height: ${({ size = "l" }) => Size[size]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;

  font-size: ${({ theme }) => theme.typeFaces.body.size};

  ${({ width }) => (width ? `width: ${width};` : null)}
  color: ${({ color, theme, isFill }) =>
    color && !isFill ? theme[ButtonColorType[color]] : theme.textcolorWhite};

  ${({ isFill, theme, color }) =>
    !isFill &&
    color !== "gradientaAccept" &&
    color !== "gray" &&
    `border: 1px solid ${
      color ? theme[ButtonColorType[color]] : theme.borderColor
    };`}

  ${({ isFill, theme, color }) =>
    `background-color: ${
      isFill
        ? color
          ? theme[ButtonColorType[color]]
          : theme.background2
        : "transparent"
    }`};
  ${({ theme, color }) =>
    color === "gradientaAccept" &&
    `background: ${theme[ThemeBackground[color]]}`};

  cursor: ${({ disabled, isLoading }) =>
    isLoading ? "wait" : disabled ? "not-allowed" : "pointer"};

  .loader {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  :focus-visible {
    outline: none;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
