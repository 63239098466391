import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import {
  ICalculateBondFee,
  IGetBondsAvailSell,
  IGetListTransferBond,
  IResponsePlaceOrderTransferBond,
  ISubAccoNo,
} from "domain/models/PlaceOrderTransferBond";
import { IGetCustomer2 } from "../register-To-Transfer-Bonds/type";
import {
  calculateBondFeeApi,
  checkTimeExecuteApi,
  getBondsAvailSellApi,
  getBondsListApi,
  getCustomer2Api,
  getListTransferBondlApi,
  getSubAccoNo2Api,
  updateCbotBondTransferApi,
} from "./urls";

export const getCustomer2 = async (
  param: IGetCustomer2
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getCustomer2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getSubAccoNo = async (
  param: ISubAccoNo
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getSubAccoNo2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBondsList = async (
  param: ISubAccoNo
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getBondsListApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBondsAvailSell = async (
  param: IGetBondsAvailSell
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getBondsAvailSellApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListTransferBond = async (
  param: IGetListTransferBond
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getListTransferBondlApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const calculateBondFee = async (
  param: ICalculateBondFee
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    calculateBondFeeApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const updateCbotBondTransfer = async (
  param: any
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    updateCbotBondTransferApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const checkTimeExecute = async (
  param: any
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    checkTimeExecuteApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
