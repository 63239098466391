import { createSelector } from "@reduxjs/toolkit";
// import { CoverWarrantInfoState } from "modules/tickerDetails/redux/coverWarrantInfo";
// import { OrderFlowDistributionState } from "modules/tickerDetails/redux/orderFlowDistribution";
import { RootState } from "redux/store";
import { VolumeAndTimeState } from "modules/stock-order/redux/volumeAndTime";
// import { TickerInfoState } from "modules/tickerDetails/redux/tickerInfo";
import { InfoQuoteState } from "modules/stock-order/redux/priceList";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";

// TODO
export const stockOrderRootSelector = (state: RootState) =>
  state.stockOrder.root;

// export const tickerInfoSelector = (state: RootState) =>
//   state.tickerDetails.tickerInfo;

export const categoryRootSelector = (state: RootState) => state.categories.root;

// CoverWarrant Selector Start
// const coverWarrantInfoSelector = (state: RootState) =>
//   state.tickerDetails.coverWarrantInfo;

// export const tickerInfoDataSelector = createSelector(
//   coverWarrantInfoSelector,
//   (state: CoverWarrantInfoState) => ({
//     tickerInfoBasic: state.tickerInfoBasic,
//     cwPriceInfo: state.cwPriceInfo,
//     priceInfoUnderlying: state.priceInfoUnderlying,
//   })
// );

export const selectedTickerSelector = createSelector(
  stockOrderRootSelector,
  (state) => state.currentTicker
);

// CoverWarrant Selector End
// ================================================
// OrderFlowDistribution Start

// export const orderFlowDistributionSelector = (state: RootState) =>
//   state.tickerDetails.orderFlowDistribution;

// export const orderFlowDistributionChartSelector = createSelector(
//   orderFlowDistributionSelector,
//   (state: OrderFlowDistributionState) => ({
//     chartInflow: state.dataInflow,
//     chartOutflow: state.dataOutflow,
//     pieChartFlow: state.dataPieChartFlow,
//     totalInflow: state.totalInflow,
//     totalOutflow: state.totalOutflow,
//   })
// );
// OrderFlowDistribution End
// =========================
// Price List Start

export const priceListSelector = (state: RootState) =>
  state.stockOrder.priceList;

export const sumAskSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.sumAsk
);

export const sumBidSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.sumBid
);

export const percentBuySelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.percentBuy
);
export const dataDetailInfoSelector = (
  value: keyof SecQuotesDetailResponse.SecDetailInfo.AsObject
) =>
  createSelector(
    priceListSelector,
    (state: InfoQuoteState) => state.detailInfo?.[value]
  );
export const basicpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.basicprice
);
export const ceilingpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.ceilingprice
);
export const floorpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.floorprice
);

// Price List End
// =========================

// Volume and Time Start
const volumeAndTimeSelector = (state: RootState) =>
  state.stockOrder.volumeAndTime;

export const volumeListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.volumeList
);

export const historyListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.historyList
);

export const tnTimeSaleSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.tnTimeSale
);
// Volume and Time End
// ========================

// Portfolio Start
const portfolioSelector = (state: RootState) => state.stockOrder.portfolio;
export const portfolioRealTimeListSelector = createSelector(
  portfolioSelector,
  (state) => state.data.portfolioRealTime
);
export const isLoadListPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.isLoadList
);
export const checkEnableInfinityPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.enableInfinity
);
export const queryParamsPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.queryParams
);
export const itemsPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.data.items
);
export const summaryPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.data.summary
);

export const accountPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.account
);

export const alloDatePortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.alloDate
);

export const secCdPortfolioSelector = createSelector(
  portfolioSelector,
  (state) => state.secCd
);
// Portfolio End
