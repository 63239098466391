import { BlinkTime } from "AppConfig";
import { valueHeaderCardDerSelector } from "modules/system/selectors";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TextColorMini } from "../styles";
import { UNIT_NUMBER } from "helper/consts";

function ValueChange() {
  const dataRaw = useSelector(valueHeaderCardDerSelector("totalqty")) as number;

  const data = dataRaw * UNIT_NUMBER.ONE_MILLION;
  const oldData = useRef(0);
  const [backgroundColor, setBackgroundColor] = useState<string>("");

  useEffect(() => {
    const _oldData = oldData.current;
    oldData.current = numeral(data).value() || 0;
    if (_oldData !== data) {
      setBackgroundColor("solidFlash");
    }
    const timeout = setTimeout(() => setBackgroundColor(""), BlinkTime);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [numeral(data).format("0,0.[00]a")]);
  return (
    <TextColorMini backgroundColor={backgroundColor}>
      {numeral(data).format("0,0.[00]a")}
    </TextColorMini>
  );
}
export default ValueChange;
