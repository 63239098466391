import { all, call, put, takeLatest } from "redux-saga/effects";
import { getSecQuotesDetail } from "core/grpc";
import {
  getInfoQuote,
  getInfoQuoteFailure,
  getInfoQuoteSuccess,
} from "../redux/priceList";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";

function* fetchQuoteWorker(action: any) {
  try {
    const response: SecQuotesDetailResponse.AsObject = yield call(
      getSecQuotesDetail,
      action.payload
    );
    if (response.secdetailinfo) {
      yield put(getInfoQuoteSuccess(response.secdetailinfo));
    }
  } catch (e: any) {
    yield put(getInfoQuoteFailure(e.message));
  }
}

function* fetchQuoteWatcher() {
  yield takeLatest(getInfoQuote.type, fetchQuoteWorker);
}

export default function* infoQuoteWidget() {
  yield all([fetchQuoteWatcher()]);
}
