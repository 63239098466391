import {
  cashAdvance,
  cashAdvanceHistory,
  cashAdvanceFeeInfo,
  findAdvanceApi,
  deletePayAdvanceApi,
  getAmtCanReceiveApi,
  getFeeAdvanceApi,
  getCustomerRestrictionApi,
  getFindParameterBankDetailForPayAdvanceApi,
  getPayAdvanceLimitRemainApi,
  createAdvanceContractApi,
  getBankBranchCdApi,
  getBankBranchCd2Api,
} from "./urls";
import {
  IAdvanceResponse,
  IDeletePayAdvanceResponse,
  IGetAmtCanReceiveResponse,
  IGetFeeResponse,
  IGetCustomerRestrictionResponse,
  IGetFindParameterBankDetailForPayAdvanceResponse,
  IGetPayAdvanceLimitRemainResponse,
  ICreateAdvanceContractResponse,
  IGetBankBranchCdResponse,
  IGetBankBranchCd2Response,
} from "domain/models/Advance";
import storage from "helper/storage";
import {
  CashAdvanceFeeInfoParams,
  CashAdvanceHistoryParams,
  CashAdvanceParams,
  statusCashAdvanceParams,
  IFindAdvanceParams,
  IDeletePayAdvanceParams,
  IGetAmtCanReceiveParams,
  IGetFeeAdvanceParams,
  ICustomerRestrictionParams,
  IParameterBankDetailForPayAdvanceParams,
  IGetPayAdvanceLimitRemainParams,
  IGetBankBranchCdParams,
  IGetBankBranchCd2Params,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";
import {
  CashAdvanceHistoryInterface,
  CashAdvanceFeeInfoInterface,
  CashAdvanceInterface,
  ResponseAdvance,
} from "domain/models/Advance";
import qs from "qs";

// get api trạng thái ứng tiền bán
export const statusCashAdvanceApi = async (
  params: statusCashAdvanceParams
): Promise<CashAdvanceInterface> => {
  const { account } = params;
  const res = await VgaiaHttp.get<CashAdvanceInterface>(
    cashAdvance(storage.getMasterAccount(), account)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// get api lịch sử ứng tiền bán
export const cashAdvanceHistoryApi = async (
  params: CashAdvanceHistoryParams
): Promise<CashAdvanceHistoryInterface> => {
  const { account, ...restParams } = params;
  const res = await VgaiaHttp.get<CashAdvanceHistoryInterface>(
    cashAdvanceHistory(storage.getMasterAccount(), account),
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// get api phí ứng tiền bán
export const cashAdvanceFeeInfoApi = async (
  params: CashAdvanceFeeInfoParams
): Promise<CashAdvanceFeeInfoInterface> => {
  const { account, ...restParams } = params;
  const res = await VgaiaHttp.get<CashAdvanceFeeInfoInterface>(
    cashAdvanceFeeInfo(storage.getMasterAccount(), account),
    restParams
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// post api ứng tiền bán
export const createAdvanceApi = async (
  params: CashAdvanceParams
): Promise<ResponseAdvance> => {
  const { account, ...restParam } = params;
  const res = await VgaiaHttp.post<ResponseAdvance>(
    cashAdvance(storage.getMasterAccount(), account),
    qs.stringify(restParam)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListAdvance = async (
  params: IFindAdvanceParams
): Promise<IAdvanceResponse> => {
  const res = await VgaiaHttp.get<IAdvanceResponse>(findAdvanceApi(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deletePayAdvance = async (
  params: IDeletePayAdvanceParams
): Promise<IDeletePayAdvanceResponse> => {
  const res = await VgaiaHttp.post<IDeletePayAdvanceResponse>(
    deletePayAdvanceApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAmtCanReceive = async (
  params: IGetAmtCanReceiveParams
): Promise<IGetAmtCanReceiveResponse> => {
  const res = await VgaiaHttp.get<IGetAmtCanReceiveResponse>(
    getAmtCanReceiveApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCustomerRestriction = async (
  params: ICustomerRestrictionParams
): Promise<IGetCustomerRestrictionResponse> => {
  const res = await VgaiaHttp.get<IGetCustomerRestrictionResponse>(
    getCustomerRestrictionApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getBankBranchCd = async (
  params: IGetBankBranchCdParams
): Promise<IGetBankBranchCdResponse> => {
  const res = await VgaiaHttp.get<IGetBankBranchCdResponse>(
    getBankBranchCdApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getBankBranchCd2 = async (
  params: IGetBankBranchCd2Params
): Promise<IGetBankBranchCd2Response> => {
  const res = await VgaiaHttp.get<IGetBankBranchCd2Response>(
    getBankBranchCd2Api(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findParameterBankDetailForPayAdvance = async (
  params: IParameterBankDetailForPayAdvanceParams
): Promise<IGetFindParameterBankDetailForPayAdvanceResponse> => {
  const res =
    await VgaiaHttp.get<IGetFindParameterBankDetailForPayAdvanceResponse>(
      getFindParameterBankDetailForPayAdvanceApi(params)
    );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getPayAdvanceLimitRemain = async (
  params: IGetPayAdvanceLimitRemainParams
): Promise<IGetPayAdvanceLimitRemainResponse> => {
  const res = await VgaiaHttp.get<IGetPayAdvanceLimitRemainResponse>(
    getPayAdvanceLimitRemainApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getFeeAdvance = async (
  params: IGetFeeAdvanceParams
): Promise<IGetFeeResponse> => {
  const res = await VgaiaHttp.post<IGetFeeResponse>(
    getFeeAdvanceApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createAdvanceContract = async (
  params: IGetFeeAdvanceParams
): Promise<ICreateAdvanceContractResponse> => {
  const res = await VgaiaHttp.post<ICreateAdvanceContractResponse>(
    createAdvanceContractApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
