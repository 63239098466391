/* eslint-disable react-hooks/exhaustive-deps */
import {
  Body,
  Container,
  Title,
  Menu,
  Content,
  ItemMenu,
  TitleContent,
  ButtonExit,
  WrapProgressBar,
  WrapItemMenu,
} from "./styles";
import { Modal } from "react-bootstrap";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { visibleModalRegisterSelector } from "modules/auth/selectors";
import { setvisibleModalRegister } from "modules/auth/redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import Step1 from "./MenuItem/Step_1";
import Step2 from "./MenuItem/Step_2";
import Step3 from "./MenuItem/Step_3";
import Step4 from "./MenuItem/Step_4";
import Step5 from "./MenuItem/Step_5";
import Step6 from "./MenuItem/Step_6";
import Step7 from "./MenuItem/Step_7";
import Step8 from "./MenuItem/Step_8";
import StepOTP from "./MenuItem/Step_OTP";
import StepConfirm from "./MenuItem/Step_Confirm";
import Step52 from "./MenuItem/Step_5_2";
import { STEPS } from "./constants";
import { useMemo } from "react";
import { selectStep } from "modules/auth/selectorsModalRegister";

function ModalSetting() {
  const visibleModal = useSelector(visibleModalRegisterSelector);
  const activeStep = useSelector(selectStep);
  const dispatch = useDispatch();

  const toggleModalSetting = () => {
    dispatch(setvisibleModalRegister());
  };

  const closeModalSetting = () => {
    toggleModalSetting();
  };

  const renderContent = useMemo(() => {
    switch (activeStep) {
      case STEPS.STEPS_1:
        return <Step1 />;
      case STEPS.STEPS_2:
        return <Step2 />;
      case STEPS.STEPS_3:
        return <Step3 />;
      case STEPS.STEPS_4:
        return <Step4 />;
      case STEPS.STEPS_5:
        return <Step5 />;
      case STEPS.STEPS_6:
        return <Step52 />;
      case STEPS.STEPS_7:
        return <Step6 />;
      case STEPS.STEPS_8:
        return <Step7 />;
      case STEPS.STEPS_9:
        return <Step8 />;
      case STEPS.STEPS_10:
        return <StepOTP />;
      case STEPS.STEPS_11:
        return <StepConfirm />;
      default:
        return <Step1 />;
    }
  }, [activeStep]);

  const renderProgressBar = useMemo(() => {
    switch (activeStep as any) {
      case STEPS.STEPS_1:
        return "33%";
      case STEPS.STEPS_2:
        return "33%";
      case STEPS.STEPS_3:
        return "33%";
      case STEPS.STEPS_4:
        return "33%";
      case STEPS.STEPS_5:
        return "33%";
      case STEPS.STEPS_6:
        return "33%";
      case STEPS.STEPS_7:
        return "33%";
      case STEPS.STEPS_8:
        return "66%";
      case STEPS.STEPS_9:
        return "100%";
      case STEPS.STEPS_10:
        return "100%";
      case STEPS.STEPS_11:
        return "100%";
      default:
        return "33%";
    }
  }, [activeStep]);

  return (
    <>
      <Modal
        show={visibleModal}
        centered
        dialogClassName="modal-900"
        onHide={closeModalSetting}
      >
        <Container>
          <Title>
            <div className="flex-1"></div>
            <TitleContent>
              <FormattedMessage id="auth.register.title" />
            </TitleContent>
            <ButtonExit>
              <IconExit onClick={closeModalSetting} />
            </ButtonExit>
          </Title>
          <Body>
            <Menu>
              <WrapProgressBar radion={renderProgressBar}>
                <ProgressBar
                  variant="success"
                  className="progress-bar-vertical"
                  striped={false}
                />
              </WrapProgressBar>
              <WrapItemMenu>
                <ItemMenu isActive={true}>
                  <FormattedMessage id="auth.register.labelLeft1" />
                </ItemMenu>
                <ItemMenu
                  isActive={
                    renderProgressBar === "66%" || renderProgressBar === "100%"
                      ? true
                      : false
                  }
                >
                  <FormattedMessage id="auth.register.labelLeft2" />
                </ItemMenu>
                <ItemMenu
                  isActive={renderProgressBar === "100%" ? true : false}
                >
                  <FormattedMessage id="auth.register.labelLeft3" />
                </ItemMenu>
              </WrapItemMenu>
            </Menu>
            <Content>{renderContent}</Content>
          </Body>
        </Container>
      </Modal>
    </>
  );
}

export default ModalSetting;
