import { FC } from "react";
import { VolumeCell as VolumeCellWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { TopStatisticalState } from "modules/market/redux/topStatistical";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  activeTabTopStatisticalSelector,
  marketTopStatisticalSelector,
} from "modules/market/redux/selectors";
import { TopStatisticalMenu } from "../types";
import { TopSecChangedResponse } from "domain/protoNew/auto_trading_pb";
import { UNIT_NUMBER } from "helper/consts";

function getFieldValue(value: TopStatisticalMenu) {
  switch (value) {
    case "topVolume":
      return "totalqty";
    case "topValue":
      return "totalamt";
    default:
      return "totalqty";
  }
}

function getFormatValue(value: TopStatisticalMenu) {
  switch (value) {
    case "topVolume":
      return UNIT_NUMBER.ONE_HUNDRED_THOUSAND;
    case "topValue":
      return UNIT_NUMBER.ONE_MILLION;
    default:
      return UNIT_NUMBER.ONE_HUNDRED_THOUSAND;
  }
}

const volumeSelector = (
  tickerName: string,
  fieldValue: keyof TopSecChangedResponse.TopSecChangedInfo.AsObject
) => {
  return createSelector(
    marketTopStatisticalSelector,
    (topStatisticalState: TopStatisticalState) =>
      topStatisticalState?.data?.[tickerName]?.[fieldValue]
  );
};

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopStatisticalSelector,
    (topStatisticalState: TopStatisticalState) =>
      topStatisticalState?.data?.[tickerName]?.colorcode
  );
};

interface Props {
  dataRow: string;
}

const VolumeCell: FC<Props> = ({ dataRow }) => {
  const activeTab = useSelector(activeTabTopStatisticalSelector);
  const volume = useSelector(
    volumeSelector(dataRow, getFieldValue(activeTab))
  ) as number;
  const textColor = useSelector(textColorSelector(dataRow));

  return (
    <VolumeCellWrapper textcolor={textColor}>
      <BlinkableCell
        value={numeral(volume / getFormatValue(activeTab)).format("0,0.00")}
      />
    </VolumeCellWrapper>
  );
};

export { VolumeCell };
