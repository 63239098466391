import { Button, DatePicker } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import {
  getListDepositRequest,
  searchDepositRequest,
} from "modules/deposit-list/redux";
import { loadingSearchDataSelector } from "modules/deposit-list/selectors";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { MarginItem, WrapBoder } from "./styles";

function Filter() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const loading = useSelector(loadingSearchDataSelector);

  const channel = [
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.all",
      })}`,
      value: "",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.black",
      })}`,
      value: "1",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.online",
      })}`,
      value: "2",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.ipay",
      })}`,
      value: "3",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.webMobile",
      })}`,
      value: "4",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.mobile",
      })}`,
      value: "5",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.home",
      })}`,
      value: "6",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.floor",
      })}`,
      value: "7",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.sms",
      })}`,
      value: "8",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.tel",
      })}`,
      value: "9",
    },
  ];

  const status = [
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.all",
      })}`,
      value: "",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.inEffect",
      })}`,
      value: "1",
    },
    {
      label: `${intl.formatMessage({
        id: "depositListPMoney.liquidated",
      })}`,
      value: "2",
    },
  ];

  const d = new Date();
  const [fromDateCreate, setFromDateCreate] = useState(
    new Date(d.getFullYear(), d.getMonth(), 1)
  );
  const [toDateCreate, setToDateCreate] = useState(new Date());
  const [fromDateLiquidation, setFromDateLiquidation] = useState(
    new Date(d.getFullYear(), d.getMonth(), 1)
  );
  const [toDateLiquidation, setToDateLiquidation] = useState(new Date());
  const [newChanel, setNewChanel] = useState();
  const [newstatus, setNewStatus] = useState();

  var custNo = storage.getMasterAccount();

  const handleSearch = () => {
    if (moment(fromDateCreate).isAfter(moment(toDateCreate))) {
      toast(
        `${intl.formatMessage({
          id: "depositListPMoney.errorToDateCreate",
        })}`,
        "error"
      );
    } else if (moment(fromDateLiquidation).isAfter(moment(toDateLiquidation))) {
      toast(
        `${intl.formatMessage({
          id: "depositListPMoney.errorToDateLiquidate",
        })}`,
        "error"
      );
    } else {
      dispatch(
        searchDepositRequest({
          branchCd: "",
          transactionCd: "",
          subAccoNo: custNo ? custNo : "",
          createDateFrom: moment(fromDateCreate).format("YYYYMMDD"),
          createDateTo: moment(toDateCreate).format("YYYYMMDD"),
          liquidatedDateFrom: moment(fromDateLiquidation).format("YYYYMMDD"),
          liquidatedDateTo: moment(toDateLiquidation).format("YYYYMMDD"),
          contractSts: "",
          status: newstatus ? newstatus : status?.[0].value,
          srcChannel: newChanel ? newChanel : channel?.[0].value,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getListDepositRequest({
        branchCd: "",
        transactionCd: "",
        subAccoNo: custNo ? custNo : "",
        createDateFrom: moment(fromDateCreate).format("YYYYMMDD"),
        createDateTo: moment(toDateCreate).format("YYYYMMDD"),
        liquidatedDateFrom: moment(fromDateLiquidation).format("YYYYMMDD"),
        liquidatedDateTo: moment(toDateLiquidation).format("YYYYMMDD"),
        contractSts: "",
        status: status?.[0].value,
        srcChannel: channel?.[0].value,
      })
    );
  }, [custNo]);

  const handleChangeFromDateCreate = (value: any) => {
    setFromDateCreate(value);
  };
  const handleChangeToDateCreate = (value: any) => {
    setToDateCreate(value);
  };
  const handleChangeFromDateLiquidation = (value: any) => {
    setFromDateLiquidation(value);
  };
  const handleChangeToDateLiquidation = (value: any) => {
    setToDateLiquidation(value);
  };

  const handleChangeChanel = (value: any) => {
    setNewChanel(value.value);
  };
  const handleChangeStatus = (value: any) => {
    setNewStatus(value.value);
  };

  // render
  return (
    <WrapBoder className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex p-0">
        <FilterItem
          filterName={intl.formatMessage({
            id: "depositListPMoney.dateCreate",
          })}
          className="mr-2"
        >
          <DatePicker
            selected={fromDateCreate}
            onChange={handleChangeFromDateCreate}
            popperPlacement="bottom-start"
          ></DatePicker>
        </FilterItem>
        <FilterItem filterName={""} className="mr-2">
          <MarginItem>
            <DatePicker
              selected={toDateCreate}
              onChange={handleChangeToDateCreate}
              popperPlacement="bottom-start"
            ></DatePicker>
          </MarginItem>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "depositListPMoney.liquidationDate",
          })}
          className="mr-2"
        >
          <DatePicker
            selected={fromDateLiquidation}
            onChange={handleChangeFromDateLiquidation}
            popperPlacement="bottom-start"
          ></DatePicker>
        </FilterItem>
        <FilterItem filterName={""} className="mr-2">
          <MarginItem>
            <DatePicker
              selected={toDateLiquidation}
              onChange={handleChangeToDateLiquidation}
              popperPlacement="bottom-start"
            ></DatePicker>
          </MarginItem>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "depositListPMoney.setUpChanel",
          })}
          className="mr-2"
        >
          <CSelect
            options={channel}
            onChange={handleChangeChanel}
            defaultValue={channel?.[0]}
          />
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "depositListPMoney.status",
          })}
          className="mr-2"
        >
          <CSelect
            options={status}
            onChange={handleChangeStatus}
            defaultValue={status?.[0]}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          onClick={handleSearch}
          loading={loading}
        >
          {intl.formatMessage({
            id: "depositListPMoney.search",
          })}
        </Button>
      </FilterWrapper>
    </WrapBoder>
  );
}

export default memo(Filter);
