import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import numeral from "numeral";
import Value from "../Value";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { useEffect, useState, useRef } from "react";
import { BlinkTime } from "AppConfig";
import { getColorPrice } from "helper/utils";

const lastpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.lastprice
);

const floorpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.floorprice
);
const ceilingpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.ceilingprice
);
const basicpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.basicprice
);

const LastPriceCell = () => {
  const oldData = useRef<number | undefined>(0);
  const lastprice = useSelector(lastpriceSelector) || 0;
  const floorprice = useSelector(floorpriceSelector) || 0;
  const ceilingprice = useSelector(ceilingpriceSelector) || 0;
  const basicprice = useSelector(basicpriceSelector) || 0;
  const [backgroundColor, setBackgroundColor] = useState<string>("yellow");
  useEffect(() => {
    const _oldData = oldData.current;
    if (_oldData && _oldData !== lastprice) {
      setBackgroundColor("solidFlash");
    }
    setTimeout(() => setBackgroundColor(""), BlinkTime);
    return () => {
      oldData.current = lastprice;
    };
  }, [lastprice]);
  const color = getColorPrice(lastprice, floorprice, ceilingprice, basicprice);
  return (
    <>
      <Value
        className="mr-2"
        color={backgroundColor ? "textcolorWhite" : color}
        size="big"
        background={backgroundColor}
        value={numeral(lastprice).format("0.00")}
      />
    </>
  );
};

export default LastPriceCell;
