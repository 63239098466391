import styled from "styled-components/macro";

export const Container = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.background1};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const Wrapper = styled.div`
  overflow-y: auto;
  height: calc(100% - 52px);
  margin-bottom: 20px;
  background: ${({ theme }) => theme.background1};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MiniCard = styled.div<{
  isActive?: boolean;
}>`
  border-radius: 8px;
  background-color: ${({ theme, isActive }) =>
    isActive ? `${theme.background2}` : `${theme.background2}`};
  border: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.brandPrimary : "transparent")};

  .myScreener_title_card {
    ${({ theme, isActive }) =>
      isActive &&
      `
      color: ${theme.brandPrimary}
    `}
  }
`;

export const HeaderCard = styled.div`
  padding: 6px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleCard = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
`;

export const MoreButton = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const BodyCard = styled.div`
  padding: 8px 12px;
`;

export const InfoRow = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.normal};
`;

export const DeleteButton = styled.div`
  user-select: none;
  cursor: pointer;
`;

export const CardRow = styled.div`
  & > div {
    display: inline;
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }
`;

export const CardLabelCell = styled.div`
  margin-right: 4px;
`;

export const CardContentCell = styled.div`
  flex: 1;
`;

export const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  color: transparent;
  user-select: none;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
`;

export const StickyButtonWrapper = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  .button-confirm {
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    height: ${({ theme }) => theme.button.height};
    width: 100%;
    box-sizing: border-box;
  }
`;
