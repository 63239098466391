import React, { useState, useCallback, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { IntlShape, useIntl } from "react-intl";
import InputPrice from "components/commons/InputPrice";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import {
  onPrice,
  onVolume,
  dertEditOrder,
  onChangePriceOrderBookEditByEnter,
  onChangeVolumeOrderBookEditByEnter,
  onLoadEdit,
  editSingleOrderStatusDerClear,
} from "modules/stock-order-der/redux/OrderBookDer";
import { Button, OTPByBusinessCd, Spacer } from "components/commons";
import { toast } from "components/commons/Toast";
import { Body, Container, Title, Footer } from "./styles";
import isMatch from "lodash/isMatch";
// import { checkEditOrder } from "helper/tradingHelper";
import InputVolume from "components/commons/InputVolume";
import { getValueRound } from "helper/utils";
import {
  dertTradingGetFeeRateSelector,
  orderBookDerSelector,
} from "modules/stock-order-der/selectors";
import { OrderBookDerDisplay } from "domain/models/OrderBookDer";
import { EBusinessCd, SrcChannel } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import {
  IDertEditOrderData,
  IDertEditOrderParams,
} from "core/http/apis/tradeinfo/types";
import { tradeDateSelector } from "modules/auth/selectors";
import { IDertTradingGetFeeRateParams } from "core/http/apis/orderexec/types";
// import storage from "helper/storage";
import { getDertTradingGetFeeRate } from "modules/stock-order-der/redux/PlaceOrderDer";

const regPrice = /^\d+(\.\d{0,9})?$/;
const regNumber = /^\d+$/;

const getTitleIntl = (intl: IntlShape) => (dataRow: OrderBookDerDisplay) => {
  if (!dataRow?.tradeType) return "-";
  return intl
    .formatMessage({
      id: `widgets.orderBookDerEdit.${
        dataRow?.tradeType === 2 ? "titleLong" : "titleShort"
      }`,
    })
    .toUpperCase();
};

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<boolean>;
  dataRow?: OrderBookDerDisplay;
}
const EditOrderBookDerModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  dataRow,
}) => {
  const intl = useIntl();
  const getTitle = getTitleIntl(intl);
  const dispatch = useDispatch();
  const refInputPrice = useRef<HTMLInputElement>(null);
  const refInputVolume = useRef<HTMLInputElement>(null);
  const {
    price,
    volume,
    profit,
    stopLoss,
    margin,
    singleOrderStatus,
    changePriceByEnter,
    changeVolumeByEnter,
    loadingEdit,
  } = useSelector(orderBookDerSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const dertTradingGetFeeRate = useSelector(dertTradingGetFeeRateSelector);

  const [errorPrice, setErrorPrice] = useState("");
  const [errorVolume, setErrorVolume] = useState("");
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);

  useEffect(() => {
    setErrorPrice("");
    setErrorVolume("");
  }, [showModal]);

  // Dispatch giá đặt
  const onChangePrice = (value: number | string, type?: string) => {
    setErrorPrice("");
    if (numeral(value).value() === null) {
      dispatch(onPrice(0));
    } else {
      dispatch(onPrice(value));
    }
  };

  // Dispatch khối lượng đặt
  const onChangeVolume = (value: number | string, type?: string) => {
    setErrorVolume("");
    if (value >= 0) {
      dispatch(onVolume(value));
    }
  };

  // Validate giá đặt
  const validatePrice = () => {
    setErrorPrice("");
    if (!price) {
      setErrorPrice(intl.formatMessage({ id: "validation.emptyPrice" }));
      return false;
    }

    if (typeof price !== "string" && !regPrice.test(price?.toString())) {
      setErrorPrice(intl.formatMessage({ id: "validation.patternPrice" }));
      return false;
    }

    return true;
  };

  // Validate khối lượng đặt
  const validateVolume = () => {
    setErrorVolume("");
    if (!volume) {
      setErrorVolume(intl.formatMessage({ id: "validation.emptyVolume" }));
      return false;
    }

    return true;
  };

  // Validate
  const validate = () => {
    if (!validatePrice() || !validateVolume()) {
      return false;
    } else {
      setErrorPrice("");
      setErrorVolume("");
      return true;
    }
  };

  // Validate TH chưa sửa gì => disable btn submit
  const validateDisableBtnSubmit = useCallback(() => {
    const oldData = [
      numeral(dataRow?.pubPrice).format("0,0.0"),
      numeral(dataRow?.pubQty).format("0,0.0"),
    ];
    const newData = [
      numeral(price).format("0,0.0"),
      numeral(volume).format("0,0.0"),
    ];

    const isDisable = isMatch(oldData.slice(0, 2), newData.slice(0, 2));

    setIsDisableSubmit(isDisable);
  }, [price, volume, profit, stopLoss, margin]);

  // Submit nút đặt lệnh
  const onSubmit = () => {
    if (isDisableSubmit) {
      toast("tradingHelper.dataDoesNotChange", "error");
      return;
    }
    dispatch(onLoadEdit(true));
    if (validate()) {
      try {
        const paramsFeeRate: IDertTradingGetFeeRateParams = {
          custNo: dataRow?.custNo || "",
          secCd: dataRow?.secCd || "",
          marketCd: 500,
          tradeType: dataRow?.tradeType || 1,
          chanel: SrcChannel.WEB,
          quantity: volume,
        };

        dispatch(getDertTradingGetFeeRate(paramsFeeRate));

        setVisibleModalOTP(true);
        setShowModal(false);
      } catch (error: any) {}
    } else {
      dispatch(onLoadEdit(false));
    }
    // TODO: Trường hợp đã đăng ký smartOTP ? (Dựa vào ..)
  };

  // Submit OK
  const handleModal = () => {
    setVisibleModalOTP(false);
    setShowModal(true);
    dispatch(onLoadEdit(false));
  };

  const doSubmitEditOrder = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    setShowModal(false);
    if (!dataRow?.subAccoNo) {
      return;
    }

    // // Validate
    // try {
    //   const error = await checkEditOrder(dataRow, params, intl);
    //   if (error) {
    //     const errorMsg = error;
    //     toast(errorMsg, "error");
    //     setShowModal(true);
    //     dispatch(onLoadEdit(false));
    //     return;
    //   }
    // } catch (error: any) {
    //   const errorMsg = intl.formatMessage({
    //     id: error.code,
    //     defaultMessage: error.description,
    //   });
    //   toast(errorMsg, "error");
    //   setShowModal(true);
    //   dispatch(onLoadEdit(false));
    //   return;
    // }

    const paramsItem: IDertEditOrderData = {
      reqType: 3,
      secCd: dataRow?.secCd,
      boardCd: dataRow?.boardCd,
      subAccoCd: dataRow?.subAccoCd,
      subAccoNo: dataRow?.subAccoNo,
      custCd: dataRow?.custCd,
      custNo: dataRow?.custNo,
      custFamilyName: dataRow?.custFamilyName,
      custGivenName: dataRow?.custGivenName,
      orgOrderNo: dataRow?.orgOrderNo,
      orderNo: dataRow?.orderNo,
      ordQty: dataRow?.ordQty,
      ordPrice: dataRow?.ordPrice,
      ordType: dataRow?.ordType,
      updLongTime: dataRow?.updLongTime,
      contraQty: parseInt(volume?.toString().replaceAll(",", "")),
      contraPrice: getValueRound(price),
      ordChanel: SrcChannel.WEB,
      tradeType: dataRow?.tradeType,
      tradeDate: Number(tradeDate),
      feeRate: dertTradingGetFeeRate,
      feeAmt: dertTradingGetFeeRate * dataRow?.pubQty,
      ordAmt: Math.max(
        dataRow?.pubQty * dataRow?.pubPrice * dataRow?.multipleNum -
          parseInt(volume?.toString().replaceAll(",", "")) *
            getValueRound(price) *
            dataRow?.multipleNum,
        0
      ),
    };

    const params: IDertEditOrderParams = {
      data: JSON.stringify(paramsItem),
      otpCode: response?.otp,
      otpType: response?.otpTypeSubmit!,
    };

    // Submit
    dispatch(dertEditOrder(params));
  };

  useEffect(() => {
    validateDisableBtnSubmit();
    // validateShowHideControlInput();
  }, [price, volume, profit, stopLoss, margin]);

  useEffect(() => {
    if (singleOrderStatus?.status === "success") {
      toast("widgets.orderBookDerEdit.orderSuccess", "success");
      dispatch(onLoadEdit(false));
      dispatch(editSingleOrderStatusDerClear());
      setShowModal(false);
    }

    if (singleOrderStatus?.status === "failed") {
      dispatch(onLoadEdit(false));
      dispatch(editSingleOrderStatusDerClear());
      const codeError = singleOrderStatus?.error;
      if (codeError) {
        const msg = `${codeError?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg
          ? "error." + msg
          : "widgets.placeOrderDer.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }
    }
  }, [singleOrderStatus]);

  useEffect(() => {
    if (showModal && numeral(price).value() !== null) {
      refInputPrice.current?.focus();
    }
  }, [showModal]);

  useEffect(() => {
    if (changePriceByEnter) {
      refInputVolume.current?.focus();
      return;
    }
  }, [changePriceByEnter]);

  useEffect(() => {
    // if (changeVolumeByEnter && dataRow?.stopType) {
    //   if (dataRow?.stopType === "B") {
    //     refInputProfit.current?.focus();
    //     return;
    //   } else if (
    //     dataRow?.stopType === "U" ||
    //     dataRow?.stopType === "D" ||
    //     dataRow?.stopType === "V" ||
    //     dataRow?.stopType === "E"
    //   ) {
    //     refInputStopLoss.current?.focus();
    //     return;
    //   }
    // }
  }, [changeVolumeByEnter]);

  const onBlurEnter = () => {
    dispatch(onChangePriceOrderBookEditByEnter(false));
    dispatch(onChangeVolumeOrderBookEditByEnter(false));
  };

  const handleKeyDown = (e: any, typeInput: string) => {
    if (e.key === "Enter" && typeInput === "price") {
      dispatch(onChangePriceOrderBookEditByEnter(true));
    }
    if (e.key === "Enter" && typeInput === "volume") {
      dispatch(onChangeVolumeOrderBookEditByEnter(true));
      if (!isDisableSubmit && !loadingEdit) {
        onSubmit();
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="modal-461"
      >
        <Container>
          <Title side={dataRow?.tradeType}>{getTitle(dataRow!)}</Title>
          <Body>
            <InputPrice
              innerRef={refInputPrice}
              suggestions={[]}
              name="input-price-order-book-edit"
              value={price}
              onChange={onChangePrice}
              label={intl.formatMessage({
                id: "widgets.orderBookDerEdit.setPrice",
              })}
              decimal
              partern={regPrice}
              onBlurEnter={onBlurEnter}
              error={errorPrice}
              min={0}
              ticker={dataRow?.secCd}
              isOneNumberAfterComma
              disabled={dataRow?.isMarketOrder}
              onKeyDown={(e) => handleKeyDown(e, "price")}
            />
            <InputVolume
              innerRef={refInputVolume}
              name="input-volume-order-book-edit"
              suggestions={[]}
              value={numeral(volume).format("0,0")}
              onChange={onChangeVolume}
              label={intl.formatMessage({
                id: "widgets.orderBookDerEdit.setVolume",
              })}
              onBlurEnter={onBlurEnter}
              decimal
              partern={regNumber}
              error={errorVolume}
              min={1}
              ticker={dataRow?.secCd}
              onKeyDown={(e) => handleKeyDown(e, "volume")}
            />
          </Body>
          <Footer>
            <Button
              className="flex-1"
              color="accept"
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({ id: "widgets.orderBookDerEdit.cancel" })}
            </Button>
            <Spacer size="s" />
            <Button
              className={`py-2 ${
                dataRow?.tradeType === 2 ? "btn-success" : "btn-danger"
              } flex-1`}
              onClick={() => onSubmit()}
              focusButton={!loadingEdit}
              loading={loadingEdit}
            >
              {intl.formatMessage({
                id: "widgets.orderBookDerEdit.confirm",
              })}
            </Button>
          </Footer>
        </Container>
      </Modal>
      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleModal}
          getResult={doSubmitEditOrder}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </>
  );
};
export default EditOrderBookDerModal;
