import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled.div`
  background-color: ${({ theme }) => theme.bgElevated3};
  text-align: center;
  justify-content: center;
  padding: 10px;

  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Body = styled.div<{ padding?: number }>`
  background-color: ${({ theme }) => theme.background1};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  padding: 16px;
  word-wrap: break-word;
  text-align: center;
`;

export const Footer = styled.div`
  display: block;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.background1};
  button {
    margin: auto;
    width: 100%;
  }
  padding: 16px 24px;
`;
