import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 540px;
  justify-content: space-between;
`;

export const WrapCamera = styled.div`
  width: 100%;
  height: 72%;
`;

export const WrapIconCamera = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeaderModal = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 15px;
`;

export const ModalTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const BodyModal = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  height: 500px;
  gap: 16px;
  background-color: ${({ theme }) => theme.background2};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const WrapModal = styled.div`
  width: 100%;
  min-height: 500px;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.bgElevated3};
`;

export const WrapLabel = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.grey0};
`;
