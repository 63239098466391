import { createSelector } from "@reduxjs/toolkit";
import { WatchlistGroupType } from "helper/consts";
import {
  categoriesInfoSelector,
  tickersListSelectedSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
}

const totalqtySelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.totalqty
  );
};

function TotalqtyCell({ ticker }: Props) {
  const totalqty = useSelector(totalqtySelector(ticker)) || 0;
  const { type } = useSelector(tickersListSelectedSelector);

  return (
    <VolumeCell
      volume={totalqty}
      keepRawValue={type === WatchlistGroupType.ODDLOT}
    />
  );
}

export default TotalqtyCell;
