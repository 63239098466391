import { toast } from "components/commons/Toast";
import {
  getListAdvance,
  deletePayAdvance,
  getAmtCanReceive,
  getFeeAdvance,
  createAdvanceContract,
  getCustomerRestriction,
  getPayAdvanceLimitRemain,
  findParameterBankDetailForPayAdvance,
  getBankBranchCd,
  getBankBranchCd2,
} from "core/http/apis/advance";
import {
  IFindAdvanceParams,
  IDeletePayAdvanceParams,
  IGetAmtCanReceiveParams,
  IGetFeeAdvanceParams,
  ICustomerRestrictionParams,
  IGetPayAdvanceLimitRemainParams,
  IParameterBankDetailForPayAdvanceParams,
  IGetBankBranchCdParams,
  IEditAdvanceParams,
  IGetBankBranchCd2Params,
} from "core/http/apis/advance/types";
import {
  IAdvanceResponse,
  IDeletePayAdvanceResponse,
  IGetAmtCanReceiveResponse,
  IGetFeeResponse,
  IGetPayAdvanceLimitRemainResponse,
  ICreateAdvanceContractResponse,
  IGetFindParameterBankDetailForPayAdvanceResponse,
  IGetBankBranchCdResponse,
  ICustomerRestrictionResponse,
  IGetBankBranchCd2Response,
} from "domain/models/Advance";

import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { advanceEditSelector } from "modules/advance/selectors";

import {
  searchAdvanceRequest,
  searchAdvanceSuccess,
  searchAdvanceFailure,
  deletePayAdvanceRequest,
  deletePayAdvanceSuccess,
  deletePayAdvanceFailure,
  getAmtCanReceiveRequest,
  getAmtCanReceiveSuccess,
  getAmtCanReceiveFailure,
  getFeeAdvanceRequest,
  getFeeAdvanceSuccess,
  getFeeAdvanceFailure,
  createAdvanceContractRequest,
  createAdvanceContractSuccess,
  createAdvanceContractFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  updateModalOtpRequest,
  updateModalOtpSuccess,
  updateModalOtpLiquidRequest,
  updateModalOtpLiquidSuccess,
  getCustomerRestrictionRequest,
  getCustomerRestrictionSuccess,
  getCustomerRestrictionFailure,
  findParameterBankDetailForPayAdvanceRequest,
  findParameterBankDetailForPayAdvanceSuccess,
  findParameterBankDetailForPayAdvanceFailure,
  getBankBranchCdRequest,
  getBankBranchCdSuccess,
  getBankBranchCdFailure,
  getPayAdvanceLimitRemainRequest,
  getPayAdvanceLimitRemainSuccess,
  getPayAdvanceLimitRemainFailure,
  getBankBranchCd2Request,
  getBankBranchCd2Success,
  getBankBranchCd2Failure,
} from "./redux";

function* searchAdvanceWorker(action: ReturnType<typeof searchAdvanceRequest>) {
  try {
    const params: IFindAdvanceParams = action.payload;
    const response: IAdvanceResponse = yield call(getListAdvance, params);

    if (response?.statusCode === 0) {
      yield put(searchAdvanceSuccess(response));
    } else {
      yield put(searchAdvanceFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchAdvanceFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* deletePayAdvanceWorker(
  action: ReturnType<typeof deletePayAdvanceRequest>
) {
  try {
    const params: IDeletePayAdvanceParams = action.payload;
    const response: IDeletePayAdvanceResponse = yield call(
      deletePayAdvance,
      params
    );

    if (response?.statusCode === 0) {
      yield put(deletePayAdvanceSuccess(response));
    } else {
      yield put(deletePayAdvanceFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(deletePayAdvanceFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getAmtCanReceiveWorker(
  action: ReturnType<typeof getAmtCanReceiveRequest>
) {
  try {
    const params: IGetAmtCanReceiveParams = action.payload;
    const response: IGetAmtCanReceiveResponse = yield call(
      getAmtCanReceive,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getAmtCanReceiveSuccess(response));
    } else {
      yield put(getAmtCanReceiveFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getAmtCanReceiveFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getCustomerRestrictionWorker(
  action: ReturnType<typeof getCustomerRestrictionRequest>
) {
  try {
    const params: ICustomerRestrictionParams = action.payload;
    const response: ICustomerRestrictionResponse = yield call(
      getCustomerRestriction,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getCustomerRestrictionSuccess(response));
    } else {
      yield put(getCustomerRestrictionFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getCustomerRestrictionFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBankBranchCdWorker(
  action: ReturnType<typeof getBankBranchCdRequest>
) {
  try {
    const params: IGetBankBranchCdParams = action.payload;
    const response: IGetBankBranchCdResponse = yield call(
      getBankBranchCd,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getBankBranchCdSuccess(response));
    } else {
      yield put(getBankBranchCdFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBankBranchCdFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBankBranchCd2Worker(
  action: ReturnType<typeof getBankBranchCd2Request>
) {
  try {
    const params: IGetBankBranchCd2Params = action.payload;
    const response: IGetBankBranchCd2Response = yield call(
      getBankBranchCd2,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getBankBranchCd2Success(response));
    } else {
      yield put(getBankBranchCd2Failure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBankBranchCd2Failure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findParameterBankDetailForPayAdvanceWorker(
  action: ReturnType<typeof findParameterBankDetailForPayAdvanceRequest>
) {
  try {
    const params: IParameterBankDetailForPayAdvanceParams = action.payload;
    const response: IGetFindParameterBankDetailForPayAdvanceResponse =
      yield call(findParameterBankDetailForPayAdvance, params);
    const itemEdit: IEditAdvanceParams = yield select(advanceEditSelector);
    let realRecvAmt =
      itemEdit && itemEdit.realRecvAmt ? itemEdit.realRecvAmt : 0;

    if (response?.statusCode === 0) {
      if (realRecvAmt === null || realRecvAmt === undefined) {
        return toast("advance.message.amountCheck1", "error");
      } else if (
        realRecvAmt &&
        realRecvAmt !== null &&
        realRecvAmt !== undefined
      ) {
        if (realRecvAmt < response.data.padContractMinValue) {
          // toast(
          //   `Số tiền ứng tối thiểu là ${response.data.padContractMinValue}`,
          //   "error"
          // );
          yield put(
            findParameterBankDetailForPayAdvanceFailure({
              status: 1,
              value: response.data.padContractMinValue,
            })
          );
        }
        if (realRecvAmt > response.data.padContractMaxValue) {
          // return toast(
          //   `Số tiền ứng tối đa là ${response.data.padContractMaxValue}`,
          //   "error"
          // );
          yield put(
            findParameterBankDetailForPayAdvanceFailure({
              status: 2,
              value: response.data.padContractMaxValue,
            })
          );
        }
      }
      yield put(findParameterBankDetailForPayAdvanceSuccess(response));
    } else {
      yield put(findParameterBankDetailForPayAdvanceFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findParameterBankDetailForPayAdvanceFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getPayAdvanceLimitRemainWorker(
  action: ReturnType<typeof getPayAdvanceLimitRemainRequest>
) {
  try {
    const params: IGetPayAdvanceLimitRemainParams = action.payload;
    const response: IGetPayAdvanceLimitRemainResponse = yield call(
      getPayAdvanceLimitRemain,
      params
    );
    const itemEdit: IEditAdvanceParams = yield select(advanceEditSelector);
    let realRecvAmt =
      itemEdit && itemEdit.realRecvAmt ? itemEdit.realRecvAmt : 0;

    if (response?.statusCode === 0) {
      if (realRecvAmt === null || realRecvAmt === undefined) {
        return toast("advance.message.amountCheck1", "error");
      } else if (
        realRecvAmt &&
        realRecvAmt !== null &&
        realRecvAmt !== undefined
      ) {
        if (
          realRecvAmt > response.data.comLimitRemain &&
          realRecvAmt > response.data.custLimitRemain
        ) {
          toast("advance.message.amountCheck4", "error");
          // toast(
          //   `Hạn mức ứng trước còn lại của khách hàng là ${response.data.custLimitRemain}. Quý KH vui lòng liên hệ 024 39343 888 để được hướng dẫn`,
          //   "error"
          // );
          yield put(
            getPayAdvanceLimitRemainFailure({
              status: 3,
              value: response.data.custLimitRemain,
            })
          );
          return;
        }
        if (realRecvAmt > response.data.comLimitRemain) {
          return toast("advance.message.amountCheck4", "error");
        }
        if (realRecvAmt > response.data.custLimitRemain) {
          // return toast(
          //   `Hạn mức ứng trước còn lại của khách hàng là ${response.data.custLimitRemain}. Quý KH vui lòng liên hệ 024 39343 888 để được hướng dẫn`,
          //   "error"
          // );
          yield put(
            getPayAdvanceLimitRemainFailure({
              status: 3,
              value: response.data.custLimitRemain,
            })
          );
        }
      }
      yield put(getPayAdvanceLimitRemainSuccess(response));
    } else {
      yield put(getPayAdvanceLimitRemainFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getPayAdvanceLimitRemainFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getFeeAdvanceWorker(action: ReturnType<typeof getFeeAdvanceRequest>) {
  try {
    const params: IGetFeeAdvanceParams = action.payload;

    const response: IGetFeeResponse = yield call(getFeeAdvance, params);

    if (response?.statusCode === 0) {
      yield put(getFeeAdvanceSuccess(response));
    } else {
      yield put(getFeeAdvanceFailure());
    }
  } catch (error: any) {
    yield put(getFeeAdvanceFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* createAdvanceContractWorker(
  action: ReturnType<typeof createAdvanceContractRequest>
) {
  try {
    const params: IGetFeeAdvanceParams = action.payload;

    const response: ICreateAdvanceContractResponse = yield call(
      createAdvanceContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(createAdvanceContractSuccess(response));
    } else {
      yield put(createAdvanceContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(createAdvanceContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
    } = action.payload;

    yield put(updateModalStatusSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalOtpWorker(
  action: ReturnType<typeof updateModalOtpRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
    } = action.payload;

    yield put(updateModalOtpSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalOtpLiquidWorker(
  action: ReturnType<typeof updateModalOtpLiquidRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
    } = action.payload;

    yield put(updateModalOtpLiquidSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* deletePayAdvanceWatcher() {
  yield takeLatest(deletePayAdvanceRequest.type, deletePayAdvanceWorker);
}
function* searchAdvanceWatcher() {
  yield takeLatest(searchAdvanceRequest.type, searchAdvanceWorker);
}

function* getAmtCanReceiveWatcher() {
  yield takeLatest(getAmtCanReceiveRequest.type, getAmtCanReceiveWorker);
}
function* getFeeAdvanceWatcher() {
  yield takeLatest(getFeeAdvanceRequest.type, getFeeAdvanceWorker);
}
function* createAdvanceContractWatcher() {
  yield takeLatest(
    createAdvanceContractRequest.type,
    createAdvanceContractWorker
  );
}
function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}
function* updateModalOtpWatcher() {
  yield takeLatest(updateModalOtpRequest.type, updateModalOtpWorker);
}
function* updateModalOtpLiquidWatcher() {
  yield takeLatest(
    updateModalOtpLiquidRequest.type,
    updateModalOtpLiquidWorker
  );
}

function* getCustomerRestrictionWatcher() {
  yield takeLatest(
    getCustomerRestrictionRequest.type,
    getCustomerRestrictionWorker
  );
}

function* findParameterBankDetailForPayAdvanceWatcher() {
  yield takeLatest(
    findParameterBankDetailForPayAdvanceRequest.type,
    findParameterBankDetailForPayAdvanceWorker
  );
}

function* getBankBranchCdWatcher() {
  yield takeLatest(getBankBranchCdRequest.type, getBankBranchCdWorker);
}
function* getBankBranchCd2Watcher() {
  yield takeLatest(getBankBranchCd2Request.type, getBankBranchCd2Worker);
}

function* getPayAdvanceLimitRemainWatcher() {
  yield takeLatest(
    getPayAdvanceLimitRemainRequest.type,
    getPayAdvanceLimitRemainWorker
  );
}
export default function* advanceLimitSaga() {
  yield all([
    searchAdvanceWatcher(),
    deletePayAdvanceWatcher(),
    getAmtCanReceiveWatcher(),
    getFeeAdvanceWatcher(),
    updateModalStatusWatcher(),
    updateModalOtpWatcher(),
    createAdvanceContractWatcher(),
    updateModalOtpLiquidWatcher(),
    getCustomerRestrictionWatcher(),
    findParameterBankDetailForPayAdvanceWatcher(),
    getBankBranchCdWatcher(),
    getBankBranchCd2Watcher(),
    getPayAdvanceLimitRemainWatcher(),
  ]);
}
