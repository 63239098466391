import styled from "styled-components/macro";
import BTable from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";

export const Header = styled.thead`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  color: ${({ theme }) => theme.grey30};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  margin: 0px 4px;
  height: ${({ theme }) => theme.tables.header.height};

  padding: 0px 8px;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  tr {
    height: ${({ theme }) => theme.tables.header.height};
    & > th {
      background: ${({ theme }) => theme.background3};
      position: sticky;
      top: 0;
      border: none;
      padding: 4px 8px;
      vertical-align: middle;
      :first-child {
        padding-left: 16px;
      }
      white-space: nowrap;
      z-index: 1;
    }
  }
`;

export const Body = styled.tbody`
  color: ${({ theme }) => theme.textcolorWhite};
  tr {
    box-shadow: ${({ theme }) => theme.shadowInnerDivider};
    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.background1};
    }
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.background1};
    }
    &:hover {
      background-color: ${({ theme }) => theme.background3};
    }
    & > td {
      position: relative;
      vertical-align: middle;
      border: none;
      padding: none;
    }
  }
`;

export const Footer = styled.tfoot`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  color: ${({ theme }) => theme.grey30};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  margin: 0px 4px;
  height: ${({ theme }) => theme.tables.header.height};
  background: ${({ theme }) => theme.background2};
  padding: 0px 8px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  tr {
    background: ${({ theme }) => theme.background2};
    height: ${({ theme }) => theme.tables.header.height};
    & > th {
      position: sticky;
      bottom: 0;
      border: none;
      padding: 4px 8px;
      vertical-align: middle;
      :first-child {
        padding-left: 16px;
      }
      white-space: nowrap;
    }
  }
`;

export const Summary = styled(BTable)<{ border: boolean }>`
  position: absolute;
  bottom: 0;
  margin: 0 !important;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  color: ${({ theme }) => theme.grey30};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  margin: 0px 4px;
  height: ${({ theme }) => theme.tables.header.height};
  background: ${({ theme }) => theme.bgElevated3};
  padding: 0px 8px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};

  ${({ border, theme }) =>
    border ? `border: 1px solid ${theme.borderColor};` : null}

  td, th {
    padding: 0 !important;
  }

  th {
    z-index: 1;

    & > div {
      font-size: ${({ theme }) => theme.typeFaces.caption12.size};
      font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    }
  }

  &.table-fixed-layout {
    .market-header-table {
      tr {
        ${({ theme }) =>
          `box-shadow: inset 0px -1px 0px ${theme.gradientLinearGray}`};
        & > th {
          :first-child {
            width: 50%;
          }
        }
      }
    }
    table-layout: fixed;
  }
  &.market-table {
    .ticker-code {
      height: 20px;
    }
    tr {
      ${({ theme }) => `box-shadow: inset 0px -1px 0px ${theme.shadowInner3}`};
    }
    td {
      padding: 0 4px;
    }
    th {
      padding: 0 8px;
    }
  }
`;
export const Row = styled.tr<{
  isLarge?: boolean;
  isHeightFull?: boolean;
  height?: string;
}>`
  position: relative;
  height: ${({ theme, isLarge, height }) =>
    height
      ? height
      : isLarge
      ? theme.tables.contentLarge.height
      : theme.tables.content.height};
  &:hover {
    & > .last-child {
      & > div {
        & .percent {
          display: none;
        }

        & .profit {
          display: none;
        }

        & .button {
          display: flex;
        }
      }
    }
    .action-container {
      visibility: visible;
    }
  }
  .action-container {
    width: 0px;
    position: sticky;
    height: auto;
    right: 0px;
    visibility: hidden;
    .btn_action_hover {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
      button {
        height: 100%;
        width: 100px;
      }
    }
  }

  @keyframes blink-up {
    from {
      background: ${({ theme }) => theme.secondaryGreenDisable};
    }
    to {
      background: none;
    }
  }

  @keyframes blink-down {
    from {
      background: ${({ theme }) => theme.secondaryRedDisable};
    }
    to {
      background: none;
    }
  }

  .cell-blink-up {
    color: inherit;
    text-decoration: ${({ theme }) => theme.secondaryBlueDisable};
    animation-name: blink-up;
    -webkit-animation-name: blink-up;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
  }

  .cell-blink-down {
    color: inherit;
    text-decoration: ${({ theme }) => theme.secondaryBlueDisable};
    animation-name: blink-down;
    -webkit-animation-name: blink-down;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-direction: alternate;
    -webkit-animation-direction: alternate;
  }

  .cell-blink {
    background: ${({ theme }) => theme.solidGray};
  }
`;

export const HeaderStyle = styled.div`
  span.text {
    padding-top: 3px;
    font-size: ${({ theme }) => theme.typeFaces.caption10.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
    color: ${({ theme }) => theme.grey30};
  }
`;

export const TextHeader = styled(HeaderStyle)<{ align?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  font-size: inherit;
`;

export const NumberHeader = styled(HeaderStyle)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: inherit;
`;

export const BoxTable = styled.div<{ border: boolean }>`
  ${({ border, theme }) =>
    border ? `border: 1px solid ${theme.borderColor};` : null}
`;

export const Table = styled(BTable)<{ border?: boolean }>`
  position: relative;
  z-index: 0;
  margin-bottom: 0;
  ${({ border, theme }) =>
    border ? `border: 1px solid ${theme.borderColor};` : null}

  td, th {
    padding: 0 !important;
  }

  th {
    z-index: 1;
  }

  &.table-fixed-layout {
    .market-header-table {
      tr {
        ${({ theme }) =>
          `box-shadow: inset 0px -1px 0px ${theme.gradientLinearGray}`};
        & > th {
          :first-child {
            width: 50%;
          }
        }
      }
    }
    table-layout: fixed;
  }
  &.market-table {
    .ticker-code {
      height: 20px;
    }
    tr {
      ${({ theme }) =>
        `box-shadow: inset 0px -1px 0px ${theme.shadowInnerDivider}`};
    }
    td {
      padding: 0 4px;
    }
    th {
      padding: 0 8px;
    }
  }
`;

export const TableCellContent = styled.div<{
  color?: string;
  align?: string;
  fixWidth?: number;
  isInput?: boolean;
  noWrap?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: ${({ noWrap }) => (noWrap ? "normal" : "nowrap")};
  ${({ isInput }) => (isInput ? `padding: 4px 8px;` : `padding: 0 8px;`)}
  align-content: center;
  align-items: center;
  text-align: ${({ noWrap }) => (noWrap ? "left" : "center")};
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ color, theme }) => (color ? theme[color] : "inherit")};

  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  height: ${({ theme }) => theme.tables.content.height};
  text-overflow: ellipsis;
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  &.market-ticker-name {
    padding: 0 12px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.full-company-name {
    height: 36px;
  }
  .input-edit {
    background-color: ${({ theme }) => theme.textcolorBlack};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.text};
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    text-align: right;
  }
`;

export const TableHeaderCellContent = styled.div<{
  color?: string;
  align?: string;
  isFooter?: boolean;
  fixWidth?: number;
  setHeight?: number;
}>`
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  ${({ theme, color, isFooter }) =>
    isFooter
      ? `
    color: ${color ? theme[color] : theme.textcolorWhite};
    font-weight: ${theme.typeFaces.caption10.weight.semiBold};
  `
      : ` 
    color: ${color ? theme[color] : theme.grey20};
  `}
  text-transform: uppercase;
  background: ${({ theme }) => theme.background3};
  font-size: ${({ theme, isFooter }) =>
    isFooter ? theme.typeFaces.caption12.size : theme.typeFaces.caption10.size};
  border: none;
  height: ${({ theme, isFooter }) =>
    isFooter ? theme.tables.footer.height : theme.tables.header.height};
  ${({ setHeight }) => setHeight && `height: ${setHeight}px`};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  user-select: none;
`;

export const Checkbox = styled.input.attrs((props) => {
  return { type: "checkbox", ...props };
})`
  border-radius: 50px;
  height: 20px;
  width: 30px;
  margin: auto;
  vertical-align: middle;
  text-align: center;
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(37, 49, 71, 0.8);
    border-radius: 4px;
    padding: 8px 12px;
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    z-index: 1px;
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export const NoDataContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 32px;
  color: ${({ theme }) => theme.grey30};
`;
