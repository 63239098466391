/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select } from "components/commons";
import { OptionSelectType } from "components/commons/Select";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  updateItemEdit,
  getInquiryBankUtilityRequest,
  updateModalStatusRequest,
} from "modules/hold-unhold-bank-account/redux";

import numeral from "numeral";
import { ChangeEvent, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "./ConfirmModal";
import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailWrapper,
  DetailRowPaddingTB,
  RequiredSymbol,
  DetailColFlexEnd,
  DetailRow,
  DetailRowPaddingB,
} from "./styles";
import { holdUnholdBankAccountEditSelector } from "modules/hold-unhold-bank-account/selectors";
import { IGetInquiryBankUtilityParams } from "core/http/apis/hold-unhold-bank-account/types";
import { Row, Col } from "react-bootstrap";
// import themes from "themes/abstracts/_themes";
import { toast } from "components/commons/Toast";
// import { themeTypeSelector } from "themes/redux";
import TextArea from "components/commons/Input/textArea";

const actionOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "holdUnholdBankAccount.actionList.hold",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "holdUnholdBankAccount.actionList.unhold",
    }),
  },
];

function RequestDetail() {
  const intl = useIntl();
  const dispatch = useDispatch();
  // const themeType = useSelector(themeTypeSelector);
  const listAcc = GetAccoList(GetAccountType.BANK, EntrustBusinessCode.Cash);

  const itemEdit = useSelector(holdUnholdBankAccountEditSelector);

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list.filter((i) => {
      return i.value[i.value.length - 2] === "2";
    });
  }, [listAcc]);
  const actionOptions = useMemo(() => actionOption(intl), [intl]);

  const changeSubAccountHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemEdit({ subAccoNo: item?.value, amount: "", remarks: "" })
    );
  };

  const changeActionHandler = (item: OptionSelectType | null) => {
    dispatch(updateItemEdit({ action: item && item.value ? +item.value : 1 }));
  };
  const changeAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;

    dispatch(
      updateItemEdit({ amount: convertToNumber ? +convertToNumber : "" })
    );
  };
  const changeRemarksHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateItemEdit({ remarks: e?.target?.value ?? "" }));
  };
  const clickResetHandler = () => {
    // if (accountOptions.length > 0) {
    //   dispatch(
    //     updateItemEdit({
    //       subAccoNo: accountOptions && accountOptions[0].value,
    //       action: 1,
    //       amount: "",
    //       remarks: "",
    //     })
    //   );
    // } else {
    dispatch(
      updateItemEdit({
        action: 1,
        amount: "",
        remarks: "",
      })
    );
    // }
  };
  const clickAcceptHandler = () => {
    if (itemEdit && Object.keys(itemEdit).length > 0) {
      if (
        itemEdit.amount === null ||
        itemEdit.amount === undefined ||
        itemEdit.amount === ""
      ) {
        return toast("holdUnholdBankAccount.message.amountCheck1", "error");
      } else if (itemEdit.amount === 0) {
        return toast("holdUnholdBankAccount.message.amountCheck2", "error");
      } else if (
        itemEdit.remarks === null ||
        itemEdit.remarks === undefined ||
        itemEdit.remarks === ""
      ) {
        return toast("holdUnholdBankAccount.message.remarksCheck1", "error");
      }

      const avaiHoldAmt =
        itemEdit && itemEdit.avaiableHoldAmt ? itemEdit.avaiableHoldAmt : 0;
      const avaiUnholdAmt =
        itemEdit && itemEdit.avaiableUnholdAmt ? itemEdit.avaiableUnholdAmt : 0;
      if (
        itemEdit.action &&
        itemEdit.action === 1 &&
        itemEdit.amount > avaiHoldAmt
      ) {
        return toast("holdUnholdBankAccount.message.errorHold", "error");
      } else if (
        itemEdit.action &&
        itemEdit.action === 2 &&
        itemEdit.amount > avaiUnholdAmt
      ) {
        return toast("holdUnholdBankAccount.message.errorUnHold", "error");
      }
      dispatch(
        updateModalStatusRequest({
          isVisible: true,
          subAccoNo: itemEdit?.subAccoNo
            ? itemEdit?.subAccoNo
            : accountOptions[0]?.value,
          actionType: itemEdit?.action
            ? itemEdit?.action
            : +actionOptions[0].value,
          amount: itemEdit?.amount ? +itemEdit?.amount : 0,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      updateItemEdit({
        subAccoNo: accountOptions[0]?.value,
        action: +actionOptions[0].value,
      })
    );

    const submitData: IGetInquiryBankUtilityParams = {
      subAccoNo:
        accountOptions && accountOptions.length > 0
          ? accountOptions[0].value
          : "",
      subAccCd: null,
    };
    dispatch(getInquiryBankUtilityRequest(submitData));
  }, []);
  useEffect(() => {
    if (itemEdit && itemEdit.subAccoNo) {
      const submitData: IGetInquiryBankUtilityParams = {
        subAccoNo: itemEdit?.subAccoNo ?? "",
        subAccCd: null,
      };

      dispatch(getInquiryBankUtilityRequest(submitData));
    }
  }, [itemEdit?.subAccoNo]);

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRow>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 my-auto">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.subAccount" />
                <RequiredSymbol className="text-danger pl-1 mb-0 ">
                  *
                </RequiredSymbol>
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={accountOptions[0]}
                    options={accountOptions}
                    value={accountOptions.find(
                      (item) => item.value === itemEdit?.subAccoNo
                    )}
                    onChange={changeSubAccountHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRow>

        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.bankAccoNumber" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{itemEdit ? itemEdit.bankAccoNumber : ""}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.bankName" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{itemEdit ? itemEdit.bankName : ""}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.bankBranch" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{itemEdit ? itemEdit.bankBranch : ""}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.bankAvaiable" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {itemEdit ? numeral(itemEdit.bankAvaiable).format("0,0") : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.avaiableHoldAmt" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {itemEdit
                    ? numeral(itemEdit.avaiableHoldAmt).format("0,0")
                    : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.totalUnholdAmt" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {itemEdit
                    ? numeral(itemEdit.totalUnholdAmt).format("0,0")
                    : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.avaiableUnholdAmt" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {itemEdit
                    ? numeral(itemEdit.avaiableUnholdAmt).format("0,0")
                    : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        {/* {showCombo === "true" && ( */}
        <DetailRowPaddingB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4  my-auto">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.action" />
                <RequiredSymbol className="text-danger pl-1 mb-0">
                  *
                </RequiredSymbol>
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={actionOptions[0]}
                    options={actionOptions}
                    value={actionOptions.find(
                      (item) => +item.value === itemEdit?.action
                    )}
                    onChange={changeActionHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingB>
        <DetailRowPaddingB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4  my-auto">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.amount" />
                <RequiredSymbol className="text-danger pl-1 mb-0">
                  *
                </RequiredSymbol>
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <Input
                    value={
                      itemEdit?.amount &&
                      numeral(itemEdit?.amount).format("0,0")
                    }
                    onChange={changeAmountHandler}
                    placeholder={intl.formatMessage({
                      id: "moneyTransfer.enterAmount",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingB>
        {/* )} */}
        <DetailRowPaddingB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-4 ">
              <DetailLabel>
                <FormattedMessage id="holdUnholdBankAccount.remarks" />
                <RequiredSymbol className="text-danger pl-1 mb-0">
                  *
                </RequiredSymbol>
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <TextArea
                    textArea={true}
                    value={itemEdit?.remarks ?? ""}
                    placeholder={intl.formatMessage({
                      id: "holdUnholdBankAccount.message.enterNote",
                    })}
                    onChange={changeRemarksHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingB>
      </DetailCol>
      <DetailColFlexEnd>
        <Row className=" d-flex align-content-center w-100">
          <Col sm={12} lg={6} className="w-100 p-0 px-1">
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={clickResetHandler}
              // style={{
              //   fontSize: `${themes[themeType].typeFaces.headline.size}`,
              //   padding: "18px 0px",
              // }}
            >
              {intl.formatMessage({
                id: "holdUnholdBankAccount.button.cancel",
              })}
            </Button>
          </Col>
          <Col sm={12} lg={6} className="w-100 p-0 px-1">
            <Button
              // style={{
              //   background: `${themes[themeType].buttonGradientColor}`,
              //   fontSize: `${themes[themeType].typeFaces.headline.size}`,
              //   padding: "18px 0px",
              // }}
              color="gradientaAccept"
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "holdUnholdBankAccount.button.confirm",
              })}
            </Button>
          </Col>
        </Row>
      </DetailColFlexEnd>

      <ConfirmModal
        itemEdit={itemEdit}
        accountOptions={accountOptions}
        actionOptions={actionOptions}
      />
    </DetailWrapper>
  );
}

export default RequestDetail;
