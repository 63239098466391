import { ClickableBox } from "./styles";
import React, { memo } from "react";
import { ForeignTab } from "./types";

export interface MarketWatchButtonProps {
  active: boolean;
  value: ForeignTab;
  onClick?: (value: ForeignTab) => void;
  children: React.ReactElement | React.ReactElement[] | undefined;
  tabName: string;
}

function ForeignButton({
  active,
  value,
  onClick,
  children,
  tabName,
}: MarketWatchButtonProps) {
  return (
    <ClickableBox active={active} onClick={() => onClick && onClick(value)}>
      {children}
    </ClickableBox>
  );
}

export default memo(ForeignButton);
