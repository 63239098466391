import VgaiaHttp from "core/http/singleton/vgaia";
import {
  ICancelOrdersResponse,
  ICancelRequestPendingResponse,
  IDeleteRequestCondByListResponse,
  IDeleteRequestCondResponse,
  IEditOrderResponse,
  IFindPortfolioResponse,
  IGetConditionResponse,
  IGetPriceWithStepPriceResponse,
  IPlaceOrdersConditionResponse,
  IPlaceOrdersPendingResponse,
  IPlaceOrdersResponse,
  IPrepareSubmitOrderReponse,
  IValidateCustomerInsiderResponse,
} from "domain/models/OrderBook";
// import qs from "qs";
import {
  ICancelOrdersParams,
  ICancelRequestPendingListParams,
  ICreateDertRequestCondParams,
  IDeleteRequestCondByListParams,
  IDeleteRequestCondParams,
  IDertInquiryTradingParams,
  IDertTradingGetFeeRateParams,
  IEditOrderParams,
  IFindPortfolioParams,
  IGetConditionParams,
  IGetCustomerRestrictionParams,
  IGetPriceWithStepPriceParams,
  IGetQuotesForDertOrderParams,
  IPlaceOrdersConditionParams,
  IPlaceOrdersDerParams,
  IPrepareSubmitOrderParam,
  IValidateCustomerInsiderParams,
  IValidateDertRequestCondTimeParams,
  ParamsSellAll,
  PlaceOrdersParam,
  PlaceOrdersPendingParam,
} from "./types";
import {
  cancelOrderAPI,
  portfolioOrderSellAllUrl,
  placeOrdersUrl,
  editOrderUrl,
  prepareSubmitOrderUrl,
  placeOrdersPendingUrl,
  getQuotesForDertOrderUrl,
  dertInquiryTradingUrl,
  getCustomerRestrictionUrl,
  dertTradingGetFeeRateUrl,
  dertCreateOrderUrl,
  createDertRequestCondUrl,
  cancelRequestPendingListUrl,
  placeOrdersConditionUrl,
  getConditionUrl,
  deleteRequestCondUrl,
  validateCustomerInsiderUrl,
  deleteRequestCondByListUrl,
  findPortfolioUrl,
  getPriceWithStepPriceUrl,
  validateDertRequestCondTimeUrl,
} from "./urls";
import { SellAllPortfolioResponse } from "domain/models/Portfolio";
import {
  IGetCustomerRestrictionResponse,
  IDertInquiryTradingResponse,
  IDertTradingGetFeeRateResponse,
  IGetQuotesForDertOrderResponse,
  IDertCreateOrderResponse,
  ICreateDertRequestCondResponse,
  IValidateDertRequestCondTimeResponse,
} from "domain/models/OrderBookDer";

// API check KH co bi phong toa khong
export const getCustomerRestrictionApi = async (
  params: IGetCustomerRestrictionParams
): Promise<IGetCustomerRestrictionResponse> => {
  const res = await VgaiaHttp.get<IGetCustomerRestrictionResponse>(
    getCustomerRestrictionUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// API check KH co la co dong lon, co dong noi bo khong
export const validateCustomerInsiderApi = async (
  params: IValidateCustomerInsiderParams
): Promise<IValidateCustomerInsiderResponse> => {
  const res = await VgaiaHttp.post<IValidateCustomerInsiderResponse>(
    validateCustomerInsiderUrl(params),
    null,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const prepareSubmitOrderApi = async (
  params: IPrepareSubmitOrderParam
): Promise<IPrepareSubmitOrderReponse> => {
  const res = await VgaiaHttp.post<IPrepareSubmitOrderReponse>(
    prepareSubmitOrderUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createPlaceOrder = async (
  data: PlaceOrdersParam
): Promise<IPlaceOrdersResponse> => {
  const res = await VgaiaHttp.post<IPlaceOrdersResponse>(
    placeOrdersUrl(data)
    // JSON.stringify(data),
    // { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createPlaceOrderPending = async (
  data: PlaceOrdersPendingParam
): Promise<IPlaceOrdersPendingResponse> => {
  const res = await VgaiaHttp.post<IPlaceOrdersPendingResponse>(
    placeOrdersPendingUrl(data)
    // JSON.stringify(data),
    // { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findPortfolioApi = async (
  params: IFindPortfolioParams
): Promise<IFindPortfolioResponse> => {
  const res = await VgaiaHttp.get<IFindPortfolioResponse>(
    findPortfolioUrl(params)
    // JSON.stringify(data),
    // { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getPriceWithStepPriceApi = async (
  params: IGetPriceWithStepPriceParams
): Promise<IGetPriceWithStepPriceResponse> => {
  const res = await VgaiaHttp.get<IGetPriceWithStepPriceResponse>(
    getPriceWithStepPriceUrl(params)
    // JSON.stringify(data),
    // { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getConditionApi = async (
  params: IGetConditionParams
): Promise<IGetConditionResponse> => {
  const res = await VgaiaHttp.post<IGetConditionResponse>(
    getConditionUrl(params)
    // JSON.stringify(data),
    // { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createPlaceOrderCondition = async (
  params: IPlaceOrdersConditionParams
): Promise<IPlaceOrdersConditionResponse> => {
  const res = await VgaiaHttp.post<IPlaceOrdersConditionResponse>(
    placeOrdersConditionUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const editOrderApi = async (
  params: IEditOrderParams
): Promise<IEditOrderResponse> => {
  const res = await VgaiaHttp.post<IEditOrderResponse>(
    editOrderUrl,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cancelOrderList = async (
  params: ICancelOrdersParams
): Promise<ICancelOrdersResponse> => {
  const res = await VgaiaHttp.post<ICancelOrdersResponse>(
    cancelOrderAPI(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// OrderBook --- start ---

export const cancelRequestPendingListApi = async (
  params: ICancelRequestPendingListParams
): Promise<ICancelRequestPendingResponse> => {
  const res = await VgaiaHttp.post<ICancelRequestPendingResponse>(
    cancelRequestPendingListUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteRequestCondApi = async (
  params: IDeleteRequestCondParams
): Promise<IDeleteRequestCondResponse> => {
  const res = await VgaiaHttp.post<IDeleteRequestCondResponse>(
    deleteRequestCondUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteRequestCondByListApi = async (
  params: IDeleteRequestCondByListParams
): Promise<IDeleteRequestCondByListResponse> => {
  const res = await VgaiaHttp.post<IDeleteRequestCondByListResponse>(
    deleteRequestCondByListUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// OrderBook --- end ---

export const sellAllPortfolio = async (
  data: ParamsSellAll
): Promise<SellAllPortfolioResponse> => {
  const res = await VgaiaHttp.post<SellAllPortfolioResponse>(
    portfolioOrderSellAllUrl,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Place order der

export const getQuotesForDertOrderApi = async (
  params: IGetQuotesForDertOrderParams
): Promise<IGetQuotesForDertOrderResponse> => {
  const res = await VgaiaHttp.get<IGetQuotesForDertOrderResponse>(
    getQuotesForDertOrderUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const dertInquiryTradingApi = async (
  params: IDertInquiryTradingParams
): Promise<IDertInquiryTradingResponse> => {
  const res = await VgaiaHttp.get<IDertInquiryTradingResponse>(
    dertInquiryTradingUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const dertTradingGetFeeRateApi = async (
  params: IDertTradingGetFeeRateParams
): Promise<IDertTradingGetFeeRateResponse> => {
  const res = await VgaiaHttp.get<IDertTradingGetFeeRateResponse>(
    dertTradingGetFeeRateUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const validateDertRequestCondTimeApi = async (
  params: IValidateDertRequestCondTimeParams
): Promise<IValidateDertRequestCondTimeResponse> => {
  const res = await VgaiaHttp.post<IValidateDertRequestCondTimeResponse>(
    validateDertRequestCondTimeUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const dertCreateOrderApi = async (
  params: IPlaceOrdersDerParams
): Promise<IDertCreateOrderResponse> => {
  const res = await VgaiaHttp.post<IDertCreateOrderResponse>(
    dertCreateOrderUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createDertRequestCondApi = async (
  params: ICreateDertRequestCondParams
): Promise<ICreateDertRequestCondResponse> => {
  const res = await VgaiaHttp.post<ICreateDertRequestCondResponse>(
    createDertRequestCondUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
