import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const BtnType = styled.div<{
  isActive?: boolean;
}>`
  width: 310px;
  height: 48px;
  background-color: ${({ theme }) => theme.brandSecondary};
  margin: auto;
  color: ${({ theme }) => theme.grey0};
  text-align: center;
  line-height: 48px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.brandPrimary : theme.brandSecondary};
  :hover {
    background-color: ${({ theme }) => theme.brandSecondary};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;
