import CommonTableInfinity from "components/commons/CommonTableInfinity";

import {
  listBondOrderSelector,
  itemDetailSelector,
} from "modules/bid-ask-bond/selectors";

import { BondOrder } from "domain/models/BidAskBond";

import { memo, useMemo, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ActionsRow,
  ButtonCell,
  ButtonConfirm,
  ButtonCancel,
  ButtonSell,
} from "./styles";
import numeral from "numeral";
import { convertNumberToDateFormat } from "helper/utils";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";

import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { updateModalOtpRequest } from "modules/advance/redux";

import {
  updateModalStatusRequest,
  searchBondOrderRequest,
  updateFilter,
  enterBondOrderRequest,
  rejectBondOrderRequest,
} from "modules/bid-ask-bond/redux";

import { isShowOtpModalSelector } from "modules/advance/selectors";
import {
  filterBondOrderSelector,
  statusEnterSelector,
  statusRejectSelector,
} from "modules/bid-ask-bond/selectors";

import { ISearchBondOrderParams } from "core/http/apis/bid-ask-bond/types";
import storage from "helper/storage";
import { toast } from "components/commons/Toast";
import DetailModal from "./DetailModal/index";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";
// import { MarginDebitContractWatch } from "domain/models/ContractDebit";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listBondOrderSelector);
  const themeType = useSelector(themeTypeSelector);
  const itemDetailRow = useSelector(itemDetailSelector);

  const isVisible = useSelector(isShowOtpModalSelector);

  const { secCdBond } = useSelector(filterBondOrderSelector);
  const center = "center";
  const flexEnd = "flex-end";
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [itemSelected, setItemSelected] = useState<BondOrder | undefined>(
    undefined
  );

  const statusEnter = useSelector(statusEnterSelector);
  const statusReject = useSelector(statusRejectSelector);
  useEffect(() => {
    if (statusEnter) {
      const submitData: ISearchBondOrderParams = {
        tradeType: 0,
        status: -1,
        type: 2,
        getDataType: 2,
        custNo: storage.getMasterAccount() ?? "",
        bondCd:
          secCdBond ===
          intl.formatMessage({
            id: "bidAskBond.all",
          })
            ? ""
            : secCdBond
            ? secCdBond
            : "",
      };
      dispatch(searchBondOrderRequest(submitData));
      dispatch(
        updateFilter({
          key: "tradeType",
          value: 0,
        })
      );
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
      toast("bidAskBond.message.successAlert", "success");
    }
  }, [statusEnter]);

  useEffect(() => {
    if (statusReject) {
      const submitData: ISearchBondOrderParams = {
        tradeType: 0,
        status: -1,
        type: 2,
        getDataType: 2,
        custNo: storage.getMasterAccount() ?? "",
        bondCd:
          secCdBond ===
          intl.formatMessage({
            id: "bidAskBond.all",
          })
            ? ""
            : secCdBond
            ? secCdBond
            : "",
      };
      dispatch(searchBondOrderRequest(submitData));
      dispatch(
        updateFilter({
          key: "tradeType",
          value: 0,
        })
      );
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
      toast("bidAskBond.message.successAlert2", "success");
    }
  }, [statusReject]);
  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    if (itemSelected) {
      dispatch(
        enterBondOrderRequest({
          id: itemSelected && itemSelected.id ? itemSelected.id : 0,

          timeStamp:
            itemSelected && itemSelected.timeStamp
              ? itemSelected.timeStamp
              : "",
          otpCode: response?.otp ?? "",
        })
      );
    }
  };

  const setConfirm = () => {
    if (itemSelected) {
      dispatch(
        rejectBondOrderRequest({
          id: itemSelected && itemSelected.id ? itemSelected.id.toString() : "",

          updDateTime:
            itemSelected && itemSelected.updDateTime
              ? itemSelected.updDateTime
              : 0,
        })
      );
    }
  };
  function changeColor(value: string, value2: number) {
    if (value2 === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].red50}>
          <FormattedMessage id="bidAskBond.table.sell" />
        </TableCellContent>
      );
    else if (value2 === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].green50}>
          <FormattedMessage id="bidAskBond.table.buy" />
        </TableCellContent>
      );
    else if (value2 === 0)
      return (
        <TableCellContent align="center" color={themes[themeType].grey50}>
          <FormattedMessage id="bidAskBond.table.unknown" />
        </TableCellContent>
      );
    else if (value2 === 3)
      return (
        <TableCellContent align="center" color={themes[themeType].red50}>
          <FormattedMessage id="bidAskBond.table.sellMortage" />
        </TableCellContent>
      );

    return "";
  }

  function changeColor2(value: number, value2: string) {
    if (value === 0)
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="bidAskBond.status.wait_approve" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskBond.status.confirmed" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskBond.status.complete" />
          {/* {value2} */}
        </TableCellContent>
      );
    // else if (value === 4)
    //   return (
    //     <TableCellContent align="center" color={themes[themeType].solidRed}>
    //       <FormattedMessage id="bidAskBond.status.rejected" />
    //       {/* {value2} */}
    //     </TableCellContent>
    //   );
    else if (value === 9)
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="bidAskBond.status.cancel" />
          {/* {value2.slice(2)} */}
        </TableCellContent>
      );

    return "";
  }
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBond.table.account" />
          </HeaderCell>
        ),
        accessor: "custNo",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBond.table.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBond.table.tradeType" />
          </HeaderCell>
        ),
        accessor: "tradeTypeDisp",
        minWidth: 80,
        Cell: (props: any) => {
          const row = props?.row.original;

          return (
            <TableCellContent align={center}>
              {changeColor(props.value.slice(2), row.tradeType)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBond.table.qty" />
          </HeaderCell>
        ),
        accessor: "qty",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBond.table.amount" />
          </HeaderCell>
        ),
        accessor: "price",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBond.table.referencePrices" />
          </HeaderCell>
        ),
        accessor: "priceEst",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBond.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,
        Cell: (props: any) => {
          const row = props?.row.original;
          return (
            <TableCellContent align={center}>
              {changeColor2(props.value, row.statusDisp)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBond.table.expiratedDate" />
          </HeaderCell>
        ),
        accessor: "expiredDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBond.table.action" />
          </HeaderCell>
        ),
        accessor: "action",
        minWidth: 120,
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              <ActionsRow>
                {currentRow.status === 0 && currentRow.tradeType === 2 && (
                  <>
                    <ButtonCell>
                      <ButtonSell onClick={() => clickHandler(currentRow)}>
                        <FormattedMessage id="bidAskBond.table.sell" />
                      </ButtonSell>
                    </ButtonCell>
                    |
                    <ButtonCell>
                      <ButtonCancel
                        onClick={() => clickCancelHandler(currentRow)}
                      >
                        <FormattedMessage id="transferBond.table.cancel" />
                      </ButtonCancel>
                    </ButtonCell>
                  </>
                )}
                {currentRow.status === 0 && currentRow.tradeType === 1 && (
                  <>
                    <ButtonCell>
                      <ButtonConfirm onClick={() => clickHandler(currentRow)}>
                        <FormattedMessage id="bidAskBond.table.buy" />
                      </ButtonConfirm>
                    </ButtonCell>
                    |
                    <ButtonCell>
                      <ButtonCancel
                        onClick={() => clickCancelHandler(currentRow)}
                      >
                        <FormattedMessage id="transferBond.table.cancel" />
                      </ButtonCancel>
                    </ButtonCell>
                  </>
                )}
              </ActionsRow>
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};
  const clickHandler = (row: BondOrder) => {
    setItemSelected(row);
    dispatch(
      updateModalStatusRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
  };
  const clickCancelHandler = (item: BondOrder) => {
    setItemSelected(item);
    setVisibleModalConfirm(true);
  };

  return (
    <Container id="bidAskBondTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"bidAskBondTable"}
        isHasFooter={false}
      />

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "bidAskBond.message.cancelContent",
        })}
      />
      {itemDetailRow && <DetailModal itemDetailRow={itemDetailRow} />}
      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </Container>
  );
}

export default memo(RequestTable);
