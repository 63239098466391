import { Button, Input, Spacer } from "components/commons";
import { useRef, useState } from "react";
import classNames from "classnames";
import { ItemCustom, WatchListCustomDropDown } from "./styles";
import { ReactComponent as IconCancel } from "assets/image/svg/ic_cancel_watchlist.svg";
import { ReactComponent as IconCheck } from "assets/image/svg/ic_check_watchlist.svg";
import { ReactComponent as IconEdit } from "assets/image/svg/ic_edit_watchlist.svg";
import { ReactComponent as IconPlus } from "assets/image/svg/ic_plus.svg";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash_watchlist.svg";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { WatchListCustomProps } from "modules/categories/types";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { toast } from "components/commons/Toast";
import { themeTypeSelector } from "themes/redux";
import { currentListCodeSelector } from "modules/categories/redux/selectors";

function WatchListCustom({
  watchlists,
  isOpen,
  isShowTitle,
  isReadOnly,
  onRemove,
  onEdit,
  onChangeList,
  onCreate,
  isFavoriteList,
}: WatchListCustomProps) {
  const intl = useIntl();
  const themeType = useSelector(themeTypeSelector);
  const currentListCode = useSelector(currentListCodeSelector);

  const editRef = useRef<HTMLInputElement>(null);
  const createRef = useRef<HTMLInputElement>(null);
  const [currentEdit, setCurrentEdit] = useState("");
  const [isCreate, setIsCreate] = useState(false);

  if (!isOpen) return null;

  const handleRemove = (index: number) => (e: any) => {
    e.stopPropagation();
    onRemove(watchlists[index]);
  };
  const handleEdit = (name: string) => (e: any) => {
    e.stopPropagation();
    setCurrentEdit(name);
  };

  const submitEdit = (index: number) => (e: any) => {
    e.stopPropagation();
    const nameEdited = editRef.current?.value;
    if (!nameEdited) {
      toast("category.errors.required", "error");
      return;
    }

    if (watchlists.some((item) => item.name === nameEdited)) {
      toast("category.errors.duplicatedName", "error");
      return;
    }

    setCurrentEdit("");
    onEdit(index, nameEdited);
  };

  const toggleCreate = () => {
    setIsCreate((prev) => !prev);
  };

  const handleCreate = () => {
    if (!createRef.current?.value) {
      toast("category.errors.required", "error");
      return;
    }
    if (watchlists.some((item) => item.nameVi === createRef.current?.value)) {
      toast("category.errors.duplicatedName", "error");
      return;
    }
    if (createRef.current?.value === "Owner") {
      toast("category.errors.sameOwnerName", "error");
      return;
    }

    onCreate(createRef.current.value);
    toggleCreate();
  };

  const keyDownInputHandler = (e: any) => {
    if (e.code === "Enter" || e.keyCode === 13) {
      handleCreate();
    }
  };

  return (
    <WatchListCustomDropDown
      className="menu"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isShowTitle && (
        <div className="title">
          <FormattedMessage id="category.custom.title" />
        </div>
      )}

      {(watchlists.length > 0 || isCreate) && <Spacer />}
      <div
        className={classNames(
          { "clear-border": watchlists.length === 0 && !isCreate },
          "custom"
        )}
      >
        {watchlists.map((item, index) => (
          <ItemCustom
            isActive={item.name === currentListCode}
            key={item.name}
            onClick={onChangeList(item.name, isFavoriteList)}
          >
            {item.name === currentEdit ? (
              <div className="d-flex align-items-center">
                <Input
                  innerRef={editRef}
                  sizeInput="m"
                  defaultValue={item.nameVi}
                />
              </div>
            ) : (
              <div className="name">{item.nameVi}</div>
            )}

            {currentEdit === item.name ? (
              <div className="icon-box">
                <div className="icon" onClick={submitEdit(index)}>
                  <IconCheck />
                </div>
                <div className="icon" onClick={handleEdit("")}>
                  <IconCancel />
                </div>
              </div>
            ) : (
              !isReadOnly && (
                <div className="icon-box">
                  <div
                    className="icon showWhenHover"
                    onClick={handleEdit(item.name)}
                  >
                    <IconEdit />
                  </div>
                  <div
                    className="icon showWhenHover"
                    onClick={handleRemove(index)}
                  >
                    <IconTrash />
                  </div>
                </div>
              )
            )}
          </ItemCustom>
        ))}
        {isCreate && (
          <div className="d-flex align-items-center p8">
            <Input
              placeholder={intl.formatMessage({
                id: "category.custom.nameCategory",
              })}
              autoFocus
              innerRef={createRef}
              sizeInput="m"
              radius={4}
              onKeyDown={keyDownInputHandler}
            />
            <Spacer />
            <Button radius={4} onClick={toggleCreate} size="m" color="accept">
              <FormattedMessage id="category.custom.cancel" />
            </Button>
            <Spacer />
            <Button
              radius={4}
              fill
              onClick={handleCreate}
              className="w140"
              size="m"
              color="accept"
            >
              <FormattedMessage id="category.custom.confirm" />
            </Button>
          </div>
        )}
      </div>
      {!isReadOnly && <Spacer />}

      {!isReadOnly && (
        <Button
          radius={4}
          size="m"
          fill
          color="accept"
          onClick={() => {
            if (isCreate) return;
            setIsCreate(true);
          }}
        >
          <IconPlus fill={themes[themeType].textcolorWhite} />
          <Spacer size="xs" />
          <div className="addWatchlist" onClick={toggleCreate}>
            <FormattedMessage id="category.custom.addCategory" />
          </div>
        </Button>
      )}
    </WatchListCustomDropDown>
  );
}

export default WatchListCustom;
