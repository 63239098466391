import React, { useState } from "react";
import { NotificationGroupByDate, Date } from "./styles";
import { NotificationListData } from "modules/auth/types";
import Notification from "./NotificatonItem";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";

interface Props {
  data: NotificationListData;
  fetchMoreData: () => void;
  onDelete: (date?: string, noticeId?: string) => void;
  isLoadingFetchMore: boolean;
}

function NotificationList({
  data,
  fetchMoreData,
  onDelete,
  isLoadingFetchMore,
}: Props) {
  const [lengthData, setLengthData] = useState(0);
  useEffect(() => {
    let length = 0;
    data.items.map((item) => (length = length + item.items.length));
    setLengthData(length);
  }, [data]);

  return (
    <React.Fragment>
      <InfiniteScroll
        dataLength={lengthData}
        next={fetchMoreData}
        hasMore={true}
        height={isLoadingFetchMore ? 460 : 480}
        loader={<div></div>}
      >
        {data.items.map((item) => {
          const date = moment(item.date, "YYYYMMDD").format("DD/MM/YYYY");
          return (
            <NotificationGroupByDate>
              <Date>{date}</Date>
              <Notification data={item.items} onDelete={onDelete} />
            </NotificationGroupByDate>
          );
        })}
      </InfiniteScroll>
    </React.Fragment>
  );
}
export default React.memo(NotificationList);
