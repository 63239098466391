import {
  CardStock,
  Under,
  Blue,
  NameIndex,
  RowCardStock,
  RowChangeStock,
  RowCardStockBottom,
} from "./styles";
import NumberChange from "./NumberChangeDer/numberChange";
import QtyChange from "./NumberChangeDer/qtyChange";
import AmtChange from "./NumberChangeDer/amtChange";
import SessionName from "./NumberChange/sessionName";
import ChartHeader from "./chartHeader";
import { FormattedMessage } from "react-intl";
import { VisibleIndex } from "helper/consts";
import { useDispatch, useSelector } from "react-redux";
import { setVisibleIndexDetailsModal } from "modules/system/redux/headerCard";
import {
  valueHeaderCardDerSelector,
  visibleSideBarSelector,
} from "modules/system/selectors";
import ArrowIndex from "./NumberChangeDer/arrowIndex";

function Card() {
  const dispatch = useDispatch();
  const displaySideBarIndex = useSelector(visibleSideBarSelector);
  const tickerName = useSelector(valueHeaderCardDerSelector("seccd")) as string;
  const ClickIndex = () => {
    dispatch(setVisibleIndexDetailsModal(tickerName));
  };
  return (
    <CardStock>
      <RowCardStock
        showChart={displaySideBarIndex % 3 !== VisibleIndex.HIDDEN_CHART}
      >
        <ChartHeader ticker={tickerName} />
      </RowCardStock>

      <RowCardStockBottom>
        <NameIndex onClick={ClickIndex}>{tickerName}</NameIndex>
        <RowChangeStock>
          <div className="d-flex">
            <ArrowIndex />
            <NumberChange fieldValue="lastprice" />
          </div>
          <div className="d-flex">
            <NumberChange fieldValue="changepoint" />
            &nbsp;
            <NumberChange fieldValue="changepercent" isPercentage={true} />
          </div>
        </RowChangeStock>
      </RowCardStockBottom>
      <RowCardStockBottom>
        <Under>
          <QtyChange />
          <Blue>
            <FormattedMessage id="common.CP" />
          </Blue>
          <AmtChange />
          <Blue>
            <FormattedMessage id="common.unit" />
          </Blue>
        </Under>
        <Under>
          <SessionName ticker="500" fieldValue="state" />
        </Under>
      </RowCardStockBottom>
    </CardStock>
  );
}

export default Card;
