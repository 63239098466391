import { Button, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import { OptionSelectType } from "components/commons/Select";
import {
  searchStatisticHighestUpdownRequest,
  updateFilter,
} from "modules/statistic-highest-updown/redux";
import {
  statisticHighestUpdownFilterSelector,
  statisticHighestUpdownLoadingSelector,
} from "modules/statistic-highest-updown/selectors";
import { memo, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const marketOptionsIntl = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.all",
    }),
  },
  {
    value: "100",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.hsx",
    }),
  },
  {
    value: "200",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.hnx",
    }),
  },
  {
    value: "300",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.upcom",
    }),
  },
  {
    value: "400",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.dccny",
    }),
  },
  {
    value: "500",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.der",
    }),
  },
  {
    value: "600",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.marketCd.otc",
    }),
  },
];

const typeOptionsIntl = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.type.up",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "statisticHighestUpdown.filter.type.down",
    }),
  },
];

const distanceOptionsIntl = (intl: IntlShape) => [
  {
    value: "5",
    label: "5",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "60",
    label: "60",
  },
  {
    value: "90",
    label: "90",
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { distance, marketCd, type } = useSelector(
    statisticHighestUpdownFilterSelector
  );
  const loading = useSelector(statisticHighestUpdownLoadingSelector);

  // memo
  const marketOptions = useMemo(() => marketOptionsIntl(intl), [intl]);
  const typeOptions = useMemo(() => typeOptionsIntl(intl), [intl]);
  const distanceOptions = useMemo(() => distanceOptionsIntl(intl), [intl]);

  // function
  const changeMarketHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "marketCd", value: e?.value ?? "0" }));
  };

  const changeTypeHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "type", value: e?.value ?? "1" }));
  };

  const changeDistanceHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "distance", value: e?.value ?? "5" }));
  };

  const handleSearch = () => {
    dispatch(
      searchStatisticHighestUpdownRequest({
        marketCd: marketCd ? +marketCd : 0,
        distance: distance ? +distance : 5,
        type: type ? +type : 1,
      })
    );
  };

  // effect

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "statisticHighestUpdown.filter.marketCd.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={marketOptions[0]}
            options={marketOptions}
            onChange={changeMarketHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "statisticHighestUpdown.filter.type.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={typeOptions[0]}
            options={typeOptions}
            onChange={changeTypeHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "statisticHighestUpdown.filter.distance.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={distanceOptions[0]}
            options={distanceOptions}
            onChange={changeDistanceHandler}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "statisticHighestUpdown.filter.search" })}
        </Button>

        <Button color="accept" className="button-filter">
          {intl.formatMessage({ id: "statisticHighestUpdown.exportExcel" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
