import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import RowStock from "./RowBidAsk";
import { MrktAdvItem } from "domain/protoNew/auto_trading_pb";

export type GridTableBodyProps = {
  putThroughList: MrktAdvItem.AsObject[];
  isMatched?: boolean;
};

function GridRow({ putThroughList, isMatched = false }: GridTableBodyProps) {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemSize={40}
          itemCount={putThroughList?.length || 0}
          width={width}
          style={{ overflowX: "visible" }}
        >
          {({ index, style }) => {
            const putThroughData = putThroughList[index];
            return (
              <RowStock
                putThroughData={putThroughData!}
                key={index}
                style={style}
                rowNumber={index}
              />
            );
          }}
        </List>
      )}
    </AutoSizer>
  );
}

export default GridRow;
