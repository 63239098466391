import queryString from "query-string";
import {
  IFindPortTransactionParams,
  IGetPflTPortTransactionParams,
} from "./types";

export const findPortTransactionApi = (params: IFindPortTransactionParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findPortTransaction${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getPflTPortTransactionApi = (
  params: IGetPflTPortTransactionParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getPflTPortTransaction${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
