import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TopStatisticalMenu } from "components/widgets/generals/TopStatistical/types";
import { Dictionary } from "lodash";
import { SORT_BY } from "components/widgets/generals/TopStatistical/constant";
import {
  LastSale,
  ORDER_TYPE,
  ORDER_TYPEMap,
  TopSecChangedResponse,
} from "domain/protoNew/auto_trading_pb";
import { TOP_PRICE_LOADING_LIMIT } from "AppConfig";

export interface IActiveSort {
  sortBy: SORT_BY;
  order: ORDER_TYPEMap[keyof ORDER_TYPEMap];
}

export function getSortByActiveTab(value: TopStatisticalMenu) {
  switch (value) {
    case "topVolume":
      return SORT_BY.TOP_VALUE_MASS;
    case "topValue":
      return SORT_BY.TOP_VALUE;
    case "topChange":
      return SORT_BY.HIGH_LOW;
    default:
      return SORT_BY.TOP_VALUE_MASS;
  }
}

export type TopStatisticalState = {
  activeTab: TopStatisticalMenu;
  data: Dictionary<TopSecChangedResponse.TopSecChangedInfo.AsObject>;
  loading: boolean;
  error: string | undefined;
  tickerList: string[];
  activeSort: IActiveSort;
  limit: number;
  enableLoadMore: boolean;
};

const initialState: TopStatisticalState = {
  activeTab: "topVolume" as TopStatisticalMenu,
  data: {},
  loading: false,
  error: undefined,
  tickerList: [],
  activeSort: {
    sortBy: SORT_BY.TOP_VALUE_MASS,
    order: ORDER_TYPE.ORDER_DEFAULT,
  },
  limit: TOP_PRICE_LOADING_LIMIT,
  enableLoadMore: true,
};

const topStatistical = createSlice({
  name: "topStatisticalSlice",
  initialState,
  reducers: {
    cleanData: (state) => {
      return initialState;
    },
    requestChangeTopStatisticalType: (
      state,
      action: PayloadAction<TopStatisticalMenu>
    ) => {
      state.activeTab = action.payload;
      state.loading = true;
      state.data = {};
      state.error = undefined;
      state.enableLoadMore = true;
      state.tickerList = [];
      state.activeSort = {
        sortBy: getSortByActiveTab(action.payload),
        order: ORDER_TYPE.ORDER_DEFAULT,
      };
    },
    changeStatisticalTypeSuccess: (
      state,
      action: PayloadAction<TopSecChangedResponse.TopSecChangedInfo.AsObject[]>
    ) => {
      state.data = action.payload.reduce(
        (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
        {}
      );
      state.limit = TOP_PRICE_LOADING_LIMIT;
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;
      state.loading = false;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    changeStatisticalFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    requestTopStatistical: (state) => {},
    requestTopStatisticalSuccess: (
      state,
      action: PayloadAction<TopSecChangedResponse.TopSecChangedInfo.AsObject[]>
    ) => {
      state.data = action.payload.reduce(
        (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
        {}
      );
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    updateTicker: (state, action: PayloadAction<LastSale.AsObject>) => {
      const { payload } = action;
      const ticker = state.data[payload.seccd];

      if (!!ticker) {
        ticker.totalqty = payload.totalqty;
        ticker.totalamt = payload.totalamt;
        ticker.closeprice = payload.lastprice;
        ticker.changepoint = payload.changepoint;
        ticker.changepercent = payload.changepercent;
        ticker.colorcode = payload.colorcode;
      }
    },
    changeActiveSort: (state, action: PayloadAction<IActiveSort>) => {
      state.activeSort = action.payload;
      state.loading = true;
      state.data = {};
      state.error = undefined;
      state.tickerList = [];
      state.enableLoadMore = true;
    },
    requestLoadMore: (state) => {},
    requestLoadMoreSuccess: (
      state,
      action: PayloadAction<TopSecChangedResponse.TopSecChangedInfo.AsObject[]>
    ) => {
      state.limit = state.limit + TOP_PRICE_LOADING_LIMIT;
      state.data = {
        ...state.data,
        ...action.payload.reduce(
          (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
          {}
        ),
      };
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
  },
});

export const {
  cleanData,
  requestChangeTopStatisticalType,
  changeStatisticalTypeSuccess,
  changeStatisticalFailure,
  updateTicker,
  changeActiveSort,
  requestTopStatistical,
  requestTopStatisticalSuccess,
  requestLoadMore,
  requestLoadMoreSuccess,
} = topStatistical.actions;

export default topStatistical.reducer;
