import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled.div`
  background-color: ${({ theme }) => theme.grey90};
  text-align: center;
  padding: 10px;
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .content {
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.grey30};
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonExit = styled.button`
  align-items: center;
  justify-content: flex-end;
  background: transparent;
`;

export const Body = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.background2};
  table {
    margin-bottom: 10px;
  }
`;

export const TextLine13RegulartextcolorBlackLeft = styled.span`
  display: flex;
  align-items: center;
  float: left;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TextLine13RegularsecondaryWhiteRight = styled.span<{
  textInput?: boolean;
}>`
  float: right;
  ${({ textInput = false }) => (textInput ? "width: 50%;" : null)}
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TextLine = styled.div<{
  colorChildBlue?: boolean;
}>`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 34px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  ${({ colorChildBlue = false }) =>
    colorChildBlue ? "cursor: pointer;" : null}
  span {
    color: ${({ theme, colorChildBlue = false }) =>
      colorChildBlue && theme.solidBlue};
  }
  .icon:hover {
    path {
      fill: ${({ theme }) => theme.brandPrimary};
    }
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  button {
    max-width: 50%;
  }
`;
