import { BrowserRouter as Router, Switch } from "react-router-dom";
import AppRoute from "router/AppRoute";
import Routes from "router/routes";
import Layout from "components/layouts/Main";
import { memo } from "react";

function AppRouter() {
  return (
    <Router>
      <Switch>
        {/* Page ko có header, sideBar thì đặt ở đây */}
        {/* <Route path="abc" component={NotFoundPage} /> */}
        <Layout>
          {Routes.map((route) => {
            return <AppRoute key={route.path} {...route} />;
          })}
        </Layout>
      </Switch>
    </Router>
  );
}

export default memo(AppRouter);
