import { toast } from "components/commons/Toast";
import {
  calculateBondFee,
  getBondsAvailSell,
  getCustomer2,
  getListTransferBond,
  getSubAccoNo,
} from "core/http/apis/place-order-transfer-bonds";
import {
  IAddFrameworkContractForm,
  IGetAccount,
} from "domain/models/AddFrameworkContractForm";
import { checkTimeExecute } from "./../../core/http/apis/place-order-transfer-bonds/index";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  calculateBondFeeFailure,
  calculateBondFeeRequest,
  calculateBondFeeSuccess,
  checkTimeExecuteFailure,
  checkTimeExecuteRequest,
  checkTimeExecuteSuccess,
  getAccountFailure,
  getAccountRequest,
  getAccountSuccess,
  getBondsAvailSellFailure,
  getBondsAvailSellRequest,
  getBondsAvailSellSuccess,
  getListTransferBondFailure,
  getListTransferBondRequest,
  getListTransferBondSuccess,
  getSubAccoNoFailure,
  getSubAccoNoRequest,
  getSubAccoNoSuccess,
  searchTransferBondFailure,
  searchTransferBondRequest,
  searchTransferBondSuccess,
} from "./redux";

function* getAccountWorker(action: ReturnType<typeof getAccountRequest>) {
  try {
    const params: IGetAccount = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      getCustomer2,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getAccountSuccess(response));
    } else {
      yield put(getAccountFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getAccountFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBondsAvailSellWorker(
  action: ReturnType<typeof getBondsAvailSellRequest>
) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      getBondsAvailSell,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getBondsAvailSellSuccess(response));
    } else {
      yield put(getBondsAvailSellFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBondsAvailSellFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListTransferBondWorker(
  action: ReturnType<typeof getListTransferBondRequest>
) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      getListTransferBond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getListTransferBondSuccess(response));
    } else {
      yield put(getListTransferBondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListTransferBondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchTransferBondWorker(
  action: ReturnType<typeof searchTransferBondRequest>
) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      getListTransferBond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchTransferBondSuccess(response));
    } else {
      yield put(searchTransferBondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferBondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* calculateBondFeeWorker(
  action: ReturnType<typeof calculateBondFeeRequest>
) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      calculateBondFee,
      params
    );

    if (response?.statusCode === 0) {
      yield put(calculateBondFeeSuccess(response));
    } else {
      yield put(calculateBondFeeFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(calculateBondFeeFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* checkTimeExecuteWorker(
  action: ReturnType<typeof checkTimeExecuteRequest>
) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      checkTimeExecute,
      params
    );

    if (response?.statusCode === 0) {
      yield put(checkTimeExecuteSuccess(response));
    } else {
      yield put(checkTimeExecuteFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(checkTimeExecuteFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getSubAccoNoWorker(action: ReturnType<typeof getSubAccoNoRequest>) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      getSubAccoNo,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getSubAccoNoSuccess(response));
    } else {
      yield put(getSubAccoNoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getSubAccoNoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getAccountWatcher() {
  yield takeLatest(getAccountRequest.type, getAccountWorker);
}
function* getBondsAvailSellWatcher() {
  yield takeLatest(getBondsAvailSellRequest.type, getBondsAvailSellWorker);
}
function* getListTransferBondWatcher() {
  yield takeLatest(getListTransferBondRequest.type, getListTransferBondWorker);
}
function* searchTransferBondWatcher() {
  yield takeLatest(searchTransferBondRequest.type, searchTransferBondWorker);
}
function* calculateBondFeeWatcher() {
  yield takeLatest(calculateBondFeeRequest.type, calculateBondFeeWorker);
}
function* checkTimeExecuteWatcher() {
  yield takeLatest(checkTimeExecuteRequest.type, checkTimeExecuteWorker);
}
function* getSubAccoNoWatcher() {
  yield takeLatest(getSubAccoNoRequest.type, getSubAccoNoWorker);
}

export default function* placeOrderTransferBondsSaga() {
  yield all([
    getAccountWatcher(),
    getBondsAvailSellWatcher(),
    getListTransferBondWatcher(),
    searchTransferBondWatcher(),
    calculateBondFeeWatcher(),
    checkTimeExecuteWatcher(),
    getSubAccoNoWatcher(),
  ]);
}
