import { toast } from "components/commons/Toast";
import {
  createContract,
  getContract,
  getCustomer2,
} from "core/http/apis/add-framework-constract-form";
import {
  IAddFrameworkContractForm,
  IGetAccount,
  IGetContract,
} from "domain/models/AddFrameworkContractForm";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createContractFailure,
  createContractRequest,
  createContractSuccess,
  getAccountFailure,
  getAccountRequest,
  getAccountSuccess,
  getContractFailure,
  getContractRequest,
  getContractSuccess,
  searchContractFailure,
  searchContractRequest,
  searchContractSuccess,
} from "./redux";

function* getAccountWorker(action: ReturnType<typeof getAccountRequest>) {
  try {
    const params: IGetAccount = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      getCustomer2,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getAccountSuccess(response));
    } else {
      yield put(getAccountFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getAccountFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* createContractWorker(
  action: ReturnType<typeof createContractRequest>
) {
  try {
    const params: any = action.payload;
    const response: IAddFrameworkContractForm = yield call(
      createContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(createContractSuccess(response));
    } else {
      yield put(createContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(createContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getContractWorker(action: ReturnType<typeof getContractRequest>) {
  try {
    const params: IGetContract = action.payload;
    const response: IAddFrameworkContractForm = yield call(getContract, params);

    if (response?.statusCode === 0) {
      yield put(getContractSuccess(response));
    } else {
      yield put(getContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchContractWorker(
  action: ReturnType<typeof searchContractRequest>
) {
  try {
    const params: IGetContract = action.payload;
    const response: IAddFrameworkContractForm = yield call(getContract, params);

    if (response?.statusCode === 0) {
      yield put(searchContractSuccess(response));
    } else {
      yield put(searchContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getAccountWatcher() {
  yield takeLatest(getAccountRequest.type, getAccountWorker);
}

function* createContractWatcher() {
  yield takeLatest(createContractRequest.type, createContractWorker);
}

function* getContractWatcher() {
  yield takeLatest(getContractRequest.type, getContractWorker);
}

function* searchContractWatcher() {
  yield takeLatest(searchContractRequest.type, searchContractWorker);
}

export default function* addFrameworkContractFormSaga() {
  yield all([
    getAccountWatcher(),
    createContractWatcher(),
    getContractWatcher(),
    searchContractWatcher(),
  ]);
}
