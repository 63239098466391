import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISearchStatisticHighestUpdownParams } from "core/http/apis/statistic-highest-updown/types";
import {
  IMarketStatistic,
  IMarketStatisticResponse,
} from "domain/models/MarketStatistic";
import { logOutSuccess } from "modules/auth/redux";
import { STATISTIC_HIGHEST_UPDOWN_TABS } from "./constants";

interface IFilter {
  type?: string;
  marketCd?: string;
  distance?: string;
}

export interface ReduxData {
  filter: IFilter;
  list: IMarketStatistic[];
  loading: boolean;
  activeTab: string;
}

const initialState: ReduxData = {
  filter: {
    type: "1",
    marketCd: "0",
    distance: "5",
  },
  list: [],
  loading: false,
  activeTab: STATISTIC_HIGHEST_UPDOWN_TABS.STATISTIC_HIGHEST_UPDOWN,
};

const statisticHighestUpdownSlice = createSlice({
  name: "statisticHighestUpdownSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchStatisticHighestUpdownRequest: (
      state,
      action: PayloadAction<ISearchStatisticHighestUpdownParams>
    ) => {
      state.loading = true;
    },
    searchStatisticHighestUpdownSuccess: (
      state,
      action: PayloadAction<IMarketStatisticResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchStatisticHighestUpdownFailure: (state) => {
      state.loading = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchStatisticHighestUpdownFailure,
  searchStatisticHighestUpdownRequest,
  searchStatisticHighestUpdownSuccess,
  updateFilter,
} = statisticHighestUpdownSlice.actions;

export default statisticHighestUpdownSlice.reducer;
