import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import MrgContractDebit from "./pages/MrgContractDebit";

const routes: RouterConfig[] = [
  {
    page: MrgContractDebit,
    path: paths.mrgContractDebit,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
