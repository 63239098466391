import React, { memo } from "react";
import { v4 as uuidv4 } from "uuid";
import { ContentBox } from "./style";

export type Checked = true | false;

interface Props {
  checked?: Checked;
  onChange?: () => void;
  disabled?: boolean;
  loading: boolean;
}

function Switch({
  checked = false,
  onChange,
  disabled = false,
  loading,
}: Props) {
  const id = uuidv4();

  const handleChange = () => {
    onChange && onChange();
  };

  return (
    <ContentBox loading={loading} disabled={disabled}>
      <input
        type="checkbox"
        id={id}
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
      />
      <label htmlFor={id}>
        <div className="loading-holder">
          <div className="spinner"></div>
        </div>
      </label>
    </ContentBox>
  );
}

export default memo(Switch);
