import { useSelector } from "react-redux";
import numeral from "numeral";
import HorizontalBarChart from "components/commons/Charts/HorizontalBarChart";
import themes from "themes/abstracts/_themes";
import {
  createGradientPlugin,
  createVN30IndexAxisPlugin,
  ChartAreaBorderPlugin,
} from "helper/chartPlugins";
import { ChartData } from "modules/tickerDetails/redux/VN30StatBasketContribution";

import {
  ContentContainer,
  HorizontalBarChartWrapper,
  MainContent,
} from "./styles";
import { themeTypeSelector } from "themes/redux";

interface VN30StatBasketContributionProps {
  data: ChartData;
}

const GradientBgPlugin: any = createGradientPlugin("rgba(51, 110, 186, 0.1)");

function VN30StatBasketContribution({ data }: VN30StatBasketContributionProps) {
  const themeType = useSelector(themeTypeSelector);

  const VN30IndexAxisPlugin: any = createVN30IndexAxisPlugin(themes[themeType]);

  const plugins = [
    VN30IndexAxisPlugin,
    ChartAreaBorderPlugin,
    GradientBgPlugin,
  ];

  const option = {
    plugins: {
      chartAreaBorder: {
        borderColor: themes[themeType].border1,
        borderWidth: 1,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          return `${numeral(tooltipItem.value).format("0.00")}%`;
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          position: "top",
          gridLines: {
            color: "transparent",
            display: true,
            zeroLineColor: themes[themeType].border1,
            zeroLineWidth: 2,
            drawTicks: false,
          },
          ticks: {
            fontColor: "transparent",
            fontSize: 10,
            display: true,
            padding: 8,
          },
        },
      ],
      yAxes: [
        {
          categoryPercentage: 2 / 3,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: themes[themeType].grey40,
            fontSize: 10,
          },
        },
      ],
    },
  };
  return (
    <ContentContainer>
      {/* <HeaderSection>
        <FormattedMessage id="widgets.vn30Index.title" />
      </HeaderSection> */}
      <MainContent>
        <HorizontalBarChartWrapper>
          <HorizontalBarChart
            dataChart={data}
            option={option}
            plugins={plugins}
          />
        </HorizontalBarChartWrapper>
      </MainContent>
    </ContentContainer>
  );
}

export default VN30StatBasketContribution;
