import { FC } from "react";
import { useIntl } from "react-intl";
import Container from "components/commons/Container";
import ConfirmOrderOnline from "./components/ConfirmOrderOnline";

const ProfitLoss: FC = () => {
  const intl = useIntl();

  const arrayForm = [ConfirmOrderOnline];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "confirmationOrder.title",
      })}
      headerForm={intl.formatMessage({
        id: "confirmationOrder.title",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default ProfitLoss;
