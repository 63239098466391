/* eslint-disable react-hooks/exhaustive-deps */
import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  CountDown,
  WrapDeteil,
  WrapInput,
  Wraplabel,
} from "./style";
import { Button, Input } from "components/commons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import { updateStep } from "modules/auth/redux/modalRegister";
import {
  authenOtpValidateApi,
  createCustomerOnlineApi,
  getAuthenOTPApi,
} from "core/http/apis/modal-register";
import {
  selectParams,
  // selectInformationExtraction,
} from "modules/auth/selectorsModalRegister";
import { toast } from "components/commons/Toast";

const DEFAULT_TIMER = 120;
const StepOTP = () => {
  const intl = useIntl();
  const allParam: any = 120;
  const dispatch = useDispatch();
  const paramsForm = useSelector(selectParams);
  // const InformationExtraction = useSelector(selectInformationExtraction);

  const [loading, setloading] = useState(false);
  const [seconds, setSeconds] = useState(
    allParam?.SMS_OTP_TIME_OUT ?? DEFAULT_TIMER
  );

  const clickResendCodeHandler = () => {
    callgetAuthenOTP();
    setSeconds(allParam?.SMS_OTP_TIME_OUT ?? DEFAULT_TIMER);
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const [sessionOtp, setsessionOtp] = useState();

  const callgetAuthenOTP = async () => {
    setloading(true);
    const data = {
      mobileNo: paramsForm?.mobileNo,
      clientInfo: "web",
    };
    const res: any = await getAuthenOTPApi(data);
    if (res?.statusCode !== 0) {
      toast(res?.errorCode, "error", res?.message, true);
      setloading(false);
    } else {
      setsessionOtp(res?.data);
      setloading(false);
    }
  };

  useEffect(() => {
    callgetAuthenOTP();
  }, []);

  const [otp, setotp] = useState("");

  const callAuthenOtpValidateApi = async () => {
    const data = {
      otpCode: otp,
      mobileNo: paramsForm?.mobileNo,
      clientInfo: "web",
      sessionId: sessionOtp,
    };
    const res: any = await authenOtpValidateApi(data);
    if (res?.statusCode === 0) {
      const respon: any = await createCustomerOnlineApi(paramsForm);
      if (respon?.statusCode === 0) {
        toast("auth.register.messageSuccess", "success");
        dispatch(updateStep(STEPS.STEPS_11));
      } else {
        toast(respon?.errorCode, "error", respon?.message, true);
      }
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  return (
    <Container>
      <Wrap>
        <WrapDeteil>
          <FormattedMessage id={"auth.register.stepOTP.title"} />
        </WrapDeteil>
        <WrapDeteil className="mb-0">
          <FormattedMessage id={"auth.register.stepOTP.content"} />
        </WrapDeteil>
        <WrapDeteil>
          <FormattedMessage
            id={"auth.register.stepOTP.content2"}
            values={{
              time: <CountDown>{seconds}</CountDown>,
            }}
          />
        </WrapDeteil>

        <Wraplabel>
          <FormattedMessage id={"auth.register.stepOTP.labelQuestion"} />
        </Wraplabel>
        <WrapInput>
          <Input
            placeholder={intl.formatMessage({
              id: "auth.register.stepOTP.placehoder",
            })}
            defaultValue={otp}
            onChange={(e: any) => setotp(e?.target?.value)}
          />
          <Button
            color="accept"
            className="button-filter ml-2"
            width="120px"
            onClick={() => clickResendCodeHandler()}
            loading={loading}
          >
            {intl.formatMessage({
              id: "auth.register.stepOTP.btnOTP",
            })}
          </Button>
        </WrapInput>
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_9))}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter"
          width="120px"
          onClick={() => callAuthenOtpValidateApi()}
        >
          {intl.formatMessage({
            id: "auth.register.btnNext",
          })}
        </Button>
      </div>
    </Container>
  );
};

export default StepOTP;
