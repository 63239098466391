import { createSelector } from "@reduxjs/toolkit";
import { DATE_TIME_FORMAT } from "helper/consts";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import moment from "moment";
import { useSelector } from "react-redux";
import { TextCell } from "../styles";

interface Props {
  ticker: string;
}

const lasttradedateSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.lasttradedate
  );
};

const renderDateValue = (date?: number) => {
  if (!date) {
    return;
  }
  return moment(date, DATE_TIME_FORMAT.YYYYMMDD).format(
    DATE_TIME_FORMAT.DDMMYYYY
  );
};
function LasttradedateCell({ ticker }: Props) {
  const lasttradedate = useSelector(lasttradedateSelector(ticker));

  return (
    <TextCell className="text-right">{renderDateValue(lasttradedate)}</TextCell>
  );
}

export default LasttradedateCell;
