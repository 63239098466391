import { TopPricePeriod } from "components/widgets/generals/TopPrice/types";

export function getEPeriod(value: TopPricePeriod) {
  switch (value) {
    case "today":
      return 1;
    case "oneWeek":
      return 2;
    case "oneMonth":
      return 3;
    case "threeMonths":
      return 4;
    case "sixMonths":
      return 5;
    case "oneYear":
      return 6;
    default:
      return 1;
  }
}
