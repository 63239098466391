import { tradeDateSelector } from "modules/auth/selectors";
import { onChangeExpiredDate } from "modules/stock-order/redux/placeOrder";
import moment from "moment";
import { memo, useEffect } from "react";
import { IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PriorityOrder from "./PriorityOrder";
import StopOrder from "./StopOrder";
import TimeConditionOrder from "./TimeConditionOrder";
import TrailingStopOrder from "./TrailingStopOrder";

export const methods = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.generatedOneTime",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.matchingEnoughVolume",
    }),
  },
];

type Props = {
  conditionalCommandType: any;
  ticker: string;
  onSubmit: () => void;
};

const ConditionCommandForm: React.FC<Props> = memo(
  ({ conditionalCommandType, ticker, onSubmit }) => {
    const dispatch = useDispatch();
    const tradeDate = useSelector(tradeDateSelector);

    const render = (type: any) => {
      switch (type) {
        case "TCO":
          return <TimeConditionOrder />;
        case "TS":
          return <TrailingStopOrder ticker={ticker} onSubmit={onSubmit} />;
        case "PRO":
          return <PriorityOrder />;
        case "SP":
        case "CL":
          return <StopOrder ticker={ticker} onSubmit={onSubmit} />;
        default:
          return null;
      }
    };

    useEffect(() => {
      dispatch(onChangeExpiredDate(moment(tradeDate, "YYYYMMDD").toDate()));
    }, [conditionalCommandType]);

    return (
      <>
        <div className="px-3">{render(conditionalCommandType)}</div>
      </>
    );
  }
);

export default ConditionCommandForm;
