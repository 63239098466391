import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetBondOrderParams,
  IEditBidAskBondOrderParams,
  IGetBondListParams,
  IGetCustomer2Params,
  ICancelBondOrderParams,
  IFindCbotBondPriceEstParams,
  IFindCbotBondInfoParams,
  IInquiryBuyingPowerParams,
  IGetBondAvaiSellParams,
  ICalculateBondFeeParams,
  ICalculateTaxAmtParams,
  IPlaceBondOrderParams,
} from "core/http/apis/bid-ask-bond-order/types";
import {
  CboBondOrder,
  IGetBondOrderResponse,
  IGetCustomer2Response,
  Bond,
  OrderBond,
  CbotBondPriceEst,
  IGetBondListResponse,
  ICancelBondOrderResponse,
  IFindCbotBondPriceEstResponse,
  IFindCbotBondInfoResponse,
  IInquiryBuyingPowerResponse,
  IGetBondAvaiSellResponse,
  ICalculateBondFeeResponse,
  ICalculateTaxAmtResponse,
  IPlaceBondOrderResponse,
} from "domain/models/BidAskBondOrder";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  status: number;

  secCdBond: string;
}

export interface ReduxData {
  filter: IFilter;
  list: CboBondOrder[];
  loading: boolean;
  activeTab: string;
  listBond: Bond[];
  listBondOrder: OrderBond[];
  bidAskBondOrder?: IEditBidAskBondOrderParams;
  statusDelete: boolean;
  listCbotBondPriceEst: CbotBondPriceEst[];

  statusPlaceBondOrder: boolean;
  activeButton: string;
}

const initialState: ReduxData = {
  filter: {
    status: -1,

    secCdBond: "",
  },
  list: [],
  loading: false,
  activeTab: "BID_ASK_BOND_ORDER",
  listBond: [],
  listCbotBondPriceEst: [],
  listBondOrder: [],
  bidAskBondOrder: {
    contraAccount: "",
    contraAccountName: "",
    amount: 0,
    qty: 0,
    secCdBond: "",

    feePayer: 1,
    taxPayer: 1,
    expiratedDate: new Date(),
    maxPrice: 0,
    purchaseAbility: 0,
    referencePrice: 0,
    fee: 0,
    orderPrice: 0,
    tax: 0,
    issuerBond: "",
    price: 0,
    releaseDate: 0,
    coupon: 0,
    expiratedDate2: 0,
    period: 0,
    description: "",
  },

  statusDelete: false,
  statusPlaceBondOrder: false,
  activeButton: "2",
};

const bidAskBondOrderSlice = createSlice({
  name: "bidAskBondOrderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    getBondOrderRequest: (
      state,
      action: PayloadAction<IGetBondOrderParams>
    ) => {
      state.loading = true;
    },
    getBondOrderSuccess: (
      state,
      action: PayloadAction<IGetBondOrderResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    getBondOrderFailure: (state) => {
      state.loading = false;
    },

    getCustomer2Request: (
      state,
      action: PayloadAction<IGetCustomer2Params>
    ) => {
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        contraAccountName: ``,
      };
    },
    getCustomer2Success: (
      state,
      action: PayloadAction<IGetCustomer2Response>
    ) => {
      if (
        action.payload &&
        action.payload.data &&
        Object.keys(action.payload.data).length > 0
      )
        state.bidAskBondOrder = {
          ...state.bidAskBondOrder,
          contraAccountName: `${action.payload.data.custFamilyName} ${action.payload.data.custGivenName}`,
        };
    },
    getCustomer2Failure: (state) => {
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        contraAccountName: ``,
      };
    },

    inquiryBuyingPowerRequest: (
      state,
      action: PayloadAction<IInquiryBuyingPowerParams>
    ) => {},
    inquiryBuyingPowerSuccess: (
      state,
      action: PayloadAction<IInquiryBuyingPowerResponse>
    ) => {
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        purchaseAbility: action.payload.data.buyingPower ?? 0,
      };
    },
    inquiryBuyingPowerFailure: (state) => {},

    getBondAvaiSellRequest: (
      state,
      action: PayloadAction<IGetBondAvaiSellParams>
    ) => {},
    getBondAvaiSellSuccess: (
      state,
      action: PayloadAction<IGetBondAvaiSellResponse>
    ) => {
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        maxPrice:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].availQty
            ? action.payload.data[0].availQty
            : 0,
      };
    },
    getBondAvaiSellFailure: (state) => {},

    calculateBondFeeRequest: (
      state,
      action: PayloadAction<ICalculateBondFeeParams>
    ) => {},
    calculateBondFeeSuccess: (
      state,
      action: PayloadAction<ICalculateBondFeeResponse>
    ) => {
      // console.log(action.payload);
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        fee: action.payload.data ?? 0,
      };
    },
    calculateBondFeeFailure: (state) => {},

    calculateTaxAmtRequest: (
      state,
      action: PayloadAction<ICalculateTaxAmtParams>
    ) => {},
    calculateTaxAmtSuccess: (
      state,
      action: PayloadAction<ICalculateTaxAmtResponse>
    ) => {
      // console.log(action.payload);
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        tax: action.payload.data ?? 0,
      };
    },
    calculateTaxAmtFailure: (state) => {},
    getBondListRequest: (
      state,
      action: PayloadAction<IGetBondListParams>
    ) => {},
    getBondListSuccess: (
      state,
      action: PayloadAction<IGetBondListResponse>
    ) => {
      state.listBond = action.payload.data ?? [];
    },
    getBondListFailure: (state) => {},

    findCbotBondInfoRequest: (
      state,
      action: PayloadAction<IFindCbotBondInfoParams>
    ) => {},
    findCbotBondInfoSuccess: (
      state,
      action: PayloadAction<IFindCbotBondInfoResponse>
    ) => {
      state.listBondOrder = action.payload.data ?? [];
    },
    findCbotBondInfoFailure: (state) => {},

    findCbotBondInfoDetailRequest: (
      state,
      action: PayloadAction<IFindCbotBondInfoParams>
    ) => {},
    findCbotBondInfoDetailSuccess: (
      state,
      action: PayloadAction<IFindCbotBondInfoResponse>
    ) => {
      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        issuerBond:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].bondName
            ? action.payload.data[0].bondName
            : "",
        price:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].faceValPrice
            ? action.payload.data[0].faceValPrice
            : 0,
        releaseDate:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].reissueDate
            ? action.payload.data[0].reissueDate
            : 0,
        expiratedDate2:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].dueDate
            ? action.payload.data[0].dueDate
            : 0,
        coupon:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].interestRate
            ? action.payload.data[0].interestRate * 100
            : 0,
        period:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].periodPayInterest
            ? action.payload.data[0].periodPayInterest
            : 0,
        description:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].remarks
            ? action.payload.data[0].remarks
            : "",
      };
    },
    findCbotBondInfoDetailFailure: (state) => {},

    findCbotBondPriceEstRequest: (
      state,
      action: PayloadAction<IFindCbotBondPriceEstParams>
    ) => {},
    findCbotBondPriceEstSuccess: (
      state,
      action: PayloadAction<IFindCbotBondPriceEstResponse>
    ) => {
      state.listCbotBondPriceEst = action.payload.data ?? [];
    },
    findCbotBondPriceEstFailure: (state) => {},
    cancelBondOrderRequest: (
      state,
      action: PayloadAction<ICancelBondOrderParams>
    ) => {
      state.statusDelete = false;
    },
    cancelBondOrderSuccess: (
      state,
      action: PayloadAction<ICancelBondOrderResponse>
    ) => {
      state.statusDelete = true;
    },
    cancelBondOrderFailure: (state) => {
      state.statusDelete = false;
    },
    placeBondOrderRequest: (
      state,
      action: PayloadAction<IPlaceBondOrderParams>
    ) => {
      state.statusPlaceBondOrder = false;
    },
    placeBondOrderSuccess: (
      state,
      action: PayloadAction<IPlaceBondOrderResponse>
    ) => {
      state.statusPlaceBondOrder = true;
    },
    placeBondOrderFailure: (state) => {
      state.statusPlaceBondOrder = false;
    },
    updateButtonActive: (state, action: PayloadAction<string>) => {
      state.activeButton = action.payload;
    },
    updateItemEdit: (
      state,
      action: PayloadAction<IEditBidAskBondOrderParams | undefined>
    ) => {
      if (!action.payload) {
        state.bidAskBondOrder = undefined;
        return;
      }

      state.bidAskBondOrder = {
        ...state.bidAskBondOrder,
        ...action.payload,
      };
    },
    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  getBondOrderFailure,
  getBondOrderRequest,
  getBondOrderSuccess,
  getBondListRequest,
  getBondListSuccess,
  getBondListFailure,
  findCbotBondPriceEstRequest,
  findCbotBondPriceEstSuccess,
  findCbotBondPriceEstFailure,

  getCustomer2Request,
  getCustomer2Success,
  getCustomer2Failure,
  cancelBondOrderRequest,
  cancelBondOrderSuccess,
  cancelBondOrderFailure,
  findCbotBondInfoRequest,
  findCbotBondInfoSuccess,
  findCbotBondInfoFailure,
  findCbotBondInfoDetailRequest,
  findCbotBondInfoDetailSuccess,
  findCbotBondInfoDetailFailure,
  inquiryBuyingPowerRequest,
  inquiryBuyingPowerSuccess,
  inquiryBuyingPowerFailure,
  getBondAvaiSellRequest,
  getBondAvaiSellSuccess,
  getBondAvaiSellFailure,
  calculateBondFeeRequest,
  calculateBondFeeSuccess,
  calculateBondFeeFailure,

  calculateTaxAmtRequest,
  calculateTaxAmtSuccess,
  calculateTaxAmtFailure,
  placeBondOrderRequest,
  placeBondOrderSuccess,
  placeBondOrderFailure,
  updateItemEdit,
  updateFilter,
  updateButtonActive,
} = bidAskBondOrderSlice.actions;

export default bidAskBondOrderSlice.reducer;
