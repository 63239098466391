import { Body, Container, Title, Footer } from "./styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalLogin } from "modules/auth/redux";
import { setVisibleModalConfirmOpenLogin } from "modules/system/redux/common";
import { visibleModalConfirmOpenLoginSelector } from "modules/system/selectors";

function ModalConfirm() {
  const dispatch = useDispatch();
  const visibleModal = useSelector(visibleModalConfirmOpenLoginSelector);
  const handleConfirm = () => {
    dispatch(toggleModalLogin(true));
    dispatch(setVisibleModalConfirmOpenLogin(false));
  };

  return (
    <>
      <Modal
        show={visibleModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-284"
      >
        <Container>
          <Title>
            <FormattedMessage id="extends.confirmationOrderOnline.modalMessage.title" />
          </Title>
          <Body>
            <FormattedMessage id="common.expiredToken" />
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={() => dispatch(setVisibleModalConfirmOpenLogin(false))}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={handleConfirm}
              className="flex-1"
              fill
              color="accept"
            >
              <FormattedMessage id="common.accept" />
            </Button>
          </Footer>
        </Container>
      </Modal>
    </>
  );
}

export default ModalConfirm;
