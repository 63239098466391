import numeral from "numeral";
interface Props {
  volume: number;
}

function Volume({ volume }: Props) {
  return (
    <div className="volume">
      {volume > 0 && numeral(volume * 10).format("0,000")}
    </div>
  );
}

export default Volume;
