import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const guideVideoSelector = (state: RootState) => state.guideVideo;

export const activeTabGuideVideoSelector = createSelector(
  guideVideoSelector,
  (state) => state.activeTab
);

export const btnActiceGuideVideoSelector = createSelector(
  guideVideoSelector,
  (state) => state.btnActive
);

export const listCategorySelector = createSelector(
  guideVideoSelector,
  (state) => state.listCategory
);

export const listArticleSelector = createSelector(
  guideVideoSelector,
  (state) => state.listArticle
);

export const offsetGuideVideoSelector = createSelector(
  guideVideoSelector,
  (state) => state.offset
);

export const limitGuideVideoSelector = createSelector(
  guideVideoSelector,
  (state) => state.limit
);

export const enableInfinitySelector = createSelector(
  guideVideoSelector,
  (state) => state.enableInfinity
);
