import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";
import { getListDeposit } from "modules/deposit-list/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  ColorGreen,
  ColorRed,
  Container,
  HeaderCell,
  TableCellContent,
} from "./styles";

function Table() {
  const data = useSelector(getListDeposit);

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="depositListPMoney.branchCD" />
          </HeaderCell>
        ),
        minWidth: 160,
        accessor: "branchCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="depositListPMoney.transactionCd" />
          </HeaderCell>
        ),
        minWidth: 200,
        accessor: "transactionCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="depositListPMoney.dateCreate" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="depositListPMoney.subAccoNo" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "subAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="depositListPMoney.amount" />
          </HeaderCell>
        ),
        minWidth: 189.6,
        accessor: "amount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="depositListPMoney.interestRate" />
          </HeaderCell>
        ),
        minWidth: 189.6,
        accessor: "interestRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="depositListPMoney.daysNo" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "daysNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="depositListPMoney.interestAmt" />
          </HeaderCell>
        ),
        minWidth: 189.6,
        accessor: "interestAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="depositListPMoney.taxRate" />
          </HeaderCell>
        ),
        minWidth: 189.6,
        accessor: "taxRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="depositListPMoney.taxAmt" />
          </HeaderCell>
        ),
        minWidth: 189.6,
        accessor: "taxAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="depositListPMoney.payedDate" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "payedDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"left"}>
            <FormattedMessage id="depositListPMoney.contractSts" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "contractSts",
        Cell: (props: any) => {
          const contractStsVal = props?.row?.original?.contractStsVal;
          return (
            <TableCellContent align={"left"}>
              {contractStsVal === "1" ? (
                <ColorGreen>
                  <FormattedMessage id="depositListPMoney.inEffectName" />
                </ColorGreen>
              ) : (
                <ColorRed>
                  <FormattedMessage id="depositListPMoney.liquidatedName" />
                </ColorRed>
              )}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="depositListTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? data : []}
        enableInfinity={true}
        scrollableTarget={"depositListTable"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
