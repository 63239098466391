import { ReactComponent as IconLock } from "assets/image/svg/ic_lock.svg";
import { ReactComponent as IconLogout2 } from "assets/image/svg/ic_logout2.svg";
import { ReactComponent as IconUserInfo2 } from "assets/image/svg/ic_userInfo2.svg";
import { ReactComponent as IconLogo } from "assets/image/svg/VGaiaLogo.svg";
import { ReactComponent as IconSearch } from "assets/image/svg/ic_header_search.svg";
import { ReactComponent as IconLanguage } from "assets/image/svg/ic_language.svg";
import { ReactComponent as Support } from "assets/image/svg/support.svg";
import {
  Button,
  HorizoltalDivider,
  ModalSuccess,
  Spacer,
} from "components/commons";
import ModalConfirmClearAll from "components/commons/ModalConfirmCleanAll";

import Profile from "components/layouts/Main/components/Header/profile";
import {
  EMarketCodeNew,
  SecType,
  TabSetting,
  VisibleIndex,
} from "helper/consts";
import Storage from "helper/storage";
import ModalAccountInfo from "modules/auth/pages/ModalAccountInfo";
import ModalChangePasswordSuccess from "modules/auth/pages/ModalChangePassword/ModalChangePasswordSuccess";
import ModalNotification from "modules/auth/pages/ModalNotification";
import ModalSetting from "modules/auth/pages/ModalSetting";
import ModalSupport from "modules/system/ModalSupport";
import {
  logOutStart,
  setTabActiveSetting,
  setvisibleModalEditPassFirst,
  setvisibleModalInfo,
  setvisibleModalSetting,
  toggleModalLogin,
} from "modules/auth/redux";
import {
  isAuthenticatedSelector,
  selectUserInfo,
} from "modules/auth/selectors";
import TickerDetailsModal from "modules/tickerDetails/pages/TickerDetails";
import { memo, useEffect, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  ButtonPopover,
  Content,
  HeaderCard,
  HeaderMidCard,
  HeaderRightCard,
  Icon,
  NotiCard,
  Setting,
} from "./styles";
import usePageTracking from "./usePageTracking";
import ModalLogin from "modules/auth/pages/ModalLogin";
import ModalRegister from "modules/auth/pages/ModalRegister";
import ModalConfirmOpenLogin from "components/commons/ModalConfirmOpenLogin";
import paths from "helper/pathRoutes";
import { LoginRequired, VersionWeb } from "AppConfig";
import SideBar from "../SideBar";
import SelectTicker from "modules/categories/pages/Categories/components/Table/Header/SelectTicker";
import { tickerListInCategorySelector } from "modules/categories/redux/selectors";
import GlobalData from "helper/globalData";
import { setVisibleTickerDetailsModal } from "modules/market/redux";
import ModalForgetPassword2 from "modules/auth/pages/ModalForgetPassword2";
import { ReactComponent as IconTypeShow } from "assets/image/svg/TypeShow.svg";
import { ReactComponent as IconTypeHide } from "assets/image/svg/TypeHide.svg";
import { visibleSideBarSelector } from "modules/system/selectors";
import {
  setVisibleModalSupport,
  setVisibleSideBar,
} from "modules/system/redux/common";
import ModalEditPassFirst from "modules/auth/pages/ModalEditPassFirst";
import { selectVisibleModalEditPassFirst } from "modules/auth/selectors";
import { useEffectOnce } from "react-use";
import { RealtimeConst } from "helper/constFirebase";
import {
  fromSelector,
  selectedTickerSelector,
  visibleTickerDetailsModalSelector,
} from "modules/market/selectors";
import { changeVisibleDerivative } from "modules/categories/redux/derivative";

const renderModalType = (tickerType?: number, tickerMarketCd?: number) => {
  if (tickerMarketCd && tickerMarketCd === EMarketCodeNew.DER_IDX) {
    return "derivative";
  } else {
    if (tickerType === SecType.CW) {
      return "coverWarrant";
    } else {
      return "default";
    }
  }
};

function Header() {
  usePageTracking();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const visibleTickerDetailsModal = useSelector(
    visibleTickerDetailsModalSelector
  );
  const fromDerivativeTicker = useSelector(fromSelector);
  const selectedTicker = useSelector(selectedTickerSelector);
  const tickersNew = useSelector(tickerListInCategorySelector);
  const displaySideBarIndex = useSelector(visibleSideBarSelector);
  const userInfo = useSelector(selectUserInfo);
  const visible = useSelector(selectVisibleModalEditPassFirst);

  const masterAccount = Storage.getMasterAccount();

  useEffect(() => {
    if (
      masterAccount &&
      (userInfo?.changePassFlag === 1 || userInfo?.changePinFlag === 1)
    ) {
      dispatch(setvisibleModalEditPassFirst());
    }
  }, [masterAccount]);

  useEffectOnce(() => {
    const resLogData = {
      event: RealtimeConst.enterApp,
      appVersion: VersionWeb,
    };
    Storage.appStateFirebase(resLogData);
    return () => {
      const resLogData = {
        event: RealtimeConst.exitApp,
      };
      Storage.appStateFirebase(resLogData);
    };
  });

  const onLogOut = () => {
    dispatch(
      logOutStart({
        refresh_token: Storage.getRefreshToken(),
        code_verifier: Storage.getChallengeCodeVerifier(),
      })
    );
  };

  const [showPop, setShowPop] = useState(false);
  const [target, setTarget] = useState(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refInputSearch = useRef<HTMLInputElement>(null);
  const refInputNotification = useRef<HTMLInputElement>(null);
  const refInputSetting = useRef<HTMLInputElement>(null);
  const [showPopSearch, setShowPopSearch] = useState(false);
  const [targetSearch, setTargetSearch] = useState(null);

  const onClickPop = (e: any) => {
    if (!masterAccount) {
      Storage.logOut();
      return;
    }
    setTimeout(() => {
      setShowPop(!showPop);
      setTarget(e.target);
    }, 150);

    if (!showPop) {
      refInput.current?.focus();
    } else {
      refInputNotification.current?.focus();
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(paths.categories);
      if (LoginRequired === true) {
        dispatch(toggleModalLogin(true));
      }
    }
  }, [isAuthenticated]);

  const handleMouseLeave = () => {
    setShowPop(false);
    setTarget(null);
  };

  const handleBlurPop = () => {
    setShowPop(false);
    setTarget(null);
  };

  const toggleModalChangePassword = () => {
    dispatch(setTabActiveSetting(TabSetting.SETTING_PASS));
    dispatch(setvisibleModalSetting());
  };

  const toggleModalChangeLanguage = () => {
    dispatch(setTabActiveSetting(TabSetting.GENERAL));
    dispatch(setvisibleModalSetting());
  };

  const toggleModalSupport = () => {
    dispatch(setVisibleModalSupport(true));
  };

  const toggleModalInfo = () => {
    dispatch(setvisibleModalInfo());
  };

  const onAddTicker = (ticker: string) => {
    const tickerInfo = GlobalData.getTickerInfoNew(ticker);
    const tickerType = tickerInfo?.sectype;
    const tickerMarketCd = tickerInfo?.marketcd;

    dispatch(
      setVisibleTickerDetailsModal({
        visible: true,
        ticker,
        modalType: renderModalType(tickerType, tickerMarketCd),
      })
    );
  };

  const onClickPopSearch = (e: any) => {
    setTimeout(() => {
      setShowPopSearch(!showPopSearch);
      setTargetSearch(e.target);
    }, 150);
    setShowPop(false);
    setTarget(null);
    if (!showPopSearch) {
      refInputSearch.current?.focus();
    } else {
      refInputNotification.current?.focus();
    }
  };

  const handleBlurPopSearch = () => {
    setShowPopSearch(false);
    setTargetSearch(null);
  };

  return (
    <HeaderCard>
      <ModalConfirmClearAll />
      <ModalLogin />
      <ModalForgetPassword2 />
      <ModalRegister />
      {visible && <ModalEditPassFirst />}

      <ModalSuccess />
      <Link to="/">
        <IconLogo />
      </Link>
      <HorizoltalDivider className="mx-2 py-2" />
      {/* <HeaderMidCard>{renderStocks()}</HeaderMidCard> */}
      <HeaderMidCard>
        <SideBar />
      </HeaderMidCard>
      <HeaderRightCard>
        <div className="pointer">
          <IconSearch onClick={onClickPopSearch} />
          <input className="input-focus" ref={refInputSearch} />
          <Overlay
            show={showPopSearch}
            target={targetSearch}
            placement="bottom"
          >
            <Popover
              className="bg-transparent popover-search"
              id="popover-basic"
            >
              <Content className="rounded-top" isDisablePadding isSearch>
                <div className="select-tickers">
                  <SelectTicker
                    tickers={tickersNew}
                    onAddTicker={onAddTicker}
                    handleBlurPopSearch={handleBlurPopSearch}
                    showPopSearch={showPopSearch}
                    isSearchHeader
                  />
                </div>
              </Content>
            </Popover>
          </Overlay>
        </div>
        <Spacer size="s" />
        <Setting className="pointer" onClick={handleMouseLeave}>
          <ModalSetting />
          <input className="input-focus" ref={refInputSetting} />
        </Setting>
        <Spacer size="s" />
        {isAuthenticated ? (
          <>
            <NotiCard className="pointer" onClick={handleMouseLeave}>
              <ModalNotification />
              <input className="input-focus" ref={refInputNotification} />
            </NotiCard>

            <div
              className="d-flex align-items-center pointer"
              onClick={onClickPop}
            >
              <Profile />
              <input
                className="input-focus"
                ref={refInput}
                onBlur={() => {
                  setTimeout(() => handleBlurPop(), 150);
                }}
              />
              <Overlay
                show={showPop}
                target={target}
                placement="bottom"
                rootClose
              >
                <Popover className="bg-transparent" id="popover-basic">
                  <Content
                    className="rounded-top"
                    onMouseDown={toggleModalInfo}
                  >
                    <ButtonPopover>
                      <Icon>
                        <IconUserInfo2 />
                      </Icon>
                      <Spacer size="s" />
                      <div className="title">
                        {intl.formatMessage({ id: "auth.info.title" })}
                      </div>
                    </ButtonPopover>
                  </Content>

                  <Content onMouseDown={toggleModalChangePassword}>
                    <ButtonPopover>
                      <Icon>
                        <IconLock />
                      </Icon>
                      <Spacer size="s" />
                      <div className="title">
                        {intl.formatMessage({ id: "auth.changePassword" })}
                      </div>
                    </ButtonPopover>
                  </Content>

                  <Content onMouseDown={toggleModalChangeLanguage}>
                    <ButtonPopover>
                      <Icon>
                        <IconLanguage />
                      </Icon>
                      <Spacer size="s" />
                      <div className="title">
                        {intl.formatMessage({ id: "auth.language" })}
                      </div>
                    </ButtonPopover>
                  </Content>

                  <Content onMouseDown={toggleModalSupport}>
                    <ButtonPopover>
                      <Icon>
                        <Support />
                      </Icon>
                      <Spacer size="s" />
                      <div className="title">
                        {intl.formatMessage({ id: "auth.support" })}
                      </div>
                    </ButtonPopover>
                  </Content>

                  <Content
                    className="rounded-bottom"
                    onMouseDown={() => {
                      onLogOut();
                      handleMouseLeave();
                    }}
                  >
                    <ButtonPopover className="item-pop">
                      <Icon>
                        <IconLogout2 />
                      </Icon>
                      <Spacer size="s" />
                      <div className="title">
                        {intl.formatMessage({ id: "auth.logout" })}
                      </div>
                    </ButtonPopover>
                  </Content>
                </Popover>
              </Overlay>
            </div>
            <ModalAccountInfo />
            <ModalSupport />
          </>
        ) : (
          <>
            <Button
              color="accept"
              className="flex-1"
              radius={4}
              onClick={() => {
                handleMouseLeave();
                dispatch(toggleModalLogin(true));
              }}
            >
              {intl.formatMessage({ id: "common.login" })}
            </Button>
          </>
        )}
      </HeaderRightCard>
      <div className="icon" onClick={() => dispatch(setVisibleSideBar())}>
        {displaySideBarIndex % 3 !== VisibleIndex.HIDDEN_ALL ? (
          <IconTypeHide />
        ) : (
          <IconTypeShow />
        )}
      </div>
      <ModalChangePasswordSuccess />
      <TickerDetailsModal
        visible={visibleTickerDetailsModal}
        onClose={() => {
          if (
            fromDerivativeTicker === "DERIVATIVE_CATEGORY_TICKER" ||
            fromDerivativeTicker === "HEADER_CARD"
          ) {
            dispatch(changeVisibleDerivative(true));
          }
          dispatch(
            setVisibleTickerDetailsModal({
              visible: !visibleTickerDetailsModal,
              ticker: null,
            })
          );
        }}
        ticker={selectedTicker}
      />
      <ModalConfirmOpenLogin />
    </HeaderCard>
  );
}

export default memo(Header);
