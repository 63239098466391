import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 512px;
  }
`;

export const Title = styled.div`
  height: 32px;
  text-align: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  background: ${({ theme }) => theme.background3};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.greyG6};
`;

export const Body = styled.div<{ padding?: number }>`
  padding: ${({ padding }) => padding}px;
  background-color: ${({ theme }) => theme.background1};
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Footer = styled.div`
  height: ${({ theme }) => theme.tables.content.height};
  text-align: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  background: ${({ theme }) => theme.background3};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :hover {
    color: ${({ theme }) => theme.textcolorWhite};
  }
  box-shadow: ${({ theme }) => theme.shadowLineUp};
`;

export const RedDot = styled.div<{ isActive?: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.red50};
  margin-left: 4px;
  position: relative;
  bottom: 3px;
  display: ${({ isActive }) => (isActive ? "block" : "none")};
`;

export const Tab = styled.button<{ isActive?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme, isActive }) =>
    isActive ? theme.background3 : theme.background3};
  color: ${({ theme, isActive }) =>
    isActive ? theme.brandPrimary : theme.grey40};
  border-bottom: ${({ theme, isActive }) =>
    isActive && `2px solid` + theme.brandPrimary};
  height: 100%;
  :hover {
    color: ${({ theme }) => theme.brandPrimary};
    border-bottom: 2px solid ${({ theme }) => theme.brandPrimary};
  }
`;

export const TotalUnread = styled.div<{ isDisable?: boolean }>`
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background: ${({ theme }) => theme.red50};
  margin-left: 4px;
  padding-top: 1px;
  position: relative;
  bottom: 6px;
  right: 12px;
  font-weight: ${({ theme }) => theme.typeFaces.minimal.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.minimal.size};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  display: ${({ isDisable }) => (isDisable ? "none" : "block")};
`;

export const MaskRead = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const BodyList = styled.div<{ isVisibleDetail: boolean }>`
  display: ${({ isVisibleDetail }) => (isVisibleDetail ? `none` : `block`)};
`;
