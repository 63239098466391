import { InputPlaceOrder } from "components/commons";
import { toast } from "components/commons/Toast";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { getMarketLabel } from "helper/utils";
import orderBy from "lodash/orderBy";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  SearchTicker,
  SelectTickerWrapper,
  TextItem,
  TextSearch,
} from "./styles";

interface Props {
  tickers: MrktSecInfoItem.AsObject[];
  onAddTicker: (value: string) => void;
  mediumSelect?: boolean;
  handleBlurPopSearch?: () => void;
  showPopSearch?: boolean;
  isSearchHeader?: boolean;
}

const SelectTicker: React.FC<Props> = React.memo(
  ({
    tickers,
    onAddTicker,
    mediumSelect = false,
    handleBlurPopSearch,
    showPopSearch,
    isSearchHeader,
  }) => {
    const intl = useIntl();
    const refInput = useRef<HTMLInputElement>(null);
    const [visibleTextSearch, setVisibleTextSearch] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [numberLoadmore, setNumberLoadMore] = useState(20);

    const onSearchTicker = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextSearch(e.target.value.toUpperCase());
    };

    useEffect(() => {
      if (showPopSearch && showPopSearch === true) {
        refInput.current?.focus();
      }
    }, [showPopSearch]);

    const handleKeyDown = (
      e: any,
      tickerSortCodeShare: MrktSecInfoItem.AsObject[]
    ) => {
      if (e.key === "Enter" || e.key === "Tab") {
        if (textSearch.length <= 2) return;
        e.stopPropagation();
        const item = tickerSortCodeShare?.filter((ticker) =>
          ticker.seccd.includes(textSearch.toUpperCase())
        )[0];
        if (item) {
          onAddTicker(item.seccd);
        } else {
          toast("widgets.placeOrder.errorTickerIllegal", "error");
        }
        setTextSearch("");
        setVisibleTextSearch(false);
      }
    };

    //filter by text input
    const tickerFilter = tickers?.filter((ticker) =>
      ticker.seccd.includes(textSearch.toUpperCase())
    );

    const tickerSortCodeShare: MrktSecInfoItem.AsObject[] = orderBy(
      tickerFilter,
      ["sectype", "seccd"]
    );

    const handleScroll = () => {
      setNumberLoadMore(numberLoadmore + 20);
    };

    return (
      <SelectTickerWrapper>
        <SearchTicker>
          <InputPlaceOrder
            innerRef={refInput}
            placeholder={intl.formatMessage({
              id: isSearchHeader
                ? "common.searchHeader"
                : "common.tickerTracking",
            })}
            value={textSearch}
            onChange={(e) => {
              onSearchTicker(e);
              if (e.target.value !== "") {
                setVisibleTextSearch(true);
              } else {
                setVisibleTextSearch(false);
              }
            }}
            sizeInput={isSearchHeader ? "m" : "s"}
            onKeyDown={(e) => handleKeyDown(e, tickerSortCodeShare)}
            isSearch
            onBlur={() => {
              setTimeout(() => {
                setVisibleTextSearch(false);
                if (handleBlurPopSearch) {
                  handleBlurPopSearch();
                }
              }, 150);
            }}
          />
          {(visibleTextSearch || textSearch) && (
            <TextSearch onScroll={handleScroll}>
              {tickerSortCodeShare.slice(0, numberLoadmore).map((item) => (
                <TextItem
                  className="ticker-item"
                  key={item.seccd}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setVisibleTextSearch(false);
                    setTextSearch("");
                    onAddTicker(item.seccd);
                  }}
                >
                  <div>
                    <span className="share-code">{item.seccd} </span>
                    {item.secname !== "" || item.secnameen !== "" ? " - " : ""}
                    <span>{item.secname || item.secnameen}</span>
                  </div>
                  <div>{getMarketLabel(item.marketcd)}</div>
                </TextItem>
              ))}
            </TextSearch>
          )}
        </SearchTicker>
      </SelectTickerWrapper>
    );
  }
);
export default SelectTicker;
