import VgaiaHttp from "core/http/singleton/vgaia";
import {
  ItemRegisterBankRequest,
  postAccountInfoRequest,
  postAccountInfoResponse,
  postServiceRegistrationRequest,
  postServiceRegistrationResponse,
  sendOTPGlobalMindRequest,
  sendOTPGlobalMindResponse,
  verifyOTPGlobalMindRequest,
  verifyOTPGlobalMindResponse,
} from "./types";
import {
  urlAccountInfoGlobalMind,
  urlAppBank,
  urlOTPGlobalMind,
  urlServiceRegistrationGlobalMind,
  urlValidateOTPGlobalMind,
} from "./urls";

export const sendOTPGlobalMind = async (
  params: sendOTPGlobalMindRequest
): Promise<sendOTPGlobalMindResponse> => {
  const res = await VgaiaHttp.post<any>(
    urlOTPGlobalMind,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res?.data?.data) throw new Error("Opps");
  return res.data.data;
};

export const verifyOTPGlobalMind = async (
  params: verifyOTPGlobalMindRequest
): Promise<verifyOTPGlobalMindResponse> => {
  const res = await VgaiaHttp.post<any>(
    urlValidateOTPGlobalMind,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res?.data?.data) throw new Error("Opps");
  return res.data.data;
};

export const postAccountInfoGlobalMind = async (
  params: postAccountInfoRequest
): Promise<postAccountInfoResponse> => {
  const res = await VgaiaHttp.post<any>(
    urlAccountInfoGlobalMind,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res?.data?.data) throw new Error("Opps");
  return res.data.data;
};

export const postServiceRegistrationGlobalMind = async (
  params: postServiceRegistrationRequest
): Promise<postServiceRegistrationResponse> => {
  const res = await VgaiaHttp.post<any>(
    urlServiceRegistrationGlobalMind,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res?.data?.data) throw new Error("Opps");
  return res.data.data;
};

export const postRegisterBank = async (
  params: ItemRegisterBankRequest[]
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(urlAppBank, JSON.stringify(params), {
    headers: { "Content-Type": "application/json" },
  });
  if (!res?.data) throw new Error("Opps");
  return res.data;
};
