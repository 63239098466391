import RequestFilter from "./AdvanceContent/RequestFilters";
import RequestTable from "./AdvanceContent/RequestTable";

function PlaceOrderBondAdvContent() {
  return (
    <>
      <RequestFilter />
      <RequestTable />
    </>
  );
}
export default PlaceOrderBondAdvContent;
