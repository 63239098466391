import { Widget } from "./types";
import {
  ConvertMarketOrderFlowDistribution,
  MarketPriceList,
  MarketTickerGraph,
  MarketVolumeAndTime,
} from "modules/tickerDetails/components";
import MarketTickerInfo from "components/widgets/details/TickerInfo";
import New from "components/widgets/details/News";
import OrganizationInfo from "components/widgets/details/OrganizationInfo";
import FinanceInfo from "components/widgets/details/Finance";

// TODO
const widgets: Widget[] = [
  {
    id: "0",
    name: "techAnalysis.widgets.technicalAnalysis",
    component: MarketTickerGraph,
  },
  {
    id: "1",
    name: "techAnalysis.widgets.tickerInfo",
    component: MarketTickerInfo,
  },
  {
    id: "2",
    name: "techAnalysis.widgets.priceList",
    component: MarketPriceList,
  },
  {
    id: "3",
    name: "techAnalysis.widgets.volumeAndTime",
    component: MarketVolumeAndTime,
  },
  {
    id: "4",
    name: "techAnalysis.widgets.orderFlowDistribution",
    component: ConvertMarketOrderFlowDistribution,
  },
  {
    id: "5",
    name: "techAnalysis.widgets.news",
    component: New,
  },
  {
    id: "6",
    name: "techAnalysis.widgets.finance",
    component: FinanceInfo,
  },
  {
    id: "7",
    name: "techAnalysis.widgets.organizationalInfo",
    component: OrganizationInfo,
  },
];

export default widgets;
