import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const marketRootSelector = (state: RootState) => state.market.root;
export const marketWatchSelector = (state: RootState) =>
  state.market.marketWatch;

export const visiblePopAddWidgetSelector = createSelector(
  marketRootSelector,
  (state) => state.visiblePopAddWidget
);

export const currentTabSelector = createSelector(
  marketRootSelector,
  (state) => state.currentTab
);

export const customizedBoardsSelector = createSelector(
  marketRootSelector,
  (state) => state.customizedBoards
);

export const tabsSelector = createSelector(
  marketRootSelector,
  (state) => state.tabs
);

export const visibleTickerDetailsModalSelector = createSelector(
  marketRootSelector,
  (state) => state.visibleTickerDetailsModal
);

export const fromSelector = createSelector(
  marketRootSelector,
  (state) => state.from
);

export const selectedTickerSelector = createSelector(
  marketRootSelector,
  (state) => state.selectedTicker
);

export const statBasketContributionDataSelector = createSelector(
  marketWatchSelector,
  (state) => state.statBasketContribution.statBasketContributionData
);
