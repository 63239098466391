import { useEffect, useMemo, useRef, useState } from "react";
import { TextColor } from "./styles";
import numeral from "numeral";
import { VolumeCellProps } from "modules/categories/types";
import { BlinkColor, BlinkTime } from "AppConfig";

function VolumeCell({
  volume,
  className,
  color = "",
  keepRawValue = false,
  formatType = "",
}: VolumeCellProps) {
  const [background, setBackground] = useState("");
  const countMounted = useRef(0);
  const oldData = useRef(0);

  const displayText = useMemo(() => {
    if (isNaN(volume) || volume === 0) {
      return "";
    }

    const formattedValue = numeral(volume * 10).format("0,0");
    if (!!formatType) {
      return numeral(volume * 10).format(formatType);
    }

    if (keepRawValue) {
      return numeral(volume).format("0,0");
    }

    return formattedValue.substring(0, formattedValue.length - 1);
  }, [volume, keepRawValue, formatType]);

  useEffect(() => {
    if (countMounted.current < 1) {
      countMounted.current++;
      return;
    }

    if (oldData.current === volume) return;
    setBackground(BlinkColor);
    const resetStatus = setTimeout(() => setBackground(""), BlinkTime);
    return () => {
      oldData.current = volume;
      setBackground("");
      clearTimeout(resetStatus);
    };
  }, [volume, color]);
  return (
    <TextColor
      className={`${className && className} text-right`}
      color={color}
      background={background}
    >
      {displayText}
    </TextColor>
  );
}

export default VolumeCell;
