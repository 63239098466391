import styled from "styled-components/macro";

export const Wrapper = styled.div`
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.border1}`};
  width: 100%;
  height: 100%;
`;

export const HeaderModal = styled.div`
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 0px !important;
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: 24px;

  color: ${({ theme }) => theme.grey0};
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const BodyModal = styled.div`
  background-color: ${({ theme }) => theme.background2};
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 24px;
  gap: 24px;
  z-index: 999;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const BodyModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 16px;
  &:first-child {
    padding-top: 12px;
  }
`;

export const LabelCell = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey20};
`;

export const LabelConfirm = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: 24px;
  color: ${({ theme }) => theme.grey20};
`;

export const TextCell = styled.div<{
  color?: string;
}>`
  flex: 1;
  text-align: right;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, color }) => (color ? color : theme.grey0)};
`;

export const ActionsRow = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 10px;
`;

export const ButtonCell = styled.div`
  flex: 1;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px dashed ${({ theme }) => theme.borderDivider};
`;
export const CheckBoxText = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: 20px;
  color: ${({ theme }) => theme.grey40};
`;
export const Link = styled.a`
  color: ${({ theme }) => theme.brandPrimary};
  transition: 0.3s all ease;

  &:hover {
    text-decoration: none;
  }
`;
export const Devided = styled.div`
  border: 1px dashed #2b3030;
  width: 100%;
`;
