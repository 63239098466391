import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const accountStatementSelector = (state: RootState) =>
  state.accountStatement;

export const surplusSelector = createSelector(
  accountStatementSelector,
  (state) => state.surplus
);
export const getMoneyStatmentList = createSelector(
  accountStatementSelector,
  (state) => state.listMoneyStatement
);
export const getSecurityStatmentList = createSelector(
  accountStatementSelector,
  (state) => state.listSecurityStatement
);
export const loadingSelector = createSelector(
  accountStatementSelector,
  (state) => state.loading
);

export const filterMoneyStatementSelector = createSelector(
  accountStatementSelector,
  (state) => state.moneyStatement.filter
);
export const filterSecurityStatementSelector = createSelector(
  accountStatementSelector,
  (state) => state.securityStatement.filter
);

export const selectCurentPage = createSelector(
  accountStatementSelector,
  (state) => state.currentPage
);

export const selectEnableInfinity = createSelector(
  accountStatementSelector,
  (state) => state.enableInfinity
);

export const selectLimitPage = createSelector(
  accountStatementSelector,
  (state) => state.limit
);
