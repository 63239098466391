export const getListMarginContractUrl = (
  tranCd: string,
  accNo: string,
  openDateFrom: number,
  openDateTo: number,
  broker: string,
  status: string,
  contractState: string
) =>
  `/findMarginContract?tranCd=${tranCd}&accNo=${accNo}&openDateFrom=${openDateFrom}&openDateTo=${openDateTo}&broker=${broker}&status=${status}&contractState=${contractState}`;
