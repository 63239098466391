import styled from "styled-components/macro";
import { UIConst } from "helper/consts";
import {
  INDEX_COLOR_CODEMap,
  STOCK_COLOR_CODEMap,
} from "domain/protoNew/auto_trading_pb";
import { getColorIndex, getColorTicker } from "helper/utils";

export const TextColorUpDown = styled.span<{
  textcolor: string;
}>`
color: ${({ theme, textcolor }) => theme[textcolor]}};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  margin-right: 4px;
`;

export const TextColorMini = styled.span<{
  backgroundColor: string;
}>`
  color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.greyG10 : theme.textcolorWhite}};
  background-color: ${({ theme, backgroundColor }) => theme[backgroundColor]}};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
`;

export const TextColor = styled.div<{
  textcolor: INDEX_COLOR_CODEMap[keyof INDEX_COLOR_CODEMap];
  backgroundColor: string;
}>`
  color: ${({ textcolor, theme }) => getColorIndex(textcolor, theme)};
  background-color: ${({ theme, backgroundColor }) => theme[backgroundColor]}};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
`;

export const TextColorDer = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
  backgroundColor: string;
}>`
${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
  background-color: ${({ theme, backgroundColor }) => theme[backgroundColor]}};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
`;

export const CardStock = styled.div`
  position: relative;
  width: 100%;
  min-width: 230px;
  height: 100%;
  padding: 8px 12px 6px;
  @media (max-width: ${UIConst.LAGRE_SCREEN_WIDTH}) {
    padding: 4px 8px;
    margin-right: 4px;
  }
  background-color: ${({ theme }) => theme.background2};
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  margin-right: 8px;
  &:last-child {
    margin-right: 4px;
  }
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .chart-size {
    height: 78px !important;
    flex: 1 1 0;
    position: relative;
    overflow: hidden;
  }
`;

export const NameIndex = styled.div`
  padding-left: 3px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Under = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  color: ${({ theme }) => theme.textcolorWhite};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
`;

export const Blue = styled.span`
  color: ${({ theme }) => theme.brandPrimary};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  margin: 0 8px 0 2px;
`;

export const RowCardStock = styled.div<{
  showChart: boolean;
}>`
  display: ${({ showChart }) => (showChart ? "flex" : "none")};
  justify-content: space-between;
`;

export const RowCardStockBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RowChangeStock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 3px;
  > div:first-child {
    padding-right: 4px;
  }
`;
