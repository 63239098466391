import React, { useState } from "react";
import { NumericFormatProps, NumericFormat } from "react-number-format";

import { UnitWrapper, Wrapper } from "./styles";

export type TSize = "small" | "normal" | "large";

export interface IProps extends NumericFormatProps {
  sizeInput?: TSize;
  fullWidth?: boolean;
  unit?: string;
}

const NumberInput: React.FC<IProps> = ({
  fullWidth,
  sizeInput = "normal",
  unit,
  ...restProps
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Wrapper
      sizeInput={sizeInput}
      fullWidth={fullWidth}
      isBorderActive={isFocus}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    >
      <NumericFormat {...restProps} autoComplete="off" />
      {!!unit && <UnitWrapper>{unit}</UnitWrapper>}
    </Wrapper>
  );
};

export default NumberInput;
