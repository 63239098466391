import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import { OrderBookDerRealTime } from "domain/models/OrderBookDer";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";

const matQtySelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) => state?.[realTimeId]?.matQty
  );
};

interface Props {
  realTimeId: string;
}

const MatQtyCell = ({ realTimeId }: Props) => {
  const matQty = useSelector(matQtySelector(realTimeId));
  return (
    <>
      <BlinkableCell value={matQty ? numeral(matQty).format("0,0") : 0} />
    </>
  );
};

export default MatQtyCell;
