import HttpClient from "../../index";
import { REACT_APP_RESTFUL_VGAIA_URL } from "AppConfig";

export default class VgaiaHttp extends HttpClient {
  private static classInstance?: VgaiaHttp;
  private constructor() {
    super(REACT_APP_RESTFUL_VGAIA_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new VgaiaHttp();
    }

    return this.classInstance;
  }
}
