import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISearchMrgContractDebitParams } from "core/http/apis/mrg-contract-debit/types";
import {
  IMarginDebitContractWatch,
  IMarginDebitContractWatchResponse,
} from "domain/models/MarginDebitContractWatch";
import { logOutSuccess } from "modules/auth/redux";
import { MRG_CONTRACT_DEBIT_TABS } from "./constants";

interface IFilter {
  accNo?: string;
  debitType?: string;
  status?: string;
}

export interface ReduxData {
  filter: IFilter;
  list: IMarginDebitContractWatch[];
  loading: boolean;
  activeTab: string;
}

const initialState: ReduxData = {
  filter: {
    accNo: "",
    debitType: "0",
    status: "0",
  },
  list: [],
  loading: false,
  activeTab: MRG_CONTRACT_DEBIT_TABS.MRG_CONTRACT_DEBIT,
};

const mrgContractDebitSlice = createSlice({
  name: "mrgContractDebitSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchMrgContractDebitRequest: (
      state,
      action: PayloadAction<ISearchMrgContractDebitParams>
    ) => {
      state.loading = true;
    },
    searchMrgContractDebitSuccess: (
      state,
      action: PayloadAction<IMarginDebitContractWatchResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchMrgContractDebitFailure: (state) => {
      state.loading = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchMrgContractDebitFailure,
  searchMrgContractDebitRequest,
  searchMrgContractDebitSuccess,
  updateFilter,
} = mrgContractDebitSlice.actions;

export default mrgContractDebitSlice.reducer;
