import styled from "styled-components/macro";

export const AcceptTermRow = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

export const AcceptText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: #ffffff;
`;

export const AcceptBox = styled.div``;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const ButtonExit = styled.div`
  position: absolute;
  top: 5%;
  right: 12px;
  transform: translateY(-50%);
  div {
    cursor: pointer;
  }
`;

export const Body = styled.div<{ padding?: number }>`
  background-color: ${({ theme }) => theme.background1};
  padding: 8px 24px;
  word-wrap: break-word;
  text-align: center;
`;

export const Footer = styled.div`
  display: block;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.background1};
  button {
    width: 100%;
    margin: auto;
  }
`;

//   border: 2px solid ${({ theme }) => theme.bgElevated3};
export const WrapBoder = styled.div`
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius} !important;
`;
export const WrapImage = styled.div`
  border-top-left-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border-bottom-left-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  width: 50%;
  background-color: ${({ theme }) => theme.brandSecondary};
`;

export const Container = styled.div<{ blur?: boolean }>`
  background-color: ${({ theme }) => theme.background2};
  border-top-right-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.cardHeader.borderRadius};

  width: 50%;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  .footer {
    div {
      padding: 7px;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }
  .title {
    padding: 7px;
    text-align: center;
    border-bottom: 1px solid;
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    color: ${({ theme }) => theme.solidBlue};
  }

  .carousel-indicators li {
    width: 10px;
    border-radius: 50%;
    height: 10px;
  }
  .carousel-indicators .active {
    background-color: ${({ theme }) => theme.solidBlue};
  }
  .body {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    .textRegister {
      color: ${({ theme }) => theme.solidBlue};
      cursor: pointer;
    }
    .text-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }
    .forgot-password {
      height: 16px;
      margin-bottom: 48px;
    }
    img {
      border-radius: 8px;
    }
  }

  .block {
    width: 100%;
  }
  .float-center {
    text-aligh: center !important;
    color: ${({ theme }) => theme.solidBlue};
    cursor: pointer;
  }
  .float-left {
    float: left;
    color: ${({ theme }) => theme.solidBlue};
    cursor: pointer;
  }
  .text-error {
    color: ${({ theme }) => theme.solidRed};
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px ${({ theme }) => theme.grey100} inset; /* Change the color to your own background color */
    -webkit-text-fill-color: ${({ theme }) => theme.textcolorWhite};
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px ${({ theme }) => theme.grey100} inset; /*your box-shadow*/
    -webkit-text-fill-color: ${({ theme }) => theme.textcolorWhite};
  }

  @media (max-width: 1366px) {
    .body {
      padding: 16px 24px;

      .text-center {
        margin-bottom: 16px;
      }

      .forgot-password {
        margin-bottom: 32px;
      }

      .contact-menu {
        margin-bottom: 32px;
      }
    }
  }
`;

export const ErrorVerify = styled.div`
  color: ${({ theme }) => theme.solidRed};
  text-align: center;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.header.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.header.size};
  line-height: ${({ theme }) => theme.typeFaces.header.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const LogoLogin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  .logo-title {
    margin-left: 8px;
    svg {
      path {
        fill: ${({ theme }) => theme.logo};
      }
    }
  }
  @media (max-width: 1366px) {
    margin-bottom: 24px;
  }
`;

export const Row = styled.div`
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Col = styled.div`
  &:hover {
    color: ${({ theme }) => theme.solidBlue};
    text-decoration: underline;
    cursor: pointer;
  }
`;
