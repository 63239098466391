import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import { OrderBookDerRealTime } from "domain/models/OrderBookDer";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";

const pubQtySelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) => state?.[realTimeId]?.pubQty
  );
};

interface Props {
  realTimeId: string;
}

const PubQtyCell = ({ realTimeId }: Props) => {
  const pubQty = useSelector(pubQtySelector(realTimeId));
  return (
    <>
      <BlinkableCell value={pubQty ? numeral(pubQty).format("0,0") : 0} />
    </>
  );
};

export default PubQtyCell;
