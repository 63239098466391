import { createSelector } from "@reduxjs/toolkit";
import { OrderFlowDistributionState } from "modules/tickerDetails/redux/orderFlowDistribution";
import { RootState } from "redux/store";
import { State as MarketRootState } from "./index";

// import { InfoQuoteState } from "modules/tickerDetails/redux/infoQuote";
import { VolumeAndTimeState } from "modules/tickerDetails/redux/volumeAndTime";
// import { TickerInfoState } from "modules/tickerDetails/redux/tickerInfo";

export const marketRootSelector = (state: RootState) => state.market.root;

export const categoryRootSelector = (state: RootState) => state.categories.root;

export const marketWatchSelector = (state: RootState) =>
  state.market.marketWatch;

// CoverWarrant Selector Start
// const coverWarrantInfoSelector = (state: RootState) =>
//   state.tickerDetails.coverWarrantInfo;

// export const tickerInfoDataSelector = createSelector(
//   coverWarrantInfoSelector,
//   (state: CoverWarrantInfoState) => ({
//     tickerInfoBasic: state.tickerInfoBasic,
//     cwPriceInfo: state.cwPriceInfo,
//     priceInfoUnderlying: state.priceInfoUnderlying,
//   })
// );

export const selectedTickerSelector = createSelector(
  marketRootSelector,
  (state: MarketRootState) => state.selectedTicker
);

export const currentMarketCodeSelector = createSelector(
  marketRootSelector,
  (state: MarketRootState) => state.currentMarketCode
);

export const visibleTickerDetailModalSelector = createSelector(
  marketRootSelector,
  (state: MarketRootState) => state.visibleTickerDetailsModal
);

// TODO
// CoverWarrant Selector End
// ================================================
// OrderFlowDistribution Start

export const orderFlowDistributionSelector = (state: RootState) =>
  state.tickerDetails.orderFlowDistribution;

export const orderFlowDistributionChartSelector = createSelector(
  orderFlowDistributionSelector,
  (state: OrderFlowDistributionState) => ({
    chartInflow: state.dataInflow,
    chartOutflow: state.dataOutflow,
    pieChartFlow: state.dataPieChartFlow,
    totalInflow: state.totalInflow,
    totalOutflow: state.totalOutflow,
  })
);
// OrderFlowDistribution End
// =========================
// Price List Start

// export const priceListSelector = (state: RootState) =>
//   state.tickerDetails.infoQuote;

// export const dataQuoteSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.dataQuote
// );

// export const sumAskSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.sumAsk
// );

// export const sumBidSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.sumBid
// );

// export const percentBuySelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.percentBuy
// );
// export const dataAskBidSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.dataAskBid
// );
// Price List End
// =========================

// Volume and Time Start
const volumeAndTimeSelector = (state: RootState) =>
  state.tickerDetails.volumeAndTime;

export const volumeListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.volumeList
);

export const historyListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.historyList
);

export const tnTimeSaleSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.tnTimeSale
);

// Volume and Time End
// ========================

// export const tickersListInCategorySelector = createSelector(
//   categoryRootSelector,
//   (state) => state.tickers
// );

// Ticker Info End
// ========================
// Transaction Value Volume Start
export const transactionValueVolumeSelector = createSelector(
  marketWatchSelector,
  (state) => state.transactionValueVolume.transactionValueVolumeData
);

export const filterTransactionValueVolumeSelector = createSelector(
  marketWatchSelector,
  (state) => state.transactionValueVolume.filterTransaction
);

export const liquidityDataSelector = createSelector(
  marketWatchSelector,
  (state) => state.liquidity.liquidityData
);

export const capitalizationSelector = createSelector(
  marketWatchSelector,
  (state) => state.capitalization.marketCapData
);

export const marketStatsSelector = createSelector(
  marketWatchSelector,
  (state) => state.marketStats
);

// Transaction Value Volume Stop
// ========================

export const foreignChartSelector = createSelector(
  marketWatchSelector,
  (state) => state.foreignChart
);

// ========================

export const marketTopPriceLeftSelector = (state: RootState) =>
  state.market.topPriceLeft;
export const tickerListTopPriceLeftSelector = createSelector(
  marketTopPriceLeftSelector,
  (state) => state.tickerList
);
export const activeTabTopPriceLeftSelector = createSelector(
  marketTopPriceLeftSelector,
  (state) => state.activeTab
);
export const activeSortTopPriceLeftSelector = createSelector(
  marketTopPriceLeftSelector,
  (state) => state.activeSort
);

// ========================
export const marketTopPriceRightSelector = (state: RootState) =>
  state.market.topPriceRight;
export const tickerListTopPriceRightSelector = createSelector(
  marketTopPriceRightSelector,
  (state) => state.tickerList
);

export const activeTabTopPriceRightSelector = createSelector(
  marketTopPriceRightSelector,
  (state) => state.activeTab
);
export const activeSortTopPriceRightSelector = createSelector(
  marketTopPriceRightSelector,
  (state) => state.activeSort
);

// ========================
export const marketTopStatisticalSelector = (state: RootState) =>
  state.market.topStatistical;
export const tickerListTopStatisticalSelector = createSelector(
  marketTopStatisticalSelector,
  (state) => state.tickerList
);

export const activeTabTopStatisticalSelector = createSelector(
  marketTopStatisticalSelector,
  (state) => state.activeTab
);

export const activeSortTopStatisticalSelector = createSelector(
  marketTopStatisticalSelector,
  (state) => state.activeSort
);
// ========================
export const marketTopForeignSelector = (state: RootState) =>
  state.market.topForeign;
export const tickerListTopForeignSelector = createSelector(
  marketTopForeignSelector,
  (state) => state.tickerList
);
export const activeTabTopForeignSelector = createSelector(
  marketTopForeignSelector,
  (state) => state.activeTab
);
export const activeSortTopForeignSelector = createSelector(
  marketTopForeignSelector,
  (state) => state.activeSort
);

// ========================
export const marketNetflowSelector = (state: RootState) => state.market.netflow;
export const tradingDatesNetflowSelector = createSelector(
  marketNetflowSelector,
  (state) => state.tradingDates
);
