import { toast } from "components/commons/Toast";
import {
  searchDepositPower,
  liquidateDepositPower,
  depositPowerRegister,
} from "core/http/apis/deposit-power";
import {
  ISearchDepositPowerParams,
  ILiquidateDepositPowerParams,
  IDepositPowerRegisterParams,
} from "core/http/apis/deposit-power/types";
import {
  ISearchDepositPowerResponse,
  ILiquidateDepositPowerResponse,
  IDepositPowerRegisterResponse,
} from "domain/models/DepositPower";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  searchDepositPowerFailure,
  searchDepositPowerRequest,
  searchDepositPowerSuccess,
  liquidateDepositPowerRequest,
  liquidateDepositPowerSuccess,
  liquidateDepositPowerFailure,
  depositPowerRegisterRequest,
  depositPowerRegisterSuccess,
  depositPowerRegisterFailure,
} from "./redux";

function* searchDepositPowerWorker(
  action: ReturnType<typeof searchDepositPowerRequest>
) {
  try {
    const params: ISearchDepositPowerParams = action.payload;
    const response: ISearchDepositPowerResponse = yield call(
      searchDepositPower,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchDepositPowerSuccess(response));
    } else {
      yield put(searchDepositPowerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchDepositPowerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* liquidateDepositPowerWorker(
  action: ReturnType<typeof liquidateDepositPowerRequest>
) {
  try {
    const params: ILiquidateDepositPowerParams = action.payload;
    const response: ILiquidateDepositPowerResponse = yield call(
      liquidateDepositPower,
      params
    );

    if (response?.statusCode === 0) {
      yield put(liquidateDepositPowerSuccess(response));
    } else {
      yield put(liquidateDepositPowerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(liquidateDepositPowerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* depositPowerRegisterWorker(
  action: ReturnType<typeof depositPowerRegisterRequest>
) {
  try {
    const params: IDepositPowerRegisterParams = action.payload;
    const response: IDepositPowerRegisterResponse = yield call(
      depositPowerRegister,
      params
    );

    if (response?.statusCode === 0) {
      yield put(depositPowerRegisterSuccess(response));
    } else {
      yield put(depositPowerRegisterFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(depositPowerRegisterFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchDepositPowerWatcher() {
  yield takeLatest(searchDepositPowerRequest.type, searchDepositPowerWorker);
}
function* liquidateDepositPowerWatcher() {
  yield takeLatest(
    liquidateDepositPowerRequest.type,
    liquidateDepositPowerWorker
  );
}

function* depositPowerRegisterWatcher() {
  yield takeLatest(
    depositPowerRegisterRequest.type,
    depositPowerRegisterWorker
  );
}
export default function* advanceLimitSaga() {
  yield all([
    searchDepositPowerWatcher(),
    liquidateDepositPowerWatcher(),

    depositPowerRegisterWatcher(),
  ]);
}
