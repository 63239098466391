import { toast } from "components/commons/Toast";
import { getInitBroker } from "core/http/apis/broker";
import { getMarginContractWatch } from "core/http/apis/margin-contract-watch";
import { IFindMarginContractWatchParams } from "core/http/apis/margin-contract-watch/types";
import { IMarginContractWatchResponse } from "domain/models/MarginContractWatch";
import { ISessionModelResponse } from "domain/models/SessionModel";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getInitBrokerFailure,
  getInitBrokerRequest,
  getInitBrokerSuccess,
  searchMarginContractWatchFailure,
  searchMarginContractWatchRequest,
  searchMarginContractWatchSuccess,
} from "./redux";

function* searchMarginContractWatchWorker(
  action: ReturnType<typeof searchMarginContractWatchRequest>
) {
  try {
    const params: IFindMarginContractWatchParams = action.payload;
    const response: IMarginContractWatchResponse = yield call(
      getMarginContractWatch,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchMarginContractWatchSuccess(response));
    } else {
      yield put(searchMarginContractWatchFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchMarginContractWatchFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchMarginContractWatchWatcher() {
  yield takeLatest(
    searchMarginContractWatchRequest.type,
    searchMarginContractWatchWorker
  );
}

function* getInitBrokerWorker(action: ReturnType<typeof getInitBrokerRequest>) {
  try {
    const response: ISessionModelResponse = yield call(getInitBroker);

    const brokerList = eval(response?.data?.data!) ?? [];

    if (response?.statusCode === 0) {
      yield put(getInitBrokerSuccess(brokerList));
    } else {
      yield put(getInitBrokerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getInitBrokerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getInitBrokerWatcher() {
  yield takeLatest(getInitBrokerRequest.type, getInitBrokerWorker);
}

export default function* statisticUpdownSaga() {
  yield all([searchMarginContractWatchWatcher(), getInitBrokerWatcher()]);
}
