import styled from "styled-components/macro";

export const Icon = styled.div``;

export const ButtonDelete = styled.button`
  background: transparent;
  padding: 0px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Content = styled.div`
  padding-top: 16px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  padding-left: 5px;
  height: calc(100% - 40px);
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.bgElevated4} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
  padding-bottom: 16px;
`;

export const ButtonBack = styled.button`
  background: transparent;
  padding: 0px;
`;

export const Container = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.grey70};
  height: 512px;
`;
