import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type OddLotState = {
  selectedOddLotType: string;
};

const initialState: OddLotState = {
  selectedOddLotType: "",
};

const oddLotSlice = createSlice({
  name: "oddLotSlice",
  initialState,
  reducers: {
    changeOddLotType: (state, action: PayloadAction<string>) => {
      state.selectedOddLotType = action.payload;
    },
  },
});

export const { changeOddLotType } = oddLotSlice.actions;

export default oddLotSlice.reducer;
