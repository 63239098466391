import styled from "styled-components";

export const Container = styled.div<{
  isVisibleSideCard: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
`;
