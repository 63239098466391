import styled from "styled-components/macro";

export const ProgressContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: ${({ theme }) => theme.textcolorWhite};
  transition: 0.5s;
  height: 19px;
`;

export const PercentValue = styled.span`
  padding: 0 8px;
`;

export const Space = styled.div`
  width: 0.5%;
  background: black;
`;
