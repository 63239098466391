import styled from "styled-components/macro";

export const Container = styled.div`
  padding: 24px;
  .title {
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const Icon = styled.div`
  svg {
    path {
      fill: ${({ theme }) => theme.textcolorWhite};
    }
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  margin-bottom: 8px;
`;
