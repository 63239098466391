/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
// import { convertNumberToDateFormat } from "helper/utils";
import {
  updateModalDerStatusRequest,
  // findOrderDetailDerRequest,
  findDertOrderCondRequest,
  findDerOrderDetailRequest,
  updateEnableInfinityDer,
  fetchMoreOrderByFilterRequest,
} from "modules/order-history/redux";

import {
  listOrderHistoryDerSelector,
  listOrderHistoryDerCondSelector,
  filterOrderHistoryDerSelector,
  itemDetailDerSelector,
  itemDetailCondDerSelector,
  selectLimit,
  selectPageDer,
  selectEnableInfinityDer,
} from "modules/order-history/selectors";

import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Container, HeaderCell } from "./styles";

import { TableCellContent } from "components/commons/CommonTable/styles";
import { TableCellContentColor, StatusContainer } from "./styles";

import {
  OrderHistoryDer,
  OrderHistoryDerCond,
} from "domain/models/OrderHistory";
import moment from "moment";
import DetailModal from "./DetailModal/index";
import DetailModalCond from "./DetailModalCond/index";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { IFindDertOrderByFilterParams } from "core/http/apis/order-history/types";
import { SrcChannel } from "helper/consts";
function RequestTable() {
  const intl = useIntl();

  const data = useSelector(listOrderHistoryDerSelector);
  const dataCond = useSelector(listOrderHistoryDerCondSelector);
  const itemDetailRow = useSelector(itemDetailDerSelector);
  const itemDetailCondRow = useSelector(itemDetailCondDerSelector);
  const { fromDate, toDate, secCd, orderType, status, tradeType } = useSelector(
    filterOrderHistoryDerSelector
  );
  const themeType = useSelector(themeTypeSelector);
  const dispatch = useDispatch();
  // const flexStart = "flex-start";
  const center = "center";
  const flexEnd = "flex-end";

  const limit = useSelector(selectLimit);
  const currentPage = useSelector(selectPageDer);
  const enable = useSelector(selectEnableInfinityDer);

  const openDetail = (row: OrderHistoryDer) => {
    dispatch(
      findDerOrderDetailRequest({
        orgOrderNo: row.orgOrderNo ?? "",
        orderNo: row.orderNo ?? "",
        tradeDate: row.tradeDate ?? 0,
      })
    );

    dispatch(
      updateModalDerStatusRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
  };
  const openDetailCond = (row: OrderHistoryDerCond) => {
    dispatch(
      findDertOrderCondRequest({
        // alloDate: row.alloDate ?? 0,
        refDate: row.refDate ?? 0,
        refNo: row.refNo ?? 0,
        custNo: row.custNo ?? "",
      })
    );

    dispatch(
      updateModalDerStatusRequest({
        isVisible: true,
        itemDetailCond: row,
      })
    );
  };
  const convertNumberToDateFormatDDMMYYHHMMSS = ({
    date,
    inputFormat = "YYYY-MM-DD HH:mm:ss",
    outputFormat = "DD/MM/YYYY HH:mm:ss",
  }: {
    date?: number;
    inputFormat?: string;
    outputFormat?: string;
  }) => {
    if (!date) return "";

    return moment(new Date(date), inputFormat).format(outputFormat);
  };
  function getColor(value: string, value2: number) {
    if (value2 === 1) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].red50}
          textTransform="uppercase"
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 2) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].green50}
          textTransform="uppercase"
        >
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  function getColor2(value: string, value2: number) {
    if (value2 === 1 || value2 === 6 || value2 === 7 || value2 === 8) {
      return (
        <StatusContainer status="reject">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorWhite} //red
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    } else if (value2 === 5) {
      return (
        <StatusContainer status="success">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorWhite} //green
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    } else if (value2 === 2 || value2 === 3) {
      return (
        <StatusContainer status="reject-text-red">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorBlack} //black
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    } else if (value2 === 4) {
      return (
        <StatusContainer status="partialMatch">
          <TableCellContent align={center}>
            <TableCellContentColor
              align="center"
              color={themes[themeType].textcolorWhite}
            >
              {value}
            </TableCellContentColor>
          </TableCellContent>
        </StatusContainer>
      );
    }

    return value;
  }
  function getColor3(value: string, value2: number) {
    if (value2 === 9 || value2 === 3) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].solidRed}
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 2) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].solidGreen}
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 1) {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].solidYellow}
        >
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  const getDisplay = (status: number) => {
    switch (status) {
      case 1:
        return "Back";
      case 2:
        return "Web";
      case 3:
        return "Front";
      case 4:
        return "Webmobile";
      case 5:
        return "Mobile";
      case 6:
        return "Home";
      case 7:
        return "Floor";
      case 8:
        return "SMS";
      case 9:
        return "Tel";
    }
  };
  const getDisplay4 = (status: number) => {
    switch (status) {
      case 1:
        return "rejected";
      case 2:
        return "entered";
      case 3:
        return "waitApprove";
      case 4:
        return "partialMatch";
      case 5:
        return "fullMatch";
      case 6:
        return "partialCancel";
      case 7:
        return "cancel";
      case 8:
        return "rejectedGkck";
    }
  };
  const getDisplay6 = (status: number) => {
    switch (status) {
      case 1:
        return "waitActive";
      case 2:
        return "end";
      case 3:
        return "expire";
      case 9:
        return "cancel";
    }
  };
  const getDisplay8 = (status: number) => {
    switch (status) {
      case 1:
        return "short";
      case 2:
        return "long";
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.tradingDate" />
          </HeaderCell>
        ),
        accessor: "reqTime",
        minWidth: 135,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormatDDMMYYHHMMSS({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.account" />
          </HeaderCell>
        ),
        accessor: "custNo",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderShare" />
          </HeaderCell>
        ),
        accessor: "secCd",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderSide" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor(
                intl.formatMessage({
                  id:
                    "orderHistory.tradeTypeDer." +
                    getDisplay8(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderVolume" />
          </HeaderCell>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderPrice" />
          </HeaderCell>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,000.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderVolumeFilled" />
          </HeaderCell>
        ),
        accessor: "matQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderPriceFilled" />
          </HeaderCell>
        ),
        accessor: "avgPrice",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,000.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderStatus" />
          </HeaderCell>
        ),
        accessor: "extStatus",
        minWidth: 135,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <>
              {getColor2(
                intl.formatMessage({
                  id:
                    "orderHistory.extStatus." + getDisplay4(+props.value ?? 0),
                }),
                props.value
              )}
            </>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderNo" />
          </HeaderCell>
        ),
        accessor: "orderNo",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.channel" />
          </HeaderCell>
        ),
        accessor: "ordChanel",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "orderHistory.ordChannel." + getDisplay(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const columnsCond = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.account" />
          </HeaderCell>
        ),
        accessor: "custNo",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderShare" />
          </HeaderCell>
        ),
        accessor: "secCd",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderType" />
          </HeaderCell>
        ),
        accessor: "condType",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id:
                  "orderHistory.orderHistoryDerTab.filter.condList." +
                  props.value,
              })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderSide" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value === 0 || props.value === "0"
                ? 0
                : getColor(
                    intl.formatMessage({
                      id:
                        "orderHistory.tradeTypeDer." +
                        getDisplay8(+props.value ?? 0),
                    }),
                    props.value
                  )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderStatus" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor3(
                intl.formatMessage({
                  id:
                    "orderHistory.extStatusCond2." +
                    getDisplay6(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderVolume" />
          </HeaderCell>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.orderPrice" />
          </HeaderCell>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.activePrice" />
          </HeaderCell>
        ),
        accessor: "a",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          const row = props?.row?.original;

          return (
            <TableCellContent align={flexEnd}>
              {numeral(
                row.condType === "UO"
                  ? row.priceUp ?? 0
                  : row.condType === "DO"
                  ? row.priceDown ?? 0
                  : 0
              ).format("0,0.00")}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.takeProfit" />
          </HeaderCell>
        ),
        accessor: "grossAmp",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {props.value ?? 0}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopLoss" />
          </HeaderCell>
        ),
        accessor: "cutLostAmp",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {props.value ?? 0}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopLossAmount" />
          </HeaderCell>
        ),
        accessor: "cutLostPrice",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value ?? 0).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.stopInterval" />
          </HeaderCell>
        ),
        accessor: "rangeStop",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {props.value ?? 0}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.arbitrage" />
          </HeaderCell>
        ),
        accessor: "limitOffset",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {props.value ?? 0}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.updDateTime" />
          </HeaderCell>
        ),
        accessor: "updDateTime",
        minWidth: 135,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormatDDMMYYHHMMSS({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.orderHistoryDerTab.table.channel" />
          </HeaderCell>
        ),
        accessor: "ordChanel",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderHistory.tooltip" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "orderHistory.ordChannel." + getDisplay(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const fetchMoreDataCond = () => {};

  const fetchMoreData = () => {
    if (data.length < (currentPage + 1) * limit) {
      return dispatch(updateEnableInfinityDer(false));
    }
    const submitData: IFindDertOrderByFilterParams = {
      branchCd: "",
      transactionCd: "",

      tradeType: tradeType ? tradeType : 0,

      orderType: "",
      secCd: secCd ? secCd : "",
      brokerCd: "",
      custNo: "",

      status: status !== null && status !== undefined ? status : 0,

      regUserId: "",
      ordChanel: SrcChannel.ALL,

      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: 0,
      rowPerPage: limit,
    };
    dispatch(fetchMoreOrderByFilterRequest(submitData));
  };

  return (
    <Container id="orderHistoryTable">
      {orderType === "2" && (
        <CommonTableInfinity
          fetchMoreData={fetchMoreDataCond}
          columns={columnsCond}
          data={dataCond ? dataCond : []}
          enableInfinity={true}
          scrollableTarget={"orderHistoryTable"}
          doubleClickRowHandler={(row) => {
            openDetailCond(row.original);
          }}
          isHasFooter={false}
        />
      )}
      {orderType === "1" && (
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={data ? data : []}
          enableInfinity={enable}
          scrollableTarget={"orderHistoryTable"}
          doubleClickRowHandler={(row) => {
            openDetail(row.original);
          }}
          isHasFooter={false}
        />
      )}
      {/* <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={orderType === "2" ? columnsCond : columns}
        data={orderType === "2" ? dataCond : data ? data : []}
        enableInfinity={true}
        scrollableTarget={"orderHistoryTable"}
        doubleClickRowHandler={(row) => {
          if (orderType === "1") {
            openDetail(row.original);
          } else if (orderType === "2") {
            openDetailCond(row.original);
          }
        }}
        isHasFooter={false}
      /> */}

      {orderType === "2" && itemDetailCondRow && (
        <DetailModalCond itemDetailRow={itemDetailCondRow} />
      )}
      {orderType === "1" && itemDetailRow && (
        <DetailModal itemDetailRow={itemDetailRow} />
      )}
    </Container>
  );
}

export default memo(RequestTable);
