import { ReactComponent as IconTrash } from "assets/image/svg/icon_trash.svg";
import { Button, Spacer } from "components/commons";
import storage from "helper/storage";
import { FormattedMessage } from "react-intl";
import { Container, Icon, Label } from "./styles";

function ClearCache() {
  const handleClearCacheAll = () => {
    localStorage.clear();
    storage.removeToken();
    window.location.reload();
  };

  return (
    <Container>
      <Label>
        <FormattedMessage id="cleanCache.contentConfirm" />
      </Label>
      <Button color="gradientaAccept" onClick={handleClearCacheAll}>
        <Icon>
          <IconTrash />
        </Icon>
        <Spacer size="s" />
        <div className="title">
          <FormattedMessage id="cleanCache.title" />
        </div>
      </Button>
    </Container>
  );
}

export default ClearCache;
