import { FC, useState } from "react";
import { useIntl } from "react-intl";
import ModalNotification from "./components/ModalNotification";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
const ChangeLimit: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(true);

  const handleCloseModal = () => {
    history.goBack();
    setVisibleModalConfirm(false);
  };

  return (
    <Container isVisibleSideCard={false}>
      <ModalNotification
        visibleModalConfirm={visibleModalConfirm}
        title={intl.formatMessage({
          id: "changeLimit.modal.titleModal",
        })}
        handleCloseModal={handleCloseModal}
      />
    </Container>
  );
};

export default ChangeLimit;
