import styled from "styled-components/macro";

export const Wrap = styled.div`
  width: 60%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const WrapInput = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const WrapLeft = styled.div`
  width: 50%;
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.grey0};
  line-height: ${({ theme }) => theme.input.height};
`;
export const WrapRight = styled.div`
  width: 50%;
  display: flex;
  gap: 12px;
`;

export const WrapTextFirst = styled.div`
  width: 20%;
  color: ${({ theme }) => theme.grey0};
  line-height: ${({ theme }) => theme.input.height};
`;

export const TextLabel = styled.div`
  color: ${({ theme }) => theme.grey0};
  margin-bottom: 18px;
`;

export const TextNote = styled.div`
  color: ${({ theme }) => theme.textColorRed};
  margin-bottom: 18px;
`;
