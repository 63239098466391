import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const subscribeBuySelect = (state: RootState) => state.subscribeBuy;

export const selectTab = createSelector(
  subscribeBuySelect,
  (state) => state.navigation
);

export const selectTypeForm = createSelector(
  subscribeBuySelect,
  (state) => state.typeForm
);

// subscribe
export const selectLoadingSubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.subscribe.loading
);

export const selectSearchSubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.subscribe.search
);

export const selectListSubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.subscribe.data
);

export const selectSearchHistorySubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.historySubscribe.search
);

export const selectSearchsearchSubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.searchSubscribe.search
);

export const selectLoadingsearchSubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.searchSubscribe.loading
);

export const selectDatasearchSubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.searchSubscribe.data
);

export const selectDetail = createSelector(
  subscribeBuySelect,
  (state) => state.detail
);

export const selectListHistorySubscribe = createSelector(
  subscribeBuySelect,
  (state) => state.historySubscribe.data
);
