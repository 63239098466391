import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISearchDepositPowerParams,
  ILiquidateDepositPowerParams,
  IEditDepositPowerParams,
  IDepositPowerRegisterParams,
} from "core/http/apis/deposit-power/types";
import {
  DepositPower,
  ISearchDepositPowerResponse,
  ILiquidateDepositPowerResponse,
  IDepositPowerRegisterResponse,
} from "domain/models/DepositPower";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  contractSts: number;
  fromDate?: Date;
  toDate?: Date;
}

export interface ReduxData {
  filter: IFilter;
  list: DepositPower[];
  loading: boolean;
  activeTab: string;
  statusLiquidate: boolean;

  statusDepositPowerRegister: boolean;
  depositPower?: IEditDepositPowerParams;
}

const initialState: ReduxData = {
  filter: {
    contractSts: 1,
    // fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    // toDate: new Date(),
  },
  list: [],
  loading: false,
  activeTab: "DEPOSIT_POWER",
  statusLiquidate: false,
  //   isShowConfirmModal: false,
  statusDepositPowerRegister: false,
};

const depositPowerSlice = createSlice({
  name: "depositPowerSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchDepositPowerRequest: (
      state,
      action: PayloadAction<ISearchDepositPowerParams>
    ) => {
      state.loading = true;
    },
    searchDepositPowerSuccess: (
      state,
      action: PayloadAction<ISearchDepositPowerResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchDepositPowerFailure: (state) => {
      state.loading = false;
    },
    liquidateDepositPowerRequest: (
      state,
      action: PayloadAction<ILiquidateDepositPowerParams>
    ) => {
      state.statusLiquidate = false;
    },
    liquidateDepositPowerSuccess: (
      state,
      action: PayloadAction<ILiquidateDepositPowerResponse>
    ) => {
      state.statusLiquidate = true;
    },
    liquidateDepositPowerFailure: (state) => {
      state.statusLiquidate = false;
    },

    depositPowerRegisterRequest: (
      state,
      action: PayloadAction<IDepositPowerRegisterParams>
    ) => {
      state.statusDepositPowerRegister = false;
    },
    depositPowerRegisterSuccess: (
      state,
      action: PayloadAction<IDepositPowerRegisterResponse>
    ) => {
      state.statusDepositPowerRegister = true;
    },
    depositPowerRegisterFailure: (state) => {
      state.statusDepositPowerRegister = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateItemEdit: (
      state,
      action: PayloadAction<IEditDepositPowerParams | undefined>
    ) => {
      if (!action.payload) {
        state.depositPower = undefined;
        return;
      }

      state.depositPower = {
        ...state.depositPower,
        ...action.payload,
      };
    },
  },
});

export const {
  searchDepositPowerFailure,
  searchDepositPowerRequest,
  searchDepositPowerSuccess,
  liquidateDepositPowerRequest,
  liquidateDepositPowerSuccess,
  liquidateDepositPowerFailure,
  depositPowerRegisterRequest,
  depositPowerRegisterSuccess,
  depositPowerRegisterFailure,

  updateItemEdit,
  updateFilter,
} = depositPowerSlice.actions;

export default depositPowerSlice.reducer;
