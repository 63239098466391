import {
  Body,
  Container,
  Title,
  ButtonPopover,
  Menu,
  Content,
  ItemMenu,
  TitleContent,
  ButtonExit,
} from "./styles";
import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as IconSetting } from "assets/image/svg/ic_setting.svg";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  visibleModalSettingSelector,
  tabActiveSettingSelector,
} from "modules/auth/selectors";
import {
  setTabActiveSetting,
  setvisibleModalSetting,
} from "modules/auth/redux";
import { TabSetting } from "helper/consts";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import GeneralSettings from "./MenuItem/GeneralSettings";
import KeyShortcutSettings from "./MenuItem/KeyShortcutSettings";
import TradingAccountSettings from "./MenuItem/TradingAccountSettings";
import PassSetting from "./MenuItem/PassSettings";
import ManageDevice from "./MenuItem/ManageDevice";
import AdvanceAuto from "./MenuItem/AdvanceAuto";
import ServiceEmailSms from "./MenuItem/ServiceEmailSms";
import ClearCache from "./MenuItem/ClearCache";

function ModalSetting() {
  const visibleModalSetting = useSelector(visibleModalSettingSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const tabActiveSetting = useSelector(tabActiveSettingSelector);

  const dispatch = useDispatch();

  const toggleModalSetting = () => {
    dispatch(setvisibleModalSetting());
  };

  const closeModalSetting = () => {
    dispatch(setTabActiveSetting(TabSetting.GENERAL));
    toggleModalSetting();
  };

  const handleTabChange = (tab: TabSetting) => {
    dispatch(setTabActiveSetting(tab));
  };

  const renderContent = useMemo(() => {
    if (tabActiveSetting === TabSetting.GENERAL) {
      return <GeneralSettings />;
    }
    if (tabActiveSetting === TabSetting.MANAGE_DEVICE) {
      return <ManageDevice />;
    }
    if (tabActiveSetting === TabSetting.ADVANCE_AUTO) {
      return <AdvanceAuto />;
    }

    if (tabActiveSetting === TabSetting.TRADING_ACC) {
      return <TradingAccountSettings />;
    }

    if (tabActiveSetting === TabSetting.KEY_SHORTCUT) {
      return <KeyShortcutSettings />;
    }

    if (tabActiveSetting === TabSetting.EMAIL_SMS) {
      return <ServiceEmailSms />;
    }

    if (tabActiveSetting === TabSetting.SETTING_PASS) {
      return <PassSetting />;
    }

    if (tabActiveSetting === TabSetting.CLEAR_CACHE) {
      return <ClearCache />;
    }

    return <GeneralSettings />;
  }, [tabActiveSetting]);

  return (
    <>
      <ButtonPopover onClick={toggleModalSetting}>
        <IconSetting />
      </ButtonPopover>
      <Modal
        show={visibleModalSetting}
        centered
        dialogClassName="modal-900"
        onHide={closeModalSetting}
      >
        <Container>
          <Title>
            <div className="flex-1"></div>
            <TitleContent>
              <FormattedMessage id="auth.setting.title" />
            </TitleContent>
            <ButtonExit>
              <IconExit onClick={closeModalSetting} />
            </ButtonExit>
          </Title>
          <Body>
            <Menu>
              <ItemMenu
                isActive={
                  tabActiveSetting === TabSetting.GENERAL ? true : false
                }
                onClick={() => handleTabChange(TabSetting.GENERAL)}
              >
                <FormattedMessage id="auth.setting.menu.general" />
              </ItemMenu>
              {isAuthenticated && (
                <>
                  <ItemMenu
                    isActive={
                      tabActiveSetting === TabSetting.MANAGE_DEVICE
                        ? true
                        : false
                    }
                    onClick={() => handleTabChange(TabSetting.MANAGE_DEVICE)}
                  >
                    <FormattedMessage id="auth.setting.menu.manageDevice" />
                  </ItemMenu>
                  <ItemMenu
                    isActive={
                      tabActiveSetting === TabSetting.ADVANCE_AUTO
                        ? true
                        : false
                    }
                    onClick={() => handleTabChange(TabSetting.ADVANCE_AUTO)}
                  >
                    <FormattedMessage id="auth.setting.menu.advanceAuto" />
                  </ItemMenu>
                  <ItemMenu
                    isActive={
                      tabActiveSetting === TabSetting.SETTING_PASS
                        ? true
                        : false
                    }
                    onClick={() => handleTabChange(TabSetting.SETTING_PASS)}
                  >
                    <FormattedMessage id="auth.setting.menu.settingPass" />
                  </ItemMenu>
                  <ItemMenu
                    isActive={
                      tabActiveSetting === TabSetting.TRADING_ACC ? true : false
                    }
                    onClick={() => handleTabChange(TabSetting.TRADING_ACC)}
                  >
                    <FormattedMessage id="auth.setting.menu.tradingAcc" />
                  </ItemMenu>
                  <ItemMenu
                    isActive={
                      tabActiveSetting === TabSetting.EMAIL_SMS ? true : false
                    }
                    onClick={() => handleTabChange(TabSetting.EMAIL_SMS)}
                  >
                    <FormattedMessage id="auth.setting.menu.emailService" />
                  </ItemMenu>
                </>
              )}

              <ItemMenu
                isActive={
                  tabActiveSetting === TabSetting.KEY_SHORTCUT ? true : false
                }
                onClick={() => handleTabChange(TabSetting.KEY_SHORTCUT)}
              >
                <FormattedMessage id="auth.setting.menu.keyShortcut" />
              </ItemMenu>

              <ItemMenu
                isActive={
                  tabActiveSetting === TabSetting.CLEAR_CACHE ? true : false
                }
                onClick={() => handleTabChange(TabSetting.CLEAR_CACHE)}
              >
                <FormattedMessage id="auth.setting.menu.clearCache" />
              </ItemMenu>
            </Menu>
            <Content>{renderContent}</Content>
          </Body>
        </Container>
      </Modal>
    </>
  );
}

export default ModalSetting;
