import { HeaderCell, TableCellContent } from "./styles";
import { FormattedMessage, IntlShape } from "react-intl";
import moment from "moment";
import numeral from "numeral";

const flexEnd = "flex-end";
// const flexStart = "flex-start";
const center = "center";
export const dataColumns = (intl: IntlShape) => [
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.subscribe.table.Subacc" />
      </HeaderCell>
    ),
    accessor: "subAccNo",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.subscribe.table.codeStock" />
      </HeaderCell>
    ),
    accessor: "secCd",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.subscribe.table.date" />
      </HeaderCell>
    ),
    accessor: "ownerFixDate",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.subscribe.table.ratio" />
      </HeaderCell>
    ),
    accessor: "ratio",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      const ratio = `${props?.row?.original?.rated}/${props?.row?.original?.rates}`;
      return <TableCellContent align={center}>{ratio}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.subscribe.table.dateStart" />
      </HeaderCell>
    ),
    accessor: "beginRegDate",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.subscribe.table.dateEnd" />
      </HeaderCell>
    ),
    accessor: "endRegDate",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.subscribe.table.numberBuy" />
      </HeaderCell>
    ),
    accessor: "appliableQty",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.subscribe.table.numberRegister" />
      </HeaderCell>
    ),
    accessor: "sumApplyQty",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.subscribe.table.price" />
      </HeaderCell>
    ),
    accessor: "price",
    tooltip: intl.formatMessage({ id: "subscribe.tooltip" }),
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {props.value !== null ? numeral(props.value * 1000).format("0,0") : 0}
        </TableCellContent>
      );
    },
  },
];
