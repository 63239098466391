import { FC } from "react";
import MarketField from "./MarketField";
import QuoteIndicator from "./QuoteIndicator";
import FinancialIndicator from "./FinancialIndicator";
import TechnicalIndicator from "./TechnicalIndicator";
import OtherField from "./OtherField";
import Actions from "./Actions";
import { Container, Content } from "./styles";

const ScreenerPanel: FC = () => {
  return (
    <Container>
      <Content>
        <MarketField />
        <QuoteIndicator />
        <FinancialIndicator />
        <TechnicalIndicator />
        <OtherField />
      </Content>
      <Actions />
    </Container>
  );
};

export default ScreenerPanel;
