import { FC } from "react";

import AdvertisingOrder from "./components/AdvertisingOrder";

import { Container } from "./styles";

const BidAskBond: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <AdvertisingOrder />
    </Container>
  );
};

export default BidAskBond;
