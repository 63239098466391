import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const addFrameworkContractFormSelector = (state: RootState) =>
  state.addFrameworkContractForm;

export const getAccount = createSelector(
  addFrameworkContractFormSelector,
  (state) => state.account
);

export const getListContract = createSelector(
  addFrameworkContractFormSelector,
  (state) => state.listContract
);

export const getStatusCode = createSelector(
  addFrameworkContractFormSelector,
  (state) => state.statusCode
);

export const loadingSearchSelector = createSelector(
  addFrameworkContractFormSelector,
  (state) => state.loadingSearch
);
export const loadingSubmitSelector = createSelector(
  addFrameworkContractFormSelector,
  (state) => state.loadingSubmit
);
