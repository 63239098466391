import queryString from "query-string";

export const getListMoneyStatementApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getR9008DataSourceCash${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getListR9008DataSourceSecApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getR9008DataSourceSec${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
