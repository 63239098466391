import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled.div`
  height: 40px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  background: ${({ theme }) => theme.bgElevated3};
  padding: 10px 16px;
  color: ${({ theme }) => theme.textcolorWhite};
  text-align: center;
`;

export const Body = styled.div`
  background-color: ${({ theme }) => theme.grey70};
  padding: 8px 24px;
  word-wrap: break-word;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  height: 76px;
  width: 100%;
  background-color: ${({ theme }) => theme.grey70};
  .flex-1 {
    flex: 1;
  }
  padding: 16px 24px;
`;
