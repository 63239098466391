import styled from "styled-components/macro";

export const Container = styled.div`
  padding-top: 11px;
  height: 44%;
  @media (max-width: 1360px) {
    height: 35%;
  };
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  font-size: 15px;
  background: ${({ theme }) => theme.background3};
  height: ${({ theme }) => theme.cardHeader.height};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const LabelCell = styled.div`
  padding: 8px;
  width: 100%;
  margin: auto;
`;

export const Wrap = styled.div`
  width: 100%;
  height: calc(90% - ${({ theme }) => theme.cardHeader.height});
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
  }
`;

export const WrapNavigation = styled.div`
  display: flex;
  max-height: ${({ theme }) => theme.cardHeader.height};
  align-items: center;
  z-index: 4;
  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
    }
  }
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: 0px 16px;
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  color: ${({ theme }) => theme.textcolorWhite};
  white-space: nowrap;
  background: ${({ theme }) => theme.background2};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ isActive, theme }) => isActive && `color: ${theme.solidBlue};`}
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  
  color: ${({ color = "white" }) => color}};
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.textcolorWhite};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
`;
// border: 1px solid ${({ theme }) => theme.borderColor};

export const ContainerTable = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px;
  overflow: auto;
  position: relative;
  border-right: 1px solid ${({ theme }) => theme.border2};
  border-left: 1px solid ${({ theme }) => theme.border2};
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      height: calc(100% - 30px);

      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;
