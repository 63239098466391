import CommonTable from "components/commons/CommonTable";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import moment from "moment";
import numeral from "numeral";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ContentContainer } from "../styles";
import { dertCondDetailListSelector } from "modules/stock-order-der/selectors";

interface Props {}

const DetailOrderDerCondTable: React.FC<Props> = () => {
  const dertCondDetailList = useSelector(dertCondDetailListSelector);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <TableHeaderCellContent align={"center"}>
      //       <FormattedMessage id="widgets.orderBookDetail.table.tradeDate" />
      //     </TableHeaderCellContent>
      //   ),
      //   accessor: "tradeDate",
      //   Cell: (props: any) => {
      //     return (
      //       <TableCellContent align={"center"}>
      //         {props.value &&
      //           moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
      //       </TableCellContent>
      //     );
      //   },
      // },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="widgets.orderBookDetail.table.tradeType" />
          </TableHeaderCellContent>
        ),
        accessor: "tradeType",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value === 1 && <FormattedMessage id="common.shortUp" />}
              {props.value === 2 && <FormattedMessage id="common.longUp" />}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0") || "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.0") || "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="widgets.orderBookDetail.table.regDateTime" />
          </TableHeaderCellContent>
        ),
        accessor: "regDateTime",
        minWidth: 120,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {moment(props.value?.toString(), "x").format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  return (
    <ContentContainer>
      <CommonTable columns={columns} data={dertCondDetailList} />
    </ContentContainer>
  );
};
export default DetailOrderDerCondTable;
