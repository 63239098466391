import { memo } from "react";
import { IndustryTab } from "./types";
import { ContentContainer } from "components/widgets/generals/MarketWatch/styles";
import TransactionIndustry from "../TransactionIndustry";
import TopIndexIndustry from "../TopIndexIndustry";
export interface ContentProps {
  activeTab: IndustryTab;
}

function Content({ activeTab }: ContentProps) {
  return (
    <ContentContainer>
      {activeTab === "transactionIndustry" && <TransactionIndustry />}
      {activeTab === "topIndex" && <TopIndexIndustry />}
    </ContentContainer>
  );
}

export default memo(Content);
