import { FC } from "react";
import Tabs from "./Tabs";
import Card from "./ResultDisplayCard";
import { Styled, Container } from "./styles";

const LoanExtension: FC = () => {
  return (
    <Styled>
      <Container>
        <Tabs />
        <Card />
      </Container>
    </Styled>
  );
};

export default LoanExtension;
