import { toast } from "components/commons/Toast";
import {
  getInquiryBankUtility,
  entryBankUtility,
} from "core/http/apis/hold-unhold-bank-account";
import {
  IGetInquiryBankUtilityParams,
  IEntryBankUtilityParams,
} from "core/http/apis/hold-unhold-bank-account/types";
import {
  IGetInquiryBankUtilityResponse,
  IEntryBankUtilityResponse,
} from "domain/models/HoldUnholdBankAccount";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getInquiryBankUtilityRequest,
  getInquiryBankUtilitySuccess,
  getInquiryBankUtilityFailure,
  entryBankUtilityRequest,
  entryBankUtilitySuccess,
  entryBankUtilityFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
} from "./redux";

function* getInquiryBankUtilityWorker(
  action: ReturnType<typeof getInquiryBankUtilityRequest>
) {
  try {
    const params: IGetInquiryBankUtilityParams = action.payload;
    const response: IGetInquiryBankUtilityResponse = yield call(
      getInquiryBankUtility,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getInquiryBankUtilitySuccess(response));
    } else {
      yield put(getInquiryBankUtilityFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getInquiryBankUtilityFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* entryBankUtilityWorker(
  action: ReturnType<typeof entryBankUtilityRequest>
) {
  try {
    const params: IEntryBankUtilityParams = action.payload;

    const response: IEntryBankUtilityResponse = yield call(
      entryBankUtility,
      params
    );

    if (response?.statusCode === 0) {
      yield put(entryBankUtilitySuccess(response));
    } else {
      yield put(entryBankUtilityFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(entryBankUtilityFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
      subAccoNo?: string;

      actionType?: number;
      amount?: number;
    } = action.payload;

    if (isVisible) {
    }

    yield put(updateModalStatusSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* getInquiryBankUtilityWatcher() {
  yield takeLatest(
    getInquiryBankUtilityRequest.type,
    getInquiryBankUtilityWorker
  );
}

function* entryBankUtilityWatcher() {
  yield takeLatest(entryBankUtilityRequest.type, entryBankUtilityWorker);
}

function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}
export default function* advanceLimitSaga() {
  yield all([
    getInquiryBankUtilityWatcher(),

    updateModalStatusWatcher(),
    entryBankUtilityWatcher(),
  ]);
}
