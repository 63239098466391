import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-left: 8px;
  padding-bottom: 12px;
  gap: 4px;
`;
