import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const rightInfoSelector = (state: RootState) => state.rightInfo;

export const activeTabSelector = createSelector(
  rightInfoSelector,
  (state) => state.activeTab
);

export const listRightInfoSelector = createSelector(
  rightInfoSelector,
  (state) => state.listRightInfo
);

export const loadingSearchDataSelector = createSelector(
  rightInfoSelector,
  (state) => state.loadingSearchData
);

export const filterRightInfoSelector = createSelector(
  rightInfoSelector,
  (state) => state.filter
);
