import CommonTableInfinity from "components/commons/CommonTableInfinity";

import { ISearchTransferBondParams } from "core/http/apis/transfer-bond/types";
import {
  listTransferBondSelector,
  statusCancelSelector,
  filterTransferBondSelector,
  statusValidateTimeSelector,
  itemDetailSelector,
} from "modules/transfer-bond/selectors";
import {
  searchTransferBondRequest,
  cancelTransferBondRequest,
  validateTimeToTransferBondRequest,
  updateModalStatusRequest,
  updateFilter,
  //   liquidateContractRequest,
} from "modules/transfer-bond/redux";
import { TransferBond } from "domain/models/TransferBond";

import moment from "moment";
import { memo, useMemo, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ActionsRow,
  ButtonCell,
  ButtonConfirm,
  ButtonCancel,
} from "./styles";
import numeral from "numeral";

import DetailModal from "./DetailModal/index";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";

import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import {
  updateModalOtpLiquidRequest,
  updateModalOtpRequest,
} from "modules/advance/redux";
import {
  isShowOtpModalLiquidSelector,
  isShowOtpModalSelector,
} from "modules/advance/selectors";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";

// import { MarginDebitContractWatch } from "domain/models/ContractDebit";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listTransferBondSelector);
  const itemDetailRow = useSelector(itemDetailSelector);
  const themeType = useSelector(themeTypeSelector);
  const statusCancel = useSelector(statusCancelSelector);
  const statusValidateTime = useSelector(statusValidateTimeSelector);
  const isVisibleOtp = useSelector(isShowOtpModalLiquidSelector);
  const isVisible = useSelector(isShowOtpModalSelector);

  const { fromDate, toDate } = useSelector(filterTransferBondSelector);
  const center = "center";
  const flexEnd = "flex-end";
  const flexStart = "flex-start";
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [transferBondSelected, setTransferBondSelected] = useState<
    TransferBond | undefined
  >(undefined);

  useEffect(() => {
    if (statusCancel) {
      const submitData: ISearchTransferBondParams = {
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        status: -1,
      };
      dispatch(searchTransferBondRequest(submitData));
    }
  }, [statusCancel]);

  const clickCancelHandler = (item: TransferBond) => {
    setTransferBondSelected(item);
    setVisibleModalConfirm(true);
  };
  const clickConfirmHandler = (item: TransferBond) => {
    setTransferBondSelected(item);
    dispatch(validateTimeToTransferBondRequest());
  };
  useEffect(() => {
    if (statusValidateTime) {
      dispatch(
        updateModalStatusRequest({
          isVisible: true,
          itemDetail: transferBondSelected,
        })
      );
    }
  }, [statusValidateTime]);

  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    if (transferBondSelected) {
      dispatch(
        cancelTransferBondRequest({
          actionMode: "2",
          alloDate: transferBondSelected.alloDate.toString()
            ? transferBondSelected.alloDate.toString()
            : "",
          refNo: transferBondSelected.refNo.toString()
            ? transferBondSelected.refNo.toString()
            : "",

          timeStamp: transferBondSelected.lastUpdTime
            ? transferBondSelected.lastUpdTime
            : "",
          otpCode: response?.otp ?? "",
          otpType: response?.otpTypeSubmit!,
        })
      );
      dispatch(
        updateFilter({
          key: "fromDate",
          value: fromDate,
        })
      );
      dispatch(
        updateFilter({
          key: "toDate",
          value: toDate,
        })
      );
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
    }
  };

  const setConfirm = () => {
    dispatch(updateModalOtpLiquidRequest({ isVisible: true }));
  };

  const handleCloseOTPModal1 = () => {
    dispatch(updateModalOtpLiquidRequest({ isVisible: false }));
  };
  const doSubmitAdvance1 = (response: IVerifyResponse) => {
    dispatch(updateModalOtpLiquidRequest({ isVisible: false }));

    if (transferBondSelected) {
      dispatch(
        cancelTransferBondRequest({
          actionMode: "4",
          alloDate: transferBondSelected.alloDate.toString() ?? "",
          refNo: transferBondSelected.refNo.toString() ?? "",

          timeStamp: transferBondSelected.lastUpdTime ?? "",
          otpCode: response?.otp ?? "",
          otpType: response?.otpTypeSubmit!,
        })
      );
    }
  };
  function changeColor(value: number) {
    if (value === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="transferBond.status.confirmed" />
        </TableCellContent>
      );
    else if (value === 0)
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="transferBond.status.wait_approve" />
        </TableCellContent>
      );
    else if (value === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGrey}>
          <FormattedMessage id="transferBond.status.rejected" />
        </TableCellContent>
      );
    else if (value === 9)
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="transferBond.status.deleted" />
        </TableCellContent>
      );

    return "";
  }
  const convertNumberToDateFormat = ({
    date,
    inputFormat = "YYYYMMDD",
    outputFormat = "DD/MM/YYYY",
  }: {
    date?: number;
    inputFormat?: string;
    outputFormat?: string;
  }) => {
    if (!date) return "";

    return moment(new Date(date), inputFormat).format(outputFormat);
  };

  const getDisplay = (status: number) => {
    switch (status) {
      case 1:
        return "transfer";
      case 2:
        return "receive";
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.confirmReject" />
          </HeaderCell>
        ),
        accessor: "a",
        minWidth: 180,
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              {currentRow.status === 0 && (
                <ActionsRow>
                  <ButtonCell>
                    <ButtonConfirm
                      onClick={() => clickConfirmHandler(currentRow)}
                    >
                      <FormattedMessage id="transferBond.table.confirm" />
                    </ButtonConfirm>
                  </ButtonCell>
                  |
                  <ButtonCell>
                    <ButtonCancel
                      onClick={() => clickCancelHandler(currentRow)}
                    >
                      <FormattedMessage id="transferBond.table.cancel" />
                    </ButtonCancel>
                  </ButtonCell>
                </ActionsRow>
              )}
            </div>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.createDate" />
          </HeaderCell>
        ),
        accessor: "regDateTime",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,

        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <TableCellContent align={center}>
              {changeColor(currentRow.status)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.accountTransfer" />
          </HeaderCell>
        ),
        accessor: "fromSubAccoNo",
        minWidth: 100,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.accountName" />
          </HeaderCell>
        ),
        accessor: "fromCustName",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.bondCode" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferBond.table.qty" />
          </HeaderCell>
        ),
        accessor: "quantity",
        minWidth: 80,

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferBond.table.amount" />
          </HeaderCell>
        ),
        accessor: "amount",
        minWidth: 80,
        Cell: (props: any) => {
          let row = props?.row?.original;

          return (
            <TableCellContent align={flexEnd}>
              {numeral(row.transferAmt / row.quantity).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferBond.table.amountByPrice" />
          </HeaderCell>
        ),
        accessor: "parAmt",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferBond.table.transferAmount" />
          </HeaderCell>
        ),
        accessor: "transferAmt",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferBond.table.fee" />
          </HeaderCell>
        ),
        accessor: "feeAmt",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.feePay" />
          </HeaderCell>
        ),
        accessor: "feePayer",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "transferBond.table.payer." + getDisplay(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferBond.table.taxValue" />
          </HeaderCell>
        ),
        minWidth: 80,
        accessor: "taxAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferBond.table.taxPay" />
          </HeaderCell>
        ),
        accessor: "taxPayer",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "transferBond.table.payer." + getDisplay(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferBond.table.remarks" />
          </HeaderCell>
        ),
        minWidth: 140,
        accessor: "remarks",

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="transferBondTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"transferBondTable"}
        isHasFooter={false}
      />
      {isVisibleOtp && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal1}
          getResult={doSubmitAdvance1}
          businessCd={EBusinessCd.TRADING}
        />
      )}
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage(
          { id: "transferBond.message.cancelContent" },
          {
            number: transferBondSelected?.bondCd,
            number2: transferBondSelected?.quantity,
          }
        )}
      />
      {itemDetailRow && <DetailModal itemDetailRow={itemDetailRow} />}
      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </Container>
  );
}

export default memo(RequestTable);
