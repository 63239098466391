import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISearchDepositContractParams,
  ILiquidateDepositContractParams,
  IFindDepositPaymentParams,
  IFindDepositContractParams,
  IGetEarlyInterestRateParams,
  IGetInterestAmountParams,
  IUpdateDepositPaymentParams,
  IUpdateDepositContractParams,
  IGetDepositInquiryParams,
  IGetDueDateDepositContractParams,
  //   IEditCreateContractParams,
} from "core/http/apis/deposit-contract/types";
import {
  DepositContract,
  DptDepositPayment,
  DetailModal,
  interestRate,
  ISearchDepositContractResponse,
  ILiquidateDepositContractResponse,
  IFindDepositPaymentResponse,
  IFindDepositContractResponse,
  IGetEarlyInterestRateResponse,
  itemEdit,
  itemDepositContractForm,
  IGetInterestAmountResponse,
  IUpdateDepositContractResponse,
  IUpdateDepositPaymentResponse,
  IGetDepositInquiryResponse,
  IGetDueDateDepositContractResponse,
  interestRateItem,

  //   ICreateContractResponse,
} from "domain/models/DepositContract";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  contractSts: number;
  fromDate: Date;
  toDate: Date;
  subAccount: string;
}

export interface ReduxData {
  filter: IFilter;
  list: DepositContract[];
  listLiquidateModal: DptDepositPayment[];
  detailModal: DetailModal[];
  loading: boolean;
  activeTab: string;
  statusLiquidate: boolean;
  itemDetail?: DepositContract;
  itemDetailRow?: DepositContract;
  isShowLiquidateModal: boolean;
  isShowDetailModal: boolean;
  isShowConfirmModal?: boolean;
  itemEdit: itemEdit;
  interestRate: interestRate;
  statusInterestRate: boolean;
  interestAmount: number;
  statusUpdateDepositPayment: boolean;
  statusUpdateDepositContract: boolean;
  itemForm: itemDepositContractForm;
  listInterestRateItem: interestRateItem[];
  statusGetDueDate: boolean;
  //   statusCreateContract: boolean;
  //   createContract?: IEditCreateContractParams;
}

const initialState: ReduxData = {
  filter: {
    contractSts: 0,
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
    subAccount: "",
  },
  list: [],
  loading: false,
  activeTab: "CREATE_CONTRACT",
  statusLiquidate: false,
  isShowLiquidateModal: false,
  isShowDetailModal: false,
  isShowConfirmModal: false,
  listLiquidateModal: [],
  detailModal: [],
  itemEdit: {},
  interestRate: {
    grossRate: 0,
    netRate: 0,
  },
  statusInterestRate: false,
  interestAmount: 0,
  //   isShowConfirmModal: false,
  statusUpdateDepositPayment: false,
  statusUpdateDepositContract: false,
  //   statusCreateContract: false,
  itemForm: {
    subAccount: "",

    availableBalance: 0,
    periodType: 0,
    period: 0,
    loanDate: "",
    dueDate: "",
    grossRate: 0,
    netRate: 0,
    calculate: 0,
    loanEstimate: 0,
    turnOverType: 1,
    remarks: "",
  },
  listInterestRateItem: [],
  statusGetDueDate: false,
};

const depositContractSlice = createSlice({
  name: "depositContractSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchDepositContractRequest: (
      state,
      action: PayloadAction<ISearchDepositContractParams>
    ) => {
      state.loading = true;
    },
    searchDepositContractSuccess: (
      state,
      action: PayloadAction<ISearchDepositContractResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchDepositContractFailure: (state) => {
      state.loading = false;
    },
    getEarlyInterestRateRequest: (
      state,
      action: PayloadAction<IGetEarlyInterestRateParams>
    ) => {
      state.statusInterestRate = false;
    },
    getEarlyInterestRateSuccess: (
      state,
      action: PayloadAction<IGetEarlyInterestRateResponse>
    ) => {
      state.interestRate = action.payload.data ?? {};
      state.statusInterestRate = true;
    },
    getEarlyInterestRateFailure: (state) => {
      state.statusInterestRate = false;
    },

    getInterestAmountRequest: (
      state,
      action: PayloadAction<IGetInterestAmountParams>
    ) => {},
    getInterestAmountSuccess: (
      state,
      action: PayloadAction<IGetInterestAmountResponse>
    ) => {
      state.itemForm.loanEstimate = action.payload.data ?? 0;
    },
    getInterestAmountFailure: (state) => {},

    getInterestAmount2Request: (
      state,
      action: PayloadAction<IGetInterestAmountParams>
    ) => {},
    getInterestAmount2Success: (
      state,
      action: PayloadAction<IGetInterestAmountResponse>
    ) => {
      state.interestAmount = action.payload.data ?? 0;
    },
    getInterestAmount2Failure: (state) => {},
    findDepositPaymentRequest: (
      state,
      action: PayloadAction<IFindDepositPaymentParams>
    ) => {},
    findDepositPaymentSuccess: (
      state,
      action: PayloadAction<IFindDepositPaymentResponse>
    ) => {
      state.listLiquidateModal = action.payload.data ?? [];
    },
    findDepositPaymentFailure: (state) => {},

    findDepositContractRequest: (
      state,
      action: PayloadAction<IFindDepositContractParams>
    ) => {},
    findDepositContractSuccess: (
      state,
      action: PayloadAction<IFindDepositContractResponse>
    ) => {
      state.detailModal = action.payload.data ?? [];
    },
    findDepositContractFailure: (state) => {},

    liquidateDepositContractRequest: (
      state,
      action: PayloadAction<ILiquidateDepositContractParams>
    ) => {
      state.statusLiquidate = false;
    },
    liquidateDepositContractSuccess: (
      state,
      action: PayloadAction<ILiquidateDepositContractResponse>
    ) => {
      state.statusLiquidate = true;
    },
    liquidateDepositContractFailure: (state) => {
      state.statusLiquidate = false;
    },
    updateLiquidateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: DepositContract;
      }>
    ) => {},
    updateLiquidateModalStatusSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: DepositContract;
      }>
    ) => {
      state.itemDetail = action.payload.itemDetail && action.payload.itemDetail;
      state.isShowLiquidateModal = action.payload.isVisible;
    },
    updateDetailModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: DepositContract;
      }>
    ) => {},
    updateDetailModalStatusSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: DepositContract;
      }>
    ) => {
      state.itemDetail = action.payload.itemDetail && action.payload.itemDetail;
      state.isShowDetailModal = action.payload.isVisible;
    },

    updateModalConfirmRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;

        itemDetail?: DepositContract;
      }>
    ) => {},
    updateModalConfirmSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: DepositContract;
      }>
    ) => {
      state.itemDetail = action.payload.itemDetail && action.payload.itemDetail;
      state.isShowConfirmModal = action.payload.isVisible;
    },

    updateDepositPaymentRequest: (
      state,
      action: PayloadAction<IUpdateDepositPaymentParams>
    ) => {
      state.statusUpdateDepositPayment = false;
    },
    updateDepositPaymentSuccess: (
      state,
      action: PayloadAction<IUpdateDepositPaymentResponse>
    ) => {
      state.statusUpdateDepositPayment = true;
    },
    updateDepositPaymentFailure: (state) => {
      state.statusUpdateDepositPayment = false;
    },
    updateDepositContractRequest: (
      state,
      action: PayloadAction<IUpdateDepositContractParams>
    ) => {
      state.statusUpdateDepositContract = false;
    },
    updateDepositContractSuccess: (
      state,
      action: PayloadAction<IUpdateDepositContractResponse>
    ) => {
      state.statusUpdateDepositContract = true;
    },
    updateDepositContractFailure: (state) => {
      state.statusUpdateDepositContract = false;
    },

    // createContractRequest: (
    //   state,
    //   action: PayloadAction<ICreateContractParams>
    // ) => {
    //   state.statusCreateContract = false;
    // },
    // createContractSuccess: (
    //   state,
    //   action: PayloadAction<ICreateContractResponse>
    // ) => {
    //   state.statusCreateContract = true;
    // },
    // createContractFailure: (state) => {
    //   state.statusCreateContract = false;
    // },

    getDepositInquiryRequest: (
      state,
      action: PayloadAction<IGetDepositInquiryParams>
    ) => {},
    getDepositInquirySuccess: (
      state,
      action: PayloadAction<IGetDepositInquiryResponse>
    ) => {
      state.itemForm.availableBalance = action.payload.data.avail;
      state.listInterestRateItem = action.payload.data.interestRateList;
    },
    getDepositInquiryFailure: (state) => {},
    getDueDateDepositContractRequest: (
      state,
      action: PayloadAction<IGetDueDateDepositContractParams>
    ) => {
      state.statusGetDueDate = false;
    },
    getDueDateDepositContractSuccess: (
      state,
      action: PayloadAction<IGetDueDateDepositContractResponse>
    ) => {
      state.itemForm.dueDate = action.payload.data.toString();
      state.statusGetDueDate = true;
    },
    getDueDateDepositContractFailure: (state) => {
      state.statusGetDueDate = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateItemEdit: (state, action: PayloadAction<itemEdit | undefined>) => {
      state.itemEdit = {
        ...state.itemEdit,
        ...action.payload,
      };
    },
    resetValueModal: (state) => {
      state.interestAmount = 0;
      state.interestRate = {
        grossRate: 0,
        netRate: 0,
      };
    },
    updateItemForm: (
      state,
      action: PayloadAction<itemDepositContractForm | undefined>
    ) => {
      state.itemForm = {
        ...state.itemForm,
        ...action.payload,
      };
    },
  },
});

export const {
  searchDepositContractFailure,
  searchDepositContractRequest,
  searchDepositContractSuccess,
  liquidateDepositContractRequest,
  liquidateDepositContractSuccess,
  liquidateDepositContractFailure,
  updateLiquidateModalStatusRequest,
  updateLiquidateModalStatusSuccess,
  updateDetailModalStatusRequest,
  updateDetailModalStatusSuccess,
  updateModalConfirmRequest,
  updateModalConfirmSuccess,

  findDepositPaymentRequest,
  findDepositPaymentSuccess,
  findDepositPaymentFailure,
  findDepositContractRequest,
  findDepositContractSuccess,
  findDepositContractFailure,
  getEarlyInterestRateRequest,
  getEarlyInterestRateSuccess,
  getEarlyInterestRateFailure,
  getInterestAmountSuccess,
  getInterestAmountRequest,
  getInterestAmountFailure,
  getInterestAmount2Request,
  getInterestAmount2Success,
  getInterestAmount2Failure,
  updateDepositPaymentRequest,
  updateDepositPaymentSuccess,
  updateDepositPaymentFailure,
  updateDepositContractRequest,
  updateDepositContractSuccess,
  updateDepositContractFailure,
  getDepositInquiryRequest,
  getDepositInquirySuccess,
  getDepositInquiryFailure,
  getDueDateDepositContractRequest,
  getDueDateDepositContractSuccess,
  getDueDateDepositContractFailure,

  //   createContractRequest,
  //   createContractSuccess,
  //   createContractFailure,

  //   updateItemEdit,
  updateFilter,
  updateItemEdit,
  updateItemForm,
  resetValueModal,
} = depositContractSlice.actions;

export default depositContractSlice.reducer;
