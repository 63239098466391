import { useSelector } from "react-redux";
import numeral from "numeral";
import { TableHeaderCellContent } from "components/commons/CommonTable/styles";
import { summaryPortfolioSelector } from "modules/stock-order/redux/selectors";
import { getColor } from "..";

const UnrealizedProfitRateNumFooter = () => {
  const summaryPortfolio = useSelector(summaryPortfolioSelector);
  return (
    <>
      <TableHeaderCellContent
        align="flex-end"
        className="portfolio-profitRatio"
        color={getColor(summaryPortfolio.totalUnrealizedprofitrate)}
        isFooter
      >
        {numeral(summaryPortfolio.totalUnrealizedprofitrate).format("0,0.00%")}
      </TableHeaderCellContent>
    </>
  );
};

export default UnrealizedProfitRateNumFooter;
