import { FC } from "react";
import { ChangePricePercent as ChangePricePercentWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { TopStatisticalState } from "modules/market/redux/topStatistical";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { marketTopStatisticalSelector } from "modules/market/redux/selectors";

const changePricePercentageSelector = (tickerName: string) => {
  return createSelector(
    marketTopStatisticalSelector,
    (topStatisticalState: TopStatisticalState) =>
      topStatisticalState?.data?.[tickerName]?.changepercent
  );
};

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopStatisticalSelector,
    (topStatisticalState: TopStatisticalState) =>
      topStatisticalState?.data?.[tickerName]?.colorcode
  );
};

interface Props {
  dataRow: string;
}

const ChangePricePercentageCell: FC<Props> = ({ dataRow }) => {
  const changePricePercentage = useSelector(
    changePricePercentageSelector(dataRow)
  );
  const textColor = useSelector(textColorSelector(dataRow));

  return (
    <div className="d-flex align-items-baseline">
      <ChangePricePercentWrapper textcolor={textColor}>
        <BlinkableCell
          value={numeral(changePricePercentage).format("0,0.00")}
        />
      </ChangePricePercentWrapper>
    </div>
  );
};

export { ChangePricePercentageCell };
