/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Input,
  Select,
  OTPByBusinessCd,
  InputTextarea,
} from "components/commons";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmModal from "./ConfirmModal";
import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailRow,
  DetailWrapper,
  TextDetail,
  DetailContentWrapper,
  FooterForm,
} from "./styles";
import { FORM_LEFT, optiosForm } from "modules/subscribeBuy/constants";
import { useDispatch, useSelector } from "react-redux";
import { OptionSelectType } from "components/commons/Select";
import {
  getDataSubscribe,
  resetDetail,
  setFormType,
} from "modules/subscribeBuy/redux";
import {
  selectDetail,
  selectSearchSubscribe,
  selectTypeForm,
} from "modules/subscribeBuy/selectors";
import { GetAccoList } from "helper/sessionData";
import {
  EBusinessCd,
  EntrustBusinessCode,
  GetAccountType,
} from "helper/consts";
import {
  createCapitalIncrease,
  getInquiryCapitalIncrese,
  getRemainQuantity,
} from "core/http/apis/subscribeBuy";
import { toast } from "components/commons/Toast";
import numeral from "numeral";
import moment from "moment";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const RequestDetail = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const optionTypeForm = useMemo(() => optiosForm(intl), [intl]);

  const typeForm = useSelector(selectTypeForm);
  const valuetypeForm = useMemo(() => {
    return optionTypeForm.filter((e) => e.value === typeForm);
  }, [typeForm]);

  const { secCd, alloDate, refNo, subAccoNo, price } =
    useSelector(selectDetail);

  const [modal, setModal] = useState({
    status: false,
  });

  const initialState = {
    applyQty: "",
    remarks: "",
  };

  const [form, setForm] = useState(initialState);

  const changeForm = (value: any, key: string) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const [avail, setAvail] = useState(0);

  const changeAccountGetHandler = async () => {
    const res: any = await getInquiryCapitalIncrese({ subAccoNo: subAccoNo });
    if (res?.statusCode === 0) {
      setAvail(res?.data?.transferAvail);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const [remainQty, setRemainQty] = useState(0);

  const getRemainQty = async () => {
    const account = listAcc.find((child) => child.subAccoNo === subAccoNo);
    const res: any = await getRemainQuantity({
      subAccCd: account?.subAccoCd ?? 0,
      parentDate: alloDate,
      parentNo: refNo,
      actionType: typeForm === FORM_LEFT.ADD ? 1 : 2,
    });
    if (res?.statusCode === 0) {
      setRemainQty(res?.data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    if (subAccoNo !== "") {
      changeAccountGetHandler();
    }
  }, [subAccoNo]);

  useEffect(() => {
    if (subAccoNo !== "") {
      getRemainQty();
    }
  }, [subAccoNo, typeForm, alloDate, secCd]);

  const search = useSelector(selectSearchSubscribe);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const handleModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitCancelOrder = (response: IVerifyResponse) => {
    addData(response);
  };

  const addData = async (IVerifyResponse: IVerifyResponse) => {
    const account = listAcc.find((child) => child.subAccoNo === subAccoNo);
    const params = {
      subAccoCd: account?.subAccoCd ?? 0,
      parentDate: Number(alloDate),
      parentNo: Number(refNo),
      actionType: typeForm === FORM_LEFT.ADD ? 1 : 2,
      applyDate: Number(moment(new Date()).format("YYYYMMDD")),
      applyQty: Number(form.applyQty),
      transferAvail: Number(price),
      amount: Number(numeral(form.applyQty).value()) * Number(price),
      remarks: form?.remarks ?? "",
      otpCode: IVerifyResponse.otp,
      otpType: IVerifyResponse?.otpTypeSubmit!,
    };
    const res = await createCapitalIncrease(params);
    const resLogData = {
      eventGroup: GroupEvent.exerciseRegisterRights,
      event: RealtimeConst.postExerciseRight,
      message: JSON.stringify(params),
    };

    storage.commonPushLogFirebase(resLogData);

    if (res?.statusCode === 0) {
      setModal({ status: false });
      if (typeForm === FORM_LEFT.ADD) {
        toast("subscribe.formAdd.messageSuccess", "success");
      } else {
        toast("subscribe.formRemove.messageSuccess", "success");
      }
      dispatch(
        getDataSubscribe({
          subAccoNo: search.subAccoNo
            ? search.subAccoNo
            : accountOptions[0].value,
          SecCd: search.SecCd ?? "",
        })
      );
      resetData();
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const resetData = () => {
    dispatch(resetDetail());
    setAvail(0);
    setForm(initialState);
    setRemainQty(0);
  };

  const openPopup = () => {
    if (Number(numeral(form.applyQty).value()) <= 0) {
      return toast("subscribe.formAdd.validate.error1", "error");
    }
    if (Number(numeral(form.applyQty).value()) > Number(remainQty)) {
      if (typeForm === FORM_LEFT.ADD) {
        return toast("subscribe.formAdd.validate.error2", "error");
      }
      return toast("subscribe.formAdd.validate.error3", "error");
    }
    if (
      typeForm === FORM_LEFT.ADD &&
      Number(form.applyQty) * Number(price) > avail
    ) {
      return toast("subscribe.formAdd.validate.error4", "error");
    }
    setModal({ status: !modal.status });
  };

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="subscribe.formAdd.action" />
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              className="flex-fill"
              value={valuetypeForm}
              options={optionTypeForm}
              onChange={(e: OptionSelectType | null) =>
                dispatch(setFormType({ type: e?.value ?? "" }))
              }
            />
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="subscribe.formAdd.codeCk" />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            <TextDetail>{secCd}</TextDetail>
          </DetailContentWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="subscribe.formAdd.Subacc" />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            <TextDetail>{subAccoNo}</TextDetail>
          </DetailContentWrapper>
        </DetailRow>

        {typeForm === FORM_LEFT.ADD && (
          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="subscribe.formAdd.moneyAvailability" />
            </DetailLabel>
            <DetailContentWrapper className="text-right">
              <TextDetail>{numeral(avail).format("0,0")}</TextDetail>
            </DetailContentWrapper>
          </DetailRow>
        )}

        <DetailRow>
          <DetailLabel>
            <FormattedMessage
              id={
                typeForm === FORM_LEFT.ADD
                  ? "subscribe.formAdd.numberCanRegister"
                  : "subscribe.formRemove.numberCanCancel"
              }
            />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            <TextDetail>{numeral(remainQty).format("0,0")}</TextDetail>
          </DetailContentWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage
              id={
                typeForm === FORM_LEFT.ADD
                  ? "subscribe.formAdd.numberRegister"
                  : "subscribe.formRemove.numberCancel"
              }
            />
          </DetailLabel>
          <DetailInputWrapper>
            <Input
              placeholder={intl.formatMessage({
                id: "subscribe.formAdd.numberRegisterPlaceholder",
              })}
              rules={{ required: true }}
              value={
                form?.applyQty ? numeral(form?.applyQty).format("0,0") : ""
              }
              onChange={(e) => changeForm(e.target.value, "applyQty")}
            />
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="subscribe.formAdd.price" />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            {price !== null ? numeral(price * 1000).format("0,0") : 0}
          </DetailContentWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="subscribe.formAdd.intoMoney" />
          </DetailLabel>
          <DetailContentWrapper className="text-right">
            <TextDetail>
              {numeral(
                Number(numeral(form.applyQty).value()) * Number(price) * 1000
              ).format("0,0")}
            </TextDetail>
          </DetailContentWrapper>
        </DetailRow>

        <DetailRow alignCenter={false}>
          <DetailLabel>
            <FormattedMessage id="subscribe.formAdd.note" />
          </DetailLabel>
          <DetailInputWrapper>
            <InputTextarea
              placeholder={intl.formatMessage({
                id: "subscribe.formAdd.notePlaceholder",
              })}
              textArea
              value={form.remarks}
              onChange={(e) => changeForm(e.target.value, "remarks")}
            />
          </DetailInputWrapper>
        </DetailRow>
      </DetailCol>
      <FooterForm className="d-flex justify-content-center p-2">
        <Button
          color="accept"
          className="button-filter w-50"
          onClick={() => resetData()}
          // loading={loadding}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.cancel",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter ml-2 w-50"
          // loading={loadding}
          onClick={() => openPopup()}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.accept",
          })}
        </Button>
      </FooterForm>
      <ConfirmModal
        isVisible={modal.status}
        onClose={() => setModal({ status: !modal.status })}
        form={form}
        onSuccess={() => setVisibleModalOTP(true)}
      />

      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleModal}
          getResult={doSubmitCancelOrder}
          businessCd={EBusinessCd.CAP_INCREASE}
        />
      )}
    </DetailWrapper>
  );
};

export default RequestDetail;
