import { EIndicatorType } from "modules/tickerDetails/redux/financeInfo";

export enum TimeOptionValue {
  yearlyList = "1",
  quarterlyList = "2",
}
export type TimeOption = {
  label: string;
  value: TimeOptionValue;
};
export type IndicatorLabel = "roe" | "roa" | "eps";

export type IndicatorOption = {
  label: IndicatorLabel;
  value: EIndicatorType;
};

export type RevenueLabel = "netincome" | "netrevenue" | "operatingincome";

export type RevenueOption = {
  label: RevenueLabel;
  value: EIndicatorType;
};

export type CashFlowLabel =
  | "cashflowoperating"
  | "cashflowinvesting"
  | "cashflowfinance";

export type CashFlowOption = {
  label: CashFlowLabel;
  value: EIndicatorType;
};
