import VgaiaHttp from "core/http/singleton/vgaia";
import {
  ekycControllerOcrIdRequest,
  ekycControllerOcrIdResponse,
  postEkycFaceCompareRequest,
  postEkycFaceCompareResponse,
  postEkycFaceLivenessRequest,
  postEkycFaceLivenessResponse,
  postEkycFaceServiceVerifyRequest,
  uploadImageEkycResponse,
} from "./types";
import {
  urlEkycUploads,
  ocrId,
  faceLiveness,
  faceCompare,
  faceServiceVerify,
} from "./urls";

export const uploadImageEkyc = async (
  data: FormData
): Promise<uploadImageEkycResponse> => {
  const res = await VgaiaHttp.post<uploadImageEkycResponse>(
    urlEkycUploads,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const ekycControllerOcrId = async (
  data: ekycControllerOcrIdRequest
): Promise<ekycControllerOcrIdResponse> => {
  const res = await VgaiaHttp.post<ekycControllerOcrIdResponse>(ocrId, data, {
    headers: { "Content-Type": "application/json" },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postEkycFaceLiveness = async (
  data: postEkycFaceLivenessRequest
): Promise<postEkycFaceLivenessResponse> => {
  const res = await VgaiaHttp.post<postEkycFaceLivenessResponse>(
    faceLiveness,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postEkycFaceCompare = async (
  data: postEkycFaceCompareRequest
): Promise<postEkycFaceCompareResponse> => {
  const res = await VgaiaHttp.post<postEkycFaceCompareResponse>(
    faceCompare,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postEkycFaceServiceVerify = async (
  data: postEkycFaceServiceVerifyRequest
): Promise<postEkycFaceCompareResponse> => {
  const res = await VgaiaHttp.post<postEkycFaceCompareResponse>(
    faceServiceVerify,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
