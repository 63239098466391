import styled from "styled-components/macro";

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.background2};
  .flex-1 {
    flex: 1;
  }
  padding: 0px 24px 16px 24px;
  .flex-1 {
    flex: 1;
  }
  .btn-success {
    background: ${({ theme }) => theme.green50};
  }
  .btn-danger {
    background: ${({ theme }) => theme.red50};
  }
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.border1};
`;

export const Title = styled.div<{ side?: number }>`
  height: 40px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  background: ${({ theme }) => theme.background3};
  padding: 10px 16px;
  color: ${({ theme, side }) => (side === 1 ? theme.red50 : theme.green50)};
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;

export const Body = styled.div`
  background-color: ${({ theme }) => theme.background2};
  padding: 24px;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
`;
