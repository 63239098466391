import styled from "styled-components";

export const ContainerTable = styled.div`
  width: 100%;
  height: 320px;
  background-color: ${({ theme }) => theme.background1};

  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 4px;

  background: ${({ theme }) => theme.background3};
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  padding: 6px 8px;
  gap: 12px;
  align-items: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal.lineHeight};
`;
export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 6px 8px;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  cursor: pointer;
`;
export const BodyChild = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Body = styled.div`
  width: 100%;
  height: 294px;
  background-color: ${({ theme }) => theme.background2};
  border-radius: 4px;
  overflow: scroll;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;
export const Width80 = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Width80Right = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: right;
`;
export const Width80Body = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;
export const Width80BodyChild = styled.div`
  width: 80px;
`;
export const Width80BodyRight = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;
export const Width100 = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: right;
`;
export const Width100Body = styled.div`
  width: 100px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
  display: flex;
  align-items: center;
  justify-content: right;
`;
export const Width100BodyChild = styled.div`
  width: 100px;
`;
export const Width354 = styled.div`
  width: 354px;
`;
export const Width354BodyChild = styled.div`
  width: 354px;
`;
export const Width354Body = styled.div`
  width: 354px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;
export const TitleChildContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.grey40};
  background-color: ${({ theme }) => theme.background1};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  padding: 8px;
`;
