import queryString from "query-string";

export const getCustomer2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomer2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getSubAccoNo2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findSubAccountByNo${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getBondsListApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findBondList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getBondsAvailSellApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getBondAvailSell${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getListTransferBondlApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findCbotBondTransferRequest${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const calculateBondFeeApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/calculateBondFee${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const updateCbotBondTransferApi = `/updateCbotBondTransferRequest`;

export const checkTimeExecuteApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/validateTimeToTransferBond${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
