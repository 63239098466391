import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, Spacer, Select, OTPByBusinessCd } from "components/commons";
import React, { FC, memo, useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {
  PopConfirmOrderBody,
  PopConfirmOrderContainer,
  PopConfirmOrderTitle,
  ButtonExit,
  Icon,
  Container,
  Label,
  Content,
  Filter,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import numeral from "numeral";
import { PortfolioItemSellAll } from "domain/models/Portfolio";
import { tickerListSelector } from "modules/categories/selectors";
import { useSelector, useDispatch } from "react-redux";
import { CellProps } from "react-table";
import { getMarketLabel } from "helper/utils";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import {
  State as PortfolioState,
  changePercentSelected,
  changeSelectedRows,
  updateAccountPortfolio,
  requestSellAllPortfolio,
  changeMarketSelected,
  sellAllPortfolioOrderClear,
  onLoadListPortfolio,
} from "modules/stock-order/redux/portfolio";
import SelectTable from "components/commons/SelectTable";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import {
  EBusinessCd,
  EntrustBusinessCode,
  MarketSession,
  GetAccountType,
  UNIT_NUMBER,
} from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import {
  IPreSubmitOrderModel,
  OrderSingleParams,
  ParamsSellAll,
} from "core/http/apis/orderexec/types";
// import { v4 as uuidv4 } from "uuid";
import { getStockPrice } from "core/http/apis/tradeinfo";
import { checkPlaceOrder } from "helper/tradingHelper";
import { toast } from "components/commons/Toast";
import { onLoadList } from "modules/order-book/redux/index";
import ModalConfirmSellAll from "../ModalConfirmSellAll/ModalConfirmSellAll";
import { PortfolioItem } from "domain/models/Portfolio";
import { GetAccoList } from "helper/sessionData";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handleCloseModal: any;
  datalist: PortfolioItem[];
}

const percentList = [
  {
    label: "100%",
    value: "1",
  },
  {
    label: "50%",
    value: "0.5",
  },
  {
    label: "20%",
    value: "0.2",
  },
  {
    label: "10%",
    value: "0.1",
  },
];

const marketList = [
  {
    label: "HSX",
    value: "HSX",
  },
  {
    label: "HNX",
    value: "HNX",
  },
  {
    label: "UPCOM",
    value: "UPCOM",
  },
];

const getMarketCd = (market: string) => {
  switch (market) {
    case "HSX":
      return 100;
    case "HNX":
      return 200;
    case "UPCOM":
      return 300;
    default:
      return 100;
  }
};

const dataSelector = (state: RootState) => state;

const portfolioSelector = createSelector(
  dataSelector,
  (state: RootState) => state.stockOrder.portfolio
);

const accountSelector = createSelector(
  portfolioSelector,
  (portfolioState: PortfolioState) => portfolioState.account
);

const ModalSubmitSellAll: FC<Props> = memo(
  ({ visible, handleCloseModal, setVisible, datalist }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [data, setData] = useState<PortfolioItemSellAll[]>([]);
    const [visibleModalOTP, setVisibleModalOTP] = useState(false);
    const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [selectedRows, setSelectedRows] = useState<PortfolioItemSellAll[]>(
      []
    );
    const setDataRow = () => {};
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const account = useSelector(accountSelector);

    const listAcc = GetAccoList(
      GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
      EntrustBusinessCode.Order
    );

    const accounts = useMemo(() => {
      let list = [];
      list = listAcc.map((account: any) => ({
        label: account.subAccoNo!,
        value: account.subAccoNo!,
      }));
      list.unshift({
        label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
        value: "",
      });
      return list;
    }, [listAcc, intl]);

    useEffect(() => {
      if (selectedRows) {
        const selectedRowsNew: PortfolioItemSellAll[] = selectedRows.filter(
          (item) => item.visibleCheckbox === true
        );
        dispatch(changeSelectedRows(selectedRowsNew));
      }
    }, [selectedRows]);

    const tickersNew = useSelector(tickerListSelector);
    const {
      selectedRows: selectedRowsPortfolio,
      percentSelected,
      marketSelected,
      singleOrderStatus,
      errorSingleOrder,
    } = useSelector(portfolioSelector);

    useEffect(() => {
      if (datalist) {
        const newList = datalist.map((item) => {
          const sellQty = item.availQty * Number(percentSelected);
          const sellQtyRound = Math.round(sellQty / 100) * 100;
          const newItem: PortfolioItemSellAll = {
            account: item.subAccountNo,
            symbol: item.secCd,
            availableBalance: item.availQty,
            sellQty:
              sellQtyRound > item.availQty ? sellQtyRound - 100 : sellQtyRound,
            marketPrice: item.lastPrice,
            exchanges: getExchanges(item.secCd),
            ratio: item.ratio,
            visibleCheckbox: sellQtyRound > 0 ? true : false,
          };
          return newItem;
        });

        const listFilter = newList.filter(
          (item) =>
            item.exchanges === marketSelected && item.availableBalance !== 0
        );
        setData(listFilter);
      }
    }, [datalist, percentSelected, marketSelected]);

    const toggleModalInfo = () => {
      dispatch(changePercentSelected("1"));
      dispatch(changeMarketSelected("HSX"));
      handleCloseModal();
    };

    const getExchanges = (symbol: string) => {
      const exchanges = tickersNew.find((item) => item.seccd === symbol);
      if (exchanges) {
        return getMarketLabel(exchanges.marketcd);
      } else {
        return "";
      }
    };

    const getPriceFollowExchanges = (
      symbol: string,
      floorPrice: number,
      session: string
    ) => {
      const exchanges = tickersNew.find((item) => item.seccd === symbol);
      if (exchanges) {
        const marketName = getMarketLabel(exchanges.marketcd);
        if (session === MarketSession.ATC) {
          if (marketName === "HNX") {
            return "ATC";
          } else if (marketName === "HSX") {
            return "ATC";
          } else {
            return "";
          }
        } else if (session === MarketSession.ATO) {
          if (marketName === "HSX") {
            return "ATO";
          } else {
            return "";
          }
        } else if (
          session === MarketSession.OPEN ||
          session === MarketSession.BREAK
        ) {
          if (marketName === "HNX") {
            return "MTL";
          } else if (marketName === "HSX") {
            return "MP";
          } else if (marketName === "UPCOM") {
            return floorPrice;
          } else {
            return "";
          }
        } else if (session === MarketSession.RUNOFF) {
          if (marketName === "HNX") {
            return "PLO";
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    const flexEnd = "flex-end";

    const columns = useMemo(
      () => [
        {
          Header: (
            <TableHeaderCellContent className="portfolio-symbol">
              <FormattedMessage id="widgets.portfolio.sellAll.table.code" />
            </TableHeaderCellContent>
          ),
          accessor: "symbol",
          Cell: (props: CellProps<PortfolioItemSellAll>) => {
            const { value } = props;
            return <TableCellContent>{value}</TableCellContent>;
          },
        },
        {
          Header: (
            <TableHeaderCellContent
              align={flexEnd}
              className="portfolio-quantity"
            >
              <FormattedMessage id="widgets.portfolio.sellAll.table.sharesBalance" />
            </TableHeaderCellContent>
          ),
          minWidth: 100,
          accessor: "availableBalance",
          Cell: (props: any) => {
            const { value } = props;
            const quantity = numeral(value).format("0,0");
            return (
              <TableCellContent align={flexEnd}>{quantity}</TableCellContent>
            );
          },
        },
        {
          Header: (
            <TableHeaderCellContent
              align={flexEnd}
              className="portfolio-shares-balance"
            >
              <FormattedMessage id="widgets.portfolio.sellAll.table.weight" />
            </TableHeaderCellContent>
          ),
          accessor: "sellQty",
          minWidth: 100,
          Cell: (props: any) => {
            const { value } = props;
            const sharesBalance = numeral(value).format("0,0");
            return (
              <TableCellContent align={flexEnd}>
                {sharesBalance}
              </TableCellContent>
            );
          },
        },
      ],
      [data]
    );

    const fetchMoreData = () => {};

    const handleChangePercent = (value?: string) => {
      dispatch(changePercentSelected(value ? value : "1"));
    };

    const handleChangeMarket = (value?: string) => {
      dispatch(changeMarketSelected(value ? value : "HSX"));
    };

    const accValue = () => {
      if (isAuthenticated) {
        return accounts.find((item: any) => item.value === account);
      } else {
        return null;
      }
    };

    const handleSubmit = () => {
      setVisibleModalOTP(true);
    };

    const doSubmitSellAllOrder = async (response: IVerifyResponse) => {
      setVisibleModalOTP(false);
      if (selectedRowsPortfolio && selectedRowsPortfolio.length > 0) {
        let listParams: IPreSubmitOrderModel[] = [];
        for (let i = 0; i < selectedRowsPortfolio.length; i++) {
          const res = await getStockPrice(selectedRowsPortfolio[i].symbol);
          if (res.statusCode !== 0) {
            toast(
              res?.errorCode ? "error." + res?.errorCode : "",
              "error",
              res.message,
              true
            );
            return;
          }
          const floorPrice = Number(
            res?.data?.floorPrice * UNIT_NUMBER.ONE_THOUSAND
          );
          const price: any = getPriceFollowExchanges(
            selectedRowsPortfolio[i].symbol,
            floorPrice,
            res?.data?.status
          );

          let params: OrderSingleParams = {
            account: selectedRowsPortfolio[i].account,
            side: "S",
            symbol: selectedRowsPortfolio[i].symbol,
            orderQty: Number(selectedRowsPortfolio[i].sellQty),
            price: typeof price === "string" ? floorPrice : price,
            type: typeof price === "string" ? price : "LO",
            otp: response?.otp,
          };

          const error = await checkPlaceOrder(params, intl);

          if (error !== null || params.orderQty === 0) {
            const errorMsg = error;
            setLoading(false);
            if (errorMsg !== null) {
              toast(errorMsg, "error");
            }
          } else {
            const paramsPlaceOrder: IPreSubmitOrderModel = {
              subAccoNo: params.account,
              tradeType: 1,
              secCd: params.symbol,
              marketCd: getMarketCd(marketSelected),
              order_type: params.type,
              order_price: params.price / 1000,
              order_qty: Number(params.orderQty),
              marginRate: 0,
              split_type: 0,
              split_num: 1,
              mortContractNo: null,
              interval: "0",
              srcChannel: 2,
              resolvedFlag: 0,
              sideType: 0,
            };
            listParams.push(paramsPlaceOrder);
          }
        }

        if (listParams.length > 0) {
          const paramSellAll: ParamsSellAll = {
            dataList: listParams,
            otpCode: response?.otp,
            otpType: response?.otpTypeSubmit!,
          };

          dispatch(
            requestSellAllPortfolio({
              params: paramSellAll,
              intl: intl,
            })
          );
        }
      } else {
        toast("widgets.portfolio.sellAll.emptySelect", "error");
        dispatch(changePercentSelected("1"));
        setLoading(false);
      }
    };

    const handleModal = () => {
      setVisibleModalOTP(false);
      setVisibleModalConfirm(true);
      setLoadingConfirm(false);
    };

    useEffect(() => {
      if (singleOrderStatus?.status === "success") {
        setLoading(false);
        setVisible(false);
        setVisibleModalConfirm(false);
        dispatch(sellAllPortfolioOrderClear());
        dispatch(onLoadListPortfolio(true));
        dispatch(changePercentSelected("1"));
        dispatch(changeMarketSelected("HSX"));
        dispatch(onLoadList(Math.random()));
      }

      if (singleOrderStatus?.status === "failed") {
        setLoading(false);
        const codeError = errorSingleOrder?.code;
        if (codeError) {
          const msg = `${errorSingleOrder?.code
            ?.toString()
            .replaceAll("-", "_")}`;
          const msgFinal = msg
            ? "error." + msg
            : "widgets.placeOrder.orderError";
          toast(msgFinal, "error");
        } else {
          toast("error.defaultErr", "error");
        }

        setVisible(false);
        setVisibleModalConfirm(false);
        dispatch(sellAllPortfolioOrderClear());
        dispatch(changePercentSelected("1"));
        dispatch(changeMarketSelected("HSX"));
      }
    }, [singleOrderStatus]);

    const handleCloseModalCofirm = () => {
      setVisibleModalConfirm(false);
      setVisible(true);
    };

    const handleVisibleModalConfirm = async () => {
      if (selectedRowsPortfolio && selectedRowsPortfolio.length > 0) {
        const res = await getStockPrice(selectedRowsPortfolio[0].symbol);
        if (res.statusCode !== 0) {
          toast(
            res?.errorCode ? "error." + res?.errorCode : "",
            "error",
            res.message,
            true
          );
          return;
        }
        if (marketSelected === "HSX") {
          if (
            res?.data?.status !== MarketSession.ATO &&
            res?.data?.status !== MarketSession.ATC &&
            res?.data?.status !== MarketSession.OPEN &&
            res?.data?.status !== MarketSession.BREAK
          ) {
            toast("widgets.portfolio.sellAll.errorSessionHSX", "error");
          } else {
            setVisibleModalConfirm(true);
            setVisible(false);
          }
        } else if (marketSelected === "HNX") {
          if (
            res?.data?.status !== MarketSession.ATC &&
            res?.data?.status !== MarketSession.OPEN &&
            res?.data?.status !== MarketSession.BREAK &&
            res?.data?.status !== MarketSession.RUNOFF
          ) {
            toast("widgets.portfolio.sellAll.errorSessionHNX", "error");
          } else {
            setVisibleModalConfirm(true);
            setVisible(false);
          }
        } else if (marketSelected === "UPCOM") {
          if (
            res?.data?.status !== MarketSession.OPEN &&
            res?.data?.status !== MarketSession.BREAK
          ) {
            toast("widgets.portfolio.sellAll.errorSessionUPCOM", "error");
          } else {
            setVisibleModalConfirm(true);
            setVisible(false);
          }
        }
      } else {
        toast("widgets.portfolio.sellAll.noSelect", "error");
      }
    };

    return (
      <>
        <Modal
          show={visible}
          centered
          keyboard={false}
          backdrop="static"
          dialogClassName="modal-534"
        >
          <PopConfirmOrderContainer>
            <PopConfirmOrderTitle>
              <div className="flex-grow-1"></div>
              {intl
                .formatMessage({ id: "widgets.portfolio.sellAll.title" })
                .toUpperCase()}
              <ButtonExit
                onClick={() => {
                  toggleModalInfo();
                }}
              >
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            </PopConfirmOrderTitle>
            <PopConfirmOrderBody>
              <div className="body">
                <Filter>
                  <Label>
                    {intl.formatMessage({
                      id: "widgets.portfolio.sellAll.account",
                    })}
                  </Label>
                  <Content>
                    <Select
                      value={accValue()}
                      options={accounts}
                      onChange={(item) => {
                        if (!item) return;
                        dispatch(updateAccountPortfolio(item.value));
                        dispatch(onLoadListPortfolio(true));
                      }}
                    ></Select>
                  </Content>
                </Filter>
                <Filter>
                  <Label>
                    {intl.formatMessage({
                      id: "widgets.portfolio.sellAll.market",
                    })}
                  </Label>
                  <Content>
                    <Select
                      value={marketList.find(
                        (item) => item.value === marketSelected
                      )}
                      options={marketList}
                      onChange={(e) => handleChangeMarket(e?.value)}
                    ></Select>
                  </Content>
                </Filter>
                <Filter>
                  <Label>
                    {intl.formatMessage({
                      id: "widgets.portfolio.sellAll.percent",
                    })}
                  </Label>
                  <Content>
                    <Select
                      value={percentList.find(
                        (item) => item.value === percentSelected
                      )}
                      options={percentList}
                      onChange={(e) => handleChangePercent(e?.value)}
                    ></Select>
                  </Content>
                </Filter>
                <Container>
                  <SelectTable
                    fetchMoreData={fetchMoreData}
                    columns={columns}
                    data={data}
                    enableInfinity={true}
                    scrollableTarget={"portfolioContainer"}
                    isHasFooter={false}
                    setDataRow={setDataRow}
                    setSelectedRows={setSelectedRows}
                  />
                </Container>
              </div>
              <div className="footer">
                <Button
                  className="flex-1"
                  color="accept"
                  onClick={() => {
                    toggleModalInfo();
                  }}
                >
                  <FormattedMessage id="widgets.portfolio.sellAll.cancel" />
                </Button>
                <Spacer size="s" />
                <Button
                  className="flex-1"
                  onClick={() => handleVisibleModalConfirm()}
                  color="accept"
                  disabled={loading}
                  loading={loading}
                  fill
                >
                  <FormattedMessage id="widgets.portfolio.sellAll.placeOrder" />
                </Button>
              </div>
            </PopConfirmOrderBody>
          </PopConfirmOrderContainer>
        </Modal>
        {visibleModalOTP && (
          <OTPByBusinessCd
            handleClose={handleModal}
            getResult={doSubmitSellAllOrder}
            businessCd={EBusinessCd.TRADING}
          />
        )}
        {visibleModalConfirm && (
          <ModalConfirmSellAll
            visible={visibleModalConfirm}
            setVisible={setVisibleModalConfirm}
            handleCloseModal={handleCloseModalCofirm}
            data={selectedRowsPortfolio}
            handleSubmit={handleSubmit}
            loading={loadingConfirm}
            setLoading={setLoadingConfirm}
          />
        )}
      </>
    );
  }
);

export default ModalSubmitSellAll;
