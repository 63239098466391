import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import CategorySelectInput from "./CategorySelectInput";
import { CategoryOption } from "./types";
import {
  changeCategoryOption,
  fetchWatchList,
  WatchListState,
} from "modules/stock-order/redux/watchList";
import { WatchListCard } from "./styles";
import { createSelector } from "@reduxjs/toolkit";
import { State as CategotyState } from "modules/categories/redux";
import Table from "./Table";

const categoriesSelector = (state: RootState) => state.categories.root;
const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const selectedCategorySelector = createSelector(
  watchListSelector,
  (watchListState: WatchListState) => watchListState.selectedCategory
);

const userFavoriteListSelector = createSelector(
  categoriesSelector,
  (categoryState: CategotyState) => categoryState.userFavoriteList
);

const ownerListSelector = createSelector(
  categoriesSelector,
  (categoryState: CategotyState) => categoryState.ownerList
);

const WatchList: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectedCategorySelector);
  const userFavoriteList = useSelector(userFavoriteListSelector);
  const ownerList = useSelector(ownerListSelector);

  const favoriteList = useMemo(
    () => ownerList.concat(userFavoriteList),
    [userFavoriteList, ownerList]
  );

  const handleCategoryOptions = (data: any) => {
    return data.map((el: any) => ({
      label: el.nameVi || el.name,
      value: el.name,
      dataList: el.listTickers,
    }));
  };

  const handleChangeSelectCategory = (option: CategoryOption) => {
    dispatch(changeCategoryOption({ option }));
    dispatch(fetchWatchList({ fullTickersList: option?.dataList }));
  };

  return (
    <WatchListCard>
      <div className="select-box">
        <div>
          <FormattedMessage id="widgets.watchlist.selectCategory" />
        </div>
        <CategorySelectInput
          categoryOptions={handleCategoryOptions(favoriteList)}
          onSelectCategory={handleChangeSelectCategory}
          selectedCategory={selectedCategory}
        />
      </div>

      <Table />
    </WatchListCard>
  );
});

export default WatchList;
