import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFindAdvanceParams,
  IDeletePayAdvanceParams,
  IEditAdvanceParams,
  IGetAmtCanReceiveParams,
  IGetFeeAdvanceParams,
  ICustomerRestrictionParams,
  IParameterBankDetailForPayAdvanceParams,
  IGetBankBranchCdParams,
  IGetPayAdvanceLimitRemainParams,
  IGetBankBranchCd2Params,
} from "core/http/apis/advance/types";
import {
  PayAdvance,
  // IAdvanceResponse,
  IDeletePayAdvanceResponse,
  IGetAmtCanReceiveResponse,
  IGetFeeResponse,
  ICreateAdvanceContractResponse,
  ICustomerRestrictionResponse,
  IGetFindParameterBankDetailForPayAdvanceResponse,
  IGetBankBranchCdResponse,
  IGetPayAdvanceLimitRemainResponse,
  IGetBankBranchCd2Response,
} from "domain/models/Advance";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
}
interface IStatusCode {
  status: number;
  value: number;
}
export interface ReduxData {
  pageCurrent: number;
  filter: IFilter;
  list: PayAdvance[];
  loading: boolean;
  currentPage: number;
  activeTab: string;
  statusDelete: boolean;

  advance?: IEditAdvanceParams;
  isShowConfirmModal: boolean;
  isShowOtpModal: boolean;
  isShowOtpModalLiquid: boolean;
  statusCreateContract: boolean;
  statusCheck: boolean;
  bankBrandCd: string;
  bankBrandCd2: string;
  statusCode: IStatusCode;
}

const initialState: ReduxData = {
  pageCurrent: 0,
  filter: {
    subAccoNo: "",
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
  },
  list: [],
  loading: false,
  currentPage: 0,
  activeTab: "ADVANCE",
  statusDelete: false,
  isShowConfirmModal: false,
  isShowOtpModal: false,
  isShowOtpModalLiquid: false,
  statusCreateContract: false,
  statusCheck: false,
  bankBrandCd: "",
  bankBrandCd2: "",
  statusCode: {
    status: 0,
    value: 0,
  },
};

const advanceSlice = createSlice({
  name: "advanceSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchAdvanceRequest: (
      state,
      action: PayloadAction<IFindAdvanceParams>
    ) => {
      state.loading = true;
    },
    searchAdvanceSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchAdvanceFailure: (state) => {
      state.loading = false;
    },

    getAmtCanReceiveRequest: (
      state,

      action: PayloadAction<IGetAmtCanReceiveParams>
    ) => {},
    getAmtCanReceiveSuccess: (
      state,
      action: PayloadAction<IGetAmtCanReceiveResponse>
    ) => {
      state.advance = {
        ...state.advance,
        totalAmtCanReceive: action.payload.data.totalAmtCanReceive,
      };
    },
    getAmtCanReceiveFailure: (state) => {},

    getFeeAdvanceRequest: (
      state,

      action: PayloadAction<IGetFeeAdvanceParams>
    ) => {},
    getFeeAdvanceSuccess: (state, action: PayloadAction<IGetFeeResponse>) => {
      state.advance = {
        ...state.advance,
        advanceFee:
          action.payload.data.totalInterestAmt +
          action.payload.data.totalManagerFeeAmt,
      };
    },
    getFeeAdvanceFailure: (state) => {},

    createAdvanceContractRequest: (
      state,

      action: PayloadAction<IGetFeeAdvanceParams>
    ) => {
      state.statusCreateContract = false;
    },
    createAdvanceContractSuccess: (
      state,
      action: PayloadAction<ICreateAdvanceContractResponse>
    ) => {
      state.statusCreateContract = true;
    },
    createAdvanceContractFailure: (state) => {
      state.statusCreateContract = false;
    },

    deletePayAdvanceRequest: (
      state,
      action: PayloadAction<IDeletePayAdvanceParams>
    ) => {
      state.statusDelete = false;
    },
    deletePayAdvanceSuccess: (
      state,
      action: PayloadAction<IDeletePayAdvanceResponse>
    ) => {
      state.statusDelete = true;
    },
    deletePayAdvanceFailure: (state) => {
      state.statusDelete = false;
    },
    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        subAccoNo?: string;
        getBankAdvance?: string;
        realRecvAmt?: number;
        totalAmtCanReceive?: number;
        advanceFee?: number;
      }>
    ) => {},
    updateModalStatusSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowConfirmModal = action.payload;
    },

    updateModalOtpRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
      }>
    ) => {},
    updateModalOtpSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowOtpModal = action.payload;
    },
    updateModalOtpLiquidRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
      }>
    ) => {},
    updateModalOtpLiquidSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowOtpModalLiquid = action.payload;
    },
    updateItemEdit: (
      state,
      action: PayloadAction<IEditAdvanceParams | undefined>
    ) => {
      if (!action.payload) {
        state.advance = undefined;
        return;
      }

      state.advance = {
        ...state.advance,
        ...action.payload,
      };
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },

    getCustomerRestrictionRequest: (
      state,

      action: PayloadAction<ICustomerRestrictionParams>
    ) => {
      state.statusCheck = false;
    },
    getCustomerRestrictionSuccess: (
      state,
      action: PayloadAction<ICustomerRestrictionResponse>
    ) => {},
    getCustomerRestrictionFailure: (state) => {},

    findParameterBankDetailForPayAdvanceRequest: (
      state,

      action: PayloadAction<IParameterBankDetailForPayAdvanceParams>
    ) => {},
    findParameterBankDetailForPayAdvanceSuccess: (
      state,
      action: PayloadAction<IGetFindParameterBankDetailForPayAdvanceResponse>
    ) => {
      state.bankBrandCd = "";
      state.statusCode = {
        status: 0,
        value: 0,
      };
    },
    findParameterBankDetailForPayAdvanceFailure: (
      state,
      action: PayloadAction<IStatusCode | undefined>
    ) => {
      if (!action.payload) {
        state.statusCode = {
          status: 0,
          value: 0,
        };
        return;
      }

      state.statusCode = {
        status: action.payload.status,
        value: action.payload.value,
      };
    },

    getBankBranchCdRequest: (
      state,

      action: PayloadAction<IGetBankBranchCdParams>
    ) => {},
    getBankBranchCdSuccess: (
      state,
      action: PayloadAction<IGetBankBranchCdResponse>
    ) => {
      if (action.payload.data && action.payload.data.key) {
        state.bankBrandCd = action.payload.data.key;
      } else {
        state.bankBrandCd = "";
      }
    },
    getBankBranchCdFailure: (state) => {},

    updateBankBrandCd: (state, action: PayloadAction<string>) => {
      state.bankBrandCd = action.payload;
    },
    getBankBranchCd2Request: (
      state,

      action: PayloadAction<IGetBankBranchCd2Params>
    ) => {},
    getBankBranchCd2Success: (
      state,
      action: PayloadAction<IGetBankBranchCd2Response>
    ) => {
      if (action.payload.data && action.payload.data.key) {
        state.bankBrandCd2 = action.payload.data.key;
      } else {
        state.bankBrandCd2 = "";
      }
    },
    getBankBranchCd2Failure: (state) => {},
    updateCurrentPage: (state, action: PayloadAction<number>) => {
      state.pageCurrent = action.payload;
    },

    getPayAdvanceLimitRemainRequest: (
      state,

      action: PayloadAction<IGetPayAdvanceLimitRemainParams>
    ) => {
      state.statusCode = {
        status: 0,
        value: 0,
      };
    },
    getPayAdvanceLimitRemainSuccess: (
      state,
      action: PayloadAction<IGetPayAdvanceLimitRemainResponse>
    ) => {},
    getPayAdvanceLimitRemainFailure: (
      state,
      action: PayloadAction<IStatusCode | undefined>
    ) => {
      if (!action.payload) {
        state.statusCode = {
          status: 0,
          value: 0,
        };
        return;
      }

      state.statusCode = {
        status: action.payload.status,
        value: action.payload.value,
      };
    },
  },
});

export const {
  deletePayAdvanceRequest,
  deletePayAdvanceSuccess,
  deletePayAdvanceFailure,
  searchAdvanceFailure,
  searchAdvanceRequest,
  searchAdvanceSuccess,

  getAmtCanReceiveRequest,
  getAmtCanReceiveSuccess,
  getAmtCanReceiveFailure,
  getFeeAdvanceRequest,
  getFeeAdvanceSuccess,
  getFeeAdvanceFailure,
  createAdvanceContractRequest,
  createAdvanceContractSuccess,
  createAdvanceContractFailure,
  reset,
  updateItemEdit,
  updateFilter,
  updateBankBrandCd,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  updateModalOtpRequest,
  updateModalOtpSuccess,
  updateModalOtpLiquidRequest,
  updateModalOtpLiquidSuccess,
  getCustomerRestrictionRequest,
  getCustomerRestrictionSuccess,
  getCustomerRestrictionFailure,
  findParameterBankDetailForPayAdvanceRequest,
  findParameterBankDetailForPayAdvanceSuccess,
  findParameterBankDetailForPayAdvanceFailure,
  getBankBranchCdRequest,
  getBankBranchCdSuccess,
  getBankBranchCdFailure,
  getPayAdvanceLimitRemainRequest,
  getPayAdvanceLimitRemainSuccess,
  getPayAdvanceLimitRemainFailure,
  getBankBranchCd2Request,
  getBankBranchCd2Success,
  getBankBranchCd2Failure,

  updateCurrentPage,
} = advanceSlice.actions;

export default advanceSlice.reducer;
