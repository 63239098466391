import HttpTradingView from "core/http/singleton/trading-view";
import { TradingViewSession } from "domain/models/TradingViewSession";
import storage from "helper/storage";
import { getAccessKeyTradingView } from "./urls";

// get api lịch sử ứng tiền bán
export const accessKeyTradingViewApi =
  async (): Promise<TradingViewSession> => {
    const res = await HttpTradingView.get<TradingViewSession>(
      getAccessKeyTradingView(storage.getMasterAccount())
    );

    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  };
