import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { marginContractWatchListSelector } from "modules/margin-contract-watch/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Container, DetailLink, HeaderCell, TableCellContent } from "./styles";

function Table() {
  const data = useSelector(marginContractWatchListSelector);
  const flexEnd = "flex-end";
  const flexStart = "flex-start";
  const center = "center";

  const clickDetailHandler = (key: string, subAccNo: string) => {
    switch (key) {
      case "detail":
        return;
      case "secValNeedSell":
        return;
      case "balanceValAdd":
        return;
      case "secValAdd":
        return;
      default:
        return;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContractWatch.table.subAccNo" />
          </HeaderCell>
        ),
        accessor: "subAccNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContractWatch.table.custName" />
          </HeaderCell>
        ),
        accessor: "custName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: <HeaderCell align={center}></HeaderCell>,
        accessor: "emailAddrs",
        Cell: (props: any) => {
          const subAccNo = props?.row?.original?.subAccNo;
          return (
            <TableCellContent align={center}>
              <DetailLink onClick={() => clickDetailHandler("detai", subAccNo)}>
                <FormattedMessage id="marginContractWatch.table.detail" />
              </DetailLink>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.totalAssetMargin" />
          </HeaderCell>
        ),
        accessor: "totalAssetMargin",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.realAsset" />
          </HeaderCell>
        ),
        accessor: "realAsset",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.totalDebtAndInt" />
          </HeaderCell>
        ),
        accessor: "totalDebtAndInt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.secValNeedSell" />
          </HeaderCell>
        ),
        accessor: "secValNeedSell",
        Cell: (props: any) => {
          const subAccNo = props?.row?.original?.subAccNo;
          return (
            <TableCellContent align={flexEnd}>
              <DetailLink
                onClick={() => clickDetailHandler("secValNeedSell", subAccNo)}
              >
                {numeral(props.value).format("0,0")}
              </DetailLink>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.balanceValAdd" />
          </HeaderCell>
        ),
        accessor: "balanceValAdd",
        Cell: (props: any) => {
          const subAccNo = props?.row?.original?.subAccNo;
          return (
            <TableCellContent align={flexEnd}>
              <DetailLink
                onClick={() => clickDetailHandler("balanceValAdd", subAccNo)}
              >
                {numeral(props.value).format("0,0")}
              </DetailLink>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.secValAdd" />
          </HeaderCell>
        ),
        accessor: "secValAdd",
        Cell: (props: any) => {
          const subAccNo = props?.row?.original?.subAccNo;
          return (
            <TableCellContent align={flexEnd}>
              <DetailLink
                onClick={() => clickDetailHandler("secValAdd", subAccNo)}
              >
                {numeral(props.value).format("0,0")}
              </DetailLink>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.retentionRate" />
          </HeaderCell>
        ),
        accessor: "retentionRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00000")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.warningRate" />
          </HeaderCell>
        ),
        accessor: "warningRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00000")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.callRate" />
          </HeaderCell>
        ),
        accessor: "callRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00000")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContractWatch.table.warningDays" />
          </HeaderCell>
        ),
        accessor: "warningDays",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="marginContractWatchTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data}
        enableInfinity={true}
        scrollableTarget={"marginContractWatchTable"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
