import ContentTop from "./assetsIncurred/contentTop";
import ContentBottom from "./assetsIncurred/contentBottom";
import { ASSETS_TAB } from "modules/assetsIncurred/constants";
import { useSelector } from "react-redux";
import ContentTopAssets from "./assets/contentTop";
import ContentBottomAssets from "./assets/contentBottom";
import ContentTopEffect from "./investEffect/contentTop";
import ContentBottomAssetsEffect from "./investEffect/contentBottom";
import { selectTab } from "modules/assetsIncurred/selectors";

const ContentPages = () => {
  const activeTab = useSelector(selectTab);

  const renderContent = () => {
    if (activeTab === ASSETS_TAB.ASSETS_INCURRED)
      return (
        <>
          <ContentTop />
          <ContentBottom />
        </>
      );
    if (activeTab === ASSETS_TAB.ASSETS)
      return (
        <>
          <ContentTopAssets />
          <ContentBottomAssets />
        </>
      );
    if (activeTab === ASSETS_TAB.ASSETS_SUM)
      return (
        <>
          <ContentTopEffect />
          <ContentBottomAssetsEffect />
        </>
      );
    return "";
  };

  return <>{renderContent()}</>;
};

export default ContentPages;
