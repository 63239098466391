import { createSelector } from "@reduxjs/toolkit";
import { LanguageType } from "languages/redux";
import {
  SCREENERS_TAB_CODE,
  SCREENER_SAMPLE,
  SCREENER_SAMPLE_EN,
} from "modules/screeners/constants";
import {
  changeSampleFilterList,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import MyScreener from "./MyScreener";
import ScreenerPanel from "./Screener";
import { Container } from "./styles";

const rootSelector = (state: RootState) => state;
const screenersSelector = (state: RootState) => state.screeners;

const languageTypeSelector = createSelector(
  rootSelector,
  (state: RootState) => state.language.type
);

const tabCodeActiveSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) => screenersSelector.tabCode
);

const TabPanel: FC = () => {
  const dispatch = useDispatch();
  const tabCodeActive = useSelector(tabCodeActiveSelector);
  const language = useSelector(languageTypeSelector);

  useEffect(() => {
    if (language === LanguageType.EN) {
      dispatch(changeSampleFilterList(SCREENER_SAMPLE_EN));
    } else {
      dispatch(changeSampleFilterList(SCREENER_SAMPLE));
    }
  }, [language, dispatch]);

  return (
    <Container>
      {tabCodeActive === SCREENERS_TAB_CODE.SCREENER && (
        <div>
          <ScreenerPanel />
        </div>
      )}
      {tabCodeActive === SCREENERS_TAB_CODE.MY_SCREENER && (
        <div>
          <MyScreener />
        </div>
      )}
    </Container>
  );
};

export default TabPanel;
