import {
  Body,
  Container,
  Title,
  ButtonCountDown,
  ButtonExit,
  Icon,
  // ErrorVerify,
} from "./styles";
import { Button } from "components/commons";
import { memo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import { useDispatch } from "react-redux";
// import { createOTP } from "modules/auth/redux";
import { TimeCountDown } from "AppConfig";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit.svg";
// import storage from "helper/storage";
// import {
//   CreateOTPParams,
//   VerifySMSOTPParams,
// } from "core/http/apis/twofactor/types";
import { useIntl, FormattedMessage } from "react-intl";
// import { verifySmsOTP } from "core/http/apis/twofactor";
import OTPInput from "components/commons/SmartOTP/OTPInput";

interface Props {
  handleModal: any;
  otpTrue: any;
  businessCd: string;
}
// const emailPhone = "0";

function ModalSendOTP({ handleModal, otpTrue, businessCd }: Props) {
  const intl = useIntl();
  // const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [codeOTP, setCodeOTP] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [errorVerify, setErrorVerify] = useState("");
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const apply = async () => {
    // const verifyOTPParams: VerifySMSOTPParams = {
    //   masterAccount: storage.getMasterAccount(),
    //   deviceId: "",
    //   deviceInfo: "",
    //   businessCd: businessCd,
    //   smsOtp: codeOTP.toString(),
    // };
    // try {
    //   setLoading(true);
    //   const result = await verifySmsOTP(verifyOTPParams);
    //   Object.assign(result, {
    //     smartOtp: codeOTP,
    //   });
    //   setLoading(false);
    //   otpTrue(result);
    //   setErrorVerify("");
    // } catch (error: any) {
    //   setLoading(false);
    //   setErrorVerify(error.code);
    // }
  };

  const sendOTP = () => {
    //clear lỗi, clear các số đã nhập
    // setCodeOTP("");
    // setErrorVerify("");
    // const createOTPParams: CreateOTPParams = {
    //   masterAccount: storage.getMasterAccount(),
    //   deviceId: null,
    //   businessCd: businessCd,
    // };
    // createOTPParams["sendType"] = emailPhone;
    // dispatch(createOTP({ params: createOTPParams, intl: intl }));
  };

  const radius4 = 4;

  useEffect(() => {
    sendOTP();
    setCounter(TimeCountDown);
  }, []);

  return (
    <>
      <Modal show={true} centered keyboard={false} dialogClassName="modal-461">
        <Container>
          <Title>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}>
              {intl
                .formatMessage({ id: "auth.info.sendOTP.title" })
                .toUpperCase()}
            </div>
            <ButtonExit onClick={handleModal}>
              <Icon>
                <IconExit />
              </Icon>
            </ButtonExit>
          </Title>
          <Body padding={0}>
            <div className="body">
              <div className="inputOTP">
                <div className="inputOTPHeader">
                  <FormattedMessage id="auth.onlineService.enterSmsOTP" />
                </div>
                <div className="inputOTPContent">
                  <OTPInput
                    loading={false}
                    autoFocus
                    isNumberInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={(otp) => setCodeOTP(String(otp))}
                    handelVerifySmartOTP={apply}
                    defaultValue={codeOTP}
                  />
                  {/* <ErrorVerify>
                    {errorVerify !== "" &&
                      intl.formatMessage({
                        id: errorVerify || "errors.defaultErr",
                      })}
                  </ErrorVerify> */}
                </div>
              </div>
            </div>
            <div className="footerConfirm">
              {counter > 0 ? (
                <ButtonCountDown>
                  <FormattedMessage id="auth.info.sendOTP.sendOTPAgain" />(
                  {counter})
                </ButtonCountDown>
              ) : (
                <Button
                  size="m"
                  style={{ flex: 3 }}
                  color="accept"
                  className="flex-1"
                  radius={radius4}
                  onClick={() => {
                    sendOTP();
                    setCounter(TimeCountDown);
                  }}
                >
                  <FormattedMessage id="auth.onlineService.send" />
                </Button>
              )}
              <div style={{ flex: 2 }}></div>
              <div style={{ flex: 2 }}></div>
              <Button
                size="m"
                style={{ flex: 3 }}
                onClick={() => {
                  apply();
                }}
                disabled={codeOTP.length < 6}
                className="flex-1"
                radius={radius4}
                fill
                color="accept"
                loading={false}
              >
                <FormattedMessage id="auth.info.sendOTP.confirm" />
              </Button>
            </div>
          </Body>
        </Container>
      </Modal>
    </>
  );
}

export default memo(ModalSendOTP);
