import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDeleteScreenerParam,
  ISaveScreenerBody,
  ISaveScreenerParam,
} from "core/http/apis/screener/types";
import {
  IScreener,
  IScreenerFilter,
  IScreenerResponse,
} from "domain/models/Screener";
import { LastSale, ScreenerInput } from "domain/protoNew/auto_trading_pb";
import { isEqual } from "lodash";
import { logOutSuccess } from "modules/auth/redux";
import {
  convertFilterToScreenFilter,
  ORDER_FIELD_TYPE,
  ORDER_TYPE,
  SCREENERS_TAB_CODE,
  SCREENER_SAMPLE,
} from "./constants";
import {
  IActiveSort,
  IBeautyResponseFilter,
  IBeautyResultItem,
  ISampleFilter,
  TScreenersTabCode,
} from "./types";

interface ResultScreenersParams {
  enableInfinity: boolean;
  offset: number;
  pageSize: number;
  sortBy?: number;
  order?: number;
}

export interface ReduxData {
  tabCode: string;
  dataScreener: {
    loading: boolean;
    isVisibleCreateNameModal: boolean;
    filterList: IScreenerFilter[];
  };
  dataMyScreener: {
    loading: boolean;
    list: IScreener[];
    screenerSelected: IScreener | null;
  };
  dataFilter: {
    loading: boolean;
    sampleFilter: {
      requestid: string;
      filtersList: ISampleFilter[];
    };
  };
  dataResultScreen: {
    loading: boolean;
    list: IBeautyResultItem[];
    total: number;
    params: ResultScreenersParams;
    tickerList: string[];
    activeSort: IActiveSort;
    tickerWatchlist: string[];
  };
}

const initialState: ReduxData = {
  tabCode: SCREENERS_TAB_CODE.SCREENER,
  dataScreener: {
    loading: false,
    isVisibleCreateNameModal: false,
    filterList: [],
  },
  dataMyScreener: {
    loading: false,
    list: [],
    screenerSelected: null,
  },
  dataFilter: {
    loading: false,
    sampleFilter: {
      requestid: "SCREENER_SAMPLE",
      filtersList: SCREENER_SAMPLE,
    },
  },
  dataResultScreen: {
    loading: false,
    list: [],
    total: 0,
    params: {
      enableInfinity: false,
      offset: 0,
      pageSize: 50,
    },
    activeSort: {
      sortBy: ORDER_FIELD_TYPE.UNKNOWN, // ESortBy.SORTBY_UNKNOWN,
      order: ORDER_TYPE.UNKNOWN, //EOrder.ORDER_UNKNOWN,
    },
    tickerList: [],
    tickerWatchlist: [],
  },
};

const screenersSlice = createSlice({
  name: "screenersSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    changeTab: (state, action: PayloadAction<TScreenersTabCode>) => {
      state.tabCode = action.payload;
    },
    changeVisibleCreateNameModal: (state, action: PayloadAction<boolean>) => {
      state.dataScreener.isVisibleCreateNameModal = action.payload;
    },
    getListScreenerRequest: (state) => {
      state.dataMyScreener.loading = true;
    },
    getListScreenerSuccess: (
      state,
      action: PayloadAction<IScreenerResponse>
    ) => {
      state.dataMyScreener.loading = false;
      state.dataMyScreener.list = action.payload.data ?? [];
    },
    getListScreenerFailure: (state) => {
      state.dataMyScreener.loading = false;
    },
    deleteScreenerRequest: (
      state,
      action: PayloadAction<IDeleteScreenerParam>
    ) => {
      state.dataMyScreener.loading = true;
    },
    deleteScreenerSuccess: (
      state,
      action: PayloadAction<IScreenerResponse>
    ) => {
      state.dataMyScreener.loading = false;

      state.dataMyScreener.list = action.payload.data ?? [];

      let isExistScreenerSelected = false;

      action.payload.data?.forEach((screener) => {
        if (isEqual(screener, state.dataMyScreener.screenerSelected)) {
          isExistScreenerSelected = true;
        }
      });

      if (!isExistScreenerSelected) {
        state.dataMyScreener.screenerSelected = null;
      }
    },
    deleteScreenerFailure: (state) => {
      state.dataMyScreener.loading = false;
    },
    // getFilterInfoRequest: (state, action: PayloadAction<TLanguageCode>) => {
    //   state.dataFilter.loading = true;
    // },
    // getFilterInfoSuccess: (
    //   state,
    //   action: PayloadAction<ScreenerInfo.AsObject>
    // ) => {
    //   state.dataFilter.loading = false;
    //   state.dataFilter.sampleFilter = action.payload;
    // },
    // getFilterInfoFailure: (state) => {
    //   state.dataFilter.loading = false;
    // },
    saveScreenerRequest: (
      state,
      action: PayloadAction<{
        param: ISaveScreenerParam;
        body: ISaveScreenerBody;
      }>
    ) => {
      state.dataScreener.isVisibleCreateNameModal = false;
      state.dataScreener.loading = true;
    },
    saveScreenerSuccess: (state, action: PayloadAction<IScreenerResponse>) => {
      state.dataScreener.loading = false;
      state.dataMyScreener.list = action.payload.data ?? [];
    },
    saveScreenerFailure: (state) => {
      state.dataScreener.loading = false;
    },
    doFilterRequest: (state, action: PayloadAction<ScreenerInput>) => {
      state.dataResultScreen.loading = true;
      state.dataResultScreen.params.offset = action.payload.toObject().offset;
      state.dataResultScreen.params.enableInfinity = true;
      state.dataResultScreen.list = [];
      state.dataResultScreen.tickerList = [];
      state.dataResultScreen.total = 0;
    },
    doFilterSuccess: (state, action: PayloadAction<IBeautyResponseFilter>) => {
      state.dataResultScreen.loading = false;
      state.dataResultScreen.list = action.payload.resultsList;
      state.dataResultScreen.total = action.payload.total;
      state.dataResultScreen.tickerList = action.payload.resultsList.map(
        (result) => result.seccd
      );

      if (
        action.payload.resultsList.length <
        state.dataResultScreen.params.pageSize
      ) {
        state.dataResultScreen.params.enableInfinity = false;
      }
    },
    doFilterFailure: (state) => {
      state.dataResultScreen.loading = false;
      state.dataResultScreen.params.enableInfinity = false;
    },
    fetchMoreFilterRequest: (state, action: PayloadAction<ScreenerInput>) => {
      state.dataResultScreen.loading = true;
      state.dataResultScreen.params.offset = action.payload.toObject().offset;
      state.dataResultScreen.params.enableInfinity = true;
    },
    fetchMoreFilterSuccess: (
      state,
      action: PayloadAction<IBeautyResponseFilter>
    ) => {
      state.dataResultScreen.loading = false;
      state.dataResultScreen.list = [
        ...state.dataResultScreen.list,
        ...action.payload.resultsList,
      ];

      const tickerListAppend = action.payload.resultsList.map(
        (result) => result.seccd
      );
      state.dataResultScreen.tickerList = [
        ...state.dataResultScreen.tickerList,
        ...tickerListAppend,
      ];

      if (
        action.payload.resultsList.length <
        state.dataResultScreen.params.pageSize
      ) {
        state.dataResultScreen.params.enableInfinity = false;
      }
    },
    fetchMoreFilterFailure: (state) => {
      state.dataResultScreen.loading = false;
      state.dataResultScreen.params.enableInfinity = false;
    },
    changeScreenerFilterList: (state, action: PayloadAction<ISampleFilter>) => {
      const filterFormatter = convertFilterToScreenFilter(action.payload);

      const filterItemInd = state.dataScreener.filterList.findIndex(
        (item) => item.key === filterFormatter.key
      );

      if (filterItemInd === -1) {
        state.dataScreener.filterList.push(filterFormatter);
        if (state.dataScreener.filterList.length === 1) {
          state.dataMyScreener.screenerSelected = null;
        }
        return;
      }

      state.dataScreener.filterList.splice(filterItemInd, 1);
    },
    changeScreenerFilterItem: (state, action: PayloadAction<ISampleFilter>) => {
      const filterFormatter = convertFilterToScreenFilter(action.payload);

      const filterItemInd = state.dataScreener.filterList.findIndex(
        (item) => item.key === filterFormatter.key
      );

      if (filterItemInd === -1) {
        state.dataScreener.filterList.push(filterFormatter);

        if (state.dataScreener.filterList.length === 1) {
          state.dataMyScreener.screenerSelected = null;
        }
        return;
      }

      state.dataScreener.filterList[filterItemInd] = filterFormatter;
    },
    resetScreenerFilterList: (state) => {
      state.dataScreener.filterList = [];
    },
    choiceMyScreenerFilter: (state, action: PayloadAction<IScreener>) => {
      if (
        state.dataMyScreener.screenerSelected &&
        state.dataMyScreener.screenerSelected.screenerId ===
          action.payload.screenerId
      ) {
        state.dataMyScreener.screenerSelected = null;
      } else {
        state.dataMyScreener.screenerSelected = action.payload;
        state.dataScreener.filterList = [];
      }
    },
    changeResultItemRealtime: (
      state,
      action: PayloadAction<{ item: LastSale.AsObject; ind: number }>
    ) => {
      state.dataResultScreen.list[action.payload.ind] = {
        ...state.dataResultScreen.list[action.payload.ind],
        changepoint: action.payload.item.changepoint ?? 0,
        lastprice: action.payload.item.lastprice ?? 0,
        colorcode: action.payload.item.colorcode,
      };
    },
    changeResultScreenerParams: (
      state,
      action: PayloadAction<ResultScreenersParams>
    ) => {
      state.dataResultScreen.params = action.payload;
    },
    changeActiveSort: (state, action: PayloadAction<IActiveSort>) => {
      state.dataResultScreen.activeSort = action.payload;
    },
    getAllTickerToAddWatchlistRequest: (
      state,
      action: PayloadAction<ScreenerInput>
    ) => {
      state.dataResultScreen.loading = true;
    },
    getAllTickerToAddWatchlistSuccess: (
      state,
      action: PayloadAction<IBeautyResponseFilter>
    ) => {
      state.dataResultScreen.loading = false;
      state.dataResultScreen.tickerWatchlist = action.payload.resultsList.map(
        (item) => item.seccd
      );
    },
    getAllTickerToAddWatchlistFailure: (state) => {
      state.dataResultScreen.loading = false;
    },
    changeSampleFilterList: (state, action: PayloadAction<ISampleFilter[]>) => {
      state.dataFilter.sampleFilter.filtersList = action.payload;
    },
  },
});

export const {
  changeTab,
  changeVisibleCreateNameModal,
  getListScreenerRequest,
  getListScreenerSuccess,
  getListScreenerFailure,
  deleteScreenerRequest,
  deleteScreenerSuccess,
  deleteScreenerFailure,
  doFilterRequest,
  doFilterSuccess,
  doFilterFailure,
  fetchMoreFilterRequest,
  fetchMoreFilterSuccess,
  fetchMoreFilterFailure,
  saveScreenerRequest,
  saveScreenerSuccess,
  saveScreenerFailure,
  changeScreenerFilterList,
  resetScreenerFilterList,
  choiceMyScreenerFilter,
  changeScreenerFilterItem,
  changeResultItemRealtime,
  changeResultScreenerParams,
  changeActiveSort,
  getAllTickerToAddWatchlistRequest,
  getAllTickerToAddWatchlistSuccess,
  getAllTickerToAddWatchlistFailure,
  changeSampleFilterList,
} = screenersSlice.actions;

export default screenersSlice.reducer;
