import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReduxData, ReduxStateType } from "../../redux/types";

export interface OrderBookState {
  orderFSList: [];
  orderCSList: [];
}

const initialState: ReduxData<OrderBookState> = {
  data: {
    orderFSList: [],
    orderCSList: [],
  },
  status: ReduxStateType.INIT,
};

const orderBookSlice = createSlice({
  name: "orderBookSlice",
  initialState,
  reducers: {
    getOrderFSListSuccess: (
      state,
      action: PayloadAction<{
        orderFSList: [];
      }>
    ) => {
      const { orderFSList } = action.payload;
      state.data = {
        ...state.data,
        orderFSList,
      };
      state.status = ReduxStateType.SUCCESS;
    },
    getOrderCSListSuccess: (
      state,
      action: PayloadAction<{
        orderCSList: [];
      }>
    ) => {
      const { orderCSList } = action.payload;
      state.data = {
        ...state.data,
        orderCSList,
      };
      state.status = ReduxStateType.SUCCESS;
    },
  },
});

export const { getOrderCSListSuccess, getOrderFSListSuccess } =
  orderBookSlice.actions;

export default orderBookSlice.reducer;
