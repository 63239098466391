import {
  searchDepositPowerApi,
  liquidateDepositPowerApi,
  depositPowerRegisterApi,
} from "./urls";
import {
  ISearchDepositPowerResponse,
  ILiquidateDepositPowerResponse,
  IDepositPowerRegisterResponse,
} from "domain/models/DepositPower";

import {
  ISearchDepositPowerParams,
  ILiquidateDepositPowerParams,
  IDepositPowerRegisterParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const searchDepositPower = async (
  params: ISearchDepositPowerParams
): Promise<ISearchDepositPowerResponse> => {
  const res = await VgaiaHttp.get<ISearchDepositPowerResponse>(
    searchDepositPowerApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const liquidateDepositPower = async (
  params: ILiquidateDepositPowerParams
): Promise<ILiquidateDepositPowerResponse> => {
  const res = await VgaiaHttp.post<ILiquidateDepositPowerResponse>(
    liquidateDepositPowerApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const depositPowerRegister = async (
  params: IDepositPowerRegisterParams
): Promise<IDepositPowerRegisterResponse> => {
  const res = await VgaiaHttp.post<IDepositPowerRegisterResponse>(
    depositPowerRegisterApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
