import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const changeLimitSelector = (state: RootState) => state.changeLimit;

export const loadingSelector = createSelector(
  changeLimitSelector,
  (state) => state.loading
);

export const getAccount = createSelector(
  changeLimitSelector,
  (state) => state.account
);
export const getStatusCode = createSelector(
  changeLimitSelector,
  (state) => state.statusCode
);
