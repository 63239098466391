import { Button, DatePicker, Input } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { toast } from "components/commons/Toast";
import { tradeDateSelector } from "modules/auth/selectors";
import {
  searchPositionCloseRequest,
  updatePositionCloseFilter,
} from "modules/position-management/redux";
import {
  loadingSelector,
  positionCloseSelector,
} from "modules/position-management/selectors";
import moment from "moment";
import { memo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer, WrapBoder } from "./styles";

function PositionCloseFilter() {
  const intl = useIntl();

  const loading = useSelector(loadingSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const { secCd, fromDate, toDate } = useSelector(positionCloseSelector);

  const dispatch = useDispatch();

  const handleSearch = () => {
    if (moment(fromDate).isAfter(moment(toDate))) {
      toast(
        `${intl.formatMessage({
          id: "positionManagement.positionClose.errorToDate",
        })}`,
        "error"
      );
    } else {
      dispatch(
        searchPositionCloseRequest({
          custNo: "",
          secCd: secCd ? secCd : "",
          fromDate: fromDate
            ? moment(fromDate).format("YYYYMMDD")
            : moment(tradeDate).format("YYYYMMDD"),
          toDate: toDate
            ? moment(toDate).format("YYYYMMDD")
            : moment(tradeDate).format("YYYYMMDD"),
        })
      );
    }
  };

  // render
  return (
    <WrapBoder className="d-flex justify-content-between align-items-center">
      <FlexContainer className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "positionManagement.positionClose.stockCode",
          })}
          className="mr-4"
        >
          <Input
            placeholder={intl.formatMessage({
              id: "positionManagement.positionClose.placeholderStockCode",
            })}
            onChange={(e) => {
              dispatch(
                updatePositionCloseFilter({
                  key: "secCd",
                  value: e ? e.target.value : "",
                })
              );
            }}
            value={secCd}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "positionManagement.positionClose.fromDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={fromDate}
            onChange={(e) => {
              dispatch(
                updatePositionCloseFilter({
                  key: "fromDate",
                  value: e ? e : "",
                })
              );
            }}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "positionManagement.positionClose.toDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={toDate}
            onChange={(e) => {
              dispatch(
                updatePositionCloseFilter({
                  key: "toDate",
                  value: e ? e : "",
                })
              );
            }}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "positionManagement.positionClose.search",
          })}
        </Button>
      </FlexContainer>
    </WrapBoder>
  );
}

export default memo(PositionCloseFilter);
