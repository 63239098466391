import { FC } from "react";
import Tabs from "./components/Tabs";
import RightInfoContent from "./components/RightInfoContent";
import { Container } from "./styles";

const RightInfo: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <Tabs />
      <RightInfoContent />
    </Container>
  );
};

export default RightInfo;
