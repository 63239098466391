import { FC } from "react";
import { PriceCell as PriceCellWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { RootState } from "redux/store";
import { WatchListState } from "modules/stock-order/redux/watchList";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import numeral from "numeral";
// import { UNIT_NUMBER } from "helper/consts";

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const priceSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.secdetailinfo?.lastprice
  );
};

const textColorSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.colorcode
  );
};

interface Props {
  ticker: string;
}

const PriceCell: FC<Props> = ({ ticker }) => {
  const price = useSelector(priceSelector(ticker)) || 0;
  const textColor = useSelector(textColorSelector(ticker));

  return (
    <PriceCellWrapper textcolor={textColor}>
      <BlinkableCell value={numeral(price).format("0,0.00")} />
    </PriceCellWrapper>
  );
};

export { PriceCell };
