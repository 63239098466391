import { FC, useMemo } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Spacer } from "components/commons";
import numeral from "numeral";
import {
  Container,
  Title,
  ButtonExit,
  TitleContent,
  RejectReason,
} from "./styles";
import { TextTradeType } from "../style";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { getOrderPendingStatus } from "../OrderBookPendingTable";
import { Channel } from "helper/consts";
import moment from "moment";

interface Props {
  showModal: boolean;
  setShowModal: any;
  dataRow?: any;
}

/**
 * Modal
 */
const ModalDetailOrderPending: FC<Props> = ({
  showModal,
  setShowModal,
  dataRow,
}) => {
  const intl = useIntl();

  const detailClone = useMemo(() => {
    return {
      ...dataRow?.original,
      statusDisp: getOrderPendingStatus(dataRow?.original?.status),
      tradeTypeDisp:
        dataRow?.original?.tradeType === 2 ? "orderBook.BUY" : "orderBook.SELL",
      orderPriceDisp:
        dataRow?.original?.ordType === "LO"
          ? numeral(dataRow?.original?.ordPrice).format("0,0.00")
          : dataRow?.original?.ordType,
      channelDisp: dataRow?.original?.ordChanel
        ? Channel[dataRow?.original?.ordChanel] || dataRow?.original?.ordChanel
        : intl.formatMessage({ id: "orderBookDer.noValue" }),
      createdTimeDisp: dataRow?.original?.regDateTime
        ? moment(dataRow?.original?.regDateTime).format("DD/MM/YYYY HH:mm:ss")
        : "- - -",
    };
  }, [dataRow, intl]);

  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        dialogClassName="modal-502"
      >
        <Container>
          <Row>
            <Col xs={12}>
              <Title>
                <div className="flex-1"></div>
                <TitleContent side={detailClone?.tradeTypeDisp}>
                  {intl
                    .formatMessage({ id: "widgets.orderBookDetail.title" })
                    .toUpperCase()}{" "}
                  {detailClone?.reqNo}
                </TitleContent>
                <ButtonExit>
                  <IconExit onClick={() => setShowModal(false)} />
                </ButtonExit>
              </Title>
            </Col>
          </Row>
          <Spacer size="m" />
          <div className="px-3">
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.status" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.statusDisp
                  ? intl.formatMessage({
                      id: detailClone?.statusDisp,
                    })
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "widgets.orderBookDetail.tradeType",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                <TextTradeType
                  side={detailClone?.tradeTypeDisp}
                  className={detailClone?.tradeTypeDisp}
                >
                  {detailClone?.tradeTypeDisp &&
                    intl.formatMessage({
                      id: detailClone?.tradeTypeDisp,
                    })}
                </TextTradeType>
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.symbol" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.secCd}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.orderQty" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.ordQty
                  ? numeral(detailClone?.ordQty).format("0,0")
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.price" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.orderPriceDisp}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.orderNo" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.reqNo}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "widgets.orderBookDetail.channel" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.channelDisp}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "widgets.orderBookDetail.tradingDate",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.createdTimeDisp
                  ? detailClone?.createdTimeDisp
                  : "---"}
              </Col>
            </Row>
            <Spacer size="s" />
            {detailClone?.notes && (
              <Row>
                <Col xs={4}>
                  {intl.formatMessage({
                    id: "widgets.orderBookDetail.rejectReason",
                  })}
                </Col>
                <Col xs={8} className="text-right text-color-light">
                  <RejectReason>
                    {detailClone?.notes ? (
                      <FormattedMessage
                        id={"error." + detailClone?.notes.split(":")[0]}
                      />
                    ) : (
                      "---"
                    )}
                  </RejectReason>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default ModalDetailOrderPending;
