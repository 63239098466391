export enum GroupEvent {
  market = "market", // market
  order = "order", // order
  ekyc = "ekyc", // ekyc
  api = "api", // api
  apiEkyc = "apiEkyc",
  appState = "appState", // appState
  socket = "socket", // Mqtt
  qA = "qA",

  // Event
  login = "login", // Đăng nhập
  expanded = "expanded", // mở rông
  noti = "noti", // thông báo
  advanceMoney = "advanceMoney", // ứng tiền
  assets = "assets", // tài sản
  satementMoney = "satementMoney", // sao kê
  moneyTransfer = "moneyTransfer", // chuyển khoản [chuyển tiền MBS]
  securities = "securities", // chuyển khoản chứng khoán
  marginDebit = "marginDebit", // công nợ margin
  profitLoss = "profitLoss", // lãi lỗ thực hiện
  xmlOnline = "xmlOnline", // xác nhận lệnh
  exerciseRegisterRights = "exerciseRegisterRights", // đăng ký quyền mua
  dertPosition = "dertPosition", // quản lý vị thế
  verifyQrCode = "verifyQrCode", // xác thực QRCode
  support = "support", // Hỗ trợ
  setting = "setting", // Cài đặt
  registerOtp = "registerOtp", // đăng ký smart OTP
  investmentTraningAndTutorial = "investmentTraningAndTutorial", // Đào tạo và hướng dẫn sử dụng
  alert = "alert", // Cảnh báo
  registerServiceOnline = "registerServiceOnline", // đăng ký dịch vụ Online
  contractTemplatePMoney = "contractTemplatePMoney", // Biểu lại Quản lý tiền TKCK
  contractTemplate = " contractTemplate", // Hợp đồng khung
  depositPower = "depositPower", // Liên kết sức mua
  loanOffer = "loanOffer", // Đề nghị cho vay
  managerRequestLoan = "managerRequestLoan", // Quản lý đề nghị cho vay
  borrowingLimit = "borrowingLimit", // Thay đổi hạn mức
  professionalInvestor = "professionalInvestor", // Nhà đầu tư chuyên nghiệp
  registerTransferBond = "registerTransferBond", // Đăng ký chuyển nhượng
  acceptTransferBond = "acceptTransferBond", // Xác nhân chuyển nhượng
  transferBond = "transferBond", // Chuyển nhượng
  profitChartPMoney = "profitChartPMoney", // Biểu lãi
  routerContractTemplatePMoney = "routerContractTemplatePMoney", // Hợp đồng khung tài khoản chứng khoán tự động
  accountInfo = "accountInfo", // Thông tin tài khoản
}

export enum ScreenLog {
  ///====> +++
  login = "login", // login
  forgotPassword = "forgotPassword", // Quên mật khẩu
  routerWebViewRiskDisclosure = "routerWebViewRiskDisclosure", // Công bố rủi ro
  routerWebViewGuide = "routerWebViewGuide", // Hướng dẫn
  routerWebViewContact = "routerWebViewContact", // Liên hệ
  ///====> [GroupEvent.ekyc]
  routerEkyc = "routerEkyc", // ekyc
  ///====> [GroupEvent.market]
  watchlist = "watchlist", // Danh mục      //done
  stockDetail = "stockDetail", // Chi tiết Mã
  searchStock = "searchStock", // Tìm kiếm mã
  selectWatchList = "selectWatchList", // chọn danh mục thêm mã
  manageWatchList = "manageWatchList", // Quản lý danh mục
  routerAddWatchList = "routerAddWatchList", // Tạo danh mục mới  // update
  routerEditWatchList = "routerEditWatchList", // Sửa thu mục   // new
  routerMarketVNPage = "routerMarketVNPage", // thị trường Việt Nam
  routerMarketAddWidget = "routerMarketAddWidget", // Thêm widget Market
  routerMarketGlobalPage = "routerMarketGlobalPage", // thị trường Thế giới
  routerTopSecUpDown = "routerTopSecUpDown", // top tăng /giảm  // new
  marketTopSec = "marketTopSec", // Top CP giao dịch // new
  marketTopForeign = "marketTopForeign", // Top nước ngoài mua bán // new
  ///====> [GroupEvent.order]
  placeOrder = "placeOrder", // Đặt lệnh cơ sở      //done
  orderList = "orderList", // Sổ lệnh cơ sở
  orderDetail = "orderDetail", // chi tiết lệnh cơ sở
  placeOrderDerivative = "placeOrderDerivative", // đặt lệnh phái sinh      //done
  orderDerivativeList = "orderDerivativeList", // Sổ lệnh phái sinh
  orderDerivativeDetail = "orderDerivativeDetail", // chi tiết lệnh phái sinh
  norOrderHistory = "norOrderHistory", // lịch sử lệnh thường
  orderHistoryDetail = "orderHistoryDetail", // chi tiết lịch sử lệnh thường
  derOrderHistory = "derOrderHistory", // lịch sử lệnh phái sinh
  orderHistoryDetailDer = "orderHistoryDetailDer", // chi tiết lịch sử lệnh phái sinh
  routerAccountNoDerivative = "routerAccountNoDerivative", // chưa có tk phái sinh  // new
  ///====> [GroupEvent.expanded]
  expanded = "expanded", // Mở rộng
  ///====> [GroupEvent.noti]
  notificationOrder = "notificationOrder", // Thông báo lệnh
  notificationMoney = "notificationMoney", // Thông báo tiền
  notificationOther = "notificationOther", // Thông báo CSKH
  detailNotification = "detailNotification", // chi tiết thông báo
  ///====> [GroupEvent.assets]
  routerDerivativeAssetDetail = "routerDerivativeAssetDetail", // Tài sản
  routerDerivativePosition = "routerDerivativePosition", // Lãi lỗ
  routerDerivativeInvestEffects = "routerDerivativeInvestEffects", // Hiệu quả đầu tư
  ///====> [GroupEvent.cashAdvance]
  cashAdvanceScreen = "cashAdvanceScreen", // Lịch sử ứng tiền
  popupCashAdvance = "popupCashAdvance", // ứng tiền trước bán
  ///====> [GroupEvent.satementMoney]
  routerSatementMoney = "routerSatementMoney", // sao kê tiền
  routerSatementStock = "routerSatementStock", // sao kê chứng khoán
  ///====> [GroupEvent.moneyTransfer]  ///[Doing]
  moneyTransfer = "moneyTransfer", // Chuyển khoản tiền
  moneyTransferHistory = "moneyTransferHistory", //  lịch sử chuyển khoản tiền
  ///====> [GroupEvent.securities]
  securitiesHistory = "securitiesHistory", // lịch sử chuyển khoản CK
  securitiesTransfer = "securitiesTransfer", // Tạo y/c chuyển khoản chứng khoán
  ///===> [GroupEvent.marginDebit],
  routerMarginDebitInfo = "routerMarginDebitInfo", // danh sách khoản vay
  routerMarginDebitHistory = "routerMarginDebitHistory", // Lịch sử
  ///===> [GroupEvent.profitLoss]
  routerProfitLoss = "routerProfitLoss", // danh sách lãi lỗ thực hiện
  routerProfitLossChart = "routerProfitLossChart", // chart lãi lỗ
  ///===> [GroupEvent.xmlOnline]
  orderConfirm = "orderConfirm", // xác nhận lệnh
  ///===> [GroupEvent.exerciseRegisterRights]
  routerRightInfo = "routerRightInfo", // danh sách quyền
  routerRightInfoDetail = "routerRightInfoDetail", // chi tiết quyền
  routerRightInfoHis = "routerRightInfoHis", // lịch sử quyền
  ///===> [GroupEvent.dertPosition]
  routerDertOpenPosition = "routerDertOpenPosition", // vị thế mở
  routerDertClosePosition = "routerDertClosePosition", // vị thế đóng
  ///===> [GroupEvent.verifyQrCode]
  verifyQrCodePage = "verifyQrCodePage", // QR code
  ///===> [GroupEvent.support]
  supportPage = "supportPage", // hỗ trợ
  ///===> [GroupEvent.setting]
  setting = "setting", // cài đặt
  smartOTP = "smartOTP", // thiết lập SmartOTP
  unregisterSmartOtp = "unregisterSmartOtp", // Quản lý thiết bị đăng ký SmartOTP
  routerSMSService = "routerSMSService", // Đăng ký dịch vụ sms
  routerEmailService = "routerEmailService", // Đăng ký dịch vụ mail
  tradingAccount = "tradingAccount", // Thiết lập tài khoản giao dịch
  addAccBank = "addAccBank", // Thêm tk thụ hưởng
  ///===> [GroupEvent.registerOtp]
  screenRegisterOtp = "screenRegisterOtp", // đăng ký smartOTP
  registerOtpInPutSMS = "registerOtpInPutSMS", // nhập SMS OTP đăng ký
  createPinCode = "createPinCode", // tạo pinCode
  ///===> [GroupEvent.investmentTraningAndTutorial]
  routerInvestmentTraning = "routerInvestmentTraning", // Đào tạo
  routerInvestmentTutorial = "routerInvestmentTutorial", // Hướng dẫn đào tạo
  ///===> [GroupEvent.alert]
  createAlert = "createAlert", // cảnh báo
  managerAlert = "managerAlert", // quản lý cảnh báo
  ///===> [GroupEvent.registerServiceOnline]
  routerRegisterServiceOnline = "routerRegisterServiceOnline", // Đăng ký dịch vụ Online
  ///===> [GroupEvent.contractTemplatePMoney]
  routerContractTemplatePMoney = "routerContractTemplatePMoney", // Biểu lãi quản lý tiền TKCK
  ///===> [GroupEvent.contractTemplate]
  routerContractTemplate = "routerContractTemplate", // Hợp đồng khung
  ///===> [GroupEvent.depositPower]
  routerDepositPower = "routerDepositPower", // Liên kết sức mua
  ///===> [GroupEvent.loanOffer]
  routerLoanOffer = "routerLoanOffer", // Đề nghị chi vay
  ///===> [GroupEvent.managerRequestLoan]
  routerManagerRequestLoan = "routerManagerRequestLoan", // Quản lý đề nghị cho vay
  ///===> [GroupEvent.borrowingLimit]
  routerChangeLimit = "routerChangeLimit", // Thay đổi hạn mức
  ///===> [GroupEvent.professionalInvestor]
  routerProfessionalInvestor = "routerProfessionalInvestor", // Nhà đầu tư chuyên nghiệp
  ///===> [GroupEvent.registerTransferBond]
  routerRegisterTransferBond = "routerRegisterTransferBond", // Đăng ký chuyển nhượng
  ///===> [GroupEvent.acceptTransferBond]
  routerAcceptTransferBond = "routerAcceptTransferBond", // Xác nhận chuyển nhượng
  ///===> [GroupEvent.transferBond]
  routerTransferBond = "routerTransferBond", // DS chuyển nhượng
  ///===> [GroupEvent.profitChartPMoney]
  routerProfitChartPMoney = "routerProfitChartPMoney", // Biểu lãi
  ///===> [GroupEvent.accountInfo]
  accountInfo = "accountInfo", // thông tin tài khoản
}

export enum RealtimeConst {
  enterApp = "enterApp", // init app   //done
  exitApp = "exitApp", // end app     //done

  ///==============================================
  enterScreen = "enterScreen", // init View     //done
  exitScreen = "exitScreen", // end View       //done

  ///==============================================
  //= event
  initLanguage = "initLanguage", // ngôn ngữ mặc định (khi mở app)      //done
  initTheme = "initTheme", // theme mặc định (khi mở app)     //done

  ///====> [ScreenLog.login]
  changeLanguage = "changeLanguage", // e_ thay đổi ngôn ngữ      //done
  changeTheme = "changeTheme", // e_ thay dổi theme     //done
  submitLogin = "submitLogin", // e_ login      //done

  ///====> [ScreenLog.forgotPassword]
  submitForgotPassword = "submitForgotPassword", // e_ submit quên mật khẩu     //done

  ///====> [ScreenLog.watchlist]
  changeWatchList = "changeWatchList", // e_ thay đổi danh mục      //done
  refreshWatchList = "refreshWatchList", // e_ refresh danh mục
  sortWatchList = "sortWatchList", // e_ sắp xếp danh mục tăng/giảm dần     //done

  ///====> [ScreenLog.stockDetail]
  getDataStockChart = "getDataStockChart", // e_ filter getData Stock chart
  quotes = "quotes", // e_ tab tổng quan
  technicalAnalysis = "technicalAnalysis", // e_ tab PTKT     //done
  news = "news", // e_ tab tin tức

  ///====> [ScreenLog.searchStock]
  inputStock = "inputStock", // e_ nhập mã tìm kiếm     //done

  ///====> [ScreenLog.selectWatchList]
  updateWatchList = "updateWatchList", // thêm, xoá mã danh mục // update     //done

  ///====> [ScreenLog.manageWatchList]
  removeWatchList = "removeWatchList", // Xoá Wl  // new      //done

  ///====> [ScreenLog.routerAddWatchList]
  createWatchList = "createWatchList", // e_ tạo mới w      //done

  ///====> [ScreenLog.routerEditWatchList]
  editWatchList = "editWatchList", // sửa tên w // new      //done

  ///====> [ScreenLog.routerMarketVNPage]
  changeIndex = "changeIndex", //e_ chọn sàn      //done
  marketRemoveWidget = "marketRemoveWidget", //xoá widget // new
  topSecChangedFilterMass = "topSecChangedFilterMass", // tab Khối lượng // new
  topSecChangedFilterValue = "topSecChangedFilterValue", // tab Giá trị // new
  topSecChangedFilterMarketCap = "topSecChangedFilterMarketCap", // tab Vốn hoá // new
  topSecChangedFilterIndexIndustries = "topSecChangedFilterIndexIndustries", // tab Mức đóng góp theo ngành // new
  topSecChangedFilterIndustries = "topSecChangedFilterIndustries", // tab GD theo ngành // new
  changeDayNetFlow = "changeDayNetFlow", //done

  ///====> [ScreenLog.routerMarketAddWidget]
  submitMarketAddWidget = "submitMarketAddWidget", //e_ Lưu thay đổi widget thị trường (Việt Nam)

  ///====> [ScreenLog.routerTopSecUpDown]
  filterTopSecUpDown = "filterTopSecUpDown", // filter top tăng/giảm (chọn time) // new

  ///====> [ScreenLog.marketTopSec]
  changeTypeTopSec = "changeTypeTopSec", //done

  ///====> [ScreenLog.marketTopForeign]
  changeTypeTopForeign = "changeTypeTopForeign", //done

  ///====> [ScreenLog.placeOrder]
  submitPlaceOrder = "submitPlaceOrder", // e_ submit đặt lệnh cơ sở      //done
  changeSecCD = "changeSecCD", // e_ chọn mã      //done

  ///====> [ScreenLog.orderList]
  dataOrderNormal = "dataOrderNormal", // filter getData sổ lệnh (update)     //done
  cancelOrderList = "cancelOrderList", // Huỷ list lệnh cơ sở     //done

  ///====> [ScreenLog.orderDetail]
  cancelOrder = "cancelOrder", // e_ huỷ lệnh thường
  editOrder = "editOrder", // e_sửa lệnh thường

  ///====> [ScreenLog.placeOrderDerivative]
  submitPlaceOrderDerivative = "submitPlaceOrderDerivative", //  e_ submit đặt lệnh phái sinh
  changeSecCDDer = "changeSecCDDer", // e_ chọn mã phái sinh

  ///====> [ScreenLog.orderDerivativeList]
  dataOrderDerivative = "dataOrderDerivative", // filter getData sổ lệnh phái sinh (update)

  ///====> [ScreenLog.orderDerivativeDetail]
  cancelOrderDerivative = "cancelOrderDerivative", // e_ huỷ lệnh phái sinh
  editOrderDerivative = "editOrderDerivative", // e_sửa lệnh phái sinh

  ///====> [ScreenLog.derOrderHistory]
  getDerOrderHistory = "getDerOrderHistory", // e_ filter getData lịch sử lệnh phái sinh

  ///====> [ScreenLog.expanded]
  logout = "logout", // e_ Đăng xuất

  ///====> [ScreenLog.norOrderHistory]
  getOrderHistoryNormal = "getOrderHistoryNormal", // e_ filter getData lịch sử lệnh thường
  filterDataInvestChart = "filterDataInvestChart", // e_ thay đổi time hiệu qủa đầu tư

  ///====> [ScreenLog.notificationOrder]_[ScreenLog.notificationMoney]_[ScreenLog.notificationOther]
  removeNotification = "removeNotification", // e_ Xoá thông báo
  readAllNotification = "readAllNotification", // e_ Đã đọc all thông báo

  ///====> [ScreenLog.routerDerivativeAssetDetail]
  getDataDerivativeAsset = "getDataDerivativeAsset", // e_ get Data tải sản

  ///====> [ScreenLog.routerDerivativePosition]
  getDataDerivativePosition = "getDataDerivativePosition", // e_ get Data lãi lỗ

  ///====> [ScreenLog.routerDerivativeInvestEffects]
  getDataInvestEffects = "getDataInvestEffects", // e_ get Data Hiệu quả đầu tư
  getDataNetWorth = "getDataNetWorth", // e_ get Data Tài sản ròng

  ///====> [ScreenLog.cashAdvanceScreen]
  getDataAdvanceHistory = "getDataAdvanceHistory", // e_ Get Data lịch sử ứng tiền

  ///====> [ScreenLog.popupCashAdvance]
  submitAdvance = "submitAdvance", // e_ subm ứng trước tiền bán

  ///====> [ScreenLog.routerSatementMoney]
  getDataSatementMoney = "getDataSatementMoney", // e_ get Data sao kê tiền

  ///====> [ScreenLog.routerSatementStock]
  getDataSatementStock = "getDataSatementStock", // e_ get Data sao kê chứng khoán

  ///====> [ScreenLog.moneyTransferHistory]
  getDataMoneyTransferHistory = "getDataMoneyTransferHistory", // e_ get Data Lịch sử chuyển khoản tiền

  ///====> [ScreenLog.moneyTransfer]
  postMoneyTransfer = "postMoneyTransfer", // e_ submit chuyển khoản tiền

  ///====> [GroupEvent.securities]
  securitiesHistory = "securitiesHistory", // v_ lịch sử chuyển khoản CK
  ///====> [ScreenLog.securitiesHistory]
  getDataSecuritiesHistory = "getDataSecuritiesHistory", // e_ get Data Lịch sử chuyển khoản chứng khoán

  ///====> [ScreenLog.securitiesTransfer]
  postTransfer = "postTransfer", // e_ submit chuyển khoản chứng khoán

  ///====> [ScreenLog.routerMarginDebitInfo]
  getDataMarginDebit = "getDataMarginDebit", // e_ get Data Khoản vay //done done

  ///====> [ScreenLog.routerMarginDebitHistory]
  getDataMarginDebitHistory = "getDataMarginDebitHistory", // e_ get Data lịch sử khoản vay

  ///===> [ScreenLog.routerProfitLoss]
  getDataProfitLoss = "getDataProfitLoss", // e_ get Data danh sách lãi lỗ

  ///===> [ScreenLog.routerProfitLossChart]
  getDataProfitLossChart = "getDataProfitLossChart", // e_ filter data chart lãi lỗ

  ///===> [ScreenLog.orderConfirm]
  getHistoryOrder = "getHistoryOrder", // filter get data xác nhận lệnh online //done
  submitConfirmOrder = "submitConfirmOrder", // submit xác nhận lệnh online  //done

  ///===> [ScreenLog.routerRightInfo]
  getDataRightInfo = "getDataRightInfo", // e_ get data danh sách quyền

  ///===> [ScreenLog.routerRightInfoDetail]
  postExerciseRight = "postExerciseRight", // e_ thực hiện đk-huỷđk quyền

  ///===> [ScreenLog.routerDertOpenPosition]
  getDataDertOpenPosition = "getDataDertOpenPosition", // e_ getData vị thế mở

  ///===> [ScreenLog.routerDertClosePosition]
  getDataDertClosePosition = "getDataDertClosePosition", // e_ getData vị thế đóng

  ///===> [ScreenLog.supportPage]
  submitSupport = "submitSupport", // e_ gửi hỗ trợ, góp ý

  ///===> [ScreenLog.setting]
  setBiometricsToLocal = "setBiometricsToLocal", // Cài đặt sinh trắc học

  routerChangeFontSize = "routerChangeFontSize", // e_ thay đổi font size

  ///===> [ScreenLog.unregisterSmartOtp]
  submitDeleteDeviceSmartOTP = "submitDeleteDeviceSmartOTP", // e_ Submit xoá thiết bị smartOTP

  ///===> [ScreenLog.routerSMSService]
  updateSMSService = "updateSMSService", // e_ Cập nhật đăng ký dịch vụ sms

  ///===> [ScreenLog.routerEmailService]
  updateEmailService = "updateEmailService", //e_ Cập nhật đăng ký dịch vụ mail

  ///===> [ScreenLog.tradingAccount]
  registerAuthenticationMethod = "registerAuthenticationMethod", // e_ submit phương thức xác thực cấp 2
  selectAccDefault = "selectAccDefault", // e_ Chọn tài khoản mặc định
  selectAccBank = "selectAccBank", // e_ Chọn tài khoản thụ hưởng
  deleteAccBank = "deleteAccBank", // e_ Xoá tài khoản thụ hưởng

  ///===> [ScreenLog.addAccBank]
  submitAddAccBank = "submitAddAccBank", // e_ Thêm tk thụ hưởng

  ///===> [ScreenLog.screenRegisterOtp]
  confirmTerm = "confirmTerm", // e_ xác nhận điều khoản

  ///===> [ScreenLog.registerOtpInPutSMS]
  submitSMSOTP = "submitSMSOTP", // e_ gửi SMS OTP

  ///===> [ScreenLog.createPinCode]
  submitPinCode = "submitPinCode", // e_ submit tạo pinCode

  ///===> [ScreenLog.createAlert]
  resetCreateAlert = "resetCreateAlert", // huỷ tạo cảnh báo
  submitCreateAlert = "submitCreateAlert", // submit cảnh báo

  ///===> [ScreenLog.managerAlert]
  getListAlerts = "getListAlerts", // get Data list cảnh báo
  deleteAlert = "deleteAlert", // xoá cảnh báo
  updateAlert = "updateAlert", // update cảnh báo

  ///===> [ScreenLog.routerRegisterServiceOnline]
  automaticAdvanceRegister = "automaticAdvanceRegister", // e_ Đăng ký ứng trước tự động

  ///===> [ScreenLog.routerContractTemplatePMoney]
  chagneInterestType = "chagneInterestType", // e_ thay đổi loại lãi

  ///===> [ScreenLog.routerContractTemplate]
  editContractTemplate = "editContractTemplate", // e_ sửa hợp đồng khung
  liquidation = "liquidation", // e_ thanh lý hợp đồng khung

  ///===> [ScreenLog.routerDepositPower]
  registerDepositPower = "registerDepositPower", // e_ đăng ký sức mua

  ///===> [ScreenLog.routerLoanOffer]
  performLoanOffer = "performLoanOffer", // e_ Đề nghị chi vay

  ///===> [ScreenLog.routerManagerRequestLoan]
  getDataRequestLoan = "getDataRequestLoan", // e_ get data DL đề nghị cho vay

  ///===> [ScreenLog.routerChangeLimit]
  submitChangeLimit = "submitChangeLimit", // e_ Thực hiện Thay đổi hạn mức

  ///===> [ScreenLog.routerRegisterTransferBond]
  submitRegisterTransferBond = "submitRegisterTransferBond", // e_ Thực hiện đăng ký chuyển nhượng

  ///===> [ScreenLog.routerAcceptTransferBond]
  getDataAcceptTransferBond = "getDataAcceptTransferBond", // e_ filter getData Xác nhạn chuyện nhượng

  ///===> [ScreenLog.routerTransferBond]
  getDataTransferBond = "getDataTransferBond", // e_ filter getData chuyển nhượng

  ///====> [GroupEvent.market]
  stockDetail = "stockDetail", // v_ Chi tiết Mã      //done
  addTickerToWatchList = "addTickerToWatchList", // e_ thêm mã vào danh mục     //done
  //web add F2
  changeTypeTopAdvancers = "changeTypeTopAdvancers", //done
  changeTypeTopDecliners = "changeTypeTopDecliners", //done
}
