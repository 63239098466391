import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IDepositList } from "domain/models/DepositList";
import { IGetCustomer2 } from "./type";
import {
  createContractApi,
  getContractApi,
  getCustomer2Api,
  liquidationApi,
} from "./urls";

export const getCustomer2 = async (
  param: IGetCustomer2
): Promise<IDepositList> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getCustomer2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getContract = async (param: any): Promise<IDepositList> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getContractApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createContract = async (param: any): Promise<any> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    createContractApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const liquidation = async (param: any): Promise<any> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    liquidationApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
