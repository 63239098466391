import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import SelectTable from "components/commons/SelectTable";
import { OrderBookDerDisplay } from "domain/models/OrderBookDer";
import {
  changeSelectedRows,
  onCheckAll,
} from "modules/stock-order-der/redux/OrderBookDer";
import {
  enableInfinitySelector,
  listSelectedRowSelector,
  isCheckAllSelector,
} from "modules/stock-order-der/selectors";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import StatusCell from "./CellRealTime/StatusCell";
import QttyCell from "./CellRealTime/QttyCell";
import OrderPriceDispCell from "./CellRealTime/OrderPriceDispCell";
import ExecqttyCell from "./CellRealTime/ExecqttyCell";
import AvgpriceCell from "./CellRealTime/AvgpriceCell";
import PubPriceCell from "./CellRealTime/PubPriceCell";
import PubQtyCell from "./CellRealTime/PubQtyCell";
import EditOrderBookDerModal from "./EditOrderBookDerModal";
import OrderBookDerRowAction from "./OrderBookDerRowAction";
import OrderDetailModal from "./OrderDetailModal";
import { Checkbox, TableContainer, TextTradeType } from "./styles";
import CheckboxCell from "./CellRealTime/CheckboxCell";

interface WidgetContentProps {
  data: OrderBookDerDisplay[];
  fetchMoreData: any;
}
function OrderBookDerTable({ data, fetchMoreData }: WidgetContentProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enableInfinity = useSelector(enableInfinitySelector);
  const selectedRows = useSelector(listSelectedRowSelector);

  const isCheckAll = useSelector(isCheckAllSelector);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [dataRowEdit, setDataRowEdit] = useState<OrderBookDerDisplay>();

  const setSelectedRows = () => {};

  const handleCheckAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    orderList: OrderBookDerDisplay[]
  ) => {
    if (e.target.checked) {
      const selectedRowsNew: OrderBookDerDisplay[] = orderList.filter(
        (item) => item.visibleCheckbox === true
      );
      dispatch(changeSelectedRows(selectedRowsNew));
      dispatch(onCheckAll(true));
    } else {
      dispatch(changeSelectedRows([]));
      dispatch(onCheckAll(false));
    }
  };

  const handleCheck = (
    order: OrderBookDerDisplay,
    e: React.ChangeEvent<HTMLInputElement>,
    orderList: OrderBookDerDisplay[]
  ) => {
    if (e.target.checked) {
      if (selectedRows && selectedRows.length > 0) {
        const selectedRowsNew = [...selectedRows, order];
        dispatch(changeSelectedRows(selectedRowsNew));
        const data: OrderBookDerDisplay[] = orderList.filter(
          (item) => item.visibleCheckbox === true
        );

        if (selectedRowsNew.length === data.length) {
          dispatch(onCheckAll(true));
        }
      } else {
        dispatch(changeSelectedRows([order]));
      }
    } else {
      if (selectedRows && selectedRows.length > 0) {
        const selectedRowsNew = selectedRows.filter((item) => item !== order);
        dispatch(changeSelectedRows(selectedRowsNew));
      } else {
        dispatch(changeSelectedRows([]));
      }
      dispatch(onCheckAll(false));
    }
  };

  const getChecked = (
    order: OrderBookDerDisplay,
    selectedList: OrderBookDerDisplay[]
  ) => {
    return selectedList &&
      selectedList.find((item) => item.orgOrderNo === order.orgOrderNo)
      ? true
      : false;
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <Checkbox
              checked={isCheckAll}
              onChange={(e: any) => handleCheckAll(e, data)}
            />
          </TableHeaderCellContent>
        ),
        accessor: "rootorderid",
        disableSortBy: true,
        width: 50,
        Cell: (props: CellProps<OrderBookDerDisplay>) => {
          const item = props?.row?.original;
          const checked = getChecked(item, selectedRows);
          const realTimeId = item?.realTimeId;
          return (
            <CheckboxCell
              realTimeId={realTimeId}
              checked={checked}
              handleCheck={handleCheck}
              item={item}
              data={data}
            />
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.secCd" />
          </TableHeaderCellContent>
        ),
        accessor: "secCd",
        minWidth: 78,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.subAccoNo" />
          </TableHeaderCellContent>
        ),
        accessor: "custNo",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.tradeType" />
          </TableHeaderCellContent>
        ),
        accessor: "tradeTypeDisplay",
        minWidth: 70,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: CellProps<OrderBookDerDisplay>) => {
          return (
            <TableCellContent align={"center"}>
              <TextTradeType side={props.value}>
                {props.value && intl.formatMessage({ id: props.value })}
              </TextTradeType>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.ordType" />
          </TableHeaderCellContent>
        ),
        accessor: "ordType",
        minWidth: 70,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: CellProps<OrderBookDerDisplay>) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.extStatus" />
          </TableHeaderCellContent>
        ),
        accessor: "orderStatusDisplay",
        minWidth: 120,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<OrderBookDerDisplay>) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <StatusCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.orderQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <QttyCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.price" />
          </TableHeaderCellContent>
        ),
        accessor: "orderPriceDisp",
        minWidth: 64,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <OrderPriceDispCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.matQty" />
          </TableHeaderCellContent>
        ),
        accessor: "matQty",
        minWidth: 64,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <ExecqttyCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.avgPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "avgPrice",
        minWidth: 85,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <AvgpriceCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.pubQty" />
          </TableHeaderCellContent>
        ),
        accessor: "pubQty",
        minWidth: 98,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <PubQtyCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.pubPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "pubPrice",
        minWidth: 84,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <PubPriceCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.orderNo" />
          </TableHeaderCellContent>
        ),
        accessor: "orgOrderNo",
        minWidth: 88,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.createdTime" />
          </TableHeaderCellContent>
        ),
        accessor: "createdTimeDisp",
        minWidth: 130,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.channel" />
          </TableHeaderCellContent>
        ),
        accessor: "channelDisplay",
        minWidth: 74,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: CellProps<OrderBookDerDisplay>) => {
          const item = props?.row?.original;
          const realTimeId = item?.realTimeId;
          return (
            <OrderBookDerRowAction
              data={props.row.original}
              setDataRow={setDataRowEdit}
              setShowEdit={setShowModalEdit}
              realTimeId={realTimeId}
            />
          );
        },
      },
    ],
    [selectedRows, data]
  );

  return (
    <TableContainer
      heighFooter={selectedRows && selectedRows.length > 0 ? 50 : 0}
      id="table-infinity-order-book-der"
    >
      <SelectTable
        columns={columns}
        data={data}
        setShowModal={setShowModal}
        setDataRow={setDataRow}
        setSelectedRows={setSelectedRows}
        fetchMoreData={fetchMoreData}
        enableInfinity={enableInfinity}
        scrollableTarget="table-infinity-order-book-der"
        hiddenCheckbox
      />
      {dataRow && showModal && (
        <OrderDetailModal
          showModal={showModal}
          setShowModal={setShowModal}
          dataRow={dataRow}
        />
      )}
      <EditOrderBookDerModal
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        dataRow={dataRowEdit}
      />
    </TableContainer>
  );
}

export default React.memo(OrderBookDerTable);
