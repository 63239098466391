import { Controller, RegisterOptions } from "react-hook-form";
import classNames from "classnames";
import { InputBox, InputConntainer, Suffix, IconButton } from "./style";
import React, { memo, useCallback, useState } from "react";
import { ReactComponent as Eye } from "assets/image/svg/eye.svg";
import { ReactComponent as Eye2 } from "assets/image/svg/eye2.svg";
import { ReactComponent as SearchIcon } from "assets/image/svg/ic_search.svg";
import { ReactComponent as Close } from "assets/image/svg/closeicon2.svg";
import { SizeType } from "../Spacer";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  innerRef?: React.RefObject<HTMLTextAreaElement>;
  title?: string;
  placeholder?: string;
  sizeInput?: SizeType;
  name?: string;
  control?: any;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  textLayer?: string;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  isSearch?: boolean;
  isBorderActive?: boolean;
  radius?: number;
  isTextCenter?: boolean;
  showClearButton?: boolean;
  canClear?: boolean;
  onClearInput?: () => void;
  textArea?: boolean;
  isTextRight?: boolean;
  isHeight28?: boolean;
}

interface InputProps extends Props {
  error?: any;
  // invalid?: any;
}

const InputPlaceOrder: React.FC<InputProps> = ({
  title,
  sizeInput,
  placeholder,
  innerRef,
  isSearch,
  append,
  prepend,
  isBorderActive,
  radius,
  isTextCenter,
  showClearButton,
  canClear,
  onClearInput,
  textArea = false,
  isTextRight,
  isHeight28 = false,
  ...props
}) => {
  const [isShowingPassword, setTypePassword] = useState(false);

  return (
    <div className="w-100">
      {title && <div>{title}</div>}
      <InputConntainer
        className={classNames(
          { "pl-3": isSearch },
          { "input-container": !isSearch },
          "input-container"
        )}
        size={sizeInput}
        isBorderActive={isBorderActive}
        radius={radius}
        textArea={textArea}
        isHeight28={isHeight28}
      >
        {prepend && prepend}
        {isSearch && <SearchIcon />}
        <InputBox isTextCenter={isTextCenter} isTextRight={isTextRight}>
          <textarea
            className="input"
            ref={innerRef}
            rows={3}
            {...props}
            placeholder={placeholder}
          />
        </InputBox>
        <Suffix>
          {canClear && showClearButton && (
            <IconButton onClick={onClearInput} className="btn-close">
              <Close />
            </IconButton>
          )}
          {props.type === "password" && (
            <IconButton onClick={() => setTypePassword(!isShowingPassword)}>
              {isShowingPassword ? <Eye2 /> : <Eye />}
            </IconButton>
          )}
        </Suffix>
        {append && append}
      </InputConntainer>
    </div>
  );
};

const CInputPlaceOrder: React.FC<Props> = memo(
  ({ name, control, rules, onClearInput, canClear, textArea, ...props }) => {
    const [showClearButton, setShowClearButton] = useState<boolean>(false);

    const handleVisibleClearButton = useCallback((value: any) => {
      if (value) {
        setShowClearButton(true);
      } else {
        setShowClearButton(false);
      }
    }, []);

    const handleOnClearInput = () => {
      setShowClearButton(false);
      onClearInput?.();
    };

    return name ? (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, ...field }, fieldState, formState }) => (
          <InputPlaceOrder
            {...props}
            {...field}
            {...fieldState}
            canClear={canClear}
            textArea={textArea}
            showClearButton={showClearButton}
            onChange={(e) => {
              canClear && handleVisibleClearButton(e.target.value);
              field.onChange(e);
            }}
            onClearInput={handleOnClearInput}
          />
        )}
      />
    ) : (
      <InputPlaceOrder
        {...props}
        canClear={canClear}
        textArea={textArea}
        showClearButton={canClear}
        onClearInput={handleOnClearInput}
      />
    );
  }
);

export default CInputPlaceOrder;
