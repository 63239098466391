import styled from "styled-components/macro";

export const Container = styled.div`
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100%) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.white;
      case "red":
        return theme.red;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green;
      case "violet":
        return theme.violet;
      case "orange":
        return theme.solidOrange;
      default:
        return color;
    }
  }};

  opacity: 0.4px;
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
  color: ${({ theme, color }) => (color ? color : theme.grey0)};
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  background: ${({ theme }) => theme.background3};
  color: ${({ theme }) => theme.grey20};
  font-weight: 500;
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;

export const IconWrapper = styled.span`
  cursor: pointer;
`;
export const ButtonBuy = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.green50};
  color: ${({ theme }) => theme.grey0};
`;

export const ButtonSell = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.red50};
  color: ${({ theme }) => theme.grey0};
`;
export const ButtonDelete = styled.button`
  flex: 1;
  width: 100%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.red50};
  color: ${({ theme }) => theme.grey0};
`;
