import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { OrderBookDisplay, OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
// import { TableCellContent } from "components/commons/CommonTable/styles";
import { FormattedMessage } from "react-intl";
import { Button } from "../style";

const buttonEditSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) => state?.[realTimeId]?.isadmend
  );
};

interface Props {
  realTimeId: string;
  onEdit: (order: OrderBookDisplay) => void;
  item: OrderBookDisplay;
}

const ButtonEditCell = ({ realTimeId, onEdit, item }: Props) => {
  const value = useSelector(buttonEditSelector(realTimeId));
  const visibleButtonEdit = value;
  return (
    <>
      {visibleButtonEdit && (
        <Button
          side={`EDIT`}
          onClick={() => onEdit(item)}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          <FormattedMessage id={`common.editUp`} />
        </Button>
      )}
    </>
  );
};

export default ButtonEditCell;
