import styled from "styled-components/macro";

export const ContentBox = styled.div<{ loading: boolean; disabled: boolean }>`
  position: relative;
  width: 56px;
  height: 30px;

  input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    width: 56px;
    height: 28px;
    background: ${({ theme }) => theme.textcolorBlack};
    border: 1px solid ${({ theme }) => theme.grey70};
    display: block;
    border-radius: 28px;
    position: relative;
    padding: 0;
    margin: 0;

    & > .loading-holder {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 20px;
      height: 20px;
      background: ${({ theme }) => theme.grey50};
      border-radius: 50%;
      transition: 0.3s;
      animation: ${({ loading }) =>
        loading ? "loadingCircle 1.2s linear infinite" : ""};
      z-index: 4;

      .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        &:after {
          ${({ loading }) => (loading ? "content: ' '" : "")};
          display: block;
          width: 18px;
          height: 18px;
          margin: 1px;
          border-radius: 50%;
          border: 3px solid #1890ff;
          border-color: #1890ff transparent transparent transparent;
          animation: lds-dual-ring 1.2s linear infinite;
        }
      }
    }

    &:active:after {
      width: 56px;
    }
  }

  input:checked + label > .loading-holder {
    left: calc(100% - 25px);
    transform: translateX(0%);
    background: ${({ theme }) => theme.brandPrimary};
    .spinner: after {
      border-color: #fff transparent transparent transparent;
    }
  }

  @keyframes loadingCircle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
