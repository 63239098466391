import { createSelector } from "@reduxjs/toolkit";
import {
  categoriesInfoSelector,
  lastpriceSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import numeral from "numeral";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

const execpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.execprice
  );
};
const execratioSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.execratio?.data
  );
};

function BreakEvenPointCell({ ticker }: Props) {
  const execprice = useSelector(execpriceSelector(ticker)) || 0;
  const lastprice = useSelector(lastpriceSelector(ticker)) || 0;
  const execratio = useSelector(execratioSelector(ticker));

  const arr = execratio?.split(":");
  const execratioNumber =
    (numeral(arr?.[0]).value() ?? 1) / (numeral(arr?.[1]).value() ?? 1);

  return (
    <PriceCell
      price={execprice + lastprice * execratioNumber}
      isPreventSetCommand
    />
  );
}

export default BreakEvenPointCell;
