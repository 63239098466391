import queryString from "query-string";

export const getListDeviceApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/SODevice${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const deleteDeviceApi = `/deactiveSODevice`;
// export const deleteDeviceApi = (params: any) => {
//   const paramsFormatter = queryString.stringify(params, {
//     skipNull: true,
//   });
//   return `/deactiveSODevice${paramsFormatter ? `?${paramsFormatter}` : ""}`;
// };

export const getSmsOtpUrl = `/createSmsOtp`;

export const verifyTwoPhaseAuthenUrl = `/verifyTwoPhaseAuthen`;
