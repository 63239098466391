import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import {
  OrderBookDerDisplay,
  OrderBookDerRealTime,
} from "domain/models/OrderBookDer";
import { Dictionary } from "lodash";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { Checkbox } from "../styles";

const checkboxSelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) => state?.[realTimeId]?.iscancel
  );
};

interface Props {
  realTimeId: string;
  checked: boolean;
  handleCheck: (
    order: OrderBookDerDisplay,
    e: React.ChangeEvent<HTMLInputElement>,
    orderList: OrderBookDerDisplay[]
  ) => void;
  item: OrderBookDerDisplay;
  data: OrderBookDerDisplay[];
}

const CheckboxCell = ({
  realTimeId,
  checked,
  handleCheck,
  item,
  data,
}: Props) => {
  const value = useSelector(checkboxSelector(realTimeId));
  const visibleCheckbox = value;
  return (
    <>
      <TableCellContent align={"center"}>
        <Checkbox
          checked={checked}
          onChange={(e: any) => handleCheck(item, e, data)}
          disabled={!visibleCheckbox}
        />
      </TableCellContent>
    </>
  );
};

export default CheckboxCell;
