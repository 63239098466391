import { FC } from "react";
import { useSelector } from "react-redux";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import Table from "./Table";
import Filter from "./Filters";

import { activeTabSelector } from "modules/margin-contract-watch/selectors";
import { MARGIN_CONTRACT_WATCH_TABS } from "modules/margin-contract-watch/constants";

const MarginContractWatchContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === MARGIN_CONTRACT_WATCH_TABS.MARGIN_CONTRACT_WATCH && (
        <>
          <Filter />
          <Table />
        </>
      )}
    </Wrap>
  );
};
export default MarginContractWatchContent;
