import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  fetchNetflowData,
  fetchNetflowDataSuccess,
  fetchNetflowDataFailure,
  changeTradingDates,
} from "../redux/netflow";
import { requestFindNetflow } from "core/grpc";
import { MarketNetFlowResponse } from "domain/protoNew/auto_trading_pb";
import { ETradingDateMapNetFlow } from "helper/consts";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";
import {
  currentMarketCodeSelector,
  tradingDatesNetflowSelector,
} from "../redux/selectors";

function* fetchNetflowWorker() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const activeTab: ETradingDateMapNetFlow = yield select(
      tradingDatesNetflowSelector
    );
    const response: MarketNetFlowResponse.AsObject = yield call(
      requestFindNetflow,
      currentMarketCode,
      activeTab
    );
    yield put(
      fetchNetflowDataSuccess({
        response: response,
      })
    );
  } catch (e: any) {
    yield put(fetchNetflowDataFailure(e.message));
  }
}

function* fetchNetflowDataWatcher() {
  yield takeLatest(fetchNetflowData.type, fetchNetflowWorker);
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchNetflowWorker);
}
function* changeTradingDatesWatcher() {
  yield takeLatest(changeTradingDates.type, fetchNetflowWorker);
}

export default function* netflowSaga() {
  yield all([
    fetchNetflowDataWatcher(),
    setCurrentMarketCodeWatcher(),
    changeTradingDatesWatcher(),
  ]);
}
