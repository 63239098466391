import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import SelectTable from "components/commons/SelectTable";
import { OrderBookDerCondDisplay } from "domain/models/OrderBookDer";
import { changeSelectedCondRows } from "modules/stock-order-der/redux/OrderBookDer";
import { enableInfinitySelector } from "modules/stock-order-der/selectors";
import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import OrderBookDerCondRowAction from "./OrderBookDerCondRowAction";
import OrderDertCondDetailModal from "./OrderDertCondDetailModal";
import { StatusContainer, TableContainer, TextTradeType } from "./styles";

interface WidgetContentProps {
  data: OrderBookDerCondDisplay[];
  fetchMoreData: any;
}
function OrderBookDerCondTable({ data, fetchMoreData }: WidgetContentProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enableInfinity = useSelector(enableInfinitySelector);

  const [selectedRows, setSelectedRows] = useState<OrderBookDerCondDisplay[]>(
    []
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState(null);

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      const selectedRowsNew: OrderBookDerCondDisplay[] = selectedRows.filter(
        (item) => item.visibleCheckbox === true
      );
      dispatch(changeSelectedCondRows(selectedRowsNew));
    }
  }, [selectedRows]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.secCd" />
          </TableHeaderCellContent>
        ),
        accessor: "secCd",
        minWidth: 78,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.subAccoNo" />
          </TableHeaderCellContent>
        ),
        accessor: "custNo",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.tradeType" />
          </TableHeaderCellContent>
        ),
        accessor: "tradeTypeDisplay",
        minWidth: 70,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: CellProps<OrderBookDerCondDisplay>) => {
          return (
            <TableCellContent align={"center"}>
              <TextTradeType side={props.value}>
                {props.value && intl.formatMessage({ id: props.value })}
              </TextTradeType>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.extStatus" />
          </TableHeaderCellContent>
        ),
        accessor: "orderStatusDisplay",
        minWidth: 100,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<OrderBookDerCondDisplay>) => {
          const {
            row: { original },
          } = props;
          return (
            <StatusContainer
              status={original.orderStatusColor}
              progress={original.orderStatusPercent}
            >
              <TableCellContent align={"center"}>
                {props.value && intl.formatMessage({ id: props.value })}
              </TableCellContent>
            </StatusContainer>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.orderQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.price" />
          </TableHeaderCellContent>
        ),
        accessor: "orderPriceDisp",
        minWidth: 64,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value && numeral(props.value).format("0,0.0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.condType.title" />
          </TableHeaderCellContent>
        ),
        accessor: "condTypeDisplay",
        minWidth: 84,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value && intl.formatMessage({ id: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.activePrice" />
          </TableHeaderCellContent>
        ),
        accessor: "priceUp",
        minWidth: 85,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<OrderBookDerCondDisplay>) => {
          const priceUp = props?.row?.original?.priceUp;
          const priceDown = props?.row?.original?.priceDown;
          return (
            <TableCellContent align={"flex-end"}>
              {props?.row?.original?.condType === "UO"
                ? numeral(priceUp).format("0,0.0")
                : props?.row?.original?.condType === "DO"
                ? numeral(priceDown).format("0,0.0")
                : "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.cutLostAmp" />
          </TableHeaderCellContent>
        ),
        accessor: "cutLostAmp",
        minWidth: 98,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.limitOffset" />
          </TableHeaderCellContent>
        ),
        accessor: "limitOffset",

        minWidth: 84,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.grossAmp" />
          </TableHeaderCellContent>
        ),
        accessor: "grossAmp",

        minWidth: 84,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.cutLostPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "cutLostPrice",

        minWidth: 84,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBookDer.rangeStop" />
          </TableHeaderCellContent>
        ),
        accessor: "rangeStop",

        minWidth: 84,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.orderNo" />
          </TableHeaderCellContent>
        ),
        accessor: "refNo",
        minWidth: 88,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.createdTime" />
          </TableHeaderCellContent>
        ),
        accessor: "createdTimeDisp",
        minWidth: 130,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),

        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBookDer.channel" />
          </TableHeaderCellContent>
        ),
        accessor: "channelDisplay",
        minWidth: 74,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: CellProps<OrderBookDerCondDisplay>) => {
          return <OrderBookDerCondRowAction data={props.row.original} />;
        },
      },
    ],
    []
  );

  return (
    <TableContainer
      heighFooter={selectedRows && selectedRows.length > 0 ? 50 : 0}
      id="table-infinity-order-book-der"
    >
      <SelectTable
        columns={columns}
        data={data}
        setShowModal={setShowModal}
        setDataRow={setDataRow}
        setSelectedRows={setSelectedRows}
        fetchMoreData={fetchMoreData}
        enableInfinity={enableInfinity}
        scrollableTarget="table-infinity-order-book-der"
      />
      {dataRow && showModal && (
        <OrderDertCondDetailModal
          showModal={showModal}
          setShowModal={setShowModal}
          dataRow={dataRow}
        />
      )}
    </TableContainer>
  );
}

export default React.memo(OrderBookDerCondTable);
