import queryString from "query-string";

export const getCustomer2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomer2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const getCbotApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCbotContract${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const registerApi = `/updateCbotContract`;

export const cancelRegisterApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/deleteCbotContract${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const createOtpApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getOtpAuthenCode${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
