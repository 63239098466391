import { FC } from "react";
import { Tab, Wrap } from "./styles";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "modules/assetsIncurred/selectors";
import { setTab } from "modules/assetsIncurred/redux";
import { ASSETS_TAB } from "modules/assetsIncurred/constants";

const Navigaton: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(selectTab);

  return (
    <Wrap>
      <div className="header-left">
        <div>
          <Tab
            isActive={activeTab === ASSETS_TAB.ASSETS}
            onClick={() => dispatch(setTab(ASSETS_TAB.ASSETS))}
          >
            {intl.formatMessage({
              id: "assets.navigation.assets",
            })}
          </Tab>
        </div>
        <div>
          <Tab
            isActive={activeTab === ASSETS_TAB.ASSETS_INCURRED}
            onClick={() => dispatch(setTab(ASSETS_TAB.ASSETS_INCURRED))}
          >
            {intl.formatMessage({
              id: "assets.navigation.assetsIncurred",
            })}
          </Tab>
        </div>
        <div>
          <Tab
            isActive={activeTab === ASSETS_TAB.ASSETS_SUM}
            onClick={() => dispatch(setTab(ASSETS_TAB.ASSETS_SUM))}
          >
            {intl.formatMessage({
              id: "assets.navigation.assetsSum",
            })}
          </Tab>
        </div>
      </div>
    </Wrap>
  );
};

export default Navigaton;
