import styled from "styled-components/macro";

export const Container = styled.div<{ blur?: boolean }>`
  opacity: ${({ blur }) => (blur ? "0.7" : "1")};
  width: 100%;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.grey70};
  overflow: hidden;
`;

export const Body = styled.div<{ padding?: number }>`
  padding: ${({ padding }) => padding}px;
  background-color: ${({ theme }) => theme.background2};
  min-height: 400px;
  span {
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .table-identification {
    border: 1px solid ${({ theme }) => theme.grey70};
  }
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Avatar = styled.div<{ image: string }>`
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background-color: ${({ theme }) => theme.background12};
  background-image: ${({ image }) => `url(${image})`};
  background-size: contain;
  margin: 16px;

  @media (max-width: 1366px) {
    margin: 12px;
  }
`;

export const ButtonEditInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  .title-edit {
    color: ${({ theme }) => theme.brandPrimary};
  }
  .icon > svg {
    path {
      fill: ${({ theme }) => theme.brandPrimary};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to left,
    ${({ theme }) => theme.borderDivider} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
  margin-bottom: 28px;
  padding-bottom: 24px;
  :last-child {
    background-image: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media (max-width: 1366px) {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const ContentLabel = styled.div`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  color: ${({ theme }) => theme.textcolorWhite};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};

  @media (max-width: 1366px) {
    margin-bottom: 16px;
  }
`;

export const ContentInfo = styled.div``;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ContentCellLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-right: 32px;
`;

export const ContentCellRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-left: 32px;
`;

export const ContentCell = styled.div<{
  isTextRight?: boolean;
  flex?: number;
  isWrap?: boolean;
}>`
  flex: ${({ flex }) => (flex ? flex : 1)};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  color: ${({ theme }) => theme.grey30};
  ${({ isTextRight, theme }) =>
    isTextRight &&
    `
    text-align: right;
    color: ${theme.textcolorWhite};
  `};
  ${({ isWrap }) =>
    !isWrap &&
    `white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`};
`;

export const ButtonEdit = styled.div`
  display: flex;
  cursor: pointer;
  .display-none {
    svg {
      path {
        fill: ${({ theme }) => theme.grey70};
      }
    }
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.background3};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 0;
`;

export const FullName = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  font-size: ${({ theme }) => theme.typeFaces.title.size};
  font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
`;

export const CustomerNo = styled.div`
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  .title {
    color: ${({ theme }) => theme.grey30};
  }
  .customerNo {
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const BodyContent = styled.div`
  margin-top: 24px;

  @media (max-width: 1366px) {
    margin-top: 20px;
  }
`;

export const PopoverContainer = styled.div`
  z-index: 1050;
  padding: 8px 12px;
  background-color: rgba(31, 45, 63, 0.9);
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  border-radius: 4px;
  margin-left: 8px;
`;

export const PopoverCell = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  color: ${({ theme }) => theme.textcolorWhite};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;

export const TabItem = styled.div<{ isActive: boolean }>`
  flex: 1;
  padding: 7px;
  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.textcolorWhite};
    background-color: ${theme.grey100};
    box-shadow: inset 0px -3px 0px ${theme.solidGrey};
  `};
`;

export const TabHeader = styled.div`
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;
  align-items: center;
`;

export const TabHeaderItem = styled.div<{
  isActive?: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.cardHeader.height};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  cursor: pointer;
  user-select: none;

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.textcolorWhite};
  `}
`;
