import queryString from "query-string";
import { ISearchStatisticOndayParams } from "./types";

export const searchStatisticOndayApi = (
  params: ISearchStatisticOndayParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return `/statisticByQtyAndAmt${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
