import styled from "styled-components/macro";
export const BalanceInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  div {
    color: ${({ theme }) => theme.grey20};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }
  .text-white-neutral1 {
    color: ${({ theme }) => theme.grey0};
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;
export const Container = styled.div`
  flex: 1 1 auto !important;
`;
