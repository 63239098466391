import {
  Body,
  Container,
  Title,
  ButtonAddAccount,
  TitleButton,
  Icon,
  Row,
  Footer,
  Label,
  Value,
  Header,
  Wrapper,
  ButtonExit,
  IconWrapper,
  Content,
} from "./styles";
import { Modal } from "react-bootstrap";
import { useRef, useState } from "react";
import { ReactComponent as IconPlusFill } from "assets/image/svg/ic_plus_fill.svg";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  requestChangeVisibleModalAddAccount,
  updateTransferContractBank,
  clearStatusTransferContractBank,
  getListBeneficiary,
} from "modules/auth/redux/tradingAccount";
import {
  Select,
  Input,
  Button,
  Spacer,
  OTPByBusinessCd,
  InputTextarea,
} from "components/commons";
import { removeAccents } from "helper/removeAccents";
import { IUpdateTransferContractBankParams } from "core/http/apis/account/types";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { useEffect } from "react";
import { toast } from "components/commons/Toast";
import {
  accountInfoSelector,
  visibleModalAddAccountSelector,
  loadingAddSelector,
  authenTypeSelector,
  statusAddAccSelector,
  errorAddAccSelector,
} from "modules/auth/selectors";
import {
  getlistBank,
  getlistBranch,
  getlistLocation,
} from "core/http/apis/transfer-internal";
import storage from "helper/storage";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { EBusinessCd } from "helper/consts";

interface ModalAddAccountBeneficiaryProps {
  hasButton?: boolean;
}

interface Options {
  label: string;
  value: string;
}

function ModalAddAccountBeneficiary({
  hasButton = true,
}: ModalAddAccountBeneficiaryProps) {
  const dispatch = useDispatch();

  const intl = useIntl();
  const accountInfo = useSelector(accountInfoSelector);

  const visibleModalAddAccount = useSelector(visibleModalAddAccountSelector);
  const statusAddAcc = useSelector(statusAddAccSelector);
  const errorAddAcc = useSelector(errorAddAccSelector);
  const loadingAdd = useSelector(loadingAddSelector);
  const { currentUser } = useSelector(accountInfoSelector);
  const authenType = useSelector(authenTypeSelector);

  const [optionBank, setOptionBank] = useState([]);
  const [optionLocation, setOptionLocation] = useState([]);
  const [optionBranch, setOptionBranch] = useState<Options[]>([]);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);

  const [endSlice, setendSlice] = useState(30);
  const [error, setError] = useState<string | undefined>("");

  const [form, setForm] = useState<any>({
    custCd: storage.getMasterAccount(),
    bankCd: null,
    bankName: "",
    bankLocation: null,
    accoName: currentUser?.displayName,
    accoNumber: 0,
    isOwner: 1,
    srcChannel: 2,
    address: "",
    otpCode: null,
  });

  const changeForm = (value: any, key: any) => {
    if (key === "bankCd" || key === "bankLocation") {
      setForm({
        ...form,
        bankName: "",
        [key]: value,
      });
    } else {
      setForm({
        ...form,
        [key]: value,
      });
    }
  };

  const refInput = useRef<HTMLInputElement>(null);

  const getlistBankApi = async () => {
    try {
      const res: any = await getlistBank();
      if (res?.statusCode === 0) {
        const data = res?.data?.map((e: any) => ({
          label: e?.bankName,
          value: e?.bankCd,
        }));
        setOptionBank(data);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      toast(error?.code, "error", error?.description, true);
    }
  };

  const getlistLocationApi = async () => {
    try {
      const res: any = await getlistLocation();

      if (res?.statusCode === 0) {
        const datas = res?.data?.map((e: any) => ({
          label: e?.codeValue1,
          value: e?.code,
        }));
        setOptionLocation(datas);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      toast(error?.code, "error", error?.description, true);
    }
  };

  const getlistBranchApi = async (bankCd?: string, bankLocation?: string) => {
    try {
      const res: any = await getlistBranch({
        bankCd: bankCd ? bankCd : form?.bankCd,
        locationCd: bankLocation ? bankLocation : form?.bankLocation,
      });
      if (res?.statusCode === 0) {
        const datas = await res?.data?.map((e: any) => ({
          label: e?.bankBranchName,
          value: e?.bankCd,
        }));
        setOptionBranch(datas);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      toast(error?.code, "error", error?.description, true);
    }
  };

  useEffect(() => {
    if (visibleModalAddAccount) {
      getlistBankApi();
      getlistLocationApi();
      getlistBranchApi();
    }
  }, [visibleModalAddAccount]);

  const toggleModalAddAccount = () =>
    dispatch(requestChangeVisibleModalAddAccount(!visibleModalAddAccount));

  const closeModalAddAccount = () => {
    setError("");
    toggleModalAddAccount();
    setForm({
      custCd: storage.getMasterAccount(),
      bankCd: null,
      bankName: "",
      bankLocation: null,
      accoName: currentUser?.displayName,
      accoNumber: 0,
      isOwner: 1,
      srcChannel: 2,
      address: "",
      otpCode: null,
    });
  };

  const bankAccountName = accountInfo?.currentUser?.displayName || "---";
  const bankAccountNameConvert = removeAccents(bankAccountName);
  const bankAccountNameUppercase = bankAccountNameConvert.toUpperCase();

  const checkError = () => {
    const value = Object.keys(form).find(
      (key: any) => form[key] === "" || form[key] === 0 || form[key] === null
    );
    if (value !== "otpCode") {
      setError(value);
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const submitAddAccount = () => {
    const error = checkError();
    if (error) {
      setVisibleModalOTP(true);
    }
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitAdvance = (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    const params: IUpdateTransferContractBankParams = {
      ...form,
      otpCode: response.otp,
      otpType: response?.otpTypeSubmit!,
    };

    dispatch(updateTransferContractBank({ params: params }));
  };

  useEffect(() => {
    if (statusAddAcc === "SUCCESSFUL") {
      toast("auth.tradingAccount.registerOtpTypeSuccess", "success");
      dispatch(clearStatusTransferContractBank());
      dispatch(getListBeneficiary());
      toggleModalAddAccount();
    }

    if (statusAddAcc === "FAILURE") {
      if (!errorAddAcc?.code && !errorAddAcc?.description) {
        toast("auth.tradingAccount.registerOtpTypeFailure", "error");
      } else {
        toast(errorAddAcc?.code, "error", errorAddAcc?.description, true);
      }
      dispatch(clearStatusTransferContractBank());
    }
  }, [statusAddAcc, errorAddAcc]);

  const getBankNameSelect = () => {
    const bankName = optionBranch.find((item) => item.label === form.bankName);

    return bankName ? bankName : null;
  };

  return (
    <>
      {hasButton && (
        <ButtonAddAccount onClick={toggleModalAddAccount}>
          <Icon>
            <IconPlusFill />
          </Icon>
          <TitleButton>
            <FormattedMessage id="auth.tradingAccount.addAccountBeneficiary" />
          </TitleButton>
        </ButtonAddAccount>
      )}

      <Modal
        show={visibleModalAddAccount}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-500"
      >
        <Container>
          <Header>
            <Title>
              {intl.formatMessage({
                id: "auth.tradingAccount.addAccount",
              })}
            </Title>
            <Wrapper>
              <ButtonExit onClick={closeModalAddAccount}>
                <IconWrapper>
                  <IconExit />
                </IconWrapper>
              </ButtonExit>
            </Wrapper>
          </Header>
          <Body>
            <Row>
              <Label>
                <FormattedMessage id="auth.tradingAccount.masterAccountName" />
              </Label>
              <Value>{bankAccountNameUppercase}</Value>
            </Row>
            <Row>
              <Label>
                <FormattedMessage id="auth.tradingAccount.bankAccountCode" />
                <span className="text-danger"> *</span>
              </Label>
              <Input
                className="input"
                innerRef={refInput}
                onChange={(e) => changeForm(e.target.value, "accoNumber")}
                placeholder={intl.formatMessage({
                  id: "auth.tradingAccount.bankAccountCodeInput",
                })}
              />
            </Row>
            {error === "accoNumber" && (
              <span className="text-error">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyAccoNumber",
                })}
              </span>
            )}
            <Row>
              <Label>
                <FormattedMessage id="auth.tradingAccount.bankName" />
                <span className="text-danger"> *</span>
              </Label>
              <Content>
                <Select
                  options={optionBank}
                  placeholder={intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.inputBankCd",
                  })}
                  onChange={(e) => {
                    changeForm(e?.value, "bankCd");
                    getlistBranchApi(e?.value);
                  }}
                />
                {error === "bankCd" && (
                  <span className="text-error">
                    {intl.formatMessage({
                      id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                    })}
                  </span>
                )}
              </Content>
            </Row>
            <Row>
              <Label>
                <FormattedMessage id="auth.tradingAccount.bankProvince" />
                <span className="text-danger"> *</span>
              </Label>
              <Content>
                <Select
                  options={optionLocation}
                  placeholder={intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.inputBankLocation",
                  })}
                  onChange={(e) => {
                    changeForm(e?.value, "bankLocation");
                    getlistBranchApi(undefined, e?.value);
                  }}
                />
                {error === "bankLocation" && (
                  <span className="text-error">
                    {intl.formatMessage({
                      id: "transferInternal.historyTransferBank.modalAddAcount.emptyCountry",
                    })}
                  </span>
                )}
              </Content>
            </Row>
            <Row>
              <Label>
                <FormattedMessage id="auth.tradingAccount.bankBranch" />
                <span className="text-danger"> *</span>
              </Label>
              <Content onWheel={() => setendSlice(endSlice + 10)}>
                <Select
                  options={
                    !!optionBranch ? optionBranch.slice(0, endSlice) : []
                  }
                  placeholder={intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.inputBankName",
                  })}
                  value={getBankNameSelect()}
                  onChange={(e) => {
                    // changeForm(e?.value, "address");
                    changeForm(e?.label, "bankName");
                  }}
                />
                {error === "bankName" && (
                  <span className="text-error">
                    {intl.formatMessage({
                      id: "transferInternal.historyTransferBank.modalAddAcount.emptyBranch",
                    })}
                  </span>
                )}
              </Content>
            </Row>

            <Row>
              <Label>
                <FormattedMessage id="auth.tradingAccount.address" />
                <span className="text-danger"> *</span>
              </Label>
              <Content onWheel={() => setendSlice(endSlice + 10)}>
                <InputTextarea
                  textArea
                  placeholder={intl.formatMessage({
                    id: "auth.tradingAccount.inputAddress",
                  })}
                  onChange={(e) => changeForm(e.target.value, "address")}
                />
                {error === "address" && (
                  <span className="text-error">
                    {intl.formatMessage({
                      id: "transferInternal.historyTransferBank.modalAddAcount.emptyAddress",
                    })}
                  </span>
                )}
              </Content>
            </Row>
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalAddAccount}
            >
              <FormattedMessage id="auth.tradingAccount.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={submitAddAccount}
              className="flex-1"
              fill
              color="gradientaAccept"
              loading={loadingAdd}
              disabled={loadingAdd}
            >
              <FormattedMessage id="auth.tradingAccount.apply" />
            </Button>
          </Footer>
        </Container>
      </Modal>
      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.TRANSFER}
          otpEverryTime={authenType.oneTimeAuth === 0}
        />
      )}
    </>
  );
}

export default ModalAddAccountBeneficiary;
