import { FC } from "react";
import { useSelector } from "react-redux";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import Table from "./Table";
import Filter from "./Filters";

import { activeTabSelector } from "modules/mrg-contract-debit/selectors";
import { MRG_CONTRACT_DEBIT_TABS } from "modules/mrg-contract-debit/constants";

const MrgContractDebitContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === MRG_CONTRACT_DEBIT_TABS.MRG_CONTRACT_DEBIT && (
        <>
          <Filter />
          <Table />
        </>
      )}
    </Wrap>
  );
};
export default MrgContractDebitContent;
