import { memo } from "react";
import { ReactComponent as ArrowUp } from "assets/image/svg/arrowUp.svg";
import { ReactComponent as ArrowDown } from "assets/image/svg/arrowDown.svg";
import { ReactComponent as ArrowUpViolet } from "assets/image/svg/arrowUpViolet.svg";
import { ReactComponent as ArrowDownBlue } from "assets/image/svg/arrowDownBlue.svg";
import { ReactComponent as ArrowHorizontal } from "assets/image/svg/arrowHorizontal.svg";
import {
  INDEX_COLOR_CODEMap,
  INDEX_COLOR_CODE,
  STOCK_COLOR_CODEMap,
  STOCK_COLOR_CODE,
} from "domain/protoNew/auto_trading_pb";

interface Props {
  color: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}

function GetArrow({ color }: Props) {
  switch (color) {
    case STOCK_COLOR_CODE.CEILING_PRICE:
      return <ArrowUpViolet />;
    case STOCK_COLOR_CODE.UP_PRICE:
      return <ArrowUp />;
    case STOCK_COLOR_CODE.DOWN_PRICE:
      return <ArrowDown />;
    case STOCK_COLOR_CODE.FLOOR_PRICE:
      return <ArrowDownBlue />;
    default:
      return <ArrowHorizontal />;
  }
}

interface PropsIndex {
  color: INDEX_COLOR_CODEMap[keyof INDEX_COLOR_CODEMap];
}

export function GetArrowIndex({ color }: PropsIndex) {
  switch (color) {
    case INDEX_COLOR_CODE.UP_INDEX:
      return <ArrowUp />;
    case INDEX_COLOR_CODE.DOWN_INDEX:
      return <ArrowDown />;
    default:
      return <ArrowHorizontal />;
  }
}
export default memo(GetArrow);
