/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";
import {
  searchAdvanceRequest,
  deletePayAdvanceRequest,
  searchAdvanceSuccess,
  updateCurrentPage,
} from "modules/advance/redux";
import {
  filterAdvanceSelector,
  getCurrentPage,
} from "modules/advance/selectors";
import { IFindAdvanceParams } from "core/http/apis/advance/types";
import {
  listAdvanceSelector,
  statusDeleteSelector,
} from "modules/advance/selectors";
import numeral from "numeral";
import { memo, useMemo, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ButtonDelete,
} from "./styles";

import { IAdvanceResponse, PayAdvance } from "domain/models/Advance";
import moment from "moment";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";
import { getListAdvance } from "core/http/apis/advance";
import { toast } from "components/commons/Toast";
import { SrcChannel } from "helper/consts";

function Table() {
  const limitPage = 30;
  const intl = useIntl();
  const dispatch = useDispatch();
  const { subAccoNo, fromDate, toDate } = useSelector(filterAdvanceSelector);
  const currentPage = useSelector(getCurrentPage);

  const themeType = useSelector(themeTypeSelector);
  const data = useSelector(listAdvanceSelector);
  const statusDelete = useSelector(statusDeleteSelector);

  const [enableInfinity, setEnableInfinity] = useState(true);
  const getDataApi = (currentpage: number) => {
    const submitData: IFindAdvanceParams = {
      currentDate: false,
      bankBranchCd: "",
      status: -1,
      srcChanel: SrcChannel.ALL,
      goalType: 0,
      subAccoNo: subAccoNo,
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: currentpage,
      rowPerPage: limitPage,
    };
    dispatch(searchAdvanceRequest(submitData));
  };

  useEffect(() => {
    if (statusDelete) {
      getDataApi(currentPage);
    }
  }, [statusDelete]);

  const flexStart = "flex-start";
  const center = "center";
  const flexEnd = "flex-end";
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [itemDeleteSelected, setItemDeleteSelected] = useState<
    PayAdvance | undefined
  >(undefined);
  function getAdvanceStatus(value: string) {
    if (value === "0")
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="advance.table.status.reject" />
        </TableCellContent>
      );
    if (value === "1")
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="advance.table.status.success" />
        </TableCellContent>
      );
    if (value === "2")
      return (
        <TableCellContent color={themes[themeType].solidGrey}>
          <FormattedMessage id="advance.table.status.collected" />
        </TableCellContent>
      );
    if (value === "3")
      return (
        <TableCellContent align="center" color={themes[themeType].solidGrey}>
          <FormattedMessage id="advance.table.status.end" />
        </TableCellContent>
      );
    if (value === "4")
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="advance.table.status.debtCollect" />
        </TableCellContent>
      );
    if (value === "9")
      return (
        <TableCellContent color={themes[themeType].solidRed}>
          <FormattedMessage id="advance.table.status.cancel" />
        </TableCellContent>
      );
    return value;
  }
  const clickDeleteHandler = (item: PayAdvance) => {
    setItemDeleteSelected(item);
    setVisibleModalConfirm(true);
  };
  const setConfirm = () => {
    dispatch(
      deletePayAdvanceRequest({
        alloDate: itemDeleteSelected?.alloDate ?? 0,
        refNo: itemDeleteSelected?.refNo ?? 0,

        lastUpdateTime: itemDeleteSelected?.lastUpdateTime ?? "",
        actionMode: "3",
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advance.table.advanceDate" />
          </HeaderCell>
        ),
        accessor: "contractDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advance.table.soldDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advance.table.paymentDate" />
          </HeaderCell>
        ),
        accessor: "paymentDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="advance.table.amount" />
          </HeaderCell>
        ),
        accessor: "advanceAmt",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="advance.table.advanceFree" />
          </HeaderCell>
        ),
        accessor: "manageFeeAmt",
        minWidth: 80,

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value + props?.row?.original?.interestAmt).format(
                "0,0"
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="advance.table.status.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,

        Cell: (props: any) => {
          return <>{getAdvanceStatus(props.value.toString())}</>;
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="advance.table.content" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {props.value}{" "}
            </TableCellContent>
          );
        },
      },

      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          const status = currentRow?.status;
          return (
            <div className="btn_action_hover">
              {status.toString() === "0" && (
                <ButtonDelete
                  className="delete"
                  onClick={() => clickDeleteHandler(currentRow)}
                >
                  <FormattedMessage id="advance.table.cancel" />
                </ButtonDelete>
              )}
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = async () => {
    if (data.length < (currentPage + 1) * limitPage) {
      setEnableInfinity(false);
      return;
    }
    const submitData: IFindAdvanceParams = {
      currentDate: false,
      bankBranchCd: "",
      status: -1,
      srcChanel: SrcChannel.ALL,
      goalType: 0,
      subAccoNo: subAccoNo,
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: currentPage + 1,
      rowPerPage: limitPage,
    };
    const res: IAdvanceResponse = await getListAdvance(submitData);
    if (res?.statusCode === 0) {
      const dataTb: PayAdvance[] = data.concat(res.data);
      dispatch(searchAdvanceSuccess({ data: dataTb }));
      dispatch(updateCurrentPage(currentPage + 1));
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };
  useEffect(() => {
    if (currentPage === 0) {
      setEnableInfinity(true);
    }
  }, [currentPage]);

  return (
    <Container id="advanceTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={enableInfinity}
        scrollableTarget={"advanceTable"}
        isHasFooter={false}
      />

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage(
          { id: "common.cancelContent" },
          { number: itemDeleteSelected?.refNo }
        )}
      />
    </Container>
  );
}

export default memo(Table);
