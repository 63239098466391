import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { getMoneyStatmentList } from "modules/account-statement/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Container, HeaderCell, TableCellContent } from "./styles";

function MoneyStatementTable() {
  const data = useSelector(getMoneyStatmentList);

  const dataNew = data?.filter((item: any) => item?.secCd === "");

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="accountStatement.businessDate" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "businessDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="accountStatement.creditAmt" />
          </HeaderCell>
        ),
        minWidth: 320,
        accessor: "creditAmt",
        Cell: (props: any) => {
          const row = props?.row?.original?.creditAmt;
          return (
            <TableCellContent align={"right"}>
              {row === 0 ? "-" : numeral(row).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="accountStatement.debitAmt" />
          </HeaderCell>
        ),
        minWidth: 320,
        accessor: "debitAmt",
        Cell: (props: any) => {
          const row = props?.row?.original?.debitAmt;
          return (
            <TableCellContent align={"right"}>
              {row === 0 ? "-" : numeral(Math.abs(row)).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="accountStatement.currentAmt" />
          </HeaderCell>
        ),
        minWidth: 320,
        accessor: "currentAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="accountStatement.remarks" />
          </HeaderCell>
        ),
        minWidth: 808,
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="transferLocalHisTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? dataNew : []}
        enableInfinity={true}
        scrollableTarget={"transferLocalHisTable"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(MoneyStatementTable);
