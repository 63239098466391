import {
  Button,
  DatePicker,
  Select,
  NumberInput,
  ModalConfirm,
} from "components/commons";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DetailCol, DetailWrapper, DetailColFlexEnd } from "./styles";
import TextArea from "components/commons/Input/textArea";
import {
  BuyButton,
  SellButton,
  ButtonItem,
  LabelBuySell,
} from "components/widgets/generals/PlaceOrder/styles";
import InputRow from "components/commons/FormStyle/InputRow";
import {
  changeUpdateDbondAdvDataList,
  getFindCbotBondPriceEstStart,
  resetData,
  resetUpdateDbondAdvDataList,
  updateDbondAdvDataListStart,
} from "modules/placeOrderBondAdv/redux";
import {
  listCbotBondPriceEstItemSelector,
  updateDbondAdvDataListSelector,
} from "modules/placeOrderBondAdv/selectors";
import { findCbotBondPriceEstItem } from "core/http/apis/placeOrderBondAdv/types";
import { TradeType } from "../RequestFilters";
import { OptionSelectType } from "components/commons/Select";
import numeral from "numeral";
import { toast } from "components/commons/Toast";
import moment from "moment";

function RequestDetail() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const listCbotBondPriceEstItem = useSelector(
    listCbotBondPriceEstItemSelector
  );
  const {
    tradeType,
    bondCd,
    ordPrice,
    ordQty,
    minQty,
    expDate,
    contactInfo,
    remarks,
  } = useSelector(updateDbondAdvDataListSelector);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  useEffect(() => {
    dispatch(getFindCbotBondPriceEstStart());
    return () => {
      dispatch(resetData());
    };
  }, []);

  const listCbotBondPriceEstOptions = useMemo(() => {
    let list = [];
    list = listCbotBondPriceEstItem?.map((item: findCbotBondPriceEstItem) => ({
      label: item.bondCd,
      value: item.bondCd,
    }));
    return list;
  }, [listCbotBondPriceEstItem.length]);

  const changeRemarksHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(changeUpdateDbondAdvDataList({ remarks: e?.target?.value ?? "" }));
  };
  const clickResetHandler = () => {
    dispatch(resetUpdateDbondAdvDataList());
  };
  const getValueNumber = (value: string) => {
    return numeral(value).value() ?? 0;
  };
  const clickSubmit = () => {
    if (!bondCd) {
      return toast("placeOrderBondAdv.bondCdNull", "error");
    }
    if (!ordPrice) {
      return toast("placeOrderBondAdv.ordPriceNull", "error");
    }
    if (!ordQty) {
      return toast("placeOrderBondAdv.ordQtyNull", "error");
    }
    if (!minQty) {
      return toast("placeOrderBondAdv.minQtyNull", "error");
    }
    if (getValueNumber(minQty) >= getValueNumber(ordQty)) {
      return toast("placeOrderBondAdv.errQty", "error");
    }
    if (moment(expDate).isBefore(moment(new Date()))) {
      return toast("placeOrderBondAdv.errExpDate", "error");
    }
    if (!contactInfo.trim()) {
      return toast("placeOrderBondAdv.contactInfoNull", "error");
    }
    setVisibleModalConfirm(true);
  };

  const setConfirm = () => {
    dispatch(updateDbondAdvDataListStart());
  };

  const changebondCd = (e: OptionSelectType | null) => {
    dispatch(changeUpdateDbondAdvDataList({ bondCd: e?.value }));
  };

  return (
    <DetailWrapper>
      <div className="d-flex">
        <ButtonItem
          className="mr-1"
          onClick={() => {
            dispatch(
              changeUpdateDbondAdvDataList({ tradeType: TradeType.BUY })
            );
          }}
        >
          <BuyButton isActive={tradeType === TradeType.BUY}></BuyButton>
          <LabelBuySell isActive={tradeType === TradeType.BUY}>
            <FormattedMessage id="common.buyUp" />
          </LabelBuySell>
        </ButtonItem>
        <ButtonItem
          onClick={() => {
            dispatch(
              changeUpdateDbondAdvDataList({ tradeType: TradeType.SELL })
            );
          }}
        >
          <SellButton isActive={tradeType === TradeType.SELL}></SellButton>
          <LabelBuySell isActive={tradeType === TradeType.SELL}>
            <FormattedMessage id="common.sellUp" />
          </LabelBuySell>
        </ButtonItem>
      </div>

      <DetailCol>
        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.bondCd",
          })}
          isRequired
        >
          <Select
            className="flex-fill"
            value={
              listCbotBondPriceEstOptions?.filter(
                (item) => item.value === bondCd
              )?.[0] || null
            }
            options={listCbotBondPriceEstOptions}
            onChange={changebondCd}
          />
        </InputRow>

        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.ordPrice",
          })}
          isRequired
        >
          <NumberInput
            value={ordPrice}
            placeholder={intl.formatMessage({
              id: "placeOrderBondAdv.ordPrice",
            })}
            fullWidth
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={0}
            onChange={(e) =>
              dispatch(
                changeUpdateDbondAdvDataList({ ordPrice: e?.target?.value })
              )
            }
          />
        </InputRow>

        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.ordQty",
          })}
          isRequired
        >
          <NumberInput
            value={ordQty}
            placeholder={intl.formatMessage({
              id: "placeOrderBondAdv.ordQty",
            })}
            fullWidth
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={0}
            onChange={(e) =>
              dispatch(
                changeUpdateDbondAdvDataList({ ordQty: e?.target?.value })
              )
            }
          />
        </InputRow>

        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.minQty",
          })}
          isRequired
        >
          <NumberInput
            value={minQty}
            placeholder={intl.formatMessage({
              id: "placeOrderBondAdv.minQty",
            })}
            fullWidth
            allowNegative={false}
            thousandSeparator={true}
            decimalScale={0}
            onChange={(e) =>
              dispatch(
                changeUpdateDbondAdvDataList({ minQty: e?.target?.value })
              )
            }
          />
        </InputRow>

        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.expDate",
          })}
          isRequired
        >
          <DatePicker
            selected={expDate as Date}
            fullWidth
            onChange={(e) =>
              dispatch(changeUpdateDbondAdvDataList({ expDate: e }))
            }
          />
        </InputRow>

        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.contactInfo",
          })}
          isRequired
        >
          <NumberInput
            value={contactInfo}
            placeholder={intl.formatMessage({
              id: "placeOrderBondAdv.contactInfo",
            })}
            fullWidth
            allowNegative={false}
            decimalScale={0}
            onChange={(e) =>
              dispatch(
                changeUpdateDbondAdvDataList({ contactInfo: e?.target?.value })
              )
            }
          />
        </InputRow>

        <InputRow
          title={intl.formatMessage({
            id: "placeOrderBondAdv.remarks",
          })}
        >
          <TextArea
            textArea={true}
            value={remarks}
            onChange={changeRemarksHandler}
            placeholder={intl.formatMessage({
              id: "holdUnholdBankAccount.message.enterNote",
            })}
          />
        </InputRow>
      </DetailCol>
      <DetailColFlexEnd>
        <Button color="accept" className="w-100" onClick={clickResetHandler}>
          <FormattedMessage id="placeOrderBondAdv.reset" />
        </Button>

        <Button
          className="w-100"
          color="success"
          onClick={clickSubmit}
          fill={true}
        >
          <FormattedMessage id="placeOrderBondAdv.submit" />
        </Button>
      </DetailColFlexEnd>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({ id: "placeOrderBondAdv.confirmCreate" })}
      />
    </DetailWrapper>
  );
}

export default RequestDetail;
