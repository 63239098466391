import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  findCbotBondPriceEstItem,
  findDbondAdvItem,
  findDbondAdvRequest,
  updateDbondAdvDataList,
} from "core/http/apis/placeOrderBondAdv/types";
import {
  StatusType,
  TradeType,
} from "./pages/components/AdvanceContent/RequestFilters";

export interface ReduxData {
  listCbotBondPriceEstItem: findCbotBondPriceEstItem[];
  searchParams: findDbondAdvRequest;
  loadingSearch: boolean;
  listDbondAdvItem: findDbondAdvItem[];
  updateDbondAdvDataList: updateDbondAdvDataList;
  loadingSubmit: boolean;
  idCancel: number;
}

const initialState: ReduxData = {
  listCbotBondPriceEstItem: [],
  searchParams: {
    tradeType: TradeType.ALL,
    bondCd: "",
    status: StatusType.ALL,
  },
  loadingSearch: false,
  listDbondAdvItem: [],
  updateDbondAdvDataList: {
    bondCd: "",
    tradeType: TradeType.BUY,
    tradeDate: "",
    ordPrice: "",
    ordQty: "",
    minQty: "",
    expDate: new Date(),
    contactInfo: "",
    remarks: "",
    action: "",
  },
  loadingSubmit: false,
  idCancel: 0,
};

const placeOrderBondAdvSlice = createSlice({
  name: "placeOrderBondAdvSlice",
  initialState,
  reducers: {
    resetData: () => {
      return initialState;
    },
    getFindCbotBondPriceEstStart: () => {},
    getFindCbotBondPriceEstSuccess: (
      state,
      action: PayloadAction<findCbotBondPriceEstItem[]>
    ) => {
      state.listCbotBondPriceEstItem = action.payload;
    },
    changeSearchParams: (state, action: PayloadAction<object>) => {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    searchRequest: (state) => {
      state.loadingSearch = true;
    },
    getListDbondAdvItemSuccess: (
      state,
      action: PayloadAction<findDbondAdvItem[]>
    ) => {
      state.loadingSearch = false;
      state.listDbondAdvItem = action.payload;
    },
    getListDbondAdvItemFail: (state) => {
      state.loadingSearch = false;
    },
    changeUpdateDbondAdvDataList: (state, action: PayloadAction<object>) => {
      state.updateDbondAdvDataList = {
        ...state.updateDbondAdvDataList,
        ...action.payload,
      };
    },
    resetUpdateDbondAdvDataList: (state) => {
      state.updateDbondAdvDataList = initialState.updateDbondAdvDataList;
    },
    updateDbondAdvDataListStart: (state) => {
      state.loadingSubmit = true;
    },
    updateDbondAdvDataListDone: (state) => {
      state.loadingSubmit = false;
    },
    updateIdCancel: (state, action: PayloadAction<number>) => {
      state.idCancel = action.payload;
    },
    cancelDbondAdvDataListStart: () => {},
  },
});

export const {
  resetData,
  getFindCbotBondPriceEstStart,
  getFindCbotBondPriceEstSuccess,
  changeSearchParams,
  searchRequest,
  getListDbondAdvItemSuccess,
  getListDbondAdvItemFail,
  changeUpdateDbondAdvDataList,
  resetUpdateDbondAdvDataList,
  updateDbondAdvDataListStart,
  updateDbondAdvDataListDone,
  updateIdCancel,
  cancelDbondAdvDataListStart,
} = placeOrderBondAdvSlice.actions;

export default placeOrderBondAdvSlice.reducer;
