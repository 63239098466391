import React, { useEffect } from "react";
import {
  WidgetContainer,
  HeaderSection,
  ChartSection,
  HorizontalSection,
  TotalTickerContainer,
  TotalTicker,
} from "./styles";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { AdIndexState } from "modules/market/redux/adIndex";
import { fetchMarketDepth } from "modules/market/redux/adIndex";
import AdIndexChart from "./AdIndexChart";
import { FormattedMessage } from "react-intl";
import { useInterval } from "helper/cusHooks";
import numeral from "numeral";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";
import { useEffectOnce } from "react-use";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { SystemState } from "domain/protoNew/auto_trading_pb";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { StyledTooltip } from "../MarketWatch/styles";

export type AdIndexWidgetProps = {};

const adIndexSelector = (state: RootState) => state.market.adIndex;

const chartDataSelector = createSelector(
  adIndexSelector,
  (state: AdIndexState) => ({
    chart: state.data,
    totalDecline: state.totalDecline,
    totalAdvance: state.totalAdvances,
    totalNoChange: state.totalNoChange,
    totalTickers: state.totalTickers,
  })
);

const AdIndexWidget: React.FC<AdIndexWidgetProps> = (
  props: AdIndexWidgetProps
) => {
  const dispatch = useDispatch();
  const chartData = useSelector(chartDataSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);
  const themeType = useSelector(themeTypeSelector);

  useEffectOnce(() => {
    dispatch(fetchMarketDepth());

    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(fetchMarketDepth());
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  });

  useInterval(() => {
    dispatch(fetchMarketDepth());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  const total =
    (chartData.totalAdvance ?? 0) +
    (chartData.totalDecline ?? 0) +
    (chartData.totalNoChange ?? 0);

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      dispatch(fetchMarketDepth());
    }
  }, [isVisibilityPage]);

  function getChartColor(colorCode: number): string {
    switch (colorCode) {
      case -1:
        return themes[themeType].solidRed;
      case 1:
        return themes[themeType].solidGreen;
      default:
        return themes[themeType].solidYellow;
    }
  }

  return (
    <WidgetContainer>
      <HeaderSection>
        <OverlayTrigger
          placement="bottom-start"
          overlay={
            <StyledTooltip id="tooltip">
              <FormattedMessage id="widgets.adIndex.tooltip" />
            </StyledTooltip>
          }
        >
          <div className="headerText">
            <FormattedMessage id="widgets.adIndex.title" />
          </div>
        </OverlayTrigger>

        <div className="sum">
          <FormattedMessage
            id="widgets.adIndex.sum"
            values={{ number: numeral(chartData.totalTickers).format("0,0") }}
          />
        </div>
      </HeaderSection>
      <ChartSection>
        <AdIndexChart
          values={chartData.chart.values}
          colors={chartData.chart.colors.map((item) => getChartColor(item))}
          labels={chartData.chart.labels}
        />
      </ChartSection>
      <HorizontalSection>
        <TotalTickerContainer>
          <TotalTicker
            value={chartData.totalDecline}
            total={total}
            color={themes[themeType].solidRed}
          />
          <TotalTicker
            value={chartData.totalNoChange}
            total={total}
            color={themes[themeType].solidYellow}
          />
          <TotalTicker
            value={chartData.totalAdvance}
            total={total}
            color={themes[themeType].solidGreen}
          />
        </TotalTickerContainer>
        <div className="progress-desc">
          <div className="total-decline-desc">
            <FormattedMessage
              id="widgets.adIndex.decline"
              values={{
                number: numeral(chartData.totalDecline).format("0,0"),
              }}
            />
          </div>
          <div className="total-advance-desc">
            <FormattedMessage
              id="widgets.adIndex.advance"
              values={{
                number: numeral(chartData.totalAdvance).format("0,0"),
              }}
            />
          </div>
        </div>
      </HorizontalSection>
    </WidgetContainer>
  );
};

export default AdIndexWidget;
