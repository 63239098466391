import { TrackingApi } from "AppConfig";
import axios from "axios";
import { toast } from "components/commons/Toast";
import { writeNewLogFirebase } from "core/firebase";
import { refreshTokenApi } from "core/http/apis/auth";
import { subscribeIndex, subscribeTickers } from "core/wss";
import LoginInterface from "domain/models/Login";
import { TrackingApiType } from "helper/consts";
import storage from "helper/storage";
import { tradeDateSelector } from "modules/auth/selectors";
import {
  pushLogData,
  pushQueueLogData,
  reconnectWebSocketSuccess,
  resLogData,
  startRefreshToken,
  resetQueueLogData,
} from "modules/system/redux/common";
import moment from "moment";
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  listIndexScribleSelector,
  listTickerScribleSelector,
  logDataSelector,
  queueLogDataSelector,
} from "../selectors";

function* getRefreshTokenSaga(action: ReturnType<typeof startRefreshToken>) {
  try {
    const res: LoginInterface = yield call(refreshTokenApi, action.payload);
    yield storage.setAccessToken(res.access_token);
    yield storage.setTimeAccessToken(
      (parseInt(moment().format("X")) + res.expires_in).toString()
    );
    yield storage.setRefreshToken(res.refresh_token);
    yield storage.setTimeRefreshToken(
      (parseInt(moment().format("X")) + res.refresh_expires_in).toString()
    );
  } catch (error: any) {
    storage.logOut();
  }
}

function* reconnectWebSocketSuccessSaga() {
  try {
    const ListTickerScrible: string[] = yield select(listTickerScribleSelector);
    const ListIndexScrible: string[] = yield select(listIndexScribleSelector);
    yield delay(1000);

    if (ListTickerScrible.length > 0) {
      subscribeTickers(ListTickerScrible.toString().replace(/,/g, ";"), true);
    }
    if (ListIndexScrible.length > 0) {
      subscribeIndex(ListIndexScrible.toString().replace(/,/g, ";"), true);
    }
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function clean(obj: any) {
  for (var propName in obj) {
    if (obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
}

function* pushLogDataSaga(action: ReturnType<typeof pushLogData>) {
  try {
    const logData: resLogData = yield select(logDataSelector);
    let objLog = { ...logData, ...action.payload };
    if (TrackingApi === TrackingApiType.firebase) {
      writeNewLogFirebase(clean(objLog));
    } else {
      const tradeDate: string = yield select(tradeDateSelector);
      const objLogRestFul: resLogData = {
        ...objLog,
        transDate: tradeDate, //chỉ restful
        tableName:
          moment().format("YYYYMMDD") +
          "_" +
          objLog.eventGroup +
          "_" +
          objLog.screen, //chỉ restful
        // id?: string; //chỉ restful };
      };
      yield put(pushQueueLogData(clean(objLogRestFul)));
    }
  } catch (error: any) {}
}

function* sendQueueLogDataSaga() {
  try {
    yield delay(3000);
    const logData: resLogData[] = yield select(queueLogDataSelector);
    yield axios.post("http://10.10.1.69:6001/rest/api/pushListLog", logData);
    yield put(resetQueueLogData());
  } catch (error: any) {}
}

export default function* commonSaga() {
  yield all([
    takeLatest(startRefreshToken.type, getRefreshTokenSaga),
    takeLatest(reconnectWebSocketSuccess.type, reconnectWebSocketSuccessSaga),
    takeEvery(pushLogData.type, pushLogDataSaga),
    takeLatest(pushQueueLogData.type, sendQueueLogDataSaga),
  ]);
}
