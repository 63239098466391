import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Colors } from "helper/consts";
import { Col, Row, TextColor } from "../styles";
import numeral from "numeral";
import { Ticker } from "modules/stock-order/redux/volumeAndTime";

const getEsideName = (esideCode: string) => {
  switch (esideCode) {
    case "2":
      return <FormattedMessage id="widgets.volumeAndTime.esideShorthand.buy" />;
    case "1":
      return (
        <FormattedMessage id="widgets.volumeAndTime.esideShorthand.sell" />
      );
    default:
      return "";
  }
};
const esideColor = (esideCode: string) => {
  switch (esideCode) {
    case "2":
      return "green50";
    case "1":
      return "red50";
    default:
      return "";
  }
};

interface TickerItemProps {
  ticker: Ticker;
}

function TickerItem({ ticker }: TickerItemProps) {
  return (
    <Row>
      <Col className="flex-2">{ticker.time}</Col>
      <Col className="justify-content-end flex-2">
        <TextColor color={Colors[ticker.color]}>
          {numeral(ticker.price).format("0,0.00")}
        </TextColor>
      </Col>
      <Col className="justify-content-end flex-2">
        <TextColor color={Colors[ticker.color]}>
          {numeral(ticker.volume * 10).format("0,0")}
        </TextColor>
      </Col>
      <Col className="justify-content-end flex-1">
        <TextColor color={esideColor(ticker.eside)}>
          {getEsideName(ticker.eside)}
        </TextColor>
      </Col>
    </Row>
  );
}

export default memo(TickerItem) as React.FC<TickerItemProps>;
