import { toast } from "components/commons/Toast";
import {
  createDertRequestCondApi,
  dertCreateOrderApi,
  dertInquiryTradingApi,
  dertTradingGetFeeRateApi,
  getCustomerRestrictionApi,
  getQuotesForDertOrderApi,
  validateDertRequestCondTimeApi,
} from "core/http/apis/orderexec";
import {
  ICreateDertRequestCondResponse,
  IDertCreateOrderResponse,
  IDertInquiryTradingResponse,
  IDertTradingGetFeeRateResponse,
  IGetCustomerRestrictionResponse,
  IGetQuotesForDertOrderResponse,
  IValidateDertRequestCondTimeResponse,
} from "domain/models/OrderBookDer";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  requestCreateOrderDer,
  postSingleOrderDerFailure,
  postSingleOrderDerSuccess,
  getQuotesForDertOrder,
  getQuotesForDertOrderSuccess,
  getQuotesForDertOrderFailed,
  dertInquiryTrading,
  dertInquiryTradingSuccess,
  dertInquiryTradingFailed,
  getCustomerRestrictionDer,
  getCustomerRestrictionDerSuccess,
  getCustomerRestrictionDerFailed,
  getDertTradingGetFeeRate,
  getDertTradingGetFeeRateSuccess,
  getDertTradingGetFeeRateFailed,
  validateDertRequestCondTime,
  validateDertRequestCondTimeSuccess,
  validateDertRequestCondTimeFailed,
  createDertRequestCond,
  createDertRequestCondSuccess,
  createDertRequestCondFailed,
} from "../redux/PlaceOrderDer";

// --- //

function* getQuotesForDertOrderWorker(
  action: ReturnType<typeof getQuotesForDertOrder>
) {
  try {
    const res: IGetQuotesForDertOrderResponse = yield call(
      getQuotesForDertOrderApi,
      action.payload
    );

    if (res && res.statusCode === 0) {
      yield put(getQuotesForDertOrderSuccess(res));
    } else {
      yield put(getQuotesForDertOrderFailed());
      toast("error." + res.errorCode, "error", res.message);
    }
  } catch (error: any) {
    toast(error);
    yield put(getQuotesForDertOrderFailed());
  }
}

function* dertInquiryTradingWorker(
  action: ReturnType<typeof dertInquiryTrading>
) {
  try {
    const res: IDertInquiryTradingResponse = yield call(
      dertInquiryTradingApi,
      action.payload
    );
    if (res && res.statusCode === 0) {
      yield put(dertInquiryTradingSuccess(res));
    } else {
      yield put(dertInquiryTradingFailed());
      toast("error." + res.errorCode, "error", res.message);
    }
  } catch (error: any) {
    toast(error);
    yield put(dertInquiryTradingFailed());
  }
}

function* getCustomerRestrictionWorker(
  action: ReturnType<typeof getCustomerRestrictionDer>
) {
  try {
    const res: IGetCustomerRestrictionResponse = yield call(
      getCustomerRestrictionApi,
      action.payload
    );

    yield put(getCustomerRestrictionDerSuccess(res));
  } catch (error: any) {
    toast(error);
    yield put(getCustomerRestrictionDerFailed(error));
  }
}

function* getDertTradingGetFeeRateWorker(
  action: ReturnType<typeof getDertTradingGetFeeRate>
) {
  try {
    const res: IDertTradingGetFeeRateResponse = yield call(
      dertTradingGetFeeRateApi,
      action.payload
    );

    if (res && res.statusCode === 0) {
      yield put(getDertTradingGetFeeRateSuccess(res));
    } else {
      yield put(getDertTradingGetFeeRateFailed());
      toast("error." + res.errorCode, "error", res.message);
    }
  } catch (error: any) {
    toast(error);
    yield put(getDertTradingGetFeeRateFailed());
  }
}

function* validateDertRequestCondTimeWorker(
  action: ReturnType<typeof validateDertRequestCondTime>
) {
  try {
    const res: IValidateDertRequestCondTimeResponse = yield call(
      validateDertRequestCondTimeApi,
      action.payload
    );

    if (res && res.statusCode === 0) {
      yield put(validateDertRequestCondTimeSuccess(res));
    } else {
      yield put(validateDertRequestCondTimeFailed());
      toast("error." + res.errorCode, "error", res.message);
    }
  } catch (error: any) {
    toast(error);
    yield put(validateDertRequestCondTimeFailed());
  }
}

function* requestCreateOrderWorker(
  action: ReturnType<typeof requestCreateOrderDer>
) {
  try {
    const singleOrder: IDertCreateOrderResponse = yield call(
      dertCreateOrderApi,
      action.payload
    );

    yield put(postSingleOrderDerSuccess(singleOrder));
  } catch (error: any) {
    yield put(postSingleOrderDerFailure(error));
  }
}

function* createDertRequestCondWorker(
  action: ReturnType<typeof createDertRequestCond>
) {
  try {
    const res: ICreateDertRequestCondResponse = yield call(
      createDertRequestCondApi,
      action.payload
    );

    yield put(createDertRequestCondSuccess(res));
  } catch (error: any) {
    yield put(createDertRequestCondFailed(error));
  }
}

// --- //

// function* requestGetTradingSessionAPI(
//   action: ReturnType<typeof requestGetSecuritiesStatus>
// ) {
//   try {
//     if (action.payload) {
//       const res: TradingSession = yield call(
//         getTradingSessionById,
//         action.payload
//       );

//       yield put({ type: getTradingSessionSuccess.type, payload: res });
//     }
//   } catch (error: any) {
//     yield put({ type: getTradingSessionFailed.type, payload: error });
//   }
// }

// function* getLastestCash(action: ReturnType<typeof getPlaceOrderDerCashStart>) {
//   try {
//     const currentAccount: string = yield select(
//       (state: RootState) =>
//         state.stockOrderDer?.placeOrderDer?.data.account.account
//     );
//     if (currentAccount) {
//       const res: Cash = yield call(
//         getLastestCashApi,
//         currentAccount,
//         action.payload
//       );
//       yield put({ type: getPlaceOrderDerCashSuccess.type, payload: res });
//     }
//   } catch (error: any) {
//     yield put({ type: getPlaceOrderDerCashFailed.type, payload: error });
//   }
// }

// function* getLastestPriceAPI(action: ReturnType<typeof getLastestPrice>) {
//   try {
//     const res: PriceInfoLastSale = yield call(
//       getPriceInfoLastSale,
//       action.payload
//     );
//     if (res.toObject().price && res.toObject().price?.value) {
//       yield put({
//         type: getLastestPriceSuccess.type,
//         payload: res.toObject().price?.value,
//       });
//     } else {
//       yield put({
//         type: getLastestPriceSuccess.type,
//         payload: 0,
//       });
//     }
//   } catch (error: any) {
//     yield put({
//       type: getLastestPriceFailed.type,
//       payload: error,
//     });
//   }
// }

// function* watchGetLastestCash() {
//   yield takeLatest(getPlaceOrderDerCashStart, getLastestCash);
// }

// function* watchGetLastestPriceAPI() {
//   yield takeLatest(getLastestPrice, getLastestPriceAPI);
// }

// --- //

function* watchGetQuotesForDertOrder() {
  yield takeLatest(getQuotesForDertOrder, getQuotesForDertOrderWorker);
}

function* watchDertInquiryTrading() {
  yield takeLatest(dertInquiryTrading, dertInquiryTradingWorker);
}

function* watchGetCustomerRestriction() {
  yield takeLatest(getCustomerRestrictionDer, getCustomerRestrictionWorker);
}

function* watchGetDertTradingGetFeeRate() {
  yield takeLatest(getDertTradingGetFeeRate, getDertTradingGetFeeRateWorker);
}

function* watchValidateDertRequestCondTime() {
  yield takeLatest(
    validateDertRequestCondTime,
    validateDertRequestCondTimeWorker
  );
}

function* watchCreateSingleOrder() {
  yield takeLatest(requestCreateOrderDer, requestCreateOrderWorker);
}

function* watchCreateDertRequestCond() {
  yield takeLatest(createDertRequestCond, createDertRequestCondWorker);
}

// --- //

// function* watchGetTradingSession() {
//   yield takeLatest(requestGetTradingSession, requestGetTradingSessionAPI);
// }

export default function* placeOrderDer() {
  yield all([
    watchDertInquiryTrading(),
    watchGetQuotesForDertOrder(),
    watchGetCustomerRestriction(),
    watchGetDertTradingGetFeeRate(),
    watchValidateDertRequestCondTime(),
    watchCreateSingleOrder(),
    watchCreateDertRequestCond(),

    // watchGetTradingSession(),
    // watchGetLastestCash(),
    // watchGetLastestPriceAPI(),
  ]);
}
