import CommonTableInfinity from "components/commons/CommonTableInfinity";
import numeral from "numeral";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Container, HeaderCell, TableCellContent } from "./styles";
import moment from "moment";
import { listDbondAdvItemSelector } from "modules/placeOrderBondAdv/selectors";
import { StatusType, TradeType } from "../RequestFilters";
import {
  updateIdCancel,
  cancelDbondAdvDataListStart,
} from "modules/placeOrderBondAdv/redux";
import { ModalConfirm } from "components/commons";
import { ButtonDelete } from "modules/advance/pages/Advance/components/AdvanceContent/RequestTable/styles";

function Table() {
  const intl = useIntl();
  const listDbondAdvItem = useSelector(listDbondAdvItemSelector);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const dispatch = useDispatch();
  const flexStart = "flex-start";
  const center = "center";
  const flexEnd = "flex-end";

  function getTradeType(value: string) {
    if (value === TradeType.SELL)
      return (
        <TableCellContent align={center} color="textColorRed">
          <FormattedMessage id="placeOrderBondAdv.tradeTypeSell" />
        </TableCellContent>
      );
    if (value === TradeType.BUY)
      return (
        <TableCellContent align={center} color="textColorGreen">
          <FormattedMessage id="placeOrderBondAdv.tradeTypeBuy" />
        </TableCellContent>
      );
    return value;
  }

  function getStatus(value: string) {
    if (value === StatusType.NOTAPPROVED)
      return (
        <TableCellContent align={flexStart} color="solidYellow">
          <FormattedMessage id="placeOrderBondAdv.statusTypeNotApproved" />
        </TableCellContent>
      );
    if (value === StatusType.APPROVED)
      return (
        <TableCellContent align={flexStart} color="solidGreen">
          <FormattedMessage id="placeOrderBondAdv.statusTypeApproved" />
        </TableCellContent>
      );
    if (value === StatusType.DONE)
      return (
        <TableCellContent align={flexStart} color="solidBlue">
          <FormattedMessage id="placeOrderBondAdv.statusTypeDone" />
        </TableCellContent>
      );
    if (value === StatusType.DONE)
      return (
        <TableCellContent align={flexStart} color="solidRed">
          <FormattedMessage id="placeOrderBondAdv.statusTypeCancel" />
        </TableCellContent>
      );
    return value;
  }

  const clickDeleteHandler = (id: number) => {
    dispatch(updateIdCancel(id));
    setVisibleModalConfirm(true);
  };

  const setConfirm = () => {
    dispatch(cancelDbondAdvDataListStart());
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderBondAdv.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderBondAdv.tradeType" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        Cell: (props: any) => {
          return getTradeType(props.value?.toString());
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="placeOrderBondAdv.ordPrice" />
          </HeaderCell>
        ),
        accessor: "ordPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="placeOrderBondAdv.ordQty" />
          </HeaderCell>
        ),
        accessor: "ordQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="placeOrderBondAdv.minQty" />
          </HeaderCell>
        ),
        accessor: "minQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderBondAdv.expDate" />
          </HeaderCell>
        ),
        accessor: "expDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="placeOrderBondAdv.status" />
          </HeaderCell>
        ),
        accessor: "status",
        Cell: (props: any) => {
          return getStatus(props.value?.toString());
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="placeOrderBondAdv.tableContactInfo" />
          </HeaderCell>
        ),
        accessor: "contactInfo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="placeOrderBondAdv.remarks" />
          </HeaderCell>
        ),
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        className: "action-container",
        Cell: (props: any) => {
          const status = props?.row?.original?.status;
          return (
            <div className="btn_action_hover">
              {(status.toString() === StatusType.NOTAPPROVED ||
                status.toString() === StatusType.APPROVED) && (
                <ButtonDelete
                  className="delete"
                  onClick={() => clickDeleteHandler(props.value)}
                >
                  <FormattedMessage id="placeOrderBondAdv.buttonCancel" />
                </ButtonDelete>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Container>
        <CommonTableInfinity
          columns={columns}
          data={listDbondAdvItem}
          fetchMoreData={() => {}}
          enableInfinity={false}
          isHasFooter={false}
        />
      </Container>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({ id: "placeOrderBondAdv.confirmCancel" })}
      />
    </>
  );
}

export default Table;
