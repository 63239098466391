import { OptionTypeBase, Props as SelectProps } from "react-select";
import { useIntl } from "react-intl";
import { DropdownIndicator } from "./components";
import React, { useRef } from "react";
import Select from "react-select";
import { SelectBox, selectStyle } from "./style";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
export interface OptionSelectType extends OptionTypeBase {
  label: string;
  value: string;
}
interface Props extends SelectProps {
  options?: OptionSelectType[];
  isClearable?: boolean;
  isSelected?: boolean;
  toUpperCase?: boolean; //viết hoa khi search
  isHeight28?: boolean; //size nhỏ
}
const CSelect: React.FC<Props> = React.memo(
  ({
    options,
    components,
    isClearable = false,
    toUpperCase = false,
    optionsNew,
    getLabel,
    getValue,
    placeholder,
    mediumSelect = false,
    defaultValue,
    isUnselected,
    isHeight28 = false,
    ...rest
  }) => {
    const intl = useIntl();
    const { type } = useSelector((state: RootState) => state.theme);
    const focusIndexRef = useRef(-1);
    return (
      <SelectBox>
        <Select
          styles={selectStyle(type, mediumSelect, isHeight28)}
          placeholder={
            placeholder || intl.formatMessage({ id: "common.choose" })
          }
          isClearable={isClearable}
          onInputChange={(newValue) =>
            toUpperCase ? newValue.toUpperCase() : newValue
          }
          components={{
            DropdownIndicator,
            IndicatorSeparator: null,
            ...components,
          }}
          getOptionLabel={
            getLabel && !isUnselected
              ? (options) => options[getLabel]
              : undefined
          }
          getOptionValue={
            getValue && !isUnselected
              ? (options) => options[getValue]
              : undefined
          }
          options={options || optionsNew}
          defaultValue={defaultValue}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            spacing: {
              ...theme.spacing,
              controlHeight: mediumSelect ? 30 : 30,
            },
            colors: { ...theme.colors },
          })}
          onKeyDown={(e) => {
            if (
              e.key === "ArrowDown" &&
              focusIndexRef.current === (options ? options.length - 1 : 0)
            ) {
              e.preventDefault();
            }
            if (e.key === "ArrowUp" && focusIndexRef.current === 0) {
              e.preventDefault();
            }
          }}
          {...rest}
        />
      </SelectBox>
    );
  }
);

export default CSelect;
