import { EIndicatorType } from "modules/tickerDetails/redux/financeInfo";
import {
  IndicatorOption,
  TimeOption,
  TimeOptionValue,
  RevenueOption,
  CashFlowOption,
} from "./types";

export const TIME_OPTIONS: TimeOption[] = [
  {
    label: "Năm",
    value: TimeOptionValue.yearlyList,
  },
  {
    label: "Quý",
    value: TimeOptionValue.quarterlyList,
  },
];

export const INDICATOR_OPTIONS: IndicatorOption[] = [
  {
    label: "roe",
    value: EIndicatorType.ROE,
  },
  {
    label: "roa",
    value: EIndicatorType.ROA,
  },
  {
    label: "eps",
    value: EIndicatorType.EPS,
  },
];

export const REVENUE_OPTIONS: RevenueOption[] = [
  {
    label: "netincome",
    value: EIndicatorType.PROFIT,
  },
  {
    label: "netrevenue",
    value: EIndicatorType.TURNOVER,
  },
  {
    label: "operatingincome",
    value: EIndicatorType.BUSINESS_PROFIT,
  },
];

export const CASH_FLOW_OPTIONS: CashFlowOption[] = [
  {
    label: "cashflowoperating",
    value: EIndicatorType.CASH_FLOW,
  },
  {
    label: "cashflowinvesting",
    value: EIndicatorType.INVEST,
  },
  {
    label: "cashflowfinance",
    value: EIndicatorType.FINANCE,
  },
];
