import styled from "styled-components/macro";

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
  padding: 12px 16px 16px;
  justify-content: flex-start;
  overflow: auto;
`;

export const Item = styled.div`
  height: 319px;
  flex-basis: calc((100% - 64px) / 5);
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const ItemImage = styled.div`
  height: 75%;
  width: 100%;
`;

export const ItemImageContent = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
`;

export const Itemdescription = styled.div`
  height: 25%;
  width: 100%;
  padding: 10px;
  background: ${({ theme }) => theme.grey80};
  border-radius: 0px 0px 8px 8px;
`;

export const ItemLabel = styled.div`
  color: ${({ theme }) => theme.grey0};
  font-size: 15px;
`;

export const ItemContent = styled.div`
  font-size: 12px;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  width: 20vh;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemView = styled.div`
  font-size: 12px;
`;
