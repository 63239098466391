import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  LastSale,
  // SecQuotesDetailResponse,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";
import last from "lodash/last";
import moment from "moment";

export type TickerChartState = {
  detailInfo: SymbolTotalInfo.AsObject;
  loading: boolean;
};

const initialState: TickerChartState = {
  detailInfo: new SymbolTotalInfo().toObject(),
  loading: false,
};

const tickerChart = createSlice({
  name: "tickerChartStockOderSlice",
  initialState,
  reducers: {
    getTickerChart: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getTickerChartSuccess: (
      state,
      action: PayloadAction<SymbolTotalInfo.AsObject | undefined>
    ) => {
      state.loading = false;
      if (action.payload) {
        state.detailInfo = action.payload;
      }
    },
    getTickerChartUpdate: (state, action: PayloadAction<LastSale.AsObject>) => {
      const dataChart = state.detailInfo.priceinfolistList;
      let lastDataChart = last(dataChart);
      //ngày mới, clean chart
      if (
        moment(lastDataChart?.time || 0).get("dates") !==
        moment(action.payload.mattime, "Hmmss").get("dates")
      ) {
        state.detailInfo.priceinfolistList = [
          {
            time: moment(action.payload.mattime, "Hmmss").valueOf(),
            price: action.payload.lastprice,
            qty: action.payload.lastqty,
          },
        ];
        return;
      }
      //push vào chart
      if (
        moment(lastDataChart?.time || 0).get("minute") !==
        moment(action.payload.mattime, "Hmmss").get("minute")
      ) {
        state.detailInfo.priceinfolistList.push({
          time: moment(action.payload.mattime, "Hmmss").valueOf(),
          price: action.payload.lastprice,
          qty: action.payload.lastqty,
        });
      } else {
        state.detailInfo.priceinfolistList[
          state.detailInfo.priceinfolistList.length - 1
        ].qty += action.payload.lastqty;
      }
    },
  },
});

export const { getTickerChart, getTickerChartSuccess, getTickerChartUpdate } =
  tickerChart.actions;

export default tickerChart.reducer;
