import styled from "styled-components";

export const Container = styled.div<{
  isVisibleSideCard: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;

export const WrapTab = styled.div`
  .header-left {
    padding: 8px 0px 8px 12px;
    display: flex;
    align-items: center;
    color: white;
    background-color: #2d2d2d;
  }
`;
