/* eslint-disable react-hooks/exhaustive-deps */
import { LabelCell, Container, ContentOne } from "./styles";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";
import { useEffect, useState, useMemo } from "react";
import { tradeOption } from "modules/advertisingOrder/pages/AdvertisingOrder/components/constants";
import { useIntl } from "react-intl";
import { findAllDbondAdvApi } from "core/http/apis/advertisingOrder";
import { tradeDateSelector } from "modules/auth/selectors";
import { useSelector } from "react-redux";
import { toast } from "components/commons/Toast";

const AdvertisingOrder: FC = () => {
  const intl = useIntl();
  const tradeTypeOptions = useMemo(() => tradeOption(intl), [intl]);
  const tradeDate = useSelector(tradeDateSelector);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoanding] = useState<boolean>(false);

  const [param, setParam] = useState<any>({
    bondCd: null,
    tradeType: tradeTypeOptions[0]?.value,
  });

  const getData = async () => {
    setLoanding(true);
    const res: any = await findAllDbondAdvApi({
      filter: JSON.stringify(param),
      tradeDateFrom: tradeDate,
      tradeDateTo: tradeDate,
    });
    if (res?.statusCode === 0) {
      setData(res?.data);
      setLoanding(false);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
      setLoanding(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const changeForm = (value: any, key: string) => {
    setParam({
      ...param,
      [key]: value,
    });
  };

  return (
    <Container>
      <ContentOne>
        <LabelCell>
          <FormattedMessage id="advertisingOrder.title" />
        </LabelCell>
        <RequestFilter
          loading={loading}
          submitForm={() => getData()}
          changeForm={changeForm}
        />
        <RequestTable data={data} />
      </ContentOne>
    </Container>
  );
};
export default AdvertisingOrder;
