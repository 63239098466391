import { Button, Spacer } from "components/commons";
import {
  updateModalStatusRequest,
  entryBankUtilityRequest,
  updateItemEdit,
  getInquiryBankUtilityRequest,
} from "modules/hold-unhold-bank-account/redux";
import {
  isShowConfirmModalSelector,
  statusEntryBankUtilitySelector,
} from "modules/hold-unhold-bank-account/selectors";
import {
  IEntryBankUtilityParams,
  IGetInquiryBankUtilityParams,
} from "core/http/apis/hold-unhold-bank-account/types";

import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Body, Container, Title, Footer } from "./styles";

import { toast } from "components/commons/Toast";
import { IEditHoldUnholdBankAccountParams } from "core/http/apis/hold-unhold-bank-account/types";
import { useEffect } from "react";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
interface Props {
  itemEdit?: IEditHoldUnholdBankAccountParams;
  accountOptions?: any;
  actionOptions?: any;
}

function ConfirmModal({ itemEdit, accountOptions, actionOptions }: Props) {
  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const isVisible = useSelector(isShowConfirmModalSelector);
  const statusEntryBankUtility = useSelector(statusEntryBankUtilitySelector);
  useEffect(() => {
    if (statusEntryBankUtility) {
      dispatch(
        updateItemEdit({
          subAccoNo: itemEdit?.subAccoNo ? itemEdit?.subAccoNo : "",
          action: +actionOptions[0].value,
          amount: "",
          remarks: "",
        })
      );
      toast("holdUnholdBankAccount.message.success", "success");
      if (itemEdit && itemEdit.subAccoNo) {
        const submitData: IGetInquiryBankUtilityParams = {
          subAccoNo: itemEdit?.subAccoNo ?? "",
          subAccCd: null,
        };

        dispatch(getInquiryBankUtilityRequest(submitData));
      }
    }
  }, [statusEntryBankUtility]);
  const closeModalHandler = () => {
    dispatch(updateModalStatusRequest({ isVisible: false }));
  };

  const clickAgreeButtonHandler = () => {
    if (itemEdit && Object.keys(itemEdit).length > 0) {
      if (
        itemEdit.amount === null ||
        itemEdit.amount === undefined ||
        itemEdit.amount === ""
      ) {
        return toast("holdUnholdBankAccount.message.amountCheck1", "error");
      } else if (itemEdit.amount === 0) {
        return toast("holdUnholdBankAccount.message.amountCheck2", "error");
      } else if (
        itemEdit.subAccoNo === null ||
        itemEdit.subAccoNo === undefined
      ) {
        return toast("holdUnholdBankAccount.message.subAccountCheck1", "error");
      } else if (itemEdit.action === null || itemEdit.action === undefined) {
        return toast("holdUnholdBankAccount.message.actionCheck1", "error");
      }
      const submitData: IEntryBankUtilityParams = {
        actionMode: itemEdit?.action ? itemEdit?.action : 0,
        subAccNo: itemEdit?.subAccoNo ? itemEdit?.subAccoNo : "",

        amount: itemEdit?.amount ? +itemEdit?.amount : 0,

        remarks: itemEdit?.remarks ? itemEdit?.remarks : "",
      };

      dispatch(entryBankUtilityRequest(submitData));

      closeModalHandler();
    }
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop="static"
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-284"
    >
      <Container>
        <Title>
          {" "}
          <FormattedMessage id="holdUnholdBankAccount.confirmModal.title" />
        </Title>

        <Body>
          <div>
            {" "}
            <FormattedMessage
              id={`holdUnholdBankAccount.confirmModal.content${
                itemEdit && itemEdit.action
              }`}
            />
          </div>
        </Body>

        <Footer>
          <Button
            color="accept"
            className="button-confirm"
            width="100%"
            onClick={closeModalHandler}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Spacer size="m" />
          <Button
            onClick={clickAgreeButtonHandler}
            className="button-confirm"
            fill
            width="100%"
            style={{
              background: `${themes[themeType].buttonGradientColor}`,
            }}
          >
            <FormattedMessage id="common.accept" />
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
}

export default ConfirmModal;
