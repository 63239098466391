import Container from "components/commons/Container";
import Navigaton from "./navigation";
import AssetsIncurredContent from "./assetsIncurred";
import { useIntl } from "react-intl";

const Assets = () => {
  const intl = useIntl();
  const arrayForm = [AssetsIncurredContent];

  return (
    <Container
      headerHistory={Navigaton}
      headerForm={intl.formatMessage({
        id: "assets.navigation.assets",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default Assets;
