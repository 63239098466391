import { combineReducers } from "@reduxjs/toolkit";
import placeOrderDerReducer from "./PlaceOrderDer";
import orderBookDerReducer from "./OrderBookDer";
import volumeAndTime from "./volumeAndTime";
import priceListReducer from "./priceList";

export default combineReducers({
  placeOrderDer: placeOrderDerReducer,
  orderBookDer: orderBookDerReducer,
  volumeAndTime: volumeAndTime,
  priceList: priceListReducer,
});
