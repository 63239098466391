import { Button, DatePicker } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import {
  getContractRequest,
  searchContractRequest,
} from "modules/add-framework-constract-form/redux";
import { loadingSearchSelector } from "modules/add-framework-constract-form/selectors";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { FilterWrapper, WrapBorder } from "./styles";

function Filter() {
  const intl = useIntl();
  const d = new Date();
  const dispatch = useDispatch();
  const loading = useSelector(loadingSearchSelector);

  const status = [
    {
      label: `${intl.formatMessage({
        id: "addFrameworkContractForm.listContract.all",
      })}`,
      value: "",
    },
    {
      label: `${intl.formatMessage({
        id: "addFrameworkContractForm.listContract.inEffect",
      })}`,
      value: "1",
    },
    {
      label: `${intl.formatMessage({
        id: "addFrameworkContractForm.listContract.liquidated",
      })}`,
      value: "2",
    },
  ];

  const accountNo = storage.getMasterAccount();

  const [fromDate, setFromDate] = useState(
    new Date(d.getFullYear(), d.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(new Date());
  const [newStatus, setNewStatus] = useState();

  const handleChangeFromDate = (value: any) => {
    setFromDate(value);
  };
  const handleChangeToDate = (value: any) => {
    setToDate(value);
  };

  const handleChangeStatus = (value: any) => {
    setNewStatus(value?.value);
  };

  const handleSearch = () => {
    if (moment(fromDate).isAfter(moment(toDate))) {
      toast(
        `${intl.formatMessage({
          id: "addFrameworkContractForm.listContract.errorToDate",
        })}`,
        "error"
      );
    } else {
      dispatch(
        searchContractRequest({
          branchCd: "",
          transactionCd: "",
          custNo: accountNo,
          contractDateFrom: moment(fromDate).format("YYYYMMDD"),
          contractDateTo: moment(toDate).format("YYYYMMDD"),
          closeDateFrom: "",
          closeDateTo: "",
          contractSts: newStatus ? newStatus : status[0]?.value,
          srcChannel: "",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getContractRequest({
        branchCd: "",
        transactionCd: "",
        custNo: accountNo,
        contractDateFrom: moment(fromDate).format("YYYYMMDD"),
        contractDateTo: moment(toDate).format("YYYYMMDD"),
        closeDateFrom: "",
        closeDateTo: "",
        contractSts: "",
        srcChannel: "",
      })
    );
  }, []);

  return (
    <WrapBorder className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "addFrameworkContractForm.listContract.fromDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={fromDate}
            onChange={handleChangeFromDate}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "addFrameworkContractForm.listContract.toDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={toDate}
            onChange={handleChangeToDate}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "addFrameworkContractForm.listContract.status",
          })}
          className="mr-4"
        >
          <CSelect
            className="flex-fill"
            options={status}
            onChange={handleChangeStatus}
            defaultValue={status[0]}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          onClick={handleSearch}
          loading={loading}
        >
          {intl.formatMessage({
            id: "addFrameworkContractForm.listContract.search",
          })}
        </Button>
      </FilterWrapper>
    </WrapBorder>
  );
}

export default memo(Filter);
