import { FC } from "react";
import { getTypeTransferSelector } from "modules/transfer-internal/selectors";
import { useSelector } from "react-redux";
import RequestDetailTransferBank from "./RequestDetailTransferBank";
import RequestDetail from "./RequestDetail";
import RequestDetailDeposit from "./RequestDetailDeposit";

const TransferInternalForm: FC = () => {
  const typeTranfer = useSelector(getTypeTransferSelector);

  const contentAdd = () => {
    if (typeTranfer === "0") return <RequestDetailTransferBank />;
    if (typeTranfer === "1") return <RequestDetail />;
    if (typeTranfer === "2" || typeTranfer === "3")
      return <RequestDetailDeposit />;
    return <RequestDetailTransferBank />;
  };
  return contentAdd();
};
export default TransferInternalForm;
