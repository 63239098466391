import React from "react";
import styled from "styled-components/macro";

interface Color {
  color?: string;
  className?: string;
}
interface PropsVerticalDivider extends Color {
  height?: number;
}

const Vertical = styled.div<PropsVerticalDivider>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color || "#283648"};
`;

export const VerticalDivider: React.FC<PropsVerticalDivider> = React.memo(
  ({ height = 1, color, className }) => {
    return <Vertical className={className} height={height} color={color} />;
  }
);

interface PropsHorizontalDivider extends Color {
  width?: number;
}

const Horizontal = styled.div<PropsHorizontalDivider>`
  height: 100%;
  width: ${(props) => props.width || 1}px;
  background-color: ${(props) => props.color || "#283648"};
`;
export const HorizoltalDivider: React.FC<PropsHorizontalDivider> = React.memo(
  ({ width = 1, color, className }) => {
    return <Horizontal className={className} width={width} color={color} />;
  }
);
