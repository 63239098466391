import { Input } from "components/commons";
import CSelect from "components/commons/Select";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList, GetParameters } from "helper/sessionData";
import {
  buyInfor,
  cbotGetInterestRequest,
  findCbotBondInfoRequest,
  inquiryBuyingPowerRequest,
  updateListPeriod,
  calInvestmentValue,
} from "modules/place-order-retail-bonds/redux";
import {
  calInvestmentValueSelector,
  getBondCdListFullSelector,
  getBondCdListSelector,
  getBuyInforSelector,
  getBuyingPowerSelector,
  getCbotBondInfoSelector,
  getcbotGetInterestSelector,
  getListPeriodSelector,
} from "modules/place-order-retail-bonds/selectors";
import numeral from "numeral";
import { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentLeft,
  ContentRight,
  ContentRow,
  ContentValue,
  Title,
} from "../ContainerBottom/styles";
import {
  Content,
  DetailInputWrapper,
  DetailLabel,
  DetailRowPaddingBorder,
  DetailRowPaddingTB,
  LabelCell,
  Wrapper,
} from "./styles";

function ContainerTop() {
  const dispatch = useDispatch();

  const buyInformation = useSelector(getBuyInforSelector);
  const listBonds = useSelector(getBondCdListSelector);
  const listBondsFull = useSelector(getBondCdListFullSelector);
  const listPeriod = useSelector(getListPeriodSelector);
  const cbotGetInterest = useSelector(getcbotGetInterestSelector);
  const buyingPower = useSelector(getBuyingPowerSelector);
  const bondCdInfor = useSelector(getCbotBondInfoSelector);
  const investmentValue = useSelector(calInvestmentValueSelector);

  const paramHd = GetParameters("CB00", "CBO_FEE_RATE");
  const listAcc: any = GetAccoList(
    GetAccountType.BOND,
    EntrustBusinessCode.All
  );

  const mapListBondCd = listBonds.filter(
    (a: any, i: any) =>
      listBonds.findIndex((s: any) => a.label === s.label) === i
  );

  const handleChangeBonds = (e: any) => {
    dispatch(
      buyInfor({
        key: "bondCd",
        value: e ? e : "",
      })
    );

    dispatch(
      findCbotBondInfoRequest({
        bondCd: e.value,
      })
    );

    const findPeriod = listBondsFull?.filter((v: any) => e.value === v?.bondCd);
    const periodItem = findPeriod?.map((item: any) => {
      return { label: item?.period, value: item?.period };
    });

    dispatch(
      buyInfor({
        key: "period",
        value: findPeriod ? periodItem : "",
      })
    );
    dispatch(updateListPeriod({ listPeriod: periodItem }));
  };

  const handChangePeriod = (e: any) => {
    dispatch(
      buyInfor({
        key: "period",
        value: e ? e : "",
      })
    );
  };
  const handleChangeQuantity = (e: any) => {
    dispatch(
      buyInfor({
        key: "qty",
        value: e ? e.target.value.replaceAll(",", "") : "",
      })
    );
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      const value = bondCdInfor?.faceValPrice * +buyInformation?.qty;

      dispatch(
        calInvestmentValue({
          calInvestmentValue: value,
        })
      );
    }
  };

  const calValueReceived = useMemo(() => {
    if (cbotGetInterest !== "" && investmentValue) {
      return investmentValue + cbotGetInterest;
    }
  }, [calInvestmentValue, cbotGetInterest]);

  const calFee = useMemo(() => {
    if (investmentValue && paramHd) {
      return investmentValue * +paramHd;
    }
  }, [investmentValue, paramHd]);

  useMemo(() => {
    dispatch(
      buyInfor({
        key: "subAccoNo",
        value: listAcc?.[0] ? listAcc?.[0]?.subAccoNo : "",
      })
    );
  }, [listAcc?.[0]?.subAccoNo]);

  useEffect(() => {
    if (investmentValue !== "") {
      dispatch(
        cbotGetInterestRequest({
          amount: investmentValue ? investmentValue : 0,
          bondCd: buyInformation?.bondCd?.value,
          period: buyInformation?.period?.[0]?.value
            ? buyInformation?.period?.[0]?.value
            : 0,
        })
      );
    }
  }, [investmentValue]);

  useEffect(() => {
    dispatch(
      inquiryBuyingPowerRequest({
        subAccoNo: listAcc?.[0]?.subAccoNo,
        subAccoCd: "",
        secCd: "",
      })
    );
  }, []);

  return (
    <Wrapper>
      <LabelCell>
        <FormattedMessage id="placeOrderRetailBonds.infomationOrder.title" />
      </LabelCell>
      <Content>
        <DetailRowPaddingTB>
          <DetailLabel>
            <FormattedMessage id="placeOrderRetailBonds.infomationOrder.subAcco" />
          </DetailLabel>
          <DetailInputWrapper>{listAcc?.[0]?.subAccoNo}</DetailInputWrapper>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <DetailLabel>
            <FormattedMessage id="placeOrderRetailBonds.infomationOrder.bondCd" />
          </DetailLabel>
          <DetailInputWrapper>
            <CSelect
              options={mapListBondCd}
              defaultValue={mapListBondCd?.[0]}
              value={buyInformation?.bondCd}
              onChange={handleChangeBonds}
            />
          </DetailInputWrapper>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <DetailLabel>
            <FormattedMessage id="placeOrderRetailBonds.infomationOrder.cycle" />
          </DetailLabel>
          <DetailInputWrapper>
            <CSelect
              options={listPeriod}
              value={buyInformation?.period}
              onChange={handChangePeriod}
            />
          </DetailInputWrapper>
        </DetailRowPaddingTB>
        <DetailRowPaddingBorder>
          <DetailLabel>
            <FormattedMessage id="placeOrderRetailBonds.infomationOrder.setQuantity" />
          </DetailLabel>
          <DetailInputWrapper>
            <Input
              onKeyDown={onKeyDown}
              onChange={handleChangeQuantity}
              value={
                buyInformation?.qty
                  ? numeral(buyInformation?.qty).format("0,0")
                  : ""
              }
            />
          </DetailInputWrapper>
        </DetailRowPaddingBorder>

        <ContentRow>
          <ContentLeft>
            <Title>
              <FormattedMessage id="placeOrderRetailBonds.infomationOrder.investmentValue" />
            </Title>
            <ContentValue>
              {investmentValue ? numeral(investmentValue).format("0,0") : ""}
            </ContentValue>
          </ContentLeft>
          <ContentRight>
            <Title>
              <FormattedMessage id="placeOrderRetailBonds.infomationOrder.purchasingAbility" />
            </Title>
            <ContentValue>
              {buyingPower ? numeral(buyingPower).format("0,0") : 0}
            </ContentValue>
          </ContentRight>
        </ContentRow>
        <ContentRow>
          <ContentLeft>
            <Title>
              <FormattedMessage id="placeOrderRetailBonds.infomationOrder.valueReceived" />
            </Title>
            <ContentValue>
              {calValueReceived && numeral(calValueReceived).format("0,0")}
            </ContentValue>
          </ContentLeft>
          <ContentRight>
            <Title>
              <FormattedMessage id="placeOrderRetailBonds.infomationOrder.fee" />
            </Title>
            <ContentValue>
              {calFee ? numeral(calFee).format("0,0") : ""}
            </ContentValue>
          </ContentRight>
        </ContentRow>
      </Content>
    </Wrapper>
  );
}

export default ContainerTop;
