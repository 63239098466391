import { Button, Spacer } from "components/commons";
import { storageOTPCodeSelector } from "modules/auth/selectors";
import { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Switch from "../Switch";
import OTPInput from "./OTPInput";
import {
  CardTable,
  ErrorVerify,
  HeaderTitle,
  ModalHeader,
  SaveOTPWrapper,
  SwitchSave,
  TextSave,
  Container,
  WrapperButton,
  ButtonExit,
  Icon,
  ModalTab,
  Tab,
} from "./styles";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import QRCode from "../QRCode";
import { EBusinessCd } from "helper/consts";
interface Props {
  handleModal: any;
  changeMasterAccount: any;
  otpEverryTime?: boolean; // otpEverryTime là check mỗi lần mở ra, mặc định = true
  submitOTP?: any;
  errorOTP?: string;
  setErrorOtp?: any;
  allowSaveOtp?: boolean;
  getResult?: any;
  businessCd: EBusinessCd;
}
const radius4 = 4;

enum TAB_CODE {
  SMART_OTP = "SMART_OTP",
  QR_CODE = "QR_CODE",
}

function SmartOTP({
  handleModal,
  changeMasterAccount,
  otpEverryTime = true,
  submitOTP,
  errorOTP,
  setErrorOtp,
  allowSaveOtp,
  businessCd,
  getResult,
}: Props) {
  const storageOTPCode = useSelector(storageOTPCodeSelector);
  const intl = useIntl();

  const [codeOTP, setCodeOTP] = useState("");

  const [loading, setLoading] = useState(false);
  const [saveOTP, setSaveOTP] = useState(true);
  const [tab, setTab] = useState(TAB_CODE.SMART_OTP);

  const changeSwitchHandler = () => {
    setSaveOTP((prev) => !prev);
  };

  // const clearStorageOTPCode = () => {
  //   if (otpEverryTime === true) return;
  //   dispatch(setStorageOTPCode({ type: 0, code: "" })); // xóa codeOTP trong redux
  // };

  useEffect(() => {
    setCodeOTP("");
    setLoading(false);
  }, []);

  const handleSubmitOTP = () => {
    setErrorOtp("");
    submitOTP(codeOTP, saveOTP);
  };

  const changeTabHandler = (tabCode: TAB_CODE) => () => {
    setTab(tabCode);
  };

  return (
    <>
      {(storageOTPCode?.code === "" || otpEverryTime === true) && (
        <Modal
          show={true}
          centered
          // backdrop={true}
          keyboard={false}
          dialogClassName="modal-434"
        >
          <Container>
            <ModalHeader>
              <HeaderTitle>
                <FormattedMessage id="auth.info.sendOTP.title" />
              </HeaderTitle>

              <WrapperButton>
                <ButtonExit onClick={handleModal}>
                  <Icon>
                    <IconExit />
                  </Icon>
                </ButtonExit>
              </WrapperButton>
            </ModalHeader>

            <ModalTab>
              <Tab
                isActive={tab === TAB_CODE.SMART_OTP}
                onClick={changeTabHandler(TAB_CODE.SMART_OTP)}
              >
                <FormattedMessage id="auth.info.smartOTP.smartOTP" />
              </Tab>
              <Tab
                isActive={tab === TAB_CODE.QR_CODE}
                onClick={changeTabHandler(TAB_CODE.QR_CODE)}
              >
                <FormattedMessage id="auth.info.smartOTP.qrcode" />
              </Tab>
            </ModalTab>

            <CardTable>
              <div className="inputOTP">
                <div className="inputOTPHeader">
                  <FormattedMessage id="auth.info.smartOTP.enterOTP" />
                </div>
                <div
                  className={`inputOTPContent ${errorOTP ? "hasError" : ""}`}
                >
                  <OTPInput
                    autoFocus
                    isNumberInput
                    length={6}
                    loading={loading}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={(otp) => {
                      setCodeOTP(String(otp));
                      setErrorOtp("");
                    }}
                    handelVerifySmartOTP={handleSubmitOTP}
                  />
                  <ErrorVerify>
                    {errorOTP !== "" &&
                      intl.formatMessage({
                        id: errorOTP || "error.defaultErr",
                      })}
                  </ErrorVerify>
                </div>

                <SaveOTPWrapper allowSaveOtp={allowSaveOtp}>
                  <TextSave>
                    <FormattedMessage id="auth.info.smartOTP.saveOTP" />
                  </TextSave>
                  <SwitchSave>
                    <Switch
                      checked={saveOTP}
                      onChange={changeSwitchHandler}
                      loading={false}
                    />
                  </SwitchSave>
                </SaveOTPWrapper>
              </div>

              <div className="footer">
                <div style={{ flex: 7 }}></div>
                <div style={{ flex: 9, display: "flex", flexDirection: "row" }}>
                  <Button
                    size="l"
                    style={{ flex: 1 }}
                    color="accept"
                    className="flex-1"
                    radius={radius4}
                    onClick={handleModal}
                  >
                    <FormattedMessage id="auth.info.smartOTP.cancel" />
                  </Button>
                  <Spacer size="m" />
                  <Button
                    disabled={codeOTP.length < 6}
                    size="l"
                    fill={true}
                    style={{ flex: 1 }}
                    loading={loading}
                    onClick={handleSubmitOTP}
                    className="flex-1"
                    radius={radius4}
                    color="gradientaAccept"
                  >
                    <FormattedMessage id="auth.info.editInfo.update" />
                  </Button>
                </div>
              </div>
            </CardTable>

            {tab === TAB_CODE.QR_CODE && (
              <QRCode businessCd={businessCd} getResult={getResult} />
            )}
          </Container>
        </Modal>
      )}
    </>
  );
}
export default memo(SmartOTP);
