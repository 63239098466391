import { FC } from "react";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import TableHistory from "./RequestTable";
import FilterHistory from "./RequestFilters";

const TransferInternalHistoryTable: FC = () => {
  return (
    <Wrap>
      <>
        <FilterHistory />
        <TableHistory />
      </>
    </Wrap>
  );
};
export default TransferInternalHistoryTable;
