import VgaiaHttp from "core/http/singleton/vgaia";
import { IMarketStatisticResponse } from "domain/models/MarketStatistic";
import { ISearchStatisticOndayParams } from "./types";
import { searchStatisticOndayApi } from "./urls";

export const searchStatisticOnday = async (
  params: ISearchStatisticOndayParams
): Promise<IMarketStatisticResponse> => {
  const res = await VgaiaHttp.post<IMarketStatisticResponse>(
    searchStatisticOndayApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
