import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { TOP_PRICE_LOADING_LIMIT } from "AppConfig";
import {
  requestChangePeriod,
  changePeriodSuccess,
  requestLoadMore,
  requestLoadMoreSuccess,
  requestTopPriceRight,
  requestTopPriceRightSuccess,
  changeActiveSort,
} from "../redux/topPriceRight";
import { getEPeriod } from "../helpers/topPrice";
import {
  TopSecUpDownResponse,
  TopSecUpDownInput,
} from "domain/protoNew/auto_trading_pb";
import { AuthenCodeGrpc } from "AppConfig";
import { getTopSecUpDown } from "core/grpc";
import {
  activeSortTopPriceRightSelector,
  activeTabTopPriceRightSelector,
  marketTopPriceRightSelector,
  currentMarketCodeSelector,
} from "../redux/selectors";
import { TopPricePeriod } from "components/widgets/generals/TopPrice/types";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";

function* fetchTickersWorker() {
  const activeTab: TopPricePeriod = yield select(
    activeTabTopPriceRightSelector
  );
  const { sortBy, order } = yield select(activeSortTopPriceRightSelector);
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  const request = new TopSecUpDownInput();
  request.setUserid(uuidv4());
  request.setToptype(2);
  request.setMarketcd(currentMarketCode.toString());
  request.setIndustries("");
  request.setPeriodtype(getEPeriod(activeTab));
  request.setOffset(0);
  request.setOrderfieldtype(sortBy);
  request.setOrdertype(order);
  request.setLimit(TOP_PRICE_LOADING_LIMIT);
  request.setAuthencode(AuthenCodeGrpc);

  try {
    const response: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] =
      yield call(getTopSecUpDown, request);

    yield put(changePeriodSuccess(response));
  } catch (e) {}
}

function* fetchPriceInfoLastSaleLoadMoreWorker() {
  try {
    const { limit, activeTab, enableLoadMore } = yield select(
      marketTopPriceRightSelector
    );
    const { sortBy, order } = yield select(activeSortTopPriceRightSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    if (enableLoadMore === false) return;
    const request = new TopSecUpDownInput();
    request.setUserid(uuidv4());
    request.setToptype(2);
    request.setMarketcd(currentMarketCode.toString());
    request.setIndustries("");
    request.setPeriodtype(getEPeriod(activeTab));
    request.setOffset(limit);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(TOP_PRICE_LOADING_LIMIT);
    request.setAuthencode(AuthenCodeGrpc);
    const response: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] =
      yield call(getTopSecUpDown, request);
    yield put(requestLoadMoreSuccess(response));
  } catch (e) {}
}

function* fetchPriceInfoLastSaleWorker() {
  try {
    const { limit, activeTab } = yield select(marketTopPriceRightSelector);
    const { sortBy, order } = yield select(activeSortTopPriceRightSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const request = new TopSecUpDownInput();
    request.setUserid(uuidv4());
    request.setToptype(2);
    request.setMarketcd(currentMarketCode.toString());
    request.setIndustries("");
    request.setPeriodtype(getEPeriod(activeTab));
    request.setOffset(0);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(limit);
    request.setAuthencode(AuthenCodeGrpc);
    const response: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] =
      yield call(getTopSecUpDown, request);
    yield put(requestTopPriceRightSuccess(response));
  } catch (e) {}
}

function* fetchTickersByPeriodWatcher() {
  yield takeLatest(requestChangePeriod.type, fetchTickersWorker);
}

function* fetchTickersBychangeActiveSortWatcher() {
  yield takeLatest(changeActiveSort.type, fetchTickersWorker);
}

function* fetchPriceInfoLastSaleLoadMoreWatcher() {
  yield takeLatest(requestLoadMore.type, fetchPriceInfoLastSaleLoadMoreWorker);
}

function* fetchTopPriceRightWatcher() {
  yield takeLatest(requestTopPriceRight.type, fetchPriceInfoLastSaleWorker);
}

function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchPriceInfoLastSaleWorker);
}

export default function* topPriceRightWidget() {
  yield all([
    fetchTickersByPeriodWatcher(),
    fetchTickersBychangeActiveSortWatcher(),
    fetchPriceInfoLastSaleLoadMoreWatcher(),
    fetchTopPriceRightWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
