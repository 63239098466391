// import queryString from "query-string";

export const urlCbotBondPriceEst = "/findCbotBondPriceEst";
export const urlFindDbondAdv = "/findDbondAdv";
export const urlUpdateDbondAdv = "/updateDbondAdv";
// export const urlUpdateDbondAdv = (params: any) => {
//   const paramsFormatter = queryString.stringify(params, {
//     skipNull: true,
//   });
//   return `/updateDbondAdv${paramsFormatter ? `?${paramsFormatter}` : ""}`;
// };
