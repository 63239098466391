import { InputPlaceOrder, Spacer } from "components/commons";
import { Colors, EMarketCodeNew, SecType } from "helper/consts";
import globalData from "helper/globalData";
import {
  onChangePriceByEnterDer,
  onChangeTickerByEnterDer,
  updateCurrentTicker,
  updateErrorMesage,
  updatePlaceOrderDerPrice,
  updateTickerInfoLastSale,
  getQuotesForDertOrder,
  updateMultipleNum,
  clearDataDer,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import numeral from "numeral";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  TextColor,
  TickerContainer,
  TickerWrappper,
  SearchTicker,
  RowSearch,
} from "./styles";
import { toast } from "components/commons/Toast";
import { useRoveFocus } from "helper/cusHooks";
import SelectItem from "./SelectItem";
import {
  dataSelector,
  placeOrderDerSelector,
  validateSelector,
} from "modules/stock-order-der/selectors";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { tickerListSelector } from "modules/categories/selectors";
import { IGetQuotesForDertOrderParams } from "core/http/apis/orderexec/types";
import { useInfoLastSale } from "helper/cusHooks";
import sortBy from "lodash/sortBy";
import { useEffectOnce } from "react-use";

const getDerTicker = (tickers: MrktSecInfoItem.AsObject[]) => {
  return {
    tickers: tickers.filter((data) => data.marketcd === EMarketCodeNew.DER_IDX),
  };
};

const TickerSearch: FC = memo(() => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [visibleTextSearch, setVisibleTextSearch] = useState(false);
  const [textDisplay, setTextDisplay] = useState(true);
  const [textSearch, setTextSearch] = useState("");
  const [firstLoadPage, setFirstLoadPage] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);
  const tickerList = useSelector(tickerListSelector);
  const tickersDer = getDerTicker(tickerList);

  const [isChangeArrow, setIsChangeArrow] = useState(false);
  const [isClickOutSide, setIsClickOutSide] = useState(false);
  const [numberLoadmore, setNumberLoadMore] = useState(20);
  const { tickerInfoLastSale, quotesForDertOrder } = useSelector(
    placeOrderDerSelector
  );

  const { currentTicker } = useSelector(dataSelector);
  const dataLastSale = useInfoLastSale(currentTicker);
  // const isPriceInput = useSelector(isPriceInputSelector);
  const { errorCurrentTicker } = useSelector(validateSelector);

  const ticker = globalData.getTickerInfoNew(currentTicker);

  const onSearchTicker = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTextSearch(e.target.value.toUpperCase());

  const updatePrice = (price: string | number) => {
    dispatch(updatePlaceOrderDerPrice(price));
    dispatch(updateErrorMesage({ key: "errorPrice", message: "" }));
    setTimeout(() => dispatch(onChangePriceByEnterDer(true)), 150);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      if (textSearch.length <= 2) return;
      e.stopPropagation();
      const item = tickersDer?.tickers
        ?.filter((ticker) => ticker.seccd.startsWith(textSearch.toUpperCase()))
        .sort((firstItem, secondItem) =>
          firstItem.seccd > secondItem.seccd
            ? 1
            : secondItem.seccd > firstItem.seccd
            ? -1
            : 0
        )[0];
      if (item) {
        // unSubscribleTicker(currentTicker);
        dispatch(updateCurrentTicker(item.seccd));
        dispatch(
          updateMultipleNum(item.sectype === SecType.S ? 100000 : 10000)
        );
        setTextSearch("");
        setVisibleTextSearch(false);
        setTextDisplay(true);
      } else {
        setTextSearch("");
        toast("widgets.placeOrder.errorTickerIllegal", "error");
        dispatch(clearDataDer());
        dispatch(updateCurrentTicker(""));
        dispatch(updateMultipleNum(10000));
      }
      dispatch(onChangeTickerByEnterDer(true));
    } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      setIsChangeArrow(true);
      setTimeout(() => setTextDisplay(false), 1);
    }
  };

  useEffect(() => {
    if (!currentTicker) return;

    const getTickerInfo = async () => {
      try {
        const params: IGetQuotesForDertOrderParams = {
          secCode: currentTicker,
        };
        dispatch(getQuotesForDertOrder(params));
      } catch (error: any) {
        if (currentTicker !== "") {
          toast("error.defaultErr", "error");
        }
        dispatch(updatePlaceOrderDerPrice(""));
        dispatch(clearDataDer());
      }
    };

    getTickerInfo();

    // if (currentTicker) {
    //   subscribleTicker(currentTicker);
    // }
  }, [currentTicker, dispatch]);

  const oldData = useRef();
  const [backgroundColor, setBackgroundColor] = useState<string>("red");

  useEffect(() => {
    const _oldData = oldData.current;
    oldData.current = tickerInfoLastSale;

    if (
      _oldData &&
      _oldData !== tickerInfoLastSale &&
      _oldData["lastprice"] &&
      _oldData["lastprice"] !== tickerInfoLastSale?.lastprice
    ) {
      setBackgroundColor("solidFlash");
    }

    if (
      _oldData &&
      _oldData !== tickerInfoLastSale &&
      _oldData["totalqty"] &&
      _oldData["totalqty"] !== tickerInfoLastSale?.totalqty
    ) {
    }

    setTimeout(() => {
      setBackgroundColor("");
    }, 500);
  }, [tickerInfoLastSale]);

  useEffect(() => {
    if (dataLastSale && dataLastSale?.seccd === currentTicker) {
      dispatch(updateTickerInfoLastSale(dataLastSale));
    }
  }, [dataLastSale, dispatch]);

  const handleSelectTicker = () => {
    if (textSearch.length <= 2) return;
    if (!isClickOutSide) return;
    const item = tickersDer?.tickers?.filter((ticker) =>
      ticker.seccd.startsWith(textSearch.toUpperCase())
    )[0];
    if (item) {
      // unSubscribleTicker(currentTicker);
      dispatch(updateCurrentTicker(item.seccd));
      dispatch(updateMultipleNum(item.sectype === SecType.S ? 100000 : 10000));
      setTextSearch("");
    } else {
      setTextSearch("");
      toast("widgets.placeOrder.errorTickerIllegal", "error");
      dispatch(clearDataDer());
      dispatch(updateCurrentTicker(""));
      dispatch(updateMultipleNum(10000));
    }
  };

  // filter by text input
  const tickerFilter = tickersDer?.tickers?.filter((ticker) =>
    ticker.seccd.startsWith(textSearch.toUpperCase())
  );

  const tickerVN30: MrktSecInfoItem.AsObject[] = [];
  const tickerOther: MrktSecInfoItem.AsObject[] = [];
  let tickerSort: MrktSecInfoItem.AsObject[] = [];

  // sort uu tien ma VN30
  tickerFilter.map((item) => {
    if (item.sectype === 1) {
      tickerVN30.push(item);
    } else {
      tickerOther.push(item);
    }
    return item;
  });

  // sort uu tien ma dao han gan nhat
  tickerSort = sortBy(tickerVN30, "lasttradedate").concat(
    sortBy(tickerOther, "lasttradedate")
  );

  useEffectOnce(() => {
    setFirstLoadPage(true);
  });

  useEffect(() => {
    if (firstLoadPage && tickersDer && tickersDer.tickers.length > 0) {
      const tickerFirstList = sortBy(
        tickersDer.tickers.filter((item) => item.sectype === 1),
        "lasttradedate"
      );
      if (tickerFirstList.length > 0) {
        dispatch(updateCurrentTicker(tickerFirstList[0].seccd));
        dispatch(updateMultipleNum(100000));
      }
      setFirstLoadPage(false);
    }
  }, [tickersDer, firstLoadPage]);

  const [focus, setFocus] = useRoveFocus(
    tickerSort.length,
    setIsChangeArrow,
    isChangeArrow
  );

  useEffect(() => {
    if (isClickOutSide) {
      setVisibleTextSearch(false);
    }
  }, [isClickOutSide]);

  const handleScroll = () => {
    setNumberLoadMore(numberLoadmore + 20);
  };

  const setTickerSelect = () => {};

  return (
    <SearchTicker>
      <RowSearch>
        <TickerWrappper>
          <InputPlaceOrder
            innerRef={refInput}
            placeholder={intl.formatMessage({
              id: "widgets.placeOrderDer.inputTicker",
            })}
            value={textDisplay ? currentTicker : textSearch}
            onChange={onSearchTicker}
            onKeyDown={handleKeyDown}
            isSearch
            onFocus={() => {
              setVisibleTextSearch(true);
              setTextDisplay(false);
            }}
            onBlur={() => {
              setTextDisplay(true);
              if (!isChangeArrow) {
                setTimeout(() => setVisibleTextSearch(false), 150);
              }
              handleSelectTicker();
            }}
          />
          {(visibleTextSearch || textSearch) && (
            <TickerContainer onScroll={handleScroll}>
              {tickerSort.slice(0, numberLoadmore).map((item, index) => (
                <SelectItem
                  key={index}
                  setFocus={setFocus}
                  index={index}
                  focus={focus === index}
                  data={item}
                  setVisibleTextSearch={setVisibleTextSearch}
                  setTextSearch={setTextSearch}
                  setTextDisplay={setTextDisplay}
                  setIsChangeArrow={setIsChangeArrow}
                  setIsClickOutSide={setIsClickOutSide}
                  setTickerSelect={setTickerSelect}
                />
              ))}
            </TickerContainer>
          )}
        </TickerWrappper>
      </RowSearch>
      {errorCurrentTicker !== "" && !visibleTextSearch && (
        <RowSearch>
          <div className="flex-4 text-danger text-center mt-2">
            {errorCurrentTicker}
          </div>
          <div className="flex-1" />
        </RowSearch>
      )}
      <Spacer size="s" />
      <div className="d-flex align-items-end">
        <div className="mr-2 code text-white-neutral1">{currentTicker}</div>
        <div className="flex-fill name">
          {ticker?.secname || ticker?.secnameen}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <TextColor
          color={
            backgroundColor
              ? "textcolorWhite"
              : Colors[tickerInfoLastSale?.colorcode]
          }
          size="big"
          backgroundColor={backgroundColor}
          className="mr-2"
          onClick={() => updatePrice(tickerInfoLastSale?.lastprice!)}
        >
          {numeral(tickerInfoLastSale?.lastprice!).format("0,000.0")}
        </TextColor>
        <div className="flex-fill mr-1">
          <TextColor
            color={
              backgroundColor
                ? "textcolorWhite"
                : Colors[tickerInfoLastSale?.colorcode]
            }
            size="small"
            backgroundColor={backgroundColor}
          >
            {tickerInfoLastSale?.changepoint! > 0 && "+"}
            {numeral(tickerInfoLastSale?.changepoint!).format("0.00")}
          </TextColor>
          <TextColor
            color={
              backgroundColor
                ? "textcolorWhite"
                : Colors[tickerInfoLastSale?.colorcode]
            }
            size="small"
            backgroundColor={backgroundColor}
          >
            {tickerInfoLastSale?.changepercent! > 0 && "+"}
            {numeral(tickerInfoLastSale?.changepercent).format("0.00")}%
          </TextColor>
        </div>
        <div className="ml-4">
          <div className="tran text-uppercase">
            <FormattedMessage id="widgets.placeOrder.ceilingPrice" />
          </div>
          <div
            className="gia-tran violet"
            onClick={() => {
              updatePrice(quotesForDertOrder?.ceilingPrice!);
            }}
          >
            {numeral(quotesForDertOrder?.ceilingPrice!).format("0,000.0")}
          </div>
        </div>
        <div className="mx-4">
          <div className="tran text-uppercase">
            <FormattedMessage id="widgets.placeOrder.floorPrice" />
          </div>
          <div
            className="gia-tran blue"
            onClick={() => {
              updatePrice(quotesForDertOrder?.floorPrice!);
            }}
          >
            {numeral(quotesForDertOrder?.floorPrice!).format("0,000.0")}
          </div>
        </div>
        <div>
          <div className="tran text-uppercase">
            <FormattedMessage id="widgets.placeOrder.referencePrice" />
          </div>
          <div
            className="gia-tran yellow"
            onClick={() => {
              updatePrice(quotesForDertOrder?.basicPrice!);
            }}
          >
            {numeral(quotesForDertOrder?.basicPrice!).format("0,000.0")}
          </div>
        </div>
      </div>
    </SearchTicker>
  );
});

export default TickerSearch;
