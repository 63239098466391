import styled from "styled-components/macro";

export const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{
  hide?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 8px;
  // border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  // border: 2px solid ${({ theme }) => theme.bgElevated3};
  // overflow: hidden;
`;

export const WrapLeft = styled.div<{
  hide?: boolean;
}>`
  width: ${({ hide }) => (hide ? "100%" : "70%")};
`;

export const LabelLeft = styled.div<{
  hide?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  font-size: 15px;
  background: ${({ theme }) => theme.background3};
  height: ${({ theme }) => theme.cardHeader.height};
  // box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};

  // p {
  //   margin: 0;
  //   /* padding: 0; */
  //   font-size: ${({ theme }) => theme.typeFaces.headline.size};
  //   font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  //   line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  //   color: ${({ theme }) => theme.grey0};
  //   padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  // }
`;

export const BodyLeft = styled.div`
  height: calc(100% - ${({ theme }) => theme.cardHeader.height});
  border-border: 8px;
`;

export const WrapRight = styled.div<{
  hide?: boolean;
}>`
  width: ${({ hide }) => (hide ? "0%" : "30%")};
  height: 100%;
`;
