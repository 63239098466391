/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Wrap } from "./styles";
import { useIntl } from "react-intl";
import { statusOptions } from "modules/confirmation/pages/constants";
import { useMemo } from "react";

interface Form {
  confirmStatus: number;
  fromDate: Date;
  toDate: Date;
}

interface PROPS {
  form: Form;
  changeForm: (value: any, key: string) => void;
  submitForm: () => void;
  loading: boolean;
}

const Filter = (props: PROPS) => {
  const { changeForm, form, submitForm, loading } = props;
  const intl = useIntl();
  const statusOption = useMemo(() => statusOptions(intl), [intl]);

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex p-0">
        <FilterItem
          filterName={intl.formatMessage({
            id: "confirmationOrder.search.fromDate.label",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={form.fromDate}
            onChange={(e: any) => changeForm(e, "fromDate")}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "confirmationOrder.search.toDate.label",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={form.toDate}
            onChange={(e: any) => changeForm(e, "toDate")}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "confirmationOrder.search.status.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={statusOption[0]}
            options={statusOption}
            onChange={(e) => changeForm(e?.value, "confirmStatus")}
          />
        </FilterItem>
        <Button
          color="accept"
          className="button-filter"
          loading={loading}
          onClick={() => submitForm()}
        >
          {intl.formatMessage({
            id: "confirmationOrder.search.button",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
};

export default Filter;
