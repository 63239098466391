import { ReactComponent as IconOpenExtra } from "assets/image/svg/ic_open_extra.svg";
import { ReactComponent as IconCloseExtra } from "assets/image/svg/ic_close_extra.svg";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Header } from "./components";
import React, { useMemo, useRef, useState } from "react";
import IndexWidgets from "./components/Header/indexWidgets";
import { useDispatch, useSelector } from "react-redux";
import {
  visibleSideBarSelector,
  isOpenExtraSelector,
  visibleIconExtraSelector,
  visibleContainerExtraSelector,
  tabExtraActiveSelector,
} from "modules/system/selectors";
import Draggable, {
  DraggableData,
  DraggableEventHandler,
} from "react-draggable";
import {
  Container,
  Body,
  Article,
  Icon,
  ContainerExtra,
  Title,
  Label,
  ButtonExit,
  Content,
  OpenExtra,
  MenuExtra,
  ItemExtra,
} from "./styles";
import {
  setIsOpenExtra,
  setTabExtraActive,
  setVisibleContainerExtra,
  setVisibleIconExtra,
} from "modules/system/redux/common";
import PlaceOrder from "components/widgets/generals/PlaceOrder";
import { FormattedMessage } from "react-intl";
import { MenuExtraTab, VisibleIndex } from "helper/consts";
import PlaceOrderDer from "components/widgets/generals/PlaceOrderDer";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import { toggleModalLogin } from "modules/auth/redux";

const MainLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const displaySideBarIndex = useSelector(visibleSideBarSelector);
  const isOpenExtra = useSelector(isOpenExtraSelector);
  const visibleIconExtra = useSelector(visibleIconExtraSelector);
  const visibleContainerExtra = useSelector(visibleContainerExtraSelector);
  const tabExtraActive = useSelector(tabExtraActiveSelector);
  const [isDragging, setIsDragging] = useState(false);

  const handleChangeIsOpenExtra = () => {
    if (!isDragging) {
      dispatch(setIsOpenExtra(!isOpenExtra));
    }
  };

  const modalHeadedRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const offsetLeftHeaderModal = useMemo(() => {
    return modalHeadedRef?.current?.offsetLeft || 0;
  }, [modalHeadedRef?.current]);

  const offsetTopHeaderModal = useMemo(() => {
    return modalHeadedRef?.current?.offsetTop || 0;
  }, [modalHeadedRef?.current]);

  const offsetHeightHeaderModal = useMemo(() => {
    return (
      modalHeadedRef?.current?.getElementsByClassName(
        "custom-modal-header"
      )?.[0]?.clientHeight || 0
    );
  }, [modalHeadedRef?.current]);

  const offsetWidthHeaderModal = useMemo(() => {
    return modalHeadedRef?.current?.offsetWidth || 0;
  }, [modalHeadedRef?.current]);

  const windowInnerWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  const windowInnerHeight = useMemo(() => {
    return window.innerHeight;
  }, []);

  const boundLeft = useMemo(
    () => -offsetLeftHeaderModal - offsetWidthHeaderModal,
    [offsetLeftHeaderModal, offsetWidthHeaderModal]
  );
  const boundRight = useMemo(
    () =>
      windowInnerWidth -
      (offsetLeftHeaderModal + offsetWidthHeaderModal) +
      offsetWidthHeaderModal,
    [windowInnerWidth, offsetLeftHeaderModal, offsetWidthHeaderModal]
  );
  const boundTop = useMemo(() => -offsetTopHeaderModal, [offsetTopHeaderModal]);
  const boundBottom = useMemo(
    () => windowInnerHeight - (offsetTopHeaderModal + offsetHeightHeaderModal),
    [windowInnerHeight, offsetTopHeaderModal, offsetHeightHeaderModal]
  );

  const onStopHandler: DraggableEventHandler = (
    e: any,
    data: DraggableData
  ) => {
    let tempX = data.lastX;
    let tempY = data.lastY;
    if (data.lastY > boundBottom) {
      tempY = 0;
    }
    if (data.lastY < boundTop) {
      tempY = boundTop;
    }
    if (data.lastX > boundRight) {
      tempX = boundRight;
    }
    if (data.lastX < boundLeft) {
      tempX = boundLeft;
    }

    setPosition({ x: tempX, y: tempY });
    if (e.type === "mouseup" || e.type === "touchend") {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  const eventControl = (event: any) => {
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragging(true);
    }
  };

  const handleChoosePlaceOrder = (value: MenuExtraTab) => {
    if (isAuthenticated === false) {
      dispatch(toggleModalLogin(true));
    }

    dispatch(setTabExtraActive(value));
    dispatch(setVisibleIconExtra(false));
    dispatch(setVisibleContainerExtra(true));
  };

  const handleCloseContainerExtra = () => {
    dispatch(setIsOpenExtra(!isOpenExtra));
    dispatch(setVisibleIconExtra(true));
    dispatch(setVisibleContainerExtra(false));
  };

  return (
    <Container>
      <Header />
      {displaySideBarIndex % 3 !== VisibleIndex.HIDDEN_ALL && <IndexWidgets />}
      <Body maxSize={displaySideBarIndex % 3}>
        <Article>{children}</Article>
        {visibleContainerExtra && isAuthenticated && (
          <ContainerExtra>
            <Title>
              <Label>
                <FormattedMessage id="common.titleExtra" />
              </Label>
              <ButtonExit>
                <IconExit onClick={handleCloseContainerExtra} />
              </ButtonExit>
            </Title>
            <Content>
              {tabExtraActive === MenuExtraTab.PLACE_ORDER && <PlaceOrder />}
              {tabExtraActive === MenuExtraTab.PLACE_ORDER_DER && (
                <PlaceOrderDer />
              )}
            </Content>
          </ContainerExtra>
        )}
      </Body>
      {visibleIconExtra && (
        <Draggable
          handle=".custom-modal-header"
          grid={[1, 1]}
          onDrag={eventControl}
          onStop={onStopHandler}
          position={position}
        >
          <Icon className="custom-modal-header" ref={modalHeadedRef}>
            {isOpenExtra ? (
              <OpenExtra>
                <MenuExtra>
                  <ItemExtra
                    onClick={() =>
                      handleChoosePlaceOrder(MenuExtraTab.PLACE_ORDER)
                    }
                  >
                    <FormattedMessage id="common.placeOrderExtra" />
                  </ItemExtra>
                  <ItemExtra
                    onClick={() =>
                      handleChoosePlaceOrder(MenuExtraTab.PLACE_ORDER_DER)
                    }
                  >
                    <FormattedMessage id="common.placeOrderDerExtra" />
                  </ItemExtra>
                </MenuExtra>
                <IconCloseExtra onClick={handleChangeIsOpenExtra} />
              </OpenExtra>
            ) : (
              <IconOpenExtra onClick={handleChangeIsOpenExtra} />
            )}
          </Icon>
        </Draggable>
      )}
    </Container>
  );
};

export default MainLayout;
