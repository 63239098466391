import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import { getMarginSecuritiesList } from "core/http/apis/margin-securities";
import { IFindMarginSecuritiesParams } from "core/http/apis/margin-securities/types";
import { IMarginSecuritiesResponse } from "domain/models/MarginSecurities";
import {
  searchMarginSecuritiesFailure,
  searchMarginSecuritiesRequest,
  searchMarginSecuritiesSuccess,
  getCustGroupFailure,
  getCustGroupRequest,
  getCustGroupSuccess,
} from "./redux";
import { IGetMarginContractParams } from "core/http/apis/margin-contract/types";
import { IMarginContractResult } from "domain/models/MarginContract";
import { getListMarginContract } from "core/http/apis/margin-contract";

function* searchMarginSecuritiesWorker(
  action: ReturnType<typeof searchMarginSecuritiesRequest>
) {
  try {
    const params: IFindMarginSecuritiesParams = action.payload;
    const response: IMarginSecuritiesResponse = yield call(
      getMarginSecuritiesList,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchMarginSecuritiesSuccess(response));
    } else {
      yield put(searchMarginSecuritiesFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchMarginSecuritiesFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchMarginSecuritiesWatcher() {
  yield takeLatest(
    searchMarginSecuritiesRequest.type,
    searchMarginSecuritiesWorker
  );
}

function* getCustGroupWorker(action: ReturnType<typeof getCustGroupRequest>) {
  try {
    const params: IGetMarginContractParams = action.payload;
    const response: IMarginContractResult = yield call(
      getListMarginContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(
        getCustGroupSuccess({
          custGroupId: response?.data?.[0]?.custGroupId
            ? response?.data?.[0]?.custGroupId?.toString()
            : "",
          custGroupName: response?.data?.[0]?.custGroupName ?? "",
        })
      );
    } else {
      yield put(getCustGroupFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getCustGroupFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getCustGroupWatcher() {
  yield takeLatest(getCustGroupRequest.type, getCustGroupWorker);
}

export default function* marginSecuritiesSaga() {
  yield all([searchMarginSecuritiesWatcher(), getCustGroupWatcher()]);
}
