import { all } from "redux-saga/effects";
import orderBookDer from "./OrderBookDer";
import placeOrderDer from "./PlaceOrderDer";
import priceListWidget from "./priceListSaga";
import volumeAndTimeWidgetSaga from "./volumeAndTimeWidgetSaga";

export default function* stockOrderSaga() {
  yield all([
    placeOrderDer(),
    orderBookDer(),
    priceListWidget(),
    volumeAndTimeWidgetSaga(),
  ]);
}
