import { PercentValue, ProgressContainer } from "./styles";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { percentBuySelector } from "modules/stock-order-der/redux/selectors";

function ProgressBuySell() {
  const percentBuy = useSelector(percentBuySelector);
  const percentSell = 100 - percentBuy;

  return (
    <ProgressContainer percentBuy={percentBuy}>
      <PercentValue>{numeral(percentBuy).format("0.00")}%</PercentValue>
      <PercentValue>{numeral(percentSell).format("0.00")}%</PercentValue>
    </ProgressContainer>
  );
}

export default ProgressBuySell;
