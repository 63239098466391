import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const Container = styled.div`
  tr {
    cursor: pointer;
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) =>
    color === "red"
      ? theme.red50
      : color === "white"
      ? theme.textcolorWhite
      : theme.green50};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.grey30};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(37, 49, 71);
    border-radius: 4px;
    padding: 8px 12px;
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    z-index: 1px;
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export const ContentText = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.span`
  cursor: pointer;
`;
