// import HttpManagerDevice from "core/http/singleton/manager-device";
import VgaiaHttp from "core/http/singleton/vgaia";
import queryString from "query-string";

interface IAdvertisingOrder {
  data: any;
  errorCode: string;
  errorField: string;
  message: string;
  statusCode: number;
}

export const findAllDbondAdvApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params);
  const res = await VgaiaHttp.get<IAdvertisingOrder>(
    `/findAllDbondAdv${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findCbotBondPriceEstApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params);
  const res = await VgaiaHttp.get<IAdvertisingOrder>(
    `/findCbotBondPriceEst${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
