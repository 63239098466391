import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ChartData = {
  labels: string[];
  values: number[];
  colors: string[];
};

export type OrderFlowDistributionState = {
  dataInflow: ChartData;
  dataOutflow: ChartData;
  dataPieChartFlow: ChartData;
  loading: boolean;
  error: string | undefined;
  totalInflow: number | undefined;
  totalOutflow: number | undefined;
};

export interface ISecDividendInfoFormatter {
  dataInflow: ChartData;
  dataOutflow: ChartData;
  dataPieChartFlow: ChartData;
  totalInflow: number;
  totalOutflow: number;
}

// TODO
const initialState: OrderFlowDistributionState = {
  dataInflow: {
    values: [],
    colors: [],
    labels: [],
  },
  dataOutflow: {
    values: [],
    colors: [],
    labels: [],
  },
  dataPieChartFlow: {
    values: [],
    colors: [],
    labels: [],
  },
  loading: false,
  error: undefined,
  totalInflow: 0,
  totalOutflow: 0,
};

const orderFlowDistributionSlice = createSlice({
  name: "orderFlowDistributionSlice",
  initialState,
  reducers: {
    fetchOrderFlowDistribution: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    fetchOrderFlowDistributionSuccess: (
      state,
      action: PayloadAction<ISecDividendInfoFormatter | null>
    ) => {
      const { payload } = action;
      state.loading = false;
      if (!payload) return;

      state.dataInflow = payload.dataInflow;
      state.dataOutflow = payload.dataOutflow;
      state.dataPieChartFlow = payload.dataPieChartFlow;
      state.totalInflow = payload.totalInflow;
      state.totalOutflow = payload.totalOutflow;
    },
    fetchOrderFlowDistributionFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchOrderFlowDistribution,
  fetchOrderFlowDistributionSuccess,
  fetchOrderFlowDistributionFailure,
} = orderFlowDistributionSlice.actions;

export default orderFlowDistributionSlice.reducer;
