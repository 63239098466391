import { createSelector } from "@reduxjs/toolkit";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
}

const buyforeignqtySelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.buyforeignqty
  );
};

function BuyforeignqtyCell({ ticker }: Props) {
  const buyforeignqty = useSelector(buyforeignqtySelector(ticker)) || 0;

  return (
    <VolumeCell className="bid" volume={buyforeignqty} keepRawValue={false} />
  );
}

export default BuyforeignqtyCell;
