import styled from "styled-components/macro";
export const ClickableBox = styled.div<{
  active: boolean;
  size?: "small" | "medium";
}>`
  cursor: pointer;
  color: ${({ theme, active }) => (active ? theme.brandPrimary : theme.grey20)};
  ${({ active, theme }) => active && `border: 1px solid ${theme.brandPrimary};`}
  background: ${({ active, theme }) => (active ? theme.grey80 : theme.grey70)};
  box-sizing: border-box;
  border-radius: 4px;
  height: ${({ theme }) => theme.headerButton.height};
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.header.weight.regular};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ size, theme }) =>
    size === "small" &&
    `font-size: ${theme.typeFaces.caption12.size};
    padding: 4px;
    height: 24px;
    line-height: ${theme.typeFaces.caption12.lineHeight};
    color: ${theme.textcolorWhite};`}
`;
