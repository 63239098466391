import { Button } from "components/commons";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Body, Container, Footer, Title } from "./styles";

interface Props {
  visibleModalConfirm?: boolean;
  title?: string;
  content?: string;
  handleCloseModal?: () => void;
  size?: any;
}

function ModalNotification({
  visibleModalConfirm,
  title,
  content,
  handleCloseModal,
  size,
}: Props) {
  const closeModalConfirm = () => {
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  return (
    <Modal
      show={visibleModalConfirm}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-434"
    >
      <Container>
        <Title>{title?.toUpperCase()}</Title>
        <Body>{content}</Body>
        <Footer>
          <Button color="accept" className="flex-1" onClick={closeModalConfirm}>
            <FormattedMessage id="addFrameworkContractForm.createContract.close" />
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
}

export default ModalNotification;
