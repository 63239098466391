import { useDispatch, useSelector } from "react-redux";
import {
  ContentContainer,
  ChartContainer,
  MarketLineChartWrapper,
} from "./styles";
import { useIntl } from "react-intl";
import { getLiquidityData } from "modules/market/redux/liquidity";
import { useEffectOnce, useInterval } from "react-use";
import MarketLineChart from "components/commons/Charts/MarketLineChart";
import { liquidityDataSelector } from "modules/market/redux/selectors";
import { ChartOptions, ChartTooltipModel } from "chart.js";
import {
  createElementFromHTML,
  div,
  updateDomStyle,
} from "helper/domBuilder/utils";
import render from "helper/domBuilder";
import themes from "themes/abstracts/_themes";
import numeral from "numeral";
import isEmpty from "lodash/isEmpty";
import { getChartHtmlTooltipPosition } from "helper/utils";
import { themeTypeSelector } from "themes/redux";
import { SystemState } from "domain/protoNew/auto_trading_pb";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

function formatValueTooltip(
  dataPoint: any,
  index: number,
  dataset: any
): string {
  if (dataPoint.index == null) {
    return "";
  }
  return numeral(dataset.data[dataPoint.index]).format("0,0");
}

function Liquidity() {
  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const intl = useIntl();

  const liquidityData = useSelector(liquidityDataSelector);

  useInterval(() => {
    dispatch(getLiquidityData());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    dispatch(getLiquidityData());
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(getLiquidityData());
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  });

  const styleChildren = {
    color: themes[themeType].textcolorWhite,
    "font-size": "12px",
    "line-height": "16px",
    "font-weight": "normal",
  };

  const option: ChartOptions = {
    legend: {
      display: true,
      position: "top",
      reverse: true,
      labels: {
        fontFamily: themes[themeType].fontFamily,
        fontSize: 11,
        fontColor: themes[themeType].grey30,
        usePointStyle: true,
        padding: 20,
      },
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: themes[themeType].grey30,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2, 4],
            color: themes[themeType].background2,
          },
          ticks: {
            maxTicksLimit: 6,
            fontColor: themes[themeType].grey30,
            callback: function (label: any, index: number, labels: any) {
              if (isEmpty(liquidityData.datasets)) {
                return "";
              }
              return `${numeral(label).format("0.0a")}`;
            },
          },
        },
      ],
    },
    onHover: function (evt, elements) {
      let tooltipEl = document.getElementById("chartjs-tooltip");
      if (elements && elements.length) {
        return;
      }
      if (tooltipEl) {
        updateDomStyle(tooltipEl, {
          opacity: "0",
        });
      }
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel: ChartTooltipModel) {
        let tooltipEl = document.getElementById("chartjs-tooltip");
        let chart = (this as any)._chart;

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = createElementFromHTML(
            render(
              div({
                id: "chartjs-tooltip",
                children: div({
                  style: {
                    "font-family": themes[themeType].fontFamily,
                    color: themes[themeType].textcolorWhite,
                    background: "rgba(40, 54, 72, 0.8)",
                    "border-radius": "4px",
                    border: "px solid black",
                    "min-width": "200px",
                    padding: "12px",
                    "margin-top": "22px",
                    "margin-left": "-20px",
                  },
                  children: "",
                }),
              })
            )
          ) as HTMLElement;
          document.body.appendChild(tooltipEl);
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          updateDomStyle(tooltipEl, {
            opacity: "0",
          });
          return;
        }
        // Remove all class and set x, y align as class
        tooltipEl.classList.remove(
          "market-line-chart-tooltip",
          "above",
          "below",
          "no-transform",
          "top",
          "bottom",
          "center",
          "left",
          "right"
        );
        tooltipEl.className = "";
        tooltipEl.classList.add("market-line-chart-tooltip");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        if (tooltipModel.xAlign) {
          tooltipEl.classList.add(tooltipModel.xAlign);
        }
        // Set Text
        if (tooltipModel.body) {
          let dataPoint = tooltipModel.dataPoints[0];
          if (!dataPoint) return;
          const bodyElements = liquidityData?.datasets.map(
            (dataset: any, index: number) => {
              return div({
                style: {
                  display: "flex",
                  "min-width": "260px",
                  "justify-content": "space-between",
                  color: themes[themeType].textcolorWhite,
                  "font-weight": "400",
                  "font-size": "12px",
                },
                children: [
                  div({
                    children: intl.formatMessage({ id: dataset.label }),
                    style: {
                      ...styleChildren,
                    },
                  }),
                  div({
                    children: `${formatValueTooltip(
                      dataPoint,
                      index,
                      dataset
                    )}${intl.formatMessage({ id: "common.million" })}`,
                    style: {
                      ...styleChildren,
                    },
                  }),
                ],
              });
            }
          );

          const tooltipElement = div({
            children: [...bodyElements!],
          });

          let root = tooltipEl.children[0];
          root.innerHTML = render(tooltipElement);
        }

        const { top, left } = getChartHtmlTooltipPosition(
          chart,
          tooltipEl,
          tooltipModel,
          24,
          8
        );

        updateDomStyle(tooltipEl, {
          opacity: "1",
          position: "absolute",
          "pointer-events": "none",
          left: left + "px",
          top: top + "px",
          "font-family": themes[themeType].fontFamily,
          "font-size": tooltipModel.bodyFontSize + "px",
          "font-style": tooltipModel._bodyFontStyle,
          padding: tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px",
        });
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <ContentContainer>
      <ChartContainer>
        <MarketLineChartWrapper>
          <MarketLineChart dataChart={liquidityData} option={option} />
        </MarketLineChartWrapper>
      </ChartContainer>
    </ContentContainer>
  );
}

export default Liquidity;
