import React, { memo } from "react";
import styled from "styled-components/macro";

export type SizeType = "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl";

export const Size: { [key in SizeType]: number } = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 12,
  l: 16,
  xl: 24,
  xxl: 32,
};

const SpacerContainer = styled.div``;

interface Props {
  size?: SizeType;
}
function Spacer({ size = "l" }: Props) {
  return (
    <SpacerContainer
      style={{
        width: `${Size[size]}px`,
        height: `${Size[size]}px`,
      }}
    />
  );
}

export default memo(Spacer);
