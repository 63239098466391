import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { createGradientPlugin } from "helper/chartPlugins";

const GradientBgPlugin: any = createGradientPlugin("rgba(8, 202, 152, 0.2)");

// NOTE: values, colors and labels must not be memoized before passing to this component
export type FlowChartProps = {
  values: number[];
  colors: string[];
  labels: string[];
};

const FlowChart: React.FC<FlowChartProps> = (props: FlowChartProps) => {
  const { values, colors, labels } = props;
  const plugins = [GradientBgPlugin, ChartDataLabels];

  const data = {
    labels,
    datasets: [
      {
        data: [...values],
        categoryPercentage: 0.35,
        backgroundColor: [...colors],
        borderColor: [...colors],
      },
    ],
  };

  const options = {
    cornerRadius: 4,
    layout: {
      padding: {
        top: 20,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          return tooltipItem.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            tickMarkLength: 0,
            display: false,
            borderDash: [8, 8],
            color: "rgba(206, 209, 214, 0.1)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#ADB3C0",
          },
          categoryPercentage: 0.5,
        },
      ],
    },
    plugins: {
      datalabels: {
        display: true,
        color: (context: Context) => {
          const value = context.dataset.backgroundColor;
          return value;
        },
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        anchor: "end",
        align: "top",
      },
    },
    responsive: true,
  } as unknown as ChartOptions;

  return <Bar options={options} data={data} plugins={plugins} />;
};

export default FlowChart;
