import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";

const pubQtySelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) => state?.[realTimeId]?.pubQty
  );
};

interface Props {
  realTimeId: string;
}

const PubQtyCell = ({ realTimeId }: Props) => {
  const pubQty = useSelector(pubQtySelector(realTimeId));
  return (
    <>
      <BlinkableCell value={pubQty ? numeral(pubQty).format("0,0") : 0} />
    </>
  );
};

export default PubQtyCell;
