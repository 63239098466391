import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { AuthState } from "modules/auth/redux";
import { TradingAccountState } from "modules/auth/redux/tradingAccount";
import { ModalEditGeneralInfoState } from "modules/auth/redux/modalEditGeneralInfo";
import { ManagerDevice } from "./redux/managerDevice";
import { ServiceEmailSms } from "./redux/serviceEmailSms";

export const authSelector = (state: RootState) => state.auth.root.data;
export const authRootSelector = (state: RootState) => state.auth.root;
export const modalEditGeneralSelector = (state: RootState) =>
  state.auth.modalEditGeneralInfo;
export const authState = (state: RootState) => state.auth.root.data;
export const tradingAccountSelector = (state: RootState) =>
  state.auth.tradingAccount;
export const managerDeviceSelector = (state: RootState) =>
  state.auth.managerDevice;
export const serviceEmailSmsSelector = (state: RootState) =>
  state.auth.serviceEmailSms;

export const errorLoginSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.errorLogin
);

export const isLoginErrorSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.isLoginError
);

export const storageOTPCodeSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.storageOTPCode
);

export const accountInfoSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.userInfo
);

export const infoAccountSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.accountInfo
);

export const tradeDateSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.userInfo.tradeDate
);

export const foreignTypeSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.userInfo.currentCustomer.foreignType
);

export const authenTypeSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.authenType
);

export const currentAccountSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.currentAccount
);

export const isAuthenticatedSelector = createSelector(
  authSelector,
  (state) => state.isAuthenticated
);

export const visibleModalChangePasswordSelector = createSelector(
  authSelector,
  (state) => state.visibleModalChangePassword
);

export const visibleModalChangePasswordSuccessSelector = createSelector(
  authSelector,
  (state) => state.visibleModalChangePasswordSuccess
);

export const otpModalChangePasswordSelector = createSelector(
  authSelector,
  (state) => state.otpModalChangePassword
);

export const visibleModalForgetPasswordSelector = createSelector(
  authSelector,
  (state) => state.statusModalForgetPassword
);

export const defauAccountForgetPasswordSelector = createSelector(
  authSelector,
  (state) => state.defauAccountForgetPassword
);

export const statusModalLoginSelector = createSelector(
  authSelector,
  (state) => state.statusModalLogin
);

export const statusSelector = createSelector(
  authRootSelector,
  (state) => state.status
);

export const hotNewsSelector = (state: RootState) => state.auth.hotNews;

export const listHotNewsSelector = createSelector(
  hotNewsSelector,
  (state) => state.listHotNews
);

export const disableHotNewsSelector = createSelector(
  hotNewsSelector,
  (state) => state.disableHotNew
);

export const visibleModalInfoSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.visibleModalInfo
);

export const visibleModalSettingSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.visibleModalSetting
);

export const tabActiveSettingSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.tabActiveSetting
);

export const visibleModalEditGeneralInfoSelector = createSelector(
  modalEditGeneralSelector,
  (modalEditGeneral: ModalEditGeneralInfoState) =>
    modalEditGeneral.visibleModalEditGeneralInfo
);

export const changeInfoParamsSelector = createSelector(
  modalEditGeneralSelector,
  (modalEditGeneral: ModalEditGeneralInfoState) =>
    modalEditGeneral.changeInfoParams
);

export const visibleModalFaceVerifySelector = createSelector(
  modalEditGeneralSelector,
  (modalEditGeneral: ModalEditGeneralInfoState) =>
    modalEditGeneral.visibleModalFaceVerify
);

export const isResetSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.isReset
);

export const selectCustType = createSelector(
  authSelector,
  (authState: AuthState) => authState.userInfo.currentCustomer.custType
);

export const visibleModalTradingAccountSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.visibleModalTradingAccount
);

export const listingAccountBeneficiarySelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.listBeneficiary
);

export const loadingRegisterSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.loadingRegister
);

export const loadingOneTimeAuthenSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.loadingOneTimeAuthen
);

export const statusRegisterOtpTypeSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.statusRegisterOtpType
);

export const statusOneTimeAuthenSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.statusOneTimeAuthen
);

export const isRegisterActionSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.isRegisterAction
);

export const visibleModalForceAdditionAccountSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.visibleModalForceAdditionAccount
);

export const errorRegisterSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.errorRegister
);

export const visibleModalAddAccountSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState.visibleModalAddAccount
);

export const listBankNameSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState?.listBankName
);

export const loadingAddSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) => tradingAccountState?.loadingAdd
);

export const isAlreadyRegisterAuthenFor134Selector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState?.isAlreadyRegisterAuthenFor134
);

export const statusSetDefaultSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState?.statusSetDefault
);

export const errorSetDefaultSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState?.errorSetDefault
);

export const statusAddAccSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState?.statusAddAcc
);

export const statusDelAccSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) =>
    tradingAccountState?.statusDelAcc
);

export const errorAddAccSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) => tradingAccountState?.errorAddAcc
);

export const errorDelAccSelector = createSelector(
  tradingAccountSelector,
  (tradingAccountState: TradingAccountState) => tradingAccountState?.errorDelAcc
);
export const getListDeviceSelector = createSelector(
  managerDeviceSelector,
  (ManagerDeviceState: ManagerDevice) => ManagerDeviceState?.listDevice
);
export const loadingSelector = createSelector(
  managerDeviceSelector,
  (ManagerDeviceState: ManagerDevice) => ManagerDeviceState?.loading
);
export const loadingSearchServiceSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.loadingSearch
);
export const loadingSubmitServiceSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.loadingSubmit
);
export const customerSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.customer
);
export const getServiceStatusOfCustomerSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.statusService
);
export const getStatusCodeSmsSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.statusCode
);
export const getSmsDataSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.smsData
);
export const getEmailDataSelector = createSelector(
  serviceEmailSmsSelector,
  (ServiceEmailSmsState: ServiceEmailSms) => ServiceEmailSmsState?.emailData
);

export const visibleModalRegisterSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.visibleModalRegister
);

export const selectUserInfo = createSelector(
  authSelector,
  (authState: AuthState) => authState.userInfo
);

export const selectVisibleModalEditPassFirst = createSelector(
  authSelector,
  (authState: AuthState) => authState.visibleModalEditPassFirst
);
