import { careFeedback, uploadIdCard } from "./urls";
import HttpFeedback from "../../singleton/feedback";

export const postCareFeedback = async (data: FormData): Promise<number> => {
  const res = await HttpFeedback.post<number>(careFeedback, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postUploadIdCard = async (data: FormData): Promise<any> => {
  const res = await HttpFeedback.post<any>(uploadIdCard, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
