import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IParameterBankDataResponse } from "domain/models/ParameterBankData";
import { ITransferContractLocalRepsonse } from "domain/models/TransferContractLocal";
import {
  ICurrentcyStrVNResponse,
  ICustomerRestrictionResponse,
  ITimeTransferResponse,
  ITransferLocalResponse,
} from "domain/models/TransferLocal";
import { ITransferLocalModelResponse } from "domain/models/TransferLocalModel";
import {
  ISearchTransferLocalParams,
  ISearchTransferLocalHisParams,
  IInquiryAccountTraParams,
  IFindTransferContractLocalParams,
  IFindParameterBankDetailParams,
  ISubmitTransferParams,
  IGetSubAccToLocalListParams,
  IGetCustomerRestrictionParams,
  IGetCurrencyStrVNParams,
  ICheckTimeTransferParams,
} from "./types";
import {
  searchTransferLocalApi,
  searchTransferLocalHisApi,
  findParameterBankDetailApi,
  findTransferContractLocalApi,
  inquiryAccountTraApi,
  submitTransferApi,
  getSubAccToLocalListApi,
  checkTimeTransferApi,
  getCustomerRestrictionApi,
  getCurrencyStrVNApi,
} from "./urls";

export const searchTransferLocal = async (
  params: ISearchTransferLocalParams
): Promise<ITransferLocalResponse> => {
  const res = await VgaiaHttp.get<ITransferLocalResponse>(
    searchTransferLocalApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const searchTransferLocalHis = async (
  params: ISearchTransferLocalHisParams
): Promise<ITransferLocalResponse> => {
  const res = await VgaiaHttp.post<ITransferLocalResponse>(
    searchTransferLocalHisApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const inquiryAccountTra = async (
  params: IInquiryAccountTraParams
): Promise<ITransferLocalModelResponse> => {
  const res = await VgaiaHttp.post<ITransferLocalModelResponse>(
    inquiryAccountTraApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findTransferContractLocal = async (
  params: IFindTransferContractLocalParams
): Promise<ITransferContractLocalRepsonse> => {
  const res = await VgaiaHttp.post<ITransferContractLocalRepsonse>(
    findTransferContractLocalApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findParameterBankDetail = async (
  params: IFindParameterBankDetailParams
): Promise<IParameterBankDataResponse> => {
  const res = await VgaiaHttp.get<IParameterBankDataResponse>(
    findParameterBankDetailApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const submitTransfer = async (
  params: ISubmitTransferParams
): Promise<ITransferLocalResponse> => {
  const res = await VgaiaHttp.post<ITransferLocalResponse>(
    submitTransferApi(params),
    null,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getSubAccToLocalList = async (
  params: IGetSubAccToLocalListParams
): Promise<IBasePairValueResponse> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getSubAccToLocalListApi(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const checkTimeTransfer = async (
  params: ICheckTimeTransferParams
): Promise<ITimeTransferResponse> => {
  const res = await VgaiaHttp.post<ITimeTransferResponse>(
    checkTimeTransferApi(params),
    null,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCurrencyStrVN = async (
  params: IGetCurrencyStrVNParams
): Promise<ICurrentcyStrVNResponse> => {
  const res = await VgaiaHttp.post<ICurrentcyStrVNResponse>(
    getCurrencyStrVNApi(params),
    null,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getCustomerRestriction = async (
  params: IGetCustomerRestrictionParams
): Promise<ICustomerRestrictionResponse> => {
  const res = await VgaiaHttp.get<ICustomerRestrictionResponse>(
    getCustomerRestrictionApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
