import { FC } from "react";
import { WrapperTicker } from "../styles";
import { RootState } from "redux/store";
import { WatchListState } from "modules/stock-order/redux/watchList";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import ClickableTicker from "components/commons/ClickableTicker";
import { ReactComponent as IconDelete } from "assets/image/svg/ic_remove.svg";
import { removeTicker } from "modules/categories/redux";

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const textColorSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.colorcode
  );
};

interface Props {
  ticker: string;
}

const TickerCell: FC<Props> = ({ ticker }) => {
  const dispatch = useDispatch();
  const textColor = useSelector(textColorSelector(ticker));

  const handleRemoveTicker = (ticker: string) => {
    dispatch(removeTicker({ tickerName: ticker }));
  };

  return (
    <WrapperTicker textcolor={textColor}>
      <ClickableTicker ticker={ticker} />
      <div className="delete-icon-container">
        <IconDelete
          className="icon-delete"
          onClick={() => handleRemoveTicker(ticker)}
        />
      </div>
    </WrapperTicker>
  );
};

export { TickerCell };
