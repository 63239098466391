import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetListHotNewsResult } from "domain/models/HotNew";

export type HotNewsState = {
  listHotNews: GetListHotNewsResult;
  disableHotNew: boolean;
  loading: boolean;
};

const initialState: HotNewsState = {
  listHotNews: {},
  disableHotNew: true,
  loading: false,
};

const hotNewsSlice = createSlice({
  name: "hotNews",
  initialState,
  reducers: {
    getHotNews: (state) => {
      state.loading = false;
    },
    getListHotNewsSuccess: (
      state,
      action: PayloadAction<GetListHotNewsResult>
    ) => {
      state.listHotNews = action.payload;
    },
    clearHotNew: (state) => {
      state.listHotNews = {};
    },
    disableHotNew: (state, action: PayloadAction<boolean>) => {
      state.disableHotNew = action.payload;
    },
  },
});

export const {
  getHotNews,
  getListHotNewsSuccess,
  disableHotNew,
  clearHotNew,
} = hotNewsSlice.actions;

export default hotNewsSlice.reducer;
