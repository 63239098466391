import { FC } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Wrapper, FooterTable, ButtonWrapper } from "./styles";
import { ReduxData as ScreenersState } from "modules/screeners/redux";
import Table from "./Table";
import WatchListScreeners from "modules/shared/WishListStar/WatchListScreeners";

const screenersSelector = (state: RootState) => state.screeners;

const tickerListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.tickerWatchlist
);

const BodyCard: FC = () => {
  const tickerList = useSelector(tickerListSelector);

  return (
    <Wrapper>
      <Table />
      <FooterTable>
        <ButtonWrapper>
          <WatchListScreeners tickers={tickerList} />
        </ButtonWrapper>
      </FooterTable>
    </Wrapper>
  );
};

export default BodyCard;
