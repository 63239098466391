import classNames from "classnames";
import { Row, OuterRowContainer } from "./styles";
import TickerCell from "./TickerCell/Index";
import { RowStockProps } from "modules/categories/types";
import {
  columnsCoverWarrant,
  columnsCoverWarrantHead,
  WATCH_LIST_FIXED_TABS,
} from "modules/categories/constant";
import { useSelector } from "react-redux";
import { baseseccdSelector } from "modules/categories/redux/selectors";
import get from "lodash/get";
import BaseseccdCell from "./BaseseccdCell";
import BasepriceCell from "./BasepriceCell";
import ExecratioCell from "./ExecratioCell";
import LasttradedateCell from "./LasttradedateCell";
import IssuerCell from "./IssuerCell";
import CurrentroomCell from "./CurrentroomCell";
import SellforeignqtyCell from "./SellforeignqtyCell";
import BuyforeignqtyCell from "./BuyforeignqtyCell";
import AvgpriceCell from "./AvgpriceCell";
import LowestpriceCell from "./LowestpriceCell";
import HighestpriceCell from "./HighestpriceCell";
import TotalamtCell from "./TotalamtCell";
import TotalqtyCell from "./TotalqtyCell";
import LastqtyCell from "./LastqtyCell";
import ChangepercentCell from "./ChangepercentCell";
import ChangepointCell from "./ChangepointCell";
import LastpriceCell from "./LastpriceCell";
import BasicpriceCell from "./BasicpriceCell";
import FloorpriceCell from "./FloorpriceCell";
import CeilingpriceCell from "./CeilingpriceCell";
import ChartCell from "./ChartCell";
import BestqtyCell from "./BestqtyCell";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
import BestpriceCell from "./BestpriceCell";
import ExecpriceCell from "./ExecpriceCell";
import BreakEvenPointCell from "./BreakEvenPointCell";

interface StringName {
  price: keyof SymbolTotalInfo.SecDetailInfo.AsObject;
  volume: keyof SymbolTotalInfo.SecDetailInfo.AsObject;
}
export const BidStringName: StringName[] = [
  {
    price: "best1bidpricestr",
    volume: "best1bidqty",
  },
  {
    price: "best2bidprice",
    volume: "best2bidqty",
  },
  {
    price: "best3bidprice",
    volume: "best3bidqty",
  },
];
export const AskStringName: StringName[] = [
  {
    price: "best1offerpricestr",
    volume: "best1offerqty",
  },
  {
    price: "best2offerprice",
    volume: "best2offerqty",
  },
  {
    price: "best3offerprice",
    volume: "best3offerqty",
  },
];

function RowStock({
  rowNumber,
  ticker,
  style,
  sumWidth,
  askWidth,
  bidWidth,
  foreignWidth,
  priceWidth,
  lastSaleWidth,
  coverWarrantWidth,
  coverWarrantHeadWidth,
  currentListCode,
  columns,
  isFocus,
}: RowStockProps) {
  const baseseccd = useSelector(baseseccdSelector(ticker));

  const renderCoverWarrantVolumeCell = (cols: string) => {
    switch (cols) {
      case "TCPH":
        return <IssuerCell ticker={ticker} />;
      case "GDCC":
        return <LasttradedateCell ticker={ticker} />;
      case "CKCS":
        if (baseseccd === undefined) return null;
        return <BaseseccdCell ticker={baseseccd} />;
      case "PRICE_CKCS":
        if (baseseccd === undefined) return null;
        return <BasepriceCell ticker={baseseccd} />;
      case "BREAK_EVEN_POINT":
        return <BreakEvenPointCell ticker={ticker} />;
      case "TH":
        return <ExecpriceCell ticker={ticker} />;
      case "RATIO":
        return <ExecratioCell ticker={ticker} />;
      default:
        break;
    }
  };
  return (
    <OuterRowContainer style={style}>
      <Row
        isOddRowCount={rowNumber % 2 === 1}
        sumWidth={sumWidth}
        askWidth={askWidth}
        bidWidth={bidWidth}
        foreignWidth={foreignWidth}
        priceWidth={priceWidth}
        lastSaleWidth={lastSaleWidth}
        coverWarrantWidth={coverWarrantWidth}
        coverWarrantHeadWidth={coverWarrantHeadWidth}
        columns={columns}
        isFocus={isFocus}
      >
        <div
          className={classNames(
            { "remove-box-shadow": columns.CHART.visible },
            "ticker-name-col text-left ticker-div"
          )}
        >
          <TickerCell ticker={ticker} />
        </div>
        {columns.CHART.visible && (
          <div className="chart">
            <div className="inner-container">
              <ChartCell ticker={ticker} />
            </div>
          </div>
        )}
        {currentListCode === WATCH_LIST_FIXED_TABS.COVER_WARRANT &&
          columns.COVER_WARRANT_HEAD && (
            <div className="flex-content cover-warrant-head-group">
              {Object.keys(
                columnsCoverWarrantHead.COVER_WARRANT_HEAD.children!
              ).map((colKey) => {
                return (
                  <div key={colKey} className={colKey.toLowerCase()}>
                    {renderCoverWarrantVolumeCell(colKey)}
                  </div>
                );
              })}
            </div>
          )}

        {columns.SUMMARY?.children?.FLOOR?.visible && (
          <CeilingpriceCell ticker={ticker} />
        )}
        {columns.SUMMARY?.children?.CEIL?.visible && (
          <FloorpriceCell ticker={ticker} />
        )}
        {columns.SUMMARY?.children?.REFER?.visible && (
          <BasicpriceCell ticker={ticker} />
        )}

        <div className="bid-col flex-content">
          {Array.from({ length: 3 }).map((_, index) => (
            <>
              {columns.BID.children![`PRICE${3 - index}`].visible && (
                <BestpriceCell
                  ticker={ticker}
                  key={`${index}-bid-price-${ticker}`}
                  className={`price${3 - index}`}
                  priceName={BidStringName[2 - index]?.price}
                />
              )}
              {columns.BID.children![`VOLUME${3 - index}`].visible && (
                <BestqtyCell
                  ticker={ticker}
                  key={`${index}-bid-volume-${ticker}`}
                  className={`volume${3 - index}`}
                  priceName={BidStringName[2 - index]?.price}
                  volumeName={BidStringName[2 - index]?.volume}
                />
              )}
            </>
          ))}
        </div>
        <div className="last-sale-col flex-content">
          <LastpriceCell ticker={ticker} />
          {get(columns, "LAST_SALE.children.CHANGE_PRICE.visible", false) && (
            <ChangepointCell ticker={ticker} />
          )}
          {get(
            columns,
            "LAST_SALE.children.CHANGE_PERCENTAGE.visible",
            false
          ) && <ChangepercentCell ticker={ticker} />}
          <LastqtyCell ticker={ticker} />
        </div>
        <div className="ask-col flex-content">
          {Array.from({ length: 3 }).map((_, index) => (
            <>
              {columns.ASK.children![`PRICE${index + 1}`].visible && (
                <BestpriceCell
                  ticker={ticker}
                  key={`${index}-ask-price-${ticker}`}
                  className={`price${index + 1}`}
                  priceName={AskStringName[index]?.price}
                  isAsk
                />
              )}
              {columns.ASK.children![`VOLUME${index + 1}`].visible && (
                <BestqtyCell
                  ticker={ticker}
                  key={`${index}-ask-volume-${ticker}`}
                  className={`volume${index + 1}`}
                  volumeName={AskStringName[index]?.volume}
                  priceName={AskStringName[index]?.price}
                />
              )}
            </>
          ))}
        </div>
        <div className="total-volume-col">
          {get(columns, "VOLUME.visible", false) && (
            <TotalqtyCell ticker={ticker} />
          )}
          {get(columns, "AMT.visible", false) && (
            <TotalamtCell ticker={ticker} />
          )}
        </div>

        {currentListCode !== WATCH_LIST_FIXED_TABS.ODDLOT && (
          <>
            <div className="price-col flex-content">
              {columns.PRICE?.children?.HIGH?.visible && (
                <HighestpriceCell ticker={ticker} />
              )}
              {columns.PRICE?.children?.LOW?.visible && (
                <LowestpriceCell ticker={ticker} />
              )}
              {columns.PRICE?.children?.AVERAGE?.visible && (
                <AvgpriceCell ticker={ticker} />
              )}
            </div>
            <div className="foreign-col flex-content">
              {columns.FOREIGN.children!.BID?.visible && (
                <BuyforeignqtyCell ticker={ticker} />
              )}
              {columns.FOREIGN.children!.ASK?.visible && (
                <SellforeignqtyCell ticker={ticker} />
              )}
              {columns.FOREIGN.children!.CURRENTROOM?.visible && (
                <CurrentroomCell ticker={ticker} />
              )}
            </div>
          </>
        )}
        {currentListCode === WATCH_LIST_FIXED_TABS.COVER_WARRANT &&
          columns.COVER_WARRANT && (
            <div className="flex-content cover-warrant-group">
              {Object.keys(columnsCoverWarrant.COVER_WARRANT.children!).map(
                (colKey) => {
                  return (
                    <div key={colKey} className={colKey.toLowerCase()}>
                      {renderCoverWarrantVolumeCell(colKey)}
                    </div>
                  );
                }
              )}
            </div>
          )}
      </Row>
    </OuterRowContainer>
  );
}

export default RowStock;
