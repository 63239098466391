import { FC, useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import TableBodyRow from "./TableBodyRow";
import { TableBodyWrapper } from "./styles";
import { useSelector } from "react-redux";
import { tickerListTopForeignSelector } from "modules/market/redux/selectors";
import { subscribeTickers, unSubscribeTickers } from "core/wss";

export interface Props {
  rowHeight: number;
}

const TableBody: FC<Props> = ({ rowHeight }) => {
  const tableBodyWrapperRef = useRef<HTMLDivElement>(null);
  const tickerList = useSelector(tickerListTopForeignSelector);

  useEffect(() => {
    if (tickerList.length > 0) {
      const listTicker = tickerList.toString().replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
    return () => {
      if (tickerList.length > 0) {
        const listTicker = tickerList.toString().replace(/,/g, ";");
        unSubscribeTickers(listTicker);
      }
    };
  }, [tickerList.length]);

  useEffect(() => {
    //lắng nghe elemenet tự sinh của thư viện react-window scroll để scroll table header
    if (tableBodyWrapperRef?.current?.firstChild?.firstChild) {
      const scrollBarHandler = () => {
        const tableHeaderWrapperRef = document.getElementById(
          "topForeign-table-header-wrapper"
        );

        if (tableHeaderWrapperRef) {
          tableHeaderWrapperRef.scrollLeft =
            tableBodyWrapperRef?.current?.firstElementChild?.firstElementChild
              ?.scrollLeft || 0;
        }
      };

      tableBodyWrapperRef.current?.firstChild?.firstChild.addEventListener(
        "scroll",
        scrollBarHandler
      );
    }
  }, [tableBodyWrapperRef.current]);

  return (
    <TableBodyWrapper ref={tableBodyWrapperRef}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemCount={tickerList?.length || 0}
            itemSize={() => rowHeight}
            height={height}
            width={width}
            useIsScrolling
          >
            {({ style, index, isScrolling }) => (
              <TableBodyRow
                style={style}
                ticker={tickerList[index]}
                rowInd={index}
                isScrolling={isScrolling}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </TableBodyWrapper>
  );
};

export default TableBody;
