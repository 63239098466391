/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import CSelect from "components/commons/Select";
import { findMarginContract } from "core/http/apis/margin-securities-portfolio";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  getListMarginSecuritiesPortfolioRequest,
  searchListRequest,
} from "modules/margin-securities-portfolio/redux";
import {
  loadingSelector,
  selectLimit,
} from "modules/margin-securities-portfolio/selectors";
import { memo, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer, WrapBoder } from "./styles";

interface PROPS {
  onChangeAccount: (value: any) => void;
  onChangeStockCode: (value: any) => void;
  setNewCusGroupId: (value: any) => void;
  newAccount: any;
  stockCode: any;
  newCusGroupId: any;
}

const SecuritiesStatmentFilter = (props: PROPS) => {
  const {
    onChangeAccount,
    onChangeStockCode,
    setNewCusGroupId,
    newAccount,
    stockCode,
    newCusGroupId,
  } = props;
  const intl = useIntl();

  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const limit = useSelector(selectLimit);

  const listAcc = GetAccoList(GetAccountType.MARGIN, EntrustBusinessCode.All);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const handleChangeAccount = async (value: any) => {
    onChangeAccount(value.value);
    const res = await findMarginContract({
      tranCd: "",
      accNo: value.value,
      openDateFrom: 0,
      openDateTo: 0,
      broker: "",
      status: 1,
      contractState: -1,
    });
    if (res?.data?.[0]) {
      setNewCusGroupId(res?.data?.[0]?.marginGroupId);
    }
  };

  const onChangeCode = (e: any) => {
    onChangeStockCode(e.target.value);
  };

  const handleSearch = () => {
    dispatch(
      searchListRequest({
        custGroupId: newCusGroupId,
        secCd: stockCode,
        status: 1,
        page: 0,
        rowPerPage: limit,
      })
    );
  };

  const findMargin = async () => {
    const res = await findMarginContract({
      tranCd: "",
      accNo: newAccount,
      openDateFrom: 0,
      openDateTo: 0,
      broker: "",
      status: 1,
      contractState: -1,
    });
    if (res && res?.data && res?.data?.[0]) {
      setNewCusGroupId(res?.data?.[0]?.marginGroupId);
      dispatch(
        getListMarginSecuritiesPortfolioRequest({
          custGroupId: res?.data?.[0] ? res?.data?.[0]?.marginGroupId : -1,
          secCd: "",
          status: 1,
          page: 0,
          rowPerPage: limit,
        })
      );
    }
  };

  useEffect(() => {
    if (parseOptionAccount.length !== 0) {
      findMargin();
    } else {
      dispatch(
        getListMarginSecuritiesPortfolioRequest({
          custGroupId: -1,
          secCd: "",
          status: 1,
          page: 0,
          rowPerPage: limit,
        })
      );
    }
  }, [parseOptionAccount.length]);

  return (
    <WrapBoder className="d-flex justify-content-between align-items-center">
      <FlexContainer className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "marginSecuritiesPortfolio.account",
          })}
          className="mr-4"
        >
          <CSelect
            defaultValue={parseOptionAccount[0]}
            options={parseOptionAccount}
            onChange={handleChangeAccount}
          />
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "marginSecuritiesPortfolio.stockCode",
          })}
          className="mr-4"
        >
          <Input
            placeholder={intl.formatMessage({
              id: "marginSecuritiesPortfolio.playhoderStockCode",
            })}
            onChange={onChangeCode}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          onClick={handleSearch}
          loading={loading}
        >
          {intl.formatMessage({
            id: "marginSecuritiesPortfolio.search",
          })}
        </Button>
      </FlexContainer>
    </WrapBoder>
  );
};

export default memo(SecuritiesStatmentFilter);
