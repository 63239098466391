import { reset } from "modules/transfer-local/redux";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import RequestFilter from "./AdvanceContent/RequestFilters";
import RequestTable from "./AdvanceContent/RequestTable";

const AdvanceContent: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <>
      <RequestFilter />
      <RequestTable />
    </>
  );
};
export default AdvanceContent;
