import HttpArticleService from "core/http/singleton/article-service";
import {
  IFindArticleCategorysResponse,
  IFindArticlesResponse,
} from "domain/models/GuideVideo";
import { IFindArticleCategorysParams, IFindArticlesParams } from "./types";
import { findArticleCategorysUrl, findArticlesUrl } from "./urls";

export const findArticleCategorysApi = async (
  params: IFindArticleCategorysParams
) => {
  const res = await HttpArticleService.get<IFindArticleCategorysResponse>(
    findArticleCategorysUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findArticlesApi = async (params: IFindArticlesParams) => {
  const res = await HttpArticleService.get<IFindArticlesResponse>(
    findArticlesUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
