import styled from "styled-components/macro";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);

  & > div {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
