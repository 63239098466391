import styled from "styled-components/macro";

interface TextAreaProps {
  error?: boolean;
  isBorderActive?: boolean;
}

export const Body = styled.div<{ padding?: number }>`
  background-color: ${({ theme }) => theme.background2};
  padding: 8px 24px;
  word-wrap: break-word;
  text-align: center;
`;

export const Footer = styled.div`
  display: block;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.background2};
  button {
    width: 100%;
    margin: auto;
  }
`;

export const DotRed = styled.span`
  color: ${({ theme }) => theme.textColorRed};
`;

export const ShowImage = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  span {
    position: relative;
    margin-left: 12px;
    margin-bottom: 6px;
    margin-top: 6px;

    svg {
      position: absolute;
      top: -8px;
      right: -8px;
      cursor: pointer;
    }
    img {
      cursor: zoom-in;
      border-radius: 2px;
    }
  }
`;

export const TextUpLoad = styled.div`
  justify-content: flex-end;
  display: flex;
  label {
    cursor: pointer;
    color: ${({ theme }) => theme.solidBlue};
  }
`;

export const TextAreaStyle = styled.textarea<TextAreaProps>`
  position: relative;
  width: 100%;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  padding: 16px 12px;
  @media (max-height: 900px) {
    min-height: 56px;
  }
  background-color: ${({ theme }) => theme.secondaryBlack};
  border: 1px solid
    ${({ error, theme, isBorderActive }) => {
      if (isBorderActive && !error) {
        return theme.primaryP1;
      } else if (error) {
        return theme.textcolorRed;
      } else {
        return theme.borderColor;
      }
    }};
  background: ${({ theme }) => theme.secondaryBlack};
  overflow: hidden;
  border-radius: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: ${({ theme }) => theme.neutral1};
  resize: none;
  outline: none;
  min-height: 55px;
  height: 55px;
`;

export const Container = styled.div`
  .footer {
    div {
      padding: 7px;
      color: ${({ theme }) => theme.neutral1};
    }
  }
  .title {
    background: ${({ theme }) => theme.background3};
    box-shadow: ${({ theme }) => theme.shadowLineDown};
    padding: 7px;
    text-align: center;
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    color: ${({ theme }) => theme.grey40};
  }
  width: 100%;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  background-color: ${({ theme }) => theme.blackB2};
  .carousel-indicators li {
    width: 10px;
    border-radius: 50%;
    height: 10px;
  }
  .carousel-indicators .active {
    background-color: ${({ theme }) => theme.primaryP1};
  }
  .lable-text {
    margin-top: 6px;
    font-size:  ${({ theme }) => theme.typeFaces.headline.size}; !important;
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.greyG1};
  }
  .body {
    padding: 24px;
    background-color: ${({ theme }) => theme.background2};
    .content , .lable-text{
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.greyG1};
      padding-right: 0;
    }
  }
  .block {
    flex: 9;
    display: flex;
    flex-direction: row;
  }
  .text-error {
    color: ${({ theme }) => theme.redR1};
  }
`;
export const ErrorVerify = styled.div`
  color: red;
  text-align: center;
`;
export const Title = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  justify-content: center !important;
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  text-align: center;
  padding: 10px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
