import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConditionalCommandTypeEnum } from "components/widgets/generals/PlaceOrderDer/ConditionalCommandType";
import {
  IPlaceOrdersDerParams,
  IDertInquiryTradingParams,
  IGetQuotesForDertOrderParams,
  IGetCustomerRestrictionParams,
  IDertTradingGetFeeRateParams,
  IPlaceOrdersDerInputParams,
  IValidateDertRequestCondTimeParams,
  ICreateDertRequestCondParams,
} from "core/http/apis/orderexec/types";
import {
  IGetCustomerRestrictionResponse,
  IDertInquiryTradingDisplay,
  IDertInquiryTradingResponse,
  IDertTradingGetFeeRateResponse,
  IGetQuotesForDertOrderResponse,
  IQuotesForDertOrderDisplay,
  IDertCustomer,
  IValidateDertRequestCondTimeResponse,
  IDertCreateOrderResponse,
  ICreateDertRequestCondResponse,
} from "domain/models/OrderBookDer";
import { MasterAccount } from "domain/models/User";
import { EOTPTypeSubmit } from "helper/consts";
import { logOutSuccess } from "modules/auth/redux";
export enum DerTradeTypeEnum {
  N = "",
  L = "Long",
  S = "Short",
}

interface OptionsSuggestions {
  value: number;
  label: string;
}

export interface ErrorSingleOrderDer {
  code: string;
  description: string;
}

export interface State {
  loading: boolean;
  loadingPlaceOrderDer: boolean;
  data: PlaceOrderDerForm;
  validate: {
    [key: string]: string;
    errorCurrentTicker: string;
    errorAccount: string;
    errorPrice: string;
    errorVolume: string;
    errorActivePrice: string;
    errorCutLostAmp: string;
    errorGrossAmp: string;
    errorCutLostPrice: string;
    errorRangeStop: string;
    errorLimitOffset: string;
    errorTran: string;
  };
  tickerInfoLastSale: any;
  tradingSession: {
    session?: string;
    tradingSessionId?: string;
  };
  singleOrderStatus: { status: string; error?: string };
  errorSingleOrderDer: ErrorSingleOrderDer;
  placeOrdersDerParams: IPlaceOrdersDerParams;
  placeOrdersDerCondParams: ICreateDertRequestCondParams;
  listOrderQtySuccess: OptionsSuggestions[];
  changeTickerByEnterDer: boolean;
  changePriceByEnterDer: boolean;
  changeVolumeByEnterDer: boolean;
  changeConditionTradeTypeByEnterDer: boolean;
  changeStopPriceByEnterDer: boolean;
  changeTakeProfitByEnterDer: boolean;
  changeMarginByEnterDer: boolean;
  tabOrderName: string;
  dertInquiryTrading: IDertInquiryTradingDisplay;
  quotesForDertOrder: IQuotesForDertOrderDisplay;
  isCustomerRestriction: boolean;
  dertTradingGetFeeRate: number;
  statusCustomerRestriction: string;
  errorCustomerRestriction: ErrorSingleOrderDer;
  derCustomer: IDertCustomer;
  multipleNum: number;
  validateDert: number;
}
export interface PlaceOrderDerForm {
  currentTicker: string;
  type: DerTradeTypeEnum;
  account: MasterAccount;
  price: any;
  volume: any;
  conditionalCommandType: {
    value: ConditionalCommandTypeEnum;
    label: string;
  } | null;
  priceUp: number | string;
  priceDown: number | string;
  cutLostAmp: number | string;
  grossAmp: number | string;
  cutLostPrice: number | string;
  limitOffset: number | string;
  rangeStop: number | string;
  updatePrice: boolean;
}
const initialState: State = {
  loading: false,
  loadingPlaceOrderDer: false,
  data: {
    currentTicker: "",
    type: DerTradeTypeEnum.L,
    account: {
      custCd: 0,
      custNo: "",
    },
    price: "",
    volume: "",
    priceUp: "",
    priceDown: "",
    cutLostAmp: "",
    grossAmp: "",
    cutLostPrice: "",
    limitOffset: "",
    rangeStop: "",
    conditionalCommandType: {
      value: ConditionalCommandTypeEnum.Normal,
      label: "---",
    },
    updatePrice: true,
  },
  validate: {
    errorCurrentTicker: "",
    errorAccount: "",
    errorPrice: "",
    errorVolume: "",
    errorActivePrice: "",
    errorCutLostAmp: "",
    errorGrossAmp: "",
    errorCutLostPrice: "",
    errorRangeStop: "",
    errorLimitOffset: "",
    errorTran: "",
  },
  tickerInfoLastSale: undefined,
  tradingSession: {},
  singleOrderStatus: { status: "", error: "" },
  errorSingleOrderDer: {
    code: "",
    description: "",
  },
  placeOrdersDerParams: {
    data: "",
    otpCode: "",
    otpType: EOTPTypeSubmit.OTP,
  },
  placeOrdersDerCondParams: {
    custNo: "",
    secCd: "",
    tradeType: 0,
    ordQty: 0,
    ordPrice: 0,
    condType: "",
    priceStop: 0,
    rangeStop: 0,
    grossAmp: 0,
    cutLostAmp: 0,
    cutLostPrice: 0,
    limitOffset: 0,
    validDate: 0,
    remarks: "",
    notes: "",
    priceUp: 0,
    priceDown: 0,
    ordChanel: "",
    clientInfo: null,
    otpType: EOTPTypeSubmit.OTP,
  },
  listOrderQtySuccess: [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 1,
    },
    {
      label: "5",
      value: 1,
    },
    {
      label: "10",
      value: 1,
    },
  ],
  changeTickerByEnterDer: false,
  changePriceByEnterDer: false,
  changeVolumeByEnterDer: false,
  changeConditionTradeTypeByEnterDer: false,
  changeStopPriceByEnterDer: false,
  changeTakeProfitByEnterDer: false,
  changeMarginByEnterDer: false,
  tabOrderName: "placeOrderDer",
  dertInquiryTrading: {
    availMargin: 0,
    maxBought: 0,
    maxSold: 0,
    currentRate: 0,
  },
  quotesForDertOrder: {
    ceilingPrice: 0,
    basicPrice: 0,
    floorPrice: 0,
  },
  isCustomerRestriction: false,
  dertTradingGetFeeRate: 0,
  statusCustomerRestriction: "",
  errorCustomerRestriction: {
    code: "",
    description: "",
  },
  derCustomer: {
    custCd: 0,
    custNo: "",
  },
  multipleNum: 10000,
  validateDert: 0,
};
const placeOrderDerSlice = createSlice({
  name: "placeOrderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      state.data.price = "";
      state.data.account = {
        custCd: 0,
        custNo: "",
      };
      state.data.currentTicker = "";
      state.quotesForDertOrder = {
        ceilingPrice: 0,
        basicPrice: 0,
        floorPrice: 0,
      };
    });
  },
  reducers: {
    updatePlaceOrderDerForm: (
      state: State,
      action: PayloadAction<PlaceOrderDerForm>
    ) => {
      state.data = action.payload;
    },
    updateCurrentTicker: (state: State, action: PayloadAction<string>) => {
      state.data.currentTicker = action.payload;
      state.data.updatePrice = true;
    },
    updateMultipleNum: (state: State, action: PayloadAction<number>) => {
      state.multipleNum = action.payload;
    },
    onChangeTickerByEnterDer: (state, action: PayloadAction<boolean>) => {
      state.changeTickerByEnterDer = action.payload;
    },
    onChangePriceByEnterDer: (state, action: PayloadAction<boolean>) => {
      state.changePriceByEnterDer = action.payload;
    },
    onChangeVolumeByEnterDer: (state, action: PayloadAction<boolean>) => {
      state.changeVolumeByEnterDer = action.payload;
    },
    onChangeConditionTradeTypeByEnterDer: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.changeConditionTradeTypeByEnterDer = action.payload;
    },
    onChangeStopPriceByEnterDer: (state, action: PayloadAction<boolean>) => {
      state.changeStopPriceByEnterDer = action.payload;
    },
    onChangeByTakeProfitEnterDer: (state, action: PayloadAction<boolean>) => {
      state.changeTakeProfitByEnterDer = action.payload;
    },
    onChangeMarginByEnterDer: (state, action: PayloadAction<boolean>) => {
      state.changeMarginByEnterDer = action.payload;
    },
    updatePlaceOrderDerType: (
      state: State,
      action: PayloadAction<DerTradeTypeEnum>
    ) => {
      state.data.type = action.payload;
    },
    updatePlaceOrderDerPrice: (
      state: State,
      action: PayloadAction<string | number>
    ) => {
      state.data.price = action.payload;
    },
    updatePlaceOrderDerVolume: (
      state: State,
      action: PayloadAction<number>
    ) => {
      state.data.volume = action.payload;
    },
    updatePlaceOrderDerConditionalCommandType: (
      state: State,
      action: PayloadAction<{
        value: ConditionalCommandTypeEnum;
        label: string;
      } | null>
    ) => {
      state.data.conditionalCommandType = action.payload;
      state.data.cutLostAmp = "";
      state.data.grossAmp = "";
      state.data.cutLostPrice = "";
      state.data.limitOffset = "";
      state.data.rangeStop = "";
      state.validate.errorStopPrice = "";
      state.validate.errorTakeProfit = "";
      state.validate.errorMargin = "";
    },
    updatePlaceOrderDerAccount: (
      state: State,
      action: PayloadAction<MasterAccount>
    ) => {
      state.data.account = action.payload;
    },
    updateTickerInfoLastSale: (state: State, action: PayloadAction<any>) => {
      state.tickerInfoLastSale = action.payload;
    },
    updatePriceUp: (state: State, action: PayloadAction<any>) => {
      state.data.priceUp = action.payload;
    },
    updatePriceDown: (state: State, action: PayloadAction<any>) => {
      state.data.priceDown = action.payload;
    },
    updateCutLostAmp: (state: State, action: PayloadAction<any>) => {
      state.data.cutLostAmp = action.payload;
    },
    updateGrossAmp: (state: State, action: PayloadAction<any>) => {
      state.data.grossAmp = action.payload;
    },
    updateCutLostPrice: (state: State, action: PayloadAction<any>) => {
      state.data.cutLostPrice = action.payload;
    },
    updateLimitOffset: (state: State, action: PayloadAction<any>) => {
      state.data.limitOffset = action.payload;
    },
    updateRangeStop: (state: State, action: PayloadAction<any>) => {
      state.data.rangeStop = action.payload;
    },
    updateErrorMesage: (state: State, action: PayloadAction<any>) => {
      state.validate[action.payload.key] = action.payload.message;
    },
    clearErrorMessage: (state: State) => {
      state.validate = {
        errorCurrentTicker: "",
        errorAccount: "",
        errorPrice: "",
        errorVolume: "",
        errorActivePrice: "",
        errorCutLostAmp: "",
        errorGrossAmp: "",
        errorCutLostPrice: "",
        errorRangeStop: "",
        errorLimitOffset: "",
        errorTran: "",
      };
    },
    resetPlaceOrderDerForm: (state: State) => {
      state.data.volume = "";
      state.data.cutLostAmp = "";
      state.data.grossAmp = "";
      state.data.cutLostPrice = "";
      state.data.limitOffset = "";
      state.data.rangeStop = "";
      state.dertInquiryTrading = {
        availMargin: 0,
        maxBought: 0,
        maxSold: 0,
        currentRate: 0,
      };
    },
    requestGetTradingSession: (state: State, action: PayloadAction<any>) => {
      state.loading = false;
    },
    getTradingSessionSuccess: (state: State, action: PayloadAction<any>) => {
      state.loading = false;
      state.tradingSession = action.payload;
    },
    getTradingSessionFailed: (state: State) => {
      state.loading = false;
    },
    getLastestPrice: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getLastestPriceSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data.price = action.payload;
    },
    getLastestPriceFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data.price = "";
    },
    onLoadingPlaceOrder: (state, action: PayloadAction<boolean>) => {
      state.loadingPlaceOrderDer = action.payload;
    },
    requestCreateOrderDer: (
      state,
      action: PayloadAction<IPlaceOrdersDerParams>
    ) => {
      state.placeOrdersDerParams = action.payload;
      state.loadingPlaceOrderDer = true;
    },
    postSingleOrderDerSuccess: (
      state,
      action: PayloadAction<IDertCreateOrderResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.singleOrderStatus = { status: "success" };
      } else {
        state.singleOrderStatus = { status: "failed" };
        state.errorSingleOrderDer = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
      const params: IPlaceOrdersDerInputParams = JSON.parse(
        state.placeOrdersDerParams.data
      );
      const checkExist = state.listOrderQtySuccess.find(
        (item) => item.label === params.ordQty.toString()
      );
      if (checkExist) {
        state.listOrderQtySuccess.map((item) => {
          if (item.label === params.ordQty.toString()) {
            item.value = item.value + 1;
          }
        });
      } else {
        if (params.ordQty.toString() !== "") {
          state.listOrderQtySuccess.unshift({
            label: params.ordQty.toString(),
            value: 1,
          });
        }
      }
      state.loadingPlaceOrderDer = false;
    },
    postSingleOrderDerFailure: (
      state,
      action: PayloadAction<ErrorSingleOrderDer>
    ) => {
      state.singleOrderStatus = { status: "failed" };
      state.errorSingleOrderDer = {
        code: action.payload.code,
        description: action.payload.description,
      };
      state.loadingPlaceOrderDer = false;
    },
    postSingleOrderDerClear: (state) => {
      state.loadingPlaceOrderDer = false;
      state.singleOrderStatus = { status: "" };
    },
    clearDataDer: (state) => {
      state.quotesForDertOrder = {
        ceilingPrice: 0,
        basicPrice: 0,
        floorPrice: 0,
      };
      state.tickerInfoLastSale = {};
      state.data.price = "";
    },
    changeOrderTabNameDer: (state, action: PayloadAction<string>) => {
      state.tabOrderName = action.payload;
    },

    // --- //
    getQuotesForDertOrder: (
      state: State,
      action: PayloadAction<IGetQuotesForDertOrderParams>
    ) => {
      state.loading = false;
    },
    getQuotesForDertOrderSuccess: (
      state: State,
      action: PayloadAction<IGetQuotesForDertOrderResponse>
    ) => {
      state.loading = false;
      state.quotesForDertOrder = {
        ceilingPrice: action.payload.data?.ceilingPrice || 0,
        basicPrice: action.payload.data?.basicPrice || 0,
        floorPrice: action.payload.data?.floorPrice || 0,
      };
    },
    getQuotesForDertOrderFailed: (state: State) => {
      state.loading = false;
      state.quotesForDertOrder = {
        ceilingPrice: 0,
        basicPrice: 0,
        floorPrice: 0,
      };
    },

    dertInquiryTrading: (
      state: State,
      action: PayloadAction<IDertInquiryTradingParams>
    ) => {
      state.loading = false;
    },
    dertInquiryTradingSuccess: (
      state: State,
      action: PayloadAction<IDertInquiryTradingResponse>
    ) => {
      state.loading = false;
      state.dertInquiryTrading = {
        availMargin: action.payload.data.availMargin,
        maxBought: action.payload.data.maxBought,
        maxSold: action.payload.data.maxSold,
        currentRate: action.payload.data.currentRate,
      };
      state.derCustomer = action.payload.data.derCustomer;
    },
    dertInquiryTradingFailed: (state: State) => {
      state.loading = false;
      state.dertInquiryTrading = {
        availMargin: 0,
        maxBought: 0,
        maxSold: 0,
        currentRate: 0,
      };
    },

    getCustomerRestrictionDer: (
      state: State,
      action: PayloadAction<IGetCustomerRestrictionParams>
    ) => {
      state.loading = false;
    },
    getCustomerRestrictionDerSuccess: (
      state: State,
      action: PayloadAction<IGetCustomerRestrictionResponse>
    ) => {
      state.loading = false;
      state.isCustomerRestriction = action.payload.data === 0;
      state.statusCustomerRestriction =
        action.payload.statusCode === 0 ? "SUCCESSFUL" : "FAILURE";
      if (action.payload.statusCode !== 0) {
        state.errorCustomerRestriction = {
          code: action.payload.errorCode,
          description: action.payload.message,
        };
      }
    },
    getCustomerRestrictionDerFailed: (
      state: State,
      action: PayloadAction<ErrorSingleOrderDer>
    ) => {
      state.loading = false;
      state.statusCustomerRestriction = "FAILURE";
      state.errorCustomerRestriction = action.payload;
    },

    clearStatusCustomerRestriction: (state: State) => {
      state.statusCustomerRestriction = "";
    },

    getDertTradingGetFeeRate: (
      state: State,
      action: PayloadAction<IDertTradingGetFeeRateParams>
    ) => {
      state.loading = false;
    },
    getDertTradingGetFeeRateSuccess: (
      state: State,
      action: PayloadAction<IDertTradingGetFeeRateResponse>
    ) => {
      state.loading = false;
      state.dertTradingGetFeeRate = action.payload.data;
    },
    getDertTradingGetFeeRateFailed: (state: State) => {
      state.loading = false;
    },

    validateDertRequestCondTime: (
      state: State,
      action: PayloadAction<IValidateDertRequestCondTimeParams>
    ) => {
      state.loading = false;
    },
    validateDertRequestCondTimeSuccess: (
      state: State,
      action: PayloadAction<IValidateDertRequestCondTimeResponse>
    ) => {
      state.loading = false;
      state.validateDert = action.payload.data.validDate;
    },
    validateDertRequestCondTimeFailed: (state: State) => {
      state.loading = false;
    },
    createDertRequestCond: (
      state,
      action: PayloadAction<ICreateDertRequestCondParams>
    ) => {
      state.placeOrdersDerCondParams = action.payload;
      state.loadingPlaceOrderDer = true;
    },
    createDertRequestCondSuccess: (
      state,
      action: PayloadAction<ICreateDertRequestCondResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.singleOrderStatus = { status: "success" };
      } else {
        state.singleOrderStatus = { status: "failed" };
        state.errorSingleOrderDer = {
          code: action.payload.errorCode || "",
          description: action.payload.message || "",
        };
      }
      const params: ICreateDertRequestCondParams =
        state.placeOrdersDerCondParams;
      const checkExist = state.listOrderQtySuccess.find(
        (item) => item.label === params.ordQty.toString()
      );
      if (checkExist) {
        state.listOrderQtySuccess.map((item) => {
          if (item.label === params.ordQty.toString()) {
            item.value = item.value + 1;
          }
        });
      } else {
        if (params.ordQty.toString() !== "") {
          state.listOrderQtySuccess.unshift({
            label: params.ordQty.toString(),
            value: 1,
          });
        }
      }
      state.loadingPlaceOrderDer = false;
    },
    createDertRequestCondFailed: (
      state,
      action: PayloadAction<ErrorSingleOrderDer>
    ) => {
      state.singleOrderStatus = { status: "failed" };
      state.errorSingleOrderDer = {
        code: action.payload.code,
        description: action.payload.description,
      };
      state.loadingPlaceOrderDer = false;
    },

    // --- //
  },
});

export const {
  updatePlaceOrderDerForm,
  updateCurrentTicker,
  updateMultipleNum,
  updatePlaceOrderDerType,
  updatePlaceOrderDerPrice,
  updatePlaceOrderDerVolume,
  updatePlaceOrderDerConditionalCommandType,
  updatePlaceOrderDerAccount,
  updateTickerInfoLastSale,
  updatePriceUp,
  updatePriceDown,
  updateCutLostAmp,
  updateGrossAmp,
  updateCutLostPrice,
  updateLimitOffset,
  updateRangeStop,
  updateErrorMesage,
  resetPlaceOrderDerForm,
  requestGetTradingSession,
  getTradingSessionSuccess,
  getTradingSessionFailed,
  getLastestPrice,
  getLastestPriceSuccess,
  getLastestPriceFailed,
  clearErrorMessage,
  requestCreateOrderDer,
  postSingleOrderDerSuccess,
  postSingleOrderDerFailure,
  postSingleOrderDerClear,
  onChangeTickerByEnterDer,
  onChangePriceByEnterDer,
  onChangeVolumeByEnterDer,
  onChangeConditionTradeTypeByEnterDer,
  onChangeStopPriceByEnterDer,
  onChangeByTakeProfitEnterDer,
  onChangeMarginByEnterDer,
  clearDataDer,
  onLoadingPlaceOrder,
  changeOrderTabNameDer,

  getQuotesForDertOrder,
  getQuotesForDertOrderSuccess,
  getQuotesForDertOrderFailed,
  dertInquiryTrading,
  dertInquiryTradingSuccess,
  dertInquiryTradingFailed,
  getCustomerRestrictionDer,
  getCustomerRestrictionDerSuccess,
  getCustomerRestrictionDerFailed,
  clearStatusCustomerRestriction,
  getDertTradingGetFeeRate,
  getDertTradingGetFeeRateSuccess,
  getDertTradingGetFeeRateFailed,
  validateDertRequestCondTime,
  validateDertRequestCondTimeSuccess,
  validateDertRequestCondTimeFailed,
  createDertRequestCond,
  createDertRequestCondSuccess,
  createDertRequestCondFailed,
} = placeOrderDerSlice.actions;
export default placeOrderDerSlice.reducer;
