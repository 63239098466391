import { SizeType } from "components/commons/Spacer";
import styled from "styled-components/macro";

export const InputConntainer = styled.div<{
  size?: SizeType;
  isBorderActive?: boolean;
  radius?: number;
  textArea: boolean;
  isHeight28: boolean;
  unSetHeight?: boolean;
}>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.grey100};
  border: 1px solid
    ${({ theme, isBorderActive }) =>
      isBorderActive ? theme.brandPrimary : `${theme.grey70};`};
  overflow: hidden;
  border-radius: ${({ radius }) => (radius ? radius : 4)}px;
  height: ${({ theme, textArea, isHeight28 }) =>
    textArea
      ? theme.input.heightArea
      : isHeight28
      ? theme.input.height28
      : theme.input.height};
  display: flex;
  align-items: ${({ textArea }) => (textArea ? "flex-start" : "center")};
  overflow: hidden;
  ${({ unSetHeight }) => unSetHeight && `height: unset !important;`}
`;

export const InputBox = styled.div<{
  isTextCenter?: boolean;
  isTextRight?: boolean;
}>`
  ${({ isTextRight }) =>
    !isTextRight &&
    `
    padding: 6px 8px;
  `}
  flex: 1;
  .input {
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.textcolorWhite};
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    text-align: ${({ isTextCenter, isTextRight }) =>
      isTextCenter ? "center" : isTextRight ? "right" : "left"};
  }
  textarea {
    resize: none;
  }
`;

export const Suffix = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 8px;
`;

export const TitleName = styled.div`
  color: ${({ theme }) => theme.greyG1};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
`;

export const IconButton = styled.div`
  cursor: pointer;

  &.btn-close {
    svg {
      path {
        fill: ${({ theme }) => theme.neutral2};
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;
