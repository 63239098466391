import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  box-shadow: 0px -1px 0px 0px ${({ theme }) => theme.shadowInner1} inset;
  h2 {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    margin-bottom: 20px;
    color: ${({ theme }) => theme.textcolorWhite};
  }
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChartContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
`;

export const MarketLineChartWrapper = styled.div`
  position: relative;
  flex: 1 1 0;
  overflow: hidden;
`;

export const Legend = styled.div`
  flex: 0 0 21px;
  display: flex;
  justify-content: center;
  gap: 20px;
  .item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
