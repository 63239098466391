import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import { OrderBookDerRealTime } from "domain/models/OrderBookDer";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";

const matPriceAvgSelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) =>
      state?.[realTimeId]?.matPriceAvg
  );
};

interface Props {
  realTimeId: string;
}

const MatPriceAvgCell = ({ realTimeId }: Props) => {
  const matPriceAvg = useSelector(matPriceAvgSelector(realTimeId));
  const formatterValue = numeral(matPriceAvg).format("0,0.00");
  return (
    <>
      <BlinkableCell value={formatterValue} />
    </>
  );
};

export default MatPriceAvgCell;
