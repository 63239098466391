import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  loadingSearch: boolean;
  loadingSubmit: boolean;
  account: object | any;
  listContract: [];
  statusCode: number;
  dataEdit: any;
}

const initialState: ReduxData = {
  loadingSearch: false,
  loadingSubmit: false,
  account: {},
  listContract: [],
  statusCode: -1,
  dataEdit: null,
};

const addFrameworkConstractFormSlice = createSlice({
  name: "addFrameworkConstractFormSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    getAccountRequest: (state, action: PayloadAction<any>) => {},
    getAccountSuccess: (state, action: PayloadAction<any>) => {
      state.account = action.payload.data;
    },
    getAccountFailure: (state) => {},

    createContractRequest: (state, action: PayloadAction<any>) => {
      state.loadingSubmit = true;
    },
    createContractSuccess: (state, action: PayloadAction<any>) => {
      state.statusCode = action?.payload?.statusCode;
      state.loadingSubmit = false;
    },
    createContractFailure: (state) => {
      state.loadingSubmit = false;
    },

    searchContractRequest: (state, action: PayloadAction<any>) => {
      state.loadingSearch = true;
    },
    searchContractSuccess: (state, action: PayloadAction<any>) => {
      state.loadingSearch = false;
      state.listContract = action?.payload?.data;
    },
    searchContractFailure: (state) => {
      state.loadingSearch = false;
    },

    getContractRequest: (state, action: PayloadAction<any>) => {},
    getContractSuccess: (state, action: PayloadAction<any>) => {
      state.listContract = action?.payload?.data;
    },
    getContractFailure: (state) => {},

    updateStatusCode: (state, action: PayloadAction<any>) => {
      state.statusCode = action?.payload?.newStatus;
    },
  },
});

export const {
  getAccountRequest,
  getAccountSuccess,
  getAccountFailure,

  createContractRequest,
  createContractSuccess,
  createContractFailure,

  getContractRequest,
  getContractSuccess,
  getContractFailure,

  searchContractRequest,
  searchContractSuccess,
  searchContractFailure,

  updateStatusCode,
} = addFrameworkConstractFormSlice.actions;

export default addFrameworkConstractFormSlice.reducer;
