import queryString from "query-string";
import { ISearchStatisticHighestUpdownParams } from "./types";

export const searchStatisticHighestUpdownApi = (
  params: ISearchStatisticHighestUpdownParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return `/findHighestUpDownPercent${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
