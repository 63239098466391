import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  msttProvinceItem,
  msttProvinceRequest,
} from "core/http/apis/api/types";
import {
  ItemRegisterBankRequest,
  postAccountInfoRequest,
  postServiceRegistrationRequest,
  sendOTPGlobalMindRequest,
  verifyOTPGlobalMindRequest,
} from "core/http/apis/globalMind/types";
import {
  BankListItem,
  CheckBrokerIdResponse,
  CheckCustodyCdResponse,
} from "core/http/apis/globalMindGateway/types";
import sortBy from "lodash/sortBy";

export type RegisterEkycState = {
  visibleModalEkyc: boolean;
  step: number;
  sendOTPGlobalMindRequest: sendOTPGlobalMindRequest;
  counterOTPEkyc: number;
  verifyOTPGlobalMindRequest: verifyOTPGlobalMindRequest;
  image: {
    cmndFront: File | undefined;
    cmndBack: File | undefined;
    ekyc: File | undefined;
    hashCmndFront: string;
    hashCmndBack: string;
    hashEkyc: string;
  };
  postAccountInfoRequest: postAccountInfoRequest;
  searchMsttProvince: msttProvinceRequest;
  dataMsttProvince: msttProvinceItem[];
  checkBrokerIdResponse: CheckBrokerIdResponse;
  checkCustodyCdResponse: CheckCustodyCdResponse;
  postServiceRegistrationRequest: postServiceRegistrationRequest;
  hasBrokerId: boolean;
  postRegisterBankRequest: ItemRegisterBankRequest[];
  bankList: BankListItem[];
  loading: boolean;
};

const defaultItemRegisterBank: ItemRegisterBankRequest = {
  id: "",
  accountId: "",
  bankAccountNo: "",
  bankName: "",
  cityBank: "",
  branch: "",
  bankAccountName: "",
  status: 0, //default =0
};

const initialState: RegisterEkycState = {
  visibleModalEkyc: false,
  step: 0,
  sendOTPGlobalMindRequest: {
    nationality: "0",
    cashAccount: true,
    customerType: "0",
    marginAccount: false,
    phone: "",
    email: "",
  },
  counterOTPEkyc: 0,
  verifyOTPGlobalMindRequest: {
    confirmId: "",
    confirmCode: "",
  },
  image: {
    cmndFront: undefined,
    cmndBack: undefined,
    ekyc: undefined,
    hashCmndFront: "",
    hashCmndBack: "",
    hashEkyc: "",
  },
  postAccountInfoRequest: {
    otpId: "",
    fullName: "",
    dob: new Date(),
    gender: "",
    nationality: "0",
    cardId: "",
    cardType: "",
    issueDate: new Date(),
    issuePlace: "",
    validDate: new Date(),
    idExpiredDate: new Date(),
    address: "",
    contactAddress: "",
    country: "0",
    city: "",
    phone: "",
    email: "",
    brokerId: "",
  },
  searchMsttProvince: {
    status: "1",
    size: "10",
    page: "0",
    name: "",
  },
  dataMsttProvince: [],
  checkBrokerIdResponse: {
    status: "",
  },
  checkCustodyCdResponse: {
    status: "",
  },
  postServiceRegistrationRequest: {
    accountId: "",
    investorPro: true,
    investorSmart: false,
    iStock: true,
    tStock: false,
    transactionReport: true,
    transactionByEmail: true,
    mStock: false,
    cashAdvance: true,
    cashAtBank: false,
    cashAtGMC: true,
    marginAccount: false,
    cashAccount: true,
    custodyCD: "049C",
    fatCa_1: true,
    fatCa_2: false,
    fatCa_3: false,
  },
  hasBrokerId: true,
  postRegisterBankRequest: [defaultItemRegisterBank],
  bankList: [],
  loading: false,
};

const modalRegisterEkycSlide = createSlice({
  name: "modalRegisterEkyc",
  initialState,
  reducers: {
    setVisibleModalEkyc: (state, action: PayloadAction<boolean>) => {
      state.visibleModalEkyc = action.payload;
      state.step = initialState.step;
    },
    setStepEkyc: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
      state.loading = false;
    },
    backStepEkyc: (state) => {
      if (state.loading) return;
      state.step = state.step - 1;
    },
    updateSendOTPGlobalMindRequest: (state, action: PayloadAction<object>) => {
      state.sendOTPGlobalMindRequest =
        { ...state.sendOTPGlobalMindRequest, ...action.payload } || {};
    },
    getCheckNumberPhoneEmailStart: (state) => {
      state.loading = true;
    },
    sendOTPStart: (state) => {},
    setcounterOTPEkyc: (state, action: PayloadAction<number>) => {
      state.counterOTPEkyc = action.payload;
    },
    setDownCounterOTPEkyc: (state) => {
      if (state.counterOTPEkyc > 0) {
        state.counterOTPEkyc = state.counterOTPEkyc - 1;
      }
    },
    updateVerifyOTPGlobalMindRequest: (
      state,
      action: PayloadAction<object>
    ) => {
      state.verifyOTPGlobalMindRequest =
        { ...state.verifyOTPGlobalMindRequest, ...action.payload } || {};
    },
    verifyOTPStart: (state) => {
      state.loading = true;
    },
    updateImageRequest: (state, action: PayloadAction<object>) => {
      state.image = { ...state.image, ...action.payload } || {};
    },
    sendFrontBackImageStart: (state) => {
      state.loading = true;
    },
    sendEkycImageStart: (state) => {
      state.loading = true;
    },
    updateAccountInfoRequest: (state, action: PayloadAction<object>) => {
      state.postAccountInfoRequest =
        { ...state.postAccountInfoRequest, ...action.payload } || {};
    },
    updateMsttProvinceRequest: (state, action: PayloadAction<object>) => {
      state.searchMsttProvince =
        { ...state.searchMsttProvince, ...action.payload } || {};
    },
    getMsttProvinceStart: (state) => {},
    getMsttProvinceSuccess: (
      state,
      action: PayloadAction<msttProvinceItem[]>
    ) => {
      state.dataMsttProvince = sortBy(action.payload, "name");
    },
    checkBrokerIdStart: (state) => {
      state.checkBrokerIdResponse = initialState.checkBrokerIdResponse;
    },
    checkBrokerIdSuccess: (
      state,
      action: PayloadAction<CheckBrokerIdResponse>
    ) => {
      state.checkBrokerIdResponse = action.payload;
    },
    checkCustodyCdStart: (state) => {
      state.loading = true;
      state.checkCustodyCdResponse = initialState.checkCustodyCdResponse;
    },
    checkCustodyCdSuccess: (
      state,
      action: PayloadAction<CheckCustodyCdResponse>
    ) => {
      state.checkCustodyCdResponse = action.payload;
    },
    updateServiceRegistrationRequest: (
      state,
      action: PayloadAction<object>
    ) => {
      state.postServiceRegistrationRequest =
        { ...state.postServiceRegistrationRequest, ...action.payload } || {};
    },
    postAccountInfoStart: (state) => {
      state.loading = true;
    },
    setHasBrokerId: (state, action: PayloadAction<boolean>) => {
      state.hasBrokerId = action.payload;
    },
    addBankAccount: (state) => {
      state.postRegisterBankRequest.push(defaultItemRegisterBank);
    },
    removeBankAccount: (state, action: PayloadAction<number>) => {
      state.postRegisterBankRequest.splice(action.payload, 1);
    },
    removeAllBankAccount: (state) => {
      state.postRegisterBankRequest = [];
    },
    getBankListStart: () => {},
    getBankListSuccess: (state, action: PayloadAction<BankListItem[]>) => {
      state.bankList = action.payload;
    },
    changeRegisterBankRequest: (
      state,
      action: PayloadAction<{ index: number; data: object }>
    ) => {
      state.postRegisterBankRequest[action.payload.index] = {
        ...state.postRegisterBankRequest[action.payload.index],
        ...action.payload.data,
      };
    },
    postVerifyBankAccountStart: (state) => {
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setVisibleModalEkyc,
  setStepEkyc,
  backStepEkyc,
  updateSendOTPGlobalMindRequest,
  getCheckNumberPhoneEmailStart,
  sendOTPStart,
  setcounterOTPEkyc,
  setDownCounterOTPEkyc,
  updateVerifyOTPGlobalMindRequest,
  verifyOTPStart,
  updateImageRequest,
  sendFrontBackImageStart,
  sendEkycImageStart,
  updateAccountInfoRequest,
  updateMsttProvinceRequest,
  getMsttProvinceStart,
  getMsttProvinceSuccess,
  checkBrokerIdStart,
  checkBrokerIdSuccess,
  checkCustodyCdStart,
  checkCustodyCdSuccess,
  updateServiceRegistrationRequest,
  postAccountInfoStart,
  setHasBrokerId,
  addBankAccount,
  removeBankAccount,
  removeAllBankAccount,
  getBankListStart,
  getBankListSuccess,
  changeRegisterBankRequest,
  postVerifyBankAccountStart,
  setLoading,
} = modalRegisterEkycSlide.actions;

export default modalRegisterEkycSlide.reducer;
