import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled.div`
  background-color: ${({ theme }) => theme.bgElevated3};
  text-align: center;
  padding: 10px;

  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div<{ padding?: number }>`
  padding: ${({ padding }) => padding}px;
  background-color: ${({ theme }) => theme.background1};

  .header {
    background-color: ${({ theme }) => theme.background2};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-item: center;
  }
  .headerContent {
    display: flex;
    flex-direction: column;
    margin: 16px 0 16px 0;
  }
  .body {
    height: 200px;
  }
  .bodyContent {
    padding: 32px 24px;
  }
  .checkField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 14px;
    margin: 14px 0;
  }
  .checkLabel {
    display: flex;
    flex-direction: row;
  }
  .titleSend {
    margin-bottom: 22px;

    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  }
  .text {
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  }
  .textConfirm {
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .checkConfirm {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
  }
  .inputOTP {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.borderColor} 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 18px 1px;
    background-repeat: repeat-x;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
  }
  .inputOTPHeader {
  }
  .inputOTPContent {
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    background: ${({ theme }) => theme.background2};
    margin: 24px 0;
    justify-content: center;
    .otpContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .otpInput {
        width: 44px;
        height: 44px;
        margin: 6px;
        text-align: center;
        background: ${({ theme }) => theme.bgBase};
        color: ${({ theme }) => theme.textcolorWhite};
        border: 1px solid ${({ theme }) => theme.borderColor};
        border-radius: 8px;
      }
    }
  }
  .cellInput {
    margin: 6px;
    .input {
      text-align: center;
    }
  }
  .footerConfirm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 24px;

    .apply-btn {
      background-color: ${({ theme }) => theme.solidBlue};
    }
    .flex-1: {
      flex: 1;
    }
  }
  .footer {
    display: flex;
    padding: 24px 24px;

    .apply-btn {
      background-color: ${({ theme }) => theme.solidBlue};
    }
    .flex-1: {
      flex: 1;
    }
  }
  span {
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const ButtonDisable = styled.div`
  width: 114px;
  height: 32px;
  background: gray;
  border: 1px solid gray;
  border-radius: 4px;
  text-align: center;
  color: ${({ theme }) => theme.textcolorWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export const ButtonCountDown = styled.div`
  width: 120px;
  height: 32px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.textcolorWhite};
  border-radius: 4px;
  text-align: center;
  color: ${({ theme }) => theme.textcolorWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  flex: 3;
`;

export const ButtonExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  background: transparent;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.neutral4};
  border: 1px solid ${({ theme }) => theme.neutral4};
  border-radius: 50%;
`;

export const ErrorVerify = styled.div`
  color: red;
  text-align: center;
`;
