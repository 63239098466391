import { FC } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import TableBodyRow from "./TableBodyRow";
import { TableBodyWrapper } from "./styles";

export interface Props {
  rowHeight: number;
  tickerList: string[];
}

const TableBody: FC<Props> = ({ rowHeight, tickerList }) => {
  return (
    <TableBodyWrapper>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemCount={tickerList?.length || 0}
            itemSize={() => rowHeight}
            height={height}
            width={width}
            useIsScrolling
          >
            {({ style, index, isScrolling }) => (
              <TableBodyRow
                style={style}
                ticker={tickerList[index]}
                rowInd={index}
                isScrolling={isScrolling}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </TableBodyWrapper>
  );
};

export default TableBody;
