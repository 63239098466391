import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Container, Tab } from "./styles";
import {
  ReduxData as ScreenersState,
  changeTab,
} from "modules/screeners/redux";
import { TScreenersTabCode } from "modules/screeners/types";
import { SCREENERS_TAB_CODE } from "modules/screeners/constants";

const screenersSelector = (state: RootState) => state.screeners;

const tabCodeActiveSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) => screenersSelector.tabCode
);

const TabHeader: FC = () => {
  const tabCodeActive = useSelector(tabCodeActiveSelector);
  const dispatch = useDispatch();

  const changeTabHandler = (tabCode: TScreenersTabCode) => () => {
    if (tabCodeActive === tabCode) return;
    dispatch(changeTab(tabCode));
  };

  return (
    <Container>
      <div className="header-left">
        <div>
          <Tab
            onClick={changeTabHandler(SCREENERS_TAB_CODE.SCREENER)}
            isActive={SCREENERS_TAB_CODE.SCREENER === tabCodeActive}
          >
            <FormattedMessage id="screeners.tabs.screener.title" />
          </Tab>
        </div>
        <div>
          <Tab
            onClick={changeTabHandler(SCREENERS_TAB_CODE.MY_SCREENER)}
            isActive={SCREENERS_TAB_CODE.MY_SCREENER === tabCodeActive}
          >
            <FormattedMessage id="screeners.tabs.myScreener.title" />
          </Tab>
        </div>
      </div>
    </Container>
  );
};

export default TabHeader;
