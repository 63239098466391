import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import {
  FLEX,
  generateRequestFilterDoFilter,
} from "modules/screeners/constants";
import {
  fetchMoreFilterRequest,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import numeral from "numeral";
import { CSSProperties, FC, useCallback, useEffect, useRef } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import BlinkableCell from "./BlinkableCell";
import {
  BodyRowWrapper,
  StyledTooltip,
  TableCell,
  TableCellContent,
  TableCellWrapper,
} from "./styles";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
}

export interface Props {
  columns: IHeaderCellInfo[];
  style: CSSProperties;
  ticker: string;
  rowInd: number;
  isScrolling?: boolean;
}

const rootSelector = (state: RootState) => state;

const screenersSelector = (state: RootState) => state.screeners;

const rowDataSelector = (ticker: string) => {
  return createSelector(
    screenersSelector,
    (screenersSelector: ScreenersState) =>
      screenersSelector.dataResultScreen.list.find(
        (item) => item.seccd === ticker
      )
  );
};

const enableInfinitySelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.params.enableInfinity
);

const currentTotalSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.list.length
);
const screenerFilterListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.filterList
);

const myScreenerSelectedSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataMyScreener.screenerSelected
);

const paramsSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.params
);

const languageTypeSelector = createSelector(
  rootSelector,
  (state: RootState) => state.language.type
);

const TableBodyRow: FC<Props> = ({
  style,
  ticker,
  columns,
  rowInd,
  isScrolling,
}) => {
  const dispatch = useDispatch();
  const rowData = useSelector(rowDataSelector(ticker));
  const enableInfinity = useSelector(enableInfinitySelector);
  const currentTotal = useSelector(currentTotalSelector);
  const myScreenerSelected = useSelector(myScreenerSelectedSelector);
  const screenerFilterList = useSelector(screenerFilterListSelector);
  const language = useSelector(languageTypeSelector);
  const { sortBy, order } = useSelector(paramsSelector);
  const rowDataRef = useRef(rowData);

  if (!isEqual(rowData, rowDataRef.current)) {
    rowDataRef.current = rowData;
  }

  const generateTickerCell = (
    value: any,
    saleColor: number | undefined = 0,
    width: number | undefined,
    position: string,
    maxWidthCell: number | undefined,
    flexCell: number | undefined,
    nameComp: any
  ) => {
    const textColor = saleColor;
    return (
      <TableCell
        maxWidthCell={maxWidthCell}
        flexCell={flexCell}
        widthCell={width}
        className="ticker-cell"
      >
        <TableCellWrapper
          align={FLEX[position as keyof typeof FLEX]}
          textcolor={textColor}
        >
          <div className="ticker-text">{value || "-"}</div>
          <OverlayTrigger
            placement="bottom-start"
            overlay={<StyledTooltip>{nameComp}</StyledTooltip>}
          >
            <div className="company-name-text">{nameComp || "-"}</div>
          </OverlayTrigger>
        </TableCellWrapper>
      </TableCell>
    );
  };

  const generateBlinkCell = useCallback(
    (
      value: any,
      saleColor: number | undefined = 0,
      format: string,
      width: number | undefined,
      position: string,
      maxWidthCell: number | undefined,
      flexCell: number | undefined,
      unit: string | undefined
    ) => {
      const textColor = saleColor;
      const price = format ? numeral(value).format(format) : value;
      return (
        <TableCell
          maxWidthCell={maxWidthCell}
          flexCell={flexCell}
          widthCell={width}
        >
          {typeof value === "number" ? (
            <TableCellWrapper
              align={FLEX[position as keyof typeof FLEX]}
              textcolor={textColor}
            >
              <BlinkableCell value={price} unit={unit} />
            </TableCellWrapper>
          ) : (
            <TableCellContent align={FLEX[position as keyof typeof FLEX]}>
              <div>-</div>
            </TableCellContent>
          )}
        </TableCell>
      );
    },
    []
  );

  const generateNumberCell = (
    value: any,
    format: string,
    width: number | undefined,
    position: string,
    maxWidthCell: number | undefined,
    flexCell: number | undefined
  ) => {
    const valueFormatter = numeral(value).format(format);
    return (
      <TableCell
        maxWidthCell={maxWidthCell}
        flexCell={flexCell}
        widthCell={width}
      >
        <TableCellContent align={FLEX[position as keyof typeof FLEX]}>
          <div>{value ? valueFormatter : "-"}</div>
        </TableCellContent>
      </TableCell>
    );
  };

  useEffect(() => {
    if (
      rowInd &&
      rowInd + 10 === currentTotal &&
      enableInfinity &&
      isScrolling
    ) {
      const fetchMoreData = () => {
        let currentFilterList;
        if (myScreenerSelected) {
          currentFilterList = myScreenerSelected.screenerFilter ?? [];
        } else {
          currentFilterList = screenerFilterList;
        }

        const requestFilter = generateRequestFilterDoFilter(currentFilterList);
        order && requestFilter.setOrdertype(order);
        sortBy && requestFilter.setOrderfieldtype(sortBy);
        requestFilter.setOffset(currentTotal);

        dispatch(fetchMoreFilterRequest(requestFilter));
      };

      fetchMoreData();
    }
  }, [rowInd, sortBy, order]);

  return (
    <BodyRowWrapper style={style} bgColor={rowDataRef.current?.rowNum || 1}>
      {generateTickerCell(
        rowDataRef.current?.seccd,
        rowDataRef.current?.colorcode ?? 0,
        columns[0].width,
        columns[0].position,
        columns[0]?.maxWidthCell,
        columns[0]?.flexCell,
        language === "EN"
          ? rowDataRef.current?.secnameen
          : rowDataRef.current?.secname
      )}
      {generateBlinkCell(
        rowDataRef.current?.lastprice,
        rowDataRef.current?.colorcode ?? 0,
        "0,0.00",
        columns[1].width,
        columns[1].position,
        columns[1]?.maxWidthCell,
        columns[1]?.flexCell,
        undefined
      )}
      {generateBlinkCell(
        rowDataRef.current?.changepoint,
        rowDataRef.current?.colorcode ?? 0,
        "0,0.00",
        columns[2].width,
        columns[2].position,
        columns[2]?.maxWidthCell,
        columns[2]?.flexCell,
        undefined
      )}
      {generateBlinkCell(
        rowDataRef.current?.changepercent,
        rowDataRef.current?.colorcode ?? 0,
        "0,0.00",
        columns[3].width,
        columns[3].position,
        columns[3]?.maxWidthCell,
        columns[3]?.flexCell,
        undefined
      )}
      {generateNumberCell(
        rowDataRef.current?.marketcap,
        "0,0",
        columns[4].width,
        columns[4].position,
        columns[4]?.maxWidthCell,
        columns[4]?.flexCell
      )}
      {generateNumberCell(
        rowDataRef.current?.pe,
        "0,0.00",
        columns[5].width,
        columns[5].position,
        columns[5]?.maxWidthCell,
        columns[5]?.flexCell
      )}
      {generateNumberCell(
        rowDataRef.current?.pb,
        "0,0.00",
        columns[6].width,
        columns[6].position,
        columns[6]?.maxWidthCell,
        columns[6]?.flexCell
      )}
      {generateNumberCell(
        rowDataRef.current?.netsale,
        "0,0.00",
        columns[7].width,
        columns[7].position,
        columns[7]?.maxWidthCell,
        columns[7]?.flexCell
      )}
      {generateNumberCell(
        rowDataRef.current?.roa,
        "0,0.00",
        columns[8].width,
        columns[8].position,
        columns[8]?.maxWidthCell,
        columns[8]?.flexCell
      )}
      {generateNumberCell(
        rowDataRef.current?.roe,
        "0,0.00",
        columns[9].width,
        columns[9].position,
        columns[9]?.maxWidthCell,
        columns[9]?.flexCell
      )}
      {generateNumberCell(
        rowDataRef.current?.esp,
        "0,0",
        columns[10].width,
        columns[10].position,
        columns[10]?.maxWidthCell,
        columns[10]?.flexCell
      )}
    </BodyRowWrapper>
  );
};

export default TableBodyRow;
