import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, CheckBox } from "components/commons";
import { editCheckModal } from "modules/transfer-internal/redux";
// import { statusTypeTransfer } from "modules/transfer-internal/constants";
import {
  getTypeTransferSelector,
  selectCheckModal,
} from "modules/transfer-internal/selectors";
import numeral from "numeral";
import { useState } from "react";
// import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  CustomHr,
  DivCheckbox,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
} from "./styles";
import { useEffectOnce } from "react-use";

interface Data {
  tradeType: string | null;
  subAccoNoFrom: string | null;
  subAccoNoTo: string | null;
  amount: string | null;
  feeType: number | null;
  feeAmt: number | null;
  remarks: string | null;
  avail: number | null;
}

interface PROPS {
  isVisible: boolean;
  onClose: () => void;
  data?: Data;
  onSuccess: () => void;
}

const ConfirmModal = (props: PROPS) => {
  const { isVisible, onClose, data, onSuccess } = props;
  const dispatch = useDispatch();
  const closeModalHandler = () => {
    onClose();
  };
  const typeTranfer = useSelector(getTypeTransferSelector);
  const [checked, setchecked] = useState<boolean>(false);
  const { depoist, withdrawal } = useSelector(selectCheckModal);

  const saveCheckModal = () => {
    setchecked(!checked);
    dispatch(
      editCheckModal({
        key: typeTranfer === "2" ? "depoist" : "withdrawal",
        value: !checked,
      })
    );
  };

  useEffectOnce(() => {
    if (
      (depoist && typeTranfer === "2") ||
      (withdrawal && typeTranfer === "3")
    ) {
      onSuccess();
    }
  });

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage
              id={
                typeTranfer === "2"
                  ? "transferInternal.formAdd.modal.title"
                  : "transferInternal.formRemove.modal.title"
              }
            />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.action" />
            </LabelCell>
            <TextCell>
              <FormattedMessage
                id={
                  typeTranfer === "2"
                    ? "transferInternal.historyTransfer.filter.typeMoneyTransfer.status2"
                    : "transferInternal.historyTransfer.filter.typeMoneyTransfer.status3"
                }
              />
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.fromSubAccoNo" />
            </LabelCell>
            <TextCell>{data?.subAccoNoFrom}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.toSubAccoNo" />
            </LabelCell>
            <TextCell>{data?.subAccoNoTo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.amount" />
            </LabelCell>
            <TextCell>{numeral(data?.amount).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.typeAmount" />
            </LabelCell>
            <TextCell>
              <FormattedMessage
                id={
                  data?.feeType === 2
                    ? "transferInternal.historyTransferBank.detail.typeAmount.value1"
                    : "transferInternal.historyTransferBank.detail.typeAmount.value0"
                }
              />
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.fee" />
            </LabelCell>
            <TextCell>{numeral(data?.feeAmt).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.formAdd.modal.content" />
            </LabelCell>
            <TextCell>{data?.remarks}</TextCell>
          </BodyModalRow>

          <CustomHr />
          <DivCheckbox>
            <CheckBox checked={checked} onChange={() => saveCheckModal()} />

            <FormattedMessage id="transferInternal.formAdd.modal.MessCheckNote" />
          </DivCheckbox>

          <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={closeModalHandler}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.cancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                className="button-confirm"
                color="gradientaAccept"
                onClick={() => {
                  onSuccess();
                }}
                width="100%"
              >
                <FormattedMessage id="transferInternal.btnConfirm" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
