import ChartComponent from "react-chartjs-2";
import { ChartData, ChartOptions, ChartTooltipModel } from "chart.js";
import * as Chart from "chart.js";
import { ChartDataSets } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import render, { div } from "helper/domBuilder";
import { createElementFromHTML, updateDomStyle } from "helper/domBuilder/utils";
import numeral from "numeral";
import {
  BubbleChartProps,
  BubbleChartDataset,
  BubbleChartDataPoint,
} from "./types";
import "./tooltip.css";
import { useIntl } from "react-intl";
import { getChartHtmlTooltipPosition } from "helper/utils";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";

const styleChildren = {
  color: "#ffffff",
  "font-size": "12px",
  "line-height": "16px",
  "font-weight": "normal",
};

export default function MarketBreathBubbleChart({
  scaleData,
  colors,
}: BubbleChartProps) {
  const intl = useIntl();
  const themeType = useSelector(themeTypeSelector);

  const chartData: ChartData = {
    datasets: [
      {
        data: scaleData.map(
          ({
            changedPercentVolumeWithAvg5d,
            contributionRate,
            marketPercent,
            contributionValue,
            name,
          }) => ({
            x:
              changedPercentVolumeWithAvg5d! > 3
                ? 3
                : changedPercentVolumeWithAvg5d!,
            y: contributionRate,
            r: marketPercent,
            avg5d: changedPercentVolumeWithAvg5d,
            contributionValue: contributionValue,
            title: name,
          })
        ),
        backgroundColor: function (ctx: {
          chart?: Chart;
          dataIndex: number;
          dataset?: ChartDataSets;
          datasetIndex?: number;
        }) {
          let colorValue = colors[ctx.dataIndex];
          return colorValue;
        },
      },
    ] as BubbleChartDataset[],
  };
  const chartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 20,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2, 4],
            color: themes[themeType].background2,
          },
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({
              id: "widgets.marketStats.yAxesLabel",
            }),
            fontColor: themes[themeType].grey50,
          },
          ticks: {
            fontColor: themes[themeType].grey30,
            maxTicksLimit: 5,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2, 4],
            color: themes[themeType].background2,
          },
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({
              id: "widgets.marketStats.xAxesLabel",
            }),
            fontColor: themes[themeType].grey50,
          },
          ticks: {
            fontColor: themes[themeType].textcolorWhite,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value: {
          x: number;
          y: number;
          r: number;
          title: string;
        }) => {
          if (value.r < 0.5) return "";
          return value.title;
        },
        display: true,
        color: themes[themeType].textcolorWhite,
        font: {
          size: 12,
          family: themes[themeType].fontFamily,
          weight: 600,
        },
      },
    },
    onHover: function (evt, elements) {
      let tooltipEl = document.getElementById("chartjs-tooltip");
      if (elements && elements.length) {
        return;
      }
      if (tooltipEl) {
        updateDomStyle(tooltipEl, {
          opacity: "0",
        });
      }
    },
    tooltips: {
      enabled: false,
      custom: function tooltip(tooltipModel: ChartTooltipModel): void {
        let tooltipEl = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = createElementFromHTML(
            render(
              div({
                id: "chartjs-tooltip",
                children: div({
                  style: {
                    color: themes[themeType].textcolorWhite,
                    background: themes[themeType].overlayGray,
                    border: "px solid black",
                    "min-width": "250px",
                    padding: "8px 12px",
                    "margin-top": "20px",
                    "margin-left": "-20px",
                  },
                  children: "",
                }),
              })
            )
          ) as HTMLElement;
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          updateDomStyle(tooltipEl, {
            opacity: "0",
          });
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove(
          "market-line-chart-tooltip",
          "above",
          "below",
          "no-transform",
          "top",
          "bottom",
          "center",
          "left",
          "right"
        );
        tooltipEl.className = "";
        tooltipEl.classList.add("bubble-chart");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        if (tooltipModel.xAlign) {
          tooltipEl.classList.add(tooltipModel.xAlign);
        }
        // Set Text
        if (tooltipModel.body) {
          let dataPoint = tooltipModel.dataPoints[0];
          let tooltipHeader = "";
          let changedPercentVolumeWithAvg5d = "";
          let contributionPercentage = "";
          let contributionValue = "";
          let marketPercent = "";
          if (dataPoint && dataPoint.index != null) {
            let item = chartData.datasets![0].data![
              dataPoint.index
            ] as BubbleChartDataPoint;
            tooltipHeader = item.title;
            changedPercentVolumeWithAvg5d =
              item.avg5d != null ? numeral(item.avg5d).format("0.0") : "";
            contributionPercentage =
              item.y != null ? `${numeral(item.y).format("0.000")}%` : "";
            contributionValue =
              item.contributionValue != null
                ? numeral(item.contributionValue).format("0.0")
                : "";
            marketPercent =
              item.r != null ? `${numeral(item.r).format("0.0")}%` : "";
          }

          const headerElement = div({
            children: tooltipHeader,
            style: {
              "font-family": themes[themeType].fontFamily,
              "font-weight": "700",
              "font-size": "15px",
              "line-height": "20px",
              "margin-bottom": "4px",
              color: themes[themeType].textcolorWhite,
            },
          });

          const changedPercentVolumeWithAvg5dElement = div({
            style: {
              "font-family": themes[themeType].fontFamily,
              display: "flex",
              "justify-content": "space-between",
              "margin-bottom": "4px",
            },
            children: [
              div({
                children: intl.formatMessage({
                  id: "widgets.marketStats.changedPercentVolumeWithAvg5d",
                }),
                style: {
                  ...styleChildren,
                },
              }),
              div({
                children: changedPercentVolumeWithAvg5d,
                style: {
                  ...styleChildren,
                },
              }),
            ],
          });

          const contributionPercentageElement = div({
            style: {
              "font-family": themes[themeType].fontFamily,
              display: "flex",
              color: themes[themeType].textcolorWhite,
              "margin-bottom": "4px",
              "justify-content": "space-between",
            },
            children: [
              div({
                children: intl.formatMessage({
                  id: "widgets.marketStats.contributionPercentage",
                }),
                style: {
                  ...styleChildren,
                },
              }),
              div({
                children: contributionPercentage,
                style: {
                  ...styleChildren,
                },
              }),
            ],
          });

          const contributionValueElement = div({
            style: {
              "font-family": themes[themeType].fontFamily,
              display: "flex",
              color: themes[themeType].textcolorWhite,
              "margin-bottom": "4px",
              "justify-content": "space-between",
            },
            children: [
              div({
                children: intl.formatMessage({
                  id: "widgets.marketStats.contributionValue",
                }),
                style: {
                  ...styleChildren,
                },
              }),
              div({
                children: contributionValue,
                style: {
                  ...styleChildren,
                },
              }),
            ],
          });

          const marketPercentElement = div({
            style: {
              "font-family": themes[themeType].fontFamily,
              display: "flex",
              color: themes[themeType].textcolorWhite,
              "justify-content": "space-between",
            },
            children: [
              div({
                children: intl.formatMessage({
                  id: "widgets.marketStats.marketPercent",
                }),
                style: {
                  ...styleChildren,
                },
              }),
              div({
                children: marketPercent,
                style: {
                  ...styleChildren,
                },
              }),
            ],
          });

          const tooltipElement = div({
            children: [
              headerElement,
              changedPercentVolumeWithAvg5dElement,
              contributionPercentageElement,
              contributionValueElement,
              marketPercentElement,
            ],
          });

          let root = tooltipEl.children[0];
          root.innerHTML = render(tooltipElement);
        }

        const chart = (this as any)._chart;
        let barWidth = 32;
        let spaceOffset = 12;
        if (tooltipModel.body) {
          let dataPoint = tooltipModel.dataPoints[0];
          if (dataPoint && dataPoint.index != null) {
            let item = chartData.datasets![0].data![
              dataPoint.index
            ] as BubbleChartDataPoint;
            if (item && item.r) {
              barWidth = barWidth + 2 * item.r;
              spaceOffset = spaceOffset - item.r;
            }
          }
        }
        const { top, left } = getChartHtmlTooltipPosition(
          chart,
          tooltipEl,
          tooltipModel,
          barWidth,
          spaceOffset
        );

        updateDomStyle(tooltipEl, {
          opacity: "1",
          position: "absolute",
          "pointer-events": "none",
          left: `${left}px`,
          top: `${top}px`,
          "font-family": themes[themeType].fontFamily,
          "font-size": tooltipModel.bodyFontSize + "px",
          "font-style": tooltipModel._bodyFontStyle,
          padding: tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px",
        });
      },
    },
  };

  const plugins = [
    ChartDataLabels,
    {
      afterDatasetUpdate: (chart: Chart) => {
        chart.getDatasetMeta(0).data.forEach((v: any) => {
          if (v._model.radius > 2) {
            v._model.radius *= 5;
          } else {
            v._model.radius = 5;
          }
          v._options.hoverRadius = v._model.radius;
        });
      },
    },
  ];
  return (
    <ChartComponent
      type="bubble"
      data={chartData}
      options={chartOptions}
      plugins={plugins}
    />
  );
}
