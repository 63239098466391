import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetInquiryBankUtilityParams,
  IEntryBankUtilityParams,
  IEditHoldUnholdBankAccountParams,
} from "core/http/apis/hold-unhold-bank-account/types";
import {
  IGetInquiryBankUtilityResponse,
  IEntryBankUtilityResponse,
} from "domain/models/HoldUnholdBankAccount";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
}

export interface ReduxData {
  filter: IFilter;

  loading: boolean;
  activeTab: string;
  statusDelete: boolean;

  holdUnholdBankAccount?: IEditHoldUnholdBankAccountParams;
  isShowConfirmModal: boolean;
  statusEntryBankUtility: boolean;
}

const initialState: ReduxData = {
  filter: {
    subAccoNo: "",
    fromDate: new Date(),
    toDate: new Date(),
  },

  loading: false,
  activeTab: "HOLD_UNHOLD_BANK_ACCOUNT",
  statusDelete: false,
  isShowConfirmModal: false,
  statusEntryBankUtility: false,
};

const holdUnholdBankAccountSlice = createSlice({
  name: "holdUnholdBankAccountSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },

    getInquiryBankUtilityRequest: (
      state,

      action: PayloadAction<IGetInquiryBankUtilityParams>
    ) => {},
    getInquiryBankUtilitySuccess: (
      state,
      action: PayloadAction<IGetInquiryBankUtilityResponse>
    ) => {
      state.holdUnholdBankAccount = {
        ...state.holdUnholdBankAccount,
        bankAccoNumber: action.payload.data.subAccoData.bankAccoNumber,
        bankName: action.payload.data.subAccoData.bankCd,
        bankBranch: action.payload.data.subAccoData.bankBranchCd,
        bankAvaiable: action.payload.data.bankAvail,
        avaiableUnholdAmt: action.payload.data.availUnholdAmt,
        totalUnholdAmt: action.payload.data.holdAmt,
        avaiableHoldAmt: action.payload.data.availHoldAmt,
      };
    },
    getInquiryBankUtilityFailure: (state) => {},

    entryBankUtilityRequest: (
      state,

      action: PayloadAction<IEntryBankUtilityParams>
    ) => {
      state.statusEntryBankUtility = false;
    },
    entryBankUtilitySuccess: (
      state,
      action: PayloadAction<IEntryBankUtilityResponse>
    ) => {
      state.statusEntryBankUtility = true;
    },
    entryBankUtilityFailure: (state) => {
      state.statusEntryBankUtility = false;
    },

    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        subAccoNo?: string;

        actionType?: number;
        amount?: number;
      }>
    ) => {},
    updateModalStatusSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowConfirmModal = action.payload;
    },
    updateItemEdit: (
      state,
      action: PayloadAction<IEditHoldUnholdBankAccountParams | undefined>
    ) => {
      if (!action.payload) {
        state.holdUnholdBankAccount = undefined;
        return;
      }

      state.holdUnholdBankAccount = {
        ...state.holdUnholdBankAccount,
        ...action.payload,
      };
    },
  },
});

export const {
  getInquiryBankUtilityRequest,
  getInquiryBankUtilitySuccess,
  getInquiryBankUtilityFailure,

  entryBankUtilityRequest,
  entryBankUtilitySuccess,
  entryBankUtilityFailure,

  updateItemEdit,

  updateModalStatusRequest,
  updateModalStatusSuccess,
} = holdUnholdBankAccountSlice.actions;

export default holdUnholdBankAccountSlice.reducer;
