import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Input, Select, Spacer } from "components/commons";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { methods } from ".";
import { FormControl, FormTitle, Error } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeCondition,
  onChangeExpiredDate,
  onChangeMatMethod,
  onChangeOptType,
  onChangeOptVal,
} from "modules/stock-order/redux/placeOrder";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import {
  onChangeOptValByEnter,
  onChangeVolumeByEnter,
} from "modules/stock-order/redux";
import { tradeDateSelector } from "modules/auth/selectors";
import moment from "moment";

const pattern = new RegExp(/^\d+(\.\d{0,9})?$/);

const timeConditionOrderConditionsList = (intl: IntlShape) => [
  {
    value: "01",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.conditionsList.noCondition",
    }),
  },
  {
    value: "02",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.conditionsList.referencePrices",
    }),
  },
];

const PriceTCList = (intl: IntlShape) => [
  { value: "01", label: ">=" },
  { value: "02", label: "<=" },
];

const orderBookSelector = (state: RootState) => state.stockOrder;

const placeOrderSelector = createSelector(
  orderBookSelector,
  (state) => state.placeOrder
);

const TimeConditionOrder: React.FC = React.memo(() => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const refOptVal = useRef<HTMLInputElement>(null);
  const [isVisibleErrorOtpVal, setIsVisibleErrorOtpVal] = useState(true);

  const {
    root: { changeVolumeByEnter },
  } = useSelector(orderBookSelector);

  const {
    optType,
    optVal,
    matMethod,
    expiredDate,
    condition,
    errorValidateOtpVal,
  } = useSelector(placeOrderSelector);
  const tradeDate = useSelector(tradeDateSelector);

  const onBlurEnter = () => {
    dispatch(onChangeVolumeByEnter(false));
    dispatch(onChangeOptValByEnter(false));
    setIsVisibleErrorOtpVal(false);
  };

  const handleKeyDown = (e: any, typeInput: string) => {
    if (e.key === "Enter" && typeInput === "optVal") {
      dispatch(onChangeOptValByEnter(true));
    }
  };

  useEffect(() => {
    if (changeVolumeByEnter) {
      refOptVal.current?.focus();
    }
  }, [changeVolumeByEnter]);

  useEffect(() => {
    if (errorValidateOtpVal === "") {
      setIsVisibleErrorOtpVal(true);
    } else {
      setIsVisibleErrorOtpVal(false);
    }
  }, [errorValidateOtpVal]);

  const handleChangeOtpVal = (value: string) => {
    if (pattern.test(value)) {
      dispatch(onChangeOptVal(value));
      setIsVisibleErrorOtpVal(true);
    }
    if (value === "") {
      dispatch(onChangeOptVal(""));
    }
  };

  const handleFocusInputOtpVal = () => {
    setIsVisibleErrorOtpVal(true);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.method" />
        </FormTitle>
        <FormControl>
          <Select
            value={methods(intl).find((x) => x.value === matMethod)}
            className="flex-fill"
            options={methods(intl)}
            onChange={(item) =>
              item && item.value && dispatch(onChangeMatMethod(item.value))
            }
          />
        </FormControl>
      </div>
      <Spacer size="s" />
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.condition" />
        </FormTitle>
        <FormControl>
          <Select
            value={timeConditionOrderConditionsList(intl).find(
              (x) => x.value === condition
            )}
            className="flex-fill"
            options={timeConditionOrderConditionsList(intl)}
            onChange={(item) =>
              item && item.value && dispatch(onChangeCondition(item.value))
            }
          />
        </FormControl>
      </div>
      <Spacer size="s" />
      {condition === "02" && (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <FormTitle>
              <FormattedMessage id="widgets.placeOrder.conditionalOrder.priceTC" />
            </FormTitle>
            <FormControl className="d-flex flex-row mb-2">
              <div className="flex-1">
                <Select
                  className="flex-fill"
                  value={optType}
                  options={PriceTCList(intl)}
                  onChange={(option) =>
                    option && dispatch(onChangeOptType(option))
                  }
                />
              </div>
              <Spacer size="s" />
              <div className="flex-3">
                <Input
                  innerRef={refOptVal}
                  value={optVal === 0 ? "" : optVal}
                  onChange={(price) => handleChangeOtpVal(price.target.value)}
                  onBlur={onBlurEnter}
                  onKeyDown={(e) => handleKeyDown(e, "optVal")}
                  placeholder={intl.formatMessage({
                    id: "formPlaceholder.inputOptValByValue",
                  })}
                  onFocus={handleFocusInputOtpVal}
                />
              </div>
            </FormControl>
          </div>
          {!isVisibleErrorOtpVal && errorValidateOtpVal && (
            <>
              <Error>
                <span className="text-danger flex-1 text-right">
                  {errorValidateOtpVal}
                </span>
              </Error>
              <Spacer size="s" />
            </>
          )}
        </div>
      )}
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.toDate" />
        </FormTitle>
        <FormControl>
          <DatePicker
            selected={new Date(expiredDate)}
            onChange={(e: Date) => dispatch(onChangeExpiredDate(e))}
            fullWidth
            minDate={moment(tradeDate, "YYYYMMDD").toDate()}
          />
        </FormControl>
      </div>
      <Spacer size="s" />
    </>
  );
});
export default TimeConditionOrder;
