import HttpClient from "../../index";
import { REACT_APP_RESTFUL_FEEDBACK_URL } from "AppConfigNew";

export default class HttpFeedback extends HttpClient {
  private static classInstance?: HttpFeedback;
  private constructor() {
    super(REACT_APP_RESTFUL_FEEDBACK_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpFeedback();
    }

    return this.classInstance;
  }
}
