import { AppReducerType } from "./types";
import authRecuder from "modules/auth/redux";
import categoriesReducer from "modules/categories/redux";
import { combineReducers } from "redux";
import marketReducer from "modules/market/redux";
import tickerDetailsReducer from "modules/tickerDetails/redux";
import languageRecuder from "languages/redux";
import themeReducer from "themes/redux";
import systemReducer from "modules/system/redux";
import marginContractReducer from "modules/margin-contract/redux";
import rightInfoReducer from "modules/right-info/redux";
import tradingViewReducer from "modules/auth/redux/tradingView";
import marginSecuritiesReducer from "modules/margin-securities/redux";
import statisticOndayReducer from "modules/statistic-onday/redux";
import statisticPriceReducer from "modules/statistic-price/redux";
import statisticUpdownReducer from "modules/statistic-updown/redux";
import statisticHighestUpdownReducer from "modules/statistic-highest-updown/redux";
import mrgContractDebitReducer from "modules/mrg-contract-debit/redux";
import marginContractWatchReducer from "modules/margin-contract-watch/redux";
import transferLocalReducer from "modules/transfer-local/redux";
import stockOrderReducer from "modules/stock-order/redux";
import stockOrderDerReducer from "modules/stock-order-der/redux";
import orderBookReducer from "modules/order-book/redux";
import orderBookWidgetReducer from "modules/order-book/redux/index";
import transferInternalReducer from "modules/transfer-internal/redux";
import orderHistoryReducer from "modules/order-history/redux";
import transferShareReducer from "modules/transfer-share/redux";
import screenersReducer from "modules/screeners/redux";
import holdUnholdBankAccount from "modules/hold-unhold-bank-account/redux";
import profitLossReducer from "modules/profit-loss/redux";
import advanceReducer from "modules/advance/redux";
import subscribeBuy from "modules/subscribeBuy/redux";
import createContractReducer from "modules/create-contract/redux";
import accountStatmentReducer from "modules/account-statement/redux";
import marginSecuritiesPortfolioReducer from "modules/margin-securities-portfolio/redux";
import positionManagementReducer from "modules/position-management/redux";
import depositListReducer from "modules/deposit-list/redux";
import addFrameworkContractFormReducer from "modules/add-framework-constract-form/redux";
import transferBondReducer from "modules/transfer-bond/redux";
import bidAskBondOrderReducer from "modules/bid-ask-bond-order/redux";
import registerToTransferBondsReducer from "modules/register-to-transfer-bonds/redux";
import placeOrderTransferBondsReducer from "modules/place-order-transfer-bonds/redux";
import depositPowerReducer from "modules/deposit-power/redux";
import depositContractReducer from "modules/deposit-contract/redux";
import bidAskBondReducer from "modules/bid-ask-bond/redux";
import assetsReducer from "modules/assetsIncurred/redux";
import changeLimitReducer from "modules/change-limit/redux";
import advanceAutoReducer from "modules/auth/pages/ModalSetting/MenuItem/AdvanceAuto/redux";
import professionalInvestorReducer from "modules/professional-investor/redux";
import modalRegister from "modules/auth/redux/modalRegister";
import placeOrderRetailBondsReducer from "modules/place-order-retail-bonds/redux";
import contractDebitReducer from "modules/contract-debit/redux";
import bidAskDBondOrderReducer from "modules/bid-ask-d-bond-order/redux";
import placeOrderBondAdvReducer from "modules/placeOrderBondAdv/redux";
import guideVideoReducer from "modules/guideVideo/redux";

export default combineReducers({
  [AppReducerType.LANGUAGE]: languageRecuder,
  [AppReducerType.AUTH]: authRecuder,
  [AppReducerType.THEME]: themeReducer,
  [AppReducerType.CATEGORIES]: categoriesReducer,
  [AppReducerType.STOCK_ORDER]: stockOrderReducer,
  [AppReducerType.STOCKORDERDER]: stockOrderDerReducer,
  [AppReducerType.ORDER_BOOK]: orderBookReducer,
  [AppReducerType.ORDER_BOOK_WIDGET]: orderBookWidgetReducer,
  [AppReducerType.TICKER_DETAILS]: tickerDetailsReducer,
  [AppReducerType.MARKET]: marketReducer,
  [AppReducerType.SYSTEM]: systemReducer,
  [AppReducerType.MARGIN_CONTRACT]: marginContractReducer,
  [AppReducerType.RIGHT_INFO]: rightInfoReducer,
  [AppReducerType.TRADINGVIEWSESSION]: tradingViewReducer,
  [AppReducerType.MARGIN_SECURITIES]: marginSecuritiesReducer,
  [AppReducerType.STATISTIC_ONDAY]: statisticOndayReducer,
  [AppReducerType.STATISTIC_PRICE]: statisticPriceReducer,
  [AppReducerType.STATISTIC_UPDOWN]: statisticUpdownReducer,
  [AppReducerType.STATISTIC_HIGHEST_UPDOWN]: statisticHighestUpdownReducer,
  [AppReducerType.MRG_CONTRACT_DEBIT]: mrgContractDebitReducer,
  [AppReducerType.MARGIN_CONTRACT_WATCH]: marginContractWatchReducer,
  [AppReducerType.TRANSFER_LOCAL]: transferLocalReducer,
  [AppReducerType.ORDER_HISTORY]: orderHistoryReducer,
  [AppReducerType.PROFIT_LOSS]: profitLossReducer,
  [AppReducerType.ADVANCE]: advanceReducer,
  [AppReducerType.HOLD_UNHOLD_BANK_ACCOUNT]: holdUnholdBankAccount,
  [AppReducerType.SCREENERS]: screenersReducer,
  [AppReducerType.TRANSFER_SHARE]: transferShareReducer,
  [AppReducerType.CONTRACT_DEBIT]: contractDebitReducer,
  [AppReducerType.ACCOUNT_STATEMENT]: accountStatmentReducer,
  [AppReducerType.TRANSFER_INTERNAL]: transferInternalReducer,
  [AppReducerType.DEPOSIT_POWER]: depositPowerReducer,
  [AppReducerType.CREATE_CONTRACT]: createContractReducer,
  [AppReducerType.DEPOSIT_CONTRACT]: depositContractReducer,
  [AppReducerType.MARGIN_SECURITIES_PORTFOLIO]:
    marginSecuritiesPortfolioReducer,
  [AppReducerType.DEPOSIT_LIST]: depositListReducer,
  [AppReducerType.POSITION_MANAGEMENT]: positionManagementReducer,
  [AppReducerType.BID_ASK_BOND_ORDER]: bidAskBondOrderReducer,
  [AppReducerType.SUBSCRIBE_BUY]: subscribeBuy,
  [AppReducerType.ADD_FRAMEWORK_CONSTRACT_FORM]:
    addFrameworkContractFormReducer,
  [AppReducerType.REGISTER_TRANSFER_BONDS]: registerToTransferBondsReducer,
  [AppReducerType.PLACE_ORDER_TRANSFER_BONDS]: placeOrderTransferBondsReducer,
  [AppReducerType.TRANSFER_BOND]: transferBondReducer,
  [AppReducerType.BID_ASK_BOND]: bidAskBondReducer,
  [AppReducerType.ASSETS]: assetsReducer,
  [AppReducerType.CHANGE_LIMIT]: changeLimitReducer,
  [AppReducerType.ADVANCE_AUTO]: advanceAutoReducer,
  [AppReducerType.PROFESSIONAL_INVESTOR]: professionalInvestorReducer,
  [AppReducerType.MODAL_REGISTER]: modalRegister,
  [AppReducerType.PLACE_ORDER_RETAIL_BONDS]: placeOrderRetailBondsReducer,
  [AppReducerType.BID_ASK_D_BOND_ORDER]: bidAskDBondOrderReducer,
  [AppReducerType.ORDER_BOND_ADV]: placeOrderBondAdvReducer,
  [AppReducerType.GUIDE_VIDEO]: guideVideoReducer,
});
