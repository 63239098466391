import HttpClient from "../../index";
import { RESTFUL_PASSWORD_URL } from "AppConfig";

export default class HttpPassword extends HttpClient {
  private static classInstance?: HttpPassword;
  private constructor() {
    super(RESTFUL_PASSWORD_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpPassword();
    }

    return this.classInstance;
  }
}
