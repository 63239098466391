import styled from "styled-components/macro";

export const Label = styled.div`
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  margin-right: 12px;
`;

export const Control = styled.div`
  padding: 4px 8px 4px 4px;
  display: flex;
  align-items: center;
  .flex-fill-select {
    width: ${({ theme }) => theme.select.width};
    * {
      font-size: ${({ theme }) => theme.typeFaces.body.size};
    }
  }
  .btn-sell-all {
    flex: 1;
    margin-left: 12px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  width: auto;
  background: ${({ theme }) => theme.background1};
  z-index: 1;
  margin: 0;
  padding: 4px 8px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  .flex-fill {
    max-width: ${({ theme }) => theme.input.width};
    & > div {
      min-height: unset !important;
      width: ${({ theme }) => theme.input.width};
    }
  }
`;

export const PortfolioBox = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const PortfolioContainer = styled.div`
  // Scroll Stiker - start
  height: calc(100% - 48px);
  overflow: auto;
  margin-bottom: 48px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(
        100% - ${({ theme }) => theme.tables.footer.height}
      ) !important;
      /* height: 100%; */
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
  // Scroll Stiker - end

  .row-info {
    background-color: ${({ theme }) => theme.grey70};
  }

  .row-info:nth-child(even) {
    background-color: ${({ theme }) => theme.background2};
  }
`;

export const TickerCode = styled.div<{ color?: string }>`
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, color }) => (color ? theme[color] : theme.yellow50)};
`;

export const Volume = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Gainloss = styled.div<{
  color: string;
}>`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, color }) => theme[color]};
`;

export const Percent = styled.div<{
  color: string;
}>`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, color }) => theme[color]};
`;

export const Button = styled.div`
  display: none;
  width: 100%;
`;

export const ButtonBuy = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.green50};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ButtonSell = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.red50};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const PercentParent = styled.div`
  height: ${({ theme }) => theme.tables.content.height};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonRefreshData = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;

  background-color: rgba(255, 255, 255, 0.2);

  svg {
    path {
      fill: ${({ theme }) => theme.textcolorWhite};
    }
  }
`;

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.background2};
  padding: 4px 12px;
`;
