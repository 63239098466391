import { IntlShape } from "react-intl";

export const dataChannelId = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "auth.register.step1.dataChannelId.value1.label",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "auth.register.step1.dataChannelId.value2.label",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "auth.register.step1.dataChannelId.value3.label",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "auth.register.step1.dataChannelId.value4.label",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "auth.register.step1.dataChannelId.value5.label",
    }),
  },
];

export const dataCustype = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "auth.register.step1.answer1.value1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "auth.register.step1.answer1.value2",
    }),
  },
];

export const dataForegin = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "auth.register.step1.answer2.value1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "auth.register.step1.answer2.value2",
    }),
  },
];
