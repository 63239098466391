/* eslint-disable no-console */
import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  DetailRow,
  DetailLabel,
  WrapLabel,
  DetailInput,
  WrapInput,
  WrapCheckBox,
  WrapInputCheckBox,
  TextAddForm,
  WrapForm,
} from "./style";
import { Button, Select, Input } from "components/commons";
import { ReactComponent as SelectedIcon } from "assets/image/svg/ic_checkbox_selected.svg";
import { ReactComponent as UnSquareSelectedIcon } from "assets/image/svg/squareUnCheckBox.svg";
import { ReactComponent as AddFormIcon } from "assets/image/svg/addFormRegister.svg";
import { ReactComponent as RemoveFormIcon } from "assets/image/svg/Plus2.svg";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import {
  updateStep,
  updateFormSubmit,
  updateDataStep7,
  updateDataStep7isOwner,
  updateParams,
} from "modules/auth/redux/modalRegister";
import {
  getBankListApi,
  getBankMasterApi,
} from "core/http/apis/modal-register";
import { useEffectOnce } from "react-use";
import { useState } from "react";
import { toast } from "components/commons/Toast";
import {
  selectDataCreateUser,
  selectParams,
} from "modules/auth/selectorsModalRegister";
import {
  // selectInformationExtraction,
  selectFormSubmit,
} from "modules/auth/selectorsModalRegister";

const Step7 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataCreate = useSelector(selectDataCreateUser);
  const formsubmit = useSelector(selectFormSubmit);
  const paramsForm = useSelector(selectParams);

  const conpanyCode = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "CU_ALLOW_ENTER_BANK_BRANCH"
      )
    : {};

  const [dataBank, setDataBank] = useState([]);
  const getBankMaster = async () => {
    const res: any = await getBankMasterApi();
    if (res?.statusCode === 0) {
      setDataBank(res.data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffectOnce(() => {
    getBankMaster();
  });

  const addFormsubmitregis = (key: any) => {
    if (formsubmit.dataFormStep7.length === 3) return;
    const newArr = [...formsubmit.dataFormStep7];
    dispatch(
      updateFormSubmit({
        value: [
          ...newArr,
          (newArr[key + 1] = {
            bankAccountName: "",
            bankAccountNumber: "",
            bankBranchLocationCd: "",
            bankCd: "",
            bankBranchCd: "",
            isOwner: false,
          }),
        ],
        key: "dataFormStep7",
      })
    );
  };

  const removeForm = (key: any) => {
    if (formsubmit.dataFormStep7.length === 1) return;
    const newArr = [...formsubmit.dataFormStep7];
    dispatch(
      updateFormSubmit({
        value: newArr.filter((_: any, i: any) => i !== key),
        key: "dataFormStep7",
      })
    );
  };

  const changeInput = (value: any, key: string, i: any) => {
    const data = [...formsubmit.dataFormStep7];
    const object = data.find((_: any, index: any) => index === i);
    const values = {
      ...object,
      [key]: value,
    };
    dispatch(
      updateDataStep7({
        value: values,
        i: i,
      })
    );
  };

  const changeSelect = async (value: any, key: string, i: any) => {
    const data = [...formsubmit.dataFormStep7];
    const object = data.find((_: any, index: any) => index === i);
    const values = {
      ...object,
      [key]: value,
    };
    dispatch(
      updateDataStep7({
        value: values,
        i: i,
      })
    );

    if (values?.bankCd !== "" && values?.bankBranchLocationCd !== "") {
      const res: any = await getBankListApi({
        bankCd: values?.bankCd,
        locationCd: values?.bankBranchLocationCd,
      });
      if (res?.statusCode === 0) {
        dispatch(
          updateDataStep7({
            value: { ...values, listBranch: res?.data },
            i: i,
          })
        );
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    }
  };

  const changeCheckBox = (index: any, valueCheck: any) => {
    const data = [...formsubmit.dataFormStep7];
    const value = data.map((e: any, i: any) => {
      if (i === index && !valueCheck) {
        return { ...e, isOwner: true };
      } else {
        return { ...e, isOwner: false };
      }
    });
    console.log(value);

    dispatch(updateDataStep7isOwner({ value }));
  };

  const renderContent = () => {
    return formsubmit.dataFormStep7.map((e: any, i: any) => (
      <Wrap>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="auth.register.step7.label" />
            {i !== 0 && (
              <div onClick={() => removeForm(i)}>
                <RemoveFormIcon />
                <FormattedMessage id="auth.register.step7.btnRemoveAccount" />
              </div>
            )}
          </DetailLabel>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step7.question1.label" />
              </WrapLabel>
              <Input
                placeholder={intl.formatMessage({
                  id: "auth.register.step7.question1.placeholder",
                })}
                onChange={(e: any) =>
                  changeInput(e?.target?.value, "bankAccountName", i)
                }
                defaultValue={e?.bankAccountName}
              />
              {errorVali.error === "bankAccountName" && errorVali.index === i && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step7.question2.label" />
              </WrapLabel>
              <WrapInput>
                <WrapInputCheckBox>
                  <Input
                    placeholder={intl.formatMessage({
                      id: "auth.register.step7.question1.placeholder",
                    })}
                    onChange={(e: any) =>
                      changeInput(e?.target?.value, "bankAccountNumber", i)
                    }
                    defaultValue={e?.bankAccountNumber}
                  />
                </WrapInputCheckBox>

                <WrapCheckBox
                  onClick={() =>
                    changeCheckBox(i, formsubmit.dataFormStep7?.[i]?.isOwner)
                  }
                >
                  {formsubmit.dataFormStep7?.[i]?.isOwner === true && (
                    <SelectedIcon className="h-100" />
                  )}
                  {formsubmit.dataFormStep7?.[i]?.isOwner === false && (
                    <UnSquareSelectedIcon className="h-100" />
                  )}

                  <div>
                    <FormattedMessage id="auth.register.step7.question1.labelCheckbox" />
                  </div>
                </WrapCheckBox>
              </WrapInput>
              {errorVali.error === "bankAccountNumber" &&
                errorVali.index === i && (
                  <span className="text-error pt-1 text-danger float-right">
                    {intl.formatMessage({
                      id: "auth.register.messageErrorValidate",
                    })}
                  </span>
                )}
            </div>
          </DetailInput>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step7.question3.label" />
              </WrapLabel>
              <Select
                options={dataBank}
                placeholder={intl.formatMessage({
                  id: "auth.register.step7.question3.placeholder",
                })}
                getValue={"bankCd"}
                getLabel={"bankName"}
                onChange={(e: any) => changeSelect(e?.bankCd, "bankCd", i)}
                value={dataBank.find((x: any) => e?.bankCd === x?.bankCd)}
              />
              {errorVali.error === "bankCd" && errorVali.index === i && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step7.question4.label" />
              </WrapLabel>
              <Select
                placeholder={intl.formatMessage({
                  id: "auth.register.step7.question4.placeholder",
                })}
                options={dataCreate?.codeList}
                getValue="code"
                getLabel="codeValue1"
                onChange={(e: any) =>
                  changeSelect(e?.code, "bankBranchLocationCd", i)
                }
                value={dataCreate?.codeList.find(
                  (x: any) => e?.bankBranchLocationCd === x?.code
                )}
              />
              {errorVali.error === "bankBranchLocationCd" &&
                errorVali.index === i && (
                  <span className="text-error pt-1 text-danger float-right">
                    {intl.formatMessage({
                      id: "auth.register.messageErrorValidate",
                    })}
                  </span>
                )}
            </div>
          </DetailInput>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step7.question5.label" />
              </WrapLabel>
              {conpanyCode?.value === "true" && (
                <Input
                  onChange={(e: any) =>
                    changeInput(e?.target?.value, "bankBranchCd", i)
                  }
                />
              )}
              {conpanyCode?.value === "false" && (
                <Select
                  placeholder={intl.formatMessage({
                    id: "auth.register.step7.question5.placeholder",
                  })}
                  options={formsubmit?.dataFormStep7?.[i]?.listBranch}
                  getValue="bankBranchCd"
                  getLabel="bankBranchName"
                  onChange={(e: any) =>
                    changeInput(e?.bankBranchCd, "bankBranchCd", i)
                  }
                  value={
                    formsubmit?.dataFormStep7?.[i]?.listBranch
                      ? formsubmit?.dataFormStep7?.[i]?.listBranch.find(
                          (x: any) => e?.bankBranchCd === x?.bankBranchCd
                        )
                      : null
                  }
                />
              )}
              {errorVali.error === "bankBranchCd" && errorVali.index === i && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
            <div className="w-50" />
          </DetailInput>
          {i === formsubmit.dataFormStep7.length - 1 &&
            formsubmit.dataFormStep7.length < 3 && (
              <DetailInput>
                <TextAddForm onClick={() => addFormsubmitregis(i)}>
                  <AddFormIcon className="mr-1" />
                  <FormattedMessage id="auth.register.step7.btnAddAccount" />
                </TextAddForm>
              </DetailInput>
            )}
        </DetailRow>
      </Wrap>
    ));
  };

  const [errorVali, setErrorVali] = useState<any>({
    error: "",
    index: null,
  });

  const checkError = () => {
    const data = [...formsubmit.dataFormStep7];
    const keyCheck = [
      "bankAccountName",
      "bankAccountNumber",
      "bankCd",
      "bankBranchLocationCd",
      "bankBranchCd",
    ];

    if (data?.[0]?.bankAccountName !== "" || data.length > 1) {
      const require = data.some((e: any, i: any) => {
        const value = keyCheck.find((x: any) => e[x] === "");
        if (value !== undefined) {
          setErrorVali({ error: value, index: i });
          return false;
        } else {
          return true;
        }
      });
      return require === undefined || require ? true : false;
    } else {
      return true;
    }
  };

  const submitNext = () => {
    const checked = checkError();
    if (checked !== true) {
      return;
    }

    setErrorVali({ error: "", index: null });
    const data = [...formsubmit.dataFormStep7];
    const checkName = data.filter((e: any) => e?.isOwner === true);
    if (checkName.length !== 0) {
      if (
        checkName[0]?.bankAccountName.toLocaleLowerCase() !==
        `${paramsForm?.custFamilyName} ${paramsForm?.custGivenName}`.toLocaleLowerCase()
      ) {
        return toast("auth.register.step7.errorName", "error");
      }
    }
    const value = data.reduce((acc, item, idx) => {
      const val = {
        [`bankAccountName${idx + 1}`]: item?.bankAccountName,
        [`bankAccountNumber${idx + 1}`]: item?.bankAccountNumber,
        [`bankBranchLocationCd${idx + 1}`]: item?.bankBranchLocationCd,
        [`bankCd${idx + 1}`]: item?.bankCd,
        [`bankBranchCd${idx + 1}`]: item?.bankBranchCd,
        [`isOwner${idx + 1}`]: item?.isOwner,
      };
      acc = { ...acc, ...val };
      return acc;
    }, {});
    dispatch(updateParams(value));
    dispatch(updateStep(STEPS.STEPS_9));
  };

  return (
    <Container>
      <WrapForm>{renderContent()}</WrapForm>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_7))}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter"
          width="120px"
          onClick={() => submitNext()}
        >
          {intl.formatMessage({
            id: "auth.register.btnNext",
          })}
        </Button>
      </div>
    </Container>
  );
};

export default Step7;
