import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 100%;
  color: ${({ theme }) => theme.grey0};
  .button-filter1 {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
  }
  .marginRight {
    margin-right: 0.8rem !important;
  }
  .marginRight2 {
    margin-right: 1.7rem !important;
  }
  .text-grey {
    color: ${({ theme }) => theme.grey20};
  }
`;
export const ContentLeft = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
border: 1px solid ${({ theme }) => theme.border1};
border-radius: 8px;
color: ${({ theme }) => theme.grey0};
width: 20%;
height: 100%;
background: ${({ theme }) => theme.background1};
.input-bg-dark {
  background: ${({ theme }) => theme.background1};
`;
export const ContentRight = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

border: 1px solid ${({ theme }) => theme.border1};

border-radius: 8px;
width: 80%;
height: 100%;
.text-white .input-container,
.text-white .css-1jpjy99-control,
.text-white .react-datepicker-wrapper {
  background: ${({ theme }) => theme.grey100} !important;
}
  background: ${({ theme }) => theme.background1};
.input-bg-dark {
  background: ${({ theme }) => theme.background1};

`;

export const LabelCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${({ theme }) => theme.grey0};
  font-weight: bold;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  padding-left: 12px;
`;
