/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  DetailRow,
  DetailLabel,
  WrapLabel,
  DetailInput,
} from "./style";
import { Button, Select, Input, DatePicker } from "components/commons";
import { STEPS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { dataSex } from "./constants";
import { useMemo, useState } from "react";
import {
  updateFormSubmit,
  updateParams,
  updateStep,
} from "modules/auth/redux/modalRegister";
import {
  selectInformationExtraction,
  selectFormSubmit,
  selectDataCreateUser,
} from "modules/auth/selectorsModalRegister";
import { formatEmail, formatPhoneNumber } from "helper/utils";
import { isEmpty } from "lodash";

const Step52 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const information = useSelector(selectInformationExtraction);
  const formSubmit = useSelector(selectFormSubmit);
  const dataButtons = useMemo(() => dataSex(intl), [intl]);
  const dataCreate = useSelector(selectDataCreateUser);

  const updateForm = (value: any, key: string) => {
    dispatch(updateFormSubmit({ value, key }));
  };

  const valuecodeList = useMemo(() => {
    if (formSubmit.issueLocationCd !== "") {
      return dataCreate?.codeList.find(
        (e: any) => e?.code === formSubmit.issueLocationCd
      );
    } else {
      return dataCreate?.codeList.find(
        (e: any) => e?.codeValue1 === information?.issueLocationCd
      );
    }
  }, [dataCreate, information?.issueLocationCd, formSubmit.issueLocationCd]);

  const checkAutoRegister = () => {
    const val = [
      "custFamilyName",
      "issueDate",
      "idNumber",
      "custGivenName",
      "birthDay",
    ].find(
      (e: any) => information[e] !== formSubmit[e] && formSubmit[e] !== ""
    );
    const checkAress =
      formSubmit?.address === "" ? information?.address : formSubmit?.address;
    if (checkAress !== information?.address) {
      return "address";
    }
    if (valuecodeList?.codeValue1 !== information?.issueLocationCd) {
      return "issueLocationCd";
    }
    return val;
  };

  const [errorValidation, setErrorValidation] = useState<string>("");
  const changePhone = (v: any) => {
    const check = formatPhoneNumber(v);

    if (check) {
      setErrorValidation("");
    } else {
      setErrorValidation("phone");
    }
  };

  const changeEmail = (v: any) => {
    const check = formatEmail(v);
    if (check) {
      setErrorValidation("");
    } else {
      setErrorValidation("email");
    }
  };

  const [errors, setErrors] = useState<any>();
  const submitNextStep = () => {
    if (errorValidation !== "") return;
    const keyCheck = [
      "custFamilyName",
      "custGivenName",
      "sex",
      "mobileNo",
      "emailAdrs",
    ];
    const value = keyCheck.find((e: string) => formSubmit[e] === "");
    setErrors(value);
    const checkAress =
      formSubmit?.address === "" ? information?.address : formSubmit?.address;
    if (checkAress === "") {
      return setErrors("address");
    }
    const valueCheckEdit = checkAutoRegister();
    if (!value) {
      dispatch(
        updateParams({
          custFamilyName: formSubmit.custFamilyName,
          custGivenName: formSubmit.custGivenName,
          sex: formSubmit.sex,
          mobileNo: formSubmit.mobileNo,
          emailAdrs: formSubmit.emailAdrs,
          address:
            formSubmit.address === ""
              ? information?.address
              : formSubmit?.address,
          birthDay: formSubmit.birthDay,
          changeInfoFlg: !isEmpty(valueCheckEdit) ? 0 : 1,
        })
      );

      dispatch(updateStep(STEPS.STEPS_7));
    }
  };

  const valueSex = useMemo(() => {
    return dataButtons.filter((e) => e.value === formSubmit.sex);
  }, [formSubmit.sex]);

  return (
    <Container>
      <Wrap>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="auth.register.step5_2.label" />
          </DetailLabel>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question1.label" />
                <span className="text-danger pl-1 mb-0">*</span>
              </WrapLabel>
              <Input
                defaultValue={formSubmit?.custFamilyName}
                onChange={(e: any) =>
                  updateForm(e?.target?.value, "custFamilyName")
                }
              />
              {errors === "custFamilyName" && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question2.label" />
                <span className="text-danger pl-1 mb-0">*</span>
              </WrapLabel>
              <Input
                defaultValue={formSubmit?.custGivenName}
                onChange={(e: any) =>
                  updateForm(e?.target?.value, "custGivenName")
                }
              />
              {errors === "custFamilyName" && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
          </DetailInput>
          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question3.label" />
              </WrapLabel>
              <DatePicker
                selected={
                  formSubmit?.birthDay
                    ? moment(formSubmit?.birthDay, "DD/MM/YYYY").toDate()
                    : null
                }
                onChange={(e: any) =>
                  updateForm(moment(e).format("DD/MM/YYYY"), "birthDay")
                }
                popperPlacement="bottom-start"
              />
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question4.label" />
                <span className="text-danger pl-1 mb-0">*</span>
              </WrapLabel>
              <Select
                options={dataButtons}
                value={valueSex}
                onChange={(e: any) => updateForm(e?.value, "sex")}
              />
              {errors === "sex" && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
          </DetailInput>

          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question5.label" />
                <span className="text-danger pl-1 mb-0">*</span>
              </WrapLabel>
              <Input
                onChange={(e: any) => updateForm(e?.target?.value, "mobileNo")}
                defaultValue={formSubmit?.mobileNo}
                onBlur={(e: any) => changePhone(e?.target?.value)}
              />
              {errors === "mobileNo" && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
              {errorValidation === "phone" &&
                (errors === undefined || errors === "") && (
                  <span className="text-error pt-1 text-danger float-right">
                    {intl.formatMessage({
                      id: "auth.register.step5_2.errorValidate.phone",
                    })}
                  </span>
                )}
            </div>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question6.label" />
                <span className="text-danger pl-1 mb-0">*</span>
              </WrapLabel>
              <Input
                onChange={(e: any) => updateForm(e?.target?.value, "emailAdrs")}
                defaultValue={formSubmit?.emailAdrs}
                onBlur={(e: any) => changeEmail(e?.target?.value)}
              />
              {errors === "emailAdrs" && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
              {errorValidation === "email" &&
                (errors === undefined || errors === "") && (
                  <span className="text-error pt-1 text-danger float-right">
                    {intl.formatMessage({
                      id: "auth.register.step5_2.errorValidate.email",
                    })}
                  </span>
                )}
            </div>
          </DetailInput>

          <DetailInput>
            <div className="w-50">
              <WrapLabel>
                <FormattedMessage id="auth.register.step5_2.question7.label" />
                <span className="text-danger pl-1 mb-0">*</span>
              </WrapLabel>
              <Input
                onChange={(e: any) => updateForm(e?.target?.value, "address")}
                // defaultValue={formSubmit?.address === "" ? information?.address : formSubmit?.address}
                defaultValue={
                  formSubmit?.address === ""
                    ? information?.address
                    : formSubmit?.address
                }
              />
              {errors === "address" && (
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              )}
            </div>
            <div className="w-50" />
          </DetailInput>
        </DetailRow>
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_5))}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter"
          width="120px"
          onClick={() => submitNextStep()}
        >
          {intl.formatMessage({
            id: "auth.register.btnNext",
          })}
        </Button>
      </div>
    </Container>
  );
};

export default Step52;
