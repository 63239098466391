import { toast } from "components/commons/Toast";
import {
  findMarginContract,
  getListMarginSecuritiesPortfolio,
  searchListMarginSecuritiesPortfolio,
} from "core/http/apis/margin-securities-portfolio";
import { IBasePairValueResponse } from "domain/models/BasePairValue";

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  findMarginContractFailure,
  findMarginContractRequest,
  findMarginContractSuccess,
  getListMarginSecuritiesPortfolioFailure,
  getListMarginSecuritiesPortfolioRequest,
  getListMarginSecuritiesPortfolioSuccess,
  searchListRequest,
  searchListSuccess,
  searchListFailure,
  fetchMoreListRequest,
  fetchMoreListSuccess,
  updatePage,
} from "./redux";

export function* getListMarginSecuritiesPortfolioWorker(
  action: ReturnType<typeof getListMarginSecuritiesPortfolioRequest>
) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      getListMarginSecuritiesPortfolio,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getListMarginSecuritiesPortfolioSuccess(response));
    } else {
      yield put(getListMarginSecuritiesPortfolioFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListMarginSecuritiesPortfolioFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchListWorker(action: ReturnType<typeof searchListRequest>) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      searchListMarginSecuritiesPortfolio,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchListSuccess(response));
    } else {
      yield put(searchListFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchListFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findMarginContractWorker(
  action: ReturnType<typeof findMarginContractRequest>
) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      findMarginContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findMarginContractSuccess(response));
    } else {
      yield put(findMarginContractFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findMarginContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

export function* getListMarginSecuritiesPortfolioWatcher() {
  yield takeLatest(
    getListMarginSecuritiesPortfolioRequest.type,
    getListMarginSecuritiesPortfolioWorker
  );
}
function* searchListWatcher() {
  yield takeLatest(searchListRequest.type, searchListWorker);
}
function* findMarginContractWatcher() {
  yield takeLatest(findMarginContractRequest.type, findMarginContractWorker);
}

function* fetchMoreListWatcher() {
  yield takeLatest(fetchMoreListRequest.type, fetchMoreWorker);
}

function* fetchMoreWorker(action: ReturnType<typeof fetchMoreListRequest>) {
  try {
    const params: any = action.payload;

    const response: IBasePairValueResponse = yield call(
      searchListMarginSecuritiesPortfolio,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updatePage(params?.page ? params?.page : 0));
      yield put(fetchMoreListSuccess(response));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

export default function* getListMarginSecuritiesPortfolioSaga() {
  yield all([
    getListMarginSecuritiesPortfolioWatcher(),
    searchListWatcher(),
    findMarginContractWatcher(),
    fetchMoreListWatcher(),
  ]);
}
