import { HeaderCell, TableCellContent, TableHeaderCellContent } from "./styles";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import numeral from "numeral";

const flexStart = "flex-start";
const center = "center";
const flexEnd = "flex-end";

export const columnOpen = [
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.KLLONG" />
      </HeaderCell>
    ),
    accessor: "longQty",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props?.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={center} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.KLSHORT" />
      </HeaderCell>
    ),
    accessor: "shortQty",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props?.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={center} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.price" />
      </HeaderCell>
    ),
    accessor: "currentPrice",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props?.value).format("0,0.0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={center} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.priceTbLong" />
      </HeaderCell>
    ),
    accessor: "longAvgPrice",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props?.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={center} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.priceTbShort" />
      </HeaderCell>
    ),
    accessor: "shortAvgPrice",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props?.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (props: any) => {
      return (
        <TableHeaderCellContent align={flexEnd} isFooter>
          <TableCellContent align={flexEnd}>
            <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.sum" />
          </TableCellContent>
        </TableHeaderCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.profit" />
      </HeaderCell>
    ),
    accessor: "profiltAmt",
    Cell: (props: any) => {
      return (
        <TableCellContent
          align={flexEnd}
          color={
            props?.value >= 0 ? (props?.value === 0 ? "white" : "green") : "red"
          }
        >
          {numeral(props?.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (props: any) => {
      const total = props?.data.reduce((total: number, item: any) => {
        return total + item.profiltAmt;
      }, 0);
      return (
        <TableHeaderCellContent
          align={flexEnd}
          isFooter
          color={total >= 0 ? (total === 0 ? "white" : "green") : "red"}
        >
          {numeral(total).format("0,0")}
        </TableHeaderCellContent>
      );
    },
  },
];

export const columnClose = [
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.alloDate" />
      </HeaderCell>
    ),
    accessor: "tradeDate",
    minWidth: 100,
    Cell: (props: any) => {
      return (
        <TableCellContent align={center} color={"while"}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
    Footer: (props: any) => {
      return (
        <TableHeaderCellContent align={flexStart} isFooter>
          <TableCellContent align={flexStart}>
            <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.sum" />
          </TableCellContent>
        </TableHeaderCellContent>
      );
    },
  },
  // {
  //   Header: (
  //     <HeaderCell align={center}>
  //       <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.subAcc" />
  //     </HeaderCell>
  //   ),
  //   accessor: "custNo",
  //   minWidth: 100,
  //   Cell: (props: any) => {
  //     return <TableCellContent align={center}>{props.value}</TableCellContent>;
  //   },
  //   Footer: (
  //     <TableHeaderCellContent align={center} isFooter></TableHeaderCellContent>
  //   ),
  // },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.secCd" />
      </HeaderCell>
    ),
    accessor: "secCd",
    minWidth: 100,
    Cell: (props: any) => {
      const currentRow = props?.row?.original;

      return (
        <TableCellContent
          align={flexStart}
          color={
            currentRow?.profitAmt >= 0
              ? currentRow?.profitAmt === 0
                ? "white"
                : "green"
              : "red"
          }
        >
          {props.value}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent
        align={flexStart}
        isFooter
      ></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.mass" />
      </HeaderCell>
    ),
    accessor: "quantity",
    minWidth: 100,
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={flexEnd} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.priceTb" />
      </HeaderCell>
    ),
    accessor: "longAvgPrice",
    minWidth: 100,
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value * 1000).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={flexEnd} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.price" />
      </HeaderCell>
    ),
    minWidth: 100,
    accessor: "price",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value * 1000).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (
      <TableHeaderCellContent align={flexEnd} isFooter></TableHeaderCellContent>
    ),
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assetsIncurred.bottom.tab2.table.profit" />
      </HeaderCell>
    ),
    accessor: "profitAmt",
    minWidth: 100,
    Cell: (props: any) => {
      return (
        <TableCellContent
          align={flexEnd}
          color={
            props.value >= 0 ? (props.value === 0 ? "white" : "green") : "red"
          }
        >
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
    Footer: (props: any) => {
      const total = props?.data.reduce((total: number, item: any) => {
        return total + item.profitAmt;
      }, 0);

      return (
        <TableHeaderCellContent
          align={flexEnd}
          isFooter
          color={total >= 0 ? (total === 0 ? "white" : "green") : "red"}
        >
          {total}
        </TableHeaderCellContent>
      );
    },
  },
];
