import { toast } from "components/commons/Toast";
import {
  getBondOrder,
  getBondList,
  getCustomer2,
  cancelBondOrder,
  findCbotBondPriceEst,
  findCbotBondInfo,
  inquiryBuyingPower,
  getBondAvaiSell,
  calculateBondFee,
  calculateTaxAmt,
  placeBondOrder,
} from "core/http/apis/bid-ask-bond-order";
import {
  IGetBondOrderParams,
  IGetBondListParams,
  IGetCustomer2Params,
  ICancelBondOrderParams,
  IFindCbotBondPriceEstParams,
  IFindCbotBondInfoParams,
  IInquiryBuyingPowerParams,
  IGetBondAvaiSellParams,
  ICalculateBondFeeParams,
  ICalculateTaxAmtParams,
  IPlaceBondOrderParams,
} from "core/http/apis/bid-ask-bond-order/types";
import {
  IGetBondOrderResponse,
  IGetBondListResponse,
  IGetCustomer2Response,
  ICancelBondOrderResponse,
  IFindCbotBondPriceEstResponse,
  IFindCbotBondInfoResponse,
  IInquiryBuyingPowerResponse,
  IGetBondAvaiSellResponse,
  ICalculateBondFeeResponse,
  ICalculateTaxAmtResponse,
  IPlaceBondOrderResponse,
} from "domain/models/BidAskBondOrder";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getBondOrderFailure,
  getBondOrderRequest,
  getBondOrderSuccess,
  getBondListRequest,
  getBondListSuccess,
  getBondListFailure,
  getCustomer2Request,
  getCustomer2Success,
  getCustomer2Failure,
  cancelBondOrderRequest,
  cancelBondOrderSuccess,
  cancelBondOrderFailure,
  findCbotBondPriceEstRequest,
  findCbotBondPriceEstSuccess,
  findCbotBondPriceEstFailure,
  findCbotBondInfoRequest,
  findCbotBondInfoSuccess,
  findCbotBondInfoFailure,
  findCbotBondInfoDetailRequest,
  findCbotBondInfoDetailSuccess,
  findCbotBondInfoDetailFailure,
  inquiryBuyingPowerRequest,
  inquiryBuyingPowerSuccess,
  inquiryBuyingPowerFailure,
  getBondAvaiSellRequest,
  getBondAvaiSellSuccess,
  getBondAvaiSellFailure,
  calculateBondFeeRequest,
  calculateBondFeeSuccess,
  calculateBondFeeFailure,
  calculateTaxAmtRequest,
  calculateTaxAmtSuccess,
  calculateTaxAmtFailure,
  placeBondOrderRequest,
  placeBondOrderSuccess,
  placeBondOrderFailure,
} from "./redux";

function* getBondOrderWorker(action: ReturnType<typeof getBondOrderRequest>) {
  try {
    const params: IGetBondOrderParams = action.payload;
    const response: IGetBondOrderResponse = yield call(getBondOrder, params);

    if (response?.statusCode === 0) {
      yield put(getBondOrderSuccess(response));
    } else {
      yield put(getBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBondListWorker(action: ReturnType<typeof getBondListRequest>) {
  try {
    const params: IGetBondListParams = action.payload;
    const response: IGetBondListResponse = yield call(getBondList, params);

    if (response?.statusCode === 0) {
      yield put(getBondListSuccess(response));
    } else {
      yield put(getBondListFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBondListFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findCbotBondPriceEstWorker(
  action: ReturnType<typeof findCbotBondPriceEstRequest>
) {
  try {
    const params: IFindCbotBondPriceEstParams = action.payload;
    const response: IFindCbotBondPriceEstResponse = yield call(
      findCbotBondPriceEst,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotBondPriceEstSuccess(response));
    } else {
      yield put(findCbotBondPriceEstFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotBondPriceEstFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findCbotBondInfoWorker(
  action: ReturnType<typeof findCbotBondInfoRequest>
) {
  try {
    const params: IFindCbotBondInfoParams = action.payload;
    const response: IFindCbotBondInfoResponse = yield call(
      findCbotBondInfo,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotBondInfoSuccess(response));
    } else {
      yield put(findCbotBondInfoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotBondInfoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findCbotBondInfoDetailWorker(
  action: ReturnType<typeof findCbotBondInfoDetailRequest>
) {
  try {
    const params: IFindCbotBondInfoParams = action.payload;
    const response: IFindCbotBondInfoResponse = yield call(
      findCbotBondInfo,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotBondInfoDetailSuccess(response));
    } else {
      yield put(findCbotBondInfoDetailFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotBondInfoDetailFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getCustomer2Worker(action: ReturnType<typeof getCustomer2Request>) {
  try {
    const params: IGetCustomer2Params = action.payload;
    const response: IGetCustomer2Response = yield call(getCustomer2, params);

    if (response?.statusCode === 0) {
      yield put(getCustomer2Success(response));
    } else {
      yield put(getCustomer2Failure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getCustomer2Failure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* inquiryBuyingPowerWorker(
  action: ReturnType<typeof inquiryBuyingPowerRequest>
) {
  try {
    const params: IInquiryBuyingPowerParams = action.payload;
    const response: IInquiryBuyingPowerResponse = yield call(
      inquiryBuyingPower,
      params
    );

    if (response?.statusCode === 0) {
      yield put(inquiryBuyingPowerSuccess(response));
    } else {
      yield put(inquiryBuyingPowerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(inquiryBuyingPowerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBondAvaiSellWorker(
  action: ReturnType<typeof getBondAvaiSellRequest>
) {
  try {
    const params: IGetBondAvaiSellParams = action.payload;
    const response: IGetBondAvaiSellResponse = yield call(
      getBondAvaiSell,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getBondAvaiSellSuccess(response));
    } else {
      yield put(getBondAvaiSellFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getBondAvaiSellFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* calculateBondFeeWorker(
  action: ReturnType<typeof calculateBondFeeRequest>
) {
  try {
    const params: ICalculateBondFeeParams = action.payload;
    const response: ICalculateBondFeeResponse = yield call(
      calculateBondFee,
      params
    );

    if (response?.statusCode === 0) {
      yield put(calculateBondFeeSuccess(response));
    } else {
      yield put(calculateBondFeeFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(calculateBondFeeFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* calculateTaxAmtWorker(
  action: ReturnType<typeof calculateTaxAmtRequest>
) {
  try {
    const params: ICalculateTaxAmtParams = action.payload;
    const response: ICalculateTaxAmtResponse = yield call(
      calculateTaxAmt,
      params
    );

    if (response?.statusCode === 0) {
      yield put(calculateTaxAmtSuccess(response));
    } else {
      yield put(calculateTaxAmtFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(calculateTaxAmtFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* cancelBondOrderWorker(
  action: ReturnType<typeof cancelBondOrderRequest>
) {
  try {
    const params: ICancelBondOrderParams = action.payload;
    const response: ICancelBondOrderResponse = yield call(
      cancelBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(cancelBondOrderSuccess(response));
    } else {
      yield put(cancelBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(cancelBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* placeBondOrderWorker(
  action: ReturnType<typeof placeBondOrderRequest>
) {
  try {
    const params: IPlaceBondOrderParams = action.payload;
    const response: IPlaceBondOrderResponse = yield call(
      placeBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(placeBondOrderSuccess(response));
    } else {
      yield put(placeBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(placeBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getBondOrderWatcher() {
  yield takeLatest(getBondOrderRequest.type, getBondOrderWorker);
}
function* getBondListWatcher() {
  yield takeLatest(getBondListRequest.type, getBondListWorker);
}

function* findCbotBondPriceEstWatcher() {
  yield takeLatest(
    findCbotBondPriceEstRequest.type,
    findCbotBondPriceEstWorker
  );
}

function* findCbotBondInfoWatcher() {
  yield takeLatest(findCbotBondInfoRequest.type, findCbotBondInfoWorker);
}
function* findCbotBondInfoDetailWatcher() {
  yield takeLatest(
    findCbotBondInfoDetailRequest.type,
    findCbotBondInfoDetailWorker
  );
}
function* getCustomer2Watcher() {
  yield takeLatest(getCustomer2Request.type, getCustomer2Worker);
}

function* inquiryBuyingPowerWatcher() {
  yield takeLatest(inquiryBuyingPowerRequest.type, inquiryBuyingPowerWorker);
}

function* getBondAvaiSellWatcher() {
  yield takeLatest(getBondAvaiSellRequest.type, getBondAvaiSellWorker);
}

function* calculateBondFeeWatcher() {
  yield takeLatest(calculateBondFeeRequest.type, calculateBondFeeWorker);
}
function* calculateTaxAmtWatcher() {
  yield takeLatest(calculateTaxAmtRequest.type, calculateTaxAmtWorker);
}

function* cancelBondOrderWatcher() {
  yield takeLatest(cancelBondOrderRequest.type, cancelBondOrderWorker);
}

function* placeBondOrderWatcher() {
  yield takeLatest(placeBondOrderRequest.type, placeBondOrderWorker);
}

export default function* advanceLimitSaga() {
  yield all([
    getBondOrderWatcher(),
    getBondListWatcher(),
    getCustomer2Watcher(),
    cancelBondOrderWatcher(),
    findCbotBondPriceEstWatcher(),
    findCbotBondInfoWatcher(),
    inquiryBuyingPowerWatcher(),
    getBondAvaiSellWatcher(),
    findCbotBondInfoDetailWatcher(),
    calculateBondFeeWatcher(),
    calculateTaxAmtWatcher(),
    placeBondOrderWatcher(),
  ]);
}
