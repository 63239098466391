import { Spacer } from "components/commons";
import { PriceListWrapper } from "components/widgets/details/PriceList/styles";
import AskBid from "./AskBid";
import ProgressBuySell from "./ProgressBuySell";

function PriceList() {
  return (
    <>
      <PriceListWrapper>
        <div className="progress-container">
          <Spacer size="m" />
          <ProgressBuySell />
          <Spacer size="s" />
        </div>
        <AskBid />
      </PriceListWrapper>
    </>
  );
}

export default PriceList;
