/* eslint-disable no-console */
/* eslint-disable jsx-a11y/iframe-has-title */
// import { useState } from "react";
import {
  BodyModal,
  HeaderModal,
  Icon,
  ModalCloseButton,
  ModalTitle,
  Wrapper,
} from "./styles";
import { Modal } from "react-bootstrap";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { FormattedMessage } from "react-intl";
import { GetParameters } from "helper/sessionData";

interface PROPS {
  isVisible: boolean;
  onClose: () => void;
}

const ModalIndex = (props: PROPS) => {
  // const intl = useIntl();
  const { isVisible, onClose } = props;
  // const custType: number = useSelector(selectCustType);

  const listAcc = GetParameters("PN00", "PN00_INTEREST_RATE_URL");

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={onClose}
      dialogClassName="modalPdf"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="profitMoney.modal.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={() => onClose()}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          {/* <DetailRow>
            <DetailLabel>
              <FormattedMessage id="transferInternal.transferInternal.detail.form.accountFrom" />
            </DetailLabel>
            <DetailInputWrapper className="text-left">
              <Select
                // value={accountOptions.find(
                //   (item) => item.value === itemEdit?.subAccoNo
                // )}
                defaultValue={listOption[0]}
                options={listOption}
                onChange={(e: any) => setValuePdf(e.value ?? "")}
              />
            </DetailInputWrapper>
          </DetailRow> */}
          <iframe src={`${listAcc}#toolbar=0`} style={{ height: "565px" }} />
        </BodyModal>
      </Wrapper>
    </Modal>
  );
};

export default ModalIndex;
