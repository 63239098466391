import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Content from "./Content";
import Header from "./Header";
import {
  cleanData,
  requestChangeTopForeignType,
  requestTopForeign,
  updateTickerLastSale,
  updateTickerForeignRoom,
} from "modules/market/redux/topForeign";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { useEffectOnce, useInterval } from "react-use";
import { Container } from "./styles";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { activeTabTopForeignSelector } from "modules/market/redux/selectors";
import {
  ForeignRoom,
  LastSale,
  SystemState,
} from "domain/protoNew/auto_trading_pb";

import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

function TopForeign(): React.ReactElement {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabTopForeignSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);

  useInterval(() => {
    dispatch(requestTopForeign());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    const eventListenerForeignRoom = (data: ForeignRoom.AsObject) => {
      dispatch(updateTickerForeignRoom(data));
    };

    const eventListenerLastSale = (data: LastSale.AsObject) => {
      dispatch(updateTickerLastSale(data));
    };
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(requestTopForeign());
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM, eventListenerForeignRoom);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    dispatch(requestChangeTopForeignType(activeTab));
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM,
        eventListenerForeignRoom
      );
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
      dispatch(cleanData());
    };
  });

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      dispatch(requestChangeTopForeignType(activeTab));
    }
  }, [isVisibilityPage]);

  return (
    <Container>
      <Header />
      <Content />
    </Container>
  );
}

export default memo(TopForeign);
