/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Button, Select, DatePicker } from "components/commons";
import { useIntl } from "react-intl";
import { optionStatus } from "modules/transfer-history/constants";

interface PROPS {
  changeSearch: (value: any, key: string) => void;
  search: any;
  getData: (cb: any) => void;
}

const Search = (props: PROPS) => {
  const { changeSearch, search, getData } = props;
  const intl = useIntl();
  const statusOptions = useMemo(() => optionStatus(intl), [intl]);
  const [loading, setLoading] = useState(false);

  const submitForm = () => {
    setLoading(true);
    getData(setLoading(false));
  };
  useEffect(() => {
    submitForm();
  }, []);
  return (
    <FilterWrapper className="d-flex mb-2 p-0 ml-4">
      <FilterItem
        filterName={intl.formatMessage({
          id: "transferHistory.search.fromDate.label",
        })}
        className="mr-4"
      >
        <DatePicker
          selected={search?.fromDate}
          onChange={(e: any) => changeSearch(e, "fromDate")}
          popperPlacement="bottom-start"
        />
      </FilterItem>

      <FilterItem
        filterName={intl.formatMessage({
          id: "transferHistory.search.toDate.label",
        })}
        className="mr-4"
      >
        <DatePicker
          selected={search?.toDate}
          onChange={(e: any) => changeSearch(e, "toDate")}
          popperPlacement="bottom-start"
        />
      </FilterItem>

      <FilterItem
        filterName={intl.formatMessage({
          id: "transferHistory.search.status.label",
        })}
        className="mr-4"
      >
        <Select
          value={search?.status}
          options={statusOptions}
          onChange={(e) => changeSearch(e, "status")}
        />
      </FilterItem>

      <Button
        color="accept"
        className="button-filter"
        style={{ marginLeft: "0px" }}
        loading={loading}
        onClick={() => submitForm()}
      >
        {intl.formatMessage({
          id: "transferInternal.historyTransfer.filter.search",
        })}
      </Button>
    </FilterWrapper>
    // </Wrap>
  );
};

export default Search;
