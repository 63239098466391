import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetPortfolioParams } from "core/http/apis/account/types";
import { ParamsSellAll } from "core/http/apis/orderexec/types";
import {
  DataPortfolio,
  PortfolioItemSellAll,
  SellAllPortfolioResponse,
} from "domain/models/Portfolio";
import { LastSale } from "domain/protoNew/auto_trading_pb";
import { sumBy, toArray } from "lodash";
import { logOutSuccess } from "modules/auth/redux";
import { IntlShape } from "react-intl";

// export type queryParams = {
//   page: number;
//   pageSize: number;
// };

export interface ErrorSingleOrder {
  code: number;
  description: string;
}

export type State = {
  data: DataPortfolio;
  queryParams: GetPortfolioParams;
  account: string;
  alloDate: Date;
  secCd: string;
  enableInfinity: boolean;
  loading: boolean;
  error: string | undefined;
  isLoadList: boolean;
  selectedRows: PortfolioItemSellAll[];
  singleOrderStatus: { status: string; error?: string };
  errorSingleOrder: ErrorSingleOrder;
  paramSellAll: ParamsSellAll;
  percentSelected: string;
  marketSelected: string;
  priceSelected: string;
  key: string;
};

const initialState: State = {
  data: {
    total: 0,
    items: [],
    portfolioRealTime: {},
    summary: {
      totalInitialValue: 0,
      totalStockValue: 0,
      totalUnrealizedprofit: 0,
      totalUnrealizedprofitrate: 0,
    },
  },
  queryParams: {},
  account: "",
  alloDate: new Date(),
  secCd: "",
  enableInfinity: true,
  loading: false,
  error: undefined,
  isLoadList: false,
  selectedRows: [],
  singleOrderStatus: { status: "", error: "" },
  errorSingleOrder: {
    code: 0,
    description: "",
  },
  paramSellAll: {
    dataList: [],
    otpCode: "",
    otpType: null,
  },
  percentSelected: "1",
  marketSelected: "HSX",
  priceSelected: "MP",
  key: "",
};

const portfolioSlice = createSlice({
  name: "portfolioSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    requestPortfolio: (state, action: PayloadAction<GetPortfolioParams>) => {
      state.loading = true;
      state.error = undefined;
      state.queryParams = action.payload;
    },
    responsePortfolioSuccess: (state, action: PayloadAction<DataPortfolio>) => {
      state.data.total = action.payload.total;
      state.data.items = state.data.items.concat(action.payload.items);
      state.data.portfolioRealTime = action.payload.items.reduce(
        (obj, cur) => ({
          ...obj,
          [cur.orderId]: {
            secCd: cur.secCd,
            remainQty: cur.remainQty,
            marketPrice: cur.marketPrice,
            avgPrice: cur.avgPrice,
            stockValue: cur.stockValue,
            initialValue: cur.initialValue,
            investmentAmt: cur.investmentAmt,
            unrealizedProfit: cur.unrealizedProfit,
            unrealizedProfitRateNum: cur.unrealizedProfitRateNum,
            colorCode: cur.colorCode,
          },
        }),
        {}
      );
      state.data.summary = action.payload.summary;
      // state.queryParams.page = state.queryParams.page + 1;
      state.loading = false;
      if (state.data.total > state.data.items.length) {
        state.enableInfinity = true;
      } else {
        state.enableInfinity = false;
      }
      state.isLoadList = false;
    },
    responsePortfolioFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoadList = false;
    },
    clearDataRefresh: (state) => {
      // state.queryParams.page = 1;
      state.data.items = [];
      state.data.portfolioRealTime = {};
    },
    updateAccountPortfolio: (state, action: PayloadAction<string>) => {
      state.account = action.payload;
    },
    updateAlloDatePortfolio: (state, action: PayloadAction<Date>) => {
      state.alloDate = action.payload;
    },
    updateSecCdPortfolio: (state, action: PayloadAction<string>) => {
      state.secCd = action.payload;
    },
    onLoadListPortfolio: (state, action: PayloadAction<boolean>) => {
      state.isLoadList = action.payload;
    },
    changeSelectedRows: (state, action: any) => {
      state.selectedRows = action.payload;
    },
    requestSellAllPortfolio: (
      state,
      action: PayloadAction<{
        params: ParamsSellAll;
        intl: IntlShape;
      }>
    ) => {
      state.paramSellAll = action.payload.params;
    },
    reponseSellAllPortfolioSuccess: (
      state,
      action: PayloadAction<SellAllPortfolioResponse>
    ) => {
      state.singleOrderStatus = { status: "success" };
    },
    reponseSellAllPortfolioFailure: (
      state,
      action: PayloadAction<ErrorSingleOrder>
    ) => {
      state.singleOrderStatus = { status: "failed" };
      state.errorSingleOrder = {
        code: action.payload.code,
        description: action.payload.description,
      };
    },
    sellAllPortfolioOrderClear: (state) => {
      state.singleOrderStatus = { status: "" };
    },
    changePercentSelected: (state, action: PayloadAction<string>) => {
      state.percentSelected = action.payload;
    },
    changeMarketSelected: (state, action: PayloadAction<string>) => {
      state.marketSelected = action.payload;
    },
    changePriceSelected: (state, action: PayloadAction<string>) => {
      state.priceSelected = action.payload;
    },
    updatePortfolioRealTime: (
      state,
      action: PayloadAction<LastSale.AsObject>
    ) => {
      const data = state.data.portfolioRealTime;
      for (let [key, value] of Object.entries(data!)) {
        state.key = key;
        if (value.secCd === action.payload.seccd) {
          if (value.marketPrice === action.payload.lastprice) return;
          value.colorCode = action.payload.colorcode;
          value.marketPrice = action.payload.lastprice;
          value.stockValue = value.remainQty * value.marketPrice * 1000;
          value.unrealizedProfit =
            value.remainQty * (value.marketPrice - value.avgPrice) * 1000;
          value.unrealizedProfitRateNum =
            (value.unrealizedProfit / value.investmentAmt) * 100;
        }
      }
      const sumStockValue = sumBy(toArray(data), "stockValue");
      state.data.summary.totalStockValue = sumStockValue;
      state.data.summary.totalUnrealizedprofit =
        sumStockValue - state.data.summary.totalInitialValue;
      state.data.summary.totalUnrealizedprofitrate =
        state.data.summary.totalUnrealizedprofit /
        state.data.summary.totalInitialValue;
    },
  },
});

export const {
  requestPortfolio,
  responsePortfolioSuccess,
  responsePortfolioFailure,
  clearDataRefresh,
  updateAccountPortfolio,
  updateAlloDatePortfolio,
  updateSecCdPortfolio,
  onLoadListPortfolio,
  changeSelectedRows,
  requestSellAllPortfolio,
  reponseSellAllPortfolioSuccess,
  reponseSellAllPortfolioFailure,
  sellAllPortfolioOrderClear,
  changePercentSelected,
  changeMarketSelected,
  changePriceSelected,
  updatePortfolioRealTime,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
