import { CheckBox } from "components/commons";
import { updateEmailData } from "modules/auth/redux/serviceEmailSms";
import {
  getEmailDataSelector,
  getServiceStatusOfCustomerSelector,
} from "modules/auth/selectors";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Body,
  BodyChild,
  BodyContainer,
  ContainerTable,
  Header,
  TitleChildContainer,
  Width100,
  Width100Body,
  Width354,
  Width354Body,
  Width80,
  Width80Body,
  Width80BodyRight,
  Width80Right,
} from "../ContentSms/styles";
import { ContainerSms, Label, LabelContainer } from "../styles";

const TitleChild = ({ children }: any) => {
  return (
    <TitleChildContainer>
      {children?.detailList?.map((item: any) => {
        return (
          <BodyChild key={item?.id}>
            <Width80Body></Width80Body>
            <Width354Body>{item?.serviceName}</Width354Body>
            <Width100Body></Width100Body>
          </BodyChild>
        );
      })}
    </TitleChildContainer>
  );
};
const TitleParenContainer = ({ item, dataMap, setDataMap }: any) => {
  const [visiableChild, setVisiableChild] = useState(false);
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(false);

  const handleCheck = (valueSelect: any) => {
    setChecked(!checked);
    setStatus(!status);
    const listNew = dataMap?.map((item: any) => {
      if (item?.id === valueSelect?.id) {
        return {
          ...item,
          selected: !item?.selected,
        };
      } else {
        return item;
      }
    });
    setDataMap(listNew);
  };

  useEffect(() => {
    if (item?.selected === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [item?.selected]);

  return (
    <>
      <BodyContainer key={item?.id}>
        <Width80Body>
          <CheckBox
            checked={checked}
            disabled={item?.registerOnline ? false : true}
            onChange={() => handleCheck(item)}
          ></CheckBox>
        </Width80Body>
        <Width354Body onClick={() => setVisiableChild(!visiableChild)}>
          {item?.serviceGroupName}
        </Width354Body>
        <Width80BodyRight>
          {numeral(item?.feeRegister).format("0,0")}
        </Width80BodyRight>
        <Width100Body>{numeral(item?.feeOnMonth).format("0,0")}</Width100Body>
      </BodyContainer>
      {visiableChild && <TitleChild children={item} />}
    </>
  );
};
const ContentEmail = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [dataMap, setDataMap] = useState<any>();

  const serviceStatusOfCustomer = useSelector(
    getServiceStatusOfCustomerSelector
  );
  const emailDataSelector = useSelector(getEmailDataSelector);

  useEffect(() => {
    setDataMap(emailDataSelector);
  }, [serviceStatusOfCustomer?.emailData?.serviceList]);

  useEffect(() => {
    dispatch(updateEmailData({ emailData: dataMap }));
  }, [dataMap]);

  return (
    <>
      <ContainerSms>
        <Label>
          {intl.formatMessage({
            id: "auth.seviceEmailSms.email",
          })}
        </Label>
        <LabelContainer>
          {serviceStatusOfCustomer?.emailData?.email6}
        </LabelContainer>
      </ContainerSms>

      <ContainerTable>
        <Header>
          <Width80>
            {intl.formatMessage({
              id: "auth.seviceEmailSms.register",
            })}
          </Width80>
          <Width354>
            {intl.formatMessage({
              id: "auth.seviceEmailSms.nameService",
            })}
          </Width354>
          <Width80Right>
            {intl.formatMessage({
              id: "auth.seviceEmailSms.feeRegister",
            })}
          </Width80Right>
          <Width100>
            {intl.formatMessage({
              id: "auth.seviceEmailSms.feeMonth",
            })}
          </Width100>
        </Header>
        <Body>
          {dataMap?.map((item: any) => {
            return (
              <TitleParenContainer
                item={item}
                dataMap={dataMap}
                setDataMap={setDataMap}
              />
            );
          })}
        </Body>
      </ContainerTable>
    </>
  );
};

export default ContentEmail;
