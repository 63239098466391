import styled from "styled-components/macro";

export const Container = styled.div`
  padding-top: 11px;
  height: 48%;
  background: ${({ theme }) => theme.background1};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @media (max-width: 1360px) {
    height: 34%;
  };
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border2};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
`;

export const LabelCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  font-size: 15px;
  background: ${({ theme }) => theme.background3};
  height: ${({ theme }) => theme.cardHeader.height};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  flex-wrap: nowrap;
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
  }
`;

export const WrapNavigation = styled.div`
  display: flex;
  max-height: ${({ theme }) => theme.cardHeader.height};
  height: 32px;

  align-items: center;
  z-index: 4;
  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
    }
  }
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  color: ${({ theme }) => theme.textcolorWhite};
  white-space: nowrap;
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isActive, theme }) =>
    isActive &&
    `border-bottom: 2px solid ${theme.solidBlue};
color: ${theme.solidBlue};`}
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.textColorRed;
      case "green":
        return theme.textColorGreen;
    }
  }} !important;
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.textcolorWhite};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
`;

export const TableHeaderCellContent = styled.div<{
  color?: string;
  align?: string;
  isFooter?: boolean;
  fixWidth?: number;
  setHeight?: number;
}>`
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.textColorRed;
      case "green":
        return theme.textColorGreen;
    }
  }} !important;
  text-transform: uppercase;
  background: ${({ theme }) => theme.bgElevated3};
  font-size: ${({ theme, isFooter }) =>
    isFooter ? theme.typeFaces.caption12.size : theme.typeFaces.caption10.size};
  border: none;

  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
`;
// height: calc(100%);
//  border: 1px solid ${({ theme }) => theme.borderColor};
export const ContainerTable = styled.div`
  height: calc(100% - ${({ theme }) => theme.cardHeader.height});
  width: 100%;
  padding: 0px;
  overflow: auto;
  position: relative;
  border-right: 1px solid ${({ theme }) => theme.border2};
  border-left: 1px solid ${({ theme }) => theme.border2};
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      height: calc(100% - 30px);

      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
          height: 40px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const Icon = styled.div`
  margin-right: 7px;
  margin-top: -4px;
  display: flex;
  justify-content: center;
`;

export const ButtonRefresh = styled.button`
  background: transparent;
  padding: 0px;
`;
