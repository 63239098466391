import HttpClient from "../../index";
import { RESTFUL_TRANS_URL } from "AppConfig";

export default class HttpTrans extends HttpClient {
  private static classInstance?: HttpTrans;
  private constructor() {
    super(RESTFUL_TRANS_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpTrans();
    }

    return this.classInstance;
  }
}
