import React from "react";
import { ContentContainer } from "./styles";
import TableHeader from "./TableHeaderPriceLeft";
import TableBody from "./TableBodyPriceLeft";

function Content() {
  return (
    <ContentContainer>
      <TableHeader />
      <TableBody rowHeight={36} />
    </ContentContainer>
  );
}

export default React.memo(Content);
