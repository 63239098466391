import styled from "styled-components/macro";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  svg.icon-close {
    path {
      fill: ${({ theme }) => theme.textcolorWhite};
    }
  }

  .flexlayout__tabset {
    background: transparent;
    .flexlayout__tabset_tabbar_outer.flexlayout__tabset_tabbar_outer_top {
      .flexlayout__tabset_tabbar_inner_tab_container.flexlayout__tabset_tabbar_inner_tab_container_top {
        div:first-child {
          margin-left: 0px;
        }
        .flexlayout__tab_button.flexlayout__tab_button_top {
          .flexlayout__tab_button_content {
            color: ${({ theme }) => theme.textcolorWhite};
            font-size: ${({ theme }) => theme.typeFaces.body.size};
            font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
            line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
          }
          &.flexlayout__tab_button--unselected {
            .flexlayout__tab_button_content {
              color: ${({ theme }) => theme.grey40};
            }
            .flexlayout__tab_button_trailing {
              svg {
                display: none;
              }
            }
            :hover {
              .flexlayout__tab_button_trailing {
                svg {
                  display: block;
                }
              }
            }
          }

          &.flexlayout__tab_button--selected {
            background-color: ${({ theme }) => theme.background3};
            border-bottom: 2px solid ${({ theme }) => theme.brandPrimary};
            .flexlayout__tab_button_content {
              color: ${({ theme }) => theme.brandPrimary};
            }
            .flexlayout__tab_button_trailing {
              background: none;
            }
          }

          &:only-child {
            border-bottom: none;
            background-color: ${({ theme }) => theme.background3};
            .flexlayout__tab_button_content {
              color: ${({ theme }) => theme.textcolorWhite};
            }
          }
        }
      }
    }
  }

  .flexlayout__tab_toolbar {
    background-color: ${({ theme }) => theme.background3};
    .flexlayout__tab_toolbar_button-min {
      background: none;
    }
  }

  .flexlayout__layout
    .flexlayout__tabset
    .flexlayout__tabset_tabbar_outer
    .flexlayout__tabset_tabbar_inner {
    background-color: ${({ theme }) => theme.background3};
    padding: 12px 16px;
  }
  .flexlayout__popup_menu_container {
    background: ${({ theme }) => theme.background2};
    .flexlayout__popup_menu {
      .flexlayout__popup_menu_item {
        color: ${({ theme }) => theme.textcolorWhite};
        :hover {
          color: ${({ theme }) => theme.brandPrimary};
          background: ${({ theme }) => theme.brandSecondary};
        }
        font-family: ${({ theme }) => theme.fontFamily};
      }
    }
  }
  .flexlayout__tabset {
    border: 1px solid ${({ theme }) => theme.border1};
  }
  .flexlayout__tab {
    background-color: ${({ theme }) => theme.background1} !important;
    border: 1px solid ${({ theme }) => theme.border1} !important;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  border-radius: 4px;
  .border1 {
    border: 1px solid ${({ theme }) => theme.border1};
  }
`;

export const IconPlusTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  border-left: 1px solid ${({ theme }) => theme.border1};
  background-color: ${({ theme }) => theme.background3};
  cursor: pointer;
`;

export const IconAddWidgetContainer = styled(IconPlusTabContainer)`
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.border1};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const PopWidgetContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.background2};
  border: 1px solid ${({ theme }) => theme.border1};
  top: 25px;
  right: 0;
  z-index: 100;
  width: 220px;
  border-radius: 8px;
  height: auto;
  max-height: 500px;
  overflow: auto;
`;

export const TitleModal = styled.div`
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  text-align: center;
  padding: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RowModal = styled.div`
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  padding: 8px 12px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey50};
  :hover {
    background-color: ${({ theme }) => theme.background3};
    color: ${({ theme }) => theme.grey0};
  }
  :last-child {
    box-shadow: none;
    boder-bottom-left-radius: 8px;
    boder-bottom-right-radius: 8px;
  }
`;

export const PopSaveContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const PopSaveTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
  background-color: ${({ theme }) => theme.background3};
  text-align: center;
  padding: 10px;
`;

export const PopSaveBody = styled.div`
  padding: 0 16px;
  background-color: ${({ theme }) => theme.background2};

  .header {
    padding: 8px 0;
    color: ${({ theme }) => theme.grey30};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
  .footer {
    display: flex;
    padding: 16px 0;
    // direction: rtl;
    .flex-1 {
      flex: 1;
    }
  }
`;

export const ButtonExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  background: transparent;
`;

export const Suggestions = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.regular};
  color: ${({ theme }) => theme.grey50};
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.background2};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;

export const TextContent = styled.div`
  flex: 5;
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
`;
