import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IRegisterTransferBonds } from "domain/models/RegisterTransferBonds";
import { changeLimitApi, getLimitAccountApi } from "./urls";

export const getLimitAccount = async (
  param: any
): Promise<IRegisterTransferBonds> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    getLimitAccountApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const changeLimit = async (
  param: any
): Promise<IRegisterTransferBonds> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    changeLimitApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
