import { FC, CSSProperties, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  BodyRowWrapper,
  TableCellContent,
  CellValue,
} from "components/widgets/generals/TopForeign/styles";
import {
  requestLoadMore,
  TopPriceRightState,
} from "modules/market/redux/topPriceRight";
import { FLEX } from "./constants";
import {
  ChangePricePercentageCell,
  PriceCell,
  TickerCell,
} from "./PriceRightCells";
import { marketTopPriceRightSelector } from "modules/market/redux/selectors";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
}

export interface Props {
  columns: IHeaderCellInfo[];
  style: CSSProperties;
  ticker: string;
  rowInd: number;
  isScrolling?: boolean;
}

const currentTotalSelector = createSelector(
  marketTopPriceRightSelector,
  (TopPriceRightState: TopPriceRightState) =>
    TopPriceRightState.tickerList.length
);

const TableBodyRow: FC<Props> = ({
  style,
  ticker,
  columns,
  rowInd,
  isScrolling,
}) => {
  const dispatch = useDispatch();

  const currentTotal = useSelector(currentTotalSelector);

  useEffect(() => {
    if (rowInd && rowInd + 5 === currentTotal) {
      dispatch(requestLoadMore());
    }
  }, [rowInd]);

  return (
    <BodyRowWrapper style={style} bgColor={rowInd + 1 || 1}>
      <TableCellContent
        minWidth={columns?.[0]?.width}
        align={
          columns[0].position
            ? FLEX[columns?.[0]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <TickerCell ticker={ticker} />
      </TableCellContent>

      <CellValue
        minWidth={columns?.[1]?.width}
        align={
          columns?.[1]?.position
            ? FLEX[columns?.[1]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <PriceCell ticker={ticker} />
      </CellValue>

      {/* <CellValue
        minWidth={columns?.[2]?.width}
        align={
          columns?.[2]?.position
            ? FLEX[columns?.[2]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <ChangePriceAmountCell ticker={ticker} />
      </CellValue> */}

      <CellValue
        minWidth={columns?.[2]?.width}
        align={
          columns?.[2]?.position
            ? FLEX[columns?.[2]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <ChangePricePercentageCell ticker={ticker} />
      </CellValue>
    </BodyRowWrapper>
  );
};

export default memo(TableBodyRow);
