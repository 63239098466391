// @flow
import { ACCOUNT_STATEMENT } from "modules/account-statement/constants";
import MoneyStatementFilter from "./MoneyStatementFilter";
import MoneyStatementTable from "./MoneyStatementTable";
import SecuritiesStatmentFilter from "./SecuritiesStatmentFilter";
import SecuritiesStatmentTable from "./SecuritiesStatmentTable";
import { Wrap } from "./styles";
type Props = {
  activeTab?: string;
};
export function AccountStatementContent(props: Props) {
  const { activeTab } = props;
  return (
    <Wrap>
      {activeTab === ACCOUNT_STATEMENT.MONEY_STATEMENT && (
        <>
          <MoneyStatementFilter />
          <MoneyStatementTable />
        </>
      )}

      {activeTab === ACCOUNT_STATEMENT.SECURITIES_STATEMENT && (
        <>
          <SecuritiesStatmentFilter />
          <SecuritiesStatmentTable />
        </>
      )}

      {!activeTab && (
        <>
          <MoneyStatementFilter />
          <MoneyStatementTable />
        </>
      )}
    </Wrap>
  );
}
