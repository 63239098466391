import { requestFindMarketDepth } from "core/grpc";
import { MarketDepthResponse } from "domain/protoNew/auto_trading_pb";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  fetchMarketDepth,
  fetchMarketDepthSuccess,
  fetchMarketDepthFailure,
} from "../redux/adIndex";
import { currentMarketCodeSelector } from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";

function* fetchMarketDepthWorker() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const res: MarketDepthResponse.AsObject = yield call(
      requestFindMarketDepth,
      currentMarketCode
    );

    if (!res) {
      yield put(fetchMarketDepthSuccess(null));
    }

    yield put(fetchMarketDepthSuccess(res));
  } catch (e: any) {
    yield put(fetchMarketDepthFailure(e.message));
  }
}

function* fetchMarketDepthWatcher() {
  yield takeLatest(fetchMarketDepth.type, fetchMarketDepthWorker);
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchMarketDepthWorker);
}

export default function* adIndexSaga() {
  yield all([fetchMarketDepthWatcher(), setCurrentMarketCodeWatcher()]);
}
