import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  enableInfinity: boolean;
  currentPage: number;
  limit: number;
  listMarginSecuritiesPortfolio: Array<any>;
  marginGroupId: number;
  loading: boolean;
}

export const initialState: ReduxData = {
  limit: 21,
  enableInfinity: true,
  currentPage: 0,
  listMarginSecuritiesPortfolio: [],
  marginGroupId: 0,
  loading: false,
};

const MarginSecuritiesPortfolioSlice = createSlice({
  name: "marginSecuritiesPortfolioSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },

  reducers: {
    getListMarginSecuritiesPortfolioRequest: (
      state,
      action: PayloadAction<any>
    ) => {},
    getListMarginSecuritiesPortfolioSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.listMarginSecuritiesPortfolio = action.payload?.data;
    },
    getListMarginSecuritiesPortfolioFailure: (state) => {},

    searchListRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    searchListSuccess: (state, action: PayloadAction<any>) => {
      state.listMarginSecuritiesPortfolio = action.payload?.data;
      state.loading = false;
      state.currentPage = 0;
      state.enableInfinity = true;
    },
    searchListFailure: (state) => {
      state.loading = false;
    },

    findMarginContractRequest: (state, action: PayloadAction<any>) => {},
    findMarginContractSuccess: (state, action: PayloadAction<any>) => {
      state.marginGroupId = action?.payload?.data?.[0]?.marginGroupId;
    },
    findMarginContractFailure: (state) => {},

    fetchMoreListRequest: (state, action: PayloadAction<any>) => {},
    fetchMoreListSuccess: (state, action: PayloadAction<any>) => {
      const data = state.listMarginSecuritiesPortfolio.concat(
        action.payload?.data
      );
      state.listMarginSecuritiesPortfolio = data ?? [];
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateEnableInfinity: (state, action: PayloadAction<boolean>) => {
      state.enableInfinity = action.payload;
    },
  },
});

export const {
  getListMarginSecuritiesPortfolioRequest,
  getListMarginSecuritiesPortfolioSuccess,
  getListMarginSecuritiesPortfolioFailure,

  searchListRequest,
  searchListSuccess,
  searchListFailure,

  findMarginContractRequest,
  findMarginContractSuccess,
  findMarginContractFailure,

  fetchMoreListRequest,
  fetchMoreListSuccess,

  updatePage,
  updateEnableInfinity,
} = MarginSecuritiesPortfolioSlice.actions;

export default MarginSecuritiesPortfolioSlice.reducer;
