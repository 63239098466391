import { tradeDateSelector } from "modules/auth/selectors";
import { getListPositionCloseRequest } from "modules/position-management/redux";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainerS } from "../PositionOpen/styles";
import PositionCloseFilter from "./PositionCloseFilter";
import PositionCloseTable from "./PositionCloseTable";

function PositionClose() {
  const dispatch = useDispatch();

  const tradeDate = useSelector(tradeDateSelector);

  const d = new Date(moment(tradeDate).format("YYYY-MM-DD"));

  useEffect(() => {
    dispatch(
      getListPositionCloseRequest({
        custNo: "",
        secCd: "",
        fromDate: tradeDate
          ? moment(new Date(d.getFullYear(), d.getMonth(), 1)).format(
              "YYYYMMDD"
            )
          : moment(new Date()).format("YYYYMMDD"),
        toDate: tradeDate
          ? moment(tradeDate).format("YYYYMMDD")
          : moment(new Date()).format("YYYYMMDD"),
      })
    );
  }, []);

  return (
    <ContainerS isVisibleSideCard={false}>
      <PositionCloseFilter />
      <PositionCloseTable />
    </ContainerS>
  );
}

export default PositionClose;
