import { FC, MouseEventHandler } from "react";
import Wrapper from "./components/Wrapper";
import styled from "styled-components/macro";
import { ReactComponent as IconDelete } from "assets/image/svg/ic_delete.svg";

const TransferWrap = styled.div`
  height: 100%;
  gap: 8px;
`;

const HistoryTableWrap = styled.div`
  flex: 3;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;

const TransferFormWrap = styled.div<{ isShow: boolean; heightMix?: boolean }>`
  ${({ heightMix }) => heightMix && `height: 228px`};
  ${({ heightMix }) =>
    heightMix &&
    `@media (max-width: 1400px) {
    height: 270px;
  }`};

  flex: 1 1 1%;
  max-width: 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  position: relative;
  @media (max-width: 1500px) {
    flex: 1 1 7%;
  }
  ${({ isShow }) =>
    isShow &&
    `max-width: 30%;
    `}
`;

const CloseForm = styled.div<{ isShow: boolean; isShowIconClose: boolean }>`
  position: absolute;
  right: 16px;
  top: 0;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${({ theme }) => theme.cardHeader.height};
  ${({ isShow, isShowIconClose }) =>
    isShow &&
    isShowIconClose &&
    `
  visibility:visible;
  `}
`;

interface Props {
  headerHistory: string | Function;
  headerForm: string;
  arrayForm: Function[];
  showForm?: boolean;
  closeForm?: MouseEventHandler;
  isShowIconClose?: boolean;
  heightMix?: boolean;
}
const Container: FC<Props> = (props) => {
  const {
    headerHistory,
    headerForm,
    arrayForm,
    showForm = true,
    closeForm = () => {},
    isShowIconClose = true,
    heightMix = false,
  } = props;
  return (
    <TransferWrap className="d-flex">
      {arrayForm.length > 1 && (
        <TransferFormWrap heightMix={heightMix} isShow={showForm}>
          <CloseForm
            isShow={showForm}
            isShowIconClose={isShowIconClose}
            onClick={closeForm}
          >
            <IconDelete />
          </CloseForm>
          <Wrapper header={headerForm}>{arrayForm[1]()}</Wrapper>
        </TransferFormWrap>
      )}

      <HistoryTableWrap>
        <Wrapper header={headerHistory}>{arrayForm[0]()}</Wrapper>
      </HistoryTableWrap>
    </TransferWrap>
  );
};

export default Container;
