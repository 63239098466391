import { ReactComponent as IconTrash } from "assets/image/svg/icon_refresh_2.svg";
import { Button } from "components/commons";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { tradeDateSelector } from "modules/auth/selectors";
import {
  getListPositionOpenRequest,
  searchPositionOpenRequest,
} from "modules/position-management/redux";
import {
  getListPositionOpen,
  loadingSelector,
} from "modules/position-management/selectors";
import moment from "moment";
import numeral from "numeral";
import { memo, useEffect, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ColorGreen,
  ColorRed,
  ColorWhile,
  TableHeaderCellContent,
} from "../PositionClose/PositionCloseTable/styles";
import {
  ButtonRefresh,
  Container,
  ContainerS,
  HeaderCell,
  Icon,
  TableCellContent,
} from "./styles";

function PositionOpenTable() {
  const data = useSelector(getListPositionOpen);

  const intl = useIntl();
  const dispatch = useDispatch();
  const tradeDate = useSelector(tradeDateSelector);
  const loading = useSelector(loadingSelector);

  const countTotal = (arr: any[]) => {
    return arr.reduce((total: number, item: any) => {
      return total + item.profiltAmt;
    }, 0);
  };

  const handleRefresh = () => {
    dispatch(
      searchPositionOpenRequest({
        secCd: "",
        custNo: "",
        subAccoNo: "",
        status: 1,
        openDateFrom: tradeDate
          ? moment(tradeDate).format("YYYYMMDD")
          : moment(new Date()).format("YYYYMMDD"),
        openDateTo: tradeDate
          ? moment(tradeDate).format("YYYYMMDD")
          : moment(new Date()).format("YYYYMMDD"),
      })
    );
  };

  useEffect(() => {
    dispatch(
      getListPositionOpenRequest({
        secCd: "",
        custNo: "",
        subAccoNo: "",
        status: 1,
        openDateFrom: tradeDate
          ? moment(tradeDate).format("YYYYMMDD")
          : moment(new Date()).format("YYYYMMDD"),
        openDateTo: tradeDate
          ? moment(tradeDate).format("YYYYMMDD")
          : moment(new Date()).format("YYYYMMDD"),
      })
    );
  }, []);
  const total = useMemo(() => {
    return countTotal(data);
  }, [data]);

  const totalProfitLoss = useRef(0);
  totalProfitLoss.current = total;

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={"center"}>
            <FormattedMessage id="positionManagement.positionOpen.subject" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "subAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
        Footer: () => {
          return (
            <TableHeaderCellContent align={"flex-start"} isFooter>
              <Button color="accept" loading={loading} onClick={handleRefresh}>
                <Icon>
                  <ButtonRefresh>
                    <IconTrash width={16} height={16} />
                  </ButtonRefresh>
                </Icon>
                {intl.formatMessage({
                  id: "positionManagement.positionOpen.refresh",
                })}
              </Button>
            </TableHeaderCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"flex-start"}>
            <FormattedMessage id="positionManagement.positionOpen.stockCode" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "secCd",
        Cell: (props: any) => {
          const profiltAmt = props?.row?.original?.profiltAmt;
          const secCd = props?.row?.original?.secCd;
          return (
            <TableCellContent align={"flex-start"}>
              {profiltAmt > 0 ? (
                <ColorGreen>{secCd}</ColorGreen>
              ) : profiltAmt < 0 ? (
                <ColorRed>{secCd}</ColorRed>
              ) : (
                <ColorWhile>{secCd}</ColorWhile>
              )}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"flex-start"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionOpen.longMass" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "longQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"center"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionOpen.shortMass" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "shortQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"center"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionOpen.currentPrice" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "currentPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"center"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionOpen.priceLongMass" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "longAvgPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: () => {
          return (
            <TableHeaderCellContent align={"right"} isFooter>
              <FormattedMessage id="positionManagement.positionOpen.total" />
            </TableHeaderCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionOpen.priceShortMass" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "shortAvgPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={"center"}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="positionManagement.positionOpen.profitAmt" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "profiltAmt",
        Cell: (props: any) => {
          const profiltAmt = props?.row?.original?.profiltAmt;
          return (
            <TableCellContent align={"right"}>
              {profiltAmt > 0 ? (
                <ColorGreen>{numeral(profiltAmt).format("0,0")}</ColorGreen>
              ) : profiltAmt < 0 ? (
                <ColorRed>{numeral(profiltAmt).format("0,0")}</ColorRed>
              ) : (
                <ColorWhile>{numeral(profiltAmt).format("0,0")}</ColorWhile>
              )}
            </TableCellContent>
          );
        },
        Footer: () => {
          return (
            <TableHeaderCellContent align={"right"} isFooter>
              {totalProfitLoss?.current > 0 ? (
                <ColorGreen>
                  {numeral(totalProfitLoss?.current).format("0,0")}
                </ColorGreen>
              ) : totalProfitLoss?.current < 0 ? (
                <ColorRed>
                  {numeral(totalProfitLoss?.current).format("0,0")}
                </ColorRed>
              ) : (
                <ColorWhile>
                  {numeral(totalProfitLoss?.current).format("0,0")}
                </ColorWhile>
              )}
            </TableHeaderCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <ContainerS isVisibleSideCard={false}>
      <Container id="positionOpen">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={data ? data : []}
          enableInfinity={true}
          scrollableTarget={"positionOpen"}
        />
      </Container>
    </ContainerS>
  );
}

export default memo(PositionOpenTable);
