import { Select, Button, DatePicker } from "components/commons";
import { memo, useMemo } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  searchAdvanceRequest,
  updateCurrentPage,
  updateFilter,
} from "modules/advance/redux";
import {
  loadingSearchDataSelector,
  filterAdvanceSelector,
} from "modules/advance/selectors";
import { IFindAdvanceParams } from "core/http/apis/advance/types";
import { GetAccountType, EntrustBusinessCode, SrcChannel } from "helper/consts";
import { GetAccoList } from "helper/sessionData";

import moment from "moment";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { fromDate, toDate, subAccoNo } = useSelector(filterAdvanceSelector);
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.PayAdvance
  );
  const loading = useSelector(loadingSearchDataSelector);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc, intl]);

  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      const resLogData = {
        eventGroup: GroupEvent.xmlOnline,
        event: RealtimeConst.getHistoryOrder,
        message: "failed",
      };
      storage.commonPushLogFirebase(resLogData);
      return toast("common.warningInvalidDay", "error");
    }
    const submitData: IFindAdvanceParams = {
      currentDate: false,
      bankBranchCd: null,
      status: -1,
      srcChanel: SrcChannel.ALL,
      goalType: 0,

      subAccoNo: subAccoNo ? subAccoNo : parseOptionAccount[0].value,
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: 0,
      rowPerPage: 30,
    };
    dispatch(searchAdvanceRequest(submitData));
    dispatch(updateCurrentPage(0));

    const resLogData = {
      eventGroup: GroupEvent.advanceMoney,
      event: RealtimeConst.getDataAdvanceHistory,
      message: JSON.stringify(submitData),
    };

    storage.commonPushLogFirebase(resLogData);
  };

  return (
    <Wrap className="d-flex justify-content-between align-items-center shadow-none">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "advance.filter.fromDate",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <DatePicker
              selected={fromDate}
              onChange={(e: any) => {
                dispatch(
                  updateFilter({
                    key: "fromDate",
                    value: e,
                  })
                );
              }}
              popperPlacement="bottom-start"
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "advance.filter.toDate",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <DatePicker
              selected={toDate}
              onChange={(e) =>
                dispatch(
                  updateFilter({
                    key: "toDate",
                    value: e,
                  })
                )
              }
              popperPlacement="bottom-start"
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "advance.filter.subAccount",
          })}
          className="mr-4"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={parseOptionAccount[0]}
              options={parseOptionAccount}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "subAccoNo",
                    value: e?.value ? e.value : "",
                  })
                );
              }}
            />
          </div>
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "transferLocal.transferLocalTab.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
