import VgaiaHttp from "core/http/singleton/vgaia";
import CustomerHttp from "core/http/singleton/customer";
import {
  ICreateSmsOtpParam,
  ICreateSmsOtpResetPasswordParam,
  IVerifyTwoPhaseAuthenParam,
  IRegisterOTPAuthenParams,
  IRegisterOTPAuthenResponse,
  IIsAlreadyRegisterAuthenFor134Params,
  IIsAlreadyRegisterAuthenFor134Response,
  IGetUserAuthenSessionParams,
  IGetUserAuthenSessionResponse,
  IUpdateOneTimeAuthenParams,
  IUpdateOneTimeAuthenResponse,
  ICheckPinTypeParams,
  ICheckPinTypeReponse,
  IValidateOtpCodeParam,
  GenerateQRParams,
  GenerateQRResult,
} from "./types";
import {
  getSmsOtpAuthenCodeUrl,
  getSmsOtpAuthenCodeResetPasswordUrl,
  verifyTwoPhaseAuthen,
  isAlreadyRegisterAuthenFor134Url,
  registerOTPAuthenUrl,
  getUserAuthenSessionUrl,
  updateOneTimeAuthenUrl,
  checkPinTypeUrl,
  validateOtpCodeUrl,
  generateQRDataUrl,
} from "./urls";

// Check Pin Type
export const checkPinTypeApi = async (
  params: ICheckPinTypeParams
): Promise<any> => {
  const res = await VgaiaHttp.post<ICheckPinTypeReponse>(
    checkPinTypeUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Tạo SMS OTP gửi cho mobile
export const createSMSOTPApi = async (
  data: ICreateSmsOtpParam
): Promise<any> => {
  const res = await VgaiaHttp.get<any>(getSmsOtpAuthenCodeUrl(data));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createSMSOTPResetPasswordApi = async (
  data: ICreateSmsOtpResetPasswordParam
): Promise<any> => {
  const res = await VgaiaHttp.get<any>(
    getSmsOtpAuthenCodeResetPasswordUrl(data)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
// Xác thực OTP
export const verifyTwoPhaseAuthenApi = async (
  data: IVerifyTwoPhaseAuthenParam
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(verifyTwoPhaseAuthen, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const validateOtpCodeApi = async (
  params: IValidateOtpCodeParam
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(validateOtpCodeUrl(params));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Check loai xac thuc cap 2
export const isAlreadyRegisterAuthenFor134Api = async (
  params: IIsAlreadyRegisterAuthenFor134Params
): Promise<IIsAlreadyRegisterAuthenFor134Response> => {
  const res = await VgaiaHttp.get<IIsAlreadyRegisterAuthenFor134Response>(
    isAlreadyRegisterAuthenFor134Url(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Đăng ký Smart/ SMS tài khoản giao dịch
export const registerOTPAuthenApi = async (
  params: IRegisterOTPAuthenParams
): Promise<IRegisterOTPAuthenResponse> => {
  const res = await VgaiaHttp.post<IRegisterOTPAuthenResponse>(
    registerOTPAuthenUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Lay trang thai xac thuc theo phien
export const getUserAuthenSessionApi = async (
  params: IGetUserAuthenSessionParams
): Promise<IGetUserAuthenSessionResponse> => {
  const res = await VgaiaHttp.get<IGetUserAuthenSessionResponse>(
    getUserAuthenSessionUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Đăng ký xac thuc theo phien
export const updateOneTimeAuthenApi = async (
  params: IUpdateOneTimeAuthenParams
): Promise<IUpdateOneTimeAuthenResponse> => {
  const res = await VgaiaHttp.post<IUpdateOneTimeAuthenResponse>(
    updateOneTimeAuthenUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const verifySmartOTP = async (data: VerifySmartOTPParams) => {
//   const res = await VgaiaHttp.post<number>(
//     verifySmartOTPcode,
//     qs.stringify(data)
//   );
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const generateQRCode = async (data: GenerateQRParams) => {
  const res = await CustomerHttp.post<GenerateQRResult>(
    generateQRDataUrl,
    JSON.stringify(data),
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getOtpWebTrading = async () => {
//   const params = { masterAccount: storage.getMasterAccount(), channel: "2" };
//   const res = await VgaiaHttp.get<number>(otpwebtrading, params);
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };
