import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const createContractSelector = (state: RootState) =>
  state.createContract;

export const activeTabSelector = createSelector(
  createContractSelector,
  (state) => state.activeTab
);

export const listContractSelector = createSelector(
  createContractSelector,
  (state) => state.list
);
export const brokerListContractSelector = createSelector(
  createContractSelector,
  (state) => state.brokerList
);

export const loadingSearchDataSelector = createSelector(
  createContractSelector,
  (state) => state.loading
);

export const filterCreateContractSelector = createSelector(
  createContractSelector,
  (state) => state.filter
);
export const statusLiquidateSelector = createSelector(
  createContractSelector,
  (state) => state.statusLiquidate
);
export const createContractEditSelector = createSelector(
  createContractSelector,
  (state) => state.createContract
);

export const statusCreateContractSelector = createSelector(
  createContractSelector,
  (state) => state.statusCreateContract
);
