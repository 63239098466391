import { FC } from "react";
import { Wrapper, Title } from "./styles";
import { ReduxData as ScreenersState } from "modules/screeners/redux";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useIntl } from "react-intl";

const screenersSelector = (state: RootState) => state.screeners;

const totalResultSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.total
);

const HeaderCard: FC = () => {
  const intl = useIntl();
  const totalResult = useSelector(totalResultSelector);

  return (
    <Wrapper>
      <Title>
        {intl.formatMessage(
          { id: "screeners.card.cardTitle" },
          { total: totalResult }
        )}
      </Title>
    </Wrapper>
  );
};

export default HeaderCard;
