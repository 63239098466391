import styled from "styled-components/macro";

export const DetailWrapper = styled.div`
  display: flex;
  padding: 8px 0;

  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.grey0};
  .text-white .input {
    color: ${({ theme }) => theme.grey0};
  }
  .text-white .input-container,
  .text-white .css-1jpjy99-control,
  .text-white .react-datepicker-wrapper {
    background: ${({ theme }) => theme.grey100} !important;
  }
`;

export const DetailCol = styled.div`
  flex: 1;
  padding: 0 8px;
  width: 100%;
`;
export const DetailColFlexEnd = styled.div`
  padding: 0 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 15px;
`;

export const DetailRow = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowButton = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowPaddingTB = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
`;
export const DetailRowPaddingSelect = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;
  width: 100%;
  padding: 4px 0px;
`;
export const DetailRowTextTop = styled.div`
  display: flex;
  padding: 4px 0px;

  justify-content: space-between;
  width: 100%;
`;
export const DetailLabel = styled.div`
  min-width: 120px;
  display: flex;
  color: ${({ theme }) => theme.grey20};
  margin-left: 8px;
`;

export const DetailInputWrapper = styled.div`
  margin-left: 20px;
  flex: 1;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
`;
export const DetailLabelAlert = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: 20px;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.red50};
  margin: 0 0.5rem;
`;

export const WrapContnetModal = styled.div``;

export const ItemModal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const ItemModalLabal = styled.div``;

export const ItemModalValue = styled.div`
  color: ${({ theme }) => theme.grey0};
`;
