import queryString from "query-string";
import {
  ISearchDepositContractParams,
  IFindDepositPaymentParams,
  IFindDepositContractParams,
  IGetEarlyInterestRateParams,
  IGetInterestAmountParams,
  IGetDepositInquiryParams,
  IGetDueDateDepositContractParams,
} from "./types";

export const searchDepositContractApi = (
  params: ISearchDepositContractParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDepositContract${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getEarlyInterestRateApi = (
  params: IGetEarlyInterestRateParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getEarlyInterestRate${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getInterestAmountApi = (params: IGetInterestAmountParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getInterestAmount${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
// export const getInterestAmountApi = "/getInterestAmount";
export const findDepositPaymentApi = (params: IFindDepositPaymentParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDepositPayment${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findDepositContractApi = (params: IFindDepositContractParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDepositContract${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const liquidateDepositContractApi = "/closeDepositRegister";

export const updateDepositPaymentApi = "/updateDepositPayment";

export const updateDepositContractApi = "/updateDepositContract";
export const getDepositInquiryApi = (params: IGetDepositInquiryParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getDepositInquiry${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getDueDateDepositContractApi = (
  params: IGetDueDateDepositContractParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getDueDateDepositContract${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
