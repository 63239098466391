import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { TickerCode } from "../styles";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { Dictionary } from "lodash";
import { portfolioRealTimeListSelector } from "modules/stock-order/redux/selectors";
import { PortfolioRealTime } from "domain/models/Portfolio";
import { getColor } from "..";

const unrealizedProfitSelector = (orderId: string) => {
  return createSelector(
    portfolioRealTimeListSelector,
    (state?: Dictionary<PortfolioRealTime>) =>
      state?.[orderId]?.unrealizedProfit
  );
};

const symbolSelector = (orderId: string) => {
  return createSelector(
    portfolioRealTimeListSelector,
    (state?: Dictionary<PortfolioRealTime>) => state?.[orderId]?.secCd
  );
};

interface Props {
  orderId: string;
}

const SymbolCell = ({ orderId }: Props) => {
  const unrealizedProfit = useSelector(unrealizedProfitSelector(orderId));
  const symbol = useSelector(symbolSelector(orderId));
  const color = getColor(unrealizedProfit);
  return (
    <>
      <TableCellContent>
        <TickerCode color={color}>{symbol || "-"}</TickerCode>
      </TableCellContent>
    </>
  );
};

export default SymbolCell;
