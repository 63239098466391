import { HeaderContainer } from "./styles";
import { FormattedMessage } from "react-intl";
import HeaderButton from "components/commons/ButtonOptional/HeaderButton";
import { useInterval } from "helper/cusHooks";
import {
  DEFAULT_WIDGET_REFETCH_INTERVAL,
  ETradingDateMapNetFlow,
} from "helper/consts";
import { useEffectOnce } from "react-use";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { tradingDatesNetflowSelector } from "modules/market/redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchNetflowData,
  changeTradingDates,
} from "modules/market/redux/netflow";
import { useEffect } from "react";

const tradingDateOption: Array<ETradingDateMapNetFlow> = [
  ETradingDateMapNetFlow.PRESENT,
  ETradingDateMapNetFlow.ONE_DAY_AGO,
  ETradingDateMapNetFlow.TWO_DAY_AGO,
  ETradingDateMapNetFlow.THREE_DAY_AGO,
  ETradingDateMapNetFlow.FOUR_DAY_AGO,
  ETradingDateMapNetFlow.FIVE_DAY_AGO,
];

const renderTradingDate = (tradingDate: ETradingDateMapNetFlow) => {
  switch (tradingDate) {
    case ETradingDateMapNetFlow.PRESENT:
      return "present";
    case ETradingDateMapNetFlow.ONE_DAY_AGO:
      return "oneDayAgo";
    case ETradingDateMapNetFlow.TWO_DAY_AGO:
      return "twoDayAgo";
    case ETradingDateMapNetFlow.THREE_DAY_AGO:
      return "threeDayAgo";
    case ETradingDateMapNetFlow.FOUR_DAY_AGO:
      return "fourDayAgo";
    case ETradingDateMapNetFlow.FIVE_DAY_AGO:
      return "fiveDayAgo";
    default:
      break;
  }
};

function Header() {
  const dispatch = useDispatch();
  const activeTab = useSelector(tradingDatesNetflowSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);

  useEffectOnce(() => {
    dispatch(fetchNetflowData());
  });

  useInterval(() => {
    if (activeTab !== 0) return;
    dispatch(fetchNetflowData());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  const handleChangeTradingDates = (tradingDate: ETradingDateMapNetFlow) => {
    dispatch(changeTradingDates(tradingDate));
  };

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      dispatch(fetchNetflowData());
    }
  }, [isVisibilityPage]);

  return (
    <HeaderContainer>
      <div className="row no-gutters">
        {tradingDateOption.map((tradingDate: ETradingDateMapNetFlow) => {
          return (
            <div
              key={tradingDate}
              className="header-wrapper col-md-4 col-sm-4 col-xs-6"
            >
              <HeaderButton
                active={activeTab === (tradingDate as ETradingDateMapNetFlow)}
                value={tradingDate}
                onClick={handleChangeTradingDates}
              >
                <FormattedMessage
                  id={`widgets.netflow.headers.${renderTradingDate(
                    tradingDate
                  )}`}
                />
              </HeaderButton>
            </div>
          );
        })}
      </div>
    </HeaderContainer>
  );
}

export default Header;
