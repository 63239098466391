import { FC } from "react";
import { Header, Body, Wrap } from "./styles";
type WrapperProps = {
  header: string | Function;
};
const Wrapper: FC<WrapperProps> = ({ header, children }) => {
  return (
    <Wrap>
      <Header>{typeof header == "string" ? <p>{header}</p> : header()}</Header>
      <Body>{children}</Body>
    </Wrap>
  );
};
export default Wrapper;
