import styled from "styled-components/macro";

export const TabCard = styled.div<{ active: boolean }>`
  flex: 1;
  height: 24px;
  display: flex;
  padding: 0 12px;
  border: 1px solid ${({ theme }) => theme.border1};
  ${({ active }) =>
    active &&
    `
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  `};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme, active }) =>
    active ? theme.background3 : theme.background2};
  :hover {
    background-color: ${({ theme }) => theme.background3};
  }

  .tab-name {
    flex: 1;
    text-align: center;
    color: ${({ theme, active }) => (active ? theme.grey40 : theme.grey40)};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }

  .tab-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .tab-close:hover {
    color: black;
  }
`;

export const Rename = styled.button`
  background-color: ${({ theme }) => theme.background2};
  margin: 0px;
  color: ${({ theme }) => theme.grey20};
  :hover {
    background-color: ${({ theme }) => theme.grey70};
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const SaveTemplate = styled.button`
  background-color: ${({ theme }) => theme.background2};
  margin: 0px;
  color: ${({ theme }) => theme.grey20};
  :hover {
    background-color: ${({ theme }) => theme.grey70};
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.background2};
  display: flex;
  flex-direction: column;
`;
