import { ValueVolumeDistributionDataNew } from "components/widgets/generals/TransactionValueVolume/types";
import { getTopIndustriesTrade } from "core/grpc";
import { TopIndustriesTradeItem } from "domain/protoNew/auto_trading_pb";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchTransactionIndustry,
  fetchTransactionValueVolumeFailure,
  fetchTransactionValueVolumeSuccess,
} from "../redux/transactionIndustry";
import { EMarketCodeNew } from "helper/consts";
import { currentMarketCodeSelector } from "../redux/selectors";
import { setCurrentMarketCode } from "../redux";

function getDisplayDataForValueDistribuiton(
  rpgcInput: TopIndustriesTradeItem.AsObject[]
): ValueVolumeDistributionDataNew[] {
  return rpgcInput.map((item) => {
    const displayItem: ValueVolumeDistributionDataNew = {
      ticker: item.industrynamevi,
      stockName: item.industrynamevi,
      totalTraded: item.industrysummarketamt as number | undefined,
      industryNameVi: item.industrynamevi ?? "",
      industryNameEn: item.industrynameen ?? "",
      lastPrice: 0,
      changedPercentage: 0,
      changedValue: 0,
      color: item.contributepercent >= 0 ? 3 : 4,
    };
    return displayItem;
  });
}

function* fetchTransactionValueVolumeWorker() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const res: TopIndustriesTradeItem.AsObject[] = yield call(
      getTopIndustriesTrade,
      currentMarketCode
    );

    yield put(
      fetchTransactionValueVolumeSuccess({
        valueData: getDisplayDataForValueDistribuiton(res),
      })
    );
  } catch (e: any) {
    yield put(fetchTransactionValueVolumeFailure(e));
  }
}

function* fetchTransactionValueVolumeWatcher() {
  yield takeLatest(
    fetchTransactionIndustry.type,
    fetchTransactionValueVolumeWorker
  );
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(
    setCurrentMarketCode.type,
    fetchTransactionValueVolumeWorker
  );
}

export default function* transactionValueVolumeWidget() {
  yield all([
    fetchTransactionValueVolumeWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
