import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";

const pubPriceSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) => state?.[realTimeId]?.pubPrice
  );
};

interface Props {
  realTimeId: string;
}

const PubPriceCell = ({ realTimeId }: Props) => {
  const pubPrice = useSelector(pubPriceSelector(realTimeId));
  const formatterValue = numeral(pubPrice).format("0,0.00");
  return (
    <>
      <BlinkableCell value={formatterValue} />
    </>
  );
};

export default PubPriceCell;
