import CommonTableInfinity from "components/commons/CommonTableInfinity";

import {
  listCbotBondPriceEstSelector,
  listBondSelector,
} from "modules/bid-ask-bond-order/selectors";
import { accountInfoSelector } from "modules/auth/selectors";
import {
  findCbotBondInfoDetailRequest,
  updateButtonActive,
  getBondAvaiSellRequest,
} from "modules/bid-ask-bond-order/redux";

import { updateItemEdit } from "modules/bid-ask-bond-order/redux";
import { memo, useEffect, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ActionsRow,
  ButtonCell,
  ButtonConfirm,
  ButtonCancel,
} from "./styles";
import numeral from "numeral";
import { convertNumberToDateFormat } from "helper/utils";
import { CboBondOrder } from "domain/models/BidAskBondOrder";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listCbotBondPriceEstSelector);
  const bondList = useSelector(listBondSelector);
  const { currentUser } = useSelector(accountInfoSelector);

  const list = useRef<any>([]);
  useEffect(() => {
    if (data.length > 0) {
      list.current = data;
    }
  }, [data]);

  const list2 = useRef<any>([]);
  useEffect(() => {
    if (bondList.length > 0) {
      list2.current = bondList;
    }
  }, [bondList]);

  const center = "center";
  const flexEnd = "flex-end";

  const clickBuyHandler = (row: CboBondOrder) => {
    let secBond = row && row.bondCd ? row.bondCd : "";
    const refPrice =
      list &&
      list.current &&
      list.current.find((i: any) => i.bondCd === secBond) &&
      list.current.find((i: any) => i.bondCd === secBond)?.priceEst
        ? list.current.find((i: any) => i.bondCd === secBond)?.priceEst
        : 0;

    dispatch(
      updateItemEdit({
        secCdBond: secBond,
        amount: 0,
        qty: 0,
        feePayer: 1,
        taxPayer: 1,
        expiratedDate: new Date(),
        maxPrice: 0,
        referencePrice: refPrice ?? 0,
      })
    );

    dispatch(updateButtonActive("2"));
    dispatch(
      findCbotBondInfoDetailRequest({
        bondCd: secBond,
      })
    );
  };

  const clickSellHandler = (row: CboBondOrder) => {
    let secBond = row && row.bondCd ? row.bondCd : "";
    const refPrice =
      list &&
      list.current &&
      list.current.find((i: any) => i.bondCd === secBond) &&
      list.current.find((i: any) => i.bondCd === secBond)?.priceEst
        ? list.current.find((i: any) => i.bondCd === secBond)?.priceEst
        : 0;

    let bondCdCheck =
      (list2 &&
        list2.current &&
        list2.current.find((i: any) => i.bondCd === secBond) &&
        list2.current.find((i: any) => i.bondCd === secBond)?.bondCd &&
        list2.current.find((i: any) => i.bondCd === secBond).bondCd) ??
      "";
    let check =
      list2 &&
      list2.current &&
      list2.current.find((i: any) => i.bondCd === secBond) &&
      list2.current.find((i: any) => i.bondCd === secBond)?.bondCd &&
      list2.current.find((i: any) => i.bondCd === secBond).bondCd
        ? true
        : false;

    dispatch(
      updateItemEdit({
        secCdBond: bondCdCheck ? bondCdCheck : "",
        amount: 0,
        qty: 0,
        feePayer: 1,
        taxPayer: 1,
        expiratedDate: new Date(),

        referencePrice: check ? refPrice : 0,
      })
    );
    dispatch(
      getBondAvaiSellRequest({
        bondCd: bondCdCheck ? bondCdCheck : "",
        custCd: 0,
        custNo: currentUser.userId ? currentUser.userId : "",
        type: 1,
      })
    );
    dispatch(updateButtonActive("1"));
    dispatch(
      findCbotBondInfoDetailRequest({
        bondCd: bondCdCheck ? bondCdCheck : "",
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.maturityDate" />
          </HeaderCell>
        ),
        accessor: "dueDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.price" />
          </HeaderCell>
        ),
        accessor: "faceValPrice",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.coupon" />
          </HeaderCell>
        ),
        accessor: "couponsRate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value * 100).format("0,0")}%/Năm
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.referencePrices" />
          </HeaderCell>
        ),
        accessor: "priceEst",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.action" />
          </HeaderCell>
        ),
        accessor: "a",
        minWidth: 120,
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              <ActionsRow>
                <ButtonCell>
                  <ButtonConfirm onClick={() => clickBuyHandler(currentRow)}>
                    <FormattedMessage id="bidAskBondOrder.table.buy" />
                  </ButtonConfirm>
                </ButtonCell>
                |
                <ButtonCell>
                  <ButtonCancel onClick={() => clickSellHandler(currentRow)}>
                    <FormattedMessage id="bidAskBondOrder.table.sell" />
                  </ButtonCancel>
                </ButtonCell>
              </ActionsRow>
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="bidAskBondOrder2">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"bidAskBondOrder2"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(RequestTable);
