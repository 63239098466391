import { useEffect } from "react";
import { Select } from "components/commons";
import { TimeOption, TimeOptionValue } from "./types";
import { FormattedMessage } from "react-intl";
import { TIME_OPTIONS } from "./constants";
import { useSelector, useDispatch } from "react-redux";
import { timeTypeFinanceInfoSelector } from "modules/tickerDetails/redux/selectors";
import { changeTimeType } from "modules/tickerDetails/redux/financeInfo";
import { requestGetFinanceInfo } from "modules/tickerDetails/redux/financeInfo";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import { IGetFinanceInfoParams } from "core/http/apis/finance/types";

function SelectIndustry() {
  const dispatch = useDispatch();
  const ticker = useSelector(selectedTickerSelector);
  const timeType = useSelector(timeTypeFinanceInfoSelector);

  useEffect(() => {
    if (!!ticker) {
      const params: IGetFinanceInfoParams = {
        secCd: ticker,
        duration: timeType === TimeOptionValue.yearlyList ? 1 : 2,
      };
      dispatch(requestGetFinanceInfo(params));
    }
  }, [ticker]);

  const handleSelectTime = (opt: any) => {
    dispatch(changeTimeType(opt.value));
  };

  return (
    <div className="d-flex align-items-center pt-2 pb-2 watchMore">
      <div className="mr-4 w-25">
        <FormattedMessage id="widgets.finance.viewType" />
      </div>
      <div className="w-75">
        <Select
          className="flex-fill"
          value={TIME_OPTIONS.find(
            (option: TimeOption) => option.value === timeType
          )}
          options={TIME_OPTIONS}
          onChange={handleSelectTime}
        />
      </div>
    </div>
  );
}

export default SelectIndustry;
