import React, { useMemo } from "react";
import { RootState } from "redux/store";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import { getThemes } from "./abstracts/_themes";
import { useDispatch, useSelector } from "react-redux";
import { ScreenType } from "./types";
import { useEffect } from "react";
import { changeScreen, State as ThemeState } from "themes/redux";
import { createSelector } from "@reduxjs/toolkit";

const themeSelector = (state: RootState) => state.theme;

const screenSelector = createSelector(
  themeSelector,
  (themeSelector: ThemeState) => themeSelector.screen
);

const ThemeProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const screen = useSelector(screenSelector);
  const { type } = useSelector((state: RootState) => state.theme);

  const themes = useMemo(() => getThemes({ screen }), [screen]);

  useEffect(() => {
    const resizeHandler = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1400) {
        dispatch(changeScreen(ScreenType.LG));
      }
      if (screenWidth < 1400) {
        dispatch(changeScreen(ScreenType.MD));
      }
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <ThemeProviderStyled theme={themes[type]}>{children}</ThemeProviderStyled>
  );
};

export default ThemeProvider;
