import { PercentValue, ProgressContainer } from "./styles";
import React, { memo } from "react";
import numeral from "numeral";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";

interface Props {
  percentBuy: number;
}

function ProgressBuySell({ percentBuy }: Props) {
  const themeType = useSelector(themeTypeSelector);
  const percentSell = 100 - percentBuy;

  return (
    <ProgressContainer
      style={{
        backgroundImage: `linear-gradient(90deg, ${themes[themeType].solidGreen} 0% calc(${percentBuy}% - 1px), ${themes[themeType].background1} calc(${percentBuy}% - 1px) calc(${percentBuy}% + 1px), ${themes[themeType].solidRed} calc(${percentBuy}% + 1px) 100%)`,
      }}
    >
      <PercentValue>{numeral(percentBuy).format("0.00")}%</PercentValue>
      <PercentValue>{numeral(percentSell).format("0.00")}%</PercentValue>
    </ProgressContainer>
  );
}

export default memo(ProgressBuySell) as React.FC<Props>;
