import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  cbot: any;
  account: any;
  loading: boolean;
}

const initialState: ReduxData = {
  cbot: {},
  account: [],
  loading: false,
};

const registerToTransferBondsSlice = createSlice({
  name: "registerToTransferBondsSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },

  reducers: {
    getCbotRequest: (state, action: PayloadAction<any>) => {},
    getCbotSuccess: (state, action: PayloadAction<any>) => {
      state.cbot = action.payload?.data;
    },
    getCbotFailure: (state) => {},

    getcustomer2Request: (state, action: PayloadAction<any>) => {},
    getcustomer2Success: (state, action: PayloadAction<any>) => {
      state.account = action.payload?.data;
    },
    getcustomer2Failure: (state) => {},
  },
});

export const {
  getCbotRequest,
  getCbotSuccess,
  getCbotFailure,

  getcustomer2Request,
  getcustomer2Success,
  getcustomer2Failure,
} = registerToTransferBondsSlice.actions;

export default registerToTransferBondsSlice.reducer;
