import Webcam from "react-webcam";
import { useRef, useState } from "react";
import {
  ButtonCamera,
  BoxButtonCamera,
  BodyCamera,
  NullCamera,
} from "./styles";
// import { ReactComponent as IconCamera } from "assets/image/svg/GmcEkycIconCamera.svg";
import { toast } from "components/commons/Toast";
import { FormattedMessage } from "react-intl";

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: "image/jpeg" });
}

interface CameraProps {
  changeImage: (file: File) => void;
}

function Camera({ changeImage }: CameraProps) {
  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isOpenCamera, setOpenCamera] = useState<boolean>(false);
  const [isPermissionDenied, setPermissionDenied] = useState<boolean>(false);

  const videoConstraints = {
    facingMode: "user",
  };

  const capture = () => {
    const imageSrc = webcamRef?.current?.getScreenshot({
      width: 1500,
      height: 1080,
    });
    setImgSrc(imageSrc);
    changeImage(dataURLtoFile(imageSrc, "cmnd"));
    setOpenCamera(false);
  };

  const handleDevices = (mediaDevices: MediaDeviceInfo[]) => {
    const devices = mediaDevices.filter(
      ({ kind }: any) => kind === "videoinput"
    );
    if (devices.length > 0) {
      setOpenCamera(true);
    } else {
      return toast("ekyc.step4.noMediaDevices", "error");
    }
  };

  const openCamera = () => {
    if (isOpenCamera) {
      capture();
    } else {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
  };

  return (
    <BodyCamera>
      {isOpenCamera ? (
        <>
          <Webcam
            audio={false}
            height={170}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMediaError={(e) => setPermissionDenied(true)}
            onUserMedia={(e) => setPermissionDenied(false)}
          />
          {isPermissionDenied && (
            <div>
              <FormattedMessage id="ekyc.step4.isPermissionDenied" />
            </div>
          )}
        </>
      ) : imgSrc ? (
        <img alt="anh1" src={imgSrc} height="170" />
      ) : (
        <NullCamera />
      )}

      <BoxButtonCamera>
        <ButtonCamera onClick={openCamera}>
          {/* <IconCamera /> */}
          <FormattedMessage id="ekyc.step4.openCamera" />
        </ButtonCamera>
      </BoxButtonCamera>
    </BodyCamera>
  );
}

export default Camera;
