import { STOCK_COLOR_CODEMap } from "domain/protoNew/auto_trading_pb";
import { getColorTicker } from "helper/utils";
import styled from "styled-components/macro";

export const HeaderContainer = styled.div`
  padding: 12px;
`;

export const ContentContainer = styled.div`
  overflow-y: auto;
`;

export const TableWrapper = styled.div`
  height: calc(100% - 48px);
  position: relative;
  overflow: hidden;

  thead > tr > th {
    z-index: 0;
    position: sticky;
    top: 0;
  }
`;

export const TableBodyWrapper = styled.div`
  height: calc(100% - 20px);

  @keyframes blink-up {
    0% {
      background: ${({ theme }) => theme.secondaryGreenDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    90% {
      background: ${({ theme }) => theme.secondaryGreenDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    100% {
      background: none;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }

  @keyframes blink-down {
    0% {
      background: ${({ theme }) => theme.secondaryRedDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    90% {
      background: ${({ theme }) => theme.secondaryRedDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    100% {
      background: none;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }

  .cell-blink-up {
    text-decoration: ${({ theme }) => theme.textcolorWhite};
    animation-name: blink-up;
    -webkit-animation-name: blink-up;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  .cell-blink-down {
    text-decoration: ${({ theme }) => theme.textcolorWhite};
    animation-name: blink-down;
    -webkit-animation-name: blink-down;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
`;

export const WrapperTicker = styled.div<{ textcolor?: number }>`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;

export const BlinkCellWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
  padding: 8px;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;

export const PriceCell = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  position: relative;
  & > div {
    ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
`;

export const VolumeCell = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  position: relative;
  .text-value-watchlist {
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }
  & > div {
    ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }
`;

export const ChangeHighLow = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  position: relative;
  & > div {
    ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
`;

export const ChangePricePercent = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  position: relative;
  & > div {
    ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
`;

export const ChangeValuePriceCell = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  position: relative;
  & > div {
    ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
`;

export const TopStatisticalCard = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  height: 100%;

  .select-box {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    column-gap: 58px;
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${({ theme }) => theme.background1};
  }

  .table-header {
    position: sticky;
    top: 56px;
    z-index: 1;
    background: ${({ theme }) => theme.background1};
  }
`;
