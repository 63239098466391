import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFindMarginContractWatchParams } from "core/http/apis/margin-contract-watch/types";
import {
  IMarginContractWatch,
  IMarginContractWatchResponse,
} from "domain/models/MarginContractWatch";
import { logOutSuccess } from "modules/auth/redux";
import { MARGIN_CONTRACT_WATCH_TABS } from "./constants";

interface IFilter {
  accNo: string;
  status: string;
  brokerCd: string;
}

export interface IBroker {
  label: string;
  value: string;
}

export interface ReduxData {
  filter: IFilter;
  list: IMarginContractWatch[];
  loading: boolean;
  activeTab: string;
  brokerList: IBroker[];
  loadingBroker: boolean;
}

const initialState: ReduxData = {
  filter: {
    accNo: "",
    status: "0",
    brokerCd: "",
  },
  list: [],
  loading: false,
  activeTab: MARGIN_CONTRACT_WATCH_TABS.MARGIN_CONTRACT_WATCH,
  brokerList: [],
  loadingBroker: false,
};

const marginContractWatchSlice = createSlice({
  name: "marginContractWatchSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchMarginContractWatchRequest: (
      state,
      action: PayloadAction<IFindMarginContractWatchParams>
    ) => {
      state.loading = true;
    },
    searchMarginContractWatchSuccess: (
      state,
      action: PayloadAction<IMarginContractWatchResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchMarginContractWatchFailure: (state) => {
      state.loading = false;
    },

    getInitBrokerRequest: (state) => {
      state.loadingBroker = true;
    },
    getInitBrokerSuccess: (state, action: PayloadAction<IBroker[]>) => {
      state.loadingBroker = false;
      state.brokerList = action.payload;
    },
    getInitBrokerFailure: (state) => {
      state.loadingBroker = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchMarginContractWatchFailure,
  searchMarginContractWatchRequest,
  searchMarginContractWatchSuccess,
  getInitBrokerFailure,
  getInitBrokerRequest,
  getInitBrokerSuccess,
  updateFilter,
} = marginContractWatchSlice.actions;

export default marginContractWatchSlice.reducer;
