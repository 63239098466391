import styled from "styled-components/macro";

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 16px;
  background-color: ${({ theme }) => theme.background3};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
`;
