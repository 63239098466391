import styled from "styled-components/macro";

export const NavItemMenus = styled.div`
  :hover {
    .menu {
      display: block;
    }
  }
  position: absolute;
  width: 100%;
`;

export const NavItemCard = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-right: 10px;
  cursor: pointer;
  svg {
    path {
      fill: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export const MenuItemCard = styled.div`
  height: auto;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.blackB2};
  position: absolute;
  right: 0;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.greyG4};
  display: none;
`;

export const ItemSelect = styled.div`
  display: flex;
  padding: 8px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.greyG5};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  :hover {
    color: ${({ theme }) => theme.brandSecondary};
  }
  color: ${({ theme }) => theme.greyG1};
`;
