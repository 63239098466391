import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const NavItemCard = styled.div<{ isActive: boolean }>`
  text-align: center;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 4px;
  cursor: pointer;
  /* svg {
    path {
      fill: ${({ theme }) => theme.grey40};
    }
  } */
  .dotRed {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 25px;
    right: 5px;
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
      background-color: ${theme.grey60};
    `}
  :hover {
    background-color: ${({ theme }) => theme.grey60};
    /* border-top: 4px solid ${({ theme }) => theme.brandPrimary}; */
    svg {
      path {
        fill: ${({ theme }) => theme.brandPrimary};
      }
    }
    .hot-key {
      background: ${({ theme }) => theme.brandPrimary};
      color: ${({ theme }) => theme.grey60};
    }
  }
  .hot-key {
    background: ${({ theme }) => theme.grey60};
    border-radius: 2px;
    height: 12px;
    width: 40px;
    font-weight: ${({ theme }) => theme.typeFaces.minimal9.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.minimal9.size};
    line-height: ${({ theme }) => theme.typeFaces.minimal9.lineHeight};
    color: ${({ theme }) => theme.grey100};
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isActive, theme }) =>
      isActive &&
      `
      background: ${theme.brandPrimary};
      color: ${theme.grey60};
    `}
  }
`;

export const Name = styled.div<{ isActive: boolean; isHover: boolean }>`
  color: ${({ isActive, isHover, theme }) =>
    isActive || isHover ? theme.grey0 : theme.grey20};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  white-space: nowrap;
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: ${({ theme }) => theme.overlayGray};
    border-radius: 4px;
    padding: 8px 12px;
    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
  .arrow {
    &:before {
      border-right-color: ${({ theme }) => theme.overlayGray};
    }
  }
`;
