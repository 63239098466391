import { memo, useEffect } from "react";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  getInfoQuote,
  getInfoQuoteUpdate,
} from "modules/stock-order/redux/priceList";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { selectedTickerSelector } from "modules/stock-order/redux/selectors";
import PriceList from "./PriceList";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { SystemState, TopPrice } from "domain/protoNew/auto_trading_pb";

function StockOrderPriceList() {
  const dispatch = useDispatch();
  const selectedTicker = useSelector(selectedTickerSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);

  useEffect(() => {
    if (selectedTicker) {
      subscribeTickers(selectedTicker!);
      dispatch(getInfoQuote(selectedTicker));
    } else {
      dispatch(clearData());
    }

    return () => {
      if (!!selectedTicker) {
        unSubscribeTickers(selectedTicker!);
      }
    };
  }, [selectedTicker]);

  useEffect(() => {
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(getInfoQuote(selectedTicker));
      }
    };

    const eventListenerLastSale = (dataEmmit: TopPrice.AsObject) => {
      if (dataEmmit.seccd === selectedTicker) {
        const toppricebidList = (top: number) =>
          dataEmmit.toppricebidList.filter((value) => value.top === top)[0];

        const toppriceofferList = (top: number) =>
          dataEmmit.toppriceofferList.filter((value) => value.top === top)[0];

        const batchTickerInfo: any = {
          best1bidpricestr: toppricebidList(1)?.price,
          best1bidqty: toppricebidList(1)?.qty,
          best2bidprice: toppricebidList(2)?.price,
          best2bidqty: toppricebidList(2)?.qty,
          best3bidprice: toppricebidList(3)?.price,
          best3bidqty: toppricebidList(3)?.qty,
          best1offerpricestr: toppriceofferList(1)?.price,
          best1offerqty: toppriceofferList(1)?.qty,
          best2offerprice: toppriceofferList(2)?.price,
          best2offerqty: toppriceofferList(2)?.qty,
          best3offerprice: toppriceofferList(3)?.price,
          best3offerqty: toppriceofferList(3)?.qty,
        };
        Object.keys(batchTickerInfo).forEach(
          (key) =>
            batchTickerInfo[key] === undefined && delete batchTickerInfo[key]
        );

        dispatch(getInfoQuoteUpdate(batchTickerInfo));
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  }, [selectedTicker]);

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      if (!!selectedTicker) {
        dispatch(getInfoQuote(selectedTicker!));
      }
    }
  }, [isVisibilityPage]);

  return <PriceList />;
}

export default memo(StockOrderPriceList);
