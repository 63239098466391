import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const transferInternalSelector = (state: RootState) =>
  state.transferInternal;

export const transferLocalFilterSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.filter
);

export const transferLocalBankFilterSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferBank.filter
);

export const transferLocalLoadingSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.loading
);

export const transferLocalHisListSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.list
);

export const transferDEtaiAccountTransfer = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.detai.acountTransfer
);

export const transferDEtaiAccountGet = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.detai.accountGet
);

export const loadingGetAccount = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.detai.loading
);

export const statusModal = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.detai.isVisible
);

export const formSubmit = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.detai.form
);

export const transferLocalBankListSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferBank.list
);

export const listAccoSubtoSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferBank.detai.subAccoToList
);

export const AccoSubtoSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferBank.detai.accountGet
);

export const AccoSubTransferBankSelector = createSelector(
  transferInternalSelector,
  (state) => state.transferBank.detai.acountTransfer
);

export const selectGetBankDetail = createSelector(
  transferInternalSelector,
  (state) => state.transferBank.detai.bankDetai
);

export const getTypeTransferSelector = createSelector(
  transferInternalSelector,
  (state) => state.typeTranfer
);

export const getListAccoToLocal = createSelector(
  transferInternalSelector,
  (state) => state.transferInternal.detai.listAccoTo
);

export const getListDeposit = createSelector(
  transferInternalSelector,
  (state) => state.deposit.data
);

export const getListWithdrawal = createSelector(
  transferInternalSelector,
  (state) => state.withdrawal.data
);

export const selectCheckModal = createSelector(
  transferInternalSelector,
  (state) => state.checkModal
);

export const selectCurrentPage = createSelector(
  transferInternalSelector,
  (state) => state.currentPage
);

export const selectEnableInfinity = createSelector(
  transferInternalSelector,
  (state) => state.enableInfinity
);

export const selectLimit = createSelector(
  transferInternalSelector,
  (state) => state.limit
);
