import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "components/commons/Toast";
import {
  cancelOrderList,
  cancelRequestPendingListApi,
  deleteRequestCondByListApi,
} from "core/http/apis/orderexec";
import {
  getAPIOrderBook,
  getAPIDetailTradingSession,
  findOrderDetailApi,
  getAPIOrderBookSummary,
  findOrderRequestCondApi,
  findRequestPendingApi,
  findRequestCondApi,
  editOrderApi,
} from "core/http/apis/tradeinfo";
import OrderBook, {
  GetOrderBookResponse,
  GetOrderBookSummaryResponse,
  IFindOrderRequestCondResponse,
  IFindOrderDetailResponse,
  IFindRequestCondResponse,
  IFindRequestPendingResponse,
  IOrderBookCondItemDisplay,
  OrderBookDisplay,
  OrderBookSummary,
  IEditOrderResponse,
  IOrderBookPendingItem,
  ICancelOrdersResponse,
  ICancelRequestPendingResponse,
  IDeleteRequestCondByListResponse,
} from "domain/models/OrderBook";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getOrderBookFailure,
  getOrderBookSuccess,
  getOrderBookDetailFailure,
  getOrderBookDetailSuccess,
  requestGetOrderBookDetail,
  findOrderDetailFailure,
  findOrderDetailSuccess,
  findOrderDetail,
  findOrderRequestCond,
  findOrderRequestCondSuccess,
  findOrderRequestCondFailure,
  requestGetOrderBook,
  requestGetOrderBookSummary,
  getOrderBookSummarySuccess,
  getOrderBookSummaryFailure,
  requestCancelOrderList,
  cancelOrderListSuccess,
  cancelOrderListFailure,
  cancelRequestPendingList,
  cancelRequestPendingListSuccess,
  cancelRequestPendingListFailure,
  deleteRequestCondByList,
  deleteRequestCondByListSuccess,
  deleteRequestCondByListFailure,
  editOrder,
  editOrderSuccess,
  editOrderFailure,
  onReloadPlaceOrder,
  findRequestPending,
  findRequestPendingSuccess,
  findRequestPendingFailure,
  findRequestCond,
  findRequestCondSuccess,
  findRequestCondFailure,
  onLoadList,
  // changeSelectedRows,
  // onCheckAll,
} from "../redux/";
import {
  convertOrderBookData,
  convertOrderBookConditionData,
} from "helper/orderBookHelper";
// import { onLoadList } from "modules/order-book/redux/index";
import { GetOrderBookSummaryParam } from "core/http/apis/tradeinfo/types";
import {
  ICancelOrdersParams,
  ICancelRequestPendingListParams,
} from "core/http/apis/orderexec/types";
import logTrackingSaga from "./logTrackingSaga";

function* fetchRequestGetOrderBook(action: any) {
  try {
    const orderBook: GetOrderBookResponse = yield call(
      getAPIOrderBook,
      action.payload.params
    );

    if (orderBook != null && orderBook.data) {
      const orderBookDisplay: OrderBookDisplay[] = convertOrderBookData(
        orderBook,
        action.payload.intl
      );

      yield put({
        type: getOrderBookSuccess.type,
        payload: {
          items: orderBookDisplay,
          offset: action.payload.params.page,
        },
      });
    } else {
      yield put({
        type: getOrderBookSuccess.type,
        payload: {
          items: [],
          offset: action.payload.params.page,
        },
      });
    }
  } catch (error: any) {
    yield put({ type: getOrderBookFailure.type, payload: error });
  }
}

function* findRequestCondWorker(action: ReturnType<typeof findRequestCond>) {
  try {
    const orderBook: IFindRequestCondResponse = yield call(
      findRequestCondApi,
      action.payload.params
    );

    if (orderBook != null && orderBook.data) {
      const orderBookDisplay: IOrderBookCondItemDisplay[] =
        convertOrderBookConditionData(orderBook.data, action.payload.intl);

      yield put(
        findRequestCondSuccess({
          items: orderBookDisplay,
          offset: action.payload.params.offset,
        })
      );
    } else {
      yield put(
        findRequestCondSuccess({
          items: [],
          offset: action.payload.params.offset,
        })
      );
    }
  } catch (error: any) {
    yield put(findRequestCondFailure(error));
  }
}

function* findRequestPendingWorker(
  action: ReturnType<typeof findRequestPending>
) {
  try {
    const orderBook: IFindRequestPendingResponse = yield call(
      findRequestPendingApi,
      action.payload.params
    );

    if (orderBook != null && orderBook.data) {
      const list: IOrderBookPendingItem[] = orderBook.data.map((item) => {
        return {
          ...item,
          visibleCheckbox: item.status === "P",
        };
      });
      yield put(
        findRequestPendingSuccess({
          items: list,
          offset: action.payload.params.page,
        })
      );
    } else {
      yield put(
        findRequestPendingSuccess({
          items: [],
          offset: action.payload.params.page,
        })
      );
    }
  } catch (error: any) {
    yield put(findRequestPendingFailure(error));
  }
}

function* fetchRequestGetOrderBookDetail(action: any) {
  try {
    const orderBookDetail: OrderBook = yield call(
      getAPIDetailTradingSession,
      action.payload.transId,
      action.payload.params
    );
    if (orderBookDetail != null && orderBookDetail.items) {
      yield put({
        type: getOrderBookDetailSuccess.type,
        payload: orderBookDetail,
      });
    }
  } catch (error: any) {
    yield put({ type: getOrderBookDetailFailure.type, payload: error });
  }
}

function* findOrderDetailWorker(action: ReturnType<typeof findOrderDetail>) {
  try {
    const res: IFindOrderDetailResponse = yield call(
      findOrderDetailApi,
      action.payload
    );
    if (res != null && res.data) {
      yield put(findOrderDetailSuccess(res));
    }
  } catch (error: any) {
    yield put(findOrderDetailFailure(error));
  }
}

function* findOrderRequestCondWorker(
  action: ReturnType<typeof findOrderRequestCond>
) {
  try {
    const res: IFindOrderRequestCondResponse = yield call(
      findOrderRequestCondApi,
      action.payload
    );
    if (res != null && res.data) {
      yield put(findOrderRequestCondSuccess(res));
    }
  } catch (error: any) {
    yield put(findOrderRequestCondFailure(error));
  }
}

function* editOrderWorker(action: ReturnType<typeof editOrder>) {
  try {
    const singleOrder: IEditOrderResponse = yield call(
      editOrderApi,
      action.payload.params
    );

    if (singleOrder.statusCode === 0) {
      toast("widgets.orderBookDerEdit.orderSuccess", "success");
      yield put(editOrderSuccess({ items: singleOrder }));
      yield put(onReloadPlaceOrder(true));
    } else {
      toast(
        singleOrder?.errorCode ? "error." + singleOrder?.errorCode : "",
        "error",
        singleOrder.message,
        true
      );
      yield put(editOrderFailure(singleOrder?.errorCode || "error.defaultErr"));
    }
  } catch (error: any) {
    if (error?.code) {
      toast(error.code, "error", error.description, true);
    } else {
      toast("widgets.orderBookDerEdit.orderError", "error");
    }
  }
}

function* requestCancelOrderListAPI(
  action: PayloadAction<{
    params: ICancelOrdersParams;
    intl: any;
    content: string;
  }>
) {
  try {
    const res: ICancelOrdersResponse = yield call(
      cancelOrderList,
      action.payload.params
    );

    if (res.statusCode === 0) {
      yield put(cancelOrderListSuccess(action.payload.params));
      toast(action.payload.content, "success");
    } else {
      toast(
        res?.errorCode ? "error." + res?.errorCode : "",
        "error",
        res.message,
        true
      );
      yield put(cancelOrderListFailure(res?.errorCode || "error.defaultErr"));
    }
  } catch (error: any) {
    toast(
      error && error.description ? error.description : "orderBook.orderError",
      "error"
    );
    yield put(cancelOrderListFailure(error));
  }
}

function* cancelRequestPendingListWorker(
  action: PayloadAction<{
    params: ICancelRequestPendingListParams;
    intl: any;
    content: string;
  }>
) {
  try {
    const res: ICancelRequestPendingResponse = yield call(
      cancelRequestPendingListApi,
      action.payload.params
    );

    if (res.statusCode === 0) {
      yield put(cancelRequestPendingListSuccess(action.payload.params));

      // Huy thanh cong 3/5
      let content = `${action.payload.intl.formatMessage({
        id: "orderBookDer.cancelSucces",
      })} ${res.data.success || 0}/${
        (res.data.success || 0) + (res.data.failed || 0)
      }`;

      toast(content, "success");
      yield put(onLoadList(Math.random()));
    } else {
      toast(
        res?.errorCode ? "error." + res?.errorCode : "",
        "error",
        res.message,
        true
      );
      yield put(
        cancelRequestPendingListFailure(res?.errorCode || "error.defaultErr")
      );
    }
  } catch (error: any) {
    toast(
      error && error.description ? error.description : "orderBook.orderError",
      "error"
    );
    yield put(cancelRequestPendingListFailure(error));
  }
}

function* deleteRequestCondByListWorker(
  action: ReturnType<typeof deleteRequestCondByList>
) {
  try {
    const res: IDeleteRequestCondByListResponse = yield call(
      deleteRequestCondByListApi,
      action.payload.params
    );

    if (res.statusCode === 0) {
      yield put(deleteRequestCondByListSuccess(action.payload.params));

      toast(action.payload.content, "success");
      yield put(onLoadList(Math.random()));
    } else {
      toast(
        res?.errorCode ? "error." + res?.errorCode : "",
        "error",
        res.message,
        true
      );
      yield put(
        deleteRequestCondByListFailure(res?.errorCode || "error.defaultErr")
      );
    }
  } catch (error: any) {
    toast(
      error && error.description ? error.description : "orderBook.orderError",
      "error"
    );
    yield put(deleteRequestCondByListFailure(error));
  }
}

function* fetchRequestGetOrderBookSummary(
  action: PayloadAction<{ params: GetOrderBookSummaryParam }>
) {
  try {
    const orderBookSummary: GetOrderBookSummaryResponse = yield call(
      getAPIOrderBookSummary,
      action.payload.params
    );

    if (orderBookSummary != null && orderBookSummary.data) {
      let sumValueBuy = 0;
      let sumValueSell = 0;

      orderBookSummary.data.forEach((item) => {
        sumValueSell = sumValueSell + item.execsellamt;
        sumValueBuy = sumValueBuy + item.execbuyamt;
      });

      const summary: OrderBookSummary = {
        sumValueSell: sumValueSell,
        sumValueBuy: sumValueBuy,
        sumValue: sumValueBuy + sumValueSell,
      };

      yield put({
        type: getOrderBookSummarySuccess.type,
        payload: {
          items: orderBookSummary.data,
          summary: summary,
        },
      });
    } else {
      yield put({
        type: getOrderBookSummarySuccess.type,
        payload: {
          items: [],
          summary: {
            sumValueSell: 0,
            sumValueBuy: 0,
            sumValue: 0,
          },
        },
      });
    }
  } catch (error: any) {
    yield put({ type: getOrderBookSummaryFailure.type, payload: error });
  }
}

function* getAllTickerWatcher() {
  yield takeLatest(editOrder, editOrderWorker);
  yield takeLatest(requestGetOrderBook, fetchRequestGetOrderBook);
  yield takeLatest(requestGetOrderBookSummary, fetchRequestGetOrderBookSummary);
  yield takeLatest(requestGetOrderBookDetail, fetchRequestGetOrderBookDetail);
  yield takeLatest(findOrderDetail, findOrderDetailWorker);
  yield takeLatest(findOrderRequestCond, findOrderRequestCondWorker);
  yield takeLatest(findRequestPending, findRequestPendingWorker);
  yield takeLatest(findRequestCond, findRequestCondWorker);
  yield takeLatest(requestCancelOrderList, requestCancelOrderListAPI);
  yield takeLatest(cancelRequestPendingList, cancelRequestPendingListWorker);
  yield takeLatest(deleteRequestCondByList, deleteRequestCondByListWorker);
}

export default function* orderBookSaga() {
  yield all([getAllTickerWatcher()]);
  logTrackingSaga();
}
