import { toast } from "components/commons/Toast";
import {
  findPortfolio,
  getCustomerRestriction,
  inquiryAccountTransferSec,
  submitTransferSec,
} from "core/http/apis/transfer-share";
import {
  IInquiryAccountTransferSecParam,
  ISubmitTransferSecList,
  ISubmitTransferSecParam,
} from "core/http/apis/transfer-share/types";
import {
  IFindPortfolioResponse,
  IGetCustomerRestrictionResponse,
  IInquiryAccountTransferSecResponse,
  ISecTransferAvailRowData,
  ISubmitTransferSecResponse,
} from "domain/models/TransferShare";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  all,
  call,
  // delay,
  put,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import {
  listTransferShareFormFailed,
  listTransferShareFormSuccess,
  requestListTransferShareForm,
  requestSubmitTransferShareForm,
  requestSubmitTransferShareFormFailed,
  requestSubmitTransferShareFormSuccess,
} from "../redux/transferForm";

const convertDataTransferShareForm = (
  res: ISecTransferAvailRowData[]
): ISecTransferAvailRowData[] => {
  const data = res ?? [];
  return data
    .filter((item) => (item.secTransferAvail ?? 0) > 0)
    .map((item, index) => ({
      secCd: item.secCd,
      secTransaction: item.secTransaction ?? "0",
      secTransferAvail: item.secTransferAvail ?? "0",
      marginRate: item.marginRate ?? "0",

      id: index,
      visibleCheckbox: true,
    }));
};

function* listTransferShareFormWorker(
  action: ReturnType<typeof requestListTransferShareForm>
) {
  try {
    const params: IInquiryAccountTransferSecParam = action.payload;

    const response: IInquiryAccountTransferSecResponse = yield call(
      inquiryAccountTransferSec,
      params
    );

    const dataConvert = convertDataTransferShareForm(
      response?.data?.secBalanceList ?? []
    );

    yield put(listTransferShareFormSuccess(dataConvert));
  } catch (error: any) {
    yield put(listTransferShareFormFailed());
    toast(error.code, "error", error.description, true);
  }
}

function* findPortfolioResponseSaga(secCd: any, subAccoNoFrom: any, e: any) {
  const res: IFindPortfolioResponse = yield call(findPortfolio, {
    alloDate: +moment().format("YYYYMMDD"),
    custNo: null,
    secCd: secCd,
    subAccoNo: subAccoNoFrom,
  });
  if (res?.statusCode === 0) {
    if (!isEmpty(res.data)) {
      return {
        ...res?.data?.[0],
        quantity: e?.quantity,
        secTransaction: e?.secTransaction,
        secTransferAvail: e?.secTransferAvail,
        marginRate: e?.marginRate,
      };
    } else {
      return {
        secCd: secCd,
        avgPrice: 0,
        quantity: e?.quantity,
        secTransaction: e?.secTransaction,
        secTransferAvail: e?.secTransferAvail,
        marginRate: e?.marginRate,
      };
    }
  } else {
    toast(res.errorCode, "error", res.message, true);
    return;
  }
}

function* submitTransferShareFormWorker(
  action: ReturnType<typeof requestSubmitTransferShareForm>
) {
  try {
    const params: ISubmitTransferSecList = action.payload;

    const getCustomerRestrictionResponse: IGetCustomerRestrictionResponse =
      yield call(getCustomerRestriction, {
        restrictionCd: 5,
        subAccoCd: 0,
        subAccoNo: params.subAccoNoFrom,
      });

    if (getCustomerRestrictionResponse?.data !== 0) {
      return toast("transferShare.transferForm.errorBanAcc");
    }

    const findPortfolioResponse: [] = yield all(
      params.dataList?.map((e: any) =>
        findPortfolioResponseSaga(e.secCd, params.subAccoNoFrom, e)
      )
    );

    if (findPortfolioResponse.some((e: any) => e === undefined)) {
      return;
    }
    const listSec: any[] = findPortfolioResponse
      ? findPortfolioResponse
          .filter((e: any) => e)
          .map((i: any) => ({
            secCd: i?.secCd,
            price: i?.avgPrice,
            quantity: i?.quantity,
            secTransaction: i?.secTransaction,
            secTransferAvail: i?.secTransferAvail,
            marginRate: i?.marginRate,
          }))
      : [];

    const paramsConvert: ISubmitTransferSecParam = {
      subAccoNoFrom: params.subAccoNoFrom,
      subAccoNoTo: params.subAccoNoTo,
      // secCd: params.secCd,
      // quantity: params.quantity,
      remarks: params.remarks,
      // price: (findPortfolioResponse?.data?.[0]?.avgPrice ?? 0) * 1000, // Lấy từ API
      subAccoCdFrom: 0, // 0
      subAccoCdTo: 0, // 0
      otpCode: null, // Mã OTP
      dataList: JSON.stringify(listSec),
    };
    const response: ISubmitTransferSecResponse = yield call(
      submitTransferSec,
      paramsConvert
    );

    if (response.statusCode === 0) {
      yield put(requestSubmitTransferShareFormSuccess(response));
      toast("transferShare.transferForm.transferShareSuccess", "success");
    } else {
      yield put(requestSubmitTransferShareFormFailed());
      toast(response.errorCode, "error", response.message, true);
    }
  } catch (error: any) {
    yield put(requestSubmitTransferShareFormFailed());
    toast(error.code, "error", error.description, true);
  }
}

function* watchListTransferShareForm() {
  yield takeLeading(
    requestListTransferShareForm.type,
    listTransferShareFormWorker
  );
}

function* watchSubmitTransferShareForm() {
  yield takeEvery(
    requestSubmitTransferShareForm.type,
    submitTransferShareFormWorker
  );
}

export default function* transferShareFormSaga() {
  yield all([watchListTransferShareForm(), watchSubmitTransferShareForm()]);
}
