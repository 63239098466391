/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Wrap,
  WrapItem,
  BodyModalRow,
  TextCell,
  LabelCell,
  Title,
} from "./styles";
import { dertInquiryTradingApi } from "core/http/apis/assetsIncurred";
import storage from "helper/storage";
import { toast } from "components/commons/Toast";
import numeral from "numeral";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const ContentTop = () => {
  const subAcco = storage.getMasterAccount();
  const [data, setData] = useState<any>({});

  const getData = async () => {
    const params = {
      custNo: subAcco,
      secCd: "",
    };
    const res: any = await dertInquiryTradingApi(params);
    const resLogData = {
      eventGroup: GroupEvent.assets,
      event: RealtimeConst.getDataDerivativePosition,
      message: JSON.stringify(params),
    };

    storage.commonPushLogFirebase(resLogData);

    if (res?.statusCode === 0) {
      setData(res?.data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    getData();
  }, [subAcco]);

  return (
    <>
      <Wrap>
        <WrapItem>
          <Title>
            <FormattedMessage id="assets.assetsIncurred.top.information.title" />
          </Title>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.deposit" />
            </LabelCell>
            <TextCell>{numeral(data?.cashMargin).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.ckDeposit" />
            </LabelCell>
            <TextCell>{numeral(data?.secMargin).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.withdrawable" />
            </LabelCell>
            <TextCell>{numeral(data?.cashWithDraw).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.opening" />
            </LabelCell>
            <TextCell>{numeral(data?.availMargin).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.moneyWait" />
            </LabelCell>
            <TextCell>{numeral(data?.pendingMargin).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.moneyWaitWithdraw" />
            </LabelCell>
            <TextCell>{numeral(data?.pendingWithDraw).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.owe" />
            </LabelCell>
            <TextCell>{numeral(data?.debitAmt).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.moneyAcco" />
            </LabelCell>
            <TextCell>{numeral(data?.derivativeCash).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.information.realtime" />
            </LabelCell>
            <TextCell>{numeral(data?.derivativeAsset).format("0,0")}</TextCell>
          </BodyModalRow>
        </WrapItem>

        <WrapItem>
          <Title>
            <FormattedMessage id="assets.assetsIncurred.top.use.title" />
          </Title>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.use.sum" />
            </LabelCell>
            <TextCell>
              {numeral(data?.cashMargin + data?.secMargin).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.use.price" />
            </LabelCell>
            <TextCell>{numeral(data?.im).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.use.ratio" />
            </LabelCell>
            <TextCell>{data?.currentRate}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.use.status" />
            </LabelCell>
            <TextCell>
              {data?.vsdAccoState ? (
                <FormattedMessage
                  id={`assets.assetsIncurred.top.information.status.value${data?.vsdAccoState}`}
                />
              ) : (
                <FormattedMessage id="assets.assetsIncurred.top.information.status.default" />
              )}
            </TextCell>
          </BodyModalRow>

          {/* <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.use.result" />
            </LabelCell>
            <TextCell>thiếu kết quả bổ sung</TextCell>
          </BodyModalRow> */}
        </WrapItem>

        <WrapItem>
          <Title>
            <FormattedMessage id="assets.assetsIncurred.top.profit.title" />
          </Title>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.profit.waitTT" />
            </LabelCell>
            <TextCell>{numeral(data?.pendingSett).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.profit.temporary" />
            </LabelCell>
            <TextCell>{numeral(data?.vm).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assetsIncurred.top.profit.sum" />
            </LabelCell>
            <TextCell>{numeral(data?.amtOtherFee).format("0,0")}</TextCell>
          </BodyModalRow>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default ContentTop;
