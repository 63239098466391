import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  SectOwnershipItem,
  SectProfileItem,
} from "domain/protoNew/auto_trading_pb";
import { CHART_COLORS } from "helper/consts";
import sumBy from "lodash/sumBy";

export type ChartData = {
  dataChart: {};
  colors: string[];
  labels: string[];
};

export type OgrInfoState = {
  data: SectProfileItem.AsObject[];
  chartDataStakeHolder: ChartData | undefined;
  chartDataOwnPercent: ChartData | undefined;
  loading: boolean;
  error: string | undefined;
};

const initialState: OgrInfoState = {
  data: [],
  loading: false,
  error: undefined,
  chartDataStakeHolder: undefined,
  chartDataOwnPercent: undefined,
};

const formatDataStakeHolderChart = (rawData: SectProfileItem.AsObject[]) => {
  if (!rawData) return; // handle error here
  const otherTotal = 100 - sumBy(rawData, "Ratio");
  if (otherTotal > 0) {
    rawData.push({
      id: 0,
      seccd: "",
      shareholdername: "Cổ đông khác",
      shareholdingpercentage: otherTotal,
      remarks: "",
    });
  }
  const names = rawData.map((el) => el.shareholdername);
  let colors = rawData.map((el, idx) => CHART_COLORS[idx]);

  const dataChart = {
    labels: rawData.map((el) => el.shareholdername),
    datasets: [
      {
        label: "",
        data: rawData.map((el) => el.shareholdingpercentage),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
  return {
    dataChart,
    colors,
    labels: names,
  };
};

const formatDataOwnPercentChart = (rawData: SectOwnershipItem.AsObject[]) => {
  if (!rawData) return; // handle error here
  if (rawData.length <= 0) return;
  const totalDomestic =
    rawData[0]?.domesticpersonpercentage +
    rawData[0]?.domesticorganizationpercentage;
  const totalForeign =
    rawData[0]?.foreignpersonpercentage +
    rawData[0]?.foreignorganizationpercentage;
  const otherTotal = 100 - (totalDomestic + totalForeign);

  const names =
    otherTotal > 0
      ? ["Nhà đầu tư trong nước", "Nhà đầu tư ngoài nước", "Cổ đông khác"]
      : ["Nhà đầu tư trong nước", "Nhà đầu tư ngoài nước"];
  let colors = names.map((el, idx) => CHART_COLORS[idx]);

  const dataChart = {
    labels: names,
    datasets: [
      {
        label: "",
        data: [totalDomestic, totalForeign],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
  return {
    dataChart,
    colors,
    labels: names,
  };
};

const orgInfo = createSlice({
  name: "orgInfoSlice",
  initialState,
  reducers: {
    getOrgInfo: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = undefined;
    },
    getOrgInfoSuccess: (
      state,
      action: PayloadAction<SectProfileItem.AsObject[]>
    ) => {
      state.data = action.payload;
      state.chartDataStakeHolder = formatDataStakeHolderChart(action.payload);
      state.loading = false;
    },
    getOwnershipSuccess: (
      state,
      action: PayloadAction<SectOwnershipItem.AsObject[]>
    ) => {
      state.chartDataOwnPercent = formatDataOwnPercentChart(action.payload);
    },
    getOrgInfoFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getOrgInfo,
  getOrgInfoSuccess,
  getOwnershipSuccess,
  getOrgInfoFailure,
} = orgInfo.actions;

export default orgInfo.reducer;
