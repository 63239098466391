import { Tooltip } from "react-bootstrap";
import styled from "styled-components/macro";

export const Widgetcontainer = styled.div`
  height: 100%;
`;

export const TableWrapper = styled.div`
  height: 100%;
  position: relative;

  thead > tr > th {
    z-index: 0;
    position: sticky;
    top: 0;
  }
`;

export const TableCell = styled.div<{
  widthCell?: number;
  maxWidthCell?: number;
  flexCell?: number;
}>`
  flex: 1;
  display: flex;
  ${({ widthCell }) => widthCell && `min-width: ${widthCell}px;`}
  ${({ maxWidthCell }) => maxWidthCell && `max-width: ${maxWidthCell}px;`}
  ${({ flexCell }) => flexCell && `flex: ${flexCell};`}

  &.ticker-cell {
    position: sticky;
    left: 0;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;

      & .ticker-text,
      & .company-name-text {
        padding-top: 0;
        padding-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        user-select: none;
      }

      & .company-name-text {
        color: ${({ theme }) => theme.grey30};
        font-size: ${({ theme }) => theme.typeFaces.caption11.size};
      }
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(37, 49, 71, 0.8);
    border-radius: 4px;
    padding: 8px 12px;
    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export const Container = styled.div`
  height: calc(100% - 48px);
`;

export const HeaderContainer = styled.div`
  padding: 12px;
`;

export const ContentContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const TableBodyWrapper = styled.div`
  height: calc(100% - 25px);

  @keyframes blink-up {
    0% {
      background: ${({ theme }) => theme.secondaryGreenDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    90% {
      background: ${({ theme }) => theme.secondaryGreenDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    100% {
      background: none;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }

  @keyframes blink-down {
    0% {
      background: ${({ theme }) => theme.secondaryRedDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    90% {
      background: ${({ theme }) => theme.secondaryRedDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    100% {
      background: none;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }

  .cell-blink-up {
    text-decoration: ${({ theme }) => theme.textcolorWhite};
    animation-name: blink-up;
    -webkit-animation-name: blink-up;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  .cell-blink-down {
    text-decoration: ${({ theme }) => theme.textcolorWhite};
    animation-name: blink-down;
    -webkit-animation-name: blink-down;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
`;
