import { ToastType, requestOpenToast } from "modules/system/redux/toast";
import { store } from "redux/store";
import { v4 as uuidv4 } from "uuid";

const toast = (
  message: string,
  type: ToastType = "default",
  defaultMessage?: string,
  isIntlKey?: Boolean,
  delay?: number,
  param?: any
) => {
  // Bypass trường hợp lỗi 401 refresh token parallel
  if (message === undefined || message === "") {
    return;
  }

  // Show Toast
  store.dispatch(
    requestOpenToast({
      id: uuidv4(),
      text: message,
      type,
      defaultMessage,
      isIntlKey,
      param,
    })
  );
};

export default toast;
