import { createSelector } from "@reduxjs/toolkit";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import { TextCell } from "../styles";

interface Props {
  ticker: string;
}

const issuerSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.issuer?.data
  );
};

function IssuerCell({ ticker }: Props) {
  const issuer = useSelector(issuerSelector(ticker));

  return <TextCell className="text-right">{issuer}</TextCell>;
}

export default IssuerCell;
