import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, Input, Spacer } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { storageOTPCodeSelector } from "modules/auth/selectors";
import { memo, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import QRCode from "../QRCode";
import ResendCode from "./ResendCode";
import {
  ButtonExit,
  CardTable,
  ErrorVerify,
  GetCodeCell,
  HeaderTitle,
  Icon,
  InputCell,
  InputOTPContainer,
  InputWrapper,
  InvisibleText,
  LabelInput,
  LabelWrapper,
  ModalHeader,
  ModalTab,
  Tab,
  WrapperButton,
} from "./styles";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
import storage from "helper/storage";
interface Props {
  handleModal: any;
  changeMasterAccount: any;
  otpEverryTime?: boolean; // otpEverryTime là check mỗi lần mở ra, mặc định = true
  submitOTP?: any;
  errorOTP?: string;
  setErrorOtp?: any;
  handleCreateSmsOTP?: any;
  getResult?: any;
  businessCd: EBusinessCd;
}

const radius4 = 4;

enum TAB_CODE {
  SMS_OTP = "SMS_OTP",
  QR_CODE = "QR_CODE",
}

const DEFAULT_TIMER = 30;

function SmsOTP({
  handleModal,
  changeMasterAccount,
  otpEverryTime = true,
  handleCreateSmsOTP,
  submitOTP,
  errorOTP,
  setErrorOtp,
  getResult,
  businessCd,
}: Props) {
  const storageOTPCode = useSelector(storageOTPCodeSelector);
  const intl = useIntl();
  const [codeOTP, setCodeOTP] = useState("");
  const inputEl = useRef<HTMLInputElement | null>(null);

  const allParam: any = 30;
  const [defaultTimer, setDefaultTimer] = useState(
    allParam?.SMS_OTP_TIME_OUT ?? DEFAULT_TIMER
  );

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(TAB_CODE.SMS_OTP);

  const handelVerifySmsOtp = (scanId?: string) => {
    const resLogData = {
      eventGroup: GroupEvent.registerOtp,
      event: RealtimeConst.submitSMSOTP,
      message: "submit sms Otp",
    };
    storage.commonPushLogFirebase(resLogData);
    setErrorOtp("");
    submitOTP(codeOTP);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && loading === false) {
      setLoading(true);
      handelVerifySmsOtp();
    }
  };

  const changeTabHandler = (tabCode: TAB_CODE) => () => {
    setTab(tabCode);
  };

  useEffect(() => {
    if (tab === TAB_CODE.SMS_OTP) {
      // Clear data
      setCodeOTP("");
      setLoading(false);

      // Gọi API create Sms OTP
      handleCreateSmsOTP?.();

      // Auto focus input
      if (inputEl.current) {
        inputEl.current.focus();
      }
    } else {
      setDefaultTimer(0);
    }
  }, [tab]);

  return (
    <>
      {(storageOTPCode?.code === "" || otpEverryTime === true) && (
        <Modal
          show={true}
          centered
          // backdrop={true}
          keyboard={false}
          dialogClassName="modal-434"
        >
          <ModalHeader>
            <HeaderTitle>
              <FormattedMessage id="auth.info.sendOTP.title" />
            </HeaderTitle>

            <WrapperButton>
              <ButtonExit onClick={handleModal}>
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            </WrapperButton>
          </ModalHeader>

          <ModalTab>
            <Tab
              isActive={tab === TAB_CODE.SMS_OTP}
              onClick={changeTabHandler(TAB_CODE.SMS_OTP)}
            >
              <FormattedMessage id="auth.info.smsOTP.title" />
            </Tab>
            <Tab
              isActive={tab === TAB_CODE.QR_CODE}
              onClick={changeTabHandler(TAB_CODE.QR_CODE)}
            >
              <FormattedMessage id="auth.info.smartOTP.qrcode" />
            </Tab>
          </ModalTab>

          {tab === TAB_CODE.SMS_OTP && (
            <CardTable>
              <LabelWrapper>
                <LabelInput>
                  <FormattedMessage id="auth.info.smsOTP.enterOTP" />
                </LabelInput>
                <GetCodeCell>
                  <ResendCode
                    handleCreateSmsOTP={handleCreateSmsOTP}
                    firstTime={defaultTimer}
                    duringTime={allParam?.SMS_OTP_TIME_OUT ?? DEFAULT_TIMER}
                  />
                </GetCodeCell>
              </LabelWrapper>

              <InputOTPContainer>
                <InputCell>
                  <InputWrapper>
                    <Input
                      maxLength={6}
                      placeholder={intl.formatMessage({
                        id: "auth.info.smsOTP.enterOTP",
                      })}
                      value={codeOTP}
                      onKeyDown={(e) => handleKeyDown(e)}
                      onChange={(e) => {
                        setCodeOTP(e.target.value);
                        const resLogData = {
                          eventGroup: GroupEvent.registerOtp,
                          event: RealtimeConst.submitSMSOTP,
                          message: "onChange sms Otp",
                        };
                        storage.commonPushLogFirebase(resLogData);
                        setErrorOtp("");
                      }}
                      innerRef={inputEl}
                    />
                  </InputWrapper>
                </InputCell>
              </InputOTPContainer>

              <ErrorVerify>
                {errorOTP !== "" ? (
                  intl.formatMessage({
                    id: errorOTP || "error.defaultErr",
                  })
                ) : (
                  <InvisibleText>.</InvisibleText>
                )}
              </ErrorVerify>

              <div className="footer">
                <div style={{ flex: 7 }}></div>
                <div style={{ flex: 9, display: "flex", flexDirection: "row" }}>
                  <Button
                    size="l"
                    style={{ flex: 1 }}
                    color="accept"
                    className="flex-1"
                    radius={radius4}
                    onClick={handleModal}
                  >
                    <FormattedMessage id="auth.info.smartOTP.cancel" />
                  </Button>
                  <Spacer size="m" />
                  <Button
                    disabled={codeOTP.length < 6}
                    size="l"
                    fill={true}
                    style={{ flex: 1 }}
                    loading={loading}
                    onClick={handelVerifySmsOtp}
                    className="flex-1"
                    radius={radius4}
                    color="gradientaAccept"
                  >
                    <FormattedMessage id="auth.info.editInfo.update" />
                  </Button>
                </div>
              </div>
            </CardTable>
          )}

          {tab === TAB_CODE.QR_CODE && (
            <QRCode businessCd={businessCd} getResult={getResult} />
          )}
        </Modal>
      )}
    </>
  );
}
export default memo(SmsOTP);
