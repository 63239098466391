import { Spacer } from "components/commons";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import { FormattedMessage } from "react-intl";
import AskBid from "./AskBid";
import ProgressBuySell from "./ProgressBuySell";
import { HeaderSection, PriceListWrapper, NameTicker } from "./styles";
import { useSelector } from "react-redux";
import GlobalData from "helper/globalData";
import { getMarketLabel } from "helper/utils";

function PriceList({ isEnableHeader }: { isEnableHeader?: boolean }) {
  const selectedTicker = useSelector(selectedTickerSelector);
  return (
    <>
      {isEnableHeader && (
        <HeaderSection>
          <FormattedMessage id="widgets.priceList.name" />
        </HeaderSection>
      )}
      <PriceListWrapper>
        {isEnableHeader && (
          <NameTicker>
            <div className="seccd">{selectedTicker}</div>
            <div className="marketcd">
              {"(" +
                getMarketLabel(
                  GlobalData.getTickerInfoNew(selectedTicker!)?.marketcd
                ) +
                ") " +
                GlobalData.getTickerInfoNew(selectedTicker!)?.secname}
            </div>
          </NameTicker>
        )}
        <div className="progress-container">
          <Spacer size="m" />
          <ProgressBuySell />
          <Spacer size="s" />
        </div>
        <AskBid />
      </PriceListWrapper>
    </>
  );
}

export default PriceList;
