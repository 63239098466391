import styled from "styled-components/macro";

const IconContainer = styled.div<{ isBorder?: boolean }>`
  background: ${({ theme }) => theme.grey70};
  ${({ isBorder, theme }) => isBorder && "none"};
  height: ${({ theme }) => theme.inputDecrementIncrement.height};
  width: ${({ theme }) => theme.inputDecrementIncrement.width};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SuggestionContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .item {
    flex: 1 0 auto;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
    background: ${({ theme }) => theme.grey70};
    border: 1px solid ${({ theme }) => theme.grey70};
    padding: 2px;
    color: ${({ theme }) => theme.grey20};
    :hover {
      background: ${({ theme }) => theme.grey80};
      color: ${({ theme }) => theme.brandPrimary};
      border: 1px solid ${({ theme }) => theme.brandPrimary};
    }
    :not(:last-child) {
      margin-right: 8px;
    }
  }
`;
const Title = styled.div`
  flex: 2;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.grey30};
`;
const Body = styled.div`
  flex: 3;
`;
const Empty = styled.div`
  flex: 2;
`;
const Error = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;

export { IconContainer, SuggestionContainer, Title, Body, Empty, Error };
