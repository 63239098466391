import { Button, DatePicker } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import { EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  searchListMoneyStatementRequest,
  updateMoneyStatementFilter,
} from "modules/account-statement/redux";
import {
  filterMoneyStatementSelector,
  loadingSelector,
  selectLimitPage,
  surplusSelector,
} from "modules/account-statement/selectors";
import moment from "moment";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterWrapper,
  FlexContainer,
  Label,
  LabelRight,
  WrapBoder,
  WrapperContainer,
  WrapperRight,
} from "./styles";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

function MoneyStatementFilter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(surplusSelector);
  const loading = useSelector(loadingSelector);
  const limit = useSelector(selectLimitPage);
  const { subAccoNo, fromDate, toDate } = useSelector(
    filterMoneyStatementSelector
  );

  const listAcc = GetAccoList(undefined, EntrustBusinessCode.Inquiry);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const handleSearch = () => {
    if (moment(fromDate).isAfter(moment(toDate))) {
      toast(
        `${intl.formatMessage({
          id: "accountStatement.errorToDate",
        })}`,
        "error"
      );
    } else {
      const params = {
        filter: {
          fil_accountType: "0",
          fil_subAccoNo: subAccoNo?.value
            ? subAccoNo?.value
            : parseOptionAccount[0]?.value,
          fil_fromDate: moment(fromDate).format("YYYYMMDD"),
          fil_toDate: moment(toDate).format("YYYYMMDD"),
          p_BEGIN_AMT: 0,
          p_LAST_AMT: 0,
          page: 0,
          rowPerPage: limit,
        },
      };

      dispatch(searchListMoneyStatementRequest(params));
      const resLogData = {
        eventGroup: GroupEvent.satementMoney,
        event: RealtimeConst.getDataSatementMoney,
        message: JSON.stringify(params),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };

  return (
    <WrapBoder className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FlexContainer>
          <FilterItem
            filterName={intl.formatMessage({
              id: "accountStatement.account",
            })}
            className="mr-4"
          >
            <CSelect
              defaultValue={parseOptionAccount[0]}
              options={parseOptionAccount}
              onChange={(e) => {
                dispatch(
                  updateMoneyStatementFilter({
                    key: "subAccoNo",
                    value: {
                      label: e?.label,
                      value: e?.value ? e.value : "",
                    },
                  })
                );
              }}
              value={
                subAccoNo?.value !== "" ? subAccoNo : parseOptionAccount[0]
              }
            />
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "accountStatement.fromDate",
            })}
            className="mr-4"
          >
            <DatePicker
              selected={fromDate}
              onChange={(e: any) => {
                dispatch(
                  updateMoneyStatementFilter({
                    key: "fromDate",
                    value: e,
                  })
                );
              }}
              popperPlacement="bottom-start"
            />
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "accountStatement.toDate",
            })}
            className="mr-4"
          >
            <DatePicker
              selected={toDate}
              onChange={(e: any) => {
                dispatch(
                  updateMoneyStatementFilter({
                    key: "toDate",
                    value: e,
                  })
                );
              }}
              popperPlacement="bottom-start"
            />
          </FilterItem>

          <Button
            color="accept"
            className="button-filter mr-4"
            loading={loading}
            onClick={handleSearch}
            width={"149px"}
          >
            {intl.formatMessage({
              id: "accountStatement.search",
            })}
          </Button>
        </FlexContainer>

        <WrapperContainer>
          <WrapperRight>
            <Label>
              {intl.formatMessage({
                id: "accountStatement.openingBalance",
              })}
            </Label>
            <Label>
              {intl.formatMessage({
                id: "accountStatement.endingBalane",
              })}
            </Label>
          </WrapperRight>

          <WrapperRight>
            <LabelRight>{numeral(data?.p_BEGIN_AMT).format("0,0")}</LabelRight>
            <LabelRight>{numeral(data?.p_LAST_AMT).format("0,0")}</LabelRight>
          </WrapperRight>
        </WrapperContainer>
      </FilterWrapper>
    </WrapBoder>
  );
}

export default memo(MoneyStatementFilter);
