import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TopForeignMenu } from "components/widgets/generals/TopForeign/types";

import { Dictionary } from "lodash";
import { SORT_BY } from "components/widgets/generals/TopForeign/constants";
import {
  ForeignRoom,
  InternalMS,
  LastSale,
  ORDER_TYPE,
  ORDER_TYPEMap,
} from "domain/protoNew/auto_trading_pb";
import { TOP_PRICE_LOADING_LIMIT } from "AppConfig";

export interface IActiveSort {
  sortBy: SORT_BY;
  order: ORDER_TYPEMap[keyof ORDER_TYPEMap];
}

export function getSortByActiveTab(value: TopForeignMenu) {
  switch (value) {
    case "foreignNetValuesBuy":
      return SORT_BY.TOP_VALUE_BUY;
    case "foreignNetValuesSell":
      return SORT_BY.TOP_VALUE_SELL;
    case "foreignBuy":
      return SORT_BY.QTY_BUYFOREIGN;
    case "foreignSell":
      return SORT_BY.QTY_SELLFOREIGN;
    case "foreignBuyValue":
      return SORT_BY.VALUE_BUYFOREIGN;
    case "foreignSellValue":
      return SORT_BY.VALUE_SELLFOREIGN;
    default:
      return SORT_BY.TOP_VALUE_BUY;
  }
}

export type TopForeignState = {
  activeTab: TopForeignMenu;
  data: Dictionary<InternalMS.AsObject>;
  loading: boolean;
  error: string | undefined;
  tickerList: string[];
  activeSort: IActiveSort;
  limit: number;
  enableLoadMore: boolean;
};

const initialState: TopForeignState = {
  activeTab: "foreignNetValuesBuy" as TopForeignMenu,
  data: {},
  loading: false,
  error: undefined,
  tickerList: [],
  activeSort: {
    sortBy: SORT_BY.TOP_VALUE_BUY,
    order: ORDER_TYPE.ORDER_DEFAULT,
  },
  limit: TOP_PRICE_LOADING_LIMIT,
  enableLoadMore: true,
};

const topForeign = createSlice({
  name: "topForeignSlice",
  initialState,
  reducers: {
    cleanData: (state) => {
      return initialState;
    },
    requestChangeTopForeignType: (
      state,
      action: PayloadAction<TopForeignMenu>
    ) => {
      state.activeTab = action.payload;
      state.loading = true;
      state.error = undefined;
      state.data = {};
      state.enableLoadMore = true;
      state.tickerList = [];
      state.activeSort = {
        sortBy: getSortByActiveTab(action.payload),
        order: ORDER_TYPE.ORDER_DEFAULT,
      };
    },
    changeForeignTypeSuccess: (
      state,
      action: PayloadAction<InternalMS.AsObject[]>
    ) => {
      state.data = action.payload.reduce(
        (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
        {}
      );
      state.limit = TOP_PRICE_LOADING_LIMIT;
      state.tickerList = Object.keys(state.data);

      state.loading = false;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    changeForeignFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;

      state.loading = false;
    },
    updateTickerLastSale: (state, action: PayloadAction<LastSale.AsObject>) => {
      const { payload } = action;
      const ticker = state.data[payload.seccd];

      if (!!ticker) {
        ticker.closeprice = payload.lastprice;
        ticker.changepoint = payload.changepoint;
        ticker.changepercent = payload.changepercent;
        ticker.colorcode = payload.colorcode;
      }
    },
    updateTickerForeignRoom: (
      state,
      action: PayloadAction<ForeignRoom.AsObject>
    ) => {
      const { payload } = action;
      const ticker = state.data[payload.seccd];

      if (!!ticker) {
        ticker.buyforeignqty = payload.buyforeignqty;
        ticker.sellforeignqty = payload.sellforeignqty;
        ticker.buyforeignamt = payload.buyforeignamt;
        ticker.sellforeignamt = payload.sellforeignamt;
      }
    },
    changeActiveSort: (state, action: PayloadAction<IActiveSort>) => {
      state.activeSort = action.payload;
      state.loading = true;
      state.data = {};
      state.error = undefined;
      state.tickerList = [];
      state.enableLoadMore = true;
    },
    requestTopForeign: (state) => {},
    requestTopForeignSuccess: (
      state,
      action: PayloadAction<InternalMS.AsObject[]>
    ) => {
      state.data = action.payload.reduce(
        (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
        {}
      );
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;

      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    requestLoadMore: (state) => {},
    requestLoadMoreSuccess: (
      state,
      action: PayloadAction<InternalMS.AsObject[]>
    ) => {
      state.limit = state.limit + TOP_PRICE_LOADING_LIMIT;
      state.data = {
        ...state.data,
        ...action.payload.reduce(
          (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
          {}
        ),
      };
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;

      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
  },
});

export const {
  cleanData,
  requestChangeTopForeignType,
  changeForeignTypeSuccess,
  changeForeignFailure,
  updateTickerLastSale,
  updateTickerForeignRoom,
  changeActiveSort,
  requestTopForeign,
  requestTopForeignSuccess,
  requestLoadMore,
  requestLoadMoreSuccess,
} = topForeign.actions;

export default topForeign.reducer;
