import { ReactComponent as IconRefresh } from "assets/image/svg/ic_refresh.svg";
import { Button, InputSymbol, Select } from "components/commons";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { GetPortfolioParams } from "core/http/apis/account/types";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
import { PortfolioItem } from "domain/models/Portfolio";
import { LastSale } from "domain/protoNew/auto_trading_pb";
import {
  EntrustBusinessCode,
  GetAccountType,
  PORTFOLIO_THROTTLE_TIME,
} from "helper/consts";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { GetAccoList } from "helper/sessionData";
// import storage from "helper/storage";
import { throttle } from "lodash";
import { changeCurrentAccount } from "modules/auth/redux";
import {
  currentAccountSelector,
  isAuthenticatedSelector,
  tradeDateSelector,
} from "modules/auth/selectors";
import { addStockOrderWidgetName } from "modules/order-book/redux/index";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import { onBuySellType } from "modules/stock-order/redux/placeOrder";
import {
  clearDataRefresh,
  onLoadListPortfolio,
  requestPortfolio,
  updateAccountPortfolio,
  updatePortfolioRealTime,
  updateSecCdPortfolio,
} from "modules/stock-order/redux/portfolio";
import {
  accountPortfolioSelector,
  secCdPortfolioSelector,
  checkEnableInfinityPortfolioSelector,
  isLoadListPortfolioSelector,
  itemsPortfolioSelector,
  // queryParamsPortfolioSelector,
} from "modules/stock-order/redux/selectors";
// import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { useEffectOnce } from "react-use";
import MarketPriceCell from "./CellRealTime/MarketPriceCell";
import RatioCell from "./CellRealTime/RatioCell";
import StockValueCell from "./CellRealTime/StockValueCell";
import SymbolCell from "./CellRealTime/SymbolCell";
import UnrealizedProfitCell from "./CellRealTime/UnrealizedProfitCell";
import UnrealizedProfitRateNumCell from "./CellRealTime/UnrealizedProfitRateNumCell";
import InitialValueFooter from "./FooterRealTime/InitialValueFooter";
import StockValueFooter from "./FooterRealTime/StockValueFooter";
import UnrealizedProfitFooter from "./FooterRealTime/UnrealizedProfitFooter";
import UnrealizedProfitRateNumFooter from "./FooterRealTime/UnrealizedProfitRateNumFooter";
import ModalSubmitSellAll from "./ModalSubmitSellAll/ModalSellAllPortfolio";
import {
  ButtonBuy,
  ButtonRefreshData,
  ButtonSell,
  Control,
  Label,
  PortfolioBox,
  PortfolioContainer,
  SearchContainer,
} from "./styles";

export const getColor = (value: number | undefined) => {
  if (value && value > 0) {
    return "green50";
  } else if (value === 0) {
    return "yellow50";
  } else {
    return "red50";
  }
};

const Portfolio = React.memo(() => {
  const flexEnd = "flex-end";
  const dispatch = useDispatch();
  const intl = useIntl();
  const itemsPortfolio = useSelector(itemsPortfolioSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const checkEnableInfinity = useSelector(checkEnableInfinityPortfolioSelector);
  const isLoadListPortfolio = useSelector(isLoadListPortfolioSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const account = useSelector(accountPortfolioSelector);
  const secCd = useSelector(secCdPortfolioSelector);
  const currentAccount = useSelector(currentAccountSelector);
  const [visibleModalSubmit, setVisibleModalSubmit] = useState(false);
  const [data, setData] = useState<PortfolioItem[]>([]);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const accounts = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    list.unshift({
      label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
      value: "0",
    });
    return list;
  }, [listAcc, intl]);

  useEffectOnce(() => {
    const eventListenerOrder = () => {
      dispatch(onLoadListPortfolio(true));
    };
    emitter.on(
      EMMIT_ACTION_TYPE.EMMIT_Order,
      throttle(eventListenerOrder, PORTFOLIO_THROTTLE_TIME, {
        trailing: true,
        leading: false,
      })
    );
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_Order, eventListenerOrder);
    };
  });

  useEffect(() => {
    if (currentAccount) {
      dispatch(
        updateAccountPortfolio(
          currentAccount?.subAccoNo ? currentAccount?.subAccoNo : ""
        )
      );
      dispatch(onLoadListPortfolio(true));
    }
  }, [currentAccount.subAccoNo]);

  useEffect(() => {
    if (itemsPortfolio.length > 0) {
      const listTicker = itemsPortfolio
        .map((item) => item.secCd)
        ?.toString()
        .replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
    return () => {
      if (itemsPortfolio.length > 0) {
        const listTicker = itemsPortfolio
          .map((item) => item.secCd)
          ?.toString()
          .replace(/,/g, ";");
        unSubscribeTickers(listTicker);
      }
    };
  }, [itemsPortfolio.length]);

  useEffectOnce(() => {
    const eventListenerLastSale = (data: LastSale.AsObject) => {
      dispatch(updatePortfolioRealTime(data));
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    };
  });

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent className="portfolio-account">
            <FormattedMessage id="widgets.portfolio.userName" />
          </TableHeaderCellContent>
        ),
        accessor: "subAccountNo",
        minWidth: 100,
        Cell: (props: any) => {
          const { value } = props;
          return <TableCellContent>{value || "-"}</TableCellContent>;
        },
        Footer: (
          <TableHeaderCellContent
            className="portfolio-account"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent className="portfolio-symbol">
            <FormattedMessage id="widgets.portfolio.code" />
          </TableHeaderCellContent>
        ),
        accessor: "secCd",
        minWidth: 60,
        Cell: (props: CellProps<PortfolioItem>) => {
          const orderId = props.row.original.orderId;
          return <SymbolCell orderId={orderId} />;
        },
        Footer: (
          <TableHeaderCellContent
            className="portfolio-symbol"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-shares-balance"
          >
            <FormattedMessage id="widgets.portfolio.sharesBalance" />
          </TableHeaderCellContent>
        ),
        accessor: "remainQty",
        minWidth: 70,
        Cell: (props: any) => {
          const { value } = props;
          const sharesBalance = numeral(value).format("0,0");
          return (
            <TableCellContent align={flexEnd}>
              {sharesBalance || "-"}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-shares-balance"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-quantity"
          >
            <FormattedMessage id="widgets.portfolio.weight" />
          </TableHeaderCellContent>
        ),
        accessor: "availQty",
        minWidth: 70,
        Cell: (props: any) => {
          const { value } = props;
          const quantity = numeral(value).format("0,0");
          return (
            <TableCellContent align={flexEnd}>
              {quantity || "-"}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-quantity"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-rightShares"
          >
            <FormattedMessage id="widgets.portfolio.rightShares" />
          </TableHeaderCellContent>
        ),
        accessor: "rightQty",
        minWidth: 85,
        Cell: (props: any) => {
          const { value } = props;
          const rightShares = numeral(value).format("0,0");
          return (
            <TableCellContent align={flexEnd}>
              {rightShares || "-"}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-blockedQuantity"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-avgPrice"
          >
            <FormattedMessage id="widgets.portfolio.avgPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "avgPrice",
        minWidth: 76,
        Cell: (props: any) => {
          const { value } = props;
          const avgPrice = numeral(value).format("0,0.00");
          return (
            <TableCellContent align={flexEnd}>
              {avgPrice || "-"}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-avgPrice"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-marketPrice"
          >
            <FormattedMessage id="widgets.portfolio.marketPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "marketPrice",
        minWidth: 75,
        Cell: (props: CellProps<PortfolioItem>) => {
          const orderId = props.row.original.orderId;
          return <MarketPriceCell orderId={orderId} />;
        },
        Footer: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-marketPrice"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-grossValue"
          >
            <FormattedMessage id="widgets.portfolio.grossValue" />
          </TableHeaderCellContent>
        ),
        accessor: "initialValue",
        minWidth: 100,
        Cell: (props: any) => {
          const { value } = props;
          const grossValue = numeral(value).format("0,0");
          return (
            <TableCellContent align={flexEnd}>
              {grossValue || "-"}
            </TableCellContent>
          );
        },
        Footer: <InitialValueFooter />,
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-marketValue"
          >
            <FormattedMessage id="widgets.portfolio.marketValue" />
          </TableHeaderCellContent>
        ),
        accessor: "stockValue",
        minWidth: 110,
        Cell: (props: CellProps<PortfolioItem>) => {
          const orderId = props.row.original.orderId;
          return <StockValueCell orderId={orderId} />;
        },
        Footer: <StockValueFooter />,
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-marketValueRatio"
          >
            <FormattedMessage id="widgets.portfolio.marketValueRatio" />
          </TableHeaderCellContent>
        ),
        accessor: "ratio",
        minWidth: 75,
        Cell: (props: CellProps<PortfolioItem>) => {
          const orderId = props.row.original.orderId;
          return <RatioCell orderId={orderId} />;
        },
        Footer: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-marketValueRatio"
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <TableHeaderCellContent align={flexEnd} className="portfolio-profit">
            <FormattedMessage id="widgets.portfolio.profit" />
          </TableHeaderCellContent>
        ),
        accessor: "unrealizedProfit",
        minWidth: 100,
        Cell: (props: CellProps<PortfolioItem>) => {
          const orderId = props.row.original.orderId;
          return <UnrealizedProfitCell orderId={orderId} />;
        },
        Footer: <UnrealizedProfitFooter />,
      },
      {
        Header: (
          <TableHeaderCellContent
            align={flexEnd}
            className="portfolio-profitRatio"
          >
            <FormattedMessage id="widgets.portfolio.profitRatio" />
          </TableHeaderCellContent>
        ),
        accessor: "unrealizedProfitRateNum",
        minWidth: 100,
        Cell: (props: CellProps<PortfolioItem>) => {
          const orderId = props.row.original?.orderId;
          const costPrice = props.row.original?.avgPrice;
          return (
            <UnrealizedProfitRateNumCell
              costPrice={costPrice}
              orderId={orderId}
            />
          );
        },
        Footer: <UnrealizedProfitRateNumFooter />,
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          return (
            <div className="btn_action_hover">
              <ButtonBuy
                className="buy"
                onClick={() => handleBuySell(props?.row?.original, "buy")}
              >
                <FormattedMessage id="extends.profitLossEquity.tableContent.buy" />
              </ButtonBuy>
              {props?.row?.original?.availQty > 0 && (
                <ButtonSell
                  className="sell"
                  onClick={() => handleBuySell(props?.row?.original, "sell")}
                >
                  <FormattedMessage id="extends.profitLossEquity.tableContent.sell" />
                </ButtonSell>
              )}
            </div>
          );
        },
        Footer: "",
      },
    ],
    [itemsPortfolio]
  );
  const handleBuySell = (data: PortfolioItem, status: string) => {
    //mở đặt lệnh phía bên phải
    dispatch(addStockOrderWidgetName("widgets.stockOrder.name"));

    //Dispacth buy sell type
    dispatch(onBuySellType(status));

    //Dispacth current ticker
    dispatch(onChangeCurrentTicker(data.secCd));

    //Dispacth price
    // dispatch(handleUsingPriceSelected(true));
    // const price = getValueRound(
    //   (numeral(data?.marketPrice).value() || 0) / EQUITY_PRICE_UNIT
    // );
    // dispatch(onFilter({ price }));

    // Dispatch account
    const accountChange = listAcc?.find(
      (item) => item.subAccoNo === data.subAccountNo
    );
    dispatch(changeCurrentAccount(accountChange!));
  };

  useEffect(() => {
    if (!isLoadListPortfolio) return;
    dispatch(onLoadListPortfolio(false));
    handleRefreshData();
  }, [account, isLoadListPortfolio]);

  //Refresh data portfolio
  const handleRefreshData = () => {
    if (isAuthenticated) {
      dispatch(clearDataRefresh());
      const paramsRefresh: GetPortfolioParams = {
        custNo: "",
        subAccoNo:
          account && account !== ""
            ? account === "0"
              ? ""
              : account
            : currentAccount?.subAccoNo,
        alloDate: Number(tradeDate),
        secCd: secCd,
      };
      dispatch(requestPortfolio(paramsRefresh));
    }
  };

  const fetchMoreData = () => {
    // const paramsGetData = {
    //   page: params?.page || 1,
    //   pageSize: params?.pageSize || 25,
    // };
    // dispatch(requestPortfolio(paramsGetData));
  };

  const accValue = () => {
    if (isAuthenticated) {
      return accounts.find((item: any) => item.value === account);
    } else {
      return null;
    }
  };

  // Show hide modal xác nhận
  const onShowSubmitSellAll = (visible: boolean) => {
    setData(itemsPortfolio);
    setVisibleModalSubmit(visible);
  };

  // const changeAlloDateHandler = (date: Date) => {
  //   dispatch(updateAlloDatePortfolio(date ?? new Date()));
  // };

  const changeSecCdHandler = (secCd: string) => {
    dispatch(updateSecCdPortfolio(secCd ?? ""));
    dispatch(onLoadListPortfolio(true));
  };

  return (
    <PortfolioBox id="portfolioBox">
      <SearchContainer>
        <Control>
          <Label>
            <FormattedMessage id="widgets.portfolio.account" />
          </Label>
          <div className="flex-fill-select">
            <Select
              value={accValue()}
              options={accounts}
              onChange={(item) => {
                if (!item) return;
                dispatch(updateAccountPortfolio(item.value));
                dispatch(onLoadListPortfolio(true));
              }}
            ></Select>
          </div>
        </Control>
        {/* <Control>
          <Label>
            <FormattedMessage id="widgets.portfolio.alloDate" />
          </Label>
          <div className="flex-fill-select">
            <DatePicker
              selected={new Date()}
              onChange={(e: Date) => changeAlloDateHandler(e)}
              popperPlacement="bottom-start"
            />
          </div>
        </Control> */}
        <Control>
          <Label>
            <FormattedMessage id="widgets.portfolio.secCd" />
          </Label>
          <div className="flex-fill-select">
            <InputSymbol
              value={secCd}
              onChange={changeSecCdHandler}
              isOnlyShareCode
            />
          </div>
        </Control>
        <Button
          color="danger"
          className="btn-sell-all"
          onClick={() => onShowSubmitSellAll(true)}
        >
          <FormattedMessage id="widgets.portfolio.sellAll.title" />
        </Button>
      </SearchContainer>

      <PortfolioContainer id="portfolioContainer">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={itemsPortfolio}
          enableInfinity={checkEnableInfinity}
          scrollableTarget={"portfolioContainer"}
          isHasFooter={itemsPortfolio.length > 0 ? true : false}
        />
      </PortfolioContainer>
      {isAuthenticated && (
        <ButtonRefreshData onClick={handleRefreshData}>
          <IconRefresh />
        </ButtonRefreshData>
      )}
      <ModalSubmitSellAll
        visible={visibleModalSubmit}
        setVisible={setVisibleModalSubmit}
        handleCloseModal={() => onShowSubmitSellAll(false)}
        datalist={data}
      />
    </PortfolioBox>
  );
});

export default Portfolio;
