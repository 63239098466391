import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetCustomerProParams } from "core/http/apis/professional-investor/types";
import {
  IGetCustomerProResponse,
  ProfessionalInvestor,
} from "domain/models/ProfessionalInvestor";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  professionalInvestor?: ProfessionalInvestor;

  isShowConfirmModal: boolean;
}

const initialState: ReduxData = {
  isShowConfirmModal: false,
};

const professionalInvestorSlice = createSlice({
  name: "professionalInvestorSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    getCustomerProRequest: (
      state,
      action: PayloadAction<IGetCustomerProParams>
    ) => {},
    getCustomerProSuccess: (
      state,
      action: PayloadAction<IGetCustomerProResponse>
    ) => {
      state.professionalInvestor = action.payload.data ?? {};
    },

    getCustomerProFailure: (state) => {},

    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
      }>
    ) => {},
    updateModalStatusSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowConfirmModal = action.payload;
    },

    updateModalOtpRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
      }>
    ) => {},
  },
});

export const {
  getCustomerProRequest,
  getCustomerProSuccess,
  getCustomerProFailure,

  updateModalStatusRequest,
  updateModalStatusSuccess,
} = professionalInvestorSlice.actions;

export default professionalInvestorSlice.reducer;
