import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const placeOrderRetailBondSelector = (state: RootState) =>
  state.placeOrderRetailBonds;

export const getBuyInforSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.buyInfor
);
export const getBondCdListSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.bondCdList
);
export const getBondCdListFullSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.bondCdListFull
);

export const getListPeriodSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.listPeriod
);

export const getRBonOrderSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.rBonOrder
);

export const getcbotGetInterestSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.cbotGetInterest
);

export const getBuyingPowerSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.buyingPower
);

export const getCbotBondInfoSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.bondInfo
);

export const getListOrderBookSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.listOrderBook
);
export const getListOrderBookBondCdSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.listOrderBookBondCd
);
export const getListOrderBookBondCdInfoSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.listOrderBookBondCdInfo
);

export const loadingSearchSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.loadingSearch
);
export const calInvestmentValueSelector = createSelector(
  placeOrderRetailBondSelector,
  (state) => state.calInvestmentValue
);
