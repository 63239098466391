import { FC } from "react";
import Tabs from "./components/Tabs";
import { Container } from "./styles";

const TransferLocal: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <Tabs />
      {/* <Content /> */}
    </Container>
  );
};

export default TransferLocal;
