import { FC, memo } from "react";
import LibDatePicker from "react-datepicker";
import { popperPlacementType, Wrapper } from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { useIntl } from "react-intl";
import vi from "date-fns/locale/vi";
import en from "date-fns/locale/en-US";
import { useRef } from "react";

interface DatePickerProps {
  selected: Date | null | undefined;
  minDate?: Date;
  maxDate?: Date;
  popperPlacement?: popperPlacementType;
  onChange: (e: any) => void;
  className?: string;
  wrapperClassName?: string;
  customInput?: React.ReactNode;
  fullWidth?: boolean;
  name?: string;
}

const DatePicker: FC<DatePickerProps> = memo(
  ({
    selected,
    minDate,
    maxDate,
    popperPlacement = "top-end",
    onChange,
    className,
    wrapperClassName,
    customInput,
    fullWidth = false,
    name,

    ...rest
  }) => {
    const intl = useIntl();
    const refInput = useRef<LibDatePicker>(null);

    const keyDownHandler = (e: any) => {
      if (e.code === "Tab" || e.keyCode === 9) {
        refInput.current?.setOpen(false);
      }
    };

    return (
      <Wrapper fullWidth={fullWidth}>
        <LibDatePicker
          name={name}
          onKeyDown={keyDownHandler}
          popperPlacement={popperPlacement}
          selected={selected}
          minDate={minDate}
          maxDate={maxDate}
          className={className ? className : "custom-date-picker-input"}
          wrapperClassName={
            wrapperClassName ? wrapperClassName : "custom-date-picker-wrapper"
          }
          locale={intl.locale === "vi" ? vi : en}
          onChange={onChange}
          dateFormat={["dd/MM/yyyy", "ddMMyyyy"]}
          placeholderText="dd/mm/yyyy"
          ref={refInput}
          {...rest}
        />
      </Wrapper>
    );
  }
);
export default DatePicker;
