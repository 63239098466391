import {
  ForeignRoom,
  LastSale,
  ProjectOpen,
  TopPrice,
  TopPriceOdd,
  LastSaleOdd,
} from "domain/protoNew/auto_trading_pb";
import { WatchlistGroupType } from "helper/consts";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import {
  batchUpdateCategoriesInfo,
  updateCategoriesInfoLastSale,
  updateCategoriesInfoProjectOpen,
} from "modules/categories/redux/categoriesInfo";
import { store } from "redux/store";

const priceInfoLastSaleEventHandler = (emittedData: LastSale.AsObject) => {
  const type = store.getState().categories.root.tickersListSelected.type;
  if (type !== WatchlistGroupType.ODDLOT) {
    store.dispatch(updateCategoriesInfoLastSale(emittedData));
  }
};

const priceInfoLastSaleOddEventHandler = (
  emittedData: LastSaleOdd.AsObject
) => {
  const type = store.getState().categories.root.tickersListSelected.type;
  if (type === WatchlistGroupType.ODDLOT) {
    store.dispatch(updateCategoriesInfoLastSale(emittedData));
  }
};

const topPriceOddEventHandler = (emittedData: TopPriceOdd.AsObject) => {
  const type = store.getState().categories.root.tickersListSelected.type;
  const tickerList =
    store.getState().categories.categoriesInfo.tickerListDefault;
  if (type !== WatchlistGroupType.ODDLOT) {
    return;
  }
  if (!tickerList.includes(emittedData.seccd)) {
    return;
  }
  const toppricebidList = (top: number) =>
    emittedData.toppricebidList.filter((value) => value.top === top)[0];

  const toppriceofferList = (top: number) =>
    emittedData.toppriceofferList.filter((value) => value.top === top)[0];

  const batchTickerInfo: any = {
    seccd: emittedData.seccd,
    best1bidpricestr: toppricebidList(1)?.price,
    best1bidqty: toppricebidList(1)?.qty,
    best2bidprice: parseFloat(toppricebidList(2)?.price),
    best2bidqty: toppricebidList(2)?.qty,
    best3bidprice: parseFloat(toppricebidList(3)?.price),
    best3bidqty: toppricebidList(3)?.qty,
    best1offerpricestr: toppriceofferList(1)?.price,
    best1offerqty: toppriceofferList(1)?.qty,
    best2offerprice: parseFloat(toppriceofferList(2)?.price),
    best2offerqty: toppriceofferList(2)?.qty,
    best3offerprice: parseFloat(toppriceofferList(3)?.price),
    best3offerqty: toppriceofferList(3)?.qty,
  };

  Object.keys(batchTickerInfo).forEach(
    (key) =>
      (batchTickerInfo[key] === undefined ||
        Number.isNaN(batchTickerInfo[key])) &&
      delete batchTickerInfo[key]
  );
  store.dispatch(batchUpdateCategoriesInfo(batchTickerInfo));
};

const topPriceEventHandler = (emittedData: TopPrice.AsObject) => {
  const type = store.getState().categories.root.tickersListSelected.type;
  const tickerList =
    store.getState().categories.categoriesInfo.tickerListDefault;
  if (type === WatchlistGroupType.ODDLOT) {
    return;
  }
  if (!tickerList.includes(emittedData.seccd)) {
    return;
  }
  const toppricebidList = (top: number) =>
    emittedData.toppricebidList.filter((value) => value.top === top)[0];

  const toppriceofferList = (top: number) =>
    emittedData.toppriceofferList.filter((value) => value.top === top)[0];

  const batchTickerInfo: any = {
    seccd: emittedData.seccd,
    best1bidpricestr: toppricebidList(1)?.price,
    best1bidqty: toppricebidList(1)?.qty,
    best2bidprice: parseFloat(toppricebidList(2)?.price),
    best2bidqty: toppricebidList(2)?.qty,
    best3bidprice: parseFloat(toppricebidList(3)?.price),
    best3bidqty: toppricebidList(3)?.qty,
    best1offerpricestr: toppriceofferList(1)?.price,
    best1offerqty: toppriceofferList(1)?.qty,
    best2offerprice: parseFloat(toppriceofferList(2)?.price),
    best2offerqty: toppriceofferList(2)?.qty,
    best3offerprice: parseFloat(toppriceofferList(3)?.price),
    best3offerqty: toppriceofferList(3)?.qty,
  };

  Object.keys(batchTickerInfo).forEach(
    (key) =>
      (batchTickerInfo[key] === undefined ||
        Number.isNaN(batchTickerInfo[key])) &&
      delete batchTickerInfo[key]
  );
  store.dispatch(batchUpdateCategoriesInfo(batchTickerInfo));
};

const priceInfoForeignRoomHandler = (emittedData: ForeignRoom.AsObject) => {
  const type = store.getState().categories.root.tickersListSelected.type;
  const tickerList =
    store.getState().categories.categoriesInfo.tickerListDefault;
  if (type === WatchlistGroupType.ODDLOT) {
    return;
  }
  if (!tickerList.includes(emittedData.seccd)) {
    return;
  }

  const batchTickerInfo = {
    seccd: emittedData.seccd,
    sellforeignqty: emittedData.sellforeignqty,
    buyforeignqty: emittedData.buyforeignqty,
    currentroom: emittedData.currentroom,
  };
  store.dispatch(batchUpdateCategoriesInfo(batchTickerInfo));
};

const projectOpenHandler = (emittedData: ProjectOpen.AsObject) => {
  const type = store.getState().categories.root.tickersListSelected.type;
  const tickerList =
    store.getState().categories.categoriesInfo.tickerListDefault;
  if (type === WatchlistGroupType.ODDLOT) {
    return;
  }
  if (!tickerList.includes(emittedData.seccd)) {
    return;
  }
  store.dispatch(updateCategoriesInfoProjectOpen(emittedData));
};

export function startListen() {
  emitter.on(
    EMMIT_ACTION_TYPE.EMMIT_LastSaleOdd,
    priceInfoLastSaleOddEventHandler
  );
  emitter.on(EMMIT_ACTION_TYPE.EMMIT_TopPriceOdd, topPriceOddEventHandler);
  emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, priceInfoLastSaleEventHandler);
  emitter.on(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, topPriceEventHandler);
  emitter.on(EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM, priceInfoForeignRoomHandler);
  emitter.on(EMMIT_ACTION_TYPE.EMMIT_ProjectOpen, projectOpenHandler);
}

export function stopListen() {
  emitter.off(
    EMMIT_ACTION_TYPE.EMMIT_LastSaleOdd,
    priceInfoLastSaleOddEventHandler
  );
  emitter.off(EMMIT_ACTION_TYPE.EMMIT_TopPriceOdd, topPriceOddEventHandler);
  emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, priceInfoLastSaleEventHandler);
  emitter.off(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, topPriceEventHandler);
  emitter.off(EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM, priceInfoForeignRoomHandler);
  emitter.off(EMMIT_ACTION_TYPE.EMMIT_ProjectOpen, projectOpenHandler);
}
