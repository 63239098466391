import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const placeOrderTransferBondsSelector = (state: RootState) =>
  state.placeOrderTransferBonds;

export const getAccountSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.account
);
export const getSubAccoNoSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.subAccoNo
);

export const loadingSearchSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.loadingSearch
);
export const loadingCheckTimeSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.loadingCheckTime
);

export const bondsAvailSellSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.bondsAvailSell
);

export const calculateBondFeeSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.calculateBondFee
);

export const statusCodeSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.statusCode
);
export const listTransferBondSelector = createSelector(
  placeOrderTransferBondsSelector,
  (state) => state.listTransferBond
);
