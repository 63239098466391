import { memo } from "react";
import { withRouter } from "react-router";
import Container from "components/commons/Container";
import transferShareHistory from "./components/TransferShareHistory";
import transferShareForm from "./components/TransferShareForm";
import { useIntl } from "react-intl";

function TransferShare() {
  const intl = useIntl();
  const arrayForm = [transferShareHistory, transferShareForm];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "transferShare.tab.transferShareHistory",
      })}
      headerForm={intl.formatMessage({
        id: "transferShare.tab.transferShareForm",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
}

export default withRouter(memo(TransferShare));
