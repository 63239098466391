import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.border1};

  filter: drop-shadow(0px 0px 12px rgba(13, 17, 21, 0.4));
  border-radius: 8px;
`;

export const TitleContainer = styled.div`
  height: 40px;
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  text-align: center;
`;
export const Title = styled.div`
  width: 84%;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  padding: 10px 16px;
  color: ${({ theme }) => theme.textcolorWhite};
`;
export const Icon = styled.div`
  padding: 8px;
  width: 8%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const IconNone = styled.div`
  padding: 8px;
  width: 8%;
`;

export const BorderBox = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.background2};
  word-wrap: break-word;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;
export const Body = styled.div``;
export const BodyConfirm = styled.div`
  text-align: center;
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  .flex-1 {
    flex: 1;
    margin-right: 10px;
  }
  .flex-2 {
    flex: 1;
  }
`;
export const WrapItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px;
  color: ${({ theme }) => theme.grey20};
`;

export const DetailRowCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextCheckBox = styled.div`
  margin: 10px 5px 10px 10px;
`;
export const BorderRight = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const ColorGreen = styled.div`
  color: ${({ theme }) => theme.green50};
`;
export const ColorRed = styled.div`
  color: ${({ theme }) => theme.red50};
`;
export const ColorBlue = styled.a<{
  href: string;
}>`
  color: ${({ theme }) => theme.brandPrimary};
`;
