import { FC, useEffect, useRef } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { RootState } from "redux/store";
import { TableWrapper } from "./styles";
import {
  changeResultItemRealtime,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { COLUMNS } from "modules/screeners/constants";
import { LastSale } from "domain/protoNew/auto_trading_pb";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";

const screenersSelector = (state: RootState) => state.screeners;
const tickerListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.tickerList
);

const BodyCard: FC = () => {
  const dispatch = useDispatch();
  const tickerList = useSelector(tickerListSelector);
  const tickerListRef = useRef(tickerList || []);

  if (!isEqual(tickerList, tickerListRef.current)) {
    tickerListRef.current = tickerList;
  }

  useEffect(() => {
    if (tickerListRef.current.length > 0) {
      const listTicker = tickerListRef.current.toString().replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
    return () => {
      if (tickerListRef.current.length > 0) {
        const listTicker = tickerListRef.current.toString().replace(/,/g, ";");
        unSubscribeTickers(listTicker);
      }
    };
  }, [tickerListRef.current]);

  useEffect(() => {
    const eventListener = (dataFormatter: LastSale.AsObject) => {
      if (!!dataFormatter) {
        const tickerHasNewDataIndex = tickerListRef.current.findIndex(
          (ticker) => ticker === dataFormatter.seccd
        );

        if (tickerHasNewDataIndex !== -1) {
          dispatch(
            changeResultItemRealtime({
              ind: tickerHasNewDataIndex,
              item: dataFormatter,
            })
          );
        }
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListener);

    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListener);
    };
  }, [tickerListRef.current]);

  return (
    <TableWrapper id="table-extend-history">
      <TableHeader columns={COLUMNS} />
      <TableBody
        rowHeight={52}
        columns={COLUMNS}
        tickerList={tickerListRef.current}
      />
    </TableWrapper>
  );
};

export default BodyCard;
