import { colorHeaderCardSelector } from "modules/system/selectors";
import { useSelector } from "react-redux";
import { GetArrowIndex } from "helper/GetArrow";

interface Props {
  ticker: string;
}

function ArrowIndex({ ticker }: Props) {
  const color = useSelector(colorHeaderCardSelector(ticker));
  return <GetArrowIndex color={color} />;
}
export default ArrowIndex;
