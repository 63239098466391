import queryString from "query-string";
import {
  ISearchTransferLocalParams,
  ISearchTransferLocalHisParams,
  IInquiryAccountTraParams,
  IFindTransferContractLocalParams,
  IFindParameterBankDetailParams,
  ISubmitTransferParams,
  IGetSubAccToLocalListParams,
  ICheckTimeTransferParams,
  IGetCustomerRestrictionParams,
  IGetCurrencyStrVNParams,
} from "./types";

export const searchTransferLocalApi = (params: ISearchTransferLocalParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/findTransferLocal${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const searchTransferLocalHisApi = (
  params: ISearchTransferLocalHisParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findTransferLocalHis${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const inquiryAccountTraApi = (params: IInquiryAccountTraParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryAccountTra${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findTransferContractLocalApi = (
  params: IFindTransferContractLocalParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findTransferContractLocal${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const findParameterBankDetailApi = (
  params: IFindParameterBankDetailParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findParameterBankDetail${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const submitTransferApi = (params: ISubmitTransferParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/submitTransfer${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getSubAccToLocalListApi = (
  params: IGetSubAccToLocalListParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getSubAccToLocalList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const checkTimeTransferApi = (params: ICheckTimeTransferParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/checkTimeTransfer${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getCurrencyStrVNApi = (params: IGetCurrencyStrVNParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCurrencyStrVN${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getCustomerRestrictionApi = (
  params: IGetCustomerRestrictionParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomerRestriction${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
