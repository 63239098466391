import { ReactComponent as IconRadioCheck } from "assets/image/svg/ic-radio-checked.svg";
import { ReactComponent as IconRadioNoCheck } from "assets/image/svg/ic-radio-no-check.svg";
import { ReactComponent as IconCheckboxSelected } from "assets/image/svg/ic_checkbox_selected.svg";
import { ReactComponent as IconCheckboxUnSelected } from "assets/image/svg/ic_checkbox_unselected.svg";
import {
  Body,
  Container,
  Title,
  Footer,
  Label,
  ItemCheck,
  Radio,
  Value,
  ContentItem,
  Link,
} from "./styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { EOTPType } from "helper/consts";
import { accountInfoSelector } from "modules/auth/selectors";
import { useSelector } from "react-redux";
import { removeAccents } from "helper/removeAccents";
import storage from "helper/storage";
import { toast } from "components/commons/Toast";

interface Props {
  visibleModalConfirm: boolean;
  setVisibleModalConfirm: (visible: boolean) => void;
  setConfirm: (value: number) => void;
  otpType: number;
}

function ModalChooseTypeOtp({
  visibleModalConfirm,
  setVisibleModalConfirm,
  setConfirm,
  otpType,
}: Props) {
  const intl = useIntl();

  const [type, setType] = useState(EOTPType.SMART_OTP);
  const accountInfo = useSelector(accountInfoSelector);
  const [checkedConfirm, setCheckedConfirm] = useState(false);
  const [enableConfirm, setEnableConfirm] = useState(true);

  const bankAccountName = accountInfo?.currentUser?.displayName || "---";
  const bankAccountNameConvert = removeAccents(bankAccountName);
  const bankAccountNameUppercase = bankAccountNameConvert.toUpperCase();

  useEffect(() => {
    if (otpType && otpType !== EOTPType.NONE) {
      if (otpType === EOTPType.SMART_OTP) {
        setType(EOTPType.SMS_OTP);
      }
      if (otpType === EOTPType.SMS_OTP) {
        setType(EOTPType.SMART_OTP);
      }
    }
  }, [otpType]);

  useEffect(() => {
    if (!checkedConfirm) {
      setEnableConfirm(false);
    } else {
      setEnableConfirm(true);
    }
  }, [checkedConfirm]);

  const closeModalConfirm = () => {
    setVisibleModalConfirm(false);
  };

  const handleConfirm = () => {
    if (type === otpType) {
      toast("auth.tradingAccount.errorNotChange", "error");
      return;
    }
    setConfirm(type);
    setCheckedConfirm(false);
    closeModalConfirm();
  };

  const handleChangeCheckConfirm = () => {
    setCheckedConfirm(!checkedConfirm);
  };

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-500"
      >
        <Container>
          <Title>
            <FormattedMessage id="auth.tradingAccount.titleModalChooseType" />
          </Title>
          <Body>
            <ContentItem>
              <div className="flex-1"></div>
              <ItemCheck>
                <Radio onClick={() => setType(EOTPType.SMART_OTP)}>
                  {type === EOTPType.SMART_OTP ? (
                    <IconRadioCheck />
                  ) : (
                    <IconRadioNoCheck />
                  )}
                </Radio>
                <Label>
                  <FormattedMessage id="auth.tradingAccount.smartOtp" />
                </Label>
              </ItemCheck>
              <ItemCheck>
                <Radio onClick={() => setType(EOTPType.SMS_OTP)}>
                  {type === EOTPType.SMS_OTP ? (
                    <IconRadioCheck />
                  ) : (
                    <IconRadioNoCheck />
                  )}
                </Radio>
                <Label>
                  <FormattedMessage id="auth.tradingAccount.smsOtp" />
                </Label>
              </ItemCheck>
              <div className="flex-1"></div>
            </ContentItem>

            <ContentItem>
              <Label>
                <FormattedMessage id="auth.tradingAccount.masterAccountName" />
              </Label>
              <Value>{bankAccountNameUppercase}</Value>
            </ContentItem>

            <ContentItem>
              <Label>
                <FormattedMessage id="auth.tradingAccount.accountCode" />
              </Label>
              <Value>{storage.getMasterAccount()}</Value>
            </ContentItem>

            <ContentItem>
              <div onClick={handleChangeCheckConfirm}>
                {checkedConfirm ? (
                  <IconCheckboxSelected />
                ) : (
                  <IconCheckboxUnSelected />
                )}
              </div>
              <div className="textConfirm">
                <FormattedMessage
                  id="auth.tradingAccount.term"
                  values={{
                    link: (
                      <Link
                        target="_blank"
                        href={intl.formatMessage({
                          id: "auth.login.RegisterOTPMethodLink",
                        })}
                      >
                        <FormattedMessage id="auth.tradingAccount.link" />
                      </Link>
                    ),
                  }}
                />
              </div>
            </ContentItem>
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={handleConfirm}
              className="flex-1"
              fill
              color="accept"
              disabled={!enableConfirm}
            >
              <FormattedMessage id="common.accept" />
            </Button>
          </Footer>
        </Container>
      </Modal>
    </>
  );
}

export default ModalChooseTypeOtp;
