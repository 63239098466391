import { RESTFUL_TRADINGVIEW_SESSION_URL } from "AppConfig";
import HttpClient from "../../index";

export default class HttpTradingView extends HttpClient {
  private static classInstance?: HttpTradingView;
  private constructor() {
    super(RESTFUL_TRADINGVIEW_SESSION_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpTradingView();
    }
    return this.classInstance;
  }
}
