import {
  INDEX_STATEMap,
  MarketIndexInfo,
} from "domain/protoNew/auto_trading_pb";
import { valueHeaderCardSelector } from "modules/system/selectors";
import { useSelector } from "react-redux";
import { getMarketSessionName } from "helper/utils";
import { useIntl } from "react-intl";

interface Props {
  ticker: string;
  fieldValue: keyof MarketIndexInfo.AsObject;
}

function SessionName({ ticker, fieldValue }: Props) {
  const intl = useIntl();
  const data = useSelector(
    valueHeaderCardSelector(ticker, fieldValue)
  ) as INDEX_STATEMap[keyof INDEX_STATEMap];

  return <>{getMarketSessionName(data, intl)}</>;
}
export default SessionName;
