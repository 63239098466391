import { floorpriceSelector } from "modules/categories/redux/selectors";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

function FloorpriceCell({ ticker }: Props) {
  const floorprice = useSelector(floorpriceSelector(ticker)) || 0;

  return (
    <PriceCell
      color="textColorBlue"
      className="bg-overlay-col opening-price floor-price"
      price={floorprice}
      isBlink={false}
      ticker={ticker}
    />
  );
}

export default FloorpriceCell;
