import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateModalStatusRequest } from "modules/bid-ask-bond/redux";
import { isShowConfirmModalSelector } from "modules/bid-ask-bond/selectors";

import numeral from "numeral";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { BondOrder } from "domain/models/BidAskBond";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  DetailRowButton,
} from "./styles";
import { Button } from "components/commons";
import { updateModalOtpRequest } from "modules/advance/redux";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
interface Props {
  itemDetailRow: BondOrder;
}

function DetailModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowConfirmModalSelector);
  const themeType = useSelector(themeTypeSelector);
  const closeModalHandler = () => {
    dispatch(updateModalStatusRequest({ isVisible: false }));
  };

  function changeColor(value: string, value2: number) {
    if (value2 === 1)
      return (
        <TextCell color={themes[themeType].green50}>
          <FormattedMessage id="bidAskBond.table.buy" />
        </TextCell>
      );
    else if (value2 === 2)
      return (
        <TextCell color={themes[themeType].red50}>
          <FormattedMessage id="bidAskBond.table.sell" />
        </TextCell>
      );

    return "";
  }
  const clickAcceptHandler = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
    closeModalHandler();
  };

  const orderPrice =
    (itemDetailRow &&
      itemDetailRow.price &&
      itemDetailRow.qty &&
      itemDetailRow?.price * itemDetailRow?.qty) ??
    0;
  const getDisPlay = (status: number) => {
    switch (status) {
      case 0:
        return "unknown";
      case 1:
        return "sell";
      case 2:
        return "buy";
      case 3:
        return "sellMortage";
    }
  };
  const getDisplay2 = (status: number) => {
    switch (status) {
      case 1:
        return "sell";
      case 2:
        return "buy";
    }
  };
  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="bidAskBond.confirmModal.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-3">
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.tradeType" />
            </LabelCell>
            <TextCell>
              {changeColor(
                intl.formatMessage({
                  id:
                    "bidAskBond.confirmModal.tradeList." +
                    getDisPlay(itemDetailRow?.tradeType ?? 0),
                }),
                itemDetailRow?.tradeType
              )}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.bondCd" />
            </LabelCell>
            <TextCell>{itemDetailRow?.bondCd}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.qty" />
            </LabelCell>
            <TextCell>{itemDetailRow?.qty}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.price" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.price ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.orderPrice" />
            </LabelCell>
            <TextCell>
              <TextCell>
                <TextCell>{numeral(orderPrice).format("0,0")}</TextCell>
              </TextCell>
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.fee" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.feeAmt ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.tax" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.taxAmt ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.feePayer" />
            </LabelCell>
            <TextCell>
              <TextCell>
                {intl.formatMessage({
                  id:
                    "bidAskBond.confirmModal.payer." +
                    getDisplay2(itemDetailRow?.feePayer ?? 0),
                })}
              </TextCell>
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="bidAskBond.confirmModal.taxPayer" />
            </LabelCell>
            <TextCell>
              <TextCell>
                {intl.formatMessage({
                  id:
                    "bidAskBond.confirmModal.payer." +
                    getDisplay2(itemDetailRow?.taxPayer ?? 0),
                })}
              </TextCell>
            </TextCell>
          </BodyModalRow>
          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={closeModalHandler}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "bidAskBond.confirmModal.cancel",
              })}
            </Button>
            <Button
              style={{
                background: `${themes[themeType].buttonGradientColor}`,
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "bidAskBond.confirmModal.confirm",
              })}
            </Button>
          </DetailRowButton>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailModal;
