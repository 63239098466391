import EventEmitter from "eventemitter3";

export enum EMMIT_ACTION_TYPE {
  EMMIT_TOPPRICE = "EMMIT_TOPPRICE",
  EMMIT_FOREIGNROOM = "EMMIT_FOREIGNROOM",
  EMMIT_LASTSALE = "EMMIT_LASTSALE",
  EMMIT_IndexUpdate = "EMMIT_IndexUpdate",
  EMMIT_ProjectOpen = "EMMIT_ProjectOpen",
  EMMIT_SystemState = "EMMIT_SystemState",
  EMMIT_Order = "EMMIT_Order",
  EMMIT_Order_Der = "EMMIT_Order_Der",
  EMMIT_TopPriceOdd = "EMMIT_TopPriceOdd",
  EMMIT_LastSaleOdd = "EMMIT_LastSaleOdd",
  EMMIT_DATA_QR_CODE = "EMMIT_DATA_QR_CODE",
}

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event: EMMIT_ACTION_TYPE, fn: any) => eventEmitter.on(event, fn),
  once: (event: EMMIT_ACTION_TYPE, fn: any) => eventEmitter.once(event, fn),
  off: (event: EMMIT_ACTION_TYPE, fn?: any) => eventEmitter.off(event, fn),
  emit: (event: EMMIT_ACTION_TYPE, payload: any) =>
    eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
