import { getCustomerProApi } from "./urls";

import { IGetCustomerProParams } from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";
import { IGetCustomerProResponse } from "domain/models/ProfessionalInvestor";

export const getCustomerPro = async (
  params: IGetCustomerProParams
): Promise<IGetCustomerProResponse> => {
  const res = await VgaiaHttp.get<IGetCustomerProResponse>(
    getCustomerProApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
