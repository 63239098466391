import { IntlShape, FormattedMessage } from "react-intl";
import { HeaderCell, TableCellContent } from "./pages/styles";
import moment from "moment";
import numeral from "numeral";

export const optionStatus = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({ id: "transferHistory.search.status.valueAll" }),
  },
  {
    value: "0",
    label: intl.formatMessage({ id: "transferHistory.search.status.value0" }),
  },
  {
    value: "1",
    label: intl.formatMessage({ id: "transferHistory.search.status.value1" }),
  },
  {
    value: "2",
    label: intl.formatMessage({ id: "transferHistory.search.status.value2" }),
  },
  {
    value: "9",
    label: intl.formatMessage({ id: "transferHistory.search.status.value9" }),
  },
];

const flexEnd = "flex-end";
const flexStart = "flex-start";
const center = "center";

const checkColorStatus = (status: any) => {
  if (status === 0) return "#EDE51D";
  if (status === 2 || status === 9) return "#F04B41";
  if (status === 1) return "#1DBB75";
  return "#EDE51D";
};

export const dataColumns = [
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferHistory.table.allDate" />
      </HeaderCell>
    ),
    accessor: "alloDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferHistory.table.status" />
      </HeaderCell>
    ),
    accessor: "status",
    Cell: (props: any) => {
      return (
        <TableCellContent
          align={flexStart}
          bgcolor={checkColorStatus(props.value)}
        >
          <FormattedMessage
            id={`transferHistory.search.status.value${props.value}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferHistory.table.custNoFrom" />
      </HeaderCell>
    ),
    accessor: "custNoFrom",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>{props.value}</TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferHistory.table.custNameFrom" />
      </HeaderCell>
    ),
    accessor: "custNameFrom",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferHistory.table.custNoTo" />
      </HeaderCell>
    ),
    accessor: "custNoTo",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferHistory.table.custNameTo" />
      </HeaderCell>
    ),
    accessor: "custNameTo",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferHistory.table.bondCd" />
      </HeaderCell>
    ),
    accessor: "bondCd",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.quantity" />
      </HeaderCell>
    ),
    accessor: "quantity",
    Cell: (props: any) => {
      return <TableCellContent align={flexEnd}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.parAmt" />
      </HeaderCell>
    ),
    accessor: "parAmt",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.transferAmt" />
      </HeaderCell>
    ),
    accessor: "transferAmt",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.parAmt1" />
      </HeaderCell>
    ),
    accessor: "parAmt1",
    Cell: (props: any) => {
      const currentRow = props?.row?.original;
      return (
        <TableCellContent align={flexEnd}>
          {numeral(currentRow?.parAmt).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.feeAmt" />
      </HeaderCell>
    ),
    accessor: "feeAmt",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.taxAmt" />
      </HeaderCell>
    ),
    accessor: "taxAmt",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.feePayerDisp" />
      </HeaderCell>
    ),
    accessor: "feePayerDisp",
    Cell: (props: any) => {
      return <TableCellContent align={flexEnd}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.taxPayerDisp" />
      </HeaderCell>
    ),
    accessor: "taxPayerDisp",
    Cell: (props: any) => {
      return <TableCellContent align={flexEnd}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferHistory.table.srcChannelDisp" />
      </HeaderCell>
    ),
    accessor: "srcChannelDisp",
    Cell: (props: any) => {
      return <TableCellContent align={flexEnd}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferHistory.table.regUserId" />
      </HeaderCell>
    ),
    accessor: "regUserId",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
];
