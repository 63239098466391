import { createSelector } from "@reduxjs/toolkit";
import { getColorPrice } from "helper/utils";
import {
  categoriesInfoSelector,
  floorpriceSelector,
  ceilingpriceSelector,
  basicpriceSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

const avgpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.avgprice
  );
};

function AvgpriceCell({ ticker }: Props) {
  const avgprice = useSelector(avgpriceSelector(ticker)) || 0;
  const floorprice = useSelector(floorpriceSelector(ticker)) || 0;
  const ceilingprice = useSelector(ceilingpriceSelector(ticker)) || 0;
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;

  return (
    <PriceCell
      ticker={ticker}
      className="bg-overlay-col average"
      price={avgprice}
      color={getColorPrice(avgprice, floorprice, ceilingprice, basicprice)}
      isAvgPrice
    />
  );
}

export default AvgpriceCell;
