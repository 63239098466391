import { useEffect, useState } from "react";
import { Button } from "components/commons";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import {
  updateModalConfirmRequest,
  updateMrgContractPaymentRequest,
  findMarginContractDebitWatchRequest,
  updateLoanTrackingFilter,
  updateItemEdit,
  updateModalPaymentRequest,
  getMrgDebitInfo2Request,
} from "modules/contract-debit/redux";
import {
  isShowConfirmModalSelector,
  loanTrackingStatusUpdateMrgContractPaymentSelector,
  loanTrackingInfoMrgDebitSelector,
  loanTrackingDetailRowSelector,
  loanTrackingItemEditSelector,
  loanTrackingFilterSelector,
} from "modules/contract-debit/selectors";
import {
  IUpdateMrgContractPaymentParams,
  IFindMarginContractDebitWatchParams,
} from "core/http/apis/contract-debit/types";
import numeral from "numeral";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  DetailInputWrapper,
  DetailRowButton,
} from "./styles";

import { convertNumberToDateFormat } from "helper/utils";
import { toast } from "components/commons/Toast";
import { updateModalOtpRequest } from "modules/advance/redux";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { EBusinessCd } from "helper/consts";

import { OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";

function ConfirmModal() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const isVisible = useSelector(isShowConfirmModalSelector);
  const itemEdit = useSelector(loanTrackingItemEditSelector);
  const isVisibleOtp = useSelector(isShowOtpModalSelector);
  const result = useSelector(loanTrackingInfoMrgDebitSelector);
  const loanTrackingDetail = useSelector(loanTrackingDetailRowSelector);
  const statusUpdate = useSelector(
    loanTrackingStatusUpdateMrgContractPaymentSelector
  );

  const { fromDate, toDate, subAccoNo } = useSelector(
    loanTrackingFilterSelector
  );

  const closeModalHandler = () => {
    dispatch(updateItemEdit({ amount: "" }));
    dispatch(
      getMrgDebitInfo2Request({
        allodate:
          loanTrackingDetail && loanTrackingDetail.alloDate
            ? loanTrackingDetail.alloDate
            : 0,
        refNo:
          loanTrackingDetail && loanTrackingDetail.refNo
            ? loanTrackingDetail.refNo
            : 0,
        amount: 0,
        isAuto: false,
      })
    );
    dispatch(updateModalConfirmRequest({ isVisible: false }));
    dispatch(
      updateModalPaymentRequest({
        isVisible: true,
        itemDetail: loanTrackingDetail,
      })
    );
  };
  const clickAcceptHandler = () => {
    setVisibleModalConfirm(true);
  };
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    const submitData: IUpdateMrgContractPaymentParams = {
      srcDate:
        loanTrackingDetail && loanTrackingDetail.alloDate
          ? loanTrackingDetail.alloDate
          : 0,
      srcNo:
        loanTrackingDetail && loanTrackingDetail.refNo
          ? loanTrackingDetail?.refNo
          : 0,
      amount: itemEdit && itemEdit.amount ? +itemEdit?.amount : 0,
      interest: result?.[0] ? result[0] : "",
      debitManager: result?.[3] ? result[3] : "",
      subAccoNo:
        loanTrackingDetail && loanTrackingDetail.subAccoNo
          ? loanTrackingDetail.subAccoNo
          : "",
      remarks: itemEdit && itemEdit.content ? itemEdit.content : "",
      lastUpdateTime:
        loanTrackingDetail && loanTrackingDetail.lastUpdateTime
          ? loanTrackingDetail.lastUpdateTime
          : "",
      otpCode: response?.otp ?? "",
      otpType: response?.otpTypeSubmit!,
    };
    dispatch(updateMrgContractPaymentRequest(submitData));
  };

  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const setConfirm = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));

    setVisibleModalConfirm(false);
  };

  useEffect(() => {
    if (statusUpdate) {
      const submitData2: IFindMarginContractDebitWatchParams = {
        branchCd: null,
        accoNo: subAccoNo ?? "",
        debitType: 0,
        status: 1,
        brokerCd: null,
        alloDate: 0,
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
      };
      dispatch(findMarginContractDebitWatchRequest(submitData2));
      dispatch(
        updateLoanTrackingFilter({
          key: "fromDate",
          value: fromDate,
        })
      );
      dispatch(
        updateLoanTrackingFilter({
          key: "toDate",
          value: toDate,
        })
      );
      dispatch(
        updateLoanTrackingFilter({
          key: "subAccoNo",
          value: subAccoNo,
        })
      );
      dispatch(
        updateLoanTrackingFilter({
          key: "status",
          value: 1,
        })
      );

      toast("contractDebit.message.debtPaymentSucesss", "success");
      dispatch(updateItemEdit({ amount: "" }));
      dispatch(updateModalConfirmRequest({ isVisible: false }));
    }
  }, [statusUpdate]);

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            {intl.formatMessage({
              id: "contractDebit.loanTrackingTab.confirmModal.title",
            })}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-3">
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.subAccount" />
            </LabelCell>
            <TextCell>{loanTrackingDetail?.subAccoNo}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.contrDateFrom" />
            </LabelCell>
            <TextCell>
              {convertNumberToDateFormat({
                date: loanTrackingDetail?.contrDateFrom,
              })}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.contrDateTo" />
            </LabelCell>
            <TextCell>
              {convertNumberToDateFormat({
                date: loanTrackingDetail?.contrDateTo,
              })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.currentDebit" />
            </LabelCell>
            <TextCell>
              {" "}
              {numeral(loanTrackingDetail?.currentDebit).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.amount" />
            </LabelCell>
            {/* <TextCell>{result?.[2]}</TextCell> */}
            <DetailInputWrapper>
              <TextCell>
                {itemEdit && itemEdit?.amount
                  ? numeral(itemEdit?.amount ?? 0).format("0,0")
                  : "0"}
              </TextCell>
            </DetailInputWrapper>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.interest" />
            </LabelCell>
            <TextCell>
              {" "}
              {result && result?.[0]
                ? numeral(result?.[0] ?? 0).format("0,0")
                : "0"}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.fee" />
            </LabelCell>
            <TextCell>
              {result && result?.[3]
                ? numeral(result?.[3] ?? 0).format("0,0")
                : "0"}
            </TextCell>
          </BodyModalRow>

          {/* <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.availableBalance" />
            </LabelCell>
            <TextCell>{result?.[2]}</TextCell>
          </BodyModalRow> */}

          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={closeModalHandler}
            >
              {intl.formatMessage({
                id: "contractDebit.loanTrackingTab.confirmModal.cancel",
              })}
            </Button>
            <Button
              color="gradientaAccept"
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "contractDebit.loanTrackingTab.confirmModal.payment",
              })}
            </Button>
          </DetailRowButton>
        </BodyModal>
      </Wrapper>

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "contractDebit.loanTrackingTab.confirmModal.contentConfirm",
        })}
      />

      {isVisibleOtp && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.MARGIN}
        />
      )}
    </Modal>
  );
}

export default ConfirmModal;
