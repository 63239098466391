import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { useMemo, useState } from "react";
import { ButtonDelete, Container, Icon } from "./styles";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { ModalConfirm } from "components/commons";
import { toast } from "components/commons/Toast";
import { deleteDevice } from "core/http/apis/manager-device";
import storage from "helper/storage";
import { convertNumberToDateFormat } from "helper/utils";
import { getListDeviceRequest } from "modules/auth/redux/managerDevice";
import {
  accountInfoSelector,
  getListDeviceSelector,
} from "modules/auth/selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import OTPByBusinessCd from "../ModalCreateSms";

function Table() {
  const listDevice = useSelector(getListDeviceSelector);
  const accountInfo = useSelector(accountInfoSelector);

  const newListDevice = listDevice?.map((item: any) => {
    return { ...item, deviceInfo: JSON.parse(item?.deviceInfo) };
  });

  const [visible, setVisible] = useState(false);
  const [dataDelete, setDataDelete] = useState<any>();
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const masterAccount = storage.getMasterAccount();

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDelete = (item: any) => {
    setVisible(true);
    setDataDelete(item);
  };
  const handleConfirm = async () => {
    setVisibleModalOTP(true);
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };
  const doSubmitAdvance = async (response: any) => {
    try {
      const res = await deleteDevice({
        masterAccount: dataDelete?.userId || "",
        deviceId: dataDelete?.deviceId || "",
        smsOtp: response?.otp,
        cancelDeviceIdList: dataDelete?.deviceId
          ? JSON.stringify([dataDelete?.deviceId])
          : [],
        mobileNo: accountInfo?.currentCustomer?.mobileNo || "",
        emailAddr: accountInfo?.currentCustomer?.emailAdrs || "",
      });
      if (res?.statusCode === 0) {
        toast(
          `${intl.formatMessage({
            id: "auth.managerDevice.deleteDone",
          })}`,
          "success"
        );
        dispatch(
          getListDeviceRequest({
            masterAccount: masterAccount,
          })
        );
      }
      if (res?.statusCode !== 0) {
        toast(
          `${intl.formatMessage({
            id: "auth.managerDevice.deleteFailure",
          })}`,
          "error"
        );
        return;
      }
      handleCloseOTPModal();
    } catch (error: any) {
      toast(
        `${intl.formatMessage({
          id: "auth.managerDevice.deleteFailure",
        })}`,
        "error"
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="auth.managerDevice.device" />
          </TableHeaderCellContent>
        ),
        minWidth: 400,
        accessor: "deviceInfo.name",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="auth.managerDevice.activeDate" />
          </TableHeaderCellContent>
        ),
        minWidth: 110,
        accessor: "activeDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="auth.managerDevice.deactivate" />
          </TableHeaderCellContent>
        ),
        minWidth: 120,
        accessor: "cancel",
        Cell: (props: any) => {
          const rowItem = props?.row?.original;

          return (
            <TableCellContent align={"center"}>
              <Icon>
                <ButtonDelete onClick={() => handleDelete(rowItem)}>
                  <IconTrash width={16} height={16} />
                </ButtonDelete>
              </Icon>
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};
  return (
    <>
      <Container id="managerDeviceTable">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={listDevice ? newListDevice : []}
          enableInfinity={true}
          scrollableTarget={"managerDeviceTable"}
          isHasFooter={false}
        />
      </Container>
      <ModalConfirm
        visibleModalConfirm={visible}
        setVisibleModalConfirm={setVisible}
        title={intl.formatMessage({
          id: "auth.managerDevice.titleDelete",
        })}
        content={intl.formatMessage({
          id: "auth.managerDevice.contentDelete",
        })}
        setConfirm={handleConfirm}
        handleCloseModal={() => setVisible(!visible)}
      ></ModalConfirm>

      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={"103"}
          dataDelete={dataDelete}
        />
      )}
    </>
  );
}

export default Table;
