import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TopPricePeriod } from "components/widgets/generals/TopPrice/types";
import { TOP_PRICE_LOADING_LIMIT } from "AppConfig";
import { Dictionary } from "lodash";
import { SORT_BY } from "components/widgets/generals/TopPrice/constants";
import {
  LastSale,
  ORDER_TYPEMap,
  ORDER_TYPE,
  TopSecUpDownResponse,
} from "domain/protoNew/auto_trading_pb";

export type TopPriceLeftState = {
  activeTab: TopPricePeriod;
  data: Dictionary<TopSecUpDownResponse.TopSecUpDownInfo.AsObject>;
  tickerList: string[];
  loading: boolean;
  error: string | undefined;
  widgetContainerHeight: number;
  limit: number;
  activeSort: {
    sortBy: SORT_BY;
    order: ORDER_TYPEMap[keyof ORDER_TYPEMap];
  };
  enableLoadMore: boolean;
};

const initialState: TopPriceLeftState = {
  activeTab: "today" as TopPricePeriod,
  data: {},
  tickerList: [],
  loading: false,
  error: undefined,
  widgetContainerHeight: 0,
  limit: TOP_PRICE_LOADING_LIMIT,
  activeSort: {
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
    order: ORDER_TYPE.ORDER_DEFAULT,
  },
  enableLoadMore: true,
};

const topPriceLeftPrice = createSlice({
  name: "topPriceLeftSlice",
  initialState,
  reducers: {
    cleanData: (state) => {
      return initialState;
    },
    requestChangePeriod: (state, action: PayloadAction<TopPricePeriod>) => {
      state.activeTab = action.payload;
      state.loading = true;
      state.data = {};
      state.error = undefined;
      state.tickerList = [];
      state.enableLoadMore = true;
      state.activeSort = initialState.activeSort;
    },
    requestLoadMore: (state) => {},
    requestLoadMoreSuccess: (
      state,
      action: PayloadAction<TopSecUpDownResponse.TopSecUpDownInfo.AsObject[]>
    ) => {
      state.limit = state.limit + TOP_PRICE_LOADING_LIMIT;
      state.data = {
        ...state.data,
        ...action.payload.reduce(
          (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
          {}
        ),
      };

      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    requestTopPriceLeft: (state) => {},
    requestTopPriceLeftSuccess: (
      state,
      action: PayloadAction<TopSecUpDownResponse.TopSecUpDownInfo.AsObject[]>
    ) => {
      state.data = action.payload.reduce(
        (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
        {}
      );
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    changePeriodSuccess: (
      state,
      action: PayloadAction<TopSecUpDownResponse.TopSecUpDownInfo.AsObject[]>
    ) => {
      state.data = action.payload.reduce(
        (obj, cur) => ({ ...obj, [cur.seccd]: cur }),
        {}
      );

      state.loading = false;
      state.limit = TOP_PRICE_LOADING_LIMIT;
      const tickerList = Object.keys(state.data);
      state.tickerList = tickerList;
      if (action.payload.length < TOP_PRICE_LOADING_LIMIT) {
        state.enableLoadMore = false;
      }
    },
    changePeriodFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    changeWidgetContainerHeight: (state, action: PayloadAction<number>) => {
      state.widgetContainerHeight = action.payload;
    },
    updateTicker: (state, action: PayloadAction<LastSale.AsObject>) => {
      const { payload } = action;
      const ticker = state.data[payload.seccd];
      if (!!ticker) {
        ticker.closeprice = payload.lastprice;
        ticker.colorcode = payload.colorcode;

        if (state.activeTab === "today") {
          ticker.changepercent = payload.changepercent;
        }
      }
    },
    changeActiveSort: (
      state,
      action: PayloadAction<{
        sortBy: SORT_BY;
        order: ORDER_TYPEMap[keyof ORDER_TYPEMap];
      }>
    ) => {
      state.activeSort = action.payload;
      state.loading = true;
      state.data = {};
      state.error = undefined;
      state.tickerList = [];
      state.enableLoadMore = true;
    },
  },
});

export const {
  cleanData,
  requestChangePeriod,
  changePeriodSuccess,
  changePeriodFailure,
  updateTicker,
  changeActiveSort,
  changeWidgetContainerHeight,
  requestLoadMore,
  requestLoadMoreSuccess,
  requestTopPriceLeft,
  requestTopPriceLeftSuccess,
} = topPriceLeftPrice.actions;

export default topPriceLeftPrice.reducer;
