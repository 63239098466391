import { basicpriceSelector } from "modules/categories/redux/selectors";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

function BasicpriceCell({ ticker }: Props) {
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;

  return (
    <PriceCell
      color="textColorYellow"
      className="bg-overlay-col opening-price reference-short-price"
      price={basicprice}
      isBlink={false}
      ticker={ticker}
    />
  );
}

export default BasicpriceCell;
