import styled from "styled-components/macro";

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  height: 100%;
`;

export const DetailCol = styled.div`
  flex: 1;
  padding: 0 8px;
`;

export const DetailRow = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  align-items: ${({ alignCenter }) =>
    !alignCenter ? "center" : alignCenter ? "center" : "none"};
  margin-bottom: 8px;
  justify-content: space-between;
`;

export const DetailLabel = styled.div`
  min-width: 120px;
`;

export const DetailInputWrapper = styled.div`
  flex: 1;
  text-align: right;
`;

export const DetailContentWrapper = styled.div`
  flex: 1;
  text-align: right;
  margin-top: 9px;
  height: 17px;
  color: ${({ theme }) => theme.grey0};
`;

export const Textarea = styled.textarea`
  position: relative;
  width: 100%;
  background-color: rgb(5, 7, 11);
  border: 1px solid rgb(33, 44, 58);
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  height: 67px;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TextDetail = styled.p`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.grey0};
`;

export const DetailTextareaRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
`;

export const FooterForm = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
