import { Container } from "./styles";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  getCustomerProRequest,
  updateModalStatusRequest,
} from "modules/professional-investor/redux";
import ConfirmModal from "./ConfirmModal";
import { IGetCustomerProParams } from "core/http/apis/professional-investor/types";

import storage from "helper/storage";

function ProfessionalInvestor() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(updateItemEdit({ subAccoNo: accountOptions[0]?.value }));

    const submitData: IGetCustomerProParams = {
      subAccoNo: storage.getMasterAccount() ?? "",
    };
    dispatch(getCustomerProRequest(submitData));
    dispatch(
      updateModalStatusRequest({
        isVisible: true,
      })
    );
  });

  return (
    <>
      <Container>
        <ConfirmModal />
      </Container>
    </>
  );
}

export default ProfessionalInvestor;
