import { v4 as uuid } from "uuid";
import { put, takeLatest, all, call, select } from "redux-saga/effects";
import {
  fetchCapitalization,
  fetchCapitalizationFailure,
  fetchCapitalizationSuccess,
} from "../redux/capitalization";
import {
  TopMarketCapInput,
  TopMarketCapResponse,
} from "domain/protoNew/auto_trading_pb";
import { getTopMarketCap } from "core/grpc";
import { AuthenCodeGrpc } from "AppConfig";
import { setCurrentMarketCode } from "../redux";
import { currentMarketCodeSelector } from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";

function* fetchCapitalizationWorker() {
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  const request = new TopMarketCapInput();
  request.setUserid(uuid());
  request.setMarketcd(currentMarketCode.toString());
  request.setOffset(0);
  request.setLimit(5000);
  request.setAuthencode(AuthenCodeGrpc);
  try {
    const response: TopMarketCapResponse.TopMarketCapInfo.AsObject[] =
      yield call(getTopMarketCap, request);

    yield put(fetchCapitalizationSuccess(response));
  } catch (e: any) {
    yield put(fetchCapitalizationFailure(e));
  }
}

function* fetchCapitalizationWatcher() {
  yield takeLatest(fetchCapitalization.type, fetchCapitalizationWorker);
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchCapitalizationWorker);
}

export default function* capitalizationWidget() {
  yield all([fetchCapitalizationWatcher(), setCurrentMarketCodeWatcher()]);
}
