import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FlagVN } from "assets/image/svg/flagVN.svg";
import { ReactComponent as FlagUK } from "assets/image/svg/flagUK.svg";
import { LanguageType, changeLanguage } from "languages/redux";
import {
  ItemLanguage,
  Languages,
  Container,
  ThemeList,
  LanguagesContainer,
  ThemeContainer,
  Title,
} from "./styles";
import { typeLanguageSelector } from "languages/selectors";
import { RootState } from "redux/store";
import { ReactComponent as LightTheme } from "assets/image/svg/LightTheme.svg";
import { ReactComponent as BlackTheme } from "assets/image/svg/BlackTheme.svg";
import { ReactComponent as IconCheckboxCycleSelect } from "assets/image/svg/ic_checkbox_cycle_select.svg";
import { ReactComponent as IconCheckboxCycleUnSelect } from "assets/image/svg/ic_checkbox_cycle_unselect.svg";
import { ThemeType } from "themes/types";
import { changeTheme } from "themes/redux";
import ItemTheme from "./ItemTheme";
import { useIntl, FormattedMessage } from "react-intl";
import storage from "helper/storage";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
import { useEffect } from "react";

function GeneralSettings() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const language = useSelector(typeLanguageSelector);
  const colorTheme = useSelector((state: RootState) => state.theme.type);

  useEffect(() => {
    const resLogData = {
      event: RealtimeConst.initLanguage,
      message: language,
    };
    storage.eventFirebase(resLogData);

    const resLogDataTheme = {
      event: RealtimeConst.initTheme,
      message: colorTheme,
    };
    storage.eventFirebase(resLogDataTheme);
  }, []);

  const setChangeLanguage = (languageType: LanguageType) => {
    dispatch(changeLanguage(languageType));

    const resLogData = {
      eventGroup: GroupEvent.login,
      event: RealtimeConst.changeLanguage,
      message: languageType,
    };
    storage.commonPushLogFirebase(resLogData);
  };

  const toggleChooseTheme = (theme: ThemeType) => {
    dispatch(changeTheme(theme));

    const resLogData = {
      eventGroup: GroupEvent.login,
      event: RealtimeConst.changeTheme,
      message: theme,
    };
    storage.commonPushLogFirebase(resLogData);
  };

  return (
    <Container>
      <LanguagesContainer>
        <Title>
          <FormattedMessage id="auth.setting.generalSetting.language" />
        </Title>
        <Languages>
          <ItemLanguage
            onClick={() => setChangeLanguage(LanguageType.VI)}
            isActive={language === LanguageType.VI}
          >
            <FlagVN />
            &nbsp; VIETNAM
          </ItemLanguage>
          <ItemLanguage
            onClick={() => setChangeLanguage(LanguageType.EN)}
            isActive={language === LanguageType.EN}
          >
            <FlagUK />
            &nbsp; ENGLISH
          </ItemLanguage>
        </Languages>
      </LanguagesContainer>

      <ThemeContainer>
        <Title>
          <FormattedMessage id="auth.setting.generalSetting.theme" />
        </Title>
        <ThemeList>
          <div onClick={() => toggleChooseTheme(ThemeType.BLACK)}>
            <ItemTheme
              image={<BlackTheme className="image" />}
              icon={
                colorTheme === ThemeType.BLACK ? (
                  <IconCheckboxCycleSelect />
                ) : (
                  <IconCheckboxCycleUnSelect />
                )
              }
              text={intl.formatMessage({ id: "auth.chooseTheme.blackTheme" })}
              active={colorTheme === ThemeType.BLACK}
              defaultTheme={intl.formatMessage({
                id: "auth.chooseTheme.default",
              })}
            />
          </div>
          <div onClick={() => toggleChooseTheme(ThemeType.LIGHT)}>
            <ItemTheme
              image={<LightTheme className="image" />}
              icon={
                colorTheme === ThemeType.LIGHT ? (
                  <IconCheckboxCycleSelect />
                ) : (
                  <IconCheckboxCycleUnSelect />
                )
              }
              text={intl.formatMessage({ id: "auth.chooseTheme.lightTheme" })}
              active={colorTheme === ThemeType.LIGHT}
            />
          </div>
        </ThemeList>
      </ThemeContainer>
    </Container>
  );
}

export default GeneralSettings;
