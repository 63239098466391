import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISearchContractParams,
  ILiquidateContractParams,
  IEditCreateContractParams,
  IFindBrokerListParams,
  ICreateContractParams,
} from "core/http/apis/create-contract/types";
import {
  Contract,
  ISearchContractResponse,
  ILiquidateContractResponse,
  IBroker,
  IFindBrokerListResponse,
  ICreateContractResponse,
} from "domain/models/CreateContract";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  contractSts: number;
  fromDate?: Date;
  toDate?: Date;
}

export interface ReduxData {
  filter: IFilter;
  list: Contract[];
  loading: boolean;
  activeTab: string;
  statusLiquidate: boolean;

  statusCreateContract: boolean;
  createContract?: IEditCreateContractParams;
  brokerList: IBroker[];
  loadingBroker: boolean;
}

const initialState: ReduxData = {
  filter: {
    contractSts: 1,
    // fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    // toDate: new Date(),
  },
  list: [],
  loading: false,
  activeTab: "CREATE_CONTRACT",
  statusLiquidate: false,
  //   isShowConfirmModal: false,
  statusCreateContract: false,
  brokerList: [],
  loadingBroker: false,
};

const createContractSlice = createSlice({
  name: "createContractSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchContractRequest: (
      state,
      action: PayloadAction<ISearchContractParams>
    ) => {
      state.loading = true;
    },
    searchContractSuccess: (
      state,
      action: PayloadAction<ISearchContractResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchContractFailure: (state) => {
      state.loading = false;
    },
    liquidateContractRequest: (
      state,
      action: PayloadAction<ILiquidateContractParams>
    ) => {
      state.statusLiquidate = false;
    },
    liquidateContractSuccess: (
      state,
      action: PayloadAction<ILiquidateContractResponse>
    ) => {
      state.statusLiquidate = true;
    },
    liquidateContractFailure: (state) => {
      state.statusLiquidate = false;
    },

    createContractRequest: (
      state,
      action: PayloadAction<ICreateContractParams>
    ) => {
      state.statusCreateContract = false;
    },
    createContractSuccess: (
      state,
      action: PayloadAction<ICreateContractResponse>
    ) => {
      state.statusCreateContract = true;
    },
    createContractFailure: (state) => {
      state.statusCreateContract = false;
    },

    findBrokerListRequest: (
      state,
      action: PayloadAction<IFindBrokerListParams>
    ) => {
      state.loadingBroker = true;
    },
    findBrokerListSuccess: (
      state,
      action: PayloadAction<IFindBrokerListResponse>
    ) => {
      state.loadingBroker = false;

      state.brokerList = action.payload.data.filter(
        (i) => i.businessType === "3" || i.businessType?.includes("3")
      );
    },
    findBrokerListFailure: (state) => {
      state.loadingBroker = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateItemEdit: (
      state,
      action: PayloadAction<IEditCreateContractParams | undefined>
    ) => {
      if (!action.payload) {
        state.createContract = undefined;
        return;
      }

      state.createContract = {
        ...state.createContract,
        ...action.payload,
      };
    },
  },
});

export const {
  searchContractFailure,
  searchContractRequest,
  searchContractSuccess,
  liquidateContractRequest,
  liquidateContractSuccess,
  liquidateContractFailure,
  createContractRequest,
  createContractSuccess,
  createContractFailure,

  updateItemEdit,
  updateFilter,
  findBrokerListFailure,
  findBrokerListRequest,
  findBrokerListSuccess,
} = createContractSlice.actions;

export default createContractSlice.reducer;
