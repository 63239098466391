import queryString from "query-string";

export const findCbotProductRetailApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findCbotProductRetail${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const findRbonOrderApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findRbondOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const cbotGetInterestApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/cbotGetInterest${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const inquiryBuyingPowerApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryBuyingPower${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const findCbotBondInfoApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findCbotBondInfo${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const createRbonOrderApi = `/createRbondOrder`;
export const deleteRbonOrderApi = `/deleteRbondOrder`;
export const updateCbotBondTransferApi = `/updateCbotBondTransferRequest`;
