import { all, takeEvery } from "redux-saga/effects";
import { changeTickersListSelected } from "../redux";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";
import { orderTickerListTable } from "../redux/categoriesInfo";
import Storage from "helper/storage";
function changeTickersListLogDataWorker(
  action: ReturnType<typeof changeTickersListSelected>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeWatchList,
    data: action.payload,
  };
  Storage.commonPushLogFirebase(resLogData);
}

function orderTickerListTableLogDataWorker(
  action: ReturnType<typeof orderTickerListTable>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.sortWatchList,
    data: action.payload,
  };
  Storage.commonPushLogFirebase(resLogData);
}

function* changeTickersListLogDataWatcher() {
  yield takeEvery(
    changeTickersListSelected.type,
    changeTickersListLogDataWorker
  );
}

function* orderTickerListTableLogDataWatcher() {
  yield takeEvery(orderTickerListTable.type, orderTickerListTableLogDataWorker);
}

export default function* logTrackingSaga() {
  yield all([
    changeTickersListLogDataWatcher(),
    orderTickerListTableLogDataWatcher(),
  ]);
}
