import { put, takeLatest, all, call, select } from "redux-saga/effects";
import {
  fetchForeignChart,
  fetchForeignChartFailure,
  fetchForeignChartSuccess,
  fetchForeignIndustryChart,
  fetchForeignIndustryChartSuccess,
} from "../redux/foreignChart";
import {
  getStatDividendMovement,
  getTopIndustriesTradeForeign,
} from "core/grpc";
import { ChartData } from "components/widgets/generals/ForeignChart/types";
import { ChartIndustriesData } from "components/widgets/generals/ForeignIndustryChart/types";
import globalData from "helper/globalData";
import {
  InternalMS,
  InternalMSResponse,
  TopIndustriesTradeForeignItem,
  TopIndustriesTradeForeignResponse,
} from "domain/protoNew/auto_trading_pb";
import { currentMarketCodeSelector } from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";

function getDisplayData(rpgcInput: InternalMS.AsObject[]): ChartData[] {
  return rpgcInput.map((item: InternalMS.AsObject) => {
    const displayItem: ChartData = {
      ticker: item.seccd,
      stockName: globalData.getTickerInfoNew(item.seccd)?.secname,
      totalForeignBuyValue: item.buyforeignamt,
      totalForeignSellValue: item.sellforeignamt,
      totalForeignNetValue: item.netforeignamt,
      changedpercentage: item.changepercent,
      color: item.netforeignamt > 0 ? 3 : 4,
    };
    return displayItem;
  });
}

function getDisplayIndustryData(
  rpgcInput: TopIndustriesTradeForeignItem.AsObject[]
): ChartIndustriesData[] {
  return rpgcInput.map((item: TopIndustriesTradeForeignItem.AsObject) => {
    const displayItem: ChartIndustriesData = {
      industryNameVi: item.industrynamevi,
      industryNameEn: item.industrynameen,
      totalForeignBuyValue: item.buyforeignamt,
      totalForeignSellValue: item.sellforeignamt,
      totalForeignNetValue: item.netforeignamt,
      color: item.netforeignamt > 0 ? 3 : 4,
    };
    return displayItem;
  });
}

function* fetchForeignChartWorker() {
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  try {
    const res: InternalMSResponse.AsObject = yield call(
      getStatDividendMovement,
      currentMarketCode.toString()
    );
    let totalBuyValue = 0;
    let totalSellValue = 0;
    let totalNetValue = 0;
    res?.internalmslistList?.map((item) => {
      totalBuyValue += item.buyforeignamt;
      totalSellValue += item.sellforeignamt;
      totalNetValue += item.netforeignamt;
      return item;
    });

    yield put(
      fetchForeignChartSuccess({
        totalBuyValue: totalBuyValue || 0,
        totalSellValue: totalSellValue || 0,
        totalNetValue: totalNetValue || 0,
        items: getDisplayData(res.internalmslistList),
      })
    );
  } catch (e: any) {
    yield put(fetchForeignChartFailure(e));
  }
}

function* fetchForeignIndustryChartWorker() {
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  try {
    const res: TopIndustriesTradeForeignResponse.AsObject = yield call(
      getTopIndustriesTradeForeign,
      currentMarketCode.toString()
    );
    let totalBuyValue = 0;
    let totalSellValue = 0;
    let totalNetValue = 0;
    res?.dataList?.map((item) => {
      totalBuyValue += item.buyforeignamt;
      totalSellValue += item.sellforeignamt;
      totalNetValue += item.netforeignamt;
      return item;
    });
    yield put(
      fetchForeignIndustryChartSuccess({
        totalBuyValue: totalBuyValue || 0,
        totalSellValue: totalSellValue || 0,
        totalNetValue: totalNetValue || 0,
        items: getDisplayIndustryData(res.dataList!),
      })
    );
  } catch (e: any) {
    yield put(fetchForeignChartFailure(e));
  }
}

function* fetchForeignChartWatcher() {
  yield takeLatest(fetchForeignChart.type, fetchForeignChartWorker);
}
function* fetchForeignIndustryChartWatcher() {
  yield takeLatest(
    fetchForeignIndustryChart.type,
    fetchForeignIndustryChartWorker
  );
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchForeignChartWorker);
}
function* setCurrentMarketCodeIndustryWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchForeignIndustryChartWorker);
}

export default function* foreignChartWidget() {
  yield all([
    fetchForeignChartWatcher(),
    fetchForeignIndustryChartWatcher(),
    setCurrentMarketCodeWatcher(),
    setCurrentMarketCodeIndustryWatcher(),
  ]);
}
