import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IResponsePlaceOrderTransferBond } from "domain/models/PlaceOrderTransferBond";
import {
  getCustomer2Api,
  getServiceStatusOfCustomer2Api,
  registerServiceApi,
} from "./urls";

export const getCustomer2 = async (
  param: any
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getCustomer2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getServiceStatusOfCustomer = async (
  param: any
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getServiceStatusOfCustomer2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const registerService = async (
  param: any
): Promise<IResponsePlaceOrderTransferBond> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    registerServiceApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
