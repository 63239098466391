import { Overlay, Popover } from "react-bootstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { TabCard, Rename, SaveTemplate, Content } from "./styles";

interface Props {
  active: boolean;
  onPress: () => void;
  onClickX: React.MouseEventHandler<HTMLDivElement>;
  //   onClickBar: () => void;
  name: string;
  id: string;
  model: any;
  onRename: () => void;
  onSaveBoard: () => void;
}

const Tab: React.FC<Props> = React.memo(
  ({
    active,
    onPress,
    onClickX,
    // onClickBar,
    name,
    id,
    model,
    onRename,
    onSaveBoard,
  }) => {
    const [showPop, setShowPop] = useState(false);
    const [target, setTarget] = useState(null);

    //TODO: thêm type giúp em nhé. vs code của em lag quá trời lag. k suggest type :((
    //TODO: tối ưu lại popover ở đây nhé . thằng pop thư viện này nó k hỗ trợ khi blur thì tắt đi . cần xem lại.
    const onClickPop = (e: any) => {
      setShowPop(!showPop);
      setTarget(e.target);
    };

    const [isHover, setIsHover] = useState(false);
    return (
      <>
        <TabCard
          active={active}
          onClick={onPress}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="tab-close">
            {model && (
              <div className={` ${!isHover && "d-none"}`} onClick={onClickPop}>
                {/* Thêm icon vào đấy anh tiến nhé . trên design k có */}
                <FormattedMessage id="stockOrderBoard.tab.open" />
                <Overlay show={showPop} target={target} placement="right">
                  <Popover id="popover-basic">
                    <Content>
                      <Rename className="item-pop p-2" onClick={onRename}>
                        <FormattedMessage id="stockOrderBoard.tab.rename" />
                      </Rename>
                      <SaveTemplate
                        className="item-pop p-2"
                        onClick={onSaveBoard}
                      >
                        <FormattedMessage id="stockOrderBoard.tab.saveTheTemplate" />
                      </SaveTemplate>
                    </Content>
                  </Popover>
                </Overlay>
              </div>
            )}
          </div>

          <div className="tab-name">{name}</div>
          <div className={`tab-close`} onClick={onClickX}>
            <div className={` ${!isHover && "d-none"}`} onClick={onClickX}>
              <IconTrash width={15} />
            </div>
          </div>
        </TabCard>
      </>
    );
  }
);

export default Tab;
