import styled from "styled-components/macro";

export const ComponentContainer = styled.div`
  position: relative;
`;

export const StarContainer = styled.div`
  cursor: pointer;
`;

export const DropdownContainer = styled.div<{
  placement?: string;
}>`
  position: absolute;
  max-height: 240px;
  width: 197px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.background2};
  filter: drop-shadow(0px 8px 12px rgba(13, 17, 21, 0.5));
  border-radius: 8px;
  right: 0;
  top: 30px;
  z-index: 1;
  ${({ placement }) =>
    placement === "top" &&
    `
    bottom: 100%;
    left: 0;
    right: unset;
    top: unset;
  `}
`;

export const DropdownItem = styled.div`
  height: 48px;
  padding: 14px 12px;
  background-color: ${({ theme }) => theme.background2};
  display: flex;
  color: ${({ theme }) => theme.textcolorWhite};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner3};
`;

export const DropdownText = styled.div`
  flex-grow: 1;
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const AddButton = styled.div`
  cursor: pointer;
`;

export const CheckMarkContainer = styled.div`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
`;

export const WatchListButton = styled.div`
  .button-plus {
    align-self: flex-end;
    height: ${({ theme }) => theme.button.height};
  }
`;
