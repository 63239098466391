import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgElevated3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  align-items: center;
  z-index: 4;
  width: 100%;

  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.background1};
    }
  }
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: 16px;
  color: ${({ theme }) => theme.gray40};
  white-space: nowrap;
  width: 83px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  ${({ isActive, theme }) =>
    isActive &&
    `border-bottom: 2px solid ${theme.solidBlue};
      color: ${theme.solidBlue};`}
`;
