import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";

import { PlaceOrderState } from "modules/categories/redux/placeOrder";
// import { TickerInfoState } from "modules/categories/redux/tickerInfo";
// import { InfoQuoteState } from "modules/categories/redux/infoQuote";
import { VolumeAndTimeState } from "modules/categories/redux/volumeAndTime";
import { State as LanguageState } from "languages/redux";
import { CategoryInfo } from "../types";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";

export const categoriesSelector = (state: RootState) => state.categories;

export const placeOrderSelector = (state: RootState) =>
  state.categories.placeOrder;

export const isShowPlaceOrderSelector = (state: RootState) =>
  state.categories.placeOrder.isShowPlaceOrder;

// export const tickerInfoSelector = (state: RootState) =>
//   state.categories.tickerInfo;

export const categoryRootSelector = (state: RootState) => state.categories.root;

export const loadingCategorySelector = createSelector(
  categoryRootSelector,
  (state) => state.loading
);

export const currentListCodeSelector = createSelector(
  categoryRootSelector,
  (state) => state.currentListCode
);

export const tickersListSelectedSelector = createSelector(
  categoryRootSelector,
  (state) => state.tickersListSelected
);

export const columnsSelector = createSelector(
  categoryRootSelector,
  (state) => state.columns
);

export const tickerFocusSelector = createSelector(
  categoryRootSelector,
  (state) => state.tickerFocus
);

export const selectedTickerSelector = createSelector(
  placeOrderSelector,
  (state: PlaceOrderState) => state.tickerNameSelected
);

// TODO
// //Ticker info selector
// export const tickerInfoWidgetSelector = createSelector(
//   tickerInfoSelector,
//   (state: TickerInfoState) => ({
//     tickerInfoBasic: state.tickerInfoBasic,
//     tickerInfoLastSale: state.tickerInfoLastSale,
//     tickerInfoSummary: state.tickerInfoSummary,
//     organizationFundamental: state.organizationFundamental,
//     tickerInfoForeignRoom: state.tickerInfoForeignRoom,
//   })
// );

export const tickersListInCategorySelector = createSelector(
  categoryRootSelector,
  (state) => state.tickersNew
);

export const tickerListInCategorySelector = createSelector(
  categoryRootSelector,
  (state) => state.tickersNew
);
// Price list selector

// export const priceListSelector = (state: RootState) =>
//   state.categories.infoQuote;

// export const dataAskBidSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.dataAskBid
// );

// export const dataQuoteSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.dataQuote
// );

// export const percentBuySelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.percentBuy
// );

// export const sumAskSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.sumAsk
// );

// export const sumBidSelector = createSelector(
//   priceListSelector,
//   (state: InfoQuoteState) => state.sumBid
// );

// Volume and time

const volumeAndTimeSelector = (state: RootState) =>
  state.categories.volumeAndTime;

export const volumeListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.volumeList
);

export const historyListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.historyList
);

// Put Through
export const putThroughSelector = (state: RootState) =>
  state.categories.putThrough;

export const rootCategoriesSelector = createSelector(
  categoriesSelector,
  (state) => state.root
);
export const currentListCodeCategoriesSelector = createSelector(
  categoriesSelector,
  (state) => state.root.currentListCode
);
export const isDeleteTickerCategoriesSelector = createSelector(
  categoriesSelector,
  (state) => state.root.isDeleteTicker
);
export const anonymousFavoriteListCategoriesSelector = createSelector(
  categoriesSelector,
  (state) => state.root.anonymousFavoriteList
);
export const userFavoriteListCategoriesSelector = createSelector(
  categoriesSelector,
  (state) => state.root.userFavoriteList
);

export const anonymousFavoriteListSelector = createSelector(
  rootCategoriesSelector,
  (state) => state.anonymousFavoriteList
);

export const userFavoriteListSelector = createSelector(
  rootCategoriesSelector,
  (state) => state.userFavoriteList
);

const languageSelector = (state: RootState) => state.language;

export const languageTypeSelector = createSelector(
  languageSelector,
  (languageState: LanguageState) => languageState.lang
);

export const listedSelector = createSelector(
  categoriesSelector,
  (state) => state.listed
);
export const selectedListedTypeSelector = createSelector(
  categoriesSelector,
  (state) => state.listed.selectedListedType
);
export const selectedOddLotTypeSelector = createSelector(
  categoriesSelector,
  (state) => state.oddLot.selectedOddLotType
);
export const oddLotListSelector = createSelector(
  categoriesSelector,
  (state) => state.root.oddLotListNew
);
export const industrySelector = createSelector(
  categoriesSelector,
  (state) => state.industry
);
export const selectedIndustryTypeSelector = createSelector(
  categoriesSelector,
  (state) => state.industry.selectedIndustryType
);
export const derivativeSelector = createSelector(
  categoriesSelector,
  (state) => state.derivative
);

// Console

export const consoleSelector = createSelector(
  categoriesSelector,
  (state) => state.console
);

export const chartDataDerivativeTickerSelector = createSelector(
  derivativeSelector,
  (state) => state.chartDataDerivativeTicker
);

export const selectedTypeDerivativeSelector = createSelector(
  derivativeSelector,
  (state) => state.selectedTypeDerivative
);
export const isShowingVN30LineSelector = createSelector(
  derivativeSelector,
  (state) => state.isShowingVN30Line
);

export const categoriesInfoSelector = createSelector(
  categoriesSelector,
  (state) => state.categoriesInfo.info
);

export const colorcodeSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.info[ticker]?.colorcode
  );
};

export const lastpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.info[ticker]?.secdetailinfo?.lastprice
  );
};

export const basicpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.info[ticker]?.secdetailinfo?.basicprice
  );
};
export const ceilingpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.info[ticker]?.secdetailinfo?.ceilingprice
  );
};
export const floorpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.info[ticker]?.secdetailinfo?.floorprice
  );
};
export const baseseccdSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.info[ticker]?.secdetailinfo?.baseseccd?.data
  );
};

export const baseseccdPriceInfoSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.infoBaseseccd[ticker].baseprice
  );
};

export const baseseccdPriceColorInfoSelector = (ticker: string) => {
  return createSelector(
    categoriesSelector,
    (state) => state.categoriesInfo.infoBaseseccd[ticker].basesecpricecolor
  );
};

export const optionScrollF1Selector = createSelector(
  categoriesSelector,
  (state) => state.categoriesInfo.optionScroll
);

export const categoriesSortTickersSelector = createSelector(
  categoriesSelector,
  (state) => state.categoriesInfo.sortTickers
);

export const tickerCategoriesInfoSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo): SymbolTotalInfo.AsObject => info[ticker]
  );
};

export const hasRightExecTickerCategoriesInfoSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.hasrightexec
  );
};

export const getAllListSelector = createSelector(
  rootCategoriesSelector,
  (state) => [
    ...state.ownerList,
    ...state.etfListNew,
    ...state.listedNew,
    ...state.derList,
    ...state.cwListNew,
    ...state.industriesListNew,
  ]
);

export const tickerListTableSelector = createSelector(
  categoriesSelector,
  (state) => state.categoriesInfo.tickerList
);

export const authenticationStatueSelector = (state: RootState) =>
  state.auth.root.data.isAuthenticated;
