export enum STEPS {
  STEPS_1 = "STEPS_1",
  STEPS_2 = "STEPS_2",
  STEPS_3 = "STEPS_3",
  STEPS_4 = "STEPS_4",
  STEPS_5 = "STEPS_5",
  STEPS_6 = "STEPS_6",
  STEPS_7 = "STEPS_7",
  STEPS_8 = "STEPS_8",
  STEPS_9 = "STEPS_9",
  STEPS_10 = "STEPS_10",
  STEPS_11 = "STEPS_11",
}
