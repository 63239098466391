import CommonTable from "components/commons/CommonTable";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import moment from "moment";
import numeral from "numeral";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ContentContainer } from "../styles";
import { dertDetailListSelector } from "modules/stock-order-der/selectors";

interface Props {}

const DetailOrderDerTable: React.FC<Props> = () => {
  const dertDetailList = useSelector(dertDetailListSelector);

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0") || "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "ordPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props.value).format("0,0.0") || "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="widgets.orderBookDetail.table.confirmTime" />
          </TableHeaderCellContent>
        ),
        accessor: "confirmTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value
                ? moment(props.value?.toString(), "x").format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "- - -"}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  return (
    <ContentContainer>
      <CommonTable columns={columns} data={dertDetailList} />
    </ContentContainer>
  );
};
export default DetailOrderDerTable;
