import React, { FC, memo, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Input } from "components/commons";
import { InputContainer, TickerContainer } from "./style";
import { useRoveFocus } from "helper/cusHooks";
import SelectItem from "./SelectItem";
import orderBy from "lodash/orderBy";
// import { Bond } from "domain/models/BidAskBond";
interface InputSymbolProps {
  value?: string;
  onChange?: (e: string) => void;
  isOnlyShareCode?: boolean;
  list: any[];
  showPlaceholder?: boolean;
}

const InputSymbol: FC<InputSymbolProps> = memo(
  ({ value, onChange, isOnlyShareCode, list, showPlaceholder = true }) => {
    const intl = useIntl();
    const refInput = useRef<HTMLInputElement>(null);

    const [textSearch, setTextSearch] = useState("");
    const [numberLoadmore, setNumberLoadMore] = useState(6);

    const [isChangeArrow, setIsChangeArrow] = useState(false);
    const [isClickOutSide, setIsClickOutSide] = useState(false);
    const [visibleTextSearch, setVisibleTextSearch] = useState(false);

    const [focus, setFocus] = useRoveFocus(
      list.length,
      setIsChangeArrow,
      isChangeArrow
    );

    const onSearchTicker = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextSearch(e.target.value.toUpperCase());

      if (e.target.value === "") {
        onChange?.("");
      }
    };
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.stopPropagation();

        if (textSearch !== "") {
          const item = tickerSort?.filter((ticker) => {
            return ticker.bondCd.includes(textSearch.toUpperCase());
          })[0];
          setTextSearch(item?.bondCd ?? "");
          onChange?.(item?.bondCd ?? "");
          refInput.current?.blur();
          setVisibleTextSearch(false);
        } else {
          setTextSearch(textSearch);
          onChange?.(textSearch);
          refInput.current?.blur();
        }
      } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        setIsChangeArrow(true);
        setIsClickOutSide(false);
      }
    };

    const tickerFilter = list.filter((value) =>
      value.bondCd.includes(textSearch.toUpperCase())
    );

    let tickerSort: any[] = orderBy(tickerFilter, ["bondCd"]);

    if (
      tickerSort &&
      tickerSort.length > 0 &&
      tickerSort[tickerSort.length - 1].bondCd ===
        intl.formatMessage({
          id: "bidAskBond.all",
        })
    ) {
      tickerSort.splice(0, 0, tickerSort.pop());
    }

    useEffect(() => {
      setTextSearch(value?.toLocaleUpperCase() ?? "");
    }, [value]);

    const handleScroll = () => {
      setNumberLoadMore(numberLoadmore + 6);
    };

    useEffect(() => {
      if (isClickOutSide) {
        setVisibleTextSearch(false);
      }
    }, [isClickOutSide]);

    // const handleChangeTicker = (ticker: string) => {
    //   if (textSearch.length <= 2) return;
    //   if (!isClickOutSide) return;
    //   onChange?.(ticker);
    // };

    return (
      <InputContainer>
        <Input
          innerRef={refInput}
          placeholder={
            showPlaceholder
              ? intl.formatMessage({ id: "common.inputSymbol" })
              : ""
          }
          value={
            textSearch ===
            intl.formatMessage({
              id: "bidAskBond.all",
            })
              ? intl.formatMessage({
                  id: "bidAskBond.all2",
                })
              : textSearch
          }
          onChange={onSearchTicker}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            setVisibleTextSearch(true);
          }}
          onBlur={(e) => {
            if (!isChangeArrow) {
              if (textSearch !== "") {
                const item = tickerSort?.filter((ticker) => {
                  return ticker.bondCd.includes(textSearch.toUpperCase());
                })[0];

                setTextSearch(item?.bondCd ?? "");
                onChange?.(item?.bondCd ?? "");

                refInput.current?.blur();
                setVisibleTextSearch(false);
              } else {
                setTextSearch(textSearch);
                onChange?.(textSearch);
                refInput.current?.blur();
                // setVisibleTextSearch(false);
              }
              setTimeout(() => setVisibleTextSearch(false), 150);
            } else {
            }
          }}
        />

        {visibleTextSearch && (
          <TickerContainer onScroll={handleScroll}>
            {tickerSort.slice(0, numberLoadmore)?.map((item, index) => (
              <SelectItem
                key={index}
                setFocus={setFocus}
                index={index}
                focus={focus === index}
                data={item}
                setVisibleTextSearch={setVisibleTextSearch}
                setTextSearch={setTextSearch}
                setIsChangeArrow={setIsChangeArrow}
                setIsClickOutSide={setIsClickOutSide}
                isOnlyShareCode={isOnlyShareCode}
                onChange={onChange}
              />
            ))}
          </TickerContainer>
        )}
      </InputContainer>
    );
  }
);

export default InputSymbol;
