import queryString from "query-string";
import {
  ICancelOrdersParams,
  ICancelRequestPendingListParams,
  IDertInquiryTradingParams,
  IDertTradingGetFeeRateParams,
  IFindPortfolioParams,
  IGetConditionParams,
  IGetCustomerRestrictionParams,
  IGetPriceWithStepPriceParams,
  IGetQuotesForDertOrderParams,
  IValidateCustomerInsiderParams,
  IValidateDertRequestCondTimeParams,
  PlaceOrdersParam,
  PlaceOrdersPendingParam,
} from "./types";

export const deleteListOrderUrl = "/orderList";

// Check KH co bi phong toa khong
export const getCustomerRestrictionUrl = (
  params: IGetCustomerRestrictionParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomerRestriction${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

// Check KH co la co dong lon, co dong noi bo khong
export const validateCustomerInsiderUrl = (
  params: IValidateCustomerInsiderParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/validateCustomerInsider${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

// Prepare truoc khi dat lenh VGAIA 2.0
export const prepareSubmitOrderUrl = "/prepareSubmitOrder";

// Đặt lệnh VGAIA 2.0
// export const placeOrdersUrl = "/submitOrder";
export const placeOrdersUrl = (params: PlaceOrdersParam) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/submitOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Đặt lệnh trước giờ VGAIA 2.0
// export const placeOrdersPendingUrl = "/submitOrderPending";
export const placeOrdersPendingUrl = (params: PlaceOrdersPendingParam) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/submitOrderPending${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Lấy giá bình quân VGAIA 2.0
export const findPortfolioUrl = (params: IFindPortfolioParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findPortfolio${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Tính giá đặt 2.0
export const getPriceWithStepPriceUrl = (
  params: IGetPriceWithStepPriceParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getPriceWithStepPrice${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

// Lấy công thức điều kiện VGAIA 2.0
export const getConditionUrl = (params: IGetConditionParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCondition${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Đặt lệnh điều kiện VGAIA 2.0
export const placeOrdersConditionUrl = "/createRequestCond";

// Hủy list lệnh VGAIA 2.0
// export const cancelOrderAPI = "/listOrders";
export const cancelOrderAPI = (params: ICancelOrdersParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/cancelOrders${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Sửa lệnh VGAIA 2.0
export const editOrderUrl = "/editOrder";

// Ban tat ca danh muc VGAIA 2.0
export const portfolioOrderSellAllUrl = "/submitOrderList";

// Hủy lệnh trước giờ VGAIA 2.0
export const cancelRequestPendingListUrl = (
  params: ICancelRequestPendingListParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/cancelRequestPending2${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

// Hủy lệnh điều kiện VGAIA 2.0
export const deleteRequestCondUrl = "/deleteRequestCond";

// Hủy lệnh điều kiện theo danh sách VGAIA 2.0
export const deleteRequestCondByListUrl = "/deleteRequestCondByList";

// Place order der VGAIA 2.0 --start--
//
export const getQuotesForDertOrderUrl = (
  params: IGetQuotesForDertOrderParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getQuotesForDertOrder${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const dertInquiryTradingUrl = (params: IDertInquiryTradingParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/dertInquiryTrading${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const dertTradingGetFeeRateUrl = (
  params: IDertTradingGetFeeRateParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/dertTradingGetFeeRate${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const validateDertRequestCondTimeUrl = (
  params: IValidateDertRequestCondTimeParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/validateDertRequestCondTime${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const dertCreateOrderUrl = "/dertCreateOrder";

export const createDertRequestCondUrl = "/createDertRequestCond";

// Place order der VGAIA 2.0 --end--
