import numeral from "numeral";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { BalanceInfoContainer, Container } from "./styles";
import { placeOrderDerSelector } from "modules/stock-order-der/selectors";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "components/commons/CommonTable/styles";
import {
  updateErrorMesage,
  updatePlaceOrderDerVolume,
} from "modules/stock-order-der/redux/PlaceOrderDer";

const BalanceInfo: React.FC = memo(() => {
  const dispatch = useDispatch();

  const { dertInquiryTrading } = useSelector(placeOrderDerSelector);

  const handleFillVolumeMax = (volume?: number) => {
    const error: { key: string; message: string } | null = {
      key: "errorVolume",
      message: "",
    };
    if (volume) {
      dispatch(updateErrorMesage(error));
      dispatch(updatePlaceOrderDerVolume(volume));
    }
  };

  return (
    <BalanceInfoContainer>
      <div className="flex-fill">
        <div className="d-flex justify-content-between mb-2">
          <div className="flex-fill">
            <FormattedMessage id="widgets.placeOrderDer.availMargin" />
          </div>
          <div className="text-white-neutral1">
            {numeral(dertInquiryTrading.availMargin).format("0,0")}
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-fill">
            <FormattedMessage id="widgets.placeOrderDer.currentRate" />
          </div>
          <div className="text-white-neutral1">
            {numeral(dertInquiryTrading.currentRate).format("0,0.00")}
          </div>
        </div>
      </div>
      <div className="flex-fill" />
      <Container>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <StyledTooltip id="tooltip">
              <FormattedMessage id="widgets.placeOrder.maxVolume.tooltip" />
            </StyledTooltip>
          }
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="flex-fill">
              <FormattedMessage id="widgets.placeOrderDer.maxLong" />
            </div>
            <div
              className="text-white-neutral1 cursor-pointer"
              onClick={() => handleFillVolumeMax(dertInquiryTrading.maxBought)}
            >
              {numeral(dertInquiryTrading.maxBought).format("0,0")}
            </div>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <StyledTooltip id="tooltip">
              <FormattedMessage id="widgets.placeOrder.maxVolume.tooltip" />
            </StyledTooltip>
          }
        >
          <div className="d-flex">
            <div className="flex-fill">
              <FormattedMessage id="widgets.placeOrderDer.maxShort" />
            </div>
            <div
              className="text-white-neutral1 cursor-pointer"
              onClick={() => handleFillVolumeMax(dertInquiryTrading.maxSold)}
            >
              {numeral(dertInquiryTrading.maxSold).format("0,0")}
            </div>
          </div>
        </OverlayTrigger>
      </Container>
    </BalanceInfoContainer>
  );
});
export default BalanceInfo;
