import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const WrapCamera = styled.div`
  width: 636px;
  height: 61%;
  text-align: center;
`;

export const WrapIconCamera = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
