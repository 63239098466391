import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background3};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
  max-height: ${({ theme }) => theme.cardHeader.height};
  align-items: center;
  z-index: 4;

  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.grey70};
    }
  }
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  color: ${({ theme }) => theme.grey40};
  cursor: pointer;
  white-space: nowrap;
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.background3};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
  user-select: none;

  :hover {
    opacity: 0.8;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      box-shadow: inset 0px -1px 0px ${theme.brandPrimary};
      color: ${theme.brandPrimary};
    `}
`;
