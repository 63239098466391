import { PayloadAction, createSlice, combineReducers } from "@reduxjs/toolkit";
import {
  IndustryPriceData,
  IndustryTab,
} from "components/widgets/generals/Industry/types";
import { Dictionary } from "lodash";
import topIndexIndustryReducer from "./topIndexIndustry";
import transactionIndustryReducer from "./transactionIndustry";

export type IndustryOption = {
  label: string;
  value: string;
};
export type IndustryState = {
  activeTab: IndustryTab;
  industries: IndustryOption[];
  listPriceInfo: Dictionary<IndustryPriceData[]>;
  loading: boolean;
  error: string | undefined;
};

const initialState: IndustryState = {
  activeTab: "topIndex",
  industries: [],
  listPriceInfo: {},
  loading: false,
  error: undefined,
};

const industrySlice = createSlice({
  name: "industrySlice",
  initialState,
  reducers: {
    changeActiveTab: (state, action: PayloadAction<IndustryTab>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { changeActiveTab } = industrySlice.actions;

export default combineReducers({
  root: industrySlice.reducer,
  topIndexIndustry: topIndexIndustryReducer,
  transactionIndustry: transactionIndustryReducer,
});
