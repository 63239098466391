import { GroupTypeBase, StylesConfig } from "react-select";
import themes from "themes/abstracts/_themes";
import { ThemeType } from "themes/types";
import { OptionSelectType } from "./index";

export const selectStyle = (type: ThemeType, mediumSelect: boolean) => {
  const colourStyles: StylesConfig<
    OptionSelectType,
    boolean,
    GroupTypeBase<OptionSelectType>
  > = {
    control: (styles, { menuIsOpen }) => ({
      ...styles,
      borderColor: themes[type]["grey70"],
      backgroundColor: themes[type]["grey100"],
      color: themes[type]["textcolorWhite"],
      height: mediumSelect
        ? themes[type].select.height
        : themes[type].select.height,
      display: "flex",
      alignItems: "center",
      borderRadius: themes[type].select.borderRadius,
      boxShadow: menuIsOpen
        ? `0 0 0 1px ${themes[type]["brandPrimary"]}`
        : "none",
      ":hover": {
        borderColor: themes[type]["brandPrimary"],
      },
    }),
    input: (styles) => ({ ...styles, color: themes[type]["textcolorWhite"] }),
    valueContainer: (styles) => ({
      ...styles,
      color: themes[type]["textcolorWhite"],
      height: mediumSelect
        ? themes[type].select.valueContent.height
        : themes[type].select.valueContent.height,
      padding: mediumSelect ? "0 8px" : "2px 8px",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: themes[type]["background2"],
    }),
    singleValue: (styles) => ({
      ...styles,
      color: themes[type]["textcolorWhite"],
      fontSize: themes[type].typeFaces.body.size,
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: isDisabled
          ? themes[type]["background2"]
          : themes[type]["background2"],
        color: isDisabled
          ? themes[type]["textcolorBlack"]
          : isFocused
          ? themes[type]["brandPrimary"]
          : themes[type]["textcolorWhite"],
        borderBottomColor: themes[type]["grey100"],
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        ":last-child": {
          borderBottomWidth: 0,
        },
        ":hover": {
          color: themes[type]["brandPrimary"],
        },
      };
    },
  };
  return colourStyles;
};

export const colourStyles: StylesConfig<
  OptionSelectType,
  boolean,
  GroupTypeBase<OptionSelectType>
> = {
  control: (styles) => ({
    ...styles,
    borderColor: "#1D2836",
    backgroundColor: "transparent",
    color: "#F8F8F8",
  }),
  input: (styles) => ({ ...styles, color: "#F8F8F8" }),
  valueContainer: (styles) => ({ ...styles, color: "#F8F8F8" }),
  menu: (styles) => ({ ...styles, backgroundColor: "#172230" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#F8F8F8",
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: "#172230",
      color: "#ADB3C0",
      borderBottomColor: "#283648",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      ":last-child": {
        borderBottomWidth: 0,
      },
      ":hover": {
        backgroundColor: "#253147",
        color: "#3391FF",
      },
    };
  },
};
