/* eslint-disable no-console */
import { FormattedMessage } from "react-intl";
import {
  Wrap,
  Container,
  WrapCamera,
  WrapIconCamera,
  HeaderModal,
  ModalTitle,
  ModalCloseButton,
  Icon,
  BodyModal,
  WrapModal,
  WrapLabel,
} from "./style";
import Webcam from "react-webcam";
import { useRef, useState } from "react";
import { ReactComponent as CameraIcon } from "assets/image/svg/CameraIcon.svg";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

interface PROPS {
  handleImageCam: (value: any, key: string) => void;
  onClose: () => void;
  type: any;
}

const ModalCamera = (props: PROPS) => {
  const { onClose, type, handleImageCam } = props;
  const webcamRef = useRef<any>(null);
  const [isPermissionDenied, setPermissionDenied] = useState<boolean>(false);

  const videoConstraints = {
    facingMode: "user",
  };

  const capture = () => {
    const imageSrc = webcamRef?.current?.getScreenshot({
      width: 1500,
      height: 1080,
    });

    handleImageCam(imageSrc, type);
    onClose();
  };

  return (
    <WrapModal>
      <HeaderModal>
        <ModalTitle>
          <FormattedMessage id="auth.register.step3.modal.title" />
        </ModalTitle>
        <ModalCloseButton>
          <Icon onClick={() => onClose()}>
            <IconExit />
          </Icon>
        </ModalCloseButton>
      </HeaderModal>
      <BodyModal>
        <Container>
          <Wrap>
            <WrapCamera>
              <WrapLabel>
                {type === "font" ? (
                  <FormattedMessage id="auth.register.step3.modal.fontImage" />
                ) : (
                  <FormattedMessage id="auth.register.step3.modal.backImage" />
                )}
              </WrapLabel>
              <Webcam
                audio={false}
                height={357}
                width={636}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMediaError={(e) => setPermissionDenied(true)}
                onUserMedia={(e) => setPermissionDenied(false)}
              />
              {isPermissionDenied && (
                <div>
                  <FormattedMessage id="ekyc.step4.isPermissionDenied" />
                </div>
              )}
            </WrapCamera>
            <WrapIconCamera onClick={() => capture()}>
              <CameraIcon />
            </WrapIconCamera>
          </Wrap>
        </Container>
      </BodyModal>
    </WrapModal>
  );
};

export default ModalCamera;
