import { Button, OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { toast } from "components/commons/Toast";
import { updateCbotBondTransfer } from "core/http/apis/place-order-transfer-bonds";
import { EBusinessCd } from "helper/consts";
import { tradeDateSelector } from "modules/auth/selectors";
import { ReactComponent as IconCancel } from "assets/image/svg/ic_cancel.svg";
import {
  checkTimeExecuteRequest,
  updateStatusCode,
} from "modules/place-order-transfer-bonds/redux";
import {
  loadingCheckTimeSelector,
  statusCodeSelector,
} from "modules/place-order-transfer-bonds/selectors";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  DetailInputWrapper,
  DetailLabelModal,
  DetailRowPaddingTBModal,
} from "../InformationOrder/styles";
import { Body, Container, Footer, Icon, Title, TitleContainer } from "./styles";
import { IconNone } from "modules/register-to-transfer-bonds/pages/components/ModalContainer/styles";
import numeral from "numeral";

interface Props {
  visibleModalConfirm?: boolean;
  title?: string;
  content?: any;
  handleCloseModal?: () => void;
  handleConfirm?: () => void;
  getResult: () => void;
  size?: any;
}

function ModalConfirm({
  visibleModalConfirm,
  title,
  content,
  handleCloseModal,
  size,
  getResult,
}: Props) {
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);

  const tradeDate = useSelector(tradeDateSelector);
  const loading = useSelector(loadingCheckTimeSelector);
  const statusCode = useSelector(statusCodeSelector);

  const dispatch = useDispatch();

  const intl = useIntl();

  const closeModalConfirm = () => {
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  const handleConfirm = () => {
    dispatch(checkTimeExecuteRequest({}));
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitAdvance = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    const res = await updateCbotBondTransfer({
      actionMode: "create",
      alloDate: moment(tradeDate).format("YYYYMMDD"),
      refNo: "0",
      subAccoNoFrom: content?.subAccoNoFrom,
      subAccoNoTo: content?.subAccoNoTo,
      bondCd: content?.bondCd,
      qty: content?.qty,
      price: content?.price,
      parAmt: content?.parAmt,
      transferAmt: content?.transferAmt,
      feeAmt: content?.feeAmt,
      taxAmt: content?.taxAmt,
      feePayer: content?.feePayer?.value,
      taxPayer: content?.taxPayer?.value,
      remarks: "",
      timeStamp: "0",
      otpCode: response?.otp,
      otpType: response?.otpTypeSubmit!,
    });

    if (res?.statusCode === 1) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.placeBook.createFalure",
        })}`,
        "error"
      );
    }

    if (res?.statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.placeBook.createDone",
        })}`,
        "success"
      );
      getResult();
      dispatch(updateStatusCode({ statusCode: -1 }));
    }
  };

  useEffect(() => {
    if (statusCode === 0) {
      setVisibleModalOTP(true);
    }
  }, [statusCode]);

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        size={size}
        keyboard={false}
        dialogClassName="modal-434"
      >
        <Container>
          <TitleContainer>
            <IconNone></IconNone>
            <Title>{title}</Title>
            <Icon onClick={closeModalConfirm}>
              <IconCancel width={16} height={16} />
            </Icon>
          </TitleContainer>
          <Body>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.accoNoFrom" />
              </DetailLabelModal>
              <DetailInputWrapper className="text-left">
                {content?.subAccoNoFrom}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.nameAccoNoFrom" />
              </DetailLabelModal>
              <DetailInputWrapper>{content?.nameAccoNoFrom}</DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.accoNoTo" />
              </DetailLabelModal>
              <DetailInputWrapper>{content?.subAccoNoTo}</DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.nameAccoNoTo" />
              </DetailLabelModal>
              <DetailInputWrapper>{content?.nameAccoNoTo}</DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.bondCd" />
              </DetailLabelModal>
              <DetailInputWrapper>{content?.bondCd}</DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.quantity" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {numeral(content?.qty).format("0,0")}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>

            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.price" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {numeral(content?.price).format("0,0")}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.parAmt" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {numeral(content?.parAmt).format("0,0")}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.transferAmt" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {numeral(content?.transferAmt).format("0,0")}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.fee" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {numeral(content?.feeAmt).format("0,0")}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.feePayer" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {content?.feePayer?.label}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.tax" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {" "}
                {numeral(content?.taxAmt).format("0,0")}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
            <DetailRowPaddingTBModal>
              <DetailLabelModal>
                <FormattedMessage id="placeOrderTransferBonds.modal.taxPayer" />
              </DetailLabelModal>
              <DetailInputWrapper>
                {content?.taxPayer?.label}
              </DetailInputWrapper>
            </DetailRowPaddingTBModal>
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="placeOrderTransferBonds.modal.cancel" />
            </Button>
            <Button
              color="accept"
              className="flex-1"
              onClick={handleConfirm}
              fill={true}
              loading={loading}
            >
              <FormattedMessage id="placeOrderTransferBonds.modal.submit" />
            </Button>
          </Footer>
        </Container>
      </Modal>
      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </>
  );
}

export default ModalConfirm;
