import { LabelCell, Container, ContentOne } from "./styles";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";

const BidAskBondContent: FC = () => {
  return (
    <Container>
      <ContentOne>
        <LabelCell>
          <FormattedMessage id="bidAskBond.title.bidAskBond" />
        </LabelCell>
        <RequestFilter />
        <RequestTable />
      </ContentOne>
    </Container>
  );
};
export default BidAskBondContent;
