import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import { searchStatisticPrice } from "core/http/apis/statistic-price";
import { ISearchStatisticPriceParams } from "core/http/apis/statistic-price/types";
import { IMarketStatisticResponse } from "domain/models/MarketStatistic";
import {
  searchStatisticPriceFailure,
  searchStatisticPriceRequest,
  searchStatisticPriceSuccess,
} from "./redux";

function* searchStatisticPriceWorker(
  action: ReturnType<typeof searchStatisticPriceRequest>
) {
  try {
    const params: ISearchStatisticPriceParams = action.payload;
    const response: IMarketStatisticResponse = yield call(
      searchStatisticPrice,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchStatisticPriceSuccess(response));
    } else {
      yield put(searchStatisticPriceFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchStatisticPriceFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchStatisticPriceWatcher() {
  yield takeLatest(
    searchStatisticPriceRequest.type,
    searchStatisticPriceWorker
  );
}

export default function* statisticPriceSaga() {
  yield all([searchStatisticPriceWatcher()]);
}
