import queryString from "query-string";
import { IFindMarginContractWatchParams } from "./types";

export const findMarginContractWatchApi = (
  params: IFindMarginContractWatchParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findMarginContractWatch${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
