import { memo } from "react";
import TickerInfo from "./TickerInfo";
import { Container, TickerInfoWrapper } from "./style";
import TickerSearch from "./TickerSearch";
import TickerName from "./TickerName";
import TickerIndex from "./TickerIndex";
import TickerDetailRealTime from "./TickerDetailRealTime";

function TickerInfoWidget() {
  return (
    <TickerInfoWrapper className="ticker-info-wrapper">
      <TickerSearch />
      <Container className="py-2 px-3">
        <TickerName />
        <TickerIndex />
        <div className="ticker-info-grid">
          <TickerDetailRealTime />
          <TickerInfo />
        </div>
      </Container>
    </TickerInfoWrapper>
  );
}

export default memo(TickerInfoWidget);
