import { STOCK_COLOR_CODEMap } from "domain/protoNew/auto_trading_pb";
import { getColorTicker } from "helper/utils";
import styled from "styled-components/macro";

export const WrapperTicker = styled.div`
  display: flex;
  align-items: center;
  column-gap: 50px;
`;

export const StockNameWrapper = styled.div`
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
`;

export const CellWrapper = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  position: relative;

  & > div {
    ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
`;

export const BlinkCellWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
  padding: 8px;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;
