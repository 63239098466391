import { useSelector } from "react-redux";
import numeral from "numeral";
import { TableHeaderCellContent } from "components/commons/CommonTable/styles";
import { summaryPortfolioSelector } from "modules/stock-order/redux/selectors";

const StockValueFooter = () => {
  const summaryPortfolio = useSelector(summaryPortfolioSelector);
  return (
    <>
      <TableHeaderCellContent
        align="flex-end"
        className="portfolio-marketValue"
        isFooter
      >
        {numeral(summaryPortfolio.totalStockValue).format("0,0")}
      </TableHeaderCellContent>
    </>
  );
};

export default StockValueFooter;
