import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  height: 40px;
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  text-align: center;
`;
export const Title = styled.div`
  width: 93%;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  padding: 10px 16px;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const BorderBox = styled.div`
  background-color: ${({ theme }) => theme.background2};
  padding: 24px;
  word-wrap: break-word;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;
export const Body = styled.div``;

export const ItemLeft = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey20};
`;
export const ItemRight = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .flex-1 {
    flex: 1;
  }
  gap: 8px;
  margin-top: 24px;
`;
export const WrapItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px;
`;

export const SelectBox = styled.div`
  width: 50%;
`;
export const Icon = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DetailRowCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextCheckBox = styled.div`
  margin: 10px 5px 10px 10px;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;

export const ColorGreen = styled.div`
  color: ${({ theme }) => theme.green50};
`;
export const ColorRed = styled.div`
  color: ${({ theme }) => theme.red50};
`;
export const ColorBlue = styled.a`
  color: ${({ theme }) => theme.brandPrimary};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;
