import styled from "styled-components/macro";
import { SizeType } from "components/commons/Spacer";

export const InputConntainer = styled.div<{
  size?: SizeType;
  isBorderActive?: boolean;
  radius?: number;
  textArea: boolean;
  isHeight28: boolean;
}>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.grey100};
  border: 1px solid
    ${({ theme, isBorderActive }) =>
      isBorderActive ? theme.brandPrimary : theme.grey70};
  overflow: hidden;
  border-radius: ${({ radius }) => (radius ? radius : 4)}px;
  height: ${({ theme, textArea, isHeight28 }) =>
    textArea
      ? theme.input.heightArea
      : isHeight28
      ? theme.input.height28
      : theme.input.height};
  display: flex;
  align-items: ${({ textArea }) => (textArea ? "flex-start" : "center")};
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
    padding: 0 8px;

    & > input {
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.grey100};
      outline: none;
      border-width: 0;
      color: ${({ theme }) => theme.textcolorWhite};
    }

    & > div {
      background: transparent !important;
      padding: 8px 0px !important;
      z-index: 99 !important;

      & > div {
        background: ${({ theme }) => theme.background2} !important;
        cursor: pointer;
        padding: 8px 12px !important;
        border-bottom: 1px solid ${({ theme }) => theme.neutral5};
        color: ${({ theme }) => theme.textcolorWhite};

        &:hover,
        &:focus,
        &::selection,
        &.isHighlighted {
          background: ${({ theme }) => theme.bgElevated4} !important;
        }
      }
    }
  }
`;
