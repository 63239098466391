import { IntlShape } from "react-intl";
import { HeaderCell, TableCellContent } from "./styles";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import numeral from "numeral";
import { ContentCell } from "components/commons";

export const statusTypeTransfer = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status0",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status1",
    }),
  },
];

const flexEnd = "flex-end";
const flexStart = "flex-start";
const center = "center";

export const dataColumnsDeposit = [
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferInternal.deposit.table.date" />
      </HeaderCell>
    ),
    accessor: "alloDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value).format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferInternal.deposit.table.fromSubAccoNo" />
      </HeaderCell>
    ),
    accessor: "subAccoNoFrom",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>{props.value}</TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferInternal.deposit.table.toSubAccoNo" />
      </HeaderCell>
    ),
    accessor: "subAccoNoTo",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>{props.value}</TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd} minWidth={"120px"}>
        <FormattedMessage id="transferInternal.deposit.table.amount" />
      </HeaderCell>
    ),
    accessor: "amount",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd} minWidth={"120px"}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="transferInternal.deposit.table.fee" />
      </HeaderCell>
    ),
    accessor: "feeAmt",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferInternal.deposit.table.content" />
      </HeaderCell>
    ),
    accessor: "remarks",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart} minWidth={"120px"}>
          <ContentCell textOverflow content={props.value} />
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferInternal.deposit.table.feePays" />
      </HeaderCell>
    ),
    accessor: "feeType",
    Cell: (props: any) => {
      let text = "";
      if (props?.value === "1") {
        text = "transferInternal.historyTransferBank.detail.typeAmount.value0";
      } else {
        text = "transferInternal.historyTransferBank.detail.typeAmount.value1";
      }
      return (
        <TableCellContent align={flexStart}>
          <FormattedMessage id={text} />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferInternal.deposit.table.statusVSD" />
      </HeaderCell>
    ),
    accessor: "vsdStatus",
    Cell: (props: any) => {
      return (
        <TableCellContent
          align={flexStart}
          color={props.value === "0" ? "yellow" : "white"}
        >
          <FormattedMessage
            id={`transferInternal.statusVsd.value${props.value}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="transferInternal.deposit.table.status" />
      </HeaderCell>
    ),
    accessor: "status",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>
          {" "}
          <FormattedMessage
            id={`transferInternal.statusDeposit.value${props.value}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="transferInternal.deposit.table.timeUpdate" />
      </HeaderCell>
    ),
    with: "120px",
    accessor: "updDateTime",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value).format("DD/MM/YYYY hh:mm:ss")}
        </TableCellContent>
      );
    },
  },
];
