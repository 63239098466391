import { all, put, call, takeLeading, select } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import {
  requestListHistoryTransferShare,
  listHistoryTransferShareSuccess,
  listHistoryTransferShareFailed,
  deleteTransferSecFailure,
  deleteTransferSecRequest,
  deleteTransferSecSuccess,
  updateCurrentPage,
} from "../redux/listHistory";
import {
  IDeleteTransferSecParam,
  IFindTransferSecHistoryParam,
} from "core/http/apis/transfer-share/types";
import {
  IDeleteTransferSecResponse,
  IFindTransferSecHistoryResponse,
} from "domain/models/TransferShare";
import {
  deleteTransferSec,
  findTransferSecHistory,
} from "core/http/apis/transfer-share";
import { RootState } from "redux/store";
import moment from "moment";

function* listHistoryTransferShareWorker(
  action: ReturnType<typeof requestListHistoryTransferShare>
) {
  try {
    const params: IFindTransferSecHistoryParam = action.payload;

    const response: IFindTransferSecHistoryResponse = yield call(
      findTransferSecHistory,
      params
    );

    yield put(listHistoryTransferShareSuccess(response?.data ?? []));
  } catch (error: any) {
    yield put(listHistoryTransferShareFailed());
    toast(error.code, "error", error.description, true);
  }
}

function* watchListHistoryTransferShare() {
  yield takeLeading(
    requestListHistoryTransferShare.type,
    listHistoryTransferShareWorker
  );
}

function* deleteTransferSecWorker(
  action: ReturnType<typeof deleteTransferSecRequest>
) {
  try {
    const params: IDeleteTransferSecParam = action.payload;

    const response: IDeleteTransferSecResponse = yield call(
      deleteTransferSec,
      params
    );

    const { fromDate, toDate, subAccoNoFrom, subAccoNoTo, secCd, status } =
      yield select(
        (state: RootState) => state.transferShare.listHistory.search
      );

    if (response?.statusCode === 0) {
      yield put(
        requestListHistoryTransferShare({
          fromDate: +moment(fromDate).format("YYYYMMDD"),
          toDate: +moment(toDate).format("YYYYMMDD"),
          subAccoNoFrom,
          subAccoNoTo,
          secCd,
          status,
          page: 0,
          rowPerPage: 30,
        })
      );
      yield put(updateCurrentPage(0));
    }

    yield put(deleteTransferSecSuccess(response));
  } catch (error: any) {
    yield put(deleteTransferSecFailure());
    toast(error.code, "error", error.description, true);
  }
}

function* deleteTransferSecWatcher() {
  yield takeLeading(deleteTransferSecRequest.type, deleteTransferSecWorker);
}

export default function* transferShareHistorySaga() {
  yield all([watchListHistoryTransferShare(), deleteTransferSecWatcher()]);
}
