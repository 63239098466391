import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "components/commons/Toast";
import { getSecQuotesDetail } from "core/grpc";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchOrganizationFundamental,
  fetchOrganizationFundamentalFailure,
  fetchOrganizationFundamentalSuccess,
} from "../redux/tickerInfo";

function* fetchOrganizationFundamentalWorker(action: PayloadAction<string>) {
  try {
    const res: SecQuotesDetailResponse.AsObject = yield call(
      getSecQuotesDetail,
      action.payload
    );

    yield put(fetchOrganizationFundamentalSuccess(res.secdetailinfo));
  } catch (e: any) {
    yield put(fetchOrganizationFundamentalFailure(e));
    toast("defaultErr.defaultErr", "error");
  }
}

function* fetchOrganizationFundamentalWatcher() {
  yield takeLatest(
    fetchOrganizationFundamental.type,
    fetchOrganizationFundamentalWorker
  );
}

export default function* tickerInfoSaga() {
  yield all([fetchOrganizationFundamentalWatcher()]);
}
