import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  background: ${({ theme }) => theme.background2};
`;
export const Body = styled.div`
  padding: 24px 24px 0px;
  gap: 10px;
`;

export const TitleContent = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.grey0};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;
  margin-top: 5px;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  gap: 16px;
  flex: 1;
`;

export const Label = styled.div<{ isTextRight?: boolean; color?: string }>`
  color: ${({ theme, color }) => (color ? color : theme.grey20)};
  flex: 1;
  width: 100%;
  text-align: ${({ isTextRight }) => (isTextRight ? `right` : `left`)};

  font-weight: 400;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: 20px;
`;
