import React, { useMemo } from "react";
import { StyledTooltip, TextColor, Wrapper } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconDelete } from "assets/image/svg/ic_remove.svg";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { rootCategoriesSelector } from "modules/categories/redux/selectors";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { WATCH_LIST_FIXED_TABS } from "modules/categories/constant";
import {
  STOCK_COLOR_CODE,
  STOCK_COLOR_CODEMap,
} from "domain/protoNew/auto_trading_pb";
import { TickerCode } from "components/commons/ClickableTicker/styles";
import { setVisibleTickerDetailsModal } from "modules/market/redux";
import { EMarketCodeNew, SecType } from "helper/consts";
import GlobalData from "helper/globalData";

interface Props {
  ticker: string;
  className?: string;
  color: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
  onRemoveTicker?: () => void;
}

const SHORT_TICKER_MAX_LENGTH = 6;

const renderModalType = (tickerType?: number, tickerMarketCd?: number) => {
  if (tickerMarketCd && tickerMarketCd === EMarketCodeNew.DER_IDX) {
    return "derivative";
  } else {
    if (tickerType === SecType.CW) {
      return "coverWarrant";
    } else {
      return "default";
    }
  }
};

function TickerCell({
  ticker,
  className = "",
  color = STOCK_COLOR_CODE.BASIC_PRICE,
  onRemoveTicker,
}: Props) {
  const { isDeleteTicker, currentListCode } = useSelector(
    rootCategoriesSelector
  );
  const dispatch = useDispatch();
  const isActiveTab = useMemo(() => {
    if (
      currentListCode === WATCH_LIST_FIXED_TABS.PUT_THROUGH ||
      currentListCode === WATCH_LIST_FIXED_TABS.COVER_WARRANT
    )
      return false;

    return true;
  }, [currentListCode]);

  const isLongTicker =
    !!ticker && ticker.length > SHORT_TICKER_MAX_LENGTH && isActiveTab;

  const handleClickTicker = (ticker: string) => {
    const tickerInfo = GlobalData.getTickerInfoNew(ticker);
    const tickerType = tickerInfo?.sectype;
    const tickerMarketCd = tickerInfo?.marketcd;
    dispatch(
      setVisibleTickerDetailsModal({
        visible: true,
        ticker,
        modalType: renderModalType(tickerType, tickerMarketCd),
      })
    );
  };

  return (
    <Wrapper isLongTicker={isLongTicker}>
      <OverlayTrigger
        placement="bottom-start"
        overlay={
          <StyledTooltip id="tooltip">
            <FormattedMessage id="categories.ticketTooltip" />
          </StyledTooltip>
        }
      >
        <TextColor
          className={classNames("text-right ticker-name", className)}
          textcolor={color}
        >
          <TickerCode
            className="ticker-code"
            onClick={() => handleClickTicker(ticker)}
          >
            <span>{ticker}</span>
          </TickerCode>
        </TextColor>
      </OverlayTrigger>
      {isDeleteTicker && (
        <div className="delete-icon-container">
          <IconDelete className="icon-delete" onClick={onRemoveTicker} />
        </div>
      )}
    </Wrapper>
  );
}

export default React.memo(TickerCell) as React.FC<Props>;
