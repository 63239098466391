import { Button, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import { OptionSelectType } from "components/commons/Select";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  searchMrgContractDebitRequest,
  updateFilter,
} from "modules/mrg-contract-debit/redux";
import {
  mrgContractDebitFilterSelector,
  mrgContractDebitLoadingSelector,
} from "modules/mrg-contract-debit/selectors";
import { memo, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const statusOptionsIntl = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.status1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.status2",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.status3",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.status4",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.status5",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.status.status6",
    }),
  },
];

const debitTypeOptionsIntl = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.debitType.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "mrgContractDebit.filter.debitType.debitType1",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();
  const listAcc = GetAccoList(GetAccountType.MARGIN, EntrustBusinessCode.All);

  // selector
  const { accNo, debitType, status } = useSelector(
    mrgContractDebitFilterSelector
  );
  const loading = useSelector(mrgContractDebitLoadingSelector);

  // memo
  const statusOptions = useMemo(() => statusOptionsIntl(intl), [intl]);
  const debitTypeOptions = useMemo(() => debitTypeOptionsIntl(intl), [intl]);
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    list.unshift({
      label: intl.formatMessage({ id: "mrgContractDebit.filter.accNo.all" }),
      value: "",
    });

    return list;
  }, [listAcc, intl]);

  // function
  const changeAccountHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "accNo", value: e?.value ?? "" }));
  };

  const changeDebitTypeHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "debitType", value: e?.value ?? "" }));
  };

  const changeStatusHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "status", value: e?.value ?? "" }));
  };

  const handleSearch = () => {
    dispatch(
      searchMrgContractDebitRequest({
        debitType: debitType ? +debitType : 0,
        status: status ? +status : 1,
        accNo: accNo ?? "",
      })
    );
  };

  // effect

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "mrgContractDebit.filter.accNo.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={accountOptions[0]}
            options={accountOptions}
            onChange={changeAccountHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "mrgContractDebit.filter.debitType.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={debitTypeOptions[0]}
            options={debitTypeOptions}
            onChange={changeDebitTypeHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "mrgContractDebit.filter.status.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={statusOptions[0]}
            options={statusOptions}
            onChange={changeStatusHandler}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter "
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "mrgContractDebit.filter.search" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
