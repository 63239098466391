import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonDelete,
  ColorGray,
  ColorGreen,
  ColorRed,
  ColorYellow,
  Container,
  HeaderCell,
  Icon,
  TableCellContent,
} from "./styles";

import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { ModalConfirm } from "components/commons";
import { updateCbotBondTransfer } from "core/http/apis/place-order-transfer-bonds";
import { convertNumberToDateFormat } from "helper/utils";
import { getListTransferBondRequest } from "modules/place-order-transfer-bonds/redux";
import { listTransferBondSelector } from "modules/place-order-transfer-bonds/selectors";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "components/commons/Toast";
import numeral from "numeral";

function Table() {
  const data = useSelector(listTransferBondSelector);

  const dispatch = useDispatch();

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [dataDelete, setDataDelete] = useState<any>();
  const [newData, setNewData] = useState<any[]>([]);
  const fromDate = new Date();
  const toDate = new Date();
  const intl = useIntl();

  const center = "center";

  const handleDelete = (item: any) => {
    setVisibleModalConfirm(true);
    setDataDelete(item);
  };

  useEffect(() => {
    if (data) {
      const newData = data?.map((item: any) => {
        return {
          ...item,
          price: item?.transferAmt / item?.quantity,
        };
      });
      setNewData(newData);
    }
  }, [data]);

  const handleSubmit = async () => {
    const res = await updateCbotBondTransfer({
      actionMode: "delete",
      alloDate: dataDelete?.alloDate ? `${dataDelete?.alloDate}` : "",
      refNo: dataDelete?.refNo ? `${dataDelete?.refNo}` : "",
      subAccoNoFrom: dataDelete?.fromSubAccoNo ? dataDelete?.fromSubAccoNo : "",
      subAccoNoTo: dataDelete?.toSubAccoNo ? dataDelete?.toSubAccoNo : "",
      bondCd: dataDelete?.bondCd ? dataDelete?.bondCd : "",
      qty: dataDelete?.quantity ? `${dataDelete?.quantity}` : "",
      parAmt: dataDelete?.parAmt,
      transferAmt: dataDelete?.transferAmt,
      feeAmt: dataDelete?.feeAmt,
      taxAmt: dataDelete?.taxAmt,
      feePayer: dataDelete?.feePayer,
      taxPayer: dataDelete?.taxPayer,
      remarks: `${dataDelete?.remarks}`,
      timeStamp: `${dataDelete?.lastUpdTime}`,
    });
    if (res?.statusCode === 1) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.placeBook.deleteFalure",
        })}`,
        "error"
      );
    }
    if (res?.statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.placeBook.deleteDone",
        })}`,
        "success"
      );
      dispatch(
        getListTransferBondRequest({
          fromDate: moment(fromDate.setDate(toDate.getDate() - 30)).format(
            "YYYYMMDD"
          ),
          toDate: moment(toDate).format("YYYYMMDD"),
          status: "-1",
        })
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.cancle" />
          </HeaderCell>
        ),
        accessor: "cancel",
        minWidth: 80,
        Cell: (props: any) => {
          const rowItem = props?.row?.original;
          return (
            <>
              {rowItem.status === 0 && (
                <Icon>
                  <ButtonDelete onClick={() => handleDelete(rowItem)}>
                    <IconTrash width={16} height={16} />
                  </ButtonDelete>
                </Icon>
              )}
            </>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.alloDate" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.refNo" />
          </HeaderCell>
        ),
        minWidth: 80,
        accessor: "refNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={"left"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 120,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>
              {props.value === 0 ? (
                <ColorYellow>
                  <FormattedMessage id="placeOrderTransferBonds.infomationPlace.delay" />
                </ColorYellow>
              ) : props.value === 1 ? (
                <ColorGreen>
                  <FormattedMessage id="placeOrderTransferBonds.infomationPlace.accep" />
                </ColorGreen>
              ) : props.value === 2 ? (
                <ColorRed>
                  <FormattedMessage id="placeOrderTransferBonds.infomationPlace.deny" />
                </ColorRed>
              ) : (
                <ColorGray>
                  <FormattedMessage id="placeOrderTransferBonds.infomationPlace.canceled" />
                </ColorGray>
              )}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.toSubaccoNo" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "toSubAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"left"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.toCustName" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "toCustName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.bonCd" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "bondCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.quantity" />
          </HeaderCell>
        ),
        minWidth: 80,
        accessor: "quantity",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.parAmt" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "parAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.price" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "price",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.transferAmt" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "transferAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.feeAmt" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "feeAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"left"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.feePayer" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "feePayer",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>
              {props.value === 1 ? (
                <FormattedMessage id="placeOrderTransferBonds.infomationPlace.personTransfer" />
              ) : (
                <FormattedMessage id="placeOrderTransferBonds.infomationPlace.personGet" />
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"right"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.taxtAmt" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "taxAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"left"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.taxPayer" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "taxPayer",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>
              {props.value === 1 ? (
                <FormattedMessage id="placeOrderTransferBonds.infomationPlace.personTransfer" />
              ) : (
                <FormattedMessage id="placeOrderTransferBonds.infomationPlace.personGet" />
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={"left"}>
            <FormattedMessage id="placeOrderTransferBonds.placeBook.remarks" />
          </HeaderCell>
        ),
        minWidth: 260,
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="placeOrderTransferBondsTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? newData : []}
        enableInfinity={true}
        scrollableTarget={"placeOrderTransferBondsTable"}
        isHasFooter={false}
      />

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={handleSubmit}
        title={intl.formatMessage({
          id: "placeOrderTransferBonds.infomationPlace.notiTitle",
        })}
        content={`${intl.formatMessage({
          id: "placeOrderTransferBonds.infomationPlace.notiAfter",
        })}`
          .concat(dataDelete?.bondCd)
          .concat(
            intl.formatMessage({
              id: "placeOrderTransferBonds.infomationPlace.notiMiddle",
            })
          )
          .concat(dataDelete?.quantity)
          .concat(
            intl.formatMessage({
              id: "placeOrderTransferBonds.infomationPlace.notiBefore",
            })
          )}
        handleCloseModal={() => setVisibleModalConfirm(false)}
      ></ModalConfirm>
    </Container>
  );
}

export default memo(Table);
