import React, { useEffect, useRef } from "react";
import { DatePicker, Select, Spacer } from "components/commons";
import { FormattedMessage, IntlShape } from "react-intl";
import { useIntl } from "react-intl";
import { methods } from ".";
import { FormControl, FormTitle } from "../styles";
import InputPrice from "components/commons/InputPrice";
import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeExpiredDate,
  onChangeLimitOffset,
  onChangeMatMethod,
  onChangeMinMaxPrice,
  onChangeOptVal,
  onChangeOptValType,
} from "modules/stock-order/redux/placeOrder";
import {
  onChangeLimitOffsetByEnter,
  onChangeOptValByEnter,
  onChangeVolumeByEnter,
} from "modules/stock-order/redux";
import numeral from "numeral";
import { tradeDateSelector } from "modules/auth/selectors";
import moment from "moment";

const regPrice = /^\d+(\.\d{0,9})?$/;
const pattern = new RegExp(/^\d+(\.\d{0,9})?$/);

const orderBookSelector = (state: RootState) => state.stockOrder;

const placeOrderSelector = createSelector(
  orderBookSelector,
  (state) => state.placeOrder
);

interface Props {
  ticker: string;
  onSubmit: () => void;
}

const stopIntervalList = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.byValue",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.byPercent",
    }),
  },
];

const TrailingStopOrder: React.FC<Props> = React.memo(
  ({ ticker, onSubmit }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
      root: { changeVolumeByEnter, changeOptValByEnter },
    } = useSelector(orderBookSelector);
    const refOptVal = useRef<HTMLInputElement>(null);
    const refLimitOffset = useRef<HTMLInputElement>(null);

    const {
      buySellType,
      limitOffset,
      optVal,
      matMethod,
      optValType,
      minBuyPrice,
      maxSellPrice,
      expiredDate,
      errorValidateOtpVal,
      errorValidateLimitOffset,
      errorValidateMinBuyPrice,
      errorValidateMaxSellPrice,
    } = useSelector(placeOrderSelector);
    const tradeDate = useSelector(tradeDateSelector);

    useEffect(() => {
      if (changeVolumeByEnter) {
        refOptVal.current?.focus();
      }
    }, [changeVolumeByEnter]);

    useEffect(() => {
      if (changeOptValByEnter) {
        refLimitOffset.current?.focus();
      }
    }, [changeOptValByEnter]);

    const onBlurEnter = () => {
      dispatch(onChangeVolumeByEnter(false));
      dispatch(onChangeOptValByEnter(false));
      dispatch(onChangeLimitOffsetByEnter(false));
    };

    const handleKeyDown = (e: any, typeInput: string) => {
      if (e.key === "Enter" && typeInput === "optVal") {
        dispatch(onChangeOptValByEnter(true));
      }
      if (e.key === "Enter" && typeInput === "limitOffset") {
        refLimitOffset.current?.blur();
        dispatch(onChangeLimitOffsetByEnter(true));
        onSubmit();
      }
    };

    const handleChangeOtpVal = (value: string | number) => {
      let text = "";
      const textValue =
        numeral(value).value() !== null ? parseFloat(value?.toString()) : 0;
      if ((textValue * 1000) % 10 !== 0) {
        text = optVal;
      } else {
        text = value?.toString();
      }
      // dispatch(onChangeOptVal(text));
      if (pattern.test(text)) {
        dispatch(onChangeOptVal(text));
      }
      if (value === "") {
        dispatch(onChangeOptVal(""));
      }
    };

    return (
      <>
        <div className="d-flex align-items-center">
          <FormTitle>
            <FormattedMessage id="widgets.placeOrder.method" />
          </FormTitle>
          <FormControl>
            <Select
              value={methods(intl).find((x) => x.value === matMethod)}
              className="flex-fill"
              options={methods(intl)}
              onChange={(item) =>
                item && item.value && dispatch(onChangeMatMethod(item.value))
              }
            />
          </FormControl>
        </div>
        <Spacer size="s" />
        <div className="d-flex align-items-center">
          <FormTitle>
            <FormattedMessage id="widgets.placeOrder.stopInterval" />
          </FormTitle>
          <FormControl>
            <Select
              value={stopIntervalList(intl).find(
                (item) => item.value === optValType?.toString()
              )}
              className="flex-fill"
              options={stopIntervalList(intl)}
              onChange={(item) => {
                if (item) {
                  dispatch(onChangeOptValType(Number(item.value)));
                }
              }}
            />
          </FormControl>
        </div>
        <Spacer size="s" />
        <InputPrice
          innerRef={refOptVal}
          suggestions={[]}
          value={optVal === 0 ? "" : optVal}
          onChange={(value) => handleChangeOtpVal(value)}
          onBlurEnter={onBlurEnter}
          onKeyDown={(e) => handleKeyDown(e, "optVal")}
          label={
            optValType === 1
              ? intl.formatMessage({
                  id: "widgets.placeOrder.value",
                })
              : intl.formatMessage({
                  id: "widgets.placeOrder.percent",
                })
          }
          error={errorValidateOtpVal}
          partern={regPrice}
          decimal
          placeholder={
            optValType === 1
              ? intl.formatMessage({
                  id: "formPlaceholder.inputOptValByValue",
                })
              : intl.formatMessage({
                  id: "formPlaceholder.inputOptValByPercent",
                })
          }
          ticker={ticker}
          containerId="place-order-container"
          isOneNumberAfterComma
        />

        <InputPrice
          innerRef={refLimitOffset}
          suggestions={[]}
          value={limitOffset === 0 ? "" : limitOffset}
          onChange={(value) => {
            dispatch(onChangeLimitOffset(value));
          }}
          onBlurEnter={onBlurEnter}
          onKeyDown={(e) => handleKeyDown(e, "limitOffset")}
          label={intl.formatMessage({ id: "widgets.placeOrder.slidingMargin" })}
          error={errorValidateLimitOffset}
          partern={regPrice}
          decimal
          placeholder={intl.formatMessage({
            id: "formPlaceholder.inputLimitOffset",
          })}
          ticker={ticker}
          containerId="place-order-container"
          isOneNumberAfterComma
        />

        <InputPrice
          innerRef={refOptVal}
          suggestions={[]}
          value={buySellType === "buy" ? minBuyPrice : maxSellPrice}
          onChange={(value) => {
            dispatch(onChangeMinMaxPrice({ price: value, type: buySellType }));
          }}
          onBlurEnter={onBlurEnter}
          onKeyDown={(e) => handleKeyDown(e, "optVal")}
          label={
            buySellType === "buy"
              ? intl.formatMessage({
                  id: "widgets.placeOrder.minBuyPrice",
                })
              : intl.formatMessage({
                  id: "widgets.placeOrder.maxSellPrice",
                })
          }
          error={
            buySellType === "buy"
              ? errorValidateMinBuyPrice
              : errorValidateMaxSellPrice
          }
          partern={regPrice}
          decimal
          placeholder={intl.formatMessage({
            id: "formPlaceholder.inputOptValByValue",
          })}
          ticker={ticker}
          containerId="place-order-container"
          isOneNumberAfterComma
        />

        <div className="d-flex align-items-center">
          <FormTitle>
            <FormattedMessage id="widgets.placeOrder.toDate" />
          </FormTitle>
          <FormControl>
            <DatePicker
              selected={new Date(expiredDate)}
              onChange={(e: Date) => dispatch(onChangeExpiredDate(e))}
              fullWidth
              minDate={moment(tradeDate, "YYYYMMDD").toDate()}
            />
          </FormControl>
        </div>
        <Spacer size="s" />
      </>
    );
  }
);
export default TrailingStopOrder;
