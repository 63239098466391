import styled from "styled-components/macro";

export const DetailWrapper = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  height: 100%;
  color: ${({ theme }) => theme.grey20};
  background: ${({ theme }) => theme.background1};
`;

export const DetailCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

export const DetailRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1500px) {
    gap: 6px;
  }
`;
export const DetailRowButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowPaddingTB = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
  @media (max-width: 1500px) {
    gap: 6px;
  }
`;
export const DetailRowTextTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1500px) {
    gap: 6px;
  }
`;
export const DetailLabel = styled.div`
  min-width: 120px;
  color: ${({ theme }) => theme.grey20};
  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const DetailInputWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  text-align: right;
  @media (max-width: 1500px) {
    display: flex;
    justify-content: left;
    text-align: left;
  }
`;
export const DetailLabelAlert = styled.div`
  font-family: "Roboto";
  font-style: normal;
  display: flex;
  align-items: center;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};
`;
export const DetailRowTop = styled.div``;
export const DetailRowBottom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const DetailRowCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextCheckBox = styled.div`
  margin: 10px 5px 10px 10px;
`;
export const ColorGray = styled.span`
  color: ${({ theme }) => theme.grey40};
`;
export const LinkContract = styled.a<{
  href: string;
}>`
  color: ${({ theme }) => theme.brandPrimary};
`;
