/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Content,
  Wrap,
  WrapItem,
  Title,
  WrapMulti,
  WrapContent,
  WrapChart2Item,
  TitleChart2,
} from "./styles";
import { FormattedMessage } from "react-intl";
import ChartShare from "./chartShare";
import {
  getChartAssetApi,
  getChartSecuritiesApi,
} from "core/http/apis/assetsIncurred";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectFilteAssetsEffect } from "modules/assetsIncurred/selectors";
import { useIntl } from "react-intl";
import { selectUserInfo } from "modules/auth/selectors";
import { isEmpty } from "lodash";
import { RootState } from "redux/store";
import themes from "themes/abstracts/_themes";

const ContentBottomAssetsEffect = () => {
  const intl = useIntl();
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const listColor = [
    themes[themeType].solidBlue,
    themes[themeType].solidYellow,
    themes[themeType].solidRed,
    themes[themeType].solidGreen,
    themes[themeType].solidOrange,
    themes[themeType].solidViolet,
    themes[themeType].solidWhite,
    themes[themeType].solidNavy,
    themes[themeType].solidFlash,
    themes[themeType].solidGrey,
  ];
  const { typeAccount, custNo, subAccoNo } = useSelector(
    selectFilteAssetsEffect
  );
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Inquiry
  );
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const userInfo = useSelector(selectUserInfo);

  const result =
    userInfo && userInfo?.entrustList
      ? userInfo?.entrustList
          .reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.custNo === o.custNo)) {
              unique.push(o);
            }
            return unique;
          }, [])
          .map((e: any) => {
            if (e?.businessCd === 0 || e?.businessCd === 9) {
              return e;
            }
          })
      : [];

  // set chart cổ phiếu
  const [dataChartSec, setdataChartSec] = useState<any>({
    labels: [],
    datasets: [],
  });

  const formmatDataChartSec = (data: any) => {
    if (!isEmpty(data)) {
      const labels = [];
      const dataset = [];
      let total = 0;
      for (var key in data) {
        labels.push(data[key].secCd);
        if (key === data[key].secCd) {
          dataset.push(data[key].amount * data[key].lastPrice * 1000);
          total += data[key].amount * data[key].lastPrice * 1000;
        }
      }
      setdataChartSec({
        labels,
        datasets: [
          {
            data: dataset ? dataset.map((e: any) => (e / total) * 100) : [],
            backgroundColor: listColor,
            borderColor: listColor,
            borderWidth: 1,
          },
        ],
      });
    }
  };

  // set chart Acc
  const [dataChartAcc, setdataChartAcc] = useState<any>({
    labels: [],
    datasets: [],
  });

  const formmatDataChartAcc = (data: any) => {
    if (!isEmpty(data)) {
      const labels = [];
      let dataset: any[] = [];
      const listIndustries: any = [];
      let total = 0;
      for (var key in data) {
        const checkDuplicate = listIndustries.findIndex(
          (e: any) => e === data[key].industries
        );
        if (checkDuplicate === -1) {
          listIndustries.push(data[key].industries);
          labels.push(data[key].industriesNameVI ?? `${data[key].industries}`);
        }
        total += data[key].amount * data[key].lastPrice * 1000;
      }
      listIndustries.map((e: any, index: any) => {
        let totalCode = 0;
        for (var key in data) {
          if (data[key].industries === e) {
            totalCode += data[key].amount * data[key].lastPrice * 1000;
          }
        }
        dataset[index] = totalCode;
      });
      setdataChartAcc({
        labels,
        datasets: [
          {
            data: dataset ? dataset.map((e: any) => (e / total) * 100) : [],
            backgroundColor: listColor,
            borderColor: listColor,
            borderWidth: 1,
          },
        ],
      });
    }
  };

  // set chart Vn30
  const [dataChartVn30, setdataChartVn30] = useState<any>({
    labels: [],
    datasets: [],
  });

  const formmatDataChartVn30 = (data: any) => {
    if (!isEmpty(data)) {
      const labels = [];
      let dataset: any[] = [];
      const listIndustries: any = [];
      let total = 0;
      for (var key in data) {
        const checkDuplicate = listIndustries.findIndex(
          (e: any) => e === data[key].industries
        );
        if (checkDuplicate === -1 && data[key]?.vn30 === true) {
          listIndustries.push(data[key].industries);
          labels.push(data[key].industriesNameVI ?? `${data[key].industries}`);
        }
        if (data[key]?.vn30 === true) {
          total += data[key].amount * data[key].lastPrice * 1000;
        }
      }
      listIndustries.map((e: any, index: any) => {
        let totalCode = 0;
        for (var key in data) {
          if (data[key].industries === e && data[key]?.vn30 === true) {
            totalCode += data[key].amount * data[key].lastPrice * 1000;
          }
        }
        dataset[index] = totalCode;
      });
      if (!isEmpty(dataset)) {
        setdataChartVn30({
          labels,
          datasets: [
            {
              data: dataset ? dataset.map((e: any) => (e / total) * 100) : [],
              backgroundColor: listColor,
              borderColor: listColor,
              borderWidth: 1,
            },
          ],
        });
      }
    }
  };

  // set chart Asset
  const [dataChartAsset, setdataChartAsset] = useState<any>({
    labels: [],
    datasets: [],
  });

  const formmatDataChartAsset = (data: any) => {
    if (!isEmpty(data)) {
      const labels = [
        intl.formatMessage({
          id: "assets.effective.bottom.chart3.debt",
        }),
        intl.formatMessage({
          id: "assets.effective.bottom.chart3.assets",
        }),
      ];
      const debt = (data?.debt / (data?.debt + data?.asset)) * 100;
      const asNewNetAsset = (data?.asset / (data?.debt + data?.asset)) * 100;

      const datasets = [
        {
          data: [debt, asNewNetAsset],
          backgroundColor: listColor,
          borderColor: listColor,
          borderWidth: 1,
        },
      ];
      setdataChartAsset({
        labels,
        datasets,
      });
    }
  };

  // set chart Asset allocation
  const [dataChartAssetAllocation, setdataChartAssetAssetAllocation] =
    useState<any>({
      labels: [],
      datasets: [],
    });

  const formmatDataChartAssetAssetAllocation = (data: any, dataAsset: any) => {
    if (!isEmpty(data) && !isEmpty(dataAsset)) {
      const labels = [
        intl.formatMessage({
          id: "assets.effective.bottom.chart4.money",
        }),
        intl.formatMessage({
          id: "assets.effective.bottom.chart4.sec",
        }),
        intl.formatMessage({
          id: "assets.effective.bottom.chart4.Bonds",
        }),
        intl.formatMessage({
          id: "assets.effective.bottom.chart4.Warranties",
        }),
        intl.formatMessage({
          id: "assets.effective.bottom.chart4.Derivative",
        }),
      ];

      let money = 0;
      let sec = 0;
      let Bonds = 0;
      let Warranties = 0;
      let Derivative = 0;

      for (var key in data) {
        if (
          data[key].secType === 1 ||
          data[key].secType === 2 ||
          data[key].secType === 4
        ) {
          sec += data[key].amount * data[key].lastPrice * 1000;
        }
        if (data[key].secType === 3 || data[key].secType === 6) {
          Bonds += data[key].amount * data[key].lastPrice * 1000;
        }
        if (data[key].secType === 5) {
          Warranties += data[key].amount * data[key].lastPrice * 1000;
        }
      }
      money += dataAsset?.cash ?? 0;
      Derivative += dataAsset?.derAsset ?? 0;
      const total = [money, sec, Bonds, Warranties, Derivative].reduce(
        (a, b) => a + b,
        0
      );

      const datasets = [
        {
          data: [money, sec, Bonds, Warranties, Derivative].map(
            (e: any) => (e / total) * 100
          ),
          backgroundColor: listColor,
          borderColor: listColor,
          borderWidth: 1,
        },
      ];
      setdataChartAssetAssetAllocation({
        labels,
        datasets,
      });
    }
  };

  const [data, setdata] = useState();

  const getDataApi = async () => {
    const params = {
      custNo:
        typeAccount !== "1" ? (custNo ? custNo : result?.[0]?.custNo) : "",
      subAccoNo:
        typeAccount === "1"
          ? subAccoNo !== ""
            ? subAccoNo
            : accountOptions?.[0]?.value
          : null,
    };
    const res: any = await getChartSecuritiesApi(params);
    if (res?.statusCode === 0) {
      setdata(res?.data);
    }
  };

  const [dataApiChartAsset, setdataApiChartAsset] = useState();
  const callChartAssetApiApi = async () => {
    const params = {
      custNo:
        typeAccount !== "1" ? (custNo ? custNo : result?.[0]?.custNo) : "",
      subAccoNo:
        typeAccount === "1"
          ? subAccoNo !== ""
            ? subAccoNo
            : accountOptions?.[0]?.value
          : null,
    };
    const res: any = await getChartAssetApi(params);
    if (res?.statusCode === 0) {
      setdataApiChartAsset(res?.data);
    }
  };

  useEffect(() => {
    getDataApi();
    callChartAssetApiApi();
  }, [typeAccount, subAccoNo, custNo]);

  useEffect(() => {
    formmatDataChartSec(data);
    formmatDataChartAcc(data);
    formmatDataChartVn30(data);
    if (!isEmpty(dataApiChartAsset)) {
      formmatDataChartAsset(dataApiChartAsset);
    }
    if (!isEmpty(dataApiChartAsset) && !isEmpty(data)) {
      formmatDataChartAssetAssetAllocation(data, dataApiChartAsset);
    }
  }, [data, dataApiChartAsset]);

  return (
    <Container>
      <Content>
        <Wrap>
          <WrapItem>
            <Title>
              <FormattedMessage id={"assets.effective.bottom.chart1.title"} />
            </Title>
            <Title />
            <ChartShare data={dataChartSec} />
          </WrapItem>
        </Wrap>

        <WrapMulti>
          <WrapItem>
            <Title>
              <FormattedMessage id={"assets.effective.bottom.chart2.title"} />
            </Title>
            <WrapContent>
              <WrapChart2Item>
                <WrapItem className="p-0">
                  <TitleChart2 className="p-0">
                    <FormattedMessage
                      id={"assets.effective.bottom.chart2.titleLeft"}
                    />
                  </TitleChart2>
                  <ChartShare data={dataChartAcc} />
                </WrapItem>
              </WrapChart2Item>
              <WrapChart2Item>
                <WrapItem className="p-0">
                  <TitleChart2 className="p-0">
                    <FormattedMessage
                      id={"assets.effective.bottom.chart2.titleRight"}
                    />
                  </TitleChart2>
                  <ChartShare data={dataChartVn30} />
                </WrapItem>
              </WrapChart2Item>
            </WrapContent>
          </WrapItem>
        </WrapMulti>

        <Wrap>
          <WrapItem>
            <Title>
              <FormattedMessage id={"assets.effective.bottom.chart3.title"} />
            </Title>
            <Title />

            <ChartShare data={dataChartAsset} />
          </WrapItem>
        </Wrap>
        <Wrap>
          <WrapItem>
            <Title>
              <FormattedMessage id={"assets.effective.bottom.chart4.title"} />
            </Title>
            <Title />
            <ChartShare data={dataChartAssetAllocation} />
          </WrapItem>
        </Wrap>
      </Content>
    </Container>
  );
};

export default ContentBottomAssetsEffect;
