import { FormattedMessage } from "react-intl";
import TickerItem from "./TickerItem";
import { Body, Header, Table } from "./styles";
import { useSelector } from "react-redux";
import { historyListSelector } from "modules/stock-order-der/redux/selectors";
function HistoryList() {
  const historyList = useSelector(historyListSelector);
  return (
    <Table>
      <Header>
        <FormattedMessage id="widgets.volumeAndTime.matchHistory" />
      </Header>
      <Body>
        {historyList.map((ticker) => (
          <TickerItem key={ticker.id} ticker={ticker} />
        ))}
      </Body>
    </Table>
  );
}

export default HistoryList;
