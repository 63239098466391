import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getListMoneyStatementRequest } from "../redux";
import { AccountStatementContent } from "./AccountStatement/components/AccountStatementContent";
import Tabs from "./AccountStatement/components/Tabs";
import { Container } from "./styles";

const AccountStatement: FC = () => {
  const dispatch = useDispatch();

  const [valueTab, setValueTab] = useState("");

  const d = new Date();

  const fromDate = new Date(d.getFullYear(), d.getMonth(), 1);

  const toDate = new Date();

  const onChangeTab = (values: any) => {
    setValueTab(values);
  };

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Inquiry
  );

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  useEffect(() => {
    const params = {
      filter: {
        fil_accountType: "0",
        fil_subAccoNo: parseOptionAccount ? parseOptionAccount[0]?.value : "",
        fil_fromDate: moment(fromDate).format("YYYYMMDD"),
        fil_toDate: moment(toDate).format("YYYYMMDD"),
        p_BEGIN_AMT: 0,
        p_LAST_AMT: 0,
        page: 0,
        rowPerPage: 30,
      },
    };

    dispatch(getListMoneyStatementRequest(params));
  }, []);
  return (
    <Container isVisibleSideCard={false}>
      <Tabs onClick={onChangeTab} />
      <AccountStatementContent activeTab={valueTab} />
    </Container>
  );
};

export default AccountStatement;
