import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FormattedMessage } from "react-intl";
import numeral from "numeral";
import classNames from "classnames";
import { TextColor, StyledTooltip } from "./styles";
import { PriceCellProps } from "modules/categories/types";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import {
  clearData,
  handleUsingPriceSelected,
  onBuySellType,
  onFilter,
} from "modules/stock-order/redux/placeOrder";
import { EMarketCodeNew, MenuExtraTab, SecType } from "helper/consts";
import { BlinkColor, BlinkTime } from "AppConfig";
import GlobalData from "helper/globalData";
import {
  setIsOpenExtra,
  setTabExtraActive,
  setVisibleContainerExtra,
  setVisibleIconExtra,
} from "modules/system/redux/common";
import {
  DerTradeTypeEnum,
  updateCurrentTicker,
  updatePlaceOrderDerPrice,
  updatePlaceOrderDerType,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import { toggleModalLogin } from "modules/auth/redux";

function PriceCell({
  price,
  className = "",
  color = "",
  isBlink = true,
  ticker = "",
  isPreventSetCommand = false,
  isRate = false,
  isAsk = false,
  isAvgPrice = false,
}: PriceCellProps) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [background, setBackground] = useState("");
  const countMounted = useRef(0);
  const oldData = useRef("");

  useEffect(() => {
    if (countMounted.current < 2) {
      countMounted.current++;
      return;
    }

    if (!isBlink) {
      return;
    }

    // Format đúng xong mới so sánh
    let newData = numeral(price).format("0.00");
    if (isRate) {
      newData = numeral(price).format("0.0") + "%";
    }

    if (oldData.current === newData) return;
    setBackground(BlinkColor);

    const resetStatus = setTimeout(() => setBackground(""), BlinkTime);
    return () => {
      oldData.current = newData;
      setBackground("");
      clearTimeout(resetStatus);
    };
  }, [price, color, isBlink]);

  const displayText = useMemo(() => {
    if (typeof price === "string") {
      return price;
    }

    if (isNaN(price) || price === 0) {
      return "";
    }

    // Tỷ lệ => Format 2 số thập phân
    if (isRate) {
      return numeral(price).format("0.0") + "%";
    }

    // Default giá / 1000
    return numeral(price).format("0.00");
  }, [price]);

  const getPriceRoundFlowStep = () => {
    let priceRound = 0;
    // Làm tròn chưa
    let isCal = false;
    const tickerInfo = GlobalData.getTickerInfoNew(ticker);
    if (typeof price === "string") {
      return;
    }

    if (tickerInfo.sectype === SecType.CW || tickerInfo.sectype === SecType.E) {
      priceRound = Math.round((price + Number.EPSILON) * 100) / 100;
      isCal = true;
    } else {
      if (
        tickerInfo !== null &&
        tickerInfo.marketcd === EMarketCodeNew.HSX_IDX
      ) {
        if (price <= 10.0) {
          priceRound = Math.round((price + Number.EPSILON) * 100) / 100;
        } else if (price > 10.0 && price <= 49.95) {
          priceRound = Math.round((price + Number.EPSILON) * 20) / 20;
        } else if (price === 50.0) {
          priceRound = 49.95;
        } else if (price > 50.0 && price < 50.1) {
          priceRound = 50.0;
        } else if (price > 50.1) {
          priceRound = Math.round((price + Number.EPSILON) * 10) / 10;
        } else {
          priceRound = Math.round((price + Number.EPSILON) * 10) / 10;
        }
        isCal = true;
      }
    }
    if (!isCal) {
      priceRound = Math.round((price + Number.EPSILON) * 10) / 10;
    }

    priceRound = Math.round((priceRound + Number.EPSILON) * 100) / 100;
    if (priceRound <= 0) priceRound = 0;

    return priceRound;
  };

  const handleSetCommand = () => {
    const marketCd = GlobalData.getTickerInfoNew(ticker)?.marketcd;
    if (isPreventSetCommand) {
      return;
    }

    if (isAuthenticated === false) {
      dispatch(toggleModalLogin(true));
    }

    //vao thang form dat lenh, mac dinh mua, gia theo value click
    // dispatch(changeActiveStep("placeOrder"));
    // if (typeof price === "number") {
    //   dispatch(
    //     changeDisplayPlaceOrder({
    //       tickerNameSelected: ticker,
    //       tickerPriceSelected: price,
    //       placeOrderType: "basic",
    //     })
    //   );
    // }
    if (marketCd !== EMarketCodeNew.DER_IDX) {
      dispatch(setTabExtraActive(MenuExtraTab.PLACE_ORDER));
    } else {
      dispatch(setTabExtraActive(MenuExtraTab.PLACE_ORDER_DER));
    }

    dispatch(setIsOpenExtra(true));
    dispatch(setVisibleIconExtra(false));
    dispatch(setVisibleContainerExtra(true));

    if (marketCd !== EMarketCodeNew.DER_IDX) {
      if (isAsk) {
        dispatch(onBuySellType("sell"));
      } else {
        dispatch(onBuySellType("buy"));
      }
      dispatch(onChangeCurrentTicker(ticker));
      dispatch(handleUsingPriceSelected(true));
      dispatch(clearData());
      if (isAvgPrice) {
        dispatch(
          onFilter({
            price: price ? getPriceRoundFlowStep() : 0,
          })
        );
      } else {
        dispatch(
          onFilter({
            price: price ? price : 0,
          })
        );
      }
    } else {
      dispatch(updateCurrentTicker(ticker));
      setTimeout(() => {
        dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.L));
        dispatch(updatePlaceOrderDerPrice(price));
      }, 0);
    }
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <StyledTooltip
          className={{ "d-none": isPreventSetCommand }}
          id="tooltip"
        >
          <FormattedMessage id="categories.placeOrderTooltip" />
        </StyledTooltip>
      }
    >
      <TextColor
        onDoubleClick={handleSetCommand}
        className={classNames("text-right", className)}
        color={color}
        background={background}
      >
        {displayText}
      </TextColor>
    </OverlayTrigger>
  );
}

export default PriceCell;
