import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { Container } from "./styles";
import { dataColumns } from "../../constants";

const Table = ({ data, pagination, fetchMoreData }: any) => {
  return (
    <Container id="transferLocalTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={dataColumns}
        data={data ?? []}
        enableInfinity={pagination?.enable}
        scrollableTarget={"transferLocalTable"}
        isHasFooter={false}
      />
    </Container>
  );
};

export default Table;
