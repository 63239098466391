import HttpClient from "../../index";
import { RESTFUL_APP_RESTFUL_BASE_URL } from "AppConfig";

export default class HttpOrderHistory extends HttpClient {
  private static classInstance?: HttpOrderHistory;
  private constructor() {
    super(RESTFUL_APP_RESTFUL_BASE_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpOrderHistory();
    }

    return this.classInstance;
  }
}
