import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";
import { ASSETS_TAB, ASSETS_INCURRED_TAB } from "./constants";
import { cloneDeep } from "lodash";

export interface ReduxData {
  subAccoNo: string;
  tab: string;
  assetsIncurred: {
    tab: string;
  };
  assets: {
    information: any;
    table: any;
  };
  assetsEffect: {
    typeAccount: string;
    custNo: string;
    subAccoNo: string;
  };
}

const initialState: ReduxData = {
  subAccoNo: "",
  tab: ASSETS_TAB.ASSETS_SUM,
  assetsIncurred: {
    tab: ASSETS_INCURRED_TAB.OPEN,
  },
  assets: {
    information: {},
    table: [],
  },
  assetsEffect: {
    typeAccount: "",
    custNo: "",
    subAccoNo: "",
  },
};

const assetsSlice = createSlice({
  name: "assets",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },

    setSubAccoNo: (state, action: PayloadAction<string>) => {
      state.subAccoNo = action.payload;
    },

    setTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setTabIncurred: (state, action: PayloadAction<string>) => {
      state.assetsIncurred.tab = action.payload;
    },

    setInformationAssets: (state, action: PayloadAction<any>) => {
      state.assets.information = action.payload;
    },

    setTableAssets: (state, action: PayloadAction<any>) => {
      state.assets.table = action.payload;
    },
    setFilteAssetsEffect: (
      state,
      action: PayloadAction<{ value: string; key: string }>
    ) => {
      state.assetsEffect = {
        ...state.assetsEffect,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  reset,
  setTab,
  setTabIncurred,
  setSubAccoNo,
  setInformationAssets,
  setTableAssets,
  setFilteAssetsEffect,
} = assetsSlice.actions;

export default assetsSlice.reducer;
