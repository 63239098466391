import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";

// import { ISearchDepositContractParams } from "core/http/apis/deposit-contract/types";
import {
  listDepositContractSelector,
  itemDetailSelector,
} from "modules/deposit-contract/selectors";
import {
  // searchDepositContractRequest,
  // liquidateDepositContractRequest,
  updateLiquidateModalStatusRequest,
  updateDetailModalStatusRequest,
  findDepositPaymentRequest,
  findDepositContractRequest,
  updateModalConfirmRequest,
} from "modules/deposit-contract/redux";
import { DepositContract } from "domain/models/DepositContract";
import numeral from "numeral";
import storage from "helper/storage";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ButtonLiquidate,
  ButtonDetail,
} from "./styles";
import DetailLiquidateModal from "./DetailLiquidateModal/index";
import DetailModal from "./DetailModal/index";
import ConfirmModal from "./ConfirmModal";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";

// import { MarginDebitContractWatch } from "domain/models/ContractDebit";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listDepositContractSelector);
  const itemDetailRow = useSelector(itemDetailSelector);
  const themeType = useSelector(themeTypeSelector);
  const center = "center";
  const flexStart = "flex-start";
  const flexEnd = "flex-end";

  const clickLiquidateHandler = (row: DepositContract) => {
    // dispatch(
    //   updateLiquidateModalStatusRequest({
    //     isVisible: true,
    //     itemDetail: row,
    //   })
    // );
    // dispatch(
    //   findDepositPaymentRequest({
    //     alloDate: row.alloDate ?? 0,
    //     refNo: row.refNo ?? 0,
    //     branchCd: "",
    //     transactionCd: row.transactionCd ?? "",
    //     custNo: storage.getMasterAccount() ?? "",
    //     fromDate: row.fromDate ?? 0,
    //     toDate: row.endDate ?? 0,
    //     status: row.status ?? 0,
    //   })
    // );

    dispatch(
      updateModalConfirmRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
  };
  const clickDetailHandler = (row: DepositContract) => {
    dispatch(
      updateDetailModalStatusRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
    dispatch(
      findDepositContractRequest({
        branchCd: "",
        transactionCd: row.transactionCd ?? "",
        custNo: storage.getMasterAccount() ?? "",
        fromDate: row.fromDate ?? 0,
        toDate: row.endDate ?? 0,
        status: row.status ?? 0,
        contractSts: row.contractSts ?? 0,
        srcChannel:
          row.srcChannel && row.srcChannel.slice(0, 1)
            ? row.srcChannel.slice(0, 1)
            : "",
      })
    );
  };
  const clickDetailHandlerStatus1 = (row: DepositContract) => {
    dispatch(
      updateLiquidateModalStatusRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
    dispatch(
      findDepositPaymentRequest({
        alloDate: row.alloDate ?? 0,
        refNo: row.refNo ?? 0,
        branchCd: "",
        transactionCd: row.transactionCd ?? "",
        custNo: storage.getMasterAccount() ?? "",
        fromDate: row.fromDate ?? 0,
        toDate: row.endDate ?? 0,
        status: row.status ?? 0,
      })
    );
  };
  function changeColor(value: number) {
    if (value === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="depositContract.status.available" />
        </TableCellContent>
      );
    else if (value === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="depositContract.status.turnOver" />
        </TableCellContent>
      );
    else if (value === 3)
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="depositContract.status.cancel" />
        </TableCellContent>
      );

    return "";
  }
  const getDisPlay = (status: number) => {
    switch (status) {
      case 1:
        return "day";
      case 2:
        return "month";
      case 3:
        return "year";

      // default:
      //   return "noValue";
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.table.loanDate" />
          </HeaderCell>
        ),
        accessor: "fromDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.table.subAccount" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="depositContract.table.contrNo" />
          </HeaderCell>
        ),
        accessor: "contractNo",
        minWidth: 180,

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="depositContract.table.amount" />
          </HeaderCell>
        ),
        accessor: "amount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="depositContract.table.balance" />
          </HeaderCell>
        ),
        accessor: "amount-payedAmount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.table.period" />
          </HeaderCell>
        ),
        accessor: "periodType",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id:
                  "depositContract.table.periodType." +
                  getDisPlay(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="depositContract.table.interest" />
          </HeaderCell>
        ),
        accessor: "netRate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.table.status" />
          </HeaderCell>
        ),
        accessor: "contractSts",
        minWidth: 100,
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <TableCellContent align={center}>
              {changeColor(currentRow.contractSts)}
            </TableCellContent>
          );
        },
      },

      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              {currentRow.contractSts === 1 && (
                <>
                  <ButtonDetail
                    className="detail"
                    onClick={() => clickDetailHandlerStatus1(currentRow)}
                  >
                    <FormattedMessage id="depositContract.table.detail" />
                  </ButtonDetail>
                  <ButtonLiquidate
                    className="liquidate"
                    onClick={() => clickLiquidateHandler(currentRow)}
                  >
                    <FormattedMessage id="depositContract.table.liquidation" />
                  </ButtonLiquidate>
                </>
              )}

              {currentRow.contractSts === 2 && (
                <ButtonDetail
                  className="detail"
                  onClick={() => clickDetailHandler(currentRow)}
                >
                  <FormattedMessage id="depositContract.table.detail" />
                </ButtonDetail>
              )}
              {currentRow.contractSts === 3 && (
                <ButtonDetail
                  className="detail"
                  onClick={() => clickDetailHandlerStatus1(currentRow)}
                >
                  <FormattedMessage id="depositContract.table.detail" />
                </ButtonDetail>
              )}
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="depositContractTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"depositContractTable"}
        isHasFooter={false}
      />
      {itemDetailRow && <DetailLiquidateModal itemDetailRow={itemDetailRow} />}
      {itemDetailRow && <DetailModal itemDetailRow={itemDetailRow} />}

      {itemDetailRow && <ConfirmModal itemDetailRow={itemDetailRow} />}
    </Container>
  );
}

export default memo(RequestTable);
