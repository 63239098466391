import { FC } from "react";
import { useSelector } from "react-redux";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import Table from "./Table";
import Filter from "./Filters";

import { activeTabSelector } from "modules/margin-securities/selectors";
import { MARGIN_SECURITIES_TABS } from "modules/margin-securities/constants";

const MarginSecuritiesContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === MARGIN_SECURITIES_TABS.MARGIN_SECURITIES && (
        <>
          <Filter />
          <Table />
        </>
      )}
    </Wrap>
  );
};
export default MarginSecuritiesContent;
