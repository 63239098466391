/* eslint-disable react-hooks/exhaustive-deps */
import isEmpty from "lodash/isEmpty";
import numeral from "numeral";
import { Doughnut } from "react-chartjs-2";
import {
  LegendContainer,
  LegendLabel,
  LegendName,
  LegendRow,
  FigureCell,
  LabelCell,
} from "./styles";

interface PROPS {
  data: { labels: any[]; datasets: any[] };
}

const ChartShare = (props: PROPS) => {
  const { data } = props;

  return (
    <>
      <Doughnut
        data={data ?? null}
        options={{
          legend: {
            display: false,
            position: "bottom",
            align: "start",
            labels: {
              boxWidth: 10,
            },
          },
        }}
      />

      <LegendContainer>
        {!isEmpty(data.labels) && !isEmpty(data.datasets)
          ? data.labels.map((el, idx) => (
              <LegendRow key={idx}>
                <LabelCell>
                  <LegendLabel
                    colorLabel={data?.datasets?.[0].backgroundColor?.[idx]}
                  />
                  <LegendName>{el}</LegendName>
                </LabelCell>
                <FigureCell>
                  {typeof data?.datasets?.[0]?.data?.[idx] === "number"
                    ? `${numeral(data?.datasets?.[0]?.data?.[idx]).format(
                        "0,0.00"
                      )}%`
                    : "-"}
                </FigureCell>
              </LegendRow>
            ))
          : null}
      </LegendContainer>
    </>
  );
};

export default ChartShare;
