import { HeaderCell, TableCellContent } from "./styles";
import { FormattedMessage } from "react-intl";
import numeral from "numeral";

const center = "center";
const flexEnd = "flex-end";

export const columnOpen = [
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="assets.assets.table.secCd" />
      </HeaderCell>
    ),
    accessor: "secCode",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.total" />
      </HeaderCell>
    ),
    accessor: "total",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.availSale" />
      </HeaderCell>
    ),
    accessor: "availSale",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.receiveT0" />
      </HeaderCell>
    ),
    accessor: "receiveT0",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.receiveT1" />
      </HeaderCell>
    ),
    accessor: "receiveT1",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.receiveT2" />
      </HeaderCell>
    ),
    accessor: "receiveT2",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.limitQty" />
      </HeaderCell>
    ),
    accessor: "limitQty",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.blockCom" />
      </HeaderCell>
    ),
    accessor: "blockCom",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.mortage" />
      </HeaderCell>
    ),
    accessor: "mortage",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.currentPrice" />
      </HeaderCell>
    ),
    accessor: "currentPrice",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="assets.assets.table.totaValue" />
      </HeaderCell>
    ),
    accessor: "totaValue",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="assets.assets.table.marketCode" />
      </HeaderCell>
    ),
    accessor: "marketCode",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
];
