import { IntlShape } from "react-intl";

export enum STATUS_TRANSFER_SHARE {
  ALL = "",
  PENDING = "0",
  APPROVE = "1",
  DELETE = "9",
}

export const statusList = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: "transferShare.status.all" }),
    value: STATUS_TRANSFER_SHARE.ALL,
  },
  {
    label: intl.formatMessage({ id: "transferShare.status.pending" }),
    value: STATUS_TRANSFER_SHARE.PENDING,
  },
  {
    label: intl.formatMessage({ id: "transferShare.status.approve" }),
    value: STATUS_TRANSFER_SHARE.APPROVE,
  },
  {
    label: intl.formatMessage({ id: "transferShare.status.delete" }),
    value: STATUS_TRANSFER_SHARE.DELETE,
  },
];
