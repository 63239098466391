import Autocomplete from "react-autocomplete";
import { InputConntainer } from "./styles";
import { Props as LibProps } from "react-autocomplete";
import classNames from "classnames";
import { SizeType } from "components/commons/Spacer";

interface Props extends LibProps {
  innerRef?: React.RefObject<HTMLInputElement>;
  title?: string;
  placeholder?: string;
  sizeInput?: SizeType;
  name?: string;
  control?: any;
  textLayer?: string;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  isSearch?: boolean;
  isBorderActive?: boolean;
  radius?: number;
  isTextCenter?: boolean;
  showClearButton?: boolean;
  canClear?: boolean;
  onClearInput?: () => void;
  textArea?: boolean;
  isTextRight?: boolean;
  isHeight28?: boolean;
}

const InputAutocompelete: React.FC<Props> = ({
  title,
  sizeInput,
  placeholder,
  innerRef,
  isSearch,
  append,
  prepend,
  isBorderActive,
  radius,
  isTextCenter,
  showClearButton,
  canClear,
  onClearInput,
  textArea = false,
  isTextRight,
  isHeight28 = false,
  ...props
}) => {
  return (
    <InputConntainer
      className={classNames(
        { "pl-3": isSearch },
        { "input-container": !isSearch },
        "input-container"
      )}
      size={sizeInput}
      isBorderActive={isBorderActive}
      radius={radius}
      textArea={textArea}
      isHeight28={isHeight28}
    >
      <Autocomplete {...props} />
    </InputConntainer>
  );
};

export default InputAutocompelete;
