import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";
import { ActiveTabRightInfo } from "helper/consts";
import { activeTabSelector } from "modules/right-info/selectors";

const Tabs: FC = () => {
  const intl = useIntl();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab isActive={activeTab === ActiveTabRightInfo.RIGHT_INFO}>
          {intl.formatMessage({ id: "rightInfo.tab" })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
