import { Select, Button } from "components/commons";
import { memo, useMemo, useEffect } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  getDBondOrderRequest,
  getDBondPriceRequest,
} from "modules/bid-ask-d-bond-order/redux";
import {
  loadingSearchDataSelector,
  filterDBondOrderSelector,
  listDBondPriceSelector,
} from "modules/bid-ask-d-bond-order/selectors";
import { tradeDateSelector } from "modules/auth/selectors";
import { IGetDBondOrderParams } from "core/http/apis/bid-ask-d-bond-order/types";

import InputSymbol from "modules/bid-ask-bond/pages/BidAskBond/components/BidAskBondContent/RequestFilters/InputSymbol";

import "../styles";
const statusOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "bidAskDBondOrder.filter.status.all",
    }),
  },
  {
    value: "0",
    label: intl.formatMessage({
      id: "bidAskDBondOrder.filter.status.wait_approve",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "bidAskDBondOrder.filter.status.confirmed",
    }),
  },

  {
    value: "2",
    label: intl.formatMessage({
      id: "bidAskDBondOrder.filter.status.pending",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "bidAskDBondOrder.filter.status.complete",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "bidAskDBondOrder.filter.status.rejected",
    }),
  },
];

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { secCdDBond, status } = useSelector(filterDBondOrderSelector);

  const loading = useSelector(loadingSearchDataSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const listDBondPrice = useSelector(listDBondPriceSelector);
  const statusOptions = useMemo(() => statusOption(intl), [intl]);

  useEffect(() => {
    dispatch(
      updateFilter({
        key: "status",
        value: statusOptions ? +statusOptions[0].value : -1,
      })
    );
    dispatch(
      getDBondPriceRequest({
        transDate: tradeDate ? tradeDate : "",
      })
    );
  }, []);

  const handleSearch = () => {
    const submitData: IGetDBondOrderParams = {
      bondCd: secCdDBond ? secCdDBond : "",
      custNo: null,
      fromDate: null,
      toDate: null,
      custCd: 0,
      status:
        status !== null && status !== undefined
          ? status
          : +statusOptions[0].value
          ? +statusOptions[0].value
          : -1,

      type: 1,
      tradeType: null,
      getDataType: 1,
    };
    dispatch(getDBondOrderRequest(submitData));
  };

  return (
    <Wrap
      className="d-flex justify-content-between align-items-center w-100"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "bidAskDBondOrder.filter.bondCd",
          })}
          className="marginRight text-grey"
        >
          <div className="text-white">
            <InputSymbol
              list={listDBondPrice}
              value={secCdDBond}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "secCdDBond",
                    value: e ? e : "",
                  })
                );
              }}
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "bidAskDBondOrder.filter.status.label",
          })}
          className="marginRight2 text-grey"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={statusOptions[0]}
              options={statusOptions}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "status",
                    value: e?.value,
                  })
                );
              }}
            />
          </div>
        </FilterItem>
        <Button
          color="accept"
          className="button-filter1 mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "bidAskDBondOrder.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
