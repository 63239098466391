import { Container, ContainerTop, Title, Languages } from "./styles";
import { FormattedMessage } from "react-intl";
import { Button } from "components/commons";
import { useState } from "react";
import ModalSetting from "./modalSetting";
import { TypeModal } from "./constants";

function GeneralSettings() {
  const [modal, setModal] = useState({
    status: false,
    type: "",
  });

  const openModal = (type: string) => {
    setModal({
      status: true,
      type: type,
    });
  };

  return (
    <Container>
      <ContainerTop>
        <Title>
          <FormattedMessage id="auth.setting.settingPass.loginPass" />
        </Title>
        <Languages>
          <Button
            color="accept"
            radius={4}
            size="m"
            width="150px"
            onClick={() => openModal(TypeModal.LOGIN)}
          >
            <FormattedMessage id="auth.setting.settingPass.button" />
          </Button>
        </Languages>
      </ContainerTop>
      <div className="pt-4">
        <Title>
          <FormattedMessage id="auth.setting.settingPass.transactionPass" />
        </Title>
        <Languages>
          <Button
            color="accept"
            radius={4}
            size="m"
            width="150px"
            onClick={() => openModal(TypeModal.TRANSACTION)}
          >
            <FormattedMessage id="auth.setting.settingPass.button" />
          </Button>
        </Languages>
      </div>
      {modal.status && (
        <ModalSetting
          isVisible={modal.status}
          onClose={() => setModal({ ...modal, status: false })}
          typeSetting={modal.type}
        />
      )}
    </Container>
  );
}

export default GeneralSettings;
