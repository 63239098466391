import { NotificationDetailParams } from "./types";

export const notification = (masterAccount: string) =>
  `/notification/${masterAccount}`;
export const notificationTotalUnread = () => `/totalUnreadNotifications`;
export const notifyDeletion = `/notification/`;
export const notifyCheckRead = `/notification`;
export const initNotification = `/initNotification`;
export const readAllNotification = (masterAccount: string) =>
  `/notification/readAllNotification/${masterAccount}`;

export const notificationDetail = (
  masterAccount: string,
  params: NotificationDetailParams
) => {
  return `/notification/${masterAccount}/${params.noticeId}?businessDate=${params.businessDate}`;
};
