import React, { memo, useEffect } from "react";
import { OrgInfoWrapper } from "./styles";
import { ChartOptions } from "chart.js";
import OrgPieChart from "./OrgPieChart";
import { useSelector, useDispatch } from "react-redux";
import { getOrgInfo } from "modules/tickerDetails/redux/orgInfo";
import {
  chartDataStakeHolderSelector,
  chartDataOwnPercentSelector,
} from "modules/tickerDetails/redux/selectors";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import numeral from "numeral";
import GlobalData from "helper/globalData";
import { useIntl } from "react-intl";

const stackHolderOptions: ChartOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 3,
  tooltips: {
    callbacks: {
      title: (item, data) => {
        return `${
          typeof item[0]?.index === "number"
            ? data?.labels?.[item[0]?.index]
            : ""
        }`;
      },
      label: (tooltipItem, data) => {
        return `${
          typeof tooltipItem.index === "number"
            ? `${numeral(data?.datasets?.[0]?.data?.[tooltipItem.index]).format(
                "0,0.[00]"
              )}%`
            : "-"
        }`;
      },
    },
    mode: "single",
  },
};

function OrganizationInfo(): React.ReactElement {
  const intl = useIntl();
  const dispatch = useDispatch();
  const chartDataStakeHolder = useSelector(chartDataStakeHolderSelector);
  const chartDataOwnPercent = useSelector(chartDataOwnPercentSelector);
  const ticker = useSelector(selectedTickerSelector);

  useEffect(() => {
    if (ticker !== null) {
      dispatch(getOrgInfo(ticker));
    }
  }, [ticker]);

  return (
    <OrgInfoWrapper className="p-2">
      <p className="org-name">
        {ticker} - {ticker ? GlobalData.getTickerInfoNew(ticker)?.secname : "-"}
      </p>
      {/* <p className="org-des">{data?.description}</p> */}
      <div className="p-2">
        {chartDataStakeHolder !== undefined && (
          <OrgPieChart
            configs={stackHolderOptions}
            colors={chartDataStakeHolder.colors}
            data={chartDataStakeHolder.dataChart}
            labels={chartDataStakeHolder.labels}
          />
        )}
      </div>
      <div className="p-2 pt-4">
        {chartDataOwnPercent !== undefined && (
          <OrgPieChart
            titleLegend={intl.formatMessage({
              id: "widgets.organization.domesticAndForeign",
            })}
            configs={stackHolderOptions}
            colors={chartDataOwnPercent.colors}
            data={chartDataOwnPercent.dataChart}
            labels={chartDataOwnPercent.labels}
          />
        )}
      </div>
    </OrgInfoWrapper>
  );
}

export default memo(OrganizationInfo);
