import { Button, Spacer, OTPByBusinessCd } from "components/commons";
import { toast } from "components/commons/Toast";
import {
  IPlaceOrdersConditionParams,
  PlaceOrdersParam,
  PlaceOrdersPendingParam,
} from "core/http/apis/orderexec/types";
import { EBusinessCd, EMarketCodeNew, SecType } from "helper/consts";
import numeral from "numeral";
import React, {
  FC,
  memo,
  // useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  PopConfirmOrderBody,
  PopConfirmOrderContainer,
  PopConfirmOrderTitle,
  // TradeLabel,
  ButtonExit,
  Icon,
  VisibleModalConfirm,
  Content,
} from "./styles";
import {
  postSingleOrderClear,
  requestPostOrderCondition,
  requestPostSingleOrder,
  requestPostSingleOrderPending,
} from "modules/stock-order/redux/placeOrder";
// import { checkPlaceOrder } from "helper/tradingHelper";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import GlobalData from "helper/globalData";
import { onLoadList } from "modules/order-book/redux/index";
import {
  rootStockOrderSelector,
  placeOrderSelector,
} from "modules/stock-order/selectors";
import {
  currentAccountSelector,
  tradeDateSelector,
} from "modules/auth/selectors";
import { ReactComponent as IconCheckBoxSelected } from "assets/image/svg/ic_checkbox_selected.svg";
import { ReactComponent as IconCheckBoxUnselected } from "assets/image/svg/ic_checkbox_unselected.svg";
import { setListKeyShort } from "themes/redux";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import moment from "moment";
import { getCondTypeDisplay } from "helper/orderBookHelper";
import storage from "helper/storage";

interface Options {
  value: string;
  label: string;
}

interface Props {
  visible: boolean;
  type: string;
  handleCloseModal: any;
  handleSubmitSuccess: any;
  visibleModalOTP: boolean;
  setVisibleModalOTP: any;
  setVisible: any;
  conditionalOrder: Options;
  tradingSessionId: number;
}

const ModalSubmitOrder: FC<Props> = memo(
  ({
    visible,
    type,
    handleCloseModal,
    handleSubmitSuccess,
    visibleModalOTP,
    setVisibleModalOTP,
    setVisible,
    conditionalOrder,
  }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentAccount = useSelector(currentAccountSelector);
    const tradeDate = useSelector(tradeDateSelector);
    const { currentTicker, floorPrice, ceilingPrice, secType } = useSelector(
      rootStockOrderSelector
    );

    const {
      filters: { price },
      volumn,
      singleOrderStatus,
      prepareSubmitOrderReponse,
      optVal,
      optType,
      matMethod,
      condition,
      expiredDate,
      limitOffset,
      optValType,
      minBuyPrice,
      maxSellPrice,
      avgPrice,
      errorSingleOrder,
      loadingPlaceOrder,
    } = useSelector(placeOrderSelector);

    const refButtonConfirm = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState(false);
    const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);

    useEffect(() => {
      if (visible || !visibleModalOTP) {
        refButtonConfirm.current?.focus();
      }
    }, [visibleModalOTP, visible]);

    const toggleModalInfo = () => {
      handleCloseModal();
    };

    const getPriceRound = (price: number, symbol: string) => {
      const priceNumber = parseFloat(price?.toString());
      if (symbol !== "") {
        const ticker = GlobalData.getTickerInfoNew(symbol);
        if (
          ticker !== null &&
          ticker.marketcd === EMarketCodeNew.HSX_IDX &&
          ticker.sectype === SecType.S
        ) {
          if (priceNumber <= 10.0) {
            return Math.round((priceNumber + Number.EPSILON) * 100) / 100;
          } else if (priceNumber > 10.0 && priceNumber <= 50) {
            return Math.round((priceNumber + Number.EPSILON) * 20) / 20;
          } else {
            return Math.round((priceNumber + Number.EPSILON) * 10) / 10;
          }
        } else if (ticker.sectype === SecType.CW) {
          return Math.round((priceNumber + Number.EPSILON) * 100) / 100;
        } else {
          return Math.round((priceNumber + Number.EPSILON) * 10) / 10;
        }
      } else {
        return Math.round((priceNumber + Number.EPSILON) * 10) / 10;
      }
    };

    const getPrice = (conditionalOrder: string, optValType: number): number => {
      switch (conditionalOrder) {
        case "01":
        case "TCO":
          if (!isNaN(price)) {
            return price;
          } else {
            if (type === "buy") {
              return ceilingPrice * 1000;
            } else {
              return floorPrice * 1000;
            }
          }
        case "PRO":
          return 0;
        case "TS":
          return ceilingPrice * 1000;
        case "SP":
          if (optValType === 2) {
            return parseFloat(avgPrice) * (1 + parseFloat(optVal) / 100);
          } else {
            return parseFloat(avgPrice) + parseFloat(optVal);
          }
        case "CL":
          if (optValType === 2) {
            return parseFloat(avgPrice) * (1 - parseFloat(optVal) / 100);
          } else {
            return parseFloat(avgPrice) - parseFloat(optVal);
          }
        default:
          return price;
      }
    };

    const handleModal = (isSuccess: boolean) => {
      setVisibleModalOTP(false);
      setVisible(true);
      setLoading(false);
    };

    const doSubmitPlaceOrder = async (response: IVerifyResponse) => {
      setVisibleModalOTP(false);
      setVisible(false);

      if (!currentAccount?.subAccoNo) {
        setLoading(false);
        return;
      }

      // Neu lenh thuong
      if (conditionalOrder.value === "01") {
        // // Validate
        // try {
        //   const error = await checkPlaceOrder(params, intl);
        //   if (error) {
        //     const errorMsg = error;
        //     setLoading(false);
        //     toast(errorMsg, "error");
        //     return;
        //   }
        // } catch (error: any) {
        //   const errorMsg = intl.formatMessage({
        //     id: error.code,
        //     defaultMessage: error.description,
        //   });
        //   toast(errorMsg, "error");
        //   setLoading(false);
        //   return;
        // }

        // Múc
        const tickerInfo = GlobalData.getTickerInfoNew(currentTicker);
        if (prepareSubmitOrderReponse?.dataType !== 1) {
          const paramsPending: PlaceOrdersPendingParam = {
            requestList: prepareSubmitOrderReponse?.dataList || "",
            otpCode: response?.otp,
            otpType: response?.otpTypeSubmit!,
          };
          dispatch(
            requestPostSingleOrderPending({
              params: paramsPending,
              tickerInfo: tickerInfo,
            })
          );
        } else {
          const params: PlaceOrdersParam = {
            list: prepareSubmitOrderReponse?.dataList || "",
            otpCode: response?.otp,
            otpType: response?.otpTypeSubmit!,
          };
          dispatch(
            requestPostSingleOrder({
              params: params,
              tickerInfo: tickerInfo,
            })
          );
        }
      } else {
        // Neu la lenh dieu kien
        const paramsCondition: IPlaceOrdersConditionParams = {
          secCd: currentTicker,
          custNo: storage.getMasterAccount(),
          subAccoNo: currentAccount?.subAccoNo,
          tradeType: type === "buy" ? 2 : 1,
          ordQty: volumn?.replaceAll(",", ""),
          minPrice: type === "buy" ? minBuyPrice : 0,
          maxPrice: type === "sell" ? maxSellPrice : 0,
          ordPrice: getPriceRound(
            getPrice(conditionalOrder.value, optValType),
            currentTicker
          ),
          condType: conditionalOrder.value,
          matMethod: conditionalOrder.value === "PRO" ? 2 : parseInt(matMethod),
          optType:
            conditionalOrder.value === "TCO"
              ? condition === "01"
                ? 0
                : parseFloat(optType.value)
              : "",
          optVal:
            conditionalOrder.value !== "PRO"
              ? optValType === 2 && conditionalOrder.value !== "TCO"
                ? parseFloat(optVal) / 100
                : parseFloat(optVal)
              : "",
          fromDate: Number(moment(tradeDate).format("yyyyMMDD")),
          toDate: Number(moment(expiredDate).format("yyyyMMDD")),
          traderId: "",
          secType: secType,
          optValType:
            conditionalOrder.value !== "TCO" && conditionalOrder.value !== "PRO"
              ? optValType
              : "",
          limitOffset:
            conditionalOrder.value !== "TCO" && conditionalOrder.value !== "PRO"
              ? parseFloat(limitOffset)
              : "",
          clientInfo: "",
          channel: "2",
          otpCode: response?.otp,
          otpType: response?.otpTypeSubmit!,
        };
        // Múc
        const tickerInfo = GlobalData.getTickerInfoNew(currentTicker);
        dispatch(
          requestPostOrderCondition({
            params: paramsCondition,
            tickerInfo: tickerInfo,
          })
        );
      }
    };

    const calculatedPrice = (): number => {
      let volumnReplace = "";
      if (volumn && typeof volumn === "string") {
        volumnReplace = volumn.replaceAll(",", "");
      }
      const volumnNumber = parseInt(volumnReplace);
      if (conditionalOrder.value === "01") {
        if (isNaN(price)) {
          return type === "buy"
            ? volumnNumber * ceilingPrice * 1000
            : volumnNumber * floorPrice * 1000;
        } else {
          return volumnNumber * price * 1000;
        }
      } else {
        const priceTotal =
          volumnNumber *
          getPriceRound(
            getPrice(conditionalOrder.value, optValType),
            currentTicker
          ) *
          1000;
        return priceTotal;
      }
    };

    useEffect(() => {
      if (singleOrderStatus?.status === "success") {
        setLoading(false);
        toast(
          intl.formatMessage({ id: "widgets.placeOrder.orderSuccess" }),
          "success"
        );
        dispatch(postSingleOrderClear());
        if (conditionalOrder.value !== "01") {
          dispatch(onLoadList(Math.random()));
        }
        handleSubmitSuccess();
        handleCloseModal();
        dispatch(
          setListKeyShort({ visiblePopupConfirm: !visibleModalConfirm })
        );
      }

      if (singleOrderStatus?.status === "failed") {
        setLoading(false);
        const codeError = errorSingleOrder?.code;
        if (codeError) {
          const msg = `${errorSingleOrder?.code
            ?.toString()
            .replaceAll("-", "_")}`;
          const msgFinal = msg
            ? "error." + msg
            : "widgets.placeOrder.orderError";
          toast(msgFinal, "error");
        } else {
          toast("error.defaultErr", "error");
        }

        dispatch(postSingleOrderClear());
        handleCloseModal();
      }
    }, [singleOrderStatus]);

    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        onSubmit();
        refButtonConfirm.current?.blur();
      }
    };

    const onSubmit = async () => {
      setLoading(true);
      setVisible(!visible);
      setVisibleModalOTP(true);
    };

    const getOrderTypeDisplay = () => {
      if (conditionalOrder.value === "01") {
        if (prepareSubmitOrderReponse?.dataType !== 1) {
          return intl.formatMessage({
            id: "widgets.placeOrder.orderType.pending",
          });
        } else {
          return intl.formatMessage({
            id: "widgets.placeOrder.orderType.normal",
          });
        }
      } else {
        if (conditionalOrder.value === "TCO") {
          return intl.formatMessage({
            id: "widgets.placeOrder.conditionalOrder.timeConditionOrder",
          });
        }

        if (conditionalOrder.value === "PRO") {
          return intl.formatMessage({
            id: "widgets.placeOrder.conditionalOrder.priorityOrder",
          });
        }

        if (conditionalOrder.value === "TS") {
          return intl.formatMessage({
            id: "widgets.placeOrder.conditionalOrder.trailingStopOrder",
          });
        }

        if (conditionalOrder.value === "SP") {
          return intl.formatMessage({
            id: "widgets.placeOrder.conditionalOrder.takeProfit",
          });
        }

        if (conditionalOrder.value === "CL") {
          return intl.formatMessage({
            id: "widgets.placeOrder.conditionalOrder.cutLoss",
          });
        }
      }
    };

    const render = (conditionalOrder: any) => {
      switch (conditionalOrder) {
        case "TCO":
          return (
            <>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.method" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      matMethod === "1"
                        ? "widgets.placeOrder.conditionalOrder.generatedOneTime"
                        : "widgets.placeOrder.conditionalOrder.matchingEnoughVolume"
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.condition" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      condition === "01"
                        ? "widgets.placeOrder.conditionalOrder.conditionsList.noCondition"
                        : "widgets.placeOrder.conditionalOrder.conditionsList.referencePrices"
                    }
                  />
                </div>
              </div>
              {condition === "02" && (
                <div className="d-flex justify-content-between p-2">
                  <div className="label">
                    <FormattedMessage id="widgets.placeOrder.conditionalOrder.priceTC" />
                  </div>
                  <div className="value">{optType?.label + " " + optVal}</div>
                </div>
              )}
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.toDate" />
                </div>
                <div className="value">
                  {moment(expiredDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </>
          );
        case "PRO":
          return (
            <>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.method" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      matMethod === "1"
                        ? "widgets.placeOrder.conditionalOrder.generatedOneTime"
                        : "widgets.placeOrder.conditionalOrder.matchingEnoughVolume"
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.toDate" />
                </div>
                <div className="value">
                  {moment(expiredDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </>
          );
        case "TS":
          return (
            <>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.method" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      matMethod === "1"
                        ? "widgets.placeOrder.conditionalOrder.generatedOneTime"
                        : "widgets.placeOrder.conditionalOrder.matchingEnoughVolume"
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.stopInterval" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      optValType === 1
                        ? "widgets.placeOrder.conditionalOrder.byValue"
                        : "widgets.placeOrder.conditionalOrder.byPercent"
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage
                    id={
                      optValType === 1
                        ? "widgets.placeOrder.value"
                        : "widgets.placeOrder.percent"
                    }
                  />
                </div>
                <div className="value">{optVal}</div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.slidingMargin" />
                </div>
                <div className="value">{limitOffset}</div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.toDate" />
                </div>
                <div className="value">
                  {moment(expiredDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </>
          );
        case "SP":
        case "CL":
          return (
            <>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.method" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      matMethod === "1"
                        ? "widgets.placeOrder.conditionalOrder.generatedOneTime"
                        : "widgets.placeOrder.conditionalOrder.matchingEnoughVolume"
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.avgPrice" />
                </div>
                <div className="value">
                  {numeral(avgPrice).format("0,0.000")}
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.arbitrage" />
                </div>
                <div className="value">
                  <FormattedMessage
                    id={
                      optValType === 1
                        ? "widgets.placeOrder.conditionalOrder.byValue"
                        : "widgets.placeOrder.conditionalOrder.byPercent"
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage
                    id={
                      optValType === 1
                        ? "widgets.placeOrder.value"
                        : "widgets.placeOrder.percent"
                    }
                  />
                </div>
                <div className="value">{optVal}</div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.slidingMargin" />
                </div>
                <div className="value">{limitOffset}</div>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="label">
                  <FormattedMessage id="widgets.placeOrder.toDate" />
                </div>
                <div className="value">
                  {moment(expiredDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </>
          );
        default:
          break;
      }
    };

    return (
      <>
        <Modal
          show={visible}
          centered
          keyboard={false}
          backdrop="static"
          dialogClassName="modal-434"
        >
          <PopConfirmOrderContainer>
            <PopConfirmOrderTitle color={type === "buy" ? "green50" : "red50"}>
              <div className="flex-grow-1 "></div>
              {type === "buy"
                ? intl
                    .formatMessage({ id: "widgets.placeOrder.buyTitle" })
                    .toUpperCase()
                : intl
                    .formatMessage({ id: "widgets.placeOrder.sellTitle" })
                    .toUpperCase()}
              <ButtonExit
                color={type === "buy" ? "green" : "red"}
                onClick={() => {
                  toggleModalInfo();
                }}
              >
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            </PopConfirmOrderTitle>
            <PopConfirmOrderBody>
              <div className="order-info">
                <div className="d-flex justify-content-between p-2">
                  <div className="label">
                    <FormattedMessage id="widgets.placeOrder.accountNumber" />
                  </div>
                  <div className="value">
                    {currentAccount?.subAccoNo}
                    {/* {convertAccType(currentAccount?.mrtype, intl)} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="label">
                    <FormattedMessage id="widgets.placeOrder.stockCode" />
                  </div>
                  <div className="value">{currentTicker}</div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="label">
                    <FormattedMessage id="widgets.placeOrder.orderType.title" />
                  </div>
                  <div className={type === "buy" ? "green" : "red"}>
                    {getOrderTypeDisplay()}
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="label">
                    {type === "buy" ? (
                      <FormattedMessage id="widgets.placeOrder.buyVolumn" />
                    ) : (
                      <FormattedMessage id="widgets.placeOrder.sellVolumn" />
                    )}
                  </div>
                  <div className="value">{numeral(volumn).format("0,000")}</div>
                </div>
                {conditionalOrder?.value === "01" && (
                  <div className="d-flex justify-content-between p-2">
                    <div className="label">
                      {type === "buy" ? (
                        <FormattedMessage id="widgets.placeOrder.buyPrice" />
                      ) : (
                        <FormattedMessage id="widgets.placeOrder.sellPrice" />
                      )}
                    </div>
                    <div className="value">{price}</div>
                  </div>
                )}
                {conditionalOrder?.value !== "TS" &&
                  conditionalOrder?.value !== "PRO" &&
                  conditionalOrder?.value !== "01" && (
                    <div className="d-flex justify-content-between p-2">
                      <div className="label">
                        {type === "buy" ? (
                          <FormattedMessage id="widgets.placeOrder.buyPrice" />
                        ) : (
                          <FormattedMessage id="widgets.placeOrder.sellPrice" />
                        )}
                      </div>
                      <div className="value">
                        {getPriceRound(
                          getPrice(conditionalOrder.value, optValType),
                          currentTicker
                        )}
                      </div>
                    </div>
                  )}
                {conditionalOrder?.value !== "01" && (
                  <div className="d-flex justify-content-between p-2">
                    <div className="label">
                      <FormattedMessage id="orderBook.tableCondition.condType" />
                    </div>
                    <div className="value">
                      <FormattedMessage
                        id={getCondTypeDisplay(conditionalOrder?.value)}
                      />
                    </div>
                  </div>
                )}
                {render(conditionalOrder?.value)}
              </div>
              {!isNaN(price) &&
                conditionalOrder?.value !== "TS" &&
                conditionalOrder?.value !== "PRO" && (
                  <div className="d-flex justify-content-between p-2 pt-3">
                    <div className="d-flex flex-column">
                      <span className="label">
                        <FormattedMessage id="widgets.placeOrder.totalLabel" />
                      </span>
                      <span className="text-small">
                        <FormattedMessage id="widgets.placeOrder.noVATLabel" />
                      </span>
                    </div>
                    <div className="text-bold">
                      {numeral(calculatedPrice()).format("0,000")}
                    </div>
                  </div>
                )}
              <VisibleModalConfirm>
                {visibleModalConfirm ? (
                  <IconCheckBoxSelected
                    onClick={() => setVisibleModalConfirm(false)}
                  />
                ) : (
                  <IconCheckBoxUnselected
                    onClick={() => setVisibleModalConfirm(true)}
                  />
                )}
                <Content>
                  <FormattedMessage id="widgets.placeOrder.visibleModalConfirm" />
                </Content>
              </VisibleModalConfirm>
              <div className="footer">
                <Button
                  className="flex-1"
                  color="accept"
                  onClick={() => {
                    toggleModalInfo();
                  }}
                >
                  <FormattedMessage id="widgets.placeOrder.cancel" />
                </Button>
                <Spacer size="s" />
                <Button
                  className={
                    type === "buy" ? "btn-success flex-1" : "btn-danger flex-1"
                  }
                  onMouseDown={() => onSubmit()}
                  onKeyDown={handleKeyDown}
                  focusButton={visible || !visibleModalOTP}
                  disabled={loading || loadingPlaceOrder}
                  loading={loading || loadingPlaceOrder}
                >
                  {type === "buy" ? (
                    <FormattedMessage id="widgets.placeOrder.comfirmBuy" />
                  ) : (
                    <FormattedMessage id="widgets.placeOrder.comfirmSell" />
                  )}
                </Button>
              </div>
            </PopConfirmOrderBody>
          </PopConfirmOrderContainer>
        </Modal>
        {visibleModalOTP && (
          <OTPByBusinessCd
            handleClose={handleModal}
            getResult={doSubmitPlaceOrder}
            businessCd={EBusinessCd.TRADING}
          />
        )}
      </>
    );
  }
);

export default ModalSubmitOrder;
