import { ProgressCard, Buy, Sell, Neutral } from "./styles";
interface ProgressProps {
  buypercent: number;
  sellpercent: number;
  maxPercent: number;
  percentage: number;
}

function Progress({
  buypercent,
  sellpercent,
  maxPercent,
  percentage,
}: ProgressProps) {
  const buyPercen = (buypercent / percentage) * 100;
  const sellPercen = (sellpercent / percentage) * 100;
  const neutralPercen = 100 - (buyPercen + sellPercen);
  return (
    <ProgressCard width={(percentage / maxPercent) * 100}>
      <Buy value={buyPercen} />
      <Sell value={sellPercen} />
      <Neutral value={neutralPercen} />
    </ProgressCard>
  );
}

export default Progress;
