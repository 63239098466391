import { Button, Input } from "components/commons";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import {
  getCustomer2Request,
  getServiceStatusOfCustomerRequest,
  registerServiceRequest,
  searchServiceStatusOfCustomerRequest,
  updateStatusCodeSms,
} from "modules/auth/redux/serviceEmailSms";
import {
  customerSelector,
  getEmailDataSelector,
  getServiceStatusOfCustomerSelector,
  getSmsDataSelector,
  getStatusCodeSmsSelector,
  loadingSearchServiceSelector,
  loadingSubmitServiceSelector,
} from "modules/auth/selectors";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "./Content";
import {
  BtnUpdate,
  ContainerLeft,
  ContentBottom,
  ContentTop,
  Label,
  LabelRight,
  SelectBox,
} from "./styles";
import Tabs from "./Tabs";

function ServiceEmailSms() {
  const dispatch = useDispatch();

  const [valueTab, setValueTab] = useState("");

  const intl = useIntl();
  const loadingSearch = useSelector(loadingSearchServiceSelector);
  const loadingSubmit = useSelector(loadingSubmitServiceSelector);
  const customer = useSelector(customerSelector);
  const serviceStatusOfCustomer = useSelector(
    getServiceStatusOfCustomerSelector
  );
  const smsDataSelector = useSelector(getSmsDataSelector);
  const emailDataSelector = useSelector(getEmailDataSelector);

  const accNo = storage.getMasterAccount();

  const statusCode = useSelector(getStatusCodeSmsSelector);

  const handleSearch = () => {
    dispatch(searchServiceStatusOfCustomerRequest({ custNo: accNo }));
  };

  const handleUpdate = () => {
    if (
      JSON.stringify(smsDataSelector) ===
        JSON.stringify(serviceStatusOfCustomer?.smsData?.serviceList) &&
      JSON.stringify(emailDataSelector) ===
        JSON.stringify(serviceStatusOfCustomer?.emailData?.serviceList)
    ) {
      toast(
        `${intl.formatMessage({
          id: "auth.seviceEmailSms.dataSame",
        })}`,
        "warning"
      );
    } else {
      dispatch(
        registerServiceRequest({
          custNo: serviceStatusOfCustomer?.custNo,
          emailData: {
            email6: serviceStatusOfCustomer?.emailData?.email6,
            serviceList: emailDataSelector,
          },
          smsData: {
            mobile55: serviceStatusOfCustomer?.smsData?.mobile55,
            serviceList: smsDataSelector,
          },
        })
      );
    }
  };

  const onChangeTab = (values: any) => {
    setValueTab(values);
  };

  useEffect(() => {
    dispatch(getServiceStatusOfCustomerRequest({ custNo: accNo }));
    dispatch(
      getCustomer2Request({
        custCd: "",
        custNo: accNo,
      })
    );
  }, [accNo]);

  useEffect(() => {
    if (statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "auth.seviceEmailSms.success",
        })}`,
        "success"
      );
      dispatch(updateStatusCodeSms({ statusCode: -1 }));
      dispatch(getServiceStatusOfCustomerRequest({ custNo: accNo }));
    }
  }, [statusCode]);

  return (
    <>
      <ContentTop>
        <ContainerLeft>
          <Label>
            <FormattedMessage id="auth.seviceEmailSms.accoName" />
          </Label>
          <SelectBox>
            <Input value={customer?.custNo}></Input>
          </SelectBox>
          <LabelRight>
            {customer?.custFamilyName?.concat(
              " ".concat(customer?.custGivenName)
            )}
          </LabelRight>
        </ContainerLeft>
        <Button
          color="accept"
          className="button-filter m-1"
          onClick={handleSearch}
          loading={loadingSearch}
          width={"120px"}
        >
          {intl.formatMessage({
            id: "auth.seviceEmailSms.search",
          })}
        </Button>
      </ContentTop>

      <ContentBottom isVisibleSideCard={false}>
        <Tabs onClick={onChangeTab} />
        <Content activeTab={valueTab}></Content>
        <BtnUpdate>
          <Button
            color="accept"
            fill={true}
            className="button-filter m-1"
            width="120px"
            onClick={handleUpdate}
            loading={loadingSubmit}
          >
            <FormattedMessage id="auth.seviceEmailSms.update" />
          </Button>
        </BtnUpdate>
      </ContentBottom>
    </>
  );
}

export default ServiceEmailSms;
