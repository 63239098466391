import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { OrderBookDisplay, OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { Checkbox } from "../style";

const checkboxSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) => state?.[realTimeId]?.iscancel
  );
};

interface Props {
  realTimeId: string;
  checked: boolean;
  handleCheck: (
    order: OrderBookDisplay,
    e: React.ChangeEvent<HTMLInputElement>,
    orderList: OrderBookDisplay[]
  ) => void;
  item: OrderBookDisplay;
  data: OrderBookDisplay[];
}

const CheckboxCell = ({
  realTimeId,
  checked,
  handleCheck,
  item,
  data,
}: Props) => {
  const value = useSelector(checkboxSelector(realTimeId));
  const visibleCheckbox = value;
  return (
    <>
      <TableCellContent align={"center"}>
        <Checkbox
          checked={checked}
          onChange={(e: any) => handleCheck(item, e, data)}
          disabled={!visibleCheckbox}
        />
      </TableCellContent>
    </>
  );
};

export default CheckboxCell;
