import { FC, useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { TopForeignState } from "modules/market/redux/topForeign";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";
import { CellWrapper } from "./styles";
import { marketTopForeignSelector } from "modules/market/redux/selectors";

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector.data?.[tickerName]?.colorcode
  );
};

const changePricePercentageSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector.data?.[tickerName]?.changepercent
  );
};

interface Props {
  ticker: string;
}

const ChangePricePercentageCell: FC<Props> = ({ ticker }) => {
  const textColor = useSelector(textColorSelector(ticker));
  const changePricePercentage = useSelector(
    changePricePercentageSelector(ticker)
  );

  const formatterValue = useMemo(() => {
    if (typeof changePricePercentage === "number") {
      return numeral(changePricePercentage).format("0,0.00");
    }

    return "-";
  }, [changePricePercentage]);

  return (
    <CellWrapper
      className="market-price-cell center-for-value small-text"
      textcolor={textColor}
    >
      <BlinkableCell value={formatterValue} />
    </CellWrapper>
  );
};

export { ChangePricePercentageCell };
