import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const marginSecuritiesPortfolioSelector = (state: RootState) =>
  state.marginSecuritiesPortfolio;

export const getListMarginSecuritiesPortfolio = createSelector(
  marginSecuritiesPortfolioSelector,
  (state) => state.listMarginSecuritiesPortfolio
);
export const getMarginGroupId = createSelector(
  marginSecuritiesPortfolioSelector,
  (state) => state.marginGroupId
);
export const loadingSelector = createSelector(
  marginSecuritiesPortfolioSelector,
  (state) => state.loading
);

export const selectLimit = createSelector(
  marginSecuritiesPortfolioSelector,
  (state) => state.limit
);

export const selectCurrentPage = createSelector(
  marginSecuritiesPortfolioSelector,
  (state) => state.currentPage
);

export const selectEnableInfinity = createSelector(
  marginSecuritiesPortfolioSelector,
  (state) => state.enableInfinity
);
