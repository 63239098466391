import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled.div`
  background-color: ${({ theme }) => theme.background2};
  text-align: center;
  padding: 10px;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Body = styled.div`
  padding: 0 24px;
  background-color: ${({ theme }) => theme.grey100};

  .header {
    padding: 16px 0;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .body {
    background-color: ${({ theme }) => theme.grey100};
    border-radius: 8px;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }
  .footer {
    display: flex;
    padding: 24px 0;

    .apply-btn {
      background-color: ${({ theme }) => theme.solidBlue};
    }
    .flex-1 {
      flex: 1;
    }
    .flex-2 {
      flex: 2;
    }
    .buttonReset {
      padding: 6px 18px;
      svg {
        path {
          fill: ${({ theme }) => theme.brandPrimary};
        }
      }
      div {
        color: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
`;
export const ItemCol = styled.div<{ hasParent?: boolean }>`
  display: flex;
  margin-right: 8px;
  flex-wrap: wrap;

  ${({ hasParent }) => !hasParent && `padding-top: 12px;`}

  ${({ hasParent, theme }) =>
    !hasParent &&
    `&::after {
    display: block;
    content: "";
    width: 100%;
    margin: 12px 16px 0px 16px;
  }
  background-image: linear-gradient(
    to right,
    ${theme.border2} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;

  :last-child {
    background-image: none;
  }

  .form-check{
    margin: 0px 14px;
    padding: 6px 6px 6px 23px;
    display:flex;
    align-items:center;
    .form-check-input{
      height:18px;
      width:18px;
      margin-top: 0;
    }
    .form-check-label{
      margin-left:7px;
    }
  }
  `}
`;

export const TextButton = styled.div`
  color: ${({ theme }) => theme.brandPrimary};
  margin: 5px 6px 5px 0px;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.greyG8};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const RowScroll = styled.div`
  padding: 12px 12px 0;
  display: flex;
  > div {
    margin-right: 14px;
    >div{
      margin-top:6px ;
    }
  }
  .note{
    display: flex;
    padding: 6px 0;
    align-items: flex-end;
}
  }
`;
