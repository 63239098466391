import queryString from "query-string";
import { IGetInquiryBankUtilityParams } from "./types";

export const getInquiryBankUtilityApi = (
  params: IGetInquiryBankUtilityParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryForExecBankUtil${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const entryBankUtilityApi = "/entryBankUtility";
