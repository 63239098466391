import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAvailableTrade,
  getAvailableTradeSuccess,
  getAvailableTradeFailed,
  getOrderLimitedQty,
  getOrderLimitedQtySuccess,
  getOrderLimitedQtyFailed,
  requestPostSingleOrderPending,
  postSingleOrderPendingSuccess,
  postSingleOrderPendingFailure,
  requestPostSingleOrder,
  postSingleOrderSuccess,
  postSingleOrderFailure,
  prepareSubmitOrder,
  prepareSubmitOrderSuccess,
  prepareSubmitOrderFailure,
  findPortfolio,
  findPortfolioSuccess,
  findPortfolioFailure,
  getPriceWithStepPrice,
  getPriceWithStepPriceSuccess,
  getPriceWithStepPriceFailure,
  getCondition,
  getConditionSuccess,
  getConditionFailure,
  requestPostOrderCondition,
  postSingleOrderConditionSuccess,
  postSingleOrderConditionFailure,
  getCustomerRestriction,
  getCustomerRestrictionSuccess,
  getCustomerRestrictionFailed,
  validateCustomerInsider,
  validateCustomerInsiderSuccess,
  validateCustomerInsiderFailed,
} from "../redux/placeOrder";
import {
  GetAvailableTradeResponse,
  GetOrderLimitedQtyResponse,
} from "domain/models/Cash";
// import { RootState } from "redux/store";
import {
  getAvailableTradeApi,
  getOrderLimitedQtyApi,
} from "core/http/apis/account";
import {
  createPlaceOrder,
  createPlaceOrderCondition,
  createPlaceOrderPending,
  findPortfolioApi,
  getConditionApi,
  getCustomerRestrictionApi,
  getPriceWithStepPriceApi,
  prepareSubmitOrderApi,
  validateCustomerInsiderApi,
} from "core/http/apis/orderexec";
import {
  IFindPortfolioResponse,
  IGetConditionResponse,
  IGetCustomerRestrictionResponse,
  IGetPriceWithStepPriceResponse,
  IPlaceOrdersConditionResponse,
  IPlaceOrdersPendingResponse,
  IPlaceOrdersResponse,
  IPrepareSubmitOrderReponse,
  IValidateCustomerInsiderResponse,
} from "domain/models/OrderBook";
// import { EMarketCodeNew, TRADING_CONST } from "helper/consts";
import {
  IFindPortfolioParams,
  IGetConditionParams,
  IGetPriceWithStepPriceParams,
  IPlaceOrdersConditionParams,
  PlaceOrdersParam,
  PlaceOrdersPendingParam,
} from "core/http/apis/orderexec/types";
// import { v4 as uuidv4 } from "uuid";

function* getLastestCash(action: ReturnType<typeof getAvailableTrade>) {
  try {
    const res: GetAvailableTradeResponse = yield call(
      getAvailableTradeApi,
      action.payload
    );

    yield put({ type: getAvailableTradeSuccess.type, payload: res.data });
  } catch (error: any) {
    yield put({ type: getAvailableTradeFailed.type, payload: error });
  }
}

function* getOrderLimitedQtySaga(
  action: ReturnType<typeof getOrderLimitedQty>
) {
  try {
    const res: GetOrderLimitedQtyResponse = yield call(
      getOrderLimitedQtyApi,
      action.payload
    );

    yield put({ type: getOrderLimitedQtySuccess.type, payload: res.data });
  } catch (error: any) {
    yield put({ type: getOrderLimitedQtyFailed.type, payload: error });
  }
}

function* prepareSubmitOrderSaga(
  action: ReturnType<typeof prepareSubmitOrder>
) {
  try {
    const res: IPrepareSubmitOrderReponse = yield call(
      prepareSubmitOrderApi,
      action.payload
    );

    yield put({ type: prepareSubmitOrderSuccess.type, payload: res });
  } catch (error: any) {
    yield put({ type: prepareSubmitOrderFailure.type, payload: error });
  }
}

function* getCustomerRestrictionWorker(
  action: ReturnType<typeof getCustomerRestriction>
) {
  try {
    const res: IGetCustomerRestrictionResponse = yield call(
      getCustomerRestrictionApi,
      action.payload
    );

    yield put(getCustomerRestrictionSuccess(res));
  } catch (error: any) {
    yield put(getCustomerRestrictionFailed());
  }
}

function* validateCustomerInsiderWorker(
  action: ReturnType<typeof validateCustomerInsider>
) {
  try {
    const res: IValidateCustomerInsiderResponse = yield call(
      validateCustomerInsiderApi,
      action.payload
    );

    yield put(validateCustomerInsiderSuccess(res));
  } catch (error: any) {
    yield put(validateCustomerInsiderFailed());
  }
}

function* fetchRequestPostSingleOrder(
  action: ReturnType<typeof requestPostSingleOrder>
) {
  const paramsPlaceOrder: PlaceOrdersParam = action.payload.params;
  try {
    // Múc
    const singleOrder: IPlaceOrdersResponse = yield call(
      createPlaceOrder,
      paramsPlaceOrder
    );
    if (singleOrder) {
      yield put(postSingleOrderSuccess(singleOrder));
    }
  } catch (error: any) {
    yield put({ type: postSingleOrderFailure.type, payload: error });
  }
}

function* fetchRequestPostSingleOrderPending(
  action: ReturnType<typeof requestPostSingleOrderPending>
) {
  const paramsPlaceOrderPending: PlaceOrdersPendingParam =
    action.payload.params;
  try {
    // Múc
    const singleOrder: IPlaceOrdersPendingResponse = yield call(
      createPlaceOrderPending,
      paramsPlaceOrderPending
    );
    if (singleOrder) {
      yield put(postSingleOrderPendingSuccess(singleOrder));
    }
  } catch (error: any) {
    yield put(postSingleOrderPendingFailure(error));
  }
}

function* findPortfolioWorker(action: ReturnType<typeof findPortfolio>) {
  const params: IFindPortfolioParams = action.payload;
  try {
    // Múc
    const res: IFindPortfolioResponse = yield call(findPortfolioApi, params);
    if (res) {
      yield put(findPortfolioSuccess(res));
    }
  } catch (error: any) {
    yield put(findPortfolioFailure(error));
  }
}

function* getPriceWithStepPriceWorker(
  action: ReturnType<typeof getPriceWithStepPrice>
) {
  const params: IGetPriceWithStepPriceParams = action.payload;
  try {
    // Múc
    const res: IGetPriceWithStepPriceResponse = yield call(
      getPriceWithStepPriceApi,
      params
    );
    if (res) {
      yield put(getPriceWithStepPriceSuccess(res));
    }
  } catch (error: any) {
    yield put(getPriceWithStepPriceFailure(error));
  }
}

function* fetchGetCondition(action: ReturnType<typeof getCondition>) {
  const params: IGetConditionParams = action.payload;
  try {
    // Múc
    const res: IGetConditionResponse = yield call(getConditionApi, params);
    if (res) {
      yield put(getConditionSuccess(res));
    }
  } catch (error: any) {
    yield put(getConditionFailure(error));
  }
}

function* fetchRequestPostSingleOrderCondition(
  action: ReturnType<typeof requestPostOrderCondition>
) {
  const paramsPlaceOrderCondition: IPlaceOrdersConditionParams =
    action.payload.params;
  try {
    // Múc
    const singleOrder: IPlaceOrdersConditionResponse = yield call(
      createPlaceOrderCondition,
      paramsPlaceOrderCondition
    );
    if (singleOrder) {
      yield put(postSingleOrderConditionSuccess(singleOrder));
    }
  } catch (error: any) {
    yield put(postSingleOrderConditionFailure(error));
  }
}

function* watchGetLastestCash() {
  yield takeLatest(getAvailableTrade, getLastestCash);
}

function* watchGetOrderLimitedQty() {
  yield takeLatest(getOrderLimitedQty, getOrderLimitedQtySaga);
}

function* watchPrepareSubmitOrder() {
  yield takeLatest(prepareSubmitOrder, prepareSubmitOrderSaga);
}

function* watchPostSingleOrder() {
  yield takeLatest(requestPostSingleOrder, fetchRequestPostSingleOrder);
}

function* watchPostSingleOrderPending() {
  yield takeLatest(
    requestPostSingleOrderPending,
    fetchRequestPostSingleOrderPending
  );
}

function* watchFindPortfolio() {
  yield takeLatest(findPortfolio, findPortfolioWorker);
}

function* watchGetPriceWithStepPrice() {
  yield takeLatest(getPriceWithStepPrice, getPriceWithStepPriceWorker);
}

function* watchGetCondition() {
  yield takeLatest(getCondition, fetchGetCondition);
}

function* watchPostSingleOrderCondition() {
  yield takeLatest(
    requestPostOrderCondition,
    fetchRequestPostSingleOrderCondition
  );
}

function* watchGetCustomerRestriction() {
  yield takeLatest(getCustomerRestriction, getCustomerRestrictionWorker);
}

function* watchValidateCustomerInsider() {
  yield takeLatest(validateCustomerInsider, validateCustomerInsiderWorker);
}

export default function* placeOrderWidget() {
  yield all([
    watchGetLastestCash(),
    watchPostSingleOrder(),
    watchGetOrderLimitedQty(),
    watchPrepareSubmitOrder(),
    watchPostSingleOrderPending(),
    watchFindPortfolio(),
    watchGetPriceWithStepPrice(),
    watchGetCondition(),
    watchPostSingleOrderCondition(),
    watchGetCustomerRestriction(),
    watchValidateCustomerInsider(),
  ]);
}
