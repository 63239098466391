import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const orderBookRootSelector = (state: RootState) =>
  state.orderBookWidget.root;
export const orderBookSelector = (state: RootState) =>
  state.orderBookWidget.orderBook;

export const enableInfinitySelector = createSelector(
  orderBookRootSelector,
  (state) => state.enableInfinity
);

export const isEditCancelOrderSelector = createSelector(
  orderBookRootSelector,
  (state) => state.isEditCancelOrder
);

export const orderBookListSelector = createSelector(
  orderBookRootSelector,
  (state) => state.orderBookList
);

export const orderBookRealTimeListSelector = createSelector(
  orderBookRootSelector,
  (state) => state.orderBookRealTime
);

export const listSelectedRowSelector = createSelector(
  orderBookRootSelector,
  (state) => state.selectedRows
);

export const listSelectedRowConditionSelector = createSelector(
  orderBookRootSelector,
  (state) => state.selectedRowsCondition
);

export const listSelectedPendingRowSelector = createSelector(
  orderBookRootSelector,
  (state) => state.selectedPendingRows
);

export const orderBookConditionListSelector = createSelector(
  orderBookRootSelector,
  (state) => state.orderBookConditionList
);

export const orderBookPendingListSelector = createSelector(
  orderBookRootSelector,
  (state) => state.orderBookPendingList
);

export const filtersSelector = createSelector(
  orderBookRootSelector,
  (state) => state.filters
);

export const conditionSelector = createSelector(
  orderBookRootSelector,
  (state) => state.condition
);

export const loadListSelector = createSelector(
  orderBookRootSelector,
  (state) => state.loadList
);

export const isCheckAllSelector = createSelector(
  orderBookRootSelector,
  (state) => state.isCheckAll
);

export const orderBookSummaryListSelector = createSelector(
  orderBookRootSelector,
  (state) => state.orderBookSummaryList
);

export const orderBookSummarySelector = createSelector(
  orderBookRootSelector,
  (state) => state.orderBookSummary
);

export const cancelStatusSelector = createSelector(
  orderBookRootSelector,
  (state) => state.cancelStatus
);
