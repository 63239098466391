import storage from "helper/storage";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getCbotRequest, getcustomer2Request } from "../redux";
import { getCbot } from "../selectors";
import ModalContainer from "./components/ModalContainer";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";

const RegisterTransferBonds: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  var custNo = storage.getMasterAccount();

  const dispatch = useDispatch();
  const cbot = useSelector(getCbot);
  useEffect(() => {
    dispatch(getCbotRequest({}));
    dispatch(
      getcustomer2Request({
        custCd: "",
        custNo: custNo,
      })
    );
  }, []);

  const [visibleModalContainer, setVisibleModalContainer] = useState(true);

  const handleCloseModalContainer = () => {
    setVisibleModalContainer(false);
    history.goBack();
  };

  return (
    <Container isVisibleSideCard={false}>
      <ModalContainer
        visibleModalContainer={visibleModalContainer}
        title={
          cbot?.contractSts === 1
            ? intl.formatMessage({
                id: "registerTransferBonds.modal.titleDelte",
              })
            : intl.formatMessage({
                id: "registerTransferBonds.modal.titleRegister",
              })
        }
        handleCloseModalContainer={handleCloseModalContainer}
        contractSts={cbot?.contractSts}
      />
    </Container>
  );
};

export default RegisterTransferBonds;
