import { Select, Button } from "components/commons";
import { useMemo, useEffect } from "react";
import { FilterWrapper } from "./styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  listCbotBondPriceEstItemSelector,
  searchParamsSelector,
  loadingSearchSelector,
} from "modules/placeOrderBondAdv/selectors";
import { findCbotBondPriceEstItem } from "core/http/apis/placeOrderBondAdv/types";
import {
  changeSearchParams,
  searchRequest,
} from "modules/placeOrderBondAdv/redux";

export enum TradeType {
  ALL = "0",
  SELL = "1",
  BUY = "2",
}

export enum StatusType {
  ALL = "-1",
  NOTAPPROVED = "0",
  APPROVED = "1",
  DONE = "2",
  CANCEL = "9",
}

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const listCbotBondPriceEstItem = useSelector(
    listCbotBondPriceEstItemSelector
  );
  const { tradeType, bondCd, status } = useSelector(searchParamsSelector);

  const loading = useSelector(loadingSearchSelector);

  const listCbotBondPriceEstOptions = useMemo(() => {
    let list = [];
    list = listCbotBondPriceEstItem?.map((item: findCbotBondPriceEstItem) => ({
      label: item.bondCd,
      value: item.bondCd,
    }));
    return list;
  }, [listCbotBondPriceEstItem.length]);

  const tradeTypeOptions = [
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.tradeTypeAll",
      }),
      value: TradeType.ALL,
    },
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.tradeTypeSell",
      }),
      value: TradeType.SELL,
    },
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.tradeTypeBuy",
      }),
      value: TradeType.BUY,
    },
  ];

  const statusOptions = [
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.tradeTypeAll",
      }),
      value: StatusType.ALL,
    },
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.statusTypeNotApproved",
      }),
      value: StatusType.NOTAPPROVED,
    },
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.statusTypeApproved",
      }),
      value: StatusType.APPROVED,
    },
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.statusTypeDone",
      }),
      value: StatusType.DONE,
    },
    {
      label: intl.formatMessage({
        id: "placeOrderBondAdv.statusTypeCancel",
      }),
      value: StatusType.CANCEL,
    },
  ];

  useEffect(() => {
    dispatch(searchRequest());
  }, []);

  const handleSearch = () => {
    dispatch(searchRequest());
  };

  return (
    <FilterWrapper>
      <FilterItem
        filterName={intl.formatMessage({
          id: "placeOrderBondAdv.bondCd",
        })}
      >
        <Select
          value={
            listCbotBondPriceEstOptions?.filter(
              (item) => item.value === bondCd
            )?.[0]
          }
          isClearable
          options={listCbotBondPriceEstOptions}
          onChange={(item) => {
            dispatch(changeSearchParams({ bondCd: item?.value }));
          }}
        />
      </FilterItem>

      <FilterItem
        filterName={intl.formatMessage({
          id: "placeOrderBondAdv.tradeType",
        })}
      >
        <Select
          value={
            tradeTypeOptions.filter((item) => item.value === tradeType)?.[0]
          }
          options={tradeTypeOptions}
          onChange={(item) => {
            dispatch(changeSearchParams({ tradeType: item?.value }));
          }}
        />
      </FilterItem>

      <FilterItem
        filterName={intl.formatMessage({
          id: "placeOrderBondAdv.status",
        })}
      >
        <Select
          defaultValue={
            statusOptions.filter((item) => item.value === status)?.[0]
          }
          options={statusOptions}
          onChange={(item) => {
            dispatch(changeSearchParams({ status: item?.value }));
          }}
        />
      </FilterItem>

      <Button
        color="accept"
        width="120px"
        loading={loading}
        onClick={handleSearch}
      >
        <FormattedMessage id="transferLocal.transferLocalTab.filter.search" />
      </Button>
    </FilterWrapper>
  );
}

export default Filter;
