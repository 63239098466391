import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VolumeAnalysis, Ticker } from "domain/models/TimeAndSale";

export type VolumeAndTimeState = {
  volumeList: VolumeAnalysis[];
  historyList: Ticker[];
  loading: boolean;
  error: string | undefined;
};

const initialState: VolumeAndTimeState = {
  volumeList: [],
  historyList: [],
  loading: false,
  error: undefined,
};

const volumeAndTime = createSlice({
  name: "categoriesVolumeAndTimeSlice",
  initialState,
  reducers: {
    getVolumeAndTime: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = undefined;
    },
    getVolumeAndTimeSuccess: (
      state,
      action: PayloadAction<VolumeAnalysis[]>
    ) => {
      state.volumeList = action.payload;
      state.loading = false;
    },
    getVolumeAndTimeFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    getHistoryListSuccess: (state, action: PayloadAction<Ticker[]>) => {
      state.historyList = action.payload;
    },
    getHistoryListFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    updateHistoryListRealTime: (state, action: PayloadAction<Ticker>) => {
      state.historyList.unshift(action.payload);
    },
  },
});

export const {
  getVolumeAndTime,
  getVolumeAndTimeSuccess,
  getVolumeAndTimeFailure,
  getHistoryListSuccess,
  getHistoryListFailure,
  updateHistoryListRealTime,
} = volumeAndTime.actions;

export default volumeAndTime.reducer;
