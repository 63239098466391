import { HeaderRow } from "./styles";
import React from "react";
import classNames from "classnames";
import { ORDER } from "modules/categories/types";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SortUpIcon } from "assets/image/svg/ic_sort_up_table.svg";
import { ReactComponent as SortDownIcon } from "assets/image/svg/ic_sort_down_table.svg";
import { ReactComponent as ArrowLeft } from "assets/image/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/image/svg/arrow-right.svg";
import {
  columnsCoverWarrant,
  columnsCoverWarrantHead,
  MAPPING_VALUE_WITH_TICKER_DATA,
  WATCH_LIST_FIXED_TABS,
} from "modules/categories/constant";
import {
  categoriesSortTickersSelector,
  categoryRootSelector,
  columnsSelector,
} from "modules/categories/redux/selectors";
import { orderTickerListTable } from "modules/categories/redux/categoriesInfo";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {
  switchVisibleChangeColumnSummaryTotal,
  switchVisibleLastSaleChangeColumn,
} from "modules/categories/redux";
import { formatTableAttribute } from "modules/categories/helpers/utils";
import { useWindowSize } from "react-use";
const switchFiledAble = [
  "category.table.profitPerLoss",
  "category.table.changePercentage",
];

const switchsummaryTotalAble = [
  "category.table.sumAmount",
  "widgets.openPosition.valuePositionSummary",
];

const TableHeader = React.memo(() => {
  const { currentListCode } = useSelector(categoryRootSelector);
  const { width } = useWindowSize();
  const tableAttribute = () => {
    if (width > 1919) {
      return formatTableAttribute(columns, "minWidth");
    } else {
      return formatTableAttribute(columns, "smallDeviceMinWidth");
    }
  };
  const sortTickers = useSelector(categoriesSortTickersSelector);
  const dispatch = useDispatch();
  const columns = useSelector(columnsSelector);
  const handleSortTickersList = (sortField?: string) => {
    if (!sortField) return;
    if (sortField === sortTickers.sortField) {
      if (sortTickers.sortDirection === ORDER.UNKNOW) {
        dispatch(
          orderTickerListTable({
            sortField,
            sortDirection: ORDER.ASC,
          })
        );
        return;
      }
      if (sortTickers.sortDirection === ORDER.ASC) {
        dispatch(
          orderTickerListTable({
            sortField,
            sortDirection: ORDER.DESC,
          })
        );
        return;
      }
      dispatch(
        orderTickerListTable({
          sortField,
          sortDirection: ORDER.UNKNOW,
        })
      );
    } else {
      dispatch(
        orderTickerListTable({
          sortField,
          sortDirection: ORDER.ASC,
        })
      );
    }
  };

  const hiddenSortIcon = (sortField: string, sortDirection: "asc" | "desc") => {
    if (isEmpty(sortField)) {
      return true;
    }
    return (
      sortField !== sortTickers.sortField ||
      sortDirection !== sortTickers.sortDirection
    );
  };

  const getClassSortColumn = (
    column: string,
    sortDirection: "asc" | "desc"
  ) => {
    return classNames({
      "hidden-sort-icon": hiddenSortIcon(column, sortDirection),
    });
  };

  const switchColumnProfitPerLost = (event: any) => {
    event.stopPropagation();
    dispatch(switchVisibleLastSaleChangeColumn());
  };

  const switchColumnSummaryTotal = (event: any) => {
    event.stopPropagation();
    dispatch(switchVisibleChangeColumnSummaryTotal());
  };

  const renderContentLargeHeader = (sortName: string, labelId: string) => {
    if (switchsummaryTotalAble.includes(labelId)) {
      return (
        <>
          <SortUpIcon className={getClassSortColumn(sortName, "asc")} />
          <div className="summary-total">
            <ArrowLeft
              className="profit-arrow-left"
              onClick={switchColumnSummaryTotal}
            />
            <FormattedMessage id={labelId} />
            <ArrowRight
              className="profit-arrow-right"
              onClick={switchColumnSummaryTotal}
            />
          </div>
          <SortDownIcon className={getClassSortColumn(sortName, "desc")} />
        </>
      );
    }

    return (
      <>
        <SortUpIcon className={getClassSortColumn(sortName, "asc")} />
        <FormattedMessage id={labelId} />
        <SortDownIcon className={getClassSortColumn(sortName, "desc")} />
      </>
    );
  };

  const renderContentSmallHeader = (sortName: string, labelId: string) => {
    if (switchFiledAble.includes(labelId)) {
      return (
        <div className="profit-per-lost">
          <ArrowLeft
            className="profit-arrow-left"
            onClick={switchColumnProfitPerLost}
          />
          {!hiddenSortIcon(sortName, "asc") && <SortUpIcon />}
          <FormattedMessage id={labelId} />
          {!hiddenSortIcon(sortName, "desc") && <SortDownIcon />}
          <ArrowRight
            className="profit-arrow-right"
            onClick={switchColumnProfitPerLost}
          />
        </div>
      );
    }
    return (
      <>
        {!hiddenSortIcon(sortName, "asc") && <SortUpIcon />}
        <FormattedMessage id={labelId} />
        {!hiddenSortIcon(sortName, "desc") && <SortDownIcon />}
      </>
    );
  };

  const renderColumnHeader = (
    sortName: string,
    labelId: string,
    styles: string,
    key: string | number,
    isSmallContent?: boolean
  ) => {
    if (isSmallContent) {
      return (
        <div
          key={key}
          onClick={() => handleSortTickersList(sortName)}
          className={styles}
        >
          {renderContentSmallHeader(sortName, labelId)}
        </div>
      );
    }
    return (
      <div
        key={key}
        onClick={() => handleSortTickersList(sortName)}
        className={styles}
      >
        {renderContentLargeHeader(sortName, labelId)}
      </div>
    );
  };
  return (
    <HeaderRow
      sumWidth={tableAttribute().sumWidth}
      askWidth={tableAttribute().askWidth}
      bidWidth={tableAttribute().bidWidth}
      foreignWidth={tableAttribute().foreignWidth}
      priceWidth={tableAttribute().priceWidth}
      lastSaleWidth={tableAttribute().lastSaleWidth}
      coverWarrantWidth={tableAttribute().coverWarrantWidth}
      coverWarrantHeadWidth={tableAttribute().coverWarrantHeadWidth}
      columns={columns}
    >
      {renderColumnHeader(
        MAPPING_VALUE_WITH_TICKER_DATA.tickerName,
        "category.table.ticker",
        classNames(
          { "remove-box-shadow": columns.CHART.visible },
          "ticker-name-col flex-content text-left sort-item"
        ),
        "category.table.ticker"
      )}
      {currentListCode === "CoverWarrant" && (
        <div className="flex-content text-right cover-warrant-head-group">
          {Object.keys(
            columnsCoverWarrantHead.COVER_WARRANT_HEAD.children!
          ).map((colKey) => {
            const item = columns.COVER_WARRANT_HEAD.children![colKey];
            return renderColumnHeader(
              item.sortName ?? "",
              columns.COVER_WARRANT_HEAD.children![colKey].name ??
                "category.table.noContent",
              `${colKey.toLowerCase()} sort-item`,
              colKey
            );
          })}
        </div>
      )}
      {columns.SUMMARY?.children?.FLOOR?.visible &&
        renderColumnHeader(
          MAPPING_VALUE_WITH_TICKER_DATA.ceilingPrice,
          "category.table.ceiling",
          "bg-overlay-col opening-price ceiling-price sort-item",
          "category.table.ceiling"
        )}
      {columns.SUMMARY?.children?.CEIL?.visible &&
        renderColumnHeader(
          MAPPING_VALUE_WITH_TICKER_DATA.floorPrice,
          "category.table.floor",
          "bg-overlay-col opening-price floor-price sort-item",
          "category.table.floor"
        )}
      {columns.SUMMARY?.children?.REFER?.visible &&
        renderColumnHeader(
          MAPPING_VALUE_WITH_TICKER_DATA.referencePrice,
          "category.table.referenceShort",
          "bg-overlay-col opening-price reference-short-price sort-item",
          "category.table.referenceShort"
        )}
      <div className="bid-col special-col">
        <div>
          <FormattedMessage id="category.table.overBought" />
        </div>
        <div className="flex-content text-right">
          {columns.BID?.children &&
            Object.keys(columns.BID?.children).map((col, idx) => {
              const item = columns.BID.children![col];
              return (
                item.visible &&
                renderColumnHeader(
                  item.sortName ?? "",
                  item.nameCol ?? "category.table.noContent",
                  `${col.toLowerCase()} sort-item`,
                  item.name ?? idx,
                  true
                )
              );
            })}
        </div>
      </div>
      <div className="last-sale-col special-col bg-overlay-col">
        <div>
          <FormattedMessage id="category.table.filled" />
        </div>
        <div className="flex-content text-right">
          {renderColumnHeader(
            MAPPING_VALUE_WITH_TICKER_DATA.lastSalePrice,
            "category.table.price",
            "last-sale-price sort-item",
            "category.table.price",
            true
          )}
          {get(columns, "LAST_SALE.children.CHANGE_PRICE.visible", false) &&
            renderColumnHeader(
              MAPPING_VALUE_WITH_TICKER_DATA.lastSaleChangePriceValue,
              "category.table.profitPerLoss",
              "last-sale-margin sort-item",
              "category.table.profitPerLoss",
              true
            )}
          {get(
            columns,
            "LAST_SALE.children.CHANGE_PERCENTAGE.visible",
            false
          ) &&
            renderColumnHeader(
              MAPPING_VALUE_WITH_TICKER_DATA.lastSaleChangePercentageValue,
              "category.table.changePercentage",
              "last-sale-margin sort-item",
              "category.table.changePercentage",
              true
            )}
          {renderColumnHeader(
            MAPPING_VALUE_WITH_TICKER_DATA.lastSaleChangeVolumeValue,
            "category.table.amount",
            "last-sale-amount sort-item",
            "category.table.amount"
          )}
        </div>
      </div>
      <div className="ask-col special-col">
        <div>
          <FormattedMessage id="category.table.overSold" />
        </div>
        <div className="flex-content text-right">
          {columns.ASK?.children &&
            Object.keys(columns.ASK?.children).map((col, idx) => {
              const item = columns.ASK.children![col];
              return (
                item.visible &&
                renderColumnHeader(
                  item.sortName ?? "",
                  item.nameCol ?? "category.table.noContent",
                  `${col.toLowerCase()} sort-item`,
                  item.name ?? idx,
                  true
                )
              );
            })}
        </div>
      </div>
      {get(columns, "VOLUME.visible", false) &&
        renderColumnHeader(
          MAPPING_VALUE_WITH_TICKER_DATA.summaryTotalSharesTradedmt,
          "category.table.sumAmount",
          "total-volume-col sort-item",
          "category.table.sumAmount"
        )}

      {get(columns, "AMT.visible", false) &&
        renderColumnHeader(
          MAPPING_VALUE_WITH_TICKER_DATA.summaryTotalAmt,
          "widgets.openPosition.valuePositionSummary",
          "total-volume-col sort-item",
          "category.table.sumAmount"
        )}

      {currentListCode !== WATCH_LIST_FIXED_TABS.ODDLOT && (
        <>
          <div className="price-col special-col bg-overlay-col">
            <div>
              <FormattedMessage id="category.table.price" />
            </div>
            <div className="flex-content text-right">
              {columns.PRICE?.children?.HIGH?.visible &&
                renderColumnHeader(
                  MAPPING_VALUE_WITH_TICKER_DATA.summaryHighestPrice,
                  "category.table.high",
                  "high sort-item",
                  "category.table.high"
                )}
              {columns.PRICE?.children?.LOW?.visible &&
                renderColumnHeader(
                  MAPPING_VALUE_WITH_TICKER_DATA.summaryLowestPrice,
                  "category.table.low",
                  "low sort-item",
                  "category.table.low"
                )}
              {columns.PRICE?.children?.AVERAGE?.visible &&
                renderColumnHeader(
                  MAPPING_VALUE_WITH_TICKER_DATA.summaryAveragePrice,
                  "category.table.average",
                  "average sort-item",
                  "category.table.average"
                )}
            </div>
          </div>
          <div className="foreign-col special-col">
            <div>
              <FormattedMessage id="category.table.foreign" />
            </div>
            <div className="flex-content text-right">
              {columns.FOREIGN?.children &&
                Object.keys(columns.FOREIGN?.children).map((col, idx) => {
                  const item = columns.FOREIGN.children![col];
                  return (
                    item.visible &&
                    renderColumnHeader(
                      item.sortName ?? "",
                      item.nameCol ?? "category.table.noContent",
                      `${col.toLowerCase()} sort-item`,
                      item.name ?? idx,
                      true
                    )
                  );
                })}
            </div>
          </div>
        </>
      )}
      {currentListCode === "CoverWarrant" && (
        <div className="flex-content text-right cover-warrant-group">
          {Object.keys(columnsCoverWarrant.COVER_WARRANT.children!).map(
            (colKey) => {
              const item = columns.COVER_WARRANT.children![colKey];
              return renderColumnHeader(
                item.sortName ?? "",
                columns.COVER_WARRANT.children![colKey].name ??
                  "category.table.noContent",
                `${colKey.toLowerCase()} sort-item`,
                colKey
              );
            }
          )}
        </div>
      )}
    </HeaderRow>
  );
});

export default TableHeader;
