import { memo } from "react";
import { MarketWatchTab } from "./types";
import { ContentContainer } from "./styles";
import Liquidity from "../Liquidity";
import StatBasketContribution from "../StatBasketContribution";
import TransactionValueVolume from "../TransactionValueVolume";
import Capitalization from "../Capitalization";
import MarketStats from "../MarketStats";

export interface ContentProps {
  activeTab: MarketWatchTab;
}

function Content({ activeTab }: ContentProps) {
  return (
    <ContentContainer>
      {activeTab === "topIndex" && <StatBasketContribution />}
      {activeTab === "liquidity" && <Liquidity />}
      {activeTab === "transactionValueVolume" && <TransactionValueVolume />}
      {activeTab === "capitalization" && <Capitalization />}
      {activeTab === "marketRange" && <MarketStats />}
    </ContentContainer>
  );
}

export default memo(Content);
