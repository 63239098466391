import { useEffect } from "react";
import themes from "themes/abstracts/_themes";
import render, { div } from "helper/domBuilder";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { ChartData } from "modules/categories/types";
import { ChartOptions, ChartTooltipModel } from "chart.js";
import ChartComponent from "react-chartjs-2";
import {
  DerivativeLineColorPlugin,
  DrawHorizontalLinePlugin,
  DrawVerticalLineOnHoverPlugin,
} from "helper/chartPlugins";
import numeral from "numeral";
import { createElementFromHTML, updateDomStyle } from "helper/domBuilder/utils";
import moment from "moment";
import { useIntl } from "react-intl";
import { ChartBox, ChartContainer } from "./styles";
import { PriceInfo } from "domain/protoNew/auto_trading_pb";
import maxBy from "lodash/maxBy";
import last from "lodash/last";

const plugins = [
  DerivativeLineColorPlugin,
  DrawVerticalLineOnHoverPlugin,
  DrawHorizontalLinePlugin,
];

function StockOrderChart({
  dataChart,
  thresholdValue,
}: {
  dataChart: PriceInfo.AsObject[];
  thresholdValue: number;
}) {
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const intl = useIntl();
  const lineColors = dataChart.map((item) =>
    item.price > thresholdValue
      ? themes[themeType].green50
      : themes[themeType].red50
  );
  const listTime = dataChart.map((item) => item.time);
  const timeChart = last(listTime);

  const chartData = {
    labels: listTime,
    datasets: [
      {
        label: "widgets.derivative.chart.closed",
        lineTension: 0.1,
        data: dataChart.map((item) => item.price),
        backgroundColor: lineColors,
        borderColor: "gray",
        borderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 10,
        yAxisID: "closedLine",
        showLine: true,
        type: "line",
        fill: false,
      },
      {
        label: "widgets.derivative.chart.volume",
        barThickness: 1,
        grouped: true,
        barPercentage: 1,
        categoryPercentage: 1,
        lineTension: 0,
        yAxisID: "volumeBar",
        data: dataChart.map((item) => item.qty),
        backgroundColor: themes[themeType].blue50,
        type: "bar",
        fill: true,
        order: 2,
      },
    ],
    thresholdValue: thresholdValue,
    lineColor: {
      positive: themes[themeType].green50,
      negative: themes[themeType].red50,
      vertical: themes[themeType].grey50,
      horizontal: themes[themeType].yellow50,
    },
  } as ChartData;

  const option: ChartOptions = {
    maintainAspectRatio: false,
    onHover: function (evt, elements) {
      let tooltipEl = document.getElementById("chartjs-tooltip");
      if (elements && elements.length) {
        return;
      }
      if (tooltipEl) {
        updateDomStyle(tooltipEl, {
          opacity: "0",
        });
      }
    },
    layout: {
      padding: {
        bottom: 24,
        top: 24,
      },
    },
    animation: {
      // duration: 0, // general animation time
    },
    responsiveAnimationDuration: 0, // anim
    hover: {
      intersect: false,
      animationDuration: 0, // duration of animations when hovering an item
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2, 4],
            color: "rgba(206, 209, 214, 0.05)",
          },
          id: "x-axis-0",
          type: "time",
          distribution: "linear",
          time: {
            unit: "minute",
            displayFormats: {
              minute: "HH:mm",
            },
          },
          ticks: {
            fontFamily: themes[themeType].fontFamily,
            display: true,
            maxTicksLimit: 6,
            source: "auto",
            fontColor: themes[themeType].grey50,
            maxRotation: 0,
            minRotation: 0,
            min: moment(timeChart).hour(9).minute(0).second(0).millisecond(0),
            max: moment(timeChart).hour(15).minute(0).second(0).millisecond(0),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2, 4],
            color: "rgba(206, 209, 214, 0.05)",
          },
          ticks: {
            fontFamily: themes[themeType].fontFamily,
            fontColor: themes[themeType].grey50,
            maxTicksLimit: 5,
            callback: function (value: any) {
              return numeral(value).format("0.00");
            },
          },
          id: "closedLine",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({
              id: "category.table.price",
            }),
            fontColor: themes[themeType].grey50,
            fontFamily: themes[themeType].fontFamily,
            fontSize: 12,
          },
        },
        {
          gridLines: {
            display: true,
          },
          ticks: {
            fontFamily: themes[themeType].fontFamily,
            fontColor: themes[themeType].grey50,
            maxTicksLimit: 8,
            min: 0,
            suggestedMax: (maxBy(dataChart, "qty")?.qty as number) * 1.5,
            callback: function (value: any) {
              return numeral(value).format("0,0a");
            },
          },
          id: "volumeBar",
          position: "right",
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({
              id: "category.table.amount",
            }),
            fontColor: themes[themeType].grey50,
            fontFamily: themes[themeType].fontFamily,
            fontSize: 12,
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
      intersect: false,
      mode: "index",
      custom: function tooltip(tooltipModel: ChartTooltipModel): void {
        let tooltipEl = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = createElementFromHTML(
            render(
              div({
                id: "chartjs-tooltip",
                children: div({
                  style: {
                    color: themes[themeType].textcolorWhite,
                    "background-color": themes[themeType].background1,
                    border: "px solid black",
                    "min-width": "150px",
                    padding: "8px 12px",
                    "margin-top": "20px",
                    "margin-left": "-20px",
                  },
                  children: "",
                }),
              })
            )
          ) as HTMLElement;
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          updateDomStyle(tooltipEl, {
            opacity: "0",
          });
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        tooltipEl.className = "";
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        // Set Text
        if (tooltipModel.body) {
          let dataPoint = tooltipModel.dataPoints[0];
          let timeDisplayText = "";
          let priceDisplayText = "";
          let volumeDisplayText = "";
          let priceDisplayColor = themes[themeType].blue50;

          if (dataPoint && dataPoint.index != null) {
            let priceItem = chartData.datasets![0].data![dataPoint.index];

            let volumeItem = chartData.datasets![1].data![dataPoint.index];
            timeDisplayText = moment(dataPoint.label).format("HH:mm:ss");
            priceDisplayText = `${numeral(priceItem).format("0.00")}`;
            priceDisplayColor =
              priceItem >= chartData.thresholdValue
                ? themes[themeType].green50
                : themes[themeType].red50;
            volumeDisplayText = `${numeral(volumeItem).format("0,0")}`;
          }

          const timeElement = div({
            children: timeDisplayText,
            style: {
              "font-weight": `${themes[themeType].typeFaces.body.weight.regular}`,
              "font-size": themes[themeType].typeFaces.body.size,
              "line-height": "20px",
              "margin-bottom": "4px",
              color: themes[themeType].textcolorWhite,
            },
          });
          const priceElement = div({
            children: [
              div({
                children: `${intl.formatMessage({
                  id: "category.table.price",
                })}: `,
                style: {
                  color: themes[themeType].textcolorWhite,
                },
              }),
              div({
                children: priceDisplayText,
                style: {
                  color: priceDisplayColor,
                  "margin-left": "4px",
                },
              }),
            ],
            style: {
              display: "flex",

              "font-weight": `${themes[themeType].typeFaces.body.weight.regular}`,
              "font-size": themes[themeType].typeFaces.body.size,
              "line-height": "20px",
              "margin-bottom": "4px",
            },
          });
          const volumeElement = div({
            children: [
              div({
                children: `${intl.formatMessage({
                  id: "category.table.amount",
                })}: `,
                style: {
                  color: themes[themeType].textcolorWhite,
                },
              }),
              div({
                children: volumeDisplayText,
                style: {
                  color: themes[themeType].blue50,
                  "margin-left": "4px",
                },
              }),
            ],
            style: {
              display: "flex",
              "font-weight": `${themes[themeType].typeFaces.body.weight.regular}`,
              "font-size": themes[themeType].typeFaces.body.size,
              "line-height": "20px",
              "margin-bottom": "4px",
            },
          });

          const tooltipElement = div({
            children: [timeElement, priceElement, volumeElement],
          });

          let root = tooltipEl.children[0];
          root.innerHTML = render(tooltipElement);
        }
        let position = (this as any)._chart.canvas.getBoundingClientRect();

        updateDomStyle(tooltipEl, {
          opacity: "1",
          position: "absolute",
          "pointer-events": "none",
          left: position.left + window.pageXOffset + tooltipModel.caretX + "px",
          top: position.top + window.pageYOffset + tooltipModel.caretY + "px",
          "font-size": tooltipModel.bodyFontSize + "px",
          "font-style": tooltipModel._bodyFontStyle,

          padding: tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px",
        });
      },
    },
  };

  useEffect(() => {
    return () => {
      let tooltipEl = document.getElementById("chartjs-tooltip");

      if (tooltipEl) {
        updateDomStyle(tooltipEl, {
          opacity: "0",
        });
      }
    };
  }, []);

  return (
    <ChartContainer>
      <ChartBox>
        {chartData.labels.length > 0 && (
          <ChartComponent
            type="line"
            data={chartData}
            options={option}
            plugins={plugins}
          />
        )}
      </ChartBox>
    </ChartContainer>
  );
}

export default StockOrderChart;
