import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import { searchStatisticUpdown } from "core/http/apis/statistic-updown";
import { ISearchStatisticUpdownParams } from "core/http/apis/statistic-updown/types";
import { IMarketStatisticResponse } from "domain/models/MarketStatistic";
import {
  searchStatisticUpdownFailure,
  searchStatisticUpdownRequest,
  searchStatisticUpdownSuccess,
} from "./redux";

function* searchStatisticUpdownWorker(
  action: ReturnType<typeof searchStatisticUpdownRequest>
) {
  try {
    const params: ISearchStatisticUpdownParams = action.payload;
    const response: IMarketStatisticResponse = yield call(
      searchStatisticUpdown,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchStatisticUpdownSuccess(response));
    } else {
      yield put(searchStatisticUpdownFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchStatisticUpdownFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchStatisticUpdownWatcher() {
  yield takeLatest(
    searchStatisticUpdownRequest.type,
    searchStatisticUpdownWorker
  );
}

export default function* statisticUpdownSaga() {
  yield all([searchStatisticUpdownWatcher()]);
}
