import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { WSS_ORDER_URL } from "AppConfig";
import { store } from "redux/store";
import {
  reconnectWebSocketSuccess,
  setStatusInternet,
} from "modules/system/redux/common";
import { w3cwebsocket, IMessageEvent } from "websocket";
import ReconnectingWebSocket from "reconnecting-websocket";
import { DataJson } from "./types";
import { OrderBookItem } from "domain/models/OrderBook";
import { QRCodeMessage } from "domain/models/QRCodeMessage";
import { OrderBookDerItem } from "domain/models/OrderBookDer";

const host = WSS_ORDER_URL;

export enum WSS_TYPE {
  ERROR = "error",
  MESSAGE = "message",
  CONNECT = "connect",
  OFFLINE = "offline",
  RECONNECT = "reconnect",
}

enum TOPIC {
  QR_CODE = "qrCode",
}

enum EVENT_NAME {
  REGISTER = "register",
  UNREGISTER = "unregister",
  AUTHENTICATE = "authenticate",
}

enum DATA_MESSAGE_TYPE {
  ORDER = "OrderEvent",
  ORDER_DER = "DertOrderEventPT",
  QRCODE = "QRResultData",
}

// catch message by type
const parseData = (message: IMessageEvent) => {
  if (typeof message.data === "string") {
    // ping pong
    if (message.data === "2") {
      client.send("3");
      return;
    }
    const dataMessage: DataJson = JSON.parse(message.data.substr(1));

    // catch order
    if (dataMessage.type === DATA_MESSAGE_TYPE.ORDER) {
      const dataEmit: OrderBookItem = JSON.parse(dataMessage.data);
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_Order, dataEmit);
      return;
    }

    // catch order der
    if (dataMessage.type === DATA_MESSAGE_TYPE.ORDER_DER) {
      const dataEmit: OrderBookDerItem = JSON.parse(dataMessage.data);
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_Order_Der, dataEmit);
      return;
    }

    // catch qr code
    if (dataMessage.type === DATA_MESSAGE_TYPE.QRCODE) {
      const dataEmit: QRCodeMessage = JSON.parse(dataMessage.data);
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_DATA_QR_CODE, dataEmit);
      return;
    }
  }
};

const options = {
  WebSocket: w3cwebsocket, // custom WebSocket constructor
  connectionTimeout: 10000,
  maxRetries: 10,
};

const client = new ReconnectingWebSocket(host, [], options);
client.binaryType = "arraybuffer";
client.onopen = () => {
  console.warn("WSS: connected", new Date());
  // Icon connect success
  // subscribeSystmState();
  store.dispatch(reconnectWebSocketSuccess());
  store.dispatch(setStatusInternet(WSS_TYPE.CONNECT));
};

client.onerror = (err: any) => {
  // Icon RECONNECT
  console.warn("WSS: Connection error: ", new Date(), { err });
  store.dispatch(setStatusInternet(WSS_TYPE.RECONNECT));
};

client.onclose = (err: any) => {
  // Icon connect OFFLINE
  console.warn("WSS: onclose error: ", new Date(), { err });
  store.dispatch(setStatusInternet(WSS_TYPE.OFFLINE));
};

client.reconnect = (err: any) => {
  // Icon reconnect
  console.warn("WSS: reconnect ", new Date(), { err });
};

client.onmessage = (event: any) => parseData(event);

export const subscribeOrderData = (token: string) => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: "authenticate",
      args: token,
      channelTopic: "order",
    };
    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      subscribeOrderData(token);
    }, 1000);
  }
};

export const subscribeQRTopic = (token: string) => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: EVENT_NAME.AUTHENTICATE,
      channelTopic: TOPIC.QR_CODE,
      args: token,
    };
    // store.dispatch(addIndexScrible(Index));

    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      subscribeQRTopic(token);
    }, 1000);
  }
};

export default client;
