import styled from "styled-components/macro";

export const FinanceWrapper = styled.div`
  left: 0;
  top: 96px;
  border-radius: 0;
  padding: 12px;

  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};

  .watchMore {
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }

  .cash-flow {
    box-shadow: unset;
  }

  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.bgElevated1};
  scrollbar-width: thin;

  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }

  .span-select-option {
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    letter-spacing: 0;
  }
`;

export const MainIndicatorWrapper = styled.div`
  .main-label {
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    text-align: left;
  }
`;

export const TickerCode = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  cursor: pointer;
  color: ${({ theme }) => theme.grey0};
`;

export const TickerName = styled.div`
  color: ${({ theme }) => theme.greyG1};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
`;

export const ContentContainer = styled.div`
  h2 {
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    margin-bottom: 20px;
    color: ${({ theme }) => theme.grey0};
  }
`;

export const GroupTitle = styled.div`
  margin-bottom: 14px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 16px;
`;
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelColorBox = styled.div<{
  color: string;
}>`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 2px;
  background-color: ${({ theme, color }) =>
    theme[color] ? theme[color] : "white"};
`;

export const LabelName = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.grey20};
`;

export const FinanceChartContainer = styled.div<{ height: number }>`
  position: relative;
  height: ${({ height }) => height}px;
`;
