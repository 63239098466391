import { convertHexToRGBA } from "helper/utils";
import styled from "styled-components/macro";

export const OrderBookWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: stretch;
  justify-content: flex-start;
  .span-select-industry {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    color: ${({ theme }) => theme.neutral2};
    vertical-align: middle;
    display: table-cell;
  }
`;

export const TickerCode = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  cursor: pointer;
  color: ${({ theme }) => theme.neutral1};
`;

export const TickerName = styled.div`
  color: ${({ theme }) => theme.neutral2};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
`;

export const TextColor = styled.div<{ color: string }>`
  color: ${(props) => props.theme[props.color]};
`;

export const StatusContainer = styled.div<{
  status: string | undefined;
  progress?: number;
}>`
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  white-space: nowrap;
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};

  ${({ status, theme, progress = 0 }) => {
    switch (status) {
      case "success":
        return `
          background: ${theme.green50};
          color: ${theme.textcolorWhite};
         `;
      case "reject":
        return `
          background: ${theme.red50};
          color: ${theme.textcolorWhite};
        `;
      case "reject-text-red":
        return `
          background: ${theme.brandPrimary};
          color: ${theme.red50};
        `;
      case "progress":
      case "edited":
        return `
          background: linear-gradient(90deg, ${theme.green50} ${progress}%, ${theme.brandPrimary} 0%);
          color: ${theme.textcolorWhite};
        `;
      default:
        return `
          background: ${theme.brandPrimary};
          color: ${theme.textcolorWhite};
        `;
    }
  }};
  border-radius: 4px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-transform: uppercase;
  & > div {
    align-items: center;
    justify-content: center;
  }
`;

export const PopConfirmOrderContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  background-color: ${({ theme }) => theme.background1};
  overflow: hidden;
`;

export const PopConfirmOrderTitle = styled.div`
  background-color: ${({ theme }) => theme.background3};
  text-align: center;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  .flex-1 {
    flex: 1;
  }
`;

export const PopConfirmOrderBody = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  padding: 0 24px;
  background-color: ${({ theme }) => theme.background2};

  .header {
    padding: 8px 0;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  }
  .body {
    background-color: ${({ theme }) => theme.bgElevated2};
    border-radius: 8px;
  }
  .order-info {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.borderColor} 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 18px 1px;
    background-repeat: repeat-x;
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: ${({ theme }) => theme.grey20};
    .label {
      color: ${({ theme }) => theme.grey20};
    }
    text-align: center;
    .text-white-neutral1 {
      color: ${({ theme }) => theme.grey0};
    }
  }
  .text-bold {
    color: ${({ theme }) => theme.neutral1};
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  }

  .text-small {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
  .footer {
    display: flex;
    margin: 0 16px;
    border-radius: 8px;

    .flex-1 {
      flex: 1;
    }
    .apply-btn {
      background-color: ${({ theme }) => theme.solidBlue};
    }
  }
  .edit {
    &_body {
      display: block;
      padding: 25px 10px 0 10px;
      .d-flex {
        justify-content: space-between;
        .w-80 {
          width: 280px;
        }
      }
    }
    &_footer {
      display: flex;
      justify-content: space-around;
      padding: 30px 0;
      .btn {
        width: 190px;
        &.btn-outline-primary {
          background-color: ${({ theme }) => theme.bgElevated2};
          &:hover {
            background-color: ${({ theme }) => theme.bgElevated3};
          }
        }
        &.btn-success {
          background-color: ${({ theme }) => theme.bgGreen};
        }
      }
    }
  }
`;

export const ButtonSubmit = styled.div<{ color: string }>`
  background: ${({ theme, color }) => theme[color]};
  color: ${({ theme }) => theme.neutral1};
  border-radius: 8px;
  padding: 8px;
  height: ${({ theme }) => theme.button.height};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  :hover {
    background: ${({ theme, color }) => convertHexToRGBA(theme[color], 0.7)};
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  table {
    td {
      border: none;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      white-space: nowrap;
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      padding: 8px 16.8px;
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      text-overflow: ellipsis;
    }
  }
`;

export const TableContainer = styled.div<{ heighFooter: number | undefined }>`
  // Scroll Stiker - start
  height: 100%;
  overflow: auto;
  margin-bottom: 40px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
  // Scroll Stiker - end
`;

export const RejectReason = styled.div<{ color?: string }>`
  color: ${({ color, theme }) => color && theme[color]};
`;

export const LinkWrapper = styled.div`
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  text-align: center;
`;

export const Link = styled.div`
  color: ${({ theme }) => theme.solidBlue};
  cursor: pointer;
  display: inline-block;
  svg {
    margin-bottom: 1px;
    margin-left: 13px;
    width: 7px;
    height: 12px;
  }
`;

export const StatusCell = styled.div<{
  textColor?: string;
}>`
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.neutral1)};
`;

export const TitleContent = styled.div<{ side: string }>`
  flex: 3;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  ${({ side, theme }) => {
    switch (side) {
      case "common.long":
        // success
        return `color: ${theme.green50}`;
      case "common.short":
        // reject
        return `color: ${theme.red50}`;
      default:
        // pending
        return `color: ${theme.textcolorWhite}`;
    }
  }};
  text-align: center;
`;

export const ButtonExit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const TextTradeType = styled.span<{
  side: string | undefined;
}>`
  text-transform: uppercase;
  ${({ side, theme }) => {
    switch (side) {
      case "common.long":
        // success
        return `color: ${theme.green50}`;
      case "common.short":
        // reject
        return `color: ${theme.red50}`;
      default:
        // pending
        return `color: ${theme.textcolorWhite}`;
    }
  }};
`;

export const Button = styled.button<{
  side: string | undefined;
}>`
  width: 120px;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.textcolorWhite};
  :hover {
    color: ${({ theme }) => theme.textcolorWhite};
  }
  ${({ side, theme }) => {
    switch (side) {
      case "buy": // success
        return `background: ${theme.green50}`;
      case "sell": // reject
        return `background: ${theme.red50}`;
      case "E": // reject
        return `background: ${theme.red50}`;
      case "EDIT":
        return `background: ${theme.brandPrimary}`;
      default:
        // pending
        return "display: none";
    }
  }};
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  border-radius: 50px;
  height: 20px;
  width: 20px;
`;
