import { all, takeEvery } from "redux-saga/effects";
import { setCurrentMarketCode, setVisibleTickerDetailsModal } from "../redux";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";
import Storage from "helper/storage";
import { changeTradingDates } from "../redux/netflow";
import { requestChangePeriod as changePeriodTopPriceLeft } from "../redux/topPriceLeft";
import { requestChangePeriod as changePeriodTopPriceRight } from "../redux/topPriceRight";
import { requestChangeTopStatisticalType } from "../redux/topStatistical";
import { requestChangeTopForeignType } from "../redux/topForeign";

function setCurrentMarketCodeLogDataWorker(
  action: ReturnType<typeof setCurrentMarketCode>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeIndex,
    data: action.payload?.toString(),
  };
  Storage.commonPushLogFirebase(resLogData);
}

function setVisibleTickerDetailsModalLogDataWorker(
  action: ReturnType<typeof setVisibleTickerDetailsModal>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.stockDetail,
    data: action.payload,
  };
  Storage.commonPushLogFirebase(resLogData);
}

function changeTradingDatesLogDataWorker(
  action: ReturnType<typeof changeTradingDates>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeDayNetFlow,
    data: { tradingDate: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);
}

function changePeriodTopPriceLeftLogDataWorker(
  action: ReturnType<typeof changePeriodTopPriceLeft>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeTypeTopAdvancers,
    data: { period: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);
}

function changePeriodTopPriceRightLogDataWorker(
  action: ReturnType<typeof changePeriodTopPriceRight>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeTypeTopDecliners,
    data: { period: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);
}

function topStatisticalTypeLogDataWorker(
  action: ReturnType<typeof requestChangeTopStatisticalType>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeTypeTopSec,
    data: { topStatisticalType: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);
}

function changeTopForeignTypeLogDataWorker(
  action: ReturnType<typeof requestChangeTopForeignType>
) {
  const resLogData = {
    eventGroup: GroupEvent.market,
    event: RealtimeConst.changeTypeTopForeign,
    data: { topForeignType: action.payload },
  };
  Storage.commonPushLogFirebase(resLogData);
}

export default function* logTrackingSaga() {
  yield all([
    takeEvery(setCurrentMarketCode.type, setCurrentMarketCodeLogDataWorker),
    takeEvery(
      setVisibleTickerDetailsModal.type,
      setVisibleTickerDetailsModalLogDataWorker
    ),
    takeEvery(changeTradingDates.type, changeTradingDatesLogDataWorker),
    takeEvery(
      changePeriodTopPriceLeft.type,
      changePeriodTopPriceLeftLogDataWorker
    ),
    takeEvery(
      changePeriodTopPriceRight.type,
      changePeriodTopPriceRightLogDataWorker
    ),
    takeEvery(
      requestChangeTopStatisticalType.type,
      topStatisticalTypeLogDataWorker
    ),
    takeEvery(
      requestChangeTopForeignType.type,
      changeTopForeignTypeLogDataWorker
    ),
  ]);
}
