import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  height: 100%;
  width: 100%;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 25%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  background: ${({ theme }) => theme.background1};
`;
export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  height: 100%;
  width: 75%;
`;
export const WrapperTop = styled.div`
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  background: ${({ theme }) => theme.background1};
`;
export const WrapperBottom = styled.div`
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  background: ${({ theme }) => theme.background1};
`;
