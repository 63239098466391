import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
  }
`;

export const HeaderContainer = styled.div`
  padding: 16px 12px;
`;

export const ContentContainer = styled.div<{
  filterHeight?: number;
  isUseTable?: boolean;
}>`
  overflow-y: auto;
  background: ${({ theme }) => theme.background1};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: ${({ filterHeight }) => `calc(100% - ${filterHeight}px)`};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};

  ${({ isUseTable, theme }) =>
    isUseTable === undefined &&
    `
  table {
    margin-bottom: 0px;
    thead {
      tr {
        th {
          padding: 4px 6px !important;
          &:first-child {
            padding-left: 4px !important;
          }
          &:last-child {
            padding-right: 8px !important;
          }
        }
      }
    }
    tbody {
      overflow: hidden;
    }
  }
  .header-widget-table > tr {
    background: ${theme.bgElevated3};
    box-shadow: inset 0px -1px 0px ${theme.shadowInner1};
  }
  .infinite-scroll-component__outerdiv {
    height: 100%;
    overflow: auto;
  }
  `}
`;

export const Body = styled.div<{ heightFooter?: number }>`
  height: ${({ heightFooter }) =>
    heightFooter ? `calc(100% - ${heightFooter}px)` : "100%"};
  overflow: auto;
`;

// style cho trạng thái của các row trong table
export const StatusContainer = styled.div<{ status: string }>`
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  background: ${({ status, theme }) => {
    switch (status) {
      case "success":
        return theme.solidGreen;
      case "reject":
        return theme.solidRed;
      case "pending":
        return theme.solidYellow;
      default:
        break;
    }
  }};
  border-radius: 4px;
  width: 80px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//style cho từng phần tử cột trong table
export const TableCellContent = styled.div<{
  isActive?: boolean;
  align?: string;
  minWidth?: number;
  isCursor?: boolean;
}>`
  ${({ isCursor }) => isCursor && `cursor: pointer;`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  height: ${({ theme }) => theme.tables.content.height};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${({ align }) => (align ? "12px" : "4px")};
  padding-right: ${({ align }) => (align === "flex-end" ? "6px" : "12px")};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ theme }) => theme.textcolorWhite};
  ${({ isActive, theme }) => isActive && `background: ${theme.overlayGray}`}
`;

export const HeaderCellChild = styled.div<{
  align?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  height: 100%;
`;

export const FooterTable = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.bgElevated3};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  height: 44px;
`;
