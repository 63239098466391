import { toast } from "components/commons/Toast";
import {
  searchContract,
  liquidateContract,
  findBrokerList,
  createContract,
} from "core/http/apis/create-contract";
import {
  ISearchContractParams,
  ILiquidateContractParams,
  IFindBrokerListParams,
  ICreateContractParams,
} from "core/http/apis/create-contract/types";
import {
  ISearchContractResponse,
  ILiquidateContractResponse,
  IFindBrokerListResponse,
  ICreateContractResponse,
} from "domain/models/CreateContract";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  searchContractFailure,
  searchContractRequest,
  searchContractSuccess,
  liquidateContractRequest,
  liquidateContractSuccess,
  liquidateContractFailure,
  findBrokerListFailure,
  findBrokerListRequest,
  findBrokerListSuccess,
  createContractRequest,
  createContractSuccess,
  createContractFailure,
  updateItemEdit,
} from "./redux";

function* searchContractWorker(
  action: ReturnType<typeof searchContractRequest>
) {
  try {
    const params: ISearchContractParams = action.payload;
    const response: ISearchContractResponse = yield call(
      searchContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchContractSuccess(response));
    } else {
      yield put(searchContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* liquidateContractWorker(
  action: ReturnType<typeof liquidateContractRequest>
) {
  try {
    const params: ILiquidateContractParams = action.payload;
    const response: ILiquidateContractResponse = yield call(
      liquidateContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(liquidateContractSuccess(response));
    } else {
      yield put(liquidateContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(liquidateContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* createContractWorker(
  action: ReturnType<typeof createContractRequest>
) {
  try {
    const params: ICreateContractParams = action.payload;
    const response: ICreateContractResponse = yield call(
      createContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(createContractSuccess(response));
      yield put(
        updateItemEdit({
          brokerCd: "",
          remarks: "",
          action: 1,
          alloDate: null,
          refNo: "",
          contractNo: "",
          timeStamp: "",
        })
      );
    } else {
      yield put(createContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(createContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findBrokerListWorker(
  action: ReturnType<typeof findBrokerListRequest>
) {
  try {
    const params: IFindBrokerListParams = action.payload;
    const response: IFindBrokerListResponse = yield call(
      findBrokerList,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findBrokerListSuccess(response));
    } else {
      yield put(findBrokerListFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findBrokerListFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchContractWatcher() {
  yield takeLatest(searchContractRequest.type, searchContractWorker);
}
function* liquidateContractWatcher() {
  yield takeLatest(liquidateContractRequest.type, liquidateContractWorker);
}
function* findBrokerListWatcher() {
  yield takeLatest(findBrokerListRequest.type, findBrokerListWorker);
}

function* createContractWatcher() {
  yield takeLatest(createContractRequest.type, createContractWorker);
}
export default function* advanceLimitSaga() {
  yield all([
    searchContractWatcher(),
    liquidateContractWatcher(),
    findBrokerListWatcher(),
    createContractWatcher(),
  ]);
}
