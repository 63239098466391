import { ceilingpriceSelector } from "modules/categories/redux/selectors";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

function CeilingpriceCell({ ticker }: Props) {
  const ceilingprice = useSelector(ceilingpriceSelector(ticker)) || 0;

  return (
    <PriceCell
      color="textColorViolet"
      className="bg-overlay-col opening-price ceiling-price"
      price={ceilingprice}
      isBlink={false}
      ticker={ticker}
    />
  );
}

export default CeilingpriceCell;
