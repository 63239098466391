import {
  searchDepositContractApi,
  liquidateDepositContractApi,
  findDepositPaymentApi,
  findDepositContractApi,
  getEarlyInterestRateApi,
  getInterestAmountApi,
  updateDepositPaymentApi,
  updateDepositContractApi,
  getDepositInquiryApi,
  getDueDateDepositContractApi,
  // createContractApi,
} from "./urls";
import {
  ISearchDepositContractResponse,
  ILiquidateDepositContractResponse,
  IFindDepositPaymentResponse,
  IFindDepositContractResponse,
  IGetEarlyInterestRateResponse,
  IGetInterestAmountResponse,
  IUpdateDepositPaymentResponse,
  IUpdateDepositContractResponse,
  IGetDepositInquiryResponse,
  IGetDueDateDepositContractResponse,
  // ICreateContractResponse,
} from "domain/models/DepositContract";

import {
  ISearchDepositContractParams,
  ILiquidateDepositContractParams,
  IFindDepositPaymentParams,
  IFindDepositContractParams,
  IGetEarlyInterestRateParams,
  IGetInterestAmountParams,
  IUpdateDepositPaymentParams,
  IUpdateDepositContractParams,
  IGetDepositInquiryParams,
  IGetDueDateDepositContractParams,

  // ICreateContractParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const searchDepositContract = async (
  params: ISearchDepositContractParams
): Promise<ISearchDepositContractResponse> => {
  const res = await VgaiaHttp.get<ISearchDepositContractResponse>(
    searchDepositContractApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getEarlyInterestRate = async (
  params: IGetEarlyInterestRateParams
): Promise<IGetEarlyInterestRateResponse> => {
  const res = await VgaiaHttp.get<IGetEarlyInterestRateResponse>(
    getEarlyInterestRateApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getInterestAmount = async (
  params: IGetInterestAmountParams
): Promise<IGetInterestAmountResponse> => {
  const res = await VgaiaHttp.get<IGetInterestAmountResponse>(
    getInterestAmountApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const getInterestAmount = async (
//   params: IGetInterestAmountParams
// ): Promise<IGetInterestAmountResponse> => {
//   const res = await VgaiaHttp.post<IGetInterestAmountResponse>(
//     getInterestAmountApi,
//     JSON.stringify(params),
//     { headers: { "Content-Type": "application/json; charset=utf-8" } }
//   );

//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };
export const findDepositPayment = async (
  params: IFindDepositPaymentParams
): Promise<IFindDepositPaymentResponse> => {
  const res = await VgaiaHttp.get<IFindDepositPaymentResponse>(
    findDepositPaymentApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDepositContract = async (
  params: IFindDepositContractParams
): Promise<IFindDepositContractResponse> => {
  const res = await VgaiaHttp.get<IFindDepositContractResponse>(
    findDepositContractApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const liquidateDepositContract = async (
  params: ILiquidateDepositContractParams
): Promise<ILiquidateDepositContractResponse> => {
  const res = await VgaiaHttp.post<ILiquidateDepositContractResponse>(
    liquidateDepositContractApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const updateDepositPayment = async (
  params: IUpdateDepositPaymentParams
): Promise<IUpdateDepositPaymentResponse> => {
  const res = await VgaiaHttp.post<IUpdateDepositPaymentResponse>(
    updateDepositPaymentApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const updateDepositContract = async (
  params: IUpdateDepositContractParams
): Promise<IUpdateDepositContractResponse> => {
  const res = await VgaiaHttp.post<IUpdateDepositContractResponse>(
    updateDepositContractApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getDepositInquiry = async (
  params: IGetDepositInquiryParams
): Promise<IGetDepositInquiryResponse> => {
  const res = await VgaiaHttp.get<IGetDepositInquiryResponse>(
    getDepositInquiryApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getDueDateDepositContract = async (
  params: IGetDueDateDepositContractParams
): Promise<IGetDueDateDepositContractResponse> => {
  const res = await VgaiaHttp.get<IGetDueDateDepositContractResponse>(
    getDueDateDepositContractApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
//   export const createContract = async (
//     params: ICreateContractParams
//   ): Promise<ICreateContractResponse> => {
//     const res = await VgaiaHttp.post<ICreateContractResponse>(
//       createContractApi,
//       JSON.stringify(params),
//       { headers: { "Content-Type": "application/json; charset=utf-8" } }
//     );

//     if (!res || !res.data) throw new Error("Opps");
//     return res.data;
//   };
