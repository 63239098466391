/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import {
  convertDateToDateFormat,
  convertNumberToDateFormat,
} from "helper/utils";
import {
  getListDeposit,
  getListWithdrawal,
  getTypeTransferSelector,
  selectCurrentPage,
  selectEnableInfinity,
  transferLocalBankListSelector,
  transferLocalFilterSelector,
  transferLocalHisListSelector,
  selectLimit,
} from "modules/transfer-internal/selectors";
import numeral from "numeral";
import { memo, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  HeaderCell,
  IconWrapper,
  TableCellContent,
  ButtonLiquidate,
} from "./styles";
// import { ReactComponent as IconEdit } from "assets/image/svg/ic_edit.svg";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { ITransferLocal } from "domain/models/TransferLocal";
import {
  ModalConfirm as ModalConfirmCompnent,
  OTPByBusinessCd,
} from "components/commons";
import {
  deletTransferBank,
  deletTransferInternal,
  fetchModeTransferBank,
  fetchModeTransferDepoist,
  fetchModeTransferInterbal,
  getDataDeposit,
  getListTransferBank,
  searchTransferLocalRequest,
  updateCurrentPage,
  updateEnableInfinity,
} from "modules/transfer-internal/redux";
// import { toast } from "components/commons/Toast";
import { dataColumnsDeposit } from "./constants";
import moment from "moment";
import { updateDerTTransferCashApi } from "core/http/apis/transfer-internal";
import { toast } from "components/commons/Toast";
import { ContentCell } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { EBusinessCd } from "helper/consts";
import ModalConfirm from "./ModalConfirm";

function Table() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataTransferInternal = useSelector(transferLocalHisListSelector);
  const typeTranfer = useSelector(getTypeTransferSelector);
  const dataBank = useSelector(transferLocalBankListSelector);
  const deposit = useSelector(getListDeposit);
  const withdrawal = useSelector(getListWithdrawal);
  const currentPage = useSelector(selectCurrentPage);
  const enableInfinity = useSelector(selectEnableInfinity);
  const limit = useSelector(selectLimit);

  const [visibleModalOTP, setvisibleModalOTP] = useState<boolean>(false);

  const flexEnd = "flex-end";
  const flexStart = "flex-start";
  const center = "center";

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [modalDeleteDepoist, setModalDeleteDepoist] = useState(false);

  const [itemDeleteSelected, setItemDeleteSelected] = useState<
    ITransferLocal | undefined
  >(undefined);

  const [itemDeleteDeposit, setItemDeleteDeposit] = useState<any>();

  const clickModalDeleteDepoist = (item: ITransferLocal) => {
    setItemDeleteDeposit(item);
    setModalDeleteDepoist(true);
  };

  const clickDeleteHandler = (item: ITransferLocal) => {
    setItemDeleteSelected(item);
    setVisibleModalConfirm(true);
  };

  // const listAcc = GetAccoList(
  //   GetAccountType.NONE_DERT_SUB_ACCOUNT,
  //   EntrustBusinessCode.Cash
  // );

  const { fromSubAccoNo, toSubAccoNo, status, m_fromDate, m_toDate } =
    useSelector(transferLocalFilterSelector);

  const setConfirm = (response: IVerifyResponse) => {
    if (typeTranfer === "1") {
      dispatch(
        deletTransferInternal({
          alloDate: Number(itemDeleteSelected?.alloDate) ?? 0,
          refNo: itemDeleteSelected?.refNo ?? 0,
          subAccoNo: itemDeleteSelected?.fromSubAccoNo ?? "",
          subAccoTo: itemDeleteSelected?.toSubAccoNo ?? "",
          toSubAccoCd: Number(itemDeleteSelected?.toSubAccoCd) ?? 0,
          amount: itemDeleteSelected?.amount ?? 0,
          remarks: itemDeleteSelected?.remarks ?? "",
          password: null,
          timeStamp: String(itemDeleteSelected?.timeStamp) ?? "",
          mode: 3,
          otpCode: null,
          otpType: response?.otpTypeSubmit!,
        })
      );
      resetData();
    }
    if (typeTranfer === "0") {
      dispatch(
        deletTransferBank({
          alloDate: Number(itemDeleteSelected?.alloDate) ?? 0,
          refNo: itemDeleteSelected?.refNo ?? 0,
          timeStamp: String(itemDeleteSelected?.timeStamp) ?? "",
          otpCode: response?.otp,
          otpType: response?.otpTypeSubmit!,
        })
      );
      resetDataBank();
    }
  };

  const deleteDeposit = async () => {
    const res = await updateDerTTransferCashApi({
      alloDate: itemDeleteDeposit?.alloDate,
      refNo: itemDeleteDeposit?.refNo,
      tradeType: itemDeleteDeposit?.tradeType,
      subAccoNoFrom: itemDeleteDeposit?.subAccoNoFrom,
      subAccoNoTo: itemDeleteDeposit?.subAccoNoTo,
      amount: itemDeleteDeposit?.amount,
      feeType: itemDeleteDeposit?.feeType,
      feeAmt: itemDeleteDeposit?.feeAmt,
      remarks: itemDeleteDeposit?.remarks,
      lastUpdateTime: itemDeleteDeposit?.updDateTime,
      actionMode: 4,
    });
    if (res?.statusCode === 0) {
      toast("transferInternal.modalDeleteDeposit.messSuccess", "success");
      if (typeTranfer === "3") {
        dispatch(
          getDataDeposit({
            tradeType: Number(1),
            subAccoNoFrom: "",
            status: status ? +status : -1,
            fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
            toDate: Number(moment(m_toDate).format("YYYYMMDD")),
            vsdStatus: -1,
            page: 0,
            rowPerPage: 30,
          })
        );
      } else {
        dispatch(
          getDataDeposit({
            tradeType: Number(2),
            subAccoNoFrom: "",
            status: status ? +status : -1,
            fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
            toDate: Number(moment(m_toDate).format("YYYYMMDD")),
            vsdStatus: -1,
            page: 0,
            rowPerPage: 30,
          })
        );
      }
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const resetData = () => {
    dispatch(
      searchTransferLocalRequest({
        fromSubAccNo: fromSubAccoNo ?? "",
        toSubAccoNo: toSubAccoNo ?? "",
        status: status ? +status : -1,
        m_fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        m_toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        page: 0,
        rowPerPage: 30,
      })
    );
  };

  const resetDataBank = () => {
    dispatch(
      getListTransferBank({
        subAccoNoFrom: fromSubAccoNo ?? "",
        toSubAccoNo: toSubAccoNo ?? "",
        status: status ? +status : -1,
        fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
        toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        page: 0,
        size: 30,
      })
    );
  };
  // const typeTransfeOptions = useMemo(() => statusTypeTransfer(intl), [intl]);

  const columnsTransferInternal = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferInternal.historyTransfer.table.editDelete" />
          </HeaderCell>
        ),
        accessor: "mFromDate",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={center}>
              {props?.row?.original?.status === 0 && (
                <IconWrapper onClick={() => clickDeleteHandler(currentRow)}>
                  <IconTrash />
                </IconWrapper>
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferInternal.historyTransfer.table.alloDate" />
          </HeaderCell>
        ),
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransfer.table.fromSubAccoName" />
          </HeaderCell>
        ),
        accessor: "fromSubAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransfer.table.toSubAccoNo" />
          </HeaderCell>
        ),
        accessor: "toSubAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransfer.table.toSubAccoName" />
          </HeaderCell>
        ),
        accessor: "toSubAccoName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferInternal.historyTransfer.table.amount" />
          </HeaderCell>
        ),
        accessor: "amount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransfer.table.content" />
          </HeaderCell>
        ),
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart} minWidth={"120px"}>
              <ContentCell textOverflow content={props.value} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransfer.table.status" />
          </HeaderCell>
        ),
        accessor: "statusName",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          let color = "white";
          if (
            currentRow?.status === 1 ||
            currentRow?.status === 2 ||
            currentRow?.status === 3
          ) {
            color = "green";
          } else if (currentRow?.status === 0) {
            color = "yellow";
          } else {
            color = "red";
          }
          return (
            <TableCellContent color={color} align={flexStart}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransfer.table.regUserId" />
          </HeaderCell>
        ),
        accessor: "regUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferInternal.historyTransfer.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "regDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertDateToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );

  const columnsTransferBank = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.editDelete" />
          </HeaderCell>
        ),
        accessor: "editDelete",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={center}>
              {props?.row?.original?.status !== 9 && (
                <IconWrapper onClick={() => clickDeleteHandler(currentRow)}>
                  <IconTrash />
                </IconWrapper>
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.alloDate" />
          </HeaderCell>
        ),
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.fromSubAccoName" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.toSubAccoNo" />
          </HeaderCell>
        ),
        accessor: "bankAccntNumber",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.toSubAccoName" />
          </HeaderCell>
        ),
        accessor: "bankAccntName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.nameBank" />
          </HeaderCell>
        ),
        accessor: "bankCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.branch" />
          </HeaderCell>
        ),
        accessor: "bankBranchNameDisp",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.amount" />
          </HeaderCell>
        ),
        accessor: "amount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.feeAmount" />
          </HeaderCell>
        ),
        accessor: "feeAmount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.content" />
          </HeaderCell>
        ),
        // tooltip: (props: any) => {
        //   return props.value;
        // },
        minWidth: 45,
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart} minWidth={"120px"}>
              <ContentCell textOverflow content={props.value} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        Cell: (props: any) => {
          return (
            <TableCellContent
              align={flexStart}
              color={
                props.value === 0
                  ? "yellow"
                  : props.value === 9
                  ? "red"
                  : "green"
              }
            >
              {intl.formatMessage({
                id: `transferInternal.historyTransferBank.table.typeStatus.type${props.value}`,
              })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.regUserId" />
          </HeaderCell>
        ),
        accessor: "updUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="transferInternal.historyTransferBank.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "updDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertDateToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl, typeTranfer]
  );

  const columsDepoist = useMemo(
    () => [
      ...dataColumnsDeposit,
      {
        Header: "",
        with: "120px",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              {(currentRow?.tradeType === "2" && currentRow?.status === "0") ||
              (currentRow?.tradeType === "1" &&
                currentRow?.vsdStatus === "0") ? (
                <ButtonLiquidate
                  className="liquidate"
                  bgcolor="#F04B41"
                  color="#fff"
                  onClick={() => clickModalDeleteDepoist(currentRow)}
                >
                  <FormattedMessage id="transferInternal.deposit.table.buttonDelete" />
                </ButtonLiquidate>
              ) : null}
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {
    if (typeTranfer === "1") {
      if (dataTransferInternal.length < (currentPage + 1) * limit) {
        return dispatch(updateEnableInfinity(false));
      }
      dispatch(
        fetchModeTransferInterbal({
          fromSubAccoNo: fromSubAccoNo ?? "",
          toSubAccoNo: toSubAccoNo ?? "",
          status: status ? +status : -1,
          page: currentPage + 1,
          rowPerPage: limit,
          fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
          toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        })
      );
      dispatch(updateCurrentPage(currentPage + 1));
    }
    if (typeTranfer === "2") {
      if (deposit.length < (currentPage + 1) * limit) {
        return dispatch(updateEnableInfinity(false));
      }
      dispatch(
        fetchModeTransferDepoist({
          tradeType: Number(2),
          subAccoNoFrom: "",
          status: status ? +status : -1,
          fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
          toDate: Number(moment(m_toDate).format("YYYYMMDD")),
          vsdStatus: -1,
          page: currentPage + 1,
          rowPerPage: limit,
        })
      );
      dispatch(updateCurrentPage(currentPage + 1));
    }
    if (typeTranfer === "3") {
      if (withdrawal.length < (currentPage + 1) * limit) {
        return dispatch(updateEnableInfinity(false));
      }
      dispatch(
        fetchModeTransferDepoist({
          tradeType: Number(1),
          subAccoNoFrom: "",
          status: status ? +status : -1,
          fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
          toDate: Number(moment(m_toDate).format("YYYYMMDD")),
          vsdStatus: -1,
          page: currentPage + 1,
          rowPerPage: limit,
        })
      );
      dispatch(updateCurrentPage(currentPage + 1));
    }
    if (typeTranfer === "0") {
      if (dataBank.length < (currentPage + 1) * limit) {
        return dispatch(updateEnableInfinity(false));
      }
      dispatch(
        fetchModeTransferBank({
          fromSubAccoNo: fromSubAccoNo ?? "",
          toSubAccoNo: toSubAccoNo ?? "",
          status: status ? +status : -1,
          page: currentPage + 1,
          size: limit,
          fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
          toDate: Number(moment(m_toDate).format("YYYYMMDD")),
        })
      );
      dispatch(updateCurrentPage(currentPage + 1));
    }
  };
  const columns = () => {
    if (typeTranfer === "1") return columnsTransferInternal;
    if (typeTranfer === "2" || typeTranfer === "3") return columsDepoist;
    return columnsTransferBank;
  };

  const data = () => {
    if (typeTranfer === "1")
      return dataTransferInternal ? dataTransferInternal : [];
    if (typeTranfer === "2") return deposit ? deposit : [];
    if (typeTranfer === "3") return withdrawal ? withdrawal : [];

    return dataBank ? dataBank : [];
  };

  return (
    <Container id="transferLocalTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns()}
        data={data()}
        enableInfinity={enableInfinity}
        scrollableTarget={"transferLocalTable"}
        isHasFooter={false}
      />

      <ModalConfirmCompnent
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={() => setvisibleModalOTP(true)}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({ id: "common.cancelContent" })}
      />

      <ModalConfirm
        visibleModalConfirm={modalDeleteDepoist}
        setVisibleModalConfirm={setModalDeleteDepoist}
        setConfirm={deleteDeposit}
        title={intl.formatMessage({
          id: "transferInternal.modalDeleteDeposit.title",
        })}
        content={intl.formatMessage({
          id: "transferInternal.modalDeleteDeposit.content",
        })}
      />
      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={() => setvisibleModalOTP(false)}
          getResult={setConfirm}
          businessCd={EBusinessCd.TRANSFER}
        />
      )}
    </Container>
  );
}

export default memo(Table);
