import { FC } from "react";
import { FormattedMessage } from "react-intl";
import Filter from "./depositList/Filter";
import { WrapTab } from "./depositList/Filter/styles";
import Table from "./depositList/Table";
import { Container } from "./styles";

const DepositList: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <WrapTab>
        <FormattedMessage id="depositListPMoney.title"></FormattedMessage>
      </WrapTab>
      <Filter />
      <Table />
    </Container>
  );
};

export default DepositList;
