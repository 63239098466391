import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetRightInfoParams } from "core/http/apis/right-info/types";
import { IRightInfoResponse, IRightInfoResult } from "domain/models/RightInfo";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  filter: {
    secCd: string;
    rightType: string;
    ownerFixDate: Date;
    rightExcDate: Date;
    beginTradeDate: Date;
    status: string;
  };
  listRightInfo: IRightInfoResponse[];
  loadingSearchData: boolean;
  activeTab: string;
}

const initialState: ReduxData = {
  filter: {
    secCd: "",
    rightType: "",
    ownerFixDate: new Date(),
    rightExcDate: new Date(),
    beginTradeDate: new Date(),
    status: "2",
  },
  listRightInfo: [],
  loadingSearchData: false,
  activeTab: "RIGHT_INFO",
};

const rightInfoSlice = createSlice({
  name: "rightInfoSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchRightInfoRequest: (
      state,
      action: PayloadAction<IGetRightInfoParams>
    ) => {
      state.loadingSearchData = true;
    },
    searchRightInfoSuccess: (
      state,
      action: PayloadAction<IRightInfoResult>
    ) => {
      state.loadingSearchData = false;
      if (action.payload.data) {
        state.listRightInfo = action.payload.data;
      }
    },
    searchRightInfoFailure: (state) => {
      state.loadingSearchData = false;
    },
    updateFilter: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchRightInfoRequest,
  searchRightInfoSuccess,
  searchRightInfoFailure,
  updateFilter,
} = rightInfoSlice.actions;

export default rightInfoSlice.reducer;
