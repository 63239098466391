import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ContentContainer,
  Table,
  Icon,
  StyledTooltip,
  TextNowrap,
} from "./styles";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash_watchlist.svg";
import { TransferAccountItem } from "domain/models/Cash";
import ModalConfirm from "components/commons/ModalConfirm";
import { CellProps } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "components/commons/CommonTable";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { OverlayTrigger } from "react-bootstrap";
import { IDeleteTransferContractBankParams } from "core/http/apis/account/types";
import {
  clearStatusTransferContractBank,
  deleteTransferContractBank,
  getListBeneficiary,
} from "modules/auth/redux/tradingAccount";
import {
  errorDelAccSelector,
  statusDelAccSelector,
} from "modules/auth/selectors";
import { toast } from "components/commons/Toast";

interface WidgetContentProps {
  data: TransferAccountItem[];
}

function TableTradingAccount({ data }: WidgetContentProps) {
  const intl = useIntl();
  const statusDelAcc = useSelector(statusDelAccSelector);
  const errorDelAcc = useSelector(errorDelAccSelector);
  const [visibleModalConfirm, setVisibaleModalConfirm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [itemSelect, setItemSelect] = useState<TransferAccountItem>();

  const toggleModalConfirm = (
    title: string,
    content: string,
    itemSelect?: TransferAccountItem
  ) => {
    setTitle(title);
    setContent(content);
    setItemSelect(itemSelect ? itemSelect : {});
    setVisibaleModalConfirm((prev) => !prev);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!confirm) return;
    const paramsDelete: IDeleteTransferContractBankParams = {
      alloDate: itemSelect?.alloDate,
      refNo: itemSelect?.refNo,
      delCd: itemSelect?.delCd,
      subAccoCd: itemSelect?.subAccoCd,
      contrDateFrom: itemSelect?.contrDateFrom,
      contrDateTo: itemSelect?.contrDateTo,
      locaTransfer: itemSelect?.locaTransfer,
      bankTransfer: itemSelect?.bankTransfer,
      contrFee: itemSelect?.contrFee,
      smsServUse: itemSelect?.smsServUse,
      transactionCd: itemSelect?.transactionCd,
      status: itemSelect?.status,
      remarks: itemSelect?.remarks,
      srcChannel: itemSelect?.srcChannel,
      approvedDateTime: itemSelect?.approvedDateTime,
      approvedUserId: itemSelect?.approvedUserId,
      regDateTime: itemSelect?.regDateTime,
      regUserId: itemSelect?.regUserId,
      updDateTime: itemSelect?.updDateTime,
      updUserId: itemSelect?.updUserId,
      fullName: itemSelect?.fullName,
      subAccoNo: itemSelect?.subAccoNo,
      branchCd: itemSelect?.branchCd,
      groupCd: itemSelect?.groupCd,
      checkLocalList: itemSelect?.checkLocalList,
      checkBankList: itemSelect?.checkBankList,
      bankAccNumber: itemSelect?.bankAccoNumber,
      bankAccoNumber: itemSelect?.bankAccoNumber,
      province: itemSelect?.province,
      bankCd: itemSelect?.bankCd,
      bankBranchCd: itemSelect?.bankBranchCd,
      bankBranchName: itemSelect?.bankBranchName,
      bankBranchNameDisp: itemSelect?.bankBranchNameDisp,
      bankBranchLocationCd: itemSelect?.bankBranchLocationCd,
      bankAccountNumber: itemSelect?.bankAccountNumber,
      bankAccountName: itemSelect?.bankAccountName,
      address: itemSelect?.address,
      timeStamp: itemSelect?.timeStamp,
    };
    // const paramsDelete: IDeleteTransferContractBankParams = {
    //   data: paramItem,
    // };
    dispatch(deleteTransferContractBank({ params: paramsDelete }));
    setConfirm(false);
  }, [confirm]);

  useEffect(() => {
    if (statusDelAcc === "SUCCESSFUL") {
      toast("auth.tradingAccount.deleteSuccess", "success");
      dispatch(clearStatusTransferContractBank());
      dispatch(getListBeneficiary());
    }

    if (statusDelAcc === "FAILURE") {
      if (!errorDelAcc?.code && !errorDelAcc?.description) {
        toast("auth.tradingAccount.deleteFailure", "error");
      } else {
        toast(errorDelAcc?.code, "error", errorDelAcc?.description, true);
      }
      dispatch(clearStatusTransferContractBank());
    }
  }, [statusDelAcc, errorDelAcc]);

  const handleChangeFormTranferMoney = () => {
    //TODO Tuan tranfer money
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align="center">
            <FormattedMessage id="auth.tradingAccount.deleteAcc" />
          </TableHeaderCellContent>
        ),
        accessor: "pkAccountBeneficiary",
        Cell: (props: CellProps<TransferAccountItem>) => {
          const itemSelect = props.row.original;
          const bankAccount = props.row.original?.bankAccoNumber;
          const title = intl.formatMessage({
            id: "auth.tradingAccount.deleteAccount",
          });
          const content =
            intl.formatMessage({ id: "auth.tradingAccount.confirmDelete" }) +
            ": " +
            bankAccount +
            "?";
          return (
            <TableCellContent align="center">
              <Icon
                onClick={() => {
                  toggleModalConfirm(title, content, itemSelect);
                }}
              >
                <IconTrash />
              </Icon>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent>
            <FormattedMessage id="auth.tradingAccount.bankAccountCode" />
          </TableHeaderCellContent>
        ),
        accessor: "bankAccNumber",
        Cell: (props: any) => {
          const { value } = props;
          return <TableCellContent>{value}</TableCellContent>;
        },
      },
      {
        Header: (
          <TableHeaderCellContent>
            <FormattedMessage id="auth.tradingAccount.masterAccountName" />
          </TableHeaderCellContent>
        ),
        accessor: "bankAccountName",
        minWidth: 110,
        Cell: (props: any) => {
          const { value } = props;
          return <TableCellContent>{value}</TableCellContent>;
        },
      },
      {
        Header: (
          <TableHeaderCellContent>
            <FormattedMessage id="auth.tradingAccount.bankName" />
          </TableHeaderCellContent>
        ),
        accessor: "bankCd",
        minWidth: 90,
        Cell: (props: CellProps<TransferAccountItem>) => {
          const bankCode = props?.row?.original?.bankCd;
          return <TableCellContent>{bankCode}</TableCellContent>;
        },
      },
      {
        Header: (
          <TableHeaderCellContent>
            <FormattedMessage id="auth.tradingAccount.bankBranch" />
          </TableHeaderCellContent>
        ),
        accessor: "bankBranchNameDisp",
        Cell: (props: any) => {
          const { value } = props;
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <StyledTooltip className="action-container" id="tooltip">
                  {value}
                </StyledTooltip>
              }
            >
              <TableCellContent>
                <TextNowrap>{value}</TextNowrap>
              </TableCellContent>
            </OverlayTrigger>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent>
            <FormattedMessage id="auth.tradingAccount.bankProvince" />
          </TableHeaderCellContent>
        ),
        accessor: "province",
        Cell: (props: any) => {
          const { value } = props;
          return <TableCellContent>{value}</TableCellContent>;
        },
      },
      {
        Header: (
          <TableHeaderCellContent>
            <FormattedMessage id="auth.tradingAccount.transferMoney" />
          </TableHeaderCellContent>
        ),
        accessor: "transferMoney",
        Cell: (props: any) => {
          return (
            <TableCellContent
              color="brandPrimary"
              onClick={() => handleChangeFormTranferMoney()}
            >
              <FormattedMessage id="auth.tradingAccount.transferMoney" />
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  return (
    <ContentContainer>
      <Table>
        <CommonTable columns={columns} data={data ? data : []} />
      </Table>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibaleModalConfirm}
        setConfirm={setConfirm}
        title={title}
        content={content}
      />
    </ContentContainer>
  );
}

export default React.memo(TableTradingAccount);
