import { FC } from "react";
import { WrapperTicker } from "./styles";
import ClickableTicker from "components/commons/ClickableTicker";

interface Props {
  ticker: string;
}

const TickerCell: FC<Props> = ({ ticker }) => {
  return (
    <WrapperTicker>
      <ClickableTicker ticker={ticker} />
    </WrapperTicker>
  );
};

export { TickerCell };
