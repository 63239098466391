import { createSelector } from "@reduxjs/toolkit";
import { closeToast } from "modules/system/redux/toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Container, PositionHolder } from "./styles";
import Toast from "./Toast";

export type ToastContainerProps = {
  timeout: number | undefined;
};

const systemSelector = (state: RootState) => state.system;

const toastSelector = createSelector(systemSelector, (state) => state.toast);

export default function ToastContainer() {
  const dispatch = useDispatch();
  const { messages, delay } = useSelector(toastSelector);

  function handleCloseToast(id: string) {
    dispatch(closeToast(id));
  }

  return (
    <Container>
      <PositionHolder>
        {messages.map(
          ({ text, id, type, defaultMessage, isIntlKey, param }) => {
            return (
              <Toast
                message={text}
                defaultMessage={defaultMessage}
                isIntlKey={isIntlKey}
                type={type}
                key={id}
                id={id}
                onClose={handleCloseToast}
                delay={delay}
                param={param}
              />
            );
          }
        )}
      </PositionHolder>
    </Container>
  );
}
