import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const positionManagementSelector = (state: RootState) =>
  state.positionManagement;

export const getListPositionClose = createSelector(
  positionManagementSelector,
  (state) => state.listPositionClose
);
export const getListPositionOpen = createSelector(
  positionManagementSelector,
  (state) => state.listPositionOpen
);
export const loadingSelector = createSelector(
  positionManagementSelector,
  (state) => state.loading
);
export const positionOpenSelector = createSelector(
  positionManagementSelector,
  (state) => state.positionOpenFilter.filter
);
export const positionCloseSelector = createSelector(
  positionManagementSelector,
  (state) => state.positionCloseFilter.filter
);
