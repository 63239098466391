import queryString from "query-string";
import { IGetRemainQuantity } from "./types";

export const getSubscribeBuyUrl = (params: {
  subAccoNo?: string;
  SecCd?: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findRightInfoCapitalIncreate${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getSearchSubscribeBuyUrl = (params: {
  secCd?: string;
  rightType?: string;
  ownerFixDate?: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findRightInfo${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const deleteTransferBankUrl = () => {
  return `/deleteTransferBank`;
};

export const inquiryCapitalIncreseUrl = (params: {
  subAccoNo: string | null;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryCapitalIncrese${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const getRemainQuantityUrl = (params: IGetRemainQuantity) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getRemainQuantity${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const createCapitalIncreaseUrl = () => {
  return `/createCapitalIncrease`;
};

export const findCapitalIncreaseUrl = (params: { subAccNo: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findCapitalIncrease${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const deleteCapitalIncreaseAppUrl = () => {
  return `/deleteCapitalIncreaseApp`;
};
