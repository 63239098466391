import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import numeral from "numeral";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import LastPriceCell from "./CellRealTime/LastPriceCell";
import ChangePointCell from "./CellRealTime/ChangePointCell";
import ChangePercentCell from "./CellRealTime/ChangePercentCell";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { createSelector } from "reselect";

const floorpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.floorprice
);
const ceilingpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.ceilingprice
);
const basicpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.basicprice
);

function TickerIndex() {
  const floorprice = useSelector(floorpriceSelector);
  const ceilingprice = useSelector(ceilingpriceSelector);
  const basicprice = useSelector(basicpriceSelector);

  return (
    <div className="d-flex align-items-center price">
      <LastPriceCell />
      <div className="flex-fill mr-3">
        <ChangePointCell />
        <ChangePercentCell />
      </div>
      <div className="price">
        <FormattedMessage id="widgets.tickerInfo.ceilingPrice" />
        <div className="gia-tran violet">
          {numeral(ceilingprice).format("0.00")}
        </div>
      </div>

      <div className="mx-3 price">
        <FormattedMessage id="widgets.tickerInfo.floorPrice" />
        <div className="gia-tran blue">
          {numeral(floorprice).format("0.00")}
        </div>
      </div>
      <div className="price">
        <FormattedMessage id="widgets.tickerInfo.referencePrice" />
        <div className="gia-tran yellow">
          {numeral(basicprice).format("0.00")}
        </div>
      </div>
    </div>
  );
}

export default TickerIndex;
