import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TimeOptionValue } from "components/widgets/details/Finance/types";
import { IGetFinanceInfoParams } from "core/http/apis/finance/types";
import { SectFinanceData } from "domain/protoNew/auto_trading_pb";
import {
  formatDataChartFinance,
  formatDataChartBalanceSheet,
} from "modules/tickerDetails/helpers/utils";

export enum EIndicatorType {
  ROE = 1,
  ROA = 2,
  EPS = 3,
  PROFIT = 4,
  TURNOVER = 5,
  BUSINESS_PROFIT = 6,
  BALANCE_SHEET = 7,
  CASH_FLOW = 8,
  INVEST = 9,
  FINANCE = 10,
}

export type ChartData = {
  labels: string[];
  datasets: any[];
};

export type FinanceState = {
  data: any;
  loading: boolean;
  dataChartMainIndicator: ChartData | undefined;
  dataChartRevenue: ChartData | undefined;
  dataChartBalance: ChartData | undefined;
  dataChartCashFlow: ChartData | undefined;
  timeType: TimeOptionValue;
  indicatorType: EIndicatorType;
  revenueType: EIndicatorType;
  cashFlowType: EIndicatorType;
};

const initialState: FinanceState = {
  data: [],
  loading: false,
  dataChartMainIndicator: undefined,
  dataChartRevenue: undefined,
  dataChartBalance: undefined,
  dataChartCashFlow: undefined,
  timeType: TimeOptionValue.yearlyList,
  indicatorType: EIndicatorType.ROE,
  revenueType: EIndicatorType.PROFIT,
  cashFlowType: EIndicatorType.CASH_FLOW,
};
const COLOR_BAR = {
  indicator: "#3391FF",
  revenue: "#3391FF",
  cashFlow: "#3391FF",
};

const financeInfo = createSlice({
  name: "financeInfoSlice",
  initialState,
  reducers: {
    requestGetFinanceInfo: (
      state,
      action: PayloadAction<IGetFinanceInfoParams>
    ) => {
      state.loading = true;
    },
    getFinanceInfoSuccess: (
      state,
      action: PayloadAction<SectFinanceData.AsObject>
    ) => {
      state.data = action.payload;
      state.dataChartMainIndicator = formatDataChartFinance(
        action.payload,
        state.timeType,
        state.indicatorType,
        COLOR_BAR.indicator,
        "0,0.00",
        state.indicatorType !== EIndicatorType.EPS
      );
      state.dataChartRevenue = formatDataChartFinance(
        action.payload,
        state.timeType,
        state.revenueType,
        COLOR_BAR.revenue,
        "0,0ab",
        false
      );
      state.dataChartCashFlow = formatDataChartFinance(
        action.payload,
        state.timeType,
        state.cashFlowType,
        COLOR_BAR.cashFlow,
        "0,0ab",
        false
      );
      state.dataChartBalance = formatDataChartBalanceSheet(
        action.payload,
        state.timeType
      );
      state.loading = false;
    },
    changeTimeType: (state, action: PayloadAction<TimeOptionValue>) => {
      state.timeType = action.payload;
      state.dataChartMainIndicator = formatDataChartFinance(
        state.data,
        action.payload,
        state.indicatorType,
        COLOR_BAR.indicator,
        "0,0.00",
        state.indicatorType !== EIndicatorType.EPS
      );
      state.dataChartRevenue = formatDataChartFinance(
        state.data,
        action.payload,
        state.revenueType,
        COLOR_BAR.revenue,
        "0,0ab",
        false
      );
      state.dataChartCashFlow = formatDataChartFinance(
        state.data,
        action.payload,
        state.cashFlowType,
        COLOR_BAR.cashFlow,
        "0,0ab",
        false
      );
      state.dataChartBalance = formatDataChartBalanceSheet(
        state.data,
        state.timeType
      );
    },
    changeIndicatorType: (
      state,
      action: PayloadAction<{
        data: EIndicatorType;
      }>
    ) => {
      state.indicatorType = action.payload.data;
      state.dataChartMainIndicator = formatDataChartFinance(
        state.data,
        state.timeType,
        action.payload.data,
        COLOR_BAR.indicator,
        "0,0.00",
        state.indicatorType !== EIndicatorType.EPS
      );
    },
    changeRevenueType: (
      state,
      action: PayloadAction<{
        data: EIndicatorType;
      }>
    ) => {
      state.revenueType = action.payload.data;
      state.dataChartRevenue = formatDataChartFinance(
        state.data,
        state.timeType,
        action.payload.data,
        COLOR_BAR.revenue,
        "0,0ab",
        false
      );
    },
    changeCashFlowType: (
      state,
      action: PayloadAction<{
        data: EIndicatorType;
      }>
    ) => {
      state.cashFlowType = action.payload.data;
      state.dataChartCashFlow = formatDataChartFinance(
        state.data,
        state.timeType,
        action.payload.data,
        COLOR_BAR.cashFlow,
        "0,0ab",
        false
      );
    },
  },
});

export const {
  requestGetFinanceInfo,
  getFinanceInfoSuccess,
  changeTimeType,
  changeIndicatorType,
  changeRevenueType,
  changeCashFlowType,
} = financeInfo.actions;

export default financeInfo.reducer;
