import { FC } from "react";
import SubscribeBuy from "./subscribeBuy";
import HistorySubscribe from "./historySubscribe";
import SearchSubscribe from "./searchSubscribe";
import { SUBSCRIBE_TABS } from "modules/subscribeBuy/constants";
import { selectTab } from "modules/subscribeBuy/selectors";
import { useSelector } from "react-redux";

const ContentData: FC = () => {
  const activeTab = useSelector(selectTab);

  const checkTab = () => {
    if (activeTab === SUBSCRIBE_TABS.HISTORY_SUBSCRIBE)
      return <HistorySubscribe />;
    if (activeTab === SUBSCRIBE_TABS.SEARCH_SUBSCRIBE)
      return <SearchSubscribe />;
    return <SubscribeBuy />;
  };

  return <>{checkTab()}</>;
};

export default ContentData;
