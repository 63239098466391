import HttpMarginContract from "core/http/singleton/margin-contract";
import { IMarginContractResult } from "domain/models/MarginContract";
import { IGetMarginContractParams } from "./types";
import { getListMarginContractUrl } from "./urls";

export const getListMarginContract = async (
  data: IGetMarginContractParams
): Promise<IMarginContractResult> => {
  const res = await HttpMarginContract.post<IMarginContractResult>(
    getListMarginContractUrl(
      data.tranCd,
      data.accNo,
      data.openDateFrom,
      data.openDateTo,
      data.broker,
      data.status,
      data.contractState
    )
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
