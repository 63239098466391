import { memo, useEffect, useMemo, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  ListChildComponentProps,
  VariableSizeList as List,
  areEqual,
} from "react-window";
import RowDnd from "./RowContainerDnd";
import Row from "./RowContainer";
import { useDispatch, useSelector } from "react-redux";
import { GridTableBodyProps } from "modules/categories/types";
import {
  currentListCodeSelector,
  tickerListTableSelector,
  userFavoriteListSelector,
  tickerFocusSelector,
  optionScrollF1Selector,
  anonymousFavoriteListSelector,
} from "modules/categories/redux/selectors";
import { LIST_ITEM_HEIGHT, TypeScroll } from "modules/categories/constant";
import {
  Droppable,
  Draggable,
  DragDropContext,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
} from "react-beautiful-dnd";
import { updateTickerListTable } from "modules/categories/redux/categoriesInfo";
import { setTickerFocus, sortTickerList } from "modules/categories/redux";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
import { smooth_scroll_to } from "helper/utils";

const RowRendererDnd = memo(
  ({ index, data, style }: ListChildComponentProps) => {
    const { tickerList, tableAttribute, columns, currentListCode, rowFocus } =
      data;

    return (
      <Draggable
        draggableId={"" + tickerList[index]}
        index={index}
        key={tickerList[index]}
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <RowDnd
            isDragging={snapshot.isDragging}
            provided={provided}
            rowNumber={index}
            key={tickerList[index]}
            style={style}
            ticker={tickerList[index]}
            currentListCode={currentListCode}
            sumWidth={tableAttribute.sumWidth}
            askWidth={tableAttribute.askWidth}
            bidWidth={tableAttribute.bidWidth}
            foreignWidth={tableAttribute.foreignWidth}
            priceWidth={tableAttribute.priceWidth}
            lastSaleWidth={tableAttribute.lastSaleWidth}
            coverWarrantWidth={tableAttribute.coverWarrantWidth}
            coverWarrantHeadWidth={tableAttribute.coverWarrantHeadWidth}
            columns={columns}
            isFocus={rowFocus === index}
          />
        )}
      </Draggable>
    );
  },
  areEqual
);

const RowRenderer = memo(({ index, data, style }: ListChildComponentProps) => {
  const { tickerList, tableAttribute, columns, currentListCode, rowFocus } =
    data;
  return (
    <Row
      rowNumber={index}
      key={tickerList[index]}
      style={style}
      ticker={tickerList[index]}
      currentListCode={currentListCode}
      sumWidth={tableAttribute.sumWidth}
      askWidth={tableAttribute.askWidth}
      bidWidth={tableAttribute.bidWidth}
      foreignWidth={tableAttribute.foreignWidth}
      priceWidth={tableAttribute.priceWidth}
      lastSaleWidth={tableAttribute.lastSaleWidth}
      coverWarrantWidth={tableAttribute.coverWarrantWidth}
      coverWarrantHeadWidth={tableAttribute.coverWarrantHeadWidth}
      columns={columns}
      isFocus={rowFocus === index}
    />
  );
}, areEqual);

function reorder(list: any, startIndex: any, endIndex: any) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function Grid({ columns, tableAttribute }: GridTableBodyProps) {
  const dispatch = useDispatch();
  const tickerList = useSelector(tickerListTableSelector);
  const userFavoriteList = useSelector(userFavoriteListSelector);
  const anonymousFavoriteList = useSelector(anonymousFavoriteListSelector);
  const tickerFocus = useSelector(tickerFocusSelector);
  const listRef = useRef<List>(null);
  const { typeScroll, duration } = useSelector(optionScrollF1Selector);
  const [rowFocus, setRowFocus] = useState(-1);
  const scrollToTicker = (ticker: string) => {
    if (listRef?.current !== null) {
      const indexTicker = tickerList.indexOf(ticker);
      if (indexTicker === -1) return;
      listRef.current.scrollToItem(indexTicker, "start");
      setRowFocus(indexTicker);
      dispatch(setTickerFocus(undefined));
    } else {
      setTimeout(() => {
        scrollToTicker(ticker);
      }, 100);
    }
  };

  useEffect(() => {
    if (tickerFocus) {
      scrollToTicker(tickerFocus);
    }
  }, [tickerFocus]);

  useEffect(() => {
    if (tickerList.length > 0) {
      const listTicker = tickerList.toString().replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
    return () => {
      if (tickerList.length > 0) {
        const listTicker = tickerList.toString().replace(/,/g, ";");
        unSubscribeTickers(listTicker);
      }
    };
  }, [tickerList.length]);

  const currentListCode = useSelector(currentListCodeSelector);

  const getItemSize = () => {
    return LIST_ITEM_HEIGHT;
  };

  var listItemData = useMemo(
    () => ({
      tickerList,
      tableAttribute,
      columns,
      currentListCode,
      rowFocus,
    }),
    [tickerList, tableAttribute, columns, currentListCode, rowFocus]
  );

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) {
      return;
    }

    const newTickerList = reorder(
      tickerList,
      result.source.index,
      result.destination.index
    );

    dispatch(updateTickerListTable(newTickerList as string[]));

    if (isFavoriteList) {
      const listId = reorder(
        isFavoriteList.listId,
        result.source.index,
        result.destination.index
      ) as number[];
      dispatch(
        sortTickerList({
          tickers: newTickerList as string[],
          listName: currentListCode,
          listId,
        })
      );
    } else {
      dispatch(
        sortTickerList({
          tickers: newTickerList as string[],
          listName: currentListCode,
        })
      );
    }
  }

  const isFavoriteList = userFavoriteList?.find(
    (item) => item.name === currentListCode
  );

  const isDraggableAndDropable =
    isFavoriteList ||
    anonymousFavoriteList?.find((item) => item.name === currentListCode);

  const scrollInfinity = () => {
    let element = document.getElementsByClassName("tickers-list")[0];
    if (element) {
      smooth_scroll_to(
        element,
        tickerList.length * 28,
        tickerList.length * duration
      );
    } else {
      setTimeout(() => {
        scrollInfinity();
      }, 1000);
    }
  };

  useEffect(() => {
    if (typeScroll !== TypeScroll.INFINITY) {
      return;
    }
    scrollInfinity();
  }, [typeScroll, duration]);

  return isDraggableAndDropable ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" mode="virtual">
        {(provided: DroppableProvided) => (
          <AutoSizer className="table-auto-size">
            {({ height, width }) => (
              <List
                ref={listRef}
                outerRef={provided.innerRef}
                className="tickers-list"
                height={height}
                itemSize={getItemSize}
                itemCount={tickerList.length}
                width={width}
                style={{
                  overflowX: "visible",
                  minWidth: tableAttribute.sumWidth,
                }}
                itemData={listItemData}
              >
                {RowRendererDnd}
              </List>
            )}
          </AutoSizer>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <AutoSizer className="table-auto-size">
      {({ height, width }) => (
        <List
          ref={listRef}
          className="tickers-list"
          height={height}
          itemSize={getItemSize}
          itemCount={tickerList.length}
          width={width}
          style={{ overflowX: "visible", minWidth: tableAttribute.sumWidth }}
          itemData={listItemData}
        >
          {RowRenderer}
        </List>
      )}
    </AutoSizer>
  );
}

export default memo(Grid);
