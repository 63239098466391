import { FC } from "react";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import TableMarginContract from "./Table";
import FilterMarginContract from "./Filters";
import { ActiveTabMarginContract } from "helper/consts";
import { useSelector } from "react-redux";

import { activeTabSelector } from "modules/margin-contract/selectors";

const MarginContractTable: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === ActiveTabMarginContract.MARGIN_CONTRACT && (
        <>
          <FilterMarginContract />
          <TableMarginContract />
        </>
      )}
    </Wrap>
  );
};
export default MarginContractTable;
