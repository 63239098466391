import VgaiaHttp from "core/http/singleton/vgaia";
import { IAccountStatmentResponse } from "domain/models/AccountStatement";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IGetListMoneyStatement } from "./type";
import { getListMoneyStatementApi, getListR9008DataSourceSecApi } from "./urls";

export const getMoneyStatementList = async (
  param: IGetListMoneyStatement
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getListMoneyStatementApi({ filter: JSON.stringify(param.filter) })
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListR9008DataSourceSecUrl = async (
  param: IGetListMoneyStatement
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getListR9008DataSourceSecApi({ filter: JSON.stringify(param.filter) })
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
