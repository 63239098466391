import {
  CardStock,
  Under,
  Blue,
  NameIndex,
  RowCardStock,
  RowChangeStock,
  RowCardStockBottom,
} from "./styles";
import NumberChange from "./NumberChange/numberChange";
import QtyChange from "./NumberChange/qtyChange";
import AmtChange from "./NumberChange/amtChange";
import SessionName from "./NumberChange/sessionName";
import ChartHeader from "./chartHeader";
import { FormattedMessage } from "react-intl";
import { EMarketCodeNew, VisibleIndex } from "helper/consts";
import { useDispatch, useSelector } from "react-redux";
import { setVisibleIndexDetailsModal } from "modules/system/redux/headerCard";
import { visibleSideBarSelector } from "modules/system/selectors";
import ArrowIndex from "./NumberChange/arrowIndex";
import UpDownChange from "./NumberChange/upDownChange";
interface Props {
  ticker: string;
}

export function getNameIndex(value: string) {
  switch (value) {
    case EMarketCodeNew.HSX_IDX.toString():
      return "VN-INDEX";
    case EMarketCodeNew.VN30_IDX.toString():
      return "VN30";
    case EMarketCodeNew.HNX_IDX.toString():
      return "HNX";
    case EMarketCodeNew.UPCOM_IDX.toString():
      return "UPCOM";
    case EMarketCodeNew.HNX30.toString():
      return "HNX30";
    default:
      return "";
  }
}

function getStringIndex(value: string) {
  switch (value) {
    case EMarketCodeNew.HSX_IDX.toString():
      return "VN-INDEX";
    case EMarketCodeNew.VN30_IDX.toString():
      return "VN30";
    case EMarketCodeNew.HNX_IDX.toString():
      return "HNX";
    case EMarketCodeNew.UPCOM_IDX.toString():
      return "UPCOM";
    case EMarketCodeNew.HNX30.toString():
      return "HNX30";
    default:
      return "";
  }
}

function Card(props: Props) {
  const dispatch = useDispatch();
  const displaySideBarIndex = useSelector(visibleSideBarSelector);
  const ClickIndex = () => {
    dispatch(setVisibleIndexDetailsModal(getStringIndex(props.ticker)));
  };
  return (
    <CardStock>
      <RowCardStock
        showChart={displaySideBarIndex % 3 !== VisibleIndex.HIDDEN_CHART}
      >
        <ChartHeader ticker={props.ticker} />
      </RowCardStock>

      <RowCardStockBottom>
        <NameIndex onClick={ClickIndex}>{getNameIndex(props.ticker)}</NameIndex>
        <RowChangeStock>
          <div className="d-flex">
            <ArrowIndex ticker={props.ticker} />
            <NumberChange ticker={props.ticker} fieldValue={"marketindex"} />
          </div>
          <div className="d-flex">
            <NumberChange ticker={props.ticker} fieldValue={"changeindex"} />
            &nbsp;
            <NumberChange
              ticker={props.ticker}
              fieldValue={"changepercent"}
              isPercentage={true}
            />
          </div>
        </RowChangeStock>
      </RowCardStockBottom>
      <RowCardStockBottom>
        <Under>
          <QtyChange
            format="0,0.[00]a"
            fieldValue="totalqty"
            ticker={props.ticker}
          />
          <Blue>
            <FormattedMessage id="common.CP" />
          </Blue>
          <AmtChange fieldValue="totalamt" ticker={props.ticker} />
          <Blue>
            <FormattedMessage id="common.unit" />
          </Blue>
        </Under>
        <Under>
          <SessionName ticker={props.ticker} fieldValue="state" />
        </Under>
      </RowCardStockBottom>

      <UpDownChange ticker={props.ticker} />
    </CardStock>
  );
}

export default Card;
