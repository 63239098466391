import { FC } from "react";
import { useSelector } from "react-redux";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import Table from "./Table";
import Filter from "./Filters";

import { activeTabSelector } from "modules/statistic-onday/selectors";
import { STATISTIC_ONDAY_TABS } from "modules/statistic-onday/constants";

const StatisticOndayContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      {activeTab === STATISTIC_ONDAY_TABS.STATISTIC_ONDAY && (
        <>
          <Filter />
          <Table />
        </>
      )}
    </Wrap>
  );
};
export default StatisticOndayContent;
