import { FC } from "react";
import { Wrap } from "./styles";
type FilterItemProps = {
  filterName: string;
  className?: string;
  isMinWidth?: boolean;
};
const FilterItem: FC<FilterItemProps> = ({
  filterName,
  className,
  children,
  isMinWidth,
}) => {
  return (
    <Wrap className={className} isMinWidth={isMinWidth}>
      <p>{filterName}</p>
      {children}
    </Wrap>
  );
};

export default FilterItem;
