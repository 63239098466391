import { createSelector } from "@reduxjs/toolkit";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
}

const currentroomSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.currentroom
  );
};

function CurrentroomCell({ ticker }: Props) {
  const currentroom = useSelector(currentroomSelector(ticker)) || 0;

  return (
    <VolumeCell
      className="currentroom"
      volume={currentroom / 10}
      keepRawValue={false}
    />
  );
}

export default CurrentroomCell;
