import { TRADING_VIEW_URL } from "AppConfig";
import storage from "helper/storage";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";
import { TickerGraph } from "./styles";
import { ThemeType } from "themes/types";

// TODO: Should implement component behavior when TRADING_VIEW_URL is missing from ENV
const url = new URL(TRADING_VIEW_URL!);

export type TickerGraphProps = {
  selectedTicker?: string | null;
};

function TickerGraphWidget({ selectedTicker }: TickerGraphProps) {
  const themeType = useSelector(themeTypeSelector);
  url.searchParams.set("sym", selectedTicker!);
  const themeChart = (themeType: ThemeType) => {
    switch (themeType) {
      case ThemeType.BLACK:
        return "dark";
      case ThemeType.LIGHT:
        return "light";
      default:
        return "dark";
    }
  };
  url.searchParams.set("theme", themeChart(themeType));
  url.searchParams.set("token", storage.getAccessToken());
  return (
    <TickerGraph>
      <iframe
        src={url.toString()}
        width="100%"
        height="100%"
        id="tradingView"
      />
    </TickerGraph>
  );
}

export default TickerGraphWidget;
