import { combineReducers } from "@reduxjs/toolkit";
import toastReducer, { ToastState } from "./toast";
import commonReducer, { CommonState } from "./common";
import headerCardReducer, { HeaderCardState } from "./headerCard";
import modalRegisterEkycReducer, {
  RegisterEkycState,
} from "./modalRegisterEkyc";

export type SystemState = {
  toast: ToastState;
  common: CommonState;
  headerCard: HeaderCardState;
  modalRegisterEkycSlide: RegisterEkycState;
};

export default combineReducers({
  toast: toastReducer,
  common: commonReducer,
  headerCard: headerCardReducer,
  modalRegisterEkycSlide: modalRegisterEkycReducer,
});
