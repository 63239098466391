import { Button, ModalConfirm, OTPByBusinessCd } from "components/commons";
import {
  Container,
  Content,
  ContentButton,
  ContentLeft,
  ContentRight,
  ContentRow,
  ContentValue,
  ContentValueDes,
  Description,
  Title,
  TitleDes,
  Wrapper,
} from "./styles";

import themes from "themes/abstracts/_themes";

import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { toast } from "components/commons/Toast";
import { createRbonOrder } from "core/http/apis/place-order-retail-bonds";
import { EBusinessCd } from "helper/consts";
import { convertNumberToDateFormat } from "helper/utils";
import {
  buyInfor,
  calInvestmentValue,
  getListOrderBookRequest,
  updateBondCdInfo,
} from "modules/place-order-retail-bonds/redux";
import {
  getBuyInforSelector,
  getCbotBondInfoSelector,
} from "modules/place-order-retail-bonds/selectors";
import numeral from "numeral";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";

function ContainerBottom() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const bondInfo = useSelector(getCbotBondInfoSelector);
  const buyInformation = useSelector(getBuyInforSelector);

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);

  const handleSkip = () => {
    dispatch(
      buyInfor({
        key: "bondCd",
        value: "",
      })
    );
    dispatch(
      buyInfor({
        key: "period",
        value: "",
      })
    );
    dispatch(
      buyInfor({
        key: "qty",
        value: "",
      })
    );
    dispatch(updateBondCdInfo({ bondInfo: {} }));
    dispatch(
      calInvestmentValue({
        calInvestmentValue: "",
      })
    );
  };
  const handleSubmit = () => {
    setVisibleModalConfirm(true);
  };
  const handleConfirm = () => {
    setVisibleModalOTP(true);
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitAdvance = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    const res = await createRbonOrder({
      subAccoNo: buyInformation?.subAccoNo,
      bondCd: buyInformation?.bondCd?.value,
      period: buyInformation?.period?.value,
      qty: buyInformation?.qty,
      otpCode: response?.otp,
      otpType: response?.otpTypeSubmit!,
    });

    if (res?.statusCode === 1) {
      toast(res?.message, "error");
    }

    if (res?.statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderRetailBonds.infomationOrder.buyDone",
        })}`,
        "success"
      );
      dispatch(
        getListOrderBookRequest({
          subAccoNo: "",
          bondCd: "",
          status: -1,
          fromDate: 0,
          toDate: 0,
        })
      );
    }
  };

  return (
    <Wrapper>
      <Container>
        <Content>
          <ContentRow>
            <ContentLeft>
              <Title>
                <FormattedMessage id="placeOrderRetailBonds.infomationOrder.issuers" />
              </Title>
              <ContentValue>{bondInfo ? bondInfo?.bondName : ""}</ContentValue>
            </ContentLeft>
            <ContentRight>
              <Title>
                <FormattedMessage id="placeOrderRetailBonds.infomationOrder.denominations" />
              </Title>
              <ContentValue>
                {bondInfo && bondInfo?.faceValPrice
                  ? numeral(bondInfo?.faceValPrice).format("0,0")
                  : ""}
              </ContentValue>
            </ContentRight>
          </ContentRow>
          <ContentRow>
            <ContentLeft>
              <Title>
                <FormattedMessage id="placeOrderRetailBonds.infomationOrder.releaseDate" />
              </Title>
              <ContentValue>
                {bondInfo
                  ? convertNumberToDateFormat({ date: bondInfo?.reissueDate })
                  : ""}
              </ContentValue>
            </ContentLeft>
            <ContentRight>
              <Title>
                <FormattedMessage id="placeOrderRetailBonds.infomationOrder.couponRate" />
              </Title>
              <ContentValue>
                {bondInfo && bondInfo?.reissuePrice
                  ? numeral(bondInfo?.reissuePrice * 100).format("0,0")
                  : ""}
              </ContentValue>
            </ContentRight>
          </ContentRow>
          <ContentRow>
            <ContentLeft>
              <Title>
                <FormattedMessage id="placeOrderRetailBonds.infomationOrder.dateDue" />
              </Title>
              <ContentValue>
                {bondInfo
                  ? convertNumberToDateFormat({ date: bondInfo?.dueDate })
                  : ""}
              </ContentValue>
            </ContentLeft>
            <ContentRight>
              <Title>
                <FormattedMessage id="placeOrderRetailBonds.infomationOrder.paymentPeriod" />
              </Title>
              <ContentValue>
                {bondInfo ? bondInfo?.periodPayInterest : ""}
              </ContentValue>
            </ContentRight>
          </ContentRow>
          <Description>
            <TitleDes>
              <FormattedMessage id="placeOrderRetailBonds.infomationOrder.description" />
            </TitleDes>
            <ContentValueDes>
              {bondInfo ? bondInfo?.remarks : ""}
            </ContentValueDes>
          </Description>
        </Content>
        <ContentButton>
          <Button
            color="accept"
            className="button-filter w-100 m-1"
            onClick={handleSkip}
            style={{
              padding: "18px 0px",
              fontSize: `${themes[themeType].typeFaces.headline.size}`,
            }}
          >
            {intl.formatMessage({
              id: "placeOrderRetailBonds.infomationOrder.skip",
            })}
          </Button>
          <Button
            style={{
              padding: "18px 0px",
              fontSize: `${themes[themeType].typeFaces.headline.size}`,
            }}
            color="success"
            className="button-filter m-1 w-100"
            onClick={handleSubmit}
            fill={true}
          >
            {intl.formatMessage({
              id: "placeOrderRetailBonds.infomationOrder.buy",
            })}
          </Button>
        </ContentButton>
      </Container>

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={handleConfirm}
        title={intl.formatMessage({
          id: "placeOrderRetailBonds.infomationOrder.modalTitle",
        })}
        content={intl.formatMessage({
          id: "placeOrderRetailBonds.infomationOrder.modalBody",
        })}
        handleCloseModal={() => setVisibleModalConfirm(!visibleModalConfirm)}
      ></ModalConfirm>

      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </Wrapper>
  );
}

export default ContainerBottom;
