import AppLanguage from "./types";
import errorsVi from "./errorVi";
import {
  ContactLinkVN,
  RiskLinkVN,
  TutorialLinkVN,
  OnlineServiceLinkVN,
} from "AppConfig";

const vi: AppLanguage = {
  common: {
    titleAlert: "THÔNG BÁO",
    buttonClose: "ĐÓNG",
    login: "Đăng nhập",
    errorlogin: "Thông tin đăng nhập không hợp lệ. Vui lòng thử lại.",
    username: "Tên đăng nhập",
    password: "Mật khẩu",
    enterPassword: "Nhập mật khẩu",
    signin: "",
    darkMode: "Theme bóng tối",
    lightMode: "Theme ánh sáng",
    EN: "TA",
    VI: "TV",
    search: "Tìm kiếm",
    buy: "Mua",
    sell: "Bán",
    buyPlus: "MUA +",
    sellPlus: "BÁN +",
    buyUp: "MUA",
    sellUp: "BÁN",
    tickerTracking: "Tìm mã CK",
    searchHeader: "Nhập mã chứng khoán, tên công ty",
    noValue: "Không có mã tương ứng",
    cancel: "Hủy bỏ",
    accept: "Đồng ý",
    short: "SHORT",
    long: "LONG",
    shortUp: "SHORT",
    longUp: "LONG",
    closeUp: "ĐÓNG",
    shortPlus: "SHORT +",
    longPlus: "LONG +",
    editUp: "SỬA",
    save: "Lưu",
    ok: "OK",
    OUTTIME: "Ngoài giờ",
    BOD: "Trước giờ",
    AVAILABLE: "Hiệu lực",
    OPEN: "Liên tục",
    RUNOFF: "Thỏa thuận",
    PAUSE: "Nghỉ trưa",
    CLOSED: "Đóng cửa",
    choose: "Tùy chọn",
    option: "Tùy chọn",
    expiredToken: "Phiên làm việc đã hết, nhấn Đồng ý ra màn đăng nhập",
    errorDelete: "Không có lệnh nào hợp lệ để hủy",
    errorCancelCopier: "Không được hủy lệnh tài khoản copier",
    hiddenTab: "Tab ẩn",
    close: "Đóng",
    maximizeTab: "Phóng to tab",
    restoreTab: "Thu nhỏ tab",
    CP: "CP",
    unit: "Tỷ",
    million: "tr",
    loading: "Đang tải...",
    inputSymbol: "Nhập mã",
    warningRangeDay:
      "Vượt quá {day} ngày. Quý KH chọn Từ ngày Đến ngày tương ứng {day} ngày trong bất kỳ thời gian nào.",
    warningInvalidDay: "Từ ngày không được vượt quá Đến ngày",
    nullInputFromDate: "Quý khách chưa nhập Từ ngày",
    nullInputToDate: "Quý khách chưa nhập Đến ngày",
    existTabError: "Chức năng đã mở",
    warningLiquidAppendix: "Vui lòng chọn phụ lục",
    warningCreateAppendix: "Quý khách vui lòng đăng ký HĐ dịch vụ",
    confirm: "XÁC NHẬN",
    cancelContent: "Bạn có chắc chắn muốn hủy yêu cầu?",
    titleExtra: "Thông tin đặt lệnh",
    placeOrderExtra: "Đặt lệnh cơ sở",
    placeOrderDerExtra: "Đặt lệnh phái sinh",
  },
  system: {
    modalSupport: {
      title: "Hỗ trợ và góp ý",
      content:
        "VGAIA trân trọng cảm ơn Quý khách hàng đã sử dụng dịch vụ. Quý khách cần trợ giúp vui lòng liên hệ với chúng tôi tại đây",
      lableComment: "Góp ý của bạn",
      errorComment: "Góp ý của bạn không được bỏ trống",
      errorEmail: "Email/Số điện thoại  không được bỏ trống.",
      enterComment: "Nhập góp ý",
      email: "Email/Số điện thoại",
      enterEmail: "Nhập email hoặc số điện thoại",
      send: "Gửi góp ý",
      upPhoto: "Tải ảnh lên",
      success: "Gửi thành công",
      contentSuccess:
        "Thông tin đã được gửi tới VGAIA. Cảm ơn bạn đã liên hệ với chúng tôi.",
      errorValidateEmailOrPhone:
        "Số điện thoại hoặc Email không đúng định dạng",
    },
  },
  auth: {
    errors: {
      emptyPassword: "Mật khẩu không được bỏ trống",
      emptyUsername: "Tài khoản không được bỏ trống",
    },
    info: {
      title: "Thông tin cá nhân",
      customerNo: "STK",
      accountNumber: "Số tài khoản",
      generalInformation: "Thông tin nhà đầu tư",
      identityCardId: "CMND/ĐKKD",
      issueDate: "Ngày cấp",
      issuePlace: "Nơi cấp",
      expireDate: "Ngày hết hạn",
      openDate: "Ngày mở tài khoản",
      birthDate: "Ngày sinh",
      sex: "Giới tính",
      communications: "Thông tin liên lạc",
      currentAddress: "Địa chỉ",
      phone: "Điện thoại",
      email: "Email",
      contactName: "Người liên hệ",
      contactTel: "Điện thoại",
      caringStaff: {
        title: "Nhân viên chăm sóc",
        registerButton: "Đăng ký",
        titleModalRegister: "ĐĂNG KÝ NHÂN VIÊN CHĂM SÓC",
        account: "Số tài khoản",
        fullName: "Họ và tên",
        offerStaff: "Đề xuất nhân viên quản lý tài khoản mới",
        broker: "Mã nhân viên",
        inputBroker: "Nhập mã nhân viên",
        fullNameStaff: "Tên nhân viên",
        branch: "Chi nhánh",
        txDesc: "Lí do",
        inputTxDesc: "Nhập lí do",
        terms:
          "Tôi đã đọc và đồng ý với các Điều khoản và điều kiện hợp đồng nêu trên",
        cancel: "Hủy",
        confirm: "Xác nhận",
        errorFindBroker:
          "Không tìm thấy thông tin nhân viên trong danh sách, vui lòng nhập lại mã nhân viên",
        registerBrokerSuccess: "Đăng ký nhân viên chăm sóc thành công",
        registerBrokerFailure: "Đăng ký nhân viên chăm sóc thất bại",
      },
      brokerCode: "Mã môi giới",
      brokerName: "Tên môi giới",
      brokerBrand: "Chi nhánh",
      brokerPhone: "Điện thoại",
      brokerEmail: "Email",
      curentPass: "Mật khẩu hiện tại",
      newPass: "Mật khẩu mới",
      confirmNewPass: "Xác nhận mật khẩu mới",
      curentPassTrade: "Mật khẩu GD hiện tại",
      newPassTrade: "Mật khẩu GD mới",
      confirmNewPassTrade: "Xác nhận mật khẩu GD mới",
      validPass: "Mật khẩu tối thiểu 8 ký tự bằng số",
      entrustInfo: "Thông tin ủy quyền",
      fullName: "Họ và tên",
      effectDate: "Ngày hiệu lực",
      entrustRange: "Phạm vi ủy quyền",
      accountStock: "Tài khoản chứng khoán",
      accountCash: "Tài khoản Cash",
      accountMcredit: "Tài khoản M-Credit",
      accountMargin: "Tài khoản Margin",
      entrustType: {
        all: "UQ toàn bộ",
        order: "Đặt lệnh",
        tranferMoney: "Giao dịch tiền",
        tranferShare: "Giao dịch CK",
        advance: "Ứng trước",
        registerBuy: "Đăng ký mua thêm",
        sellOddLot: "Bán lô lẻ",
        pledge: "Cầm cố",
        tranferDer: "GD phái sinh",
        statementSearch: "Sao kê, tra cứu",
        otherEntrust: "UQ khác",
        margin: "Margin",
      },
      editInfo: {
        titleButton: "Sửa thông tin",
        title: "Sửa thông tin liên lạc",
        cancel: "Hủy",
        update: "Áp dụng",
        inputAddress: "Nhập địa chỉ liên lạc",
        inputPhone: "Nhập số điện thoại liên lạc",
        inputEmail: "Nhập Email liên lạc",
        inputContactName: "Nhập tên người liên hệ",
        inputContactTel: "Nhập số điện thoại",
        inputCurentPass: "Nhập mật khẩu hiện tại",
        inputNewPass: "Nhập mật khẩu hiện mới",
        inputConfirmNewPass: "Nhập lại mật khẩu mới",
        curentPassRequired: "Mật khẩu hiện tại không được bỏ trống",
        newPassRequired: "Mật khấu mới không được bỏ trống",
        confirmNewPassRequired: "Xác nhận mật khẩu mới không được bỏ trống",
        confirmNewPass: "Mật khẩu không khớp, vui lòng thử lại",
        errorPhoneNumber: "Số điện thoại không hợp lệ. Vui lòng thử lại!",
        errorEmail: "Email không hợp lệ. Vui lòng thử lại!",
        errorAddress: "Địa chỉ nhỏ hơn 15 ký tự. Vui lòng nhập lại địa chỉ!",
        errorChangeAll:
          "Email và số điện thoại không chỉnh sửa đồng thời. Vui lòng thử lại!",
        changeAccountInfoSuccess:
          "Quý khách sửa thông tin thành công, hệ thống sẽ cập nhật thông tin sớm nhất",
        changeAccountInfoFailed: "Cập nhật thất bại",
        noChangeInfo: "Thông tin liên lạc không thay đổi!",
        confirm: "Xác nhận",
        passwordMinLengthError: "Độ dài mật khẩu tối thiểu 8 ký tự",
      },
      editGeneralInformation: {
        title: "Sửa thông tin chung",
        fullName: "Họ và tên",
        inputFullName: "Nhập họ và tên",
        idCode: "CMND/ĐKKD",
        inputIdCode: "Nhập CMND/ĐKKD",
        idDate: "Ngày cấp",
        idPlace: "Nơi cấp",
        inputIdPlace: "Nhập nơi cấp",
        idExpired: "Ngày hết hạn",
        address: "Địa chỉ",
        inputAddress: "Nhập địa chỉ",
        errorEmptyFullName: "Vui lòng nhập họ và tên",
        errorEmptyIdCode: "Vui lòng nhập CMND/ĐKKD",
        errorEmptyIdDate: "Vui lòng nhập ngày cấp",
        errorEmptyIdPlace: "Vui lòng nhập nơi cấp",
        errorEmptyIdExpired: "Vui lòng nhập ngày hết hạn",
        errorEmptyAddress: "Vui lòng nhập địa chỉ",
      },
      smartOTP: {
        cancel: "Hủy",
        smartOTP: "SMART OTP",
        qrcode: "QR CODE",
        enterOTP: "Nhập mã OTP",
        content: "Vui lòng mở ứng dụng VGAIA và quét mã",
        expired: "Mã QR hết hạn",
        buttonGetQR: "Lấy mã mới",
        errorInputOTP: "Chưa nhập đủ mã, vui lòng nhập đủ 6 số!",
        errorQR: "Quét mã không thành công, vui lòng thử lại",
        saveOTP: "Lưu mã OTP",
      },
      smsOTP: {
        title: "SMS OTP",
        enterOTP: "Nhập mã OTP",
        resendCode: "Gửi lại mã",
        countdown: "Hiệu lực trong {second}",
        saveOTP: "Lưu mã OTP",
        cancel: "Hủy",
        submit: "Xác nhận",
      },
      sendOTP: {
        label: "Nhập mã OTP",
        title: "Xác thực OTP",
        cancel: "Hủy",
        confirm: "Xác nhận",
        sendOTP: "Gửi mã OTP",
        sendOTPAgain: "Gửi lại OTP ",
        textConfirm: "Tôi xác nhận đăng ký thay đổi thông tin liên hệ",
        textTitle: "Gửi mã OTP qua:",
        sendOTPAgainToastSuccess: "Gửi lại mã xác thực thành công",
        errorNoRegister: "Bạn chưa đăng ký xác thực cấp 2",
        errorOtpType:
          "Loại xác thực của tòi khoản đang đăng ký hiện không được hỗ trợ trên web, vui lòng kiểm tra lại!",
      },
      pinCdOTP: {
        title: "Mã PIN",
        enterPinCd: "Nhập mã PIN",
      },
    },
    identification: {
      title: "Mã định danh",
      accountNo: "Tiểu khoản",
      mrType: "Loại TK",
      accountType: "Mã định danh",
    },
    setting: {
      title: "Cài đặt",
      menu: {
        general: "Cài đặt chung",
        settingPass: "Cài đặt mật khẩu",
        manageDevice: "QL thiết bị sử dụng Smart OTP",
        tradingAcc: "Tài khoản giao dịch",
        keyShortcut: "Cài đặt phím tắt",
        clearCache: "Xóa cache",
        advanceAuto: "Dịch vụ ứng trước tự động",
        emailService: "Dịch vụ Email/SMS",
      },
      generalSetting: {
        language: "Ngôn ngữ",
        theme: "Giao diện",
      },
      settingPass: {
        loginPass: "Mật khẩu đăng nhập",
        transactionPass: "Mật khẩu giao dịch",
        button: "Đổi mật khẩu",
        modal: {
          title: "Tạo mật khẩu đăng nhập mới",
          titlePassTransaction: "Tạo mật khẩu giao dịch mới",
          passNow: "Mật khẩu hiện tại",
          placeholderPassNow: "Nhập mật khẩu hiện tại",
          passNew: "Mật khẩu mới",
          placeholderPassNew: "Nhập mật khẩu mới",
          againPass: "Nhập lại MK mới",
          placeholderAgainPass: "Nhập lại mật khẩu mới",
          btnCancel: "Hủy",
          btnSuccess: "Cập nhật",
          messageSuccess: "Cập nhật mật khẩu thành công",
          messErrorConfirmPass: "Nhập lại mật khẩu mới!",
          errorMin: "Mật khẩu mới phải có ít nhất {x} ký tự",
          errorAlpha: "Mật khẩu mới phải có ký tự đặc biệt",
          errorChar: "Mật khẩu mới phải có chữ viết hoa",
        },
      },
      advanceAuto: {
        title: "Ứng trước tự động",
        status: "Trạng thái",
        statusList: {
          unregistered: "Chưa đăng ký",
          registered: "Đã đăng ký",
          noValue: "",
        },
        register: "Đăng ký",
        cancelRegistration: "Hủy đăng ký",
        modal: {
          titleRegister: "Đăng ký dịch vụ Ứng trước tự động",
          custNo: "Số tài khoản",
          custName: "Họ tên",
          textConfirmRegister: "Tôi muốn Đăng ký dịch vụ Ứng trước tự động",

          textCheckBox:
            "Tôi đã đọc và đồng ý với {link} sử dụng dịch vụ của {company}",
          textCheckBoxMiddle: "Điều kiện và điều khoản ",

          register: "Đăng ký",
          titleCancelUnRegistration: "Hủy đăng ký dịch vụ Ứng trước tự động",
          textConfirmUnRegister:
            "Tôi muốn hủy đăng ký dịch vụ Ứng trước tự động",
          cancelRegistration: "Hủy đăng ký",
          status: {
            registerDone: "Đăng ký thành công",
            registerFailure: "Đăng ký thất bại",
            deleteDone: "Hủy đăng ký thành công",
            deleteFailure: "Hủy đăng ký thất bại",
          },
        },
      },
    },
    login: {
      questionRegister: "Bạn chưa có tài khoản?",
      linkRegisterL: "Mở tài khoản",
      login: "Đăng nhập",
      callMBS: "Gọi MBS",
      numberMBS: "Hotline MBS: 1900 9088",
      username: "Số tài khoản",
      enterUsername: "Nhập số tài khoản",
      rememberMe: "Lưu tài khoản đăng nhập",
      forgetPassword: "Quên mật khẩu?",
      forgetPasswordSuccess: "Quên mật khẩu thành công",
      userIncorect: "Sai tài khoản",
      passIncorect: "Sai mật khẩu",
      signIn: "Mở tài khoản",
      risk: "Công bố rủi ro",
      riskLink: RiskLinkVN,
      Hotline: "Hotline",
      Contact: "Liên hệ",
      ContactLink: ContactLinkVN,
      Tutorial: "Hướng dẫn",
      TutorialLink: TutorialLinkVN,
      forgotPass: "Quên mật khẩu?",
    },
    forgetPassword: {
      logo: "vgaia",
      max10: "Số tài khoản tối đa 10 ký tự",
      errorIssueDate: "Ngày cấp không được bỏ trống",
      errorRequired: "1 trong 2 trường email và phone không được bỏ trống",
      maxDate: "Ngày cấp không được vượt quá ngày hiện tại",
      phoneMax10: "Số điện thoại tối đa 10 ký tự",
      enterAccount: "Nhập số tài khoản",
      enterCMND: "Nhập số CMND/Hộ chiếu",
      enterIssueDate: "Chọn ngày",
      enterEmail: "Nhập Email",
      enterPhone: "Nhập số điện thoại",
      buttonCancel: "Quay lại",
      buttonAccept: "Xác nhận",
      issueDate: "Ngày cấp",
      email: "Email",
      phone: "Số điện thoại",
      max6: "Nhập tối đa 6 ký tự",
      errorCMND: "CMND CMND/CCCD/GPKD/... không được bỏ trống",
      CMND: "CMND/Hộ chiếu",
      errorUser: "Số tài khoản không được bỏ trống",
      errorString: "OTP code gồm có 6 ký tự số",
      errorOtpCode: "OTP code không được bỏ trống",
      otpCode: "Nhập mã OTP",
      enterOtpCode: "Nhập mã OTP",

      forgetPassword: "QUÊN MẬT KHẨU",
      subTitle: "Quý khách vui lòng nhập thông tin dưới đây ",
      subTitle2: "Nhập email hoặc số điện thoại đăng ký nhận mật khẩu",
      enter: "Nhập thông tin",
      cancel: "Hủy",
      submit: "Đồng ý",
      forgetPasswordSuccess:
        "Mật khẩu đã được gửi về SMS/Email đăng ký của Khách hàng",
    },
    changePassword: "Đổi mật khẩu",
    language: "Ngôn ngữ",
    support: "Hỗ trợ và góp ý",
    changePasswordSuccess: "Đổi mật khẩu thành công",
    logout: "Đăng xuất",
    notification: {
      transaction: "Giao dịch",
      service: "CSKH",
      recommendations: "Khuyến nghị",
      notify: "Thông báo",
      maskAsRead: "Đánh dấu đã đọc",
      warning: "Cảnh báo",
    },
    tradingAccount: {
      titleTypeOtp: "Xác thực cấp 2",
      typeOtp: "Phương thức xác thực",
      title: "Tài khoản giao dịch",
      defaultAccount: "Tài khoản mặc định",
      accountName: "Tài khoản",
      accountStock: "Tài khoản chứng khoán",
      accountCash: "Tài khoản Cash",
      accountMCredit: "Tài khoản M-Credit",
      accountMargin: "Tài khoản Margin",
      accountDerivative: "Tài khoản phái sinh",
      accountBeneficiary: "Tài khoản thụ hưởng",
      addAccountBeneficiary: "Thêm tài khoản thụ hưởng",
      success: "Thêm tài khoản thụ hưởng thành công",
      masterAccountName: "Chủ Tài khoản",
      bankAccountCode: "Số tài khoản",
      bankAccountCodeInput: "Nhập số tài khoản",
      errorBankAccount: "Số tài khoản không được bỏ trống",
      errorBankName: "Vui lòng chọn ngân hàng",
      errorBankProvince: "Vui lòng chọn tỉnh/ thành phố",
      errorBankBranch: "Vui lòng chọn chi nhánh",
      bankName: "Ngân hàng",
      bankNameInput: "Chọn ngân hàng",
      bankProvince: "Tỉnh/Thành phố",
      bankProvinceInput: "Chọn Tỉnh/Thành phố",
      bankBranch: "Chi nhánh",
      bankBranchInput: "Nhập chi nhánh",
      address: "Địa chỉ",
      inputAddress: "Nhập địa chỉ",
      transferMoney: "Chuyển tiền",
      loginByPassStatic: "Xác thực theo phiên",
      cancel: "Hủy",
      addAccount: "Thêm tài khoản",
      typeAccount: "Loại TK",
      deleteAccount: "Xóa tài khoản",
      confirmDelete: "Xác nhận xóa tài khoản thụ hưởng",
      accept: "Đồng ý",
      apply: "Áp dụng",
      deleteSuccess: "Xóa tài khoản thành công",
      deleteFailure: "Xóa tài khoản thất bại",
      table: {
        tooltipTable: "{bankName}",
      },
      registerSmartOtp: "Đăng ký Smart OTP",
      registerSMSOtp: "Đăng ký SMS OTP",
      registerOtpTypeSuccess:
        "_Quý khách đăng ký phương thức xác thực thành công. Hệ thống sẽ tự động đăng xuất_",
      registerOtpTypeFailure: "Đăng ký tài khoản thụ hưởng thành côngthất bại",
      passStaticSuccess: "Đăng ký đăng nhập bằng mật khẩu tĩnh thành công",
      offPassStaticSuccess: "Tắt đăng nhập bằng mật khẩu tĩnh thành công",
      passStaticFailure: "Đăng ký đăng nhập bằng mật khẩu tĩnh thất bại",
      offPassStaticFailure: "Tắt đăng nhập bằng mật khẩu tĩnh thất bại",
      unknown: "Không xác định",
      register: "Đăng ký xác thực",
      titleModalChooseType: "Đăng ký phương thức xác thực",
      smartOtp: "Smart OTP",
      smsOtp: "SMS OTP",
      inputBankName: "Chọn ngân hàng",
      inputBankProvince: "Nhập tỉnh/ thành phố",
      inputBankBranch: "Nhập chi nhánh",
      accountCode: "Số tài khoản",
      term: "Tôi đã đọc và đồng ý với {link} sử dụng dịch vụ của Kafi!",
      link: "Điều khoản và điều kiện",
      existBeneficiaryAccountError: "Tài khoản thụ hưởng đã tồn tại",
      messageTitle: "Thông báo",
      messageContent:
        "Dịch vụ có tính phí SMS, Quý Khách có muốn tiếp tục đăng ký sử dụng không?",
      deleteAcc: "Hủy",
      titleConfirmSetDefault: "Thông báo",
      contentConfirmSetDefault:
        "Quý khách có muốn thay đổi tiểu khoản giao dịch mặc định không?",
      setDefaultSuccess: "Cập nhật tiểu khoản mặc định thành công",
      errorDefault: "Đã có lỗi xảy ra!",
      errorNotChange: "Dữ liệu không thay đổi",
    },
    smsEmail: {
      title: "Dịch vụ Email/SMS",
      sms: "Dịch vụ SMS",
      email: "Dịch vụ Email",
      free: "GÓI MIỄN PHÍ",
      basic: "GÓI CƠ BẢN (8.800 VND)",
      pro: "GÓI NÂNG CAO (33.000 VND)",
      settingEmail: "THIẾT LẬP DỊCH VỤ EMAIL",
      basicRegister: "Bạn muốn đăng ký gói Cơ bản?",
      advancedToBasic: "Bạn muốn chuyển từ gói Nâng cao sang gói Cơ bản?",
      advancedRegister: "Bạn muốn đăng ký gói Nâng cao?",
      basicToAdvanced: "Bạn muốn chuyển từ gói Cơ bản sang gói Nâng cao?",
      canelBasic: "Bạn muốn hủy đăng ký gói Cơ bản?",
      canelAdvanced: "Bạn muốn hủy đăng ký gói Nâng cao?",
      modalTitle: "Cài đặt dịch vụ SMS/Email",
    },
    onlineService: {
      modalTitle: "Đăng ký dịch vụ online",
      titleCancelMargin: "Hủy đăng ký tài khoản dịch vụ Margin",
      marginContr: "Hạn mức đăng ký",
      cancelMarginConfirm:
        "Tôi đồng ý với các điều kiện điều khoản sử dụng dịch vụ của MBS trong từng thời kỳ",
      buttonCancel: "Hủy đăng ký",
      titleCancelSMUT: "Hủy đăng ký dịch vụ Sức mua ứng trước",
      SMUTCancelConfirm:
        "Tôi đã đọc và đồng ý các với điều khoản của MBS khi Hủy đăng ký sức mua ứng trước",
      accountNumber: "Số tài khoản",
      fullName: "Họ tên",
      titleBroker: "Đăng ký dịch vụ có Broker",
      lableBroker: "Đề xuất chuyên viên chăm sóc và quản lý tài khoản",
      brokerConfirm:
        "Tôi đã đọc và đồng ý với các quyền lợi khi chuyển đổi dịch vụ quản lý tài khoản",
      buttonYes: "Đăng ký",
      brokerRequest:
        "Đề nghị chuyển đổi dịch vụ quản lý tài khoản: từ Dịch vụ MBS Online sang Dịch vụ có Broker",
      enterCode: "Nhập mã nhân viên",
      titleMargin: "Đăng ký tài khoản dịch vụ Margin",
      maxMarginValue: "Giá trị tối đa được đăng ký là ",
      minMarginValue: "Giá trị tối thiểu là ",
      stepMarginValue: "Giá trị  hạn mức phải là bội số của ",
      marginValue: "Hạn mức đăng ký",
      marginConfirm:
        "Tôi đồng ý với các điều kiện điều khoản sử dụng dịch vụ của MBS trong từng thời kỳ",
      titleMbsOnline: "Đăng ký dịch vụ MBS Online (Không có Broker)",
      mbsOnline: "MBS Online",
      authenticationMethod: "Phương thức xác thực",
      matrix: "Ma trận",
      smartOTP: "SmartOTP",
      titleSmartOTP: "Thay đổi phương thức xác thực Smart OTP",
      smartOTPRequest:
        "ĐỀ NGHỊ CHUYỂN ĐỔI PHƯƠNG THỨC XÁC THỰC TỪ MA TRẬN SANG SMART OTP",
      smartOTPConfirm:
        "Tôi đã đọc và đồng ý với điều khoản của MBS khi thay đổi phương thức xác thực",
      mbsOnlineRequest:
        "Đề nghị chuyển đổi dịch vụ QL tài khoản: từ Dịch vụ có Broker sang Dịch vụ MBS Online",
      mbsOnlineConfirm:
        "Tôi đã đọc và đồng ý với các quyền lợi khi chuyển đổi dịch vụ quản lý tài khoản",
      titleSMUT: "Đăng ký dịch vụ Sức mua ứng trước",
      SMUTConfirm:
        "Tôi đã đọc và đồng ý các với điều khoản của MBS khi Đăng ký sức mua ứng trước",
      accountService: "Tài khoản dịch vụ",
      marginService: "Dịch vụ Margin",
      registered: "Đã đăng ký",
      unregistered: "Chưa đăng ký",
      MCreditService: "Dịch Vụ M-Credit",
      CKService: "Dịch vụ CK phái sinh",
      registerETrading: "Đăng ký Giao dịch điện tử",
      callTrading: "Giao dịch tổng đài",
      onlineTrading: "Giao dịch trực tuyến",
      accountManagementService: "Dịch vụ quản lý tài khoản ",
      onlineServiceLink: OnlineServiceLinkVN,
      brokerService: "Dịch vụ có Broker",
      SMUTService: "Sức mua ứng trước",
      DateRequest: "NGÀY YÊU CẦU",
      content: "NỘI DUNG",
      status: "TRẠNG THÁI",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      all: "Tất cả",
      CHUA_DUYET: "Chưa duyệt",
      DA_DUYET: "Đã duyệt",
      statusFilter: "Trạng thái",
      errorDayRange: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
      MBSOnlineService: "Dịch vụ MBS Online",
      registerMBSOnline: "Đăng ký dịch vụ Online",
      history: "Lịch sử",
      sendOTP: "Gửi mã OTP qua:",
      numberPhone: "Số điện thoại",
      enterOTP: "Nhập mã OTP",
      enterSmsOTP: "Nhập mã SMS OTP",
      send: "Gửi lại",
    },
    chooseTheme: {
      editTheme: "Cài đặt theme",
      chooseTheme: "Chọn giao diện",
      blackTheme: "Giao diện tối",
      darkTheme: "Giao diện tối xanh",
      lightTheme: "Giao diện trắng",
      lightBlueTheme: "Giao diện trắng xanh",
      default: "(mặc định)",
    },
    keyboardShortcuts: {
      title: "Cài đặt phím tắt",
      noKey: "Không",
      duplicateKey: "Lỗi trùng phím tắt, vui lòng chọn lại!",
      resetAll: "Reset All",
      keyShortcutOpenForm: {
        title: "Phím tắt mở form",
        category: "Danh mục",
        market: "Thị trường",
        stockOrder: "Lệnh cơ sở",
        stockOrderDer: "Lệnh phái sinh",
      },
      transactionSettings: {
        title: "Cài đặt giao dịch",
        stockOrderBuy: "Đặt lệnh bán",
        stockOrderSell: "Đặt lệnh mua",
        popupConfirmOrder: "Popup Xác nhận lệnh",
      },
    },
    managerDevice: {
      title: "Danh sách thiết bị",
      titleBtn: "Refresh",
      device: "Thiết bị",
      activeDate: "Ngày kích hoạt",
      deactivate: "Hủy kích hoạt",
      deleteDone: "Hủy kích hoạt thành công",
      deleteFailure: "Có lỗi xảy ra",
      titleDelete: "Thông báo",
      contentDelete: "Bạn có chắc muốn hủy kích hoạt thiết bị này?",
    },
    seviceEmailSms: {
      accoName: "Tài khoản",
      search: "Tìm",
      titleSms: "SMS",
      titleEmail: "Email",
      numberPhone: "Số điện thoại",
      email: "Email",
      register: "Đăng ký",
      nameService: "Tên dịch vụ",
      feeRegister: "Phí ĐK",
      feeMonth: "Phí tháng",
      update: "Cập nhật",
      dataSame: "Dữ liệu không đổi",
      success: "Cập nhật thành công",
    },
    register: {
      title: "Mở tài khoản trực tuyến",
      btnPrev: "Quay lại",
      btnNext: "Tiếp tục",
      btnLogin: "Đăng nhập",
      messageErrorValidate: "Không được để trống trường này!",
      messageSuccess: "Tạo tài khoản thành công",
      labelLeft1: "Thông tin cơ bản",
      labelLeft2: "Thông tin TK thụ hưởng",
      labelLeft3: "Đăng ký dịch vụ",
      step: {
        infomationBasic: "Thông tin cơ bản",
        infomationAccount: "Thông tin TK thụ hưởng",
        register: "Đăng ký dịch vụ",
      },
      step1: {
        question1: "1. Quý khách là nhà đầu tư ?",
        answer1: {
          value1: "Cá nhân",
          value2: "Tổ chức",
        },
        question2: "2. Quốc tịch của Quý khách?",
        answer2: {
          value1: "Việt Nam",
          value2: "Nước ngoài",
        },
        question3: "3. Quý khách biết đến chúng tôi?",
        question4: "4. Chọn chi nhánh, điểm giao dịch gần nhất",
        answer4: {
          labelBranch: "Chi nhánh",
          transactionPoint: "Điểm giao dịch",
          address: "Địa chỉ",
          placeholderAddress: "18 Láng Hạ",
          phone: "Số điện thoại",
          placeholderPhone: "04.2789898",
        },
        question5: "5. Nhân viên giới thiệu/ chăm sóc tài khoản",
        placeholderQuestion5: "Chọn nhân viên giới thiệu/chăm sóc",
        dataChannelId: {
          value1: {
            label: "Internet",
          },
          value2: {
            label: "Báo chỉ",
          },
          value3: {
            label: "Quảng cáo",
          },
          value4: {
            label: "Nhân viên giới thiệu",
          },
          value5: {
            label: "Khác",
          },
        },
        optionBroker: {
          valueAll: {
            label: "Không có nhân viên chăm sóc/giới thiệu",
          },
        },
        modal: {
          title: "Hình ảnh xác thực EKYC",
          content:
            "Tôi đồng ý để VGAIA sử dụng hình ảnh sau đây cho mục đích xác thực và cung cấp cho cơ quan quản lý nếu được yêu cầu.",
          btnAccep: "Đồng ý",
          btncancel: "Hủy",
        },
      },
      step2: {
        cmnd: "CMND",
        passport: "HỘ CHIẾU",
        business: "GIẤY PHÉP KINH DOANH",
        other: "KHÁC",
        traddingCodeOrganization: "TRADINGCODE CHO TỔ CHỨC NN",
        traddingCodeIndividual: "TRADINGCODE CHO CÁ NHÂN NN",
        governmentAgencies: "CƠ QUAN CHÍNH PHỦ",
        identity: "CĂN CƯỚC CÔNG DÂN",
      },
      step3: {
        question: "Chụp ảnh mặt trước/mặt sau CMND/CCCD",
        labelFronSide: "CMND/CCCD mặt trước",
        fronSide: "CMND/CCCD mặt trước",
        labelBackSide: "CMND/CCCD mặt sau",
        backSide: "CMND/CCCD mặt sau",
        btnPhoto: "Chụp ảnh",
        dowloadPhoto: "Tải ảnh lên",
        modal: {
          title: "Chụp ảnh CCCD/CMND",
          fontImage: "CMND/CCCD mặt trước",
          backImage: "CMND/CCCD mặt sau",
        },
      },
      step4: {
        question: "Xác thực khuôn mặt",
      },
      step5: {
        label: "Thông tin cá nhân",
        exhibit: {
          label: "Loại giấy tờ",
        },
        CMND: {
          label: "Số CMND/CCCD",
        },
        address: {
          label: "Nơi cấp",
        },
        dateRange: {
          label: "Ngày cấp",
        },
        expirationDate: {
          label: "Ngày hết hạn",
        },
      },
      step5_2: {
        label: "Thông tin cá nhân",
        question1: {
          label: "Họ",
          placeHolder: "",
        },
        question2: {
          label: "Đệm và Tên",
          placeHolder: "",
        },
        question3: {
          label: "Ngày sinh",
          placeHolder: "",
        },
        question4: {
          label: "Giới tính",
          placeHolder: "",
        },
        question5: {
          label: "Số điện thoại",
          placeHolder: "",
        },
        question6: {
          label: "Email",
          placeHolder: "",
        },
        question7: {
          label: "Địa chỉ",
          placeHolder: "",
        },
        optionSex: {
          value0: "Khác",
          value1: "Nam",
          value2: "Nữ",
        },
        errorValidate: {
          phone: "Số điện thoại không đúng định dạng",
          email: "Email không đúng định dạng",
        },
      },
      step6: {
        label: "Chọn số tài khoản",
        question1: {
          label: "Không chọn số",
        },
        question2: {
          label: "Chọn số theo gợi ý",
        },
        question3: {
          label: "Tự chọn số tài khoản",
          prefix: "045C",
        },
        note: "Lưu ý: Số tài khoản phải gồm 6 chữ số !",
        checkCode: "Số tài khoản đã tồn tại. Quý khách vui lòng chọn số khác",
      },
      step7: {
        label: "Thông tin tài khoản thụ hưởng",
        btnAddAccount: "Thêm tài khoản",
        btnRemoveAccount: "Xoá tài khoản",
        errorName: "Tên tài khoản thụ hưởng của Quý khách không phải chính chủ",
        question1: {
          label: "Người thụ hưởng",
          placeholder: "Nhập tên người thụ hưởng",
          labelCheckbox: "Chính chủ",
        },
        question2: {
          label: "Số tài khoản",
          placeholder: "Nhập số tài khoản",
          check: "Chính chủ",
        },
        question3: {
          label: "Ngân hàng",
          placeholder: "Chọn ngân hàng",
        },
        question4: {
          label: "Tỉnh thành",
          placeholder: "Chọn tỉnh thành",
        },
        question5: {
          label: "Chi nhánh",
          placeholder: "Chọn chi nhánh",
        },
      },
      step8: {
        label: "",
        btnAddAccount: "",
        question1: {
          label: "1. Giao dịch qua tổng đài điện thoại PSI Contact Center",
          value1: "Có",
          value2: "Không",
        },
        question2: {
          label: "2. Giao dịch trực tuyến quá Internet, ứng dụng di động",
          value1: "Có",
          value2: "Không",
        },
        question3: {
          label: "3. Phương thức xác thực cấp 2",
          value1: "SMS OTP",
          value2: "Smart OTP",
        },
        question4: {
          label: "4. Dịch vụ nhận tin nhắn SMS",
          value1: "Có",
          value2: "Không",
        },
        question5: {
          label: "5. Dịch vụ nhận kết quả khớp lệnh, sao kê qua Email",
          value1: "Có",
          value2: "Không",
        },
        question6: {
          label: "6. Dịch vụ ứng trước tiền bán tự động",
          value1: "Có",
          value2: "Không",
        },
        note: "Tìm hiểu về ứng trước tiền bán tự động ",
        noteLinhk: "Tại đây",
        contentApcet:
          "Tôi đã đọc và đồng ý với các điều khoản được quy định tại ",
        contentLink: "Hợp đồng mở tài khoản",
      },
      stepOTP: {
        title: "Xác nhận OTP",
        content:
          "Vui lòng chờ trong giây lát để chúng tôi xác thực số điện thoại của Quý khách. Hãy nhập mã OTP vừa được gửi đến số điện thoại Quý khách vừa đăng ký.",
        content2: "Mã OTP có hiệu lực trong {time} giây",
        labelQuestion: "Nhập mã OTP",
        placehoder: "Nhập mã OTP",
        btnOTP: "Gửi lại mã",
      },
      confirm: {
        content1:
          "Quý khách dã hoàn tất việc đăng ký thông tin mở tài khoản giao dịch chứng khoán trực tuyến tại VGAIA.",
        content2:
          "Thông báo kích hoạt tài khoản giao dịch chứng khoán và hướng dẫn hoàn thiện hồ sơ sẽ được gửi đến email của Quý khách.",
        content3:
          "Sau khi nhận được email, tài khoản của Quý khách sẽ được kích hoạt tính năng đặt lệnh giao dịch tạm thời (Quý khách có thể nộp và đặt lệnh giao dịch chứng khoán trên tài khoản trực tiếp tại quầy giao dịch, qua tổng đài Contact Center hoặc cả ứng dụng giao dịch trực tuyến của VGAIA). Để có thể sử dụng đầy đủ các dịch (rút tiền/đăng ký margin...) của VGAIA. Quý khách vui lòng hoàn thiện hồ sơ pháp lý và gửi về Chi nhánh/Phòng giao dịch của VGAIA trong thời gian quy đinh (14 ngày kể từ ngày tài khoản thành công).",
        content4: "Hồ sơ bao gồm:",
        content5:
          "Hợp đồng mở tài khoản giao dịch chứng khoán (đã gửi về email của Quý khách): 02 bản",
        content6: "Bản sao CMND hoặc CCCD: 02 bản",
        content7:
          "Bản xác thực chữ ký có xác nhận của UBND phường xã hoặc Phòng công chứng: 01 bản",
        content8:
          "Vui lòng liên hệ TRung tâm Dịch vụ khách hàng: 1900 558838 hoặc +84 24 3972 9888 để được hỗ trợ nếu không nhận được email sau 1 ngày làm việc.",
        content9:
          "Cảm ơn Quý khách đã lựa chọn dịch vụ của CTCP Chứng khoán VGAIA!",
      },
    },
    editPassFirst: {
      title: "Đổi mật khẩu",
      btnCancel: "Hủy",
      btnSuccess: "Cập nhật",
      errerValid: "Không được để trống trường này",
      passLogin: {
        title: "Đổi mật khẩu đăng nhập",
        nowPass: "Mật khẩu hiện tại",
        placeholderNowPass: "Nhập mật khẩu hiện tại",
        newPass: "Mật khẩu mới",
        placeholderNewPass: "Nhập mật khẩu mới",
        confirmPass: "Nhập lại MK mới",
        placeholderconfirmPass: "Nhập lại mật khẩu mới",
      },
      cdLogin: {
        title: "Đổi mật khẩu giao dịch",
        nowPass: "Mật khẩu hiện tại",
        placeholderNowPass: "Nhập mật khẩu hiện tại",
        newPass: "Mật khẩu mới",
        placeholderNewPass: "Nhập mật khẩu mới",
        confirmPass: "Nhập lại MK mới",
        placeholderconfirmPass: "Nhập lại mật khẩu mới",
      },
    },
  },
  category: {
    table: {
      noContent: "",
      profitPerLoss: "+/-",
      changePercentage: "%",
      ticker: "Mã CK",
      ceiling: "Trần",
      floor: "Sàn",
      referenceShort: "TC",
      reference: "Tham chiếu",
      overBought: "Dư mua",
      overSold: "Dư bán",
      filled: "Khớp lệnh",
      price: "Giá",
      amount: "KL",
      high: "Cao",
      low: "Thấp",
      average: "TB",
      foreign: "ĐT NƯỚC NGOÀI",
      overBoughtAmount3: "(Dư mua) KL 3",
      amount3: "KL 3",
      overBoughtPrice3: "(Dư mua) Giá 3",
      price3: "GIÁ 3",
      overBoughtAmount2: "(Dư mua) KL 2",
      amount2: "KL 2",
      overBoughtPrice2: "(Dư mua) Giá 2",
      price2: "GIÁ 2",
      overBoughtAmount1: "(Dư mua) KL 1",
      amount1: "KL 1",
      overBoughtPrice1: "(Dư mua) Giá 1",
      price1: "GIÁ 1",
      filledPrice: "(Khớp lệnh) Giá",
      lastSale: "LAST_SALE",
      filledDiff: "(Khớp lệnh) +/-",
      filledAmount: "(Khớp lệnh) KL",
      overSoldPrice1: "(Dư bán) Giá 1",
      overSoldAmount1: "(Dư bán) KL 1",
      overSoldPrice2: "(Dư bán) Giá 2",
      overSoldAmount2: "(Dư bán) KL 2",
      overSoldPrice3: "(Dư bán) Giá 3",
      overSoldAmount3: "(Dư bán) KL 3",
      sumAmount: "TỔNG KL",
      foreignBought: "(ĐTNN) Mua",
      buy: "MUA",
      foreignSold: "(ĐTNN) Bán",
      sold: "BÁN",
      date_dh: "Ngày ĐH",
      diff: "Độ lệch",
      oi: "OI",
      tcph: "TCPH",
      gdcc: "GDCC",
      ckcs: "CKCS",
      price_ckcs: "Giá CKCS",
      th: "GIÁ THỰC HIỆN",
      ratio: "Tỷ lệ chuyển đổi",
      diff_cd: "Độ lệch CĐ",
      break_even_point: "Điểm hòa vốn",
      volume: "Khối lượng",
      stock: "Chứng khoán",
      time: "Thời gian",
      transactionValue: "Giá trị giao dịch",
      accumulatedValue: "Giá trị tích lũy",
      accumulatedVolume: "KL tích lũy",
      bid: "Chào mua",
      ask: "Chào bán",
      currentRoom: "ROOM NN",
      footer: "Giá x 1,000 VNĐ. Khối lượng x 10. Giá trị x 1,000,000 VNĐ.",
      footerPutThrough: "Giá x 1,000 VNĐ. Khối lượng x 1. Giá trị x 1 VNĐ.",
      footerOdd: "Giá x 1,000 VNĐ. Khối lượng x 1. Giá trị x 1,000,000 VNĐ.",
    },
    modal: {
      title: "Chỉnh sửa cột",
      editColumn: "Sửa cột",
      selectColumn: "Chọn cột hiển thị",
      reset: "Reset",
      cancel: "Hủy",
      apply: "Áp dụng",
      autoScroll: "Tự động cuộn",
      duration: "Độ trễ",
      note: "(mili giây / mã chứng khoán)",
      duration500: "Độ trễ phải >=500",
    },
    custom: {
      title: "Danh mục của bạn",
      confirm: "Xác nhận",
      nameCategory: "Nhập tên danh mục",
      addCategory: "Thêm danh mục",
      cancel: "Hủy",
      favorite: "Danh mục yêu thích",
      addToFavoriteListSuccess: "Thêm vào danh mục yêu thích thành công",
      addToFavoriteListFailure: "Mã này đã tồn tại trong danh mục",
      removeFromFavoriteListSuccess: "Cập nhật danh mục thành công",
    },
    tabs: {
      owner: "Danh mục sở hữu",
      listed: "Niêm yết",
      putThrough: "Thỏa thuận",
      derivative: "Phái sinh",
      coverWarrant: "Chứng quyền",
      industry: "Ngành",
      oddLot: "Lô lẻ ",
    },
    derivative: {
      vn30: "HĐTL chỉ số VN30",
      tpcp: "HĐTL TPCP",
    },
    coverWarrant: {
      mbs: "Chứng quyền tại MBS",
      full: "Chứng quyền toàn thị trường",
    },
    putThrough: {
      hsx: "HSX",
      hnx: "HNX",
      upcom: "UPCOM",
      totalSharesTradedPT: "TỔNG KHỐI LƯỢNG GIAO DỊCH THỎA THUẬN",
      totalValuesTradedPT: "TỔNG GIÁ TRỊ GIAO DỊCH THỎA THUẬN",
      billionVnd: "đồng",
      dataEmpty: "Chưa có dữ liệu",
    },
    errors: {
      duplicatedName: "Tên danh mục đã tồn tại",
      required: "Nhập tên danh mục để lưu lại",
      sameOwnerName: "Tên danh mục đã trùng với tên danh mục sở hữu",
    },
  },
  market: {
    dragTooltip: "Thêm vùng hiển thị<br>(Di chuột đến khu vực cần thêm)",
    viewDoesntExist: "Màn hình không tồn tại",
    newScreen: "Phân tích thị trường",
  },
  widgets: {
    orderBookCondition: {
      name: "Sổ lệnh điều kiện",
    },
    orderBasket: {
      name: "Giỏ lệnh cơ sở",
    },
    orderBasketDer: {
      name: "Giỏ lệnh phái sinh",
    },
    moneyblock: {
      name: "Thông tin tài sản",
    },
    coverWarrantInfo: {
      name: "Thông tin CW",
      cwIssuerName: "Tổ chức phát hành",
      cwType: "Loại CW",
      cwExercisePrice: "Giá thực hiện",
      cwExerciseRatio: "Tỉ lệ chuyển đổi",
      cwRemainLastTradingDate: "Thời gian còn lại (ngày)",
      CKCSCode: "Mã CKCS",
      CKCSPrice: "Giá CKCS",
      status: "Trạng thái",
      cwLastTradingDate: "Ngày giao dịch cuối cùng",
      cwMaturityDate: "Ngày đáo hạn",
    },
    tickerGraph: {
      name: "Phân tích kỹ thuật",
    },
    watchList: {
      name: "Danh sách theo dõi",
    },
    accountInfo: {
      name: "Thông tin tài khoản",
    },
    financialService: {
      name: "Dịch vụ tài chính",
    },
    stockOrder: {
      name: "Đặt lệnh",
    },
    priceList: {
      name: "Bảng giá",
    },
    TickerInfo: {
      name: "Thông tin ticker",
      errors: {
        noWatchlistToAdd: "Bạn cần có ít nhất một danh sách yêu thích",
      },
    },
    orderBookOfTheDay: {
      name: "Sổ lệnh trong ngày",
    },
    finance: {
      year: "Năm",
      quarter: "Quý",
      viewType: "Xem theo",
      mainIndicator: {
        label: "Chỉ số chính",
        type: {
          roe: "ROE",
          roa: "ROA",
          eps: "EPS",
          yoy: "YOY",
        },
      },
      reportRevenue: {
        label: "Báo cáo doanh thu",
        type: {
          netincome: "Lợi nhuận",
          netrevenue: "Tổng doanh thu",
          operatingincome: "Lợi nhuận KD",
          yoy: "YOY",
        },
      },
      cashFlow: {
        label: "Dòng tiền",
        type: {
          cashflowoperating: "Kinh doanh",
          cashflowinvesting: "Đầu tư",
          cashflowfinance: "Tài chính",
          yoy: "YOY",
        },
      },
      balanceSheet: {
        label: "Bảng cân đối kế toán",
        totalAssets: "Tổng tài sản",
        totalLiabilities: "Nợ phải trả",
        solvencyDebtAssets: "Nợ trên tổng tài sản",
      },
    },
    Card: {
      agree: "Thỏa thuận",
    },
    foreignWatch: {
      name: "Khối ngoại",
      description:
        "Màu xanh trên biểu đồ thể hiện khối ngoại mua ròng. Màu đỏ thể hiện khối ngoại bán ròng. Dữ liệu bao gồm Cổ phiếu và ETF. Dữ liệu bao gồm cả thỏa thuận. Đơn vị giá trị là triệu VND.",
      floor: "Sàn",
      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      buyValue: "GT Mua",
      sellValue: "GT Bán",
      netValue: "GT Ròng",
      totalBuyValue: "Tổng mua",
      totalSellValue: "Tổng bán",
      totalNetValue: "GT ròng",
    },
    marketWatch: {
      name: "Chuyển động thị trường",
      topIndex: "TOP đóng góp INDEX",
      marketRange: "Độ rộng thị trường",
      liquidity: "Thanh khoản",
      transactionValueVolume: "Giá trị và khối lượng GD",
      capitalization: "Vốn hóa",
      floor: "Sàn",
      valueType: "Loại GD",
      value: "Giá trị",

      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      transactionTypes: {
        value: "Giao dịch khớp lệnh",
        valueAll: "Giao dịch khớp lệnh và thỏa thuận",
      },
      valueTypes: {
        price: "Giá trị giao dịch",
        volume: "Khối lượng giao dịch",
      },
    },
    marketStats: {
      yAxesLabel: "Đóng góp vào INDEX (%)",
      xAxesLabel: "KLGD / TB KLGD 5 phiên",
      changedPercentVolumeWithAvg5d: "KLGD / TB KLGD 5 phiên:",
      contributionPercentage: "Đóng góp vào INDEX (%):",
      contributionValue: "Đóng góp vào INDEX (điểm):",
      marketPercent: "% Vốn hóa:",
    },
    liquidity: {
      name: "Thanh khoản",
      description:
        "Biểu đồ so sánh dòng tiền tại cùng thời điểm với phiên trước, trung bình 1 tuần, 2 tuần và trung bình 1 tháng.",
      present: "Hiện tại",
      lastSession: "Phiên trước",
      oneWeek: "1 tuần",
      twoWeek: "2 tuần",
      oneMonth: "1 tháng",
    },
    topIndex: {
      percent: "Đóng góp vào INDEX (%)",
      value: "Đóng góp vào INDEX (điểm)",
      increaseContribute: "Mức đóng góp tăng",
      decreaseContribute: "Mức đóng góp giảm",
      point: "điểm",
      description:
        "Biểu đồ thể hiện thông tin điểm ảnh hưởng và % ảnh hưởng của 30 cổ phiếu tác động nhất tới tăng giảm điểm của Bộ chỉ số, trong đó 15 mã ảnh hưởng chiều tăng và 15 mã ảnh hưởng chiều giảm.",
    },
    capitalization: {
      description:
        "Biểu đồ cho thấy phân bổ vốn hóa của tất cả các mã trên sàn. Đơn vị là triệu VNĐ.",
    },
    transactionValueVolume: {
      description:
        "Biểu đồ thể hiện giá trị/khối lượng giao dịch của sàn đang tập trung vào những mã nào. Đơn vị giá trị giao dịch là triệu VND.",
    },
    industryDescription: {
      topIndex: {
        description:
          "Biểu đồ thể hiện đóng góp của ngành vào INDEX. Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
      transactionIndustry: {
        description:
          "Biểu đồ thể hiện giá trị giao dịch ngành (đơn vị Triệu VND). Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
    },
    marketRange: {
      description:
        "Trục ngang thể hiện tỷ lệ KLGD với trung bình KLGD 5 phiên. Trục đứng cho thấy đóng góp của từng mã vào return của index tại phiên hiện tại.",
    },
    topPrice: {
      left: "Top tăng",
      right: "Top giảm",
      headers: {
        today: "Hôm nay",
        oneWeek: "1 tuần",
        oneMonth: "1 tháng",
        threeMonths: "3 tháng",
        sixMonths: "6 tháng",
        oneYear: "1 năm",
      },
      table: {
        stockId: "MÃ CK/TÊN CÔNG TY",
        price: "giá khớp",
        changedPrice: "Thay đổi",
        changedPricePercentage: "%",
      },
    },
    coverWarrant: {
      title: "Chứng quyền",
      table: {
        stockId: "Mã CK",
        price: "Giá Khớp",
        changedPrice: "+/-",
        changedPricePercentage: "%",
        volume: "Tổng KL",
      },
      headers: {
        allIssuer: "Tất cả TCPH",
        allUnderlying: "Tất cả CKCS",
      },
    },
    industry: {
      title: "Ngành",
      select: "Chọn ngành",
      table: {
        ticker: "MÃ CK",
        price: "GIÁ KHỚP",
        totalAmount: "TỔNG K/L",
      },
    },
    topStatistical: {
      name: "Cổ phiếu GD hàng đầu",
      headers: {
        topVolume: "Khối lượng",
        topValue: "Giá trị",
        topChange: "% Biến động",
      },
      table: {
        stockId: "Mã CK",
        price: "Giá cuối",
        changedPrice: "Thay đổi",
        changedPricePercentage: "%",
        changeHighLow: "% Biến động",
        topValue: "Giá trị(Tỷ)",
        topValueMass: "KL(Triệu)",
      },
    },
    topForeign: {
      name: "Top nước ngoài",
      headers: {
        foreignNetValuesBuy: "Mua ròng",
        foreignNetValuesSell: "Bán ròng",
        foreignBuy: "KL Mua",
        foreignSell: "KL Bán",
        foreignBuyValue: "GT Mua",
        foreignSellValue: "GT Bán",
      },
      table: {
        stockId: "MÃ CK/TÊN CÔNG TY",
        price: "giá khớp",
        changedPrice: "Thay đổi",
        changedPricePercentage: "%",
        topValue: "Giá trị(Triệu)",
        topValueMass: "KL(Nghìn)",
      },
    },
    exchangeTradedFund: {
      name: "ETF",
      priceMatch: "Giá khớp",
      codeTicker: "Mã CK",
      volume: "Tổng KL",
    },
    recommendation: {
      name: "Cổ phiếu khuyến nghị",
      code: "Mã",
      side: "KN",
      recommendedPrice: "Giá KN",
      lastSale: "Giá TT",
      expectedPrice: "Mục tiêu",
      positive: "Tích cực",
      watching: "Theo dõi",
      buy: "Mua",
      sell: "Bán",
    },
    adIndex: {
      name: "Độ sâu thị trường",
      title: "Chỉ số A-D",
      sum: "Tổng: {number}",
      advance: "Tăng (Advancers): {number}",
      decline: "Giảm (Decliners): {number}",
    },
    vn30Index: {
      title: "Chỉ số VN30",
    },
    organization: {
      title: "Hồ sơ doanh nghiệp",
      domesticAndForeign: "Cơ cấu trong và ngoài nước",
      domesticStackHolder: "Nhà đầu tư trong nước",
      foreignStackHolder: "Nhà đầu tư ngoài nước",
    },
    netflow: {
      name: "Netflow",
      positive: "Net Flow > 0: Tín hiệu tích cực",
      negative: "Net Flow < 0: Tín hiệu tiêu cực",
      headers: {
        present: "T",
        oneDayAgo: "T - 1",
        twoDayAgo: "T - 2",
        threeDayAgo: "T - 3",
        fourDayAgo: "T - 4",
        fiveDayAgo: "T - 5",
      },
      noteText:
        "Là chỉ báo đo bằng giá trị lệnh mua chủ động - giá trị lệnh bán chủ động của tất cả các mã cổ phiếu trong VN30, để từ đó xác định được dòng tiền đang mua chủ động hay bán chủ động.",
    },
    orderFlowDistribution: {
      title: "Phân bổ lệnh khớp (Đơn vị: Tỷ đồng)",
      small: "Nhỏ",
      medium: "TB",
      large: "Lớn",
      totalBuy: "Giá trị Mua",
      totalSell: "Giá trị Bán",
      noteTitle:
        "GIÁ TRỊ MUA/BÁN ĐƯỢC THỐNG KÊ THEO CHI TIẾT LỆNH KHỚP LÀ MUA CHỦ ĐỘNG/BÁN CHỦ ĐỘNG VỚI CÁC MỨC GIÁ KHỚP LỆNH (GTKL) TƯƠNG ỨNG:",
      noteText1: "- Lớn: 10% GTKL lớn nhất",
      noteText2: "- Nhỏ: 10% GTKL bé nhất",
      noteText3: "- Trung bình: 80% GTKL còn lại",
    },
    infoQuote: {
      title: "Bước giá",
      volume: "Khối lượng",
    },
    volumeAndTime: {
      title: "Khối lượng và thời gian",
      matchHistory: "Lịch sử khớp",
      volumeAnalysis: "Phân tích KL",
      esideShorthand: {
        buy: "M",
        sell: "B",
      },
    },
    tickerInfo: {
      fillCode: "Nhập mã CK tên công ty...",
      ceilingPrice: "Trần",
      floorPrice: "Sàn",
      referencePrice: "TC",
      openAveragePrice: "Mở cửa/TB",
      lowesthighestPrice: "Thấp - Cao",
      totalSellQty: "KLNN Bán",
      totalBuyQty: "KLNN Mua",
      totalSharesTradeMt: "Tổng khối lượng",
      marketCap: "Vốn hóa",
      sharesOutstanding: "KL Lưu hành",
      priceToEarningRatio: "P/E (TTM)",
      tangibleBookValuePerShare: "Giá trị/ Giá trị sổ sách",
      returnOnEquity: "ROE",
      highestPrice52Week: "52 Tuần",
      turnoverRate: "% Giao dịch ",
      priceToBook: "P/B",
      dividend: "Cổ tức",
      range: "% Chênh lệch",
      earningPerShare: "EPS (TTM)",
      priceToSalesRatio: "P/S",
      dividendYield: "TL Cổ tức",
      currentRoom: "Room NN",
      DILastTradingDate: "Ngày ĐH",
      bias: "Độ Lệch",
      OI: "OI",
      lasttradedate: "Ngày GD CC",
      execprice: "GIÁ THỰC HIỆN",
      execratio: "TL CHUYỂN ĐỔI",
      issuer: "TCPH",
      baseseccd: "MÃ CKCS",
      baseprice: "GIÁ CKCS",
    },
    placeOrder: {
      conditionalOrder: {
        title: "Lệnh điều kiện",
        normal: "---",
        timeConditionOrder: "Trước ngày",
        trailingStopOrder: "Xu hướng",
        priorityOrder: "Tranh Mua/Bán",
        takeProfit: "Chốt lãi",
        cutLoss: "Cắt lỗ",
        generatedOneTime: "Phát sinh 1 lần",
        matchingEnoughVolume: "Khớp đủ khối lượng",
        byPercent: "Theo %",
        byValue: "Theo giá trị",
        conditionsList: {
          noCondition: "Không điều kiện",
          referencePrices: "Giá tham chiếu",
          matchedPrice: "Giá khớp",
        },
        priceTC: "Giá TC",
        errorPlaceSellOrderSP: "Lệnh chốt lãi không áp dụng lệnh mua",
        errorPlaceSellOrderCL: "Lệnh cắt lỗ không áp dụng lệnh mua",
        errorPriceTCOLO: "Lệnh trước ngày chỉ hỗ trợ giá LO",
      },
      errorCheckFAL: "Vui lòng nhấn xác nhận trước khi đặt lệnh.",
      inputTicker: "Nhập mã CK",
      errorTickerEmpty: "Vui lòng nhập mã chứng khoán",
      errorTickerIllegal: "Mã CK không hợp lệ",
      account: "Tài khoản",
      tradetype: "Loại lệnh",
      method: "Phương thức",
      condition: "Điều kiện",
      toDate: "Đến ngày",
      buyTitle: "XÁC NHẬN LỆNH MUA",
      sellTitle: "XÁC NHẬN LỆNH BÁN",
      accountNumber: "Số tài khoản",
      commandType: "Loại lệnh",
      stockCode: "Mã chứng khoán",
      sellVolumn: "Khối lượng bán",
      buyVolumn: "Khối lượng mua",
      sellPrice: "Giá bán",
      buyPrice: "Giá mua",
      totalLabel: "Giá trị lệnh đặt (VND)",
      noVATLabel: "(chưa bao gồm thuế và phí)",
      confirmFAL:
        "Quý khách hàng đang đặt lệnh duyệt FAL, vui lòng xác nhận lại!",
      visibleModalConfirm:
        "Không hiển thị lại vào lần sau. Bạn có thể bật lại chức năng này ở Cài đặt giao dịch",
      comfirmBuy: "Xác nhận mua",
      comfirmSell: "Xác nhận bán",
      slidingMargin: "Biên trượt",
      value: "Giá trị",
      percent: "%",
      stopInterval: "Khoảng dừng",
      minBuyPrice: "Giá mua thấp nhất",
      maxSellPrice: "Giá bán cao nhất",
      avgPrice: "Giá BQ",
      arbitrage: "Chênh lệch giá",
      cancel: "Hủy",
      buyFal: "Mua Fal",
      dailyCredit: "Hạn mức mua trong ngày",
      maxBuy: "Mua tối đa",
      maxSell: "Bán tối đa",
      maxVolume: {
        tooltip: "Nhấn để đặt lệnh tối đa",
      },
      marginRate: "Tỷ lệ ký quỹ",
      purchasingAbility: "Sức mua",
      buy_command: "ĐẶT LỆNH MUA",
      sell_command: "ĐẶT LỆNH BÁN",
      volumn: "KHỐI LƯỢNG",
      ceilingPrice: "Trần",
      floorPrice: "Sàn",
      referencePrice: "TC",
      orderSuccess: "Đặt lệnh thành công",
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      errorAccountDer: "Dữ liệu không hợp lệ, vui lòng thử lại",
      volume: "Khối lượng",
      price: "Giá",
      normal: "Thường",
      orderLimitedQty: "Tối đa",
      accountBlockage: "Tài khoản của khách hành đang bị phong tỏa.",
      insiderShareholder:
        "Giao dịch liên quan đến cổ đông nội bộ. Bạn có tiếp tục thực hiện giao dịch không?",
      bigShareholder:
        "Giao dịch liên quan đến cổ đông lớn. Bạn có tiếp tục thực hiện giao dịch không?",
      maxRateWarning:
        "TL sở hữu chứng khoán của quý khách vượt quá {maxRate}%, quý khách phải thực hiện chào mua công khai theo quy định của pháp luật. Bạn có muốn tiếp tục thực hiện giao dịch không?",
      privateTradingBond:
        "KL tối thiểu còn lại phải đặt trong ngày là {privateRemainQty}, bạn có muốn tiếp tục không?",
      submitOrderPending: "Bạn có muốn đặt lệnh trước giờ?",
      orderType: {
        title: "Loại lệnh",
        normal: "THƯỜNG",
        pending: "TRƯỚC GIỜ",
        condition: "ĐIỀU KIỆN",
      },
      currentRoom: "Room CL",
      suggestionFail: "Giá nhập không hợp lệ",
    },
    orderBookDetail: {
      title: "Chi tiết đặt lệnh",
      subAccountNo: "Tài khoản",
      status: "Trạng thái",
      tradeType: "Loại giao dịch",
      symbol: "Mã CK",
      tradingDate: "Thời gian đặt",
      orderQty: "Khối lượng đặt",
      price: "Giá đặt",
      pubQty: "KL chờ",
      pubPrice: "Giá chờ",
      cumQty: "Khối lượng khớp",
      matchAvgPrice: "Giá trị khớp",
      orderNo: "Số hiệu lệnh",
      channel: "Kênh đặt lệnh",
      rejectReason: "Lý do từ chối",
      table: {
        rowNum: "STT",
        tradeDate: "Ngày GD",
        ordQty: "Khối lượng",
        ordPrice: "Giá",
        matQty: "KL khớp",
        matAvg: "Giá khớp",
        regDateTime: "Thời gian",
        confirmTime: "Thời gian",
        tradeType: "Loại giao dịch",
      },
      noData: "Không có dữ liệu",
    },
    orderBookEdit: {
      setPrice: "Giá đặt",
      setVolumn: "KL đặt",
      cancel: "Hủy",
      confirm: "Xác nhận",
      BUY: "Sửa lệnh mua",
      BUY_OUTR: "Sửa lệnh mua OutR",
      SELL: "Sửa lệnh bán",
      SELL_OutR: "Sửa lệnh bán OutR",
      SELL_TT68: "Sửa lệnh bán TT68",
      orderSuccess: "Sửa Lệnh Thành Công",
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      errorVolumnUp: "Không được sửa khối lượng lên.",
      errorVolumnDown: "Không được sửa khối lượng xuống.",
      errorChangeBoth: "Không sửa đồng thời giá và khối lượng.",
    },
    placeOrderDer: {
      name: "Đặt lệnh",
      conditionalOrder: "Lệnh điều kiện",
      availMargin: "Sức mua",
      currentRate: "TL sử dụng KQ",
      maxLong: "Max Long",
      maxShort: "Max Short",
      comfirmShortTitle: "Xác nhận lệnh SHORT",
      comfirmLongTitle: "Xác nhận lệnh LONG",
      tickerCode: "Mã CK",
      volume: "Khối lượng",
      price: "Giá",
      acceptLong: "LONG",
      acceptShort: "SHORT",
      inputTicker: "Nhập mã CK",
      all: "Tất cả",
      A: "---",
      UP: "UP",
      DOWN: "DOWN",
      IFDONESTOP: "IF DONE STOP",
      IFDONEGAIN: "IF DONE GAIN",
      BULLBEAR: "BULL & BEAR",
      OCO: "OCO",
      TRAILINGSTOP: "TRAILING STOP",
      activePrice: "Giá kích hoạt",
      cutLostPrice: "Giá cắt lỗ",
      takeprofit: "Giá chốt lãi",
      limitOffset: "Biên trượt",
      cutLostAmp: "Biên độ cắt lỗ",
      grossAmp: "Biên độ chốt lãi",
      rangeStop: "Khoảng dừng",
      normal: "Lệnh thường",
      orderSuccess: "Đặt Lệnh Thành Công",
      orderError: "Đặt lệnh thất bại",
      errorAccountEquity: "Dữ liệu không hợp lệ, vui lòng thử lại",
    },
    portfolio: {
      account: "Tài khoản",
      alloDate: "Ngày",
      secCd: "Mã CK",
      name: "Danh mục đầu tư",
      userName: "TÀI KHOẢN",
      code: "MÃ CK",
      type: "LOẠI",
      weight: "KHẢ DỤNG",
      wait: "CHỜ NHẬN",
      rightShares: "QUYỀN CHỜ VỀ",
      avgPrice: "GIÁ TB (VNĐ)",
      marketPrice: "GIÁ HIỆN TẠI",
      grossValue: "GIÁ TRỊ MUA",
      marketValue: "GIÁ TRỊ HIỆN TẠI",
      marketValueRatio: "TỈ TRỌNG (%)",
      profit: "LÃI/LỖ",
      profitRatio: "% LÃI/LỖ",
      sum: "TỔNG CỘNG",
      sharesBalance: "TỔNG",
      sellAll: {
        title: "Bán tất cả danh mục",
        titleConfirm: "Xác nhận đặt lệnh",
        percent: "Tỉ lệ KL bán",
        account: "Tiểu tài khoản",
        market: "Sàn",
        floorPrice: "Giá sàn",
        price: "Giá đặt",
        table: {
          userName: "Tài khoản",
          code: "Mã CK",
          type: "Loại",
          sharesBalance: "KL khả dụng",
          weight: "KL bán",
          marketPrice: "Giá hiện tại",
          exchanges: "Sàn",
          sellQty: "KL đặt",
          price: "Giá đặt",
        },
        cancel: "Hủy",
        confirm: "Xác nhận",
        placeOrder: "Đặt lệnh",
        emptySelect: "Không có lệnh nào phù hợp được chọn",
        orderSuccess: "Đặt lệnh thành công {count}/ {total} lệnh",
        errorSessionHSX:
          "Bán toàn danh mục sàn HOSE chỉ đặt lệnh phiên ATO, ATC, Liên tục",
        errorSessionHNX:
          "Bán toàn danh mục sàn HNX chỉ đặt lệnh phiên ATC, Liên tục, PLO",
        errorSessionUPCOM:
          "Bán toàn danh mục sàn Upcom chỉ đặt lệnh phiên Liên tục",
        noSelect: "Không có lệnh nào được chọn",
      },
    },
    orderBookDerEdit: {
      titleLong: "Sửa lệnh long",
      titleShort: "Sửa lệnh short",
      setPrice: "Giá đặt",
      setVolume: "KL đặt",
      profit: "Giá chốt lãi",
      conditionPrice: "Giá điều kiện",
      stopLoss: "Giá cắt lỗ",
      margin: "Biên trượt",
      cancel: "Hủy",
      orderSuccess: "Gửi lệnh sửa thành công",
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      confirmLong: "Xác nhận lệnh Long",
      confirmShort: "Xác nhận lệnh Short",
      confirm: "Xác nhận",
    },
    openPosition: {
      name: "Vị thế mở",
      seriesId: "Mã CK",
      net: "KL vị thế mở",
      askAvgPrice: "Giá vốn",
      marketPrice: "Giá TT",
      floatingPL: "Lãi/Lỗ",
      summary: "Tổng lãi/lỗ",
      valuePositionSummary: "TỔNG GT",
    },
    closePosition: {
      name: "Vị thế đóng",
      seriesId: "Mã CK",
      long: "Vị thế đóng",
      net: "Vị thế ròng",
      askAvgPrice: "Bán trung bình",
      bidAvgPrice: "Mua trung bình",
      tradingPL: "Lãi/Lỗ",
      summary: "Tổng cộng",
    },
    watchlist: {
      selectCategory: "Chọn danh mục",
      ticker: "MÃ CK",
      sumVolume: "TỔNG KL",
      percent: "%",
    },
    maximunTabError: "Vượt quá số lượng bảng tối đa",
    marginContract: {
      name: "Margin contract",
    },
  },
  tickerDetail: {
    title: "Chi tiết cổ phiếu",
    tabs: {
      finance: "Tài chính",
      orgInfo: "Thông tin doanh nghiệp",
      news: "Tin tức",
      listPrice: "Bước giá",
      volumeAndTime: "KL & thời gian",
      distribution: "Phân bổ lệnh khớp",
    },
    widgets: {
      tickerInfo: "Thông tin mã chứng khoán",
      priceList: "Bước giá",
      volumeAndTime: "Khối lượng và thời gian",
      orderFlowDistribution: "Phân bổ lệnh khớp (Đơn vị: Tỷ đồng)",
      organizationInfo: "Thông tin doanh nghiệp",
      financeInfo: "Tài chính",
      new: "Tin tức",
      tickerGraph: "Phân tích kỹ thuật",
      tickerDetailsBasketContribution: "Chỉ số VN30",
      coverWarrantInfo: "Thông tin mã chứng quyền",
    },
  },
  extends: {
    statement: {
      orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
      error90Day: "Vượt quá giới hạn tìm kiếm 90 ngày",
      error30Day: "Vượt quá giới hạn tìm kiếm 30 ngày",
      error7Day: "Vượt quá giới hạn tìm kiếm 7 ngày",
      errorDay: "Từ ngày không được lớn hơn Đến ngày",
      type: "Nghiệp vụ",
      all: "Tất cả",
      cashStatement: "Sao kê Tiền",
      stockStatement: "Sao kê chứng khoán",
      derStatement: "Sao kê phái sinh",
      stt: "STT",
      userName: "TÀI KHOẢN",
      tradeDate: "NGÀY THỰC HIỆN",
      cashUp: "PHÁT SINH TĂNG",
      cashDown: "PHÁT SINH GIẢM",
      balance: "SỐ DƯ",
      content: "NỘI DUNG",
      beginBalance: "SỐ DƯ ĐẦU KỲ",
      sum: "TỔNG CỘNG",
      closeBalance: "SỐ DƯ CUỐI KỲ",
      ticker: "Mã chứng khoán",
      exchange: "Sàn giao dịch",
      account: "Tài khoản",
      shareBegin: "Số dư đầu kỳ",
      shareChange: "Tổng cộng",
      shareEnd: "Số dư cuối kỳ",
      shareCode: "MÃ CHỨNG KHOÁN",
      shareStatus: "LOẠI",

      derStatementTable: {
        rowNo: "STT",
        date: "NGÀY THỰC HIỆN",
        transactionNo: "SỐ CHỨNG TỪ",
        balanceMBS: "SỐ DƯ TẠI MBS",
        clientDebit: "PHÁT SINH GIẢM",
        clientCredit: "PHÁT SINH TĂNG",
        balanceVSD: "SỐ DƯ TẠI VSD",
        brokerDebit: "PHÁT SINH GIẢM",
        brokerCredit: "PHÁT SINH TĂNG",
        totalBalance: "SỐ DƯ",
        description: "NỘI DUNG",

        summary: "TỔNG PHÁI SINH",
        beginBalance: "SỐ DƯ ĐẦU KỲ",
        endBalance: "SỐ DƯ CUỐI KỲ",
      },
    },
    profitLossEquity: {
      title: "Lãi lỗ cơ sở",
      header: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        symbol: "Mã CK",
        search: "Tìm kiếm",
        inputSearch: "Nhập mã CK",
        allAccount: "Tất cả",
        errorDayRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
        errorDayRange2: "Từ ngày không được vượt quá Đến ngày",
      },
      tableContent: {
        tradingDate: "Ngày giao dịch",
        accountNo: "Tài khoản",
        symbol: "Mã CK",
        status: {
          title: "Trạng thái",
          normal: "Thường",
          outR: "OutR",
          tt68: "TT68",
        },
        content: "Loại lệnh",
        volume: "Khối lượng",
        matchedPrice: "Giá khớp",
        fee: "Phí bán",
        taxToPay: "Thuế/Phí khác",
        avgPrice: "Giá TB khi bán",
        gainloss: "Lãi/lỗ",
        percent: "%Lãi/lỗ",
        totalProfit: "Tổng cộng",
        loadmore: "Tải thêm...",
        loadend: "Bạn đã tải hết!!!",
        nothing: "Không có bản ghi nào",
        buy: "MUA",
        sell: "BÁN",
      },
    },
    profitLossDer: {
      title: "Lãi lỗ phái sinh",
      header: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        symbol: "Mã CK",
        search: "Tìm kiếm",
        inputSearch: "Nhập mã CK",
        allAccount: "Tất cả",
        errorDayRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
      },
      tableContent: {
        transDate: "Ngày giao dịch",
        symbol: "Mã CK",
        netOff: "Đóng giao dịch",
        expired: "Đáo hạn",
        open: "Vị thế mở còn lại",
        totalNetOff: "Tổng vị thế",
        avgBuyPrice: "Giá mua TB",
        avgSellPrice: "Giá bán TB",
        closePrice: "Giá đóng cửa",
        profit: "Tổng lãi/lỗ",
        totalProfit: "Tổng cộng",
        buy: "Mua",
        sell: "Bán",
        long: "Long",
        short: "Short",
      },
    },
    confirmationOrderOnline: {
      success: "Đăng ký thành công",
      title: "Xác nhận lệnh online",
      modalMessage: {
        title: "Thông báo",
        titleConfirm:
          "Tôi đồng ý và xác nhận thông tin tại đây là chính xác và đúng theo thỏa thuận/yêu cầu của tôi với MBS.",
        textBody:
          " \t Khách hàng đồng ý với các thông tin ở trên và xác nhận rằng các lệnh giao dịch trên được thực hiện bởi nhân viên của MBS là đúng theo yêu cầu của Khách hàng gửi tới MBS trước thời điểm các lệnh giao dịch này được thực hiện. \n \t Khách hàng xác nhận rằng, số tiền giải ngân theo từng ngày được MBS thông báo tới Khách hàng theo các phương thức do MBS triển khai từng thời kì.\n \t Đồng thời, Khách hàng nhận nợ và cam kết thanh toán cho MBS và/hoặc Bên thứ 3 có liên quan toàn bộ các nghĩa vụ tài chính phát sinh từ kết quả khớp lệnh đã được Khách hàng xác nhận ở trên.",
        content:
          "Quý khách vui lòng đăng ký tiện ích Xác nhận lệnh qua hệ thống GDĐT tại MBS.",
      },
      modalConfirmExit: {
        title: "Xác nhận thoát",
        content: "Bạn có chắc chắn muốn hủy hoạt động này?",
      },
      modalConfirmCondition: {
        title: "Đăng ký dịch vụ Xác nhận lệnh Online",
        account: "Tài khoản",
        textCondition1: "Tôi đồng ý với",
        textCondition2: "Điều kiện và Điều khoản",
        textCondition3: "xác nhận lệnh qua hệ thống giao dịch điện tử tại MBS.",
        messageConfirmOrderSuccess: "Xác nhận lệnh thành công",
        messageVerifyOTPSuccess: "Xác nhận mã OTP thành công",
        messageVerifyOTPFailure:
          "Xác nhận mã OTP thất bại, vui lòng xác nhận lại.",
        sendAgain: "Gửi lại",
      },
      header: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        search: "Tìm kiếm",
        allAccount: "Tất cả",
        confirmType: "Trạng thái XNL",
        errorDayRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
        errorDay: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
      },
      tableContent: {
        orderDate: "Ngày đặt lệnh",
        accountCode: "Tài khoản",
        side: "Loại giao dịch",
        shareCode: "Mã CK",
        orderVolume: "Khối lượng",
        showPrice: "Giá đặt",
        orderStatus: "Trạng thái",
        orderNo: "Số hiệu lệnh",
        channels: "Kênh",
        confirmType: "Trạng thái XNL",
        confirmDate: "Thời gian xác nhận",
        confirmOrder: "Xác nhận lệnh",
        buy: "Mua",
        sell: "Bán",
        unconfimred: "Chưa xác nhận",
        signed: "Đã ký",
        confirmed: "Đã xác nhận",
        selectAll: "Tất cả",
        cancelWaiting: "Chờ hủy",
        fixWaitingFixed: "Chờ sửa/Đã sửa",
        matchWaiting: "Chờ khớp",
        cancelled: "Đã hủy",
        matched: "Đã khớp",
        partialMatch: "Khớp 1 phần",
        refuse: "Từ chối",
      },
    },
    categoryFinancialService: {
      title: "Danh mục Chứng khoán",
      header: {
        serviceType: "Loại dịch vụ",
        serviceTypeAll: "Tất cả",
        exchanges: "Sàn giao dịch",
        exchangesSelectAll: "Tất cả",
        shareCode: "Mã chứng khoán",
        shareCodeSelectAll: "Tất cả",
        search: "Tìm kiếm",
      },
      tableContent: {
        numericalOrder: "STT",
        exchanges: "Sàn giao dịch",
        shareCode: "Mã chứng khoán",
        shareName: "Tên chứng khoán",
        marginMin: "TL ký quỹ tối thiểu (%)",
        marginMax: "TL MBS cho vay/Hợp tác tối đa (%)",
        marginReal: "TL MBS cho vay/Hợp tác thực tế (%)",
        textFooter:
          "Tỷ lệ MBS cho vay/hợp tác thực tế đang được tính trên giá tham chiếu, tỷ lệ giải ngân chính xác sẽ phụ thuộc vào giá đặt mua của Qúy khách hàng.",
      },
    },
    oddLotShare: {
      tabHeader: {
        available: "Bán chứng khoán lô lẻ",
        history: "Lịch sử bán chứng khoán lô lẻ",
      },
      tabPanel: {
        sellOddLotShare: {
          filterActions: {
            search: "Tìm kiếm",
            accountNumberLabel: "Số tài khoản",
            shareCodeLabel: "Mã chứng khoán",
            selectAll: "Tất cả",
          },
          table: {
            header: {
              account: "TÀI KHOẢN",
              shareCode: "MÃ CK",
              oddShare: "CP LẺ",
              sellingPrice: "GIÁ BÁN",
              sellValue: "GIÁ TRỊ BÁN",
              exchange: "SÀN GIAO DỊCH",
              stateShare: "TRẠNG THÁI CK",
            },
            footer:
              "Giao dịch lô lẻ với giá sàn, phí dịch vụ 10% tổng giá trị giao dịch từ ngày 23/07/2012",
            sellButton: "Bán",
            transactionSuccessfulMsg: "Bán thành công",
          },
        },
        sellOddHistory: {
          filterActions: {
            search: "Tìm kiếm",
            fromDateLabel: "Từ ngày",
            toDateLabel: "Đến ngày",
            accountNumberLabel: "Số tài khoản",
            shareCodeLabel: "Mã CK",
            selectAll: "Tất cả",
          },
          table: {
            header: {
              order: "STT",
              account: "TÀI KHOẢN",
              shareCode: "MÃ CK",
              oddShare: "SỐ LƯỢNG",
              buyPrice: "GIÁ BÁN",
              buyValue: "GIÁ TRỊ BÁN",
              buyDate: "NGÀY BÁN",
              statusProcess: "TRẠNG THÁI CK",
            },
          },
          error: {
            dateRange: "Vượt quá giới hạn tìm kiếm 30 ngày",
            dateError: "Từ ngày phải nhỏ hơn Đến ngày",
          },
        },
      },
    },
  },
  categories: {
    placeOrderTooltip: "Click đúp để đặt lệnh",
    ticketTooltip: "Kích để xem chi tiết mã",
    ticketDetail: "Xem chi tiết mã",
  },
  moneyTransfer: {
    all: "Tất cả",
    equity: "Cơ sở",
    derivative: "Phái sinh",
    bankTransfer: "Chuyển tiền ngân hàng",
    internalTransfer: "Chuyển tiền nội bộ",
    derivativeTransfer: "Chuyển tiền phái sinh",
    depositToVSD: "Nộp ký quỹ lên VSD",
    withdrawFromVSD: "Rút ký quỹ từ VSD",
    D_CP: "Nộp ký quỹ",
    W_CP: "Rút ký quỹ",
    BIDVTransfer: "Chuyển tiền BIDV",
    ExternalTransfer: "Chuyển tiền ra ngoài",
    CS_TO_PS: "Chuyển tiền ra phái sinh",
    PS_TO_CS: "Chuyển tiền ra cơ sở",
    transferHistory: "Lịch sử chuyển tiền",
    createTransferRequest: "Tạo yêu cầu chuyển tiền",
    fromAccount: "Từ tài khoản",
    toAccount: "Đến tài khoản",
    accountHolder: "Chủ tài khoản",
    bank: "Ngân hàng",
    branch: "Chi nhánh",
    address: "Địa chỉ",
    transferMethod: "Phương thức chuyển",
    amountsTransferable: "Số tiền có thể chuyển",
    cashBalance: "Tiền mặt hiền tại",
    transferAmount: "Số tiền chuyển",
    transferFee: "Phí tạm tính",
    remark: "Nội dung",
    enterRemark: "Nhập nội dung sử dụng Tiếng Việt không dấu",
    enterAmount: "Nhập số tiền",
    fastTransfer: "Chuyển nhanh",
    normalTransfer: "Chuyển thường",
    payMarginFee: "Chuyển tiền thanh toán lãi Margin/BCC+",
    transferType: "Loại chuyển khoản",
    checkDetail: "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện",
    petitioner: "Người yêu cầu",
    idOrPassportNumber: "Số CMND/ Hộ chiếu",
    MBSAccountNumber: "Số tài khoản tại MBS",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    accountType: "Loại tài khoản",
    transferStatus: "Trạng thái",
    transferOverLimit: "Chuyển tiền vượt hạn mức",
    transferOverLimitDesc:
      "Số tiền chuyển vượt quá hạn mức giao dịch, cần MBS kiểm tra và phê duyệt. Bạn có chắc chắn muốn thực hiện?",
    moneyTransferSuccess: "Gửi yêu cầu thành công",
    transferSuccess:
      "Yêu cầu chuyển tiền của quý khách đã được gửi tới hệ thống, vui lòng kiểm tra lại trạng thái chuyển tiền",
    noRecord: "Không có bản ghi nào",
    choseBranch: "Chọn chi nhánh",
    escrow_d_cp: "Nop ky quy",
    escrow_w_cp: "Rut ky quy",
    first_escrow_desc:
      "Lưu ý: Thời gian thực hiện từ 8h30 đến 15h45 các ngày làm việc. Quý khách hàng lưu ý khi thực hiện nộp/rút ký quỹ để hạn chế phát sinh phí Quản lý tài sản ký quỹ. Chi tiết về biểu phí",
    is_here: "Tại đây",
    from_vsd: "Phí rút tiền từ",
    to_vsd: "Phí chuyển tiền lên",
    last_escrow_desc:
      "VSD (5.500) thu theo biểu phí của ngân hàng sẽ được trừ trên tài khoản của Quý khách tại MBS.",
    ac: "TK",
    accountNotSupport:
      "Tài khoản Ngân hàng nhận không thuộc danh sách liên kết,  giao dịch thực hiện theo hình thức chuyển thường.",
    transferNormalMethod:
      "Giao dịch được thực hiện theo hình thức chuyển thường",
    branchNote:
      "Quý khách chọn Chi nhánh trong danh sách liên kết để chuyển tự động, trường hợp Chi nhánh NH của Quý khách không có trong danh sách vui lòng chọn “Khác”, giao dịch sẽ được xử lý thủ công.",
    calculatingTransFee: "Hệ thống đang tính phí chuyển tiền...",
    error: {
      limitAmount: "Không được chuyển quá số dư",
      notEmptyContent: "Nội dung không được bỏ trống",
      notEmptyAmount: "Số tiền chuyển không được bỏ trống",
      notEmptyBranch: "Chi nhánh không được để trống",
      invalidAmount: "Số tiền chuyển không hợp lệ",
      invalidFeePaymentAccount:
        "Tài khoản nhận thanh toán phí Margin/BCC+ không hợp lệ",
      doNotTransferMoreThanCashMaxInOneDay:
        "Trong một ngày không được chuyển số tiền lớn hơn",
      doNotTransferLargerAmounts: "Không được chuyển số tiền lớn hơn",
      lessThanMin: "Không được chuyển số tiền nhỏ hơn",
      remarksIsAlphanumeric:
        "Nội dung không được chứa ký tự đặc biệt, tiếng Việt có dấu",
      errorDay: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
    },
    table: {
      transferDate: "Ngày GD",
      transferType: "Loại giao dịch",
      accountTransfer: "TK chuyển",
      accountReceive: "TK nhận",
      inputClientName: "Tên người nhận",
      transferAmount: "Số tiền",
      transferFee: "Phí GD",
      remark: "Nội dung",
      rejectReason: "Lý do từ chối",
      status: {
        status: "Trạng thái",
        notAccept: "Chưa duyệt",
        success: "Đã duyệt",
        reject: "Từ chối",
        pending: "Đang xử lý",
        DA_DUYET: "Đã duyệt",
        DANG_XU_LY: "Đang xử lý",
      },
    },
    button: {
      confirm: "Xác nhận",
      cancel: "Hủy",
      search: "Tìm kiếm",
    },
  },
  orderBook: {
    confirm: "Xác nhận",
    contentConfirm: "Bạn có chắc chắn muốn hủy lệnh đã chọn",
    title: "Sổ lệnh trong ngày",
    search: "Tìm kiếm",
    select: "Chọn",
    account: "Tài khoản",
    cancel: "Hủy lệnh đã chọn",
    all: "Tất cả",
    buy: "Mua",
    sell: "Bán",
    condition: "Loại lệnh",
    conditionType: {
      title: "Lệnh điều kiện",
      all: "Tất cả",
      timeConditionOrder: "Trước ngày",
      priorityOrder: "Tranh mua/bán",
      trailingStopOrder: "Xu hướng",
      takeProfit: "Chốt lãi",
      cutLoss: "Cắt lỗ",
    },
    normal: "Loại thường",
    errorCheckCancel: "Không có lệnh nào được chọn",
    symbol: "Mã CK",
    table: {
      ticker: "Mã CK",
      account: "Tài khoản",
      type: "Loại GD",
      status: "Trạng thái",
      volume: "Khối lượng",
      price: "Giá",
      matchVolume: "KL Khớp",
      matchPrice: "Giá Khớp",
      pubQty: "KL chờ",
      pubPrice: "Giá chờ",
      commandNumber: "Số hiệu lệnh",
      timeBook: "TG Đặt",
      timeMatch: "TG Khớp",
      timeCancel: "TG Hủy",
      channel: "Kênh",
      tooltipTable: "Click đúp để xem chi tiết",
    },
    orderPending: "Lệnh trước giờ",
    tablePending: {
      secCd: "Mã CK",
      subAccoNo: "Tài khoản",
      tradeType: "Loại",
      status: {
        title: "Trạng thái",
        waitApprove: "Chờ duyệt",
        approved: "Đã duyệt",
        cancelled: "Đã hủy",
        ctckCancel: "CTCK hủy",
        noValue: "---",
      },
      ordQty: "KL đặt",
      ordPrice: "Giá đặt",
      regDateTime: "TG đặt",
      channel: "Kênh",
    },
    tableCondition: {
      tradeDate: "Trade Date",
      reqTime: "Thời gian đặt",
      channel: "Kênh",
      side: "Loại lệnh",
      traderId: "Trader Id",
      masterAcc: "Master Account",
      accoNo: "Tài khoản",
      brokerId: "Broker Id",
      secCd: "Mã CK",
      secType: "Sec Type",
      secSubType: "Sec Sub Type",
      tradingSessionId: "Trading Session Id",
      ordQty: "KL đặt",
      ordPrice: "Giá Đặt",
      matQty: "KL khớp",
      cancelQty: "KL hủy",
      condType: "Lệnh ĐK",
      effectDate: "TG hiệu lực",
      expiredDate: "Đến ngày",
      matMethod: "Phương thức",
      optType: "Điều kiện",
      optVal: "Giá TC",
      optValType: "Loại TC",
      condition: "Loại điều kiện",
      value: "Giá trị",
      valueActive: "Giá kích hoạt",
      tooltipValueActive:
        "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
      limitOffset: "Biên trượt",
      notes: "Notes",
      clientInfo: "Client Info",
      status: "Trạng thái",
      updDateType: "Thời gian hủy",
      takeEffect: "Hiệu lực",
      complete: "Hoàn thành",
      expired: "Hết hạn",
      cancel: "Đã hủy",
      generatedOneTime: "Phát sinh 1 lần",
      matchingEnoughVolume: "Khớp đủ khối lượng",
      noValue: "---",
      timeConditionOrder: "Trước ngày",
      trailingStopOrder: "Xu hướng",
      priorityOrder: "Tranh Mua/Bán",
      takeProfit: "Chốt lãi",
      cutLoss: "Cắt lỗ",
      noData: "Không có dữ liệu",
      arbitrage: "Chênh lệch",
      optValByValue: "Giá trị",
      optValByPercent: "%",
      regDateTime: "TG đặt",
      pubQty: "KL chờ",
      pubPrice: "Giá chờ",
      minBuyPrice: "Giá mua thấp nhất",
      maxSellPrice: "Giá bán cao nhất",
    },
    // Utils status - start
    REJECTED: "Từ chối",
    ENTERED: "Chờ gửi",
    WAIT_FILLED: "Chờ khớp",
    PARTIALLY_FILLED: "Khớp một phần",
    FILLED: "Khớp toàn bộ",
    PARTIALLY_CANCELLED: "Hủy một phần",
    CANCELLED: "Hủy toàn bộ",
    REJECTED_DEPARTMENT: "Sở từ chối",
    WAIT_ACTIVE: "Chờ kích hoạt",
    EFFECT: "Hiệu lực",
    FINISHED: "Đã kết thúc",
    EXPIRE: "Hết hiệu lực",
    CANCELED: "Đã hủy",
    // Utils status - end
    BUY: "Mua",
    BUY_OUTR: "Mua OutR",
    SELL: "Bán",
    SELL_OutR: "Bán OutR",
    SELL_TT68: "Bán TT68",
    SELL_FORCE: "Bán Force",
    SELL_FORCE_OutR: "Bán Force OutR",
    SELL_FORCE_TT68: "Bán Force TT68",
    orderSuccess: "Đặt Lệnh Thành Công",
    orderError: "Có lỗi xảy ra. Vui lòng kiểm tra lại",
    editCommand: "Sửa Lệnh",
    undefined: "",
  },
  orderBasket: {
    filter: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      symbol: "Mã chứng khoán",
    },
    table: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        buy: "Mua",
        sell: "Bán",
      },
      symbol: "Mã CK",
      commandType: {
        title: "Loại CK",
        normal: "Thường",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Khối lượng",
      price: "Giá",
    },
    titleButtonBuy: "GIỎ LỆNH (MUA)",
    titleButtonSell: "GIỎ LỆNH (BÁN)",
    cancel: "Hủy",
    placeOrder: "Đặt lệnh",
    orderCreateSuccess: "Thêm lệnh vào giỏ lệnh thành công",
    orderDeleteError: "Xóa lệnh khỏi giỏ lệnh thất bại",
    confirmDelete: "Xác nhận hủy lệnh",
    contentConfirmDelete: "Bạn chắc chắn muốn hủy lệnh",
    confirmActive: "Xác nhận đặt lệnh",
    contentConfirmActive: "Bạn chắc chắn muốn đặt lệnh",
    cancelSuccess: "Hủy lệnh trong giỏ thành công",
    cancelFail: "Hủy lệnh trong giỏ thất bại",
    activeSuccess: "Đặt lệnh trong giỏ thành công",
    activeFailed: "Đặt lệnh trong giỏ thất bại",
    createSuccess: "Tạo lệnh vào giỏ thành công",
    createFailed: "Tạo lệnh vào giỏ thất bại",
    errorCheckCancel: "Không có dòng nào được chọn để hủy",
    errorCheckActive: "Không có dòng nào được chọn để đặt lệnh",
    orderError: "Thêm vào giỏ lệnh thất bại",
  },
  orderBasketDer: {
    filter: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        all: "Tất cả",
        buy: "Long",
        sell: "Short",
      },
      symbol: "Mã CK",
    },
    table: {
      account: "Tài khoản",
      tradeType: {
        title: "Loại GD",
        buy: "Mua",
        sell: "Bán",
      },
      symbol: "Mã CK",
      commandType: {
        title: "Loại CK",
        normal: "Thường",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Khối lượng",
      price: "Giá",
    },
    titleButtonLong: "GIỎ LỆNH (LONG)",
    titleButtonShort: "GIỎ LỆNH (SHORT)",
    cancel: "Hủy",
    placeOrder: "Đặt lệnh",
    orderError: "Thêm vào giỏ lệnh thất bại",
  },
  error: errorsVi,
  validation: {
    emptyAccount: "Vui lòng chọn tài khoản",
    emptyTicker: "Vui lòng nhập Mã CK",
    emptyTradeType: "Vui lòng chọn loại giao dịch",
    emptyPrice: "Vui lòng nhập giá",
    emptyStopPrice: "Vui lòng nhập giá kích hoạt",
    emptyStopLoss: "Vui lòng nhập giá cắt lỗ",
    emptyProfit: "Vui lòng nhập giá chốt lãi",
    restrictedTrading: "Mã bị tạm ngưng/hạn chế giao dịch",
    rangePrice: "Giá phải nằm trong khoảng từ Sàn đến Trần",
    patternPrice: "Giá đặt không hợp lệ (bước giá 0.1)",
    patternStopPrice: "Giá kích hoạt không hợp lệ",
    patternStopLoss: "Giá cắt lỗ không hợp lệ",
    patternProfit: "Giá chốt lãi không hợp lệ",
    patternMargin: "Giá biên trượt không hợp lệ",
    emptyVolume: "Vui lòng nhập Khối lượng",
    emptyOtpVal: "Vui lòng nhập giá trị",
    emptyLimitOffset: "Vui lòng nhập biên trượt",
    patternVolume: "Khối lượng không tròn lô (lô 100)",
    maxVolumeSell: "Khối lượng bán vượt khối lượng tối đa có thể bán",
    maxVolumeBuy: "Khối lượng mua vượt khối lượng tối đa có thể mua",
    maxVolumeFAL: "Khối lượng mua vượt quá FAL tối đa",
    overRoom: "Số lượng mua vượt quá Room còn lại",
    patternMaxLongVolume: "KL không được vượt quá KL Max Long",
    patternMaxShortVolume: "KL không được vượt quá KL Max Short",
    activePriceUp: "Giá kích hoạt phải > giá thị trường",
    activePriceDown: "Giá kích hoạt phải < giá thị trường",
    emptyActivePrice: "Vui lòng nhập giá kích hoạt",
    emptyCutLostAmp: "Vui lòng nhập biên độ cắt lỗ",
    emptyGrossAmp: "Vui lòng nhập biên độ chốt lãi",
    emptyCutLostPrice: "Vui lòng nhập giá cắt lỗ",
    emptyRangeStop: "Vui lòng nhập giá khoảng dừng",
    emptyMargin: "Vui lòng nhập biên trượt",
    emptyMinBuyPrice: "Vui lòng nhập giá mua thấp nhất",
    emptyMaxSellPrice: "Vui lòng nhập giá bán cao nhất",
    takeProfilePrice_Error_001: "Giá chốt lãi phải lớn hơn giá đặt",
    takeProfilePrice_Error_002: "Giá chốt lãi không được lớn hơn giá Trần",
    takeProfilePrice_Error_003: "Giá chốt lãi phải nhỏ hơn giá đặt",
    takeProfilePrice_Error_004: "Giá chốt lãi phải >= giá sàn",
    stopLotPrice_Error_001: "Giá cắt lỗ phải nhỏ hơn giá đặt",
    stopLotPrice_Error_002: "Giá cắt lỗ không được nhỏ hơn giá Sàn",
    stopLotPrice_Error_003:
      "Giá cắt lỗ Trừ biên trượt không được nhỏ hơn giá Sàn",
    stopLotPrice_Error_004: "Giá cắt lỗ phải lớn hơn giá đặt",
    stopLotPrice_Error_005: "Giá cắt lỗ không được lớn hơn giá Trần",
    stopLotPrice_Error_006:
      "Giá cắt lỗ + biên trượt không được lớn hơn giá Trần",
    stopLotPrice_Error_007: "Giá cắt lỗ phải khác giá đặt",
    stopLotPrice_Error_008: "Giá cắt lỗ phải LỚN hơn giá thị trường",
    stopLotPrice_Error_009:
      "Giá cắt lỗ CỘNG biên trượt phải nhỏ hơn hoặc bằng giá TRẦN",
    stopLotPrice_Error_010: "Giá cắt lỗ phải NHỎ hơn giá thị trường",
    stopLotPrice_Error_011:
      "Giá cắt lỗ TRỪ biên trượt phải nhỏ hơn hoặc bằng giá SÀN",
  },
  formPlaceholder: {
    inputPrice: "Nhập giá",
    inputVolumne: "Nhập KL",
    inputValue: "Nhập giá trị",
    inputAvgPrice: "Nhập giá BQ",
    inputOptValByValue: "Nhập giá trị",
    inputOptValByPercent: "Nhập %",
    inputLimitOffset: "Nhập biên trượt",
  },
  sidebar: {
    getOTP: "Lấy OTP",
    categories: "BẢNG GIÁ",
    marketUp: "THỊ TRƯỜNG",
    market: "Thị trường",
    stockOrder: "LỆNH CƠ SỞ",
    placeOrderDer: "LỆNH PHÁI SINH",
    asset: "TÀI SẢN",
    basicServices: "DỊCH VỤ CƠ BẢN",
    financialServices: "DV TÀI CHÍNH",
    settings: "Cài đặt",
    orderBook: "Sổ lệnh",
    orderHistory: "Lịch sử lệnh",
    moneyTransfer: "Chuyển tiền",
    advance: "Ứng trước",
    coveredWarrant: "Chứng quyền",
    proInvestor: "Nhà đầu tư chuyên nghiệp",
    marginLimit: "Thay đổi hạn mức Online",
    loanExtension: "Gia hạn khoản vay Online",
    screeners: "Bộ lọc",
    DVTC_NAV: "Báo cáo tài sản",
    infoWarrant: "Đăng ký quyền mua & Tra cứu quyền",
    disableMenu: "Ẩn/ Hiện menu",
    support: "Hỗ trợ/ Góp ý",
    businessCooperation: "Giao dịch eM24",
    extends: {
      title: "Mở rộng",
      profitLoss: "Lãi lỗ đã thực hiện",
      confirmOrderOnline: "Xác nhận lệnh Online",
      categoryFinancialService: "Danh mục DVTC",
      statement: "Sao kê tài khoản",
      oddLotShare: "Bán chứng khoán lô lẻ",
    },
    transferShare: "Chuyển khoản chứng khoán",
    contractDebit: "Công nợ margin",
    packServices: "Đăng ký chính sách DVTC",
    extendsOne: "Extends One",
    extendsTwo: "Extends Two",
    extendsThree: "Extends Three",
    investmentEfficiency: "Hiệu quả đầu tư",
    techAnalysis: "Phân tích kỹ thuật",
    expandMenu: "Mở rộng",
    collapseMenu: "Thu gọn",
    marginContract: "Thông tin hợp đồng Margin",
    accountInfo: "Thông tin tài khoản",
    marginSecurities: "Danh mục chứng khoán Margin",
    rightInfo: "Thông tin thực hiện quyền",
    statisticOnday: "Thống kê giao dịch",
    statisticPrice: "Thống kê CK trần/sàn",
    statisticUpdown: "Thống kê CK tăng/giảm",
    statisticHighestUpdown: "Thống kê CK tăng/giảm cao nhất",
    mrgContractDebit: "Theo dõi công nợ Margin",
    marginContractWatch: "Theo dõi tài sản Margin",
    transferLocal: "Chuyển khoản tiền",
    profitLoss: "Lãi lỗ thực hiện",
    holdUnholdBankAccount: "Phong tỏa/giải tỏa ngân hàng",
    confirmation: "Xác nhận lệnh Online",
    createContract: "Hợp đồng khung",
    depositContract: "Đề nghị cho vay",
    transferBond: "Xác nhận chuyển nhượng trái phiếu",
    accountStatementSideBar: "Sao kê tài khoản",
    marginSecuritiesPortfolio: "Danh mục chứng khoán ký quỹ",
    depositPower: "Liên kết sức mua",
    manegerMoney: "Quản lý tiền TKCK",
    pMoney: "Quản lý tiền TKCK tự động",
    profitChart: "Biểu lãi suất quản lý tiền",
    addMhPositionManagement: "Quản lý vị thế",
    subscribeBuy: "Đăng ký mua phát hành thêm",
    depositListPMoney: "Danh sách gửi tiền",
    addFrameworkContractFormSideBar: "Hợp đồng Khung QLT TKCK tự động",
    profitMoney: "Biểu lãi quản lý tiền TKCK tự động",
    subAccoRegister: "Đăng ký tiểu khoản gửi tiền TKCK tự động",
    transferHistory: "Lịch sử chuyển nhượng TPDN",
    bidAskBond: "Sổ lệnh chào mua/bán trái phiếu",
    assetsIncurred: "Báo cáo tài sản",
    bidAskBondOrder: "Chào mua/Chào bán Trái phiếu",
    bonds: "Trái phiếu",
    placeOrderToTransferBonds: "Đặt lệnh chuyển nhượng trái phiếu",
    registerToTransferBonds: "Đăng ký chuyển nhượng trái phiếu",
    addMhChangeLimit: "Thay đổi hạn mức",
    professionalInvestor: "Nhà đầu tư chuyên nghiệp",
    placeOrderRetailBonds: "Đặt lệnh trái phiếu bán lẻ",
    bidAskDBondOrder: "Đặt lệnh trái phiếu D-BOND",
    placeOrderBondAdv: "Đặt lệnh quảng cáo Trái phiếu",
    advertisingOrder: "Sổ lệnh quảng cáo",
    guideVideo: "Đào tạo và hướng dẫn sử dụng",
  },
  stockOrderBoard: {
    chooseABoard: "Chọn một bảng",
    all: "Tất cả",
    default: "Mặc định",
    customized: "Tùy chỉnh",
    newBoard: "Bảng mới",
    descNewBoard: "Thêm các khối vào bảng trống.",
    standard: "Tiêu chuẩn",
    descStandard: "Dành cho nhà giao dịch cơ bản và mới.",
    standard2: "Tiêu chuẩn 2",
    widgetsSettings: "Cài đặt các khối",
    suggestionsWidgetsSettings: "Chọn và kéo thả vào bảng",
    addDisplayArea: "Thêm vùng hiển thị",
    hoverOverTheAreaToAdd: "Di chuột đến khu vực cần thêm",
    viewDoesNotExist: "Chế độ xem không tồn tại",
    tab: {
      title: "Màn",
      open: "Mở",
      rename: "Đổi tên",
      saveTheTemplate: "Lưu lại mẫu",
    },
    modalSaveBoard: {
      titleModal: "Lưu lại bảng",
      nameBoard: "Tên bảng",
      description: "Mô tả",
      cancel: "Hủy",
      save: "Lưu",
    },
    modalRenameBoard: {
      titleModal: "Đổi tên bảng",
      ok: "OK",
      cancel: "Hủy",
    },
  },
  stockOrderDerBoard: {
    all: "Tất cả",
    default: "Mặc định",
    customized: "Tùy chỉnh",
    addingWidggets: "Thêm tiện ích vào bảng trống",
    blank: "Bảng trống",
    DefaultStock: "Thêm tiện ích vào bảng trống",
    normal: "Bình thường",
    multiTrades: "Nhiều giao dịch",
    MultiChart: "Nhiều biểu đồ hoặc bảng giao dịch",
    addDisplaytitle: "Thêm vùng hiển thị<br>(Di chuột đến khu vực cần thêm)",
    saveBoard: "Lưu lại 1 bảng",
    boardName: "Tên bảng",
    description: "Mô tả",
    changeBoardName: "Đổi tên bảng",
    chooseBoard: "Chọn một bảng",
    open: "Mở",
    changeName: "Đổi tên",
    save: "Lưu lại mẫu",
    noView: "Chế độ xem không tồn tại",
  },
  advance: {
    filter: {
      account: "Tài khoản",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      subAccount: "Tài khoản",
      search: "Tìm kiếm",
      advancePurpose: "Mục đích ứng",
      purpose: {
        buy: "Ứng để mua",
        withdraw: "Ứng để rút",
      },
    },
    subAccount: "Chọn tài khoản ứng",

    advanceHistory: "Lịch sử ứng tiền",
    advanceMoney: "Ứng trước tiền bán",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    advancePurpose: "Mục đích ứng",
    account: "Tài khoản",
    overDate: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
    lengthDate: "Vượt quá giới hạn tìm kiếm 30 ngày",
    success: "Ứng trước tiền bán thành công",
    button: {
      confirm: "Xác nhận",
      cancel: "Hủy",
      search: "Tìm kiếm",
      add: "Thêm tài khoản",
    },
    table: {
      index: "STT",
      contractID: "SỐ HĐ",
      account: "TÀI KHOẢN",
      accountHolder: "CHỦ TÀI KHOẢN",
      soldDate: "NGÀY BÁN",
      paymentDate: "NGÀY THANH TOÁN",
      advanceDate: "NGÀY ỨNG",
      amount: "SỐ TIỀN",
      advanceFree: "PHÍ ỨNG",
      advanceSource: "NGUỒN ỨNG",
      receiveAccount: "TK NHẬN TIỀN",
      cancel: "HỦY",
      content: "NỘI DUNG",
      status: {
        status: "TRẠNG THÁI",
        success: "Đã duyệt",
        reject: "Chờ duyệt",
        pending: "ĐANG XỬ LÝ",
        end: "Đã hủy",
        cancel: "Đã xóa",
        collected: "Đã thu tiền",
        debtCollect: "Cần thu nợ",
      },
    },
    message: {
      amountCheck1: "Vui lòng nhập số tiền ứng",
      amountCheck2: "Số tiền ứng phải lớn hơn 0",
      amountCheck3: "Số tiền ứng vượt quá số tiền có thể ứng",
      amountCheck4:
        "Số tiền ứng vượt quá hạn mức còn lại của công ty. Quý KH vui lòng liên hệ 024 39343 888 để được hướng dẫn",
      amountCheck5: "Phí ứng bằng 0",

      checkErr1: "Số tiền ứng tối thiểu là {number}",
      checkErr2: "Số tiền ứng tối đa là {number}",
      checkErr3:
        "Hạn mức ứng trước còn lại của khách hàng là {number}. Quý KH vui lòng liên hệ 024 39343 888 để được hướng dẫn",
    },
    confirmModal: {
      title: "Xác nhận ứng tiền",
      subAccountFrom: "Tài khoản ứng",
      subAccountTo: "Tài khoản nhận",
      withdrawable: "Số tiền ứng",
      interestRate: "Lãi suất",
      provisionalFee: "Phí tạm tính",
      labelCheckBox:
        "Không hiển thị lại vào lần sau. Bạn có thể bật lại chức năng này ở Cài đặt giao dịch",
      remarks: "Nội dung",

      accept: "Xác nhận",
      cancel: "Hủy",
    },
    advanceAccount: "Tài khoản",
    subAccoNoOut: "Chọn tài khoản ứng",
    note: "Ghi chú",
    totalSaleAmount: "Tổng tiền bán",
    advancedMoney: "Tiền đã ứng + phí",
    advanceableMoney: "Tiền bán có thể ứng",
    // withdrawable: "Tiền ứng (có thể rút)",
    withdrawable: "Số tiền ứng",
    interestRate: "Lãi suất",
    provisionalFee: "Phí tạm tính",
    enterAmount: "Nhập số tiền",
    addAccount: "+ Thêm tài khoản thụ hưởng",
    error: {
      noContent: "Vui lòng nhập số tiền ứng",
      overPriced: "Vượt quá số tiền có thể ứng",
    },
    accountReceiver: {
      header: "THÊM TÀI KHOẢN",
      id: "Số tài khoản",
      owner: "Chủ tài khoản",
      bank: "Ngân hàng",
      city: "Tỉnh/Thành phố",
      branch: "Chi nhánh",
      register: "Đăng ký ngay tại đây.",
      description: "Mô tả",
      input: {
        id: "Nhập số tài khoản",
        owner: "Nhập tên chủ tài khoản",
        bank: "Chọn ngân hàng",
        city: "Chọn tỉnh/thành phố",
        branch: "Chọn chi nhánh",
      },
    },
  },
  depositPower: {
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        available: "Đang hiệu lực",
        liquidated: "Đã thanh lý",
      },
    },
    title: {
      depositPowerRegister: "Đăng ký liên kết sức mua",
      depositPowerList: "Danh sách liên kết sức mua",
    },

    table: {
      openDate: "Ngày mở HĐ",
      contrNo: "Số HĐ",

      expiredDate: "Ngày kết thúc",
      status: "Trạng thái",
      remarks: "Ghi chú",

      regDateTime: "Thời gian tạo",
      liquidation: "Thanh lý",
    },
    create: {
      account: "Tài khoản",
      accountName: "Tên tài khoản",

      remarks: "Ghi chú",

      placeholderRemarks: "Nhập nội dung",
      mess1:
        "Tôi đã đọc và đồng ý với Điều kiện và điều khoản đã nêu trong {link}",
      mess2: "Phụ lục liên kết sức mua",
      confirm: "Xác nhận",
    },
    status: {
      waitApprove: "Chờ duyệt",
      available: "Đang hiệu lực",
      cancelApprovedWait: "Đóng chờ duyệt",
      liquidated: "Đã thanh lý",
    },
    message: {
      liquidateContent: "Bạn có chắc chắn muốn thanh lý Liên kết sức mua ?",
      depositPowerRegisterContent: "Đăng ký liên kết sức mua thành công",
    },
    confirmModal: {
      accept: "Xác nhận",
      cancel: "Hủy",
    },
  },

  depositContract: {
    filter: {
      subAccount: "Tiểu khoản",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        available: "Đang hiệu lực",
        turnOver: "Đã quay vòng",
        cancel: "Đã thanh lý",
      },
    },
    title: {
      depositContract: "Đề nghị cho vay",
      depositContractList: "Quản lý đề nghị cho vay",
    },

    table: {
      loanDate: "Ngày vay",
      subAccount: "Tiểu khoản",
      contrNo: "Số hợp đồng",
      amount: "Số tiền cho vay",
      balance: "Số tiền còn lại",
      period: "Kỳ hạn",
      interest: "TL lãi thực nhận (%)",

      status: "Trạng thái",
      periodType: {
        day: "Ngày",
        month: "Tuần",
        year: "Tháng",
      },
      calculateType: {
        day: "Ngày",
        month: "Tháng (30 ngày)",
        year1: "Năm (360 ngày)",
        year2: "Năm (365 ngày)",
        year3: "Năm (365/360 ngày)",
      },
      liquidation: "Thanh lý",
      detail: "Chi tiết",
    },
    create: {
      subAccount: "Tiểu khoản",
      amount: "Số tiền cho vay",
      avaiable: "Khả dụng",
      availableBalance: "Khả dụng",
      periodType: "Loại kỳ hạn",
      period: "Kỳ hạn",
      loanDate: "Ngày vay",
      dueDate: "Ngày đáo hạn",
      loanInterest: "Lãi suất vay (%)",
      interest: "Lãi thực nhận (%)",
      dayCountConvention: "Cơ sở tính lãi",
      loanEstimate: "Tiền lãi dự tính",
      turnOverType: "Hình thức quay vòng",
      remarks: "Nội dung",
      placeholderRemarks: "Nhập nội dung",
      confirm: "Thực hiện",
      periodTypeOption: {
        date: "Ngày",
        month: "Tuần",
        year: "Tháng",
      },
      periodTypeLabel: "Chọn loại kỳ hạn",
      periodLabel: "Chọn kỳ hạn",
      turnOverTypeOption: {
        manual: "Không tự động quay vòng",
        auto: "Tự động quay vòng gốc",
      },
      turnOverTypeLabel: "Chọn hình thức quay vòng",
    },
    status: {
      all: "Tất cả",
      available: "Đang hiệu lực",
      turnOver: "Đã quay vòng",
      cancel: "Đã thanh lý",
    },
    message: {
      confirmContent:
        "Bạn có chắc chắn muốn thực hiện thanh lý đề nghị {contractNo} ?",
      confirmContent2: "Bạn có chắc chắn muốn thực hiện tạo đề nghị cho vay ?",
      periodCheck: "Chưa chọn kỳ hạn",
      periodTypeCheck: "Chưa chọn loại kỳ hạn",
    },
    liquidateModal: {
      paymentDate: "Ngày thanh lý",
      amount: "Số tiền đã thanh lý",
      currentAmt: "Số tiền còn lại",
      grossRate: "Lãi suất thực nhận",
      payedInterest: "Tiền lãi đã trả",
      accept: "Thanh lý",
    },
    detailModal: {
      endDate: "Ngày gia hạn",
      payedInterestAmt: "Tiền lãi đã trả",
      amount: "Số tiền gia hạn",
    },
    confirmModal: {
      title: "Thanh lý đề nghị",
      subAccoNo: "Tiểu khoản",
      contractNo: "Số ĐNCV",
      amount: "Số tiền cho vay",
      balance: "Số tiền còn lại",
      fromDate: "Ngày cho vay",
      tradeDate: "Ngày thanh lý",
      countLoanDay: "Số ngày cho vay",
      amountLiquidateL: "Số tiền thanh lý",
      grossRate: "Lãi suất thanh lý trước hạn (%)",
      netRate: "Lãi suất thanh lý thực nhận (%)",
      interest: "Số tiền lãi thực nhận",
      accept: "Thực hiện",
      cancel: "Hủy",
    },
    modal: {
      label1: "Số tiền cho vay",
      label2: "Kỳ hạn",
      label3: "Hình thức gia hạn",
    },
  },

  createContract: {
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        available: "Đang hiệu lực",
        liquidated: "Đã thanh lý",
      },
    },
    title: {
      createContract: "Tạo hợp đồng khung",
      contractList: "Danh sách hợp đồng",
    },

    table: {
      openDate: "Ngày mở hợp đồng",
      contrNo: "Số hợp đồng",
      collab: "CTV giới thiệu",
      expiredDate: "Ngày kết thúc",
      status: "Trạng thái",
      remarks: "Ghi chú",
      transaction: "Điểm giao dịch",
      regDateTime: "Thời gian tạo",
      liquidation: "Thanh lý",
      edit: "Sửa",
    },
    create: {
      account: "Tài khoản",
      accountName: "Tên tài khoản",
      collab: "CTV giới thiệu",
      remarks: "Ghi chú",
      placeholderCollab: "Nhập CTV",
      placeholderRemarks: "Nhập nội dung",
      mess1:
        "Tôi đã đọc và đồng ý với Điều kiện và điều khoản đã nêu trong {link}",
      mess2: "Hợp đồng Quản lý tiền Tài khoản chứng khoán",
      confirm: "Xác nhận",
    },
    status: {
      waitApprove: "Chờ duyệt",
      available: "Đang hiệu lực",
      cancelApprovedWait: "Đóng chờ duyệt",
      liquidated: "Đã thanh lý",
    },
    message: {
      liquidateContent: "Bạn có chắc chắn thanh lý hợp đồng khung ?",
      createContractContent: "Tạo hợp đồng khung thành công",
    },
    confirmModal: {
      accept: "Xác nhận",
      cancel: "Hủy",
    },
  },
  bidAskBond: {
    filter: {
      tradeType: "Mua/Bán",
      bondCd: "Mã TP",

      search: "Tìm kiếm",
      tradeList: {
        all: "Tất cả",
        sell: "Bán",
        buy: "Mua",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Đã từ chối",
        cancel: "Hủy",
      },
    },
    all: "TẤT CẢ",
    all2: "Tất cả",
    title: {
      bidAskBond: "Sổ lệnh chào mua/bán trái phiếu",
    },

    table: {
      account: "Tài khoản",
      action: "Thao tác",
      tradeType: "Mua/bán",
      bondCd: "Mã tp",

      status: "Trạng thái",
      qty: "Khối lượng",
      amount: "Giá chào",
      expiratedDate: "Ngày hết hạn",
      referencePrices: "Giá tham chiếu",
      buy: "Mua",
      sell: "Bán",
      unknown: "Không xác định",
      sellMortage: "Bán cầm cố",
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      pending: "Đang thanh toán",
      complete: "Hoàn thành",
      rejected: "Đã từ chối",
      cancel: "Hủy",
    },
    message: {
      successAlert: "Xác nhận lệnh thành công",
      successAlert2: "Từ chối lệnh thành công",
      cancelContent: "Bạn có chắc chắn muốn thực hiện từ chối ?",
    },
    confirmModal: {
      title: "Xác nhận",
      tradeType: "Loại lệnh",
      tradeList: {
        sell: "Bán",
        buy: "Mua",
        unknown: "Không xác định",
        sellMortage: "Bán cầm cố",
      },

      bondCd: "Mã TP",
      qty: "Khối lượng",
      price: "Giá",
      orderPrice: "Giá trị lệnh",
      fee: "Phí",
      tax: "Thuế",
      feePayer: "Người trả phí",
      taxPayer: "Người trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },
      cancel: "Hủy",
      confirm: "Nhập lệnh",
    },
  },
  professionalInvestor: {
    title: "Nhà đầu tư chuyên nghiệp",
    statusList: {
      registered: "Đã đăng ký",
      unRegistered: "Chưa đăng ký",
    },
    userId: "Số TK KH",
    name: "Họ tên",
    status: "Trạng thái ĐK",
    expiredDate: "Ngày hết hạn",
    cancel: "Đóng",
  },
  bidAskDBondOrder: {
    filter: {
      tradeType: "Mua/Bán",
      bondCd: "Mã TP",

      search: "Tìm kiếm",
      tradeList: {
        all: "Tất cả",
        sell: "Bán",
        buy: "Mua",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Hủy",
      },
    },
    title: {
      orderBook: "Sổ lệnh",
      bondList: "Danh sách trái phiếu",
      orderData: "Thông tin lệnh",
    },

    table: {
      tradingDate: "Ngày GD",
      cancel: "Hủy",

      bondCd: "Mã TP",
      tradeType: "Mua/bán",
      traderList: {
        unknown: "Không xác định",
        sell: "Bán",
        buy: "Mua",
        sellMortage: "Bán cầm cố",
      },

      qty: "Khối lượng",
      amount: "Giá",
      investValue: "Giá trị đầu tư",
      fee: "Phí",
      feePayer: "Đối tượng trả phí",
      tax: "Thuế",
      taxPayer: "Đối tượng trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },

      status: "Trạng thái",
      statusList: {
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Hủy",
      },

      maturityDate: "Ngày đáo hạn",

      coupon: "Coupon",

      buyPrice: "Giá mua",
      buyVol: "Hạn mức mua",
      sellPrice: "Giá bán",
      sellVol: "Hạn mức bán",
      action: "Thao tác",

      buy: "Mua",
      sell: "Bán",
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      pending: "Đang thanh toán",
      complete: "Hoàn thành",
      rejected: "Đã hủy",
    },
    message: {
      deleteContent: "Bạn có chắc chắn muốn hủy lệnh {number} không ?",
      deleteSuccess: "Hủy lệnh thành công",
      confirmContent: "Bạn có chắc chắn muốn đặt lệnh ?",
      success: "Đặt lênh thành công",

      checkErr3: "Chưa nhập mã trái phiếu",
      checkErr4: "Chưa nhập mã trái phiếu",

      checkErr7: "Chưa nhập khối lượng",
      checkErr8: "Khối lượng phải lớn hơn 0",

      checkErr10: "Số lượng bán không được vượt quá số lượng hiện có",
    },
    create: {
      account: "Tài khoản",
      buy: "MUA",
      sell: "BÁN",

      bondCd: "Mã TP",
      qty: "Khối lượng",
      amount: "Giá",

      maxPrice: "Tối đa",

      fee: "Phí",
      purchaseAbility: "Sức mua",
      tax: "Thuế",
      totalAmount: "Tổng tiền",
      issuerBond: "Tổ chức phát hành",
      releaseDate: "Ngày phát hành",
      expiratedDate2: "Ngày đáo hạn",
      price: "Mệnh giá",
      coupon: "Tỉ lệ coupon",
      period: "Kỳ trả lãi",
      description: "Mô tả",
      reset: "BỎ QUA",
      confirm: "Mua",
    },
    confirmModal: {
      title: "Xác nhận",

      cancel: "Hủy",
      confirm: "Nhập lệnh",
    },
  },
  bidAskBondOrder: {
    filter: {
      tradeType: "Mua/Bán",
      bondCd: "Mã TP",

      search: "Tìm kiếm",
      tradeList: {
        all: "Tất cả",
        sell: "Bán",
        buy: "Mua",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Hủy",
      },
    },
    title: {
      orderBook: "Sổ lệnh",
      bondList: "Danh sách trái phiếu",
      orderData: "Thông tin lệnh",
    },

    table: {
      tradingDate: "Ngày GD",
      cancel: "Hủy",
      account: "Tài khoản",
      contraAccount: "Tài khoản đối ứng",

      bondCd: "Mã TP",
      tradeType: "Mua/bán",
      traderList: {
        unknown: "Không xác định",
        sell: "Bán",
        buy: "Mua",
        sellMortage: "Bán cầm cố",
      },

      qty: "Khối lượng",
      amount: "Giá chào",
      orderPrice: "Giá trị lệnh",
      expiredDate: "Ngày hết hạn",
      fee: "Phí",
      feePayer: "Đối tượng trả phí",
      tax: "Thuế",
      taxPayer: "Đối tượng trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },

      status: "Trạng thái",
      statusList: {
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Hủy",
      },
      regUserId: "Người tạo",
      alloDate: "Ngày tạo",

      releaseDate: "Ngày phát hành",
      maturityDate: "Ngày đáo hạn",
      price: "Mệnh giá",
      coupon: "Coupon",

      referencePrices: "Giá tham chiếu",
      action: "Thao tác",

      buy: "Mua",
      sell: "Bán",
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      pending: "Đang thanh toán",
      complete: "Hoàn thành",
      rejected: "Đã hủy",
    },
    message: {
      deleteContent: "Bạn có chắc chắn muốn hủy lệnh {number} không ?",
      deleteSuccess: "Hủy lệnh thành công",
      confirmContent: "Bạn có chắc chắn muốn đặt lệnh ?",
      success: "Đặt lênh thành công",
      maxLengthCheck: "Độ dài tài khoản đối ứng là 10 ký tự",
      checkErr1: "Chưa nhập tài khoản đối ứng",
      checkErr2: "Chưa nhập tài khoản đối ứng",
      checkErr3: "Chưa nhập mã trái phiếu",
      checkErr4: "Chưa nhập mã trái phiếu",
      checkErr5: "Chưa nhập giá chào",
      checkErr6: "Giá chào phải lớn hơn 0",
      checkErr7: "Chưa nhập khối lượng",
      checkErr8: "Khối lượng phải lớn hơn 0",
      checkErr9: "Chưa nhập ngày hết hạn",
      checkErr10: "Số lượng bán không được vượt quá số lượng hiện có",
      checkErr11: "Tài khoản đối ứng trùng tài khoản Mua/Bán",
    },
    create: {
      account: "Tài khoản",
      buy: "MUA",
      sell: "BÁN",
      contraAccount: "Tài khoản đối ứng",
      contraAccountName: "Tên tài khoản đối ứng",
      bondCd: "Mã TP",
      qty: "Khối lượng",
      amount: "Giá chào",
      feePayer: "Người trả phí",
      taxPayer: "Người trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },
      expiratedDate: "Ngày hết hạn",

      maxPrice: "Tối đa",
      referencePrice: "Giá TC",
      orderPrice: "Giá trị lệnh",

      fee: "Phí",
      purchaseAbility: "Sức mua",
      tax: "Thuế",
      issuerBond: "Tổ chức phát hành",
      releaseDate: "Ngày phát hành",
      expiratedDate2: "Ngày đáo hạn",
      price: "Mệnh giá",
      coupon: "Tỉ lệ coupon",
      period: "Kỳ trả lãi",
      description: "Mô tả",
      reset: "NHẬP LẠI",
      confirm: "Mua",
    },
    confirmModal: {
      title: "Xác nhận",

      cancel: "Hủy",
      confirm: "Nhập lệnh",
    },
  },
  transferBond: {
    filter: {
      fromDate: "Ngày tạo",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        rejected: "Từ chối",
        deleted: "Đã hủy",
      },
    },
    title: {
      transferBond: "Xác nhận chuyển nhượng trái phiếu",
    },
    table: {
      confirmReject: "Xác nhận/Từ chối",
      createDate: "Ngày tạo",
      status: "Trạng thái",
      accountTransfer: "TK chuyển",
      accountName: "Tên tk chuyển",
      bondCode: "Mã tp",
      qty: "Khối lượng",
      amount: "Giá",
      amountByPrice: "Giá trị theo mệnh giá",
      transferAmount: "Giá trị chuyển nhượng",
      fee: "Phí chuyển nhượng",
      feePay: "Đối tượng trả phí",
      taxValue: "Thuế TNCN",
      taxPay: "Đối tượng trả thuế",
      remarks: "Ghi chú",
      confirm: "Xác nhận",
      cancel: "Từ chối",
      payer: {
        receive: "Bên nhận",
        transfer: "Bên chuyển",
      },
      payer2: {
        receive: "Người nhận",
        transfer: "Người chuyển",
      },
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      rejected: "Từ chối",
      deleted: "Đã hủy",
    },
    message: {
      cancelContent:
        "Bạn có chắc muốn từ chối yêu cầu chuyển nhượng TP {number} số lượng {number2} không ?",
    },
    confirmModal: {
      title: "Chuyển nhượng trái phiếu",
      fromSubAccoNo: "Bên chuyển",
      fromCustName: "Tên TK chuyển",
      toSubAccoNo: "Bên nhận",
      toCustName: "Tên TK nhận",
      bondCode: "Mã TP",
      qty: "Khối lượng",
      amount: "Giá",
      amountByPrice: "GT theo mệnh giá",
      transferAmount: "GT chuyển nhượng",
      fee: "Phí",
      feePay: "Người trả phí",
      taxValue: "Thuế",
      taxPay: "Người trả thuế",
      cancel: "Hủy",
      confirm: "Xác nhận",
    },
  },

  tableInfinityScroll: {
    tableContent: {
      loadMore: "Tải thêm",
      loadEnd: "Không có dữ liệu mới",
      nothing: "Không có bản ghi nào",
    },
  },
  orderBookDer: {
    secCd: "Mã CK",
    subAccoNo: "Tài khoản",
    tradeType: "Loại GD",
    ordType: "Loại lệnh",
    extStatus: "Trạng thái",
    orderQty: "Khối lượng",
    price: "Giá",
    matQty: "KL khớp",
    avgPrice: "Giá khớp",
    pubQty: "KL chờ",
    pubPrice: "Giá chờ",
    orderNo: "Số hiệu lệnh",
    activePrice: "Giá kích hoạt",
    cutLostAmp: "Biên độ cắt lỗ",
    limitOffset: "Chênh lệch",
    grossAmp: "Biên độ chốt lãi",
    cutLostPrice: "Giá cắt lỗ",
    rangeStop: "Khoảng dừng",
    createdTime: "TG đặt",
    matchTime: "TG khớp",
    cancelTime: "TG hủy",
    channel: "Kênh",
    Normal: "Thường",
    UP: "UP",
    DOWN: "Down",
    TUP: "T Up",
    TDOWN: "T Down",
    BULLBEAR: "Bull&Bear",
    OCO: "OCO",
    inputHD: "Nhập mã HD",
    search: "Tìm kiếm",
    comfirmTitle: "Xác Nhận Lệnh Hủy",
    comfirmMessage: "Bạn có chắc chắn muốn hủy lệnh này",
    accept: "Đồng ý",
    cancel: "Hủy bỏ",
    Updated: "Đã Sửa",
    PedingUpdate: "Chờ sửa",
    Waiting: "Đang chờ",
    WaitingCancel: "Chờ hủy",
    RejectUpdate: "Từ chối sửa",
    RejectCancel: "Từ chối hủy",
    noValue: "---",
    cancelSucces: "Gửi lệnh hủy thành công",
    cancelFail: "Không có lệnh hợp lệ để hủy",
    noAccoDer: "Không có tài khoản phái sinh",
    condType: {
      title: "Loại điều kiện",
      orderUp: "UP",
      orderDown: "DOWN",
      ifDoneStop: "IF DONE STOP",
      ifDoneGain: "IF DONE GAIN",
      orderOco: "BULL & BEAR",
      orderBullBear: "OCO",
      orderTS: "TRAILING STOP",
    },
    orderStatusDerCond: {
      waitActive: "Chờ kích hoạt",
      finished: "Đã kết thúc",
      expire: "Hết hiệu lực",
      cancelled: "Đã hủy",
    },
  },
  OrderBookDerDetail: {
    title: "Chi tiết lệnh",
    tradeNumber: "Số hiệu lệnh",
    account: "Tài khoản",
    status: "Trạng thái",
    tradeType: "Loại lệnh",
    pubQty: "KL chờ",
    pubPrice: "Giá chờ",
    orderTime: "Thời gian đặt",
    activeTime: "Thời gian kích hoạt",
    orderVolumn: "Khối lượng đặt",
    orderPrice: "Giá đặt",
    stopLoss: "Giá điều kiện",
    stopPrice: "Giá cắt lỗ",
    margin: "Biên trượt",
    activePrice: "Giá kích hoạt",
    limitOffset: "Biên trượt",
    grossAmp: "Biên độ chốt lãi",
    cutLostAmp: "Biên độ cắt lỗ",
    cutLostPrice: "Giá cắt lỗ",
    rangeStop: "Khoảng dừng",
    adjustableStopLossPrice: "Giá cắt lỗ điều chỉnh",
    matchingVolume: "Khối lượng khớp",
    stopPrice2: "Giá chốt lãi",
    matchingValue: "Giá trị khớp",
    cancelTime: "Thời gian hủy",
    cancelVolume: "Khối lượng hủy",
    chanel: "Kênh đặt lệnh",
    cancelSuccessMessage: "Hủy lệnh thành công",
  },
  orderHistory: {
    tabs: {
      orderHistoryTab: "Lịch sử lệnh cơ sở",
      orderHistoryDerTab: "Lịch sử lệnh phái sinh",
    },
    orderHistoryDerTab: {
      filter: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        orderType: "Loại lệnh",
        trade: "Loại giao dịch",
        status: "Trạng thái",
        condType: "Loại điều kiện",

        ticker: "Mã chứng khoán",
        placeTicker: "Nhập mã CK",
        search: "Tìm kiếm",
        type: {
          normalOrder: "Lệnh thường",
          conditionalOrder: "Lệnh điều kiện",
        },
        condList: {
          all: "Tất cả",
          SO: "Lệnh dừng",
          UO: "Lệnh UP",
          DO: "Lệnh DOWN",
          CL: "IF DONE STOP",
          SP: "IF DONE GAIN",

          OC: "Lệnh OCO",
          BB: "Lệnh Bull & Bear",
          TS: "Lệnh xu hướng",
        },
        statusList: {
          all: "Tất cả",

          rejected: "Từ chối",
          entered: "Đã nhập vào hệ thống",
          waitApprove: "Chờ khớp",
          partialMatch: "Khớp một phần",
          fullMatch: "Khớp toàn bộ",
          partialCancel: "Hủy một phần",
          cancel: "Hủy toàn bộ",
          rejectedGkck: "Từ chối bởi sở GDCK",
        },
        statusCondList: {
          all: "Tất cả",

          waitActive: "Chờ kích hoạt",
          end: "Đã kết thúc",
          expire: "Hết hiệu lực",
          cancel: "Đã hủy",
        },
        tradeList: {
          all: "Tất cả",
          short: "SHORT",
          long: "LONG",
        },
      },
      table: {
        tradingDate: "Ngày giao dịch",
        account: "Tài khoản",
        orderShare: "Mã CK",
        orderSide: "Loại giao dịch",
        orderVolume: "Khối lượng đặt",
        orderPrice: "Giá đặt",
        orderVolumeFilled: "Khối lượng khớp",
        orderPriceFilled: "Giá khớp",
        orderStatus: "Trạng thái",
        orderNo: "Số hiệu lệnh",
        channel: "Kênh",
        orderType: "Loại lệnh",
        activePrice: "Giá kích hoạt",
        takeProfit: "Biên độ chốt lãi",
        stopLoss: "Biên độ cắt lỗ",
        stopLossAmount: "Giá cắt lỗ",
        stopInterval: "Khoảng dừng",
        arbitrage: "Chênh lệch",
        updDateTime: "Thời gian cập nhật",
        availableDateTime: "Thời gian hiệu lực",
        matMethod: "Phương thức khớp",
        orderVolume2: "KL",
        orderPrice2: "Giá",
        orderVolumeFilled2: "KL khớp",
        orderPriceFilled2: "Giá khớp",
        long: "LONG+",
        short: "SHORT+",
      },
    },
    condType: {
      "": "Tất cả",
      TCO: "Lệnh trước ngày",
      TS: "Lệnh xu hướng",
      SP: "Lệnh chốt lãi",
      CL: "Lệnh cắt lỗ",
      PRO: "Lệnh tranh mua/bán",
    },

    orderHistoryCS: "Lịch sử lệnh cơ sở",
    orderHistoryFS: "Lịch sử lệnh phái sinh",
    orderHistory: "Lịch sử lệnh",
    orderDetail: "Chi tiết lệnh",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    account: "Tài khoản",
    subAccount: "Tài khoản",
    side: "Loại giao dịch",
    orderType: "Loại lệnh",
    conditionOrder: "Loại điều kiện",
    status: "Trạng thái",
    tooltip: "Click đúp để xem chi tiết",
    priceConditionNote:
      "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
    ordChannel: {
      Back: "Back",
      Web: "Web",
      Front: "Front",
      Webmobile: "Web mobile",
      Mobile: "Mobile",
      Home: "Home",
      Floor: "Floor",
      SMS: "SMS",
      Tel: "Tel",
    },
    ordChannelCond: {
      Back: "BACK",
      Web: "WEB",
      Front: "FRONT",
      Webmobile: "WEB MOBILE",
      Mobile: "MOBILE",
      Home: "HOME",
      Floor: "FLOOR",
      SMS: "SMS",
      Tel: "TEL",
    },

    matMethod: {
      matMethod1: "Phát sinh 1 lần",
      matMethod2: "Khớp đủ khối lượng đặt",
    },
    extStatus: {
      rejected: "Từ chối",
      entered: "Chờ gửi",
      waitApprove: "Chờ khớp",
      partialMatch: "Khớp một phần",
      fullMatch: "Khớp toàn bộ",
      partialCancel: "Hủy một phần",
      cancel: "Hủy toàn bộ",
      rejectedGkck: "Sở từ chối",
    },
    extStatusCond: {
      takeEffect: "Đang hiệu lực",
      expire: "Hết hiệu lực",
      complete: "Hoàn thành",
      cancel: "Đã hủy",
    },
    extStatusCond2: {
      waitActive: "Chờ kích hoạt",
      end: "Đã kết thúc",
      expire: "Hết hiệu lực",
      cancel: "Đã hủy",
    },
    tradeType: {
      sell: "Bán",
      buy: "Mua",
    },
    tradeTypeDer: {
      short: "Short",
      long: "Long",
    },
    filter: {
      account: "Tài khoản",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      orderType: "Loại lệnh",
      trade: "Loại giao dịch",
      status: "Trạng thái",

      ticker: "Mã chứng khoán",
      placehoderTicker: "Nhập mã CK",
      search: "Tìm kiếm",
      type: {
        normalOrder: "Lệnh thường",
        conditionalOrder: "Lệnh điều kiện",
      },
      condType: {
        all: "Tất cả",
        tco: "Lệnh trước ngày",
        ts: "Lệnh xu hướng",
        sp: "Lệnh chốt lãi",
        cl: "Lệnh cắt lỗ",
        pro: "Lệnh tranh mua/bán",
      },
      statusType: {
        all: "Tất cả",
        rejected: "Từ chối",
        entered: "Chờ gửi",
        waitApprove: "Chờ khớp",
        partialMatch: "Khớp một phần",
        fullMatch: "Khớp toàn bộ",
        partialCancel: "Hủy một phần",
        cancel: "Hủy toàn bộ",
        rejectedGkck: "Sở từ chối",
      },
      statusCondType: {
        all: "Tất cả",

        takeEffect: "Đang hiệu lực",
        expire: "Hết hiệu lực",
        complete: "Hoàn thành",
        cancel: "Đã hủy",
      },
      tradeType: {
        all: "Tất cả",
        buy: "MUA",
        sell: "BÁN",
      },
    },

    table: {
      stt: "STT",
      tradingDate: "Ngày giao dịch",
      condType: "Loại lệnh",
      regDateTime: "Thời gian cập nhật",
      availableDateTime: "Thời gian hiệu lực",
      matMethod: "Phương thức khớp",
      orderDate: "Ngày đặt lệnh",
      orderTime: "Thời gian đặt",
      marketPrice: "Giá TT",
      orderActive: "Thời gian kích hoạt",
      account: "Tài khoản",
      orderShare: "Mã CK",
      orderContract: "Mã CK",
      orderSide: "Loại giao dịch",
      volume: "Khối lượng",
      orderVolume: "Khối lượng đặt",
      price: "Giá",
      stopPrice: "Giá cắt lỗ",
      orderPrice: "Giá đặt",
      orderStatus: "Trạng thái",
      orderNo: "Số hiệu lệnh",
      orderChannel: "Kênh đặt lệnh",
      orderVolumeFilled: "Khối lượng khớp",
      orderPriceFilled: "Giá khớp",
      matchAvgPrice: "Giá khớp BQ",
      orderType: "Loại lệnh",
      order: "Lệnh",
      slipMargin: "Biên trượt",
      adjustableStopPrice: "Giá cắt lỗ điều chỉnh",
      cancelTime: "Thời gian hủy",
      cancelVolume: "Khối lượng hủy",
      channel: "Kênh",
      time: "Thời gian",
      action: "Thao tác",
      reasonReject: "Nguyên nhân từ chối",
      conditionPrice: "Giá đk/Cắt lỗ",
      takeProfitPrice: "Giá chốt lãi",
      orderVolumeShort: "KL đặt",
      match: "Khớp",
      conditionOrder: "Lệnh điều kiện",
      method: "Phương thức",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      referencePrice: "Giá TC",
      channelOrder: "Kênh ĐL",
      difference: "Chênh lệch giá",
      cond: "Điều kiện",
      byPercent: "Theo %",
      byValue: "Theo giá trị",
      value: "Giá trị",
      pause: "Khoảng dừng",
      activePrice: "Giá kích hoạt",
      status: {
        all: "Tất cả",
        filled: "Đã khớp",
        pending: "Chờ khớp",
        cancel: "Đã hủy",
      },
      fsStatus: {
        all: "Tất cả",
        reject: "Từ chối",
        cancel: "Đã hủy",
        filled: "Đã khớp",
        pending: "Chờ khớp",
      },
      fsStatusHistory: {
        inActive: "Chưa kích hoạt",
        stopReady: "Lệnh dừng",
        readyToSend: "Sẵn sàng gửi",
        queue: "Chờ khớp",
        sending: "Đang gửi",
        sent: "Đã gửi",
        reject: "Từ chối",
        cancel: "Đã hủy",
        partialMatch: "Khớp 1 phần",
        partialFilledCancel: "Đã hủy",
        fullMatch: "Đã khớp",
        matchAndCancel: "Đã hủy",
        waitApprove: "Đang chờ",
        waitEdit: "Chờ sửa",
        waitCancel: "Chờ hủy",
        priceWarning: "Cảnh báo giá",
        accomplished: "Đã hoàn thành",
        expried: "Đã hết hạn",
        partialExpried: "Hết hạn 1 phần",
        conditionalOrder: "Lệnh điều kiện",
        coi: "COI",
        oco: "OCO",
        oci: "OCI",
        undefined: "Không xác định",
        beAllocated: "Be Allocated (Fully Filled)",
      },
      side: {
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      tradeDate: "Ngày đặt lệnh",
      seriesId: "Mã hợp đồng",
      fsSide: {
        all: "Tất cả",
        short: "Short",
        long: "Long",
        longUp: "LONG",
        shortUp: "SHORT",
      },
      fsAction: {
        IC: "Đặt lệnh",
        AS: "Đặt thành công",
        PE: "Khớp lệnh",
        MC: "Sửa lệnh",
        MS: "Sửa thành công",
        FL: "Khớp toàn bộ",
        MO: "Sửa lệnh",
        AF: "Từ chối lệnh",
        CO: "Hủy lệnh",
        DC: "Hủy thành công",
        AZ: "Hủy phần còn lại",
        IS: "Đặt lệnh",
        CI: "Chuyển về chưa kích hoạt",
        CA: "Kích hoạt lệnh",
        KL: "Bị từ chối",
        II: "Đặt lệnh",
        SC: "Kích hoạt lệnh dừng",
        EX: "Từ chối phần còn lại",
        CS: "Hủy lệnh thành công",
        IJ: "Đặt lệnh",
        IA: "Đặt lệnh",
        AD: "Đã duyệt",
        AM: "Đã duyệt",
        AP: "Đã duyệt",
        AX: "Không duyệt",
        BC: "Hủy lệnh nội bộ",
        BM: "Sửa lệnh nội bộ",
        BR: "Giảm khối lượng lệnh nội bộ",
        CF: "Thông báo khớp hết",
        CM: "Từ chối sửa",
        CW: "Hủy cảnh báo giá",
        DA: "Hủy kích hoạt",
        DE: "Yêu cầu không phân bổ lệnh",
        DI: "Gỡ treo ký quỹ",
        FA: "Khớp lệnh định kỳ",
        FC: "Hủy toàn bộ",
        FF: "Khớp toàn bộ",
        HF: "Treo ký quỹ",
        ID: "Input C.D. Order",
        IG: "Không hiệu lực hôm nay",
        IK: "Đặt lệnh",
        IM: "Đặt lệnh",
        IN: "Đặt lệnh",
        IP: "Sửa lệnh",
        LM: "Sửa lệnh",
        MJ: "Từ chối sửa",
        ML: "Sửa lệnh",
        NA: "Thông báo khách hàng",
        OI: "Nhập lệnh vào hệ thống",
        PC: "Hủy phần còn lại",
        PF: "Khớp một phần",
        PM: "Chờ sửa",
        PR: "Hủy khớp 1 phần",
        SS: "Gửi lệnh",
        RA: "Yêu cầu phân bổ lệnh",
        RB: "Gửi lại lệnh",
        RD: "Giảm khối lượng lệnh",
        RJ: "Bị từ chối",
        RM: "Từ chối sửa",
        RR: "Từ chối (Thị trường đóng cửa)",
        SD: "Chia lệnh",
        PW: "Cảnh báo giá",
        ST: "Kích hoạt",
        SX: "Trả lại trạng thái lệnh",
        TB: "User Manually Unfill Executed Trade",
        TM: "Sửa lệnh",
        TR: "Từ chối",
        TS: "Kích hoạt lệnh",
        UC: "Hủy chưa xử lý",
      },
      fsOrderType: {
        L: "Lệnh LO",
        M: "Lệnh MO",
        A: "Lệnh ATO",
        C: "Lệnh ATC",
        K: "Lệnh MTL",
      },
      channelName: {
        I: "Internet",
        M: "Mobile",
        D: "Broker",
        B: "Bloomberg",
        H: "Home",
        O: "Omnibus",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      channelFSName: {
        WT: "D24",
        FOT: "FOT",
        BR: "Broker",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      fsStopType: {
        N: "Thường",
        D: "Down",
        U: "Up",
        V: "T Up",
        E: "T Down",
        O: "OCO",
        B: "Bull & Bear",
      },
      fsDealStatus: {
        waitApprove: "Chờ khớp",
        waitActive: "Chờ kích hoạt",
        partialMatch: "Khớp 1 phần",
        fullMatch: "Đã khớp",
        cancel: "Đã hủy",
        reject: "Từ chối",
        edited: "Đã sửa",
        waiting: "Đang chờ",
        waitCancel: "Chờ hủy",
        rejectEdit: "Từ chối sửa",
        waitEdit: "Chờ sửa",
        rejectCancel: "Từ chối hủy",
      },
      filterOrderType: {
        normal: "Lệnh thường",
        condition: "Lệnh điều kiện",
      },
      filterCondition: {
        all: "Tất cả",
        beforeDay: "Trước ngày",
        trending: "Xu hướng",
        competitive: "Tranh mua/bán",
        takeProfit: "Chốt lãi",
        stopLoss: "Cắt lỗ",
      },
      statusCondition: {
        all: "Tất cả",
        complete: "Hoàn thành",
        expired: "Hết hạn",
        cancel: "Đã hủy",
      },
      sideCondition: {
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      methodCondition: {
        once: "Phát sinh 1 lần",
        full: "Khớp đủ KL đặt",
      },
      channelCondition: {
        N: "Mobile App",
        W: "New Web",
      },
    },
    null: "--",
    modal: {
      link: "Xem chi tiết lệnh",
      title: "Chi tiết lệnh phái sinh",
    },
  },
  infoWarrant: {
    registerBuy: {
      errorShareRegister0: "Số CP đăng ký không được bỏ trống",
      errorShareRegister_shareCanBuy:
        "Số CP đăng ký không được lớn hơn Số lượng được mua",
      errorBuyPrice_cashBalance: "Số dư hiện tại không đủ",
      accountCode: "Tài khoản đăng ký",
      shareCode: "Mã CK",
      registerFromDate: "Thời gian đăng ký từ ngày",
      registerToDate: "Đến ngày",
      shareCanBuy: "SL CP còn được mua",
      buyPrice: "Giá mua",
      shareRegister: "Số CP đăng ký",
      placeholder_shareRegister: "Nhập số CP",
      sum: "Tổng tiền mua",
      accountPay: "TK chuyển tiền",
      cashBalance: "Số dư tiền",
      success: "Đăng kí thành công",
    },
    filter: {
      all: "Tất cả",
      CHUA_THUC_HIEN: "Chưa thực hiện",
      DA_THUC_HIEN: "Đã thực hiện",
      account: "Số tài khoản",
      sticker: "Mã chứng khoán",
      status: "Trạng thái",
      search: "Tìm kiếm",
      RIGHT_BUY: "Quyền mua",
      RIGHT_DIVIDEND: "Quyền cổ tức bằng tiền",
      RIGHT_STOCK: "Quyền cổ tức bằng cổ phiếu",
      RIGHT_VOTE: "Quyền họp ĐHCĐ",
      CONVERSION_STOCK: "Quyền chuyển đổi cổ phiếu",
      RIGHT_CASH_ORIGIN_BOND: "Quyền nhận gốc Trái phiếu",
      CHUA_CHOT_DS_CO_DONG: "Chưa chốt DS cổ đông",
      RIGHT_COVERED_WARRANT: "Quyền đáo hạn CW",
      DA_DUYET: "Đã duyệt",
      RIGHT_BUY_CHUA_THUC_HIEN: "Quyền mua chưa về TK",
      RIGHT_BUY_DA_THUC_HIEN: "Quyền mua đã về TK",
      CHUA_CHOT_CO_DONG: "Chưa chốt danh sách sở hữu",
      DA_CHUYEN_T_THAI: "Đã chuyển trạng thái",
      DA_DUYET_T_THAI: "Đã duyệt chuyển trạng thái",
      RIGHT_STOCK_CHUA_CHOT_DS_CO_DONG: "Chưa chốt DS sở hữu",
      CHUA_CHOT_DANG_KY: "Chưa chốt danh sách đăng ký mua",
      RIGHT_STOCK_CHUA_THUC_HIEN: "Chưa thực hiện quyền",
      RIGHT_STOCK_DA_THUC_HIEN: "Đã thực hiện quyền",
      type: "Loại quyền",
      closeFromDate: "Ngày chốt từ",
      execFromDate: "Ngày thực hiện từ",
    },
    table: {
      index: "STT",
      DA_THUC_HIEN: "Quyền đã về",
      CHUA_THUC_HIEN: "Quyền chưa về",
      accountCode: "SỐ TÀI KHOẢN",
      shareCode: "MÃ CK",
      shareRegister: "SL CỔ PHIẾU ĐĂNG KÝ MUA",
      buyPrice: "GIÁ MUA",
      cashMoney: "SỐ TIỀN",
      registerDate: "NGÀY ĐĂNG KÝ",
      excuteDate: "NGÀY THỰC HIỆN",
      statusCode: "TRẠNG THÁI",
      registerFromDate: "NGÀY BẮT ĐẦU ĐĂNG KÝ",
      registerToDate: "NGÀY KÊT THÚC ĐĂNG KÝ",
      rightTotal: "SL QUYỀN",
      shareCanBuy: "SL CP ĐƯỢC MUA",
      valueShareRegister: "SL ĐÃ ĐĂNG KÝ",
      type: "LOẠI QUYỀN",
      closeDate: "NGÀY ĐK CUỐI CÙNG",
      volumeCanBuy: "SLCP CÒN ĐƯỢC MUA",
      registedVolume: "SLCP ĐÃ ĐĂNG KÝ",
      shareDividend: "CỔ TỨC/CP THƯỞNG ĐƯỢC NHẬN",
      cashDividend: "CỔ TỨC BẰNG TIỀN ĐƯỢC NHẬN",
      rightCashRate: "TỈ LỆ CỔ TỨC TIỀN (%)",
      rightShareRate: "TỈ LỆ CỔ TỨC/ THƯỞNG CP",
      registerTime: "TG ĐĂNG KÝ MUA",
      transferTime: "TG CHUYỂN NHƯỢNG",
      rightBuyRate: "TỈ LỆ MUA",
      price: "GIÁ MUA",
      confirmDate: "NGÀY TH",
    },
    tab: {
      RESIGNTER: "Đăng ký Quyền mua",
      HISTORY: "Lịch sử đăng ký Quyền mua",
      INFORMATION: "Tra cứu thông tin Quyền",
      register: "Đăng ký mua",
    },
  },
  transferShare: {
    title: "Chuyển khoản chứng khoán",
    status: {
      all: "Tất cả",
      pending: "Chưa duyệt",
      approve: "Đã duyệt",
      delete: "Đã xóa",
    },
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      account: "TK chuyển",
      shareCode: "Mã CK",
      status: "Trạng thái",
      search: "Tìm kiếm",
    },
    table: {
      index: "STT",
      transactionDate: "Ngày GD",
      accountOut: "TK chuyển",
      accountIn: "TK nhận",
      shareCode: "Mã CK",
      shareStatusOut: {
        title: "Loại CK",
        normal: "Thường",
        outR: "OutR",
        tt68: "TT68",
      },
      shareVolume: "Số lượng",
      statusName: "Loại CK",
      content: "Nội dung",
      status: "Trạng thái",
      updUserId: "Người cập nhật",
      updDateTime: "Thời gian cập nhật",
      action: "Hủy",
    },
    tab: {
      transferShare: "Chuyển khoản chứng khoán",
      transferShareHistory: "Lịch sử chuyển khoản chứng khoán",
      transferShareForm: "Chuyển khoản chứng khoán",
    },
    transferForm: {
      accountOut: "Tiểu khoản chuyển",
      accountIn: "Tiểu khoản nhận",
      content: "Nội dung",
      content_value: "TK {accountOut} chuyển khoản sang {accountIn}",
      content_value1: "TK",
      content_value2: "chuyển khoản sang",
      placeholder_content: "Nhập nội dung",
      table: {
        shareCode: "Mã CK",
        statusName: "Loại CK",
        shareBalance: "KHẢ DỤNG CHUYỂN",
        maxWithDraw: "SL CHUYỂN",
      },
      button: {
        cancel: "Hủy",
        confirm: "Thực hiện",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      errorMaxWidthdraw: "Vui lòng nhập SL chuyển lớn hơn 0",
      errorCalPrice: "Có lỗi xảy ra khi tính toán mã chứng khoán đã chọn",
      errorBanAcc: "Tiểu khoản đã bị phong tỏa",
      transferShareSuccess: "Chuyển khoản thành công",
      modal: {
        title: "Xác nhận chuyển",
        content:
          "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện, bạn có muốn tiếp tục thực hiện ?",
        cancel: "Hủy",
        confirm: "Xác nhận",
      },
    },
    modalConfirmDelete: {
      title: "Xác nhận",
      content:
        "Bạn có chắc chắn muốn huỷ yêu cầu chuyển khoản mã {secCd}, khối lượng {volume}",
    },
  },
  packServices: {
    title: "Đăng ký chính sách DVTC",
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      status: {
        title: "Trạng thái",
        all: "Tất cả",
        notApprovedYet: "Chưa duyệt",
        approved: "Đã duyệt",
        rejectApproved: "Từ chối duyệt",
        canceled: "Đã hủy",
        cancelApprovedWait: "Chờ duyệt hủy",
      },
      search: "Tìm kiếm",
    },
    table: {
      accountCode: "Tài khoản",
      createdDate: "Ngày ĐK",
      packServicesName: "Tên chính sách",
      packServicesCode: "Mã",
      expiredDate: "Ngày kết thúc",
      status: "Trạng thái",
      cancel: "Hủy",
      cancelSuccess: "Hủy đăng ký thành công",
    },
    tab: {
      packServices: "Đăng ký chính sách DVTC",
      packServicesHistory: "Lịch sử đăng ký chính sách DVTC",
      packServicesForm: "Đăng ký chính sách DVTC",
    },
    packServicesForm: {
      accountCode: "Tài khoản",
      fullName: "Họ và tên",
      packServicesName: "Tên chính sách",
      packServicesCode: "Mã chính sách",
      content: "Thông tin chính sách",
      createdDate: "Ngày đăng ký",
      expiredDate: "Ngày kết thúc",
      contentCondition:
        "Tôi đã đọc, hiểu và xác nhận đồng ý với các chính sách, dịch vụ liên quan đã đăng ký",
      placeholder_content: "Nhập nội dung",
      button: {
        cancel: "Hủy",
        confirm: "Đồng ý",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      packServicesSuccess: "Đăng ký chính sách thành công",
    },
  },
  coveredWarrant: {
    register: "Đăng ký mua",
    purchaseHistory: "Lịch sử đăng ký mua",
    information: "Thông tin chứng quyền",
    all: "Tất cả",
    buy: "Mua",
    sell: "Bán",
    history: {
      filter: {
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        cutAccount: "TK cắt tiền",
        coveredWarrantID: "Mã CQ",
      },
      table: {
        index: "STT",
        coveredWarrantID: "Mã CQ",
        registerAccount: "TK đăng ký",
        cutAccount: "TK cắt tiền",
        ckcs: "Mã CKCS",
        publishDate: "Đợt phát hành",
        registerDate: "Ngày đăng ký",
        quantity: "Số lượng",
        purchasePrice: "Giá đặt mua",
      },
      register: {
        coveredWarrantID: "Mã chứng quyền",
        cwIDPlaceHolder: "Chọn mã chứng quyền",
        coveredWarrantPlaceholder: "Nhập mã CQ",
        quantityPlaceholder: "Nhập KL đăng ký mua",
        codeList: "Chứng Quyền FPT/BSC/C/6M/EU/",
        ckcs: "Mã CKCS",
        publishDate: "Đợt phát hành",
        publishDatePlaceHolder: "Chọn đợt phát hành",
        purchaseAccount: "TK đăng ký mua",
        cutAccount: "TK cắt tiền",
        availableAmount: "Tiền có thể mua",
        quantity: "KL đăng ký mua",
        purchasePrice: "Giá đặt mua",
        total: "Tổng giá trị đặt mua",
        agree: "Tôi đã đọc và đồng ý với các",
        terms: "điều khoản và điều kiện",
        registerToBuy: "đăng ký mua Chứng quyền tại MBS",
        success: "Đăng ký thành công",
        modalTerms: {
          title: "Điều khoản và điều kiện",
          close: "Đóng",
        },
        error: {
          noCWCode: "Mã chứng quyền không được bỏ trống",
          noVolume: "Khối lượng đăng ký mua không được bỏ trống",
          overPriced: "Giá trị đặt mua phải nhỏ hơn tiền có thể mua",
          overDate: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
          lengthDate: "Vượt quá giới hạn tìm kiếm 180 ngày",
          divisible: "Khối lượng phải chia hết cho 100",
          notYet:
            "Chưa đến thời gian đăng ký trong ngày. Quý khách vui lòng thử lại sau",
          overVolume:
            "Số lượng đăng ký mua vượt số lượng chứng quyền phát hành còn được đăng ký",
          account: "Tài khoản CHƯA DUYỆT hoặc ĐANG SỬA ĐỔI",
        },
        button: {
          cancel: "Hủy bỏ",
          confirm: "Xác nhận",
        },
      },
    },
    info: {
      filter: {
        coveredWarrantID: "Mã chứng quyền",
        type: "Loại chứng quyền",
        all: "Tất cả",
        underlyingSecuritiesID: "Mã CKCS",
        underlyingSecuritiesIDPlaceHolder: "Nhập mã CKCS",
      },
      table: {
        index: "STT",
        coveredWarrantID: "Mã CQ",
        name: "Tên CQ",
        ckcs: "Mã CKCS",
        tcph: "TCPH",
        amount: "KL niêm yết",
        exerciseRatio: "TL chuyển đổi",
        period: "Thời hạn (tháng)",
        gdccDate: "Ngày GDCC",
        dueDate: "Ngày đáo hạn",
        estimatedPaymentDate: "Ngày TT dự kiến",
        exercisePrice: "Giá thực hiện",
        estimatedPaymentPrice: "Giá TT tạm tính",
      },
      description:
        "Khuyến cáo: Giá thanh toán tạm tính mang tính chất tham khảo. MBS không chịu trách nhiệm về sự sai lệch (nếu có).",
    },
  },
  screeners: {
    tabs: {
      screener: {
        title: "Bộ lọc",
        market: {
          legend: "Niêm yết",
          exchange: "Sàn",
          industry: "Ngành",
          select: "Tất cả",
        },
        quote: {
          legend: "Tiêu chí cơ bản",
          marketCap: "Vốn hóa TT",
          price: "Giá",
          changeRate: "% Thay đổi",
        },
        financial: {
          legend: "Chỉ số cơ bản",
          pe: "P/E",
          eps: "EPS",
        },
        technical: {
          legend: "Tín hiệu kỹ thuật",
        },
        other: {
          legend: "Other",
          analystRating: "Analyst Rating",
          marginAvailable: "Margin Available",
        },
        actions: {
          reset: "Hủy",
          save: "Lưu ",
          emptyWarning: "Quý khách vui lòng chọn tiêu chí lọc cổ phiếu",
          createSuccess: "Tạo bộ lọc thành công",
          deleteSuccess: "Xóa bộ lọc thành công",
        },
      },
      myScreener: {
        title: "Bộ lọc của tôi",
        applyError: "Vui lòng chọn bộ lọc",
        applyButton: "Áp dụng",
        cancelSelectButton: "Hủy",
      },
    },
    card: {
      cardTitle: "{total} Kết quả",
      tickerTooltip: "Nhấn để xem chi tiết mã",
      table: {
        order: "STT",
        shareCode: "MÃ CK",
        companyName: "TÊN CÔNG TY",
        price: "PRICE",
        change: "CHANGE",
        changeRate: "% CHANGE",
        marketCap: "MARKET CAP",
        pe: "P/E",
        pb: "P/B",
        netMargin: "NET SALES (B)",
        plusButton: "Thêm vào danh mục ({number})",
        roa: "ROA (%)",
        roe: "ROE (%)",
        eps: "EPS",
      },
      modal: {
        title: "THÊM BỘ LỌC",
        screenerName: "Tên bộ lọc",
        placeholder: "Nhập tên bộ lọc",
        cancel: "Hủy",
        save: "Lưu",
        errorMessage: "Bộ lọc {name} đã tồn tại, vui lòng chọn tên khác",
      },
    },
  },
  loanExtension: {
    tabHeader: {
      loanTracking: {
        title: "Theo dõi dư nợ",
      },
      extendHistory: {
        title: "Lịch sử gia hạn online",
      },
    },
    tabPanel: {
      loanTracking: {
        filterActions: {
          account: "Tài khoản",
          status: "Trạng thái",
          search: "Tìm kiếm",
          all: "Tất cả",
          canExtend: "Có thể gia hạn",
        },
        table: {
          header: {
            deliverDate: "NGÀY GIẢI NGÂN",
            oldExpireDate: "NGÀY ĐẾN HẠN HIỆN TẠI",
            numDayDiff: "SỐ NGÀY ĐÃ VAY",
            feeRate: "LÃI SUẤT HIỆN TẠI (%/NGÀY)",
            intermLoan: "NỢ GỐC TRONG HẠN",
            intermFee: "NỢ LÃI TRONG HẠN",
            exterLoan: "NỢ GỐC NGOÀI HẠN",
            exterFee: "NỢ LÃI NGOÀI HẠN",
            totalLoanBLN: "TỔNG NỢ",
            extendFeeRate: "LÃI SUẤT SAU GIA HẠN (%/NGÀY)",
            newExpireDate: "NGÀY ĐẾN HẠN SAU GIA HẠN",
          },
          footer: {
            extend: "Gia hạn",
            total: "TỔNG",
            notCheckedError: "Chưa món vay nào được chọn",
          },
        },
      },
      extendHistory: {
        filterActions: {
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          status: "Trạng thái",
          search: "Tìm kiếm",
          all: "Tất cả",
          extendStatus: {
            approved: "Đã duyệt",
            notControl: "Chưa kiểm soát",
            notApprove: "Chưa duyệt",
          },
          error: {
            dateRange: "Số ngày tìm kiếm không được vượt quá 30 ngày",
            invalidDate: "Từ ngày phải nhỏ hơn Đến ngày",
          },
        },
        table: {
          header: {
            order: "STT",
            accountNum: "SỐ TK",
            deliverDate: "NGÀY GIẢI NGÂN",
            oldExpireDate: "NGÀY ĐẾN HẠN CŨ",
            numDayDiff: "SỐ NGÀY ĐÃ VAY",
            loanBalance: "NỢ HIỆN TẠI",
            feeBalance: "NỢ LÃI",
            totalLoanBLN: "TỔNG NỢ",
            newExpireDate: "NGÀY ĐẾN HẠN MỚI",
            extendFeeRate: "LÃI SAU KHI GIA HẠN (%/NĂM)",
            approveDate: "NGÀY GIA HẠN",
            channel: "KÊNH",
            statusName: "TRẠNG THÁI",
          },
        },
      },
      extendExpireSuccessMsg: "Gia hạn thành công",
    },
    card: {
      header: "Gia hạn online",
      accountNum: "Số tài khoản",
      deliverDate: "Ngày giải ngân",
      oldExpireDate: "Ngày đến hạn hiện tại",
      numDayDiff: "Số ngày đã vay",
      loanBalance: "Nợ gốc hiện tại",
      feeBalance: "Nợ lãi",
      totalFeeRate: "Tổng nợ",
      newExpireDate: "Ngày đến hạn sau khi gia hạn",
      extendFeeRate: "Lãi sau khi gia hạn (%/năm)",
      acceptTerms:
        "Tôi đã đọc và đồng ý với các điều khoản thỏa thuận trong hợp đồng ký quỹ, phụ lục hợp đồng và các chính sách dịch vụ tài chính đang áp dụng tại MBS",
      cancelButton: "Hủy bỏ",
      confirmButton: "Xác nhận",
      closeButton: "Đóng",
      note: {
        r1: "Món vay không bị chặn gia hạn",
        r2: "Tài khoản hiện không tồn tại món nợ nào bị quá hạn",
        r3: "Món vay vẫn còn có thể gia hạn: Tổng số thời hạn của khoản vay tính từ lúc giải ngân đến ngày đáo hạn mới phải nhỏ hơn hoặc bằng 275 ngày (tổng số ngày có thể thay đổi theo chính sách MBS từng thời kỳ)",
        r4: "Số ngày còn lại của món vay đến ngày hết hạn hiện tại phải nhỏ hơn hoặc bằng 20 ngày (số ngày vay có thể thay đổi theo chính sách của MBS từng thời kỳ)",
        r5: "Món vay chưa từng được gia hạn tại quầy",
      },
    },
  },
  rangeDateSearch: {
    success: "",
    errorRange: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
    exceededLimit: "Vượt quá giới hạn tìm kiếm {days} ngày",
  },
  marginLimit: {
    tabs: {
      marginLimitHistory: {
        header: {
          title: "Lịch sử thay đổi hạn mức",
        },
        filterActions: {
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          account: "Tài khoản",
          status: "Trạng thái",
          search: "Tìm kiếm",
        },
        statusList: {
          all: "Tất cả",
          approved: "Đã duyệt",
          modified: "Đã sửa đổi",
          modifying: "Đang sửa đổi",
          cancel: "Hủy thay đổi",
          reject: "Từ chối duyệt",
        },
        dateError: {
          overRange: "Số ngày tìm kiếm phải không vượt quá 30 ngày",
          underRange: "Từ ngày phải nhỏ hơn đến ngày",
        },
        table: {
          header: {
            order: "STT",
            limitLoanOld: "Giá trị cũ",
            limitLoan: "Giá trị yêu cầu mới",
            modifyDate: "Ngày yêu cầu",
            approveDate: "Ngày duyệt",
            status: "Trạng thái",
          },
        },
      },
    },
    cards: {
      marginLimitChange: {
        header: {
          title: "Thay đổi hạn mức",
        },
        body: {
          placeholderMarginRequire: "Nhập hạn mức yêu cầu",
          underLimitError:
            "Hạn mức yêu cầu mới không được phép nhỏ hơn hạn mức tối thiểu",
          overLimitError:
            "Hạn mức yêu cầu mới không được phép lớn hơn hạn mức tối đa",
          stepError:
            "Hạn mức yêu cầu mới phải tuân theo bước giá trị (nghĩa là mức gia hạn mới phải là số chia hết cho bước giá trị)",
          emptyError:
            "Hạn mức yêu cầu mới không được bỏ trống, vui lòng nhập lại",
          accountNum: "Số tài khoản",
          currentLimit: "Hạn mức hiện tại",
          requestLimit: "Hạn mức yêu cầu",
          requestLimitPlaceHolder: "Nhập hạn mức",
          acceptTerms:
            "Tôi đã đọc và đồng ý với các điều khoản và điều kiện về giao dịch ký quỹ và chính sách dịch vụ tại MBS",
          note: "Lưu ý: Thời gian thực hiện thay đổi hạn mức trước 16h các ngày làm việc",
        },
        actions: {
          cancel: "Hủy bỏ",
          agree: "Xác nhận",
        },
        limitRules: {
          title: "Quy định về hạn mức",
          minLimitLabel: "Hạn mức tối thiểu:",
          minLimitUnit: " triệu",
          maxLimitLabel: "Hạn mức tối đa:",
          maxLimitUnit: " tỷ",
          stepValueLabel: "Bước giá trị:",
          stepValueUnit: " triệu",
          confirmPrefix: "Tăng hạn mức yêu cầu trên ",
          confirmSuffix: " tỷ đồng cần MBS thẩm định",
        },
      },
    },
    message: {
      modifySuccess: "Thay đổi hạn mức thành công",
    },
  },
  tradingHelper: {
    checkPlaceOrder: "Không xác định được thông tin lệnh",
    symbolDataNotFound: "Không tìm thấy thông tin mã CK {symbol}",
    symbolStatusHalt:
      "Mã chứng khoán  {symbol} đã tạm ngưng hoặc hạn chế giao dịch",
    invalidPriceStep: "Giá đặt không hợp lệ (Bước giá {priceStep}đ)",
    invalidSecurityType1: "Chưa hỗ trợ đặt lệnh Trái phiếu doanh nghiệp",
    invalidSecurityType2: "Loại chứng khoán không hợp lệ",
    invalidMarketCode: "Sàn không hợp lệ {marketCode}",
    checkVolumeLot1: "Khối lượng không hợp lệ",
    checkVolumeLot2: "Khối lượng không tròn lô (Lô {number})",
    invalidVolumeLot: "Khối lượng không tròn lô (Lô {number})",
    invalidVolumeLotEdit:
      "Sàn HNX, Upcom không được sửa KL lô chẵn thành lẻ, lẻ thành chẵn",
    invalidMarketCode2: "Sàn không hợp lệ",
    invalidTradingSession: "Không xác định được phiên {tradingSessionId}",
    invalidTradingSession2: "Thị trường đóng cửa",
    invalidMarketState: "Thị trường đóng cửa",
    invalidMarketState2:
      "Giá không hợp lệ. Phiên Ngoài giờ chỉ có thể đặt lệnh LO, ATO, ATC",
    invalidMarketState3:
      "Giá không hợp lệ. Phiên ATO chỉ có thể đặt lệnh LO, ATO, ATC",
    invalidMarketState4:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MP, ATC",
    invalidMarketState5:
      "Loại lệnh không hợp lệ. Phiên Nghỉ trưa chỉ có thể đặt lệnh LO, MP, ATC",
    invalidMarketState6:
      "Loại lệnh không hợp lệ. Phiên ATC chỉ có thể đặt lệnh LO, ATC",
    invalidMarketState7:
      "Giá không hợp lệ. Phiên Ngoài giờ chỉ có thể đặt lệnh LO, ATC",
    invalidMarketState8:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState9:
      "Giá không hợp lệ. Phiên Nghỉ trưa chỉ có thể đặt lệnh LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState10:
      "Giá không hợp lệ. Phiên ATC chỉ có thể đặt lệnh LO, ATC, PLO",
    invalidMarketState11:
      "Giá không hợp lệ. Phiên RUNOFF chỉ có thể đặt lệnh PLO",
    invalidMarketState12: "Lệnh lô lẻ chỉ được phép đặt giá LO",
    invalidMarketState13:
      "Lệnh lô lẻ chỉ được phép đặt trong phiên KLLT, Nghỉ trưa, Ngoài giờ và Trước giờ",
    invalidMarketState14: "Giá không hợp lệ. Sàn UPCOM chỉ có thể đặt lệnh LO",
    invalidMarketState15:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MOK, MAK, MTL, ATC",
    invalidMarketState16:
      "Giá không hợp lệ. Phiên KLLT chỉ có thể đặt lệnh LO, MOK, MAK, MTL, ATC",
    invalidMarketState17:
      "Giá không hợp lệ. Phiên ATC chỉ có thể đặt lệnh LO, ATC",
    invalidMarketState18: "Sàn không hợp lệ",
    invalidMarketState19: "Phiên giao dịch không hợp lệ đặt lệnh báo giá",
    volumeShouldNotBeExceeded: "Khối lượng không được vượt quá {limit}",
    invalidPrice0: "Chỉ được đăt giá LO với lệnh điều kiện {stopType}",
    invalidPrice: "Giá cắt lỗ phải lớn hơn giá hiện tại",
    invalidPrice2: "Giá cắt lỗ + biên trượt không được lớn hơn giá trần",
    invalidPrice3: "Giá cắt lỗ phải khác giá đặt",
    invalidPrice4: "Giá cắt lỗ phải nhỏ hơn giá hiện tại",
    invalidPrice5: "Giá cắt lỗ - biên trượt phải lớn hơn giá sàn",
    invalidPrice6: "Giá chốt lãi phải lớn hơn giá đặt",
    invalidPrice7: "Giá chốt lãi phải nhỏ hơn hoặc bằng giá trần",
    invalidPrice8: "Giá cắt lỗ phải nhỏ hơn giá đặt",
    invalidPrice9: "Giá cắt lỗ phải lớn hơn hoặc bằng giá sàn",
    invalidPrice10: "Giá cắt lỗ hoặc chốt lãi phải khác giá đặt",
    invalidPrice11: "Giá chốt lãi phải nhỏ hơn giá đặt",
    invalidPrice12: "Giá chốt lãi phải lớn hơn hoặc bằng giá sàn",
    invalidPrice13: "Giá cắt lỗ phải lớn hơn giá đặt",
    invalidPrice14: "Giá cắt lỗ phải phải nhỏ hơn hoặc bằng giá trần",
    invalidPrice15: "Giá kích hoạt không được lớn hơn giá trần",
    invalidPrice16: "Giá kích hoạt không được nhỏ hơn giá sàn",
    invalidPrice17:
      "Giá kích hoạt phải lớn hơn giá thị trường {priceToCheckCond}",
    invalidPrice18:
      "Giá kích hoạt phải nhỏ hơn giá thị trường {priceToCheckCond}",
    invalidPrice19: "Giá sửa không được lớn hơn giá trần",
    invalidPrice20: "Giá sửa không được nhỏ hơn giá sàn",
    invalidPrice21: "Giá chốt lãi phải nhỏ hơn giá trần",
    invalidPrice22: "Giá cắt lỗ phải lớn hơn giá sàn",
    invalidPrice23: "Không được sửa lệnh mua FAL",
    invalidOrderData: "Không xác định được thông tin lệnh cần sửa",
    marketSessionCloseErr: "Thị trường đóng cửa",
    couldNotGetStockCodeInformationErr:
      "Không tìm thấy thông tin mã CK {symbol}",
    dataDoesNotChange: "Dữ liệu không thay đổi",
    invalidVolumne: "Khối lượng sửa phải lớn hơn 0",
    invalidVolumne2: "Khối lượng mới phải lớn hơn phần đã khớp",
    invalidVolumne3: "Giá cắt lỗ phải lớn hơn giá hiện tại",
    invalidVolumne4: "Giá cắt lỗ + biên trượt không được lớn hơn giá trần",
    invalidVolumne5: "Giá cắt lỗ phải khác giá đặt",
    invalidVolumne6: "Giá cắt lỗ phải nhỏ hơn giá hiện tại",
    invalidVolumne7: "Giá cắt lỗ - biên trượt phải lớn hơn giá sàn",
    invalidVolumne8: "Không được sửa tăng khối lượng",
    doNotModifyOrCancelThisOrderErr: "Không được sửa/hủy lệnh này",
    invalidPriceAndQty: "Không được sửa đồng thời cả Giá và Khối lượng",
    invalidMarketCd: "Không được sửa khối lượng với lệnh sàn HSX",
    volumeAllowedAndNotChangeprice:
      "Lệnh thị trường đang chờ vào sàn chỉ được phép sửa khối lượng, không được sửa giá",
    checkEditOrderStatus: "Không được sửa/hủy lệnh trong phiên KLĐK",
    checkEditOrderStatus2: "Không được sửa/hủy lệnh này",
    checkEditOrderStatus3: "Không được sửa lệnh này",
    checkEditOrderStatusDer: "Không được sửa/hủy lệnh trong phiên KLĐK",
    checkEditOrderStatusDerCond: "Không được sửa/hủy lệnh trong phiên KLĐK",
    checkEditOrderStatusDerCond2: "Không được sửa/hủy lệnh này",
    checkCancelOrder: "Không xác định được lệnh cần hủy",
  },
  netAssetsValue: {
    nav: {
      tabHeaderTitle: "Tổng tài sản & Hiệu quả đầu tư",
      card: {
        navSub: "Tài sản ròng (NAV):",
        marketValueSub: "Giá trị danh mục:",
        profitSub: "Lãi/lỗ tạm tính:",
        totalAsset: "Tổng tài sản:",
      },
      assetInfo: {
        nav: "Tài sản ròng",
        totalAsset: "Tổng tài sản",
        totalCashBalance: "1. Tiền",
        cashBalance: "1.1 Số dư tiền",
        matchedBuyT0: "1.2 Mua khớp chờ thanh toán",
        unMatchBuy: "1.3 Tiền mua chờ khớp",
        cashAdvanceAble: "1.4 Số tiền có thể ứng",
        cashWithdrawable: "1.5 Số tiền có thể rút",
        totalShareValue: "2. Cổ phiếu",
        shareValue: "2.1 Giá trị CP đang có",
        shareValueBuy: "2.2 Giá trị CP mua chờ về",
        shareValueSell: "2.3 Giá trị CP bán chờ thanh toán",
        sumRight: "2.4 Giá trị quyền chờ về",
        rightCash: "Cổ tức bằng tiền mặt",
        rightShare: "Cổ tức bằng CP",
        rightBuyStock: "Quyền mua",
        totalCWValue: "3. Chứng quyền",
        cwValue: "3.1 Giá trị CW đang có",
        cwValueBuy: "3.2 Giá trị CW mua chờ về",
        cwValueSell: "3.3 Giá trị CW bán chờ thanh toán",
        cwValueBuyMaturity: "3.4 Giá trị CW mua chờ thanh toán đáo hạn",
        bondValue: "4. Trái phiếu",
        capValue: "5. Hợp tác kinh doanh",
        fsTotalAssets: "6. Phái sinh",
        fsMBS: "6.1 Tiền phái sinh tại MBS",
        fsVSD: "6.2 Tiền phái sinh ký quỹ VSD",
        fsCashWithdrawable: "6.3 Tiền phái sinh có thể rút",
        totalDebit: "7. Nợ",
        rootDebit: "7.1 Dư nợ gốc",
        fee: "7.2 Lãi phí dịch vụ tài chính",
      },
    },
    financialServices: {
      tabHeaderTitle: "TS DVTC",
      account: "Tài khoản",
      normal: "Thường",
      table: {
        tableName: "Danh mục chứng khoán",
        order: "STT",
        accountNoDetail: "TẢI KHOẢN",
        symbol: "MÃ CK",
        tradeType: "TRẠNG THÁI",
        marginRatio: "TỶ LỆ KÝ QUỸ (%)",
        availableBalance: "Khả dụng",
        sharesBalance: "TỔNG KL",
        unmatchSell: "CHỜ KHỚP BÁN",
        qtyT0: "T + 0",
        qtyT1: "T + 1",
        qtyT2: "T + 2",
        bought: "MUA",
        sold: "BÁN",
        marketValue: "GIÁ TT",
        grossValue: "TỔNG GIÁ TRỊ",
        total: "TỔNG CỘNG",
        rightShares: "QUYỀN CHỜ VỀ",
      },
      cards: {
        totalShareValue: "I. TỔNG GIÁ TRỊ CHỨNG KHOÁN",
        totalCashBalance: {
          title: "II. TIỀN (1-2-3+4+6)",
          cashBalance: "1. Số dư",
          buyingCashBlock: "2. Mua chờ khớp",
          matchedBuyT0: "3. Mua đã khớp chờ thanh toán",
          receivingSellCash: "4. Tiền bán chờ về (T0, T1, T2)",
          withDrawCash: "5. Tiền có thể rút",
          cashDividend: "6. Quyền chờ về tạm tính",
        },
        totalDebit: {
          title: "III. TỔNG NỢ DVTC (7+8+9)",
          principalBalance: "7. Dư nợ gốc",
          paidInterest: "8. Lãi/Phí dịch vụ tài chính",
          cashAdvanced: "9. Tiền đã ứng",
        },
        totalAsset: "IV. TÀI SẢN RÒNG THỰC",
        financialServicesAccInfo: {
          title: "THÔNG TIN TÀI KHOẢN DỊCH VỤ TÀI CHÍNH",
          callRatio: "Tỷ lệ Call của MBS",
          forceSellRatio: "Tỷ lệ Force Sell của MBS",
          afterBuyRatio: "Tỷ lệ sau mua",
          interestRatio:
            "Tỷ lệ lãi vay dịch vụ chưa gồm hệ số gia hạn (nếu có)",
          creditLimit: "Hạn mức tài khoản",
          falLimit: "Hạn mức Fal",
          falRemainCredit: "Hạn mức Fal còn lại",
          falUsed: "Nợ Fal đã dùng",
          buyCredit: "Sức mua",
          portfolioValue: "GTDM tính QTRR",
          netWorth: "TSR tính QTRR",
          currentMarginRatio: "Tỷ lệ ký quỹ hiện tại (TSR/GTDM)",
          capValueLink: "HTKD link sức mua",
          bPLinkMB: "Sức mua liên kết TK MB",
          bPLinkMBUsed: "Đã dùng SM liên kết MB",
        },
      },
    },
    derivative: {
      tabHeaderTitle: "TS Phái sinh",
      noAccount: "Tài khoản chưa có tiểu khoản phái sinh",
      filter: {
        account: "Tài khoản",
      },
      card: {
        overCash: {
          cardTitle: "TỔNG SỐ DƯ TIỀN",
          commissionPerFee: "Phí treo",
          interest: "Lãi treo",
          deliveryAmount: "Giá trị chuyển giao",
          floatingPLPerTradingPL: "Lãi lỗ vị thế (mở/đóng)",
          totalPL: "Tổng lãi/lỗ",
          minreservPerMarginable: "Số dư tối thiếu/Tiền có thể KQ VSD",
          cashDrawablePerNonCashDrawable: "Tổng tiền/Giá trị CK có thể rút",
        },

        assetPortfolio: {
          cardTitle: "DANH MỤC TÀI SẢN",
          MBS: "TẠI MBS",
          VSD: "TẠI VSD",
          cash: "Tiền mặt",
          validNonCash: "Giá trị CK ký quỹ",
          totalValue: "Tổng giá trị ký quỹ hợp lệ",
          maxValidNonCash: "GT CK ký quỹ tối đa tính hợp lệ",
          cashWithdrawable: "Tiền có thể rút",
          ee: "Sức mở vị thế",
        },

        signFund: {
          cardTitle: "SỬ DỤNG KÝ QUỸ",
          MBS: "TẠI MBS",
          VSD: "TẠI VSD",
          signInitValue: "Giá trị ký quỹ ban đầu",
          signTransValue: "Giá trị ký quỹ chuyển giao",
          marginRequirement: "Tổng giá trị ký quỹ yêu cầu",
          accountRatio: "Tỷ lệ sử dụng tài sản ký quỹ (%)",
          usedLimitWarning: "Ngưỡng cảnh báo 1/2/3 (%)",
          marginCall: "Giá trị tiền cần bổ sung",
        },
      },
      table: {
        positionOpen: {
          title: "Vị thế mở",
          rowNum: "STT",
          seriesID: "MÃ CK",
          dueDate: "NGÀY ĐÁO HẠN",
          long: "VỊ THẾ MUA",
          short: "VỊ THẾ BÁN",
          net: "VỊ THẾ RÒNG",
          bidAvgPrice: "GIÁ MUA TB",
          askAvgPrice: "GIÁ BÁN TB",
          marketPrice: "GIÁ TT",
          tradingPLPerFloatingPL: "LÃI/LỖ TRONG NGÀY",
        },
        positionClose: {
          title: "Vị thế đóng",
          rowNum: "STT",
          seriesID: "MÃ CK",
          long: "VỊ THẾ ĐÓNG",
          net: "VỊ THẾ RÒNG",
          bidAvgPrice: "MUA TRUNG BÌNH",
          askAvgPrice: "BÁN TRUNG BÌNH",
          tradingPLPerFloatingPL: "LÃI/LỖ",
        },
      },
    },
    charts: {
      beta: "(BETA)",
      betaText1: "Dữ liệu HQDT được thống kê từ 23/11/2021",
      betaText2:
        "TSSL tính theo công thức Time-Weighted Rate(TWR) = {End NAV - (Begin NAV + Cash Flow)}/(Begin NAV + Cash Flow)",
      betaText3:
        "Công thức chỉ mang tính chất tham khảo, Chúng tôi không chịu trách nhiệm về các quyết định của Khách hàng khi dựa vào số liệu này",
      navLineChart: {
        title: "TÀI SẢN RÒNG",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Tài khoản",
        growth: "Tăng trưởng:",
        nav: "NAV:",
      },
      investLineChart: {
        title: "HIỆU QUẢ ĐẦU TƯ",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Tài khoản",
        indexLabel: "Index",
        profitLabel: "Tỷ suất sinh lời (TSSL)",
        profitLabelShort: "TSSL",
        idxVal: "VN-Index",
        percentDelta: "NAV",
      },
      assetDistributionChart: {
        title: "PHÂN BỔ TỔNG TÀI SẢN",
        totalCashBalance: "Tiền",
        totalShareValue: "Cổ phiếu",
        totalCWValue: "Chứng quyền",
        bondValue: "Trái phiếu",
        capValue: "Hợp tác kinh doanh",
        fsTotalAssets: "Phái sinh",
      },
      debtAndNavChart: {
        title: "NỢ VÀ TỔNG TÀI SẢN",
        debt: "Nợ",
        nav: "Tài sản ròng",
      },
      tickerRateChart: {
        title: "TỶ TRỌNG MÃ CỔ PHIẾU",
      },
      indexRateChart: {
        title: "TỶ TRỌNG NHÓM CHỈ SỐ",
        account: "Tài khoản",
        ticker: "VN-Index",
      },
      industryRateChart: {
        title: "TỶ TRỌNG NGÀNH",
        account: "Tài khoản",
        ticker: "VN30",
      },
      unit: "Đơn vị: Triệu VNĐ",
    },
  },
  onlineService: {
    careService: "Dịch vụ chăm sóc",
    authenticationMethod: "PT xác thực",
  },
  alertSettings: {
    modalTitle: "Cài đặt cảnh báo",
    symbolWarning: "Vui lòng chọn mã để cài đặt cảnh báo",
    setAlert: {
      tabTitle: "Đặt cảnh báo",
      volume: "KHỐI LƯỢNG",
      ceiling: "TRẦN",
      floor: "SÀN",
      referenceShort: "TC",
      totalValue: "GIÁ TRỊ",
      priceMatchGTE: "Giá khớp >=",
      priceMatchLTE: "Giá khớp <=",
      overBoughtGTE: "Dư mua >=",
      overSoldtLTE: "Dư bán <=",
      priceIncreasePercent: "% tăng giá",
      priceDecreasePercent: "% giảm giá",
      timesPerDay: "Lặp lại",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      createSuccessMsg: "Tạo cảnh báo {symbol} thành công",
      editSuccessMsg: "Sửa cảnh báo {symbol} thành công",
      deleteSuccessMsg: "Xóa thành công",
      notExistMsg: "Mã không tồn tại",
      dateError1: "Từ ngày không được vượt quá Đến ngày",
      dateError2: "Từ ngày và đến ngày không phải là ngày trong quá khứ",
      indexValUp: "Chỉ số >=",
      indexValDown: "Chỉ số <=",
      indexChangePerUp: "% Tăng chỉ số",
      indexChangePerDown: "% Giảm chỉ số",
      totalQtyUp: "KL >= (Triệu CP)",
      totalAmtUp: "Giá trị >= (Tỷ)",
      repeatType1: "1 ngày 1 lần",
      repeatType2: "1 phút 1 lần",
      repeatType3: "30 phút 1 lần",
    },
    manageAlert: {
      tabTitle: "Quản lý cảnh báo",
    },
    action: {
      cancel: "Hủy",
      save: "Lưu",
    },
    emptySymbolError: "Không được để trống mã",
  },
  businessCooperation: {
    businessCooperationTab: {
      tableTitle: "Giao dịch eM24",
      filterActions: {
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        account: "Tài khoản",
        status: "Trạng thái",
        search: "Tìm kiếm",
        disapprove: "Chưa duyệt",
        approved: "Đã duyệt",
        liquidated: "Đã thanh lý",
        all: "Tất cả",
      },
      table: {
        appendixNumber: "SỐ PHỤ LỤC",
        accountNo: "TK LẬP",
        value: "GIÁ TRỊ",
        period: "KỲ HẠN",
        openDate: "NGÀY MỞ",
        expireDate: "NGÀY HẾT HẠN",
        commission: "LS (%/NĂM)",
        estimatedIncome: "THU NHẬP DỰ TÍNH",
        buyCredit: "TÍNH SỨC MUA",
        accountNoLinkBuyCredit: "TK TÍNH SM",
        extendMethod: "PT GIA HẠN",
        introduceStaffName: "TÊN NVGT",
        introduceStaffCode: "MÃ NVGT",
        channel: "KÊNH",
        status: "TRẠNG THÁI",
        total: "TỔNG",
        liquidation: "Thanh lý",
        periodMsg1: "Từ 1 ngày đến 7 ngày",
        periodMsg2: "Từ 8 ngày đến 14 ngày",
        periodMsg3: "Từ 15 ngày đến 21 ngày",
        periodMsg4: "Từ 22 ngày đến 29 ngày",
        periodMsg5: "1 tháng",
        periodMsg6: "2 tháng",
        periodMsg7: "3 tháng",
        periodMsg8: "4 tháng",
        periodMsg9: "5 tháng",
        periodMsg10: "6 tháng",
        periodMsg11: "9 tháng",
        periodMsg12: "1 năm",
      },
      doubleClickTooltip: "Nhấn đúp để sửa đổi",
    },
    createAppendixCard: {
      cardTitle: "Tạo phụ lục",
      cardInfo: {
        accountNum: "Số tài khoản",
        withDrawCash: "Số dư",
        period: "Kỳ hạn",
        days: "Ngày",
        openDate: "Ngày mở",
        expireDate: "Ngày hết hạn",
        cooperationDays: "Số ngày thực tế",
        capitalValue: "Giá trị",
        enterValue: "Nhập giá trị",
        interestRate: "LS (%/năm)",
        profitEstimate: "Tổng thu nhập dự tính",
        calcPurchaseAbility: "Tính sức mua",
        linkType0: "Không tính sức mua",
        linkType1: "Tính sức mua",
        accountDesignation: "Tài khoản chỉ định",
        accountNoLinkBuyCredit: "Tài khoản tính sức mua",
        extensionMethod: "Phương thức gia hạn",
        extensionType0: "Tự động gia hạn gốc",
        extensionType1: "Tự động gia hạn gốc + lãi",
        extensionType2: "Không gia hạn",
        introduceStaffName: "Tên NV giới thiệu",
        enterStaffCode: "Nhập mã nhân viên giới thiệu",
        staffCodeError: "Mã nhân viên không tồn tại",
        introduceStaffCode: "Mã NV giới thiệu",
        confirmationText:
          "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện đã nêu trong hợp đồng giao dịch eM24",
        createSuccessMsg: "Tạo phụ lục thành công",
        valueMoreWithdrawCashError: "Số dư tiền gửi không đủ để thực hiện",
        valueEmpty: "Vui lòng nhập giá trị góp vốn",
        noteTitle: "QUÝ KHÁCH CHÚ Ý",
        noteText1: `"Số ngày hợp tác (thực tế)" > "Số ngày cụ thể" khách hàng đăng ký hợp tác.`,
        noteText2: `Quý khách nên chọn lại Tên Kỳ hạn và "Số ngày cụ thể" KH đăng ký hợp tác để được hưởng Lợi suất tương ứng.`,
      },
      cardActions: {
        cancel: "Hủy bỏ",
        agree: "Đồng ý",
      },
    },
    liquidationModal: {
      modalTitle: "Thanh lý",
      accountNum: "Số tài khoản",
      appendixNum: "Số phụ lục",
      openDate: "Ngày mở",
      expireDate: "Ngày hết hạn",
      capitalValue: "Giá trị",
      liquidationDate: "Ngày thanh lý",
      numberDayBorrow: "Số ngày thực tế",
      liquidFeeRate: "LS thanh lý (%/năm)",
      liquidValue: "Giá trị thanh lý",
      liquidFee: "Tổng thu nhập nhận được",
      confirmationText:
        "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện đã nêu trong hợp đồng giao dịch eM24",
      cancel: "Bỏ qua",
      agreeLiquid: "Thanh lý",
      liquidSuccessMsg: "Thanh lý thành công",
    },
    interestPercentTable: {
      tableTitle: "Biểu lợi suất",
      feeType: "Biểu lợi suất",
      feeTypeInterest: "HTKD",
      feeTypeAuto: "HTKD Tự động",
      feeTypeInterestLiquid: "Thanh lý trước hạn",
      customerGroup: "Nhóm KH",
      personnal: "Cá nhân",
      organizational: "Tổ chức",
      search: "Tìm kiếm",
      rowNum: "STT",
      period: "KỲ HẠN",
      fromDate: "NGÀY BAN HÀNH",
      fromValue: "GIÁ TRỊ TỪ (VNĐ)",
      toValue: "GIÁ TRỊ ĐẾN (VNĐ)",
      interestPercent: "LÃI SUẤT (%/NĂM)",
    },
    editModal: {
      modalTitle: "Sửa đổi",
      accountNum: "Số tài khoản",
      appendixNum: "Số phụ lục",
      openDate: "Ngày mở",
      capitalValue: "Giá trị",
      calcBuyCredit: "Tính sức mua",
      calcBuyCreditAccount: "TK tính sức mua",
      designationAccount: "TK chỉ định",
      extensionMethod: "Phương thức gia hạn",
      cancel: "Bỏ qua",
      agreeLiquid: "Cập nhật",
      editSuccessMsg: "Chỉnh sửa phụ lục thành công",
    },
    createContractFrameModal: {
      modalTitle: "Đăng ký dịch vụ",
      accountNum: "Số tài khoản",
      openDate: "Ngày ĐK",
      expireDate: "Ngày kết thúc",
      confirmationText:
        "Tôi đã đọc, hiểu và đồng ý với Bộ điều khoản và điều kiện sử dụng {linkHTKD}, {linkDV} và quy định tại MBS.",
      HTKD: "giao dịch eM24",
      DVCalcBuyCredit: " Dịch vụ Tính sức mua",
      cancel: "Hủy bỏ",
      agree: "Đồng ý",
      registerSuccessMsg: "Đăng ký thành công",
    },
  },
  statement: {
    errorDay: "Ngày chốt không được lớn hơn Đến ngày",
  },
  bonds: {
    cancelBuy: {
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      bondCode: "Mã trái phiếu",
      contractCode: "Số HĐ",
      buyDate: "Ngày GD mua",
      maturityDate: "Ngày kết thúc đầu tư dự kiến",
      termRate: "Lợi suất (%/năm)",
      sellVol: "KL bán",
      price: "Đơn giá",
      contractValue: "Tổng GTGD",
      cancel: "ĐĂNG KÝ HỦY",
      cancelLeft: "Yêu cầu hủy",
      cancelRight: "Hủy bán để nắm giữ đến khi đáo hạn",
      cancelButton: "Hủy bán",
    },
    manageBond: {
      createDateFrom: "Ngày GD mua từ",
      createDateTo: "Đến ngày",
      buy: "Mua",
      sell: "Bán",
      DA_GIAO_DICH_MUA: "Đã giao dịch mua",
      DA_GIAO_DICH_BAN: "Đã giao dịch bán",
      CHO_BAN: "Chờ bán",
      contractCode: "SỐ HỢP ĐỒNG",
      transType: "LOẠI GIAO DỊCH",
      vol: "KHỐI LƯỢNG (TP)",
      price: "ĐƠN GIÁ",
      contractValue: "GTGD",
      termRate: "LỢI SUẤT (%/NĂM)",
      buyDate: "NGÀY GIAO DỊCH MUA",
      finishDate: "NGÀY KT DỰ KIẾN",
      sellDate: "THỜI GIAN GIAO DỊCH",
      buyChannel: "KÊNH MUA",
      sellChannel: "KÊNH BÁN",
      accountReceiveBank: "MỞ TẠI",
      statusCode: "TRẠNG THÁI",
      accountReceiveCode: "TK nhận tiền",
    },
    buyBond: {
      rowNum: "STT",
      bondCode: "MÃ TRÁI PHIẾU",
      roomBalance: "KHỐI LƯỢNG TP CÒN LẠI",
      termRate: "LỢI SUẤT ĐÁO HẠN NET (%/NĂM)",
      maturityDate: "NGÀY ĐÁO HẠN",
      timeRemaining: "THỜI GIAN CÒN LẠI (THÁNG)",
      minInvestmentValue: "SỐ TIỀN TỐI THIỂU",
    },
    owningBond: {
      contractValue: "GIÁ TRỊ ĐẦU TƯ",
      cashNonInvestSum: "TỔNG GT ĐẦU TƯ DỰ KIẾN",
      accountReceiveCode: "TK NHẬN TIỀN",
      accountReceiveAddr: "MỞ TẠI",
      channel: "KÊNH ĐẶT LỆNH",
      cancelSell: "HỦY BÁN",
      sell: "BÁN",
    },
    sellBond: {
      HUY_DAT: "Hủy đặt",
      HUY_GIAO_DICH: "Hủy giao dịch",
      KY_THUAT: "Kỹ thuật",
      SUA_GIAO_DICH: "Sửa giao dịch",
      DA_GIAO_DICH: "Đã giao dịch",
      DA_SUA_GIAO_DICH: "Đã sửa giao dịch",
      buyPrice: "GIÁ MUA",
      buyDate: "NGÀY GIAO DỊCH",
      footerTable:
        'Quý khách sử dụng chức năng Bán trái phiếu để thay đổi thời gian đầu tư của HĐ, từ "Tới khi đáo hạn Trái phiếu" sang kỳ hạn ngắn hơn.',
    },
    infoBond: {
      issuerBond: "Tổ chức phát hành",
      bondCode: "Mã trái phiếu",
      parPrice: "Mệnh giá trái phiếu",
      bondNum: "Khối lượng phát hành",
      bondVal: "Tổng giá trị mệnh giá phát hành",
      issuePurpose: "Mục đích phát hành",
      moneyAndPay: "Đồng tiền phát hàng và thanh toán",
      VND: "Đồng Việt Nam",
      termBond: "Kỳ hạn trái phiếu",
      releaseDate: "Ngày phát hành",
      maturityDate: "Ngày đáo hạn",
      interestbonds: "Lãi suất danh nghĩa Trái Phiếu",
      applyInterest: "Lãi suất áp dụng cho ",
      firstInterest: " kỳ tính lãi đầu tiên của Trái Phiếu là ",
      year: " %/năm.",
      applyYear:
        " %/năm.Lãi suất áp dụng cho các kỳ tính lãi tiếp theo của Trái Phiếu sẽ được xác định bằng lãi suất tham chiếu cộng (+) biên độ ",
      datePayment: "Ngày thanh toán lãi",
      couponPayment1: "Tiền lãi Trái phiếu được trả sau, định kỳ mỗi ",
      couponPayment2: " tháng một lần vào ngày cuối cùng của mỗi Kỳ Tính Lãi",
      paymentMethod: "Phương thức thanh toán gốc, lãi Trái phiếu",
      typeOfBond: "Loại hình Trái phiếu",
      releaseForm: "Hình thức Trái phiếu",
      depositAgents: "Đại lý đăng ký lưu ký",
      paymentAgents: "Đại lý thanh toán",
      collateral: "Tài sản đảm bảo",
      collateralAgents: "Đại lý quản lý TSĐB",
      repreOwner: "Đại diện NSH Trái phiếu",
      issuerBond1:
        "Các điều khoản, điều kiện khác của Trái Phiếu theo thông tin nêu tại Bản Công bố thông tin sơ bộ do ",
      ocDate1: " công bố ngày ",
      ocDate2:
        " và các tài liệu khác có liên quan (nếu có) (gọi chung là 'Các Văn Kiện Trái Phiếu')",
    },
    registerBuy: {
      errorVolNull: "Vui lòng nhập khối lượng trái phiếu",
      errorVol0: "Vui lòng nhập khối lượng trái phiếu",
      EXTERNAL_ACCOUNT: "TK Ngân hàng",
      INTERNAL_ACCOUNT: "TK GDCK",
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      termRate: "Lợi suất đáo hạn NET",
      numInvestDate: "Số ngày đầu tư",
      maturityDate: "Ngày đáo hạn TP",
      roomBalance: "Khối lượng TP còn lại",
      infoTrading: "THÔNG TIN GIAO DỊCH",
      accountPay: "TK GDCK",
      cashBalance: "Số dư tiền (VND)",
      buyVolMax: "KL mua tối đa",
      buyVolMin: "KL mua tối thiểu",
      date: "Ngày đăng ký mua",
      buyDate: "Ngày giao dịch mua",
      buyVol: "Khối lượng (TP)",
      enterVol: "Nhập khối lượng",
      buyPrice: "Đơn giá giao dịch",
      buyPrice2: "Giá trị giao dịch",
      buyFee: "Phí chuyển nhượng",
      buyFee2: "Tổng giá trị giao dịch",
      termCodeFuture: "Thời gian đầu tư dự kiến",
      termDate: "Ngày kết thúc đầu tư dự kiến",
      termRate2: "Lợi suất đầu tư NET ",
      detail: "Xem chi tiết dòng tiền đầu tư dự kiến",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      accountReceiveType: "Loại TK",
      accountReceiveCode: "Số TK",
      accountName: "Tên chủ TK",
      bankName: "Mở tại",
      bankBranch: "Chi nhánh",
      infoFrontUser: "THÔNG TIN NHÂN VIÊN TƯ VẤN ",
      optional: "(KHÔNG BẮT BUỘC)",
      contractMgtCode: "Mã NV quản lý",
      enterContractMgtCode: "Nhập mã NV quản lý",
      frontUserName: "Tên NV quản lý",
      distrCustomerCode: "Mã NV tư vấn",
      enterDistrCustomerCode: "Nhập mã NV tư vấn",
      distrCustomerName: "Tên NV tư vấn",
      contractDistrCode: "Sổ HĐ NV tư vấn",
      one: "1. Các điều khoản và Điều kiện mua Trái phiếu",
      two: "2. Đề nghị chuyển nhượng mua Trái phiếu",
      confirm:
        'Tôi xác nhận và đồng ý với "Các Điều khoản và Điều kiện Mua Trái Phiếu", " Đề nghị chuyển nhượng Mua Trái phiếu" đã nêu trên và các Văn kiện Trái Phiếu liên quan',
      registerBuy: "Đăng ký mua",
    },
    investmentDetail: {
      content: "NỘI DUNG",
      payCouponDate: "NGÀY TT",
      cashNonInvest: "SỐ TIỀN DỰ KIẾN NHẬN",
      cashCouponReInvest: "SỐ TIỀN COUPON TÁI ĐẦU TƯ",
      cashReInvest: "LÃI/LỢI SUẤT",
      payCouponDate2: "NGÀY ĐẦU TƯ",
      lastPayCouponDate: "NGÀY KẾT THÚC",
      detail: "Chi tiết dòng tiền đầu tư dự kiến nhận",
      sumCashFlowNonInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowNonInvest: "Lợi suất chưa bao gồm tái đầu tư Coupon",
      flowCashReInvests: "GIẢ ĐỊNH KH TÁI ĐẦU TƯ COUPON NHẬN ĐƯỢC",
      sumCashFlowReInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowReInvest: "Lợi suất đã bao gồm tái đầu tư Coupon ",
      year: " %/năm",
      tooltip1:
        "Tái đầu tư coupon: Là việc Khách Hàng tiếp tục đầu tư khoản coupon nhận được vào các hạng mục đầu tư khác trong thời gian nắm giữ Trái Phiếu (ví dụ gửi tiết kiệm….)",
      tooltip2:
        "Lợi suất đã tái đầu tư: Là lợi suất đầu tư Trái Phiếu Khách Hàng nhận được đã bao gồm tái đầu tư coupon (với giả định Khách Hàng tiếp tục đầu tư khoản coupon với lãi suất 7.2%/năm)",
      tooltip3:
        "Là lợi suất khách hàng thực nhận được trong thời gian đầu tư (MBS đã hỗ trợ các khoản thuế/phí liên quan)",
      dateCoupon: "Ngày thanh toán lãi Coupon",
      tooltipCoupon:
        "COUPON: Là lãi Trái Phiếu (đã trừ thuế thu nhập cá nhân (nếu có)) do Tổ Chức Phát Hành thanh toán. Lãi suất coupon và cách tính lãi coupon căn cứ theo quy định của Tổ Chức Phát Hành đối với Trái Phiếu.",
    },
    referenceRate: {
      termName: "THỜI GIAN ĐẦU TƯ",
      termRate: "LỢI SUẤT (%/NĂM)",
      content: "Bảng lợi suất tham khảo",
      bondCode: "Mã trái phiếu",
      buyValue: "Giá trị đầu tư",
    },
    transferBond: {
      dear: "Kính gửi:",
      mb: " Công ty cổ phần Chứng khoán MB",
      issuerBond:
        "Sau khi thỏa thuận, Bên chuyển nhượng và Bên nhận chuyển nhượng thống nhất thực hiện việc chuyển nhượng Trái phiếu ",
      bondCode1: " - Mã số Trái Phiếu: ",
      bondCode2:
        " từ bên chuyển nhượng sang bên nhận chuyển nhượng theo thông tin được nêu dưới đây:",
      taxValue: "Thuế TNCN (do MBS chi trả)",
      taxValue2: "(Thuế TNCN tạm khấu trừ = Tổng giá trị x 0.1%)",
      content: "ĐỀ NGHỊ CHUYỂN NHƯỢNG MUA TRÁI PHIẾU",
      contentSell: "ĐỀ NGHỊ CHUYỂN NHƯỢNG BÁN TRÁI PHIẾU",
      bondCode: "Mã trái phiếu",
      assignor: "BÊN CHUYỂN NHƯỢNG",
      organizationName1: "Tên tổ chức",
      organizationName2: "CTCP Chứng khoán MB",
      registrationNumber1: "Số ĐKKD",
      registrationNumber2: "116/GP-UBCK do UBCKNN cấp ngày 09/12/2013",
      address1: "Địa chỉ",
      address2: "Tầng M-3-7, Tòa nhà MB, Số 3 Liễu Giai - Ba Đình - Hà Nội",
      representative1: "Người đại diện",
      representative2: "Ông Trần Hải Hà",
      position1: "Chức vụ",
      position2: "Tổng giám đốc",
      transferParty: "BÊN NHẬN CHUYỂN NHƯỢNG",
      fullName: "Tên cá nhân",
      identityCardId: "Số CMND/CCCD",
      issueDate1: "Ngày cấp",
      issuePlace1: "Nơi cấp",
      accountReceiveCode: "Số tài khoản",
      bankName: "Mở tại",
      transferContent: "NỘI DUNG CHUYỂN NHƯỢNG",
      buyVol: "Khối lượng",
      unit: " Trái phiếu",
      parPrice: "Mệnh giá",
      parPrice2: " VND/Trái phiếu",
      buyPrice: "Giá chuyển nhượng",
      valueBuyPrice: "Tổng giá trị chuyển nhượng",
      buyFee: "Phí chuyển nhượng",
      VND: " VND",
      textArea1: "Tổ chức (Ông/ Bà): ",
      textArea2:
        " được ghi tên trên Sổ Đăng Ký và được sở hữu, hưởng mọi quyền lợi và chịu trách nhiệm về số trái phiếu chuyển nhượng nói trên kể từ ngày có xác nhận đăng ký chuyển nhượng của Công ty Cổ phần Chứng khoán MB. Bên nhận chuyển nhượng không chịu ảnh hưởng cũng như không phải chịu trách nhiệm về bất kỳ thỏa thuận liên quan đến Trái Phiếu giữa Tổ Chức Phát Hành và/hoặc Đại Lý Đăng Ký Lưu Ký và/hoặc Bên chuyển nhượng và/hoặc bên thứ ba nào khác mà Bên nhận chuyển nhượng không được thông báo bằng văn bản, ngoại trừ Bản Công Bố Thông Tin, Hợp Đồng Đại lý Đăng Ký Lưu Ký và Hợp đồng mua bán giữa Bên chuyển nhượng và Bên nhận chuyển nhượng. ",
      textArea3:
        " ủy quyền cho Công ty Cổ phần Chứng khoán MB xác nhận đăng ký chuyển nhượng theo yêu cầu của các Nhà Đầu Tư (Bên chuyển nhượng, Bên nhận chuyển nhượng). Công ty Cổ phần Chứng khoán MB chỉ xác nhận tính hợp lệ về thủ tục và việc đăng ký quyền sở hữu trái phiếu, không xác nhận việc thanh toán giữa hai bên. Các Bên thừa nhận, trong mọi trường hợp, ",
      textArea4:
        " là đơn vị chịu trách nhiệm thanh toán gốc và/hoặc lãi Trái Phiếu cho Chủ Sở Hữu Trái Phiếu và cam đoan chịu trách nhiệm về tính đầy đủ, hợp pháp của nguồn tiền thanh toán gốc và/hoặc lãi cho Chủ Sở Hữu Trái Phiếu.",
    },
    registerSell: {
      canSell0:
        "Hợp đồng đang sửa đổi, Quý Khách chưa thể thực hiện giao dịch.",
      canSell_1:
        "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch.",
      buyValue: "Giá trị đầu tư (VND)",
      maturityDate: "Ngày kết thúc đầu tư",
      termNonInvest: "Lợi suất NET (%/năm)",
      infoSell: "THÔNG TIN GIAO DỊCH BÁN",
      date: "Ngày đề nghị bán",
      sellDate: "Ngày giao dịch bán",
      termRate: "Lợi suất (%/năm)",
      sellValue: "Tổng giá trị giao dịch (VND)",
      taxPit: "Tỷ lệ thuế TNCN",
      taxValue: "Thuế TNCN (Do MBS chi trả)",
      detail: "Xem chi tiết dòng tiền dự kiến nhận được khi bán TP",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      change: "Thay đổi",
      two: "1. Các điều khoản và Điều kiện bán Trái phiếu",
      three: "2. Đề nghị chuyển nhượng bán Trái phiếu",
      confirm:
        "Tôi xác nhận và đồng ý với Các Điều khoản và Điều kiện Bán Trái Phiếu, Đề nghị chuyển nhượng Bán Trái phiếu đã nêu trên và các Văn kiện Trái Phiếu liên quan",
      registerSell: "Đăng ký bán",
      contractCode: "Mã hợp đồng",
    },
    changeAccountBank: {
      success: "Thay đổi tài khoản nhận tiền thành công",
      changeAccountBank: "Thay đổi tài khoản nhận tiền",
    },
    tab: {
      BUYBONDS: "Mua trái phiếu",
      SELLBONDS: "Bán trái phiếu",
      OWNINGBONDS: "Sở hữu trái phiếu",
      MANAGEBONDS: "Quản lý GD trái phiếu",
      dayCareParams1: "Thời gian được thực hiện Đăng ký bán trong vòng tối đa ",
      dayCareParams2: " ngày làm việc kể từ Ngày GD mua Trái Phiếu.",
      infoTrading: "Thông tin trái phiếu giao dịch",
      registerBuy: "Đăng ký mua trái phiếu",
      registerSell: "Đăng ký bán trái phiếu ",
      cancelSell: "Hủy bán trái phiếu",
    },
    toast: {
      buySuccess: "Đăng ký mua thành công",
      cancelSellSuccess: "Hủy bán thành công",
      sellSuccess: "Đăng ký bán thành công",
    },
  },
  techAnalysis: {
    labelLeft: "Phân tích kỹ thuật",
    labelRight: "Thông tin chi tiết",
    labelDropdown: "CÀI ĐẶT CÁC KHỐI",
    tab: {
      title: "Màn",
      open: "Mở",
      rename: "Đổi tên",
      saveTheTemplate: "Lưu lại mẫu",
    },
    createBoard: {
      chooseABoard: "Chọn một bảng",
      all: "Tất cả",
      default: "Mặc định",
      customized: "Tùy chỉnh",
      newBoard: "Bảng mới",
      descNewBoard: "Thêm các khối vào bảng trống.",
      standard: "Tiêu chuẩn",
      descStandard: "Dành cho nhà giao dịch cơ bản và mới.",
      standard2: "Tiêu chuẩn 2",
      widgetsSettings: "Cài đặt các khối",
      suggestionsWidgetsSettings: "Chọn và kéo thả vào bảng",
      addDisplayArea: "Thêm vùng hiển thị",
      hoverOverTheAreaToAdd: "Di chuột đến khu vực cần thêm",
      viewDoesNotExist: "Chế độ xem không tồn tại",
    },
    modalSaveBoard: {
      titleModal: "Lưu lại bảng",
      nameBoard: "Tên bảng",
      description: "Mô tả",
      cancel: "Hủy",
      save: "Lưu",
    },
    modalRenameBoard: {
      titleModal: "Đổi tên bảng",
      ok: "OK",
      cancel: "Hủy",
    },
    widgets: {
      technicalAnalysis: "Phân tích kỹ thuật",
      priceList: "Bảng giá",
      volumeAndTime: "Khối lượng và thời gian",
      news: "Tin tức",
      orderFlowDistribution: "Phân bổ lệnh khớp",
      orderFlowDistributionUnit: "(Đơn vị: Tỷ đồng)",
      finance: "Tài chính",
      organizationalInfo: "Thông tin doanh nghiệp",
      tickerInfo: "Thông tin mã chứng khoán",
    },
  },
  changeStockStatus: {
    changeStatus: " chuyển trạng thái CK",
    shareStatus: "LOẠI CK",
    shareStatusIn: "TT NHẬN",
    shareVol: "SL CHUYỂN",
    enter: "Chuyển khoản",
    transactionDate: "NGÀY GIAO DỊCH",
    accountOut: "TK CHUYỂN",
    shareVol2: "SỐ LƯỢNG",
    shareStatusOut: "TRẠNG THÁI CHUYỂN",
    shareStatusIn2: "TRẠNG THÁI NHẬN",
    history: "Lịch sử chuyển trạng thái CK",
    changeStatusHeader: "Chuyển trạng thái",
    changeStatusSuccess: "Chuyển trạng thái chứng khoán thành công",
    bonds: "Trái phiếu",
    changeStockStatus: "Chuyển trạng thái chứng khoán",
    fromDateNull: "Quý khách chưa nhập Từ ngày",
    toDateNull: "Quý khách chưa nhập Đến ngày",
  },
  proInvestor: {
    header: {
      registrationHistory: "Lịch sử đăng ký",
      registerPro: "Đăng ký NĐT chuyên nghiệp",
    },
    noti: {
      success: "Đăng ký NĐT chuyên nghiệp thành công",
    },
    button: {
      close: "Đóng",
      search: "Tìm Kiếm",
    },
    popUp: {
      title: "Thông tin nhà đầu tư chuyên nghiệp",
      condition:
        "Theo quy định của Luật chứng khoán số 54/QH2020, từ ngày 1/1/2021, đối tượng mua Trái Phiếu phát hành riêng lẻ phải là nhà đầu tư chứng khoán chuyên nghiệp “NĐTCN”.",
      conditionPeople:
        "Với cá nhân, để là NĐTCN, KH cần đáp ứng 1 trong các tiêu chí sau:",
      coditionCategory:
        "- Sở hữu danh mục chứng khoán niêm yết, đăng ký giao dịch tại các Sở Giao dịch (Theo quy định của Luật Chứng khoán có hiệu lực ngày 1/1/2021) có giá trị tối thiểu là 02 tỷ đồng.",
      conditionFee:
        "- Có thu nhập chịu thuế năm gần nhất tối thiểu là 01 tỷ đồng theo hồ sơ khai thuế đã nộp cho cơ quan thuế hoặc chứng từ khấu trừ thuế của tổ chức, cá nhân chi trả.",
      conditonJob: "- Có chứng chỉ hành nghề chứng khoán.",
    },
    history: {
      number: "STT",
      registDate: "Ngày đăng ký",
      bondCode: "Mã trái phiếu",
      volume: "Khối lượng",
      unitPrice: "Đơn giá",
      transaction: "GT giao dịch",
      purchaseFee: "Phí mua",
      totalValue: "Tổng giá trị",
      status: "Trạng thái",
    },
    form: {
      account: "Tài khoản",
      name: "Họ tên",
      status: "Trạng thái",
      acceptCondition: "Quý KH đã đủ điều kiện trở thành NĐT CN",
      registed: "Quý KH đã đăng ký dịch vụ NĐT CN",
      noInfo: "Quý KH chưa có thông tin NĐT CN tại MBS",
      button: "Trải nghiệm NĐT CN",
    },
    modal: {
      title:
        "ĐK mua Trái phiếu niêm yết từ MBS để có đủ điều kiện xác nhận là NĐT CN:",
      idAccount: "Số tài khoản",
      regisDate: "Ngày ĐK",
      valueMine: "GT DMCK sở hữu",
      valueBone: "GT cần mua trái phiếu",
      moneyBalance: "Số dư tiền",
      codeBone: "Mã trái phiếu",
      release: "TCPH",
      info: "GT cần mua TP = 2 tỷ đồng - GT DMCK sở hữu * Tỷ lệ chiết khấu theo chính sách MBS từng thời kỳ.",
      transactionInfo: {
        title: "Thông tin giao dịch",
        dayTrading: "Ngày GD",
        price: "Đơn giá",
        volume: "Số lượng",
        valueTransaction: "Gía trị GD",
        feeTransaction: "Phí GD",
        tax: "Thuế bán + phí CK",
        sumTransaction: "Tổng tiền GD",
        account: "TK cắt tiền",
        moneyBalance: "Số dư tiền",
        buyTransaction: "GD MUA",
        sellTransaction: "GD BÁN",
      },
      footer: {
        note: "Ghi chú:",
        conditionMoney:
          "- Để trở thành NĐT CN, Quý khách cần nắm giữ danh mục CK NY từ 02 tỷ đồng.",
        conditionDate:
          "- Sau 1 ngày làm việc kể từ ngày GD Mua Trái phiếu thành công, Quý khách sẽ được MBS xác nhận là NĐT CN.",
        conditionAccept:
          "- Sau 02 ngày làm việc kể từ ngày thực hiện GD Mua thành công, hệ thống sẽ tự động Bán lại toàn bộ Trái phiếu mà Quý khách đã   Mua trong giao dịch này!",
        conditionInTime:
          "- Trong thời gian đăng ký trải nghiệm NĐTCN tại MBS, kính đề nghị Quý KH không bán chứng khoán niêm yết trên tài khoản để đảm bảo duy trì số dư khi MBS xác nhận KH đủ điều kiện trở thành NĐTCN.",
        register: "Đăng ký",
      },
      error: {
        noVolume: "Vui lòng nhập số lượng",
        min: "Vui lòng nhập số lượng",
        room: "Hiện tại đã hết Hạn mức giao dịch Nhà đầu tư chuyên nghiệp. Quý Khách vui lòng đăng ký lại sau.",
      },
      placeHolder: {
        input: "Nhập số lượng",
      },
    },
  },
  textConfirmLoanExtension:
    "Tôi đã đọc và đồng ý với các điều khoản thỏa thuận trong hợp đồng ký quỹ, phụ lục đồng và các chính sách dịch vụ tài chính đang áp dụng tại MBS",
  toast: {
    success: "Thông báo",
    warning: "Cảnh báo",
    error: "Lỗi",
    default: "Thông báo",
  },
  smartOtp: {
    alert: "Vui lòng mở ứng dụng MBS Mobile và lấy mã SmartOTP",
  },
  statusWarrant: {
    QUYEN_CHUA_THUC_HIEN: "Quyền chưa thực hiện",
    QUYEN_CHO_VE: "Quyền chờ về",
  },
  registerCKService: {
    title: "Đăng ký tài khoản dịch vụ Phái sinh",
    confirm:
      "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện về Giao dịch chứng khoán phái sinh tại MBS.",
  },
  cleanCache: {
    title: "Xóa cache",
    contentConfirm: "Bạn chắc chắn muốn xóa bộ nhớ tạm thời",
  },
  tooltipAccountReceiveCode: "Kích để thay đổi TK nhận tiền",
  modalTransferMoney: {
    sidebar: "HD chuyển tiền vào TKCK",
    headerTitle: "HƯỚNG DẪN CHUYỂN TIỀN VÀO TKCK",
    TAB_MB: "Từ NH MB",
    TAB_FAST: "Siêu nhanh - 9612",
    TAB_NORMAL: "Chuyển thông thường",
    toastSuccessNumber: "Đã sao chép số tài khoản chuyển tiền",
    toastSuccessContent: "Đã sao chép nội dung chuyển tiền",
    TabFastTextRed:
      "Quý khách có TK tại các NH khác MB/BIDV, sử dụng DV Ngân hàng điện tử trên web/app để chuyển tiền vào TKCK tại MBS 24/7, tự động hạch toán như dưới đây:",
    step1: "Bước 1:",
    TabFastStep1: "Đăng nhập app/web Ngân hàng chọn Chuyển tiền",
    TabFastStep2_1: "Thông tin Người nhận chọn:",
    TabFastStep2_2: "Ngân hàng: Đầu tư phát triển - BIDV",
    TabFastStep2_3: "Số Tài khoản: 9612+ Số TKCK 7 số (tiểu khoản tại MBS)",
    TabFastStep2_4: "Họ tên: Tự động hiển thị tên theo số TKCK nhập ở trên",
    TabFastStep2_5: "Số tiền: Nhập số tiền muốn chuyển vào TKCK",
    TabFastStep2_6: "Ví dụ: ",
    TabFastStep2_7: "Nhập Số tài khoản nhận ",
    TabFastStep2_8: "khi nộp tiền vào TKCK",
    TabFastStep2_9: "- Nộp tiền vào tài khoản thường (tiểu khoản 1): ",
    TabFastStep2_10: "- Nộp tiền vào tài khoản Margin (tiểu khoản 8): ",
    TabFastStep2_11: "- Nộp tiền vào tài khoản M-Credit (tiểu khoản 7): ",
    TabFastStep2_12: "(abcdef là số TKCK 6 số của Quý khách tại MBS)",
    step2: "Bước 2:",
    step3: "Bước 3:",
    TabFastStep3_1: "Xác nhận OTP và ấn Tiếp tục",
    step4: "Bước 4:",
    TabFastStep4_1:
      "Xác nhận OTP và ấn Tiếp tục Giao dịch thành công, TKCK nhận được tiền ngay",
    note: "Nếu Quý Khách có tài khoản tại BIDV, Quý khách chọn mục “Thanh toán”, chọn chức năng “Nộp tiền chứng khoán” và nhập thông tin thụ hưởng: Công ty CP Chứng khoán MB và số tài khoản 9612+TKCK 7 số của Quý khách như trên.",
    step5: "Bước 5:",
    TabMBTextRed:
      "Quý khách vui lòng đăng nhập vào App MBBank và làm theo hướng dẫn:",
    TabMBStep1_1: "Chọn mục Sản phẩm",
    TabMBStep2_1: "Chọn Bảo hiểm, Chứng khoán và Vay tiêu dùng",
    TabMBStep3_1: "Chọn Chứng khoán MB",
    TabMBStep4_1: "Chuyển tiền đến tài khoản MBS",
    TabMBStep5_1: "Thêm (+) Người thụ hưởng mới và nhập thông tin",
    detail: "Xem chi tiết hướng dẫn ",
    here: " tại đây",
    TabNormalTextRed:
      "Quý khách lưu ý chuyển khoản đúng nội dung hướng dẫn để tiền được hạch toán tự động vào TKCK tại MBS",
    left: "Người thụ hưởng",
    right: "Công ty CP Chứng khoán MB",
    title: "Chuyển khoản tới một trong các tài khoản sau",
    bottom1: "MBBank - Sở Giao dịch 2",
    bottom2: "MBBank - CN Hàm Nghi",
    bottom3: "Vietcombank - CN Hà Nội",
    bottom4: "BIDV - CN Hà Thành",
    bottom5: "Techcombank - CN Hội Sở",
    content: "NỘI DUNG CHUYỂN KHOẢN",
    top1: "Nộp tiền vào TK thường (đuôi 1)",
    top8: "Nộp tiền vào TK Margin (đuôi 8)",
    top7: "Nộp tiền vào TK Margin (đuôi 7)",
    bottom6: "Nop tien TKCK ",
    bottom7: "1 cua ",
    bottom8: "8 cua ",
    bottom9: "7 cua ",
  },
  saveQRSuccess: "Quét QR code thành công",
  saveOtpSuccess: "Đã lưu mã xác thực",
  smartOTP: {
    success: "Gửi mã OTP thành công",
    title1: "Quý khách vui lòng ",
    title2: "Lấy mã OTP",
    title3: " qua sms/email và lưu tại ô dưới đây",
    title4: "Quý khách đã lấy OTP thành công, vui lòng kiểm tra sms/email.",
  },
  industry: {
    name: "Chuyển động ngành",
    topIndex: "TOP đóng góp INDEX",
    transactionIndustry: "GD theo ngành",
  },
  foreignWatch: {
    topStocks: "TOP cổ phiếu mua bán ròng",
    topIndustry: "TOP ngành mua bán ròng",
  },
  modalSell: {
    shareStatus: "Trạng thái CK",
    shareOddLot: "CP lẻ",
    sellValue: "Giá trị bán",
    confirm1: "Tôi đã đọc và đồng ý với ",
    confirm2: "các điều khoản và điều kiện",
    confirm3:
      " về Giao dịch lô lẻ trực tuyến của MBS và xác nhận đăng ký dịch vụ.",
    note: "Ghi chú: giao dịch lô lẻ với giá sàn, phí dịch vụ 10% tổng giá trị giao dịch từ 23/07/2021",
  },
  modalSVD: {
    title1: "Trân trọng chào mừng Quý Khách hàng đến với MBS!",
    title2:
      "Kính chúc Quý khách có trải nghiệm giao dịch vượt trội và nhận được những ưu đãi hấp dẫn.",
    acceptText1: "Tôi đã đọc, hiểu và đồng ý với",
    acceptText2: " Bộ các điều khoản và điều kiện ",
    acceptText3: "Mở tài khoản và sửa dụng dịch vụ tại MBS",
    onSubmit: "TIẾP TỤC",
  },
  copi24: {
    tab: {
      TOPLEADER: "Top Leader",
      FOLLOW: "Theo dõi",
      MANAGECOPY: "Quản lý sao chép",
      HISTORY: "Lịch sử đầu tư",
    },
    modalCreateCashPayment: {
      valueAmount0: "Số tiền nộp phải > 0",
      cashBalance: "Số tiền nộp không được vượt quá Số dư tài khoản Cash",
      cashPayment: "Nộp tiền",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      amount: "Số tiền nộp",
      placeholder: "Nhập số tiền",
      noteRed:
        "Ghi chú: Sau khi nộp thêm tiền, đầu ngày giao dịch tiếp theo hệ thống sẽ tự động renew lại danh mục theo tỷ trọng Leader.",
      cancel: "Hủy",
    },
    modalCreateCashWithdraw: {
      valueAmount0: "Số tiền rút phải > 0",
      maximumCashWithdraw0: "Lớn hơn số tiền rút tối đa",
      cashWithdraw: "Rút tiền",
      account: "Tài khoản Copi24",
      totalAsset: "Giá trị tài sản",
      maximumCashWithdraw: "Số tiền rút tối đa",
      amount: "Số tiền rút",
      placeholder: "Nhập số tiền",
      cancel: "Hủy",
    },
    modalCreateCopy: {
      valueAmount0: "Giá trị sao chép phải > 0",
      cashBalance: "Giá trị sao chép không được vượt quá Số dư tài khoản Cash",
      COPY_MIN_AMOUNT0:
        "Giá trị sao chép không được nhỏ hơn giá trị sao chép tối thiểu",
      secPerNavRate0: "Bạn chưa nhập Tỷ trọng tối đa 1 mã",
      priceMaxPercent0: "Bạn chưa nhập Giá đặt chênh lệch tối đa",
      stopLossPercent0: "Bạn chưa nhập Danh mục lỗ",
      stopProfitPercent0: "Bạn chưa nhập Danh mục lãi",
      optionPriceType1: "Lệnh thị trường",
      optionPriceType2: "Lệnh LO",
      rule: "Quy tắc sao chép",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      COPY_MIN_AMOUNT: "Giá trị sao chép tối thiểu",
      amount: "Giá trị sao chép (VND)",
      ALLOW_CHOOSE_PRICE_TYPE: "Nguyên tắc giá sinh lệnh",
      priceBuyPercent: "Chênh lệch giá MUA (%)",
      priceSellPercent: "Chênh lệch giá BÁN (%)",
      secPerNavRate: "Tỷ trọng tối đa 1 mã (%)",
      textLine1: "Lệnh sao chép phát sinh khi",
      textLine2: "Lệnh Leader khớp",
      isCopyCurrentPortfolio: "Sao chép DM hiện tại",
      stop: "Điều kiện lệnh dừng sao chép",
      stopLossPercent: "Danh mục lỗ (%)",
      stopProfitPercent: "Danh mục lãi (%)",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      cancel: "Hủy",
      copy: "Sao chép",
    },
    modalStopCopy: {
      title: "Dừng sao chép",
      account: "Tài khoản Copi24",
      amount: "Giá trị sao chép",
      profitAmt: "Lãi/lỗ",
      feeCopy: "Phí sao chép",
      total: "Tổng giá trị",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      confirm: "Xác nhận chuyển toàn bộ danh mục sang tài khoản 1",
      cancel: "Hủy",
      stop: "Dừng sao chép",
    },
    modalUpdateAvatar: {
      title: "Cập nhật ảnh đại diện",
      error: "Ảnh đại diện của bạn không được vượt quá xxx Kbs",
      cancel: "Đóng",
      confirm: "Xác nhận",
    },
    modalCancelCopier: {
      title: "Đóng COPIER",
      confirm: "Bạn có chắn chắn muốn đóng Copier?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    modalCancelLeader: {
      toastSuccess:
        "Yêu cầu đóng leader đã được gửi tới hệ thống. Yêu cầu của quý khách sẽ được xử lý trong {day} ngày tới",
      title: "Đóng LEADER",
      confirm: "Bạn có chắn chắn muốn đóng Leader?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    cardUser: {
      month: " /tháng",
      month6: " trong 6 tháng",
      portfolioPoint: "Điểm danh mục: ",
      totalFollow: "Tổng theo dõi:",
      totalCopy: "Tổng sao chép:",
      copy: "Sao chép",
      stop: " Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    topLeader: {
      topLeader: "TOP LEADER",
      registerCopier: "Đăng ký COPIER",
      cancelCopier: "Đóng COPIER",
      delay: "Chờ xử lý đóng",
      registerLeader: "Đăng ký LEADER",
      cancelLeader: "Đóng LEADER",
    },
    registerCopier: {
      registerCopier: "Đăng ký COPIER",
      customerId: "Tài khoản",
      confirm:
        "Tôi đã đọc và đồng ý với các điều khoản điều kiện đăng ký tham gia COPIER...,",
      cancel: "Hủy",
    },
    registerLeader: {
      disableButtonRegister: " của tài khoản không đáp ứng giá trị tối thiểu",
      registerLeader: "Đăng ký LEADER",
      accountRegister: "Tài khoản đăng ký ban đầu:",
      confirm:
        "Tôi đã đọc và đồng ý với các điều khoản điều kiện đăng ký tham gia LEADER...,",
    },
    tableRegisterLeader: {
      navValue: "Tài sản ròng",
      tradeMonthNo: "Số tháng đã giao dịch",
      tradingDiaryRate1: "Hiệu quả đầu tư (",
      tradingDiaryRate2: " tháng)",
      portfolioPoint: "Điểm số danh mục",
      title: "TIÊU CHÍ",
      valueMin: "GIÁ TRỊ TỐI THIỂU",
      valueAccount: "GIÁ TRỊ CỦA TÀI KHOẢN",
    },
    topFollow: {
      listTopFollow: "DANH SÁCH THEO DÕI",
    },
    manager: {
      total_amount: "Tổng giá trị đầu tư: ",
      total_profit_amt: "Lãi/lỗ:",
      total_value: "Tổng giá trị:",
      leaderAccoStat: "Tài khoản leader đã đóng hoặc đang xử lý đóng",
      amount: "Giá trị đầu tư",
      nav: "NAV",
      profitAmt: "Lãi/lỗ",
      profitPercent: "% lãi/lỗ",
      totalFeeDebt: "Phí sao chép",
      createCashPayment: " Nộp tiền",
      withdrawMoney: "Rút tiền",
      stopCopy: "Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    history: {
      tradeDate: "NGÀY SAO CHÉP",
      leader: "LEADER",
      amount: "GIÁ TRỊ ĐẦU TƯ",
      profitAmt: "LÃI LỖ",
      profitPercent: "% LÃI LỖ",
      stopDate: "NGÀY DỪNG SAO CHÉP",
    },
    portTransaction: {
      tradeDate: "NGÀY GIAO DỊCH",
      secCd: "MÃ CK",
      tradeType: "LOẠI GD",
      profitAmt: "LÃI LỖ",
      profitPercent: "% LÃI LỖ",
      qty: "KHỐI LƯỢNG",
      price: "GIÁ",
      avgPrice: "GIÁ VỐN",
      feeAmt: "PHÍ",
      taxAmt: "PHÍ THUẾ KHÁC",
      history: "Lịch sử giao dịch",
      remarks: "GHI CHÚ",
      ratio: "TỈ TRỌNG",
      totalQty: "TỔNG",
      availableQty: "KHẢ DỤNG",
      waitingReceive: "CHỜ NHẬN",
      avgPrice2: "GIÁ TB",
      currentPrice: "GIÁ HIỆN TẠI",
      buyAmt: "GIÁ TRỊ MUA",
      currentAmt: "GIÁ TRỊ HIỆN TẠI",
      tabRule1: "DANH MỤC SAO CHÉP",
      tabRule2: "QUY TẮC SAO CHÉP",
      priceMaxPercent: "Giá Mua chênh tối đa với DM hiện tại (%)",
      tradeDate2: "Ngày sao chép",
    },
  },
  marginContract: {
    tab: "Thông tin hợp đồng Margin",
    info: {
      subAccoNo: "Tiểu khoản",
      contrNo: "Số hợp đồng",
      custGroupName: "Nhóm Margin",
      retentionRate: "TL duy trì",
      loanDays: "Số ngày vay",
      warningDays: "Số ngày cảnh báo",
      contrDateFrom: "Ngày bắt đầu",
      warningRate: "TL cảnh báo",
      freeDays: "Số ngày miễn phí",
      callRate: "TL giải chấp",
      tnType: "Loại nợ",
      sourceLoan: "Nguồn",
      brokerCd: "NVCS",
      marginLimit: "Hạn mức Margin",
      marginLimitGrp: "HM nhóm",
    },
    filter: {
      account: "Tiểu khoản",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      status: {
        title: "Trạng thái",
        all: "Tất cả",
        notApprove: "Chưa duyệt",
        approved: "Đã duyệt",
        approveWaitEdit: "Sửa chờ duyệt",
        edited: "Đã sửa",
        closed: "Đã đóng",
      },
      contractState: {
        title: "Trạng thái HĐ",
        all: "Tất cả",
        notActive: "Chưa kích hoạt",
        actived: "Kích hoạt",
      },
      broker: "NVCS",
      search: "Tìm",
    },
    table: {
      alloDate: "Ngày tạo",
      refNo: "Số TT",
      contrNo: "Số HĐ",
      subAccoNo: "Tiểu khoản",
      custName: "Tên KH",
      marginGroupCode: "Mã nhóm",
      retentionRate: "TL duy trì",
      warningRate: "TL cảnh báo",
      callRate: "TL giải chấp",
      loanDays: "Số ngày vay",
      freeDays: "Số ngày miễn phí",
      warningDays: "Số ngày cảnh báo",
      tnType: "Loại nợ",
      sourceLoan: "Nguồn",
      brokerCd: "NVCS",
      contrDateFrom: "Ngày bắt đầu",
      status: {
        title: "Trạng thái",
        notApprove: "Chưa duyệt",
        approved: "Đã duyệt",
        approveWaitEdit: "Sửa chờ duyệt",
        edited: "Đã sửa",
        closed: "Đã xóa",
      },
      contractState: {
        title: "Trạng thái HĐ",
        notActive: "Chưa kích hoạt",
        actived: "Kích hoạt",
      },
      transactionCd: "Điểm giao dịch",
      remarks: "Ghi chú",
      regDateTime: "Thời gian tạo",
      regUserId: "Người tạo",
      updDateTime: "TG cập nhật",
      updUserId: "Người cập nhật",
    },
  },
  accountInfo: {
    tab: "Thông tin tài khoản",
    info: {
      custName: "Tên khách hàng",
      telNo: "Điện thoại",
      address: "Địa chỉ",
      mobileNo: "Di động",
      idNumber: "Số CMND",
      emailAdrs: "Email",
      issueDate: "Ngày cấp",
      issueLocation: "Nơi cấp",
      expireDate: "Ngày hết hạn",
      accoStrDate: "Ngày mở tài khoản",
      subAccoNo: "Ngày mở tài khoản",
      bankCd: "Lần truy cập gần nhất",
      bankAccoNumber: "Số tài khoản ngân hàng	",
      lastAccess: "Ngân hàng",
    },
    filter: {
      account: "Tài khoản",
      search: "Vấn tin",
    },
    table: {
      title: "Thông tin ủy quyền",
      entrustCustName: "Tên",
      adrs1: "Địa chỉ",
    },
  },
  marginSecurities: {
    tabName: "Danh mục chứng khoán Margin",
    filter: {
      subAccount: "Tiểu khoản",
      custGroup: "Nhóm khách hàng",
      secCd: "Mã CK",
      all: "Tất cả",
      defaultCustGroup: "Danh mục chung",
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      estimateRate: "TL định giá",
      marginRate: "TL ký quỹ",
      mLoanRate: "TL vay margin",
      minPrice: "Giá tối thiểu",
      maxPrice: "Giá tối đa",
      marketName: "Sàn",
      updDateTime: "Thời gian cập nhật",
    },
  },
  rightInfo: {
    tab: "Thông tin thực hiện quyền",
    filter: {
      secCd: "Mã CK",
      rightType: {
        title: "Loại THQ",
        all: "Tất cả",
        cashDevidend: "Tiền mặt",
        stockDividend: "Cổ tức CP",
        stockBonus: "Cổ phiếu thưởng",
        rightRegister: "Đăng ký PHT",
        bondConvertion: "Trái phiếu chuyển đổi",
        stockMerge: "Hoán đổi cổ phiếu",
        stockSplit: "Chia tách",
        delisting: "Hủy đăng ký",
        changeSecuritiesType: "Thay đổi loại hình",
        depositoryRegistration: "Đăng ký lưu ký",
        votes: "Biểu quyết",
        returnCapital: "Hoàn trả góp vốn",
        bondExpires: "Đáo hạn trái phiếu",
        buyCapital: "Mua lại vốn góp của TCPH",
        buyBondConvertion: "Quyền mua trái phiếu chuyển đổi",
      },
      ownerFixDate: "Ngày chốt",
      rightExcDate: "Ngày thực hiện",
      beginTradeDate: "Ngày GD",
      status: {
        title: "Trạng thái",
        all: "Tất cả",
        pending: "Chưa duyệt",
        approved: "Đã duyệt",
        deleted: "Đã xóa",
      },
      search: "Tìm",
    },
    table: {
      secCd: "Mã CK",
      marketSname: "Sàn",
      noRightDate: "Ngày KHQ",
      ownerFixDate: "Ngày chốt",
      rightExcDate: "Ngày TT",
      beginTradeDate: "Ngày GD",
      ownerListPeportDate: "Hạn nộp DS",
      rightRate: "Tỉ lệ quyền",
      rate: "Tỉ lệ",
      rightType: {
        title: "Loại THQ",
        cashDevidend: "Tiền mặt",
        stockDividend: "Cổ tức CP",
        stockBonus: "Cổ phiếu thưởng",
        rightRegister: "Đăng ký PHT",
        bondConvertion: "Trái phiếu chuyển đổi",
        stockMerge: "Hoán đổi cổ phiếu",
        stockSplit: "Chia tách",
        delisting: "Hủy đăng ký",
        changeSecuritiesType: "Thay đổi loại hình",
        depositoryRegistration: "Đăng ký lưu ký",
        votes: "Biểu quyết",
        returnCapital: "Hoàn trả góp vốn",
        bondExpires: "Đáo hạn trái phiếu",
        buyCapital: "Mua lại vốn góp của TCPH",
        buyBondConvertion: "Quyền mua trái phiếu chuyển đổi",
      },
      status: {
        title: "Trạng thái",
        pending: "Chưa duyệt",
        approved: "Đã duyệt",
        deleted: "Đã xóa",
      },
      beginRegDate: "Ngày BĐ ĐK",
      endRegDate: "Ngày KT ĐK",
      beginTransDate: "Ngày BĐ CN",
      endTransDate: "Ngày KT CN",
      price: "Giá PH",
      taxRate: "Thuế TNCN",
      secState: {
        title: "Loại CK",
        all: "Tất cả",
        popularity: "Phổ thông",
        restricted: "Hạn chế",
      },
      targetSecCd: "Mã CK chuyển",
      remarks: "Diễn giải",
      alloDate: "Ngày tạo",
      refNo: "STT",
      regDateTime: "Ngày tạo",
      regUserId: "Người tạo",
      approvedDateTime: "Ngày duyệt",
      approvedUserId: "Người duyệt",
    },
  },
  statisticOnday: {
    tabs: {
      statisticOndayTab: "Thống kế giao dịch",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      tradeDate: {
        label: "Ngày GD",
      },
      type: {
        label: "Thống kê theo",
        quantity: "Khối lượng giao dịch",
        value: "Giá trị giao dịch",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      matAmt: "GTGD",
      highestPrice: "Giá cao nhất",
      lowestPrice: "Giá thấp nhất",
    },
    exportExcel: "In dạng Excel",
  },
  statisticPrice: {
    tabs: {
      statisticPriceTab: "Thống kế CK trần/sàn",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      type: {
        label: "Thống kê theo",
        floorPrice: "Giá sàn",
        ceilingPrice: "Giá trần",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      matAmt: "GTGD",
      best1OfferPrice: "Giá dư bán",
      best1BidPrice: "Giá dư mua",
    },
    exportExcel: "In dạng Excel",
  },
  statisticUpdown: {
    tabs: {
      statisticUpdownTab: "Thống kế CK tăng/giảm",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      type: {
        label: "Tỷ lệ",
        up: "Tăng",
        down: "Giảm",
      },
      fromDate: {
        label: "Từ ngày",
      },
      toDate: {
        label: "Đến ngày",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      updownPercent: "Tỷ lệ tăng giảm",
      updownValue: "Giá trị tăng giảm",
      beginClosedPrice: "Giá đóng cửa ngày bắt đầu",
      endClosedPrice: "Giá đóng cửa ngày kết thúc",
    },
    exportExcel: "In dạng Excel",
  },
  statisticHighestUpdown: {
    tabs: {
      statisticHighestUpdownTab: "Thống kế CK tăng/giảm cao nhất",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      type: {
        label: "Tỷ lệ",
        up: "Tăng",
        down: "Giảm",
      },
      distance: {
        label: "Số ngày GD gần nhất",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      matAmt: "GTGD",
      updownPercent: "Tỷ lệ tăng/giảm",
      beginClosedPrice: "Giá đóng cửa ngày bắt đầu tính",
    },
    exportExcel: "In dạng Excel",
  },
  mrgContractDebit: {
    tabs: {
      mrgContractDebitTab: "Theo dõi công nợ Margin",
    },
    filter: {
      accNo: {
        label: "Tiểu khoản",
        all: "Tất cả",
      },
      debitType: {
        label: "Loại vay",
        all: "Tất cả",
        debitType1: "Margin",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        status1: "Còn nợ",
        status2: "Sắp đến hạn",
        status3: "Đến hạn",
        status4: "Quá hạn",
        status5: "Đã trả hết nợ",
        status6: "Đã gia hạn",
      },
      search: "Tìm kiếm",
    },
    table: {
      contrDateFrom: "Ngày vay",
      contrDateTo: "Ngày hết hạn",
      subAccoNo: "Tiểu khoản",
      custName: "Tên KH",
      debitTypeName: "Loại vay",
      amount: "Tổng nợ",
      payedAmount: "Đã trả",
      pendingPayment: "Trả nợ chờ duyệt",
      currentDebit: "Còn nợ",
      payedInterest: "Lãi đã trả",
      todayInterest: "Lãi đến ngày hiện tại",
      endDayInterest: "Lãi đến hạn dự kiến",
      remainDays: "Số ngày còn lại",
      inRate: "Lãi suất trong hạn",
      outRate: "Lãi suất quá hạn",
      payedManager: "Phí QL đã trả",
      todayManager: "Phí QL đến ngày",
      managerRate: "Phí QL trong hạn",
      managerOutRate: "Phí QL quá hạn",
      initConfigId: "Mã lãi bậc thang",
      extendCount: "Số lần gia hạn",
      brokerCd: "Broker",
      branchSName: "Chi nhánh",
      placeOrder: "Đặt lệnh",
    },
  },
  marginContractWatch: {
    tabs: {
      marginContractWatchTab: "Theo dõi tài sản Margin",
    },
    filter: {
      accNo: {
        label: "Tiểu khoản",
        all: "Tất cả",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        status1: "Cảnh báo",
        status2: "Xử lý",
        status3: "Dừng kích hoạt",
        status4: "Nợ quá hạn",
      },
      brokerCd: {
        label: "NVCS",
      },
      search: "Tìm kiếm",
    },
    table: {
      subAccNo: "Tiểu khoản",
      custName: "Tên KH",
      detail: "Chi tiết",
      totalAssetMargin: "Tổng tài sản Margin",
      realAsset: "Tài sản thực",
      totalDebtAndInt: "Tổng nợ + lãi",
      secValNeedSell: "GT CK cần bán",
      balanceValAdd: "GT Tiền bổ sung",
      secValAdd: "GT CK bổ sung",
      retentionRate: "Tỷ lệ duy trì",
      warningRate: "Tỷ lệ cảnh báo",
      callRate: "Tỷ lệ giải chấp",
      warningDays: "Số ngày cảnh báo liên tiếp",
    },
  },
  transferLocal: {
    tabs: {
      transferLocalTab: "Chuyển khoản nội bộ",
      transferRequest: "Yêu cầu chuyển khoản",
      transferHistory: "Lịch sử chuyển khoản",
    },
    transferLocalTab: {
      filter: {
        fromSubAccoNo: {
          label: "Tài khoản chuyển",
        },
        toSubAccoNo: {
          label: "Tài khoản nhận",
        },
        status: {
          label: "Trạng thái",
          all: "Tất cả",
          status0: "Chờ duyệt",
          status1: "Đã duyệt",
          status2: "Hạch toán nhận chờ duyệt",
          status3: "Hoàn thành",
          status9: "Hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        editDelete: "Sửa/Hủy",
        alloDate: "Ngày YC",
        refNo: "Số YC",
        fromSubAccoName: "Tên Tài khoản chuyển",
        fromSubAccoNo: "Tài khoản chuyển",
        toSubAccoNo: "TK nhận",
        toSubAccoName: "Tên Tài khoản nhận",
        amount: "Số tiền chuyển",
        feeAmount: "Phí CK",
        status: "Trạng thái",
        feeRate: "Tỉ lện phí",
        feeMin: "Phí CK tối thiểu",
        transferFeeFlg: "Cách tính phí",
        transactionName: "Chi nhánh",
        regUserId: "Người tạo",
        regDateTime: "Thời gian tạo",
      },
    },
    transferLocalHisTab: {
      filter: {
        fromDate: {
          label: "Từ ngày",
        },
        toDate: {
          label: "Đến ngày",
        },
        toSubAccoNo: {
          label: "Tài khoản nhận",
        },
        status: {
          label: "Trạng thái",
          all: "Tất cả",
          status0: "Chờ duyệt",
          status1: "Đã duyệt",
          status2: "Hạch toán nhận chờ duyệt",
          status3: "Hoàn thành",
          status9: "Hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        alloDate: "Ngày YC",
        refNo: "Số YC",
        fromSubAccoName: "Tên Tài khoản chuyển",
        fromSubAccoNo: "Tài khoản chuyển",
        toSubAccoNo: "TK nhận",
        toSubAccoName: "Tên Tài khoản nhận",
        amount: "Số tiền chuyển",
        feeAmount: "Phí CK",
        status: "Trạng thái",
        feeRate: "Tỉ lện phí",
        feeMin: "Phí CK tối thiểu",
        transferFeeFlg: "Cách tính phí",
        transactionName: "Chi nhánh",
        regUserId: "Người tạo",
        regDateTime: "Thời gian tạo",
      },
    },
    feePayFlag: {
      feeTypeRate: "Theo tỉ lệ",
      feeTypeConst: "Phí cố định",
    },
    message: {
      fromDateRequired: "Vui lòng nhập từ ngày",
      toDateRequired: "Vui lòng nhập đến ngày",
      invalidStatus: "Trạng thái không hợp lệ",
      checkTransferFlagEdit:
        "Bạn không có quyền sửa giao dịch bổ sung tài sản margin",
      amountCheck2: "Số tiền phải lớn hơn 0",
      amountCheck3: "Số tiền chuyển phải lớn hơn số tiền tối thiểu",
      amountCheck4: "Số tiền chuyển phải nhỏ hơn số tiền tối đa",
      transferFreeCheck1: "Phí phải là số",
      dialogCheckAvail: "Khả dụng của bạn không đủ để chuyển khoản",
    },
    detail: {
      accountFrom: "Tài khoản chuyển",
      accountTo: "Tài khoản nhận",
      amount: "Số tiền chuyển",
      fee: "Phí",
      remarks: "Ghi chú",
      balance: "Số dư tiền",
      transferLimit: "Khả dụng CK tối đa",
      withdrawableCash: "Khả dụng rút tiền mặt",
      withdrawableAsset: "Khả dụng rút theo TS",
      startTime: "Thời gian bắt đầu",
      endTime: "Thời gian kết thúc",
      minAmount: "Số tiền tối thiểu",
      maxAmount: "Số tiền tối đa",
      transferFeeRate: "Tỉ lệ phí",
      transferFeeMin: "Phí CK tối thiểu",
      transferFee: "Cách tính phí",
      transferFeeFlag: "Cách tính phí",
      accept: "Chấp nhận",
      reset: "Nhập lại",
    },
    confirmModal: {
      title: "Xác nhận chuyển khoản nội bộ",
      accountFrom: "Tài khoản chuyển",
      accountTo: "Tài khoản nhận",
      amount: "Số tiền chuyển",
      amountText: "Bằng chữ",
      fee: "Phí",
      feeSms: "Phí SMS",
      remarks: "Ghi chú",
      notes:
        "Không hiển thị lại vào lần sau, vui lòng kiểm tra lại chi tiết xác nhận.",
      accept: "Chấp nhận",
      cancel: "Hủy",
    },
  },
  contractDebit: {
    tabs: {
      loanTrackingTab: "Theo dõi dư nợ",
      paymentHistoryTab: "Lịch sử trả nợ",
      extendHistoryTab: "Lịch sử gia hạn",
    },
    loanTrackingTab: {
      filter: {
        account: "Tiểu khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",

        status: {
          label: "Trạng thái",
          all: "--Tất cả--",

          status1: "1. Còn nợ  ",
          status2: "2. Sắp đến hạn  ",
          status3: "3. Đến hạn  ",
          status4: "4. Quá hạn  ",
          status5: "5. Đã trả hết nợ  ",
          status6: "6. Đã gia hạn  ",
        },
        search: "Tìm kiếm",
      },

      table: {
        contrDateFrom: "Ngày vay",
        contrDateTo: "Ngày hết hạn",
        amount: "Số tiền vay",
        payedAmount: "Đã trả",
        currentDebit: "Còn nợ",
        payedInterest: "Lãi đã trả",
        todayInterest: "Lãi đến hiện tại",
        endDayInterest: "Lãi đến hạn dự kiến",
        extendCount: "Số lần gia hạn",
        status: "Trạng thái",
        button: {
          debtPayment: "Trả nợ",
          loanExtension: "Gia hạn",
        },
      },
      confirmModal: {
        title: "Thanh toán nợ vay",
        subAccount: "Tiểu khoản",
        contrDateFrom: "Ngày vay",
        contrDateTo: "Ngày hết hạn",
        currentDebit: "Còn nợ",
        interest: "Tiền lãi",
        fee: "Phí quản lý",
        amount: "Giá trị thanh toán",
        availableBalance: "Khả dụng thanh toán",
        content: "Nội dung",
        cancel: "Hủy",
        payment: "Xác nhận",
        loanExtend: "Gia hạn",
        title2: "Gia hạn khoản vay",
        totalLoanBLN: "Tổng nợ",
        fee2: "Phí gia hạn",
        extendDays: "Số ngày gia hạn",
        contentRemark: "{accoNo}  thanh toán khoản vay ngày {date}",
        contentConfirm: "Bạn muốn thực hiện trả nợ cho hợp đồng đã chọn ?",
        contentConfirm2: "Bạn chắc chắn muốn gia hạn món vay ?",
      },
    },
    paymentHistoryTab: {
      filter: {
        account: "Tiểu khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",

        status: {
          label: "Trạng thái",
          all: "Tất cả",

          status1: "Chờ duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        subAccount: "Tiểu khoản",
        deliverDate: "Ngày giải ngân",
        loanBalance: "Nợ gốc",
        paymentDate: "Ngày trả",
        amount: "Trả nợ",
        interestRate: "Trả lãi",
        fee: "Trả phí",
        numDaysInterest: "Số ngày tính lãi",
        statusList: {
          status1: "Chưa duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
        status: "Trạng thái",
        cancel: "Hủy",
      },
    },
    extendHistoryTab: {
      filter: {
        account: "Tiểu khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",

        status: {
          label: "Trạng thái",
          all: "Tất cả",

          status1: "Chờ duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        cancel: "Hủy",
        subAccount: "Tiểu khoản",
        deliverDate: "Ngày giải ngân",
        expireDate: "Ngày hết hạn",
        pproveDate: "Ngày gia hạn",
        newExpireDate: "Ngày hết hạn mới",
        loanBalance: "Nợ gốc",
        amount: "Số tiền gia hạn",
        fee: "Phí gia hạn",
        extendCount: "Số lần gia hạn",
        status: "Trạng thái",
        statusList: {
          status1: "Chưa duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
      },
    },

    message: {
      fromDateRequired: "Vui lòng nhập từ ngày",
      toDateRequired: "Vui lòng nhập đến ngày",
      amountCheck1: "Chưa nhập giá trị thanh toán",
      amountCheck2: "Giá trị thanh toán phải lớn hơn 0",
      debtPaymentSucesss: "Trả nợ thành công",
      loanExtendSuccess: "Gia hạn thành công",
      errorAvaiableBalance: "Khả dụng không đủ để gia hạn",
    },

    confirmModal: {
      accept: "Chấp nhận",
      cancel: "Hủy",
    },
  },
  ekyc: {
    title: "MỞ TÀI KHOẢN TRỰC TUYẾN",
    step0: {
      title:
        "Để thực hiện đăng ký, Quý khách vui lòng chuẩn bị các thông tin sau:",
      label1: "CMND hoặc CCCD còn hiệu lực theo quy định.",
      label2: "Máy tính hoặc Điện thoại có Camera.",
      label3: "Điện thoại di động để nhận mã xác thực (OTP).",
      note: "Trình duyệt khuyến nghị: Chrome (Window/Android/MacOS version 10.0.0 trở lên); Safari (iPhone/iPad version 12.4.1 trở lên)",
      start: "Bắt đầu",
      tutorial: "Xem hướng dẫn mở tài khoản",
    },
    step1: {
      label1: "Quý khách là nhà đầu tư ?",
      individual: "Cá nhân",
      organization: "Tổ chức",
      label2: "Quốc tịch của Quý khách ?",
      vn: "Việt Nam",
      foreign: "Nước ngoài",
      label3: "Chọn loại tài khoản",
      cash: "Tài khoản thường (Cash)",
      margin: "Tài khoản thông thường (Cash) và ký quỹ (Margin)",
      label4: "Nhân viên quản lý tài khoản",
      no: "Không",
      yes: "Có",
      brokerId: "Mã NV",
      brokerName: "Tên NV",
      next: "Tiếp tục",
    },
    step2: {
      phoneNull: "Quý khách chưa nhập Số điện thoại",
      emailNull: "Quý khách chưa nhập Email",
      phoneErr: "Số điện thoại không hợp lệ",
      emailErr: "Địa chỉ email không hợp lệ",
      title:
        "Thông tin quan trọng dùng để mở tài khoản và xác thực giao dịch của Quý khách trong tương lai.",
      inputNumberPhone: "Điện thoại di động",
      placeholderNumberPhone: "Nhập số điện thoại, VD: 098xxxxxxxxx",
      placeholderEmail: "Nhập địa chỉ email",
      resCheckNumberPhone:
        "Số điện thoại đã được sử dụng đăng ký tài khoản tại GMC.Quý khách có muốn tiếp tục đăng ký tài khoản?",
      resCheckEmail:
        "Email đã được sử dụng đăng ký tài khoản tại GMC.Quý khách có muốn tiếp tục đăng ký tài khoản?",
    },
    step3: {
      confirmCodeErr: "Quý khách chưa nhập mã xác thực",
      label1: "Quý khách vui lòng nhập mã OTP đã được gửi về số điện thoại ",
      label2: "Mã xác thực đã gửi có hiệu lực trong ",
      placeholder: "Nhập mã xác thực (OTP)",
      sendOTPAgain: "Gửi lại mã",
      otpFalse: "Mã OTP không hợp lệ. Quý khách vui lòng kiểm tra lại!",
    },
    step4: {
      imageNull: "Quý khách vui lòng chụp đủ 02 mặt CMND/CCCD !",
      label1: "Chụp/Tải ảnh mặt trước và mặt sau CMND/CCCD.",
      cmndFront: "CMND/CCCD MẶT TRƯỚC",
      cmndBack: "CMND/CCCD MẶT SAU",
      noMediaDevices:
        "Thiết bị bạn đang sử dụng không được hỗ trợ, vui lòng thử lại trên smartphone !",
      isPermissionDenied: "Quyền truy cập camera bị từ chối",
      openCamera: "Mở Camera",
      upload: "Chụp/ Tải ảnh",
      resCheckIdCode:
        "CMND/ CCCD đã có tài khoản tại GMC. Quý khách vui lòng liên hệ tổng đài để được trợ giúp. Trân trọng cảm ơn!",
      valid_date:
        "CMND/CCCD của Quý khách đã hết hạn, vui lòng liên hệ tổng đài để được hỗ trợ. Trân trọng cảm ơn!",
      birth_day:
        "Theo quy định pháp luật, độ tuổi được phép mở TKCK  từ 18 tuổi trở lên",
    },
    step5: {
      imageErr: "Ảnh khuôn mặt không hợp lệ",
      label1: "Xác thực khuôn mặt",
      resFaceLiveness:
        "Khuôn mặt xác thực không hợp lê. Quý khách vui lòng thực hiện lại!",
      resFaceCompare:
        "Khuôn mặt không khớp trên giấy tờ. Quý khách vui lòng thực hiện lại!",
    },
    step6: {
      null: "Quý khách vui lòng nhập đầy đủ thông tin các trường có dấu (*) bắt buộc",
      idExpiredDateErr:
        "CMND/CCCD của Quý khách đã hết hạn, vui lòng liên hệ tổng đài để được hỗ trợ. Trân trọng cảm ơn!",
      dobErr:
        "Theo quy định pháp luật, độ tuổi được phép mở TKCK  từ 18 tuổi trở lên",
      female: "Nữ",
      male: "Nam",
      fullName: "Họ và tên",
      dob: "Ngày sinh",
      gender: "Giới tính",
      nationality: "Quốc tịch",
      label1: "Thông tin giấy tờ CMND/ CCCD",
      cardId: "Số CMND/ CCCD",
      cardType: "Loại giấy tờ",
      issueDate: "Ngày cấp",
      idExpiredDate: "Ngày hết hạn",
      issuePlace: "Nơi cấp",
    },
    step7: {
      label1: "Thông tin liên lạc",
      label2: "Điện thoại di động",
      nationality: "Quốc gia",
      address: "Địa chỉ thường trú",
      city: "Tỉnh/ Thành phố",
      placeholderCity: "Chọn Tỉnh/ Thành phố",
      contactAddress: "Địa chỉ liên lạc",
    },
    step8: {
      checkValid:
        "Phải nhập đủ thông tin: Ngân hàng, Số tài khoản, Chi nhánh, Tỉnh thành",
      label1: "Đăng ký tài khoản ngân hàng",
      note1: "Lưu ý: Tài khoản ngân hàng phải chính chủ",
      addBank: "Thêm tài khoản",
      bankName: "Ngân hàng",
      placeholderBankName: "Vui lòng chọn ngân hàng",
      cityBank: "Tỉnh/ Thành phố",
      placeholderCityBank: "Chọn Tỉnh/ Thành phố",
      bankAccountNo: "Số tài khoản",
      placeholderBankAccountNo: "Vui lòng nhập số tài khoản",
      branch: "Chi nhánh",
      placeholderBranch: "Tên chi nhánh",
    },
    step9: {
      label1: "Đăng ký chữ kỹ mẫu",
      label2:
        "Chữ ký của Quý khách hợp lệ khi Ký và ghi rõ Họ tên bằng chữ viết tay trên giấy trắng. Chữ ký mẫu này dùng làm cơ sở để kiểm tra chữ ký của Quý khách khi thực hiện giao dịch tại GMC.",
    },
    step10: {
      custodyCDNull: "Quý khách cần nhập đủ 6 số",
      custodyCDDupli: "Tài khoản đã tồn tại",
      brokerIdErr: "Mã nhân viên môi giới không hợp lệ",
      label1: "Chọn số tài khoản",
      note1:
        "Lưu ý: Hệ thống ưu tiên số tự chọn dành cho khách hàng kích hoạt hoàn tất đăng ký sớm nhất",
      label2:
        "Quý khách có thể lựa chọn mở tài khoản với dãy số là các chữ số đẹp, chữ số theo yêu cầu, sở thích. Hoặc bỏ qua bước này, số tài khoản sẽ được hệ thống cung cấp tự động",
      custodyCD: "Chọn số tài khoản",
      custodyCDAuto: "Hệ thống tự cấp",
      noteInput: "Số tài khoản phải gồm 6 chữ số !",
    },
    sideBar: {
      step1: "Chọn tài khoản đăng ký",
      step2: "Xác thực thông tin",
      step3: "Xác thực giấy tờ khuôn mặt",
      step4: "Bổ sung thông tin cá nhân",
    },
    step11: {
      label1: "Đăng ký loại dịch vụ môi giới",
      investorPro:
        "Investor Pro: Dịch vụ chọn gói, có chuyên viên môi giới tư vấn & chăm sóc khách hàng",
      investorSmart: "Investor Smart: Tự động hóa giao dịch",
      label2: "Đăng ký sử dụng Dịch Vụ Giao Dịch Trực Tuyến",
      iStock: "I-Stock (Giao dịch qua Internet)",
      tStock: "T-Stock (Giao dịch qua Call Center)",
      label3:
        "Đăng ký hình thức nhận thông tin, thông báo, sao kê tài khoản, kết quả giao dịch chứng",
      transactionByEmail: "Sao kê định kỳ (qua email)",
      mStock: "M-Stock - Dịch vụ tin nhắn SMS",
      label4:
        "Đăng ký Dịch Vụ Ứng Trước Tiền Bán Chứng Khoán tự động cho tiểu khoản thưởng",
      label5: "Đăng ký phương thức quản lý tiền:",
      cashAtBank:
        "Trực tiếp mở tài khoản tại ngân hàng thương mại do GMC lựa chọn để quản lý giao dịch chứng khoán",
      cashAtGMC:
        "Thông qua tài khoản chuyên dụng của GMC mở tại ngân hàng thương mại",
    },
    step12: {
      confirmNull:
        "Quý khách vui lòng đồng ý với Điều khoản và điều kiện mở tài khoản của GMC",
      label1: "Xác nhận điều khoản hợp đồng",
      content1:
        "Trên cơ sở đề nghị mở tài khoản giao dịch chứng khoán của Khách Hàng, hai Bên đã thống nhất ký kết Hợp Đồng với các điều khoản và điều kiện: ",
      detail: "Xem thông tin chi tiết",
      label2: "Thông tin liên quan đến Hoa Kỳ (cho mục đích tuân thủ FATCA)",
      optionA:
        "(a) Khách Hàng là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa Kỳ (*) hoặc là đối tượng phải khai thuế tại Hoa Kỳ.",
      optionB:
        "(b) Khách Hàng không phải là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa Kỳ nhưng có dấu hiệu nhận biết Hoa Kỳ (**).",
      optionC:
        "(c ) Khách Hàng không phải là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa Kỳ không có dấu hiệu nhận biết Hoa Kỳ.",
      note2:
        "Bằng cách tích chọn vào ô thích hợp bên trên và ký xác nhận trên Hợp Đồng này, Khách Hàng cam đoan các thông tin kê khai là chính xác và hoàn chỉnh. Khách hàng cam kết sẽ thông báo cho GMC trong thời hạn 30 ngày kể từ ngày có sự thay đổi về thông tin như đã kê khai như trên. Khách hàng hiểu rằng, tuyên bố hoặc che giấu về tình trang thuế của cá nhân Hoa Kỳ có thể dẫn đến các chế tài/hình phạt theo pháp luật thuế Hoa Kỳ, bao gồm cả FATCA.",
      label3: "Thời gian liên hệ & hình thức chỉnh gửi hồ sơ",
      content2: "1.Thời gian GMC liên hệ gửi hồ sơ",
      content3: "2.Hình thức gửi hồ sơ",
      content4:
        "Tôi đã đọc và đồng ý với các Điều khoản và điều kiện hợp đồng nêu trên",
    },
    step13: {
      title: "HOÀN TẤT",
      label1:
        "Cảm ơn Quý khách đã đăng ký mở tài khoản tại Công ty cổ phần chứng khoán GMC!",
      label2:
        "Để hoàn tất đăng kí, Quý khách vui lòng kiểm tra và thực hiện theo hướng dẫn đã được hệ thống gửi đến Email.",
      label3: "Kính chúc quý khách đầu tư thành công!",
    },
    progressCard: {
      step1: "Xác Thực Thông Tin",
      step2: "Đăng ký dịch vụ",
      step3: "Ký Hợp Đồng",
      step4: "Hoàn Tất",
    },
  },

  profitLoss: {
    filter: {
      account: "Tài khoản",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      ticker: "Mã chứng khoán",
      search: "Tìm kiếm",
    },

    profitLoss: "Lãi lỗ thực hiện",
    chartProfitLoss: "Biểu đồ lãi lỗ",
    netflow: "Netflow",

    fromDate: "Từ ngày",
    toDate: "Đến ngày",

    overDate: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
    lengthDate: "Vượt quá giới hạn tìm kiếm 30 ngày",

    table: {
      index: "STT",

      account: "TÀI KHOẢN",
      ticker: "MÃ CK",
      sellVolumn: "KHỐI LƯỢNG BÁN",
      buyPrice: "GIÁ MUA",
      value: "GIÁ TRỊ",
      profitLoss: "LÃI/LỖ",
      percent: "%LÃI/LỖ",
      tradingDate: "NGÀY GIAO DỊCH",
      action: "THAO TÁC",

      content: "NỘI DUNG",
      totalProfit: "TỔNG CỘNG",
    },
    groupCategory: {
      OD001: "Bán",
      OD01: "Mua",
      OD02: "Bán",
      KR03: "Gửi CK",
      KR04: "Gửi CK",
      KR05: "Gửi CK",
      KD03: "Rút CK",
      KD04: "Rút CK",
      KD05: "Rút CK",
      KD06: "Hủy rút CK",
      KC01: "Tất toán TK",
      KC02: "Tất toán TK",
      KC03: "Tất toán TK",
      KC04: "Tất toán TK",
      KC05: "Tất toán TK",
      KO03: "Bán",
      KO04: "Bán",
      KT03: "Chuyển khoản",
      KT04: "Chuyển khoản",
      RC01: "Cổ tức tiền",
      RS01: "Cổ tức CK",
      RS02: "Cổ tức CK",
      RS03: "Cổ tức CK",
      RI02: "CK mua PHT",
      RI03: "Nhận CK PHT",
      RI04: "CK PHT",
      RI05: "Hủy mua PHT",
      RU00: "Hủy niêm yết",
      RU01: "Hủy niêm yết",
      RB02: "CK chuyển đổi",
      RB03: "CK chuyển đổi",
      RP00: "Chia tách CK",
      RP01: "Chia tách CK",
      RP02: "Chia tách CK",
      RP03: "Chia tách CK",
      RJ00: "Sát nhập CK",
      RJ02: "Sát nhập CK",
      RJ03: "Sát nhập CK",
      RA01: "Chuyển đổi CK",
      RR01: "Chuyển đổi CK",
      ST01: "GD CK",
      ST02: "GD CK",
      MO05: "Cầm cố CK",
      MO06: "Cầm cố CK",
      MO07: "Cầm cố CK",
      MO08: "Cầm cố CK",
      MO09: "Cầm cố CK",
      MO10: "Cầm cố CK",
      MO13: "Giải toả CC CK",
      CW05: "Hủy niêm yết",
    },
  },

  holdUnholdBankAccount: {
    subAccount: "Tiểu khoản",
    bankAccoNumber: "Số TK ngân hàng",
    bankBranch: "Chi nhánh",
    bankName: "Ngân hàng",
    bankAvaiable: "Khả dụng ngân hàng",
    avaiableUnholdAmt: "Có thể giải tỏa",
    totalUnholdAmt: "Tổng đã phong tỏa",
    avaiableHoldAmt: "Có thể phong tỏa",
    action: "Thao tác",
    amount: "Số tiền",
    remarks: "Ghi chú",
    utility: "Phong tỏa/giải tỏa ngân hàng",

    overDate: "Ngày bắt đầu không được lớn hơn ngày kết thúc",
    lengthDate: "Vượt quá giới hạn tìm kiếm 30 ngày",
    actionList: {
      hold: "Phong tỏa",
      unhold: "Giải tỏa",
    },
    button: {
      confirm: "Thực hiện",
      cancel: "Bỏ qua",
      search: "Tìm kiếm",
    },
    message: {
      amountCheck1: "Chưa nhập số tiền",
      amountCheck2: "Số tiền phải lớn hơn 0",
      remarksCheck1: "Chưa nhập ghi chú",
      subAccountCheck1: "Chưa chọn tiểu khoản",
      actionCheck1: "Chưa chọn thao tác",
      success: "Thực hiện thành công",
      enterNote: "Nhập ghi chú",
      errorHold:
        "Số tiền phong tỏa không hợp lệ (Vượt quá khả dụng có thể phong tỏa)",
      errorUnHold:
        "Số tiền giải tỏa không hợp lệ (Vượt quá số tiền có thể giải tỏa)",
    },

    confirmModal: {
      title: "Xác nhận",

      content1: "Bạn có chắc chắn muốn thực hiện Phong tỏa tiểu khoản ?",
      content2: "Bạn có chắc chắn muốn thực hiện Giải tỏa tiểu khoản ?",
      accept: "Xác nhận",
      cancel: "Hủy bỏ",
    },
  },
  sessionName: {
    S: "Trước giờ",
    P: "ATO",
    O: "Liên tục",
    A: "ATC",
    C: "Thỏa thuận",
    I: "Nghỉ trưa",
    K: "Đóng cửa",
    G: "Đóng cửa",
    J: "Đóng cửa",
    Z: "Đóng cửa",
  },
  transferInternal: {
    transferInternal: {
      title: "Tạo yêu cầu chuyển tiền",
      detail: {
        form: {
          typeMoneyTransfer: "Loại chuyển khoản",
          accountFrom: "TK chuyển",
          accountTo: "TK nhận",
          nameAccountFrom: "Tên TK nhận",
          transferAvailability: "Khả dụng chuyển",
          amount: "Số tiền chuyển",
          content: "Nội dung",
          note: "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện",
          placeholderContent:
            "TK {subAccFrom} chuyển tiền nội bộ sang TK {subAccTo}",
        },
        button: {
          accept: "Thực hiện",
          cancel: "Hủy",
        },
        modal: {
          accountTransfe: "TK chuyển",
          accountGet: "TK nhận",
          nameAccountGet: "Tên TK nhận",
          amount: "Số tiền",
          content: "Nội dung",
          toastSuccess: "Chuyển tiền thành công!",
        },
      },
    },
    historyTransfer: {
      title: "Lịch sử chuyển tiền",
      filter: {
        valueAll: "Tất cả",
        fromSubAccoNo: {
          label: "Tài khoản chuyển",
        },
        toSubAccoNo: {
          label: "Tài khoản nhận",
        },
        fromDate: {
          label: "Từ ngày",
        },
        toDate: {
          label: "Đến ngày",
        },
        status: {
          label: "Trạng thái",
          all: "Tất cả",
          status0: "Chưa duyệt",
          status1: "Đã duyệt",
          status2: "NextDay",
          status3: "Đã hoàn tiền",
          status9: "Đã hủy",
        },
        typeMoneyTransfer: {
          label: "Loại chuyển tiền",
          status0: "Chuyển khoản ngân hàng",
          status1: "Chuyển khoản nội bộ",
          status2: "Nộp ký quỹ",
          status3: "Rút ký quỹ",
        },
        search: "Tìm kiếm",
      },
      table: {
        editDelete: "Hủy",
        alloDate: "Ngày YC",
        refNo: "Số YC",
        fromSubAccoName: "TK chuyển",
        fromSubAccoNo: "Tài khoản chuyển",
        toSubAccoNo: "TK nhận",
        toSubAccoName: "Tên Tài khoản nhận",
        amount: "Số tiền chuyển",
        feeAmount: "Phí CK",
        status: "Trạng thái",
        feeRate: "Tỉ lện phí",
        feeMin: "Phí CK tối thiểu",
        transferFeeFlg: "Cách tính phí",
        transactionName: "Chi nhánh",
        regUserId: "Người tạo",
        regDateTime: "Thời gian tạo",
        typeMoneyTransfer: "Loại chuyển khoản",
        content: "Nội dung",
      },
      messageFail: "Xóa thành công!",
    },
    historyTransferBank: {
      table: {
        editDelete: "Hủy",
        alloDate: "NGÀY GD",
        typeMoneyTransfer: "LOẠI CHUYỂN TIỀN",
        fromSubAccoName: "TK CHUYỂN",
        fromSubAccoNo: "TK CHUYỂN",
        toSubAccoNo: "TK NHẬN",
        toSubAccoName: "TÊN TK NHẬN",
        nameBank: "NGÂN HÀNG",
        branch: "CHI NHÁNH",
        amount: "SỐ TIỀN",
        feeAmount: "PHÍ",
        status: "TRẠNG THÁI",
        content: "NỘI DUNG",
        regUserId: "NGƯỜI CẬP NHẬT",
        regDateTime: "THỜI GIAN CẬP NHẬT",
        typeStatus: {
          type0: "Chưa duyệt",
          type1: "Đã duyệt",
          type2: "NextDay",
          type3: "Hoàn thành",
          type9: "Đã hủy",
        },
      },
      detail: {
        typeMoneyTransfer: "Loại chuyển tiền",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        nameToSubAccoNo: "Tên TK nhận",
        nambank: "Ngân hàng",
        branch: "Chi nhánh",
        city: "Tỉnh/Thành phố",
        address: "Địa chỉ",
        transferAvailability: "Khả dụng chuyển",
        amount: "Số tiền chuyển",
        provisionalFee: "Phí tạm tính (VNĐ)",
        content: "Nội dung",
        typeAmount: {
          value0: "Phí trong",
          value1: "Phí ngoài",
        },
        note: "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện",
        valueContent:
          "TK {tkFrom} - {nametk} chuyển khoản ngân hàng online cho TK {tkTo} - {nameTkTo}, tại {bank}, {branch} - {city}",
      },
      modal: {
        title: "Xác nhận chuyển khoản",
        accountTransfe: "TK chuyển",
        accountGet: "TK nhận",
        nameAccountGet: "Tên TK nhận",
        amount: "Số tiền ",
        bank: "Ngân hàng",
        typeTransfer: "Phương thức chuyển",
        fee: "Phí",
        content: "Nội dung",
        toastSuccess: "Chuyển tiền thành công!",
      },
      modalAddAcount: {
        title: "Thêm tài khoản thụ hưởng",
        nameAccount: "Tên tài khoản",
        numberAccouny: "Số tài khoản",
        bank: "Ngân hàng",
        country: "Tỉnh/Thành phố",
        branch: "Chi nhánh",
        address: "Địa chỉ",
        emptyAccoNumber: "Không được để trống trường này!",
        emptyBank: "Không được để trống trường này!",
        emptyCountry: "Không được để trống trường này!",
        emptyBranch: "Không được để trống trường này!",
        emptyAddress: "Không được để trống trường này!",
        messageSuccess: "Thêm tài khoản thụ hưởng thành công",
        inputBankCd: "Chọn ngân hàng",
        inputBankLocation: "Chọn tỉnh/thành phố",
        inputBankName: "Chọn chi nhánh",
      },
      messageFail: "Xóa thành công",
    },
    deposit: {
      title: "Xác nhận Nộp/Rút ký quỹ",
      note: "Lưu ý: Thời gian thực hiện từ 8h30 đến 15h45 các ngày làm việc.",
      table: {
        date: "NGÀY GD",
        fromSubAccoNo: "TK CHUYỂN",
        toSubAccoNo: "TK NHẬN",
        amount: "SỐ TIỀN",
        fee: "PHÍ",
        content: "NỘI DUNG",
        feePays: "HÌNH THỨC TRẢ PHÍ",
        statusVSD: "TRẠNG THÁI VSD",
        status: "TRẠNG THÁI",
        timeUpdate: "THỜI GIAN CẬP NHẬT",
        buttonDelete: "HỦY",
      },
    },
    withdrawal: {
      table: {
        date: "NGÀY GD",
        fromSubAccoNo: "TK CHUYỂN",
        toSubAccoNo: "TK NHẬN",
        amount: "SỐ TIỀN",
        fee: "PHÍ",
        content: "NỘI DUNG",
        feePays: "HÌNH THỨC TRẢ PHÍ",
        statusVSD: "TRẠNG THÁI VSD",
        status: "TRẠNG THÁI",
        timeUpdate: "THỜI GIAN CẬP NHẬT",
      },
    },
    statusVsd: {
      value0: "Chưa gửi",
      value1: "Đã gửi",
      value2: "VSD đồng ý",
      value3: "VSD từ chối",
      value4: "Từ chối từ Gate",
    },
    statusDeposit: {
      value0: "Chưa duyệt",
      value1: "Đã duyệt",
      value2: "Bank đồng ý",
      value3: "Bank từ chối",
      value9: "Đã hủy",
    },
    tradeType: {
      value1: "Rút ký quỹ",
      value2: "Nộp ký quỹ",
    },
    modalDeleteDeposit: {
      title: "Thông báo",
      content: "Bạn có chắc chắn muốn xóa giao dịch này?",
      messSuccess: "Xóa giao dịch thành công",
    },
    formAdd: {
      form: {
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        transferAvailability: "Khả dụng",
        amount: "Số tiền chuyển",
        fee: "Giá trị phí",
        content: "Nội dung",
      },
      modal: {
        title: "Xác nhận Nộp ký quỹ",
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        amount: "Số tiền chuyển",
        typeAmount: "Hình thức trả phí",
        fee: "Phí",
        content: "Nội dung",
        MessCheckNote:
          "Không hiển thị lại vào lần sau, vui lòng kiểm tra lại chi tiết xác nhận.",
        messSuccess: "Nộp ký quỹ thành công",
        messFail: "Nộp ký quỹ thất bại",
      },
    },
    formRemove: {
      form: {
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        transferAvailability: "Khả dụng",
        amount: "Số tiền chuyển",
        fee: "Giá trị phí",
        content: "Ghi chú",
      },
      modal: {
        title: "Xác nhận rút ký quỹ",
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        amount: "Số tiền chuyển",
        typeAmount: "Hình thức trả phí",
        fee: "Phí",
        content: "Nội dung",
        messSuccess: "Rút ký quỹ thành công",
        messFail: "Rút ký quỹ thất bại",
      },
    },
    errorAmountDeposit: {
      error1: "Số tiền giao dịch không hợp lệ",
      error2: "Số tiền không được vượt quá Khả dụng chuyển khoản",
      error3: "Số tiền chuyển không được vượt quá Khả dụng chuyển khoản",
      error4: "Số tiền chuyển + Phí không được vượt quá Khả dụng chuyển khoản",
    },
    btnConfirm: "Xác nhận",
  },
  subscribe: {
    tooltip: "Click đúp để đăng ký/ hủy đăng ký mua",

    titleLeft: "Đăng ký mua",

    tabNavigation: {
      subscribe: "Đăng ký quyền mua",
      historySubscribe: "Lịch sử đăng ký quyền mua",
      searchSubscribe: "Tra cứu thông tin quyền",
    },
    typeForm: {
      typeAdd: "Đăng ký mua",
      typeRemove: "Hủy đăng ký mua",
    },
    formAdd: {
      action: "Thao tác",
      codeCk: "Mã CK",
      Subacc: "Tiểu khoản",
      moneyAvailability: "Khả dụng tiền",
      numberCanRegister: "SL có thể đăng ký",
      numberRegister: "Nhập số CP",
      numberRegisterPlaceholder: "Nhập số CP",
      price: "Giá",
      intoMoney: "Thành tiền",
      note: "Ghi chú",
      notePlaceholder: "Nhập ghi chú",
      modal: {
        title: "Đăng ký mua",
        subacc: "Tiểu khoản đăng ký",
        codeStock: "Mã CK",
        price: "Giá mua",
        numberRegister: "SL đăng ký",
        intoMoney: "Thành tiền",
        note: "Ghi chú",
      },
      messageSuccess: "Đăng ký thành công",
      validate: {
        error1: "Số lượng phải > 0",
        error2: "Số lượng đăng ký phải nhỏ hơn số lượng có thể đăng ký",
        error3: "Số lượng hủy đăng ký phải nhỏ hơn số lượng có thể hủy đăng ký",
        error4: "Không đủ khả dụng thanh toán",
      },
    },
    formRemove: {
      action: "Thao tác",
      codeCk: "Mã CK",
      Subacc: "Tiểu khoản",
      numberCanCancel: "SL có thể huỷ",
      numberCancel: "SL huỷ",
      numberCanCancelPlaceholder: "Nhập số CP",
      price: "Giá",
      intoMoney: "Thành tiền",
      note: "Ghi chú",
      notePlaceholder: "Nhập ghi chú",
      modal: {
        title: "Hủy đăng ký mua",
        Subacc: "Tiểu khoản đăng ký",
        code: "Mã CK",
        price: "Giá mua",
        numberCancelRegister: "SL hủy đăng ký",
        intoMoney: "Thành tiền",
        note: "Ghi chú",
      },
      messageSuccess: "Hủy ký thành công",
    },
    content: {
      navigation: {
        subscribe: "Đăng ký quyền mua",
        historySubscribe: "Lịch sử đăng ký quyền mua",
        searchSubscribe: "Tra cứu thông tin quyền",
      },
      subscribe: {
        search: {
          Subacc: "Tiểu khoản",
          codeStock: "Mã chứng khoán",
          placeholderCodeStock: "Nhập mã",
        },
        table: {
          stt: "",
          Subacc: "TIỂU KHOẢN",
          codeStock: "MÃ CHỨNG KHOÁN",
          date: "NGÀY CHỐT",
          ratio: "TỶ LỆ NHẬN",
          dateStart: "NGÀY BẮT ĐẦU ĐĂNG KÝ",
          dateEnd: "NGÀY KẾT THÚC ĐĂNG KÝ",
          numberBuy: "SL CP ĐƯỢC MUA",
          numberRegister: "SL CP ĐÃ ĐĂNG KÝ MUA",
          price: "GIÁ MUA",
        },
      },
      historySubscribe: {
        search: {
          Subacc: "Tiểu khoản",
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          codeStock: "Mã chứng khoán",
          placeCodeStock: "Nhập mã",
          type: "Loại",
          option: {
            valueAll: "Tất cả",
          },
        },
        table: {
          action: "HUỶ",
          date: "NGÀY",
          Subacc: "TIỂU KHOẢN",
          secCd: "MÃ CHỨNG KHOÁN",
          actionType: "THAO TÁC",
          number: "SỐ LƯỢNG",
          price: "GIÁ",
          intoMoney: "THÀNH TIỀN",
          status: "TRẠNG THÁI",
        },
        modalDelete: {
          title: "Xác nhận",
          content: "Bạn có chắc chắn muốn hủy yêu cầu?",
          messageSuccess: "Hủy yêu cầu thành công",
        },
      },
      searchSubscribe: {
        search: {
          codeStock: "Mã chứng khoán",
          placeCodeStock: "Nhập mã",
          type: "Loại quyền",
          date: "Ngày chốt",
        },
        table: {
          codeStock: "MÃ CK",
          floor: "SÀN",
          dateKHQ: "NGÀY KHQ",
          dateClosing: "NGÀY CHỐT",
          dateTT: "NGÀY TT",
          dateGD: "NGÀY GD",
          ration: "TỶ LỆ QUYỀN",
          rationFrom: "TỶ LỆ NHẬN",
          typeRole: "LOẠI QUYỀN",
          dateStartDK: "NGÀY BẮT ĐẦU ĐK",
          dateEndDK: "NGÀY KẾT THÚC ĐK",
          dateStartCN: "NGÀY BẮT ĐẦU CN",
          dateEndCN: "NGÀY KẾT THÚC CN",
          pricePH: "GIÁ PH",
          tax: "THUẾ TNCN",
          typeCK: "LOẠI CK",
          code: "MÃ CK CHUYỂN",
          explain: "DIỄN GIẢI",
          secState: {
            value0: "Tất cả",
            value1: "Phổ thông",
            value2: "Hạn chế",
          },
        },
      },
    },
    statusThq: {
      valueAll: "Tất cả",
      value1: "Tiền mặt",
      value2: "Cổ tức CP",
      value3: "Cổ phiếu thưởng",
      value4: "Đăng ký PHT",
      value5: "Trái phiếu chuyển đổi",
      value6: "Hoán đổi cổ phiếu",
      value7: "Chia tách",
      value8: "Hủy đăng ký",
      value9: "Thay đổi loại hình",
      value10: "Đăng ký lưu ký",
      value11: "Biểu quyết",
      value12: "Hoàn trả góp vốn",
      value13: "Đáo hạn trái phiếu",
      value14: "Mua lại vốn góp của TCPH",
      value15: "Quyền mua trái phiếu chuyển đổi",
    },
    statusHistory: {
      value0: "Chưa duyệt",
      value1: "Đã duyệt",
      value9: "Đã hủy",
    },
  },
  confirmationOrder: {
    title: "Xác nhận lệnh Online",
    search: {
      fromDate: {
        label: "Từ ngày",
      },
      toDate: {
        label: "Đến ngày",
      },
      status: {
        label: "Trạng thái XN",
        status0: "Chưa xác nhận",
        status1: "Đã xác nhận",
      },
      button: "Tìm kiếm",
    },
    table: {
      subAccoNo: "TIỂU KHOẢN",
      reqType: "LOẠI GIAO DỊCH",
      secCd: "MÃ CK",
      confirmStatus: "XÁC NHẬN",
      status: "TRẠNG THÁI",
      orgOrderNo: "SỐ HIỆU LỆNH",
      pubQty: "KHỐI LƯỢNG ĐẶT",
      pubPrice: "GIÁ ĐẶT",
      matQty: "KHỐI LƯỢNG KHỚP",
      matPrice: "GIÁ KHỚP",
      ordChanel: "KÊNH",
      regDateTime: "THỜI GIAN ĐẶT LỆNH ",
      updDateTime: "THỜI GIAN CẬP NHẬT",
      accep: "Xác nhận lệnh",
      reqTypeStatus: {
        value1: "Bán",
        value2: "Mua",
      },
      typeOrdChanel: {
        value1: "Back",
        value2: "Online",
        value3: "Ipay",
        value4: "Web mobile",
        value5: "App",
        value6: "Home",
        value7: "Floor",
        value8: "SMS Trading",
        value9: "CC",
      },
      statusType: {
        value1: "Từ chối",
        value2: "Đã nhập vào hệ thống",
        value3: "Chờ khớp",
        value4: "Khớp một phần",
        value5: "Khớp toàn bộ",
        value6: "Hủy một phần",
        value7: "Hủy toàn bộ",
        value8: "Sở từ chối",
      },
      confirmStatusType: {
        value0: "Chưa xác nhận",
        value1: "Đã xác nhận",
      },
    },

    modal: {
      title: "Thông báo",
      content: "Bạn có muốn tiếp tục Xác nhận lệnh đã chọn?",
      accep: "Xác nhận",
      cancel: "Hủy",
      titleError: "Thông báo lỗi",
      messageError: "Không có lệnh nào được chọn để thực hiện xác nhận.",
      messageSuccess: "Xác nhận lệnh thành công",
    },
  },

  accountStatement: {
    titleMoneyStatement: "Sao kê tiền",
    titleStockStatement: "Sao kê chứng khoán",
    account: "Tài khoản",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    search: "Tìm kiếm",
    openingBalance: "Số dư đầu kỳ",
    endingBalane: "Số dư cuối kỳ",
    businessDate: "Ngày thực hiện",
    businessDateSer: "Ngày giao dịch",
    creditAmt: "Phát sinh tăng",
    debitAmt: "Phát sinh giảm",
    currentAmt: "Số dư",
    remarks: "Nội dung",
    stockCode: "Mã chứng khoán",
    placeholderStockCode: "Nhập mã CK",
    dayTrading: "Ngày giao dịch",
    errorToDate: "Từ ngày không được lớn hơn Đến ngày",
  },

  marginSecuritiesPortfolio: {
    title: "Danh mục chứng khoán ký quỹ",
    account: "Tài khoản",
    stockCode: "Mã chứng khoán",
    playhoderStockCode: "Nhập mã CK",
    search: "Tìm kiếm",
    no: "STT",
    stockName: "Tên chứng khoán",
    loanRate: "TL cho vay",
    estimateRate: "Tỉ lệ định giá",
    minPrice: "Giá tối thiểu",
    maxPrice: "Giá tối đa",
    marketName: "Sàn",
  },

  manegerMoney: {
    modal: {
      title: "Biểu lãi suất quản lý tiền",
      typeInterest: "Loại lãi",
      option: {
        label0: "Đúng hạn",
        label1: "Trước hạn",
      },
    },
  },

  positionManagement: {
    tab: {
      titleOpen: "Vị thế mở",
      titleClose: "Vị thế đóng",
    },
    positionOpen: {
      subject: "Tiểu khoản",
      placeholderStockCode: "Nhập mã CK",
      stockCode: "Mã chứng khoán",
      search: "Tìm kiếm",
      longMass: "KL Long",
      shortMass: "KL Short",
      currentPrice: "Giá hiện tại",
      priceLongMass: "Giá TB Long",
      priceShortMass: "Giá TB Short",
      profitAmt: "Lãi/Lỗ",
      total: "Tổng cộng",
      refresh: "Làm mới",
    },
    positionClose: {
      subject: "Tiểu khoản",
      placeholderStockCode: "Nhập mã CK",
      stockCode: "Mã chứng khoán",
      search: "Tìm kiếm",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      tradeDate: "Ngày giao dịch",
      quantity: "Khối lượng",
      longAvgPrice: "Giá TB",
      price: "Giá",
      profitAmt: "Lãi/Lỗ",
      errorToDate: "Từ ngày không được lớn hơn Đến ngày",
    },
  },

  depositListPMoney: {
    title: "Danh sách tiền gửi P-Money",
    dateCreate: "Ngày tạo",
    liquidationDate: "Ngày thanh toán",
    setUpChanel: "Kênh lập HD",
    status: "Trạng thái",
    search: "Tìm kiếm",
    branchCD: "Chi nhánh",
    transactionCd: "Điểm giao dịch",
    subAccoNo: "Tiểu khoản",
    amount: "Số tiền",
    interestRate: "TL Lãi",
    daysNo: "Số ngày",
    interestAmt: "Lãi trước thuế",
    taxRate: "TL Thuế TNCN",
    taxAmt: "Thuế TNCN",
    payedDate: "Ngày TT",
    contractSts: "Trạng thái HĐ",
    black: "1.Black",
    online: "2.Online",
    ipay: "3.Ipay",
    webMobile: "4.Web Mobile",
    mobile: "5.Mobile",
    home: "6.Home",
    floor: "7.Floor",
    sms: "8.SMS",
    tel: "9.Tel",
    inEffect: "1.Đang hiệu lực",
    liquidated: "2.Đã thanh lý",
    inEffectName: "Đang hiệu lực",
    liquidatedName: "Đã thanh lý",
    all: "Tất cả",
    errorToDateCreate: "Ngày tạo từ ngày phải bé hơn Ngày tạo đến ngày",
    errorToDateLiquidate:
      "Ngày thanh toán từ ngày phải bé hơn Ngày thanh toán đến ngày",
  },

  addFrameworkContractForm: {
    createContract: {
      title: "Tạo hợp đồng khung",
      account: "Tài khoản",
      accountName: "Tên tài khoản ",
      contractNo: "Số HĐ khung",
      dateOpenContract: "Ngày mở HĐ",
      remark: "Ghi chú",
      placeholderRemark: "Nhập nội dung",
      textConfirmAfter:
        "Tôi đã đọc và đồng ý với điều kiện và điều khoản đã nêu trong ",
      textConfirmBefore: "Hợp đồng Quản lý P-Money",
      btnReset: "Nhập lại",
      btnSubmit: "Xác nhận",
      close: "Đóng",
      titleNoti: "Thông báo",
      notiAfter: "Hợp đồng khung P-Money tài khoản số",
      notiBefor: " đã được lập thành công",
    },
    listContract: {
      title: "Danh sách hợp đồng",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      search: "Tìm kiếm",
      status: "Trạng thái",
      btnEdit: "Sửa",
      account: "Tài khoản",
      contractNo: "Số hợp đồng khung",
      dateOpenContract: "Ngày mở hợp đồng",
      infoCtv: "CTV Giới thiệu",
      dateCloseContract: "Ngày kết thúc",
      statusContract: "Trạng thái HĐ",
      remark: "Ghi chú",
      transactionCd: "Điểm giao dịch",
      liquidation: "Thanh lý",
      notiLiquidation: "Bạn có chắc muốn thanh lý hợp đồng này?",
      inEffect: "1. Đang hiệu lực",
      liquidated: "2. Đã thanh lý",
      all: "Tất cả",
      valueInEffect: "Đang hiệu lực",
      valueliquidated: "Đã thanh lý",
      liquidateSuccess: "Thanh lý thành công",
      liquidateFailure: "Thanh lý thất bại",
      errorToDate: "Từ ngày không được lớn hơn Đến ngày",
    },
  },
  profitMoney: {
    modal: {
      title: "Biểu lãi P-MONEY",
    },
  },
  subAccoRegister: {
    formLeft: {
      title: "Đăng ký tiểu khoản sử dụng",
      subAcco: "Tài khoản",
      bank: "Tên tài khoản",
      subAccoNo: "Tiểu khoản",
      messSuccess: "Thực hiện thành công",
      btnSuccess: "Đăng ký",
    },
    status: {
      valueAll: "Chọn tất cả",
    },
    modal: {
      title: "Thông báo",
      content:
        "Bạn chắc chắn muốn đăng ký/ Hủy đăng ký các tiểu khoản gửi tiền Quản lý tiền TKCK tự động",
    },
  },
  transferHistory: {
    title: "Lịch sử chuyển nhượng TPDN",
    search: {
      fromDate: {
        label: "Ngày tạo",
      },
      toDate: {
        label: "Đến ngày",
      },
      status: {
        label: "Trạng thái",
        valueAll: "Tất cả",
        value0: "Chờ xác nhận",
        value1: "Đã xác nhận",
        value2: "Từ chối",
        value9: "Đã xóa",
      },
    },
    table: {
      allDate: "NGÀY GD",
      status: "TRẠNG THÁI",
      custNoFrom: "SỐ TK CHUYỂN",
      custNameFrom: "TÊN TK CHUYỂN",
      custNoTo: "SỐ TK NHẬN",
      custNameTo: "TÊN TK NHẬN",
      bondCd: "MÃ TP",
      quantity: "SỐ LƯỢNG",
      parAmt: "GIÁ CHUYỂN NHƯỢNG",
      transferAmt: "GIÁ TRỊ CHUYỂN NHƯỢNG",
      parAmt1: "GIÁ TRỊ THEO MỆNH GIÁ",
      feeAmt: "PHÍ CHUYỂN NHƯỢNG",
      taxAmt: "THUẾ TNCN",
      feePayerDisp: "ĐỐI TƯỢNG TRẢ PHÍ",
      taxPayerDisp: "ĐỐI TƯỢNG TRẢ THUẾ",
      srcChannelDisp: "KÊNH GIAO DỊCH",
      regUserId: "NGƯỜI ĐẶT LỆNH",
    },
  },
  assets: {
    navigation: {
      assets: "Tài sản DVTC",
      assetsIncurred: "Tài sản Phái sinh",
      assetsSum: "Tổng tài sản & hiệu quả đầu tư",
    },
    search: {
      account: {
        label: "Tài khoản",
      },
    },
    assetsIncurred: {
      top: {
        information: {
          title: "THÔNG TIN TIỀN",
          deposit: "Tiền ký quỹ",
          ckDeposit: "CK ký quỹ",
          withdrawable: "Tiền có thể rút",
          opening: "Sức mở vị thế",
          moneyWait: "Tiền chờ KQ",
          moneyWaitWithdraw: "Tiền chờ rút KQ",
          owe: "Nợ",
          moneyAcco: "Tiền tài khoản giao dịch",
          realtime: "Tài sản realtime ",
          status: {
            default: "Bình thường",
            valueY: "Bình thường",
            valueX: "Dừng giao dịch",
            valueS: "Dừng GD mã CK",
            value1: "Cảnh báo mức 1",
            value2: "Cảnh báo mức 2",
          },
        },
        use: {
          title: "SỬ DỤNG KÝ QUỸ",
          sum: "Tổng giá trị ký quỹ",
          price: "Giá trị ký quỹ yêu cầu",
          ratio: "Tỷ lệ sử dụng tài sản",
          status: "Trạng thái",
          result: "KQ cần bổ sung",
        },
        profit: {
          title: "LÃI LỖ",
          waitTT: "Lãi lỗ chờ TT",
          temporary: "Lãi lỗ tạm tính",
          sum: "Tổng phí tạm tính",
        },
      },
      bottom: {
        tab1: {
          title: "Vị thế mở",
          table: {
            subAcc: "TIỂU KHOẢN",
            secCd: "MÃ CHỨNG KHOÁN",
            KLLONG: "KL LONG",
            KLSHORT: "KL SHORT",
            price: "GIÁ HIỆN TẠI",
            priceTbLong: "GIÁ TB LONG",
            priceTbShort: "GIÁ TB SHORT",
            profit: "LÃI/LỖ",
            sum: "TỔNG CỘNG",
          },
        },
        tab2: {
          title: "Vị thế đóng",
          table: {
            alloDate: "NGÀY GIAO DỊCH",
            subAcc: "TIỂU KHOẢN",
            secCd: "MÃ CHỨNG KHOÁN",
            mass: "KHỐI LƯỢNG ",
            priceTb: "GIÁ TB",
            price: "GIÁ",
            profit: "LÃI/LỖ",
            sum: "TỔNG CỘNG",
          },
        },
      },
    },
    assets: {
      top: {
        Money: "TIỀN",
        bankAvail: "1. Số dư",
        asNewPendingAmt: "2. Tiền bán chờ về",
        withdrawAvail: "3. Tiền có thể rút",
        Stock: "CHỨNG KHOÁN",
        asNewSecVal: "1. Chứng khoán hiện có",
        asNewRightVal: "2. Giá trị quyền",
        AsNewDebitTotal: "NỢ",
        AsNewAsset: "TỔNG TÀI SẢN",
        AsNewNetAsset: "TÀI SẢN RÒNG",

        TitleDVTL: "TÀI SẢN RÒNG",
        limit4: "Hạn mức tài khoản",
        limitL: "Hạn mức còn lại",
        normalLimitAllocated: "Hạn mức bảo lãnh đã cấp",
        todayBuyValue: "Mua trong ngày",
        totalMissMoney: "Tiền thiếu",
        needToLoand: "Cần giải ngân",
        normalLimitNeedSupp: "Bảo lãnh cần nộp",

        asNewCurrentRate: "Tỷ lệ hiện tại",
        warningRate: "Tỷ lệ ký quỹ duy trì",
        callRate: "Tỷ lệ ký quỹ xử lý",
        warningLevel: {
          lablel: "Trạng thái tài khoản",
          value0: "An toàn",
          value1: "Cảnh báo",
          value2: "Giải chấp",
        },
        needToAdditionCash: "Tiền mặt cần bổ sung",
        needToAdditionSec: "Chứng khoán cần bổ sung",
        needToSell: "GT chứng khoán cần bán",
      },
      table: {
        title: "Chứng khoán sở hữu",
        secCd: "MÃ CHỨNG KHOÁN",
        total: "TỔNG",
        availSale: "KHẢ DỤNG",
        receiveT0: "CHỜ VỀ T0",
        receiveT1: "CHỜ VỀ T1",
        receiveT2: "CHỜ VỀ T2",
        limitQty: "HẠN CHẾ CN",
        blockCom: "PHONG TỎA",
        mortage: "CẦM CỐ",
        currentPrice: "GIÁ TC",
        totaValue: "GT CHỨNG KHOÁN",
        marketCode: "SÀN",
      },
    },
    effective: {
      top: {
        titleLeft: "HIỆU QUẢ ĐẦU TƯ",
        titleRight: "TÀI SẢN RÒNG",
        button: {
          btn1m: "1m",
          btn2m: "2m",
          btn3m: "3m",
          btn6m: "6m",
          btn1y: "1y",
        },
        chartLeft: {
          tooltip: {
            index: "Index",
            TSSL: "TSSL",
          },
        },
        chartRight: {
          tooltip: {
            NAV: "NAV",
            growth: "Tăng trưởng",
          },
        },
      },
      bottom: {
        chart1: {
          title: "TỶ TRỌNG MÃ CỔ PHIẾU",
          MSN: "MSN",
          HPG: "HPG",
          VCB: "VCB",
          ACB: "ACB",
          MWG: "MWG",
          VNM: "VNM",
        },
        chart2: {
          title: "TỶ TRỌNG NGÀNH",
          titleLeft: "Tài khoản",
          titleRight: "VN30",
          Finance: "Tài chính",
          BĐS: "BĐS",
          Build: "Xây dựng",
        },
        chart3: {
          title: "NỢ VÀ TÀI SẢN RÒNG",
          debt: "Nợ",
          assets: "Tài sản ròng ",
        },
        chart4: {
          title: "PHÂN BỔ TỔNG TÀI SẢN",
          money: "Tiền",
          sec: "Cổ phiếu",
          Warranties: "Chứng quyền",
          Bonds: "Trái phiếu",
          Business: "Hợp tác kinh doanh",
          Derivative: "Phái sinh",
        },
      },
    },
  },

  registerTransferBonds: {
    modal: {
      titleRegister: "Đăng ký chuyển nhượng trái phiếu online",
      titleDelte: "Hủy đăng ký chuyển nhượng trái phiếu online",
      accountNo: "Số TK KH",
      fullName: "Họ tên",
      status: "Trạng thái Đk",
      textCheckBoxBefore: "Tôi đã đọc và đồng ý với ",
      textCheckBoxMiddle: "Điều khoản và điều kiện ",
      textCheckBoxAfter: " tại Phụ lục Giao dịch Chứng khoán chưa niêm yết",
      registered: "Đã đăng ký",
      unRegistered: "Chưa đăng ký",
      cancel: "Hủy",
      register: "Đăng ký",
      cancelRegistration: "Hủy đăng ký",
      registerDone: "Đăng ký thành công",
      registerFailure: "Có lỗi xảy ra",
      deleteDone: "Hủy đăng ký thành công",
      deleteFailure: "Có lỗi xảy ra",
    },
    modalConfirm: {
      titleConfirmDelte: "Xác nhận hủy đăng ký",
      titleConfirmRegister: "Xác nhận đăng ký",
      containerConfirm: "Bạn có chắc chắn muốn đăng ký hợp đồng khung",
      containerConfirmDelete:
        "Bạn có chắc chắn muốn hủy đăng ký hợp đồng khung",
      btnConfirm: "Xác nhận",
    },
  },

  placeOrderTransferBonds: {
    filter: {
      all: "Tất cả",
      delay: "Chờ xác nhận",
      accep: "Đã xác nhận",
      deny: "Từ chối",
      cancel: "Đã hủy",
    },
    infomationPlace: {
      title: "Thông tin lệnh",
      accoNoFrom: "Tài khoản chuyển",
      nameAccoNoFrom: "Tên TK chuyển",
      accoNoTo: "Tài khoản nhận",
      nameAccoNoTo: "Tên TK nhận",
      bondCd: "Mã TP",
      quantity: "Khối lượng chuyển",
      quantityApply: "KL được chuyển ",
      price: "Giá",
      feePayer: "Người trả phí",
      taxPayer: "Người trả thuế",
      parAmt: "Giá trị theo mệnh giá",
      transferAmt: "Giá trị chuyển nhượng",
      fee: "Phí",
      tax: "Thuế",
      cancel: "Hủy",
      submit: "Nhập lệnh",
      placeHolderText: "Nhập nội dung",
      notiTitle: "Thông báo",
      delay: "Chờ xác nhận",
      accep: "Đã xác nhận",
      deny: "Từ chối",
      canceled: "Đã hủy",
      notiAfter: "Bạn chắc chắn muốn hủy yêu cầu chuyển nhượng ",
      notiMiddle: ", số lượng ",
      notiBefore: " không?",
      personTransfer: "Người chuyển",
      personGet: "Người nhận",
      volumeLess: "Khối lượng chuyển phải bé hơn khối lượng được chuyển",
      errorAccount: "Tài khoản này chưa đăng ký tiểu khoản .60",
      errorAccTo: "Tài khoản nhận phải khác tài khoản chuyển",
    },
    placeBook: {
      title: "Sổ lệnh",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      status: "Trạng thái",
      search: "Tìm kiếm",
      cancle: "Hủy",
      alloDate: "Ngày tạo",
      refNo: "Số TT",
      toSubaccoNo: "Tài khoản nhận",
      toCustName: "Tên TK nhận",
      bonCd: "Mã TP",
      quantity: "Khối lượng",
      parAmt: "Gt theo mệnh giá",
      price: "Giá",
      transferAmt: "GT chuyển nhượng",
      feeAmt: "Phí CN",
      feePayer: "Đối tượng trả phí",
      taxtAmt: "Thuế TNCN",
      taxPayer: "Đối tượng trả thuế",
      remarks: "Ghi chú",
      deleteDone: "Xóa thành công",
      deleteFalure: "Không thể xóa bản ghi này",
      createDone: "Nhập lệnh thành công",
      createFalure: "Có lỗi xảy ra",
      errorToDate: "Từ ngày không được lớn hơn Đến ngày",
    },
    modal: {
      title: "Chuyển nhượng trái phiếu",
      accoNoFrom: "Bên chuyển",
      nameAccoNoFrom: "Tên TK chuyển",
      accoNoTo: "Bên nhận",
      nameAccoNoTo: "Tên TK nhận",
      bondCd: "Mã TP",
      quantity: "Khối lượng",
      price: "Giá",
      parAmt: "GT theo mệnh giá",
      transferAmt: "GT chuyển nhượng",
      fee: "Phí",
      feePayer: "Người trả phí",
      tax: "Thuế",
      taxPayer: "Người trả thuế",
      cancel: "Hủy",
      submit: "Xác nhận",
    },
  },

  changeLimit: {
    modal: {
      titleModal: "Thay đổi hạn mức",
      subAccountNo: "Tiểu khoản",
      currentLimit: "Hạn mức hiện tại",
      requireLimit: "Hạn mức yêu cầu",
      textCheckBoxBefore: "Tôi đã đọc và đồng ý với ",
      textCheckBoxMiddle: "Điều khoản và điều kiện ",
      textCheckBoxAfter: "sử dụng dịch vụ tại VGAIA",
      cancel: "Hủy",
      submit: "Thực hiện",
      done: "Thành công",
      failure: "Tài khoản này không thể thay đổi hạn mức",
      errorLimit: "Bạn chưa nhập hạn mức mới",
    },
  },

  placeOrderRetailBonds: {
    infomationOrder: {
      title: "Thông tin lệnh",
      subAcco: "Tiểu khoản",
      bondCd: "Mã TP",
      cycle: "Chu kỳ",
      setQuantity: "KL đặt",
      investmentValue: "Giá trị đầu tư",
      purchasingAbility: "Sức mua",
      valueReceived: "Giá trị nhận được",
      fee: "Phí",
      issuers: "Tổ chức phát hành",
      denominations: "Mệnh giá",
      releaseDate: "Ngày phát hành",
      couponRate: "Tỉ lệ coupon",
      dateDue: "Ngày đáo hạn",
      paymentPeriod: "Kỳ trả lãi",
      description: "Mô tả",
      skip: "Bỏ qua",
      buy: "Mua",
      modalTitle: "Xác nhận mua",
      modalBody: "Bạn có chắc chắc muốn mua mã trái phiếu này?",
      buyDone: "Mua mã trái phiếu thành công",
    },
    listBonds: {
      title: "Danh sách trái phiếu",
      bondCd: "Mã TP",
      period: "Kỳ hạn",
      commitedRate: "Lãi suất cam kết",
      remainingQuantity: "KL còn lại",
      quantityMin: "KL tối thiểu",
      valueReceived: "GT nhận được",
      status: "Trạng thái",
      action: "Thao tác",
    },
    orderBook: {
      title: "Sổ lệnh",
      placeHolderBondCd: "Nhập mã",
      bondCd: "Mã TP",
      status: "Trạng thái",
      search: "Tìm kiếm",
      cancel: "Hủy",
      orderDate: "Ngày đặt lệnh",
      subAcco: "Tiểu khoản",
      quantity: "Khối lượng",
      buy: "Mua/bán",
      investmentValue: "GT đầu tư",
      feeValue: "GT phí",
      period: "Kỳ hạn",
      profitRate: "Tỉ lệ lãi",
      dateDue: "Ngày đáo hạn",
      valueReceived: "GT nhận được",
      all: "Tất cả",
      delay: "Chưa duyệt",
      accept: "Đã duyệt",
      deny: "Đã đáo hạn",
      canceled: "Đã hủy",
      modalTitle: "Xác nhận xóa",
      modalBody: "Bạn có chắc chắn muốn xóa bản ghi này?",
      deleteDone: "Xóa thành công",
    },
  },
  advertisingOrder: {
    title: "Sổ lệnh quảng cáo",
    tradeTypeOptions: {
      value0: "Tất cả",
      value1: "Bán",
      value2: "Mua",
    },
    search: {
      bond: "Mã TP",
      placeBond: "Mã TP",
      tradeType: "Mua/Bán",
      placeTradeType: "Loại giao dịch",
      btnSearch: "Tìm kiếm",
    },
    table: {
      bondCd: "MÃ TP",
      TradeType: "MUA/BÁN",
      ordPrice: "GIÁ KỲ VỌNG",
      ordQty: "KHỐI LƯỢNG",
      minQty: "KHỐI LƯỢNG GD TỐI THIỂU ",
      expDate: "NGÀY HẾT HẠN",
    },
  },
  placeOrderBondAdv: {
    bondCdNull: "Mã TP không được để trống",
    ordPriceNull: "Giá kỳ vọng không được để trống",
    ordQtyNull: "Khối lượng không được để trống",
    minQtyNull: "Khối lượng GD tối thiểu không được để trống",
    errQty: "Khối lượng GD tối thiểu < Khối lượng",
    errExpDate: "Ngày hết hạn phải lớn hơn ngày hiện tại",
    contactInfoNull: "Số điện thoại liên hệ không được để trống",
    bondCd: "Mã TP",
    ordPrice: "Giá kỳ vọng",
    ordQty: "Khối lượng",
    minQty: "Khối lượng GD tối thiểu",
    expDate: "Ngày hết hạn",
    contactInfo: "Số điện thoại liên hệ",
    remarks: "Ghi chú",
    reset: "Lệnh mới",
    submit: "Nhập lệnh",
    confirmCreate: "Bạn muốn tạo yêu cầu quảng cáo?",
    tradeTypeAll: "Tất cả",
    tradeTypeSell: "Bán",
    tradeTypeBuy: "Mua",
    statusTypeNotApproved: "Chưa duyệt",
    statusTypeApproved: "Đã duyệt",
    statusTypeDone: "Đã xử lý",
    statusTypeCancel: "Đã hủy",
    tradeType: "Mua / Bán",
    status: "Trạng thái",
    tableContactInfo: "SĐT LIÊN HỆ",
    buttonCancel: "HỦY LỆNH",
    confirmCancel: "Bạn muốn hủy yêu cầu quảng cáo này?",
    titleBondAdv: "Lệnh quảng cáo",
    titleHistory: "Sổ lệnh",
    addSuccess: "Thêm mới thành công",
    cancelSuccess: "Hủy thành công",
  },
  guideVideo: {
    navigation: {
      training: "Đào tạo",
      userManual: "Hướng dẫn sử dụng",
    },
    valueBtn: {
      valueAll: "Tất cả",
    },
  },
};

export default vi;
