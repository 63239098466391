import React from "react";
import { ChartDataSets, ChartOptions, ChartPluginsOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import cloneDeep from "lodash/cloneDeep";

interface Props {
  labels: string[];
  datasets: ChartDataSets[];
  plugins: ChartPluginsOptions[];
  configs?: ChartOptions;
}

const FinanceChart: React.FC<Props> = ({
  datasets,
  plugins,
  labels,
  configs,
}) => {
  return (
    <Bar
      data={{
        labels: labels,
        datasets: cloneDeep(datasets),
      }}
      plugins={plugins}
      options={configs}
    />
  );
};
export default FinanceChart;
