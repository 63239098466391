import { ForeignWatchWrapper } from "./styles";
import Content from "./Content";
import { memo } from "react";
import Tabs from "./Tabs";
import { ForeignTab } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { changeActiveTab } from "modules/market/redux/foreignChart";

const foreignChartSelector = (state: RootState) =>
  state.market.marketWatch.foreignChart;

function ForeignWatch() {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(foreignChartSelector);

  const handleChangeTab = (tab: ForeignTab) => {
    dispatch(changeActiveTab(tab));
  };

  return (
    <ForeignWatchWrapper>
      <Tabs activeTab={activeTab} onChangeTabMarketWatch={handleChangeTab} />
      <Content activeTab={activeTab} />
    </ForeignWatchWrapper>
  );
}
export default memo(ForeignWatch);
