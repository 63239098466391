import { Widget } from "./types";
import OrganizationInfo from "components/widgets/details/OrganizationInfo";
import FinanceInfo from "components/widgets/details/Finance";
import New from "components/widgets/details/News";
import MarketTickerInfo from "components/widgets/details/TickerInfo";
import MarketTickerGraph from "modules/tickerDetails/components/MarketTickerGraph";
import OrderFlowDistributionWidget from "modules/tickerDetails/components/MarketOrderFlowDistribution";
import MarketPriceList from "modules/tickerDetails/components/MarketPriceList";
import MarketVolumeAndTime from "modules/tickerDetails/components/MarketVolumeAndTime";

const tickerDetailWidgets: Widget[] = [
  {
    name: "tickerDetail.widgets.tickerInfo",
    component: MarketTickerInfo,
  },
  {
    name: "tickerDetail.widgets.priceList",
    component: MarketPriceList,
  },
  {
    name: "tickerDetail.widgets.volumeAndTime",
    component: MarketVolumeAndTime,
  },
  {
    name: "tickerDetail.widgets.orderFlowDistribution",
    component: OrderFlowDistributionWidget,
  },
  {
    name: "tickerDetail.widgets.organizationInfo",
    component: OrganizationInfo,
  },
  {
    name: "tickerDetail.widgets.financeInfo",
    component: FinanceInfo,
  },
  {
    name: "tickerDetail.widgets.new",
    component: New,
  },
  {
    name: "tickerDetail.widgets.tickerGraph",
    component: MarketTickerGraph,
  },
];

export default tickerDetailWidgets;
