import { findPortTransactionApi, getPflTPortTransactionApi } from "./urls";
import {
  IFindPortTransactionResponse,
  IGetPflTPortTransactionResponse,
} from "domain/models/ProfitLoss";

import {
  IFindPortTransactionParams,
  IGetPflTPortTransactionParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const findPortTransaction = async (
  params: IFindPortTransactionParams
): Promise<IFindPortTransactionResponse> => {
  const res = await VgaiaHttp.get<IFindPortTransactionResponse>(
    findPortTransactionApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getPflTPortTransaction = async (
  params: IGetPflTPortTransactionParams
): Promise<IGetPflTPortTransactionResponse> => {
  const res = await VgaiaHttp.get<IGetPflTPortTransactionResponse>(
    getPflTPortTransactionApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
