import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionValueVolumeDataNew } from "components/widgets/generals/TransactionValueVolume/types";
export type TransactionValueVolumeState = {
  loading: boolean;
  error: string | undefined;
  transactionValueVolumeData: TransactionValueVolumeDataNew;
};

const initialState: TransactionValueVolumeState = {
  loading: false,
  error: undefined,
  transactionValueVolumeData: {
    valueData: [],
  },
};

const transactionIndustrySlice = createSlice({
  name: "transactionIndustrySlice",
  initialState,
  reducers: {
    fetchTransactionIndustry: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    fetchTransactionValueVolumeSuccess: (
      state,
      action: PayloadAction<TransactionValueVolumeDataNew>
    ) => {
      state.loading = false;
      state.transactionValueVolumeData.valueData = action.payload.valueData;
    },
    fetchTransactionValueVolumeFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchTransactionIndustry,
  fetchTransactionValueVolumeSuccess,
  fetchTransactionValueVolumeFailure,
} = transactionIndustrySlice.actions;

export default transactionIndustrySlice.reducer;
