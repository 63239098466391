import queryString from "query-string";

export const getCustomer2Api = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomer2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getContractApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getPMoneyContract${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const createContractApi = `/createPMoneyContract`;
export const liquidationApi = `/liquidatePMoneyContract`;
