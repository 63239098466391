import { useRef } from "react";
import { useClickAway } from "react-use";
import { DropdownContainer } from "./styles";
import WatchListDropdownItem from "./WatchListDropdownItem";
export type WatchListDropdownProps = {
  list: WatchListItem[];
  onAddToList: (listName: string) => void;
  onRemoveFromList?: (listName: string) => void;
  onClose: () => void;
  placement?: string;
};

export type WatchListItem = {
  nameVi: string;
  name: string;
  isBelongToWatchlist: boolean;
};
export default function WatchListDropdown({
  list,
  onAddToList,
  onRemoveFromList = () => {},
  onClose,
  placement,
}: WatchListDropdownProps) {
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });
  function handleAddClick(listName: string) {
    onAddToList(listName);
  }
  function handleRemoveClick(listName: string) {
    onRemoveFromList(listName);
  }

  return (
    <DropdownContainer ref={ref} placement={placement}>
      {list.map((item) => {
        return (
          <WatchListDropdownItem
            nameVi={item.nameVi}
            name={item.name}
            checked={item.isBelongToWatchlist}
            onAddClick={handleAddClick}
            onRemoveClick={handleRemoveClick}
            key={item.name}
          />
        );
      })}
    </DropdownContainer>
  );
}
