import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import PlaceOrderBondAdv from "./pages";

const routes: RouterConfig[] = [
  {
    page: PlaceOrderBondAdv,
    path: paths.placeOrderBondAdv,
    exact: true,
  },
];

export default routes;
