import VgaiaHttp from "core/http/singleton/vgaia";
import { IAccountStatmentResponse } from "domain/models/AccountStatement";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import {
  IFindMarginContractRequest,
  IGetListMarginSecuritiesPortfolio,
} from "./type";
import {
  findMarginContractApi,
  getListMarginSecuritiesPortfolioApi,
} from "./urls";

export const getListMarginSecuritiesPortfolio = async (
  param: IGetListMarginSecuritiesPortfolio
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getListMarginSecuritiesPortfolioApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const searchListMarginSecuritiesPortfolio = async (
  param: IGetListMarginSecuritiesPortfolio
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<any>(
    getListMarginSecuritiesPortfolioApi(param)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findMarginContract = async (
  param: IFindMarginContractRequest
): Promise<IAccountStatmentResponse> => {
  const res = await VgaiaHttp.get<any>(findMarginContractApi(param));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
