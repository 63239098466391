import { convertHexToRGBA } from "helper/utils";
import styled from "styled-components/macro";

export const PopConfirmOrderContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;

export const PopConfirmOrderTitle = styled.div<{ color: string }>`
  background-color: ${({ theme }) => theme.background3};
  text-align: center;
  padding: 10px;
  color: ${({ theme, color }) => theme[color]};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;
`;

export const PopConfirmOrderBody = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  padding: 24px;
  background-color: ${({ theme }) => theme.background2};

  .header {
    padding: 8px 0;
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.bold};
  }
  .body {
    background-color: ${({ theme }) => theme.bgElevated2};
    border-radius: 8px;
  }
  .order-info {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.borderDivider} 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 18px 1px;
    background-repeat: repeat-x;
  }
  .text-bold {
    color: ${({ theme }) => theme.neutral1};
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  }

  .text-small {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
  .footer {
    display: flex;
    padding-top: 24px;
    .flex-1 {
      flex: 1;
    }
    .apply-btn {
      background-color: ${({ theme }) => theme.solidBlue};
    }
    .btn-success {
      background: ${({ theme }) => theme.green50};
    }
    .btn-danger {
      background: ${({ theme }) => theme.red50};
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
`;
export const TradeLabel = styled.div<{ color: string }>`
  color: ${({ theme, color }) => theme[color]};
`;
export const ButtonConfirmBuy = styled.div<{ color: string }>`
  background: ${({ theme, color }) => theme[color]};
  color: ${({ theme }) => theme.neutral1};
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  cursor: pointer;

  :hover {
    background: ${({ theme, color }) => convertHexToRGBA(theme[color], 0.7)};
  }
`;
export const ButtonExit = styled.button<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  background: transparent;
  border: none;
  svg path {
    fill: ${({ theme, color }) => theme[color]};
  }
`;

export const AcceptButton = styled.button<{ color: string }>`
  background: ${({ theme, color }) => theme[color]};
  color: ${({ theme }) => theme.neutral1};
  height: ${({ theme }) => theme.button.height};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  padding: 8px;
  max-height: 36px;
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  flex: 1;
  :hover {
    background: ${({ theme, color }) => convertHexToRGBA(theme[color], 0.7)};
  }
  :focus {
    outline: 0;
  }
`;

export const TextColor = styled.div<{ color: string }>`
  color: ${({ theme, color }) => (color ? theme[color] : theme.grey0)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;
`;

export const VisibleModalConfirm = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Content = styled.div`
  margin-left: 10px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.grey20};
`;

export const Value = styled.div`
  color: ${({ theme }) => theme.grey0};
`;
