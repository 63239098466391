// import { EWatchListTypeMap } from "domain/proto/service_watchlist_pb";
// import {
//   CWPriceInfo,
//   PriceInfoBasic,
//   PriceInfoForeignRoom,
//   PriceInfoLastSale,
//   PriceInfoQuote,
//   PriceInfoQuotes,
//   PriceInfoSummary,
//   PriceInfoUnderlying,
//   DIPriceInfo,
// } from "domain/proto/common/data_priceinfo_pb";
import {
  STOCK_COLOR_CODEMap,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";

export interface ListType {
  name: string;
  groupType: string;
  type: number;
  listTickers: string[];
  listId?: number[];
  isCache?: boolean;
  code?: string;
  subCode?: string;
  nameVi?: string;
  nameEn?: string;
}

export type PutThroughListType = {
  name: string;
  groupType: string;
  displayName: string;
};

export type TickerOption = {
  value: string;
  label: string;
};

export interface ColManage {
  [key: string]: {
    visible?: boolean;
    minWidth?: number;
    children?: ColManage;
    name?: string;
    nameCol?: string;
    parent?: string;
    fixed?: boolean;
    disable?: boolean;
    sortName?: string;
    settingOrderIndex: number;
    smallDeviceMinWidth?: number;
  };
}

// export type InfoState = {
//   ticker?: string;
//   dataChart?: number[];
//   timeListArray?: number[];
//   referencePrice?: number;
//   dataInfoLastSale?: PriceInfoLastSale.AsObject;
//   dataInfoQuotes?: PriceInfoQuotes.AsObject;
//   dataInfoBasic?: PriceInfoBasic.AsObject;
//   dataInfoSummary?: PriceInfoSummary.AsObject;
//   dataInfoForeignRoom?: PriceInfoForeignRoom.AsObject;
//   dataInfoUnderlying?: PriceInfoUnderlying.AsObject;
//   dataInfoCWPrice?: CWPriceInfo.AsObject;
//   dataDiPriceInfo?: DIPriceInfo.AsObject;
//   underlyingColor?: number;
//   newDay?: boolean;
// };

export interface CategoryInfo {
  [key: string]: SymbolTotalInfo.AsObject;
}

export interface InfoBaseseccd {
  baseprice: number | undefined;
  basesecpricecolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}
export interface TickerChartUpdateProps {
  ticker: string;
  dataChart: number[];
  timeListArray: number[];
  referencePrice: number;
}

// export interface TickerQuoteUpdateProps {
//   ticker: string;
//   eside: number;
//   dataQuotes: PriceInfoQuote.AsObject;
// }

export interface SortTickerProps {
  sortField: string;
  sortDirection: ORDER;
}

export enum ORDER {
  UNKNOW = "",
  DESC = "desc",
  ASC = "asc",
}

export interface TickerInfoUpdateProps {
  ticker: string;
  data: any;
}

export interface ModalEditColProps {
  columns: ColManage;
}

export interface TableAttributeProps {
  sumWidth: number;
  bidWidth: number;
  askWidth: number;
  foreignWidth: number;
}

export interface RowStockProps {
  rowNumber: number;
  ticker: string;
  style?: React.CSSProperties;
  sumWidth: number;
  askWidth: number;
  bidWidth: number;
  foreignWidth: number;
  priceWidth: number;
  lastSaleWidth: number;
  coverWarrantWidth: number;
  coverWarrantHeadWidth: number;
  currentListCode: string;
  columns: ColManage;
  provided?: any;
  isDragging?: boolean;
  isFocus: boolean;
}

export interface GridTableBodyProps {
  columns: ColManage;
  tableAttribute: {
    sumWidth: number;
    bidWidth: number;
    askWidth: number;
    foreignWidth: number;
    priceWidth: number;
    lastSaleWidth: number;
    coverWarrantWidth: number;
  };
}

export interface ChangeCurrentListPayload {
  name: WATCH_LIST_TAB_NAME;
  isDeleteTicker: boolean;
}

export interface WatchListCustomProps {
  watchlists: ListType[];
  isOpen: boolean;
  isShowTitle: boolean;
  isReadOnly: boolean;
  onRemove: (item: ListType) => void;
  onEdit: (index: number, nameEdited: string) => void;
  onChangeList: (
    name: WATCH_LIST_TAB_NAME | string,
    isDeleteTicker: boolean
  ) => () => void;
  onCreate: (name: string) => void;
  isFavoriteList: boolean;
}

export interface WatchListReadOnlyProps {
  listCode: ListType[];
  selectedCode: string;
  isOpen: boolean;
  onChangeList: (name: string) => () => void;
  useCodeAsKey?: boolean;
  twoCol?: boolean;
}

export interface ListPutThrough {
  listCode: PutThroughListType[];
  selectedCode: string;
  isOpen: boolean;
  onChangeList: (name: string) => () => void;
}

export interface PriceCellProps {
  price: number | string;
  className?: string;
  color?: string;
  isBlink?: boolean;
  ticker?: string;
  isPreventSetCommand?: boolean;
  isDerivativeVN30?: boolean;
  isRate?: boolean;
  isAsk?: boolean;
  isAvgPrice?: boolean;
}

export interface VolumeCellProps {
  volume: number;
  className?: string;
  color?: string;
  keepRawValue?: boolean;
  formatType?: string;
}

export type WATCH_LIST_TAB_NAME =
  | "HOSE"
  | "HNX"
  | "HNX30"
  | "Derivative"
  | "CoverWarrant"
  | "Owner"
  | "Industry"
  | "PutThrough"
  | string;

export type ChartData = {
  labels: number[];
  thresholdValue: number;
  datasets: any[];
};

export type ChartValueItem = {
  closedValue?: number;
  volumeValue?: number;
  timeValue?: number;
};
