import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Select, Spacer, Input } from "components/commons";
import { FormattedMessage } from "react-intl";
import { useIntl, IntlShape } from "react-intl";
import { methods } from ".";
import { FormControl, FormTitle, Error } from "../styles";
import InputPrice from "components/commons/InputPrice";
import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeAvgPrice,
  onChangeExpiredDate,
  onChangeLimitOffset,
  onChangeMatMethod,
  onChangeOptVal,
  onChangeOptValType,
} from "modules/stock-order/redux/placeOrder";
import {
  onChangeAvgPriceByEnter,
  onChangeLimitOffsetByEnter,
  onChangeOptValByEnter,
  onChangeVolumeByEnter,
} from "modules/stock-order/redux";
import numeral from "numeral";
import { tradeDateSelector } from "modules/auth/selectors";
import moment from "moment";

const pattern = new RegExp(/^\d+(\.\d{0,9})?$/);

const stopIntervalList = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.byValue",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "widgets.placeOrder.conditionalOrder.byPercent",
    }),
  },
];

const regPrice = /^\d+(\.\d{0,9})?$/;

const orderBookSelector = (state: RootState) => state.stockOrder;

const placeOrderSelector = createSelector(
  orderBookSelector,
  (state) => state.placeOrder
);

interface Props {
  ticker: string;
  onSubmit: () => void;
}

const StopOrder: React.FC<Props> = React.memo(({ ticker, onSubmit }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isVisibleErrorOtpVal, setIsVisibleErrorOtpVal] = useState(true);
  //Todo move to redux
  const {
    limitOffset,
    optVal,
    optValType,
    matMethod,
    expiredDate,
    avgPrice,
    errorValidateLimitOffset,
    errorValidateOtpVal,
  } = useSelector(placeOrderSelector);
  const tradeDate = useSelector(tradeDateSelector);

  const {
    root: { changeVolumeByEnter, changeAvgPriceByEnter, changeOptValByEnter },
  } = useSelector(orderBookSelector);
  const refOptVal = useRef<HTMLInputElement>(null);
  const refLimitOffset = useRef<HTMLInputElement>(null);
  const refAvgPrice = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (changeVolumeByEnter) {
      refAvgPrice.current?.focus();
    }
  }, [changeVolumeByEnter]);

  useEffect(() => {
    if (changeAvgPriceByEnter) {
      refOptVal.current?.focus();
    }
  }, [changeAvgPriceByEnter]);

  useEffect(() => {
    if (changeOptValByEnter) {
      refLimitOffset.current?.focus();
    }
  }, [changeOptValByEnter]);

  const onBlurEnter = () => {
    dispatch(onChangeVolumeByEnter(false));
    dispatch(onChangeOptValByEnter(false));
    dispatch(onChangeLimitOffsetByEnter(false));
    dispatch(onChangeAvgPriceByEnter(false));
    setIsVisibleErrorOtpVal(false);
  };

  const handleKeyDown = (e: any, typeInput: string) => {
    if (e.key === "Enter" && typeInput === "avgPrice") {
      dispatch(onChangeAvgPriceByEnter(true));
    }
    if (e.key === "Enter" && typeInput === "optVal") {
      dispatch(onChangeOptValByEnter(true));
    }
    if (e.key === "Enter" && typeInput === "limitOffset") {
      refLimitOffset.current?.blur();
      dispatch(onChangeLimitOffsetByEnter(true));
      onSubmit();
    }
  };

  useEffect(() => {
    if (errorValidateOtpVal === "") {
      setIsVisibleErrorOtpVal(true);
    } else {
      setIsVisibleErrorOtpVal(false);
    }
  }, [errorValidateOtpVal]);

  const handleFocusInputOtpVal = () => {
    setIsVisibleErrorOtpVal(true);
  };

  const handleChangeOtpVal = (value: string) => {
    let text = "";
    const textValue = numeral(value).value() !== null ? parseFloat(value) : 0;
    if ((textValue * 1000) % 10 !== 0) {
      text = optVal;
    } else {
      text = value;
    }
    // dispatch(onChangeOptVal(text));
    if (pattern.test(text)) {
      dispatch(onChangeOptVal(text));
      setIsVisibleErrorOtpVal(true);
    }
    if (value === "") {
      dispatch(onChangeOptVal(""));
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.method" />
        </FormTitle>
        <FormControl>
          <Select
            value={methods(intl).find((x) => x.value === matMethod)}
            className="flex-fill"
            options={methods(intl)}
            onChange={(item) =>
              item && item.value && dispatch(onChangeMatMethod(item.value))
            }
          />
        </FormControl>
      </div>
      <Spacer size="s" />

      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.avgPrice" />
        </FormTitle>
        <FormControl>
          <Input
            innerRef={refAvgPrice}
            value={avgPrice === 0 ? "" : avgPrice}
            onChange={(value) => {
              dispatch(onChangeAvgPrice(value));
            }}
            onBlur={onBlurEnter}
            onKeyDown={(e) => handleKeyDown(e, "avgPrice")}
            // placeholder={intl.formatMessage({
            //   id: "formPlaceholder.inputAvgPrice",
            // })}
            disabled={true}
            isTextRight
          />
        </FormControl>
      </div>
      <Spacer size="s" />

      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <FormTitle>
            <FormattedMessage id="widgets.placeOrder.arbitrage" />
          </FormTitle>
          <FormControl>
            <div className="flex-5">
              <Select
                value={stopIntervalList(intl).find(
                  (item) => item.value === optValType?.toString()
                )}
                className="flex-fill"
                options={stopIntervalList(intl)}
                onChange={(item) => {
                  if (item) {
                    dispatch(onChangeOptValType(Number(item.value)));
                  }
                }}
              />
            </div>
            <Spacer size="s" />
            <div className="flex-4">
              <Input
                innerRef={refOptVal}
                value={optVal === 0 ? "" : optVal}
                onChange={(e) => handleChangeOtpVal(e.target.value)}
                onBlur={onBlurEnter}
                onFocus={handleFocusInputOtpVal}
                onKeyDown={(e) => handleKeyDown(e, "optVal")}
                placeholder={intl.formatMessage({
                  id:
                    optValType === 1
                      ? "formPlaceholder.inputOptValByValue"
                      : "formPlaceholder.inputOptValByPercent",
                })}
                isTextRight
              />
            </div>
          </FormControl>
        </div>
        {!isVisibleErrorOtpVal && errorValidateOtpVal && (
          <>
            <Error>
              <span className="text-danger flex-1 text-right">
                {errorValidateOtpVal}
              </span>
            </Error>
            <Spacer size="s" />
          </>
        )}
      </div>
      <Spacer size="s" />

      <InputPrice
        innerRef={refLimitOffset}
        suggestions={[]}
        value={limitOffset === 0 ? "" : limitOffset}
        onChange={(value) => {
          dispatch(onChangeLimitOffset(value));
        }}
        onBlurEnter={onBlurEnter}
        onKeyDown={(e) => handleKeyDown(e, "limitOffset")}
        label={intl.formatMessage({ id: "widgets.placeOrder.slidingMargin" })}
        partern={regPrice}
        error={errorValidateLimitOffset}
        decimal
        placeholder={intl.formatMessage({
          id: "formPlaceholder.inputLimitOffset",
        })}
        ticker={ticker}
        containerId="place-order-container"
        isOneNumberAfterComma
      />
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.toDate" />
        </FormTitle>
        <FormControl>
          <DatePicker
            selected={new Date(expiredDate)}
            onChange={(e: Date) => dispatch(onChangeExpiredDate(e))}
            fullWidth
            minDate={moment(tradeDate, "YYYYMMDD").toDate()}
          />
        </FormControl>
      </div>
      <Spacer size="s" />
    </>
  );
});
export default StopOrder;
