import React, { memo } from "react";

import { ReactComponent as DropDownIcon } from "assets/image/svg/drop-down.svg";

const DropdownIndicator = memo(() => {
  return (
    <div className="px-2">
      <DropDownIcon />
    </div>
  );
});

export default DropdownIndicator;
