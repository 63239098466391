import { ThemedStyledProps } from "styled-components";

export type TypeFaceItem = {
  size: string;
  weight: {
    bold: number;
    semiBold: number;
    regular: number;
  };
  lineHeight: string;
};

export type TTypeFace = {
  header: TypeFaceItem;
  title: TypeFaceItem;
  headline: TypeFaceItem;
  body: TypeFaceItem;
  caption12: TypeFaceItem;
  caption11: TypeFaceItem;
  caption10: TypeFaceItem;
  minimal9: TypeFaceItem;
  minimal: TypeFaceItem;
  watchlist: {
    menu: TypeFaceItem;
    header: TypeFaceItem;
    body: TypeFaceItem;
  };
  sideBar: {
    width: string;
    sideBarItem: {
      height: string;
    };
  };
};

export interface ThemeData {
  typeFaces: TTypeFace;
  tables: {
    header: {
      height: string;
    };
    content: {
      height: string;
      padding: string;
    };
    contentLarge: {
      height: string;
    };
    footer: {
      height: string;
    };
  };
  button: {
    height: string;
    width: string;
    borderRadius: string;
  };
  input: {
    heightArea: string;
    height: string;
    height28: string;
    width: string;
  };
  inputCellTable: {
    height: string;
  };
  inputDecrementIncrement: {
    height: string;
    width: string;
  };
  select: {
    height: string;
    height28: string;
    width: string;
    borderRadius: string;
    valueContent: {
      height: string;
    };
  };
  filterTable: {
    padding: string;
  };
  cardHeader: {
    height: string;
    borderRadius: string;
    cardHeaderItem: {
      padding: string;
    };
  };
  headerButton: {
    height: string;
  };
  zIndex: {
    tooltip: number;
    modal: number;
    popup: number;
  };

  fontFamily: string;
  // Mau VGAIA
  brandPrimary: string;
  brandSecondary: string;

  backgroundFixed: string;
  background1: string;
  background2: string;
  background3: string;
  background4: string;

  grey0: string;
  grey5: string;
  grey10: string;
  grey20: string;
  grey30: string;
  grey40: string;
  grey50: string;
  grey60: string;
  grey70: string;
  grey80: string;
  grey90: string;
  grey100: string;

  green10: string;
  green20: string;
  green50: string;
  green60: string;

  red10: string;
  red20: string;
  red50: string;
  red60: string;

  redR1: string;

  violet10: string;
  violet20: string;
  violet50: string;
  violet60: string;

  blue10: string;
  blue20: string;
  blue50: string;
  blue60: string;

  yellow10: string;
  yellow20: string;
  yellow50: string;
  yellow60: string;

  border1: string;
  border2: string;

  solidBlue: string;
  solidYellow: string;
  solidRed: string;
  solidGreen: string;
  solidGrey: string;
  solidOrange: string;
  solidViolet: string;
  solidWhite: string;
  solidNavy: string;
  solidFlash: string;

  graphBlue: string;
  graphYellow: string;
  graphRed: string;
  graphGreen: string;
  graphViolet: string;

  textColorBlue: string;
  textColorYellow: string;
  textColorRed: string;
  textColorGreen: string;
  textColorViolet: string;
  textcolorWhite: string;
  textcolorBlack: string;

  overlayBlue: string;
  overlayYellow: string;
  overlayRed: string;
  overlayGreen: string;
  overlayViolet: string;
  overlayGray: string;
  overlayBlack: string;
  overlayBlue30: string;

  gradientBlue: string;
  gradientYellow: string;
  gradientRed: string;
  gradientGreen: string;
  gradientViolet: string;

  linearGradientBlue: string;
  linearGradientGreen: string;

  shadowInnerDown: string;
  shadowInnerDivider: string;
  shadowInnerGreenDown: string;
  shadowInnerGreenUp: string;

  shadowLineDown: string;
  shadowLineUp: string;

  shadowDown: string;
  shadowUp: string;
  shadowRight: string;

  buttonGradientColor: string;

  borderDivider: string;
}

export enum ThemeType {
  BLACK = "black",
  LIGHT = "light",
}

export enum ScreenType {
  MD = "md",
  LG = "lg",
}

export interface IThemes {
  theme?: ThemeType;
  screen?: ScreenType;
}

export type AppThemeProps<P> = ThemedStyledProps<P, ThemeData>;

export interface ListKeyShortcuts {
  keyShortcutCategory?: string;
  keyShortcutMarket?: string;
  keyShortcutStockOrder?: string;
  keyShortcutStockOrderDer?: string;
  keyShortcutStockOrderBuy?: string;
  keyShortcutStockOrderSell?: string;
  visiblePopupConfirm?: boolean;
  isPriceInput?: boolean;
}
