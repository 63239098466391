import {
  Container,
  ErrorVerify,
  Title,
  ContentRight,
  ContentLeft,
  SubTitle,
  SubTitle2,
  LabelForm,
  Body,
  FormButton,
  BackgroundImg,
  Logo,
  ButtonBack,
  RequiredSymbol,
} from "./styles";
import { memo, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button, Spacer, Input, DatePicker } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleModalForgetPassword,
  toggleModalLogin,
  toggleModalSuccess,
} from "modules/auth/redux";
import { updateModalOtpRequest } from "modules/advance/redux";
import { ReduxStateType } from "redux/types";
import { Controller, useForm } from "react-hook-form";
import {
  forgetPasswordApi,
  validateResetPasswordOtpCodeApi,
} from "core/http/apis/auth";
import { useIntl, FormattedMessage } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  visibleModalForgetPasswordSelector,
  defauAccountForgetPasswordSelector,
} from "modules/auth/selectors";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { statusSelector } from "modules/auth/selectors";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import moment from "moment";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { toast } from "components/commons/Toast";
import { selectDataCreateUser } from "modules/auth/selectorsModalRegister";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
import storage from "helper/storage";
interface Inputs {
  masterAccount: string;
  customerId: string;
  issueDate: Date;
  email: string;
  phone: string;
}

const schema = yup.object().shape(
  {
    masterAccount: yup
      .string()
      .uppercase()
      .max(10, "auth.forgetPassword.max10")
      .required("auth.forgetPassword.errorUser"),
    customerId: yup.string().required("auth.forgetPassword.errorCMND"),
    issueDate: yup
      .date()
      .max(new Date(), "auth.forgetPassword.maxDate")
      .required("auth.forgetPassword.errorIssueDate"),
    email: yup
      .string()
      .email()
      .when("phone", {
        is: (phone: string) => !phone || phone.length === 0,
        then: yup.string().required("auth.forgetPassword.errorRequired"),
      }),
    phone: yup
      .string()
      .max(10, "auth.forgetPassword.phoneMax10")
      .matches(
        new RegExp(new RegExp(/^[0-9\b]+$/)),
        "auth.forgetPassword.errorPhone"
      )
      .when("email", {
        is: (email: string) => !email || email.length === 0,
        then: yup.string().required("auth.forgetPassword.errorRequired"),
      }),
  },
  [["email", "phone"]]
);

const ModalForgetPassword = memo(() => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [error, setError] = useState("");
  const status = useSelector(statusSelector);
  const visibleModal = useSelector(visibleModalForgetPasswordSelector);
  const defauAccount = useSelector(defauAccountForgetPasswordSelector);
  const isVisible = useSelector(isShowOtpModalSelector);
  const accountRef = useRef<HTMLInputElement>(null);
  const dataCreate = useSelector(selectDataCreateUser);

  const disabled_custType = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "COMPANY_CODE"
      )
    : {};
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,

    register,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const themeType = useSelector(themeTypeSelector);
  useEffect(() => {
    if (!visibleModal) {
      setValue("masterAccount", "");
      setValue("customerId", "");
      setValue("issueDate", "");
      setValue("email", "");
      setValue("phone", "");
    } else {
      reset();
      setError("");
      setValue("masterAccount", defauAccount);
    }
  }, [visibleModal]);
  useEffect(() => {
    if (visibleModal) {
      const autoFillAccount = () => {
        const account = getValues("masterAccount");

        if (account.length <= 10) {
          let pattern = `${disabled_custType?.value}C000000`;
          if (account) {
            const value = pattern
              .slice(0, pattern.length - account.length)
              .concat(account);

            setValue("masterAccount", value);
          } else {
            setValue("masterAccount", "");
          }
        } else {
          // return toast("bidAskBondOrder.message.maxLengthCheck", "error");
        }
      };
      const autoFillAcountKeyDown = (e: any) => {
        if (e.key === "Enter" || e.key === "Tab") {
          e.stopPropagation();
          const account = getValues("masterAccount");

          if (account.length <= 10) {
            let pattern = `${disabled_custType?.value}C000000`;
            if (account) {
              const value = pattern
                .slice(0, pattern.length - account.length)
                .concat(account);

              setValue("masterAccount", value);
            } else {
              setValue("masterAccount", "");
            }
          }
        }
      };

      accountRef.current?.addEventListener("blur", autoFillAccount);
      accountRef.current?.addEventListener("keydown", autoFillAcountKeyDown);
      return () => {
        accountRef.current?.removeEventListener("blur", autoFillAccount);
        accountRef.current?.removeEventListener(
          "keydown",
          autoFillAcountKeyDown
        );
      };
    }
  }, [visibleModal]);

  //viết hoa
  useEffect(() => {
    setValue("masterAccount", watch("masterAccount")?.toUpperCase());
  }, [watch("masterAccount")]);

  useEffect(() => {
    setValue("customerId", watch("customerId")?.toUpperCase());
  }, [watch("customerId")]);
  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const doSubmitAdvance = async (response: IVerifyResponse) => {
    const res = await validateResetPasswordOtpCodeApi({
      userId: getValues("masterAccount") ?? "",

      otpCode: response?.otp ?? "",
    });

    if (res.statusCode !== 0) {
      const resLogData = {
        eventGroup: GroupEvent.login,
        event: RealtimeConst.submitForgotPassword,
        message: "OTP fail",
        data: response?.otp,
      };
      storage.commonPushLogFirebase(resLogData);

      toast(res?.errorCode, "error", res?.message, true);
    } else {
      const res2 = await forgetPasswordApi({
        userName: getValues("masterAccount") ?? "",
        identityNumber: getValues("customerId") ?? "",
        issueDate:
          getValues("issueDate") &&
          moment(getValues("issueDate")).format("YYYYMMDD")
            ? moment(getValues("issueDate")).format("YYYYMMDD")
            : "",
        email: getValues("email") ?? "",
        mobile: getValues("phone") ?? "",
      });
      if (res2.statusCode !== 0) {
        toast(res2?.errorCode, "error", res2?.message, true);
        dispatch(updateModalOtpRequest({ isVisible: false }));
      } else {
        dispatch(updateModalOtpRequest({ isVisible: false }));
        dispatch(toggleModalForgetPassword());
        setValue("masterAccount", "");
        setValue("customerId", "");
        setValue("issueDate", "");
        setValue("email", "");
        setValue("phone", "");
        dispatch(
          toggleModalSuccess({
            content: intl.formatMessage({
              id: "auth.forgetPassword.forgetPasswordSuccess",
            }),
            hasIntlKey: false,
          })
        );
      }
    }
    const resLogData = {
      eventGroup: GroupEvent.login,
      event: RealtimeConst.submitForgotPassword,
      message: "success",
    };
    storage.commonPushLogFirebase(resLogData);
  };
  const onSubmit = async ({
    masterAccount,
    customerId,
    issueDate,
    email,
    phone,
  }: Inputs) => {
    dispatch(updateModalOtpRequest({ isVisible: true }));

    try {
    } catch (error: any) {
      // toast(error.code, "error", error.description, true);
      setError(error.code);
    }
  };
  return (
    <>
      <Modal
        show={visibleModal}
        centered
        onHide={() => {
          dispatch(toggleModalForgetPassword());
          // dispatch(toggleModalLogin(true));
        }}
        keyboard={false}
        // dialogClassName="modal-468"
        size={"lg"}
      >
        <Container>
          <ContentLeft>
            <BackgroundImg />
            <Logo>
              <FormattedMessage id="auth.forgetPassword.logo" />
            </Logo>
          </ContentLeft>
          <ContentRight>
            <Title className="pt-2">
              <FormattedMessage id="auth.forgetPassword.forgetPassword" />
            </Title>
            <SubTitle className="pb-2">
              <FormattedMessage id="auth.forgetPassword.subTitle" />
            </SubTitle>
            <Body>
              <div className="text-grey">
                <div className="text-white">
                  <Input
                    {...register("masterAccount")}
                    name="masterAccount"
                    maxLength={10}
                    innerRef={accountRef}
                    title={intl.formatMessage({ id: "auth.login.username" })}
                    rules={{ required: true }}
                    isRequired
                    control={control}
                    placeholder={intl.formatMessage({
                      id: "auth.forgetPassword.enterAccount",
                    })}
                  />
                </div>
              </div>
              <span className="text-error">
                {errors.masterAccount?.message &&
                  intl.formatMessage({ id: errors.masterAccount?.message })}
              </span>

              <Spacer size="m" />
              <div className="text-grey">
                <div className="text-white">
                  <Input
                    {...register("customerId")}
                    name="customerId"
                    title={intl.formatMessage({
                      id: "auth.forgetPassword.CMND",
                    })}
                    rules={{ required: true }}
                    isRequired
                    control={control}
                    placeholder={intl.formatMessage({
                      id: "auth.forgetPassword.enterCMND",
                    })}
                    // onKeyPress={(e) => {
                    //   const re = /[0-9a-fA-F]+/g;
                    //   if (!re.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </div>
              </div>
              <span className="text-error">
                {errors.customerId?.message &&
                  intl.formatMessage({ id: errors.customerId?.message })}
              </span>

              <Spacer size="m" />

              <LabelForm>
                <span className="text-grey">
                  <FormattedMessage id="auth.forgetPassword.issueDate" />
                </span>
                <RequiredSymbol className="text-danger pl-1 mb-0">
                  *
                </RequiredSymbol>
              </LabelForm>
              <div className="text-white">
                <Controller
                  {...register("issueDate")}
                  name="issueDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date: Date) => field.onChange(date)}
                      popperPlacement="bottom-start"
                    />
                  )}
                />
              </div>
              <span className="text-error">
                {errors.issueDate?.message &&
                  intl.formatMessage({ id: errors.issueDate?.message })}
              </span>

              <Spacer size="m" />
              <SubTitle2>
                <FormattedMessage id="auth.forgetPassword.subTitle2" />
              </SubTitle2>
              <div className="text-grey">
                <div className="text-white">
                  <Input
                    {...register("email")}
                    name="email"
                    title={intl.formatMessage({
                      id: "auth.forgetPassword.email",
                    })}
                    control={control}
                    placeholder={intl.formatMessage({
                      id: "auth.forgetPassword.enterEmail",
                    })}
                    // onKeyPress={(e) => {
                    //   const re = /[0-9a-fA-F]+/g;
                    //   if (!re.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </div>
              </div>
              <span className="text-error">
                {errors.email?.message &&
                  intl.formatMessage({ id: errors.email?.message })}
              </span>

              <Spacer size="m" />
              <div className="text-grey">
                <div className="text-white">
                  <Input
                    {...register("phone")}
                    name="phone"
                    control={control}
                    title={intl.formatMessage({
                      id: "auth.forgetPassword.phone",
                    })}
                    placeholder={intl.formatMessage({
                      id: "auth.forgetPassword.enterPhone",
                    })}
                    // onKeyPress={(e) => {
                    //   const re = /[0-9a-fA-F]+/g;
                    //   if (!re.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </div>
              </div>
              <span className="text-error">
                {errors.phone?.message &&
                  intl.formatMessage({ id: errors.phone?.message })}
              </span>

              <ErrorVerify>
                {error !== "" && intl.formatMessage({ id: error })}
              </ErrorVerify>
              <FormButton>
                <Button
                  style={{
                    background: `${themes[themeType].grey50}`,
                    flex: "1",
                    color: `${themes[themeType].grey90}`,
                    fontWeight: "bold",
                    fontSize: `${themes[themeType].typeFaces.headline.size}`,

                    padding: "18px 0px",
                  }}
                  width="100%"
                  fill
                  disabled={!!errors.customerId || !!errors.masterAccount}
                  loading={status === ReduxStateType.LOADING}
                  onClick={handleSubmit(onSubmit)}
                >
                  <FormattedMessage id="auth.forgetPassword.buttonAccept" />
                </Button>
                <ButtonBack
                  // style={{ flex: 1 }}
                  // color="accept"
                  // width="100%"
                  // loading={status === ReduxStateType.LOADING}
                  onClick={() => {
                    dispatch(toggleModalForgetPassword());
                    dispatch(toggleModalLogin(true));
                  }}
                >
                  <FormattedMessage id="auth.forgetPassword.buttonCancel" />
                </ButtonBack>
              </FormButton>
            </Body>
          </ContentRight>
        </Container>
        {isVisible && (
          <OTPByBusinessCd
            handleClose={handleCloseOTPModal}
            getResult={doSubmitAdvance}
            businessCd={EBusinessCd.RESET_PASSWORD}
            userId={getValues("masterAccount") ?? ""}
          />
        )}
      </Modal>
    </>
  );
});

export default ModalForgetPassword;
