import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export interface ManagerDevice {
  listDevice: any;
  loading: boolean;
}

const initialState: ManagerDevice = {
  listDevice: [],
  loading: false,
};

const managerDeviceSlice = createSlice({
  name: "managerDeviceSlice",
  initialState: cloneDeep(initialState),

  reducers: {
    getListDeviceRequest: (state, action: PayloadAction<any>) => {},
    getListDeviceSuccess: (state, action: PayloadAction<any>) => {
      state.listDevice = action.payload?.data;
    },
    getListDeviceFailure: (state, action: PayloadAction<any>) => {},

    refreshDeviceRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    refreshDeviceSuccess: (state, action: PayloadAction<any>) => {
      state.listDevice = action.payload?.data;
      state.loading = false;
    },
    refreshDeviceFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
  },
});

export const {
  getListDeviceRequest,
  getListDeviceSuccess,
  getListDeviceFailure,

  refreshDeviceRequest,
  refreshDeviceSuccess,
  refreshDeviceFailure,
} = managerDeviceSlice.actions;

export default managerDeviceSlice.reducer;
