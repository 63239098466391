import styled from "styled-components/macro";

export const WatchListCustomDropDown = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.popup};
  width: 400px;
  background-color: ${({ theme }) => theme.background3};
  border-radius: 4px;
  padding: 8px;
  display: none;

  .custom {
    background: ${({ theme }) => theme.background4};
    max-height: 500px;
    overflow: auto;

    @media (max-height: 800px) {
      max-height: 300px;
    }
  }

  .clear-border {
    border: 0;
  }

  .addWatchlist {
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }

  .p8 {
    padding: 4px 8px;
  }

  .p16 {
    padding: 16px;
  }

  .w140 {
    width: 140px;
  }

  .title {
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;
export const WatchListReadOnlyWrapper = styled.div<{ twoCol?: boolean }>`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.popup};
  width: ${({ twoCol }) => (twoCol ? "400px" : "230px")};
  background-color: ${({ theme }) => theme.background3};
  border-radius: 4px;
  display: none;
  .custom {
    ${({ twoCol }) =>
      twoCol &&
      `    display: grid;
    grid-template-columns: 1fr 1fr;`}
  }
`;

export const ItemCustom = styled.div<{ isActive: boolean }>`
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;

  :hover {
    background-color: ${({ theme }) => theme.background4};
    .showWhenHover {
      display: flex !important;
    }
  }

  .input-name {
    flex: 1;
    padding: 4px 8px;
    ${({ isActive, theme }) => isActive && `color:${theme.blue50} !important;`}
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }

  .name {
    flex: 1;
    padding: 4px 8px;
    ${({ isActive, theme }) => isActive && `color:${theme.blue50} !important;`}
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }

  .name-item {
    ${({ isActive, theme }) =>
      `color:${isActive ? theme.brandPrimary : theme.textcolorWhite};`}
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  }

  .icon-box {
    padding: 8px 12px;
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      margin-right: 8px;
    }
    svg {
      path {
        fill: ${({ theme }) => theme.textcolorWhite};
      }
    }
    &.showWhenHover {
      display: none;
    }
  }
`;
