import { createSelector } from "@reduxjs/toolkit";
import { OrderFlowDistributionState } from "modules/tickerDetails/redux/orderFlowDistribution";
import { RootState } from "redux/store";
import { InfoQuoteState } from "modules/stock-order-der/redux/priceList";
import { VolumeAndTimeState } from "modules/tickerDetails/redux/volumeAndTime";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";

export const stockOrderDerRootSelector = (state: RootState) =>
  state.stockOrderDer.placeOrderDer;

export const tickerInfoSelector = (state: RootState) =>
  state.tickerDetails.tickerInfo;

export const categoryRootSelector = (state: RootState) => state.categories.root;

export const selectedTickerSelector = createSelector(
  stockOrderDerRootSelector,
  (state) => state.data.currentTicker
);
// ================================================
// OrderFlowDistribution Start

export const orderFlowDistributionSelector = (state: RootState) =>
  state.tickerDetails.orderFlowDistribution;

export const orderFlowDistributionChartSelector = createSelector(
  orderFlowDistributionSelector,
  (state: OrderFlowDistributionState) => ({
    chartInflow: state.dataInflow,
    chartOutflow: state.dataOutflow,
    pieChartFlow: state.dataPieChartFlow,
    totalInflow: state.totalInflow,
    totalOutflow: state.totalOutflow,
  })
);
// OrderFlowDistribution End
// =========================
// Price List Start

export const priceListSelector = (state: RootState) =>
  state.stockOrderDer.priceList;

export const sumAskSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.sumAsk
);

export const sumBidSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.sumBid
);

export const percentBuySelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.percentBuy
);
export const dataDetailInfoSelector = (
  value: keyof SecQuotesDetailResponse.SecDetailInfo.AsObject
) =>
  createSelector(
    priceListSelector,
    (state: InfoQuoteState) => state.detailInfo?.[value]
  );
export const basicpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.basicprice
);
export const ceilingpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.ceilingprice
);
export const floorpriceDetailInfoSelector = createSelector(
  priceListSelector,
  (state: InfoQuoteState) => state.detailInfo.floorprice
);
// Price List End
// =========================
// Volume and Time Start
const volumeAndTimeSelector = (state: RootState) =>
  state.stockOrderDer.volumeAndTime;

export const volumeListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.volumeList
);

export const historyListSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.historyList
);

export const tnTimeSaleSelector = createSelector(
  volumeAndTimeSelector,
  (state: VolumeAndTimeState) => state.tnTimeSale
);
// Volume and Time End
// ========================
// Ticker Info Start
// export const tickerInfoWidgetSelector = createSelector(
//   tickerInfoSelector,
//   (state: TickerInfoState) => ({
//     tickerInfoBasic: state.tickerInfoBasic,
//     tickerInfoLastSale: state.tickerInfoLastSale,
//     tickerInfoSummary: state.tickerInfoSummary,
//     organizationFundamental: state.organizationFundamental,
//     tickerInfoForeignRoom: state.tickerInfoForeignRoom,
//   })
// );

// export const tickersListInCategorySelector = createSelector(
//   categoryRootSelector,
//   (state) => state.tickers
// );

// Ticker Info End
