import React from "react";
import { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import moment from "moment";
import {
  createGradientPlugin,
  NetflowGradientPlugin,
  NetflowYAxisOffsetPlugin,
} from "helper/chartPlugins";
import numeral from "numeral";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";
import { marketNetflowSelector } from "modules/market/redux/selectors";

const GradientBgPlugin: any = createGradientPlugin("rgba(65, 188, 228, 0.2)");

const AdIndexChart: React.FC = () => {
  const themeType = useSelector(themeTypeSelector);
  const { values, colors, labels } = useSelector(marketNetflowSelector);

  const borderColor = colors.map((item) => getChartColor(item));

  function getChartColor(colorCode: number): string {
    switch (colorCode) {
      case -1:
        return themes[themeType].solidRed;
      case 1:
        return themes[themeType].solidGreen;
      default:
        return themes[themeType].solidYellow;
    }
  }

  const plugins = [
    GradientBgPlugin,
    NetflowGradientPlugin,
    NetflowYAxisOffsetPlugin(themes[themeType]),
  ];

  const data = {
    labels,
    thresholdValue: 0,
    lineColor: {
      positive: themes[themeType].solidGreen,
      negative: themes[themeType].solidRed,
      vertical: themes[themeType].textcolorBlack,
      horizontal: themes[themeType].textcolorWhite,
    },
    datasets: [
      {
        data: [...values],
        borderColor: [...borderColor],
        backgroundColor: [...borderColor],
        tension: 0.1,
        pointRadius: 0.1,
        pointHitRadius: 0.1,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 20,
      },
    },
    maintainAspectRatio: false,
    cornerRadius: 4,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      mode: "x-axis",
      callbacks: {
        label: function (tooltipItem: any) {
          return tooltipItem.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: "closedLine",
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: "#ADB3C0",
            callback: function (value: any) {
              return numeral(value).format("0,0");
            },
          },
          gridLines: {
            // tickMarkLength: 0,
            display: true,
            borderDash: [2, 4],
            color: themes[themeType].grey100,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            // autoSkip: false,
            maxTicksLimit: 20,
            maxRotation: 90,
            minRotation: 90,
            fontSize: 10,
            callback: function (value: string) {
              return moment(value, "HH:mm:ss").format("HH:mm");
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {},
  } as unknown as ChartOptions;

  return <Line options={options} data={data} plugins={plugins} />;
};

export default AdIndexChart;
