import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.background2};
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  .text-color-light {
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

export const Title = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.background3};
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  .flex-1 {
    flex: 1;
  }
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ButtonExit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const TitleContent = styled.div<{ side?: string }>`
  flex: 3;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  ${({ side, theme }) => {
    switch (side) {
      case "orderBook.BUY":
        // success
        return `color: ${theme.green50}`;
      case "orderBook.SELL":
        // reject
        return `color: ${theme.red50}`;
      default:
        // pending
        return `color: ${theme.textcolorWhite}`;
    }
  }};
  text-align: center;
`;

export const Body = styled.div`
  display: block;
  background: ${({ theme }) => theme.background2};
  padding: 30px 30px 0 30px;
  .d-flex {
    justify-content: space-between;
    .w-80 {
      width: 280px;
    }
  }
`;

export const Footer = styled.div`
  padding: 30px;
  .btn {
    width: 190px;
  }
`;

export const RejectReason = styled.div`
  color: ${({ theme }) => theme.solidRed};
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
  margin-left: 4px;
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.grey90};
  height: 52px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SummaryItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Value = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;
