import HttpScreener from "core/http/singleton/screener";
import { IScreenerResponse } from "domain/models/Screener";
import {
  IDeleteScreenerParam,
  IGetScreenerListParam,
  ISaveScreenerBody,
  ISaveScreenerParam,
} from "./types";
import { deleteScreener, getScreenerList, saveScreener } from "./urls";

export const getScreenerListApi = async (
  params: IGetScreenerListParam
): Promise<IScreenerResponse> => {
  const res = await HttpScreener.get<IScreenerResponse>(getScreenerList, {
    ...params,
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const saveScreenerApi = async (
  params: ISaveScreenerParam,
  body: ISaveScreenerBody
): Promise<IScreenerResponse> => {
  const res = await HttpScreener.post<IScreenerResponse>(
    saveScreener,
    JSON.stringify(body),
    {
      params,
      headers: { "Content-Type": "application/json" },
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteScreenerApi = async (
  params: IDeleteScreenerParam
): Promise<IScreenerResponse> => {
  const res = await HttpScreener.delete<IScreenerResponse>(deleteScreener, {
    params,
  });
  if (!res || res.status === 1) throw new Error("Opps");
  return res.data;
};
