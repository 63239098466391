import { Button, Input } from "components/commons";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import {
  getBondsList,
  getSubAccoNo,
} from "core/http/apis/place-order-transfer-bonds";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList, GetParameters } from "helper/sessionData";
import { selectDataCreateUser } from "modules/auth/selectorsModalRegister";
import {
  calculateBondFeeRequest,
  getAccountRequest,
  getBondsAvailSellRequest,
  getListTransferBondRequest,
  getSubAccoNoRequest,
  updateBondFee,
} from "modules/place-order-transfer-bonds/redux";
import {
  bondsAvailSellSelector,
  calculateBondFeeSelector,
  getAccountSelector,
  getSubAccoNoSelector,
} from "modules/place-order-transfer-bonds/selectors";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirm from "../ModalNotification";
import {
  DetailCol,
  DetailFee,
  DetailInput,
  DetailInputAccount,
  DetailInputRight,
  DetailInputWrapper,
  DetailLabel,
  DetailLabelFee,
  DetailRowButton,
  DetailRowMarginTB,
  DetailRowPaddingBorder,
  DetailRowPaddingTB,
  DetailRowTop,
  DetailWrapper,
  FlexItem,
} from "./styles";

interface Options {
  label: string;
  value: string;
}

function InformationOrder() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const accountNo = localStorage.getItem("mbs_master_account");

  const account = useSelector(getAccountSelector);
  const bondsAvailSell = useSelector(bondsAvailSellSelector);
  const bondFee = useSelector(calculateBondFeeSelector);
  const subAccoNo = useSelector(getSubAccoNoSelector);

  const [price, setPrice] = useState<string>();
  const [quantity, setQuantity] = useState<string>();
  const [subAccoNos, setSubAccoNos] = useState<any>();
  const [bondValue, setBond] = useState<any>();
  const [labelbond, setLabelBond] = useState<any>();
  const [feePayer, setFeePayer] = useState<any>();
  const [taxPayer, setTaxPayer] = useState<any>();
  const [disCustType, setDisCustType] = useState<any>();
  const [bonCdThan0, setListBonCdThan0] = useState<any>();
  const [newBondList, setNewBondList] = useState<Options[]>([
    { label: "", value: "" },
  ]);
  const [bondListFull, setBondListFull] = useState<Options[]>([
    { label: "", value: "" },
  ]);
  const [newBondsAvailSell, setNewBondsAvailSell] = useState<any>({});
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const usernameRef = useRef<HTMLInputElement>(null);
  const [dataConfirm, setDataConfirm] = useState({
    subAccoNoFrom: "",
    nameAccoNoFrom: "",
    subAccoNoTo: "",
    nameAccoNoTo: "",
    bondCd: "",
    qty: "0",
    price: "",
    parAmt: 0,
    transferAmt: 0,
    feeAmt: 0,
    taxAmt: 0,
    feePayer: "",
    taxPayer: "",
    remarks: "",
    timeStamp: "0",
  });

  const { control, setValue, getValues } = useForm({
    defaultValues: { accountTo: "" },
  });

  const fromDate = new Date();
  const toDate = new Date();

  const dataCreate = useSelector(selectDataCreateUser);
  const paramHd = GetParameters(
    "CB00",
    subAccoNo && subAccoNo?.custType === 1
      ? "CBO_TAX_RATE"
      : subAccoNo && subAccoNo?.custType === 2
      ? "CBO_ORG_TAX_RATE"
      : account && account?.custType === 1
      ? "CBO_TAX_RATE"
      : account && account?.custType === 2
      ? "CBO_ORG_TAX_RATE"
      : "CBO_TAX_RATE"
  )?.toString();

  const optionFeePayer = [
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.infomationPlace.personTransfer",
      })}`,
      value: "1",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.infomationPlace.personGet",
      })}`,
      value: "2",
    },
  ];

  const listAcc = GetAccoList(GetAccountType.BOND, EntrustBusinessCode.All);

  const valueDenomination = useMemo(() => {
    const faceValPrice = bonCdThan0?.filter(
      (item: any) => item?.bondCd === bondValue?.value
    )?.[0]?.faceValPrice;
    return faceValPrice * Number(quantity?.replaceAll(",", ""));
  }, [bondValue, quantity]);

  const valueTransfer = useMemo(() => {
    const newQ = quantity ? Number(quantity?.replaceAll(",", "")) : 0;
    const newP = price ? Number(price?.replaceAll(",", "")) : 0;

    return newQ * newP;
  }, [quantity, price]);

  const calculatorTax = useMemo(() => {
    return valueTransfer * +paramHd;
  }, [paramHd, valueTransfer]);

  const handleChangeQuantity = (e: any) => {
    setQuantity(e.target.value);
    if (e.target.value === "") {
      dispatch(updateBondFee({}));
    }
  };
  const handleChangePrice = (e: any) => {
    setPrice(e.target.value);
  };

  const handleChangeBondList = (value: any) => {
    const bondLabel = bondListFull?.filter(
      (item: any) => item?.value === value?.value
    )?.[0];

    const bondValue = newBondList?.filter(
      (item: any) => item?.value === value?.value
    )?.[0];

    setLabelBond(bondLabel?.label);
    setBond({
      label: bondValue?.value,
      value: bondValue?.value,
    });
    dispatch(
      getBondsAvailSellRequest({
        bondCd: value?.label,
        custNo: accountNo,
        custCd: "",
        type: 1,
      })
    );
    setQuantity("");
    setPrice("");
    setFeePayer(optionFeePayer?.[0]);
    setTaxPayer(optionFeePayer?.[0]);
    dispatch(updateBondFee({}));
  };

  const handleChangeFeePayer = (value: any) => {
    setFeePayer(value);
    dispatch(
      calculateBondFeeRequest({
        bondCd: bondValue ? bondValue?.value : "",
        feeType: value?.value === "1" ? "1" : value?.value === "2" ? "2" : "1",
        parAmt: valueDenomination ? valueDenomination : "",
        subAccoNo:
          value?.value === "1"
            ? listAcc?.[0]?.subAccoNo
            : value?.value === "2"
            ? getValues("accountTo")
            : listAcc?.[0]?.subAccoNo,
      })
    );
  };

  const handleChangeTaxPayer = (value: any) => {
    setTaxPayer(value);
    let accoCo;
    if (value?.value === "1") {
      accoCo = listAcc?.[0]?.subAccoNo;
    } else {
      const accountTo = getValues("accountTo");
      accoCo = accountTo;
    }

    dispatch(
      getSubAccoNoRequest({
        subAccoNo: accoCo,
      })
    );
  };

  const handleSubmit = () => {
    const user = getValues("accountTo");
    if (Number(quantity?.replace(",", "")) < newBondsAvailSell?.availQty) {
      setVisibleModalConfirm(true);
      setDataConfirm((state: any) => {
        return {
          ...state,
          subAccoNoFrom: listAcc?.[0]?.subAccoNo,
          nameAccoNoFrom: account?.custFamilyName
            ?.concat(" ")
            .concat(account?.custGivenName),
          subAccoNoTo: user,
          nameAccoNoTo: subAccoNos?.custFamilyName
            ?.concat(" ")
            .concat(subAccoNos?.custGivenName),
          bondCd: bondValue ? bondValue?.value : 0,
          qty: quantity ? quantity?.replaceAll(",", "") : "0",
          price: price ? price?.replaceAll(",", "") : "0",
          parAmt: valueDenomination ? valueDenomination : 0,
          transferAmt: valueTransfer ? valueTransfer : 0,
          feeAmt: bondFee ? bondFee : 0,
          taxAmt: calculatorTax ? calculatorTax : 0,
          feePayer: feePayer ? feePayer : optionFeePayer?.[0],
          taxPayer: taxPayer ? taxPayer : optionFeePayer?.[0],
          remarks: "",
          timeStamp: "0",
        };
      });
    } else {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.infomationPlace.volumeLess",
        })}`,
        "error"
      );
    }
  };

  const cancelValue = () => {
    setBond({});
    setLabelBond("");
    setNewBondsAvailSell({});
    setQuantity("");
    setPrice("");
    setFeePayer(optionFeePayer?.[0]);
    setTaxPayer(optionFeePayer?.[0]);
    dispatch(updateBondFee({}));
  };

  const handleCancel = () => {
    setBond({});
    setLabelBond("");
    setNewBondsAvailSell({});
    setQuantity("");
    setPrice("");
    setValue("accountTo", "");
    setSubAccoNos("");
    setFeePayer(optionFeePayer?.[0]);
    setTaxPayer(optionFeePayer?.[0]);
    dispatch(updateBondFee({}));
  };

  const handleCloseModal = () => {
    setVisibleModalConfirm(false);
    dispatch(
      getListTransferBondRequest({
        fromDate: moment(fromDate?.setDate(toDate?.getDate() - 30)).format(
          "YYYYMMDD"
        ),
        toDate: moment(toDate).format("YYYYMMDD"),
        status: "-1",
      })
    );
    handleCancel();
  };

  const handleConfirm = () => {};

  const findBondList = async (value: string) => {
    const response = await getBondsList({ subAccoNo: value });
    if (response && response?.statusCode === 0) {
      const bonCDThan0 = response?.data?.filter((item: any) => {
        if (item?.quantity > 0) {
          return { item };
        } else return null;
      });

      const bonCdValue = bonCDThan0?.map((item: any) => {
        return { label: item?.bondCd, value: item?.bondCd };
      });
      const bonCdFull = bonCDThan0?.map((item: any) => {
        return { label: item?.bondName, value: item?.bondCd };
      });

      setListBonCdThan0(bonCDThan0);
      setNewBondList(bonCdValue);
      setBondListFull(bonCdFull);
    }
  };

  const autoFillUser = async () => {
    let response: any;
    let userLengthLess6;
    let userLengthThan6;
    let user = getValues("accountTo");
    if (user.length <= 6) {
      const partern = `${disCustType?.value}C000000`;
      const value = partern.slice(0, partern.length - user.length).concat(user);
      userLengthLess6 = value.concat(".60");
      if (userLengthLess6 === listAcc?.[0]?.subAccoNo) {
        toast(
          `${intl.formatMessage({
            id: "placeOrderTransferBonds.infomationPlace.errorAccTo",
          })}`,
          "error"
        );
      } else {
        response = await getSubAccoNo({ subAccoNo: userLengthLess6 });
      }
    } else if (user.length === 13) {
      userLengthThan6 = user;
      if (userLengthThan6 === listAcc?.[0]?.subAccoNo) {
        toast(
          `${intl.formatMessage({
            id: "placeOrderTransferBonds.infomationPlace.errorAccTo",
          })}`,
          "error"
        );
      } else {
        response = await getSubAccoNo({ subAccoNo: userLengthThan6 });
      }
    }
    if (
      response &&
      response?.statusCode === 0 &&
      response?.data?.accoType === 7
    ) {
      setValue("accountTo", response?.data?.subAccoNo);
      setSubAccoNos(response?.data);
      cancelValue();
    } else if (response && response?.data?.accoType !== 7) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.infomationPlace.errorAccount",
        })}`,
        "error"
      );
      handleCancel();
    }
  };

  const onKeyDown = async (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      autoFillUser();
    }
  };

  useEffect(() => {
    const disabled_custType = dataCreate?.paramList
      ? dataCreate?.paramList.find(
          (item: any) => item?.paramName === "COMPANY_CODE"
        )
      : {};
    setDisCustType(disabled_custType);
  }, [dataCreate]);

  useEffect(() => {
    if (disCustType && disCustType?.value) {
      const autoFillUser = async () => {
        let response: any;
        let userLengthLess6;
        let userLengthThan6;
        let user = getValues("accountTo");
        if (user.length <= 6) {
          const partern = `${disCustType?.value}C000000`;
          const value = partern
            .slice(0, partern.length - user.length)
            .concat(user);
          userLengthLess6 = value.concat(".60");
          if (userLengthLess6 === listAcc?.[0]?.subAccoNo) {
            toast(
              `${intl.formatMessage({
                id: "placeOrderTransferBonds.infomationPlace.errorAccTo",
              })}`,
              "error"
            );
          } else {
            response = await getSubAccoNo({ subAccoNo: userLengthLess6 });
          }
        } else if (user.length === 13) {
          userLengthThan6 = user;
          if (userLengthThan6 === listAcc?.[0]?.subAccoNo) {
            toast(
              `${intl.formatMessage({
                id: "placeOrderTransferBonds.infomationPlace.errorAccTo",
              })}`,
              "error"
            );
          } else {
            response = await getSubAccoNo({ subAccoNo: userLengthThan6 });
          }
        }
        if (
          response &&
          response?.statusCode === 0 &&
          response?.data?.accoType === 7
        ) {
          setValue("accountTo", response?.data?.subAccoNo);
          setSubAccoNos(response?.data);
          cancelValue();
        } else if (response && response?.data?.accoType !== 7) {
          toast(
            `${intl.formatMessage({
              id: "placeOrderTransferBonds.infomationPlace.errorAccount",
            })}`,
            "error"
          );
          handleCancel();
        }
      };
      usernameRef.current?.addEventListener("blur", autoFillUser);
    }
  }, [disCustType]);

  useEffect(() => {
    findBondList(listAcc?.[0]?.subAccoNo);
    dispatch(
      getListTransferBondRequest({
        fromDate: moment(fromDate?.setDate(toDate?.getDate() - 30)).format(
          "YYYYMMDD"
        ),
        toDate: moment(toDate).format("YYYYMMDD"),
        status: "-1",
      })
    );
    dispatch(
      getAccountRequest({
        custCd: "",
        custNo: accountNo,
      })
    );
  }, []);

  useEffect(() => {
    if (bondsAvailSell) {
      setNewBondsAvailSell(bondsAvailSell);
    }
  }, [bondsAvailSell, accountNo]);

  useEffect(() => {
    if (valueDenomination) {
      dispatch(
        calculateBondFeeRequest({
          bondCd: bondValue ? bondValue?.value : "",
          feeType:
            feePayer?.value === "1" ? "1" : feePayer?.value === "2" ? "2" : "1",
          parAmt: valueDenomination ? valueDenomination : "",
          subAccoNo:
            feePayer?.value === "1"
              ? listAcc?.[0]?.subAccoNo
              : feePayer?.value === "2"
              ? getValues("accountTo")
              : listAcc?.[0]?.subAccoNo,
        })
      );
    }
  }, [valueDenomination]);

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowTop>
          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.accoNoFrom" />
            </DetailLabel>
            <DetailInputWrapper className="text-left">
              <Input value={listAcc?.[0]?.subAccoNo} disabled={true} />
            </DetailInputWrapper>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.nameAccoNoFrom" />
            </DetailLabel>
            <DetailInputAccount>
              {account?.custFamilyName
                ?.concat(" ")
                .concat(account?.custGivenName)}
            </DetailInputAccount>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.accoNoTo" />
            </DetailLabel>
            <DetailInputWrapper>
              <Input
                name="accountTo"
                control={control}
                onKeyDown={onKeyDown}
                innerRef={usernameRef}
              />
            </DetailInputWrapper>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.nameAccoNoTo" />
            </DetailLabel>
            <DetailInputAccount>
              {subAccoNos?.custFamilyName
                ?.concat(" ")
                .concat(subAccoNos?.custGivenName)}
            </DetailInputAccount>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.bondCd" />
            </DetailLabel>
            <DetailInputWrapper>
              <CSelect
                options={newBondList && newBondList}
                onChange={handleChangeBondList}
                value={bondValue && bondValue}
              ></CSelect>
            </DetailInputWrapper>
          </DetailRowPaddingTB>
          <DetailRowPaddingTB>
            <DetailLabel></DetailLabel>
            <DetailInputAccount>{labelbond}</DetailInputAccount>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.quantity" />
            </DetailLabel>
            <DetailInputWrapper>
              <Input
                value={quantity ? numeral(quantity).format("0,0") : ""}
                onChange={handleChangeQuantity}
              ></Input>
            </DetailInputWrapper>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel></DetailLabel>
            <DetailInput>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.quantityApply" />
              <DetailInputRight>
                {newBondsAvailSell && newBondsAvailSell?.availQty
                  ? Intl.NumberFormat().format(newBondsAvailSell?.availQty)
                  : 0}
              </DetailInputRight>
            </DetailInput>
          </DetailRowPaddingTB>

          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.price" />
            </DetailLabel>
            <DetailInputWrapper>
              <Input
                value={price ? numeral(price).format("0,0") : ""}
                onChange={handleChangePrice}
              ></Input>
            </DetailInputWrapper>
          </DetailRowPaddingTB>
          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.feePayer" />
            </DetailLabel>
            <DetailInputWrapper>
              <CSelect
                options={optionFeePayer}
                defaultValue={optionFeePayer?.[0]}
                onChange={handleChangeFeePayer}
                value={feePayer}
              ></CSelect>
            </DetailInputWrapper>
          </DetailRowPaddingTB>
          <DetailRowPaddingBorder>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.taxPayer" />
            </DetailLabel>
            <DetailInputWrapper>
              <CSelect
                options={optionFeePayer}
                defaultValue={optionFeePayer?.[0]}
                onChange={handleChangeTaxPayer}
                value={taxPayer}
              ></CSelect>
            </DetailInputWrapper>
          </DetailRowPaddingBorder>

          <DetailRowMarginTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.parAmt" />
            </DetailLabel>
            <DetailInputAccount>
              {valueDenomination ? numeral(valueDenomination).format("0,0") : 0}
            </DetailInputAccount>
          </DetailRowMarginTB>
          <DetailRowPaddingTB>
            <DetailLabel>
              <FormattedMessage id="placeOrderTransferBonds.infomationPlace.transferAmt" />
            </DetailLabel>
            <DetailInputAccount>
              {valueTransfer ? numeral(valueTransfer).format("0,0") : 0}
            </DetailInputAccount>
          </DetailRowPaddingTB>
          <DetailFee>
            <FlexItem>
              <DetailLabelFee>
                <FormattedMessage id="placeOrderTransferBonds.infomationPlace.fee" />
              </DetailLabelFee>
              <DetailInputRight>
                {numeral(bondFee).format("0,0")}
              </DetailInputRight>
            </FlexItem>
            <FlexItem>
              <DetailLabelFee>
                <FormattedMessage id="placeOrderTransferBonds.infomationPlace.tax" />
              </DetailLabelFee>
              <DetailInputRight>
                {calculatorTax
                  ? numeral(Math.round(calculatorTax)).format("0,0")
                  : 0}
              </DetailInputRight>
            </FlexItem>
          </DetailFee>
        </DetailRowTop>

        <DetailRowButton>
          <Button
            color="accept"
            className="button-filter w-100 m-1"
            onClick={handleCancel}
          >
            {intl.formatMessage({
              id: "placeOrderTransferBonds.infomationPlace.cancel",
            })}
          </Button>
          <Button
            color="accept"
            className="button-filter m-1 w-100"
            onClick={handleSubmit}
            fill={true}
          >
            {intl.formatMessage({
              id: "placeOrderTransferBonds.infomationPlace.submit",
            })}
          </Button>
        </DetailRowButton>
      </DetailCol>

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        handleConfirm={handleConfirm}
        title={intl.formatMessage({
          id: "placeOrderTransferBonds.modal.title",
        })}
        content={dataConfirm}
        getResult={handleCloseModal}
        handleCloseModal={() => setVisibleModalConfirm(!visibleModalConfirm)}
      ></ModalConfirm>
    </DetailWrapper>
  );
}

export default InformationOrder;
