import VgaiaHttp from "core/http/singleton/vgaia";
import queryString from "query-string";

interface paramGetData {
  secCd: string | null;
  tradeType: number;
  confirmStatus?: number;
  fromDate?: number;
  toDate?: number;
}

export interface paramConfirm {
  input: string;
}

export const findOrderForConfirmApi = async (params: paramGetData) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/findOrderForConfirm${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const updateOrderConfirmStatusArrApi = async (params: paramConfirm) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.post(
    `/updateOrderConfirmStatusArr${
      paramsFormatter ? `?${paramsFormatter}` : ""
    }`
  );
  return res?.data;
};
