import { FormattedMessage, useIntl } from "react-intl";
import { Wrap, Container, WrapText, WrapContent, WrapLi } from "./style";
import { Button } from "components/commons";
import { useDispatch } from "react-redux";
import { setvisibleModalRegister } from "modules/auth/redux";
import { resetRegister } from "modules/auth/redux/modalRegister";
const StepConfirm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleOpenModalRegister = () => {
    dispatch(resetRegister());
    dispatch(setvisibleModalRegister());
  };
  return (
    <Container>
      <Wrap>
        <WrapText>
          <FormattedMessage id={"auth.register.confirm.content1"} />
        </WrapText>
        <WrapText>
          <FormattedMessage id={"auth.register.confirm.content2"} />
        </WrapText>
        <WrapContent>
          <WrapText>
            <FormattedMessage id={"auth.register.confirm.content3"} />
          </WrapText>
          <WrapText>
            <FormattedMessage id={"auth.register.confirm.content4"} />
          </WrapText>
          <WrapText>
            <WrapLi>
              <FormattedMessage id={"auth.register.confirm.content5"} />
            </WrapLi>
            <WrapLi>
              <FormattedMessage id={"auth.register.confirm.content6"} />
            </WrapLi>

            <WrapLi>
              <FormattedMessage id={"auth.register.confirm.content7"} />
            </WrapLi>
          </WrapText>
        </WrapContent>

        <WrapText>
          <FormattedMessage id={"auth.register.confirm.content8"} />
        </WrapText>

        <WrapText>
          <FormattedMessage id={"auth.register.confirm.content9"} />
        </WrapText>
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="gradientaAccept"
          className="button-filter"
          width="120px"
          onClick={() => handleOpenModalRegister()}
        >
          {intl.formatMessage({
            id: "auth.register.btnLogin",
          })}
        </Button>
      </div>
    </Container>
  );
};

export default StepConfirm;
