import {
  INDEX_COLOR_CODE,
  INDEX_COLOR_CODEMap,
  STOCK_COLOR_CODE,
  STOCK_COLOR_CODEMap,
} from "domain/protoNew/auto_trading_pb";

export const TICKER_LIST_ALL_KEY_NEW = "ticker_list_all_new";

export enum GetAccountType {
  CASH = "0", //0x
  MARGIN = "1", //1x
  BANK = "2", //2x
  DER = "5", //5x
  BOND = "6", //6x
  NONE_DERT_SUB_ACCOUNT = "NONE_DERT_SUB_ACCOUNT",
  NONE_BOND_SUB_ACCOUNT = "NONE_BOND_SUB_ACCOUNT",
  NONE_DERT_AND_BOND_SUB_ACCOUNT = "NONE_DERT_AND_BOND_SUB_ACCOUNT",
  NONE_DERT_AND_BANK_SUB_ACCOUNT = "NONE_DERT_AND_BANK_SUB_ACCOUNT",
  NONE_BANK_SUB_ACCOUNT = "NONE_BANK_SUB_ACCOUNT",
}

export enum EntrustBusinessCode {
  /// <summary>
  /// 0 - UQ Toàn bộ
  /// </summary>
  Owner = -1,

  /// <summary>
  /// 0 - UQ Toàn bộ
  /// </summary>
  All = 0,

  /// <summary>
  /// 1 - UQ Đặt lệnh
  /// </summary>
  Order = 1,

  /// <summary>
  /// 2 - UQ Nộp, rút tiền
  /// </summary>
  Cash = 2,

  /// <summary>
  /// 3 - UQ Gửi, rút CK
  /// </summary>
  Sec = 3,

  /// <summary>
  /// 4 - UQ Ứng trước
  /// </summary>
  PayAdvance = 4,

  /// <summary>
  /// 5 - UQ Đăng ký mua thêm
  /// </summary>
  RightExec = 5,

  /// <summary>
  /// 6 - UQ Bán Lô lẻ
  /// </summary>
  OddSell = 6,

  /// <summary>
  /// 7 - UQ Cầm cố
  /// </summary>
  Mortgage = 7,

  /// <summary>
  /// 8 - UQ GD Phái sinh
  /// </summary>
  Derivative = 8,

  /// <summary>
  /// 9 - UQ Tra cứu thông tin, nhận sao kê
  /// </summary>
  Inquiry = 9,

  /// <summary>
  /// 10 - UQ Other
  /// </summary>
  Other = 10,

  /// <summary>
  /// 11 - UQ Margin
  /// </summary>
  Margin = 11,
}

//----------------

export const API_REQUEST_TIMEOUT = 200000; // 20s
export const ACCESS_TOKEN_KEY = "mbs_access_token";
export const TIME_ACCESS_TOKEN_KEY = "mbs_time_access_token";
export const REFRESH_ACCESS_TOKEN_KEY = "mbs_refresh_token";
export const TIME_REFRESH_ACCESS_TOKEN_KEY = "mbs_time_refresh_token";
export const MASTER_ACCOUNT_KEY = "mbs_master_account";
export const ACCOUNT_KEY = "mbs_remember_account";
export const MASTER_ACCOUNT_INFO_KEY = "mbs_master_account_info";
export const ACCOUNT_LISTING_KEY = "mbs_account_listing";
export const USER_AUTHEN_LIST_KEY = "user_authen_list_key";
export const REMEMBER_OTP_CODE_LIST_KEY = "remember_otp_code_list_key";
export const CHALLENGE_CODE_KEY = "mbs_challenge_code";
export const TICKER_LIST_ALL_KEY = "mbs_ticker_list_all";
export const CHART_DATA_CACHE_KEY = "mbs_chart_data_cache";
export const TRADING_SESSION_CACHE_KEY = "mbs_tradingSession_cache";
export const DEFAULT_ACCOUNT = "mbs_default-account";
export const STOCK_ORDER_DER_LAYOUT_STORAGE_KEY = "stock_order_der_layout";
export const DEFAULT_WIDGET_REFETCH_INTERVAL = 60000; //60s
export const TIME_REFETCH_INTERVAL_PUTTHROUGH = 5000; //5s
export const ADDRESS_IP = "address_ip";
export const SESSION_STATE = "session_state";
export const VERSIONWEB = "versionWeb";

export const RESPONSE_CODE = {
  TOKEN_EXPIRED: 401,
};

export const TRADING_CONST = {
  MAX_LONG_SHORT_QTY: 500,
  HSX_MAX_ORDER_QTY: 500000,
  HNX_MAX_ORDER_QTY: 99999900,
  HSX_VOLUME_LOT: 100,
  HNX_VOLUME_LOT: 100,
  UPCOM_VOLUME_LOT: 100,
  DER_VOLUME_LOT: 1,
};

// Các const liên quan UI
export const UIConst = {
  // Từ 1440 coi là màn hình to
  LAGRE_SCREEN_WIDTH: "1440px",
};

export const Colors: {
  [keyof in STOCK_COLOR_CODEMap as number]: string;
} = {
  [STOCK_COLOR_CODE.UP_PRICE]: "green50",
  [STOCK_COLOR_CODE.CEILING_PRICE]: "violet50",
  [STOCK_COLOR_CODE.DOWN_PRICE]: "red50",
  [STOCK_COLOR_CODE.FLOOR_PRICE]: "blue50",
  [STOCK_COLOR_CODE.BASIC_PRICE]: "yellow50",
  [STOCK_COLOR_CODE.NO_TRADE_PRICE]: "grey0",
};

export const ColorsIndex: {
  [keyof in INDEX_COLOR_CODEMap as number]: string;
} = {
  [INDEX_COLOR_CODE.NONE]: "yellow50",
  [INDEX_COLOR_CODE.OPEN_INDEX]: "yellow50",
  [INDEX_COLOR_CODE.UP_INDEX]: "green50",
  [INDEX_COLOR_CODE.DOWN_INDEX]: "red50",
};

export enum AccountType {
  CASH = "CASH",
  MCREDIT = "MCREDIT",
  MARGIN = "MARGIN",
  DERIVATIVE = "DERIVATIVE",
  COPIER = "COPIER",
}

export enum CopierType {
  COPIER = "COPIER",
  LEADER = "LEADER",
}

export enum GroupCategory {
  OD001 = "OD001",
  OD01 = "OD01",
  OD02 = "OD02",
  KR03 = "KR03",
  KR04 = "KR04",
  KR05 = "KR05",
  KD03 = "KD03",
  KD04 = "KD04",
  KD05 = "KD05",
  KD06 = "KD06",
  KC01 = "KC01",
  KC02 = "KC02",
  KC03 = "KC03",
  KC04 = "KC04",
  KC05 = "KC05",
  KO03 = "KO03",
  KO04 = "KO04",
  KT03 = "KT03",
  KT04 = "KT04",
  RC01 = "RC01",
  RS01 = "RS01",

  RS02 = "RS02",
  RS03 = "RS03",
  RI02 = "RI02",
  RI03 = "RI03",
  RI04 = "RI04",
  RI05 = "RI05",
  RU00 = "RU00",
  RU01 = "RU01",
  RB02 = "RB02",
  RB03 = "RB03",
  RP00 = "RP00",
  RP01 = "RP01",
  RP02 = "RP02",
  RP03 = "RP03",
  RJ00 = "RJ00",
  RJ02 = "RJ02",
  RJ03 = "RJ03",
  RA01 = "RA01",
  RR01 = "RR01",
  ST01 = "ST01",

  ST02 = "ST02",
  MO05 = "MO05",
  MO06 = "MO06",
  MO07 = "MO07",
  MO08 = "MO08",
  MO09 = "MO09",
  MO10 = "MO10",
  MO13 = "MO13",
  CW05 = "CW05",
}

export enum TransferType {
  BANK_TRANSFER = "BANK_TRANSFER",
  INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
  DERIVATIVE_TRANSFER_DEPOSIT = "DERIVATIVE_TRANSFER_DEPOSIT",
  DERIVATIVE_TRANSFER_WITHDRAW = "DERIVATIVE_TRANSFER_WITHDRAW",
  CS_TO_PS = "CS_TO_PS",
  PS_TO_CS = "PS_TO_CS",
}

export enum BankTransferType {
  MB = "MB",
  BIDV = "BIDV",
}
export enum InternalTransferType {
  IN = "IN",
  OUT = "OUT",
}

export enum WatchlistGroupType {
  CUSTOM = "CUSTOM",
  OWNER = "OWNER",
  LISTED = "LISTED",
  PT = "PT",
  INDUSTRY = "INDUSTRY",
  CW = "CW",
  DERIVATIVE = "DERIVATIVE",
  PUT_THROUGH = "PUT_THROUGH",
  ETF = "ETF",
  ODDLOT = "ODDLOT",
}

export const UNIT_NUMBER = {
  ONE_HUNDRED: 100,
  ONE_THOUSAND: 1000,
  TEN_THOUSAND: 10000,
  ONE_HUNDRED_THOUSAND: 100000,
  ONE_MILLION: 1000000,
  ONE_BILLION: 1000000000,
};

export const CHART_COLORS = [
  "#3391FF",
  "#253147",
  "#613E45",
  "#215448",
  "#5F532B",
  "#00B98D",
  "#DD3640",
  "#E7BC26",
  "#DE20DF",
  "#ADB3C0",
  "#6E7789",
  "#3C424B",
  "#2B3039",
  "#101B27",
  "#172230",
  "#283648",
  "#1570db",
  "#1e2b43",
  "#5f373f",
  "#205b4d",
  "#665728",
  "#02a981",
  "#de313b",
  "#e3b821",
  "#dd1bde",
  "#a6aebf",
  "#6a758c",
  "#39414e",
  "#495260",
  "#102030",
  "#1d314a",
  "#2b3d54",
];

export const Channel: {
  [key: string]: string;
} = {
  1: "Back",
  2: "Web ",
  3: "Front",
  4: "Web mobile",
  5: "Mobile",
  6: "Home",
  7: "Floor",
  8: "SMS",
  9: "Tel",
};

export const SrcChannel = {
  ALL: 0,
  BACK: 1,
  WEB: 2,
  FRONT: 3,
  WEB_MOBILE: 4,
  MOBILE: 5,
  HOME: 6,
  FLOOR: 7,
  SMS: 8,
  TEL: 9,
};

export const regPrice = /^\d+(\.\d{0,9})?$/;
export const regPriceLimitOffset = /^\d+(\.\d{0,1})?$/;
export const regPriceOneDecimal = /^[0-9]+(\.[0-9])?$/;
export const regVolumn = /^\d+$/;
export const DEFAULT_PAGE_SIZE = 30;
export const DEFAULT_PAGE = 1;

export enum MenuExtraTab {
  PLACE_ORDER = "PLACE_ORDER",
  PLACE_ORDER_DER = "PLACE_ORDER_DER",
}

export enum FilterAccountType {
  EQUITY = "EQUITY",
  DERIVATIVE = "DERIVATIVE",
}

export enum ActiveTabCoveredWarrant {
  RESIGNTER = "RESIGNTER",
  INFORMATION = "INFORMATION",
}

export enum ActiveTabInfoWarrant {
  RESIGNTER = "RESIGNTER",
  HISTORY = "HISTORY",
  INFORMATION = "INFORMATION",
}

export enum ActiveTabBonds {
  BUYBONDS = "BUYBONDS",
  SELLBONDS = "SELLBONDS",
  OWNINGBONDS = "OWNINGBONDS",
  MANAGEBONDS = "MANAGEBONDS",
}

export enum ActiveTabCopyTrading {
  TOPLEADER = "TOPLEADER",
  FOLLOW = "FOLLOW",
  MANAGECOPY = "MANAGECOPY",
  HISTORY = "HISTORY",
}

export enum ActiveTabMarginContract {
  MARGIN_CONTRACT = "MARGIN_CONTRACT",
}

export enum ActiveTabAdvance {
  ADVANCE = "ADVANCE",
}

export enum ActiveTabRightInfo {
  RIGHT_INFO = "RIGHT_INFO",
}

export enum ActiveTabAccountInfo {
  ACCOUNT_INFO = "ACCOUNT_INFO",
  ENTRUST_LIST = "ENTRUST_LIST",
}

export const EOTradingSessionMap = [
  "EOT_UNKNOWN",
  "ATO",
  "OPEN",
  "PAUSE",
  "ATC",
  "CLOSED",
  "OT",
  "BOD",
  "EOD",
  "RUNOFF",
];

export const TOP_PRICE_LOADING_LIMIT = Number(
  process.env.REACT_APP_TOP_PRICE_LOADING_LIMIT
);

// Enum các sàn trả về trong thông tin mã CK (getSecuritiesStatus)
export enum TradingSessionType {
  HSX_LISTED = "HSX_LISTED",
  HNX_LISTED = "HNX_LISTED",
  HNX_UPCOM = "HNX_UPCOM",
  VNFE = "VNFE",
}

// Enum các Loại CK trả về trong thông tin mã CK (getSecuritiesStatus)
export enum SecurityType {
  CS = "CS",
  WAR = "WAR",
  FUT = "FUT",
  MF = "MF",
  CORP = "CORP",
}

// Lô chẵn các sàn
export enum VolumeLot {
  HSX_VOLUME_LOT = 100,
  HNX_VOLUME_LOT = 100,
}

// Các loại giá đặt lệnh
export enum OrderTypeVal {
  LO = "LO",
  ATO = "ATO",
  ATC = "ATC",
  MP = "MP",
  MTL = "MTL",
  MOK = "MOK",
  MAK = "MAK",
  PLO = "PLO",
}

// Các loại phiên
export enum MarketSessionType {
  OT = "OT",
  OUTTIME = "OUTTIME",
  BOD = "BOD",
  AVAILABLE = "AVAILABLE",
  ATO = "ATO",
  OPEN = "OPEN",
  BREAK = "BREAK",
  ATC = "ATC",
  RUNOFF = "RUNOFF",
  EOD = "EOD",
  HALT = "HALT",
  CLOSED = "CLOSED",
}

// Action Hủy/Sửa lệnh
export enum OrderActionType {
  EDIT = "EDIT",
  CANCEL = "CANCEL",
}

// Up, Down, TUp, TDown
// Trạng thái mã CK
export enum SecuritiesStatus {
  HALT = "HALT",
  HALT_AT = "HALT_AT",
  HALT_PT = "HALT_PT",
  NORMAL = "NORMAL",
}

// Các loại lệnh Điều kiện phái sinh
export enum DerConditionalOrderType {
  W = "W",
  U = "U",
  D = "D",
  V = "V",
  E = "E",
  O = "O",
  B = "B",

  // Lệnh thường
  N = "N",
}

// Trạng thái lệnh chi tiết (hiển thị)
export enum OrderStatusDetailType {
  NONE = "NONE",
  REJECTED = "REJECTED", // Từ chối
  ENTERED = "ENTERED", // Chờ gửi
  WAIT_FILLED = "WAIT_FILLED", // Chờ khớp
  PARTIALLY_FILLED = "PARTIALLY_FILLED", // Khớp 1 phần
  FILLED = "FILLED", // Khớp toàn bộ
  PARTIALLY_CANCELLED = "PARTIALLY_CANCELLED", // Hủy 1 phần
  CANCELLED = "CANCELLED", // Hủy toàn bộ
  REJECTED_DEPARTMENT = "REJECTED_DEPARTMENT", // Sở từ chối
}

// Trạng thái lệnh đk phái sinh (hiển thị)
export enum OrderStatusDerCondDetailType {
  NONE = "NONE",
  WAIT_ACTIVE = "WAIT_ACTIVE", // Chờ kích hoạt
  FINISHED = "FINISHED", // Đã kết thúc
  EXPIRE = "EXPIRE", // Hết hiệu lực
  CANCELED = "CANCELED", // Đã hủy
}

// Trạng thái lệnh đk cơ sở (hiển thị)
export enum OrderStatusCondDetailType {
  NONE = "NONE",
  EFFECT = "EFFECT", // Đang hiệu lực
  FINISHED = "FINISHED", // Đã kết thúc
  EXPIRE = "EXPIRE", // Hết hiệu lực
  CANCELED = "CANCELED", // Đã hủy
}

export enum TypeAccount {
  O = "O",
  SE = "SE",
  D = "D",
}

// Đơn vị nhân giá Phái sinh
export const DER_PRICE_UNIT = 1;

// Đơn vị nhân giá Cơ sở
export const EQUITY_PRICE_UNIT = 1000;

// Hệ số nhân giá trị lệnh phái sinh 100k
export const DER_MULTIPLE_NUM = 100000;

export const DATE_TIME_FORMAT = {
  YYYYMMDD: "YYYYMMDD",
  DDMMYYYY: "DD/MM/YYYY",
};

// Giá trị string mặc định
export const DEFAULT_STRING = "---";

export enum RANGE_DAY {
  RANGE_7 = 7,
  RANGE_30 = 30,
  RANGE_90 = 90,
  RANGE_180 = 180,
}

export enum BtnFunction {
  ChangeOnlineService = 1,
  RegisterOnlineService = 2,
  RoutesBonds = 3,
  SettingsModal = 5,
  F3 = 8,
  F4 = 9,
}

export enum OrderType {
  NORMAL = "NORMAL",
  CONDITION = "CONDITION",
}

export enum OrderCondition {
  ALL = "",
  BEFORE_DAY = "TCO",
  TRENDING = "TS",
  COMPETITIVE = "PRO",
  TAKE_PROFIT = "SP",
  STOP_LOSS = "CL",
}

export enum OptValType {
  VALUE = 1,
  PERCENT = 2,
}

export enum OptType {
  GREATER = 1,
  LESS = 2,
}

export enum ConditionStatus {
  COMPLETE = 2,
  EXPIRED = 3,
  CANCEL = 9,
}

export enum ETradingDateMapNetFlow {
  PRESENT = 0,
  ONE_DAY_AGO = 1,
  TWO_DAY_AGO = 2,
  THREE_DAY_AGO = 3,
  FOUR_DAY_AGO = 4,
  FIVE_DAY_AGO = 5,
  ONE_WEEK_AGO = 6,
  TWO_WEEK_AGO = 7,
  ONE_MONTH_AGO = 8,
}

export enum EMarketCodeNew {
  EMC_UNKNOWN = 0,
  HSX_IDX = 100,
  VN30_IDX = 101,
  HNX_IDX = 200,
  UPCOM_IDX = 300,
  DER_IDX = 500,
  VNXALL = 117,
  HNX30 = 201,
}

export enum SecType {
  ALL = 0,
  S = 1,
  U = 2,
  D = 3,
  E = 4,
  CW = 5,
  TP = 6,
}

export enum MarketSession {
  BEFORE = "S",
  ATO = "P",
  OPEN = "O",
  ATC = "A",
  RUNOFF = "C",
  BREAK = "I",
  CLOSED_N = "N",
  CLOSED_K = "K",
  CLOSED_G = "G",
  CLOSED_J = "J",
  CLOSED_Z = "Z",
}

export interface ETickerTypeMapNew {
  ALL: 0;
  S: 1;
  U: 2;
  D: 3;
  E: 4;
  CW: 5;
  TP: 6;
}

export enum EBusinessCd {
  LOGIN = 1,
  TRADING = 2,
  TRANSFER = 3,
  ADVANCE = 4,
  ODD_SELL = 5,
  CAP_INCREASE = 6,
  MARGIN = 7,
  IPAY_CONFIRM = 8,
  DEPOSIT = 9,
  BOND = 10,
  REGISTER_AUTHEN = 100,
  RESET_PASSWORD = 101,
}

export enum EBusinessCdPinType {
  LOGIN = "loginPinType",
  TRADING = "tradingPinType",
  TRANSFER = "transferPinType",
  ADVANCE = "advancePinType",
  ODD_SELL = "oddSellPinType",
  CAP_INCREASE = "capIncreasePinType",
  MARGIN = "marginPinType",
  DEPOSIT = "depositPinType",
}

export enum EOTPType {
  NONE = 0,
  PIN_CD = 1,
  SMART_OTP = 6,
  SMS_OTP = 3,
}

export enum EOTPTypeSubmit {
  OTP = "1",
  QR_CODE = "2",
}

export enum EChannelNumber {
  APP = 1,
  WEB = 2,
}

export enum ESendType {
  SMS = 1,
  EMAIL = 2,
}

export const DEVICE_ID = "gmc-web";

export enum EAccountType {
  NORMAL = "N",
  MARGIN = "T",
}

export const APPEND_BUSINESS_SUBMIT = {
  channel: EChannelNumber.WEB,
  deviceId: DEVICE_ID,
};

// Sau 1 giây khi nhận được msg sổ lệnh sẽ reload danh mục đầu tư
export const PORTFOLIO_THROTTLE_TIME = 1 * 1000;

export enum TabSetting {
  GENERAL = 0,
  TRADING_ACC = 1,
  MANAGE_DEVICE = 2,
  KEY_SHORTCUT = 3,
  CLEAR_CACHE = 4,
  SETTING_PASS = 5,
  ADVANCE_AUTO = 6,
  EMAIL_SMS = 7,
}

export enum EntrustType {
  ALL = "0",
  ORDER = "1",
  TRANFER_MONEY = "2",
  TRANFER_SHARE = "3",
  ADVANCE = "4",
  REGISTER_BUY = "5",
  SELL_ODD_LOT = "6",
  PLEDGE = "7",
  TRANFER_DER = "8",
  STATEMENT_SEARCH = "9",
  OTHER_ENTRUST = "10",
  MARGIN = "11",
}

export enum VisibleIndex {
  OPEN = 0,
  HIDDEN_CHART = 1,
  HIDDEN_ALL = 2,
}

export enum TrackingApiType {
  restful = "restful",
  firebase = "firebase",
}
