import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button } from "components/commons";

import {
  updateModalStatusRequest,
  updateModalOtpRequest,
  // createAdvanceContractRequest,
} from "modules/advance/redux";
import { isShowConfirmModalSelector } from "modules/advance/selectors";

import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
} from "./styles";

import { IEditAdvanceParams } from "core/http/apis/advance/types";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
import storage from "helper/storage";
interface Props {
  itemEdit?: IEditAdvanceParams;
}

function ConfirmModal({ itemEdit }: Props) {
  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const isVisible = useSelector(isShowConfirmModalSelector);

  const closeModalHandler = () => {
    dispatch(updateModalStatusRequest({ isVisible: false }));
  };

  const clickAgreeButtonHandler = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
    closeModalHandler();
    const resLogData = {
      eventGroup: GroupEvent.xmlOnline,
      event: RealtimeConst.submitConfirmOrder,
      message: "success",
    };
    storage.commonPushLogFirebase(resLogData);
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="advance.confirmModal.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="pt-3">
            <LabelCell>
              <FormattedMessage id="advance.confirmModal.subAccountFrom" />
            </LabelCell>
            <TextCell>{itemEdit?.subAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="advance.confirmModal.withdrawable" />
            </LabelCell>
            <TextCell>{numeral(itemEdit?.realRecvAmt).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="advance.confirmModal.provisionalFee" />
            </LabelCell>
            <TextCell>{numeral(itemEdit?.advanceFee).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="advance.confirmModal.remarks" />
            </LabelCell>
            <TextCell>{itemEdit?.remarks}</TextCell>
          </BodyModalRow>

          <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={closeModalHandler}
                width="100%"
                style={{
                  fontSize: `${themes[themeType].typeFaces.headline.size}`,
                  padding: "18px 0px",
                }}
              >
                <FormattedMessage id="advance.confirmModal.cancel" />
              </Button>
            </ButtonCell>
            <ButtonCell>
              <Button
                className="button-confirm"
                fill={true}
                onClick={clickAgreeButtonHandler}
                width="100%"
                style={{
                  background: `${themes[themeType].buttonGradientColor}`,
                  fontSize: `${themes[themeType].typeFaces.headline.size}`,
                  padding: "18px 0px",
                }}
              >
                <FormattedMessage id="advance.confirmModal.accept" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default ConfirmModal;
