import { ToastType } from "modules/system/redux/toast";
import { useIntl } from "react-intl";
import {
  StyledToast,
  StyledToastHeader,
  StyledToastBody,
  Title,
} from "./styles";

export type ToastProps = {
  id: string;
  type: ToastType;
  message: string | undefined;
  defaultMessage: string | undefined;
  isIntlKey?: Boolean;
  onClose: (id: string) => void;
  delay: number;
  param?: any;
};

const Toast = ({
  message,
  defaultMessage,
  isIntlKey = false, //Mặc định sẽ là false
  id,
  type,
  onClose,
  delay,
  param,
}: ToastProps) => {
  const intl = useIntl();
  function handleClose() {
    onClose(id);
  }

  let content;
  if (isIntlKey) {
    content = intl.formatMessage({
      id: message ?? "error.defaultErr",
      defaultMessage: defaultMessage ?? "error.defaultErr",
    });
  } else {
    content = intl.formatMessage(
      { id: message ?? "error.defaultErr" },
      param && message ? param : undefined
    );
  }

  function checkTitle(value: ToastType) {
    switch (value) {
      case "success":
        return intl.formatMessage({ id: "toast.success" });
      case "warning":
        return intl.formatMessage({ id: "toast.warning" });
      case "error":
        // return intl.formatMessage({ id: "toast.error" });
        return null;
      default:
        return intl.formatMessage({ id: "toast.default" });
    }
  }

  return (
    <StyledToast
      onClose={handleClose}
      type={type}
      show={true}
      delay={delay}
      autohide
    >
      <StyledToastHeader type={type}>
        <Title className="mr-auto title">{checkTitle(type)}</Title>
      </StyledToastHeader>
      <StyledToastBody>{content}</StyledToastBody>
    </StyledToast>
  );
};

export default Toast;
