import Table from "./components/Table";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";

const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Categories: React.FC = () => {
  return (
    <Styled>
      <Table />
    </Styled>
  );
};

export default withRouter(Categories);
