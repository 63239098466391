import { FC, memo, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import TableBodyRow from "./TableBodyRowPriceRight";
import { TableBodyWrapper } from "./styles";
import { useRef } from "react";
import { COLUMNS } from "./constants";
import { useSelector } from "react-redux";
import { tickerListTopPriceRightSelector } from "modules/market/redux/selectors";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
}

export interface Props {
  rowHeight: number;
}

const TableBody: FC<Props> = ({ rowHeight }) => {
  const tableBodyWrapperRef = useRef<HTMLDivElement>(null);
  const tickerList = useSelector(tickerListTopPriceRightSelector);

  useEffect(() => {
    if (tickerList.length > 0) {
      const listTicker = tickerList.toString().replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
    return () => {
      if (tickerList.length > 0) {
        const listTicker = tickerList.toString().replace(/,/g, ";");
        unSubscribeTickers(listTicker);
      }
    };
  }, [tickerList.length]);

  return (
    <TableBodyWrapper ref={tableBodyWrapperRef}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemCount={tickerList.length}
            itemSize={() => rowHeight}
            height={height}
            width={width}
            useIsScrolling
          >
            {({ style, index, isScrolling }) => (
              <TableBodyRow
                style={style}
                ticker={tickerList[index]}
                columns={COLUMNS}
                rowInd={index}
                isScrolling={isScrolling}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </TableBodyWrapper>
  );
};

export default memo(TableBody);
