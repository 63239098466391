import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IPlaceOrderRetailBonds } from "domain/models/PlaceOrderRetailBonds";

import {
  ICreateRbonOrder,
  IDeleteRbonOrder,
  IFindCbotProductRetail,
} from "./type";
import {
  cbotGetInterestApi,
  createRbonOrderApi,
  deleteRbonOrderApi,
  findCbotBondInfoApi,
  findCbotProductRetailApi,
  findRbonOrderApi,
  inquiryBuyingPowerApi,
} from "./urls";

export const findCbotProductRetail = async (
  param: IFindCbotProductRetail
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    findCbotProductRetailApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findRbonOrder = async (
  param: any
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    findRbonOrderApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cbotGetInterest = async (
  param: any
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    cbotGetInterestApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const inquiryBuyingPower = async (
  param: any
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    inquiryBuyingPowerApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findCbotBondInfo = async (
  param: any
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    findCbotBondInfoApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createRbonOrder = async (
  param: ICreateRbonOrder
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    createRbonOrderApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const deleteRbonOrder = async (
  param: IDeleteRbonOrder
): Promise<IPlaceOrderRetailBonds> => {
  const res = await VgaiaHttp.post<IBasePairValueResponse>(
    deleteRbonOrderApi,
    JSON.stringify(param),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
