import HttpClient from "../../index";
import { RESTFUL_ACCOUNT_URL } from "AppConfig";

export default class HttpAccount extends HttpClient {
  private static classInstance?: HttpAccount;
  private constructor() {
    super(RESTFUL_ACCOUNT_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpAccount();
    }

    return this.classInstance;
  }
}
