import { CSSProperties, memo, useEffect } from "react";
import {
  BodyRowWrapper,
  TableCellContent,
  CellValue,
} from "components/widgets/generals/TopForeign/styles";
import { COLUMNS_TOP_VOLUME, FLEX } from "./constant";
import {
  ChangePricePercentageCell,
  ChangeHighLowCell,
  ChangePriceAmountCell,
  PriceCell,
  VolumeCell,
  CompanyNameCell,
  TickerCell,
} from "./Cells";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { marketTopStatisticalSelector } from "modules/market/redux/selectors";
import {
  requestLoadMore,
  TopStatisticalState,
} from "modules/market/redux/topStatistical";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
}

export interface Props {
  style: CSSProperties;
  ticker: string;
  rowInd: number;
  isScrolling?: boolean;
  activeTab: string;
}

const currentTotalSelector = createSelector(
  marketTopStatisticalSelector,
  (topStatisticalState: TopStatisticalState) =>
    topStatisticalState.tickerList.length
);

function TableBodyRow({ style, ticker, rowInd, activeTab }: Props) {
  const columns = COLUMNS_TOP_VOLUME;
  const dispatch = useDispatch();
  const currentTotal = useSelector(currentTotalSelector);
  useEffect(() => {
    if (rowInd && rowInd + 5 === currentTotal) {
      dispatch(requestLoadMore());
    }
  }, [rowInd]);

  return (
    <BodyRowWrapper style={style} bgColor={rowInd + 1 || 1}>
      <TableCellContent
        className="market-ticker-name full-company-name"
        minWidth={columns[0]?.width}
        align={
          columns[0]?.position
            ? FLEX[columns[0]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <TickerCell dataRow={ticker} />
        <CompanyNameCell dataRow={ticker} />
      </TableCellContent>
      {activeTab !== "topChange" && (
        <CellValue
          minWidth={columns[1]?.width}
          align={
            columns[1]?.position
              ? FLEX[columns[1]?.position as keyof typeof FLEX]
              : FLEX.START
          }
        >
          <VolumeCell dataRow={ticker} />
        </CellValue>
      )}
      {activeTab === "topChange" && (
        <CellValue
          minWidth={columns[1]?.width}
          align={
            columns[1]?.position
              ? FLEX[columns[1]?.position as keyof typeof FLEX]
              : FLEX.START
          }
        >
          <ChangeHighLowCell dataRow={ticker} />
        </CellValue>
      )}
      <CellValue
        minWidth={columns[2]?.width}
        align={
          columns[2]?.position
            ? FLEX[columns[2]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <PriceCell dataRow={ticker} />
      </CellValue>
      <CellValue
        minWidth={columns[3]?.width}
        align={
          columns[3]?.position
            ? FLEX[columns[3]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <ChangePriceAmountCell dataRow={ticker} />
      </CellValue>
      <CellValue
        minWidth={columns[3]?.width}
        align={
          columns[3]?.position
            ? FLEX[columns[3]?.position as keyof typeof FLEX]
            : FLEX.START
        }
      >
        <ChangePricePercentageCell dataRow={ticker} />
      </CellValue>
    </BodyRowWrapper>
  );
}

export default memo(TableBodyRow) as React.FC<Props>;
