/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useMemo } from "react";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Wrap } from "./styles";
import { Button, DatePicker, Input, Select } from "components/commons";
import { useIntl } from "react-intl";
import { optioTypes } from "modules/subscribeBuy/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoadingsearchSubscribe,
  selectSearchsearchSubscribe,
} from "modules/subscribeBuy/selectors";
import {
  getDataSearchSubscribe,
  searchDataSearchSubscribe,
} from "modules/subscribeBuy/redux";
import moment from "moment";

const Search: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const optionType = useMemo(() => optioTypes(intl), [intl]);
  const { secCd, rightType, ownerFixDate } = useSelector(
    selectSearchsearchSubscribe
  );

  const optionRightType = useMemo(() => optioTypes(intl), [intl]);

  const valueRightType = useMemo(() => {
    return optionRightType.filter((e) => e.value === rightType);
  }, [optionRightType, rightType]);

  const loading = useSelector(selectLoadingsearchSubscribe);

  const submitForm = () => {
    dispatch(
      getDataSearchSubscribe({
        secCd,
        rightType,
        ownerFixDate: ownerFixDate
          ? moment(ownerFixDate).format("YYYYMMDD")
          : "",
      })
    );
  };

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "subscribe.content.searchSubscribe.search.codeStock",
          })}
          className="mr-4"
        >
          <Input
            placeholder={intl.formatMessage({
              id: "subscribe.content.searchSubscribe.search.placeCodeStock",
            })}
            value={secCd}
            onChange={(e) =>
              dispatch(
                searchDataSearchSubscribe({
                  value: (e?.target?.value).toLocaleUpperCase(),
                  key: "secCd",
                })
              )
            }
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "subscribe.content.searchSubscribe.search.type",
          })}
          className="mr-4"
        >
          <Select
            options={optionType}
            value={valueRightType}
            onChange={(e: any) => {
              dispatch(
                searchDataSearchSubscribe({ value: e?.value, key: "rightType" })
              );
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "subscribe.content.searchSubscribe.search.date",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={ownerFixDate}
            onChange={(e: any) => {
              dispatch(
                searchDataSearchSubscribe({ value: e, key: "ownerFixDate" })
              );
            }}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter"
          style={{ marginLeft: "0px" }}
          loading={loading}
          onClick={submitForm}
        >
          {intl.formatMessage({
            id: "transferInternal.historyTransfer.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
};

export default Search;
