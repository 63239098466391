import VgaiaHttp from "core/http/singleton/vgaia";
import { IMarketStatisticResponse } from "domain/models/MarketStatistic";
import { ISearchStatisticPriceParams } from "./types";
import { searchStatisticPriceApi } from "./urls";

export const searchStatisticPrice = async (
  params: ISearchStatisticPriceParams
): Promise<IMarketStatisticResponse> => {
  const res = await VgaiaHttp.post<IMarketStatisticResponse>(
    searchStatisticPriceApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
