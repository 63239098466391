import { MARGIN_CONTRACT_WATCH_TABS } from "modules/margin-contract-watch/constants";
import { activeTabSelector } from "modules/margin-contract-watch/selectors";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";

const Tabs: FC = () => {
  const intl = useIntl();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab
          isActive={
            activeTab === MARGIN_CONTRACT_WATCH_TABS.MARGIN_CONTRACT_WATCH
          }
        >
          {intl.formatMessage({
            id: "marginContractWatch.tabs.marginContractWatchTab",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
