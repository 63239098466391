const paths = {
  categories: "/",
  login: "/login",
  register: "/register",
  stockOrder: "/stock-order",
  market: "/market",
  orderBook: "/order-book",
  // profitLoss: "/extends/profit-loss",
  confirmOrderOnline: "/confirmationOrderOnline",
  copyTrading: "/copi24",
  categotyFinancialServices: "/category-financial-services",
  statement: "/extends/statement",
  moneyTransfer: "/money-transfer",
  advance: "/advance",
  accountStatement: "/account-statement",
  orderHistory: "/order-history",
  stockOrderDer: "/stock-order-der",
  oddLotShare: "/odd-lot-share",
  coveredWarrant: "/covered-warrant",
  loanExtension: "/loan-extension",
  infoWarrant: "/info-warrant",
  screeners: "/screeners",
  marginLimit: "/margin-limit",
  nav: "/net-assets-value",
  investment: "/investment-efficiency",
  tickerGraph: "/ticker-graph",
  proInvestor: "/pro-investor",
  bonds: "/bonds",
  transferShare: "/transfer-share",
  businessCooperation: "/business-cooperation",
  packServices: "/pack-services",
  techAnalysis: "/technical-analysis",
  changeStockStatus: "/change-stock-status",
  marginSecuritiesPortfolio: "/margin-securities-portfolio",
  depositList: "/deposit-list",
  addFrameworkContractForm: "/add-framework-contract-form",
  registerToTransferBonds: "/register-to-transfer-the-bonds",
  placeOrderTransferBonds: "/place-order-transfer-the-bonds",
  placeOrderRetailBonds: "/place-order-retail-bonds",
  placeOrderBondAdv: "/place-order-bondAdv",
  changeLimit: "/change-limit",

  // vgaia
  marginContract: "/margin-contract",
  marginSecurities: "/margin-securities",
  rightInfo: "/right-info",
  statisticOnday: "/statistic-onday",
  statisticPrice: "/statistic-price",
  statisticUpdown: "/statistic-updown",
  statisticHighestUpdown: "/statistic-highest-updown",
  accountInfo: "/account-info",
  mrgContractDebit: "/mrg-contract-debit",
  marginContractWatch: "/margin-contract-watch",
  transferLocal: "/transfer-local",
  profitLoss: "/profit-loss",
  holdUnholdBankAccount: "/hold-unhold-bank-account",
  transferInternal: "/transfer-internal",
  subscribeBuy: "/subscribe-buy",
  createContract: "/create-contract",
  confirmation: "/confirmation",
  depositPower: "/deposit-power",
  depositContract: "/deposit-contract",
  manegerMoney: "/maneger-money",
  profitMoney: "/profit-money",
  subAccoRegister: "/subAcco-register",
  transferHistory: "/transfer-history",
  transferBond: "/transfer-bond",
  bidAskBond: "/bid-ask-bond",
  assetsIncurred: "/assetsIncurred",
  bidAskBondOrder: "/bid-ask-bond-order",
  professionalInvestor: "/professional-investor",
  contractDebit: "/contract-debit",
  bidAskDBondOrder: "/bid-ask-d-bond-order",
  advertisingOrder: "/advertising-order",
  guideVideo: "/guide-video",
};

export default paths;
