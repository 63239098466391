import Row from "./Row";
import { RowStockProps } from "modules/categories/types";

function RowContainer({
  rowNumber,
  ticker,
  style,
  askWidth,
  bidWidth,
  foreignWidth,
  sumWidth,
  currentListCode,
  columns,
  priceWidth,
  lastSaleWidth,
  coverWarrantWidth,
  coverWarrantHeadWidth,
  isFocus,
}: RowStockProps) {
  return (
    <Row
      rowNumber={rowNumber}
      key={ticker}
      style={style}
      ticker={ticker!}
      currentListCode={currentListCode}
      sumWidth={sumWidth}
      askWidth={askWidth}
      bidWidth={bidWidth}
      foreignWidth={foreignWidth}
      priceWidth={priceWidth}
      lastSaleWidth={lastSaleWidth}
      coverWarrantWidth={coverWarrantWidth}
      coverWarrantHeadWidth={coverWarrantHeadWidth}
      columns={columns}
      isFocus={isFocus}
    />
  );
}

export default RowContainer;
