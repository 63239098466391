import queryString from "query-string";
import { ISearchDepositPowerParams } from "./types";

export const searchDepositPowerApi = (params: ISearchDepositPowerParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDepositPowerRegisterList${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const liquidateDepositPowerApi = "/closeDepositPowerRegister";
export const depositPowerRegisterApi = "/updateDepositPowerRegister";
