import { Widget } from "./types";
import MarketTickerGraph from "modules/tickerDetails/components/MarketTickerGraph";
import MarketTickerInfo from "components/widgets/details/TickerInfo";
import OrderFlowDistributionWidget from "modules/tickerDetails/components/MarketOrderFlowDistribution";
import MarketPriceList from "modules/tickerDetails/components/MarketPriceList";
import MarketVolumeAndTime from "modules/tickerDetails/components/MarketVolumeAndTime";

const tickerDetailCWWidgets: Widget[] = [
  {
    name: "tickerDetail.widgets.coverWarrantInfo",
    component: MarketTickerInfo,
  },
  {
    name: "tickerDetail.widgets.priceList",
    component: MarketPriceList,
  },
  {
    name: "tickerDetail.widgets.volumeAndTime",
    component: MarketVolumeAndTime,
  },
  {
    name: "tickerDetail.widgets.orderFlowDistribution",
    component: OrderFlowDistributionWidget,
  },
  {
    name: "tickerDetail.widgets.tickerGraph",
    component: MarketTickerGraph,
  },
];

export default tickerDetailCWWidgets;
