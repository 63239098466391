import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { FormattedMessage, IntlShape } from "react-intl";
import moment from "moment";
import numeral from "numeral";

const flexEnd = "flex-end";
// const flexStart = "flex-start";
const center = "center";

export const dateNow = new Date();
export const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

export const statusOptions = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "confirmationOrder.search.status.status0",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "confirmationOrder.search.status.status1",
    }),
  },
];

export const dataColumns = [
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.subAccoNo" />
      </TableHeaderCellContent>
    ),
    accessor: "subAccoNo",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.reqType" />
      </TableHeaderCellContent>
    ),
    accessor: "tradeType",
    Cell: (props: any) => {
      return (
        <TableCellContent
          align={center}
          color={
            props.value === 2
              ? "green50"
              : props.value === 1
              ? "red50"
              : "grey0"
          }
        >
          <FormattedMessage
            id={`confirmationOrder.table.reqTypeStatus.value${props.value}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.secCd" />
      </TableHeaderCellContent>
    ),
    accessor: "secCd",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.pubQty" />
      </TableHeaderCellContent>
    ),
    accessor: "pubQty",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={flexEnd}>
        <FormattedMessage id="confirmationOrder.table.pubPrice" />
      </TableHeaderCellContent>
    ),
    accessor: "pubPrice",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0.00")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <TableHeaderCellContent align={flexEnd}>
        <FormattedMessage id="confirmationOrder.table.matQty" />
      </TableHeaderCellContent>
    ),
    accessor: "matQty",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <TableHeaderCellContent align={flexEnd}>
        <FormattedMessage id="confirmationOrder.table.matPrice" />
      </TableHeaderCellContent>
    ),
    accessor: "matPriceAvg",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0.00")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={flexEnd}>
        <FormattedMessage id="confirmationOrder.table.orgOrderNo" />
      </TableHeaderCellContent>
    ),
    accessor: "orgOrderNo",
    Cell: (props: any) => {
      return <TableCellContent align={flexEnd}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.ordChanel" />
      </TableHeaderCellContent>
    ),
    accessor: "ordChanel",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          <FormattedMessage
            id={`confirmationOrder.table.typeOrdChanel.value${props.value}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={flexEnd}>
        <FormattedMessage id="confirmationOrder.table.status" />
      </TableHeaderCellContent>
    ),
    accessor: "extStatus",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          <FormattedMessage
            id={`confirmationOrder.table.statusType.value${props?.value}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.confirmStatus" />
      </TableHeaderCellContent>
    ),
    accessor: "confirmStatus",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          <FormattedMessage
            id={`confirmationOrder.table.confirmStatusType.value${numeral(
              props.value
            ).format("0,0")}`}
          />
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.regDateTime" />
      </TableHeaderCellContent>
    ),
    accessor: "regDateTime",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value).format("DD/MM/YYYY hh:mm:ss")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <TableHeaderCellContent align={center}>
        <FormattedMessage id="confirmationOrder.table.updDateTime" />
      </TableHeaderCellContent>
    ),
    accessor: "updDateTime",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value).format("DD/MM/YYYY hh:mm:ss")}
        </TableCellContent>
      );
    },
  },
];

export enum ActiveTabGuideVideo {
  TRAINING = "TRAINING",
  USER_MANUAL = "USER_MANUAL",
}
