import { FC, useState } from "react";
import Filter from "./Filter";
import Table from "./Table";

const MarginSecuritiesPortfolioContent: FC = () => {
  const [newAccount, setNewAccount] = useState("");
  const [stockCode, setStockCode] = useState("");
  const [newCusGroupId, setNewCusGroupId] = useState();

  return (
    <>
      <Filter
        onChangeAccount={(e: any) => setNewAccount(e)}
        onChangeStockCode={(e: any) => setStockCode(e)}
        setNewCusGroupId={(e: any) => setNewCusGroupId(e)}
        stockCode={stockCode}
        newAccount={newAccount}
        newCusGroupId={newCusGroupId}
      />
      <Table newCusGroupId={newCusGroupId} stockCode={stockCode} />
    </>
  );
};

export default MarginSecuritiesPortfolioContent;
