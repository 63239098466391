import CommonTableInfinity from "components/commons/CommonTableInfinity";

import { listDBondPriceSelector } from "modules/bid-ask-d-bond-order/selectors";
// import { accountInfoSelector } from "modules/auth/selectors";
import {
  // findCbotDBondInfoDetailRequest,
  updateButtonActive,
} from "modules/bid-ask-d-bond-order/redux";

import { updateItemEdit } from "modules/bid-ask-d-bond-order/redux";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ActionsRow,
  ButtonCell,
  ButtonConfirm,
  ButtonCancel,
} from "./styles";
import numeral from "numeral";
import { convertNumberToDateFormat } from "helper/utils";
import { DBondPrice } from "domain/models/BidAskDBondOrder";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listDBondPriceSelector);

  // const { currentUser } = useSelector(accountInfoSelector);

  const center = "center";
  const flexEnd = "flex-end";

  const clickBuyHandler = (row: DBondPrice) => {
    dispatch(updateButtonActive("2"));

    dispatch(
      updateItemEdit({
        secCdDBond: row && row.bondCd ? row.bondCd : "",
      })
    );
  };

  const clickSellHandler = (row: DBondPrice) => {
    dispatch(updateButtonActive("1"));
    dispatch(
      updateItemEdit({
        secCdDBond: row && row.bondCd ? row.bondCd : "",
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.maturityDate" />
          </HeaderCell>
        ),
        accessor: "dueDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.coupon" />
          </HeaderCell>
        ),
        accessor: "interestRate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value * 100).format("0,0")}%/Năm
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.buyPrice" />
          </HeaderCell>
        ),
        accessor: "buyPrice",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.buyVol" />
          </HeaderCell>
        ),
        accessor: "buyVol",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.sellPrice" />
          </HeaderCell>
        ),
        accessor: "sellPrice",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.sellVol" />
          </HeaderCell>
        ),
        accessor: "sellVol",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.action" />
          </HeaderCell>
        ),
        accessor: "a",
        minWidth: 120,
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              <ActionsRow>
                <ButtonCell>
                  <ButtonConfirm onClick={() => clickBuyHandler(currentRow)}>
                    <FormattedMessage id="bidAskDBondOrder.table.buy" />
                  </ButtonConfirm>
                </ButtonCell>
                |
                <ButtonCell>
                  <ButtonCancel onClick={() => clickSellHandler(currentRow)}>
                    <FormattedMessage id="bidAskDBondOrder.table.sell" />
                  </ButtonCancel>
                </ButtonCell>
              </ActionsRow>
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="bidAskDBondOrder2">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"bidAskDBondOrder2"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(RequestTable);
