import VgaiaHttp from "core/http/singleton/vgaia";
import {
  bankBranchRequest,
  bankBranchResponse,
  msttProvinceRequest,
  msttProvinceResponse,
} from "./types";
import { urlBankBranch, urlMsttProvince } from "./urls";

export const getMsttProvince = async (
  data: msttProvinceRequest
): Promise<msttProvinceResponse> => {
  const res = await VgaiaHttp.get<msttProvinceResponse>(urlMsttProvince, data);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankBranch = async (
  data: bankBranchRequest
): Promise<bankBranchResponse> => {
  const res = await VgaiaHttp.get<bankBranchResponse>(urlBankBranch, data);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
