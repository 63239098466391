import { getColorPrice } from "helper/utils";
import {
  floorpriceSelector,
  ceilingpriceSelector,
  basicpriceSelector,
  lastpriceSelector,
} from "modules/categories/redux/selectors";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

function LastpriceCell({ ticker }: Props) {
  const floorprice = useSelector(floorpriceSelector(ticker)) || 0;
  const ceilingprice = useSelector(ceilingpriceSelector(ticker)) || 0;
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;
  const lastprice = useSelector(lastpriceSelector(ticker)) || 0;
  return (
    <PriceCell
      price={lastprice}
      color={getColorPrice(lastprice, floorprice, ceilingprice, basicprice)}
      className="bg-overlay-col last-sale-price"
      ticker={ticker}
    />
  );
}

export default LastpriceCell;
