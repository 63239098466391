import {
  PayloadAction,
  createSlice,
  combineReducers,
  Reducer,
  AnyAction,
} from "@reduxjs/toolkit";
import User, {
  Account,
  IAuthenType,
  InfoAccount,
  // ResponseData
} from "domain/models/User";
import {
  CreateOTPParams,
  VerifySMSOTPParams,
  VerifyOTPResult,
  VerifyOTPError,
} from "core/http/apis/twofactor/types";
import { ChangePasswordParams } from "core/http/apis/password/types";
import { LoginParams, LogOutParams } from "core/http/apis/auth/types";
import { IntlShape } from "react-intl";
import storage from "redux-persist/lib/storage";
import { createTransform, persistReducer } from "redux-persist";
import { pick } from "lodash";
import { ReduxData, ReduxStateType } from "redux/types";

import notificationReducer from "./notification";
import managerDeviceReducer from "./managerDevice";
import serviceEmailSmsReducer from "./serviceEmailSms";
import hotNewsReducer from "./hotnews";
import modalEditGeneralInfoReducer from "./modalEditGeneralInfo";
import tradingAccountReducer from "./tradingAccount";
import { IAccountInfoResponse } from "domain/models/AccountInfo";
import { IFindAccountInfoParams } from "core/http/apis/account-info/types";
import Storage from "helper/storage";
import { TabSetting } from "helper/consts";
// import Storage from "helper/storage";

export interface AuthState {
  userInfo: InfoAccount;
  accountInfo: IAccountInfoResponse;
  isAuthenticated: boolean;
  createOTPMethod: CreateOTPParams;
  storageOTPCode: { code: string; type: number };
  verifyOTPMethod: VerifySMSOTPParams;
  verifyOTPResult: VerifyOTPResult;
  verifyOTPError: VerifyOTPError;
  statusModalSuccess: boolean;
  contentModalSuccess: string;
  hasIntlKeyModalSuccess: boolean;
  statusModalLogin: boolean;
  errorLogin: string;
  defauAccountForgetPassword: string;
  statusModalForgetPassword: boolean;

  visibleModalInfo: boolean;
  visibleModalEditInfo: boolean;
  visibleModalRegisterCaringStaff: boolean;
  visibleModalSMSOTPInfo: boolean;
  visibleModalSettingKeyShortcuts: boolean;
  visibleModalTheme: boolean;
  visibleModalEditPassFirst: boolean;
  visibleModalChangePassword: boolean;
  visibleModalChangePasswordSuccess: boolean;
  visibleModalSetting: boolean;
  visibleModalRegister: boolean;
  otpModalChangePassword: string | undefined;
  isLoginError: boolean;
  isReset: string;
  currentAccount: Account;
  authenType: IAuthenType;
  tabActiveSetting: TabSetting;
}

const initialState: ReduxData<AuthState> = {
  data: {
    isAuthenticated: false,
    userInfo: {
      tradeDate: "",
      marketDate: "",
      serverDate: "",
      serverTime: "",
      resetMarketTime: "",
      defaultSubAccoNo: "",
      changePassFlag: null,
      changePinFlag: null,
      currentUser: {
        userId: "",
        userType: 0,
        manageType: 0,
        branchCd: "",
        transactionCd: "",
        srcChannel: 0,
        displayName: "",
        mobileNo: "",
        transactionFlag: 0,
        lastAccess: 0,
        recvMsgTypes: [],
      },
      authenType: {
        id: 0,
        delCd: 0,
        refId: 0,
        userID: "",
        userType: 0,
        loginPinType: 0,
        tradingPinType: 0,
        advancePinType: 0,
        transferPinType: 0,
        oddSellPinType: 0,
        capIncreasePinType: 0,
        marginPinType: 0,
        depositPinType: 0,
        oneTimeAuth: 0,
        status: 0,
        regDateTime: 0,
        regUserId: "",
        updDateTime: 0,
        updUserId: "",
      },
      srvtTradingOnline: {
        id: 0,
        delCd: 0,
        refId: 0,
        custCd: 0,
        fromDate: 0,
        active: 0,
        od00: 0,
        pd00: 0,
        tr00: 0,
        ri00: 0,
        ko00: 0,
        mg00: 0,
        forgotPassword: 0,
        status: 0,
        remarks: null,
        regDateTime: 0,
        regUserId: "",
        updDateTime: 0,
        updUserId: "",
      },
      personalSettingList: [
        {
          paramName: "",
          value: "",
        },
      ],
      ordTypeList: [],
      subAccoList: [],
      entrustList: [],
      secListEn: [],
      categoryList: [],
      adfParamList: [],
      parameterList: [],
      parameterAdditionList: [],
      locationList: null,
      dertSecPriceListEn: [],
      entrustTypeList: [],
      entrustTypeDetailList: [],
      secTypeGroupList: null,
      subAccoListExt: [],
      currentCustomer: {
        custCd: 0,
        delCd: 0,
        custNo: "",
        transactionCd: "",
        custFamilyName: "",
        custGivenName: "",
        custType: 0,
        birthday: 0,
        sex: 0,
        mobileNo: "",
        emailAdrs: "",
        emailFlg: 0,
        approvalCd: 0,
        idNumber: "",
        issueLocation: "",
        issueDate: 0,
        expireDate: 0,
        countryCd: "",
        brokerCd: "",
        introducerCd: "",
        channelId: 0,
        numberOfSig: 0,
        accoDivide: 0,
        accoSts: 0,
        accoStsDate: 0,
        accoStrDate: 0,
        contractNo: "",
        orderMoneyConf: 0,
        orderStockConf: 0,
        notCollectTax: 0,
        foreignType: 0,
        regDateTime: 0,
        regUserId: "",
        updDateTime: 0,
        updUserId: "",
        bankList: null,
        address: null,
        telNo: null,
        preAccessTime: null,
        accoStsDisplay: null,
        userId: null,
        branchName: null,
        customerName: null,
        branchCd: null,
        contractStatus: 0,
      },
    },
    accountInfo: {},
    createOTPMethod: {
      masterAccount: "",
      deviceId: "",
      businessCd: "",
      sendType: "",
    },
    storageOTPCode: {
      code: "",
      type: 0,
    },
    verifyOTPMethod: {
      masterAccount: "",
      deviceId: "",
      deviceInfo: "",
      businessCd: "",
      smsOtp: "",
    },
    verifyOTPResult: {
      result: 1,
      message: "",
      error: "",
    },
    verifyOTPError: {
      result: 1,
      message: "",
      error: "",
      code: "",
    },
    statusModalSuccess: false,
    contentModalSuccess: "",
    hasIntlKeyModalSuccess: false,
    statusModalLogin: false,
    errorLogin: "",
    defauAccountForgetPassword: "",
    statusModalForgetPassword: false,

    visibleModalInfo: false,
    visibleModalEditInfo: false,
    visibleModalRegisterCaringStaff: false,
    visibleModalSMSOTPInfo: false,
    visibleModalSettingKeyShortcuts: false,
    visibleModalTheme: false,
    visibleModalChangePassword: false,
    visibleModalChangePasswordSuccess: false,
    visibleModalEditPassFirst: false,
    visibleModalSetting: false,
    visibleModalRegister: false,
    otpModalChangePassword: "",
    isLoginError: false,
    isReset: "",
    currentAccount: {
      subAccoCd: 0,
      subAccoNo: "",
    },
    authenType: {
      id: 0,
      delCd: 0,
      refId: 0,
      userID: "",
      userType: 0,
      loginPinType: 0,
      tradingPinType: 0,
      advancePinType: 0,
      transferPinType: 0,
      oddSellPinType: 0,
      capIncreasePinType: 0,
      marginPinType: 0,
      depositPinType: 0,
      oneTimeAuth: 0,
      status: 0,
      regDateTime: 0,
      regUserId: "",
      updDateTime: 0,
      updUserId: "",
    },
    tabActiveSetting: TabSetting.GENERAL,
  },
  status: ReduxStateType.INIT,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loginStart: (state, action: PayloadAction<LoginParams>) => {
      state.status = ReduxStateType.LOADING;
      state.data.isLoginError = false;
      state.data.errorLogin = "";
    },
    loginSuccess: (state) => {
      state.data.errorLogin = "";
      state.status = ReduxStateType.SUCCESS;
    },
    loginFailed: (
      state,
      action: PayloadAction<{ code: string; description: string }>
    ) => {
      if (action.payload.code === "error.errorServer") {
        state.data.errorLogin = action.payload.code;
      }

      state.status = ReduxStateType.ERROR;
      state.data.isLoginError = true;
    },
    logOutStart: (state, action: PayloadAction<LogOutParams>) => {
      state.status = ReduxStateType.LOADING;
    },
    logOutSuccess: (state) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.storageOTPCode = { type: 0, code: "" }; // xóa SmartOTP khi đăng xuất
      state.data.isAuthenticated = false;
      state.data.visibleModalInfo = false;
      state.data.visibleModalEditInfo = false;
      state.data.visibleModalSMSOTPInfo = false;
      state.data.visibleModalSetting = false;
      state.data.currentAccount = {
        subAccoCd: 0,
        subAccoNo: "",
      };
    },
    getMeStart: (state) => {
      state.status = ReduxStateType.LOADING;
    },
    getMeSuccess: (
      state,
      action: PayloadAction<{
        account: InfoAccount;
        isReset: string;
      }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data.userInfo = action.payload.account;
      state.data.isAuthenticated = true;
      state.data.isReset = action.payload.isReset;
      state.data.authenType = action.payload.account.authenType;
      let firstAccount: Account = {
        subAccoCd: 0,
        subAccoNo: "",
      };
      if (
        action.payload.account &&
        action.payload.account.subAccoList &&
        action.payload.account.subAccoList.length > 0
      ) {
        firstAccount = {
          subAccoCd: parseInt(
            action.payload.account.subAccoList[0].split("|")[0]
          ),
          subAccoNo: action.payload.account.subAccoList[0].split("|")[1],
        };
      }
      const defaultSubAccoNo = action.payload.account.defaultSubAccoNo;
      if (defaultSubAccoNo) {
        if (
          action.payload.account &&
          action.payload.account.subAccoList &&
          action.payload.account.subAccoList.length > 0
        ) {
          const accountCurrent = action.payload.account.subAccoList.find(
            (item) => item.split(defaultSubAccoNo).length > 0
          );
          state.data.currentAccount = {
            subAccoCd: accountCurrent
              ? parseInt(accountCurrent.split(defaultSubAccoNo)[0])
              : 0,
            subAccoNo: defaultSubAccoNo,
          };
        } else {
          state.data.currentAccount = {
            subAccoCd: 0,
            subAccoNo: defaultSubAccoNo,
          };
        }
      } else if (firstAccount.subAccoNo) {
        state.data.currentAccount = firstAccount;
      } else {
        state.data.currentAccount =
          Storage.getDefaultAccount()[Storage.getMasterAccount()];
      }
    },
    getMeFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.data.isAuthenticated = false;
      state = { ...initialState, status: ReduxStateType.ERROR };
    },

    changeInfoAccountSuccess: (
      state,
      action: PayloadAction<{ user: User }>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      // state.data.userInfo = action.payload.user;
      state.data.verifyOTPResult = {};
      state.data.isAuthenticated = true;
    },
    changeInfoAccountFailed: (state, action: PayloadAction<Error>) => {
      state = { ...initialState, status: ReduxStateType.ERROR };
    },
    createOTP: (
      state,
      action: PayloadAction<{
        params: CreateOTPParams;
        toast?: boolean;
        intl: IntlShape;
      }>
    ) => {
      state.data.createOTPMethod = action.payload.params;
    },
    setStorageOTPCode: (
      state,
      action: PayloadAction<{ code: string; type: number }>
    ) => {
      state.data.storageOTPCode = action.payload;
    },
    toastSaveOTPSuccess: () => {},
    createOTPFailed: (state, action: PayloadAction<Error>) => {
      state = { ...initialState, status: ReduxStateType.ERROR };
    },
    verifyOTP: (state, action: PayloadAction<VerifySMSOTPParams>) => {
      state.data.verifyOTPMethod = action.payload;
    },
    verifyOTPSuccess: (state, action: PayloadAction<VerifyOTPResult>) => {
      state.data.verifyOTPResult = action.payload;
    },
    verifyOTPFailed: (state, action: PayloadAction<VerifyOTPError>) => {
      state.data.verifyOTPError = action.payload;
    },
    changePassword: (state, action: PayloadAction<ChangePasswordParams>) => {},
    toggleModalSuccess: (
      state,
      action: PayloadAction<{ content: string; hasIntlKey: boolean }>
    ) => {
      state.data.statusModalSuccess = !state.data.statusModalSuccess;
      state.data.contentModalSuccess = action.payload.content;
      state.data.hasIntlKeyModalSuccess = action.payload.hasIntlKey;
    },

    toggleModalLogin: (state, action: PayloadAction<boolean>) => {
      state.status = ReduxStateType.INIT;
      state.data.statusModalLogin = action.payload;
      state.data.isLoginError = false;
      state.data.errorLogin = "";
    },
    toggleModalForgetPassword: (
      state,
      action?: PayloadAction<string | undefined>
    ) => {
      state.data.statusModalForgetPassword =
        !state.data.statusModalForgetPassword;
      state.data.defauAccountForgetPassword =
        action?.payload ?? state.data.defauAccountForgetPassword;
    },

    getSmsEmailList: (state, action: PayloadAction<string>) => {},

    setvisibleModalInfo: (state) => {
      state.data.visibleModalInfo = !state.data.visibleModalInfo;
    },
    setvisibleModalEditInfo: (state) => {
      state.data.visibleModalEditInfo = !state.data.visibleModalEditInfo;
    },
    setVisibleModalRegisterCaringStaff: (state) => {
      state.data.visibleModalRegisterCaringStaff =
        !state.data.visibleModalRegisterCaringStaff;
    },

    setvisibleModalSMSOTPInfo: (state) => {
      state.data.visibleModalSMSOTPInfo = !state.data.visibleModalSMSOTPInfo;
    },
    setvisibleModalSettingKeyShortcuts: (state) => {
      state.data.visibleModalSettingKeyShortcuts =
        !state.data.visibleModalSettingKeyShortcuts;
    },
    setvisibleModalTheme: (state) => {
      state.data.visibleModalTheme = !state.data.visibleModalTheme;
    },
    setvisibleModalChangePassword: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.data.visibleModalChangePassword =
        !state.data.visibleModalChangePassword;
      state.data.otpModalChangePassword = action.payload;
    },
    setVisibleModalChangePasswordSuccess: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.data.visibleModalChangePasswordSuccess = action.payload;
    },
    setvisibleModalSetting: (state) => {
      state.data.visibleModalSetting = !state.data.visibleModalSetting;
    },
    setTabActiveSetting: (state, action: PayloadAction<TabSetting>) => {
      state.data.tabActiveSetting = action.payload;
    },

    getAccountInfo: (state, action: PayloadAction<IFindAccountInfoParams>) => {
      state.status = ReduxStateType.LOADING;
    },
    getAccountInfoSuccess: (
      state,
      action: PayloadAction<IAccountInfoResponse>
    ) => {
      state.data.accountInfo = action.payload;
    },
    getAccountInfoFailed: (state, action: PayloadAction<Error>) => {
      state = { ...initialState, status: ReduxStateType.ERROR };
    },
    changeIsReset: (state, action: PayloadAction<string>) => {
      state.data.isReset = action.payload;
    },
    changeCurrentAccount: (state, action: PayloadAction<Account>) => {
      state.data.currentAccount = action.payload;
    },

    setvisibleModalRegister: (state) => {
      state.data.visibleModalRegister = !state.data.visibleModalRegister;
    },
    setvisibleModalEditPassFirst: (state) => {
      state.data.visibleModalEditPassFirst =
        !state.data.visibleModalEditPassFirst;
    },
  },
});

const persistConfig = {
  key: "auth:root",
  storage: storage,
  blacklist: ["status"],
  transforms: [
    createTransform((inboundState: ReduxData<AuthState>, key) => {
      if (key === "data")
        return pick(inboundState, [
          "isAuthenticated",
          "currentAccount",
          "storageOTPCode",
          "userInfo",
        ]);
      return inboundState;
    }, null),
  ],
};

export const {
  loginFailed,
  loginStart,
  loginSuccess,
  logOutStart,
  logOutSuccess,
  getMeStart,
  getMeSuccess,
  getMeFailed,

  changeInfoAccountSuccess,
  changeInfoAccountFailed,
  createOTP,
  setStorageOTPCode,
  toastSaveOTPSuccess,
  createOTPFailed,
  verifyOTP,
  verifyOTPSuccess,
  verifyOTPFailed,
  changePassword,
  toggleModalSuccess,
  toggleModalLogin,
  toggleModalForgetPassword,

  getSmsEmailList,

  setvisibleModalInfo,
  setvisibleModalEditInfo,
  setVisibleModalRegisterCaringStaff,
  setvisibleModalSMSOTPInfo,
  setvisibleModalSettingKeyShortcuts,
  setvisibleModalTheme,
  setvisibleModalChangePassword,
  setVisibleModalChangePasswordSuccess,
  setvisibleModalSetting,
  setTabActiveSetting,
  setvisibleModalRegister,

  getAccountInfo,
  getAccountInfoSuccess,
  getAccountInfoFailed,
  changeIsReset,
  changeCurrentAccount,
  setvisibleModalEditPassFirst,
} = authSlice.actions;

export default combineReducers({
  root: persistReducer(persistConfig, authSlice.reducer) as Reducer<
    ReduxData<AuthState>,
    AnyAction
  >,

  notification: notificationReducer,
  hotNews: hotNewsReducer,
  tradingAccount: tradingAccountReducer,
  modalEditGeneralInfo: modalEditGeneralInfoReducer,
  managerDevice: managerDeviceReducer,
  serviceEmailSms: serviceEmailSmsReducer,
});
