import {
  Body,
  Container,
  Title,
  ButtonPopover,
  Footer,
  RedDot,
  Tab,
  TotalUnread,
  MaskRead,
  BodyList,
} from "./styles";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as IconNoti } from "assets/image/svg/ic_noti.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import {
  getNotification,
  requestChangeVisibleModalNotification,
  changeVisibleNotificationDetail,
  deleteNotificationParams,
  checkReadAllNotificationParams,
  NotificationState,
} from "modules/auth/redux/notification";
import {
  GetNotificationListParams,
  DeleteNotificationParams,
  NotificationCheckReadAllParams,
} from "core/http/apis/trans/types";
import NotificationList from "./NotificationList";
import { useEffectOnce } from "react-use";
import { createSelector } from "@reduxjs/toolkit";
import NotificationDetail from "./NotificationDetail";
import Spinner from "react-bootstrap/Spinner";
import storage from "helper/storage";
import { NotificationListData } from "modules/auth/types";
import { useIntl, FormattedMessage } from "react-intl";
import { setTotalNotification } from "modules/system/redux/common";
import Storage from "helper/storage";
import { Spacer } from "components/commons";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";

const notificationSelector = (state: RootState) => state.auth.notification;

const notificationUnreadSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) =>
    notificatinState.notificationTotalUnread
);

const totalNotificationUnreadSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) =>
    notificatinState.totalNotificationUnread
);

const notifySelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) => notificatinState.notification
);

const visibleModalSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) =>
    notificatinState.visibleModalNotification
);

const notificationParamsSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) => notificatinState.notificationParams
);

const notificationDetailSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) => notificatinState.notificationDetail
);

const isVisibleDetailSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) =>
    notificatinState.isVisibleNotificationDetail
);

const isLoadingNotificationSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) =>
    notificatinState.isLoadingNotification
);

const isLoadingFetchMoreSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) => notificatinState.isLoadingFetchMore
);

const isLoadingDetailNotificationSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) =>
    notificatinState.isLoadingDetailNotification
);

const isFetchMoreDeleteSelector = createSelector(
  notificationSelector,
  (notificatinState: NotificationState) => notificatinState.isFetchMoreDelete
);

const NOTIFICATION_TYPE = {
  TRANSACTION: 1,
  SERVICE: 2,
  RECOMMENDATIONS: 3,
  NOTIFY: 99,
};

function ModalNotification() {
  const intl = useIntl();
  const visibleModalNotification = useSelector(visibleModalSelector);
  const [isActive, setIsActive] = useState(1);
  const masterAccount = storage.getMasterAccount();
  const isLoadingNotification = useSelector(isLoadingNotificationSelector);
  const isLoadingFetchMore = useSelector(isLoadingFetchMoreSelector);
  const isLoadingDetailNotification = useSelector(
    isLoadingDetailNotificationSelector
  );
  const isFetchMoreDelete = useSelector(isFetchMoreDeleteSelector);

  const dispatch = useDispatch();

  const toggleModalNotification = () => {
    if (!masterAccount) {
      storage.logOut();
      return;
    }
    dispatch(requestChangeVisibleModalNotification(!visibleModalNotification));
  };

  const closeModalNotification = () => {
    toggleModalNotification();
  };

  const notification: NotificationListData = useSelector(notifySelector);

  const onDelete = (date?: string, noticeId?: string) => {
    const paramsDelete: DeleteNotificationParams = {
      masterAccount: storage.getMasterAccount(),
      date: date ? date : "",
      noticeId: noticeId ? noticeId : "",
    };
    dispatch(
      deleteNotificationParams({
        params: paramsDelete,
        notificationList: notification,
      })
    );
    dispatch(changeVisibleNotificationDetail(false));
    const resLogData = {
      eventGroup: GroupEvent.noti,
      event: RealtimeConst.removeNotification,
      message: "Remove noti",
    };
    storage.commonPushLogFirebase(resLogData);
  };

  const padding0 = 0;
  const isVisibleDetail = useSelector(isVisibleDetailSelector);

  const handleTabChange = (type: number) => {
    try {
      dispatch(changeVisibleNotificationDetail(false));
      const params: GetNotificationListParams = {
        type: type,
        offset: 0,
        limit: 20,
      };
      setIsActive(type);
      // TODO
      dispatch(getNotification({ params: params, isChangeTab: true }));
      // let events =
    } catch (error: any) {}
  };

  useEffectOnce(() => {
    handleTabChange(1);
  });

  const params: GetNotificationListParams = useSelector(
    notificationParamsSelector
  );

  const fetchMoreData = () => {
    const submitData: GetNotificationListParams = {
      type: params.type,
      offset: params.offset + 20,
      limit: params.limit,
    };
    // TODO
    dispatch(getNotification({ params: submitData }));
  };

  useEffect(() => {
    if (isFetchMoreDelete) {
      // TODO
      fetchMoreData();
    }
  }, [isFetchMoreDelete]);

  const notificationUnread = useSelector(notificationUnreadSelector);
  const notificationDetail = useSelector(notificationDetailSelector);

  const totalNotificationUnread = useSelector(totalNotificationUnreadSelector);

  useEffect(() => {
    dispatch(setTotalNotification(totalNotificationUnread));
  }, [totalNotificationUnread]);

  const getRedDotState = (type: number) => {
    if (!notificationUnread) return false;

    const redDot = notificationUnread.data.find(
      (item) => item.noticeType === type
    );

    if (!redDot || redDot?.countUnread === 0) return false;
    return true;
  };

  const handleMaskRead = (noticeType: number) => {
    const params: NotificationCheckReadAllParams = {
      masterAccount: Storage.getMasterAccount(),
      noticeType: noticeType,
    };
    dispatch(checkReadAllNotificationParams(params));
    const resLogData = {
      eventGroup: GroupEvent.noti,
      event: RealtimeConst.readAllNotification,
      message: "Read all noti",
    };
    storage.commonPushLogFirebase(resLogData);
  };

  useEffect(() => {
    if (visibleModalNotification) {
      handleTabChange(isActive);
      // dispatch(getNotificationTotalUnreadStart());
    }
  }, [visibleModalNotification]);

  return (
    <>
      <ButtonPopover onClick={toggleModalNotification}>
        <IconNoti />
        <TotalUnread isDisable={totalNotificationUnread === 0}>
          {totalNotificationUnread < 100 ? totalNotificationUnread : "99+"}
        </TotalUnread>
      </ButtonPopover>
      {totalNotificationUnread === 0 && <Spacer size="s" />}
      <Modal
        show={visibleModalNotification}
        dialogClassName="modal-416"
        onHide={closeModalNotification}
      >
        <Container>
          <Title>
            <Tab
              isActive={
                isActive === NOTIFICATION_TYPE.TRANSACTION ? true : false
              }
              onClick={() => handleTabChange(NOTIFICATION_TYPE.TRANSACTION)}
            >
              {intl.formatMessage({ id: "auth.notification.transaction" })}
              <RedDot
                isActive={getRedDotState(NOTIFICATION_TYPE.TRANSACTION)}
              />
            </Tab>
            <Tab
              isActive={isActive === NOTIFICATION_TYPE.SERVICE ? true : false}
              onClick={() => {
                handleTabChange(NOTIFICATION_TYPE.SERVICE);
              }}
            >
              {intl.formatMessage({ id: "auth.notification.service" })}
              <RedDot isActive={getRedDotState(NOTIFICATION_TYPE.SERVICE)} />
            </Tab>
            <Tab
              isActive={
                isActive === NOTIFICATION_TYPE.RECOMMENDATIONS ? true : false
              }
              onClick={() => handleTabChange(NOTIFICATION_TYPE.RECOMMENDATIONS)}
            >
              {intl.formatMessage({ id: "auth.notification.recommendations" })}
              <RedDot
                isActive={getRedDotState(NOTIFICATION_TYPE.RECOMMENDATIONS)}
              />
            </Tab>
            <Tab
              isActive={isActive === NOTIFICATION_TYPE.NOTIFY ? true : false}
              onClick={() => handleTabChange(NOTIFICATION_TYPE.NOTIFY)}
            >
              {intl.formatMessage({ id: "auth.notification.notify" })}
              <RedDot isActive={getRedDotState(NOTIFICATION_TYPE.NOTIFY)} />
            </Tab>
          </Title>
          {notificationDetail &&
            Object.keys(notificationDetail).length > 0 &&
            isVisibleDetail &&
            (isLoadingDetailNotification ? (
              <div className="spinner">
                <Spinner animation="border" variant="light" />
              </div>
            ) : (
              <NotificationDetail
                data={notificationDetail}
                onDelete={onDelete}
              />
            ))}
          <BodyList isVisibleDetail={isVisibleDetail}>
            <Body padding={padding0}>
              {isLoadingNotification ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <>
                  <NotificationList
                    data={notification}
                    fetchMoreData={fetchMoreData}
                    onDelete={onDelete}
                    isLoadingFetchMore={isLoadingFetchMore}
                  />
                  {isLoadingFetchMore && (
                    <Spinner animation="border" variant="light" size="sm" />
                  )}
                </>
              )}
            </Body>
            <Footer>
              <MaskRead onClick={() => handleMaskRead(isActive)}>
                <FormattedMessage id="auth.notification.maskAsRead" />
              </MaskRead>
            </Footer>
          </BodyList>
        </Container>
      </Modal>
    </>
  );
}

export default ModalNotification;
