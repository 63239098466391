import { createSelector } from "@reduxjs/toolkit";
import { Select } from "components/commons";
import { userFavoriteListSelector } from "modules/categories/redux/selectors";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CategoryOption } from "./types";
import { State as CategotyState } from "modules/categories/redux";
import { RootState } from "redux/store";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";

export interface CategorySelectInputProps {
  selectedCategory?: CategoryOption;
  categoryOptions: CategoryOption[];
  onSelectCategory: (option: CategoryOption) => void;
}
const categoriesSelector = (state: RootState) => state.categories.root;
const ownerListSelector = createSelector(
  categoriesSelector,
  (categoryState: CategotyState) => categoryState.ownerList
);

function CategorySelectInput({
  selectedCategory,
  categoryOptions,
  onSelectCategory,
}: CategorySelectInputProps) {
  const intl = useIntl();

  const userFavoriteList = useSelector(userFavoriteListSelector);
  const ownerList = useSelector(ownerListSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);

  const [optionSelect, setOptionSelect] = useState(0);
  const handleChange = (option: any) => {
    onSelectCategory(option);
    for (let i = 0; i < categoryOptions.length; i++) {
      if (option === categoryOptions[i]) setOptionSelect(i);
    }
  };

  // check thêm mã từ đặt lệnh
  useEffect(() => {
    handleChange(categoryOptions[optionSelect]);
  }, [ownerList, userFavoriteList]);

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      if (categoryOptions.length > 0) {
        handleChange(categoryOptions[optionSelect || 0]);
      }
    }
  }, [isVisibilityPage]);

  return (
    <Select
      className="flex-fill"
      value={selectedCategory || null}
      options={categoryOptions}
      onChange={handleChange}
      placeholder={intl.formatMessage({
        id: "widgets.watchlist.selectCategory",
      })}
    />
  );
}

export default CategorySelectInput;
