import {
  ContentContainer,
  GroupTitle,
  LabelWrapper,
  LabelColorBox,
  LabelName,
  FinanceChartContainer,
} from "./styles";
import { FormattedMessage } from "react-intl";
import React from "react";
import HeaderButton from "components/commons/ButtonOptional/HeaderButton";
import { CashFlowOption } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  createGradientPlugin,
  FinanceLabelOnAxisPlugin,
} from "helper/chartPlugins";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  changeCashFlowType,
  EIndicatorType,
} from "modules/tickerDetails/redux/financeInfo";
import FinanceChart from "./FinanceChart";
import { ChartOptions } from "chart.js";
import numeral from "numeral";
import {
  cashFlowTypeSelector,
  dataChartCashFlowSelector,
} from "modules/tickerDetails/redux/selectors";
import min from "lodash/min";
import get from "lodash/get";
import max from "lodash/max";
import {
  getMaxTickForChart,
  getMinTickForChart,
} from "modules/tickerDetails/helpers/utils";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { CASH_FLOW_OPTIONS } from "./constants";

const GradientBgPlugin: any = createGradientPlugin("rgba(65, 188, 228, 0.2)");

// TODO
const CashFlow = () => {
  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const dataChartCashFlow = useSelector(dataChartCashFlowSelector);
  const cashFlowType = useSelector(cashFlowTypeSelector);

  const onChangeCashFlow = (option: EIndicatorType) => {
    dispatch(changeCashFlowType({ data: option }));
  };

  const plugins = [
    GradientBgPlugin,
    FinanceLabelOnAxisPlugin(themes[themeType]),
    ChartDataLabels,
  ];

  const option: ChartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 70,
        top: 30,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          id: "yAxis1",
          display: false,
          ticks: {
            suggestedMin: getMinTickForChart(
              min(get(dataChartCashFlow, "datasets[0].data", [0])) as number
            ),
            suggestedMax: getMaxTickForChart(
              max(get(dataChartCashFlow, "datasets[0].data", [0]))
            ),
          },
        },
        {
          id: "yAxis2",
          display: false,
          ticks: {
            suggestedMin: getMinTickForChart(
              min(get(dataChartCashFlow, "datasets[1].data", [0])) as number
            ),
            suggestedMax: getMaxTickForChart(
              max(get(dataChartCashFlow, "datasets[1].data", [0]))
            ),
          },
        },
      ],
      xAxes: [
        {
          id: "xAxis1",
          type: "category",
          ticks: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          if (
            parseInt(tooltipItem.yLabel) > 10000 ||
            parseInt(tooltipItem.yLabel) < -10000
          ) {
            return numeral(tooltipItem.yLabel).format("0,0ab");
          } else {
            return numeral(tooltipItem.yLabel).format("0.0");
          }
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <ContentContainer className="cash-flow">
      <h2>
        <FormattedMessage id="widgets.finance.cashFlow.label" />
      </h2>
      <div className="row no-gutters justify-content-between">
        {CASH_FLOW_OPTIONS.map((option) => {
          return (
            <div key={option.value} className="col-4">
              <HeaderButton
                active={cashFlowType === option.value}
                value={option.value}
                onClick={onChangeCashFlow}
                size="small"
              >
                <FormattedMessage
                  id={`widgets.finance.cashFlow.type.${option.label}`}
                />
              </HeaderButton>
            </div>
          );
        })}
      </div>
      <GroupTitle className="pt-3">
        <LabelWrapper>
          <LabelColorBox color="solidBlue" />
          <LabelName>
            <FormattedMessage
              id={`widgets.finance.cashFlow.type.${
                CASH_FLOW_OPTIONS.find(
                  (el: CashFlowOption) => el.value === cashFlowType
                )?.label
              }`}
            />
          </LabelName>
        </LabelWrapper>
        <LabelWrapper>
          <LabelColorBox color="solidYellow" />
          <LabelName>
            <FormattedMessage id="widgets.finance.cashFlow.type.yoy" /> (%)
          </LabelName>
        </LabelWrapper>
      </GroupTitle>
      {dataChartCashFlow !== undefined && (
        <FinanceChartContainer height={252}>
          <FinanceChart
            labels={dataChartCashFlow.labels}
            plugins={plugins}
            datasets={dataChartCashFlow.datasets}
            configs={option}
          />
        </FinanceChartContainer>
      )}
    </ContentContainer>
  );
};

export default CashFlow;
