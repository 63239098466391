import { memo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Title, Container, ButtonExit, Body, ButtonWrapper } from "./styles";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { visibleModalFaceVerifySelector } from "modules/auth/selectors";
import {
  setVisibleModalEditGeneralInfo,
  setVisibleModalFaceVerify,
  startFaceVerify,
} from "modules/auth/redux/modalEditGeneralInfo";

import Camera from "modules/system/ModalRegisterEkyc/components/Step1/B5/camera";
import { BoxCamera } from "modules/system/ModalRegisterEkyc/components/Step1/B5/styles";
import { toast } from "components/commons/Toast";

function FaceVerify() {
  const dispatch = useDispatch();
  const visibleModal = useSelector(visibleModalFaceVerifySelector);
  const [file, setFile] = useState<File | undefined>(undefined);
  const closeModal = () => {
    dispatch(setVisibleModalEditGeneralInfo(true));
    dispatch(setVisibleModalFaceVerify(false));
  };

  const submit = () => {
    if (!file) return toast("Ảnh khuôn mặt không hợp lệ", "error");
    dispatch(startFaceVerify(file));
  };

  return (
    <>
      <Modal show={visibleModal} centered onHide={closeModal} keyboard={false}>
        <Container>
          <Title>
            <div className="content">
              <FormattedMessage id="Sửa thông tin chung" />
            </div>
            <ButtonExit onClick={closeModal}>
              <IconExit />
            </ButtonExit>
          </Title>
          <Body>
            <BoxCamera>
              <Camera changeImage={(value) => setFile(value)} />
            </BoxCamera>

            <Spacer size="l" />
            <ButtonWrapper>
              <Button
                color="accept"
                className="flex-fill mr-2"
                onClick={closeModal}
              >
                <FormattedMessage id="Hủy" />
              </Button>

              <Button
                color="accept"
                className="flex-fill"
                fill={true}
                onClick={submit}
              >
                <FormattedMessage id="Cập nhật" />
              </Button>
            </ButtonWrapper>
          </Body>
        </Container>
      </Modal>
    </>
  );
}

export default memo(FaceVerify);
