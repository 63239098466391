import CommonTableInfinity from "components/commons/CommonTableInfinity";
import {
  fetchMoreListRequest,
  updateEnableInfinity,
} from "modules/margin-securities-portfolio/redux";
import {
  getListMarginSecuritiesPortfolio,
  selectLimit,
  selectCurrentPage,
  selectEnableInfinity,
} from "modules/margin-securities-portfolio/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./styles";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";

interface PROPS {
  newCusGroupId: any;
  stockCode: any;
}

const Table = (props: PROPS) => {
  const { newCusGroupId, stockCode } = props;
  const dispatch = useDispatch();
  const data = useSelector(getListMarginSecuritiesPortfolio);
  const limit = useSelector(selectLimit);
  const currentPage = useSelector(selectCurrentPage);
  const enable = useSelector(selectEnableInfinity);

  const newData = data?.map((item: any, index: number) => {
    return {
      ...item,
      stt: index + 1,
    };
  });

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="marginSecuritiesPortfolio.no" />
          </TableHeaderCellContent>
        ),
        minWidth: 80,
        accessor: "stt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"left"}>
            <FormattedMessage id="marginSecuritiesPortfolio.stockCode" />
          </TableHeaderCellContent>
        ),
        minWidth: 180,
        accessor: "secCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="marginSecuritiesPortfolio.stockName" />
          </TableHeaderCellContent>
        ),
        minWidth: 708,
        accessor: "secName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"left"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"right"}>
            <FormattedMessage id="marginSecuritiesPortfolio.loanRate" />
          </TableHeaderCellContent>
        ),
        minWidth: 200,
        accessor: "mLoanRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {Intl.NumberFormat().format(props.value)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"right"}>
            <FormattedMessage id="marginSecuritiesPortfolio.estimateRate" />
          </TableHeaderCellContent>
        ),
        minWidth: 200,
        accessor: "estimateRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {Intl.NumberFormat().format(props.value)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"right"}>
            <FormattedMessage id="marginSecuritiesPortfolio.minPrice" />
          </TableHeaderCellContent>
        ),
        minWidth: 200,
        accessor: "minPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"right"}>
            <FormattedMessage id="marginSecuritiesPortfolio.maxPrice" />
          </TableHeaderCellContent>
        ),
        minWidth: 200,
        accessor: "maxPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"right"}>
            <FormattedMessage id="marginSecuritiesPortfolio.marketName" />
          </TableHeaderCellContent>
        ),
        minWidth: 120,
        accessor: "marketName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"right"}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {
    if (data.length < (currentPage + 1) * limit) {
      return dispatch(updateEnableInfinity(false));
    }
    dispatch(
      fetchMoreListRequest({
        custGroupId: newCusGroupId,
        secCd: stockCode,
        status: 1,
        page: currentPage + 1,
        rowPerPage: limit,
      })
    );
  };

  return (
    <Container id="marginSecuritiesPortfolioTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? newData : []}
        enableInfinity={enable}
        scrollableTarget={"marginSecuritiesPortfolioTable"}
        isHasFooter={false}
      />
    </Container>
  );
};

export default memo(Table);
