import { all } from "@redux-saga/core/effects";
import orderFlowDistributionSaga from "./orderFlowDistributionSaga";
import tickerInfoSaga from "./tickerInfoSaga";
import volumeAndTimeWidgetSaga from "./volumeAndTimeWidgetSaga";
import organizationInfoWidget from "./organizationInfoWidget";
import financeInfoWidget from "./financeInfoWidget";
import newsInfoWidget from "./newsInfoWidget";

export default function* tickerDetailsSaga() {
  yield all([
    orderFlowDistributionSaga(),
    tickerInfoSaga(),
    volumeAndTimeWidgetSaga(),
    organizationInfoWidget(),
    financeInfoWidget(),
    newsInfoWidget(),
  ]);
}
