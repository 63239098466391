import styled from "styled-components/macro";

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const WrapText = styled.div`
  color: ${({ theme }) => theme.grey0};
  margin-bottom: 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const WrapContent = styled.div`
  color: ${({ theme }) => theme.grey0};
  padding: 14px;
  border: 1px dashed ${({ theme }) => theme.border1};
`;

export const WrapLi = styled.li`
  margin-bottom: 8px;
`;
