import FlexLayout, { Action, Layout, TabNode } from "flexlayout-react";
import {
  IconAddWidgetContainer,
  IconPlusTabContainer,
  PopWidgetContainer,
  PageContainer,
  TabContainer,
  PopSaveTitle,
  PopSaveBody,
  PopSaveContainer,
  TitleModal,
  RowModal,
  ButtonExit,
  Suggestions,
  TextContent,
} from "./styles";
import { Modal } from "react-bootstrap";
import React, { useRef, useState } from "react";
import {
  dataFlexLayout,
  formatTextFlexLayout,
  getDefaultTab,
  parseDataToSaveStorage,
  parseDataToState,
} from "helper/flexLayout";
import { Icon } from "components/commons/Icon/styles";
import { Button, Input, Spacer } from "components/commons";
import CreateBoard from "./components/CreateBoard";
import { ReactComponent as IconPlus } from "assets/image/svg/ic_plus16.svg";
import { ReactComponent as IconList } from "assets/image/svg/ic_list16.svg";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit.svg";
import { ReactComponent as IconMaximize } from "assets/image/svg/ic_enlarge.svg";
import { ReactComponent as IconClose } from "assets/image/svg/ic_close.svg";
import Storage from "helper/storage";
import Tab from "./components/Tab";
import { v4 as uuid } from "uuid";
import MarketWidgets from "modules/market/MarketWidgets";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTab,
  setCustomizedBoard,
  setVisiblePopAddWidget,
  setTabs,
} from "modules/market/redux";
import first from "lodash/first";
import { useEffectOnce } from "react-use";
import { FormattedMessage, useIntl } from "react-intl";
import cloneDeep from "lodash/cloneDeep";
import { IIcons } from "flexlayout-react/declarations/view/Layout";
import { TabHeader } from "modules/market/types";
import { MaximunTabFlexlayout } from "AppConfig";
import { toast } from "components/commons/Toast";
import {
  visiblePopAddWidgetSelector,
  currentTabSelector,
  customizedBoardsSelector,
  tabsSelector,
} from "modules/market/selectors";
import { findAllTabSet } from "helper/utils";
import TabIndex from "modules/market/pages/Market/components/TabIndex";

export interface TabInterface {
  name: string;
  model?: any;
  id: string;
  desc?: string;
  image?: string;
}

const MARKET_LAYOUT_STORAGE_KEY = "market_layout";
export const MARKET_BOARD_STORAGE_KEY = "market_board";
const MARKET_CURRENT_TAB_STORAGE_KEY = "market_current_tab";

const MarketPage: React.FC = () => {
  const intl = useIntl();
  const [visibleModalSaveBoard, setVisibleModalSaveBoard] = useState(false);
  const [visibleModalRenameBoard, setVisibleModalRenameBoard] = useState(false);
  const flexLayoutRef = useRef<Layout>(null);
  const refInputNameBoard = useRef<HTMLInputElement>(null);
  const refInputDescBoard = useRef<HTMLInputElement>(null);
  const refInputRenameBoard = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const visiblePopAddWidget = useSelector(visiblePopAddWidgetSelector);
  const currentTab = useSelector(currentTabSelector);
  const customizedBoards = useSelector(customizedBoardsSelector);
  const tabs = useSelector(tabsSelector);

  useEffectOnce(() => {
    let customizedStorage = Storage.get(MARKET_BOARD_STORAGE_KEY);
    if (customizedStorage)
      dispatch(setCustomizedBoard(parseDataToState(customizedStorage)));
  });

  useEffectOnce(() => {
    const data = Storage.get(MARKET_LAYOUT_STORAGE_KEY);
    const currentTab = Storage.get(MARKET_CURRENT_TAB_STORAGE_KEY);
    if (data) {
      dispatch(setTabs(parseDataToState(data)));
      dispatch(setCurrentTab(currentTab));
      return;
    }
    if (tabs.length) {
      dispatch(setCurrentTab(currentTab || first(tabs)?.id || ""));
    } else {
      // Create new tab and auto-select that tab if there is no tabs
      const firstTabId = uuid();
      dispatch(
        setTabs([
          {
            name: intl.formatMessage({ id: "market.newScreen" }),
            model: FlexLayout.Model.fromJson(dataFlexLayout.normalMarket),
            id: firstTabId,
          },
        ])
      );

      changeTab(firstTabId)();
    }
  });

  const storeDataBoards = (dataStore: any, curTab?: string) => () => {
    Storage.set(
      MARKET_LAYOUT_STORAGE_KEY,
      JSON.stringify(parseDataToSaveStorage(dataStore))
    );
    if (curTab) Storage.set(MARKET_CURRENT_TAB_STORAGE_KEY, curTab);
  };

  const changeTab = (id: string) => () => {
    Storage.set(MARKET_CURRENT_TAB_STORAGE_KEY, id);
    dispatch(setCurrentTab(id));
  };

  const insertTab = () => {
    if (tabs && tabs.length < Number(MaximunTabFlexlayout)) {
      const newId = uuid();
      dispatch(setTabs([...tabs, getDefaultTab(newId, intl)]));
      dispatch(setCurrentTab(newId));
      storeDataBoards([...tabs, getDefaultTab(newId, intl)], newId)();
    } else {
      toast("widgets.maximunTabError", "error");
    }
  };

  const closeTab = (index: number) => (e: any) => {
    e.stopPropagation();
    if (tabs.length === 1) return;
    const dataClone = [...tabs];
    let curId;
    if (dataClone[index].id === currentTab) {
      if (index === 0) {
        curId = dataClone[1].id;
      } else if (index === dataClone.length - 1) {
        curId = dataClone[dataClone.length - 2].id;
      } else {
        curId = dataClone[index - 1].id;
      }
    }
    dataClone.splice(index, 1);
    if (curId) dispatch(setCurrentTab(curId));
    dispatch(setTabs(dataClone));
    storeDataBoards(dataClone, curId)();
  };

  const createBoard = (type: any, { name, model }: any) => {
    const dataClone = [...tabs];
    const index = dataClone.findIndex((model) => model.id === currentTab);
    if (index < -1) return;
    dataClone[index] = {
      ...dataClone[index],
      name,
      model: FlexLayout.Model.fromJson(
        type === "customized" ? model.toJson() : dataFlexLayout[type]
      ),
    };

    dispatch(setTabs(dataClone));
    storeDataBoards(dataClone)();
  };

  const saveBoard = () => {
    const name = refInputNameBoard.current?.value;
    const desc = refInputDescBoard.current?.value;
    const tab = tabs.find((model) => model.id === currentTab);
    if (!name || !tab) return;
    if (!tab.model) return toggleModalSaveBoard();
    const boardItem: TabInterface[] = [
      {
        name: name,
        model: tab.model,
        id: tab.id,
        desc: desc,
      },
    ];
    const customizedBoardsNew = customizedBoards.concat(boardItem);

    dispatch(setCustomizedBoard(cloneDeep(customizedBoardsNew)));
    toggleModalSaveBoard();
    Storage.set(
      MARKET_BOARD_STORAGE_KEY,
      JSON.stringify(parseDataToSaveStorage(customizedBoardsNew))
    );
  };

  const handleCustomizedBoard = (customizedBoardsNew: TabInterface[]) => {
    dispatch(setCustomizedBoard(customizedBoardsNew));
  };

  const renameBoard = () => {
    const name = refInputRenameBoard.current?.value;
    const indexTab = tabs.findIndex((model) => model.id === currentTab);
    if (!name || indexTab < 0) return;
    const tabsNew: TabHeader[] = tabs.map((item) => {
      if (item.id === currentTab) {
        return {
          ...item,
          name,
        };
      } else {
        return item;
      }
    });
    // tabs[indexTab] = { ...tabs[indexTab], name };
    dispatch(setTabs(cloneDeep(tabsNew)));
    storeDataBoards(tabsNew)();
    toggleModalRenameBoard();
  };

  const togglePopAddWidget = () =>
    dispatch(setVisiblePopAddWidget(!visiblePopAddWidget));

  const toggleModalSaveBoard = () => setVisibleModalSaveBoard((prev) => !prev);

  const toggleModalRenameBoard = () =>
    setVisibleModalRenameBoard((prev) => !prev);

  const addWidget = (widgetName: string) => () => {
    const id = uuid();
    if (!flexLayoutRef.current) return;

    let dataCheck =
      flexLayoutRef?.current?.props?.model?.toJson()?.layout?.children;

    const currentAllTab: any[] = [];

    findAllTabSet(
      dataCheck,
      ({ type }: { type: string }) => type === "tab",
      currentAllTab
    );

    let isExistWidget = undefined;

    if (currentAllTab.length) {
      isExistWidget = currentAllTab.find((item) => item.name === widgetName);
    }

    if (!isExistWidget) {
      flexLayoutRef.current.addTabWithDragAndDrop(
        intl.formatMessage({ id: "market.dragTooltip" }),
        {
          name: widgetName,
          id,
        }
      );
    } else {
      toast(intl.formatMessage({ id: "common.existTabError" }), "error");
    }
  };

  const renderTabHeaders = () => {
    if (!tabs.length) return;
    return tabs.map(({ id, name, model }, index) => (
      <Tab
        key={id}
        id={id}
        active={id === currentTab}
        onPress={changeTab(id)}
        onClickX={closeTab(index)}
        name={name}
        model={model}
        onRename={toggleModalRenameBoard}
        onSaveBoard={toggleModalSaveBoard}
      />
    ));
  };

  const renderModalSaveBoard = () => {
    const tab = tabs.find((model) => model.id === currentTab);
    return (
      <>
        <Modal
          show={visibleModalSaveBoard}
          onHide={toggleModalSaveBoard}
          centered
          dialogClassName="modal-300"
        >
          <PopSaveContainer>
            <PopSaveTitle>
              {intl
                .formatMessage({
                  id: "stockOrderBoard.modalSaveBoard.titleModal",
                })
                .toUpperCase()}
            </PopSaveTitle>
            <PopSaveBody>
              <div className="header">
                <FormattedMessage id="stockOrderBoard.modalSaveBoard.nameBoard" />
              </div>
              <Input
                innerRef={refInputNameBoard}
                autoFocus
                defaultValue={tab?.name}
                placeholder={intl.formatMessage({
                  id: "stockOrderBoard.modalSaveBoard.nameBoard",
                })}
              />
              <Spacer />
              <div className="header">
                <FormattedMessage id="stockOrderBoard.modalSaveBoard.description" />
              </div>
              <Input
                innerRef={refInputDescBoard}
                defaultValue={tab?.desc}
                placeholder={intl.formatMessage({
                  id: "stockOrderBoard.modalSaveBoard.description",
                })}
              />
              <div className="footer">
                <div className="flex-grow-1" />
                <Button
                  size="m"
                  onClick={toggleModalSaveBoard}
                  className="flex-grow-1"
                  color="accept"
                >
                  <FormattedMessage id="stockOrderBoard.modalSaveBoard.cancel" />
                </Button>
                <Spacer />
                <Button
                  size="m"
                  fill
                  color="accept"
                  onClick={saveBoard}
                  className="flex-grow-1"
                >
                  <FormattedMessage id="stockOrderBoard.modalSaveBoard.save" />
                </Button>
              </div>
            </PopSaveBody>
          </PopSaveContainer>
        </Modal>
      </>
    );
  };

  const renderModalRenameBoard = () => {
    const tab = tabs.find((model) => model.id === currentTab);
    return (
      <Modal
        show={visibleModalRenameBoard}
        onHide={toggleModalRenameBoard}
        centered
        dialogClassName="modal-300"
      >
        <PopSaveContainer>
          <PopSaveTitle>
            {intl
              .formatMessage({
                id: "stockOrderBoard.modalRenameBoard.titleModal",
              })
              .toUpperCase()}
          </PopSaveTitle>
          <PopSaveBody>
            <div className="header">
              <FormattedMessage id="stockOrderBoard.modalSaveBoard.nameBoard" />
            </div>
            <Input
              innerRef={refInputRenameBoard}
              autoFocus
              defaultValue={tab?.name}
              placeholder={intl.formatMessage({
                id: "stockOrderBoard.modalSaveBoard.nameBoard",
              })}
            />
            <div className="footer">
              <div className="flex-grow-1" />
              <Button
                size="m"
                fill
                onClick={renameBoard}
                color="accept"
                className="flex-grow-1"
              >
                <FormattedMessage id="stockOrderBoard.modalRenameBoard.ok" />
              </Button>
              <Spacer />
              <Button
                size="m"
                onClick={toggleModalRenameBoard}
                color="accept"
                className="flex-grow-1"
              >
                <FormattedMessage id="stockOrderBoard.modalRenameBoard.cancel" />
              </Button>
            </div>
          </PopSaveBody>
        </PopSaveContainer>
      </Modal>
    );
  };

  const factory = (node: TabNode) => {
    let name = node.getName();
    let component = MarketWidgets.find((widget) => {
      return widget.name === name;
    });
    const Component = component?.component;
    return Component ? (
      <Component />
    ) : (
      intl.formatMessage({ id: "market.viewDoesntExist" })
    );
  };

  function titleFactory(node: TabNode) {
    let name = node.getName();
    return <FormattedMessage id={name} />;
  }

  function onRenderTab(node: TabNode, renderValues: any) {
    let name = node.getName();
    renderValues.name = intl.formatMessage({ id: name });
  }

  const handleFlexAction = (action: Action) => {
    if (action.type === "FlexLayout_AddNode") {
    }
    return action;
  };

  const renderContentTab = () => {
    const iconMaximize: IIcons = {
      maximize: <IconMaximize />,
      close: <IconClose className="icon-close" />,
    };
    if (!tabs.length) return;
    return tabs.map(
      (item, index) =>
        item.id === currentTab && (
          <div
            key={item.id}
            className={`${item.id === currentTab ? "d-block" : "d-none"}`}
            style={{ position: "relative", flex: 1 }}
          >
            {item.model ? (
              <FlexLayout.Layout
                model={item.model}
                i18nMapper={(id, param?) =>
                  formatTextFlexLayout(id, intl, param)
                }
                factory={factory}
                titleFactory={titleFactory}
                ref={item.id === currentTab ? flexLayoutRef : undefined}
                onModelChange={storeDataBoards(tabs, currentTab)}
                onAction={handleFlexAction}
                onRenderTab={onRenderTab}
                icons={iconMaximize}
              />
            ) : (
              <CreateBoard
                key={item.id}
                onCreateBoard={createBoard}
                customBoards={customizedBoards}
                setCustomizedBoard={handleCustomizedBoard}
              />
            )}
          </div>
        )
    );
  };
  return (
    <>
      <PageContainer>
        {renderModalSaveBoard()}
        {renderModalRenameBoard()}
        <TabContainer>
          <TabIndex />
          {renderTabHeaders()}
          <IconPlusTabContainer className="px-2 border1" onClick={insertTab}>
            <IconPlus />
          </IconPlusTabContainer>
          <IconAddWidgetContainer
            className="border1"
            onClick={togglePopAddWidget}
          >
            <IconList />
            {visiblePopAddWidget && (
              <PopWidgetContainer>
                <TitleModal>
                  <div className="flex-grow-1" />
                  <TextContent>
                    {intl
                      .formatMessage({ id: "stockOrderBoard.widgetsSettings" })
                      .toUpperCase()}
                  </TextContent>
                  <ButtonExit
                    onClick={() => {
                      togglePopAddWidget();
                    }}
                  >
                    <Icon>
                      <IconExit />
                    </Icon>
                  </ButtonExit>
                </TitleModal>
                <Suggestions>
                  <FormattedMessage id="stockOrderBoard.suggestionsWidgetsSettings" />
                </Suggestions>
                {MarketWidgets.map(({ name }) => (
                  <RowModal id={name} onClick={addWidget(name)} key={name}>
                    {intl.formatMessage({ id: name })}
                  </RowModal>
                ))}
              </PopWidgetContainer>
            )}
          </IconAddWidgetContainer>
        </TabContainer>
        {renderContentTab()}
      </PageContainer>
    </>
  );
};

export default withRouter(MarketPage);
