import TreemapChart from "components/commons/Charts/TreemapChart";
import { DEFAULT_WIDGET_REFETCH_INTERVAL, UNIT_NUMBER } from "helper/consts";
import { getColorChart } from "helper/utils";
import sortBy from "lodash/sortBy";
import { foreignChartSelector } from "modules/market/redux/selectors";
import { fetchForeignIndustryChart } from "modules/market/redux/foreignChart";
import numeral from "numeral";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce, useInterval } from "react-use";
import { themeTypeSelector } from "themes/redux";
import {
  ContentContainer,
  Description,
  Header,
  SummaryValue,
} from "components/widgets/generals/ForeignChart/styles";
import { ChartIndustriesData } from "./types";
import { languageTypeSelector } from "modules/categories/redux/selectors";

function ForeignChart() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { foreignIndustryChartData } = useSelector(foreignChartSelector);
  const themeType = useSelector(themeTypeSelector);
  const languageType = useSelector(languageTypeSelector);

  const getIndustryName = (
    industryNameVi?: string,
    industryNameEn?: string
  ) => {
    return languageType === "vi" ? industryNameVi : industryNameEn;
  };

  useEffectOnce(() => {
    dispatch(fetchForeignIndustryChart());
  });

  useInterval(() => {
    dispatch(fetchForeignIndustryChart());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  const generateData = () => {
    let chartData: number[] = [];
    let chartTooltips: string[] = [];
    let chartTooltips2: string[] = [];
    let chartTooltips3: string[] = [];
    let chartColors: string[] = [];
    let chartLabels: string[] = [];
    let chartSubLabels: string[] = [];
    let chartLongLabels: string[] = [];

    let totalBuyValue: number = foreignIndustryChartData.totalBuyValue!;
    let totalSellValue: number = foreignIndustryChartData.totalSellValue!;
    let totalNetValue: number = foreignIndustryChartData.totalNetValue!;

    const sortedData = sortBy(foreignIndustryChartData.items, [
      function (o) {
        return o.totalForeignNetValue! < 0
          ? o.totalForeignNetValue! * -1
          : o.totalForeignNetValue!;
      },
    ]).reverse();
    sortedData.forEach((item: ChartIndustriesData) => {
      chartData.push(
        item.totalForeignNetValue! < 0
          ? item.totalForeignNetValue! * -1
          : item.totalForeignNetValue!
      );
      chartSubLabels.push(
        `${numeral(
          item.totalForeignNetValue! / UNIT_NUMBER.ONE_THOUSAND
        ).format("0,0")}${intl.formatMessage({ id: "common.million" })}`
      );

      chartTooltips.push(
        `${intl.formatMessage({
          id: "widgets.foreignWatch.buyValue",
        })}: ${numeral(
          item.totalForeignBuyValue! / UNIT_NUMBER.ONE_THOUSAND
        ).format("0,0")}${intl.formatMessage({ id: "common.million" })}`
      );
      chartTooltips2.push(
        `${intl.formatMessage({
          id: "widgets.foreignWatch.sellValue",
        })}: ${numeral(
          item.totalForeignSellValue! / UNIT_NUMBER.ONE_THOUSAND
        ).format("0,0")}${intl.formatMessage({ id: "common.million" })}`
      );
      chartTooltips3.push(
        `${intl.formatMessage({
          id: "widgets.foreignWatch.netValue",
        })}: ${numeral(
          item.totalForeignNetValue! / UNIT_NUMBER.ONE_THOUSAND
        ).format("0,0")}${intl.formatMessage({ id: "common.million" })}`
      );
      chartColors.push(getColorChart(item.color!, themeType));
      chartLabels.push(
        getIndustryName(item.industryNameVi, item.industryNameEn) || ""
      );
      chartLongLabels.push(
        `${getIndustryName(item.industryNameVi, item.industryNameEn)}`
      );
    });

    return {
      chartData,
      chartColors,
      chartLabels,
      chartSubLabels,
      chartTooltips,
      chartTooltips2,
      chartTooltips3,
      chartLongLabels,
      totalBuyValue,
      totalSellValue,
      totalNetValue,
    };
  };

  const {
    chartData,
    chartColors,
    chartLabels,
    chartSubLabels,
    chartTooltips,
    chartTooltips2,
    chartTooltips3,
    chartLongLabels,
    totalBuyValue,
    totalSellValue,
    totalNetValue,
  } = generateData();

  return (
    <ContentContainer>
      <Header>
        <div className="item-selection market-filter">
          <Description>
            <FormattedMessage id="widgets.foreignWatch.description" />
          </Description>
        </div>
        <div className="item-summary">
          <div>
            <FormattedMessage id="widgets.foreignWatch.totalBuyValue" />:
            <SummaryValue>
              {`${numeral(totalBuyValue / UNIT_NUMBER.ONE_MILLION).format(
                "0.0"
              )}`}{" "}
              <FormattedMessage id="common.unit" />
            </SummaryValue>
          </div>
          <div>
            <FormattedMessage id="widgets.foreignWatch.totalSellValue" />:
            <SummaryValue>
              {`${numeral(totalSellValue / UNIT_NUMBER.ONE_MILLION).format(
                "0.0"
              )}`}{" "}
              <FormattedMessage id="common.unit" />
            </SummaryValue>
          </div>
          <div>
            <FormattedMessage id="widgets.foreignWatch.totalNetValue" />:
            <SummaryValue textColor={totalNetValue >= 0 ? 1 : -1}>
              {`${numeral(totalNetValue / UNIT_NUMBER.ONE_MILLION).format(
                "0.0"
              )}`}{" "}
              <FormattedMessage id="common.unit" />
            </SummaryValue>
          </div>
        </div>
      </Header>
      <div className="chart">
        <TreemapChart
          values={chartData}
          tooltipValues={chartTooltips}
          colors={chartColors}
          treeLabels={chartLabels}
          treeSubLabels={chartSubLabels}
          treeLongLabels={chartLongLabels}
          tooltipValues2={chartTooltips2}
          tooltipValues3={chartTooltips3}
        />
      </div>
    </ContentContainer>
  );
}

export default ForeignChart;
