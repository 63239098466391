import styled from "styled-components/macro";

export const HeaderSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.shadowInner1};
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: ${({ theme }) => theme.grey70};
  .headerText {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    color: ${({ theme }) => theme.neutral7};
  }
`;

export const ChartSectionWrapper = styled.div<{
  size: "large" | undefined;
}>`
  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: ${({ size }) => (size === "large" ? "410px" : "290px")}; */
`;

export const ChartSection = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  min-height: 100%;
  justify-content: center;

  .barChartSection {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;

    .chartjs-render-monitor {
      max-width: 100% !important;
    }
  }

  .total {
    text-align: center;
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    color: ${({ theme }) => theme.textcolorWhite};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    .total-inflow {
      margin-left: 5px;
      color: ${({ theme }) => theme.green50};
    }
    .total-outflow {
      margin-left: 5px;
      color: ${({ theme }) => theme.red50};
    }
  }

  @media (max-width: 1919px) {
    max-width: 900px;
  }

  @media (max-width: 1680px) {
    max-width: 700px;
  }

  @media (max-width: 1399px) {
    max-width: 540px;
  }
`;

export const Icon = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-left: 8px;
`;

export const PopoverContainer = styled.div`
  z-index: 9999;
  width: 280px;
  background-color: rgba(31, 45, 63, 0.9);
  padding: 8px 16px;
  margin-right: 12px;
  position: relative;
  border-radius: 4px;
`;

export const PopoverTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.brandPrimary};
  margin-bottom: 8px;
`;

export const PopoverText = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
`;
