import { ReactComponent as IconPlus } from "assets/image/svg/ic_plus_white.svg";
import { ReactComponent as IconCheck } from "assets/image/svg/ic_check.svg";
import {
  AddButton,
  DropdownItem,
  DropdownText,
  CheckMarkContainer,
} from "./styles";

export type WatchListDropdownItemProps = {
  nameVi: string;
  name: string;
  checked: boolean;
  onAddClick?: (text: string) => void;
  onRemoveClick?: (text: string) => void;
};

export default function WatchListDropdownItem({
  nameVi,
  name,
  checked,
  onAddClick = () => {},
  onRemoveClick = () => {},
}: WatchListDropdownItemProps) {
  return (
    <DropdownItem>
      <DropdownText title={nameVi}>{nameVi}</DropdownText>
      {!checked && (
        <AddButton onClick={() => onAddClick(name)}>
          <IconPlus />
        </AddButton>
      )}
      {checked && (
        <CheckMarkContainer onClick={() => onRemoveClick(name)}>
          <IconCheck />
        </CheckMarkContainer>
      )}
    </DropdownItem>
  );
}
