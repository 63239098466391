import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import Screeners from "./pages/Screeners";

const routes: RouterConfig[] = [
  {
    page: Screeners,
    path: paths.screeners,
    exact: true,
    layout: MainLayout,
    checkLogin: false,
  },
];

export default routes;
