import { FC } from "react";
import { TopPriceLeftState } from "modules/market/redux/topPriceLeft";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { CellWrapper } from "components/widgets/generals/TopForeign/Cells/styles";
import BlinkableCell from "components/widgets/generals/TopForeign/Cells/BlinkableCell";
import { marketTopPriceLeftSelector } from "modules/market/redux/selectors";
import numeral from "numeral";

const colorSelector = (ticker: string) => {
  return createSelector(
    marketTopPriceLeftSelector,
    (topPriceLeftState: TopPriceLeftState) =>
      topPriceLeftState?.data?.[ticker]?.colorcode
  );
};

const priceSelector = (ticker: string) => {
  return createSelector(
    marketTopPriceLeftSelector,
    (topPriceLeftState: TopPriceLeftState) =>
      topPriceLeftState?.data?.[ticker]?.closeprice
  );
};

interface Props {
  ticker: string;
}

const Cell: FC<Props> = ({ ticker }) => {
  const color = useSelector(colorSelector(ticker));
  const price = useSelector(priceSelector(ticker));

  return (
    <CellWrapper
      className="market-price-cell is-bold center-for-value"
      textcolor={color}
    >
      <BlinkableCell value={numeral(price).format("0,0.00")} />
    </CellWrapper>
  );
};

export default Cell;
