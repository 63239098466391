import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const marginContractWatchSelector = (state: RootState) =>
  state.marginContractWatch;

export const marginContractWatchListSelector = createSelector(
  marginContractWatchSelector,
  (state) => state.list
);

export const marginContractWatchLoadingSelector = createSelector(
  marginContractWatchSelector,
  (state) => state.loading
);

export const marginContractWatchFilterSelector = createSelector(
  marginContractWatchSelector,
  (state) => state.filter
);

export const activeTabSelector = createSelector(
  marginContractWatchSelector,
  (state) => state.activeTab
);

export const brokerListSelector = createSelector(
  marginContractWatchSelector,
  (state) => state.brokerList
);

export const loadingBrokerListSelector = createSelector(
  marginContractWatchSelector,
  (state) => state.loadingBroker
);
