import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, CheckBox } from "components/commons";

import {
  updateModalStatusRequest,
  updateModalOtpRequest,
  // createAdvanceContractRequest,
} from "modules/auth/pages/ModalSetting/MenuItem/AdvanceAuto/redux";
import {
  isShowConfirmModalSelector,
  getAdvanceAutoSelector,
} from "modules/auth/pages/ModalSetting/MenuItem/AdvanceAuto/selectors";
import { accountInfoSelector } from "modules/auth/selectors";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  CheckBoxText,
  Devided,
  HeaderModal,
  Icon,
  LabelCell,
  Link,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  LabelConfirm,
} from "./styles";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { useState } from "react";
import { GetParameters } from "helper/sessionData";

function ConfirmModal() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const themeType = useSelector(themeTypeSelector);
  const [checked, setChecked] = useState(false);
  const isVisible = useSelector(isShowConfirmModalSelector);
  const advanceAutoStatus = useSelector(getAdvanceAutoSelector);
  const { currentUser } = useSelector(accountInfoSelector);
  const company = GetParameters("SYST", "COMPANY_DEFAULT");
  const link = GetParameters("SYST", "TERM_CONDITION_ADVANCE_URL");

  const closeModalHandler = () => {
    dispatch(updateModalStatusRequest({ isVisible: false }));
    setChecked(false);
  };

  const clickAgreeButtonHandler = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
    closeModalHandler();
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            {advanceAutoStatus === 0 ? (
              <FormattedMessage id="auth.setting.advanceAuto.modal.titleRegister" />
            ) : advanceAutoStatus === 1 ? (
              <FormattedMessage id="auth.setting.advanceAuto.modal.titleCancelUnRegistration" />
            ) : (
              ""
            )}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="auth.setting.advanceAuto.modal.custNo" />
            </LabelCell>
            <TextCell>
              {currentUser && currentUser.userId ? currentUser.userId : ""}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="auth.setting.advanceAuto.modal.custName" />
            </LabelCell>
            <TextCell>
              {currentUser && currentUser.displayName
                ? currentUser.displayName
                : ""}
            </TextCell>
          </BodyModalRow>
          <Devided />
          <BodyModalRow>
            <LabelConfirm>
              {advanceAutoStatus === 0 ? (
                <FormattedMessage id="auth.setting.advanceAuto.modal.textConfirmRegister" />
              ) : advanceAutoStatus === 1 ? (
                <FormattedMessage id="auth.setting.advanceAuto.modal.textConfirmUnRegister" />
              ) : (
                ""
              )}
            </LabelConfirm>
          </BodyModalRow>
          <BodyModalRow>
            <CheckBox onChange={() => setChecked(!checked)} checked={checked} />
            <CheckBoxText>
              <FormattedMessage
                id="auth.setting.advanceAuto.modal.textCheckBox"
                values={{
                  link: (
                    <Link target="_blank" href={link}>
                      <FormattedMessage id="auth.setting.advanceAuto.modal.textCheckBoxMiddle" />
                    </Link>
                  ),
                  company: company ? company : "",
                }}
              />
            </CheckBoxText>
          </BodyModalRow>
          <ActionsRow>
            <ButtonCell>
              {advanceAutoStatus === 0 ? (
                <Button
                  className="button-filter m-1 w-100"
                  onClick={clickAgreeButtonHandler}
                  fill={true}
                  disabled={!checked}
                  style={{
                    background: `${themes[themeType].buttonGradientColor}`,
                    fontSize: `${themes[themeType].typeFaces.headline.size}`,
                    padding: "20px 16px",
                    gap: "8px",
                  }}
                >
                  {intl.formatMessage({
                    id: "auth.setting.advanceAuto.modal.register",
                  })}
                </Button>
              ) : advanceAutoStatus === 1 ? (
                <Button
                  className="button-filter m-1 w-100"
                  onClick={clickAgreeButtonHandler}
                  fill={true}
                  disabled={!checked}
                  style={{
                    border: `1px solid ${themes[themeType].red50}`,
                    color: `${themes[themeType].red50}`,
                    background: `${themes[themeType].background2}`,
                    fontSize: `${themes[themeType].typeFaces.headline.size}`,
                    padding: "20px 16px",
                    gap: "8px",
                  }}
                >
                  {intl.formatMessage({
                    id: "auth.setting.advanceAuto.modal.cancelRegistration",
                  })}
                </Button>
              ) : (
                <></>
              )}
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default ConfirmModal;
