import queryString from "query-string";
import { ISearchTransferBondParams } from "./types";

export const searchTransferBondApi = (params: ISearchTransferBondParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findCbotBondTransferRequestContra${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const validateTimeToTransferBondApi = () => {
  return `/validateTimeToTransferBond`;
};

export const cancelTransferBondApi = "/updateBondTransferRequest";
