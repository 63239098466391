import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type PlaceOrderState = {
  isShowPlaceOrder: boolean;
  tickerNameSelected: string;
  tickerPriceSelected: number | undefined;
  placeOrderType: "basic" | "derivative";
  activeStep: "tickerInfo" | "placeOrder";
};

const initialState: PlaceOrderState = {
  isShowPlaceOrder: false,
  tickerNameSelected: "",
  tickerPriceSelected: undefined,
  placeOrderType: "basic",
  activeStep: "tickerInfo",
};

const placeOrderSlice = createSlice({
  name: "categoriesPlaceOrderSlice",
  initialState,
  reducers: {
    changeDisplayPlaceOrder: (
      state,
      action: PayloadAction<{
        tickerNameSelected: string;
        tickerPriceSelected?: number | undefined;
        placeOrderType: "basic";
      }>
    ) => {
      state.isShowPlaceOrder = true;
      state.tickerNameSelected = action.payload.tickerNameSelected;
      state.tickerPriceSelected = action.payload.tickerPriceSelected;
      state.placeOrderType = action.payload.placeOrderType;
    },
    closePlaceOrder: (state) => {
      state.isShowPlaceOrder = false;
      state.tickerNameSelected = "";
      state.tickerPriceSelected = undefined;
    },
    changeSelectedTicker: (
      state,
      action: PayloadAction<{
        tickerNameSelected: string;
      }>
    ) => {
      state.tickerNameSelected = action.payload.tickerNameSelected;
    },
    changeActiveStep: (
      state,
      action: PayloadAction<"tickerInfo" | "placeOrder">
    ) => {
      state.activeStep = action.payload;
    },
  },
});

export const {
  changeDisplayPlaceOrder,
  closePlaceOrder,
  changeSelectedTicker,
  changeActiveStep,
} = placeOrderSlice.actions;

export default placeOrderSlice.reducer;
