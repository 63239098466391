import React from "react";
import { Button } from "components/commons";
import { FormattedMessage } from "react-intl";
import { Container } from "./styles";
import ConfirmCancelOrderModal from "../ConfirmCancelOrderModal";
interface Props {
  handelCancalOrder: () => void;
  setVisibaleModalConfirm: (value: boolean) => void;
  visibleModalConfirm: boolean;
}
const TableAction: React.FC<Props> = ({
  handelCancalOrder,
  setVisibaleModalConfirm,
  visibleModalConfirm,
}) => {
  return (
    <Container>
      <Button color="danger" style={{ flex: 1 }} onClick={handelCancalOrder}>
        <FormattedMessage id="orderBook.cancel" />
      </Button>
      {visibleModalConfirm && (
        <ConfirmCancelOrderModal
          showModal={visibleModalConfirm}
          setShowModal={setVisibaleModalConfirm}
        />
      )}
    </Container>
  );
};

export default TableAction;
