import { FormattedMessage, useIntl } from "react-intl";
import {
  Wrap,
  Container,
  WrapInput,
  WrapLeft,
  WrapRight,
  WrapCheckBoxs,
  WrapCheckBox,
  TextNote,
  TextLink,
  WrapTextAccep,
  TextAccepLink,
} from "./style";
import { Button } from "components/commons";
import { ReactComponent as SelectedIcon } from "assets/image/svg/checkBank.svg";
import { ReactComponent as UnSelectedIcon } from "assets/image/svg/unCheckBank.svg";
import { ReactComponent as UnSquareSelectedIcon } from "assets/image/svg/squareUnCheckBox.svg";
import { ReactComponent as SquareSelectedIcon } from "assets/image/svg/ic_checkbox_selected.svg";

// import { ReactComponent as SquareSelectedIcon } from "assets/image/svg/checkBank.svg";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import {
  updateStep,
  updateFormSubmit,
  updateParams,
} from "modules/auth/redux/modalRegister";
import { useEffect, useState } from "react";
import { getAllServiceAndDetailApi } from "core/http/apis/modal-register";
import { toast } from "components/commons/Toast";
import {
  selectFormSubmit,
  selectParams,
  selectDataCreateUser,
} from "modules/auth/selectorsModalRegister";

const Step8 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [listTypeSms, setListTypeSms] = useState([]);
  const formsubmit = useSelector(selectFormSubmit);
  const paramsForm = useSelector(selectParams);
  const dataCreate = useSelector(selectDataCreateUser);

  const hideOptiopCenter = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "HIDE_OPTION_CONTACT_CENTER"
      )
    : {};
  const hideOptiopTrading = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "HIDE_OPTION_ONLINE_TRADING"
      )
    : {};

  const link1 =
    "https://www.psi.vn/vi/san-pham/khach-hang-ca-nhan/dich-vu-chung-khoan/ung-truoc-tien-ban";
  const link2 =
    "https://www.psi.vn/uploads/bieu-mau/vi/MTK_Online/Dieu_khoan_dieu_kien_MTK_17032022.pdf";

  const calllisdataSms = async () => {
    const res: any = await getAllServiceAndDetailApi();
    if (res?.statusCode === 0) {
      setListTypeSms(res?.data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    calllisdataSms();
  }, []);

  const changeCheckbox = (value: any, key: string) => {
    dispatch(updateFormSubmit({ value, key }));
  };

  const renderCheckbox = (props: any) => {
    const { value, key, text1, text2 } = props;
    return (
      <WrapRight>
        <WrapCheckBoxs>
          <WrapCheckBox onClick={() => changeCheckbox(true, key)}>
            {value ? (
              <SelectedIcon className="h-100 mr-2" />
            ) : (
              <UnSelectedIcon className="h-100 mr-2" />
            )}
            <FormattedMessage id={text1} />
          </WrapCheckBox>
          <WrapCheckBox onClick={() => changeCheckbox(false, key)}>
            {!value ? (
              <SelectedIcon className="h-100 mr-2" />
            ) : (
              <UnSelectedIcon className="h-100 mr-2" />
            )}
            <FormattedMessage id={text2} />
          </WrapCheckBox>
        </WrapCheckBoxs>
      </WrapRight>
    );
  };

  const submbitNext = () => {
    const smsService =
      listTypeSms &&
      listTypeSms
        .filter((e: any) => e?.serviceType === 1)
        .map((e: any) => ({
          key: e?.serviceGroupId,
          value: formsubmit?.sms ? 1 : 2,
        }));
    const emailService =
      listTypeSms &&
      listTypeSms
        .filter((e: any) => e?.serviceType === 2)
        .map((e: any) => ({
          key: e?.serviceGroupId,
          value: formsubmit?.email ? 1 : 2,
        }));

    const params = {
      //chechbox 1
      activeContactCenter: formsubmit?.activeContactCenter,
      contactCenterMobile1: paramsForm?.mobileNo,
      contactCenterMobile2: null,
      contactCenterMobile3: null,
      contactCenterMobile4: null,
      contactCenterMobile5: null,

      contactCenterKO00: hideOptiopCenter?.value.includes("ko00")
        ? false
        : formsubmit?.activeContactCenter,
      contactCenterOD00: hideOptiopCenter?.value.includes("0d00")
        ? false
        : formsubmit?.activeContactCenter,
      contactCenterPD00: hideOptiopCenter?.value.includes("pd00")
        ? false
        : formsubmit?.activeContactCenter,
      contactCenterRI00: hideOptiopCenter?.value.includes("ri00")
        ? false
        : formsubmit?.activeContactCenter,
      contactCenterTR00: hideOptiopCenter?.value.includes("tr00")
        ? false
        : formsubmit?.activeContactCenter,
      contactCenterCB00: hideOptiopCenter?.value.includes("cb00")
        ? false
        : formsubmit?.activeContactCenter,
      //checkbox 2
      active: formsubmit?.active,
      od00: hideOptiopTrading?.value.includes("od00")
        ? false
        : formsubmit?.active,
      pd00: hideOptiopTrading?.value.includes("pd00")
        ? false
        : formsubmit?.active,
      tr00: hideOptiopTrading?.value.includes("tr00")
        ? false
        : formsubmit?.active,
      ri00: hideOptiopTrading?.value.includes("ri00")
        ? false
        : formsubmit?.active,
      ko00: hideOptiopTrading?.value.includes("ko00")
        ? false
        : formsubmit?.active,
      mg00: hideOptiopTrading?.value.includes("mg00")
        ? false
        : formsubmit?.active,
      cb00: hideOptiopTrading?.value.includes("mg00")
        ? false
        : formsubmit?.active,
      forgotPassword: hideOptiopTrading?.value.includes("FORGOT_PASSWORD")
        ? false
        : formsubmit?.active,

      //checkbox 3
      userAuthen: formsubmit?.userAuthen ? 3 : 6,
      //checkbox 4
      smsMobile: paramsForm?.mobileNo,
      smsServices: JSON.stringify(smsService),
      //checkbox 5
      email: paramsForm?.emailAdrs,
      emailServices: JSON.stringify(emailService),
      //checkbox 6
      payAdvanceAuto: formsubmit?.payAdvanceAuto,
    };
    dispatch(updateParams(params));
    dispatch(updateStep(STEPS.STEPS_10));
  };

  return (
    <Container>
      <Wrap>
        <div>
          <WrapInput>
            <WrapLeft>
              <FormattedMessage id={"auth.register.step8.question1.label"} />
            </WrapLeft>
            {renderCheckbox({
              value: formsubmit?.activeContactCenter,
              key: "activeContactCenter",
              text1: "auth.register.step8.question1.value1",
              text2: "auth.register.step8.question1.value2",
            })}
          </WrapInput>

          <WrapInput>
            <WrapLeft>
              <FormattedMessage id={"auth.register.step8.question2.label"} />
            </WrapLeft>
            {renderCheckbox({
              value: formsubmit?.active,
              key: "active",
              text1: "auth.register.step8.question2.value1",
              text2: "auth.register.step8.question2.value2",
            })}
          </WrapInput>

          <WrapInput>
            <WrapLeft>
              <FormattedMessage id={"auth.register.step8.question3.label"} />
            </WrapLeft>
            {renderCheckbox({
              value: formsubmit?.userAuthen,
              key: "userAuthen",
              text1: "auth.register.step8.question3.value1",
              text2: "auth.register.step8.question3.value2",
            })}
          </WrapInput>

          <WrapInput>
            <WrapLeft>
              <FormattedMessage id={"auth.register.step8.question4.label"} />
            </WrapLeft>
            {renderCheckbox({
              value: formsubmit?.sms,
              key: "sms",
              text1: "auth.register.step8.question4.value1",
              text2: "auth.register.step8.question4.value2",
            })}
          </WrapInput>

          <WrapInput>
            <WrapLeft>
              <FormattedMessage id={"auth.register.step8.question5.label"} />
            </WrapLeft>
            {renderCheckbox({
              value: formsubmit?.email,
              key: "email",
              text1: "auth.register.step8.question5.value1",
              text2: "auth.register.step8.question5.value2",
            })}
          </WrapInput>

          <WrapInput>
            <WrapLeft>
              <FormattedMessage id={"auth.register.step8.question6.label"} />
            </WrapLeft>
            {renderCheckbox({
              value: formsubmit?.payAdvanceAuto,
              key: "payAdvanceAuto",
              text1: "auth.register.step8.question6.value1",
              text2: "auth.register.step8.question6.value2",
            })}
          </WrapInput>
          <TextNote>
            {`(${intl.formatMessage({
              id: "auth.register.step8.note",
            })}`}
            <TextLink onClick={() => window.open(link1)}>
              <FormattedMessage id={"auth.register.step8.noteLinhk"} />
            </TextLink>
            {")"}
          </TextNote>
        </div>
        <WrapTextAccep>
          {formsubmit?.agreeStep8 ? (
            <SquareSelectedIcon
              onClick={() => changeCheckbox(false, "agreeStep8")}
            />
          ) : (
            <UnSquareSelectedIcon
              onClick={() => changeCheckbox(true, "agreeStep8")}
            />
          )}
          {/* <UnSquareSelectedIcon /> */}
          <FormattedMessage id={"auth.register.step8.contentApcet"} />
          <TextAccepLink className="ml-1" onClick={() => window.open(link2)}>
            <FormattedMessage id={"auth.register.step8.contentLink"} />
          </TextAccepLink>
        </WrapTextAccep>
      </Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_8))}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        {formsubmit?.agreeStep8 && (
          <Button
            color="gradientaAccept"
            className="button-filter"
            width="120px"
            onClick={() => submbitNext()}
          >
            {intl.formatMessage({
              id: "auth.register.btnNext",
            })}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Step8;
