import { EntrustBusinessCode, GetAccountType, SrcChannel } from "helper/consts";
import {
  findDertOrderByFilter,
  findDertOrderByFilterSuccess,
  findDertRequestCond,
  findDertRequestCondSuccess,
  updateOrderBookDerRealTime,
} from "modules/stock-order-der/redux/OrderBookDer";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "./Filter";
import OrderBookDerTable from "./OrderBookDerTable";
import { OrderBookWrapper } from "./styles";
import TableAction from "./TableAction";
import { ActionContainer, FilterIcon } from "../OrderBook/style";
import { ReactComponent as IconFilter } from "assets/image/svg/filter.svg";
import { ReactComponent as IconRefresh } from "assets/image/svg/ic_refresh.svg";
import { toggleModalLogin } from "modules/auth/redux";
import { toast } from "components/commons/Toast";
import {
  isAuthenticatedSelector,
  tradeDateSelector,
} from "modules/auth/selectors";
import {
  selectedRowsSelector,
  selectedCondRowsSelector,
  loadListSelector,
  orderBookDerListSelector,
  orderBookDerCondListSelector,
  filtersSelector,
  conditionOrderBookDerSelector,
} from "modules/stock-order-der/selectors";
import OrderBookDerCondTable from "./OrderBookDerCondTable";
import {
  IFindDertOrderByFilterParams,
  IFindDertRequestCondParams,
} from "core/http/apis/tradeinfo/types";
import { GetAccoList } from "helper/sessionData";
import { useIntl } from "react-intl";
import { OrderBookDerItem } from "domain/models/OrderBookDer";
import { useEffectOnce } from "react-use";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";

function OrderBookDer() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const listAcc = GetAccoList(
    GetAccountType.DER,
    EntrustBusinessCode.Derivative
  );
  const orderBookDerList = useSelector(orderBookDerListSelector);
  const orderBookDerCondList = useSelector(orderBookDerCondListSelector);
  const {
    currentTicker,
    tradeType,
    orderStatus,
    condType,
    orderStatusCond,
    limit,
    offset,
  } = useSelector(filtersSelector);
  const selectedRows = useSelector(selectedRowsSelector);
  const selectedCondRows = useSelector(selectedCondRowsSelector);
  const loadList = useSelector(loadListSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const condition = useSelector(conditionOrderBookDerSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const [visibleModalConfirm, setVisibaleModalConfirm] = useState(false);

  const [showFilter, setShowFilter] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      onSearch(false);
    } else {
      dispatch(findDertOrderByFilterSuccess({ items: [], offset: 0 }));
      dispatch(findDertRequestCondSuccess({ items: [], offset: 0 }));
    }
  }, [isAuthenticated, loadList, condition?.value]);

  const onSearch = (loadmore: boolean) => {
    if (!listAcc || listAcc.length === 0) {
      dispatch(findDertOrderByFilterSuccess({ items: [], offset: 0 }));
      dispatch(findDertRequestCondSuccess({ items: [], offset: 0 }));
      return;
    }

    if (condition.value === "01") {
      let currentOffset = offset ? offset : 0;
      if (!loadmore) {
        currentOffset = 0;
      }

      const params: IFindDertOrderByFilterParams = {
        size: limit,
        page: currentOffset,
        branchCd: "",
        transactionCd: "",
        tradeType: Number(tradeType.value) === 0 ? 0 : Number(tradeType.value),
        orderType: "",
        secCd: currentTicker === "" ? "" : currentTicker,
        brokerCd: "",
        custNo: "",
        status: orderStatus.value,
        regUserId: "",
        ordChanel: SrcChannel.ALL,
        fromDate: Number(tradeDate),
        toDate: Number(tradeDate),
      };

      dispatch(
        findDertOrderByFilter({
          params: params,
          intl: intl,
          isRefestData: !loadmore,
        })
      );
    } else {
      let currentOffset = offset ? offset : 0;
      if (!loadmore) {
        currentOffset = 0;
      }
      const params: IFindDertRequestCondParams = {
        custNo: "",
        tradeType: Number(tradeType.value ? tradeType.value : "-1"),
        secCd: currentTicker === "" ? "" : currentTicker,
        condType: condType.value,
        regUserId: "",
        status: Number(orderStatusCond.value ? orderStatusCond.value : "-1"),
        ordChanel: "",
        fromDate: Number(tradeDate),
        toDate: Number(tradeDate),
        offset: currentOffset,
        limit: limit,
      };

      dispatch(
        findDertRequestCond({
          params: params,
          intl: intl,
          isRefestData: !loadmore,
        })
      );
    }
  };

  useEffectOnce(() => {
    const eventListenerOrder = (data: OrderBookDerItem) => {
      dispatch(updateOrderBookDerRealTime(data));
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_Order_Der, eventListenerOrder);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_Order_Der, eventListenerOrder);
    };
  });

  const handelCancalOrder = () => {
    if (!isAuthenticated) {
      return dispatch(toggleModalLogin(true));
    } else {
      if (condition.value === "01") {
        if (selectedRows && selectedRows.length > 0) {
          setVisibaleModalConfirm(true);
        } else {
          toast("orderBook.errorCheckCancel", "error");
        }
      } else {
        if (selectedCondRows && selectedCondRows.length > 0) {
          setVisibaleModalConfirm(true);
        } else {
          toast("orderBook.errorCheckCancel", "error");
        }
      }
    }
  };

  return (
    <OrderBookWrapper id="orderBookDer">
      <Filter isShow={showFilter} />
      {condition.value === "01" ? (
        <OrderBookDerTable
          data={orderBookDerList}
          fetchMoreData={() => {
            onSearch(true);
          }}
        />
      ) : (
        <OrderBookDerCondTable
          data={orderBookDerCondList}
          fetchMoreData={() => {
            onSearch(true);
          }}
        />
      )}
      <TableAction
        handelCancalOrder={handelCancalOrder}
        setVisibaleModalConfirm={setVisibaleModalConfirm}
        visibleModalConfirm={visibleModalConfirm}
      />
      <ActionContainer>
        {isAuthenticated && (
          <FilterIcon onClick={() => onSearch(false)}>
            <IconRefresh />
          </FilterIcon>
        )}

        <FilterIcon onClick={() => setShowFilter(!showFilter)}>
          <IconFilter />
        </FilterIcon>
      </ActionContainer>
    </OrderBookWrapper>
  );
}
export default memo(OrderBookDer);
