import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const BtnType = styled.div`
  width: 310px;
  height: 48px;
  background-color: ${({ theme }) => theme.brandSecondary};
  margin: auto;
  color: ${({ theme }) => theme.grey0};
  text-align: center;
  line-height: 48px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.brandPrimary};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const DetailRow = styled.div`
  width: 100%;
`;

export const DetailLabel = styled.div`
  min-width: 120px;
  color: ${({ theme }) => theme.grey0};
  display: flex;
  justify-content: space-between;
`;

export const WrapLabel = styled.div`
  padding-bottom: 8px;
  color: ${({ theme }) => theme.grey0};
`;

export const DetailInput = styled.div`
  padding: 12px 0px;
  width: 100%;
  display: flex;
  gap: 24px;
  color: ${({ theme }) => theme.grey0};
`;

export const WrapInput = styled.div`
  display: flex;
  width: 100%;
  gap: 11px;
`;

export const WrapCheckBox = styled.div`
  width: 45%;
  display: flex;
  gap: 7px;
  line-height: ${({ theme }) => theme.input.height};
  cursor: pointer;
`;

export const WrapInputCheckBox = styled.div`
  width: 55%;
`;

export const TextAddForm = styled.div`
  color: ${({ theme }) => theme.solidBlue};
  cursor: pointer;
`;

export const WrapForm = styled.div`
  overflow: scroll;
`;
