import Price from "./Price";
// import ProgressPrice from "./ProgressPrice";
import { PriceContainer, RowContainer, ProgressPrice } from "./styles";
import Volume from "./Volume";

interface RowPriceProps {
  percent: number;
  isSell: boolean;
  volume: number;
  price: number | string;
  color: string;
  handleClickPrice: (price: number | string) => void;
}

function RowPrice({
  percent,
  volume,
  isSell,
  price,
  color,
  handleClickPrice,
}: RowPriceProps) {
  return (
    <RowContainer isReverse={isSell}>
      <Volume volume={volume} />
      <PriceContainer isReverse={isSell}>
        <ProgressPrice percent={percent} isReverse={isSell} />
        <Price
          price={price}
          color={color}
          handleClickPrice={handleClickPrice}
        />
      </PriceContainer>
    </RowContainer>
  );
}

export default RowPrice;
