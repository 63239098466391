import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";
import { cloneDeep } from "lodash";
import { ActiveTabGuideVideo } from "modules/confirmation/pages/constants";
import {
  IArticle,
  IArticleCategory,
  IFindArticleCategorysResponse,
} from "domain/models/GuideVideo";
import {
  IFindArticleCategorysParams,
  IFindArticlesParams,
} from "core/http/apis/guideVideo/types";

export interface ReduxData {
  loading: boolean;
  activeTab: ActiveTabGuideVideo;
  btnActive: string;
  listCategory: IArticleCategory[];
  listArticle: IArticle[];
  isRefestData?: boolean;
  offset: number;
  limit: number;
  enableInfinity: boolean;
}

const initialState: ReduxData = {
  loading: false,
  activeTab: ActiveTabGuideVideo.TRAINING,
  btnActive: "",
  listCategory: [],
  listArticle: [],
  isRefestData: false,
  offset: 0,
  limit: 10,
  enableInfinity: true,
};

const guideVideoSlice = createSlice({
  name: "guideVideoSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },

    findArticlesRequest: (
      state,
      action: PayloadAction<{
        params: IFindArticlesParams;
        isRefestData?: boolean;
      }>
    ) => {
      state.isRefestData = action.payload.isRefestData;
    },

    findArticlesSuccess: (
      state,
      action: PayloadAction<{ items: IArticle[]; offset: number }>
    ) => {
      state.listArticle =
        action.payload.offset === 0
          ? action.payload.items
          : state.listArticle.concat(action.payload.items);
      if (action.payload.items.length < state.limit) {
        state.enableInfinity = false;
        state.offset = 0;
      } else {
        if (state.isRefestData === true) {
          state.enableInfinity = true;
          state.offset = 1;
        } else {
          state.enableInfinity = true;
          state.offset = state.offset + 1;
        }
      }
      state.isRefestData = false;
    },

    findArticlesFailure: (state) => {},

    findArticleCategorysRequest: (
      state,
      action: PayloadAction<IFindArticleCategorysParams>
    ) => {},

    findArticleCategorysSuccess: (
      state,
      action: PayloadAction<IFindArticleCategorysResponse>
    ) => {
      state.listCategory = action.payload.data;
    },

    findArticleCategorysFailure: (state) => {},

    changeActiveTabGuideVideo: (
      state,
      action: PayloadAction<ActiveTabGuideVideo>
    ) => {
      state.activeTab = action.payload;
    },

    changeButtonActiveGuideVideo: (state, action: PayloadAction<string>) => {
      state.btnActive = action.payload;
    },
  },
});

export const {
  findArticlesRequest,
  findArticlesSuccess,
  findArticlesFailure,
  findArticleCategorysRequest,
  findArticleCategorysSuccess,
  findArticleCategorysFailure,
  changeActiveTabGuideVideo,
  changeButtonActiveGuideVideo,
} = guideVideoSlice.actions;

export default guideVideoSlice.reducer;
