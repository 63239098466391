import VgaiaHttp from "core/http/singleton/vgaia";
import queryString from "query-string";

// export const findOrderForConfirmApi = async (params: paramGetData) => {
//   const paramsFormatter = queryString.stringify(params, {
//     skipNull: true,
//   });
//   const res = await VgaiaHttp.post(
//     `/findOrderForConfirm${paramsFormatter ? `?${paramsFormatter}` : ""}`
//   );
//   return res?.data;
// };

export interface IResponse {
  data: any;
  errorCode: string;
  errorField: string;
  message: string;
  statusCode: number;
}

export const findSubAccoRegisterApi = async (params: { custNo: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/findSubAccoRegister${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const getSubAccoListRegisterApi = async (params: { custNo: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/getSubAccoListRegister${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const registerSubAcco = async (params: any): Promise<IResponse> => {
  const res = await VgaiaHttp.post<IResponse>(
    "/registerSubAcco",
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
