import React, {
  FC,
  useEffect,
  useState,
  // useCallback,
  useRef,
} from "react";
import { Modal } from "react-bootstrap";
import { Container, Title, Body, Footer } from "./styles";
import InputPrice from "components/commons/InputPrice";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { onPrice, onVolumn } from "modules/order-book/redux/orderBook";
import {
  Button,
  OTPByBusinessCd,
  // SmartOTP
} from "components/commons";
import { toast } from "components/commons/Toast";
import {
  editSingleOrderStatusClear,
  onLoadingEdit,
  editOrder,
} from "modules/order-book/redux/index";
import { IntlShape, useIntl } from "react-intl";
import { OrderBookDisplay } from "domain/models/OrderBook";
import { EBusinessCd, EMarketCodeNew } from "helper/consts";
// import { checkEditOrder } from "helper/tradingHelper";
import InputVolume from "components/commons/InputVolume";
import {
  onChangePriceOrderBookEditByEnter,
  onChangeVolumeOrderBookEditByEnter,
} from "modules/order-book/redux/orderBook";
import { parseInt } from "lodash";
import {
  orderBookSelector,
  orderBookRootSelector,
} from "modules/order-book/selectors";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { tickerListSelector } from "modules/categories/selectors";
import { IEditOrderParams } from "core/http/apis/orderexec/types";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<boolean>;
  dataRow?: OrderBookDisplay;
}

const regPrice = /^\d+(\.\d{0,9})?$/;
const regVolumn = /^\d+$/;

const getTradeTypeIntl = (intl: IntlShape) => (a: string) => {
  const type = a === "buy" ? "BUY" : "SELL";
  return type
    ? intl.formatMessage({ id: `widgets.orderBookEdit.${type}` })
    : "-";
};

/**
 * Modal
 */
const ModalEditCommand: FC<Props> = ({ showModal, setShowModal, dataRow }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getTradeType = getTradeTypeIntl(intl);
  const { loadingEdit, singleOrderStatus } = useSelector(orderBookRootSelector);
  const { price, volumn, changePriceByEnter } = useSelector(orderBookSelector);
  const tickerList = useSelector(tickerListSelector);
  const [errorPrice, setErrorPrice] = useState("");
  const [errorVolumn, setErrorVolumn] = useState("");
  const refInputPrice = useRef<HTMLInputElement>(null);
  const refInputVolume = useRef<HTMLInputElement>(null);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    if (dataRow) {
      const tickerInfo = tickerList.find(
        (item) => item.seccd === dataRow.secCd
      );
      if (tickerInfo && tickerInfo.marketcd === EMarketCodeNew.HSX_IDX) {
        setDisableInput(true);
      } else {
        setDisableInput(false);
      }
    }
  }, [dataRow]);

  const validatePrice = () => {
    setErrorPrice("");
    if (!price) {
      setErrorPrice(intl.formatMessage({ id: "validation.emptyPrice" }));
      return false;
    }
    if (typeof price !== "string" && !regPrice.test(price?.toString())) {
      setErrorPrice(intl.formatMessage({ id: "validation.patternPrice" }));
      return false;
    }
    return true;
  };

  const validateVolumn = async () => {
    setErrorVolumn("");

    if (!volumn) {
      setErrorVolumn(intl.formatMessage({ id: "validation.emptyVolume" }));
      return false;
    }

    return true;
  };

  // Validate
  const validate = () => {
    if (!validatePrice() || !validateVolumn()) {
      return false;
    } else {
      setErrorPrice("");
      setErrorVolumn("");
      return true;
    }
  };

  // Submit nút đặt lệnh
  const onSubmit = () => {
    if (disableButton) {
      toast("tradingHelper.dataDoesNotChange", "error");
      return;
    }
    dispatch(onLoadingEdit(true));
    if (validate()) {
      setVisibleModalOTP(true);
    } else {
      dispatch(onLoadingEdit(false));
    }
  };

  // Submit OK
  const doSubmitUpdateOrder = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    if (!dataRow?.subAccoNo) {
      return;
    }

    const resultPrice: number = Number(price * 1000);
    const equityPriceToCheck =
      Math.round((resultPrice + Number.EPSILON) * 100) / 100;

    const params: IEditOrderParams = {
      subAccoNo: dataRow?.subAccoNo,
      orderNo: dataRow?.orderNo,
      ordType: dataRow?.ordType,
      secCd: dataRow?.secCd,
      marketCd: dataRow?.marketCd,
      tradeType: dataRow?.tradeType,
      ordQtty: dataRow?.pubQty,
      ordPrice: dataRow?.pubPrice,
      newQty: parseInt(volumn?.toString().replaceAll(",", "")),
      newPrice:
        numeral(price).value() !== null ? equityPriceToCheck / 1000 : price,
      ordChannel: dataRow?.ordChanel,
      otpCode: response?.otp,
      otpType: response?.otpTypeSubmit!,
    };

    // Validate
    // try {
    //   const error = await checkEditOrder(dataRow, params, intl);
    //   if (error) {
    //     const errorMsg = error;
    //     toast(errorMsg, "error");
    //     dispatch(onLoadingEdit(false));
    //     return;
    //   }
    // } catch (error: any) {
    //   const errorMsg = intl.formatMessage({
    //     id: error.code,
    //     defaultMessage: error.description,
    //   });
    //   toast(errorMsg, "error");
    //   dispatch(onLoadingEdit(false));
    //   return;
    // }

    // Submit
    dispatch(editOrder({ params: params }));
    setShowModal(false);
  };

  useEffect(() => {
    if (singleOrderStatus?.status === "success") {
      // Load list
      dispatch(onLoadingEdit(false));
      dispatch(editSingleOrderStatusClear());
    }

    if (singleOrderStatus?.status === "failed") {
      dispatch(onLoadingEdit(false));
      dispatch(editSingleOrderStatusClear());
    }
  }, [singleOrderStatus]);

  // Đóng modal otp
  const cancelModalOTP = () => {
    setVisibleModalOTP(false);
    dispatch(onLoadingEdit(false));
  };

  // Dispatch giá
  const onChangePrice = (price: any, type?: string) => {
    setErrorPrice("");
    if (numeral(price).value() === null) {
      dispatch(onPrice(""));
    } else {
      dispatch(onPrice(price));
    }
    setDisableButton(false);
  };

  // Dispatch khối lượng
  const onChangeVolumn = (volumn: any, type?: string) => {
    setDisableButton(false);
    if (volumn > 0) {
      dispatch(onVolumn(numeral(volumn).format("0,0")));
    } else {
      dispatch(onVolumn(""));
    }
  };

  useEffect(() => {
    setErrorPrice("");
    setErrorVolumn("");
    setDisableButton(true);
  }, [showModal]);

  useEffect(() => {
    if (showModal && numeral(price).value() !== null) {
      refInputPrice.current?.focus();
    } else {
      refInputVolume.current?.focus();
    }
  }, [showModal]);

  useEffect(() => {
    if (changePriceByEnter) {
      refInputVolume.current?.focus();
      return;
    }
  }, [changePriceByEnter]);

  const onBlurEnter = () => {
    dispatch(onChangePriceOrderBookEditByEnter(false));
    dispatch(onChangeVolumeOrderBookEditByEnter(false));
  };

  const handleKeyDown = (e: any, typeInput: string) => {
    if (e.key === "Enter" && typeInput === "price") {
      dispatch(onChangePriceOrderBookEditByEnter(true));
    }
    if (e.key === "Enter" && typeInput === "volume") {
      dispatch(onChangeVolumeOrderBookEditByEnter(true));
      if (!disableButton && !loadingEdit) {
        onSubmit();
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="modal-461"
      >
        <Container>
          <Title className="d-flex justify-content-center text-uppercase">
            {getTradeType(dataRow!?.tradeType === 2 ? "buy" : "sell")}
          </Title>
          <Body>
            <InputPrice
              innerRef={refInputPrice}
              suggestions={[]}
              value={price}
              onChange={onChangePrice}
              label={intl.formatMessage({
                id: "widgets.orderBookEdit.setPrice",
              })}
              placeholder={intl.formatMessage({
                id: "formPlaceholder.inputPrice",
              })}
              onBlurEnter={onBlurEnter}
              partern={regPrice}
              error={errorPrice}
              decimal
              min={0}
              ticker={dataRow?.secCd}
              disabled={dataRow?.isMarketOrder}
              onKeyDown={(e) => handleKeyDown(e, "price")}
            />
            <InputVolume
              innerRef={refInputVolume}
              suggestions={[]}
              value={numeral(volumn).format("0,0")}
              onChange={onChangeVolumn}
              label={intl.formatMessage({
                id: "widgets.orderBookEdit.setVolumn",
              })}
              placeholder={intl.formatMessage({
                id: "formPlaceholder.inputVolumne",
              })}
              onBlurEnter={onBlurEnter}
              partern={regVolumn}
              error={errorVolumn}
              min={1}
              ticker={dataRow?.secCd}
              onKeyDown={(e) => handleKeyDown(e, "volume")}
              disabled={disableInput}
            />
          </Body>
          <Footer className="d-flex justify-content-between">
            <Button
              color="accept"
              className="btn btn-outline-primary py-2"
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({ id: "widgets.orderBookEdit.cancel" })}
            </Button>
            <Button
              fill
              color="accept"
              className="btn btn-primary py-2"
              onClick={() => onSubmit()}
              loading={loadingEdit}
            >
              {intl.formatMessage({ id: "widgets.orderBookEdit.confirm" })}
            </Button>
          </Footer>
        </Container>
      </Modal>
      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={cancelModalOTP}
          getResult={doSubmitUpdateOrder}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </>
  );
};

export default ModalEditCommand;
