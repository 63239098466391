import InputPrice from "components/commons/InputPrice";
import { regPrice, regPriceLimitOffset } from "helper/consts";
import {
  onChangeByTakeProfitEnterDer,
  // onChangeConditionTradeTypeByEnterDer,
  onChangeMarginByEnterDer,
  onChangeStopPriceByEnterDer,
  updateErrorMesage,
  updatePriceUp,
  updatePriceDown,
  updateCutLostAmp,
  updateGrossAmp,
  updateCutLostPrice,
  updateLimitOffset,
  updateRangeStop,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  placeOrderDerSelector,
  dataSelector,
  validateSelector,
} from "modules/stock-order-der/selectors";

export enum ConditionalCommandTypeEnum {
  Normal = "N",
  UP = "UO",
  DOWN = "DO",
  IFDONESTOP = "CL",
  IFDONEGAIN = "SP",
  BULLBEAR = "BB",
  OCO = "OC",
  TRAILINGSTOP = "TS",
}

type Props = {
  conditionalCommandType: ConditionalCommandTypeEnum | undefined;
  onSubmit: () => void;
};

const ConditionalCommandType: React.FC<Props> = memo(
  ({ conditionalCommandType, onSubmit }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const refInputStopPrice = useRef<HTMLInputElement>(null);
    const refInputTakeProfit = useRef<HTMLInputElement>(null);
    const refInputLimitOffset = useRef<HTMLInputElement>(null);

    const {
      changeStopPriceByEnterDer,
      changeTakeProfitByEnterDer,
      changeVolumeByEnterDer,
      // changeConditionTradeTypeByEnterDer,
    } = useSelector(placeOrderDerSelector);

    const {
      priceUp,
      priceDown,
      cutLostAmp,
      grossAmp,
      cutLostPrice,
      limitOffset,
      rangeStop,
      currentTicker,
    } = useSelector(dataSelector);
    const {
      errorActivePrice,
      errorCutLostAmp,
      errorGrossAmp,
      errorCutLostPrice,
      errorRangeStop,
      errorLimitOffset,
    } = useSelector(validateSelector);

    const onBlurEnter = () => {
      dispatch(onChangeStopPriceByEnterDer(false));
      dispatch(onChangeByTakeProfitEnterDer(false));
      dispatch(onChangeMarginByEnterDer(false));
    };

    // useEffect(() => {
    //   if (changeConditionTradeTypeByEnterDer) {
    //     if (conditionalCommandType === ConditionalCommandTypeEnum.BULLBEAR) {
    //       refInputTakeProfit.current?.focus();
    //       dispatch(onChangeConditionTradeTypeByEnterDer(false));
    //     } else {
    //       refInputStopPrice.current?.focus();
    //       dispatch(onChangeConditionTradeTypeByEnterDer(false));
    //     }
    //   }
    // }, [changeConditionTradeTypeByEnterDer]);

    useEffect(() => {
      if (changeVolumeByEnterDer) {
        if (conditionalCommandType === ConditionalCommandTypeEnum.BULLBEAR) {
          refInputTakeProfit.current?.focus();
        } else {
          refInputStopPrice.current?.focus();
        }
      }
    }, [changeVolumeByEnterDer]);

    useEffect(() => {
      if (changeTakeProfitByEnterDer) {
        refInputStopPrice.current?.focus();
      }
    }, [changeTakeProfitByEnterDer]);

    useEffect(() => {
      if (changeStopPriceByEnterDer) {
        refInputLimitOffset.current?.focus();
      }
    }, [changeStopPriceByEnterDer]);

    const handleKeyDown = (e: any, typeInput: string) => {
      if (e.key === "Enter" && typeInput === "stopPrice") {
        dispatch(onChangeStopPriceByEnterDer(true));
        if (
          conditionalCommandType === ConditionalCommandTypeEnum.UP ||
          conditionalCommandType === ConditionalCommandTypeEnum.DOWN
        ) {
          onSubmit();
          refInputStopPrice.current?.blur();
        }
      }
      if (e.key === "Enter" && typeInput === "takeProfit") {
        dispatch(onChangeByTakeProfitEnterDer(true));
      }
      if (e.key === "Enter" && typeInput === "limitOffset") {
        dispatch(onChangeMarginByEnterDer(true));
        onSubmit();
        refInputLimitOffset.current?.blur();
      }
    };

    const render = (conditionalCommandType: any) => {
      switch (conditionalCommandType) {
        case ConditionalCommandTypeEnum.UP:
        case ConditionalCommandTypeEnum.DOWN:
          return (
            <InputPrice
              innerRef={refInputStopPrice}
              value={
                conditionalCommandType === ConditionalCommandTypeEnum.UP
                  ? priceUp
                  : priceDown
              }
              onChange={(price) => {
                dispatch(
                  updateErrorMesage({ key: "errorActivePrice", message: "" })
                );
                if (conditionalCommandType === ConditionalCommandTypeEnum.UP) {
                  dispatch(updatePriceUp(price));
                } else {
                  dispatch(updatePriceDown(price));
                }
              }}
              min={0}
              decimal
              label={intl.formatMessage({
                id: "widgets.placeOrderDer.activePrice",
              })}
              onBlurEnter={onBlurEnter}
              partern={regPrice}
              error={errorActivePrice}
              placeholder={intl.formatMessage({
                id: "formPlaceholder.inputPrice",
              })}
              ticker={currentTicker}
              onKeyDown={(e) => handleKeyDown(e, "stopPrice")}
              containerId="place-order-der-container"
            />
          );
        case ConditionalCommandTypeEnum.IFDONESTOP:
        case ConditionalCommandTypeEnum.IFDONEGAIN:
          return (
            <>
              <InputPrice
                innerRef={refInputStopPrice}
                value={
                  conditionalCommandType ===
                  ConditionalCommandTypeEnum.IFDONESTOP
                    ? cutLostAmp
                    : grossAmp
                }
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorValueAmp", message: "" })
                  );
                  if (
                    conditionalCommandType ===
                    ConditionalCommandTypeEnum.IFDONESTOP
                  ) {
                    dispatch(updateCutLostAmp(price));
                  } else {
                    dispatch(updateGrossAmp(price));
                  }
                }}
                min={0}
                decimal
                label={
                  conditionalCommandType ===
                  ConditionalCommandTypeEnum.IFDONESTOP
                    ? intl.formatMessage({
                        id: "widgets.placeOrderDer.cutLostAmp",
                      })
                    : intl.formatMessage({
                        id: "widgets.placeOrderDer.grossAmp",
                      })
                }
                onBlurEnter={onBlurEnter}
                partern={regPrice}
                error={
                  conditionalCommandType ===
                  ConditionalCommandTypeEnum.IFDONESTOP
                    ? errorCutLostAmp
                    : errorGrossAmp
                }
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputPrice",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "stopPrice")}
                containerId="place-order-der-container"
              />
              <InputPrice
                innerRef={refInputLimitOffset}
                value={limitOffset}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorLimitOffset", message: "" })
                  );
                  dispatch(updateLimitOffset(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.limitOffset",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPriceLimitOffset}
                error={errorLimitOffset}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputValue",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "limitOffset")}
                containerId="place-order-der-container"
              />
            </>
          );
        case ConditionalCommandTypeEnum.BULLBEAR:
          return (
            <>
              <InputPrice
                innerRef={refInputTakeProfit}
                value={grossAmp}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorGrossAmp", message: "" })
                  );
                  dispatch(updateGrossAmp(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.grossAmp",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPrice}
                error={errorGrossAmp}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputPrice",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "takeProfit")}
                containerId="place-order-der-container"
              />
              <InputPrice
                innerRef={refInputStopPrice}
                value={cutLostAmp}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorCutLostAmp", message: "" })
                  );
                  dispatch(updateCutLostAmp(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.cutLostAmp",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPrice}
                error={errorCutLostAmp}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputPrice",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "stopPrice")}
                containerId="place-order-der-container"
              />
              <InputPrice
                innerRef={refInputLimitOffset}
                value={limitOffset}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorLimitOffset", message: "" })
                  );
                  dispatch(updateLimitOffset(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.limitOffset",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPriceLimitOffset}
                error={errorLimitOffset}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputValue",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "limitOffset")}
                containerId="place-order-der-container"
              />
            </>
          );
        case ConditionalCommandTypeEnum.OCO:
          return (
            <>
              <InputPrice
                innerRef={refInputStopPrice}
                value={cutLostPrice}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorCutLostPrice", message: "" })
                  );
                  dispatch(updateCutLostPrice(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.cutLostPrice",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPrice}
                error={errorCutLostPrice}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputPrice",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "stopPrice")}
                containerId="place-order-der-container"
              />
              <InputPrice
                innerRef={refInputLimitOffset}
                value={limitOffset}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorLimitOffset", message: "" })
                  );
                  dispatch(updateLimitOffset(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.limitOffset",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPriceLimitOffset}
                error={errorLimitOffset}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputValue",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "limitOffset")}
                containerId="place-order-der-container"
              />
            </>
          );
        case ConditionalCommandTypeEnum.TRAILINGSTOP:
          return (
            <>
              <InputPrice
                innerRef={refInputStopPrice}
                value={rangeStop}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorRangeStop", message: "" })
                  );
                  dispatch(updateRangeStop(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.rangeStop",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPrice}
                error={errorRangeStop}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputPrice",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "stopPrice")}
                containerId="place-order-der-container"
              />
              <InputPrice
                innerRef={refInputLimitOffset}
                value={limitOffset}
                onChange={(price) => {
                  dispatch(
                    updateErrorMesage({ key: "errorLimitOffset", message: "" })
                  );
                  dispatch(updateLimitOffset(price));
                }}
                min={0}
                decimal
                label={intl.formatMessage({
                  id: "widgets.placeOrderDer.limitOffset",
                })}
                onBlurEnter={onBlurEnter}
                partern={regPriceLimitOffset}
                error={errorLimitOffset}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputValue",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "limitOffset")}
                containerId="place-order-der-container"
              />
            </>
          );
        default:
          break;
      }
    };
    return <>{render(conditionalCommandType)}</>;
  }
);
export default ConditionalCommandType;
