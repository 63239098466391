import { changePassword } from "./urls";
import HttpPassword from "../../singleton/password";
import { ChangePasswordParams } from "./types";
import qs from "qs";

export const changePass = async (
  data: ChangePasswordParams
): Promise<number> => {
  const res = await HttpPassword.post<number>(
    `${changePassword}/${data.masterAccount}`,
    qs.stringify(data)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
