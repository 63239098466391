import queryString from "query-string";
import {
  IGetDBondOrderParams,
  IGetDBondPriceParams,
  IFindCbotDBondInfoDetailParams,
  // IPlaceDBondOrderParams,
  IInquiryBuyingPowerParams,
} from "./types";

export const getDBondOrderApi = (params: IGetDBondOrderParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/getBondOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const getDBondPriceApi = (params: IGetDBondPriceParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/public/getBondPrice${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findCbotDBondInfoDetailApi = (
  params: IFindCbotDBondInfoDetailParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/findCbotBondInfo${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const placeDBondOrderApi = "/placeBondOrder";

export const inquiryBuyingPowerApi = (params: IInquiryBuyingPowerParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/inquiryBuyingPower${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const cancelDBondOrderApi = "/truyenMotApiDeleteNaoDoVaoDay";
