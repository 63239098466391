import { useDispatch, useSelector } from "react-redux";
import { NavItemCard, MenuItemCard, ItemSelect, NavItemMenus } from "./styles";
import { ReactComponent as IconFilter } from "assets/image/svg/filter.svg";
import CheckBox from "./CheckBox";
import {
  DateType,
  setTnTimeSale,
} from "modules/stock-order/redux/volumeAndTime";
import { tnTimeSaleSelector } from "modules/stock-order/redux/selectors";
import { useInterval } from "react-use";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

function DateTypeMenu() {
  const dispatch = useDispatch();
  const tnTimeSale = useSelector(tnTimeSaleSelector);

  useInterval(() => {
    dispatch(setTnTimeSale(tnTimeSale));
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  return (
    <NavItemMenus>
      <NavItemCard>
        <IconFilter />
      </NavItemCard>
      <MenuItemCard className="menu">
        <ItemSelect className="item-select">
          <CheckBox
            active={DateType.T0 === tnTimeSale}
            onClick={() => dispatch(setTnTimeSale(DateType.T0))}
          />
          T
        </ItemSelect>
        <ItemSelect className="item-select">
          <CheckBox
            active={DateType.T1 === tnTimeSale}
            onClick={() => dispatch(setTnTimeSale(DateType.T1))}
          />
          T-1
        </ItemSelect>
        <ItemSelect className="item-select">
          <CheckBox
            active={DateType.T5 === tnTimeSale}
            onClick={() => dispatch(setTnTimeSale(DateType.T5))}
          />
          T-5
        </ItemSelect>
      </MenuItemCard>
    </NavItemMenus>
  );
}

export default DateTypeMenu;
