import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { FormattedMessage } from "react-intl";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";

const lowestpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.lowestprice
);
const highestpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.highestprice
);

const HighestPriceCell = () => {
  const lowestprice = useSelector(lowestpriceSelector);
  const highestprice = useSelector(highestpriceSelector);
  return (
    <div>
      <div className="info-text">
        <FormattedMessage id="widgets.tickerInfo.lowesthighestPrice" />
      </div>
      <div className="info-value">
        <VolumeChange
          value={`${numeral(lowestprice).format("0.00")} - ${numeral(
            highestprice
          ).format("0.00")}`}
        />
      </div>
    </div>
  );
};

export default HighestPriceCell;
