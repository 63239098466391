import styled from "styled-components";

export const WrapBoder = styled.div`
  padding: ${({ theme }) => theme.filterTable.padding};
  .button-filter {
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
  }
`;

export const FilterWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  p {
    margin-bottom: 4px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WrapTab = styled.div`
  height: 40px;
  background: ${({ theme }) => theme.background3};
  padding: 8px 12px;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grey0};

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
`;

export const Tab: any = styled.div<{ isActive?: boolean }>`
  padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  color: ${({ theme }) => theme.textcolorWhite};
  white-space: nowrap;
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    `border-bottom: 2px solid ${theme.solidBlue};
      color: ${theme.solidBlue};`}
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  > DatePicker {
    margin-right: 50px;
  }
`;
export const MarginItem = styled.div`
  padding-top: 18px;
`;
