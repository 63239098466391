import { toast } from "components/commons/Toast";
import {
  getDBondOrder,
  getDBondPrice,
  findCbotDBondInfoDetail,
  placeDBondOrder,
  inquiryBuyingPower,
  cancelDBondOrder,
} from "core/http/apis/bid-ask-d-bond-order";
import {
  IGetDBondOrderParams,
  IGetDBondPriceParams,
  IFindCbotDBondInfoDetailParams,
  IPlaceDBondOrderParams,
  IInquiryBuyingPowerParams,
  ICancelDBondOrderParams,
} from "core/http/apis/bid-ask-d-bond-order/types";
import {
  IGetDBondOrderResponse,
  IGetDBondPriceResponse,
  IFindCbotDBondInfoDetailResponse,
  IPlaceDBondOrderResponse,
  IInquiryBuyingPowerResponse,
  ICancelDBondOrderResponse,
} from "domain/models/BidAskDBondOrder";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getDBondOrderRequest,
  getDBondOrderSuccess,
  getDBondOrderFailure,
  getDBondPriceRequest,
  getDBondPriceSuccess,
  getDBondPriceFailure,
  findCbotDBondInfoDetailRequest,
  findCbotDBondInfoDetailSuccess,
  findCbotDBondInfoDetailFailure,
  placeDBondOrderRequest,
  placeDBondOrderSuccess,
  placeDBondOrderFailure,
  inquiryBuyingPowerRequest,
  inquiryBuyingPowerSuccess,
  inquiryBuyingPowerFailure,
  cancelDBondOrderRequest,
  cancelDBondOrderSuccess,
  cancelDBondOrderFailure,
} from "./redux";

function* getDBondOrderWorker(action: ReturnType<typeof getDBondOrderRequest>) {
  try {
    const params: IGetDBondOrderParams = action.payload;
    const response: IGetDBondOrderResponse = yield call(getDBondOrder, params);

    if (response?.statusCode === 0) {
      yield put(getDBondOrderSuccess(response));
    } else {
      yield put(getDBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getDBondPriceWorker(action: ReturnType<typeof getDBondPriceRequest>) {
  try {
    const params: IGetDBondPriceParams = action.payload;
    const response: IGetDBondPriceResponse = yield call(getDBondPrice, params);

    if (response?.statusCode === 0) {
      yield put(getDBondPriceSuccess(response));
    } else {
      yield put(getDBondPriceFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDBondPriceFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* inquiryBuyingPowerWorker(
  action: ReturnType<typeof inquiryBuyingPowerRequest>
) {
  try {
    const params: IInquiryBuyingPowerParams = action.payload;
    const response: IInquiryBuyingPowerResponse = yield call(
      inquiryBuyingPower,
      params
    );

    if (response?.statusCode === 0) {
      yield put(inquiryBuyingPowerSuccess(response));
    } else {
      yield put(inquiryBuyingPowerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(inquiryBuyingPowerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findCbotDBondInfoDetailWorker(
  action: ReturnType<typeof findCbotDBondInfoDetailRequest>
) {
  try {
    const params: IFindCbotDBondInfoDetailParams = action.payload;
    const response: IFindCbotDBondInfoDetailResponse = yield call(
      findCbotDBondInfoDetail,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotDBondInfoDetailSuccess(response));
    } else {
      yield put(findCbotDBondInfoDetailFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotDBondInfoDetailFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* placeDBondOrderWorker(
  action: ReturnType<typeof placeDBondOrderRequest>
) {
  try {
    const params: IPlaceDBondOrderParams = action.payload;
    const response: IPlaceDBondOrderResponse = yield call(
      placeDBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(placeDBondOrderSuccess(response));
    } else {
      yield put(placeDBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(placeDBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* cancelDBondOrderWorker(
  action: ReturnType<typeof cancelDBondOrderRequest>
) {
  try {
    const params: ICancelDBondOrderParams = action.payload;
    const response: ICancelDBondOrderResponse = yield call(
      cancelDBondOrder,
      params
    );

    if (response?.statusCode === 0) {
      yield put(cancelDBondOrderSuccess(response));
    } else {
      yield put(cancelDBondOrderFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(cancelDBondOrderFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getDBondOrderWatcher() {
  yield takeLatest(getDBondOrderRequest.type, getDBondOrderWorker);
}

function* getDBondPriceWatcher() {
  yield takeLatest(getDBondPriceRequest.type, getDBondPriceWorker);
}

function* findCbotDBondInfoDetailWatcher() {
  yield takeLatest(
    findCbotDBondInfoDetailRequest.type,
    findCbotDBondInfoDetailWorker
  );
}

function* placeDBondOrderWatcher() {
  yield takeLatest(placeDBondOrderRequest.type, placeDBondOrderWorker);
}

function* inquiryBuyingPowerWatcher() {
  yield takeLatest(inquiryBuyingPowerRequest.type, inquiryBuyingPowerWorker);
}

function* cancelDBondOrderWatcher() {
  yield takeLatest(cancelDBondOrderRequest.type, cancelDBondOrderWorker);
}
export default function* advanceLimitSaga() {
  yield all([
    getDBondOrderWatcher(),
    getDBondPriceWatcher(),
    findCbotDBondInfoDetailWatcher(),
    placeDBondOrderWatcher(),
    inquiryBuyingPowerWatcher(),
    cancelDBondOrderWatcher(),
  ]);
}
