/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import SelectTable from "components/commons/SelectTable";
import { Container, DivFooter, WrapTable } from "./styles";
import { useIntl } from "react-intl";
import { dataColumns } from "modules/confirmation/pages/constants";
import { Button } from "components/commons";
import { useEffect, useState } from "react";

interface PROPS {
  data: any[];
  clickSubmit: () => void;
  changeDataSubmit: (data: any[]) => void;
}

const Table = (props: PROPS) => {
  const { clickSubmit, data, changeDataSubmit } = props;
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const intl = useIntl();

  useEffect(() => {
    changeDataSubmit(selectedRows);
  }, [selectedRows]);

  return (
    <WrapTable>
      <Container id="transferLocalTable">
        <SelectTable
          columns={dataColumns}
          data={data}
          setDataRow={(e: any) => console.log("setDataRow", e)}
          setSelectedRows={setSelectedRows}
          setShowModal={(e: boolean) => console.log("setShowModal", e)}
          fetchMoreData={() => console.log("fetchMoreData")}
          enableInfinity={false}
          scrollableTarget="table-infinity-order-book"
        />
      </Container>
      <DivFooter>
        <Button color="gradientaAccept" onClick={() => clickSubmit()}>
          {intl.formatMessage({ id: "confirmationOrder.table.accep" })}
        </Button>
      </DivFooter>
    </WrapTable>
  );
};

export default Table;
