/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo } from "react";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Wrap } from "./styles";
import { Button, Input, Select } from "components/commons";
import { useIntl } from "react-intl";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataSubscribe,
  searchDataSubscribe,
} from "modules/subscribeBuy/redux";
import {
  selectLoadingSubscribe,
  selectSearchSubscribe,
} from "modules/subscribeBuy/selectors";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const Search: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const { subAccoNo, SecCd } = useSelector(selectSearchSubscribe);
  const loading = useSelector(selectLoadingSubscribe);

  const getData = () => {
    const params = {
      subAccoNo: subAccoNo ? subAccoNo : accountOptions[0].value,
      SecCd: SecCd ?? "",
    };

    dispatch(getDataSubscribe(params));

    const resLogData = {
      eventGroup: GroupEvent.exerciseRegisterRights,
      event: RealtimeConst.getDataRightInfo,
      message: JSON.stringify(params),
    };

    storage.commonPushLogFirebase(resLogData);
  };

  const changeSubAcc = (e: any) => {
    dispatch(searchDataSubscribe({ value: e?.value, key: "subAccoNo" }));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "subscribe.content.subscribe.search.Subacc",
          })}
          className="mr-4"
        >
          <Select
            defaultValue={accountOptions[0]}
            options={accountOptions}
            onChange={(e: any) => {
              changeSubAcc(e);
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "subscribe.content.subscribe.search.codeStock",
          })}
          className="mr-4"
        >
          <Input
            placeholder={intl.formatMessage({
              id: "subscribe.content.subscribe.search.placeholderCodeStock",
            })}
            value={SecCd ?? ""}
            onChange={(e) =>
              dispatch(
                searchDataSubscribe({
                  value: (e?.target?.value).toLocaleUpperCase(),
                  key: "SecCd",
                })
              )
            }
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter"
          style={{ marginLeft: "0px" }}
          loading={loading}
          onClick={getData}
        >
          {intl.formatMessage({
            id: "transferInternal.historyTransfer.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
};

export default Search;
