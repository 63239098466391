/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select } from "components/commons";
import { OptionSelectType } from "components/commons/Select";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";

import numeral from "numeral";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
// import ConfirmModal from "./ConfirmModal";
import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailWrapper,
  DetailRowTextTop,
  DetailRowPaddingTB,
  DetailColFlexEnd,
  DetailRowPaddingSelect,
  WrapContnetModal,
  ItemModal,
  ItemModalLabal,
  ItemModalValue,
} from "./styles";
import {
  itemFormSelector,
  listInterestRateItemSelector,
  statusGetDueDateSelector,
  statusUpdateDepositContractSelector,
  filterDepositContractSelector,
} from "modules/deposit-contract/selectors";
import { tradeDateSelector } from "modules/auth/selectors";
import {
  updateItemForm,
  getDepositInquiryRequest,
  getDueDateDepositContractRequest,
  getInterestAmountRequest,
  updateDepositContractRequest,
  searchDepositContractRequest,
} from "modules/deposit-contract/redux";
import {
  IGetDepositInquiryParams,
  IGetDueDateDepositContractParams,
  IGetInterestAmountParams,
  IUpdateDepositContractParams,
  ISearchDepositContractParams,
} from "core/http/apis/deposit-contract/types";
import moment from "moment";

import { toast } from "components/commons/Toast";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { updateModalOtpRequest } from "modules/advance/redux";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { Row, Col } from "react-bootstrap";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";
import TextArea from "components/commons/Input/textArea";
const periodTypeOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "depositContract.create.periodTypeOption.date",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "depositContract.create.periodTypeOption.month",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "depositContract.create.periodTypeOption.year",
    }),
  },
];
const turnOverTypeOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "depositContract.create.turnOverTypeOption.manual",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "depositContract.create.turnOverTypeOption.auto",
    }),
  },
];

function RequestDetail() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BANK_SUB_ACCOUNT,
    EntrustBusinessCode.All
  );

  const itemForm = useSelector(itemFormSelector);

  const listInterestRateItem = useSelector(listInterestRateItemSelector);
  // const interestAmount = useSelector(getInterestAmountInfoSelector);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const statusGetDueDate = useSelector(statusGetDueDateSelector);
  const statusUpdateDepositContract = useSelector(
    statusUpdateDepositContractSelector
  );
  const tradeDate = useSelector(tradeDateSelector);
  const isVisible = useSelector(isShowOtpModalSelector);
  const { fromDate, toDate, contractSts, subAccount } = useSelector(
    filterDepositContractSelector
  );

  const [filterInterestRate, setFilterInterestRate] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc]);
  const periodTypeOptions = useMemo(() => periodTypeOption(intl), [intl]);
  const turnOverTypeOptions = useMemo(() => turnOverTypeOption(intl), [intl]);
  const changeSubAccountHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemForm({ subAccount: item && item.value ? item.value : "" })
    );
  };

  const changePeriodTypeHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemForm({
        periodType: item && item.value ? +item.value : 1,
        dueDate: "",
        period: 0,
        loanEstimate: 0,
        grossRate: 0,
        netRate: 0,
        calculate: 0,
      })
    );

    if (item && item.value) {
      setFilterInterestRate(
        listInterestRateItem
          .filter((i) => {
            return (
              i.periodType === (item && item.value ? +item.value : 1) &&
              i.fromValue <=
                (itemForm && itemForm.amount ? +itemForm.amount : 0) &&
              i.toValue >= (itemForm && itemForm.amount ? +itemForm.amount : 0)
            );
          })
          .map((interestRateItem: any) => ({
            label: interestRateItem?.period.toString(),
            value: interestRateItem?.period.toString(),
          }))
      );
    }
  };

  const changePeriodHandler = (item: OptionSelectType | null) => {
    dispatch(updateItemForm({ period: item && item.value ? +item.value : 1 }));
    if (item && item.value && itemForm && itemForm.periodType) {
      setFilterInterestRate(
        listInterestRateItem
          .filter((i) => {
            return (
              i.period === (item && item.value ? +item.value : 1) &&
              i.periodType ===
                (itemForm.periodType ? itemForm.periodType : 1) &&
              i.fromValue <=
                (itemForm && itemForm.amount ? +itemForm.amount : 0) &&
              i.toValue >= (itemForm && itemForm.amount ? +itemForm.amount : 0)
            );
          })
          .map((interestRateItem: any) => ({
            label: interestRateItem?.period.toString(),
            value: interestRateItem?.period.toString(),
          }))
      );
    }
    const submitData: IGetDueDateDepositContractParams = {
      // fromDate: parseInt(moment(new Date()).format("YYYYMMDD")),
      fromDate: tradeDate && parseInt(tradeDate) ? parseInt(tradeDate) : 0,
      period: item && item.value ? +item.value : 1,
      periodType: itemForm.periodType ? itemForm.periodType : 1,
    };
    dispatch(getDueDateDepositContractRequest(submitData));
  };
  const changeAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;
    dispatch(
      updateItemForm({ amount: convertToNumber ? +convertToNumber : "" })
    );

    if (
      convertToNumber &&
      itemForm &&
      itemForm.periodType &&
      !itemForm.period
    ) {
      setFilterInterestRate(
        listInterestRateItem
          .filter((i) => {
            return (
              i.fromValue <= (+convertToNumber ?? 0) &&
              i.toValue >= (+convertToNumber ?? 0) &&
              i.periodType === (itemForm.periodType ? itemForm.periodType : 1)
            );
          })
          .map((interestRateItem: any) => ({
            label: interestRateItem?.period.toString(),
            value: interestRateItem?.period.toString(),
          }))
      );
      if (
        listInterestRateItem.filter((i) => {
          return (
            i.fromValue <= (+convertToNumber ?? 0) &&
            i.toValue >= (+convertToNumber ?? 0) &&
            i.periodType === (itemForm.periodType ? itemForm.periodType : 1)
          );
        }).length === 0
      ) {
        dispatch(
          updateItemForm({
            period: 0,
            dueDate: "",
            loanEstimate: 0,
            grossRate: 0,
            netRate: 0,
            calculate: 0,
          })
        );
      }
    } else if (
      convertToNumber &&
      itemForm &&
      itemForm.periodType &&
      itemForm.period
    ) {
      setFilterInterestRate(
        listInterestRateItem
          .filter((i) => {
            return (
              i.fromValue <= (+convertToNumber ?? 0) &&
              i.toValue >= (+convertToNumber ?? 0) &&
              i.periodType === itemForm.periodType &&
              i.period === itemForm.period
            );
          })
          .map((interestRateItem: any) => ({
            label: interestRateItem?.period.toString(),
            value: interestRateItem?.period.toString(),
          }))
      );
      if (
        listInterestRateItem.filter((i) => {
          return (
            i.fromValue <= (+convertToNumber ?? 0) &&
            i.toValue >= (+convertToNumber ?? 0) &&
            i.periodType === itemForm.periodType &&
            i.period === itemForm.period
          );
        }).length === 0
      ) {
        dispatch(
          updateItemForm({
            period: 0,
            dueDate: "",
            loanEstimate: 0,
            grossRate: 0,
            netRate: 0,
            calculate: 0,
          })
        );
      }
    }
  };
  const blurAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;
    let fee = convertToNumber ? +convertToNumber : 0;

    if (fee > 0) {
      const data =
        listInterestRateItem &&
        listInterestRateItem.find((i) => {
          return (
            i.period === (itemForm.period ? itemForm.period : 1) &&
            i.periodType === (itemForm.periodType ? itemForm.periodType : 1) &&
            i.fromValue <= (fee ?? 0) &&
            i.toValue >= (fee ?? 0)
          );
        });

      if (
        itemForm &&
        itemForm.grossRate &&
        itemForm.netRate &&
        itemForm.grossRate > 0
      ) {
        const dueDate = itemForm && itemForm.dueDate ? itemForm.dueDate : "";

        const submitData2: IGetInterestAmountParams = {
          amount: fee ?? 0,
          inRate: itemForm && itemForm.grossRate ? itemForm.grossRate : 0,
          outRate: itemForm && itemForm.grossRate ? itemForm.grossRate : 0,
          fromDate:
            tradeDate && parseInt(moment(tradeDate).format("YYYYMMDD"))
              ? parseInt(moment(tradeDate).format("YYYYMMDD"))
              : 0,
          toDate: parseInt(dueDate) ?? 0,
          expDate: parseInt(dueDate) ?? 0,
          calculate: itemForm && itemForm.calculate ? itemForm.calculate : 0,
          decimalPoint: data && data.decimalPoint ? data.decimalPoint : 0,
          roundType: data && data.roundType ? data.roundType : 0,

          feeDays: 0,
        };

        dispatch(getInterestAmountRequest(submitData2));
      }
    } else {
      dispatch(updateItemForm({ loanEstimate: 0 }));
    }
  };

  const keyUpAmountHandler = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.stopPropagation();
      let convertToNumber =
        e?.target?.value
          .replaceAll(",", "")
          .replaceAll(".", "")
          .replace(/[^0-9.]/g, "") ?? 0;
      let fee = convertToNumber ? +convertToNumber : 0;

      if (fee > 0) {
        const data =
          listInterestRateItem &&
          listInterestRateItem.find((i) => {
            return (
              i.period === (itemForm.period ? itemForm.period : 1) &&
              i.periodType ===
                (itemForm.periodType ? itemForm.periodType : 1) &&
              i.fromValue <= (fee ?? 0) &&
              i.toValue >= (fee ?? 0)
            );
          });

        if (
          itemForm &&
          itemForm.grossRate &&
          itemForm.netRate &&
          itemForm.grossRate > 0
        ) {
          const dueDate = itemForm && itemForm.dueDate ? itemForm.dueDate : "";

          const submitData2: IGetInterestAmountParams = {
            amount: fee ?? 0,
            inRate: itemForm && itemForm.grossRate ? itemForm.grossRate : 0,
            outRate: itemForm && itemForm.grossRate ? itemForm.grossRate : 0,
            fromDate:
              tradeDate && parseInt(moment(tradeDate).format("YYYYMMDD"))
                ? parseInt(moment(tradeDate).format("YYYYMMDD"))
                : 0,
            toDate: parseInt(dueDate) ?? 0,
            expDate: parseInt(dueDate) ?? 0,
            calculate: itemForm && itemForm.calculate ? itemForm.calculate : 0,
            decimalPoint: data && data.decimalPoint ? data.decimalPoint : 0,
            roundType: data && data.roundType ? data.roundType : 0,

            feeDays: 0,
          };

          dispatch(getInterestAmountRequest(submitData2));
        }
      } else {
        dispatch(updateItemForm({ loanEstimate: 0 }));
      }
    }
  };
  useEffect(() => {
    if (statusGetDueDate) {
      const data =
        listInterestRateItem &&
        listInterestRateItem.find((i) => {
          return (
            i.period === (itemForm.period ? itemForm.period : 1) &&
            i.periodType === (itemForm.periodType ? itemForm.periodType : 1) &&
            i.fromValue <=
              (itemForm && itemForm.amount ? +itemForm.amount : 0) &&
            i.toValue >= (itemForm && itemForm.amount ? +itemForm.amount : 0)
          );
        });
      dispatch(
        updateItemForm({
          grossRate: data && data.grossRate ? data.grossRate : 0,
          netRate: data && data.netRate ? data.netRate : 0,
          calculate: data && data.calculate ? data.calculate : 0,
        })
      );
      if (data && data.grossRate && data.netRate && data.grossRate > 0) {
        const dueDate = itemForm && itemForm.dueDate ? itemForm.dueDate : "";

        const submitData2: IGetInterestAmountParams = {
          amount: itemForm && itemForm.amount ? +itemForm.amount : 0,
          inRate: data && data.grossRate ? data.grossRate : 0,
          outRate: data && data.grossRate ? data.grossRate : 0,
          fromDate:
            tradeDate && parseInt(moment(tradeDate).format("YYYYMMDD"))
              ? parseInt(moment(tradeDate).format("YYYYMMDD"))
              : 0,
          toDate: parseInt(dueDate) ?? 0,
          expDate: parseInt(dueDate) ?? 0,
          calculate: data && data.calculate ? data.calculate : 0,
          decimalPoint: data && data.decimalPoint ? data.decimalPoint : 0,
          roundType: data && data.roundType ? data.roundType : 0,

          feeDays: 0,
        };

        dispatch(getInterestAmountRequest(submitData2));
      }
    }
  }, [statusGetDueDate]);

  const doSubmitAdvance2 = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));

    const data =
      listInterestRateItem &&
      listInterestRateItem.find((i) => {
        return (
          i.period === (itemForm.period ? itemForm.period : 1) &&
          i.periodType === (itemForm.periodType ? itemForm.periodType : 1) &&
          i.fromValue <= (itemForm && itemForm.amount ? +itemForm.amount : 0) &&
          i.toValue >= (itemForm && itemForm.amount ? +itemForm.amount : 0)
        );
      });
    const dueDate = itemForm && itemForm.dueDate ? itemForm.dueDate : "";
    const submitData: IUpdateDepositContractParams = {
      actionMode: 1,
      alloDate:
        tradeDate && parseInt(moment(tradeDate).format("YYYYMMDD"))
          ? parseInt(moment(tradeDate).format("YYYYMMDD"))
          : 0,
      refNo: 0,
      subAccoNo:
        itemForm && itemForm.subAccount
          ? itemForm.subAccount
          : accountOptions[0].value,
      contractNo: "",
      amount: itemForm && itemForm.amount ? +itemForm.amount : 0,
      interestId: data && data.interestRateId ? data.interestRateId : 0,
      period: itemForm && itemForm.period ? itemForm.period : 0,
      periodType: itemForm && itemForm.periodType ? itemForm.periodType : 0,
      fromDate:
        tradeDate && parseInt(moment(tradeDate).format("YYYYMMDD"))
          ? parseInt(moment(tradeDate).format("YYYYMMDD"))
          : 0,
      endDate: parseInt(dueDate) ?? 0,
      grossRate: data && data.grossRate ? data.grossRate : 0,
      netRate: data && data.netRate ? data.netRate : 0,
      interestEstAmt:
        itemForm && itemForm.loanEstimate ? itemForm.loanEstimate : 0,
      calculate: data && data.calculate ? data.calculate : 0,
      extendType: itemForm && itemForm.turnOverType ? itemForm.turnOverType : 0,
      remarks: itemForm && itemForm.remarks ? itemForm.remarks : "",
      updDateTime: null,

      otpCode: response?.otp ?? "",
      otpType: response?.otpTypeSubmit!,

      earlyTermiType: data && data.earlyTermiType ? data.earlyTermiType : 0,
      daysNo: moment(moment(dueDate, "YYYYMMDD").format("YYYY-MM-DD")).diff(
        moment(
          moment(
            parseInt(moment(tradeDate).format("YYYYMMDD")),
            "YYYYMMDD"
          ).format("YYYY-MM-DD")
        ),
        "days"
      )
        ? moment(moment(dueDate, "YYYYMMDD").format("YYYY-MM-DD")).diff(
            moment(
              moment(
                parseInt(moment(tradeDate).format("YYYYMMDD")),
                "YYYYMMDD"
              ).format("YYYY-MM-DD")
            ),
            "days"
          )
        : 0,
    };
    dispatch(updateDepositContractRequest(submitData));
  };

  const changeTurnOverTypeHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemForm({ turnOverType: item && item.value ? +item.value : 1 })
    );
  };

  const changeRemarksHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateItemForm({ remarks: e?.target?.value ?? "" }));
  };

  const clickAcceptHandler = () => {
    if (itemForm && Object.keys(itemForm).length > 0) {
      if (
        itemForm.amount === null ||
        itemForm.amount === undefined ||
        itemForm.amount === ""
      ) {
        return toast("holdUnholdBankAccount.message.amountCheck1", "error");
      } else if (itemForm.amount === 0) {
        return toast("holdUnholdBankAccount.message.amountCheck2", "error");
      } else if (
        itemForm.periodType === null ||
        itemForm.periodType === undefined ||
        itemForm.periodType === 0
      ) {
        return toast("depositContract.message.periodTypeCheck", "error");
      } else if (
        itemForm.period === null ||
        itemForm.period === undefined ||
        itemForm.period === 0
      ) {
        return toast("depositContract.message.periodCheck", "error");
      }
    }

    setVisibleModalConfirm(true);
  };
  const setConfirm = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
  };
  const handleCloseOTPModal2 = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  useEffect(() => {
    if (statusUpdateDepositContract) {
      const submitData: ISearchDepositContractParams = {
        custNo: subAccount ? subAccount : "",
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        contractSts:
          contractSts !== null && contractSts !== undefined ? contractSts : 0,
      };
      dispatch(searchDepositContractRequest(submitData));
      dispatch(
        updateItemForm({
          subAccount: accountOptions[0].value ?? "",
          // periodType: +periodTypeOptions[0].value ?? 1,
          // turnOverType: +turnOverTypeOptions[0].value ?? 1,
          amount: "",
          turnOverType: +turnOverTypeOptions[0].value ?? 1,
          periodType: 0,
          period: 0,
          dueDate: "",
          remarks: "",
          loanEstimate: 0,
          grossRate: 0,
          netRate: 0,
          calculate: 0,
        })
      );
      setVisibleModalConfirm(false);
    }
  }, [statusUpdateDepositContract]);

  useEffect(() => {
    dispatch(
      updateItemForm({
        subAccount: accountOptions[0].value ?? "",
        // periodType: +periodTypeOptions[0].value ?? 1,
        // turnOverType: +turnOverTypeOptions[0].value ?? 1,
        amount: "",
        turnOverType: +turnOverTypeOptions[0].value ?? 1,
        periodType: 0,
        period: 0,
        dueDate: "",
        remarks: "",
        loanEstimate: 0,
        grossRate: 0,
        netRate: 0,
        calculate: 0,
      })
    );

    const submitData: IGetDepositInquiryParams = {
      subAccoNo:
        accountOptions && accountOptions.length > 0
          ? accountOptions[0].value
          : "",
    };
    dispatch(getDepositInquiryRequest(submitData));
  }, []);

  useEffect(() => {
    if (itemForm && itemForm.subAccount) {
      dispatch(
        updateItemForm({
          subAccount: itemForm.subAccount ?? "",
          // periodType: +periodTypeOptions[0].value ?? 1,
          amount: "",
          turnOverType: +turnOverTypeOptions[0].value ?? 1,

          // periodType: 1,
          periodType: 0,
          period: 0,
          dueDate: "",
          remarks: "",
          loanEstimate: 0,
          grossRate: 0,
          netRate: 0,
          calculate: 0,
        })
      );
      const submitData: IGetDepositInquiryParams = {
        subAccoNo: itemForm?.subAccount,
      };
      dispatch(getDepositInquiryRequest(submitData));
    }
  }, [itemForm?.subAccount]);

  const getDisPlay = (status: number) => {
    switch (status) {
      case 1:
        return "day";
      case 2:
        return "month";
      case 3:
        return "year1";
      case 4:
        return "year2";
      case 5:
        return "year3";
      // default:
      //   return "noValue";
    }
  };
  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100 mt-1"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.subAccount" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={accountOptions[0]}
                    options={accountOptions}
                    onChange={changeSubAccountHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3  my-auto">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.amount" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <Input
                    value={
                      itemForm?.amount &&
                      numeral(itemForm?.amount ?? 0).format("0,0")
                    }
                    onChange={changeAmountHandler}
                    onBlur={blurAmountHandler}
                    onKeyUp={keyUpAmountHandler}
                    placeholder={intl.formatMessage({
                      id: "moneyTransfer.enterAmount",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100 mt-1"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.availableBalance" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {numeral(
                    itemForm && itemForm.availableBalance
                      ? itemForm.availableBalance
                      : 0
                  ).format("0,0")}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3  my-auto">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.periodType" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={periodTypeOptions[0]}
                    value={{
                      label:
                        itemForm &&
                        itemForm.periodType &&
                        itemForm.periodType.toString() === "1"
                          ? intl.formatMessage({
                              id: "depositContract.create.periodTypeOption.date",
                            })
                          : itemForm &&
                            itemForm.periodType &&
                            itemForm.periodType.toString() === "2"
                          ? intl.formatMessage({
                              id: "depositContract.create.periodTypeOption.month",
                            })
                          : itemForm &&
                            itemForm.periodType &&
                            itemForm.periodType.toString() === "3"
                          ? intl.formatMessage({
                              id: "depositContract.create.periodTypeOption.year",
                            })
                          : "",
                      value:
                        itemForm &&
                        itemForm.turnOverType &&
                        itemForm.turnOverType.toString()
                          ? itemForm.turnOverType.toString()
                          : "",
                    }}
                    options={periodTypeOptions}
                    onChange={changePeriodTypeHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3  my-auto">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.period" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    options={filterInterestRate}
                    onChange={changePeriodHandler}
                    value={{
                      label:
                        itemForm &&
                        itemForm.period &&
                        itemForm.period.toString()
                          ? itemForm.period.toString()
                          : "",
                      value:
                        itemForm &&
                        itemForm.period &&
                        itemForm.period.toString()
                          ? itemForm.period.toString()
                          : "",
                    }}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>

        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100 mt-1"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.loanDate" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{moment(tradeDate).format("DD/MM/YYYY")}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.dueDate" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {itemForm && itemForm.dueDate
                    ? moment(itemForm.dueDate).format("DD/MM/YYYY")
                    : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.loanInterest" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {" "}
                  {itemForm && itemForm.grossRate
                    ? (itemForm.grossRate * 100).toFixed(3)
                    : 0}{" "}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.interest" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {" "}
                  {itemForm && itemForm.netRate
                    ? (itemForm.netRate * 100).toFixed(3)
                    : 0}{" "}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.dayCountConvention" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {intl.formatMessage({
                    id:
                      "depositContract.table.calculateType." +
                      getDisPlay(
                        itemForm && itemForm.calculate ? itemForm.calculate : 0
                      ),
                  }).length < 20
                    ? intl.formatMessage({
                        id:
                          "depositContract.table.calculateType." +
                          getDisPlay(
                            itemForm && itemForm.calculate
                              ? itemForm.calculate
                              : 0
                          ),
                      })
                    : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.loanEstimate" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {numeral(
                    itemForm && itemForm.loanEstimate
                      ? itemForm.loanEstimate
                      : 0
                  ).format("0,0")}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3  my-auto">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.turnOverType" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={turnOverTypeOptions[0]}
                    value={{
                      label:
                        itemForm &&
                        itemForm.turnOverType &&
                        itemForm.turnOverType.toString() === "1"
                          ? intl.formatMessage({
                              id: "depositContract.create.turnOverTypeOption.manual",
                            })
                          : intl.formatMessage({
                              id: "depositContract.create.turnOverTypeOption.auto",
                            }),
                      value:
                        itemForm &&
                        itemForm.turnOverType &&
                        itemForm.turnOverType.toString()
                          ? itemForm.turnOverType.toString()
                          : "",
                    }}
                    options={turnOverTypeOptions}
                    onChange={changeTurnOverTypeHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>

        <DetailRowTextTop>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositContract.create.remarks" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <TextArea
                    textArea={true}
                    value={itemForm?.remarks}
                    onChange={changeRemarksHandler}
                    placeholder={intl.formatMessage({
                      id: "addFrameworkContractForm.createContract.placeholderRemark",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowTextTop>
      </DetailCol>
      <DetailColFlexEnd>
        <Row className=" d-flex align-content-center w-100">
          <Col sm={12} className="w-100 p-0 px-1">
            <Button
              style={{
                background: `${themes[themeType].buttonGradientColor}`,
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "depositContract.create.confirm",
              })}
            </Button>
          </Col>
        </Row>
      </DetailColFlexEnd>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        // content={intl.formatMessage({
        //   id: "depositContract.message.confirmContent2",
        // })}
        content={
          <WrapContnetModal>
            <ItemModal>
              <ItemModalLabal>
                <FormattedMessage id="depositContract.modal.label1" />
              </ItemModalLabal>
              <ItemModalValue>
                {itemForm?.amount &&
                  numeral(itemForm?.amount ?? 0).format("0,0")}
              </ItemModalValue>
            </ItemModal>

            <ItemModal>
              <ItemModalLabal>
                <FormattedMessage id="depositContract.modal.label2" />
              </ItemModalLabal>
              <ItemModalValue>{`${
                itemForm && itemForm.period && itemForm.period.toString()
                  ? itemForm.period.toString()
                  : ""
              } ${
                itemForm &&
                itemForm.periodType &&
                itemForm.periodType.toString() === "1"
                  ? intl.formatMessage({
                      id: "depositContract.create.periodTypeOption.date",
                    })
                  : itemForm &&
                    itemForm.periodType &&
                    itemForm.periodType.toString() === "2"
                  ? intl.formatMessage({
                      id: "depositContract.create.periodTypeOption.month",
                    })
                  : itemForm &&
                    itemForm.periodType &&
                    itemForm.periodType.toString() === "3"
                  ? intl.formatMessage({
                      id: "depositContract.create.periodTypeOption.year",
                    })
                  : ""
              } `}</ItemModalValue>
            </ItemModal>

            <ItemModal className="mb-0">
              <ItemModalLabal>
                <FormattedMessage id="depositContract.modal.label3" />
              </ItemModalLabal>
              <ItemModalValue>
                {itemForm &&
                itemForm.turnOverType &&
                itemForm.turnOverType.toString() === "1"
                  ? intl.formatMessage({
                      id: "depositContract.create.turnOverTypeOption.manual",
                    })
                  : intl.formatMessage({
                      id: "depositContract.create.turnOverTypeOption.auto",
                    })}
              </ItemModalValue>
            </ItemModal>
          </WrapContnetModal>
        }
      />
      {/* <ConfirmModal
        itemEdit={itemEdit}
        accountOptions={accountOptions}
        actionOptions={actionOptions}
      /> */}
      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal2}
          getResult={doSubmitAdvance2}
          businessCd={EBusinessCd.DEPOSIT}
        />
      )}
    </DetailWrapper>
  );
}

export default RequestDetail;
