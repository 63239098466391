import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  addTickerStart,
  changeCurrentList,
  createUserFavoriteListRequest,
  editUserFavoriteListRequest,
  removeFavoriteListRequest,
  changeTickersListSelected,
} from "modules/categories/redux";
import { changeListedType } from "modules/categories/redux/listed";
import { WatchlistGroupType } from "helper/consts";
import { changeIndustryType } from "modules/categories/redux/industry";
import ModalEditCol from "./ModalEditCol";
import WatchListCustom from "./WatchListCustom";
import SelectTicker from "./SelectTicker";
import { ListType, WATCH_LIST_TAB_NAME } from "modules/categories/types";
import { CardHeader } from "./styles";
import { changeTypePutThrough } from "modules/categories/redux/putThrough";
import WatchListReadOnly from "./WatchListCustom/WatchListReadOnly";
import {
  rootCategoriesSelector,
  putThroughSelector,
  userFavoriteListSelector,
  selectedListedTypeSelector,
  selectedIndustryTypeSelector,
  languageTypeSelector,
  columnsSelector,
  tickerListInCategorySelector,
  selectedTypeDerivativeSelector,
  selectedOddLotTypeSelector,
  oddLotListSelector,
  anonymousFavoriteListSelector,
} from "modules/categories/redux/selectors";
import { putThrough, WATCH_LIST_FIXED_TABS } from "modules/categories/constant";
import ListPutThroughSelect from "./WatchListCustom/ListPutThroughSelect";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import isEmpty from "lodash/isEmpty";
import WatchlistCardWidget from "./WatchlistCard";
import { closePlaceOrder } from "modules/categories/redux/placeOrder";
import { changeTypeDerivative } from "modules/categories/redux/derivative";
import { changeOddLotType } from "modules/categories/redux/oddLot";

function Header() {
  const intl = useIntl();
  const { currentListCode, listedNew, derList, industriesListNew } =
    useSelector(rootCategoriesSelector);

  const columns = useSelector(columnsSelector);
  const tickersNew = useSelector(tickerListInCategorySelector);
  const anonymousFavoriteList = useSelector(anonymousFavoriteListSelector);
  const userFavoriteList = useSelector(userFavoriteListSelector);
  const selectedListedType = useSelector(selectedListedTypeSelector);
  const selectedOddLotType = useSelector(selectedOddLotTypeSelector);
  const oddLotListNew = useSelector(oddLotListSelector);
  const selectedIndustryType = useSelector(selectedIndustryTypeSelector);
  const selectedTypeDerivative = useSelector(selectedTypeDerivativeSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { selectedTypePutThrough } = useSelector(putThroughSelector);
  const languageType = useSelector(languageTypeSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    //Default load VN30 in Listed TAB
    if (currentListCode === "Listed" && isEmpty(selectedListedType)) {
      dispatch(changeListedType("VN30_IDX"));
      dispatch(changeCurrentList({ name: "Listed", isDeleteTicker: false }));
    }
  }, [currentListCode, selectedListedType]);

  const onCreateWatchlist = (name: string) => {
    dispatch(createUserFavoriteListRequest(name));
  };

  const handleChangeWatchList =
    (name: WATCH_LIST_TAB_NAME, isDeleteTicker: boolean = false) =>
    () => {
      if (name === currentListCode) return;
      dispatch(changeCurrentList({ name, isDeleteTicker }));
      if (!["Owner"].includes(name)) {
        dispatch(
          changeTickersListSelected({
            name,
            type: WatchlistGroupType.CUSTOM,
          })
        );
        return;
      }
      dispatch(
        changeTickersListSelected({
          name,
          type: WatchlistGroupType.OWNER,
        })
      );
    };

  // const handleChangeWatchListETF = () => {
  //   if (currentListCode !== "ETF") {
  //     dispatch(
  //       changeTickersListSelected({
  //         name: "ETF",
  //         type: WatchlistGroupType.ETF,
  //       })
  //     );
  //     dispatch(changeCurrentList({ name: "ETF", isDeleteTicker: false }));
  //   }
  // };

  const handleChangeWatchListDerivative = (name: string) => () => {
    if (name !== selectedTypeDerivative || currentListCode !== "Derivative") {
      dispatch(changeTypeDerivative(name));
      dispatch(
        changeTickersListSelected({
          name,
          type: WatchlistGroupType.DERIVATIVE,
        })
      );
      dispatch(
        changeCurrentList({ name: "Derivative", isDeleteTicker: false })
      );
      // dispatch(toggleDropdownDerivative());
    }
  };

  const handleChangeWatchListIndustry = (industryCode: string) => () => {
    if (
      industryCode !== selectedIndustryType ||
      currentListCode !== "Industry"
    ) {
      dispatch(
        changeTickersListSelected({
          name: industryCode,
          type: WatchlistGroupType.INDUSTRY,
        })
      );
      dispatch(changeIndustryType(industryCode));
      dispatch(changeCurrentList({ name: "Industry", isDeleteTicker: false }));
    }
  };

  const handleChangeWatchListCoverWarrant = () => {
    if (currentListCode !== "CoverWarrant") {
      dispatch(
        changeTickersListSelected({
          name: "CoverWarrant",
          type: WatchlistGroupType.CW,
        })
      );
      dispatch(
        changeCurrentList({ name: "CoverWarrant", isDeleteTicker: false })
      );
    }
  };

  const handleChangeWatchListPutThrough = (name: string) => () => {
    if (name !== selectedTypePutThrough || currentListCode !== "PutThrough") {
      dispatch(changeTypePutThrough(name));
      dispatch(
        changeTickersListSelected({
          name,
          type: WatchlistGroupType.PUT_THROUGH,
        })
      );
      dispatch(
        changeCurrentList({ name: "PutThrough", isDeleteTicker: false })
      );
    }
  };

  const handleChangeWatchListListed = (name: string) => () => {
    if (name !== selectedListedType || currentListCode !== "Listed") {
      dispatch(
        changeTickersListSelected({
          name,
          type: WatchlistGroupType.LISTED,
        })
      );
      dispatch(changeListedType(name));
      dispatch(changeCurrentList({ name: "Listed", isDeleteTicker: false }));
    }
  };

  const handleChangeWatchListOddLot = (name: string) => () => {
    if (
      name !== selectedOddLotType ||
      currentListCode !== WATCH_LIST_FIXED_TABS.ODDLOT
    ) {
      dispatch(
        changeTickersListSelected({
          name,
          type: WatchlistGroupType.ODDLOT,
        })
      );
      dispatch(changeOddLotType(name));
      dispatch(
        changeCurrentList({
          name: WATCH_LIST_FIXED_TABS.ODDLOT,
          isDeleteTicker: false,
        })
      );
    }
  };

  const removeWatchlist = (item: ListType) => {
    dispatch(removeFavoriteListRequest(item));
  };

  const onEditWatchlist = (index: number, nameEdited: string) => {
    dispatch(editUserFavoriteListRequest({ index, nameEdited }));
  };

  const onAddTicker = (ticker: string) => {
    dispatch(addTickerStart(ticker));
  };

  const getCustomName = useMemo(() => {
    if (!isAuthenticated) {
      const selectedList = anonymousFavoriteList.find(
        (item) => item.name === currentListCode
      );
      return selectedList?.nameVi;
    } else {
      const selectedList = userFavoriteList.find(
        (item) => item.code === currentListCode
      );
      return selectedList?.nameVi;
    }
  }, [
    currentListCode,
    userFavoriteList,
    anonymousFavoriteList,
    isAuthenticated,
  ]);

  const getOddLotName = useMemo(() => {
    const curr = oddLotListNew.find((item) => {
      if (
        item.code === selectedOddLotType &&
        currentListCode === WATCH_LIST_FIXED_TABS.ODDLOT
      ) {
        return item;
      }
    });
    return curr?.name || "";
  }, [currentListCode, oddLotListNew, selectedOddLotType]);

  const getListedName = useMemo(() => {
    const curr = listedNew.find((item) => {
      if (
        item.code === selectedListedType &&
        currentListCode === WATCH_LIST_FIXED_TABS.LISTED
      ) {
        return item;
      }
    });
    return curr?.name;
  }, [currentListCode, listedNew, selectedListedType]);

  const getIndustryName = useMemo(() => {
    if (currentListCode !== WATCH_LIST_FIXED_TABS.INDUSTRY) return;
    const curr = industriesListNew.find(
      (item) => item.code === selectedIndustryType
    );
    return languageType === "vi" ? curr?.nameVi : curr?.nameEn;
  }, [currentListCode, industriesListNew, selectedIndustryType, languageType]);

  const getDerivativeName = useMemo(() => {
    if (currentListCode !== WATCH_LIST_FIXED_TABS.DERIVATIVE) return;
    if (!selectedTypeDerivative) return;
    return intl.formatMessage({ id: selectedTypeDerivative });
  }, [currentListCode, selectedTypeDerivative]);

  const getPutThroughName = useMemo(() => {
    if (currentListCode !== WATCH_LIST_FIXED_TABS.PUT_THROUGH) return;
    const curr = putThrough.find(
      (item) => item.name === selectedTypePutThrough
    );
    return curr?.displayName;
  }, [currentListCode, selectedTypePutThrough]);

  useEffect(() => {
    dispatch(closePlaceOrder());
  }, [currentListCode]);

  return (
    <CardHeader>
      <div className="header-right">
        <SelectTicker tickers={tickersNew} onAddTicker={onAddTicker} />
      </div>
      <div className="header-left">
        <div className="header-item">
          <WatchlistCardWidget
            isActive={!!getCustomName}
            label={
              getCustomName ||
              intl.formatMessage({ id: "category.custom.favorite" })
            }
            isShowIcon={true}
          />
          <WatchListCustom
            watchlists={
              isAuthenticated ? userFavoriteList : anonymousFavoriteList
            }
            isOpen={true}
            isReadOnly={false}
            isShowTitle={true}
            onRemove={removeWatchlist}
            onEdit={onEditWatchlist}
            onChangeList={handleChangeWatchList}
            onCreate={onCreateWatchlist}
            isFavoriteList
          />
        </div>

        {isAuthenticated && (
          <WatchlistCardWidget
            isActive={"Owner" === currentListCode}
            label={intl.formatMessage({ id: "category.tabs.owner" })}
            handleOnClick={handleChangeWatchList("Owner")}
          />
        )}

        <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === WATCH_LIST_FIXED_TABS.LISTED}
            label={
              getListedName ||
              intl.formatMessage({ id: "category.tabs.listed" })
            }
            isShowIcon={true}
          />

          <WatchListReadOnly
            isOpen={true}
            onChangeList={handleChangeWatchListListed}
            listCode={listedNew}
            selectedCode={selectedListedType}
            useCodeAsKey
          />
        </div>

        {/* <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === "ETF"}
            label={intl.formatMessage({ id: "ETF" })}
            handleOnClick={handleChangeWatchListETF}
          />
        </div> */}

        <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === WATCH_LIST_FIXED_TABS.PUT_THROUGH}
            label={
              getPutThroughName ||
              intl.formatMessage({ id: "category.tabs.putThrough" })
            }
            isShowIcon={true}
          />

          <ListPutThroughSelect
            isOpen={true}
            onChangeList={handleChangeWatchListPutThrough}
            listCode={putThrough}
            selectedCode={selectedTypePutThrough}
          />
        </div>

        <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === WATCH_LIST_FIXED_TABS.INDUSTRY}
            label={
              getIndustryName ||
              intl.formatMessage({ id: "category.tabs.industry" })
            }
            isShowIcon={true}
          />

          <WatchListReadOnly
            isOpen={true}
            twoCol
            onChangeList={handleChangeWatchListIndustry}
            listCode={industriesListNew}
            selectedCode={selectedIndustryType}
            useCodeAsKey
          />
        </div>
        <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === WATCH_LIST_FIXED_TABS.DERIVATIVE}
            label={
              getDerivativeName ||
              intl.formatMessage({ id: "category.tabs.derivative" })
            }
            isShowIcon={true}
          />

          <WatchListReadOnly
            isOpen={true}
            onChangeList={handleChangeWatchListDerivative}
            listCode={derList}
            selectedCode={selectedTypeDerivative}
          />
        </div>
        <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === WATCH_LIST_FIXED_TABS.COVER_WARRANT}
            label={intl.formatMessage({ id: "category.tabs.coverWarrant" })}
            handleOnClick={handleChangeWatchListCoverWarrant}
          />
        </div>

        <div className="header-item">
          <WatchlistCardWidget
            isActive={currentListCode === WATCH_LIST_FIXED_TABS.ODDLOT}
            label={
              intl.formatMessage({ id: "category.tabs.oddLot" }) + getOddLotName
            }
            isShowIcon={true}
          />

          <WatchListReadOnly
            isOpen={true}
            onChangeList={handleChangeWatchListOddLot}
            listCode={oddLotListNew}
            selectedCode={selectedOddLotType}
            useCodeAsKey
          />
        </div>
      </div>

      {currentListCode !== WATCH_LIST_FIXED_TABS.PUT_THROUGH && (
        <ModalEditCol columns={columns} />
      )}
    </CardHeader>
  );
}

export default memo(Header);
