import { ColManage } from "modules/categories/types";
import styled from "styled-components/macro";
import {
  getWidthRatio,
  getWidthForOptionalColumn,
} from "modules/categories/helpers/utils";

export const HeaderRow = styled.div<{
  sumWidth: number;
  askWidth: number;
  bidWidth: number;
  foreignWidth: number;
  priceWidth: number;
  lastSaleWidth: number;
  coverWarrantWidth: number;
  coverWarrantHeadWidth: number;
  columns: ColManage;
}>`
  flex: 0 0 50px;
  min-width: ${({ sumWidth }) => sumWidth}px;
  text-align: center;
  display: flex;
  text-transform: uppercase;

  ::after {
    content: "";
    display: block;
    clear: both;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  div {
    font-size: ${({ theme }) => theme.typeFaces.watchlist.header.size};
    color: ${({ theme }) => theme.textcolorWhite};
  }

  & > div {
    float: left;
    min-width: 64px;
    width: ${({ sumWidth }) => getWidthRatio(64, sumWidth)}%;
    padding: 16px 12px;
    background-color: ${({ theme }) => theme.background2};
    line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
    overflow: hidden;
    color: ${({ theme }) => theme.textcolorWhite};
    box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
    div {
      font-size: ${({ theme }) => theme.typeFaces.watchlist.header.size};
    }

    &.special-col {
      padding: 0;
      div {
        padding: 4px 12px;
        box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
        &.flex-content {
          padding: 0;
        }
      }
    }

    &.bg-overlay-col {
      background-color: ${({ theme }) => theme.background4};
    }

    &.ticker-name-col {
      padding: 2px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: ${({ columns }) => columns.TICKER.minWidth}px;
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.TICKER.minWidth, sumWidth)}%;
      background-color: ${({ theme }) => theme.background2};
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => columns.TICKER.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.TICKER.smallDeviceMinWidth, sumWidth)}%;
      }
    }

    &.ticker-name-col.remove-box-shadow {
      min-width: ${({ columns }) => {
        if (columns.CHART.minWidth && columns.TICKER.minWidth) {
          return columns.TICKER.minWidth + columns.CHART.minWidth;
        }
      }}px;
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.TICKER.minWidth, sumWidth) +
        getWidthRatio(columns.CHART.minWidth, sumWidth)}%;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => {
          if (
            columns.CHART.smallDeviceMinWidth &&
            columns.TICKER.smallDeviceMinWidth
          ) {
            return (
              columns.TICKER.smallDeviceMinWidth +
              columns.CHART.smallDeviceMinWidth
            );
          }
        }}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.TICKER.smallDeviceMinWidth, sumWidth) +
          getWidthRatio(columns.CHART.minWidth, sumWidth)}%;
      }
    }

    &.chart {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.CHART.minWidth, sumWidth)}%;
      min-width: ${({ columns }) => columns.CHART.minWidth}px;
      background-color: ${({ theme }) => theme.bgElevated3};
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => columns.TICKER.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.TICKER.smallDeviceMinWidth, sumWidth)}%;
      }
    }

    &.opening-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &.opening-price.ceiling-price {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.SUMMARY.children!.CEIL.minWidth!, sumWidth)}%;
      min-width: ${({ columns }) => columns.SUMMARY.children!.CEIL.minWidth}px;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) =>
          columns.SUMMARY.children!.CEIL.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.SUMMARY.children!.CEIL.smallDeviceMinWidth,
            sumWidth
          )}%;
      }
    }

    &.opening-price.floor-price {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.SUMMARY.children!.FLOOR.minWidth, sumWidth)}%;
      min-width: ${({ columns }) => columns.SUMMARY.children!.FLOOR.minWidth}px;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) =>
          columns.SUMMARY.children!.FLOOR.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.SUMMARY.children!.FLOOR.smallDeviceMinWidth,
            sumWidth
          )}%;
      }
    }

    &.opening-price.reference-short-price {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.SUMMARY.children!.REFER.minWidth, sumWidth)}%;
      min-width: ${({ columns }) => columns.SUMMARY.children!.REFER.minWidth}px;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) =>
          columns.SUMMARY.children!.REFER.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.SUMMARY.children!.REFER.smallDeviceMinWidth,
            sumWidth
          )}%;
      }
    }

    &.bid-col {
      min-width: ${({ bidWidth }) => bidWidth}px;
      width: ${({ sumWidth, bidWidth }) => getWidthRatio(bidWidth, sumWidth)}%;
      background-color: ${({ theme }) => theme.background2};
      .volume1 {
        min-width: ${({ columns }) => columns.BID.children!.VOLUME1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.VOLUME1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.VOLUME1.smallDeviceMinWidth}px;
        }
      }

      .volume2 {
        min-width: ${({ columns }) => columns.BID.children!.VOLUME2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.VOLUME2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.VOLUME2.smallDeviceMinWidth}px;
        }
      }

      .volume3 {
        min-width: ${({ columns }) => columns.BID.children!.VOLUME3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.VOLUME3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.VOLUME3.smallDeviceMinWidth}px;
        }
      }

      .price1 {
        min-width: ${({ columns }) => columns.BID.children!.PRICE1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.PRICE1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.PRICE1.smallDeviceMinWidth}px;
        }
      }

      .price2 {
        min-width: ${({ columns }) => columns.BID.children!.PRICE2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.PRICE2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.PRICE2.smallDeviceMinWidth}px;
        }
      }

      .price3 {
        min-width: ${({ columns }) => columns.BID.children!.PRICE3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.PRICE3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.PRICE3.smallDeviceMinWidth}px;
        }
      }
    }

    &.last-sale-col {
      width: ${({ lastSaleWidth, sumWidth }) =>
        getWidthRatio(lastSaleWidth, sumWidth)}%;
      min-width: ${({ lastSaleWidth }) => lastSaleWidth}px;

      .last-sale-price {
        min-width: ${({ columns }) =>
          columns.LAST_SALE.children!.PRICE.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.LAST_SALE.children!.PRICE.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.LAST_SALE.children!.PRICE.smallDeviceMinWidth}px;
        }
      }

      .last-sale-margin {
        min-width: ${({ columns }) =>
          columns.LAST_SALE.children!.CHANGE_PRICE.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.LAST_SALE.children!.CHANGE_PRICE.minWidth,
            sumWidth
          )}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.LAST_SALE.children!.CHANGE_PRICE.smallDeviceMinWidth}px;
        }
        .profit-per-lost {
          align-items: center;
          box-shadow: none;
          position: relative;
          padding: 2px 0;
          width: 100%;
          .profit-arrow-left,
          .profit-arrow-right {
            position: absolute;
            height: 100%;
            width: 12px;
            padding: 0 4px;
            &:hover {
              background-color: ${({ theme }) => theme.grey70};
            }
          }
          .profit-arrow-left {
            left: 0;
          }
          .profit-arrow-right {
            right: 0;
          }
        }
      }

      .last-sale-amount {
        min-width: ${({ columns }) =>
          columns.LAST_SALE.children!.VOLUME.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.LAST_SALE.children!.VOLUME.minWidth,
            sumWidth
          )}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.LAST_SALE.children!.VOLUME.smallDeviceMinWidth}px;
        }
      }
    }

    &.ask-col {
      min-width: ${({ askWidth }) => askWidth}px;
      width: ${({ sumWidth, askWidth }) => getWidthRatio(askWidth, sumWidth)}%;
      background-color: ${({ theme }) => theme.background2};
      .volume1 {
        min-width: ${({ columns }) => columns.ASK.children!.VOLUME1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.VOLUME1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.VOLUME1.smallDeviceMinWidth}px;
        }
      }

      .volume2 {
        min-width: ${({ columns }) => columns.ASK.children!.VOLUME2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.VOLUME2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.VOLUME2.smallDeviceMinWidth}px;
        }
      }

      .volume3 {
        min-width: ${({ columns }) => columns.ASK.children!.VOLUME3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.VOLUME3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.VOLUME3.smallDeviceMinWidth}px;
        }
      }

      .price1 {
        min-width: ${({ columns }) => columns.ASK.children!.PRICE1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.PRICE1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.PRICE1.smallDeviceMinWidth}px;
        }
      }

      .price2 {
        min-width: ${({ columns }) => columns.ASK.children!.PRICE2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.PRICE2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.PRICE2.smallDeviceMinWidth}px;
        }
      }

      .price3 {
        min-width: ${({ columns }) => columns.ASK.children!.PRICE3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.PRICE3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.PRICE3.smallDeviceMinWidth}px;
        }
      }
    }

    &.total-volume-col {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.VOLUME.minWidth!, sumWidth)}%;
      min-width: ${({ columns }) => columns.VOLUME.minWidth}px;
      text-align: right;
      background-color: ${({ theme }) => theme.background3};
      padding: 2px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => columns.VOLUME.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.VOLUME.smallDeviceMinWidth!, sumWidth)}%;
      }
      cursor: pointer;
      .summary-total {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .profit-arrow-left,
        .profit-arrow-right {
          height: 100%;
          width: 12px;
          padding: 0 4px;
          &:hover {
            background-color: ${({ theme }) => theme.grey70};
          }
        }
        .profit-arrow-left {
          left: 0;
        }
        .profit-arrow-right {
          right: 0;
        }
      }
    }

    &.price-col {
      width: ${({ priceWidth, sumWidth }) =>
        getWidthRatio(priceWidth, sumWidth)}%;
      min-width: ${({ priceWidth }) => priceWidth}px;

      .high {
        min-width: ${({ columns }) => columns.PRICE?.children!.HIGH.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.PRICE?.children!.HIGH.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.PRICE?.children!.HIGH.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.PRICE?.children!.HIGH.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .low {
        min-width: ${({ columns }) => columns.PRICE?.children!.LOW.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.PRICE?.children!.LOW.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.PRICE?.children!.LOW.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.PRICE?.children!.LOW.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .average {
        min-width: ${({ columns }) =>
          columns.PRICE?.children!.AVERAGE.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.PRICE?.children!.AVERAGE.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.PRICE?.children!.AVERAGE.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.PRICE?.children!.AVERAGE.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }
    }

    &.foreign-col {
      width: ${({ sumWidth, foreignWidth }) =>
        getWidthRatio(foreignWidth, sumWidth)}%;
      min-width: ${({ foreignWidth }) => foreignWidth}px;
      background-color: ${({ theme }) => theme.background2};
      .bid {
        min-width: ${({ columns }) =>
          columns.FOREIGN?.children!.BID.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.FOREIGN?.children!.BID.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.FOREIGN?.children!.BID.smallDeviceMinWidth}px;
        }
      }

      .ask {
        min-width: ${({ columns }) =>
          columns.FOREIGN?.children!.ASK.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.FOREIGN?.children!.ASK.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.FOREIGN?.children!.ASK.smallDeviceMinWidth}px;
        }
      }

      .currentroom {
        min-width: ${({ columns }) =>
          columns.FOREIGN?.children!.CURRENTROOM?.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.FOREIGN?.children!.CURRENTROOM?.minWidth,
            sumWidth
          )}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.FOREIGN?.children!.CURRENTROOM?.smallDeviceMinWidth}px;
        }
      }
    }

    &.cover-warrant-group {
      width: ${({ sumWidth, coverWarrantWidth }) =>
        getWidthRatio(coverWarrantWidth, sumWidth)}%;
      min-width: ${({ coverWarrantWidth }) => coverWarrantWidth}px;
      background-color: ${({ theme }) => theme.background2};
      padding: 0;
      > div {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 100%;
        flex-direction: column;
        height: 100%;
        padding: 4px 0;
      }
      .ckcs {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "CKCS")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "CKCS"),
            sumWidth
          )}%;
      }
      .price_ckcs {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "PRICE_CKCS")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "PRICE_CKCS"),
            sumWidth
          )}%;
      }
      .th {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "TH")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "TH"),
            sumWidth
          )}%;
      }
      .ratio {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "RATIO")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "RATIO"),
            sumWidth
          )}%;
      }
      .diff_cd {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "DIFF_CD")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "DIFF_CD"),
            sumWidth
          )}%;
      }
      .break_even_point {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(
            columns,
            "COVER_WARRANT",
            "BREAK_EVEN_POINT"
          )}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(
              columns,
              "COVER_WARRANT",
              "BREAK_EVEN_POINT"
            ),
            sumWidth
          )}%;
      }
    }

    &.cover-warrant-head-group {
      width: ${({ sumWidth, coverWarrantHeadWidth }) =>
        getWidthRatio(coverWarrantHeadWidth, sumWidth)}%;
      min-width: ${({ coverWarrantHeadWidth }) => coverWarrantHeadWidth}px;
      background-color: ${({ theme }) => theme.background2};
      padding: 0;
      > div {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 100%;
        flex-direction: column;
        height: 100%;
        padding: 4px 0;
      }

      .tcph {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "TCPH")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "TCPH"),
            sumWidth
          )}%;
      }
      .gdcc {
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "GDCC")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "GDCC"),
            sumWidth
          )}%;
      }
    }

    &.flex-content {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      div {
        flex: 1;
      }
    }

    &.sort-item {
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      padding-bottom: 4px;
      justify-content: space-between;
    }

    .hidden-sort-icon {
      path {
        fill: transparent;
      }
    }

    .flex-content {
      display: flex;

      div {
        flex: 1;
        box-shadow: inset -1px 0px 0px ${({ theme }) => theme.border1};
        padding: 2px 6px;
        height: 28px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
      }
    }
  }
`;

// export const DerivativeHeader = styled.div<{
//   minWidth: number;
//   sumWidth: number;
// }>``;

// export const CoverWarrantHeader = styled.div<{
//   minWidth: number;
//   sumWidth: number;
// }>`
//   width: ${({ sumWidth, minWidth }) =>
//     getWidthRatio(minWidth, sumWidth)}% !important;
//   min-width: ${({ minWidth }) => minWidth}px !important;
//   text-align: right;
// `;
