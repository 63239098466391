import VgaiaHttp from "core/http/singleton/vgaia";
import queryString from "query-string";

interface paramGetData {
  fromDate?: string;
  toDate?: string;
  status?: string;
  custNo?: string;
}

export const findCbotBondTransferHistoryApi = async (params: paramGetData) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/findCbotBondTransferHistory${
      paramsFormatter ? `?${paramsFormatter}` : ""
    }`
  );
  return res?.data;
};
