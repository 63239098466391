import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const marginContractSelector = (state: RootState) =>
  state.marginContract;

export const activeTabSelector = createSelector(
  marginContractSelector,
  (state) => state.activeTab
);

export const listMarginContractSelector = createSelector(
  marginContractSelector,
  (state) => state.listMarginContract
);

export const loadingSearchDataSelector = createSelector(
  marginContractSelector,
  (state) => state.loadingSearchData
);

export const filterMarginContractSelector = createSelector(
  marginContractSelector,
  (state) => state.filter
);

export const marginAccountInfoSelector = createSelector(
  marginContractSelector,
  (state) => state.marginAccountInfo
);
