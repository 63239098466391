import VgaiaHttp from "core/http/singleton/vgaia";
import queryString from "query-string";

interface paramGetData {
  custNo: string;
  openDateFrom: string;
  openDateTo: string;
  secCd: string;
  status: number;
  subAccoNo: string;
}

export const findDertPositionCloseApi = async (params: {
  fromDate: string;
  secCd: string;
  toDate: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/findDertPositionClose${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const findDerTPositionApi = async (params: paramGetData) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/findDerTPosition${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const inquiryBuyingPowerApi = async (params: {
  subAccoNo?: string;
  subAccoCd?: string;
  custNo?: string;
  secCd?: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/inquiryBuyingPower${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const getChartSecuritiesApi = async (params: {
  subAccoCd?: string;
  custNo?: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/getChartSecurities${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const doAnswerBrokerApi = async (params: { subAccoNo: string }) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/doAnswerBroker${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const getCortassetGetdeltaApi = async (params: {
  custNo: string;
  subAccoNo: string | null;
  fromDate: string;
  toDate: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/getCortassetGetdelta${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const getMrkpIndexGetDeltaApi = async (params: {
  secCd: string;
  fromDate: string;
  toDate: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/getMrkpIndexGetDelta${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const getPortfolioAssetApi = async (params: {
  subAccoNo: string;
  fromDate: string;
  toDate: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/getPortfolioAsset${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const dertInquiryTradingApi = async (params: {
  custNo: string;
  secCd: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/dertInquiryTrading${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};

export const getChartAssetApi = async (params: {
  subAccoNo?: string;
  custNo?: string;
}) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get(
    `/getChartAsset${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );
  return res?.data;
};
