import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const modalRegister = (state: RootState) => state.modalRegister;

export const selectStep = createSelector(
  modalRegister,
  (state) => state.activeStep
);

// globo Form
export const selectFormSubmit = createSelector(
  modalRegister,
  (state) => state.formSubmit
);

export const selectParams = createSelector(
  modalRegister,
  (state) => state.paramSubmit
);

// lấy thông tin bóc tách
export const selectInformationExtraction = createSelector(
  modalRegister,
  (state) => state.informationExtraction
);

export const selectDataCreateUser = createSelector(
  modalRegister,
  (state) => state.informationRegister
);

export const selectValueFormsubmit = createSelector(
  modalRegister,
  (state) => state.formSubmit
);

// step 2
export const selectBtnSt2 = createSelector(
  modalRegister,
  (state) => state.paramSubmit.approvalCd
);
