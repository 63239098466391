import styled from "styled-components/macro";

export const Container = styled.div`
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100%) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "grey0" }) => theme[color]};
  padding: 0 4px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  background: ${({ theme }) => theme.background3};
  color: ${({ theme }) => theme.grey20};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;
