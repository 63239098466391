import { combineReducers } from "@reduxjs/toolkit";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Dictionary } from "lodash";
import orderBookReducer from "./orderBook";
import { DEFAULT_PAGE_SIZE } from "helper/consts";
import {
  OrderBookDisplay,
  OrderBookItem,
  OrderBookRealTime,
  OrderBookSummary,
  OrderBookSummaryItem,
  IFindOrderDetailResponse,
  IOrderDetailData,
  IFindOrderRequestCondResponse,
  IOrderDetailCond,
  IOrderBookPendingItem,
  IOrderBookCondItemDisplay,
} from "domain/models/OrderBook";
import { IntlShape } from "react-intl";
import { logOutSuccess } from "modules/auth/redux";
import moment from "moment";
import {
  getOrderPriceDisp,
  getOrderStatusColor,
  getOrderStatusPercent,
} from "helper/orderBookHelper";
import { getOrderStatusDisp } from "helper/tradingHelper";
import {
  GetOrderBookSummaryParam,
  IFindOrderRequestCondParams,
  IFindOrderDetailParams,
  IFindRequestCondParams,
  IFindRequestPendingParams,
} from "core/http/apis/tradeinfo/types";
import {
  ICancelOrdersParams,
  ICancelRequestPendingListParams,
  IDeleteRequestCondByListParams,
  IEditOrderParams,
} from "core/http/apis/orderexec/types";

export type OrderBookWidgetState = {
  orderBookList: OrderBookDisplay[];
  orderBookPendingList: IOrderBookPendingItem[];
  orderBookConditionList: IOrderBookCondItemDisplay[];
  orderBookSummaryList: OrderBookSummaryItem[];
  orderBookSummary: OrderBookSummary;
  orderBookRealTime: Dictionary<OrderBookRealTime>;
  orderBookDetail: Dictionary<OrderBookDisplay[]>;
  orderBookDetailList: IOrderDetailData[];
  orderBookConditionDetailList: IOrderDetailCond[];
  singleOrderStatus: { status: string; error?: string };
  singleOrderConditionStatus: { status: string; error?: string };
  loading: boolean;
  error: string | undefined;
  enableInfinity: boolean;
  filters: {
    limit: number;
    offset: number;
    accountSelect: { value: string; label: string };
    tradeType: { value: string; label: string };
    orderStatus: { value: string; label: string };
    conditionType: { value: string; label: string };
    symbol: string;
  };
  condition: { value: string; label: string };
  tradingSession: string;
  widgetName: string;
  selectedRows: OrderBookDisplay[];
  selectedRowsCondition: IOrderBookCondItemDisplay[];
  selectedPendingRows: IOrderBookPendingItem[];
  loadingEdit: boolean;
  isEditCancelOrder: boolean;
  isRefestData: boolean | undefined;
  loadList: number;
  isCheckAll: boolean;
  cancelStatus: { status: string; error?: string };
};

const initialState: OrderBookWidgetState = {
  orderBookList: [],
  orderBookPendingList: [],
  orderBookConditionList: [],
  orderBookSummaryList: [],
  orderBookSummary: {
    sumValueSell: 0,
    sumValueBuy: 0,
    sumValue: 0,
  },
  orderBookRealTime: {},
  orderBookDetail: {},
  orderBookDetailList: [],
  orderBookConditionDetailList: [],
  singleOrderStatus: { status: "", error: "" },
  singleOrderConditionStatus: { status: "", error: "" },
  loading: false,
  error: undefined,
  enableInfinity: false,
  filters: {
    limit: DEFAULT_PAGE_SIZE,
    offset: 1,
    accountSelect: { value: "", label: "Tất cả" },
    tradeType: { value: "0", label: "Tất cả" },
    orderStatus: { value: "0", label: "Tất cả" },
    conditionType: { value: "", label: "Tất cả" },
    symbol: "",
  },
  condition: { value: "01", label: "Lệnh thường" },
  tradingSession: "",
  widgetName: "",
  selectedRows: [],
  selectedRowsCondition: [],
  selectedPendingRows: [],
  loadingEdit: false,
  isEditCancelOrder: false,
  isRefestData: false,
  loadList: 0,
  isCheckAll: false,
  cancelStatus: { status: "", error: "" },
};

const orderBookWidgetSlice = createSlice({
  name: "orderBookWidgetSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    requestGetOrderBook: (
      state,
      action: PayloadAction<{
        params: any;
        statusVal: string;
        intl: IntlShape;
        isRefestData?: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
      state.isRefestData = action.payload.isRefestData;
    },
    getOrderBookSuccess: (
      state,
      action: PayloadAction<{ items: any[]; offset: number }>
    ) => {
      state.orderBookList =
        action.payload.offset === 0
          ? action.payload.items
          : state.orderBookList.concat(action.payload.items);

      if (action.payload.offset === 0) {
        state.orderBookRealTime = action.payload.items.reduce(
          (obj, cur) => ({
            ...obj,
            [cur.realTimeId]: {
              orderStatusDisplay: cur.orderStatusDisplay,
              orderStatusColor: cur.orderStatusColor,
              orderStatusPercent: cur.orderStatusPercent,
              ordQty: cur.ordQty,
              orderPriceDisp: cur.orderPriceDisp,
              matQty: cur.matQty,
              matPriceAvg: cur.matPriceAvg,
              pubQty: cur.pubQty,
              pubPrice: cur.pubPrice,
              lastMatchTimeDisp: cur.lastMatchTimeDisp,
              iscancel: cur.iscancel,
              isadmend: cur.isadmend,
            },
          }),
          {}
        );
      } else {
        state.orderBookRealTime = {
          ...state.orderBookRealTime,
          ...action.payload.items.reduce(
            (obj, cur) => ({
              ...obj,
              [cur.realTimeId]: {
                orderStatusDisplay: cur.orderStatusDisplay,
                orderStatusColor: cur.orderStatusColor,
                orderStatusPercent: cur.orderStatusPercent,
                ordQty: cur.ordQty,
                orderPriceDisp: cur.orderPriceDisp,
                matQty: cur.matQty,
                matPriceAvg: cur.matPriceAvg,
                pubQty: cur.pubQty,
                pubPrice: cur.pubPrice,
                lastMatchTimeDisp: cur.lastMatchTimeDisp,
                iscancel: cur.iscancel,
                isadmend: cur.isadmend,
              },
            }),
            {}
          ),
        };
      }

      if (action.payload.items.length < state.filters.limit) {
        state.enableInfinity = false;
        state.filters.offset = 0;
      } else {
        if (state.isRefestData === true) {
          state.enableInfinity = true;
          state.filters.offset = 1;
        } else {
          state.enableInfinity = true;
          state.filters.offset = state.filters.offset + 1;
        }
      }

      if (state.isRefestData === true) {
        state.selectedRows = [];
      }

      state.isRefestData = false;
      state.loading = false;
      if (state.isCheckAll) {
        const selectedRowsNew = action.payload.items.filter(
          (item) => item.visibleCheckbox === true
        );
        state.selectedRows = state.selectedRows.concat(selectedRowsNew);
      }
    },
    getOrderBookFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    findRequestPending: (
      state,
      action: PayloadAction<{
        params: IFindRequestPendingParams;
        intl: IntlShape;
        isRefestData?: boolean;
      }>
    ) => {
      state.loading = true;
    },
    findRequestPendingSuccess: (
      state,
      action: PayloadAction<{
        items: IOrderBookPendingItem[];
        offset: number;
      }>
    ) => {
      state.orderBookPendingList =
        action.payload.offset === 0
          ? action.payload.items
          : state.orderBookPendingList.concat(action.payload.items);

      if (action.payload.items.length < state.filters.limit) {
        state.enableInfinity = false;
        state.filters.offset = 0;
      } else {
        if (state.isRefestData === true) {
          state.enableInfinity = true;
          state.filters.offset = 1;
        } else {
          state.enableInfinity = true;
          state.filters.offset = state.filters.offset + 1;
        }
      }

      if (state.isRefestData === true) {
        state.selectedPendingRows = [];
      }

      state.isRefestData = false;
      state.loading = false;
    },
    findRequestPendingFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    findRequestCond: (
      state,
      action: PayloadAction<{
        params: IFindRequestCondParams;
        intl: IntlShape;
        isRefestData?: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
      state.isRefestData = action.payload.isRefestData;
      state.singleOrderConditionStatus = { status: "" };
    },
    findRequestCondSuccess: (
      state,
      action: PayloadAction<{
        items: IOrderBookCondItemDisplay[];
        offset: number;
      }>
    ) => {
      state.orderBookConditionList =
        action.payload.offset === 0
          ? action.payload.items
          : state.orderBookConditionList.concat(action.payload.items);

      if (action.payload.items.length < state.filters.limit) {
        state.enableInfinity = false;
        state.filters.offset = 0;
      } else {
        if (state.isRefestData === true) {
          state.enableInfinity = true;
          state.filters.offset = 1;
        } else {
          state.enableInfinity = true;
          state.filters.offset = state.filters.offset + 1;
        }
      }

      if (state.isRefestData === true) {
        state.selectedRowsCondition = [];
      }

      state.isRefestData = false;
      state.loading = false;
    },
    findRequestCondFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    requestGetOrderBookDetail: (
      state,
      action: PayloadAction<{
        transId: number;
        params: any;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    getOrderBookDetailSuccess: (
      state,
      action: PayloadAction<{ items: any }>
    ) => {
      state.orderBookDetail = action.payload.items;
      state.loading = false;
    },
    getOrderBookDetailFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    findOrderDetail: (state, action: PayloadAction<IFindOrderDetailParams>) => {
      state.loading = true;
      state.error = undefined;
    },
    findOrderDetailSuccess: (
      state,
      action: PayloadAction<IFindOrderDetailResponse>
    ) => {
      state.orderBookDetailList = action.payload.data.filter(
        (item) => item.reqType === 0
      );
      state.loading = false;
    },
    findOrderDetailFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    findOrderRequestCond: (
      state,
      action: PayloadAction<IFindOrderRequestCondParams>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    findOrderRequestCondSuccess: (
      state,
      action: PayloadAction<IFindOrderRequestCondResponse>
    ) => {
      state.orderBookConditionDetailList = action.payload.data;
      state.loading = false;
    },
    findOrderRequestCondFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    updateFilter: (state, action: any) => {
      state.filters = action.payload;
      state.loading = false;
    },
    updateAccountSelect: (state, action: any) => {
      state.filters.accountSelect = action.payload;
    },
    updateTradeType: (state, action: any) => {
      state.filters.tradeType = action.payload;
    },
    updateOrderStatus: (state, action: any) => {
      state.filters.orderStatus = action.payload;
    },
    updateConditionType: (state, action: any) => {
      state.filters.conditionType = action.payload;
    },
    updateSymbol: (state, action: any) => {
      state.filters.symbol = action.payload;
    },
    clearFilter: (state) => {
      state.filters = {
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
        accountSelect: { value: "", label: "Tất cả" },
        tradeType: { value: "0", label: "Tất cả" },
        orderStatus: { value: "0", label: "Tất cả" },
        conditionType: { value: "", label: "Tất cả" },
        symbol: "",
      };
    },
    onLoadingEdit: (state, action: PayloadAction<boolean>) => {
      state.loadingEdit = action.payload;
    },
    editOrder: (
      state,
      action: PayloadAction<{
        params: IEditOrderParams;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    editOrderSuccess: (state, action: PayloadAction<{ items: any }>) => {
      state.singleOrderStatus = { status: "success" };
      state.loading = false;
    },
    editOrderFailure: (state, action: PayloadAction<string>) => {
      state.singleOrderStatus = { status: "failed", error: action.payload };
    },
    requestCancelOrderList: (
      state,
      action: PayloadAction<{
        params: ICancelOrdersParams;
        intl: IntlShape;
        content: string;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    cancelOrderListSuccess: (
      state,
      action: PayloadAction<ICancelOrdersParams>
    ) => {
      const listOrder = JSON.parse(action.payload.input);
      for (let i = 0; i < listOrder.length; i++) {
        const indexFound = state.orderBookList.findIndex(
          (item) => item?.orgOrderNo?.toString() === listOrder[1]
        );
        if (indexFound !== -1) {
          state.orderBookList[indexFound].visibleCheckbox = false;
        }
      }
      state.cancelStatus = { status: "success" };
      state.loading = false;
    },
    cancelOrderListFailure: (state, action: PayloadAction<string>) => {
      state.cancelStatus = { status: "failed", error: action.payload };
    },
    cancelOrderListStatusClear: (state) => {
      state.cancelStatus = { status: "" };
    },
    cancelRequestPendingList: (
      state,
      action: PayloadAction<{
        params: ICancelRequestPendingListParams;
        intl: IntlShape;
        content: string;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    cancelRequestPendingListSuccess: (
      state,
      action: PayloadAction<ICancelRequestPendingListParams>
    ) => {
      const listOrder = action.payload.input;
      listOrder.map((item) => {
        const itemChild = item.split(":");
        if (itemChild && itemChild.length > 1) {
          const indexFound = state.orderBookPendingList.findIndex(
            (item) =>
              item?.tradeDate?.toString() === itemChild[0] &&
              item?.reqNo?.toString() === itemChild[1]
          );
          if (indexFound !== -1) {
            state.orderBookPendingList[indexFound].visibleCheckbox = false;
          }
        }

        return item;
      });
      state.cancelStatus = { status: "success" };
      state.loading = false;
    },
    cancelRequestPendingListFailure: (state, action: PayloadAction<string>) => {
      state.cancelStatus = { status: "failed", error: action.payload };
    },
    deleteRequestCondByList: (
      state,
      action: PayloadAction<{
        params: IDeleteRequestCondByListParams;
        intl: IntlShape;
        content: string;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    deleteRequestCondByListSuccess: (
      state,
      action: PayloadAction<IDeleteRequestCondByListParams>
    ) => {
      const listOrder = action.payload.delRequestCondModels;
      for (let i = 0; i < listOrder.length; i++) {
        const indexFound = state.orderBookConditionList.findIndex(
          (item) => item.id === listOrder[i].id
        );
        if (indexFound !== -1) {
          state.orderBookConditionList[indexFound].visibleCheckbox = false;
        }
      }
      state.cancelStatus = { status: "success" };
      state.loading = false;
    },
    deleteRequestCondByListFailure: (state, action: PayloadAction<string>) => {
      state.cancelStatus = { status: "failed", error: action.payload };
    },
    onReloadPlaceOrder: (state, action: PayloadAction<boolean>) => {
      state.isEditCancelOrder = action.payload;
    },
    requestPostSingleOrder: (state, action: PayloadAction<{ params: any }>) => {
      state.loading = true;
      state.error = undefined;
    },
    postSingleOrderSuccess: (state, action: PayloadAction<{ items: any }>) => {
      state.singleOrderStatus = { status: "success" };
      state.loading = false;
    },
    postSingleOrderFailure: (state, action: PayloadAction<string>) => {
      state.singleOrderStatus = { status: "failed", error: action.payload };
    },
    addStockOrderWidgetName(state, action: PayloadAction<string>) {
      state.widgetName = action.payload;
    },
    changeSelectedRows: (state, action: any) => {
      state.selectedRows = action.payload;
    },
    changeCondition: (state, action: any) => {
      state.condition = action.payload;
    },
    editSingleOrderStatusClear: (state) => {
      state.singleOrderStatus = { status: "" };
    },
    updateOrderBookRealTime: (state, action: PayloadAction<OrderBookItem>) => {
      const item = action.payload;
      if (state.orderBookRealTime[item.clOrdId]) {
        Object.assign(state.orderBookRealTime[item.clOrdId], {
          orderStatusDisplay: getOrderStatusDisp(item),
          orderStatusColor: getOrderStatusColor(item),
          orderStatusPercent: getOrderStatusPercent(item),
          ordQty: item.ordQty,
          orderPriceDisp: getOrderPriceDisp(item),
          matQty: item.matQty,
          matPriceAvg: item.matPriceAvg,
          pubQty: item.pubQty,
          pubPrice: item.pubPrice,
          lastMatchTimeDisp: moment(item.updDateTime).format(
            "DD/MM/yyyy HH:mm:ss"
          ),
          isadmend:
            item.status === "O" &&
            item.ordType === "LO" &&
            item.resolvedFlag !== 1,
          iscancel:
            (item.status === "O" || item.status === "E") &&
            item.resolvedFlag !== 1 &&
            item.ordType !== "PT" &&
            item.ordType !== "ATO" &&
            item.ordType !== "ATC" &&
            item.ordType !== "PLO",
        });
        return;
      } else {
        //reLoad lại sổ lệnh
        state.loadList = Math.random();
      }
    },
    onLoadList: (state, action) => {
      state.loadList = action.payload;
    },
    onCheckAll: (state, action: PayloadAction<boolean>) => {
      state.isCheckAll = action.payload;
    },
    requestGetOrderBookSummary: (
      state,
      action: PayloadAction<{
        params: GetOrderBookSummaryParam;
      }>
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    getOrderBookSummarySuccess: (
      state,
      action: PayloadAction<{
        items: OrderBookSummaryItem[];
        summary: OrderBookSummary;
      }>
    ) => {
      state.orderBookSummaryList = action.payload.items;
      state.orderBookSummary = action.payload.summary;
    },
    getOrderBookSummaryFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    changeSelectedRowsCondition: (state, action: any) => {
      state.selectedRowsCondition = action.payload;
    },
    changeSelectedPendingRows: (state, action: any) => {
      state.selectedPendingRows = action.payload;
    },
  },
});

export const {
  requestGetOrderBook,
  getOrderBookSuccess,
  getOrderBookFailure,
  findRequestPending,
  findRequestPendingSuccess,
  findRequestPendingFailure,
  findRequestCond,
  findRequestCondSuccess,
  findRequestCondFailure,
  requestGetOrderBookSummary,
  getOrderBookSummarySuccess,
  getOrderBookSummaryFailure,
  requestGetOrderBookDetail,
  getOrderBookDetailSuccess,
  getOrderBookDetailFailure,
  findOrderDetail,
  findOrderDetailSuccess,
  findOrderDetailFailure,
  findOrderRequestCond,
  findOrderRequestCondSuccess,
  findOrderRequestCondFailure,
  updateFilter,
  updateAccountSelect,
  updateTradeType,
  updateOrderStatus,
  updateConditionType,
  updateSymbol,
  clearFilter,
  requestCancelOrderList,
  cancelOrderListSuccess,
  cancelOrderListFailure,
  cancelRequestPendingList,
  cancelRequestPendingListSuccess,
  cancelRequestPendingListFailure,
  cancelOrderListStatusClear,
  deleteRequestCondByList,
  deleteRequestCondByListSuccess,
  deleteRequestCondByListFailure,
  editOrder,
  editOrderSuccess,
  editOrderFailure,
  addStockOrderWidgetName,
  changeSelectedRows,
  changeSelectedRowsCondition,
  changeSelectedPendingRows,
  onLoadingEdit,
  changeCondition,
  onReloadPlaceOrder,
  editSingleOrderStatusClear,
  updateOrderBookRealTime,
  onLoadList,
  onCheckAll,
} = orderBookWidgetSlice.actions;
export default combineReducers({
  root: orderBookWidgetSlice.reducer,
  orderBook: orderBookReducer,
});
