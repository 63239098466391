import { ChartTooltipModel } from "chart.js";
import crypto from "crypto";
import {
  INDEX_COLOR_CODE,
  INDEX_COLOR_CODEMap,
  INDEX_STATE,
  INDEX_STATEMap,
  STOCK_COLOR_CODE,
  STOCK_COLOR_CODEMap,
} from "domain/protoNew/auto_trading_pb";
import { Dictionary } from "lodash";
import flatten from "lodash/flatten";
import get from "lodash/get";
import toArray from "lodash/toArray";
import moment from "moment";
import { IntlShape } from "react-intl";
import themes from "themes/abstracts/_themes";
import { ThemeType } from "themes/types";
import { EMarketCodeNew, MarketSession } from "./consts";
import qs from "query-string";
import { OptionSelectType } from "components/commons/Select";
import paths from "./pathRoutes";
import { ScreenLog } from "./constFirebase";

function base64URLEncode(str: ArrayBuffer | SharedArrayBuffer) {
  return Buffer.from(str)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}
//crypto.randomBytes(64)
export const verifier = (value: ArrayBuffer | SharedArrayBuffer) =>
  base64URLEncode(value);

function sha256(verifier: string) {
  const iconv = require("iconv-lite");
  verifier = iconv.encode(verifier, "ISO-8859-1");
  return crypto.createHash("sha256").update(verifier).digest();
}
export const challenge = (value: ArrayBuffer | SharedArrayBuffer) =>
  base64URLEncode(sha256(verifier(value)));

// Deep flatten object
/**
 *
 * @param ob : Object input
 * @returns single object flattened
 */
export const flattenObject = (inputObj: { [key: string]: any }) => {
  const outputObj: { [key: string]: string } = {};

  for (const i in inputObj) {
    if (!inputObj.hasOwnProperty(i)) continue;

    if (typeof inputObj[i] === "object") {
      const flatObject = flattenObject(inputObj[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        outputObj[i + "." + x] = flatObject[x];
      }
    } else {
      outputObj[i] = inputObj[i];
    }
  }
  return outputObj;
};

export const convertKL = (numb: number) => {
  const str = numb.toLocaleString().toString();

  return numb.toLocaleString().substring(0, str.length - 1) || "-";
};
export const isNumberKey = (evt: React.KeyboardEvent<HTMLInputElement>) => {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (evt.ctrlKey && charCode === 65)
    //ctrl + A
    return true;
  if (charCode >= 48 && charCode <= 57) return true;
  if (charCode > 57 && charCode < 96) return false;
  if (charCode >= 96 && charCode <= 105) return true;
  if (charCode > 105 && charCode < 110) return false;
  if (charCode > 110 && charCode < 111) return false;
  if (charCode >= 186 && charCode < 190) return false;
  if (charCode > 190) return false;

  return true;
};

export const getColorPrice = (
  price: number | string,
  floorPrice: number,
  ceilingPrice: number,
  referencePrice: number
): string => {
  if (!parseFloat(price?.toString())) {
    return "textcolorWhite";
  }
  let color = "";

  if (price > referencePrice) {
    if (price < ceilingPrice) {
      color = "textColorGreen";
    } else {
      color = "textColorViolet";
    }
  } else if (price < referencePrice) {
    if (price > floorPrice) {
      color = "textColorRed";
    } else {
      color = "textColorBlue";
    }
  } else {
    color = "textColorYellow";
  }
  return color;
};

export const convertHexToRGBA = (hexCode: string, opacity?: number) => {
  if (!hexCode) return "rgba(250,174,50,0)";
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity || 1})`;
};

export const formatEmail = (email: string) => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // const pattern = new RegExp(/^[0-9\b]+$/);
  const pattern = new RegExp(/^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/);

  if (!pattern.test(phoneNumber)) {
    return false;
  } else if (phoneNumber.length !== 10) {
    return false;
  } else {
    return true;
  }
};

export const regExEmailPhone =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)|(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/;

export function convertDictionaryToArray<T>(dictionary: Dictionary<T[]>): T[] {
  return flatten(toArray(dictionary));
}
export const isAlphanumeric = (text: string) => {
  const regex = new RegExp("[^a-zA-Z0-9 .,]");
  return regex.test(text);
};

export function getMarketSessionName(
  type: INDEX_STATEMap[keyof INDEX_STATEMap],
  intl: IntlShape
) {
  switch (type) {
    case INDEX_STATE.S:
      return intl.formatMessage({ id: "sessionName.S" });
    case INDEX_STATE.P:
      return intl.formatMessage({ id: "sessionName.P" });
    case INDEX_STATE.O:
      return intl.formatMessage({ id: "sessionName.O" });
    case INDEX_STATE.A:
      return intl.formatMessage({ id: "sessionName.A" });
    case INDEX_STATE.C:
      return intl.formatMessage({ id: "sessionName.C" });
    case INDEX_STATE.I:
      return intl.formatMessage({ id: "sessionName.I" });
    case INDEX_STATE.K:
      return intl.formatMessage({ id: "sessionName.K" });
    case INDEX_STATE.G:
      return intl.formatMessage({ id: "sessionName.G" });
    case INDEX_STATE.J:
      return intl.formatMessage({ id: "sessionName.J" });
    case INDEX_STATE.Z:
      return intl.formatMessage({ id: "sessionName.Z" });
    default:
      return type;
  }
}

export function getMarketSessionCd(type: INDEX_STATEMap[keyof INDEX_STATEMap]) {
  switch (type) {
    case INDEX_STATE.S:
      return MarketSession.BEFORE;
    case INDEX_STATE.P:
      return MarketSession.ATO;
    case INDEX_STATE.O:
      return MarketSession.OPEN;
    case INDEX_STATE.A:
      return MarketSession.ATC;
    case INDEX_STATE.C:
      return MarketSession.RUNOFF;
    case INDEX_STATE.I:
      return MarketSession.BREAK;
    case INDEX_STATE.K:
      return MarketSession.CLOSED_K;
    case INDEX_STATE.G:
      return MarketSession.CLOSED_G;
    case INDEX_STATE.J:
      return MarketSession.CLOSED_J;
    case INDEX_STATE.Z:
      return MarketSession.CLOSED_Z;
    default:
      return type;
  }
}

export function isOdd(input: number) {
  return input % 2 !== 0;
}

type Item = { name?: string; children?: Item }[];
export function findWidgetsName(nodes: Item, predicate: any) {
  // đệ quy check Widgets đã được mở hay chưa
  if (nodes) {
    for (const node of nodes) {
      if (predicate(node)) return node;

      if (node.children) {
        let match: any = findWidgetsName(node.children, predicate);
        if (match) return match;
      }
    }
  }
}

export function findAllTabSet(nodes: Item, predicate: any, newArr: any[]) {
  if (nodes) {
    for (const node of nodes) {
      if (predicate(node)) {
        newArr.push(node);
        continue;
      }

      if (node.children) {
        findAllTabSet(node.children, predicate, newArr);
      }
    }
  }
}

export const getColorChart = (color: number, themeType: ThemeType) => {
  const colorCode = getColor(color);
  return get(themes[themeType], colorCode);
};

export function getColor(color: number | undefined) {
  switch (color) {
    case 1:
      return `solidViolet`;
    case 3:
      return `solidGreen`;
    case 4:
      return `solidRed`;
    case 2:
      return `solidBlue`;
    default:
      return `solidYellow`;
  }
}

export const getColorGradientChart = (color: number, themeType: ThemeType) => {
  const colorCode = getColorGradient(color);
  return get(themes[themeType], colorCode);
};

export function getColorGradient(color: number | undefined) {
  switch (color) {
    case 1:
      return `overlayViolet`;
    case 3:
      return `overlayGreen`;
    case 4:
      return `overlayRed`;
    case 2:
      return `overlayBlue`;
    default:
      return `overlayYellow`;
  }
}

export function getChartHtmlTooltipPosition(
  chart: any,
  tooltipElement: HTMLElement,
  tooltipModel: ChartTooltipModel,
  barWidth: number,
  spaceOffset: number
) {
  const yAlign = tooltipModel.yAlign;
  const xAlign = tooltipModel.xAlign;
  let position = chart.canvas.getBoundingClientRect();
  // Tooltip height and width
  const { height, width } = tooltipElement.getBoundingClientRect();
  let top =
    position.top + window.pageYOffset + tooltipModel.caretY - spaceOffset;
  let left = position.left + window.pageXOffset + tooltipModel.caretX;

  // yAlign could be: `top`, `bottom`, `center`
  if (yAlign === "top") {
    top -= spaceOffset;
  } else if (yAlign === "center") {
    top -= height / 2;
  } else if (yAlign === "bottom") {
    top = top - height - spaceOffset;
  }

  // xAlign could be: `left`, `center`, `right`

  if (xAlign === "left") {
    left = left + barWidth - tooltipModel.xPadding - spaceOffset / 2;
    if (yAlign === "center") {
      left += spaceOffset;
    }

    if (yAlign === "top") {
      left -= spaceOffset;
    }
  } else if (xAlign === "right") {
    left =
      left - width - barWidth / 2 + tooltipModel.xPadding + spaceOffset / 2;

    if (yAlign === "bottom") {
      left += barWidth / 2 + spaceOffset;
    }

    if (yAlign === "top") {
      left += barWidth / 2 + spaceOffset;
    }
  } else if (xAlign === "center" && yAlign === "top") {
    left =
      left - width / 2 - barWidth / 2 + tooltipModel.xPadding + spaceOffset / 2;
  }
  return {
    top,
    left,
  };
}

export const truncateText = (text: string, newLength: number) => {
  const textLength = text.length;
  if (textLength <= newLength) return text;
  const newText = text.slice(0, newLength);
  const newTextArr = newText.split(" ");
  newTextArr.pop();
  return newTextArr.join(" ") + "...";
};

export const checkDateFilter = (
  fromDate: Date,
  toDate: Date,
  range: number
): "rangeError" | "invalidFromDate" | undefined => {
  const fromDateMoment = moment(fromDate);
  const toDateMoment = moment(toDate);

  const checkDayRange = toDateMoment.diff(fromDateMoment, "days", true);

  if (checkDayRange > range + 1) {
    return "rangeError";
  }

  if (checkDayRange < 0) {
    return "invalidFromDate";
  }
};

export const getValueRound = (value: number | string) => {
  const valueNumber: number = Number(value);
  const valueRound = Math.round((valueNumber + Number.EPSILON) * 100) / 100;
  return valueRound;
};

export const getMarketLabel = (marketCode: number) => {
  switch (marketCode) {
    case EMarketCodeNew.HSX_IDX:
      return "HSX";
    case EMarketCodeNew.HNX_IDX:
      return "HNX";
    case EMarketCodeNew.UPCOM_IDX:
      return "UPCOM";
    default:
      return "-";
  }
};

export function getColorTicker(
  color: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap],
  theme: any
) {
  switch (color) {
    case STOCK_COLOR_CODE.CEILING_PRICE:
      return `color: ${theme.textColorViolet};`;
    case STOCK_COLOR_CODE.UP_PRICE:
      return `color: ${theme.textColorGreen};`;
    case STOCK_COLOR_CODE.DOWN_PRICE:
      return `color: ${theme.textColorRed};`;
    case STOCK_COLOR_CODE.FLOOR_PRICE:
      return `color: ${theme.textColorBlue};`;
    case STOCK_COLOR_CODE.NO_TRADE_PRICE:
      return `color: ${theme.grey0};`;
    default:
      return `color: ${theme.textColorYellow};`;
  }
}

export function stringColorTicker(
  color?: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap]
) {
  switch (color) {
    case STOCK_COLOR_CODE.CEILING_PRICE:
      return `textColorViolet`;
    case STOCK_COLOR_CODE.UP_PRICE:
      return `textColorGreen`;
    case STOCK_COLOR_CODE.DOWN_PRICE:
      return `textColorRed`;
    case STOCK_COLOR_CODE.FLOOR_PRICE:
      return `textColorBlue`;
    case STOCK_COLOR_CODE.NO_TRADE_PRICE:
      return `grey0`;
    default:
      return `textColorYellow`;
  }
}

export function getColorIndex(
  color: INDEX_COLOR_CODEMap[keyof INDEX_COLOR_CODEMap],
  theme: any
) {
  switch (color) {
    case INDEX_COLOR_CODE.OPEN_INDEX:
      return theme.textColorYellow;
    case INDEX_COLOR_CODE.UP_INDEX:
      return theme.textColorGreen;
    case INDEX_COLOR_CODE.DOWN_INDEX:
      return theme.textColorRed;
    default:
      return theme.textColorYellow;
  }
}

// get color
export function getColorPriceInfoLastSale(
  price: number,
  ceilingPrice: number,
  floorPrice: number,
  basicPrice: number
) {
  if (price === 0 || price === basicPrice) {
    return STOCK_COLOR_CODE.BASIC_PRICE;
  }

  if (price === ceilingPrice) {
    return STOCK_COLOR_CODE.CEILING_PRICE;
  }

  if (price === floorPrice) {
    return STOCK_COLOR_CODE.FLOOR_PRICE;
  }

  if (price > basicPrice && price < ceilingPrice) {
    return STOCK_COLOR_CODE.UP_PRICE;
  }

  if (price < basicPrice && price > floorPrice) {
    return STOCK_COLOR_CODE.DOWN_PRICE;
  }

  // Default
  return STOCK_COLOR_CODE.BASIC_PRICE;
}

export const setColor = (
  currentPrice: number | string,
  floorPrice: number,
  basicPrice: number,
  ceilingPrice: number
) => {
  if (!basicPrice) {
    return "";
  }

  const color1 = "orange";

  if (currentPrice === null) {
    return color1;
  }

  const m_val = currentPrice?.toString();

  if (m_val === "") {
    return color1;
  }

  if (m_val === "ATO" || m_val === "ATC") {
    return color1;
  }

  const m_1 = +m_val;

  if (m_1 <= floorPrice) {
    return "blue";
  } else if (m_1 > floorPrice && m_1 < basicPrice) {
    return "red";
  } else if (m_1 === basicPrice) {
    return color1;
  } else if (m_1 > basicPrice && m_1 < ceilingPrice) {
    return "green";
  } else if (m_1 >= ceilingPrice) {
    return "violet";
  }
};

export const convertNumberToDateFormat = ({
  date,
  inputFormat = "YYYYMMDD",
  outputFormat = "DD/MM/YYYY",
}: {
  date?: number;
  inputFormat?: string;
  outputFormat?: string;
}) => {
  if (!date) return "";

  return moment(date, inputFormat).format(outputFormat);
};

export const convertDateToDateFormat = ({
  date,
  outputFormat = "DD/MM/YYYY HH:mm:ss",
}: {
  date?: number;
  outputFormat?: string;
}) => {
  if (!date) return "";

  return moment(date).format(outputFormat);
};

export const combineUrlAndParam = (
  url: string,
  param: any,
  skipEmptyString?: boolean
) => {
  if (param === undefined || param === null) return url;

  const paramFormatter = qs.stringify(param, {
    skipNull: true,
    skipEmptyString,
  });
  return `${url}${paramFormatter ? `?${paramFormatter}` : ""}`;
};

export const convertCodeToText = (
  list: OptionSelectType[],
  code: number | string | undefined | null
) => {
  if (code === undefined || code === null) return "";
  return list.find((item) => item.value === code.toString())?.label ?? "";
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const smooth_scroll_to = async function (
  element: any,
  target: any,
  duration: number
) {
  element.style.pointerEvents = "none";
  await delay(1000);
  target = Math.round(target);
  duration = Math.round(duration);
  if (duration < 0) {
    return Promise.reject("bad duration");
  }
  if (duration === 0) {
    element.scrollTop = target;
    return Promise.resolve();
  }

  var start_time = Date.now();
  var end_time = start_time + duration;

  var start_top = element?.scrollTop;
  var distance = target - start_top;

  var smooth_step = function (start: any, end: any, point: any) {
    if (point <= start) {
      return 0;
    }
    if (point >= end) {
      return 1;
    }
    var x = (point - start) / (end - start); // interpolation
    return x;
  };

  return new Promise<void>(function (resolve) {
    var previous_top = element?.scrollTop;

    var scroll_frame = async function () {
      // set the scrollTop for this frame
      var now = Date.now();
      var point = smooth_step(start_time, end_time, now);
      var frameTop = Math.round(start_top + distance * point);

      element.scrollTop = frameTop;

      // check if we're done!
      if (now >= end_time) {
        resolve();
        return;
      }
      if (
        element?.scrollTop === previous_top &&
        element?.scrollTop < frameTop - 10
      ) {
        await delay(1000);
        element.scrollTop = 0;
        resolve();
        return smooth_scroll_to(element, target, duration);
      }
      previous_top = element.scrollTop;

      // schedule next frame for execution
      setTimeout(scroll_frame, 0);
    };

    // boostrap the animation process
    setTimeout(scroll_frame, 0);
  });
};

export const validURL = (str: any) => {
  var pattern = new RegExp(
    "^(https:\\/\\/)" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export function getScreenName(router: string) {
  switch (router) {
    case paths.categories:
    case paths.market:
      return ScreenLog.watchlist;
    case paths.techAnalysis:
      return ScreenLog.stockDetail;
    case paths.stockOrder:
      return ScreenLog.placeOrder;
    case paths.stockOrderDer:
      return ScreenLog.placeOrderDerivative;
    case paths.assetsIncurred:
      return ScreenLog.routerDerivativeAssetDetail;
    case paths.profitLoss:
      return ScreenLog.routerDerivativePosition;
    case paths.accountStatement:
      return ScreenLog.routerSatementMoney;
    case paths.transferShare:
      return ScreenLog.securitiesHistory;
    case paths.orderHistory:
      return ScreenLog.norOrderHistory;
    case paths.confirmation:
      return ScreenLog.orderConfirm;
    case paths.guideVideo:
      return ScreenLog.routerInvestmentTraning;
    case paths.changeLimit:
      return ScreenLog.routerChangeLimit;
    case paths.contractDebit:
      return ScreenLog.routerMarginDebitInfo;
    case paths.professionalInvestor:
      return ScreenLog.routerProfessionalInvestor;
    default:
      return "";
  }
}
