import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  gap: 2px;
`;

export const HidenCheckBox = styled.input`
  display: none;
`;

export const StyledCheckBox = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
    cursor: not-allowed;
  `}
`;
