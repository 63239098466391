import { createSelector } from "@reduxjs/toolkit";
import { FC, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { COLUMNS } from "./constant";
import { TableWrapper } from "./styles";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { isEqual } from "lodash";
import {
  updateTicker,
  WatchListState,
} from "modules/stock-order/redux/watchList";
import { subscribeTickers } from "core/wss";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { LastSale } from "domain/protoNew/auto_trading_pb";

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const selectedCategorySelector = createSelector(
  watchListSelector,
  (watchListState: WatchListState) => watchListState.selectedCategory
);

const tickerListSelector = createSelector(
  watchListSelector,
  (watchListState: WatchListState) => watchListState.tickerList
);

const tickerListSortedSelector = createSelector(
  watchListSelector,
  (watchListState: WatchListState) => watchListState.tickerListSorted
);

const Table: FC = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectedCategorySelector);

  const tickerList = useSelector(tickerListSelector);
  const tickerListSorted = useSelector(tickerListSortedSelector);
  const tickerListRef = useRef(tickerList || []);

  if (!isEqual(tickerList, tickerListRef.current)) {
    tickerListRef.current = tickerList;
  }

  const tickerShowList = useMemo(() => {
    return selectedCategory?.dataList || [];
  }, [selectedCategory?.dataList]);

  useEffect(() => {
    if (tickerShowList.length > 0) {
      const listTicker = tickerShowList.toString().replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
  }, [tickerShowList]);

  useEffect(() => {
    const eventListener = (data: LastSale.AsObject) => {
      dispatch(updateTicker(data));
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListener);

    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListener);
    };
  }, []);

  return (
    <TableWrapper>
      <TableHeader columns={COLUMNS} />
      <TableBody rowHeight={56} tickerList={tickerListSorted} />
    </TableWrapper>
  );
};

export default Table;
