import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const advanceAutoSelector = (state: RootState) => state.advanceAuto;

export const getAdvanceAutoSelector = createSelector(
  advanceAutoSelector,
  (state) => state.advanceAuto
);

export const isShowConfirmModalSelector = createSelector(
  advanceAutoSelector,
  (state) => state.isShowConfirmModal
);
export const isShowOtpModalSelector = createSelector(
  advanceAutoSelector,
  (state) => state.isShowOtpModal
);

export const statusRegisterSelector = createSelector(
  advanceAutoSelector,
  (state) => state.statusRegister
);
