import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";
import topPriceLeftReducer, { TopPriceLeftState } from "./topPriceLeft";
import topPriceRightReducer, { TopPriceRightState } from "./topPriceRight";
import topForeignReducer, { TopForeignState } from "./topForeign";
import topStatisticalReducer, { TopStatisticalState } from "./topStatistical";
import { TabHeader } from "modules/market/types";
import industryReducer, { IndustryState } from "./industry";
import adIndexReducer, { AdIndexState } from "./adIndex";
import netflowReducer, { NetflowState } from "./netflow";
import marketWatchReducer, { MarketWatchState } from "./marketWatch";
import { changeCurrentList } from "modules/categories/redux";
import { EMarketCodeNew } from "helper/consts";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export interface TabInterface {
  name: string;
  model?: any;
  id: string;
  desc?: string;
  image?: string;
}

export interface MarketState {
  root: State;
  topPriceLeft: TopPriceLeftState;
  topPriceRight: TopPriceRightState;
  industry: IndustryState;
  topForeign: TopForeignState;
  topStatistical: TopStatisticalState;
  // exchangeTradedFund: ExchangeTradedFundState;
  // recommendation: RecommendationState;
  adIndex: AdIndexState;
  netflow: NetflowState;
  marketWatch: MarketWatchState;
}

export interface ModalTickerState {
  visible: boolean;
  ticker: string | null;
  modalType?: "default" | "derivative" | string;
  from?: string;
}

export interface State {
  currentTab: string | undefined;
  tabs: TabHeader[];
  customizedBoards: TabInterface[];
  visiblePopAddWidget: boolean;
  visibleTickerDetailsModal: boolean;
  selectedTicker: string | null;
  modalType: "default" | "derivative" | string;
  from?: string;
  currentMarketCode: EMarketCodeNew;
}

const initialState: State = {
  currentTab: undefined,
  customizedBoards: [],
  visiblePopAddWidget: false,
  visibleTickerDetailsModal: false,
  tabs: [],
  selectedTicker: null,
  modalType: "default",
  currentMarketCode: EMarketCodeNew.HSX_IDX,
};

const marketSlice = createSlice({
  name: "marketSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changeCurrentList, (state) => {
      state.visibleTickerDetailsModal = false;
      state.selectedTicker = null;
    });
  },
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    getAllTickers: (state) => {},
    setCustomizedBoard: (state, action: PayloadAction<TabInterface[]>) => {
      state.customizedBoards = action.payload;
    },
    setVisiblePopAddWidget: (state, action: PayloadAction<boolean>) => {
      state.visiblePopAddWidget = action.payload;
    },
    setTabs: (state, action: PayloadAction<TabHeader[]>) => {
      state.tabs = action.payload;
    },
    setVisibleTickerDetailsModal: (
      state,
      action: PayloadAction<ModalTickerState>
    ) => {
      state.visibleTickerDetailsModal = action.payload.visible;
      state.selectedTicker = action.payload.ticker;
      state.modalType = !!action.payload.modalType
        ? action.payload.modalType
        : "default";
      state.from = action.payload.from;
    },
    setModalType: (state, action: PayloadAction<string>) => {
      state.modalType = !!action.payload ? action.payload : "default";
    },
    changeSelectedTicker: (state, action: PayloadAction<string>) => {
      state.selectedTicker = action.payload;
    },
    setCurrentMarketCode: (state, action: PayloadAction<EMarketCodeNew>) => {
      state.currentMarketCode = action.payload;
    },
  },
});

export const {
  setCurrentTab,
  getAllTickers,
  setCustomizedBoard,
  setTabs,
  setVisiblePopAddWidget,
  setVisibleTickerDetailsModal,
  changeSelectedTicker,
  setModalType,
  setCurrentMarketCode,
} = marketSlice.actions;

const persistConfig = {
  key: "marketCode",
  storage: storage,
  whitelist: ["currentMarketCode"],
};

// TODO
export default combineReducers({
  root: persistReducer(persistConfig, marketSlice.reducer),
  topPriceLeft: topPriceLeftReducer,
  topPriceRight: topPriceRightReducer,
  industry: industryReducer,
  topForeign: topForeignReducer,
  topStatistical: topStatisticalReducer,
  // exchangeTradedFund: exchangeTradedFundReducer,
  // recommendation: recommendationReducer,
  adIndex: adIndexReducer,
  netflow: netflowReducer,
  marketWatch: marketWatchReducer,
});
