import { HeaderContainer } from "./styles";
import { TopStatisticalMenu } from "./types";
import { FormattedMessage } from "react-intl";
import HeaderButton from "components/commons/ButtonOptional/HeaderButton";

export interface TopStatisticalHeaderProps {
  activeTab: TopStatisticalMenu;
  onChangeTopStatisticalType: (type: TopStatisticalMenu) => void;
}

const topStatisticalTypes: TopStatisticalMenu[] = [
  "topVolume",
  "topValue",
  "topChange",
];

function Header({
  activeTab,
  onChangeTopStatisticalType,
}: TopStatisticalHeaderProps) {
  return (
    <HeaderContainer>
      <div className="row no-gutters text-center">
        {topStatisticalTypes.map((type: TopStatisticalMenu) => {
          return (
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <HeaderButton
                active={activeTab === (type as TopStatisticalMenu)}
                value={type}
                onClick={onChangeTopStatisticalType}
              >
                <FormattedMessage
                  id={`widgets.topStatistical.headers.${type}`}
                />
              </HeaderButton>
            </div>
          );
        })}
      </div>
    </HeaderContainer>
  );
}

export default Header;
