import styled from "styled-components/macro";

export const Container = styled.div`
  padding: 24px;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const LanguagesContainer = styled.div`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.borderColor} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
`;

export const ItemLanguage = styled.button<{ isActive?: boolean }>`
  width: 150px;
  background: transparent;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textcolorWhite};
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
  ${({ isActive, theme }) =>
    isActive &&
    `
        svg {
            border: 1px solid ${theme.textcolorWhite};
            border-radius: 4px;
        }
    `}
`;

export const Languages = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ThemeContainer = styled.div`
  padding-top: 16px;
`;

export const ThemeList = styled.div`
  display: flex;
`;

export const ItemCard = styled.div<{ border?: boolean }>`
  .style-card {
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;

    .image {
      border: ${({ theme, border }) =>
        border ? "1px solid " + theme.brandPrimary : ""};
      border-radius: 8px;
      height: 7rem !important;
      width: auto;
    }
  }
  .title {
    text-align: left;
    margin: 0;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
    span {
      color: ${({ theme }) => theme.neutral3};
      font-weight: ${({ theme }) => theme.typeFaces.headline.weight.regular};
    }
  }
`;
