import { Spacer, Button, OTPByBusinessCd } from "components/commons";
import InputPrice from "components/commons/InputPrice";
import InputVolume from "components/commons/InputVolume";
import {
  ICreateDertRequestCondParams,
  IDertInquiryTradingParams,
  IDertTradingGetFeeRateParams,
  IGetCustomerRestrictionParams,
  IPlaceOrdersDerInputParams,
  IPlaceOrdersDerParams,
  IValidateDertRequestCondTimeParams,
} from "core/http/apis/orderexec/types";
import {
  EBusinessCd,
  EMarketCodeNew,
  regPrice,
  regVolumn,
  SrcChannel,
} from "helper/consts";
import storage from "helper/storage";
import { getSuggestionOrderTypes } from "helper/tradingHelper";
import {
  clearErrorMessage,
  DerTradeTypeEnum,
  resetPlaceOrderDerForm,
  updateErrorMesage,
  updatePlaceOrderDerConditionalCommandType,
  updatePlaceOrderDerPrice,
  updatePlaceOrderDerType,
  updatePlaceOrderDerVolume,
  requestCreateOrderDer,
  onChangeTickerByEnterDer,
  onChangePriceByEnterDer,
  onChangeVolumeByEnterDer,
  onChangeConditionTradeTypeByEnterDer,
  postSingleOrderDerClear,
  changeOrderTabNameDer,
  dertInquiryTrading,
  getCustomerRestrictionDer,
  getDertTradingGetFeeRate,
  validateDertRequestCondTime,
  createDertRequestCond,
  clearStatusCustomerRestriction,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import numeral from "numeral";
import React, { memo, useEffect, useRef, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AccountSelect from "./AccountSelect";
import BalanceInfo from "./BalanceInfo";
import ConditionalCommandType, {
  ConditionalCommandTypeEnum,
} from "./ConditionalCommandType";
import InputConditionTradeType from "./InputConditionTradeType";
import ModalSubmitOrder from "./ModalSubmitOrder";
import {
  Container,
  Content,
  BuySellButtonRow,
  BuyButton,
  SellButton,
  ButtonItem,
  LabelBuySell,
  RowButton,
  ContentPlace,
} from "./styles";
import TickerSearch from "./TickerSearch";
import { validateForm } from "./validator";
import { toast } from "components/commons/Toast";
import { toggleModalLogin } from "modules/auth/redux";
import { listHotKeysSelector } from "themes/redux";
import { getMarketSessionCd, getValueRound } from "helper/utils";
import { onReloadPlaceOrderDer } from "modules/stock-order-der/redux/OrderBookDer";
import {
  // listingSelector,
  isAuthenticatedSelector,
  tradeDateSelector,
  // hasDerivativeAccoSelector,
} from "modules/auth/selectors";
import {
  placeOrderDerSelector,
  isReloadPlaceOrderDerSelector,
  dataSelector,
  validateSelector,
} from "modules/stock-order-der/selectors";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { valueHeaderCardSelector } from "modules/system/selectors";
import { INDEX_STATEMap } from "domain/protoNew/auto_trading_pb";
import { GetParameters } from "helper/sessionData";

export const getConditionTradeTypes = (
  intl: IntlShape
): {
  value: ConditionalCommandTypeEnum;
  label: string;
}[] => [
  {
    value: ConditionalCommandTypeEnum.Normal,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.A" }),
  },
  {
    value: ConditionalCommandTypeEnum.UP,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.UP" }),
  },
  {
    value: ConditionalCommandTypeEnum.DOWN,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.DOWN" }),
  },
  {
    value: ConditionalCommandTypeEnum.IFDONESTOP,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.IFDONESTOP" }),
  },
  {
    value: ConditionalCommandTypeEnum.IFDONEGAIN,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.IFDONEGAIN" }),
  },
  {
    value: ConditionalCommandTypeEnum.BULLBEAR,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.BULLBEAR" }),
  },
  {
    value: ConditionalCommandTypeEnum.OCO,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.OCO" }),
  },
  {
    value: ConditionalCommandTypeEnum.TRAILINGSTOP,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.TRAILINGSTOP" }),
  },
];

export const getCashPlaceOrderDerPrice = (
  price: any,
  type: DerTradeTypeEnum,
  ceilingPrice: number,
  floorPrice: number
) => {
  if (!regPrice.test(price as string) && numeral(price).value() === null) {
    if (type === DerTradeTypeEnum.L) return ceilingPrice;
    if (type === DerTradeTypeEnum.S) return floorPrice;
    return 0;
  }
  return Number(price);
};

export const getOrdType = (price: any) => {
  if (!regPrice.test(price as string) && numeral(price).value() === null) {
    return price;
  }
  return "LO";
};

interface Props {
  selectedPrice?: number | undefined;
}

const PlaceOrderDer: React.FC<Props> = memo(({ selectedPrice }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const refInputPrice = useRef<HTMLInputElement>(null);
  const refInputVolume = useRef<HTMLInputElement>(null);
  const conditionTradeTypes = getConditionTradeTypes(intl);
  const [visibleSubmitOrder, setVisibleSubmitOrder] = useState(false);
  const [visibleSubmitOrderModal, setVisibleSubmitOrderModal] = useState(false);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const ordTradeTypeDefault = GetParameters("OD00", "ORD_TRADE_TYPE_DEFAULT");
  const ordReturnToDefault = GetParameters("OD00", "ORD_RETURN_TO_DEFAULT");
  const currentTradingSession = useSelector(
    valueHeaderCardSelector(EMarketCodeNew.DER_IDX.toString(), "state")
  ) as INDEX_STATEMap[keyof INDEX_STATEMap];

  const {
    quotesForDertOrder,
    listOrderQtySuccess,
    singleOrderStatus,
    changeTickerByEnterDer,
    changePriceByEnterDer,
    changeConditionTradeTypeByEnterDer,
    errorSingleOrderDer,
    isCustomerRestriction,
    statusCustomerRestriction,
    errorCustomerRestriction,
    dertTradingGetFeeRate,
    derCustomer,
    multipleNum,
    validateDert,
    dertInquiryTrading: { maxBought, maxSold },
  } = useSelector(placeOrderDerSelector);
  const { errorAccount, errorPrice, errorVolume, errorTran } =
    useSelector(validateSelector);
  const {
    currentTicker,
    account,
    type,
    price,
    volume,
    conditionalCommandType,
    priceUp,
    priceDown,
    cutLostAmp,
    grossAmp,
    cutLostPrice,
    limitOffset,
    rangeStop,
  } = useSelector(dataSelector);

  const isReloadPlaceOrderDer = useSelector(isReloadPlaceOrderDerSelector);
  // const listing = useSelector(listingSelector);

  const listHotKeyStorage = useSelector(listHotKeysSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const tradeDate = useSelector(tradeDateSelector);
  // const hasDerivativeAcco = useSelector(hasDerivativeAccoSelector);

  const masterAccount = storage.getMasterAccount();

  const onChangePrice = (price: string | number, type?: string) => {
    const error: { key: string; message: string } | null = {
      key: "errorPrice",
      message: "",
    };
    dispatch(updateErrorMesage(error));
    dispatch(updatePlaceOrderDerPrice(price));
  };

  const onChangeVolume = (volumn: any, type?: string) => {
    const error: { key: string; message: string } | null = {
      key: "errorVolume",
      message: "",
    };
    dispatch(updateErrorMesage(error));
    dispatch(updatePlaceOrderDerVolume(volumn));
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!isAuthenticated) {
      setLoading(false);
      return dispatch(toggleModalLogin(true));
    }
    if (!masterAccount || masterAccount === "") {
      setLoading(false);
      storage.logOut();
    }
    dispatch(clearErrorMessage());

    let error: { key: string; message: string } | null = validateForm(
      currentTicker,
      account,
      price,
      volume,
      quotesForDertOrder,
      conditionalCommandType,
      priceUp,
      priceDown,
      cutLostAmp,
      grossAmp,
      cutLostPrice,
      limitOffset,
      rangeStop,
      type,
      maxBought,
      maxSold
    );

    // Bước giá
    if (
      conditionalCommandType?.value !== ConditionalCommandTypeEnum.TRAILINGSTOP
    ) {
      if (regPrice.test(price)) {
        if (!currentTicker) {
          dispatch(
            updateErrorMesage({
              ...error,
              message: intl.formatMessage({ id: error?.message }),
            })
          );
          setLoading(false);
          return;
        }

        if (!Number.isInteger(price * 10)) {
          error = {
            key: "errorPrice",
            message: intl.formatMessage(
              { id: "tradingHelper.invalidPriceStep" },
              { priceStep: 0.1 }
            ),
          };
        }
      }
    }

    if (error) {
      dispatch(
        updateErrorMesage({
          ...error,
          message: intl.formatMessage({ id: error?.message }),
        })
      );
      setLoading(false);
    } else {
      const params: IGetCustomerRestrictionParams = {
        subAccoCd: 0,
        subAccoNo: account.custNo + ".50",
        restrictionCd: type === DerTradeTypeEnum.L ? 1 : 2,
      };

      dispatch(getCustomerRestrictionDer(params));
    }
  };

  useEffect(() => {
    if (statusCustomerRestriction === "SUCCESSFUL") {
      dispatch(clearStatusCustomerRestriction());
      if (isCustomerRestriction) {
        const paramsFeeRate: IDertTradingGetFeeRateParams = {
          custNo: account.custNo,
          secCd: currentTicker,
          marketCd: 500,
          tradeType: type === DerTradeTypeEnum.L ? 2 : 1,
          chanel: SrcChannel.WEB,
          quantity: volume,
        };

        dispatch(getDertTradingGetFeeRate(paramsFeeRate));

        const paramsCondTime: IValidateDertRequestCondTimeParams = {
          secCd: currentTicker,
        };

        dispatch(validateDertRequestCondTime(paramsCondTime));

        if (listHotKeyStorage.visiblePopupConfirm) {
          setVisibleSubmitOrder(true);
          setVisibleSubmitOrderModal(true);
        } else {
          setVisibleModalOTP(true);
        }
      } else {
        // check step error accout blockage
        toast("widgets.placeOrder.accountBlockage", "error");
        setLoading(false);
        return;
      }
    }

    if (statusCustomerRestriction === "FAILURE") {
      dispatch(clearStatusCustomerRestriction());
      setLoading(false);
      const codeError = errorCustomerRestriction?.code;
      if (codeError) {
        const msg = `${codeError?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg
          ? "error." + msg
          : "widgets.placeOrderDer.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }
    }
  }, [statusCustomerRestriction, isCustomerRestriction]);

  const handleSubmitsuccess = () => {
    if (ordReturnToDefault && ordReturnToDefault === "true") {
      if (ordTradeTypeDefault !== "") {
        if (ordTradeTypeDefault === "0") {
          dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.N));
        }

        if (ordTradeTypeDefault === "1") {
          dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.S));
        }

        if (ordTradeTypeDefault === "2") {
          dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.L));
        }
      } else {
        dispatch(updatePlaceOrderDerType(type ? type : DerTradeTypeEnum.L));
      }
    }
    getTickerInfo();
  };

  const getTickerInfo = () => {
    if (!isAuthenticated) {
      return;
    }

    // Lay thong tin suc mua
    const params: IDertInquiryTradingParams = {
      custNo: account.custNo,
      secCd: currentTicker,
    };
    dispatch(dertInquiryTrading(params));

    // Clear error nếu có
    dispatch(clearErrorMessage());
  };

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(resetPlaceOrderDerForm());
      dispatch(
        updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0])
      );
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentTicker && account?.custNo) {
      dispatch(onReloadPlaceOrderDer(true));
    }
  }, [currentTicker, account?.custNo]);

  useEffect(() => {
    dispatch(resetPlaceOrderDerForm());
    dispatch(updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0]));
  }, [currentTicker]);

  useEffect(() => {
    //check edit or cancel order reload place order
    if (!isReloadPlaceOrderDer) return;

    dispatch(onReloadPlaceOrderDer(false));

    //get info place order
    getTickerInfo();
  }, [isReloadPlaceOrderDer]);

  // useEffect(() => {
  //   dispatch(requestGetTradingSession(tickerInfoBasic.tradingSessionId));
  // }, [tickerInfoBasic, dispatch]);

  useEffect(() => {
    if (ordTradeTypeDefault !== "") {
      if (ordTradeTypeDefault === "0") {
        dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.N));
      }

      if (ordTradeTypeDefault === "1") {
        dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.S));
      }

      if (ordTradeTypeDefault === "2") {
        dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.L));
      }
    } else {
      dispatch(updatePlaceOrderDerType(type ? type : DerTradeTypeEnum.L));
    }
    if (!conditionalCommandType) {
      dispatch(
        updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0])
      );
    }

    dispatch(changeOrderTabNameDer("placeOrderDer"));
  }, []);

  // Dispatch switch button Long/Short
  const handleChangeType = (type: DerTradeTypeEnum) => () => {
    dispatch(updatePlaceOrderDerType(type));
    dispatch(onChangeVolumeByEnterDer(false));
  };

  const getPriceSuggestions = () => {
    if (
      conditionalCommandType !== null &&
      conditionalCommandType?.value !== ConditionalCommandTypeEnum.Normal
    ) {
      return [];
    }

    if (currentTradingSession !== undefined) {
      const types = getSuggestionOrderTypes(
        EMarketCodeNew.DER_IDX, // Sàn
        getMarketSessionCd(currentTradingSession) // Phiên
      );
      return types;
    }

    // Params currentTicker
    return [];
  };

  const getVolumnSuggestions = () => {
    const suggestions = listOrderQtySuccess
      ? listOrderQtySuccess
          .slice()
          .sort((firstItem, secondItem) => secondItem.value - firstItem.value)
      : listOrderQtySuccess;
    return listOrderQtySuccess ? suggestions.slice(0, 4) : listOrderQtySuccess;
  };

  const handleModal = () => {
    setVisibleModalOTP(false);
    setLoading(false);
  };

  const doSubmitPlaceOrder = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);

    if (!account.custNo) {
      setLoading(false);
      return;
    }

    if (conditionalCommandType?.value === ConditionalCommandTypeEnum.Normal) {
      const paramsOrder: IPlaceOrdersDerInputParams = {
        reqType: 1,
        secCd: currentTicker,
        boardCd: "500",
        custCd: derCustomer.custCd,
        custNo: derCustomer.custNo,
        ordQty: Number(volume?.toString().replaceAll(",", "")),
        ordPrice: getCashPlaceOrderDerPrice(
          getValueRound(price),
          type,
          quotesForDertOrder?.ceilingPrice!,
          quotesForDertOrder?.floorPrice!
        ),
        ordChanel: SrcChannel.WEB,
        tradeType: type === DerTradeTypeEnum.L ? 2 : 1,
        tradeDate: Number(tradeDate),
        feeRate: dertTradingGetFeeRate,
        feeAmt: Number(volume) * dertTradingGetFeeRate,
        ordAmt: Number(volume) * dertTradingGetFeeRate * multipleNum,
        ordType: getOrdType(price),
      };

      // Múc
      try {
        const params: IPlaceOrdersDerParams = {
          data: JSON.stringify(paramsOrder),
          otpCode: response.otp,
          otpType: response?.otpTypeSubmit!,
        };
        dispatch(requestCreateOrderDer(params));
      } catch (error: any) {
        const errorMessage: string = intl.formatMessage({
          id: error.code,
          defaultMessage: error.description,
        });

        toast(errorMessage, "error");
      }
    } else {
      const paramsOrderCond: ICreateDertRequestCondParams = {
        custNo: derCustomer.custNo,
        secCd: currentTicker,
        tradeType: type === DerTradeTypeEnum.L ? 2 : 1,
        ordQty: Number(volume?.toString().replaceAll(",", "")),
        ordPrice:
          conditionalCommandType?.value ===
          ConditionalCommandTypeEnum.TRAILINGSTOP
            ? 0
            : getCashPlaceOrderDerPrice(
                getValueRound(price),
                type,
                quotesForDertOrder?.ceilingPrice!,
                quotesForDertOrder?.floorPrice!
              ),
        condType: conditionalCommandType?.value || "",
        priceStop: null,
        rangeStop:
          conditionalCommandType?.value ===
          ConditionalCommandTypeEnum.TRAILINGSTOP
            ? Number(rangeStop)
            : null,
        grossAmp:
          conditionalCommandType?.value ===
            ConditionalCommandTypeEnum.IFDONEGAIN ||
          conditionalCommandType?.value === ConditionalCommandTypeEnum.BULLBEAR
            ? Number(grossAmp)
            : null,
        cutLostAmp:
          conditionalCommandType?.value ===
            ConditionalCommandTypeEnum.IFDONESTOP ||
          conditionalCommandType?.value === ConditionalCommandTypeEnum.BULLBEAR
            ? Number(cutLostAmp)
            : null,
        cutLostPrice:
          conditionalCommandType?.value === ConditionalCommandTypeEnum.OCO
            ? Number(cutLostPrice)
            : null,
        limitOffset: !(
          conditionalCommandType?.value === ConditionalCommandTypeEnum.UP ||
          conditionalCommandType?.value === ConditionalCommandTypeEnum.DOWN
        )
          ? Number(limitOffset)
          : null,
        validDate: validateDert,
        remarks: "",
        notes: "",
        priceUp:
          conditionalCommandType?.value === ConditionalCommandTypeEnum.UP
            ? Number(priceUp)
            : null,
        priceDown:
          conditionalCommandType?.value === ConditionalCommandTypeEnum.DOWN
            ? Number(priceDown)
            : null,
        ordChanel: SrcChannel.WEB.toString(),
        clientInfo: null,
        otpType: response?.otpTypeSubmit!,
      };

      dispatch(createDertRequestCond(paramsOrderCond));
    }
  };

  useEffect(() => {
    if (listHotKeyStorage.visiblePopupConfirm) return;
    if (singleOrderStatus?.status === "success") {
      setLoading(false);
      toast("widgets.placeOrderDer.orderSuccess", "success");
      dispatch(postSingleOrderDerClear());
      dispatch(resetPlaceOrderDerForm());
      handleSubmitsuccess();
      dispatch(
        updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0])
      );
    }

    if (singleOrderStatus?.status === "failed") {
      setLoading(false);
      const codeError = errorSingleOrderDer?.code;
      if (codeError) {
        const msg = `${codeError?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg
          ? "error." + msg
          : "widgets.placeOrderDer.orderError";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }

      dispatch(postSingleOrderDerClear());
      dispatch(
        updatePlaceOrderDerConditionalCommandType(conditionTradeTypes[0])
      );
    }
  }, [singleOrderStatus]);

  useEffect(() => {
    if (changeTickerByEnterDer) {
      refInputPrice.current?.focus();
    }
  }, [changeTickerByEnterDer]);

  useEffect(() => {
    if (changePriceByEnterDer) {
      refInputVolume.current?.focus();
    }
  }, [changePriceByEnterDer]);

  useEffect(() => {
    if (changeConditionTradeTypeByEnterDer) {
      if (
        conditionalCommandType?.value ===
        ConditionalCommandTypeEnum.TRAILINGSTOP
      ) {
        refInputVolume.current?.focus();
        dispatch(onChangeConditionTradeTypeByEnterDer(false));
      } else {
        refInputPrice.current?.focus();
        dispatch(onChangeConditionTradeTypeByEnterDer(false));
      }
    }
  }, [changeConditionTradeTypeByEnterDer]);

  const onBlurEnter = () => {
    dispatch(onChangeTickerByEnterDer(false));
    dispatch(onChangePriceByEnterDer(false));
    dispatch(onChangeVolumeByEnterDer(false));
  };

  const handleKeyDown = (e: any, typeInput: string) => {
    if (e.key === "Enter" && typeInput === "price") {
      dispatch(onChangePriceByEnterDer(true));
    }
    if (
      e.key === "Backspace" &&
      typeInput === "price" &&
      !regPrice.test(price as string)
    ) {
      onChangePrice("");
    }
    if (e.key === "Enter" && typeInput === "volume") {
      dispatch(onChangeVolumeByEnterDer(true));
      if (conditionalCommandType?.value === ConditionalCommandTypeEnum.Normal) {
        onSubmit();
        refInputVolume.current?.blur();
      }
    }
  };

  const handleChangeSuggestionsPrice = () => {
    dispatch(onChangePriceByEnterDer(true));
  };

  const onChangeSwitch = (value: boolean) => {
    if (value) {
      // dispatch(updatePlaceOrderDerPrice(tickerInfoLastSale?.price!));
    }
  };

  return (
    <Container
      className="place-order-der-container"
      id="place-order-der-container"
    >
      <Content>
        <div>
          <TickerSearch />

          <BuySellButtonRow>
            <ButtonItem
              className="mr-1"
              onClick={handleChangeType(DerTradeTypeEnum.L)}
            >
              <BuyButton isActive={type === DerTradeTypeEnum.L}></BuyButton>
              <LabelBuySell isActive={type === DerTradeTypeEnum.L}>
                <FormattedMessage id="common.long" />
              </LabelBuySell>
            </ButtonItem>
            <ButtonItem onClick={handleChangeType(DerTradeTypeEnum.S)}>
              <SellButton isActive={type === DerTradeTypeEnum.S}></SellButton>
              <LabelBuySell isActive={type === DerTradeTypeEnum.S}>
                <FormattedMessage id="common.short" />
              </LabelBuySell>
            </ButtonItem>
          </BuySellButtonRow>

          <ContentPlace>
            <AccountSelect error={errorAccount} />
            <Spacer size="s" />

            <InputConditionTradeType
              value={conditionalCommandType}
              label={intl.formatMessage({
                id: "widgets.placeOrderDer.conditionalOrder",
              })}
              onChange={(item) => {
                dispatch(updatePlaceOrderDerConditionalCommandType(item));
                dispatch(onChangeConditionTradeTypeByEnterDer(true));
              }}
              listItem={conditionTradeTypes}
            />

            {conditionalCommandType?.value !==
              ConditionalCommandTypeEnum.TRAILINGSTOP && (
              <InputPrice
                innerRef={refInputPrice}
                suggestions={getPriceSuggestions()}
                handleChangeSuggestionsPriceNextFocus={
                  handleChangeSuggestionsPrice
                }
                value={typeof price !== "object" ? price : 0}
                onChange={onChangePrice}
                onBlurEnter={onBlurEnter}
                label={intl.formatMessage({ id: "widgets.placeOrder.price" })}
                max={quotesForDertOrder?.ceilingPrice!}
                min={quotesForDertOrder?.floorPrice!}
                partern={regPrice}
                decimal
                error={errorPrice}
                placeholder={intl.formatMessage({
                  id: "formPlaceholder.inputPrice",
                })}
                ticker={currentTicker}
                onKeyDown={(e) => handleKeyDown(e, "price")}
                containerId="place-order-der-container"
                isShowSwitch
                onChangeSwitch={onChangeSwitch}
              />
            )}

            <InputVolume
              innerRef={refInputVolume}
              suggestions={getVolumnSuggestions()}
              value={volume !== "" ? numeral(volume).format("0,000") : volume}
              onChange={onChangeVolume}
              onBlurEnter={onBlurEnter}
              label={intl.formatMessage({ id: "widgets.placeOrder.volume" })}
              partern={regVolumn}
              error={errorVolume}
              min={1}
              placeholder={intl.formatMessage({
                id: "formPlaceholder.inputVolumne",
              })}
              ticker={currentTicker}
              onKeyDown={(e) => handleKeyDown(e, "volume")}
              containerId="place-order-der-container"
            />

            <ConditionalCommandType
              conditionalCommandType={conditionalCommandType?.value}
              onSubmit={onSubmit}
            />

            {errorTran ? (
              <div className="text-center">
                <span className="text-danger">{errorTran}</span>
                <Spacer size="s" />
              </div>
            ) : (
              <div></div>
            )}
          </ContentPlace>

          <BalanceInfo />
        </div>

        <RowButton>
          <Button
            className={
              type === DerTradeTypeEnum.L
                ? "btn-place-order-der btn-place-order-der-long"
                : "btn-place-order-der btn-place-order-der-short"
            }
            onClick={() => onSubmit()}
            disabled={loading}
            loading={loading}
          >
            {type === DerTradeTypeEnum.L
              ? intl.formatMessage({ id: "common.long" })
              : intl.formatMessage({ id: "common.short" })}
          </Button>
        </RowButton>

        {listHotKeyStorage.visiblePopupConfirm
          ? visibleSubmitOrderModal && (
              <ModalSubmitOrder
                visible={visibleSubmitOrder}
                setVisibleSubmitOrder={setVisibleSubmitOrder}
                closeModalInfo={() => {
                  setVisibleSubmitOrder(false);
                  setLoading(false);
                }}
                visibleModalOTP={visibleModalOTP}
                setVisibleModalOTP={setVisibleModalOTP}
                handleSubmitsuccess={() => {
                  handleSubmitsuccess();
                }}
              />
            )
          : visibleModalOTP && (
              <OTPByBusinessCd
                handleClose={handleModal}
                getResult={doSubmitPlaceOrder}
                businessCd={EBusinessCd.TRADING}
              />
            )}
      </Content>
    </Container>
  );
});
export default PlaceOrderDer;
