import styled from "styled-components/macro";

export const DetailWrapper = styled.div`
  display: flex;
  padding: 8px 0;

  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.grey0};
  .button-disable-opac {
    &:disabled,
    &[disabled] {
      opacity: 0.5;
    }
  }
  .text-white .input {
    color: ${({ theme }) => theme.grey0};
  }
  .text-white .input-container,
  .text-white .css-1jpjy99-control,
  .text-white .react-datepicker-wrapper {
    background: ${({ theme }) => theme.grey100} !important;
  }
`;

export const DetailCol = styled.div`
  flex: 1;
  padding: 0 8px;
  width: 100%;
`;
export const DetailColFlexEnd = styled.div`
  flex: 1;
  padding: 0 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;
export const DetailRow = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowButton = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;
  width: 100%;

  margin-bottom: 8px;
  margin-top: -4px;
`;
export const DetailRowPaddingTB = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
`;
export const DetailRowTextTop = styled.div`
  display: flex;

  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
`;
export const DetailLabel = styled.div`
  min-width: 120px;
  color: ${({ theme }) => theme.grey20};
  margin-left: 8px;
`;

export const DetailInputWrapper = styled.div<{
  color?: string;
}>`
  flex: 1;
  text-align: right;
  margin-left: 20px;
  color: ${({ theme }) => theme.grey0};
`;

export const CheckBoxRow = styled.div`
  display: flex;
  padding-bottom: 16px;
  margin: 0 8px;
`;
export const CheckBoxText = styled.span`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  margin-left: 10px;
  color: ${({ theme }) => theme.grey40};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;

export const ColorCheckBoxText = styled.span<{
  color?: string;
}>`
  display: inline;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ color }) => color};
`;
export const Link = styled.a`
  color: ${({ theme }) => theme.brandPrimary};
  transition: 0.3s all ease;

  &:hover {
    text-decoration: none;
  }
`;
