import { reset } from "modules/transfer-local/redux";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import RequestDetail from "./RequestDetail";

const HoldUnholdBankAccountContent: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <>
      <RequestDetail />
    </>
  );
};
export default HoldUnholdBankAccountContent;
