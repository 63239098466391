import { createSelector } from "@reduxjs/toolkit";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
}

const sellforeignqtySelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.sellforeignqty
  );
};

function SellforeignqtyCell({ ticker }: Props) {
  const sellforeignqty = useSelector(sellforeignqtySelector(ticker)) || 0;

  return (
    <VolumeCell className="ask" volume={sellforeignqty} keepRawValue={false} />
  );
}

export default SellforeignqtyCell;
