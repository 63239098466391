import { ReactComponent as IconList } from "assets/image/svg/ic_list16.svg";
import { ReactComponent as IconPlus } from "assets/image/svg/ic_plus16.svg";
import { ReactComponent as IconSave } from "assets/image/svg/ic_save16.svg";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit.svg";
import { ReactComponent as IconMaximize } from "assets/image/svg/ic_enlarge.svg";
import { ReactComponent as IconClose } from "assets/image/svg/ic_close.svg";
import { Button, Input, Spacer } from "components/commons";
import TickerDetailCWWidgets from "components/widgets/TickerDetailCWWidgets";
import FlexLayout, { Action, Layout, TabNode } from "flexlayout-react";
import {
  dataFlexLayout,
  formatTextFlexLayout,
  getDefaultTab,
  parseDataToSaveStorage,
  parseDataToState,
} from "helper/flexLayout";
import Storage from "helper/storage";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { addWidgetTickerDetailCWName } from "modules/tickerDetails/redux";
import { widgetTickerDetailCWNameSelector } from "modules/tickerDetails/redux/selectors";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { v4 as uuid } from "uuid";
import CreateBoard from "./components/CreateBoard";
import Tab from "./components/Tab";
import { IIcons } from "flexlayout-react/declarations/view/Layout";
import {
  IconAddWidgetContainer,
  IconPlusTabContainer,
  PopSaveBody,
  PopSaveContainer,
  PopSaveTitle,
  PopWidgetContainer,
  PageContainer as StockOrderContainer,
  TabContainer,
  TitleModal,
  RowModal,
  ButtonExit,
  Suggestions,
  TextContent,
} from "modules/market/pages/Market/styles";
import { Icon } from "components/commons/Icon/styles";
import { findAllTabSet, findWidgetsName } from "helper/utils";
import { MaximunTabFlexlayout } from "AppConfig";
import { toast } from "components/commons/Toast";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import {
  setIsOpenExtra,
  setVisibleContainerExtra,
  setVisibleIconExtra,
} from "modules/system/redux/common";

const TICKER_DETAIL_CW_LAYOUT_STORAGE_KEY = "ticker_detail_cw_layout";
const TICKER_DETAIL_CW_BOARD_STORAGE_KEY = "ticker_detail_cw_board";
const TICKER_DETAIL_CW_CURRENT_TAB_STORAGE_KEY = "ticker_detail_cw_current_tab";
const INIT_BOARD = "init_board";

export interface TabInterface {
  name: string;
  model?: any;
  id: string;
  desc?: string;
  image?: string;
}

export interface IBoard {
  name: string;
  id: string;
  model?: any;
}

const CWTickerDetails: React.FC = () => {
  const intl = useIntl();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const flexLayoutRef = useRef<Layout>(null);
  const refInputNameBoard = useRef<HTMLInputElement>(null);
  const refInputDescBoard = useRef<HTMLInputElement>(null);
  const refInputRenameBoard = useRef<HTMLInputElement>(null);

  const [currentTab, setCurrentTab] = useState("#t1");
  const [customizedBoards, setCustomizedBoard] = useState<TabInterface[]>([]);
  const [visiblePopAddWidget, setVisiblePopAddWidget] = useState(false);
  const [visibleModalSaveBoard, setVisibleModalSaveBoard] = useState(false);
  const [visibleModalRenameBoard, setVisibleModalRenameBoard] = useState(false);
  const widgetName = useSelector(widgetTickerDetailCWNameSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(widgetName)) {
      addWidgetManual(widgetName);
      dispatch(addWidgetTickerDetailCWName(""));
    }
  }, [widgetName]);

  const [models, setModels] = useState<TabInterface[]>([
    {
      name: intl.formatMessage({ id: "stockOrderBoard.tab.title" }) + " 1",
      model: FlexLayout.Model.fromJson(
        window.innerWidth < 1440
          ? dataFlexLayout.tickerCWDetail
          : dataFlexLayout.tickerCWDetail
      ),
      id: uuid(),
    },
  ]);

  useEffectOnce(() => {
    dispatch(setIsOpenExtra(false));
    dispatch(setVisibleIconExtra(false));
    dispatch(setVisibleContainerExtra(false));
    setCurrentTab(models[0].id);
    const data = Storage.get(TICKER_DETAIL_CW_LAYOUT_STORAGE_KEY);
    const currentTab = Storage.get(TICKER_DETAIL_CW_CURRENT_TAB_STORAGE_KEY);
    let customizedStorage = Storage.get(TICKER_DETAIL_CW_BOARD_STORAGE_KEY);
    if (customizedStorage)
      setCustomizedBoard(parseDataToState(customizedStorage));
    if (data) {
      setModels(parseDataToState(data));
      setCurrentTab(currentTab);
    }
  });

  useEffect(() => {
    if (!isAuthenticated) return;

    let data = Storage.get(TICKER_DETAIL_CW_LAYOUT_STORAGE_KEY);

    let currentTab = Storage.get(TICKER_DETAIL_CW_CURRENT_TAB_STORAGE_KEY);

    if (!data || !currentTab) {
      //khởi tạo layout nếu lần đầu vào page chưa có cache
      const dataParse =
        window.innerWidth < 1440
          ? [{ model: dataFlexLayout.tickerCWDetail, id: INIT_BOARD }]
          : [{ model: dataFlexLayout.tickerCWDetail, id: INIT_BOARD }];
      data = JSON.stringify(dataParse);

      currentTab = INIT_BOARD;
    }

    const parseData: IBoard[] = JSON.parse(data);

    const currentBoardInd = parseData.findIndex(
      (board) => board.id === currentTab
    );

    if (currentBoardInd !== -1) {
      let dataCheck = parseData[currentBoardInd]?.model?.layout.children;

      const allCurrentTabSet: any[] = [];
      findAllTabSet(
        dataCheck,
        ({ type }: { type: string }) => type === "tabset",
        allCurrentTabSet
      );
    }
  }, [isAuthenticated]);

  const storeDataBoards = (dataStore: any, curTab?: string) => () => {
    Storage.set(
      TICKER_DETAIL_CW_LAYOUT_STORAGE_KEY,
      JSON.stringify(parseDataToSaveStorage(dataStore))
    );

    if (flexLayoutRef!.current !== null) {
      let dataCheck =
        flexLayoutRef?.current?.props.model?.toJson()?.layout?.children;
      findWidgetsName(
        dataCheck,
        ({ active }: { active: boolean }) => active === true
      );
    }

    if (curTab) Storage.set(TICKER_DETAIL_CW_CURRENT_TAB_STORAGE_KEY, curTab);
  };

  const changeTab = (id: string) => () => {
    Storage.set(TICKER_DETAIL_CW_CURRENT_TAB_STORAGE_KEY, id);
    setCurrentTab(id);
  };

  const insertTab = () => {
    if (models && models.length < Number(MaximunTabFlexlayout)) {
      const newId = uuid();
      setModels([...models, getDefaultTab(newId, intl)]);
      setCurrentTab(newId);
      storeDataBoards([...models, getDefaultTab(newId, intl)], newId)();
    } else {
      toast("widgets.maximunTabError", "error");
    }
  };

  const closeTab = (index: number) => (e: any) => {
    e.stopPropagation();
    if (models.length === 1) return;
    let curId;
    if (models[index].id === currentTab) {
      if (index === 0) {
        curId = models[1].id;
      } else if (index === models.length - 1) {
        curId = models[models.length - 2].id;
      } else {
        curId = models[index - 1].id;
      }
    }
    models.splice(index, 1);
    if (curId) setCurrentTab(curId);
    setModels(cloneDeep(models));
    storeDataBoards(models, curId)();
  };

  const createBoard = (type: any, { name, model }: any) => {
    const index = models.findIndex((model) => model.id === currentTab);
    if (index < -1) return;
    models[index] = {
      ...models[index],
      name,
      model: FlexLayout.Model.fromJson(
        type === "customized" ? model.toJson() : dataFlexLayout[type]
      ),
    };

    setModels(cloneDeep(models));
    storeDataBoards(models)();
  };

  const saveBoard = () => {
    const name = refInputNameBoard.current?.value;
    const desc = refInputDescBoard.current?.value;
    const tab = models.find((model) => model.id === currentTab);

    if (!name || !tab) return;
    if (!tab.model) return toggleModalSaveBoard();

    customizedBoards.push({ ...tab, name, desc });

    setCustomizedBoard(cloneDeep(customizedBoards));
    toggleModalSaveBoard();
    Storage.set(
      TICKER_DETAIL_CW_BOARD_STORAGE_KEY,
      JSON.stringify(parseDataToSaveStorage(customizedBoards))
    );
  };

  const renameBoard = () => {
    const name = refInputRenameBoard.current?.value;
    const indexTab = models.findIndex((model) => model.id === currentTab);
    if (!name || indexTab < 0) return;
    models[indexTab] = { ...models[indexTab], name };
    setModels(cloneDeep(models));
    storeDataBoards(models)();
    toggleModalRenameBoard();
  };

  const togglePopAddWidget = () => setVisiblePopAddWidget(!visiblePopAddWidget);

  const toggleModalSaveBoard = () => setVisibleModalSaveBoard((prev) => !prev);

  const toggleModalRenameBoard = () =>
    setVisibleModalRenameBoard((prev) => !prev);

  const addWidgetManual = (widgetsName: string) => {
    if (!flexLayoutRef.current) return;
    let dataCheck =
      flexLayoutRef?.current?.props?.model?.toJson()?.layout?.children;
    // check Widgets đã được mở hay chưa
    if (
      findWidgetsName(
        dataCheck,
        ({ name }: { name: string }) => name === widgetsName
      ) === undefined
    ) {
      flexLayoutRef.current.props.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tabset",
            id: uuid(),
            name: widgetsName,
          },
          flexLayoutRef.current.props.model.getRoot().getId(),
          FlexLayout.DockLocation.RIGHT,
          0
        )
      );
    }
  };

  const addWidget = (widgetName: string) => () => {
    const id = uuid();

    if (!flexLayoutRef.current) return;

    let dataCheck =
      flexLayoutRef?.current?.props?.model?.toJson()?.layout?.children;

    const currentAllTab: any[] = [];

    findAllTabSet(
      dataCheck,
      ({ type }: { type: string }) => type === "tab",
      currentAllTab
    );

    let isExistWidget = undefined;

    if (currentAllTab.length) {
      isExistWidget = currentAllTab.find((item) => item.name === widgetName);
    }

    if (!isExistWidget) {
      flexLayoutRef.current.addTabWithDragAndDrop(
        intl.formatMessage({ id: "stockOrderDerBoard.addDisplaytitle" }),
        {
          name: widgetName,
          id,
        }
      );
    } else {
      toast(intl.formatMessage({ id: "common.existTabError" }), "error");
    }
  };

  const renderTab = () => {
    if (!models.length) return;
    return models.map(({ id, name, model }, index) => (
      <Tab
        key={id}
        id={id}
        active={id === currentTab}
        onPress={changeTab(id)}
        onClickX={closeTab(index)}
        name={name}
        model={model}
        onRename={toggleModalRenameBoard}
        onSaveBoard={toggleModalSaveBoard}
      />
    ));
  };

  const renderModalSaveBoard = () => {
    const tab = models.find((model) => model.id === currentTab);
    return (
      <Modal
        show={visibleModalSaveBoard}
        onHide={toggleModalSaveBoard}
        centered
        dialogClassName="modal-300"
      >
        <PopSaveContainer>
          <PopSaveTitle>
            {intl
              .formatMessage({ id: "stockOrderDerBoard.saveBoard" })
              .toUpperCase()}
          </PopSaveTitle>
          <PopSaveBody>
            <div className="header">
              <FormattedMessage id="stockOrderDerBoard.boardName" />
            </div>
            <Input
              innerRef={refInputNameBoard}
              autoFocus
              defaultValue={tab?.name}
              placeholder={intl.formatMessage({
                id: "stockOrderDerBoard.boardName",
              })}
            />
            <Spacer />
            <div className="header">
              <FormattedMessage id="stockOrderDerBoard.description" />
            </div>
            <Input
              innerRef={refInputDescBoard}
              defaultValue={tab?.desc}
              placeholder={intl.formatMessage({
                id: "stockOrderDerBoard.description",
              })}
            />
            <div className="footer">
              <div className="flex-grow-1" />
              <Button
                className="flex-grow-1"
                size="m"
                onClick={toggleModalSaveBoard}
                color="accept"
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Spacer />
              <Button
                className="flex-grow-1"
                size="m"
                fill
                color="accept"
                onClick={saveBoard}
              >
                <FormattedMessage id="common.save" />
              </Button>
            </div>
          </PopSaveBody>
        </PopSaveContainer>
      </Modal>
    );
  };

  const renderModalRenameBoard = () => {
    const tab = models.find((model) => model.id === currentTab);
    return (
      <Modal
        show={visibleModalRenameBoard}
        onHide={toggleModalRenameBoard}
        centered
        dialogClassName="modal-300"
      >
        <PopSaveContainer>
          <PopSaveTitle>
            {intl
              .formatMessage({ id: "stockOrderDerBoard.changeBoardName" })
              .toUpperCase()}
          </PopSaveTitle>
          <PopSaveBody>
            <div className="header">
              <FormattedMessage id="stockOrderBoard.modalSaveBoard.nameBoard" />
            </div>
            <Input
              innerRef={refInputRenameBoard}
              autoFocus
              defaultValue={tab?.name}
              placeholder={intl.formatMessage({
                id: "stockOrderDerBoard.boardName",
              })}
            />
            <div className="footer">
              <div className="flex-grow-1" />
              <Button
                size="m"
                fill
                onClick={renameBoard}
                color="accept"
                className="flex-grow-1"
              >
                <FormattedMessage id="stockOrderBoard.modalRenameBoard.ok" />
              </Button>
              <Spacer />
              <Button
                size="m"
                onClick={toggleModalRenameBoard}
                color="accept"
                className="flex-grow-1"
              >
                <FormattedMessage id="stockOrderBoard.modalRenameBoard.cancel" />
              </Button>
            </div>
          </PopSaveBody>
        </PopSaveContainer>
      </Modal>
    );
  };

  const factory = (node: TabNode) => {
    var name = node.getName();
    let component = TickerDetailCWWidgets.find(
      (widget) => widget.name === name
    );
    const Component = component?.component;
    return Component ? (
      <Component />
    ) : (
      intl.formatMessage({ id: "stockOrderDerBoard.noView" })
    );
  };

  const onFlexLayoutAction = (action: Action) => {
    if (action.type === "FlexLayout_AddNode") {
    }
    return action;
  };

  function titleFactory(node: TabNode) {
    let name = node.getName();
    return <FormattedMessage id={name} />;
  }

  function onRenderTab(node: TabNode, renderValues: any) {
    let name = node.getName();
    renderValues.name = intl.formatMessage({ id: name });
  }

  const renderContentTab = () => {
    const iconMaximize: IIcons = {
      maximize: <IconMaximize />,
      close: <IconClose className="icon-close" />,
    };

    if (!models.length) return;

    return models.map(
      (item, index) =>
        item.id === currentTab && (
          <div
            key={item.id}
            className={`${item.id === currentTab ? "d-block" : "d-none"}`}
            style={{ position: "relative", flex: 1 }}
          >
            {item.model ? (
              <FlexLayout.Layout
                model={item.model}
                i18nMapper={(id, param?) =>
                  formatTextFlexLayout(id, intl, param)
                }
                factory={factory}
                titleFactory={titleFactory}
                ref={item.id === currentTab ? flexLayoutRef : undefined}
                onModelChange={storeDataBoards(models, currentTab)}
                onAction={onFlexLayoutAction}
                onRenderTab={onRenderTab}
                icons={iconMaximize}
              />
            ) : (
              <CreateBoard
                key={item.id}
                onCreateBoard={createBoard}
                customBoards={customizedBoards}
                setCustomizedBoard={setCustomizedBoard}
              />
            )}
          </div>
        )
    );
  };

  return (
    <StockOrderContainer>
      {renderModalSaveBoard()}
      {renderModalRenameBoard()}
      <TabContainer>
        {renderTab()}
        <IconPlusTabContainer className="px-2" onClick={insertTab}>
          <IconPlus fill="gray" />
        </IconPlusTabContainer>
        <IconPlusTabContainer onClick={toggleModalSaveBoard}>
          <IconSave fill="grey" />
        </IconPlusTabContainer>
        <IconAddWidgetContainer onClick={togglePopAddWidget}>
          <IconList fill="gray" />
          {visiblePopAddWidget && (
            <PopWidgetContainer>
              <TitleModal>
                <div className="flex-grow-1" />
                <TextContent>
                  {intl
                    .formatMessage({ id: "stockOrderBoard.widgetsSettings" })
                    .toUpperCase()}
                </TextContent>
                <ButtonExit
                  onClick={() => {
                    togglePopAddWidget();
                  }}
                >
                  <Icon>
                    <IconExit />
                  </Icon>
                </ButtonExit>
              </TitleModal>
              <Suggestions>
                <FormattedMessage id="stockOrderBoard.suggestionsWidgetsSettings" />
              </Suggestions>
              {TickerDetailCWWidgets.map(({ name }) => (
                <RowModal id={name} onClick={addWidget(name)}>
                  {intl.formatMessage({ id: name })}
                </RowModal>
              ))}
            </PopWidgetContainer>
          )}
        </IconAddWidgetContainer>
      </TabContainer>
      {renderContentTab()}
    </StockOrderContainer>
  );
};

export default withRouter(CWTickerDetails);
