import CommonTableInfinity from "components/commons/CommonTableInfinity";
import {
  enableInfinityPaymenHistory,
  paymentHistoryListSelector,
  currentPagePaymenHistory,
} from "modules/contract-debit/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  HeaderCell,
  TableCellContent,
  TableCellContentColor,
  // IconWrapper,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
// import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import {
  paymentHistoryFilterSelector,
  selectLimitPage,
} from "modules/contract-debit/selectors";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";
import moment from "moment";
import { IGetListDebitPayExParams } from "core/http/apis/contract-debit/types";
import { GetAccoList } from "helper/sessionData";
import {
  fetchMoreListDebitPayExRequest,
  updateEnableInfinityPayMenHistory,
} from "modules/contract-debit/redux";

function RequestTablePaymentHistory() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(paymentHistoryListSelector);
  const enableInfinity = useSelector(enableInfinityPaymenHistory);
  const currentPage = useSelector(currentPagePaymenHistory);

  const { fromDate, toDate, subAccoNo } = useSelector(
    paymentHistoryFilterSelector
  );
  const limit = useSelector(selectLimitPage);
  const listAcc = GetAccoList(
    GetAccountType.MARGIN,
    EntrustBusinessCode.Margin
  );
  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc, intl]);

  const themeType = useSelector(themeTypeSelector);
  const center = "center";
  const flexEnd = "flex-end";
  function getColor2(value: string, value2: string) {
    if (value2 === "0") {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].solidYellow}
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === "1") {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].solidGreen}
        >
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === "9") {
      return (
        <TableCellContentColor
          align="center"
          color={themes[themeType].solidRed}
        >
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  const getDisplay10 = (status: number) => {
    switch (status) {
      case 0:
        return "status1";
      case 1:
        return "status2";
      case 9:
        return "status3";
    }
  };
  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <HeaderCell align={center}>
      //       <FormattedMessage id="contractDebit.paymentHistoryTab.table.cancel" />
      //     </HeaderCell>
      //   ),
      //   minWidth: 40,
      //   accessor: "id",
      //   Cell: (props: any) => {
      //     // const currentRow = props?.row?.original;
      //     return (
      //       <TableCellContent align={center}>
      //         {/* <IconWrapper onClick={() => clickDeleteHandler(currentRow)}> */}
      //         <IconWrapper>
      //           <IconTrash />
      //         </IconWrapper>
      //       </TableCellContent>
      //     );
      //   },
      // },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.subAccount" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.deliverDate" />
          </HeaderCell>
        ),
        accessor: "contrDateFrom",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.loanBalance" />
          </HeaderCell>
        ),
        accessor: "amount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.paymentDate" />
          </HeaderCell>
        ),
        accessor: "paymentDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.amount" />
          </HeaderCell>
        ),
        accessor: "paymentAmount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.interestRate" />
          </HeaderCell>
        ),
        accessor: "interestAmt",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.fee" />
          </HeaderCell>
        ),
        accessor: "managerFeeAmt",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.numDaysInterest" />
          </HeaderCell>
        ),
        accessor: "interestDay",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.paymentHistoryTab.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 100,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor2(
                intl.formatMessage({
                  id:
                    "contractDebit.paymentHistoryTab.table.statusList." +
                    getDisplay10(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {
    if (data.length < (currentPage + 1) * limit) {
      return dispatch(updateEnableInfinityPayMenHistory(false));
    }
    const submitData: IGetListDebitPayExParams = {
      subAccoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      type: 1,
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: currentPage + 1,
      rowPerPage: limit,
    };
    dispatch(fetchMoreListDebitPayExRequest(submitData));
  };

  return (
    <Container id="paymentHistoryTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={enableInfinity}
        scrollableTarget={"paymentHistoryTable"}
        isHasFooter={false}
      />

      {/* <SelectTable
        columns={columns}
        data={data}
        // setShowModal={setShowModalDetail}
        setDataRow={(row: any) => {
          console.log(row);
        }}
        // setSelectedRows={setSelectedRows}
        fetchMoreData={fetchMoreData}
        enableInfinity={true}
        scrollableTarget="profitLossTable"
        hiddenCheckbox={false}
        isHasFooter={false}
      /> */}
    </Container>
  );
}

export default memo(RequestTablePaymentHistory);
