/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Wrap } from "./styles";
import { useIntl } from "react-intl";
import { Button } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { IFindArticleCategorysParams } from "core/http/apis/guideVideo/types";
import {
  activeTabGuideVideoSelector,
  btnActiceGuideVideoSelector,
  listCategorySelector,
} from "modules/guideVideo/selectors";
import { ActiveTabGuideVideo } from "modules/confirmation/pages/constants";
import {
  changeButtonActiveGuideVideo,
  findArticleCategorysRequest,
} from "modules/guideVideo/redux";

const GroupBtn = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabGuideVideoSelector);
  const btnActive = useSelector(btnActiceGuideVideoSelector);
  const listCategory = useSelector(listCategorySelector);

  const [data, setData] = useState([
    {
      categorySname: intl.formatMessage({
        id: "guideVideo.valueBtn.valueAll",
      }),
      id: "",
    },
  ]);

  useEffect(() => {
    if (listCategory && listCategory.length > 0) {
      const listNew = listCategory.map((item) => {
        return {
          categorySname: item.categorySname || "",
          id: item.id?.toString() || "",
        };
      });
      setData([
        {
          categorySname: intl.formatMessage({
            id: "guideVideo.valueBtn.valueAll",
          }),
          id: "",
        },
        ...listNew,
      ]);
    }
  }, [listCategory]);

  const clickbtn = (value: string) => {
    dispatch(changeButtonActiveGuideVideo(value));
  };

  const renderContent = () => {
    return data.map((e: any) => (
      <Button
        size="s"
        color={btnActive === e?.id ? "accept" : "gray"}
        onClick={() => clickbtn(e?.id)}
        // width="120px"
      >
        <div>{e?.categorySname}</div>
      </Button>
    ));
  };

  useEffect(() => {
    const param: IFindArticleCategorysParams = {
      articleType: activeTab === ActiveTabGuideVideo.TRAINING ? 1 : 2,
    };
    dispatch(findArticleCategorysRequest(param));
  }, [activeTab]);

  return <Wrap>{renderContent()}</Wrap>;
};

export default GroupBtn;
