import { createSelector } from "@reduxjs/toolkit";
import { FILTER_GROUP, FILTER_TYPE } from "modules/screeners/constants";
import {
  changeScreenerFilterList,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import { ISampleFilter } from "modules/screeners/types";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Legend, Tag, TagContainer, Wrapper } from "./styles";

const screenersSelector = (state: RootState) => state.screeners;

const sampleListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataFilter.sampleFilter.filtersList
);

const screenerListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.filterList
);

const FinancialIndicator: FC = () => {
  const dispatch = useDispatch();
  const sampleList = useSelector(sampleListSelector);
  const screenerList = useSelector(screenerListSelector);

  const sampleListFilter = useMemo(() => {
    return sampleList.filter(
      (filterItem) =>
        filterItem.group === FILTER_GROUP.TECHNICAL_INDICATOR &&
        filterItem.type === FILTER_TYPE.RADIO
    );
  }, [sampleList]);

  const screenersListFilter = useMemo(() => {
    return screenerList.filter(
      (filterItem) =>
        filterItem.groupCd === FILTER_GROUP.TECHNICAL_INDICATOR &&
        filterItem.type === FILTER_TYPE.RADIO
    );
  }, [screenerList]);

  const clickActiveTagHandler = useCallback((item: ISampleFilter) => {
    dispatch(changeScreenerFilterList(item));
  }, []);

  return (
    <Wrapper hide={sampleListFilter.length === 0}>
      <Legend>
        <FormattedMessage id="screeners.tabs.screener.technical.legend" />
      </Legend>
      <TagContainer>
        {sampleListFilter.map((item) => (
          <Tag
            key={item.key}
            title={item.name}
            isActive={
              !!screenersListFilter.find(
                (screenerItem) => screenerItem.key === item.key
              )
            }
            onClick={() => clickActiveTagHandler(item)}
          >
            {item.name}
          </Tag>
        ))}
      </TagContainer>
    </Wrapper>
  );
};

export default FinancialIndicator;
