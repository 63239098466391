import { Body, Container, Title, Footer } from "./styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";

interface Props {
  visibleModalConfirm: boolean;
  setVisibleModalConfirm: (visible: boolean) => void;
  setConfirm: (value: boolean) => void;
  title: string;
  content: string;
  handleCloseModal?: () => void;
}

function ModalConfirm({
  visibleModalConfirm,
  setVisibleModalConfirm,
  setConfirm,
  title,
  content,
  handleCloseModal,
}: Props) {
  const closeModalConfirm = () => {
    setVisibleModalConfirm(false);
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  const handleConfirm = () => {
    setConfirm(true);
    closeModalConfirm();
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-434"
      >
        <Container>
          <Title>{title.toUpperCase()}</Title>
          <Body>{content}</Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={handleConfirm}
              className="flex-1"
              fill
              color="gradientaAccept"
            >
              <FormattedMessage id="transferInternal.btnConfirm" />
            </Button>
          </Footer>
        </Container>
      </Modal>
    </>
  );
}

export default ModalConfirm;
