import styled from "styled-components/macro";
import { UIConst } from "helper/consts";

export const Info = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  border-radius: ${({ theme }) => theme.select.borderRadius};
  padding: 4px 8px;
  background: ${({ theme }) => theme.buttonGradientColor};
  svg {
    path {
      fill: ${({ theme }) => theme.textcolorWhite} !important;
    }
  }

  @media (max-width: ${UIConst.LAGRE_SCREEN_WIDTH}) {
    height: 24px;
  }
`;

export const UserName = styled.div`
  margin-top: 11px;
  float: right;
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  padding-top: 4px;
`;

export const MasterAcount = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
`;

export const Pro = styled.div`
  color: ${({ theme }) => theme.solidYellow};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  background-color: ${({ theme }) => theme.neutral5};
  padding: 0px 8px;
  border-radius: ${({ theme }) => theme.select.borderRadius};
  border: 2px solid ${({ theme }) => theme.solidYellow};
`;

export const Graph = styled.div`
  height: calc(100vh - 140px);
`;

export const HeaderCard = styled.div`
  height: 36px;
  @media (max-width: ${UIConst.LAGRE_SCREEN_WIDTH}) {
    height: 30px;
  }
  background-color: ${({ theme }) => theme.background2};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: relative;
  color: ${({ theme }) => theme.textcolorWhite};
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text-logo {
      margin-left: 5px;
      svg {
        path {
          fill: ${({ theme }) => theme.logo};
        }
      }
    }
  }

  .icon {
    svg {
      path {
        fill: ${({ theme }) => theme.grey40};
      }
    }
    :hover {
      cursor: pointer;
      svg {
        path {
          fill: ${({ theme }) => theme.brandPrimary};
        }
      }
    }
  }
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
`;

export const HeaderMidCard = styled.div`
  display: flex;
  width: 80%;
  overflow-y: scroll;
  div:last-child {
    margin-right: 0;
  }
  margin-right: 10px;
  height: 100%;
`;

export const HeaderRightCard = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 14px;
  .flex-1 {
    width: ${({ theme }) => theme.button.width};
  }
  .input-focus {
    width: 0px;
    height: 0px;
    margin: 0;
    padding: 0;
    border: none;
  }
  .pointer {
    svg {
      path {
        fill: ${({ theme }) => theme.grey40};
      }
    }
    :hover {
      cursor: pointer;
      svg {
        path {
          fill: ${({ theme }) => theme.brandPrimary};
        }
      }
    }
  }
`;

export const NotiCard = styled.div`
  width: 50px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const Content = styled.div<{
  isDisablePadding?: boolean;
  isSearch?: boolean;
}>`
  background-color: ${({ theme }) => theme.background2};
  color: ${({ theme }) => theme.grey0};
  min-width: 180px;
  display: flex;
  flex-direction: row;
  ${({ isSearch, theme }) =>
    isSearch &&
    `
    border: 1px solid ${theme.bgElevated3};
    width: 320px;
    position: relative;
    right: 50px;
  `}
  padding: ${({ isDisablePadding }) => (isDisablePadding ? "0px" : "8px")};
  .title {
    color: ${({ theme }) => theme.grey0};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  }
  :not(.languages) {
    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.background3};
      svg {
        path {
          fill: ${({ theme }) => theme.brandPrimary};
        }
      }
      .title {
        color: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
  .select-tickers {
    border-radius: ${({ theme }) => theme.select.borderRadius};
    border: 1px solid ${({ theme }) => theme.brandSecondary};
    height: 34px; // cao 32px + border viền 2px
    margin: 12px;
    min-width: 296px;
    display: flex;
    align-items: center;

    .input {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

export const Setting = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIndex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0;
  overflow-x: auto;
`;
