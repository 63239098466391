import { memo } from "react";
import { Select } from "components/commons";
import { components } from "react-select";
import { useIntl } from "react-intl";
import { getIntlOption } from "helper/intlOption";
import { ValueType } from "react-select";
import { OptionSelectType } from "../Select";
import { ReactComponent as ArrowDown } from "assets/image/svg/ic_select_down.svg";
import styled from "styled-components/macro";

export interface SelectFilterMarketWatchProps {
  selectedOption?: OptionSelectType;
  options: OptionSelectType[];
  onSelect: (option: OptionSelectType | undefined) => void;
}

export const DropdownWapper = styled(components.DropdownIndicator)<{}>`
  height: 32px;
  align-items: center;
`;

function SelectFilterMarketWatch({
  selectedOption,
  options,
  onSelect,
}: SelectFilterMarketWatchProps) {
  const handleChange = (option: ValueType<OptionSelectType, false>) => {
    if (!option) return onSelect(undefined);

    const changedOption = options.find((item) => item.value === option.value);

    onSelect(changedOption);
  };
  const intl = useIntl();

  const DropdownIndicator = (props: any) => {
    return (
      <DropdownWapper {...props}>
        <ArrowDown />
      </DropdownWapper>
    );
  };

  const selectOptions = options.map((option) => getIntlOption(option, intl));

  return (
    <Select
      className="flex-fill"
      value={selectOptions.filter(function (option) {
        return option.value === selectedOption?.value;
      })}
      options={selectOptions}
      onChange={handleChange}
      components={{ DropdownIndicator }}
      mediumSelect
    />
  );
}

export default memo(SelectFilterMarketWatch);
