import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, last } from "lodash";
import {
  IndexUpdate,
  LastSale,
  MarketIndexInfo,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";
import moment from "moment";
import { EMarketCodeNew } from "helper/consts";

export type HeaderCardState = {
  data: Dictionary<MarketIndexInfo.AsObject>;
  selectedIndex?: string;
  tickerDer: SymbolTotalInfo.AsObject;
};

const initialState: HeaderCardState = {
  data: {},
  tickerDer: new SymbolTotalInfo().toObject(),
};

const headerCardSlide = createSlice({
  name: "headerCard",
  initialState,
  reducers: {
    getDataMarketIndex: (state, action: PayloadAction<string>) => {
      let indexCdList = action.payload.split(",");
      indexCdList.forEach(
        (item) => (state.data[item] = new MarketIndexInfo().toObject())
      );
    },
    getDataMarketIndexSuccess: (
      state,
      action: PayloadAction<MarketIndexInfo.AsObject[]>
    ) => {
      action.payload.forEach((item) => {
        if (item.indexcd !== EMarketCodeNew.DER_IDX) {
          state.data[item.indexcd] = item;
        }
      });
    },
    indexUpdate: (state, action: PayloadAction<IndexUpdate.AsObject>) => {
      const indexCd = action.payload.indexcd.toString();
      const qtyMatched = action.payload.totalqty - state.data[indexCd].totalqty;

      Object.assign(state.data[indexCd], {
        marketindex: action.payload.index,
        changeindex: action.payload.changeindex,
        changepercent: action.payload.changepercent,
        totalqty: action.payload.totalqty,
        totalamt: action.payload.totalamt,
        state: action.payload.state,
        colorcode: action.payload.colorcode,
        advances: action.payload.advances,
        declenes: action.payload.declenes,
        nochange: action.payload.nochange,
      });

      const dataChart = state.data[indexCd].indextimeList;
      //push vào chart
      if (
        moment(last(dataChart)?.time || 0).get("minute") !==
        moment(action.payload.updtime).get("minute")
      ) {
        state.data[indexCd].indextimeList.push({
          time: action.payload.updtime,
          indexval: action.payload.index,
          qtyval: qtyMatched,
        });
      } else if (dataChart) {
        state.data[indexCd].indextimeList[
          state.data[indexCd].indextimeList.length - 1
        ].qtyval += qtyMatched;
      }
    },
    setVisibleIndexDetailsModal: (state, action: PayloadAction<string>) => {
      state.selectedIndex = action.payload;
    },
    closeVisibleIndexDetailsModal: (state) => {
      state.selectedIndex = undefined;
    },
    getDataTickerDerSuccess: (
      state,
      action: PayloadAction<SymbolTotalInfo.AsObject>
    ) => {
      state.tickerDer = action.payload;
      if (action.payload.secdetailinfo) {
        state.data[EMarketCodeNew.DER_IDX.toString()].state =
          action.payload.secdetailinfo.status;
      }
    },
    getDataTickerDerStart: (state, action: PayloadAction<string>) => {},
    getTickerDerChartUpdate: (
      state,
      action: PayloadAction<LastSale.AsObject>
    ) => {
      const dataChart = state.tickerDer.priceinfolistList;
      let lastDataChart = last(dataChart);
      //push vào chart
      if (
        moment(lastDataChart?.time || 0).get("minute") !==
        moment(action.payload.mattime, "Hmmss").get("minute")
      ) {
        state.tickerDer.priceinfolistList.push({
          time: moment(action.payload.mattime, "Hmmss").valueOf(),
          price: action.payload.lastprice,
          qty: action.payload.lastqty,
        });
      } else {
        state.tickerDer.priceinfolistList[
          state.tickerDer.priceinfolistList.length - 1
        ].qty += action.payload.lastqty;
      }
    },
  },
});

export const {
  getDataMarketIndex,
  getDataMarketIndexSuccess,
  indexUpdate,
  setVisibleIndexDetailsModal,
  closeVisibleIndexDetailsModal,
  getDataTickerDerSuccess,
  getDataTickerDerStart,
  getTickerDerChartUpdate,
} = headerCardSlide.actions;

export default headerCardSlide.reducer;
