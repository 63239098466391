import styled from "styled-components/macro";

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

export const WrapDeteil = styled.div`
  color: ${({ theme }) => theme.grey0};
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const CountDown = styled.span`
  color: ${({ theme }) => theme.brandPrimary};
`;

export const WrapInput = styled.span`
  width: 65%;
  display: flex;
  gap: 12px;
`;

export const Wraplabel = styled.div`
  color: ${({ theme }) => theme.grey0};
  margin-bottom: 4px;
`;
