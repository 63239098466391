import styled from "styled-components/macro";

export const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

// export const Container = styled.div<{
//   isVisibleSideCard: boolean;
// }>`
//   display: flex;
//   height: 100%;
//   flex-direction: column;
//   flex: 1;
//   border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
//   border: 2px solid ${({ theme }) => theme.bgElevated3};
//   overflow: hidden;
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
`;
export const ContentLeft = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: 30%;
  height: 100%;
`;
export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

export const LabelCell = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  font-size: 15px;
  color: ${({ theme }) => theme.grey0};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  height: 100vh;
  background: ${({ theme }) => theme.background1};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
  }
  gap: 0;
`;
