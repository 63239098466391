import classNames from "classnames";
import { TextColor } from "./style";
import numeral from "numeral";

interface Props {
  value: number;
  className?: string;
}

function ValueCell({ value, className }: Props) {
  return (
    <TextColor className={classNames(`${className} text-right`)}>
      {isNaN(value) ? "-" : numeral(value).format("0,000")}
    </TextColor>
  );
}

export default ValueCell;
