import { InfoAccount, ResponseData } from "domain/models/User";
import {
  getListing,
  initSession,
  initTrading,
  loginUrl,
  logoutUrl,
  refershTokenUrl,
  resetUrl,
  validateResetPasswordOtp,
} from "./urls";
import VgaiaHttp from "core/http/singleton/vgaia";
import LoginInterface from "domain/models/Login";
import {
  LoginParams,
  LogOutParams,
  ForgetPasswordParams,
  validateResetPasswordOtpCodeParams,
  RefreshTokenParams,
  InitTradingParams,
} from "./types";
import httpAccount from "../../singleton/account";
import httpAuth from "../../singleton/auth";
import qs from "qs";
import storage from "helper/storage";
import queryString from "query-string";

export const loginApi = async (data: LoginParams): Promise<LoginInterface> => {
  const res = await httpAuth.post<LoginInterface>(
    loginUrl,
    qs.stringify(data),
    {
      headers: {
        channel: "2",
        platform: "WEB",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const logOutApi = async (data: LogOutParams): Promise<number> => {
  const res = await httpAuth.post<number>(logoutUrl, qs.stringify(data));
  if (!res) throw new Error("Opps");
  return res.data;
};

export const postInitTrading = async (
  data: InitTradingParams
): Promise<ResponseData<InfoAccount>> => {
  const res = await httpAccount.post<ResponseData<InfoAccount>>(
    initTrading,
    qs.stringify(data),
    { headers: { channel: "2" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postInitSession = async (): Promise<any> => {
  const res = await httpAccount.post<any>(initSession);
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListingApi = async (): Promise<any> => {
  const res = await httpAccount.get<any>(
    getListing(storage.getMasterAccount())
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const forgetPasswordApi = async (
  data: ForgetPasswordParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(resetUrl, qs.stringify(data));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const validateResetPasswordOtpCodeApi = async (
  data: validateResetPasswordOtpCodeParams
): Promise<any> => {
  const res = await VgaiaHttp.post<any>(
    validateResetPasswordOtp,
    qs.stringify(data)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const refreshTokenApi = async (
  data: RefreshTokenParams
): Promise<any> => {
  const res = await httpAuth.post<any>(refershTokenUrl, qs.stringify(data));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const userChangePasswordApi = async (params: any): Promise<any> => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  const res = await httpAccount.post<{
    data: number;
    errorCode: string;
    errorField: string;
    message: string;
    statusCode: number;
  }>(`/UserChangePassword${paramsFormatter ? `?${paramsFormatter}` : ""}`, {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const UserChangePinCDApi = async (params: any): Promise<any> => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  const res = await httpAccount.post<{
    data: number;
    errorCode: string;
    errorField: string;
    message: string;
    statusCode: number;
  }>(`/UserChangePinCD${paramsFormatter ? `?${paramsFormatter}` : ""}`, {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
