import {
  searchBondOrderApi,
  getBondListApi,
  enterBondOrderApi,
  rejectBondOrderApi,
} from "./urls";
import {
  ISearchBondOrderResponse,
  IGetBondListResponse,
  IEnterBondOrderResponse,
  IRejectBondOrderResponse,
} from "domain/models/BidAskBond";

import {
  ISearchBondOrderParams,
  IGetBondListParams,
  IEnterBondOrderParams,
  IRejectBondOrderParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const searchBondOrder = async (
  params: ISearchBondOrderParams
): Promise<ISearchBondOrderResponse> => {
  const res = await VgaiaHttp.get<ISearchBondOrderResponse>(
    searchBondOrderApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getBondList = async (
  params: IGetBondListParams
): Promise<IGetBondListResponse> => {
  const res = await VgaiaHttp.post<IGetBondListResponse>(
    getBondListApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const enterBondOrder = async (
  params: IEnterBondOrderParams
): Promise<IEnterBondOrderResponse> => {
  const res = await VgaiaHttp.post<IEnterBondOrderResponse>(
    enterBondOrderApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const rejectBondOrder = async (
//   params: IRejectBondOrderParams
// ): Promise<IRejectBondOrderResponse> => {
//   const res = await VgaiaHttp.post<IRejectBondOrderResponse>(
//     rejectBondOrderApi(params)
//   );

//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };
export const rejectBondOrder = async (
  params: IRejectBondOrderParams
): Promise<IRejectBondOrderResponse> => {
  const res = await VgaiaHttp.post<IRejectBondOrderResponse>(
    rejectBondOrderApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
