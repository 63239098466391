import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const mrgContractDebitSelector = (state: RootState) =>
  state.mrgContractDebit;

export const mrgContractDebitListSelector = createSelector(
  mrgContractDebitSelector,
  (state) => state.list
);

export const mrgContractDebitLoadingSelector = createSelector(
  mrgContractDebitSelector,
  (state) => state.loading
);

export const mrgContractDebitFilterSelector = createSelector(
  mrgContractDebitSelector,
  (state) => state.filter
);

export const activeTabSelector = createSelector(
  mrgContractDebitSelector,
  (state) => state.activeTab
);
