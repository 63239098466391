/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { ReactComponent as IconCheckBoxSelected } from "assets/image/svg/ic_checkbox_selected.svg";
import { ReactComponent as IconCheckBoxUnselected } from "assets/image/svg/ic_checkbox_unselected.svg";
import { Button, Input, Select } from "components/commons";
import CommonTable from "components/commons/CommonTable";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import {
  InputContainer,
  InputWrapper,
} from "components/commons/FormStyle/Form/styles";
import { OptionSelectType } from "components/commons/Select";
import { toast } from "components/commons/Toast";
import {
  IInquiryAccountTransferSecParam,
  // ISubmitTransferSecParam,
} from "core/http/apis/transfer-share/types";
import { ISecTransferAvailRowData } from "domain/models/TransferShare";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { isEmpty, isEqual } from "lodash";
import ModalConfirm from "./modalConfirm";
import themes from "themes/abstracts/_themes";

import {
  changeAccountInSubmit,
  changeAccountOutSubmit,
  changeContentSubmit,
  changeEditRow,
  changeMaxWithDraw,
  changeSelectedAllRows,
  changeSelectedRows,
  clearStatus,
  getTransferShareClear,
  onLoadList,
  requestListTransferShareForm,
  requestSubmitTransferShareForm,
} from "modules/transfer-share/redux/transferForm";
import {
  isSelectAllRowsSelector,
  listDataTransferShareFromSelector,
  listTransferShareFormSelectRowsSelector,
  onLoadListSelector,
  statusSelector,
  submitListTransferShareFormParamsSelector,
} from "modules/transfer-share/selectors";
import numeral from "numeral";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { ReduxStateType } from "redux/types";
import {
  ButtonRow,
  ComboBox,
  ContainerForm,
  Footer,
  Header,
  InputRow,
  Label,
  TableContainer,
} from "./styles";
import { RootState } from "redux/store";
import { updateCurrentPage } from "modules/advance/redux";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

interface Props {
  initialValue: string;
  isCheckRow: boolean;
  id: number;
  handleBlurInputEdit: () => void;
  handleChangeMaxWithDraw: (text: string, id: number) => void;
  handleChecked: (
    ischecked: boolean,
    id: number,
    data: ISecTransferAvailRowData[]
  ) => void;
  isChecked: boolean;
  selectedOrders: ISecTransferAvailRowData[];
}

const EditableCell = ({
  initialValue,
  isCheckRow,
  id,
  handleBlurInputEdit,
  handleChangeMaxWithDraw,
  handleChecked,
  isChecked,
  selectedOrders,
}: Props) => {
  const [valueText, setValueText] = React.useState(initialValue);
  const { type: themeType } = useSelector((state: RootState) => state.theme);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueText(numeral(e.target.value).format("0,0"));
    handleChangeMaxWithDraw(e.target.value, id);
  };

  const onBlur = () => {
    handleBlurInputEdit();
  };

  React.useEffect(() => {
    setValueText(numeral(initialValue).format("0,0"));
    handleChangeMaxWithDraw(initialValue, id);
  }, [initialValue]);

  return (
    <input
      className="input-edit"
      placeholder={initialValue}
      value={isCheckRow ? valueText : ""}
      onChange={(e) => onChange(e)}
      onBlur={onBlur}
      disabled={!isChecked}
      style={{ color: themes[themeType].grey0 }}
    />
  );
};

const TransferShareForm: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(getTransferShareClear());
    };
  }, []);

  const status = useSelector(statusSelector);
  const data = useSelector(listDataTransferShareFromSelector);
  const { subAccoNoFrom, subAccoNoTo, remarks } = useSelector(
    submitListTransferShareFormParamsSelector
  );
  const selectedOrders = useSelector(listTransferShareFormSelectRowsSelector);
  const isCheckedAll = useSelector(isSelectAllRowsSelector);
  const isLoadList = useSelector(onLoadListSelector);
  const [isChangeContent, setIsChangeContent] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!isLoadList) return;
    dispatch(onLoadList(false));
    FilterDataTable();
  }, [isLoadList]);

  const FilterDataTable = (accountOutSelect?: string) => {
    if (parseOptionAccount.length > 0) {
      const params: IInquiryAccountTransferSecParam = {
        subAccoNoFrom: accountOutSelect
          ? accountOutSelect
          : subAccoNoFrom !== ""
          ? subAccoNoFrom
          : parseOptionAccount[0].value,
        subAccoNoTo: "",
      };
      dispatch(requestListTransferShareForm(params));
    }
  };

  const postTransferShare = () => {
    const errorMaxWidthdraw = selectedOrders.find(
      (item) => item.secTransferAvail === "0"
    );
    if (errorMaxWidthdraw) {
      toast("transferShare.transferForm.errorMaxWidthdraw", "error");
    } else {
      if (selectedOrders.length > 0) {
        const params = {
          subAccoNoFrom: subAccoNoFrom,
          subAccoNoTo: subAccoNoTo,
          subAccoCdFrom: 0,
          subAccoCdTo: 0, // 0
          remarks: remarks,
          otpCode: null, // Mã OTP
          dataList: selectedOrders.map((e: any) => ({
            secCd: e.secCd ?? "",
            quantity: numeral(e.secTransferAvail).value() || 1,
            secTransaction: e?.secTransaction,
            secTransferAvail: e?.secTransferAvail,
            marginRate: e?.marginRate,
          })),
        };
        dispatch(requestSubmitTransferShareForm(params));
        dispatch(updateCurrentPage(0));

        const resLogData = {
          eventGroup: GroupEvent.securities,
          event: RealtimeConst.postTransfer,
          message: JSON.stringify(params),
        };

        storage.commonPushLogFirebase(resLogData);
      } else {
        toast("transferShare.transferForm.errorCheckNone", "error");
      }
      setModal(false);
    }
  };

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );

  const listAccOptions: OptionSelectType[] = useMemo(() => {
    const newList: OptionSelectType[] = [];
    if (!isEmpty(listAcc)) {
      listAcc.forEach((item) =>
        newList.push({ label: item.subAccoNo, value: item.subAccoNo })
      );
    }
    return newList;
  }, [listAcc]);

  const listAccRef = useRef<OptionSelectType[]>([]);

  useEffect(() => {
    listAccRef.current = listAccOptions;
  }, []);

  const parseOptionAccount = useMemo(() => {
    if (!isEqual(listAccRef.current, listAccOptions)) {
      return listAccOptions;
    }
    return listAccRef.current;
  }, [listAcc]);

  // const currentAccount = useSelector(currentAccountSelector);

  useEffect(() => {
    if (parseOptionAccount.length > 0) {
      dispatch(changeAccountOutSubmit(parseOptionAccount?.[0]?.value || "ALL"));
      FilterDataTable(parseOptionAccount?.[0]?.value || "ALL");
    } else {
      dispatch(changeAccountOutSubmit(""));
    }
    if (parseOptionAccount.length > 1) {
      dispatch(
        changeAccountInSubmit(
          parseOptionAccount.filter(
            (item) => item.value !== (parseOptionAccount?.[0]?.value || "ALL")
          )?.[0]?.value
        )
      );
    } else {
      dispatch(changeAccountInSubmit(""));
    }
  }, [parseOptionAccount]);

  const handleChangeMaxWithDraw = (value: string, id: number) => {
    dispatch(changeEditRow({ text: value, id: id }));
  };

  const handleBlurInputEdit = () => {
    dispatch(changeMaxWithDraw());
  };

  const getCheckRow = (id: number) =>
    !!selectedOrders.find((item) => item.id === id);

  const flexStart = "flex-start";
  const flexEnd = "flex-end";
  const center = "center";

  const handleCheckedAll = (isCheckedAll: boolean) => {
    dispatch(changeSelectedAllRows(!isCheckedAll));
  };

  const handleChecked = (
    ischecked: boolean,
    id: number,
    data: ISecTransferAvailRowData[]
  ) => {
    dispatch(
      changeSelectedRows({
        isChecked: !ischecked,
        id: id,
        listTransferShare: data,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={center}>
            {isCheckedAll ? (
              <IconCheckBoxSelected
                onClick={() => handleCheckedAll(isCheckedAll)}
              />
            ) : (
              <IconCheckBoxUnselected
                onClick={() => handleCheckedAll(isCheckedAll)}
              />
            )}
          </TableHeaderCellContent>
        ),
        accessor: "id",
        minWidth: 25,
        disableSortBy: true,
        Cell: (props: any) => {
          const { value } = props;
          const checked = getCheckRow(value);
          return (
            <TableCellContent align={center}>
              {checked ? (
                <IconCheckBoxSelected
                  onClick={() => handleChecked(checked, value, selectedOrders)}
                />
              ) : (
                <IconCheckBoxUnselected
                  onClick={() => handleChecked(checked, value, selectedOrders)}
                />
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="transferShare.transferForm.table.shareCode" />
          </TableHeaderCellContent>
        ),
        minWidth: 45,
        accessor: "secCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      // {
      //   Header: (
      //     <TableHeaderCellContent align={flexStart}>
      //       <FormattedMessage id="transferShare.transferForm.table.statusName" />
      //     </TableHeaderCellContent>
      //   ),
      //   minWidth: 45,
      //   accessor: "statusName",
      //   Cell: (props: any) => {
      //     return (
      //       <TableCellContent align={flexStart}>{props.value}</TableCellContent>
      //     );
      //   },
      // },
      {
        Header: (
          <TableHeaderCellContent align={flexEnd}>
            <FormattedMessage id="transferShare.transferForm.table.shareBalance" />
          </TableHeaderCellContent>
        ),
        minWidth: 65,
        accessor: "secTransaction",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={flexEnd}>
              {numeral(currentRow?.secTransferAvail).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexEnd}>
            <FormattedMessage id="transferShare.transferForm.table.maxWithDraw" />
          </TableHeaderCellContent>
        ),
        minWidth: 105,
        accessor: "secTransferAvail",
        Cell: (props: CellProps<ISecTransferAvailRowData>) => {
          const id = props?.row?.original?.id;
          const checked = getCheckRow(id!);
          const initialValue = selectedOrders.find((item) => item.id === id);
          return (
            <TableCellContent align={flexEnd} isInput>
              <EditableCell
                initialValue={
                  initialValue
                    ? numeral(initialValue.secTransferAvail).format("0,0")
                    : numeral(props.value).format("0,0")
                }
                isCheckRow={getCheckRow(id!)}
                handleBlurInputEdit={handleBlurInputEdit}
                handleChangeMaxWithDraw={handleChangeMaxWithDraw}
                id={id!}
                handleChecked={handleChecked}
                isChecked={checked}
                selectedOrders={selectedOrders}
              />
            </TableCellContent>
          );
        },
      },
    ],
    [isCheckedAll, selectedOrders]
  );

  const handleChangeAccountOut = (value?: string) => {
    if (parseOptionAccount.length > 0) {
      dispatch(
        changeAccountOutSubmit(value ? value : parseOptionAccount[0].value)
      );
      dispatch(
        changeAccountInSubmit(
          parseOptionAccount.filter((item) => item.value !== value)[0].value
        )
      );
      dispatch(getTransferShareClear());
    }
  };

  const handleChangeAccountIn = (value?: string) => {
    dispatch(
      changeAccountInSubmit(value ? value : parseOptionAccount[1].value)
    );
    dispatch(getTransferShareClear());
  };

  const handleChangeContent = (value: string) => {
    dispatch(changeContentSubmit(value));
  };

  const accountOutChecked = parseOptionAccount.find(
    (item) => item.value === subAccoNoFrom
  );
  const accountInChecked = parseOptionAccount.find(
    (item) => item.value === subAccoNoTo
  );

  useEffect(() => {
    const text_content =
      intl.formatMessage({ id: "transferShare.transferForm.content_value1" }) +
      " " +
      subAccoNoFrom +
      " " +
      intl.formatMessage({ id: "transferShare.transferForm.content_value2" }) +
      " " +
      subAccoNoTo;
    dispatch(changeContentSubmit(text_content));
  }, [subAccoNoFrom, subAccoNoTo]);

  useEffect(() => {
    if (status === ReduxStateType.SUCCESS) {
      const contentDefault = intl.formatMessage(
        { id: "transferShare.transferForm.content_value" },
        {
          accountOut: subAccoNoFrom,
          accountIn: subAccoNoTo,
        }
      );
      dispatch(changeContentSubmit(contentDefault));
      dispatch(clearStatus());
    }
    if (status === ReduxStateType.ERROR) {
      dispatch(clearStatus());
    }
  }, [status]);

  const getContent = (content: string, isChangeContent: boolean) => {
    if (isChangeContent) {
      return content;
    } else {
      return intl.formatMessage(
        { id: "transferShare.transferForm.content_value" },
        {
          accountOut: subAccoNoFrom,
          accountIn: subAccoNoTo,
        }
      );
    }
  };

  return (
    <ContainerForm>
      <Header>
        <InputRow>
          <Label>
            <FormattedMessage id="transferShare.transferForm.accountOut" />
          </Label>
          <ComboBox>
            <Select
              className="flex-fill"
              value={
                accountOutChecked ? accountOutChecked : parseOptionAccount[0]
              }
              options={parseOptionAccount}
              onChange={(e) => {
                handleChangeAccountOut(e?.value);
                FilterDataTable(e?.value);
              }}
            />
          </ComboBox>
        </InputRow>

        <InputRow>
          <Label>
            <FormattedMessage id="transferShare.transferForm.accountIn" />
          </Label>
          <ComboBox>
            <Select
              className="flex-fill"
              value={
                accountInChecked
                  ? accountInChecked
                  : parseOptionAccount.filter(
                      (item) => item.value !== subAccoNoFrom
                    )[0]
              }
              options={
                subAccoNoFrom
                  ? parseOptionAccount.filter(
                      (item) => item.value !== subAccoNoFrom
                    )
                  : parseOptionAccount
              }
              onChange={(e) => handleChangeAccountIn(e?.value)}
            />
          </ComboBox>
        </InputRow>
      </Header>
      <TableContainer id="table-infinity-transfer-share-form">
        <CommonTable columns={columns} data={data} />
      </TableContainer>
      <Footer>
        <InputRow>
          <Label>
            <FormattedMessage id="transferShare.transferForm.content" />
          </Label>
          <ComboBox flex={8}>
            <InputWrapper className="flex-fill">
              <InputContainer isInvalid={false}>
                <Input
                  placeholder={intl.formatMessage({
                    id: "transferShare.transferForm.placeholder_content",
                  })}
                  value={getContent(remarks ?? "", isChangeContent)}
                  onChange={(e) => {
                    setIsChangeContent(true);
                    handleChangeContent(e.target.value);
                  }}
                />
              </InputContainer>
            </InputWrapper>
          </ComboBox>
        </InputRow>

        <ButtonRow className="d-flex button-wrapper">
          <Button
            color="accept"
            className="flex-fill mr-2 flex-1"
            onClick={() => {
              dispatch(getTransferShareClear());
            }}
          >
            {intl.formatMessage({
              id: "transferShare.transferForm.button.cancel",
            })}
          </Button>
          <Button
            color="gradientaAccept"
            className="flex-fill flex-1"
            fill={true}
            loading={status === ReduxStateType.LOADING}
            onClick={() => setModal(true)}
          >
            {intl.formatMessage({
              id: "transferShare.transferForm.button.confirm",
            })}
          </Button>
        </ButtonRow>
      </Footer>
      <ModalConfirm
        isOpen={modal}
        onClose={() => setModal(false)}
        onSuccess={() => postTransferShare()}
      />
    </ContainerForm>
  );
};

export default TransferShareForm;
