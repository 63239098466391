import VgaiaHttp from "core/http/singleton/vgaia";
import { ResponseData } from "domain/models/User";
// import qs from "qs";
import {
  findCbotBondPriceEstRequest,
  findCbotBondPriceEstItem,
  findDbondAdvRequest,
  findDbondAdvItem,
  updateDbondAdvDataList,
} from "./types";
import {
  urlCbotBondPriceEst,
  urlFindDbondAdv,
  urlUpdateDbondAdv,
} from "./urls";

export const getFindCbotBondPriceEst = async (
  data: findCbotBondPriceEstRequest
): Promise<ResponseData<findCbotBondPriceEstItem[]>> => {
  const res = await VgaiaHttp.get<ResponseData<findCbotBondPriceEstItem[]>>(
    urlCbotBondPriceEst,
    data
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getFindDbondAdv = async (
  data: findDbondAdvRequest
): Promise<ResponseData<findDbondAdvItem[]>> => {
  const res = await VgaiaHttp.get<ResponseData<findDbondAdvItem[]>>(
    urlFindDbondAdv,
    {
      filter: JSON.stringify(data),
    }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postUpdateDbondAdv = async (
  data: updateDbondAdvDataList[]
): Promise<ResponseData<any>> => {
  const res = await VgaiaHttp.post<ResponseData<any>>(urlUpdateDbondAdv, data, {
    headers: { "Content-Type": "application/json" },
  });
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
