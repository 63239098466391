import styled from "styled-components/macro";

export const OrderBookWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: stretch;
  justify-content: flex-start;
  .span-select-industry {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    color: ${({ theme }) => theme.grey30};
    vertical-align: middle;
    display: table-cell;
  }
  .order-book-header {
    background: ${({ theme }) => theme.background1};
  }
  .footer-content {
    position: sticky;
    bottom: 0;
    background: ${({ theme }) => theme.background2};
    padding: 8px 24px;
  }
`;

export const TickerCode = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  cursor: pointer;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TickerName = styled.div`
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
`;

export const StatusContainer = styled.div<{
  status: string | undefined;
  progress?: number;
}>`
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  white-space: nowrap;
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};

  ${({ status, theme, progress = 0 }) => {
    switch (status) {
      case "success":
        return `
          background: ${theme.green50};
          color: ${theme.textcolorWhite};
         `;
      case "reject":
        return `
          background: ${theme.red50};
          color: ${theme.textcolorWhite};
        `;
      case "reject-text-red":
        return `
          background: ${theme.brandPrimary};
          color: ${theme.red50};
        `;
      case "progress":
      case "edited":
        return `
          background: linear-gradient(90deg, ${theme.green50} ${progress}%, ${theme.brandPrimary} 0%);
          color: ${theme.textcolorWhite};
        `;
      default:
        return `
          background: ${theme.brandPrimary};
          color: ${theme.textcolorWhite};
        `;
    }
  }};
  border-radius: 4px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-transform: uppercase;
  & > div {
    align-items: center;
    justify-content: center;
  }
`;

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.background2};
  padding: 4px 12px;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
`;

export const Button = styled.button<{
  side: string | undefined;
}>`
  width: 120px;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${({ theme }) => theme.textcolorWhite};
  :hover {
    color: ${({ theme }) => theme.textcolorWhite};
  }
  ${({ side, theme }) => {
    switch (side) {
      case "buy": // success
        return `background: ${theme.green50}`;
      case "sell": // reject
        return `background: ${theme.red50}`;
      case "E": // reject
        return `background: ${theme.red50}`;
      case "EDIT":
        return `background: ${theme.brandPrimary}`;
      default:
        // pending
        return "display: none";
    }
  }};
`;

export const TextTradeType = styled.span<{
  side: string | undefined;
}>`
  text-transform: uppercase;
  ${({ side, theme }) => {
    switch (side) {
      case "orderBook.BUY":
      case "orderBook.BUY_OUTR":
        // success
        return `color: ${theme.green50}`;
      case "orderBook.SELL":
      case "orderBook.SELL_OutR":
      case "orderBook.SELL_TT68":
      case "orderBook.SELL_FORCE":
      case "orderBook.SELL_FORCE_OutR":
      case "orderBook.SELL_FORCE_TT68":
        // reject
        return `color: ${theme.red50}`;
      default:
        // pending
        return `color: ${theme.textcolorWhite}`;
    }
  }};
`;

export const SearchContainer = styled.div<{
  isShow: boolean;
}>`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  width: auto;
  background: ${({ theme }) => theme.background1};
  z-index: 1;
  margin: 0;
  padding: 4px 8px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  .flex-fill {
    max-width: ${({ theme }) => theme.input.width};
    & > div {
      min-height: unset !important;
      width: ${({ theme }) => theme.input.width};
    }
  }
`;

export const TableContainer = styled.div<{ heighFooter: number | undefined }>`
  height: 100%;
  overflow: auto;
  margin-bottom: 40px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  // Scroll Stiker - start
  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      thead {
        tr > th {
          top: 0px;
          position: sticky;
        }
      }
      tbody {
        tr > td {
          &:first-child {
            padding-right: 4px;
            padding-left: 12px;
          }
        }
      }
    }
  }
  // Scroll Stiker - end
`;

export const Control = styled.div<{
  align?: string | undefined;
  width?: string | undefined;
}>`
  padding: 4px 8px 4px 4px;
  width: ${({ width = "auto" }) => width};
  display: flex;
  align-items: center;
  justify-content: ${({ align = "left" }) =>
    align === "right" ? "flex-end" : "start"};
  &.tradeType {
    width: 200px;
  }
  &.status {
    width: 220px;
  }
  &.account {
    width: 260px;
  }
  .flex-fill-select {
    width: ${({ theme }) => theme.select.width};
    * {
      font-size: ${({ theme }) => theme.typeFaces.body.size};
    }
  }
`;

export const Label = styled.div<{
  size?: string | undefined;
  marginRight?: string | undefined;
}>`
  min-width: 77px;
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  margin-right: ${({ marginRight = "12px" }) => marginRight};
  white-space: nowrap;
`;

export const Scroll = styled.div<{
  width?: string | undefined;
  size?: string | undefined;
}>`
  width: ${({ width }) => width};
  overflow-y: unset;
  overflow-x: auto;
  overflow-anchor: none;

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border: 2px solid transparent;
    background-clip: content-box;
  }

  // size and active
  &::-webkit-scrollbar {
    width: ${({ size = "8px" }) => size};
    height: ${({ size = "8px" }) => size};
    display: block;
  }

  // background color
  &::-webkit-scrollbar-thumb {
    background: #3391ff47;
    border: 1px solid #3391ff57;
  }
`;

export const FilterIcon = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff4d;
  padding: 4px;
  :hover {
    background-color: #ffffff24;
  }
`;

export const ActionContainer = styled.div`
  position: absolute;
  height: 24px;
  right: 12px;
  bottom: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 4px;
  gap: 10px;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  border-radius: 50px;
  height: 20px;
  width: 20px;
`;

export const StatusCell = styled.div<{
  textColor?: string;
}>`
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.neutral1)};
`;
