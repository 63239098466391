import { Button, DatePicker } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import { searchTransferBondRequest } from "modules/place-order-transfer-bonds/redux";
import { loadingSearchSelector } from "modules/place-order-transfer-bonds/selectors";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { FilterWrapper, WrapBorder } from "./styles";

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector(loadingSearchSelector);

  const status = [
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.filter.all",
      })}`,
      value: "-1",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.filter.delay",
      })}`,
      value: "0",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.filter.accep",
      })}`,
      value: "1",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.filter.deny",
      })}`,
      value: "2",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderTransferBonds.filter.cancel",
      })}`,
      value: "9",
    },
  ];

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [newStatus, setNewStatus] = useState();

  const handleChangeFromDate = (value: any) => {
    setFromDate(value);
  };
  const handleChangeToDate = (value: any) => {
    setToDate(value);
  };

  const handleChangeStatus = (value: any) => {
    setNewStatus(value?.value);
  };

  useEffect(() => {
    setFromDate(new Date(fromDate?.setDate(toDate?.getDate() - 30)));
  }, []);

  const handleSearch = () => {
    if (moment(fromDate).isAfter(moment(toDate))) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderTransferBonds.placeBook.errorToDate",
        })}`,
        "error"
      );
    } else {
      dispatch(
        searchTransferBondRequest({
          fromDate: moment(fromDate).format("YYYYMMDD"),
          toDate: moment(toDate).format("YYYYMMDD"),
          status: newStatus || "-1",
        })
      );
    }
  };

  return (
    <WrapBorder className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "placeOrderTransferBonds.placeBook.fromDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={fromDate}
            onChange={handleChangeFromDate}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "placeOrderTransferBonds.placeBook.toDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={toDate}
            onChange={handleChangeToDate}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "placeOrderTransferBonds.placeBook.status",
          })}
          className="mr-4"
        >
          <CSelect
            className="flex-fill"
            options={status}
            onChange={handleChangeStatus}
            defaultValue={status?.[0]}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          onClick={handleSearch}
          loading={loading}
        >
          {intl.formatMessage({
            id: "placeOrderTransferBonds.placeBook.search",
          })}
        </Button>
      </FilterWrapper>
    </WrapBorder>
  );
}

export default memo(Filter);
