import styled from "styled-components/macro";

export const PopConfirmOrderContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.border1};
`;

export const PopConfirmOrderTitle = styled.div`
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  text-align: center;
  display: flex;
  align-items: center;
  height: 40px;
`;

export const PopConfirmOrderBody = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  padding: 0 24px;
  background-color: ${({ theme }) => theme.background2};
  height: 500px;

  .header {
    padding: 8px 0;
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.bold};
  }
  .body {
    padding-top: 24px;
    height: calc(100% - 75px);
  }
  .order-info {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.border1} 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 18px 1px;
    background-repeat: repeat-x;
    padding-bottom: 1rem;
    padding-top: 1rem;
    .value {
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }
  .text-bold {
    color: ${({ theme }) => theme.textcolorWhite};
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  }

  .text-small {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }
  .footer {
    display: flex;
    padding: 24px 0;

    .apply-btn {
      background-color: ${({ theme }) => theme.solidBlue};
    }
    .flex-1 {
      flex: 1;
    }
    .btn-success {
      background: ${({ theme }) => theme.green50};
    }
    .btn-danger {
      background: ${({ theme }) => theme.red50};
    }
  }
`;

export const ButtonExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  background: transparent;
  border: none;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
`;

export const TickerCode = styled.div<{ color?: string }>`
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, color }) =>
    color === "red"
      ? theme.red50
      : color === "yellow"
      ? theme.yellow50
      : theme.green50};
`;

export const Container = styled.div`
  height: calc(100% - 5px);
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: 100%;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }

  .loader {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
`;

export const Filter = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`;

export const Label = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  flex: 2;
`;
