import { FC, useState } from "react";
import { Wrap } from "../../styles";
import { FormattedMessage, useIntl } from "react-intl";
import Search from "./Search";
import Table from "./Table";
import moment from "moment";
import Modal from "./ConfirmModal";
import { toast } from "components/commons/Toast";
import {
  paramConfirm,
  updateOrderConfirmStatusArrApi,
  findOrderForConfirmApi,
} from "core/http/apis/confirmation";
import themes from "themes/abstracts/_themes";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { firstDay, dateNow } from "../../constants";

interface Form {
  confirmStatus: number;
  fromDate: Date;
  toDate: Date;
}

interface ContentModal {
  title: string;
  content: HTMLElement | null;
  lengthData: number;
}

const ProfitLoss: FC = () => {
  const intl = useIntl();
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setDoading] = useState<boolean>(false);
  const [dataSubmit, setDataSubmit] = useState<paramConfirm>({
    input: "",
  });
  const [data, setData] = useState([]);
  const [form, setForm] = useState<Form>({
    fromDate: firstDay,
    toDate: dateNow,
    confirmStatus: 0,
  });

  const [contentModal, setContentModal] = useState<ContentModal>({
    title: intl.formatMessage({ id: "confirmationOrder.modal.title" }),
    content: null,
    lengthData: 0,
  });
  const { type: themeType } = useSelector((state: RootState) => state.theme);

  const changeForm = (value: any, key: string) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const getDate = async () => {
    setDoading(true);
    try {
      const params = {
        ...form,
        fromDate: Number(moment(form.fromDate).format("YYYYMMDD")),
        toDate: Number(moment(form.toDate).format("YYYYMMDD")),
        tradeType: 0,
        secCd: "",
      };
      const res: any = await findOrderForConfirmApi(params);

      if (res.statusCode === 0) {
        const listData = res?.data?.map((e: any) => {
          const visibleCheckbox = e?.confirmStatus === 0 ? true : false;
          return {
            ...e,
            visibleCheckbox: visibleCheckbox,
          };
        });
        setData(listData);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
      setDoading(false);
    } catch (error) {
      setDoading(false);
    }
  };

  const clickSubmit = () => {
    setModal(!modal);
  };

  const SubmitData = async () => {
    if (dataSubmit.input === "") {
      clickSubmit();
      return toast("confirmationOrder.modal.messageError", "error");
    }
    const res: any = await updateOrderConfirmStatusArrApi(dataSubmit);
    if (res?.statusCode === 0) {
      toast("confirmationOrder.modal.messageSuccess", "success");
      getDate();
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
    setModal(false);
  };

  const changeDataSubmit = (array: any[]) => {
    setContentModal({
      ...contentModal,
      lengthData: array.filter((i: any) => i?.visibleCheckbox === true).length,
    });
    const param = array
      .filter((i: any) => i?.visibleCheckbox === true)
      .map((e: any) => `${e?.subAccoNo};${e?.orgOrderNo};${e?.tradeDate}`)
      .join(",");
    setDataSubmit({ input: param });
  };

  const setTextContentModal = () => {
    if (contentModal?.lengthData > 0)
      return intl.formatMessage({
        id: "confirmationOrder.modal.content",
      });
    return (
      <p style={{ color: themes[themeType].solidRed }}>
        <FormattedMessage id="confirmationOrder.modal.messageError" />
      </p>
    );
  };

  return (
    <Wrap>
      <Search
        form={form}
        changeForm={changeForm}
        submitForm={getDate}
        loading={loading}
      />
      <Table
        clickSubmit={clickSubmit}
        data={data}
        changeDataSubmit={changeDataSubmit}
      />
      <Modal
        visibleModalConfirm={modal}
        onClose={clickSubmit}
        setConfirm={SubmitData}
        title={
          contentModal?.lengthData > 0
            ? intl.formatMessage({ id: "confirmationOrder.modal.title" })
            : intl.formatMessage({
                id: "confirmationOrder.modal.titleError",
              })
        }
        content={setTextContentModal()}
        error={contentModal?.lengthData > 0 ? true : false}
      />
    </Wrap>
  );
};

export default ProfitLoss;
