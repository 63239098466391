import { StarContainer } from "./style";
import { getMarketLabel } from "helper/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectedTickerSelector } from "modules/market/redux/selectors";
// import WishListStar from "modules/shared/WishListStar";
import { useEffect } from "react";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { subscribeTickers } from "core/wss";
import {
  ForeignRoom,
  LastSale,
  SecQuotesDetailResponse,
} from "domain/protoNew/auto_trading_pb";
import {
  fetchOrganizationFundamental,
  updateLastSale,
  updateForeignRoom,
} from "modules/tickerDetails/redux/tickerInfo";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { createSelector } from "reselect";

const marketcdSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.marketcd
);
const secnameSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.secname
);

function TickerName() {
  const dispatch = useDispatch();
  const selectedTicker = useSelector(selectedTickerSelector);
  const marketcd = useSelector(marketcdSelector);
  const secname = useSelector(secnameSelector);

  useEffect(() => {
    if (selectedTicker) {
      subscribeTickers(selectedTicker);
      dispatch(fetchOrganizationFundamental(selectedTicker));
    }
    const eventListenerLastSale = (emittedData: LastSale.AsObject) => {
      dispatch(updateLastSale(emittedData));
    };
    const eventListenerForeignRoom = (emittedData: ForeignRoom.AsObject) => {
      if (emittedData.seccd === selectedTicker) {
        dispatch(updateForeignRoom(emittedData));
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM, eventListenerForeignRoom);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM,
        eventListenerForeignRoom
      );
    };
  }, [selectedTicker]);

  return (
    <div className="d-flex align-items-end">
      <div className="mr-2 code">{selectedTicker}</div>
      <div className="flex-fill name">
        {marketcd ? `(${getMarketLabel(parseInt(marketcd))})` : null}
        &nbsp;
        {selectedTicker ? secname : "-"}
      </div>

      {selectedTicker && (
        <StarContainer>
          {/* <WishListStar tickerName={selectedTicker} /> */}
        </StarContainer>
      )}
    </div>
  );
}

export default TickerName;
