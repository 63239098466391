import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryInfo, InfoBaseseccd, ORDER, SortTickerProps } from "../types";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import {
  LastSale,
  ProjectOpen,
  SymbolTotalInfo,
} from "domain/protoNew/auto_trading_pb";

import moment from "moment";
import { Dictionary, last } from "lodash";
import { TimeScroll, TypeScroll } from "../constant";

export type CategoriesInfoState = {
  info: CategoryInfo;
  infoBaseseccd: Dictionary<InfoBaseseccd>;
  tickerListDefault: string[];
  tickerList: string[];
  sortTickers: SortTickerProps;
  chartVisible: boolean;
  optionScroll: {
    typeScroll: TypeScroll;
    duration: number;
  };
};

const initialState: CategoriesInfoState = {
  info: {},
  infoBaseseccd: {},
  tickerListDefault: [],
  tickerList: [],
  sortTickers: {
    sortField: "",
    sortDirection: ORDER.UNKNOW,
  },
  chartVisible: false,
  optionScroll: {
    typeScroll: TypeScroll.NORMAL,
    duration: TimeScroll,
  },
};

function sortTickerList(
  tickers: string[],
  data: CategoryInfo,
  sortField: string,
  sortDirection: ORDER
) {
  let tickerListSorted = [...tickers];
  if (sortField !== "" && sortDirection !== ORDER.UNKNOW) {
    tickerListSorted = orderBy(data, [sortField], [sortDirection]).map(
      (tickerInfo) => tickerInfo.secdetailinfo?.seccd!
    );
  }
  return tickerListSorted;
}

const categoriesInfoSlice = createSlice({
  name: "categoriesInfoSlice",
  initialState,
  reducers: {
    updateTickerListTable: (state, action: PayloadAction<string[]>) => {
      const { sortField, sortDirection } = state.sortTickers;
      let listDefault = action.payload;
      state.tickerListDefault = listDefault;
      state.tickerList = sortTickerList(
        listDefault,
        state.info,
        sortField,
        sortDirection
      );
    },
    setCategoriesInfo: (
      state,
      action: PayloadAction<SymbolTotalInfo.AsObject[]>
    ) => {
      if (isEmpty(action.payload)) {
        return;
      }
      action.payload.forEach((el) => {
        const tickerCode = el.secdetailinfo?.seccd;
        if (!tickerCode) return;

        if (state.info[tickerCode]) {
          state.info[tickerCode] = {
            ...state.info[tickerCode],
            ...el,
          };
        } else {
          state.info[tickerCode] = el;
        }
      });
    },
    removeTickerInfo: (state, action: PayloadAction<string>) => {
      if (state.info[action.payload]) {
        delete state.info[action.payload];
      }
      return;
    },
    orderTickerListTable: (state, action: PayloadAction<SortTickerProps>) => {
      const { sortField, sortDirection } = action.payload;
      const { tickerListDefault, info } = state;
      state.sortTickers = action.payload;
      state.tickerList = sortTickerList(
        tickerListDefault,
        info,
        sortField,
        sortDirection
      );
    },
    resetTickerListTable: (state) => {
      state.info = {};
      state.sortTickers = {
        sortField: "",
        sortDirection: ORDER.UNKNOW,
      };
    },
    batchUpdateCategoriesInfo: (state, action: PayloadAction<any>) => {
      const tickerName = action.payload.seccd;
      if (state.info[tickerName]) {
        Object.assign(state.info[tickerName].secdetailinfo, action.payload);
        return;
      }
    },
    updateCategoriesInfoLastSale: (
      state,
      action: PayloadAction<LastSale.AsObject>
    ) => {
      const tickerName = action.payload.seccd;
      const batchTickerInfo = {
        changepoint: action.payload.changepoint,
        changepercent: action.payload.changepercent,
        lastprice: action.payload.lastprice,
        lastqty: action.payload.lastqty,
        totalqty: action.payload.totalqty,
        totalamt: action.payload.totalamt,
        highestprice: action.payload.hightprice,
        lowestprice: action.payload.lowprice,
        avgprice: action.payload.avgprice,
      };

      if (state.info[tickerName]) {
        Object.assign(state.info[tickerName].secdetailinfo, batchTickerInfo);
        state.info[tickerName].colorcode = action.payload.colorcode;
        if (state.chartVisible) {
          //ngày mới, clean chart
          const dataChart = state.info[tickerName].priceinfolistList;

          if (
            moment(last(dataChart)?.time || 0).get("dates") !==
            moment(action.payload.mattime, "Hmmss").get("dates")
          ) {
            state.info[tickerName].priceinfolistList = [
              {
                time: moment(action.payload.mattime, "Hmmss").valueOf(),
                price: action.payload.lastprice,
                qty: action.payload.lastqty,
              },
            ];
            return;
          }
          //push vào chart
          if (
            moment(last(dataChart)?.time || 0).get("minute") !==
            moment(action.payload.mattime, "Hmmss").get("minute")
          ) {
            state.info[tickerName].priceinfolistList.push({
              time: moment(action.payload.mattime, "Hmmss").valueOf(),
              price: action.payload.lastprice,
              qty: action.payload.lastqty,
            });
          }
        }
        return;
      }
      if (state.infoBaseseccd[tickerName]) {
        const batchBaseseccdInfo = {
          baseprice: action.payload.lastprice,
          basesecpricecolor: action.payload.colorcode,
        };
        Object.assign(state.infoBaseseccd[tickerName], batchBaseseccdInfo);
      }
    },
    updateCategoriesInfoProjectOpen: (
      state,
      action: PayloadAction<ProjectOpen.AsObject>
    ) => {
      const tickerName = action.payload.seccd;
      const batchTickerInfo = {
        changepoint: action.payload.changepoint,
        changepercent: action.payload.changepercent,
        lastprice: action.payload.price,
        lastqty: action.payload.qty,
      };

      if (state.info[tickerName]) {
        Object.assign(state.info[tickerName].secdetailinfo, batchTickerInfo);
        state.info[tickerName].colorcode = action.payload.colorcode;
        return;
      }
    },
    setChartVisible: (state, action: PayloadAction<boolean>) => {
      state.chartVisible = action.payload;
    },
    setInfoBaseseccd: (
      state,
      action: PayloadAction<Dictionary<InfoBaseseccd>>
    ) => {
      state.infoBaseseccd = action.payload;
    },
    setOptionScroll: (
      state,
      action: PayloadAction<{
        typeScroll: TypeScroll;
        duration: number;
      }>
    ) => {
      state.optionScroll = action.payload;
    },
  },
});

export const {
  setCategoriesInfo,
  updateTickerListTable,
  removeTickerInfo,
  orderTickerListTable,
  resetTickerListTable,
  batchUpdateCategoriesInfo,
  updateCategoriesInfoLastSale,
  updateCategoriesInfoProjectOpen,
  setChartVisible,
  setInfoBaseseccd,
  setOptionScroll,
} = categoriesInfoSlice.actions;

export default categoriesInfoSlice.reducer;
