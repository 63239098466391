import React from "react";
import { Row } from "./style";
import TimeCell from "./TimeCell";
import ValueCell from "./ValueCell";
import classNames from "classnames";
import PriceCell from "../Table/Body/TableBody/Row/PriceCell/Index";
import TickerCell from "./TickerCell/Index";
import VolumeCell from "../Table/Body/TableBody/Row/VolumeCell";
import { MrktMatchPtItem } from "domain/protoNew/auto_trading_pb";
import { stringColorTicker } from "helper/utils";

export type RowProps = {
  putThroughData: MrktMatchPtItem.AsObject;
  style: React.CSSProperties;
  rowNumber: number;
};

function RowStock({ putThroughData, style, rowNumber }: RowProps) {
  return (
    <Row style={style} isOddRowCount={rowNumber % 2 === 1}>
      <div className="ticker-div ticker-col">
        <TickerCell
          ticker={putThroughData.seccd}
          color={putThroughData.colorcode}
        />
      </div>
      <PriceCell
        color={stringColorTicker(putThroughData.colorcode)}
        price={putThroughData.matprice}
        className="price-col"
        isPreventSetCommand
      />
      <VolumeCell
        className="volume-col"
        volume={putThroughData.matqty}
        keepRawValue
      />

      <ValueCell
        value={putThroughData.matprice * putThroughData.matqty * 1000}
        className="transaction-value-col"
      />
      <TimeCell
        className={classNames("time-col", { "time-matched-col": true })}
        time={putThroughData.mattime}
      />
    </Row>
  );
}

export default RowStock;
