import styled from "styled-components/macro";

export const DetailWrapper = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  height: 100%;
  overflow: scroll;
`;

export const DetailCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

export const DetailRow = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowPaddingTB = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0px;
  @media (max-width: 1200px) {
    gap: 6px;
  }
`;
export const DetailRowPaddingTBModal = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0px;
`;
export const DetailFee = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0px;
  gap: 24px;
  @media (max-width: 1200px) {
    gap: 6px;
  }
  @media (max-width: 1785px) {
    gap: 3px;
  }
`;
export const DetailRowMarginTB = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 22px;
  padding: 7px 0px;
  @media (max-width: 1200px) {
    gap: 6px;
  }
`;

export const DetailRowPaddingBorder = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0px 16px 0px;
  border-bottom: 1px dashed #2b3030;
`;

export const DetailRowTextTop = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailLabel = styled.div`
  min-width: 120px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
export const DetailLabelModal = styled.div`
  min-width: 120px;
`;
export const DetailLabelFee = styled.div`
  min-width: 120px;
  @media (max-width: 1200px) {
    width: 50%;
    min-width: 10px;
  }
  @media (max-width: 900px) {
    min-width: 10px;
  }
`;

export const DetailInputWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;
export const DetailInput = styled.div`
  display: flex;
`;
export const DetailInputAccount = styled.div`
  color: ${({ theme }) => theme.grey0};
  flex: 1;
  display: flex;
  justify-content: right;
  @media (max-width: 1200px) {
    justify-content: left;
  }
`;
export const DetailInputRight = styled.div`
  color: ${({ theme }) => theme.grey0};
  flex: 1;
  display: flex;
  justify-content: right;
  text-align: right;
  margin-left: 16px;
`;
export const DetailLabelAlert = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  display: flex;
  align-items: center;

  color: #dd3640;
`;
export const DetailRowTop = styled.div`
  @media (max-width: 1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const DetailRowBottom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const DetailRowCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextCheckBox = styled.div`
  margin: 10px 5px 10px 10px;
`;
export const FlexItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1785px) {
    width: 100%;
  }
`;
