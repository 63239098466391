import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import { OrderBookDerRealTime } from "domain/models/OrderBookDer";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";
import numeral from "numeral";

const ordQtySelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) => state?.[realTimeId]?.ordQty
  );
};

interface Props {
  realTimeId: string;
}

const OrdQtyCell = ({ realTimeId }: Props) => {
  const ordQty = useSelector(ordQtySelector(realTimeId));
  const formatterValue = numeral(ordQty).format("0,0");
  return (
    <>
      <BlinkableCell value={formatterValue} />
    </>
  );
};

export default OrdQtyCell;
