import ChartComponent from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { RootState } from "redux/store";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import min from "lodash/min";
import max from "lodash/max";
import {
  INDEX_COLOR_CODEMap,
  MarketIndexInfo,
} from "domain/protoNew/auto_trading_pb";
import {
  DrawHorizontalLinePlugin,
  DerivativeLineColorPlugin,
} from "helper/chartPlugins";
import moment from "moment";
import { ChartData } from "modules/categories/types";
interface Props {
  referencePrice: number;
  dataChart: MarketIndexInfo.IndexTime.AsObject[];
  color: INDEX_COLOR_CODEMap[keyof INDEX_COLOR_CODEMap];
}
function ChartHeader({ referencePrice, color = 0, dataChart }: Props) {
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const plugins = [DrawHorizontalLinePlugin, DerivativeLineColorPlugin];
  const data: number[] = dataChart?.map((value) => value.indexval) || [];
  const options: ChartOptions = {
    plugins: {
      decimation: {
        algorithm: "lttb",
        enabled: true,
        samples: 10,
        threshold: 20,
      },
    },
    animation: {
      duration: 0, // general animation time
    },
    tooltips: {
      enabled: false,
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "H",
            },
          },
          ticks: {
            maxTicksLimit: 8,
            max: moment(dataChart?.[0]?.time)
              .hour(15)
              .minute(0)
              .second(0)
              .valueOf(), // chặn 9h đến 15h
            min: moment(dataChart?.[0]?.time)
              .hour(8)
              .minute(45)
              .second(0)
              .valueOf(),
            maxRotation: 0,
            minRotation: 0,
            fontSize: 9,
            fontColor: themes[themeType].grey20,
            callback: (value: number | string) => value + "h",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          id: "closedLine",
          ticks: {
            source: "labels",
            display: false,
            min: min([...data, referencePrice]) || 0 * 0.999,
            max: max([...data, referencePrice]) || 0 * 1.001,
          },
        },
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
          id: "volumeBar",
          position: "right",
          stacked: true,
        },
      ],
    },
  };

  const getDataChart = {
    labels: dataChart?.map((value) => value.time),
    thresholdValue: referencePrice,
    lineColor: {
      positive: themes[themeType].textColorGreen,
      negative: themes[themeType].textColorRed,
      vertical: themes[themeType].grey0,
      horizontal: themes[themeType].grey0,
    },
    datasets: [
      {
        data: data,
        pointRadius: 0,
        parsing: false,
        spanGaps: true,
        borderWidth: 1,
      },
      {
        barThickness: 1,
        grouped: true,
        barPercentage: 1,
        lineTension: 0,
        yAxisID: "volumeBar",
        data: dataChart?.map((value) => value.qtyval),
        backgroundColor: themes[themeType].overlayBlue30,
        type: "bar",
      },
    ],
  } as ChartData;
  return (
    <ChartComponent
      type="line"
      data={getDataChart}
      options={options}
      plugins={plugins}
    />
  );
}

export default ChartHeader;
