import classNames from "classnames";
import { Button, InputAutocompelete, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import { OptionSelectType } from "components/commons/Select";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  getInitBrokerRequest,
  searchMarginContractWatchRequest,
  updateFilter,
} from "modules/margin-contract-watch/redux";
import {
  brokerListSelector,
  marginContractWatchFilterSelector,
  marginContractWatchLoadingSelector,
} from "modules/margin-contract-watch/selectors";
import { ChangeEvent, memo, useEffect, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const statusOptionsIntl = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "marginContractWatch.filter.status.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "marginContractWatch.filter.status.status1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "marginContractWatch.filter.status.status2",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "marginContractWatch.filter.status.status3",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "marginContractWatch.filter.status.status4",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();
  const listAcc = GetAccoList(GetAccountType.MARGIN, EntrustBusinessCode.All);

  // selector
  const { accNo, brokerCd, status } = useSelector(
    marginContractWatchFilterSelector
  );
  const loading = useSelector(marginContractWatchLoadingSelector);
  const brokerList = useSelector(brokerListSelector);

  // memo
  const statusOptions = useMemo(() => statusOptionsIntl(intl), [intl]);
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    list.unshift({
      label: intl.formatMessage({ id: "marginContractWatch.filter.accNo.all" }),
      value: "",
    });

    return list;
  }, [listAcc, intl]);

  // function
  const changeStatusHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "status", value: e?.value ?? "0" }));
  };

  const changeAccountHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "accNo", value: e?.value ?? "" }));
  };

  const changeBrokerHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFilter({ key: "brokerCd", value: e?.target?.value ?? "" }));
  };

  const selectBrokerHandler = (value: string) => {
    dispatch(updateFilter({ key: "brokerCd", value: value ?? "" }));
  };

  const handleSearch = () => {
    dispatch(
      searchMarginContractWatchRequest({
        status: status ? +status : 0,
        accNo: accNo ?? "",
        brokerCd: brokerCd ?? "",
        branchCd: "",
      })
    );
  };

  // effect
  useEffect(() => {
    dispatch(getInitBrokerRequest());
  }, []);

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContractWatch.filter.status.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={statusOptions[0]}
            options={statusOptions}
            onChange={changeStatusHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContractWatch.filter.accNo.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={accountOptions[0]}
            options={accountOptions}
            onChange={changeAccountHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContractWatch.filter.brokerCd.label",
          })}
          className="mr-4"
        >
          <InputAutocompelete
            getItemValue={(item) => item.value}
            items={brokerList}
            renderItem={(item, isHighlighted) => (
              <div className={classNames({ isHighlighted })}>
                {`${item.value} - ${item.label}`}
              </div>
            )}
            shouldItemRender={(item, value) =>
              item.value.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            value={brokerCd}
            onChange={changeBrokerHandler}
            onSelect={selectBrokerHandler}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "marginContractWatch.filter.search" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
