import {
  TopPriceLeft,
  TopPriceRight,
} from "components/widgets/generals/TopPrice";
import TopForeign from "components/widgets/generals/TopForeign";
import TopStatistical from "components/widgets/generals/TopStatistical";
// import Recommendation from "components/widgets/generals/Recommendation";
import AdIndex from "components/widgets/generals/ADIndex";
import Netflow from "components/widgets/generals/Netflow";
import MarketWatch from "components/widgets/generals/MarketWatch";
import { Widget } from "components/widgets/types";
import ForeignWatch from "components/widgets/generals/ForeignWatch";
import Industry from "components/widgets/generals/Industry";

const MarketWidgets: Widget[] = [
  {
    name: "widgets.topPrice.left",
    component: TopPriceLeft,
  },
  {
    name: "widgets.topPrice.right",
    component: TopPriceRight,
  },
  {
    name: "widgets.topForeign.name",
    component: TopForeign,
  },
  {
    name: "widgets.topStatistical.name",
    component: TopStatistical,
  },
  // TODO
  // {
  //   name: "widgets.recommendation.name",
  //   component: Recommendation,
  // },
  {
    name: "widgets.adIndex.name",
    component: AdIndex,
  },
  {
    name: "widgets.netflow.name",
    component: Netflow,
  },
  {
    name: "widgets.marketWatch.name",
    component: MarketWatch,
  },

  {
    name: "widgets.foreignWatch.name",
    component: ForeignWatch,
  },
  {
    name: "industry.name",
    component: Industry,
  },
];

export default MarketWidgets;
