import {
  DerTradeTypeEnum,
  PlaceOrderDerForm,
  updatePlaceOrderDerForm,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { addWidgetName } from "modules/stock-order-der/redux/OrderBookDer";
import { Button } from "../../OrderBook/style";
import { handleUsingPriceSelected } from "modules/stock-order/redux/placeOrder";
import { getValueRound } from "helper/utils";
import { OrderBookDerCondDisplay } from "domain/models/OrderBookDer";
import { ConditionalCommandTypeEnum } from "../../PlaceOrderDer/ConditionalCommandType";
import { MasterAccount } from "domain/models/User";

interface Props {
  data: OrderBookDerCondDisplay;
}
const OrderBookDerCondRowAction: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();

  const onBuySellClick = (data: OrderBookDerCondDisplay) => {
    const account: MasterAccount = {
      custCd: data?.custCd!,
      custNo: data?.custNo!,
    };
    if (!account || !data || !data.secCd) {
      return;
    }
    dispatch(handleUsingPriceSelected(true));
    const formData: PlaceOrderDerForm = {
      currentTicker: data.secCd,
      type: data.tradeType === 2 ? DerTradeTypeEnum.L : DerTradeTypeEnum.S,
      price: data.isMarketOrder
        ? data.orderPriceDisp!
        : getValueRound(data.ordPrice!),
      volume: data.ordQty || 0,
      conditionalCommandType: {
        label: "",
        value: ConditionalCommandTypeEnum.Normal,
      },
      account: account,
      priceUp: data.priceUp,
      priceDown: data.priceDown,
      cutLostAmp: data.cutLostAmp,
      grossAmp: data.grossAmp,
      cutLostPrice: data.cutLostPrice,
      limitOffset: data.limitOffset,
      rangeStop: data.rangeStop,
      updatePrice: false,
    };
    dispatch(updatePlaceOrderDerForm(formData));
    dispatch(addWidgetName("widgets.placeOrderDer.name"));
  };

  return (
    <div className="btn_action_hover">
      <Button
        side={data.tradeType === 2 ? "buy" : "sell"}
        onClick={() => onBuySellClick(data)}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <FormattedMessage
          id={data.tradeType === 2 ? "common.longPlus" : "common.shortPlus"}
        />
      </Button>
    </div>
  );
};
export default OrderBookDerCondRowAction;
