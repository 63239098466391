import { Box } from "./styles";
import { ReactComponent as CheckBoxInActive } from "assets/image/svg/ic_checkbox_unselected.svg";
import { ReactComponent as CheckBoxActive } from "assets/image/svg/ic_checkbox_selected.svg";

interface RadioProps {
  active: boolean;
  onClick?: () => void;
}

function CheckBox({ active = false, onClick }: RadioProps) {
  const onPress = () => {
    onClick && onClick();
  };
  return (
    <Box onClick={onPress} active={active}>
      {active ? <CheckBoxActive /> : <CheckBoxInActive />}
    </Box>
  );
}

export default CheckBox;
