import { FC } from "react";
import { Wrap } from "components/commons/FormStyle/Table/styles";
import TableHistory from "./Table/TableHistory";
import FilterHistory from "./Filter/FilterHistory";

const TransferShareHistoryTable: FC = () => {
  return (
    <Wrap>
      <>
        <FilterHistory />
        <TableHistory />
      </>
    </Wrap>
  );
};
export default TransferShareHistoryTable;
