import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  listDeposit: any;
  subAccoNo: [];
  loading: boolean;
}

const initialState: ReduxData = {
  listDeposit: [],
  subAccoNo: [],
  loading: false,
};

const listDepositSlice = createSlice({
  name: "listDepositSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },

  reducers: {
    getListDepositRequest: (state, action: PayloadAction<any>) => {},
    getListDepositSuccess: (state, action: PayloadAction<any>) => {
      state.listDeposit = action.payload?.data;
    },
    getListDepositFailure: (state) => {
      state.loading = false;
    },

    searchDepositRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    searchDepositSuccess: (state, action: PayloadAction<any>) => {
      state.listDeposit = action.payload?.data;
      state.loading = false;
    },
    searchDepositFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getListDepositRequest,
  getListDepositSuccess,
  getListDepositFailure,
  searchDepositRequest,
  searchDepositSuccess,
  searchDepositFailure,
} = listDepositSlice.actions;

export default listDepositSlice.reducer;
