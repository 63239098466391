import { MrktMatchPtItem } from "domain/protoNew/auto_trading_pb";
import { columnsDataPutThrough } from "modules/categories/constant";
import { FormattedMessage } from "react-intl";
import GridRow from "./GridRowMatched";
import { MatchedHeader, TableWrappper } from "./style";

interface Props {
  putThroughDealList: MrktMatchPtItem.AsObject[];
}

function MatchedTable({ putThroughDealList }: Props) {
  return (
    <TableWrappper>
      <div className="header-wrapper">
        <div className="title">
          <FormattedMessage id="category.table.filled" />
        </div>
        <div className="header-content">
          {Object.keys(columnsDataPutThrough.MATCHED?.children!).map(
            (col, idx) => {
              const item = columnsDataPutThrough.MATCHED?.children![col];
              return (
                <MatchedHeader key={idx} minWidth={item.minWidth || 70}>
                  <FormattedMessage id={item.name} />
                </MatchedHeader>
              );
            }
          )}
        </div>
      </div>
      <div className="body-wrapper">
        <GridRow putThroughList={putThroughDealList} />
      </div>
    </TableWrappper>
  );
}

export default MatchedTable;
