import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const placeOrderBondAdvSelector = (state: RootState) =>
  state.placeOrderBondAdv;

export const listCbotBondPriceEstItemSelector = createSelector(
  placeOrderBondAdvSelector,
  (state) => state.listCbotBondPriceEstItem
);

export const searchParamsSelector = createSelector(
  placeOrderBondAdvSelector,
  (state) => state.searchParams
);

export const loadingSearchSelector = createSelector(
  placeOrderBondAdvSelector,
  (state) => state.loadingSearch
);

export const listDbondAdvItemSelector = createSelector(
  placeOrderBondAdvSelector,
  (state) => state.listDbondAdvItem
);

export const updateDbondAdvDataListSelector = createSelector(
  placeOrderBondAdvSelector,
  (state) => state.updateDbondAdvDataList
);

export const idCancelSelector = createSelector(
  placeOrderBondAdvSelector,
  (state) => state.idCancel
);
