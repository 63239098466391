import styled from "styled-components/macro";

export const NotificationGroupByDate = styled.div`
  padding: 16px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  &:last-child {
    box-shadow: none;
  }
`;

export const Date = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey40};
  margin-bottom: 12px;
`;
