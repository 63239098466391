import { createSelector } from "@reduxjs/toolkit";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
import { WatchlistGroupType } from "helper/consts";
import { getColorPrice } from "helper/utils";
import {
  categoriesInfoSelector,
  floorpriceSelector,
  ceilingpriceSelector,
  basicpriceSelector,
  tickersListSelectedSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import VolumeCell from "../VolumeCell";

interface Props {
  ticker: string;
  key: string;
  className: string;
  volumeName: keyof SymbolTotalInfo.SecDetailInfo.AsObject;
  priceName: keyof SymbolTotalInfo.SecDetailInfo.AsObject;
}

const volumeSelector = (
  ticker: string,
  volumeName: keyof SymbolTotalInfo.SecDetailInfo.AsObject
) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.[volumeName]
  );
};
const priceSelector = (
  ticker: string,
  priceName: keyof SymbolTotalInfo.SecDetailInfo.AsObject
) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.[priceName]
  );
};

function BestqtyCell({ ticker, key, className, volumeName, priceName }: Props) {
  const volume =
    (useSelector(volumeSelector(ticker, volumeName)) as number) || 0;
  const price =
    (useSelector(priceSelector(ticker, priceName)) as string | number) || 0;
  const floorprice = useSelector(floorpriceSelector(ticker)) || 0;
  const ceilingprice = useSelector(ceilingpriceSelector(ticker)) || 0;
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;
  const { type } = useSelector(tickersListSelectedSelector);
  return (
    <VolumeCell
      key={key}
      className={className}
      volume={volume}
      color={getColorPrice(price, floorprice, ceilingprice, basicprice)}
      keepRawValue={type === WatchlistGroupType.ODDLOT}
    />
  );
}

export default BestqtyCell;
