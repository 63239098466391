import { TrackingApiType } from "helper/consts";

// UTILITIES ===================================
export const SHOW_WATCHLIST_CONSOLE =
  process.env.REACT_APP_SHOW_WATCHLIST_CONSOLE === "true" ? true : false;

export const USE_REDUX_LOG =
  process.env.REACT_APP_ENABLE_REDUX_LOG === `true` ? true : false;

export const TOP_PRICE_LOADING_LIMIT = Number(
  process.env.REACT_APP_TOP_PRICE_LOADING_LIMIT
);

// UTILITIES ===================================

// GRPC ========================================

export const GRPC_URL = process.env.REACT_APP_GRPC_URL;
export const GRPC_PORT = process.env.REACT_APP_GRPC_PORT;

export const GRPC_WATCHLIST_URL = process.env.REACT_APP_GRPC_WATCHLIST_URL;
export const GRPC_WATCHLIST_PORT = process.env.REACT_APP_GRPC_WATCHLIST_PORT;

export const GRPC_SCREENERS_URL = process.env.REACT_APP_GRPC_SCREENERS_URL;
export const GRPC_SCREENERS_PORT = process.env.REACT_APP_GRPC_SCREENERS_PORT;
// GRPC ========================================

// REST ========================================
export const TRADING_VIEW_URL = process.env.REACT_APP_TRADING_VIEW_URL;

export const VAPID_KEY = process.env.REACT_APP_FIREBASE_TOKEN_NOTIFICATION;
export const FIREBASE_apiKey = process.env.REACT_APP_FIREBASE_apiKey;
export const FIREBASE_authDomain = process.env.REACT_APP_FIREBASE_authDomain;
export const FIREBASE_databaseURL = process.env.REACT_APP_FIREBASE_databaseURL;
export const FIREBASE_projectId = process.env.REACT_APP_FIREBASE_projectId;
export const FIREBASE_storageBucket =
  process.env.REACT_APP_FIREBASE_storageBucket;
export const FIREBASE_messagingSenderId =
  process.env.REACT_APP_FIREBASE_messagingSenderId;
export const REACT_APP_FIREBASE_appId = process.env.REACT_APP_FIREBASE_appId;
export const FIREBASE_measurementId =
  process.env.REACT_APP_FIREBASE_measurementId;

export const RESTFUL_AUTH_URL = process.env.REACT_APP_RESTFUL_AUTH_URL || "";

export const RESTFUL_ACCOUNT_URL =
  process.env.REACT_APP_RESTFUL_ACCOUNT_URL || "";

export const RESTFUL_ACCOUNT_CUSTOMER_URL =
  process.env.REACT_APP_RESTFUL_CUSTOMER_URL || "";

export const RESTFUL_APP_RESTFUL_BASE_URL =
  process.env.REACT_APP_RESTFUL_BASE_URL || "";
export const RESTFUL_ORDER_EXEC_URL =
  process.env.REACT_APP_RESTFUL_ORDER_EXEC_URL || "";
export const RESTFUL_ORDER_URL = process.env.REACT_APP_RESTFUL_ORDER_URL || "";
export const RESTFUL_PASSWORD_URL =
  process.env.REACT_APP_RESTFUL_PASSWORD_URL || "";

export const RESTFUL_BOND_URL = process.env.REACT_APP_RESTFUL_BOND_URL || "";

export const RESTFUL_TRADE_INFO_URL =
  process.env.REACT_APP_RESTFUL_TRADE_INFO_URL || "";

export const RESTFUL_TRANS_URL = process.env.REACT_APP_RESTFUL_TRANS_URL || "";

export const RESTFUL_TWO_FACTOR_URL =
  process.env.REACT_APP_RESTFUL_TWO_FACTOR_URL || "";

export const RESTFUL_CP24_URL = process.env.REACT_APP_RESTFUL_CP24_URL || "";
export const RESTFUL_TRADINGVIEW_SESSION_URL =
  process.env.REACT_APP_RESTFUL_TRADINGVIEW_SESSION_URL || "";
export const RESTFUL_ORDER_BASKET_URL =
  process.env.REACT_APP_RESTFUL_ORDER_BASKET_URL || "";

// VGAIA
export const REACT_APP_RESTFUL_MARGIN_CONTRACT_URL =
  process.env.REACT_APP_RESTFUL_MARGIN_CONTRACT_URL || "";
export const REACT_APP_RESTFUL_VGAIA_URL =
  process.env.REACT_APP_RESTFUL_VGAIA_URL || "";

export const REACT_APP_RESTFUL_RIGHT_INFO_URL =
  process.env.REACT_APP_RESTFUL_RIGHT_INFO_URL || "";

export const REACT_APP_RESTFUL_ARTICLE_URL =
  process.env.REACT_APP_RESTFUL_ARTICLE_URL || "";

// REST ========================================

// MQTT ========================================
export const MQTT_URL = process.env.REACT_APP_MQTT_URL;
export const MQTT_PORT = Number(process.env.REACT_APP_MQTT_PORT);
export const MQTT_USER = process.env.REACT_APP_MQTT_USER;
export const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD;
// MQTT ========================================

//WSS =================
export const WSS_URL = process.env.REACT_APP_WSS_URL || "";
export const WSS_ORDER_URL = process.env.REACT_APP_WSS_ORDER_URL || "";

//Link MBS
export const RiskLinkVN = process.env.REACT_APP_RiskLinkVN;
export const RiskLinkEN = process.env.REACT_APP_RiskLinkEN;
export const ContactLinkVN = process.env.REACT_APP_ContactLinkVN;
export const ContactLinkEN = process.env.REACT_APP_ContactLinkEN;
export const TutorialLinkVN = process.env.REACT_APP_TutorialLinkVN;
export const TutorialLinkEN = process.env.REACT_APP_TutorialLinkEN;
export const OnlineServiceLinkVN = process.env.REACT_APP_OnlineServiceLinkVN;
export const OnlineServiceLinkEN = process.env.REACT_APP_OnlineServiceLinkEN;
export const LinkModal_VSD = process.env.REACT_APP_VSD;
export const LinkModal_Sell = process.env.REACT_APP_MODALSELL;

// WATCHLIST REALTIME_INTERVAL

const watchlistInterval =
  process.env.REACT_APP_WATCHLIST_BATCH_UPDATE_INTERVAL || "10";
export const WATCHLIST_BATCH_UPDATE_INTERVAL = parseInt(watchlistInterval, 10);

// Time countDown
export const TimeCountDown = parseInt(
  process.env.REACT_APP_TIME_COUNTDOWN || "60"
);

//mã GA
export const CodeGA = process.env.REACT_APP_CODE_GA || "";

// Time delay cancel order
export const TimeDelayCancelOrder = parseInt(
  process.env.REACT_APP_TIME_DELAY_CANCEL_ORDER || "2"
);

//time nháy
export const BlinkTime = parseInt(
  process.env.REACT_APP_TIME_BLINKABLECELL || "500"
);

export const IsEnablePhase2 =
  process.env.REACT_APP_IS_ENABLE_PHASE_2 === "true" ? true : false;

//màu nháy F1
export const BlinkColor = "solidFlash";

//time call api trước khi hết hạn
export const TimeBeforeExpire = parseInt(
  process.env.REACT_APP_TIME_BEFORE_EXPIRE || "30"
);

export const VersionWeb = process.env.REACT_APP_VERSION_WEB || "v.1.0";

export const MaximunTabFlexlayout =
  process.env.REACT_APP_MAXIMUN_TAB_FLEXLAYOUT || "5";

export const AuthenCodeGrpc =
  process.env.REACT_APP_AUTHEN_CODE_GRPC || "vgaia-front-market-api";

export const LoginRequired =
  process.env.REACT_APP_LOGIN_REQUIRED === "true" ? true : false;

export const TrackingApi =
  process.env.REACT_APP_TrackingApiType || TrackingApiType.restful;
