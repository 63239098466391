import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  min-height: 600px;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;

export const Title = styled.div`
  height: 40px;
  padding: 8px;
  text-align: center;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  background: ${({ theme }) => theme.background3};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  .flex-1 {
    flex: 1;
  }
`;

export const Body = styled.div<{ padding?: number }>`
  padding: ${({ padding }) => padding}px;
  background-color: ${({ theme }) => theme.grey70};
  min-height: 560px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Menu = styled.div`
  width: 216px;
  height: 560px;
  background-color: ${({ theme }) => theme.background3};
`;

export const Content = styled.div`
  width: calc(100% - 216px);
  height: 560px;
  background-color: ${({ theme }) => theme.background2};
`;

export const ItemMenu = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
  height: 44px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.brandPrimary : theme.textcolorWhite};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.background4 : theme.background3};

  :hover {
    background-color: ${({ theme }) => theme.background4};
    color: ${({ theme }) => theme.brandPrimary};
  }
`;

export const ButtonExit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const TitleContent = styled.div`
  flex: 3;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite} !important;
  text-align: center;
`;
