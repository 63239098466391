import { StyledTooltip, TextColor, Wrapper } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconDelete } from "assets/image/svg/ic_remove.svg";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  isDeleteTickerCategoriesSelector,
  currentListCodeCategoriesSelector,
  colorcodeSelector,
  hasRightExecTickerCategoriesInfoSelector,
} from "modules/categories/redux/selectors";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { WATCH_LIST_FIXED_TABS } from "modules/categories/constant";
import { TickerCode } from "components/commons/ClickableTicker/styles";
import { setVisibleTickerDetailsModal } from "modules/market/redux";
import { removeTicker } from "modules/categories/redux";
import { EMarketCodeNew, SecType } from "helper/consts";
import GlobalData from "helper/globalData";

interface Props {
  ticker: string;
}

const SHORT_TICKER_MAX_LENGTH = 6;

const renderModalType = (tickerType?: number, tickerMarketCd?: number) => {
  if (tickerMarketCd && tickerMarketCd === EMarketCodeNew.DER_IDX) {
    return "derivative";
  } else {
    if (tickerType === SecType.CW) {
      return "coverWarrant";
    } else {
      return "default";
    }
  }
};

function TickerCell({ ticker }: Props) {
  const colorcode = useSelector(colorcodeSelector(ticker));
  const isDeleteTicker = useSelector(isDeleteTickerCategoriesSelector);
  const currentListCode = useSelector(currentListCodeCategoriesSelector);
  const hasRightExec = useSelector(
    hasRightExecTickerCategoriesInfoSelector(ticker)
  );
  const dispatch = useDispatch();

  const isLongTicker =
    (!!ticker && ticker.length > SHORT_TICKER_MAX_LENGTH) ||
    currentListCode === WATCH_LIST_FIXED_TABS.COVER_WARRANT;

  const handleClickTicker = (ticker: string) => {
    const tickerInfo = GlobalData.getTickerInfoNew(ticker);
    const tickerType = tickerInfo?.sectype;
    const tickerMarketCd = tickerInfo?.marketcd;
    dispatch(
      setVisibleTickerDetailsModal({
        visible: true,
        ticker,
        modalType: renderModalType(tickerType, tickerMarketCd),
      })
    );
  };

  const handleRemoveTicker = () => {
    dispatch(removeTicker({ tickerName: ticker }));
  };

  return (
    <Wrapper isLongTicker={isLongTicker}>
      <OverlayTrigger
        placement="bottom-start"
        overlay={
          <StyledTooltip id="tooltip">
            <FormattedMessage id="categories.ticketTooltip" />
          </StyledTooltip>
        }
      >
        <TextColor
          className={classNames("text-right ticker-name")}
          textcolor={colorcode}
        >
          <TickerCode
            className="ticker-code"
            onClick={() => handleClickTicker(ticker)}
          >
            <span>
              {ticker}
              {hasRightExec === 1 && " *"}
            </span>
          </TickerCode>
        </TextColor>
      </OverlayTrigger>
      {isDeleteTicker && (
        <div className="delete-icon-container">
          <IconDelete className="icon-delete" onClick={handleRemoveTicker} />
        </div>
      )}
    </Wrapper>
  );
}

export default TickerCell;
