import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button } from "components/commons";

import { updateModalStatusRequest } from "modules/professional-investor/redux";
import {
  getProfessionalInvestorSelector,
  isShowConfirmModalSelector,
} from "modules/professional-investor/selectors";
import { accountInfoSelector } from "modules/auth/selectors";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
} from "./styles";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { convertNumberToDateFormat } from "helper/utils";
import { useHistory } from "react-router-dom";
function ConfirmModal() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const themeType = useSelector(themeTypeSelector);
  const history = useHistory();
  const isVisible = useSelector(isShowConfirmModalSelector);
  const professionalInvestor = useSelector(getProfessionalInvestorSelector);
  const { currentUser } = useSelector(accountInfoSelector);
  const closeModalHandler = () => {
    history.goBack();
    dispatch(updateModalStatusRequest({ isVisible: false }));
  };
  function getColor(value: string, value2: number) {
    if (professionalInvestor) {
      if (
        (value2 === 1 || value2 === 0 || value2 === 2 || value2 === 3) &&
        professionalInvestor.id !== 0
      ) {
        return (
          <TextCell color={themes[themeType].green50}>
            {" "}
            <FormattedMessage id="professionalInvestor.statusList.registered" />
          </TextCell>
        );
      } else if (
        value2 === 4 ||
        value2 === 9 ||
        professionalInvestor.id === 0
      ) {
        return (
          <TextCell color={themes[themeType].red50}>
            {" "}
            <FormattedMessage id="professionalInvestor.statusList.unRegistered" />
          </TextCell>
        );
      }
    }

    return value;
  }

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="professionalInvestor.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="professionalInvestor.userId" />
            </LabelCell>
            <TextCell>
              {currentUser && currentUser.userId ? currentUser.userId : ""}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="professionalInvestor.name" />
            </LabelCell>
            <TextCell>
              {currentUser && currentUser.displayName
                ? currentUser.displayName
                : ""}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="professionalInvestor.status" />
            </LabelCell>

            {professionalInvestor &&
              getColor(
                intl.formatMessage({
                  id:
                    "professionalInvestor.statusList." +
                    professionalInvestor.status,
                }),
                professionalInvestor.status
              )}
          </BodyModalRow>
          {professionalInvestor !== null &&
            professionalInvestor !== undefined &&
            [0, 1, 2, 3].includes(professionalInvestor.status) &&
            professionalInvestor.id !== 0 && (
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="professionalInvestor.expiredDate" />
                </LabelCell>
                <TextCell>
                  {convertNumberToDateFormat({
                    date:
                      professionalInvestor && professionalInvestor.expiredDate
                        ? professionalInvestor.expiredDate
                        : 0,
                  })}
                </TextCell>
              </BodyModalRow>
            )}

          <ActionsRow>
            <ButtonCell>
              <Button
                onClick={closeModalHandler}
                className="button-confirm"
                style={{
                  background: `${themes[themeType].buttonGradientColor}`,
                  fontSize: "15px",
                  padding: "18px 0px",
                }}
                fill={true}
                width="100%"
              >
                <FormattedMessage id="professionalInvestor.cancel" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default ConfirmModal;
