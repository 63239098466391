import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";

interface IBuyInfor {
  bondCd: any;
  period: any;
  qty: string;
  subAccoNo: string;
}

export interface ReduxData {
  bondCdList: object | any;
  bondCdListFull: object | any;
  listPeriod: any;
  rBonOrder: any;
  listOrderBookBondCd: any;
  listOrderBookBondCdInfo: any;
  cbotGetInterest: any;
  buyingPower: number;
  bondInfo: any;
  buyInfor: IBuyInfor;
  listOrderBook: any;
  loadingSearch: boolean;
  calInvestmentValue: any;
}

const initialState: ReduxData = {
  bondCdList: [{ label: "", value: "" }],
  bondCdListFull: [{}],
  listPeriod: [{ label: "", value: "" }],
  listOrderBookBondCd: [],
  listOrderBookBondCdInfo: [],
  rBonOrder: [],
  cbotGetInterest: "",
  buyingPower: 0,
  bondInfo: {},
  buyInfor: {
    bondCd: { label: "", value: "" },
    period: { label: "", value: "" },
    qty: "",
    subAccoNo: "",
  },
  listOrderBook: [],
  loadingSearch: false,
  calInvestmentValue: "",
};

const placeOrderRetailBondsSlice = createSlice({
  name: "placeOrderRetailBondsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    //thông tin mua
    buyInfor: (
      state,
      action: PayloadAction<{ key: keyof IBuyInfor; value: any }>
    ) => {
      state.buyInfor = {
        ...state.buyInfor,
        [action.payload.key]: action.payload.value,
      };
    },

    //lấy danh sách mã trái phiếu
    findCbotProductRetailRequest: (state, action: PayloadAction<any>) => {},
    findCbotProductRetailSuccess: (state, action: PayloadAction<any>) => {
      const data = action.payload?.data?.map((item: any) => {
        return {
          label: item?.bondCd,
          value: item?.bondCd,
        };
      });
      state.bondCdList = data;
      state.bondCdListFull = action.payload?.data;
      state.listOrderBookBondCd = action?.payload?.data;
    },
    findCbotProductRetailFailure: (state) => {},

    //lấy dữ liệu để tính giá trị nhận được
    cbotGetInterestRequest: (state, action: PayloadAction<any>) => {},
    cbotGetInterestSuccess: (state, action: PayloadAction<any>) => {
      state.cbotGetInterest = action?.payload?.data;
    },
    cbotGetInterestFailure: (state) => {},

    //lấy thông tin sức mua
    inquiryBuyingPowerRequest: (state, action: PayloadAction<any>) => {},
    inquiryBuyingPowerSuccess: (state, action: PayloadAction<any>) => {
      state.buyingPower = action?.payload?.data?.buyingPower;
    },
    inquiryBuyingPowerFailure: (state) => {},

    //lấy thông tin mã trái phiếu của tài khoản
    findCbotBondInfoRequest: (state, action: PayloadAction<any>) => {},
    findCbotBondInfoSuccess: (state, action: PayloadAction<any>) => {
      state.bondInfo = action?.payload?.data?.[0];
    },
    findCbotBondInfoFailure: (state) => {},

    //lấy danh sách sổ lệnh
    getListOrderBookRequest: (state, action: PayloadAction<any>) => {},
    getListOrderBookSuccess: (state, action: PayloadAction<any>) => {
      state.listOrderBook = action?.payload?.data;
      state.listOrderBookBondCdInfo = action?.payload?.data;
    },
    getListOrderBookFailure: (state) => {},

    //tìm kiếm danh sách sổ lệnh
    searchListOrderBookRequest: (state, action: PayloadAction<any>) => {
      state.loadingSearch = true;
    },
    searchListOrderBookSuccess: (state, action: PayloadAction<any>) => {
      state.listOrderBook = action?.payload?.data;
      state.loadingSearch = false;
    },
    searchListOrderBookFailure: (state) => {
      state.loadingSearch = false;
    },

    updateBondCdInfo: (state, action: PayloadAction<any>) => {
      state.bondInfo = action?.payload?.bondInfo;
      state.cbotGetInterest = "";
    },

    updateListPeriod: (state, action: PayloadAction<any>) => {
      state.listPeriod = action?.payload?.listPeriod;
    },

    calInvestmentValue: (state, action: PayloadAction<any>) => {
      state.calInvestmentValue = action?.payload?.calInvestmentValue;
    },
  },
});

export const {
  buyInfor,

  findCbotProductRetailRequest,
  findCbotProductRetailSuccess,
  findCbotProductRetailFailure,

  cbotGetInterestRequest,
  cbotGetInterestSuccess,
  cbotGetInterestFailure,

  inquiryBuyingPowerRequest,
  inquiryBuyingPowerSuccess,
  inquiryBuyingPowerFailure,

  findCbotBondInfoRequest,
  findCbotBondInfoSuccess,
  findCbotBondInfoFailure,

  getListOrderBookRequest,
  getListOrderBookSuccess,
  getListOrderBookFailure,

  searchListOrderBookRequest,
  searchListOrderBookSuccess,
  searchListOrderBookFailure,

  updateBondCdInfo,
  updateListPeriod,

  calInvestmentValue,
} = placeOrderRetailBondsSlice.actions;

export default placeOrderRetailBondsSlice.reducer;
