import { Select, Button } from "components/commons";
import { memo, useMemo, useEffect } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  searchBondOrderRequest,
  findCbotBondPriceEstRequest,
} from "modules/bid-ask-bond/redux";
import {
  loadingSearchDataSelector,
  filterBondOrderSelector,
  listCbotBondPriceEstSelector,
} from "modules/bid-ask-bond/selectors";
import { tradeDateSelector } from "modules/auth/selectors";
import { ISearchBondOrderParams } from "core/http/apis/bid-ask-bond/types";

import storage from "helper/storage";
import InputSymbol from "./InputSymbol";
import { CbotBondPriceEst } from "domain/models/BidAskBondOrder";

const statusOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "bidAskBond.filter.status.all",
    }),
  },
  {
    value: "0",
    label: intl.formatMessage({
      id: "bidAskBond.filter.status.wait_approve",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "bidAskBond.filter.status.confirmed",
    }),
  },

  {
    value: "2",
    label: intl.formatMessage({
      id: "bidAskBond.filter.status.complete",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "bidAskBond.filter.status.cancel",
    }),
  },
];
const tradeOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "bidAskBond.filter.tradeList.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "bidAskBond.filter.tradeList.sell",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "bidAskBond.filter.tradeList.buy",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { tradeType, status, secCdBond } = useSelector(filterBondOrderSelector);

  const loading = useSelector(loadingSearchDataSelector);
  const listCbotBondPriceEst = useSelector(listCbotBondPriceEstSelector);

  let temp: CbotBondPriceEst[] = listCbotBondPriceEst.map((item) => {
    return item;
  });
  temp.unshift({
    id: 123123,
    delCd: 0,
    tradeDate: 0,
    bondCd: intl.formatMessage({
      id: "bidAskBond.all",
    }),
    bondName: intl.formatMessage({
      id: "bidAskBond.all",
    }),
    priceEst: 0,
    couponsLastDate: 0,
    couponsRate: 0,
    calculate: 0,
    decimalPoint: 0,
    roundType: 0,
    faceValPrice: 0,
    remarks: "",
    approveDateTime: 0,
    approveUserId: 0,
    regDateTime: 0,
    regUserId: "",
    updDateTime: 0,
    updUserId: "",
    dueDate: 0,
  });

  const tradeOptions = useMemo(() => tradeOption(intl), [intl]);
  const tradeDate = useSelector(tradeDateSelector);
  const statusOptions = useMemo(() => statusOption(intl), [intl]);
  // const listAcc = GetAccoList(GetAccountType.BOND, EntrustBusinessCode.All);
  // const listAccFilter = listAcc.filter((i) => {
  //   return i.subAccoNo.includes(storage.getMasterAccount());
  // });

  useEffect(() => {
    dispatch(
      updateFilter({
        key: "status",
        value: statusOptions ? +statusOptions[0].value : -1,
      })
    );
    dispatch(
      updateFilter({
        key: "tradeType",
        value: tradeOptions ? +tradeOptions[0].value : 0,
      })
    );
    dispatch(
      findCbotBondPriceEstRequest({
        tradeDate: tradeDate ?? "",
        bondCd: null,
      })
    );
    dispatch(
      updateFilter({
        key: "secCdBond",
        value: intl.formatMessage({
          id: "bidAskBond.all",
        }),
      })
    );
  }, []);

  const handleSearch = () => {
    const submitData: ISearchBondOrderParams = {
      tradeType: tradeType
        ? tradeType
        : +tradeOptions[0].value
        ? +tradeOptions[0].value
        : 0,
      status:
        status !== null && status !== undefined
          ? status
          : +statusOptions[0].value
          ? +statusOptions[0].value
          : -1,
      type: 2,
      getDataType: 2,
      custNo: storage.getMasterAccount() ?? "",
      bondCd:
        secCdBond ===
        intl.formatMessage({
          id: "bidAskBond.all",
        })
          ? ""
          : secCdBond
          ? secCdBond
          : "",
    };
    dispatch(searchBondOrderRequest(submitData));
  };

  // effect

  // render
  return (
    <Wrap
      className="d-flex justify-content-between align-items-center"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "bidAskBond.filter.bondCd",
          })}
          className="marginRight text-grey"
        >
          <div className="text-white">
            <InputSymbol
              list={temp}
              value={secCdBond}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "secCdBond",
                    value: e,
                  })
                );
              }}
            />
          </div>
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "bidAskBond.filter.tradeType",
          })}
          className="marginRight text-grey"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={tradeOptions[0]}
              options={tradeOptions}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "tradeType",
                    value: e?.value,
                  })
                );
              }}
            />
          </div>
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "bidAskBond.filter.status.label",
          })}
          className="marginRight2 text-grey"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              defaultValue={statusOptions[0]}
              options={statusOptions}
              onChange={(e) => {
                dispatch(
                  updateFilter({
                    key: "status",
                    value: e?.value,
                  })
                );
              }}
            />
          </div>
        </FilterItem>
        <Button
          color="accept"
          className="button-filter1 mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "bidAskBond.filter.search",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
