import queryString from "query-string";

export const getPositionOpenApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDerTPosition${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getPositionCloseApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertPositionClose${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
