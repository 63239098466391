import {
  getDBondOrderApi,
  getDBondPriceApi,
  findCbotDBondInfoDetailApi,
  placeDBondOrderApi,
  inquiryBuyingPowerApi,
  cancelDBondOrderApi,
} from "./urls";

import {
  IGetDBondOrderResponse,
  IGetDBondPriceResponse,
  IFindCbotDBondInfoDetailResponse,
  IPlaceDBondOrderResponse,
  IInquiryBuyingPowerResponse,
  ICancelDBondOrderResponse,
} from "domain/models/BidAskDBondOrder";

import {
  IGetDBondOrderParams,
  IGetDBondPriceParams,
  IFindCbotDBondInfoDetailParams,
  IPlaceDBondOrderParams,
  IInquiryBuyingPowerParams,
  ICancelDBondOrderParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const getDBondOrder = async (
  params: IGetDBondOrderParams
): Promise<IGetDBondOrderResponse> => {
  const res = await VgaiaHttp.get<IGetDBondOrderResponse>(
    getDBondOrderApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getDBondPrice = async (
  params: IGetDBondPriceParams
): Promise<IGetDBondPriceResponse> => {
  const res = await VgaiaHttp.get<IGetDBondPriceResponse>(
    getDBondPriceApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findCbotDBondInfoDetail = async (
  params: IFindCbotDBondInfoDetailParams
): Promise<IFindCbotDBondInfoDetailResponse> => {
  const res = await VgaiaHttp.get<IFindCbotDBondInfoDetailResponse>(
    findCbotDBondInfoDetailApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const placeDBondOrder = async (
  params: IPlaceDBondOrderParams
): Promise<IPlaceDBondOrderResponse> => {
  const res = await VgaiaHttp.post<IPlaceDBondOrderResponse>(
    placeDBondOrderApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const inquiryBuyingPower = async (
  params: IInquiryBuyingPowerParams
): Promise<IInquiryBuyingPowerResponse> => {
  const res = await VgaiaHttp.get<IInquiryBuyingPowerResponse>(
    inquiryBuyingPowerApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const cancelDBondOrder = async (
  params: ICancelDBondOrderParams
): Promise<ICancelDBondOrderResponse> => {
  const res = await VgaiaHttp.post<ICancelDBondOrderResponse>(
    cancelDBondOrderApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
