import { memo } from "react";
import { MasterAcount, Info } from "./styles";
import Storage from "helper/storage";
import { ReactComponent as ArrowDown } from "assets/image/svg/ic_arrow_down_fill_white.svg";
import usePageTracking from "./usePageTracking";

function Profile() {
  usePageTracking();
  const masterAccount = Storage.getMasterAccount();

  return (
    <Info>
      <div>
        <MasterAcount>{masterAccount}</MasterAcount>
        {/* <UserName>
          {(accountInfo?.currentCustomer?.custFamilyName || "") +
            " " +
            (accountInfo?.currentCustomer?.custGivenName || "")}
        </UserName> */}
      </div>
      <div>
        <ArrowDown />
      </div>
    </Info>
  );
}
export default memo(Profile);
