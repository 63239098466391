import { FC } from "react";
import Search from "./search";
import TableData from "./table";
// import { FormattedMessage } from "react-intl";

const ContentData: FC = () => {
  return (
    <>
      <Search />
      <TableData />
    </>
  );
};

export default ContentData;
