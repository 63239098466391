import React from "react";
import { ListType } from "modules/categories/types";
import { TableBody as StyledTableBody } from "../../style";
import Grid from "./Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  columnsSelector,
  getAllListSelector,
  selectedIndustryTypeSelector,
  selectedListedTypeSelector,
  loadingCategorySelector,
  currentListCodeCategoriesSelector,
  userFavoriteListCategoriesSelector,
  selectedTypeDerivativeSelector,
  selectedOddLotTypeSelector,
  anonymousFavoriteListSelector,
} from "modules/categories/redux/selectors";
import { getTickersList } from "modules/categories/helpers/tableUtils";
import { updateTickerListTable } from "modules/categories/redux/categoriesInfo";
import { useEffectOnce, useWindowSize } from "react-use";
import { startListen, stopListen } from "./realtime";
import { changeLoading } from "modules/categories/redux";
import { formatTableAttribute } from "modules/categories/helpers/utils";
import { isAuthenticatedSelector } from "modules/auth/selectors";

function TableBody() {
  const dispatch = useDispatch();
  const columns = useSelector(columnsSelector);
  const { width } = useWindowSize();
  const tableAttribute = () => {
    if (width > 1919) {
      return formatTableAttribute(columns, "minWidth");
    } else {
      return formatTableAttribute(columns, "smallDeviceMinWidth");
    }
  };
  const currentListCode = useSelector(currentListCodeCategoriesSelector);
  const userFavoriteList = useSelector(userFavoriteListCategoriesSelector);
  const anonymousFavoriteList = useSelector(anonymousFavoriteListSelector);
  const selectedListedType = useSelector(selectedListedTypeSelector);
  const selectedTypeDerivative = useSelector(selectedTypeDerivativeSelector);
  const selectedIndustryType = useSelector(selectedIndustryTypeSelector);
  const selectedOddLotType = useSelector(selectedOddLotTypeSelector);
  const allList: ListType[] = useSelector(getAllListSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const loading = useSelector(loadingCategorySelector);
  let list: ListType[];
  if (isAuthenticated) {
    list = [...allList, ...userFavoriteList];
  } else {
    list = [...allList, ...anonymousFavoriteList];
  }
  let tickers = getTickersList(
    list,
    currentListCode,
    selectedListedType,
    selectedIndustryType,
    selectedTypeDerivative,
    selectedOddLotType
  );

  const tickerListTable = tickers?.listTickers ?? [];
  dispatch(updateTickerListTable(tickerListTable));
  useEffectOnce(() => {
    startListen();
    return () => {
      dispatch(changeLoading(true));
      stopListen();
    };
  });

  return (
    <StyledTableBody>
      {!loading && <Grid tableAttribute={tableAttribute()} columns={columns} />}
    </StyledTableBody>
  );
}

export default React.memo(TableBody);
