import { Button, CheckBox, Input } from "components/commons";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import { changeLimit } from "core/http/apis/change-limit";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { getLimitAccountRequest } from "modules/change-limit/redux";
import { getAccount, loadingSelector } from "modules/change-limit/selectors";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Body,
  BorderBox,
  ColorBlue,
  Container,
  DetailRowCheckBox,
  Footer,
  Icon,
  ItemLeft,
  ItemRight,
  SelectBox,
  TextCheckBox,
  Title,
  TitleContainer,
  WrapItem,
} from "./styles";
import { GetParameters } from "helper/sessionData";
import { ReactComponent as IconCancel } from "assets/image/svg/ic_cancel.svg";

interface Props {
  visibleModalConfirm?: boolean;
  title?: string;
  content?: string;
  handleCloseModal?: () => void;
  size?: any;
}

function ModalNotification({
  visibleModalConfirm,
  title,
  handleCloseModal,
  size,
}: Props) {
  const intl = useIntl();

  const dispatch = useDispatch();

  const loading = useSelector(loadingSelector);
  const account = useSelector(getAccount);

  const [checked, setChecked] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [currentLimit, setCurrentLimit] = useState(0);
  const link = GetParameters("SYST", "TERM_CONDITION_MAR_LIMIT_URL");

  const listAcc = GetAccoList(GetAccountType.MARGIN, EntrustBusinessCode.All);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const handleChangeAccount = (value: any) => {
    dispatch(
      getLimitAccountRequest({
        subAccoNo: value?.value,
      })
    );
    setNewValue("");
  };

  const handleChangeRequireLimit = (e: any) => {
    const valueFormat = e.target.value.replaceAll(",", "");
    setNewValue(valueFormat);
  };

  const closeModalConfirm = () => {
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  const handleConfirm = async () => {
    if (newValue === "") {
      toast(
        `${intl.formatMessage({
          id: "changeLimit.modal.errorLimit",
        })}`,
        "error"
      );
    } else {
      const response = await changeLimit({
        alloDate: account?.alloDate ? account?.alloDate : 0,
        refNo: account?.refNo ? account?.refNo : 0,
        marginLimit: +numeral(newValue).format("00"),
        lastUpdateTime: account?.lastUpdateTime ? account?.lastUpdateTime : "",
      });
      if (response?.statusCode === 0) {
        toast(
          `${intl.formatMessage({
            id: "changeLimit.modal.done",
          })}`,
          "success"
        );
        setNewValue("");
        setChecked(false);
        dispatch(
          getLimitAccountRequest({
            subAccoNo: parseOptionAccount?.[0]?.value,
          })
        );
      } else {
        toast(
          `${intl.formatMessage({
            id: "changeLimit.modal.failure",
          })}`,
          "error"
        );
      }
    }
  };

  const calRequireLimit = useMemo(() => {
    if (+newValue > 0) {
      return numeral(newValue).format("0,0");
    } else {
      return "";
    }
  }, [newValue]);

  useEffect(() => {
    dispatch(
      getLimitAccountRequest({
        subAccoNo: parseOptionAccount?.[0]?.value,
      })
    );
  }, []);

  useEffect(() => {
    setCurrentLimit(account?.marginLimit);
  }, [account]);

  return (
    <Modal
      show={visibleModalConfirm}
      centered
      backdrop="static"
      size={size}
      keyboard={false}
      dialogClassName="modal-434"
    >
      <Container>
        <TitleContainer>
          <Title>{title}</Title>
          <Icon onClick={closeModalConfirm}>
            <IconCancel width={16} height={16} />
          </Icon>
        </TitleContainer>
        <BorderBox>
          <Body>
            <WrapItem>
              <ItemLeft>
                <FormattedMessage id="changeLimit.modal.subAccountNo"></FormattedMessage>
              </ItemLeft>
              <SelectBox>
                <CSelect
                  defaultValue={parseOptionAccount?.[0]}
                  options={parseOptionAccount}
                  onChange={handleChangeAccount}
                ></CSelect>
              </SelectBox>
            </WrapItem>
            <WrapItem>
              <ItemLeft>
                <FormattedMessage id="changeLimit.modal.currentLimit"></FormattedMessage>
              </ItemLeft>
              <ItemRight>
                {parseOptionAccount?.[0]?.value
                  ? numeral(currentLimit).format("0,0")
                  : 0}
              </ItemRight>
            </WrapItem>
            <WrapItem>
              <ItemLeft>
                <FormattedMessage id="changeLimit.modal.requireLimit"></FormattedMessage>
              </ItemLeft>
              <SelectBox>
                <Input
                  value={newValue ? calRequireLimit : ""}
                  onChange={handleChangeRequireLimit}
                ></Input>
              </SelectBox>
            </WrapItem>
            <DetailRowCheckBox>
              <CheckBox
                checked={checked}
                onChange={() => setChecked(!checked)}
              ></CheckBox>
              <TextCheckBox>
                {intl.formatMessage({
                  id: "changeLimit.modal.textCheckBoxBefore",
                })}
                <ColorBlue onClick={() => window.open(link ?? "")}>
                  {intl.formatMessage({
                    id: "changeLimit.modal.textCheckBoxMiddle",
                  })}
                </ColorBlue>
                {intl.formatMessage({
                  id: "changeLimit.modal.textCheckBoxAfter",
                })}
              </TextCheckBox>
            </DetailRowCheckBox>
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="changeLimit.modal.cancel" />
            </Button>
            <Button
              color="accept"
              className="flex-1"
              onClick={handleConfirm}
              disabled={checked === true ? false : true}
              fill={true}
              loading={loading}
            >
              <FormattedMessage id="changeLimit.modal.submit" />
            </Button>
          </Footer>
        </BorderBox>
      </Container>
    </Modal>
  );
}

export default ModalNotification;
