import { Body, Container, ItemCol, Title, Icon, RowScroll } from "./styles";
import { Button, Spacer, Switch, NumberInput } from "components/commons";
import { memo, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ReactComponent as IconReset } from "assets/image/svg/ic_reset.svg";
import { ReactComponent as IconEditColumn } from "assets/image/svg/ic_edit_column.svg";
import {
  changeColumn,
  changeVisibleModalEditCol,
  synchronizedTableWithCurrentListCode,
} from "modules/categories/redux";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch, useSelector } from "react-redux";
import { ColManage, ModalEditColProps } from "modules/categories/types";
import {
  columnsDataDefault,
  TimeScroll,
  TypeScroll,
} from "modules/categories/constant";
import { useIntl, FormattedMessage } from "react-intl";
import { RootState } from "redux/store";
import { optionScrollF1Selector } from "modules/categories/redux/selectors";
import { setOptionScroll } from "modules/categories/redux/categoriesInfo";
import { toast } from "components/commons/Toast";

const visibleModalEditColSelector = (state: RootState) =>
  state.categories.root.visibleModalEditCol;

function ModalEditCol({ columns }: ModalEditColProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const visibleModalEditCol = useSelector(visibleModalEditColSelector);
  const [columnsState, setColumnsState] = useState<ColManage>();
  const { typeScroll, duration } = useSelector(optionScrollF1Selector);
  const [typeScrollState, setTypeScrollState] = useState<TypeScroll>(
    TypeScroll.NORMAL
  );
  const [durationState, setDurationState] = useState<number>(TimeScroll);

  useEffect(() => {
    setColumnsState(cloneDeep(columns));
  }, [columns]);

  const renderItem = (columns?: ColManage) => {
    if (!columns) {
      return;
    }
    return Object.keys(columns)
      .sort(
        (a, b) => columns[a]?.settingOrderIndex - columns[b]?.settingOrderIndex
      )
      .map((item, index) => {
        const col = columns[item];
        if ((!col.name && !col.children) || col.fixed) return null;
        return (
          <ItemCol hasParent={!!col?.parent} key={col.name || `group-${index}`}>
            {col.name && (
              <div style={{ width: 170 }}>
                <Form.Group controlId={`${col.name}-checkbox`}>
                  <Form.Check
                    type="checkbox"
                    checked={col?.visible || col?.disable}
                    onChange={() => {
                      col.visible = !col.visible;
                      setColumnsState({ ...columnsState });
                    }}
                    label={intl.formatMessage({ id: col.name })}
                    disabled={col.disable}
                  />
                </Form.Group>
              </div>
            )}
            {col.children && renderItem(col.children)}
          </ItemCol>
        );
      });
  };

  const toggleModal = () =>
    dispatch(changeVisibleModalEditCol(!visibleModalEditCol));

  const closeModal = () => {
    toggleModal();
    setColumnsState(cloneDeep(columns));
    setTypeScrollState(typeScroll);
    setDurationState(duration);
  };

  const reset = () => {
    let columnsResetData = cloneDeep(columnsDataDefault);
    columnsResetData.CHART.visible = columns.CHART.visible;
    setColumnsState(cloneDeep(columnsResetData));
    setTypeScrollState(TypeScroll.NORMAL);
    setDurationState(TimeScroll);
  };

  const apply = () => {
    if (
      (typeof durationState !== "number" || durationState < 500) &&
      typeScrollState === TypeScroll.INFINITY
    )
      return toast("category.modal.duration500", "error");
    toggleModal();
    if (typeScrollState !== typeScroll || durationState !== duration) {
      if (typeScroll !== TypeScroll.NORMAL) {
        dispatch(synchronizedTableWithCurrentListCode());
      }
      dispatch(
        setOptionScroll({
          typeScroll: typeScrollState,
          duration: durationState,
        })
      );
    }
    if (!columnsState) {
      return;
    }
    dispatch(changeColumn({ colummns: cloneDeep(columnsState) }));
  };

  return (
    <>
      <Icon onClick={toggleModal}>
        <IconEditColumn />
      </Icon>
      <Modal
        show={visibleModalEditCol}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-596"
      >
        <Container>
          <Title>
            {intl.formatMessage({ id: "category.modal.title" }).toUpperCase()}
          </Title>
          <Body>
            <div className="header">
              <FormattedMessage id="category.modal.selectColumn" />
            </div>
            <div className="body">
              {renderItem(columnsState)}
              <RowScroll>
                <div>
                  <FormattedMessage id="category.modal.autoScroll" />
                  <Switch
                    loading={false}
                    checked={typeScrollState === TypeScroll.INFINITY}
                    onChange={() =>
                      setTypeScrollState(
                        typeScrollState === TypeScroll.INFINITY
                          ? TypeScroll.NORMAL
                          : TypeScroll.INFINITY
                      )
                    }
                  />
                </div>
                {typeScrollState === TypeScroll.INFINITY && (
                  <>
                    <div>
                      <FormattedMessage id="category.modal.duration" />
                      <NumberInput
                        value={durationState}
                        fullWidth
                        allowNegative={false}
                        decimalScale={0}
                        onChange={(e) =>
                          setDurationState(parseInt(e?.target?.value))
                        }
                      />
                    </div>
                    <div className="note">
                      <FormattedMessage id="category.modal.note" />
                    </div>
                  </>
                )}
              </RowScroll>
            </div>
            <div className="footer">
              <Button
                className="buttonReset"
                color="accept"
                onClick={reset}
                radius={4}
                size="m"
              >
                <IconReset />
                <Spacer size="m" />
                <div>
                  <FormattedMessage id="category.modal.reset" />
                </div>
              </Button>
              <div className="flex-2" />
              <Button
                color="accept"
                className="flex-1"
                onClick={closeModal}
                radius={4}
                size="m"
              >
                <FormattedMessage id="category.modal.cancel" />
              </Button>
              <Spacer size="m" />
              <Button
                onClick={apply}
                className="flex-1"
                fill
                color="accept"
                radius={4}
                size="m"
              >
                <FormattedMessage id="category.modal.apply" />
              </Button>
            </div>
          </Body>
        </Container>
      </Modal>
    </>
  );
}

export default memo(ModalEditCol);
