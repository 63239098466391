import { toast } from "components/commons/Toast";
import { getListRightInfo } from "core/http/apis/right-info";
import { IGetRightInfoParams } from "core/http/apis/right-info/types";
import { IRightInfoResult } from "domain/models/RightInfo";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  searchRightInfoRequest,
  searchRightInfoSuccess,
  searchRightInfoFailure,
} from "./redux";

function* searchRightInfoWorker(
  action: ReturnType<typeof searchRightInfoRequest>
) {
  try {
    const params: IGetRightInfoParams = action.payload;
    const response: IRightInfoResult = yield call(getListRightInfo, params);

    if (response?.statusCode === 0) {
      yield put(searchRightInfoSuccess(response));
    } else {
      yield put(searchRightInfoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchRightInfoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchRightInfoWatcher() {
  yield takeLatest(searchRightInfoRequest.type, searchRightInfoWorker);
}

export default function* rightInfoSaga() {
  yield all([searchRightInfoWatcher()]);
}
