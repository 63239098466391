import { createSelector } from "@reduxjs/toolkit";
import { ReactComponent as IconPlus } from "assets/image/svg/ic_plus24.svg";
import { CheckBox, InputRange } from "components/commons";
import { DoubleValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { FILTER_GROUP, FILTER_TYPE } from "modules/screeners/constants";
import {
  changeScreenerFilterItem,
  changeScreenerFilterList,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import { ISampleFilter } from "modules/screeners/types";
import { FC, useMemo } from "react";
import { ListGroup, ListGroupItem, OverlayTrigger } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  Label,
  Legend,
  ListGroupContainer,
  PlusButton,
  Wrapper,
} from "./styles";

const screenersSelector = (state: RootState) => state.screeners;

const sampleListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataFilter.sampleFilter.filtersList
);

const screenerListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.filterList
);

const FinancialIndicator: FC = () => {
  const dispatch = useDispatch();
  const sampleList = useSelector(sampleListSelector);
  const screenersList = useSelector(screenerListSelector);

  const sampleListFilter = useMemo(() => {
    return sampleList.filter(
      (filterItem) =>
        filterItem.group === FILTER_GROUP.FINANCIAL_INDICATOR &&
        filterItem.type === FILTER_TYPE.RANGE
    );
  }, [sampleList]);

  const screenersListFilter = useMemo(() => {
    return screenersList.filter(
      (filterItem) =>
        filterItem.groupCd === FILTER_GROUP.FINANCIAL_INDICATOR &&
        filterItem.type === FILTER_TYPE.RANGE
    );
  }, [screenersList]);

  const displayList = useMemo(() => {
    return sampleListFilter.filter((item) =>
      screenersListFilter.find((filterItem) => filterItem.key === item.key)
    );
  }, [sampleListFilter, screenersListFilter]);

  const checkBoxHandler = (item: ISampleFilter) => {
    dispatch(changeScreenerFilterList(item));
  };

  const getRangeValue = (values: number[], name: string) => {
    const filter: ISampleFilter | undefined = sampleListFilter.find(
      (item) => item.key === name
    );

    if (!filter) return;

    const tempFilter: ISampleFilter = { ...filter };
    const minValue = new DoubleValue();
    const maxValue = new DoubleValue();
    minValue.setValue(values[0]);
    maxValue.setValue(values[1]);

    tempFilter.minvalue = minValue.toObject();
    tempFilter.maxvalue = maxValue.toObject();

    dispatch(changeScreenerFilterItem(tempFilter));
  };

  const popover = (
    <ListGroupContainer>
      <ListGroup>
        {sampleListFilter.map((item) => (
          <ListGroupItem key={item.key}>
            <Label>{item.name}</Label>
            <CheckBox
              checked={
                !!screenersListFilter.find((filter) => filter.key === item.key)
              }
              onChange={() => checkBoxHandler(item)}
            />
          </ListGroupItem>
        ))}
      </ListGroup>
    </ListGroupContainer>
  );

  const generateRangeInput = useMemo(() => {
    return displayList.map((item) => {
      return (
        <InputRange
          key={item.key}
          label={item.name}
          placeholder={["", ""]}
          unit={item.unit}
          min={item.minvalue?.value || 0}
          max={item.maxvalue?.value || 100}
          name={item.key}
          getRangeValue={getRangeValue}
        />
      );
    });
  }, [displayList]);

  return (
    <Wrapper hide={sampleListFilter.length === 0}>
      <Legend>
        <FormattedMessage id="screeners.tabs.screener.financial.legend" />
      </Legend>
      {generateRangeInput}
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom-end"
        overlay={popover}
      >
        <PlusButton>
          <IconPlus />
        </PlusButton>
      </OverlayTrigger>
    </Wrapper>
  );
};

export default FinancialIndicator;
