import styled from "styled-components/macro";

export const Wrap = styled.div`
  padding: 16px;
  height: 100%;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  .button-wrapper {
    margin-top: auto;
    .mini-width {
      width: 50%;
    }
  }
  .button-mr {
    margin-top: 20px;
  }
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
    max-width: 240px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid #d6d6d62a;
    background: transparent;
    border-radius: 10px;
  }
`;
export const Note = styled.p`
  color: ${({ theme }) => theme.solidRed};
  margin-top: 12px;
  margin-bottom: 3rem;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;
export const InputWrapper = styled.div`
  position: relative;
  & span {
    transform: translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
    z-index: 2;
    padding: 0 !important;
    color: ${({ theme }) => theme.neutral3} !important;
  }
  .error-message {
    right: 0;
    top: 31px;
    position: absolute;
    color: ${({ theme }) => theme.solidRed};
  }
`;

interface InputProps {
  isInvalid?: boolean;
}
export const InputContainer = styled.div<InputProps>`
  position: relative;
  .w-100 {
    border: ${({ isInvalid, theme }) =>
      isInvalid && "1px solid " + theme.solidRed};
    border-radius: 4px;
  }
`;

export const ContainerRow = styled.div<{
  right?: string;
  top?: string;
  error?: boolean;
}>`
  position: relative;
  .error-message {
    position: absolute;
    right: ${({ right = "0" }) => right};
    top: ${({ top = "23px" }) => top};
    color: ${({ theme }) => theme.solidRed};
  }
  .flex-fill {
    border-radius: 4px;
    border: ${({ error, theme }) => error && "1px solid " + theme.solidRed};
  }
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .title {
    font-size: 15px;
    margin-top: 14px;
    width: 59%;
  }
  .sl-item {
    width: 100%;
  }
  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    padding: 0;

    &-item {
      width: 118px;
      padding: 8px 10px;
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      height: 32px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`;
