import { toast } from "components/commons/Toast";
import {
  isRegisterPayAdvanceAuto,
  registerPayAdvanceAuto,
  cancelPayAdvanceAuto,
} from "core/http/apis/advance-auto";
import {
  IIsRegisterPayAdvanceParams,
  IRegisterPayAdvanceAutoParams,
  ICancelPayAdvanceAutoParams,
} from "core/http/apis/advance-auto/types";
import {
  IIsRegisterPayAdvanceResponse,
  IRegisterPayAdvanceAutoResponse,
  ICancelPayAdvanceAutoResponse,
} from "domain/models/AdvanceAuto";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  isRegisterPayAdvanceAutoRequest,
  isRegisterPayAdvanceAutoSuccess,
  isRegisterPayAdvanceAutoFailure,
  registerPayAdvanceAutoRequest,
  registerPayAdvanceAutoSuccess,
  registerPayAdvanceAutoFailure,
  cancelPayAdvanceAutoRequest,
  cancelPayAdvanceAutoSuccess,
  cancelPayAdvanceAutoFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  updateModalOtpRequest,
  updateModalOtpSuccess,
} from "./redux";

function* isRegisterPayAdvanceAutoWorker(
  action: ReturnType<typeof isRegisterPayAdvanceAutoRequest>
) {
  try {
    const params: IIsRegisterPayAdvanceParams = action.payload;
    const response: IIsRegisterPayAdvanceResponse = yield call(
      isRegisterPayAdvanceAuto,
      params
    );

    if (response?.statusCode === 0) {
      yield put(isRegisterPayAdvanceAutoSuccess(response));
    } else {
      yield put(isRegisterPayAdvanceAutoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(isRegisterPayAdvanceAutoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* registerPayAdvanceAutoWorker(
  action: ReturnType<typeof registerPayAdvanceAutoRequest>
) {
  try {
    const params: IRegisterPayAdvanceAutoParams = action.payload;

    const response: IRegisterPayAdvanceAutoResponse = yield call(
      registerPayAdvanceAuto,
      params
    );

    if (response?.statusCode === 0) {
      yield put(registerPayAdvanceAutoSuccess(response));
    } else {
      yield put(registerPayAdvanceAutoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(registerPayAdvanceAutoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* cancelPayAdvanceAutoWorker(
  action: ReturnType<typeof cancelPayAdvanceAutoRequest>
) {
  try {
    const params: ICancelPayAdvanceAutoParams = action.payload;

    const response: ICancelPayAdvanceAutoResponse = yield call(
      cancelPayAdvanceAuto,
      params
    );

    if (response?.statusCode === 0) {
      yield put(cancelPayAdvanceAutoSuccess(response));
    } else {
      yield put(cancelPayAdvanceAutoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(cancelPayAdvanceAutoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
    } = action.payload;

    yield put(updateModalStatusSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalOtpWorker(
  action: ReturnType<typeof updateModalOtpRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
    } = action.payload;

    yield put(updateModalOtpSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* isRegisterPayAdvanceAutoWatcher() {
  yield takeLatest(
    isRegisterPayAdvanceAutoRequest.type,
    isRegisterPayAdvanceAutoWorker
  );
}

function* registerPayAdvanceAutoWatcher() {
  yield takeLatest(
    registerPayAdvanceAutoRequest.type,
    registerPayAdvanceAutoWorker
  );
}
function* cancelPayAdvanceAutoWatcher() {
  yield takeLatest(
    cancelPayAdvanceAutoRequest.type,
    cancelPayAdvanceAutoWorker
  );
}
function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}
function* updateModalOtpWatcher() {
  yield takeLatest(updateModalOtpRequest.type, updateModalOtpWorker);
}

export default function* advanceLimitSaga() {
  yield all([
    isRegisterPayAdvanceAutoWatcher(),

    updateModalStatusWatcher(),
    updateModalOtpWatcher(),
    registerPayAdvanceAutoWatcher(),
    cancelPayAdvanceAutoWatcher(),
  ]);
}
