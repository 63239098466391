import { ReactComponent as IconCancel } from "assets/image/svg/ic_cancel.svg";
import { Button } from "components/commons";
import OTPByBusinessCd, {
  IVerifyResponse,
} from "components/commons/OTPByBusinessCd";
import { toast } from "components/commons/Toast";
import {
  cancelRegister,
  register,
} from "core/http/apis/register-To-Transfer-Bonds";
import { EBusinessCd } from "helper/consts";
import storage from "helper/storage";
import {
  getCbotRequest,
  getcustomer2Request,
} from "modules/register-to-transfer-bonds/redux";
import {
  getCbot,
  loadingSelector,
} from "modules/register-to-transfer-bonds/selectors";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  BodyConfirm,
  BorderBox,
  Container,
  Footer,
  Icon,
  IconNone,
  Title,
  TitleContainer,
} from "../ModalContainer/styles";

interface Props {
  visibleModalConfirm?: boolean;
  title?: string;
  content?: string;
  handleCloseModalConfirm?: () => void;
  size?: any;
  contractSts?: any;
  setVisibelModalConfirm?: any;
  setChecked?: any;
}

function ModalConfirm({
  visibleModalConfirm,
  title,
  handleCloseModalConfirm,
  size,
  contractSts,
  setVisibelModalConfirm,
  setChecked,
}: Props) {
  const intl = useIntl();

  const dispatch = useDispatch();
  var custNo = storage.getMasterAccount();
  const loading = useSelector(loadingSelector);
  const cbot = useSelector(getCbot);

  const [visibleModalOTP, setVisibleModalOTP] = useState(false);

  const closeModalConfirm = () => {
    if (handleCloseModalConfirm) {
      handleCloseModalConfirm();
    }
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitAdvance = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    const res = await register({
      otpCode: response?.otp,
      validate: false,
      otpType: response?.otpTypeSubmit!,
    });
    if (res?.statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "registerTransferBonds.modal.registerDone",
        })}`,
        "success"
      );
      setVisibelModalConfirm(false);
      setChecked(false);
      dispatch(getCbotRequest({}));
      dispatch(
        getcustomer2Request({
          custCd: "",
          custNo: custNo,
        })
      );
    }
    if (res?.statusCode !== 0) {
      toast(
        `${intl.formatMessage({
          id: "registerTransferBonds.modal.registerFailure",
        })}`,
        "error"
      );
      setVisibelModalConfirm(false);
      setChecked(false);
    }
  };

  const handleConfirm = async () => {
    if (contractSts === 0) {
      setVisibleModalOTP(true);
    } else {
      const res = await cancelRegister({
        id: cbot?.id,
        contractSts: cbot?.contractSts,
        updateTime: cbot?.updDateTimeStr,
        status: cbot?.status,
      });
      if (res?.statusCode === 0) {
        toast(
          `${intl.formatMessage({
            id: "registerTransferBonds.modal.deleteDone",
          })}`,
          "success"
        );
        setChecked(false);
        setVisibelModalConfirm(false);
        dispatch(getCbotRequest({}));
        dispatch(
          getcustomer2Request({
            custCd: "",
            custNo: custNo,
          })
        );
      }
      if (res?.statusCode !== 0) {
        setVisibelModalConfirm(false);
        setChecked(false);
        toast(`${res?.message}`, "error");
      }
    }
  };

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        size={size}
        keyboard={false}
        dialogClassName="modal-434"
      >
        <Container>
          <TitleContainer>
            <IconNone></IconNone>
            <Title>{title}</Title>
            <Icon onClick={closeModalConfirm}>
              <IconCancel width={16} height={16} />
            </Icon>
          </TitleContainer>
          <BorderBox>
            <BodyConfirm>
              {contractSts === 1 ? (
                <FormattedMessage id="registerTransferBonds.modalConfirm.containerConfirmDelete" />
              ) : (
                <FormattedMessage id="registerTransferBonds.modalConfirm.containerConfirm" />
              )}
            </BodyConfirm>
            <Footer>
              <Button
                color="accept"
                className="flex-1"
                onClick={closeModalConfirm}
              >
                <FormattedMessage id="registerTransferBonds.modal.cancel" />
              </Button>
              <Button
                color="accept"
                className="flex-2"
                onClick={handleConfirm}
                fill={true}
                loading={loading}
              >
                <FormattedMessage id="registerTransferBonds.modalConfirm.btnConfirm" />
              </Button>
            </Footer>
          </BorderBox>
        </Container>
      </Modal>
      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </>
  );
}

export default ModalConfirm;
