import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  .text-error {
    float: right;
    color: ${({ theme }) => theme.red50};
  }
`;

export const Header = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  text-align: center;
  flex: 1;
`;

export const Body = styled.div`
  background-color: ${({ theme }) => theme.background2};
  padding: 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Icon = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleButton = styled.div`
  padding: 6px 6px;
  color: ${({ theme }) => theme.brandPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
`;

export const ButtonAddAccount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      fill: ${({ theme }) => theme.brandPrimary};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  .w-100 {
    flex: 3;
    .input-container {
      background: ${({ theme }) => theme.grey100};
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.background2};
  .flex-1 {
    flex: 1;
  }
  padding: 16px 48px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Label = styled.div`
  flex: 2;
`;

export const Content = styled.div`
  flex: 3;
`;

export const Value = styled.div`
  flex: 2;
  text-align: right;
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 14px;
  height: 14px;
`;

export const ButtonExit = styled.button`
  background: transparent;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
`;
