import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { FormattedMessage } from "react-intl";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";

const buyforeignqtySelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.buyforeignqty
);

const BuyforeignqtyCell = () => {
  const buyforeignqty = useSelector(buyforeignqtySelector);
  return (
    <div>
      <div className="info-text">
        <FormattedMessage id="widgets.tickerInfo.totalBuyQty" />
      </div>
      <div className="info-value">
        <VolumeChange
          value={numeral(buyforeignqty).multiply(10).format("0,0")}
        />
      </div>
    </div>
  );
};

export default BuyforeignqtyCell;
