import { HeaderContainer } from "./styles";
import { TopPricePeriod } from "./types";
import { FormattedMessage } from "react-intl";
import HeaderButton from "components/commons/ButtonOptional/HeaderButton";

export interface TopPriceHeaderProps {
  activeTab: TopPricePeriod;
  onChangePeriod: (period: TopPricePeriod) => void;
}

const periods: TopPricePeriod[] = [
  "today",
  "oneWeek",
  "oneMonth",
  "threeMonths",
  "sixMonths",
  "oneYear",
];

function Header({ activeTab, onChangePeriod }: TopPriceHeaderProps) {
  return (
    <HeaderContainer>
      <div className="row no-gutters">
        {periods.map((period: TopPricePeriod) => {
          return (
            <div key={period} className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
              <HeaderButton
                active={activeTab === (period as TopPricePeriod)}
                value={period}
                onClick={onChangePeriod}
              >
                <FormattedMessage id={`widgets.topPrice.headers.${period}`} />
              </HeaderButton>
            </div>
          );
        })}
      </div>
    </HeaderContainer>
  );
}

export default Header;
