import OrderBook, {
  GetOrderBookResponse,
  GetOrderBookSummaryResponse,
  IEditOrderResponse,
  IFindOrderRequestCondResponse,
  IFindOrderDetailResponse,
  IFindRequestCondResponse,
  IFindRequestPendingResponse,
} from "domain/models/OrderBook";
import VgaiaHttp from "core/http/singleton/vgaia";
import {
  SecuritiesStatusInterface,
  GetStockPriceResponse,
} from "domain/models/SecuritiesStatusInterface";
import {
  securitiesStatusUrl,
  // tradingSessionUrl,
  ordersStatusDetailUrl,
  getOrderBookAPI,
  getOrderBookSummaryAPI,
  getStockPriceApi,
  findOrderDetailUrl,
  findDertOrderByFilterUrl,
  findDertOrderDetailUrl,
  dertEditOrderUrl,
  dertCancelOrderUrl,
  findDertRequestCondUrl,
  findDertOrderCondUrl,
  cancelDertRequestCondUrl,
  findOrderRequestCondUrl,
  findRequestPendingUrl,
  findRequestCondUrl,
  dertCancelMultiOrderUrl,
} from "./urls";

import qs from "qs";
import {
  GetOrderBookParam,
  GetOrderBookSummaryParam,
  GetStockPriceParam,
  ICancelDertRequestCondParams,
  IDertCancelOrderParams,
  IDertEditOrderParams,
  IFindDertOrderByFilterParams,
  IFindDertOrderCondParams,
  IFindDertOrderDetailParams,
  IFindDertRequestCondParams,
  IFindOrderRequestCondParams,
  IFindOrderDetailParams,
  IFindRequestCondParams,
  IFindRequestPendingParams,
  IDertCancelMultiOrderParams,
} from "./types";
import { editOrderUrl } from "../orderexec/urls";
import {
  ICancelDertRequestCondResponse,
  IDertCancelMultiOrderResponse,
  IDertCancelOrderResponse,
  IDertEditOrderResponse,
  IFindDertOrderByFilterResponse,
  IFindDertOrderCondResponse,
  IFindDertOrderDetailResponse,
  IFindDertRequestCondResponse,
} from "domain/models/OrderBookDer";
import { IEditOrderParams } from "../orderexec/types";

export const getSecuritiesStatus = async (
  ticker: string
): Promise<SecuritiesStatusInterface> => {
  const res = await VgaiaHttp.get<SecuritiesStatusInterface>(
    securitiesStatusUrl + `/${ticker}`
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAPIDetailTradingSession = async (
  transId: number,
  params: any
): Promise<OrderBook> => {
  const res = await VgaiaHttp.get<OrderBook>(
    ordersStatusDetailUrl + `/${transId}`,
    new URLSearchParams({
      ...params,
    })
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postAPISingleOrder = async (params: any): Promise<OrderBook> => {
  const res = await VgaiaHttp.post<OrderBook>(
    `/orderSingle`,
    qs.stringify(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const editOrderApi = async (
  params: IEditOrderParams
): Promise<IEditOrderResponse> => {
  const res = await VgaiaHttp.post<IEditOrderResponse>(
    editOrderUrl,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAPIOrderBook = async (
  params: GetOrderBookParam
): Promise<GetOrderBookResponse> => {
  const res = await VgaiaHttp.get<GetOrderBookResponse>(
    getOrderBookAPI(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAPIOrderBookSummary = async (
  params: GetOrderBookSummaryParam
): Promise<GetOrderBookSummaryResponse> => {
  const res = await VgaiaHttp.get<GetOrderBookSummaryResponse>(
    getOrderBookSummaryAPI,
    params
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// Lay thong tin ma chung khoan
export const getStockPrice = async (
  ticker: string
): Promise<GetStockPriceResponse> => {
  const param: GetStockPriceParam = {
    secCode: ticker,
  };
  const res = await VgaiaHttp.get<GetStockPriceResponse>(
    getStockPriceApi(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findOrderDetailApi = async (
  params: IFindOrderDetailParams
): Promise<IFindOrderDetailResponse> => {
  const res = await VgaiaHttp.get<IFindOrderDetailResponse>(
    findOrderDetailUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findOrderRequestCondApi = async (
  params: IFindOrderRequestCondParams
): Promise<IFindOrderRequestCondResponse> => {
  const res = await VgaiaHttp.get<IFindOrderRequestCondResponse>(
    findOrderRequestCondUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findRequestPendingApi = async (
  params: IFindRequestPendingParams
): Promise<IFindRequestPendingResponse> => {
  const res = await VgaiaHttp.get<IFindRequestPendingResponse>(
    findRequestPendingUrl(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findRequestCondApi = async (
  params: IFindRequestCondParams
): Promise<IFindRequestCondResponse> => {
  const res = await VgaiaHttp.get<IFindRequestCondResponse>(
    findRequestCondUrl,
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// OrderbookDer API --- start ---

export const findDertOrderByFilterApi = async (
  params: IFindDertOrderByFilterParams
): Promise<IFindDertOrderByFilterResponse> => {
  const res = await VgaiaHttp.get<IFindDertOrderByFilterResponse>(
    findDertOrderByFilterUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDertOrderDetailApi = async (
  params: IFindDertOrderDetailParams
): Promise<IFindDertOrderDetailResponse> => {
  const res = await VgaiaHttp.get<IFindDertOrderDetailResponse>(
    findDertOrderDetailUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const dertEditOrderApi = async (
  params: IDertEditOrderParams
): Promise<IDertEditOrderResponse> => {
  const res = await VgaiaHttp.post<IDertEditOrderResponse>(
    dertEditOrderUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const dertCancelOrderApi = async (
  params: IDertCancelOrderParams
): Promise<IDertCancelOrderResponse> => {
  const res = await VgaiaHttp.post<IDertCancelOrderResponse>(
    dertCancelOrderUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const dertCancelMultiOrderApi = async (
  params: IDertCancelMultiOrderParams
): Promise<IDertCancelMultiOrderResponse> => {
  const res = await VgaiaHttp.post<IDertCancelMultiOrderResponse>(
    dertCancelMultiOrderUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDertRequestCondApi = async (
  params: IFindDertRequestCondParams
): Promise<IFindDertRequestCondResponse> => {
  const res = await VgaiaHttp.get<IFindDertRequestCondResponse>(
    findDertRequestCondUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findDertOrderCondApi = async (
  params: IFindDertOrderCondParams
): Promise<IFindDertOrderCondResponse> => {
  const res = await VgaiaHttp.get<IFindDertOrderCondResponse>(
    findDertOrderCondUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cancelDertRequestCondApi = async (
  params: ICancelDertRequestCondParams
): Promise<ICancelDertRequestCondResponse> => {
  const res = await VgaiaHttp.post<ICancelDertRequestCondResponse>(
    cancelDertRequestCondUrl,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// OrderbookDer API --- end ---
