import styled from "styled-components/macro";

export const ProgressContainer = styled.div<{ percentBuy: number }>`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: ${({ theme }) => theme.textcolorWhite};
  height: 19px;
  background-image: ${({ theme, percentBuy }) =>
    `linear-gradient(90deg, ${theme.green50} 0% calc(${percentBuy}% - 1px), ${theme.grey70} calc(${percentBuy}% - 1px) calc(${percentBuy}% + 1px), ${theme.red50} calc(${percentBuy}% + 1px) 100%)`};
`;

export const PercentValue = styled.span`
  padding: 0 8px;
`;

export const Space = styled.div`
  width: 0.5%;
  background: black;
`;
