import { useMemo, useEffect, useState } from "react";
import { FC } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Spacer } from "components/commons";
import numeral from "numeral";
import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  OrderBookWidgetState,
  findOrderRequestCond,
} from "modules/order-book/redux/index";
import {
  Container,
  Title,
  ButtonExit,
  TitleContent,
  IconBox,
  RejectReason,
} from "./styles";
import { Channel } from "helper/consts";
import { StatusCell, TextTradeType } from "../style";
import { FormattedMessage, useIntl } from "react-intl";
import { getStatusConditionDisPlay } from "helper/orderBookHelper";
import CommonTable from "components/commons/CommonTable";
import {
  TableCellContent,
  TableHeaderCellContent,
  StyledTooltip,
} from "components/commons/CommonTable/styles";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { ReactComponent as IconInfo } from "assets/image/svg/ic_info.svg";
import { OverlayTrigger } from "react-bootstrap";
import { IFindOrderRequestCondParams } from "core/http/apis/tradeinfo/types";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";

interface Props {
  showModal: boolean;
  setShowModal: any;
  dataRow?: any;
}

/**
 * Create Selector
 */
const orderBookSelector = (state: RootState) => state.orderBookWidget.root;

const orderBookConditionDetailListSelector = createSelector(
  orderBookSelector,
  (orderState: OrderBookWidgetState) => orderState.orderBookConditionDetailList
);

const getChannel = (a: string = "") => {
  return Channel[a] ? Channel[a] : "-";
};

/**
 * Modal
 */
const ModalDetailOrderCondition: FC<Props> = ({
  showModal,
  setShowModal,
  dataRow,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const detailList = useSelector(orderBookConditionDetailListSelector);
  const themeType = useSelector(themeTypeSelector);
  const [isHover, setHover] = useState(false);

  const detailClone = useMemo(() => {
    if (dataRow) {
      const data = dataRow.original;
      return {
        ...data,
        orderStatus: getStatusConditionDisPlay(data?.status),
        tradingDate:
          data?.tradeDate &&
          moment(numeral(data?.tradeDate).value()).format("HH:mm:ss"),
        orderQty: numeral(data?.ordQty).format("0,0"),
        channel: getChannel(data?.channel),
      };
    }
  }, [dataRow, intl]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="widgets.orderBookDetail.table.rowNum" />
          </TableHeaderCellContent>
        ),
        accessor: "no",
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {Number(props?.row?.id) + 1}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="widgets.orderBookDetail.table.regDateTime" />
          </TableHeaderCellContent>
        ),
        accessor: "tradeDate",
        minWidth: 135,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value ? moment(props.value).format("DD/MM/YYYY") : "-"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props?.row?.original?.ordQty).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.ordPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "ordPrice",
        minWidth: 80,
        Cell: (props: any) => {
          const formatValue = numeral(
            props?.row?.original?.ordPrice || 0
          ).format("0,0.00");
          return (
            <TableCellContent align={"flex-end"}>
              {formatValue}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.matQty" />
          </TableHeaderCellContent>
        ),
        accessor: "matQty",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {numeral(props?.row?.original?.matQty).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="widgets.orderBookDetail.table.matAvg" />
          </TableHeaderCellContent>
        ),
        accessor: "matPriceAvg",
        minWidth: 80,
        Cell: (props: any) => {
          const formatValue = numeral(
            props?.row?.original?.matPriceAvg || 0
          ).format("0,0.00");
          return (
            <TableCellContent align={"flex-end"}>
              {formatValue}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const onLoadData = async () => {
    try {
      if (dataRow) {
        const params: IFindOrderRequestCondParams = {
          custNo: dataRow?.original?.custNo,
          subAccoNo: dataRow?.original?.subAccoNo,
          refDate: dataRow?.original?.refDate,
          refNo: dataRow?.original?.refNo,
          offset: 0,
          limit: 30,
        };
        dispatch(findOrderRequestCond(params));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (showModal) {
      onLoadData();
    }
  }, [showModal]);

  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        dialogClassName="modal-502"
      >
        <Container>
          <Row>
            <Col xs={12}>
              <Title>
                <div className="flex-1"></div>
                <TitleContent side={detailClone?.tradeTypeDisplay}>
                  {intl
                    .formatMessage({ id: "widgets.orderBookDetail.title" })
                    .toUpperCase()}{" "}
                  {detailClone?.id}
                </TitleContent>
                <ButtonExit>
                  <IconExit onClick={() => setShowModal(false)} />
                </ButtonExit>
              </Title>
            </Col>
          </Row>
          <Spacer size="m" />
          <div className="px-3">
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "orderBook.tableCondition.accoNo" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.subAccoNo}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "orderBook.tableCondition.side",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                <TextTradeType
                  side={detailClone?.tradeTypeDisplay}
                  className={detailClone?.tradeTypeDisplay}
                >
                  {detailClone?.tradeTypeDisplay &&
                    intl.formatMessage({
                      id: detailClone?.tradeTypeDisplay,
                    })}{" "}
                  {detailClone?.secCd}
                </TextTradeType>
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "orderBook.tableCondition.status" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                <StatusCell
                  textColor={
                    detailClone?.orderStatusColor === "reject"
                      ? themes[themeType].solidRed
                      : detailClone?.orderStatusColor === "success"
                      ? themes[themeType].solidGreen
                      : detailClone?.orderStatusColor === "pending"
                      ? themes[themeType].solidBlue
                      : themes[themeType].solidBlue
                  }
                >
                  {detailClone &&
                    intl.formatMessage({
                      id: detailClone?.statusDisplay,
                    })}
                </StatusCell>
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "orderBook.tableCondition.condType",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.condTypeDisplay &&
                  intl.formatMessage({ id: detailClone?.condTypeDisplay })}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "orderBook.tableCondition.ordQty",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {numeral(detailClone?.ordQty).format("0,0")}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "orderBook.tableCondition.ordPrice",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.condType === "TS" ||
                detailClone?.condType === "PRO"
                  ? "- - -"
                  : numeral(detailClone?.ordPrice).format("0,0.00")}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "orderBook.tableCondition.matQty" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.matQty}
              </Col>
            </Row>
            <Spacer size="s" />
            {detailClone?.status === 3 && (
              <>
                <Row>
                  <Col xs={4}>
                    {intl.formatMessage({
                      id: "orderBook.tableCondition.cancelQty",
                    })}
                  </Col>
                  <Col xs={8} className="text-right text-color-light">
                    {detailClone?.ordQty}
                  </Col>
                </Row>
                <Spacer size="s" />
              </>
            )}
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "orderBook.tableCondition.matMethod",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.matMethodDisplay &&
                  intl.formatMessage({ id: detailClone?.matMethodDisplay })}
              </Col>
            </Row>
            <Spacer size="s" />
            {detailClone?.condType === "TCO" && (
              <>
                <Row>
                  <Col xs={4}>
                    {intl.formatMessage({
                      id: "orderBook.tableCondition.optVal",
                    })}
                  </Col>
                  <Col xs={8} className="text-right text-color-light">
                    {detailClone?.optType === 0
                      ? intl.formatMessage({ id: detailClone?.optTypeDisplay })
                      : detailClone?.optTypeDisplay + " " + detailClone?.optVal}
                  </Col>
                </Row>
                <Spacer size="s" />
              </>
            )}
            {detailClone?.condType !== "TCO" &&
              detailClone?.condType !== "PRO" && (
                <>
                  {(detailClone?.condType === "SP" ||
                    detailClone?.condType === "CL") && (
                    <>
                      <Row>
                        <Col xs={6}>
                          {intl.formatMessage({
                            id: "orderBook.tableCondition.arbitrage",
                          })}
                        </Col>
                        <Col xs={6} className="text-right text-color-light">
                          {detailClone?.optValTypeDisplay
                            ? intl.formatMessage({
                                id: detailClone?.optValTypeDisplay,
                              })
                            : "- - - -"}
                        </Col>
                      </Row>
                      <Spacer size="s" />
                    </>
                  )}
                  <Row>
                    <Col xs={4}>
                      {intl.formatMessage({
                        id:
                          detailClone?.condType === "TS"
                            ? "widgets.placeOrder.stopInterval"
                            : detailClone?.optValType === 1
                            ? "orderBook.tableCondition.optValByValue"
                            : "orderBook.tableCondition.optValByPercent",
                      })}
                    </Col>
                    <Col xs={8} className="text-right text-color-light">
                      {numeral(
                        detailClone?.optValType === 1
                          ? detailClone?.optVal
                          : detailClone?.optVal * 100
                      ).format("0,0.00")}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                  <Row>
                    <Col xs={4}>
                      {intl.formatMessage({
                        id: "orderBook.tableCondition.limitOffset",
                      })}
                    </Col>
                    <Col xs={8} className="text-right text-color-light">
                      {numeral(detailClone?.limitOffset).format("0,0.00")}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                  <Row>
                    <Col xs={4}>
                      {detailClone?.tradeType === 2
                        ? intl.formatMessage({
                            id: "orderBook.tableCondition.minBuyPrice",
                          })
                        : intl.formatMessage({
                            id: "orderBook.tableCondition.maxSellPrice",
                          })}
                    </Col>
                    <Col xs={8} className="text-right text-color-light">
                      {numeral(
                        detailClone?.tradeType === 2
                          ? detailClone?.minPrice
                          : detailClone?.maxPrice
                      ).format("0,0.00")}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                </>
              )}
            {detailClone?.condType === "TS" && (
              <>
                <Row>
                  <Col xs={4} className="d-flex align-items-center">
                    {intl.formatMessage({
                      id: "orderBook.tableCondition.valueActive",
                    })}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <StyledTooltip
                          className={{ "d-none": !isHover }}
                          id="tooltip"
                        >
                          {intl.formatMessage({
                            id: "orderBook.tableCondition.tooltipValueActive",
                          })}
                        </StyledTooltip>
                      }
                    >
                      <IconBox
                        onMouseEnter={() => {
                          setHover(true);
                        }}
                        onMouseLeave={() => {
                          setHover(false);
                        }}
                      >
                        <IconInfo />
                      </IconBox>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={8} className="text-right text-color-light">
                    {numeral(detailClone?.value).format("0,0.00")}
                  </Col>
                </Row>
                <Spacer size="s" />
              </>
            )}
            <Row>
              <Col xs={4}>
                {intl.formatMessage({
                  id: "orderBook.tableCondition.effectDate",
                })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.effectDateDisplay +
                  " - " +
                  detailClone?.expiredDateDisplay}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "orderBook.tableCondition.channel" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.channelDisplay}
              </Col>
            </Row>
            <Spacer size="s" />
            <Row>
              <Col xs={4}>
                {intl.formatMessage({ id: "orderBook.tableCondition.reqTime" })}
              </Col>
              <Col xs={8} className="text-right text-color-light">
                {detailClone?.reqTime}
              </Col>
            </Row>
            <Spacer size="s" />
            {dataRow?.original?.notes && (
              <Row>
                <Col xs={4}>
                  {intl.formatMessage({
                    id: "widgets.orderBookDetail.rejectReason",
                  })}
                </Col>
                <Col xs={8} className="text-right text-color-light">
                  <RejectReason>
                    {dataRow?.original?.notes ? (
                      <FormattedMessage
                        id={"error." + dataRow?.original?.notes.split(":")[0]}
                      />
                    ) : (
                      "---"
                    )}
                  </RejectReason>
                </Col>
              </Row>
            )}
            <Spacer size="s" />
          </div>
          <Spacer size="l" />
          <CommonTable columns={columns} data={detailList} />
        </Container>
      </Modal>
    </>
  );
};

export default ModalDetailOrderCondition;
