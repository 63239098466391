import { FC } from "react";
import Content from "./components/StatisticUpdownContent";
import Tabs from "./components/Tabs";
import { Container } from "./styles";

const StatisticUpdown: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <Tabs />
      <Content />
    </Container>
  );
};

export default StatisticUpdown;
