import { Select, Button, DatePicker } from "components/commons";
import { memo, useMemo, useEffect } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePaymentHistoryFilter,
  getListDebitPayExRequest,
} from "modules/contract-debit/redux";
import {
  paymentHistoryLoadingSelector,
  //   filterProfitLossSelector,
  paymentHistoryFilterSelector,
  selectLimitPage,
} from "modules/contract-debit/selectors";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";

import moment from "moment";
import { toast } from "components/commons/Toast";
import { Row } from "react-bootstrap";

import { IGetListDebitPayExParams } from "core/http/apis/contract-debit/types";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
import storage from "helper/storage";

const statusOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "contractDebit.paymentHistoryTab.filter.status.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "contractDebit.paymentHistoryTab.filter.status.status1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "contractDebit.paymentHistoryTab.filter.status.status2",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "contractDebit.paymentHistoryTab.filter.status.status3",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { fromDate, toDate, subAccoNo } = useSelector(
    paymentHistoryFilterSelector
  );
  const limit = useSelector(selectLimitPage);
  const listAcc = GetAccoList(
    GetAccountType.MARGIN,
    EntrustBusinessCode.Margin
  );

  const loading = useSelector(paymentHistoryLoadingSelector);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc, intl]);
  const statusOptions = useMemo(() => statusOption(intl), [intl]);
  useEffect(() => {
    dispatch(
      updatePaymentHistoryFilter({
        key: "subAccoNo",
        value: parseOptionAccount ? parseOptionAccount[0]?.value : "",
      })
    );

    dispatch(
      updatePaymentHistoryFilter({
        key: "status",
        value: statusOptions ? +statusOptions[0].value : 0,
      })
    );
  }, []);

  const handleSearch = () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      const resLogData = {
        eventGroup: GroupEvent.marginDebit,
        event: RealtimeConst.getDataMarginDebitHistory,
        message: "faild",
      };
      storage.commonPushLogFirebase(resLogData);
      return toast("common.warningInvalidDay", "error");
    }
    const submitData: IGetListDebitPayExParams = {
      subAccoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      type: 1,

      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: 0,
      rowPerPage: limit,
    };
    dispatch(getListDebitPayExRequest(submitData));
    const resLogData = {
      eventGroup: GroupEvent.marginDebit,
      event: RealtimeConst.getDataMarginDebitHistory,
      message: "success",
    };
    storage.commonPushLogFirebase(resLogData);
  };

  // effect

  // render
  return (
    <Wrap
      className="d-flex justify-content-between align-items-center"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <Row className=" d-flex align-content-center w-100 pl-3">
          <FilterItem
            filterName={intl.formatMessage({
              id: "contractDebit.paymentHistoryTab.filter.account",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={parseOptionAccount[0]}
                options={parseOptionAccount}
                onChange={(e) => {
                  dispatch(
                    updatePaymentHistoryFilter({
                      key: "subAccoNo",
                      value: e?.value ? e.value : "",
                    })
                  );
                }}
              />
            </div>
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "contractDebit.paymentHistoryTab.filter.fromDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={fromDate}
                onChange={(e: any) => {
                  dispatch(
                    updatePaymentHistoryFilter({
                      key: "fromDate",
                      value: e,
                    })
                  );
                }}
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>

          <FilterItem
            filterName={intl.formatMessage({
              id: "contractDebit.paymentHistoryTab.filter.toDate",
            })}
            className="marginRight text-grey"
          >
            <div className="text-white">
              <DatePicker
                selected={toDate}
                onChange={(e) =>
                  dispatch(
                    updatePaymentHistoryFilter({
                      key: "toDate",
                      value: e,
                    })
                  )
                }
                popperPlacement="bottom-start"
              />
            </div>
          </FilterItem>

          {/* <FilterItem
            filterName={intl.formatMessage({
              id: "contractDebit.paymentHistoryTab.filter.status.label",
            })}
            className="marginRight2 text-grey"
          >
            <div className="text-white">
              <Select
                className="flex-fill"
                defaultValue={statusOptions[0]}
                options={statusOptions}
                onChange={(e) => {
                  dispatch(
                    updatePaymentHistoryFilter({
                      key: "status",
                      value: e?.value ? +e.value : 0,
                    })
                  );
                }}
              />
            </div>
          </FilterItem> */}
          <Button
            color="accept"
            className="button-filter1 mr-4"
            loading={loading}
            onClick={handleSearch}
          >
            {intl.formatMessage({
              id: "contractDebit.paymentHistoryTab.filter.search",
            })}
          </Button>
        </Row>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
