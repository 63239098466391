import { currentMarketCodeSelector } from "modules/market/redux/selectors";
import { TabCard, TabItem } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "modules/market/redux";

function TabIndex() {
  const dispatch = useDispatch();
  const currentMarket = useSelector(currentMarketCodeSelector);
  const changeTab = (tab: EMarketCodeNew) => {
    if (tab !== currentMarket) {
      dispatch(setCurrentMarketCode(tab));
    }
  };

  return (
    <TabCard>
      <TabItem
        active={EMarketCodeNew.HSX_IDX === currentMarket}
        onClick={() => changeTab(EMarketCodeNew.HSX_IDX)}
      >
        HSX
      </TabItem>
      <TabItem
        active={EMarketCodeNew.HNX_IDX === currentMarket}
        onClick={() => changeTab(EMarketCodeNew.HNX_IDX)}
      >
        HNX
      </TabItem>
      <TabItem
        active={EMarketCodeNew.UPCOM_IDX === currentMarket}
        onClick={() => changeTab(EMarketCodeNew.UPCOM_IDX)}
      >
        UPCOM
      </TabItem>
      <TabItem
        active={EMarketCodeNew.VN30_IDX === currentMarket}
        onClick={() => changeTab(EMarketCodeNew.VN30_IDX)}
      >
        VN30
      </TabItem>
    </TabCard>
  );
}

export default TabIndex;
