import {
  notification,
  notificationTotalUnread,
  notificationDetail,
  notifyDeletion,
  notifyCheckRead,
  initNotification,
  readAllNotification,
} from "./urls";
import {
  GetNotificationListParams,
  GetNotificationTotalUnreadParams,
  DeleteNotificationParams,
  NotificationCheckReadParams,
  InitNotificationParams,
  NotificationCheckReadAllParams,
  NotificationDetailParams,
} from "./types";
import httpTrans from "../../singleton/trans";
import storage from "helper/storage";
import {
  Notifications,
  NotificationTotalUnread,
  ResultDeleteNotification,
  ResultCheckReadNotification,
  ResultCheckReadAllNotification,
  NotificationDetailResponse,
} from "domain/models/Notification";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

export const getNotificationList = async (
  params: GetNotificationListParams
): Promise<Notifications> => {
  const res = await httpTrans.get<Notifications>(
    notification(storage.getMasterAccount()),
    params
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getNotificationTotalUnread =
  async (): Promise<NotificationTotalUnread> => {
    const params: GetNotificationTotalUnreadParams = {
      masterAccount: storage.getMasterAccount(),
      type: 0,
    };
    const res = await httpTrans.get<NotificationTotalUnread>(
      notificationTotalUnread(),
      params
    );
    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  };

export const deleteNotification = async (
  params: DeleteNotificationParams
): Promise<ResultDeleteNotification> => {
  const paramsDelete: AxiosRequestConfig = {
    params: params,
  };
  const res = await httpTrans.delete<ResultDeleteNotification>(
    notifyDeletion,
    paramsDelete
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getNotificationDetail = async (
  params: NotificationDetailParams
): Promise<NotificationDetailResponse> => {
  const res = await httpTrans.get<NotificationDetailResponse>(
    notificationDetail(storage.getMasterAccount(), params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const checkReadNotification = async (
  params: NotificationCheckReadParams
): Promise<ResultCheckReadNotification> => {
  const res = await httpTrans.put<ResultCheckReadNotification>(
    notifyCheckRead,
    params,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postInitNotification = async (
  params: InitNotificationParams
): Promise<number> => {
  const res = await httpTrans.post<number>(
    initNotification,
    qs.stringify(params)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const checkReadAllNotification = async (
  params: NotificationCheckReadAllParams
): Promise<ResultCheckReadNotification> => {
  const res = await httpTrans.put<ResultCheckReadAllNotification>(
    readAllNotification(storage.getMasterAccount()),
    params,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
