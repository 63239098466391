import NavigationBottom from "./navigation";
import { Container, Content, LabelCell } from "./styles";
// import { selectTabIncurred } from "modules/assetsIncurred/selectors";
// import { useSelector } from "react-redux";
// import { ASSETS_INCURRED_TAB } from "modules/assetsIncurred/constants";
import TableOpen from "./tableOpen";
// import TableClose from "./tableClose";

const ContentBottom = () => {
  // const activeTab = useSelector(selectTabIncurred);

  // const checkTable = () => {
  //   if (activeTab === ASSETS_INCURRED_TAB.OPEN) return <TableOpen />;
  //   return <TableClose />;
  // };

  return (
    <Container>
      <Content>
        <LabelCell>
          <NavigationBottom />
        </LabelCell>
      </Content>
      {/* {checkTable()} */}
      <TableOpen />
    </Container>
  );
};

export default ContentBottom;
