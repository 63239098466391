import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  FilterTransaction,
  TransactionValueVolumeData,
} from "components/widgets/generals/TransactionValueVolume/types";
export type TransactionValueVolumeState = {
  filterTransaction: FilterTransaction;
  loading: boolean;
  error: string | undefined;
  transactionValueVolumeData: TransactionValueVolumeData;
};

const initialState: TransactionValueVolumeState = {
  filterTransaction: {
    marketType: {
      label: "widgets.marketWatch.marketTypes.HSX",
      value: "100",
    },
    valueType: {
      label: "widgets.marketWatch.valueTypes.price",
      value: "1",
    },
    transactionType: {
      label: "widgets.marketWatch.transactionTypes.value",
      value: "EFIELDTYPE_VALUE",
    },
  },
  loading: false,
  error: undefined,
  transactionValueVolumeData: {
    valueData: [],
    volumeData: [],
  },
};

const transactionValueVolumeSlice = createSlice({
  name: "transactionValueVolumeSlice",
  initialState,
  reducers: {
    changeTransactionFilter: (
      state,
      action: PayloadAction<FilterTransaction>
    ) => {
      state.filterTransaction = action.payload;
    },
    fetchTransactionValueVolume: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    fetchTransactionValueVolumeSuccess: (
      state,
      action: PayloadAction<TransactionValueVolumeData>
    ) => {
      state.loading = false;
      state.transactionValueVolumeData.valueData = action.payload.valueData;
      state.transactionValueVolumeData.volumeData = action.payload.volumeData;
    },
    fetchTransactionValueVolumeFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  changeTransactionFilter,
  fetchTransactionValueVolume,
  fetchTransactionValueVolumeSuccess,
  fetchTransactionValueVolumeFailure,
} = transactionValueVolumeSlice.actions;

export default transactionValueVolumeSlice.reducer;
