import { FormattedMessage } from "react-intl";
import SelectTime from "./SelectTime";
import { FinanceWrapper } from "./styles";
import MainIndicator from "./MainIndicator";
import BalanceSheet from "./BalanceSheet";
import CashFlow from "./CashFlow";
import ReportRevenue from "./ReportRevenue";

// Extract state from component if we need to reuse this component in multiple screens
const FinanceInfo = () => {
  return (
    <FinanceWrapper>
      <div className="d-flex align-items-center pb-2 watchMore">
        <div className="mr-4 w-25">
          <FormattedMessage id="widgets.finance.viewType" />
        </div>
        <div className="w-75">
          <SelectTime />
        </div>
      </div>
      <MainIndicator />
      <br />
      <ReportRevenue />
      <br />
      <BalanceSheet />
      <br />
      <CashFlow />
    </FinanceWrapper>
  );
};
export default FinanceInfo;
