import {
  Notification,
  Title,
  Icon,
  TitleRight,
  Content,
  TitleLeft,
  NotificationParent,
  Time,
} from "./styles";
import { NotificationItem } from "modules/auth/types";
import { ReactComponent as IconDelete } from "assets/image/svg/ic_delete_solid.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationDetailParams,
  changeVisibleNotificationDetail,
  checkReadNotificationParams,
  NotificationState,
} from "modules/auth/redux/notification";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import storage from "helper/storage";
import {
  NotificationCheckReadParams,
  NotificationDetailParams,
} from "core/http/apis/trans/types";
interface Props {
  data: NotificationItem[];
  onDelete: (date?: string, noticeId?: string) => void;
}

const notificationSelector = (state: RootState) => state.auth.notification;

const isVisibleDetailSelector = createSelector(
  notificationSelector,
  (notificationState: NotificationState) =>
    notificationState.isVisibleNotificationDetail
);

function NotificationItems({ data, onDelete }: Props) {
  const isVisibleDetail = useSelector(isVisibleDetailSelector);
  const dispatch = useDispatch();
  const onShowDetail = (date?: string, noticeId?: string) => {
    const paramsAction: NotificationCheckReadParams = {
      masterAccount: storage.getMasterAccount(),
      date: date ? date : "",
      noticeId: noticeId ? noticeId : "",
      actionType: 1,
    };
    const paramDetail: NotificationDetailParams = {
      businessDate: Number(date),
      noticeId: noticeId ? noticeId : "",
    };
    dispatch(changeVisibleNotificationDetail(!isVisibleDetail));
    dispatch(getNotificationDetailParams(paramDetail));
    dispatch(checkReadNotificationParams(paramsAction));
  };
  return (
    <div>
      {data.map((item) => {
        const time = moment(item.regDateTime).format("HH:mm:ss");
        const status = item.readState === 1 ? true : false;
        return (
          <NotificationParent>
            <Notification
              onClick={() => {
                onShowDetail(item.date?.toString(), item.noticeId?.toString());
              }}
              status={status}
            >
              <Title>
                <TitleLeft status={status}>{item.title}</TitleLeft>
                <TitleRight>
                  <Time>{time}</Time>
                  <Icon
                    onClick={(e) => {
                      e.stopPropagation();

                      onDelete(
                        item.date?.toString(),
                        item.noticeId?.toString()
                      );
                    }}
                  >
                    <IconDelete />
                  </Icon>
                </TitleRight>
              </Title>
              <Content
                lengthTextContent={item.content?.length}
                dangerouslySetInnerHTML={{
                  __html: item && item.content ? item.content : "",
                }}
              ></Content>
            </Notification>
          </NotificationParent>
        );
      })}
    </div>
  );
}

export default NotificationItems;
