import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReduxStateType } from "redux/types";
import {
  IDeleteTransferSecResponse,
  ITransferSec,
} from "domain/models/TransferShare";
import { logOutSuccess } from "modules/auth/redux";
// import { RANGE_DAY } from "helper/consts";
import {
  IDeleteTransferSecParam,
  IFindTransferSecHistoryParam,
} from "core/http/apis/transfer-share/types";
import { STATUS_TRANSFER_SHARE } from "../constants";

export interface ReduxData {
  data: ITransferSec[];
  currentPage: number;
  search: ISearchInput;
  status: ReduxStateType;
}

interface ISearchInput {
  fromDate?: Date;
  toDate?: Date;
  subAccoNoFrom?: string;
  subAccoNoTo?: string;
  secCd?: string;
  status?: string;
}

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

const initialState: ReduxData = {
  data: [],
  currentPage: 0,
  search: {
    fromDate: firstDay,
    toDate: new Date(),
    subAccoNoFrom: "",
    subAccoNoTo: "",
    secCd: "",
    status: STATUS_TRANSFER_SHARE.ALL,
  },
  status: ReduxStateType.INIT,
};

const listHistoryTransferShare = createSlice({
  name: "listHistoryTransferShareSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    requestListHistoryTransferShare: (
      state,
      action: PayloadAction<IFindTransferSecHistoryParam>
    ) => {
      state.status = ReduxStateType.LOADING;
    },
    listHistoryTransferShareSuccess: (
      state,
      action: PayloadAction<ITransferSec[]>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload.map((item) => ({
        ...item,
        id: `${item?.alloDate}${item?.refNo}`,
      }));
    },
    fetchModelistHistoryTransferShare: (
      state,
      action: PayloadAction<ITransferSec[]>
    ) => {
      const dataNext: ITransferSec[] = action.payload.map((item) => ({
        ...item,
        id: `${item?.alloDate}${item?.refNo}`,
      }));
      state.data = [...state.data, ...dataNext];
    },
    listHistoryTransferShareFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    changeFromDateSearch: (state, action: PayloadAction<Date>) => {
      state.search.fromDate = action.payload;
    },
    changeToDateSearch: (state, action: PayloadAction<Date>) => {
      state.search.toDate = action.payload;
    },
    changeAccountSearch: (state, action: PayloadAction<string>) => {
      state.search.subAccoNoFrom = action.payload;
    },
    changeShareCodeSearch: (state, action: PayloadAction<string>) => {
      state.search.secCd = action.payload;
    },
    changeStatusSearch: (state, action: PayloadAction<string>) => {
      state.search.status = action.payload;
    },
    clearDataFilterGetListParamsTransferShare: (state) => {
      state.search = {
        fromDate: firstDay,
        toDate: new Date(),
        status: STATUS_TRANSFER_SHARE.PENDING,
        secCd: "",
        subAccoNoFrom: "",
      };
    },
    deleteTransferSecRequest: (
      state,
      action: PayloadAction<IDeleteTransferSecParam>
    ) => {
      state.status = ReduxStateType.LOADING;
    },
    deleteTransferSecSuccess: (
      state,
      action: PayloadAction<IDeleteTransferSecResponse>
    ) => {
      state.status = ReduxStateType.SUCCESS;
    },
    deleteTransferSecFailure: (state) => {
      state.status = ReduxStateType.ERROR;
    },
    updateCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const {
  requestListHistoryTransferShare,
  listHistoryTransferShareSuccess,
  listHistoryTransferShareFailed,
  changeAccountSearch,
  changeFromDateSearch,
  changeToDateSearch,
  changeShareCodeSearch,
  changeStatusSearch,
  clearDataFilterGetListParamsTransferShare,
  deleteTransferSecFailure,
  deleteTransferSecRequest,
  deleteTransferSecSuccess,
  fetchModelistHistoryTransferShare,
  updateCurrentPage,
} = listHistoryTransferShare.actions;

export default listHistoryTransferShare.reducer;
