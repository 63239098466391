import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChartData } from "./marketWatch";

export type LiquidityState = {
  liquidityData: ChartData;
  loading: boolean;
  error: string | undefined;
};

const initialState: LiquidityState = {
  liquidityData: {
    labels: [],
    datasets: [],
  },
  loading: false,
  error: undefined,
};

const liquiditySlice = createSlice({
  name: "liquiditySlice",
  initialState,
  reducers: {
    getLiquidityData: (state) => {
      state.loading = true;
    },
    getLiquidityDataSuccess: (
      state,
      action: PayloadAction<{ data: ChartData }>
    ) => {
      state.liquidityData = action.payload.data;
      state.loading = false;
    },
    getLiquidityDataFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getLiquidityData,
  getLiquidityDataSuccess,
  getLiquidityDataFailure,
} = liquiditySlice.actions;

export default liquiditySlice.reducer;
