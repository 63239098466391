import { Button } from "components/commons";
import { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { userFavoriteListSelector } from "modules/categories/redux/selectors";
import { ComponentContainer, WatchListButton } from "./styles";
import WatchListDropdown, { WatchListItem } from "./WatchListDropdown";
import { toast } from "components/commons/Toast";
import { addTickersToListByName } from "modules/categories/redux";

interface Props {
  tickers: string[];
}

const WatchListScreeners: FC<Props> = ({ tickers }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userFavoriteList = useSelector(userFavoriteListSelector);

  const [isVisibleDropDown, setIsVisibleDropDown] = useState<boolean>(false);

  const list: WatchListItem[] = useMemo(() => {
    return userFavoriteList.map((favoriteList) => {
      return {
        nameVi: favoriteList.nameVi,
        name: favoriteList.name,
        isBelongToWatchlist: tickers.every((ticker) =>
          favoriteList.listTickers.includes(ticker)
        ),
      } as WatchListItem;
    });
  }, [userFavoriteList, tickers]);

  function handleAddToList(listName: string) {
    if (!list.length) {
      toast("widgets.TickerInfo.errors.noWatchlistToAdd", "warning");
      return;
    }
    dispatch(addTickersToListByName({ tickers, listName }));
  }

  const clickShowDropdown = () => {
    if (!list.length) {
      toast("widgets.TickerInfo.errors.noWatchlistToAdd", "warning");
      return;
    }
    setIsVisibleDropDown(true);
  };

  const clickCloseDropdown = () => {
    setIsVisibleDropDown(false);
  };

  return (
    <ComponentContainer>
      <WatchListButton>
        <Button
          className="button-watch-list"
          color="accept"
          onClick={clickShowDropdown}
        >
          {intl.formatMessage(
            {
              id: "screeners.card.table.plusButton",
            },
            { number: tickers.length }
          )}
        </Button>
      </WatchListButton>
      {isVisibleDropDown && (
        <WatchListDropdown
          list={list}
          onAddToList={handleAddToList}
          onClose={clickCloseDropdown}
          placement="top"
        />
      )}
    </ComponentContainer>
  );
};

export default WatchListScreeners;
