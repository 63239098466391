import styled from "styled-components/macro";

export const TableContainer = styled.div`
  /* flex: 48; */
  height: 100%;
  overflow: auto;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  background-color: ${({ theme }) => theme.background1};

  // Scroll Stiker - start
  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      thead {
        tr > th {
          top: 0px;
          position: sticky;
        }
      }
      tbody {
        tr > td {
          &:first-child {
            padding-right: 4px;
            padding-left: 12px;
          }
        }
      }
    }
  }
  // Scroll Stiker - end
`;

export const Header = styled.div`
  /* flex: 13; */
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background1};
  .button-wrapper {
    margin-top: auto;
  }
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
    max-width: 240px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid #d6d6d62a;
    background: transparent;
    border-radius: 10px;
  }
`;

export const Footer = styled.div`
  /* flex: 12; */
  padding: 20px 16px 16px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background1};

  .button-wrapper {
    margin-top: auto;
  }
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
    max-width: 240px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid #d6d6d62a;
    background: transparent;
    border-radius: 10px;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  flex: 2;
`;

export const ComboBox = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex ? flex : 3)};
`;

export const ButtonRow = styled.div`
  .flex-1 {
    flex: 1;
  }
`;

export const HeaderModal = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  background-color: ${({ theme }) => theme.background3};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 15px;
`;

export const ModalTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const BodyModal = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.background1};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const ActionsRow = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonCell = styled.div`
  flex: 1;
`;
