import styled from "styled-components/macro";

export const ContainerS = styled.div<{
  isVisibleSideCard: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
export const Container = styled.div`
  height: 100%;

  .infinite-scroll-component__outerdiv {
    height: calc(100% - 34px);
    overflow: auto;
    .infinite-scroll-component {
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }

      tfoot {
        tr > th > div {
          font-size: ${({ theme }) => theme.typeFaces.headline.size};
          font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
          line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
          color: ${({ theme }) => theme.grey0};
        }
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.red;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green;
      case "violet":
        return theme.violet;
      case "orange":
        return theme.solidOrange;
    }
  }};
  height: calc(100% - 65px);
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.grey20};
  height: ${({ theme }) => theme.tables.header.height};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;
  height: 28px;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
  box-shadow: ${({ theme }) => theme.lineDown};
`;

export const ColorRed = styled.div`
  color: ${({ theme }) => theme.red50};
`;
export const ColorGreen = styled.div`
  color: ${({ theme }) => theme.green50};
`;
export const ColorWhile = styled.div`
  color: ${({ theme }) => theme.grey0};
`;

export const TableHeaderCellContent = styled.div<{
  align?: string;
  isFooter?: boolean;
  fixWidth?: number;
  setHeight?: number;
}>`
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  padding: 0 8px;
  display: flex;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  align-items: center;
  height: ${({ theme }) => theme.tables.contentLarge.height};
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  ${({ theme, color, isFooter }) =>
    isFooter
      ? `
    color: ${color ? theme.grey0 : theme.grey0};
    font-weight: ${theme.typeFaces.caption10.weight.semiBold};
  `
      : `
    color: ${color ? theme.grey0 : theme.grey0};
  `}
  text-transform: uppercase;
  background: ${({ theme }) => theme.bgElevated3};
  border: none;
`;

export const Icon = styled.div`
  margin-right: 7px;
  margin-top: -4px;
  display: flex;
  justify-content: center;
`;

export const ButtonRefresh = styled.button`
  background: transparent;
  padding: 0px;
`;
