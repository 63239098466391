/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select, InputTextarea } from "components/commons";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import { updateTypeTranfer } from "modules/transfer-internal/redux";
// import moment from "moment";

import numeral from "numeral";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "./ConfirmModal";
import {
  DetailCheckbox,
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailRow,
  DetailTextareaRow,
  DetailWrapper,
  TextDetail,
} from "./styles";
import CheckboxTransfer from "./CheckboxTransfer";
import { statusTypeTransfer } from "modules/transfer-internal/constants";
import {
  calculateTransferCashFeeAmt,
  createDerTTransferCashApi,
  getTransferAvailDert,
} from "core/http/apis/transfer-internal";
import { toast } from "components/commons/Toast";
import themes from "themes/abstracts/_themes";
import { RootState } from "redux/store";
import { getTypeTransferSelector } from "modules/transfer-internal/selectors";

const RequestDetail = () => {
  const typeTranfer = useSelector(getTypeTransferSelector);
  const intl = useIntl();
  const dispatch = useDispatch();
  const listAcc = GetAccoList(GetAccountType.DER, EntrustBusinessCode.Cash);
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const typeTransfeOptions = useMemo(() => statusTypeTransfer(intl), [intl]);

  const account1Options: any[] = useMemo(() => {
    const list = listAcc
      .map((account: any) => {
        if (account?.subAccoNo.includes(".50"))
          return {
            label: account?.subAccoNo!,
            value: account?.subAccoNo!,
          };
      })
      .filter((e: any) => e);
    return list;
  }, [listAcc]);

  const account2Options: any[] = useMemo(() => {
    const list = listAcc
      .map((account: any) => {
        if (account?.subAccoNo.includes(".51"))
          return {
            label: account?.subAccoNo!,
            value: account?.subAccoNo!,
          };
      })
      .filter((e: any) => e);
    return list;
  }, [listAcc]);

  const valueTypeTransfer = useMemo(() => {
    return typeTransfeOptions.filter((e) => e.value === typeTranfer);
  }, [typeTranfer]);

  const [modalConfirm, setmModalConfirm] = useState(false);

  const initialStat = {
    tradeType: typeTranfer,
    subAccoNoFrom: "",
    subAccoNoTo: "",
    amount: "",
    feeType: "",
    feeAmt: 0,
    remarks: "",
  };

  const [form, setForm] = useState<any>(initialStat);
  const [avail, setAvail] = useState(0);

  const changeForm = (value: any, key: string) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const defaultOption = {
    subAccForm: { label: "", value: "" },
    subAccTo: { label: "", value: "" },
  };

  const [selectOption, setSelectOption] = useState(defaultOption);

  const [checkBox, setCheckBox] = useState<number | null>();

  const conFirmAdd = async () => {
    try {
      const res = await createDerTTransferCashApi({
        ...form,
        tradeType: typeTranfer === "3" ? "1" : typeTranfer,
        amount: numeral(form.amount).value(),
      });
      if (res?.statusCode === 0) {
        if (typeTranfer === "2") {
          toast("transferInternal.formAdd.modal.messSuccess", "success");
        } else {
          toast("transferInternal.formRemove.modal.messSuccess", "success");
        }
        setmModalConfirm(false);
        resetForm();
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error) {}
  };

  const [error, setError] = useState<string | undefined>("");

  const checkError = () => {
    const value = Object.keys(form).find(
      (key) => form[key] === "" || form[key] === 0 || form[key] === null
    );
    if (value !== "remarks" && value) {
      setError(value);
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const openModalConfirm = () => {
    const formatAmount: any = form.amount ? numeral(form.amount).value() : -1;
    if (formatAmount < 0) {
      toast("transferInternal.errorAmountDeposit.error1", "error");
      return;
    }
    if (formatAmount > avail) {
      toast("transferInternal.errorAmountDeposit.error2", "error");
      return;
    }
    if (form?.feeType === 1 && formatAmount >= avail) {
      toast("transferInternal.errorAmountDeposit.error3", "error");
      return;
    }
    const amountT = Number(form?.feeAmt) + formatAmount;

    if (form?.feeType === 2 && amountT >= avail) {
      toast("transferInternal.errorAmountDeposit.error4", "error");
      return;
    }
    const check = checkError();
    if (check) {
      setmModalConfirm(true);
    }
  };

  const onBlurGetFee = async (e: ChangeEvent<HTMLInputElement>) => {
    const res: any = await calculateTransferCashFeeAmt({
      amount: `${numeral(e?.target?.value).value()}`,
      feeType: "2",
    });
    if (res.statusCode === 0) {
      changeForm(res?.data, "feeAmt");
    }
  };

  useEffect(() => {
    changeForm(typeTranfer, "tradeType");
    changeSubaccFrom(
      typeTranfer === "2" ? account1Options[0] : account2Options[0]
    );
    changeForm(
      typeTranfer === "2"
        ? account1Options[0]?.value
        : account2Options[0]?.value,
      "subAccoNoFrom"
    );
    setForm({
      ...form,
      subAccoNoFrom:
        typeTranfer === "2"
          ? account1Options[0]?.value
          : account2Options[0]?.value,
      subAccoNoTo:
        typeTranfer === "2"
          ? account2Options[0]?.value
          : account1Options[0]?.value,
    });
    // resetForm();
  }, [typeTranfer]);

  const changeSubaccFrom = async (e: any) => {
    await setSelectOption({
      ...selectOption,
      subAccForm: e,
    });
    const res: any = await getTransferAvailDert({
      subAccoNo: e?.value,
      tradeType: typeTranfer === "2" ? 2 : 1,
    });
    if (res?.statusCode === 0) {
      setAvail(res?.data?.transferAvail);
    }
  };

  const resetForm = () => {
    setForm({
      ...form,
      amount: "",
    });
    // setSelectOption(defaultOption);
    setCheckBox(null);
    // setAvail(0);
  };

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.action" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              className="flex-fill"
              value={valueTypeTransfer}
              options={typeTransfeOptions}
              onChange={(e: any) =>
                dispatch(updateTypeTranfer({ type: e.value }))
              }
            />
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.fromSubAccoNo" />
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              // value={selectOption.subAccForm}
              value={
                typeTranfer === "2" ? account1Options[0] : account2Options[0]
              }
              options={typeTranfer === "2" ? account1Options : account2Options}
              onChange={(e: any) => {
                changeForm(e?.value, "subAccoNoFrom");
                changeSubaccFrom(e);
                setSelectOption({
                  ...selectOption,
                  subAccForm: e,
                });
              }}
            />
            {error === "subAccoNoFrom" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.toSubAccoNo" />
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              // value={selectOption?.subAccTo}
              value={
                typeTranfer === "2" ? account2Options[0] : account1Options[0]
              }
              options={typeTranfer === "2" ? account2Options : account1Options}
              onChange={(e: any) => {
                setSelectOption({
                  ...selectOption,
                  subAccTo: e,
                });
                changeForm(e.value, "subAccoNoTo");
              }}
            />
            {error === "subAccoNoTo" && (
              <span className="text-error text-danger">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.transferAvailability" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{numeral(avail).format("0,0")}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.amount" />
          </DetailLabel>
          <DetailInputWrapper>
            <Input
              placeholder={intl.formatMessage({
                id: "transferInternal.formAdd.form.amount",
              })}
              // rules={{ required: true }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                changeForm(e.target.value, "amount")
              }
              onBlur={onBlurGetFee}
              value={numeral(form?.amount).format("0,0")}
            />
            {error === "amount" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow className="mb-0">
          <DetailLabel></DetailLabel>
          <DetailCheckbox className="text-right">
            <CheckboxTransfer
              checked={checkBox === 1 ? true : false}
              disabled={false}
              values={1}
              onClick={(e) => {
                setCheckBox(e);
                changeForm(e, "feeType");
              }}
              text="transferInternal.historyTransferBank.detail.typeAmount.value0"
            />
            <CheckboxTransfer
              checked={checkBox === 2 ? true : false}
              disabled={false}
              values={2}
              onClick={(e) => {
                setCheckBox(e);
                changeForm(e, "feeType");
              }}
              text="transferInternal.historyTransferBank.detail.typeAmount.value1"
            />
          </DetailCheckbox>
        </DetailRow>

        <DetailRow>
          <div className="w-100 text-right">
            {error === "feeType" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </div>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.fee" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{numeral(form?.feeAmt).format("0,0")}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailTextareaRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.formAdd.form.content" />
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <InputTextarea
              textArea={true}
              onChange={(e: any) => changeForm(e.target.value, "remarks")}
              value={form.remarks}
            />
          </DetailInputWrapper>
        </DetailTextareaRow>

        <DetailRow>
          <p style={{ color: themes[themeType].textColorBlue }}>
            <FormattedMessage id="transferInternal.deposit.note" />
          </p>
        </DetailRow>
      </DetailCol>
      <div className="d-flex justify-content-center p-2">
        <Button
          color="accept"
          className="button-filter mr-2  w-50"
          onClick={() => resetForm()}
          // loading={loadding}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.cancel",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter w-50"
          onClick={() => openModalConfirm()}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.accept",
          })}
        </Button>
      </div>
      {modalConfirm && (
        <ConfirmModal
          isVisible={modalConfirm}
          onClose={() => setmModalConfirm(false)}
          data={{
            ...form,
            avail,
          }}
          onSuccess={conFirmAdd}
        />
      )}
    </DetailWrapper>
  );
};

export default RequestDetail;
