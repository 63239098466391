import React, { useState } from "react";
import { Button, OTPByBusinessCd, Spacer } from "components/commons";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { Container, Title, Body, Footer } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelDertRequestCond,
  dertCancelMultiOrder,
} from "modules/stock-order-der/redux/OrderBookDer";
// import {
//   checkCancelOrderDer,
//   checkCancelOrderDerCond,
// } from "helper/tradingHelper";
import { toast } from "components/commons/Toast";
import {
  conditionOrderBookDerSelector,
  selectedCondRowsSelector,
  selectedRowsSelector,
} from "modules/stock-order-der/selectors";
// import {
//   OrderBookDerCondDisplay,
//   OrderBookDerDisplay,
// } from "domain/models/OrderBookDer";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import {
  ICancelDertRequestCondItem,
  ICancelDertRequestCondParams,
  IDertCancelMultiOrderParams,
} from "core/http/apis/tradeinfo/types";
import { isAuthenticatedSelector } from "modules/auth/selectors";

interface Props {
  showModal: boolean;
  setShowModal: any;
}

const ConfirmCancelOrderModal: React.FC<Props> = ({
  showModal,
  setShowModal,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  const selectedRows = useSelector(selectedRowsSelector);
  const selectedCondRows = useSelector(selectedCondRowsSelector);
  const condition = useSelector(conditionOrderBookDerSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  // const checkOrder = async (item: OrderBookDerDisplay) => {
  //   const result = await checkCancelOrderDer(item, intl);
  //   if (result === null) return true;
  //   toast(result, "error", "error.defaultErr", false);
  //   setIsError(true);
  //   return false;
  // };

  // const checkOrderDerCond = async (item: OrderBookDerCondDisplay) => {
  //   const result = await checkCancelOrderDerCond(item, intl);
  //   if (result === null) return true;
  //   toast(result, "error", "error.defaultErr", false);
  //   setIsError(true);
  //   return false;
  // };

  const handleModal = () => {
    setVisibleModalOTP(false);
    setLoading(false);
  };

  const doSubmitCancelOrder = async (response: IVerifyResponse) => {
    setLoading(false);
    if (condition.value === "01") {
      let sumSuccess = 0;
      let paramsList: string[] = [];
      if (selectedRows && selectedRows.length > 0) {
        for (let i = 0; i < selectedRows.length; i++) {
          sumSuccess += 1;
          const params: string =
            selectedRows[i].tradeDate?.toString() +
            ";" +
            selectedRows[i].orgOrderNo?.toString() +
            ";" +
            selectedRows[i].ordChanel?.toString() +
            ";" +
            selectedRows[i].updLongTime?.toString();
          paramsList.push(params);
        }

        // Huy thanh cong 3/5
        let content = `${intl.formatMessage({
          id: "orderBookDer.cancelSucces",
        })} ${sumSuccess}/${selectedRows.length}`;

        if (sumSuccess === 0) {
          // if (selectedRows.length > 1 && !isError) {
          //   toast("common.errorDelete", "error");
          // }
          toast("common.errorDelete", "error");
          return;
        }

        const paramCancelList: IDertCancelMultiOrderParams = {
          input: paramsList,
          otpCode: response?.otp,
        };

        try {
          dispatch(
            dertCancelMultiOrder({
              params: paramCancelList,
              intl: intl,
              content: content,
            })
          );
        } catch (error: any) {
          toast("orderBookDer.cancelFail", "error");
        }

        setShowModal(false);
      } else {
        toast("orderBookDer.cancelFail", "error");
      }
    } else {
      let sumSuccess = 0;
      let paramsList: ICancelDertRequestCondItem[] = [];
      if (selectedCondRows && selectedCondRows.length > 0) {
        for (let i = 0; i < selectedCondRows.length; i++) {
          sumSuccess += 1;
          const paramChild: ICancelDertRequestCondItem = {
            custNo: selectedCondRows[i].custNo,
            refDate: selectedCondRows[i].alloDate,
            refNo: selectedCondRows[i].refNo,
          };
          paramsList.push(paramChild);
        }

        // Huy thanh cong 3/5
        let content = `${intl.formatMessage({
          id: "orderBookDer.cancelSucces",
        })} ${sumSuccess}/${selectedCondRows.length}`;

        if (sumSuccess === 0) {
          // if (selectedCondRows.length > 1 && !isError) {
          //   toast("common.errorDelete", "error");
          // }
          toast("common.errorDelete", "error");
          return;
        }

        const paramCancelList: ICancelDertRequestCondParams = {
          dertRequestConds: paramsList,
          otpCode: response?.otp,
        };

        try {
          dispatch(
            cancelDertRequestCond({
              params: paramCancelList,
              intl: intl,
              content: content,
            })
          );
        } catch (error: any) {
          toast("orderBookDer.cancelFail", "error");
        }

        setShowModal(false);
      } else {
        toast("orderBookDer.cancelFail", "error");
      }
    }
  };

  const handleCancelOrder = () => {
    if (isAuthenticated) {
      if (condition.value === "01") {
        if (selectedRows && selectedRows.length > 0) {
          setVisibleModalOTP(true);
        } else {
          setLoading(false);
          toast("orderBook.errorCheckCancel", "error");
        }
      } else {
        if (selectedCondRows && selectedCondRows.length > 0) {
          setVisibleModalOTP(true);
        } else {
          setLoading(false);
          toast("orderBook.errorCheckCancel", "error");
        }
      }
    }
  };

  return (
    <>
      <Modal
        dialogClassName="modal-434"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Container>
          <Title>
            {intl
              .formatMessage({ id: "orderBookDer.comfirmTitle" })
              .toUpperCase()}
          </Title>
          <Body>
            <FormattedMessage id="orderBookDer.comfirmMessage" />
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={() => setShowModal(false)}
            >
              <FormattedMessage id="orderBookDer.cancel" />
            </Button>
            <Spacer />
            <Button
              fill
              color="accept"
              className="flex-1"
              onClick={() => handleCancelOrder()}
              loading={loading}
              disabled={loading}
            >
              <FormattedMessage id="orderBookDer.accept" />
            </Button>
          </Footer>
        </Container>
        {visibleModalOTP && (
          <OTPByBusinessCd
            handleClose={handleModal}
            getResult={doSubmitCancelOrder}
            businessCd={EBusinessCd.TRADING}
          />
        )}
      </Modal>
    </>
  );
};

export default ConfirmCancelOrderModal;
