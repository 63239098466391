import { FC, useState } from "react";
import { Container, WrapLeft, WrapRight, LabelLeft, BodyLeft } from "./styles";
import { MarketTickerGraph } from "modules/tickerDetails/components";
import { FormattedMessage } from "react-intl";
import { ReactComponent as IconRight } from "assets/image/svg/techanalysisRight.svg";
import { ReactComponent as IconLeft } from "assets/image/svg/techAnalysisLeft.svg";
import ContentRight from "./ContentRight";

const TechAnalysis: FC = () => {
  const [hideFormRight, setHideFormRight] = useState<boolean>(false);
  return (
    <Container>
      <WrapLeft hide={hideFormRight}>
        <LabelLeft>
          <FormattedMessage id="techAnalysis.labelLeft" />
          {hideFormRight ? (
            <IconLeft onClick={() => setHideFormRight(false)} />
          ) : (
            <IconRight onClick={() => setHideFormRight(true)} />
          )}
        </LabelLeft>
        <BodyLeft>
          <MarketTickerGraph />
        </BodyLeft>
      </WrapLeft>
      <WrapRight hide={hideFormRight}>
        <ContentRight />
      </WrapRight>
    </Container>
  );
};

export default TechAnalysis;
