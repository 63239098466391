import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import CommonTable from "components/commons/CommonTable";
import { Body } from "./styles";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";

function checkMrtype(value: string) {
  switch (value) {
    case "N":
      return "common.normalType";
    case "T":
      return "common.marginType";
    default:
      return value;
  }
}

function TabVaacctnolist() {
  const flexStart = "flex-start";
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.All
  );
  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="TIỂU KHOẢN" />
          </TableHeaderCellContent>
        ),
        accessor: "accountno",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="LOẠI TK" />
          </TableHeaderCellContent>
        ),
        accessor: "mrtype",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              <FormattedMessage id={checkMrtype(props.value)} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={flexStart}>
            <FormattedMessage id="MÃ ĐỊNH DANH" />
          </TableHeaderCellContent>
        ),
        accessor: "vaacctnolist",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  return (
    <Body padding={8}>
      <div className="table-identification">
        <CommonTable columns={columns} data={listAcc || []} />
      </div>
    </Body>
  );
}

export default memo(TabVaacctnolist);
