import styled from "styled-components/macro";

export const Container = styled.div`
  height: calc(100% - 35px);
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% - 30px) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;
