import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const profitLossSelector = (state: RootState) => state.profitLoss;

export const activeTabSelector = createSelector(
  profitLossSelector,
  (state) => state.activeTab
);

export const listProfitLossSelector = createSelector(
  profitLossSelector,
  (state) => state.list
);

export const loadingSearchDataSelector = createSelector(
  profitLossSelector,
  (state) => state.loading
);

export const filterProfitLossSelector = createSelector(
  profitLossSelector,
  (state) => state.filter
);
export const chartDataProfitLossSelector = createSelector(
  profitLossSelector,
  (state) => state.chart
);

export const selectCurrentPage = createSelector(
  profitLossSelector,
  (state) => state.currentPage
);

export const selectLimit = createSelector(
  profitLossSelector,
  (state) => state.limit
);

export const selectEnableInfinity = createSelector(
  profitLossSelector,
  (state) => state.enableInfinity
);
