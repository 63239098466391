import PlaceOrderTransferBondsContent from "./components";
import Container from "components/commons/Container";
import { useIntl } from "react-intl";
import InformationOrder from "./components/InformationOrder";

function PlaceOrderTransferBond() {
  const intl = useIntl();
  const arrayForm = [PlaceOrderTransferBondsContent, InformationOrder];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "placeOrderTransferBonds.placeBook.title",
      })}
      headerForm={intl.formatMessage({
        id: "placeOrderTransferBonds.infomationPlace.title",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
}

export default PlaceOrderTransferBond;
