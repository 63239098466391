import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import StockOrderChart from "components/commons/Charts/StockOrderChart";
import {
  getTickerChart,
  getTickerChartUpdate,
} from "modules/stock-order/redux/tickerChart";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { LastSale, SystemState } from "domain/protoNew/auto_trading_pb";
import {
  basicpriceTickerChartSelector,
  tickerChartSelector,
} from "modules/stock-order/selectors";

export type TickerChartProps = {
  selectedTicker: string | null;
  isShowVn30Button?: boolean;
  isStockOrderDer?: boolean;
};

function TickerChartWidget({
  selectedTicker,
  isShowVn30Button = false,
  isStockOrderDer = true,
}: TickerChartProps) {
  const dataChart = useSelector(tickerChartSelector);
  const basicprice = useSelector(basicpriceTickerChartSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTicker !== null && selectedTicker !== "") {
      dispatch(getTickerChart(selectedTicker));
    }
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (
        selectedTicker !== null &&
        data.status === SystemState.StatusCode.AVAILABLE
      ) {
        dispatch(getTickerChart(selectedTicker));
      }
    };
    const eventListenerLastSale = (data: LastSale.AsObject) => {
      if (data.seccd === selectedTicker) {
        dispatch(getTickerChartUpdate(data));
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  }, [selectedTicker]);

  return (
    <StockOrderChart dataChart={dataChart} thresholdValue={basicprice || 0} />
  );
}

export default TickerChartWidget;
