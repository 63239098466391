import { toast } from "components/commons/Toast";
import {
  addTransferInternalApi,
  deleteTransferBank,
  findParameterBankDetail,
  findTransferContractLocal,
  getAccountBanks,
  getDerCashTransList,
  getSubAccToLocalList,
  getTransferBankApi,
  getTransferContractBank,
  inquiryAccountTra,
  inquiryAccountTransBank,
} from "core/http/apis/transfer-internal";
import {
  IAccountTransferInternalResponse,
  IfindParameterBankDetail,
  IGetAccountTransfer,
} from "core/http/apis/transfer-internal/types";
import { searchTransferLocal } from "core/http/apis/transfer-local";
import { ISearchTransferLocalParams } from "core/http/apis/transfer-local/types";
import {} from "domain/models/BasePairValue";
import {} from "domain/models/ParameterBankData";
import {} from "domain/models/TransferContractLocal";
import { ITransferLocalResponse } from "domain/models/TransferLocal";
import {} from "domain/models/TransferLocalModel";
import { all, call, put, takeLatest } from "redux-saga/effects";

// import { RootState } from "redux/store";
import {
  addTransferInternal,
  deletTransferBank,
  deletTransferBankSuccess,
  // closeModal,
  deletTransferInternal,
  deletTransferInternalFail,
  deletTransferInternalSuccess,
  fetchModeTransferBank,
  fetchModeTransferBankSuccess,
  fetchModeTransferDepoist,
  fetchModeTransferDepoistSuccess,
  fetchModeTransferInterbal,
  fetchModeTransferInterbalSuccess,
  fetchModeTransferWithdrawalSuccess,
  FormSubmit,
  getAccoToLocalSuccessL,
  getAccountGet,
  getAccountGetfail,
  getAccountGetSuccess,
  getAccountTransfer,
  getAccountTransferfail,
  getAccountTransferSuccess,
  getAccToTransferInternal,
  getAccToTransferInternalSuccess,
  getBankDetail,
  getBankDetailSuccess,
  getDataDeposit,
  getDataDepositFail,
  getDataDepositSuccess,
  getDatawithdrawalSuccess,
  getlistSubAccoToList,
  getlistSubAccoToListFail,
  getlistSubAccoToListSuccess,
  getListTransferBank,
  getListTransferBankSuccess,
  getSubAccoToList,
  getSubAccoToListFail,
  getSubAccoToListSuccess,
  getSubAccoTransferBank,
  getSubAccoTransferBankFail,
  getSubAccoTransferBankSuccess,
  ITransferLocalFilter,
  searchTransferLocalFailure,
  searchTransferLocalRequest,
  searchTransferLocalSuccess,
  updateCurrentPage,
} from "./redux";
import { IParamGetDeposit, IParamsGetSubAccoTo } from "./types";
// import {} from "./redux";

function* searchTransferLocalWorker(
  action: ReturnType<typeof searchTransferLocalRequest>
) {
  try {
    const params: ISearchTransferLocalParams = action.payload;
    const response: ITransferLocalResponse = yield call(
      searchTransferLocal,
      params
    );
    if (response?.statusCode === 0) {
      yield put(searchTransferLocalSuccess(response));
    } else {
      yield put(searchTransferLocalFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferLocalFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchTransferLocalWatcher() {
  yield takeLatest(searchTransferLocalRequest.type, searchTransferLocalWorker);
}

// lây tài khoản chuyển
function* searchAccountTransfer() {
  yield takeLatest(getAccountTransfer.type, searchAccountTransferWorker);
}
function* searchAccountTransferWorker(
  action: ReturnType<typeof getAccountTransfer>
) {
  try {
    const params: IGetAccountTransfer = action.payload;
    const response: IAccountTransferInternalResponse = yield call(
      inquiryAccountTra,
      params
    );
    if (response?.statusCode === 0) {
      yield put(getAccountTransferSuccess(response.data));
    } else {
      yield put(getAccountTransferfail());
      toast(response?.errorCode, "error", response?.message, true);
    }

    const responseAccoTo: IAccountTransferInternalResponse = yield call(
      getSubAccToLocalList,
      { subAccNo: params.subAccoNo }
    );
    if (responseAccoTo?.statusCode === 0) {
      yield put(getAccoToLocalSuccessL(responseAccoTo.data));
    } else {
      toast(responseAccoTo?.errorCode, "error", responseAccoTo?.message, true);
    }
  } catch (error: any) {
    yield put(getAccountTransferfail());
    toast(error?.code, "error", error?.description, true);
  }
}

// lây tài khoản nhận
function* searchAccountGet() {
  yield takeLatest(getAccountGet.type, searchAccountGetWorker);
}
function* searchAccountGetWorker(action: ReturnType<typeof getAccountGet>) {
  try {
    const params: IGetAccountTransfer = action.payload;
    const response: IAccountTransferInternalResponse = yield call(
      inquiryAccountTra,
      params
    );
    if (response?.statusCode === 0) {
      yield put(getAccountGetSuccess(response.data));
    } else {
      yield put(getAccountGetfail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getAccountGetfail());
    toast(error?.code, "error", error?.description, true);
  }
}

// lây tài khoản nhận
function* addTransferInternallist() {
  yield takeLatest(addTransferInternal.type, addTransferInternalSaga);
}
function* addTransferInternalSaga(
  action: ReturnType<typeof addTransferInternal>
) {
  try {
    const params: FormSubmit = action.payload;
    const response: IAccountTransferInternalResponse = yield call(
      addTransferInternalApi,
      params
    );
    if (response?.statusCode === 0) {
      toast(
        "transferInternal.transferInternal.detail.modal.toastSuccess",
        "success"
      );
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// xóa chuyển khaorn nội bộ
function* deleteTransferInternal() {
  yield takeLatest(deletTransferInternal.type, deleteTransferInternalSaga);
}
function* deleteTransferInternalSaga(
  action: ReturnType<typeof addTransferInternal>
) {
  try {
    const params: FormSubmit = action.payload;
    const response: IAccountTransferInternalResponse = yield call(
      addTransferInternalApi,
      params
    );
    if (response?.statusCode === 0) {
      toast("transferInternal.historyTransfer.messageFail", "success");
      yield put(deletTransferInternalSuccess());
    } else {
      yield put(deletTransferInternalFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListTransferBankWorker() {
  yield takeLatest(getListTransferBank.type, getListTransferBankSaga);
}

function* getListTransferBankSaga(
  action: ReturnType<typeof getListTransferBank>
) {
  try {
    const params: ITransferLocalFilter = action.payload;
    const response: IAccountTransferInternalResponse = yield call(
      getTransferBankApi,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getListTransferBankSuccess(response?.data));
    } else {
      yield put(deletTransferInternalFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy danh sách tài khaorn nhận
function* getListSubAccoToWorker() {
  yield takeLatest(getlistSubAccoToList.type, getListSubAccoToSaga);
}

function* getListSubAccoToSaga(
  action: ReturnType<typeof getlistSubAccoToList>
) {
  try {
    const params: { subAccoNo: string } = action.payload;
    const response: {
      data: { bankAccNumber: string }[];
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(getAccountBanks, params);

    if (response?.statusCode === 0) {
      yield put(getlistSubAccoToListSuccess(response?.data));
    } else {
      yield put(getlistSubAccoToListFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy thông tin tài khaorn nhận
function* getSubAccoToWorker() {
  yield takeLatest(getSubAccoToList.type, getSubAccoToSaga);
}

function* getSubAccoToSaga(action: ReturnType<typeof getSubAccoToList>) {
  try {
    const params: { subAccCd?: number; toSubAccountNo: string } =
      action.payload;
    const response: {
      data: IParamsGetSubAccoTo;
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(getTransferContractBank, params);

    if (response?.statusCode === 0) {
      yield put(getSubAccoToListSuccess(response?.data));
    } else {
      yield put(getSubAccoToListFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy thông tin tài khaorn nhận bank
function* getSubAccoFromWorker() {
  yield takeLatest(getSubAccoTransferBank.type, getSubAccoFromSaga);
}

function* getSubAccoFromSaga(
  action: ReturnType<typeof getSubAccoTransferBank>
) {
  try {
    const params: { subAccoNo: string } = action.payload;
    const response: {
      data: IParamsGetSubAccoTo;
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(inquiryAccountTransBank, params);

    if (response?.statusCode === 0) {
      yield put(getSubAccoTransferBankSuccess(response?.data));
    } else {
      yield put(getSubAccoTransferBankFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy thông chi tiết bank
function* getDetailBankWorker() {
  yield takeLatest(getBankDetail.type, getDetailBankFromSaga);
}

function* getDetailBankFromSaga(action: ReturnType<typeof getBankDetail>) {
  try {
    const params: IfindParameterBankDetail = action.payload;
    const response: {
      data: any;
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(findParameterBankDetail, params);

    if (response?.statusCode === 0) {
      yield put(getBankDetailSuccess(response?.data));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy thông chi tiết bank
function* getAccToTransferInternalCall() {
  yield takeLatest(getAccToTransferInternal.type, getAccToTransferInternalSaga);
}

function* getAccToTransferInternalSaga(
  action: ReturnType<typeof getAccToTransferInternal>
) {
  try {
    const params: {
      subAccCd?: number;
      toSubAccountNo?: string;
      checkContract?: number;
    } = action.payload;
    const response: {
      data: any;
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(findTransferContractLocal, params);

    if (response?.statusCode === 0) {
      yield put(getAccToTransferInternalSuccess(response?.data));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy thông chi tiết bank
function* deletTransferBankCall() {
  yield takeLatest(deletTransferBank.type, deletTransferBankSaga);
}

function* deletTransferBankSaga(action: ReturnType<typeof deletTransferBank>) {
  try {
    const params: FormSubmit = action.payload;
    const response: {
      data: any;
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(deleteTransferBank, params);
    if (response?.statusCode === 0) {
      toast("transferInternal.historyTransfer.messageFail", "success");
      yield put(deletTransferBankSuccess());
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// lấy thông
function* getDataDepositAction() {
  yield takeLatest(getDataDeposit.type, getDataDepositSaga);
}

function* getDataDepositSaga(action: ReturnType<typeof getDataDeposit>) {
  try {
    const params: IParamGetDeposit = action.payload;
    const response: {
      data: any;
      errorCode: string;
      errorField: string;
      message: string;
      statusCode: number;
    } = yield call(getDerCashTransList, params);
    if (response?.statusCode === 0) {
      if (params.tradeType === 2) {
        yield put(getDataDepositSuccess(response?.data));
      } else {
        yield put(getDatawithdrawalSuccess(response?.data));
      }
    } else {
      yield put(getDataDepositFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDataTransferBank() {
  yield takeLatest(fetchModeTransferBank.type, fetchMoreDataTransferBankSaga);
}

function* fetchMoreDataTransferBankSaga(
  action: ReturnType<typeof fetchModeTransferBank>
) {
  try {
    const params: ITransferLocalFilter = action.payload;
    const response: IAccountTransferInternalResponse = yield call(
      getTransferBankApi,
      params
    );

    if (response?.statusCode === 0) {
      yield put(fetchModeTransferBankSuccess(response?.data));
    } else {
      yield put(deletTransferInternalFail());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDataTransferInternalsaga(
  action: ReturnType<typeof fetchModeTransferInterbal>
) {
  try {
    const params: ISearchTransferLocalParams = action.payload;
    const response: ITransferLocalResponse = yield call(
      searchTransferLocal,
      params
    );
    if (response?.statusCode === 0) {
      yield put(updateCurrentPage(params?.page ? params?.page : 0));
      yield put(fetchModeTransferInterbalSuccess(response?.data));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferLocalFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDataTransferInternal() {
  yield takeLatest(
    fetchModeTransferInterbal.type,
    fetchMoreDataTransferInternalsaga
  );
}

function* fetchMoreDataTransferDepoistSaga(
  action: ReturnType<typeof fetchModeTransferDepoist>
) {
  try {
    const params: IParamGetDeposit = action.payload;
    const response: ITransferLocalResponse = yield call(
      getDerCashTransList,
      params
    );
    if (response?.statusCode === 0) {
      if (params.tradeType === 2) {
        yield put(fetchModeTransferDepoistSuccess(response?.data));
      } else {
        yield put(fetchModeTransferWithdrawalSuccess(response?.data));
      }
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferLocalFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreDataTransferDepoist() {
  yield takeLatest(
    fetchModeTransferDepoist.type,
    fetchMoreDataTransferDepoistSaga
  );
}

export default function* transferLocalSaga() {
  yield all([
    searchTransferLocalWatcher(),
    searchAccountTransfer(),
    searchAccountGet(),
    addTransferInternallist(),
    deleteTransferInternal(),
    getListTransferBankWorker(),
    getListSubAccoToWorker(),
    getSubAccoToWorker(),
    getSubAccoFromWorker(),
    getDetailBankWorker(),
    getAccToTransferInternalCall(),
    deletTransferBankCall(),
    getDataDepositAction(),
    fetchMoreDataTransferBank(),
    fetchMoreDataTransferInternal(),
    fetchMoreDataTransferDepoist(),
  ]);
}
