export const FLEX = {
  START: "flex-start",
  CENTER: "center",
  END: "flex-end",
};

export enum SORT_BY {
  UNKNOW = "UNKNOW",
  TICKER = "secdetailinfo.seccd",
  SUM_VOLUME = "secdetailinfo.totalqty",
  PERCENT = "secdetailinfo.changepercent",
}

export enum ORDER {
  UNKNOW = "",
  DESC = "desc",
  ASC = "asc",
}

export const COLUMNS = [
  {
    width: 120,
    name: "widgets.watchlist.ticker",
    position: "START",
    sortBy: SORT_BY.TICKER,
    flexCell: 3,
  },
  {
    width: 100,
    name: "widgets.watchlist.sumVolume",
    position: "END",
    sortBy: SORT_BY.SUM_VOLUME,
  },
  {
    width: 60,
    name: "widgets.watchlist.percent",
    position: "END",
    sortBy: SORT_BY.PERCENT,
  },
];

export const TIME_BLINK = 600;
