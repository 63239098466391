import { CSSProperties, useCallback, memo } from "react";
import { FLEX, COLUMNS } from "./constant";
import { BodyRowWrapper, TableCellContent, CellValue } from "./styles";
import {
  ChangePricePercentCell,
  ChangePriceValueCell,
  PriceCell,
  VolumeCell,
  CompanyNameCell,
  TickerCell,
} from "./Cells";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
}

export interface Props {
  style: CSSProperties;
  ticker: string;
  rowInd: number;
  isScrolling?: boolean;
}

const columns = COLUMNS;

function TableBodyRow({ style, ticker, rowInd }: Props) {
  const generateTickerCell = useCallback(
    ({
      ticker,
      cellStyle,
    }: {
      cellStyle?: IHeaderCellInfo;
      ticker: string;
    }) => {
      return (
        <TableCellContent
          minWidth={cellStyle?.width}
          align={FLEX.CENTER}
          flexCell={cellStyle?.flexCell}
        >
          <TickerCell ticker={ticker} />
          <CompanyNameCell ticker={ticker} />
        </TableCellContent>
      );
    },
    []
  );

  const generateValueCell = useCallback(
    ({
      ticker,
      cellStyle,
    }: {
      cellStyle?: IHeaderCellInfo;
      ticker: string;
    }) => {
      return (
        <CellValue
          minWidth={cellStyle?.width}
          align={
            cellStyle?.position
              ? FLEX[cellStyle?.position as keyof typeof FLEX]
              : FLEX.START
          }
        >
          <VolumeCell ticker={ticker} />
          <ChangePriceValueCell ticker={ticker} />
        </CellValue>
      );
    },
    []
  );

  const generatePercentCell = useCallback(
    ({
      cellStyle,
      ticker,
    }: {
      cellStyle?: IHeaderCellInfo;
      ticker: string;
    }) => {
      return (
        <CellValue
          minWidth={cellStyle?.width}
          align={
            cellStyle?.position
              ? FLEX[cellStyle?.position as keyof typeof FLEX]
              : FLEX.START
          }
        >
          <PriceCell ticker={ticker} />
          <ChangePricePercentCell ticker={ticker} />
        </CellValue>
      );
    },
    []
  );

  return (
    <BodyRowWrapper style={style} bgColor={rowInd + 1 || 1}>
      {generateTickerCell({
        cellStyle: columns[0],
        ticker,
      })}
      {generateValueCell({
        cellStyle: columns[1],
        ticker,
      })}
      {generatePercentCell({
        cellStyle: columns[2],
        ticker,
      })}
    </BodyRowWrapper>
  );
}

export default memo(TableBodyRow) as React.FC<Props>;
