import {
  sumAskSelector,
  dataDetailInfoSelector,
} from "modules/tickerDetails/redux/selectors";
import { FormattedMessage } from "react-intl";
import RowPrice from "./RowPrice";
import { BuySellContainer, VolumeContainer } from "./styles";
import { useSelector } from "react-redux";
import { getColorPrice } from "helper/utils";

function BuySell() {
  const sumAsk = useSelector(sumAskSelector);
  const basicprice = useSelector(
    dataDetailInfoSelector("basicprice")
  ) as number;
  const ceilingprice = useSelector(
    dataDetailInfoSelector("ceilingprice")
  ) as number;
  const floorprice = useSelector(
    dataDetailInfoSelector("floorprice")
  ) as number;
  const best1offerpricestr = useSelector(
    dataDetailInfoSelector("best1offerpricestr")
  ) as string;
  const best1offerqty = useSelector(
    dataDetailInfoSelector("best1offerqty")
  ) as number;
  const best2offerprice = useSelector(
    dataDetailInfoSelector("best2offerprice")
  ) as number;
  const best2offerqty = useSelector(
    dataDetailInfoSelector("best2offerqty")
  ) as number;
  const best3offerprice = useSelector(
    dataDetailInfoSelector("best3offerprice")
  ) as number;
  const best3offerqty = useSelector(
    dataDetailInfoSelector("best3offerqty")
  ) as number;

  // TODO
  const handleClickPrice = (price: number | string) => {
    // dispatch(onFilter({ price: price }));
    // setTimeout(() => dispatch(onChangePriceByEnter(true)), 150);
  };

  const isSell = true;

  return (
    <BuySellContainer>
      <VolumeContainer isReverse={isSell}>
        <FormattedMessage id="widgets.infoQuote.volume" />
        <div className="text">
          <FormattedMessage id={"common.sell"} />
        </div>
      </VolumeContainer>
      <RowPrice
        price={best1offerpricestr}
        volume={best1offerqty}
        color={getColorPrice(
          best1offerpricestr,
          floorprice,
          ceilingprice,
          basicprice
        )}
        handleClickPrice={handleClickPrice}
        isSell={isSell}
        percent={(best1offerqty / sumAsk) * 100}
      />
      <RowPrice
        price={best2offerprice}
        volume={best2offerqty}
        color={getColorPrice(
          best2offerprice,
          floorprice,
          ceilingprice,
          basicprice
        )}
        handleClickPrice={handleClickPrice}
        isSell={isSell}
        percent={(best2offerqty / sumAsk) * 100}
      />
      <RowPrice
        price={best3offerprice}
        volume={best3offerqty}
        color={getColorPrice(
          best3offerprice,
          floorprice,
          ceilingprice,
          basicprice
        )}
        handleClickPrice={handleClickPrice}
        isSell={isSell}
        percent={(best3offerqty / sumAsk) * 100}
      />
    </BuySellContainer>
  );
}

export default BuySell;
