import numeral from "numeral";
import { Context } from "chartjs-plugin-datalabels";
import { EIndicatorType } from "../redux/financeInfo";
import { FinanceInfoItem } from "domain/models/FinanceInfo";
import { TimeOptionValue } from "components/widgets/details/Finance/types";
import chunk from "lodash/chunk";
import join from "lodash/join";
import map from "lodash/map";
import {
  SectFinanceData,
  SectFinanceItem,
} from "domain/protoNew/auto_trading_pb";

function getMultilineLabel(displayText: string, limitLength: number) {
  const arrayOfChars = Array.from(displayText);
  const chunks = chunk(arrayOfChars, limitLength);
  const result = map(chunks, (chunkItem) => join(chunkItem, ""));
  return result;
}

export const formatDataChartFinance = (
  rawData: SectFinanceData.AsObject,
  timeType: TimeOptionValue,
  type: EIndicatorType,
  colorBar: string,
  formatNumber = "0,0.0",
  usePercentLabel = true
) => {
  let revenue: SectFinanceItem.AsObject[] = [];

  if (type === EIndicatorType.ROE) {
    revenue = rawData.listroeList;
  } else if (type === EIndicatorType.ROA) {
    revenue = rawData.listroaList;
  } else if (type === EIndicatorType.EPS) {
    revenue = rawData.listepsList;
  } else if (type === EIndicatorType.PROFIT) {
    revenue = rawData.listprofitList;
  } else if (type === EIndicatorType.TURNOVER) {
    revenue = rawData.listturnoverList;
  } else if (type === EIndicatorType.BUSINESS_PROFIT) {
    revenue = rawData.listbusinessprofitList;
  } else if (type === EIndicatorType.CASH_FLOW) {
    revenue = rawData.listcashflowList;
  } else if (type === EIndicatorType.INVEST) {
    revenue = rawData.listinvestList;
  } else if (type === EIndicatorType.FINANCE) {
    revenue = rawData.listfinanceList;
  } else {
    revenue = [];
  }

  if (revenue) {
    let labels = [];
    const datasets = [];

    if (timeType === "1") {
      labels = revenue.map((el: any) => el.year);
    }
    if (timeType === "2") {
      labels = revenue.map((el: any) => `Q${el.quarterly}/${el.year}`);
    }
    const ratioBar = {
      type: "bar",
      yAxisID: "yAxis1",
      categoryPercentage: 0.35,
      label: "bar_ratio",
      data: revenue.map((el: any) => {
        if (el.value1 !== undefined) {
          return el.value1;
        } else return undefined;
      }),
      formatter: (value: any) => {
        const displayText = numeral(value).format(formatNumber);
        return `${displayText}${usePercentLabel ? "%" : ""}`;
      },
      order: 2,
      backgroundColor: colorBar,
      datalabels: {
        formatter: (value: number) => {
          const displayText = numeral(value).format(formatNumber);
          return `${displayText}${usePercentLabel ? "%" : ""}`;
        },
        display: true,
        color: (context: Context) => {
          return context.dataset.backgroundColor;
        },
        anchor: "end",
        align: "top",
        offset: 0,
        font: {
          size: 10,
        },
      },
    };
    const yoyLine = {
      type: "line",
      yAxisID: "yAxis2",
      label: "line_yoy",
      lineTension: 0,
      backgroundColor: "#E7BC26",
      borderColor: "#E7BC26",
      borderWidth: 1,
      fill: false,
      order: 1,
      data: revenue.map((el: any) => {
        if (el.yoy !== undefined) {
          return el.yoy;
        } else return 0;
      }),
      labelColor: (value: any) => {
        if (value >= 0) return "#00B98D";
        return "#DD3640";
      },
      formatter: (value: any) => {
        const displayText = numeral(value).format("0.00");
        return `${displayText}%`;
      },
    };
    datasets.push(ratioBar);
    datasets.push(yoyLine);
    return {
      labels,
      datasets,
    };
  }
  return undefined;
};

export const formatDataChartBalanceSheet = (
  rawData: SectFinanceData.AsObject,
  timeType: TimeOptionValue
) => {
  let labels = [];
  const datasets = [];
  const dataBalance: SectFinanceItem.AsObject[] = rawData.listbalancesheetList;

  if (dataBalance) {
    if (timeType === "1") {
      labels = dataBalance.map((el: any) => el.year);
    }
    if (timeType === "2") {
      labels = dataBalance.map((el: any) => `Q${el.quarterly}/${el.year}`);
    }

    const getDataBarChart = (type: string) => {
      if (type === "1") {
        return dataBalance.map((item: FinanceInfoItem) => {
          if (item.value1 === undefined) {
            return undefined;
          } else {
            return item.value1;
          }
        });
      }

      if (type === "2") {
        return dataBalance.map((item: FinanceInfoItem) => {
          if (item.value2 === undefined) {
            return undefined;
          } else {
            return item.value2;
          }
        });
      }
    };

    const getDataLineChart = () => {
      return dataBalance.map((item: FinanceInfoItem) => {
        if (item.value3 === undefined) {
          return undefined;
        } else {
          return item.value3;
        }
      });
    };

    const dataTotalAsset = {
      data: getDataBarChart("1"),
      type: "bar",
      label: "bar_ratio_1",
      formatter: (value: any) => {
        return numeral(value).format("0,0ab");
      },
      backgroundColor: "#3391FF",
      yAxisID: "yAxis1",
      labelColor: "#3391FF",
      order: 2,
      datalabels: {
        formatter: (value: number) => {
          return getMultilineLabel(numeral(value).format("0,0ab"), 5);
        },
        display: true,
        color: (context: Context) => {
          return context.dataset.backgroundColor;
        },
        anchor: "end",
        align: "top",
        font: {
          size: 10,
        },
      },
    };

    const dataTotalLiabilities = {
      data: getDataBarChart("2"),
      type: "bar",
      backgroundColor: "#DE20DF",
      label: "bar_ratio_2",
      formatter: (value: any) => {
        return numeral(value).format("0,0ab");
      },
      labelColor: "#DE20DF",
      yAxisID: "yAxis1",
      order: 2,
      datalabels: {
        formatter: (value: number) => {
          return getMultilineLabel(numeral(value).format("0,0ab"), 5);
        },
        display: true,
        color: (context: Context) => {
          return context.dataset.backgroundColor;
        },
        anchor: "end",
        align: "top",
        font: {
          size: 10,
        },
      },
    };

    const dataSolvencyDebtAssets = {
      data: getDataLineChart(),
      type: "line",
      backgroundColor: "#E7BC26",
      id: "line",
      borderColor: "#E7BC26",
      label: "line_yoy",
      lineTension: 0,
      borderWidth: 1,
      yAxisID: "yAxis2",
      fill: false,
      order: 1,
      formatter: (value: any) => {
        return value ? `${value} %` : "";
      },
      labelColor: (value: any) => {
        if (value >= 0) return "#00B98D";
        return "#DD3640";
      },
    };
    // The order is important!
    datasets.push(dataTotalAsset);
    datasets.push(dataTotalLiabilities);
    datasets.push(dataSolvencyDebtAssets);
    return {
      labels,
      datasets,
    };
  }
  return undefined;
};

export function getMinTickForChart(value?: number) {
  if (!value) return 0;
  if (value > 0) return 0;
  if (value < 0) return value - value / 10;

  return value;
}

export function getMaxTickForChart(value?: number) {
  if (value == null) return 0;
  if (value === 0) return 100;
  if (value < 0) return value / 10;

  return value;
}
