import styled from "styled-components";

export const WrapBoder = styled.div`
  padding: 16px;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  .button-filter {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
    @media (min-width: 1800px) {
      margin-left: auto;
    }
  }
`;

export const FilterWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  p {
    margin-bottom: 4px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WrapTab = styled.div`
  .header-left {
    padding: 8px 12px;
    height: 40px;
    display: flex;
    background-color: ${({ theme }) => theme.background2};
    align-items: center;
    color: ${({ theme }) => theme.grey0};
    box-shadow: ${({ theme }) => theme.shadowLineDown};
  }
`;
