import { FC, memo, useMemo, useRef, useState } from "react";
import { Modal, ModalDialog } from "react-bootstrap";
import { ModalContainer } from "./styles";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "assets/image/svg/closeicon.svg";
import { useIntl } from "react-intl";
import HeaderTickerGraph from "components/layouts/Main/components/Header/components/Card/HeaderTickerGraph";
import Draggable, {
  DraggableData,
  DraggableEventHandler,
} from "react-draggable";
import DerivativeTickerDetails from "../DerivativeTickerDetails";
import DefaultTickerDetails from "../DefaultTickerDetails";
import CWTickerDetails from "../CWTickerDetails";
import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { State } from "modules/market/redux";
import { getMarketLabel } from "helper/utils";
import GlobalData from "helper/globalData";

const DraggableModalDialog: FC = ({ ...props }) => {
  const modalHeadedRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const offsetLeftHeaderModal = useMemo(() => {
    return modalHeadedRef?.current?.offsetLeft || 0;
  }, [modalHeadedRef?.current]);

  const offsetTopHeaderModal = useMemo(() => {
    return modalHeadedRef?.current?.offsetTop || 0;
  }, [modalHeadedRef?.current]);

  const offsetHeightHeaderModal = useMemo(() => {
    return (
      modalHeadedRef?.current?.getElementsByClassName(
        "custom-modal-header"
      )?.[0]?.clientHeight || 0
    );
  }, [modalHeadedRef?.current]);

  const offsetWidthHeaderModal = useMemo(() => {
    return modalHeadedRef?.current?.offsetWidth || 0;
  }, [modalHeadedRef?.current]);

  const windowInnerWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  const windowInnerHeight = useMemo(() => {
    return window.innerHeight;
  }, []);

  const boundLeft = useMemo(
    () => -offsetLeftHeaderModal - offsetWidthHeaderModal + 50,
    [offsetLeftHeaderModal, offsetWidthHeaderModal]
  );
  const boundRight = useMemo(
    () =>
      windowInnerWidth -
      (offsetLeftHeaderModal + offsetWidthHeaderModal) +
      offsetWidthHeaderModal -
      50,
    [windowInnerWidth, offsetLeftHeaderModal, offsetWidthHeaderModal]
  );
  const boundTop = useMemo(() => -offsetTopHeaderModal, [offsetTopHeaderModal]);
  const boundBottom = useMemo(
    () => windowInnerHeight - (offsetTopHeaderModal + offsetHeightHeaderModal),
    [windowInnerHeight, offsetTopHeaderModal, offsetHeightHeaderModal]
  );

  const onStopHandler: DraggableEventHandler = (
    e: any,
    data: DraggableData
  ) => {
    let tempX = data.lastX;
    let tempY = data.lastY;
    if (data.lastY > boundBottom) {
      tempY = 0;
    }
    if (data.lastY < boundTop) {
      tempY = boundTop;
    }
    if (data.lastX > boundRight) {
      tempX = boundRight;
    }
    if (data.lastX < boundLeft) {
      tempX = boundLeft;
    }
    setPosition({ x: tempX, y: tempY });
  };

  return (
    <Draggable
      handle=".custom-modal-header"
      grid={[1, 1]}
      onStop={onStopHandler}
      position={position}
    >
      <ModalDialog {...props} ref={modalHeadedRef} />
    </Draggable>
  );
};

interface Props {
  visible: boolean;
  ticker: string | null;
  onClose: () => void;
}

const tickerSelector = (state: RootState) => state.market.root;

const modalTypeSelector = createSelector(
  tickerSelector,
  (state: State) => state.modalType
);

function TickerDetailsModal({ visible, onClose, ticker }: Props) {
  const intl = useIntl();

  const modalType = useSelector(modalTypeSelector);

  const renderModalBody = () => {
    if (modalType === "coverWarrant") {
      return <CWTickerDetails />;
    }
    if (modalType === "derivative") {
      return <DerivativeTickerDetails />;
    }
    if (modalType === "tickerHeader") {
      return <HeaderTickerGraph ticker={ticker} />;
    }
    return <DefaultTickerDetails />;
  };

  return (
    <ModalContainer
      show={visible}
      onHide={onClose}
      dialogAs={DraggableModalDialog}
      dialogClassName="modal-wrapper"
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <div className="custom-modal-header">
          <div>
            {modalType !== "tickerHeader" &&
              intl
                .formatMessage({ id: "tickerDetail.title" })
                .toUpperCase()}{" "}
            {ticker}&nbsp;
            {ticker
              ? `(${getMarketLabel(
                  GlobalData.getTickerInfoNew(ticker)?.marketcd
                )})`
              : null}
          </div>
        </div>
        <div className="close-btn" onClick={onClose}>
          <CloseIcon />
        </div>
      </Modal.Header>
      {renderModalBody()}
    </ModalContainer>
  );
}

export default memo(TickerDetailsModal);
