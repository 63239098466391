import { toast } from "components/commons/Toast";
import {
  cancelDertRequestCondApi,
  dertCancelMultiOrderApi,
  dertEditOrderApi,
  findDertOrderByFilterApi,
  findDertOrderCondApi,
  findDertOrderDetailApi,
  findDertRequestCondApi,
} from "core/http/apis/tradeinfo";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  findDertOrderByFilter,
  findDertOrderByFilterSuccess,
  findDertOrderByFilterFailure,
  findDertOrderDetail,
  findDertOrderDetailSuccess,
  findDertOrderDetailFailure,
  dertEditOrder,
  dertEditOrderSuccess,
  dertEditOrderFailure,
  dertCancelMultiOrder,
  dertCancelMultiOrderSuccess,
  dertCancelMultiOrderFailure,
  changeSelectedRows,
  onReloadPlaceOrderDer,
  findDertRequestCond,
  findDertRequestCondSuccess,
  findDertRequestCondFailure,
  findDertOrderCond,
  findDertOrderCondSuccess,
  findDertOrderCondFailure,
  cancelDertRequestCond,
  onLoadList,
} from "../redux/OrderBookDer";
import {
  ICancelDertRequestCondResponse,
  IDertCancelMultiOrderResponse,
  IDertEditOrderResponse,
  IFindDertOrderByFilterResponse,
  IFindDertOrderCondResponse,
  IFindDertOrderDetailResponse,
  IFindDertRequestCondResponse,
  OrderBookDerCondDisplay,
  OrderBookDerDisplay,
} from "domain/models/OrderBookDer";
import {
  convertOrderBookDerCondData,
  convertOrderBookDerData,
} from "helper/orderBookHelper";
import { IDertCancelMultiOrderParams } from "core/http/apis/tradeinfo/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

// Orderbook Saga --- start ---
function* findDertOrderByFilterWorker(
  action: ReturnType<typeof findDertOrderByFilter>
) {
  try {
    const orderBook: IFindDertOrderByFilterResponse = yield call(
      findDertOrderByFilterApi,
      action.payload.params
    );

    if (orderBook != null && orderBook.data) {
      const tradeDate: string = yield select(
        (state: RootState) => state.auth.root.data.userInfo.tradeDate
      );

      const dataConvert: OrderBookDerDisplay[] = convertOrderBookDerData(
        orderBook,
        action.payload.intl,
        tradeDate
      );

      yield put(
        findDertOrderByFilterSuccess({
          items: dataConvert,
          offset: action.payload.params.page,
        })
      );
    } else {
      yield put(
        findDertOrderByFilterSuccess({
          items: [],
          offset: action.payload.params.page,
        })
      );
    }
  } catch (error: any) {
    yield put(findDertOrderByFilterFailure(error));
  }
}

function* watchFindDertOrderByFilter() {
  yield takeLatest(findDertOrderByFilter, findDertOrderByFilterWorker);
}

function* findDertOrderDetailWorker(
  action: ReturnType<typeof findDertOrderDetail>
) {
  try {
    const res: IFindDertOrderDetailResponse = yield call(
      findDertOrderDetailApi,
      action.payload
    );
    if (res != null && res.data) {
      yield put(findDertOrderDetailSuccess({ items: res.data }));
    }
  } catch (error: any) {
    yield put(findDertOrderDetailFailure(error));
  }
}

function* watchFindDertOrderDetail() {
  yield takeLatest(findDertOrderDetail, findDertOrderDetailWorker);
}

function* dertEditOrderWorker(action: ReturnType<typeof dertEditOrder>) {
  try {
    const res: IDertEditOrderResponse = yield call(
      dertEditOrderApi,
      action.payload
    );
    if (res != null) {
      yield put(dertEditOrderSuccess(res));
    }
  } catch (error: any) {
    yield put(dertEditOrderFailure(error));
  }
}

function* watchDertEditOrder() {
  yield takeLatest(dertEditOrder, dertEditOrderWorker);
}

function* dertCancelMultiOrderWorker(
  action: PayloadAction<{
    params: IDertCancelMultiOrderParams;
    intl: any;
    content: string;
  }>
) {
  try {
    const res: IDertCancelMultiOrderResponse = yield call(
      dertCancelMultiOrderApi,
      action.payload.params
    );

    if (res.statusCode === 0) {
      yield put(dertCancelMultiOrderSuccess(action.payload.params));

      toast(action.payload.content, "success");
      yield put(onLoadList(Math.random()));
    } else {
      toast(
        res?.errorCode ? "error." + res?.errorCode : "",
        "error",
        res.message,
        true
      );
      yield put(
        dertCancelMultiOrderFailure(res?.errorCode || "error.defaultErr")
      );
    }
  } catch (error: any) {
    toast(
      error && error.description ? error.description : "orderBook.orderError",
      "error"
    );
    yield put(dertCancelMultiOrderFailure(error));
  }
}

function* watchDertCancelMultiOrder() {
  yield takeLatest(dertCancelMultiOrder, dertCancelMultiOrderWorker);
}

function* findDertRequestCondWorker(
  action: ReturnType<typeof findDertRequestCond>
) {
  try {
    const orderBook: IFindDertRequestCondResponse = yield call(
      findDertRequestCondApi,
      action.payload.params
    );

    if (orderBook != null && orderBook.data) {
      const dataConvert: OrderBookDerCondDisplay[] =
        convertOrderBookDerCondData(orderBook, action.payload.intl);

      yield put(
        findDertRequestCondSuccess({
          items: dataConvert,
          offset: action.payload.params.offset,
        })
      );
    } else {
      yield put(
        findDertRequestCondSuccess({
          items: [],
          offset: action.payload.params.offset,
        })
      );
    }
  } catch (error: any) {
    yield put(findDertRequestCondFailure(error));
  }
}

function* watchFindDertRequestCond() {
  yield takeLatest(findDertRequestCond, findDertRequestCondWorker);
}

function* findDertOrderCondWorker(
  action: ReturnType<typeof findDertOrderCond>
) {
  try {
    const res: IFindDertOrderCondResponse = yield call(
      findDertOrderCondApi,
      action.payload
    );
    if (res != null && res.data) {
      yield put(findDertOrderCondSuccess({ items: res.data }));
    }
  } catch (error: any) {
    yield put(findDertOrderCondFailure(error));
  }
}

function* watchFindDertOrderCond() {
  yield takeLatest(findDertOrderCond, findDertOrderCondWorker);
}

function* cancelDertRequestCondWorker(
  action: ReturnType<typeof cancelDertRequestCond>
) {
  try {
    const res: ICancelDertRequestCondResponse = yield call(
      cancelDertRequestCondApi,
      action.payload.params
    );

    if (res.statusCode === 0) {
      yield put(changeSelectedRows([]));
      yield put(onReloadPlaceOrderDer(true));
      toast(action.payload.content, "success");
      yield put(onLoadList(Math.random()));
    } else {
      toast(
        res?.errorCode ? "error." + res?.errorCode : "",
        "error",
        res.message,
        true
      );
    }
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function* watchCancelDertRequestCond() {
  yield takeLatest(cancelDertRequestCond, cancelDertRequestCondWorker);
}

// Orderbook Saga --- end ---

export default function* orderBookDer() {
  yield all([
    watchFindDertOrderByFilter(),
    watchFindDertOrderDetail(),
    watchDertEditOrder(),
    watchDertCancelMultiOrder(),
    watchFindDertRequestCond(),
    watchFindDertOrderCond(),
    watchCancelDertRequestCond(),
  ]);
}
