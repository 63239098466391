import styled from "styled-components/macro";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background2};
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 12px;
  align-items: center;
`;

export const Label = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
`;
