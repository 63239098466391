import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetMarginContractParams } from "core/http/apis/margin-contract/types";

import { IFindMarginSecuritiesParams } from "core/http/apis/margin-securities/types";
import {
  IMarginSecurities,
  IMarginSecuritiesResponse,
} from "domain/models/MarginSecurities";
import { logOutSuccess } from "modules/auth/redux";

import { MARGIN_SECURITIES_TABS } from "./constants";

interface IFilter {
  custGroupId: string;
  custGroupName: string;
  secCd: string;
  status: string;
}

export interface ReduxData {
  marginSecuritiesFilter: IFilter;
  marginSecuritiesList: IMarginSecurities[];
  marginSecuritiesLoading: boolean;
  activeTab: string;
}

const initialState: ReduxData = {
  marginSecuritiesFilter: {
    custGroupId: "",
    custGroupName: "",
    secCd: "",
    status: "1",
  },
  marginSecuritiesList: [],
  marginSecuritiesLoading: false,
  activeTab: MARGIN_SECURITIES_TABS.MARGIN_SECURITIES,
};

const marginSecuritiesSlice = createSlice({
  name: "marginSecuritiesSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchMarginSecuritiesRequest: (
      state,
      action: PayloadAction<IFindMarginSecuritiesParams>
    ) => {
      state.marginSecuritiesLoading = true;
    },
    searchMarginSecuritiesSuccess: (
      state,
      action: PayloadAction<IMarginSecuritiesResponse>
    ) => {
      state.marginSecuritiesLoading = false;
      state.marginSecuritiesList = action.payload.data ?? [];
    },
    searchMarginSecuritiesFailure: (state) => {
      state.marginSecuritiesLoading = false;
    },
    getCustGroupRequest: (
      state,
      action: PayloadAction<IGetMarginContractParams>
    ) => {
      state.marginSecuritiesLoading = true;
    },
    getCustGroupSuccess: (
      state,
      action: PayloadAction<{ custGroupId: string; custGroupName: string }>
    ) => {
      state.marginSecuritiesLoading = false;
      state.marginSecuritiesFilter.custGroupId = action.payload.custGroupId;
      state.marginSecuritiesFilter.custGroupName = action.payload.custGroupName;
    },
    getCustGroupFailure: (state) => {
      state.marginSecuritiesLoading = false;
    },
    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: string }>
    ) => {
      state.marginSecuritiesFilter = {
        ...state.marginSecuritiesFilter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchMarginSecuritiesFailure,
  searchMarginSecuritiesRequest,
  searchMarginSecuritiesSuccess,
  getCustGroupRequest,
  getCustGroupFailure,
  getCustGroupSuccess,
  updateFilter,
} = marginSecuritiesSlice.actions;

export default marginSecuritiesSlice.reducer;
