import {
  sumBidSelector,
  dataDetailInfoSelector,
} from "modules/tickerDetails/redux/selectors";
import { FormattedMessage } from "react-intl";
import RowPrice from "./RowPrice";
import { BuySellContainer, VolumeContainer } from "./styles";
import { useSelector } from "react-redux";
import { getColorPrice } from "helper/utils";

function BuySell() {
  const sumBid = useSelector(sumBidSelector);
  const basicprice = useSelector(
    dataDetailInfoSelector("basicprice")
  ) as number;
  const ceilingprice = useSelector(
    dataDetailInfoSelector("ceilingprice")
  ) as number;
  const floorprice = useSelector(
    dataDetailInfoSelector("floorprice")
  ) as number;
  const best1bidpricestr = useSelector(
    dataDetailInfoSelector("best1bidpricestr")
  ) as string;
  const best1bidqty = useSelector(
    dataDetailInfoSelector("best1bidqty")
  ) as number;
  const best2bidprice = useSelector(
    dataDetailInfoSelector("best2bidprice")
  ) as number;
  const best2bidqty = useSelector(
    dataDetailInfoSelector("best2bidqty")
  ) as number;
  const best3bidprice = useSelector(
    dataDetailInfoSelector("best3bidprice")
  ) as number;
  const best3bidqty = useSelector(
    dataDetailInfoSelector("best3bidqty")
  ) as number;

  // TODO
  const handleClickPrice = (price: number | string) => {
    // dispatch(onFilter({ price: price }));
    // setTimeout(() => dispatch(onChangePriceByEnter(true)), 150);
  };

  const isSell = false;

  return (
    <BuySellContainer>
      <VolumeContainer isReverse={isSell}>
        <FormattedMessage id="widgets.infoQuote.volume" />
        <div className="text">
          <FormattedMessage id={"common.buy"} />
        </div>
      </VolumeContainer>
      <RowPrice
        price={best1bidpricestr}
        volume={best1bidqty}
        color={getColorPrice(
          best1bidpricestr,
          floorprice,
          ceilingprice,
          basicprice
        )}
        isSell={isSell}
        percent={(best1bidqty / sumBid) * 100}
        handleClickPrice={handleClickPrice}
      />
      <RowPrice
        price={best2bidprice}
        volume={best2bidqty}
        color={getColorPrice(
          best2bidprice,
          floorprice,
          ceilingprice,
          basicprice
        )}
        isSell={isSell}
        percent={(best2bidqty / sumBid) * 100}
        handleClickPrice={handleClickPrice}
      />
      <RowPrice
        price={best3bidprice}
        volume={best3bidqty}
        color={getColorPrice(
          best3bidprice,
          floorprice,
          ceilingprice,
          basicprice
        )}
        isSell={isSell}
        percent={(best3bidqty / sumBid) * 100}
        handleClickPrice={handleClickPrice}
      />
    </BuySellContainer>
  );
}

export default BuySell;
