import { Select, Button, DatePicker, Input } from "components/commons";
import { memo, useEffect, useMemo } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  searchMarginContractRequest,
  updateFilter,
} from "modules/margin-contract/redux";
import {
  loadingSearchDataSelector,
  filterMarginContractSelector,
} from "modules/margin-contract/selectors";
import { IGetMarginContractParams } from "core/http/apis/margin-contract/types";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import moment from "moment";

const statusOption = (intl: IntlShape) => [
  {
    value: "NONE",
    label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
  },
  {
    value: "0",
    label: intl.formatMessage({
      id: "marginContract.filter.status.notApprove",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({ id: "marginContract.filter.status.approved" }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "marginContract.filter.status.approveWaitEdit",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({ id: "marginContract.filter.status.edited" }),
  },
  {
    value: "9",
    label: intl.formatMessage({ id: "marginContract.filter.status.closed" }),
  },
];

const contractStateOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "marginContract.filter.contractState.all",
    }),
  },
  {
    value: "0",
    label: intl.formatMessage({
      id: "marginContract.filter.purpose.notActive",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "marginContract.filter.purpose.actived",
    }),
  },
];

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { openDateFrom, openDateTo, accNo, broker, status, contractState } =
    useSelector(filterMarginContractSelector);
  const listAcc = GetAccoList(GetAccountType.MARGIN, EntrustBusinessCode.All);

  const loading = useSelector(loadingSearchDataSelector);

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    list.unshift({
      label: intl.formatMessage({ id: "marginContract.filter.status.all" }),
      value: "",
    });
    return list;
  }, [listAcc, intl]);

  const statusOptions = useMemo(() => statusOption(intl), [intl]);
  const contractStateOptions = useMemo(() => contractStateOption(intl), [intl]);

  // useEffect(() => {
  //   dispatch(
  //     updateFilter({
  //       key: "accNo",
  //       value: parseOptionAccount[0].value,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (accNo !== "") {
      handleSearch();
    }
  }, [accNo, broker, status, contractState]);

  const handleSearch = () => {
    const submitData: IGetMarginContractParams = {
      tranCd: "",
      accNo: accNo,
      openDateFrom: Number(moment(openDateFrom).format("YYYYMMDD")),
      openDateTo: Number(moment(openDateTo).format("YYYYMMDD")),
      broker: broker,
      status: status,
      contractState: contractState,
    };
    dispatch(searchMarginContractRequest(submitData));
  };

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContract.filter.account",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={parseOptionAccount[0]}
            options={parseOptionAccount}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "accNo",
                  value: e?.value ? e.value : "",
                })
              );
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContract.filter.fromDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={openDateFrom}
            onChange={(e: any) =>
              dispatch(
                updateFilter({
                  key: "openDateFrom",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContract.filter.toDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={openDateTo}
            onChange={(e) =>
              dispatch(
                updateFilter({
                  key: "advanceFromDate",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContract.filter.status.title",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            options={statusOptions}
            defaultValue={statusOptions[0]}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "status",
                  value: e?.value ? e.value : "",
                })
              );
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContract.filter.contractState.title",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            options={contractStateOptions}
            defaultValue={contractStateOptions[0]}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "contractState",
                  value: e?.value ? e.value : "",
                })
              );
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginContract.filter.broker",
          })}
          className="mr-4"
        >
          <Input
            value={broker}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "broker",
                  value: e.target.value,
                })
              );
            }}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "marginContract.filter.search" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
