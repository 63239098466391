import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, CheckBox } from "components/commons";
import { statusTypeTransfer } from "modules/transfer-internal/constants";
import { editCheckModal } from "modules/transfer-internal/redux";
import {
  AccoSubtoSelector,
  // AccoSubTransferBankSelector,
  selectGetBankDetail,
  getTypeTransferSelector,
  selectCheckModal,
} from "modules/transfer-internal/selectors";
import numeral from "numeral";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  CustomHr,
  DivCheckbox,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
} from "./styles";
import { useEffectOnce } from "react-use";

interface PROPS {
  isVisible: boolean;
  onClose: () => void;
  data?: { amount?: string | null; remarks?: string; subAccoNo?: string };
  onSuccess: () => void;
}

const ConfirmModal = (props: PROPS) => {
  const { isVisible, onClose, data, onSuccess } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const closeModalHandler = () => {
    onClose();
  };
  const typeTranfer = useSelector(getTypeTransferSelector);
  const { transferBank } = useSelector(selectCheckModal);

  const optionSubAccoTo = useSelector(AccoSubtoSelector);
  // const accoFromBank = useSelector(AccoSubTransferBankSelector);
  const bankDetail = useSelector(selectGetBankDetail);
  const typeTransfeOptions = useMemo(() => statusTypeTransfer(intl), [intl]);

  const feeAmt = useCallback(() => {
    let amountFee = 0;
    if (bankDetail?.transferFeeFlg === 1) {
      const val1 = Number(data?.amount) * bankDetail?.transferFeeRate;
      const val2 = bankDetail?.transferFeeMin;
      amountFee = Math.max(val1, val2);
    } else {
      amountFee = bankDetail?.transferFee;
    }
    return numeral(amountFee).format("0,0");
  }, [bankDetail, data]);

  const [checked, setchecked] = useState<boolean>(false);
  const saveCheckModal = () => {
    setchecked(!checked);
    dispatch(
      editCheckModal({
        key: "transferBank",
        value: !checked,
      })
    );
  };

  useEffectOnce(() => {
    if (transferBank) {
      onSuccess();
    }
  });

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="transferInternal.historyTransferBank.modal.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.accountTransfe" />
            </LabelCell>
            <TextCell>{data?.subAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.accountGet" />
            </LabelCell>
            <TextCell>{optionSubAccoTo?.bankAccoNumber}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.nameAccountGet" />
            </LabelCell>
            <TextCell>{optionSubAccoTo?.bankAccountName}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.bank" />
            </LabelCell>
            <TextCell>{optionSubAccoTo?.bankCd}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.amount" />
            </LabelCell>
            <TextCell>
              {/* {formModal?.remarks} */}
              {numeral(data?.amount).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.typeTransfer" />
            </LabelCell>
            <TextCell>
              {
                typeTransfeOptions.filter(
                  (e: any) => e?.value === typeTranfer
                )[0]?.label
              }
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.fee" />
            </LabelCell>
            <TextCell>{feeAmt()}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.historyTransferBank.modal.content" />
            </LabelCell>
            <TextCell>{data?.remarks}</TextCell>
          </BodyModalRow>
          <CustomHr />
          <DivCheckbox>
            <CheckBox checked={checked} onChange={() => saveCheckModal()} />
            <FormattedMessage id="transferLocal.confirmModal.notes" />
          </DivCheckbox>

          <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={closeModalHandler}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.cancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                className="button-confirm"
                color="gradientaAccept"
                onClick={() => {
                  onSuccess();
                }}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.accept" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
