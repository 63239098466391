import VgaiaHttp from "core/http/singleton/vgaia";
import { FormSubmit } from "modules/transfer-internal/redux";
import { IParamAddDeposit } from "modules/transfer-internal/types";
import {
  IfindParameterBankDetail,
  IFormAddAccount,
  IFormAddAccountBank,
  IGetAccountTransfer,
  IParamGetDepositApi,
  ITransferInternalModelResponse,
  ITransferLocalFilter,
} from "./types";
import {
  addAccountUrl,
  addTransferInternalUrl,
  findParameterBankDetailUrl,
  findTransferContractBank,
  findTransferContractLocalUrl,
  getListAccountBanks,
  getListBankUrl,
  getListBranchUrl,
  getListlocationUrl,
  getSubAccToLocalListlUrl,
  getTransferBankUrl,
  inquiryAccountTraApi,
  inquiryAccountTransBankUrl,
  submitTransferBankUrl,
  deleteTransferBankUrl,
  getDerCashTransListApi,
  calculateTransferCashFeeAmtApi,
  getTransferAvailDertApi,
  createDerTTransferCashUrl,
  updateDerTTransferCashhUrl,
  getTransferBankFeeAmtUrl,
} from "./urls";

export const inquiryAccountTra = async (
  params: IGetAccountTransfer
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.get<ITransferInternalModelResponse>(
    inquiryAccountTraApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const addTransferInternalApi = async (
  params: FormSubmit
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.post<ITransferInternalModelResponse>(
    addTransferInternalUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getTransferBankApi = async (
  params: ITransferLocalFilter
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.get<ITransferInternalModelResponse>(
    getTransferBankUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getlistBank = async () => {
  const res = await VgaiaHttp.get<ITransferInternalModelResponse>(
    getListBankUrl({ groupCategory: "TR00" })
  );

  if (!res || !res.data) throw new Error("Opps");
  return res?.data;
};

export const getlistLocation = async () => {
  const res = await VgaiaHttp.get<ITransferInternalModelResponse>(
    getListlocationUrl
  );

  if (!res || !res.data) throw new Error("Opps");
  return res?.data;
};

export const getlistBranch = async (params: {
  bankCd: string | null;
  locationCd: string | null;
}) => {
  const res = await VgaiaHttp.get<ITransferInternalModelResponse>(
    getListBranchUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const postAccount = async (params: IFormAddAccount) => {
  const res = await VgaiaHttp.post(addAccountUrl(), JSON.stringify(params), {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAccountBanks = async (params: { subAccoNo: string }) => {
  const res = await VgaiaHttp.get(getListAccountBanks(params));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getTransferContractBank = async (params: {
  subAccCd?: number;
  toSubAccountNo: string;
}) => {
  const res = await VgaiaHttp.get(findTransferContractBank(params));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const inquiryAccountTransBank = async (params: {
  subAccoNo: string;
}) => {
  const res = await VgaiaHttp.get(inquiryAccountTransBankUrl(params));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const submitTransferBank = async (
  params: IFormAddAccountBank
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.post<ITransferInternalModelResponse>(
    submitTransferBankUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findParameterBankDetail = async (
  params: IfindParameterBankDetail
) => {
  const res = await VgaiaHttp.get(findParameterBankDetailUrl(params));
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getSubAccToLocalList = async (params: { subAccNo?: string }) => {
  const res = await VgaiaHttp.get(getSubAccToLocalListlUrl(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findTransferContractLocal = async (params: {
  number?: string;
  toSubAccountNo?: string;
  checkContract?: number;
}) => {
  const res = await VgaiaHttp.get(findTransferContractLocalUrl(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const deleteTransferBank = async (
  params: FormSubmit
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.post<ITransferInternalModelResponse>(
    deleteTransferBankUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getDerCashTransList = async (params: IParamGetDepositApi) => {
  const res = await VgaiaHttp.get(getDerCashTransListApi(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

// export const createDerTTransferCash = async (
//   params: FormSubmit
// ): Promise<ITransferInternalModelResponse> => {
//   const res = await VgaiaHttp.post<ITransferInternalModelResponse>(
//     createDerTTransferCashkUrl(),
//     JSON.stringify(params),
//     { headers: { "Content-Type": "application/json; charset=utf-8" } }
//   );

//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

export const calculateTransferCashFeeAmt = async (params: {
  amount: string | null;
  feeType: string;
}) => {
  const res = await VgaiaHttp.post(calculateTransferCashFeeAmtApi(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getTransferAvailDert = async (params: {
  subAccoNo: string | null;
  tradeType: number;
}) => {
  const res = await VgaiaHttp.get(getTransferAvailDertApi(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createDerTTransferCashApi = async (
  params: IParamAddDeposit
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.post<ITransferInternalModelResponse>(
    createDerTTransferCashUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const updateDerTTransferCashApi = async (
  params: IParamAddDeposit
): Promise<ITransferInternalModelResponse> => {
  const res = await VgaiaHttp.post<ITransferInternalModelResponse>(
    updateDerTTransferCashhUrl(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getTransferBankFeeAmtApi = async (params: any) => {
  const res = await VgaiaHttp.get<ITransferInternalModelResponse>(
    getTransferBankFeeAmtUrl(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
