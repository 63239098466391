import styled from "styled-components";

export const WrapBoder = styled.div`
  padding: 16px;
  .button-filter {
    margin-top: 15px;
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
    @media (min-width: 1800px) {
      margin-left: auto;
    }
  }
`;

export const FilterWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  p {
    margin-bottom: 4px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: stretch;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
`;
export const WrapperRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 132px;
`;
export const Label = styled.div``;
export const LabelRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;
