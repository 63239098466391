import { Container } from "./styles";
import { FC, memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import ModalEditInfo from "./ModalEditInfo";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { setvisibleModalInfo } from "modules/auth/redux";
import { getAccountInfo } from "modules/auth/redux";
import Storage from "helper/storage";
import {
  currentAccountSelector,
  visibleModalEditGeneralInfoSelector,
  visibleModalFaceVerifySelector,
  visibleModalInfoSelector,
} from "modules/auth/selectors";
// import ModalRegisterCaringStaff from "./ModalRegisterCaringStaff";
import { TabHeader, TabHeaderItem } from "./styles";
import TabVaacctnolist from "./TabVaacctnolist";
import AccountInfo from "./AccountInfo";
import ModalEditGeneralInfo from "./ModalEditGeneralInfo";
import FaceVerify from "./ModalEditGeneralInfo/FaceVerify";
import { IFindAccountInfoParams } from "core/http/apis/account-info/types";

enum TABS {
  AccountInfo = "accountInfo",
  Vaacctnolist = "vaacctnolist",
}

const ModalAccountInfo: FC = memo(() => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(TABS.AccountInfo);
  const masterAccount = Storage.getMasterAccount();
  const currentAccount = useSelector(currentAccountSelector);
  const visibleModalInfo = useSelector(visibleModalInfoSelector);
  const visibleModalEdit = useSelector(visibleModalEditGeneralInfoSelector);
  const visibleModalFaceVerify = useSelector(visibleModalFaceVerifySelector);
  const dispatch = useDispatch();
  const changeActiveTabHandler = (tabCode: TABS) => {
    if (tabCode !== activeTab) {
      setActiveTab(tabCode);
    }
  };

  const closeModalInfo = () => {
    setActiveTab(TABS.AccountInfo);
    dispatch(setvisibleModalInfo());
  };

  useEffect(() => {
    if (visibleModalInfo && masterAccount) {
      const params: IFindAccountInfoParams = {
        subAccNo: currentAccount?.subAccoNo || masterAccount + ".01",
      };
      dispatch(getAccountInfo(params));
    }
  }, [visibleModalInfo, masterAccount]);

  return (
    <>
      <Modal
        show={visibleModalInfo}
        centered
        onHide={closeModalInfo}
        keyboard={false}
        dialogClassName="modal-584"
      >
        <Container blur={visibleModalEdit || visibleModalFaceVerify}>
          <TabHeader>
            <TabHeaderItem
              isActive={activeTab === TABS.AccountInfo}
              onClick={() => changeActiveTabHandler(TABS.AccountInfo)}
            >
              {intl.formatMessage({ id: "auth.info.title" }).toUpperCase()}
            </TabHeaderItem>
            {/* <TabHeaderItem
              isActive={activeTab === TABS.Vaacctnolist}
              onClick={() => changeActiveTabHandler(TABS.Vaacctnolist)}
            >
              {intl
                .formatMessage({ id: "auth.identification.title" })
                .toUpperCase()}
            </TabHeaderItem> */}
          </TabHeader>
          {activeTab === TABS.AccountInfo ? (
            <AccountInfo />
          ) : (
            <TabVaacctnolist />
          )}
        </Container>
      </Modal>
      {/* <ModalEditInfo /> */}
      <ModalEditGeneralInfo />
      <FaceVerify />
      {/* <ModalRegisterCaringStaff /> */}
    </>
  );
});

export default ModalAccountInfo;
