/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { EBusinessCd, EOTPType, EOTPTypeSubmit } from "helper/consts";
import storage from "helper/storage";
import { toast } from "components/commons/Toast";
import SmartOTP from "../SmartOTP";
import SmsOTPNew from "../SmsOTPNew";
import PinCdOTP from "../PinCdOTP";
import {
  ICheckPinTypeParams,
  ICheckPinTypeReponse,
  ICreateSmsOtpParam,
  ICreateSmsOtpResetPasswordParam,
  IValidateOtpCodeParam,
} from "core/http/apis/twofactor/types";
import {
  checkPinTypeApi,
  createSMSOTPApi,
  createSMSOTPResetPasswordApi,
  validateOtpCodeApi,
} from "core/http/apis/twofactor";
import { useSelector } from "react-redux";
import { isResetSelector } from "modules/auth/selectors";
import { useIntl } from "react-intl";
export interface IVerifyResponse {
  businessCd: number;
  otpType: number;
  otp: string;
  // isSaveOtp: number;
  otpTypeSubmit?: EOTPTypeSubmit;
}
interface IProps {
  handleClose: any;
  getResult: (response: IVerifyResponse) => void;
  otpEverryTime?: boolean;
  businessCd: EBusinessCd;
  userId?: string;
}

const OTPByBusinessCd = ({
  handleClose,
  getResult,
  otpEverryTime = true,
  businessCd,
  userId,
}: IProps) => {
  const masterAccount = storage.getMasterAccount();
  const isReset = useSelector(isResetSelector);
  const intl = useIntl();

  const [otpType, setOtpType] = useState<number>(0);
  const [errorOtp, setErrorOtp] = useState("");
  const [pinType, setPinType] = useState(0);

  const handleCreateSmsOTP = async () => {
    try {
      if (businessCd === EBusinessCd.RESET_PASSWORD) {
        const data: ICreateSmsOtpResetPasswordParam = {
          userId: userId ?? "",
        };
        await createSMSOTPResetPasswordApi(data);
      } else {
        const data: ICreateSmsOtpParam = {
          custNo: masterAccount,
          businessCd:
            businessCd === EBusinessCd.REGISTER_AUTHEN ? 0 : businessCd,
        };
        await createSMSOTPApi(data);
      }
    } catch (error: any) {
      setErrorOtp(
        intl.formatMessage({
          id: error?.code,
          defaultMessage: error?.description,
        })
      );
    }
  };

  const verifyOtp = async (code: string) => {
    try {
      if (pinType !== EOTPType.NONE) {
        const data: IValidateOtpCodeParam = {
          otpCode: code,
          businessCd:
            businessCd === EBusinessCd.REGISTER_AUTHEN ||
            businessCd === EBusinessCd.RESET_PASSWORD
              ? 0
              : businessCd,
          pinType: pinType,
        };

        await validateOtpCodeApi(data)
          .then((response) => {
            if (response.statusCode === 0) {
              getResult({
                businessCd: businessCd,
                otpType: otpType,
                otp: code,
                // isSaveOtp: !otpEverryTime ? 1 : 0,
                otpTypeSubmit: EOTPTypeSubmit.OTP,
              });
              // const resLogData = {
              //   eventGroup: GroupEvent.registerOtp,
              //   event: RealtimeConst.screenRegisterOtp,
              //   message: "Success",
              // };
              // storage.commonPushLogFirebase(resLogData);

              if (businessCd !== EBusinessCd.RESET_PASSWORD) {
                handleClose();
              }
            } else {
              setErrorOtp(
                intl.formatMessage({
                  id: "error." + response?.errorCode,
                  defaultMessage: response?.message,
                })
              );
            }
          })
          .catch((error) => {
            setErrorOtp(
              intl.formatMessage({
                id: "error." + error?.code,
                defaultMessage: error?.description,
              })
            );
          });
      } else {
        getResult({
          businessCd: businessCd,
          otpType: otpType,
          otp: code,
          // isSaveOtp: !otpEverryTime ? 1 : 0,
          otpTypeSubmit: EOTPTypeSubmit.OTP,
        });
        if (businessCd !== EBusinessCd.RESET_PASSWORD) {
          handleClose();
        }
      }
    } catch (error: any) {
      setErrorOtp(
        intl.formatMessage({
          id: "error." + error?.code,
          defaultMessage: error?.description,
        })
      );
    }
  };

  const checkPinType = async () => {
    const params: ICheckPinTypeParams = {
      businessCd:
        businessCd === EBusinessCd.REGISTER_AUTHEN ||
        businessCd === EBusinessCd.RESET_PASSWORD
          ? 0
          : businessCd,
    };
    const pinCode: ICheckPinTypeReponse = await checkPinTypeApi(params);
    setPinType(pinCode?.data?.pinType || 0);

    if (pinCode && pinCode.data && pinCode.statusCode === 0) {
      if (pinCode.data.pinType === EOTPType.NONE) {
        return verifyOtp("");
      }

      if (pinCode.data.pinType === EOTPType.SMART_OTP) {
        return setOtpType(EOTPType.SMART_OTP);
      }

      if (pinCode.data.pinType === EOTPType.SMS_OTP) {
        return setOtpType(EOTPType.SMS_OTP);
      }

      if (pinCode.data.pinType === EOTPType.PIN_CD) {
        return setOtpType(EOTPType.PIN_CD);
      }

      if (
        pinCode.data.pinType !== EOTPType.NONE &&
        pinCode.data.pinType !== EOTPType.SMART_OTP &&
        pinCode.data.pinType !== EOTPType.SMS_OTP &&
        pinCode.data.pinType !== EOTPType.PIN_CD
      ) {
        toast("auth.info.sendOTP.errorOtpType", "error");
        handleClose();
        return;
      }
    } else {
      toast(pinCode?.errorCode || "", "error", pinCode?.message, true);
    }
  };

  useEffect(() => {
    // if (!storageOTPCode?.code || otpEverryTime === true) return;
    if (
      businessCd === EBusinessCd.REGISTER_AUTHEN ||
      businessCd === EBusinessCd.RESET_PASSWORD
    ) {
      setPinType(3);
      return;
    }
    checkPinType();
  }, []);

  useEffect(() => {
    if (businessCd) {
      if (businessCd === EBusinessCd.RESET_PASSWORD) {
        return setOtpType(EOTPType.SMS_OTP);
      }

      if (businessCd === EBusinessCd.REGISTER_AUTHEN) {
        return setOtpType(EOTPType.SMS_OTP);
      }
    }
  }, [businessCd, masterAccount, isReset]);

  const generateModal = (type: EOTPType) => {
    switch (type) {
      case EOTPType.SMART_OTP:
        return (
          <SmartOTP
            handleModal={handleClose}
            changeMasterAccount={() => {}}
            otpEverryTime={otpEverryTime}
            submitOTP={verifyOtp}
            errorOTP={errorOtp}
            setErrorOtp={setErrorOtp}
            getResult={getResult}
            businessCd={businessCd}
          />
        );
      case EOTPType.SMS_OTP:
        return (
          <SmsOTPNew
            handleModal={handleClose}
            changeMasterAccount={() => {}}
            otpEverryTime={otpEverryTime}
            handleCreateSmsOTP={handleCreateSmsOTP}
            submitOTP={verifyOtp}
            errorOTP={errorOtp}
            setErrorOtp={setErrorOtp}
            getResult={getResult}
            businessCd={businessCd}
          />
        );
      case EOTPType.PIN_CD:
        return (
          <PinCdOTP
            handleModal={handleClose}
            otpEverryTime={otpEverryTime}
            submitOTP={verifyOtp}
            errorOTP={errorOtp}
            setErrorOtp={setErrorOtp}
          />
        );
      default:
        return null;
    }
  };

  return generateModal(otpType);
};

export default OTPByBusinessCd;
