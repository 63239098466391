/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";

import {
  listProfitLossSelector,
  selectEnableInfinity,
  selectLimit,
  selectCurrentPage,
  filterProfitLossSelector,
  chartDataProfitLossSelector,
} from "modules/profit-loss/selectors";
import numeral from "numeral";
import { memo, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  ButtonBuy,
  ButtonSell,
  HeaderCell,
  TableCellContent,
  TableCellContentColor,
  TableHeaderCellContent,
} from "./styles";

import { PortTransaction } from "domain/models/ProfitLoss";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import paths from "helper/pathRoutes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import { changeCurrentAccount } from "modules/auth/redux";
import { onBuySellType } from "modules/stock-order/redux/placeOrder";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  fetchMoreProfitLossRequest,
  updateEnableInfinity,
} from "modules/profit-loss/redux";
import { IFindPortTransactionParams } from "core/http/apis/profit-loss/types";
import moment from "moment";

function RequestTable() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const themeType = useSelector(themeTypeSelector);
  const enable = useSelector(selectEnableInfinity);
  const currentPage = useSelector(selectCurrentPage);
  const limit = useSelector(selectLimit);
  const { fromDate, toDate, subAccoNo, secCd } = useSelector(
    filterProfitLossSelector
  );
  const { values } = useSelector(chartDataProfitLossSelector);
  const totalTable = values ? values.reduce((a, b) => a + b, 0) : 0;

  const data = useSelector(listProfitLossSelector);
  // const countTotal = (arr: PortTransaction[]) => {
  //   return arr.reduce((total: number, item: PortTransaction) => {
  //     return total + item.profitAmt;
  //   }, 0);
  // };
  // const total = useMemo(() => {
  //   return countTotal(data);
  // }, [data]);
  const totalProfitLoss = useRef(0);
  totalProfitLoss.current = totalTable;

  const flexStart = "flex-start";
  const center = "center";
  const flexEnd = "flex-end";
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.All
  );

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc]);
  function getColor(value: string, value2: string) {
    if (parseInt(value2) < 0) {
      return (
        <TableCellContentColor align="right" color={themes[themeType].red50}>
          {value}
        </TableCellContentColor>
      );
    } else if (parseInt(value2) > 0) {
      return (
        <TableCellContentColor align="right" color={themes[themeType].green50}>
          {value}
        </TableCellContentColor>
      );
    } else if (parseInt(value2) === 0) {
      return (
        <TableCellContentColor align="right" color={themes[themeType].grey0}>
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  function getColor2(value: string, value2: string) {
    if (parseInt(value2) < 0) {
      return (
        <TableCellContentColor
          fontSize15={true}
          align="right"
          color={themes[themeType].red50}
          fontWeight={true}
        >
          {value}
        </TableCellContentColor>
      );
    } else if (parseInt(value2) > 0) {
      return (
        <TableCellContentColor
          fontSize15={true}
          align="right"
          color={themes[themeType].green50}
          fontWeight={true}
        >
          {value}
        </TableCellContentColor>
      );
    } else if (parseInt(value2) === 0) {
      return (
        <TableCellContentColor
          fontSize15={true}
          align="right"
          color={themes[themeType].grey0}
          fontWeight={true}
        >
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  const clickBuySellHandler = (data: PortTransaction, type: number) => {
    // Dispatch mã ck
    const symbol = data?.secCd;
    dispatch(onChangeCurrentTicker(symbol));

    const accountChange = listAcc?.find(
      (item) => item.subAccoNo === data?.subAccNo
    );
    dispatch(changeCurrentAccount(accountChange!));

    // Dispatch switch button mua/bán
    dispatch(onBuySellType(type === 1 ? "sell" : "buy"));

    history.push(paths.stockOrder);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="profitLoss.table.tradingDate" />
          </HeaderCell>
        ),
        accessor: "alloDate",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        Footer: (
          <TableHeaderCellContent align={flexStart} isFooter>
            <TableCellContent
              align={flexStart}
              fontSize15={true}
              fontWeight={true}
            >
              <FormattedMessage id="profitLoss.table.totalProfit" />
            </TableCellContent>
          </TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="profitLoss.table.account" />
          </HeaderCell>
        ),
        accessor: "subAccNo",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="profitLoss.table.ticker" />
          </HeaderCell>
        ),
        accessor: "secCd",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="profitLoss.table.sellVolumn" />
          </HeaderCell>
        ),
        accessor: "qty",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="profitLoss.table.buyPrice" />
          </HeaderCell>
        ),
        accessor: "price",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="profitLoss.table.value" />
          </HeaderCell>
        ),
        accessor: "amount",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="profitLoss.table.profitLoss" />
          </HeaderCell>
        ),
        accessor: "profitAmt",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {getColor(numeral(props.value).format("0,0"), props.value)}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent align={flexEnd} isFooter>
            {getColor2(
              numeral(totalProfitLoss.current.toString()).format("0,0"),
              totalProfitLoss.current.toString()
            )}
          </TableHeaderCellContent>
        ),
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="profitLoss.table.action" />
          </HeaderCell>
        ),
        accessor: "taskCd",
        minWidth: 185.2,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id: "profitLoss.groupCategory." + props.value.replace(" ", ""),
              })}
            </TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="profitLoss.table.content" />
          </HeaderCell>
        ),
        accessor: "remarks",
        minWidth: 370.4,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        Footer: (
          <TableHeaderCellContent
            align={center}
            isFooter
          ></TableHeaderCellContent>
        ),
      },

      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <div className="btn_action_hover">
              <ButtonBuy
                className="buy"
                onClick={() => clickBuySellHandler(currentRow, 2)}
              >
                <FormattedMessage id="extends.profitLossEquity.tableContent.buy" />
              </ButtonBuy>

              <ButtonSell
                className="sell"
                onClick={() => clickBuySellHandler(currentRow, 1)}
              >
                <FormattedMessage id="extends.profitLossEquity.tableContent.sell" />
              </ButtonSell>
              {/* )} */}
            </div>
          );
        },
        Footer: "",
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {
    if (data.length < (currentPage + 1) * limit) {
      return dispatch(updateEnableInfinity(false));
    }
    const submitData: IFindPortTransactionParams = {
      subAccoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      tradeType: 1,
      secCd: secCd ? secCd : "",
      page: currentPage + 1,
      rowPerPage: limit,
    };
    dispatch(fetchMoreProfitLossRequest(submitData));
  };

  return (
    <Container id="profitLossTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={enable}
        scrollableTarget={"profitLossTable"}
      />
    </Container>
  );
}

export default memo(RequestTable);
