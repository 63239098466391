export const loginUrl = "/login";
export const logoutUrl = "/logout";
export const resetUrl = "public/resetPassword";
export const validateResetPasswordOtp = "public/validateResetPasswordOtpCode";
export const refershTokenUrl = "/refresh-token";
export const initTrading = "/initTradingMobile";
export const initSession = "/initSession";
export const getMasterAccountUrl = (masterAccount: string) =>
  `/info/${masterAccount}`;

export const getListing = (masterAccount: string) =>
  `${getMasterAccountUrl(masterAccount)}/listing`;
