import { FC } from "react";
import { useIntl } from "react-intl";
import Container from "components/commons/Container";
import { getTypeTransferSelector } from "modules/transfer-internal/selectors";
import { useSelector } from "react-redux";
import TransferInternalHistory from "./components/TransferLocalContent/TransferInternalHistory";
import TransferInternalForm from "./components/TransferLocalContent/TransferInternalForm";

const TransferLocal: FC = () => {
  const intl = useIntl();
  const typeTranfer = useSelector(getTypeTransferSelector);

  const arrayForm = [TransferInternalHistory, TransferInternalForm];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "transferInternal.historyTransfer.title",
      })}
      headerForm={
        typeTranfer === "2" || typeTranfer === "3"
          ? intl.formatMessage({ id: "transferInternal.deposit.title" })
          : intl.formatMessage({
              id: "transferInternal.transferInternal.title",
            })
      }
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default TransferLocal;
