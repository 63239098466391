import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import {
  cleanData,
  requestChangeTopStatisticalType,
  requestTopStatistical,
  updateTicker,
} from "modules/market/redux/topStatistical";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import { TopStatisticalMenu } from "./types";
import { useEffectOnce, useInterval } from "react-use";
import { TableWrapper, TopStatisticalCard } from "./styles";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import {
  COLUMNS_TOP_VOLUME,
  COLUMNS_TOP_VALUE,
  COLUMNS_TOP_CHANGE,
} from "./constant";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { LastSale, SystemState } from "domain/protoNew/auto_trading_pb";
import { activeTabTopStatisticalSelector } from "modules/market/redux/selectors";

import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

const getColumns = (activeTab: string) => {
  switch (activeTab) {
    case "topVolume":
      return COLUMNS_TOP_VOLUME;
    case "topValue":
      return COLUMNS_TOP_VALUE;
    case "topChange":
      return COLUMNS_TOP_CHANGE;
    default:
      return COLUMNS_TOP_VOLUME;
  }
};

function TopStatistical(): React.ReactElement {
  const activeTab = useSelector(activeTabTopStatisticalSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);
  const dispatch = useDispatch();

  function handleChangeTopStatisticalType(
    topStatisticalType: TopStatisticalMenu
  ) {
    dispatch(requestChangeTopStatisticalType(topStatisticalType));
  }

  useInterval(() => {
    dispatch(requestTopStatistical());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    const eventListenerLastSale = (data: LastSale.AsObject) => {
      dispatch(updateTicker(data));
    };
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(requestTopStatistical());
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    dispatch(requestChangeTopStatisticalType(activeTab));
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
      dispatch(cleanData());
    };
  });

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      dispatch(requestChangeTopStatisticalType(activeTab));
    }
  }, [isVisibilityPage]);

  return (
    <TopStatisticalCard>
      <Header
        activeTab={activeTab}
        onChangeTopStatisticalType={handleChangeTopStatisticalType}
      />
      <TableWrapper>
        <TableHeader columns={getColumns(activeTab)} />
        <TableBody rowHeight={36} activeTab={activeTab} />
      </TableWrapper>
    </TopStatisticalCard>
  );
}

export default memo(TopStatistical);
