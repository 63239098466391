import { useEffect } from "react";
import {
  Wrap,
  Item,
  ItemImage,
  Itemdescription,
  ItemLabel,
  ItemContent,
  ItemView,
} from "./styles";
import moment from "moment";
import { tradeDateSelector } from "modules/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import Image from "react-bootstrap/Image";
import { validURL } from "helper/utils";
import {
  activeTabGuideVideoSelector,
  btnActiceGuideVideoSelector,
  listArticleSelector,
  offsetGuideVideoSelector,
  limitGuideVideoSelector,
  enableInfinitySelector,
} from "modules/guideVideo/selectors";
import { IFindArticlesParams } from "core/http/apis/guideVideo/types";
import { findArticlesRequest } from "modules/guideVideo/redux";
import { ActiveTabGuideVideo } from "modules/confirmation/pages/constants";

const ListVideo = () => {
  const dispatch = useDispatch();
  const tradeDate = useSelector(tradeDateSelector);
  const activeTab = useSelector(activeTabGuideVideoSelector);
  const categoryId = useSelector(btnActiceGuideVideoSelector);
  const listArticle = useSelector(listArticleSelector);
  const offset = useSelector(offsetGuideVideoSelector);
  const limit = useSelector(limitGuideVideoSelector);
  const enableInfinity = useSelector(enableInfinitySelector);

  const getDataApi = (loadmore: boolean) => {
    let currentOffset = offset ? offset : 0;
    if (!loadmore) {
      currentOffset = 0;
    }

    const param: IFindArticlesParams = {
      articleType: activeTab === ActiveTabGuideVideo.TRAINING ? 1 : 2,
      categoryId: Number(categoryId),
      businessDate: Number(tradeDate),
      offset: currentOffset,
      limit: limit,
    };

    dispatch(findArticlesRequest({ params: param, isRefestData: false }));
  };

  const wheelList = () => {
    if (!enableInfinity) return;
    setTimeout(async () => {
      getDataApi(true);
    }, 1000);
  };

  const coverThumbail = (url: any): string => {
    if (!validURL(url)) return "";
    const api = new URL(url);
    const obj = Object.fromEntries(new URLSearchParams(api.search));
    return obj?.v ? obj?.v : "";
  };

  const renderContent = () => {
    return listArticle.map((e: any) => {
      const urlImage = coverThumbail(e?.link);
      return (
        <Item onClick={() => window.open(e?.link)}>
          <ItemImage>
            <Image
              src={`https://img.youtube.com/vi/${urlImage}/maxresdefault.jpg`}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px 8px 0px 0px",
              }}
            />
          </ItemImage>
          <Itemdescription>
            <ItemLabel>{e?.title}</ItemLabel>
            <ItemContent>{e?.description}</ItemContent>
            <ItemView>
              __ -{" "}
              {e?.regDateTime
                ? moment(e?.regDateTime, "YYYYMMDD").format("DD/MM/YYYY")
                : "__"}
            </ItemView>
          </Itemdescription>
        </Item>
      );
    });
  };

  useEffect(() => {
    getDataApi(false);
  }, [activeTab, categoryId]);

  return <Wrap onWheel={() => wheelList()}>{renderContent()}</Wrap>;
};

export default ListVideo;
