import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type ChartData = {
  labels: string[];
  datasets: any[];
};

export type StatBasketContributionState = {
  statBasketContributionData: ChartData;
  loading: boolean;
  error: string | undefined;
};

const initialState: StatBasketContributionState = {
  statBasketContributionData: {
    labels: [],
    datasets: [],
  },
  loading: false,
  error: undefined,
};

const statBasketContributionSlice = createSlice({
  name: "statBasketContributionSlice",
  initialState,
  reducers: {
    getStatBasketContributionData: (state) => {
      state.loading = true;
    },
    getStatBasketContributionDataSuccess: (
      state,
      action: PayloadAction<{ data: ChartData }>
    ) => {
      state.statBasketContributionData = action.payload.data;
      state.loading = false;
    },
    getStatBasketContributionDataFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getStatBasketContributionData,
  getStatBasketContributionDataSuccess,
  getStatBasketContributionDataFailure,
} = statBasketContributionSlice.actions;

export default statBasketContributionSlice.reducer;
