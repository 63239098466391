import { all, call, takeLatest, put } from "redux-saga/effects";
import {
  getNewsInfo,
  getNewsInfoSuccess,
  getNewsInfoFailure,
  getNewsDetailRequest,
  getNewsDetailSuccess,
  getNewsDetailFailure,
} from "../redux/newsInfo";
import { toast } from "components/commons/Toast";
import { getSectNews, getSectNewsDetail } from "core/grpc";
import {
  SectNewsDetailItem,
  SectNewsItem,
} from "domain/protoNew/auto_trading_pb";

function* fetchNewsInfoByTicker(action: ReturnType<typeof getNewsInfo>) {
  try {
    const response: SectNewsItem.AsObject[] = yield call(
      getSectNews,
      action.payload.secCd,
      action.payload.page,
      action.payload.size
    );

    yield put(
      getNewsInfoSuccess({
        items: response,
        offset: action.payload.page,
      })
    );
  } catch (e: any) {
    yield put(getNewsInfoFailure(e));
  }
}

function* getNewsDetailWorker(action: ReturnType<typeof getNewsDetailRequest>) {
  try {
    const articleId: number | null = action.payload;
    if (articleId === null) return;

    const response: SectNewsDetailItem.AsObject = yield call(
      getSectNewsDetail,
      articleId
    );

    yield put(getNewsDetailSuccess(response));
  } catch (error: any) {
    yield put(getNewsDetailFailure());
    if (error?.code) {
      toast(error.code, "error", error.description, true);
    } else {
      toast("defaultErr.defaultErr", "error");
    }
  }
}

function* watchNewsInfo() {
  yield takeLatest(getNewsInfo.type, fetchNewsInfoByTicker);
}

function* getNewsDetailWatcher() {
  yield takeLatest(getNewsDetailRequest.type, getNewsDetailWorker);
}

export default function* newsInfoWidget() {
  yield all([watchNewsInfo(), getNewsDetailWatcher()]);
}
