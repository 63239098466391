import styled from "styled-components/macro";

export const Wrap: any = styled.div`
  height: 13%;
  .button-filter {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
  }
`;

export const WrapTypeTransfer: any = styled.div`
  width: 190px;
  p {
    margin-bottom: 4px;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
  }
`;
