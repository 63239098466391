import { Button, CheckBox } from "components/commons";

import {
  updateItemEdit,
  depositPowerRegisterRequest,
  searchDepositPowerRequest,
} from "modules/deposit-power/redux";
import { ISearchDepositPowerParams } from "core/http/apis/deposit-power/types";
import { accountInfoSelector } from "modules/auth/selectors";
import {
  depositPowerEditSelector,
  statusDepositPowerRegisterSelector,
  filterDepositPowerSelector,
} from "modules/deposit-power/selectors";

import moment from "moment";
import storage from "helper/storage";
import { ChangeEvent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailWrapper,
  DetailRowTextTop,
  DetailRowPaddingTB,
  DetailRowButton,
  CheckBoxRow,
  CheckBoxText,
  DetailColFlexEnd,
  Link,
} from "./styles";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { updateModalOtpRequest } from "modules/advance/redux";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { Row, Col } from "react-bootstrap";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import TextArea from "components/commons/Input/textArea";
import { toast } from "components/commons/Toast";
function RequestDetail() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { currentUser, tradeDate } = useSelector(accountInfoSelector);
  const itemEdit = useSelector(depositPowerEditSelector);
  const isVisible = useSelector(isShowOtpModalSelector);
  const [checked, setChecked] = useState(false);
  const statusDepositPowerRegister = useSelector(
    statusDepositPowerRegisterSelector
  );
  const { contractSts, fromDate, toDate } = useSelector(
    filterDepositPowerSelector
  );
  const themeType = useSelector(themeTypeSelector);
  useEffect(() => {
    if (statusDepositPowerRegister) {
      const submitData: ISearchDepositPowerParams = {
        branchCd: "",
        transactionCd: "",
        custNo: storage.getMasterAccount() ?? "",
        fromDate: fromDate ? Number(moment(fromDate).format("YYYYMMDD")) : 0,
        toDate: toDate ? Number(moment(toDate).format("YYYYMMDD")) : 0,
        contractSts: contractSts ? contractSts : 0,
        status: "",
        srcChannel: "",
      };
      dispatch(searchDepositPowerRequest(submitData));
      toast("depositPower.message.depositPowerRegisterContent", "success");
      dispatch(updateItemEdit({ remarks: "" }));
    }
  }, [statusDepositPowerRegister]);

  const changeRemarksHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateItemEdit({ remarks: e?.target?.value ?? "" }));
  };

  const clickAcceptHandler = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
  };

  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    dispatch(
      depositPowerRegisterRequest({
        actionMode: 1,
        id: null,
        contractNo: null,

        custNo: storage.getMasterAccount() ?? "",

        tradeDate: tradeDate ? tradeDate : "",

        remarks: itemEdit?.remarks ? itemEdit.remarks : "",
        timeStamp: null,
        srcChannel: 2,
        otpCode: response?.otp ?? "",
      })
    );
  };
  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100  mt-2"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositPower.create.account" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{currentUser.userId}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositPower.create.accountName" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{currentUser.displayName}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRowTextTop>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1", marginTop: "-8px" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="depositPower.create.remarks" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <TextArea
                    textArea={true}
                    value={itemEdit?.remarks}
                    onChange={changeRemarksHandler}
                    placeholder={intl.formatMessage({
                      id: "addFrameworkContractForm.createContract.placeholderRemark",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowTextTop>
      </DetailCol>
      <DetailColFlexEnd>
        <CheckBoxRow>
          <CheckBox onChange={() => setChecked(!checked)} checked={checked} />

          {/* <CheckBoxText>
            <FormattedMessage id="depositPower.create.mess1" />
            <ColorCheckBoxText color={themes[themeType].brandPrimary}>
              <FormattedMessage id="depositPower.create.mess2" />
            </ColorCheckBoxText>
          </CheckBoxText> */}
          <CheckBoxText>
            <FormattedMessage
              id="depositPower.create.mess1"
              values={{
                link: (
                  <Link
                    target="_blank"
                    href={
                      process.env
                        .REACT_APP_PSI_Dieu_khoan_dieu_kien_PL_lien_ket_suc_mua
                    }
                    // href="http://dev1.goline:8001/downloads/PSI_Dieu_khoan_dieu_kien_PL_lien_ket_suc_mua.pdf"
                  >
                    <FormattedMessage id="depositPower.create.mess2" />
                  </Link>
                ),
              }}
            />
          </CheckBoxText>
        </CheckBoxRow>
        <DetailRowButton>
          <Button
            style={{
              background: `${themes[themeType].buttonGradientColor}`,
              fontSize: `${themes[themeType].typeFaces.headline.size}`,
              padding: "18px 0px",
            }}
            className="button-filter m-1 w-100 button-disable-opac"
            onClick={clickAcceptHandler}
            fill={true}
            disabled={!checked}
          >
            {intl.formatMessage({
              id: "depositPower.create.confirm",
            })}
          </Button>
        </DetailRowButton>
      </DetailColFlexEnd>

      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.DEPOSIT}
        />
      )}
    </DetailWrapper>
  );
}

export default RequestDetail;
