// import { allParamSelector } from "modules/auth/selectors";
import React, {
  useEffect,
  // useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
// import { useSelector } from "react-redux";
import { TextWrapper } from "../styles";
import { CountDown } from "./styles";

interface IProps {
  handleCreateSmsOTP: any;
  firstTime: number;
  duringTime: number;
}

const ResendCode = ({ handleCreateSmsOTP, firstTime, duringTime }: IProps) => {
  const [seconds, setSeconds] = useState(firstTime);

  const clickResendCodeHandler = () => {
    handleCreateSmsOTP();
    setSeconds(duringTime);
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <TextWrapper>
      {seconds === 0 ? (
        <span className="allowClick" onClick={clickResendCodeHandler}>
          <FormattedMessage id="auth.info.smsOTP.resendCode" />
        </span>
      ) : (
        <span>
          <FormattedMessage
            id="auth.info.smsOTP.countdown"
            values={{ second: <CountDown>({seconds}s)</CountDown> }}
          />
        </span>
      )}
    </TextWrapper>
  );
};

export default ResendCode;
