import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import MarginContractWatch from "./pages/MarginContractWatch";

const routes: RouterConfig[] = [
  {
    page: MarginContractWatch,
    path: paths.marginContractWatch,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
