export const GRPC_SCREENERS_URL = process.env.REACT_APP_GRPC_SCREENERS_URL_NEW;
export const GRPC_SCREENERS_PORT =
  process.env.REACT_APP_GRPC_SCREENERS_PORT_NEW;
export const GRPC_URL_NEW = process.env.REACT_APP_GRPC_URL_NEW || "";
export const GRPC_PORT_NEW = process.env.REACT_APP_GRPC_PORT_NEW;

export const RESTFUL_URL = process.env.REACT_APP_RESTFUL_URL || "";
export const RESTFUL_AUTH_URL = process.env.REACT_APP_RESTFUL_AUTH_URL || "";
export const RESTFUL_BASE_URL = process.env.REACT_APP_RESTFUL_BASE_URL || "";
export const RESTFUL_WATCHLIST_URL =
  process.env.REACT_APP_RESTFUL_WATCHLIST_URL || "";
export const RESTFUL_GLOBALMIND_GATEWAY_URL =
  process.env.REACT_APP_RESTFUL_GLOBALMIND_GATEWAY_URL || "";
export const RESTFUL_GLOBALMIND_URL =
  process.env.REACT_APP_RESTFUL_GLOBALMIND_URL || "";
export const RESTFUL_EKYC_URL = process.env.REACT_APP_RESTFUL_EKYC_URL || "";
export const RESTFUL_API_URL = process.env.REACT_APP_RESTFUL_API_URL || "";
export const RESTFUL_VIETSTOCK_URL =
  process.env.REACT_APP_RESTFUL_VIETSTOCK_URL || "";
export const RESTFUL_ACCOUNT_URL =
  process.env.REACT_APP_RESTFUL_ACCOUNT_URL || "";
export const RESTFUL_ACCOUNT_CUSTOMER_URL =
  process.env.REACT_APP_RESTFUL_CUSTOMER_URL || "";
export const RESTFUL_CUSTOMER_URL =
  process.env.REACT_APP_RESTFUL_CUSTOMER_URL || "";
export const RESTFUL_TWO_FACTOR_URL =
  process.env.REACT_APP_RESTFUL_TWO_FACTOR_URL || "";
export const RESTFUL_SYSTEM_URL =
  process.env.REACT_APP_RESTFUL_SYSTEM_URL || "";
export const RESTFUL_LOAN_URL = process.env.REACT_APP_RESTFUL_LOAN_URL || "";
export const RESTFUL_TRANS_URL = process.env.REACT_APP_RESTFUL_TRANS_URL || "";
export const RESTFUL_PROVINCE_URL =
  process.env.REACT_APP_RESTFUL_PROVINCE_URL || "";
export const RESTFUL_SCREENER_URL =
  process.env.REACT_APP_RESTFUL_SCREENER_URL || "";
export const RESTFUL_PARAM_URL = process.env.REACT_APP_RESTFUL_PARAM_URL || "";
export const RESTFUL_ORDER_URL = process.env.REACT_APP_RESTFUL_ORDER_URL || "";
export const REACT_APP_RESTFUL_FEEDBACK_URL =
  process.env.REACT_APP_RESTFUL_FEEDBACK_URL || "";
