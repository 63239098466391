import { memo, useEffect } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  clearData,
  getVolumeAndTime,
  updateHistoryListRealTime,
} from "modules/tickerDetails/redux/volumeAndTime";
import { useSelector, useDispatch } from "react-redux";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import VolumeAndTime from "components/widgets/details/VolumnAndTime";
import {
  historyListSelector,
  volumeListSelector,
  selectedTickerSelector,
} from "modules/market/redux/selectors";
import { LastSale } from "domain/protoNew/auto_trading_pb";

export type MarketVolumeAndTimeProps = {
  isEnableHeader?: boolean;
};

function MarketVolumeAndTime({
  isEnableHeader = false,
}: MarketVolumeAndTimeProps) {
  const dispatch = useDispatch();
  const selectedTicker = useSelector(selectedTickerSelector);
  const volumeList = useSelector(volumeListSelector);
  const historyList = useSelector(historyListSelector);

  useEffect(() => {
    if (selectedTicker! !== null) {
      dispatch(getVolumeAndTime(selectedTicker!));
    }
  }, [selectedTicker, dispatch]);

  useEffect(() => {
    if (selectedTicker === "") {
      dispatch(clearData());
    }
  }, [selectedTicker]);

  useEffect(() => {
    const eventListener = (dataEmmit: LastSale.AsObject) => {
      if (selectedTicker === dataEmmit.seccd) {
        const ticker = {
          id: uuid(),
          color: dataEmmit.colorcode,
          time: moment(dataEmmit.mattime, "Hmmss").format("HH:mm:ss"),
          price: dataEmmit.lastprice,
          volume: dataEmmit.lastqty,
          eside: dataEmmit.side?.data ?? "",
        };
        dispatch(updateHistoryListRealTime(ticker));
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListener);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListener);
    };
  }, [selectedTicker]);

  return (
    <VolumeAndTime
      isEnableHeader={isEnableHeader}
      historyList={historyList}
      volumeList={volumeList}
      selectedTicker={selectedTicker}
    />
  );
}

export default memo(MarketVolumeAndTime) as React.FC<MarketVolumeAndTimeProps>;
