import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { Select, ModalConfirm } from "components/commons";
import { changeCurrentAccount } from "modules/auth/redux";
import Storage from "helper/storage";
import {
  currentAccountSelector,
  statusSetDefaultSelector,
  errorSetDefaultSelector,
} from "modules/auth/selectors";
import { useIntl } from "react-intl";
import { GetAccoList } from "helper/sessionData";
import { ISetDefaultSubAccoNoParams } from "core/http/apis/account/types";
import {
  clearStatusSetDefault,
  setDefaultSubAccoNo,
} from "modules/auth/redux/tradingAccount";
import { toast } from "components/commons/Toast";

function AccountSelect() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentAccount = useSelector(currentAccountSelector);

  const statusSetDefault = useSelector(statusSetDefaultSelector);
  const errorSetDefault = useSelector(errorSetDefaultSelector);
  const [visibleModalConfirm, setVisibaleModalConfirm] = useState(false);
  const [accountSelect, setAccountSelected] = useState("");

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.All
  );

  const accounts = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoCd!,
    }));
    return list;
  }, [listAcc, intl]);

  const handleChange = (option: any) => {
    if (option.value === currentAccount.subAccoNo) return;
    setAccountSelected(option.value);
    setVisibaleModalConfirm(true);
  };

  const setConfirm = () => {
    if (accountSelect) {
      const params: ISetDefaultSubAccoNoParams = {
        subAccoCd: accountSelect,
      };
      dispatch(setDefaultSubAccoNo(params));
    }
  };

  useEffect(() => {
    if (statusSetDefault === "SUCCESSFUL") {
      toast("auth.tradingAccount.setDefaultSuccess", "success");
      const accountChange = listAcc?.find(
        (item: any) => item.subAccoCd === accountSelect
      );
      // ghi DefaultAccount vào storage
      let dataCache: any = Storage.getDefaultAccount();
      dataCache[Storage.getMasterAccount()] = accountChange!;
      Storage.setDefaultAccount(JSON.stringify(dataCache));
      dispatch(changeCurrentAccount(accountChange!));
      dispatch(clearStatusSetDefault());
    }

    if (statusSetDefault === "FAILURE") {
      const codeError = errorSetDefault?.code;
      if (codeError) {
        const msg = `${errorSetDefault?.code?.toString().replaceAll("-", "_")}`;
        const msgFinal = msg
          ? "error." + msg
          : "auth.tradingAccount.errorDefault";
        toast(msgFinal, "error");
      } else {
        toast("error.defaultErr", "error");
      }
      dispatch(clearStatusSetDefault());
    }
  }, [statusSetDefault]);

  return (
    <>
      <Select
        className="flex-fill"
        value={accounts.find(
          (item: any) => item.label === currentAccount.subAccoNo
        )}
        options={accounts}
        onChange={handleChange}
      />
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibaleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({
          id: "auth.tradingAccount.titleConfirmSetDefault",
        })}
        content={intl.formatMessage({
          id: "auth.tradingAccount.contentConfirmSetDefault",
        })}
      />
    </>
  );
}

export default AccountSelect;
