import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import {
  changeActiveSort,
  changeStatisticalTypeSuccess,
  requestChangeTopStatisticalType,
  requestLoadMore,
  requestLoadMoreSuccess,
  requestTopStatistical,
  requestTopStatisticalSuccess,
} from "../redux/topStatistical";
import { TopStatisticalMenu } from "components/widgets/generals/TopStatistical/types";
import { getTopSecChanged } from "core/grpc";
import {
  TopSecChangedInput,
  TopSecChangedResponse,
} from "domain/protoNew/auto_trading_pb";
import { AuthenCodeGrpc, TOP_PRICE_LOADING_LIMIT } from "AppConfig";
import {
  activeTabTopStatisticalSelector,
  activeSortTopStatisticalSelector,
  marketTopStatisticalSelector,
  currentMarketCodeSelector,
} from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";

function getDataForRequestInfo(value: TopStatisticalMenu) {
  switch (value) {
    case "topVolume":
      return 2;
    case "topValue":
      return 1;
    case "topChange":
      return 3;
    default:
      return 2;
  }
}

function* fetchTickersByTopStatisticalTypeWorker() {
  const activeTab: TopStatisticalMenu = yield select(
    activeTabTopStatisticalSelector
  );
  const { sortBy, order } = yield select(activeSortTopStatisticalSelector);
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  const request = new TopSecChangedInput();
  request.setUserid(uuidv4());
  request.setMarketcd(currentMarketCode.toString());
  request.setChangedtype(getDataForRequestInfo(activeTab));
  request.setPeriodtype(1);
  request.setOffset(0);
  request.setOrderfieldtype(sortBy);
  request.setOrdertype(order);
  request.setLimit(TOP_PRICE_LOADING_LIMIT);
  request.setAuthencode(AuthenCodeGrpc);

  try {
    const response: TopSecChangedResponse.TopSecChangedInfo.AsObject[] =
      yield call(getTopSecChanged, request);

    yield put(changeStatisticalTypeSuccess(response));
  } catch (e) {}
}

function* fetchTickersByTopStatisticalLoadMoreWorker() {
  try {
    const { limit, activeTab, enableLoadMore } = yield select(
      marketTopStatisticalSelector
    );
    const { sortBy, order } = yield select(activeSortTopStatisticalSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    if (enableLoadMore === false) return;
    const request = new TopSecChangedInput();
    request.setUserid(uuidv4());
    request.setMarketcd(currentMarketCode.toString());
    request.setChangedtype(getDataForRequestInfo(activeTab));
    request.setPeriodtype(1);
    request.setOffset(limit);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(TOP_PRICE_LOADING_LIMIT);
    request.setAuthencode(AuthenCodeGrpc);

    const response: TopSecChangedResponse.TopSecChangedInfo.AsObject[] =
      yield call(getTopSecChanged, request);
    yield put(requestLoadMoreSuccess(response));
  } catch (e) {}
}

function* fetchTopStatisticalWorker() {
  try {
    const { limit, activeTab } = yield select(marketTopStatisticalSelector);
    const { sortBy, order } = yield select(activeSortTopStatisticalSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const request = new TopSecChangedInput();
    request.setUserid(uuidv4());
    request.setMarketcd(currentMarketCode.toString());
    request.setChangedtype(getDataForRequestInfo(activeTab));
    request.setPeriodtype(1);
    request.setOffset(0);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(limit);
    request.setAuthencode(AuthenCodeGrpc);

    const response: TopSecChangedResponse.TopSecChangedInfo.AsObject[] =
      yield call(getTopSecChanged, request);
    yield put(requestTopStatisticalSuccess(response));
  } catch (e) {}
}

function* fetchTickersByTopStatisticalTypeWatcher() {
  yield takeLatest(
    requestChangeTopStatisticalType.type,
    fetchTickersByTopStatisticalTypeWorker
  );
}
function* fetchTickersByActiveSortWatcher() {
  yield takeLatest(
    changeActiveSort.type,
    fetchTickersByTopStatisticalTypeWorker
  );
}
function* fetchTickersByTopStatisticalLoadMoreWatcher() {
  yield takeLatest(
    requestLoadMore.type,
    fetchTickersByTopStatisticalLoadMoreWorker
  );
}
function* fetchTopStatisticalWatcher() {
  yield takeLatest(requestTopStatistical.type, fetchTopStatisticalWorker);
}

function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchTopStatisticalWorker);
}

export default function* topStatisticalWidget() {
  yield all([
    fetchTickersByTopStatisticalTypeWatcher(),
    fetchTickersByActiveSortWatcher(),
    fetchTickersByTopStatisticalLoadMoreWatcher(),
    fetchTopStatisticalWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
