export interface uploadImageEkycResponse {
  status: string;
  data: {
    fileName: string;
    tokenId: string;
    description: string;
    storageType: string;
    title: string;
    uploadedDate: string;
    hash: string;
    fileType: string;
  };
}

export interface ekycControllerOcrIdRequest {
  backImage: string;
  clientSession: string;
  frontImage: string;
  type: string;
}

export interface ekycControllerOcrIdResponse {
  status: string;
  data: {
    origin_location: string;
    gender: string;
    valid_date: string;
    issue_date: string;
    id: string;
    issue_place: string;
    recent_location: string;
    card_type: string;
    nationality: string;
    name: string;
    birth_day: string;
  };
}

export interface postEkycFaceLivenessRequest {
  image: string;
  clientSession: string;
}

export interface postEkycFaceLivenessResponse {
  status: string;
  data: {
    liveness: FaceLiveness;
    blur_face: string;
    liveness_msg: string;
    is_eye_open: string;
    blur_face_score: number;
    liveness_prob: number;
  };
}

export enum FaceLiveness {
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface postEkycFaceCompareRequest {
  faceImage: string;
  frontImage: string;
  clientSession: string;
}

export interface postEkycFaceCompareResponse {
  status: string;
  data: {
    match_warning: string;
    msg: FaceCompare;
    multiple_faces: string;
    prob: number;
    result: string;
  };
}

export enum FaceCompare {
  MATCH = "MATCH",
  NOMATCH = "NOMATCH",
}

export interface postEkycFaceServiceVerifyRequest {
  img: string;
  idCard: string;
  idType: string;
}
