import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
import { TableCellContent } from "components/commons/CommonTable/styles";

const lastmodifiedSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) =>
      state?.[realTimeId]?.lastMatchTimeDisp
  );
};

interface Props {
  realTimeId: string;
}

const LastModifiedCell = ({ realTimeId }: Props) => {
  const value = useSelector(lastmodifiedSelector(realTimeId));
  return (
    <>
      <TableCellContent align={"center"}>{value}</TableCellContent>
    </>
  );
};

export default LastModifiedCell;
