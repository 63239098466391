import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const orderHistorySelector = (state: RootState) => state.orderHistory;

export const activeTabSelector = createSelector(
  orderHistorySelector,
  (state) => state.activeTab
);

export const listOrderHistorySelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.list
);
export const listOrderHistoryCondSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.listCond
);
export const listOrderDetailModalSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.listDetailModal
);
export const listOrderDetailNormalModalSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.listDetailNormalModal
);

export const loadingSearchDataSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.loading
);

export const filterOrderHistorySelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.filter
);

export const itemDetailSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.itemDetail
);
export const itemDetailCondSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.itemDetailCond
);
export const isShowConfirmModalSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.isShowConfirmModal
);

export const listOrderHistoryDerSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.list
);
export const listOrderHistoryDerCondSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.listCond
);
export const listOrderDetailNormalDerModalSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.listDetailNormalModal
);

export const listOrderDerDetailModalSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.listDetailModal
);

export const loadingSearchDataDerSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.loading
);

export const filterOrderHistoryDerSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.filter
);

export const itemDetailDerSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.itemDetail
);
export const itemDetailCondDerSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.itemDetailCond
);
export const isShowConfirmModalDerSelector = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.isShowConfirmModalDer
);

export const selectLimit = createSelector(
  orderHistorySelector,
  (state) => state.limit
);

export const selectPageOder = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.currentPageOder
);

export const selectEnableInfinityOder = createSelector(
  orderHistorySelector,
  (state) => state.orderHistory.enableInfinityOder
);

export const selectPageDer = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.currentPage
);

export const selectEnableInfinityDer = createSelector(
  orderHistorySelector,
  (state) => state.orderHistoryDer.enableInfinity
);
