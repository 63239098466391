import { createSelector } from "@reduxjs/toolkit";
import { stringColorTicker } from "helper/utils";
import {
  categoriesInfoSelector,
  colorcodeSelector,
  basicpriceSelector,
} from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import TableChart from "components/commons/Charts/TableChart";

interface Props {
  ticker: string;
}

const priceinfolistListSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.priceinfolistList
  );
};

function ChartCell({ ticker }: Props) {
  const dataChart = useSelector(priceinfolistListSelector(ticker));
  const colorcode = useSelector(colorcodeSelector(ticker));
  const basicprice = useSelector(basicpriceSelector(ticker)) || 0;

  return (
    <TableChart
      data={dataChart?.map((item: any) => item.price)}
      color={stringColorTicker(colorcode)}
      referencePrice={basicprice}
      visibleTooltip={false}
    />
  );
}

export default ChartCell;
