import HttpClient from "../../index";
import { RESTFUL_ACCOUNT_CUSTOMER_URL } from "AppConfig";

export default class CustomerHttp extends HttpClient {
  private static classInstance?: CustomerHttp;
  private constructor() {
    super(RESTFUL_ACCOUNT_CUSTOMER_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new CustomerHttp();
    }

    return this.classInstance;
  }
}
