import { FC } from "react";
import { ChangeValuePriceCell as ChangeValuePriceCellWrapper } from "../styles";
import BlinkableCell from "../BlinkableCell";
import { RootState } from "redux/store";
import { WatchListState } from "modules/stock-order/redux/watchList";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { Spacer } from "components/commons";
import { STOCK_COLOR_CODE } from "domain/protoNew/auto_trading_pb";
import { ReactComponent as ArrowUp } from "assets/image/svg/arrowUp16.svg";
import { ReactComponent as ArrowDown } from "assets/image/svg/arrowDown.svg";
import { ReactComponent as ArrowUpViolet } from "assets/image/svg/arrowUpViolet16.svg";
import { ReactComponent as ArrowDownBlue } from "assets/image/svg/arrowDownBlue16.svg";
import { ReactComponent as ArrowHorizontal } from "assets/image/svg/arrowHorizontal16.svg";

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const changePriceValueSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.secdetailinfo?.changepoint
  );
};

const textColorSelector = (tickerName: string) => {
  return createSelector(
    watchListSelector,
    (watchListState: WatchListState) =>
      watchListState.data[tickerName]?.colorcode
  );
};

interface Props {
  ticker: string;
}

const getArrow = (color: number) => {
  switch (color) {
    case STOCK_COLOR_CODE.CEILING_PRICE:
      return <ArrowUpViolet />;
    case STOCK_COLOR_CODE.UP_PRICE:
      return <ArrowUp />;
    case STOCK_COLOR_CODE.DOWN_PRICE:
      return <ArrowDown />;
    case STOCK_COLOR_CODE.FLOOR_PRICE:
      return <ArrowDownBlue />;
    default:
      return <ArrowHorizontal />;
  }
};

const ChangePriceValueCell: FC<Props> = ({ ticker }) => {
  const changePriceValue = useSelector(changePriceValueSelector(ticker)) || 0;
  const textColor = useSelector(textColorSelector(ticker));

  return (
    <div className="d-flex align-items-center">
      {getArrow(textColor)}
      <Spacer size="xs" />
      <ChangeValuePriceCellWrapper textcolor={textColor}>
        <BlinkableCell value={numeral(changePriceValue).format("0,0.00")} />
      </ChangeValuePriceCellWrapper>
    </div>
  );
};

export { ChangePriceValueCell };
