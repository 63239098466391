import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import { Spacer } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  NavItemCard,
  // Name,
  MenuItemCard,
  MenuItemCardChild,
  ItemSelect,
  NavItemMenus,
  Name,
} from "./styles";
import { toggleModalLogin } from "modules/auth/redux";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import {
  pushMenuDotRed,
  setIsOpenExtra,
  setVisibleContainerExtra,
  setVisibleIconExtra,
} from "modules/system/redux/common";
import { Spacer } from "components/commons";

interface Props {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  option: {
    label: string;
    value?: string;
    option?: {
      label: string;
      value?: string;
      checkLogin?: Boolean;
    }[];
    linkTo?: string;
    checkLogin?: Boolean;
    checkDotRed?: Boolean;
  }[];
  fn?: string;
  slug?: string;
  checkLogin?: Boolean;
}

const NavItemMenu: React.FC<Props> = React.memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isHover, setHover] = useState(false);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const {
    icon: Icon,
    activeIcon: ActiveIcon,
    name,
    option,
    slug,
    checkLogin,
  } = props;
  const intl = useIntl();
  const location = useLocation();
  const isActive = option.find((item) => item.value === location.pathname)
    ? true
    : false;
  return (
    <NavItemMenus>
      <NavItemCard
        onClick={() => {
          if (checkLogin && isAuthenticated === false) {
            //check chưa đăng nhập sẽ mở login
            dispatch(toggleModalLogin(true));
          } else {
            if (slug !== "/stock-order" && slug !== "/stock-order-der") {
              dispatch(setVisibleIconExtra(true));
            } else {
              dispatch(setIsOpenExtra(false));
              dispatch(setVisibleIconExtra(false));
              dispatch(setVisibleContainerExtra(false));
            }
          }
          history.push(slug ? slug : location.pathname);
        }}
        isActive={isActive}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {isActive ? <ActiveIcon /> : <Icon />}
        <Spacer size="s" />

        <Name isActive={isActive} isHover={isHover}>
          {name}
        </Name>

        {/* {fn && <div className="hot-key">{fn}</div>} */}
      </NavItemCard>
      <MenuItemCard className="menu">
        {option.map((item, index) => (
          <Link
            className={item.option && "link-child"}
            key={`link-${index}`}
            to={item.value ? item.value : location.pathname}
            onClick={() => {
              if (item.checkLogin && isAuthenticated === false) {
                //check chưa đăng nhập sẽ mở login
                dispatch(toggleModalLogin(true));
              } else {
                if (slug !== "/stock-order" && slug !== "/stock-order-der") {
                  dispatch(setVisibleIconExtra(true));
                } else {
                  dispatch(setIsOpenExtra(false));
                  dispatch(setVisibleIconExtra(false));
                  dispatch(setVisibleContainerExtra(false));
                }
              }
              if (item.checkDotRed) dispatch(pushMenuDotRed(item.label));
              if (item.linkTo) return window.open(item.linkTo, "_blank");
              if (item.value) return;
            }}
          >
            <ItemSelect className="item-select">
              {intl.formatMessage({ id: item.label })}
            </ItemSelect>
            {item.option && (
              <MenuItemCardChild className="menu-child">
                {item.option?.map((itemChild, indexChild) => (
                  <Link
                    key={`link-${indexChild}`}
                    to={itemChild.value ? itemChild.value : location.pathname}
                    onClick={() => {
                      if (itemChild.checkLogin && isAuthenticated === false) {
                        //check chưa đăng nhập sẽ mở login
                        dispatch(toggleModalLogin(true));
                      } else {
                        if (
                          slug !== "/stock-order" &&
                          slug !== "/stock-order-der"
                        ) {
                          dispatch(setVisibleIconExtra(true));
                        } else {
                          dispatch(setIsOpenExtra(false));
                          dispatch(setVisibleIconExtra(false));
                          dispatch(setVisibleContainerExtra(false));
                        }
                      }

                      if (itemChild.value) return;
                    }}
                  >
                    <ItemSelect className="item-select">
                      {intl.formatMessage({ id: itemChild.label })}
                    </ItemSelect>
                  </Link>
                ))}
              </MenuItemCardChild>
            )}
          </Link>
        ))}
      </MenuItemCard>
    </NavItemMenus>
  );
});

export default NavItemMenu;
