import { FC, useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { TopForeignState } from "modules/market/redux/topForeign";
import numeral from "numeral";
import BlinkableCell from "./BlinkableCell";
import { CellWrapper } from "./styles";
import { marketTopForeignSelector } from "modules/market/redux/selectors";

const textColorSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector.data?.[tickerName]?.colorcode
  );
};

const priceSelector = (tickerName: string) => {
  return createSelector(
    marketTopForeignSelector,
    (topForeignSelector: TopForeignState) =>
      topForeignSelector.data?.[tickerName]?.closeprice
  );
};

interface Props {
  ticker: string;
}

const PriceCell: FC<Props> = ({ ticker }) => {
  const textColor = useSelector(textColorSelector(ticker));
  const price = useSelector(priceSelector(ticker));

  const formatterValue = useMemo(() => {
    if (typeof price === "number") {
      return numeral(price).format("0,000.00");
    }

    return "-";
  }, [price]);

  return (
    <CellWrapper
      className="market-price-cell is-bold center-for-value"
      textcolor={textColor}
    >
      <BlinkableCell value={formatterValue} />
    </CellWrapper>
  );
};

export { PriceCell };
