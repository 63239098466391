import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

import { ListKeyShortcuts, ScreenType, ThemeType } from "./types";

export interface State {
  type: ThemeType;
  screen: ScreenType;
  listHotKeys: ListKeyShortcuts;
}
const initialState: State = {
  type: ThemeType.BLACK,
  screen: ScreenType.LG,
  listHotKeys: {
    keyShortcutCategory: "F1",
    keyShortcutMarket: "F2",
    keyShortcutStockOrder: "F3",
    keyShortcutStockOrderDer: "F4",
    keyShortcutStockOrderBuy: "ctrl+b",
    keyShortcutStockOrderSell: "ctrl+s",
    visiblePopupConfirm: true,
  },
};

const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<ThemeType>) => {
      state.type = action.payload;
    },
    changeScreen: (state, action: PayloadAction<ScreenType>) => {
      state.screen = action.payload;
    },
    setListKeyShort: (state, action: PayloadAction<ListKeyShortcuts>) => {
      state.listHotKeys = { ...state.listHotKeys, ...action.payload };
    },
  },
});

export const themeSelector = (state: RootState) => state.theme;
export const themeTypeSelector = createSelector(
  themeSelector,
  (state) => state.type
);
export const listHotKeysSelector = createSelector(
  themeSelector,
  (state) => state.listHotKeys
);
export const { changeTheme, changeScreen, setListKeyShort } =
  themeSlice.actions;
export default themeSlice.reducer;
