import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";

import { ISearchDepositPowerParams } from "core/http/apis/deposit-power/types";
import {
  listDepositPowerSelector,
  statusLiquidateSelector,
  filterDepositPowerSelector,
} from "modules/deposit-power/selectors";
import {
  searchDepositPowerRequest,
  liquidateDepositPowerRequest,
} from "modules/deposit-power/redux";
import { DepositPower } from "domain/models/DepositPower";

import storage from "helper/storage";
import moment from "moment";
import { memo, useMemo, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ButtonLiquidate,
} from "./styles";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
// import { MarginDebitContractWatch } from "domain/models/ContractDebit";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listDepositPowerSelector);
  const statusLiquidate = useSelector(statusLiquidateSelector);
  const { contractSts, fromDate, toDate } = useSelector(
    filterDepositPowerSelector
  );

  useEffect(() => {
    if (statusLiquidate) {
      const submitData: ISearchDepositPowerParams = {
        branchCd: "",
        transactionCd: "",
        custNo: storage.getMasterAccount() ?? "",
        fromDate: fromDate ? Number(moment(fromDate).format("YYYYMMDD")) : 0,
        toDate: toDate ? Number(moment(toDate).format("YYYYMMDD")) : 0,
        contractSts: contractSts ? contractSts : 1,
        status: "",
        srcChannel: "",
      };
      dispatch(searchDepositPowerRequest(submitData));
    }
  }, [statusLiquidate]);

  const center = "center";
  const flexStart = "flex-start";
  const themeType = useSelector(themeTypeSelector);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [itemLiquidateSelected, setItemLiquidateSelected] = useState<
    DepositPower | undefined
  >(undefined);

  const clickLiquidateHandler = (item: DepositPower) => {
    setItemLiquidateSelected(item);
    setVisibleModalConfirm(true);
  };
  const setConfirm = () => {
    if (itemLiquidateSelected) {
      dispatch(
        liquidateDepositPowerRequest({
          action: "1",
          dataList: JSON.stringify([
            {
              custCd: itemLiquidateSelected.custCd ?? 0,
              custNo: itemLiquidateSelected.custNo ?? "",
              id: itemLiquidateSelected.id ?? 0,
              contractNo: itemLiquidateSelected.contractNo ?? "",
              tradeDate: itemLiquidateSelected.tradeDate ?? 0,
              brokerCd: itemLiquidateSelected.brokerCd ?? "",
              remarks: itemLiquidateSelected.remarks ?? "",
              status: itemLiquidateSelected.status ?? 0,
              srcChannel: itemLiquidateSelected.srcChannel ?? "",
              timeStamp: itemLiquidateSelected.timeStamp ?? "",
              select: true,
            },
          ]),
        })
      );
    }
  };

  const convertNumberToDateFormatDDMMYYHHMMSS = ({
    date,
    inputFormat = "YYYY-MM-DD HH:mm:ss",
    outputFormat = "DD/MM/YYYY HH:mm:ss",
  }: {
    date?: number;
    inputFormat?: string;
    outputFormat?: string;
  }) => {
    if (!date) return "";

    return moment(new Date(date), inputFormat).format(outputFormat);
  };
  function changeColor(value: number) {
    if (value === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="createContract.status.available" />
        </TableCellContent>
      );
    else if (value === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="createContract.status.liquidated" />
        </TableCellContent>
      );

    return "";
  }
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositPower.table.openDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="depositPower.table.contrNo" />
          </HeaderCell>
        ),
        accessor: "contractNo",
        minWidth: 180,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositPower.table.expiredDate" />
          </HeaderCell>
        ),
        accessor: "closeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositPower.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,

        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <TableCellContent align={center}>
              {changeColor(currentRow.contractSts)}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="depositPower.table.remarks" />
          </HeaderCell>
        ),
        accessor: "remarks",
        minWidth: 180,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositPower.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "regDateTime",
        minWidth: 135,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormatDDMMYYHHMMSS({
                date: props.value,
              })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              {currentRow.contractSts === 1 && (
                <ButtonLiquidate
                  className="liquidate"
                  color={themes[themeType].grey0}
                  onClick={() => clickLiquidateHandler(currentRow)}
                >
                  <FormattedMessage id="depositPower.table.liquidation" />
                </ButtonLiquidate>
              )}
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="depositPowerTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"depositPowerTable"}
        isHasFooter={false}
      />

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "depositPower.message.liquidateContent",
        })}
      />
    </Container>
  );
}

export default memo(RequestTable);
