import { GroupTypeBase, StylesConfig } from "react-select";
import styled from "styled-components";
import themes from "themes/abstracts/_themes";
import { ThemeType } from "themes/types";
import { OptionSelectType } from "./index";

export const selectStyle = (
  type: ThemeType,
  mediumSelect: boolean,
  isHeight28?: boolean
) => {
  const colourStyles: StylesConfig<
    OptionSelectType,
    boolean,
    GroupTypeBase<OptionSelectType>
  > = {
    control: (styles, { menuIsOpen }) => ({
      ...styles,
      borderColor: menuIsOpen
        ? themes[type]["brandPrimary"]
        : themes[type]["grey80"],
      backgroundColor: themes[type]["grey100"],
      color: themes[type]["textcolorWhite"],
      height: isHeight28
        ? themes[type].select.height28
        : themes[type].select.height,
      fontSize: themes[type].typeFaces.body.size,
      display: "flex",
      alignItems: "center",
      borderRadius: themes[type].select.borderRadius,
      minHeight: "unset",
      outLine: "none",
      boxShadow: "none",
      ":hover": {
        borderColor: themes[type]["brandPrimary"],
      },
    }),
    input: (styles) => ({
      ...styles,
      color: themes[type]["textcolorWhite"],
      fontSize: themes[type].typeFaces.body.size,
    }),
    valueContainer: (styles) => ({
      ...styles,
      color: themes[type]["textcolorWhite"],
      height: themes[type].select.valueContent.height,
      padding: mediumSelect ? "0 8px" : "2px 8px",
      fontSize: themes[type].typeFaces.body.size,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 5,
      backgroundColor: themes[type]["background2"],
    }),
    singleValue: (styles) => ({
      ...styles,
      color: themes[type]["textcolorWhite"],
      fontSize: themes[type].typeFaces.body.size,
      lineHeight: "20px",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: isDisabled
          ? themes[type]["background2"]
          : themes[type]["background2"],
        color: isDisabled
          ? themes[type]["textcolorBlack"]
          : isFocused
          ? themes[type]["brandPrimary"]
          : themes[type]["textcolorWhite"],
        borderBottomColor: themes[type]["grey100"],
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        ":last-child": {
          borderBottomWidth: 0,
        },
        ":hover": {
          color: themes[type]["brandPrimary"],
        },
      };
    },
  };
  return colourStyles;
};

export const SelectBox = styled.div`
  flex: 1 1 auto;
  * {
    font-size: ${({ theme }) => theme.typeFaces.body.size} !important;
  }
`;
