import { ForeignTab } from "./types";
import { FormattedMessage } from "react-intl";
import { memo } from "react";
import { TabsContainer } from "components/widgets/generals/MarketWatch/styles";
import ForeignButton from "./ForeignButton";

export interface TabsProps {
  activeTab: ForeignTab;
  onChangeTabMarketWatch: (tab: ForeignTab) => void;
}

const foreignTabs: ForeignTab[] = ["topStocks", "topIndustry"];

function Tabs({ activeTab, onChangeTabMarketWatch }: TabsProps) {
  return (
    <TabsContainer>
      <div className="tabs">
        {foreignTabs.map((tab: ForeignTab) => {
          return (
            <div key={tab}>
              <ForeignButton
                active={activeTab === (tab as ForeignTab)}
                value={tab}
                onClick={onChangeTabMarketWatch}
                tabName={tab}
              >
                <FormattedMessage id={`foreignWatch.${tab}`} />
              </ForeignButton>
            </div>
          );
        })}
      </div>
    </TabsContainer>
  );
}

export default memo(Tabs);
