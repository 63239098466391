import classNames from "classnames";
import { Row, OuterRowContainer } from "./styles";
import TickerCell from "./TickerCell/Index";
import { RowStockProps } from "modules/categories/types";
import get from "lodash/get";

import CurrentroomCell from "./CurrentroomCell";
import SellforeignqtyCell from "./SellforeignqtyCell";
import BuyforeignqtyCell from "./BuyforeignqtyCell";
import AvgpriceCell from "./AvgpriceCell";
import LowestpriceCell from "./LowestpriceCell";
import HighestpriceCell from "./HighestpriceCell";
import TotalamtCell from "./TotalamtCell";
import TotalqtyCell from "./TotalqtyCell";
import LastqtyCell from "./LastqtyCell";
import ChangepercentCell from "./ChangepercentCell";
import ChangepointCell from "./ChangepointCell";
import LastpriceCell from "./LastpriceCell";
import BasicpriceCell from "./BasicpriceCell";
import FloorpriceCell from "./FloorpriceCell";
import CeilingpriceCell from "./CeilingpriceCell";
import ChartCell from "./ChartCell";
import BestqtyCell from "./BestqtyCell";
import BestpriceCell from "./BestpriceCell";
import { AskStringName, BidStringName } from ".";

function RowStock({
  rowNumber,
  ticker,
  style,
  sumWidth,
  askWidth,
  bidWidth,
  foreignWidth,
  priceWidth,
  lastSaleWidth,
  coverWarrantWidth,
  coverWarrantHeadWidth,
  currentListCode,
  columns,
  isFocus,
}: RowStockProps) {
  return (
    <OuterRowContainer>
      <Row
        isOddRowCount={rowNumber % 2 === 1}
        sumWidth={sumWidth}
        askWidth={askWidth}
        bidWidth={bidWidth}
        foreignWidth={foreignWidth}
        priceWidth={priceWidth}
        lastSaleWidth={lastSaleWidth}
        coverWarrantWidth={coverWarrantWidth}
        coverWarrantHeadWidth={coverWarrantHeadWidth}
        columns={columns}
        isFocus={isFocus}
      >
        <div
          className={classNames(
            { "remove-box-shadow": columns.CHART.visible },
            "ticker-name-col text-left ticker-div"
          )}
        >
          <TickerCell ticker={ticker} />
        </div>
        {columns.CHART.visible && (
          <div className="chart">
            <div className="inner-container">
              <ChartCell ticker={ticker} />
            </div>
          </div>
        )}
        <CeilingpriceCell ticker={ticker} />
        <FloorpriceCell ticker={ticker} />
        <BasicpriceCell ticker={ticker} />
        <div className="bid-col flex-content">
          {Array.from({ length: 3 }).map((_, index) => (
            <>
              {columns.BID.children![`PRICE${3 - index}`].visible && (
                <BestpriceCell
                  ticker={ticker}
                  key={`${index}-bid-price-${ticker}`}
                  className={`price${3 - index}`}
                  priceName={BidStringName[2 - index]?.price}
                />
              )}
              {columns.BID.children![`VOLUME${3 - index}`].visible && (
                <BestqtyCell
                  ticker={ticker}
                  key={`${index}-bid-volume-${ticker}`}
                  className={`volume${3 - index}`}
                  priceName={BidStringName[2 - index]?.price}
                  volumeName={BidStringName[2 - index]?.volume}
                />
              )}
            </>
          ))}
        </div>
        <div className="last-sale-col flex-content">
          <LastpriceCell ticker={ticker} />
          {get(columns, "LAST_SALE.children.CHANGE_PRICE.visible", false) && (
            <ChangepointCell ticker={ticker} />
          )}
          {get(
            columns,
            "LAST_SALE.children.CHANGE_PERCENTAGE.visible",
            false
          ) && <ChangepercentCell ticker={ticker} />}
          <LastqtyCell ticker={ticker} />
        </div>
        <div className="ask-col flex-content">
          {Array.from({ length: 3 }).map((_, index) => (
            <>
              {columns.ASK.children![`PRICE${index + 1}`].visible && (
                <BestpriceCell
                  ticker={ticker}
                  key={`${index}-ask-price-${ticker}`}
                  className={`price${index + 1}`}
                  priceName={AskStringName[index]?.price}
                  isAsk
                />
              )}
              {columns.ASK.children![`VOLUME${index + 1}`].visible && (
                <BestqtyCell
                  ticker={ticker}
                  key={`${index}-ask-volume-${ticker}`}
                  className={`volume${index + 1}`}
                  volumeName={AskStringName[index]?.volume}
                  priceName={AskStringName[index]?.price}
                />
              )}
            </>
          ))}
        </div>
        <div className="total-volume-col">
          {get(columns, "VOLUME.visible", false) && (
            <TotalqtyCell ticker={ticker} />
          )}
          {get(columns, "AMT.visible", false) && (
            <TotalamtCell ticker={ticker} />
          )}
        </div>
        <div className="price-col flex-content">
          <HighestpriceCell ticker={ticker} />
          <LowestpriceCell ticker={ticker} />
          <AvgpriceCell ticker={ticker} />
        </div>
        <div className="foreign-col flex-content">
          {columns.FOREIGN.children!.BID.visible && (
            <BuyforeignqtyCell ticker={ticker} />
          )}
          {columns.FOREIGN.children!.ASK.visible && (
            <SellforeignqtyCell ticker={ticker} />
          )}
          {columns.FOREIGN.children!.CURRENTROOM?.visible && (
            <CurrentroomCell ticker={ticker} />
          )}
        </div>
      </Row>
    </OuterRowContainer>
  );
}

export default RowStock;
