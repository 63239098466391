import queryString from "query-string";
import {
  IFindOrderByFilterParams,
  IFindRequestCondParams,
  IFindOrderDetailParams,
  IFindOrderByRequestCondParams,
  IFindDertOrderByFilterParams,
  IFindDertRequestCondParams,
  IFindOrderDetailDerParams,
  IFindDertOrderCondParams,
  IFindDerOrderDetailParams,
} from "./types";

export const getOrderHistory = (masterAccount: string) =>
  `/accounts/orders/${masterAccount}`;
export const getDealOfOrder = (masterAccount: string) =>
  `/accounts/orders/deals/${masterAccount}`;
export const getConditionHistory = () => `/order/care/requestCond/his`;
export const getConditionDetail = () => `/order/care/orderCondMatch`;

export const findOrderByFilterApi = (params: IFindOrderByFilterParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrderByFilter${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findDerOrderByFilterApi = (
  params: IFindDertOrderByFilterParams
) => {
  const paramsFormatter = queryString.stringify(params, {});
  return `/findDertOrderByFilter${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const findRequestCondApi = (params: IFindRequestCondParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findRequestCondHistApp${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const findDertRequestCondApi = (params: IFindDertRequestCondParams) => {
  const paramsFormatter = queryString.stringify(params, {});
  return `/findDertRequestCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const findOrderDetailApi = (params: IFindOrderDetailParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrderDetail${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findOrderDetailDerApi = (params: IFindOrderDetailDerParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrderDetail${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findDerOrderDetailApi = (params: IFindDerOrderDetailParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertOrderDetail${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const findOrderByRequestCondApi = (
  params: IFindOrderByRequestCondParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrderRequestCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findDertOrderCondApi = (params: IFindDertOrderCondParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertOrderCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
