import { Tab, WrapNavigation } from "./styles";
import { useIntl } from "react-intl";
import { ASSETS_INCURRED_TAB } from "modules/assetsIncurred/constants";
// import { selectTabIncurred } from "modules/assetsIncurred/selectors";
import { setTabIncurred } from "modules/assetsIncurred/redux";
import { useDispatch } from "react-redux";

const NavigationBottom = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  // const activeTab = useSelector(selectTabIncurred);

  return (
    <WrapNavigation>
      <div className="header-left">
        <div>
          <Tab
            isActive={false}
            onClick={() => dispatch(setTabIncurred(ASSETS_INCURRED_TAB.OPEN))}
          >
            {intl.formatMessage({
              id: "assets.assetsIncurred.bottom.tab1.title",
            })}
          </Tab>
        </div>

        {/* <div>
          <Tab
            isActive={activeTab === ASSETS_INCURRED_TAB.CLOSE}
            onClick={() => dispatch(setTabIncurred(ASSETS_INCURRED_TAB.CLOSE))}
          >
            {intl.formatMessage({
              id: "assets.assetsIncurred.bottom.tab2.title",
            })}
          </Tab>
        </div> */}
      </div>
    </WrapNavigation>
  );
};

export default NavigationBottom;
