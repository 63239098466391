import {
  DerTradeTypeEnum,
  PlaceOrderDerForm,
  updatePlaceOrderDerForm,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  onPrice,
  onVolume,
  addWidgetName,
} from "modules/stock-order-der/redux/OrderBookDer";
import { Button } from "../../OrderBook/style";
import { handleUsingPriceSelected } from "modules/stock-order/redux/placeOrder";
import { getValueRound } from "helper/utils";
import {
  OrderBookDerDisplay,
  OrderBookDerRealTime,
} from "domain/models/OrderBookDer";
import { ConditionalCommandTypeEnum } from "../../PlaceOrderDer/ConditionalCommandType";
import { MasterAccount } from "domain/models/User";
import { createSelector } from "@reduxjs/toolkit";
import { orderBookDerRealTimeListSelector } from "modules/stock-order-der/selectors";
import { Dictionary } from "lodash";

const buttonEditSelector = (realTimeId: string) => {
  return createSelector(
    orderBookDerRealTimeListSelector,
    (state: Dictionary<OrderBookDerRealTime>) => state?.[realTimeId]?.isadmend
  );
};
interface Props {
  data: OrderBookDerDisplay;
  setDataRow: React.Dispatch<OrderBookDerDisplay>;
  setShowEdit: React.Dispatch<boolean>;
  realTimeId: string;
}
const OrderBookDerRowAction: React.FC<Props> = ({
  data,
  setDataRow,
  setShowEdit,
  realTimeId,
}) => {
  const dispatch = useDispatch();

  const onBuySellClick = (data: OrderBookDerDisplay) => {
    const account: MasterAccount = {
      custCd: data?.custCd!,
      custNo: data?.custNo!,
    };
    if (!account || !data || !data.secCd) {
      return;
    }
    dispatch(handleUsingPriceSelected(true));
    const formData: PlaceOrderDerForm = {
      currentTicker: data.secCd,
      type: data.tradeType === 2 ? DerTradeTypeEnum.L : DerTradeTypeEnum.S,
      price: data.isMarketOrder
        ? data.orderPriceDisp!
        : getValueRound(data.ordPrice!),
      volume: data.ordQty || 0,
      conditionalCommandType: {
        label: "",
        value: ConditionalCommandTypeEnum.Normal,
      },
      account: account,
      priceUp: 0,
      priceDown: 0,
      cutLostAmp: 0,
      grossAmp: 0,
      cutLostPrice: 0,
      limitOffset: 0,
      rangeStop: 0,
      updatePrice: false,
    };
    dispatch(updatePlaceOrderDerForm(formData));
    dispatch(addWidgetName("widgets.placeOrderDer.name"));
  };

  const showModalEdit = () => {
    setDataRow(data);
    setShowEdit(true);
    dispatch(onPrice(getValueRound(data?.pubPrice ? data?.pubPrice : 0)));
    dispatch(onVolume(data?.pubQty));
  };

  const value = useSelector(buttonEditSelector(realTimeId));
  const visibleButtonEdit = value;

  return (
    <div className="btn_action_hover">
      {visibleButtonEdit && (
        <Button side={`EDIT`} onClick={() => showModalEdit()}>
          <FormattedMessage id={`common.editUp`} />
        </Button>
      )}

      <Button
        side={data.tradeType === 2 ? "buy" : "sell"}
        onClick={() => onBuySellClick(data)}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <FormattedMessage
          id={data.tradeType === 2 ? "common.longPlus" : "common.shortPlus"}
        />
      </Button>
    </div>
  );
};
export default OrderBookDerRowAction;
