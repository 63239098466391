import styled from "styled-components/macro";

export const TextColor = styled.div<{ color: string; background: string }>`
  color: ${({ theme, color }) => theme[color]};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex: 1;
  overflow: hidden;
  padding-right: 8px;
  ${({ theme, background }) =>
    !!background && `background-color: ${theme[background]} !important`};
`;
