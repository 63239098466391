import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IIsRegisterPayAdvanceParams,
  IRegisterPayAdvanceAutoParams,
  ICancelPayAdvanceAutoParams,
} from "core/http/apis/advance-auto/types";
import {
  IIsRegisterPayAdvanceResponse,
  IRegisterPayAdvanceAutoResponse,
  ICancelPayAdvanceAutoResponse,
} from "domain/models/AdvanceAuto";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  advanceAuto: number;

  isShowConfirmModal: boolean;
  isShowOtpModal: boolean;

  statusRegister: boolean;
}

const initialState: ReduxData = {
  advanceAuto: 0,

  isShowConfirmModal: false,
  isShowOtpModal: false,

  statusRegister: false,
};

const advanceAutoSlice = createSlice({
  name: "advanceAutoSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    isRegisterPayAdvanceAutoRequest: (
      state,
      action: PayloadAction<IIsRegisterPayAdvanceParams>
    ) => {},
    isRegisterPayAdvanceAutoSuccess: (
      state,
      action: PayloadAction<IIsRegisterPayAdvanceResponse>
    ) => {
      state.advanceAuto = action.payload.data ?? 0;
    },

    isRegisterPayAdvanceAutoFailure: (state) => {},

    registerPayAdvanceAutoRequest: (
      state,

      action: PayloadAction<IRegisterPayAdvanceAutoParams>
    ) => {
      state.statusRegister = false;
    },
    registerPayAdvanceAutoSuccess: (
      state,
      action: PayloadAction<IRegisterPayAdvanceAutoResponse>
    ) => {
      state.statusRegister = true;
    },
    registerPayAdvanceAutoFailure: (state) => {
      state.statusRegister = false;
    },
    cancelPayAdvanceAutoRequest: (
      state,

      action: PayloadAction<ICancelPayAdvanceAutoParams>
    ) => {
      state.statusRegister = false;
    },
    cancelPayAdvanceAutoSuccess: (
      state,
      action: PayloadAction<ICancelPayAdvanceAutoResponse>
    ) => {
      state.statusRegister = true;
    },
    cancelPayAdvanceAutoFailure: (state) => {
      state.statusRegister = false;
    },
    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
      }>
    ) => {},
    updateModalStatusSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowConfirmModal = action.payload;
    },

    updateModalOtpRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
      }>
    ) => {},
    updateModalOtpSuccess: (state, action: PayloadAction<boolean>) => {
      state.isShowOtpModal = action.payload;
    },
  },
});

export const {
  isRegisterPayAdvanceAutoRequest,
  isRegisterPayAdvanceAutoSuccess,
  isRegisterPayAdvanceAutoFailure,
  registerPayAdvanceAutoRequest,
  registerPayAdvanceAutoSuccess,
  registerPayAdvanceAutoFailure,
  cancelPayAdvanceAutoRequest,
  cancelPayAdvanceAutoSuccess,
  cancelPayAdvanceAutoFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  updateModalOtpRequest,
  updateModalOtpSuccess,
} = advanceAutoSlice.actions;

export default advanceAutoSlice.reducer;
