import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, all, call } from "redux-saga/effects";
import { fetchWatchList, fetchWatchListSuccess } from "../redux/watchList";
import { getStreamPriceInfoFulls } from "core/grpc";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";

function* fetchWatchListWorker(
  action: PayloadAction<{ fullTickersList: string[] }>
) {
  try {
    const { fullTickersList } = action.payload;
    if (fullTickersList && fullTickersList.length) {
      const response: SymbolTotalInfo.AsObject[] = yield call(
        getStreamPriceInfoFulls,
        fullTickersList.toString()
      );
      yield put(fetchWatchListSuccess(response));
    }
  } catch (e) {}
}

function* fetchWatchListWatcher() {
  yield takeLatest(fetchWatchList.type, fetchWatchListWorker);
}

export default function* WatchListWidget() {
  yield all([fetchWatchListWatcher()]);
}
