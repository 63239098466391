import { Select, Button, DatePicker, InputSymbol } from "components/commons";
import { memo, useMemo } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { searchRightInfoRequest, updateFilter } from "modules/right-info/redux";
import {
  loadingSearchDataSelector,
  filterRightInfoSelector,
} from "modules/right-info/selectors";
import { IGetRightInfoParams } from "core/http/apis/right-info/types";
import moment from "moment";

// const statusOption = (intl: IntlShape) => [
//   {
//     value: "-1",
//     label: intl.formatMessage({ id: "rightInfo.filter.status.all" }),
//   },
//   {
//     value: "0",
//     label: intl.formatMessage({
//       id: "rightInfo.filter.status.pending",
//     }),
//   },
//   {
//     value: "2",
//     label: intl.formatMessage({ id: "rightInfo.filter.status.approved" }),
//   },
//   {
//     value: "9",
//     label: intl.formatMessage({
//       id: "rightInfo.filter.status.deleted",
//     }),
//   },
// ];

const rightTypeOption = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.cashDevidend",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.stockDividend",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.stockBonus",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.rightRegister",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.bondConvertion",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.stockMerge",
    }),
  },
  {
    value: "7",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.stockSplit",
    }),
  },
  {
    value: "8",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.delisting",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.changeSecuritiesType",
    }),
  },
  {
    value: "10",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.depositoryRegistration",
    }),
  },
  {
    value: "11",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.votes",
    }),
  },
  {
    value: "12",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.returnCapital",
    }),
  },
  {
    value: "13",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.bondExpires",
    }),
  },
  {
    value: "14",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.buyCapital",
    }),
  },
  {
    value: "15",
    label: intl.formatMessage({
      id: "rightInfo.filter.rightType.buyBondConvertion",
    }),
  },
];

function Filter() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    secCd,
    rightType,
    ownerFixDate,
    rightExcDate,
    beginTradeDate,
    status,
  } = useSelector(filterRightInfoSelector);

  const loading = useSelector(loadingSearchDataSelector);

  // const statusOptions = useMemo(() => statusOption(intl), [intl]);
  const rightTypeOptions = useMemo(() => rightTypeOption(intl), [intl]);

  // useEffect(() => {
  //   if (accNo !== "") {
  //     handleSearch();
  //   }
  // }, [accNo, broker, status, contractState]);

  const handleSearch = () => {
    const submitData: IGetRightInfoParams = {
      secCd: secCd,
      rightType: rightType,
      ownerFixDate: Number(moment(ownerFixDate).format("YYYYMMDD")),
      rightExcDate: Number(moment(rightExcDate).format("YYYYMMDD")),
      beginTradeDate: Number(moment(beginTradeDate).format("YYYYMMDD")),
      status: status,
    };
    dispatch(searchRightInfoRequest(submitData));
  };

  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "rightInfo.filter.secCd",
          })}
          className="mr-4"
        >
          <InputSymbol />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "rightInfo.filter.rightType.title",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            options={rightTypeOptions}
            defaultValue={rightTypeOptions[0]}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "rightType",
                  value: e?.value ? e.value : "",
                })
              );
            }}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "rightInfo.filter.ownerFixDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={ownerFixDate}
            onChange={(e: any) =>
              dispatch(
                updateFilter({
                  key: "ownerFixDate",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "rightInfo.filter.rightExcDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={rightExcDate}
            onChange={(e) =>
              dispatch(
                updateFilter({
                  key: "rightExcDate",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "rightInfo.filter.beginTradeDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={beginTradeDate}
            onChange={(e) =>
              dispatch(
                updateFilter({
                  key: "beginTradeDate",
                  value: e,
                })
              )
            }
            popperPlacement="bottom-start"
          />
        </FilterItem>

        {/* <FilterItem
          filterName={intl.formatMessage({
            id: "rightInfo.filter.status.title",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            options={statusOptions}
            defaultValue={statusOptions[0]}
            onChange={(e) => {
              dispatch(
                updateFilter({
                  key: "status",
                  value: e?.value ? e.value : "",
                })
              );
            }}
          />
        </FilterItem> */}

        <Button
          color="accept"
          className="button-filter"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "rightInfo.filter.search" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
