import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const MarketWatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(40, 54, 72, 0.8);
    max-width: 276px;
    border-radius: 4px;
    padding: 8px 12px;
    text-align: left;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .arrow {
    transform: translate(20px, 0px) !important;
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export const TabsContainer = styled.div`
  padding: 12px 16px 4px;
  .tabs {
    display: flex;
    flex-wrap: wrap;
  }
  .description {
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    padding: 10px 0 0 4px;
  }
`;

export const ContentContainer = styled.div`
  background-image: ${({ theme }) => theme.linearGradientBlue};
  flex: 1 1 0;
`;

export const ClickableBox = styled.div<{
  active: boolean;
  size?: "small" | "medium";
}>`
  cursor: pointer;
  color: ${({ theme, active }) => (active ? theme.brandPrimary : theme.grey20)};
  ${({ active, theme }) => active && `border: 1px solid ${theme.brandPrimary};`}
  background: ${({ active, theme }) => (active ? theme.grey80 : theme.grey70)};
  box-sizing: border-box;
  border-radius: 4px;
  height: ${({ theme }) => theme.headerButton.height};
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.header.weight.regular};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ size, theme }) =>
    size === "small" &&
    `font-size: ${theme.typeFaces.caption12.size};
    padding: 4px;
    height: 24px;
    line-height: ${theme.typeFaces.caption12.lineHeight};
    color: ${theme.textcolorWhite};`}
`;
