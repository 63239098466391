import MainLayout from "components/layouts/Main";
import Market from "./pages/Market";
import { RouterConfig } from "router/AppRoute";
import paths from "helper/pathRoutes";

const routes: RouterConfig[] = [
  {
    page: Market,
    path: paths.market,
    exact: true,
    layout: MainLayout,
    checkLogin: false,
  },
];

export default routes;
