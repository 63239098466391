import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const bidAskBondOrderSelector = (state: RootState) =>
  state.bidAskBondOrder;

export const activeTabSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.activeTab
);

export const listBondOrderSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.list
);
export const listBondSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.listBond
);
export const listOrderBondSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.listBondOrder
);
export const listCbotBondPriceEstSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.listCbotBondPriceEst
);

export const loadingSearchDataSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.loading
);
export const activeButtonSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.activeButton
);

export const filterBondOrderSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.filter
);

export const bidAskBondOrderEditSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.bidAskBondOrder
);

export const statusPlaceBondOrderSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.statusPlaceBondOrder
);
export const statusDeleteSelector = createSelector(
  bidAskBondOrderSelector,
  (state) => state.statusDelete
);
