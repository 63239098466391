import ContainerBottom from "./ContainerBottom";
import ContainerTop from "./ContainerTop";
import { Wrapper } from "./styles";

function InformationOrder() {
  return (
    <Wrapper>
      <ContainerTop />
      <ContainerBottom />
    </Wrapper>
  );
}

export default InformationOrder;
