import { BlinkTime } from "AppConfig";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
import {
  colorcodeTickerDerIndexSelector,
  valueHeaderCardDerSelector,
} from "modules/system/selectors";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TextColorDer } from "../styles";
interface Props {
  fieldValue: keyof SymbolTotalInfo.SecDetailInfo.AsObject;
  isPercentage?: boolean;
  className?: string;
}

function NumberChange({ fieldValue, isPercentage = false, className }: Props) {
  const oldData = useRef(0);
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const data = useSelector(valueHeaderCardDerSelector(fieldValue)) as number;
  const color = useSelector(colorcodeTickerDerIndexSelector);

  useEffect(() => {
    const _oldData = oldData.current;
    oldData.current = data;
    if (_oldData !== data) {
      setBackgroundColor("solidFlash");
    }
    const timeout = setTimeout(() => setBackgroundColor(""), BlinkTime);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [numeral(data).format("0.00")]);
  return (
    <TextColorDer
      className={className}
      backgroundColor={backgroundColor}
      textcolor={color}
    >
      {fieldValue === "changepoint" && "("}
      {numeral(data).format(isPercentage ? "+0.00" : "0.00")}
      {isPercentage && "%)"}
    </TextColorDer>
  );
}

export default NumberChange;
