import styled from "styled-components/macro";

export const Wrap: any = styled.div`
  padding: ${({ theme }) => theme.filterTable.padding};
  .button-filter {
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
  }
`;
