import { MarketCapDataNew } from "components/widgets/generals/Capitalization/types";
import { getTopIndustriesContribution } from "core/grpc";
import { TopIndustriesContributionItem } from "domain/protoNew/auto_trading_pb";
import { EMarketCodeNew } from "helper/consts";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { setCurrentMarketCode } from "../redux";
import { currentMarketCodeSelector } from "../redux/selectors";
import {
  fetchCapitalizationFailure,
  fetchCapitalizationSuccess,
  fetchTopIndexIndustry,
} from "../redux/topIndexIndustry";

function getDisplayDataForMarketCap(
  rpgcInput: TopIndustriesContributionItem.AsObject[]
): MarketCapDataNew[] {
  return rpgcInput.map((item) => {
    const displayItem: MarketCapDataNew = {
      marketCap: Math.abs(item.contributepercent || 0) as number | undefined,
      changedPercentage: item.contributepercent as number | undefined,
      changedValue: item.contributeval as number | undefined,
      color: item.contributepercent!! >= 0 ? 3 : 4,
      industryNameVi: item.industrynamevi ?? "",
      industryNameEn: item.industrynameen ?? "",
    };
    return displayItem;
  });
}

function* fetchTopIndexIndustryWorker() {
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  try {
    const res: TopIndustriesContributionItem.AsObject[] = yield call(
      getTopIndustriesContribution,
      currentMarketCode
    );

    const displayData = getDisplayDataForMarketCap(res);

    yield put(fetchCapitalizationSuccess(displayData));
  } catch (e: any) {
    yield put(fetchCapitalizationFailure(e));
  }
}

function* fetchTopIndexIndustryWatcher() {
  yield takeLatest(fetchTopIndexIndustry.type, fetchTopIndexIndustryWorker);
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchTopIndexIndustryWorker);
}

export default function* topIndexIndustryWidget() {
  yield all([fetchTopIndexIndustryWatcher(), setCurrentMarketCodeWatcher()]);
}
