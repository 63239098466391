import { FormattedMessage } from "react-intl";
import {
  Wrap,
  WrapItemLeft,
  WrapItemRight,
  BodyModalRow,
  TextCell,
  LabelCell,
  Title,
  LabelCellBig,
  LabelCellRight,
  BodyModalRowright,
} from "./styles";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { selectAssetsInformation } from "modules/assetsIncurred/selectors";
import numeral from "numeral";
import themes from "themes/abstracts/_themes";
import { RootState } from "redux/store";

const ContentTopAssets = () => {
  const item = useSelector(selectAssetsInformation);
  const { type: themeType } = useSelector((state: RootState) => state.theme);

  return (
    <>
      <Wrap>
        <WrapItemLeft>
          <BodyModalRow>
            <LabelCellBig>
              <FormattedMessage id="assets.assets.top.Money" />
            </LabelCellBig>
            <TextCell color="white">
              {numeral(
                item?.bankAvail
                  ? item?.bankAvail + item?.asNewPendingAmt
                  : item?.asNewCashAmt + item?.asNewPendingAmt
              ).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assets.top.bankAvail" />
            </LabelCell>
            <TextCell>
              {numeral(
                item?.bankAvail ? item?.bankAvail : item?.asNewCashAmt
              ).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assets.top.asNewPendingAmt" />
            </LabelCell>
            <TextCell>{numeral(item?.asNewPendingAmt).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assets.top.withdrawAvail" />
            </LabelCell>
            <TextCell>{numeral(item?.withdrawAvail).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCellBig>
              <FormattedMessage id="assets.assets.top.Stock" />
            </LabelCellBig>
            <TextCell color="white">
              {numeral(item?.asNewSecVal + item?.asNewRightVal).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assets.top.asNewSecVal" />
            </LabelCell>
            <TextCell>{numeral(item?.asNewSecVal).format("0,0")}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="assets.assets.top.asNewRightVal" />
            </LabelCell>
            <TextCell>{numeral(item?.asNewRightVal).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCellBig>
              <FormattedMessage id="assets.assets.top.AsNewDebitTotal" />
            </LabelCellBig>
            <TextCell color="white">
              {numeral(item?.asNewDebitTotal).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCellBig>
              <FormattedMessage id="assets.assets.top.AsNewAsset" />
            </LabelCellBig>
            <TextCell color="white">
              {numeral(item?.asNewAsset).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCellBig>
              <FormattedMessage id="assets.assets.top.AsNewNetAsset" />
            </LabelCellBig>
            <TextCell color="white">
              {numeral(item?.asNewNetAsset).format("0,0")}
            </TextCell>
          </BodyModalRow>
        </WrapItemLeft>

        <WrapItemRight>
          <Title>
            <FormattedMessage id="assets.assets.top.TitleDVTL" />
          </Title>
          <div className="w-100 p-2">
            <Row>
              <Col>
                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.limit4" />
                  </LabelCellRight>
                  <TextCell>{numeral(item?.limitL4).format("0,0")}</TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.limitL" />
                  </LabelCellRight>
                  <TextCell>{numeral(item?.limitL).format("0,0")}</TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.normalLimitAllocated" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.normalLimitAllocated).format("0,0")}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.todayBuyValue" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.todayBuyValue).format("0,0")}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.totalMissMoney" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.totalMissMoney).format("0,0")}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.needToLoand" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.needToLoand).format("0,0")}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.normalLimitNeedSupp" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.normalLimitNeedSupp).format("0,0")}
                  </TextCell>
                </BodyModalRowright>
              </Col>

              <Col>
                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.asNewCurrentRate" />
                  </LabelCellRight>
                  <TextCell>{item?.asNewCurrentRate}</TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.warningRate" />
                  </LabelCellRight>
                  <TextCell>{item?.warningRate}</TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.callRate" />
                  </LabelCellRight>
                  <TextCell>{item?.callRate}</TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.warningLevel.lablel" />
                  </LabelCellRight>
                  <TextCell
                    color={
                      item?.warningLevel === 0
                        ? themes[themeType].grey0
                        : item?.warningLevel === 1
                        ? themes[themeType].yellow50
                        : themes[themeType].solidRed
                    }
                  >
                    {item?.warningLevel && (
                      <FormattedMessage
                        id={`assets.assets.top.warningLevel.value${item?.warningLevel}`}
                      />
                    )}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.needToAdditionCash" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.needToAdditionCash).format("0,0")}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.needToAdditionSec" />
                  </LabelCellRight>
                  <TextCell>
                    {numeral(item?.needToAdditionSec).format("0,0")}
                  </TextCell>
                </BodyModalRowright>

                <BodyModalRowright>
                  <LabelCellRight>
                    <FormattedMessage id="assets.assets.top.needToSell" />
                  </LabelCellRight>
                  <TextCell>{numeral(item?.needToSell).format("0,0")}</TextCell>
                </BodyModalRowright>
              </Col>
            </Row>
          </div>
        </WrapItemRight>
      </Wrap>
    </>
  );
};

export default ContentTopAssets;
