/* eslint-disable react-hooks/exhaustive-deps */
import { FormattedMessage } from "react-intl";
import {
  Wrap,
  WrapItemLeft,
  WrapItemRight,
  Title,
  TabsContainer,
  ClickableBox,
} from "./styles";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { createGradientPlugin } from "helper/chartPlugins";
import { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import {
  getCortassetGetdeltaApi,
  getMrkpIndexGetDeltaApi,
  getPortfolioAssetApi,
} from "core/http/apis/assetsIncurred";
import {
  selectSubAcco,
  selectFilteAssetsEffect,
} from "modules/assetsIncurred/selectors";
import { useSelector } from "react-redux";
import moment from "moment";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import numeral from "numeral";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { selectUserInfo, tradeDateSelector } from "modules/auth/selectors";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const GradientBgPlugin: any = createGradientPlugin("rgba(8, 109, 202, 0.2)");

const ContentTopEffect = () => {
  const intl = useIntl();
  const subAcc = useSelector(selectSubAcco);
  const themeType = useSelector(themeTypeSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const { typeAccount, custNo, subAccoNo } = useSelector(
    selectFilteAssetsEffect
  );
  const userInfo = useSelector(selectUserInfo);
  const plugins = [GradientBgPlugin, ChartDataLabels];
  const d = new Date(moment(tradeDate, "YYYYMMDD").format("YYYY-MM-DD"));
  const [timeLeft, setTimeLeft] = useState<number>(1);
  const [timeRight, setTimeRight] = useState<number>(1);

  const [chartLeft, setChartLeft] = useState<{
    labels: any[];
    datasets: any[];
  }>({
    labels: [],
    datasets: [],
  });

  const [chartRight, setChartRight] = useState<{
    labels: any[];
    datasets: any[];
  }>({
    labels: [],
    datasets: [],
  });

  const result =
    userInfo && userInfo?.entrustList
      ? userInfo?.entrustList
          .reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.custNo === o.custNo)) {
              unique.push(o);
            }
            return unique;
          }, [])
          .map((e: any) => {
            if (e?.businessCd === 0 || e?.businessCd === 9) {
              return e;
            }
          })
      : [];

  const setChart = async () => {
    const ctx = document.getElementById("line-chart-demo");
    ReactDOM.render(
      <>
        <Line
          options={{
            legend: {
              display: true,
              position: "bottom",
              reverse: true,
              labels: {
                boxWidth: 10,
              },
            },
            responsive: true,
            elements: {
              point: {
                radius: 0,
              },
              line: {
                tension: 0,
                borderWidth: 1,
              },
            },
            plugins: {
              title: {
                display: true,
              },
              datalabels: {
                display: false,
              },
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data: any) => {
                  const stt = tooltipItem.index ?? -1;
                  let text = "";

                  if (tooltipItem.datasetIndex === 0) {
                    text =
                      typeof tooltipItem.index === "number" &&
                      data?.datasets?.[0]?.data?.[stt]
                        ? `${intl.formatMessage({
                            id: "assets.effective.top.chartLeft.tooltip.TSSL",
                          })} : ${
                            typeof tooltipItem.index === "number"
                              ? numeral(
                                  data?.datasets?.[0]?.data?.[stt]?.y
                                ).format("0,0.00") + "%"
                              : "-"
                          }`
                        : "";
                  } else if (tooltipItem.datasetIndex === 1) {
                    text =
                      typeof tooltipItem.index === "number" &&
                      data?.datasets?.[1]?.data?.[stt]
                        ? `${intl.formatMessage({
                            id: "assets.effective.top.chartLeft.tooltip.index",
                          })} : ${
                            typeof tooltipItem.index === "number"
                              ? numeral(
                                  data?.datasets?.[1]?.data?.[stt]?.y
                                ).format("0,0.00") + "%"
                              : "-"
                          }`
                        : "";
                  }

                  return text;
                },
                // afterLabel: (tooltipItem, data: any) => {
                //   const stt = tooltipItem.index ?? -1;
                //   const text =
                //     typeof tooltipItem.index === "number" &&
                //     data?.datasets?.[0]?.data?.[stt]
                //       ? `${intl.formatMessage({
                //           id: "assets.effective.top.chartLeft.tooltip.index",
                //         })} : ${
                //           typeof tooltipItem.index === "number"
                //             ? numeral(
                //                 data?.datasets?.[1]?.data?.[stt]?.y
                //               ).format("0,0.00") + "%"
                //             : "-"
                //         }`
                //       : "";
                //   return text;
                // },
              },
              mode: "x-axis",
              // intersect: false,
            },
          }}
          data={chartLeft}
          height={85}
          plugins={plugins}
        />
      </>,
      ctx
    );
  };

  const setChart2 = () => {
    const ctx = document.getElementById("line-chart-demo2");
    ReactDOM.render(
      <>
        <Line
          options={{
            legend: {
              display: false,
              position: "bottom",
              reverse: true,
              labels: {
                boxWidth: 10,
              },
            },
            responsive: true,
            elements: {
              line: {
                tension: 0,
                borderWidth: 1,
              },
              point: {
                radius: 0,
                hoverRadius: 2,
              },
            },
            plugins: {
              title: {
                display: true,
              },
              datalabels: {
                display: (context: any) => {
                  return context?.dataset?.data?.[context.dataIndex]?.y !== 0;
                },
                formatter: (value: any, context: any) => {
                  return value?.creditAmt > 0
                    ? "C"
                    : value?.creditAmt < 0
                    ? "W"
                    : "";
                },
                align: (context: any) => {
                  return context?.dataset?.data?.[context.dataIndex]
                    ?.creditAmt > 0
                    ? "top"
                    : "bottom";
                },
                color: (context: any) => {
                  return context?.dataset?.data?.[context.dataIndex]
                    ?.creditAmt > 0
                    ? "#1DBB75"
                    : "#F04B41";
                },
                borderColor: (context: any) => {
                  const value =
                    context?.dataset?.data?.[context.dataIndex]?.creditAmt;
                  return value > 0 ? "#1DBB75" : value === 0 ? "" : "#F04B41";
                },
                anchor: "center",
                opacity: 1,
                borderWidth: 1,
                borderRadius: 50,
                font: {
                  lineHeight: 1,
                },
                labels: {
                  title: {
                    font: {
                      weight: "bold",
                      size: 11,
                    },
                  },
                },
              },
            },

            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  return `${intl.formatMessage({
                    id: "assets.effective.top.chartRight.tooltip.NAV",
                  })} : ${
                    typeof tooltipItem.index === "number"
                      ? numeral(
                          chartRight?.datasets?.[0]?.data?.[tooltipItem.index]
                            ?.y
                        ).format("0,0")
                      : "-"
                  }`;
                },
                // afterLabel: (tooltipItem) => {
                //   return `${intl.formatMessage({
                //     id: "assets.effective.top.chartRight.tooltip.growth",
                //   })} ${
                //     typeof tooltipItem.index === "number"
                //       ? numeral(
                //           chartRight?.datasets?.[1]?.data?.[tooltipItem.index]
                //             ?.y
                //         ).format("0,0.00")
                //       : "-"
                //   }`;
                // },
              },
              mode: "x-axis",
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    callback: function (value) {
                      return numeral(value).format("0.0a");
                    },
                  },
                },
              ],
            },
          }}
          data={chartRight}
          height={87}
          plugins={plugins}
        />
      </>,
      ctx
    );
  };

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.All
  );
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  // const accountToSubacc = userInfo?.entrustList?.find(
  //   (e: any) =>
  //     e?.subAccoNo ===
  //     (subAccoNo !== "" ? subAccoNo : accountOptions?.[0].value)
  // );

  const getData = async () => {
    const fromDate = moment(d.setMonth(d.getMonth() - timeLeft)).format(
      "YYYYMMDD"
    );
    const params = {
      custNo:
        typeAccount !== "1" ? (custNo ? custNo : result?.[0]?.custNo) : null,
      subAccoNo:
        typeAccount === "1"
          ? subAccoNo !== ""
            ? subAccoNo
            : accountOptions?.[0]?.value
          : null,
      fromDate: fromDate,
      toDate: tradeDate,
    };
    const res: any = await getCortassetGetdeltaApi(params);
    const res2: any = await getMrkpIndexGetDeltaApi({
      secCd: "VN-IDX",
      fromDate: fromDate,
      toDate: tradeDate,
    });
    const resLogData = {
      eventGroup: GroupEvent.assets,
      event: RealtimeConst.getDataInvestEffects,
      message: JSON.stringify(params),
    };

    storage.commonPushLogFirebase(resLogData);
    if (res?.statusCode === 0 && res2?.statusCode === 0) {
      const data: any[] = res?.data ? res?.data : [];

      const labelvalue: any =
        data && !isEmpty(data)
          ? data
              .map((e: any) => Number(e?.[0]))
              .concat(
                res2?.data ? res2?.data.map((i: any) => Number(i?.[0])) : []
              )
              .sort((a: any, b: any) => Number(a) - Number(b))
          : [];

      const labels: any = !isEmpty(labelvalue)
        ? labelvalue
            .filter(
              (e: any, index: any, array: any) => array.indexOf(e) === index
            )
            .map((e: any) => moment(e, "YYYYMMDD").format("DD/MM/YYYY"))
        : [];
      const datasets = [
        {
          label: intl.formatMessage({
            id: "assets.effective.top.chartLeft.tooltip.TSSL",
          }),
          data: res?.data
            ? res?.data.map((e: any) => ({
                x: moment(e?.[0], "YYYYMMDD").format("DD/MM/YYYY"),
                y: e?.[1],
              }))
            : [],
          fill: false,
          borderColor: themes[themeType].solidYellow,
          backgroundColor: themes[themeType].solidYellow,
        },
        {
          label: intl.formatMessage({
            id: "assets.effective.top.chartLeft.tooltip.index",
          }),
          data: res2?.data
            ? res2?.data.map((i: any) => ({
                x: moment(i?.[0], "YYYYMMDD").format("DD/MM/YYYY"),
                y: i?.[2],
              }))
            : [],
          fill: false,
          borderColor: themes[themeType].solidBlue,
          backgroundColor: themes[themeType].solidBlue,
        },
      ];
      setChartLeft({ labels, datasets });
    }
  };

  const getDataPortfolioAsset = async () => {
    const fromDate = moment(d.setMonth(d.getMonth() - timeRight)).format(
      "YYYYMMDD"
    );
    const params = {
      subAccoNo: subAcc !== "" ? subAcc : accountOptions?.[0]?.value,
      fromDate: fromDate,
      toDate: tradeDate,
    };
    const res: any = await getPortfolioAssetApi(params);
    const resLogData = {
      eventGroup: GroupEvent.assets,
      event: RealtimeConst.getDataNetWorth,
      message: JSON.stringify(params),
    };

    storage.commonPushLogFirebase(resLogData);
    if (res?.statusCode === 0) {
      const labels = res?.data.map((e: any) =>
        moment(e?.businessDate, "YYYYMMDD").format("DD/MM/YYYY")
      );
      const datasets = [
        {
          label: "Dataset 1",
          data: res?.data.map((e: any) => ({
            x: moment(e?.businessDate, "YYYYMMDD").format("DD/MM/YYYY"),
            y: e?.totalAsset,
            creditAmt: e?.creditAmt,
          })),
          fill: false,
          borderColor: themes[themeType].solidGreen,
          backgroundColor: themes[themeType].solidGreen,
        },
      ];
      setChartRight({ labels, datasets });
    }
  };

  useEffect(() => {
    getData();
  }, [typeAccount, custNo, subAccoNo, timeLeft]);

  useEffect(() => {
    getDataPortfolioAsset();
  }, [typeAccount, custNo, subAccoNo, timeRight]);

  useEffect(() => {
    setChart();
  }, [chartLeft]);

  useEffect(() => {
    setChart2();
  }, [chartRight]);

  return (
    <>
      <Wrap>
        <WrapItemLeft>
          <Title>
            <FormattedMessage id="assets.effective.top.titleLeft" />
            <TabsContainer>
              <div className="tabs">
                {/* <OverlayTrigger
                  placement="bottom-start"
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeLeft === 1 ? true : false}
                  onClick={() => setTimeLeft(1)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn1m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeLeft === 2 ? true : false}
                  onClick={() => setTimeLeft(2)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn2m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeLeft === 3 ? true : false}
                  onClick={() => setTimeLeft(3)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn3m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeLeft === 6 ? true : false}
                  onClick={() => setTimeLeft(6)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn6m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeLeft === 12 ? true : false}
                  onClick={() => setTimeLeft(12)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn1y" />
                </ClickableBox>
                {/* </OverlayTrigger> */}
              </div>
            </TabsContainer>
          </Title>
          <div id="line-chart-demo" />
        </WrapItemLeft>

        <WrapItemRight>
          <Title>
            <FormattedMessage id="assets.assets.top.TitleDVTL" />
            <TabsContainer>
              <div className="tabs">
                {/* <OverlayTrigger
                  placement="bottom-start"
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeRight === 1 ? true : false}
                  onClick={() => setTimeRight(1)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn1m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeRight === 2 ? true : false}
                  onClick={() => setTimeRight(2)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn2m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeRight === 3 ? true : false}
                  onClick={() => setTimeRight(3)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn3m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeRight === 6 ? true : false}
                  onClick={() => setTimeRight(6)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn6m" />
                </ClickableBox>
                {/* </OverlayTrigger> */}

                {/* <OverlayTrigger
                  overlay={<StyledTooltip id="tooltip">???????</StyledTooltip>}
                > */}
                <ClickableBox
                  active={timeRight === 12 ? true : false}
                  onClick={() => setTimeRight(12)}
                >
                  <FormattedMessage id="assets.effective.top.button.btn1y" />
                </ClickableBox>
                {/* </OverlayTrigger> */}
              </div>
            </TabsContainer>
          </Title>
          <div id="line-chart-demo2"></div>
        </WrapItemRight>
      </Wrap>
    </>
  );
};

export default ContentTopEffect;
