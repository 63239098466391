import { FC } from "react";

import { FormattedMessage } from "react-intl";
import CreateContract from "./CreateConstract";
import Filter from "./Filters";
import { Container, ContentLeft, ContentRight, LabelCell } from "./styles";
import Table from "./Table";
const AddFrameworkContractForm: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <ContentLeft>
        <LabelCell>
          <FormattedMessage id="addFrameworkContractForm.createContract.title" />
        </LabelCell>
        <CreateContract />
      </ContentLeft>

      <ContentRight>
        <LabelCell>
          <FormattedMessage id="addFrameworkContractForm.listContract.title" />
        </LabelCell>
        <Filter />
        <Table />
      </ContentRight>
    </Container>
  );
};
export default AddFrameworkContractForm;
