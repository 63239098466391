import {
  Body,
  Container,
  Content,
  ContentParent,
  TitleContent,
  Label,
  ContentTable,
  ContentItem,
  TitleText,
} from "./styles";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  changeIsRegisterAction,
  changeRegisterOtpType,
  clearStatusRegisterOtpType,
  getListBeneficiary,
  isAlreadyRegisterAuthenFor134Request,
  updateOneTimeAuthen,
} from "modules/auth/redux/tradingAccount";
import AccountSelect from "./AccountSelect";
import OtpSelect from "./OtpSelect";
import TableTradingAccount from "./TableTradingAccount";
import ModalAddAccountBeneficiary from "./ModalAddAccountBeneficiary";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ModalConfirm,
  OTPByBusinessCd,
  Switch,
} from "components/commons";
import { setTabOtp } from "modules/system/redux/common";
import storage from "helper/storage";
import { EBusinessCd, EOTPType } from "helper/consts";
import {
  listingAccountBeneficiarySelector,
  loadingRegisterSelector,
  statusRegisterOtpTypeSelector,
  statusOneTimeAuthenSelector,
  isRegisterActionSelector,
  errorRegisterSelector,
  loadingOneTimeAuthenSelector,
  isAlreadyRegisterAuthenFor134Selector,
  authenTypeSelector,
} from "modules/auth/selectors";
import { toast } from "components/commons/Toast";
import ModalChooseTypeOtp from "./ModalChooseTypeOtp";
import { getMeStart, logOutStart, toggleModalLogin } from "modules/auth/redux";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { YesNoButton } from "components/commons/InputPrice/styles";
import {
  IIsAlreadyRegisterAuthenFor134Params,
  IRegisterOTPAuthenParams,
  IUpdateOneTimeAuthenParams,
} from "core/http/apis/twofactor/types";

function TradingAccountSettings() {
  const dispatch = useDispatch();

  const intl = useIntl();
  const [otpType, setOtpType] = useState(EOTPType.NONE);
  const [otpTypeTemporary, setOtpTypeTemporary] = useState(EOTPType.NONE);
  const [isOneTimeAuthen, setIsOneTimeAuthen] = useState(false);
  const [isOtpOneTime, setIsOtpOneTime] = useState(false);
  const [isVisibleModalChooseType, setIsVisibleModalChooseType] =
    useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const isRegisterAction = useSelector(isRegisterActionSelector);
  const listingAccountBeneficiary = useSelector(
    listingAccountBeneficiarySelector
  );
  const loadingRegister = useSelector(loadingRegisterSelector);
  const loadingOneTimeAuthen = useSelector(loadingOneTimeAuthenSelector);
  const statusRegisterOtpType = useSelector(statusRegisterOtpTypeSelector);
  const statusOneTimeAuthen = useSelector(statusOneTimeAuthenSelector);
  const errorRegister = useSelector(errorRegisterSelector);
  const isAlreadyRegisterAuthenFor134 = useSelector(
    isAlreadyRegisterAuthenFor134Selector
  );
  const authenType = useSelector(authenTypeSelector);

  useEffect(() => {
    if (authenType && authenType.oneTimeAuth === 0) {
      setIsOneTimeAuthen(false);
    }

    if (authenType && authenType.oneTimeAuth === 1) {
      setIsOneTimeAuthen(true);
    }
  }, [authenType]);

  useEffect(() => {
    if (isAlreadyRegisterAuthenFor134 === 0) {
      setOtpType(EOTPType.NONE);
    }
    if (isAlreadyRegisterAuthenFor134 === 1) {
      setOtpType(EOTPType.SMS_OTP);
    }
    if (isAlreadyRegisterAuthenFor134 === 2) {
      setOtpType(EOTPType.SMART_OTP);
    }
  }, [isAlreadyRegisterAuthenFor134]);

  useEffect(() => {
    dispatch(getMeStart());
    const isCheckOtpTypeParams: IIsAlreadyRegisterAuthenFor134Params = {
      custNo: storage.getMasterAccount(),
    };
    dispatch(isAlreadyRegisterAuthenFor134Request(isCheckOtpTypeParams));
    dispatch(getListBeneficiary());
    if (isRegisterAction) {
      setIsVisibleModalChooseType(true);
      dispatch(changeIsRegisterAction(false));
    }
  }, []);

  const handleSwitch = () => {
    setIsSwitch(true);
    setVisibleModalOTP(true);
  };

  const handleConfirm = (type: number) => {
    setOtpTypeTemporary(type);
    handleConfirmRegisterSMS();
  };

  const handleConfirmRegisterSMS = () => {
    setVisibleModalOTP(true);
  };

  const handleCloseOTPModal = () => {
    setVisibleModalOTP(false);
  };

  const doSubmitAdvance = (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    if (isSwitch) {
      setIsSwitch(false);
      const params: IUpdateOneTimeAuthenParams = {
        custNo: storage.getMasterAccount(),
        flag: isOneTimeAuthen ? 0 : 1,
        otpCode: response?.otp,
      };
      dispatch(updateOneTimeAuthen(params));
    } else {
      dispatch(setTabOtp(otpTypeTemporary));
      const params: IRegisterOTPAuthenParams = {
        actionMode: 3,
        custNo: storage.getMasterAccount(),
        authenType:
          otpTypeTemporary === 6
            ? EOTPType.SMART_OTP.toString()
            : EOTPType.SMS_OTP.toString(),
        oneTimeAuthen: false,
        checkOtp: false,
        smsOtp: response?.otp,
      };

      dispatch(changeRegisterOtpType({ params: params }));
    }
  };

  useEffect(() => {
    if (statusRegisterOtpType === "SUCCESSFUL") {
      toast("auth.tradingAccount.registerOtpTypeSuccess", "success");

      setOtpType(otpTypeTemporary);
      dispatch(clearStatusRegisterOtpType());
      const isCheckOtpTypeParams: IIsAlreadyRegisterAuthenFor134Params = {
        custNo: storage.getMasterAccount(),
      };
      dispatch(isAlreadyRegisterAuthenFor134Request(isCheckOtpTypeParams));
      dispatch(
        logOutStart({
          refresh_token: storage.getRefreshToken(),
          code_verifier: storage.getChallengeCodeVerifier(),
        })
      );
      dispatch(toggleModalLogin(true));
    }

    if (statusRegisterOtpType === "FAILURE") {
      if (!errorRegister?.code && !errorRegister?.description) {
        toast("auth.tradingAccount.registerOtpTypeFailure", "error");
      } else {
        toast(errorRegister?.code, "error", errorRegister?.description, true);
      }
      dispatch(clearStatusRegisterOtpType());
    }
  }, [statusRegisterOtpType, errorRegister]);

  useEffect(() => {
    if (statusOneTimeAuthen === "SUCCESSFUL") {
      if (isOtpOneTime) {
        toast("auth.tradingAccount.passStaticSuccess", "success");
      } else {
        toast("auth.tradingAccount.offPassStaticSuccess", "success");
      }
      setIsOneTimeAuthen(!isOneTimeAuthen);
      setIsOtpOneTime(!isOneTimeAuthen);
      dispatch(clearStatusRegisterOtpType());
      dispatch(getMeStart());
    }

    if (statusOneTimeAuthen === "FAILURE") {
      if (!errorRegister?.code && !errorRegister?.description) {
        if (isOtpOneTime) {
          toast("auth.tradingAccount.passStaticFailure", "error");
        } else {
          toast("auth.tradingAccount.offPassStaticFailure", "error");
        }
      } else {
        toast(errorRegister?.code, "error", errorRegister?.description, true);
      }
      dispatch(clearStatusRegisterOtpType());
    }
  }, [statusOneTimeAuthen, isOtpOneTime]);

  const handleVisibleModalChooseType = () => {
    setIsVisibleModalChooseType(true);
  };

  const generateButtonRegister = useMemo(() => {
    if (otpType === EOTPType.NONE) {
      return (
        <Button
          fill
          color="gradientaAccept"
          width="100%"
          className="flex-fill"
          onClick={() => handleVisibleModalChooseType()}
        >
          <FormattedMessage id="auth.tradingAccount.register" />
        </Button>
      );
    }

    if (otpType === EOTPType.SMART_OTP) {
      return (
        <Button
          fill
          color="gradientaAccept"
          width="100%"
          className="flex-fill"
          onClick={() => handleVisibleModalChooseType()}
          loading={loadingRegister}
          disabled={loadingRegister}
        >
          <FormattedMessage id="auth.tradingAccount.registerSMSOtp" />
        </Button>
      );
    }

    if (otpType === EOTPType.SMS_OTP) {
      return (
        <Button
          fill
          color="gradientaAccept"
          width="100%"
          className="flex-fill"
          onClick={() => handleVisibleModalChooseType()}
          loading={loadingRegister}
          disabled={loadingRegister}
        >
          <FormattedMessage id="auth.tradingAccount.registerSmartOtp" />
        </Button>
      );
    }
  }, [otpType]);

  return (
    <>
      <Container>
        <Body>
          <ContentParent>
            <TitleContent>
              <FormattedMessage id="auth.tradingAccount.defaultAccount" />
            </TitleContent>
            <Content>
              <ContentItem>
                <Label>
                  <FormattedMessage id="auth.tradingAccount.accountName" />
                </Label>
                <AccountSelect />
              </ContentItem>
              <ContentItem></ContentItem>
            </Content>
          </ContentParent>

          <ContentParent>
            <TitleContent>
              <FormattedMessage id="auth.tradingAccount.titleTypeOtp" />
            </TitleContent>
            <Content>
              <ContentItem>
                <Label>
                  <FormattedMessage id="auth.tradingAccount.typeOtp" />
                </Label>
                <OtpSelect otpType={otpType} />
              </ContentItem>
              <ContentItem className="justify-content-center">
                <Label></Label>
                <div className="flex-fill">{generateButtonRegister}</div>
              </ContentItem>
            </Content>
            <Content>
              <ContentItem>
                <Label>
                  <FormattedMessage id="auth.tradingAccount.loginByPassStatic" />
                </Label>
                <YesNoButton>
                  <Switch
                    checked={isOneTimeAuthen}
                    onChange={() => handleSwitch()}
                    disabled={loadingOneTimeAuthen}
                    loading={loadingOneTimeAuthen}
                  />
                </YesNoButton>
              </ContentItem>
              <ContentItem></ContentItem>
            </Content>
          </ContentParent>
          <ContentTable>
            <TitleContent>
              <TitleText>
                <FormattedMessage id="auth.tradingAccount.accountBeneficiary" />
              </TitleText>
              <ModalAddAccountBeneficiary />
            </TitleContent>
            <TableTradingAccount data={listingAccountBeneficiary?.data} />
          </ContentTable>
        </Body>
      </Container>

      {isVisibleModalChooseType && (
        <ModalChooseTypeOtp
          visibleModalConfirm={isVisibleModalChooseType}
          setVisibleModalConfirm={setIsVisibleModalChooseType}
          setConfirm={handleConfirm}
          otpType={otpType}
        />
      )}

      {visibleModalConfirm && (
        <ModalConfirm
          visibleModalConfirm={visibleModalConfirm}
          setVisibleModalConfirm={setVisibleModalConfirm}
          setConfirm={handleConfirmRegisterSMS}
          title={intl.formatMessage({ id: "auth.tradingAccount.messageTitle" })}
          content={intl.formatMessage({
            id: "auth.tradingAccount.messageContent",
          })}
        />
      )}

      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.REGISTER_AUTHEN}
        />
      )}
    </>
  );
}

export default TradingAccountSettings;
