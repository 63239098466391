import styled from "styled-components/macro";

export const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Wrap = styled.div`
  background: ${({ theme }) => theme.grey90};
  border-radius: 8px;
  height: 100%;
`;

export const WrapLabel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  font-size: 15px;
  // background: ${({ theme }) => theme.background3};
  height: ${({ theme }) => theme.cardHeader.height};
  // box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};

  // p {
  //   margin: 0;
  //   /* padding: 0; */
  //   font-size: ${({ theme }) => theme.typeFaces.headline.size};
  //   font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  //   line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  //   color: ${({ theme }) => theme.textcolorWhite};
  //   padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  // }
`;

export const WrapContent = styled.div`
  height: calc(100% - ${({ theme }) => theme.cardHeader.height});
  overflow: auto;
`;

export const WrapItem = styled.div`
  height: 310px;
  margin: 0px 8px 8px 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
`;

export const LabelItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  font-size: 15px;
  background: ${({ theme }) => theme.background3};
  height: ${({ theme }) => theme.cardHeader.height};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;

  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.grey0};

  // p {
  //   margin: 0;
  //   /* padding: 0; */
  //   font-size: ${({ theme }) => theme.typeFaces.headline.size};
  //   font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  //   line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  //   color: ${({ theme }) => theme.textcolorWhite};
  //   padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  // }
`;

export const WrapIcons = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContentItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${({ theme }) => theme.cardHeader.height});
`;

export const WrapIconMenu = styled.div`
  .dropdown-menu {
    background: ${({ theme }) => theme.grey80};
    padding: 0px;
    border-radius: 8px;
    .dropdown-header {
      text-align: center;
      background: ${({ theme }) => theme.background4};
      color: ${({ theme }) => theme.grey10};
      font-size: ${({ theme }) => theme.typeFaces.headline.size};
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .dropdown-item {
      color: ${({ theme }) => theme.grey10};
      font-size: 13px;
      padding: 10px 32px;
      box-shadow: ${({ theme }) => theme.shadowLineDown};
    }
    .dropdown-item:hover {
      background: ${({ theme }) => theme.grey60};
      color: ${({ theme }) => theme.grey0};
    }
    .dropdown-item:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;
