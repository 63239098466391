import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  requestGetFinanceInfo,
  getFinanceInfoSuccess,
  changeTimeType,
} from "modules/tickerDetails/redux/financeInfo";
import { getSectFinance } from "core/grpc";
import { SectFinanceData } from "domain/protoNew/auto_trading_pb";

function* fetchFinanceDataWorker(
  action: ReturnType<typeof requestGetFinanceInfo>
) {
  try {
    const response: SectFinanceData.AsObject = yield call(
      getSectFinance,
      action.payload.secCd,
      action.payload.duration
    );

    yield put(getFinanceInfoSuccess(response));
  } catch (e) {}
}

function* fetchFinanceDataWatcher() {
  yield takeLatest(requestGetFinanceInfo.type, fetchFinanceDataWorker);
}
function* fetchFinanceChangeTimeTypeWatcher() {
  yield takeLatest(changeTimeType.type, fetchFinanceDataWorker);
}

export default function* financeInfoWidget() {
  yield all([fetchFinanceDataWatcher(), fetchFinanceChangeTimeTypeWatcher()]);
}
