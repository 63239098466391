import { FC, useMemo } from "react";
import { Container } from "./styles";
import { dataColumns } from "./constants";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { useDispatch, useSelector } from "react-redux";
import { selectListSubscribe } from "modules/subscribeBuy/selectors";
import { updateDetail } from "modules/subscribeBuy/redux";
import { useIntl } from "react-intl";

const TableData: FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectListSubscribe);
  const fetchMoreData = () => {};
  const intl = useIntl();
  const columns = useMemo(() => dataColumns(intl), [intl]);

  return (
    <>
      <Container id="transferLocalTable">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={data}
          enableInfinity={true}
          scrollableTarget={"transferLocalTable"}
          isHasFooter={false}
          clickRowHandler={(row) =>
            dispatch(
              updateDetail({
                subAccoCd: row?.original?.subAccNo,
                subAccoNo: row?.original?.subAccNo,
                alloDate: row?.original?.alloDate,
                refNo: row?.original?.refNo,
                secCd: row?.original?.secCd,
                price: row?.original?.price,
              })
            )
          }
        />
      </Container>
    </>
  );
};

export default TableData;
