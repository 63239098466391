import { SERVICE } from "../constants";
import ContentEmail from "./ContentEmail";
import ContentSms from "./ContentSms";
import { Wrap } from "./styles";
type Props = {
  activeTab?: string;
};
export function Content(props: Props) {
  const { activeTab } = props;
  return (
    <Wrap>
      {activeTab === SERVICE.SMS && <ContentSms />}

      {activeTab === SERVICE.EMAIL && <ContentEmail />}

      {!activeTab && <ContentSms />}
    </Wrap>
  );
}
