import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import {
  changeActiveSort,
  changeForeignTypeSuccess,
  requestChangeTopForeignType,
  requestLoadMore,
  requestLoadMoreSuccess,
  requestTopForeign,
  requestTopForeignSuccess,
} from "../redux/topForeign";
import { TopForeignMenu } from "components/widgets/generals/TopForeign/types";
import {
  InternalMS,
  InternalMSInput,
  NullableString,
} from "domain/protoNew/auto_trading_pb";
import { AuthenCodeGrpc, TOP_PRICE_LOADING_LIMIT } from "AppConfig";
import { getTopForeignTrade } from "core/grpc";
import {
  activeSortTopForeignSelector,
  activeTabTopForeignSelector,
  marketTopForeignSelector,
  currentMarketCodeSelector,
} from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";
import { setCurrentMarketCode } from "../redux";

function getDataForRequestInfo(value: TopForeignMenu) {
  switch (value) {
    case "foreignNetValuesBuy":
      return 2;
    case "foreignNetValuesSell":
      return 1;
    case "foreignBuy":
      return 3;
    case "foreignSell":
      return 4;
    case "foreignBuyValue":
      return 5;
    case "foreignSellValue":
      return 6;
    default:
      return 0;
  }
}

function* fetchTickersByTopForeignTypeWorker() {
  const activeTab: TopForeignMenu = yield select(activeTabTopForeignSelector);
  const { sortBy, order } = yield select(activeSortTopForeignSelector);
  const currentMarketCode: EMarketCodeNew = yield select(
    currentMarketCodeSelector
  );
  const request = new InternalMSInput();
  const userId = new NullableString();
  userId.setData(uuidv4());
  request.setUserid(userId);
  request.setSide(getDataForRequestInfo(activeTab));
  request.setIndexcdstr(currentMarketCode.toString());
  request.setIndustryidstr("");
  request.setType(1);
  request.setOffset(0);
  request.setOrderfieldtype(sortBy);
  request.setOrdertype(order);
  request.setLimit(TOP_PRICE_LOADING_LIMIT);
  request.setAuthencode(AuthenCodeGrpc);

  try {
    const response: InternalMS.AsObject[] = yield call(
      getTopForeignTrade,
      request
    );

    yield put(changeForeignTypeSuccess(response));
  } catch (e) {}
}

function* fetchTickersByTopForeignLoadMoreWorker() {
  try {
    const { limit, activeTab, enableLoadMore } = yield select(
      marketTopForeignSelector
    );
    const { sortBy, order } = yield select(activeSortTopForeignSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    if (enableLoadMore === false) return;
    const request = new InternalMSInput();
    const userId = new NullableString();
    userId.setData(uuidv4());
    request.setUserid(userId);
    request.setSide(getDataForRequestInfo(activeTab));
    request.setIndexcdstr(currentMarketCode.toString());
    request.setIndustryidstr("");
    request.setType(1);
    request.setOffset(limit);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(TOP_PRICE_LOADING_LIMIT);
    request.setAuthencode(AuthenCodeGrpc);

    const response: InternalMS.AsObject[] = yield call(
      getTopForeignTrade,
      request
    );
    yield put(requestLoadMoreSuccess(response));
  } catch (e) {}
}

function* fetchTopForeignWorker() {
  try {
    const { limit, activeTab } = yield select(marketTopForeignSelector);
    const { sortBy, order } = yield select(activeSortTopForeignSelector);
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const request = new InternalMSInput();
    const userId = new NullableString();
    userId.setData(uuidv4());
    request.setUserid(userId);
    request.setSide(getDataForRequestInfo(activeTab));
    request.setIndexcdstr(currentMarketCode.toString());
    request.setIndustryidstr("");
    request.setType(1);
    request.setOffset(0);
    request.setOrderfieldtype(sortBy);
    request.setOrdertype(order);
    request.setLimit(limit);
    request.setAuthencode(AuthenCodeGrpc);

    const response: InternalMS.AsObject[] = yield call(
      getTopForeignTrade,
      request
    );
    yield put(requestTopForeignSuccess(response));
  } catch (e) {}
}

function* fetchTickersByTopForeignTypeWatcher() {
  yield takeLatest(
    requestChangeTopForeignType.type,
    fetchTickersByTopForeignTypeWorker
  );
}
function* fetchTickersByActiveSortWatcher() {
  yield takeLatest(changeActiveSort.type, fetchTickersByTopForeignTypeWorker);
}
function* fetchTickersByTopForeignLoadMoreWatcher() {
  yield takeLatest(
    requestLoadMore.type,
    fetchTickersByTopForeignLoadMoreWorker
  );
}
function* fetchTopForeignWatcher() {
  yield takeLatest(requestTopForeign.type, fetchTopForeignWorker);
}

function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, fetchTopForeignWorker);
}

export default function* topForeignWidget() {
  yield all([
    fetchTickersByTopForeignTypeWatcher(),
    fetchTickersByActiveSortWatcher(),
    fetchTickersByTopForeignLoadMoreWatcher(),
    fetchTopForeignWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
