import { ContainerTable } from "./styles";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { columnOpen } from "./constants";
import { useSelector } from "react-redux";
import { selectAssetsTable } from "modules/assetsIncurred/selectors";

const Table = () => {
  const data = useSelector(selectAssetsTable);
  const fetchMoreData = () => {};

  return (
    <ContainerTable id="assets">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columnOpen}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"assets"}
        isHasFooter={false}
      />
    </ContainerTable>
  );
};

export default Table;
