import { useMemo } from "react";

import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateLiquidateModalStatusRequest } from "modules/deposit-contract/redux";
import {
  isShowLiquidateModalSelector,
  listDepositContractLiquidateModalSelector,
} from "modules/deposit-contract/selectors";
import {} from "core/http/apis/deposit-contract/types";
import numeral from "numeral";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { DepositContract } from "domain/models/DepositContract";
import {
  BodyModal,
  HeaderModal,
  Icon,
  ModalCloseButton,
  ModalTitle,
  Wrapper,
  Container,
  HeaderCell,
  TableCellContent,
} from "./styles";
import { convertNumberToDateFormat } from "helper/utils";
import CommonTableInfinity from "components/commons/CommonTableInfinity";

interface Props {
  itemDetailRow: DepositContract;
}

function DetailLiquidateModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowLiquidateModalSelector);
  const data = useSelector(listDepositContractLiquidateModalSelector);
  // const { orderType } = useSelector(filterOrderHistorySelector);
  const closeModalHandler = () => {
    dispatch(updateLiquidateModalStatusRequest({ isVisible: false }));
  };

  // const clickAcceptHandler = () => {
  //   dispatch(
  //     updateModalConfirmRequest({
  //       isVisible: true,
  //       itemDetailRow: itemDetailRow,
  //     })
  //   );
  // };
  // useEffect(() => {
  //   if (isVisibleConfirmModal === true) {
  //     dispatch(updateLiquidateModalStatusRequest({ isVisible: false }));
  //   }
  // }, [isVisibleConfirmModal]);

  const fetchMoreData = () => {};
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.liquidateModal.paymentDate" />
          </HeaderCell>
        ),
        accessor: "paymentDate",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.liquidateModal.amount" />
          </HeaderCell>
        ),
        accessor: "amount",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.liquidateModal.currentAmt" />
          </HeaderCell>
        ),
        accessor: "currentAmt",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.liquidateModal.grossRate" />
          </HeaderCell>
        ),
        accessor: "grossRate",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0.00")}%
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.liquidateModal.payedInterest" />
          </HeaderCell>
        ),
        accessor: "payedInterest",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>Chi tiết {itemDetailRow.contractNo}</ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <Container id="orderHistoryModalTable">
            <CommonTableInfinity
              fetchMoreData={fetchMoreData}
              columns={columns}
              data={data ?? []}
              enableInfinity={true}
              scrollableTarget={"orderHistoryModalTable"}
              isHasFooter={false}
            />
          </Container>
          {/* <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                style={{
                  background: `${themes[themeType].buttonGradientColor}`,
                }}
                fill={true}
                width="7rem"
                onClick={clickAcceptHandler}
              >
                <FormattedMessage id="depositContract.liquidateModal.accept" />
              </Button>
            </ButtonCell>
          </ActionsRow> */}
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailLiquidateModal;
