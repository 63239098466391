import React from "react";
import { Row } from "./style";
import TimeCell from "./TimeCell";
import classNames from "classnames";
import PriceCell from "../Table/Body/TableBody/Row/PriceCell/Index";
import TickerCell from "./TickerCell/Index";
import VolumeCell from "../Table/Body/TableBody/Row/VolumeCell";
import { MrktAdvItem } from "domain/protoNew/auto_trading_pb";

export type RowProps = {
  putThroughData: MrktAdvItem.AsObject;
  style: React.CSSProperties;
  rowNumber: number;
};

function RowStock({ putThroughData, style, rowNumber }: RowProps) {
  return (
    <Row style={style} isOddRowCount={rowNumber % 2 === 1}>
      <div className="ticker-div ticker-col">
        <TickerCell
          ticker={putThroughData.seccd}
          color={putThroughData.colorcode}
        />
      </div>
      <PriceCell
        color="yellow50"
        price={putThroughData.price}
        className="price-col"
        isPreventSetCommand
      />
      <VolumeCell
        className="volume-col"
        volume={putThroughData.qty}
        keepRawValue
      />
      <TimeCell
        className={classNames("time-col", { "time-matched-col": false })}
        time={putThroughData.advtime}
      />
    </Row>
  );
}

export default RowStock;
