import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { logOutSuccess } from "modules/auth/redux";
import { ACCOUNT_STATEMENT } from "./constants";

interface IMoneyStatementFilter {
  subAccoNo: any;
  fromDate: Date;
  toDate: Date;
}
export interface ReduxData {
  currentPage: number;
  limit: number;
  enableInfinity: boolean;
  activeTab: string;
  loading: boolean;
  listMoneyStatement: [];
  listSecurityStatement: [];
  surplus: { p_BEGIN_AMT: 0; p_LAST_AMT: 0 };
  moneyStatement: {
    filter: IMoneyStatementFilter;
  };
  securityStatement: {
    filter: IMoneyStatementFilter;
  };
}

const initialState: ReduxData = {
  currentPage: 0,
  limit: 30,
  enableInfinity: true,
  activeTab: ACCOUNT_STATEMENT.SECURITIES_STATEMENT,
  loading: false,
  listMoneyStatement: [],
  listSecurityStatement: [],
  surplus: { p_BEGIN_AMT: 0, p_LAST_AMT: 0 },
  moneyStatement: {
    filter: {
      subAccoNo: { label: "", value: "" },
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
    },
  },
  securityStatement: {
    filter: {
      subAccoNo: { label: "", value: "" },
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
    },
  },
};

const AccountStatementSlice = createSlice({
  name: "accountStatementSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },

  reducers: {
    getListMoneyStatementRequest: (state, action: PayloadAction<any>) => {},
    getListMoneyStatementSuccess: (state, action: PayloadAction<any>) => {
      state.listMoneyStatement = action.payload?.data?.listR9008Data;
      state.listSecurityStatement = action.payload?.data?.listR9008Data;
      state.surplus = {
        p_BEGIN_AMT: action.payload?.data?.p_BEGIN_AMT,
        p_LAST_AMT: action.payload?.data?.p_LAST_AMT,
      };
    },
    getListMoneyStatementFailure: (state) => {},

    searchListMoneyStatementRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    searchListMoneyStatementSuccess: (state, action: PayloadAction<any>) => {
      state.listMoneyStatement = action.payload?.data?.listR9008Data;
      state.surplus = {
        p_BEGIN_AMT: action.payload?.data?.p_BEGIN_AMT,
        p_LAST_AMT: action.payload?.data?.p_LAST_AMT,
      };
      state.loading = false;
    },
    searchListMoneyStatementFailure: (state) => {
      state.loading = false;
    },

    searchListSecurityStatementRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    searchListSecurityStatementSuccess: (state, action: PayloadAction<any>) => {
      state.listSecurityStatement = action.payload?.data?.listR9008Data;
      state.loading = false;
    },
    searchListSecurityStatementFailure: (state) => {
      state.loading = false;
    },
    updateMoneyStatementFilter: (
      state,
      action: PayloadAction<{ key: keyof IMoneyStatementFilter; value: any }>
    ) => {
      state.moneyStatement.filter = {
        ...state.moneyStatement.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateSecurityStatementFilter: (
      state,
      action: PayloadAction<{ key: keyof IMoneyStatementFilter; value: any }>
    ) => {
      state.securityStatement.filter = {
        ...state.securityStatement.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateEnableInfinity: (state, action: PayloadAction<boolean>) => {
      state.enableInfinity = action.payload;
    },

    // fetchMoreDataRequest: (state, action: PayloadAction<any>) => {},
    // fetchMoreDataSuccess: (state, action: PayloadAction<any>) => {
    //   state.listMoneyStatement = action.payload?.data?.listR9008Data;
    //   state.listSecurityStatement = action.payload?.data?.listR9008Data;
    //   state.surplus = {
    //     p_BEGIN_AMT: action.payload?.data?.p_BEGIN_AMT,
    //     p_LAST_AMT: action.payload?.data?.p_LAST_AMT,
    //   };
    // },
  },
});

export const {
  getListMoneyStatementRequest,
  getListMoneyStatementSuccess,
  getListMoneyStatementFailure,

  searchListMoneyStatementRequest,
  searchListMoneyStatementSuccess,
  searchListMoneyStatementFailure,

  updateMoneyStatementFilter,
  updateSecurityStatementFilter,

  searchListSecurityStatementRequest,
  searchListSecurityStatementSuccess,
  searchListSecurityStatementFailure,

  updatePage,
  updateEnableInfinity,
} = AccountStatementSlice.actions;
export default AccountStatementSlice.reducer;
