import { Route, RouteProps } from "react-router-dom";

import React from "react";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "modules/auth/selectors";

export interface RouterConfig extends RouteProps {
  path: string;
  page: React.ElementType;
  layout?: React.ElementType;
  checkLogin?: Boolean;
}

const AppRoute: React.FC<RouterConfig> = (props: RouterConfig) => {
  const { page: Component, layout: Layout, checkLogin = true, ...rest } = props;

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <Route
      {...rest}
      render={(appProps) => {
        return checkLogin === false || isAuthenticated === true ? ( //check chưa đăng nhập sẽ trả về null
          <Component {...appProps} {...rest} />
        ) : null;
      }}
    />
  );
};

export default AppRoute;
