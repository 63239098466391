import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ORDER, SORT_BY } from "components/widgets/generals/WatchList/constant";
import { CategoryOption } from "components/widgets/generals/WatchList/types";
import { LastSale, SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
import { Dictionary, orderBy } from "lodash";

export interface IActiveSort {
  sortBy: SORT_BY;
  order: ORDER;
}

export type WatchListState = {
  data: Dictionary<SymbolTotalInfo.AsObject>;
  selectedCategory: CategoryOption;
  error: string | undefined;
  loading: boolean;
  tickerList: string[];
  tickerListSorted: string[];
  activeSort: IActiveSort;
};

const initialState: WatchListState = {
  data: {},
  selectedCategory: { value: "", label: "", dataList: [] },
  error: undefined,
  loading: false,
  tickerList: [],
  tickerListSorted: [],
  activeSort: {
    sortBy: SORT_BY.UNKNOW,
    order: ORDER.UNKNOW,
  },
};

const watchListSlice = createSlice({
  name: "watchListSlice",
  initialState,
  reducers: {
    fetchWatchList: (
      state,
      action: PayloadAction<{ fullTickersList: string[] }>
    ) => {
      state.loading = true;
      state.error = undefined;
      state.data = {};
      state.tickerList = [];
      state.tickerListSorted = [];
    },
    fetchWatchListSuccess: (
      state,
      action: PayloadAction<SymbolTotalInfo.AsObject[]>
    ) => {
      action.payload.forEach((el) => {
        const tickerCode = el.secdetailinfo?.seccd;
        if (!tickerCode) return;

        if (state.data[tickerCode]) {
          state.data[tickerCode] = {
            ...state.data[tickerCode],
            ...el,
          };
        } else {
          state.data[tickerCode] = el;
        }
      });

      state.tickerList = Object.keys(state.data);
      state.tickerListSorted = [...state.tickerList];
      state.activeSort = {
        sortBy: SORT_BY.UNKNOW,
        order: ORDER.UNKNOW,
      };
      state.loading = false;
    },
    fetchWatchListFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    changeCategoryOption: (
      state,
      action: PayloadAction<{ option: CategoryOption }>
    ) => {
      state.selectedCategory = action.payload.option;
    },
    updateTicker: (state, action: PayloadAction<LastSale.AsObject>) => {
      const tickerName = action.payload.seccd;
      if (state.data[tickerName]) {
        const batchTickerInfo = {
          changepoint: action.payload.changepoint,
          changepercent: action.payload.changepercent,
          lastprice: action.payload.lastprice,
          totalqty: action.payload.totalqty,
        };
        Object.assign(state.data[tickerName].secdetailinfo, batchTickerInfo);
        state.data[tickerName].colorcode = action.payload.colorcode;
      }
    },
    changeActiveSort: (state, action: PayloadAction<IActiveSort>) => {
      state.activeSort = action.payload;
      let dataDefault = [...state.tickerList];
      if (
        action.payload.order !== ORDER.UNKNOW &&
        action.payload.sortBy !== SORT_BY.UNKNOW
      ) {
        dataDefault = orderBy(
          state.data,
          [action.payload.sortBy],
          [action.payload.order]
        ).map((tickerInfo) => tickerInfo.secdetailinfo?.seccd!);
      }
      state.tickerListSorted = dataDefault;
    },
  },
});

export const {
  fetchWatchList,
  fetchWatchListSuccess,
  fetchWatchListFailure,
  changeCategoryOption,
  updateTicker,
  changeActiveSort,
} = watchListSlice.actions;

export default watchListSlice.reducer;
