import { ChangeEvent, useEffect } from "react";
import { Input, Button } from "components/commons";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import {
  updateModalPaymentRequest,
  updateModalConfirmRequest,
  getMrgDebitInfo2Request,
  updateItemEdit,
} from "modules/contract-debit/redux";
import {
  isShowPaymentModalSelector,
  isShowConfirmModalSelector,
  loanTrackingInfoMrgDebitSelector,
  loanTrackingItemEditSelector,
} from "modules/contract-debit/selectors";
import { IGetMrgDebitInfo2Params } from "core/http/apis/contract-debit/types";
import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { MarginDebitContractWatch } from "domain/models/ContractDebit";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  DetailRowButton,
  BodyModalRowTB,
  DetailInputWrapper,
} from "./styles";

import { convertNumberToDateFormat } from "helper/utils";
import TextArea from "components/commons/Input/textArea";

interface Props {
  itemDetailRow?: MarginDebitContractWatch;
}

function PaymentModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisibleConfirmModal = useSelector(isShowConfirmModalSelector);
  const isVisible = useSelector(isShowPaymentModalSelector);
  const itemEdit = useSelector(loanTrackingItemEditSelector);

  const result = useSelector(loanTrackingInfoMrgDebitSelector);

  const closeModalHandler = () => {
    dispatch(updateItemEdit({ amount: "" }));

    dispatch(updateModalPaymentRequest({ isVisible: false }));
  };
  const clickAcceptHandler = () => {
    dispatch(
      updateModalConfirmRequest({
        isVisible: true,
        amount: itemEdit && itemEdit.amount ? +itemEdit.amount : 0,
        itemDetailRow: itemDetailRow,
      })
    );
  };
  useEffect(() => {
    if (isVisibleConfirmModal === true) {
      dispatch(updateModalPaymentRequest({ isVisible: false }));
    }
  }, [isVisibleConfirmModal]);
  const changeAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;

    dispatch(
      updateItemEdit({ amount: convertToNumber ? +convertToNumber : "" })
    );
  };
  const blurAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;

    const submitData: IGetMrgDebitInfo2Params = {
      allodate:
        itemDetailRow && itemDetailRow.alloDate ? itemDetailRow.alloDate : 0,
      refNo: itemDetailRow && itemDetailRow.refNo ? itemDetailRow.refNo : 0,
      amount: convertToNumber ? +convertToNumber : 0,
      isAuto: false,
    };
    dispatch(getMrgDebitInfo2Request(submitData));
  };
  const keyUpAmountHandler = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.stopPropagation();
      let convertToNumber =
        e?.target?.value
          .replaceAll(",", "")
          .replaceAll(".", "")
          .replace(/[^0-9.]/g, "") ?? 0;

      const submitData: IGetMrgDebitInfo2Params = {
        allodate:
          itemDetailRow && itemDetailRow.alloDate ? itemDetailRow.alloDate : 0,
        refNo: itemDetailRow && itemDetailRow.refNo ? itemDetailRow.refNo : 0,
        amount: convertToNumber ? +convertToNumber : 0,
        isAuto: false,
      };

      dispatch(getMrgDebitInfo2Request(submitData));
    }
  };

  const changeContentHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateItemEdit({ content: e?.target?.value ?? "" }));
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            {intl.formatMessage({
              id: "contractDebit.loanTrackingTab.confirmModal.title",
            })}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-3">
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.subAccount" />
            </LabelCell>
            <TextCell>{itemDetailRow?.subAccoNo}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.contrDateFrom" />
            </LabelCell>
            <TextCell>
              {convertNumberToDateFormat({
                date: itemDetailRow?.contrDateFrom,
              })}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.contrDateTo" />
            </LabelCell>
            <TextCell>
              {convertNumberToDateFormat({ date: itemDetailRow?.contrDateTo })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.currentDebit" />
            </LabelCell>
            <TextCell>
              {" "}
              {numeral(itemDetailRow?.currentDebit).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.interest" />
            </LabelCell>
            <TextCell>
              {" "}
              {result && result?.[0]
                ? numeral(result?.[0] ?? 0).format("0,0")
                : "0"}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.fee" />
            </LabelCell>
            <TextCell>
              {" "}
              {result && result?.[3]
                ? numeral(result?.[3] ?? 0).format("0,0")
                : "0"}
            </TextCell>
          </BodyModalRow>
          <BodyModalRowTB>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.amount" />
            </LabelCell>
            {/* <TextCell>{result?.[2]}</TextCell> */}
            <DetailInputWrapper>
              <div className="text-white">
                <Input
                  value={
                    itemEdit?.amount &&
                    numeral(itemEdit?.amount ?? 0).format("0,0")
                  }
                  onChange={changeAmountHandler}
                  onKeyUp={keyUpAmountHandler}
                  onBlur={blurAmountHandler}
                  placeholder={intl.formatMessage({
                    id: "moneyTransfer.enterAmount",
                  })}
                />
              </div>
            </DetailInputWrapper>
          </BodyModalRowTB>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.availableBalance" />
            </LabelCell>
            <TextCell>
              {" "}
              {result && result?.[2]
                ? numeral(result?.[2] ?? 0).format("0,0")
                : "0"}
            </TextCell>
          </BodyModalRow>
          <BodyModalRowTB>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.content" />
            </LabelCell>
            {/* <TextCell>{result?.[2]}</TextCell> */}
            <DetailInputWrapper>
              <div className="text-white">
                <TextArea
                  textArea={true}
                  value={itemEdit?.content}
                  onChange={changeContentHandler}
                  placeholder={intl.formatMessage({
                    id: "depositContract.create.placeholderRemarks",
                  })}
                />
              </div>
            </DetailInputWrapper>
          </BodyModalRowTB>
          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={closeModalHandler}
            >
              {intl.formatMessage({
                id: "contractDebit.loanTrackingTab.confirmModal.cancel",
              })}
            </Button>
            <Button
              color="gradientaAccept"
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "contractDebit.loanTrackingTab.confirmModal.payment",
              })}
            </Button>
          </DetailRowButton>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default PaymentModal;
