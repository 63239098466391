import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { SelectPlaceOrder } from "components/commons";
import { FormattedMessage, useIntl } from "react-intl";
import {
  onChangeTickerByEnterDer,
  updatePlaceOrderDerAccount,
} from "modules/stock-order-der/redux/PlaceOrderDer";
import { FormControl, FormTitle } from "../styles";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import { GetAccoList } from "helper/sessionData";
import { accountSelectSelector } from "modules/stock-order-der/selectors";
import { MasterAccount } from "domain/models/User";

interface Props {
  error?: string;
}

interface Options {
  label: string;
  value: string;
}

const AccountSelect: React.FC<Props> = memo(({ error }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const accountSelect = useSelector(accountSelectSelector);
  const listAcc = GetAccoList(
    GetAccountType.DER,
    EntrustBusinessCode.Derivative
  );

  const accounts = useMemo(() => {
    let list: Options[] = [];
    listAcc.map((account: any) => {
      const masterAcc = account?.subAccoNo.split(".");
      if (list.length > 0) {
        const checkExist = list.find((item) => item.label === masterAcc[0]);
        if (!checkExist) {
          list.push({
            label: masterAcc[0],
            value: masterAcc[0],
          });
        }
      } else {
        list.push({
          label: masterAcc[0],
          value: masterAcc[0],
        });
      }
      return account;
    });
    return list;
  }, [listAcc, intl]);

  const onChange = (option: any) => {
    dispatch(onChangeTickerByEnterDer(true));
    if (option.value === accountSelect?.custNo) return;
    const accountChange: MasterAccount = {
      custCd: 0,
      custNo: option.value,
    };

    dispatch(updatePlaceOrderDerAccount(accountChange!));
  };

  const accValue = () => {
    if (isAuthenticated) {
      if (accounts.length > 0) {
        if (accountSelect?.custNo !== "") {
          return accounts.find(
            (item: any) => item.value === accountSelect?.custNo
          );
        } else {
          const accountChange: MasterAccount = {
            custCd: 0,
            custNo: accounts[0].value,
          };
          dispatch(updatePlaceOrderDerAccount(accountChange!));
          return accounts[0];
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="d-flex align-items-center">
      <FormTitle>
        <FormattedMessage id="widgets.placeOrder.account" />
      </FormTitle>
      <FormControl>
        <SelectPlaceOrder
          className="flex-fill"
          value={accValue()}
          options={accounts}
          onChange={onChange}
        />
        {error ? (
          <div>
            <span style={{ color: "red" }}>{error}</span>
          </div>
        ) : (
          <div></div>
        )}
      </FormControl>
    </div>
  );
});

export default AccountSelect;
