import {
  Body,
  Avatar,
  ButtonEditInfo,
  Content,
  ContentLabel,
  ContentInfo,
  ContentRow,
  ContentCell,
  ContentCellLeft,
  ContentCellRight,
  ButtonEdit,
  Header,
  HeaderContent,
  BodyContent,
  LabelRow,
  FullName,
  CustomerNo,
} from "./styles";
// import { Spacer } from "components/commons";
import {
  memo,
  // useMemo,
  useState,
} from "react";
// import { ReactComponent as IconEdit2 } from "assets/image/svg/ic_edit2.svg";
import { ReactComponent as IconArrowLeft } from "assets/image/svg/ic_arrow_fill_left.svg";
import { ReactComponent as IconArrowRight } from "assets/image/svg/ic_arrow_fill_right.svg";
import { useIntl, FormattedMessage, IntlShape } from "react-intl";
import { useSelector } from "react-redux";
// import {
//   getBrokerList,
//   setvisibleModalEditInfo,
//   setvisibleModalInfo,
//   setVisibleModalRegisterCaringStaff,
// } from "modules/auth/redux";
import { infoAccountSelector } from "modules/auth/selectors";
// import { OverlayTrigger } from "react-bootstrap";
// import { ReactComponent as IconI } from "assets/image/svg/icon_i.svg";
// import {
//   PopoverContainer,
//   PopoverCell,
// } from "modules/auth/pages/ModalAccountInfo/styles";
import moment from "moment";
// import { setVisibleModalEditGeneralInfo } from "modules/auth/redux/modalEditGeneralInfo";
// import { GetListBrokerParams } from "core/http/apis/account/types";
import {
  IAccountInfo,
  IEntrustsList,
  MsttCode,
} from "domain/models/AccountInfo";
import { EntrustType } from "helper/consts";

const getEntrustTypeDisplay = (entrustType: string, intl: IntlShape) => {
  switch (entrustType) {
    case EntrustType.ALL:
      return intl.formatMessage({ id: "auth.info.entrustType.all" });
    case EntrustType.ORDER:
      return intl.formatMessage({ id: "auth.info.entrustType.order" });
    case EntrustType.TRANFER_MONEY:
      return intl.formatMessage({ id: "auth.info.entrustType.tranferMoney" });
    case EntrustType.TRANFER_SHARE:
      return intl.formatMessage({ id: "auth.info.entrustType.tranferShare" });
    case EntrustType.ADVANCE:
      return intl.formatMessage({ id: "auth.info.entrustType.advance" });
    case EntrustType.REGISTER_BUY:
      return intl.formatMessage({ id: "auth.info.entrustType.registerBuy" });
    case EntrustType.SELL_ODD_LOT:
      return intl.formatMessage({ id: "auth.info.entrustType.sellOddLot" });
    case EntrustType.PLEDGE:
      return intl.formatMessage({ id: "auth.info.entrustType.pledge" });
    case EntrustType.TRANFER_DER:
      return intl.formatMessage({ id: "auth.info.entrustType.tranferDer" });
    case EntrustType.STATEMENT_SEARCH:
      return intl.formatMessage({
        id: "auth.info.entrustType.statementSearch",
      });
    case EntrustType.OTHER_ENTRUST:
      return intl.formatMessage({ id: "auth.info.entrustType.otherEntrust" });
    case EntrustType.MARGIN:
      return intl.formatMessage({ id: "auth.info.entrustType.margin" });
    default:
      return "";
  }
};

const getEntrustTypeDisplayList = (
  entrustTypeList: MsttCode[],
  intl: IntlShape
) => {
  const entrustTypeListDisplay = entrustTypeList.map((item, index) => {
    return index > 0
      ? " " +
          getEntrustTypeDisplay(
            item?.codeValue2 ? item?.codeValue2.trim() : "",
            intl
          )
      : getEntrustTypeDisplay(
          item?.codeValue2 ? item?.codeValue2.trim() : "",
          intl
        );
  });
  return entrustTypeListDisplay.toString().replaceAll(", ", "/ ");
};

const AccountInfo = memo(() => {
  const intl = useIntl();
  const accountInfo = useSelector(infoAccountSelector);
  const subAccountInfo: IAccountInfo = accountInfo?.data?.subAccount || {};
  const entrustList: IEntrustsList[] = accountInfo?.data?.entrustList || [];
  const [indexList, setIndexList] = useState(0);

  // const dispatch = useDispatch();

  // const closeModalInfo = () => {
  //   dispatch(setvisibleModalInfo());
  // };

  // const toggleModalEdit = () => {
  //   dispatch(setvisibleModalEditInfo());
  // };

  // const toggleModalRegisterCaringStaff = () => {
  //   const params: GetListBrokerParams = {
  //     tlName: "ALL",
  //   };
  //   dispatch(getBrokerList(params));
  //   dispatch(setVisibleModalRegisterCaringStaff());
  // };

  // const toggleModalEditGeneral = () => {
  //   dispatch(setVisibleModalEditGeneralInfo(true));
  // };

  const padding24 = 24;

  // const popover = useMemo(() => {
  //   return (
  //     <PopoverContainer>
  //       <PopoverCell>
  //         <FormattedMessage id="Quý khách có thể thay đổi đồng thời các thông tin, trường hợp thay đổi đồng thời Họ tên và Số CMT cần đến quầy giao dịch để thực hiện hoặc liên hệ NVCS tài khoản." />
  //       </PopoverCell>
  //     </PopoverContainer>
  //   );
  // }, []);

  return (
    <Body padding={padding24}>
      <Header>
        <Avatar image="https://i.pinimg.com/564x/b0/3a/29/b03a295fdbe6094acccf7f021b986d8c.jpg" />
        <HeaderContent>
          <FullName>
            {(subAccountInfo?.custFamilyName || "") +
              " " +
              (subAccountInfo?.custGivenName || "")}
          </FullName>
          <CustomerNo>
            <span className="title">
              {intl.formatMessage({ id: "auth.info.customerNo" })}:&nbsp;
            </span>
            <span className="customerNo">
              {subAccountInfo?.currentCustomer?.custNo}
            </span>
          </CustomerNo>
        </HeaderContent>
      </Header>
      <BodyContent>
        <Content>
          <LabelRow>
            <ContentLabel>
              <FormattedMessage id="auth.info.generalInformation" />
              {/* &nbsp;
              <OverlayTrigger
                trigger="hover"
                placement="bottom"
                overlay={popover}
              >
                <IconI className="icon" />
              </OverlayTrigger> */}
            </ContentLabel>
            {/* <ButtonEdit>
              <ButtonEditInfo
              onClick={() => {
                toggleModalEditGeneral();
              }}
              >
                <div className="title-edit">
                  <FormattedMessage id="auth.info.editInfo.titleButton" />
                </div>
                <Spacer size="s" />
                <div className="icon">
                  <IconEdit2 />
                </div>
              </ButtonEditInfo>
            </ButtonEdit> */}
          </LabelRow>
          <ContentInfo>
            <ContentRow>
              <ContentCellLeft>
                <ContentCell>
                  <FormattedMessage id="auth.info.identityCardId" />
                </ContentCell>
                <ContentCell></ContentCell>
              </ContentCellLeft>
              <ContentCellRight>
                <ContentCell></ContentCell>
                <ContentCell
                  isTextRight
                  title={subAccountInfo.idNumber}
                  flex={3}
                >
                  {subAccountInfo.idNumber}
                </ContentCell>
              </ContentCellRight>
            </ContentRow>
            <ContentRow>
              <ContentCellLeft>
                <ContentCell>
                  <FormattedMessage id="auth.info.issueDate" />
                </ContentCell>
                <ContentCell isTextRight>
                  {subAccountInfo?.currentCustomer?.issueDate
                    ? moment(
                        subAccountInfo?.currentCustomer?.issueDate,
                        "YYYYMMDD"
                      ).format("DD/MM/YYYY")
                    : ""}
                </ContentCell>
              </ContentCellLeft>
              <ContentCellRight>
                <ContentCell>
                  <FormattedMessage id="auth.info.expireDate" />
                </ContentCell>
                <ContentCell isTextRight>
                  {subAccountInfo?.currentCustomer?.expireDate
                    ? moment(
                        subAccountInfo?.currentCustomer?.expireDate,
                        "YYYYMMDD"
                      ).format("DD/MM/YYYY")
                    : ""}
                </ContentCell>
              </ContentCellRight>
            </ContentRow>
            <ContentRow>
              <ContentCellLeft>
                <ContentCell>
                  <FormattedMessage id="auth.info.birthDate" />
                </ContentCell>
                <ContentCell isTextRight>
                  {subAccountInfo?.currentCustomer?.birthday
                    ? moment(
                        subAccountInfo?.currentCustomer?.birthday,
                        "YYYYMMDD"
                      ).format("DD/MM/YYYY")
                    : ""}
                </ContentCell>
              </ContentCellLeft>
              <ContentCellRight>
                <ContentCell>
                  <FormattedMessage id="auth.info.sex" />
                </ContentCell>
                <ContentCell isTextRight>
                  {subAccountInfo?.currentCustomer?.sex === 2 && "Nữ"}
                  {subAccountInfo?.currentCustomer?.sex === 1 && "Nam"}
                </ContentCell>
              </ContentCellRight>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.issuePlace" />
              </ContentCell>
              <ContentCell
                isTextRight
                title={subAccountInfo?.currentCustomer?.issueLocation}
                flex={3}
                isWrap
              >
                {subAccountInfo?.currentCustomer?.issueLocation}
              </ContentCell>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.currentAddress" />
              </ContentCell>
              <ContentCell
                isTextRight
                title={subAccountInfo?.currentCustomer?.address}
                flex={3}
                isWrap
              >
                {subAccountInfo?.currentCustomer?.address}
              </ContentCell>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.phone" />
              </ContentCell>
              <ContentCell isTextRight>
                {subAccountInfo?.currentCustomer?.mobileNo}
              </ContentCell>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.email" />
              </ContentCell>
              <ContentCell isTextRight>
                {subAccountInfo?.currentCustomer?.emailAdrs}
              </ContentCell>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.openDate" />
              </ContentCell>
              <ContentCell isTextRight>
                {subAccountInfo?.openDate
                  ? moment(subAccountInfo?.openDate, "YYYYMMDD").format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </ContentCell>
            </ContentRow>
          </ContentInfo>
        </Content>
        <Content>
          <LabelRow>
            <ContentLabel>
              <FormattedMessage id="auth.info.entrustInfo" />
            </ContentLabel>
            <ButtonEdit>
              {entrustList?.length > 0 ? (
                <>
                  <ButtonEditInfo
                    className={indexList === 0 ? "display-none" : ""}
                    onClick={() => {
                      if (indexList !== 0) {
                        setIndexList(indexList - 1);
                      }
                    }}
                  >
                    <IconArrowLeft />
                  </ButtonEditInfo>
                  <ButtonEditInfo
                    className={
                      indexList === entrustList.length - 1 ? "display-none" : ""
                    }
                    onClick={() => {
                      if (indexList !== entrustList.length - 1) {
                        setIndexList(indexList + 1);
                      }
                    }}
                  >
                    <IconArrowRight />
                  </ButtonEditInfo>
                </>
              ) : (
                <div></div>
              )}
            </ButtonEdit>
          </LabelRow>
          <ContentInfo>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.fullName" />
              </ContentCell>
              <ContentCell isTextRight>
                {entrustList?.length > 0
                  ? entrustList[indexList].entrustCustName
                  : "---"}
              </ContentCell>
            </ContentRow>

            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.entrustRange" />
              </ContentCell>
              <ContentCell isTextRight>
                {entrustList?.length > 0
                  ? getEntrustTypeDisplayList(
                      entrustList[indexList].lstEntrustDetail || [],
                      intl
                    )
                  : "---"}
              </ContentCell>
            </ContentRow>

            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.effectDate" />
              </ContentCell>
              <ContentCell isTextRight>
                {entrustList?.length > 0
                  ? entrustList[indexList].entrustStartDate
                    ? moment(
                        entrustList[indexList].entrustStartDate,
                        "YYYYMMDD"
                      ).format("DD/MM/YYYY")
                    : "---"
                  : "---"}
              </ContentCell>
            </ContentRow>
          </ContentInfo>
        </Content>
        <Content>
          <LabelRow>
            <ContentLabel>
              <FormattedMessage id="auth.info.caringStaff.title" />
            </ContentLabel>
            {/* <ButtonEdit>
              <ButtonEditInfo
              onClick={() => {
                closeModalInfo();
                toggleModalRegisterCaringStaff();
              }}
              >
                <div className="title-edit">
                  {intl.formatMessage({
                    id: "auth.info.caringStaff.registerButton",
                  })}
                </div>
                <Spacer size="s" />
                <div className="icon">
                  <IconEdit2 />
                </div>
              </ButtonEditInfo>
            </ButtonEdit> */}
          </LabelRow>
          <ContentInfo>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.brokerCode" />
              </ContentCell>
              <ContentCell isTextRight>{subAccountInfo.brokerCd}</ContentCell>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.brokerName" />
              </ContentCell>
              <ContentCell isTextRight>
                {subAccountInfo.brokerFullName}
              </ContentCell>
            </ContentRow>
            {/* <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.brokerBrand" />
              </ContentCell>
              <ContentCell isTextRight>{}</ContentCell>
            </ContentRow> */}
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.brokerEmail" />
              </ContentCell>
              <ContentCell isTextRight>
                {subAccountInfo?.currentCustomer?.brokerEmails}
              </ContentCell>
            </ContentRow>
            <ContentRow>
              <ContentCell>
                <FormattedMessage id="auth.info.brokerPhone" />
              </ContentCell>
              <ContentCell isTextRight>
                {subAccountInfo?.currentCustomer?.brokerMobileNo}
              </ContentCell>
            </ContentRow>
          </ContentInfo>
        </Content>
      </BodyContent>
    </Body>
  );
});

export default AccountInfo;
