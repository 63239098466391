import React from "react";
import { TickerCode } from "./styles";
import { SecType } from "helper/consts";

interface Props {
  ticker: string;
  isBold?: boolean;
  tickerType?: number;
  isFontMin?: boolean;
}

const ClickableTicker: React.FC<Props> = React.memo(
  ({ ticker, isBold = true, tickerType = SecType.ALL, isFontMin }) => {
    return (
      <TickerCode className="ticker-code" isFontMin={isFontMin}>
        <span className={isBold ? "is-bold" : ""}>{ticker}</span>
      </TickerCode>
    );
  }
);

export default ClickableTicker;
