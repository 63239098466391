import TreemapChart from "components/commons/Charts/TreemapChart";
import { getColorChart } from "helper/utils";
import { fetchCapitalization } from "modules/market/redux/capitalization";
import { capitalizationSelector } from "modules/market/redux/selectors";
import numeral from "numeral";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ContentContainer } from "./styles";
import GlobalData from "helper/globalData";
import { themeTypeSelector } from "themes/redux";
import sortBy from "lodash/sortBy";
import { DEFAULT_WIDGET_REFETCH_INTERVAL, UNIT_NUMBER } from "helper/consts";
import {
  SystemState,
  TopMarketCapResponse,
} from "domain/protoNew/auto_trading_pb";
import { useEffectOnce, useInterval } from "react-use";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";

function Capitalization() {
  const dispatch = useDispatch();
  const marketCapData = useSelector(capitalizationSelector);
  const themeType = useSelector(themeTypeSelector);
  const intl = useIntl();

  useInterval(() => {
    dispatch(fetchCapitalization());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    dispatch(fetchCapitalization());
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(fetchCapitalization());
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  });

  const generateData = (
    marketCapData: TopMarketCapResponse.TopMarketCapInfo.AsObject[]
  ) => {
    let chartData: number[] = [];
    let chartTooltips: string[] = [];
    let chartColors: string[] = [];
    let chartLabels: string[] = [];
    let chartPercentLabels: string[] = [];
    let chartLongLabels: string[] = [];
    const sortedMarketCapData = sortBy(marketCapData, [
      function (o) {
        return o.marketcap;
      },
    ]).reverse();
    sortedMarketCapData.forEach(
      (item: TopMarketCapResponse.TopMarketCapInfo.AsObject) => {
        chartData.push(item.marketcap);
        chartTooltips.push(
          `${numeral(item.marketcap / UNIT_NUMBER.ONE_THOUSAND).format(
            "0,0"
          )}${intl.formatMessage({ id: "common.million" })}`
        );
        chartColors.push(getColorChart(item.colorcode, themeType));
        chartLabels.push(item.seccd);
        chartLongLabels.push(
          `${item.seccd} - ${GlobalData.getTickerInfoNew(item.seccd)?.secname}`
        );
        chartPercentLabels.push(
          `${numeral(item.changepercent).format("0.[00]")}%`
        );
      }
    );
    return {
      chartData,
      chartColors,
      chartLabels,
      chartTooltips,
      chartPercentLabels,
      chartLongLabels,
    };
  };

  const {
    chartData,
    chartColors,
    chartLabels,
    chartTooltips,
    chartPercentLabels,
    chartLongLabels,
  } = generateData(marketCapData);

  return (
    <ContentContainer>
      <div className="chart">
        <TreemapChart
          values={chartData}
          tooltipValues={chartTooltips}
          colors={chartColors}
          treeLabels={chartLabels}
          treeLongLabels={chartLongLabels}
          valuesInPercents={chartPercentLabels}
        />
      </div>
    </ContentContainer>
  );
}

export default Capitalization;
