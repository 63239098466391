import queryString from "query-string";
import {
  IFindAdvanceParams,
  IGetAmtCanReceiveParams,
  ICustomerRestrictionParams,
  IParameterBankDetailForPayAdvanceParams,
  IGetPayAdvanceLimitRemainParams,
  IGetBankBranchCdParams,
  IGetBankBranchCd2Params,
} from "./types";

export const cashAdvance = (masterAccount: string, account: string): string =>
  `accounts/cash/advance/${masterAccount}/${account}`;
export const cashAdvanceHistory = (
  masterAccount: string,
  account?: string
): string => `accounts/cash/advanceHistory/${masterAccount}/${account}`;
export const cashAdvanceFeeInfo = (
  masterAccount: string,
  account: string
): string => `accounts/cash/advanceFeeInfo/${masterAccount}/${account}`;

export const findAdvanceApi = (params: IFindAdvanceParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findListAdvanceContract${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const deletePayAdvanceApi = "/updatePayAdvance";

export const getAmtCanReceiveApi = (params: IGetAmtCanReceiveParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findAdvanceOrderDataForMultiContract${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const getCustomerRestrictionApi = (
  params: ICustomerRestrictionParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getCustomerRestriction${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const getFindParameterBankDetailForPayAdvanceApi = (
  params: IParameterBankDetailForPayAdvanceParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findParameterBankDetailForPayAdvance${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const getPayAdvanceLimitRemainApi = (
  params: IGetPayAdvanceLimitRemainParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getPayAdvanceLimitRemain${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const getBankBranchCdApi = (params: IGetBankBranchCdParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getBankBranchCd${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const getBankBranchCd2Api = (params: IGetBankBranchCd2Params) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getBankBranchCd${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getFeeAdvanceApi = "/updatePayAdvanceMultiContract";

export const createAdvanceContractApi = "/updatePayAdvanceMultiContract";
