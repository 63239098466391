import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Dictionary } from "lodash";
import numeral from "numeral";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { PortfolioRealTime } from "domain/models/Portfolio";
import { portfolioRealTimeListSelector } from "modules/stock-order/redux/selectors";
import { Colors } from "helper/consts";

const colorCodeSelector = (orderId: string) => {
  return createSelector(
    portfolioRealTimeListSelector,
    (state?: Dictionary<PortfolioRealTime>) => state?.[orderId]?.colorCode
  );
};

const marketPriceSelector = (orderId: string) => {
  return createSelector(
    portfolioRealTimeListSelector,
    (state?: Dictionary<PortfolioRealTime>) => state?.[orderId]?.marketPrice
  );
};

interface Props {
  orderId: string;
}

const MarketPriceCell = ({ orderId }: Props) => {
  const colorCode = useSelector(colorCodeSelector(orderId));
  const marketPrice = useSelector(marketPriceSelector(orderId));
  const formatterValue = numeral(marketPrice).format("0,0.00");
  return (
    <>
      <TableCellContent align="flex-end" color={Colors[colorCode ?? 0]}>
        {formatterValue || "-"}
      </TableCellContent>
    </>
  );
};

export default MarketPriceCell;
