import {
  getWidthForOptionalColumn,
  getWidthRatio,
} from "modules/categories/helpers/utils";
import { ColManage } from "modules/categories/types";
import styled from "styled-components/macro";

const getOddRowStyle = (isOddRowCount: boolean, theme: any) => {
  if (!isOddRowCount)
    return `  div {
    background-color: ${theme.background2};
  }`;

  return `
  div {
    background-color: ${theme.background1};
  }
  `;
};

export const OuterRowContainer = styled.div`
  transition: all 0.3s ease;

  div {
    font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
  }
`;

export const Row = styled.div<{
  isOddRowCount: boolean;
  sumWidth: number;
  askWidth: number;
  bidWidth: number;
  foreignWidth: number;
  priceWidth: number;
  lastSaleWidth: number;
  coverWarrantWidth: number;
  coverWarrantHeadWidth: number;
  columns: ColManage;
  isFocus: boolean;
}>`
  min-width: ${({ sumWidth }) => sumWidth}px;
  text-align: center;
  ${({ isFocus, theme }) =>
    isFocus &&
    `
  div {
    background-color: ${theme.grey60} !important;
  }
  `}
  :hover div {
    background-color: ${({ theme }) => theme.grey60};
  }

  ${({ isOddRowCount, theme }) => getOddRowStyle(isOddRowCount, theme)}

  ::after {
    content: "";
    display: block;
    clear: both;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .ticker-div {
    display: flex;
    align-items: center;
  }

  div {
    font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
    font-weight: ${({ theme }) =>
      theme.typeFaces.watchlist.body.weight.regular};
    height: 28px;
    box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
  }

  .bg-overlay-col {
    background-color: ${({ theme }) => theme.background2};
  }

  .flex-content {
    display: flex;
    align-items: stretch;

    div {
      flex: 1;
    }
  }

  & > div {
    float: left;
    font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
    min-width: ${({ columns }) => columns.TICKER.minWidth}px;
    width: ${({ sumWidth, columns }) =>
      getWidthRatio(columns.TICKER.minWidth, sumWidth)}%;

    line-height: ${({ theme }) => theme.typeFaces.watchlist.body.lineHeight};

    &.ticker-name-col {
      min-width: ${({ columns }) => columns.TICKER.minWidth}px;
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.TICKER.minWidth, sumWidth)}%;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => columns.TICKER.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.TICKER.smallDeviceMinWidth, sumWidth)}%;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.textcolorWhite};
        font-weight: ${({ theme }) =>
          theme.typeFaces.watchlist.body.weight.regular};
        font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
        line-height: ${({ theme }) =>
          theme.typeFaces.watchlist.body.lineHeight};
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        :first-child {
          @media (max-width: 1919px) {
            width: ${({ sumWidth, columns }) =>
              getWidthRatio(columns.TICKER.smallDeviceMinWidth, sumWidth)}%;
          }
          min-width: 100%;
          text-align: left;
          justify-content: flex-start;
          padding-left: 2px;
        }
      }
    }

    &.ticker-name-col.remove-box-shadow {
      box-shadow: inset -1px 0px 0px transparent,
        ${({ theme }) => theme.shadowInnerDivider};
      div:first-child {
        box-shadow: inset -1px 0px 0px transparent,
          ${({ theme }) => theme.shadowInnerDivider};
      }
    }

    &.chart {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.CHART.minWidth, sumWidth)}%;
      min-width: ${({ columns }) => columns.CHART.minWidth}px;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => columns.CHART.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.CHART.smallDeviceMinWidth, sumWidth)}%;
      }
      height: 28px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .inner-container {
        box-shadow: inset -1px 0px 0px ${({ theme }) => theme.grey60},
          ${({ theme }) => theme.shadowInnerDivider};
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.CHART.minWidth, sumWidth)}%;
        min-width: ${({ columns }) => columns.CHART.minWidth}px;
        > canvas {
          height: 20px !important;
          margin-top: 4px;
          margin-bottom: 4px;
          padding-right: 4px;
        }
      }
    }

    &.opening-price.ceiling-price {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.SUMMARY.children!.CEIL.minWidth!, sumWidth)}%;
      min-width: ${({ columns }) => columns.SUMMARY.children!.CEIL.minWidth}px;
      box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
      @media (max-width: 1919px) {
        min-width: ${({ columns }) =>
          columns.SUMMARY.children!.CEIL.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.SUMMARY.children!.CEIL.smallDeviceMinWidth,
            sumWidth
          )}%;
      }
    }

    &.opening-price.floor-price {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.SUMMARY.children!.FLOOR.minWidth, sumWidth)}%;
      min-width: ${({ columns }) => columns.SUMMARY.children!.FLOOR.minWidth}px;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) =>
          columns.SUMMARY.children!.FLOOR.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.SUMMARY.children!.FLOOR.smallDeviceMinWidth,
            sumWidth
          )}%;
      }
      box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
    }

    &.opening-price.reference-short-price {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.SUMMARY.children!.REFER.minWidth, sumWidth)}%;
      min-width: ${({ columns }) => columns.SUMMARY.children!.REFER.minWidth}px;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) =>
          columns.SUMMARY.children!.REFER.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.SUMMARY.children!.REFER.smallDeviceMinWidth,
            sumWidth
          )}%;
      }
      box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
    }

    &.bid-col {
      min-width: ${({ bidWidth }) => bidWidth}px;
      width: ${({ sumWidth, bidWidth }) => getWidthRatio(bidWidth, sumWidth)}%;

      .volume1 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.BID.children!.VOLUME1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.VOLUME1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.VOLUME1.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.BID.children!.VOLUME1.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .volume2 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.BID.children!.VOLUME2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.VOLUME2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.VOLUME2.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.BID.children!.VOLUME2.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .volume3 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.BID.children!.VOLUME3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.VOLUME3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.VOLUME3.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.BID.children!.VOLUME3.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .price1 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.BID.children!.PRICE1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.PRICE1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.PRICE1.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.BID.children!.PRICE1.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .price2 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.BID.children!.PRICE2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.PRICE2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.PRICE2.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.BID.children!.PRICE2.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .price3 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.BID.children!.PRICE3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.BID.children!.PRICE3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.BID.children!.PRICE3.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.BID.children!.PRICE3.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }
    }

    &.last-sale-col {
      width: ${({ lastSaleWidth, sumWidth }) =>
        getWidthRatio(lastSaleWidth, sumWidth)}%;
      min-width: ${({ lastSaleWidth }) => lastSaleWidth}px;

      .last-sale-price {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.LAST_SALE.children!.PRICE.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.LAST_SALE.children!.PRICE.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.LAST_SALE.children!.PRICE.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.LAST_SALE.children!.PRICE.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .last-sale-margin {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.LAST_SALE.children!.CHANGE_PRICE.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.LAST_SALE.children!.CHANGE_PRICE.minWidth,
            sumWidth
          )}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.LAST_SALE.children!.CHANGE_PRICE.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.LAST_SALE.children!.CHANGE_PRICE.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .last-sale-amount {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.LAST_SALE.children!.VOLUME.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.LAST_SALE.children!.VOLUME.minWidth,
            sumWidth
          )}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.LAST_SALE.children!.VOLUME.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.LAST_SALE.children!.VOLUME.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }
    }

    &.ask-col {
      min-width: ${({ askWidth }) => askWidth}px;
      width: ${({ sumWidth, askWidth }) => getWidthRatio(askWidth, sumWidth)}%;

      .volume1 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.ASK.children!.VOLUME1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.VOLUME1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.VOLUME1.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.ASK.children!.VOLUME1.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .volume2 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.ASK.children!.VOLUME2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.VOLUME2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.VOLUME2.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.ASK.children!.VOLUME2.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .volume3 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.ASK.children!.VOLUME3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.VOLUME3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.VOLUME3.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.ASK.children!.VOLUME3.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .price1 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.ASK.children!.PRICE1.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.PRICE1.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.PRICE1.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.ASK.children!.PRICE1.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .price2 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.ASK.children!.PRICE2.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.PRICE2.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.PRICE2.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.ASK.children!.PRICE2.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .price3 {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.ASK.children!.PRICE3.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.ASK.children!.PRICE3.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.ASK.children!.PRICE3.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.ASK.children!.PRICE3.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }
    }

    &.total-volume-col {
      width: ${({ sumWidth, columns }) =>
        getWidthRatio(columns.VOLUME.minWidth!, sumWidth)}%;
      min-width: ${({ columns }) => columns.VOLUME.minWidth}px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1919px) {
        min-width: ${({ columns }) => columns.VOLUME.smallDeviceMinWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.VOLUME.smallDeviceMinWidth!, sumWidth)}%;
      }

      div {
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.VOLUME.minWidth!, sumWidth)}%;
        min-width: ${({ columns }) => columns.VOLUME.minWidth}px;
        border-left: 1px solid ${({ theme }) => theme.border1};
        @media (max-width: 1919px) {
          min-width: ${({ columns }) => columns.VOLUME.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(columns.VOLUME.smallDeviceMinWidth!, sumWidth)}%;
        }
      }
    }

    &.price-col {
      width: ${({ priceWidth, sumWidth }) =>
        getWidthRatio(priceWidth, sumWidth)}%;
      min-width: ${({ priceWidth }) => priceWidth}px;

      .high {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.PRICE?.children!.HIGH.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.PRICE?.children!.HIGH.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.PRICE?.children!.HIGH.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.PRICE?.children!.HIGH.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .low {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) => columns.PRICE?.children!.LOW.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.PRICE?.children!.LOW.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.PRICE?.children!.LOW.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.PRICE?.children!.LOW.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .average {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.PRICE?.children!.AVERAGE.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.PRICE?.children!.AVERAGE.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.PRICE?.children!.AVERAGE.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.PRICE?.children!.AVERAGE.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }
    }

    &.foreign-col {
      width: ${({ sumWidth, foreignWidth }) =>
        (foreignWidth / sumWidth) * 100}%;
      min-width: ${({ foreignWidth }) => foreignWidth}px;
      .bid {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.FOREIGN?.children!.BID.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.FOREIGN?.children!.BID.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.FOREIGN?.children!.BID.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.FOREIGN?.children!.BID.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .ask {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.FOREIGN?.children!.ASK.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(columns.FOREIGN?.children!.ASK.minWidth, sumWidth)}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.FOREIGN?.children!.ASK.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.FOREIGN?.children!.ASK.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }

      .currentroom {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          columns.FOREIGN?.children!.CURRENTROOM?.minWidth}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            columns.FOREIGN?.children!.CURRENTROOM?.minWidth,
            sumWidth
          )}%;
        @media (max-width: 1919px) {
          min-width: ${({ columns }) =>
            columns.FOREIGN?.children!.CURRENTROOM?.smallDeviceMinWidth}px;
          width: ${({ sumWidth, columns }) =>
            getWidthRatio(
              columns.FOREIGN?.children!.CURRENTROOM?.smallDeviceMinWidth,
              sumWidth
            )}%;
        }
      }
    }

    &.cover-warrant-group {
      width: ${({ sumWidth, coverWarrantWidth }) =>
        getWidthRatio(coverWarrantWidth, sumWidth)}%;
      min-width: ${({ coverWarrantWidth }) => coverWarrantWidth}px;

      .ckcs {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "CKCS")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "CKCS"),
            sumWidth
          )}%;
      }
      .price_ckcs {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "PRICE_CKCS")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "PRICE_CKCS"),
            sumWidth
          )}%;
      }
      .th {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "TH")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "TH"),
            sumWidth
          )}%;
      }
      .ratio {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "RATIO")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "RATIO"),
            sumWidth
          )}%;
      }
      .diff_cd {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "DIFF_CD")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "DIFF_CD"),
            sumWidth
          )}%;
      }
      .break_even_point {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(
            columns,
            "COVER_WARRANT",
            "BREAK_EVEN_POINT"
          )}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(
              columns,
              "COVER_WARRANT",
              "BREAK_EVEN_POINT"
            ),
            sumWidth
          )}%;
      }
    }

    &.cover-warrant-head-group {
      width: ${({ sumWidth, coverWarrantHeadWidth }) =>
        getWidthRatio(coverWarrantHeadWidth, sumWidth)}%;
      min-width: ${({ coverWarrantHeadWidth }) => coverWarrantHeadWidth}px;

      .tcph {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "TCPH")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "TCPH"),
            sumWidth
          )}%;
      }
      .gdcc {
        box-shadow: inset -1px -1px 0px ${({ theme }) => theme.border1};
        min-width: ${({ columns }) =>
          getWidthForOptionalColumn(columns, "COVER_WARRANT", "GDCC")}px;
        width: ${({ sumWidth, columns }) =>
          getWidthRatio(
            getWidthForOptionalColumn(columns, "COVER_WARRANT", "GDCC"),
            sumWidth
          )}%;
      }
    }
  }
`;

export const TextCell = styled.div<{ color?: string }>`
  color: ${({ theme, color }) => (color ? theme[color] : theme.textcolorWhite)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
  flex-direction: row-reverse;
  padding-right: 8px;
`;
