import PlaceOrderDer from "./generals/PlaceOrderDer";
import OrderBookDer from "./generals/OrderBookDer";
import PriceList from "modules/stock-order-der/components/StockOrderDerPriceList"; // TODO: create StockOrderDerPriceList container for OrderStockDer
import VolumeAndTime from "modules/stock-order-der/components/StockOrderDerVolumeAndTime"; // TODO: create StockOrderDerVolumeAndTime container for OrderStockDer
import OpenPosition from "modules/position-management/page/PositonManagement/PositionOpen";
import ClosePosition from "modules/position-management/page/PositonManagement/PositionClose";
import { Widget } from "./types";
// import MoneyBlock from "./generals/MoneyBlock";
import MarketTickerChart from "modules/tickerDetails/components/MarketTickerChart";

const orderStockDerWidgets: Widget[] = [
  {
    name: "widgets.placeOrderDer.name",
    component: PlaceOrderDer,
  },
  {
    name: "widgets.priceList.name",
    component: PriceList,
  },
  {
    name: "widgets.volumeAndTime.title",
    component: VolumeAndTime,
  },
  {
    name: "widgets.openPosition.name",
    component: OpenPosition,
  },
  {
    name: "widgets.closePosition.name",
    component: ClosePosition,
  },
  {
    name: "orderBook.title",
    component: OrderBookDer,
  },
  {
    name: "widgets.tickerGraph.name",
    component: MarketTickerChart,
  },
  // {
  //   name: "widgets.moneyblock.name",
  //   component: MoneyBlock,
  // },
];

export default orderStockDerWidgets;
