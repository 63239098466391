import { toast } from "components/commons/Toast";
import { doFilter } from "core/grpc/screenersNew";
import {
  deleteScreenerApi,
  getScreenerListApi,
  saveScreenerApi,
} from "core/http/apis/screener";
import { IScreenerResponse } from "domain/models/Screener";
import { ScreenerResponse } from "domain/protoNew/auto_trading_pb";
import { all, call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  deleteScreenerFailure,
  deleteScreenerRequest,
  deleteScreenerSuccess,
  doFilterFailure,
  doFilterRequest,
  doFilterSuccess,
  fetchMoreFilterFailure,
  fetchMoreFilterRequest,
  fetchMoreFilterSuccess,
  getAllTickerToAddWatchlistFailure,
  getAllTickerToAddWatchlistRequest,
  getAllTickerToAddWatchlistSuccess,
  getListScreenerFailure,
  getListScreenerRequest,
  getListScreenerSuccess,
  saveScreenerFailure,
  saveScreenerRequest,
  saveScreenerSuccess,
} from "./redux";
import { IBeautyResponseFilter } from "./types";
import storage from "helper/storage";

const convertBeautyResultList = (
  response: ScreenerResponse.AsObject,
  offset: number
): IBeautyResponseFilter => {
  const newList = response.secdetailinfoList.map((item, index) => {
    return {
      ...item,
      rowNum: offset + index + 1,
    };
  });

  return {
    total: response.totalrecords ?? 0,
    resultsList: newList,
  };
};

function* getListScreenerWorker() {
  try {
    const masterAccount = storage.getMasterAccount();
    const response: IScreenerResponse = yield call(getScreenerListApi, {
      userId: masterAccount,
    });
    yield put(getListScreenerSuccess(response));
  } catch (error: any) {
    yield put(getListScreenerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListScreenerWatcher() {
  yield takeLatest(getListScreenerRequest.type, getListScreenerWorker);
}

function* deleteScreenerWorker(
  action: ReturnType<typeof deleteScreenerRequest>
) {
  try {
    const screener = action.payload;
    yield call(deleteScreenerApi, screener);
    const masterAccount = storage.getMasterAccount();

    const response: IScreenerResponse = yield call(getScreenerListApi, {
      userId: masterAccount,
    });

    yield put(deleteScreenerSuccess(response));
    toast("screeners.tabs.screener.actions.deleteSuccess", "success");
  } catch (error: any) {
    yield put(deleteScreenerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* deleteScreenerWatcher() {
  yield takeLatest(deleteScreenerRequest.type, deleteScreenerWorker);
}

function* saveScreenerWorker(action: ReturnType<typeof saveScreenerRequest>) {
  try {
    const { param, body } = action.payload;
    yield call(saveScreenerApi, param, body);
    const masterAccount = storage.getMasterAccount();

    const response: IScreenerResponse = yield call(getScreenerListApi, {
      userId: masterAccount,
    });

    yield put(saveScreenerSuccess(response));
    toast("screeners.tabs.screener.actions.createSuccess", "success");
  } catch (error: any) {
    yield put(saveScreenerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* saveScreenerWatcher() {
  yield takeLatest(saveScreenerRequest.type, saveScreenerWorker);
}

function* doFilterWorker(action: ReturnType<typeof doFilterRequest>) {
  try {
    const requestFilter = action.payload;
    if (
      requestFilter.getIndustries() ||
      requestFilter.getQuotesindicators() ||
      requestFilter.getFinancialindicators() ||
      requestFilter.getTechnicalindicators() ||
      requestFilter.getMarketcd()
    ) {
      const response: ScreenerResponse.AsObject = yield call(
        doFilter,
        requestFilter
      );
      yield put(doFilterSuccess(convertBeautyResultList(response, 0)));
    }
  } catch (error: any) {
    yield put(doFilterFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* doFilterWatcher() {
  yield takeLatest(doFilterRequest.type, doFilterWorker);
}

function* getAllTickerToAddWatchlistWorker(
  action: ReturnType<typeof getAllTickerToAddWatchlistRequest>
) {
  try {
    const requestFilter = action.payload;
    if (
      requestFilter.getIndustries() ||
      requestFilter.getQuotesindicators() ||
      requestFilter.getFinancialindicators() ||
      requestFilter.getTechnicalindicators() ||
      requestFilter.getMarketcd()
    ) {
      const response: ScreenerResponse.AsObject = yield call(
        doFilter,
        requestFilter
      );
      yield put(
        getAllTickerToAddWatchlistSuccess(convertBeautyResultList(response, 0))
      );
    }
  } catch (error: any) {
    yield put(getAllTickerToAddWatchlistFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getAllTickerToAddWatchlistWatcher() {
  yield takeLatest(
    getAllTickerToAddWatchlistRequest.type,
    getAllTickerToAddWatchlistWorker
  );
}

function* fetchMoreFilterWorker(
  action: ReturnType<typeof fetchMoreFilterRequest>
) {
  try {
    const requestFilter = action.payload;
    if (
      requestFilter.getIndustries() ||
      requestFilter.getQuotesindicators() ||
      requestFilter.getFinancialindicators() ||
      requestFilter.getTechnicalindicators() ||
      requestFilter.getMarketcd()
    ) {
      const response: ScreenerResponse.AsObject = yield call(
        doFilter,
        requestFilter
      );
      yield put(
        fetchMoreFilterSuccess(
          convertBeautyResultList(response, requestFilter.toObject().offset)
        )
      );
    }
  } catch (error: any) {
    yield put(fetchMoreFilterFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreFilterWatcher() {
  yield takeLeading(fetchMoreFilterRequest.type, fetchMoreFilterWorker);
}

export default function* screenersSaga() {
  yield all([
    getListScreenerWatcher(),
    deleteScreenerWatcher(),
    saveScreenerWatcher(),
    doFilterWatcher(),
    fetchMoreFilterWatcher(),
    getAllTickerToAddWatchlistWatcher(),
  ]);
}
