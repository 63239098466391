import {
  getCustomer2,
  getServiceStatusOfCustomer,
  registerService,
} from "./../../core/http/apis/service-email-sms/index";
import {
  loginApi,
  // postInitSession,
  postInitTrading,
} from "core/http/apis/auth";
import { InfoAccount, ResponseData } from "domain/models/User";
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { IManagerDevice } from "./../../core/http/apis/manager-device/type";

import {
  checkReadAllNotification,
  checkReadNotification,
  deleteNotification,
  getNotificationDetail,
  getNotificationList,
  getNotificationTotalUnread,
} from "core/http/apis/trans";
import {
  NotificationDetailResponse,
  Notifications,
  NotificationTotalUnread,
  ResultDeleteNotification,
} from "domain/models/Notification";
// import {
//   createSMSOTPApi,
//   verifyTwoPhaseAuthenApi,
// } from "core/http/apis/twofactor";
import { changePass } from "core/http/apis/password";
import {
  // createOTP,
  // createOTPFailed,
  // verifyOTP,
  // verifyOTPSuccess,
  // verifyOTPFailed,
  changePassword,
  getAccountInfo,
  getAccountInfoFailed,
  getAccountInfoSuccess,
  getMeFailed,
  getMeStart,
  getMeSuccess,
  loginFailed,
  loginStart,
  loginSuccess,
  logOutStart,
  logOutSuccess,
  setvisibleModalChangePassword,
  setVisibleModalChangePasswordSuccess,
  toastSaveOTPSuccess,
  toggleModalLogin,
} from "./redux";

import { toast } from "components/commons/Toast";
import { accessKeyTradingViewApi } from "core/http/apis/trading-view";
import { subscribeOrderData } from "core/wssOrder";
import LoginInterface from "domain/models/Login";
import { TradingViewSession } from "domain/models/TradingViewSession";
import storage from "helper/storage";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { disableHotNew } from "./redux/hotnews";
import {
  checkReadAllNotificationFailed,
  checkReadAllNotificationParams,
  checkReadAllNotificationSuccess,
  checkReadNotificationFailed,
  checkReadNotificationParams,
  checkReadNotificationSuccess,
  deleteNotificationFailed,
  deleteNotificationParams,
  deleteNotificationSuccess,
  getNotification,
  getNotificationDetailFailed,
  getNotificationDetailParams,
  getNotificationDetailSuccess,
  getNotificationFailed,
  getNotificationSuccess,
  getNotificationTotalUnreadFailed,
  getNotificationTotalUnreadStart,
  getNotificationTotalUnreadSuccess,
} from "./redux/notification";
import {
  changeRegisterOtpType,
  changeRegisterOtpTypeFailed,
  changeRegisterOtpTypeSuccess,
  deleteTransferContractBank,
  deleteTransferContractBankFailed,
  deleteTransferContractBankSuccess,
  getListBeneficiary,
  getListBeneficiaryFailed,
  getListBeneficiarySuccess,
  setDefaultSubAccoNo,
  setDefaultSubAccoNoSuccess,
  setDefaultSubAccoNoFailed,
  isAlreadyRegisterAuthenFor134Failed,
  isAlreadyRegisterAuthenFor134Request,
  isAlreadyRegisterAuthenFor134Success,
  updateOneTimeAuthen,
  updateOneTimeAuthenFailed,
  updateOneTimeAuthenSuccess,
  updateTransferContractBank,
  updateTransferContractBankFailed,
  updateTransferContractBankSuccess,
} from "./redux/tradingAccount";
import {
  NotificationGroupByDate,
  NotificationItem,
  NotificationListData,
} from "./types";

import {
  addAccountBeneficiary,
  deleleAccountBeneficiary,
  getAccountBeneficiary,
  setDefaultSubAccoNoApi,
} from "core/http/apis/account";
import { getAccountInfoApi } from "core/http/apis/account-info";
import { GetTransferAccountParams } from "core/http/apis/account/types";
import { InitTradingParams } from "core/http/apis/auth/types";
import { getListDevice } from "core/http/apis/manager-device";
import {
  isAlreadyRegisterAuthenFor134Api,
  registerOTPAuthenApi,
  updateOneTimeAuthenApi,
} from "core/http/apis/twofactor";
import {
  IRegisterOTPAuthenResponse,
  IUpdateOneTimeAuthenResponse,
} from "core/http/apis/twofactor/types";
import { IAccountInfoResponse } from "domain/models/AccountInfo";
import {
  GetTransferAccountResponse,
  IDeleteTransferContractBankResponse,
  ISetDefaultSubAccoNoResponse,
  IUpdateTransferContractBankResponse,
} from "domain/models/Cash";
import moment from "moment";
import { RootState } from "redux/store";
import {
  getListDeviceFailure,
  getListDeviceRequest,
  getListDeviceSuccess,
  refreshDeviceFailure,
  refreshDeviceRequest,
  refreshDeviceSuccess,
} from "./redux/managerDevice";
import {
  getTradingViewAccessSession,
  getTradingViewAccessSessionSuccess,
} from "./redux/tradingView";
import { storageOTPCodeSelector } from "./selectors";
import {
  getCustomer2Failure,
  getCustomer2Request,
  getCustomer2Success,
  getServiceStatusOfCustomerFailure,
  getServiceStatusOfCustomerRequest,
  getServiceStatusOfCustomerSuccess,
  registerServiceFailure,
  registerServiceRequest,
  registerServiceSuccess,
  searchServiceStatusOfCustomerFailure,
  searchServiceStatusOfCustomerRequest,
  searchServiceStatusOfCustomerSuccess,
} from "./redux/serviceEmailSms";
import { getWatchListsOwnerStart } from "modules/categories/redux";

// const getUserAuthenList = (authenList: IAuthenType) => {
//   const list: CortUserAuthenItem[] = [
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.LOGIN,
//       otpType: authenList[EBusinessCdPinType.LOGIN],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.TRADING,
//       otpType: authenList[EBusinessCdPinType.TRADING],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.TRANSFER,
//       otpType: authenList[EBusinessCdPinType.TRANSFER],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.ADVANCE,
//       otpType: authenList[EBusinessCdPinType.ADVANCE],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.ODD_SELL,
//       otpType: authenList[EBusinessCdPinType.ODD_SELL],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.CAP_INCREASE,
//       otpType: authenList[EBusinessCdPinType.CAP_INCREASE],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.MARGIN,
//       otpType: authenList[EBusinessCdPinType.MARGIN],
//     },
//     {
//       allowSaveOtp: authenList.oneTimeAuth,
//       businessCd: EBusinessCd.DEPOSIT,
//       otpType: authenList[EBusinessCdPinType.DEPOSIT],
//     },
//   ];

//   return list;
// };

function* loginSaga(action: ReturnType<typeof loginStart>) {
  try {
    const resLogin: LoginInterface = yield call(loginApi, action.payload);
    yield storage.setAccessToken(resLogin.access_token);
    subscribeOrderData(resLogin.access_token);
    yield storage.setTimeAccessToken(
      (parseInt(moment().format("X")) + resLogin.expires_in).toString()
    );
    yield storage.setMasterAccount(action.payload.username);

    yield storage.setSessionState(resLogin.session_state);

    yield storage.setRefreshToken(resLogin.refresh_token);
    yield storage.setTimeRefreshToken(
      (parseInt(moment().format("X")) + resLogin.refresh_expires_in).toString()
    );

    yield put(toggleModalLogin(false));

    yield put(loginSuccess());
    yield put(getMeStart());
  } catch (error: any) {
    yield put({ type: loginFailed, payload: error });
  }
}

function* getMeSaga() {
  try {
    // yield call(postInitSession);

    const body: InitTradingParams = {
      param:
        "ORDER_TYPE|SUB_ACCOUNT|ENTRUST|CATEGORY|PARAMETER|PARAMETER_ADDITION|ENTRUST_TYPE|DERT_SECPRICE|ENTRUST_TYPE_DETAIL|SECURITIES|ADF_PARAMETER",
    };

    const resAccount: ResponseData<InfoAccount> = yield call(
      postInitTrading,
      body
    );
    if (resAccount?.statusCode === 0) {
      yield put(getMeSuccess({ account: resAccount.data, isReset: "" }));
      yield put(getWatchListsOwnerStart());
    } else {
      toast(resAccount?.errorCode, "error", resAccount.message, true);
    }
  } catch (error: any) {
    yield put({ type: getMeFailed });
  }
}

function* logOutSaga(action: ReturnType<typeof logOutStart>) {
  try {
    // unSubscribe
    yield storage.removeToken();
    yield put({ type: logOutSuccess });
    yield put(disableHotNew(true));
  } catch (error: any) {
    // yield put({ type: getMeFailed });
  }
}

function* getAccountInfoSaga(action: ReturnType<typeof getAccountInfo>) {
  try {
    // Master Account Data
    const resAccount: IAccountInfoResponse = yield call(
      getAccountInfoApi,
      action.payload
    );

    yield put(getAccountInfoSuccess(resAccount));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(getAccountInfoFailed(error));
  }
}

// function* createOTPCode(action: ReturnType<typeof createOTP>) {
//   try {
//     yield call(createSMSOTP, action.payload.params);
//     const intl = action.payload.intl;
//     if (action.payload.toast) {
//       toast(
//         intl.formatMessage({
//           id: "auth.info.sendOTP.sendOTPAgainToastSuccess",
//         }),
//         "success"
//       );
//     }
//   } catch (error: any) {
//     yield put({ type: createOTPFailed });
//   }
// }

// function* verifyOTPSMS(action: ReturnType<typeof verifyOTP>) {
//   try {
//     const OTPCode: number = yield call(verifyTwoPhaseAuthenApi, action.payload);

//     yield put({
//       type: verifyOTPSuccess,
//       payload: OTPCode,
//     });
//   } catch (error: any) {
//     toast(error.code, "error", error.description, true);
//     yield put({ type: verifyOTPFailed, payload: error });
//   }
// }

function* fcchangePass(action: ReturnType<typeof changePassword>) {
  try {
    yield call(changePass, action.payload);
    yield put({ type: setvisibleModalChangePassword });
    yield put({ type: logOutSuccess });
    yield put({
      type: setVisibleModalChangePasswordSuccess,
      payload: true,
    });
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function getDisplayNotificationDataGroupByDate(
  rpgcInput: Notifications
): NotificationListData {
  const notificationList = groupBy(
    rpgcInput.data,
    (item: NotificationGroupByDate) => item.date
  );
  const listNotification = Object.keys(notificationList).map((key) => {
    const items = notificationList[key] as NotificationItem[];
    const notificationGroupByDate: NotificationGroupByDate = {
      date: key,
      items: items,
    };
    return notificationGroupByDate;
  });
  const displayData: NotificationListData = {
    items: orderBy(listNotification, "date", "desc"),
  };
  return displayData;
}

function* getNotificationSaga(action: ReturnType<typeof getNotification>) {
  try {
    // list notification
    const notification: Notifications = yield call(
      getNotificationList,
      action.payload.params
    );

    const displayData: NotificationListData =
      getDisplayNotificationDataGroupByDate(notification);

    yield put(getNotificationSuccess(displayData));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(getNotificationFailed(error));
  }
}

function* getNotificationDetailSaga(
  action: ReturnType<typeof getNotificationDetailParams>
) {
  try {
    // list notification
    const notificationDetail: NotificationDetailResponse = yield call(
      getNotificationDetail,
      action.payload
    );

    yield put(getNotificationDetailSuccess(notificationDetail.data));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(getNotificationDetailFailed(error));
  }
}

function* deleteNotificationSaga(
  action: ReturnType<typeof deleteNotificationParams>
) {
  try {
    // delete notification
    const resultDelete: ResultDeleteNotification = yield call(
      deleteNotification,
      action.payload.params
    );

    yield put(
      deleteNotificationSuccess({
        result: resultDelete,
        noticeId: action.payload.params.noticeId,
        notificationList: action.payload.notificationList,
      })
    );
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(deleteNotificationFailed(error));
  }
}

function* checkReadNotificationSaga(
  action: ReturnType<typeof checkReadNotificationParams>
) {
  try {
    // check read notification
    const resultCheckRead: ResultDeleteNotification = yield call(
      checkReadNotification,
      action.payload
    );

    // Total Notification Unread
    const notificationUnread: NotificationTotalUnread = yield call(
      getNotificationTotalUnread
    );
    yield put(getNotificationTotalUnreadSuccess(notificationUnread));
    yield put(
      checkReadNotificationSuccess({
        params: resultCheckRead,
        noticeId: action.payload.noticeId,
      })
    );
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(checkReadNotificationFailed(error));
  }
}

function* checkReadAllNotificationSaga(
  action: ReturnType<typeof checkReadAllNotificationParams>
) {
  try {
    // check read notification
    const resultCheckReadAll: ResultDeleteNotification = yield call(
      checkReadAllNotification,
      action.payload
    );

    // Total Notification Unread
    const notificationUnread: NotificationTotalUnread = yield call(
      getNotificationTotalUnread
    );
    yield put(getNotificationTotalUnreadSuccess(notificationUnread));
    yield put(checkReadAllNotificationSuccess(resultCheckReadAll));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
    yield put(checkReadAllNotificationFailed(error));
  }
}

function* getNotificationTotalUnreadWorker() {
  try {
    // Total Notification Unread
    const notificationUnread: NotificationTotalUnread = yield call(
      getNotificationTotalUnread
    );
    yield put({
      type: getNotificationTotalUnreadSuccess,
      payload: notificationUnread,
    });
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(getNotificationTotalUnreadFailed());
  }
}

function* toastSaveOTPSuccessWorker() {
  const storageOTPCode: { code: string; type: number } = yield select(
    storageOTPCodeSelector
  );
  if (storageOTPCode.type === 2) {
    toast("saveQRSuccess", "success");
  } else {
    return toast("saveOtpSuccess", "success");
  }
}

// TK Giao dich - Start

function* isAlreadyRegisterAuthenFor134Worker(
  action: ReturnType<typeof isAlreadyRegisterAuthenFor134Request>
) {
  try {
    // Change type otp account trading
    const res: IRegisterOTPAuthenResponse = yield call(
      isAlreadyRegisterAuthenFor134Api,
      action.payload
    );

    yield put(isAlreadyRegisterAuthenFor134Success(res));
  } catch (error: any) {
    yield put(isAlreadyRegisterAuthenFor134Failed(error));
  }
}

function* watchIsAlreadyRegisterAuthenFor134() {
  yield takeLatest(
    isAlreadyRegisterAuthenFor134Request.type,
    isAlreadyRegisterAuthenFor134Worker
  );
}

function* changeRegisterOtpTypeWorker(
  action: ReturnType<typeof changeRegisterOtpType>
) {
  try {
    // Change type otp account trading
    const res: IRegisterOTPAuthenResponse = yield call(
      registerOTPAuthenApi,
      action.payload.params
    );

    yield put(changeRegisterOtpTypeSuccess(res));
  } catch (error: any) {
    yield put(changeRegisterOtpTypeFailed(error));
  }
}

function* watchChangeRegisterOtpType() {
  yield takeLatest(changeRegisterOtpType.type, changeRegisterOtpTypeWorker);
}

function* updateOneTimeAuthenWorker(
  action: ReturnType<typeof updateOneTimeAuthen>
) {
  try {
    // Change type otp account trading
    const res: IUpdateOneTimeAuthenResponse = yield call(
      updateOneTimeAuthenApi,
      action.payload
    );

    yield put(updateOneTimeAuthenSuccess(res));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(updateOneTimeAuthenFailed(error));
  }
}
function* managerDeviceWorker(action: ReturnType<typeof getListDeviceRequest>) {
  try {
    const res: IManagerDevice = yield call(getListDevice, action.payload);

    yield put(getListDeviceSuccess(res));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(getListDeviceFailure(error));
  }
}
function* refreshManagerDeviceWorker(
  action: ReturnType<typeof refreshDeviceRequest>
) {
  try {
    const res: IManagerDevice = yield call(getListDevice, action.payload);

    yield put(refreshDeviceSuccess(res));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(refreshDeviceFailure(error));
  }
}
function* getServiceStatusOfCustomerWorker(
  action: ReturnType<typeof getServiceStatusOfCustomerRequest>
) {
  try {
    const res: IManagerDevice = yield call(
      getServiceStatusOfCustomer,
      action.payload
    );

    yield put(getServiceStatusOfCustomerSuccess(res));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(getServiceStatusOfCustomerFailure(error));
  }
}
function* searchServiceStatusOfCustomerWorker(
  action: ReturnType<typeof searchServiceStatusOfCustomerRequest>
) {
  try {
    const res: IManagerDevice = yield call(
      getServiceStatusOfCustomer,
      action.payload
    );

    yield put(searchServiceStatusOfCustomerSuccess(res));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(searchServiceStatusOfCustomerFailure(error));
  }
}
function* registerServiceWorker(
  action: ReturnType<typeof registerServiceRequest>
) {
  try {
    const res: IManagerDevice = yield call(registerService, action.payload);
    if (res?.statusCode === 0) {
      yield put(registerServiceSuccess(res));
    } else {
      yield put(registerServiceFailure(res));
      toast(`${res?.message}`, "error");
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(registerServiceFailure(error));
  }
}
function* getCustomer2Worker(action: ReturnType<typeof getCustomer2Request>) {
  try {
    const res: IManagerDevice = yield call(getCustomer2, action.payload);

    yield put(getCustomer2Success(res));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
    yield put(getCustomer2Failure(error));
  }
}

function* watchupdateOneTimeAuthen() {
  yield takeLatest(updateOneTimeAuthen.type, updateOneTimeAuthenWorker);
}

function* GetListBeneficiary(action: ReturnType<typeof getListBeneficiary>) {
  try {
    //List Account Beneficiary

    const currentAccount: string = yield select(
      (state: RootState) => state.auth.root.data.currentAccount.subAccoNo
    );

    const paramsGetTransferAccount: GetTransferAccountParams = {
      subAccoNo: currentAccount,
    };

    const listAccountBeneficiary: GetTransferAccountResponse = yield call(
      getAccountBeneficiary,
      paramsGetTransferAccount
    );
    yield put(getListBeneficiarySuccess(listAccountBeneficiary));
  } catch (error: any) {
    yield put(getListBeneficiaryFailed(error));
  }
}

function* watchGetListBeneficiary() {
  yield takeLatest(getListBeneficiary.type, GetListBeneficiary);
}

function* setDefaultSubAccoNoWorker(
  action: ReturnType<typeof setDefaultSubAccoNo>
) {
  try {
    const res: ISetDefaultSubAccoNoResponse = yield call(
      setDefaultSubAccoNoApi,
      action.payload
    );
    yield put(setDefaultSubAccoNoSuccess(res));
  } catch (error: any) {
    yield put(setDefaultSubAccoNoFailed(error));
  }
}

function* watchSetDefaultSubAccoNo() {
  yield takeLatest(setDefaultSubAccoNo.type, setDefaultSubAccoNoWorker);
}

function* updateTransferContractBankWorker(
  action: ReturnType<typeof updateTransferContractBank>
) {
  try {
    const res: IUpdateTransferContractBankResponse = yield call(
      addAccountBeneficiary,
      action.payload.params
    );
    yield put(updateTransferContractBankSuccess(res));
  } catch (error: any) {
    yield put(updateTransferContractBankFailed(error));
  }
}

function* watchUpdateTransferContractBank() {
  yield takeLatest(
    updateTransferContractBank.type,
    updateTransferContractBankWorker
  );
}

function* deleteTransferContractBankWorker(
  action: ReturnType<typeof deleteTransferContractBank>
) {
  try {
    const res: IDeleteTransferContractBankResponse = yield call(
      deleleAccountBeneficiary,
      action.payload.params
    );
    yield put(deleteTransferContractBankSuccess(res));
  } catch (error: any) {
    yield put(deleteTransferContractBankFailed(error));
  }
}

function* watchDeleteTransferContractBank() {
  yield takeLatest(
    deleteTransferContractBank.type,
    deleteTransferContractBankWorker
  );
}

// TK Giao dich - End

function* toastSaveOTPSuccessWatcher() {
  yield takeLatest(toastSaveOTPSuccess.type, toastSaveOTPSuccessWorker);
}

function* getNotificationTotalUnreadWatcher() {
  yield takeLatest(
    getNotificationTotalUnreadStart.type,
    getNotificationTotalUnreadWorker
  );
}

function* watchLogin() {
  yield takeLatest(loginStart.type, loginSaga);
}

function* watchGetMe() {
  yield takeLatest(getMeStart.type, getMeSaga);
}

// function* watchCreateOTP() {
//   yield takeLatest(createOTP.type, createOTPCode);
// }

// function* watchVerifyOTP() {
//   yield takeLatest(verifyOTP.type, verifyOTPSMS);
// }

function* watchChagePass() {
  yield takeLeading(changePassword.type, fcchangePass);
}

function* watchLogOut() {
  yield takeLeading(logOutStart.type, logOutSaga);
}

function* watchGetNotification() {
  yield takeLatest(getNotification.type, getNotificationSaga);
}

function* watchGetNotificationDetail() {
  yield takeLeading(
    getNotificationDetailParams.type,
    getNotificationDetailSaga
  );
}

function* watchDeleteNotification() {
  yield takeLeading(deleteNotificationParams.type, deleteNotificationSaga);
}

function* watchCheckReadNotification() {
  yield takeLeading(
    checkReadNotificationParams.type,
    checkReadNotificationSaga
  );
}

function* watchCheckReadAllNotification() {
  yield takeLeading(
    checkReadAllNotificationParams.type,
    checkReadAllNotificationSaga
  );
}

function* getAccessKey(action: ReturnType<typeof getTradingViewAccessSession>) {
  try {
    const response: TradingViewSession = yield call(accessKeyTradingViewApi);
    yield put(getTradingViewAccessSessionSuccess(response));
  } catch (error: any) {}
}

function* watchAccessKey() {
  yield takeLatest(getTradingViewAccessSession.type, getAccessKey);
}

function* watchGetAccountInfo() {
  yield takeLatest(getAccountInfo.type, getAccountInfoSaga);
}
function* managerDeviceWatcher() {
  yield takeLatest(getListDeviceRequest.type, managerDeviceWorker);
}
function* refreshManagerDeviceWatcher() {
  yield takeLatest(refreshDeviceRequest.type, refreshManagerDeviceWorker);
}
function* getServiceStatusOfCustomerWatcher() {
  yield takeLatest(
    getServiceStatusOfCustomerRequest.type,
    getServiceStatusOfCustomerWorker
  );
}
function* searchServiceStatusOfCustomerWatcher() {
  yield takeLatest(
    searchServiceStatusOfCustomerRequest.type,
    searchServiceStatusOfCustomerWorker
  );
}
function* registerServiceWatcher() {
  yield takeLatest(registerServiceRequest.type, registerServiceWorker);
}
function* getCustomer2Watcher() {
  yield takeLatest(getCustomer2Request.type, getCustomer2Worker);
}

export default function* authSaga() {
  yield all([
    watchLogin(),
    watchGetMe(),

    // watchCreateOTP(),
    // watchVerifyOTP(),
    watchChagePass(),
    watchLogOut(),
    watchGetNotification(),

    watchGetNotificationDetail(),
    watchDeleteNotification(),
    watchCheckReadNotification(),
    watchCheckReadAllNotification(),

    getNotificationTotalUnreadWatcher(),
    watchAccessKey(),
    toastSaveOTPSuccessWatcher(),
    watchGetAccountInfo(),

    watchIsAlreadyRegisterAuthenFor134(),
    watchChangeRegisterOtpType(),
    watchupdateOneTimeAuthen(),
    watchGetListBeneficiary(),
    watchSetDefaultSubAccoNo(),
    watchUpdateTransferContractBank(),
    watchDeleteTransferContractBank(),
    managerDeviceWatcher(),
    refreshManagerDeviceWatcher(),
    getServiceStatusOfCustomerWatcher(),
    getCustomer2Watcher(),
    searchServiceStatusOfCustomerWatcher(),
    registerServiceWatcher(),
  ]);
}
