import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";

import {
  loanTrackingListSelector,
  loanTrackingDetailSelector,
  enableInfinityLoanTracking,
  currentPageloanTracking,
  selectLimitPage,
  loanTrackingFilterSelector,
} from "modules/contract-debit/selectors";
import {
  updateModalPaymentRequest,
  getMrgDebitInfo2Request,
  updateModalLoanExtendRequest,
  findListMarginAttrRequest,
  getMarginDebitExtendFeeInfoRequest,
  updateItemEdit,
  updateEnableInfinityLoanTracking,
  fetchMoreDebitWatchRequest,
} from "modules/contract-debit/redux";

import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  ButtonBuy,
  ButtonSell,
  HeaderCell,
  TableCellContent,
  TableCellContentColor,
} from "./styles";
import { MarginDebitContractWatch } from "domain/models/ContractDebit";
import PaymentModal from "./PaymentModal/index";
import LoanExtendModal from "./LoanExtendModal/index";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import ConfirmModal from "./ConfirmModal/index";
import { IFindMarginContractDebitWatchParams } from "core/http/apis/contract-debit/types";
import moment from "moment";
import { toast } from "components/commons/Toast";
import { GetAccoList } from "helper/sessionData";
import { GetAccountType, EntrustBusinessCode } from "helper/consts";

const statusOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.status1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.status2",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.status3",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.status4",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.status5",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "contractDebit.loanTrackingTab.filter.status.status6",
    }),
  },
];

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(loanTrackingListSelector);
  const itemDetailRow = useSelector(loanTrackingDetailSelector);
  const currentPage = useSelector(currentPageloanTracking);
  const limit = useSelector(selectLimitPage);
  const enable = useSelector(enableInfinityLoanTracking);
  const { fromDate, toDate, subAccoNo, status } = useSelector(
    loanTrackingFilterSelector
  );

  const listAcc = GetAccoList(
    GetAccountType.MARGIN,
    EntrustBusinessCode.Margin
  );
  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));

    return list;
  }, [listAcc, intl]);

  const statusOptions = useMemo(() => statusOption(intl), [intl]);
  const center = "center";
  const flexEnd = "flex-end";
  const themeType = useSelector(themeTypeSelector);
  const openPaymentModal = (row: MarginDebitContractWatch) => {
    dispatch(
      getMrgDebitInfo2Request({
        allodate: row.alloDate ?? 0,
        refNo: row.refNo ?? 0,
        amount: 0,
        isAuto: true,
      })
    );

    dispatch(
      updateModalPaymentRequest({
        isVisible: true,
        itemDetail: row,
      })
    );

    dispatch(
      updateItemEdit({
        content: intl.formatMessage(
          { id: "contractDebit.loanTrackingTab.confirmModal.contentRemark" },
          {
            accoNo: row.subAccoNo,
            date: convertNumberToDateFormat({
              date: row?.contrDateFrom,
            }),
          }
        ),
      })
    );
  };
  // useEffect(() => {
  //   if (result && result.length > 0 && result[1]) {
  //     dispatch(
  //       updateItemEdit({
  //         amount: result && result?.[1] ? +result?.[1] : 0,
  //       })
  //     );
  //   }
  // }, [result]);
  const openLoanExtendModal = (row: MarginDebitContractWatch) => {
    dispatch(
      findListMarginAttrRequest({
        subAccoCd: row.subAccoCd ?? 0,
        subAccoNo: row.subAccoNo ?? "",
      })
    );
    let amount = row.amount - row.payedAmount;
    dispatch(
      getMarginDebitExtendFeeInfoRequest({
        subAccoCd: row.subAccoCd ?? 0,
        subAccoNo: row.subAccoNo ?? "",
        amount: amount ?? 0,
      })
    );
    dispatch(
      updateModalLoanExtendRequest({
        isVisible: true,
        itemDetail: row,
      })
    );
  };
  function getColor2(value: string, value2: number) {
    if (value2 === 1 || value2 === 2 || value2 === 3 || value2 === 4) {
      return (
        <TableCellContentColor align="center" color={themes[themeType].grey0}>
          {value}
        </TableCellContentColor>
      );
    } else if (value2 === 6 || value2 === 5) {
      return (
        <TableCellContentColor align="center" color={themes[themeType].grey0}>
          {value}
        </TableCellContentColor>
      );
    }

    return value;
  }
  const getDisplay9 = (status: number) => {
    switch (status) {
      case 0:
        return "all";
      case 1:
        return "status1";
      case 2:
        return "status2";
      case 3:
        return "status3";
      case 4:
        return "status4";
      case 5:
        return "status5";
      case 6:
        return "status6";
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.contrDateFrom" />
          </HeaderCell>
        ),
        accessor: "contrDateFrom",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.contrDateTo" />
          </HeaderCell>
        ),
        accessor: "contrDateTo",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.amount" />
          </HeaderCell>
        ),
        accessor: "amount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.payedAmount" />
          </HeaderCell>
        ),
        accessor: "payedAmount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.currentDebit" />
          </HeaderCell>
        ),
        accessor: "currentDebit",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.payedInterest" />
          </HeaderCell>
        ),
        accessor: "payedInterest",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.todayInterest" />
          </HeaderCell>
        ),
        accessor: "todayInterest",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.endDayInterest" />
          </HeaderCell>
        ),
        accessor: "endDayInterest",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.extendCount" />
          </HeaderCell>
        ),
        accessor: "extendCount",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="contractDebit.loanTrackingTab.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 100,

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {getColor2(
                intl
                  .formatMessage({
                    id:
                      "contractDebit.loanTrackingTab.filter.status." +
                      getDisplay9(+props.value ?? 0),
                  })
                  .slice(2, -2),
                props.value
              )}
            </TableCellContent>
          );
        },
      },

      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          let row = props?.row?.original;

          return (
            <div className="btn_action_hover">
              {row && [1, 2, 3, 4].includes(row.status) && (
                <>
                  <ButtonBuy
                    className="buy"
                    onClick={() => openPaymentModal(props?.row?.original)}
                  >
                    <FormattedMessage id="contractDebit.loanTrackingTab.table.button.debtPayment" />
                  </ButtonBuy>

                  <ButtonSell
                    className="sell"
                    onClick={() => openLoanExtendModal(props?.row?.original)}
                  >
                    <FormattedMessage id="contractDebit.loanTrackingTab.table.button.loanExtension" />
                  </ButtonSell>
                </>
              )}

              {/* )} */}
            </div>
          );
        },
        // Footer: "",
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {
    if (data.length < (currentPage + 1) * limit) {
      return dispatch(updateEnableInfinityLoanTracking(false));
    }
    if (moment(toDate).isBefore(moment(fromDate))) {
      return toast("common.warningInvalidDay", "error");
    }
    const submitData: IFindMarginContractDebitWatchParams = {
      branchCd: null,
      accoNo: subAccoNo
        ? subAccoNo
        : parseOptionAccount[0].value
        ? parseOptionAccount[0].value
        : "",
      debitType: 0,
      status:
        status !== undefined && status !== null
          ? status
          : +statusOptions[1].value
          ? +statusOptions[1].value
          : 1,
      brokerCd: null,
      alloDate: 0,
      fromDate:
        fromDate && Number(moment(fromDate).format("YYYYMMDD"))
          ? Number(moment(fromDate).format("YYYYMMDD"))
          : 0,
      toDate:
        toDate && Number(moment(toDate).format("YYYYMMDD"))
          ? Number(moment(toDate).format("YYYYMMDD"))
          : 0,
      page: currentPage + 1,
      rowPerPage: limit,
    };
    dispatch(fetchMoreDebitWatchRequest(submitData));
  };

  return (
    <Container id="profitLossTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={enable}
        scrollableTarget={"profitLossTable"}
        isHasFooter={false}
      />
      {itemDetailRow && <PaymentModal itemDetailRow={itemDetailRow} />}
      {itemDetailRow && <LoanExtendModal itemDetailRow={itemDetailRow} />}

      <ConfirmModal />
    </Container>
  );
}

export default memo(RequestTable);
