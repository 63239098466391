import styled from "styled-components";

export type TSize = "small" | "normal" | "large";

export const Wrapper = styled.div<{
  sizeInput?: TSize;
  fullWidth?: boolean;
  isBorderActive?: boolean;
}>`
  height: 32px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.grey70};
  ${({ theme, isBorderActive }) =>
    isBorderActive &&
    `
        border-color: ${theme.brandPrimary};
    `}
  background-color: ${({ theme }) => theme.grey100};
  border-radius: 4px;
  display: flex;
  align-items: center;

  ${({ sizeInput }) =>
    sizeInput === "normal" &&
    `
    width: 160px;
  `}

  ${({ sizeInput }) =>
    sizeInput === "small" &&
    `
    width: 120px;
  `}

  ${({ sizeInput }) =>
    sizeInput === "large" &&
    `
    width: 200px;
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}

  & > input {
    width: 100%;
    height: 100%;
    padding-left: 8px;
    color: ${({ theme }) => theme.textcolorWhite};
    background-color: transparent;
    border: none;
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};

    &:focus {
      outline: none;
    }

    &:read-only,
    &:disabled {
      background-color: #f7f7f7;
    }
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.brandPrimary};
  }
`;

export const UnitWrapper = styled.div`
  margin: 0 8px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.height};
  background-color: ${({ theme }) => theme.grey100};
`;
