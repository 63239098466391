import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const depositContractSelector = (state: RootState) =>
  state.depositContract;

export const activeTabSelector = createSelector(
  depositContractSelector,
  (state) => state.activeTab
);

export const listDepositContractSelector = createSelector(
  depositContractSelector,
  (state) => state.list
);
export const listDepositContractLiquidateModalSelector = createSelector(
  depositContractSelector,
  (state) => state.listLiquidateModal
);

export const listInterestRateItemSelector = createSelector(
  depositContractSelector,
  (state) => state.listInterestRateItem
);
export const depositContractDetailModalSelector = createSelector(
  depositContractSelector,
  (state) => state.detailModal
);

export const loadingSearchDataSelector = createSelector(
  depositContractSelector,
  (state) => state.loading
);

export const filterDepositContractSelector = createSelector(
  depositContractSelector,
  (state) => state.filter
);
export const statusLiquidateSelector = createSelector(
  depositContractSelector,
  (state) => state.statusLiquidate
);
export const statusUpdateDepositPaymentSelector = createSelector(
  depositContractSelector,
  (state) => state.statusUpdateDepositPayment
);
export const statusUpdateDepositContractSelector = createSelector(
  depositContractSelector,
  (state) => state.statusUpdateDepositContract
);
export const statusGetDueDateSelector = createSelector(
  depositContractSelector,
  (state) => state.statusGetDueDate
);

// export const createContractEditSelector = createSelector(
//   depositContractSelector,
//   (state) => state.createContract
// );

// export const statusCreateContractSelector = createSelector(
//     depositContractSelector,
//   (state) => state.statusCreateContract
// );
export const itemDetailSelector = createSelector(
  depositContractSelector,
  (state) => state.itemDetail
);
export const itemDetailRowSelector = createSelector(
  depositContractSelector,
  (state) => state.itemDetailRow
);
export const itemEditSelector = createSelector(
  depositContractSelector,
  (state) => state.itemEdit
);
export const itemFormSelector = createSelector(
  depositContractSelector,
  (state) => state.itemForm
);

export const isShowLiquidateModalSelector = createSelector(
  depositContractSelector,
  (state) => state.isShowLiquidateModal
);
export const isShowDetailModalSelector = createSelector(
  depositContractSelector,
  (state) => state.isShowDetailModal
);
export const isShowConfirmModalSelector = createSelector(
  depositContractSelector,
  (state) => state.isShowConfirmModal
);
export const getInterestRateInfoSelector = createSelector(
  depositContractSelector,
  (state) => state.interestRate
);

export const statusInterestRateSelector = createSelector(
  depositContractSelector,
  (state) => state.statusInterestRate
);
export const getInterestAmountInfoSelector = createSelector(
  depositContractSelector,
  (state) => state.interestAmount
);
