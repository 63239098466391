import { FC } from "react";
import { useIntl } from "react-intl";
import ManageContentLeft from "./contentLeft";
import ManageContentRight from "./contentRight";
import NavigatonSubscribe from "./contentRight/navigation";
import Container from "components/commons/Container";
import { SUBSCRIBE_TABS } from "modules/subscribeBuy/constants";
import { selectTab } from "modules/subscribeBuy/selectors";
import { useSelector } from "react-redux";

const SubscribeBuy: FC = () => {
  const intl = useIntl();
  const activeTab = useSelector(selectTab);

  const arrayForm =
    activeTab === SUBSCRIBE_TABS.SUBSCRIBE
      ? [ManageContentRight, ManageContentLeft]
      : [ManageContentRight];

  return (
    <Container
      headerHistory={NavigatonSubscribe}
      headerForm={intl.formatMessage({
        id: "subscribe.titleLeft",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default SubscribeBuy;
