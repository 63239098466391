import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const marginSecuritiesSelector = (state: RootState) =>
  state.marginSecurities;

export const marginSecuritiesListSelector = createSelector(
  marginSecuritiesSelector,
  (state) => state.marginSecuritiesList
);

export const marginSecuritiesLoadingSelector = createSelector(
  marginSecuritiesSelector,
  (state) => state.marginSecuritiesLoading
);

export const marginSecuritiesFilterSelector = createSelector(
  marginSecuritiesSelector,
  (state) => state.marginSecuritiesFilter
);

export const activeTabSelector = createSelector(
  marginSecuritiesSelector,
  (state) => state.activeTab
);
