/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useState, useEffect } from "react";
import { Button, Input } from "components/commons";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import {
  updateModalConfirmRequest,
  getEarlyInterestRateRequest,
  getInterestAmount2Request,
  updateDepositPaymentRequest,
  searchDepositContractRequest,
  //   updateMrgContractPaymentRequest,
  //   findMarginContractDebitWatchRequest,
  //   updateLoanTrackingFilter,
  updateItemEdit,
  resetValueModal,
} from "modules/deposit-contract/redux";
import {
  isShowConfirmModalSelector,
  statusUpdateDepositPaymentSelector,
  filterDepositContractSelector,
  getInterestRateInfoSelector,
  statusInterestRateSelector,
  getInterestAmountInfoSelector,
  //   loanTrackingDetailRowSelector,
  //   loanTrackingInfoMrgDebitSelector,
  itemEditSelector,

  //   loanTrackingStatusUpdateMrgContractPaymentSelector,
  //   loanTrackingFilterSelector,
} from "modules/deposit-contract/selectors";
import {
  IGetEarlyInterestRateParams,
  IGetInterestAmountParams,
  IUpdateDepositPaymentParams,
  ISearchDepositContractParams,
} from "core/http/apis/deposit-contract/types";
import numeral from "numeral";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "components/commons/Toast";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  DetailInputWrapper,
  DetailRowButton,
  BodyModalRowTB,
} from "./styles";

import { convertNumberToDateFormat } from "helper/utils";

import { updateModalOtpLiquidRequest } from "modules/advance/redux";
import { isShowOtpModalLiquidSelector } from "modules/advance/selectors";
import { EBusinessCd } from "helper/consts";

import { OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { DepositContract } from "domain/models/DepositContract";
import { tradeDateSelector } from "modules/auth/selectors";
interface Props {
  itemDetailRow: DepositContract;
}

function ConfirmModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const isVisible = useSelector(isShowConfirmModalSelector);
  const tradeDate = useSelector(tradeDateSelector);
  // const detailRow = useSelector(itemDetailRowSelector);
  const itemEdit = useSelector(itemEditSelector);
  const isVisibleOtp = useSelector(isShowOtpModalLiquidSelector);
  const themeType = useSelector(themeTypeSelector);
  const interestRate = useSelector(getInterestRateInfoSelector);
  const statusInterestRate = useSelector(statusInterestRateSelector);
  const interestAmount = useSelector(getInterestAmountInfoSelector);
  const statusUpdateDepositPayment = useSelector(
    statusUpdateDepositPaymentSelector
  );
  const { fromDate, toDate, subAccount } = useSelector(
    filterDepositContractSelector
  );
  //   const result = useSelector(loanTrackingInfoMrgDebitSelector);
  //   const itemEdit = useSelector(loanTrackingItemEditSelector);
  //   const { fromDate, toDate, subAccoNo } = useSelector(
  //     loanTrackingFilterSelector
  //   );
  //   const statusUpdate = useSelector(
  //     loanTrackingStatusUpdateMrgContractPaymentSelector
  //   );

  const closeModalHandler = () => {
    dispatch(updateItemEdit({ amount: "" }));
    dispatch(resetValueModal());
    dispatch(updateModalConfirmRequest({ isVisible: false }));
  };
  const clickAcceptHandler = () => {
    if (itemEdit && Object.keys(itemEdit).length > 0) {
      if (
        itemEdit.amount === null ||
        itemEdit.amount === undefined ||
        itemEdit.amount === ""
      ) {
        return toast("holdUnholdBankAccount.message.amountCheck1", "error");
      } else if (itemEdit.amount === 0) {
        return toast("holdUnholdBankAccount.message.amountCheck2", "error");
      }
    }
    setVisibleModalConfirm(true);
  };
  const handleCloseOTPModal1 = () => {
    dispatch(updateModalOtpLiquidRequest({ isVisible: false }));
  };

  useEffect(() => {
    if (statusUpdateDepositPayment) {
      const submitData: ISearchDepositContractParams = {
        custNo: subAccount ? subAccount : "",
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        contractSts: 0,
      };
      dispatch(searchDepositContractRequest(submitData));
      closeModalHandler();
    }
  }, [statusUpdateDepositPayment]);
  const doSubmitAdvance1 = (response: IVerifyResponse) => {
    dispatch(updateModalOtpLiquidRequest({ isVisible: false }));

    const submitData: IUpdateDepositPaymentParams = {
      alloDate:
        itemDetailRow && itemDetailRow.alloDate ? itemDetailRow.alloDate : 0,
      refNo: itemDetailRow && itemDetailRow.refNo ? itemDetailRow.refNo : 0,
      subAccoNo:
        itemDetailRow && itemDetailRow.subAccoNo ? itemDetailRow.subAccoNo : "",
      daysNo:
        itemDetailRow && itemDetailRow.endDate && itemDetailRow.fromDate
          ? moment(moment(tradeDate, "YYYYMMDD").format("YYYY-MM-DD")).diff(
              moment(
                moment(itemDetailRow.fromDate, "YYYYMMDD").format("YYYY-MM-DD")
              ),
              "days"
            )
          : 0,
      payAmt: itemEdit && itemEdit.amount ? +itemEdit.amount : 0,
      remarks:
        itemDetailRow && itemDetailRow.remarks ? itemDetailRow.remarks : "",
      // srcChannel:
      //   itemDetailRow && itemDetailRow.srcChannel
      //     ? itemDetailRow.srcChannel.slice(0, 1)
      //     : "",
      srcChannel: "2",
      updDateTime:
        itemDetailRow && itemDetailRow.updDateTime
          ? itemDetailRow.updDateTime
          : 0,
      otpCode: response?.otp ?? "",
      otpType: response?.otpTypeSubmit!,
    };
    dispatch(updateDepositPaymentRequest(submitData));
  };

  const setConfirm = () => {
    dispatch(updateModalOtpLiquidRequest({ isVisible: true }));

    setVisibleModalConfirm(false);
  };
  const changeAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;
    dispatch(
      updateItemEdit({ amount: convertToNumber ? +convertToNumber : "" })
    );
  };
  const blurAmountHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;
    let amount = convertToNumber ? +convertToNumber : 0;
    if (amount > 0) {
      const submitData: IGetEarlyInterestRateParams = {
        fromDate:
          itemDetailRow && itemDetailRow.fromDate ? itemDetailRow.fromDate : 0,
        subAccoCd:
          itemDetailRow && itemDetailRow.subAccoCd
            ? itemDetailRow.subAccoCd
            : 0,
        amount: amount ?? 0,
        interestId:
          itemDetailRow && itemDetailRow.interestRateId
            ? itemDetailRow.interestRateId
            : 0,
      };

      dispatch(getEarlyInterestRateRequest(submitData));
    } else {
      dispatch(updateItemEdit({ amount: "" }));
      dispatch(resetValueModal());
    }
  };
  const keyUpAmountHandler = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.stopPropagation();
      let convertToNumber =
        e?.target?.value
          .replaceAll(",", "")
          .replaceAll(".", "")
          .replace(/[^0-9.]/g, "") ?? 0;
      let amount = convertToNumber ? +convertToNumber : 0;
      if (amount > 0) {
        const submitData: IGetEarlyInterestRateParams = {
          fromDate:
            itemDetailRow && itemDetailRow.fromDate
              ? itemDetailRow.fromDate
              : 0,
          subAccoCd:
            itemDetailRow && itemDetailRow.subAccoCd
              ? itemDetailRow.subAccoCd
              : 0,
          amount: amount ?? 0,
          interestId:
            itemDetailRow && itemDetailRow.interestRateId
              ? itemDetailRow.interestRateId
              : 0,
        };

        dispatch(getEarlyInterestRateRequest(submitData));
      } else {
        dispatch(updateItemEdit({ amount: "" }));
        dispatch(resetValueModal());
      }
    }
  };

  useEffect(() => {
    if (statusInterestRate) {
      const submitData2: IGetInterestAmountParams = {
        amount: itemEdit && itemEdit.amount ? +itemEdit.amount : 0,
        inRate:
          interestRate && interestRate.grossRate ? interestRate.grossRate : 0,
        outRate:
          interestRate && interestRate.grossRate ? interestRate.grossRate : 0,
        fromDate:
          itemDetailRow && itemDetailRow.fromDate ? itemDetailRow.fromDate : 0,
        toDate:
          itemDetailRow && itemDetailRow.endDate ? itemDetailRow.endDate : 0,
        expDate:
          itemDetailRow && itemDetailRow.endDate ? itemDetailRow.endDate : 0,
        calculate:
          itemDetailRow && itemDetailRow.calculate
            ? itemDetailRow.calculate
            : 0,
        decimalPoint:
          itemDetailRow && itemDetailRow.decimalPoint
            ? itemDetailRow.decimalPoint
            : 0,
        roundType:
          itemDetailRow && itemDetailRow.roundType
            ? itemDetailRow.roundType
            : 0,

        feeDays: 0,
      };

      dispatch(getInterestAmount2Request(submitData2));
    }
  }, [statusInterestRate]);

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            {intl.formatMessage({
              id: "depositContract.confirmModal.title",
            })}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-4">
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.subAccoNo" />
            </LabelCell>
            <TextCell>{itemDetailRow?.subAccoNo}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.contractNo" />
            </LabelCell>

            <TextCell>{itemDetailRow?.contractNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.amount" />
            </LabelCell>

            <TextCell>{numeral(itemDetailRow?.amount).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.balance" />
            </LabelCell>

            <TextCell>
              {numeral(
                itemDetailRow &&
                  itemDetailRow.amount - itemDetailRow.payedAmount
              ).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.fromDate" />
            </LabelCell>

            <TextCell>
              {convertNumberToDateFormat({ date: itemDetailRow?.fromDate })}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.tradeDate" />
            </LabelCell>

            <TextCell>
              {/* {convertNumberToDateFormat({
                date: parseInt(moment(new Date()).format("YYYYMMDD")),
              })} */}
              {moment(tradeDate, "YYYYMMDD").format("DD/MM/YYYY")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.countLoanDay" />
            </LabelCell>

            <TextCell>
              {moment(
                moment(
                  parseInt(moment(tradeDate).format("YYYYMMDD")),
                  "YYYYMMDD"
                ).format("YYYY-MM-DD")
              ).diff(
                moment(
                  moment(itemDetailRow?.fromDate, "YYYYMMDD").format(
                    "YYYY-MM-DD"
                  )
                ),
                "days"
              )}
            </TextCell>
          </BodyModalRow>
          <BodyModalRowTB>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.amountLiquidateL" />
            </LabelCell>

            <DetailInputWrapper>
              <div className="text-white">
                <Input
                  value={
                    itemEdit?.amount &&
                    numeral(itemEdit?.amount ?? 0).format("0,0")
                  }
                  onChange={changeAmountHandler}
                  onBlur={blurAmountHandler}
                  onKeyUp={keyUpAmountHandler}
                  placeholder={intl.formatMessage({
                    id: "moneyTransfer.enterAmount",
                  })}
                />
              </div>
            </DetailInputWrapper>
          </BodyModalRowTB>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.grossRate" />
            </LabelCell>

            <TextCell>
              {interestRate && interestRate.grossRate
                ? interestRate.grossRate * 100
                : 0}
              %
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.netRate" />
            </LabelCell>
            <TextCell>
              {interestRate && interestRate.netRate
                ? interestRate.netRate * 100
                : 0}
              %
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="depositContract.confirmModal.interest" />
            </LabelCell>
            <TextCell>{numeral(interestAmount ?? 0).format("0,0")}</TextCell>
          </BodyModalRow>
          {/* <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="contractDebit.loanTrackingTab.confirmModal.availableBalance" />
            </LabelCell>
            <TextCell>{result?.[2]}</TextCell>
          </BodyModalRow> */}

          <DetailRowButton>
            <Button
              color="accept"
              className="button-filter w-100 m-1"
              onClick={closeModalHandler}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "depositContract.confirmModal.cancel",
              })}
            </Button>
            <Button
              style={{
                background: `${themes[themeType].buttonGradientColor}`,
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
              className="button-filter m-1 w-100"
              onClick={clickAcceptHandler}
              fill={true}
            >
              {intl.formatMessage({
                id: "depositContract.confirmModal.accept",
              })}
            </Button>
          </DetailRowButton>
        </BodyModal>
      </Wrapper>
      {itemDetailRow && (
        <ModalConfirm
          visibleModalConfirm={visibleModalConfirm}
          setVisibleModalConfirm={setVisibleModalConfirm}
          setConfirm={setConfirm}
          title={intl.formatMessage({ id: "common.confirm" })}
          content={intl.formatMessage(
            { id: "depositContract.message.confirmContent" },
            { contractNo: itemDetailRow.contractNo }
          )}
        />
      )}
      {isVisibleOtp && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal1}
          getResult={doSubmitAdvance1}
          businessCd={EBusinessCd.DEPOSIT}
        />
      )}
    </Modal>
  );
}

export default ConfirmModal;
