import VgaiaHttp from "core/http/singleton/vgaia";
import { IMarginDebitContractWatchResponse } from "domain/models/MarginDebitContractWatch";
import { ISearchMrgContractDebitParams } from "./types";
import { findMarginContractDebitWatchApi } from "./urls";

export const searchMrgContractDebit = async (
  params: ISearchMrgContractDebitParams
): Promise<IMarginDebitContractWatchResponse> => {
  const res = await VgaiaHttp.get<IMarginDebitContractWatchResponse>(
    findMarginContractDebitWatchApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
