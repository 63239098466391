import classNames from "classnames";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";
import { FormattedMessage, useIntl } from "react-intl";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { createSelector } from "reselect";
import { UNIT_NUMBER } from "helper/consts";

const marketCapSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.marketcap
);
const w52lowSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.w52low
);
const w52highSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.w52high
);
const rangeSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.range
);
const listedQtySelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.listedqty
);
const turnoverrateSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.turnoverrate
);
const espSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.esp
);
const peSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.pe
);
const pbSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.pb
);
const psSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.ps
);
const roeSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.roe
);
const dividendamtSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.dividendamt
);
const dividendrateSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) =>
    state?.dividendrate
);

function TickerDetail() {
  const intl = useIntl();
  const marketCap = useSelector(marketCapSelector);
  const w52low = useSelector(w52lowSelector);
  const w52high = useSelector(w52highSelector);
  const range = useSelector(rangeSelector);
  const listedqty = useSelector(listedQtySelector);
  const turnoverrate = useSelector(turnoverrateSelector);
  const esp = useSelector(espSelector);
  const pe = useSelector(peSelector);
  const pb = useSelector(pbSelector);
  const ps = useSelector(psSelector);
  const roe = useSelector(roeSelector);
  const dividendamt = useSelector(dividendamtSelector);
  const dividendrate = useSelector(dividendrateSelector);

  const listData = [
    {
      text: "marketCap",
      value: numeral(marketCap).divide(UNIT_NUMBER.ONE_MILLION).format("0,0"),
      unit: intl.formatMessage({ id: "common.unit" }),
    },
    {
      text: "highestPrice52Week",
      value: `${numeral(w52low).format("0.00")} - ${numeral(w52high).format(
        "0.00"
      )}`,
      unit: "",
    },
    {
      text: "range",
      value: numeral(range).format("0.00"),
      unit: "%",
    },
    {
      text: "sharesOutstanding",
      value: numeral(listedqty)
        .divide(UNIT_NUMBER.ONE_BILLION)
        .format("0,0.00"),
      unit: intl.formatMessage({ id: "common.unit" }),
    },
    {
      text: "turnoverRate",
      value: numeral(turnoverrate).format("0.00"),
      unit: "%",
    },
    {
      text: "earningPerShare",
      value: numeral(esp).format("0,000.00"),
      unit: "",
    },
    {
      text: "priceToEarningRatio",
      value: numeral(pe).format("0.00"),
      unit: "",
    },
    {
      text: "priceToBook",
      value: numeral(pb).format("0.00"),
      unit: "",
    },
    {
      text: "priceToSalesRatio",
      value: numeral(ps).format("0.00"),
      unit: "",
    },
    {
      text: "returnOnEquity",
      value: `${numeral(roe).format("0,0.00")}%`,
      unit: "",
    },
    {
      text: "dividend",
      value: numeral(dividendamt).format("0,000"),
      unit: "",
    },
    {
      text: "dividendYield",
      value: numeral(dividendrate).format("0.00"),
      unit: "%",
    },
  ];
  return (
    <>
      {listData.map((item, idx) => (
        <div
          className={classNames({ "title-info": (idx + 1) % 3 === 0 })}
          key={idx}
        >
          <div className="info-text">
            <FormattedMessage id={`widgets.tickerInfo.${item?.text}`} />
          </div>
          <div className="info-value">
            <VolumeChange value={item?.value} />
            {item?.unit}
          </div>
        </div>
      ))}
    </>
  );
}

export default TickerDetail;
