import { Body, Container, Title, Footer } from "./styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { useSelector } from "react-redux";
interface Props {
  visibleModalConfirm: boolean;
  setVisibleModalConfirm: (visible: boolean) => void;
  setConfirm: (value: boolean) => void;
  title: string;
  content: any;
  handleCloseModal?: () => void;
}

function ModalConfirm({
  visibleModalConfirm,
  setVisibleModalConfirm,
  setConfirm,
  title,
  content,
  handleCloseModal,
}: Props) {
  const themeType = useSelector(themeTypeSelector);
  const closeModalConfirm = () => {
    setVisibleModalConfirm(false);
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  const handleConfirm = () => {
    setConfirm(true);
    closeModalConfirm();
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  return (
    <>
      <Modal
        show={visibleModalConfirm}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-434"
      >
        <Container>
          <Title>{title.toUpperCase()}</Title>
          <Body>
            <div>{content}</div>
          </Body>
          <Footer>
            <Button
              color="accept"
              className="button-confirm"
              width="100%"
              onClick={closeModalConfirm}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={handleConfirm}
              className="button-confirm"
              fill
              width="100%"
              style={{
                background: `${themes[themeType].buttonGradientColor}`,
              }}
            >
              <FormattedMessage id="common.accept" />
            </Button>
          </Footer>
        </Container>
      </Modal>
    </>
  );
}

export default ModalConfirm;
