import { useState, useEffect } from "react";
import numeral from "numeral";
import { usePrevious } from "helper/cusHooks";
import { TIME_BLINK } from "./constant";
import { BlinkCellWrapper } from "./styles";

function BlinkableCell({ value, unit }: any) {
  const prevCellValue = usePrevious(value);
  const [blinkClass, setBlinkClass] = useState<string | undefined>(undefined);

  useEffect(() => {
    const cellValue = numeral(value).value() || 0;
    const prevCellValueAsNumber = numeral(prevCellValue).value() || 0;

    if (!prevCellValueAsNumber) return;

    if (cellValue > prevCellValueAsNumber) {
      setBlinkClass("cell-blink-up");
      setTimeout(() => {
        setBlinkClass(undefined);
      }, TIME_BLINK);
    }

    if (cellValue < prevCellValueAsNumber) {
      setBlinkClass("cell-blink-down");
      setTimeout(() => {
        setBlinkClass(undefined);
      }, TIME_BLINK);
    }

    return () => {};
  }, [value, prevCellValue]);

  return (
    <BlinkCellWrapper className={blinkClass}>
      {value} {unit && unit}
    </BlinkCellWrapper>
  );
}

export default BlinkableCell;
