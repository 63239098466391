export const FLEX = {
  START: "flex-start",
  CENTER: "center",
  END: "flex-end",
};

export enum SORT_BY {
  TICKER = 1, //	1:Mã CK, 2: gia khop, 3: change_percent
  PRICE = 2,
  CHANGED_PRICE_PERCENTAGE = 3,
}

export const COLUMNS = [
  {
    width: 60,
    name: "MÃ CK/TÊN CÔNG TY",
    position: "START",
    sortBy: SORT_BY.TICKER,
    flexCell: 3,
  },
  {
    width: 20,
    name: "GIÁ KHỚP",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  // {
  //   width: 66,
  //   name: "THAY ĐỔI",
  //   position: "END",
  //   sortBy: SORT_BY.CHANGED_PRICE,
  // },
  {
    width: 20,
    name: "%",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const TIME_BLINK = 600;
