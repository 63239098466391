const errors = {
  defaultErr: "Đã có lỗi xảy ra, vui lòng kiểm tra lại",
  errorServer: "Connect server fail",
  MSG9999: "System Error",
  MSG1001: "System Error",
  MSG1002: "Securities Code not existed",
  MSG1003: "Price must be between floor-ceiling",
  MSG1004: "Price must be between floor-ceiling",
  MSG1005: "Invalid trading pin code. Please try again",
  MSG1006: "Invalid Status",
  MSG1007: "Invalid birthday",
  MSG1008: "Invalid issue date",
  MSG1009: "Invalid Status",
  MSG1010: "Not yet set up trading online time of board @0",
  MSG1011: "Out of trading online time,you can not place orders",
  MSG1012: "ID must be not null",
  MSG1013: "Sec Code must be not empty",
  MSG1014: "The lengh of Sec code is not over @0 characters",
  MSG1015: "Data is not existed",
  MSG1016: "Password must be not empty",
  MSG1017: "Session timeout, you must login to continue",
  MSG1018: "Sec Code stopped trading",
  MSG1019: "Not enough room to execute order",
  MSG1020: "New Sec code does not allow to order",
  MSG1021: "Account does not allow to access system",
  MSG1022: "Invalid account MSG0011",
  MSG1023: " Max lengh of Account is 20 characters",
  MSG1024: "Account Type is not valid",
  MSG1025: "System does support channel @0",
  MSG1026: "Account does not register the number phone to receive message",
  MSG1027:
    "Account has not been provided the matrix. Please contact to caring staff for assistance.",
  MSG1028: "Input data is not valid",
  MSG1029: "From Date is not valid",
  MSG1030: "To Date is not valid",
  MSG1031: "From Date and To Date is not valid",
  MSG1032: "Login password must be not empty",
  MSG1033: "User Name must be not empty",
  MSG1034: "User Type is not valid",
  MSG1035: "IP address is locked, please try again after 15 minutes",
  MSG1036: "Input Price is not valid",
  MSG1037: "Do not support to place odd lot order",
  MSG1038: "Input Quantity is not valid",
  MSG1039: "List order is not valid",
  MSG1040: "Suggestion sell price must be greater than 0",
  MSG1041: "Suggestion buy price must be greater than 0",
  MSG1042: "Reg date is not valid",
  MSG1043: "Input data is not valid",
  MSG1044: "Order No of order is not valid",
  MSG1045: "Transaction Date is not valid",
  MSG1046: "Not found information of order to cancel",
  MSG1047: "Order has been changed, Please refresh data",
  MSG1048: "Status of order is not valid to cancel",
  MSG1049: "Not found information of B145e securities @0",
  MSG1050: "Not found information of board @0",
  MSG1051: "Pub price is not valid to edit order",
  MSG1052: "Pub quantity is not valid to edit order",
  MSG1053: "New price is not valid",
  MSG1054: "New quantity is not valid",
  MSG1055: "Sell account is not valid",
  MSG1056: "Buy account is not valid",
  MSG1057: "Transaction Date is not valid",
  MSG1058: "Information of board is not valid",
  MSG1059: "Order No is not valid",
  MSG1060: "Not found information of order to cancel",
  MSG1061: "Status of order is not valid to cancel",
  MSG1062: "Not found information of put throught request",
  MSG1063: "Status of order is not valid to execute",
  MSG1064: " From Date must be not empty",
  MSG1065: "To Date must be not empty",
  MSG1066: "From Date must be less than To date",
  MSG1067: "Market is closed, can not place order",
  MSG1068: "Market status is not valid to place order",
  MSG1069: "Session AVAILABLE that System does not allow to place order",
  MSG1070:
    "Session CALL_AUCTION_OPENING that System does not allow to edit or cancel order",
  MSG1071: "Do not allow to place order at the before opening",
  MSG1072: "Session @0 does not allow to edit order",
  MSG1073: "Session @0 does not allow to edit or cancel  for order @1",
  MSG1074: "Sec Code is stopped trading in holiday",
  MSG1075: "Sec Code is suspended trading",
  MSG1076: "LDAP Service URL not found",
  MSG1077: "No results returned from the LDAP authentication system",
  MSG1078: "Get faile results returned from the LDAP authentication system",
  MSG1079: "Sec Code is suspended trading",
  MSG1080: "Sec Code is limited trading",
  MSG1081: "Sec Code is expired",
  MSG1082: "Member Code received put throught is not valid",
  MSG1083: "ATC session does not allow to place order @0",
  MSG1084: "ATC session does not allow to edit order @0",
  MSG1085: "ATO session does not allow to place order @0",
  MSG1086: "ATO session does not allow to edit or cancel order @0",
  MSG1087: "ATO does not allow to edit order @0",
  MSG1088: "Buy/Sell invalid",
  MSG1089: "Input Quantity is not valid",
  MSG1090: "Input Price is not valid",
  MSG1091: "Not found put throught request number @0",
  MSG1092: "User has not been provided the matrix",
  MSG1093: "User ID is not valid",
  MSG1094: "Account Type is not valid",
  MSG1095: "Invalid ID number or passport",
  MSG1096: "Invalid issue date",
  MSG1097: "Phone number/Email must be not empty",
  MSG1098: "Not found user in the system",
  MSG1099:
    "IP address is locked. Please contact to caring staff for assistance.",
  MSG1100: "Invalid ID number or passport",
  MSG1101: "Invalid issue date",
  MSG1102:
    "You do not register to use the service. Please contact to caring staff for assistance.",
  MSG1103:
    "The phone number does not match the information registered with the company. Please contact to caring staff for assistance.",
  MSG1104:
    "The email does not match the information registered with the company. Please contact to caring staff for assistance.",
  MSG1105: "Invalid credentials",
  MSG1106: "Not found credentials",
  MSG1107: "Incorrect authentication code. Please try again",
  MSG1108: "Authentication request has expired. Please enter information again",
  MSG1109:
    "Transaction Quantity must not be less than min quantity (Min quantity: @0)",
  MSG1110:
    "Transaction Quantity must not be less than min quantity (Min quantity: @0)",
  MSG1111: "Sub account must be not empty",
  MSG1112: "Bond code must be not empty",
  MSG1113: "Invalid Buy/Sell quantity bond",
  MSG1114: "Invalid Buy/Sell price bond",
  MSG1115: "Invalid Transaction Date",
  MSG1116: "Invalid Buy/Sell Type",
  MSG1117: "Invalid repurchase / resale date",
  MSG1118: "Number of days to repurchase / resale must be greater than @ 0",
  MSG1119: "Number of days to repurchase / resale must be less than @ 0",
  MSG1120: "The system does not support to place bond order board @0",
  MSG1121: "You do not have permission to perform the function @0",
  MSG1122: "Not found put through bond request",
  MSG1123: "ID number or passport existed",
  MSG1124: "Sec code is not in list sec of securities company",
  MSG1125:
    "Currently there is no odd stock purchase of stock companies, please contact us for help.",
  MSG1126:
    "In addition to the retail sale of securities of securities companies, please contact us for help.",
  MSG1127: "Order No existed in database",
  MSG1128:
    "You do not register the phone number or email receive Authentication code",
  MSG1129: "Invalid Expired date",
  MSG1130: "Could not find reciprocal account information ..",
  MSG1131: "Order does not change. Edit order faile",
  MSG1132: "User is not active. Please contact to caring staff for assistance.",
  MSG1133:
    "Account is not active. Please contact to caring staff for assistance.",
  MSG1134: "User ID does not exist in the system",
  MSG1135:
    "Not found the authentication matrix. Please contact to caring staff for assistance.",
  MSG1136:
    "The system does not support to place order at the before openning. Please contact to caring staff for assistance.",
  MSG1137: "Invalid code, please try again",
  MSG1138:
    "Authentication failed. Please contact to caring staff for assistance.",
  MSG1139: "Invalid input data",
  MSG1140: "Invalid order no",
  MSG1141: "Invalid board",
  MSG1142: "Not yet set up quantity board @0",
  MSG1143: "Request time between @0  and @1",
  MSG1144: " Invalid Transaction Date",
  MSG1145: "Can not fine put throught request",
  MSG1146: "Does not support order place in session @0",
  MSG1147: "Password must have character letter (Not case sensitive)",
  MSG1148: "You do not enter floor price",
  MSG1149: "You do not enter basic price",
  MSG1150: "You do not enter celling price",
  MSG1151: " Before openning,system do not support order type @0 with board @1",
  MSG1152: "Sec code does not exist in the system",
  MSG1153:
    "Securities company is pausing buy odd sec of board @0. Please contact to caring staff for assistance.",
  MSG1154:
    "Quantity of Multi/Split command is out of system allowed for limit (@ 0)",
  MSG1155: "Do not enter quantity",
  MSG1156: "Do not enter invest amount",
  MSG1157: "Do not enter average price",
  MSG1158: "Your ID is expired, you can not execute request",
  MSG1159: "Invalid calling flag",
  MSG1160: "You do not enter remarks",
  MSG1161: "Invalid derivative account",
  MSG1162: "Order type of condition order is invalid",
  MSG1163: "Invalid derivative sec code",
  MSG1164: "Derivative sec code has maximum @0 character",
  MSG1165: "Invalid quantity",
  MSG1166: "Invalid stop price",
  MSG1167: "Cut lost amp is invalid",
  MSG1168: "Gross amp is invalid",
  MSG1169: "Invalid cut loss price",
  MSG1170: "Cut lost amp is invalid",
  MSG1171: "Gross amp is invalid",
  MSG1172: "Range stoped is invalid",
  MSG1173:
    "Can not found information of Condition request tradedata: @0, id: @1",
  MSG1174: "Data changed. Please refresh data",
  MSG1175: "You do not enter from date, to date",
  MSG1176: "You must deal with member: @0",
  MSG1177: "Opening call auction does not allow editing/cancelling ATO order",
  MSG1178_100:
    "HNX exchange is not ready to receive orders, please wait a moment",
  MSG1178_200:
    "HSX exchange is not ready to receive orders, please wait a moment",
  MSG1178_300:
    "UPCOM exchange is not ready to receive orders, please wait a moment",
  MSG1178_400:
    "OTC exchange is not ready to receive orders, please wait a moment",
  MSG1220: "CIF number is invalid",
  MSG1221: "CIF number does not exist",
  MSG1222: "IPAY authentication has not been setup",
  MSG1223:
    "You have not registed to use order service. Please contact Customer Service Center",
  MSG1224: "SELL market order set price must be equal to floor price",
  MSG1225: "Buy market order with price equal to the ceiling price",
  MSG1226: "Customer name is invalid",
  MSG1234: "Không tìm thấy thông tin mã chứng quyền @0.",
  MSG1235: "Mã chứng quyền @0 đã hết hạn giao dịch.",
  MSG2001: "Error while connect gate",
  MSG2002: "System cannot connect to exchange",
  MSG2003: "Error while connect BankService",
  MSG2004: "Bank inquiry result incorrect format",
  MSG2005: "Not yet set up address connection of BankGate Service",
  MSG2006: "Do not support action [@0]",
  MSG2007: "Do not support order type @0 with HNX exchange",
  MSG2008: "Do not support order type @0 with HSX exchange",
  MSG2009: "Not yet set up Mailing address (MailSendingQueue)",
  MSG2010: "Not yet set up Queue address to edit price HSX",
  MSG2011: "Not yet set up interval order Queue address",
  MSG2013: "Error while sending interval order",
  MSG2014: "Error while sending mail. Check mail adapter again please",
  MSG2015: "Error while inquiry into bank @0",
  MSG2016S: "Do not allow to edit/cancel order in Before openning session",
  MSG2016P: "Do not allow to edit/cancel order in ATO/ATC session",
  MSG2016I: "Do not allow to edit/cancel order in I session",
  MSG2016A: "Do not allow to edit/cancel order in ATO/ATC session",
  MSG2016C: "Do not allow to edit/cancel order in C session",
  MSG2016K: "Do not allow to edit/cancel order in K session",
  MSG2017: "Do not allow to edit/cancel order type PLO",
  MSG2018:
    "Operation is only supported by the order with status D: Canceled by Securities Company",
  MSG2019: "You have not entered the reason for the status change before now",
  MSG2020: "The state transition reason cannot be more than 200 characters",
  MSG2021: "Character is wrong.Please try again",
  MSG3001: "RequestType must be put through 2Firm",
  MSG3002: "Account No must be not empty",
  MSG3003: "Account No is 13 characters",
  MSG3004: "Sec code must be not empty",
  MSG3005: "Do not leave empty member to buy 2Firm agreement",
  MSG3006: "Do not leave empty agent for 2Firm deal",
  MSG3007: "Order type must be sell",
  MSG3008: "Can not be determined sec price",
  MSG3009: "Can not be determined market status",
  MSG3010: "Can not be determined account no",
  MSG3011: "Can not be determined customer",
  MSG3012: "Trading rules is not found",
  MSG3013: "Price must be between floor-ceiling",
  MSG3014: "Price must be between floor-ceiling",
  MSG3015: "Can not be determined sec price",
  MSG3016: "Step price is not valid",
  MSG3017: "Not yet set up trading lot of board",
  MSG3018: "Volume placed without rounding",
  MSG3019: "Not set up the negotiable mass parameter",
  MSG3020: "Minimum volume to execute the deal is @ 0",
  MSG3021: "Not set up required volume parameters for agreement",
  MSG3022: "Volume is too large to place a quote order",
  MSG3023: "RequestType must be the reply to 2Firm agreement order",
  MSG3024: "Order type must be sell",
  MSG3025: "Order no not found",
  MSG3026: "Bank account  not found",
  MSG3027: "RequestType must be a 2Firm negotiable order modification response",
  MSG3028: "RequestType must be a cancel reply to the 2Firm agreement",
  MSG3029: "ConfirmNo not found",
  MSG3030:
    "You do not have a transfer agreement or the contract has expired. To use the service, please contact the securities company trading department!",
  MSG3031: "Receiving account not in the transfer contract",
  MSG3033: "The operation could not be performed. Contract has changed state",
  MSG3034: "Cannot cancel",
  MSG3035: "Buying account is invalid",
  MSG3036: "Buying account is is only 13 characters",
  MSG3037: "Seeling account is invalid",
  MSG3038: "Seeling account is is only 13 characters",
  MSG3039: "Symbol is invalid",
  MSG3040: "Market code is invalid",
  MSG3041: "Order no is invalid",
  MSG3042: "TraderId is invalid",
  MSG3043: "Stock Price not found with symbol: @0",
  MSG3044: "Market state not found with code: @0",
  MSG3045: "Sub account not found: @0",
  MSG3046: "No permissions set for: @ 0",
  MSG3047: "Access denied",
  MSG3048: "Market code is invalid",
  MSG3049: "Market code must be number",
  MSG3050: "Cannot delete",
  MSG3051: "Symbol has not been entered",
  MSG3052: "Price has not been entered",
  MSG3053: "Quatity has not been entered",
  MSG3054: "Trading is Successful",
  MSG3055: "Trading is error",
  MSG3056: "RequestType must be a 1 firm order",
  MSG3057: "Order data without sell account information",
  MSG3058: "Order data has no stock code information",
  MSG3059: "The order data does not contain buying account information",
  MSG3060: "The deal volume must be greater than 0",
  MSG3061: "The agreed price must be greater than 0",
  MSG3062: "Not found customer no: @0",
  MSG3063: "Branch not found with code: @0",
  MSG3064: "Not found selling sub account: @0",
  MSG3065: "Order role is not setup for market",
  MSG3066: "RequestType must be a Modify 1 Firm agreement order",
  MSG3067: "There are no new conditions",
  MSG3068: "Customer no is invalid",
  MSG3069: "Account number must be greater than 10 characters",
  MSG3070: "Symbol is invalid",
  MSG3071: "ConfirmNo is invalid",
  MSG3072: "Market code must be greater than zero",
  MSG3073: "Transaction is invalid",
  MSG3074: "Data not found",
  MSG3075: "TraderId is invalid",
  MSG3080: "Account has no transfer agreement or is expired",
  MSG3081: "Receiving account is not in the transfer contract",
  MSG3082: "Amount must be less than the available amount",
  MSG3083: "Amount exceeds the remaining limit of the company: @0",
  MSG3084: "The number transfers is greater than the allowed limit",
  MSG3085: "Amount must be greater than the minimum amount @ 0",
  MSG3086: "Amount must be less than the maximum amount @ 0",
  MSG3087: "Invalid transfer time. It's not time to receive the transfer",
  MSG3088: "Invalid transfer period. Transfer receipt time has expired",
  MSG3089: "Invalid execution time. Transfer receipt time has expired",
  MSG3090: "Registration date is invalid",
  MSG3091: "Ref no is invalid",
  MSG3092: "Sub account is invalid",
  MSG3093: "Data has changed. You cannot edit/delete",
  MSG3094: "Status is invalid!",
  MSG3095: "Approvement not found",
  MSG3096: "Reg quantity must be greater than 0.",
  MSG3097: "Registration date is invalid",
  MSG3098: "Reg quantity must be less than the remain quantity",
  MSG3100: "Sub account transfer does not exist",
  MSG3101: "Sub account received does not exist",
  MSG3102:
    "Sub account transfer and Sub account received must belong to the same customer",
  MSG3103: "Symbol does not exist",
  MSG3104: "Stock code is not in the available list",
  MSG3105: "Quantity transfer is greater than the available quantity",
  MSG3106: "Invalid account or account has not stock",
  MSG3107: "Sub account is not empty",
  MSG3108: "Bank account no is not empty",
  MSG3109: "Bank account name is not empty",
  MSG3110: "Amount is not empty",
  MSG3111: "Allo Date is wrong",
  MSG3112: "Allo Date is wrong",
  MSG3113: "Allo Date not found",
  MSG3114: "Operation is not supported",
  MSG3115: "Quantity is not empty",
  MSG3116: "Right info not found",
  MSG3117: "Data does not exist",
  MSG3118: "Sub account transfer and Sub account received cannot be the same",
  MSG3119: "Do not edit the approved record",
  MSG3120: "Do not edit canceled record",
  MSG3121: "Amount cannot be left blank or equal to 0",
  MSG3122: "Maturity cannot be left blank or equal to 0",
  MSG3123: "Term cannot be left blank or equal to 0",
  MSG3124: "Unspecified maturity type",
  MSG3125: "Unspecified pre-maturity interest rate",
  MSG3126: "Interest in term unspecified",
  MSG3127: "Unspecified interest calculation",
  MSG3128: "Decimal point unknown",
  MSG3129: "Unspecified rounding type",
  MSG3130: "Insufficient balance",
  MSG3131: "Unspecified deposit interest calculation setting",
  MSG3132: "Unable to establish interest rate set as at current date",
  MSG3133: "From Date Must be Less Than To Date",
  MSG3134: "Date ranges cannot be duplicated",
  MSG3135: "New addition failed",
  MSG3136: "Log update failed",
  MSG3137: "Broker does not exist",
  MSG3138: "Grant limit must be greater than 0",
  MSG3139:
    "Authorization limit cannot be greater than the maximum limit of user (@ 0)",
  MSG3140: "Quota type cannot be left blank",
  MSG3141: "The availability of broker level must be greater than 0",
  MSG3142: "Brokre Code cannot be left blank",
  MSG3143: "From date is not empty",
  MSG3144: "To date is not empty",
  MSG3145: "Limit value is not empty",
  MSG3146: "Limit value must be greater than 0",
  MSG3147: "UserId logged in does not have permission to edit this record",
  MSG3148: "Invalid edit limit due to new limit <Used limit @ 0",
  MSG3149: "New limit less than broker used limit @ 0",
  MSG3150: "The limit change value is greater than the broker availability",
  MSG3152: "User does not exist",
  MSG3153: "Old password is incorrect",
  MSG3154: "New password trading has been entered",
  MSG3155: "New password trading does not match",
  MSG3156: "New password is the same old password",
  MSG3157: "Amout is greater avail",
  MSG3158: "Number of certificates used",
  MSG3159: "User does not exist",
  MSG3160: "Status is invalid!",
  MSG3161: "Did not declare account number for the branch",
  MSG3162: "Status is invalid!",
  MSG3163: "Market status is invalid.",
  MSG3164: "Market information not found",
  MSG3165: "The amount you transferred hasn't changed, please choose again!",
  MSG3166: "Symbol not found @0",
  MSG3167: "Cannot find exchange market status @ 0",
  MSG3168: "Set up transaction role for exchange @ 0",
  MSG3169: "Cannot find data to be approved or canceled",
  MSG3170: "Error: Exchange online not support",
  MSG3171: "Account is Invalid",
  MSG3172: "Data length (@ 0) must not exceed @ 1",
  MSG3173: "Sub account is invalid",
  MSG3174: "Account is not valid.",
  MSG3175: "Existing system added new OderRole!",
  MSG3176: "System is no longer available updated OrderRole!",
  MSG3177: "Could not identify original advance order",
  MSG3178: "The amount to be advanced exceeds the maximum amount (Max :  @ 0).",
  MSG3179: "Symbol is invalid",
  MSG3180: "Quatity has not been entered",
  MSG3181: "Price has not been entered",
  MSG3182: "Did not enter member list",
  MSG3183: "Invalid user list",
  MSG3184: "Quantity must be a number",
  MSG3185: "The price is not correct format",
  MSG3186: "Request type is invalid.",
  MSG3187: "Customer account number cannot be left blank.",
  MSG3188: "Reciprocal account number cannot be left blank.",
  MSG3189: "Stock symbol is not empty",
  MSG3190: "The floor code cannot be left blank",
  MSG3191: "Order no is not empty",
  MSG3192: "Trader is not empty",
  MSG3193: "The system no longer exists Parameter!",
  MSG3194: "Parameter setting already exists!",
  MSG3195: "Parameter set is incorrect!",
  MSG3196: "Cannot find contract!",
  MSG3197: "SecPrice not found with symbol",
  MSG3198: "Market state not found with code",
  MSG3199: "No subsection exists in the system",
  MSG3200: "Order role is not setup for market",
  MSG3201: "The system has not set up a trading order",
  MSG3202: "Incorrect command type set",
  MSG3203: "Order type is not allowed in the session",
  MSG3204: "Power inquiry is error",
  MSG3205: "Order value must not be greater than buying power",
  MSG3206:
    "Order volume is greater than the total available volume of the warehouse",
  MSG3207: "Order amount is greater than the remaining volume",
  MSG3208: "Order information not found",
  MSG3209: "Completed orders cannot be canceled / edited",
  MSG3210: "Unable to cancel / modify order status",
  MSG3211: "Question no longer exists!",
  MSG3212: "The client does not have enough balance to place an order",
  MSG3213: "Client does not have enough stock balance to place an order",
  MSG3214: "Order has requested to cancel or correct",
  MSG3215: "The transfer amount must be less than the maximum amount @ 0",
  MSG3216: "The amount of available funds needs to be greater than 0",
  MSG3217: "The client's balance is not enough to execute the buy order",
  MSG3218: "The stock balance is not enough to execute the sell order",
  MSG3219: "Could not find original command information",
  MSG3220: "Invalid order status to cancel.",
  MSG3221: "Invalid execution time. It's not time to receive the transfer",
  MSG3222: "Invalid execution time. Transfer receipt time has expired",
  MSG3223: "Order type is invalid",
  MSG3224: "Customer No is not empty",
  MSG3225: "Symbol is not empty",
  MSG3226: "Quantity is not empty",
  MSG3227: "Price is not empty",
  MSG3228: "New quantity is not empty",
  MSG3229: "New price is not empty",
  MSG3230: "Floor information cannot be left blank",
  MSG3231: "OrderNo not found",
  MSG3232: "Exec quantity and exec price changed.",
  MSG3233: "The transfer amount exceeds the customer's remaining limit: @ 0",
  MSG3234:
    "Transfer receipt time has expired. Your transfer will be processed the next day",
  MSG3235: "Invalid transfer transaction date",
  MSG3236: "Quota value must not be less than limit used @ 0",
  MSG3237:
    "You have been blocked for money transfer operation. Your request cannot be made",
  MSG3238: "Cannot find 1Firm order number @ 0",
  MSG3239: "Invalid command status to modify command",
  MSG3240: "Order volume has changed, please refresh the data",
  MSG3241: "The order price has changed, please refresh the data",
  MSG3242: "Could not find the internal transfer parameter",
  MSG3243: "Ngày tạo TT không hợp lệ",
  MSG3244: "Số không hợp lệ",
  MSG3245: "Ngày đăng ký không hợp lệ",
  MSG3246: "Số lượng đăng ký lớn hơn số lượng còn lại",
  MSG3247: "Thành tiền lớn hơn khả dụng",
  MSG3248: "Trạng thái không hợp lệ",
  MSG3250: "Lệnh chọn sửa không còn tồn tại!",
  MSG3251: "Số tiểu khoản nhập vào không còn tồn tại!",
  MSG3252: "Loại lệnh @0 không được thiết lập trong phiên @1.",
  MSG3253: "Loại lệnh @0 không được thiết lập trong phiên @1.",
  MSG3253P: "Order type @0 have not been set in (P) Periodic session.",
  MSG3253I: "Order type @0 have not been set in (I) Intermission session.",
  MSG3253O: "Order type @0 have not been set in (O) Continuous session.",
  MSG3253A:
    "Order type @0 have not been set in (A) Periodic session (ATO/ATC).",
  MSG3253C: "Order type @0 have not been set in (C) Put through session.",
  MSG3254: "Không hỗ trợ sửa giá lệnh báo giá sàn HSX",
  MSG3255: "Không hỗ trợ đặt lệnh lô lẻ với sàn HSX",
  MSG3256: "Trạng thái hệ thống giao dịch không còn tồn tại!",
  MSG3257: "Chưa kết thúc giao dịch.",
  MSG3258: "Không phải lệnh thanh toán.",
  MSG3259: "Không được phép đặt cùng lúc Bán/Mua cho một tài khoản giao dịch",
  MSG3260: "Không được phân bổ hạn mức cho tài khoản của chính mình",
  MSG3261: "Lệnh chọn không còn tồn tại!",
  MSG3262: "Trạng thái không hợp lệ!",
  MSG3263: "Trạng thái thị trường không hợp lệ để thực hiện hủy lệnh",
  MSG3264: "Mã chứng khoán truyền lên bị lỗi!",
  MSG3265: "Thông tin người dùng trên lên bị lỗi!",
  MSG3266: "Thiết lập cảnh báo đã tồn tại!",
  MSG3267: "Chưa thiết lập địa chỉ Gateway.",
  MSG3268: "Thiệt lập cảnh báo không còn tồn tại!",
  MSG3269: "Người dùng không được cấp quyền @0.",
  MSG3270: "Bạn không có quyền quản lý tài khoản @0",
  MSG3271:
    "Người dùng @0 được cấp quyền @1 thực hiện với tiểu khoản @2 tối đa @3",
  MSG3272: "Không tìm thấy thông tin tiểu khoản @0",
  MSG3273: "Không tồn tại mã chứng khoán @0",
  MSG3274: "Mã chứng khoán @0 không có trong danh mục HTCK",
  MSG3275: "Khả dụng kho @0 không đủ để thực hiện giao dịch",
  MSG3276: "Hệ thống không hỗ trợ sửa lệnh HTCK sàn @0",
  MSG3277: "Không tìm thấy thông tin lệnh gốc của deal.",
  MSG3278: "Khối lượng chờ khớp đã hết, bạn không thể sửa/hủy lệnh.",
  MSG3279: "Khối lượng chờ khớp đã thay đổi, bạn hãy làm mới dữ liệu.",
  MSG3280: "Thao tác vượt quá giá trị cho phép (giá trị tối đa : @0).",
  MSG3281: "Loại yêu cầu không phải là lệnh báo giá",
  MSG3282: "Loại lệnh không được phép trống",
  MSG3283: "Mã chứng khoán không được phép để trống",
  MSG3284: "Số tài khoản đặt không được phép trống",
  MSG3285: "Giá đặt lệnh phải lớn hơn 0",
  MSG3286: "Khối lượng đặt lệnh phải lớn hơn 0",
  MSG3287: "Độ dài của loại lệnh không vượt quá @0 ký tự",
  MSG3288: "Độ dài mã chứng khoán không vượt quá @0 ký tự",
  MSG3289: "Độ dài của số tiểu khoản không được vượt quá @0 ký tự",
  MSG3290: "hông tin mã thành viên giao dịch không được để trống",
  MSG3291: "Mã thành viên giao dịch không được vượt quá @0 ký tự",
  MSG3292: "Lỗi vấn tin tài khoản @0",
  MSG3293: "Tài khoản @0 không đủ số dư tiền để đặt lệnh",
  MSG3294: "Hệ thống không hỗ trợ giao dịch lệnh trong phiên @0",
  MSG3295: "Mã trader giao dịch không được phép trống",
  MSG3296: "Mã trader id không được phép vượt quá @0 ký tự",
  MSG3297: "BrokerCd không được để trống",
  MSG3298: "Loại hạn mức không đúng định dạng",
  MSG3299: "Từ ngày không đúng định dạng",
  MSG3300: "Đến ngày không đúng định dạng",
  MSG3301: "BrokerCd không đúng định dạng",
  MSG3302: "Hạn mức phân bổ vượt quá giới hạn cho phép",
  MSG3303: "Ngày nhập vào không đúng",
  MSG3304: "Người dùng không có quyền thực hiện với tài khoản @0",
  MSG3305: "Bạn chưa chọn kho",
  MSG3306: "Mã chứng khoán không được vượt quá 20 ký tự",
  MSG3307: "Loại lệnh không được để trống",
  MSG3308: "Giá lệnh đặt phải lớn hơn 0",
  MSG3309: "Khối lượng để đặt lệnh phải lớn hơn 0",
  MSG3310: "UserId không được quá @0 ký tự",
  MSG3311: "UserId không được phép null",
  MSG3312: "UserType không được quá @0 ký tự",
  MSG3313: "UserType không được phép null",
  MSG3314: "USER_LOCK_FLAG không được quá @0 ký tự",
  MSG3315: "USER_LOCK_FLAG không được phép null",
  MSG3316: "USER_TRADING_FLAG không được quá @0 ký tự",
  MSG3317: "USER_TRADING_FLAG không được phép null",
  MSG3318: "MenuId không được quá @0 ký tự",
  MSG3319: "MenuId không được phép null",
  MSG3320: "Password mới không được quá @0 ký tự",
  MSG3321: "Password mới không được phép null",
  MSG3322: "Id number không được quá @0 ký tự",
  MSG3323: "Không tìm thấy mã lệnh",
  MSG3324: "Không tìm thấy ngày giao dịch",
  MSG3325: "Độ dài trường ghi chú không được vượt quá 200 ký tự",
  MSG3326: "FirmNo nhập vào không được vượt quá @0 ký tự",
  MSG3327: "Mã chứng khoán nhập vào không được vượt quá @0 ký tự",
  MSG3328: "Số tiểu khoản nhập vào không được vượt quá @0 ký tự",
  MSG3329: "CUST_CD truyền vào không được phép null",
  MSG3330: "CUST_CD truyền vào không được vượt quá @0 ký tự",
  MSG3331: "STATUS truyền vào không được phép null",
  MSG3332: "STATUS truyền vào không được phép vượt quá @0 ký tự",
  MSG3333: "ID_NUMBER không được phép null",
  MSG3334: "APPROVAL_CD không được phép null",
  MSG3335: "APPROVAL_CD không được phép vượt quá @0 ký tự",
  MSG3336: "Chi nhánh không được phép null",
  MSG3337: "Số CMT không được phép null",
  MSG3338: "Parent Broker Cd không được phép null",
  MSG3339: "Chi nhánh truyền vào không được phép vượt quá @0 ký tự",
  MSG3340: "Số CMT truyền vào không được phép vượt quá @0 ký tự",
  MSG3341: "Mã Broker truyền vào không được phép vượt quá @0 ký tự",
  MSG3342: "Broker quản lý truyền vào không được phép vượt quá @0 ký tự",
  MSG3343: "Không tìm thấy mã nhóm khách hàng",
  MSG3344: "Mã Broker phải có độ dài 5 ký tự",
  MSG3345: "Broker quản lý phải có độ dài 5 ký tự",
  MSG3346: "Broker quản lý không tồn tại",
  MSG3347: "Từ ngày phải nhỏ hơn Đến ngày",
  MSG3348: "Số hiệu lệnh sàn tối đa 30 ký tự",
  MSG3349: "Mã chứng khoán tối đa 20 ký tự",
  MSG3350: "Broker tối đa 10 ký tự",
  MSG3351: "Số tài khoản chỉ tối đa 13 ký tự",
  MSG3352: "Từ ngày không đúng",
  MSG3353: "Đến ngày không không đúng",
  MSG3354: "Không tìm thấy bankCd",
  MSG3355: "Không tìm thấy bankBrandCd",
  MSG3356: "Tiền ứng trước phải lớn hơn 0",
  MSG3357: "Tiền ứng trước phải nhỏ hoặc bằng 18 số",
  MSG3358: "Giá trị tham số không được phép vượt quá @0 ký tự",
  MSG3359: "Ghi chú không được phép vượt quá @0 ký tự",
  MSG3360: "Ref no không được phép null",
  MSG3361: "Ref no không được phép vượt quá @0 ký tự",
  MSG3362: "Từ giá trị không được phép vượt quá @0 ký tự",
  MSG3363: "Đến giá trị không được phé vượt quá @0 ký tự",
  MSG3364: "Khối lượng không được lớn hơn 23 ký tự",
  MSG3365: "Trường giá không được vượt quá 13 ký tự",
  MSG3366: "Danh sách thành viên không được vượt quá 65 ký tự",
  MSG3367: "Ghi chú không được vượt quá 200 ký tự",
  MSG3368: "Firm No không được vượt quá 3 ký tự",
  MSG3369: "Bạn chưa nhập mật khẩu đăng nhập hiện tại",
  MSG3370: "Mật khẩu đăng nhập mới trùng mật khẩu hiện tại",
  MSG3371: "Mật khẩu đăng nhập mới không được quá @0 ký tự",
  MSG3372: "Ngày chốt không đúng định dạng.",
  MSG3373: "Ngày thực hiện không đúng định dạng.",
  MSG3374: "Ngày giao dịch không đúng định dạng.",
  MSG3375: "Trường tháng chỉ nhập tối đa 7 ký tự",
  MSG3376: "Trường broker chỉ nhập tối đa 10 ký tự",
  MSG3378: "Loại chứng khoán không được để trống",
  MSG3379: "Số tiền không được vượt quá 18 ký tự",
  MSG3380: "Giá nhập vào phải lớn hơn 0",
  MSG3381: "Giá nhập vào không được vượt quá 10 ký tự",
  MSG3382: "Giấy CNSHCK nhập vào không quá 20 ký tự",
  MSG3383: "Từ khóa nhập vào không được quá @0 ký tự",
  MSG3384: "Bạn chưa nhập Tiêu đề câu hỏi",
  MSG3385: "Tiêu đề nhập vào không được quá @0 ký tự",
  MSG3386: "Bạn chưa nhập Nội dung câu hỏi",
  MSG3387: "Nội dung câu hỏi không được quá @0 ký tự",
  MSG3388: "Bạn chưa nhập Nội dung câu trả lời",
  MSG3389: "Nội dung câu trả lời không được quá @0 ký tự",
  MSG3390: "Tên tài khoản nhận không vượt được quá @0 ký tự",
  MSG3391: "Tên ngân hàng không được vượt quá @0 ký tự",
  MSG3392: "Độ dài trường phí chuyển khoản không được vượt quá @0 ký tự",
  MSG3393: "Độ dài trường phí SMS không được vượt quá @0 ký tự",
  MSG3394: "Ngày tạo TT là bắt buộc.",
  MSG3395: "Trường số là bắt buộc.",
  MSG3396: "Trường số không được nhập quá 6 ký tự số.",
  MSG3397: "Ngày đăng ký là bắt buộc.",
  MSG3398: "Ngày đăng ký không hợp lệ",
  MSG3399: "Số lượng đăng ký không được nhập quá 18 ký tự.",
  MSG3400: "Ngày yêu cầu cần được phải nhập đúng định dạng ngày",
  MSG3401: "Tiểu khoản chuyển không được để trống",
  MSG3402: "Tiểu khoản nhận không được để trống",
  MSG3403: "Độ dài tiểu khoản chuyển không được vượt quá @0 ký tự",
  MSG3404: "Độ dài tiểu khoản nhận không được vượt quá @0 ký tự",
  MSG3405: "Độ dài số lượng chứng khoán chuyển không được vượt quá @0 ký tự",
  MSG3406: "Trade type không được phép để trống",
  MSG3407: "Giá nhập vào không được quá @0 chữ số",
  MSG3408: "Khối lượng nhập vào không được quá @0 chữ số",
  MSG3409: "TRADE_DATE không được phép để trống",
  MSG3410: "REQ_NO không được phép để trống",
  MSG3411: "Giá trị thế chấp không được vượt quá 1 ký tự",
  MSG3412: "Biểu lãi suất không được để trống",
  MSG3413: "Số lần đáo hạn không được vượt quá 2 ký tự",
  MSG3414: "Số lượng bán nhập vào không được vượt quá @0 ký tự",
  MSG3415: "Số lượng tổng tiền tính ra không được vượt quá @0 ký tự",
  MSG3416: "Giá phải lớn hơn 0",
  MSG3417: "Tỷ lệ giá phải lớn hơn 0",
  MSG3418: "Tỷ lệ thuế phải lớn hơn 0",
  MSG3419: "Tổng thành tiền phải lớn hơn 0",
  MSG3420: "Trạng thái không được phép để trống",
  MSG3421: "Trạng thái không được phép vượt quá @0 chữ số",
  MSG3422: "Nội dung câu trả lời không được phép để trống",
  MSG3423: "Nội dung câu trả lời không được phép vượt quá @0 ký tự",
  MSG3424: "Giá trị trả lãi không được vượt quá 18 số",
  MSG3425: "DEAL_ID truyền lên không được phép để trống",
  MSG3426: "DEAL_ID truyền lên không được vượt quá @0 chữ số",
  MSG3427: "Số tiền chuyển không được vượt quá @0 ký tự (Gồm cả dấu đơn vị).",
  MSG3428: "Số lượng chuyển không được vượt quá @0 ký tự (Gồm cả dấu đơn vị).",
  MSG3429: "Số tiền chuyển phải lớn hơn @0",
  MSG3430: "Số lượng chuyển phải lớn hơn @0",
  MSG3431: "Yêu cầu bạn chọn đơn vị",
  MSG3432: "Chi nhánh không được phép Null",
  MSG3433: "Điểm giao dịch không được phép Null",
  MSG3434: "Độ dài chi nhánh nhập vào không được vượt quá @0 ký tự",
  MSG3435: "Độ dài điểm giao dịch nhập vào không được vượt quá @0 ký tự",
  MSG3436: "Lệnh giao dịch không đươc để rỗng",
  MSG3437: "Chu kỳ thanh toán không đươc để rỗng",
  MSG3438: "Kênh giao dịch không đươc để rỗng",
  MSG3439: "Loại khách hàng không đươc để rỗng",
  MSG3440: "Loại khách hàng (Trong nước / NN) không đươc để rỗng",
  MSG3441: "Nhóm khách hàng không đươc để rỗng",
  MSG3442: "Loại tài khoản không đươc để rỗng",
  MSG3443: "Tiêu chí sắp xếp không đươc để rỗng",
  MSG3444: "Từ ngày GD không được vượt qúa @0 ký tự",
  MSG3445: "Đến ngày GD không được vượt quá @0 ký tự",
  MSG3446: "Từ ngày mở TK không được vượt quá @0 ký tự",
  MSG3447: "Đến ngày mở TK không được vượt quá @0 ký tự",
  MSG3448: "Sàn giao dịch nhập vào không được vượt quá @0 ký tự",
  MSG3449: "Loại chứng khoán nhập vào không được vượt quá @0 ký tự",
  MSG3450: "Chu kỳ thanh toán nhập vào không được vượt quá @0 ký tự",
  MSG3451: "Kênh giao dịch nhập vào không được vượt quá @0 ký tự",
  MSG3452: "Loại khách hàng (Trong nước / NN) không đươc vượt quá @ ký tự",
  MSG3453: "Loại khách hàng không đươc vượt quá @ ký tự",
  MSG3454: "Nhóm khách hàng không đươc vượt quá @ ký tự",
  MSG3455: "Loại tài khoản nhập vào không đươc vượt quá @ ký tự",
  MSG3456: "Độ dài tiểu khoản không đươc vượt quá @ ký tự",
  MSG3457: "Độ dài tài khoản không đươc vượt quá @ ký tự",
  MSG3458: "Tiêu chí sắp xếp không được vượt quá @0 ký tự",
  MSG3459: "Số ngày truy vấn sổ lệnh không được vượt quá @0",
  MSG3460: "Số ngày truy vấn báo cáo không được vượt quá @0",
  MSG3463: "Tỉ lệ ứng trước không hợp lệ (AD_ADVANCE_RATE)",
  MSG3464: "Không xác định được ngày thực hiện quyền (Right Allo Date)",
  MSG3465: "Right RefNo không hợp lệ",
  MSG3466: "Ngày thanh toán không hợp lệ (RIGHT_EXC_DATE)",
  MSG3467: "Bạn chưa nhập ngày giao dịch",
  MSG3468: "Số tài khoản khách hàng đồng bộ phải là 10 ký tự",
  MSG3469: "Mã khách hàng đồng bộ không được quá 20 ký tự",
  MSG3470: "Cảnh báo tham chiếu (<) không được vượt quá @0 chữ số",
  MSG3471: "Cảnh báo tham chiếu (>) không được vượt quá @0 chữ số",
  MSG3472: "Cảnh báo giá khớp (<) không được vượt quá @0 chữ số",
  MSG3473: "Cảnh báo giá khớp (>) không được vượt quá @0 chữ số",
  MSG3474: "Hộp thoại thông báo không được quá @0 chữ số",
  MSG3475: "Gửi tin nhắn SMS không được quá @0 chữ số",
  MSG3476: "Gửi Email không được quá @0 chữ số",
  MSG3477: "Không tìm thấy nhóm khách hàng",
  MSG3478: "Không tìm thấy tham số ứng trước",
  MSG3479: "Ngày giao dịch ứng trước không hợp lệ",
  MSG3480: "Chưa đến giờ nhận HĐ ứng trước. Thời gian bắt đầu ứng trước @0",
  MSG3481:
    "Đã hết thời gian nhận HĐ ứng  trước. Thời gian kết thúc ứng trước @0",
  MSG3482: "Giá trị ứng trước vượt quá hạn mức còn lại khách hàng @0",
  MSG3483: "Mã kho truyền lên bị rỗng",
  MSG3484: "P_Reset truyền lên bị null",
  MSG3485: "Tiểu khoản short sale không được nhập quá @0 ký tự.",
  MSG3486: "Ngày tạo không được phép null",
  MSG3487: "Họ nhập vào không được quá @0 ký tự",
  MSG3488: "Tên nhập vào không được quá @0 ký tự",
  MSG3489: "Ngày sinh nhập vào không đúng",
  MSG3490: "Mã Giới tính nhập vào không được quá @0 chữ số",
  MSG3491: "Mã Loại tài khoản nhập vào không được quá @0 chữ số",
  MSG3492: "Mã Địa điểm nhập vào không được quá @0 chữ số",
  MSG3493: "Mã Loại giấy tờ nhập vào không được quá @0 chữ số",
  MSG3494: "Số giấy tờ nhập vào không được quá @0 ký tự",
  MSG3495: "Nơi cấp nhập vào không được quá @0 chữ số",
  MSG3496: "Ngày cấp nhập vào không đúng",
  MSG3497: "Mã quốc gia nhập vào không được quá @0 ký tự",
  MSG3498: "Mã Người giới thiệu nhập vào không được quá @0 ký tự",
  MSG3499: "Số điện thoại nhập vào không được quá @0 ký tự",
  MSG3500: "Email nhập vào không được quá @0 ký tự",
  MSG3501: "Mã chi nhánh nhập vào không được quá @0 ký tự",
  MSG3502: "Điểm giao dịch nhập vào không được quá @0 ký tự",
  MSG3503: "Mã kênh nhập vào không được quá @0 chữ số",
  MSG3504: "Mã sàn không được quá @0 chữ số",
  MSG3505: "Mã chu kỳ không được quá @0 chữ số",
  MSG3506: "Loại không được quá @0 chữ số",
  MSG3507: "Không xác định được ngày yêu cầu",
  MSG3508: "Không xác định được số yêu cầu",
  MSG3509: "Độ dài ngày yêu cầu không được vượt quá @0 ký tự",
  MSG3510: "Độ dài số yêu cầu không được vượt quá @0 ký tự",
  MSG3511: "Ngày yêu cầu không đúng định dạng",
  MSG3512: "Quyền chọn hủy không còn tồn tại",
  MSG3513: "Trạng thái thị trường không được quá @0 ký tự",
  MSG3514: "Trạng thái giao dịch không được quá @0 ký tự",
  MSG3515: "Chế độ giao dịch không được quá @0 chữ số",
  MSG3516: "Hệ thống không còn tồn tại câu hỏi",
  MSG3517: "Số tiền cần trả nợ vượt quá khả dụng",
  MSG3518: "Không tìm thấy dữ liệu lệnh.",
  MSG3519: "Không tìm thấy dữ liệu lệnh khớp.",
  MSG3520: "Không tìm thấy Order ID.",
  MSG3521: "Loại shortsale không đúng.",
  MSG3522: "Không tìm thấy deal id.",
  MSG3523: "Không tìm thấy tiểu khoản shortsale.",
  MSG3524: "Tên danh mục không được phép trống",
  MSG3525: "Tên danh mục không được vượt quá 250 ký tự",
  MSG3526: "Không tìm thấy mã số danh mục để xóa",
  MSG3527: "Không được để trống OrgOrderNo.",
  MSG3528: "OrgOrderNo không đúng.",
  MSG3529: "Không được để trống Trade date.",
  MSG3530: "Trade date không hợp lệ.",
  MSG3531: "Loại lệnh của lệnh gốc không đúng.",
  MSG3532: "Loại lệnh của lệnh sửa không đúng.",
  MSG3533: "Loại lệnh trùng nhau.",
  MSG3534: "Số tiểu khoản gốc không đúng.",
  MSG3535: "Số tiểu khoản gốc phải là 13 ký tự.",
  MSG3536: "Loại lệnh HTCK không hợp lệ.",
  MSG3537: "Không tìm thấy thông tin lệnh.",
  MSG3538: "Thông tin lệnh đã bị thay đổi, bạn hãy lấy lại dữ liệu.",
  MSG3539: "Trạng thái lệnh không hợp lệ để sửa.",
  MSG3540: "Không tìm thấy thông tin Order.",
  MSG3541: "Không tìm thấy thông tin tài khoản.",
  MSG3542: "Mã chứng khoán không có trong kho.",
  MSG3543: "Mã chứng khoán không được giao dịch ShortSale.",
  MSG3544: "Tài khoản không có trong kho giao dịch HTCk.",
  MSG3545: "Tài khoản @0 không được giao dịch ShortSale.",
  MSG3546: "Có lỗi khi thực hiện vấn tin tài khoản @0.",
  MSG3547: "Tài khoản @0 không đủ số dư để thực hiện sửa lệnh.",
  MSG3548: "Lệnh thị trường đang chờ khớp không được phép hủy",
  MSG3549: "Yêu cầu hủy lệnh bắt buộc phải có số hiệu lệnh",
  MSG3550: "Người dùng không được phép đặt lệnh thỏa thuận",
  MSG3551: "Không tìm thấy thông tin lệnh thỏa thuận số hiệu lệnh: @0",
  MSG3552: "Trạng thái lệnh thỏa thuận không hợp lệ để thực hiện sửa",
  MSG3553: "Không tìm thấy thông tin lệnh quảng cáo có số hiệu lệnh: @0",
  MSG3554: "Không tìm thấy thông tin thiết lập mã thành viên của CTCK",
  MSG3555: "Lệnh quảng cáo không hợp lệ để thực hiện thỏa thuận",
  MSG3556: "Trạng thái lệnh quảng cáo không hợp lệ để thực hiện thỏa thuận",
  MSG3557: "Loại Mua/Bán không được để trống",
  MSG3558: "Loại lệnh không phải sửa tài khoản",
  MSG3559: "Số tiểu khoản sửa lệnh không được phép trống",
  MSG3560: "Số hiệu lệnh sửa không được phép trống",
  MSG3561: "Mã sàn không được phép trống",
  MSG3562: "Lệnh sửa tài khoản chỉ áp dụng đối với sàn HSX",
  MSG3563: "Không tìm thấy thông tin lệnh sửa",
  MSG3564: "Trạng thái lệnh không hợp lệ để thực hiện sửa tài khoản",
  MSG3565: "Lệnh đã khớp một phần, bạn không thể thực hiện sửa tài khoản",
  MSG3566: "Không tìm thấy thông tin tiểu khoản @0",
  MSG3567: "Không tìm thấy thông tin tiểu khoản",
  MSG3568: "Tài khoản sửa mới phải khác tài khoản cũ",
  MSG3569: "Không được phép sửa lệnh lô lẻ sang lô chẵn",
  MSG3570: "Không được phép sửa lệnh lô chẵn sang lô lẻ",
  MSG3571: "Mã chứng khoán bị ngừng giao dịch",
  MSG3572: "Mã chứng khoán bị ngừng giao dịch",
  MSG3573: "Mã chứng khoán không đủ ROOM để thực hiện giao dịch",
  MSG3574: "Mã chứng khoán mới không được phép giao dịch lệnh @0",
  MSG3575: "Mã trader @0 bị ngừng giao dịch",
  MSG3576: "Không cho phép thực hiện lệnh mua/bán",
  MSG3577: "Không cho phép thực hiện lệnh bán",
  MSG3578: "Không cho phép thực hiện lệnh mua",
  MSG3579: "Broker bị tạm ngừng giao dịch thỏa thuận mua và bán",
  MSG3580: "Broker bị tạm ngừng giao dịch thỏa thuận bán",
  MSG3581: "Broker bị tạm ngừng giao dịch thỏa thuận mua",
  MSG3582: "Lệnh lô lẻ không được phép sửa sang lô chẵn",
  MSG3583: "Lệnh lô chẵn không được phép sửa sang lô lẻ",
  MSG3584: "Độ dài tên bộc lọc không được quá @0 ký tự",
  MSG3585: "Không xác định được mã form",
  MSG3586: "User Id không được để trống",
  MSG3587: "User Type không được để trống",
  MSG3588: "Độ dài nội dung bộc lọc không được quá @0 ký tự",
  MSG3589: "Tên bộ lọc đã tồn tại, bạn vui lòng nhập vào tên khác.",
  MSG3590: "Tên nhóm khách hàng không được để trống",
  MSG3591: "Tên nhóm khách hàng không được vượt quá @0 ký tự",
  MSG3592: "Tên nhóm đã tồn tại, bạn vui lòng nhập vào tên khác",
  MSG3593: "Bạn không được phép đặt lệnh báo giá với trái phiếu sàn HSX",
  MSG3594: "Tiểu khoản @0 đã tồn tại trong danh sách đăng ký dịch vụ này.",
  MSG3595:
    "Chưa thiết lập tham số khối lượng trái phiếu tối thiểu được phép thỏa thuận.",
  MSG3596: "Khối lượng tối thiểu để thỏa thuận trái phiếu là @0",
  MSG3597: "Số tiền ứng trước không được nhỏ hơn số tiền cần trả nợ @0",
  MSG3598: "Thời gian chuyển khoản không hợp lệ",
  MSG3599: "Không tìm thấy thông tin lệnh thỏa thuận số hiệu @0",
  MSG3600: "Trạng thái lệnh thỏa thuận không hợp lệ để thực hiện sửa",
  MSG3601: "Trạng thái lệnh thỏa thuận không hợp lệ để thực hiện hủy",
  MSG3602: "Mật khẩu phải có tối thiểu @0 ký tự",
  MSG3603: "Mật khẩu phải có ký tự hoa",
  MSG3604: "Mật khẩu phải có ký tự số",
  MSG3605: "Mật khẩu phải có ký tự đặc biệt",
  MSG3606: "Không thể xóa, tài khoản này đã được thu phí dịch vụ",
  MSG3607: "Không tìm thấy số hợp đồng.",
  MSG3608: "Chưa nhập tiểu khoản.",
  MSG3609: "Chưa chọn ngân hàng.",
  MSG3610: "Ngày hợp đồng không hợp lệ.",
  MSG3611: "Ngày hết hạn không hợp lệ.",
  MSG3612: "Chưa mã CK nào được chọn.",
  MSG3613: "Không tìm thấy dữ liệu cần xóa.",
  MSG3614: "Không tìm thấy ngày hợp đồng.",
  MSG3615: "Tiểu khoản không tồn tại.",
  MSG3616: "Ngày hết hạn nhỏ hơn ngày hiện tại.",
  MSG3617: "Giá trị hợp đồng cầm cố phải lớn hơn 0.",
  MSG3618: "Giá trị cầm cố lớn hơn hạn mức khách hàng còn lại @0.",
  MSG3619: "Giá trị cầm cố lớn hơn hạn mức công ty @0.",
  MSG3620: "Giá trị cầm cố nhỏ hơn giá trị tối thiểu @0.",
  MSG3621: "Giá trị cầm cố lớn hơn giá trị tối đa @0.",
  MSG3622: "Số lượng cầm cố của mã @0 lớn hơn số dư @1.",
  MSG3623: "Số lượng cầm cố của mã @0 nhỏ hơn số lượng tối thiểu @1.",
  MSG3624: "Số lượng cầm cố của mã @0 lớn hơn số lượng tối đa @1.",
  MSG3625: "Mã chứng khoán @0 không được phép cầm cố.",
  MSG3626: "Không tìm thấy hợp đồng cần xóa.",
  MSG3627: "Trạng thái không hợp lệ.",
  MSG3628: "Không tìm thấy dữ liệu Approment.",
  MSG3629: "Không tìm thấy dữ liệu chi tiết hợp đồng.",
  MSG3630: "Ngày hết hạn nhỏ hơn ngày hợp đồng.",
  MSG3631: "Không thể thêm mới, mã chứng khoán đã có trong danh mục.",
  MSG3632: "Mã chứng khoán không tồn tại trong danh mục.",
  MSG3633: "Tên danh mục này đã tồn tại, bạn cần chọn một tên khác.",
  MSG3634:
    "Đã tồn tại 1 bản ghi được thiết lập danh mục mặc định, bạn không thể thiết lập tại đây.",
  MSG3635: "Tổng khối lượng cầm cố mã @0 lớn hơn hạn mức @1.",
  MSG3636: "Không tìm thấy thông tin mã trader @0 của sàn @1",
  MSG3637: "Không tìm thấy thông tin trạng thái broker sàn @0",
  MSG3638: "Giá trị cầm cố lớn hơn hạn mức ngân hàng LOCAL",
  MSG3639: "Phiên sau đóng cửa chỉ cho phép thực hiện lệnh thỏa thuận",
  MSG3640: "Mã chứng khoán không được phép đặt lệnh trong phiên",
  MSG3641: "Không cho phép hủy lệnh giao dịch thỏa thuận 2F sàn @0",
  MSG3642: "Lệnh báo giá lô lẻ chi được phép đặt trong phiên KLLT",
  MSG3643: "Số hiệu lệnh quảng cáo không được phép trống",
  MSG3644: "Không tìm thấy thông tin lệnh quảng cáo để thực hiện hủy",
  MSG3645: "Lệnh quảng cáo đã hủy không được phép hủy lại",
  MSG3646: "Lệnh quảng cáo đã thỏa thuận không được phép hủy",
  MSG3647: "Trạng thái phiên không hợp lệ để thực hiện sửa/hủy",
  MSG3648:
    "Hyper Link không đúng định dạng. Bạn cần nhập link đầy đủ dưới dạng (http://...)",
  MSG3649: "Không tìm thấy ngân hàng @0.",
  MSG3650: "Không tìm thấy thông tin chi tiết HĐ.",
  MSG3651: "Không tìm thấy thông tin biểu lãi.",
  MSG3652: "Không tìm thấy tiểu khoản.",
  MSG3653: "Trả nợ lớn hơn nợ còn lại.",
  MSG3654: "Không đủ tiền để trả nợ.",
  MSG3655: "Dữ liệu đã thay đổi.",
  MSG3656: "Tổng tiền trả lớn hơn khả dụng.",
  MSG3657: "Dữ liệu đầu vào không được để trống",
  MSG3658: "Tổng tiền trả nợ phải lớn hơn 0.",
  MSG3659: "Hạn mức tổng không đủ.",
  MSG3660: "Không tìm thấy tham số chuyển khoản ngân hàng.",
  MSG3661: "Không xác định được thời gian bắt đầu chuyển khoản.",
  MSG3662: "Không xác định được thời gian kết thúc chuyển khoản.",
  MSG3663: "Không xác định được thời gian Bắt đầu NextDay.",
  MSG3664: "Không xác định được thời gian Kết thúc NextDay.",
  MSG3665: "Không xác định được số tiền tối thiểu.",
  MSG3666: "Không xác định được số tiền tối đa.",
  MSG3667: "Không xác định được số lần CK/Ngày.",
  MSG3668: "Không xác định được tỉ lệ phí.",
  MSG3669: "Không được xóa danh mục sở hữu.",
  MSG3670: "Không được phép xóa danh mục do CTCK thiết lập.",
  MSG3671: "Không được Thêm/Xóa CK trong danh mục do CTCK thiết lập.",
  MSG3672: "Không được xóa danh mục do User khác thiết lập.",
  MSG3677: "Ký tự xác nhận không khớp.",
  MSG3678: "Không được thêm mới chứng khoán vào danh mục sở hữu.",
  MSG3679: "Không tìm thấy tham số địa chỉ dịch vụ ngân hàng.",
  MSG3680: "Có lỗi khi thực hiện kết nối ngân hàng: @0",
  MSG3682: "Bạn không được phép sửa lệnh giải chấp",
  MSG3683: "Bạn không được phép hủy lệnh giải chấp",
  MSG3684: "Không tìm thấy thông tin tiền tài khoản @0",
  MSG3685: "Không được xóa bản ghi đã bị sửa",
  MSG3686: "Không được xóa bản ghi đã bị hủy",
  MSG3687: "Không thể xóa bản ghi vì hạn mức đã sử dụng là: @0",
  MSG3688: "Mẫu email không được để trống",
  MSG3689: "Tiêu đề không được để trống",
  MSG3690: "Mail CC không được để trống",
  MSG3691: "Nội dung không được để trống",
  MSG3692: "Không tìm thấy dữ liệu bản ghi muốn Sửa/Xóa",
  MSG3693: "Độ dài trường Mẫu email không được vượt quá @0 ký tự",
  MSG3694: "Độ dài trường Tiêu đề không được vượt quá @0 ký tự",
  MSG3695: "Độ dài trường MailCC không được vượt quá @0 ký tự",
  MSG3696: "Độ dài trường MailBcc không được vượt quá @0 ký tự",
  MSG3697: "Độ dài trường Content không được vượt quá @0 ký tự",
  MSG3698: "Không thể Thêm mới/Cập nhật. Mẫu Email này đã có trong danh sách.",
  MSG3699: "Khối lượng không đủ để đặt lệnh",
  MSG3700: "Không xác định được tham số của ngân hàng được chọn",
  MSG3701: "Không xác định được tỷ lệ lãi ứng trước tính với khách hàng",
  MSG3702: "Không xác định được tỷ  lệ lãi ứng trước tính với ngân hàng",
  MSG3703: "Không xác định được tham số cách tính lãi",
  MSG3704: "Không xác định được tham số số ngày miễn phí",
  MSG3705: "Không xác định được tham số lãi ứng trước tối thiểu",
  MSG3706: "Không xác định được tham số tỷ lệ phí quản lý",
  MSG3707: "Không xác định được tham số cách tính phí quản lý",
  MSG3708: "Không xác định được tham số số ngày miễn phí quản lý",
  MSG3709: "Không xác định được tham số phí quản lý tối thiểu",
  MSG3710: "Không xác định được tham số giá trị tối thiểu của hợp đồng ƯT",
  MSG3711: "Không xác định được tham số giá trị tối đa của HĐ ứng trước",
  MSG3712: "Không xác định được tham số tổng hạn mức ứng trước của KH",
  MSG3713: "Không xác định được tham số tổng hạn mức ngân hàng",
  MSG3714: "Không xác định được tham số thu lãi ngày T3",
  MSG3715: "Không xác định được tham số thời gian bắt đầu ứng trước",
  MSG3716: "Không xác định được tham số thời gian kết thúc ứng trước",
  MSG3717:
    "Mã cổ phiếu mới lên sàn/Giao dịch đặc biệt không được phép giao dịch lô lẻ",
  MSG3718:
    "Mã cổ phiếu mới giao dịch trở lại sau 25 phiên không được phép giao dịch lô lẻ",
  MSG3719: "Không tìm thấy thông tin yêu cầu sửa thỏa thuận SHL @0",
  MSG3720:
    "Trạng thái yêu cầu sửa thỏa thuận SHL @0 đã thay đổi, bạn không thể thực hiện",
  MSG3721: "Thị trường chưa đóng cửa, bạn không được phép sửa lệnh",
  MSG3722: "Tài khoản chưa khai báo trong nhóm khách hàng cầm cố.",
  MSG3723: "Tài khoản không đủ số dư tiền để thực hiện lệnh mua",
  MSG3724: "Tài khoán không đủ số dư chứng khoán để thực hiện lệnh bán",
  MSG3725: "Không tìm thấy dữ liệu sàn",
  MSG3726: "Không tìm thấy dữ liệu ngày giao dịch",
  MSG3727: "Không tìm thấy dữ liệu kiểu sắp xếp",
  MSG3728: "Không tìm thấy dữ liệu ngày bắt đầu",
  MSG3729: "Không tìm thấy dữ liệu ngày kết thúc",
  MSG3730: "Mật khẩu Phone Agent cũ không tồn tại",
  MSG3731:
    "Dữ liệu lệnh điều kiện đã thay đổi, hệ thống không cho phép cập nhật",
  MSG3732: "Không tìm thấy thông tin tiểu khoản",
  MSG3733: "Tài khoản không đủ số dư để thực hiện đăng ký",
  MSG3734: "Mật khẩu phone agent mới không được quá @0 ký tự",
  MSG3735: "Mật khẩu phone agent mới không được phép null",
  MSG3736: "Chứng khoán khả dụng không đủ để thực hiện bán",
  MSG3737: "Ngày hệ thống đã thay đổi, bạn hãy đăng nhập lại để tiếp tục",
  MSG3738: "Không tìm thấy thông tin thực hiện quyền",
  MSG3739: "Dịch vụ đã duyệt, bạn không thể cập nhật",
  MSG3740: "Không tìm thấy thông tin dịch vụ cần sửa",
  MSG3741: "Có lỗi khi thực hiện vấn tin kho HTCK",
  MSG3742: "Không lấy được giá trị của Sequence @0",
  MSG3743: "Dữ liệu không hợp lệ để thực hiện sửa lệnh",
  MSG3744: "Không được phép sửa lệnh ngày quá khứ",
  MSG3745: "Số tiền ứng trước tối thiểu là @0",
  MSG3746: "Số tiền ứng trước tối đa là @0",
  MSG3747: "Số tiền ứng trước vượt quá hạn mức còn lại của công ty(@0)",
  MSG3748: "Không tìm thấy thông tin hợp đồng cấm cố: @0",
  MSG3749: "Khối lượng bán cầm cố không được phép vượt quá: @0",
  MSG3750: "Mã chứng khoán không được để trống",
  MSG3751: "Từ ngày không được để trống",
  MSG3752: "Đến ngày không được để trống",
  MSG3753: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc",
  MSG3754: "Dữ liệu input không hợp lệ",
  MSG3755: "Thông tin người dùng không hợp lệ",
  MSG3756: "Không xác định được phiên giao dịch của người dùng",
  MSG3757: "Mã xác thực không hợp lệ",
  MSG3758: "Mã nghiệp vụ không hợp lệ",
  MSG3759: "Mã ứng dụng không hợp lệ",
  MSG3760: "Độ dài mã xác thực không được vượt quá @0 ký tự",
  MSG3761: "Không tìm thấy thông tin xác thực",
  MSG3762: "Đã hết thời gian xác thực",
  MSG3763: "Số lần xác thực quá số lượng cho phép",
  MSG3764: "Your OTP is incorrect. Please try againt",
  MSG3765: "Không tìm thấy thông tin lệnh",
  MSG3766: "Trạng thái lệnh không hợp lệ",
  MSG3767: "Các lệnh được chọn đã thực hiện gửi email thông báo đến khách hàng",
  MSG3768: "Không tìm thấy thông tin người dùng",
  MSG3769: "Bản ghi đã tồn tại trong CSDL, bạn hãy thực hiện cập nhật",
  MSG3770: "Mã người dùng không hợp lệ",
  MSG3771: "Loại người dùng không hợp lệ",
  MSG3772: "Loại ứng dụng không hợp lệ",
  MSG3773: "Khối lượng giải chấp không đủ để đặt lệnh",
  MSG3774: "Số tài khoản khách hàng không được trống",
  MSG3775: "Mã chứng khoán không được trống",
  MSG3776: "Không tìm thấy thông tin tài khoản @0",
  MSG3777: "Không tìm thấy thông tin người dùng @0",
  MSG3778: "Không tìm thấy thông tin broker: @0",
  MSG3779: "Tài khoản @0 đang bị chặn đặt lệnh, bạn không thể thực hiện",
  MSG3780: "Bản ghi đã tồn tại trong CSDL",
  MSG3781: "Bản ghi không tồn tại để thực hiện hủy/sửa",
  MSG3782: "Trạng thái bản ghi không hợp lệ",
  MSG3783: "Loại Mua/Bán không hợp lệ",
  MSG3784: "Khối lượng Mua/Bán không hợp lệ",
  MSG3785: "Không tìm thấy mã chứng khoán @0 trong bảng mã",
  MSG3786: "Số hiệu lệnh không hợp lệ",
  MSG3787: "Ngày đặt lệnh không hợp lệ",
  MSG3788: "Không tìm thấy mã trái phiếu @0",
  MSG3789:
    "Tài khoản của bạn chưa được kích hoạt, bạn hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",
  MSG3790: "Loại lệnh không hợp lệ.",
  MSG3791: "Mã sàn không hợp lệ.",
  MSG3792:
    "Mã chứng khoán đang bị chặn giao dịch, bạn hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",
  MSG3793:
    "Thông tin lệnh đã thay đổi, bạn hãy làm mới dữ liệu và thực hiện lại.",
  MSG3794: "Không tìm thấy thông tin lệnh gốc.",
  MSG3795: "Dữ liệu đã thay đổi, bạn hãy làm mới và thực hiện lại.",
  MSG3796: "Không tìm thấy yêu cầu đặt lệnh thỏa thuận của khách hàng.",
  MSG3797:
    "Thông tin yêu cầu thỏa thuận của khách hàng đã thay đổi, bạn hãy làm mới dữ liệu và thử lại.",
  MSG3798: "Hệ thống không cho phép đặt lệnh lô lẻ với lệnh điều kiện.",
  MSG3799: "Không tìm thấy thông tin lệnh.",
  MSG3800: "Trạng thái lệnh không hợp lệ để thực hiện hủy.",
  MSG3801:
    "Tài khoản của bạn đã đóng tại công ty chứng khoán, bạn không thể đăng nhập.",
  MSG3802: "Từ ngày không được để trống.",
  MSG3803: "Đến ngày không được để trống.",
  MSG3804: "Từ ngày phải nhỏ hơn đến ngày.",
  MSG3805: "Trạng thái lệnh không hợp lệ để thực hiện sửa/hủy.",
  MSG3806: "Mã chứng khoán không được để trống.",
  MSG3807: "Ngày import không hợp lệ.",
  MSG3808: "Dữ liệu đã tồn tại trong CSDL.",
  MSG3809: "Không tìm thấy thông tin khuyển nghị MUA.",
  MSG3810:
    "Thông tin khuyến nghị MUA đã thay đổi, bạn hãy làm mới dữ liệu và thử lại.",
  MSG3811: "Không tìm thấy thông tin khuyển nghị BÁN.",
  MSG3812:
    "Thông tin khuyến nghị BÁN đã thay đổi, bạn hãy làm mới dữ liệu và thử lại.",
  MSG3813:
    "Tồn tại khuyến nghị Mua/Bán với mã chứng khoán @0, bạn không thể thực hiện.",
  MSG3814: "Không tìm thấy thông tin mã chứng khoán @0.",
  MSG3815: "Không xác định được lô.",
  MSG3816: "Khối lượng lô lẻ phải < @0.",
  MSG3817: "Khối lượng đặt phải >=  khối lượng tối thiểu (@0).",
  MSG3818: "Mã chứng khoán phái sinh không được giao dịch lệnh báo giá.",
  MSG3819:
    "Hệ thống giao dịch phái sinh đang tạm ngừng nhận lệnh, bạn hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",
  MSG3820: "Không tìm thấy thông tin tài khoản phái sinh @0.",
  MSG3821: "Tài khoản phái sinh không được để trống.",
  MSG3822: "Không tìm thấy thông tin tài khoản.",
  MSG3823: "Không tìm thấy thông tin mã chứng khoán phái sinh @0.",
  MSG3824: "Không tìm thấy thông tin giá chứng khoán @0.",
  MSG3825: "Không tìm thấy thông tin tài khoản khái sinh @0.",
  MSG3826: "Dữ liệu input không hợp lệ.",
  MSG3827: "Mã chứng khoán không được trống.",
  MSG3828: "Tài khoản đặt lệnh không hợp lệ.",
  MSG3829: "Giá đặt không hợp lệ.",
  MSG3830: "Khối lượng nhập không hợp lệ.",
  MSG3831: "Loại lệnh nhập không hợp lệ.",
  MSG3832: "Không tìm thấy thông tin lệnh cần hủy.",
  MSG3833: "Trạng thái lệnh không hợp lệ để thực hiện lệnh hủy.",
  MSG3834: "Số hiệu lệnh không được trống.",
  MSG3835: "Cờ xác nhận/Từ chối không được để trống",
  MSG3836: "Không hỗ trợ loại lệnh @0 cho lệnh Phái sinh",
  MSG3837: "Không tìm thấy thông tin thành viên @0",
  MSG3838:
    "Tài khoản @0 bị cảnh báo vi phạm tỷ lệ an toàn sử dụng tài sản/vị thế.",
  MSG3839: "Bạn chưa nhập mật khẩu giao dịch hiện tại.",
  MSG3840: "Mã thành viên đối ứng không hợp lệ.",
  MSG3841:
    "Tài khoản đăng nhập không hợp lệ, không tìm thấy thông tin khách hàng.",
  MSG3842: "Khách hàng không đăng ký sử dụng dịch vụ: Gửi mật khẩu OTP",
  MSG3843: "Không tìm thấy thông tin yêu cầu thỏa thuận.",
  MSG3844: "Thông tin yêu cầu thỏa thuận đã thay đổi, bạn hãy làm mới dữ liêu.",
  MSG3845: "Tài khoản mua và tài khoản bán không được trùng nhau.",
  MSG3846: "Mật khẩu giao dịch hiện tại không đúng",
  MSG3847: "Không tìm thấy số điện thoại đăng ký nhận mã SMS OTP",
  MSG3848: "Không tìm thấy thông tin lệnh Repos No1",
  MSG3849: "Thông tin lệnh không thay đổi để thực hiện sửa",
  MSG3850: "Số tài khoản không hợp lệ",
  MSG3851: "Mã chứng khoán không hợp lệ",
  MSG3852: "Loại lệnh mua/bán không hợp lệ",
  MSG3853: "Trạng thái mã chứng khoán không hợp lệ",
  MSG3854:
    "Lỗi đặt lệnh cùng phiên, bạn đã có lệnh đối ứng với mã chứng khoán này.",
  MSG3855: "Danh mục đã tồn tại trong CSDL",
  MSG3856: "Không tìm thấy thông tin danh mục",
  MSG3857:
    "Kênh giao dịch đang tạm khóa, bạn hãy liên hệ nhân viên CSKH để được trợ giúp.",
  MSG3858:
    "Kênh giao dịch đang tạm khóa, bạn hãy liên hệ nhân viên CSKH để được trợ giúp.",
  MSG3860: "Bạn không được phép đặt lệnh báo giá với trái phiếu sàn HNX",
  MSG3861: "Trái phiếu chỉ được phép đặt lệnh báo giá.",
  MSG3862:
    "Tiểu khoản @0 đang có có dư nợ cầm cố mã @1, bạn không thể đặt lệnh",
  MSG3863:
    "Mã chứng khoán @0 nằm trong danh mục không được phép mua cầm cố, bạn không thể đặt lệnh",
  MSG3864: "Tên tài khoản ngân hàng không vượt quá @0 ký tự",
  MSG3865: "Số tài khoản ngân hàng không vượt quá @0 ký tự",
  MSG3866: "Ký tự xác nhận không hợp lệ.",
  MSG3867: "KH @0 đang bị cảnh báo đặt lệnh MUA.",
  MSG3868: "KH @0 đang bị cảnh báo lệnh BÁN.",
  MSG3869: "KH @0 đang bị cấm đặt lệnh MUA.",
  MSG3870: "KH @0 đang bị cấm đặt lệnh BÁN.",
  MSG3897: "Không hỗ trợ đặt lệnh lô lẻ với lệnh trước giờ.",
  MSG3913: "Lệnh thỏa thuận lô lẻ chỉ được phép đặt trong phiên KLLT.",
  MSG3914: "Phiên @0 không cho phép đặt lệnh thỏa thuận lô lẻ.",
  MSG3915:
    "Mã chứng khoán mới niêm yết/giao dịch trở lại sau 25 phiên không được giao dịch lô lẻ.",
  MSG4001: "Customers do not have enough securities balance to sell",
  MSG4002: "Customers do not have enough money balance to buy",
  MSG4003: "Tồn tại yêu cầu đang chờ xử lý với lệnh này",
  MSG4004: "Cannot buy & sell the same symbol in ATO/ATC session",
  MSG4005: "The order status is not valid for cancel order",
  MSG4006: "Cannot cancel order type ATO/ATC",
  MSG4007: "Cannot edit/cancel order in ATO/ATC session",
  MSG4008: "Invalid user or password. Please try again",
  MSG4009:
    "Your account is locked, please contact Customer Service for assistance.",
  MSG4010: "Password is wrong. Please try again",
  MSG4011:
    "Your account has been locked because you login was wrongly entered @0 times, please contact Customer Service staff for assistance.",
  MSG4012: "Buying power not found",
  MSG4013: "Not enough buying power to edit orders.",
  MSG4014: "Tiểu khoản @0 đã tồn tại trong nhóm.",
  MSG4015: "Original orders information not found",
  MSG4016: "Status is invalid. Cannot change account",
  MSG4017: "Lệnh đã khớp một phần, bạn không được phép sửa tài khoản",
  MSG4018: "Số tiền muốn rút vượt quá khả dụng",
  MSG4019: "Lệnh chọn duyệt không đúng trình tự",
  MSG4020: "Lệnh đặt Online không được phép hủy tại Floor",
  MSG4021: "ATO / ATC not allowed to edit",
  MSG4022: "Khối lượng chờ khớp đã thay đổi, bạn cần làm mới dữ liệu.",
  MSG4023: "Lệnh dừng chưa kích hoạt không được phép sửa.",
  MSG4024: "MOK/MAK not allowed to edit",
  MSG4025: "Lệnh đang chờ xác nhận từ sàn không được phép hủy",
  MSG4026: "Cannot edit/cancel order in ATO/ATC session",
  MSG4027: "Số lần chuyển khoản trong ngày đã vượt quá số lần cho phép",
  MSG4028: "Tiểu khoản không hợp lệ",
  MSG4029: "Mã CCQ không hợp lệ",
  MSG4030: "Giá trị mua không hợp lệ",
  MSG4031: "Phương thức thanh toán không hợp lệ",
  MSG4032: "Ngày bắt đầu phải lớn hơn ngày hiện tại",
  MSG4033: "Kỳ hạn không hợp lệ",
  MSG4034: "Chu kỳ thanh toán không hợp lệ",
  MSG4035: "Không xác định được dữ liệu hợp đồng cần cập nhật",
  MSG4036: "Không vấn tin được sức mua",
  MSG4037: "Giá trị mua vượt quá sức mua tối đa",
  MSG4038:
    "Tồn tại dữ liệu chi tiết hợp động trạng thái không hợp lệ (Đã khớp lệnh hoặc Đã thanh toán)",
  MSG4039: "Không tìm thấy thông tin Hợp đồng SIP",
  MSG4040: "Trạng thái lệnh không hợp lệ hoặc dữ liệu lệnh đã thay đổi",
  MSG4041: "Không đủ số dư chứng khoán để bán",
  MSG4042:
    "Số lượng chứng khoán bán vượt quá số lượng có thể bán tối đa của HĐ SIP",
  MSG4043: "Loại tiểu khoản không hợp lệ",
  MSG4044: "Số dư còn lại sau khi bán không đủ số dư tối thiểu yêu cầu @0",
  MSG4045: "Không tìm thấy thông tin CCQ",
  MSG4046: "Đã quá thời gian chốt đặt lệnh",
  MSG4047: "Không tìm thấy thông tin hợp đồng Margin tương ứng",
  MSG4048:
    "Món vay chưa đến ngày thanh toán (Ngày bắt đầu cho phép thanh toán là @0)",
  MSG4049: "Không tìm thấy thông tin hợp đồng Cầm cố tương ứng",
  MSG4050: "Loại tiểu khoản không hợp lệ (Không phải tiểu khoản tự doanh)",
  MSG4051: "Giá trị mua lần đầu tối thiểu phải là @0",
  MSG4052: "Trạng thái chi tiết hợp đồng SIP không hợp lệ",
  MSG4053: "Chưa chọn hình thức trả phí Trong/Ngoài",
  MSG4054: "Loại tiểu khoản không hợp lệ",
  MSG4055: "Số tiền giải tỏa vượt quá số tiền có thể giải tỏa @0",
  MSG4056: "Room margin còn lại không đủ để đặt lệnh",
  MSG4057: "Tiểu khoản @0 chưa đăng ký dịch vụ @1",
  MSG4058: "Mật khẩu giao dịch qua điện thoại không đúng",
  MSG4059:
    "Hệ thống không cho phép hủy lệnh chờ khớp trong thời gian nghỉ giữa phiên",
  MSG4060: "Hệ thống đang mở ngày, bạn vui lòng truy cập lại sau ít phút",
  MSG4061: "Tồn tại lệnh MUA đối ứng đang chờ khớp có giá >=  giá BÁN",
  MSG4062: "Tồn tại lệnh BÁN đối ứng đang chờ khớp có giá <=  giá MUA",
  MSG4063: "Không tìm thấy tham số @0 của ngân hàng @1",
  MSG4064: "Không tìm thấy tham số ngân hàng @0",
  MSG4065: "Tài khoản Margin không được phép mua mã chứng khoán ngoài danh mục",
  MSG4066: "Hiện chưa có đợt bán đối với cổ phiếu quỹ, bạn không thể thực hiện",
  MSG4067: "Hiện chưa có đợt mua đối với cổ phiếu quỹ, bạn không thể thực hiện",
  MSG4068: "Khối lượng đặt phải >=  khối lượng tối thiểu được phép đặt (@0)",
  MSG4069: "Khối lượng đặt phải <=  khối lượng tối đa được phép đặt (@0)",
  MSG4070: "Khối lượng đặt phải <=  khối lượng còn lại đăng ký (@0)",
  MSG4071: "Giá bán phải >=  giá bán tối thiểu (@0)",
  MSG4072: "Giá mua phải <=  giá mua tối đa (@0)",
  MSG4073: "Tiểu khoản @0 chưa đăng ký giao dịch quỹ mở @1",
  MSG4074: "Khối lượng mua/bán phải <=  khối lượng tối đa (@0)",
  MSG4075: "Quantity must be smaller than maximum securities can sell (@0)",
  MSG4076: "Purchase volume must be <=  maximum purchase volume (@0)",
  MSG4077: "Có lỗi khi thực hiện vấn tin tài khoản phái sinh @0",
  MSG4078: "Tài khoản không đủ số sư ký quỹ để đặt lệnh",
  MSG4079: "Room tự doanh không đủ để thực hiện. Room còn lại @0",
  MSG4080: "Khối lượng khớp không hợp lệ",
  MSG4081: "Ngày @0 là ngày nghỉ",
  MSG4082: "Bạn chưa đăng ký dịch vụ đặt lênh online",
  MSG4083:
    "CMND/CCCD của Quý khách đã hết hạn. Đăng ký mở tài khoản GDCK không thành công",
  MSG4084:
    "Số CMND/CCCD của Quý khách đã được sử dụng để mở tài khoản tại CTS. Quý khách vui lòng liên hệ với CTS để được hỗ trợ.LH: 1900 588 866",
  MSG4085: "Số lượng mã cổ phiếu nắm giữ của tiểu khoản không được vượt quá @0",
  MSG4086:
    "Tiểu khoản chưa thực hiện kết nối ngân hàng. Quý khách vui lòng liên hệ CTCK để được hỗ trợ",
  MSG4087:
    "CMND của Quý khách đã hết hạn, không thể thực hiện yêu cầu. Quý khách vui lòng liên hệ tổng đài 1900 588 866 để được hỗ trợ",
  MSG4088: "Không đúng định dạng giờ (@0)",
  MSG4089: "Hạn mức còn lại của điểm giao dịch @0 không đủ (còn lại @1)",
  MSG4090: "Người dùng không được cấp quyền cho thao tác @1 với giá trị @2",
  MSG5001: "Customer not found",
  MSG1179: "Giá nhập không hợp lệ",
  MSG1180: "Dữ liệu input không hợp lệ (@0)",
  MSG1181:
    "Quý khách chưa đăng ký mở tài khoản giao dịch phái sinh. Để được hỗ trợ mở tài khoản phái sinh, Quý khách vui lòng liên hệ Bộ phận chăm sóc khách hàng.",
  MSG1182: "Lỗi từ hệ thống BAS: (-102)Người dùng không tồn tại",
  MSG1183: "Lỗi từ hệ thống BAS: (-603)Xác thực OTP thất bại",
  MSG1184: "Chưa kích hoạt sử dụng OTP",
  MSG1185: "Lỗi từ hệ thống BAS: (-704)Dịch vụ đã bị huỷ",
  MSG1186: "Có lỗi khi thực hiện xác thực từ máy chủ BAS",
  MSG1187: "Người dùng không tồn tại",
  MSG1188: "Chưa kích hoạt sử dụng OTP/Thiết bị chưa đăng ký",
  MSG1189: "Dịch vụ chưa được đăng ký",
  MSG1190: "Có lỗi khi thực hiện hủy dịch vụ từ máy chủ BAS",
  MSG1191: "Có lỗi khi thực hiện kết nối máy chủ BAS",
  MSG1192: "Tài khoản chuyển không được để trống",
  MSG1193_1: "Không thể load được dữ liệu đã ký",
  MSG1193_2: "Không tìm thấy chữ ký số trong dữ liệu",
  MSG1193_3: "Chữ ký đã bị thay đổi",
  MSG1193_4: "Đường dẫn tin tưởng (TrustPath) không hợp lệ",
  MSG1193_5: "Không tìm thấy chứng thư số của CA",
  MSG11931: "Chứng thư số chưa đến hạn sử dụng",
  MSG11932: "Chứng thư số đã hết hạn",
  MSG11933: "Chứng thư số không có quyền ký",
  MSG11934: "Chứng thư số đã bị thu hồi",
  MSG11935: "Không thể kiểm tra trạng thái chứng thư số",
  MSG11936:
    "Không thể kiểm tra chứng thư số qua OCSP: Không tồn tại url trong chứng thư số",
  MSG11937: "Không tìm thấy chứng thư số trong chữ ký",
  MSG11938: "Không thể kiểm tra chứng thư số qua OCSP: response null",
  MSG11939: "Không thể kiểm tra chứng thư số qua CRL",
  MSG119310: "Không thể kiểm tra chứng thư số qua OCSP: url không tồn tại",
  MSG119311: "Chữ ký trong OCSP response không hợp lệ",
  MSG119312: "Không thể khởi tạo OCSP request",
  MSG119313: "Chữ ký số của CA trên chứng thư số không hợp lệ",
  MSG119314: "Dùng cho ký CMS: Không có dữ liệu trong chữ ký",
  MSG1194: "Dữ liệu ký không hợp lệ",
  MSG1195: "Số CMND người đại diện không hợp lệ",
  MSG11961:
    "Tài khoản @0 chỉ được phép BÁN mã @1 với mức giá trong khoảng @2-@3",
  MSG11962:
    "Tài khoản @0 chỉ được phép MUA mã @1 với mức giá trong khoảng @2-@3",
  MSG1197: "Có lỗi khi thực hiện xác thực Smart OTP, mã lỗi: @0",
  MSG1198: "Có lỗi khi thực hiện hủy kích hoạt thiết bị SmartOTP, mã lỗi: @0",
  MSG1199: "Lỗi từ hệ thống BAS: (-201)Không tôn tại cấu hình config",
  MSG1200: "Lỗi từ hệ thống BAS: (-401)Không thể kết nối đến CSDL",
  MSG1201: "Lỗi từ hệ thống BAS: (-501)Chức năng chưa khả dụng",
  MSG1202: "Lỗi từ hệ thống BAS: (-604)Xác thực mật khẩu thất bại",
  MSG1203: "Lỗi từ hệ thống BAS: (-605)Không thể sinh khóa bí mật",
  MSG1204:
    "Lỗi từ hệ thống BAS: (-606)Mã xác thực để reset mã pin không chính xác",
  MSG1205: "Lỗi từ hệ thống BAS: (-700)Token đã tồn tại",
  MSG1206: "Lỗi từ hệ thống BAS: (-701)Token không tồn tại",
  MSG1207: "Lỗi từ hệ thống BAS: (-702)Token đã được active",
  MSG1208: "Lỗi từ hệ thống BAS: (-703)Token chưa được active",
  MSG1209: "Lỗi từ hệ thống BAS: (-705)Thông tin token đã tồn tại",
  MSG1210:
    "Lỗi từ hệ thống BAS: (-706)Bạn đã gửi yêu cầu kích hoạt quá nhiều lần",
  MSG1211: "Lỗi từ hệ thống BAS: (-707)Token không được phép",
  MSG1212: "Lỗi từ hệ thống BAS: (-708)Lỗi khi kích hoạt token",
  MSG1213: "Lỗi từ hệ thống BAS: (-900)Dữ liệu đầu vào không đúng định dạng",
  MSG1214: "Lỗi từ hệ thống BAS: (-901)Không thể cập nhật vào CSDL",
  MSG1215: "Lỗi từ hệ thống BAS: (-902)Có lỗi trong quá trình xử lý",
  MSG1216: "Lỗi từ hệ thống BAS: (-909)Dữ liệu json không đúng định dạng",
  MSG1217: "Lỗi từ hệ thống BAS: (-910)Không thể gửi SMS",
  MSG1218: "Tiểu khoản không hợp lệ để thực hiện nộp ký quỹ (hậu tố 01->50)",
  MSG1219: "Tiểu khoản không hợp lệ để thực hiện rút ký quỹ (hậu tố 51)",
  MSG1227: "Dữ liệu đầu vào(data) không hợp lệ.",
  MSG1228: "Dữ liệu đầu vào(mac) không hợp lệ.",
  MSG1229: "Dữ liệu đầu vào(data) không thể giải mã.",
  MSG1230: "Có lỗi khi tạo hmac",
  MSG1231: "Dữ liệu hmac không khớp.",
  MSG1232: "Không tìm thấy thông tin lệnh thỏa thuận 2Firm",
  MSG1233: "Không hỗ trợ hủy lệnh thỏa thuận với bên MUA",
  MSG2012: "Chưa thiết lập địa chỉ server mail Adapter",
  MSG3253S:
    "Loại lệnh @0 không được thiết lập trong phiên (S)Trước giờ mở cửa.",
  MSG3859: "Thông tin tài khoản không hợp lệ",
  MSG3871:
    "Không được phép đặt lệnh báo giá lô lẻ đối với trái phiếu sàn HNX/UpCOM.",
  MSG3872: "Tài khoản không sử dụng dịch vụ Contact center.",
  MSG3873: "Tài khoản xác thực không hợp lệ.",
  MSG3874: "Giá trị BusinessCd không hợp lệ.",
  MSG3875: "Giá trị TelPinType không lệ.",
  MSG3876: "Mật khẩu Contact center không đúng, bạn hãy thử lại.",
  MSG3877: "Bạn chưa nhập mật khẩu CC hiện tại",
  MSG3878: "Mật khẩu CC mới trùng mật khẩu hiện tại",
  MSG3879: "Mật khẩu CC mới không được quá @0 ký tự",
  MSG3880: "Chưa nhập mật khẩu CC mới",
  MSG3881: "Mật khẩu CC mới không khớp",
  MSG3882: "Không tìm thấy thông tin đăng ký dịch vụ Contact Center",
  MSG3883: "Mật khẩu Contact Center không đúng",
  MSG3884:
    "Mật khẩu truy cập hệ thống và mật khẩu giao dịch không được trùng nhau.",
  MSG3885: "Người thực hiện xác nhận lệnh phải là NVCS của khách hàng",
  MSG3886: "Lệnh đã xác nhận, bạn không thể thực hiện lại.",
  MSG3887: "Bạn không có quyền thực hiện Xác nhận lệnh cho tài khoản @0.",
  MSG3888: "You have not entered the SmartOTP verification character",
  MSG3889: "Your device has not registered to use SmartOTP.s",
  MSG3890: "The Smart OTP verification character is incorrect.",
  MSG3891:
    "Quý khách chưa có tài khoản chứng khoán. Liên hệ Công ty chứng khoán Công Thương: <a href='tel:1900588866'>1900588866</a>  để được hỗ trợ.",
  MSG3892:
    "Quý khách vui lòng đăng ký dịch vụ giao dịch trực tuyến và SMS tại Công ty chứng khoán công thương để thực hiện giao dịch. Liên hệ Công ty chứng khoán công thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG3893:
    "Quý khách vui lòng đăng ký dịch vụ giao dịch trực tuyến  tại Công ty chứng khoán công thương để thực hiện giao dịch. Liên hệ Công ty chứng khoán công thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG3894:
    "Quý khách vui lòng đăng ký dịch vụ SMS tại Công ty chứng khoán công thương để thực hiện giao dịch. Liên hệ Công ty chứng khoán công thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG3895: "Dữ liệu mapping của tài khoản @0 đã tồn tại trong CSDL.",
  MSG3896:
    "Quý khách không có quyền truy cập chức năng giao dịch chứng khoán. LH: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ",
  MSG4091:
    "Hệ thống không hõ trợ hủy lệnh thỏa thuận 1F đã khớp sàn HNX, UpCOM",
  MSG5035:
    "Công ty từ chối yêu cầu vì mã ck ko nằm trong danh mục được ký quỹ.",
  MSG5043: "Tài khoản @0 không hợp lệ",
  MSG5044: "Bạn chưa nhập tài khoản chuyển",
  MSG5045: "Bạn chưa nhập tài khoản nhận",
  MSG5046: "Bạn chưa nhập Mã trái phiếu",
  MSG5047: "Bạn chưa nhập khối lượng chuyển nhượng",
  IDG_00000000: "Thành công",
  IDG_00000050: "Không kết nối được tới service AI",
  IDG_00000500: "Lỗi hệ thống",
  IDG_00010002: "Lỗi dữ liệu ảnh đầu vào không đúng format",
  IDG_00010004: "Hệ thống đang bận",
  IDG_00010101: "Lỗi phân loại giấy tờ",
  IDG_00010102:
    "Lỗi dữ liệu ảnh đầu vào phân loại giấy tờ(Kiểm tra file dữ liệu nhận được từ Minio xem có phải là ảnh không)",
  IDG_00010201: "Lỗi nhận dạng ký tự trên ảnh giấy tờ",
  IDG_00010202: "Lỗi dữ liệu ảnh đầu vào ocr",
  IDG_00010301: "Lỗi nhận dạng ký tự trên ảnh giấy tờ mặt trước",
  IDG_00010302: "Lỗi dữ liệu ảnh đầu vào ocr mặt trước",
  IDG_00010401: "Lỗi nhận dạng ký tự trên ảnh giấy tờ mặt sau",
  IDG_00010402: "Lỗi dữ liệu ảnh đầu vào ocr mặt sau",
  IDG_00010444: "Lỗi định dạng dữ liệu nhập vào",
  IDG_00010445: "Lỗi không đúng luồng giấy tờ",
  IDG_00010501: "Lỗi so sánh mặt",
  IDG_00010502: "Lỗi dữ liệu ảnh đầu vào so sánh mặt",
  MSG5053: " Nội dung không được vượt quá 4000 ký tự",
  EKYC0001: "[Face] Bạn chưa chụp ảnh chân dung",
  EKYC0002: "Lỗi upload ảnh vui lòng kiểm tra lại",
  EKYC0003: "Khuôn mặt không khớp",
  MSG5054: "Chỉ được sửa tiểu khoản mặc định chính chủ",
  MSG5055: "Chỉ được lấy dữ liệu trong vòng @0 ngày",
  MSG5056: "Quý khách vui lòng lấy dữ liệu xác nhận lệnh trong vòng @0 ngày",
  EKYC0004: "[Front] Bạn chưa chọn ảnh mặt trước",
  EKYC0005: "[Back] Bạn chưa chọn ảnh mặt sau",
  EKYC0006: "Lỗi upload ảnh vui lòng kiểm tra lại",
  EKYC0007: "Số CMND/CCCD không đúng hoặc đã tồn tại",
  MSG5057: "Bạn phải nhập số điện thoại hoặc địa chỉ email",
  MSG3899: "Không tìm thấy thông tin triển khai của Chiến thuật @0.",
  MSG3900: "Trạng thái triển khai (@0) không hợp lệ để thực hiện đặt lệnh.",
  MSG3901:
    "Không thể mở deal mới khi triển khai (@0) đang tồn tại một deal đang kích hoạt (@1).",
  MSG3902:
    "Không tìm thấy deal đang mở để đặt lệnh đóng vị thế cho triển khai (@0) của chiến thuật.",
  MSG3903: "Trạng thái deal(@0,@1) không hợp lệ để tạo lệnh đóng vị thế",
  MSG3904: "Không tìm thấy mã chứng khoán @0 để thực hiện tạo lệnh cho BOT",
  MSG3905: "Mã triển khai không hợp lệ",
  MSG3906: "Loại điều kiện (Entry/Exit) không được để trống",
  MSG3907: "Thông tin mã chứng khoán không hợp lệ",
  MSG3908: "Không tìm thấy StrategyRecord có mã @0.",
  MSG3909: "Chỉ được phép đặt lệnh đóng với lệnh mở vị thế đã khớp.",
  MSG3910: "Thông tin input không hợp lệ (Mã entry null).",
  MSG3911: "Thông tin input không hợp lệ (Ngày giao dịch null).",
  MSG3912: "Lệnh của bạn tạm thời không được sửa/hủy.",
  MSG4092:
    "Chiến thuật đang triển khai. Quý khách vui lòng dừng hết các chiến thuật đang triển khai và thử lại",
  MSG4093: "Không tìm thấy thông tin chiến thuật",
  MSG5002: "Số tiểu khoản không được để trống",
  MSG5003: "Mã trái phiếu không được để trống",
  MSG5004: "Kỳ hạn trái phiếu không được để trống",
  MSG5005: "Khối lượng trái phiếu không được để trống",
  MSG5006: "Không tìm thấy thông tin tiểu khoản @0",
  MSG5007: "Không tìm thấy thông tin mã trái phiếu @0",
  MSG5008: "Không tìm thấy sản phẩm bán lẻ mã trái phiều @0 kỳ hạn @1 tháng.",
  MSG5009: "Mã RBond orde không được để trống.",
  MSG5010: "Rbond order UpDateTime is not null.",
  MSG5011: "Không tìm thấy dữ liệu Rbond order @0.",
  MSG5012:
    "Dữ liệu Rbond order đã thay đổi, bạn hãy làm mới trước khi thực hiện.",
  MSG5013: "Tiểu khoản của bạn không đủ số dư để thực hiện lệnh mua.",
  MSG5014: "Bạn chỉ được hủy các lệnh có trạng thái 0: Chưa duyệt",
  MSG5015: "Khối lượng trái phiếu tối thiểu được phép mua là @0.",
  MSG5016: "Khối lượng trái phiếu tối đa được phép mua là @0.",
  MSG5017: "Không thực hiện được lệnh của chính mình",
  MSG5018: "Dữ liệu đầu vào không đúng định dạng (@0)",
  MSG5019: "Số tài khoản nhận chuyển nhượng không hợp lệ",
  MSG5020: "Số tài khoản chuyển nhượng không hợp lệ",
  MSG5021: "Mã trái phiếu không hợp lệ",
  MSG5022: "Khối lượng chuyển nhượng không hợp lệ",
  MSG5023: "Giá chuyển nhượng không hợp lệ",
  MSG5024: "Không tìm thấy thông tin tài khoản nhận chuyển nhượng (@0)",
  MSG5025: "Không tìm thấy thông tin tài khoản chuyển nhượng (@0)",
  MSG5026: "Không tìm thấy thông tin trái phiếu mã @0",
  MSG5027:
    "Tài khoản chuyển nhượng không đủ trái phiếu để thực hiện chuyển nhượng",
  MSG5028:
    "Tài khoản nhận chuyển nhượng không khả dụng để thực hiện chuyển nhượng",
  MSG5029: "Tài chuyển nhượng chưa có thông tin trái chủ.",
  MSG5030: "Không tìm thấy thông tin lệnh để thực hiện yêu cầu hủy",
  MSG5031: "Trạng thái lệnh đã thay đổi, bạn không thể thực hiện hủy",
  MSG5032: "Độ dài ghi chú không vượt quá 200 ký tự",
  MSG5033: "Bạn chưa nhập loại lãi.",
  MSG5034: "Tài khoản đã ngừng Giao dịch tạm thời.",
  MSG5036: "Không tìm thấy thông tin tiểu khoản chuyển (@0)",
  MSG5037: "Không tìm thấy thông tin tiểu khoản nhận (@0)",
  MSG5038: "Không tìm thấy thông tin mã trái phiếu (@0)",
  MSG5039: "Hệ thống chưa đến giờ nhận chuyển trái phiếu (@0)",
  MSG5040: "Đã hết giờ nhận chuyển trái phiếu (@0)",
  MSG5041: "Trạng thái yêu cầu không hợp lệ để thực hiện hủy",
  MSG5042: "Hợp đồng khung của TK @0 không hợp lệ hoặc đã hết hạn",
  MSG5048: "Hợp đồng khung của Quý khách không hợp lệ hoặc đã hết hạn",
  MSG5049: "Không tìm thấy thông tin tiểu khoản @0",
  MSG5050: "Phương thức thanh toán của TK @0 không hợp lệ",
  MSG5051: "Không tìm thấy thông tin trái chủ",
  MSG5052:
    "Quý khách cần chứng nhận là NĐT chuyên nghiệp để nhận chuyển nhượng. Vui lòng liên hệ hotline @0 để được hỗ trợ!",
  "100001": "Mã CK đang ở trạng thái đã thiết lập",
  "100002": "Mã Index đang ở trạng thái đã thiết lập",
  "100003": "Ngày hiệu lực đang lớn hơn ngày hết hạn",
  "100004": "Không tìm thấy thông tin mã chứng khoán",
  "100005": "Mã Index không được rỗng",
  "100006": "Mã CK không được rỗng",
  "100007": "Account No không được để trống",
  "100008": "Khoảng thời gian tìm kiếm vượt quá 30 ngày",
  "100009": "Can not found info marketQuotes, marketCd: @0",
  "100010": "Not found tradeDate redis",
};
export default errors;
