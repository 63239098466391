import { TextColor } from "components/commons/WidgetTable/styles";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import themes from "themes/abstracts/_themes";
import { ThemeData } from "themes/types";

interface Props {
  value?: any;
}

function VolumeChange({ value }: Props) {
  const oldData = useRef(0);
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const { type } = useSelector((state: RootState) => state.theme);
  useEffect(() => {
    const _oldData = oldData.current;
    oldData.current = numeral(value).value() || 0;
    if (_oldData !== value && _oldData) {
      setBackgroundColor("solidFlash");
    }
    const timeout = setTimeout(() => setBackgroundColor(""), 500);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [numeral(value).value()]);
  return (
    <TextColor
      style={{
        backgroundColor: themes[type][
          backgroundColor as keyof ThemeData
        ] as string,
      }}
    >
      {value}
    </TextColor>
  );
}
export default VolumeChange;
