import OrderBondAdvContent from "./components";
import Container from "components/commons/Container";
import { useIntl } from "react-intl";
import RequestDetail from "./components/AdvanceContent/RequestDetail";

function PlaceOrderBondAdv() {
  const intl = useIntl();
  const arrayForm = [OrderBondAdvContent, RequestDetail];

  return (
    <Container
      headerHistory={intl.formatMessage({
        id: "placeOrderBondAdv.titleHistory",
      })}
      headerForm={intl.formatMessage({
        id: "placeOrderBondAdv.titleBondAdv",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
}

export default PlaceOrderBondAdv;
