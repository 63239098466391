/* eslint-disable react-hooks/exhaustive-deps */
import { Select, Button } from "components/commons";
import { memo, useEffect, useMemo, useState } from "react";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import { useIntl } from "react-intl";
import { tradeDateSelector } from "modules/auth/selectors";
import { useSelector } from "react-redux";
import { findCbotBondPriceEstApi } from "core/http/apis/advertisingOrder";
import { toast } from "components/commons/Toast";
import { tradeOption } from "modules/advertisingOrder/pages/AdvertisingOrder/components/constants";

interface PROPS {
  loading: boolean;
  submitForm: () => void;
  changeForm: (value: any, key: string) => void;
}

const Filter = (props: PROPS) => {
  const { loading, submitForm, changeForm } = props;
  // hook
  const intl = useIntl();
  const tradeTypeOptions = useMemo(() => tradeOption(intl), [intl]);
  const tradeDate = useSelector(tradeDateSelector);

  const [bondOptions, setBondOption] = useState<any[]>([]);

  const getOptionBond = async () => {
    const res: any = await findCbotBondPriceEstApi({
      tradeDate: tradeDate,
      bondCd: null,
    });
    if (res?.statusCode === 0) {
      setBondOption(res?.data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    getOptionBond();
  }, []);

  return (
    <Wrap
      className="d-flex justify-content-between align-items-center"
      style={{ boxShadow: "none" }}
    >
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "advertisingOrder.search.bond",
          })}
          className="marginRight text-grey"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              placeholder={intl.formatMessage({
                id: "advertisingOrder.search.placeBond",
              })}
              options={bondOptions ? bondOptions : []}
              getValue={"bondCd"}
              getLabel={"bondCd"}
              onChange={(e: any) => changeForm(e?.bondCd, "bondCd")}
              isClearable
            />
          </div>
        </FilterItem>
        <FilterItem
          filterName={intl.formatMessage({
            id: "advertisingOrder.search.tradeType",
          })}
          className="marginRight text-grey"
        >
          <div className="text-white">
            <Select
              className="flex-fill"
              placeholder={intl.formatMessage({
                id: "advertisingOrder.search.placeTradeType",
              })}
              defaultValue={tradeTypeOptions[0]}
              options={tradeTypeOptions}
              onChange={(e: any) => changeForm(e?.value, "tradeType")}
            />
          </div>
        </FilterItem>

        <Button
          color="accept"
          className="button-filter1 mr-4"
          loading={loading}
          onClick={() => submitForm()}
        >
          {intl.formatMessage({
            id: "advertisingOrder.search.btnSearch",
          })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
};

export default memo(Filter);
