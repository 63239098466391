const errors = {
  defaultErr: "Đã có lỗi xảy ra, vui lòng kiểm tra lại",
  errorServer: "Lỗi kết nối đến server, vui lòng thử lại sau",
  MSG9999: "Lỗi hệ thống (Lỗi gọi từ front sang back)",
  MSG1001: "Lỗi hệ thống",
  MSG1002: "Mã chứng khoán không tồn tại",
  MSG1003: "Giá nhập nhỏ hơn giá sàn",
  MSG1004: "Giá nhập lớn hơn giá trần",
  MSG1005: "Mật khẩu giao dịch không đúng, bạn hãy thử lại.",
  MSG1006: "Trạng thái không hợp lệ!",
  MSG1007: "Ngày sinh nhập vào không đúng!",
  MSG1008: "Ngày cấp không đúng!",
  MSG1009: "Trạng thái không hợp lệ!",
  MSG1010: "Chưa thiết lập thời gian giao dịch trực tuyến cho sàn @0",
  MSG1011: "Đã hết thời gian giao dịch trực tuyến, bạn không thể đặt lệnh",
  MSG1012: "ID truyền vào không được phép null",
  MSG1013: "Mã CK không được phép bỏ trống",
  MSG1014: "Mã CK nhập vào không được phép dài quá @0 ký tự",
  MSG1015: "Bản ghi đang thao tác không tồn tại",
  MSG1016: "Password đặt lệnh chưa nhập",
  MSG1017: "Phiên giao dịch của Quý khách đã hết, vui lòng đăng nhập lại.",
  MSG1018: "Mã chứng khoán bị ngừng giao dịch báo giá",
  MSG1019: "Thị trường không đủ room để thực hiện giao dịch",
  MSG1020: "Mã chứng khoán mới không được phép giao dịch lệnh",
  MSG1021: "Tài khoản không được phép truy cập hệ thống.",
  MSG1022: "Tài khoản đăng nhập không hợp lệ",
  MSG1023: "Tài khoản đăng nhập có tối đa 20 ký tự.",
  MSG1024: "Loại tài khoản không hợp lệ",
  MSG1025: "Hệ thống không hỗ trợ kênh giao dịch @0",
  MSG1026: "Tài khoản @0 chưa đăng ký số điện thoại nhận tin nhắn",
  MSG1027:
    "Tài khoản @0 chưa được cấp ma trận xác thực, bạn hãy liên hệ nhân viên chăm sóc để được hỗ trợ.",
  MSG1028: "Dữ liệu input không hợp lệ",
  MSG1029: "Từ Ngày không hợp lệ",
  MSG1030: "Đến Ngày không hợp lệ",
  MSG1031: "Từ ngày, đến ngày không hợp lệ",
  MSG1032: "Mật khẩu đăng nhập không được để trống",
  MSG1033: "Mã người dùng không được để trống",
  MSG1034: "Loại người dùng không hợp lệ",
  MSG1035:
    "Địa chỉ IP đang bị tạm khóa truy cập hệ thống, bạn hãy thử lại sau 15 phút",
  MSG1036: "Giá nhập không hợp lệ",
  MSG1037: "Không hỗ trợ đặt lệnh lô lẻ với sàn HSX",
  MSG1038: "Khối lượng nhập không hợp lệ",
  MSG1039: "Danh sách lệnh không hợp lệ",
  MSG1040: "Giá khuyến nghị BÁN phải lớn hơn 0",
  MSG1041: "Giá khuyến nghị MUA phải lớn hơn 0",
  MSG1042: "Ngày tạo không hợp lệ",
  MSG1043: "Dữ liệu input không hợp lệ",
  MSG1044: "Số hiệu lệnh hủy không hợp lệ",
  MSG1045: "Ngày giao dịch không hợp lệ",
  MSG1046: "Không tìm thấy thông tin lệnh để thực hiện hủy",
  MSG1047: "Thông tin lệnh đã thay đổi, bạn hãy làm mới dữ liệu.",
  MSG1048: "Trạng thái lệnh không hợp lệ để tạo yêu cầu hủy.",
  MSG1049: "Không tìm thấy thông tin chứng khoán Phái sinh @0",
  MSG1050: "Không tìm thấy thông tin sàn @0",
  MSG1051: "Giá chờ không hợp lệ để thực hiện sửa lệnh",
  MSG1052: "KL chờ khớp không đủ để thực hiện sửa lệnh",
  MSG1053: "Giá sửa không hợp lệ",
  MSG1054: "Khối lượng sửa không hợp lệ",
  MSG1055: "Tài khoản BÁN không hợp lệ",
  MSG1056: "Tài khoản MUA không hợp lệ",
  MSG1057: "Ngày giao dịch không hợp lệ",
  MSG1058: "Thông tin sàn không hợp lệ",
  MSG1059: "Thông tin số hiệu lệnh không hợp lệ",
  MSG1060: "Không tìm thấy thông tin lệnh để thực hiện lệnh hủy.",
  MSG1061: "Trạng thái lệnh không hợp lệ để thực hiện lệnh hủy.",
  MSG1062: "Không tìm thấy thông tin yêu cầu thỏa thuận.",
  MSG1063: "Trạng thái lệnh không hợp lệ để thực hiện thỏa thuận.",
  MSG1064: "Bạn chưa nhập Từ ngày.",
  MSG1065: "Bạn chưa nhập Đến ngày.",
  MSG1066: "Bộ lọc Từ ngày phải nhỏ hơn Đến ngày.",
  MSG1067: "Thị trường đóng cửa, bạn không thể đặt lệnh",
  MSG1068: "Trạng thái phiên không hợp lệ để thực hiện đặt lệnh",
  MSG1069: "Phiên AVAILABLE hệ thống không cho phép đặt lệnh thỏa thuận",
  MSG1070: "Phiên CALL_AUCTION_OPENING không cho phép hủy/sửa với lệnh ATO, LO",
  MSG1071: "Phiên trước giờ mở cửa không cho phép đặt lệnh @0",
  MSG1072: "Phiên @0 không cho phép sửa lệnh @1",
  MSG1073: "Phiên @0 không cho phép hủy/sửa với lệnh @1",
  MSG1074: "Mã chứng khoán đang tạm ngưng giao dịch do ngày nghỉ",
  MSG1075: "Mã chứng khoán đang tạm ngưng giao dịch",
  MSG1076: "Chưa thiết lập địa chỉ xác thực LDAP",
  MSG1077: "Không có kết quả trả về từ hệ thống xác thực LDAP",
  MSG1078: "Không lấy được thông tin kết quả trả về từ hệ thống xác thực LDAP",
  MSG1079: "Mã chứng khoán đang tạm ngưng giao dịch",
  MSG1080: "Mã chứng khoán đang hạn chế giao dịch",
  MSG1081: "Mã chứng khoán đã hết hạn",
  MSG1082: "Mã thành viên nhận thỏa thuận không hợp lệ",
  MSG1083: "Phiên KLĐK đóng cửa không cho phép đặt lệnh @0",
  MSG1084: "Phiên KLĐK đóng cửa không cho phép sửa lệnh @0",
  MSG1085: "Phiên KLĐK mở cửa không cho phép đặt lệnh @0",
  MSG1086: "Phiên KLĐK mở cửa không cho phép hủy/sửa lệnh @0",
  MSG1087: "Phiên KLĐK mở cửa không cho phép sửa lệnh @0",
  MSG1088: "Loại Mua/Bán không hợp lệ.",
  MSG1089: "Khối lượng đặt không hợp lệ.",
  MSG1090: "Giá đặt không hợp lệ.",
  MSG1091: "Không tìm thấy yêu cầu thỏa thuận số @0.",
  MSG1092: "Người dùng chưa được khai báo ma trận.",
  MSG1093: "Mã người dùng không hợp lệ.",
  MSG1094: "Loại tài khoản không hợp lệ.",
  MSG1095: "Số CMT/Hộ chiếu không hợp lệ.",
  MSG1096: "Ngày cấp CMT/Hộ chiếu không hợp lệ.",
  MSG1097: "Số điện thoại/Email không được để trống.",
  MSG1098: "Không tìm thấy thông tin người dùng trong hệ thống.",
  MSG1099:
    "Trạng thái người dùng đang bị khóa, bạn hãy liên hệ nhân viên hỗ trợ khách hàng để được trợ giúp.",
  MSG1100: "Số CMT/Hộ chiếu không đúng.",
  MSG1101: "Ngày cấp CMT/Hộ chiếu không đúng.",
  MSG1102:
    "Quý khách không đăng ký sử dụng dịch vụ. Quý khách vui lòng liên hệ bộ phận CSKH để được hỗ trợ",
  MSG1103:
    "Số điện thoại không đúng với thông tin đã đăng ký với công ty. Quý khách vui lòng kiểm tra lại thông tin",
  MSG1104:
    "Địa chỉ email không đúng với thông tin đã đăng ký với công ty. Quý khách vui lòng kiểm tra lại thông tin",
  MSG1105: "Dữ liệu xác thực không hợp lệ",
  MSG1106: "Không tìm thấy thông tin xác thực",
  MSG1107: "Mã xác thực không chính xác, bạn vui lòng thử lại",
  MSG1108:
    "Yêu cầu xác thực của bạn đã hết hạn, bạn vui lòng nhập lại thông tin",
  MSG1109:
    "KL giao dịch không được nhỏ hơn KL tối thiểu của một lần giao dịch (KL tối thiểu: @0)",
  MSG1110: "KL giao dịch không được nhỏ hơn KL tối thiểu (KL tối thiểu: @0)",
  MSG1111: "Mã tiểu khoản không được để trống",
  MSG1112: "Mã trái phiếu không được để trống",
  MSG1113: "Khối lượng mua/bán trái phiếu không hợp lệ",
  MSG1114: "Giá mua/bán trái phiếu không hợp lệ",
  MSG1115: "Ngày mua/bán không hợp lệ",
  MSG1116: "Loại mua/bán không hợp lệ",
  MSG1117: "Ngày mua/bán lại không hợp lệ",
  MSG1118: "Số ngày mua/bán lại phải lớn hơn @0",
  MSG1119: "Số ngày mua/bán lại phải nhỏ hơn @0",
  MSG1120: "Hệ thống không hỗ trợ đặt lệnh trái phiếu sàn @0",
  MSG1121: "Bạn không có quyền thực hiện chức năng @0",
  MSG1122: "Không tìm thấy thông tin lệnh thỏa thuận trái phiếu",
  MSG1123: "Số CMT/Hộ chiếu @0 đã tồn tại trong CSDL",
  MSG1124: "Mã chứng khoán @0 không nằm trong danh mục mua của CTCK",
  MSG1125:
    "Hiện tại không có đợt mua chứng khoán lẻ của CTCK, bạn hãy liên hệ để được trợ giúp.",
  MSG1126: "Ngoài đợt mua CK lẻ của CTCK, bạn hãy liên hệ để được trợ giúp.",
  MSG1127: "Lệnh có SHL @0 đã tồn tại trong CSDL.",
  MSG1128: "Khách hàng chưa đăng ký địa chỉ Email/Mobile nhận mã xác thực OTP.",
  MSG1129: "Ngày hết hạn không hợp lệ.",
  MSG1130: "Không tìm thấy thông tin tài khoản đối ứng.",
  MSG1131: "Lệnh không thay đổi. Sửa lệnh không thành công.",
  MSG1132:
    "Trạng thái người dùng chưa kích hoạt, bạn hãy liên hệ nhân viên hỗ trợ khách hàng để được trợ giúp.",
  MSG1133:
    "Tài khoản của Quý khách chưa được kích hoạt, Quý khách vui lòng hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp",
  MSG1134: "Mã người dùng không tồn tại trong hệ thống.",
  MSG1135:
    "Không tìm thấy ma trận xác thực, bạn hãy liên hệ bộ phận IT để được trợ giúp.",
  MSG1136:
    "Hệ thống không hỗ trợ đặt lệnh trước giờ, bạn hãy liên hệ bộ phận chăm sóc khách hàng để được trợ giúp.",
  MSG1137: "Mật khẩu Token không đúng, vui lòng nhập lại!",
  MSG1138: "Xác thực không thành công, vui lòng liên hệ CTCK để được hỗ trợ!",
  MSG1139: "Dữ liệu input không hợp lệ.",
  MSG1140: "Số hiệu lệnh không hợp lệ.",
  MSG1141: "Thông tin sàn không hợp lệ.",
  MSG1142: "Chưa thiết lập khối lượng cho lệnh lô lớn sàn @0.",
  MSG1143: "Thời gian nhận yêu cầu từ @0 đến @1.",
  MSG1144: "Ngày giao dịch không hợp lệ.",
  MSG1145: "Không tìm thấy yêu cầu thỏa thuận.",
  MSG1146: "Không hỗ trợ đặt lệnh phiên @0.",
  MSG1147: "Mật khẩu phải có ký tự chữ (không phân biệt hoa thường).",
  MSG1148: "Bạn chưa nhập giá sàn",
  MSG1149: "Bạn chưa nhập giá tham chiếu",
  MSG1150: "Bạn chưa nhập giá trần",
  MSG1151: "Hệ thống nhận lệnh trước giờ không hỗ trợ loại lệnh @0 với sàn @1",
  MSG1152: "Mã chứng khoán @0 đã tồn tại trong hệ thống",
  MSG1153:
    "CTCK đang tạm dừng mua cổ phiếu lẻ sàn @0, bạn hãy liên hệ bộ phận chăm sóc khách hàng để được trợ giúp.",
  MSG1154:
    "Khối lượng lệnh nhân/chia tự động vượt quá giới hạn cho phép của hệ thống (@0).",
  MSG1155: "Khối lượng đầu tư chưa nhập",
  MSG1156: "Giá trị đầu tư chưa nhập",
  MSG1157: "Giá vốn bình quân chưa nhập",
  MSG1158: "CMND của bạn đã hết hạn, không thể thực hiện yêu cầu",
  MSG1159: "Cờ giải chấp không hợp lệ",
  MSG1160: "Bạn chưa nhâp ghi chú",
  MSG1161: "Tài khoản phái sinh không hợp lệ",
  MSG1162: "Loại lệnh điều kiện không hợp lệ",
  MSG1163: "Mã chứng khoán Phái sinh không hợp lệ",
  MSG1164: "Mã chứng khoán Phái sinh có tối đa @0 ký tự",
  MSG1165: "Khối lượng không hợp lệ",
  MSG1166: "Giá dừng không hợp lệ",
  MSG1167: "Biên độ cắt lỗ không hợp lệ",
  MSG1168: "Biên độ chốt lãi không hợp lệ",
  MSG1169: "Giá cắt lỗ không hợp lệ",
  MSG1170: "Biên độ chốt lãi không hợp lệ",
  MSG1171: "Biên độ cắt lỗ không hợp lệ",
  MSG1172: "Khoảng dừng không hợp lệ",
  MSG1173: "Không tìm thấy thông tin lệnh điều kiện tradedata: @0, id: @1",
  MSG1174: "Dữ liệu đã thay đổi bạn hãy làm mới",
  MSG1175: "Bạn chưa nhập hiệu lực từ ngày - đến ngày",
  MSG1176: "Bạn chỉ thực hiện thỏa thuận với các thành viên đích danh: @0",
  MSG1177: "Phiên KLLT không cho đặt lệnh lệnh ATO",
  MSG1178_100:
    "Sàn giao dịch HSX chưa sẵn sàng nhận lệnh, bạn hãy chờ trong giây lát",
  MSG1178_200:
    "Sàn giao dịch HNX chưa sẵn sàng nhận lệnh, bạn hãy chờ trong giây lát",
  MSG1178_300:
    "Sàn giao dịch UpCOM chưa sẵn sàng nhận lệnh, bạn hãy chờ trong giây lát",
  MSG1178_400:
    "Sàn giao dịch OTC chưa sẵn sàng nhận lệnh, bạn hãy chờ trong giây lát",
  MSG1179: "Giá nhập không hợp lệ",
  MSG1180: "Dữ liệu input không hợp lệ (@0)",
  MSG1181:
    "Quý khách chưa đăng ký mở tài khoản giao dịch phái sinh. Để được hỗ trợ mở tài khoản phái sinh, Quý khách vui lòng liên hệ Bộ phận chăm sóc khách hàng.",
  MSG1182: "Lỗi từ hệ thống BAS: (-102)Người dùng không tồn tại",
  MSG1183: "Lỗi từ hệ thống BAS: (-603)Xác thực OTP thất bại",
  MSG1184: "Chưa kích hoạt sử dụng OTP",
  MSG1185: "Lỗi từ hệ thống BAS: (-704)Dịch vụ đã bị huỷ",
  MSG1186: "Có lỗi khi thực hiện xác thực từ máy chủ BAS",
  MSG1187: "Người dùng không tồn tại",
  MSG1188: "Chưa kích hoạt sử dụng OTP/Thiết bị chưa đăng ký",
  MSG1189: "Dịch vụ chưa được đăng ký",
  MSG1190: "Có lỗi khi thực hiện hủy dịch vụ từ máy chủ BAS",
  MSG1191: "Có lỗi khi thực hiện kết nối máy chủ BAS",
  MSG1192: "Tài khoản chuyển không được để trống",
  MSG1193_1: "Không thể load được dữ liệu đã ký",
  MSG1193_2: "Không tìm thấy chữ ký số trong dữ liệu",
  MSG1193_3: "Chữ ký đã bị thay đổi",
  MSG1193_4: "Đường dẫn tin tưởng (TrustPath) không hợp lệ",
  MSG1193_5: "Không tìm thấy chứng thư số của CA",
  MSG11931: "Chứng thư số chưa đến hạn sử dụng",
  MSG11932: "Chứng thư số đã hết hạn",
  MSG11933: "Chứng thư số không có quyền ký",
  MSG11934: "Chứng thư số đã bị thu hồi",
  MSG11935: "Không thể kiểm tra trạng thái chứng thư số",
  MSG11936:
    "Không thể kiểm tra chứng thư số qua OCSP: Không tồn tại url trong chứng thư số",
  MSG11937: "Không tìm thấy chứng thư số trong chữ ký",
  MSG11938: "Không thể kiểm tra chứng thư số qua OCSP: response null",
  MSG11939: "Không thể kiểm tra chứng thư số qua CRL",
  MSG119310: "Không thể kiểm tra chứng thư số qua OCSP: url không tồn tại",
  MSG119311: "Chữ ký trong OCSP response không hợp lệ",
  MSG119312: "Không thể khởi tạo OCSP request",
  MSG119313: "Chữ ký số của CA trên chứng thư số không hợp lệ",
  MSG119314: "Dùng cho ký CMS: Không có dữ liệu trong chữ ký",
  MSG1194: "Dữ liệu ký không hợp lệ",
  MSG1195: "Số CMND người đại diện không hợp lệ",
  MSG11961:
    "Tài khoản @0 chỉ được phép BÁN mã @1 với mức giá trong khoảng @2-@3",
  MSG11962:
    "Tài khoản @0 chỉ được phép MUA mã @1 với mức giá trong khoảng @2-@3",
  MSG1197: "Có lỗi khi thực hiện xác thực Smart OTP, mã lỗi: @0",
  MSG1198: "Có lỗi khi thực hiện hủy kích hoạt thiết bị SmartOTP, mã lỗi: @0",
  MSG1199: "Lỗi từ hệ thống BAS: (-201)Không tôn tại cấu hình config",
  MSG1200: "Lỗi từ hệ thống BAS: (-401)Không thể kết nối đến CSDL",
  MSG1201: "Lỗi từ hệ thống BAS: (-501)Chức năng chưa khả dụng",
  MSG1202: "Lỗi từ hệ thống BAS: (-604)Xác thực mật khẩu thất bại",
  MSG1203: "Lỗi từ hệ thống BAS: (-605)Không thể sinh khóa bí mật",
  MSG1204:
    "Lỗi từ hệ thống BAS: (-606)Mã xác thực để reset mã pin không chính xác",
  MSG1205: "Lỗi từ hệ thống BAS: (-700)Token đã tồn tại",
  MSG1206: "Lỗi từ hệ thống BAS: (-701)Token không tồn tại",
  MSG1207: "Lỗi từ hệ thống BAS: (-702)Token đã được active",
  MSG1208: "Lỗi từ hệ thống BAS: (-703)Token chưa được active",
  MSG1209: "Lỗi từ hệ thống BAS: (-705)Thông tin token đã tồn tại",
  MSG1210:
    "Lỗi từ hệ thống BAS: (-706)Bạn đã gửi yêu cầu kích hoạt quá nhiều lần",
  MSG1211: "Lỗi từ hệ thống BAS: (-707)Token không được phép",
  MSG1212: "Lỗi từ hệ thống BAS: (-708)Lỗi khi kích hoạt token",
  MSG1213: "Lỗi từ hệ thống BAS: (-900)Dữ liệu đầu vào không đúng định dạng",
  MSG1214: "Lỗi từ hệ thống BAS: (-901)Không thể cập nhật vào CSDL",
  MSG1215: "Lỗi từ hệ thống BAS: (-902)Có lỗi trong quá trình xử lý",
  MSG1216: "Lỗi từ hệ thống BAS: (-909)Dữ liệu json không đúng định dạng",
  MSG1217: "Lỗi từ hệ thống BAS: (-910)Không thể gửi SMS",
  MSG1218: "Tiểu khoản không hợp lệ để thực hiện nộp ký quỹ (hậu tố 01->50)",
  MSG1219: "Tiểu khoản không hợp lệ để thực hiện rút ký quỹ (hậu tố 51)",
  MSG1220: "Số CIF không hợp lệ",
  MSG1221: "Số CIF không tồn tại trong hệ thống",
  MSG1222: "Chưa thiết lập địa chỉ xác thực IPAY",
  MSG1223:
    "Quý khách chưa thể sử dụng  chức năng giao dịch chứng khoán. Liên hệ Công ty chứng khoán Công Thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG1224: "Lệnh thị trường BÁN giá đặt phải bằng giá sàn",
  MSG1225: "Lệnh thị trường MUA giá đặt phải bằng giá trần",
  MSG1226: "Tên khách hàng không hợp lệ",
  MSG1227: "Dữ liệu đầu vào(data) không hợp lệ.",
  MSG1228: "Dữ liệu đầu vào(mac) không hợp lệ.",
  MSG1229: "Dữ liệu đầu vào(data) không thể giải mã.",
  MSG1230: "Có lỗi khi tạo hmac",
  MSG1231: "Dữ liệu hmac không khớp.",
  MSG1232: "Không tìm thấy thông tin lệnh thỏa thuận 2Firm",
  MSG1233: "Không hỗ trợ hủy lệnh thỏa thuận với bên MUA",
  MSG1234: "Không tìm thấy thông tin mã chứng quyền @0.",
  MSG1235: "Mã chứng quyền @0 đã hết hạn giao dịch.",
  MSG2001: "Lỗi khi thực hiện kết nối gate",
  MSG2002: "System cannot connect to exchange",
  MSG2003: "Lỗi kết nối BankService",
  MSG2004: "Bank inquiry result incorrect format",
  MSG2005: "Chưa thiết lập địa chỉ kết nối BankGate Service",
  MSG2006: "Không hỗ trợ sự kiện [@0]",
  MSG2007: "Không hỗ trợ loại lệnh @0 với sàn HNX",
  MSG2008: "Không hỗ trợ loại lệnh @0 với sàn HSX",
  MSG2009: "Chưa thiết lập địa chỉ gửi mail (MailSendingQueue)",
  MSG2010: "Chưa thiết lập địa chỉ Queue sửa giá sàn HSX",
  MSG2011: "Chưa thiết lập địa chỉ Queue lệnh interval",
  MSG2012: "Chưa thiết lập địa chỉ server mail Adapter",
  MSG2013: "Có lỗi khi gửi lệnh interval",
  MSG2014:
    "Có lỗi khi thực hiện gửi mail, bạn hãy kiểm tra lại địa chỉ Mail Adapter",
  MSG2015: "Có lỗi khi thực hiện vấn tin sang ngân hàng @0",
  MSG2016S: "Không được phép sửa/hủy lệnh trong Phiên trước giờ mở cửa",
  MSG2016P: "Không được phép sửa/hủy lệnh trong Phiên KLĐK (ATO/ATC)",
  MSG2016I: "Không được phép sửa/hủy lệnh trong thời gian nghỉ giữa phiên",
  MSG2016A: "Không được phép sửa/hủy lệnh trong Phiên KLĐK (ATO/ATC)",
  MSG2016C: "Không được phép sửa/hủy lệnh trong Phiên thỏa thuận",
  MSG2016K: "Không được phép sửa/hủy lệnh khi kết thúc giao dịch",
  MSG2017: "Lệnh PLO đang chờ khớp không được phép sửa/hủy.",
  MSG2018: "Thao tác chỉ hỗ trợ với lệnh có trạng thái D: Hủy bởi CTCK",
  MSG2019: "Bạn chưa nhập lý do chuyển trạng thái lệnh trước giờ",
  MSG2020: "Lý do chuyển trạng thái không được quá 200 ký tự",
  MSG2021: "Sai ký tự xác nhận",
  MSG3001: "RequestType phải là đặt lệnh thỏa thuận 2Firm",
  MSG3002: "Không được để trống số tài khoản",
  MSG3003: "Số tài khoản gồm 13 ký tự",
  MSG3004: "Không được để trống mã chứng khoán",
  MSG3005: "Không được để trống thành viên mua thỏa thuận 2Firm",
  MSG3006: "Không được để trống đại diện mua thỏa thuận 2Firm",
  MSG3007: "Loại lệnh phải là lệnh bán",
  MSG3008: "Không xác định được giá chứng khoán",
  MSG3009: "Không xác định được trạng thái thị trường",
  MSG3010: "Không xác định được tài khoản",
  MSG3011: "Không xác định được khách hàng",
  MSG3012: "Không tìm thấy thiết lập giao dịch của sàn",
  MSG3013: "Giá đặt không được thấp hơn giá sàn",
  MSG3014: "Giá đặt không được cao hơn giá trần",
  MSG3015: "Không xác định được khoảng giá của mã chứng khoán",
  MSG3016: "Đặt lệnh sai bước giá",
  MSG3017: "Chưa thiết lập lô của sàn",
  MSG3018: "Khối lượng đặt không tròn lô",
  MSG3019: "Chưa thiết lập tham số khối lượng có thể thỏa thuận",
  MSG3020: "Khối lượng tối thiểu để thực hiện thỏa thuận là @0",
  MSG3021: "Chưa thiết lập tham số khối lượng bắt buộc phải thỏa thuận",
  MSG3022: "Khối lượng quá lớn để đặt lệnh báo giá",
  MSG3023: "RequestType phải là trả lời lệnh thỏa thuận 2Firm",
  MSG3024: "Loại lệnh phải là lệnh mua",
  MSG3025: "Không xác định được số hiệu lệnh",
  MSG3026: "Không xác định được ngân hàng của tài khoản",
  MSG3027: "RequestType phải là trả lời sửa lệnh thỏa thuận 2Firm",
  MSG3028: "RequestType phải là trả lời hủy lệnh thỏa thuận 2Firm",
  MSG3029: "Không xác định được ConfirmNo",
  MSG3030:
    "Quý khách chưa có hợp đồng chuyển khoản hoặc hợp đồng đã hết hạn. Để sử dụng dịch vụ quý khách vui lòng liên hệ với bộ phận giao dịch công ty chứng khoán!",
  MSG3031: "Tài khoản nhận chưa có trong hợp đồng chuyển khoản",
  MSG3033: "Thao tác không thực hiện được. Hợp đồng đã chuyển trạng thái",
  MSG3034: "Không thể hủy",
  MSG3035: "Tài khoản mua không hợp lệ",
  MSG3036: "Tài khoản mua chỉ gồm 13 ký tự",
  MSG3037: "Tài khoản bán không hợp lệ",
  MSG3038: "Tài khoản bán chỉ gồm 13 ký tự",
  MSG3039: "Mã CK không hợp lệ",
  MSG3040: "Mã sàn không hợp lệ",
  MSG3041: "Số hiệu lệnh không hợp lệ",
  MSG3042: "TraderId không hợp lệ",
  MSG3043: "Không tìm thấy giá với symbol: @0",
  MSG3044: "Không tìm thấy sàn với mã: @0",
  MSG3045: "Không tìm thấy tiểu khoản: @0",
  MSG3046: "Không có thiết lập quyền cho: @0",
  MSG3047: "Không có quyền truy cập",
  MSG3048: "Market code không hợp lệ",
  MSG3049: "Market code phải là số",
  MSG3050: "Chưa thể xóa",
  MSG3051: "Chưa nhập mã CK",
  MSG3052: "Chưa nhập giá",
  MSG3053: "Chưa nhập khối lượng",
  MSG3054: "Nhập lệnh thành công",
  MSG3055: "Nhập lệnh lỗi",
  MSG3056: "RequestType phải là đặt lệnh 1 firm",
  MSG3057: "Dữ liệu lệnh không có thông tin tài khoản bán",
  MSG3058: "Dữ liệu lệnh không có thông tin mã chứng khoán",
  MSG3059: "Dữ liệu lệnh không chứa thông tin tài khoản mua",
  MSG3060: "Khối lượng thỏa thuận phải lớn hơn 0",
  MSG3061: "Giá thỏa thuận phải lớn hơn 0",
  MSG3062: "Không tìm thấy số tài khoản: @0",
  MSG3063: "Không tìm thấy chi nhánh với mã: @0",
  MSG3064: "Không tìm thấy tieu khoan ban: @0",
  MSG3065: "Order role is not setup for market",
  MSG3066: "RequestType phải là sửa lệnh thỏa thuận 1 Firm",
  MSG3067: "Không có điều kiện mới",
  MSG3068: "Số tài khoản không hợp lệ",
  MSG3069: "Số tài khoản phải lớn hơn 10 ký tự",
  MSG3070: "Mã CK không hợp lệ",
  MSG3071: "Confirm no không hợp lệ",
  MSG3072: "Market code phải lớn hơn 0",
  MSG3073: "Điểm giao dịch không hợp lệ",
  MSG3074: "Không tìm thấy dữ liệu",
  MSG3075: "Trader Id không hợp lệ",
  MSG3080: "Tài khoản không có hợp đồng chuyển khoản hoặc hết hiệu lực",
  MSG3081: "Tài khoản nhận không có trong hợp đồng chuyển khoản",
  MSG3082: "Số tiền chuyển khoản phải nhỏ hơn số khả dụng",
  MSG3083: "Số tiền chuyển khoản vượt quá hạn mức còn lại của công ty: @0",
  MSG3084: "Số lần chuyển khoản trong ngày lớn hơn giới hạn cho phép",
  MSG3085: "Số tiền chuyển phải lớn hơn số tiền tối thiểu @0",
  MSG3086: "Số tiền chuyển phải nhỏ hơn số tiền tối đa @0",
  MSG3087:
    "Thời gian chuyển khoản không hợp lệ. Chưa đến thời gian nhận chuyển khoản",
  MSG3088:
    "Thời gian chuyển khoản không hợp lệ. Đã hết thời gian nhận chuyển khoản",
  MSG3089:
    "Thời gian thực hiện không hợp lệ. Đã hết thời gian nhận chuyển khoản",
  MSG3090: "Ngày đăng ký không hợp lệ",
  MSG3091: "Ref no không hợp lệ",
  MSG3092: "Tiểu khoản không hợp lệ",
  MSG3093: "Bản ghi đã bị tác động. Bạn không thể sửa xóa .",
  MSG3094: "Trạng thái không hợp lệ",
  MSG3095: "Không tìm thấy Approvement",
  MSG3096: "Số lượng đăng ký phải lớn hơn 0",
  MSG3097: "Ngày đăng ký không hợp lệ",
  MSG3098: "Số lượng đăng ký không được lớn hơn số lượng còn lại",
  MSG3100: "Tiểu khoản chuyển không tồn tại",
  MSG3101: "Tiểu khoản nhận không tồn tại",
  MSG3102: "Tiểu khoản chuyển và tiểu khoản nhận phải thuộc cùng 1 tài khoản",
  MSG3103: "Mã chứng khoán không tồn tại",
  MSG3104: "Mã chứng khoán không có trong danh sách khả dụng",
  MSG3105: "Số lượng chứng khoán chuyển lớn hơn số lượng khả dụng",
  MSG3106: "Tài khoản không hợp lệ hoặc không có chứng khoán",
  MSG3107: "Tiểu khoản không được để trống",
  MSG3108: "Số tài khoản ngân hàng không được để trống",
  MSG3109: "Tên ngân hàng không được để trống",
  MSG3110: "Số tiền chuyển không được để trống",
  MSG3111: "Ngày tạo TT không đúng",
  MSG3112: "Ngày tạo TT không đúng",
  MSG3113: "Không xác định được ngày tạo (AlloDate)",
  MSG3114: "Thao tác không được hỗ trợ",
  MSG3115: "Số lượng không được để trống",
  MSG3116: "Không tìm thấy thông tin thực hiện quyền",
  MSG3117: "Không tìm thấy dữ liệu",
  MSG3118: "Tiểu khoản chuyển và Tiểu khoản nhận không được giống nhau",
  MSG3119: "Không được sửa bản ghi đã duyệt",
  MSG3120: "Không được sửa bản ghi đã hủy",
  MSG3121: "Số tiền không được để trống hoặc bằng 0",
  MSG3122: "Số lần đáo hạn không được để trống hoặc bằng 0",
  MSG3123: "Số kỳ hạn không được để trống hoặc bằng 0",
  MSG3124: "Loại kỳ hạn không xác định",
  MSG3125: "Tỷ lệ lãi trước hạn không xác định",
  MSG3126: "Tỷ lệ lãi trong hạn không xác định",
  MSG3127: "Cách tính lãi không xác định",
  MSG3128: "Decimal point không xác định",
  MSG3129: "Loại làm tròn không xác định",
  MSG3130: "Không đủ số dư",
  MSG3131: "Không xác định được thiết lập tính lãi tiền gửi",
  MSG3132: "Không xác định được thiếp lập tinh lãi tiền gửi tại ngày hiện tại",
  MSG3133: "Từ ngày phải nhỏ hơn Đến ngày",
  MSG3134: "Khoảng ngày không được trùng lặp",
  MSG3135: "Thêm mới thất bại",
  MSG3136: "Cập nhật bản ghi thất bại",
  MSG3137: "Broker không tồn tại",
  MSG3138: "Hạn mức cấp phải lớn hơn 0",
  MSG3139: "Hạn mức cấp không được lớn hơn hạn mức tối đa của user(@0)",
  MSG3140: "Loại hạn mức không được để trống",
  MSG3141: "Khả dụng broker cấp phải lớn hơn 0",
  MSG3142: "Brokre Code không được để trống",
  MSG3143: "Từ ngày không được để trống",
  MSG3144: "Đến ngày không được để trống",
  MSG3145: "Hạn mức không được để trống",
  MSG3146: "Hạn mức phải lớn hơn 0",
  MSG3147: "UserId đăng nhập không có quyền sửa bản ghi này",
  MSG3148: "Hạn mức sửa không hợp lệ do Hạn mức mới < Hạn mức đã sử dụng @0",
  MSG3149: "Hạn mức mới nhỏ hơn hạn mức đã dùng của broker @0",
  MSG3150: "Giá trị hạn mức thay đổi lớn hơn khả dụng của broker cấp",
  MSG3152: "User không tồn tại",
  MSG3153: "Mật khẩu đăng nhập hiện tại không đúng",
  MSG3154: "Chưa nhập mật khẩu giao dịch mới",
  MSG3155: "Mật khẩu giao dịch mới không khớp",
  MSG3156: "Mật khẩu giao dịch mới trùng mật khẩu cũ",
  MSG3157: "Số tiền chuyển lớn hơn khả dụng",
  MSG3158: "Số chứng thư đã được sử dụng",
  MSG3159: "Người dùng không tồn tại",
  MSG3160: "Trạng thái không hợp lệ",
  MSG3161: "Chưa khai báo được số tài khoản cho chi nhánh",
  MSG3162: "Trạng thái không hợp lệ!",
  MSG3163: "Trạng thái thị trường không hợp lệ.",
  MSG3164: "Không tìm thấy thông tin thị trường",
  MSG3165: "Số tiền bạn chuyển không hề thay đổi, vui lòng chọn lại!",
  MSG3166: "Không tìm thấy mã chứng khoán @0",
  MSG3167: "Không tìm thấy trạng thái thị trường sàn @0",
  MSG3168: "Chứ thiết lập role giao dịch cho sàn @0",
  MSG3169: "Không tìm thấy dữ liệu cần duyệt hoặc hủy",
  MSG3170: "Error: Exchange online not support",
  MSG3171: "Tài khoản không hợp lệ",
  MSG3172: "Độ dài dữ liệu (@0) không được vượt quá @1",
  MSG3173: "Trạng thái tiểu khoản không hợp lệ",
  MSG3174: "Tài khoản không có hiệu lực.",
  MSG3175: "Hệ thống đã tồn tại OderRole thêm mới!",
  MSG3176: "Hệ thống không còn tồn tại OrderRole cập nhật!",
  MSG3177: "Không xác định được lệnh gốc ứng trước",
  MSG3178: "Số tiền cần ứng vượt quá số tiền tối đa có thể ứng (Tối đa :  @0).",
  MSG3179: "Mã CK không hợp lệ",
  MSG3180: "Chưa nhập khối lượng",
  MSG3181: "Chưa nhập giá",
  MSG3182: "Chưa nhập danh sách thành viên",
  MSG3183: "Danh sách thành viên không đúng định dạng",
  MSG3184: "Khối lượng phải là số",
  MSG3185: "Giá không đúng định dạng",
  MSG3186: "Request type is invalid.",
  MSG3187: "Số tài khoản khách hàng không được để trống.",
  MSG3188: "Số tài khoản đối ứng không được để trống.",
  MSG3189: "Mã chứng khoán không được để trống",
  MSG3190: "Mã sàn không được để tróng",
  MSG3191: "Số hiệu lệnh không được để trống.",
  MSG3192: "Trader không được phép trống",
  MSG3193: "Hệ thống không còn tồn tại Tham số!",
  MSG3194: "Tham số thiết lập đã tồn tại!",
  MSG3195: "Tham số thiết lập không đúng!",
  MSG3196: "Không tìm thấy hợp đồng!",
  MSG3197: "SecPrice not found with symbol",
  MSG3198: "Market state not found with code",
  MSG3199: "Hệ thống không tồn tại tiểu khoản",
  MSG3200: "Order role is not setup for market",
  MSG3201: "Hệ thống chưa thiết lập lệnh giao dịch",
  MSG3202: "Loại lệnh thiết lập không đúng",
  MSG3203: "Loại lệnh không được phép giao dịch trong phiên",
  MSG3204: "Vấn tin sức mua lỗi",
  MSG3205: "Giá trị lệnh không được lớn hơn giá trị sức mua",
  MSG3206: "Khối lượng đặt lớn hơn tổng khối lượng khả dụng của kho",
  MSG3207: "Khối lượng đặt lớn hớn khối lượng còn",
  MSG3208: "Không tìm thấy thông tin lệnh",
  MSG3209: "Lệnh khớp hết không thể thực hiện hủy/sửa",
  MSG3210: "Trạng thái lệnh không được thực hiện hủy/sửa",
  MSG3211: "Câu hỏi không còn tồn tại!",
  MSG3212: "Khách hàng không đủ số dư tiền để đặt lệnh",
  MSG3213: "Khách hàng không đủ số dư chứng khoán để đặt lệnh",
  MSG3214: "Lệnh đã có yêu cầu hủy hoặc sửa",
  MSG3215: "Số tiền chuyển khoản phải nhỏ hơn số tiền tối đa @0",
  MSG3216: "Số lượng tiền khả dụng cần lớn hơn 0",
  MSG3217: "Số dư tiền của khách hàng không đủ để thực hiện lệnh mua",
  MSG3218: "Số dư chứng khoán không đủ để thực hiện lệnh bán",
  MSG3219: "Không tìm thấy thông tin lệnh gốc",
  MSG3220: "Trạng thái lệnh không hợp lệ để thực hiện sửa/hủy lệnh.",
  MSG3221:
    "Thời gian thực hiện không hợp lệ. Chưa đến thời gian nhận chuyển khoản",
  MSG3222:
    "Thời gian thực hiện không hợp lệ. Đã hết thời gian nhận chuyển khoản",
  MSG3223: "Loại lệnh không không hợp lệ",
  MSG3224: "Số tài khoản không được để trống",
  MSG3225: "Mã chứng khoán không được phép trống",
  MSG3226: "Khối lượng đặt không được phép trống",
  MSG3227: "Giá đặt không được phép trống",
  MSG3228: "Khối lượng sửa không được để trống",
  MSG3229: "Giá sửa không được để trống",
  MSG3230: "Thông tin sàn không được để trống",
  MSG3231: "Không tìm thấy thông tin số hiệu lệnh",
  MSG3232: "KL chờ khớp và giá chờ khớp đã thay đổi.",
  MSG3233: "Số tiền chuyển khoản vượt quá hạn mức còn lại của khách hàng: @0",
  MSG3234:
    "Đã hết thời gian nhận chuyển khoản. Phiếu chuyển khoản của bạn sẽ được xử lý vào ngày tiếp theo",
  MSG3235: "Ngày giao dịch chuyển khoản không hợp lệ",
  MSG3236: "Giá trị hạn mức không được nhỏ hơn hạn mức đã sử dụng @0",
  MSG3237:
    "Quý khách đã bị phong tỏa nghiệp vụ chuyển tiền. Yêu cầu của quý khách không thể thực hiện",
  MSG3238: "Không tìm thấy thông tin lệnh thỏa thuận 1Firm số hiệu lệnh @0",
  MSG3239: "Trạng thái lệnh không hợp lệ để thực hiện sửa lệnh",
  MSG3240: "Khối lượng khớp của lệnh đã thay đổi, bạn hãy làm mới dữ liệu",
  MSG3241: "Giá khớp của lệnh đã thay đổi, bạn hãy làm mới dữ liệu",
  MSG3242: "Không tìm thấy tham số chuyển khoản nội bộ",
  MSG3243: "Ngày tạo TT không hợp lệ",
  MSG3244: "Số không hợp lệ",
  MSG3245: "Ngày đăng ký không hợp lệ",
  MSG3246: "Số lượng đăng ký lớn hơn số lượng còn lại",
  MSG3247: "Thành tiền lớn hơn khả dụng",
  MSG3248: "Trạng thái không hợp lệ",
  MSG3250: "Lệnh chọn sửa không còn tồn tại!",
  MSG3251: "Số tiểu khoản nhập vào không còn tồn tại!",
  MSG3252: "Loại lệnh @0 không được thiết lập trong phiên @1.",
  MSG3253: "Loại lệnh @0 không được thiết lập trong phiên @1.",
  MSG3253S:
    "Loại lệnh @0 không được thiết lập trong phiên (S)Trước giờ mở cửa.",
  MSG3253P:
    "Loại lệnh @0 không được thiết lập trong phiên (P)Phiên KLĐK (ATO/ATC).",
  MSG3253I: "Loại lệnh @0 không được thiết lập trong phiên (I)Nghỉ giữa phiên.",
  MSG3253O:
    "Loại lệnh @0 không được thiết lập trong phiên (O)Khớp lệnh liên tục.",
  MSG3253A: "Loại lệnh @0 không được thiết lập trong phiên (A)KLĐK (ATO/ATC).",
  MSG3253C: "Loại lệnh @0 không được thiết lập trong phiên (C)Thỏa thuận.",
  MSG3254: "Không hỗ trợ sửa giá lệnh báo giá sàn HSX",
  MSG3255: "Không hỗ trợ đặt lệnh lô lẻ với sàn HSX",
  MSG3256: "Trạng thái hệ thống giao dịch không còn tồn tại!",
  MSG3257: "Chưa kết thúc giao dịch.",
  MSG3258: "Không phải lệnh thanh toán.",
  MSG3259: "Không được phép đặt cùng lúc Bán/Mua cho một tài khoản giao dịch",
  MSG3260: "Không được phân bổ hạn mức cho tài khoản của chính mình",
  MSG3261: "Lệnh chọn không còn tồn tại!",
  MSG3262: "Trạng thái không hợp lệ!",
  MSG3263: "Trạng thái thị trường không hợp lệ để thực hiện hủy lệnh",
  MSG3264: "Mã chứng khoán truyền lên bị lỗi!",
  MSG3265: "Thông tin người dùng trên lên bị lỗi!",
  MSG3266: "Thiết lập cảnh báo đã tồn tại!",
  MSG3267: "Chưa thiết lập địa chỉ Gateway.",
  MSG3268: "Thiệt lập cảnh báo không còn tồn tại!",
  MSG3269: "Người dùng không được cấp quyền @0.",
  MSG3270: "Bạn không có quyền quản lý tài khoản @0",
  MSG3271:
    "Người dùng @0 được cấp quyền @1 thực hiện với tiểu khoản @2 tối đa @3",
  MSG3272: "Không tìm thấy thông tin tiểu khoản @0",
  MSG3273: "Không tồn tại mã chứng khoán @0",
  MSG3274: "Mã chứng khoán @0 không có trong danh mục HTCK",
  MSG3275: "Khả dụng kho @0 không đủ để thực hiện giao dịch",
  MSG3276: "Hệ thống không hỗ trợ sửa lệnh HTCK sàn @0",
  MSG3277: "Không tìm thấy thông tin lệnh gốc của deal.",
  MSG3278: "Khối lượng chờ khớp đã hết, bạn không thể sửa/hủy lệnh.",
  MSG3279: "Khối lượng chờ khớp đã thay đổi, bạn hãy làm mới dữ liệu.",
  MSG3280: "Thao tác vượt quá giá trị cho phép (giá trị tối đa : @0).",
  MSG3281: "Loại yêu cầu không phải là lệnh báo giá",
  MSG3282: "Loại lệnh không được phép trống",
  MSG3283: "Mã chứng khoán không được phép để trống",
  MSG3284: "Số tài khoản đặt không được phép trống",
  MSG3285: "Giá đặt lệnh phải lớn hơn 0",
  MSG3286: "Khối lượng đặt lệnh phải lớn hơn 0",
  MSG3287: "Độ dài của loại lệnh không vượt quá @0 ký tự",
  MSG3288: "Độ dài mã chứng khoán không vượt quá @0 ký tự",
  MSG3289: "Độ dài của số tiểu khoản không được vượt quá @0 ký tự",
  MSG3290: "hông tin mã thành viên giao dịch không được để trống",
  MSG3291: "Mã thành viên giao dịch không được vượt quá @0 ký tự",
  MSG3292: "Lỗi vấn tin tài khoản @0",
  MSG3293: "Tài khoản @0 không đủ số dư tiền để đặt lệnh",
  MSG3294: "Hệ thống không hỗ trợ giao dịch lệnh trong phiên Đóng cửa",
  MSG3295: "Mã trader giao dịch không được phép trống",
  MSG3296: "Mã trader id không được phép vượt quá @0 ký tự",
  MSG3297: "BrokerCd không được để trống",
  MSG3298: "Loại hạn mức không đúng định dạng",
  MSG3299: "Từ ngày không đúng định dạng",
  MSG3300: "Đến ngày không đúng định dạng",
  MSG3301: "BrokerCd không đúng định dạng",
  MSG3302: "Hạn mức phân bổ vượt quá giới hạn cho phép",
  MSG3303: "Ngày nhập vào không đúng",
  MSG3304: "Người dùng không có quyền thực hiện với tài khoản @0",
  MSG3305: "Bạn chưa chọn kho",
  MSG3306: "Mã chứng khoán không được vượt quá 20 ký tự",
  MSG3307: "Loại lệnh không được để trống",
  MSG3308: "Giá lệnh đặt phải lớn hơn 0",
  MSG3309: "Khối lượng để đặt lệnh phải lớn hơn 0",
  MSG3310: "UserId không được quá @0 ký tự",
  MSG3311: "UserId không được phép null",
  MSG3312: "UserType không được quá @0 ký tự",
  MSG3313: "UserType không được phép null",
  MSG3314: "USER_LOCK_FLAG không được quá @0 ký tự",
  MSG3315: "USER_LOCK_FLAG không được phép null",
  MSG3316: "USER_TRADING_FLAG không được quá @0 ký tự",
  MSG3317: "USER_TRADING_FLAG không được phép null",
  MSG3318: "MenuId không được quá @0 ký tự",
  MSG3319: "MenuId không được phép null",
  MSG3320: "Password mới không được quá @0 ký tự",
  MSG3321: "Password mới không được phép null",
  MSG3322: "Id number không được quá @0 ký tự",
  MSG3323: "Không tìm thấy mã lệnh",
  MSG3324: "Không tìm thấy ngày giao dịch",
  MSG3325: "Độ dài trường ghi chú không được vượt quá 200 ký tự",
  MSG3326: "FirmNo nhập vào không được vượt quá @0 ký tự",
  MSG3327: "Mã chứng khoán nhập vào không được vượt quá @0 ký tự",
  MSG3328: "Số tiểu khoản nhập vào không được vượt quá @0 ký tự",
  MSG3329: "CUST_CD truyền vào không được phép null",
  MSG3330: "CUST_CD truyền vào không được vượt quá @0 ký tự",
  MSG3331: "STATUS truyền vào không được phép null",
  MSG3332: "STATUS truyền vào không được phép vượt quá @0 ký tự",
  MSG3333: "ID_NUMBER không được phép null",
  MSG3334: "APPROVAL_CD không được phép null",
  MSG3335: "APPROVAL_CD không được phép vượt quá @0 ký tự",
  MSG3336: "Chi nhánh không được phép null",
  MSG3337: "Số CMT không được phép null",
  MSG3338: "Parent Broker Cd không được phép null",
  MSG3339: "Chi nhánh truyền vào không được phép vượt quá @0 ký tự",
  MSG3340: "Số CMT truyền vào không được phép vượt quá @0 ký tự",
  MSG3341: "Mã Broker truyền vào không được phép vượt quá @0 ký tự",
  MSG3342: "Broker quản lý truyền vào không được phép vượt quá @0 ký tự",
  MSG3343: "Không tìm thấy mã nhóm khách hàng",
  MSG3344: "Mã Broker phải có độ dài 5 ký tự",
  MSG3345: "Broker quản lý phải có độ dài 5 ký tự",
  MSG3346: "Broker quản lý không tồn tại",
  MSG3347: "Từ ngày phải nhỏ hơn Đến ngày",
  MSG3348: "Số hiệu lệnh sàn tối đa 30 ký tự",
  MSG3349: "Mã chứng khoán tối đa 20 ký tự",
  MSG3350: "Broker tối đa 10 ký tự",
  MSG3351: "Số tài khoản chỉ tối đa 13 ký tự",
  MSG3352: "Từ ngày không đúng",
  MSG3353: "Đến ngày không không đúng",
  MSG3354: "Không tìm thấy bankCd",
  MSG3355: "Không tìm thấy bankBrandCd",
  MSG3356: "Tiền ứng trước phải lớn hơn 0",
  MSG3357: "Tiền ứng trước phải nhỏ hoặc bằng 18 số",
  MSG3358: "Giá trị tham số không được phép vượt quá @0 ký tự",
  MSG3359: "Ghi chú không được phép vượt quá @0 ký tự",
  MSG3360: "Ref no không được phép null",
  MSG3361: "Ref no không được phép vượt quá @0 ký tự",
  MSG3362: "Từ giá trị không được phép vượt quá @0 ký tự",
  MSG3363: "Đến giá trị không được phé vượt quá @0 ký tự",
  MSG3364: "Khối lượng không được lớn hơn 23 ký tự",
  MSG3365: "Trường giá không được vượt quá 13 ký tự",
  MSG3366: "Danh sách thành viên không được vượt quá 65 ký tự",
  MSG3367: "Ghi chú không được vượt quá 200 ký tự",
  MSG3368: "Firm No không được vượt quá 3 ký tự",
  MSG3369: "Bạn chưa nhập mật khẩu đăng nhập hiện tại",
  MSG3370: "Mật khẩu đăng nhập mới trùng mật khẩu hiện tại",
  MSG3371: "Mật khẩu đăng nhập mới không được quá @0 ký tự",
  MSG3372: "Ngày chốt không đúng định dạng.",
  MSG3373: "Ngày thực hiện không đúng định dạng.",
  MSG3374: "Ngày giao dịch không đúng định dạng.",
  MSG3375: "Trường tháng chỉ nhập tối đa 7 ký tự",
  MSG3376: "Trường broker chỉ nhập tối đa 10 ký tự",
  MSG3378: "Loại chứng khoán không được để trống",
  MSG3379: "Số tiền không được vượt quá 18 ký tự",
  MSG3380: "Giá nhập vào phải lớn hơn 0",
  MSG3381: "Giá nhập vào không được vượt quá 10 ký tự",
  MSG3382: "Giấy CNSHCK nhập vào không quá 20 ký tự",
  MSG3383: "Từ khóa nhập vào không được quá @0 ký tự",
  MSG3384: "Bạn chưa nhập Tiêu đề câu hỏi",
  MSG3385: "Tiêu đề nhập vào không được quá @0 ký tự",
  MSG3386: "Bạn chưa nhập Nội dung câu hỏi",
  MSG3387: "Nội dung câu hỏi không được quá @0 ký tự",
  MSG3388: "Bạn chưa nhập Nội dung câu trả lời",
  MSG3389: "Nội dung câu trả lời không được quá @0 ký tự",
  MSG3390: "Tên tài khoản nhận không vượt được quá @0 ký tự",
  MSG3391: "Tên ngân hàng không được vượt quá @0 ký tự",
  MSG3392: "Độ dài trường phí chuyển khoản không được vượt quá @0 ký tự",
  MSG3393: "Độ dài trường phí SMS không được vượt quá @0 ký tự",
  MSG3394: "Ngày tạo TT là bắt buộc.",
  MSG3395: "Trường số là bắt buộc.",
  MSG3396: "Trường số không được nhập quá 6 ký tự số.",
  MSG3397: "Ngày đăng ký là bắt buộc.",
  MSG3398: "Ngày đăng ký không hợp lệ",
  MSG3399: "Số lượng đăng ký không được nhập quá 18 ký tự.",
  MSG3400: "Ngày yêu cầu cần được phải nhập đúng định dạng ngày",
  MSG3401: "Tiểu khoản chuyển không được để trống",
  MSG3402: "Tiểu khoản nhận không được để trống",
  MSG3403: "Độ dài tiểu khoản chuyển không được vượt quá @0 ký tự",
  MSG3404: "Độ dài tiểu khoản nhận không được vượt quá @0 ký tự",
  MSG3405: "Độ dài số lượng chứng khoán chuyển không được vượt quá @0 ký tự",
  MSG3406: "Trade type không được phép để trống",
  MSG3407: "Giá nhập vào không được quá @0 chữ số",
  MSG3408: "Khối lượng nhập vào không được quá @0 chữ số",
  MSG3409: "TRADE_DATE không được phép để trống",
  MSG3410: "REQ_NO không được phép để trống",
  MSG3411: "Giá trị thế chấp không được vượt quá 1 ký tự",
  MSG3412: "Biểu lãi suất không được để trống",
  MSG3413: "Số lần đáo hạn không được vượt quá 2 ký tự",
  MSG3414: "Số lượng bán nhập vào không được vượt quá @0 ký tự",
  MSG3415: "Số lượng tổng tiền tính ra không được vượt quá @0 ký tự",
  MSG3416: "Giá phải lớn hơn 0",
  MSG3417: "Tỷ lệ giá phải lớn hơn 0",
  MSG3418: "Tỷ lệ thuế phải lớn hơn 0",
  MSG3419: "Tổng thành tiền phải lớn hơn 0",
  MSG3420: "Trạng thái không được phép để trống",
  MSG3421: "Trạng thái không được phép vượt quá @0 chữ số",
  MSG3422: "Nội dung câu trả lời không được phép để trống",
  MSG3423: "Nội dung câu trả lời không được phép vượt quá @0 ký tự",
  MSG3424: "Giá trị trả lãi không được vượt quá 18 số",
  MSG3425: "DEAL_ID truyền lên không được phép để trống",
  MSG3426: "DEAL_ID truyền lên không được vượt quá @0 chữ số",
  MSG3427: "Số tiền chuyển không được vượt quá @0 ký tự (Gồm cả dấu đơn vị).",
  MSG3428: "Số lượng chuyển không được vượt quá @0 ký tự (Gồm cả dấu đơn vị).",
  MSG3429: "Số tiền chuyển phải lớn hơn @0",
  MSG3430: "Số lượng chuyển phải lớn hơn @0",
  MSG3431: "Yêu cầu bạn chọn đơn vị",
  MSG3432: "Chi nhánh không được phép Null",
  MSG3433: "Điểm giao dịch không được phép Null",
  MSG3434: "Độ dài chi nhánh nhập vào không được vượt quá @0 ký tự",
  MSG3435: "Độ dài điểm giao dịch nhập vào không được vượt quá @0 ký tự",
  MSG3436: "Lệnh giao dịch không đươc để rỗng",
  MSG3437: "Chu kỳ thanh toán không đươc để rỗng",
  MSG3438: "Kênh giao dịch không đươc để rỗng",
  MSG3439: "Loại khách hàng không đươc để rỗng",
  MSG3440: "Loại khách hàng (Trong nước / NN) không đươc để rỗng",
  MSG3441: "Nhóm khách hàng không đươc để rỗng",
  MSG3442: "Loại tài khoản không đươc để rỗng",
  MSG3443: "Tiêu chí sắp xếp không đươc để rỗng",
  MSG3444: "Từ ngày GD không được vượt qúa @0 ký tự",
  MSG3445: "Đến ngày GD không được vượt quá @0 ký tự",
  MSG3446: "Từ ngày mở TK không được vượt quá @0 ký tự",
  MSG3447: "Đến ngày mở TK không được vượt quá @0 ký tự",
  MSG3448: "Sàn giao dịch nhập vào không được vượt quá @0 ký tự",
  MSG3449: "Loại chứng khoán nhập vào không được vượt quá @0 ký tự",
  MSG3450: "Chu kỳ thanh toán nhập vào không được vượt quá @0 ký tự",
  MSG3451: "Kênh giao dịch nhập vào không được vượt quá @0 ký tự",
  MSG3452: "Loại khách hàng (Trong nước / NN) không đươc vượt quá @ ký tự",
  MSG3453: "Loại khách hàng không đươc vượt quá @ ký tự",
  MSG3454: "Nhóm khách hàng không đươc vượt quá @ ký tự",
  MSG3455: "Loại tài khoản nhập vào không đươc vượt quá @ ký tự",
  MSG3456: "Độ dài tiểu khoản không đươc vượt quá @ ký tự",
  MSG3457: "Độ dài tài khoản không đươc vượt quá @ ký tự",
  MSG3458: "Tiêu chí sắp xếp không được vượt quá @0 ký tự",
  MSG3459: "Số ngày truy vấn sổ lệnh không được vượt quá @0",
  MSG3460: "Số ngày truy vấn báo cáo không được vượt quá @0",
  MSG3463: "Tỉ lệ ứng trước không hợp lệ (AD_ADVANCE_RATE)",
  MSG3464: "Không xác định được ngày thực hiện quyền (Right Allo Date)",
  MSG3465: "Right RefNo không hợp lệ",
  MSG3466: "Ngày thanh toán không hợp lệ (RIGHT_EXC_DATE)",
  MSG3467: "Bạn chưa nhập ngày giao dịch",
  MSG3468: "Số tài khoản khách hàng đồng bộ phải là 10 ký tự",
  MSG3469: "Mã khách hàng đồng bộ không được quá 20 ký tự",
  MSG3470: "Cảnh báo tham chiếu (<) không được vượt quá @0 chữ số",
  MSG3471: "Cảnh báo tham chiếu (>) không được vượt quá @0 chữ số",
  MSG3472: "Cảnh báo giá khớp (<) không được vượt quá @0 chữ số",
  MSG3473: "Cảnh báo giá khớp (>) không được vượt quá @0 chữ số",
  MSG3474: "Hộp thoại thông báo không được quá @0 chữ số",
  MSG3475: "Gửi tin nhắn SMS không được quá @0 chữ số",
  MSG3476: "Gửi Email không được quá @0 chữ số",
  MSG3477: "Không tìm thấy nhóm khách hàng",
  MSG3478: "Không tìm thấy tham số ứng trước",
  MSG3479: "Ngày giao dịch ứng trước không hợp lệ",
  MSG3480: "Chưa đến giờ nhận HĐ ứng trước. Thời gian bắt đầu ứng trước @0",
  MSG3481:
    "Đã hết thời gian nhận HĐ ứng  trước. Thời gian kết thúc ứng trước @0",
  MSG3482: "Giá trị ứng trước vượt quá hạn mức còn lại khách hàng @0",
  MSG3483: "Mã kho truyền lên bị rỗng",
  MSG3484: "P_Reset truyền lên bị null",
  MSG3485: "Tiểu khoản short sale không được nhập quá @0 ký tự.",
  MSG3486: "Ngày tạo không được phép null",
  MSG3487: "Họ nhập vào không được quá @0 ký tự",
  MSG3488: "Tên nhập vào không được quá @0 ký tự",
  MSG3489: "Ngày sinh nhập vào không đúng",
  MSG3490: "Mã Giới tính nhập vào không được quá @0 chữ số",
  MSG3491: "Mã Loại tài khoản nhập vào không được quá @0 chữ số",
  MSG3492: "Mã Địa điểm nhập vào không được quá @0 chữ số",
  MSG3493: "Mã Loại giấy tờ nhập vào không được quá @0 chữ số",
  MSG3494: "Số giấy tờ nhập vào không được quá @0 ký tự",
  MSG3495: "Nơi cấp nhập vào không được quá @0 chữ số",
  MSG3496: "Ngày cấp nhập vào không đúng",
  MSG3497: "Mã quốc gia nhập vào không được quá @0 ký tự",
  MSG3498: "Mã Người giới thiệu nhập vào không được quá @0 ký tự",
  MSG3499: "Số điện thoại nhập vào không được quá @0 ký tự",
  MSG3500: "Email nhập vào không được quá @0 ký tự",
  MSG3501: "Mã chi nhánh nhập vào không được quá @0 ký tự",
  MSG3502: "Điểm giao dịch nhập vào không được quá @0 ký tự",
  MSG3503: "Mã kênh nhập vào không được quá @0 chữ số",
  MSG3504: "Mã sàn không được quá @0 chữ số",
  MSG3505: "Mã chu kỳ không được quá @0 chữ số",
  MSG3506: "Loại không được quá @0 chữ số",
  MSG3507: "Không xác định được ngày yêu cầu",
  MSG3508: "Không xác định được số yêu cầu",
  MSG3509: "Độ dài ngày yêu cầu không được vượt quá @0 ký tự",
  MSG3510: "Độ dài số yêu cầu không được vượt quá @0 ký tự",
  MSG3511: "Ngày yêu cầu không đúng định dạng",
  MSG3512: "Quyền chọn hủy không còn tồn tại",
  MSG3513: "Trạng thái thị trường không được quá @0 ký tự",
  MSG3514: "Trạng thái giao dịch không được quá @0 ký tự",
  MSG3515: "Chế độ giao dịch không được quá @0 chữ số",
  MSG3516: "Hệ thống không còn tồn tại câu hỏi",
  MSG3517: "Số tiền cần trả nợ vượt quá khả dụng",
  MSG3518: "Không tìm thấy dữ liệu lệnh.",
  MSG3519: "Không tìm thấy dữ liệu lệnh khớp.",
  MSG3520: "Không tìm thấy Order ID.",
  MSG3521: "Loại shortsale không đúng.",
  MSG3522: "Không tìm thấy deal id.",
  MSG3523: "Không tìm thấy tiểu khoản shortsale.",
  MSG3524: "Tên danh mục không được phép trống",
  MSG3525: "Tên danh mục không được vượt quá 250 ký tự",
  MSG3526: "Không tìm thấy mã số danh mục để xóa",
  MSG3527: "Không được để trống OrgOrderNo.",
  MSG3528: "OrgOrderNo không đúng.",
  MSG3529: "Không được để trống Trade date.",
  MSG3530: "Trade date không hợp lệ.",
  MSG3531: "Loại lệnh của lệnh gốc không đúng.",
  MSG3532: "Loại lệnh của lệnh sửa không đúng.",
  MSG3533: "Loại lệnh trùng nhau.",
  MSG3534: "Số tiểu khoản gốc không đúng.",
  MSG3535: "Số tiểu khoản gốc phải là 13 ký tự.",
  MSG3536: "Loại lệnh HTCK không hợp lệ.",
  MSG3537: "Không tìm thấy thông tin lệnh.",
  MSG3538: "Thông tin lệnh đã bị thay đổi, bạn hãy lấy lại dữ liệu.",
  MSG3539: "Trạng thái lệnh không hợp lệ để sửa.",
  MSG3540: "Không tìm thấy thông tin Order.",
  MSG3541: "Không tìm thấy thông tin tài khoản.",
  MSG3542: "Mã chứng khoán không có trong kho.",
  MSG3543: "Mã chứng khoán không được giao dịch ShortSale.",
  MSG3544: "Tài khoản không có trong kho giao dịch HTCk.",
  MSG3545: "Tài khoản @0 không được giao dịch ShortSale.",
  MSG3546: "Có lỗi khi thực hiện vấn tin tài khoản @0.",
  MSG3547: "Tài khoản @0 không đủ số dư để thực hiện sửa lệnh.",
  MSG3548: "Lệnh thị trường đang chờ khớp không được phép hủy",
  MSG3549: "Yêu cầu hủy lệnh bắt buộc phải có số hiệu lệnh",
  MSG3550: "Người dùng không được phép đặt lệnh thỏa thuận",
  MSG3551: "Không tìm thấy thông tin lệnh thỏa thuận số hiệu lệnh: @0",
  MSG3552: "Trạng thái lệnh thỏa thuận không hợp lệ để thực hiện sửa",
  MSG3553: "Không tìm thấy thông tin lệnh quảng cáo có số hiệu lệnh: @0",
  MSG3554: "Không tìm thấy thông tin thiết lập mã thành viên của CTCK",
  MSG3555: "Lệnh quảng cáo không hợp lệ để thực hiện thỏa thuận",
  MSG3556: "Trạng thái lệnh quảng cáo không hợp lệ để thực hiện thỏa thuận",
  MSG3557: "Loại Mua/Bán không được để trống",
  MSG3558: "Loại lệnh không phải sửa tài khoản",
  MSG3559: "Số tiểu khoản sửa lệnh không được phép trống",
  MSG3560: "Số hiệu lệnh sửa không được phép trống",
  MSG3561: "Mã sàn không được phép trống",
  MSG3562: "Lệnh sửa tài khoản chỉ áp dụng đối với sàn HSX",
  MSG3563: "Không tìm thấy thông tin lệnh sửa",
  MSG3564: "Trạng thái lệnh không hợp lệ để thực hiện sửa tài khoản",
  MSG3565: "Lệnh đã khớp một phần, bạn không thể thực hiện sửa tài khoản",
  MSG3566: "Không tìm thấy thông tin tiểu khoản @0",
  MSG3567: "Không tìm thấy thông tin tiểu khoản",
  MSG3568: "Tài khoản sửa mới phải khác tài khoản cũ",
  MSG3569: "Không được phép sửa lệnh lô lẻ sang lô chẵn",
  MSG3570: "Không được phép sửa lệnh lô chẵn sang lô lẻ",
  MSG3571: "Mã chứng khoán bị ngừng giao dịch",
  MSG3572: "Mã chứng khoán bị ngừng giao dịch",
  MSG3573: "Mã chứng khoán không đủ ROOM để thực hiện giao dịch",
  MSG3574: "Mã chứng khoán mới không được phép giao dịch lệnh @0",
  MSG3575: "Mã trader bị ngừng giao dịch",
  MSG3576: "Broker bị chặn không cho phép thực hiện lệnh mua/bán",
  MSG3577: "Broker bị chặn không cho phép thực hiện lệnh bán",
  MSG3578: "Broker bị chặn không cho phép thực hiện lệnh mua",
  MSG3579: "Broker bị tạm ngừng giao dịch thỏa thuận mua và bán",
  MSG3580: "Broker bị tạm ngừng giao dịch thỏa thuận bán",
  MSG3581: "Broker bị tạm ngừng giao dịch thỏa thuận mua",
  MSG3582: "Lệnh lô lẻ không được phép sửa sang lô chẵn",
  MSG3583: "Lệnh lô chẵn không được phép sửa sang lô lẻ",
  MSG3584: "Độ dài tên bộc lọc không được quá @0 ký tự",
  MSG3585: "Không xác định được mã form",
  MSG3586: "User Id không được để trống",
  MSG3587: "User Type không được để trống",
  MSG3588: "Độ dài nội dung bộc lọc không được quá @0 ký tự",
  MSG3589: "Tên bộ lọc đã tồn tại, bạn vui lòng nhập vào tên khác.",
  MSG3590: "Tên nhóm khách hàng không được để trống",
  MSG3591: "Tên nhóm khách hàng không được vượt quá @0 ký tự",
  MSG3592: "Tên nhóm đã tồn tại, bạn vui lòng nhập vào tên khác",
  MSG3593: "Bạn không được phép đặt lệnh báo giá với trái phiếu sàn HSX",
  MSG3594: "Tiểu khoản @0 đã tồn tại trong danh sách đăng ký dịch vụ này.",
  MSG3595:
    "Chưa thiết lập tham số khối lượng trái phiếu tối thiểu được phép thỏa thuận.",
  MSG3596: "Khối lượng tối thiểu để thỏa thuận trái phiếu là @0",
  MSG3597: "Số tiền ứng trước không được nhỏ hơn số tiền cần trả nợ @0",
  MSG3598: "Thời gian chuyển khoản không hợp lệ",
  MSG3599: "Không tìm thấy thông tin lệnh thỏa thuận số hiệu @0",
  MSG3600: "Trạng thái lệnh thỏa thuận không hợp lệ để thực hiện sửa",
  MSG3601: "Trạng thái lệnh thỏa thuận không hợp lệ để thực hiện hủy",
  MSG3602: "Mật khẩu phải có tối thiểu @0 ký tự",
  MSG3603: "Mật khẩu phải có ký tự hoa",
  MSG3604: "Mật khẩu phải có ký tự số",
  MSG3605: "Mật khẩu phải có ký tự đặc biệt",
  MSG3606: "Không thể xóa, tài khoản này đã được thu phí dịch vụ",
  MSG3607: "Không tìm thấy số hợp đồng.",
  MSG3608: "Chưa nhập tiểu khoản.",
  MSG3609: "Chưa chọn ngân hàng.",
  MSG3610: "Ngày hợp đồng không hợp lệ.",
  MSG3611: "Ngày hết hạn không hợp lệ.",
  MSG3612: "Chưa mã CK nào được chọn.",
  MSG3613: "Không tìm thấy dữ liệu cần xóa.",
  MSG3614: "Không tìm thấy ngày hợp đồng.",
  MSG3615: "Tiểu khoản không tồn tại.",
  MSG3616: "Ngày hết hạn nhỏ hơn ngày hiện tại.",
  MSG3617: "Giá trị hợp đồng cầm cố phải lớn hơn 0.",
  MSG3618: "Giá trị cầm cố lớn hơn hạn mức khách hàng còn lại @0.",
  MSG3619: "Giá trị cầm cố lớn hơn hạn mức công ty @0.",
  MSG3620: "Giá trị cầm cố nhỏ hơn giá trị tối thiểu @0.",
  MSG3621: "Giá trị cầm cố lớn hơn giá trị tối đa @0.",
  MSG3622: "Số lượng cầm cố của mã @0 lớn hơn số dư @1.",
  MSG3623: "Số lượng cầm cố của mã @0 nhỏ hơn số lượng tối thiểu @1.",
  MSG3624: "Số lượng cầm cố của mã @0 lớn hơn số lượng tối đa @1.",
  MSG3625: "Mã chứng khoán @0 không được phép cầm cố.",
  MSG3626: "Không tìm thấy hợp đồng cần xóa.",
  MSG3627: "Trạng thái không hợp lệ.",
  MSG3628: "Không tìm thấy dữ liệu Approment.",
  MSG3629: "Không tìm thấy dữ liệu chi tiết hợp đồng.",
  MSG3630: "Ngày hết hạn nhỏ hơn ngày hợp đồng.",
  MSG3631: "Không thể thêm mới, mã chứng khoán đã có trong danh mục.",
  MSG3632: "Mã chứng khoán không tồn tại trong danh mục.",
  MSG3633: "Tên danh mục này đã tồn tại, bạn cần chọn một tên khác.",
  MSG3634:
    "Đã tồn tại 1 bản ghi được thiết lập danh mục mặc định, bạn không thể thiết lập tại đây.",
  MSG3635: "Tổng khối lượng cầm cố mã @0 lớn hơn hạn mức @1.",
  MSG3636: "Không tìm thấy thông tin mã trader @0 của sàn @1",
  MSG3637: "Không tìm thấy thông tin trạng thái broker sàn @0",
  MSG3638: "Giá trị cầm cố lớn hơn hạn mức ngân hàng LOCAL",
  MSG3639: "Phiên sau đóng cửa chỉ cho phép thực hiện lệnh thỏa thuận",
  MSG3640: "Mã chứng khoán không được phép đặt lệnh trong phiên",
  MSG3641: "Không cho phép hủy lệnh giao dịch thỏa thuận 2F sàn @0",
  MSG3642: "Lệnh báo giá lô lẻ chi được phép đặt trong phiên KLLT",
  MSG3643: "Số hiệu lệnh quảng cáo không được phép trống",
  MSG3644: "Không tìm thấy thông tin lệnh quảng cáo để thực hiện hủy",
  MSG3645: "Lệnh quảng cáo đã hủy không được phép hủy lại",
  MSG3646: "Lệnh quảng cáo đã thỏa thuận không được phép hủy",
  MSG3647: "Trạng thái phiên không hợp lệ để thực hiện sửa/hủy",
  MSG3648:
    "Hyper Link không đúng định dạng. Bạn cần nhập link đầy đủ dưới dạng (http://...)",
  MSG3649: "Không tìm thấy ngân hàng @0.",
  MSG3650: "Không tìm thấy thông tin chi tiết HĐ.",
  MSG3651: "Không tìm thấy thông tin biểu lãi.",
  MSG3652: "Không tìm thấy tiểu khoản.",
  MSG3653: "Trả nợ lớn hơn nợ còn lại.",
  MSG3654: "Không đủ tiền để trả nợ.",
  MSG3655: "Dữ liệu đã thay đổi.",
  MSG3656: "Tổng tiền trả lớn hơn khả dụng.",
  MSG3657: "Dữ liệu đầu vào không được để trống",
  MSG3658: "Tổng tiền trả nợ phải lớn hơn 0.",
  MSG3659: "Hạn mức tổng không đủ.",
  MSG3660: "Không tìm thấy tham số chuyển khoản ngân hàng.",
  MSG3661: "Không xác định được thời gian bắt đầu chuyển khoản.",
  MSG3662: "Không xác định được thời gian kết thúc chuyển khoản.",
  MSG3663: "Không xác định được thời gian Bắt đầu NextDay.",
  MSG3664: "Không xác định được thời gian Kết thúc NextDay.",
  MSG3665: "Không xác định được số tiền tối thiểu.",
  MSG3666: "Không xác định được số tiền tối đa.",
  MSG3667: "Không xác định được số lần CK/Ngày.",
  MSG3668: "Không xác định được tỉ lệ phí.",
  MSG3669: "Không được xóa danh mục sở hữu.",
  MSG3670: "Không được phép xóa danh mục do CTCK thiết lập.",
  MSG3671: "Không được Thêm/Xóa CK trong danh mục do CTCK thiết lập.",
  MSG3672: "Không được xóa danh mục do User khác thiết lập.",
  MSG3677: "Ký tự xác nhận không khớp.",
  MSG3678: "Không được thêm mới chứng khoán vào danh mục sở hữu.",
  MSG3679: "Không tìm thấy tham số địa chỉ dịch vụ ngân hàng.",
  MSG3680: "Có lỗi khi thực hiện kết nối ngân hàng: @0",
  MSG3682: "Bạn không được phép sửa lệnh giải chấp",
  MSG3683: "Bạn không được phép hủy lệnh giải chấp",
  MSG3684: "Không tìm thấy thông tin tiền tài khoản @0",
  MSG3685: "Không được xóa bản ghi đã bị sửa",
  MSG3686: "Không được xóa bản ghi đã bị hủy",
  MSG3687: "Không thể xóa bản ghi vì hạn mức đã sử dụng là: @0",
  MSG3688: "Mẫu email không được để trống",
  MSG3689: "Tiêu đề không được để trống",
  MSG3690: "Mail CC không được để trống",
  MSG3691: "Nội dung không được để trống",
  MSG3692: "Không tìm thấy dữ liệu bản ghi muốn Sửa/Xóa",
  MSG3693: "Độ dài trường Mẫu email không được vượt quá @0 ký tự",
  MSG3694: "Độ dài trường Tiêu đề không được vượt quá @0 ký tự",
  MSG3695: "Độ dài trường MailCC không được vượt quá @0 ký tự",
  MSG3696: "Độ dài trường MailBcc không được vượt quá @0 ký tự",
  MSG3697: "Độ dài trường Content không được vượt quá @0 ký tự",
  MSG3698: "Không thể Thêm mới/Cập nhật. Mẫu Email này đã có trong danh sách.",
  MSG3699: "Khối lượng không đủ để đặt lệnh",
  MSG3700: "Không xác định được tham số của ngân hàng được chọn",
  MSG3701: "Không xác định được tỷ lệ lãi ứng trước tính với khách hàng",
  MSG3702: "Không xác định được tỷ  lệ lãi ứng trước tính với ngân hàng",
  MSG3703: "Không xác định được tham số cách tính lãi",
  MSG3704: "Không xác định được tham số số ngày miễn phí",
  MSG3705: "Không xác định được tham số lãi ứng trước tối thiểu",
  MSG3706: "Không xác định được tham số tỷ lệ phí quản lý",
  MSG3707: "Không xác định được tham số cách tính phí quản lý",
  MSG3708: "Không xác định được tham số số ngày miễn phí quản lý",
  MSG3709: "Không xác định được tham số phí quản lý tối thiểu",
  MSG3710: "Không xác định được tham số giá trị tối thiểu của hợp đồng ƯT",
  MSG3711: "Không xác định được tham số giá trị tối đa của HĐ ứng trước",
  MSG3712: "Không xác định được tham số tổng hạn mức ứng trước của KH",
  MSG3713: "Không xác định được tham số tổng hạn mức ngân hàng",
  MSG3714: "Không xác định được tham số thu lãi ngày T3",
  MSG3715: "Không xác định được tham số thời gian bắt đầu ứng trước",
  MSG3716: "Không xác định được tham số thời gian kết thúc ứng trước",
  MSG3717:
    "Mã cổ phiếu mới lên sàn/Giao dịch đặc biệt không được phép giao dịch lô lẻ",
  MSG3718:
    "Mã cổ phiếu mới giao dịch trở lại sau 25 phiên không được phép giao dịch lô lẻ",
  MSG3719: "Không tìm thấy thông tin yêu cầu sửa thỏa thuận SHL @0",
  MSG3720:
    "Trạng thái yêu cầu sửa thỏa thuận SHL @0 đã thay đổi, bạn không thể thực hiện",
  MSG3721: "Thị trường chưa đóng cửa, bạn không được phép sửa lệnh",
  MSG3722: "Tài khoản chưa khai báo trong nhóm khách hàng cầm cố.",
  MSG3723: "Tài khoản không đủ số dư tiền để thực hiện lệnh mua",
  MSG3724: "Tài khoán không đủ số dư chứng khoán để thực hiện lệnh bán",
  MSG3725: "Không tìm thấy dữ liệu sàn",
  MSG3726: "Không tìm thấy dữ liệu ngày giao dịch",
  MSG3727: "Không tìm thấy dữ liệu kiểu sắp xếp",
  MSG3728: "Không tìm thấy dữ liệu ngày bắt đầu",
  MSG3729: "Không tìm thấy dữ liệu ngày kết thúc",
  MSG3730: "Mật khẩu Phone Agent cũ không tồn tại",
  MSG3731:
    "Dữ liệu lệnh điều kiện đã thay đổi, hệ thống không cho phép cập nhật",
  MSG3732: "Không tìm thấy thông tin tiểu khoản",
  MSG3733: "Tài khoản không đủ số dư để thực hiện đăng ký",
  MSG3734: "Mật khẩu phone agent mới không được quá @0 ký tự",
  MSG3735: "Mật khẩu phone agent mới không được phép null",
  MSG3736: "Chứng khoán khả dụng không đủ để thực hiện bán",
  MSG3737: "Ngày hệ thống đã thay đổi, bạn hãy đăng nhập lại để tiếp tục",
  MSG3738: "Không tìm thấy thông tin thực hiện quyền",
  MSG3739: "Dịch vụ đã duyệt, bạn không thể cập nhật",
  MSG3740: "Không tìm thấy thông tin dịch vụ cần sửa",
  MSG3741: "Có lỗi khi thực hiện vấn tin kho HTCK",
  MSG3742: "Không lấy được giá trị của Sequence @0",
  MSG3743: "Dữ liệu không hợp lệ để thực hiện sửa lệnh",
  MSG3744: "Không được phép sửa lệnh ngày quá khứ",
  MSG3745: "Số tiền ứng trước tối thiểu là @0",
  MSG3746: "Số tiền ứng trước tối đa là @0",
  MSG3747: "Số tiền ứng trước vượt quá hạn mức còn lại của công ty(@0)",
  MSG3748: "Không tìm thấy thông tin hợp đồng cấm cố: @0",
  MSG3749: "Khối lượng bán cầm cố không được phép vượt quá: @0",
  MSG3750: "Mã chứng khoán không được để trống",
  MSG3751: "Từ ngày không được để trống",
  MSG3752: "Đến ngày không được để trống",
  MSG3753: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc",
  MSG3754: "Dữ liệu input không hợp lệ",
  MSG3755: "Thông tin người dùng không hợp lệ",
  MSG3756: "Không xác định được phiên giao dịch của người dùng",
  MSG3757: "Mã xác thực không hợp lệ",
  MSG3758: "Mã nghiệp vụ không hợp lệ",
  MSG3759: "Mã ứng dụng không hợp lệ",
  MSG3760: "Độ dài mã xác thực không được vượt quá @0 ký tự",
  MSG3761: "Không tìm thấy thông tin xác thực hoặc mã OTP đã hết hạn.",
  MSG3762: "Đã hết thời gian xác thực",
  MSG3763: "Số lần xác thực quá số lượng cho phép",
  MSG3764: "Mã xác thực OTP không đúng, Quý khách vui lòng thử lại",
  MSG3765: "Không tìm thấy thông tin lệnh",
  MSG3766: "Trạng thái lệnh không hợp lệ",
  MSG3767: "Các lệnh được chọn đã thực hiện gửi email thông báo đến khách hàng",
  MSG3768: "Không tìm thấy thông tin người dùng",
  MSG3769: "Bản ghi đã tồn tại trong CSDL, bạn hãy thực hiện cập nhật",
  MSG3770: "Mã người dùng không hợp lệ",
  MSG3771: "Loại người dùng không hợp lệ",
  MSG3772: "Loại ứng dụng không hợp lệ",
  MSG3773: "Khối lượng giải chấp không đủ để đặt lệnh",
  MSG3774: "Số tài khoản khách hàng không được trống",
  MSG3775: "Mã chứng khoán không được trống",
  MSG3776: "Không tìm thấy thông tin tài khoản @0",
  MSG3777: "Không tìm thấy thông tin người dùng @0",
  MSG3778: "Không tìm thấy thông tin broker: @0",
  MSG3779: "Tài khoản @0 đang bị chặn đặt lệnh, bạn không thể thực hiện",
  MSG3780: "Bản ghi đã tồn tại trong CSDL",
  MSG3781: "Bản ghi không tồn tại để thực hiện hủy/sửa",
  MSG3782: "Trạng thái bản ghi không hợp lệ",
  MSG3783: "Loại Mua/Bán không hợp lệ",
  MSG3784: "Khối lượng Mua/Bán không hợp lệ",
  MSG3785: "Không tìm thấy mã chứng khoán @0 trong bảng mã",
  MSG3786: "Số hiệu lệnh không hợp lệ",
  MSG3787: "Ngày đặt lệnh không hợp lệ",
  MSG3788: "Không tìm thấy mã trái phiếu @0",
  MSG3789:
    "Tài khoản của bạn chưa được kích hoạt, bạn hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",
  MSG3790: "Loại lệnh không hợp lệ.",
  MSG3791: "Mã sàn không hợp lệ.",
  MSG3792:
    "Mã chứng khoán đang bị chặn giao dịch, bạn hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",
  MSG3793:
    "Thông tin lệnh đã thay đổi, bạn hãy làm mới dữ liệu và thực hiện lại.",
  MSG3794: "Không tìm thấy thông tin lệnh gốc.",
  MSG3795: "Dữ liệu đã thay đổi, bạn hãy làm mới và thực hiện lại.",
  MSG3796: "Không tìm thấy yêu cầu đặt lệnh thỏa thuận của khách hàng.",
  MSG3797:
    "Thông tin yêu cầu thỏa thuận của khách hàng đã thay đổi, bạn hãy làm mới dữ liệu và thử lại.",
  MSG3798: "Hệ thống không cho phép đặt lệnh lô lẻ với lệnh điều kiện.",
  MSG3799: "Không tìm thấy thông tin lệnh.",
  MSG3800: "Trạng thái lệnh không hợp lệ để thực hiện hủy.",
  MSG3801:
    "Tài khoản của bạn đã đóng tại công ty chứng khoán, bạn không thể đăng nhập.",
  MSG3802: "Từ ngày không được để trống.",
  MSG3803: "Đến ngày không được để trống.",
  MSG3804: "Từ ngày phải nhỏ hơn đến ngày.",
  MSG3805: "Trạng thái lệnh không hợp lệ để thực hiện sửa/hủy.",
  MSG3806: "Mã chứng khoán không được để trống.",
  MSG3807: "Ngày import không hợp lệ.",
  MSG3808: "Dữ liệu đã tồn tại trong CSDL.",
  MSG3809: "Không tìm thấy thông tin khuyển nghị MUA.",
  MSG3810:
    "Thông tin khuyến nghị MUA đã thay đổi, bạn hãy làm mới dữ liệu và thử lại.",
  MSG3811: "Không tìm thấy thông tin khuyển nghị BÁN.",
  MSG3812:
    "Thông tin khuyến nghị BÁN đã thay đổi, bạn hãy làm mới dữ liệu và thử lại.",
  MSG3813:
    "Tồn tại khuyến nghị Mua/Bán với mã chứng khoán @0, bạn không thể thực hiện.",
  MSG3814: "Không tìm thấy thông tin mã chứng khoán @0.",
  MSG3815: "Không xác định được lô.",
  MSG3816: "Khối lượng lô lẻ phải < @0.",
  MSG3817: "Khối lượng đặt phải >=  khối lượng tối thiểu (@0).",
  MSG3818: "Mã chứng khoán phái sinh không được giao dịch lệnh báo giá.",
  MSG3819:
    "Hệ thống giao dịch phái sinh đang tạm ngừng nhận lệnh, bạn hãy liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",
  MSG3820: "Không tìm thấy thông tin tài khoản phái sinh @0.",
  MSG3821: "Tài khoản phái sinh không được để trống.",
  MSG3822: "Không tìm thấy thông tin tài khoản.",
  MSG3823: "Không tìm thấy thông tin mã chứng khoán phái sinh @0.",
  MSG3824: "Không tìm thấy thông tin giá chứng khoán @0.",
  MSG3825: "Không tìm thấy thông tin tài khoản khái sinh @0.",
  MSG3826: "Dữ liệu input không hợp lệ.",
  MSG3827: "Mã chứng khoán không được trống.",
  MSG3828: "Tài khoản đặt lệnh không hợp lệ.",
  MSG3829: "Giá đặt không hợp lệ.",
  MSG3830: "Khối lượng nhập không hợp lệ.",
  MSG3831: "Loại lệnh nhập không hợp lệ.",
  MSG3832: "Không tìm thấy thông tin lệnh cần hủy.",
  MSG3833: "Trạng thái lệnh không hợp lệ để thực hiện lệnh hủy.",
  MSG3834: "Số hiệu lệnh không được trống.",
  MSG3835: "Cờ xác nhận/Từ chối không được để trống",
  MSG3836: "Không hỗ trợ loại lệnh @0 cho lệnh Phái sinh",
  MSG3837: "Không tìm thấy thông tin thành viên @0",
  MSG3838:
    "Tài khoản @0 bị cảnh báo vi phạm tỷ lệ an toàn sử dụng tài sản/vị thế.",
  MSG3839: "Bạn chưa nhập mật khẩu giao dịch hiện tại.",
  MSG3840: "Mã thành viên đối ứng không hợp lệ.",
  MSG3841:
    "Tài khoản đăng nhập không hợp lệ, không tìm thấy thông tin khách hàng.",
  MSG3842: "Khách hàng không đăng ký sử dụng dịch vụ: Gửi mật khẩu OTP",
  MSG3843: "Không tìm thấy thông tin yêu cầu thỏa thuận.",
  MSG3844: "Thông tin yêu cầu thỏa thuận đã thay đổi, bạn hãy làm mới dữ liêu.",
  MSG3845: "Tài khoản mua và tài khoản bán không được trùng nhau.",
  MSG3846: "Mật khẩu giao dịch hiện tại không đúng",
  MSG3847: "Không tìm thấy số điện thoại đăng ký nhận mã SMS OTP",
  MSG3848: "Không tìm thấy thông tin lệnh Repos No1",
  MSG3849: "Thông tin lệnh không thay đổi để thực hiện sửa",
  MSG3850: "Số tài khoản không hợp lệ",
  MSG3851: "Mã chứng khoán không hợp lệ",
  MSG3852: "Loại lệnh mua/bán không hợp lệ",
  MSG3853: "Trạng thái mã chứng khoán không hợp lệ",
  MSG3854:
    "Lỗi đặt lệnh cùng phiên, bạn đã có lệnh đối ứng với mã chứng khoán này.",
  MSG3855: "Danh mục đã tồn tại trong CSDL",
  MSG3856: "Không tìm thấy thông tin danh mục",
  MSG3857:
    "Kênh giao dịch đang tạm khóa, bạn hãy liên hệ nhân viên CSKH để được trợ giúp.",
  MSG3858:
    "Kênh giao dịch đang tạm khóa, bạn hãy liên hệ nhân viên CSKH để được trợ giúp.",
  MSG3859: "Thông tin tài khoản không hợp lệ",
  MSG3860: "Bạn không được phép đặt lệnh báo giá với trái phiếu sàn HNX",
  MSG3861: "Trái phiếu chỉ được phép đặt lệnh báo giá.",
  MSG3862:
    "Tiểu khoản @0 đang có có dư nợ cầm cố mã @1, bạn không thể đặt lệnh",
  MSG3863:
    "Mã chứng khoán @0 nằm trong danh mục không được phép mua cầm cố, bạn không thể đặt lệnh",
  MSG3864: "Tên tài khoản ngân hàng không vượt quá @0 ký tự",
  MSG3865: "Số tài khoản ngân hàng không vượt quá @0 ký tự",
  MSG3866: "Ký tự xác nhận không hợp lệ.",
  MSG3867: "KH @0 đang bị cảnh báo đặt lệnh MUA.",
  MSG3868: "KH @0 đang bị cảnh báo lệnh BÁN.",
  MSG3869: "KH @0 đang bị cấm đặt lệnh MUA.",
  MSG3870: "KH @0 đang bị cấm đặt lệnh BÁN.",
  MSG3871:
    "Không được phép đặt lệnh báo giá lô lẻ đối với trái phiếu sàn HNX/UpCOM.",
  MSG3872: "Tài khoản không sử dụng dịch vụ Contact center.",
  MSG3873: "Tài khoản xác thực không hợp lệ.",
  MSG3874: "Giá trị BusinessCd không hợp lệ.",
  MSG3875: "Giá trị TelPinType không lệ.",
  MSG3876: "Mật khẩu Contact center không đúng, bạn hãy thử lại.",
  MSG3877: "Bạn chưa nhập mật khẩu CC hiện tại",
  MSG3878: "Mật khẩu CC mới trùng mật khẩu hiện tại",
  MSG3879: "Mật khẩu CC mới không được quá @0 ký tự",
  MSG3880: "Chưa nhập mật khẩu CC mới",
  MSG3881: "Mật khẩu CC mới không khớp",
  MSG3882: "Không tìm thấy thông tin đăng ký dịch vụ Contact Center",
  MSG3883: "Mật khẩu Contact Center không đúng",
  MSG3884:
    "Mật khẩu truy cập hệ thống và mật khẩu giao dịch không được trùng nhau.",
  MSG3885: "Người thực hiện xác nhận lệnh phải là NVCS của khách hàng",
  MSG3886: "Lệnh đã xác nhận, bạn không thể thực hiện lại.",
  MSG3887: "Bạn không có quyền thực hiện Xác nhận lệnh cho tài khoản @0.",
  MSG3888: "Bạn chưa nhập ký tự SmartOTP.",
  MSG3889: "Thiết bị của bạn chưa đăng ký sử dụng SmartOTP.",
  MSG3890: "Ký tự xác nhận Smart OTP không đúng.",
  MSG3891:
    "Quý khách chưa có tài khoản chứng khoán. Liên hệ Công ty chứng khoán Công Thương: <a href='tel:1900588866'>1900588866</a>  để được hỗ trợ.",
  MSG3892:
    "Quý khách vui lòng đăng ký dịch vụ giao dịch trực tuyến và SMS tại Công ty chứng khoán công thương để thực hiện giao dịch. Liên hệ Công ty chứng khoán công thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG3893:
    "Quý khách vui lòng đăng ký dịch vụ giao dịch trực tuyến  tại Công ty chứng khoán công thương để thực hiện giao dịch. Liên hệ Công ty chứng khoán công thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG3894:
    "Quý khách vui lòng đăng ký dịch vụ SMS tại Công ty chứng khoán công thương để thực hiện giao dịch. Liên hệ Công ty chứng khoán công thương: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ.",
  MSG3895: "Dữ liệu mapping của tài khoản @0 đã tồn tại trong CSDL.",
  MSG3896:
    "Quý khách không có quyền truy cập chức năng giao dịch chứng khoán. LH: <a href='tel:1900588866'>1900588866</a> để được hỗ trợ",
  MSG3897: "Không hỗ trợ đặt lệnh lô lẻ với lệnh trước giờ.",
  MSG3899: "Không tìm thấy thông tin triển khai của Chiến thuật @0.",
  MSG3900: "Trạng thái triển khai (@0) không hợp lệ để thực hiện đặt lệnh.",
  MSG3901:
    "Không thể mở deal mới khi triển khai (@0) đang tồn tại một deal đang kích hoạt (@1).",
  MSG3902:
    "Không tìm thấy deal đang mở để đặt lệnh đóng vị thế cho triển khai (@0) của chiến thuật.",
  MSG3903: "Trạng thái deal(@0,@1) không hợp lệ để tạo lệnh đóng vị thế",
  MSG3904: "Không tìm thấy mã chứng khoán @0 để thực hiện tạo lệnh cho BOT",
  MSG3905: "Mã triển khai không hợp lệ",
  MSG3906: "Loại điều kiện (Entry/Exit) không được để trống",
  MSG3907: "Thông tin mã chứng khoán không hợp lệ",
  MSG3908: "Không tìm thấy StrategyRecord có mã @0.",
  MSG3909: "Chỉ được phép đặt lệnh đóng với lệnh mở vị thế đã khớp.",
  MSG3910: "Thông tin input không hợp lệ (Mã entry null).",
  MSG3911: "Thông tin input không hợp lệ (Ngày giao dịch null).",
  MSG3912: "Lệnh của bạn tạm thời không được sửa/hủy.",
  MSG3913: "Lệnh thỏa thuận lô lẻ chỉ được phép đặt trong phiên KLLT.",
  MSG3914: "Phiên @0 không cho phép đặt lệnh thỏa thuận lô lẻ.",
  MSG3915:
    "Mã chứng khoán mới niêm yết/giao dịch trở lại sau 25 phiên không được giao dịch lô lẻ.",
  MSG4001: "Khách hàng không đủ số dư chứng khoán để bán",
  MSG4002: "Khách hàng không đủ số dư tiền để thực hiện lệnh mua",
  MSG4003: "Tồn tại yêu cầu đang chờ xử lý với lệnh này",
  MSG4004: "Không được mua bán cùng mã CK phiên khớp lệnh định kỳ",
  MSG4005: "Trạng thái lệnh không hợp lệ để thực hiện hủy lệnh",
  MSG4006: "Không được phép hủy lệnh ATO/ATC với sàn giao dịch HSX",
  MSG4007:
    "Không được phép hủy/sửa lệnh trong phiên khớp lệnh định kỳ đóng của",
  MSG4008: "Sai thông tin đăng nhập, bạn vui lòng thử lại",
  MSG4009:
    "Tài khoản của bạn đang bị khóa truy cập, bạn hãy liên hệ nhân viên CSKH để được trợ giúp.",
  MSG4010: "Sai mật khẩu đăng nhập, bạn vui lòng thử lại",
  MSG4011:
    "Tài khoản của bạn bị khóa do đăng nhập sai quá @0 lần, bạn hãy liên hệ nhân viên CSKH để được trợ giúp.",
  MSG4012: "Không tìm thấy sức mua.",
  MSG4013: "Không đủ sức mua để sửa lệnh.",
  MSG4014: "Tiểu khoản @0 đã tồn tại trong nhóm.",
  MSG4015: "Không tìm thấy thông tin lệnh gốc",
  MSG4016: "Trạng thái lệnh không hợp lệ để thực hiện sửa tài khoản",
  MSG4017: "Lệnh đã khớp một phần, bạn không được phép sửa tài khoản",
  MSG4018: "Số tiền muốn rút vượt quá khả dụng",
  MSG4019: "Lệnh chọn duyệt không đúng trình tự",
  MSG4020: "Lệnh đặt Online không được phép hủy tại Floor",
  MSG4021: "Lệnh ATO/ATC không được phép sửa",
  MSG4022: "Khối lượng chờ khớp đã thay đổi, bạn cần làm mới dữ liệu.",
  MSG4023: "Lệnh dừng chưa kích hoạt không được phép sửa.",
  MSG4024: "Lệnh MOK/MAK không được phép sửa",
  MSG4025: "Lệnh đang chờ xác nhận từ sàn không được phép hủy",
  MSG4026: "Không được phép hủy lệnh trong phiên KLĐK",
  MSG4027: "Số lần chuyển khoản trong ngày đã vượt quá số lần cho phép",
  MSG4028: "Tiểu khoản không hợp lệ",
  MSG4029: "Mã CCQ không hợp lệ",
  MSG4030: "Giá trị mua không hợp lệ",
  MSG4031: "Phương thức thanh toán không hợp lệ",
  MSG4032: "Ngày bắt đầu phải lớn hơn ngày hiện tại",
  MSG4033: "Kỳ hạn không hợp lệ",
  MSG4034: "Chu kỳ thanh toán không hợp lệ",
  MSG4035: "Không xác định được dữ liệu hợp đồng cần cập nhật",
  MSG4036: "Không vấn tin được sức mua",
  MSG4037: "Giá trị mua vượt quá sức mua tối đa",
  MSG4038:
    "Tồn tại dữ liệu chi tiết hợp động trạng thái không hợp lệ (Đã khớp lệnh hoặc Đã thanh toán)",
  MSG4039: "Không tìm thấy thông tin Hợp đồng SIP",
  MSG4040: "Trạng thái lệnh không hợp lệ hoặc dữ liệu lệnh đã thay đổi",
  MSG4041: "Không đủ số dư chứng khoán để bán",
  MSG4042:
    "Số lượng chứng khoán bán vượt quá số lượng có thể bán tối đa của HĐ SIP",
  MSG4043: "Loại tiểu khoản không hợp lệ",
  MSG4044: "Số dư còn lại sau khi bán không đủ số dư tối thiểu yêu cầu @0",
  MSG4045: "Không tìm thấy thông tin CCQ",
  MSG4046: "Đã quá thời gian chốt đặt lệnh",
  MSG4047: "Không tìm thấy thông tin hợp đồng Margin tương ứng",
  MSG4048:
    "Món vay chưa đến ngày thanh toán (Ngày bắt đầu cho phép thanh toán là @0)",
  MSG4049: "Không tìm thấy thông tin hợp đồng Cầm cố tương ứng",
  MSG4050: "Loại tiểu khoản không hợp lệ (Không phải tiểu khoản tự doanh)",
  MSG4051: "Giá trị mua lần đầu tối thiểu phải là @0",
  MSG4052: "Trạng thái chi tiết hợp đồng SIP không hợp lệ",
  MSG4053: "Chưa chọn hình thức trả phí Trong/Ngoài",
  MSG4054: "Loại tiểu khoản không hợp lệ",
  MSG4055: "Số tiền giải tỏa vượt quá số tiền có thể giải tỏa @0",
  MSG4056: "Room margin còn lại không đủ để đặt lệnh",
  MSG4057: "Tiểu khoản @0 chưa đăng ký dịch vụ @1",
  MSG4058: "Mật khẩu giao dịch qua điện thoại không đúng",
  MSG4059:
    "Hệ thống không cho phép hủy lệnh chờ khớp trong thời gian nghỉ giữa phiên",
  MSG4060: "Hệ thống đang mở ngày, bạn vui lòng truy cập lại sau ít phút",
  MSG4061: "Tồn tại lệnh MUA đối ứng đang chờ khớp có giá >=  giá BÁN",
  MSG4062: "Tồn tại lệnh BÁN đối ứng đang chờ khớp có giá <=  giá MUA",
  MSG4063: "Không tìm thấy tham số @0 của ngân hàng @1",
  MSG4064: "Không tìm thấy tham số ngân hàng @0",
  MSG4065: "Tài khoản Margin không được phép mua mã chứng khoán ngoài danh mục",
  MSG4066: "Hiện chưa có đợt bán đối với cổ phiếu quỹ, bạn không thể thực hiện",
  MSG4067: "Hiện chưa có đợt mua đối với cổ phiếu quỹ, bạn không thể thực hiện",
  MSG4068: "Khối lượng đặt phải >=  khối lượng tối thiểu được phép đặt (@0)",
  MSG4069: "Khối lượng đặt phải <=  khối lượng tối đa được phép đặt (@0)",
  MSG4070: "Khối lượng đặt phải <=  khối lượng còn lại đăng ký (@0)",
  MSG4071: "Giá bán phải >=  giá bán tối thiểu (@0)",
  MSG4072: "Giá mua phải <=  giá mua tối đa (@0)",
  MSG4073: "Tiểu khoản @0 chưa đăng ký giao dịch quỹ mở @1",
  MSG4074: "Khối lượng mua/bán phải <=  khối lượng tối đa (@0)",
  MSG4075: "Khối lượng bán phải <=  KL bán tối đa (@0)",
  MSG4076: "Khối lượng mua phải <=  KL mua tối đa (@0)",
  MSG4077: "Có lỗi khi thực hiện vấn tin tài khoản phái sinh @0",
  MSG4078: "Tài khoản không đủ số sư ký quỹ để đặt lệnh",
  MSG4079: "Room tự doanh không đủ để thực hiện. Room còn lại @0",
  MSG4080: "Khối lượng khớp không hợp lệ",
  MSG4081: "Ngày @0 là ngày nghỉ",
  MSG4082: "Bạn chưa đăng ký dịch vụ đặt lênh online",
  MSG4083:
    "CMND/CCCD của Quý khách đã hết hạn. Đăng ký mở tài khoản GDCK không thành công",
  MSG4084:
    "Số CMND/CCCD của Quý khách đã được sử dụng để mở tài khoản tại CTS. Quý khách vui lòng liên hệ với CTS để được hỗ trợ.LH: 1900 588 866",
  MSG4085: "Số lượng mã cổ phiếu nắm giữ của tiểu khoản không được vượt quá @0",
  MSG4086:
    "Tiểu khoản chưa thực hiện kết nối ngân hàng. Quý khách vui lòng liên hệ CTCK để được hỗ trợ",
  MSG4087:
    "CMND của Quý khách đã hết hạn, không thể thực hiện yêu cầu. Quý khách vui lòng liên hệ tổng đài 1900 588 866 để được hỗ trợ",
  MSG4088: "Không đúng định dạng giờ (@0)",
  MSG4089: "Hạn mức còn lại của điểm giao dịch @0 không đủ (còn lại @1)",
  MSG4090: "Người dùng không được cấp quyền cho thao tác @1 với giá trị @2",
  MSG4091:
    "Hệ thống không hõ trợ hủy lệnh thỏa thuận 1F đã khớp sàn HNX, UpCOM",
  MSG4092:
    "Chiến thuật đang triển khai. Quý khách vui lòng dừng hết các chiến thuật đang triển khai và thử lại",
  MSG4093: "Không tìm thấy thông tin chiến thuật",
  MSG5001: "Không tìm thấy thông tin tài khoản",
  MSG5002: "Số tiểu khoản không được để trống",
  MSG5003: "Mã trái phiếu không được để trống",
  MSG5004: "Kỳ hạn trái phiếu không được để trống",
  MSG5005: "Khối lượng trái phiếu không được để trống",
  MSG5006: "Không tìm thấy thông tin tiểu khoản @0",
  MSG5007: "Không tìm thấy thông tin mã trái phiếu @0",
  MSG5008: "Không tìm thấy sản phẩm bán lẻ mã trái phiều @0 kỳ hạn @1 tháng.",
  MSG5009: "Mã RBond orde không được để trống.",
  MSG5010: "Rbond order UpDateTime is not null.",
  MSG5011: "Không tìm thấy dữ liệu Rbond order @0.",
  MSG5012:
    "Dữ liệu Rbond order đã thay đổi, bạn hãy làm mới trước khi thực hiện.",
  MSG5013: "Tiểu khoản của bạn không đủ số dư để thực hiện lệnh mua.",
  MSG5014: "Bạn chỉ được hủy các lệnh có trạng thái 0: Chưa duyệt",
  MSG5015: "Khối lượng trái phiếu tối thiểu được phép mua là @0.",
  MSG5016: "Khối lượng trái phiếu tối đa được phép mua là @0.",
  MSG5017: "Không thực hiện được lệnh của chính mình",
  MSG5018: "Dữ liệu đầu vào không đúng định dạng (@0)",
  MSG5019: "Số tài khoản nhận chuyển nhượng không hợp lệ",
  MSG5020: "Số tài khoản chuyển nhượng không hợp lệ",
  MSG5021: "Mã trái phiếu không hợp lệ",
  MSG5022: "Khối lượng chuyển nhượng không hợp lệ",
  MSG5023: "Giá chuyển nhượng không hợp lệ",
  MSG5024: "Không tìm thấy thông tin tài khoản nhận chuyển nhượng (@0)",
  MSG5025: "Không tìm thấy thông tin tài khoản chuyển nhượng (@0)",
  MSG5026: "Không tìm thấy thông tin trái phiếu mã @0",
  MSG5027:
    "Tài khoản chuyển nhượng không đủ trái phiếu để thực hiện chuyển nhượng",
  MSG5028:
    "Tài khoản nhận chuyển nhượng không khả dụng để thực hiện chuyển nhượng",
  MSG5029: "Tài chuyển nhượng chưa có thông tin trái chủ.",
  MSG5030: "Không tìm thấy thông tin lệnh để thực hiện yêu cầu hủy",
  MSG5031: "Trạng thái lệnh đã thay đổi, bạn không thể thực hiện hủy",
  MSG5032: "Độ dài ghi chú không vượt quá 200 ký tự",
  MSG5033: "Bạn chưa nhập loại lãi.",
  MSG5034: "Tài khoản đã ngừng Giao dịch tạm thời.",
  MSG5035: "@0 từ chối yêu cầu vì mã ck ko nằm trong danh mục được ký quỹ.",
  MSG5036: "Không tìm thấy thông tin tiểu khoản chuyển (@0)",
  MSG5037: "Không tìm thấy thông tin tiểu khoản nhận (@0)",
  MSG5038: "Không tìm thấy thông tin mã trái phiếu (@0)",
  MSG5039: "Hệ thống chưa đến giờ nhận chuyển trái phiếu (@0)",
  MSG5040: "Đã hết giờ nhận chuyển trái phiếu (@0)",
  MSG5041: "Trạng thái yêu cầu không hợp lệ để thực hiện hủy",
  MSG5042: "Hợp đồng khung của TK @0 không hợp lệ hoặc đã hết hạn",
  MSG5043: "Tài khoản @0 không hợp lệ",
  MSG5044: "Bạn chưa nhập tài khoản chuyển",
  MSG5045: "Bạn chưa nhập tài khoản nhận",
  MSG5046: "Bạn chưa nhập Mã trái phiếu",
  MSG5047: "Bạn chưa nhập khối lượng chuyển nhượng",
  MSG5048: "Hợp đồng khung của Quý khách không hợp lệ hoặc đã hết hạn",
  MSG5049: "Không tìm thấy thông tin tiểu khoản @0",
  MSG5050: "Phương thức thanh toán của TK @0 không hợp lệ",
  MSG5051: "Không tìm thấy thông tin trái chủ",
  IDG_00000000: "Thành công",
  IDG_00000050: "Không kết nối được tới service AI",
  IDG_00000500: "Lỗi hệ thống",
  IDG_00010002: "Lỗi dữ liệu ảnh đầu vào không đúng format",
  IDG_00010004: "Hệ thống đang bận",
  IDG_00010101: "Lỗi phân loại giấy tờ",
  IDG_00010102:
    "Lỗi dữ liệu ảnh đầu vào phân loại giấy tờ(Kiểm tra file dữ liệu nhận được từ Minio xem có phải là ảnh không)",
  IDG_00010201: "Lỗi nhận dạng ký tự trên ảnh giấy tờ",
  IDG_00010202: "Lỗi dữ liệu ảnh đầu vào ocr",
  IDG_00010301: "Lỗi nhận dạng ký tự trên ảnh giấy tờ mặt trước",
  IDG_00010302: "Lỗi dữ liệu ảnh đầu vào ocr mặt trước",
  IDG_00010401: "Lỗi nhận dạng ký tự trên ảnh giấy tờ mặt sau",
  IDG_00010402: "Lỗi dữ liệu ảnh đầu vào ocr mặt sau",
  IDG_00010444: "Lỗi định dạng dữ liệu nhập vào",
  IDG_00010445: "Lỗi không đúng luồng giấy tờ",
  IDG_00010501: "Lỗi so sánh mặt",
  IDG_00010502: "Lỗi dữ liệu ảnh đầu vào so sánh mặt",
  MSG5052:
    " Quý khách cần chứng nhận là NĐT chuyên nghiệp để nhận chuyển nhượng. Vui lòng liên hệ hotline @0 để được hỗ trợ!",
  MSG5053: " Nội dung không được vượt quá 4000 ký tự",
  EKYC0001: "[Face] Bạn chưa chụp ảnh chân dung",
  EKYC0002: "Lỗi upload ảnh vui lòng kiểm tra lại",
  EKYC0003: "Khuôn mặt không khớp",
  MSG5054: "Chỉ được sửa tiểu khoản mặc định chính chủ",
  MSG5055: "Chỉ được lấy dữ liệu trong vòng @0 ngày",
  MSG5056: "Quý khách vui lòng lấy dữ liệu xác nhận lệnh trong vòng @0 ngày",
  EKYC0004: "[Front] Bạn chưa chọn ảnh mặt trước",
  EKYC0005: "[Back] Bạn chưa chọn ảnh mặt sau",
  EKYC0006: "Lỗi upload ảnh vui lòng kiểm tra lại",
  EKYC0007: "Số CMND/CCCD không đúng hoặc đã tồn tại",
  MSG5057: "Bạn phải nhập số điện thoại hoặc địa chỉ email",
  "100001": "Mã CK đang ở trạng thái đã thiết lập",
  "100002": "Mã Index đang ở trạng thái đã thiết lập",
  "100003": "Ngày hiệu lực đang lớn hơn ngày hết hạn",
  "100004": "Không tìm thấy thông tin mã chứng khoán",
  "100005": "Mã Index không được rỗng",
  "100006": "Mã CK không được rỗng",
  "100007": "Account No không được để trống",
  "100008": "Khoảng thời gian tìm kiếm vượt quá 30 ngày",
  "100009": "Can not found info marketQuotes, marketCd: @0",
  "100010": "Not found tradeDate redis",
};
export default errors;
