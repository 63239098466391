import {
  ContentRight,
  ContentLeft,
  Container,
  LabelCell,
  ContentRightTop,
  ContentRightBottom,
} from "./styles";

import { reset } from "modules/transfer-local/redux";

import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import RequestDetail from "./RequestDetail";
import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";
import RequestTableBond from "./RequestTableBond/index";
import { FormattedMessage } from "react-intl";
const BidAskBondContent: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Container>
      <ContentLeft>
        <LabelCell>
          <FormattedMessage id="bidAskBondOrder.title.orderData" />
        </LabelCell>
        <RequestDetail />
      </ContentLeft>
      <ContentRight>
        <ContentRightTop>
          <LabelCell>
            <FormattedMessage id="bidAskBondOrder.title.bondList" />
          </LabelCell>

          <RequestTableBond />
        </ContentRightTop>
        <ContentRightBottom>
          <LabelCell>
            <FormattedMessage id="bidAskBondOrder.title.orderBook" />
          </LabelCell>
          <RequestFilter />
          <RequestTable />
        </ContentRightBottom>
      </ContentRight>
    </Container>
  );
};
export default BidAskBondContent;
