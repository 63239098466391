import { combineReducers } from "redux";
import listHistoryTransferShareSlice from "./listHistory";
import transferShareFormSlice from "./transferForm";
import { ITransferSec } from "domain/models/TransferShare";
export interface TransferShareState {
  listHistory: ITransferSec[];
}
export default combineReducers({
  listHistory: listHistoryTransferShareSlice,
  transferShareForm: transferShareFormSlice,
});
