import { FC } from "react";
import Filter from "./Filters";
import Table from "./Table";

const PlaceOrderTransferBondsContent: FC = () => {
  return (
    <>
      <Filter />
      <Table />
    </>
  );
};
export default PlaceOrderTransferBondsContent;
