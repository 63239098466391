import VgaiaHttp from "core/http/singleton/vgaia";
import { IAccountInfoResponse } from "domain/models/AccountInfo";
import { IFindAccountInfoParams } from "./types";
import { inquiryAccountTotalForMobileUrl } from "./urls";

export const getAccountInfoApi = async (
  params: IFindAccountInfoParams
): Promise<IAccountInfoResponse> => {
  const res = await VgaiaHttp.get<IAccountInfoResponse>(
    inquiryAccountTotalForMobileUrl(params.subAccNo)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
