import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFindParameterBankDetailParams,
  IFindTransferContractLocalParams,
  IGetSubAccToLocalListParams,
  IInquiryAccountTraParams,
  ISearchTransferLocalHisParams,
  ISearchTransferLocalParams,
  ISubmitTransferParams,
} from "core/http/apis/transfer-local/types";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IParameterBankDataResponse } from "domain/models/ParameterBankData";
import { ITransferContractLocalRepsonse } from "domain/models/TransferContractLocal";
import {
  ITransferLocal,
  ITransferLocalResponse,
} from "domain/models/TransferLocal";
import { ITransferLocalModelResponse } from "domain/models/TransferLocalModel";
import { logOutSuccess } from "modules/auth/redux";
import { TRANSFER_LOCAL_TABS } from "./constants";
import { cloneDeep } from "lodash";
import { EOTPTypeSubmit } from "helper/consts";

export interface IItemCalculate {
  subAccoNoName?: string;
  subAccoToName?: string;

  balance?: number;
  transferLimit?: number;
  withdrawableCash?: number;
  withdrawableAsset?: number;

  startTime?: string;
  endTime?: string;
  minAmount?: number;
  maxAmount?: number;
  transferFeeRate?: number;
  transferFeeMin?: number;
  transferFee?: number;
  transferFeeFlag?: number;
}

export interface ITransferLocalConfirmContent {
  cashTransferText?: string;
}

interface ITransferLocalFilter {
  fromSubAccoNo?: string;
  toSubAccoNo?: string;
  status?: number;
}

interface ITransferLocalHisFilter {
  toSubAccNo?: string;
  fromSubAccNo?: string;
  status?: string;
  fromDate?: Date;
  toDate?: Date;
}

export interface ReduxData {
  activeTab: string;

  transferLocal: {
    loading: boolean;
    filter: ITransferLocalFilter;
    list: ITransferLocal[];
    itemSelected?: ITransferLocal;

    itemEdit?: ISubmitTransferParams;
    itemCalculate?: IItemCalculate;

    accFromHidden?: boolean;
    accToHidden?: boolean;
    amountHidden?: boolean;

    confirmationContent?: ITransferLocalConfirmContent;

    isShowConfirmModal?: boolean;

    mode: string;
  };
  transferLocalHis: {
    loading: boolean;
    filter: ITransferLocalHisFilter;
    list: ITransferLocal[];
  };
}

const initialState: ReduxData = {
  activeTab: TRANSFER_LOCAL_TABS.TRANSFER_LOCAL,

  transferLocal: {
    loading: false,
    filter: {
      fromSubAccoNo: "",
      toSubAccoNo: "",
      status: 0,
    },
    list: [],
    accToHidden: true,
    isShowConfirmModal: false,
    mode: "1",
  },
  transferLocalHis: {
    loading: false,
    filter: {
      toSubAccNo: "",
      fromSubAccNo: "",
      status: "-1",
      fromDate: new Date(),
      toDate: new Date(),
    },
    list: [],
  },
};

const transferLocalSlice = createSlice({
  name: "transferLocalSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },

    searchTransferLocalRequest: (
      state,
      action: PayloadAction<ISearchTransferLocalParams>
    ) => {
      state.transferLocal.loading = true;
    },
    searchTransferLocalSuccess: (
      state,
      action: PayloadAction<ITransferLocalResponse>
    ) => {
      state.transferLocal.loading = false;
      state.transferLocal.list = action.payload.data ?? [];
    },
    searchTransferLocalFailure: (state) => {
      state.transferLocal.loading = false;
    },

    searchTransferLocalHisRequest: (
      state,
      action: PayloadAction<ISearchTransferLocalHisParams>
    ) => {
      state.transferLocalHis.loading = true;
    },
    searchTransferLocalHisSuccess: (
      state,
      action: PayloadAction<ITransferLocalResponse>
    ) => {
      state.transferLocalHis.loading = false;
      state.transferLocalHis.list = action.payload.data ?? [];
    },
    searchTransferLocalHisFailure: (state) => {
      state.transferLocalHis.loading = false;
    },

    submitTransferRequest: (
      state,
      action: PayloadAction<ISubmitTransferParams>
    ) => {
      state.transferLocalHis.loading = true;
    },
    submitTransferSuccess: (
      state,
      action: PayloadAction<ITransferLocalResponse>
    ) => {
      state.transferLocalHis.loading = false;
    },
    submitTransferFailure: (state) => {
      state.transferLocalHis.loading = false;
    },

    inquiryAccountTraRequest: (
      state,
      action: PayloadAction<IInquiryAccountTraParams>
    ) => {},
    inquiryAccountTraSuccess: (
      state,
      action: PayloadAction<ITransferLocalModelResponse>
    ) => {
      state.transferLocal.itemCalculate = {
        ...state.transferLocal.itemCalculate,
        subAccoNoName: action.payload.data?.fullName,
        balance: action.payload.data?.balance,
        transferLimit:
          (action.payload.data?.transferAvail ?? 0) +
          (state.transferLocal.itemSelected?.amount ?? 0) +
          (state.transferLocal.itemSelected?.feeAmount ?? 0),
        withdrawableCash: action.payload.data?.withdrawAvailByCash,
        withdrawableAsset: action.payload.data?.withdrawAvailByAsset,
      };
    },
    inquiryAccountTraFailure: (state) => {},

    findTransferContractLocalRequest: (
      state,
      action: PayloadAction<IFindTransferContractLocalParams>
    ) => {},
    findTransferContractLocalSuccess: (
      state,
      action: PayloadAction<ITransferContractLocalRepsonse>
    ) => {
      state.transferLocal.itemEdit = {
        ...state.transferLocal.itemEdit,
        remarks: action.payload.data[2]
          .replace("%0", state.transferLocal.itemEdit?.subAccoNo ?? "")
          .replace("%1", state.transferLocal.itemEdit?.subAccoTo ?? ""),
        otpType: EOTPTypeSubmit.OTP,
      };

      state.transferLocal.itemCalculate = {
        ...state.transferLocal.itemCalculate,
        subAccoToName: action.payload.data[0] ?? undefined,
      };
    },
    findTransferContractLocalFailure: (state) => {},

    findParameterBankDetailRequest: (
      state,
      action: PayloadAction<IFindParameterBankDetailParams>
    ) => {},
    findParameterBankDetailSuccess: (
      state,
      action: PayloadAction<IParameterBankDataResponse>
    ) => {
      state.transferLocal.itemCalculate = {
        ...state.transferLocal.itemCalculate,
        startTime: action.payload.data?.startTime,
        endTime: action.payload.data?.endTime,
        minAmount: action.payload.data?.minAmount,
        maxAmount: action.payload.data?.maxAmount,
        transferFeeRate: action.payload.data?.transferFeeRate,
        transferFeeMin: action.payload.data?.transferFeeMin,
        transferFee: action.payload.data?.transferFee,
        transferFeeFlag: action.payload.data?.transferFeeFlg
          ? +action.payload.data?.transferFeeFlg
          : 0,
      };
    },
    findParameterBankDetailFailure: (state) => {},

    changeSubAccountNoRequest: (
      state,
      action: PayloadAction<IInquiryAccountTraParams>
    ) => {},
    changeSubAccountNoSuccess: (
      state,
      action: PayloadAction<ITransferLocalModelResponse>
    ) => {},
    changeSubAccountNoFailure: (state) => {},

    getSubAccToLocalListRequest: (
      state,
      action: PayloadAction<IGetSubAccToLocalListParams>
    ) => {},
    getSubAccToLocalListSuccess: (
      state,
      action: PayloadAction<IBasePairValueResponse>
    ) => {},
    getSubAccToLocalListFailure: (state) => {},

    updateTransferLocalFilter: (
      state,
      action: PayloadAction<{ key: keyof ITransferLocalFilter; value: any }>
    ) => {
      state.transferLocal.filter = {
        ...state.transferLocal.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateTransferLocalHisFilter: (
      state,
      action: PayloadAction<{ key: keyof ITransferLocalHisFilter; value: any }>
    ) => {
      state.transferLocalHis.filter = {
        ...state.transferLocalHis.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    updateItemSelected: (
      state,
      action: PayloadAction<ITransferLocal | undefined>
    ) => {
      state.transferLocal.itemSelected = action.payload;
    },

    updateItemEdit: (
      state,
      action: PayloadAction<ISubmitTransferParams | undefined>
    ) => {
      if (!action.payload) {
        state.transferLocal.itemEdit = undefined;
        return;
      }

      state.transferLocal.itemEdit = {
        ...state.transferLocal.itemEdit,
        ...action.payload,
      };
    },
    updateItemCalculate: (
      state,
      action: PayloadAction<IItemCalculate | undefined>
    ) => {
      if (!action.payload) {
        state.transferLocal.itemCalculate = undefined;
        return;
      }

      state.transferLocal.itemCalculate = {
        ...state.transferLocal.itemCalculate,
        ...action.payload,
      };
    },

    updateConfirmationContent: (
      state,
      action: PayloadAction<ITransferLocalConfirmContent | undefined>
    ) => {
      if (!action.payload) {
        state.transferLocal.confirmationContent = undefined;
        return;
      }

      state.transferLocal.confirmationContent = {
        ...state.transferLocal.confirmationContent,
        ...action.payload,
      };
    },

    setAccFromHidden: (state, action: PayloadAction<boolean>) => {
      state.transferLocal.accFromHidden = action.payload;
    },

    setAccToHidden: (state, action: PayloadAction<boolean>) => {
      state.transferLocal.accToHidden = action.payload;
    },

    setAmountHidden: (state, action: PayloadAction<boolean>) => {
      state.transferLocal.amountHidden = action.payload;
    },

    updateModalStatusRequest: (
      state,
      action: PayloadAction<{ isVisible: boolean; listAcc?: any[] }>
    ) => {
      state.transferLocal.isShowConfirmModal = action.payload.isVisible;

      if (!action.payload) {
        state.transferLocal.confirmationContent = undefined;
      }
    },
    updateModalStatusSuccess: (state, action: PayloadAction<boolean>) => {
      state.transferLocal.isShowConfirmModal = action.payload;
    },
    updateMode: (state, action: PayloadAction<string>) => {
      state.transferLocal.mode = action.payload;
    },
  },
});

export const {
  searchTransferLocalFailure,
  searchTransferLocalHisFailure,
  searchTransferLocalHisRequest,
  searchTransferLocalHisSuccess,
  searchTransferLocalRequest,
  searchTransferLocalSuccess,

  updateTransferLocalFilter,
  updateTransferLocalHisFilter,

  submitTransferRequest,
  submitTransferFailure,
  submitTransferSuccess,

  findParameterBankDetailFailure,
  findParameterBankDetailRequest,
  findParameterBankDetailSuccess,

  findTransferContractLocalFailure,
  findTransferContractLocalRequest,
  findTransferContractLocalSuccess,

  inquiryAccountTraFailure,
  inquiryAccountTraRequest,
  inquiryAccountTraSuccess,

  changeSubAccountNoRequest,
  changeSubAccountNoSuccess,
  changeSubAccountNoFailure,

  getSubAccToLocalListRequest,
  getSubAccToLocalListFailure,
  getSubAccToLocalListSuccess,

  updateItemSelected,

  updateItemEdit,
  updateItemCalculate,

  setAccFromHidden,
  setAccToHidden,
  setAmountHidden,

  updateModalStatusRequest,
  updateModalStatusSuccess,

  updateConfirmationContent,

  updateMode,

  updateActiveTab,

  reset,
} = transferLocalSlice.actions;

export default transferLocalSlice.reducer;
