// package: market_api
// file: auto_trading.proto

var auto_trading_pb = require("./auto_trading_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MarketApi = (function () {
  function MarketApi() {}
  MarketApi.serviceName = "market_api.MarketApi";
  return MarketApi;
}());

MarketApi.findWatchList = {
  methodName: "findWatchList",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.WatchListInput,
  responseType: auto_trading_pb.SymbolTotalInfo
};

MarketApi.findMarketDepth = {
  methodName: "findMarketDepth",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.MarketDepthInput,
  responseType: auto_trading_pb.MarketDepthResponse
};

MarketApi.findMarketMatching = {
  methodName: "findMarketMatching",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.MarketMatchingInput,
  responseType: auto_trading_pb.MarketMatchingResponse
};

MarketApi.findTimeSale = {
  methodName: "findTimeSale",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.TimeSaleInput,
  responseType: auto_trading_pb.TimeSaleResponse
};

MarketApi.findNetFlow = {
  methodName: "findNetFlow",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.NetFlowInput,
  responseType: auto_trading_pb.MarketNetFlowResponse
};

MarketApi.findMarketShare = {
  methodName: "findMarketShare",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.MarketShareInput,
  responseType: auto_trading_pb.MarketShareResponse
};

MarketApi.findTopForeignTrade = {
  methodName: "findTopForeignTrade",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.InternalMSInput,
  responseType: auto_trading_pb.InternalMSResponse
};

MarketApi.findTopSecUpDown = {
  methodName: "findTopSecUpDown",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.TopSecUpDownInput,
  responseType: auto_trading_pb.TopSecUpDownResponse
};

MarketApi.findTopSecChanged = {
  methodName: "findTopSecChanged",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.TopSecChangedInput,
  responseType: auto_trading_pb.TopSecChangedResponse
};

MarketApi.findSecDividend = {
  methodName: "findSecDividend",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.SecDividendInput,
  responseType: auto_trading_pb.SecDividendResponse
};

MarketApi.findMarketIndex = {
  methodName: "findMarketIndex",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.MarketIndexInput,
  responseType: auto_trading_pb.MarketIndexInfo
};

MarketApi.findSecQuotesDetail = {
  methodName: "findSecQuotesDetail",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.SecQuotesDetailInput,
  responseType: auto_trading_pb.SecQuotesDetailResponse
};

MarketApi.findMarketQuotes = {
  methodName: "findMarketQuotes",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.MarketQuotesInput,
  responseType: auto_trading_pb.MarketQuotesResponse
};

MarketApi.findSecIntraday = {
  methodName: "findSecIntraday",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.SecIntradayInput,
  responseType: auto_trading_pb.SecIntradayResponse
};

MarketApi.findIndicatorScreener = {
  methodName: "findIndicatorScreener",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.ScreenerInput,
  responseType: auto_trading_pb.ScreenerResponse
};

MarketApi.getMarketInitData = {
  methodName: "getMarketInitData",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.MarketInitDataParam,
  responseType: auto_trading_pb.MarketInitDataResponse
};

MarketApi.findTopMarketCap = {
  methodName: "findTopMarketCap",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.TopMarketCapInput,
  responseType: auto_trading_pb.TopMarketCapResponse
};

MarketApi.getAllMrktSecInfo = {
  methodName: "getAllMrktSecInfo",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.MrktSecInfoParam,
  responseType: auto_trading_pb.MrktSecInfoResponse
};

MarketApi.getPutThrough = {
  methodName: "getPutThrough",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.PutThroughParam,
  responseType: auto_trading_pb.PutThroughResponse
};

MarketApi.getTopIndexContribution = {
  methodName: "getTopIndexContribution",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.TopIndexContributionParam,
  responseType: auto_trading_pb.TopIndexContributionResponse
};

MarketApi.getMarketBreadth = {
  methodName: "getMarketBreadth",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.MarketBreadthParam,
  responseType: auto_trading_pb.MarketBreadthResponse
};

MarketApi.getMarketLiquidity = {
  methodName: "getMarketLiquidity",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.MarketLiquidityParam,
  responseType: auto_trading_pb.MarketLiquidityResponse
};

MarketApi.getTopIndustriesTradeForeign = {
  methodName: "getTopIndustriesTradeForeign",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.TopIndustriesTradeForeignParam,
  responseType: auto_trading_pb.TopIndustriesTradeForeignResponse
};

MarketApi.getTopIndustriesContribution = {
  methodName: "getTopIndustriesContribution",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.TopIndustriesContributionParam,
  responseType: auto_trading_pb.TopIndustriesContributionResponse
};

MarketApi.getTopIndustriesTrade = {
  methodName: "getTopIndustriesTrade",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.TopIndustriesTradeParam,
  responseType: auto_trading_pb.TopIndustriesTradeResponse
};

MarketApi.getIndustriesHeatMap = {
  methodName: "getIndustriesHeatMap",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.IndustriesHeatMapParam,
  responseType: auto_trading_pb.IndustriesHeatMapResponse
};

MarketApi.getSecQuotesSameIndustry = {
  methodName: "getSecQuotesSameIndustry",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.SecQuotesSameIndustryParam,
  responseType: auto_trading_pb.SecQuotesSameIndustryResponse
};

MarketApi.findWorldIndex = {
  methodName: "findWorldIndex",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.FindWorldIndexInput,
  responseType: auto_trading_pb.FindWorldIndexResponse
};

MarketApi.findWorldIndexDetail = {
  methodName: "findWorldIndexDetail",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.FindWorldIndexDetailInput,
  responseType: auto_trading_pb.FindWorldIndexDetailResponse
};

MarketApi.findWatchListOdd = {
  methodName: "findWatchListOdd",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.WatchListInput,
  responseType: auto_trading_pb.SymbolTotalInfo
};

MarketApi.findSecQuotesOddDetail = {
  methodName: "findSecQuotesOddDetail",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.SecQuotesDetailInput,
  responseType: auto_trading_pb.SecQuotesDetailResponse
};

MarketApi.findSecIntradayOdd = {
  methodName: "findSecIntradayOdd",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.SecIntradayInput,
  responseType: auto_trading_pb.SecIntradayResponse
};

MarketApi.findCurrency = {
  methodName: "findCurrency",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.FindCurrencyInput,
  responseType: auto_trading_pb.FindCurrencyResponse
};

MarketApi.findTechnicals = {
  methodName: "findTechnicals",
  service: MarketApi,
  requestStream: false,
  responseStream: true,
  requestType: auto_trading_pb.FindTechnicalsInput,
  responseType: auto_trading_pb.FindTechnicalsResponse
};

MarketApi.getSectFinance = {
  methodName: "getSectFinance",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.SectFinanceParam,
  responseType: auto_trading_pb.SectFinanceResponse
};

MarketApi.getSectProfile = {
  methodName: "getSectProfile",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.SectProfileParam,
  responseType: auto_trading_pb.SectProfileResponse
};

MarketApi.getSectOwnership = {
  methodName: "getSectOwnership",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.SectOwnershipParam,
  responseType: auto_trading_pb.SectOwnershipResponse
};

MarketApi.getSectNews = {
  methodName: "getSectNews",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.SectNewsParam,
  responseType: auto_trading_pb.SectNewsResponse
};

MarketApi.getSectNewsDetail = {
  methodName: "getSectNewsDetail",
  service: MarketApi,
  requestStream: false,
  responseStream: false,
  requestType: auto_trading_pb.SectNewsDetailParam,
  responseType: auto_trading_pb.SectNewsDetailResponse
};

exports.MarketApi = MarketApi;

function MarketApiClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MarketApiClient.prototype.findWatchList = function findWatchList(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findWatchList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findMarketDepth = function findMarketDepth(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findMarketDepth, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findMarketMatching = function findMarketMatching(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findMarketMatching, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findTimeSale = function findTimeSale(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findTimeSale, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findNetFlow = function findNetFlow(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findNetFlow, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findMarketShare = function findMarketShare(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findMarketShare, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findTopForeignTrade = function findTopForeignTrade(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findTopForeignTrade, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findTopSecUpDown = function findTopSecUpDown(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findTopSecUpDown, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findTopSecChanged = function findTopSecChanged(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findTopSecChanged, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findSecDividend = function findSecDividend(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findSecDividend, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findMarketIndex = function findMarketIndex(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findMarketIndex, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findSecQuotesDetail = function findSecQuotesDetail(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findSecQuotesDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findMarketQuotes = function findMarketQuotes(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findMarketQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findSecIntraday = function findSecIntraday(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findSecIntraday, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findIndicatorScreener = function findIndicatorScreener(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findIndicatorScreener, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getMarketInitData = function getMarketInitData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getMarketInitData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findTopMarketCap = function findTopMarketCap(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findTopMarketCap, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getAllMrktSecInfo = function getAllMrktSecInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getAllMrktSecInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getPutThrough = function getPutThrough(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getPutThrough, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getTopIndexContribution = function getTopIndexContribution(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getTopIndexContribution, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getMarketBreadth = function getMarketBreadth(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getMarketBreadth, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getMarketLiquidity = function getMarketLiquidity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getMarketLiquidity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getTopIndustriesTradeForeign = function getTopIndustriesTradeForeign(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getTopIndustriesTradeForeign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getTopIndustriesContribution = function getTopIndustriesContribution(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getTopIndustriesContribution, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getTopIndustriesTrade = function getTopIndustriesTrade(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getTopIndustriesTrade, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getIndustriesHeatMap = function getIndustriesHeatMap(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getIndustriesHeatMap, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getSecQuotesSameIndustry = function getSecQuotesSameIndustry(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.getSecQuotesSameIndustry, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findWorldIndex = function findWorldIndex(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.findWorldIndex, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findWorldIndexDetail = function findWorldIndexDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.findWorldIndexDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findWatchListOdd = function findWatchListOdd(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findWatchListOdd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findSecQuotesOddDetail = function findSecQuotesOddDetail(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findSecQuotesOddDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findSecIntradayOdd = function findSecIntradayOdd(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findSecIntradayOdd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findCurrency = function findCurrency(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.findCurrency, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.findTechnicals = function findTechnicals(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketApi.findTechnicals, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getSectFinance = function getSectFinance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getSectFinance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getSectProfile = function getSectProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getSectProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getSectOwnership = function getSectOwnership(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getSectOwnership, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getSectNews = function getSectNews(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getSectNews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketApiClient.prototype.getSectNewsDetail = function getSectNewsDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketApi.getSectNewsDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MarketApiClient = MarketApiClient;

