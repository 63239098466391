import { useEffect, useMemo } from "react";
import { ChartData } from "modules/tickerDetails/redux/orderFlowDistribution";
import {
  ChartSection,
  HeaderSection,
  ChartSectionWrapper,
  Icon,
  PopoverContainer,
  PopoverText,
  PopoverTitle,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import FlowChart from "./FlowChart";
import FlowPieChart from "./FlowPieChart";
import { ReactComponent as WarningIcon } from "assets/image/svg/ic_warning.svg";
import { OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { languageSelector } from "languages/selectors";

export type OrderFlowDistributionProps = {
  isHideHeader?: boolean;
  chartInflow: ChartData;
  chartOutflow: ChartData;
  pieChartFlow: ChartData;
  totalInflow: number | undefined;
  totalOutflow: number | undefined;
  selectedTicker?: string | null;
  onFetch: (selectedTicker: string) => void;
};

function OrderFlowDistributionWidget({
  isHideHeader = false,
  chartInflow,
  chartOutflow,
  pieChartFlow,
  totalInflow,
  totalOutflow,
  selectedTicker,
  onFetch,
}: OrderFlowDistributionProps) {
  const intl = useIntl();
  const language = useSelector(languageSelector);

  useEffect(() => {
    if (selectedTicker != null) {
      onFetch(selectedTicker);
    }
  }, [selectedTicker]);

  const notePopover = useMemo(() => {
    return (
      <PopoverContainer>
        <PopoverTitle>
          <FormattedMessage id="widgets.orderFlowDistribution.noteTitle" />
        </PopoverTitle>
        <PopoverText>
          <FormattedMessage id="widgets.orderFlowDistribution.noteText1" />
        </PopoverText>
        <PopoverText>
          <FormattedMessage id="widgets.orderFlowDistribution.noteText2" />
        </PopoverText>
        <PopoverText>
          <FormattedMessage id="widgets.orderFlowDistribution.noteText3" />
        </PopoverText>
      </PopoverContainer>
    );
  }, [language]);

  return (
    <>
      {!isHideHeader && (
        <HeaderSection>
          <div className="headerText">
            <FormattedMessage id="widgets.orderFlowDistribution.title" />
          </div>
          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            overlay={notePopover}
            rootClose
          >
            <Icon>
              <WarningIcon />
            </Icon>
          </OverlayTrigger>
        </HeaderSection>
      )}
      <ChartSectionWrapper
        className="chart-section"
        size={isHideHeader ? "large" : undefined}
      >
        <ChartSection>
          <div>
            <FlowPieChart
              values={pieChartFlow.values}
              colors={pieChartFlow.colors}
              labels={pieChartFlow.labels.map((item) =>
                intl.formatMessage({ id: item })
              )}
            />
          </div>
          <div className="barChartSection">
            <div className="outflow">
              <FlowChart
                values={chartOutflow.values}
                colors={chartOutflow.colors}
                labels={chartOutflow.labels.map((item) =>
                  intl.formatMessage({ id: item })
                )}
              />
              <div className="total">
                <FormattedMessage id="widgets.orderFlowDistribution.totalSell" />
                :<span className="total-outflow">{totalOutflow}</span>
              </div>
            </div>

            <div className="inflow">
              <FlowChart
                values={chartInflow.values}
                colors={chartInflow.colors}
                labels={chartInflow.labels.map((item) =>
                  intl.formatMessage({ id: item })
                )}
              />
              <div className="total">
                <FormattedMessage id="widgets.orderFlowDistribution.totalBuy" />
                :<span className="total-inflow">{totalInflow}</span>
              </div>
            </div>
          </div>
        </ChartSection>
      </ChartSectionWrapper>
    </>
  );
}

export default OrderFlowDistributionWidget;
