import { useState, useEffect, useRef } from "react";
import numeral from "numeral";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { BlinkTime } from "AppConfig";

function BlinkableCell({ value }: any) {
  const oldData = useRef(0);
  const [blinkClass, setBlinkClass] = useState<string | undefined>(undefined);

  useEffect(() => {
    const cellValue = numeral(value).value() || 0;
    const prevCellValueAsNumber = numeral(oldData.current).value() || 0;
    if (cellValue === prevCellValueAsNumber) return;

    if (!prevCellValueAsNumber) return;

    if (cellValue !== prevCellValueAsNumber) {
      setBlinkClass("cell-blink");
      setTimeout(() => {
        setBlinkClass(undefined);
      }, BlinkTime);
    }
    return () => {
      oldData.current = value;
    };
  }, [value]);

  return (
    <TableCellContent
      className={blinkClass}
      color={blinkClass && "textColorBlack"}
      align={"flex-end"}
    >
      {value}
    </TableCellContent>
  );
}

export default BlinkableCell;
