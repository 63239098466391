import { Wrap } from "components/commons/FormStyle/Table/styles";
import { CONTRACT_DEBIT_TABS } from "modules/contract-debit/constants";
import { reset } from "modules/contract-debit/redux";
import { activeTabSelector } from "modules/contract-debit/selectors";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";
import RequestFilterPaymentHistory from "./RequestFilterPaymentHistory/index";
import RequestTablePaymentHistory from "./RequestTablePaymentHistory";
import RequestFilterExtendHistory from "./RequestFilterExtendHistory/index";
import RequestTableExtendHistory from "./RequestTableExtendHistory";
const ContractDebitContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Wrap>
      {activeTab === CONTRACT_DEBIT_TABS.LOAN_TRACKING && (
        <>
          <RequestFilter />
          <RequestTable />
        </>
      )}
      {activeTab === CONTRACT_DEBIT_TABS.PAYMENT_HISTORY && (
        <>
          <RequestFilterPaymentHistory />
          <RequestTablePaymentHistory />
        </>
      )}
      {activeTab === CONTRACT_DEBIT_TABS.EXTEND_HISTORY && (
        <>
          <RequestFilterExtendHistory />
          <RequestTableExtendHistory />
        </>
      )}
    </Wrap>
  );
};
export default ContractDebitContent;
