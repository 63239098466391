import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import DepositContract from "./pages/DepositContract";

const routes: RouterConfig[] = [
  {
    page: DepositContract,
    path: paths.depositContract,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
