import {
  isRegisterPayAdvanceAutoApi,
  registerPayAdvanceAutoApi,
  cancelPayAdvanceAutoApi,
} from "./urls";

import {
  IIsRegisterPayAdvanceParams,
  IRegisterPayAdvanceAutoParams,
  ICancelPayAdvanceAutoParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";
import {
  IIsRegisterPayAdvanceResponse,
  IRegisterPayAdvanceAutoResponse,
  ICancelPayAdvanceAutoResponse,
} from "domain/models/AdvanceAuto";

export const isRegisterPayAdvanceAuto = async (
  params: IIsRegisterPayAdvanceParams
): Promise<IIsRegisterPayAdvanceResponse> => {
  const res = await VgaiaHttp.post<IIsRegisterPayAdvanceResponse>(
    isRegisterPayAdvanceAutoApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const registerPayAdvanceAuto = async (
  params: IRegisterPayAdvanceAutoParams
): Promise<IRegisterPayAdvanceAutoResponse> => {
  const res = await VgaiaHttp.post<IRegisterPayAdvanceAutoResponse>(
    registerPayAdvanceAutoApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cancelPayAdvanceAuto = async (
  params: ICancelPayAdvanceAutoParams
): Promise<ICancelPayAdvanceAutoResponse> => {
  const res = await VgaiaHttp.post<ICancelPayAdvanceAutoResponse>(
    cancelPayAdvanceAutoApi(),
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
// export const cancelPayAdvanceAuto = async (
//   params: ICancelPayAdvanceAutoParams
// ): Promise<ICancelPayAdvanceAutoResponse> => {
//   const res = await VgaiaHttp.post<ICancelPayAdvanceAutoResponse>(
//     cancelPayAdvanceAutoApi,
//     JSON.stringify(params),
//     { headers: { "Content-Type": "application/json; charset=utf-8" } }
//   );

//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };
