import styled from "styled-components/macro";

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .detailTicker {
    display: flex;
    padding: 10px 10px 0 10px;
  }
`;

export const ChartBox = styled.div`
  position: relative;
  height: 100%;
`;
