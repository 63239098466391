import { ACCOUNT_STATEMENT } from "modules/account-statement/constants";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Tab, Wrap } from "./styles";

type Props = {
  onClick?: (values: any) => void;
};

const Tabs = (props: Props) => {
  const [active, setActive] = useState(ACCOUNT_STATEMENT.MONEY_STATEMENT);
  const { onClick } = props;

  const intl = useIntl();

  const handleChangeTab1 = () => {
    if (onClick) {
      onClick(ACCOUNT_STATEMENT.MONEY_STATEMENT);
    }
    setActive(ACCOUNT_STATEMENT.MONEY_STATEMENT);
  };
  const handleChangeTab = () => {
    if (onClick) {
      onClick(ACCOUNT_STATEMENT.SECURITIES_STATEMENT);
    }
    setActive(ACCOUNT_STATEMENT.SECURITIES_STATEMENT);
  };
  return (
    <Wrap>
      <div className="header-left">
        <Tab
          isActive={active === ACCOUNT_STATEMENT.MONEY_STATEMENT}
          onClick={handleChangeTab1}
        >
          {intl.formatMessage({
            id: "accountStatement.titleMoneyStatement",
          })}
        </Tab>
        <Tab
          isActive={active === ACCOUNT_STATEMENT.SECURITIES_STATEMENT}
          onClick={handleChangeTab}
        >
          {intl.formatMessage({
            id: "accountStatement.titleStockStatement",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
