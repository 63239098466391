import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { tickerInfoWidgetSelector } from "modules/tickerDetails/redux/selectors";
import { SecQuotesDetailResponse } from "domain/protoNew/auto_trading_pb";
import { FormattedMessage } from "react-intl";
import VolumeChange from "components/widgets/details/TickerInfo/VolumeChange";

const openpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.openprice
);
const avgpriceSelector = createSelector(
  tickerInfoWidgetSelector,
  (state?: SecQuotesDetailResponse.SecDetailInfo.AsObject) => state?.avgprice
);

const AvgPriceCell = () => {
  const openprice = useSelector(openpriceSelector);
  const avgprice = useSelector(avgpriceSelector);
  return (
    <div>
      <div className="info-text">
        <FormattedMessage id="widgets.tickerInfo.openAveragePrice" />
      </div>
      <div className="info-value">
        <VolumeChange
          value={`${numeral(openprice).format("0.00")} /${numeral(
            avgprice
          ).format("0.00")}`}
        />
      </div>
    </div>
  );
};

export default AvgPriceCell;
