import React, { FC, memo, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Input } from "components/commons";
import { InputContainer, TickerContainer } from "./style";
import { State as CategoriesRootState } from "modules/categories/redux";
import { createSelector } from "@reduxjs/toolkit";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { useRoveFocus } from "helper/cusHooks";
import SelectItem from "./SelectItem";
import orderBy from "lodash/orderBy";
import { EMarketCodeNew } from "helper/consts";
interface InputSymbolProps {
  value?: string;
  onChange?: (e: string) => void;
  isOnlyShareCode?: boolean;
  isDerivatives?: boolean;
}

const getEquityTicker = (
  tickers: MrktSecInfoItem.AsObject[],
  isDerivatives?: boolean
) => {
  return !isDerivatives
    ? tickers
    : tickers.filter((item) => item.marketcd === EMarketCodeNew.DER_IDX);
};

const categoriesSelector = (state: RootState) => state.categories.root;

const tickersSelector = createSelector(
  categoriesSelector,
  (categoriesSelector: CategoriesRootState) => categoriesSelector.tickersNew
);

const InputSymbol: FC<InputSymbolProps> = memo(
  ({ value, onChange, isOnlyShareCode, isDerivatives }) => {
    const intl = useIntl();
    const refInput = useRef<HTMLInputElement>(null);
    const [textSearch, setTextSearch] = useState("");
    const [numberLoadmore, setNumberLoadMore] = useState(6);
    const tickers = useSelector(tickersSelector);
    const tickersEquity = getEquityTicker(tickers, isDerivatives);
    const [isChangeArrow, setIsChangeArrow] = useState(false);
    const [isClickOutSide, setIsClickOutSide] = useState(false);
    const [visibleTextSearch, setVisibleTextSearch] = useState(false);

    const [focus, setFocus] = useRoveFocus(
      tickersEquity.length,
      setIsChangeArrow,
      isChangeArrow
    );

    const onSearchTicker = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextSearch(e.target.value.toUpperCase());
      if (e.target.value === "") {
        onChange?.("");
      }
    };
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.stopPropagation();

        if (textSearch !== "") {
          const item = tickerSort?.filter((ticker) =>
            ticker.seccd.includes(textSearch.toUpperCase())
          )[0];

          if (item) {
            onChange?.(item.seccd);
            refInput.current?.blur();
            setVisibleTextSearch(false);
          } else {
            onChange?.(textSearch);
          }
        } else {
          onChange?.(textSearch);
          refInput.current?.blur();
        }
      } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        setIsChangeArrow(true);
        setIsClickOutSide(false);
      }
    };

    const tickerFilter = tickersEquity.filter((value) =>
      value.seccd.includes(textSearch.toUpperCase())
    );

    const tickerSort: MrktSecInfoItem.AsObject[] = orderBy(tickerFilter, [
      "sectype",
      "seccd",
    ]);

    useEffect(() => {
      setTextSearch(value?.toLocaleUpperCase() || "");
    }, [value]);

    const handleScroll = () => {
      setNumberLoadMore(numberLoadmore + 6);
    };

    useEffect(() => {
      if (isClickOutSide) {
        setVisibleTextSearch(false);
      }
    }, [isClickOutSide]);

    const handleChangeTicker = () => {
      setTimeout(() => setVisibleTextSearch(false), 150);

      if (textSearch !== "") {
        const item = tickerSort?.filter((ticker) =>
          ticker.seccd.includes(textSearch.toUpperCase())
        )[0];

        if (item) {
          onChange?.(item.seccd);
          refInput.current?.blur();
          setVisibleTextSearch(false);
        } else {
          onChange?.(textSearch);
        }
      } else {
        onChange?.(textSearch);
        refInput.current?.blur();
      }
    };

    return (
      <InputContainer>
        <Input
          innerRef={refInput}
          placeholder={intl.formatMessage({ id: "common.inputSymbol" })}
          value={textSearch}
          onChange={onSearchTicker}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            setVisibleTextSearch(true);
          }}
          onBlur={handleChangeTicker}
        />

        {visibleTextSearch && (
          <TickerContainer onScroll={handleScroll}>
            {tickerSort.slice(0, numberLoadmore)?.map((item, index) => (
              <SelectItem
                key={index}
                setFocus={setFocus}
                index={index}
                focus={focus === index}
                data={item}
                setVisibleTextSearch={setVisibleTextSearch}
                setTextSearch={setTextSearch}
                setIsChangeArrow={setIsChangeArrow}
                setIsClickOutSide={setIsClickOutSide}
                isOnlyShareCode={isOnlyShareCode}
                onChange={onChange}
              />
            ))}
          </TickerContainer>
        )}
      </InputContainer>
    );
  }
);

export default InputSymbol;
