import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  findCbotProductRetailRequest,
  getListOrderBookRequest,
} from "../redux";
import InformationOrder from "./InfomationOrder";
import ListBonds from "./ListBonds";
import OrderBook from "./OrderBook";
import {
  Container,
  ContainerLeft,
  ContainerRight,
  WrapperBottom,
  WrapperTop,
} from "./styles";

function PlaceOrderRetailBonds() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findCbotProductRetailRequest({ bondCd: "" }));

    dispatch(
      getListOrderBookRequest({
        subAccoNo: "",
        bondCd: "",
        status: -1,
        fromDate: 0,
        toDate: 0,
      })
    );
  }, []);
  return (
    <Container>
      <ContainerLeft>
        <InformationOrder />
      </ContainerLeft>
      <ContainerRight>
        <WrapperTop>
          <ListBonds />
        </WrapperTop>
        <WrapperBottom>
          <OrderBook />
        </WrapperBottom>
      </ContainerRight>
    </Container>
  );
}

export default PlaceOrderRetailBonds;
