import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISearchStatisticUpdownParams } from "core/http/apis/statistic-updown/types";
import {
  IMarketStatistic,
  IMarketStatisticResponse,
} from "domain/models/MarketStatistic";
import { logOutSuccess } from "modules/auth/redux";
import { STATISTIC_UPDOWN_TABS } from "./constants";

interface IFilter {
  type?: string;
  marketCd?: string;
  fromDate?: Date;
  toDate?: Date;
}

export interface ReduxData {
  filter: IFilter;
  list: IMarketStatistic[];
  loading: boolean;
  activeTab: string;
}

const initialState: ReduxData = {
  filter: {
    type: "1",
    marketCd: "0",
    fromDate: new Date(),
    toDate: new Date(),
  },
  list: [],
  loading: false,
  activeTab: STATISTIC_UPDOWN_TABS.STATISTIC_UPDOWN,
};

const statisticUpdownSlice = createSlice({
  name: "statisticUpdownSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchStatisticUpdownRequest: (
      state,
      action: PayloadAction<ISearchStatisticUpdownParams>
    ) => {
      state.loading = true;
    },
    searchStatisticUpdownSuccess: (
      state,
      action: PayloadAction<IMarketStatisticResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchStatisticUpdownFailure: (state) => {
      state.loading = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchStatisticUpdownFailure,
  searchStatisticUpdownRequest,
  searchStatisticUpdownSuccess,
  updateFilter,
} = statisticUpdownSlice.actions;

export default statisticUpdownSlice.reducer;
