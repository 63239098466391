import { IntlShape } from "react-intl";

export const statusTypeTransfer = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status0",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status2",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "transferInternal.historyTransfer.filter.typeMoneyTransfer.status3",
    }),
  },
];

export enum TRANSFER_LOCAL_TABS {
  TRANSFER_LOCAL = "TRANSFER_LOCAL",
  TRANSFER_LOCAL_HIS = "TRANSFER_LOCAL_HIS",
}
