import Row from "./Row/RowDnd";
import { RowStockProps } from "modules/categories/types";
import { DraggableProvided } from "react-beautiful-dnd";

function RowContainer({
  rowNumber,
  ticker,
  style,
  askWidth,
  bidWidth,
  foreignWidth,
  sumWidth,
  currentListCode,
  columns,
  priceWidth,
  lastSaleWidth,
  coverWarrantWidth,
  coverWarrantHeadWidth,
  provided,
  isDragging,
  isFocus,
}: RowStockProps) {
  const { style: draggableStyle, ...draggableProps } = provided.draggableProps;
  const { dragHandleProps } = provided;

  function getStyle({
    provided,
    style,
    isDragging,
  }: {
    provided: DraggableProvided;
    style: any;
    isDragging: boolean;
  }) {
    // If you don't want any spacing between your items
    // then you could just return this.
    // I do a little bit of magic to have some nice visual space
    // between the row items
    const combined = {
      ...style,
      ...provided.draggableProps.style,
    };

    const marginBottom = 8;
    const withSpacing = {
      ...combined,
      height: isDragging ? combined.height : combined.height - marginBottom,
      marginBottom,
    };
    return withSpacing;
  }
  return (
    <div
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging: !!isDragging })}
      {...draggableProps}
      {...dragHandleProps}
    >
      <Row
        isDragging={false}
        provided={provided}
        rowNumber={rowNumber}
        key={ticker}
        style={style}
        ticker={ticker!}
        currentListCode={currentListCode}
        sumWidth={sumWidth}
        askWidth={askWidth}
        bidWidth={bidWidth}
        foreignWidth={foreignWidth}
        priceWidth={priceWidth}
        lastSaleWidth={lastSaleWidth}
        coverWarrantWidth={coverWarrantWidth}
        coverWarrantHeadWidth={coverWarrantHeadWidth}
        columns={columns}
        isFocus={isFocus}
      />
    </div>
  );
}

export default RowContainer;
