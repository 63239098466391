import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const listHistoryTransferShareSelector = (state: RootState) =>
  state.transferShare.listHistory;

export const transferShareFormSelector = (state: RootState) =>
  state.transferShare.transferShareForm;

export const searchListHistoryParamsSelector = createSelector(
  listHistoryTransferShareSelector,
  (state) => state.search
);

export const listDataHistoryTransferShareSelector = createSelector(
  listHistoryTransferShareSelector,
  (state) => state.data
);

export const listDataTransferShareFromSelector = createSelector(
  transferShareFormSelector,
  (state) => state.data
);

export const submitListTransferShareFormParamsSelector = createSelector(
  transferShareFormSelector,
  (state) => state.submitTransferShareParams
);

export const listTransferShareFormSelectRowsSelector = createSelector(
  transferShareFormSelector,
  (state) => state.selectedRows
);

export const isSelectAllRowsSelector = createSelector(
  transferShareFormSelector,
  (state) => state.isCheckAll
);

export const onLoadListSelector = createSelector(
  transferShareFormSelector,
  (state) => state.loadList
);

export const statusSelector = createSelector(
  transferShareFormSelector,
  (state) => state.status
);

export const statusCurrentPage = createSelector(
  listHistoryTransferShareSelector,
  (state) => state.currentPage
);
