import { TopPricePeriod } from "components/widgets/generals/TopPrice/types";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "redux/store";
import { getAllTickers } from "../redux";
import { requestChangePeriod as requestChangePeriodForTopPriceLeft } from "../redux/topPriceLeft";
import { requestChangePeriod as requestChangePeriodForTopPriceRight } from "../redux/topPriceRight";
import topPriceLeftWidgetSaga from "./topPriceLeftSaga";
import topPriceRightWidgetSaga from "./topPriceRightSaga";
import topForeignWidgetSaga from "./topForeignWidgetSaga";
import topStatisticalWidget from "./topStatisticalWidgetSaga";
import adIndexSaga from "./adIndexSaga";
import netflowSaga from "./netflowSaga";
import liquiditySaga from "./liquiditySaga";
import statBasketContributionSaga from "./statBasketContributionSaga";
import transactionValueVolumeSaga from "./transactionValueVolumeSaga";
import capitalizationSaga from "./capitalizationSaga";
import marketStatsSaga from "./marketStatsSaga";
import foreignChartSaga from "./foreignChartSaga";
import topIndexIndustrySaga from "./topIndexIndustrySaga";
import transactionIndustrySaga from "./transactionIndustrySaga";
import logTrackingSaga from "./logTrackingSaga";

function* fetchAllTickersWorker() {
  try {
    const activeTabLeft: string = yield select(
      (state: RootState) => state.market.topPriceLeft.activeTab
    );

    const activeTabRight: string = yield select(
      (state: RootState) => state.market.topPriceLeft.activeTab
    );
    yield put(
      requestChangePeriodForTopPriceLeft(activeTabLeft as TopPricePeriod)
    );
    yield put(
      requestChangePeriodForTopPriceRight(activeTabRight as TopPricePeriod)
    );
  } catch (e) {}
}

function* getAllTickerWatcher() {
  yield takeLatest(getAllTickers.type, fetchAllTickersWorker);
}

export default function* stockOrderSaga() {
  yield all([
    topPriceLeftWidgetSaga(),
    topPriceRightWidgetSaga(),
    topForeignWidgetSaga(),
    getAllTickerWatcher(),
    topStatisticalWidget(),
    adIndexSaga(),
    netflowSaga(),
    liquiditySaga(),
    statBasketContributionSaga(),
    transactionValueVolumeSaga(),
    foreignChartSaga(),
    capitalizationSaga(),
    marketStatsSaga(),
    topIndexIndustrySaga(),
    transactionIndustrySaga(),
    logTrackingSaga(),
  ]);
}
