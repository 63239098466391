import {
  cbotGetInterest,
  findCbotBondInfo,
  findRbonOrder,
  inquiryBuyingPower,
} from "./../../core/http/apis/place-order-retail-bonds/index";
import { toast } from "components/commons/Toast";
import { findCbotProductRetail } from "core/http/apis/place-order-retail-bonds";
import { IPlaceOrderRetailBonds } from "domain/models/PlaceOrderRetailBonds";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  cbotGetInterestFailure,
  cbotGetInterestRequest,
  cbotGetInterestSuccess,
  findCbotBondInfoFailure,
  findCbotBondInfoRequest,
  findCbotBondInfoSuccess,
  findCbotProductRetailFailure,
  findCbotProductRetailRequest,
  findCbotProductRetailSuccess,
  getListOrderBookFailure,
  getListOrderBookRequest,
  getListOrderBookSuccess,
  inquiryBuyingPowerFailure,
  inquiryBuyingPowerRequest,
  inquiryBuyingPowerSuccess,
  searchListOrderBookFailure,
  searchListOrderBookRequest,
  searchListOrderBookSuccess,
} from "./redux";

function* findCbotProductRetailWorker(
  action: ReturnType<typeof findCbotProductRetailRequest>
) {
  try {
    const params: any = action.payload;
    const response: IPlaceOrderRetailBonds = yield call(
      findCbotProductRetail,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotProductRetailSuccess(response));
    } else {
      yield put(findCbotProductRetailFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotProductRetailFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* cbotGetInterestWorker(
  action: ReturnType<typeof cbotGetInterestRequest>
) {
  try {
    const params: any = action.payload;
    const response: IPlaceOrderRetailBonds = yield call(
      cbotGetInterest,
      params
    );

    if (response?.statusCode === 0) {
      yield put(cbotGetInterestSuccess(response));
    } else {
      yield put(cbotGetInterestFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(cbotGetInterestFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* inquiryBuyingPowerWorker(
  action: ReturnType<typeof inquiryBuyingPowerRequest>
) {
  try {
    const params: any = action.payload;
    const response: IPlaceOrderRetailBonds = yield call(
      inquiryBuyingPower,
      params
    );

    if (response?.statusCode === 0) {
      yield put(inquiryBuyingPowerSuccess(response));
    } else {
      yield put(inquiryBuyingPowerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(inquiryBuyingPowerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findCbotBondInfoWorker(
  action: ReturnType<typeof findCbotBondInfoRequest>
) {
  try {
    const params: any = action.payload;
    const response: IPlaceOrderRetailBonds = yield call(
      findCbotBondInfo,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findCbotBondInfoSuccess(response));
    } else {
      yield put(findCbotBondInfoFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findCbotBondInfoFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getListOrderBookfoWorker(
  action: ReturnType<typeof getListOrderBookRequest>
) {
  try {
    const params: any = action.payload;
    const response: IPlaceOrderRetailBonds = yield call(findRbonOrder, params);

    if (response?.statusCode === 0) {
      yield put(getListOrderBookSuccess(response));
    } else {
      yield put(getListOrderBookFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListOrderBookFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchListOrderBookfoWorker(
  action: ReturnType<typeof searchListOrderBookRequest>
) {
  try {
    const params: any = action.payload;
    const response: IPlaceOrderRetailBonds = yield call(findRbonOrder, params);

    if (response?.statusCode === 0) {
      yield put(searchListOrderBookSuccess(response));
    } else {
      yield put(searchListOrderBookFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchListOrderBookFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findCbotProductRetailWatcher() {
  yield takeLatest(
    findCbotProductRetailRequest.type,
    findCbotProductRetailWorker
  );
}
function* cbotGetInterestWatcher() {
  yield takeLatest(cbotGetInterestRequest.type, cbotGetInterestWorker);
}
function* inquiryBuyingPowerWatcher() {
  yield takeLatest(inquiryBuyingPowerRequest.type, inquiryBuyingPowerWorker);
}
function* findCbotBondInfoWatcher() {
  yield takeLatest(findCbotBondInfoRequest.type, findCbotBondInfoWorker);
}
function* getListOrderBookWatcher() {
  yield takeLatest(getListOrderBookRequest.type, getListOrderBookfoWorker);
}
function* searchListOrderBookWatcher() {
  yield takeLatest(
    searchListOrderBookRequest.type,
    searchListOrderBookfoWorker
  );
}

export default function* placeOrderRetailBondSaga() {
  yield all([
    findCbotProductRetailWatcher(),
    cbotGetInterestWatcher(),
    inquiryBuyingPowerWatcher(),
    findCbotBondInfoWatcher(),
    getListOrderBookWatcher(),
    searchListOrderBookWatcher(),
  ]);
}
