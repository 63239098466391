import {
  Container,
  Body,
  TitleContent,
  Content,
  ContentItem,
  Label,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "components/commons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statusRegisterSelector,
  isShowOtpModalSelector,
  getAdvanceAutoSelector,
} from "modules/auth/pages/ModalSetting/MenuItem/AdvanceAuto/selectors";
import {
  isRegisterPayAdvanceAutoRequest,
  registerPayAdvanceAutoRequest,
  cancelPayAdvanceAutoRequest,
  updateModalStatusRequest,
  updateModalOtpRequest,
} from "modules/auth/pages/ModalSetting/MenuItem/AdvanceAuto/redux";
import ConfirmModal from "./ConfirmModal";
import {
  IIsRegisterPayAdvanceParams,
  IRegisterPayAdvanceAutoParams,
  ICancelPayAdvanceAutoParams,
} from "core/http/apis/advance-auto/types";
import { OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { toast } from "components/commons/Toast";
import storage from "helper/storage";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { EBusinessCd } from "helper/consts";
function AdvanceAuto() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const statusRegister = useSelector(statusRegisterSelector);
  const isVisible = useSelector(isShowOtpModalSelector);
  const advanceAutoStatus = useSelector(getAdvanceAutoSelector);
  const themeType = useSelector(themeTypeSelector);
  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  useEffect(() => {
    if (statusRegister) {
      //   dispatch(updateItemEdit({ advanceFee: 0, realRecvAmt: 0, remarks: "" }));
      const submitData: IIsRegisterPayAdvanceParams = {
        custNo: storage.getMasterAccount() ?? "",
      };
      dispatch(isRegisterPayAdvanceAutoRequest(submitData));
      if (advanceAutoStatus === 0) {
        toast("auth.setting.advanceAuto.modal.status.registerDone", "success");
      } else {
        toast("auth.setting.advanceAuto.modal.status.deleteDone", "success");
      }
    }
  }, [statusRegister]);
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    if (advanceAutoStatus === 0) {
      const submitData: IRegisterPayAdvanceAutoParams = {
        custNo: storage.getMasterAccount() ?? "",
        otpCode: response?.otp ?? "",
        otpType: response?.otpTypeSubmit!,
      };
      dispatch(registerPayAdvanceAutoRequest(submitData));
    } else {
      const submitData: ICancelPayAdvanceAutoParams = {
        custNo: storage.getMasterAccount() ?? "",
        otpCode: response?.otp ?? "",
        otpType: response?.otpTypeSubmit!,
      };
      dispatch(cancelPayAdvanceAutoRequest(submitData));
    }
  };

  const clickAcceptHandler = () => {
    dispatch(
      updateModalStatusRequest({
        isVisible: true,
      })
    );
  };
  useEffect(() => {
    // dispatch(updateItemEdit({ subAccoNo: accountOptions[0]?.value }));

    const submitData: IIsRegisterPayAdvanceParams = {
      custNo: storage.getMasterAccount() ?? "",
    };
    dispatch(isRegisterPayAdvanceAutoRequest(submitData));
  }, []);
  function getColor(value: string, value2: number) {
    if (value2 === 0) {
      return (
        <Label isTextRight color={themes[themeType].red50}>
          {value}
        </Label>
      );
    } else if (value2 === 1) {
      return (
        <Label isTextRight color={themes[themeType].green50}>
          {value}
        </Label>
      );
    }

    return value;
  }
  const getStatusDisPlay = (status: number) => {
    switch (status) {
      case 0:
        return "unregistered";
      case 1:
        return "registered";

      default:
        return "noValue";
    }
  };
  return (
    <>
      <Container>
        <Body>
          <TitleContent>
            <FormattedMessage id="auth.setting.advanceAuto.title" />
          </TitleContent>
          <Content>
            <ContentItem>
              <Label>
                <FormattedMessage id="auth.setting.advanceAuto.status" />
              </Label>

              {getColor(
                intl.formatMessage({
                  id:
                    "auth.setting.advanceAuto.statusList." +
                    getStatusDisPlay(advanceAutoStatus),
                }),
                advanceAutoStatus
              )}
            </ContentItem>
            <ContentItem className="justify-content-center">
              <Label></Label>
              {advanceAutoStatus === 0 ? (
                <Button
                  className="button-filter m-1"
                  onClick={clickAcceptHandler}
                  fill={true}
                  style={{
                    background: `${themes[themeType].buttonGradientColor}`,
                    width: "8rem",
                  }}
                >
                  {intl.formatMessage({
                    id: "auth.setting.advanceAuto.register",
                  })}
                </Button>
              ) : advanceAutoStatus === 1 ? (
                <Button
                  className="button-filter m-1 "
                  onClick={clickAcceptHandler}
                  fill={true}
                  style={{
                    border: `1px solid ${themes[themeType].red50}`,
                    color: `${themes[themeType].red50}`,
                    background: `${themes[themeType].background2}`,
                    width: "8rem",
                  }}
                >
                  {intl.formatMessage({
                    id: "auth.setting.advanceAuto.cancelRegistration",
                  })}
                </Button>
              ) : (
                <></>
              )}
            </ContentItem>
          </Content>
        </Body>
        {isVisible && (
          <OTPByBusinessCd
            handleClose={handleCloseOTPModal}
            getResult={doSubmitAdvance}
            businessCd={EBusinessCd.ADVANCE}
          />
        )}

        <ConfirmModal />
      </Container>
    </>
  );
}

export default AdvanceAuto;
