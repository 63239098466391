/* eslint-disable no-template-curly-in-string */
import AppLanguage from "./types";
import errorsEn from "./errorEn";
import {
  ContactLinkEN,
  RiskLinkEN,
  TutorialLinkEN,
  OnlineServiceLinkEN,
} from "AppConfig";

const en: AppLanguage = {
  common: {
    titleAlert: "NOTIFICATION",
    buttonClose: "CLOSE",
    login: "Login",
    errorlogin: "Invalid login information. Please try again.",
    username: "Username",
    password: "Password",
    enterPassword: "Enter password",
    signin: "Signin",
    darkMode: "Dark mode",
    lightMode: "Light mode",
    EN: "EN",
    VI: "VI",
    search: "Search",
    buy: "Buy",
    sell: "Sell",
    buyPlus: "BUY +",
    sellPlus: "SELL +",
    buyUp: "BUY",
    sellUp: "SELL",
    tickerTracking: "Search symbol",
    searchHeader: "Enter, company name",
    noValue: "No corresponding code",
    cancel: "Cancel",
    accept: "Accept",
    short: "Short",
    long: "Long",
    shortUp: "SHORT",
    longUp: "LONG",
    closeUp: "CLOSE",
    shortPlus: "SHORT +",
    longPlus: "LONG +",
    editUp: "EDIT",
    save: "Save",
    ok: "OK",
    OUTTIME: "Outtime",
    BOD: "BOD",
    AVAILABLE: "AVAILABLE",
    OPEN: "OPEN",
    RUNOFF: "Put-through",
    PAUSE: "PAUSE",
    CLOSED: "CLOSED",
    option: "Option",
    choose: "Choose",
    expiredToken: "Session has expired, please login again.",
    errorDelete: "Cannot cancel order",
    errorCancelCopier: "Cannot cancel order account copier",
    hiddenTab: "Hidden tab",
    close: "Close",
    maximizeTab: "Zoom in tab",
    restoreTab: "Zoom out tab",
    CP: "CP",
    unit: "B",
    million: "M",
    loading: "Loading...",
    inputSymbol: "Input symbol",
    warningRangeDay:
      "Exceed {day} days. You choose From date To date corresponding to {day} days in any time period.",
    warningInvalidDay: "From date must not exceed to date",
    nullInputFromDate: "Quý khách chưa nhập Từ ngày",
    nullInputToDate: "Quý khách chưa nhập Đến ngày",
    existTabError: "Function opened",
    warningLiquidAppendix: "Vui lòng chọn phụ lục",
    warningCreateAppendix: "Quý khách vui lòng đăng ký HĐ dịch vụ",
    confirm: "XÁC NHẬN",
    cancelContent: "Bạn có chắc muốn hủy yêu cầu số {number}",
    titleExtra: "Thông tin đặt lệnh",
    placeOrderExtra: "Đặt lệnh cơ sở",
    placeOrderDerExtra: "Đặt lệnh phái sinh",
  },
  system: {
    modalSupport: {
      title: "Support",
      content:
        "VGAIA would like to thank you for using our service. If you need any assistance please contact us here.",
      lableComment: "Your suggestions",
      errorComment: "Your suggestions cannot be left blank",
      errorEmail: "Email/mobile number cannot be left blank.",
      enterComment: "Enter suggestions",
      email: "Email/Mobile",
      enterEmail: "Enter email or mobile number",
      send: "Send",
      upPhoto: "Send photos",
      success: "Send successfully",
      contentSuccess:
        "Information has been sent to VGAIA. Thank you for contacting us.",
      errorValidateEmailOrPhone:
        "Số điện thoại hoặc Email không đúng định dạng",
    },
  },
  auth: {
    errors: {
      emptyPassword: "Password cannot be left blank",
      emptyUsername: "Account cannot be left blank",
    },
    info: {
      title: "Individual Information",
      customerNo: "Customer No",
      accountNumber: "Số tài khoản",
      generalInformation: "General Information",
      identityCardId: "Identity Card Id",
      issueDate: "Issue Date",
      issuePlace: "Issue Place",
      expireDate: "Expire Date",
      openDate: "Open Date",
      birthDate: "Birth Date",
      sex: "Sex",
      communications: "Communications",
      currentAddress: "Current Address",
      phone: "Phone",
      email: "Email",
      contactName: "Contact Name",
      contactTel: "Contact Telephone",
      caringStaff: {
        title: "Caring Staff",
        registerButton: "Register",
        titleModalRegister: "ĐĂNG KÝ NHÂN VIÊN CHĂM SÓC",
        account: "Số tài khoản",
        fullName: "Họ và tên",
        offerStaff: "Đề xuất nhân viên quản lý tài khoản mới",
        broker: "Mã nhân viên",
        inputBroker: "Nhập mã nhân viên",
        fullNameStaff: "Tên nhân viên",
        branch: "Chi nhánh",
        txDesc: "Lí do",
        inputTxDesc: "Nhập lí do",
        terms:
          "Tôi đã đọc và đồng ý với các Điều khoản và điều kiện hợp đồng nêu trên",
        cancel: "Hủy",
        confirm: "Xác nhận",
        errorFindBroker:
          "Không tìm thấy thông tin nhân viên trong danh sách, vui lòng nhập lại mã nhân viên",
        registerBrokerSuccess: "Đăng ký nhân viên chăm sóc thành công",
        registerBrokerFailure: "Đăng ký nhân viên chăm sóc thất bại",
      },
      brokerCode: "Broker Code",
      brokerName: "Broker Name",
      brokerBrand: "Broker Brand",
      brokerPhone: "Broker Phone",
      brokerEmail: "Broker Email",
      curentPass: "Curent password",
      newPass: "New password",
      confirmNewPass: "Confirm new password",
      curentPassTrade: "Curent trade password",
      newPassTrade: "New trade password",
      confirmNewPassTrade: "Confirm new trade password",
      validPass: "Password must be at least 8 characters in numbers",
      entrustInfo: "Entrust info",
      fullName: "Họ và tên",
      effectDate: "Ngày hiệu lực",
      entrustRange: "Phạm vi ủy quyền",
      accountStock: "Tài khoản chứng khoán",
      accountCash: "Tài khoản Cash",
      accountMcredit: "Tài khoản M-Credit",
      accountMargin: "Tài khoản Margin",
      entrustType: {
        all: "UQ toàn bộ",
        order: "Đặt lệnh",
        tranferMoney: "Giao dịch tiền",
        tranferShare: "Giao dịch CK",
        advance: "Ứng trước",
        registerBuy: "Đăng ký mua thêm",
        sellOddLot: "Bán lô lẻ",
        pledge: "Cầm cố",
        tranferDer: "GD phái sinh",
        statementSearch: "Sao kê, tra cứu",
        otherEntrust: "UQ khác",
        margin: "Margin",
      },
      editInfo: {
        title: "Edit Info",
        titleButton: "Edit Communications",
        cancel: "Cancel Change",
        update: "Apply",
        inputAddress: "Input Address",
        inputPhone: "Input Phone",
        inputEmail: "Input Email",
        inputContactName: "Input Contact Name",
        inputContactTel: "Input Contact Phone",
        inputCurentPass: "Enter Curent Password",
        inputNewPass: "Enter new Password",
        inputConfirmNewPass: "Enter again new password",
        errorPhoneNumber: "Invalid phone number. Please try again!",
        errorEmail: "Invalid email. Please try again!",
        curentPassRequired: "Please enter your password",
        newPassRequired: "Please enter your password",
        confirmNewPassRequired: "Please enter your password",
        confirmNewPass: "Passwords don't match.",
        errorAddress:
          "The address is less than 15 characters. Please re-enter address!",
        errorChangeAll:
          "Email and phone number do not edit at the same time. Please try again!",
        changeAccountInfoSuccess:
          "You have successfully edited your information, the system will update the information as soon as possible",
        changeAccountInfoFailed: "Update Failed",
        noChangeInfo: "Contact information unchanged!",
        confirm: "Confirm",
        passwordMinLengthError: "8-character minimum",
      },
      editGeneralInformation: {
        title: "Sửa thông tin chung",
        fullName: "Họ và tên",
        inputFullName: "Nhập họ và tên",
        idCode: "CMND/ĐKKD",
        inputIdCode: "Nhập CMND/ĐKKD",
        idDate: "Ngày cấp",
        idPlace: "Nơi cấp",
        inputIdPlace: "Nhập nơi cấp",
        idExpired: "Ngày hết hạn",
        address: "Địa chỉ",
        inputAddress: "Nhập địa chỉ",
        errorEmptyFullName: "Vui lòng nhập họ và tên",
        errorEmptyIdCode: "Vui lòng nhập CMND/ĐKKD",
        errorEmptyIdDate: "Vui lòng nhập ngày cấp",
        errorEmptyIdPlace: "Vui lòng nhập nơi cấp",
        errorEmptyIdExpired: "Vui lòng nhập ngày hết hạn",
        errorEmptyAddress: "Vui lòng nhập địa chỉ",
      },
      smartOTP: {
        cancel: "Cancel",
        smartOTP: "Enter SmartOTP",
        qrcode: "QR CODE",
        enterOTP: "Enter OTP",
        content: "Please open the Kafi Mobile app and scan the code",
        expired: "QRcode expire",
        buttonGetQR: "Get a new code",
        errorInputOTP: "Didn't enter enough code, please enter all 6 numbers!",
        errorQR: "Scan the code failed, please try again",
        saveOTP: "Lưu mã OTP",
      },
      smsOTP: {
        title: "SMS OTP",
        enterOTP: "Nhập mã OTP",
        resendCode: "Gửi lại mã",
        countdown: "Hiệu lực trong {second}s",
        saveOTP: "Lưu mã OTP",
        cancel: "Hủy",
        submit: "Xác nhận",
      },
      sendOTP: {
        label: "Input code OTP",
        title: "Get OTP",
        cancel: "Cancel",
        confirm: "Confirm",
        sendOTP: "Send OTP",
        sendOTPAgain: "Send OTP Again",
        textConfirm: "I confirm registration to change contact information",
        textTitle: "Send OTP code via:",
        sendOTPAgainToastSuccess: "Resend verification code successfully",
        errorNoRegister: "Bạn chưa đăng ký xác thực cấp 2",
        errorOtpType:
          "Loại xác thực của tòi khoản đang đăng ký hiện không được hỗ trợ trên web, vui lòng kiểm tra lại!",
      },
      pinCdOTP: {
        title: "Mã PIN",
        enterPinCd: "Nhập mã PIN",
      },
    },
    identification: {
      title: "Mã định danh",
      accountNo: "Tiểu khoản",
      mrType: "Loại TK",
      accountType: "Mã định danh",
    },
    setting: {
      title: "Cài đặt",
      menu: {
        general: "Cài đặt chung",
        settingPass: "Cài đặt mật khẩu",
        manageDevice: "QL thiết bị sử dụng Smart OTP",
        tradingAcc: "Tài khoản giao dịch",
        keyShortcut: "Cài đặt phím tắt",
        clearCache: "Xóa cache",
        emailService: "Dịch vụ Email/SMS",
        advanceAuto: "Dịch vụ ứng trước tự động",
      },
      generalSetting: {
        language: "Ngôn ngữ",
        theme: "Giao diện",
      },
      settingPass: {
        loginPass: "Mật khẩu đăng nhập",
        transactionPass: "Mật khẩu giao dịch",
        button: "Đổi mật khẩu",
        modal: {
          title: "Tạo mật khẩu đăng nhập mới",
          titlePassTransaction: "Tạo mật khẩu giao dịch mới",
          passNow: "Mật khẩu hiện tại",
          placeholderPassNow: "Nhập mật khẩu hiện tại",
          passNew: "Mật khẩu mới",
          placeholderPassNew: "Nhập mật khẩu mới",
          againPass: "Nhập lại MK mới",
          placeholderAgainPass: "Nhập lại mật khẩu mới",
          btnCancel: "Hủy",
          btnSuccess: "Cập nhật",
          messageSuccess: "Cập nhật mật khẩu thành công",
          messErrorConfirmPass: "Nhập lại mật khẩu mới!",
          errorMin: "Mật khẩu mới phải có ít nhất {x} ký tự",
          errorAlpha: "Mật khẩu mới phải có ký tự đặc biệt",
          errorChar: "Mật khẩu mới phải có chữ viết hoa",
        },
      },
      advanceAuto: {
        title: "Ứng trước tự động",
        status: "Trạng thái",
        statusList: {
          unregistered: "Chưa đăng ký",
          registered: "Đã đăng ký",
          noValue: "",
        },

        register: "Đăng ký",
        cancelRegistration: "Hủy đăng ký",
        modal: {
          titleRegister: "Đăng ký dịch vụ Ứng trước tự động",
          custNo: "Số tài khoản",
          custName: "Họ tên",
          textConfirmRegister: "Tôi muốn Đăng ký dịch vụ Ứng trước tự động",

          textCheckBox:
            "Tôi đã đọc và đồng ý với {link} sử dụng dịch vụ của VGAIAI",
          textCheckBoxMiddle: "Điều kiện và điều khoản ",

          register: "Đăng ký",
          titleCancelUnRegistration: "Hủy đăng ký dịch vụ Ứng trước tự động",
          textConfirmUnRegister:
            "Tôi muốn hủy đăng ký dịch vụ Ứng trước tự động",
          cancelRegistration: "Hủy đăng ký",
          status: {
            registerDone: "Đăng ký thành công",
            registerFailure: "Đăng ký thất bại",
            deleteDone: "Hủy đăng ký thành công",
            deleteFailure: "Hủy đăng ký thất bại",
          },
        },
      },
    },
    login: {
      questionRegister: "Bạn chưa có tài khoản?",
      linkRegisterL: "Mở tài khoản",
      login: "Đăng nhập",
      callMBS: "Gọi MBS",
      numberMBS: "Hotline MBS: 1900 9088",
      username: "Username",
      enterUsername: "Enter username",
      rememberMe: "Remember me",
      forgetPassword: "Forget password?",
      forgetPasswordSuccess: "Forgot password successfully",
      userIncorect: "userIncorect",
      passIncorect: "passIncorect",
      signIn: "signIn",
      risk: "Risk",
      riskLink: RiskLinkEN,
      Hotline: "Hotline",
      Contact: "Contact",
      ContactLink: ContactLinkEN,
      Tutorial: "Tutorial",
      TutorialLink: TutorialLinkEN,
      forgotPass: "Quên mật khẩu?",
    },
    forgetPassword: {
      logo: "vgaia",
      max6: "Max 6 characters",
      max10: "Số tài khoản tối đa 10 ký tự",
      errorIssueDate: "Ngày cấp không được bỏ trống",
      errorRequired: "1 trong 2 trường email và phone không được bỏ trống",
      maxDate: "Ngày cấp không được vượt quá ngày hiện tại",
      phoneMax10: "Số điện thoại tối đa 10 ký tự",
      enterAccount: "Nhập số tài khoản",
      enterCMND: "Nhập số CMND/Hộ chiếu",
      enterIssueDate: "Chọn ngày",
      enterEmail: "Nhập Email",
      enterPhone: "Nhập số điện thoại",
      buttonCancel: "Quay lại",
      buttonAccept: "Xác nhận",
      issueDate: "Ngày cấp",
      email: "Email",
      phone: "Số điện thoại",
      errorString: "OTP code gồm có 6 ký tự số",
      errorOtpCode: "OTP code không được bỏ trống",
      otpCode: "Nhập mã OTP",
      enterOtpCode: "Nhập mã OTP",

      errorCMND: "wrong",
      CMND: "CMND/CCCD/GPKD/...",
      errorUser: "User wrong",
      forgetPassword: "forgetPassword",
      subTitle: "Quý khách vui lòng nhập thông tin sau",
      subTitle2: "Nhập email hoặc số điện thoại đăng ký nhận mật khẩu",
      enter: "Enter",
      cancel: "Cancel",
      submit: "submit",
      forgetPasswordSuccess:
        "The password is sent to the SMS / Email customers registered with MBS.",
    },
    changePassword: "Change password",
    language: "Language",
    support: "Hỗ trợ và góp ý",
    changePasswordSuccess: "Change password Success",
    logout: "Logout",
    notification: {
      transaction: "Transaction",
      service: "Service",
      recommendations: "Recommendations",
      notify: "Notify",
      maskAsRead: "Mask as read",
      warning: "Warning",
    },
    tradingAccount: {
      titleTypeOtp: "Authentication",
      typeOtp: "Authentication type",
      title: "Trading Account",
      defaultAccount: "Default Account",
      accountName: "Account Name",
      accountStock: "Account Stock",
      accountCash: "Account Cash",
      accountMCredit: "Account M-Credit",
      accountMargin: "Account Margin",
      accountDerivative: "Account Derivative",
      accountBeneficiary: "Account Beneficiary",
      addAccountBeneficiary: "Add Account Beneficiary",
      success: "Add account success",
      masterAccountName: "Master Account Name",
      bankAccountCode: "Bank Acc Code",
      bankAccountCodeInput: "Bank Acc Code Input",
      errorBankAccount: "Acc cannot be left blank",
      errorBankName: "Vui lòng chọn ngân hàng",
      errorBankProvince: "Vui lòng chọn tỉnh/ thành phố",
      errorBankBranch: "Vui lòng chọn chi nhánh",
      bankName: "Bank Name",
      bankNameInput: "Bank Name Input",
      bankProvince: "Bank Province",
      bankProvinceInput: "Bank Province Input",
      bankBranch: "Bank Branch",
      bankBranchInput: "Bank Branch Input",
      address: "Address",
      inputAddress: "Input Address",
      transferMoney: "Chuyển tiền",
      loginByPassStatic: "Xác thực theo phiên",
      cancel: "Cancel",
      addAccount: "Add Acc",
      typeAccount: "Type Acc",
      deleteAccount: "Delete Acc",
      confirmDelete: "Confirm delete account beneficiary",
      accept: "Accept",
      apply: "Apply",
      deleteSuccess: "Delete Account Success",
      deleteFailure: "Xóa tài khoản thất bại",
      table: {
        tooltipTable: "{bankName}",
      },
      registerSmartOtp: "Đăng ký Smart OTP",
      registerSMSOtp: "Đăng ký SMS OTP",
      registerOtpTypeSuccess: "Đăng ký thành công",
      registerOtpTypeFailure: "Đăng ký thất bại",
      passStaticSuccess: "Đăng ký đăng nhập bằng mật khẩu tĩnh thành công",
      offPassStaticSuccess: "Tắt đăng nhập bằng mật khẩu tĩnh thành công",
      passStaticFailure: "Đăng ký đăng nhập bằng mật khẩu tĩnh thất bại",
      offPassStaticFailure: "Tắt đăng nhập bằng mật khẩu tĩnh thất bại",
      unknown: "Không xác định",
      register: "Đăng ký xác thực",
      titleModalChooseType: "Đăng ký phương thức xác thực",
      smartOtp: "Smart OTP",
      smsOtp: "SMS OTP",
      inputBankName: "Chọn ngân hàng",
      inputBankProvince: "Nhập tỉnh/ thành phố",
      inputBankBranch: "Nhập chi nhánh",
      accountCode: "Số tài khoản",
      term: "Tôi đã đọc và đồng ý với {link} sử dụng dịch vụ của Kafi!",
      link: "Điều khoản và điều kiện",
      existBeneficiaryAccountError: "Tài khoản thụ hưởng đã tồn tại",
      messageTitle: "Thông báo",
      messageContent:
        "Dịch vụ có tính phí SMS, Quý Khách có muốn tiếp tục đăng ký sử dụng không?",
      deleteAcc: "Hủy",
      titleConfirmSetDefault: "Thông báo",
      contentConfirmSetDefault:
        "Quý khách có muốn thay đổi tiểu khoản giao dịch mặc định không?",
      setDefaultSuccess: "Cập nhật tiểu khoản mặc định thành công",
      errorDefault: "Đã có lỗi xảy ra!",
      errorNotChange: "Dữ liệu không thay đổi",
    },
    smsEmail: {
      title: "Email/SMS",
      sms: "SMS",
      email: "Email",
      free: "FREE",
      basic: "BASIC (8,800 VND)",
      pro: "ADVANCED(33,000 VND)",
      settingEmail: "SETTING A EMAIL",
      basicRegister: "You want to register Basic package?",
      advancedToBasic:
        "You want to go from  advanced package to basic package ?",
      advancedRegister: "You want to sign up package advanced??",
      basicToAdvanced:
        "You want to go from basic package to advanced package ?",
      canelBasic: "You want to cancel the Basic plan?",
      canelAdvanced: "You want to cancel the Advanced plan?",
      modalTitle: "SMS/Email setting",
    },
    onlineService: {
      modalTitle: "Register online services",
      titleCancelMargin: " Canceling margin registration",
      marginContr: "Registration limit",
      cancelMarginConfirm:
        "I agree to the terms and conditions of service use of MBS from time to time",
      buttonCancel: "Unregister",
      titleCancelSMUT: "Cancel",
      SMUTCancelConfirm:
        "I have read and agree to the terms of MBS when canceling SMUT",
      accountNumber: "Account number",
      fullName: "Full name",
      titleBroker: "Register broker service",
      lableBroker: "Recommend an account manager and broker",
      brokerConfirm:
        "I have read and agree to the conversion rights account management services",
      buttonYes: "Registered",
      brokerRequest:
        "CHANGE ACCOUNT MANAGEMENT SERVICES: FROM MBS ONLINE TO BROKER SERVICE",
      enterCode: "Enter broker",
      titleMargin: "Register Margin service",
      maxMarginValue: "Maximum value registered ",
      minMarginValue: "Minimum value registered ",
      stepMarginValue: "step value margin ",
      marginValue: "Limit register",
      marginConfirm:
        "I agree to the terms and conditions of service use of MBS from time to time",
      titleMbsOnline: "Register MBS Online service (No Broker)",
      mbsOnline: "MBS Online",
      authenticationMethod: "Authentication method",
      matrix: "Matrix",
      smartOTP: "SmartOTP",
      titleSmartOTP: "Change authentication method Smart OTP",
      smartOTPRequest: "CHANGE METHOD FROM MATRIX TO SMART OTP",
      smartOTPConfirm:
        "I have read and agree to the terms of MBS when changing the authentication method",
      mbsOnlineRequest:
        "CHANGE ACCOUNT MANAGEMENT SERVICES: FROM BROKER SERVICE TO MBS ONLINE",
      mbsOnlineConfirm:
        "I have read and agree to the conversion rights account management services",
      titleSMUT: "Register SMUT service",
      SMUTConfirm:
        "I have read and agreed with register purchase_power service",
      accountService: "Account service",
      marginService: "Margin service",
      registered: "registered",
      unregistered: "Unregistered",
      MCreditService: "M-Credit service ",
      CKService: "CK service",
      registerETrading: "Register ETrading",
      callTrading: "Call trading",
      onlineTrading: "Online trading",
      accountManagementService: "Acc management service",
      onlineServiceLink: OnlineServiceLinkEN,
      brokerService: "Broker service",
      SMUTService: "SMUT service",
      DateRequest: "REQUEST DATE",
      content: "CONTENT",
      status: "STATUS",
      fromDate: "From date",
      toDate: "To Date",
      all: "All",
      CHUA_DUYET: "Pending",
      DA_DUYET: "Approved",
      statusFilter: "Status",
      errorDayRange: "The start date can not be greater than the end date",
      MBSOnlineService: "MBS Online service",
      registerMBSOnline: "Register MBS Online",
      history: "History",
      sendOTP: "Send OTP:",
      numberPhone: "Number phone",
      enterOTP: "Enter OTP",
      enterSmsOTP: "Enter SMS OTP",
      send: "Send code again",
    },
    chooseTheme: {
      editTheme: "Change theme",
      chooseTheme: "Choose theme",
      blackTheme: "Black theme",
      darkTheme: "Dark blue theme",
      lightTheme: "Light theme",
      lightBlueTheme: "Light and Blue theme",
      default: "(default)",
    },
    keyboardShortcuts: {
      title: "Setting keyboard",
      noKey: "Empty",
      duplicateKey: "Error Duplicate Key",
      resetAll: "Reset All",
      keyShortcutOpenForm: {
        title: "Key Open Form",
        category: "Category",
        market: "Market",
        stockOrder: "Stock Order",
        stockOrderDer: "Stock Order Der",
      },
      transactionSettings: {
        title: "Transaction Settings",
        stockOrderBuy: "Stock order buy",
        stockOrderSell: "Stock order sell",
        popupConfirmOrder: "Popup confirm order",
      },
    },
    managerDevice: {
      title: "Danh sách thiết bị",
      titleBtn: "Refresh",
      device: "Thiết bị",
      activeDate: "Ngày kích hoạt",
      deactivate: "Hủy kích hoạt",
      deleteDone: "Hủy kích hoạt thành công",
      deleteFailure: "An error occurred",
      titleDelete: "Thông báo",
      contentDelete: "Bạn có chắc muốn hủy kích hoạt thiết bị này?",
    },
    seviceEmailSms: {
      accoName: "Tài khoản",
      search: "Tìm",
      titleSms: "SMS",
      titleEmail: "Email",
      numberPhone: "Số điện thoại",
      email: "Email",
      register: "Đăng ký",
      nameService: "Tên dịch vụ",
      feeRegister: "Phí ĐK",
      feeMonth: "Phí tháng",
      update: "Cập nhật",
      dataSame: "Dữ liệu không đổi",
      success: "Cập nhật thành công",
    },
    register: {
      title: "Mở tài khoản trực tuyến",
      btnPrev: "Quay lại",
      btnNext: "Tiếp tục",
      btnLogin: "Đăng nhập",
      messageErrorValidate: "Không được để trống trường này!",
      messageSuccess: "Tạo tài khoản thành công",
      labelLeft1: "Thông tin cơ bản",
      labelLeft2: "Thông tin TK thụ hưởng",
      labelLeft3: "Đăng ký dịch vụ",
      step: {
        infomationBasic: "Thông tin cơ bản",
        infomationAccount: "Thông tin TK thụ hưởng",
        register: "Đăng ký dịch vụ",
      },
      step1: {
        question1: "1. Quý khách là nhà đầu tư ?",
        answer1: {
          value1: "Cá nhân",
          value2: "Tổ chức",
        },
        question2: "2. Quốc tịch của Quý khách? *",
        answer2: {
          value1: "Việt Nam",
          value2: "Nước ngoài",
        },
        question3: "3. Quý khách biết đến chúng tôi? * ",
        question4: "4. Chọn chi nhánh, điểm giao dịch gần nhất",
        answer4: {
          labelBranch: "Chi nhánh",
          transactionPoint: "Điểm giao dịch",
          address: "Địa chỉ",
          placeholderAddress: "18 Láng Hạ",
          phone: "Số điện thoại",
          placeholderPhone: "04.2789898",
        },
        question5: "5. Nhân viên giới thiệu/ chăm sóc tài khoản",
        placeholderQuestion5: "Chọn nhân viên giới thiệu/chăm sóc",
        dataChannelId: {
          value1: {
            label: "Internet",
          },
          value2: {
            label: "Báo chỉ",
          },
          value3: {
            label: "Quảng cáo",
          },
          value4: {
            label: "Nhân viên giới thiệu",
          },
          value5: {
            label: "Khác",
          },
        },
        optionBroker: {
          valueAll: {
            label: "Không có nhân viên chăm sóc/giới thiệu",
          },
        },
        modal: {
          title: "Hình ảnh xác thực EKYC",
          content:
            "Tôi đồng ý để VGAIA sử dụng hình ảnh sau đây cho mục đích xác thực và cung cấp cho cơ quan quản lý nếu được yêu cầu.",
          btnAccep: "Đồng ý",
          btncancel: "Hủy",
        },
      },
      step2: {
        cmnd: "CMND",
        passport: "HỘ CHIẾU",
        business: "GIẤY PHÉP KINH DOANH",
        other: "KHÁC",
        traddingCodeOrganization: "TRADINGCODE CHO TỔ CHỨC NN",
        traddingCodeIndividual: "TRADINGCODE CHO CÁ NHÂN NN",
        governmentAgencies: "CƠ QUAN CHÍNH PHỦ",
        identity: "CĂN CƯỚC CÔNG DÂN",
      },
      step3: {
        question: "Chụp ảnh mặt trước/mặt sau CMND/CCCD",
        labelFronSide: "CMND/CCCD mặt trước *",
        fronSide: "CMND/CCCD mặt trước",
        labelBackSide: "CMND/CCCD mặt sau *",
        backSide: "CMND/CCCD mặt sau",
        btnPhoto: "Chụp ảnh",
        dowloadPhoto: "Tải ảnh lên",
        modal: {
          title: "Chụp ảnh CCCD/CMND",
          fontImage: "CMND/CCCD mặt trước",
          backImage: "CMND/CCCD mặt sau",
        },
      },
      step4: {
        question: "Xác thực khuôn mặt",
      },
      step5: {
        label: "Thông tin cá nhân",
        exhibit: {
          label: "Loại giấy tờ",
        },
        CMND: {
          label: "Số CMND/CCCD",
        },
        address: {
          label: "Nơi cấp",
        },
        dateRange: {
          label: "Ngày cấp",
        },
        expirationDate: {
          label: "Ngày hết hạn",
        },
      },
      step6: {
        label: "Chọn số tài khoản",
        question1: {
          label: "Không chọn số",
        },
        question2: {
          label: "Chọn số theo gợi ý",
        },
        question3: {
          label: "Tự chọn số tài khoản",
          prefix: "045C",
        },
        note: "Lưu ý: Số tài khoản phải gồm 6 chữ số !",
        checkCode: "Số tài khoản đã tồn tại. Quý khách vui lòng chọn số khác",
      },
      step7: {
        label: "Thông tin tài khoản thụ hưởng",
        btnAddAccount: "Thêm tài khoản",
        btnRemoveAccount: "Xoá tài khoản",
        errorName: "Tên tài khoản thụ hưởng của Quý khách không phải chính chủ",
        question1: {
          label: "Người thụ hưởng",
          placeholder: "Nhập tên người thụ hưởng",
          labelCheckbox: "Chính chủ",
        },
        question2: {
          label: "Số tài khoản",
          placeholder: "Nhập số tài khoản",
          check: "Chính chủ",
        },
        question3: {
          label: "Ngân hàng",
          placeholder: "Chọn ngân hàng",
        },
        question4: {
          label: "Tỉnh thành",
          placeholder: "Chọn tỉnh thành",
        },
        question5: {
          label: "Chi nhánh",
          placeholder: "Chọn chi nhánh",
        },
      },
      step8: {
        label: "",
        btnAddAccount: "",
        question1: {
          label: "1. Giao dịch qua tổng đài điện thoại GAIA Contact Center",
          value1: "Có",
          value2: "Không",
        },
        question2: {
          label: "2. Giao dịch trực tuyến quá Internet, ứng dụng di động",
          value1: "Có",
          value2: "Không",
        },
        question3: {
          label: "3. Phương thức xác thực cấp 2",
          value1: "SMS OTP",
          value2: "Smart OTP",
        },
        question4: {
          label: "4. Dịch vụ nhận tin nhắn SMS",
          value1: "Có",
          value2: "Không",
        },
        question5: {
          label: "5. Dịch vụ nhận kết quả khớp lệnh, sao kê qua Email",
          value1: "Có",
          value2: "Không",
        },
        question6: {
          label: "6. Dịch vụ ứng trước tiền bán tự động",
          value1: "Có",
          value2: "Không",
        },
        note: "Tìm hiểu về ứng trước tiền bán tự động ",
        noteLinhk: "Tại đây",
        contentApcet:
          "Tôi đã đọc và đồng ý với các điều khoản được quy định tại ",
        contentLink: "Hợp đồng mở tài khoản",
      },
      stepOTP: {
        title: "Xác nhận OTP",
        content:
          "Vui lòng chờ trong giây lát để chúng tôi xác thực số điện thoại của Quý khách. Hãy nhập mã OTP vừa được gửi đến số điện thoại Quý khách vừa đăng ký.",
        content2: "Mã OTP có hiệu lực trong ${time} giây",
        labelQuestion: "Nhập mã OTP",
        placehoder: "Nhập mã OTP",
        btnOTP: "Gửi lại mã",
      },
      confirm: {
        content1:
          "Quý khách dã hoàn tất việc đăng ký thông tin mở tài khoản giao dịch chứng khoán trực tuyến tại VGAIA.",
        content2:
          "Thông báo kích hoạt tài khoản giao dịch chứng khoán và hướng dẫn hoàn thiện hồ sơ sẽ được gửi đến email của Quý khách.",
        content3:
          "Sau khi nhận được email, tài khoản của Quý khách sẽ được kích hoạt tính năng đặt lệnh giao dịch tạm thời (Quý khách có thể nộp và đặt lệnh giao dịch chứng khoán trên tài khoản trực tiếp tại quầy giao dịch, qua tổng đài Contact Center hoặc cả ứng dụng giao dịch trực tuyến của VGAIA). Để có thể sử dụng đầy đủ các dịch (rút tiền/đăng ký margin...) của VGAIA. Quý khách vui lòng hoàn thiện hồ sơ pháp lý và gửi về Chi nhánh/Phòng giao dịch của VGAIA trong thời gian quy đinh (14 ngày kể từ ngày tài khoản thành công).",
        content4: "Hồ sơ bao gồm:",
        content5:
          "Hợp đồng mở tài khoản giao dịch chứng khoán (đã gửi về email của Quý khách): 02 bản",
        content6: "Bản sao CMND hoặc CCCD: 02 bản",
        content7:
          "Bản xác thực chữ ký có xác nhận của UBND phường xã hoặc Phòng công chứng: 01 bản",
        content8:
          "Vui lòng liên hệ TRung tâm Dịch vụ khách hàng: 1900 558838 hoặc +84 24 3972 9888 để được hỗ trợ nếu không nhận được email sau 1 ngày làm việc.",
        content9:
          "Cảm ơn Quý khách đã lựa chọn dịch vụ của CTCP Chứng khoán VGAIA!",
      },
      step5_2: {
        label: "Thông tin cá nhân",
        question1: {
          label: "Họ",
          placeHolder: "",
        },
        question2: {
          label: "Đệm và Tên",
          placeHolder: "",
        },
        question3: {
          label: "Ngày sinh",
          placeHolder: "",
        },
        question4: {
          label: "Giới tính",
          placeHolder: "",
        },
        question5: {
          label: "Số điện thoại",
          placeHolder: "",
        },
        question6: {
          label: "Email",
          placeHolder: "",
        },
        question7: {
          label: "Địa chỉ",
          placeHolder: "",
        },
        optionSex: {
          value0: "Khác",
          value1: "Nam",
          value2: "Nữ",
        },
        errorValidate: {
          phone: "Số điện thoại không đúng định dạng",
          email: "Email không đúng định dạng",
        },
      },
    },
    editPassFirst: {
      title: "Đổi mật khẩu",
      btnCancel: "Hủy",
      btnSuccess: "Cập nhật",
      errerValid: "Không được để trống trường này",
      passLogin: {
        title: "Đổi mật khẩu đăng nhập",
        nowPass: "Mật khẩu hiện tại",
        placeholderNowPass: "Nhập mật khẩu hiện tại",
        newPass: "Mật khẩu mới",
        placeholderNewPass: "Nhập mật khẩu mới",
        confirmPass: "Nhập lại MK mới",
        placeholderconfirmPass: "Nhập lại mật khẩu mới",
      },
      cdLogin: {
        title: "Đổi mật khẩu giao dịch",
        nowPass: "Mật khẩu hiện tại",
        placeholderNowPass: "Nhập mật khẩu hiện tại",
        newPass: "Mật khẩu mới",
        placeholderNewPass: "Nhập mật khẩu mới",
        confirmPass: "Nhập lại MK mới",
        placeholderconfirmPass: "Nhập lại mật khẩu mới",
      },
    },
  },
  category: {
    table: {
      noContent: "",
      profitPerLoss: "+/-",
      changePercentage: "%",
      ticker: "Symbol",
      ceiling: "CE",
      floor: "FL",
      referenceShort: "TC",
      reference: "REF",
      overBought: "Over bought",
      overSold: "Over sold",
      filled: "Order matching",
      price: "Price",
      amount: "Volume",
      high: "High",
      low: "Low",
      average: "Average",
      foreign: "Foreign",
      overBoughtAmount3: "(Over bought) Volume 3 ",
      amount3: "Vol 3",
      overBoughtPrice3: "(Over bough) Price 3",
      price3: "Price 3",
      overBoughtAmount2: "(Over bought) Volume 2",
      amount2: "Vol 2",
      overBoughtPrice2: "(Over bought) Price 2",
      price2: "Price 2",
      overBoughtAmount1: "(Over bought) Volume 1",
      amount1: " Vol 1",
      overBoughtPrice1: "(Over bought) Price 1",
      price1: "Price 1",
      filledPrice: "(Order matching) PRICE",
      lastSale: "LAST_SALE",
      filledDiff: "(Order matching) +/-",
      filledAmount: "(Order matching) Volume",
      overSoldPrice1: "(Over bought) Price 1",
      overSoldAmount1: "(Over bought) Volume 1",
      overSoldPrice2: "(Over bought) Price 2",
      overSoldAmount2: "(Over bought) Volume 2",
      overSoldPrice3: "(Over bought) Price 3",
      overSoldAmount3: "(Over bought) Volume 3",
      sumAmount: "Total volume ",
      foreignBought: "(ĐTNN) Buy",
      buy: "Buy",
      foreignSold: "(ĐTNN) Sell",
      sold: "Sell",
      date_dh: "Date ĐH",
      diff: "Differrence",
      oi: "OI",
      tcph: "TCPH",
      gdcc: "GDCC",
      ckcs: "UDS",
      price_ckcs: "Price",
      th: "TH",
      ratio: "Radio",
      diff_cd: "Diff CD",
      break_even_point: "Break even point",
      volume: "volume",
      stock: "Stock",
      time: "Time",
      transactionValue: "Transaction value",
      accumulatedValue: "Accumulated value",
      accumulatedVolume: "Accumulated volume",
      bid: "Bid",
      ask: "Ask",
      currentRoom: "ROOM NN",
      footer: "Giá x 1,000 VNĐ. Khối lượng x 10. Giá trị x 1,000,000 VNĐ.",
      footerPutThrough: "Giá x 1,000 VNĐ. Khối lượng x 1. Giá trị x 1 VNĐ.",
      footerOdd: "Giá x 1,000 VNĐ. Khối lượng x 1. Giá trị x 1,000,000 VNĐ.",
    },
    modal: {
      title: "Edit column",
      editColumn: "Edit Column",
      selectColumn: "Select column",
      reset: "Reset",
      cancel: "Cancel",
      apply: "Apply",
      autoScroll: "Tự động cuộn",
      duration: "Độ trễ",
      note: "(mili giây / mã chứng khoán)",
      duration500: "Độ trễ phải >=500",
    },
    custom: {
      title: "Your category",
      confirm: "Confirm",
      nameCategory: "Enter name category",
      addCategory: "Add category",
      cancel: "Cancel",
      favorite: "Favorites category",
      addToFavoriteListSuccess: "Add to favorite list success",
      addToFavoriteListFailure: "Add to favorite list failure",
      removeFromFavoriteListSuccess: "Remove to favorite list success",
    },
    tabs: {
      owner: "Owner category",
      listed: "List",
      putThrough: "Put through",
      derivative: "Derivative",
      coverWarrant: "Cover warrant",
      industry: "Industry",
      oddLot: "Odd lot ",
    },
    derivative: {
      vn30: "VN30",
      tpcp: "HĐTL TPCP",
    },
    coverWarrant: {
      mbs: "Cover warrant MBS",
      full: "Full market warrants",
    },
    putThrough: {
      hsx: "HSX",
      hnx: "HNX",
      upcom: "UPCOM",
      totalSharesTradedPT: "TOTAL VOLUME TRANSACTION AGREEMENT",
      totalValuesTradedPT: "TOTAL VALUE OF TRANSACTION AGREEMENT",
      billionVnd: "VND",
      dataEmpty: "Data empty",
    },
    errors: {
      duplicatedName: "Duplicated name",
      required: "Enter a list name",
      sameOwnerName: "Duplicated owner name",
    },
  },
  market: {
    dragTooltip: "Add to display",
    viewDoesntExist: "View doesnt exist",
    newScreen: "Market Analysis (empty)",
  },
  widgets: {
    orderBookCondition: {
      name: "Order List",
    },
    orderBasket: {
      name: "Order basket",
    },
    orderBasketDer: {
      name: "Order basket der",
    },
    moneyblock: {
      name: "Money block",
    },
    coverWarrantInfo: {
      name: "CW Information",
      cwIssuerName: "CW issuer name",
      cwType: "CW type",
      cwExercisePrice: "Exercise price",
      cwExerciseRatio: "Exercise ratio",
      cwRemainLastTradingDate: "Remain last trading date",
      CKCSCode: "UDS ",
      CKCSPrice: "Price",
      status: "Status",
      cwLastTradingDate: "Last trading date",
      cwMaturityDate: "Maturity date",
    },
    tickerGraph: {
      name: "Technical Analystic",
    },
    watchList: {
      name: "Watch List",
    },
    accountInfo: {
      name: "Account Info",
    },
    financialService: {
      name: "Financial Service",
    },
    stockOrder: {
      name: "Stock Order",
    },
    priceList: {
      name: "Price list",
    },
    TickerInfo: {
      name: "TickerInfo",
      errors: {
        noWatchlistToAdd: "No watchlist to add",
      },
    },
    orderBookOfTheDay: {
      name: "Order list of the day",
    },
    finance: {
      year: "Year",
      quarter: "Quarter",
      viewType: "View type",
      mainIndicator: {
        label: "Label",
        type: {
          roe: "ROE",
          roa: "ROA",
          eps: "EPS",
          yoy: "YOY",
        },
      },
      reportRevenue: {
        label: "Income Statement",
        type: {
          netincome: "Net Income",
          netrevenue: "Total Revenue",
          operatingincome: "Operating Income",
          yoy: "YOY",
        },
      },
      cashFlow: {
        label: "Cash Flow",
        type: {
          cashflowoperating: "Operating",
          cashflowinvesting: "Investing",
          cashflowfinance: "Financing",
          yoy: "YOY",
        },
      },
      balanceSheet: {
        label: "Balance Sheet",
        totalAssets: "Total Assets",
        totalLiabilities: "Total Liabilities",
        solvencyDebtAssets: "Debt to Asset",
      },
    },
    Card: {
      agree: "Agree",
    },
    foreignWatch: {
      name: "Foreigners",
      description:
        "The green color on the chart shows foreign net buying. Red shows foreign net selling. Data includes Stocks and ETFs. The unit value of million VND.",
      floor: "FL",
      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      buyValue: "Buy Value",
      sellValue: "Sell Value",
      netValue: "Net Value",
      totalBuyValue: "Total buy value",
      totalSellValue: "Total sell Value",
      totalNetValue: "Total net value",
    },
    marketWatch: {
      name: "Market movement",
      topIndex: "TOP INDEX",
      marketRange: "Market range",
      liquidity: "Liquidity",
      transactionValueVolume: "Transaction value volume",
      capitalization: "MARKET CAP",
      floor: "FL",
      valueType: "Type",
      value: "Value",
      marketTypes: {
        HSX: "HSX",
        HNX: "HNX",
        UPCOM: "UPCOM",
        VN30: "VN30",
        HNX30: "HNX30",
      },
      transactionTypes: {
        value: "Matched transactions",
        valueAll: "Matched transactions and agreements",
      },
      valueTypes: {
        price: "Transaction value",
        volume: "Trading volume",
      },
    },
    marketStats: {
      yAxesLabel: "Contribute to INDEX (%)",
      xAxesLabel: "Amount trading",
      changedPercentVolumeWithAvg5d: "Amount trading:",
      contributionPercentage: "Contribute to INDEX (%):",
      contributionValue: "Contribute to INDEX (Point):",
      marketPercent: "% Market cap:",
    },
    liquidity: {
      name: "Liquidity",
      description:
        "Chart comparing the cash flow at the same time of the previous session, an average of 1 week, 2 weeks and 1 month average.",
      present: "Present",
      lastSession: "Last Session",
      oneWeek: "One week",
      twoWeek: "Two week",
      oneMonth: "One month",
    },
    topIndex: {
      percent: "Contribute to INDEX (%)",
      value: "Contribute to INDEX (Point)",
      increaseContribute: "Increase contribute",
      decreaseContribute: "Decrease contribute",
      point: "Point",
      description:
        "The graph shows the influence of information points and the impact of a 30% share to increase impact of the index declined, 15 stocks rose and 15 pm influence affects dimensional reduction codes.",
    },
    capitalization: {
      description:
        "Chart shows the allocation of all-cap stocks on the floor. Unit is million VND.",
    },
    transactionValueVolume: {
      description:
        "The graph shows the value / volume of exchanges are focused on the code. Transaction value unit is million VND.",
    },
    industryDescription: {
      topIndex: {
        description:
          "Biểu đồ thể hiện đóng góp của ngành vào INDEX. Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
      transactionIndustry: {
        description:
          "Biểu đồ thể hiện giá trị giao dịch ngành (đơn vị Triệu VND). Màu xanh trên biểu đồ thể hiện ngành đóng góp dương (kéo INDEX lên), màu đỏ thể hiện đóng góp âm (kéo INDEX xuống).",
      },
    },
    marketRange: {
      description:
        "The horizontal axis shows the ratio of volume to 5-day average of volume. The vertical axis shows the contribution of each token to the return of the index at the current session.",
    },
    topPrice: {
      left: "Top increases",
      right: "Top decreased",
      headers: {
        today: "To day",
        oneWeek: "One week",
        oneMonth: "One month",
        threeMonths: "Three months",
        sixMonths: "Six months",
        oneYear: "One year",
      },
      table: {
        stockId: "Company name",
        price: "Price match",
        changedPrice: "Change",
        changedPricePercentage: "%",
      },
    },
    coverWarrant: {
      title: "Cover Warrant",
      table: {
        stockId: "Symbol",
        price: "Price match",
        changedPrice: "+/-",
        changedPricePercentage: "%",
        volume: "Volume",
      },
      headers: {
        allIssuer: "All Issuer",
        allUnderlying: "All Underlying",
      },
    },
    industry: {
      title: "Industry",
      select: "Choose industry",
      table: {
        ticker: "Symbol",
        price: "Match price",
        totalAmount: "Total volume",
      },
    },
    topStatistical: {
      name: "Top trading stocks",
      headers: {
        topVolume: "Volume",
        topValue: "Value",
        topChange: "% Change",
      },
      table: {
        stockId: "Symbol",
        price: "Last price",
        changedPrice: "Change",
        changedPricePercentage: "%",
        changeHighLow: "% Biến động",
        topValue: "Value(Bill)",
        topValueMass: "Vol(Mill)",
      },
    },
    topForeign: {
      name: "Top foreign trading",
      headers: {
        foreignNetValuesBuy: "Buy Net",
        foreignNetValuesSell: "Sell Net",
        foreignBuy: "F buy volume",
        foreignSell: "F sell volume",
        foreignBuyValue: "F buy value",
        foreignSellValue: "F sell value",
      },
      table: {
        stockId: "Company name",
        price: "Match",
        changedPrice: "Change",
        changedPricePercentage: "%",
        topValue: "Value(Bill)",
        topValueMass: "Value(Thousand)",
      },
    },
    exchangeTradedFund: {
      name: "ETF",
      priceMatch: "Price match",
      codeTicker: "Symbol",
      volume: "Total volume",
    },
    recommendation: {
      name: "Stock recommendations",
      code: "Code",
      side: "KN",
      recommendedPrice: "Rec.Price",
      lastSale: "Market Price",
      expectedPrice: "Target Price",
      positive: "Positive",
      watching: "tracking",
      buy: "Buy",
      sell: "Sell",
    },
    adIndex: {
      name: "Advances & Declines Distribution",
      title: "Index A-D",
      sum: "Total: {number}",
      advance: "Increase(Advancers): {number}",
      decline: "Decrease (Decliners): {number}",
    },
    vn30Index: {
      title: " Index VN30",
    },
    organization: {
      title: "Business Profile",
      domesticAndForeign: "Domestic and Foreign",
      domesticStackHolder: "Domestic stack holder",
      foreignStackHolder: "Foreign stack holder",
    },
    netflow: {
      name: "Netflow",
      positive: "Net Flow > 0: Positive signal",
      negative: "Net Flow < 0: Negative signal",
      headers: {
        present: "T",
        oneDayAgo: "T-1",
        twoDayAgo: "T-2",
        threeDayAgo: "T-3",
        fourDayAgo: "T-4",
        fiveDayAgo: "T-5",
      },
      noteText:
        "It is an indicator that measures the value of active buy orders - the value of active sell orders of all stocks in VN30, to determine whether the cash flow is actively buying or selling.",
    },
    orderFlowDistribution: {
      title: "Order Flow Distribution (Unit: Billion VND)",
      small: "Small",
      medium: "Medium",
      large: "Larger",
      totalBuy: "Inflow",
      totalSell: "Outflow",
      noteTitle:
        "GIÁ TRỊ MUA/BÁN ĐƯỢC THỐNG KÊ THEO CHI TIẾT LỆNH KHỚP LÀ MUA CHỦ ĐỘNG/BÁN CHỦ ĐỘNG VỚI CÁC MỨC GIÁ KHỚP LỆNH (GTKL) TƯƠNG ỨNG:",
      noteText1: "- Lớn: 10% GTKL lớn nhất",
      noteText2: "- Nhỏ: 10% GTKL bé nhất",
      noteText3: "- Trung bình: 80% GTKL còn lại",
    },
    infoQuote: {
      title: "Step value",
      volume: "Volume",
    },
    volumeAndTime: {
      title: "Volume and Time",
      matchHistory: "Match history",
      volumeAnalysis: "Volume Analysis",
      esideShorthand: {
        buy: "B",
        sell: "S",
      },
    },
    tickerInfo: {
      fillCode: "Enter symbol, company name ...",
      ceilingPrice: "CE",
      floorPrice: "FL",
      referencePrice: "TC",
      openAveragePrice: "OPEN/AVG",
      lowesthighestPrice: "DAY’S RANGE",
      totalSellQty: "F.SELL VOLUME",
      totalBuyQty: "F.BUY VOLUME",
      totalSharesTradeMt: "Total volume",
      marketCap: "MARKET CAP",
      sharesOutstanding: "SHARE OUTSTANDING",
      priceToEarningRatio: "P/E (TTM)",
      tangibleBookValuePerShare: "VALUE/BOOK VALUE",
      returnOnEquity: "ROE",
      highestPrice52Week: "52 WEEK RANGE",
      turnoverRate: "% TURNOVER",
      priceToBook: "P/B",
      dividend: "DIVIDEND",
      range: "% RANGE",
      earningPerShare: "EPS (TTM)",
      priceToSalesRatio: "P/S",
      dividendYield: "DIV YIELD",
      currentRoom: "Room NN",
      DILastTradingDate: "Expired date",
      bias: "BASIC",
      OI: "OI",
      lasttradedate: "Ngày GD CC",
      execprice: "GIÁ THỰC HIỆN",
      execratio: "TL CHUYỂN ĐỔI",
      issuer: "TCPH",
      baseseccd: "MÃ CKCS",
      baseprice: "GIÁ CKCS",
    },
    placeOrder: {
      conditionalOrder: {
        title: "Conditional order",
        normal: "Normal",
        timeConditionOrder: "Before the date",
        trailingStopOrder: "Trend",
        priorityOrder: "Painting Buy / Sell",
        takeProfit: "Take profit",
        cutLoss: "Cut loss",
        generatedOneTime: "Generated One Time",
        matchingEnoughVolume: "Matching enough volume",
        byPercent: "By percent",
        byValue: "By value",
        conditionsList: {
          noCondition: "No condition",
          referencePrices: "Reference Prices",
          matchedPrice: "Matched Price",
        },
        priceTC: "Prices Reference",
        errorPlaceSellOrderSP: "Take profit don't apply order buy",
        errorPlaceSellOrderCL: "Take loss don't apply order buy",
        errorPriceTCOLO: "Before day order only support LO price",
      },
      errorCheckFAL: "Please click confirm before trading.",
      inputTicker: "Input Ticker",
      errorTickerEmpty: "Please enter stock code or company name",
      errorTickerIllegal: "Inval symbol",
      account: "Account",
      tradetype: "Type order",
      method: "Method",
      condition: "condition",
      toDate: "To date",
      buyTitle: "COMFIRM BUY",
      sellTitle: "COMFIRM SELL",
      accountNumber: "Account number",
      commandType: "Type order",
      stockCode: "Stock code",
      sellVolumn: "Sell volumn",
      buyVolumn: "Buy volumn",
      sellPrice: "Sell price",
      buyPrice: "Buy price",
      totalLabel: "Order Value (VND)",
      noVATLabel: "(Excluding fees and taxes)",
      confirmFAL: "You are replacing a FAL order. Please confirm this order!",
      visibleModalConfirm:
        "Không hiển thị lại vào lần sau. Bạn có thể bật lại chức năng này ở Cài đặt giao dịch",
      comfirmBuy: "Comfirm buy",
      comfirmSell: "Comfirm sell",
      slidingMargin: "Sliding margin",
      value: "Value",
      percent: "%",
      stopInterval: "Stop range",
      minBuyPrice: "Min buy price",
      maxSellPrice: "Max sell price",
      avgPrice: "Avg Price",
      arbitrage: "Diff price",
      cancel: "Cancel",
      buyFal: "Buy fal",
      dailyCredit: "Daily Credit",
      maxBuy: "Max buy",
      maxSell: "Max sell",
      maxVolume: {
        tooltip: "Click to place order",
      },
      marginRate: "Margin rate",
      purchasingAbility: "Buy power",
      buy_command: "PLACE ORDER",
      sell_command: "PLACE ORDER",
      volumn: "VOLUME",
      ceilingPrice: "CE",
      floorPrice: "FL",
      referencePrice: "REF",
      orderSuccess: "Order success",
      orderError: "An error occurred. Please check again",
      errorAccountDer: "Dữ liệu không hợp lệ, vui lòng thử lại",
      volume: "Volume",
      price: "Price",
      normal: "Normal",
      orderLimitedQty: "Max",
      accountBlockage: "Tài khoản của khách hành đang bị phong tỏa.",
      insiderShareholder:
        "Giao dịch liên quan đến cổ đông nội bộ. Bạn có tiếp tục thực hiện giao dịch không?",
      bigShareholder:
        "Giao dịch liên quan đến cổ đông lớn. Bạn có tiếp tục thực hiện giao dịch không?",
      maxRateWarning:
        "TL sở hữu chứng khoán của quý khách vượt quá {maxRate}%, quý khách phải thực hiện chào mua công khai theo quy định của pháp luật. Bạn có muốn tiếp tục thực hiện giao dịch không?",
      privateTradingBond:
        "KL tối thiểu còn lại phải đặt trong ngày là {privateRemainQty}, bạn có muốn tiếp tục không?",
      submitOrderPending: "Bạn có muốn đặt lệnh trước giờ?",
      orderType: {
        title: "Loại lệnh",
        normal: "THƯỜNG",
        pending: "TRƯỚC GIỜ",
        condition: "ĐIỀU KIỆN",
      },
      currentRoom: "Room CL",
      suggestionFail: "Giá nhập không hợp lệ",
    },
    orderBookDetail: {
      title: "Order details",
      subAccountNo: "Account",
      status: "Status",
      tradeType: "Transfer Type",
      symbol: "Symbol",
      tradingDate: "Trading date",
      orderQty: "Order qty",
      price: "Price set",
      pubQty: "KL chờ",
      pubPrice: "Giá chờ",
      cumQty: "Matching volume",
      matchAvgPrice: "Match avg price",
      orderNo: "Số hiệu lệnh",
      channel: "channel",
      rejectReason: "Reject reason",
      table: {
        rowNum: "STT",
        tradeDate: "Ngày GD",
        ordQty: "Khối lượng",
        ordPrice: "Giá",
        matQty: "KL khớp",
        matAvg: "Giá khớp",
        regDateTime: "Thời gian",
        confirmTime: "Thời gian",
        tradeType: "Loại giao dịch",
      },
      noData: "No data",
    },
    orderBookEdit: {
      setPrice: "Set price",
      setVolumn: "Set volumn",
      cancel: "Cancel",
      confirm: "Confirm",
      BUY: "Edit buy order",
      BUY_OUTR: "Edit buy order OutR",
      SELL: "Edit sell orde",
      SELL_OutR: "Edit buy order OutR",
      SELL_TT68: "Edit buy order TT68",
      orderSuccess: "Order Success",
      orderError: "An error occurred. Please check again",
      errorVolumnUp: "Do not edit the volume up.",
      errorVolumnDown: "Do not edit the volume down.",
      errorChangeBoth: "Do not edit price and volume at the same time.",
    },
    placeOrderDer: {
      name: "Derivative",
      conditionalOrder: "Conditional order",
      availMargin: "Sức mua",
      currentRate: "TL sử dụng KQ",
      maxLong: "max Long",
      maxShort: "Max Short",
      comfirmShortTitle: "Comfirm Short tittle",
      comfirmLongTitle: "Comfirm Long tittle",
      tickerCode: "Symbol",
      volume: "Volume",
      price: "Price",
      acceptLong: "LONG",
      acceptShort: "SHORT",
      inputTicker: "Enter symbol",
      all: "Tất cả",
      A: "---",
      UP: "UP",
      DOWN: "DOWN",
      IFDONESTOP: "IF DONE STOP",
      IFDONEGAIN: "IF DONE GAIN",
      BULLBEAR: "BULL & BEAR",
      OCO: "OCO",
      TRAILINGSTOP: "TRAILING STOP",
      activePrice: "Giá kích hoạt",
      cutLostPrice: "Giá cắt lỗ",
      takeprofit: "Giá chốt lãi",
      limitOffset: "Biên trượt",
      cutLostAmp: "Biên độ cắt lỗ",
      grossAmp: "Biên độ chốt lãi",
      rangeStop: "Khoảng dừng",
      normal: "Normal",
      orderSuccess: "Order Success",
      orderError: "Order Error",
      errorAccountEquity: "Dữ liệu không hợp lệ, vui lòng thử lại",
    },
    portfolio: {
      account: "Tài khoản",
      alloDate: "Ngày",
      secCd: "Mã CK",
      name: "Portfolio",
      userName: "USER",
      code: "CODE",
      type: "TYPE",
      weight: "AVAILABE",
      wait: "wait",
      rightShares: "WAIT RIGHT SHARE",
      avgPrice: "AVG PRICE(VNĐ)",
      marketPrice: "MARKET PRICE",
      grossValue: "INFLOW",
      marketValue: "MARKET PRICE",
      marketValueRatio: "RATIO (%)",
      profit: "PL",
      profitRatio: "% PL/",
      sum: "TOTAL",
      sharesBalance: "TOTAL",
      sellAll: {
        title: "Bán tất cả danh mục",
        titleConfirm: "Xác nhận đặt lệnh",
        percent: "Tỉ lệ KL bán",
        account: "Tiểu tài khoản",
        market: "Sàn",
        floorPrice: "Giá sàn",
        price: "Giá đặt",
        table: {
          userName: "Tài khoản",
          code: "Mã CK",
          type: "Loại",
          sharesBalance: "KL khả dụng",
          weight: "KL bán",
          marketPrice: "Giá hiện tại",
          exchanges: "Sàn",
          sellQty: "KL đặt",
          price: "Giá đặt",
        },
        cancel: "Hủy",
        confirm: "Xác nhận",
        placeOrder: "Đặt lệnh",
        emptySelect: "Không có lệnh nào phù hợp được chọn",
        orderSuccess: "Đặt lệnh thành công {count}/ {total} lệnh",
        errorSessionHSX:
          "Bán toàn danh mục sàn HOSE chỉ đặt lệnh phiên ATO, ATC, Liên tục",
        errorSessionHNX:
          "Bán toàn danh mục sàn HNX chỉ đặt lệnh phiên ATC, Liên tục, PLO",
        errorSessionUPCOM:
          "Bán toàn danh mục sàn Upcom chỉ đặt lệnh phiên Liên tục",
        noSelect: "Không có lệnh nào được chọn",
      },
    },
    orderBookDerEdit: {
      titleLong: "Edit Long order",
      titleShort: "Edit Short order",
      setPrice: "Set price",
      setVolume: "Set volume",
      profit: "Take profit price",
      conditionPrice: "Condition price",
      stopLoss: "Cut loss price",
      margin: "Silp margin",
      cancel: "Cancel",
      orderSuccess: "Order Success",
      orderError: "An error occurred. Please check again",
      confirmLong: "Confirm Long",
      confirmShort: " Confirm Short",
      confirm: "Xác nhận",
    },
    openPosition: {
      name: "Position",
      seriesId: "Contract code",
      net: "Net",
      askAvgPrice: "Ask avg price",
      marketPrice: "Market price",
      floatingPL: "PL",
      summary: "Total",
      valuePositionSummary: "Value of open position",
    },
    closePosition: {
      name: "Close position",
      seriesId: "Contract code",
      long: "Long",
      net: "Net",
      askAvgPrice: "Avg sell",
      bidAvgPrice: "Avg buy",
      tradingPL: "PL",
      summary: "Total",
    },
    watchlist: {
      selectCategory: "Select watchlist",
      ticker: "Symbol",
      sumVolume: "	TOTAL VOLUME",
      percent: "%",
    },
    maximunTabError: "Exceeded the maximum number of boards",
    marginContract: {
      name: "Margin contract",
    },
  },
  tickerDetail: {
    title: "Stock details",
    tabs: {
      finance: "Financing",
      orgInfo: "Business information",
      news: "Newws",
      listPrice: "Step price",
      volumeAndTime: "Volume and time",
      distribution: "Order flow distribution",
    },
    widgets: {
      tickerInfo: "Thông tin mã chứng khoán",
      priceList: "Bước giá",
      volumeAndTime: "Khối lượng và thời gian",
      orderFlowDistribution: "Phân bổ lệnh khớp (Đơn vị: Tỷ đồng)",
      organizationInfo: "Thông tin doanh nghiệp",
      financeInfo: "Tài chính",
      new: "Tin tức",
      tickerGraph: "Phân tích kỹ thuật",
      tickerDetailsBasketContribution: "Chỉ số VN30",
      coverWarrantInfo: "Thông tin mã chứng quyền",
    },
  },
  extends: {
    statement: {
      orderError: "An error occurred. Please check again",
      error90Day: "Exceeded the 90-day search",
      error30Day: "Exceeded the 30-day search",
      error7Day: "Exceeded the 7-day search",
      errorDay: "The start date can not be greater than the end date",
      type: "Type",
      all: "All",
      cashStatement: "Cash Statement",
      stockStatement: "Stock Statement",
      derStatement: "Derivative statement",
      stt: "ID",
      userName: "UserName",
      tradeDate: "TRADE DATE",
      cashUp: "CASH UP",
      cashDown: "CASH DOWN",
      balance: "BALANCE",
      content: "CONTENT",
      beginBalance: "BEGIN BALANCE",
      sum: "TOTAL",
      closeBalance: "CLOSE BALANCE",
      ticker: "SYMBOL",
      exchange: "BOARD",
      account: "Account",
      shareBegin: "Begin balance",
      shareChange: "Total",
      shareEnd: "Close balance",
      shareCode: "SYMBOL",
      shareStatus: "TYPE",

      derStatementTable: {
        rowNo: "ID",
        date: "TRADE DATE",
        transactionNo: "NUMBER OF VOUCHERS",
        balanceMBS: "BALANCE MBS",
        clientDebit: "CASH DOWN",
        clientCredit: "CASH UP",
        balanceVSD: "BALANCE VSD",
        brokerDebit: "CASH DOWN",
        brokerCredit: "CASH DOWN",
        totalBalance: "BALANCE",
        description: "CONTENT",

        summary: "TOTAL",
        beginBalance: "BEGIN BALANCE",
        endBalance: "CLOSE BALANCE",
      },
    },
    profitLossEquity: {
      title: "Profit Loss Equity",
      header: {
        account: "Account",
        fromDate: "From Date",
        toDate: "To Date",
        symbol: "Symbol",
        search: "Search",
        inputSearch: "Input Share code Search",
        allAccount: "All Account",
        errorDayRange: "Exceeded 30-day search limit",
        errorDayRange2: "From date not greater than To date",
      },
      tableContent: {
        tradingDate: "Trading Date",
        accountNo: "Account No",
        symbol: "Symbol",
        status: {
          title: "Status",
          normal: "Normal",
          outR: "OutR",
          tt68: "TT68",
        },
        content: "Content",
        volume: "Volume",
        matchedPrice: "Matched Price",
        fee: "Fee",
        taxToPay: "Tax/ Other Fee",
        avgPrice: "Avg Price",
        gainloss: "Gain Loss",
        percent: "Percent",
        totalProfit: "Total Profit",
        loadmore: "Load more items",
        loadend: "You have seen it all",
        nothing: "Nothing Item",
        buy: "BUY",
        sell: "SELL",
      },
    },
    profitLossDer: {
      title: "Profit Loss Der",
      header: {
        account: "Account",
        fromDate: "From Date",
        toDate: "To Date",
        symbol: "Symbol",
        search: "Search",
        inputSearch: "Input Search",
        allAccount: "All Account",
        errorDayRange: "Exceeded 30-day search limit",
      },
      tableContent: {
        transDate: "Trans Date",
        symbol: "Symbol",
        netOff: "Net Off",
        expired: "Expired",
        open: "Open",
        totalNetOff: "Total Net Off",
        avgBuyPrice: "Avg Buy Price",
        avgSellPrice: "Avg Sell Price",
        closePrice: "Close Price",
        profit: "Profit",
        totalProfit: "Total Profit",
        buy: "Buy",
        sell: "Sell",
        long: "Long",
        short: "Short",
      },
    },
    confirmationOrderOnline: {
      success: "Registration successful",
      title: "Confirmation Order Online",
      modalMessage: {
        title: "Notification",
        titleConfirm:
          "I agree and confirm the transaction in order to be true according to my request.",
        textBody:
          "Customers here agree with the information in this Form and confirm ....  . . . .   .  .  .  .  . . . . . . . . . . . . . .",
        content:
          "Please register for Order Confirmation utility via GDDT system at MBS.",
      },
      modalConfirmExit: {
        title: "Confirm Exit",
        content: "Are you sure you want to cancel this activity?",
      },
      modalConfirmCondition: {
        title: "Sign up for Online Order Confirmation service",
        account: "Account",
        textCondition1: "I agree with",
        textCondition2: "Terms and Conditions",
        textCondition3:
          "Order confirmation via electronic transaction system at MBS.",
        messageConfirmOrderSuccess: "Confirm order success",
        messageVerifyOTPSuccess: "Verify OTP Success",
        messageVerifyOTPFailure: "Verify OTP Failure",
        sendAgain: "Send Again",
      },
      header: {
        account: "Account",
        fromDate: "From Date",
        toDate: "To Date",
        search: "Search",
        allAccount: "All Acc",
        confirmType: "Confirm Type",
        errorDayRange: "Error Day Range",
        errorDay: "The start date can not be greater than the end date",
      },
      tableContent: {
        orderDate: "Order Date",
        accountCode: "Account Code",
        side: "Side",
        shareCode: "Share Code",
        orderVolume: "Order Volume",
        showPrice: "Show Price",
        orderStatus: "Order Status",
        orderNo: "Order No",
        channels: "Channels",
        confirmType: "Confirm Type",
        confirmDate: "Confirm Date",
        confirmOrder: "Confirm Order",
        buy: "Buy",
        sell: "Sell",
        unconfimred: "Unconfirmed",
        signed: "Signed",
        confirmed: "Confirmed",
        selectAll: "Select All",
        cancelWaiting: "Cancel Waiting",
        fixWaitingFixed: "Fix Waiting/Fixed",
        matchWaiting: "Match Waiting",
        cancelled: "Cancel",
        matched: "Matched",
        partialMatch: "Partial Match",
        refuse: "Refuse",
      },
    },
    categoryFinancialService: {
      title: "Stock Portfolio",
      header: {
        serviceType: "Service Type",
        serviceTypeAll: "All",
        exchanges: "Exchanges",
        exchangesSelectAll: "All",
        shareCode: "Share Code",
        shareCodeSelectAll: "All",
        search: "Search",
      },
      tableContent: {
        numericalOrder: "ID",
        exchanges: "Exchanges",
        shareCode: "Share Code",
        shareName: "Share Name",
        marginMin: "Margin Min",
        marginMax: "Margin Max",
        marginReal: "Margin Real",
        textFooter:
          "The actual ratio of MBS lending/cooperation is being calculated on the reference price, the exact disbursement rate will depend on the purchase price of the customer.",
      },
    },
    oddLotShare: {
      tabHeader: {
        available: "Odd lot selling",
        history: "Odd lot selling hist",
      },
      tabPanel: {
        sellOddLotShare: {
          filterActions: {
            search: "Search",
            accountNumberLabel: "Account number",
            shareCodeLabel: "Symbol",
            selectAll: "All",
          },
          table: {
            header: {
              account: "ACCOUNT",
              shareCode: "SYMBOL",
              oddShare: "ODD SYMBOL",
              sellingPrice: "SELL PRINCE",
              sellValue: "SELL VALUE",
              exchange: "BOARD",
              stateShare: "STATUS",
            },
            footer:
              "Odd lot transactions for exchanges, service charges 10% of total transaction value date 23/07/2012",
            sellButton: "Sell",
            transactionSuccessfulMsg: "Transaction successful",
          },
        },
        sellOddHistory: {
          filterActions: {
            search: "Search",
            fromDateLabel: "From date",
            toDateLabel: "To date",
            accountNumberLabel: "Acc number",
            shareCodeLabel: "Symbol",
            selectAll: "All",
          },
          table: {
            header: {
              order: "ID",
              account: "ACCOUNT",
              shareCode: "SYMBOL",
              oddShare: "VOLUME",
              buyPrice: "BUY PRICE",
              buyValue: "BUY VALUE",
              buyDate: "BUY DATE",
              statusProcess: "STATUS",
            },
          },
          error: {
            dateRange: "Exceeded the 30-day search",
            dateError: "From date must be less To date",
          },
        },
      },
    },
  },
  categories: {
    placeOrderTooltip: "Double click to order",
    ticketTooltip: "Click to see more code",
    ticketDetail: "View code details",
  },
  moneyTransfer: {
    all: "All",
    equity: "Equity",
    derivative: "Derivative",
    bankTransfer: "Bank tranfer",
    internalTransfer: "Internal tranfer",
    derivativeTransfer: "Tranfer to derivative",
    depositToVSD: "Deposit to VSD",
    withdrawFromVSD: "Withdraw from VSD",
    D_CP: "Deposit",
    W_CP: "Withdraw",
    BIDVTransfer: "BIDV Transfer",
    ExternalTransfer: "Transfer out",
    CS_TO_PS: "Tranfer to derivative",
    PS_TO_CS: "Transfer MB",
    transferHistory: "Transfer history",
    createTransferRequest: "Create a money transfer request",
    fromAccount: "From account",
    toAccount: "To account",
    accountHolder: "Bank acc name",
    bank: "Bank",
    branch: "Branch",
    address: "Address",
    transferMethod: "Transfer method",
    amountsTransferable: "Amounts transferable",
    cashBalance: "Current cash",
    transferAmount: "Transfer amount",
    transferFee: "Transfer fee",
    remark: "Content",
    enterRemark: "Entter content",
    enterAmount: "Enter money",
    fastTransfer: "Fast transfer",
    normalTransfer: "Normal transfer",
    payMarginFee: "Pay fee Margin/BCC+",
    transferType: "Transfer type",
    checkDetail: "Please check the transaction details Before you action",
    petitioner: "Requester",
    idOrPassportNumber: "ID number",
    MBSAccountNumber: "Account at MBS",
    fromDate: "From date",
    toDate: "To date",
    accountType: "Account Type",
    transferStatus: "Status",
    transferOverLimit: "Transfe over limit",
    transferOverLimitDesc:
      "Amount is over limit that MBS needs to check and approve. Are you sure to execute??",
    moneyTransferSuccess: "Send your request successfully",
    transferSuccess:
      "Your request has sent to our system. Please check status request transfer",
    noRecord: "Not record",
    choseBranch: "Choose branch",
    escrow_d_cp: "Deposit",
    escrow_w_cp: "Withdraw",
    first_escrow_desc:
      "Note: Available trading time is from 8:30 to 15h45 on working days. Please pay attention when making deposit / withdrawal to limit the collateral management fee. Fee details sinh phí Quản lý tài sản ký quỹ. Chi tiết về biểu phí",
    is_here: "Here",
    from_vsd: "Withdraw fee",
    to_vsd: "Deposit fee",
    last_escrow_desc:
      "VSD (5.500) bank's fee will be deducted at your MBS account.",
    ac: "ACC",
    accountNotSupport:
      "Tài khoản Ngân hàng nhận không thuộc danh sách liên kết,  giao dịch thực hiện theo hình thức chuyển thường.",
    transferNormalMethod:
      "Giao dịch được thực hiện theo hình thức chuyển thường",
    branchNote:
      "Quý khách chọn Chi nhánh trong danh sách liên kết để chuyển tự động, trường hợp Chi nhánh NH của Quý khách không có trong danh sách vui lòng chọn “Khác”, giao dịch sẽ được xử lý thủ công.",
    calculatingTransFee: "Hệ thống đang tính phí chuyển tiền...",
    error: {
      limitAmount: "Transferred share must not exceed withdrawable available",
      notEmptyContent: "Content isn't blank",
      notEmptyAmount: "Tranfer money isn't blank",
      notEmptyBranch: "Branch isn't blank",
      invalidAmount: "Invalid transfer amount",
      invalidFeePaymentAccount:
        "Receive account pay Margin/BCC+ fee is invalid",
      doNotTransferMoreThanCashMaxInOneDay:
        "On a day, do not transfer larger amount",
      doNotTransferLargerAmounts: "do not transfer larger amount",
      lessThanMin: "Transfer amount cannot be smaller",
      remarksIsAlphanumeric:
        "Remarks cannot contain special characters and accented Vietnamese",
      errorDay: "Begin day cannot be larger than end day",
    },
    table: {
      transferDate: "Trans date",
      transferType: "Trans type",
      accountTransfer: "From ACC",
      accountReceive: "To ACC",
      inputClientName: "Tên người nhận",
      transferAmount: "Amount",
      transferFee: "Trans fee",
      remark: "Remark",
      rejectReason: "Reject reason",
      status: {
        status: "Status",
        notAccept: "Pending",
        success: "Approved",
        reject: "Reject",
        pending: "In Progress",
        DA_DUYET: "Approved",
        DANG_XU_LY: "In Progress",
      },
    },
    button: {
      confirm: "Confirm",
      cancel: "Cancel",
      search: "Search",
    },
  },
  orderBook: {
    confirm: "Confirm",
    contentConfirm: "Cancel the selected order",
    title: "Order list of the day",
    search: "Search",
    select: "Select",
    account: "Account",
    cancel: "Cancel order",
    all: "All",
    buy: "Buy",
    sell: "Sell",
    condition: "Condition Type",
    conditionType: {
      title: "Condition",
      all: "All",
      timeConditionOrder: "Time Condition Order",
      priorityOrder: "Priority Order",
      trailingStopOrder: "Trailing Stop Order",
      takeProfit: "Take Profit",
      cutLoss: "Cut Loss",
    },
    normal: "Normal",
    errorCheckCancel: "No orders selected",
    symbol: "Symbol",
    table: {
      ticker: "Symbol",
      account: "Acc",
      type: "Trans",
      status: "Status",
      volume: "Vol",
      price: "Price",
      matchVolume: "Match vol",
      matchPrice: "Match price",
      pubQty: "KL chờ",
      pubPrice: "Giá chờ",
      commandNumber: "Com number",
      timeBook: "P time",
      timeMatch: "F time",
      timeCancel: "C time",
      channel: "Channel",
      tooltipTable: "Double click to see details",
    },
    orderPending: "Lệnh trước giờ",
    tablePending: {
      secCd: "Mã CK",
      subAccoNo: "Tài khoản",
      tradeType: "Loại",
      status: {
        title: "Trạng thái",
        waitApprove: "Chờ duyệt",
        approved: "Đã duyệt",
        cancelled: "Đã hủy",
        ctckCancel: "CTCK hủy",
        noValue: "---",
      },
      ordQty: "KL đặt",
      ordPrice: "Giá đặt",
      regDateTime: "TG đặt",
      channel: "Kênh",
    },
    tableCondition: {
      tradeDate: "Trade Date",
      reqTime: "Request Time",
      channel: "Channel",
      side: "Side",
      traderId: "Trader Id",
      masterAcc: "Master Account",
      accoNo: "Account Code",
      brokerId: "Broker Id",
      secCd: "Sec Cd",
      secType: "Sec Type",
      secSubType: "Sec Sub Type",
      tradingSessionId: "Trading Session Id",
      ordQty: "Order Qty",
      ordPrice: "Order Price",
      matQty: "Mat Qty",
      cancelQty: "Cancel Qty",
      condType: "Condition Type",
      effectDate: "Effect Date",
      expiredDate: "Expired Date",
      matMethod: "Mat Method",
      optType: "Opt Type",
      optVal: "Opt Value",
      optValType: "Opt Value Type",
      condition: "Condition",
      value: "Value",
      valueActive: "Value Active",
      tooltipValueActive:
        "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
      limitOffset: "Limit Offset",
      notes: "Notes",
      clientInfo: "Client Info",
      status: "Status",
      updDateType: "Update Date Type",
      takeEffect: "Take Effect",
      complete: "Complete",
      expired: "Expired",
      cancel: "Canceled",
      generatedOneTime: "Incurred 1 time",
      matchingEnoughVolume: "Match",
      noValue: "---",
      timeConditionOrder: "Before day",
      trailingStopOrder: "Trend",
      priorityOrder: "Buy/Sell",
      takeProfit: "Take profit",
      cutLoss: "Loss",
      noData: "Not Data",
      arbitrage: "Differrence",
      optValByValue: "Value",
      optValByPercent: "%",
      regDateTime: "TG đặt",
      pubQty: "KL chờ",
      pubPrice: "Giá chờ",
      minBuyPrice: "Giá mua thấp nhất",
      maxSellPrice: "Giá bán cao nhất",
    },
    // Utils status - start
    REJECTED: "Từ chối",
    ENTERED: "Chờ gửi",
    WAIT_FILLED: "Chờ khớp",
    PARTIALLY_FILLED: "Khớp một phần",
    FILLED: "Khớp toàn bộ",
    PARTIALLY_CANCELLED: "Hủy một phần",
    CANCELLED: "Hủy toàn bộ",
    REJECTED_DEPARTMENT: "Sở từ chối",
    WAIT_ACTIVE: "Chờ kích hoạt",
    EFFECT: "Hiệu lực",
    FINISHED: "Đã kết thúc",
    EXPIRE: "Hết hiệu lực",
    CANCELED: "Đã hủy",
    // Utils status - end
    BUY: "Buy",
    BUY_OUTR: "Buy OutR",
    SELL: "Sell",
    SELL_OutR: "Sell OutR",
    SELL_TT68: "Sell TT68",
    SELL_FORCE: "Sell Force",
    SELL_FORCE_OutR: "Sell Force OutR",
    SELL_FORCE_TT68: "Sell Force TT68",
    orderSuccess: "Order success",
    orderError: "An error occurred. Please check again",
    editCommand: "Edit oder",
    undefined: "Undefined",
  },
  orderBasket: {
    filter: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        all: "All",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
    },
    table: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
      commandType: {
        title: "Command type",
        normal: "Normal",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Volume",
      price: "Price",
    },
    titleButtonBuy: "ORDER BASKET (BUY)",
    titleButtonSell: "ORDER BASKET (SELL)",
    cancel: "Cancel",
    placeOrder: "Place order",
    orderCreateSuccess: "Create order basket success",
    orderDeleteError: "Delete order basket failed",
    confirmDelete: "Confirm cancel order",
    contentConfirmDelete: "You definitely want to cancel the order",
    confirmActive: "Confirm active order",
    contentConfirmActive: "You definitely want to active the order",
    cancelSuccess: "Cancel order basket success",
    cancelFail: "Cancel order basket failed",
    activeSuccess: "Active order basket success",
    activeFailed: "Active order basket failed",
    createSuccess: "Create order basket success",
    createFailed: "Create order basket failed",
    errorCheckCancel: "No lines selected to cancel",
    errorCheckActive: "No lines selected to active",
    orderError: "Order basket error",
  },
  orderBasketDer: {
    filter: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        all: "All",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
    },
    table: {
      account: "Account",
      tradeType: {
        title: "Trade type",
        buy: "Buy",
        sell: "Sell",
      },
      symbol: "Symbol",
      commandType: {
        title: "Command type",
        normal: "Normal",
        outR: "OUTR",
        tt68: "TT68",
      },
      volume: "Volume",
      price: "Price",
    },
    titleButtonLong: "ORDER BASKET (LONG)",
    titleButtonShort: "ORDER BASKET (SHORT)",
    cancel: "Cancel",
    placeOrder: "Place order",
    orderError: "Order basket der error",
  },
  error: errorsEn,
  validation: {
    emptyAccount: "Please select an account",
    emptyTicker: "Please select symbol",
    emptyTradeType: "Please select trade type",
    emptyPrice: "Please select a price",
    emptyStopPrice: "Please enter activation price",
    emptyStopLoss: "Please enter activation stop loss",
    emptyProfit: "Please enter activation profit",
    restrictedTrading: "Code suspended / restricted transactions",
    rangePrice: "Price must be between Floor and Ceiling",
    patternPrice: "Inval set price (step price 0.1)",
    patternStopPrice: "Giá kích hoạt không hợp lệ",
    patternStopLoss: "Giá cắt lỗ không hợp lệ",
    patternProfit: "Giá chốt lãi không hợp lệ",
    patternMargin: "Giá biên trượt không hợp lệ",
    emptyVolume: "Please select volume",
    emptyOtpVal: "Please input otp value",
    emptyLimitOffset: "Please input limit offset",
    patternVolume: "The volume is not round lot (Lot 100)",
    maxVolumeSell: "Quantity is over max number",
    maxVolumeBuy: "Quantity is over max quantity",
    maxVolumeFAL: "Quantity is over maximum FAL volume",
    overRoom: "Quantity is over remain room",
    patternMaxLongVolume: "Volume exceeds max Long volume",
    patternMaxShortVolume: "Volume exceeds max Short volume",
    activePriceUp: "Trigger price have to be larger than market price",
    activePriceDown: "Trigger price have to be smaller than market price",
    emptyActivePrice: "Vui lòng nhập giá kích hoạt",
    emptyCutLostAmp: "Vui lòng nhập biên độ cắt lỗ",
    emptyGrossAmp: "Vui lòng nhập biên độ chốt lãi",
    emptyCutLostPrice: "Vui lòng nhập giá cắt lỗ",
    emptyRangeStop: "Vui lòng nhập giá khoảng dừng",
    emptyMargin: "Please enter slip margin",
    emptyMinBuyPrice: "Vui lòng nhập giá mua thấp nhất",
    emptyMaxSellPrice: "Vui lòng nhập giá bán cao nhất",
    takeProfilePrice_Error_001: "Price take profit big than price",
    takeProfilePrice_Error_002: "Price take profit less than celling",
    takeProfilePrice_Error_003: "Price take profit less than price",
    takeProfilePrice_Error_004: "Price take profit big than floor",
    stopLotPrice_Error_001: "Price cut loss less than price",
    stopLotPrice_Error_002: "Price cut loss big than floor",
    stopLotPrice_Error_003: "Price cut loss plus sliding big celling",
    stopLotPrice_Error_004: "Price cut loss big than price",
    stopLotPrice_Error_005: "Price cut loss less than celling",
    stopLotPrice_Error_006: "Price cut loss plus sliding not big celling",
    stopLotPrice_Error_007: "Price cut loss different set price,",
    stopLotPrice_Error_008: "Price cut loss big than market price",
    stopLotPrice_Error_009: "Price cut loss plus sliding less than celling",
    stopLotPrice_Error_010: "Price cut loss less than market price",
    stopLotPrice_Error_011: "Price cut loss minus sliding not less than floor",
  },
  formPlaceholder: {
    inputAvgPrice: "Enter Price Avg",
    inputOptValByValue: "Enter Value",
    inputOptValByPercent: "Enter Percent",
    inputLimitOffset: "Enter LimitOffset",
    inputPrice: "Enter price",
    inputVolumne: "Enter volume",
    inputValue: "Enter value",
  },
  sidebar: {
    getOTP: "Get OTP",
    categories: "BẢNG GIÁ",
    marketUp: "THỊ TRƯỜNG",
    market: "Thị trường",
    stockOrder: "LỆNH CƠ SỞ",
    placeOrderDer: "LỆNH PHÁI SINH",
    asset: "TÀI SẢN",
    basicServices: "DỊCH VỤ CƠ BẢN",
    financialServices: "DV TÀI CHÍNH",
    settings: "Cài đặt",
    orderBook: "Order List",
    orderHistory: "Order history",
    moneyTransfer: "Cash transfer",
    advance: "Advance",
    coveredWarrant: "Covered warrant",
    proInvestor: "Pro Investor",
    loanExtension: "Cash transaction",
    marginLimit: "Margin limit",
    screeners: "Screeners",
    DVTC_NAV: "Property report",
    infoWarrant: "Register for the right to buy",
    disableMenu: "Dis/able menu",
    support: "Support",
    businessCooperation: "Business cooperation",
    extends: {
      title: "Extends",
      profitLoss: "Profit loss equity/der",
      confirmOrderOnline: "Confirmation order online",
      categoryFinancialService: "Category financial service",
      statement: "Statement",
      oddLotShare: "Buy odd-lot share",
    },
    transferShare: "Transfer Share",
    contractDebit: "Contract Debit",
    packServices: "Pack Services",
    extendsOne: "Extends One",
    extendsTwo: "Extends Two",
    extendsThree: "Extends Three",
    investmentEfficiency: "Investment Efficiency",
    techAnalysis: "Technical Analystic",
    expandMenu: "Expand",
    collapseMenu: "Collapse",
    marginContract: "Margin Contract",
    accountInfo: "Account Info",
    marginSecurities: "Margin Securities List",
    rightInfo: "Right Info",
    statisticOnday: "Statistic Onday",
    statisticPrice: "Statistic Price",
    statisticUpdown: "Statistic Updown",
    statisticHighestUpdown: "Statistic Highest Updown",
    mrgContractDebit: "Margin Contract Debit",
    marginContractWatch: "Margin Contract Watch",
    transferLocal: "Chuyển khoản tiền",
    profitLoss: "Lãi lỗ thực hiện",
    holdUnholdBankAccount: "Tiện ích ngân hàng",
    confirmation: "Xác nhận lệnh Online",
    createContract: "Hợp đồng khung",
    depositContract: "Đề nghị cho vay",
    transferBond: "Xác nhận chuyển nhượng trái phiếu",
    accountStatementSideBar: "Sao kê tài khoản",
    marginSecuritiesPortfolio: "Danh mục chứng khoán ký quỹ",
    depositPower: "Liên kết sức mua",
    manegerMoney: "Quản lý tiền TKCK",
    pMoney: "Quản lý tiền TKCK tự động",
    profitChart: "Biểu lãi suất quản lý tiền",
    addMhPositionManagement: "Quản lý vị thế",
    subscribeBuy: "Đăng ký mua phát hành thêm",
    bidAskBondOrder: "Chào mua/Chào bán Trái phiếu",
    depositListPMoney: "Danh sách gửi tiền",
    addFrameworkContractFormSideBar: "Hợp đồng Khung QLT TKCK tự động",
    profitMoney: "Biểu lãi quản lý tiền TKCK tự động",
    subAccoRegister: "Đăng ký tiểu khoản gửi tiền TKCK tự động",
    transferHistory: "Lịch sử chuyển nhượng TPDN",

    bidAskBond: "Sổ lệnh chào mua/bán trái phiếu",
    assetsIncurred: "Báo cáo tài sản",
    bonds: "Trái phiếu",
    placeOrderToTransferBonds: "Place order to transfer bonds",
    registerToTransferBonds: "Đăng ký chuyển nhượng trái phiếu",
    addMhChangeLimit: "Change Limit",
    professionalInvestor: "Nhà đầu tư chuyên nghiệp",
    placeOrderRetailBonds: "Đặt lệnh trái phiếu bán lẻ",
    bidAskDBondOrder: "Đặt lệnh trái phiếu D-BOND",
    placeOrderBondAdv: "Đặt lệnh quảng cáo Trái phiếu",
    advertisingOrder: "Sổ lệnh quảng cáo",
    guideVideo: "Đào tạo và hướng dẫn sử dụng",
  },
  stockOrderBoard: {
    chooseABoard: "Choose a Board",
    all: "All",
    default: "Default",
    customized: "Customized",
    newBoard: "New Board",
    descNewBoard: "Adding widggets to an blank board.",
    standard: "Standard",
    descStandard: "For basic and new trader.",
    standard2: "Standard2",
    widgetsSettings: "Widgets Settings",
    suggestionsWidgetsSettings: "Select and drag and drop and board",
    addDisplayArea: "Add display area",
    hoverOverTheAreaToAdd: "Hover over the area to add",
    viewDoesNotExist: "View does not exist",
    tab: {
      title: "Tab",
      open: "Open",
      rename: "Rename",
      saveTheTemplate: "Save the template",
    },
    modalSaveBoard: {
      titleModal: "Save as a board",
      nameBoard: "Name board",
      description: "Description",
      cancel: "Cancel",
      save: "Save",
    },
    modalRenameBoard: {
      titleModal: "Rename Board",
      ok: "OK",
      cancel: "Cancel",
    },
  },
  stockOrderDerBoard: {
    all: "All",
    default: "Default",
    customized: "Customized",
    addingWidggets: "Adding widggets to an blank board",
    blank: "Blank",
    DefaultStock: "Adding widggets to an blank board",
    normal: "Normal",
    multiTrades: "Multi-trades",
    MultiChart: "Multi chart or trades board",
    addDisplaytitle: "Add display area<br>(Hover over the area to add)",
    saveBoard: "SAVE AS A BOARD",
    boardName: "Name board",
    description: "Description",
    changeBoardName: "Rename board",
    chooseBoard: "Choose a Board",
    open: "Open",
    changeName: "Rename",
    save: "Save the template",
    noView: "View does not exist",
  },

  advance: {
    filter: {
      account: "Account",
      fromDate: "From date",
      toDate: "To date",
      subAccount: "SubAccount",
      search: "Search",
      advancePurpose: "Purpose Advance",
      purpose: {
        buy: "Advance to buy",
        withdraw: "Advance to withdraw",
      },
    },
    subAccount: "Sub Account",
    advanceHistory: "Advance history",
    advanceMoney: "Advance",
    fromDate: "From date",
    toDate: "To date",
    advancePurpose: "Purpose Advance",
    account: "Account",
    overDate: "The start date cannot be greater than the end date",
    lengthDate: "Exceeded the 30-day search",
    success: "Advance successful",
    button: {
      confirm: "Comfirm",
      cancel: "Cancel",
      search: "Search",
      add: "Add account",
    },
    table: {
      index: "ID",
      contractID: "Contract number",
      account: "ACC",
      accountHolder: "ACC HOLDER",
      soldDate: "SELL DATE",
      paymentDate: "PAYMENT DATE",
      advanceDate: "ADV DATE",
      amount: "AMOUNT",
      advanceFree: "ADV FREE",
      advanceSource: "ADVAN SOURCE",
      receiveAccount: "RECEIVE ACC",
      cancel: "CANCEL",
      content: "CONTENT",
      status: {
        status: "STATUS",
        success: "APPROVED",
        reject: "PENDING",
        pending: "IN PROGRESS",
        end: "END",
        cancel: "CANCEL",
        collected: "COLLECTED MONEY",
        debtCollect: "DEBT COLLECT",
      },
    },
    message: {
      amountCheck1: "Vui lòng nhập số tiền ứng",
      amountCheck2: "Số tiền ứng phải lớn hơn 0",
      amountCheck3: "Số tiền ứng vượt quá số tiền có thể ứng",
      amountCheck4:
        "Số tiền ứng vượt quá hạn mức còn lại của công ty. Quý KH vui lòng liên hệ 024 39343 888 để được hướng dẫn",
      amountCheck5: "Phí ứng bằng 0",
      checkErr1: "Số tiền ứng tối thiểu là {number}",
      checkErr2: "Số tiền ứng tối đa là {number}",
      checkErr3:
        "Hạn mức ứng trước còn lại của khách hàng là {number}. Quý KH vui lòng liên hệ 024 39343 888 để được hướng dẫn",
    },
    confirmModal: {
      title: "Confirm",
      subAccountFrom: "Sub Account From",
      subAccountTo: "Sub Account To",
      withdrawable: "Advance amount",
      interestRate: "Interest Rate",
      provisionalFee: "Fee estimate",
      labelCheckBox:
        "Không hiển thị lại vào lần sau. Bạn có thể bật lại chức năng này ở Cài đặt giao dịch",
      remarks: "Note",

      accept: "Chấp nhận",
      cancel: "Hủy",
    },
    advanceAccount: "Account",
    subAccoNoOut: "Choose a account",
    note: "Note",
    totalSaleAmount: "Total sell amount",
    advancedMoney: "Advance amount + fees",
    advanceableMoney: "Advance available",
    withdrawable: "Advance amount",
    interestRate: "Interest rate",
    provisionalFee: "Fee estimate",
    enterAmount: "Enter amount",
    addAccount: "+ Add account",
    error: {
      noContent: "Please enter the amount",
      overPriced: "Amount is over available",
    },
    accountReceiver: {
      header: "Add account",
      id: "Number account",
      owner: "Acc holder",
      bank: "Bank",
      city: "Bank province",
      branch: "Branch",
      description:
        "Services Loan Bank advances MB provided with preferential interest rate of 8% / year.",
      register: "Register now here.",
      input: {
        id: "Enter account",
        owner: "Enter account holder name",
        bank: "Enter bank",
        city: "Enter bank province",
        branch: "Enter branch",
      },
    },
  },
  depositPower: {
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        available: "Đang hiệu lực",
        liquidated: "Đã thanh lý",
      },
    },
    title: {
      depositPowerRegister: "Đăng ký liên kết sức mua",
      depositPowerList: "Danh sách liên kết sức mua",
    },

    table: {
      openDate: "Ngày mở HĐ",
      contrNo: "Số HĐ",

      expiredDate: "Ngày kết thúc",
      status: "Trạng thái",
      remarks: "Ghi chú",

      regDateTime: "Thời gian tạo",
      liquidation: "Thanh lý",
    },
    create: {
      account: "Tài khoản",
      accountName: "Tên tài khoản",

      remarks: "Ghi chú",

      placeholderRemarks: "Nhập nội dung",
      mess1:
        "Tôi đã đọc và đồng ý với Điều kiện và điều khoản đã nêu trong {link}",
      mess2: "Phụ lục liên kết sức mua",
      confirm: "Xác nhận",
    },
    status: {
      waitApprove: "Chờ duyệt",
      available: "Đang hiệu lực",
      cancelApprovedWait: "Đóng chờ duyệt",
      liquidated: "Đã thanh lý",
    },
    message: {
      liquidateContent: "Bạn có chắc chắn muốn thanh lý Liên kết sức mua ?",
      depositPowerRegisterContent: "Đăng ký liên kết sức mua thành công",
    },
    confirmModal: {
      accept: "Xác nhận",
      cancel: "Hủy",
    },
  },
  createContract: {
    filter: {
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        available: "Đang hiệu lực",
        liquidated: "Đã thanh lý",
      },
    },
    title: {
      createContract: "Tạo hợp đồng khung",
      contractList: "Danh sách hợp đồng",
    },

    table: {
      openDate: "Ngày mở hợp đồng",
      contrNo: "Số hợp đồng",
      collab: "CTV giới thiệu",
      expiredDate: "Ngày kết thúc",
      status: "Trạng thái",
      remarks: "Ghi chú",
      transaction: "Điểm giao dịch",
      regDateTime: "Thời gian tạo",
      liquidation: "Thanh lý",
      edit: "Sửa",
    },
    create: {
      account: "Tài khoản",
      accountName: "Tên tài khoản",
      collab: "CTV giới thiệu",
      remarks: "Ghi chú",
      placeholderCollab: "Nhập CTV",
      placeholderRemarks: "Nhập nội dung",
      mess1:
        "Tôi đã đọc và đồng ý với Điều kiện và điều khoản đã nêu trong {link}",
      mess2: "Hợp đồng Quản lý tiền Tài khoản chứng khoán",
      confirm: "Xác nhận",
    },
    status: {
      waitApprove: "Chờ duyệt",
      available: "Đang hiệu lực",
      cancelApprovedWait: "Đóng chờ duyệt",
      liquidated: "Đã thanh lý",
    },
    message: {
      liquidateContent: "Bạn có chắc chắn thanh lý hợp đồng khung ?",
      createContractContent: "Tạo hợp đồng khung thành công",
    },
    confirmModal: {
      accept: "Xác nhận",
      cancel: "Hủy",
    },
  },
  bidAskBond: {
    filter: {
      tradeType: "Mua/Bán",
      bondCd: "Mã TP",

      search: "Tìm kiếm",
      tradeList: {
        all: "Tất cả",
        sell: "Bán",
        buy: "Mua",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Đã từ chối",
        cancel: "Đã hủy",
      },
    },
    all: "TẤT CẢ",
    all2: "Tất cả",
    title: {
      bidAskBond: "Sổ lệnh chào mua/bán trái phiếu",
    },

    table: {
      account: "Tài khoản",
      action: "Thao tác",
      tradeType: "Mua/bán",
      bondCd: "Mã tp",

      status: "Trạng thái",
      qty: "Khối lượng",
      amount: "Giá chào",
      expiratedDate: "Ngày hết hạn",
      referencePrices: "Giá tham chiếu",
      buy: "Mua",
      sell: "Bán",
      unknown: "Không xác định",
      sellMortage: "Bán cầm cố",
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      pending: "Đang thanh toán",
      complete: "Hoàn thành",
      rejected: "Đã từ chối",
      cancel: "Đã hủy",
    },
    message: {
      successAlert: "Xác nhận lệnh thành công",
      successAlert2: "Từ chối lệnh thành công",
      cancelContent: "Bạn có chắc chắn muốn thực hiện từ chối ?",
    },
    confirmModal: {
      title: "Xác nhận",
      tradeType: "Loại lệnh",
      tradeList: {
        sell: "Bán",
        buy: "Mua",
        unknown: "Không xác định",
        sellMortage: "Bán cầm cố",
      },

      bondCd: "Mã TP",
      qty: "Khối lượng",
      price: "Giá",
      orderPrice: "Giá trị lệnh",
      fee: "Phí",
      tax: "Thuế",
      feePayer: "Người trả phí",
      taxPayer: "Người trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },
      cancel: "Hủy",
      confirm: "Nhập lệnh",
    },
  },
  professionalInvestor: {
    title: "Nhà đầu tư chuyên nghiệp",
    statusList: {
      registered: "Đã đăng ký",
      unRegistered: "Chưa đăng ký",
    },
    userId: "Số TK KH",
    name: "Họ tên",
    status: "Trạng thái ĐK",
    expiredDate: "Ngày hết hạn",
    cancel: "Đóng",
  },
  bidAskDBondOrder: {
    filter: {
      tradeType: "Mua/Bán",
      bondCd: "Mã TP",

      search: "Tìm kiếm",
      tradeList: {
        all: "Tất cả",
        sell: "Bán",
        buy: "Mua",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Hủy",
      },
    },
    title: {
      orderBook: "Sổ lệnh",
      bondList: "Danh sách trái phiếu",
      orderData: "Thông tin lệnh",
    },

    table: {
      tradingDate: "Ngày GD",
      cancel: "Hủy",

      bondCd: "Mã TP",
      tradeType: "Mua/bán",
      traderList: {
        unknown: "Không xác định",
        sell: "Bán",
        buy: "Mua",
        sellMortage: "Bán cầm cố",
      },

      qty: "Khối lượng",
      amount: "Giá",
      investValue: "Giá trị đầu tư",
      fee: "Phí",
      feePayer: "Đối tượng trả phí",
      tax: "Thuế",
      taxPayer: "Đối tượng trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },

      status: "Trạng thái",
      statusList: {
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Hủy",
      },

      maturityDate: "Ngày đáo hạn",

      coupon: "Coupon",

      buyPrice: "Giá mua",
      buyVol: "Hạn mức mua",
      sellPrice: "Giá bán",
      sellVol: "Hạn mức bán",
      action: "Thao tác",

      buy: "Mua",
      sell: "Bán",
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      pending: "Đang thanh toán",
      complete: "Hoàn thành",
      rejected: "Đã hủy",
    },
    message: {
      deleteContent: "Bạn có chắc chắn muốn hủy lệnh {number} không ?",
      deleteSuccess: "Hủy lệnh thành công",
      confirmContent: "Bạn có chắc chắn muốn đặt lệnh ?",
      success: "Đặt lênh thành công",

      checkErr3: "Chưa nhập mã trái phiếu",
      checkErr4: "Chưa nhập mã trái phiếu",

      checkErr7: "Chưa nhập khối lượng",
      checkErr8: "Khối lượng phải lớn hơn 0",

      checkErr10: "Số lượng bán không được vượt quá số lượng hiện có",
    },
    create: {
      account: "Tài khoản",
      buy: "MUA",
      sell: "BÁN",

      bondCd: "Mã TP",
      qty: "Khối lượng",
      amount: "Giá",

      maxPrice: "Tối đa",

      fee: "Phí",
      purchaseAbility: "Sức mua",
      tax: "Thuế",
      totalAmount: "Tổng tiền",
      issuerBond: "Tổ chức phát hành",
      releaseDate: "Ngày phát hành",
      expiratedDate2: "Ngày đáo hạn",
      price: "Mệnh giá",
      coupon: "Tỉ lệ coupon",
      period: "Kỳ trả lãi",
      description: "Mô tả",
      reset: "BỎ QUA",
      confirm: "Mua",
    },
    confirmModal: {
      title: "Xác nhận",

      cancel: "Hủy",
      confirm: "Nhập lệnh",
    },
  },
  bidAskBondOrder: {
    filter: {
      tradeType: "Mua/Bán",
      bondCd: "Mã TP",

      search: "Tìm kiếm",
      tradeList: {
        all: "Tất cả",
        sell: "Bán",
        buy: "Mua",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Đã từ chối",
      },
    },
    title: {
      orderBook: "Sổ lệnh",
      bondList: "Danh sách trái phiếu",
      orderData: "Thông tin lệnh",
    },

    table: {
      tradingDate: "Ngày GD",
      cancel: "Hủy",
      account: "Tài khoản",
      contraAccount: "Tài khoản đối ứng",

      bondCd: "Mã TP",
      tradeType: "Mua/bán",
      traderList: {
        unknown: "Không xác định",
        sell: "Bán",
        buy: "Mua",
        sellMortage: "Bán cầm cố",
      },

      qty: "Khối lượng",
      amount: "Giá chào",
      orderPrice: "Giá trị lệnh",
      expiredDate: "Ngày hết hạn",
      fee: "Phí",
      feePayer: "Đối tượng trả phí",
      tax: "Thuế",
      taxPayer: "Đối tượng trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },

      status: "Trạng thái",
      statusList: {
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        pending: "Đang thanh toán",
        complete: "Hoàn thành",
        rejected: "Đã từ chối",
      },
      regUserId: "Người tạo",
      alloDate: "Ngày tạo",

      releaseDate: "Ngày phát hành",
      maturityDate: "Ngày đáo hạn",
      price: "Mệnh giá",
      coupon: "Coupon",

      referencePrices: "Giá tham chiếu",
      action: "Thao tác",

      buy: "Mua",
      sell: "Bán",
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      pending: "Đang thanh toán",
      complete: "Hoàn thành",
      rejected: "Đã từ chối",
    },
    message: {
      deleteContent: "Bạn có chắc chắn muốn hủy lệnh {number} không ?",
      deleteSuccess: "Hủy lệnh thành công",
      confirmContent: "Bạn có chắc chắn muốn đặt lệnh ?",
      success: "Đặt lênh thành công",
      maxLengthCheck: "Độ dài tài khoản đối ứng là 10 ký tự",
      checkErr1: "Chưa nhập tài khoản đối ứng",
      checkErr2: "Chưa nhập tài khoản đối ứng",
      checkErr3: "Chưa nhập mã trái phiếu",
      checkErr4: "Chưa nhập mã trái phiếu",
      checkErr5: "Chưa nhập giá chào",
      checkErr6: "Giá chào phải lớn hơn 0",
      checkErr7: "Chưa nhập khối lượng",
      checkErr8: "Khối lượng phải lớn hơn 0",
      checkErr9: "Chưa nhập ngày hết hạn",
      checkErr10: "Số lượng bán không được vượt quá số lượng hiện có",
      checkErr11: "Tài khoản đối ứng trùng tài khoản Mua/Bán",
    },
    create: {
      account: "Tài khoản",
      buy: "Mua",
      sell: "Bán",
      contraAccount: "Tài khoản đối ứng",
      contraAccountName: "Tên tài khoản đối ứng",
      bondCd: "Mã TP",
      qty: "Khối lượng",
      amount: "Giá chào",
      feePayer: "Người trả phí",
      taxPayer: "Người trả thuế",
      payer: {
        sell: "Bên bán",
        buy: "Bên mua",
      },
      expiratedDate: "Ngày hết hạn",

      maxPrice: "Tối đa",
      referencePrice: "Giá TC",
      orderPrice: "Giá trị lệnh",

      fee: "Phí",
      purchaseAbility: "Sức mua",
      tax: "Thuế",
      issuerBond: "Tổ chức phát hành",
      releaseDate: "Ngày phát hành",
      expiratedDate2: "Ngày đáo hạn",
      price: "Mệnh giá",
      coupon: "Tỉ lệ coupon",
      period: "Kỳ trả lãi",
      description: "Mô tả",
      reset: "Nhập lại",
      confirm: "Mua",
    },
    confirmModal: {
      title: "Xác nhận",
      // tradeType: "Loại lệnh",
      // tradeList: {
      //   "1": "Bán",
      //   "2": "Mua",
      // },

      // bondCd: "Mã TP",
      // qty: "Khối lượng",
      // price: "Giá",
      // orderPrice: "Giá trị lệnh",
      // fee: "Phí",
      // tax: "Thuế",
      // feePayer: "Người trả phí",
      // taxPayer: "Người trả thuế",
      // payer: {
      //   "1": "Bên bán",
      //   "2": "Bên mua",
      // },
      cancel: "Hủy",
      confirm: "Nhập lệnh",
    },
  },
  transferBond: {
    filter: {
      fromDate: "Ngày tạo",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        wait_approve: "Chờ xác nhận",
        confirmed: "Đã xác nhận",
        rejected: "Từ chối",
        deleted: "Đã xóa",
      },
    },
    title: {
      transferBond: "Xác nhận chuyển nhượng trái phiếu",
    },
    table: {
      confirmReject: "Xác nhận/Từ chối",
      createDate: "Ngày tạo",
      status: "Trạng thái",
      accountTransfer: "TK chuyển",
      accountName: "Tên tk chuyển",
      bondCode: "Mã tp",
      qty: "Khối lượng",
      amount: "Giá",
      amountByPrice: "Giá trị theo mệnh giá",
      transferAmount: "Giá trị chuyển nhượng",
      fee: "Phí chuyển nhượng",
      feePay: "Đối tượng trả phí",
      taxValue: "Thuế TNCN",
      taxPay: "Đối tượng trả thuế",
      remarks: "Ghi chú",
      confirm: "Xác nhận",
      cancel: "Từ chối",
      payer: {
        receive: "Bên nhận",
        transfer: "Bên chuyển",
      },
      payer2: {
        receive: "Người nhận",
        transfer: "Người chuyển",
      },
    },

    status: {
      wait_approve: "Chờ xác nhận",
      confirmed: "Đã xác nhận",
      rejected: "Từ chối",
      deleted: "Đã xóa",
    },
    message: {
      cancelContent:
        "Bạn có chắc muốn từ chối yêu cầu chuyển nhượng TP {number} số lượng {number2} không ?",
    },
    confirmModal: {
      title: "Chuyển nhượng trái phiếu",
      fromSubAccoNo: "Bên chuyển",
      fromCustName: "Tên TK chuyển",
      toSubAccoNo: "Bên nhận",
      toCustName: "Tên TK nhận",
      bondCode: "Mã TP",
      qty: "Khối lượng",
      amount: "Giá",
      amountByPrice: "GT theo mệnh giá",
      transferAmount: "GT chuyển nhượng",
      fee: "Phí",
      feePay: "Người trả phí",
      taxValue: "Thuế",
      taxPay: "Người trả thuế",
      cancel: "Hủy",
      confirm: "Xác nhận",
    },
  },
  depositContract: {
    filter: {
      subAccount: "Tiểu khoản",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",

      search: "Tìm kiếm",
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        available: "Đang hiệu lực",
        turnOver: "Đã quay vòng",
        cancel: "Đã kết thúc",
      },
    },
    title: {
      depositContract: "Đề nghị cho vay",
      depositContractList: "Quản lý đề nghị cho vay",
    },

    table: {
      loanDate: "Ngày vay",
      subAccount: "Tiểu khoản",
      contrNo: "Số hợp đồng",
      amount: "Số tiền cho vay",
      balance: "Số tiền còn lại",
      period: "Kỳ hạn",
      interest: "Lãi suất thực nhận",

      status: "Trạng thái",
      periodType: {
        day: "Ngày",
        month: "Tuần",
        year: "Tháng",
      },
      calculateType: {
        day: "Ngày",
        month: "Tháng (30 ngày)",
        year1: "Năm (360 ngày)",
        year2: "Năm (365 ngày)",
        year3: "Năm (365/360 ngày)",
      },
      liquidation: "Thanh lý",
      detail: "Chi tiết",
    },
    create: {
      subAccount: "Tiểu khoản",
      amount: "Số tiền cho vay",
      avaiable: "Khả dụng",
      availableBalance: "Khả dụng",
      periodType: "Loại kỳ hạn",
      period: "Kỳ hạn",
      loanDate: "Ngày vay",
      dueDate: "Ngày đáo hạn",
      loanInterest: "Lãi suất vay (%)",
      interest: "Lãi thực nhận (%)",
      dayCountConvention: "Cơ sở tính lãi",
      loanEstimate: "Tiền lãi dự tính",
      turnOverType: "Hình thức quay vòng",
      remarks: "Nội dung",
      placeholderRemarks: "Nhập nội dung",
      confirm: "Thực hiện",
      periodTypeOption: {
        date: "Ngày",
        month: "Tháng",
        year: "Năm",
      },
      periodTypeLabel: "Chọn loại kỳ hạn",
      periodLabel: "Chọn kỳ hạn",
      turnOverTypeOption: {
        manual: "Không tự động quay vòng",
        auto: "Tự động quay vòng gốc",
      },
      turnOverTypeLabel: "Chọn hình thức quay vòng",
    },
    status: {
      all: "Tất cả",
      available: "Đang hiệu lực",
      turnOver: "Đã quay vòng",
      cancel: "Đã kết thúc",
    },
    message: {
      confirmContent:
        "Bạn có chắc chắn muốn thực hiện thanh lý đề nghị {contractNo} ?",
      confirmContent2: "Bạn có chắc chắn muốn thực hiện tạo đề nghị cho vay ?",
      periodCheck: "Chưa chọn kỳ hạn",
      periodTypeCheck: "Chưa chọn loại kỳ hạn",
    },
    liquidateModal: {
      paymentDate: "Ngày thanh lý",
      amount: "Số tiền đã thanh lý",
      currentAmt: "Số tiền còn lại",
      grossRate: "Lãi suất thực nhận",
      payedInterest: "Tiền lãi đã trả",
      accept: "Thanh lý",
    },
    detailModal: {
      endDate: "Ngày gia hạn",
      payedInterestAmt: "Tiền lãi đã trả",
      amount: "Số tiền gia hạn",
    },
    confirmModal: {
      title: "Thanh lý đề nghị",
      subAccoNo: "Tiểu khoản",
      contractNo: "Số ĐNCV",
      amount: "Số tiền cho vay",
      balance: "Số tiền còn lại",
      fromDate: "Ngày cho vay",
      tradeDate: "Ngày thanh lý",
      countLoanDay: "Số ngày cho vay",
      amountLiquidateL: "Số tiền thanh lý",
      grossRate: "Lãi suất thanh lý trước hạn (%)",
      netRate: "Lãi suất thanh lý thực nhận (%)",
      interest: "Số tiền lãi thực nhận",
      accept: "Thực hiện",
      cancel: "Hủy",
    },
    modal: {
      label1: "Số tiền cho vay",
      label2: "Kỳ hạn",
      label3: "Hình thức gia hạn",
    },
  },

  tableInfinityScroll: {
    tableContent: {
      loadMore: "Loading",
      loadEnd: "No new data",
      nothing: "Nothing",
    },
  },
  orderBookDer: {
    secCd: "Symbol",
    subAccoNo: "Sub Account",
    tradeType: "Trans",
    ordType: "Type",
    extStatus: "Status",
    orderQty: "Mass",
    price: "Price",
    matQty: "Match mass",
    avgPrice: "Price match BQ",
    pubQty: "Pub Qty",
    pubPrice: "Pub Price",
    orderNo: "SHL",
    activePrice: "Giá kích hoạt",
    cutLostAmp: "Biên độ cắt lỗ",
    limitOffset: "Chênh lệch",
    grossAmp: "Biên độ chốt lãi",
    cutLostPrice: "Giá cắt lỗ",
    rangeStop: "Khoảng dừng",
    createdTime: "Placed time",
    matchTime: "Filled time",
    cancelTime: "Cancel time",
    channel: "Channel",
    Normal: "Normal",
    UP: "UP",
    DOWN: "Down",
    TUP: "T Up",
    TDOWN: "T Down",
    BULLBEAR: "Bull&Bear",
    OCO: "OCO",
    inputHD: "Enter HD",
    search: "Search",
    comfirmTitle: "Confirm Order Cancellation",
    comfirmMessage: "Are you sure you want to cancel this order",
    accept: "Accept",
    cancel: "Cancel",
    Updated: "Edited",
    PedingUpdate: "Pending edit",
    Waiting: "Pending",
    WaitingCancel: "Pending cancel",
    RejectUpdate: "Reject edit",
    RejectCancel: "Reject cancel",
    noValue: "-",
    cancelSucces: "Cancel successfully",
    cancelFail: "Cancel fail",
    noAccoDer: "Không có tài khoản phái sinh",
    condType: {
      title: "Loại điều kiện",
      orderUp: "UP",
      orderDown: "DOWN",
      ifDoneStop: "IF DONE STOP",
      ifDoneGain: "IF DONE GAIN",
      orderOco: "BULL & BEAR",
      orderBullBear: "OCO",
      orderTS: "TRAILING STOP",
    },
    orderStatusDerCond: {
      waitActive: "Chờ kích hoạt",
      finished: "Đã kết thúc",
      expire: "Hết hiệu lực",
      cancelled: "Đã hủy",
    },
  },
  OrderBookDerDetail: {
    title: "Details",
    tradeNumber: "Order No",
    account: "Account",
    status: "Status",
    tradeType: "Type",
    pubQty: "KL chờ",
    pubPrice: "Giá chờ",
    orderTime: "Order time",
    activeTime: "Active time",
    orderVolumn: "Order QTY",
    orderPrice: "Order price",
    stopLoss: "Stop Price",
    stopPrice: "Cut loss price",
    margin: "Sliding",
    activePrice: "Giá kích hoạt",
    limitOffset: "Biên trượt",
    grossAmp: "Biên độ chốt lãi",
    cutLostAmp: "Biên độ cắt lỗ",
    cutLostPrice: "Giá cắt lỗ",
    rangeStop: "Khoảng dừng",
    adjustableStopLossPrice: "Cut loss price controled",
    matchingVolume: "Matched QTY",
    stopPrice2: "Price take profit",
    matchingValue: "Matched price",
    cancelTime: "Cancel time",
    cancelVolume: "Cancel QTY",
    chanel: "Order chanel",
    cancelSuccessMessage: "CANCEL successful",
  },
  orderHistory: {
    tabs: {
      orderHistoryTab: "Lịch sử lệnh cơ sở",
      orderHistoryDerTab: "Lịch sử lệnh phái sinh",
    },
    orderHistoryDerTab: {
      filter: {
        account: "Tài khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",
        orderType: "Loại lệnh",
        trade: "Loại Mua/Bán",
        status: "Trạng thái",
        condType: "Loại điều kiện",

        ticker: "Mã chứng khoán",
        placeTicker: "Nhập mã HĐ",
        search: "Tìm kiếm",
        type: {
          normalOrder: "Lệnh thường",
          conditionalOrder: "Lệnh điểu kiện",
        },
        condList: {
          all: "Tất cả",
          SO: "Lệnh dừng",
          UO: "Lệnh UP",
          DO: "Lệnh DOWN",
          CL: "IF DONE STOP",
          SP: "IF DONE GAIN",

          OC: "Lệnh OCO",
          BB: "Lệnh Bull & Bear",
          TS: "Lệnh xu hướng",
        },
        statusList: {
          all: "Tất cả",

          rejected: "Từ chối",
          entered: "Chờ gửi",
          waitApprove: "Chờ khớp",
          partialMatch: "Khớp một phần",
          fullMatch: "Khớp toàn bộ",
          partialCancel: "Hủy một phần",
          cancel: "Hủy toàn bộ",
          rejectedGkck: "Sở từ chối",
        },
        statusCondList: {
          all: "Tất cả",

          waitActive: "Chờ kích hoạt",
          end: "Đã kết thúc",
          expire: "Hết hiệu lực",
          cancel: "Đã hủy",
        },
        tradeList: {
          all: "Tất cả",
          short: "Short",
          long: "Long",
        },
      },
      table: {
        tradingDate: "Ngày giao dịch",
        account: "Tài khoản",
        orderShare: "Mã CK",
        orderSide: "Loại giao dịch",
        orderVolume: "Khối lượng đặt",
        orderPrice: "Giá đặt",
        orderVolumeFilled: "Khối lượng khớp",
        orderPriceFilled: "Giá khớp",
        orderStatus: "Trạng thái",
        orderNo: "Số hiệu lệnh",
        channel: "Kênh",
        orderType: "Loại lệnh",
        activePrice: "Giá kích hoạt",
        takeProfit: "Biên độ chốt lãi",
        stopLoss: "Biên độ cắt lỗ",
        stopLossAmount: "Giá cắt lỗ",
        stopInterval: "Khoảng dừng",
        arbitrage: "Chênh lệch",
        updDateTime: "Thời gian cập nhật",
        availableDateTime: "Thời gian hiệu lực",
        matMethod: "Phương thức khớp",
        orderVolume2: "KL",
        orderPrice2: "Giá",
        orderVolumeFilled2: "KL khớp",
        orderPriceFilled2: "Giá khớp",
        long: "LONG+",
        short: "SHORT+",
      },
    },
    condType: {
      "": "Tất cả",
      TCO: "Lệnh trước ngày",
      TS: "Lệnh xu hướng",
      SP: "Lệnh chốt lãi",
      CL: "Lệnh cắt lỗ",
      PRO: "Lệnh tranh mua/bán",
    },
    orderHistoryCS: "Order history",
    orderHistoryFS: "Derivative history",
    orderHistory: "Order history",
    orderDetail: "Deatails",
    fromDate: "From date",
    toDate: "To date",
    account: "Account",
    subAccount: "Sub account",
    side: "Transaction",
    orderType: "Order type",
    conditionOrder: "Condition order",
    status: "Status",
    tooltip: "Kích đúp để xem chi tiết lệnh",
    priceConditionNote:
      "Giá điều kiện khi đặt lệnh căn cứ theo giá thị trường và khoảng dừng tại thời điểm đặt lệnh",
    ordChannel: {
      Back: "Back",
      Web: "Web",
      Front: "Front",
      Webmobile: "Web mobile",
      Mobile: "Mobile",
      Home: "Home",
      Floor: "Floor",
      SMS: "SMS",
      Tel: "Tel",
    },
    ordChannelCond: {
      Back: "BACK",
      Web: "WEB",
      Front: "FRONT",
      Webmobile: "WEB MOBILE",
      Mobile: "MOBILE",
      Home: "HOME",
      Floor: "FLOOR",
      SMS: "SMS",
      Tel: "TEL",
    },

    matMethod: {
      matMethod1: "Phát sinh 1 lần",
      matMethod2: "Khớp đủ khối lượng đặt",
    },
    extStatus: {
      rejected: "Từ chối",
      entered: "Chờ gửi",
      waitApprove: "Chờ khớp",
      partialMatch: "Khớp một phần",
      fullMatch: "Khớp toàn bộ",
      partialCancel: "Hủy một phần",
      cancel: "Hủy toàn bộ",
      rejectedGkck: "Sở từ chối",
    },
    extStatusCond: {
      takeEffect: "Đang hiệu lực",
      expire: "Hết hiệu lực",
      complete: "Hoàn thành",
      cancel: "Đã hủy",
    },
    extStatusCond2: {
      waitActive: "Chờ kích hoạt",
      end: "Đã kết thúc",
      expire: "Hết hiệu lực",
      cancel: "Đã hủy",
    },
    tradeType: {
      sell: "Bán",
      buy: "Mua",
    },
    tradeTypeDer: {
      short: "Short",
      long: "Long",
    },
    filter: {
      account: "Account",
      fromDate: "From date",
      toDate: "To date",
      orderType: "Order type",
      trade: "Trade type",
      status: "Status",

      ticker: "Ticker",
      placehoderTicker: "Nhập mã CK",

      search: "Search",
      type: {
        normalOrder: "Normal order",
        conditionalOrder: "Conditional order",
      },
      condType: {
        all: "All",
        tco: "TCO",
        ts: "TS",
        sp: "SP",
        cl: "CL",
        pro: "PRO",
      },
      statusType: {
        all: "All",

        rejected: "Rejected",
        entered: "Entered the system",
        waitApprove: "Wait approve",
        partialMatch: "Matched partial",
        fullMatch: "Matched",
        partialCancel: "Canceled partial",
        cancel: "Canceled",
        rejectedGkck: "Rejected by GDCK",
      },

      statusCondType: {
        all: "All",

        takeEffect: "Take effect",
        expire: "Expire",
        complete: "Complete",
        cancel: "Cancel",
      },
      tradeType: {
        all: "All",
        buy: "Buy",
        sell: "Sell",
      },
    },

    table: {
      stt: "ID",
      tradingDate: "Trading date",
      condType: "Condition type",
      regDateTime: "Reg date time",
      availableDateTime: "Available date time",
      matMethod: "match method",
      orderDate: "Order date",
      orderTime: "Order time",
      marketPrice: "Market price",
      orderActive: "Active time",
      account: "Account",
      orderShare: "No",
      orderContract: "Contract No",
      orderSide: "Transaction",
      volume: "QTY",
      orderVolume: "QTY order",
      price: "Price",
      stopPrice: "Price cut loss",
      orderPrice: "Order Price",
      orderStatus: "Status",
      orderNo: "Order No",
      orderChannel: "Order Channel",
      orderVolumeFilled: "Matched amount",
      orderPriceFilled: "Matched price",
      matchAvgPrice: "Match avg price",
      orderType: "Order type",
      order: "Order",
      slipMargin: "sliding",
      adjustableStopPrice: "Price cut loss control",
      cancelTime: "Cancel time",
      cancelVolume: "Cancel amount",
      channel: "Channel",
      time: "Time",
      action: "Action",
      reasonReject: "Reason for refusal",
      conditionPrice: "Condition price/Stop loss",
      takeProfitPrice: "Take profit price",
      orderVolumeShort: "KL đặt",
      match: "Khớp",
      conditionOrder: "Lệnh điều kiện",
      method: "Phương thức",
      fromDate: "Từ ngày",
      toDate: "Đến ngày",
      referencePrice: "Giá TC",
      channelOrder: "Kênh ĐL",
      difference: "Chênh lệch",
      cond: "Điều kiện",
      byPercent: "Theo %",
      byValue: "Theo giá trị",
      value: "Giá trị",
      pause: "Khoảng dừng",
      activePrice: "Giá kích hoạt",
      status: {
        all: "All",
        filled: "Matched",
        pending: "Matched partial",
        cancel: "Cancel",
      },
      fsStatus: {
        all: "All",
        reject: "Reject",
        cancel: "Cancel",
        filled: "Match",
        pending: "Wait",
      },
      fsStatusHistory: {
        inActive: "Not active",
        stopReady: "Stop",
        readyToSend: "Ready to send",
        queue: "Wait",
        sending: "Sending",
        sent: "Sended",
        reject: "Reject",
        cancel: "CANCEL",
        partialMatch: "Matched partial",
        partialFilledCancel: "Canceled",
        fullMatch: "Matched",
        matchAndCancel: "Canceled",
        waitApprove: "Pending",
        waitEdit: "Pending edit",
        waitCancel: "Waiting",
        priceWarning: "Warning Price",
        accomplished: "Finished",
        expried: "expried",
        partialExpried: "Expired partial",
        conditionalOrder: "Conditional order",
        coi: "COI",
        oco: "OCO",
        oci: "OCI",
        undefined: "Undefined",
        beAllocated: "Be Allocated (Fully Filled)",
      },
      side: {
        all: "all",
        buy: "Buy",
        sell: "Sell",
      },
      tradeDate: "order date",
      seriesId: "ID contract",
      fsSide: {
        all: "all",
        short: "Short",
        long: "Long",
        longUp: "LONG",
        shortUp: "SHORT",
      },
      fsAction: {
        IC: "order",
        AS: "Order successful",
        PE: "Matched",
        MC: "Edit",
        MS: "Edit successful",
        FL: "Matched",
        MO: "Edit",
        AF: "Reject",
        CO: "Cancel",
        DC: "Cancel successful",
        AZ: "Cancel",
        IS: "Place Order",
        CI: "tranfer not active",
        CA: "Active",
        KL: "Reject",
        II: "Place Order",
        SC: "Active",
        EX: "Reject",
        CS: "Cancel successfully",
        IJ: "Place Order",
        IA: "Place Order",
        AD: "Allocation done",
        AM: "Approve OK (Local modify)",
        AP: "Approve OK",
        AX: "Disapprove",
        BC: "BIX Locally Cancel",
        BM: "BIX Locally Modified",
        BR: "BIX Locally Reduced",
        CF: "Notify Client for fully-matched",
        CM: "Cancel Modify Request",
        CW: "Cancel Price Warning",
        DA: "Deactive Order",
        DE: "Request Deallocation",
        DI: "Dehold Inactive",
        FA: "Fill Auction",
        FC: "Fully Cancel",
        FF: "Fully Filled",
        HF: "Hold Fund",
        ID: "Input C.D. Order",
        IG: "Inactivate Today GTD Order",
        IK: "Input Inactive Conditional Order",
        IM: "Input Conditional Order (Odd Lot)",
        IN: "Input Inactive Con Order(Odd Lot)",
        IP: "Input Split Order",
        LM: "Local Modify",
        MJ: "Modify Reject",
        ML: "Modify Local Order",
        NA: "Notify client (No Answer)",
        OI: "Order Inputed to host",
        PC: "Partially Cancel",
        PF: "Partially Filled",
        PM: "Pending Modify",
        PR: "Trade Partially Reject",
        SS: "Send",
        RA: "Request Allocation",
        RB: "Resubmit for price chasing",
        RD: "Reduce Order",
        RJ: "Host Reject",
        RM: "Reject Modify (Local Modify)",
        RR: "Reject Resubmit (Market Close)",
        SD: "Split C.D. Order",
        PW: "Price Warning",
        ST: "Submit Stop Order",
        SX: "Undo Send by Manual Reporting",
        TB: "User Manually Unfill Executed Trade",
        TM: "Trade Modify",
        TR: "Trade Reject",
        TS: "Trigger Send",
        UC: "Unsolicited Cancel",
      },
      fsOrderType: {
        L: "LO",
        M: "MO",
        A: "ATO",
        C: "ATC",
        K: "MTL",
      },
      channelName: {
        I: "Internet",
        M: "Mobile",
        D: "Broker",
        B: "Bloomberg",
        H: "Home",
        O: "Omnibus",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      channelFSName: {
        WT: "D24",
        FOT: "FOT",
        BR: "Broker",
        G: "Plus24",
        N: "New Mobile",
        W: "New Web",
      },
      fsStopType: {
        N: "Thường",
        D: "Down",
        U: "Up",
        V: "T Up",
        E: "T Down",
        O: "OCO",
        B: "Bull & Bear",
      },
      fsDealStatus: {
        waitApprove: "Wait",
        waitActive: "Waiting active",
        partialMatch: "Matched partial",
        fullMatch: "Matched",
        cancel: "Canceled",
        reject: "Reject",
        edited: "edited",
        waiting: "pending",
        waitCancel: "Pending cancel",
        rejectEdit: "Reject edit",
        waitEdit: "Pending edit",
        rejectCancel: "Reject cancel",
      },
      filterOrderType: {
        normal: "Lệnh thường",
        condition: "Lệnh điều kiện",
      },
      filterCondition: {
        all: "Tất cả",
        beforeDay: "Trước ngày",
        trending: "Xu hướng",
        competitive: "Tranh mua/bán",
        takeProfit: "Chốt lãi",
        stopLoss: "Cắt lỗ",
      },
      statusCondition: {
        all: "Tất cả",
        complete: "Hoàn thành",
        expired: "Hết hạn",
        cancel: "Đã hủy",
      },
      sideCondition: {
        all: "Tất cả",
        buy: "Mua",
        sell: "Bán",
      },
      methodCondition: {
        once: "Phát sinh 1 lần",
        full: "Khớp đủ KL đặt",
      },
      channelCondition: {
        N: "Mobile App",
        W: "New Web",
      },
    },
    null: "--",
    modal: {
      link: "Details order",
      title: "Derivative",
    },
  },
  infoWarrant: {
    registerBuy: {
      errorShareRegister0: "Quantity must be larger than 0 ",
      errorShareRegister_shareCanBuy:
        "Amout of the stock not bigger than the amount orderd",
      errorBuyPrice_cashBalance: "The current balance is not enough",
      accountCode: "Account registed",
      shareCode: "Ticket",
      registerFromDate: "Begin reg date",
      registerToDate: "To date",
      shareCanBuy: "Quantity available",
      buyPrice: "Price",
      shareRegister: "Quantity",
      placeholder_shareRegister: "Enter quantity",
      sum: "Total cash order",
      accountPay: "Payment Account",
      cashBalance: "Cash balance",
      success: "Register successfully",
    },
    filter: {
      all: "All",
      CHUA_THUC_HIEN: "not perform",
      DA_THUC_HIEN: "performed",
      account: "Acconut Number",
      sticker: "Stock code",
      status: "Status",
      search: "Search",
      RIGHT_BUY: "Order rights",
      RIGHT_DIVIDEND: "Dividend rights as cash",
      RIGHT_STOCK: "Dividend rights as stock",
      RIGHT_VOTE: "Meeting rights ĐHCĐ",
      CONVERSION_STOCK: "tranfer rights stock",
      RIGHT_CASH_ORIGIN_BOND: "Received the orgin Bond rights",
      CHUA_CHOT_DS_CO_DONG: "Unfinish the list shareholder",
      RIGHT_COVERED_WARRANT: "Right to maturity CW",
      DA_DUYET: "Approved",
      RIGHT_BUY_CHUA_THUC_HIEN: "order rights pending",
      RIGHT_BUY_DA_THUC_HIEN: "Rights to order",
      CHUA_CHOT_CO_DONG: "Unfinsh the list owner",
      DA_CHUYEN_T_THAI: "The account {} transfer securities state",
      DA_DUYET_T_THAI: "Approved",
      RIGHT_STOCK_CHUA_CHOT_DS_CO_DONG: "Unfinsh the list owner",
      CHUA_CHOT_DANG_KY: "Unfinish the list order",
      RIGHT_STOCK_CHUA_THUC_HIEN: "Not Right Executed",
      RIGHT_STOCK_DA_THUC_HIEN: "Right Executed",
      type: "Rights",
      closeFromDate: "Close date",
      execFromDate: "Implementation date",
    },
    table: {
      index: "#",
      DA_THUC_HIEN: "Received",
      CHUA_THUC_HIEN: "Not received yet",
      accountCode: "ACC number",
      shareCode: "Stock",
      shareRegister: "The amount of registed",
      buyPrice: "Price",
      cashMoney: "Cash",
      registerDate: "The register day",
      excuteDate: "Impleme date",
      statusCode: "Status",
      registerFromDate: "The begin day register",
      registerToDate: "The last day register",
      rightTotal: "Amount of right",
      shareCanBuy: "Amount can buy",
      valueShareRegister: "Amount registed",
      type: "Right",
      closeDate: "Last day",
      volumeCanBuy: "Amount can buy",
      registedVolume: "Amount of registed",
      shareDividend: "Cash received",
      cashDividend: "Dividend by cash",
      rightCashRate: "cash DIV(%)",
      rightShareRate: "DIV",
      registerTime: "Register to buy time",
      transferTime: "Tranfer time",
      rightBuyRate: "Rate order",
      price: "Order price",
      confirmDate: "Implementation date",
    },
    tab: {
      RESIGNTER: "Right to buy registed",
      HISTORY: "Right to buy registed history",
      INFORMATION: "Infor rights search",
      register: "RegisterBuy",
    },
  },
  transferShare: {
    title: "Transfer Share",
    status: {
      all: "Tất cả",
      pending: "Chưa duyệt",
      approve: "Đã duyệt",
      delete: "Đã xóa",
    },
    filter: {
      fromDate: "From Date",
      toDate: "To Date",
      account: "Account",
      shareCode: "ShareCode",
      status: "Status",
      search: "Search",
    },
    table: {
      index: "STT",
      transactionDate: "Transaction Date",
      accountOut: "Account Out",
      accountIn: "Account In",
      shareCode: "ShareCode",
      shareStatusOut: {
        title: "Share Status Out",
        normal: "Normal",
        outR: "OutR",
        tt68: "TT68",
      },
      shareVolume: "Share Volume",
      statusName: "Status Name",
      content: "Content",
      status: "Status",
      updUserId: "Người cập nhật",
      updDateTime: "Thời gian cập nhật",
      action: "Hủy",
    },
    tab: {
      transferShare: "Transfer Share",
      transferShareHistory: "Transfer Share History",
      transferShareForm: "Transfer Share Form",
    },
    transferForm: {
      accountOut: "Account Out",
      accountIn: "Account In",
      content: "Content",
      content_value: "TK {accountOut} chuyển khoản sang {accountIn}",
      content_value1: "TK",
      content_value2: "chuyển khoản sang",
      placeholder_content: "Nhập nội dung",
      table: {
        shareCode: "Share Code",
        statusName: "Status Name",
        shareBalance: "Share Balance",
        maxWithDraw: "Max With Draw",
      },
      button: {
        cancel: "Cancel",
        confirm: "Confirm",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      errorMaxWidthdraw: "Vui lòng nhập SL chuyển lớn hơn 0",
      errorCalPrice: "Có lỗi xảy ra khi tính toán mã chứng khoán đã chọn",
      errorBanAcc: "Tiểu khoản đã bị phong tỏa",
      transferShareSuccess: "Chuyển khoản thành công",
      modal: {
        title: "Xác nhận chuyển",
        content:
          "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện, bạn có muốn tiếp tục thực hiện ?",
        cancel: "Hủy",
        confirm: "Xác nhận",
      },
    },
    modalConfirmDelete: {
      title: "Xác nhận",
      content:
        "Bạn có chắc chắn muốn huỷ yêu cầu chuyển khoản mã {secCd}, khối lượng {volume}",
    },
  },
  packServices: {
    title: "Transfer Share",
    filter: {
      fromDate: "From Date",
      toDate: "To Date",
      status: {
        title: "Status",
        all: "All",
        notApprovedYet: "Not Approved Yet",
        approved: "Approved",
        rejectApproved: "Cancel Approved",
        canceled: "Canceled",
        cancelApprovedWait: "Cancel Approved Wait",
      },
      search: "Search",
    },
    table: {
      accountCode: "Account Code",
      createdDate: "Created Date",
      packServicesName: "Pack Services Name",
      packServicesCode: "Pack Services Code",
      expiredDate: "Expired Date",
      status: "Status",
      cancel: "Cancel",
      cancelSuccess: "Hủy đăng ký thành công",
    },
    tab: {
      packServices: "Pack Services",
      packServicesHistory: "Pack Services History",
      packServicesForm: "Pack Services Form",
    },
    packServicesForm: {
      accountCode: "Account Code",
      fullName: "Full Name",
      packServicesName: "Pack Services Name",
      packServicesCode: "Pack Services Code",
      content: "Content",
      createdDate: "Created Date",
      expiredDate: "Expired Date",
      contentCondition:
        "Tôi đã đọc, hiểu và xác nhận đồng ý với các chính sách, dịch vụ liên quan đã đăng ký",
      placeholder_content: "Nhập nội dung",
      button: {
        cancel: "Cancel",
        confirm: "Confirm",
      },
      errorCheckNone: "Quý khách vui lòng chọn Mã CK chuyển trạng thái",
      packServicesSuccess: "Đăng ký chính sách thành công",
    },
  },
  coveredWarrant: {
    register: "RegisterBuy",
    purchaseHistory: "Order register history",
    information: "Infor the warrant",
    all: "All",
    buy: "Buy",
    sell: "Sell",
    history: {
      filter: {
        fromDate: "From date",
        toDate: "To date",
        cutAccount: "Cut cash on the account",
        coveredWarrantID: "W",
      },
      table: {
        index: "ID",
        coveredWarrantID: "W",
        registerAccount: "Accout off registed",
        cutAccount: "Cut cash on the account",
        ckcs: "UDS",
        publishDate: "Issued",
        registerDate: "Register date",
        quantity: "QTY",
        purchasePrice: "Price",
      },
      register: {
        coveredWarrantID: "Warrant code",
        cwIDPlaceHolder: "Select the warrants",
        coveredWarrantPlaceholder: "Warrant code",
        quantityPlaceholder: "Amount registed order",
        codeList: "FPT/BSC/C/6M/EU/ warrants",
        ckcs: "UDS",
        publishDate: "Issued",
        publishDatePlaceHolder: "Select  issued",
        purchaseAccount: "Account registed",
        cutAccount: "Account",
        availableAmount: "Cash available",
        quantity: "Amout registed order",
        purchasePrice: "Order price",
        total: "Total value order price",
        agree: "I read and agreed with ",
        terms: "Terms and conditions",
        registerToBuy: "Register buy the warrants at MBS",
        modalTerms: {
          title: "Terms and conditions",
          close: "Closed",
        },
        success: "Register successfully",
        error: {
          noCWCode: "Warrant code not empty",
          noVolume: "Volume registed not empty",
          overPriced: "Value of order must less than cash available",
          overDate: "From date must to less than to date",
          lengthDate: "Exceeded the 100-day search",
          divisible: "Amount must to divisible by 100",
          overVolume:
            "Amout of order exceeded amount of the warrants available",
          notYet: "Not time to sign. Please try again",
          account: " This account is Pending or editting",
        },
        button: {
          cancel: "CANCEL",
          confirm: "CONFIRM",
        },
      },
    },
    info: {
      filter: {
        coveredWarrantID: "Warrant code",
        all: "All",
        type: "Warrant",
        underlyingSecuritiesID: "UDS",
        underlyingSecuritiesIDPlaceHolder: "Type UDS code",
      },
      table: {
        index: "ID",
        coveredWarrantID: "Code",
        name: "Name",
        ckcs: "UDS code",
        tcph: "Organization",
        amount: "Amount",
        exerciseRatio: "convert rate",
        period: "Period (M)",
        gdccDate: "Tran ate",
        dueDate: "Expired date",
        estimatedPaymentDate: "Es paym date",
        exercisePrice: "Price",
        estimatedPaymentPrice: "Es paym price",
      },
      description:
        "Disclaimer: The estimated payment price is for reference only. MBS is not responsible for deviations.",
    },
  },
  screeners: {
    tabs: {
      screener: {
        title: "Screener",
        market: {
          legend: "Market",
          exchange: "Exchange",
          industry: "Industry",
          select: "All",
        },
        quote: {
          legend: "Quote Indicator",
          marketCap: "Market Cap",
          price: "Price",
          changeRate: "% Change",
        },
        financial: {
          legend: "Financial Indicator",
          pe: "P/E",
          eps: "EPS",
        },
        technical: {
          legend: "Technical Indicator",
        },
        other: {
          legend: "Other",
          analystRating: "Analyst Rating",
          marginAvailable: "Margin Available",
        },
        actions: {
          reset: "Reset",
          save: "Save",
          emptyWarning: "Quý khách vui lòng chọn tiêu chí lọc cổ phiếu",
          createSuccess: "Tạo bộ lọc thành công",
          deleteSuccess: "Xóa bộ lọc thành công",
        },
      },
      myScreener: {
        title: "My screener",
        applyError: "Vui lòng chọn bộ lọc",
        applyButton: "Áp dụng",
        cancelSelectButton: "Hủy",
      },
    },
    card: {
      cardTitle: "{total} Result",
      tickerTooltip: "Detail code",
      table: {
        order: "ID",
        shareCode: "stock code",
        companyName: "company name",
        price: "PRICE",
        change: "CHANGE",
        changeRate: "%CHANGE",
        marketCap: "MARKET CAP",
        pe: "P/E",
        pb: "P/B",
        netMargin: "NET SALES (B)",
        plusButton: "Add to Watchlist {number}",
        roa: "ROA (%)",
        roe: "ROE (%)",
        eps: "EPS",
      },
      modal: {
        title: "ADD FILTER",
        screenerName: "Filter name",
        placeholder: "Type name filter",
        cancel: "Cancel",
        save: "save",
        errorMessage: "Filter {name} exist, please select other filter name",
      },
    },
  },
  loanExtension: {
    tabHeader: {
      loanTracking: {
        title: "Flow debit",
      },
      extendHistory: {
        title: "Online extend history",
      },
    },
    tabPanel: {
      loanTracking: {
        filterActions: {
          account: "Acount",
          status: "Status",
          search: "Search",
          all: "All",
          canExtend: "Can extension'",
        },
        table: {
          header: {
            deliverDate: "DISB DATE",
            oldExpireDate: "EXPIRED DATE",
            numDayDiff: "NUMBER DAYS",
            feeRate: "INTEREST (%/DAY)",
            intermLoan: "DEBIT DATE",
            intermFee: "INTEREST OF DATE",
            exterLoan: "DEBIT OUT DATE",
            exterFee: "INTEREST OUT DATE",
            totalLoanBLN: "Total debit",
            extendFeeRate: "Interest after extended (%/DAY)",
            newExpireDate: "NEW EXPIRE DATE",
          },
          footer: {
            extend: "Extension",
            total: "TOTAL",
            notCheckedError: `Loan hasn't been selected`,
          },
        },
      },
      extendHistory: {
        filterActions: {
          fromDate: "From date",
          toDate: "To date",
          status: "Status",
          search: "Search",
          all: "All",
          extendStatus: {
            approved: "Approved",
            notControl: "Not control",
            notApprove: "Not approve",
          },
          error: {
            dateRange: "Exceeded the 30-day search",
            invalidDate: "From date must to less than to date",
          },
        },
        table: {
          header: {
            order: "ID",
            accountNum: "Acc number",
            deliverDate: "Delidate",
            oldExpireDate: "Old expice date ",
            numDayDiff: "Borrowed date",
            loanBalance: "Current deb",
            feeBalance: "Debit interest",
            totalLoanBLN: "Total debit",
            newExpireDate: "New expice date",
            extendFeeRate: "Interest (%/year)",
            approveDate: "Extension date",
            channel: "Channel",
            statusName: "Status",
          },
        },
      },
      extendExpireSuccessMsg: "Extend success",
    },
    card: {
      header: "Online extension",
      accountNum: "Account number",
      deliverDate: "Disbursement date",
      oldExpireDate: "Date of maturity",
      numDayDiff: "Loan number",
      loanBalance: "Original debt currenti",
      feeBalance: "Debit interest",
      totalFeeRate: "All debit",
      newExpireDate: "Date of maturity after extended",
      extendFeeRate: "Interest after extension (%/năm)",
      acceptTerms:
        "I have read and agree to the terms and conditions of the margin trading and service policy at MBS",
      cancelButton: "Cancel",
      confirmButton: "Confirm",
      closeButton: "Close",
      note: {
        r1: "Loan isn't block the extension",
        r2: "Not exit current account is out of date",
        r3: " Loan is still renewable: Total loan term from disbursement to new maturity date must be less than or equal to 275 days (total number of days may change according to MBS policy from time to time))",
        r4: "The number of days remaining of the loan until the current expiration date must be less than or equal to 20 days (the number of loan days may change according to MBS's policy from time to time)",
        r5: "The loan isn't extend at the transaction office",
      },
    },
  },
  rangeDateSearch: {
    success: "",
    errorRange: "From dat must not the bigger than to date",
    exceededLimit: "Exceeded the 30-day search ",
  },
  marginLimit: {
    tabs: {
      marginLimitHistory: {
        header: {
          title: "Limit change history",
        },
        filterActions: {
          fromDate: "From date",
          toDate: "To date",
          account: "Account",
          status: "Status",
          search: "Search",
        },
        statusList: {
          all: "All",
          approved: "Approved",
          modified: "Edited",
          modifying: "Editting",
          cancel: "Cancel",
          reject: "Reject",
        },
        dateError: {
          overRange: "Exceeded the 30-day search",
          underRange: "From date must less than to date",
        },
        table: {
          header: {
            order: "ID",
            limitLoanOld: "Value old",
            limitLoan: "Value request new",
            modifyDate: "Request date",
            approveDate: "Approve date",
            status: "Status",
          },
        },
      },
    },
    cards: {
      marginLimitChange: {
        header: {
          title: "LIMIT CHANGE",
        },
        body: {
          placeholderMarginRequire: "Type requestLimit",
          underLimitError: "The new request limit cannot be less than minLimit",
          overLimitError:
            "The new limit of request the bigger than the max limit",
          stepError: " The new limit of request must follow the value step ",
          emptyError: "New limit of request is not . Please type again",
          accountNum: "Acconut number",
          currentLimit: "Current limit",
          requestLimit: "Required limit",
          requestLimitPlaceHolder: "Type required",
          acceptTerms:
            "I have read and agree to the terms and conditions of the margin trading and service policy at MBS",
          note: "Note: The time to change credit limit is before 16:00 on working days",
        },
        actions: {
          cancel: "Cancel",
          agree: "Confirm",
        },
        limitRules: {
          title: "Rules of limit",
          minLimitLabel: "Min credit limit:",
          minLimitUnit: " Million",
          maxLimitLabel: "Max credit limit:",
          maxLimitUnit: " Billion",
          stepValueLabel: "Step value:",
          stepValueUnit: " Million",
          confirmPrefix: "Raise the request limit ",
          confirmSuffix: " MBS need expertise",
        },
      },
    },
    message: {
      modifySuccess: "Change limit successfully",
    },
  },
  tradingHelper: {
    checkPlaceOrder: "Not determined the order",
    symbolDataNotFound: "Not found infor stock {symbol}",
    symbolStatusHalt: "Stock code stoped or limited transaction",
    invalidPriceStep: "Invalid price {priceStep}đ)",
    invalidSecurityType1: "Not yet support placing orders for corporate bonds",
    invalidSecurityType2: "Invalid symbol type",
    invalidMarketCode: "Invalid trading session {marketCode}",
    checkVolumeLot1: "Board lot unit invalid",
    checkVolumeLot2: "The volume is not round lot",
    invalidVolumeLot: "The volume is not round lot",
    invalidVolumeLotEdit:
      "Sàn HNX, Upcom không được sửa KL lô chẵn thành lẻ, lẻ thành chẵn",
    invalidMarketCode2: "Invalid trading session",
    invalidTradingSession: "Unspecified trading session {tradingSessionId}",
    invalidTradingSession2: "The market is closed",
    invalidMarketState: "The market is closed",
    invalidMarketState2:
      "Invalid prics. OT trading session can only place with LO, ATO, ATC price",
    invalidMarketState3:
      "Invalid prics. ATO trading session can only place with LO, ATO, ATC price",
    invalidMarketState4:
      "Invalid prics. OPEN trading session can only place with LO, MP, ATC price",
    invalidMarketState5:
      "Invalid price. BREAK trading session can only place with LO, MP, ATC price",
    invalidMarketState6:
      "Invalid prics. ATC trading session can only place with LO, ATC price",
    invalidMarketState7:
      "Invalid price. OT trading session can only place with LO, ATC price",
    invalidMarketState8:
      "Invalid price. OPEN trading session can only place with LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState9:
      "Invalid price. BREAK trading session can only place with LO, MOK, MAK, MTL, PLO, ATC",
    invalidMarketState10:
      "Invalid price. ATC trading session can only place with LO, ATC, PLO",
    invalidMarketState11:
      "Invalid price. RUNOFF rading session can only place with PLO",
    invalidMarketState12: "Odd lot orders allow only LO price",
    invalidMarketState13:
      "Odd lot orders allow to place in OPEN, BREAK, OT, OutTime and BOD trading session",
    invalidMarketState14: "Invalid price. UPCOM exchange allow only LO price",
    invalidMarketState15:
      "Invalid price. OPEN trading session can only place LO, MOK, MAK, MTL, ATC",
    invalidMarketState16:
      "Invalid price. OPEN trading session can only place LO, MOK, MAK, MTL, ATC",
    invalidMarketState17:
      "Invalid price. ATC trading session can only place with LO, ATC",
    invalidMarketState18: "Invalid trading session",
    invalidMarketState19: "Phiên giao dịch không hợp lệ đặt lệnh báo giá",
    volumeShouldNotBeExceeded: "Quantity cannot to be exceeded {limit}",
    invalidPrice0: "Only order LO price with condition type",
    invalidPrice: "Cut loss price must to bigger than current price",
    invalidPrice2: "Cut loss price not bigger than ceilingPrice",
    invalidPrice3: "Cut loss price other price",
    invalidPrice4: "Cut loss price have to less than current price",
    invalidPrice5: "Cut loss price have to bigger than floorPrice ",
    invalidPrice6: "TakeProfit price have to bigger than price",
    invalidPrice7:
      "Price takeProfit have to less or the same than ceilingPrice",
    invalidPrice8: "Cut loss price have to less than price",
    invalidPrice9: "Cut loss price have to  bigger or the same floorPrice",
    invalidPrice10: "Cut loss price or take profit have to other price",
    invalidPrice11: "Take profit price less than price",
    invalidPrice12: "Take profit pricebigger than floor",
    invalidPrice13: "Cut loss price have to bigger than price",
    invalidPrice14: "Cut loss price less than ceiling price",
    invalidPrice15: "Trigger price cannot be larger than ceiling price",
    invalidPrice16: "Trigger price cannot be smaller than floor price",
    invalidPrice17:
      "Trigger price have to be larger than market price {priceToCheckCond}",
    invalidPrice18:
      "Trigger price  must be lower than last price {priceToCheckCond}",
    invalidPrice19: "Price change cannot be larger than ceiling price",
    invalidPrice20: "Price change cannot be smaller than floor price",
    invalidPrice21: "price take profit less than celling",
    invalidPrice22: "price cut loss bigger than floor",
    invalidPrice23: "Do not edit FAL ordeer",
    invalidOrderData: "Not determined the order to edit",
    marketSessionCloseErr: "The market is closed",
    couldNotGetStockCodeInformationErr: "Not found symbol infomation {symbol}",
    dataDoesNotChange: "There is no change in the data",
    invalidVolumne: "Quantity change have to be larger than 0",
    invalidVolumne2: "Quantity change have to be larger than matched quantity",
    invalidVolumne3: "price cut loss bigger than current price",
    invalidVolumne4: "price cut loss not big celling",
    invalidVolumne5: "price cut loss other price",
    invalidVolumne6: "price cut loss less than last price",
    invalidVolumne7: "price cut loss not less than floor",
    invalidVolumne8: "Not allow to increase quantity",
    doNotModifyOrCancelThisOrderErr: "Do not edit/ cancel this order",
    invalidPriceAndQty: "Price and quantity cannot be edited at the same time",
    invalidMarketCd: "Not allow to edit HSX's order",
    volumeAllowedAndNotChangeprice:
      "Market order is pending to send that allow to edit quantity only",
    checkEditOrderStatus:
      "In the ATO/ATC trading session, do not edit or cancel orders",
    checkEditOrderStatus2: "Do not edit/ cancel this order",
    checkEditOrderStatus3: "Do not edit this order",
    checkEditOrderStatusDer:
      "In the ATO/ATC trading session, do not edit or cancel orders",
    checkEditOrderStatusDerCond:
      "In the ATO/ATC trading session, do not edit or cancel orders",
    checkEditOrderStatusDerCond2: "Do not edit/ cancel this order",
    checkCancelOrder: "Not determine the order to cancel",
  },
  netAssetsValue: {
    nav: {
      tabHeaderTitle: " Net asset NAV",
      card: {
        navSub: "NET ASSET (NAV):",
        marketValueSub: "Portfolio value:",
        profitSub: "Profit/loss (temporary):",
        totalAsset: "Total asset:",
      },
      assetInfo: {
        nav: "TOTAL NAV",
        totalAsset: "TOTAL ASSET",
        totalCashBalance: "1. Cast",
        cashBalance: "1.1 Cash balance",
        matchedBuyT0: "1.2 Order pending payment",
        unMatchBuy: "1.3 Order pending",
        cashAdvanceAble: "1.4 Advance available",
        cashWithdrawable: "1.5 Money can withdraw",
        totalShareValue: "2. Stock",
        shareValue: "2.1 Value of stock balance",
        shareValueBuy: "2.2 Value of stock buy wait for return",
        shareValueSell: "2.3 Value of rights wait a payment",
        sumRight: "2.4 Value of rights wait for return",
        rightCash: "Dividends to cash",
        rightShare: "Dividends to stock ",
        rightBuyStock: "Right to buy",
        totalCWValue: "3. Warrant",
        cwValue: "3.1 Value of warrant present",
        cwValueBuy: "3.2 Value of warrant buy wait for return",
        cwValueSell: "3.3 Value of warrant sell wait a payment",
        cwValueBuyMaturity: "3.4 Value of warrant buy wait a payment expired",
        bondValue: "4. Bond",
        capValue: "5. Bussiness contract ",
        fsTotalAssets: "6. Derivative",
        fsMBS: "6.1 Money of derivative at MBS",
        fsVSD: "6.2 Money of derivative margin VSD ",
        fsCashWithdrawable: "6.3 Money of derivative can withdraw",
        totalDebit: "7. Debit",
        rootDebit: "7.1 Debit balance",
        fee: "7.2 Interest/fee Financial service ",
      },
    },
    financialServices: {
      tabHeaderTitle: "Financial service",
      account: "Account",
      normal: "normal",
      table: {
        tableName: "Menu stock",
        order: "ID",
        accountNoDetail: "ACCOUNT",
        symbol: "Stock code",
        tradeType: "TRANSACTION",
        marginRatio: "Rate of margin (%)",
        availableBalance: "Available",
        sharesBalance: "QTY",
        unmatchSell: "unmatch sell",
        qtyT0: "T + 0",
        qtyT1: "T + 1",
        qtyT2: "T + 2",
        bought: "buy",
        sold: "sell",
        marketValue: "price market",
        grossValue: "Total value",
        total: "Sum",
        rightShares: "Right Shares",
      },
      cards: {
        totalShareValue: "I. Total value of stock",
        totalCashBalance: {
          title: "II. Money (1-2-3+4+6)",
          cashBalance: "1. Balance",
          buyingCashBlock: "2. Wait_match",
          matchedBuyT0: "3. Matched order wait payment",
          receivingSellCash: "4. Wait sell money (T0, T1, T2)",
          withDrawCash: "5. Money can withdraw",
          cashDividend: `6. Value of rights (temporary)`,
        },
        totalDebit: {
          title: "III. Total debit DVTV (7+8+9)",
          principalBalance: "7. Debit balance",
          paidInterest: "8. Interest/fee financial service",
          cashAdvanced: "9. Used money",
        },
        totalAsset: "IV. Net asset",
        financialServicesAccInfo: {
          title: "INFOR FINANCIAL SERVICE ACCOUNT",
          callRatio: "Rate Call off MBS",
          forceSellRatio: "Rate Force Sell off MBS",
          afterBuyRatio: "Rate after buy",
          interestRatio:
            "Rate of interest loan service not includes extension ",
          creditLimit: "Limit of account",
          falLimit: "Limit of FAL",
          falRemainCredit: "Limit of Fal balance",
          buyCredit: "Purchasing power",
          portfolioValue: "GTDM count QTRR",
          netWorth: "TSR count QTRR",
          currentMarginRatio: "Rate of margin present (TSR/GTDM)",
          capValueLink: "Link purchasing power of business contract",
          bPLinkMB: "link purchasing power of account MB",
          bPLinkMBUsed: "Used link purchasing power MB",
          falUsed: "Limit of Fal used",
        },
      },
    },
    derivative: {
      tabHeaderTitle: "Derivative",
      noAccount: "Account not derivative sub-account",
      filter: {
        account: "Account",
      },
      card: {
        overCash: {
          cardTitle: "Total money balance",
          commissionPerFee: "Fee",
          interest: "Interest",
          deliveryAmount: "Transfer value",
          floatingPLPerTradingPL: "Profit loss position (open/close)",
          totalPL: "Total profit/loss",
          minreservPerMarginable: "Money balance can deposit on the VSD",
          cashDrawablePerNonCashDrawable: "Total money",
        },

        assetPortfolio: {
          cardTitle: "Menu assets",
          MBS: "MBS",
          VSD: "VSD",
          cash: "Cash",
          validNonCash: "Value of margin stock",
          totalValue: "Total value of margin",
          maxValidNonCash: "Value max of margin valid",
          cashWithdrawable: "Money can withdraw",
          ee: "Position",
        },

        signFund: {
          cardTitle: "Use margin",
          MBS: "MBS",
          VSD: "VSD",
          signInitValue: "Value of margin initial",
          signTransValue: "Transfer value of margin",
          marginRequirement: "Total value of margin request",
          accountRatio: "Rate use asset of margin (%)",
          usedLimitWarning: "Warning - level 1/2/3 (%)",
          marginCall: "Add to money",
        },
      },
      table: {
        positionOpen: {
          title: "Open position",
          rowNum: "ID",
          seriesID: "Contract code",
          dueDate: "Duedate",
          long: "Close_position",
          short: " short position",
          net: "net position",
          bidAvgPrice: "Buy_tb",
          askAvgPrice: "sell_tb",
          marketPrice: "Market Price",
          tradingPLPerFloatingPL: "PROFIT/LOSS",
        },
        positionClose: {
          title: "Close_position",
          rowNum: "ID",
          seriesID: "Contract code",
          long: "Close_position",
          net: "Net position",
          bidAvgPrice: "Buy_tb",
          askAvgPrice: "Sell_tb",
          tradingPLPerFloatingPL: "PROFIT/LOSS",
        },
      },
    },
    charts: {
      beta: "(BETA)",
      betaText1: "Dữ liệu HQDT được thống kê từ 23/11/2021",
      betaText2:
        "TSSL tính theo công thức Time-Weighted Rate(TWR) = {End NAV - (Begin NAV + Cash Flow)}/(Begin NAV + Cash Flow)",
      betaText3:
        "Công thức chỉ mang tính chất tham khảo, Chúng tôi không chịu trách nhiệm về các quyết định của Khách hàng khi dựa vào số liệu này",
      navLineChart: {
        title: "NET ASSET VALUE",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Account",
        growth: "Growth:",
        nav: "NAV:",
      },
      investLineChart: {
        title: "INVESTMENT EFFICIENCY",
        filterDate: {
          oneMonth: "1m",
          twoMonth: "2m",
          threeMonth: "3m",
          sixMonth: "6m",
          oneYear: "1y",
        },
        filterAccount: "Account",
        indexLabel: "Index",
        profitLabel: "Profit rate (PR)",
        profitLabelShort: "PR",
        idxVal: "VN-Index",
        percentDelta: "NAV",
      },
      assetDistributionChart: {
        title: "PHÂN BỔ TỔNG TÀI SẢN",
        totalCashBalance: "Tiền",
        totalShareValue: "Cổ phiếu",
        totalCWValue: "Chứng quyền",
        bondValue: "Trái phiếu",
        capValue: "Hợp tác kinh doanh",
        fsTotalAssets: "Phái sinh",
      },
      debtAndNavChart: {
        title: "NỢ VÀ TỔNG TÀI SẢN",
        debt: "Nợ",
        nav: "Tài sản ròng",
      },
      tickerRateChart: {
        title: "TỶ TRỌNG MÃ CỔ PHIẾU",
      },
      indexRateChart: {
        title: "TỶ TRỌNG NHÓM CHỈ SỐ",
        account: "Tài khoản",
        ticker: "VN-Index",
      },
      industryRateChart: {
        title: "TỶ TRỌNG NGÀNH",
        account: "Tài khoản",
        ticker: "VN30",
      },
      unit: "Unit: Million VND",
    },
  },
  onlineService: {
    careService: "Customer service",
    authenticationMethod: "Accurate",
  },
  alertSettings: {
    modalTitle: "Warning setting",
    symbolWarning: "Please choose code to waring setting",
    setAlert: {
      tabTitle: "Warning setting",
      volume: "Amount",
      ceiling: "Ceiling",
      floor: "Floor",
      referenceShort: "REF",
      priceMatchGTE: "Match price >=",
      priceMatchLTE: "Match price <=",
      overBoughtGTE: "Buy >=",
      overSoldtLTE: "Sell <=",
      priceIncreasePercent: "% Increase price",
      priceDecreasePercent: "% Decrease price",
      timesPerDay: "Repeat",
      fromDate: "From date",
      toDate: "To date",
      createSuccessMsg: "Creat a warning successfully",
      editSuccessMsg: "Edit a warning successfully",
      deleteSuccessMsg: "Delete successfully",
      notExistMsg: "Code isn't exist",
      dateError1: "From date can not exceeded to date",
      dateError2: "From date and to date is not past date",
      indexValUp: "Index >=",
      indexValDown: "Index <=",
      indexChangePerUp: "% Increase Index",
      indexChangePerDown: "% Decrease Index",
      totalQtyUp: "A >= (MILL)",
      totalAmtUp: "Value >= (BILL)",
      totalValue: "VALUE",
      repeatType1: "Once a day",
      repeatType2: "Once a minute",
      repeatType3: "Once a half hour",
    },
    manageAlert: {
      tabTitle: "Warning Manager",
    },
    action: {
      cancel: "Cancel",
      save: "Save",
    },
    emptySymbolError: "The symbol cannot be empty",
  },
  businessCooperation: {
    businessCooperationTab: {
      tableTitle: "Business cooperation",
      filterActions: {
        fromDate: "From date",
        toDate: "To date",
        account: "Account",
        status: "Status",
        search: "Search",
        disapprove: "Pending",
        approved: "Approved",
        liquidated: "Liquidation",
        all: "All",
      },
      table: {
        appendixNumber: "App number",
        accountNo: "account No",
        value: "Capital value",
        period: "period",
        openDate: "OPEN DATE",
        expireDate: "EXPIRED DATE",
        commission: "INTEREST (%/Y)",
        estimatedIncome: "Profit est",
        buyCredit: "Pur_power",
        accountNoLinkBuyCredit: "Pur_power Acc",
        extendMethod: "Extension method",
        introduceStaffName: "Intro",
        introduceStaffCode: "Intro code",
        channel: "Channel",
        status: "Status",
        total: "All",
        liquidation: "Liquidation",
        periodMsg1: "From 1 day to 7 days",
        periodMsg2: "From 8 days to 14 days",
        periodMsg3: "From 15 days to 21 days",
        periodMsg4: "From 22 days to 29 days",
        periodMsg5: "1 month",
        periodMsg6: "2 months",
        periodMsg7: "3 months",
        periodMsg8: "4 months",
        periodMsg9: "5 months",
        periodMsg10: "6 months",
        periodMsg11: "9 months",
        periodMsg12: "1 year",
      },
      doubleClickTooltip: "Double click to change",
    },
    createAppendixCard: {
      cardTitle: "Add Appendix",
      cardInfo: {
        accountNum: "Acc number",
        withDrawCash: "Cash balance",
        period: "Period",
        days: "Date",
        openDate: "Open date",
        expireDate: "Expired date",
        cooperationDays: "Cooperation days",
        capitalValue: "Capital value",
        enterValue: "Enter capital value",
        interestRate: "Interest (%/year)",
        profitEstimate: "Total income",
        calcPurchaseAbility: "Purchase_power",
        linkType0: "Not Purchase_power",
        linkType1: "Purchase_power",
        accountDesignation: "Account",
        accountNoLinkBuyCredit: "Purchase_power Acc",
        extensionMethod: "Extension method",
        extensionType0: "Expand original auto",
        extensionType1: "Expand original and interest auto",
        extensionType2: "Not expand",
        introduceStaffName: "Introducer code",
        introduceStaffCode: "Intro Name",
        enterStaffCode: "Enter code",
        staffCodeError: "Introducer code isn't exist",
        confirmationText:
          "I read and agreed with terms and conditions service at MBS.",
        createSuccessMsg: "Add appendix successfully",
        valueMoreWithdrawCashError:
          "Balance is not enough to make this transaction",
        valueEmpty: "Please enter capital value",
        noteTitle: "NOTE",
        noteText1: `"Real date" > "active date" customer registed cooperate.`,
        noteText2: `You choose priod name and real date to accept interest.`,
      },
      cardActions: {
        cancel: "Cancel",
        agree: "Confirm",
      },
    },
    liquidationModal: {
      modalTitle: "Liquidation",
      accountNum: "Acc number",
      appendixNum: "Appendix no",
      openDate: "Open date",
      expireDate: "Expire date",
      capitalValue: "Capital value",
      liquidationDate: "Liquidate",
      numberDayBorrow: "Date",
      liquidFeeRate: "Interest (%/day)",
      liquidValue: "Liquid Value",
      liquidFee: "Total Income",
      confirmationText:
        "I read and agreed with terms and conditions service at MBS",
      cancel: "Cancel",
      agreeLiquid: "Liquidation",
      liquidSuccessMsg: "Liquid successful",
    },
    interestPercentTable: {
      tableTitle: "Interest",
      feeType: "Interest",
      feeTypeInterest: "Contract",
      feeTypeAuto: "Contract auto",
      feeTypeInterestLiquid: "Early_liquidation",
      customerGroup: "Customer team",
      personnal: "Person",
      organizational: "Organization",
      search: "Search",
      rowNum: "ID",
      period: "PERIOD",
      fromDate: "Issued date",
      fromValue: "From value(VNĐ)",
      toValue: "To value (VNĐ)",
      interestPercent: "Interest (%/year)",
    },
    editModal: {
      modalTitle: "Edit",
      accountNum: "Acc number",
      appendixNum: "Apendix no",
      openDate: "Open date",
      capitalValue: "Capital value",
      calcBuyCredit: "Purchase_power",
      calcBuyCreditAccount: "Purchase_power Acc",
      designationAccount: "Account",
      extensionMethod: "Expand method",
      cancel: "Cancel",
      agreeLiquid: "Update",
      editSuccessMsg: "Edit appendix successful",
    },
    createContractFrameModal: {
      modalTitle: "Register business service contract",
      accountNum: "Acc number",
      openDate: "Open date",
      expireDate: "Expired date",
      confirmationText:
        "I read and agreed with terms and conditions service at MBS.",
      HTKD: "Contract business service",
      DVCalcBuyCredit: "Purchase_power service",
      cancel: "Cancel",
      agree: "Confirm",
      registerSuccessMsg: "Register successful",
    },
  },
  statement: {
    errorDay: "Close date do not the larger to date",
  },
  bonds: {
    cancelBuy: {
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      bondCode: "Mã trái phiếu",
      contractCode: "Số HĐ",
      buyDate: "Ngày GD mua",
      maturityDate: "Ngày kết thúc đầu tư dự kiến",
      termRate: "Lợi suất (%/năm)",
      sellVol: "KL bán",
      price: "Đơn giá",
      contractValue: "Tổng GTGD",
      cancel: "ĐĂNG KÝ HỦY",
      cancelLeft: "Yêu cầu hủy",
      cancelRight: "Hủy bán để nắm giữ đến khi đáo hạn",
      cancelButton: "Hủy bán",
    },
    manageBond: {
      createDateFrom: "Từ ngày",
      createDateTo: "Đến ngày",
      buy: "Mua",
      sell: "Bán",
      DA_GIAO_DICH_MUA: "Đã giao dịch mua",
      DA_GIAO_DICH_BAN: "Đã giao dịch bán",
      CHO_BAN: "Chờ bán",
      contractCode: "SỐ HỢP ĐỒNG",
      transType: "LOẠI GIAO DỊCH",
      vol: "KHỐI LƯỢNG (TP)",
      price: "ĐƠN GIÁ",
      contractValue: "GTGD",
      termRate: "LỢI SUẤT (%/NĂM)",
      buyDate: "NGÀY MUA DỰ KIẾN",
      finishDate: "NGÀY KT DỰ KIẾN",
      sellDate: "THỜI GIAN GIAO DỊCH",
      buyChannel: "KÊNH MUA",
      sellChannel: "KÊNH BÁN",
      accountReceiveBank: "MỞ TẠI",
      statusCode: "TRẠNG THÁI",
      accountReceiveCode: "TK nhận tiền",
    },
    buyBond: {
      rowNum: "STT",
      bondCode: "MÃ TRÁI PHIẾU",
      roomBalance: "KHỐI LƯỢNG TP CÒN LẠI",
      termRate: "LỢI SUẤT ĐÁO HẠN NET (%/NĂM)",
      maturityDate: "NGÀY ĐÁO HẠN",
      timeRemaining: "THỜI GIAN CÒN LẠI (THÁNG)",
      minInvestmentValue: "SỐ TIỀN TỐI THIỂU",
    },
    owningBond: {
      contractValue: "GIÁ TRỊ ĐẦU TƯ",
      cashNonInvestSum: "TỔNG GT ĐẦU TƯ DỰ KIẾN",
      accountReceiveCode: "TK NHẬN TIỀN",
      accountReceiveAddr: "MỞ TẠI",
      channel: "KÊNH ĐẶT LỆNH",
      cancelSell: "HỦY BÁN",
      sell: "BÁN",
    },
    sellBond: {
      HUY_DAT: "Hủy đặt",
      HUY_GIAO_DICH: "Hủy giao dịch",
      KY_THUAT: "Kỹ thuật",
      SUA_GIAO_DICH: "Sửa giao dịch",
      DA_GIAO_DICH: "Đã giao dịch",
      DA_SUA_GIAO_DICH: "Đã sửa giao dịch",
      buyPrice: "GIÁ MUA",
      buyDate: "NGÀY GIAO DỊCH",
      footerTable:
        'Quý khách sử dụng chức năng Bán trái phiếu để thay đổi thời gian đầu tư của HĐ, từ "Tới khi đáo hạn Trái phiếu" sang kỳ hạn ngắn hơn.',
    },
    infoBond: {
      issuerBond: "Tổ chức phát hành",
      bondCode: "Mã trái phiếu",
      parPrice: "Mệnh giá trái phiếu",
      bondNum: "Khối lượng phát hành",
      bondVal: "Tổng giá trị mệnh giá phát hành",
      issuePurpose: "Mục đích phát hành",
      moneyAndPay: "Đồng tiền phát hàng và thanh toán",
      VND: "Đồng Việt Nam",
      termBond: "Kỳ hạn trái phiếu",
      releaseDate: "Ngày phát hành",
      maturityDate: "Ngày đáo hạn",
      interestbonds: "Lãi suất danh nghĩa Trái Phiếu",
      applyInterest: "Lãi suất áp dụng cho ",
      firstInterest: " kỳ tính lãi đầu tiên của Trái Phiếu là ",
      year: " %/năm.",
      applyYear:
        " %/năm.Lãi suất áp dụng cho các kỳ tính lãi tiếp theo của Trái Phiếu sẽ được xác định bằng lãi suất tham chiếu cộng (+) biên độ ",
      datePayment: "Ngày thanh toán lãi",
      couponPayment1: "Tiền lãi Trái phiếu được trả sau, định kỳ mỗi ",
      couponPayment2: " tháng một lần vào ngày cuối cùng của mỗi Kỳ Tính Lãi",
      paymentMethod: "Phương thức thanh toán gốc, lãi Trái phiếu",
      typeOfBond: "Loại hình Trái phiếu",
      releaseForm: "Hình thức Trái phiếu",
      depositAgents: "Đại lý đăng ký lưu ký",
      paymentAgents: "Đại lý thanh toán",
      collateral: "Tài sản đảm bảo",
      collateralAgents: "Đại lý quản lý TSĐB",
      repreOwner: "Đại diện NSH Trái phiếu",
      issuerBond1:
        "Các điều khoản, điều kiện khác của Trái Phiếu theo thông tin nêu tại Bản Công bố thông tin sơ bộ do ",
      ocDate1: " công bố ngày ",
      ocDate2:
        " và các tài liệu khác có liên quan (nếu có) (gọi chung là 'Các Văn Kiện Trái Phiếu')",
    },
    registerBuy: {
      errorVolNull: "Vui lòng nhập khối lượng trái phiếu",
      errorVol0: "Vui lòng nhập khối lượng trái phiếu",
      EXTERNAL_ACCOUNT: "TK Ngân hàng",
      INTERNAL_ACCOUNT: "TK GDCK",
      infoBond: "THÔNG TIN TRÁI PHIẾU SỞ HỮU",
      termRate: "Lợi suất đáo hạn NET",
      numInvestDate: "Số ngày đầu tư",
      maturityDate: "Ngày đáo hạn TP",
      roomBalance: "Khối lượng TP còn lại",
      infoTrading: "THÔNG TIN GIAO DỊCH",
      accountPay: "TK GDCK",
      cashBalance: "Số dư tiền (VND)",
      buyVolMax: "KL mua tối đa",
      buyVolMin: "KL mua tối thiểu",
      date: "Ngày đăng ký mua",
      buyDate: "Ngày giao dịch mua",
      buyVol: "Khối lượng (TP)",
      enterVol: "Nhập khối lượng",
      buyPrice: "Đơn giá giao dịch",
      buyPrice2: "Giá trị giao dịch",
      buyFee: "Phí chuyển nhượng",
      buyFee2: "Tổng giá trị giao dịch",
      termCodeFuture: "Thời gian đầu tư dự kiến",
      termDate: "Ngày kết thúc đầu tư dự kiến",
      termRate2: "Lợi suất đầu tư NET ",
      detail: "Xem chi tiết dòng tiền đầu tư dự kiến",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      accountReceiveType: "Loại TK",
      accountReceiveCode: "Số TK",
      accountName: "Tên chủ TK",
      bankName: "Mở tại",
      bankBranch: "Chi nhánh",
      infoFrontUser: "THÔNG TIN NHÂN VIÊN TƯ VẤN ",
      optional: "(KHÔNG BẮT BUỘC)",
      contractMgtCode: "Mã NV quản lý",
      enterContractMgtCode: "Nhập mã NV quản lý",
      frontUserName: "Tên NV quản lý",
      distrCustomerCode: "Mã NV tư vấn",
      enterDistrCustomerCode: "Nhập mã NV tư vấn",
      distrCustomerName: "Tên NV tư vấn",
      contractDistrCode: "Sổ HĐ NV tư vấn",
      one: "1. Các điều khoản và Điều kiện mua Trái phiếu",
      two: "2. Đề nghị chuyển nhượng mua Trái phiếu",
      confirm:
        'Tôi xác nhận và đồng ý với "Các Điều khoản và Điều kiện Mua Trái Phiếu", " Đề nghị chuyển nhượng Mua Trái phiếu" đã nêu trên và các Văn kiện Trái Phiếu liên quan',
      registerBuy: "Đăng ký mua",
    },
    investmentDetail: {
      content: "NỘI DUNG",
      payCouponDate: "NGÀY TT",
      cashNonInvest: "SỐ TIỀN DỰ KIẾN NHẬN",
      cashCouponReInvest: "SỐ TIỀN COUPON TÁI ĐẦU TƯ",
      cashReInvest: "LÃI/LỢI SUẤT",
      payCouponDate2: "NGÀY ĐẦU TƯ",
      lastPayCouponDate: "NGÀY KẾT THÚC",
      detail: "Chi tiết dòng tiền đầu tư dự kiến nhận",
      sumCashFlowNonInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowNonInvest: "Lợi suất chưa bao gồm tái đầu tư Coupon",
      flowCashReInvests: "GIẢ ĐỊNH KH TÁI ĐẦU TƯ COUPON NHẬN ĐƯỢC",
      sumCashFlowReInvest: "Tổng dòng tiền từ Trái phiếu",
      termFlowReInvest: "Lợi suất đã bao gồm tái đầu tư Coupon ",
      year: " %/năm",
      tooltip1:
        "Tái đầu tư coupon: Là việc Khách Hàng tiếp tục đầu tư khoản coupon nhận được vào các hạng mục đầu tư khác trong thời gian nắm giữ Trái Phiếu (ví dụ gửi tiết kiệm….)",
      tooltip2:
        "Lợi suất đã tái đầu tư: Là lợi suất đầu tư Trái Phiếu Khách Hàng nhận được đã bao gồm tái đầu tư coupon (với giả định Khách Hàng tiếp tục đầu tư khoản coupon với lãi suất 7.2%/năm)",
      tooltip3:
        "Là lợi suất khách hàng thực nhận được trong thời gian đầu tư (MBS đã hỗ trợ các khoản thuế/phí liên quan)",
      dateCoupon: "Ngày thanh toán lãi Coupon",
      tooltipCoupon:
        "COUPON: Là lãi Trái Phiếu (đã trừ thuế thu nhập cá nhân (nếu có)) do Tổ Chức Phát Hành thanh toán. Lãi suất coupon và cách tính lãi coupon căn cứ theo quy định của Tổ Chức Phát Hành đối với Trái Phiếu.",
    },
    referenceRate: {
      termName: "THỜI GIAN ĐẦU TƯ",
      termRate: "LỢI SUẤT (%/NĂM)",
      content: "Bảng lợi suất tham khảo",
      bondCode: "Mã trái phiếu",
      buyValue: "Giá trị đầu tư",
    },
    transferBond: {
      dear: "Kính gửi:",
      mb: " Công ty cổ phần Chứng khoán MB",
      issuerBond:
        "Sau khi thỏa thuận, Bên chuyển nhượng và Bên nhận chuyển nhượng thống nhất thực hiện việc chuyển nhượng Trái phiếu ",
      bondCode1: " - Mã số Trái Phiếu: ",
      bondCode2:
        " từ bên chuyển nhượng sang bên nhận chuyển nhượng theo thông tin được nêu dưới đây:",
      taxValue: "Thuế TNCN (do MBS chi trả)",
      taxValue2: "(Thuế TNCN tạm khấu trừ = Tổng giá trị x 0.1%)",
      content: "ĐỀ NGHỊ CHUYỂN NHƯỢNG MUA TRÁI PHIẾU",
      contentSell: "ĐỀ NGHỊ CHUYỂN NHƯỢNG BÁN TRÁI PHIẾU",
      bondCode: "Mã trái phiếu",
      assignor: "BÊN CHUYỂN NHƯỢNG",
      organizationName1: "Tên tổ chức",
      organizationName2: "CTCP Chứng khoán MB",
      registrationNumber1: "Số ĐKKD",
      registrationNumber2: "116/GP-UBCK do UBCKNN cấp ngày 09/12/2013",
      address1: "Địa chỉ",
      address2: "Tầng M-3-7, Tòa nhà MB, Số 3 Liễu Giai - Ba Đình - Hà Nội",
      representative1: "Người đại diện",
      representative2: "Ông Trần Hải Hà",
      position1: "Chức vụ",
      position2: "Tổng giám đốc",
      transferParty: "BÊN NHẬN CHUYỂN NHƯỢNG",
      fullName: "Tên cá nhân",
      identityCardId: "Số CMND/CCCD",
      issueDate1: "Ngày cấp",
      issuePlace1: "Nơi cấp",
      accountReceiveCode: "Số tài khoản",
      bankName: "Mở tại",
      transferContent: "NỘI DUNG CHUYỂN NHƯỢNG",
      buyVol: "Khối lượng",
      unit: " Trái phiếu",
      parPrice: "Mệnh giá",
      parPrice2: " VND/Trái phiếu",
      buyPrice: "Giá chuyển nhượng",
      valueBuyPrice: "Tổng giá trị chuyển nhượng",
      buyFee: "Phí chuyển nhượng",
      VND: " VND",
      textArea1: "Tổ chức (Ông/ Bà): ",
      textArea2:
        " được ghi tên trên Sổ Đăng Ký và được sở hữu, hưởng mọi quyền lợi và chịu trách nhiệm về số trái phiếu chuyển nhượng nói trên kể từ ngày có xác nhận đăng ký chuyển nhượng của Công ty Cổ phần Chứng khoán MB. Bên nhận chuyển nhượng không chịu ảnh hưởng cũng như không phải chịu trách nhiệm về bất kỳ thỏa thuận liên quan đến Trái Phiếu giữa Tổ Chức Phát Hành và/hoặc Đại Lý Đăng Ký Lưu Ký và/hoặc Bên chuyển nhượng và/hoặc bên thứ ba nào khác mà Bên nhận chuyển nhượng không được thông báo bằng văn bản, ngoại trừ Bản Công Bố Thông Tin, Hợp Đồng Đại lý Đăng Ký Lưu Ký và Hợp đồng mua bán giữa Bên chuyển nhượng và Bên nhận chuyển nhượng.",
      textArea3:
        " ủy quyền cho Công ty Cổ phần Chứng khoán MB xác nhận đăng ký chuyển nhượng theo yêu cầu của các Nhà Đầu Tư (Bên chuyển nhượng, Bên nhận chuyển nhượng). Công ty Cổ phần Chứng khoán MB chỉ xác nhận tính hợp lệ về thủ tục và việc đăng ký quyền sở hữu trái phiếu, không xác nhận việc thanh toán giữa hai bên. Các Bên thừa nhận, trong mọi trường hợp, ",
      textArea4:
        " là đơn vị chịu trách nhiệm thanh toán gốc và/hoặc lãi Trái Phiếu cho Chủ Sở Hữu Trái Phiếu và cam đoan chịu trách nhiệm về tính đầy đủ, hợp pháp của nguồn tiền thanh toán gốc và/hoặc lãi cho Chủ Sở Hữu Trái Phiếu.",
    },
    registerSell: {
      canSell0:
        "Hợp đồng đang sửa đổi, Quý Khách chưa thể thực hiện giao dịch.",
      canSell_1:
        "Hợp đồng đang được cấp Giấy chứng nhận sở hữu, Quý Khách chưa thể thực hiện giao dịch.",
      buyValue: "Giá trị đầu tư (VND)",
      maturityDate: "Ngày kết thúc đầu tư",
      termNonInvest: "Lợi suất NET (%/năm)",
      infoSell: "THÔNG TIN GIAO DỊCH BÁN",
      date: "Ngày đề nghị bán",
      sellDate: "Ngày giao dịch bán",
      termRate: "Lợi suất (%/năm)",
      sellValue: "Tổng giá trị giao dịch (VND)",
      taxPit: "Tỷ lệ thuế TNCN",
      taxValue: "Thuế TNCN (Do MBS chi trả)",
      detail: "Xem chi tiết dòng tiền dự kiến nhận được khi bán TP",
      infoAccount: "THÔNG TIN TÀI KHOẢN NHẬN TIỀN",
      change: "Thay đổi",
      two: "1. Các điều khoản và Điều kiện bán Trái phiếu",
      three: "2. Đề nghị chuyển nhượng bán Trái phiếu",
      confirm:
        "Tôi xác nhận và đồng ý với Các Điều khoản và Điều kiện Bán Trái Phiếu, Đề nghị chuyển nhượng Bán Trái phiếu đã nêu trên và các Văn kiện Trái Phiếu liên quan",
      registerSell: "Đăng ký bán",
      contractCode: "Mã hợp đồng",
    },
    changeAccountBank: {
      success: "Thay đổi tài khoản nhận tiền thành công",
      changeAccountBank: "Thay đổi tài khoản nhận tiền",
    },
    tab: {
      BUYBONDS: "Mua trái phiếu",
      SELLBONDS: "Bán trái phiếu",
      OWNINGBONDS: "Sở hữu trái phiếu",
      MANAGEBONDS: "Quản lý GD trái phiếu",
      dayCareParams1: "Thời gian được thực hiện Đăng ký bán trong vòng tối đa ",
      dayCareParams2: " ngày làm việc kể từ Ngày GD mua Trái Phiếu.",
      infoTrading: "Thông tin trái phiếu giao dịch",
      registerBuy: "Đăng ký mua trái phiếu",
      registerSell: "Đăng ký bán trái phiếu ",
      cancelSell: "Hủy bán trái phiếu",
    },
    toast: {
      buySuccess: "Đăng ký mua thành công",
      cancelSellSuccess: "Hủy bán thành công",
      sellSuccess: "Đăng ký bán thành công",
    },
  },
  techAnalysis: {
    labelLeft: "Phân tích kỹ thuật",
    labelRight: "Thông tin chi tiết",
    labelDropdown: "CÀI ĐẶT CÁC KHỐI",
    tab: {
      title: "Tab",
      open: "Open",
      rename: "Rename",
      saveTheTemplate: "Save the template",
    },
    createBoard: {
      chooseABoard: "Choose a Board",
      all: "All",
      default: "Default",
      customized: "Customized",
      newBoard: "New Board",
      descNewBoard: "Adding widggets to an blank board.",
      standard: "Standard",
      descStandard: "For basic and new trader.",
      standard2: "Standard2",
      widgetsSettings: "Widgets Settings",
      suggestionsWidgetsSettings: "Select and drag and drop and board",
      addDisplayArea: "Add display area",
      hoverOverTheAreaToAdd: "Hover over the area to add",
      viewDoesNotExist: "View does not exist",
    },
    modalSaveBoard: {
      titleModal: "Save as a board",
      nameBoard: "Name board",
      description: "Description",
      cancel: "Cancel",
      save: "Save",
    },
    modalRenameBoard: {
      titleModal: "Rename Board",
      ok: "OK",
      cancel: "Cancel",
    },
    widgets: {
      technicalAnalysis: "Technical Analystic",
      priceList: "Step value",
      volumeAndTime: "Volume and time",
      news: "News",
      orderFlowDistribution: "Order flow distribution",
      orderFlowDistributionUnit: "(Unit: Billion VND)",
      finance: "Financing",
      organizationalInfo: "Business Infomation",
      tickerInfo: "Ticker infomation",
    },
  },
  changeStockStatus: {
    changeStatus: " chuyển trạng thái CK",
    shareStatus: "LOẠI CK",
    shareStatusIn: "TT NHẬN",
    shareVol: "SL CHUYỂN",
    enter: "Chuyển khoản",
    transactionDate: "NGÀY GIAO DỊCH",
    accountOut: "TK CHUYỂN",
    shareVol2: "SỐ LƯỢNG",
    shareStatusOut: "TRẠNG THÁI CHUYỂN",
    shareStatusIn2: "TRẠNG THÁI NHẬN",
    history: "Lịch sử chuyển trạng thái CK",
    changeStatusHeader: "Chuyển trạng thái",
    changeStatusSuccess: "Chuyển trạng thái chứng khoán thành công",
    bonds: "Trái phiếu",
    changeStockStatus: "Chuyển trạng thái chứng khoán",
    fromDateNull: "Quý khách chưa nhập Từ ngày",
    toDateNull: "Quý khách chưa nhập Đến ngày",
  },
  proInvestor: {
    header: {
      registrationHistory: "Lịch sử đăng ký",
      registerPro: "Đăng ký NĐT chuyên nghiệp",
    },
    noti: {
      success: "Thành công",
    },
    button: {
      close: "Đóng",
      search: "Tìm Kiếm",
    },
    popUp: {
      title: "Thông tin nhà đầu tư chuyên nghiệp",
      condition:
        "Theo quy định của Luật chứng khoán số 54/QH2020, từ ngày 1/1/2021, đối tượng mua Trái Phiếu phát hành riêng lẻ phải là nhà đầu tư chứng khoán chuyên nghiệp “NĐTCN”.",
      conditionPeople:
        "Với cá nhân, để là NĐTCN, KH cần đáp ứng 1 trong các tiêu chí sau:",
      coditionCategory:
        "- Sở hữu danh mục chứng khoán niêm yết, đăng ký giao dịch tại các Sở Giao dịch (Theo quy định của Luật Chứng khoán có hiệu lực ngày 1/1/2021) có giá trị tối thiểu là 02 tỷ đồng.",
      conditionFee:
        "- Có thu nhập chịu thuế năm gần nhất tối thiểu là 01 tỷ đồng theo hồ sơ khai thuế đã nộp cho cơ quan thuế hoặc chứng từ khấu trừ thuế của tổ chức, cá nhân chi trả.",
      conditonJob: "- Có chứng chỉ hành nghề chứng khoán.",
    },
    history: {
      number: "STT",
      registDate: "Ngày đăng ký",
      bondCode: "Mã trái phiếu",
      volume: "Khối lượng",
      unitPrice: "Đơn giá",
      transaction: "GT giao dịch",
      purchaseFee: "Phí mua",
      totalValue: "Tổng giá trị",
      status: "Trạng thái",
    },
    form: {
      account: "Tài khoản",
      name: "Họ tên",
      status: "Trạng thái",
      acceptCondition: "Quý KH đã đủ điều kiện trở thành NĐT CN",
      registed: "Quý KH đã đăng ký dịch vụ NĐT CN",
      noInfo: "Quý KH chưa có thông tin NĐT CN tại MBS",
      button: "Trải nghiệm NĐT CN",
    },
    modal: {
      title:
        "ĐK mua Trái phiếu niêm yết từ MBS để có đủ điều kiện xác nhận là NĐT CN:",
      idAccount: "Số tài khoản",
      regisDate: "Ngày ĐK",
      valueMine: "GT DMCK sở hữu",
      valueBone: "GT cần mua trái phiếu",
      moneyBalance: "Số dư tiền",
      codeBone: "Mã trái phiếu",
      release: "TCPH",
      info: "GT cần mua TP = 2 tỷ đồng - GT DMCK sở hữu * Tỷ lệ chiết khấu theo chính sách MBS từng thời kỳ.",
      transactionInfo: {
        title: "Thông tin giao dịch",
        dayTrading: "Ngày GD",
        price: "Đơn giá",
        volume: "Số lượng",
        valueTransaction: "Gía trị GD",
        feeTransaction: "Phí GD",
        tax: "Thuế bán + phí CK",
        sumTransaction: "Tổng tiền GD",
        account: "TK cắt tiền",
        moneyBalance: "Số dư tiền",
        buyTransaction: "GD MUA",
        sellTransaction: "GD BÁN",
      },
      footer: {
        note: "Ghi chú:",
        conditionMoney:
          "- Để trở thành NĐT CN, Quý khách cần nắm giữ danh mục CK NY từ 02 tỷ đồng.",
        conditionDate:
          "- Sau 1 ngày làm việc kể từ ngày GD Mua Trái phiếu thành công, Quý khách sẽ được MBS xác nhận là NĐT CN.",
        conditionAccept:
          "- Sau 02 ngày làm việc kể từ ngày thực hiện GD Mua thành công, hệ thống sẽ tự động Bán lại toàn bộ Trái phiếu mà Quý khách đã   Mua trong giao dịch này!",
        conditionInTime:
          "- Trong thời gian đăng ký trải nghiệm NĐTCN tại MBS, kính đề nghị Quý KH không bán chứng khoán niêm yết trên tài khoản để đảm bảo duy trì số dư khi MBS xác nhận KH đủ điều kiện trở thành NĐTCN.",
        register: "Đăng ký",
      },
      error: {
        noVolume: "Vui lòng nhập số lượng",
        min: "Vui lòng nhập số lượng",
        room: "Hiện tại đã hết Hạn mức giao dịch Nhà đầu tư chuyên nghiệp. Quý Khách vui lòng đăng ký lại sau.",
      },
      placeHolder: {
        input: "Nhập số lượng",
      },
    },
  },
  textConfirmLoanExtension:
    "Tôi đã đọc và đồng ý với các điều khoản thỏa thuận trong hợp đồng ký quỹ, phụ lục đồng và các chính sách dịch vụ tài chính đang áp dụng tại MBS",
  toast: {
    success: "Notice",
    warning: "Warning",
    error: "Error",
    default: "Notice",
  },
  smartOtp: {
    alert: "Vui lòng mở ứng dụng MBS Mobile và lấy mã SmartOTP",
  },
  statusWarrant: {
    QUYEN_CHUA_THUC_HIEN: "Quyền chưa thực hiện",
    QUYEN_CHO_VE: "Quyền chờ về",
  },
  registerCKService: {
    title: "Đăng ký tài khoản dịch vụ Phái sinh",
    confirm:
      "Tôi đã đọc, hiểu và đồng ý với các điều khoản và điều kiện về Giao dịch chứng khoán phái sinh tại MBS.",
  },
  cleanCache: {
    title: "Xóa cache",
    contentConfirm: "Bạn chắc chắn muốn xóa bộ nhớ tạm thời",
  },
  tooltipAccountReceiveCode: "Kích để thay đổi TK nhận tiền",
  modalTransferMoney: {
    sidebar: "HD chuyển tiền vào TKCK",
    headerTitle: "HƯỚNG DẪN CHUYỂN TIỀN VÀO TKCK",
    TAB_MB: "Từ NH MB",
    TAB_FAST: "Siêu nhanh - 9612",
    TAB_NORMAL: "Chuyển thông thường",
    toastSuccessNumber: "Đã sao chép số tài khoản chuyển tiền",
    toastSuccessContent: "Đã sao chép nội dung chuyển tiền",
    TabFastTextRed:
      "Quý khách có TK tại các NH khác MB/BIDV, sử dụng DV Ngân hàng điện tử trên web/app để chuyển tiền vào TKCK tại MBS 24/7, tự động hạch toán như dưới đây:",
    step1: "Bước 1:",
    TabFastStep1: "Đăng nhập app/web Ngân hàng chọn Chuyển tiền",
    TabFastStep2_1: "Thông tin Người nhận chọn:",
    TabFastStep2_2: "Ngân hàng: Đầu tư phát triển - BIDV",
    TabFastStep2_3: "Số Tài khoản: 9612+ Số TKCK 7 số (tiểu khoản tại MBS)",
    TabFastStep2_4: "Họ tên: Tự động hiển thị tên theo số TKCK nhập ở trên",
    TabFastStep2_5: "Số tiền: Nhập số tiền muốn chuyển vào TKCK",
    TabFastStep2_6: "Ví dụ: ",
    TabFastStep2_7: "Nhập Số tài khoản nhận ",
    TabFastStep2_8: "khi nộp tiền vào TKCK",
    TabFastStep2_9: "- Nộp tiền vào tài khoản thường (tiểu khoản 1): ",
    TabFastStep2_10: "- Nộp tiền vào tài khoản Margin (tiểu khoản 8): ",
    TabFastStep2_11: "- Nộp tiền vào tài khoản M-Credit (tiểu khoản 7): ",
    TabFastStep2_12: "(abcdef là số TKCK 6 số của Quý khách tại MBS)",
    step2: "Bước 2:",
    step3: "Bước 3:",
    TabFastStep3_1: "Xác nhận OTP và ấn Tiếp tục",
    step4: "Bước 4:",
    TabFastStep4_1:
      "Xác nhận OTP và ấn Tiếp tục Giao dịch thành công, TKCK nhận được tiền ngay",
    note: "Nếu Quý Khách có tài khoản tại BIDV, Quý khách chọn mục “Thanh toán”, chọn chức năng “Nộp tiền chứng khoán” và nhập thông tin thụ hưởng: Công ty CP Chứng khoán MB và số tài khoản 9612+TKCK 7 số của Quý khách như trên.",
    step5: "Bước 5:",
    TabMBTextRed:
      "Quý khách vui lòng đăng nhập vào App MBBank và làm theo hướng dẫn:",
    TabMBStep1_1: "Chọn mục Sản phẩm",
    TabMBStep2_1: "Chọn Bảo hiểm, Chứng khoán và Vay tiêu dùng",
    TabMBStep3_1: "Chọn Chứng khoán MB",
    TabMBStep4_1: "Chuyển tiền đến tài khoản MBS",
    TabMBStep5_1: "Thêm (+) Người thụ hưởng mới và nhập thông tin",
    detail: "Xem chi tiết hướng dẫn ",
    here: "tại đây",
    TabNormalTextRed:
      "Quý khách lưu ý chuyển khoản đúng nội dung hướng dẫn để tiền được hạch toán tự động vào TKCK tại MBS",
    left: "Người thụ hưởng",
    right: "Công ty CP Chứng khoán MB",
    title: "Chuyển khoản tới một trong các tài khoản sau",
    bottom1: "MBBank - CN Ba Đình",
    bottom2: "MBBank - CN Hàm Nghi",
    bottom3: "Vietcombank - CN Hà Nội",
    bottom4: "BIDV - CN Hà Thành",
    bottom5: "Techcombank - CN Hội Sở",
    content: "NỘI DUNG CHUYỂN KHOẢN",
    top1: "Nộp tiền vào TK thường (đuôi 1)",
    top8: "Nộp tiền vào TK Margin (đuôi 8)",
    top7: "Nộp tiền vào TK Margin (đuôi 7)",
    bottom6: "Nop tien TKCK ",
    bottom7: "1 cua ",
    bottom8: "8 cua ",
    bottom9: "7 cua ",
  },
  saveQRSuccess: "Quét QR code thành công",
  saveOtpSuccess: "Đã lưu mã xác thực",
  smartOTP: {
    success: "Gửi mã OTP thành công",
    title1: "Quý khách vui lòng ",
    title2: "Lấy mã OTP",
    title3: " qua sms/email và lưu tại ô dưới đây",
    title4: "Quý khách đã lấy OTP thành công, vui lòng kiểm tra sms/email.",
  },
  industry: {
    name: "Chuyển động ngành",
    topIndex: "TOP đóng góp INDEX",
    transactionIndustry: "GD theo ngành",
  },
  foreignWatch: {
    topStocks: "TOP cổ phiếu mua bán ròng",
    topIndustry: "TOP ngành mua bán ròng",
  },
  modalSell: {
    shareStatus: "Trạng thái CK",
    shareOddLot: "CP lẻ",
    sellValue: "Giá trị bán",
    confirm1: "Tôi đã đọc và đồng ý với ",
    confirm2: "các điều khoản và điều kiện",
    confirm3:
      " về Giao dịch lô lẻ trực tuyến của MBS và xác nhận đăng ký dịch vụ.",
    note: "Ghi chú: giao dịch lô lẻ với giá sàn, phí dịch vụ 10% tổng giá trị giao dịch từ 23/07/2021",
  },
  modalSVD: {
    title1: "Trân trọng chào mừng Quý Khách hàng đến với MBS!",
    title2:
      "Kính chúc Quý khách có trải nghiệm giao dịch vượt trội và nhận được những ưu đãi hấp dẫn.",
    acceptText1: "Tôi đã đọc, hiểu và đồng ý với",
    acceptText2: " Bộ các điều khoản và điều kiện ",
    acceptText3: "Mở tài khoản và sửa dụng dịch vụ tại MBS",
    onSubmit: "TIẾP TỤC",
  },
  copi24: {
    tab: {
      TOPLEADER: "Top Leader",
      FOLLOW: "Theo dõi",
      MANAGECOPY: "Quản lý sao chép",
      HISTORY: "Lịch sử đầu tư",
    },
    modalCreateCashPayment: {
      valueAmount0: "Số tiền nộp phải > 0",
      cashBalance: "Số tiền nộp không được vượt quá Số dư tài khoản Cash",
      cashPayment: "Nộp tiền",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      amount: "Số tiền nộp",
      placeholder: "Nhập số tiền",
      noteRed:
        "Ghi chú: Sau khi nộp thêm tiền, đầu ngày giao dịch tiếp theo hệ thống sẽ tự động renew lại danh mục theo tỷ trọng Leader.",
      cancel: "Hủy",
    },
    modalCreateCashWithdraw: {
      valueAmount0: "Số tiền rút phải > 0",
      maximumCashWithdraw0: "Lớn hơn số tiền rút tối đa",
      cashWithdraw: "Rút tiền",
      account: "Tài khoản Copi24",
      totalAsset: "Giá trị tài sản",
      maximumCashWithdraw: "Số tiền rút tối đa",
      amount: "Số tiền rút",
      placeholder: "Nhập số tiền",
      cancel: "Hủy",
    },
    modalCreateCopy: {
      valueAmount0: "Giá trị sao chép phải > 0",
      cashBalance: "Giá trị sao chép không được vượt quá Số dư tài khoản Cash",
      COPY_MIN_AMOUNT0:
        "Giá trị sao chép không được nhỏ hơn giá trị sao chép tối thiểu",
      secPerNavRate0: "Bạn chưa nhập Tỷ trọng tối đa 1 mã",
      priceMaxPercent0: "Bạn chưa nhập Giá đặt chênh lệch tối đa",
      stopLossPercent0: "Bạn chưa nhập Danh mục lỗ",
      stopProfitPercent0: "Bạn chưa nhập Danh mục lãi",
      optionPriceType1: "Lệnh thị trường",
      optionPriceType2: "Lệnh LO",
      rule: "Quy tắc sao chép",
      account: "Tài khoản Copi24",
      cash: "Số dư tài khoản Cash",
      COPY_MIN_AMOUNT: "Giá trị sao chép tối thiểu",
      amount: "Giá trị sao chép (VND)",
      ALLOW_CHOOSE_PRICE_TYPE: "Nguyên tắc giá sinh lệnh",
      priceBuyPercent: "Chênh lệch giá MUA (%)",
      priceSellPercent: "Chênh lệch giá BÁN (%)",
      secPerNavRate: "Tỷ trọng tối đa 1 mã (%)",
      textLine1: "Lệnh sao chép phát sinh khi",
      textLine2: "Lệnh Leader khớp",
      isCopyCurrentPortfolio: "Sao chép DM hiện tại",
      stop: "Điều kiện lệnh dừng sao chép",
      stopLossPercent: "Danh mục lỗ (%)",
      stopProfitPercent: "Danh mục lãi (%)",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      cancel: "Hủy",
      copy: "Sao chép",
    },
    modalStopCopy: {
      title: "Dừng sao chép",
      account: "Tài khoản Copi24",
      amount: "Giá trị sao chép",
      profitAmt: "Lãi/lỗ",
      feeCopy: "Phí sao chép",
      total: "Tổng giá trị",
      NoteRed:
        "Ghi chú: Khi dừng sao chép, toàn bộ tiền và chứng khoán tự động được chuyển sang tài khoản 1",
      confirm: "Xác nhận chuyển toàn bộ danh mục sang tài khoản 1",
      cancel: "Hủy",
      stop: "Dừng sao chép",
    },
    modalUpdateAvatar: {
      title: "Cập nhật ảnh đại diện",
      error: "Ảnh đại diện của bạn không được vượt quá xxx Kbs",
      cancel: "Đóng",
      confirm: "Xác nhận",
    },
    modalCancelCopier: {
      title: "Đóng COPIER",
      confirm: "Bạn có chắn chắn muốn đóng Copier?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    modalCancelLeader: {
      toastSuccess:
        "Yêu cầu đóng leader đã được gửi tới hệ thống. Yêu cầu của quý khách sẽ được xử lý trong {day} ngày tới",
      title: "Đóng LEADER",
      confirm: "Bạn có chắn chắn muốn đóng Leader?",
      cancel: "Hủy",
      yes: "Đồng ý",
    },
    cardUser: {
      month: " /tháng",
      month6: " trong 6 thángg",
      portfolioPoint: "Điểm danh mục: ",
      totalFollow: "Tổng theo dõi:",
      totalCopy: "Tổng sao chép:",
      copy: "Sao chép",
      stop: " Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    topLeader: {
      topLeader: "TOP LEADER",
      registerCopier: "Đăng ký COPIER",
      cancelCopier: "Đóng COPIER",
      delay: "Chờ xử lý đóng",
      registerLeader: "Đăng ký LEADER",
      cancelLeader: "Đóng LEADER",
    },
    registerCopier: {
      registerCopier: "Đăng ký COPIER",
      customerId: "Tài khoản",
      confirm:
        "Tôi đã đọc và đồng ý với các điều khoản điều kiện đăng ký tham gia COPIER...,",
      cancel: "Hủy",
    },
    registerLeader: {
      disableButtonRegister: " của tài khoản không đáp ứng giá trị tối thiểu",
      registerLeader: "Đăng ký LEADER",
      accountRegister: "Tài khoản đăng ký ban đầu:",
      confirm:
        "Tôi đã đọc và đồng ý với các điều khoản điều kiện đăng ký tham gia LEADER...,",
    },
    tableRegisterLeader: {
      navValue: "Tài sản ròng",
      tradeMonthNo: "Số tháng đã giao dịch",
      tradingDiaryRate1: "Hiệu quả đầu tư (",
      tradingDiaryRate2: " tháng)",
      portfolioPoint: "Điểm số danh mục",
      title: "TIÊU CHÍ",
      valueMin: "GIÁ TRỊ TỐI THIỂU",
      valueAccount: "GIÁ TRỊ CỦA TÀI KHOẢN",
    },
    topFollow: {
      listTopFollow: "DANH SÁCH THEO DÕI",
    },
    manager: {
      total_amount: "Tổng giá trị đầu tư: ",
      total_profit_amt: "Lãi/lỗ:",
      total_value: "Tổng giá trị:",
      leaderAccoStat: "Tài khoản leader đã đóng hoặc đang xử lý đóng",
      amount: "Giá trị đầu tư",
      nav: "NAV",
      profitAmt: "Lãi/lỗ",
      profitPercent: "% lãi/lỗ",
      totalFeeDebt: "Phí sao chép",
      createCashPayment: " Nộp tiền",
      withdrawMoney: "Rút tiền",
      stopCopy: "Dừng sao chép",
      delay: "Dừng chờ xử lý",
    },
    history: {
      tradeDate: "NGÀY SAO CHÉP",
      leader: "LEADER",
      amount: "GIÁ TRỊ ĐẦU TƯ",
      profitAmt: "LÃI LỖ",
      profitPercent: "% LÃI LỖ",
      stopDate: "NGÀY DỪNG SAO CHÉP",
    },
    portTransaction: {
      tradeDate: "NGÀY GIAO DỊCH",
      secCd: "MÃ CK",
      tradeType: "LOẠI GD",
      profitAmt: "LÃI LỖ",
      profitPercent: "% LÃI LỖ",
      qty: "KHỐI LƯỢNG",
      price: "GIÁ",
      avgPrice: "GIÁ VỐN",
      feeAmt: "PHÍ",
      taxAmt: "PHÍ THUẾ KHÁC",
      history: "Lịch sử giao dịch",
      remarks: "GHI CHÚ",
      ratio: "TỈ TRỌNG",
      totalQty: "TỔNG",
      availableQty: "KHẢ DỤNG",
      waitingReceive: "CHỜ NHẬN",
      avgPrice2: "GIÁ TB",
      currentPrice: "GIÁ HIỆN TẠI",
      buyAmt: "GIÁ TRỊ MUA",
      currentAmt: "GIÁ TRỊ HIỆN TẠI",
      tabRule1: "DANH MỤC SAO CHÉP",
      tabRule2: "QUY TẮC SAO CHÉP",
      priceMaxPercent: "Giá Mua chênh tối đa với DM hiện tại (%)",
      tradeDate2: "Ngày sao chép",
    },
  },
  marginContract: {
    tab: "Margin contract",
    info: {
      subAccoNo: "Sub account no",
      contrNo: "Control no",
      custGroupName: "Customer group name",
      retentionRate: "Retention rate",
      loanDays: "Loan days",
      warningDays: "Warning days",
      contrDateFrom: "Control date from",
      warningRate: "Warning rate",
      freeDays: "Free days",
      callRate: "Call rate",
      tnType: "Tn type",
      sourceLoan: "Source loan",
      brokerCd: "Broker cd",
      marginLimit: "Margin limit",
      marginLimitGrp: "Margin limit group",
    },
    filter: {
      account: "Sub account",
      fromDate: "From date",
      toDate: "To date",
      status: {
        title: "Status",
        all: "All",
        notApprove: "Not Approve",
        approved: "Approve",
        approveWaitEdit: "Approve Wait Edit",
        edited: "Edited",
        closed: "Closed",
      },
      contractState: {
        title: "Contract state",
        all: "All",
        notActive: "Not Active",
        actived: "Active",
      },
      broker: "Broker",
      search: "Search",
    },
    table: {
      alloDate: "Ngày tạo",
      refNo: "Số TT",
      contrNo: "Số HĐ",
      subAccoNo: "Tiểu khoản",
      custName: "Tên KH",
      marginGroupCode: "Mã nhóm",
      retentionRate: "TL duy trì",
      warningRate: "TL cảnh báo",
      callRate: "TL giải chấp",
      loanDays: "Số ngày vay",
      freeDays: "Số ngày miễn phí",
      warningDays: "Số ngày cảnh báo",
      tnType: "Loại nợ",
      sourceLoan: "Nguồn",
      brokerCd: "NVCS",
      contrDateFrom: "Ngày bắt đầu",
      status: {
        title: "Trạng thái",
        notApprove: "Chưa duyệt",
        approved: "Đã duyệt",
        approveWaitEdit: "Sửa chờ duyệt",
        edited: "Đã sửa",
        closed: "Đã xóa",
      },
      contractState: {
        title: "Trạng thái HĐ",
        notActive: "Chưa kích hoạt",
        actived: "Kích hoạt",
      },
      transactionCd: "Điểm giao dịch",
      remarks: "Ghi chú",
      regDateTime: "Thời gian tạo",
      regUserId: "Người tạo",
      updDateTime: "TG cập nhật",
      updUserId: "Người cập nhật",
    },
  },
  accountInfo: {
    tab: "Thông tin tài khoản",
    info: {
      custName: "Tên khách hàng",
      telNo: "Điện thoại",
      address: "Địa chỉ",
      mobileNo: "Di động",
      idNumber: "Số CMND",
      emailAdrs: "Email",
      issueDate: "Ngày cấp",
      issueLocation: "Nơi cấp",
      expireDate: "Ngày hết hạn",
      accoStrDate: "Ngày mở tài khoản",
      subAccoNo: "Ngày mở tài khoản",
      bankCd: "Lần truy cập gần nhất",
      bankAccoNumber: "Số tài khoản ngân hàng	",
      lastAccess: "Ngân hàng",
    },
    filter: {
      account: "Tài khoản",
      search: "Vấn tin",
    },
    table: {
      title: "Thông tin ủy quyền",
      entrustCustName: "Tên",
      adrs1: "Địa chỉ",
    },
  },
  marginSecurities: {
    tabName: "Danh mục chứng khoán Margin",
    filter: {
      subAccount: "Tiểu khoản",
      custGroup: "Nhóm khách hàng",
      secCd: "Mã CK",
      all: "Tất cả",
      defaultCustGroup: "Danh mục chung",
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      estimateRate: "TL định giá",
      marginRate: "TL ký quỹ",
      mLoanRate: "TL vay margin",
      minPrice: "Giá tối thiểu",
      maxPrice: "Giá tối đa",
      marketName: "Sàn",
      updDateTime: "Thời gian cập nhật",
    },
  },
  rightInfo: {
    tab: "Thông tin thực hiện quyền",
    filter: {
      secCd: "Mã CK",
      rightType: {
        title: "Loại THQ",
        all: "Tất cả",
        cashDevidend: "Tiền mặt",
        stockDividend: "Cổ tức CP",
        stockBonus: "Cổ phiếu thưởng",
        rightRegister: "Đăng ký PHT",
        bondConvertion: "Trái phiếu chuyển đổi",
        stockMerge: "Hoán đổi cổ phiếu",
        stockSplit: "Chia tách",
        delisting: "Hủy đăng ký",
        changeSecuritiesType: "Thay đổi loại hình",
        depositoryRegistration: "Đăng ký lưu ký",
        votes: "Biểu quyết",
        returnCapital: "Hoàn trả góp vốn",
        bondExpires: "Đáo hạn trái phiếu",
        buyCapital: "Mua lại vốn góp của TCPH",
        buyBondConvertion: "Quyền mua trái phiếu chuyển đổi",
      },
      ownerFixDate: "Ngày chốt",
      rightExcDate: "Ngày thực hiện",
      beginTradeDate: "Ngày GD",
      status: {
        title: "Trạng thái",
        all: "Tất cả",
        pending: "Chưa duyệt",
        approved: "Đã duyệt",
        deleted: "Đã xóa",
      },
      search: "Tìm",
    },
    table: {
      secCd: "Mã CK",
      marketSname: "Sàn",
      noRightDate: "Ngày KHQ",
      ownerFixDate: "Ngày chốt",
      rightExcDate: "Ngày TT",
      beginTradeDate: "Ngày GD",
      ownerListPeportDate: "Hạn nộp DS",
      rightRate: "Tỉ lệ quyền",
      rate: "Tỉ lệ",
      rightType: {
        title: "Loại THQ",
        cashDevidend: "Tiền mặt",
        stockDividend: "Cổ tức CP",
        stockBonus: "Cổ phiếu thưởng",
        rightRegister: "Đăng ký PHT",
        bondConvertion: "Trái phiếu chuyển đổi",
        stockMerge: "Hoán đổi cổ phiếu",
        stockSplit: "Chia tách",
        delisting: "Hủy đăng ký",
        changeSecuritiesType: "Thay đổi loại hình",
        depositoryRegistration: "Đăng ký lưu ký",
        votes: "Biểu quyết",
        returnCapital: "Hoàn trả góp vốn",
        bondExpires: "Đáo hạn trái phiếu",
        buyCapital: "Mua lại vốn góp của TCPH",
        buyBondConvertion: "Quyền mua trái phiếu chuyển đổi",
      },
      status: {
        title: "Trạng thái",
        pending: "Chưa duyệt",
        approved: "Đã duyệt",
        deleted: "Đã xóa",
      },
      beginRegDate: "Ngày BĐ ĐK",
      endRegDate: "Ngày KT ĐK",
      beginTransDate: "Ngày BĐ CN",
      endTransDate: "Ngày KT CN",
      price: "Giá PH",
      taxRate: "Thuế TNCN",
      secState: {
        title: "Loại CK",
        all: "Tất cả",
        popularity: "Phổ thông",
        restricted: "Hạn chế",
      },
      targetSecCd: "Mã CK chuyển",
      remarks: "Diễn giải",
      alloDate: "Ngày tạo",
      refNo: "STT",
      regDateTime: "Ngày tạo",
      regUserId: "Người tạo",
      approvedDateTime: "Ngày duyệt",
      approvedUserId: "Người duyệt",
    },
  },
  statisticOnday: {
    tabs: {
      statisticOndayTab: "Thống kế giao dịch",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      tradeDate: {
        label: "Ngày GD",
      },
      type: {
        label: "Thống kê theo",
        quantity: "Khối lượng giao dịch",
        value: "Giá trị giao dịch",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      matAmt: "GTGD",
      highestPrice: "Giá cao nhất",
      lowestPrice: "Giá thấp nhất",
    },
    exportExcel: "In dạng Excel",
  },
  statisticPrice: {
    tabs: {
      statisticPriceTab: "Statistic Price",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      type: {
        label: "Thống kê theo",
        floorPrice: "Giá sàn",
        ceilingPrice: "Giá trần",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      matAmt: "GTGD",
      best1OfferPrice: "Giá dư bán",
      best1BidPrice: "Giá dư mua",
    },
    exportExcel: "In dạng Excel",
  },
  statisticUpdown: {
    tabs: {
      statisticUpdownTab: "Thống kế CK tăng/giảm",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      type: {
        label: "Tỷ lệ",
        up: "Tăng",
        down: "Giảm",
      },
      fromDate: {
        label: "Từ ngày",
      },
      toDate: {
        label: "Đến ngày",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      updownPercent: "Tỷ lệ tăng giảm",
      updownValue: "Giá trị tăng giảm",
      beginClosedPrice: "Giá đóng cửa ngày bắt đầu",
      endClosedPrice: "Giá đóng cửa ngày kết thúc",
    },
    exportExcel: "In dạng Excel",
  },
  statisticHighestUpdown: {
    tabs: {
      statisticHighestUpdownTab: "Thống kế CK tăng/giảm cao nhất",
    },
    filter: {
      marketCd: {
        label: "Sàn GD",
        all: "Tất cả",
        hsx: "HSX",
        hnx: "HNX",
        upcom: "UPCOM",
        dccny: "DCCNY",
        der: "Phái sinh",
        otc: "OTC",
      },
      type: {
        label: "Tỷ lệ",
        up: "Tăng",
        down: "Giảm",
      },
      distance: {
        label: "Số ngày GD gần nhất",
      },
      search: "Tìm kiếm",
    },
    table: {
      secCd: "Mã CK",
      curentPrice: "Giá hiện tại",
      changePoint: "+/-",
      changePercent: "%",
      matQty: "KL giao dịch",
      matAmt: "GTGD",
      updownPercent: "Tỷ lệ tăng/giảm",
      beginClosedPrice: "Giá đóng cửa ngày bắt đầu tính",
    },
    exportExcel: "In dạng Excel",
  },
  mrgContractDebit: {
    tabs: {
      mrgContractDebitTab: "Theo dõi công nợ Margin",
    },
    filter: {
      accNo: {
        label: "Tiểu khoản",
        all: "Tất cả",
      },
      debitType: {
        label: "Loại vay",
        all: "Tất cả",
        debitType1: "Margin",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        status1: "Còn nợ",
        status2: "Sắp đến hạn",
        status3: "Đến hạn",
        status4: "Quá hạn",
        status5: "Đã trả hết nợ",
        status6: "Đã gia hạn",
      },
      search: "Tìm kiếm",
    },
    table: {
      contrDateFrom: "Ngày vay",
      contrDateTo: "Ngày hết hạn",
      subAccoNo: "Tiểu khoản",
      custName: "Tên KH",
      debitTypeName: "Loại vay",
      amount: "Tổng nợ",
      payedAmount: "Đã trả",
      pendingPayment: "Trả nợ chờ duyệt",
      currentDebit: "Còn nợ",
      payedInterest: "Lãi đã trả",
      todayInterest: "Lãi đến ngày hiện tại",
      endDayInterest: "Lãi đến hạn dự kiến",
      remainDays: "Số ngày còn lại",
      inRate: "Lãi suất trong hạn",
      outRate: "Lãi suất quá hạn",
      payedManager: "Phí QL đã trả",
      todayManager: "Phí QL đến ngày",
      managerRate: "Phí QL trong hạn",
      managerOutRate: "Phí QL quá hạn",
      initConfigId: "Mã lãi bậc thang",
      extendCount: "Số lần gia hạn",
      brokerCd: "Broker",
      branchSName: "Chi nhánh",
      placeOrder: "Đặt lệnh",
    },
  },
  marginContractWatch: {
    tabs: {
      marginContractWatchTab: "Theo dõi tài sản Margin",
    },
    filter: {
      accNo: {
        label: "Tiểu khoản",
        all: "Tất cả",
      },
      status: {
        label: "Trạng thái",
        all: "Tất cả",
        status1: "Cảnh báo",
        status2: "Xử lý",
        status3: "Dừng kích hoạt",
        status4: "Nợ quá hạn",
      },
      brokerCd: {
        label: "NVCS",
      },
      search: "Tìm kiếm",
    },
    table: {
      subAccNo: "Tiểu khoản",
      custName: "Tên KH",
      detail: "Chi tiết",
      totalAssetMargin: "Tổng tài sản Margin",
      realAsset: "Tài sản thực",
      totalDebtAndInt: "Tổng nợ + lãi",
      secValNeedSell: "GT CK cần bán",
      balanceValAdd: "GT Tiền bổ sung",
      secValAdd: "GT CK bổ sung",
      retentionRate: "Tỷ lệ duy trì",
      warningRate: "Tỷ lệ cảnh báo",
      callRate: "Tỷ lệ giải chấp",
      warningDays: "Số ngày cảnh báo liên tiếp",
    },
  },
  transferLocal: {
    tabs: {
      transferLocalTab: "Chuyển khoản nội bộ",
      transferRequest: "Yêu cầu chuyển khoản",
      transferHistory: "Lịch sử chuyển khoản",
    },
    transferLocalTab: {
      filter: {
        fromSubAccoNo: {
          label: "Tài khoản chuyển",
        },
        toSubAccoNo: {
          label: "Tài khoản nhận",
        },
        status: {
          label: "Trạng thái",
          all: "Tất cả",
          status0: "Chờ duyệt",
          status1: "Đã duyệt",
          status2: "Hạch toán nhận chờ duyệt",
          status3: "Hoàn thành",
          status9: "Hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        editDelete: "Sửa/Hủy",
        alloDate: "Ngày YC",
        refNo: "Số YC",
        fromSubAccoName: "Tên Tài khoản chuyển",
        fromSubAccoNo: "Tài khoản chuyển",
        toSubAccoNo: "TK nhận",
        toSubAccoName: "Tên Tài khoản nhận",
        amount: "Số tiền chuyển",
        feeAmount: "Phí CK",
        status: "Trạng thái",
        feeRate: "Tỉ lện phí",
        feeMin: "Phí CK tối thiểu",
        transferFeeFlg: "Cách tính phí",
        transactionName: "Chi nhánh",
        regUserId: "Người tạo",
        regDateTime: "Thời gian tạo",
      },
    },
    transferLocalHisTab: {
      filter: {
        fromDate: {
          label: "Từ ngày",
        },
        toDate: {
          label: "Đến ngày",
        },
        toSubAccoNo: {
          label: "Tài khoản nhận",
        },
        status: {
          label: "Trạng thái",
          all: "Tất cả",
          status0: "Chờ duyệt",
          status1: "Đã duyệt",
          status2: "Hạch toán nhận chờ duyệt",
          status3: "Hoàn thành",
          status9: "Hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        alloDate: "Ngày YC",
        refNo: "Số YC",
        fromSubAccoName: "Tên Tài khoản chuyển",
        fromSubAccoNo: "Tài khoản chuyển",
        toSubAccoNo: "TK nhận",
        toSubAccoName: "Tên Tài khoản nhận",
        amount: "Số tiền chuyển",
        feeAmount: "Phí CK",
        status: "Trạng thái",
        feeRate: "Tỉ lện phí",
        feeMin: "Phí CK tối thiểu",
        transferFeeFlg: "Cách tính phí",
        transactionName: "Chi nhánh",
        regUserId: "Người tạo",
        regDateTime: "Thời gian tạo",
      },
    },
    feePayFlag: {
      feeTypeRate: "Theo tỉ lệ",
      feeTypeConst: "Phí cố định",
    },
    message: {
      fromDateRequired: "Vui lòng nhập từ ngày",
      toDateRequired: "Vui lòng nhập đến ngày",
      invalidStatus: "Trạng thái không hợp lệ",
      checkTransferFlagEdit:
        "Bạn không có quyền sửa giao dịch bổ sung tài sản margin",
      amountCheck2: "Số tiền phải lớn hơn 0",
      amountCheck3: "Số tiền chuyển phải lớn hơn số tiền tối thiểu",
      amountCheck4: "Số tiền chuyển phải nhỏ hơn số tiền tối đa",
      transferFreeCheck1: "Phí phải là số",
      dialogCheckAvail: "Khả dụng của bạn không đủ để chuyển khoản",
    },
    detail: {
      accountFrom: "Tài khoản chuyển",
      accountTo: "Tài khoản nhận",
      amount: "Số tiền chuyển",
      fee: "Phí",
      remarks: "Ghi chú",
      balance: "Số dư tiền",
      transferLimit: "Khả dụng CK tối đa",
      withdrawableCash: "Khả dụng rút tiền mặt",
      withdrawableAsset: "Khả dụng rút theo TS",
      startTime: "Thời gian bắt đầu",
      endTime: "Thời gian kết thúc",
      minAmount: "Số tiền tối thiểu",
      maxAmount: "Số tiền tối đa",
      transferFeeRate: "Tỉ lệ phí",
      transferFeeMin: "Phí CK tối thiểu",
      transferFee: "Cách tính phí",
      transferFeeFlag: "Cách tính phí",
      accept: "Chấp nhận",
      reset: "Nhập lại",
    },
    confirmModal: {
      title: "Xác nhận chuyển khoản nội bộ",
      accountFrom: "Tài khoản chuyển",
      accountTo: "Tài khoản nhận",
      amount: "Số tiền chuyển",
      amountText: "Bằng chữ",
      fee: "Phí",
      feeSms: "Phí SMS",
      remarks: "Ghi chú",
      notes:
        "Không hiển thị lại vào lần sau, vui lòng kiểm tra lại chi tiết xác nhận.",
      accept: "Chấp nhận",
      cancel: "Hủy",
    },
  },
  contractDebit: {
    tabs: {
      loanTrackingTab: "Theo dõi dư nợ",
      paymentHistoryTab: "Lịch sử trả nợ",
      extendHistoryTab: "Lịch sử gia hạn",
    },
    loanTrackingTab: {
      filter: {
        account: "Tiểu khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",

        status: {
          label: "Trạng thái",
          all: "--Tất cả--",

          status1: "1. Còn nợ  ",
          status2: "2. Sắp đến hạn  ",
          status3: "3. Đến hạn  ",
          status4: "4. Quá hạn  ",
          status5: "5. Đã trả hết nợ  ",
          status6: "6. Đã gia hạn  ",
        },
        search: "Tìm kiếm",
      },

      table: {
        contrDateFrom: "Ngày vay",
        contrDateTo: "Ngày hết hạn",
        amount: "Số tiền vay",
        payedAmount: "Đã trả",
        currentDebit: "Còn nợ",
        payedInterest: "Lãi đã trả",
        todayInterest: "Lãi đến hiện tại",
        endDayInterest: "Lãi đến hạn dự kiến",
        extendCount: "Số lần gia hạn",
        status: "Trạng thái",
        button: {
          debtPayment: "Trả nợ",
          loanExtension: "Gia hạn",
        },
      },
      confirmModal: {
        title: "Thanh toán nợ vay",
        subAccount: "Tiểu khoản",
        contrDateFrom: "Ngày vay",
        contrDateTo: "Ngày hết hạn",
        currentDebit: "Còn nợ",
        interest: "Tiền lãi",
        fee: "Phí quản lý",
        amount: "Giá trị thanh toán",
        availableBalance: "Khả dụng thanh toán",
        content: "Nội dung",
        cancel: "Hủy",
        payment: "Xác nhận",
        loanExtend: "Gia hạn",
        title2: "Gia hạn khoản vay",
        totalLoanBLN: "Tổng nợ",
        fee2: "Phí gia hạn",
        extendDays: "Số ngày gia hạn",
        contentRemark: "{accoNo}  thanh toán khoản vay ngày {date}",
        contentConfirm: "Bạn muốn thực hiện trả nợ cho hợp đồng đã chọn ?",
        contentConfirm2: "Bạn chắc chắn muốn gia hạn món vay ?",
      },
    },
    paymentHistoryTab: {
      filter: {
        account: "Tiểu khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",

        status: {
          label: "Trạng thái",
          all: "Tất cả",

          status1: "Chờ duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        subAccount: "Tiểu khoản",
        deliverDate: "Ngày giải ngân",
        loanBalance: "Nợ gốc",
        paymentDate: "Ngày trả",
        amount: "Trả nợ",
        interestRate: "Trả lãi",
        fee: "Trả phí",
        numDaysInterest: "Số ngày tính lãi",
        statusList: {
          status1: "Chưa duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
        status: "Trạng thái",
        cancel: "Hủy",
      },
    },
    extendHistoryTab: {
      filter: {
        account: "Tiểu khoản",
        fromDate: "Từ ngày",
        toDate: "Đến ngày",

        status: {
          label: "Trạng thái",
          all: "Tất cả",

          status1: "Chờ duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
        search: "Tìm kiếm",
      },
      table: {
        cancel: "Hủy",
        subAccount: "Tiểu khoản",
        deliverDate: "Ngày giải ngân",
        expireDate: "Ngày hết hạn",
        pproveDate: "Ngày gia hạn",
        newExpireDate: "Ngày hết hạn mới",
        loanBalance: "Nợ gốc",
        amount: "Số tiền gia hạn",
        fee: "Phí gia hạn",
        extendCount: "Số lần gia hạn",
        status: "Trạng thái",
        statusList: {
          status1: "Chưa duyệt",
          status2: "Đã duyệt",
          status3: "Đã hủy",
        },
      },
    },

    message: {
      fromDateRequired: "Vui lòng nhập từ ngày",
      toDateRequired: "Vui lòng nhập đến ngày",
      amountCheck1: "Chưa nhập giá trị thanh toán",
      amountCheck2: "Giá trị thanh toán phải lớn hơn 0",
      debtPaymentSucesss: "Trả nợ thành công",
      loanExtendSuccess: "Gia hạn thành công",
      errorAvaiableBalance: "Khả dụng không đủ để gia hạn",
    },

    confirmModal: {
      accept: "Accept",
      cancel: "Cancel",
    },
  },

  ekyc: {
    title: "MỞ TÀI KHOẢN TRỰC TUYẾN",
    step0: {
      title:
        "Để thực hiện đăng ký, Quý khách vui lòng chuẩn bị các thông tin sau:",
      label1: "CMND hoặc CCCD còn hiệu lực theo quy định.",
      label2: "Máy tính hoặc Điện thoại có Camera.",
      label3: "Điện thoại di động để nhận mã xác thực (OTP).",
      note: "Trình duyệt khuyến nghị: Chrome (Window/Android/MacOS version 10.0.0 trở lên); Safari (iPhone/iPad version 12.4.1 trở lên)",
      start: "Bắt đầu",
      tutorial: "Xem hướng dẫn mở tài khoản",
    },
    step1: {
      label1: "Quý khách là nhà đầu tư ?",
      individual: "Cá nhân",
      organization: "Tổ chức",
      label2: "Quốc tịch của Quý khách ?",
      vn: "Việt Nam",
      foreign: "Nước ngoài",
      label3: "Chọn loại tài khoản",
      cash: "Tài khoản thường (Cash)",
      margin: "Tài khoản thông thường (Cash) và ký quỹ (Margin)",
      label4: "Nhân viên quản lý tài khoản",
      no: "Không",
      yes: "Có",
      brokerId: "Mã NV",
      brokerName: "Tên NV",
      next: "Tiếp tục",
    },
    step2: {
      phoneNull: "Quý khách chưa nhập Số điện thoại",
      emailNull: "Quý khách chưa nhập Email",
      phoneErr: "Số điện thoại không hợp lệ",
      emailErr: "Địa chỉ email không hợp lệ",
      title:
        "Thông tin quan trọng dùng để mở tài khoản và xác thực giao dịch của Quý khách trong tương lai.",
      inputNumberPhone: "Điện thoại di động",
      placeholderNumberPhone: "Nhập số điện thoại, VD: 098xxxxxxxxx",
      placeholderEmail: "Nhập địa chỉ email",
      resCheckNumberPhone:
        "Số điện thoại đã được sử dụng đăng ký tài khoản tại GMC.Quý khách có muốn tiếp tục đăng ký tài khoản?",
      resCheckEmail:
        "Email đã được sử dụng đăng ký tài khoản tại GMC.Quý khách có muốn tiếp tục đăng ký tài khoản?",
    },
    step3: {
      confirmCodeErr: "Quý khách chưa nhập mã xác thực",
      label1: "Quý khách vui lòng nhập mã OTP đã được gửi về số điện thoại ",
      label2: "Mã xác thực đã gửi có hiệu lực trong ",
      placeholder: "Nhập mã xác thực (OTP)",
      sendOTPAgain: "Gửi lại mã",
      otpFalse: "Mã OTP không hợp lệ. Quý khách vui lòng kiểm tra lại!",
    },
    step4: {
      imageNull: "Quý khách vui lòng chụp đủ 02 mặt CMND/CCCD !",
      label1: "Chụp/Tải ảnh mặt trước và mặt sau CMND/CCCD.",
      cmndFront: "CMND/CCCD MẶT TRƯỚC",
      cmndBack: "CMND/CCCD MẶT SAU",
      noMediaDevices:
        "Thiết bị bạn đang sử dụng không được hỗ trợ, vui lòng thử lại trên smartphone !",
      isPermissionDenied: "Quyền truy cập camera bị từ chối",
      openCamera: "Mở Camera",
      upload: "Chụp/ Tải ảnh",
      resCheckIdCode:
        "CMND/ CCCD đã có tài khoản tại GMC. Quý khách vui lòng liên hệ tổng đài để được trợ giúp. Trân trọng cảm ơn!",
      valid_date:
        "CMND/CCCD của Quý khách đã hết hạn, vui lòng liên hệ tổng đài để được hỗ trợ. Trân trọng cảm ơn!",
      birth_day:
        "Theo quy định pháp luật, độ tuổi được phép mở TKCK  từ 18 tuổi trở lên",
    },
    step5: {
      imageErr: "Ảnh khuôn mặt không hợp lệ",
      label1: "Xác thực khuôn mặt",
      resFaceLiveness:
        "Khuôn mặt xác thực không hợp lê. Quý khách vui lòng thực hiện lại!",
      resFaceCompare:
        "Khuôn mặt không khớp trên giấy tờ. Quý khách vui lòng thực hiện lại!",
    },
    step6: {
      null: "Quý khách vui lòng nhập đầy đủ thông tin các trường có dấu (*) bắt buộc",
      idExpiredDateErr:
        "CMND/CCCD của Quý khách đã hết hạn, vui lòng liên hệ tổng đài để được hỗ trợ. Trân trọng cảm ơn!",
      dobErr:
        "Theo quy định pháp luật, độ tuổi được phép mở TKCK  từ 18 tuổi trở lên",
      female: "Nữ",
      male: "Nam",
      fullName: "Họ và tên",
      dob: "Ngày sinh",
      gender: "Giới tính",
      nationality: "Quốc tịch",
      label1: "Thông tin giấy tờ CMND/ CCCD",
      cardId: "Số CMND/ CCCD",
      cardType: "Loại giấy tờ",
      issueDate: "Ngày cấp",
      idExpiredDate: "Ngày hết hạn",
      issuePlace: "Nơi cấp",
    },
    step7: {
      label1: "Thông tin liên lạc",
      label2: "Điện thoại di động",
      nationality: "Quốc gia",
      address: "Địa chỉ thường trú",
      city: "Tỉnh/ Thành phố",
      placeholderCity: "Chọn Tỉnh/ Thành phố",
      contactAddress: "Địa chỉ liên lạc",
    },
    step8: {
      checkValid:
        "Phải nhập đủ thông tin: Ngân hàng, Số tài khoản, Chi nhánh, Tỉnh thành",
      label1: "Đăng ký tài khoản ngân hàng",
      note1: "Lưu ý: Tài khoản ngân hàng phải chính chủ",
      addBank: "Thêm tài khoản",
      bankName: "Ngân hàng",
      placeholderBankName: "Vui lòng chọn ngân hàng",
      cityBank: "Tỉnh/ Thành phố",
      placeholderCityBank: "Chọn Tỉnh/ Thành phố",
      bankAccountNo: "Số tài khoản",
      placeholderBankAccountNo: "Vui lòng nhập số tài khoản",
      branch: "Chi nhánh",
      placeholderBranch: "Tên chi nhánh",
    },
    step9: {
      label1: "Đăng ký chữ kỹ mẫu",
      label2:
        "Chữ ký của Quý khách hợp lệ khi Ký và ghi rõ Họ tên bằng chữ viết tay trên giấy trắng. Chữ ký mẫu này dùng làm cơ sở để kiểm tra chữ ký của Quý khách khi thực hiện giao dịch tại GMC.",
    },
    step10: {
      custodyCDNull: "Quý khách cần nhập đủ 6 số",
      custodyCDDupli: "Tài khoản đã tồn tại",
      brokerIdErr: "Mã nhân viên môi giới không hợp lệ",
      label1: "Chọn số tài khoản",
      note1:
        "Lưu ý: Hệ thống ưu tiên số tự chọn dành cho khách hàng kích hoạt hoàn tất đăng ký sớm nhất",
      label2:
        "Quý khách có thể lựa chọn mở tài khoản với dãy số là các chữ số đẹp, chữ số theo yêu cầu, sở thích. Hoặc bỏ qua bước này, số tài khoản sẽ được hệ thống cung cấp tự động",
      custodyCD: "Chọn số tài khoản",
      custodyCDAuto: "Hệ thống tự cấp",
      noteInput: "Số tài khoản phải gồm 6 chữ số !",
    },
    sideBar: {
      step1: "Chọn tài khoản đăng ký",
      step2: "Xác thực thông tin",
      step3: "Xác thực giấy tờ khuôn mặt",
      step4: "Bổ sung thông tin cá nhân",
    },
    step11: {
      label1: "Đăng ký loại dịch vụ môi giới",
      investorPro:
        "Investor Pro: Dịch vụ chọn gói, có chuyên viên môi giới tư vấn & chăm sóc khách hàng",
      investorSmart: "Investor Smart: Tự động hóa giao dịch",
      label2: "Đăng ký sử dụng Dịch Vụ Giao Dịch Trực Tuyến",
      iStock: "I-Stock (Giao dịch qua Internet)",
      tStock: "T-Stock (Giao dịch qua Call Center)",
      label3:
        "Đăng ký hình thức nhận thông tin, thông báo, sao kê tài khoản, kết quả giao dịch chứng",
      transactionByEmail: "Sao kê định kỳ (qua email)",
      mStock: "M-Stock - Dịch vụ tin nhắn SMS",
      label4:
        "Đăng ký Dịch Vụ Ứng Trước Tiền Bán Chứng Khoán tự động cho tiểu khoản thưởng",
      label5: "Đăng ký phương thức quản lý tiền:",
      cashAtBank:
        "Trực tiếp mở tài khoản tại ngân hàng thương mại do GMC lựa chọn để quản lý giao dịch chứng khoán",
      cashAtGMC:
        "Thông qua tài khoản chuyên dụng của GMC mở tại ngân hàng thương mại",
    },
    step12: {
      confirmNull:
        "Quý khách vui lòng đồng ý với Điều khoản và điều kiện mở tài khoản của GMC",
      label1: "Xác nhận điều khoản hợp đồng",
      content1:
        "Trên cơ sở đề nghị mở tài khoản giao dịch chứng khoán của Khách Hàng, hai Bên đã thống nhất ký kết Hợp Đồng với các điều khoản và điều kiện: ",
      detail: "Xem thông tin chi tiết",
      label2: "Thông tin liên quan đến Hoa Kỳ (cho mục đích tuân thủ FATCA)",
      optionA:
        "(a) Khách Hàng là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa Kỳ (*) hoặc là đối tượng phải khai thuế tại Hoa Kỳ.",
      optionB:
        "(b) Khách Hàng không phải là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa Kỳ nhưng có dấu hiệu nhận biết Hoa Kỳ (**).",
      optionC:
        "(c ) Khách Hàng không phải là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa Kỳ không có dấu hiệu nhận biết Hoa Kỳ.",
      note2:
        "Bằng cách tích chọn vào ô thích hợp bên trên và ký xác nhận trên Hợp Đồng này, Khách Hàng cam đoan các thông tin kê khai là chính xác và hoàn chỉnh. Khách hàng cam kết sẽ thông báo cho GMC trong thời hạn 30 ngày kể từ ngày có sự thay đổi về thông tin như đã kê khai như trên. Khách hàng hiểu rằng, tuyên bố hoặc che giấu về tình trang thuế của cá nhân Hoa Kỳ có thể dẫn đến các chế tài/hình phạt theo pháp luật thuế Hoa Kỳ, bao gồm cả FATCA.",
      label3: "Thời gian liên hệ & hình thức chỉnh gửi hồ sơ",
      content2: "1.Thời gian GMC liên hệ gửi hồ sơ",
      content3: "2.Hình thức gửi hồ sơ",
      content4:
        "Tôi đã đọc và đồng ý với các Điều khoản và điều kiện hợp đồng nêu trên",
    },
    step13: {
      title: "HOÀN TẤT",
      label1:
        "Cảm ơn Quý khách đã đăng ký mở tài khoản tại Công ty cổ phần chứng khoán GMC!",
      label2:
        "Để hoàn tất đăng kí, Quý khách vui lòng kiểm tra và thực hiện theo hướng dẫn đã được hệ thống gửi đến Email.",
      label3: "Kính chúc quý khách đầu tư thành công!",
    },
    progressCard: {
      step1: "Xác Thực Thông Tin",
      step2: "Đăng ký dịch vụ",
      step3: "Ký Hợp Đồng",
      step4: "Hoàn Tất",
    },
  },

  profitLoss: {
    filter: {
      account: "Account",
      fromDate: "From date",
      toDate: "To date",

      ticker: "Ticker",
      search: "Search",
    },

    profitLoss: "Profit loss",
    chartProfitLoss: "Chart Profit loss",
    netflow: "Netflow",

    fromDate: "From date",
    toDate: "To date",

    overDate: "From date must to less than to date",
    lengthDate: "Exceeded the 100-day search",

    table: {
      index: "ID",

      account: "ACCOUNT",
      ticker: "TICKER",
      sellVolumn: "SELL VOLUME",
      buyPrice: "BUY PRICE",
      value: "VALUE",
      profitLoss: "PROFIT LOSS",
      percent: "PERCENT",
      tradingDate: "TRADING DATE",
      action: "ACTION",

      content: "CONTENT",
      totalProfit: "TOTAL PROFIT",
    },
    groupCategory: {
      OD001: "Bán",
      OD01: "Mua",
      OD02: "Bán",
      KR03: "Gửi CK",
      KR04: "Gửi CK",
      KR05: "Gửi CK",
      KD03: "Rút CK",
      KD04: "Rút CK",
      KD05: "Rút CK",
      KD06: "Hủy rút CK",
      KC01: "Tất toán TK",
      KC02: "Tất toán TK",
      KC03: "Tất toán TK",
      KC04: "Tất toán TK",
      KC05: "Tất toán TK",
      KO03: "Bán",
      KO04: "Bán",
      KT03: "Chuyển khoản",
      KT04: "Chuyển khoản",
      RC01: "Cổ tức tiền",
      RS01: "Cổ tức CK",
      RS02: "Cổ tức CK",
      RS03: "Cổ tức CK",
      RI02: "CK mua PHT",
      RI03: "Nhận CK PHT",
      RI04: "CK PHT",
      RI05: "Hủy mua PHT",
      RU00: "Hủy niêm yết",
      RU01: "Hủy niêm yết",
      RB02: "CK chuyển đổi",
      RB03: "CK chuyển đổi",
      RP00: "Chia tách CK",
      RP01: "Chia tách CK",
      RP02: "Chia tách CK",
      RP03: "Chia tách CK",
      RJ00: "Sát nhập CK",
      RJ02: "Sát nhập CK",
      RJ03: "Sát nhập CK",
      RA01: "Chuyển đổi CK",
      RR01: "Chuyển đổi CK",
      ST01: "GD CK",
      ST02: "GD CK",
      MO05: "Cầm cố CK",
      MO06: "Cầm cố CK",
      MO07: "Cầm cố CK",
      MO08: "Cầm cố CK",
      MO09: "Cầm cố CK",
      MO10: "Cầm cố CK",
      MO13: "Giải toả CC CK",
      CW05: "Hủy niêm yết",
    },
  },
  holdUnholdBankAccount: {
    subAccount: "Sub account",
    bankAccoNumber: "Bank account number",
    bankBranch: "Bank branch",
    bankName: "Bank name",
    bankAvaiable: "Bank avaiable",
    avaiableUnholdAmt: "Avaiable unhold amt",
    totalUnholdAmt: "Total unhold amt",
    avaiableHoldAmt: "Avaiable hold amt",
    action: "Action",
    amount: "Amount",
    remarks: "Remarks",
    utility: "Utility",

    overDate: "From date must to less than to date",
    lengthDate: "Exceeded the 100-day search",

    actionList: {
      hold: "Hold",
      unhold: "Unhold",
    },
    button: {
      confirm: "Confirm",
      cancel: "Cancel",
      search: "Search",
    },
    message: {
      amountCheck1: "Chưa nhập số tiền",
      amountCheck2: "Số tiền phải lớn hơn 0",
      remarksCheck1: "Chưa nhập ghi chú",
      subAccountCheck1: "Chưa chọn tiểu khoản",
      actionCheck1: "Chưa chọn thao tác",
      success: "Thực hiện thành công",
      enterNote: "Nhập ghi chú",
      errorHold:
        "Số tiền phong tỏa không hợp lệ (Vượt quá khả dụng có thể phong tỏa)",
      errorUnHold:
        "Số tiền giải tỏa không hợp lệ (Vượt quá số tiền có thể giải tỏa)",
    },

    confirmModal: {
      title: "Notification",

      content1: "Bạn có chắc chắn muốn thực hiện Phong tỏa tiểu khoản ?",
      content2: "Bạn có chắc chắn muốn thực hiện Giải tỏa tiểu khoản ?",

      accept: "Accept",
      cancel: "Cancel",
    },
  },
  sessionName: {
    S: "Trước giờ",
    P: "ATO",
    O: "Liên tục",
    A: "ATC",
    C: "Thỏa thuận",
    I: "Nghỉ trưa",
    K: "Đóng cửa",
    G: "Đóng cửa",
    J: "Đóng cửa",
    Z: "Đóng cửa",
  },
  transferInternal: {
    transferInternal: {
      title: "Tạo yêu cầu chuyển tiền",
      detail: {
        form: {
          typeMoneyTransfer: "Loại chuyển khoản",
          accountFrom: "TK chuyển",
          accountTo: "TK nhận",
          nameAccountFrom: "Tên TK nhận",
          transferAvailability: "Khả dụng chuyển",
          amount: "Số tiền chuyển",
          content: "Nội dung",
          note: "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện",
          placeholderContent: "Nhập nội dung ",
        },
        button: {
          accept: "Thực hiện",
          cancel: "Hủy",
        },
        modal: {
          accountTransfe: "TK chuyển",
          accountGet: "TK nhận",
          nameAccountGet: "Tên TK nhận",
          amount: "Số tiền",
          content: "Nội dung",
          toastSuccess: "Chuyển tiền thành công!",
        },
      },
    },
    historyTransfer: {
      title: "Lịch sử chuyển tiền",
      filter: {
        valueAll: "Tất cả",
        fromSubAccoNo: {
          label: "Tài khoản chuyển",
        },
        toSubAccoNo: {
          label: "Tài khoản nhận",
        },
        fromDate: {
          label: "Từ ngày",
        },
        toDate: {
          label: "Đến ngày",
        },
        status: {
          label: "Trạng thái",
          all: "Tất cả",
          status0: "Chờ duyệt",
          status1: "Đã duyệt",
          status2: "Hạch toán nhận chờ duyệt",
          status3: "Hoàn thành",
          status9: "Hủy",
        },
        typeMoneyTransfer: {
          label: "Loại chuyển khoản",
          status0: "Chuyển khoản ngân hàng",
          status1: "Chuyển khoản nội bộ",
          status2: "Nộp ký quỹ",
          status3: "Rút ký quỹ",
        },
        search: "Tìm kiếm",
      },
      table: {
        editDelete: "Hủy",
        alloDate: "Ngày YC",
        refNo: "Số YC",
        fromSubAccoName: "Tên Tài khoản chuyển",
        fromSubAccoNo: "Tài khoản chuyển",
        toSubAccoNo: "TK nhận",
        toSubAccoName: "Tên Tài khoản nhận",
        amount: "Số tiền chuyển",
        feeAmount: "Phí CK",
        status: "Trạng thái",
        feeRate: "Tỉ lện phí",
        feeMin: "Phí CK tối thiểu",
        transferFeeFlg: "Cách tính phí",
        transactionName: "Chi nhánh",
        regUserId: "Người tạo",
        regDateTime: "Thời gian tạo",
        typeMoneyTransfer: "Loại chuyển khoản",
        content: "Nội dung",
      },
      messageFail: "Xóa thành công!",
    },
    historyTransferBank: {
      table: {
        editDelete: "Hủy",
        alloDate: "NGÀY GD",
        typeMoneyTransfer: "LOẠI CHUYỂN TIỀN",
        fromSubAccoName: "TK CHUYỂN",
        fromSubAccoNo: "TK CHUYỂN",
        toSubAccoNo: "TK NHẬN",
        toSubAccoName: "TÊN TK NHẬN",
        nameBank: "NGÂN HÀNG",
        branch: "CHI NHÁNH",
        amount: "SỐ TIỀN",
        feeAmount: "PHÍ",
        status: "TRẠNG THÁI",
        content: "NỘI DUNG",
        regUserId: "NGƯỜI CẬP NHẬT",
        regDateTime: "THỜI GIAN CẬP NHẬT",
        typeStatus: {
          type0: "Chưa duyệt",
          type1: "Đã duyệt",
          type2: "Hạch toán nhận chờ duyệt",
          type3: "Hoàn thành",
          type9: "Đã hủy",
        },
      },
      detail: {
        typeMoneyTransfer: "Loại chuyển tiền",
        fromSubAccoNo: "TK chuyển *",
        toSubAccoNo: "TK nhận *",
        nameToSubAccoNo: "Tên TK nhận",
        nambank: "Ngân hàng",
        branch: "Chi nhánh",
        city: "Tỉnh/Thành phố",
        address: "Địa chỉ",
        transferAvailability: "Khả dụng chuyển",
        amount: "Số tiền chuyển *",
        provisionalFee: "Phí tạm tính (VNĐ)",
        content: "Nội dung *",
        typeAmount: {
          value0: "Phí trong",
          value1: "Phí ngoài",
        },
        note: "Vui lòng kiểm tra lại chi tiết giao dịch trước khi thực hiện",
        valueContent:
          "TK {tkFrom} - {nametk} chuyển chuyển khoản ngân hàng online cho TK {tkTo} - {nameTkTo}, tại {bank}, {branch} - {city}",
      },
      modal: {
        title: "Xác nhận chuyển khoản",
        accountTransfe: "TK chuyển",
        accountGet: "TK nhận",
        nameAccountGet: "Tên TK nhận",
        amount: "Số tiền ",
        bank: "Ngân hàng",
        typeTransfer: "Phương thức chuyển",
        fee: "Phí",
        content: "Nội dung",
        toastSuccess: "Chuyển tiền thành công!",
      },
      modalAddAcount: {
        title: "Thêm tài khoản thụ hưởng",
        nameAccount: "Tên tài khoản",
        numberAccouny: "Số tài khoản",
        bank: "Ngân hàng",
        country: "Tỉnh/Thành phố",
        branch: "Chi nhánh",
        address: "Địa chỉ",
        emptyAccoNumber: "Không được để trống trường này!",
        emptyBank: "Không được để trống trường này!",
        emptyCountry: "Không được để trống trường này!",
        emptyBranch: "Không được để trống trường này!",
        emptyAddress: "Không được để trống trường này!",
        messageSuccess: "Thêm tài khoản thụ hưởng thành công",
        inputBankCd: "Chọn ngân hàng",
        inputBankLocation: "Chọn tỉnh/thành phố",
        inputBankName: "Chọn chi nhánh",
      },
      messageFail: "Xóa thành công",
    },
    deposit: {
      title: "Xác nhận Nộp/Rút ký quỹ",
      note: "Lưu ý: Thời gian thực hiện từ 8h30 đến 15h45 các ngày làm việc.",
      table: {
        date: "NGÀY GD",
        fromSubAccoNo: "TK CHUYỂN",
        toSubAccoNo: "TK NHẬN",
        amount: "SỐ TIỀN",
        fee: "PHÍ",
        content: "NỘI DUNG",
        feePays: "HÌNH THỨC TRẢ PHÍ",
        statusVSD: "TRẠNG THÁI VSD",
        status: "TRẠNG THÁI",
        timeUpdate: "THỜI GIAN CẬP NHẬT",
        buttonDelete: "HỦY",
      },
    },
    withdrawal: {
      table: {
        date: "NGÀY GD",
        fromSubAccoNo: "TK CHUYỂN",
        toSubAccoNo: "TK NHẬN",
        amount: "SỐ TIỀN",
        fee: "PHÍ",
        content: "NỘI DUNG",
        feePays: "HÌNH THỨC TRẢ PHÍ",
        statusVSD: "TRẠNG THÁI VSD",
        status: "TRẠNG THÁI",
        timeUpdate: "THỜI GIAN CẬP NHẬT",
      },
    },
    statusVsd: {
      value0: "Chưa gửi",
      value1: "Đã gửi",
      value2: "VSD đồng ý",
      value3: "VSD từ chối",
      value4: "Từ chối từ Gate",
    },
    statusDeposit: {
      value0: "Chưa duyệt",
      value1: "Đã duyệt",
      value2: "Bank đồng ý",
      value3: "Bank từ chối",
      value9: "Đã hủy",
    },
    tradeType: {
      value1: "Rút ký quỹ",
      value2: "Nộp ký quỹ",
    },
    modalDeleteDeposit: {
      title: "Thông báo",
      content: "Bạn có chắc muốn xóa giao dịch này?",
      messSuccess: "Xóa giao dịch thành công",
    },
    formAdd: {
      form: {
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        transferAvailability: "Khả dụng",
        amount: "Số tiền chuyển",
        fee: "Giá trị phí",
        content: "Ghi chú",
      },
      modal: {
        title: "Xác nhận Nộp ký quỹ",
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        amount: "Số tiền chuyển",
        typeAmount: "Hình thức trả phí",
        fee: "Phí",
        content: "Nội dung",
        MessCheckNote:
          "Không hiển thị lại vào lần sau, vui lòng kiểm tra lại chi tiết xác nhận.",
        messSuccess: "Nộp ký quỹ thành công",
        messFail: "Nộp ký quỹ thất bại",
      },
    },
    formRemove: {
      form: {
        action: "Thực hiện *",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        transferAvailability: "Khả dụng",
        amount: "Số tiền chuyển",
        fee: "Giá trị phí",
        content: "Ghi chú",
      },
      modal: {
        title: "Xác nhận Rút ký quỹ",
        action: "Thực hiện",
        fromSubAccoNo: "TK chuyển",
        toSubAccoNo: "TK nhận",
        amount: "Số tiền chuyển",
        typeAmount: "Hình thức trả phí",
        fee: "Phí",
        content: "Nội dung",
        messSuccess: "Rút ký quỹ thành công",
        messFail: "Rút ký quỹ thất bại",
      },
    },
    errorAmountDeposit: {
      error1: "Số tiền giao dịch không hợp lệ",
      error2: "Số tiền không được vượt quá Khả dụng chuyển khoản",
      error3: "Số tiền chuyển không được vượt quá Khả dụng chuyển khoản",
      error4: "Số tiền chuyển + Phí không được vượt quá Khả dụng chuyển khoản",
    },
    btnConfirm: "Xác nhận",
  },
  subscribe: {
    titleLeft: "Đăng ký mua",
    tooltip: "Click đúp để đăng ký/ hủy đăng ký mua",

    tabNavigation: {
      subscribe: "Đăng ký quyền mua",
      historySubscribe: "Lịch sử đăng ký quyền mua",
      searchSubscribe: "Tra cứu thông tin quyền",
    },
    typeForm: {
      typeAdd: "Đăng ký mua",
      typeRemove: "Hủy đăng ký mua",
    },
    formAdd: {
      action: "Thao tác",
      codeCk: "Mã CK",
      Subacc: "Tiểu khoản",
      moneyAvailability: "Khả dụng tiền ",
      numberCanRegister: "SL có thể đăng ký",
      numberRegister: "SL đăng ký",
      numberRegisterPlaceholder: "Nhập số CP",
      price: "Giá",
      intoMoney: "Thành tiền",
      note: "Ghi chú",
      notePlaceholder: "Nhập ghi chú",
      modal: {
        title: "Đăng ký mua",
        subacc: "Tiểu khoản đăng ký",
        codeStock: "Mã CK",
        price: "Giá mua",
        numberRegister: "SL đăng ký",
        intoMoney: "Thành tiền",
        note: "Ghi chú",
      },
      messageSuccess: "Đăng ký thành công",
      validate: {
        error1: "Số lượng phải > 0",
        error2: "Số lượng đăng ký phải nhỏ hơn số lượng có thể đăng ký",
        error3: "Số lượng hủy đăng ký phải nhỏ hơn số lượng có thể hủy đăng ký",
        error4: "Không đủ khả dụng thanh toán",
      },
    },
    formRemove: {
      action: "Thao tác",
      codeCk: "Mã CK",
      Subacc: "Tiểu khoản",
      numberCanCancel: "SL có thể huỷ",
      numberCancel: "SL huỷ",
      numberCanCancelPlaceholder: "Nhập số CP",
      price: "Giá",
      intoMoney: "Thành tiền",
      note: "Ghi chú",
      notePlaceholder: "Nhập ghi chú",
      modal: {
        title: "Hủy đăng ký mua",
        Subacc: "Tiểu khoản đăng ký",
        code: "Mã CK",
        price: "Giá mua",
        numberCancelRegister: "SL hủy đăng ký",
        intoMoney: "Thành tiền",
        note: "Ghi chú",
      },
      messageSuccess: "Hủy ký thành công",
    },
    content: {
      navigation: {
        subscribe: "Đăng ký quyền mua",
        historySubscribe: "Lịch sử đăng ký quyền mua",
        searchSubscribe: "Tra cứu thông tin quyền",
      },
      subscribe: {
        search: {
          Subacc: "Tiểu khoản",
          codeStock: "Mã chứng khoán",
          placeholderCodeStock: "Nhập mã",
        },
        table: {
          stt: "",
          Subacc: "TIỂU KHOẢN",
          codeStock: "MÃ CHỨNG KHOÁN",
          date: "NGÀY CHỐT",
          ratio: "TỶ LỆ NHẬN",
          dateStart: "NGÀY BẮT ĐẦU ĐĂNG KÝ",
          dateEnd: "NGÀY KẾT THÚC ĐĂNG KÝ",
          numberBuy: "SL CP ĐƯỢC MUA",
          numberRegister: "SL CP ĐÃ ĐĂNG KÝ MUA",
          price: "GIÁ MUA",
        },
      },
      historySubscribe: {
        search: {
          Subacc: "Tiểu khoản",
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          codeStock: "Mã chứng khoán",
          placeCodeStock: "Nhập mã",
          type: "Loại",
          option: {
            valueAll: "Tất cả",
          },
        },
        table: {
          action: "HUỶ",
          date: "NGÀY",
          Subacc: "TIỂU KHOẢN",
          secCd: "MÃ CHỨNG KHOÁN",
          actionType: "THAO TÁC",
          number: "SỐ LƯỢNG",
          price: "GIÁ",
          intoMoney: "THÀNH TIỀN",
          status: "TRẠNG THÁI",
        },
        modalDelete: {
          title: "Xác nhận",
          content: "Bạn có chắc chắn muốn hủy yêu cầu?",
          messageSuccess: "Hủy yêu cầu thành công",
        },
      },
      searchSubscribe: {
        search: {
          codeStock: "Mã chứng khoán",
          placeCodeStock: "Nhập mã",
          type: "Loại TH",
          date: "Ngày chốt",
        },
        table: {
          codeStock: "MÃ CK",
          floor: "SÀN",
          dateKHQ: "NGÀY KHQ",
          dateClosing: "NGÀY CHỐT",
          dateTT: "NGÀY TT",
          dateGD: "NGÀY GD",
          ration: "TỶ LỆ QUYỀN",
          rationFrom: "TỶ LỆ NHẬN",
          typeRole: "LOẠI QUYỀN",
          dateStartDK: "NGÀY BẮT ĐẦU ĐK",
          dateEndDK: "NGÀY KẾT THÚC ĐK",
          dateStartCN: "NGÀY BẮT ĐẦU CN",
          dateEndCN: "NGÀY KẾT THÚC CN",
          pricePH: "GIÁ PH",
          tax: "THUẾ TNCN",
          typeCK: "LOẠI CK",
          code: "MÃ CK CHUYỂN",
          explain: "DIỄN GIẢI",
          secState: {
            value0: "Tất cả",
            value1: "Phổ thông",
            value2: "Hạn chế",
          },
        },
      },
    },
    statusThq: {
      valueAll: "Tất cả",
      value1: "1. Tiền mặt",
      value2: "2. Cổ tức CP",
      value3: "3. Cổ phiếu thưởng",
      value4: "4. Đăng ký PHT",
      value5: "5. Trái phiếu hoán đổi",
      value6: "6. Hoán đổi cổ phiếu",
      value7: "7. Chia tách",
      value8: "8. Huỷ đăng ký",
      value9: "9. Thay đổi loại hình",
      value10: "10. Đăng ký lưu trú",
      value11: "11. Biểu quyết",
      value12: "12. Hoàn trả góp vốn",
      value13: "13. Đáo hạn trái phiếu",
      value14: "14. Mua lại vốn góp của TCPH",
      value15: "15. Quyền mua trái phiếu chuyển đổi",
    },
    statusHistory: {
      value0: "Chưa duyệt",
      value1: "Đã duyệt",
      value9: "Đã hủy",
    },
  },
  confirmationOrder: {
    title: "Xác nhận lệnh Online",
    search: {
      fromDate: {
        label: "Từ ngày",
      },
      toDate: {
        label: "Đến ngày",
      },
      status: {
        label: "Trạng thái XN",
        status0: "Chưa xác nhận",
        status1: "Đã xác nhận",
      },
      button: "Tìm kiếm",
    },
    table: {
      subAccoNo: "TIỂU KHOẢN",
      reqType: "LOẠI GIAO DỊCH",
      secCd: "MÃ CK",
      confirmStatus: "XÁC NHẬN",
      status: "TRẠNG THÁI",
      orgOrderNo: "SỐ HIỆU LỆNH",
      pubQty: "KHỐI LƯỢNG ĐẶT",
      pubPrice: "GIÁ ĐẶT",
      matQty: "KHỐI LƯỢNG KHỚP",
      matPrice: "GIÁ KHỚP",
      ordChanel: "KÊNH",
      regDateTime: "THỜI GIAN ĐẶT LỆNH ",
      updDateTime: "THỜI GIAN CẬP NHẬT",
      accep: "Xác nhận lệnh",
      reqTypeStatus: {
        value1: "Bán",
        value2: "Mua",
      },
      typeOrdChanel: {
        value1: "Back",
        value2: "Online",
        value3: "Ipay",
        value4: "Web mobile",
        value5: "App",
        value6: "Home",
        value7: "Floor",
        value8: "SMS Trading",
        value9: "CC",
      },
      statusType: {
        value1: "Từ chối",
        value2: "Chờ gửi",
        value3: "Chờ khớp",
        value4: "Khớp một phần",
        value5: "Khớp toàn bộ",
        value6: "Hủy một phần",
        value7: "Hủy toàn bộ",
        value8: "Sở từ chối",
      },
      confirmStatusType: {
        value0: "Chưa xác nhận",
        value1: "Đã xác nhận",
      },
    },

    modal: {
      title: "Thông báo",
      content: "Bạn có muốn tiếp tục Xác nhận lệnh đã chọn?",
      accep: "Xác nhận",
      cancel: "Hủy",
      titleError: "Thông báo lỗi",
      messageError: "Không có lệnh nào được chọn để thực hiện xác nhận.",
      messageSuccess: "Xác nhận lệnh thành công",
    },
  },

  accountStatement: {
    titleMoneyStatement: "Sao kê tiền",
    titleStockStatement: "Sao kê chứng khoán",
    account: "Tài khoản",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    search: "Tìm kiếm",
    openingBalance: "Số dư đầu kỳ",
    endingBalane: "Số dư cuối kỳ",
    businessDate: "Ngày thực hiện",
    businessDateSer: "Ngày giao dịch",
    creditAmt: "Phát sinh tăng",
    debitAmt: "Phát sinh giảm",
    currentAmt: "Số dư",
    remarks: "Nội dung",
    stockCode: "Mã chứng khoán",
    placeholderStockCode: "Nhập mã CK",
    dayTrading: "Ngày giao dịch",
    errorToDate: "Từ ngày không được lớn hơn Đến ngày",
  },

  marginSecuritiesPortfolio: {
    title: "Danh mục chứng khoán ký quỹ",
    account: "Tài khoản",
    stockCode: "Mã chứng khoán",
    playhoderStockCode: "Nhập mã CK",
    search: "Tìm kiếm",
    no: "STT",
    stockName: "Tên chứng khoán",
    loanRate: "TL cho vay",
    estimateRate: "Tỉ lệ định giá",
    minPrice: "Giá tối thiểu",
    maxPrice: "Giá tối đa",
    marketName: "Sàn",
  },

  manegerMoney: {
    modal: {
      title: "Biểu lãi suất quản lý tiền",
      typeInterest: "Loại lãi",
      option: {
        label0: "Đúng hạn",
        label1: "Trước hạn",
      },
    },
  },
  positionManagement: {
    tab: {
      titleOpen: "Position Open",
      titleClose: "Position Close",
    },
    positionOpen: {
      subject: "Subject",
      placeholderStockCode: "Enter Stock code",
      stockCode: "Stock Code",
      search: "Search",
      longMass: "Long Quantity",
      shortMass: "Short Quantity",
      currentPrice: "Current Price",
      priceLongMass: "Price Long Mass",
      priceShortMass: "Price Short Mass",
      profitAmt: "Profit",
      total: "Total",
      refresh: "Refresh",
    },
    positionClose: {
      subject: "Subject",
      placeholderStockCode: "Enter Stock code",
      stockCode: "Stock Code",
      search: "Search",
      fromDate: "From Date",
      toDate: "To Date",
      tradeDate: "Trade Date",
      quantity: "Quantity",
      longAvgPrice: "Long Average Price",
      price: "Price",
      profitAmt: "Profit",
      errorToDate: "Từ ngày không được lớn hơn Đến ngày",
    },
  },

  depositListPMoney: {
    title: "P-Money Deposit List",
    dateCreate: "Date Created",
    liquidationDate: "Liquidation Date",
    setUpChanel: "Setup Chanel HD",
    status: "Status",
    search: "Search",
    branchCD: "Branch",
    transactionCd: "Transaction point",
    subAccoNo: "Sub-account",
    amount: "Amount",
    interestRate: "Interest Rate",
    daysNo: "Number of day",
    interestAmt: "Profit Before Tax",
    taxRate: "Personal Income tax rate",
    taxAmt: "Personal Income Tax",
    payedDate: "Date of Payment",
    contractSts: "Constract Status",
    black: "1.Black",
    online: "2.Online",
    ipay: "3.Ipay",
    webMobile: "4.Web Mobile",
    mobile: "5.Mobile",
    home: "6.Home",
    floor: "7.Floor",
    sms: "8.SMS",
    tel: "9.Tel",
    inEffect: "1.Đang hiệu lực",
    liquidated: "2.Đã thanh lý",
    inEffectName: "Đang hiệu lực",
    liquidatedName: "Đã thanh lý",
    all: "Tất cả",
    errorToDateCreate: "Ngày tạo từ ngày phải bé hơn Ngày tạo đến ngày",
    errorToDateLiquidate:
      "Ngày thanh toán từ ngày phải bé hơn Ngày thanh toán đến ngày",
  },

  addFrameworkContractForm: {
    createContract: {
      title: "Create a framework contract",
      account: "Account",
      accountName: "Account name",
      contractNo: "Frame contract number",
      dateOpenContract: "Contract opening date",
      remark: "Remark",
      placeholderRemark: "Enter remark",
      textConfirmAfter:
        "I have read and agree to the terms and conditions stated in",
      textConfirmBefore: " the P-Money Management Agreement",
      btnReset: "Reset",
      btnSubmit: "Submit",
      close: "Close",
      titleNoti: "Notification",
      notiAfter: "Frame contract P-Money account number",
      notiBefor: " was created successfully",
    },
    listContract: {
      title: "Contract List",
      fromDate: "From date",
      toDate: "To date",
      search: "Search",
      status: "Status",
      btnEdit: "Edit",
      account: "Account",
      contractNo: "Frame contract number",
      dateOpenContract: "Contract opening date",
      infoCtv: "Introducing collaborators",
      dateCloseContract: "End date",
      statusContract: "Status contract",
      remark: "Remark",
      transactionCd: "Transaction point",
      liquidation: "Liquidation",
      notiLiquidation: "Are you sure you want to cancel this contract?",
      inEffect: "1. Đang hiệu lực",
      liquidated: "2. Đã thanh lý",
      all: "Tất cả",
      valueInEffect: "Đang hiệu lực",
      valueliquidated: "Đã thanh lý",
      liquidateSuccess: "Thanh lý thành công",
      liquidateFailure: "Thanh lý thất bại",
      errorToDate: "Từ ngày không được lớn hơn Đến ngày",
    },
  },
  profitMoney: {
    modal: {
      title: "Biểu lãi P-MONEY",
    },
  },
  subAccoRegister: {
    formLeft: {
      title: "Đăng ký tiểu khoản sử dụng",
      subAcco: "Tài khoản",
      bank: "Ngân hàng",
      subAccoNo: "Tiểu khoản",
      messSuccess: "Thực hiện thành công",
      btnSuccess: "Cập nhật",
    },
    status: {
      valueAll: "Chọn tất cả",
    },
    modal: {
      title: "Thông báo",
      content:
        "Bạn chắc chắn muốn đăng ký/ Hủy đăng ký các tiểu khoản gửi tiền Quản lý tiền TKCK tự động",
    },
  },
  transferHistory: {
    title: "Lịch sử chuyển nhượng TPDN",
    search: {
      fromDate: {
        label: "Ngày tạo",
      },
      toDate: {
        label: "Đến ngày",
      },
      status: {
        label: "Trạng thái",
        valueAll: "Tất cả",
        value0: "Chờ xác nhận",
        value1: "Đã xác nhận",
        value2: "Từ chối",
        value9: "Đã xóa",
      },
    },
    table: {
      allDate: "NGÀY GD",
      status: "TRẠNG THÁI",
      custNoFrom: "SỐ TK CHUYỂN",
      custNameFrom: "TÊN TK CHUYỂN",
      custNoTo: "SỐ TK NHẬN",
      custNameTo: "TÊN TK NHẬN",
      bondCd: "MÃ TP",
      quantity: "SỐ LƯỢNG",
      parAmt: "GIÁ CHUYỂN NHƯỢNG",
      transferAmt: "GIÁ TRỊ CHUYỂN NHƯỢNG",
      parAmt1: "GIÁ TRỊ THEO MỆNH GIÁ",
      feeAmt: "PHÍ CHUYỂN NHƯỢNG",
      taxAmt: "THUẾ TNCN",
      feePayerDisp: "ĐỐI TƯỢNG TRẢ PHÍ",
      taxPayerDisp: "ĐỐI TƯỢNG TRẢ THUẾ",
      srcChannelDisp: "KÊNH GIAO DỊCH",
      regUserId: "NGƯỜI ĐẶT LỆNH",
    },
  },
  assets: {
    navigation: {
      assets: "Tài sản DVTC",
      assetsIncurred: "Tài sản Phái sinh",
      assetsSum: "Tổng tài sản & hiệu quả đầu tư",
    },
    search: {
      account: {
        label: "Tài khoản",
      },
    },
    assetsIncurred: {
      top: {
        information: {
          title: "THÔNG TIN TIỀN",
          deposit: "Tiền ký quỹ",
          ckDeposit: "CK ký quỹ",
          withdrawable: "Tiền có thể rút",
          opening: "Sức mở vị thế",
          moneyWait: "Tiền chờ KQ",
          moneyWaitWithdraw: "Tiền chờ rút KQ",
          owe: "Nợ",
          moneyAcco: "Tiền tài khoản giao dịch",
          realtime: "Tài sản realtime",
          status: {
            default: "Bình thường",
            valueY: "Bình thường",
            valueX: "Dừng giao dịch",
            valueS: "Dừng GD mã CK",
            value1: "Cảnh báo mức 1",
            value2: "Cảnh báo mức 2",
          },
        },
        use: {
          title: "SỬ DỤNG KÝ QUỸ",
          sum: "Tổng giá trị ký quỹ",
          price: "Giá trị ký quỹ yêu cầu",
          ratio: "Tỷ lệ sử dụng tài sản",
          status: "Trạng thái",
          result: "KQ cần bổ sung",
        },
        profit: {
          title: "LÃI LỖ",
          waitTT: "Lãi lỗ chờ TT",
          temporary: "Lãi lỗ tạm tính",
          sum: "Tổng phí tạm tính",
        },
      },
      bottom: {
        tab1: {
          title: "Vị thế mở",
          table: {
            subAcc: "TIỂU KHOẢN",
            secCd: "MÃ CHỨNG KHOÁN",
            KLLONG: "KL LONG",
            KLSHORT: "KL SHORT",
            price: "GIÁ BÁN HIỆN TẠI",
            priceTbLong: "GIÁ TB LONG",
            priceTbShort: "GIÁ TB SHORT",
            profit: "LÃI/LỖ",
            sum: "TỔNG CỘNG",
          },
        },
        tab2: {
          title: "Vị thế đóng",
          table: {
            alloDate: "NGÀY GIAO DỊCH",
            subAcc: "TIỂU KHOẢN",
            secCd: "MÃ CHỨNG KHOÁN",
            mass: "KHỐI LƯỢNG ",
            priceTb: "GIÁ TB",
            price: "GIÁ",
            profit: "LÃI/LỖ",
            sum: "TỔNG CỘNG",
          },
        },
      },
    },
    assets: {
      top: {
        Money: "TIỀN",
        bankAvail: "1. Số dư",
        asNewPendingAmt: "2. Tiền bán chờ về",
        withdrawAvail: "3. Tiền có thể rút",
        Stock: "CHỨNG KHOÁN",
        asNewSecVal: "1. Chứng khoán hiện có",
        asNewRightVal: "2. Giá trị quyền",
        AsNewDebitTotal: "NỢ",
        AsNewAsset: "TỔNG TÀI SẢN",
        AsNewNetAsset: "TÀI SẢN RÒNG  ",

        TitleDVTL: "THÔNG TIN TÀI KHOẢN DỊCH VỤ TÀI CHÍNH",
        limit4: "Hạn mức tài khoản",
        limitL: "Hạn mức còn lại",
        normalLimitAllocated: "Hạn mức bảo lãnh đã cấp",
        todayBuyValue: "Mua trong ngày",
        totalMissMoney: "Tiền thiếu",
        needToLoand: "Cần giải ngân",
        normalLimitNeedSupp: "Bảo lãnh cần nộp",

        asNewCurrentRate: "Tỷ lệ hiện tại",
        warningRate: "Tỷ lệ ký quỹ duy trì",
        callRate: "Tỷ lệ ký quỹ xử lý",
        warningLevel: {
          lablel: "Trạng thái tài khoản",
          value0: "An toàn",
          value1: "Cảnh báo",
          value2: "Giải chấp",
        },
        needToAdditionCash: "Tiền mặt cần bổ sung",
        needToAdditionSec: "Chứng khoán cần bổ sung",
        needToSell: "GT chứng khoán cần bán",
      },
      table: {
        title: "Chứng khoán sở hữu",
        secCd: "MÃ CHỨNG KHOÁN",
        total: "TỔNG",
        availSale: "KHẢ DỤNG",
        receiveT0: "CHỜ VỀ T0",
        receiveT1: "CHỜ VỀ T1",
        receiveT2: "CHỜ VỀ T2",
        limitQty: "HẠN CHẾ CN",
        blockCom: "PHONG TỎA",
        mortage: "CẦM CỐ",
        currentPrice: "GIÁ TC",
        totaValue: "GT CHỨNG KHOÁN",
        marketCode: "SÀN",
      },
    },
    effective: {
      top: {
        titleLeft: "HIỆU QUẢ ĐẦU TƯ",
        titleRight: "TÀI SẢN RÒNG",
        button: {
          btn1m: "1m",
          btn2m: "2m",
          btn3m: "3m",
          btn6m: "6m",
          btn1y: "1y",
        },
        chartLeft: {
          tooltip: {
            index: "index",
            TSSL: "TSSL",
          },
        },
        chartRight: {
          tooltip: {
            NAV: "NAV",
            growth: "Tăng trưởng",
          },
        },
      },
      bottom: {
        chart1: {
          title: "TỶ TRỌNG MÃ CỔ PHIẾU",
          MSN: "MSN",
          HPG: "HPG",
          VCB: "VCB",
          ACB: "ACB",
          MWG: "MWG",
          VNM: "VNM",
        },
        chart2: {
          title: "TỶ TRỌNG NGÀNH",
          titleLeft: "Tài khoản",
          titleRight: "VN30",
          Finance: "Tài chính",
          BĐS: "BĐS",
          Build: "Xây dựng",
        },
        chart3: {
          title: "NỢ VÀ TÀI SẢN RÒNG",
          debt: "Nợ",
          assets: "Tài sản ròng ",
        },
        chart4: {
          title: "PHÂN BỔ TỔNG TÀI SẢN",
          money: "Tiền",
          sec: "Cổ phiếu",
          Warranties: "Chứng quyền",
          Bonds: "Trái phiếu",
          Business: "Hợp tác kinh doanh",
          Derivative: "Phái sinh",
        },
      },
    },
  },

  registerTransferBonds: {
    modal: {
      titleRegister: "Register for Online Bonds Transfer",
      titleDelte: "Unsubscribe Register for Online Bonds Transfer",
      accountNo: "Account No",
      fullName: "Full Name",
      status: "Status",
      textCheckBoxBefore: "I have read and agree to ",
      textCheckBoxMiddle: "Terms and condition ",
      textCheckBoxAfter: "in the Appendix of Unlisted Securities Trading",
      registered: "Registered",
      unRegistered: "UnRegistered",
      cancel: "Cancel",
      register: "Register",
      cancelRegistration: "Cancel Registration",
      registerDone: "Register Done",
      registerFailure: "An error occurred",
      deleteDone: "UnRegister Done",
      deleteFailure: "An error occurred",
    },
    modalConfirm: {
      titleConfirmDelte: "Xác nhận hủy đăng ký",
      titleConfirmRegister: "Xác nhận đăng ký",
      containerConfirm: "Bạn có chắc chắn muốn đăng ký hợp đồng khung",
      containerConfirmDelete:
        "Bạn có chắc chắn muốn hủy đăng ký hợp đồng khung",
      btnConfirm: "Xác nhận",
    },
  },

  placeOrderTransferBonds: {
    filter: {
      all: "Tất cả",
      delay: "Chờ xác nhận",
      accep: "Đã xác nhận",
      deny: "Từ chối",
      cancel: "Đã hủy",
    },
    infomationPlace: {
      title: "Command information",
      accoNoFrom: "Account transfer",
      nameAccoNoFrom: "Name Account transfer",
      accoNoTo: "Account get",
      nameAccoNoTo: "Name account get",
      bondCd: "Bond code",
      quantity: "Quantity",
      quantityApply: "allowable quantity",
      price: "Price",
      feePayer: "Fee payer",
      taxPayer: "Tax payer",
      parAmt: "Value at face value",
      transferAmt: "Transfer Value",
      fee: "Fee",
      tax: "Tax",
      cancel: "Cancel",
      submit: "Enter command",
      placeHolderText: "Enter text",
      notiTitle: "Notification",
      delay: "Wait for confirmation",
      accep: "confirmed",
      deny: "refuse",
      canceled: "cancelled",
      notiAfter: "You definitely want to cancel the transfer request ",
      notiMiddle: ", quanity ",
      notiBefore: " No?",
      personTransfer: "Người chuyển",
      personGet: "Người nhận",
      volumeLess: "Khối lượng chuyển phải bé hơn khối lượng được chuyển",
      errorAccount: "Tài khoản này chưa đăng ký tiểu khoản .60",
      errorAccTo: "Tài khoản nhận phải khác tài khoản chuyển",
    },
    placeBook: {
      title: "Place book",
      fromDate: "From date",
      toDate: "To date",
      status: "Status",
      search: "Search",
      cancle: "Cancel",
      alloDate: "Date created",
      refNo: "Ref No",
      toSubaccoNo: "Account get",
      toCustName: "name Account get",
      bonCd: "Bond Code",
      quantity: "Quantity",
      parAmt: "Value at face value",
      price: "Price",
      transferAmt: "Transfer Value",
      feeAmt: "Fee Transfer",
      feePayer: "Fee payer",
      taxtAmt: "Tax Transfer",
      taxPayer: "Tax payer",
      remarks: "Remarks",
      deleteDone: "Delete Done",
      deleteFalure: "This record cannot be deleted",
      createDone: "Enter the command successfully",
      createFalure: "An error occurred",
      errorToDate: "Từ ngày không được lớn hơn Đến ngày",
    },
    modal: {
      title: "Chuyển nhượng trái phiếu",
      accoNoFrom: "Bên chuyển",
      nameAccoNoFrom: "Tên TK chuyển",
      accoNoTo: "Bên nhận",
      nameAccoNoTo: "Tên TK nhận",
      bondCd: "Mã TP",
      quantity: "Khối lượng",
      price: "Giá",
      parAmt: "GT theo mệnh giá",
      transferAmt: "GT chuyển nhượng",
      fee: "Phí",
      feePayer: "Người trả phí",
      tax: "Thuế",
      taxPayer: "Người trả thuế",
      cancel: "Hủy",
      submit: "Xác nhận",
    },
  },

  changeLimit: {
    modal: {
      titleModal: "Change Limit",
      subAccountNo: "Sub Account",
      currentLimit: "Current Limit",
      requireLimit: "Require Limit",
      textCheckBoxBefore: "I have read and agree to ",
      textCheckBoxMiddle: "terms and condition ",
      textCheckBoxAfter: "use services at VGAIA",
      cancel: "Cancel",
      submit: "Submit",
      done: "Done",
      failure: "Tài khoản này không thể thay đổi hạn mức",
      errorLimit: "Bạn chưa nhập hạn mức mới",
    },
  },

  placeOrderRetailBonds: {
    infomationOrder: {
      title: "Thông tin lệnh",
      subAcco: "Tiểu khoản",
      bondCd: "Mã TP",
      cycle: "Chu kỳ",
      setQuantity: "KL đặt",
      investmentValue: "Giá trị đầu tư",
      purchasingAbility: "Sức mua",
      valueReceived: "Giá trị nhận được",
      fee: "Phí",
      issuers: "Tổ chức phát hành",
      denominations: "Mệnh giá",
      releaseDate: "Ngày phát hành",
      couponRate: "Tỉ lệ coupon",
      dateDue: "Ngày đáo hạn",
      paymentPeriod: "Kỳ trả lãi",
      description: "Mô tả",
      skip: "Bỏ qua",
      buy: "Mua",
      modalTitle: "Xác nhận mua",
      modalBody: "Bạn có chắc chắc muốn mua mã trái phiếu này?",
      buyDone: "Mua mã trái phiếu thành công",
    },
    listBonds: {
      title: "Danh sách trái phiếu",
      bondCd: "Mã TP",
      period: "Kỳ hạn",
      commitedRate: "Lãi suất cam kết",
      remainingQuantity: "KL còn lại",
      quantityMin: "KL tối thiểu",
      valueReceived: "GT nhận được",
      status: "Trạng thái",
      action: "Thao tác",
    },
    orderBook: {
      title: "Sổ lệnh",
      placeHolderBondCd: "Nhập mã",
      bondCd: "Mã TP",
      status: "Trạng thái",
      search: "Tìm kiếm",
      cancel: "Hủy",
      orderDate: "Ngày đặt lệnh",
      subAcco: "Tiểu khoản",
      quantity: "Khối lượng",
      buy: "Mua/bán",
      investmentValue: "GT đầu tư",
      feeValue: "GT phí",
      period: "Kỳ hạn",
      profitRate: "Tỉ lệ lãi",
      dateDue: "Ngày đáo hạn",
      valueReceived: "GT nhận được",
      all: "Tất cả",
      delay: "Chưa duyệt",
      accept: "Đã duyệt",
      deny: "Đã đáo hạn",
      canceled: "Đã hủy",
      modalTitle: "Xác nhận xóa",
      modalBody: "Bạn có chắc chắn muốn xóa bản ghi này?",
      deleteDone: "Xóa thành công",
    },
  },

  advertisingOrder: {
    title: "Sổ lệnh quảng cáo",
    tradeTypeOptions: {
      value0: "Tất cả",
      value1: "Bán",
      value2: "Mua",
    },
    search: {
      bond: "Mã TP",
      placeBond: "Mã TP",
      tradeType: "Mua/Bán",
      placeTradeType: "Loại giao dịch",
      btnSearch: "Tìm kiếm",
    },
    table: {
      bondCd: "MÃ TP",
      TradeType: "MUA/BÁN",
      ordPrice: "GIÁ KỲ VỌNG",
      ordQty: "KHỐI LƯỢNG",
      minQty: "KHỐI LƯỢNG GD TỐI THIỂU ",
      expDate: "NGÀY HẾT HẠN",
    },
  },
  placeOrderBondAdv: {
    bondCdNull: "Mã TP không được để trống",
    ordPriceNull: "Giá kỳ vọng không được để trống",
    ordQtyNull: "Khối lượng không được để trống",
    minQtyNull: "Khối lượng GD tối thiểu không được để trống",
    errQty: "Khối lượng GD tối thiểu < Khối lượng",
    errExpDate: "Ngày hết hạn phải lớn hơn ngày hiện tại",
    contactInfoNull: "Số điện thoại liên hệ không được để trống",
    bondCd: "Mã TP",
    ordPrice: "Giá kỳ vọng",
    ordQty: "Khối lượng",
    minQty: "Khối lượng GD tối thiểu",
    expDate: "Ngày hết hạn",
    contactInfo: "Số điện thoại liên hệ",
    remarks: "Ghi chú",
    reset: "Lệnh mới",
    submit: "Nhập lệnh",
    confirmCreate: "Bạn muốn tạo yêu cầu quảng cáo?",
    tradeTypeAll: "Tất cả",
    tradeTypeSell: "Bán",
    tradeTypeBuy: "Mua",
    statusTypeNotApproved: "Chưa duyệt",
    statusTypeApproved: "Đã duyệt",
    statusTypeDone: "Đã xử lý",
    statusTypeCancel: "Đã hủy",
    tradeType: "Mua / Bán",
    status: "Trạng thái",
    tableContactInfo: "SĐT LIÊN HỆ",
    buttonCancel: "HỦY LỆNH",
    confirmCancel: "Bạn muốn hủy yêu cầu quảng cáo này?",
    titleBondAdv: "Lệnh quảng cáo",
    titleHistory: "Sổ lệnh",
    addSuccess: "Thêm mới thành công",
    cancelSuccess: "Hủy thành công",
  },
  guideVideo: {
    navigation: {
      training: "Đào tạo",
      userManual: "Hướng dẫn sử dụng",
    },
    valueBtn: {
      valueAll: "Tất cả",
    },
  },
};

export default en;
