import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";
import placeOrderReducer from "./placeOrder";
import watchListReducer from "./watchList";
import { logOutSuccess } from "modules/auth/redux";
import volumeAndTime from "./volumeAndTime";
import priceListReducer from "./priceList";
import portfolioReducer from "./portfolio";
import tickerChartReducer from "./tickerChart";
export interface StockOrderState {
  root: State;
}

export interface State {
  [key: string]: any;
  currentTicker: string;
  currentAccount: string;
  ceilingPrice: number;
  floorPrice: number;
  referencePrice: number;
  currentRoom: number;
  secType: number;
  currentTradingSession: string;
  securityType: string;
  curentTickerStatus: string;
  tradingSessionId: number;
  changeTickerByEnter: boolean;
  changePriceByEnter: boolean;
  changeVolumeByEnter: boolean;
  changeOptValByEnter: boolean;
  changeLimitOffsetByEnter: boolean;
  changeAvgPriceByEnter: boolean;
  updatePrice: boolean;
}

const initialState: State = {
  currentTicker: "",
  currentAccount: "",
  ceilingPrice: 0,
  floorPrice: 0,
  referencePrice: 0,
  currentRoom: 0,
  secType: 0,
  currentTradingSession: "",
  securityType: "",
  curentTickerStatus: "",
  tradingSessionId: 0,
  changeTickerByEnter: false,
  changePriceByEnter: false,
  changeVolumeByEnter: false,
  changeOptValByEnter: false,
  changeLimitOffsetByEnter: false,
  changeAvgPriceByEnter: false,
  updatePrice: false,
};

const stockOrderSlice = createSlice({
  name: "stockOrderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    onChangeCurrentTicker: (state, action: PayloadAction<string>) => {
      state.currentTicker = action.payload;
      state.updatePrice = true;
    },
    onChangeTickerByEnter: (state, action: PayloadAction<boolean>) => {
      state.changeTickerByEnter = action.payload;
    },
    onChangePriceByEnter: (state, action: PayloadAction<boolean>) => {
      state.changePriceByEnter = action.payload;
    },
    onChangeVolumeByEnter: (state, action: PayloadAction<boolean>) => {
      state.changeVolumeByEnter = action.payload;
    },
    onChangeOptValByEnter: (state, action: PayloadAction<boolean>) => {
      state.changeOptValByEnter = action.payload;
    },
    onChangeLimitOffsetByEnter: (state, action: PayloadAction<boolean>) => {
      state.changeLimitOffsetByEnter = action.payload;
    },
    onChangeAvgPriceByEnter: (state, action: PayloadAction<boolean>) => {
      state.changeAvgPriceByEnter = action.payload;
    },
    changePrice: (
      state,
      action: PayloadAction<{
        ceilingPrice: number;
        floorPrice: number;
        referencePrice: number;
        currentRoom: number;
        secType: number;
      }>
    ) => {
      state.ceilingPrice = action.payload.ceilingPrice!;
      state.floorPrice = action.payload.floorPrice!;
      state.referencePrice = action.payload.referencePrice!;
      state.currentRoom = action.payload.currentRoom!;
      state.secType = action.payload.secType!;
    },
    changeTradingSession: (state, action: PayloadAction<string>) => {
      state.currentTradingSession = action.payload;
    },
    changeSecuritiesStatus: (
      state,
      action: PayloadAction<{
        status: string;
        tradingSessionId: number;
      }>
    ) => {
      state.curentTickerStatus = action.payload.status;
      state.tradingSessionId = action.payload.tradingSessionId;
    },
  },
});

export const {
  onChangeCurrentTicker,
  changePrice,
  changeTradingSession,
  changeSecuritiesStatus,
  onChangeTickerByEnter,
  onChangePriceByEnter,
  onChangeVolumeByEnter,
  onChangeOptValByEnter,
  onChangeLimitOffsetByEnter,
  onChangeAvgPriceByEnter,
} = stockOrderSlice.actions;

export default combineReducers({
  root: stockOrderSlice.reducer,
  placeOrder: placeOrderReducer,
  watchList: watchListReducer,
  volumeAndTime: volumeAndTime,
  priceList: priceListReducer,
  portfolio: portfolioReducer,
  tickerChart: tickerChartReducer,
});
