import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  cbot: any;
  account: any;
  loading: boolean;
  statusCode: number;
}

const initialState: ReduxData = {
  cbot: {},
  account: [],
  loading: false,
  statusCode: -1,
};

const changeLimitSlice = createSlice({
  name: "changeLimitSlice",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },

  reducers: {
    getLimitAccountRequest: (state, action: PayloadAction<any>) => {},
    getLimitAccountSuccess: (state, action: PayloadAction<any>) => {
      state.account = action.payload?.data;
    },
    getLimitAccountFailure: (state) => {},
  },
});

export const {
  getLimitAccountRequest,
  getLimitAccountSuccess,
  getLimitAccountFailure,
} = changeLimitSlice.actions;

export default changeLimitSlice.reducer;
