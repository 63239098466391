import { FC, memo } from "react";
import { NoDataContent } from "./styles";
import { ReactComponent as IconEmpty } from "assets/image/svg/empty.svg";
import { FormattedMessage } from "react-intl";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const NoData: FC<{}> = memo(() => {
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  return (
    <NoDataContent>
      <IconEmpty
        width={64}
        height={64}
        color={themes[themeType].textcolorBlack}
      />
      <FormattedMessage id="extends.profitLossEquity.tableContent.nothing" />
    </NoDataContent>
  );
});

export default NoData;
