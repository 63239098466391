import React, { memo } from "react";
import { ClickableBox } from "./styles";
import { TopPricePeriod } from "components/widgets/generals/TopPrice/types";

export interface HeaderButtonProps {
  active: boolean;
  value: TopPricePeriod | any;
  onClick?: (value: TopPricePeriod | any) => void;
  children: React.ReactElement | React.ReactElement[] | undefined;
  size?: "small" | "medium";
}

export default memo(function HeaderButton({
  active,
  value,
  onClick,
  children,
  size = "medium",
}: HeaderButtonProps) {
  return (
    <ClickableBox
      size={size}
      active={active}
      onClick={() => onClick && onClick(value)}
    >
      {children}
    </ClickableBox>
  );
});
