import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import GuideVideo from "./pages/GuideVideo";

const routes: RouterConfig[] = [
  {
    page: GuideVideo,
    path: paths.guideVideo,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
