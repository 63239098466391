import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logOutSuccess } from "modules/auth/redux";
// import { TRANSFER_LOCAL_TABS } from "./constants";
import { cloneDeep } from "lodash";
import { SUBSCRIBE_TABS, FORM_LEFT } from "./constants";

interface ReduxData {
  navigation: string;
  typeForm: string | null;
  subscribe: {
    loading: boolean;
    data: any[];
    search: {
      subAccoNo: string | null;
      SecCd: string | null;
    };
  };
  historySubscribe: {
    loading: boolean;
    data: any[];
    search: {
      subAccoNo: string;
      SecCd: string;
    };
  };
  searchSubscribe: {
    loading: boolean;
    data: any[];
    search: {
      secCd: string;
      rightType: string;
      ownerFixDate: Date | null;
    };
  };
  detail: {
    alloDate: number | null;
    refNo: number | null;
    secCd: string | null;
    subAccoCd: string | null;
    subAccoNo: string | null;
    price: number | null;
  };
}
const date = new Date();

const initialState: ReduxData = {
  navigation: SUBSCRIBE_TABS.SUBSCRIBE,
  typeForm: FORM_LEFT.ADD,
  subscribe: {
    loading: false,
    data: [],
    search: {
      subAccoNo: "",
      SecCd: "",
    },
  },
  historySubscribe: {
    loading: false,
    data: [],
    search: {
      subAccoNo: "",
      SecCd: "",
    },
  },
  searchSubscribe: {
    loading: false,
    data: [],
    search: {
      secCd: "",
      rightType: "",
      ownerFixDate: date,
    },
  },
  detail: {
    alloDate: 0,
    refNo: 0,
    secCd: "",
    subAccoCd: "",
    subAccoNo: "",
    price: 0,
  },
};

const subscribeBuy = createSlice({
  name: "subscribeBuy",
  initialState: cloneDeep(initialState),
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return cloneDeep(initialState);
    });
  },
  reducers: {
    setTab: (state, action: PayloadAction<string>) => {
      state.navigation = action.payload;
    },
    setFormType: (state, action: PayloadAction<{ type: string | null }>) => {
      state.typeForm = action.payload.type;
    },

    // Subscribe
    searchDataSubscribe: (
      state,
      action: PayloadAction<{ value: string | null; key: string }>
    ) => {
      state.subscribe.search = {
        ...state.subscribe.search,
        [action.payload.key]: action.payload.value,
      };
    },

    getDataSubscribe: (
      state,
      action: PayloadAction<{ subAccoNo?: string; SecCd?: string }>
    ) => {
      state.subscribe.loading = true;
    },
    getDataSubscribeSuccess: (state, action: PayloadAction<any>) => {
      state.subscribe.loading = false;
      state.subscribe.data = action.payload;
    },
    getDataSubscribeFail: (state) => {
      state.subscribe.loading = false;
    },

    // detail
    updateDetail: (
      state,
      action: PayloadAction<{
        alloDate: number | null;
        refNo: number | null;
        secCd: string | null;
        subAccoCd: string | null;
        subAccoNo: string | null;
        price: number | null;
      }>
    ) => {
      state.detail = action.payload;
    },

    resetDetail: (state) => {
      state.detail = {
        alloDate: 0,
        refNo: 0,
        secCd: "",
        subAccoCd: "",
        subAccoNo: "",
        price: 0,
      };
    },
    //history
    searchDataHistorySubscribe: (
      state,
      action: PayloadAction<{ value: any; key: string }>
    ) => {
      state.historySubscribe.search = {
        ...state.historySubscribe.search,
        [action.payload.key]: action.payload.value,
      };
    },

    getDataHistorySubscribe: (
      state,
      action: PayloadAction<{ subAccoNo: string }>
    ) => {
      state.historySubscribe.loading = true;
    },
    getDataHistorySubscribeSuccess: (state, action: PayloadAction<any>) => {
      state.historySubscribe.loading = false;
      state.historySubscribe.data = action.payload;
    },
    getDataHistorySubscribeFail: (state) => {
      state.historySubscribe.loading = false;
    },

    // searchSubscribe
    searchDataSearchSubscribe: (
      state,
      action: PayloadAction<{ value: any; key: string }>
    ) => {
      state.searchSubscribe.search = {
        ...state.searchSubscribe.search,
        [action.payload.key]: action.payload.value,
      };
    },

    getDataSearchSubscribe: (
      state,
      action: PayloadAction<{
        secCd?: string;
        rightType?: string;
        ownerFixDate?: string;
      }>
    ) => {
      state.searchSubscribe.loading = true;
    },
    getDataSearchSubscribeSuccess: (state, action: PayloadAction<any>) => {
      state.searchSubscribe.loading = false;
      state.searchSubscribe.data = action.payload;
    },
    getDataSearchSubscribeFail: (state) => {
      state.searchSubscribe.loading = false;
    },
  },
});

export const {
  setTab,
  setFormType,

  searchDataSubscribe,

  getDataSubscribe,
  getDataSubscribeSuccess,
  getDataSubscribeFail,
  //history
  searchDataHistorySubscribe,
  getDataHistorySubscribe,
  getDataHistorySubscribeFail,
  getDataHistorySubscribeSuccess,
  // searchSubscribe
  searchDataSearchSubscribe,
  getDataSearchSubscribe,
  getDataSearchSubscribeSuccess,
  getDataSearchSubscribeFail,

  updateDetail,
  resetDetail,
} = subscribeBuy.actions;

export default subscribeBuy.reducer;
