import React from "react";
import { DatePicker, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";
import { FormControl, FormTitle, MatMethod } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import {
  onChangeExpiredDate,
  // onChangeMatMethod,
} from "modules/stock-order/redux/placeOrder";
import { tradeDateSelector } from "modules/auth/selectors";
import moment from "moment";

const orderBookSelector = (state: RootState) => state.stockOrder;

const placeOrderSelector = createSelector(
  orderBookSelector,
  (state) => state.placeOrder
);

const PriorityOrder: React.FC = React.memo(() => {
  //Todo add to redux
  const dispatch = useDispatch();
  const { expiredDate } = useSelector(placeOrderSelector);
  const tradeDate = useSelector(tradeDateSelector);

  return (
    <>
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.method" />
        </FormTitle>
        <FormControl>
          {/* <Select
            value={methods(intl).find((x) => x.value === matMethod)}
            className="flex-fill"
            options={methods(intl)}
            onChange={(item) =>
              item && item.value && dispatch(onChangeMatMethod(item.value))
            }
          /> */}
          <MatMethod>
            <FormattedMessage id="widgets.placeOrder.conditionalOrder.matchingEnoughVolume" />
          </MatMethod>
        </FormControl>
      </div>
      <Spacer size="s" />
      <div className="d-flex align-items-center">
        <FormTitle>
          <FormattedMessage id="widgets.placeOrder.toDate" />
        </FormTitle>
        <FormControl>
          <DatePicker
            selected={new Date(expiredDate)}
            onChange={(e: Date) => dispatch(onChangeExpiredDate(e))}
            fullWidth
            minDate={moment(tradeDate, "YYYYMMDD").toDate()}
          />
        </FormControl>
      </div>
      <Spacer size="s" />
    </>
  );
});
export default PriorityOrder;
