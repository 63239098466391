import {
  getBondListApi,
  getBondOrderApi,
  getCustomer2Api,
  cancelBondOrderApi,
  findCbotBondPriceEstApi,
  findCbotBondInfoApi,
  inquiryBuyingPowerApi,
  getBondAvaiSellApi,
  calculateBondFeeApi,
  calculateTaxAmtApi,
  placeBondOrderApi,
} from "./urls";

import {
  IGetBondOrderResponse,
  IGetBondListResponse,
  IGetCustomer2Response,
  ICancelBondOrderResponse,
  IFindCbotBondPriceEstResponse,
  IFindCbotBondInfoResponse,
  IInquiryBuyingPowerResponse,
  IGetBondAvaiSellResponse,
  ICalculateBondFeeResponse,
  ICalculateTaxAmtResponse,
  IPlaceBondOrderResponse,
} from "domain/models/BidAskBondOrder";

import {
  IGetBondOrderParams,
  IGetBondListParams,
  IGetCustomer2Params,
  ICancelBondOrderParams,
  IFindCbotBondPriceEstParams,
  IFindCbotBondInfoParams,
  IInquiryBuyingPowerParams,
  IGetBondAvaiSellParams,
  ICalculateBondFeeParams,
  ICalculateTaxAmtParams,
  IPlaceBondOrderParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const getBondOrder = async (
  params: IGetBondOrderParams
): Promise<IGetBondOrderResponse> => {
  const res = await VgaiaHttp.get<IGetBondOrderResponse>(
    getBondOrderApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBondList = async (
  params: IGetBondListParams
): Promise<IGetBondListResponse> => {
  const res = await VgaiaHttp.get<IGetBondListResponse>(getBondListApi(params));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findCbotBondInfo = async (
  params: IFindCbotBondInfoParams
): Promise<IFindCbotBondInfoResponse> => {
  const res = await VgaiaHttp.get<IFindCbotBondInfoResponse>(
    findCbotBondInfoApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findCbotBondPriceEst = async (
  params: IFindCbotBondPriceEstParams
): Promise<IFindCbotBondPriceEstResponse> => {
  const res = await VgaiaHttp.get<IFindCbotBondPriceEstResponse>(
    findCbotBondPriceEstApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const getCustomer2 = async (
  params: IGetCustomer2Params
): Promise<IGetCustomer2Response> => {
  const res = await VgaiaHttp.get<IGetCustomer2Response>(
    getCustomer2Api(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const inquiryBuyingPower = async (
  params: IInquiryBuyingPowerParams
): Promise<IInquiryBuyingPowerResponse> => {
  const res = await VgaiaHttp.get<IInquiryBuyingPowerResponse>(
    inquiryBuyingPowerApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBondAvaiSell = async (
  params: IGetBondAvaiSellParams
): Promise<IGetBondAvaiSellResponse> => {
  const res = await VgaiaHttp.get<IGetBondAvaiSellResponse>(
    getBondAvaiSellApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const calculateBondFee = async (
  params: ICalculateBondFeeParams
): Promise<ICalculateBondFeeResponse> => {
  const res = await VgaiaHttp.post<ICalculateBondFeeResponse>(
    calculateBondFeeApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const calculateTaxAmt = async (
  params: ICalculateTaxAmtParams
): Promise<ICalculateTaxAmtResponse> => {
  const res = await VgaiaHttp.post<ICalculateTaxAmtResponse>(
    calculateTaxAmtApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const cancelBondOrder = async (
  params: ICancelBondOrderParams
): Promise<ICancelBondOrderResponse> => {
  const res = await VgaiaHttp.post<ICancelBondOrderResponse>(
    cancelBondOrderApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const placeBondOrder = async (
  params: IPlaceBondOrderParams
): Promise<IPlaceBondOrderResponse> => {
  const res = await VgaiaHttp.post<IPlaceBondOrderResponse>(
    placeBondOrderApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
