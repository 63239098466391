import { all } from "redux-saga/effects";
import placeOrderWidget from "./PlaceOrderWidget";
import portfolioSaga from "./portfolioSaga";
import priceListWidget from "./priceListSaga";
import volumeAndTimeWidgetSaga from "./volumeAndTimeWidgetSaga";
import watchListSaga from "./watchListSaga";
import tickerChartSaga from "./tickerChartSaga";
import logTrackingSaga from "./logTrackingSaga";

export default function* stockOrderSaga() {
  yield all([
    placeOrderWidget(),
    watchListSaga(),
    priceListWidget(),
    volumeAndTimeWidgetSaga(),
    portfolioSaga(),
    tickerChartSaga(),
    logTrackingSaga(),
  ]);
}
