import { Button, ButtonType } from "./styles";
import React, { useRef } from "react";
import { SizeType } from "../Spacer";
import { ReactComponent as Loading } from "assets/image/svg/Loading.svg";
import { useEffect } from "react";
interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  fill?: boolean;
  size?: SizeType;
  onClick?: () => void;
  radius?: number;
  color?: ButtonType;
  backgroundColor?: string;
  width?: string;
  type?: "button" | "submit" | "reset" | undefined;
  ref?: any;
  focusButton?: boolean;
}

const CButton: React.FC<Props> = React.memo(
  ({
    ref,
    title = "Button",
    loading = false,
    disabled,
    fill,
    onClick,
    children,
    size,
    radius,
    color,
    width,
    type,
    focusButton,
    ...rest
  }) => {
    const refButtonDerConfirm = useRef<HTMLButtonElement>(null);
    const renderContent = () => {
      return (
        <>
          {children || <div>{title}</div>}&nbsp;
          {loading && <Loading className="loader" />}
        </>
      );
    };

    const onPress = () => {
      if (loading || disabled) return;
      onClick && onClick();
    };

    useEffect(() => {
      if (focusButton) {
        refButtonDerConfirm.current?.focus();
      } else {
        refButtonDerConfirm.current?.blur();
      }
    }, [focusButton]);

    return (
      <Button
        ref={refButtonDerConfirm}
        disabled={disabled}
        isFill={disabled ? false : fill}
        isLoading={loading}
        onClick={onPress}
        size={size}
        color={color}
        radius={radius}
        width={width}
        type={type}
        {...rest}
      >
        {renderContent()}
      </Button>
    );
  }
);

export default CButton;
