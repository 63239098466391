import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import storageLocal from "helper/storage";
import { RefreshTokenParams } from "core/http/apis/auth/types";
import moment from "moment";
import { logOutSuccess } from "modules/auth/redux";
import { uniq } from "lodash";
import { MenuExtraTab, VisibleIndex } from "helper/consts";
import { RealtimeConst } from "helper/constFirebase";

export interface resLogData {
  sessionId?: string; //
  groupId?: string;
  fromScreen?: string;
  screen?: string;
  eventGroup?: string;
  event?: RealtimeConst;
  time?: number;
  user?: string;
  channel?: string;
  deviceInfo?: any;
  status?: number;
  message?: string;
  totalTime?: number;
  url?: string;
  appVersion?: string;
  platform?: string;
  data?:
    | {
        input?: string;
      }
    | object
    | string;
  transDate?: string; //chỉ restful
  tableName?: string; //chỉ restful
  id?: string; //chỉ restful
}

export type CommonState = {
  totalNotification: number;
  visibleModalConfirmOpenLogin: Boolean;
  visibleModalPinCode: boolean;
  visibleModalSetPassword: boolean;
  visibleModalSetPasswordSuccess: boolean;
  visibleModalConfirmCleanAll: Boolean;
  visibleModalSupport: Boolean;
  visibleModalAd: Boolean;
  visibleModalVSD: Boolean;
  titleTabBrowser: string;
  statusInternet: string;
  tabOtp: { [key: string]: { tab: number } };
  isVisibilityPage: boolean;
  hasMqttOffline: boolean;
  menuDotRed: string[];
  currentDate?: string;
  stateOTPnew: boolean;
  listTickerScrible: string[];
  listIndexScrible: string[];
  visibleSideBar: number;
  isOpenExtra: boolean;
  visibleIconExtra: boolean;
  visibleContainerExtra: boolean;
  tabExtraActive: MenuExtraTab;
  logData: resLogData;
  queueLogData: resLogData[];
};

const initialState: CommonState = {
  totalNotification: 0,
  visibleModalConfirmOpenLogin: false,
  visibleModalPinCode: false,
  visibleModalSetPassword: false,
  visibleModalSetPasswordSuccess: false,
  visibleModalConfirmCleanAll: false,
  visibleModalSupport: false,
  visibleModalAd: false,
  visibleModalVSD: false,
  titleTabBrowser: "",
  statusInternet: "",
  tabOtp: {}, //tabSmartOtp = 1, tabQRCode = 2, tabSmsOtp = 3
  isVisibilityPage: false,
  hasMqttOffline: false,
  menuDotRed: [],
  currentDate: moment().format("YYYYMMDD"),
  stateOTPnew: false,
  listTickerScrible: [],
  listIndexScrible: [],
  visibleSideBar: VisibleIndex.OPEN,
  isOpenExtra: false,
  visibleIconExtra: true,
  visibleContainerExtra: false,
  tabExtraActive: MenuExtraTab.PLACE_ORDER,
  logData: {
    sessionId: "",
    groupId: "",
    fromScreen: "",
    screen: "",
    eventGroup: "",
    event: undefined,
    time: 0,
    user: "",
    channel: "Web",
    deviceInfo: "",
    status: 0,
    message: "",
    totalTime: 0,
    url: "",
    appVersion: "",
    platform: "",
    data: "",
  },
  queueLogData: [],
};

const commonSlide = createSlice({
  name: "common",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      state.stateOTPnew = false;
    });
  },
  reducers: {
    setTotalNotification: (state, { payload }: PayloadAction<number>) => {
      state.totalNotification = payload;
    },
    setVisibleModalConfirmOpenLogin: (
      state,
      action: PayloadAction<Boolean>
    ) => {
      state.visibleModalConfirmOpenLogin = action.payload;
    },
    setVisibleModalPinCode: (state, action: PayloadAction<boolean>) => {
      state.visibleModalPinCode = action.payload;
    },
    setVisibleModalSetPassword: (state, action: PayloadAction<boolean>) => {
      state.visibleModalSetPassword = action.payload;
    },
    setVisibleModalSetPasswordSuccess: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.visibleModalSetPasswordSuccess = action.payload;
    },
    setVisibleModalConfirmCleanAll: (state, action: PayloadAction<Boolean>) => {
      state.visibleModalConfirmCleanAll = action.payload;
    },
    setVisibleModalSupport: (state, { payload }: PayloadAction<boolean>) => {
      state.visibleModalSupport = payload;
    },
    setVisibleModalVSD: (state, { payload }: PayloadAction<boolean>) => {
      state.visibleModalVSD = payload;
    },
    setTitleTabBrowser: (state, { payload }: PayloadAction<string>) => {
      state.titleTabBrowser = payload;
    },
    setStatusInternet: (state, { payload }: PayloadAction<string>) => {
      state.statusInternet = payload;
    },
    setTabOtp: (state, { payload }: PayloadAction<number>) => {
      let dataCache: any = state.tabOtp;
      if (typeof dataCache !== "object") {
        dataCache = {};
      }
      dataCache[storageLocal.getMasterAccount()] = {
        tab: payload,
      };
      state.tabOtp = dataCache;
    },
    startRefreshToken: (
      state,
      { payload }: PayloadAction<RefreshTokenParams>
    ) => {},
    changeIsVisibilityPage: (state, action: PayloadAction<boolean>) => {
      state.isVisibilityPage = action.payload;
    },
    changeIsModifiedStatus: (state, action: PayloadAction<boolean>) => {
      state.hasMqttOffline = action.payload;
    },
    pushMenuDotRed: (state, action: PayloadAction<string>) => {
      const newMenuDotRed = [...state.menuDotRed];
      newMenuDotRed.push(action.payload);
      state.menuDotRed = uniq(newMenuDotRed);
    },
    changeCurrentDate: (state, action: PayloadAction<string>) => {
      state.currentDate = action.payload;
    },
    setStateOTPnew: (state, action: PayloadAction<boolean>) => {
      state.stateOTPnew = action.payload;
    },
    addTickerScrible: (state, action: PayloadAction<string>) => {
      state.listTickerScrible = state.listTickerScrible.concat(
        action.payload.split(";")
      );
    },
    removeTickerScrible: (state, action: PayloadAction<string>) => {
      let arr = action.payload.split(";");
      const ticketsToDeleteSet = new Set(arr);

      state.listTickerScrible = state.listTickerScrible.filter((name) => {
        return !ticketsToDeleteSet.has(name);
      });
    },
    addIndexScrible: (state, action: PayloadAction<string>) => {
      state.listIndexScrible = state.listIndexScrible.concat(
        action.payload.split(";")
      );
    },
    removeIndexScrible: (state, action: PayloadAction<string>) => {
      let arr = action.payload.split(";");
      const indexsToDeleteSet = new Set(arr);

      state.listIndexScrible = state.listIndexScrible.filter((name) => {
        return !indexsToDeleteSet.has(name);
      });
    },
    reconnectWebSocketSuccess: () => {},
    setVisibleSideBar: (state) => {
      state.visibleSideBar = state.visibleSideBar + 1;
    },
    setIsOpenExtra: (state, action: PayloadAction<boolean>) => {
      state.isOpenExtra = action.payload;
    },
    setVisibleIconExtra: (state, action: PayloadAction<boolean>) => {
      state.visibleIconExtra = action.payload;
    },
    setVisibleContainerExtra: (state, action: PayloadAction<boolean>) => {
      state.visibleContainerExtra = action.payload;
    },
    setTabExtraActive: (state, action: PayloadAction<MenuExtraTab>) => {
      state.tabExtraActive = action.payload;
    },
    pushLogData: (state, action: PayloadAction<object>) => {},
    updateLogData: (state, action: PayloadAction<resLogData>) => {
      state.logData = { ...state.logData, ...action.payload } || {};
    },
    updateScreenLogData: (state, action: PayloadAction<string>) => {
      state.logData = {
        ...state.logData,
        fromScreen: state.logData.screen,
        screen: action.payload,
      };
    },
    pushQueueLogData: (state, action: PayloadAction<resLogData>) => {
      state.queueLogData.push(action.payload);
    },
    resetQueueLogData: (state) => {
      state.queueLogData = initialState.queueLogData;
    },
  },
});

const persistConfig = {
  key: "common:root",
  storage: storage,
  whitelist: ["visibleSideBar", "tabOtp", "menuDotRed"],
};

export const {
  setTotalNotification,
  setVisibleModalConfirmOpenLogin,
  setVisibleModalPinCode,
  setVisibleModalConfirmCleanAll,
  setVisibleModalSupport,
  setVisibleModalVSD,
  setTitleTabBrowser,
  setStatusInternet,
  setTabOtp,
  startRefreshToken,
  changeIsVisibilityPage,
  changeIsModifiedStatus,
  pushMenuDotRed,
  changeCurrentDate,
  setStateOTPnew,
  addTickerScrible,
  removeTickerScrible,
  addIndexScrible,
  removeIndexScrible,
  reconnectWebSocketSuccess,
  setVisibleModalSetPassword,
  setVisibleModalSetPasswordSuccess,
  setVisibleSideBar,
  setIsOpenExtra,
  setVisibleIconExtra,
  setVisibleContainerExtra,
  setTabExtraActive,
  pushLogData,
  updateLogData,
  updateScreenLogData,
  pushQueueLogData,
  resetQueueLogData,
} = commonSlide.actions;

export default persistReducer(persistConfig, commonSlide.reducer);
