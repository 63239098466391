import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Dictionary } from "lodash";
import numeral from "numeral";
import { TableCellContent } from "components/commons/CommonTable/styles";
import { portfolioRealTimeListSelector } from "modules/stock-order/redux/selectors";
import { PortfolioRealTime } from "domain/models/Portfolio";
import { Percent } from "../styles";
import { getColor } from "..";

const unrealizedProfitRateNumSelector = (orderId: string) => {
  return createSelector(
    portfolioRealTimeListSelector,
    (state?: Dictionary<PortfolioRealTime>) =>
      state?.[orderId]?.unrealizedProfitRateNum
  );
};

interface Props {
  orderId: string;
  costPrice: number;
}

const UnrealizedProfitRateNumCell = ({ orderId, costPrice }: Props) => {
  const unrealizedProfitRateNum = useSelector(
    unrealizedProfitRateNumSelector(orderId)
  );
  const formatterValue = numeral(unrealizedProfitRateNum).format("0,0.00");
  const color = getColor(unrealizedProfitRateNum);
  return (
    <>
      <TableCellContent align="flex-end">
        <Percent className="percent" color={color}>
          {costPrice === 0 ? "100" : formatterValue || "-"}%
        </Percent>
      </TableCellContent>
    </>
  );
};

export default UnrealizedProfitRateNumCell;
