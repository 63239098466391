import React, {
  // useEffect,
  useMemo,
  useState,
} from "react";
import numeral from "numeral";
import { CellProps } from "react-table";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, TextTradeType, TableContainer, Checkbox } from "./style";
import SelectTable from "components/commons/SelectTable";
import ModalDetail from "components/widgets/generals/OrderBook/modal/ModalDetail";
import ModalEditCommand from "components/widgets/generals/OrderBook/modal/ModalEditCommand";
import { onPrice, onVolumn } from "modules/order-book/redux/orderBook";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  onBuySellType,
  onUpdateVolumn,
  onFilter,
  handleUsingPriceSelected,
} from "modules/stock-order/redux/placeOrder";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import {
  addStockOrderWidgetName,
  changeSelectedRows,
  onCheckAll,
} from "modules/order-book/redux/index";
import { OrderBookDisplay } from "domain/models/OrderBook";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import {
  Account,
  // ListingAccount
} from "domain/models/User";
import { getValueRound } from "helper/utils";
import {
  enableInfinitySelector,
  listSelectedRowSelector,
  isCheckAllSelector,
} from "modules/order-book/selectors";
import { changeCurrentAccount } from "modules/auth/redux";
import StatusCell from "./CellRealTime/StatusCell";
import ExecqttyCell from "./CellRealTime/ExecqttyCell";
import AvgpriceCell from "./CellRealTime/AvgpriceCell";
import LastModifiedCell from "./CellRealTime/LastModifiedCell";
import OrderPriceDispCell from "./CellRealTime/OrderPriceDispCell";
import QttyCell from "./CellRealTime/QttyCell";
import CheckboxCell from "./CellRealTime/CheckboxCell";
import ButtonEditCell from "./CellRealTime/ButtonEditCell";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import PubQtyCell from "./CellRealTime/PubQtyCell";
import PubPriceCell from "./CellRealTime/PubPriceCell";

interface WidgetContentProps {
  data: OrderBookDisplay[];
  fetchMoreData: () => void;
  heightFooter: number;
}

function OrderBookTable({
  data,
  fetchMoreData,
  heightFooter,
}: WidgetContentProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enableInfinity = useSelector(enableInfinitySelector);
  const selectedRows = useSelector(listSelectedRowSelector);
  const isCheckAll = useSelector(isCheckAllSelector);

  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState<OrderBookDisplay>();
  const [dataRowEdit, setDataRowEdit] = useState<OrderBookDisplay>();

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const setSelectedRows = () => {};

  const handleCheckAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    orderList: OrderBookDisplay[]
  ) => {
    if (e.target.checked) {
      const selectedRowsNew: OrderBookDisplay[] = orderList.filter(
        (item) => item.visibleCheckbox === true
      );
      dispatch(changeSelectedRows(selectedRowsNew));
      dispatch(onCheckAll(true));
    } else {
      dispatch(changeSelectedRows([]));
      dispatch(onCheckAll(false));
    }
  };

  const handleCheck = (
    order: OrderBookDisplay,
    e: React.ChangeEvent<HTMLInputElement>,
    orderList: OrderBookDisplay[]
  ) => {
    if (e.target.checked) {
      if (selectedRows && selectedRows.length > 0) {
        const selectedRowsNew = [...selectedRows, order];
        dispatch(changeSelectedRows(selectedRowsNew));
        const data: OrderBookDisplay[] = orderList.filter(
          (item) => item.visibleCheckbox === true
        );

        if (selectedRowsNew.length === data.length) {
          dispatch(onCheckAll(true));
        }
      } else {
        dispatch(changeSelectedRows([order]));
      }
    } else {
      if (selectedRows && selectedRows.length > 0) {
        const selectedRowsNew = selectedRows.filter((item) => item !== order);
        dispatch(changeSelectedRows(selectedRowsNew));
      } else {
        dispatch(changeSelectedRows([]));
      }
      dispatch(onCheckAll(false));
    }
  };

  const getChecked = (
    order: OrderBookDisplay,
    selectedList: OrderBookDisplay[]
  ) => {
    return selectedList &&
      selectedList.find((item) => item.clOrdId === order.clOrdId)
      ? true
      : false;
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <Checkbox
              checked={isCheckAll}
              onChange={(e: any) => handleCheckAll(e, data)}
            />
          </TableHeaderCellContent>
        ),
        accessor: "rootorderid",
        disableSortBy: true,
        width: 50,
        Cell: (props: CellProps<OrderBookDisplay>) => {
          const item = props?.row?.original;
          const checked = getChecked(item, selectedRows);
          const realTimeId = item?.realTimeId;
          return (
            <CheckboxCell
              realTimeId={realTimeId}
              checked={checked}
              handleCheck={handleCheck}
              item={item}
              data={data}
            />
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.table.account" />
          </TableHeaderCellContent>
        ),
        accessor: "subAccoNo",
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        minWidth: 90,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.table.type" />
          </TableHeaderCellContent>
        ),
        accessor: "side",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<OrderBookDisplay>) => {
          const tradeTypeDisplay = props?.row?.original?.tradeTypeDisplay || "";
          return (
            <TableCellContent align={"center"}>
              <TextTradeType side={tradeTypeDisplay}>
                {tradeTypeDisplay &&
                  intl.formatMessage({ id: tradeTypeDisplay })}
              </TextTradeType>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.table.status" />
          </TableHeaderCellContent>
        ),
        accessor: "orderStatusDisplay",
        minWidth: 140,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<OrderBookDisplay>) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <StatusCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.table.ticker" />
          </TableHeaderCellContent>
        ),
        accessor: "secCd",
        minWidth: 70,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.volume" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 80,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <QttyCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.price" />
          </TableHeaderCellContent>
        ),
        accessor: "orderPriceDisp",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <OrderPriceDispCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.matchVolume" />
          </TableHeaderCellContent>
        ),
        accessor: "execqtty",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <ExecqttyCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.matchPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "avgprice",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <AvgpriceCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.pubQty" />
          </TableHeaderCellContent>
        ),
        accessor: "pubQty",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <PubQtyCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.pubPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "pubPrice",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <PubPriceCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.table.commandNumber" />
          </TableHeaderCellContent>
        ),
        accessor: "orderNo",
        minWidth: 120,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.table.timeBook" />
          </TableHeaderCellContent>
        ),
        accessor: "createdTimeDisp",
        minWidth: 130,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.table.timeMatch" />
          </TableHeaderCellContent>
        ),
        accessor: "lastMatchTimeDisp",
        minWidth: 130,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const realTimeId = props?.row?.original?.realTimeId;
          return <LastModifiedCell realTimeId={realTimeId} />;
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.table.channel" />
          </TableHeaderCellContent>
        ),
        accessor: "channelDisplay",
        minWidth: 70,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-end"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: CellProps<OrderBookDisplay>) => {
          const item = props?.row?.original;
          const realTimeId = item?.realTimeId;
          return (
            <div className="btn_action_hover">
              <ButtonEditCell
                realTimeId={realTimeId}
                onEdit={onEdit}
                item={item}
              />

              <Button
                side={props?.row?.original?.tradeType === 2 ? "buy" : "sell"}
                onClick={() => onBuySell(props?.row?.original, listAcc)}
                onDoubleClick={(e) => e.stopPropagation()}
              >
                <FormattedMessage
                  id={
                    props?.row?.original?.tradeType === 2
                      ? "common.buyPlus"
                      : "common.sellPlus"
                  }
                />
              </Button>
            </div>
          );
        },
      },
    ],
    [selectedRows, data]
  );

  const onEdit = (data: OrderBookDisplay) => {
    const price = Number(data.pubPrice);
    dispatch(
      onPrice(data?.isMarketOrder ? data?.pubPrice : getValueRound(price))
    );
    dispatch(onVolumn(data?.pubQty));
    setDataRowEdit(data!);
    setShowModalEdit(true);
  };

  const onBuySell = (data: OrderBookDisplay, listing: Account[]) => {
    // Dispatch mã ck
    const symbol = data?.secCd as string;
    dispatch(onChangeCurrentTicker(symbol));

    // Dispatch account
    const accountChange = listing?.find(
      (item) => item.subAccoNo === data?.subAccoNo
    );
    dispatch(changeCurrentAccount(accountChange!));

    // Dispatch switch button mua/bán
    dispatch(onBuySellType(data?.tradeType === 2 ? "buy" : "sell"));

    // Dispatch Giá
    dispatch(handleUsingPriceSelected(true));
    const price = data?.isMarketOrder
      ? data?.orderPriceDisp
      : getValueRound(numeral(data?.ordPrice).value() || 0);

    dispatch(onFilter({ price }));

    // Dispatch khối lượng
    const volumn = numeral(data?.ordQty).format("0,0");
    dispatch(onUpdateVolumn({ volumn }));
    dispatch(addStockOrderWidgetName("widgets.stockOrder.name"));
  };

  return (
    <TableContainer heighFooter={heightFooter} id="table-infinity-order-book">
      <SelectTable
        columns={columns}
        data={data}
        setShowModal={setShowModalDetail}
        setDataRow={setDataRow}
        setSelectedRows={setSelectedRows}
        fetchMoreData={fetchMoreData}
        enableInfinity={enableInfinity}
        scrollableTarget="table-infinity-order-book"
        hiddenCheckbox
      />

      {showModalDetail && (
        <ModalDetail
          showModal={showModalDetail}
          setShowModal={setShowModalDetail}
          dataRow={dataRow}
        />
      )}

      <ModalEditCommand
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        dataRow={dataRowEdit}
      />
    </TableContainer>
  );
}

export default React.memo(OrderBookTable);
