import { Button, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import { OptionSelectType } from "components/commons/Select";
import {
  searchStatisticPriceRequest,
  updateFilter,
} from "modules/statistic-price/redux";
import {
  statisticPriceFilterSelector,
  statisticPriceLoadingSelector,
} from "modules/statistic-price/selectors";
import { memo, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const marketOptionsIntl = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.all",
    }),
  },
  {
    value: "100",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.hsx",
    }),
  },
  {
    value: "200",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.hnx",
    }),
  },
  {
    value: "300",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.upcom",
    }),
  },
  {
    value: "400",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.dccny",
    }),
  },
  {
    value: "500",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.der",
    }),
  },
  {
    value: "600",
    label: intl.formatMessage({
      id: "statisticPrice.filter.marketCd.otc",
    }),
  },
];

const typeOptionsIntl = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "statisticPrice.filter.type.ceilingPrice",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "statisticPrice.filter.type.floorPrice",
    }),
  },
];

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();

  // selector
  const { marketCd, type } = useSelector(statisticPriceFilterSelector);
  const loading = useSelector(statisticPriceLoadingSelector);

  // memo
  const marketOptions = useMemo(() => marketOptionsIntl(intl), [intl]);
  const typeOptions = useMemo(() => typeOptionsIntl(intl), [intl]);

  // function
  const changeMarketHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "marketCd", value: e?.value ?? "0" }));
  };

  const changeTypeHandler = (e: OptionSelectType | null) => {
    dispatch(updateFilter({ key: "type", value: e?.value ?? "1" }));
  };

  const handleSearch = () => {
    dispatch(
      searchStatisticPriceRequest({
        marketCd: marketCd ? +marketCd : 0,
        type: type ? +type : 1,
      })
    );
  };

  // effect

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "statisticPrice.filter.marketCd.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={marketOptions[0]}
            options={marketOptions}
            onChange={changeMarketHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "statisticPrice.filter.type.label",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={typeOptions[0]}
            options={typeOptions}
            onChange={changeTypeHandler}
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "statisticPrice.filter.search" })}
        </Button>

        <Button color="accept" className="button-filter">
          {intl.formatMessage({ id: "statisticPrice.exportExcel" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
