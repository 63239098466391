import { FC } from "react";
import { WrapperTicker } from "../styles";
import ClickableTicker from "components/commons/ClickableTicker";

interface Props {
  dataRow: string;
}

const TickerCell: FC<Props> = ({ dataRow }) => {
  return (
    <WrapperTicker>
      <ClickableTicker ticker={dataRow} isFontMin />
    </WrapperTicker>
  );
};

export { TickerCell };
