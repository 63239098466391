import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 1px solid ${({ theme }) => theme.border1};
`;
// border: 2px solid ${({ theme }) => theme.bgElevated3};

export const Title = styled.div`
  height: 40px;
  padding: 8px;
  text-align: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  background-color: ${({ theme }) => theme.background3};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
  .flex-1 {
    flex: 1;
  }
  border-radius: 8px 8px 0px 0px;
`;

export const Body = styled.div<{ padding?: number }>`
  padding: 24px;
  background-color: ${({ theme }) => theme.background2};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const ButtonExit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const TitleContent = styled.div`
  flex: 3;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite} !important;
  text-align: center;
`;

export const BodyModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const LabelCell = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  width: 40%;
`;

export const WrapInput = styled.div`
  width: 60%;
`;

export const LabelInput = styled.div`
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.title.size};
  padding-bottom: 12px;
`;

export const ActionsRow = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

export const ButtonCell = styled.div`
  flex: 1;
`;

export const WrapFormBotton = styled.div<{ mgt?: boolean }>`
  ${({ mgt }) => (mgt === true ? "margin-top: 24px" : "")};
`;
