import { toast } from "components/commons/Toast";
import {
  findOrderByFilter,
  findRequestCond,
  findOrderDetail,
  findOrderDetailDer,
  findDerOrderDetail,
  findOrderByRequestCond,
  findDerOrderByFilter,
  findDertRequestCond,
  findDertOrderCond,
} from "core/http/apis/order-history";
import {
  IFindOrderByFilterParams,
  IFindRequestCondParams,
  IFindOrderDetailParams,
  IFindOrderDetailDerParams,
  IFindDerOrderDetailParams,
  IFindOrderByRequestCondParams,
  IFindDertOrderByFilterParams,
  IFindDertRequestCondParams,
  IFindDertOrderCondParams,
} from "core/http/apis/order-history/types";
import {
  OrderHistory,
  IFindOrderByFilterResponse,
  IFindRequestCondResponse,
  IFindOrderDetailResponse,
  IFindOrderDetailDerResponse,
  IFindDerOrderDetailResponse,
  IFindOrderByRequestCondResponse,
  IFindDertOrderByFilterResponse,
  IFindDertRequestCondResponse,
  OrderHistoryDerCond,
  OrderHistoryDer,
  IFindDertOrderCondResponse,
  requestCondModel,
} from "domain/models/OrderHistory";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  findOrderByFilterFailure,
  findOrderByFilterRequest,
  findOrderByFilterSuccess,
  findRequestCondRequest,
  findRequestCondSuccess,
  findRequestCondFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  findOrderDetailRequest,
  findOrderDetailSuccess,
  findOrderDetailFailure,
  findOrderDetailDerRequest,
  findOrderDetailDerSuccess,
  findOrderDetailDerFailure,
  findDerOrderDetailRequest,
  findDerOrderDetailSuccess,
  findDerOrderDetailFailure,
  findOrderByRequestCondRequest,
  findOrderByRequestCondSuccess,
  findOrderByRequestCondFailure,
  findDertOrderByFilterRequest,
  findDertOrderByFilterSuccess,
  findDertOrderByFilterFailure,
  findDertRequestCondRequest,
  findDertRequestCondSuccess,
  findDertRequestCondFailure,
  updateModalDerStatusRequest,
  updateModalDerStatusSuccess,
  findDertOrderCondRequest,
  findDertOrderCondSuccess,
  findDertOrderCondFailure,
  fetchMoreCondRequest,
  fetchMoreCondSuccess,
  updatePageOder,
  fetchMoreOrderByFilterRequest,
  fetchMoreOrderByFilterSuccess,
  updatePageDer,
} from "./redux";

function* findOrderByFilterWorker(
  action: ReturnType<typeof findOrderByFilterRequest>
) {
  try {
    const params: IFindOrderByFilterParams = action.payload;
    const response: IFindOrderByFilterResponse = yield call(
      findOrderByFilter,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findOrderByFilterSuccess(response));
    } else {
      yield put(findOrderByFilterFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findOrderByFilterFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findDertOrderByFilterWorker(
  action: ReturnType<typeof findDertOrderByFilterRequest>
) {
  try {
    const params: IFindDertOrderByFilterParams = action.payload;
    const response: IFindDertOrderByFilterResponse = yield call(
      findDerOrderByFilter,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findDertOrderByFilterSuccess(response));
    } else {
      yield put(findDertOrderByFilterFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findDertOrderByFilterFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findRequestCondWorker(
  action: ReturnType<typeof findRequestCondRequest>
) {
  try {
    const params: IFindRequestCondParams = action.payload;
    const response: IFindRequestCondResponse = yield call(
      findRequestCond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findRequestCondSuccess(response));
    } else {
      yield put(findRequestCondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findRequestCondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findDertRequestCondWorker(
  action: ReturnType<typeof findDertRequestCondRequest>
) {
  try {
    const params: IFindDertRequestCondParams = action.payload;
    const response: IFindDertRequestCondResponse = yield call(
      findDertRequestCond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findDertRequestCondSuccess(response));
    } else {
      yield put(findDertRequestCondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findDertRequestCondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findOrderDetailWorker(
  action: ReturnType<typeof findOrderDetailRequest>
) {
  try {
    const params: IFindOrderDetailParams = action.payload;
    const response: IFindOrderDetailResponse = yield call(
      findOrderDetail,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findOrderDetailSuccess(response));
    } else {
      yield put(findOrderDetailFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findOrderDetailFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findOrderDetailDerWorker(
  action: ReturnType<typeof findOrderDetailDerRequest>
) {
  try {
    const params: IFindOrderDetailDerParams = action.payload;
    const response: IFindOrderDetailDerResponse = yield call(
      findOrderDetailDer,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findOrderDetailDerSuccess(response));
    } else {
      yield put(findOrderDetailDerFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findOrderDetailDerFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findDerOrderDetailWorker(
  action: ReturnType<typeof findDerOrderDetailRequest>
) {
  try {
    const params: IFindDerOrderDetailParams = action.payload;
    const response: IFindDerOrderDetailResponse = yield call(
      findDerOrderDetail,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findDerOrderDetailSuccess(response));
    } else {
      yield put(findDerOrderDetailFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findDerOrderDetailFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findOrderByRequestCondWorker(
  action: ReturnType<typeof findOrderByRequestCondRequest>
) {
  try {
    const params: IFindOrderByRequestCondParams = action.payload;
    const response: IFindOrderByRequestCondResponse = yield call(
      findOrderByRequestCond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findOrderByRequestCondSuccess(response));
    } else {
      yield put(findOrderByRequestCondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findOrderByRequestCondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* findDertOrderCondWorker(
  action: ReturnType<typeof findDertOrderCondRequest>
) {
  try {
    const params: IFindDertOrderCondParams = action.payload;
    const response: IFindDertOrderCondResponse = yield call(
      findDertOrderCond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findDertOrderCondSuccess(response));
    } else {
      yield put(findDertOrderCondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findDertOrderCondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
      itemDetailCond,
    }: {
      isVisible: boolean;
      itemDetail?: OrderHistory;
      itemDetailCond?: requestCondModel;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateModalStatusSuccess({ isVisible, itemDetail }));
    } else if (itemDetailCond) {
      yield put(updateModalStatusSuccess({ isVisible, itemDetailCond }));
    } else {
      yield put(updateModalStatusSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalDerStatusWorker(
  action: ReturnType<typeof updateModalDerStatusRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
      itemDetailCond,
    }: {
      isVisible: boolean;
      itemDetail?: OrderHistoryDer;
      itemDetailCond?: OrderHistoryDerCond;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateModalDerStatusSuccess({ isVisible, itemDetail }));
    } else if (itemDetailCond) {
      yield put(updateModalDerStatusSuccess({ isVisible, itemDetailCond }));
    } else {
      yield put(updateModalDerStatusSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* findOrderByFilterWatcher() {
  yield takeLatest(findOrderByFilterRequest.type, findOrderByFilterWorker);
}

function* findDertOrderByFilterWatcher() {
  yield takeLatest(
    findDertOrderByFilterRequest.type,
    findDertOrderByFilterWorker
  );
}
function* findRequestCondWatcher() {
  yield takeLatest(findRequestCondRequest.type, findRequestCondWorker);
}

function* findDertRequestCondWatcher() {
  yield takeLatest(findDertRequestCondRequest.type, findDertRequestCondWorker);
}
function* findOrderDetailWatcher() {
  yield takeLatest(findOrderDetailRequest.type, findOrderDetailWorker);
}
function* findOrderDetailDerWatcher() {
  yield takeLatest(findOrderDetailDerRequest.type, findOrderDetailDerWorker);
}
function* findDerOrderDetailWatcher() {
  yield takeLatest(findDerOrderDetailRequest.type, findDerOrderDetailWorker);
}

function* findOrderByRequestCondWatcher() {
  yield takeLatest(
    findOrderByRequestCondRequest.type,
    findOrderByRequestCondWorker
  );
}
function* findDertOrderCondWatcher() {
  yield takeLatest(findDertOrderCondRequest.type, findDertOrderCondWorker);
}
function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}
function* updateModalDerStatusWatcher() {
  yield takeLatest(
    updateModalDerStatusRequest.type,
    updateModalDerStatusWorker
  );
}

function* fetchMoreCondWatcher() {
  yield takeLatest(fetchMoreCondRequest.type, fetchMoreCondWorker);
}
function* fetchMoreCondWorker(action: ReturnType<typeof fetchMoreCondRequest>) {
  try {
    const params: IFindRequestCondParams = action.payload;
    const response: IFindRequestCondResponse = yield call(
      findRequestCond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updatePageOder(params?.page));
      yield put(fetchMoreCondSuccess(response));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* fetchMoreOrderByFilterWatcher() {
  yield takeLatest(
    fetchMoreOrderByFilterRequest.type,
    fetchMoreOrderByFilterWorker
  );
}
function* fetchMoreOrderByFilterWorker(
  action: ReturnType<typeof fetchMoreOrderByFilterRequest>
) {
  try {
    const params: IFindDertOrderByFilterParams = action.payload;
    const response: IFindDertOrderByFilterResponse = yield call(
      findDerOrderByFilter,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updatePageDer(params?.page ?? 0));
      yield put(fetchMoreOrderByFilterSuccess(response));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}
export default function* orderHistoryLimitSaga() {
  yield all([
    findOrderByFilterWatcher(),
    findRequestCondWatcher(),
    findOrderDetailWatcher(),
    findOrderByRequestCondWatcher(),

    updateModalStatusWatcher(),
    findDertOrderByFilterWatcher(),
    findDertRequestCondWatcher(),
    updateModalDerStatusWatcher(),
    findOrderDetailDerWatcher(),
    findDerOrderDetailWatcher(),
    findDertOrderCondWatcher(),
    fetchMoreCondWatcher(),
    fetchMoreOrderByFilterWatcher(),
  ]);
}
