import styled from "styled-components/macro";
import IconCalendar from "assets/image/svg/ic_calendar.svg";
import { SizeType } from "../Spacer";
export type popperPlacementType =
  | "top-end"
  | "auto-start"
  | "auto"
  | "auto-end"
  | "top-start"
  | "top"
  | "right-start"
  | "right"
  | "right-end"
  | "bottom-end"
  | "bottom"
  | "bottom-start"
  | "left-end"
  | "left"
  | "left-start"
  | undefined;
const Size: { [key in SizeType]?: number } = {
  l: 32,
  m: 32,
};
export const DatePickerButton = styled.div`
  padding: 2px 8px;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bgBase};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border: 1px solid ${({ theme }) => theme.bgElevated4};
  min-width: ${({ theme }) => theme.input.width};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 32px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text};
  & input {
    background: transparent;
    color: ${({ theme }) => theme.textcolorWhite};
    border: none;
    outline: none;
  }
  & svg {
    position: absolute;
    right: 12px;
  }
`;
export const Wrapper = styled.div<{
  size?: SizeType;
  isBorderActive?: boolean;
  radius?: number;
  fullWidth?: boolean;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  .custom-date-picker-wrapper {
    position: relative;
    width: 100%;
    background-color: ${({ theme }) => theme.grey100};
    border: 1px solid
      ${({ theme, isBorderActive }) =>
        isBorderActive ? theme.brandSecondary : theme.grey80};
    overflow: hidden;
    border-radius: ${({ radius }) => (radius ? radius : 4)}px;
    height: ${({ size = "l" }) => Size[size]}px;
    display: flex;
    align-items: center;
    overflow: hidden;
    &:after {
      content: "";
      background: url(${IconCalendar}) no-repeat;
      background-size: cover;
      width: 15px;
      position: absolute;
      right: 10px;
      height: 15px;
    }
  }

  .custom-date-picker-input {
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    border: none;
    outline: none;
    background: transparent;
    padding-left: 8px;
  }
`;
