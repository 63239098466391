import styled from "styled-components/macro";

export const Box = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 2px;
  svg {
    path {
      fill: ${({ theme, active }) =>
        active ? theme.brandSecondary : theme.greyG7};
    }
  }
`;
