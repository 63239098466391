import {
  searchTransferBondApi,
  cancelTransferBondApi,
  validateTimeToTransferBondApi,
} from "./urls";
import {
  ISearchTransferBondResponse,
  ICancelTransferBondResponse,
  IValidateTimeToTransferBondResponse,
} from "domain/models/TransferBond";

import { ISearchTransferBondParams, ICancelTransferBondParams } from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const searchTransferBond = async (
  params: ISearchTransferBondParams
): Promise<ISearchTransferBondResponse> => {
  const res = await VgaiaHttp.get<ISearchTransferBondResponse>(
    searchTransferBondApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const cancelTransferBond = async (
  params: ICancelTransferBondParams
): Promise<ICancelTransferBondResponse> => {
  const res = await VgaiaHttp.post<ICancelTransferBondResponse>(
    cancelTransferBondApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const validateTimeToTransferBond =
  async (): Promise<IValidateTimeToTransferBondResponse> => {
    const res = await VgaiaHttp.post<IValidateTimeToTransferBondResponse>(
      validateTimeToTransferBondApi()
    );

    if (!res || !res.data) throw new Error("Opps");
    return res.data;
  };
