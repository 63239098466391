import { ContentRight, ContentLeft, Container, LabelCell } from "./styles";

import { reset } from "modules/transfer-local/redux";

import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import RequestDetail from "./RequestDetail";
import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";

import { FormattedMessage } from "react-intl";
const DepositContractContent: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Container>
      <ContentLeft>
        <LabelCell>
          <FormattedMessage id="depositContract.title.depositContract" />
        </LabelCell>
        <RequestDetail />
      </ContentLeft>
      <ContentRight>
        <LabelCell>
          <FormattedMessage id="depositContract.title.depositContractList" />
        </LabelCell>
        <RequestFilter />
        <RequestTable />
      </ContentRight>
    </Container>
  );
};
export default DepositContractContent;
