import { all, call, put, takeLatest } from "redux-saga/effects";
import { getStreamPriceInfoFulls } from "core/grpc";
import { SymbolTotalInfo } from "domain/protoNew/auto_trading_pb";
import { getTickerChart, getTickerChartSuccess } from "../redux/tickerChart";

function* fetchChartWorker(action: any) {
  try {
    const response: SymbolTotalInfo.AsObject[] = yield call(
      getStreamPriceInfoFulls,
      action.payload,
      true
    );
    if (response) {
      yield put(getTickerChartSuccess(response[0]));
    }
  } catch (e: any) {}
}

function* fetchChartWatcher() {
  yield takeLatest(getTickerChart.type, fetchChartWorker);
}

export default function* tickerChartWidget() {
  yield all([fetchChartWatcher()]);
}
