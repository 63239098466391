import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { WSS_URL } from "AppConfig";
import { store } from "redux/store";
import {
  addIndexScrible,
  addTickerScrible,
  reconnectWebSocketSuccess,
  removeIndexScrible,
  removeTickerScrible,
  setStatusInternet,
} from "modules/system/redux/common";
import { w3cwebsocket, IMessageEvent } from "websocket";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  AnyMessage,
  ForeignRoom,
  IndexUpdate,
  LastSale,
  ProjectOpen,
  SystemState,
  TopPrice,
  TopPriceOdd,
  LastSaleOdd,
} from "domain/protoNew/auto_trading_pb";

const host = WSS_URL;

enum PAYLOAD_TYPE {
  TOPPRICE = "TopPrice",
  FOREIGNROOM = "ForeignRoom",
  LASTSALE = "LastSale",
  INDEXUPDATE = "IndexUpdate",
  PROJECTOPEN = "ProjectOpen",
  SYSTEMSTATE = "SystemState",
  TOPPRICEODD = "TopPriceOdd",
  LASTSALEODD = "LastSaleOdd",
}

export enum WSS_TYPE {
  ERROR = "error",
  MESSAGE = "message",
  CONNECT = "connect",
  OFFLINE = "offline",
  RECONNECT = "reconnect",
}

const parseData = (message: IMessageEvent) => {
  if (message.data instanceof ArrayBuffer) {
    const dataMessage: any = AnyMessage.deserializeBinary(
      message.data as Uint8Array
    );

    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.TOPPRICEODD)) {
      const topPriceOdd = TopPriceOdd.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_TopPriceOdd, topPriceOdd.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.LASTSALEODD)) {
      const lastSaleOdd = LastSaleOdd.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_LastSaleOdd, lastSaleOdd.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.TOPPRICE)) {
      const topPrice = TopPrice.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_TOPPRICE, topPrice.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.LASTSALE)) {
      const lastSale = LastSale.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, lastSale.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.FOREIGNROOM)) {
      const foreignRoom = ForeignRoom.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_FOREIGNROOM, foreignRoom.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.INDEXUPDATE)) {
      const indexUpdate = IndexUpdate.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_IndexUpdate, indexUpdate.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.PROJECTOPEN)) {
      const projectOpen = ProjectOpen.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_ProjectOpen, projectOpen.toObject());
      return;
    }
    if (dataMessage.getTypeUrl().includes(PAYLOAD_TYPE.SYSTEMSTATE)) {
      const systemState = SystemState.deserializeBinary(dataMessage.getValue());
      emitter.emit(EMMIT_ACTION_TYPE.EMMIT_SystemState, systemState.toObject());
      return;
    }
  } else {
    if (typeof message.data === "string") {
      if (message.data === "2") {
        client.send("3");
        return;
      }
    }
  }
};

const options = {
  WebSocket: w3cwebsocket, // custom WebSocket constructor
  connectionTimeout: 10000,
  maxRetries: 10,
};

const client = new ReconnectingWebSocket(host, [], options);
client.binaryType = "arraybuffer";
client.onopen = () => {
  console.warn("WSS: connected", new Date());
  // Icon connect success
  subscribeSystmState();
  store.dispatch(reconnectWebSocketSuccess());
  store.dispatch(setStatusInternet(WSS_TYPE.CONNECT));
};

client.onerror = (err: any) => {
  // Icon RECONNECT
  console.warn("WSS: Connection error: ", new Date(), { err });
  store.dispatch(setStatusInternet(WSS_TYPE.RECONNECT));
};

client.onclose = (err: any) => {
  // Icon connect OFFLINE
  console.warn("WSS: onclose error: ", new Date(), { err });
  store.dispatch(setStatusInternet(WSS_TYPE.OFFLINE));
};

client.reconnect = (err: any) => {
  // Icon reconnect
  console.warn("WSS: reconnect ", new Date(), { err });
};

client.onmessage = (event: any) => parseData(event);

export const subscribeTickers = (tickers: string, notSaveCache?: boolean) => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: "register",
      channelTopic: "ticker",
      args: { channel: tickers },
    };
    if (!notSaveCache) {
      store.dispatch(addTickerScrible(tickers));
    }
    console.warn("subscribeTickers", tickers);
    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      subscribeTickers(tickers);
    }, 1000);
  }
};

export const unSubscribeTickers = (tickers: string) => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: "unregister",
      channelTopic: "ticker",
      args: { channel: tickers },
    };
    store.dispatch(removeTickerScrible(tickers));
    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      unSubscribeTickers(tickers);
    }, 1000);
  }
};

export const subscribeIndex = (Index: string, notSaveCache?: boolean) => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: "register",
      channelTopic: "index",
      args: { channel: Index },
    };
    if (!notSaveCache) {
      store.dispatch(addIndexScrible(Index));
    }

    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      subscribeIndex(Index);
    }, 1000);
  }
};

export const unSubscribeIndex = (Index: string) => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: "unregister",
      channelTopic: "index",
      args: { channel: Index },
    };
    store.dispatch(removeIndexScrible(Index));
    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      unSubscribeIndex(Index);
    }, 1000);
  }
};

export const subscribeSystmState = () => {
  if (client.readyState === WebSocket.OPEN) {
    const apiCall = {
      eventName: "listenState",
      channelTopic: "systemState",
    };
    client.send("4" + JSON.stringify(apiCall));
  } else {
    // Queue a retry
    setTimeout(() => {
      subscribeSystmState();
    }, 1000);
  }
};

export default client;
