import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { ReactComponent as Loading } from "assets/image/svg/Loading.svg";
import { Button, Spacer } from "components/commons";
import React, { FC, memo, useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {
  PopConfirmOrderBody,
  PopConfirmOrderContainer,
  PopConfirmOrderTitle,
  ButtonExit,
  Icon,
  TickerCode,
  Container,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { PortfolioItemSellAll } from "domain/models/Portfolio";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { CellProps } from "react-table";
import numeral from "numeral";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { useSelector } from "react-redux";
import { getMarketLabel } from "helper/utils";
import { MarketSession } from "helper/consts";
import { tickerListSelector } from "modules/categories/selectors";
import { getStockPrice } from "core/http/apis/tradeinfo";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { toast } from "components/commons/Toast";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handleCloseModal: any;
  data: PortfolioItemSellAll[];
  handleSubmit: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const getColor = (value: number | undefined) => {
  if (value && value > 0) {
    return "green";
  } else if (value === 0) {
    return "yellow";
  } else {
    return "red";
  }
};

const dataSelector = (state: RootState) => state;

const portfolioSelector = createSelector(
  dataSelector,
  (state: RootState) => state.stockOrder.portfolio
);

const ModalConfirmSellAll: FC<Props> = memo(
  ({
    visible,
    handleCloseModal,
    setVisible,
    data,
    handleSubmit,
    loading,
    setLoading,
  }) => {
    const intl = useIntl();
    const [loadList, setLoadList] = useState(false);
    const [dataConvert, setDataConvert] = useState<PortfolioItemSellAll[]>([]);
    const tickersNew = useSelector(tickerListSelector);
    const { singleOrderStatus } = useSelector(portfolioSelector);

    const toggleModalInfo = () => {
      handleCloseModal();
    };

    const getPriceFollowExchanges = (
      symbol: string,
      floorPrice: number,
      session: string
    ) => {
      const exchanges = tickersNew.find((item) => item.seccd === symbol);
      if (exchanges) {
        const marketName = getMarketLabel(exchanges.marketcd);
        if (session === MarketSession.ATC) {
          if (marketName === "HNX") {
            return "ATC";
          } else if (marketName === "HSX") {
            return "ATC";
          } else {
            return "";
          }
        } else if (session === MarketSession.ATO) {
          if (marketName === "HSX") {
            return "ATO";
          } else {
            return "";
          }
        } else if (
          session === MarketSession.OPEN ||
          session === MarketSession.BREAK
        ) {
          if (marketName === "HNX") {
            return "MTL";
          } else if (marketName === "HSX") {
            return "MP";
          } else if (marketName === "UPCOM") {
            return floorPrice;
          } else {
            return "";
          }
        } else if (session === MarketSession.RUNOFF) {
          if (marketName === "HNX") {
            return "PLO";
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    const getDataConvert = async (data: PortfolioItemSellAll[]) => {
      const dataNew: PortfolioItemSellAll[] = [];
      for (let i = 0; i < data.length; i++) {
        const res = await getStockPrice(data[i].symbol);
        if (res.statusCode !== 0) {
          toast(
            res?.errorCode ? "error." + res?.errorCode : "",
            "error",
            res.message,
            true
          );
          return;
        }
        const floorPrice = Number(res?.data?.floorPrice) / 1000;
        const price: any = getPriceFollowExchanges(
          data[i].symbol,
          floorPrice,
          res?.data?.status
        );
        const itemNew: PortfolioItemSellAll = {
          ...data[i],
          price: price,
        };
        dataNew.push(itemNew);
      }
      setDataConvert(dataNew);
      setLoadList(false);
    };

    useEffect(() => {
      if (visible) {
        setDataConvert([]);
        if (data && data.length > 0) {
          setLoadList(true);
          getDataConvert(data);
        }
      }
    }, [visible, data]);

    useEffect(() => {
      if (singleOrderStatus?.status === "success") {
        setLoading(false);
      }

      if (singleOrderStatus?.status === "failed") {
        setLoading(false);
      }
    }, [singleOrderStatus]);

    const flexEnd = "flex-end";

    const columns = useMemo(
      () => [
        {
          Header: (
            <TableHeaderCellContent className="portfolio-account">
              <FormattedMessage id="widgets.portfolio.sellAll.table.userName" />
            </TableHeaderCellContent>
          ),
          accessor: "account",
          Cell: (props: any) => {
            const { value } = props;
            return <TableCellContent>{value}</TableCellContent>;
          },
        },
        {
          Header: (
            <TableHeaderCellContent className="portfolio-symbol">
              <FormattedMessage id="widgets.portfolio.sellAll.table.code" />
            </TableHeaderCellContent>
          ),
          accessor: "symbol",
          Cell: (props: CellProps<PortfolioItemSellAll>) => {
            const { value } = props;
            const profitRatio = props.row.original.ratio as number;
            const color = getColor(profitRatio);
            return (
              <TableCellContent>
                <TickerCode color={color}>{value}</TickerCode>
              </TableCellContent>
            );
          },
        },
        {
          Header: (
            <TableHeaderCellContent
              align={flexEnd}
              className="portfolio-shares-balance"
            >
              <FormattedMessage id="widgets.portfolio.sellAll.table.sellQty" />
            </TableHeaderCellContent>
          ),
          accessor: "sellQty",
          Cell: (props: any) => {
            const { value } = props;
            const sharesBalance = numeral(value).format("0,0");
            return (
              <TableCellContent align={flexEnd}>
                {sharesBalance}
              </TableCellContent>
            );
          },
        },
        {
          Header: (
            <TableHeaderCellContent
              align={flexEnd}
              className="portfolio-marketPrice"
            >
              <FormattedMessage id="widgets.portfolio.sellAll.table.price" />
            </TableHeaderCellContent>
          ),
          accessor: "price",
          Cell: (props: any) => {
            const { value } = props;
            return (
              <TableCellContent align={flexEnd}>
                {typeof value === "string"
                  ? value
                  : numeral(value).format("0,0.00")}
              </TableCellContent>
            );
          },
        },
      ],
      [dataConvert]
    );

    const fetchMoreData = () => {};

    return (
      <>
        <Modal
          show={visible}
          centered
          keyboard={false}
          backdrop="static"
          dialogClassName="modal-472"
        >
          <PopConfirmOrderContainer>
            <PopConfirmOrderTitle>
              <div className="flex-grow-1"></div>
              {intl
                .formatMessage({ id: "widgets.portfolio.sellAll.titleConfirm" })
                .toUpperCase()}
              <ButtonExit
                onClick={() => {
                  toggleModalInfo();
                }}
              >
                <Icon>
                  <IconExit />
                </Icon>
              </ButtonExit>
            </PopConfirmOrderTitle>
            <PopConfirmOrderBody>
              <div className="body">
                <Container>
                  {loadList ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                      <Loading className="loader" />
                    </div>
                  ) : (
                    <CommonTableInfinity
                      fetchMoreData={fetchMoreData}
                      columns={columns}
                      data={dataConvert}
                      enableInfinity={true}
                      scrollableTarget={"portfolioContainer"}
                      isHasFooter={false}
                      loading={loadList}
                    />
                  )}
                </Container>
              </div>
              <div className="footer">
                <Button
                  className="flex-1"
                  color="accept"
                  onClick={() => {
                    toggleModalInfo();
                  }}
                >
                  <FormattedMessage id="widgets.portfolio.sellAll.cancel" />
                </Button>
                <Spacer size="s" />
                <Button
                  className="flex-1"
                  onClick={() => {
                    setLoading(true);
                    handleSubmit();
                  }}
                  disabled={loading || data.length <= 0}
                  loading={loading}
                  color="accept"
                  fill
                >
                  <FormattedMessage id="widgets.portfolio.sellAll.confirm" />
                </Button>
              </div>
            </PopConfirmOrderBody>
          </PopConfirmOrderContainer>
        </Modal>
      </>
    );
  }
);

export default ModalConfirmSellAll;
