import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  SecTimeSalePro,
  STOCK_COLOR_CODEMap,
} from "domain/protoNew/auto_trading_pb";
import orderBy from "lodash/orderBy";

export enum DateType {
  T0 = 0,
  T1 = 1,
  T5 = 5,
}

export interface Ticker {
  id: string;
  time: string;
  price: number;
  volume: number;
  color: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
  eside: string;
}

export type VolumeAndTimeState = {
  volumeList: SecTimeSalePro.AsObject[];
  historyList: Ticker[];
  tnTimeSale: DateType;
  loading: boolean;
  error: string | undefined;
};

const initialState: VolumeAndTimeState = {
  volumeList: [],
  historyList: [],
  tnTimeSale: DateType.T0,
  loading: false,
  error: undefined,
};

const volumeAndTime = createSlice({
  name: "volumeAndTimeSlice",
  initialState,
  reducers: {
    getVolumeAndTime: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = undefined;
    },
    getVolumeAndTimeSuccess: (
      state,
      action: PayloadAction<SecTimeSalePro.AsObject[]>
    ) => {
      state.volumeList = orderBy(action.payload, ["percentbytotal"], ["desc"]);
      state.loading = false;
    },
    getVolumeAndTimeFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearData: (state) => {
      state.volumeList = [];
      state.historyList = [];
    },
    getHistoryListSuccess: (state, action: PayloadAction<Ticker[]>) => {
      state.historyList = action.payload;
    },
    getHistoryListFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    updateHistoryListRealTime: (state, action: PayloadAction<Ticker>) => {
      state.historyList.unshift(action.payload);
      state.historyList.pop();
    },
    setTnTimeSale: (state, action: PayloadAction<DateType>) => {
      state.tnTimeSale = action.payload;
    },
  },
});

export const {
  getVolumeAndTime,
  getVolumeAndTimeSuccess,
  getVolumeAndTimeFailure,
  clearData,
  getHistoryListSuccess,
  getHistoryListFailure,
  updateHistoryListRealTime,
  setTnTimeSale,
} = volumeAndTime.actions;

export default volumeAndTime.reducer;
