import { FC } from "react";

import DepositContractContent from "./components/DepositContractContent/index";

import { Container } from "./styles";

const DepositContract: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <DepositContractContent />
    </Container>
  );
};

export default DepositContract;
