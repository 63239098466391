import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { ModalConfirm } from "components/commons";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { toast } from "components/commons/Toast";
import { deleteRbonOrder } from "core/http/apis/place-order-retail-bonds";
import { convertNumberToDateFormat } from "helper/utils";
import { getListOrderBookRequest } from "modules/place-order-retail-bonds/redux";
import { getListOrderBookSelector } from "modules/place-order-retail-bonds/selectors";
import numeral from "numeral";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ColorGray,
  ColorGreen,
  ColorRed,
  ColorYellow,
} from "../../ListBonds/styles";
import {
  ButtonDelete,
  Container,
  HeaderCell,
  Icon,
  TableCellContent,
} from "./styles";

function Table() {
  const dispatch = useDispatch();
  const listOrderBook = useSelector(getListOrderBookSelector);

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [dataDelete, setDataDelete] = useState<any>({});

  const intl = useIntl();

  const center = "center";
  const left = "left";
  const right = "right";

  const handleDelete = (item: any) => {
    setVisibleModalConfirm(true);
    setDataDelete(item);
  };

  const handleConfirm = async () => {
    const res = await deleteRbonOrder({
      id: dataDelete?.id,
      updDateTime: dataDelete?.updDateTime,
    });
    if (res && res?.statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "placeOrderRetailBonds.orderBook.deleteDone",
        })}`,
        "success"
      );
    } else {
      toast(res?.message, "error");
    }
    setVisibleModalConfirm(false);
    dispatch(
      getListOrderBookRequest({
        subAccoNo: "",
        bondCd: "",
        status: -1,
        fromDate: 0,
        toDate: 0,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.cancel" />
          </HeaderCell>
        ),
        minWidth: 80,
        accessor: "cancel",
        Cell: (props: any) => {
          const rowItem = props?.row?.original;
          return (
            <>
              {rowItem.status === 0 && (
                <Icon>
                  <ButtonDelete onClick={() => handleDelete(rowItem)}>
                    <IconTrash width={16} height={16} />
                  </ButtonDelete>
                </Icon>
              )}
            </>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.orderDate" />
          </HeaderCell>
        ),
        minWidth: 104,
        accessor: "orderDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.subAcco" />
          </HeaderCell>
        ),
        minWidth: 140,
        accessor: "subAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.bondCd" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "bondCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={right}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.quantity" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "qty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={right}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={right}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.investmentValue" />
          </HeaderCell>
        ),
        minWidth: 108.67,
        accessor: "amount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={right}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={right}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.feeValue" />
          </HeaderCell>
        ),
        minWidth: 108.67,
        accessor: "feeAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={right}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={right}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.period" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "period",
        Cell: (props: any) => {
          return (
            <TableCellContent align={right}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={right}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.profitRate" />
          </HeaderCell>
        ),
        minWidth: 100,
        accessor: "interestRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={right}>
              {numeral(props.value * 100).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.dateDue" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "dueDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={right}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.valueReceived" />
          </HeaderCell>
        ),
        minWidth: 108.67,
        accessor: "dueAmount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={right}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={left}>
            <FormattedMessage id="placeOrderRetailBonds.orderBook.status" />
          </HeaderCell>
        ),
        minWidth: 200,
        accessor: "status",
        Cell: (props: any) => {
          return (
            <TableCellContent align={left}>
              {props.value === 0 ? (
                <ColorYellow>
                  <FormattedMessage id="placeOrderRetailBonds.orderBook.delay" />
                </ColorYellow>
              ) : props.value === 1 ? (
                <ColorGreen>
                  <FormattedMessage id="placeOrderRetailBonds.orderBook.accept" />
                </ColorGreen>
              ) : props.value === 2 ? (
                <ColorRed>
                  <FormattedMessage id="placeOrderRetailBonds.orderBook.deny" />
                </ColorRed>
              ) : (
                <ColorGray>
                  <FormattedMessage id="placeOrderRetailBonds.orderBook.canceled" />
                </ColorGray>
              )}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};
  return (
    <>
      <Container id="placeOrderRetailBondsOrderTable">
        <CommonTableInfinity
          fetchMoreData={fetchMoreData}
          columns={columns}
          data={listOrderBook ? listOrderBook : []}
          enableInfinity={true}
          scrollableTarget={"placeOrderTransferBondsOrderTable"}
          isHasFooter={false}
        />
      </Container>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={handleConfirm}
        title={intl.formatMessage({
          id: "placeOrderRetailBonds.orderBook.modalTitle",
        })}
        content={intl.formatMessage({
          id: "placeOrderRetailBonds.orderBook.modalBody",
        })}
        handleCloseModal={() => setVisibleModalConfirm(!visibleModalConfirm)}
      ></ModalConfirm>
    </>
  );
}

export default Table;
