import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { setColor } from "helper/utils";
import { statisticHighestUpdownListSelector } from "modules/statistic-highest-updown/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Container, HeaderCell, TableCellContent } from "./styles";

function Table() {
  const data = useSelector(statisticHighestUpdownListSelector);
  const flexEnd = "flex-end";
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="statisticHighestUpdown.table.secCd" />
          </HeaderCell>
        ),
        accessor: "secCd",
        Cell: (props: any) => {
          const curentPrice = props?.row?.original?.curentPrice;
          const floorPrice = props?.row?.original?.floorPrice;
          const basicPrice = props?.row?.original?.basicPrice;
          const ceilingPrice = props?.row?.original?.ceilingPrice;
          return (
            <TableCellContent
              align={center}
              color={setColor(
                curentPrice ?? "",
                floorPrice ?? 0,
                basicPrice ?? 0,
                ceilingPrice ?? 0
              )}
            >
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.curentPrice" />
          </HeaderCell>
        ),
        accessor: "curentPrice",
        Cell: (props: any) => {
          const curentPrice = props?.row?.original?.curentPrice;
          const floorPrice = props?.row?.original?.floorPrice;
          const basicPrice = props?.row?.original?.basicPrice;
          const ceilingPrice = props?.row?.original?.ceilingPrice;
          return (
            <TableCellContent
              align={flexEnd}
              color={setColor(
                curentPrice ?? "",
                floorPrice ?? 0,
                basicPrice ?? 0,
                ceilingPrice ?? 0
              )}
            >
              {numeral(props.value).format("0,0.0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.changePoint" />
          </HeaderCell>
        ),
        accessor: "changePoint",
        Cell: (props: any) => {
          const curentPrice = props?.row?.original?.curentPrice;
          const floorPrice = props?.row?.original?.floorPrice;
          const basicPrice = props?.row?.original?.basicPrice;
          const ceilingPrice = props?.row?.original?.ceilingPrice;
          return (
            <TableCellContent
              align={flexEnd}
              color={setColor(
                curentPrice ?? "",
                floorPrice ?? 0,
                basicPrice ?? 0,
                ceilingPrice ?? 0
              )}
            >
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.changePercent" />
          </HeaderCell>
        ),
        accessor: "changePercent",
        Cell: (props: any) => {
          const curentPrice = props?.row?.original?.curentPrice;
          const floorPrice = props?.row?.original?.floorPrice;
          const basicPrice = props?.row?.original?.basicPrice;
          const ceilingPrice = props?.row?.original?.ceilingPrice;
          return (
            <TableCellContent
              align={flexEnd}
              color={setColor(
                curentPrice ?? "",
                floorPrice ?? 0,
                basicPrice ?? 0,
                ceilingPrice ?? 0
              )}
            >
              {numeral(props.value).format("0,0.0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.matQty" />
          </HeaderCell>
        ),
        accessor: "matQty",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.matAmt" />
          </HeaderCell>
        ),
        accessor: "matAmt",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value * 1000).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.updownPercent" />
          </HeaderCell>
        ),
        accessor: "statisticType",
        Cell: (props: any) => {
          const endClosedPrice = props?.row?.original?.endClosedPrice;
          const beginClosedPrice = props?.row?.original?.beginClosedPrice;
          return (
            <TableCellContent align={flexEnd}>
              {beginClosedPrice !== 0
                ? numeral((endClosedPrice / beginClosedPrice - 1) * 100).format(
                    "0,0.00"
                  )
                : 100}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="statisticHighestUpdown.table.beginClosedPrice" />
          </HeaderCell>
        ),
        accessor: "beginClosedPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.[000]")}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="statisticHighestUpdownTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data}
        enableInfinity={true}
        scrollableTarget={"statisticHighestUpdownTable"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
