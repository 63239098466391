/* eslint-disable array-callback-return */
/* eslint-disable no-console */
import { useIntl } from "react-intl";
import { Wrap, BtnType, Container } from "./style";
import { Button } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { STEPS } from "../../constants";
import { updateParams, updateStep } from "modules/auth/redux/modalRegister";
import { useMemo } from "react";
import { dataButton } from "./constants";
import { selectBtnSt2 } from "modules/auth/selectorsModalRegister";
import { selectDataCreateUser } from "modules/auth/selectorsModalRegister";
const Step2 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataButtons = useMemo(() => dataButton(intl), [intl]);
  const selectBtnSt = useSelector(selectBtnSt2);
  const dataCreate = useSelector(selectDataCreateUser);

  const disabled_btn = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "HIDE_APPROVAL_CD_VALUE"
      )
    : {};

  const arrHide = disabled_btn?.value ? disabled_btn?.value.split(",") : [];

  const renderbutton = () => {
    return dataButtons.map((e: any) => {
      const check = arrHide.some((x: any) => x === e?.value);
      if (!check)
        return (
          <BtnType
            onClick={() =>
              dispatch(
                updateParams({
                  approvalCd: e?.value,
                })
              )
            }
            isActive={selectBtnSt === e?.value ? true : false}
          >
            {e?.label}
          </BtnType>
        );
    });
  };
  return (
    <Container>
      <Wrap>{renderbutton()}</Wrap>

      <div className="d-flex justify-content-end">
        <Button
          color="accept"
          className="button-filter mr-2"
          width="120px"
          onClick={() => dispatch(updateStep(STEPS.STEPS_1))}
        >
          {intl.formatMessage({
            id: "auth.register.btnPrev",
          })}
        </Button>
        {selectBtnSt !== "" && (
          <Button
            color="gradientaAccept"
            className="button-filter"
            width="120px"
            onClick={() => dispatch(updateStep(STEPS.STEPS_3))}
          >
            {intl.formatMessage({
              id: "auth.register.btnNext",
            })}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Step2;
