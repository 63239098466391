import styled from "styled-components/macro";

export const TextColor = styled.div<{ color: string; background: string }>`
  color: ${({ theme, color }) => (color ? theme[color] : theme.textcolorWhite)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;
  padding-right: 4px;
  ${({ background, theme }) =>
    !!background && `background: ${theme[background]} !important`};
  :hover {
    background: ${({ theme }) => theme.grey30} !important;
  }
`;
