import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgElevated3};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  max-height: ${({ theme }) => theme.cardHeader.height};
  align-items: center;
  z-index: 4;

  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.background1};
    }
  }
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: ${({ theme }) => theme.cardHeader.cardHeaderItem.padding};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  color: ${({ theme }) => theme.textcolorWhite};
  white-space: nowrap;
  height: ${({ theme }) => theme.cardHeader.height};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isActive, theme }) =>
    isActive &&
    `border-bottom: 2px solid ${theme.solidBlue};
      color: ${theme.solidBlue};`}
`;
