import { IntlShape } from "react-intl";

export const dataSex = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "auth.register.step5_2.optionSex.value0",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "auth.register.step5_2.optionSex.value1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "auth.register.step5_2.optionSex.value2",
    }),
  },
];
