import MainLayout from "components/layouts/Main";
import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import BidAskBond from "./pages/BidAskBond";

const routes: RouterConfig[] = [
  {
    page: BidAskBond,
    path: paths.bidAskBond,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
