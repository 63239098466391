import { createSelector } from "@reduxjs/toolkit";
import { categoriesInfoSelector } from "modules/categories/redux/selectors";
import { CategoryInfo } from "modules/categories/types";
import { useSelector } from "react-redux";
import PriceCell from "../PriceCell/Index";

interface Props {
  ticker: string;
}

const execpriceSelector = (ticker: string) => {
  return createSelector(
    categoriesInfoSelector,
    (info: CategoryInfo) => info[ticker]?.secdetailinfo?.execprice
  );
};

function ExecpriceCell({ ticker }: Props) {
  const execprice = useSelector(execpriceSelector(ticker)) || 0;

  return <PriceCell price={execprice} isPreventSetCommand />;
}

export default ExecpriceCell;
