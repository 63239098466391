import { FC, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import TableBodyRow from "./TableBodyRow";
import { TableBodyWrapper } from "./styles";
import { useSelector } from "react-redux";
import { tickerListTopStatisticalSelector } from "modules/market/redux/selectors";
import { subscribeTickers, unSubscribeTickers } from "core/wss";
export interface Props {
  rowHeight: number;
  activeTab: string;
}

const TableBody: FC<Props> = ({ rowHeight, activeTab }) => {
  const tickerListSorted = useSelector(tickerListTopStatisticalSelector);

  useEffect(() => {
    if (tickerListSorted.length > 0) {
      const listTicker = tickerListSorted.toString().replace(/,/g, ";");
      subscribeTickers(listTicker);
    }
    return () => {
      if (tickerListSorted.length > 0) {
        const listTicker = tickerListSorted.toString().replace(/,/g, ";");
        unSubscribeTickers(listTicker);
      }
    };
  }, [tickerListSorted.length]);

  return (
    <TableBodyWrapper>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemCount={tickerListSorted?.length || 0}
            itemSize={() => rowHeight}
            height={height}
            width={width}
            useIsScrolling
          >
            {({ style, index, isScrolling }) => (
              <TableBodyRow
                style={style}
                ticker={tickerListSorted[index]}
                rowInd={index}
                isScrolling={isScrolling}
                activeTab={activeTab}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </TableBodyWrapper>
  );
};

export default TableBody;
