import styled from "styled-components/macro";

export const CreateBoardContainer = styled.div`
  color: ${({ theme }) => theme.textcolorWhite};
  padding-top: 100px;
`;

export const TitleContainer = styled.div`
  height: 100px;
  font-size: ${({ theme }) => theme.typeFaces.header.size};
  line-height: ${({ theme }) => theme.typeFaces.header.lineHeight};
  text-align: center;
  padding: 12px 0px;
  font-weight: ${({ theme }) => theme.typeFaces.header.weight.bold};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TabContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  margin: 12px;
  :hover {
    color: ${({ theme }) => theme.solidBlue};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
  border-bottom: 1px solid ${theme.solidBlue};
  color: ${theme.solidBlue};
  `}
`;

export const BoardContainer = styled.div`
  justify-content: space-around;
  color: black;
  display: flex;
  flex-wrap: wrap;
`;

export const BoardBox = styled.div`
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background2};
  width: 17%;
  :hover {
    background-color: ${({ theme }) => theme.background2};
  }
  :hover .item-board-title,
  :hover .item-board-desc {
    color: ${({ theme }) => theme.textcolorWhite};
  }
  display: flex;
  flex-direction: column;
  .item-board-thumb {
    width: 100%;
    height: auto;
    flex: 2;
    background: ${({ theme }) => theme.background2};
    border: 1px solid ${({ theme }) => theme.grey70};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex: 1;
    .title-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .item-board-title {
        color: ${({ theme }) => theme.textcolorWhite};
        font-size: ${({ theme }) => theme.typeFaces.headline.size};
        line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
        margin-top: 12px;
      }
    }

    .item-board-desc {
      color: ${({ theme }) => theme.textcolorWhite};
      overflow: hidden;
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      margin-top: 4px;
      margin-bottom: 24px;
      max-height: 28px;
      text-overflow: ellipsis;
    }
  }
`;

export const ButtonExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  position: relative;
  left: 25px;
  bottom: 195px;
`;
