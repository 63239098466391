import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import moment from "moment";
import {
  getVolumeAndTime,
  getVolumeAndTimeFailure,
  getVolumeAndTimeSuccess,
  getHistoryListSuccess,
  getHistoryListFailure,
  setTnTimeSale,
} from "../redux/volumeAndTime";
import { PayloadAction } from "@reduxjs/toolkit";
import { getFindSecIntraday, getFindTimeSale } from "core/grpc";
import {
  SecIntradayInput,
  SecIntradayResponse,
  TimeSaleResponse,
} from "domain/protoNew/auto_trading_pb";
import { AuthenCodeGrpc } from "AppConfig";
import { tnTimeSaleSelector } from "../redux/selectors";
import { selectedTickerSelector } from "modules/stock-order/redux/selectors";

function handleTickersList(
  response: SecIntradayResponse.SecIntradayInfo.AsObject[]
) {
  return response.map((ticker) => {
    return {
      id: uuid(),
      price: ticker.price,
      volume: ticker.qty,
      time: moment(ticker.time, "H:mm:ss").format("HH:mm:ss"),
      color: ticker.colorcode,
      eside: ticker.side?.data ?? "",
    };
  });
}

function* fetchTimeAndSale(action: PayloadAction<string>) {
  try {
    const request = new SecIntradayInput();
    request.setUserid(uuid());
    request.setSeccd(action.payload);
    request.setLimit(50);
    request.setOffset(0);
    request.setAuthencode(AuthenCodeGrpc);

    const responseIntraday: SecIntradayResponse.AsObject = yield call(
      getFindSecIntraday,
      request
    );
    yield put(
      getHistoryListSuccess(
        handleTickersList(responseIntraday.secintradayinfoList)
      )
    );

    const tnTimeSale: number = yield select(tnTimeSaleSelector);
    const responseTimeSale: TimeSaleResponse.AsObject = yield call(
      getFindTimeSale,
      action.payload,
      tnTimeSale
    );
    yield put(getVolumeAndTimeSuccess(responseTimeSale.sectimesalelistList));
  } catch (e: any) {
    yield put(getVolumeAndTimeFailure(e));
    yield put(getHistoryListFailure(e));
  }
}

function* findTimeSaleWorker() {
  try {
    const selectedTicker: string = yield select(selectedTickerSelector);
    const tnTimeSale: number = yield select(tnTimeSaleSelector);
    if (selectedTicker !== "") {
      const responseTimeSale: TimeSaleResponse.AsObject = yield call(
        getFindTimeSale,
        selectedTicker,
        tnTimeSale
      );
      yield put(getVolumeAndTimeSuccess(responseTimeSale.sectimesalelistList));
    }
  } catch (e: any) {
    yield put(getVolumeAndTimeFailure(e));
  }
}

function* watchTimeAndSale() {
  yield takeLatest(getVolumeAndTime.type, fetchTimeAndSale);
}
function* watchTimeSaleSaga() {
  yield takeLatest(setTnTimeSale.type, findTimeSaleWorker);
}

export default function* volumnAndTimeWidget() {
  yield all([watchTimeAndSale(), watchTimeSaleSaga()]);
}
