import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IGetListNewsBySecCdParams } from "core/http/apis/news/types";
import {
  SectNewsDetailItem,
  SectNewsItem,
} from "domain/protoNew/auto_trading_pb";

export type NewsInfoState = {
  loading: boolean;
  getNewsParams: IGetListNewsBySecCdParams;
  error: string | undefined;
  newId: number | null;
  newsItemDetailSelected: SectNewsDetailItem.AsObject;
  newsList: SectNewsItem.AsObject[];
  enableInfinity: boolean;
};

const initialState: NewsInfoState = {
  loading: false,
  getNewsParams: {
    secCd: "",
    page: 0,
    size: 10,
  },
  error: undefined,
  newId: 0,
  newsList: [],
  newsItemDetailSelected: {
    id: 0,
    seccd: "",
    publicationdate: 0,
    title: "",
    thumbnail: "",
    shortcontent: "",
    content: "",
    remarks: "",
  },
  enableInfinity: true,
};

const newsInfo = createSlice({
  name: "newsInfoSlice",
  initialState,
  reducers: {
    getNewsInfo: (state, action: PayloadAction<IGetListNewsBySecCdParams>) => {
      state.loading = true;
    },
    getNewsInfoSuccess: (
      state,
      action: PayloadAction<{ items: SectNewsItem.AsObject[]; offset: number }>
    ) => {
      state.newsList =
        action.payload.offset === 0
          ? action.payload.items
          : state.newsList.concat(action.payload.items);
      if (action.payload.items.length < state.getNewsParams.size) {
        state.enableInfinity = false;
        state.getNewsParams.page = 0;
      } else {
        state.enableInfinity = true;
        state.getNewsParams.page = state.getNewsParams.page + 1;
      }
      state.loading = false;
    },
    getNewsInfoFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    getNewsDetailRequest: (state, action: PayloadAction<number | null>) => {
      state.newId = action.payload;
      state.loading = true;
    },
    getNewsDetailSuccess: (
      state,
      action: PayloadAction<SectNewsDetailItem.AsObject>
    ) => {
      state.loading = false;
      state.newsItemDetailSelected = action.payload;
    },
    getNewsDetailFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getNewsInfo,
  getNewsInfoSuccess,
  getNewsInfoFailure,
  getNewsDetailRequest,
  getNewsDetailSuccess,
  getNewsDetailFailure,
} = newsInfo.actions;

export default newsInfo.reducer;
