import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ForeignChartData } from "components/widgets/generals/ForeignChart/types";
import { ForeignIndustriesChartData } from "components/widgets/generals/ForeignIndustryChart/types";
import { ForeignTab } from "components/widgets/generals/ForeignWatch/types";
export type ForeignChartState = {
  activeTab: ForeignTab;
  loading: boolean;
  error: string | undefined;
  foreignChartData: ForeignChartData;
  foreignIndustryChartData: ForeignIndustriesChartData;
};

const initialState: ForeignChartState = {
  activeTab: "topStocks",
  loading: false,
  error: undefined,
  foreignChartData: {
    items: [],
    totalBuyValue: 0,
    totalSellValue: 0,
    totalNetValue: 0,
  },
  foreignIndustryChartData: {
    items: [],
    totalBuyValue: 0,
    totalSellValue: 0,
    totalNetValue: 0,
  },
};

const foreignChartSlice = createSlice({
  name: "foreignChartSlice",
  initialState,
  reducers: {
    changeActiveTab: (state, action: PayloadAction<ForeignTab>) => {
      state.activeTab = action.payload;
    },
    fetchForeignChart: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    fetchForeignIndustryChart: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    fetchForeignChartSuccess: (
      state,
      action: PayloadAction<ForeignChartData>
    ) => {
      state.loading = false;
      state.foreignChartData.items = action.payload.items;
      state.foreignChartData.totalBuyValue = action.payload.totalBuyValue;
      state.foreignChartData.totalSellValue = action.payload.totalSellValue;
      state.foreignChartData.totalNetValue = action.payload.totalNetValue;
    },
    fetchForeignIndustryChartSuccess: (
      state,
      action: PayloadAction<ForeignIndustriesChartData>
    ) => {
      state.loading = false;
      state.foreignIndustryChartData.items = action.payload.items;
      state.foreignIndustryChartData.totalBuyValue =
        action.payload.totalBuyValue;
      state.foreignIndustryChartData.totalSellValue =
        action.payload.totalSellValue;
      state.foreignIndustryChartData.totalNetValue =
        action.payload.totalNetValue;
    },
    fetchForeignChartFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  changeActiveTab,
  fetchForeignChart,
  fetchForeignChartSuccess,
  fetchForeignChartFailure,
  fetchForeignIndustryChart,
  fetchForeignIndustryChartSuccess,
} = foreignChartSlice.actions;

export default foreignChartSlice.reducer;
