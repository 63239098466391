import styled from "styled-components/macro";

export const Container = styled.div`
  padding: 24px;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ContainerTop = styled.div`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.borderColor} 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
`;

export const ItemLanguage = styled.button<{ isActive?: boolean }>`
  width: 150px;
  background: transparent;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textcolorWhite};
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
  ${({ isActive, theme }) =>
    isActive &&
    `
        svg {
            border: 1px solid ${theme.textcolorWhite};
            border-radius: 4px;
        }
    `}
`;

export const Languages = styled.div`
  padding: 16px 16px 16px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ThemeContainer = styled.div`
  padding-top: 16px;
`;

export const ThemeList = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
`;

export const HeaderModal = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  background-color: ${({ theme }) => theme.background3};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const ModalTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const BodyModal = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.background2};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const BodyModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelCell = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
`;

export const WrapInput = styled.div`
  flex: 1;
`;

export const ActionsRow = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonCell = styled.div`
  flex: 1;
`;
