import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";
import { UIConst } from "helper/consts";

export const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
`;

export const Content = styled.div<{
  isDisablePadding?: boolean;
  isSearch?: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.greyG6};
  background-color: ${({ theme }) => theme.background3};
  display: flex;
  flex-direction: row;
  ${({ isSearch }) => isSearch && `width: 320px;`}
  padding: ${({ isDisablePadding }) =>
    isDisablePadding ? "0px" : "14px 12px"};
  :not(.languages) {
    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.greyG6};
      svg {
        path {
          fill: ${({ theme }) => theme.textcolorWhite};
        }
      }
      .title {
        color: ${({ theme }) => theme.textcolorWhite};
      }
    }
  }
  .select-tickers {
    margin: 12px;
    min-width: 296px;
    display: flex;
    align-items: center;

    .input {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
`;

export const DisplayTime = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.grey50};
`;

export const SideBarCard = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.background2};
  display: flex;
  /* position: relative; */
  justify-content: space-between;
`;

export const MiniSize = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: hidden;
  flex: 1;

  @media (max-width: ${UIConst.LAGRE_SCREEN_WIDTH}) {
    padding-bottom: 2px;
  }
  .version {
    padding-top: 10px;
    color: ${({ theme }) => theme.grey50};
    font-size: ${({ theme }) => theme.typeFaces.caption11.size};
    line-height: ${({ theme }) => theme.typeFaces.caption11.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.caption11.weight.regular};
  }
  .icon {
    padding-top: 10px;
    :nth-last-child(2) {
      padding-top: 5px;
    }
    svg {
      cursor: pointer;
      &:hover {
        path {
          fill: ${({ theme }) => theme.brandPrimary};
        }
      }
    }
  }
  .input-focus {
    width: 0px;
    height: 0px;
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const ZoomSize = styled.div`
  position: absolute;
  bottom: 10px;
  z-index: 999;
  svg {
    cursor: pointer;
    &:hover {
      path {
        fill: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: ${({ theme }) => theme.overlayGray};
    border-radius: 4px;
    padding: 8px 12px;
    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  }
  .arrow {
    &:before {
      border-right-color: ${({ theme }) => theme.overlayGray};
    }
  }
`;

export const ListContent = styled.div`
  width: 216px;
  height: auto;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.background2};
  position: absolute;
  left: ${({ theme }) => theme.typeFaces.sideBar.width};
  z-index: 5;
  border-radius: 4px;
  bottom: 50px;
  display: none;
`;

export const Setting = styled.div`
  :hover {
    .menu {
      display: block;
    }
  }
`;

export const SettingIcon = styled.div<{ isFill: boolean }>`
  ${({ isFill, theme }) =>
    isFill &&
    `
    svg {
      path {
        fill: ${theme.brandPrimary};
      }
    }
  `}
`;

export const MainMenu = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .flex-1 {
    flex: 1;
    height: 100%;
    text-decoration: none;
  }
`;

export const ButtonChangeHeight = styled.div<{
  responsiveHeight?: number;
  isMaxHeight?: boolean;
}>`
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: none;
  margin: 8px auto;

  @media (max-height: ${({ responsiveHeight }) => responsiveHeight || 650}px) {
    display: block;

    ${({ isMaxHeight }) =>
      isMaxHeight &&
      `
      transform: rotate(180deg);
    `}
  }
`;
