import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const WrapInput = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const WrapLeft = styled.div`
  width: 70%;
  gap: 12px;
  color: ${({ theme }) => theme.grey0};
  line-height: ${({ theme }) => theme.input.height};
`;
export const WrapRight = styled.div`
  width: 30%;
  display: flex;
  gap: 12px;
`;

export const WrapCheckBoxs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const WrapCheckBox = styled.div`
  width: 50%;
  display: flex;
  line-height: ${({ theme }) => theme.input.height};
  cursor: pointer;
`;

export const TextNote = styled.div`
  color: ${({ theme }) => theme.grey40};
`;

export const TextLink = styled.span`
  color: ${({ theme }) => theme.solidBlue};
  cursor: pointer;
`;

export const WrapTextAccep = styled.div`
  display: flex;
  color: ${({ theme }) => theme.grey0};
  line-height: 24px;
`;

export const TextAccepLink = styled.div`
  color: ${({ theme }) => theme.solidBlue};
  cursor: pointer;
`;
