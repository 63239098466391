import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  min-width: 430px;
`;
