import { useDispatch, useSelector } from "react-redux";
import AppRouter from "router";
import { getWatchListsStart } from "modules/categories/redux";
import { useEffect, useRef } from "react";
import { getToken } from "core/firebase";
import storage from "helper/storage";
import { currentDateSelector } from "modules/system/selectors";
import {
  changeCurrentDate,
  changeIsModifiedStatus,
  changeIsVisibilityPage,
  startRefreshToken,
  updateLogData,
} from "modules/system/redux/common";
import { getNotificationTotalUnreadStart } from "modules/auth/redux/notification";
import { isAuthenticatedSelector } from "modules/auth/selectors";
import { RefreshTokenParams } from "core/http/apis/auth/types";
import { TimeBeforeExpire } from "AppConfig";
import { defaults } from "react-chartjs-2";
import moment from "moment";
import SetTitleRealTime from "helper/setTitleRealTime";
import { subscribeOrderData, subscribeQRTopic } from "core/wssOrder";
import { SystemState } from "domain/protoNew/auto_trading_pb";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { v4 as uuidv4 } from "uuid";

defaults.global.defaultFontFamily = "Ropoto";

const App = () => {
  const dispatch = useDispatch();
  const masterAccountCache = useRef<string>(storage.getMasterAccount());
  const currentDate = useSelector(currentDateSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    dispatch(updateLogData({ sessionId: uuidv4() }));
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        dispatch(changeIsVisibilityPage(false));
        dispatch(changeIsModifiedStatus(false));
      } else {
        dispatch(changeIsVisibilityPage(true));

        //check new dateW
        const newDate = moment().format("YYYYMMDD");
        if (currentDate !== newDate) {
          dispatch(changeCurrentDate(newDate));
          window.location.reload();
        }
      }
    };

    document.addEventListener("visibilitychange", visibilityChangeHandler);

    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      return;
    }

    const setCallApi = setInterval(async () => {
      if (
        new Date() >
        new Date(
          (parseInt(storage.getTimeAccessToken()) - TimeBeforeExpire) * 1000
        )
      ) {
        if (isAuthenticated) {
          const body: RefreshTokenParams = {
            refreshToken: storage.getRefreshToken(),
          };
          dispatch(startRefreshToken(body));
        }
      }
    }, 10000);

    const visibilityChangeHandler = () => {
      if (
        document.hidden === false &&
        new Date() >
          new Date(
            (parseInt(storage.getTimeAccessToken()) - TimeBeforeExpire) * 1000
          )
      ) {
        const body: RefreshTokenParams = {
          refreshToken: storage.getRefreshToken(),
        };
        dispatch(startRefreshToken(body));
      }
    };

    document.addEventListener("visibilitychange", visibilityChangeHandler);

    return () => {
      clearTimeout(setCallApi);
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      masterAccountCache.current = storage.getMasterAccount();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const checkChangeMasterAccount = () => {
      // lấy master account trong storage hiện tại
      const currentMasterAccount = storage.getMasterAccount();
      //nếu đã có masterAccount trước đó(đã được đăng nhập trước đó) và hiện tại tab khác logout tài khoản (không có master account) thì logout tại tab hiện tại
      if (!currentMasterAccount && masterAccountCache.current) {
        masterAccountCache.current = "";
        storage.logOut();
      }
      // nếu có 1 tab đăng nhập, các tab khác đều được reload
      if (currentMasterAccount && !masterAccountCache.current) {
        // delay để đảm bảo tab mới hoàn thành hết trạng thái đăng nhập sau đó mới reload
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return;
      } else if (
        // nếu có tab đăng nhập tài khoản khác, sẽ reload tab hiện tại
        currentMasterAccount &&
        masterAccountCache.current &&
        currentMasterAccount !== masterAccountCache.current
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return;
      }
      // gán lại trạng thái master account hiện tại
      masterAccountCache.current = storage.getMasterAccount();
    };

    window.addEventListener("storage", checkChangeMasterAccount);

    return () => {
      window.removeEventListener("storage", checkChangeMasterAccount);
    };
  }, []);

  useEffect(() => {
    dispatch(getWatchListsStart());
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(getWatchListsStart());
      }
    };

    if (storage.getAccessToken()) {
      subscribeOrderData(storage.getAccessToken());
      subscribeQRTopic(storage.getAccessToken());
    }

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getToken();
      dispatch(getNotificationTotalUnreadStart());
    }
  }, [isAuthenticated]);

  return (
    <>
      <AppRouter />
      <SetTitleRealTime />
    </>
  );
};

export default App;
