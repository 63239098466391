import styled from "styled-components/macro";

export const PutThroughWapper = styled.div`
  display: grid;
  grid-template-rows: min-content;
  height: 100%;
  margin-bottom: 8px;
`;

export const Header = styled.div`
  margin: 0 0 8px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.grey70};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.title.size};
  line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.title.weight.regular};
  text-transform: uppercase;
`;

export const Wapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 8px;
`;

export const TableWrappper = styled.div`
  border: 1px solid ${({ theme }) => theme.border1};
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  color: ${({ theme }) => theme.grey30};
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background1};
  .header-wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${({ theme }) => theme.bgElevated3};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption10.size};
    line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
    color: ${({ theme }) => theme.grey30};
    flex: 0 0 48px;
  }

  .title {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${({ theme }) => theme.background3};
    &.green {
      background: ${({ theme }) => theme.solidGreen};
    }
    &.red {
      background: ${({ theme }) => theme.solidRed};
    }

    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.border1};
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
    padding: 4px 8px;
  }
  .header-content {
    background: ${({ theme }) => theme.background3};
    box-shadow: ${({ theme }) => theme.shadowLineDown};
    height: 28px;
    display: flex;
    justify-content: space-between;
    div:first-child {
      text-align: left;
    }
  }

  .body-wrapper {
    flex-grow: 1;
  }
`;

export const Row = styled.div<{
  isOddRowCount: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 32px;
  background-color: ${({ theme, isOddRowCount }) =>
    isOddRowCount ? theme.background2 : theme.background1};
  :hover div {
    background-color: ${({ theme }) => theme.background4};
  }

  .ticker-div {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .ticker-col {
    min-width: 80px;
    > div {
      font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
    }
  }

  .price-col {
    min-width: 50px;
    flex: unset;
    font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
  }
  .volume-col {
    min-width: 80px;
    font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
  }
  .time-col {
    min-width: 80px;
  }

  .ticker-col,
  .price-col,
  .volume-col,
  .time-col,
  .time-matched-col {
    padding: 10px 8px;
    flex: 1;
    font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
    > div {
      font-size: ${({ theme }) => theme.typeFaces.watchlist.body.size};
    }
  }

  .transaction-value-col,
  .accumulated-value-col,
  .accumulated-volume-col {
    min-width: 80px;
    padding: 10px 8px;
  }

  .time-matched-col {
    min-width: 80px;
  }
`;

export const BidAskHeader = styled.div<{
  minWidth: number;
}>`
  min-width: ${({ minWidth }) => minWidth}px;
  text-align: right;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  padding: 4px 8px;
  color: ${({ theme }) => theme.grey30};
`;

export const MatchedHeader = styled.div<{
  minWidth: number;
}>`
  min-width: ${({ minWidth }) => minWidth}px !important;
  text-align: right;
  flex: 1;
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  padding: 4px 8px;
  color: ${({ theme }) => theme.grey30};
`;

export const TextColor = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;
  color: ${({ theme }) => theme.textcolorWhite};
`;
