import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .chart {
    padding: 16px 24px 0 24px;
    flex: 1 1 0;
    position: relative;
    overflow: hidden;
  }
`;
