import styled from "styled-components/macro";

export const TabCard = styled.div`
  display: flex;
  border-radius: 4px;
  margin-right: 15px;
`;

export const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  min-width: 60px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.grey80 : theme.grey70};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme, active }) => (active ? theme.brandPrimary : theme.grey20)};
  ${({ theme, active }) => active && `border: 1px solid ` + theme.brandPrimary};
  &:last-child {
    margin-right: 0;
  }
`;
