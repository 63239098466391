import { Wrap } from "components/commons/FormStyle/Table/styles";
import { ORDER_HISTORY_TABS } from "modules/order-history/constants";
import { reset } from "modules/order-history/redux";
import { activeTabSelector } from "modules/order-history/selectors";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";
import RequestFilterDer from "./RequestFiltersDer";
import RequestTableDer from "./RequestTableDer";
const ContractDebitContent: FC = () => {
  const activeTab = useSelector(activeTabSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Wrap>
      {activeTab === ORDER_HISTORY_TABS.ORDER_HISTORY && (
        <>
          <RequestFilter />
          <RequestTable />
        </>
      )}
      {activeTab === ORDER_HISTORY_TABS.ORDER_HISTORY_DER && (
        <>
          <RequestFilterDer />
          <RequestTableDer />
        </>
      )}
    </Wrap>
  );
};
export default ContractDebitContent;
