import { toast } from "components/commons/Toast";
import { getCustomerPro } from "core/http/apis/professional-investor";
import { IGetCustomerProParams } from "core/http/apis/professional-investor/types";
import { IGetCustomerProResponse } from "domain/models/ProfessionalInvestor";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getCustomerProRequest,
  getCustomerProSuccess,
  getCustomerProFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
} from "./redux";

function* getCustomerProWorker(
  action: ReturnType<typeof getCustomerProRequest>
) {
  try {
    const params: IGetCustomerProParams = action.payload;
    const response: IGetCustomerProResponse = yield call(
      getCustomerPro,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getCustomerProSuccess(response));
    } else {
      yield put(getCustomerProFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getCustomerProFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
    }: {
      isVisible: boolean;
    } = action.payload;

    yield put(updateModalStatusSuccess(isVisible));
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* getCustomerProWatcher() {
  yield takeLatest(getCustomerProRequest.type, getCustomerProWorker);
}

function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}

export default function* advanceLimitSaga() {
  yield all([getCustomerProWatcher(), updateModalStatusWatcher()]);
}
