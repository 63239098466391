import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { TICKER_LIST_ALL_KEY_NEW } from "./consts";

const data: any = localStorage.getItem(TICKER_LIST_ALL_KEY_NEW);
const tickerList = JSON.parse(data || "[]");

let tickerMapAllNew: { [key: string]: MrktSecInfoItem.AsObject } = {};
let tickerMapEquityNew: { [key: string]: MrktSecInfoItem.AsObject } = {};

const pushTickerToMapNew = (data: MrktSecInfoItem.AsObject[]) => {
  data.forEach((item) => {
    const ticker = item.seccd;

    // Map All Tickers
    tickerMapAllNew[ticker] = item;

    // Cơ sở
    tickerMapEquityNew[ticker] = item;
  });
};

const GlobalData = {
  // New
  // Lấy thông tin 1 mã chứng khoán từ store => Lấy tên vi hoặc en
  getTickerInfoNew(ticker: string): MrktSecInfoItem.AsObject {
    return tickerMapAllNew[ticker];
  },

  // Lấy danh sách Tk Lãi lỗ cơ sở
  getTickerMapEquityNew(): { [key: string]: MrktSecInfoItem.AsObject } {
    return tickerMapEquityNew;
  },
};
pushTickerToMapNew(tickerList);
export { pushTickerToMapNew };
export default GlobalData;
