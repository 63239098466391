import { useMemo, useState } from "react";
import { ReactComponent as IconStar } from "assets/image/svg/ic_star.svg";
import { ReactComponent as IconStarSelected } from "assets/image/svg/ic_star_light.svg";
import WatchListDropdown, { WatchListItem } from "./WatchListDropdown";
import { StarContainer, ComponentContainer } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  anonymousFavoriteListSelector,
  userFavoriteListSelector,
} from "modules/categories/redux/selectors";
import { addTickerToListByName, removeTicker } from "modules/categories/redux";
import { toast } from "components/commons/Toast";
import { isAuthenticatedSelector } from "modules/auth/selectors";

export type WishListStarProps = {
  tickerName: string;
};

function WishListStar({ tickerName }: WishListStarProps) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userFavoriteList = useSelector(userFavoriteListSelector);
  const anonymousFavoriteList = useSelector(anonymousFavoriteListSelector);
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const isSelected = useMemo(() => {
    if (isAuthenticated) {
      return userFavoriteList.find((favoriteList) =>
        favoriteList.listTickers.some((tickerCode) => tickerCode === tickerName)
      );
    }
    return anonymousFavoriteList.find((favoriteList) =>
      favoriteList.listTickers.some((tickerCode) => tickerCode === tickerName)
    );
  }, [isAuthenticated, userFavoriteList, anonymousFavoriteList, tickerName]);

  const list: WatchListItem[] = useMemo(() => {
    if (isAuthenticated) {
      return userFavoriteList.map((favoriteList) => {
        return {
          nameVi: favoriteList.nameVi,
          name: favoriteList.name,
          isBelongToWatchlist: favoriteList.listTickers.some(
            (item) => item === tickerName
          ),
        } as WatchListItem;
      });
    }
    return anonymousFavoriteList.map((favoriteList) => {
      return {
        nameVi: favoriteList.nameVi,
        name: favoriteList.name,
        isBelongToWatchlist: favoriteList.listTickers.some(
          (item) => item === tickerName
        ),
      } as WatchListItem;
    });
  }, [isAuthenticated, userFavoriteList, anonymousFavoriteList, tickerName]);

  function handleAddToList(listName: string) {
    if (!list.length) {
      toast("widgets.TickerInfo.errors.noWatchlistToAdd", "warning");
      return;
    }
    dispatch(addTickerToListByName({ tickerName, listName }));
  }

  function handleRemoveToList(listName: string) {
    if (!list.length) {
      return;
    }
    dispatch(removeTicker({ tickerName, listCode: listName }));
  }

  function handleClickOnStar() {
    if (!list.length) {
      toast("widgets.TickerInfo.errors.noWatchlistToAdd", "warning");
      return;
    }
    setExpanded(!isExpanded);
  }

  function handleClose() {
    setExpanded(false);
  }
  return (
    <ComponentContainer>
      <StarContainer onClick={handleClickOnStar}>
        {!isSelected && <IconStar fill="yellow" />}
        {isSelected && <IconStarSelected fill="yellow" />}
      </StarContainer>
      {isExpanded && (
        <WatchListDropdown
          list={list}
          onAddToList={handleAddToList}
          onRemoveFromList={handleRemoveToList}
          onClose={handleClose}
        />
      )}
    </ComponentContainer>
  );
}

export default WishListStar;
