import {
  Body,
  Container,
  Title,
  Footer,
} from "components/commons/ModalConfirmOpenLogin/styles";
import { Modal } from "react-bootstrap";
import { Button, Spacer } from "components/commons";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import storage from "helper/storage";
import { setVisibleModalConfirmCleanAll } from "modules/system/redux/common";
import { visibleModalConfirmCleanAllSelector } from "modules/system/selectors";
import { memo } from "react";

function ModalConfirm() {
  const dispatch = useDispatch();
  const visibleModal = useSelector(visibleModalConfirmCleanAllSelector);
  const handleConfirm = () => {
    localStorage.clear();
    storage.removeToken();
    window.location.reload();
  };

  return (
    <>
      <Modal
        show={visibleModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-284"
      >
        <Container>
          <Title>
            <FormattedMessage id="extends.confirmationOrderOnline.modalMessage.title" />
          </Title>
          <Body>
            <FormattedMessage id="cleanCache.contentConfirm" />
          </Body>
          <Footer>
            <Button
              color="accept"
              className="flex-1"
              onClick={() => dispatch(setVisibleModalConfirmCleanAll(false))}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Spacer size="m" />
            <Button
              onClick={handleConfirm}
              className="flex-1"
              fill
              color="accept"
            >
              <FormattedMessage id="common.accept" />
            </Button>
          </Footer>
        </Container>
      </Modal>
    </>
  );
}

export default memo(ModalConfirm);
