import { FC, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import TableBodyRow from "./TableBodyRow";
import { TableBodyWrapper } from "./styles";
import { useRef } from "react";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
}

export interface Props {
  columns: IHeaderCellInfo[];
  rowHeight: number;
  tickerList: string[];
}

const TableBody: FC<Props> = ({ columns, rowHeight, tickerList }) => {
  const tableBodyWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //lắng nghe elemenet tự sinh của thư viện react-window scroll để scroll table header
    if (tableBodyWrapperRef?.current?.firstChild?.firstChild) {
      const scrollBarHandler = () => {
        const tableHeaderWrapperRef = document.getElementById(
          "table-header-wrapper"
        );

        if (tableHeaderWrapperRef) {
          tableHeaderWrapperRef.scrollLeft =
            tableBodyWrapperRef?.current?.firstElementChild?.firstElementChild
              ?.scrollLeft || 0;
        }
      };

      tableBodyWrapperRef.current?.firstChild?.firstChild.addEventListener(
        "scroll",
        scrollBarHandler
      );
    }
  }, [tableBodyWrapperRef.current]);

  return (
    <TableBodyWrapper ref={tableBodyWrapperRef}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemCount={tickerList.length}
            itemSize={() => rowHeight}
            height={height}
            width={width}
            useIsScrolling
          >
            {({ style, index, isScrolling }) => (
              <TableBodyRow
                style={style}
                ticker={tickerList[index]}
                columns={columns}
                rowInd={index}
                isScrolling={isScrolling}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </TableBodyWrapper>
  );
};

export default TableBody;
