import { IntlShape } from "react-intl";

export const optionImage = (intl: IntlShape, key: number) => [
  {
    label: intl.formatMessage({
      id: "manegerMoney.modal.option.label0",
    }),
    value: key === 1 ? "DP_INTEREST_RATE_IND_URL" : "DP_INTEREST_RATE_ORG_URL",
  },
  {
    label: intl.formatMessage({
      id: "manegerMoney.modal.option.label1",
    }),
    value:
      key === 1
        ? "DP_INTEREST_RATE_EARLY_IND_URL"
        : "DP_INTEREST_RATE_EARLY_ORG_URL",
  },
];
