/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select, InputTextarea } from "components/commons";
// import { OptionSelectType } from "components/commons/Select";
import {
  EntrustBusinessCode,
  EOTPTypeSubmit,
  GetAccountType,
} from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  getBankDetail,
  getlistSubAccoToList,
  getListTransferBank,
  getSubAccoToList,
  getSubAccoTransferBank,
  resetDetailTransferBank,
  updateTypeTranfer,
} from "modules/transfer-internal/redux";
import {
  transferLocalFilterSelector,
  listAccoSubtoSelector,
  AccoSubtoSelector,
  AccoSubTransferBankSelector,
  selectGetBankDetail,
  getTypeTransferSelector,
} from "modules/transfer-internal/selectors";
import { OTPByBusinessCd } from "components/commons";
// import moment from "moment";

import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "./ConfirmModal";
import {
  AddBeneficiaryTxt,
  DetailCheckbox,
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailRow,
  DetailTextareaRow,
  DetailWrapper,
  TextDetail,
  TextNote,
  DetailInput,
  DetailInputTextarea,
} from "./styles";
import CheckboxTransfer from "./CheckboxTransfer";
import { components } from "react-select";
import ModaladdAccount from "./ModaladdAccount";
import {
  IfindParameterBankDetail,
  IFormAddAccountBank,
} from "core/http/apis/transfer-internal/types";
import {
  getTransferBankFeeAmtApi,
  submitTransferBank,
} from "core/http/apis/transfer-internal";
import { toast } from "components/commons/Toast";
import { statusTypeTransfer } from "modules/transfer-internal/constants";
import themes from "themes/abstracts/_themes";
import { RootState } from "redux/store";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import moment from "moment";
import storage from "helper/storage";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";

const RequestDetail = () => {
  const typeTranfer = useSelector(getTypeTransferSelector);
  const { type: themeType } = useSelector((state: RootState) => state.theme);
  const intl = useIntl();
  const dispatch = useDispatch();
  const listAcc = GetAccoList(
    GetAccountType.NONE_BANK_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );
  const typeTransfeOptions = useMemo(() => statusTypeTransfer(intl), [intl]);

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const { fromSubAccoNo, toSubAccoNo, status, m_fromDate, m_toDate } =
    useSelector(transferLocalFilterSelector);

  const valueTypeTransfer = useMemo(() => {
    return typeTransfeOptions.filter((e) => e.value === typeTranfer);
  }, [typeTranfer]);

  const [open, setopen] = useState(false);
  const [modalAdd, setmModalAdd] = useState(false);
  const [modalConfirm, setmModalConfirm] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const initialStat = {
    subAccoNo: "",
    subAccoCd: "",
    bankAccntNumber: "",
    bankAccntName: "",
    bankCd: "",
    bankBranchName: "",
    bankBranchLocationCd: "",
    amount: "",
    feeType: 0,
    remarks: "",
    srcChannel: 2,
    otpCode: null,
    otpType: EOTPTypeSubmit.OTP,
  };

  const [form, setForm] = useState<IFormAddAccountBank>(initialStat);

  const ChangeForm = (value: any, key: string) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const getListAccounSubto = (subAccoNo: string) => {
    dispatch(getlistSubAccoToList({ subAccoNo }));
    dispatch(getSubAccoTransferBank({ subAccoNo }));
  };

  const optionAccoTo = useSelector(listAccoSubtoSelector);

  const optionAccoToList = useMemo(() => {
    let list = [];
    list = optionAccoTo.map((account: any) => ({
      label: account.bankAccNumber!,
      value: account.bankAccNumber!,
    }));
    return list;
  }, [optionAccoTo]);

  const optionSubAccoTo = useSelector(AccoSubtoSelector);
  const accoFromBank = useSelector(AccoSubTransferBankSelector);

  const getAccounSubto = (toSubAccountNo: string) => {
    const childToFound = listAcc.find(
      (child) => child.subAccoNo === form?.subAccoNo
    );
    dispatch(
      getSubAccoToList({ subAccCd: childToFound?.subAccoCd, toSubAccountNo })
    );
  };

  const [checkBox, setCheckBox] = useState<number | null>();

  const conFirmAdd = async (response: IVerifyResponse) => {
    const params = {
      ...form,
      bankAccntName: optionSubAccoTo?.bankAccountName,
      bankBranchLocationCd: optionSubAccoTo?.bankBranchLocationCd,
      bankBranchName: optionSubAccoTo?.bankBranchNameDisp,
      bankCd: optionSubAccoTo?.bankCd,
      subAccoCd: optionSubAccoTo?.subAccoCd,
      amount: numeral(form.amount).value(),
      otpCode: response?.otp ?? "",
      otpType: response?.otpTypeSubmit!,
    };

    const res: any = await submitTransferBank(params);

    const resLogData = {
      eventGroup: GroupEvent.moneyTransfer,
      event: RealtimeConst.postMoneyTransfer,
      data: params.toString(),
    };

    storage.commonPushLogFirebase(resLogData);

    if (res?.statusCode === 0) {
      toast(
        "transferInternal.historyTransferBank.modal.toastSuccess",
        "success"
      );
      setForm(initialStat);
      setCheckBox(null);
      resetForm();
      dispatch(
        getListTransferBank({
          subAccoNoFrom: fromSubAccoNo ?? "",
          toSubAccoNo: toSubAccoNo ?? "",
          status: status ? +status : -1,
          fromDate: Number(moment(m_fromDate).format("YYYYMMDD")),
          toDate: Number(moment(m_toDate).format("YYYYMMDD")),
          page: 0,
          size: 30,
        })
      );
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
    setmModalConfirm(false);
  };

  const [error, setError] = useState<string | undefined>("");

  const checkError = () => {
    const value = Object.keys(form).find(
      (key) => form[key] === "" || form[key] === 0 || form[key] === null
    );
    if (value !== "otpCode") {
      setError(value);
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const openModalConfirm = () => {
    const check = checkError();
    if (check) {
      setmModalConfirm(true);
    }
  };
  const getDataBankDetail = () => {
    const paramsBank: IfindParameterBankDetail = {
      branchCd: accoFromBank.branchCd,
      transactionCd: accoFromBank.transactionCd,
      groupCd: "",
      paramGroup: "TR00",
      paramName: null,
      bankCd: optionSubAccoTo.bankCd,
      bankBranchCd: null,
    };
    dispatch(getBankDetail(paramsBank));
  };
  const bankDetail: any = useSelector(selectGetBankDetail);

  // const feeAmt = useCallback(() => {
  //   let amountFee = 0;
  //   if (optionSubAccoTo?.bankCd !== "" && form?.amount) {
  //     const res: any = await getTransferBankFeeAmtApi({
  //       bankCd: optionSubAccoTo?.bankCd,
  //       amount: numeral(form.amount).value(),
  //     });
  //     if (res?.statusCode === 0) {
  //       amountFee = res?.data;
  //     }
  //   }
  //   if (bankDetail?.transferFeeFlg === 1) {
  //     const val1 = Number(form?.amount) * bankDetail?.transferFeeRate;
  //     const val2 = bankDetail?.transferFeeMin;
  //     amountFee = Math.max(val1, val2);
  //   } else {
  //     amountFee = bankDetail?.transferFee;
  //   }
  //   return numeral(amountFee).format("0,0");
  // }, [bankDetail, form.amount]);

  const [feeAmt, setfeeAmt] = useState(0);

  const getFeeAmt = async () => {
    if (optionSubAccoTo?.bankCd !== "" && form?.amount) {
      const res: any = await getTransferBankFeeAmtApi({
        bankCd: optionSubAccoTo?.bankCd,
        amount: numeral(form?.amount).value(),
      });
      if (res?.statusCode === 0) {
        setfeeAmt(res?.data);
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }
    }
  };

  useEffect(() => {
    getFeeAmt();
  }, [bankDetail, form.amount]);

  useEffect(() => {
    if (optionSubAccoTo) {
      setForm({
        ...form,
        bankAccntName: optionSubAccoTo?.bankAccountName,
        bankBranchLocationCd: optionSubAccoTo?.bankBranchLocationCd,
        bankBranchName: optionSubAccoTo?.bankBranchNameDisp,
        subAccoCd: optionSubAccoTo?.subAccoCd,
        bankCd: optionSubAccoTo?.bankCd,
        remarks: intl.formatMessage(
          {
            id: "transferInternal.historyTransferBank.detail.valueContent",
          },
          {
            tkFrom: form.subAccoNo,
            nametk: accoFromBank?.fullName,
            tkTo: form.bankAccntNumber,
            nameTkTo: optionSubAccoTo?.bankAccountName,
            bank: optionSubAccoTo?.bankCd,
            branch: optionSubAccoTo?.bankBranchNameDisp,
            city: optionSubAccoTo?.province,
          }
        ),
      });
    }
  }, [optionSubAccoTo, form.subAccoNo, form.bankAccntNumber, accoFromBank]);

  const resetForm = () => {
    setForm(initialStat);
    setCheckBox(null);
    dispatch(resetDetailTransferBank());
    setfeeAmt(0);
  };

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.typeMoneyTransfer" />
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              className="flex-fill"
              value={valueTypeTransfer}
              options={typeTransfeOptions}
              onChange={(e: any) =>
                dispatch(updateTypeTranfer({ type: e.value }))
              }
            />
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.fromSubAccoNo" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              value={
                accountOptions.find((item) => item.value === form?.subAccoNo)
                  ? accountOptions.find(
                      (item) => item.value === form?.subAccoNo
                    )
                  : { label: "", value: "" }
              }
              options={accountOptions}
              onChange={(e: any) => {
                getListAccounSubto(e.value);
                ChangeForm(e.value, "subAccoNo");
              }}
            />
            {error === "subAccoNo" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.toSubAccoNo" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputWrapper className="text-left">
            <Select
              value={
                optionAccoToList.find(
                  (item) => item.value === form?.bankAccntNumber
                )
                  ? optionAccoToList.find(
                      (item) => item.value === form?.bankAccntNumber
                    )
                  : { label: "", value: "" }
              }
              options={optionAccoToList}
              onChange={(e: any) => {
                getAccounSubto(e.value);
                ChangeForm(e.value, "bankAccntNumber");
              }}
              menuIsOpen={open}
              onMenuOpen={() => setopen(true)}
              onMenuClose={() => setopen(false)}
              components={{
                Menu: ({ children, ...rest }: any) => {
                  return (
                    <components.Menu {...rest}>
                      {children}
                      <AddBeneficiaryTxt
                        onClick={() => {
                          setopen(false);
                          setmModalAdd(true);
                        }}
                      >
                        <span>+</span>
                        {intl.formatMessage({
                          id: "auth.tradingAccount.addAccountBeneficiary",
                        })}
                      </AddBeneficiaryTxt>
                    </components.Menu>
                  );
                },
              }}
            />
            {error === "bankAccntNumber" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.nameToSubAccoNo" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{optionSubAccoTo?.bankAccountName}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.nambank" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{optionSubAccoTo?.bankCd}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.branch" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{optionSubAccoTo?.bankBranchNameDisp}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.city" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{optionSubAccoTo?.province}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.address" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>{optionSubAccoTo?.province}</TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.transferAvailability" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>
              {numeral(accoFromBank?.transferAvail).format("0,0")}
            </TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.amount" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInput>
            <Input
              placeholder={intl.formatMessage({
                id: "transferInternal.historyTransferBank.detail.amount",
              })}
              onChange={(e) => ChangeForm(e.target.value, "amount")}
              onBlur={() => getDataBankDetail()}
              value={form?.amount ? numeral(form?.amount).format("0,0") : ""}
            />
            {error === "amount" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInput>
        </DetailRow>

        <DetailRow>
          <DetailLabel></DetailLabel>
          <DetailCheckbox className="text-right">
            <CheckboxTransfer
              checked={checkBox === 1 ? true : false}
              disabled={false}
              values={1}
              onClick={(e) => {
                setCheckBox(e);
                ChangeForm(e, "feeType");
              }}
              text="transferInternal.historyTransferBank.detail.typeAmount.value0"
            />
            <CheckboxTransfer
              checked={checkBox === 2 ? true : false}
              disabled={false}
              values={2}
              onClick={(e) => {
                setCheckBox(e);
                ChangeForm(e, "feeType");
              }}
              text="transferInternal.historyTransferBank.detail.typeAmount.value1"
            />
          </DetailCheckbox>
        </DetailRow>
        <div className="w-100 text-right"></div>
        <DetailRow>
          <DetailLabel></DetailLabel>
          <DetailInputWrapper className="text-right">
            {error === "feeType" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputWrapper>
        </DetailRow>

        <DetailRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.modal.fee" />
          </DetailLabel>
          <DetailInputWrapper className="text-right">
            <TextDetail>
              {/* {feeAmt()} */}
              {numeral(feeAmt).format("0,0")}
            </TextDetail>
          </DetailInputWrapper>
        </DetailRow>

        <DetailTextareaRow>
          <DetailLabel>
            <FormattedMessage id="transferInternal.historyTransferBank.detail.content" />
            <span className="text-danger mb-0"> *</span>
          </DetailLabel>
          <DetailInputTextarea className="text-left mt-0">
            <InputTextarea
              textArea
              onChange={(e) => ChangeForm(e.target.value, "remarks")}
              value={form.remarks}
            />
            {error === "remarks" && (
              <span className="text-error pt-1 text-danger float-right">
                {intl.formatMessage({
                  id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                })}
              </span>
            )}
          </DetailInputTextarea>
        </DetailTextareaRow>

        {/* <DetailRow> */}
        <TextNote style={{ color: themes[themeType].textColorBlue }}>
          <FormattedMessage id="transferInternal.historyTransferBank.detail.note" />
        </TextNote>
        {/* </DetailRow> */}
      </DetailCol>
      <div className="d-flex p-2">
        <Button
          color="accept"
          className="button-filter mr-2 w-50"
          onClick={() => resetForm()}
          // loading={loadding}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.cancel",
          })}
        </Button>
        <Button
          color="gradientaAccept"
          className="button-filter w-50"
          // loading={loadding}
          onClick={() => openModalConfirm()}
        >
          {intl.formatMessage({
            id: "transferInternal.transferInternal.detail.button.accept",
          })}
        </Button>
      </div>

      {modalConfirm && (
        <ConfirmModal
          isVisible={modalConfirm}
          onClose={() => setmModalConfirm(false)}
          data={{
            amount: form?.amount,
            remarks: form?.remarks,
            subAccoNo: form?.subAccoNo,
          }}
          onSuccess={() => setisVisible(true)}
        />
      )}

      {modalAdd && (
        <ModaladdAccount
          isVisible={modalAdd}
          onClose={() => setmModalAdd(false)}
          getListAccounSubto={() => getListAccounSubto(form?.subAccoNo ?? "")}
        />
      )}
      {isVisible && (
        <OTPByBusinessCd
          handleClose={() => setisVisible(false)}
          getResult={conFirmAdd}
          businessCd={EBusinessCd.TRANSFER}
        />
      )}
    </DetailWrapper>
  );
};

export default RequestDetail;
