import queryString from "query-string";

export const getListMarginSecuritiesPortfolioApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findMarginSecrities${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const findMarginContractApi = (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findMarginContract${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
