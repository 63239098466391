import TreemapChart from "components/commons/Charts/TreemapChart";
import { DEFAULT_WIDGET_REFETCH_INTERVAL, UNIT_NUMBER } from "helper/consts";
import { getColor } from "helper/utils";
import { get } from "lodash";
import sortBy from "lodash/sortBy";
import { languageTypeSelector } from "modules/categories/redux/selectors";
import { fetchTransactionIndustry } from "modules/market/redux/transactionIndustry";
import numeral from "numeral";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce, useInterval } from "react-use";
import { RootState } from "redux/store";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { ValueVolumeDistributionDataNew } from "../TransactionValueVolume/types";
import { ContentContainer } from "./styles";

const transactionValueVolumeSelector = (state: RootState) =>
  state.market.industry.transactionIndustry;

function TransactionValueVolume() {
  const dispatch = useDispatch();
  const { transactionValueVolumeData } = useSelector(
    transactionValueVolumeSelector
  );
  const languageType = useSelector(languageTypeSelector);
  const { valueData } = transactionValueVolumeData;
  const themeType = useSelector(themeTypeSelector);
  const intl = useIntl();
  const getIndustryName = (industryNameVi: string, industryNameEn: string) => {
    return languageType === "vi" ? industryNameVi : industryNameEn;
  };

  useEffectOnce(() => {
    dispatch(fetchTransactionIndustry());
  });

  useInterval(() => {
    dispatch(fetchTransactionIndustry());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  const generateData = () => {
    let chartData: number[] = [];
    let chartTooltips: string[] = [];
    let chartColors: string[] = [];
    let chartLabels: string[] = [];
    let chartLongLabels: string[] = [];
    const transactionData = valueData;
    const sortedTransactionData = sortBy(transactionData, [
      function (o) {
        return o.totalTraded;
      },
    ]).reverse();
    sortedTransactionData.forEach((item: ValueVolumeDistributionDataNew) => {
      chartData.push(item.totalTraded! * UNIT_NUMBER.ONE_THOUSAND);
      chartTooltips.push(
        `${numeral(item.totalTraded! / UNIT_NUMBER.ONE_THOUSAND).format(
          "0,0"
        )}${intl.formatMessage({ id: "common.million" })}`
      );
      chartColors.push(get(themes[themeType], getColor(item.color!)));
      chartLabels.push(
        getIndustryName(item.industryNameVi, item.industryNameEn) || ""
      );
      chartLongLabels.push(
        `${getIndustryName(item.industryNameVi, item.industryNameEn)}`
      );
    });

    return {
      chartData,
      chartColors,
      chartLabels,
      chartTooltips,
      chartLongLabels,
    };
  };

  const {
    chartData,
    chartColors,
    chartLabels,
    chartTooltips,
    chartLongLabels,
  } = generateData();

  return (
    <ContentContainer>
      <div className="chart">
        <TreemapChart
          values={chartData}
          tooltipValues={chartTooltips}
          colors={chartColors}
          treeLabels={chartLabels}
          treeLongLabels={chartLongLabels}
        />
      </div>
    </ContentContainer>
  );
}

export default TransactionValueVolume;
