import { combineUrlAndParam } from "helper/utils";

// Lịch sử chuyển khoản chứng khoán
export const findTransferSecHistoryApi = (param: any) =>
  combineUrlAndParam("/findTransferSecHistory", param);

// Xóa yêu cầu chuyển khoản chứng khoán chưa duyệt
export const deleteTransferSecApi = (param: any) =>
  combineUrlAndParam("/deleteTransferSec", param);

// Lấy số chứng khoán đang sở hữu của tiểu khoản
export const inquiryAccountTransferSecApi = (param: any) =>
  combineUrlAndParam("/inquiryAccountTransferSec", param);

// Kiểm tra tài khoản có bị phong tỏa không
export const getCustomerRestrictionApi = (param: any) =>
  combineUrlAndParam("/getCustomerRestriction", param);

// Lấy giá trước khi submit chuyển khoản ck
export const findPortfolioApi = (param: any) =>
  combineUrlAndParam("/findPortfolio", param);

// Thực hiện chuyển khoản chứng khoán
export const submitTransferSecApi = () => {
  return "/submitTransferSecList";
};
