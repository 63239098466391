import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px ;
  height: 100%;
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
`;
export const ContentLeft = styled.div<{
  width: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: ${({ width }) => width};
  height: 100%;
`;
export const ContentRight = styled.div<{
  width: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: ${({ width }) => width};
  height: 100%;
`;

export const LabelCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  font-size: 15px;
  font-style: normal;
  color: ${({ theme }) => theme.grey0};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  height: 100%;
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
  }
  .button-filter {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
    @media (min-width: 1800px) {
      margin-left: auto;
    }
  }
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.grey0};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
  min-width: 80px;
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
  bgcolor?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.red;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green;
      case "violet":
        return theme.violet;
      case "orange":
        return theme.solidOrange;
    }
  }};

  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  color: ${({ bgcolor }) => bgcolor};
  white-space: nowrap;
`;
