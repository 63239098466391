import { FC } from "react";
import ContentData from "./content";

const ManageContentRight: FC = () => {
  return (
    <>
      <ContentData />
    </>
  );
};

export default ManageContentRight;
