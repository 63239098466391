import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";

import { ISearchContractParams } from "core/http/apis/create-contract/types";
import {
  listContractSelector,
  statusLiquidateSelector,
  filterCreateContractSelector,
} from "modules/create-contract/selectors";
import {
  searchContractRequest,
  liquidateContractRequest,
  updateItemEdit,
} from "modules/create-contract/redux";

import { Contract } from "domain/models/CreateContract";

import storage from "helper/storage";
import moment from "moment";
import { memo, useMemo, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  HeaderCell,
  TableCellContent,
  ButtonLiquidate,
  ButtonEdit,
} from "./styles";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import ModalConfirm from "./ModalConfirm";
// import { MarginDebitContractWatch } from "domain/models/ContractDebit";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listContractSelector);
  const statusLiquidate = useSelector(statusLiquidateSelector);
  const { contractSts, fromDate, toDate } = useSelector(
    filterCreateContractSelector
  );
  const themeType = useSelector(themeTypeSelector);

  useEffect(() => {
    if (statusLiquidate) {
      const submitData: ISearchContractParams = {
        branchCd: "",
        transactionCd: "",
        custNo: storage.getMasterAccount(),
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        contractSts: contractSts ? contractSts : 1,
        status: "",
        srcChannel: "",
        closeDateFrom: 0,
        closeDateTo: 0,
        linkPower: "",
      };
      dispatch(searchContractRequest(submitData));
    }
  }, [statusLiquidate]);

  const center = "center";
  const flexStart = "flex-start";
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [itemLiquidateSelected, setItemLiquidateSelected] = useState<
    Contract | undefined
  >(undefined);

  const clickLiquidateHandler = (item: Contract) => {
    setItemLiquidateSelected(item);
    setVisibleModalConfirm(true);
  };
  const clickEditHandler = (item: Contract) => {
    dispatch(
      updateItemEdit({
        brokerCd: item && item.brokerName ? item.brokerName : "",
        remarks: item && item.remarks ? item.remarks : "",
        alloDate: item?.alloDate ? item.alloDate : "",
        refNo: item?.refNo ? item.refNo : "",
        action: 2,
        contractNo: item?.contractNo ? item.contractNo : "",
        timeStamp: item?.updDateTime ? item.updDateTime : null,
      })
    );
  };

  const setConfirm = () => {
    if (itemLiquidateSelected) {
      dispatch(
        liquidateContractRequest({
          action: "1",
          dataList: JSON.stringify([
            {
              // branchCd: itemLiquidateSelected.branchCd,
              transactionCd: itemLiquidateSelected.transactionCd ?? "",
              custNo: itemLiquidateSelected.custNo ?? "",
              // custName: itemLiquidateSelected.custName,
              contractNo: itemLiquidateSelected.contractNo ?? "",
              tradeDate: itemLiquidateSelected.tradeDate ?? "",
              // closeDate: itemLiquidateSelected.closeDate,
              brokerCd: itemLiquidateSelected.brokerCd ?? "",
              // brokerName: itemLiquidateSelected.brokerName,
              // contracSts: itemLiquidateSelected.contracSts,
              status: itemLiquidateSelected.status ?? "",
              // contracStsDisp: itemLiquidateSelected.contracStsDisp,
              // statusDisp: itemLiquidateSelected.statusDisp,
              remarks: itemLiquidateSelected.remarks ?? "",
              alloDate: itemLiquidateSelected.alloDate ?? "",
              refNo: itemLiquidateSelected.refNo ?? "",
              regDateTime: itemLiquidateSelected.regDateTime ?? "",
              regUserId: itemLiquidateSelected.regUserId ?? "",
              updDateTime: itemLiquidateSelected.updDateTime ?? "",
              updUserId: itemLiquidateSelected.updUserId ?? "",
              // timeStamp: itemLiquidateSelected.timeStamp,
              custCd: itemLiquidateSelected.custCd ?? "",
              srcChannel: itemLiquidateSelected.srcChannel ?? "",
              // powerLink: itemLiquidateSelected.powerLink,
              select: true,
              // linkReport: itemLiquidateSelected.linkReport,
            },
          ]),
        })
      );
    }
  };

  const convertNumberToDateFormatDDMMYYHHMMSS = ({
    date,
    inputFormat = "YYYY-MM-DD HH:mm:ss",
    outputFormat = "DD/MM/YYYY HH:mm:ss",
  }: {
    date?: number;
    inputFormat?: string;
    outputFormat?: string;
  }) => {
    if (!date) return "";

    return moment(date, inputFormat).format(outputFormat);
  };
  function changeColor(value: string) {
    if (value === "1")
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="createContract.status.available" />
        </TableCellContent>
      );
    else if (value === "2")
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="createContract.status.liquidated" />
        </TableCellContent>
      );

    return "";
  }
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="createContract.table.openDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="createContract.table.contrNo" />
          </HeaderCell>
        ),
        minWidth: 180,
        accessor: "contractNo",

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="createContract.table.collab" />
          </HeaderCell>
        ),
        accessor: "brokerCd",
        minWidth: 180,
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={flexStart}>
              {/* {props.value}-{currentRow.brokerName} */}
              {currentRow.brokerName}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="createContract.table.expiredDate" />
          </HeaderCell>
        ),
        accessor: "closeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="createContract.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,

        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <TableCellContent align={center}>
              {changeColor(currentRow.contracSts)}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="createContract.table.remarks" />
          </HeaderCell>
        ),
        accessor: "remarks",
        minWidth: 180,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="createContract.table.transaction" />
          </HeaderCell>
        ),
        accessor: "transactionCd",
        minWidth: 135,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="createContract.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "regDateTime",
        minWidth: 135,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormatDDMMYYHHMMSS({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;

          return (
            <div className="btn_action_hover">
              {currentRow.contracSts === "1" && (
                <>
                  <ButtonEdit
                    className="liquidate"
                    color={themes[themeType].grey0}
                    onClick={() => clickEditHandler(currentRow)}
                  >
                    <FormattedMessage id="createContract.table.edit" />
                  </ButtonEdit>
                  <ButtonLiquidate
                    className="liquidate"
                    color={themes[themeType].grey0}
                    onClick={() => clickLiquidateHandler(currentRow)}
                  >
                    <FormattedMessage id="createContract.table.liquidation" />
                  </ButtonLiquidate>
                </>
              )}
            </div>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="profitLossTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"profitLossTable"}
        isHasFooter={false}
      />

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "createContract.message.liquidateContent",
        })}
      />
    </Container>
  );
}

export default memo(RequestTable);
