import styled from "styled-components/macro";

const SuggestionContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .item {
    flex: 1 0 auto;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.grey70};

    cursor: pointer;
    background: ${({ theme }) => theme.grey70};
    color: ${({ theme }) => theme.grey20};
    :hover {
      background: ${({ theme }) => theme.grey80};
      color: ${({ theme }) => theme.brandPrimary};
      border: 1px solid ${({ theme }) => theme.brandPrimary};
    }
    :not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const Title = styled.div`
  flex: 2;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.grey30};
`;

const Body = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
`;

const Empty = styled.div`
  flex: 2;
`;

const Error = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;

const YesNoButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 8px;
`;

export { SuggestionContainer, Title, Body, Empty, Error, YesNoButton };
