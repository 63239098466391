import { convertHexToRGBA } from "helper/utils";
import styled from "styled-components/macro";

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
`;

const ButtonPlaceOrder = styled.div<{ color: string }>`
  background: ${({ theme, color }) => theme[color]};
  color: ${({ theme }) => theme.textcolorWhite};
  border-radius: 4px;
  padding: 8px;
  max-height: ${({ theme }) => theme.button.height};
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  flex: 1;
  :hover {
    background: ${({ theme, color }) => convertHexToRGBA(theme[color], 0.7)};
  }
`;

const BuySellContainer = styled.div<{
  isActive?: boolean;
  type: "green50" | "red50";
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  flex: 1;
  min-width: 100px;
  height: 30px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  cursor: pointer;
  color: ${({ isActive, theme }) =>
    isActive ? theme.textcolorWhite : theme.grey50};
  background-color: ${({ theme, isActive, type }) =>
    isActive ? theme[type] : theme.grey90};

  :hover {
    color: ${({ theme }) => theme.textcolorWhite};
    background-color: ${({ type, theme, isActive }) =>
      !isActive && convertHexToRGBA(theme[type], 0.4)};
  }
`;

const FormTitle = styled.div`
  flex: 2;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.grey30};
`;

const FormControl = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-between;
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  .flex-4 {
    flex: 4;
  }
  .flex-5 {
    flex: 5;
  }
`;

const Label = styled.div<{
  weight?: string | undefined;
  line?: string | undefined;
  colorKey?: string | undefined;
  isTruncate?: boolean;
  isCursor?: boolean;
}>`
  color: ${({ theme, colorKey }) =>
    colorKey ? theme[colorKey] : theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.body.size} !important;
  ${({ weight }) => weight && `font-weight: ${weight}`};
  ${({ line }) => line && `line-height: ${line}`};
  margin-right: 2px;
  white-space: nowrap;
  ${({ isTruncate }) =>
    isTruncate &&
    `
  overflow: hidden;
  text-overflow: ellipsis;`};
  ${({ isCursor }) => isCursor && `cursor: pointer;`}
`;

const Error = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-end;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
`;

const Content = styled.div`
  background: ${({ theme }) => theme.background1};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-width: 437px; */
  .code {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
  }

  .text-big {
    font-weight: ${({ theme }) => theme.typeFaces.header.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.header.size};
    line-height: ${({ theme }) => theme.typeFaces.header.lineHeight};
    color: ${({ theme }) => theme.green50};
  }
  .text-small {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    color: ${({ theme }) => theme.green50};
  }

  .tran {
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  }

  .gia-tran {
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    cursor: pointer;
  }

  .violet {
    color: ${({ theme }) => theme.violet50};
  }

  .blue {
    color: ${({ theme }) => theme.blue50};
  }

  .yellow {
    color: ${({ theme }) => theme.yellow50};
  }

  .w-20 {
    width: 20%;
  }
  .w-80 {
    width: 80%;
  }
  .ticker-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  .w-20 {
    width: 20%;
  }
  .w-80 {
    width: 80%;
  }
  .btn-place-order-buy {
    background: ${({ theme }) => theme.green50};
    :hover {
      background: ${({ theme }) => convertHexToRGBA(theme.green50, 0.7)};
    }
  }
  .btn-place-order-sell {
    background: ${({ theme }) => theme.red50};
    :hover {
      background: ${({ theme }) => convertHexToRGBA(theme.red50, 0.7)};
    }
  }
  .btn-place-order {
    color: ${({ theme }) => theme.textcolorWhite};
    border-radius: 4px;
    max-height: ${({ theme }) => theme.button.height};
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    flex: 1;
  }
`;

const BuyButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 10px);
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.green50 : theme.grey70};
    transform: skewX(345deg);
    height: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: calc(100% - 10px);
    border-radius: 4px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.green50 : theme.grey70};
  }
`;

const SellButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 10px);
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.red50 : theme.grey70};
    transform: skewX(345deg);
    height: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 30px;
    width: calc(100% - 10px);
    border-radius: 4px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.red50 : theme.grey70};
  }
`;

const IconReset = styled.div`
  margin-right: 8px;
`;

const RowButton = styled.div`
  display: flex;
  padding: 16px 14px;
`;

const BuySellButtonRow = styled.div`
  display: flex;
  overflow: hidden;
  height: 48px;
  padding: 8px 16px;
`;

const ButtonItem = styled.div`
  flex: 1;
  position: relative;
`;

const LabelBuySell = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  color: ${({ isActive, theme }) =>
    isActive ? theme.textcolorWhite : theme.textcolorWhite};

  :hover {
    color: ${({ theme }) => theme.textcolorWhite};
  }
`;

const MatMethod = styled.div`
  border: 1px solid ${({ theme }) => theme.grey70};
  background: ${({ theme }) => theme.grey100};
  border-radius: 4px;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
`;

export {
  Container,
  BuySellContainer,
  ButtonPlaceOrder,
  FormTitle,
  FormControl,
  Label,
  Error,
  Content,
  IconReset,
  RowButton,
  BuySellButtonRow,
  BuyButton,
  SellButton,
  ButtonItem,
  LabelBuySell,
  MatMethod,
};
