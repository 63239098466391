import { FC } from "react";
import { Tab, Wrap } from "./styles";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setTab } from "modules/subscribeBuy/redux";
import { SUBSCRIBE_TABS } from "modules/subscribeBuy/constants";
import { selectTab } from "modules/subscribeBuy/selectors";

const NavigatonSubscribe: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(selectTab);

  return (
    <Wrap>
      <div className="header-left">
        <div>
          <Tab
            isActive={activeTab === SUBSCRIBE_TABS.SUBSCRIBE}
            onClick={() => dispatch(setTab(SUBSCRIBE_TABS.SUBSCRIBE))}
          >
            {intl.formatMessage({
              id: "subscribe.tabNavigation.subscribe",
            })}
          </Tab>
        </div>
        <div>
          <Tab
            isActive={activeTab === SUBSCRIBE_TABS.HISTORY_SUBSCRIBE}
            onClick={() => dispatch(setTab(SUBSCRIBE_TABS.HISTORY_SUBSCRIBE))}
          >
            {intl.formatMessage({
              id: "subscribe.tabNavigation.historySubscribe",
            })}
          </Tab>
        </div>
        <div>
          <Tab
            isActive={activeTab === SUBSCRIBE_TABS.SEARCH_SUBSCRIBE}
            onClick={() => dispatch(setTab(SUBSCRIBE_TABS.SEARCH_SUBSCRIBE))}
          >
            {intl.formatMessage({
              id: "subscribe.tabNavigation.searchSubscribe",
            })}
          </Tab>
        </div>
      </div>
    </Wrap>
  );
};

export default NavigatonSubscribe;
