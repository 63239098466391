import { Container, ContentRight, LabelCell, Wrap } from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import Search from "modules/transfer-history/pages/components/Search";
import Table from "./components/Table";
import { useMemo, useState } from "react";
import { optionStatus } from "../constants";
import { findCbotBondTransferHistoryApi } from "core/http/apis/transferHistory";
import moment from "moment";
import storage from "helper/storage";
import { toast } from "components/commons/Toast";

const TransferHistory = () => {
  const intl = useIntl();
  const date = new Date();
  const limit = 30;
  const statusOptions = useMemo(() => optionStatus(intl), [intl]);
  const custNo = storage.getMasterAccount();

  const initialState = {
    fromDate: date,
    toDate: date,
    status: statusOptions[0],
  };
  const [pagination, setPagination] = useState({
    page: 0,
    enable: true,
  });
  const [search, setSearch] = useState(initialState);
  const [data, setData] = useState([]);
  const changeSearch = (value: any, key: string) => {
    setSearch({
      ...search,
      [key]: value,
    });
  };

  const getData = async (cb: any) => {
    const params = {
      fromDate: search?.fromDate
        ? moment(search?.fromDate).format("YYYYMMDD")
        : "0",
      toDate: search?.toDate ? moment(search?.toDate).format("YYYYMMDD") : "0",
      status: search?.status?.value ?? "-1",
      custNo: custNo,
      page: 0,
      rowPerPage: limit,
    };
    const res: any = await findCbotBondTransferHistoryApi(params);
    if (res?.statusCode === 0) {
      setPagination({
        page: 0,
        enable: true,
      });
      setData(res?.data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
    if (cb) cb();
  };

  const fetchMoreData = async () => {
    if (data.length < (pagination.page + 1) * limit) {
      return setPagination({
        ...pagination,
        enable: false,
      });
    }
    const params = {
      fromDate: moment(search?.fromDate).format("YYYYMMDD"),
      toDate: moment(search?.toDate).format("YYYYMMDD"),
      status: search?.status?.value ?? "-1",
      custNo: custNo,
      page: pagination.page + 1,
      rowPerPage: limit,
    };
    const res: any = await findCbotBondTransferHistoryApi(params);
    if (res?.statusCode === 0) {
      const dataList = data.concat(res?.data);
      setData(dataList);
      setPagination({
        ...pagination,
        page: pagination.page + 1,
      });
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  return (
    <Container>
      <ContentRight width="100%">
        <LabelCell>
          <FormattedMessage id="transferHistory.title" />
        </LabelCell>
        <Wrap>
          <Search
            changeSearch={changeSearch}
            search={search}
            getData={getData}
          />
          <Table
            data={data}
            pagination={pagination}
            fetchMoreData={fetchMoreData}
          />
        </Wrap>
      </ContentRight>
    </Container>
  );
};

export default TransferHistory;
