import { memo } from "react";
import ForeignChart from "../ForeignChart";
import ForeignIndustryChart from "../ForeignIndustryChart";
import { ContentContainer } from "components/widgets/generals/MarketWatch/styles";
import { ForeignTab } from "./types";
export interface ContentProps {
  activeTab: ForeignTab;
}
function Content({ activeTab }: ContentProps) {
  return (
    <ContentContainer>
      {activeTab === "topStocks" && <ForeignChart />}
      {activeTab === "topIndustry" && <ForeignIndustryChart />}
    </ContentContainer>
  );
}

export default memo(Content);
