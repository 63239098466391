import { useDispatch, useSelector } from "react-redux";
import { ContentContainer } from "./styles";
import { useEffectOnce, useInterval } from "react-use";
import { fetchMarketStatsRequest } from "modules/market/redux/marketStats";
import { marketStatsSelector } from "modules/market/redux/selectors";
import MarketBubbleChart from "./MarketBubbleChart";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

function StatBasketContribution() {
  const dispatch = useDispatch();

  const { chartData } = useSelector(marketStatsSelector);

  useInterval(() => {
    dispatch(fetchMarketStatsRequest());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  useEffectOnce(() => {
    dispatch(fetchMarketStatsRequest());
  });

  return (
    <ContentContainer>
      <div className="chart">
        <MarketBubbleChart
          scaleData={chartData.scaleData}
          colors={chartData.colors}
        />
      </div>
    </ContentContainer>
  );
}

export default StatBasketContribution;
