import React from "react";
import { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import moment from "moment";
import {
  createGradientPlugin,
  NetflowGradientPlugin,
} from "helper/chartPlugins";
import numeral from "numeral";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";
import { chartDataProfitLossSelector } from "modules/profit-loss/selectors";
// import ChartDataLabels from "chartjs-plugin-datalabels";
const GradientBgPlugin: any = createGradientPlugin("rgba(65, 188, 228, 0.2)");

const ChartProfitLoss: React.FC = () => {
  const themeType = useSelector(themeTypeSelector);
  const { values, colors, labels } = useSelector(chartDataProfitLossSelector);

  const borderColor = colors.map((item) => getChartColor(item));

  function getChartColor(colorCode: number): string {
    switch (colorCode) {
      case -1:
        return themes[themeType].solidRed;
      case 1:
        return themes[themeType].solidGreen;
      default:
        return themes[themeType].solidYellow;
    }
  }

  const plugins = [GradientBgPlugin, NetflowGradientPlugin];

  const data = {
    labels,
    thresholdValue: 0,
    lineColor: {
      positive: themes[themeType].solidGreen,
      negative: themes[themeType].solidRed,
      vertical: themes[themeType].textcolorBlack,
      horizontal: themes[themeType].textcolorWhite,
    },

    datasets: [
      {
        data: [...values],
        borderColor: [...borderColor],
        backgroundColor: [...borderColor],
        tension: 0,
        pointRadius: 0.1,
        pointHitRadius: 0.1,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 20,
        bottom: 45,
        left: 5,
        right: 5,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 4,
    legend: {
      display: false,
    },

    tooltips: {
      displayColors: false,
      enabled: true,
      mode: "x-axis",
      callbacks: {
        title: function (tooltipItems: any, data: any) {
          if (tooltipItems.length > 0) {
            const item = tooltipItems[0];
            const labels = item.xLabel ?? "";
            return moment(labels, "YYYYMMDD").format("DD/MM/YYYY");
          }
          return "";
        },
        label: function (tooltipItem: any) {
          return numeral(tooltipItem.yLabel).format("0,0");
        },
        bodyFontColor: function (tooltipItem: any) {
          const color =
            tooltipItem.yLabel >= 0 ? "red" : themes[themeType].solidRed;
          return color;
        },
      },
    },

    scales: {
      xAxes: [
        {
          precision: 0,
          display: true,
          ticks: {
            precision: 0,
            display: true,

            fontColor: "#ADB3C0",
            callback: function (value: string) {
              return moment(value, "YYYYMMDD").format("DD/MM/YYYY");
            },
          },
        },
      ],
      yAxes: [
        {
          precision: 0,
          display: true,
          id: "closedLine",
          ticks: {
            precision: 0,
            display: true,
            beginAtZero: true,
            fontColor: "#ADB3C0",
            callback: function (value: any) {
              return numeral(value).format("0,0");
            },
          },
        },
      ],
    },
    // scales: {
    //   xAxes: [
    //     {
    //       ticks: {
    //         display: true,
    //         maxTicksLimit: 20,
    //         maxRotation: 90,
    //         minRotation: 90,
    //         fontSize: 10,
    //         callback: function (value: string) {
    //           return moment(value, "YYYYMMDD").format("DD/MM/YYYY");
    //         },
    //       },
    //       gridLines: {
    //         display: false,
    //       },
    //     },
    //   ],
    //   yAxes: [
    //     {
    //       gridLines: {
    //         drawBorder: false,
    //         display: false,
    //       },
    //     },

    //     {
    //       id: "closedLine",
    //       ticks: {
    //         display: false,
    //         beginAtZero: true,
    //         fontColor: "#ADB3C0",
    //         callback: function (value: any) {
    //           return numeral(value).format("0,0");
    //         },
    //       },
    //     },
    //   ],

    // },
  } as unknown as ChartOptions;

  return <Line options={options} data={data} plugins={plugins} />;
};

export default ChartProfitLoss;
