import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetDBondOrderParams,
  IEditBidAskDBondOrderParams,
  IGetDBondPriceParams,
  IFindCbotDBondInfoDetailParams,
  IPlaceDBondOrderParams,
  IInquiryBuyingPowerParams,
  ICancelDBondOrderParams,
} from "core/http/apis/bid-ask-d-bond-order/types";
import {
  DBondOrder,
  IGetDBondOrderResponse,
  DBondPrice,
  IGetDBondPriceResponse,
  IFindCbotDBondInfoDetailResponse,
  IPlaceDBondOrderResponse,
  IInquiryBuyingPowerResponse,
  ICancelDBondOrderResponse,
} from "domain/models/BidAskDBondOrder";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  status: number;

  secCdDBond: string;
}

export interface ReduxData {
  filter: IFilter;
  list: DBondOrder[];
  loading: boolean;
  activeTab: string;
  listDBondPrice: DBondPrice[];
  bidAskDBondOrder?: IEditBidAskDBondOrderParams;

  statusPlaceDBondOrder: boolean;
  activeButton: string;
  statusDelete: boolean;
}

const initialState: ReduxData = {
  filter: {
    status: -1,

    secCdDBond: "",
  },
  list: [],
  loading: false,
  activeTab: "BID_ASK_D_BOND_ORDER",
  listDBondPrice: [],
  bidAskDBondOrder: {
    amount: 0,
    qty: 0,
    secCdDBond: "",

    maxPrice: 0,
    purchaseAbility: 0,

    fee: 0,

    totalAmount: 0,
    issuerBond: "",
    price: 0,
    releaseDate: 0,
    coupon: 0,
    expiratedDate2: 0,
    period: 0,
    description: "",
  },

  statusPlaceDBondOrder: false,
  activeButton: "2",
  statusDelete: false,
};

const bidAskDBondOrderSlice = createSlice({
  name: "bidAskDBondOrderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    getDBondPriceRequest: (
      state,
      action: PayloadAction<IGetDBondPriceParams>
    ) => {},
    getDBondPriceSuccess: (
      state,
      action: PayloadAction<IGetDBondPriceResponse>
    ) => {
      state.listDBondPrice = action.payload.data ?? [];
    },
    getDBondPriceFailure: (state) => {},
    getDBondOrderRequest: (
      state,
      action: PayloadAction<IGetDBondOrderParams>
    ) => {
      state.loading = true;
    },
    getDBondOrderSuccess: (
      state,
      action: PayloadAction<IGetDBondOrderResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    getDBondOrderFailure: (state) => {
      state.loading = false;
    },
    inquiryBuyingPowerRequest: (
      state,
      action: PayloadAction<IInquiryBuyingPowerParams>
    ) => {},
    inquiryBuyingPowerSuccess: (
      state,
      action: PayloadAction<IInquiryBuyingPowerResponse>
    ) => {
      if (state.bidAskDBondOrder?.amount) {
        state.bidAskDBondOrder = {
          ...state.bidAskDBondOrder,

          purchaseAbility: action.payload.data
            ? action.payload.data.buyingPower
            : 0,
          maxPrice: action.payload.data
            ? action.payload.data.buyingPower / state.bidAskDBondOrder?.amount
            : 0,
        };
      } else {
        state.bidAskDBondOrder = {
          ...state.bidAskDBondOrder,

          purchaseAbility: action.payload.data
            ? action.payload.data.buyingPower
            : 0,
        };
      }
    },
    inquiryBuyingPowerFailure: (state) => {},

    findCbotDBondInfoDetailRequest: (
      state,
      action: PayloadAction<IFindCbotDBondInfoDetailParams>
    ) => {},
    findCbotDBondInfoDetailSuccess: (
      state,
      action: PayloadAction<IFindCbotDBondInfoDetailResponse>
    ) => {
      state.bidAskDBondOrder = {
        ...state.bidAskDBondOrder,
        issuerBond:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].bondName
            ? action.payload.data[0].bondName
            : "",
        price:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].faceValPrice
            ? action.payload.data[0].faceValPrice
            : 0,
        releaseDate:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].reissueDate
            ? action.payload.data[0].reissueDate
            : 0,
        expiratedDate2:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].dueDate
            ? action.payload.data[0].dueDate
            : 0,
        coupon:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].interestRate
            ? action.payload.data[0].interestRate * 100
            : 0,
        period:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].periodPayInterest
            ? action.payload.data[0].periodPayInterest
            : 0,
        description:
          action.payload.data &&
          action.payload.data[0] &&
          action.payload.data[0].remarks
            ? action.payload.data[0].remarks
            : "",
      };
    },
    findCbotDBondInfoDetailFailure: (state) => {},

    placeDBondOrderRequest: (
      state,
      action: PayloadAction<IPlaceDBondOrderParams>
    ) => {
      state.statusPlaceDBondOrder = false;
    },
    placeDBondOrderSuccess: (
      state,
      action: PayloadAction<IPlaceDBondOrderResponse>
    ) => {
      state.statusPlaceDBondOrder = true;
    },
    placeDBondOrderFailure: (state) => {
      state.statusPlaceDBondOrder = false;
    },
    updateButtonActive: (state, action: PayloadAction<string>) => {
      state.activeButton = action.payload;
    },
    cancelDBondOrderRequest: (
      state,
      action: PayloadAction<ICancelDBondOrderParams>
    ) => {
      state.statusDelete = false;
    },
    cancelDBondOrderSuccess: (
      state,
      action: PayloadAction<ICancelDBondOrderResponse>
    ) => {
      state.statusDelete = true;
    },
    cancelDBondOrderFailure: (state) => {
      state.statusDelete = false;
    },
    updateItemEdit: (
      state,
      action: PayloadAction<IEditBidAskDBondOrderParams | undefined>
    ) => {
      if (!action.payload) {
        state.bidAskDBondOrder = undefined;
        return;
      }

      state.bidAskDBondOrder = {
        ...state.bidAskDBondOrder,
        ...action.payload,
      };
    },
    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  getDBondOrderRequest,
  getDBondOrderSuccess,
  getDBondOrderFailure,
  getDBondPriceRequest,
  getDBondPriceSuccess,
  getDBondPriceFailure,
  inquiryBuyingPowerRequest,
  inquiryBuyingPowerSuccess,
  inquiryBuyingPowerFailure,
  findCbotDBondInfoDetailRequest,
  findCbotDBondInfoDetailSuccess,
  findCbotDBondInfoDetailFailure,

  placeDBondOrderRequest,
  placeDBondOrderSuccess,
  placeDBondOrderFailure,
  cancelDBondOrderRequest,
  cancelDBondOrderSuccess,
  cancelDBondOrderFailure,
  updateItemEdit,
  updateFilter,
  updateButtonActive,
} = bidAskDBondOrderSlice.actions;

export default bidAskDBondOrderSlice.reducer;
