import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .chart {
    position: relative;
    flex: 1 1 0;
    overflow: hidden;
  }
`;
