import { FC } from "react";

import CreateContractContent from "./components/CreateContractContent/index";

import { Container } from "./styles";

const CreateContract: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <CreateContractContent />
    </Container>
  );
};

export default CreateContract;
