import { toast } from "components/commons/Toast";
import { getListDeposit } from "core/http/apis/deposit-list";
import { IGetListDeposit } from "core/http/apis/deposit-list/type";
import { IDepositList } from "domain/models/DepositList";

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getListDepositFailure,
  getListDepositRequest,
  getListDepositSuccess,
  searchDepositFailure,
  searchDepositRequest,
  searchDepositSuccess,
} from "./redux";

function* getListDepositWorker(
  action: ReturnType<typeof getListDepositRequest>
) {
  try {
    const params: IGetListDeposit = action.payload;

    const response: IDepositList = yield call(getListDeposit, params);

    if (response?.statusCode === 0) {
      yield put(getListDepositSuccess(response));
    } else {
      yield put(getListDepositFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getListDepositFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getListDepositWatcher() {
  yield takeLatest(getListDepositRequest.type, getListDepositWorker);
}
function* searchDepositWorker(action: ReturnType<typeof searchDepositRequest>) {
  try {
    const params: IGetListDeposit = action.payload;

    const response: IDepositList = yield call(getListDeposit, params);

    if (response?.statusCode === 0) {
      yield put(searchDepositSuccess(response));
    } else {
      yield put(searchDepositFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchDepositFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchDepositWatcher() {
  yield takeLatest(searchDepositRequest.type, searchDepositWorker);
}

export default function* getListDepositSaga() {
  yield all([getListDepositWatcher(), searchDepositWatcher()]);
}
