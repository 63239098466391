import React, { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import { CellProps } from "react-table";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, TableContainer, TextTradeType } from "./style";
import SelectTable from "components/commons/SelectTable";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentAccount } from "modules/auth/redux";
import {
  onBuySellType,
  onUpdateVolumn,
  onFilter,
  handleUsingPriceSelected,
} from "modules/stock-order/redux/placeOrder";
import { onChangeCurrentTicker } from "modules/stock-order/redux";
import {
  addStockOrderWidgetName,
  changeSelectedPendingRows,
} from "modules/order-book/redux/index";
import { IOrderBookPendingItem } from "domain/models/OrderBook";
import {
  TableCellContent,
  TableHeaderCellContent,
} from "components/commons/CommonTable/styles";
import { Account } from "domain/models/User";
import moment from "moment";
import { enableInfinitySelector } from "modules/order-book/selectors";
import { GetAccoList } from "helper/sessionData";
import { Channel, EntrustBusinessCode, GetAccountType } from "helper/consts";
import ModalDetailOrderPending from "./modal/ModalDetailOrderPending";

interface WidgetContentProps {
  data: IOrderBookPendingItem[];
  fetchMoreData: () => void;
  heightFooter: number;
}

export const getOrderPendingStatus = (status: string) => {
  switch (status) {
    case "P":
      return "orderBook.tablePending.status.waitApprove";
    case "A":
      return "orderBook.tablePending.status.approved";
    case "X":
      return "orderBook.tablePending.status.cancelled";
    case "D":
      return "orderBook.tablePending.status.ctckCancel";
    default:
      return "orderBook.tablePending.status.noValue";
  }
};

function OrderBookPendingTable({
  data,
  fetchMoreData,
  heightFooter,
}: WidgetContentProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const enableInfinity = useSelector(enableInfinitySelector);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const [selectedRows, setSelectedRows] = useState<IOrderBookPendingItem[]>([]);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState<IOrderBookPendingItem>();

  useEffect(() => {
    if (selectedRows) {
      const selectedRowsNew: IOrderBookPendingItem[] = selectedRows.filter(
        (item) => item.visibleCheckbox === true
      );
      dispatch(changeSelectedPendingRows(selectedRowsNew));
    }
  }, [selectedRows]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tablePending.secCd" />
          </TableHeaderCellContent>
        ),
        accessor: "secCd",
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        minWidth: 65,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tablePending.subAccoNo" />
          </TableHeaderCellContent>
        ),
        accessor: "subAccoNo",
        minWidth: 65,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookPendingItem>) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.tablePending.tradeType" />
          </TableHeaderCellContent>
        ),
        accessor: "tradeType",
        minWidth: 75,
        Cell: (props: CellProps<IOrderBookPendingItem>) => {
          const tradeTypeDisplay =
            props.value === 2 ? "orderBook.BUY" : "orderBook.SELL";
          return (
            <TableCellContent align={"center"}>
              <TextTradeType side={tradeTypeDisplay}>
                {tradeTypeDisplay &&
                  intl.formatMessage({ id: tradeTypeDisplay })}
              </TextTradeType>
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tablePending.status.title" />
          </TableHeaderCellContent>
        ),
        accessor: "status",
        minWidth: 85,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              <FormattedMessage id={getOrderPendingStatus(props.value)} />
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tablePending.ordQty" />
          </TableHeaderCellContent>
        ),
        accessor: "ordQty",
        minWidth: 75,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: any) => {
          const formatterValue = numeral(props.value).format("0,0");
          return (
            <TableCellContent align={"flex-end"}>
              {formatterValue}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-end"}>
            <FormattedMessage id="orderBook.tablePending.ordPrice" />
          </TableHeaderCellContent>
        ),
        accessor: "ordPrice",
        minWidth: 75,
        tooltip: intl.formatMessage({ id: "orderBook.table.tooltipTable" }),
        Cell: (props: CellProps<IOrderBookPendingItem>) => {
          const price = props?.row?.original?.ordPrice;
          const ordType = props?.row?.original?.ordType;
          return (
            <TableCellContent align={"flex-end"}>
              {ordType === "LO" ? numeral(price).format("0,0.00") : ordType}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"center"}>
            <FormattedMessage id="orderBook.tablePending.regDateTime" />
          </TableHeaderCellContent>
        ),
        accessor: "regDateTime",
        minWidth: 140,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"center"}>
              {props.value
                ? moment(props.value).format("DD/MM/YYYY HH:mm:ss")
                : "---"}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <TableHeaderCellContent align={"flex-start"}>
            <FormattedMessage id="orderBook.tablePending.channel" />
          </TableHeaderCellContent>
        ),
        accessor: "ordChanel",
        minWidth: 65,
        Cell: (props: any) => {
          return (
            <TableCellContent align={"flex-start"}>
              {props.value
                ? Channel[props.value] || props.value
                : intl.formatMessage({ id: "orderBookDer.noValue" })}
            </TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: CellProps<IOrderBookPendingItem>) => {
          return (
            <div className="btn_action_hover">
              <Button
                side={props?.row?.original?.tradeType === 2 ? "buy" : "sell"}
                onClick={() => onBuySell(props?.row?.original, listAcc)}
                onDoubleClick={(e) => e.stopPropagation()}
              >
                <FormattedMessage
                  id={
                    props?.row?.original?.tradeType === 2
                      ? "common.buyPlus"
                      : "common.sellPlus"
                  }
                />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const onBuySell = (data: IOrderBookPendingItem, listing: Account[]) => {
    // Dispatch mã ck
    const symbol = data?.secCd as string;
    dispatch(onChangeCurrentTicker(symbol));
    // Dispatch account
    const accountChange = listing?.find(
      (item) => item.subAccoNo === data?.subAccoNo
    );
    dispatch(changeCurrentAccount(accountChange!));
    // Dispatch switch button mua/bán
    const buySell = data?.tradeType === 2 ? "buy" : "sell";
    dispatch(onBuySellType(buySell));

    // Dispatch Giá
    dispatch(handleUsingPriceSelected(true));
    // const price = numeral(data?.ordPrice).value();
    const price = Math.round((data?.ordPrice + Number.EPSILON) * 100) / 100;
    dispatch(onFilter({ price }));

    // Dispatch khối lượng
    const volumn = numeral(data?.ordQty).format("0,0");
    dispatch(onUpdateVolumn({ volumn }));

    dispatch(addStockOrderWidgetName("widgets.stockOrder.name"));
  };

  return (
    <TableContainer
      heighFooter={heightFooter}
      id="table-infinity-order-book-pending"
    >
      <SelectTable
        columns={columns}
        data={data}
        setShowModal={setShowModalDetail}
        setDataRow={setDataRow}
        setSelectedRows={setSelectedRows}
        fetchMoreData={fetchMoreData}
        enableInfinity={enableInfinity}
        scrollableTarget="table-infinity-order-book-pending"
      />
      <ModalDetailOrderPending
        showModal={showModalDetail}
        setShowModal={setShowModalDetail}
        dataRow={dataRow}
      />
    </TableContainer>
  );
}

export default React.memo(OrderBookPendingTable);
