/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";

import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateModalDerStatusRequest } from "modules/order-history/redux";
import {
  isShowConfirmModalDerSelector,
  listOrderDetailNormalDerModalSelector,
} from "modules/order-history/selectors";

import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { OrderHistoryDer } from "domain/models/OrderHistory";
import {
  BodyModal,
  BodyModalRow,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
  Container,
  HeaderCell,
  TableCellContent,
} from "./styles";

// import { convertNumberToDateFormat } from "helper/utils";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
interface Props {
  itemDetailRow: OrderHistoryDer;
}

function DetailModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowConfirmModalDerSelector);
  const themeType = useSelector(themeTypeSelector);
  const data = useSelector(listOrderDetailNormalDerModalSelector);
  const convertNumberToDateFormatDDMMYYHHMMSS = ({
    date,
    inputFormat = "YYYY-MM-DD HH:mm:ss",
    outputFormat = "DD/MM/YYYY HH:mm:ss",
  }: {
    date?: number;
    inputFormat?: string;
    outputFormat?: string;
  }) => {
    if (!date) return "";

    return moment(new Date(date), inputFormat).format(outputFormat);
  };
  // const { orderType } = useSelector(filterOrderHistorySelector);
  const closeModalHandler = () => {
    dispatch(updateModalDerStatusRequest({ isVisible: false }));
  };
  const fetchMoreData = () => {};
  const center = "center";
  function getColor2(value: string, value2: number) {
    if (value2 === 1 || value2 === 6 || value2 === 7 || value2 === 8) {
      return <TextCell color={themes[themeType].red50}>{value}</TextCell>;
    } else if (value2 === 4 || value2 === 5) {
      return <TextCell color={themes[themeType].green50}>{value}</TextCell>;
    } else if (value2 === 2 || value2 === 3) {
      return <TextCell color={themes[themeType].yellow50}>{value}</TextCell>;
    }

    return value;
  }
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.volume" />
          </HeaderCell>
        ),
        accessor: "ordQty",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.orderPriceFilled" />
          </HeaderCell>
        ),
        accessor: "ordPrice",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,000.00")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="orderHistory.table.time" />
          </HeaderCell>
        ),
        accessor: "confirmTime",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormatDDMMYYHHMMSS({ date: props.value })}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );
  const getDisplay = (status: number) => {
    switch (status) {
      case 1:
        return "Back";
      case 2:
        return "Web";
      case 3:
        return "Front";
      case 4:
        return "Webmobile";
      case 5:
        return "Mobile";
      case 6:
        return "Home";
      case 7:
        return "Floor";
      case 8:
        return "SMS";
      case 9:
        return "Tel";
    }
  };
  const getDisplay4 = (status: number) => {
    switch (status) {
      case 1:
        return "rejected";
      case 2:
        return "entered";
      case 3:
        return "waitApprove";
      case 4:
        return "partialMatch";
      case 5:
        return "fullMatch";
      case 6:
        return "partialCancel";
      case 7:
        return "cancel";
      case 8:
        return "rejectedGkck";
    }
  };
  const getDisplay8 = (status: number) => {
    switch (status) {
      case 1:
        return "short";
      case 2:
        return "long";
    }
  };
  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle
            color={
              itemDetailRow.tradeType === 1
                ? `${themes[themeType].red50}`
                : itemDetailRow.tradeType === 2
                ? `${themes[themeType].green50}`
                : "inherit"
            }
          >
            {intl.formatMessage({
              id:
                "orderHistory.tradeTypeDer." +
                getDisplay8(itemDetailRow.tradeType ?? 0),
            })}{" "}
            {itemDetailRow.secCd}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow className="mt-2">
            <LabelCell>
              <FormattedMessage id="orderHistory.subAccount" />
            </LabelCell>
            <TextCell>{itemDetailRow?.custNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.status" />
            </LabelCell>

            {itemDetailRow && itemDetailRow?.extStatus
              ? getColor2(
                  intl.formatMessage({
                    id:
                      "orderHistory.extStatus." +
                      getDisplay4(itemDetailRow.extStatus ?? 0),
                  }),
                  itemDetailRow.extStatus
                )
              : ""}
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.orderVolume" />
            </LabelCell>
            <TextCell>
              {numeral(itemDetailRow?.ordQty ?? 0).format("0,0")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.orderPrice" />
            </LabelCell>
            <TextCell>
              {" "}
              {numeral(itemDetailRow?.ordPrice ?? 0).format("0.00")}
            </TextCell>
          </BodyModalRow>
          {itemDetailRow.tradeType === 1 ? (
            <>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.orderVolumeFilled" />
                </LabelCell>

                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.matQty ?? 0).format("0,0")}
                </TextCell>
              </BodyModalRow>
              <BodyModalRow>
                <LabelCell>
                  <FormattedMessage id="orderHistory.table.matchAvgPrice" />
                </LabelCell>
                <TextCell>
                  {" "}
                  {numeral(itemDetailRow?.avgPrice ?? 0).format("0,000.00")}
                </TextCell>
              </BodyModalRow>
            </>
          ) : (
            <BodyModalRow>
              <LabelCell>
                <FormattedMessage id="orderHistory.table.orderPriceFilled" />
              </LabelCell>
              <TextCell>
                {" "}
                {numeral(itemDetailRow?.avgPrice ?? 0).format("0,0")}
              </TextCell>
            </BodyModalRow>
          )}

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.regDateTime" />
            </LabelCell>
            <TextCell>
              {moment(itemDetailRow?.regDateTime ?? 0).format("HH:mm:ss")}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="orderHistory.table.channel" />
            </LabelCell>
            <TextCell>
              {intl.formatMessage({
                id:
                  "orderHistory.ordChannel." +
                  getDisplay(itemDetailRow?.ordChanel ?? 0),
              })}
            </TextCell>
          </BodyModalRow>
          <Container id="orderHistoryModalTable">
            <CommonTableInfinity
              fetchMoreData={fetchMoreData}
              columns={columns}
              data={data ? data : []}
              enableInfinity={true}
              scrollableTarget={"orderHistoryModalTable"}
              isHasFooter={false}
            />
          </Container>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailModal;
