import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const bidAskDBondOrderSelector = (state: RootState) =>
  state.bidAskDBondOrder;

export const filterDBondOrderSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.filter
);
export const listDBondOrderSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.list
);

export const loadingSearchDataSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.loading
);

export const activeTabSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.activeTab
);
export const listDBondPriceSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.listDBondPrice
);

export const bidAskDBondOrderEditSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.bidAskDBondOrder
);

export const statusPlaceDBondOrderSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.statusPlaceDBondOrder
);
export const activeButtonSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.activeButton
);
export const statusDeleteSelector = createSelector(
  bidAskDBondOrderSelector,
  (state) => state.statusDelete
);
