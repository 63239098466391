import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { listMarginContractSelector } from "modules/margin-contract/selectors";
import { HeaderCell, TableCellContent } from "./styles";
import moment from "moment";
import numeral from "numeral";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { Container } from "./styles";

function getMarginContractStatus(value: string) {
  if (value === "0")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.status.notApprove" />
      </TableCellContent>
    );
  if (value === "1")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.status.approved" />
      </TableCellContent>
    );
  if (value === "2")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.status.approveWaitEdit" />
      </TableCellContent>
    );
  if (value === "3")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.status.edited" />
      </TableCellContent>
    );
  if (value === "9")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.status.closed" />
      </TableCellContent>
    );
  return value;
}

function getContractState(value: string) {
  if (value === "0")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.contractState.notActive" />
      </TableCellContent>
    );
  if (value === "1")
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="marginContract.table.contractState.actived" />
      </TableCellContent>
    );
  return value;
}

function Table() {
  const data = useSelector(listMarginContractSelector);
  const flexStart = "flex-start";
  const flexEnd = "flex-end";
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginContract.table.alloDate" />
          </HeaderCell>
        ),
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.refNo" />
          </HeaderCell>
        ),
        accessor: "refNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginContract.table.contrNo" />
          </HeaderCell>
        ),
        accessor: "contrNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginContract.table.subAccoNo" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.custName" />
          </HeaderCell>
        ),
        accessor: "custName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.marginGroupCode" />
          </HeaderCell>
        ),
        accessor: "marginGroupCode",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.retentionRate" />
          </HeaderCell>
        ),
        accessor: "retentionRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00000")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.warningRate" />
          </HeaderCell>
        ),
        accessor: "warningRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00000")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.callRate" />
          </HeaderCell>
        ),
        accessor: "callRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00000")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.loanDays" />
          </HeaderCell>
        ),
        accessor: "loanDays",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.freeDays" />
          </HeaderCell>
        ),
        accessor: "freeDays",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.warningDays" />
          </HeaderCell>
        ),
        accessor: "warningDays",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginContract.table.tnType" />
          </HeaderCell>
        ),
        accessor: "tnType",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.sourceLoan" />
          </HeaderCell>
        ),
        accessor: "sourceLoan",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.brokerCd" />
          </HeaderCell>
        ),
        accessor: "brokerCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginContract.table.contrDateFrom" />
          </HeaderCell>
        ),
        accessor: "contrDateFrom",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.status.title" />
          </HeaderCell>
        ),
        accessor: "status",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {getMarginContractStatus(props.value.toString())}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.contractState.title" />
          </HeaderCell>
        ),
        accessor: "contractState",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {getContractState(props.value.toString())}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.transactionCd" />
          </HeaderCell>
        ),
        accessor: "transactionCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.remarks" />
          </HeaderCell>
        ),
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginContract.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "regDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value ? moment(props.value).format("DD/MM/YYYY") : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.regUserId" />
          </HeaderCell>
        ),
        accessor: "regUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginContract.table.updDateTime" />
          </HeaderCell>
        ),
        accessor: "updDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value ? moment(props.value).format("DD/MM/YYYY") : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginContract.table.updUserId" />
          </HeaderCell>
        ),
        accessor: "updUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="marginContractContainer">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"marginContractContainer"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
