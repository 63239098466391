import { get } from "react-hook-form";
import { TextColor } from "./style";
import themes from "themes/abstracts/_themes";
import { useSelector } from "react-redux";
import { themeTypeSelector } from "themes/redux";

interface Props {
  color: string;
  size: string;
  background: string;
  value: string;
  className?: string;
}
function Value({ color, size, background, value, className }: Props) {
  const themeType = useSelector(themeTypeSelector);
  return (
    <TextColor
      className={className}
      size={size}
      style={{
        color: get(themes[themeType], color),
        backgroundColor: get(themes[themeType], background),
      }}
    >
      {value}
    </TextColor>
  );
}

export default Value;
