import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";
import { STOCK_COLOR_CODEMap } from "domain/protoNew/auto_trading_pb";
import { getColorTicker } from "helper/utils";

export const TextColor = styled.div<{
  textcolor: STOCK_COLOR_CODEMap[keyof STOCK_COLOR_CODEMap];
}>`
  .ticker-code {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    overflow: hidden;
    span {
      ${({ textcolor, theme }) => getColorTicker(textcolor, theme)};
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
    }
  }
`;

export const Wrapper = styled.div<{
  isLongTicker?: boolean;
}>`
  cursor: pointer;
  display: flex;
  padding-right: 4px;
  position: relative;

  .delete-icon-container {
    display: none;
  }
  :hover .delete-icon-container {
    display: flex;
  }

  .ticker-name {
    flex: 1 1 0;
  }

  .delete-icon-container {
    width: 16px;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ticker-code {
    & > span {
      ${({ theme, isLongTicker }) =>
        isLongTicker &&
        `
        font-size: ${theme.typeFaces.caption10.size};
      `}
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: ${({ theme }) => theme.brandPrimary};
    border-radius: 4px;
    padding: 8px 12px;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};

    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandPrimary};
    }
  }
`;
