import { IntlShape } from "react-intl";

export const tradeOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "advertisingOrder.tradeTypeOptions.value0",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "advertisingOrder.tradeTypeOptions.value1",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "advertisingOrder.tradeTypeOptions.value2",
    }),
  },
];
