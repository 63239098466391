import SmsOTPNewCreate from "components/commons/SmsOTPNewCreate";
import { createSMSOTPApi } from "core/http/apis/manager-device";
import { ICreateOtpSms } from "core/http/apis/manager-device/type";
import storage from "helper/storage";
import { accountInfoSelector } from "modules/auth/selectors";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export interface IVerifyResponse {
  businessCd: string;
  otpType: number;
  otp: string;
}
interface IProps {
  handleClose: any;
  getResult: (response: IVerifyResponse) => void;
  businessCd: string;
  dataDelete: any;
}

const OTPByBusinessCd = ({
  handleClose,
  getResult,
  businessCd,
  dataDelete,
}: IProps) => {
  const masterAccount = storage.getMasterAccount();

  const accountInfo = useSelector(accountInfoSelector);

  const intl = useIntl();

  const otpType = 0;
  const [errorOtp, setErrorOtp] = useState("");

  const handleCreateSmsOTP = async () => {
    try {
      const data: ICreateOtpSms = {
        userId: masterAccount,
        deviceId: dataDelete?.deviceId,
        businessCd: businessCd,
        mobileNo: accountInfo?.currentCustomer?.mobileNo || "",
        emailAddr: accountInfo?.currentCustomer?.emailAdrs || "",
        sendType: 0,
        channel: 2,
      };
      await createSMSOTPApi(data);
    } catch (error: any) {
      setErrorOtp(
        intl.formatMessage({
          id: error?.code,
          defaultMessage: error?.description,
        })
      );
    }
  };

  const verifyOtp = async (code: string) => {
    getResult({
      businessCd: businessCd,
      otpType: otpType,
      otp: code,
    });
    // handleClose();
  };

  return (
    <SmsOTPNewCreate
      handleModal={handleClose}
      changeMasterAccount={() => {}}
      handleCreateSmsOTP={handleCreateSmsOTP}
      submitOTP={verifyOtp}
      errorOTP={errorOtp}
      setErrorOtp={setErrorOtp}
    />
  );
};
export default OTPByBusinessCd;
