import CommonTableInfinity from "components/commons/CommonTableInfinity";

import {
  listBondOrderSelector,
  filterBondOrderSelector,
  statusDeleteSelector,
} from "modules/bid-ask-bond-order/selectors";
import { IGetBondOrderParams } from "core/http/apis/bid-ask-bond-order/types";
import {
  getBondOrderRequest,
  cancelBondOrderRequest,
  updateFilter,
} from "modules/bid-ask-bond-order/redux";

import { memo, useMemo, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Container, HeaderCell, TableCellContent, IconWrapper } from "./styles";
import numeral from "numeral";
import { convertNumberToDateFormat } from "helper/utils";
import moment from "moment";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { useSelector, useDispatch } from "react-redux";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";

import { toast } from "components/commons/Toast";

import { CboBondOrder } from "domain/models/BidAskBondOrder";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listBondOrderSelector);
  const statusDelete = useSelector(statusDeleteSelector);
  const { secCdBond } = useSelector(filterBondOrderSelector);
  const themeType = useSelector(themeTypeSelector);
  useEffect(() => {
    if (statusDelete) {
      const submitData: IGetBondOrderParams = {
        status: -1,

        bondCd: secCdBond ? secCdBond : "",
        custCd: 0,
        custNo: null,
        fromDate: null,
        toDate: null,
        type: 2,
        tradeType: null,
        getDataType: 1,
      };
      dispatch(getBondOrderRequest(submitData));
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
      toast("bidAskBondOrder.message.deleteSuccess", "success");
    }
  }, [statusDelete]);
  const center = "center";
  const flexEnd = "flex-end";

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [bondOrderSelected, setBondOrderSelected] = useState<
    CboBondOrder | undefined
  >(undefined);

  const clickDeleteHandler = (item: CboBondOrder) => {
    setBondOrderSelected(item);
    setVisibleModalConfirm(true);
  };
  const setConfirm = () => {
    if (bondOrderSelected) {
      dispatch(
        cancelBondOrderRequest({
          id: bondOrderSelected.id ?? 0,

          updDateTime: bondOrderSelected.timeStamp ?? "",
        })
      );
    }
  };
  const convertNumberToDateFormatDDMMYYHHMMSS = ({
    date,
    inputFormat = "YYYY-MM-DD HH:mm:ss",
    outputFormat = "DD/MM/YYYY HH:mm:ss",
  }: {
    date?: number;
    inputFormat?: string;
    outputFormat?: string;
  }) => {
    if (!date) return "";

    return moment(new Date(date), inputFormat).format(outputFormat);
  };
  function changeColor(value: string, value2: number) {
    if (value2 === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].red50}>
          <FormattedMessage id="bidAskBondOrder.table.sell" />
        </TableCellContent>
      );
    else if (value2 === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].green50}>
          <FormattedMessage id="bidAskBondOrder.table.buy" />
        </TableCellContent>
      );

    return "";
  }
  function changeColor2(value: number, value2: string) {
    if (value === 0)
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="bidAskBondOrder.status.wait_approve" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskBondOrder.status.confirmed" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskBondOrder.status.complete" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 9)
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="bidAskBondOrder.status.rejected" />
          {/* {value2} */}
        </TableCellContent>
      );

    return "";
  }
  const getDisplay = (status: number) => {
    switch (status) {
      case 0:
        return "unknown";
      case 1:
        return "sell";

      case 2:
        return "buy";
      case 3:
        return "sellMortage";
    }
  };
  const getDisplay2 = (status: number) => {
    switch (status) {
      case 1:
        return "sell";

      case 2:
        return "buy";
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.cancel" />
          </HeaderCell>
        ),
        minWidth: 50,
        accessor: "id",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={center}>
              {currentRow.status === 0 && (
                <IconWrapper onClick={() => clickDeleteHandler(currentRow)}>
                  <IconTrash />
                </IconWrapper>
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.tradingDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.account" />
          </HeaderCell>
        ),
        accessor: "custNo",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.contraAccount" />
          </HeaderCell>
        ),
        accessor: "custNoTo",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 120,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.tradeType" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 50,
        Cell: (props: any) => {
          // const row = props?.row.original;

          return (
            <TableCellContent align={center}>
              {changeColor(
                intl.formatMessage({
                  id:
                    "bidAskBondOrder.table.traderList." +
                    getDisplay(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.qty" />
          </HeaderCell>
        ),
        accessor: "qty",
        minWidth: 50,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.amount" />
          </HeaderCell>
        ),
        accessor: "price",
        minWidth: 50,

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.orderPrice" />
          </HeaderCell>
        ),
        accessor: "c",
        minWidth: 50,

        Cell: (props: any) => {
          const row = props?.row?.original;
          const orderPrice =
            (row && row.price && row.qty && row?.price * row?.qty) ?? 0;
          return (
            <TableCellContent align={flexEnd}>
              {numeral(orderPrice).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.expiredDate" />
          </HeaderCell>
        ),
        accessor: "expiredDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.fee" />
          </HeaderCell>
        ),
        accessor: "feeAmt",
        minWidth: 50,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.feePayer" />
          </HeaderCell>
        ),
        accessor: "feePayer",
        minWidth: 60,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id:
                  "bidAskBondOrder.table.payer." +
                  getDisplay2(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskBondOrder.table.tax" />
          </HeaderCell>
        ),
        accessor: "taxAmt",
        minWidth: 50,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.taxPayer" />
          </HeaderCell>
        ),
        accessor: "taxPayer",
        minWidth: 60,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {intl.formatMessage({
                id:
                  "bidAskBondOrder.table.payer." +
                  getDisplay2(+props.value ?? 0),
              })}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,
        Cell: (props: any) => {
          const row = props?.row.original;
          return (
            <TableCellContent align={center}>
              {changeColor2(props.value, row.statusDisp)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.regUserId" />
          </HeaderCell>
        ),
        accessor: "regUserId",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskBondOrder.table.alloDate" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "regDateTime",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {" "}
              {convertNumberToDateFormatDDMMYYHHMMSS({ date: props.value })}
            </TableCellContent>
          );
        },
        // Footer: "",
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="bidAskBondOrder">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"bidAskBondOrder"}
        isHasFooter={false}
      />
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage(
          { id: "bidAskBondOrder.message.deleteContent" },
          {
            number: bondOrderSelected?.id,
          }
        )}
      />
    </Container>
  );
}

export default memo(RequestTable);
