import styled from "styled-components/macro";
import { Toast as BootstrapToast } from "react-bootstrap";
import { ToastType } from "modules/system/redux/toast";

const ColorType: { [key in ToastType]: string } = {
  info: "#3391FF",
  success: "#00B98D",
  error: "#DD3640",
  warning: "#E7BC26",
  default: "#253147",
};

export const Container = styled.div`
  position: relative;
`;

export const PositionHolder = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1200;
`;

export const StyledToast = styled(BootstrapToast)<{
  type: ToastType;
}>`
  background-color: ${({ type }) => ColorType[type]};
  min-width: 320px;
`;

export const StyledToastHeader = styled(BootstrapToast.Header)<{
  type: ToastType;
}>`
  background-color: ${({ type }) => ColorType[type]};
  button > span {
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: 20px;
  }
`;

export const StyledToastBody = styled(BootstrapToast.Body)`
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
`;
