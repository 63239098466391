import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { memo, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonLiquidation,
  ColorGreen,
  ColorRed,
  Container,
  HeaderCell,
  TableCellContent,
} from "./styles";

import { ModalConfirm } from "components/commons";
import { convertNumberToDateFormat } from "helper/utils";
import { getListContract } from "modules/add-framework-constract-form/selectors";
import { liquidation } from "core/http/apis/add-framework-constract-form";
import { toast } from "components/commons/Toast";
import { getContractRequest } from "modules/add-framework-constract-form/redux";
import moment from "moment";
import storage from "helper/storage";

function Table() {
  const data = useSelector(getListContract);

  const accountNo = storage.getMasterAccount();
  const intl = useIntl();
  const dispatch = useDispatch();
  const d = new Date();
  const fromDate = new Date(d.getFullYear(), d.getMonth(), 1);
  const toDate = new Date();

  const flexStart = "flex-start";
  const center = "center";
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [dataLiquidation, setDataLiquidation] = useState<any>({});

  const handleLiquidation = (item: any) => {
    setDataLiquidation(item);
    setVisibleModalConfirm(true);
  };

  const setConfirm = async () => {
    const res = await liquidation([
      {
        branchCd: dataLiquidation?.branchCd ? dataLiquidation?.branchCd : "",
        transactionCd: dataLiquidation?.transactionCd
          ? dataLiquidation?.transactionCd
          : "",
        custNo: dataLiquidation?.custNo ? dataLiquidation?.custNo : "",
        custName: dataLiquidation?.custName ? dataLiquidation?.custName : "",
        contractNo: dataLiquidation?.contractNo
          ? dataLiquidation?.contractNo
          : "",
        contractDate: dataLiquidation?.contractDate
          ? dataLiquidation?.contractDate
          : "",
        closeDate: dataLiquidation?.closeDate ? dataLiquidation?.closeDate : "",
        contractSts: dataLiquidation?.contractSts
          ? dataLiquidation?.contractSts
          : "",
        srcChannel: dataLiquidation?.srcChannel
          ? dataLiquidation?.srcChannel
          : "",
        status: dataLiquidation?.status ? dataLiquidation?.status : "",
        id: dataLiquidation?.id ? dataLiquidation?.id : "",
        contractStsVal: dataLiquidation?.contractStsVal
          ? dataLiquidation?.contractStsVal
          : "",
        statusVal: dataLiquidation?.statusVal ? dataLiquidation?.statusVal : "",
        remarks: dataLiquidation?.remarks ? dataLiquidation?.remarks : "",
        regDateTime: dataLiquidation?.regDateTime
          ? dataLiquidation?.regDateTime
          : "",
        regUserId: dataLiquidation?.regUserId ? dataLiquidation?.regUserId : "",
        updDateTime: dataLiquidation?.updDateTime
          ? dataLiquidation?.updDateTime
          : "",
        updUserId: dataLiquidation?.updUserId ? dataLiquidation?.updUserId : "",
      },
    ]);

    if (res?.statusCode === 0) {
      toast(
        `${intl.formatMessage({
          id: "addFrameworkContractForm.listContract.liquidateSuccess",
        })}`,
        "success"
      );
      dispatch(
        getContractRequest({
          branchCd: "",
          transactionCd: "",
          custNo: accountNo,
          contractDateFrom: moment(fromDate).format("YYYYMMDD"),
          contractDateTo: moment(toDate).format("YYYYMMDD"),
          closeDateFrom: "",
          closeDateTo: "",
          contractSts: "",
          srcChannel: "",
        })
      );
    } else {
      toast(
        `${intl.formatMessage({
          id: "addFrameworkContractForm.listContract.liquidateFailure",
        })}`,
        "error"
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="addFrameworkContractForm.listContract.account" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "custNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="addFrameworkContractForm.listContract.contractNo" />
          </HeaderCell>
        ),
        minWidth: 160,
        accessor: "contractNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="addFrameworkContractForm.listContract.dateOpenContract" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "contractDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props?.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="addFrameworkContractForm.listContract.dateCloseContract" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "closeDate",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props?.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="addFrameworkContractForm.listContract.statusContract" />
          </HeaderCell>
        ),
        minWidth: 120,
        accessor: "contractSts",
        Cell: (props: any) => {
          const contractStsVal = props?.row?.original?.contractStsVal;

          return (
            <TableCellContent align={flexStart}>
              {contractStsVal === "1" ? (
                <ColorGreen>
                  <FormattedMessage id="addFrameworkContractForm.listContract.valueInEffect" />
                </ColorGreen>
              ) : (
                <ColorRed>
                  <FormattedMessage id="addFrameworkContractForm.listContract.valueliquidated" />
                </ColorRed>
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="addFrameworkContractForm.listContract.remark" />
          </HeaderCell>
        ),
        minWidth: 505,
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="addFrameworkContractForm.listContract.transactionCd" />
          </HeaderCell>
        ),
        minWidth: 220,
        accessor: "transactionCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        className: "action-container",
        Cell: (props: any) => {
          const rowItem = props?.row?.original;
          const contractStsVal = props?.row?.original?.contractStsVal;
          const statusVal = props?.row?.original?.statusVal;

          return (
            <div className="btn_action_hover">
              {contractStsVal === "1" && statusVal === "1" && (
                <ButtonLiquidation onClick={() => handleLiquidation(rowItem)}>
                  <FormattedMessage id="addFrameworkContractForm.listContract.liquidation" />
                </ButtonLiquidation>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="addFrameworkContractFormTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ? data : []}
        enableInfinity={true}
        scrollableTarget={"addFrameworkContractFormTable"}
        isHasFooter={false}
      />

      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({
          id: "addFrameworkContractForm.createContract.titleNoti",
        })}
        content={intl.formatMessage({
          id: "addFrameworkContractForm.listContract.notiLiquidation",
        })}
      />
    </Container>
  );
}

export default memo(Table);
