import { Button, InputAutocompelete, CheckBox } from "components/commons";

import classNames from "classnames";
import {
  updateItemEdit,
  findBrokerListRequest,
  createContractRequest,
  searchContractRequest,
} from "modules/create-contract/redux";
import { updateModalOtpRequest } from "modules/advance/redux";
import { ISearchContractParams } from "core/http/apis/create-contract/types";
import { accountInfoSelector } from "modules/auth/selectors";
import {
  createContractEditSelector,
  brokerListContractSelector,
  statusCreateContractSelector,
  filterCreateContractSelector,
} from "modules/create-contract/selectors";
import { isShowOtpModalSelector } from "modules/advance/selectors";

import moment from "moment";
import storage from "helper/storage";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailWrapper,
  DetailRowTextTop,
  DetailRowPaddingTB,
  DetailRowButton,
  CheckBoxRow,
  CheckBoxText,
  DetailColFlexEnd,
  Link,
} from "./styles";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { Row, Col } from "react-bootstrap";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import TextArea from "components/commons/Input/textArea";
import { toast } from "components/commons/Toast";

const contractStsOption = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "createContract.filter.status.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "createContract.filter.status.available",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "createContract.filter.status.liquidated",
    }),
  },
];

function RequestDetail() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { currentUser, tradeDate } = useSelector(accountInfoSelector);
  const itemEdit = useSelector(createContractEditSelector);
  const brokerList = useSelector(brokerListContractSelector);
  const themeType = useSelector(themeTypeSelector);
  const [checked, setChecked] = useState(false);
  const statusCreateContract = useSelector(statusCreateContractSelector);
  const { contractSts, fromDate, toDate } = useSelector(
    filterCreateContractSelector
  );
  const isVisible = useSelector(isShowOtpModalSelector);

  const contractStsOptions = useMemo(() => contractStsOption(intl), [intl]);

  useEffect(() => {
    if (statusCreateContract) {
      const submitData: ISearchContractParams = {
        branchCd: "",
        transactionCd: "",
        custNo: storage.getMasterAccount() ?? "",
        fromDate:
          fromDate && Number(moment(fromDate).format("YYYYMMDD"))
            ? Number(moment(fromDate).format("YYYYMMDD"))
            : 0,
        toDate:
          toDate && Number(moment(toDate).format("YYYYMMDD"))
            ? Number(moment(toDate).format("YYYYMMDD"))
            : 0,
        contractSts: contractSts
          ? contractSts
          : Number(contractStsOptions[1].value),
        status: "",
        srcChannel: "",
        closeDateFrom: 0,
        closeDateTo: 0,
        linkPower: "",
      };
      dispatch(searchContractRequest(submitData));
      toast("createContract.message.createContractContent", "success");
      dispatch(updateItemEdit({ brokerCd: "", remarks: "" }));
    }
  }, [statusCreateContract]);
  const changeBrokerHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateItemEdit({ brokerCd: e?.target?.value ?? "" }));
  };

  const selectBrokerHandler = (value: string) => {
    dispatch(updateItemEdit({ brokerCd: value ?? "" }));
  };

  const changeRemarksHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateItemEdit({ remarks: e?.target?.value ?? "" }));
  };

  const clickAcceptHandler = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
  };
  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    dispatch(
      createContractRequest({
        actionMode: itemEdit?.action ? itemEdit?.action : 1,
        alloDate: itemEdit?.alloDate ? Number(itemEdit?.alloDate) : null,
        refNo: itemEdit?.refNo ? itemEdit?.refNo : null,
        custNo: storage.getMasterAccount() ?? "",
        contractNo: itemEdit?.contractNo ? itemEdit?.contractNo : null,
        tradeDate: tradeDate ? tradeDate : "",

        brokerCd: itemEdit?.brokerCd
          ? itemEdit.brokerCd.replace(" ", "").split("-")[0]
          : "",
        remarks: itemEdit?.remarks ? itemEdit.remarks : "",
        timeStamp: itemEdit?.timeStamp ? itemEdit.timeStamp : null,
        srcChannel: 5,
        otpCode: response?.otp ?? "",
        otpType: response?.otpTypeSubmit!,
      })
    );
  };
  useEffect(() => {
    dispatch(findBrokerListRequest({ transactionCd: "" }));
  }, []);

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100 mt-2"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="createContract.create.account" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{currentUser.userId}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="createContract.create.accountName" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{currentUser.displayName}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <DetailRowTextTop>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1", marginTop: "-8px" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="createContract.create.collab" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper
                className="text-left"
                color={themes[themeType].grey0}
              >
                <div className="text-white">
                  <InputAutocompelete
                    placeholder={intl.formatMessage({
                      id: "addFrameworkContractForm.createContract.placeholderRemark",
                    })}
                    getItemValue={(item) =>
                      `${item.brokerCd} - ${item.familyName} ${item.givenName}`
                    }
                    items={brokerList}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={classNames({ isHighlighted })}
                        style={{ color: `${themes[themeType].grey0}` }}
                      >
                        {`${item.brokerCd} - ${item.familyName} ${item.givenName}`}
                      </div>
                    )}
                    shouldItemRender={(item, value) => {
                      return (
                        `${item.brokerCd} - ${item.familyName} ${item.givenName}`
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) > -1
                      );
                    }}
                    value={itemEdit?.brokerCd}
                    onChange={changeBrokerHandler}
                    onSelect={selectBrokerHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowTextTop>
        <DetailRowTextTop>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} lg={4} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="createContract.create.remarks" />
              </DetailLabel>
            </Col>
            <Col xs={12} lg={8} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <TextArea
                    textArea={true}
                    value={itemEdit?.remarks}
                    onChange={changeRemarksHandler}
                    placeholder={intl.formatMessage({
                      id: "addFrameworkContractForm.createContract.placeholderRemark",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowTextTop>
      </DetailCol>
      <DetailColFlexEnd>
        <CheckBoxRow>
          <CheckBox onChange={() => setChecked(!checked)} checked={checked} />

          {/* <CheckBoxText>
            <FormattedMessage id="createContract.create.mess1" />
            <ColorCheckBoxText>
              <FormattedMessage id="createContract.create.mess2" />
            </ColorCheckBoxText>
          </CheckBoxText> */}

          <CheckBoxText>
            <FormattedMessage
              id="createContract.create.mess1"
              values={{
                link: (
                  <Link
                    target="_blank"
                    href={
                      process.env.REACT_APP_PSI_QLTTKCK_Dieu_khoan_dieu_kien
                    }
                    // href="http://dev1.goline:8001/downloads/PSI_QLTTKCK_Dieu_khoan_dieu_kien.pdf"
                  >
                    <FormattedMessage id="createContract.create.mess2" />
                  </Link>
                ),
              }}
            />
          </CheckBoxText>
        </CheckBoxRow>
        <DetailRowButton>
          <Button
            style={{
              background: `${themes[themeType].buttonGradientColor}`,
              fontSize: `${themes[themeType].typeFaces.headline.size}`,
              padding: "18px 0px",
            }}
            className="button-filter m-1 w-100 button-disable-opac"
            onClick={clickAcceptHandler}
            fill={true}
            disabled={!checked}
          >
            {intl.formatMessage({
              id: "createContract.create.confirm",
            })}
          </Button>
        </DetailRowButton>
      </DetailColFlexEnd>
      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.DEPOSIT}
        />
      )}
    </DetailWrapper>
  );
}

export default RequestDetail;
