import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  min-height: 650px;
  border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
  border: 2px solid ${({ theme }) => theme.bgElevated3};
`;

export const Title = styled.div`
  height: 40px;
  padding: 8px;
  text-align: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  background: ${({ theme }) => theme.background3};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.greyG6};
  .flex-1 {
    flex: 1;
  }
`;

export const Body = styled.div<{ padding?: number }>`
  padding: ${({ padding }) => padding}px;
  background-color: ${({ theme }) => theme.grey70};
  min-height: 650px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Menu = styled.div`
  width: 216px;
  height: 650px;
  background-color: ${({ theme }) => theme.background3};
  display: flex;
`;

export const Content = styled.div`
  padding: 16px 24px;
  width: 100%;
  height: 650px;
  background-color: ${({ theme }) => theme.background2};
`;

export const ItemMenu = styled.div<{ isActive: boolean }>`
  width: 100%;
  padding: 24px 0px;
  height: 44px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.brandPrimary : theme.textcolorWhite};
  background-color: ${({ theme }) => theme.background3};
`;

export const ButtonExit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const TitleContent = styled.div`
  flex: 3;
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite} !important;
  text-align: center;
`;

export const WrapProgressBar = styled.div<{ radion?: string }>`
  padding: 12px 0px;
  .progress-bar-vertical {
    width: 4px;
    min-height: 142px;
    margin-right: 12px;
    margin-left: 16px;
    display: flex;
    background-color: ${({ theme }) => theme.border2};
    flex-direction: column;
    .progress-bar {
      width: 100%;
      background-color: ${({ theme }) => theme.brandPrimary} !important;
      height: ${({ radion }) => (radion ? radion : "33%")};
      -webkit-transition: height 0.6s ease;
      -o-transition: height 0.6s ease;
      transition: height 0.6s ease;
      display: block;
    }
  }
`;

export const WrapItemMenu = styled.div`
  display: flex;
  flex-direction: column;
`;
