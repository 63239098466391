import styled from "styled-components/macro";

export const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

// export const Container = styled.div<{
//   isVisibleSideCard: boolean;
// }>`
//   display: flex;
//   height: 100%;
//   flex-direction: column;
//   flex: 1;
//   border-radius: ${({ theme }) => theme.cardHeader.borderRadius};
//   border: 2px solid ${({ theme }) => theme.bgElevated3};
//   overflow: hidden;
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 100%;
  background: ${({ theme }) => theme.background1};
  .input-bg-dark {
    background: ${({ theme }) => theme.background1};
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

export const ContentRight = styled.div<{
  width: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
  width: ${({ width }) => width};
  height: 100%;
`;

export const LabelCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background2};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.grey0};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background1};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const DetailWrapper = styled.div`
  padding: 8px 0;
  height: 100%;
`;

export const DetailCol = styled.div`
  flex: 1;
  padding: 0 8px;
`;

export const DetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const DetailLabel = styled.div`
  min-width: 120px;
`;

export const DetailInputWrapper = styled.div`
  min-width: 220px;
  text-align: right;
`;

export const DetailContentWrapper = styled.div`
  min-width: 220px;
  text-align: right;
  margin-top: 9px;
`;

export const Textarea = styled.textarea`
  position: relative;
  width: 100%;
  background-color: rgb(5, 7, 11);
  border: 1px solid rgb(33, 44, 58);
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  height: 67px;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const TextDetail = styled.p`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.grey0};
`;

export const DetailCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 8px;
  width: 341px;
  padding: 14px;
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
`;
