import { HeaderCell, TableCellContent } from "./styles";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import numeral from "numeral";

const flexEnd = "flex-end";
const flexStart = "flex-start";
const center = "center";

export const dataColumns = [
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.codeStock" />
      </HeaderCell>
    ),
    accessor: "secCd",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.floor" />
      </HeaderCell>
    ),
    accessor: "marketSname",
    Cell: (props: any) => {
      return <TableCellContent align={center}>{props.value}</TableCellContent>;
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateKHQ" />
      </HeaderCell>
    ),
    accessor: "noRightDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateClosing" />
      </HeaderCell>
    ),
    accessor: "ownerFixDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },
  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateTT" />
      </HeaderCell>
    ),
    accessor: "rightExcDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {props.value !== 0 &&
            moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateGD" />
      </HeaderCell>
    ),
    accessor: "beginTradeDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {props.value !== 0
            ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
            : ""}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.ration" />
      </HeaderCell>
    ),
    accessor: "matPrice1",
    Cell: (props: any) => {
      const currentRow = props?.row?.original;
      return (
        <TableCellContent
          align={center}
        >{`${currentRow?.rated1}:${currentRow?.rates1}`}</TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.rationFrom" />
      </HeaderCell>
    ),
    accessor: "matPrice2",
    Cell: (props: any) => {
      const currentRow = props?.row?.original;
      return (
        <TableCellContent
          align={center}
        >{`${currentRow?.rated}:${currentRow?.rates}`}</TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.typeRole" />
      </HeaderCell>
    ),
    accessor: "rightType",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>
          <FormattedMessage id={`subscribe.statusThq.value${props.value}`} />
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateStartDK" />
      </HeaderCell>
    ),
    accessor: "beginRegDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {props.value !== 0
            ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
            : ""}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateEndDK" />
      </HeaderCell>
    ),
    accessor: "endRegDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {props.value !== 0
            ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
            : ""}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateStartCN" />
      </HeaderCell>
    ),
    accessor: "beginTransDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {props.value !== 0
            ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
            : ""}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.dateEndCN" />
      </HeaderCell>
    ),
    accessor: "endTransDate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {props.value !== 0
            ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
            : ""}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={center}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.pricePH" />
      </HeaderCell>
    ),
    accessor: "price",
    Cell: (props: any) => {
      return (
        <TableCellContent align={center}>
          {numeral(props.value * 1000).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.tax" />
      </HeaderCell>
    ),
    accessor: "taxRate",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexEnd}>
          {numeral(props.value).format("0,0")}
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.typeCK" />
      </HeaderCell>
    ),
    accessor: "secState",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>
          <FormattedMessage
            id={`subscribe.content.searchSubscribe.table.secState.value${props.value}`}
          />
        </TableCellContent>
      );
    },
  },

  {
    Header: (
      <HeaderCell align={flexEnd}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.code" />
      </HeaderCell>
    ),
    accessor: "targetSecCd",
    Cell: (props: any) => {
      return <TableCellContent align={flexEnd}>{props.value}</TableCellContent>;
    },
  },

  {
    Header: (
      <HeaderCell align={flexStart}>
        <FormattedMessage id="subscribe.content.searchSubscribe.table.explain" />
      </HeaderCell>
    ),
    accessor: "remarks",
    Cell: (props: any) => {
      return (
        <TableCellContent align={flexStart}>{props.value}</TableCellContent>
      );
    },
  },
];
