import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { orderBookRealTimeListSelector } from "modules/order-book/selectors";
import { OrderBookRealTime } from "domain/models/OrderBook";
import { Dictionary } from "lodash";
import BlinkableCell from "./BlinkableCell";

const orderPriceDispSelector = (realTimeId: string) => {
  return createSelector(
    orderBookRealTimeListSelector,
    (state: Dictionary<OrderBookRealTime>) =>
      state?.[realTimeId]?.orderPriceDisp
  );
};

interface Props {
  realTimeId: string;
}

const OrderPriceDispCell = ({ realTimeId }: Props) => {
  const orderPriceDisp = useSelector(orderPriceDispSelector(realTimeId));
  return (
    <>
      <BlinkableCell value={orderPriceDisp} />
    </>
  );
};

export default OrderPriceDispCell;
