import { InputPlaceOrder, Spacer } from "components/commons";
import numeral from "numeral";
import { ChangeEvent, FC, memo, useState } from "react";
import {
  Body,
  IconContainer,
  SuggestionContainer,
  Title,
  Empty,
  Error,
} from "./styles";
import { ReactComponent as IconDecrement } from "assets/image/svg/ic_decrement.svg";
import { ReactComponent as IconIncrement } from "assets/image/svg/ic_increment.svg";
import { incrementVolume, decrementVolume } from "helper/tradingHelper";

const getNumb = (numb: number) => Math.round(numb * 100) / 100;

interface Props {
  value: string | number;
  onChange: (num: number | string, type?: string) => void;
  suggestions?: any[];
  label?: string;
  ticker?: string;
  max?: number;
  min?: number;
  partern: RegExp;
  decimal?: boolean;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  maxVolume?: number;
  name?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlurEnter?: () => void;
  containerId?: string;
  onClickButton?: (newValue: number) => void;
}
const InputVolume: FC<Props> = memo(
  ({
    value,
    onChange,
    suggestions,
    label,
    ticker,
    max,
    min,
    partern,
    decimal,
    error,
    placeholder,
    disabled = false,
    maxVolume,
    name,
    innerRef,
    onKeyDown,
    onBlurEnter,
    containerId,
    onClickButton,
  }) => {
    const [focus, setFocus] = useState(false);
    const onText = (e: ChangeEvent<HTMLInputElement>) => {
      let text: string | number | null = decimal
        ? e.target.value
        : numeral(e.target.value).value();

      text = numeral(text).value();
      if (text === null) {
        return onChange("");
      }
      if (text) {
        onChange(Math.abs(text));
      }
    };

    const onClickSuggest = (value: string | number) => () => {
      setFocus(false);
      onChange(value);
    };

    const onBlur = () => {
      if (onBlurEnter) {
        onBlurEnter();
      }
      setTimeout(() => setFocus(false), 150);
    };

    const onFocus = () => setFocus(true);

    const handleWheel = (event: any) => {
      if (event.deltaY < 0) {
        if (!disabled) {
          const volumeChange = incrementVolume(ticker ?? "", value, maxVolume);
          onChange(getNumb(Number(volumeChange)), "increment");
        }
      } else if (event.deltaY > 0) {
        if (!disabled) {
          const volumeChange = decrementVolume(ticker ?? "", value);
          onChange(getNumb(Number(volumeChange)), "decrement");
        }
      }
    };

    const onMouseOverHandler = () => {
      if (containerId) {
        const containerEle = document.getElementById(containerId);
        if (!!containerEle) {
          containerEle.style.overflowY = "hidden";
        }
      }
    };

    const onMouseOutHandler = () => {
      if (containerId) {
        const containerEle = document.getElementById(containerId);
        if (!!containerEle) {
          containerEle.style.overflowY = "auto";
        }
      }
    };

    return (
      <>
        <div className="d-flex align-items-center">
          {label && <Title>{label}</Title>}
          <Body>
            <InputPlaceOrder
              innerRef={innerRef}
              name={name}
              onFocus={onFocus}
              onBlur={onBlur}
              value={value}
              onChange={onText}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onWheel={handleWheel}
              onMouseOver={onMouseOverHandler}
              onMouseOut={onMouseOutHandler}
              prepend={
                <IconContainer
                  isBorder={true}
                  onClick={() => {
                    if (!disabled) {
                      const volumeChange = decrementVolume(ticker ?? "", value);
                      onChange(getNumb(Number(volumeChange)), "decrement");
                    }
                    const newValue = +(+value - 100).toFixed(2);
                    if (
                      (typeof min === "number" && newValue >= min) ||
                      typeof min === "undefined"
                    ) {
                      onClickButton?.(newValue);
                    }
                  }}
                >
                  <IconDecrement />
                </IconContainer>
              }
              append={
                <IconContainer
                  onClick={() => {
                    if (!disabled) {
                      const volumeChange = incrementVolume(
                        ticker ?? "",
                        value,
                        maxVolume
                      );
                      onChange(getNumb(Number(volumeChange)), "increment");
                    }
                    const newValue = +(+value + 100).toFixed(2);
                    if (
                      (typeof max === "number" && newValue <= max) ||
                      typeof max === "undefined"
                    ) {
                      onClickButton?.(newValue);
                    }
                  }}
                >
                  <IconIncrement />
                </IconContainer>
              }
              isBorderActive={focus}
              isTextCenter
              placeholder={placeholder}
            />
          </Body>
        </div>
        {error && !focus ? (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Empty></Empty>
            <Error>
              <span className="text-danger flex-1">{error}</span>
            </Error>
          </div>
        ) : (
          <div></div>
        )}
        {label && <Spacer size="s" />}
        {focus && suggestions && suggestions.length > 0 && (
          <>
            <SuggestionContainer>
              {suggestions.map((item, index) => (
                <div
                  className="item"
                  key={index}
                  onMouseDown={onClickSuggest(item.label)}
                  onMouseOver={onFocus}
                >
                  {decimal ? item.label : numeral(item.label).format("0,000")}
                </div>
              ))}
            </SuggestionContainer>
            <Spacer size="s" />
          </>
        )}
      </>
    );
  }
);

export default InputVolume;
