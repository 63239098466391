import { ReactComponent as Loading } from "assets/image/svg/Loading.svg";
import { Button } from "components/commons";
import { toast } from "components/commons/Toast";
import { generateQRCode } from "core/http/apis/twofactor";
import { GenerateQRParams } from "core/http/apis/twofactor/types";
import {
  EBusinessCd,
  EChannelNumber,
  EOTPType,
  EOTPTypeSubmit,
} from "helper/consts";
import { useQRCodeAuthen } from "helper/cusHooks";
import storage from "helper/storage";
import moment from "moment";
import QRCodeLib from "qrcode.react";
import { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IVerifyResponse } from "../OTPByBusinessCd";
import {
  BodyQRcode,
  CardTable,
  ContentText,
  ErrorVerify,
  LoadingBox,
  QRcodeExpired,
} from "./styles";

interface Props {
  businessCd: EBusinessCd;
  getResult: any;
}

const radius4 = 4;

function QRCode({ businessCd, getResult }: Props) {
  const [isCodeExpired, setCodeExpired] = useState(false);
  const [isErrorQR, setErrorQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const QRCodeAuthen = useQRCodeAuthen();
  const [codeQR, setCodeQR] = useState("");

  const callQRCode = async () => {
    try {
      setLoading(true);
      setCodeExpired(false);
      const body: GenerateQRParams = {
        userId: storage.getMasterAccount().toString(),
        businessCd: businessCd,
        channel: EChannelNumber.WEB,
        remarks: "",
      };

      const result = await generateQRCode(body);
      setCodeQR(result.data.uuid!);

      // set thời gian hết hạn QRcode
      setTimeout(function () {
        setCodeExpired(true);
      }, moment(result.data.expiredTime).diff(moment(), "milliseconds"));
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast(error.code, "error", error.description, true);
    }
  };

  useEffect(() => {
    callQRCode();
  }, []);

  // check res để success
  useEffect(() => {
    if (QRCodeAuthen) {
      // TODO: ScanId từ mqtt trả về
      // handleSubmitBusinessApi(QRCodeAuthen);
      const response: IVerifyResponse = {
        otp: QRCodeAuthen?.qrCode,
        otpTypeSubmit: EOTPTypeSubmit.QR_CODE,
        businessCd: businessCd,
        otpType: EOTPType.NONE,
      };
      console.warn("QRCodeAuthen", response);
      getResult(response);
      setErrorQR(false);
      // if (QRCodeAuthen) {

      // } else {
      //   setErrorQR(true);
      // }
    }
  }, [QRCodeAuthen]);

  return (
    <CardTable>
      <BodyQRcode disabled={isCodeExpired || codeQR === "" || loading}>
        <QRCodeLib
          id="qrcode"
          value={codeQR}
          size={322}
          level={"L"}
          includeMargin={true}
        />
      </BodyQRcode>

      <ContentText>
        {isErrorQR ? (
          <ErrorVerify>
            <FormattedMessage id="auth.info.smartOTP.errorQR" />
          </ErrorVerify>
        ) : (
          <FormattedMessage id="auth.info.smartOTP.content" />
        )}
      </ContentText>
      {loading && (
        <LoadingBox>
          <Loading className="loader" />
        </LoadingBox>
      )}
      {isCodeExpired && (
        <QRcodeExpired>
          <p>
            <FormattedMessage id="auth.info.smartOTP.expired" />
          </p>
          <Button
            size="l"
            style={{ flex: 1 }}
            onClick={() => callQRCode()}
            className="flex-1"
            color="accept"
            radius={radius4}
          >
            <FormattedMessage id="auth.info.smartOTP.buttonGetQR" />
          </Button>
        </QRcodeExpired>
      )}
    </CardTable>
  );
}
export default memo(QRCode);
