import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ListedState = {
  selectedListedType: string;
};

const initialState: ListedState = {
  selectedListedType: "",
};

const listedSlice = createSlice({
  name: "listedSlice",
  initialState,
  reducers: {
    changeListedType: (state, action: PayloadAction<string>) => {
      state.selectedListedType = action.payload;
    },
  },
});

export const { changeListedType } = listedSlice.actions;

export default listedSlice.reducer;
