import { Graph } from "../../styles";
import TickerGraphWidget from "../TickerGraph";

interface Props {
  ticker?: string | null;
}

export default function HeaderTickerGraph({ ticker }: Props) {
  return (
    <Graph>
      <TickerGraphWidget selectedTicker={ticker} />
    </Graph>
  );
}
