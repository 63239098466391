import { Button, Input } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import CSelect from "components/commons/Select";
import { searchListOrderBookRequest } from "modules/place-order-retail-bonds/redux";
import { loadingSearchSelector } from "modules/place-order-retail-bonds/selectors";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ButtonSearch, FilterWrapper, WrapBorder } from "./styles";

function Filter() {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSearchSelector);

  const [bondCd, setBondCd] = useState<any>();
  const [newStatus, setNewStatus] = useState<any>();

  const handleChangeBondCd = (e: any) => {
    setBondCd(e.target.value);
  };

  const handleChangeStatus = (e: any) => {
    setNewStatus(e);
  };

  const handleSearch = () => {
    dispatch(
      searchListOrderBookRequest({
        subAccoNo: "",
        bondCd: bondCd ? bondCd : "",
        status: newStatus ? newStatus?.value : -1,
        fromDate: 0,
        toDate: 0,
      })
    );
  };

  const intl = useIntl();

  const status = [
    {
      label: `${intl.formatMessage({
        id: "placeOrderRetailBonds.orderBook.all",
      })}`,
      value: "-1",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderRetailBonds.orderBook.delay",
      })}`,
      value: "0",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderRetailBonds.orderBook.accept",
      })}`,
      value: "1",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderRetailBonds.orderBook.deny",
      })}`,
      value: "2",
    },
    {
      label: `${intl.formatMessage({
        id: "placeOrderRetailBonds.orderBook.cancel",
      })}`,
      value: "9",
    },
  ];

  return (
    <WrapBorder>
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "placeOrderRetailBonds.orderBook.bondCd",
          })}
          className="mr-4"
        >
          <Input
            placeholder={intl.formatMessage({
              id: "placeOrderRetailBonds.orderBook.placeHolderBondCd",
            })}
            onChange={handleChangeBondCd}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "placeOrderRetailBonds.orderBook.status",
          })}
          className="mr-4"
        >
          <CSelect
            className="flex-fill"
            options={status}
            onChange={handleChangeStatus}
            defaultValue={status?.[0]}
          />
        </FilterItem>
        <ButtonSearch>
          <Button
            color="accept"
            onClick={handleSearch}
            className="button-filter mr-4"
            loading={loading}
          >
            {intl.formatMessage({
              id: "placeOrderRetailBonds.orderBook.search",
            })}
          </Button>
        </ButtonSearch>
      </FilterWrapper>
    </WrapBorder>
  );
}

export default Filter;
