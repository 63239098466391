import React from "react";
import { Doughnut } from "react-chartjs-2";
import { LegendOrgChart, LegendOrgName } from "./styles";
import { ChartData, ChartOptions } from "chart.js";
import cloneDeep from "lodash/cloneDeep";
import { ChartContentWrapper } from "./styles";

interface Props {
  titleLegend?: string;
  colors: string[];
  data: ChartData;
  labels: string[];
  configs?: ChartOptions;
}

const OrgPieChart: React.FC<Props> = ({
  titleLegend,
  colors,
  data,
  labels,
  configs,
}) => {
  return (
    <ChartContentWrapper className="row">
      <div className="col pie-chart">
        <Doughnut data={cloneDeep(data)} options={configs} />
      </div>
      <div className="col stack-holder-info">
        {titleLegend && (
          <div className="org-ratio-stake-holder">{titleLegend}</div>
        )}
        <div className="legend-row pt-2">
          {labels.map((el, idx) => (
            <div className="legend-item" key={idx}>
              <LegendOrgChart color={colors[idx]} />
              <LegendOrgName>{el}</LegendOrgName>
            </div>
          ))}
        </div>
      </div>
    </ChartContentWrapper>
  );
};

export default OrgPieChart;
