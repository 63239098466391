/* eslint-disable no-useless-escape */
import { Modal } from "react-bootstrap";
import {
  Container,
  Title,
  TitleContent,
  ButtonExit,
  Body,
  BodyModalRow,
  LabelCell,
  WrapInput,
  LabelInput,
  ActionsRow,
  ButtonCell,
  WrapFormBotton,
} from "./styles";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Button } from "components/commons";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserInfo,
  selectVisibleModalEditPassFirst,
} from "modules/auth/selectors";
import { logOutStart, setvisibleModalEditPassFirst } from "modules/auth/redux";
import Storage from "helper/storage";
import { useEffect, useState } from "react";
import { GetParameters } from "helper/sessionData";
import { toast } from "components/commons/Toast";
import { userChangePasswordApi, UserChangePinCDApi } from "core/http/apis/auth";

const ModalEditPassFirst = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const visible = useSelector(selectVisibleModalEditPassFirst);
  const minPass = GetParameters("SYST", "SYSTPASS_MIN");
  const alphaPass = GetParameters("SYST", "SYSTPASS_ALPHA_REQ");
  const reqPass = GetParameters("SYST", "SYSTPASS_CHAR_REQ");

  useEffect(() => {
    return () => {
      setpassLogin({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setpassCd({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    };
  }, []);

  const onLogOut = () => {
    dispatch(
      logOutStart({
        refresh_token: Storage.getRefreshToken(),
        code_verifier: Storage.getChallengeCodeVerifier(),
      })
    );
    dispatch(setvisibleModalEditPassFirst());
  };

  const [passLogin, setpassLogin] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const updatePassLogin = (value: any, key: string) => {
    setpassLogin({
      ...passLogin,
      [key]: value,
    });
  };

  const [passCd, setpassCd] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const updatePassCd = (value: any, key: string) => {
    setpassCd({
      ...passCd,
      [key]: value,
    });
  };

  const [validateNewPass, setvalidateNewPass] = useState({
    errors: "",
    type: "",
  });
  const checkValidate = (value: string, key: string) => {
    if (value < minPass) {
      return setvalidateNewPass({
        errors: "Min",
        type: key,
      });
    }
    if (reqPass === "true") {
      const format = /[!?@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (!format.test(value)) {
        return setvalidateNewPass({
          errors: "Alpha",
          type: key,
        });
      }
    }

    if (alphaPass === "true") {
      const x = value.match(/[A-Z]/g);

      if (!x) {
        return setvalidateNewPass({
          errors: "Char",
          type: key,
        });
      }
    }
    return setvalidateNewPass({
      errors: "",
      type: "",
    });
  };

  const [error, setError] = useState({
    error: "",
    type: "",
  });
  const checkError = () => {
    if (userInfo?.changePassFlag === 1) {
      const value = Object.keys(passLogin).find(
        (key) => passLogin[key] === "" || passLogin[key] === null
      );
      if (value) {
        setError({
          error: value,
          type: "login",
        });
        return false;
      } else {
        setError({
          error: "",
          type: "",
        });
      }
    }
    if (userInfo?.changePinFlag === 1) {
      const value = Object.keys(passCd).find(
        (key) => passCd[key] === "" || passCd[key] === null
      );
      if (value) {
        setError({
          error: value,
          type: "cd",
        });
        return false;
      } else {
        setError({
          error: "",
          type: "",
        });
      }
    }
    return true;
  };

  const updatePinPass = async () => {
    const res: any = await UserChangePinCDApi({
      ...passCd,
      currentPinCd: passCd.currentPassword,
    });
    if (res?.statusCode === 0) {
      toast("auth.setting.settingPass.modal.messageSuccess", "success");
      onLogOut();
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const submitForm = async () => {
    if (validateNewPass.errors !== "") return;
    if (checkError()) {
      if (
        (passLogin.newPassword !== passLogin.confirmPassword ||
          passLogin.newPassword === "") &&
        userInfo?.changePassFlag === 1
      ) {
        return toast(
          "auth.setting.settingPass.modal.messErrorConfirmPass",
          "error"
        );
      }

      if (
        (passCd.newPassword !== passCd.confirmPassword ||
          passCd.newPassword === "") &&
        userInfo?.changePinFlag === 1
      ) {
        return toast(
          "auth.setting.settingPass.modal.messErrorConfirmPass",
          "error"
        );
      }

      if (userInfo?.changePassFlag === 1) {
        const res: any = await userChangePasswordApi(passLogin);
        if (res?.statusCode === 0) {
          toast("auth.setting.settingPass.modal.messageSuccess", "success");
          if (userInfo?.changePinFlag === 1) {
            updatePinPass();
          }
        } else {
          toast(res?.errorCode, "error", res?.message, true);
          // onLogOut();
        }
      }
      if (userInfo?.changePinFlag === 1 && userInfo?.changePassFlag !== 1) {
        updatePinPass();
      }
    }
  };

  return (
    <>
      <Modal
        show={visible}
        centered
        dialogClassName="modal-450"
        onHide={onLogOut}
      >
        <Container>
          <Title>
            <div className="flex-1"></div>
            <TitleContent>
              <FormattedMessage id="auth.editPassFirst.title" />
            </TitleContent>
            <ButtonExit>
              <IconExit onClick={() => onLogOut()} />
            </ButtonExit>
          </Title>
          <Body>
            {userInfo?.changePassFlag === 1 && (
              <>
                <LabelInput>
                  <FormattedMessage id="auth.editPassFirst.passLogin.title" />
                </LabelInput>
                <BodyModalRow>
                  <LabelCell>
                    <div className="d-flex">
                      <FormattedMessage id="auth.editPassFirst.passLogin.nowPass" />
                      <p className="text-danger pl-1 mb-0">*</p>
                    </div>
                  </LabelCell>
                  <WrapInput>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "auth.editPassFirst.passLogin.placeholderNowPass",
                      })}
                      type="password"
                      onChange={(e: any) =>
                        updatePassLogin(e?.target?.value, "currentPassword")
                      }
                      // value={form?.currentPassword}
                      autoComplete="off"
                    />
                    {error.error === "currentPassword" &&
                      error.type === "login" && (
                        <span className="text-error text-danger">
                          {intl.formatMessage({
                            id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                          })}
                        </span>
                      )}
                  </WrapInput>
                </BodyModalRow>

                <BodyModalRow>
                  <LabelCell>
                    <div className="d-flex">
                      <FormattedMessage id="auth.editPassFirst.passLogin.newPass" />
                      <p className="text-danger pl-1 mb-0">*</p>
                    </div>
                  </LabelCell>
                  <WrapInput>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "auth.editPassFirst.passLogin.placeholderNewPass",
                      })}
                      type="password"
                      onChange={(e: any) =>
                        updatePassLogin(e?.target?.value, "newPassword")
                      }
                      onBlur={(e: any) =>
                        checkValidate(e?.target?.value, "login")
                      }
                      value={passLogin?.newPassword}
                    />
                    {error.error === "newPassword" && error.type === "login" && (
                      <span className="text-error text-danger">
                        {intl.formatMessage({
                          id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                        })}
                      </span>
                    )}
                    {error.error === "" &&
                      validateNewPass.errors !== "" &&
                      validateNewPass.type === "login" && (
                        <span className="text-error text-danger">
                          {validateNewPass.errors === "Min"
                            ? intl.formatMessage(
                                {
                                  id: `auth.setting.settingPass.modal.error${validateNewPass.errors}`,
                                },
                                { x: minPass }
                              )
                            : intl.formatMessage({
                                id: `auth.setting.settingPass.modal.error${validateNewPass.errors}`,
                              })}
                        </span>
                      )}
                  </WrapInput>
                </BodyModalRow>

                <BodyModalRow>
                  <LabelCell>
                    <div className="d-flex">
                      <FormattedMessage id="auth.editPassFirst.passLogin.confirmPass" />
                      <p className="text-danger pl-1 mb-0">*</p>
                    </div>
                  </LabelCell>
                  <WrapInput>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "auth.editPassFirst.passLogin.placeholderconfirmPass",
                      })}
                      type="password"
                      onChange={(e: any) =>
                        updatePassLogin(e?.target?.value, "confirmPassword")
                      }
                      // value={form.currentPassword}
                      autoComplete="off"
                    />
                    {error.error === "confirmPassword" &&
                      error.type === "login" && (
                        <span className="text-error text-danger">
                          {intl.formatMessage({
                            id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                          })}
                        </span>
                      )}
                  </WrapInput>
                </BodyModalRow>
              </>
            )}

            {userInfo?.changePinFlag === 1 && (
              <WrapFormBotton
                mgt={userInfo?.changePassFlag === 1 ? true : false}
              >
                <LabelInput>
                  <FormattedMessage id="auth.editPassFirst.cdLogin.title" />
                </LabelInput>
                <BodyModalRow>
                  <LabelCell>
                    <div className="d-flex">
                      <FormattedMessage id="auth.editPassFirst.cdLogin.nowPass" />
                      <p className="text-danger pl-1 mb-0">*</p>
                    </div>
                  </LabelCell>
                  <WrapInput>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "auth.editPassFirst.cdLogin.placeholderNowPass",
                      })}
                      type="password"
                      onChange={(e: any) =>
                        updatePassCd(e?.target?.value, "currentPassword")
                      }
                      // value={form.currentPassword}
                      autoComplete="off"
                    />
                    {error.error === "currentPassword" && error.type === "cd" && (
                      <span className="text-error text-danger">
                        {intl.formatMessage({
                          id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                        })}
                      </span>
                    )}
                  </WrapInput>
                </BodyModalRow>

                <BodyModalRow>
                  <LabelCell>
                    <div className="d-flex">
                      <FormattedMessage id="auth.editPassFirst.cdLogin.newPass" />
                      <p className="text-danger pl-1 mb-0">*</p>
                    </div>
                  </LabelCell>
                  <WrapInput>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "auth.editPassFirst.cdLogin.placeholderNewPass",
                      })}
                      type="password"
                      onChange={(e: any) =>
                        updatePassCd(e?.target?.value, "newPassword")
                      }
                      value={passCd.newPassword}
                      onBlur={(e: any) => checkValidate(e?.target?.value, "cd")}
                      autoComplete="off"
                    />
                    {error.error === "newPassword" && error.type === "cd" && (
                      <span className="text-error text-danger">
                        {intl.formatMessage({
                          id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                        })}
                      </span>
                    )}
                    {error.error === "" &&
                      validateNewPass.errors !== "" &&
                      validateNewPass.type === "cd" && (
                        <span className="text-error text-danger">
                          {validateNewPass.errors === "Min"
                            ? intl.formatMessage(
                                {
                                  id: `auth.setting.settingPass.modal.error${validateNewPass.errors}`,
                                },
                                { x: minPass }
                              )
                            : intl.formatMessage({
                                id: `auth.setting.settingPass.modal.error${validateNewPass.errors}`,
                              })}
                        </span>
                      )}
                  </WrapInput>
                </BodyModalRow>

                <BodyModalRow>
                  <LabelCell>
                    <div className="d-flex">
                      <FormattedMessage id="auth.editPassFirst.cdLogin.confirmPass" />
                      <p className="text-danger pl-1 mb-0">*</p>
                    </div>
                  </LabelCell>
                  <WrapInput>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "auth.editPassFirst.cdLogin.placeholderconfirmPass",
                      })}
                      type="password"
                      onChange={(e: any) =>
                        updatePassCd(e?.target?.value, "confirmPassword")
                      }
                      // value={form.currentPassword}
                      autoComplete="off"
                    />
                    {error.error === "confirmPassword" && error.type === "cd" && (
                      <span className="text-error text-danger">
                        {intl.formatMessage({
                          id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                        })}
                      </span>
                    )}
                  </WrapInput>
                </BodyModalRow>
              </WrapFormBotton>
            )}

            <ActionsRow>
              <ButtonCell>
                <Button
                  className="button-confirm"
                  color="accept"
                  onClick={() => onLogOut()}
                  width="100%"
                >
                  <FormattedMessage id="auth.setting.settingPass.modal.btnCancel" />
                </Button>
              </ButtonCell>

              <ButtonCell>
                <Button
                  className="button-confirm"
                  color="gradientaAccept"
                  onClick={() => submitForm()}
                  width="100%"
                >
                  <FormattedMessage id="auth.setting.settingPass.modal.btnSuccess" />
                </Button>
              </ButtonCell>
            </ActionsRow>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default ModalEditPassFirst;
