import { getListPriceInfoPutThrough } from "core/grpc";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchListPriceInfoPutThrough,
  fetchListPriceInfoPutThroughFailure,
  fetchListPriceInfoPutThroughSuccess,
} from "../redux/putThrough";
import { PutThroughData } from "domain/protoNew/auto_trading_pb";

function* fetchListPriceInfoPutThroughWorker(
  action: ReturnType<typeof fetchListPriceInfoPutThrough>
) {
  try {
    const res: PutThroughData.AsObject = yield call(
      getListPriceInfoPutThrough,
      parseInt(action.payload)
    );
    yield put(fetchListPriceInfoPutThroughSuccess(res));
  } catch (e: any) {
    yield put(fetchListPriceInfoPutThroughFailure(e.message));
  }
}

function* fetchListPriceInfoPutThroughWatcher() {
  yield takeLatest(
    fetchListPriceInfoPutThrough.type,
    fetchListPriceInfoPutThroughWorker
  );
}

export default function* putThroughSaga() {
  yield all([fetchListPriceInfoPutThroughWatcher()]);
}
