import queryString from "query-string";
import { ISearchContractParams, IFindBrokerListParams } from "./types";

export const searchContractApi = (params: ISearchContractParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDepositRegisterList${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};
export const findBrokerListApi = (params: IFindBrokerListParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findBrokerList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const liquidateContractApi = "/closeDepositRegister";
export const createContractApi = "/updateDepositRegister";
