import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";
import { ActiveTabMarginContract } from "helper/consts";
import { activeTabSelector } from "modules/margin-contract/selectors";

const Tabs: FC = () => {
  const intl = useIntl();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab isActive={activeTab === ActiveTabMarginContract.MARGIN_CONTRACT}>
          {intl.formatMessage({ id: "marginContract.tab" })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
