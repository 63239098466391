import max from "lodash/max";
import { FormattedMessage } from "react-intl";
import VolumeItem from "./VolumeItem";
import { Body, Header, Table } from "./styles";
import { volumeListSelector } from "modules/stock-order/redux/selectors";
import { useSelector } from "react-redux";
import DateTypeMenu from "./DateTypeMenu";

function VolumeAnalysisList() {
  const volumeList = useSelector(volumeListSelector);
  const maxPercentage = max(volumeList.map((item) => item.percentbytotal));
  return (
    <Table>
      <Header>
        <FormattedMessage id="widgets.volumeAndTime.volumeAnalysis" />
        <DateTypeMenu />
      </Header>

      <Body>
        {volumeList.map((volume, index) => (
          <VolumeItem
            key={index.toString()}
            maxPercent={maxPercentage || 0}
            {...volume}
          />
        ))}
      </Body>
    </Table>
  );
}

export default VolumeAnalysisList;
