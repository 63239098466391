import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button } from "components/commons";
import { selectDetail, selectTypeForm } from "modules/subscribeBuy/selectors";
// import numeral from "numeral";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
} from "./styles";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { FORM_LEFT } from "modules/subscribeBuy/constants";

interface PROPS {
  isVisible: boolean;
  onClose: () => void;
  form: any;
  onSuccess: () => void;
}

const ConfirmModal = (props: PROPS) => {
  const { isVisible, onClose, form, onSuccess } = props;
  const { secCd, subAccoNo, price } = useSelector(selectDetail);
  const typeForm = useSelector(selectTypeForm);

  const closeModalHandler = () => {
    onClose();
  };

  const confirm = () => {
    onSuccess();
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage
              id={
                typeForm === FORM_LEFT.ADD
                  ? "subscribe.formAdd.modal.title"
                  : "subscribe.formRemove.modal.title"
              }
            />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="subscribe.formAdd.modal.subacc" />
            </LabelCell>
            <TextCell>{subAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="subscribe.formAdd.modal.codeStock" />
            </LabelCell>
            <TextCell>{secCd}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="subscribe.formAdd.modal.price" />
            </LabelCell>
            <TextCell>
              {price !== null ? numeral(price * 1000).format("0,0") : 0}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage
                id={
                  typeForm === FORM_LEFT.ADD
                    ? "subscribe.formAdd.modal.numberRegister"
                    : "subscribe.formRemove.modal.numberCancelRegister"
                }
              />
            </LabelCell>
            <TextCell>{numeral(form?.applyQty).format("0,0")}</TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="subscribe.formAdd.modal.intoMoney" />
            </LabelCell>
            <TextCell>
              {numeral(
                Number(numeral(form.applyQty * 1000).value()) * Number(price)
              ).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="subscribe.formAdd.modal.note" />
            </LabelCell>
            <TextCell>{form?.remarks}</TextCell>
          </BodyModalRow>

          <ActionsRow className="mt-3">
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={closeModalHandler}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.cancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                className="button-confirm"
                color="gradientaAccept"
                // onClick={clickAgreeButtonHandler}
                onClick={() => confirm()}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.accept" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
