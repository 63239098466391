import styled from "styled-components/macro";

export const Container = styled.div`
  height: calc(100% - 35px);
  width: 100%;
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% - 30px) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.red;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green;
      case "violet":
        return theme.violet;
      case "orange":
        return theme.solidOrange;
      default:
        return color;
    }
  }};

  opacity: 0.4px;
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  height: 20px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.grey20};
  font-weight: 500;
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};

  box-shadow: ${({ theme }) => theme.shadowLineDown};
  font-size: ${({ theme }) => theme.typeFaces.caption11.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.minimal9.lineHeight};
`;

export const IconWrapper = styled.span`
  cursor: pointer;
`;
export const ButtonBuy = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.green50};
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const ButtonSell = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.red50};
  color: ${({ theme }) => theme.textcolorWhite};
`;
export const ButtonLiquidation = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.brandPrimary};
  color: ${({ theme }) => theme.textcolorWhite};
`;
export const ButtonEdit = styled.button`
  width: 100%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.yellow60};
  color: ${({ theme }) => theme.textcolorWhite};
`;
export const ColorGreen = styled.div`
  color: ${({ theme }) => theme.green50};
`;
export const ColorRed = styled.div`
  color: ${({ theme }) => theme.solidRed};
`;
