import HttpClient from "../../index";
import { REACT_APP_RESTFUL_MARGIN_CONTRACT_URL } from "AppConfig";

export default class HttpMarginContract extends HttpClient {
  private static classInstance?: HttpMarginContract;
  private constructor() {
    super(REACT_APP_RESTFUL_MARGIN_CONTRACT_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpMarginContract();
    }

    return this.classInstance;
  }
}
