import { FC } from "react";
import ClickableTicker from "components/commons/ClickableTicker";
import { StockNameWrapper } from "components/widgets/generals/TopForeign/Cells/styles";
import GlobalData from "helper/globalData";

interface Props {
  ticker: string;
}

const Cell: FC<Props> = ({ ticker }) => {
  return (
    <>
      <ClickableTicker ticker={ticker || "-"} />
      <StockNameWrapper>
        {GlobalData.getTickerInfoNew(ticker!)?.secname || "-"}
      </StockNameWrapper>
    </>
  );
};

export default Cell;
