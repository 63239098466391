import { IThemes, ScreenType, ThemeData, ThemeType, TTypeFace } from "../types";

const typeFaces: { [key in ScreenType]: TTypeFace } = {
  [ScreenType.MD]: {
    header: {
      size: "32px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "40px",
    },
    title: {
      size: "15px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "24px",
    },
    headline: {
      size: "13px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "20px",
    },
    body: {
      size: "12px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "16px",
    },
    caption12: {
      size: "11px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "14px",
    },
    caption11: {
      size: "10px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "16px",
    },
    caption10: {
      size: "9px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "16px",
    },

    minimal9: {
      size: "8px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "12px",
    },
    minimal: {
      size: "8px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "12px",
    },
    watchlist: {
      menu: {
        size: "11px",
        weight: {
          bold: 700,
          semiBold: 600,
          regular: 500,
        },
        lineHeight: "16px",
      },
      header: {
        size: "11px",
        weight: {
          bold: 700,
          semiBold: 600,
          regular: 500,
        },
        lineHeight: "16px",
      },
      body: {
        size: "11px",
        weight: {
          bold: 700,
          semiBold: 600,
          regular: 500,
        },
        lineHeight: "16px",
      },
    },
    sideBar: {
      width: "48px",
      sideBarItem: {
        height: "48px",
      },
    },
  },
  [ScreenType.LG]: {
    header: {
      size: "32px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "40px",
    },
    title: {
      size: "20px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "25px",
    },
    headline: {
      size: "15px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "20px",
    },
    body: {
      size: "13px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "18px",
    },
    caption12: {
      size: "12px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "14px",
    },
    caption11: {
      size: "11px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "18px",
    },
    caption10: {
      size: "10px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "16px",
    },

    minimal9: {
      size: "9px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "12px",
    },
    minimal: {
      size: "8px",
      weight: {
        bold: 700,
        semiBold: 600,
        regular: 400,
      },
      lineHeight: "12px",
    },
    watchlist: {
      menu: {
        size: "12px",
        weight: {
          bold: 700,
          semiBold: 600,
          regular: 500,
        },
        lineHeight: "16px",
      },
      header: {
        size: "12px",
        weight: {
          bold: 700,
          semiBold: 600,
          regular: 500,
        },
        lineHeight: "16px",
      },
      body: {
        size: "12px",
        weight: {
          bold: 700,
          semiBold: 600,
          regular: 500,
        },
        lineHeight: "16px",
      },
    },
    sideBar: {
      width: "52px",
      sideBarItem: {
        height: "52px",
      },
    },
  },
};

const tables = {
  header: {
    height: "24px",
  },
  content: {
    height: "32px",
    padding: "8px",
  },
  contentLarge: {
    height: "36px",
  },
  footer: {
    height: "32px",
  },
};

const button = {
  height: "32px !important",
  width: "120px !important",
  borderRadius: "4px",
};

const input = {
  heightArea: "80px !important",
  height: "32px !important",
  height28: "28px !important",
  width: "160px !important",
};

const inputCellTable = {
  height: "24px !important",
};

const inputDecrementIncrement = {
  height: "32px",
  width: "40px",
};

const select = {
  height: "32px",
  height28: "28px",
  width: "160px",
  borderRadius: "4px",
  valueContent: {
    height: "30px",
  },
};

const filterTable = {
  padding: "12px 16px",
};

const cardHeader = {
  height: "40px",
  borderRadius: "8px",
  cardHeaderItem: {
    padding: "6px 16px",
  },
};

const headerButton = {
  height: "24px",
};

export const getThemes = ({ screen }: IThemes) => {
  const tempTypeFaces = screen ? typeFaces[screen] : typeFaces[ScreenType.LG];

  const tempThemes: { [key in ThemeType]: ThemeData } = {
    [ThemeType.BLACK]: {
      typeFaces: tempTypeFaces,
      tables,
      button,
      input,
      inputCellTable,
      inputDecrementIncrement,
      select,
      filterTable,
      cardHeader,
      headerButton,
      zIndex: {
        tooltip: 1500,
        modal: 999,
        popup: 9999,
      },

      fontFamily: "Roboto",

      // Mau VGAIA
      brandPrimary: "#29ABE2",
      brandSecondary: "#0071BC",

      backgroundFixed: "#000000",
      background1: "#0B0C0C",
      background2: "#171B1B",
      background3: "#232727",
      background4: "#2E3434",

      grey0: "#FFFFFF",
      grey5: "#E6EDF8",
      grey10: "#D6E0EC",
      grey20: "#BEC7D3",
      grey30: "#A5ADB8",
      grey40: "#8B929D",
      grey50: "#747A85",
      grey60: "#464A53",
      grey70: "#2B3030",
      grey80: "#1F2125",
      grey90: "#15171B",
      grey100: "#0E1013",

      green10: "#EFFFF1",
      green20: "#BEF2C0",
      green50: "#1DBB75",
      green60: "#109B58",

      red10: "#FFEDE9",
      red20: "#FFC8C5",
      red50: "#F04B41",
      red60: "#C73F37",

      redR1: "#FE000E",

      violet10: "#FEE3FF",
      violet20: "#FFC5FF",
      violet50: "#DE20DF",
      violet60: "#AE0CAF",

      blue10: "#E8F2FF",
      blue20: "#C0E1FF",
      blue50: "#0EB7FF",
      blue60: "#009FD1",

      yellow10: "#FFFBD9",
      yellow20: "#FFFBA0",
      yellow50: "#EDE51D",
      yellow60: "#DFC900",

      border1: "#394452",
      border2: "#2C3541",

      solidBlue: "#0EB7FF",
      solidYellow: "#DFC900",
      solidRed: "#F04B41",
      solidGreen: "#1DBB75",
      solidGrey: "#787C82",
      solidOrange: "#F39E50",
      solidViolet: "#BE15DE",
      solidWhite: "#FFFFFF",
      solidNavy: "#003585",
      solidFlash: "#5B6161",

      graphBlue: "#25D2EA",
      graphYellow: "#EDE51D",
      graphRed: "#EC3052",
      graphGreen: "#0FBD6A",
      graphViolet: "#ED1DFF",

      textColorBlue: "#0EB7FF",
      textColorYellow: "#EDE51D",
      textColorRed: "#F04B41",
      textColorGreen: "#1DBB75",
      textColorViolet: "#DE20DF",
      textcolorWhite: "#FFFFFF",
      textcolorBlack: "#000000",

      overlayBlue: "rgba(37, 210, 234, 0.6)",
      overlayYellow: "rgba(237, 229, 29, 0.6)",
      overlayRed: "rgba(236, 48, 82, 0.6)",
      overlayGreen: "rgba(15, 189, 106, 0.6)",
      overlayViolet: "rgba(237, 29, 255, 0.6)",
      overlayGray: "rgba(31, 33, 37, 0.8)",
      overlayBlack: "rgba(0, 0, 0, 0.5)",
      overlayBlue30: "rgba(37, 210, 234, 0.3)",

      gradientBlue:
        "linear-gradient(180deg, rgba(48, 185, 243, 0.2) 0%, rgba(48, 185, 243, 0) 100%)",
      gradientYellow:
        "linear-gradient(180deg, rgba(237, 229, 29, 0.2) 0%, rgba(237, 229, 29, 0) 100%)",
      gradientRed:
        "linear-gradient(180deg, rgba(255, 65, 65, 0.2) 0%, rgba(255, 65, 65, 0) 100%)",
      gradientGreen:
        "linear-gradient(180deg, rgba(0, 211, 135, 0.2) 0%, rgba(0, 211, 135, 0) 100%)",
      gradientViolet:
        "linear-gradient(180deg, rgba(222, 32, 223, 0.2) 0%, rgba(222, 32, 223, 0) 100%)",

      linearGradientBlue:
        "linear-gradient(rgba(34, 43, 54, 0.0001),rgba(65, 188, 228, 0.2))",
      linearGradientGreen:
        "linear-gradient(rgba(0, 211, 135, 0),rgba(0, 211, 135, 0.2))",

      shadowInnerDown: "inset 0px -1px 0px #353535",
      shadowInnerDivider: "inset 0px -0.5px 0px #243142",
      shadowInnerGreenDown: "inset 0px -2px 0px #0EB7FF",
      shadowInnerGreenUp: "inset 0px 2px 0px #0EB7FF",

      shadowLineDown: "inset 0px -1px 0px #394452",
      shadowLineUp: "inset 0px 1px 0px #394452",

      shadowDown: "inset 1px 4px 10px rgba(0, 0, 0, 0.15)",
      shadowUp: "inset 0px -4px 15px rgba(0, 0, 0, 0.08)",
      shadowRight: "inset -1px 0px 0px rgba(40, 46, 39, 0.5)",

      buttonGradientColor: "linear-gradient(90deg, #009FE2 0%, #004FC7 100%)",

      borderDivider: "#424949",
    },
    [ThemeType.LIGHT]: {
      typeFaces: tempTypeFaces,
      tables,
      button,
      input,
      inputCellTable,
      inputDecrementIncrement,
      select,
      filterTable,
      cardHeader,
      headerButton,
      zIndex: {
        tooltip: 1500,
        modal: 999,
        popup: 9999,
      },

      fontFamily: "Roboto",

      // Mau VGAIA
      brandPrimary: "#29ABE2",
      brandSecondary: "#0071BC",

      backgroundFixed: "#000000",
      background1: "#0B0C0C",
      background2: "#171B1B",
      background3: "#232727",
      background4: "#2E3434",

      grey0: "#FFFFFF",
      grey5: "#E6EDF8",
      grey10: "#D6E0EC",
      grey20: "#BEC7D3",
      grey30: "#A5ADB8",
      grey40: "#8B929D",
      grey50: "#747A85",
      grey60: "#464A53",
      grey70: "#2B3030",
      grey80: "#1F2125",
      grey90: "#15171B",
      grey100: "#0E1013",

      green10: "#EFFFF1",
      green20: "#BEF2C0",
      green50: "#1DBB75",
      green60: "#109B58",

      red10: "#FFEDE9",
      red20: "#FFC8C5",
      red50: "#F04B41",
      red60: "#C73F37",

      redR1: "#FE000E",

      violet10: "#FEE3FF",
      violet20: "#FFC5FF",
      violet50: "#DE20DF",
      violet60: "#AE0CAF",

      blue10: "#E8F2FF",
      blue20: "#C0E1FF",
      blue50: "#0EB7FF",
      blue60: "#009FD1",

      yellow10: "#FFFBD9",
      yellow20: "#FFFBA0",
      yellow50: "#EDE51D",
      yellow60: "#DFC900",

      border1: "#394452",
      border2: "#2C3541",

      solidBlue: "#0EB7FF",
      solidYellow: "#DFC900",
      solidRed: "#F04B41",
      solidGreen: "#1DBB75",
      solidGrey: "#787C82",
      solidOrange: "#F39E50",
      solidViolet: "#BE15DE",
      solidWhite: "#FFFFFF",
      solidNavy: "#003585",
      solidFlash: "#5B6161",

      graphBlue: "#25D2EA",
      graphYellow: "#EDE51D",
      graphRed: "#EC3052",
      graphGreen: "#0FBD6A",
      graphViolet: "#ED1DFF",

      textColorBlue: "#0EB7FF",
      textColorYellow: "#EDE51D",
      textColorRed: "#F04B41",
      textColorGreen: "#1DBB75",
      textColorViolet: "#DE20DF",
      textcolorWhite: "#FFFFFF",
      textcolorBlack: "#000000",

      overlayBlue: "rgba(37, 210, 234, 0.6)",
      overlayYellow: "rgba(237, 229, 29, 0.6)",
      overlayRed: "rgba(236, 48, 82, 0.6)",
      overlayGreen: "rgba(15, 189, 106, 0.6)",
      overlayViolet: "rgba(237, 29, 255, 0.6)",
      overlayGray: "rgba(31, 33, 37, 0.8)",
      overlayBlack: "rgba(0, 0, 0, 0.5)",
      overlayBlue30: "rgba(37, 210, 234, 0.3)",

      gradientBlue:
        "linear-gradient(180deg, rgba(48, 185, 243, 0.2) 0%, rgba(48, 185, 243, 0) 100%)",
      gradientYellow:
        "linear-gradient(180deg, rgba(237, 229, 29, 0.2) 0%, rgba(237, 229, 29, 0) 100%)",
      gradientRed:
        "linear-gradient(180deg, rgba(255, 65, 65, 0.2) 0%, rgba(255, 65, 65, 0) 100%)",
      gradientGreen:
        "linear-gradient(180deg, rgba(0, 211, 135, 0.2) 0%, rgba(0, 211, 135, 0) 100%)",
      gradientViolet:
        "linear-gradient(180deg, rgba(222, 32, 223, 0.2) 0%, rgba(222, 32, 223, 0) 100%)",

      linearGradientBlue:
        "linear-gradient(rgba(34, 43, 54, 0.0001),rgba(65, 188, 228, 0.2))",
      linearGradientGreen:
        "linear-gradient(rgba(0, 211, 135, 0),rgba(0, 211, 135, 0.2))",

      shadowInnerDown: "inset 0px -1px 0px #353535",
      shadowInnerDivider: "inset 0px -0.5px 0px #243142",
      shadowInnerGreenDown: "inset 0px -2px 0px #0EB7FF",
      shadowInnerGreenUp: "inset 0px 2px 0px #0EB7FF",

      shadowLineDown: "inset 0px -1px 0px #394452",
      shadowLineUp: "inset 0px 1px 0px #394452",

      shadowDown: "inset 1px 4px 10px rgba(0, 0, 0, 0.15)",
      shadowUp: "inset 0px -4px 15px rgba(0, 0, 0, 0.08)",
      shadowRight: "inset -1px 0px 0px rgba(40, 46, 39, 0.5)",

      buttonGradientColor: "linear-gradient(90deg, #009FE2 0%, #004FC7 100%)",

      borderDivider: "#424949",
    },
  };

  return tempThemes;
};

export default getThemes({});
