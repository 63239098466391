import { TRANSFER_LOCAL_TABS } from "modules/transfer-local/constants";
import { activeTabSelector } from "modules/transfer-local/selectors";
import { updateActiveTab } from "modules/transfer-local/redux";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Wrap } from "./styles";

const Tabs: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSelector);
  return (
    <Wrap>
      <div className="header-left">
        <Tab
          isActive={activeTab === TRANSFER_LOCAL_TABS.TRANSFER_LOCAL}
          onClick={() =>
            dispatch(updateActiveTab(TRANSFER_LOCAL_TABS.TRANSFER_LOCAL))
          }
        >
          {intl.formatMessage({
            id: "transferLocal.tabs.transferRequest",
          })}
        </Tab>
        <Tab
          isActive={activeTab === TRANSFER_LOCAL_TABS.TRANSFER_LOCAL_HIS}
          onClick={() =>
            dispatch(updateActiveTab(TRANSFER_LOCAL_TABS.TRANSFER_LOCAL_HIS))
          }
        >
          {intl.formatMessage({
            id: "transferLocal.tabs.transferHistory",
          })}
        </Tab>
      </div>
    </Wrap>
  );
};
export default Tabs;
