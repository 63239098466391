/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DetailRow,
  DetailLabel,
  DetailCheckbox,
  DetailInput,
  WrapLabel,
  Container,
  WrapBtn,
  WrapForm,
} from "./style";
import CheckboxTransfer from "./CheckboxTransfer";
import { Button, Input, Select } from "components/commons";
import { dataChannelId, dataCustype, dataForegin } from "./constants";
import { useSelector, useDispatch } from "react-redux";
import {
  updateFormSubmit,
  updateParams,
  updateStep,
} from "modules/auth/redux/modalRegister";
import { STEPS } from "../../constants";
import {
  selectDataCreateUser,
  selectValueFormsubmit,
} from "modules/auth/selectorsModalRegister";
import { toast } from "components/commons/Toast";
import {
  findBrokerListApi,
  findTransactionListApi,
  getTransactionListApi,
} from "core/http/apis/modal-register";
import { ModalConfirm } from "components/commons";
import { setvisibleModalRegister } from "modules/auth/redux";
import { resetRegister } from "modules/auth/redux/modalRegister";

const Step1 = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataCreate = useSelector(selectDataCreateUser);
  const {
    custType,
    foreignType,
    mobileNoSt1,
    adrs,
    channelId,
    branchSt1,
    transactionPointSt1,
    brokerCd,
  } = useSelector(selectValueFormsubmit);

  const handleOpenModalRegister = () => {
    dispatch(resetRegister());
    dispatch(setvisibleModalRegister());
  };

  const statusChannelId = useMemo(() => dataChannelId(intl), [intl]);
  const dataCustypes = useMemo(() => dataCustype(intl), [intl]);
  const dataForegins = useMemo(() => dataForegin(intl), [intl]);

  const disabled_custType = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "DISABLED_CUST_TYPE_2"
      )
    : {};

  const disabled_foreign = dataCreate?.paramList
    ? dataCreate?.paramList.find(
        (item: any) => item?.paramName === "DISABLED_FOREIGN_TYPE_2"
      )
    : {};

  const [branch, setBranch] = useState([]);
  const getBranch = async () => {
    const res: any = await findTransactionListApi();
    if (res?.statusCode === 0) {
      const data = res?.data
        ? res?.data.filter((e: any) => e?.locationName !== null)
        : [];
      const covertData = data.reduce((acc: any, p: any, i: any) => {
        const isDuplicate =
          i !== data.findIndex((e: any) => e?.locationCd === p.locationCd);
        if (isDuplicate) {
          const wasAlreadyMet = acc.some(
            (e: any) => e?.locationCd === p.locationCd
          );
          if (!wasAlreadyMet) {
            acc.push(p);
          }
        } else {
          acc.push(p);
        }
        return acc;
      }, []);
      setBranch(covertData);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    getBranch();
  }, []);

  const [transactionPoint, setTransactionPoint] = useState<any[]>([]);

  const onChangeBranch = async (branchCd: any) => {
    const res: any = await getTransactionListApi({
      branchLocaltionCd: branchCd,
    });
    if (res?.statusCode === 0) {
      const data = res?.data
        ? res?.data.filter((e: any) => e?.openCustDisplay === 1)
        : [];
      setTransactionPoint(data);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    if (branchSt1 !== "") {
      onChangeBranch(branchSt1);
    }
  }, [branch, branchSt1]);

  useEffect(() => {
    if (transactionPointSt1?.transactionCd !== "") {
      onChangeAddress(transactionPointSt1);
    }
  }, [transactionPointSt1, transactionPoint]);

  const [brokerList, setbrokerList] = useState<any[]>([]);

  const onChangeAddress = async (user: any) => {
    dispatch(updateFormSubmit({ value: user?.branchAdrs1, key: "adrs" }));
    dispatch(updateFormSubmit({ value: user?.telNo, key: "mobileNoSt1" }));
    dispatch(updateFormSubmit({ value: user, key: "transactionPointSt1" }));
    const res: any = await findBrokerListApi({
      transactionCd: user?.transactionCd,
    });

    if (res?.statusCode === 0) {
      const data: any[] = res?.data
        ? res?.data.map((e: any) => ({
            label: `${e?.fullName} - ${e?.brokerCd}`,
            value: e?.brokerCd,
            employeeType: e?.employeeType,
            brokerCd: e?.brokerCd,
          }))
        : [];
      setbrokerList([
        {
          value: "-1",
          label: intl.formatMessage({
            id: "auth.register.step1.optionBroker.valueAll.label",
          }),
        },
        ...data,
      ]);
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const valueChannelId = useMemo(() => {
    return statusChannelId.filter((e) => e.value === channelId);
  }, [channelId]);

  const valueBranch = useMemo(() => {
    return branch.filter((e: any) => e?.locationCd === branchSt1);
  }, [branchSt1, branch]);

  const valueTransactionPointSt1 = useMemo(() => {
    return transactionPoint.filter(
      (e: any) => e?.transactionCd === transactionPointSt1?.transactionCd
    );
  }, [transactionPointSt1, transactionPoint]);

  const valuebroker = useMemo(() => {
    return brokerList.filter((e: any) => e?.value === brokerCd);
  }, [brokerCd, brokerList]);

  const updateForm = (value: any, key: string) => {
    dispatch(updateFormSubmit({ value, key }));
  };

  const [visible, setVisible] = useState<boolean>(false);

  const [error, setError] = useState<string | undefined>("");

  const checkError = () => {
    const param: any = {
      custType,
      foreignType,
      mobileNoSt1,
      adrs,
      channelId,
      branchSt1,
    };
    const value = Object.keys(param).find(
      (key) => param[key] === "" || param[key] === null
    );

    if (value) {
      setError(value);
    } else {
      if (transactionPointSt1?.transactionCd === "") {
        setError("transactionPointSt1");
      } else {
        setError("");
        setVisible(true);
      }
    }
  };

  const nextStep = () => {
    const checkBrokerCd = brokerList.find((e: any) => e?.value === brokerCd);
    const param = {
      custType,
      foreignType,
      channelId,
      introducerCd:
        checkBrokerCd?.employeeType === 3 ? checkBrokerCd?.brokerCd : "",
      brokerCd:
        checkBrokerCd?.employeeType !== 3 ? checkBrokerCd?.brokerCd : "",
    };
    dispatch(updateParams(param));
    dispatch(updateStep(STEPS.STEPS_2));
  };

  return (
    <>
      <Container>
        <WrapForm>
          <DetailRow className="mb-0">
            <DetailLabel>
              <FormattedMessage id="auth.register.step1.question1" />
              <span className="text-danger pl-1 mb-0">*</span>
            </DetailLabel>
            <DetailCheckbox className="text-left">
              <CheckboxTransfer
                checked={custType === dataCustypes[0].value ? true : false}
                disabled={false}
                values={dataCustypes[0].value}
                onClick={(e) => {
                  dispatch(updateFormSubmit({ value: e, key: "custType" }));
                }}
                text={dataCustypes[0].label}
              />
              {disabled_custType?.value === "false" && (
                <CheckboxTransfer
                  checked={custType === dataCustypes[1].value ? true : false}
                  disabled={false}
                  values={dataCustypes[1].value}
                  onClick={(e) => {
                    dispatch(updateFormSubmit({ value: e, key: "custType" }));
                  }}
                  text={dataCustypes[1].label}
                />
              )}
            </DetailCheckbox>
          </DetailRow>
          {error === "custType" && (
            <DetailRow>
              <DetailLabel></DetailLabel>
              <DetailInput className="p-0 w-50 justify-content-end">
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              </DetailInput>
            </DetailRow>
          )}

          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="auth.register.step1.question2" />
              <span className="text-danger pl-1 mb-0">*</span>
            </DetailLabel>
            <DetailCheckbox className="text-left">
              <CheckboxTransfer
                checked={foreignType === dataForegins[0].value ? true : false}
                disabled={false}
                values={dataForegins[0].value}
                onClick={(e) => {
                  dispatch(updateFormSubmit({ value: e, key: "foreignType" }));
                }}
                text={dataForegins[0].label}
              />
              {disabled_foreign?.value === "false" && (
                <CheckboxTransfer
                  checked={foreignType === dataForegins[1].value ? true : false}
                  disabled={false}
                  values={dataForegins[1].value}
                  onClick={(e) => {
                    dispatch(
                      updateFormSubmit({ value: e, key: "foreignType" })
                    );
                  }}
                  text={dataForegins[1].label}
                />
              )}
            </DetailCheckbox>
          </DetailRow>

          {error === "foreignType" && (
            <DetailRow>
              <DetailLabel></DetailLabel>
              <DetailInput className="p-0 w-50 justify-content-end">
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              </DetailInput>
            </DetailRow>
          )}

          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="auth.register.step1.question3" />
              <span className="text-danger pl-1 mb-0">*</span>
            </DetailLabel>
            <DetailInput>
              <Select
                className="w-50"
                options={statusChannelId}
                value={valueChannelId}
                onChange={(e: any) => updateForm(e?.value, "channelId")}
              />
            </DetailInput>
          </DetailRow>

          {error === "channelId" && (
            <DetailRow>
              <DetailLabel></DetailLabel>
              <DetailInput className="p-0 w-50 justify-content-end">
                <span className="text-error pt-1 text-danger float-right">
                  {intl.formatMessage({
                    id: "auth.register.messageErrorValidate",
                  })}
                </span>
              </DetailInput>
            </DetailRow>
          )}

          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="auth.register.step1.question4" />
            </DetailLabel>
            <DetailInput>
              <div className="w-50">
                <WrapLabel>
                  <FormattedMessage id="auth.register.step1.answer4.labelBranch" />
                  <span className="text-danger pl-1 mb-0">*</span>
                </WrapLabel>
                <Select
                  options={branch}
                  getLabel={"locationName"}
                  getValue={"locationCd"}
                  onChange={(e: any) => {
                    updateForm(e?.locationCd, "branchSt1");
                    onChangeBranch(e?.locationCd);
                  }}
                  value={valueBranch}
                />
                {error === "branchSt1" && (
                  <DetailRow>
                    <DetailLabel></DetailLabel>
                    <DetailInput className="p-0 w-100 justify-content-end">
                      <span className="text-error pt-1 text-danger float-right">
                        {intl.formatMessage({
                          id: "auth.register.messageErrorValidate",
                        })}
                      </span>
                    </DetailInput>
                  </DetailRow>
                )}
              </div>
              <div className="w-50">
                <WrapLabel>
                  <FormattedMessage id="auth.register.step1.answer4.transactionPoint" />
                  <span className="text-danger pl-1 mb-0">*</span>
                </WrapLabel>
                <Select
                  options={transactionPoint}
                  getLabel={"branchName"}
                  getValue={"transactionCd"}
                  onChange={(e: any) => {
                    updateForm(e?.transactionCd, "transactionPointSt1");
                    onChangeAddress(e);
                    dispatch(updateParams({ transactionCd: e?.transactionCd }));
                  }}
                  value={valueTransactionPointSt1}
                />
                {error === "transactionPointSt1" && (
                  <DetailRow>
                    <DetailLabel></DetailLabel>
                    <DetailInput className="p-0 w-100 justify-content-end">
                      <span className="text-error pt-1 text-danger float-right">
                        {intl.formatMessage({
                          id: "auth.register.messageErrorValidate",
                        })}
                      </span>
                    </DetailInput>
                  </DetailRow>
                )}
              </div>
            </DetailInput>

            <DetailInput className="pt-0">
              <div className="w-50">
                <WrapLabel>
                  <FormattedMessage id="auth.register.step1.answer4.address" />
                </WrapLabel>
                <Input value={adrs} disabled />
              </div>
              <div className="w-50">
                <WrapLabel>
                  <FormattedMessage id="auth.register.step1.answer4.phone" />
                </WrapLabel>
                <Input value={mobileNoSt1} disabled />
              </div>
            </DetailInput>
          </DetailRow>

          <DetailRow>
            <DetailLabel>
              <FormattedMessage id="auth.register.step1.question5" />
            </DetailLabel>
            <DetailInput>
              <Select
                className="w-50"
                options={brokerList}
                onChange={(e) => updateForm(e?.value, "brokerCd")}
                value={valuebroker}
              />
            </DetailInput>
          </DetailRow>
        </WrapForm>

        <WrapBtn className="d-flex justify-content-end">
          <Button
            color="accept"
            className="button-filter mr-2"
            width="120px"
            onClick={() => handleOpenModalRegister()}
          >
            {intl.formatMessage({
              id: "auth.register.btnPrev",
            })}
          </Button>
          <Button
            color="gradientaAccept"
            className="button-filter"
            width="120px"
            onClick={() => checkError()}
          >
            {intl.formatMessage({
              id: "auth.register.btnNext",
            })}
          </Button>
        </WrapBtn>
      </Container>
      <ModalConfirm
        visibleModalConfirm={visible}
        setVisibleModalConfirm={setVisible}
        setConfirm={() => nextStep()}
        title={intl.formatMessage({
          id: "auth.register.step1.modal.title",
        })}
        content={intl.formatMessage({
          id: "auth.register.step1.modal.content",
        })}
      />
    </>
  );
};

export default Step1;
