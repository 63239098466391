import HttpClient from "../../index";
import { REACT_APP_RESTFUL_ARTICLE_URL } from "AppConfig";

export default class ArticleServiceHttp extends HttpClient {
  private static classInstance?: ArticleServiceHttp;
  private constructor() {
    super(REACT_APP_RESTFUL_ARTICLE_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ArticleServiceHttp();
    }

    return this.classInstance;
  }
}
