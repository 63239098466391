import { changeActiveTab } from "modules/market/redux/industry";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { MarketWatchWrapper } from "../MarketWatch/styles";
import Content from "./Content";
import Tabs from "./Tabs";
import { IndustryTab } from "./types";

const industrySelector = (state: RootState) => state.market.industry.root;

function Industry() {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(industrySelector);

  const handleChangeTab = (tab: IndustryTab) => {
    dispatch(changeActiveTab(tab));
  };

  return (
    <MarketWatchWrapper>
      <Tabs activeTab={activeTab} onChangeTabMarketWatch={handleChangeTab} />
      <Content activeTab={activeTab} />
    </MarketWatchWrapper>
  );
}

export default Industry;
