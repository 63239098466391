import {
  all,
  call,
  takeLeading,
  takeEvery,
  put,
  select,
  delay,
} from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import {
  sendOTPStart,
  setStepEkyc,
  setcounterOTPEkyc,
  setDownCounterOTPEkyc,
  updateVerifyOTPGlobalMindRequest,
  verifyOTPStart,
  sendFrontBackImageStart,
  sendEkycImageStart,
  updateImageRequest,
  updateAccountInfoRequest,
  getMsttProvinceStart,
  getMsttProvinceSuccess,
  checkBrokerIdStart,
  checkBrokerIdSuccess,
  checkCustodyCdStart,
  checkCustodyCdSuccess,
  postAccountInfoStart,
  getBankListSuccess,
  getBankListStart,
  postVerifyBankAccountStart,
  getCheckNumberPhoneEmailStart,
  setLoading,
} from "../redux/modalRegisterEkyc";
import {
  accountInfoRequestSelector,
  counterOTPEkycSelector,
  imageEkycSelector,
  postServiceRegistrationRequestSelector,
  registerBankRequestSelector,
  searchMsttProvinceSelector,
  sendOTPGlobalMindRequestSelector,
  verifyOTPGlobalMindRequestSelector,
} from "modules/system/selectors";
import {
  postAccountInfoGlobalMind,
  postRegisterBank,
  postServiceRegistrationGlobalMind,
  sendOTPGlobalMind,
  verifyOTPGlobalMind,
} from "core/http/apis/globalMind";
import {
  ItemRegisterBankRequest,
  postAccountInfoRequest,
  postAccountInfoResponse,
  postServiceRegistrationRequest,
  sendOTPGlobalMindRequest,
  sendOTPGlobalMindResponse,
  verifyOTPGlobalMindRequest,
  verifyOTPGlobalMindResponse,
} from "core/http/apis/globalMind/types";
import {
  ekycControllerOcrIdRequest,
  ekycControllerOcrIdResponse,
  FaceCompare,
  FaceLiveness,
  postEkycFaceCompareRequest,
  postEkycFaceCompareResponse,
  postEkycFaceLivenessRequest,
  postEkycFaceLivenessResponse,
  uploadImageEkycResponse,
} from "core/http/apis/ekyc/types";
import {
  ekycControllerOcrId,
  postEkycFaceCompare,
  postEkycFaceLiveness,
  uploadImageEkyc,
} from "core/http/apis/ekyc";
import { v4 as uuid } from "uuid";
import {
  getBankList,
  getCheckBrokerId,
  getCheckCustodycd,
  getCheckIdCode,
  getCheckNumberPhone,
  getCheckEmail,
  postVerifyBankAccount,
} from "core/http/apis/globalMindGateway";
import {
  BankListResponse,
  CheckBrokerIdResponse,
  CheckCustodyCdResponse,
  CheckEmailResponse,
  CheckIdCodeResponse,
  CheckNumberPhoneResponse,
  verifyBankAccountRequest,
  verifyBankAccountResponse,
} from "core/http/apis/globalMindGateway/types";
import moment from "moment";
import {
  msttProvinceRequest,
  msttProvinceResponse,
} from "core/http/apis/api/types";
import { getMsttProvince } from "core/http/apis/api";

function* checkNumberPhoneEmailSaga() {
  try {
    const { phone, email } = yield select(sendOTPGlobalMindRequestSelector);

    const resCheckNumberPhone: CheckNumberPhoneResponse = yield call(
      getCheckNumberPhone,
      phone
    );
    if (resCheckNumberPhone.data) {
      return toast("ekyc.step2.resCheckNumberPhone", "error");
    }

    const resCheckEmail: CheckEmailResponse = yield call(getCheckEmail, email);
    if (resCheckEmail.data) {
      return toast("ekyc.step2.resCheckEmail", "error");
    }

    yield put(sendOTPStart());
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* sendOTPSaga() {
  try {
    const params: sendOTPGlobalMindRequest = yield select(
      sendOTPGlobalMindRequestSelector
    );

    const res: sendOTPGlobalMindResponse = yield call(
      sendOTPGlobalMind,
      params
    );
    yield put(setStepEkyc(3));
    yield put(setcounterOTPEkyc(90));
    yield put(setDownCounterOTPEkyc());
    toast(res.data.confirmCode, "error");
    yield put(
      updateVerifyOTPGlobalMindRequest({ confirmId: res.data.confirmId })
    );
    yield put(updateAccountInfoRequest({ otpId: res.data.confirmId }));
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* setcounterOTPEkycSaga() {
  try {
    yield delay(1000);
    const time: number = yield select(counterOTPEkycSelector);
    if (time === 0) return;
    yield put(setDownCounterOTPEkyc());
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function* verifyOTPSaga() {
  try {
    const params: verifyOTPGlobalMindRequest = yield select(
      verifyOTPGlobalMindRequestSelector
    );

    const res: verifyOTPGlobalMindResponse = yield call(
      verifyOTPGlobalMind,
      params
    );
    yield put(setLoading(false));
    if (res.code === 2) {
      return toast("ekyc.step2.otpFalse", "error");
    }
    if (res.code === 0) {
      yield put(setStepEkyc(4));
    }
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* sendFrontBackImageSaga() {
  try {
    const { cmndFront, cmndBack } = yield select(imageEkycSelector);
    let formDataCmndFront = new FormData();
    formDataCmndFront.append("image", cmndFront);
    const resCmndFront: uploadImageEkycResponse = yield call(
      uploadImageEkyc,
      formDataCmndFront
    );

    let formDatacmndBack = new FormData();
    formDatacmndBack.append("image", cmndBack);
    const resCmndBack: uploadImageEkycResponse = yield call(
      uploadImageEkyc,
      formDatacmndBack
    );

    yield put(
      updateImageRequest({
        hashCmndFront: resCmndFront.data.hash,
        hashCmndBack: resCmndBack.data.hash,
      })
    );

    const request: ekycControllerOcrIdRequest = {
      frontImage: resCmndFront.data.hash,
      clientSession: uuid(),
      backImage: resCmndBack.data.hash,
      type: "-1",
    };

    const resEkycControl: ekycControllerOcrIdResponse = yield call(
      ekycControllerOcrId,
      request
    );

    const resCheckIdCode: CheckIdCodeResponse = yield call(
      getCheckIdCode,
      resEkycControl.data.id
    );
    yield put(setLoading(false));
    if (resCheckIdCode.data) {
      return toast("ekyc.step4.resCheckIdCode", "error");
    }
    if (
      moment(resEkycControl.data.valid_date, "DD/MM/YYYY").diff(
        moment(),
        "days"
      ) < 0
    ) {
      return toast("ekyc.step4.valid_date", "error");
    }

    if (
      moment().diff(
        moment(resEkycControl.data.birth_day, "DD/MM/YYYY"),
        "years"
      ) < 18
    ) {
      return toast("ekyc.step4.birth_day", "error");
    }

    const { phone, email } = yield select(sendOTPGlobalMindRequestSelector);

    let idExpiredDate: Date | undefined = undefined;
    if (resEkycControl?.data?.card_type === "GIẤY CHỨNG MINH NHÂN DÂN") {
      //#18919 với loại cmt ngày hết hạn = ngày đăng ký + 15 năm
      idExpiredDate = new Date(
        moment(resEkycControl.data.issue_date, "DD/MM/YYYY")
          .add(15, "years")
          .calendar()
      );
    } else {
      idExpiredDate = moment(
        resEkycControl.data.valid_date,
        "DD/MM/YYYY"
      ).isValid()
        ? new Date(
            moment(resEkycControl.data.valid_date, "DD/MM/YYYY").calendar()
          )
        : undefined;
    }

    let gender = "";
    if (resEkycControl.data.gender === "Nam") {
      gender = "0";
    }
    if (resEkycControl.data.gender === "Nữ") {
      gender = "1";
    }
    yield put(
      updateAccountInfoRequest({
        fullName: resEkycControl.data.name,
        gender,
        dob: moment(resEkycControl.data.birth_day, "DD/MM/YYYY").isValid()
          ? new Date(
              moment(resEkycControl.data.birth_day, "DD/MM/YYYY").calendar()
            )
          : undefined,
        cardId: resEkycControl.data.id,
        cardType: resEkycControl.data.card_type,
        issueDate: moment(
          resEkycControl.data.issue_date,
          "DD/MM/YYYY"
        ).isValid()
          ? new Date(
              moment(resEkycControl.data.issue_date, "DD/MM/YYYY").calendar()
            )
          : undefined,
        idExpiredDate,
        issuePlace: resEkycControl.data.issue_place,
        phone,
        email,
        address: resEkycControl.data.origin_location,
        contactAddress: resEkycControl.data.recent_location,
      })
    );

    yield put(setStepEkyc(5));
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* sendEkycImageSaga() {
  try {
    const { ekyc, hashCmndFront } = yield select(imageEkycSelector);
    let formDataEkyc = new FormData();
    formDataEkyc.append("image", ekyc);
    const resImageEkyc: uploadImageEkycResponse = yield call(
      uploadImageEkyc,
      formDataEkyc
    );

    const faceLivenessRequest: postEkycFaceLivenessRequest = {
      image: resImageEkyc.data.hash,
      clientSession: uuid(),
    };

    const resFaceLiveness: postEkycFaceLivenessResponse = yield call(
      postEkycFaceLiveness,
      faceLivenessRequest
    );

    if (resFaceLiveness.data.liveness === FaceLiveness.FAILURE) {
      yield put(setLoading(false));
      return toast("ekyc.step5.resFaceLiveness", "error");
    }

    const faceCompareRequest: postEkycFaceCompareRequest = {
      faceImage: resImageEkyc.data.hash,
      frontImage: hashCmndFront,
      clientSession: uuid(),
    };

    const resFaceCompare: postEkycFaceCompareResponse = yield call(
      postEkycFaceCompare,
      faceCompareRequest
    );
    if (resFaceCompare.data.msg === FaceCompare.NOMATCH) {
      yield put(setLoading(false));
      return toast("ekyc.step5.resFaceCompare", "error");
    }
    yield put(setStepEkyc(6));
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* getMsttProvinceSaga() {
  try {
    const params: msttProvinceRequest = yield select(
      searchMsttProvinceSelector
    );

    const res: msttProvinceResponse = yield call(getMsttProvince, params);

    yield put(getMsttProvinceSuccess(res.data));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function* checkBrokerIdSaga() {
  try {
    const { brokerId } = yield select(accountInfoRequestSelector);
    const res: CheckBrokerIdResponse = yield call(getCheckBrokerId, brokerId);

    if (!res.data) {
      return toast("ekyc.step10.brokerIdErr", "error");
    }

    yield put(checkBrokerIdSuccess(res));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function* checkCustodyCdSaga() {
  try {
    const { custodyCD } = yield select(postServiceRegistrationRequestSelector);
    const res: CheckCustodyCdResponse = yield call(
      getCheckCustodycd,
      custodyCD
    );

    if (res.data) {
      toast("ekyc.step10.custodyCDDupli", "error");
    }
    yield put(setLoading(false));
    yield put(checkCustodyCdSuccess(res));
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* postAccountInfoSaga() {
  try {
    const bodyAccountInfoRequest: postAccountInfoRequest = yield select(
      accountInfoRequestSelector
    );
    const resAccountInfo: postAccountInfoResponse = yield call(
      postAccountInfoGlobalMind,
      {
        ...bodyAccountInfoRequest,
        dob: moment(bodyAccountInfoRequest.dob).format("DD/MM/YYYY"),
        issueDate: moment(bodyAccountInfoRequest.issueDate).format(
          "DD/MM/YYYY"
        ),
        validDate: moment(bodyAccountInfoRequest.issueDate).format(
          "DD/MM/YYYY"
        ),
        idExpiredDate: moment(bodyAccountInfoRequest.idExpiredDate).format(
          "DD/MM/YYYY"
        ),
      }
    );
    yield put(setStepEkyc(13));

    //đăng ký dịch vụ (tương ứng màn Đăng ký dịch vụ)
    const bodyServiceRegistrationRequest: postServiceRegistrationRequest =
      yield select(postServiceRegistrationRequestSelector);
    yield call(postServiceRegistrationGlobalMind, {
      ...bodyServiceRegistrationRequest,
      accountId: resAccountInfo.data.id,
    });
    //đăng ký tài khoản ngân hàng
    const registerBankRequest: ItemRegisterBankRequest[] = yield select(
      registerBankRequestSelector
    );
    if (registerBankRequest.length > 0) {
      const bodyRegisterBank: ItemRegisterBankRequest[] =
        registerBankRequest.map((item) => {
          return {
            ...item,
            id: uuid(),
            accountId: resAccountInfo.data.id,
            bankAccountName: bodyAccountInfoRequest.fullName,
          };
        });

      yield call(postRegisterBank, bodyRegisterBank);
    }
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

function* getBankListSaga() {
  try {
    const res: BankListResponse = yield call(getBankList);
    yield put(getBankListSuccess(res.data));
  } catch (error: any) {
    toast(error.code, "error", error.description, true);
  }
}

function* verifyBankAccountSaga() {
  try {
    const registerBankRequest: ItemRegisterBankRequest[] = yield select(
      registerBankRequestSelector
    );
    const { fullName } = yield select(accountInfoRequestSelector);
    let checkValid = true;

    for (let item of registerBankRequest) {
      const body: verifyBankAccountRequest = {
        cityBank: item.cityBank,
        bankName: item.bankName,
        bankAccountno: item.bankAccountNo,
        bankAccountName: fullName,
      };
      const res: verifyBankAccountResponse = yield call(
        postVerifyBankAccount,
        body
      );
      if (res?.data !== "true") {
        yield put(setLoading(false));
        checkValid = false;
        return toast(`Tài khoản ${item.bankAccountNo} không đúng`, "error");
      }
    }

    if (checkValid) {
      yield put(setStepEkyc(9));
    }
  } catch (error: any) {
    yield put(setLoading(false));
    toast(error.code, "error", error.description, true);
  }
}

export default function* modalRegisterEkycSaga() {
  yield all([
    takeLeading(getCheckNumberPhoneEmailStart.type, checkNumberPhoneEmailSaga),
    takeLeading(sendOTPStart.type, sendOTPSaga),
    takeEvery(setDownCounterOTPEkyc.type, setcounterOTPEkycSaga),
    takeLeading(verifyOTPStart.type, verifyOTPSaga),
    takeLeading(sendFrontBackImageStart.type, sendFrontBackImageSaga),
    takeLeading(sendEkycImageStart.type, sendEkycImageSaga),
    takeLeading(getMsttProvinceStart.type, getMsttProvinceSaga),
    takeLeading(checkBrokerIdStart.type, checkBrokerIdSaga),
    takeLeading(checkCustodyCdStart.type, checkCustodyCdSaga),
    takeLeading(postAccountInfoStart.type, postAccountInfoSaga),
    takeLeading(getBankListStart.type, getBankListSaga),
    takeLeading(postVerifyBankAccountStart.type, verifyBankAccountSaga),
  ]);
}
