import styled from "styled-components/macro";
import ForgetPicture from "assets/image/svg/Rectangle1.svg";
export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.background2};
  display: flex;
  .text-error {
    color: ${({ theme }) => theme.solidRed};
  }
  .text-grey {
    font-weight: 400;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: 20px;
    color: ${({ theme }) => theme.grey20} !important;
    margin-bottom: 5px;
    .d-flex {
      font-weight: 400;
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: 20px;
      color: ${({ theme }) => theme.grey20} !important;
      margin-bottom: 5px;
    }

    .text-white .input {
      color: ${({ theme }) => theme.grey0};
    }
    .text-white .input-container {
      background: ${({ theme }) => theme.grey100};
    }
  }
`;

export const ContentLeft = styled.div`
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.brandSecondary};
  position: relative;
`;
export const BackgroundImg = styled.div`
  width: 100%;
  height: 50%;
  background: url(${ForgetPicture}) no-repeat;
  background-position: center;
`;
export const Logo = styled.div`
  position: absolute;
  top: 1.4rem;
  left: 1.9rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.grey0};
  font-size: ${({ theme }) => theme.typeFaces.header.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-family: "Roboto";
  line-height: ${({ theme }) => theme.typeFaces.header.lineHeight};
`;
export const ContentRight = styled.div`
  width: 78%;
  height: 100%;
  padding: 32px 24px 0px;
  gap: 32px;
`;
export const ErrorVerify = styled.div`
  color: red;
  text-align: center;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.grey0};
`;
export const SubTitle = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: 20px;

  margin-bottom: 32px;
  margin-top: 8px;
  color: ${({ theme }) => theme.grey40};
`;
export const SubTitle2 = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: 20px;

  margin-bottom: 16px;
  margin-top: 8px;
  color: ${({ theme }) => theme.grey40};
`;

export const Body = styled.div`
  width: 100%;

  padding: 0px;
  gap: 24px;
`;
export const LabelForm = styled.div`
  padding: 2px 8px;
  gap: 16px;
  padding-left: 0px;
  display: flex;
`;
export const FormButton = styled.div`
  margin-top: 24px;
`;
export const ButtonBack = styled.div`
  margin: 12px auto 24px;

  color: ${({ theme }) => theme.brandPrimary};
  cursor: pointer;
  text-align: center;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
`;
export const RequiredSymbol = styled.p`
  margin-left: -15px;
`;
