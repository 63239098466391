import {
  onChangeCurrentTicker,
  onChangeTickerByEnter,
} from "modules/stock-order/redux";
import React, {
  useEffect,
  useRef,
  useCallback,
  FC,
  memo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextItem } from "./styles";
import { useOutsideClick } from "helper/cusHooks";
import { getMarketLabel } from "helper/utils";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { typeLanguageSelector } from "languages/selectors";
import { LanguageType } from "languages/redux";
interface Props {
  data: MrktSecInfoItem.AsObject;
  focus: any;
  index: number;
  setFocus: any;
  setVisibleTextSearch: (value: boolean) => void;
  setTextSearch: (value: string) => void;
  setTextDisplay: (value: boolean) => void;
  setIsChangeArrow: (value: boolean) => void;
  setIsClickOutSide: (value: boolean) => void;
}

const SelectItem: FC<Props> = memo(
  ({
    data,
    focus,
    index,
    setFocus,
    setVisibleTextSearch,
    setTextSearch,
    setTextDisplay,
    setIsChangeArrow,
    setIsClickOutSide,
  }) => {
    const ref = useRef<HTMLLIElement>(null);
    const dispatch = useDispatch();
    const [isFocus, setIsFocus] = useState(false);
    const language = useSelector(typeLanguageSelector);

    useEffect(() => {
      if (focus) {
        setIsFocus(true);
        ref.current?.focus();
      }
    }, [focus]);

    const handleSelect = useCallback(() => {
      setFocus(index);
    }, [data, index, setFocus]);

    const handleKeyDown = (e: any, data: any) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.stopPropagation();
        // unSubscribeTicker(currentTicker);
        setVisibleTextSearch(false);
        setTextSearch("");
        setTextDisplay(true);
        dispatch(onChangeCurrentTicker(data.ticker));
        setIsChangeArrow(false);
        dispatch(onChangeTickerByEnter(true));
      }
    };

    useOutsideClick(ref, () => {
      if (isFocus) {
        setIsClickOutSide(true);
        setTimeout(() => setIsChangeArrow(false), 150);
      }
    });

    return (
      <TextItem
        tabIndex={focus ? 0 : -1}
        ref={ref}
        className="ticker-item"
        key={data.seccd}
        onMouseDown={(e) => {
          e.stopPropagation();
          // unSubscribeTicker(currentTicker);
          setVisibleTextSearch(false);
          setTextSearch("");
          setTextDisplay(true);
          dispatch(onChangeCurrentTicker(data.seccd));
          dispatch(onChangeTickerByEnter(true));
        }}
        onKeyPress={handleSelect}
        onKeyDown={(e) => handleKeyDown(e, data)}
        onMouseEnter={() => setIsChangeArrow(false)}
      >
        <div>
          <span
            className={
              data.secname !== "" || data.secnameen !== ""
                ? "share-code mr-2"
                : "share-code"
            }
          >
            {data.seccd}{" "}
          </span>

          <span className="share-name">
            {language === LanguageType.VI ? data.secname : data.secnameen}
          </span>
        </div>
        <div>{getMarketLabel(data.marketcd)}</div>
      </TextItem>
    );
  }
);

export default SelectItem;
