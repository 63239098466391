import { all, takeEvery } from "redux-saga/effects";
import { RealtimeConst } from "helper/constFirebase";
import Storage from "helper/storage";
import { requestGetOrderBook } from "../redux/index";

function requestGetOrderBookLogDataWorker(
  action: ReturnType<typeof requestGetOrderBook>
) {
  const resLogData = {
    event: RealtimeConst.dataOrderNormal,
    message: action.payload?.params?.toString(),
  };
  Storage.orderFirebase(resLogData);
}

function* requestGetOrderBookLogDataWatcher() {
  yield takeEvery(requestGetOrderBook.type, requestGetOrderBookLogDataWorker);
}

export default function* logTrackingSaga() {
  yield all([requestGetOrderBookLogDataWatcher()]);
}
