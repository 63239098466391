import { createSelector } from "@reduxjs/toolkit";
import { ReactComponent as IconSortDefault } from "assets/image/svg/ic_sort_default_2.svg";
import { ReactComponent as IconSortDown } from "assets/image/svg/ic_sort_down_2.svg";
import { ReactComponent as IconSortUp } from "assets/image/svg/ic_sort_up_2.svg";
import classNames from "classnames";
import { IScreener, IScreenerFilter } from "domain/models/Screener";
import {
  FLEX,
  generateRequestFilterDoFilter,
  ORDER_TYPE,
} from "modules/screeners/constants";
import {
  changeActiveSort,
  changeResultScreenerParams,
  doFilterRequest,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import {
  IActiveSort,
  TScreenersOrder,
  TScreenersSortBy,
} from "modules/screeners/types";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { HeaderCell, HeaderRowWrapper, TableHeaderWrapper } from "./styles";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
  sortBy?: number;
}

export interface Props {
  columns: IHeaderCellInfo[];
}

const screenersSelector = (state: RootState) => state.screeners;
const rootSelector = (state: RootState) => state;

const paramsSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.params
);

const activeSortSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataResultScreen.activeSort
);

const languageTypeSelector = createSelector(
  rootSelector,
  (state: RootState) => state.language.type
);

const screenerFilterListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.filterList
);

const myScreenerSelectedSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataMyScreener.screenerSelected
);

const TableHeader: FC<Props> = ({ columns }) => {
  const dispatch = useDispatch();
  const screenerFilterList = useSelector(screenerFilterListSelector);
  const myScreenerSelected = useSelector(myScreenerSelectedSelector);
  const language = useSelector(languageTypeSelector);
  const params = useSelector(paramsSelector);
  const activeSort = useSelector(activeSortSelector);

  const doFilterHandler = useCallback(
    (
      newOrder: number | undefined,
      newSortBy: number | undefined,
      myScreenerSelectedTemp: IScreener | null,
      screenerFilterListTemp: IScreenerFilter[]
    ) => {
      let currentFilterList: IScreenerFilter[];
      if (myScreenerSelected) {
        currentFilterList =
          myScreenerSelectedTemp?.screenerFilter as IScreenerFilter[];
      } else {
        currentFilterList = screenerFilterListTemp as IScreenerFilter[];
      }

      const requestFilter = generateRequestFilterDoFilter(currentFilterList);
      newOrder && requestFilter.setOrdertype(newOrder);
      newSortBy && requestFilter.setOrderfieldtype(newSortBy);

      dispatch(doFilterRequest(requestFilter));
    },
    [myScreenerSelected, language, screenerFilterList]
  );

  const clickHeaderHandler = useCallback(
    (tempCode: number | undefined) => {
      const code = tempCode as TScreenersSortBy;
      if (!code) return;
      // nếu chưa set sortBy hoặc sortBy trường khác
      if (!activeSort.sortBy || activeSort.sortBy !== code) {
        dispatch(changeActiveSort({ order: ORDER_TYPE.DESC, sortBy: code }));
        dispatch(
          changeResultScreenerParams({
            ...params,
            order: ORDER_TYPE.DESC,
            sortBy: code,
          })
        );
        doFilterHandler(
          ORDER_TYPE.DESC,
          code,
          myScreenerSelected,
          screenerFilterList
        );
        return;
      }
      //nếu order = desc thì gán newOrder = asc và ngược lại
      let newOrder;
      let unsetCode;
      if (activeSort.order === ORDER_TYPE.UNKNOWN) {
        newOrder = ORDER_TYPE.DESC;
      } else if (activeSort.order === ORDER_TYPE.DESC) {
        newOrder = ORDER_TYPE.ASC;
      } else {
        unsetCode = true;
      }
      dispatch(
        changeActiveSort({
          order: unsetCode ? ORDER_TYPE.UNKNOWN : (newOrder as TScreenersOrder),
          sortBy: unsetCode ? ORDER_TYPE.UNKNOWN : code,
        })
      );
      dispatch(
        changeResultScreenerParams({
          ...params,
          order: unsetCode ? undefined : (newOrder as TScreenersOrder),
          sortBy: unsetCode ? undefined : code,
        })
      );
      doFilterHandler(
        unsetCode ? undefined : (newOrder as TScreenersOrder),
        unsetCode ? undefined : code,
        myScreenerSelected,
        screenerFilterList
      );
    },
    [activeSort, myScreenerSelected, screenerFilterList]
  );

  const generateSortIcon = (sort: IActiveSort) => {
    let icon = <IconSortDefault />;
    if (sort.sortBy === activeSort.sortBy) {
      if (sort.order === ORDER_TYPE.DESC) {
        icon = <IconSortDown />;
      }
      if (sort.order === ORDER_TYPE.ASC) {
        icon = <IconSortUp />;
      }
    }
    return <span>{icon}</span>;
  };

  return (
    <TableHeaderWrapper id="table-header-wrapper">
      <HeaderRowWrapper>
        {columns.map((column) => (
          <HeaderCell
            align={FLEX[column.position as keyof typeof FLEX]}
            setMinWidth={column?.width}
            maxWidthCell={column?.maxWidthCell}
            flexCell={column?.flexCell}
            key={column.name}
            onClick={() => clickHeaderHandler(column.sortBy)}
            canSort={!!column.sortBy}
            className={classNames({
              "ticker-cell": column?.name === "screeners.card.table.shareCode",
            })}
          >
            <div>
              <span>
                <FormattedMessage id={column?.name} />
              </span>
              {!!column.sortBy &&
                generateSortIcon({
                  sortBy: column.sortBy as TScreenersSortBy,
                  order: activeSort.order,
                })}
            </div>
          </HeaderCell>
        ))}
      </HeaderRowWrapper>
    </TableHeaderWrapper>
  );
};

export default TableHeader;
