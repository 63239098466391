import { useSelector } from "react-redux";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import TickerChartWidget from "components/widgets/details/TickerChart";
import { memo, useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import pathRoutes from "helper/pathRoutes";
import { useLocation } from "react-router-dom";

const selector = (state: RootState) => state;

const currentTickerSelector = createSelector(
  selector,
  (state) => state.stockOrder.root.currentTicker
);
const currentTickerDerSelector = createSelector(
  selector,
  (state) => state.stockOrderDer.placeOrderDer.data
);

export default memo(function MarketTickerChart() {
  const location = useLocation();
  const selectedTicker = useSelector(selectedTickerSelector);
  const [ticker, setTicker] = useState(selectedTicker);

  const currentTicker = useSelector(currentTickerSelector);
  const { currentTicker: currentTickerDer } = useSelector(
    currentTickerDerSelector
  );

  useEffect(() => {
    if (location.pathname === pathRoutes.stockOrder) {
      return setTicker(currentTicker);
    }
    if (location.pathname === pathRoutes.stockOrderDer) {
      return setTicker(currentTickerDer);
    }
    if (selectedTicker === null) return;
    return setTicker(selectedTicker);
  }, [location.pathname, currentTicker, currentTickerDer, selectedTicker]);

  return (
    <TickerChartWidget
      selectedTicker={ticker}
      isShowVn30Button={false}
      isStockOrderDer={ticker === currentTickerDer}
    />
  );
});
