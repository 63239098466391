import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { listRightInfoSelector } from "modules/right-info/selectors";
import { HeaderCell, TableCellContent } from "./styles";
import moment from "moment";
import numeral from "numeral";
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { CellProps } from "react-table";
import { IRightInfoResponse } from "domain/models/RightInfo";
import { Container } from "./styles";

function getRightInfoStatus(value: number) {
  if (value === 0)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.status.pending" />
      </TableCellContent>
    );
  if (value === 2)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.status.approved" />
      </TableCellContent>
    );
  if (value === 9)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.status.deleted" />
      </TableCellContent>
    );
  return value;
}

const getRightType = (value: number) => {
  if (value === 1)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.cashDevidend" />
      </TableCellContent>
    );
  if (value === 2)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.stockDividend" />
      </TableCellContent>
    );
  if (value === 3)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.stockBonus" />
      </TableCellContent>
    );
  if (value === 4)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.rightRegister" />
      </TableCellContent>
    );
  if (value === 5)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.bondConvertion" />
      </TableCellContent>
    );
  if (value === 6)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.stockMerge" />
      </TableCellContent>
    );
  if (value === 7)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.stockSplit" />
      </TableCellContent>
    );
  if (value === 8)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.delisting" />
      </TableCellContent>
    );
  if (value === 9)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.changeSecuritiesType" />
      </TableCellContent>
    );
  if (value === 10)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.depositoryRegistration" />
      </TableCellContent>
    );
  if (value === 11)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.votes" />
      </TableCellContent>
    );
  if (value === 12)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.returnCapital" />
      </TableCellContent>
    );
  if (value === 13)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.bondExpires" />
      </TableCellContent>
    );
  if (value === 14)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.buyCapital" />
      </TableCellContent>
    );
  if (value === 15)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.rightType.buyBondConvertion" />
      </TableCellContent>
    );
  return value;
};

const getSecState = (value: number) => {
  if (value === 0)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.secState.all" />
      </TableCellContent>
    );
  if (value === 1)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.secState.popularity" />
      </TableCellContent>
    );
  if (value === 2)
    return (
      <TableCellContent align="flex-start">
        <FormattedMessage id="rightInfo.table.secState.restricted" />
      </TableCellContent>
    );
  return value;
};

const getRate = (value1: number, value2: number) => {
  if (value1 != null && value2 != null) {
    return value1 + "/" + value2;
  } else {
    return "";
  }
};

function Table() {
  const data = useSelector(listRightInfoSelector);
  const flexStart = "flex-start";
  const flexEnd = "flex-end";
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.secCd" />
          </HeaderCell>
        ),
        accessor: "secCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.marketSname" />
          </HeaderCell>
        ),
        accessor: "marketSname",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.noRightDate" />
          </HeaderCell>
        ),
        accessor: "noRightDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.ownerFixDate" />
          </HeaderCell>
        ),
        accessor: "ownerFixDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.rightExcDate" />
          </HeaderCell>
        ),
        accessor: "rightExcDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.beginTradeDate" />
          </HeaderCell>
        ),
        accessor: "beginTradeDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.ownerListPeportDate" />
          </HeaderCell>
        ),
        accessor: "ownerListPeportDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.rightRate" />
          </HeaderCell>
        ),
        accessor: "rightRate",
        Cell: (props: CellProps<IRightInfoResponse>) => {
          const rates1 = props?.row?.original?.rates1 || 0;
          const rated1 = props?.row?.original?.rated1 || 0;
          const rightRate = getRate(rates1, rated1);
          return (
            <TableCellContent align={center}>{rightRate}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.rate" />
          </HeaderCell>
        ),
        accessor: "rate",
        Cell: (props: any) => {
          const rates = props?.row?.original?.rates || 0;
          const rated = props?.row?.original?.rated || 0;
          const rate = getRate(rates, rated);
          return <TableCellContent align={center}>{rate}</TableCellContent>;
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.rightType.title" />
          </HeaderCell>
        ),
        accessor: "rightType",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {getRightType(props.value)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.status.title" />
          </HeaderCell>
        ),
        accessor: "status",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {getRightInfoStatus(props.value)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.beginRegDate" />
          </HeaderCell>
        ),
        accessor: "beginRegDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.endRegDate" />
          </HeaderCell>
        ),
        accessor: "endRegDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.beginTransDate" />
          </HeaderCell>
        ),
        accessor: "beginTransDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.endTransDate" />
          </HeaderCell>
        ),
        accessor: "endTransDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="rightInfo.table.price" />
          </HeaderCell>
        ),
        accessor: "price",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.[000]")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="rightInfo.table.taxRate" />
          </HeaderCell>
        ),
        accessor: "taxRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.[00]")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.secState.title" />
          </HeaderCell>
        ),
        accessor: "secState",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {getSecState(props.value)}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.targetSecCd" />
          </HeaderCell>
        ),
        accessor: "targetSecCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.remarks" />
          </HeaderCell>
        ),
        accessor: "remarks",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart} title={props.value}>
              {props.value}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.alloDate" />
          </HeaderCell>
        ),
        accessor: "alloDate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value
                ? moment(props.value, "YYYYMMDD").format("DD/MM/YYYY")
                : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.refNo" />
          </HeaderCell>
        ),
        accessor: "refNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.regDateTime" />
          </HeaderCell>
        ),
        accessor: "regDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value ? moment(props.value).format("DD/MM/YYYY") : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.regUserId" />
          </HeaderCell>
        ),
        accessor: "regUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="rightInfo.table.approvedDateTime" />
          </HeaderCell>
        ),
        accessor: "approvedDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {props.value ? moment(props.value).format("DD/MM/YYYY") : ""}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="rightInfo.table.approvedUserId" />
          </HeaderCell>
        ),
        accessor: "approvedUserId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="rightInfoContainer">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data}
        enableInfinity={true}
        scrollableTarget={"rightInfoContainer"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
