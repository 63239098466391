import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

export type ToastType = "info" | "success" | "error" | "warning" | "default";

export type ToastState = {
  messages: ToastMessage[];
  delay: number;
};

export type ToastMessage = {
  text: string | undefined;
  type: ToastType;
  id: string;
  defaultMessage?: string;
  isIntlKey?: Boolean;
  param?: any;
};

const initialState: ToastState = {
  messages: [],
  delay: 5000,
};

const toastSlide = createSlice({
  name: "system",
  initialState,
  reducers: {
    requestOpenToast: (state, { payload }: PayloadAction<ToastMessage>) => {},
    openToast: (state, { payload }: PayloadAction<ToastMessage>) => {
      // check trùng text
      if (
        state.messages.some((item) => item.text === payload.text) &&
        state.messages.find((item) => isEqual(item.param, payload.param))
      )
        return;
      state.messages.push(payload);
    },

    requestCloseToast: (state, { payload }: PayloadAction<string>) => {},
    closeToast: (state, { payload }: PayloadAction<string>) => {
      const deletedIndex = state.messages.findIndex(
        (message) => message.id === payload
      );
      if (deletedIndex !== -1) state.messages.splice(deletedIndex, 1);
    },

    setDelay: (state, { payload }: PayloadAction<number>) => {
      state.delay = payload;
    },
  },
});

export const {
  openToast,
  closeToast,
  requestOpenToast,
  requestCloseToast,
  setDelay,
} = toastSlide.actions;

export default toastSlide.reducer;
