import { FormattedMessage } from "react-intl";
import { LabelCell } from "../InfomationOrder/ContainerTop/styles";
import { Wrapper } from "../InfomationOrder/styles";
import Filter from "./Filter";
import Table from "./Table";

function OrderBook() {
  return (
    <Wrapper>
      <LabelCell>
        <FormattedMessage id="placeOrderRetailBonds.orderBook.title" />
      </LabelCell>
      <Filter />
      <Table />
    </Wrapper>
  );
}

export default OrderBook;
