import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .chart {
    padding: 16px 24px 0 24px;
    flex: 1 1 0;
    position: relative;
    overflow: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};

  .item-selection {
    display: flex;
    align-items: center;
    min-width: 300px;
    padding: 8px 24px 0;
    &.market-filter {
      min-width: 200px;
    }
  }

  .label {
    width: 50px;
  }

  .selection {
    width: calc(100% - 50px);
  }
`;
