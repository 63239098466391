import { memo } from "react";
import numeral from "numeral";
import { Colors } from "helper/consts";
import Progress from "../Progress";
import { Col, Row, TextColor } from "../styles";
import { SecTimeSalePro } from "domain/protoNew/auto_trading_pb";
interface VolumeItemProp extends SecTimeSalePro.AsObject {
  maxPercent: number;
}
function VolumeItem({
  matprice,
  buypercent,
  sellpercent,
  percentbytotal,
  maxPercent,
  colorcode,
}: VolumeItemProp) {
  return (
    <Row>
      <Col>
        <TextColor color={Colors[colorcode]}>
          {numeral(matprice).format("0.00")}
        </TextColor>
      </Col>
      <Col className="progressBar">
        <Progress
          percentage={percentbytotal}
          maxPercent={maxPercent}
          buypercent={buypercent}
          sellpercent={sellpercent}
        />
      </Col>
      <Col className="justify-content-end">
        {numeral(percentbytotal).format("0.00%")}
      </Col>
    </Row>
  );
}

export default memo(VolumeItem);
