import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 0 16px 12px;
  flex-direction: column;
  .chart {
    padding: 0 24px;
    flex: 1 1 0;
    position: relative;
    overflow: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};

  .item-selection {
    padding: 8px 0;
  }
  .item-summary {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    div {
      padding: 0 8px;
      font-size: ${({ theme }) => theme.typeFaces.caption12.size};
      font-weight: ${({ theme }) => theme.typeFaces.caption12.semiBold};
      color: ${({ theme }) => theme.textcolorWhite};
      span {
        padding-left: 6px;
      }
    }
  }
`;

export const SummaryValue = styled.span<{ textColor?: number | undefined }>`
  ${({ theme, textColor }) => getColor(textColor, theme)};
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  color: ${({ theme }) => theme.textcolorWhite};
`;

function getColor(color: number | undefined, theme: any) {
  switch (color) {
    case 1:
      return `color: ${theme.green50};`;
    case -1:
      return `color: ${theme.red50};`;
    default:
      return `color: ${theme.textcolorWhite};`;
  }
}
