import { createSlice } from "@reduxjs/toolkit";

export type ConsoleState = {
  isShowingPanel: boolean;
};

const initialState: ConsoleState = {
  isShowingPanel: false,
};

const consoleSlice = createSlice({
  name: "watchlistConsole",
  initialState,
  reducers: {
    togglePanel: (state) => {
      state.isShowingPanel = !state.isShowingPanel;
    },
  },
});

export const { togglePanel } = consoleSlice.actions;

export default consoleSlice.reducer;
