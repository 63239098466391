import { memo } from "react";
import { Card } from "react-bootstrap";
import { ItemCard } from "./styles";

interface Props {
  image: JSX.Element;
  icon: JSX.Element;
  text: string;
  active: boolean;
  defaultTheme?: string;
}

function ItemTheme({ image, icon, text, active, defaultTheme }: Props) {
  return (
    <ItemCard border={active}>
      <Card className="style-card">
        <Card.Body>
          <Card.Title className="image">{image}</Card.Title>
          <div className="title">
            {icon}&nbsp; {text} <span>{defaultTheme}</span>
          </div>
        </Card.Body>
      </Card>
    </ItemCard>
  );
}

export default memo(ItemTheme);
