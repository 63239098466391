import { valueHeaderCardSelector } from "modules/system/selectors";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { TextColorUpDown } from "../styles";
import { ReactComponent as ArrowUp } from "assets/image/svg/arrowDuplicateUp.svg";
import { ReactComponent as ArrowHorizontal } from "assets/image/svg/ArrowHorizontalDuplicate.svg";
import { ReactComponent as ArrowDown } from "assets/image/svg/arrowDuplicateDown.svg";
interface Props {
  ticker: string;
}

function UpDownChange({ ticker }: Props) {
  const advances = useSelector(
    valueHeaderCardSelector(ticker, "advances")
  ) as number;
  const declenes = useSelector(
    valueHeaderCardSelector(ticker, "declenes")
  ) as number;
  const nochange = useSelector(
    valueHeaderCardSelector(ticker, "nochange")
  ) as number;

  return (
    <div className="d-flex align-items-center justify-content-center">
      <ArrowUp />
      <TextColorUpDown textcolor="textColorGreen">
        {numeral(advances).format("0,0")}
      </TextColorUpDown>
      <TextColorUpDown textcolor="textColorViolet">(--)</TextColorUpDown>
      <ArrowHorizontal />
      <TextColorUpDown textcolor="textColorYellow">
        {numeral(nochange).format("0,0")}
      </TextColorUpDown>
      <ArrowDown />
      <TextColorUpDown textcolor="textColorRed">
        {numeral(declenes).format("0,0")}
      </TextColorUpDown>
      <TextColorUpDown textcolor="textColorBlue">(--)</TextColorUpDown>
    </div>
  );
}
export default UpDownChange;
