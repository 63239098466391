import styled from "styled-components/macro";

export const Container = styled.div`
  height: calc(100% - 48px);
`;

export const HeaderContainer = styled.div`
  padding: 12px;
`;

export const ContentContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const TickerName = styled.div`
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
`;

export const TableHeaderWrapper = styled.div`
  overflow-x: hidden;
`;

export const HeaderCell = styled.div<{
  align?: string;
  setMinWidth?: number;
  canSort?: boolean;
}>`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  white-space: nowrap;
  justify-content: ${({ align }) => align};
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  ${({ setMinWidth }) => setMinWidth && `width: ${setMinWidth}%`};
  ${({ canSort }) => canSort && `cursor: pointer;`}
  text-transform: uppercase;

  & > div {
    padding: 1px 4px 0px;
    display: flex;
    align-items: center;

    & > span {
      color: ${({ theme }) => theme.grey40};
      font-size: ${({ theme }) => theme.typeFaces.caption10.size};
      line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
      font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
    }
  }

  &.ticker-cell {
    position: sticky;
    left: 0;
  }
`;

export const HeaderRowWrapper = styled.div`
  display: flex;
`;

export const TableBodyWrapper = styled.div`
  height: calc(100% - 25px);

  @keyframes blink-up {
    0% {
      background: ${({ theme }) => theme.secondaryGreenDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    90% {
      background: ${({ theme }) => theme.secondaryGreenDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    100% {
      background: none;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }

  @keyframes blink-down {
    0% {
      background: ${({ theme }) => theme.secondaryRedDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    90% {
      background: ${({ theme }) => theme.secondaryRedDisable};
      color: ${({ theme }) => theme.textcolorWhite};
    }
    100% {
      background: none;
      color: ${({ theme }) => theme.textcolorWhite};
    }
  }

  .cell-blink-up {
    text-decoration: ${({ theme }) => theme.textcolorWhite};
    animation-name: blink-up;
    -webkit-animation-name: blink-up;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  .cell-blink-down {
    text-decoration: ${({ theme }) => theme.textcolorWhite};
    animation-name: blink-down;
    -webkit-animation-name: blink-down;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
`;

export const BodyRowWrapper = styled.div<{
  bgColor: number;
}>`
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadowInnerDivider};
  & > div {
    background-color: ${({ theme, bgColor }) =>
      bgColor % 2 === 0 ? theme.blackB4 : theme.grey100};
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  minWidth?: number;
}>`
  ${({ minWidth }) => minWidth && `width: ${minWidth}%;`}
  height: 100%;
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  justify-content: center;
  color: ${({ theme }) => theme.grey0};
  background: ${({ theme }) => theme.background2};
  & > div {
    font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  }
  &.market-ticker-name {
    div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${({ theme }) => theme.grey40};
    }
  }
`;

export const CellValue = styled.div<{
  align?: string;
  minWidth?: number;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) => align};
  text-align: right;
  padding: 8px;
  color: ${({ theme }) => theme.textcolorWhite};
  background: ${({ theme }) => theme.background2};
  ${({ minWidth }) => minWidth && `width: ${minWidth}%;`}
  .big {
    > div {
      font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
      font-size: ${({ theme }) => theme.typeFaces.headline.size};
    }
  }
`;
