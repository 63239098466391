import { useState } from "react";
import ModalIndex from "./modal";
import { useHistory } from "react-router-dom";

const ManegerMoney = () => {
  const history = useHistory();

  const [modal, setModal] = useState(true);
  const closeModal = () => {
    history.goBack();
    setModal(false);
  };
  return (
    <>
      <div></div>
      <ModalIndex isVisible={modal} onClose={closeModal} />
    </>
  );
};

export default ManegerMoney;
