import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export interface ServiceEmailSms {
  statusService: any;
  customer: any;
  loadingSearch: boolean;
  loadingSubmit: boolean;
  statusCode: number;
  smsData: any;
  emailData: any;
}

const initialState: ServiceEmailSms = {
  statusService: [],
  customer: [],
  loadingSearch: false,
  loadingSubmit: false,
  statusCode: -1,
  smsData: [{}],
  emailData: [{}],
};

const serviceEmailSms = createSlice({
  name: "serviceEmailSms",
  initialState: cloneDeep(initialState),

  reducers: {
    getServiceStatusOfCustomerRequest: (
      state,
      action: PayloadAction<any>
    ) => {},
    getServiceStatusOfCustomerSuccess: (state, action: PayloadAction<any>) => {
      state.statusService = action.payload?.data;
      state.smsData = action.payload?.data?.smsData?.serviceList;
      state.emailData = action.payload?.data?.emailData?.serviceList;
    },
    getServiceStatusOfCustomerFailure: (
      state,
      action: PayloadAction<any>
    ) => {},

    searchServiceStatusOfCustomerRequest: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loadingSearch = true;
    },
    searchServiceStatusOfCustomerSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.statusService = action.payload?.data;
      state.loadingSearch = false;
      state.smsData = action.payload?.data?.smsData?.serviceList;
      state.emailData = action.payload?.data?.emailData?.serviceList;
    },
    searchServiceStatusOfCustomerFailure: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loadingSearch = false;
    },

    getCustomer2Request: (state, action: PayloadAction<any>) => {},
    getCustomer2Success: (state, action: PayloadAction<any>) => {
      state.customer = action.payload?.data;
    },
    getCustomer2Failure: (state, action: PayloadAction<any>) => {},

    registerServiceRequest: (state, action: PayloadAction<any>) => {
      state.loadingSubmit = true;
    },
    registerServiceSuccess: (state, action: PayloadAction<any>) => {
      state.loadingSubmit = false;
      state.statusCode = 0;
    },
    registerServiceFailure: (state, action: PayloadAction<any>) => {
      state.loadingSubmit = false;
    },
    updateStatusCodeSms: (state, action: PayloadAction<any>) => {
      state.statusCode = action.payload.statusCode;
    },
    updateSmsData: (state, action: PayloadAction<any>) => {
      state.smsData = action.payload.smsData;
    },
    updateEmailData: (state, action: PayloadAction<any>) => {
      state.emailData = action.payload.emailData;
    },
  },
});

export const {
  getServiceStatusOfCustomerRequest,
  getServiceStatusOfCustomerSuccess,
  getServiceStatusOfCustomerFailure,

  getCustomer2Request,
  getCustomer2Success,
  getCustomer2Failure,

  searchServiceStatusOfCustomerRequest,
  searchServiceStatusOfCustomerSuccess,
  searchServiceStatusOfCustomerFailure,

  registerServiceRequest,
  registerServiceSuccess,
  registerServiceFailure,
  updateStatusCodeSms,
  updateSmsData,
  updateEmailData,
} = serviceEmailSms.actions;

export default serviceEmailSms.reducer;
