import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  h2 {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    margin-bottom: 20px;
    color: ${({ theme }) => theme.textcolorWhite};
  }

  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MarketLineChartWrapper = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  .chart {
    flex: 1 1 0;
    position: relative;
    overflow: hidden;
  }

  .legend {
    flex: 0 0 16px;
    display: flex;
    justify-content: center;
    gap: 32px;
    position: relative;
    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: ${({ theme }) => theme.typeFaces.caption12.size};
      font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
      line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    }
  }
`;

export const LegendBlock = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background: ${({ color, theme }) => theme[color]};
  border-radius: 2px;
  margin-right: 4px;
`;
