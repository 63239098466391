import MainLayout from "components/layouts/Main";
import { RouterConfig } from "router/AppRoute";
import StockOrderDer from "./pages/StockOrderDer";
import paths from "helper/pathRoutes";

const routes: RouterConfig[] = [
  {
    path: paths.stockOrderDer,
    page: StockOrderDer,
    exact: true,
    layout: MainLayout,
  },
];

export default routes;
