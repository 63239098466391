import { toast } from "components/commons/Toast";
import {
  findPortTransaction,
  getPflTPortTransaction,
} from "core/http/apis/profit-loss";
import {
  IFindPortTransactionParams,
  IGetPflTPortTransactionParams,
} from "core/http/apis/profit-loss/types";
import {
  IFindPortTransactionResponse,
  IGetPflTPortTransactionResponse,
} from "domain/models/ProfitLoss";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  searchProfitLossSuccess,
  searchProfitLossRequest,
  searchProfitLossFailure,
  getPflTPortTransactionRequest,
  getPflTPortTransactionSuccess,
  getPflTPortTransactionFailure,
  fetchMoreProfitLossSuccess,
  fetchMoreProfitLossRequest,
  updatePage,
} from "./redux";

function* searchProfitLossWorker(
  action: ReturnType<typeof searchProfitLossRequest>
) {
  try {
    const params: IFindPortTransactionParams = action.payload;
    const response: IFindPortTransactionResponse = yield call(
      findPortTransaction,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchProfitLossSuccess(response));
    } else {
      yield put(searchProfitLossFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchProfitLossFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getPflTPortTransactionWorker(
  action: ReturnType<typeof getPflTPortTransactionRequest>
) {
  try {
    const params: IGetPflTPortTransactionParams = action.payload;
    const response: IGetPflTPortTransactionResponse = yield call(
      getPflTPortTransaction,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getPflTPortTransactionSuccess(response));
    } else {
      yield put(getPflTPortTransactionFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getPflTPortTransactionFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchProfitLossWatcher() {
  yield takeLatest(searchProfitLossRequest.type, searchProfitLossWorker);
}

function* getPflTPortTransactionWatcher() {
  yield takeLatest(
    getPflTPortTransactionRequest.type,
    getPflTPortTransactionWorker
  );
}

function* fetchMoreProfitLossWatcher() {
  yield takeLatest(fetchMoreProfitLossRequest.type, fetchMoreProfitLossWorker);
}

function* fetchMoreProfitLossWorker(
  action: ReturnType<typeof fetchMoreProfitLossRequest>
) {
  try {
    const params: IFindPortTransactionParams = action.payload;
    const response: IFindPortTransactionResponse = yield call(
      findPortTransaction,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updatePage(params.page ? params.page + 1 : 0));
      yield put(fetchMoreProfitLossSuccess(response));
    } else {
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

export default function* advanceLimitSaga() {
  yield all([
    searchProfitLossWatcher(),
    getPflTPortTransactionWatcher(),
    fetchMoreProfitLossWatcher(),
  ]);
}
