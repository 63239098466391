import { SizeType } from "components/commons/Spacer";
import styled from "styled-components/macro";

export const InputConntainer = styled.div<{
  size?: SizeType;
  isBorderActive?: boolean;
  radius?: number;
}>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.bgBase};
  border: 1px solid
    ${({ theme, isBorderActive }) =>
      isBorderActive ? theme.brandSecondary : theme.grey70};
  overflow: hidden;
  border-radius: ${({ radius }) => (radius ? radius : 4)}px;
  height: ${({ theme }) => theme.input.height};
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const InputBox = styled.div<{
  isTextCenter?: boolean;
}>`
  padding: 0 8px;
  flex: 1;
  .input {
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.grey0};
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    text-align: ${({ isTextCenter }) => (isTextCenter ? "center" : "left")};
  }
`;
