import { FC } from "react";

import TransferBondContent from "./components/TransferBondContent/index";

import { Container } from "./styles";

const TransferBond: FC = () => {
  return (
    <Container isVisibleSideCard={false}>
      <TransferBondContent />
    </Container>
  );
};

export default TransferBond;
