import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import { ReactComponent as ArrowLeft } from "assets/image/svg/ic-left.svg";
import { selectedTickerSelector } from "modules/market/redux/selectors";
import {
  getNewsInfo,
  getNewsDetailRequest,
} from "modules/tickerDetails/redux/newsInfo";
import { ReactComponent as CalendarIcon } from "assets/image/svg/ic_calendar.svg";
import { ReactComponent as ClockIcon } from "assets/image/svg/ic_clock.svg";
import { NewsWrapper } from "./styles";
import { truncateText } from "helper/utils";
import { FormattedMessage } from "react-intl";
import {
  newsListSelector,
  newIdSelector,
  newsItemDetailLoadingSelector,
  newsItemDetailSelectedSelector,
  enableInfinitySelector,
  getNewsParamsSelector,
} from "modules/tickerDetails/redux/selectors";
import InfiniteScroll from "react-infinite-scroll-component";
import { IGetListNewsBySecCdParams } from "core/http/apis/news/types";

function News() {
  const dispatch = useDispatch();
  const ticker = useSelector(selectedTickerSelector);
  const newId = useSelector(newIdSelector);
  const newsList = useSelector(newsListSelector);
  const newsItemDetailSelected = useSelector(newsItemDetailSelectedSelector);
  const newsItemDetailLoading = useSelector(newsItemDetailLoadingSelector);
  const enableInfinity = useSelector(enableInfinitySelector);
  const { page, size } = useSelector(getNewsParamsSelector);

  useEffect(() => {
    if (ticker) {
      dispatch(getNewsDetailRequest(null));
      searchFilter(false);
    }
  }, [ticker]);

  const searchFilter = (loadmore: boolean) => {
    let currentOffset = page ? page : 0;
    if (!loadmore) {
      currentOffset = 0;
    }
    const params: IGetListNewsBySecCdParams = {
      secCd: ticker || "",
      size: size,
      page: currentOffset,
    };
    dispatch(getNewsInfo(params));
  };

  const handleViewNewsDetails = (articleID: number | null) => {
    dispatch(getNewsDetailRequest(articleID));
  };

  const decodeHtml = (currentArticle: string) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = currentArticle;
    return txt.value;
  };

  const fetchMoreData = () => {
    searchFilter(true);
  };

  return (
    <NewsWrapper>
      {!newId && (
        <InfiniteScroll
          dataLength={newsList?.length}
          next={fetchMoreData}
          hasMore={enableInfinity}
          height={"100%"}
          loader={""}
          endMessage={""}
        >
          {newsList?.map((contentNew) => (
            <div className="new-item">
              <div className="main-info">
                <h3
                  onClick={() => {
                    handleViewNewsDetails(contentNew.id || null);
                  }}
                  className="main-title"
                >
                  {truncateText(contentNew.title || "", 80)}
                </h3>
                <div className="time">
                  <CalendarIcon />
                  <span className="date">
                    {contentNew.publicationdate &&
                      moment(contentNew.publicationdate).format("DD/MM/YYYY")}
                  </span>
                  <div className="divide"></div>
                  <ClockIcon className="clock-icon" />
                  <span className="hour">
                    {contentNew.publicationdate &&
                      moment(contentNew.publicationdate).format("HH:ss")}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}

      {newId &&
        (!newsItemDetailLoading ? (
          <div className="news-detail">
            {newsItemDetailSelected?.title && (
              <div
                className="news-details-header"
                onClick={() => handleViewNewsDetails(null)}
              >
                <ArrowLeft />
                <span>{truncateText(newsItemDetailSelected?.title, 30)}</span>
              </div>
            )}
            <div className="news-detail-content">
              {HTMLReactParser(
                decodeHtml(
                  newsItemDetailSelected?.content
                    ? newsItemDetailSelected?.content
                    : ""
                )
              )}
            </div>
          </div>
        ) : (
          <div className="new-detail-loading">
            <FormattedMessage id="common.loading" />
          </div>
        ))}
    </NewsWrapper>
  );
}

export default memo(News);
