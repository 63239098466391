import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IInquiryAccountTransferSecParam,
  ISubmitTransferSecList,
  ISubmitTransferSecParam,
} from "core/http/apis/transfer-share/types";
import {
  ISecTransferAvailRowData,
  ISubmitTransferSecResponse,
} from "domain/models/TransferShare";
import { logOutSuccess } from "modules/auth/redux";
import { ReduxStateType } from "redux/types";

export interface ReduxData {
  data: ISecTransferAvailRowData[];
  submitTransferShareParams: ISubmitTransferSecParam;
  selectedRows: ISecTransferAvailRowData[];
  isCheckAll: boolean;
  textEdit: string;
  idEdit: number;
  loadList: boolean;
  status: ReduxStateType;
}

const initialState: ReduxData = {
  data: [],
  submitTransferShareParams: {
    subAccoNoFrom: "",
    subAccoNoTo: "",
    subAccoCdFrom: 0,
    subAccoCdTo: 0,
    // secCd: "",
    quantity: 0,
    price: 0, // Lấy từ API
    remarks: "",
    otpCode: "",
    dataList: [],
  },
  selectedRows: [],
  isCheckAll: false,
  textEdit: "",
  idEdit: 0,
  loadList: true,
  status: ReduxStateType.INIT,
};

const transferShareForm = createSlice({
  name: "transferShareFormSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    requestListTransferShareForm: (
      state,
      action: PayloadAction<IInquiryAccountTransferSecParam>
    ) => {
      state.status = ReduxStateType.LOADING;
    },
    listTransferShareFormSuccess: (
      state,
      action: PayloadAction<ISecTransferAvailRowData[]>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.data = action.payload || [];
    },
    listTransferShareFormFailed: (state) => {
      state.status = ReduxStateType.ERROR;
    },

    changeAccountOutSubmit: (state, action: PayloadAction<string>) => {
      state.submitTransferShareParams.subAccoNoFrom = action.payload;
    },
    changeAccountInSubmit: (state, action: PayloadAction<string>) => {
      state.submitTransferShareParams.subAccoNoTo = action.payload;
    },

    // changeListTransferShareCheckedSubmit: (
    //   state,
    //   action: PayloadAction<TransferShareItem[]>
    // ) => {
    //   state.submitTransferShareParams.listShareUpdate = action.payload;
    // },
    changeContentSubmit: (state, action: PayloadAction<string>) => {
      state.submitTransferShareParams.remarks = action.payload;
    },
    changeSelectedAllRows: (state, action: PayloadAction<boolean>) => {
      state.isCheckAll = action.payload;
      if (action.payload) {
        state.selectedRows = state.data;
      } else {
        state.selectedRows = [];
      }
    },
    changeSelectedRows: (
      state,
      action: PayloadAction<{
        isChecked: boolean;
        id: number;
        listTransferShare: ISecTransferAvailRowData[];
      }>
    ) => {
      if (action.payload.isChecked) {
        const rowSelected = state.data.find(
          (item) => item.id === action.payload.id
        );
        if (rowSelected) {
          state.selectedRows = state.selectedRows.concat(rowSelected);
        }
      } else {
        const rowSelect: ISecTransferAvailRowData[] =
          action.payload.listTransferShare.filter(
            (item) => item.id !== action.payload.id
          );
        state.selectedRows = rowSelect;
      }
      if (state.data.length === state.selectedRows.length) {
        state.isCheckAll = true;
      } else {
        state.isCheckAll = false;
      }
    },
    changeMaxWithDraw: (state) => {
      const selectedRowsNew: ISecTransferAvailRowData[] =
        state.selectedRows.map((item) => {
          if (item.id === state.idEdit) {
            const itemEdit: ISecTransferAvailRowData = {
              id: item.id,
              secCd: item.secCd,
              marginRate: item.marginRate,
              secTransaction: item.secTransaction,
              secTransferAvail: state.textEdit,
              visibleCheckbox: item.visibleCheckbox,
            };
            return itemEdit;
          } else {
            return item;
          }
        });
      state.selectedRows = selectedRowsNew;
    },
    requestSubmitTransferShareForm: (
      state,
      action: PayloadAction<ISubmitTransferSecList>
    ) => {
      state.status = ReduxStateType.LOADING;
      state.submitTransferShareParams = action.payload;
    },
    requestSubmitTransferShareFormSuccess: (
      state,
      action: PayloadAction<ISubmitTransferSecResponse>
    ) => {
      state.status = ReduxStateType.SUCCESS;
      state.selectedRows = [];
      state.loadList = true;
    },
    requestSubmitTransferShareFormFailed: (state) => {
      state.status = ReduxStateType.ERROR;
      state.loadList = true;
    },
    getTransferShareClear: (state) => {
      state.selectedRows = [];
    },
    changeEditRow: (
      state,
      action: PayloadAction<{ text: string; id: number }>
    ) => {
      state.textEdit = action.payload.text;
      state.idEdit = action.payload.id;
    },
    onLoadList: (state, action: PayloadAction<boolean>) => {
      state.loadList = action.payload;
    },
    clearStatus: (state) => {
      state.status = ReduxStateType.INIT;
    },
  },
});

export const {
  requestListTransferShareForm,
  listTransferShareFormSuccess,
  listTransferShareFormFailed,
  changeAccountOutSubmit,
  changeAccountInSubmit,
  // changeListTransferShareCheckedSubmit,
  changeContentSubmit,
  changeSelectedRows,
  changeSelectedAllRows,
  changeMaxWithDraw,
  requestSubmitTransferShareForm,
  requestSubmitTransferShareFormSuccess,
  requestSubmitTransferShareFormFailed,
  getTransferShareClear,
  changeEditRow,
  onLoadList,
  clearStatus,
} = transferShareForm.actions;

export default transferShareForm.reducer;
