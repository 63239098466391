import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import {
  findArticleCategorysApi,
  findArticlesApi,
} from "core/http/apis/guideVideo";
import {
  IFindArticleCategorysResponse,
  IFindArticlesResponse,
} from "domain/models/GuideVideo";
import {
  findArticlesRequest,
  findArticlesSuccess,
  findArticlesFailure,
  findArticleCategorysRequest,
  findArticleCategorysSuccess,
  findArticleCategorysFailure,
} from "./redux";

function* findArticlesWorker(action: ReturnType<typeof findArticlesRequest>) {
  try {
    const response: IFindArticlesResponse = yield call(
      findArticlesApi,
      action.payload.params
    );

    if (response?.statusCode === 0) {
      yield put(
        findArticlesSuccess({
          items: response.data,
          offset: action.payload.params.offset,
        })
      );
    } else {
      yield put(findArticlesFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findArticlesFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findArticlesWatcher() {
  yield takeLatest(findArticlesRequest.type, findArticlesWorker);
}

function* findArticleCategorysWorker(
  action: ReturnType<typeof findArticleCategorysRequest>
) {
  try {
    const response: IFindArticleCategorysResponse = yield call(
      findArticleCategorysApi,
      action.payload
    );

    if (response?.statusCode === 0) {
      yield put(findArticleCategorysSuccess(response));
    } else {
      yield put(findArticleCategorysFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findArticleCategorysFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findArticleCategorysWatcher() {
  yield takeLatest(
    findArticleCategorysRequest.type,
    findArticleCategorysWorker
  );
}

export default function* advanceLimitSaga() {
  yield all([findArticlesWatcher(), findArticleCategorysWatcher()]);
}
