import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const transferLocalSelector = (state: RootState) => state.transferLocal;

export const transferLocalListSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.list
);

export const transferLocalLoadingSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.loading
);

export const transferLocalFilterSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.filter
);

export const transferLocalHisListSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocalHis.list
);

export const transferLocalHisLoadingSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocalHis.loading
);

export const transferLocalHisFilterSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocalHis.filter
);

export const activeTabSelector = createSelector(
  transferLocalSelector,
  (state) => state.activeTab
);

export const itemSelectedSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.itemSelected
);

export const itemEditSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.itemEdit
);

export const itemCalculateSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.itemCalculate
);

export const accFromHiddenSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.accFromHidden
);

export const accToHiddenSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.accToHidden
);

export const amountHiddenSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.amountHidden
);

export const isShowConfirmModalSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.isShowConfirmModal
);

export const confirmationContentSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.confirmationContent
);

export const modeSelector = createSelector(
  transferLocalSelector,
  (state) => state.transferLocal.mode
);
