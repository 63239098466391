import { memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, Spacer, Input, DatePicker } from "components/commons";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Title,
  Container,
  ButtonExit,
  Body,
  TextLine,
  TextLine13RegulartextcolorBlackLeft,
  TextLine13RegularsecondaryWhiteRight,
  ButtonWrapper,
} from "./styles";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import {
  infoAccountSelector,
  changeInfoParamsSelector,
  visibleModalEditGeneralInfoSelector,
} from "modules/auth/selectors";
import {
  setVisibleModalEditGeneralInfo,
  setVisibleModalFaceVerify,
  updateChangeInfoParams,
} from "modules/auth/redux/modalEditGeneralInfo";
import moment from "moment";
import { toast } from "components/commons/Toast";
import { IAccountInfo } from "domain/models/AccountInfo";

function ModalEditGeneralInfo() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const visibleModal = useSelector(visibleModalEditGeneralInfoSelector);
  const accountInfo = useSelector(infoAccountSelector);
  const subAccountInfo: IAccountInfo = accountInfo?.data?.subAccount || {};
  const changeInfoParams = useSelector(changeInfoParamsSelector);

  useEffect(() => {
    if (visibleModal) {
      dispatch(
        updateChangeInfoParams({
          custodycd: subAccountInfo?.currentCustomer?.custNo,
          fullname:
            subAccountInfo?.currentCustomer?.custFamilyName ||
            "" + subAccountInfo?.currentCustomer?.custGivenName ||
            "",
          idcode: subAccountInfo?.currentCustomer?.idNumber,
          iddate: subAccountInfo?.currentCustomer?.issueDate
            ? moment(
                subAccountInfo?.currentCustomer?.issueDate,
                "YYYYMMDD"
              ).format("DD/MM/YYYY")
            : null,
          // idexpired: accountInfo.idDate
          //   ? moment(accountInfo.idDate, "YYYYMMDD").format("DD/MM/YYYY")
          //   : null,
          idplace: subAccountInfo?.currentCustomer?.issueLocation,
        })
      );
    }
  }, [visibleModal]);

  const closeModal = () => {
    dispatch(setVisibleModalEditGeneralInfo(false));
  };
  const submit = () => {
    if (changeInfoParams.fullname.trim() === "") {
      return toast(
        "auth.info.editGeneralInformation.errorEmptyFullName",
        "error"
      );
    }
    if (changeInfoParams.idcode.trim() === "") {
      return toast(
        "auth.info.editGeneralInformation.errorEmptyIdCode",
        "error"
      );
    }
    if (!changeInfoParams.iddate) {
      return toast(
        "auth.info.editGeneralInformation.errorEmptyIdDate",
        "error"
      );
    }
    if (changeInfoParams.idplace.trim() === "") {
      return toast(
        "auth.info.editGeneralInformation.errorEmptyIdPlace",
        "error"
      );
    }
    if (!changeInfoParams.idexpired) {
      return toast(
        "auth.info.editGeneralInformation.errorEmptyIdExpired",
        "error"
      );
    }
    if (changeInfoParams.address.trim() === "") {
      return toast(
        "auth.info.editGeneralInformation.errorEmptyAddress",
        "error"
      );
    }
    dispatch(setVisibleModalEditGeneralInfo(false));
    dispatch(setVisibleModalFaceVerify(true));
  };

  return (
    <>
      <Modal
        show={visibleModal}
        centered
        onHide={closeModal}
        keyboard={false}
        dialogClassName="modal-389"
      >
        <Container>
          <Title>
            <div className="content">
              {intl
                .formatMessage({ id: "auth.info.editGeneralInformation.title" })
                .toUpperCase()}
            </div>
            <ButtonExit onClick={closeModal}>
              <IconExit />
            </ButtonExit>
          </Title>
          <Body>
            <TextLine>
              <TextLine13RegulartextcolorBlackLeft>
                <FormattedMessage id="auth.info.editGeneralInformation.fullName" />
                <span className="text-danger ml-1"> *</span>
              </TextLine13RegulartextcolorBlackLeft>
              <TextLine13RegularsecondaryWhiteRight textInput={true}>
                <Input
                  value={changeInfoParams.fullname}
                  onChange={(e) =>
                    dispatch(
                      updateChangeInfoParams({
                        fullname: e.target.value.trimStart(),
                      })
                    )
                  }
                  placeholder={intl.formatMessage({
                    id: "auth.info.editGeneralInformation.inputFullName",
                  })}
                />
              </TextLine13RegularsecondaryWhiteRight>
            </TextLine>

            <TextLine>
              <TextLine13RegulartextcolorBlackLeft>
                <FormattedMessage id="auth.info.editGeneralInformation.idCode" />
                <span className="text-danger ml-1"> *</span>
              </TextLine13RegulartextcolorBlackLeft>
              <TextLine13RegularsecondaryWhiteRight textInput={true}>
                <Input
                  value={changeInfoParams.idcode}
                  onChange={(e) =>
                    dispatch(
                      updateChangeInfoParams({
                        idcode: e.target.value.trimStart(),
                      })
                    )
                  }
                  placeholder={intl.formatMessage({
                    id: "auth.info.editGeneralInformation.inputIdCode",
                  })}
                />
              </TextLine13RegularsecondaryWhiteRight>
            </TextLine>

            <TextLine>
              <TextLine13RegulartextcolorBlackLeft>
                <FormattedMessage id="auth.info.editGeneralInformation.idDate" />
                <span className="text-danger ml-1"> *</span>
              </TextLine13RegulartextcolorBlackLeft>
              <TextLine13RegularsecondaryWhiteRight textInput={true}>
                <DatePicker
                  selected={
                    changeInfoParams.iddate
                      ? moment(changeInfoParams.iddate, "DD/MM/YYYY").toDate()
                      : null
                  }
                  onChange={(e) =>
                    dispatch(
                      updateChangeInfoParams({
                        iddate: e ? moment(e).format("DD/MM/YYYY") : "",
                      })
                    )
                  }
                />
              </TextLine13RegularsecondaryWhiteRight>
            </TextLine>

            <TextLine>
              <TextLine13RegulartextcolorBlackLeft>
                <FormattedMessage id="auth.info.editGeneralInformation.idPlace" />
                <span className="text-danger ml-1"> *</span>
              </TextLine13RegulartextcolorBlackLeft>
              <TextLine13RegularsecondaryWhiteRight textInput={true}>
                <Input
                  value={changeInfoParams.idplace}
                  onChange={(e) =>
                    dispatch(
                      updateChangeInfoParams({
                        idplace: e.target.value.trimStart(),
                      })
                    )
                  }
                  placeholder={intl.formatMessage({
                    id: "auth.info.editGeneralInformation.inputIdPlace",
                  })}
                />
              </TextLine13RegularsecondaryWhiteRight>
            </TextLine>

            <TextLine>
              <TextLine13RegulartextcolorBlackLeft>
                <FormattedMessage id="auth.info.editGeneralInformation.idExpired" />
                <span className="text-danger ml-1"> *</span>
              </TextLine13RegulartextcolorBlackLeft>
              <TextLine13RegularsecondaryWhiteRight textInput={true}>
                <DatePicker
                  selected={
                    changeInfoParams.idexpired
                      ? moment(
                          changeInfoParams.idexpired,
                          "DD/MM/YYYY"
                        ).toDate()
                      : null
                  }
                  onChange={(e) =>
                    dispatch(
                      updateChangeInfoParams({
                        idexpired: e ? moment(e).format("DD/MM/YYYY") : "",
                      })
                    )
                  }
                />
              </TextLine13RegularsecondaryWhiteRight>
            </TextLine>

            <TextLine>
              <TextLine13RegulartextcolorBlackLeft>
                <FormattedMessage id="auth.info.editGeneralInformation.address" />
                <span className="text-danger ml-1"> *</span>
              </TextLine13RegulartextcolorBlackLeft>
              <TextLine13RegularsecondaryWhiteRight textInput={true}>
                <Input
                  value={changeInfoParams.address}
                  onChange={(e) =>
                    dispatch(
                      updateChangeInfoParams({
                        address: e.target.value.trimStart(),
                      })
                    )
                  }
                  placeholder={intl.formatMessage({
                    id: "auth.info.editGeneralInformation.inputAddress",
                  })}
                />
              </TextLine13RegularsecondaryWhiteRight>
            </TextLine>

            <Spacer size="l" />
            <ButtonWrapper>
              <Button
                color="accept"
                className="flex-fill mr-2"
                onClick={closeModal}
              >
                <FormattedMessage id="Hủy" />
              </Button>

              <Button
                color="accept"
                className="flex-fill"
                fill={true}
                onClick={submit}
              >
                <FormattedMessage id="Cập nhật" />
              </Button>
            </ButtonWrapper>
          </Body>
        </Container>
      </Modal>
    </>
  );
}

export default memo(ModalEditGeneralInfo);
