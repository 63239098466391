export enum AppReducerType {
  LANGUAGE = "language",
  THEME = "theme",
  AUTH = "auth",
  CATEGORIES = "categories",
  STOCK_ORDER = "stockOrder",
  MARKET = "market",
  MONEY_TRANSFER = "moneyTransfer",
  TICKER_DETAILS = "tickerDetails",
  EXTENDS = "extends",
  PROFIT_LOSS = "profitLoss",
  STATEMENT = "statement",
  ADVANCE = "advance",
  ORDER_BOOK = "orderBook",
  ORDER_BOOK_WIDGET = "orderBookWidget",
  ORDER_BASKET_WIDGET = "orderBasketWidget",
  ORDER_BASKET_DER_WIDGET = "orderBasketDerWidget",
  SYSTEM = "system",
  STOCKORDERDER = "stockOrderDer",
  ORDER_HISTORY = "orderHistory",
  ODD_LOT_SHARE = "oddLotShare",
  COVERED_WARRANT = "coveredWarrant",
  LOAN_EXTENSION = "loanExtension",
  INFO_WARRANT = "infoWarrant",
  SCREENERS = "screeners",
  MARGIN_LIMIT = "marginLimit",
  NAV = "nav",
  BONDS = "bonds",
  BUSINESS_COOPERATION = "businessCooperation",
  PACK_SERVICES = "packServices",
  INVESTMENT_EFFICIENCY = "investmentEfficiency",

  COPYTRADING = "copyTrading",
  TRADINGVIEWSESSION = "tradingViewSession",

  //VGAIA
  MARGIN_CONTRACT = "marginContract",
  MARGIN_SECURITIES = "marginSecurities",
  RIGHT_INFO = "rightInfo",
  STATISTIC_ONDAY = "statisticOnday",
  STATISTIC_PRICE = "statisticPrice",
  STATISTIC_UPDOWN = "statisticUpdown",
  STATISTIC_HIGHEST_UPDOWN = "statisticHighestUpdown",
  ACCOUNT_INFO = "accountInfo",
  MRG_CONTRACT_DEBIT = "mrgContractDebit",
  MARGIN_CONTRACT_WATCH = "marginContractWatch",
  TRANSFER_LOCAL = "transferLocal",
  HOLD_UNHOLD_BANK_ACCOUNT = "holdUnholdBankAccount",
  TRANSFER_SHARE = "transferShare",
  CONTRACT_DEBIT = "contractDebit",
  CREATE_CONTRACT = "createContract",
  DEPOSIT_CONTRACT = "depositContract",
  TRANSFER_INTERNAL = "transferInternal",
  ACCOUNT_STATEMENT = "accountStatement",
  MARGIN_SECURITIES_PORTFOLIO = "marginSecuritiesPortfolio",
  DEPOSIT_POWER = "depositPower",
  POSITION_MANAGEMENT = "positionManagement",
  SUBSCRIBE_BUY = "subscribeBuy",
  DEPOSIT_LIST = "depositList",
  ADD_FRAMEWORK_CONSTRACT_FORM = "addFrameworkContractForm",
  TRANSFER_BOND = "transferBond",
  BID_ASK_BOND = "bidAskBond",
  ASSETS = "assets",
  REGISTER_TRANSFER_BONDS = "registerToTransferBonds",
  PLACE_ORDER_TRANSFER_BONDS = "placeOrderTransferBonds",
  BID_ASK_BOND_ORDER = "bidAskBondOrder",
  CHANGE_LIMIT = "changeLimit",
  ADVANCE_AUTO = "advanceAuto",
  PROFESSIONAL_INVESTOR = "professionalInvestor",
  MODAL_REGISTER = "modalRegister",
  PLACE_ORDER_RETAIL_BONDS = "placeOrderRetailBonds",
  BID_ASK_D_BOND_ORDER = "bidAskDBondOrder",
  ORDER_BOND_ADV = "placeOrderBondAdv",
  GUIDE_VIDEO = "guideVideo",
}

export enum ReduxStateType {
  INIT = "init",
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
  CANCELLED = "cancelled",
  SUCCESS = "success",
}

export interface ReduxData<T> {
  data: T;
  status: ReduxStateType;
  error?: Error;
}
