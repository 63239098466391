import { subscribeIndex } from "core/wss";
import { EMarketCodeNew } from "helper/consts";
import {
  getDataMarketIndex,
  getDataTickerDerStart,
  getTickerDerChartUpdate,
  indexUpdate,
} from "modules/system/redux/headerCard";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "react-use";
import Card from "./components/Card/Card";
import CardDer from "./components/Card/CardDer";
import { HeaderIndex } from "./styles";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import {
  IndexUpdate,
  LastSale,
  SystemState,
} from "domain/protoNew/auto_trading_pb";

export const indexInfos = [
  {
    ticker: EMarketCodeNew.HSX_IDX.toString(),
  },
  {
    ticker: EMarketCodeNew.VN30_IDX.toString(),
  },
  {
    ticker: EMarketCodeNew.HNX30.toString(),
  },
  {
    ticker: EMarketCodeNew.HNX_IDX.toString(),
  },
  {
    ticker: EMarketCodeNew.UPCOM_IDX.toString(),
  },
];

function IndexWidgets() {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    subscribeIndex("100;101;200;300;201;500");
    const listIndex = "100,101,200,300,201,500";
    dispatch(getDataMarketIndex(listIndex));
    dispatch(getDataTickerDerStart("VN30F2211"));
    const indexUpdateEventHandler = (emittedData: IndexUpdate.AsObject) => {
      if (listIndex.includes(emittedData.indexcd.toString())) {
        dispatch(indexUpdate(emittedData));
      }
    };
    const eventListenerSystemState = (emittedData: SystemState.AsObject) => {
      if (emittedData.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(getDataMarketIndex(listIndex));
        dispatch(getDataTickerDerStart("VN30F2211"));
      }
    };
    const eventListenerLastSale = (data: LastSale.AsObject) => {
      if (data.seccd === "VN30F2211") {
        dispatch(getTickerDerChartUpdate(data));
      }
    };

    emitter.on(EMMIT_ACTION_TYPE.EMMIT_IndexUpdate, indexUpdateEventHandler);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_IndexUpdate, indexUpdateEventHandler);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    };
  });

  const renderStocks = () => {
    return indexInfos.map((item, index) => {
      if (index === 1) {
        return (
          <>
            <Card key={item.ticker} {...item} />
            <CardDer key="tickerDer" />
          </>
        );
      } else {
        return <Card key={item.ticker} {...item} />;
      }
    });
  };

  return <HeaderIndex>{renderStocks()}</HeaderIndex>;
}

export default IndexWidgets;
