import styled from "styled-components/macro";

export const Container = styled.div`
  height: calc(100% - 35px);
  border: 1px solid ${({ theme }) => theme.borderColor};
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% - 30px) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.white;
      case "red":
        return theme.red;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green;
      case "violet":
        return theme.violet;
    }
  }};

  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  white-space: nowrap;
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  color: ${({ theme }) => theme.textcolorBlack};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
`;

export const PlaceOrderLink = styled.span`
  color: ${({ theme }) => theme.blue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
