import { FC } from "react";
import { useIntl } from "react-intl";
import Container from "components/commons/Container";
import Navigaton from "./components/AdvertisingOrder/navigation";
import GuideVideoContent from "./components/AdvertisingOrder";

const GuideVideo: FC = () => {
  const intl = useIntl();

  const arrayForm = [GuideVideoContent];

  return (
    <Container
      headerHistory={Navigaton}
      headerForm={intl.formatMessage({
        id: "guideVideo.navigation.value0",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default GuideVideo;
