import { toast } from "components/commons/Toast";
import {
  Wrap,
  DetailRow,
  DetailLabel,
  DetailContentWrapper,
  TextDetail,
  DetailInputWrapper,
  DetailWrapper,
  DetailCol,
  DetailCheckBox,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useState, useEffect } from "react";
import storage from "helper/storage";
import { useSelector } from "react-redux";
import {
  getSubAccoListRegisterApi,
  registerSubAcco,
} from "core/http/apis/subAccoRegister";
import { Button, ModalConfirm } from "components/commons";
import { accountInfoSelector } from "modules/auth/selectors";
import CheckBox from "./CheckboxTransfer";

const SubAccoregisterCotent = () => {
  const intl = useIntl();
  const account = storage.getMasterAccount();
  const [list, setList] = useState<any[]>([]);
  const [listDefault, setListDefault] = useState<any[]>([]);
  const { currentUser } = useSelector(accountInfoSelector);

  const getList = async () => {
    const res: any = await getSubAccoListRegisterApi({ custNo: account });
    if (res?.statusCode === 0) {
      setList(res?.data);
      setListDefault(res?.data);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const submitData = async () => {
    const params = list.map((e: any) => ({
      selected: e?.selected,
      subAccoNo: e?.subAccoNo,
      subAccoCd: e?.subAccoCd,
      registerId: e?.registerId,
    }));
    const res: any = await registerSubAcco({
      custNo: account,
      subAccoList: params,
    });
    if (res?.statusCode === 0) {
      toast("subAccoRegister.formLeft.messSuccess", "success");
    } else {
      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  const [modalConfirm, setModalConfirm] = useState<boolean>(false);

  const renderContent = () => {
    return list.map((e: any, i: any) => (
      <CheckBox
        values={e?.subAccoCd}
        checked={e?.selected}
        textCheck={e?.subAccoNo}
        onClick={(check: any) => {
          const el = list.map((item) => {
            if (item?.subAccoCd === check) {
              return { ...item, selected: !list[i].selected };
            } else {
              return item;
            }
          });
          setList(el);
        }}
      />
    ));
  };

  const [checkAll, setcheckAll] = useState<boolean>(false);
  const selectall = () => {
    if (!checkAll) {
      const el = list.map((item) => {
        return { ...item, selected: true };
      });
      setList(el);
    } else {
      setList(listDefault);
    }
    setcheckAll(!checkAll);
  };
  return (
    <>
      <Wrap>
        <DetailWrapper>
          <DetailCol>
            <DetailRow>
              <DetailLabel>
                <FormattedMessage id="subAccoRegister.formLeft.subAcco" />
              </DetailLabel>
              <DetailContentWrapper className="text-right">
                <TextDetail>{account}</TextDetail>
              </DetailContentWrapper>
            </DetailRow>

            <DetailRow>
              <DetailLabel>
                <FormattedMessage id="subAccoRegister.formLeft.bank" />
              </DetailLabel>
              <DetailContentWrapper className="text-right">
                <TextDetail>{currentUser?.displayName}</TextDetail>
              </DetailContentWrapper>
            </DetailRow>

            <DetailRow>
              <DetailLabel>
                <FormattedMessage id="subAccoRegister.formLeft.subAccoNo" />
              </DetailLabel>
              <DetailInputWrapper className="text-left"></DetailInputWrapper>
            </DetailRow>
            <DetailCheckBox>{renderContent()}</DetailCheckBox>
            <DetailRow>
              <CheckBox
                values={"All"}
                checked={checkAll}
                textCheck={intl.formatMessage({
                  id: "subAccoRegister.status.valueAll",
                })}
                onClick={() => selectall()}
              />
            </DetailRow>
          </DetailCol>
        </DetailWrapper>
        <div className="p-2">
          <Button
            color="gradientaAccept"
            className="button-filter"
            onClick={() => setModalConfirm(true)}
            width={"210px"}
          >
            {intl.formatMessage({
              id: "subAccoRegister.formLeft.btnSuccess",
            })}
          </Button>
        </div>
      </Wrap>
      <ModalConfirm
        visibleModalConfirm={modalConfirm}
        setVisibleModalConfirm={(val: boolean) => setModalConfirm(val)}
        setConfirm={() => submitData()}
        title={intl.formatMessage({
          id: "subAccoRegister.modal.title",
        })}
        content={intl.formatMessage({
          id: "subAccoRegister.modal.content",
        })}
      />
    </>
  );
};

export default SubAccoregisterCotent;
