import queryString from "query-string";
import { ISearchStatisticUpdownParams } from "./types";

export const searchStatisticUpdownApi = (
  params: ISearchStatisticUpdownParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return `/statisticByUpDown${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
