import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const createTransferBondSelector = (state: RootState) =>
  state.transferBond;

export const activeTabSelector = createSelector(
  createTransferBondSelector,
  (state) => state.activeTab
);

export const listTransferBondSelector = createSelector(
  createTransferBondSelector,
  (state) => state.list
);

export const loadingSearchDataSelector = createSelector(
  createTransferBondSelector,
  (state) => state.loading
);

export const filterTransferBondSelector = createSelector(
  createTransferBondSelector,
  (state) => state.filter
);
export const statusCancelSelector = createSelector(
  createTransferBondSelector,
  (state) => state.statusCancel
);

export const statusValidateTimeSelector = createSelector(
  createTransferBondSelector,
  (state) => state.statusValidateTime
);
export const itemDetailSelector = createSelector(
  createTransferBondSelector,
  (state) => state.itemDetail
);

export const isShowConfirmModalSelector = createSelector(
  createTransferBondSelector,
  (state) => state.isShowConfirmModal
);
