import { ReactComponent as Category } from "assets/image/svg/ic_heart.svg";
import { ReactComponent as Market } from "assets/image/svg/ic_market.svg";
import { ReactComponent as StockOrder } from "assets/image/svg/ic_order.svg";
import { ReactComponent as StockOrderDer } from "assets/image/svg/ic_order_der.svg";
import { ReactComponent as DVCB } from "assets/image/svg/ic_service.svg";
import { ReactComponent as Asset } from "assets/image/svg/ic_asset.svg";
import { ReactComponent as DVTC } from "assets/image/svg/ic_financial_service.svg";
import { ReactComponent as BlueCategory } from "assets/image/svg/ic_blue_heart.svg";
import { ReactComponent as BlueMarket } from "assets/image/svg/ic_blue_market.svg";
import { ReactComponent as BlueStockOrder } from "assets/image/svg/ic_blue_order.svg";
import { ReactComponent as BlueStockOrderDer } from "assets/image/svg/ic_blue_order_der.svg";
import { ReactComponent as BlueDVCB } from "assets/image/svg/ic_blue_service.svg";
import { ReactComponent as BlueAsset } from "assets/image/svg/ic_blue_asset.svg";
import { ReactComponent as BlueDVTC } from "assets/image/svg/ic_blue_financial_service.svg";
import NavItem from "./components/NavItem";
import NavItemMenu from "./components/NavItemMenu";
import React, { useEffect, useState } from "react";
import pathRoutes from "helper/pathRoutes";
import { useHistory, useLocation } from "react-router-dom";
import { SideBarCard, MainMenu } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setTitleTabBrowser,
  updateScreenLogData,
} from "modules/system/redux/common";
import { useIntl } from "react-intl";
import { listHotKeysSelector } from "themes/redux";
import { ListKeyShortcuts } from "themes/types";
import HotKeysManager from "components/commons/HotkeysManager";
import paths from "helper/pathRoutes";
import { VersionWeb } from "AppConfig";
import Storage from "helper/storage";
import { changeColumn } from "modules/categories/redux";
import { columnsDataDefault } from "modules/categories/constant";
import { logDataSelector } from "modules/system/selectors";
import { RealtimeConst } from "helper/constFirebase";

import { getScreenName } from "helper/utils";
import { onBuySellType } from "modules/stock-order/redux/placeOrder";
import {
  DerTradeTypeEnum,
  updatePlaceOrderDerType,
} from "modules/stock-order-der/redux/PlaceOrderDer";

const getDataSideBar = (listHotKeyStorage?: ListKeyShortcuts) => {
  return [
    {
      name: "sidebar.categories",
      icon: Category,
      activeIcon: BlueCategory,
      slug: pathRoutes.categories,
      fn: listHotKeyStorage?.keyShortcutCategory
        ? listHotKeyStorage?.keyShortcutCategory
        : "F1",
    },
    {
      name: "sidebar.marketUp",
      icon: Market,
      activeIcon: BlueMarket,
      slug: pathRoutes.market,
      fn: listHotKeyStorage?.keyShortcutMarket
        ? listHotKeyStorage?.keyShortcutMarket
        : "F2",
      option: [
        {
          label: "sidebar.market",
          value: pathRoutes.market,
        },
        {
          label: "sidebar.techAnalysis",
          value: pathRoutes.techAnalysis,
        },
        {
          label: "sidebar.screeners",
          value: pathRoutes.screeners,
          checkLogin: true,
        },
      ],
    },
    {
      name: "sidebar.stockOrder",
      icon: StockOrder,
      activeIcon: BlueStockOrder,
      slug: pathRoutes.stockOrder,
      checkLogin: true,
      fn: listHotKeyStorage?.keyShortcutStockOrder
        ? listHotKeyStorage?.keyShortcutStockOrder
        : "F3",
    },
    {
      name: "sidebar.placeOrderDer",
      icon: StockOrderDer,
      activeIcon: BlueStockOrderDer,
      slug: pathRoutes.stockOrderDer,
      checkLogin: true,
      fn: listHotKeyStorage?.keyShortcutStockOrderDer
        ? listHotKeyStorage?.keyShortcutStockOrderDer
        : "F4",
    },
    {
      name: "sidebar.asset",
      icon: Asset,
      activeIcon: BlueAsset,
      slug: pathRoutes.assetsIncurred,
      checkLogin: true,
      option: [
        {
          label: "sidebar.assetsIncurred",
          value: pathRoutes.assetsIncurred,
          checkLogin: true,
        },

        {
          label: "sidebar.profitLoss",
          value: pathRoutes.profitLoss,
          checkLogin: true,
        },
        {
          label: "sidebar.accountStatementSideBar",
          value: pathRoutes.accountStatement,
          checkLogin: true,
        },
      ],
    },
    {
      name: "sidebar.basicServices",
      icon: DVCB,
      activeIcon: BlueDVCB,
      slug: pathRoutes.advance,
      checkLogin: true,
      option: [
        {
          label: "sidebar.advance",
          value: pathRoutes.advance,
          checkLogin: true,
        },
        {
          label: "sidebar.transferLocal",
          value: pathRoutes.transferInternal,
          checkLogin: true,
        },
        {
          label: "sidebar.transferShare",
          value: pathRoutes.transferShare,
          checkLogin: true,
        },
        {
          label: "sidebar.subscribeBuy",
          value: pathRoutes.subscribeBuy,
          checkLogin: true,
        },
        {
          label: "sidebar.holdUnholdBankAccount",
          value: pathRoutes.holdUnholdBankAccount,
          checkLogin: true,
        },
        {
          label: "sidebar.orderHistory",
          value: pathRoutes.orderHistory,
          checkLogin: true,
        },
        {
          label: "sidebar.confirmation",
          value: pathRoutes.confirmation,
          checkLogin: true,
        },
        {
          label: "sidebar.guideVideo",
          value: pathRoutes.guideVideo,
          checkLogin: true,
        },
      ],
    },
    {
      name: "sidebar.financialServices",
      icon: DVTC,
      activeIcon: BlueDVTC,
      slug: pathRoutes.marginSecuritiesPortfolio,
      checkLogin: true,
      option: [
        {
          label: "sidebar.marginSecuritiesPortfolio",
          value: pathRoutes.marginSecuritiesPortfolio,
          checkLogin: true,
        },
        {
          label: "sidebar.addMhChangeLimit",
          value: pathRoutes.changeLimit,
          checkLogin: true,
        },
        {
          label: "sidebar.contractDebit",
          value: pathRoutes.contractDebit,
          checkLogin: true,
        },
        {
          label: "sidebar.bonds",
          value: pathRoutes.registerToTransferBonds,
          option: [
            {
              label: "sidebar.professionalInvestor",
              value: pathRoutes.professionalInvestor,
              checkLogin: true,
            },
            {
              label: "sidebar.registerToTransferBonds",
              value: pathRoutes.registerToTransferBonds,
              checkLogin: true,
            },
            {
              label: "sidebar.placeOrderToTransferBonds",
              value: pathRoutes.placeOrderTransferBonds,
              checkLogin: true,
            },
            {
              label: "sidebar.transferBond",
              value: pathRoutes.transferBond,
              checkLogin: true,
            },
            {
              label: "sidebar.bidAskBondOrder",
              value: pathRoutes.bidAskBondOrder,
              checkLogin: true,
            },
            {
              label: "sidebar.bidAskBond",
              value: pathRoutes.bidAskBond,
              checkLogin: true,
            },
            {
              label: "sidebar.placeOrderRetailBonds",
              value: pathRoutes.placeOrderRetailBonds,
              checkLogin: true,
            },
            {
              label: "sidebar.bidAskDBondOrder",
              value: pathRoutes.bidAskDBondOrder,
              checkLogin: true,
            },

            {
              label: "sidebar.placeOrderBondAdv",
              value: pathRoutes.placeOrderBondAdv,
              checkLogin: true,
            },
            {
              label: "sidebar.advertisingOrder",
              value: pathRoutes.advertisingOrder,
              checkLogin: true,
            },
            {
              label: "sidebar.transferHistory",
              value: pathRoutes.transferHistory,
              checkLogin: true,
            },
          ],
          checkLogin: true,
        },
        {
          label: "sidebar.manegerMoney",
          value: pathRoutes.manegerMoney,
          option: [
            {
              label: "sidebar.profitChart",
              value: pathRoutes.manegerMoney,
              checkLogin: true,
            },
            {
              label: "sidebar.createContract",
              value: pathRoutes.createContract,
              checkLogin: true,
            },
            {
              label: "sidebar.depositPower",
              value: pathRoutes.depositPower,
              checkLogin: true,
            },
            {
              label: "sidebar.depositContract",
              value: pathRoutes.depositContract,
              checkLogin: true,
            },
          ],
          checkLogin: true,
        },
        {
          label: "sidebar.pMoney",
          value: pathRoutes.profitMoney,
          option: [
            {
              label: "sidebar.profitMoney",
              value: pathRoutes.profitMoney,
              checkLogin: true,
            },
            {
              label: "sidebar.subAccoRegister",
              value: pathRoutes.subAccoRegister,
              checkLogin: true,
            },
            {
              label: "sidebar.addFrameworkContractFormSideBar",
              value: pathRoutes.addFrameworkContractForm,
              checkLogin: true,
            },
            {
              label: "sidebar.depositListPMoney",
              value: pathRoutes.depositList,
              checkLogin: true,
            },
          ],
          checkLogin: true,
        },
      ],
    },
  ];
};

const SideBar: React.FC = React.memo(() => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const versionWebCache = Storage.getVersionWeb();
  if (versionWebCache !== VersionWeb) {
    Storage.setVersionWeb(VersionWeb);
    dispatch(changeColumn({ colummns: columnsDataDefault }));
    Storage.remove("TECH_ANALYSIS_FLEXLAYOUT"); //remove tài chính ở F2
  }

  const handlers = {
    GO_TO_HOME_PAGE: (e: globalThis.KeyboardEvent | undefined) => {
      e?.preventDefault();
      history.push(paths.categories);
    },
    GO_TO_MARKET_PAGE: (e: globalThis.KeyboardEvent | undefined) => {
      e?.preventDefault();
      history.push(paths.market);
    },
    GO_TO_STOCK_ORDER_PAGE: (e: globalThis.KeyboardEvent | undefined) => {
      e?.preventDefault();
      history.push(paths.stockOrder);
    },
    GO_TO_STOCK_ORDER_DER_PAGE: (e: globalThis.KeyboardEvent | undefined) => {
      e?.preventDefault();
      history.push(paths.stockOrderDer);
    },
    GO_TO_STOCK_ORDER_BUY: (e: globalThis.KeyboardEvent | undefined) => {
      e?.preventDefault();
      dispatch(onBuySellType("buy"));
      dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.L));
    },
    GO_TO_STOCK_ORDER_SELL: (e: globalThis.KeyboardEvent | undefined) => {
      e?.preventDefault();
      dispatch(onBuySellType("sell"));
      dispatch(updatePlaceOrderDerType(DerTradeTypeEnum.S));
    },
  };
  const logData = useSelector(logDataSelector);
  const listHotKeyStorage = useSelector(listHotKeysSelector);
  const dataSideBar = getDataSideBar(listHotKeyStorage);
  const [dataSideBarChange, setDataSideBarChange] = useState(dataSideBar);

  useEffect(() => {
    const screen = getScreenName(location.pathname);

    if (logData.screen) {
      const resLogDataExitScreen = {
        event: RealtimeConst.exitScreen,
      };
      Storage.eventFirebase(resLogDataExitScreen);
    }

    const resLogDataEnterScreen = {
      fromScreen: logData.screen,
      screen: screen,
      event: RealtimeConst.enterScreen,
    };
    Storage.eventFirebase(resLogDataEnterScreen);
    dispatch(updateScreenLogData(screen));

    if (location.pathname === pathRoutes.stockOrder) {
      dispatch(setTitleTabBrowser(pathRoutes.stockOrder));
      return;
    }
    if (location.pathname === pathRoutes.stockOrderDer) {
      dispatch(setTitleTabBrowser(pathRoutes.stockOrderDer));
      return;
    }
    dispatch(setTitleTabBrowser(""));
  }, [location.pathname]);

  useEffect(() => {
    setDataSideBarChange(dataSideBar);
  }, [listHotKeyStorage]);

  return (
    <>
      <SideBarCard>
        <MainMenu>
          {dataSideBarChange.map(
            ({ name, icon, activeIcon, slug, fn, option, checkLogin }, key) =>
              option ? (
                <NavItemMenu
                  key={name}
                  name={intl.formatMessage({ id: name })}
                  icon={icon}
                  activeIcon={activeIcon}
                  option={option}
                  slug={slug}
                  fn={fn}
                  checkLogin={checkLogin}
                />
              ) : (
                <NavItem
                  key={key}
                  isActive={location.pathname === slug}
                  name={intl.formatMessage({ id: name })}
                  icon={icon}
                  activeIcon={activeIcon}
                  slug={slug!}
                  fn={fn}
                  checkLogin={checkLogin}
                  checkDotRed={false}
                />
              )
          )}
        </MainMenu>
        <HotKeysManager handlers={handlers} allowChanges />
      </SideBarCard>
    </>
  );
});

export default SideBar;
