import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

export const TextColor = styled.div<{ color: string; background: string }>`
  color: ${({ theme, color }) => (color ? theme[color] : theme.textcolorWhite)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex: 1;
  overflow: hidden;
  padding-right: 4px;
  ${({ background, theme }) =>
    !!background && `background: ${theme[background]} !important`};
  :hover {
    background: ${({ theme }) => theme.grey30} !important;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: ${({ theme }) => theme.brandPrimary};
    opacity: 1;
    border-radius: 4px;
    padding: 8px 12px;
    div {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandPrimary};
      opacity: 1;
    }
  }
`;
