import classNames from "classnames";
import { Spacer } from "components/commons";
import {
  pushMenuDotRed,
  setIsOpenExtra,
  setVisibleContainerExtra,
  setVisibleIconExtra,
} from "modules/system/redux/common";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Name, NavItemCard } from "./styles";
import { toggleModalLogin } from "modules/auth/redux";
import { isAuthenticatedSelector } from "modules/auth/selectors";
interface Props {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  isActive: boolean;
  slug: string;
  fn?: string;
  checkLogin?: Boolean;
  checkDotRed?: Boolean;
}

const NavItem: React.FC<Props> = React.memo((props) => {
  const dispatch = useDispatch();
  const [isHover, setHover] = useState(false);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const {
    icon: Icon,
    activeIcon: ActiveIcon,
    isActive,
    slug,
    fn,
    name,
    checkDotRed,
    checkLogin,
  } = props;
  return (
    <Link to={slug} className={"flex-1"}>
      <NavItemCard
        className={classNames({ "center-for-item": fn })}
        onClick={() => {
          if (checkLogin && isAuthenticated === false) {
            //check chưa đăng nhập sẽ mở login
            dispatch(toggleModalLogin(true));
          } else {
            if (slug !== "/stock-order" && slug !== "/stock-order-der") {
              dispatch(setVisibleIconExtra(true));
            } else {
              dispatch(setIsOpenExtra(false));
              dispatch(setVisibleIconExtra(false));
              dispatch(setVisibleContainerExtra(false));
            }
          }
          if (checkDotRed) dispatch(pushMenuDotRed(name));
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        isActive={isActive}
      >
        {/* <div className="dotRed">
            {checkDotRed && !menuDotRed.some((value) => value === name) && (
              <DotRed />
            )}
          </div> */}
        {isActive ? <ActiveIcon /> : <Icon />}
        <Spacer size="s" />

        <Name isActive={isActive} isHover={isHover}>
          {name}
        </Name>

        {/* {fn && <div className="hot-key">{fn}</div>} */}
      </NavItemCard>
    </Link>
  );
});

export default NavItem;
