import styled from "styled-components/macro";

export const ContentContainer = styled.div`
  box-shadow: 0px -1px 0px 0px ${({ theme }) => theme.shadowInner1} inset;
  h2 {
    font-weight: ${({ theme }) => theme.typeFaces.title.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.title.size};
    line-height: ${({ theme }) => theme.typeFaces.title.lineHeight};
    margin-bottom: 20px;
    color: ${({ theme }) => theme.neutral1};
  }
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderSection = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.neutral7};
  border-bottom: 1px solid ${({ theme }) => theme.shadowInner1};
  padding: 6px 16px;
  background-color: ${({ theme }) => theme.bgElevated5};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 64px;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item-selection {
    display: flex;
    align-items: center;
    min-width: 400px;
    padding: 16px 20px 16px 0;
    gap: 10px;
  }
  .label {
    width: 130px;
  }
  .selection {
    width: 100%;
  }
`;

export const HorizontalBarChartWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const MainContent = styled.div`
  padding: 16px;
  height: 100%;
`;
