import { createSelector } from "@reduxjs/toolkit";
import { ReactComponent as IconDelete } from "assets/image/svg/ic_delete_border.svg";
import { ReactComponent as IconDeleteFill } from "assets/image/svg/ic_delete_border_fill.svg";
import { Button } from "components/commons";
import { toast } from "components/commons/Toast";
import { IDeleteScreenerParam } from "core/http/apis/screener/types";
import { IScreener } from "domain/models/Screener";
import storage from "helper/storage";
import {
  convertStringToObjectArr,
  FILTER_TYPE,
  IOptionType,
} from "modules/screeners/constants";
import {
  choiceMyScreenerFilter,
  deleteScreenerRequest,
  getListScreenerRequest,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import numeral from "numeral";
import { FC, MouseEvent, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { RootState } from "redux/store";
import {
  BodyCard,
  ButtonWrapper,
  CardContentCell,
  CardLabelCell,
  CardRow,
  Container,
  DeleteButton,
  HeaderCard,
  HorizontalBar,
  MiniCard,
  StickyButtonWrapper,
  TitleCard,
  Wrapper,
} from "./styles";

const screenersSelector = (state: RootState) => state.screeners;

const myScreenerFilterListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) => screenersSelector.dataMyScreener.list
);

const screenerSelectedSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataMyScreener.screenerSelected
);

const filterText = (text: string) => {
  if (!text) return "";
  const newText = text.replaceAll("Sàn", "").replaceAll("Exchange", "").trim();
  return newText;
};

const MyScreener: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const myScreenerFilterList = useSelector(myScreenerFilterListSelector);
  const screenerSelected = useSelector(screenerSelectedSelector);
  const [screenerChoice, setScreenerChoice] = useState<IScreener | null>(null);

  const clickMiniCardHandler = (item: IScreener) => {
    setScreenerChoice(item);
  };

  const cancelSelectMyScreener = () => {
    setScreenerChoice(null);
    dispatch(choiceMyScreenerFilter(screenerChoice!));
  };

  const applyMyScreener = () => {
    if (!screenerChoice) {
      toast("screeners.tabs.myScreener.applyError", "error");
    }
    dispatch(choiceMyScreenerFilter(screenerChoice!));
  };

  const clickDeleteMiniCardHandler = (
    e: MouseEvent<HTMLDivElement>,
    screener: IScreener
  ) => {
    e.stopPropagation();
    const masterAccount = storage.getMasterAccount();

    const deleteScreenerParam: IDeleteScreenerParam = {
      id: screener.screenerId,
      userId: masterAccount,
    };

    dispatch(deleteScreenerRequest(deleteScreenerParam));
  };

  useEffectOnce(() => {
    dispatch(getListScreenerRequest());
  });

  const generateFilterListSample = useMemo(() => {
    return myScreenerFilterList
      ?.filter((record) => record?.screener?.screenerType === 1)
      ?.map((item) => {
        const rangeList = item?.screenerFilter?.filter(
          (filterItem) => filterItem.type === FILTER_TYPE.RANGE
        );
        const optionList = item?.screenerFilter?.filter(
          (filterItem) => filterItem.type === FILTER_TYPE.OPTION
        );
        const radioList = item?.screenerFilter?.filter(
          (filterItem) => filterItem.type === FILTER_TYPE.RADIO
        );

        return (
          <MiniCard
            onClick={() => clickMiniCardHandler(item)}
            isActive={item?.screenerId === screenerChoice?.screenerId}
          >
            <HeaderCard>
              <TitleCard className="myScreener_title_card">
                {item?.screener?.name}
              </TitleCard>
            </HeaderCard>
            <BodyCard>
              {!!optionList &&
                optionList?.length > 0 &&
                optionList.map((optionItem) => {
                  const optionsFormatter: IOptionType[] =
                    convertStringToObjectArr(optionItem?.options ?? "");
                  return (
                    <CardRow key={optionItem.key}>
                      <CardLabelCell>{optionItem.name}:</CardLabelCell>
                      {/* {optionItem.optionsList.map((optionItemMini, ind) => (
                      <CardContentCell key={optionItemMini.value}>
                        <>{filterText(optionItemMini.name)}</>
                        <>
                          {ind !== optionItem.optionsList.length - 1 ? "," : "."}
                        </>
                      </CardContentCell>
                    ))} */}
                      {!!optionItem?.options &&
                        optionsFormatter.map((optionItemMini, ind) => (
                          <CardContentCell key={optionItemMini.value}>
                            <>{filterText(optionItemMini.name)}</>
                            <>
                              {ind !== optionsFormatter.length - 1 ? "," : "."}
                            </>
                          </CardContentCell>
                        ))}
                    </CardRow>
                  );
                })}
              {!!rangeList &&
                rangeList.length > 0 &&
                rangeList.map((rangeItem) => (
                  <CardRow key={rangeItem.key}>
                    <CardLabelCell>{rangeItem.name}:</CardLabelCell>
                    <CardContentCell>
                      {typeof rangeItem?.minValue === "number"
                        ? numeral(rangeItem?.minValue).format("0,0")
                        : "-"}
                      {rangeItem?.unit} -{" "}
                      {typeof rangeItem?.maxValue === "number"
                        ? numeral(rangeItem?.maxValue).format("0,0")
                        : "-"}
                      {rangeItem?.unit}.
                    </CardContentCell>
                  </CardRow>
                ))}
              {!!radioList && radioList.length > 0 && (
                <CardRow>
                  <CardLabelCell>
                    <FormattedMessage id="screeners.tabs.screener.technical.legend" />
                    :
                  </CardLabelCell>
                  <CardContentCell>
                    {radioList.map((radioItem, ind) => (
                      <span key={radioItem.name}>
                        {radioItem.name}
                        {ind !== radioList.length - 1 ? "," : "."}{" "}
                      </span>
                    ))}
                  </CardContentCell>
                </CardRow>
              )}
            </BodyCard>
          </MiniCard>
        );
      });
  }, [myScreenerFilterList, screenerSelected, screenerChoice]);

  const generateFilterList = useMemo(() => {
    return myScreenerFilterList
      ?.filter((record) => record?.screener?.screenerType === 2)
      ?.map((item) => {
        const rangeList = item?.screenerFilter?.filter(
          (filterItem) => filterItem.type === FILTER_TYPE.RANGE
        );
        const optionList = item?.screenerFilter?.filter(
          (filterItem) => filterItem.type === FILTER_TYPE.OPTION
        );
        const radioList = item?.screenerFilter?.filter(
          (filterItem) => filterItem.type === FILTER_TYPE.RADIO
        );

        return (
          <MiniCard
            onClick={() => clickMiniCardHandler(item)}
            isActive={item?.screenerId === screenerChoice?.screenerId}
          >
            <HeaderCard>
              <TitleCard className="myScreener_title_card">
                {item?.screener?.name}
              </TitleCard>
              <DeleteButton
                onClick={(e) => clickDeleteMiniCardHandler(e, item)}
              >
                {item?.screenerId === screenerSelected?.screenerId ? (
                  <IconDeleteFill />
                ) : (
                  <IconDelete />
                )}
              </DeleteButton>
            </HeaderCard>
            <BodyCard>
              {!!optionList &&
                optionList?.length > 0 &&
                optionList.map((optionItem) => {
                  const optionsFormatter: IOptionType[] =
                    convertStringToObjectArr(optionItem?.options ?? "");
                  return (
                    <CardRow key={optionItem.key}>
                      <CardLabelCell>{optionItem.name}:</CardLabelCell>
                      {/* {optionItem.optionsList.map((optionItemMini, ind) => (
                      <CardContentCell key={optionItemMini.value}>
                        <>{filterText(optionItemMini.name)}</>
                        <>
                          {ind !== optionItem.optionsList.length - 1 ? "," : "."}
                        </>
                      </CardContentCell>
                    ))} */}
                      {!!optionItem?.options &&
                        optionsFormatter.map((optionItemMini, ind) => (
                          <CardContentCell key={optionItemMini.value}>
                            <>{filterText(optionItemMini.name)}</>
                            <>
                              {ind !== optionsFormatter.length - 1 ? "," : "."}
                            </>
                          </CardContentCell>
                        ))}
                    </CardRow>
                  );
                })}
              {!!rangeList &&
                rangeList.length > 0 &&
                rangeList.map((rangeItem) => (
                  <CardRow key={rangeItem.key}>
                    <CardLabelCell>{rangeItem.name}:</CardLabelCell>
                    <CardContentCell>
                      {typeof rangeItem?.minValue === "number"
                        ? numeral(rangeItem?.minValue).format("0,0")
                        : "-"}
                      {rangeItem?.unit} -{" "}
                      {typeof rangeItem?.maxValue === "number"
                        ? numeral(rangeItem?.maxValue).format("0,0")
                        : "-"}
                      {rangeItem?.unit}.
                    </CardContentCell>
                  </CardRow>
                ))}
              {!!radioList && radioList.length > 0 && (
                <CardRow>
                  <CardLabelCell>
                    <FormattedMessage id="screeners.tabs.screener.technical.legend" />
                    :
                  </CardLabelCell>
                  <CardContentCell>
                    {radioList.map((radioItem, ind) => (
                      <span key={radioItem.name}>
                        {radioItem.name}
                        {ind !== radioList.length - 1 ? "," : "."}{" "}
                      </span>
                    ))}
                  </CardContentCell>
                </CardRow>
              )}
            </BodyCard>
          </MiniCard>
        );
      });
  }, [myScreenerFilterList, screenerSelected, screenerChoice]);

  return (
    <Container>
      <Wrapper>
        <>{generateFilterListSample}</>
        <HorizontalBar>.</HorizontalBar>
        <>{generateFilterList}</>
      </Wrapper>
      <StickyButtonWrapper>
        <ButtonWrapper>
          {screenerChoice?.screenerId === screenerSelected?.screenerId &&
          !!screenerChoice ? (
            <Button
              className="button-confirm"
              color="accept"
              onClick={cancelSelectMyScreener}
            >
              {intl.formatMessage({
                id: "screeners.tabs.myScreener.cancelSelectButton",
              })}
            </Button>
          ) : (
            <Button
              className="button-confirm"
              color="gradientaAccept"
              onClick={applyMyScreener}
              fill
            >
              {intl.formatMessage({
                id: "screeners.tabs.myScreener.applyButton",
              })}
            </Button>
          )}
        </ButtonWrapper>
      </StickyButtonWrapper>
    </Container>
  );
};

export default MyScreener;
