import { memo, useEffect } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  getVolumeAndTime,
  updateHistoryListRealTime,
  clearData,
} from "modules/stock-order-der/redux/volumeAndTime";
import { useSelector, useDispatch } from "react-redux";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import VolumeAndTime from "./VolumnAndTime";
import { selectedTickerSelector } from "modules/stock-order-der/redux/selectors";
import {
  hasMqttOfflineSelector,
  isVisibilityPageSelector,
} from "modules/system/selectors";
import { LastSale, SystemState } from "domain/protoNew/auto_trading_pb";

function StockOrderDerVolumeAndTime() {
  const dispatch = useDispatch();
  const selectedTicker = useSelector(selectedTickerSelector);
  const isVisibilityPage = useSelector(isVisibilityPageSelector);
  const hasMqttOffline = useSelector(hasMqttOfflineSelector);

  useEffect(() => {
    if (selectedTicker !== "") {
      dispatch(getVolumeAndTime(selectedTicker));
    } else {
      dispatch(clearData());
    }
    const eventListenerSystemState = (data: SystemState.AsObject) => {
      if (data.status === SystemState.StatusCode.AVAILABLE) {
        dispatch(getVolumeAndTime(selectedTicker));
      }
    };
    const eventListenerLastSale = (dataEmmit: LastSale.AsObject) => {
      if (selectedTicker === dataEmmit.seccd) {
        const ticker = {
          id: uuid(),
          color: dataEmmit.colorcode,
          time: moment(dataEmmit.mattime, "Hmmss").format("HH:mm:ss"),
          price: dataEmmit.lastprice,
          volume: dataEmmit.lastqty,
          eside: dataEmmit.side?.data ?? "",
        };
        dispatch(updateHistoryListRealTime(ticker));
      }
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_SystemState, eventListenerSystemState);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_LASTSALE, eventListenerLastSale);
      emitter.off(
        EMMIT_ACTION_TYPE.EMMIT_SystemState,
        eventListenerSystemState
      );
    };
  }, [selectedTicker]);

  useEffect(() => {
    if (isVisibilityPage && hasMqttOffline) {
      if (!!selectedTicker) {
        dispatch(getVolumeAndTime(selectedTicker));
      }
    }
  }, [isVisibilityPage]);

  return <VolumeAndTime />;
}

export default memo(StockOrderDerVolumeAndTime);
