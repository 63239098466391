import { SizeType } from "components/commons/Spacer";
import styled from "styled-components/macro";

const Size: { [key in SizeType]?: number } = {
  l: 32,
  m: 32,
  s: 24,
};

export const InputConntainer = styled.div<{
  size?: SizeType;
  isBorderActive?: boolean;
  radius?: number;
  disabled?: boolean;
}>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.grey100};
  border: 1px solid
    ${({ theme, isBorderActive }) =>
      isBorderActive ? theme.brandPrimary : theme.grey70};
  overflow: hidden;
  border-radius: ${({ radius }) => (radius ? radius : 4)}px;
  height: ${({ size = "l" }) => Size[size]}px;
  display: flex;
  align-items: center;
  overflow: hidden;
  div {
    input {
      ${({ disabled }) => (disabled ? "cursor: not-allowed;" : null)}
    }
    ${({ disabled }) => (disabled ? "cursor: not-allowed;" : null)}
  }
  .search-icon {
    cursor: pointer;
  }
`;

export const InputBox = styled.div<{
  isTextCenter?: boolean;
  size?: SizeType;
}>`
  padding: 0 8px;
  flex: 1;
  .input {
    background-color: ${({ theme }) => theme.grey100};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.textcolorWhite};
    height: 100%;
    width: 100%;
    font-size: ${({ theme, size }) =>
      size === "s"
        ? theme.typeFaces.caption12.size
        : theme.typeFaces.body.size};
    line-height: ${({ theme, size }) =>
      size === "s"
        ? theme.typeFaces.caption12.lineHeight
        : theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme, size }) =>
      size === "s"
        ? theme.typeFaces.caption12.weight.regular
        : theme.typeFaces.body.weight.regular};
    text-align: ${({ isTextCenter }) => (isTextCenter ? "center" : "left")};
  }
`;
