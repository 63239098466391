import {
  Container,
  ErrorVerify,
  Title,
  TextUpLoad,
  ShowImage,
  DotRed,
  Body,
  Footer,
} from "./styles";
import { memo, useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Button, Spacer, Input } from "components/commons";
import TextArea from "components/commons/Input/textArea";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { postCareFeedback } from "core/http/apis/feedback";
import { useIntl, FormattedMessage } from "react-intl";
import * as yup from "yup";
import { visibleModalSupportSelector } from "modules/system/selectors";
import { setVisibleModalSupport } from "modules/system/redux/common";
import { ReactComponent as Camera } from "assets/image/svg/camera.svg";
import { ReactComponent as IconClose } from "assets/image/svg/iconClose.svg";
import { useFilePicker } from "use-file-picker";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { FileContent } from "use-file-picker/dist/interfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import storage from "helper/storage";
import { accountInfoSelector } from "modules/auth/selectors";
import { formatEmail, formatPhoneNumber, regExEmailPhone } from "helper/utils";
import { RealtimeConst, GroupEvent } from "helper/constFirebase";
interface Inputs {
  commentArea: string;
  phoneNumber: string;
}

const schema = yup.object().shape({
  commentArea: yup.string().trim().required("system.modalSupport.errorComment"),
  phoneNumber: yup
    .string()
    .trim()
    .required("system.modalSupport.errorEmail")
    .matches(regExEmailPhone, "system.modalSupport.errorValidateEmailOrPhone"),
});

const ModalSupport = memo(() => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [error, setError] = useState("");
  const [isOpenImage, setOpenImage] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [fileShow, setFileShow] = useState<FileContent[]>([]);
  const [fileUpLoad, setFileUpLoad] = useState<File[]>([]);
  const [statusModalSuccess, setStatusModalSuccess] = useState(false);
  const visibleModal = useSelector(visibleModalSupportSelector);
  const accountInfo = useSelector(accountInfoSelector);

  const [openFileSelector, { filesContent, plainFiles, errors: errorsFile }] =
    useFilePicker({
      readAs: "DataURL",
      accept: "image/*",
      multiple: true,
      // limitFilesConfig: { max: 6 },
      // minFileSize: 1,
      maxFileSize: 50, // in megabytes
    });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setFileShow(fileShow.concat(filesContent));
  }, [filesContent]);

  useEffect(() => {
    setFileUpLoad(fileUpLoad.concat(plainFiles));
  }, [plainFiles]);

  if (errorsFile.length) {
  }

  useEffect(() => {
    if (visibleModal) {
      setError("");
      setFileShow([]);
      setFileUpLoad([]);
      reset({ commentArea: "", phoneNumber: "" });
    }
  }, [visibleModal, statusModalSuccess]);

  const onSubmit = async ({ commentArea, phoneNumber }: Inputs) => {
    if (!isNaN(Number(phoneNumber))) {
      if (!formatPhoneNumber(phoneNumber)) {
        return;
      }
    } else {
      if (!formatEmail(phoneNumber)) {
        return;
      }
    }

    let formData = new FormData();
    fileUpLoad.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("masterAccount", storage.getMasterAccount() || "GUEST");
    formData.append("content", commentArea);
    formData.append(
      "customerName",
      accountInfo?.currentCustomer?.customerName || "GUEST"
    );
    formData.append("emailOrSDT", phoneNumber);
    // formData.append("channel", "W");
    formData.append("subjectTitle", "Đóng góp ý kiến");

    try {
      await postCareFeedback(formData);

      const resLogData = {
        eventGroup: GroupEvent.setting,
        event: RealtimeConst.submitSupport,
        data: formData,
        message: "Submit form success",
      };
      storage.commonPushLogFirebase(resLogData);
      setStatusModalSuccess(true);
    } catch (error: any) {
      const resLogData = {
        eventGroup: GroupEvent.setting,
        event: RealtimeConst.submitSupport,
        message: "Submit form fail",
      };
      storage.commonPushLogFirebase(resLogData);
      setError(error.code);
    }
  };

  return (
    <>
      <Modal
        show={visibleModal}
        centered
        onHide={() => dispatch(setVisibleModalSupport(false))}
        keyboard={false}
        dialogClassName="modal-468"
      >
        <Container>
          <Title>
            <FormattedMessage id="system.modalSupport.title" />
          </Title>
          <div className="body">
            <Row>
              <Col xs={12} className="content">
                <FormattedMessage id="system.modalSupport.content" />
              </Col>
            </Row>
            <Spacer size="m" />
            <Row>
              <Col className="lable-text">
                <div>
                  <FormattedMessage id="system.modalSupport.lableComment" />
                  <DotRed> *</DotRed>
                </div>
              </Col>
              <Col xs={8}>
                <TextArea
                  textArea={true}
                  name="commentArea"
                  control={control}
                  placeholder={intl.formatMessage({
                    id: "system.modalSupport.enterComment",
                  })}
                />

                <span className="text-error">
                  {errors.commentArea?.message &&
                    intl.formatMessage({ id: errors.commentArea?.message })}
                </span>
              </Col>
            </Row>

            <Spacer size="m" />
            <ShowImage>
              {fileShow.map((file, index) => (
                <span key={index}>
                  <IconClose
                    onClick={() => {
                      let array = [...fileShow];
                      array.splice(index, 1);
                      setFileShow(array);
                      let arrayFile = [...fileUpLoad];
                      arrayFile.splice(index, 1);
                      setFileUpLoad(arrayFile);
                    }}
                  />

                  <img
                    onClick={() => {
                      setOpenImage(true);
                      setPhotoIndex(index);
                    }}
                    alt={file.name}
                    src={file.content}
                    width="56"
                    height="56"
                  ></img>
                  <br />
                </span>
              ))}
              {isOpenImage && (
                <Lightbox
                  reactModalStyle={{
                    overlay: {
                      zIndex: 1050,
                    },
                  }}
                  mainSrc={fileShow[photoIndex].content}
                  nextSrc={fileShow[(photoIndex + 1) % fileShow.length].content}
                  prevSrc={
                    fileShow[
                      (photoIndex + fileShow.length - 1) % fileShow.length
                    ].content
                  }
                  onCloseRequest={() => setOpenImage(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + fileShow.length - 1) % fileShow.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % fileShow.length)
                  }
                />
              )}
            </ShowImage>
            <TextUpLoad>
              <label onClick={() => openFileSelector()}>
                <Camera />
                <FormattedMessage id="system.modalSupport.upPhoto" />
              </label>
            </TextUpLoad>

            <Row>
              <Col className="lable-text">
                <FormattedMessage id="system.modalSupport.email" />
                <DotRed> *</DotRed>
              </Col>
              <Col xs={8}>
                <Input
                  name="phoneNumber"
                  control={control}
                  placeholder={intl.formatMessage({
                    id: "system.modalSupport.enterEmail",
                  })}
                />
                <span className="text-error">
                  {errors.phoneNumber?.message &&
                    intl.formatMessage({ id: errors.phoneNumber?.message })}
                </span>
              </Col>
            </Row>
            <Spacer size="l" />
            <ErrorVerify>
              {error !== "" && intl.formatMessage({ id: error })}
            </ErrorVerify>
            <Spacer size="l" />
            <Spacer size="l" />
            <Spacer size="l" />
            <Spacer size="l" />
            <div className="block">
              <Button
                style={{ flex: 1 }}
                color="accept"
                onClick={() => dispatch(setVisibleModalSupport(false))}
              >
                <FormattedMessage id="auth.forgetPassword.cancel" />
              </Button>
              <Spacer size="m" />
              <Button
                style={{ flex: 1 }}
                color="accept"
                fill
                disabled={!!errors.phoneNumber || !!errors.commentArea}
                // loading={status === ReduxStateType.LOADING}
                onClick={handleSubmit(onSubmit)}
              >
                <FormattedMessage id="system.modalSupport.send" />
              </Button>
            </div>
          </div>
        </Container>
        <Modal
          show={statusModalSuccess}
          centered
          keyboard={false}
          onHide={() => {
            setStatusModalSuccess(false);
            dispatch(setVisibleModalSupport(false));
          }}
          dialogClassName="modal-284"
        >
          <Container>
            <div className="title">
              <FormattedMessage id="system.modalSupport.success" />
            </div>
            <Body padding={24}>
              <div className="footer">
                <Body>
                  <FormattedMessage id="system.modalSupport.contentSuccess" />
                </Body>
                <Footer>
                  <Button
                    fill={true}
                    color="accept"
                    className="flex-1"
                    radius={4}
                    onClick={() => {
                      setStatusModalSuccess(false);
                      dispatch(setVisibleModalSupport(false));
                    }}
                  >
                    <FormattedMessage id="common.buttonClose" />
                  </Button>
                </Footer>
              </div>
            </Body>
          </Container>
        </Modal>
      </Modal>
    </>
  );
});

export default ModalSupport;
