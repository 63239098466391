import {
  Title,
  Icon,
  Content,
  ButtonDelete,
  Header,
  ButtonBack,
  Container,
} from "./styles";
import { NotificationDetail } from "domain/models/Notification";
import { ReactComponent as IconBack } from "assets/image/svg/ic_back.svg";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  changeVisibleNotificationDetail,
  NotificationState,
} from "modules/auth/redux/notification";

interface Props {
  data: NotificationDetail;
  onDelete: (date?: string, noticeId?: string) => void;
}

const notificationSelector = (state: RootState) => state.auth.notification;

const isVisibleDetailSelector = createSelector(
  notificationSelector,
  (notificationState: NotificationState) =>
    notificationState.isVisibleNotificationDetail
);

function NotificationInfo({ data, onDelete }: Props) {
  const isVisibleDetail = useSelector(isVisibleDetailSelector);
  const dispatch = useDispatch();
  const onBack = () => {
    dispatch(changeVisibleNotificationDetail(!isVisibleDetail));
  };
  return (
    <Container>
      <Header>
        <Icon>
          <ButtonBack onClick={() => onBack()}>
            <IconBack />
          </ButtonBack>
        </Icon>
        <Title>{data.title}</Title>
        <Icon>
          <ButtonDelete
            onClick={() =>
              onDelete(data.date?.toString(), data.noticeId?.toString())
            }
          >
            <IconTrash />
          </ButtonDelete>
        </Icon>
      </Header>
      <Content
        dangerouslySetInnerHTML={{
          __html: data.contentFull ? data.contentFull : "",
        }}
      ></Content>
    </Container>
  );
}

export default NotificationInfo;
