import { toast } from "components/commons/Toast";
import {
  searchTransferBond,
  cancelTransferBond,
  validateTimeToTransferBond,
} from "core/http/apis/transfer-bond/";
import {
  ISearchTransferBondParams,
  ICancelTransferBondParams,
} from "core/http/apis/transfer-bond/types";
import {
  ISearchTransferBondResponse,
  ICancelTransferBondResponse,
  IValidateTimeToTransferBondResponse,
  TransferBond,
} from "domain/models/TransferBond";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  searchTransferBondFailure,
  searchTransferBondRequest,
  searchTransferBondSuccess,
  cancelTransferBondRequest,
  cancelTransferBondSuccess,
  cancelTransferBondFailure,
  validateTimeToTransferBondRequest,
  validateTimeToTransferBondSuccess,
  validateTimeToTransferBondFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
} from "./redux";

function* searchTransferBondWorker(
  action: ReturnType<typeof searchTransferBondRequest>
) {
  try {
    const params: ISearchTransferBondParams = action.payload;
    const response: ISearchTransferBondResponse = yield call(
      searchTransferBond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchTransferBondSuccess(response));
    } else {
      yield put(searchTransferBondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchTransferBondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* cancelTransferBondWorker(
  action: ReturnType<typeof cancelTransferBondRequest>
) {
  try {
    const params: ICancelTransferBondParams = action.payload;
    const response: ICancelTransferBondResponse = yield call(
      cancelTransferBond,
      params
    );

    if (response?.statusCode === 0) {
      yield put(cancelTransferBondSuccess(response));
    } else {
      yield put(cancelTransferBondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(cancelTransferBondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* validateTimeToTransferBondWorker(
  action: ReturnType<typeof validateTimeToTransferBondRequest>
) {
  try {
    const response: IValidateTimeToTransferBondResponse = yield call(
      validateTimeToTransferBond
    );

    if (response?.statusCode === 0) {
      yield put(validateTimeToTransferBondSuccess(response));
    } else {
      yield put(validateTimeToTransferBondFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(validateTimeToTransferBondFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateModalStatusWorker(
  action: ReturnType<typeof updateModalStatusRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
    }: {
      isVisible: boolean;
      itemDetail?: TransferBond;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateModalStatusSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateModalStatusSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* searchTransferBondWatcher() {
  yield takeLatest(searchTransferBondRequest.type, searchTransferBondWorker);
}
function* cancelTransferBondWatcher() {
  yield takeLatest(cancelTransferBondRequest.type, cancelTransferBondWorker);
}
function* validateTimeToTransferBondWatcher() {
  yield takeLatest(
    validateTimeToTransferBondRequest.type,
    validateTimeToTransferBondWorker
  );
}
function* updateModalStatusWatcher() {
  yield takeLatest(updateModalStatusRequest.type, updateModalStatusWorker);
}

export default function* advanceLimitSaga() {
  yield all([
    searchTransferBondWatcher(),
    cancelTransferBondWatcher(),
    validateTimeToTransferBondWatcher(),
    updateModalStatusWatcher(),
  ]);
}
