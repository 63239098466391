import styled from "styled-components/macro";
import { UIConst } from "helper/consts";

export const NavItemMenus = styled.div`
  flex: 1;
  height: 100%;
  :hover {
    .menu {
      display: block;
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavItemCard = styled.div<{ isActive: boolean }>`
  /* flex-direction: column; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  ${({ isActive, theme }) =>
    isActive &&
    `
      background-color: ${theme.grey60};
    `}
  :hover {
    /* border-top: 4px solid ${({ theme }) => theme.brandPrimary}; */
    background-color: ${({ theme }) => theme.grey60};
    svg {
      path {
        fill: ${({ theme }) => theme.brandPrimary};
      }
    }
    .hot-key {
      background: ${({ theme }) => theme.brandPrimary};
      color: ${({ theme }) => theme.grey60};
    }
  }
  .hot-key {
    background: ${({ theme }) => theme.grey60};
    border-radius: 2px;
    height: 12px;
    width: 40px;
    font-weight: ${({ theme }) => theme.typeFaces.minimal9.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.minimal9.size};
    line-height: ${({ theme }) => theme.typeFaces.minimal9.lineHeight};
    color: ${({ theme }) => theme.grey100};
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isActive, theme }) =>
      isActive &&
      `
      background: ${theme.brandPrimary};
      color: ${theme.grey60};
    `}
  }
`;

export const Name = styled.div<{ isActive: boolean; isHover: boolean }>`
  color: ${({ isActive, isHover, theme }) =>
    isActive || isHover ? theme.grey0 : theme.grey20};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  white-space: nowrap;
`;

export const MenuItemCard = styled.div`
  width: 230px;
  height: auto;
  /* overflow-y: auto; */
  background-color: ${({ theme }) => theme.background3};
  position: absolute;
  top: 36px;
  @media (max-width: ${UIConst.LAGRE_SCREEN_WIDTH}) {
    top: 30px;
  }
  z-index: 5;
  border-radius: 4px;

  display: none;
  a {
    :last-child {
      .item-select {
        box-shadow: none;
      }
    }
  }
  :hover {
    a {
      text-decoration: none;
    }
  }

  .link-child {
    position: relative;

    :hover {
      .menu-child {
        display: block;
      }
    }
  }

  @media (max-height: 900px) {
    /* max-height: 350px; */
    /* overflow-y: auto; */

    scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(94, 102, 115);
      border-radius: 3px;
      height: 50px;
    }
  }
`;

export const MenuItemCardChild = styled.div`
  width: 230px;
  height: auto;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.background3};
  position: absolute;
  top: 0;
  right: 230px;

  z-index: 5;
  border-radius: 4px;

  display: none;
  a {
    :last-child {
      .item-select {
        box-shadow: none;
      }
    }
  }
  :hover {
    a {
      text-decoration: none;
    }
  }

  @media (max-height: 900px) {
    max-height: 350px;
    overflow-y: auto;

    scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.background1};
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(94, 102, 115);
      border-radius: 3px;
      height: 50px;
    }
  }

  @media (max-height: 800px) {
    max-height: 250px;
  }
  @media (max-height: 700px) {
    max-height: 180px;
  }
  @media (max-height: 600px) {
    max-height: 120px;
  }
  @media (max-height: 520px) {
    max-height: 80px;
  }
`;

export const ItemSelect = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  z-index: 5;
  position: relative;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  :hover {
    background-color: ${({ theme }) => theme.background4};
    .menu-child {
      display: block;
    }
  }
  color: ${({ theme }) => theme.textcolorWhite};
`;
