/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo } from "react";
import { FilterWrapper } from "components/commons/FormStyle/Filter/styles";
import { Wrap, Label, WrapSelect } from "./styles";
import { Select } from "components/commons";
import { FormattedMessage, useIntl } from "react-intl";
import { GetAccoList } from "helper/sessionData";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import {
  doAnswerBrokerApi,
  inquiryBuyingPowerApi,
} from "core/http/apis/assetsIncurred";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilteAssetsEffect,
  setInformationAssets,
  setSubAccoNo,
  setTableAssets,
} from "modules/assetsIncurred/redux";
import { toast } from "components/commons/Toast";
import {
  selectFilteAssetsEffect,
  selectTab,
} from "modules/assetsIncurred/selectors";
import storage from "helper/storage";
import { ASSETS_TAB, listTypeAccount } from "modules/assetsIncurred/constants";
import { selectUserInfo } from "modules/auth/selectors";

const Search: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useSelector(selectTab);
  const acount = storage.getMasterAccount();
  const userInfo = useSelector(selectUserInfo);
  const optionAcc = [{ label: acount, value: acount }];
  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Inquiry
  );
  const { typeAccount } = useSelector(selectFilteAssetsEffect);

  const typeAccounts = useMemo(() => listTypeAccount(intl), [intl]);

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const getdata = async (subAcc: any) => {
    try {
      dispatch(setSubAccoNo(subAcc ?? ""));
      const res: any = await inquiryBuyingPowerApi({
        subAccoCd: "",
        subAccoNo: subAcc ?? "",
        secCd: "",
      });
      if (res?.statusCode === 0) {
        dispatch(setInformationAssets(res?.data));
      } else {
        toast(res?.errorCode, "error", res?.message, true);
      }

      const resTable: any = await doAnswerBrokerApi({
        subAccoNo: subAcc ?? "",
      });
      if (resTable?.statusCode === 0) {
        dispatch(setTableAssets(resTable?.data?.secBalanceData2));
      } else {
        toast(resTable?.errorCode, "error", resTable?.message, true);
      }
    } catch (error) {}
  };

  const changeSubAcc = (e: any) => {
    getdata(e?.value);
  };

  useEffect(() => {
    getdata(accountOptions?.[0]?.value);
  }, []);

  const result =
    userInfo && userInfo?.entrustList
      ? userInfo?.entrustList
          .reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.custNo === o.custNo)) {
              unique.push(o);
            }
            return unique;
          }, [])
          .map((e: any) => {
            if (e?.businessCd === 0 || e?.businessCd === 9) {
              return e;
            }
          })
      : [];

  return (
    <Wrap width={activeTab === ASSETS_TAB.ASSETS_SUM ? "40%" : "20%"}>
      {activeTab === ASSETS_TAB.ASSETS_SUM && (
        <FilterWrapper className="p-0">
          <div className="d-flex">
            <Label>
              <p>
                <FormattedMessage id={"assets.search.account.label"} />
              </p>
            </Label>
            <WrapSelect>
              <Select
                defaultValue={typeAccounts[0]}
                options={typeAccounts}
                onChange={(e: any) => {
                  dispatch(
                    setFilteAssetsEffect({
                      value: e?.value,
                      key: "typeAccount",
                    })
                  );
                }}
              />
            </WrapSelect>
          </div>
        </FilterWrapper>
      )}

      <FilterWrapper className="p-0">
        <div className="d-flex">
          <Label>
            <p>
              <FormattedMessage id={"assets.search.account.label"} />
            </p>
          </Label>
          <WrapSelect>
            {activeTab === ASSETS_TAB.ASSETS && (
              <Select
                defaultValue={accountOptions[0]}
                options={accountOptions}
                onChange={(e: any) => {
                  changeSubAcc(e);
                }}
              />
            )}
            {activeTab === ASSETS_TAB.ASSETS_INCURRED && (
              <Select
                defaultValue={optionAcc[0]}
                options={optionAcc}
                onChange={(e: any) => {}}
              />
            )}
            {activeTab === ASSETS_TAB.ASSETS_SUM && typeAccount === "1" && (
              <Select
                defaultValue={accountOptions[0]}
                options={accountOptions}
                onChange={(e: any) => {
                  changeSubAcc(e);
                  dispatch(
                    setFilteAssetsEffect({
                      value: e?.value,
                      key: "subAccoNo",
                    })
                  );
                }}
              />
            )}
            {activeTab === ASSETS_TAB.ASSETS_SUM && typeAccount !== "1" && (
              <Select
                defaultValue={result[0]}
                options={result}
                onChange={(e: any) => {
                  dispatch(
                    setFilteAssetsEffect({
                      value: e?.custNo,
                      key: "custNo",
                    })
                  );
                }}
                getLabel="custNo"
                getValue="custNo"
              />
            )}
          </WrapSelect>
        </div>
      </FilterWrapper>
    </Wrap>
  );
};

export default Search;
