import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const depositPowerSelector = (state: RootState) => state.depositPower;

export const activeTabSelector = createSelector(
  depositPowerSelector,
  (state) => state.activeTab
);

export const listDepositPowerSelector = createSelector(
  depositPowerSelector,
  (state) => state.list
);

export const loadingSearchDataSelector = createSelector(
  depositPowerSelector,
  (state) => state.loading
);

export const filterDepositPowerSelector = createSelector(
  depositPowerSelector,
  (state) => state.filter
);
export const statusLiquidateSelector = createSelector(
  depositPowerSelector,
  (state) => state.statusLiquidate
);
export const depositPowerEditSelector = createSelector(
  depositPowerSelector,
  (state) => state.depositPower
);

export const statusDepositPowerRegisterSelector = createSelector(
  depositPowerSelector,
  (state) => state.statusDepositPowerRegister
);
