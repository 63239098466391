import queryString from "query-string";
import {
  ICheckPinTypeParams,
  ICreateSmsOtpParam,
  ICreateSmsOtpResetPasswordParam,
  IGetUserAuthenSessionParams,
  IIsAlreadyRegisterAuthenFor134Params,
  IValidateOtpCodeParam,
} from "./types";

export const verifyTwoPhaseAuthen = "/verifyTwoPhaseAuthen";

// Url check pin type
export const checkPinTypeUrl = (params: ICheckPinTypeParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/checkPinType${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// url create sms otp
export const getSmsOtpAuthenCodeUrl = (params: ICreateSmsOtpParam) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/getSmsOtpAuthenCode${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getSmsOtpAuthenCodeResetPasswordUrl = (
  params: ICreateSmsOtpResetPasswordParam
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `public/getResetPasswordOtpCode${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const validateOtpCodeUrl = (params: IValidateOtpCodeParam) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/validateOtpCode${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// url check loai xac thuc cap 2
export const isAlreadyRegisterAuthenFor134Url = (
  params: IIsAlreadyRegisterAuthenFor134Params
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/isAlreadyRegisterAuthenFor134${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

// url dang ky loai xac thuc cap 2
export const registerOTPAuthenUrl = "/registerOTPAuthen";

// url lay trang thai xac thuc theo phien
export const getUserAuthenSessionUrl = (
  params: IGetUserAuthenSessionParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/getUserAuthenSession${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// url chuyen trang thai xac thuc theo phien
export const updateOneTimeAuthenUrl = "/updateOneTimeAuthen";

// create QR code
export const generateQRDataUrl = "/createQrData";
