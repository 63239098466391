import { memo, useMemo } from "react";
import max from "lodash/max";
import { FormattedMessage } from "react-intl";
import { Spacer } from "components/commons";
import TickerItem from "./TickerItem";
import VolumeItem from "./VolumeItem";

import {
  Body,
  HeaderSection,
  Container,
  Header,
  Table,
  VolumeAndTimeWrapper,
} from "./styles";
import { Ticker } from "domain/models/TimeAndSale";
import { SecTimeSalePro } from "domain/protoNew/auto_trading_pb";
import DateTypeMenu from "./DateTypeMenu";

interface VolumeAndTimeProps {
  isEnableHeader?: boolean;
  selectedTicker?: string | null;
  historyList: Ticker[];
  volumeList: SecTimeSalePro.AsObject[];
}

function VolumeAndTime({
  isEnableHeader = false,
  historyList,
  volumeList,
  selectedTicker,
}: VolumeAndTimeProps) {
  const renderVolume = useMemo(() => {
    return (
      <Table>
        <Header>
          <FormattedMessage id="widgets.volumeAndTime.matchHistory" />
        </Header>

        <Body>
          {historyList.map((ticker) => (
            <TickerItem key={ticker.id} ticker={ticker} />
          ))}
        </Body>
      </Table>
    );
  }, [historyList]);

  const renderHistory = useMemo(() => {
    const maxPercentage = max(volumeList.map((item) => item.percentbytotal));

    return (
      <Table>
        <Header>
          <FormattedMessage id="widgets.volumeAndTime.volumeAnalysis" />
          <DateTypeMenu />
        </Header>

        <Body>
          {volumeList.map((volume, index) => (
            <VolumeItem
              key={index.toString()}
              maxPercent={maxPercentage || 0}
              {...volume}
            />
          ))}
        </Body>
      </Table>
    );
  }, [volumeList]);

  return (
    <>
      {isEnableHeader && (
        <HeaderSection>
          <FormattedMessage id="widgets.volumeAndTime.title" />
        </HeaderSection>
      )}
      <VolumeAndTimeWrapper className="volume-and-time-wrapper">
        <Container>
          {renderVolume}
          <Spacer />
          {renderHistory}
        </Container>
      </VolumeAndTimeWrapper>
    </>
  );
}

export default memo(VolumeAndTime) as React.FC<VolumeAndTimeProps>;
