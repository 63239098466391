import { ClickableBox } from "./styles";
import React, { memo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FormattedMessage } from "react-intl";
import { StyledTooltip } from "components/widgets/generals/MarketWatch/styles";
import { IndustryTab } from "./types";

export interface MarketWatchButtonProps {
  active: boolean;
  value: IndustryTab;
  onClick?: (value: IndustryTab) => void;
  children: React.ReactElement | React.ReactElement[] | undefined;
  tabName: string;
}

function IndustryButton({
  active,
  value,
  onClick,
  children,
  tabName,
}: MarketWatchButtonProps) {
  return (
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <StyledTooltip id="tooltip">
          <FormattedMessage
            id={`widgets.industryDescription.${tabName}.description`}
          />
        </StyledTooltip>
      }
    >
      <ClickableBox active={active} onClick={() => onClick && onClick(value)}>
        {children}
      </ClickableBox>
    </OverlayTrigger>
  );
}

export default memo(IndustryButton);
