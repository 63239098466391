import { Spacer } from "components/commons";
import parseInt from "lodash/parseInt";
import { findDertOrderCond } from "modules/stock-order-der/redux/OrderBookDer";
import moment from "moment";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  PopConfirmOrderBody,
  PopConfirmOrderContainer,
  PopConfirmOrderTitle,
  TextColor,
  StatusCell,
  TitleContent,
  ButtonExit,
  RejectReason,
} from "../styles";
import DetailOrderDerCondTable from "./DetailOrderDerCondTable";
import themes from "themes/abstracts/_themes";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { themeTypeSelector } from "themes/redux";
import { IFindDertOrderCondParams } from "core/http/apis/tradeinfo/types";
import { ConditionalCommandTypeEnum } from "../../PlaceOrderDer/ConditionalCommandType";

interface Props {
  showModal: boolean;
  setShowModal: any;
  dataRow?: any;
}

const OrderDertCondDetailModal: FC<Props> = ({
  showModal,
  setShowModal,
  dataRow,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const themeType = useSelector(themeTypeSelector);

  const detailClone = useMemo(() => {
    return {
      ...dataRow?.original,
    };
  }, [dataRow, intl]);

  const onLoadData = async () => {
    try {
      const params: IFindDertOrderCondParams = {
        custNo: dataRow?.original?.custNo,
        refDate: dataRow?.original?.refDate,
        refNo: dataRow?.original?.refNo,
      };

      dispatch(findDertOrderCond(params));
    } catch (error: any) {}
  };

  useEffect(() => {
    onLoadData();
  }, [dataRow]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="modal-437"
      >
        <PopConfirmOrderContainer>
          <PopConfirmOrderTitle>
            <div className="flex-1"></div>
            <TitleContent side={detailClone?.tradeTypeDisplay}>
              <FormattedMessage id="OrderBookDerDetail.title" />
              &nbsp;
              {detailClone &&
                intl
                  .formatMessage({ id: detailClone.tradeTypeDisplay })
                  .toUpperCase()}
              &nbsp;
              {detailClone?.secCd}
            </TitleContent>
            <ButtonExit>
              <IconExit onClick={() => setShowModal(false)} />
            </ButtonExit>
          </PopConfirmOrderTitle>
          <PopConfirmOrderBody className="pl-0 pr-0">
            <div className="order-info pl-3 pr-3">
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.tradeNumber" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone && detailClone.refNo ? detailClone.refNo : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.account" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone?.custNo}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.status" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  <StatusCell
                    textColor={
                      detailClone?.orderStatusColor === "reject"
                        ? themes[themeType].solidRed
                        : detailClone?.orderStatusColor === "success"
                        ? themes[themeType].solidGreen
                        : detailClone?.orderStatusColor === "pending"
                        ? themes[themeType].solidBlue
                        : themes[themeType].solidBlue
                    }
                  >
                    {detailClone &&
                      intl.formatMessage({
                        id: detailClone?.orderStatusDisplay,
                      })}
                  </StatusCell>
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.tradeType" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  <TextColor
                    color={detailClone?.tradeType === 2 ? "green" : "red"}
                  >
                    {detailClone
                      ? intl.formatMessage({
                          id: detailClone?.condTypeDisplay,
                        })
                      : "---"}
                  </TextColor>
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.orderVolumn" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone ? detailClone.ordQty : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.orderPrice" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone ? detailClone.orderPriceDisp : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              {(detailClone?.condType === ConditionalCommandTypeEnum.UP ||
                detailClone?.condType === ConditionalCommandTypeEnum.DOWN) && (
                <>
                  <Row>
                    <Col xs={5} className="text-left">
                      <FormattedMessage id="OrderBookDerDetail.activePrice" />
                    </Col>
                    <Col xs={7} className="text-right text-white-neutral1">
                      {detailClone?.condType === ConditionalCommandTypeEnum.UP
                        ? numeral(detailClone.priceUp).format("0,0.0")
                        : detailClone?.condType ===
                          ConditionalCommandTypeEnum.DOWN
                        ? numeral(detailClone.priceDown).format("0,0.0")
                        : "---"}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                </>
              )}
              {(detailClone?.condType === ConditionalCommandTypeEnum.BULLBEAR ||
                detailClone?.condType ===
                  ConditionalCommandTypeEnum.IFDONEGAIN) && (
                <>
                  <Row>
                    <Col xs={5} className="text-left">
                      <FormattedMessage id="OrderBookDerDetail.grossAmp" />
                    </Col>
                    <Col xs={7} className="text-right text-white-neutral1">
                      {detailClone?.grossAmp
                        ? numeral(detailClone.grossAmp).format("0,0.00")
                        : "---"}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                </>
              )}
              {(detailClone?.condType === ConditionalCommandTypeEnum.BULLBEAR ||
                detailClone?.condType ===
                  ConditionalCommandTypeEnum.IFDONESTOP) && (
                <>
                  <Row>
                    <Col xs={5} className="text-left">
                      <FormattedMessage id="OrderBookDerDetail.cutLostAmp" />
                    </Col>
                    <Col xs={7} className="text-right text-white-neutral1">
                      {detailClone?.cutLostAmp && detailClone?.cutLostAmp > 0
                        ? numeral(detailClone.cutLostAmp).format("0,0.00")
                        : "---"}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                </>
              )}
              {detailClone?.condType === ConditionalCommandTypeEnum.OCO && (
                <>
                  <Row>
                    <Col xs={5} className="text-left">
                      <FormattedMessage id="OrderBookDerDetail.cutLostPrice" />
                    </Col>
                    <Col xs={7} className="text-right text-white-neutral1">
                      {detailClone?.cutLostPrice &&
                      detailClone?.cutLostPrice > 0
                        ? numeral(detailClone.cutLostPrice).format("0,0.00")
                        : "---"}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                </>
              )}
              {detailClone?.condType ===
                ConditionalCommandTypeEnum.TRAILINGSTOP && (
                <>
                  <Row>
                    <Col xs={5} className="text-left">
                      <FormattedMessage id="OrderBookDerDetail.rangeStop" />
                    </Col>
                    <Col xs={7} className="text-right text-white-neutral1">
                      {detailClone?.rangeStop && detailClone?.rangeStop > 0
                        ? numeral(detailClone.rangeStop).format("0,0.00")
                        : "---"}
                    </Col>
                  </Row>
                  <Spacer size="s" />
                </>
              )}
              {detailClone?.condType !== ConditionalCommandTypeEnum.UP &&
                detailClone?.condType !== ConditionalCommandTypeEnum.DOWN &&
                detailClone?.condType !== ConditionalCommandTypeEnum.Normal && (
                  <>
                    <Row>
                      <Col xs={5} className="text-left">
                        <FormattedMessage id="OrderBookDerDetail.limitOffset" />
                      </Col>
                      <Col xs={7} className="text-right text-white-neutral1">
                        {detailClone?.limitOffset &&
                        detailClone?.limitOffset > 0
                          ? numeral(detailClone.limitOffset).format("0,0.00")
                          : "---"}
                      </Col>
                    </Row>
                    <Spacer size="s" />
                  </>
                )}
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.orderTime" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone
                    ? moment(
                        new Date(parseInt(detailClone.regDateTime?.toString()))
                      ).format("DD/MM/YYYY HH:mm:ss")
                    : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              <Row>
                <Col xs={5} className="text-left">
                  <FormattedMessage id="OrderBookDerDetail.chanel" />
                </Col>
                <Col xs={7} className="text-right text-white-neutral1">
                  {detailClone ? detailClone.channelDisplay : "---"}
                </Col>
              </Row>
              <Spacer size="s" />
              {detailClone?.notes && (
                <Row>
                  <Col xs={4}>
                    {intl.formatMessage({
                      id: "widgets.orderBookDetail.rejectReason",
                    })}
                  </Col>
                  <Col xs={8} className="text-right text-color-light">
                    <RejectReason>
                      {detailClone?.notes ? (
                        <FormattedMessage
                          id={"error." + detailClone?.notes.split(":")[0]}
                        />
                      ) : (
                        "---"
                      )}
                    </RejectReason>
                  </Col>
                </Row>
              )}
            </div>
            <div className="footer">
              <DetailOrderDerCondTable />
            </div>
          </PopConfirmOrderBody>
        </PopConfirmOrderContainer>
      </Modal>
    </>
  );
};
export default OrderDertCondDetailModal;
