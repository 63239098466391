import { BAR_WIDTH } from "components/widgets/generals/StatBasketContribution/configs";
import { getTopIndexContribution } from "core/grpc";
import { TopIndexContributionData } from "domain/protoNew/auto_trading_pb";
import { EMarketCodeNew } from "helper/consts";
import get from "lodash/get";
import {
  ChartData,
  getStatBasketContributionData,
  getStatBasketContributionDataFailure,
  getStatBasketContributionDataSuccess,
} from "modules/market/redux/statBasketContribution";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { ThemeType } from "themes/types";
import { setCurrentMarketCode } from "../redux";
import { currentMarketCodeSelector } from "../redux/selectors";

function* getStatBasketContributionSaga() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );

    const response: TopIndexContributionData.AsObject = yield call(
      getTopIndexContribution,
      currentMarketCode
    );

    const labels: string[] = [];
    const contributionPercentData: number[] = [];
    const contributionValueData: number[] = [];
    const colorContributionData: string[] = [];
    const themeType: ThemeType = yield select(themeTypeSelector);

    response?.topincreaseList.forEach((item) => {
      if (item.contributeval !== 0 && item.contributepercent !== 0) {
        labels.push(item.seccd);
        contributionPercentData.push(item.contributepercent);
        contributionValueData.push(item.contributeval);
        colorContributionData.push(
          item.contributepercent!! > 0
            ? get(themes[themeType], "green50")
            : get(themes[themeType], "red50")
        );
      }
    });
    response?.topdecreaseList.forEach((item) => {
      if (item.contributeval !== 0 && item.contributepercent !== 0) {
        labels.push(item.seccd);
        contributionPercentData.push(item.contributepercent);
        contributionValueData.push(item.contributeval);
        colorContributionData.push(
          item.contributepercent!! > 0
            ? get(themes[themeType], "green50")
            : get(themes[themeType], "red50")
        );
      }
    });

    const statBasketContributionData: ChartData = {
      labels,
      datasets: [
        {
          label: "percentage",
          data: contributionPercentData,
          fill: true,
          backgroundColor: colorContributionData,
          market: labels,
          maxBarThickness: BAR_WIDTH,
        },
        {
          label: "value",
          data: contributionValueData,
          hidden: true,
        },
      ],
    };

    yield put(
      getStatBasketContributionDataSuccess({ data: statBasketContributionData })
    );
  } catch (e: any) {
    yield put(getStatBasketContributionDataFailure(e));
  }
}

function* fetchStatBasketContributionWatcher() {
  yield takeLatest(
    getStatBasketContributionData.type,
    getStatBasketContributionSaga
  );
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, getStatBasketContributionSaga);
}

export default function* liquidityWidget() {
  yield all([
    fetchStatBasketContributionWatcher(),
    setCurrentMarketCodeWatcher(),
  ]);
}
