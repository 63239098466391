import { RegisterOptions } from "react-hook-form";
import classNames from "classnames";
import { InputBox, InputConntainer } from "./style";
import React, { memo, useState } from "react";
import { ReactComponent as Eye } from "assets/image/svg/eye.svg";
import { ReactComponent as Eye2 } from "assets/image/svg/eye2.svg";
import { ReactComponent as SearchIcon } from "assets/image/svg/ic_search.svg";
import { SizeType } from "../Spacer";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  innerRef?: React.RefObject<HTMLInputElement>;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  sizeInput?: SizeType;
  name?: string;
  control?: any;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  textLayer?: string;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  isSearch?: boolean;
  isBorderActive?: boolean;
  radius?: number;
  isTextCenter?: boolean;
}

interface InputProps extends Props {
  error?: any;
  // invalid?: any;
}

const Input: React.FC<InputProps> = ({
  title,
  sizeInput,
  placeholder,
  disabled = false,
  innerRef,
  isSearch,
  append,
  prepend,
  isBorderActive,
  radius,
  isTextCenter,
  name,
  ...props
}) => {
  const [isShowingPassword, setTypePassword] = useState(false);
  function getInputType() {
    if (props.type !== "password") {
      return props.type;
    }
    if (isShowingPassword) {
      return "text";
    }
    return "password";
  }

  return (
    <div className="w-100">
      {title && <div>{title}</div>}
      <InputConntainer
        className={classNames(
          { "pl-3": isSearch },
          { "input-container": !isSearch },
          "input-container"
        )}
        size={sizeInput}
        isBorderActive={isBorderActive}
        radius={radius}
        disabled={disabled}
      >
        {isSearch && (
          <SearchIcon
            className="search-icon"
            onClick={() => innerRef?.current?.focus()}
          />
        )}
        <InputBox isTextCenter={isTextCenter} size={sizeInput}>
          <input
            disabled={disabled}
            autoComplete="off"
            name={name}
            className={classNames(name, "input")}
            ref={innerRef}
            {...props}
            type={getInputType()}
            placeholder={placeholder}
          />
        </InputBox>
        {props.type === "password" && (
          <div onClick={() => setTypePassword(!isShowingPassword)}>
            {isShowingPassword ? <Eye2 /> : <Eye />} &nbsp;
          </div>
        )}
        {prepend && prepend}
        {append && append}
      </InputConntainer>
    </div>
  );
};

const CInput: React.FC<Props> = memo(({ name, control, rules, ...props }) => {
  return name ? (
    // <Controller
    //   control={control}
    //   name={name}
    //   rules={rules}
    //   render={({ field: { ref, ...field }, fieldState, formState }) => (
    //     <Input {...props} {...field} {...fieldState} />
    //   )}
    // />
    <Input name={name} {...props} />
  ) : (
    <Input name={name} {...props} />
  );
});

export default CInput;
