import React, {
  useEffect,
  useRef,
  useCallback,
  FC,
  memo,
  useState,
} from "react";
import { TextItem } from "./style";
import { useOutsideClick } from "helper/cusHooks";
import { Bond } from "domain/models/BidAskBond";
import { useIntl } from "react-intl";
interface Props {
  data: Bond;
  focus: any;
  index: number;
  setFocus: any;
  setVisibleTextSearch: (value: boolean) => void;
  setTextSearch: (value: string) => void;
  setIsChangeArrow: (value: boolean) => void;
  setIsClickOutSide: (value: boolean) => void;
  isOnlyShareCode?: boolean;
  onChange?: (value: string) => void;
}

const SelectItem: FC<Props> = memo(
  ({
    data,
    focus,
    index,
    setFocus,
    setVisibleTextSearch,
    setTextSearch,
    setIsChangeArrow,
    setIsClickOutSide,
    isOnlyShareCode,
    onChange,
  }) => {
    const ref = useRef<HTMLLIElement>(null);
    const [isFocus, setIsFocus] = useState(false);
    const intl = useIntl();
    useEffect(() => {
      if (focus) {
        setIsFocus(true);
        ref.current?.focus();
      }
    }, [focus]);

    const handleSelect = useCallback(() => {
      setFocus(index);
    }, [data, index, setFocus]);

    const handleKeyDown = (e: any, data: any) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.stopPropagation();
        // unSubscribeTicker(currentTicker);
        setVisibleTextSearch(false);
        setTextSearch("");
        setIsChangeArrow(false);
        onChange?.(data.bondCd ?? data.seccd);
      }
    };

    useOutsideClick(ref, () => {
      if (isFocus) {
        setIsClickOutSide(true);
        setTimeout(() => setIsChangeArrow(false), 150);
      }
    });

    return (
      <TextItem
        tabIndex={focus ? 0 : -1}
        ref={ref}
        className="ticker-item"
        key={data.bondCd}
        onMouseDown={(e) => {
          e.stopPropagation();
          setVisibleTextSearch(false);
          setTextSearch("");

          onChange?.(
            data.bondCd === ""
              ? intl.formatMessage({
                  id: "bidAskBond.all",
                })
              : data.bondCd
          );
        }}
        onKeyPress={handleSelect}
        onKeyDown={(e) => handleKeyDown(e, data)}
        onMouseEnter={() => setIsChangeArrow(false)}
      >
        {isOnlyShareCode ? (
          <div>
            <span className="share-code">{data.bondCd} </span>
          </div>
        ) : (
          <>
            <div>
              <span className="share-code">
                {data.bondCd.replace("/s+/g", "")}{" "}
              </span>
              {/* {data.bondCd !== "" &&
              data.bondName &&
              data.bondName !== "" &&
              data.bondName !==
                intl.formatMessage({
                  id: "bidAskBond.all",
                })
                ? " - "
                : ""} */}
              <span className="share-code">
                {data.bondName ===
                intl.formatMessage({
                  id: "bidAskBond.all",
                })
                  ? ""
                  : data.bondName
                  ? ""
                  : ""}
              </span>
            </div>
          </>
        )}
      </TextItem>
    );
  }
);

export default SelectItem;
