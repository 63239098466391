import { InputPlaceOrder, Spacer } from "components/commons";
import { getStockPrice } from "core/http/apis/tradeinfo";
import { Colors, EMarketCodeNew } from "helper/consts";
import { useInfoLastSale } from "helper/cusHooks";
import globalData from "helper/globalData";
import {
  changePrice,
  changeSecuritiesStatus,
  changeTradingSession,
  onChangeCurrentTicker,
  onChangePriceByEnter,
  onChangeTickerByEnter,
} from "modules/stock-order/redux";
import {
  onFilter,
  updateTickerInfoLastSale,
  handleUsingPriceSelected,
  clearDataPlaceOrder,
} from "modules/stock-order/redux/placeOrder";
import numeral from "numeral";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  TextColor,
  VolumnTextColor,
  VolumeTitle,
  SearchTicker,
  TextSearch,
  StarContainer,
  Container,
  CurrentTicker,
  RowSearch,
} from "./styles";
import { Label } from "../styles";
import WishListStar from "modules/shared/WishListStar";
import { toast } from "components/commons/Toast";
import { useRoveFocus } from "helper/cusHooks";
import SelectItem from "./SelectItem";
import {
  rootStockOrderSelector,
  placeOrderSelector,
} from "modules/stock-order/selectors";
import { tickerListSelector } from "modules/categories/selectors";
import {
  // getColorPriceInfoLastSale,
  getMarketLabel,
} from "helper/utils";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import orderBy from "lodash/orderBy";
import { getSecQuotesDetail } from "core/grpc";

interface Props {
  setErrorTicker: (value: string) => void;
  errorTicker: string;
  setErrorPrice: (value: string) => void;
}

const getEquityTicker = (tickers: MrktSecInfoItem.AsObject[]) => {
  return {
    tickers: tickers.filter((data) => data.marketcd !== EMarketCodeNew.DER_IDX),
  };
};

const TickerSearch: FC<Props> = memo(
  ({ setErrorTicker, errorTicker, setErrorPrice }) => {
    const dispatch = useDispatch();
    const refInput = useRef<HTMLInputElement>(null);
    const tickerList = useSelector(tickerListSelector);
    const equityTickerList = getEquityTicker(tickerList);
    const { currentTicker, floorPrice, ceilingPrice, referencePrice } =
      useSelector(rootStockOrderSelector);
    const { tickerInfoLastSale, isSetPrice } = useSelector(placeOrderSelector);

    const [visibleTextSearch, setVisibleTextSearch] = useState(false);
    const [textDisplay, setTextDisplay] = useState(true);
    const [textSearch, setTextSearch] = useState("");
    const [tickerSelect, setTickerSelect] = useState(currentTicker);
    const dataLastSale = useInfoLastSale(currentTicker);
    const ticker = globalData.getTickerInfoNew(currentTicker);
    const [isChangeArrow, setIsChangeArrow] = useState(false);
    const [isClickOutSide, setIsClickOutSide] = useState(false);
    const [numberLoadmore, setNumberLoadMore] = useState(20);
    const intl = useIntl();

    const onSearchTicker = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextSearch(e.target.value.toUpperCase());
      setTextDisplay(false);
    };

    //filter by text input
    const tickerFilter = equityTickerList.tickers.filter((value) =>
      value.seccd.includes(textSearch.toUpperCase())
    );

    const tickerSortCodeShare: MrktSecInfoItem.AsObject[] = orderBy(
      tickerFilter,
      ["sectype", "seccd"]
    );

    const [focus, setFocus] = useRoveFocus(
      tickerSortCodeShare.length,
      setIsChangeArrow,
      isChangeArrow
    );

    const handleKeyDown = (
      e: any,
      tickerSortCodeShare: MrktSecInfoItem.AsObject[]
    ) => {
      if (e.key === "Enter" || e.key === "Tab") {
        if (textSearch.length <= 2) return;
        e.stopPropagation();
        const item = tickerSortCodeShare?.filter((ticker) =>
          ticker.seccd.includes(textSearch.toUpperCase())
        )[0];
        if (item) {
          // unSubscribeTicker(currentTicker);
          dispatch(onChangeCurrentTicker(item.seccd));
          setTextSearch("");
          setVisibleTextSearch(false);
          setTextDisplay(true);
          setTickerSelect(item.seccd);
        } else {
          setTextSearch("");
          toast("widgets.placeOrder.errorTickerIllegal", "error");
          getTickerInfoError();
          dispatch(onChangeCurrentTicker(""));
        }
        dispatch(onChangeTickerByEnter(true));
      } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        setIsChangeArrow(true);
        setIsClickOutSide(false);
        setTimeout(() => setTextDisplay(false), 1);
      }
    };

    const handleSelectTicker = () => {
      if (textSearch.length <= 2) return;
      if (!isClickOutSide) return;
      const item = tickerSortCodeShare?.filter((ticker) =>
        ticker.seccd.includes(textSearch.toUpperCase())
      )[0];
      if (item) {
        // unSubscribeTicker(currentTicker);
        dispatch(onChangeCurrentTicker(item.seccd));
        setTextSearch("");
        setTickerSelect(item.seccd);
      } else {
        setTextSearch("");
        toast("widgets.placeOrder.errorTickerIllegal", "error");
        getTickerInfoError();
        dispatch(onChangeCurrentTicker(""));
      }
    };

    useEffect(() => {
      if (!currentTicker) return;
      const getTickerInfo = async () => {
        try {
          const res = await getStockPrice(currentTicker);
          if (res.statusCode === 0) {
            dispatch(
              changePrice({
                ceilingPrice: Number(res.data.ceilingPrice!),
                floorPrice: Number(res.data.floorPrice!),
                referencePrice: Number(res.data.basicPrice!),
                currentRoom: Number(res.data.currentRoom!),
                secType: res.data.secType,
              })
            );

            dispatch(
              changeSecuritiesStatus({
                status: "NORMAL",
                tradingSessionId: res.data.marketCd,
              })
            );

            const priceInfoLastSaleRes = await getSecQuotesDetail(
              currentTicker
            );

            if (priceInfoLastSaleRes && !isSetPrice) {
              dispatch(
                onFilter({
                  price: priceInfoLastSaleRes.secdetailinfo?.lastprice!,
                })
              );
            }

            dispatch(handleUsingPriceSelected(false));

            if (res.data.status) {
              dispatch(changeTradingSession(res.data.status));
            }
          } else {
            toast(
              res?.errorCode ? "error." + res?.errorCode : "",
              "error",
              res.message,
              true
            );
          }
        } catch (error: any) {
          if (currentTicker !== "") {
            toast("error.defaultErr", "error");
          }
          getTickerInfoError();
        }
      };

      getTickerInfo();

      // TODO
      // if (currentTicker) {
      //   subscribeTicker(currentTicker);
      // }
    }, [currentTicker, tickerSelect]);

    const getTickerInfoError = () => {
      dispatch(clearDataPlaceOrder());
      dispatch(
        changePrice({
          ceilingPrice: 0,
          floorPrice: 0,
          referencePrice: 0,
          currentRoom: 0,
          secType: 0,
        })
      );
      dispatch(
        changeSecuritiesStatus({
          status: "",
          tradingSessionId: 0,
        })
      );
      dispatch(
        onFilter({
          price: 0,
        })
      );
    };

    const oldData = useRef();
    const [backgroundColor, setBackgroundColor] = useState<string>("red");
    const [volumnBackground, setVolumnBackground] = useState("");

    useEffect(() => {
      const _oldData = oldData.current;
      oldData.current = tickerInfoLastSale;

      if (
        _oldData &&
        _oldData !== tickerInfoLastSale &&
        _oldData["lastprice"] &&
        _oldData["lastprice"] !== tickerInfoLastSale?.lastprice
      ) {
        setBackgroundColor("solidFlash");
      }

      if (
        _oldData &&
        _oldData !== tickerInfoLastSale &&
        _oldData["totalqty"] &&
        _oldData["totalqty"] !== tickerInfoLastSale?.totalqty
      ) {
        setVolumnBackground("grey30");
      }

      setTimeout(() => {
        setBackgroundColor("");
        setVolumnBackground("");
      }, 500);
    }, [tickerInfoLastSale]);

    useEffect(() => {
      if (dataLastSale && dataLastSale?.seccd === currentTicker) {
        dispatch(updateTickerInfoLastSale(dataLastSale));
      }
    }, [dataLastSale, dispatch]);

    useEffect(() => {
      if (isClickOutSide) {
        setVisibleTextSearch(false);
      }
    }, [isClickOutSide]);

    const handleScroll = () => {
      setNumberLoadMore(numberLoadmore + 20);
    };

    return (
      <Container>
        <RowSearch>
          <SearchTicker>
            <InputPlaceOrder
              name="input-ticker-search"
              innerRef={refInput}
              placeholder={intl.formatMessage({
                id: "widgets.placeOrder.inputTicker",
              })}
              value={textDisplay ? currentTicker : textSearch}
              isBorderActive={!textDisplay}
              onChange={onSearchTicker}
              onKeyDown={(e) => handleKeyDown(e, tickerSortCodeShare)}
              isSearch
              onFocus={() => {
                setVisibleTextSearch(true);
                setTextDisplay(false);
              }}
              onBlur={() => {
                setTextDisplay(true);
                setErrorTicker("");
                if (!isChangeArrow) {
                  setTimeout(() => setVisibleTextSearch(false), 150);
                }
                handleSelectTicker();
              }}
            />

            {(visibleTextSearch || textSearch) && (
              <TextSearch onScroll={handleScroll}>
                {tickerSortCodeShare
                  .slice(0, numberLoadmore)
                  .map((item, index) => (
                    <SelectItem
                      key={index}
                      setFocus={setFocus}
                      index={index}
                      focus={focus === index}
                      data={item}
                      setVisibleTextSearch={setVisibleTextSearch}
                      setTextSearch={setTextSearch}
                      setTextDisplay={setTextDisplay}
                      setIsChangeArrow={setIsChangeArrow}
                      setIsClickOutSide={setIsClickOutSide}
                    />
                  ))}
              </TextSearch>
            )}
          </SearchTicker>
        </RowSearch>
        {errorTicker !== "" && !visibleTextSearch && (
          <RowSearch>
            <div className="flex-4 text-danger text-center mt-2">
              {errorTicker}
            </div>
            <div className="flex-1" />
          </RowSearch>
        )}
        <Spacer size="xs" />
        <div className="d-flex align-items-end">
          <CurrentTicker line="24px" colorKey="textcolorWhite" className="mr-2">
            {currentTicker}
          </CurrentTicker>

          <Label line="20px" className="flex-fill" isTruncate>
            {ticker && "(" + getMarketLabel(ticker.marketcd) + ") "}
            {ticker?.secname || ticker?.secnameen}
          </Label>
          <StarContainer>
            <WishListStar tickerName={currentTicker} />
          </StarContainer>
        </div>
        <div className="d-flex align-items-center mt-1">
          <TextColor
            color={
              backgroundColor
                ? "textcolorWhite"
                : Colors[tickerInfoLastSale?.colorcode]
            }
            size="big"
            backgroundColor={backgroundColor}
            className="mr-2"
            onClick={() => {
              dispatch(
                onFilter({
                  price: tickerInfoLastSale?.lastprice!,
                })
              );
              setTimeout(() => dispatch(onChangePriceByEnter(true)), 150);
              setErrorPrice("");
            }}
          >
            {numeral(tickerInfoLastSale?.lastprice!).format("0.00")}
          </TextColor>
          <div className="flex-fill mr-1">
            <TextColor
              color={
                backgroundColor
                  ? "textcolorWhite"
                  : Colors[tickerInfoLastSale?.colorcode]
              }
              size="small"
              backgroundColor={backgroundColor}
            >
              {tickerInfoLastSale?.changepoint! > 0 && "+"}
              {numeral(tickerInfoLastSale?.changepoint!).format("0.00")}
            </TextColor>
            <TextColor
              color={
                backgroundColor
                  ? "textcolorWhite"
                  : Colors[tickerInfoLastSale?.colorcode]
              }
              size="small"
              backgroundColor={backgroundColor}
            >
              {tickerInfoLastSale?.changepercent! > 0 && "+"}
              {numeral(tickerInfoLastSale?.changepercent).format("0.00")}%
            </TextColor>
          </div>
          <div>
            <VolumeTitle className="text-uppercase">
              <FormattedMessage id="widgets.placeOrder.volume" />
            </VolumeTitle>
            <VolumnTextColor
              backgroundColor={volumnBackground}
              className="text-right"
            >
              {numeral(tickerInfoLastSale?.totalqty * 10).format("0,000")}
            </VolumnTextColor>
          </div>
          <div className="ml-3">
            <div className="tran text-right text-uppercase">
              <FormattedMessage id="widgets.placeOrder.ceilingPrice" />
            </div>
            <div
              className="gia-tran violet"
              onClick={() => {
                dispatch(
                  onFilter({
                    price: ceilingPrice!,
                  })
                );
                setTimeout(() => dispatch(onChangePriceByEnter(true)), 150);
                setErrorPrice("");
              }}
            >
              {numeral(ceilingPrice!).format("0.00")}
            </div>
          </div>
          <div className="mx-3">
            <div className="tran text-right text-uppercase">
              <FormattedMessage id="widgets.placeOrder.floorPrice" />
            </div>
            <div
              className="gia-tran blue"
              onClick={() => {
                dispatch(
                  onFilter({
                    price: floorPrice!,
                  })
                );
                setTimeout(() => dispatch(onChangePriceByEnter(true)), 150);
                setErrorPrice("");
              }}
            >
              {numeral(floorPrice!).format("0.00")}
            </div>
          </div>
          <div>
            <div className="tran text-right text-uppercase">
              <FormattedMessage id="widgets.placeOrder.referencePrice" />
            </div>
            <div
              className="gia-tran yellow"
              onClick={() => {
                dispatch(
                  onFilter({
                    price: referencePrice!,
                  })
                );
                setTimeout(() => dispatch(onChangePriceByEnter(true)), 150);
                setErrorPrice("");
              }}
            >
              {numeral(referencePrice!).format("0.00")}
            </div>
          </div>
        </div>
      </Container>
    );
  }
);

export default TickerSearch;
