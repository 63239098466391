import { Button, Input, Select } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import {
  FilterWrapper,
  Wrap,
} from "components/commons/FormStyle/Filter/styles";
import { OptionSelectType } from "components/commons/Select";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  getCustGroupRequest,
  searchMarginSecuritiesRequest,
  updateFilter,
} from "modules/margin-securities/redux";
import {
  marginSecuritiesFilterSelector,
  marginSecuritiesLoadingSelector,
} from "modules/margin-securities/selectors";
import { ChangeEvent, memo, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const convertCustGroupText = (custGroup: string, intl: IntlShape) => {
  if (custGroup) return custGroup;

  return intl.formatMessage({ id: "marginSecurities.filter.defaultCustGroup" });
};

function Filter() {
  // hook
  const intl = useIntl();
  const dispatch = useDispatch();
  const listAcc = GetAccoList(GetAccountType.MARGIN, EntrustBusinessCode.All);

  // selector
  const filterParams = useSelector(marginSecuritiesFilterSelector);
  const loading = useSelector(marginSecuritiesLoadingSelector);

  // memo
  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  // function
  const changeSubAccountHandler = (e: OptionSelectType | null) => {
    dispatch(
      getCustGroupRequest({
        accNo: e?.value ?? "",
        openDateFrom: 0,
        openDateTo: 0,
        broker: "",
        status: "1",
        contractState: "-1",
        tranCd: "",
      })
    );
  };

  const changeSecCdHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFilter({
        key: "secCd",
        value: e?.target.value ?? "",
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      searchMarginSecuritiesRequest({
        custGroupId: +filterParams.custGroupId,
        secCd: filterParams.secCd,
        status: +filterParams.status,
      })
    );
  };

  // effect

  // render
  return (
    <Wrap className="d-flex justify-content-between align-items-center">
      <FilterWrapper className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "marginSecurities.filter.subAccount",
          })}
          className="mr-4"
        >
          <Select
            className="flex-fill"
            defaultValue={accountOptions[0]}
            options={accountOptions}
            onChange={changeSubAccountHandler}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginSecurities.filter.custGroup",
          })}
          className="mr-4"
        >
          <Input
            value={convertCustGroupText(filterParams.custGroupName, intl)}
            onChange={changeSecCdHandler}
            readOnly
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "marginSecurities.filter.secCd",
          })}
          className="mr-4"
        >
          <Input value={filterParams.secCd} onChange={changeSecCdHandler} />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({ id: "marginSecurities.filter.search" })}
        </Button>
      </FilterWrapper>
    </Wrap>
  );
}

export default memo(Filter);
