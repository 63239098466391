import { stringColorTicker } from "helper/utils";
import { baseseccdPriceColorInfoSelector } from "modules/categories/redux/selectors";
import { useSelector } from "react-redux";
import { TextCell } from "../styles";

interface Props {
  ticker: string;
}

function BaseseccdCell({ ticker }: Props) {
  const color = useSelector(baseseccdPriceColorInfoSelector(ticker));

  return (
    <TextCell className="text-right" color={stringColorTicker(color)}>
      {ticker}
    </TextCell>
  );
}

export default BaseseccdCell;
