import { MarketWatchTab } from "./types";
import MarketWatchButton from "./MarketWatchButton";
import { FormattedMessage } from "react-intl";
import { memo } from "react";
import { TabsContainer } from "./styles";

export interface TabsProps {
  activeTab: MarketWatchTab;
  onChangeTabMarketWatch: (tab: MarketWatchTab) => void;
}

const marketWatchTabs: MarketWatchTab[] = [
  "topIndex",
  "marketRange",
  "liquidity",
  "transactionValueVolume",
  "capitalization",
];

function Tabs({ activeTab, onChangeTabMarketWatch }: TabsProps) {
  return (
    <TabsContainer>
      <div className="tabs">
        {marketWatchTabs.map((tab: MarketWatchTab) => {
          return (
            <div key={tab}>
              <MarketWatchButton
                active={activeTab === (tab as MarketWatchTab)}
                value={tab}
                onClick={onChangeTabMarketWatch}
                tabName={tab}
              >
                <FormattedMessage id={`widgets.marketWatch.${tab}`} />
              </MarketWatchButton>
            </div>
          );
        })}
      </div>
    </TabsContainer>
  );
}

export default memo(Tabs);
