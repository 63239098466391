import styled from "styled-components/macro";

export const ButtonExit = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const DetailRow = styled.div`
  align-items: center;
  margin-bottom: 8px;
`;

export const DetailLabel = styled.div`
  min-width: 120px;
  color: ${({ theme }) => theme.grey0};
`;

export const DetailCheckbox = styled.div`
  color: ${({ theme }) => theme.grey0};
  padding: 14px 0px;
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

export const DetailInput = styled.div`
  padding: 14px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  color: ${({ theme }) => theme.grey0};
`;

export const WrapLabel = styled.div`
  padding-bottom: 4px;
  color: ${({ theme }) => theme.grey0};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: scroll;
`;

export const WrapBtn = styled.div`
  display: flex;
  justify-content: end;
`;

export const WrapForm = styled.div`
  height: 100%;
  overflow: scroll;
`;
