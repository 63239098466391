import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { TableHeaderWrapper, HeaderCell, HeaderRowWrapper } from "./styles";
import { ReactComponent as IconSortUp } from "assets/image/svg/ic_sort_up_2.svg";
import { ReactComponent as IconSortDown } from "assets/image/svg/ic_sort_down_2.svg";
import { ReactComponent as IconSortDefault } from "assets/image/svg/ic_sort_default_2.svg";
import {
  SORT_BY,
  FLEX,
  COLUMNS_VALUE_BUY,
  COLUMNS_VALUE_SELL,
  COLUMNS_BUYFOREIGN_AMT,
  COLUMNS_SELLFOREIGN_QTY,
  COLUMNS_BUYFOREIGN_QTY,
  COLUMNS_SELLFOREIGN_AMT,
} from "./constants";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveSort,
  getSortByActiveTab,
} from "modules/market/redux/topForeign";
import {
  activeSortTopForeignSelector,
  activeTabTopForeignSelector,
} from "modules/market/redux/selectors";
import { TopForeignMenu } from "./types";
import { ORDER_TYPE, ORDER_TYPEMap } from "domain/protoNew/auto_trading_pb";

function getColumn(value: TopForeignMenu) {
  switch (value) {
    case "foreignNetValuesBuy":
      return COLUMNS_VALUE_BUY;
    case "foreignNetValuesSell":
      return COLUMNS_VALUE_SELL;
    case "foreignBuy":
      return COLUMNS_BUYFOREIGN_QTY;
    case "foreignSell":
      return COLUMNS_SELLFOREIGN_QTY;
    case "foreignBuyValue":
      return COLUMNS_BUYFOREIGN_AMT;
    case "foreignSellValue":
      return COLUMNS_SELLFOREIGN_AMT;
    default:
      return COLUMNS_VALUE_BUY;
  }
}

const TableHeader: FC = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabTopForeignSelector);
  const activeSort = useSelector(activeSortTopForeignSelector);
  const columns = getColumn(activeTab);

  const generateSortIcon = (sort: {
    sortBy: SORT_BY;
    order: ORDER_TYPEMap[keyof ORDER_TYPEMap];
  }) => {
    let icon = <IconSortDefault />;
    if (sort.sortBy === activeSort?.sortBy) {
      if (sort.order === ORDER_TYPE.ORDER_DOWN) {
        icon = <IconSortDown />;
      }
      if (sort.order === ORDER_TYPE.ORDER_UP) {
        icon = <IconSortUp />;
      }
    }
    return <span>{icon}</span>;
  };

  const clickHeaderHandler = useCallback(
    (sortByCode?: SORT_BY) => {
      if (!sortByCode) return;
      // nếu chưa set sortBy hoặc sortBy trường khác
      if (!activeSort.sortBy || activeSort.sortBy !== sortByCode) {
        dispatch(
          changeActiveSort({ order: ORDER_TYPE.ORDER_DOWN, sortBy: sortByCode })
        );
        return;
      }
      //nếu order = desc thì gán newOrder = asc và ngược lại
      let newOrder: ORDER_TYPEMap[keyof ORDER_TYPEMap] =
        ORDER_TYPE.ORDER_DEFAULT;
      let unsetCode = false;
      if (activeSort.order === ORDER_TYPE.ORDER_DEFAULT) {
        newOrder = ORDER_TYPE.ORDER_DOWN;
      } else if (activeSort.order === ORDER_TYPE.ORDER_DOWN) {
        newOrder = ORDER_TYPE.ORDER_UP;
      } else {
        unsetCode = true;
      }
      dispatch(
        changeActiveSort({
          order: unsetCode ? ORDER_TYPE.ORDER_DEFAULT : newOrder,
          sortBy: unsetCode ? getSortByActiveTab(activeTab) : sortByCode,
        })
      );
    },
    [activeSort]
  );

  return (
    <TableHeaderWrapper id="topForeign-table-header-wrapper">
      <HeaderRowWrapper>
        {columns.map((column) => (
          <HeaderCell
            align={FLEX[column.position as keyof typeof FLEX]}
            setMinWidth={column?.width}
            key={column.name}
            onClick={() => clickHeaderHandler(column?.sortBy)}
            canSort={!!column.sortBy}
          >
            <div>
              <span>
                <FormattedMessage id={column?.name} />
              </span>
              {!!column.sortBy &&
                generateSortIcon({
                  sortBy: column.sortBy,
                  order: activeSort?.order,
                })}
            </div>
          </HeaderCell>
        ))}
      </HeaderRowWrapper>
    </TableHeaderWrapper>
  );
};

export default TableHeader;
