import {
  Container,
  CardTable,
  Footer,
  Content,
  ButtonRight,
  CardRow,
  CardLabel,
  CardValue,
  YesNoButton,
  TitleContent,
} from "./styles";
import { memo, useMemo, useState } from "react";
import { Spacer, Button, SelectPlaceOrder, Switch } from "components/commons";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ListKeyShortcuts } from "themes/types";
import { listHotKeysSelector, setListKeyShort } from "themes/redux";

export const getListKeyShortcutOpenForm = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({ id: "auth.keyboardShortcuts.noKey" }),
  },
  {
    value: "F1",
    label: "F1",
  },
  {
    value: "F2",
    label: "F2",
  },
  {
    value: "F3",
    label: "F3",
  },
  {
    value: "F4",
    label: "F4",
  },
];

export const getListKeyShortcutTransaction = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({ id: "auth.keyboardShortcuts.noKey" }),
  },
  {
    value: "ctrl+s",
    label: "Ctrl + S",
  },
  {
    value: "ctrl+b",
    label: "Ctrl + B",
  },
  {
    value: "ctrl+F1",
    label: "Ctrl + F1",
  },
  {
    value: "ctrl+F2",
    label: "Ctrl + F2",
  },
  {
    value: "ctrl+F3",
    label: "Ctrl + F3",
  },
  {
    value: "ctrl+F4",
    label: "Ctrl + F4",
  },
];

interface Option {
  label: string;
  value: string;
}

function ModalSettingKeyShortcuts() {
  const dispatch = useDispatch();
  const intl = useIntl();
  // const listKeyShortcuts = Storage.get("KEY_SHORTCUT");
  const listKeyShortcuts = useSelector(listHotKeysSelector);
  const [listKeyShortcutsStorage, setListKeyShortcutsStorage] =
    useState<ListKeyShortcuts>({
      keyShortcutCategory: "",
      keyShortcutMarket: "",
      keyShortcutStockOrder: "",
      keyShortcutStockOrderDer: "",
      keyShortcutStockOrderBuy: "",
      keyShortcutStockOrderSell: "",
      visiblePopupConfirm: false,
    });

  useEffect(() => {
    if (listKeyShortcuts) {
      setListKeyShortcutsStorage(listKeyShortcuts);
    } else {
      const listKeyShortcut: ListKeyShortcuts = {
        keyShortcutCategory: "F1",
        keyShortcutMarket: "F2",
        keyShortcutStockOrder: "F3",
        keyShortcutStockOrderDer: "F4",
        keyShortcutStockOrderBuy: "ctrl+b",
        keyShortcutStockOrderSell: "ctrl+s",
        visiblePopupConfirm: true,
      };
      // Storage.set("KEY_SHORTCUT", JSON.stringify(listKeyShortcut));
      dispatch(setListKeyShort(listKeyShortcut));
      setListKeyShortcutsStorage(listKeyShortcut);
    }
  }, [listKeyShortcuts]);

  const listKeyShortcutOpenForm = useMemo(
    () => getListKeyShortcutOpenForm(intl),
    [intl]
  );
  const listKeyShortcutTransaction = useMemo(
    () => getListKeyShortcutTransaction(intl),
    [intl]
  );
  const [errorDuplicateKeyOpenForm, setErrorDuplicateKeyOpenForm] =
    useState("");
  const [errorDuplicateKeySettingTrading, setErrorDuplicateKeySettingTrading] =
    useState("");

  const keyShortcutCategorySelect = listKeyShortcutOpenForm.find(
    (item) => item.value === listKeyShortcutsStorage.keyShortcutCategory
  );
  const keyShortcutMarketSelect = listKeyShortcutOpenForm.find(
    (item) => item.value === listKeyShortcutsStorage.keyShortcutMarket
  );
  const keyShortcutStockOrderSelect = listKeyShortcutOpenForm.find(
    (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrder
  );
  const keyShortcutStockOrderDerSelect = listKeyShortcutOpenForm.find(
    (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrderDer
  );
  const keyShortcutStockOrderBuySelect = listKeyShortcutTransaction.find(
    (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrderBuy
  );
  const keyShortcutStockOrderSellSelect = listKeyShortcutTransaction.find(
    (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrderSell
  );
  const visiblePopupConfirmSelect = listKeyShortcutsStorage.visiblePopupConfirm;

  const [keyShortcutCategory, setKeyShortcutCategory] = useState(
    keyShortcutCategorySelect
  );
  const [keyShortcutMarket, setKeyShortcutMarket] = useState(
    keyShortcutMarketSelect
  );
  const [keyShortcutStockOrder, setKeyShortcutStockOrder] = useState(
    keyShortcutStockOrderSelect
  );
  const [keyShortcutStockOrderDer, setKeyShortcutStockOrderDer] = useState(
    keyShortcutStockOrderDerSelect
  );
  const [keyShortcutStockOrderBuy, setKeyShortcutStockOrderBuy] = useState(
    keyShortcutStockOrderBuySelect
  );
  const [keyShortcutStockOrderSell, setKeyShortcutStockOrderSell] = useState(
    keyShortcutStockOrderSellSelect
  );
  const [visiblePopupConfirm, setVisiblePopupConfirm] = useState(
    visiblePopupConfirmSelect
  );
  const [loading, showLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const keyShortcutCategorySelectTemp = listKeyShortcutOpenForm.find(
      (item) => item.value === listKeyShortcutsStorage.keyShortcutCategory
    );
    const keyShortcutMarketSelectTemp = listKeyShortcutOpenForm.find(
      (item) => item.value === listKeyShortcutsStorage.keyShortcutMarket
    );
    const keyShortcutStockOrderSelectTemp = listKeyShortcutOpenForm.find(
      (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrder
    );
    const keyShortcutStockOrderDerSelectTemp = listKeyShortcutOpenForm.find(
      (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrderDer
    );
    const keyShortcutStockOrderBuySelectTemp = listKeyShortcutTransaction.find(
      (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrderBuy
    );
    const keyShortcutStockOrderSellSelectTemp = listKeyShortcutTransaction.find(
      (item) => item.value === listKeyShortcutsStorage.keyShortcutStockOrderSell
    );
    const visiblePopupConfirmSelectTemp =
      listKeyShortcutsStorage.visiblePopupConfirm;

    setKeyShortcutCategory(keyShortcutCategorySelectTemp);

    setKeyShortcutMarket(keyShortcutMarketSelectTemp);

    setKeyShortcutStockOrder(keyShortcutStockOrderSelectTemp);

    setKeyShortcutStockOrderDer(keyShortcutStockOrderDerSelectTemp);

    setKeyShortcutStockOrderBuy(keyShortcutStockOrderBuySelectTemp);

    setKeyShortcutStockOrderSell(keyShortcutStockOrderSellSelectTemp);

    setVisiblePopupConfirm(visiblePopupConfirmSelectTemp);
  }, [listKeyShortcutsStorage]);

  //   const closeModal = () => {
  //     setKeyShortcutCategory(keyShortcutCategorySelect);
  //     setKeyShortcutMarket(keyShortcutMarketSelect);
  //     setKeyShortcutStockOrder(keyShortcutStockOrderSelect);
  //     setKeyShortcutStockOrderDer(keyShortcutStockOrderDerSelect);
  //     setKeyShortcutStockOrderBuy(keyShortcutStockOrderBuySelect);
  //     setKeyShortcutStockOrderSell(keyShortcutStockOrderSellSelect);
  //     setVisiblePopupConfirm(visiblePopupConfirmSelect);
  //     setErrorDuplicateKeyOpenForm("");
  //     setErrorDuplicateKeySettingTrading("");
  //   };

  const reset = () => {
    setKeyShortcutCategory(keyShortcutCategorySelect);
    setKeyShortcutMarket(keyShortcutMarketSelect);
    setKeyShortcutStockOrder(keyShortcutStockOrderSelect);
    setKeyShortcutStockOrderDer(keyShortcutStockOrderDerSelect);
    setKeyShortcutStockOrderBuy(keyShortcutStockOrderBuySelect);
    setKeyShortcutStockOrderSell(keyShortcutStockOrderSellSelect);
    setVisiblePopupConfirm(visiblePopupConfirmSelect);
    setErrorDuplicateKeyOpenForm("");
    setErrorDuplicateKeySettingTrading("");
  };

  const apply = () => {
    //check duplicate select
    if (
      keyShortcutCategory?.value === keyShortcutMarket?.value ||
      keyShortcutCategory?.value === keyShortcutStockOrder?.value ||
      keyShortcutCategory?.value === keyShortcutStockOrderDer?.value ||
      keyShortcutMarket?.value === keyShortcutStockOrder?.value ||
      keyShortcutMarket?.value === keyShortcutStockOrderDer?.value ||
      keyShortcutStockOrder?.value === keyShortcutStockOrderDer?.value
    ) {
      setErrorDuplicateKeyOpenForm(
        intl.formatMessage({
          id: "auth.keyboardShortcuts.duplicateKey",
        })
      );
      return;
    }
    if (keyShortcutStockOrderBuy?.value === keyShortcutStockOrderSell?.value) {
      setErrorDuplicateKeySettingTrading(
        intl.formatMessage({
          id: "auth.keyboardShortcuts.duplicateKey",
        })
      );
      return;
    }
    setErrorDuplicateKeyOpenForm("");
    setErrorDuplicateKeySettingTrading("");
    const listKeyShortcutParams: ListKeyShortcuts = {
      keyShortcutCategory: keyShortcutCategory?.value,
      keyShortcutMarket: keyShortcutMarket?.value,
      keyShortcutStockOrder: keyShortcutStockOrder?.value,
      keyShortcutStockOrderDer: keyShortcutStockOrderDer?.value,
      keyShortcutStockOrderBuy: keyShortcutStockOrderBuy?.value,
      keyShortcutStockOrderSell: keyShortcutStockOrderSell?.value,
      visiblePopupConfirm: visiblePopupConfirm,
    };
    dispatch(setListKeyShort(listKeyShortcutParams));
    showLoading(false);
    setDisabled(false);
  };

  useEffect(() => {
    apply();
  }, [
    keyShortcutCategory,
    keyShortcutMarket,
    keyShortcutStockOrder,
    keyShortcutStockOrderDer,
    keyShortcutStockOrderBuy,
    keyShortcutStockOrderSell,
    visiblePopupConfirm,
  ]);

  const handleChangeKeyShortcutCategory = (item: Option) => {
    setKeyShortcutCategory(item);
  };

  const handleChangeKeyShortcutMarket = (item: Option) => {
    setKeyShortcutMarket(item);
  };

  const handleChangeKeyShortcutStockOrder = (item: Option) => {
    setKeyShortcutStockOrder(item);
  };

  const handleChangeKeyShortcutStockOrderDer = (item: Option) => {
    setKeyShortcutStockOrderDer(item);
  };

  const handleChangeKeyShortcutStockOrderBuy = (item: Option) => {
    setKeyShortcutStockOrderBuy(item);
  };

  const handleChangeKeyShortcutStockOrderSell = (item: Option) => {
    setKeyShortcutStockOrderSell(item);
  };

  const handleSwitch = async () => {
    setVisiblePopupConfirm(!visiblePopupConfirm);
  };

  return (
    <Container>
      <Content>
        <CardTable>
          <CardRow>
            <TitleContent>
              <FormattedMessage id="auth.keyboardShortcuts.keyShortcutOpenForm.title" />
            </TitleContent>
          </CardRow>
          <CardRow>
            <CardLabel>
              <FormattedMessage id="auth.keyboardShortcuts.keyShortcutOpenForm.category" />
            </CardLabel>
            <CardValue>
              <SelectPlaceOrder
                className="flex-fill"
                value={keyShortcutCategory}
                options={listKeyShortcutOpenForm}
                onChange={(item) => {
                  item !== null && handleChangeKeyShortcutCategory(item);
                }}
              />
            </CardValue>
            <Spacer size="l" />
            <CardLabel>
              <FormattedMessage id="auth.keyboardShortcuts.keyShortcutOpenForm.stockOrder" />
            </CardLabel>
            <CardValue>
              <SelectPlaceOrder
                className="flex-fill"
                value={keyShortcutStockOrder}
                options={listKeyShortcutOpenForm}
                onChange={(item) => {
                  item !== null && handleChangeKeyShortcutStockOrder(item);
                }}
              />
            </CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>
              <FormattedMessage id="auth.keyboardShortcuts.keyShortcutOpenForm.market" />
            </CardLabel>
            <CardValue>
              <SelectPlaceOrder
                className="flex-fill"
                value={keyShortcutMarket}
                options={listKeyShortcutOpenForm}
                onChange={(item) => {
                  item !== null && handleChangeKeyShortcutMarket(item);
                }}
              />
            </CardValue>
            <Spacer size="l" />
            <CardLabel>
              <FormattedMessage id="auth.keyboardShortcuts.keyShortcutOpenForm.stockOrderDer" />
            </CardLabel>
            <CardValue>
              <SelectPlaceOrder
                className="flex-fill"
                value={keyShortcutStockOrderDer}
                options={listKeyShortcutOpenForm}
                onChange={(item) => {
                  item !== null && handleChangeKeyShortcutStockOrderDer(item);
                }}
              />
            </CardValue>
          </CardRow>
          {errorDuplicateKeyOpenForm && (
            <div className="text-center text-danger">
              {errorDuplicateKeyOpenForm}
            </div>
          )}
        </CardTable>
        <CardTable>
          <CardRow>
            <TitleContent>
              <FormattedMessage id="auth.keyboardShortcuts.transactionSettings.title" />
            </TitleContent>
          </CardRow>
          <CardRow>
            <CardLabel>
              <FormattedMessage id="auth.keyboardShortcuts.transactionSettings.stockOrderSell" />
            </CardLabel>
            <CardValue>
              <SelectPlaceOrder
                className="flex-fill"
                value={keyShortcutStockOrderBuy}
                options={listKeyShortcutTransaction}
                onChange={(item) => {
                  item !== null && handleChangeKeyShortcutStockOrderBuy(item);
                }}
              />
            </CardValue>
            <Spacer size="l" />
            <CardLabel isSwitch>
              <FormattedMessage id="auth.keyboardShortcuts.transactionSettings.popupConfirmOrder" />
            </CardLabel>
            <CardValue isSwitch>
              <YesNoButton>
                <Switch
                  checked={visiblePopupConfirm}
                  onChange={() => handleSwitch()}
                  disabled={disabled}
                  loading={loading}
                />
              </YesNoButton>
            </CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>
              <FormattedMessage id="auth.keyboardShortcuts.transactionSettings.stockOrderBuy" />
            </CardLabel>
            <CardValue>
              <SelectPlaceOrder
                className="flex-fill"
                value={keyShortcutStockOrderSell}
                options={listKeyShortcutTransaction}
                onChange={(item) => {
                  item !== null && handleChangeKeyShortcutStockOrderSell(item);
                }}
              />
            </CardValue>
            <Spacer size="l" />
            <CardLabel></CardLabel>
            <CardValue></CardValue>
          </CardRow>

          {errorDuplicateKeySettingTrading && (
            <div className="text-center text-danger">
              {errorDuplicateKeySettingTrading}
            </div>
          )}
        </CardTable>
      </Content>
      <Footer>
        <div className="flex-1"></div>
        <ButtonRight className="w-96">
          <Button
            width="100%"
            className="buttonReset"
            color="accept"
            onClick={reset}
            radius={4}
            size="m"
          >
            <div>
              <FormattedMessage id="auth.keyboardShortcuts.resetAll" />
            </div>
          </Button>
        </ButtonRight>
      </Footer>
    </Container>
  );
}
export default memo(ModalSettingKeyShortcuts);
