/* eslint-disable @typescript-eslint/no-redeclare */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { STEPS } from "../pages/ModalRegister/constants";

// interface ElementStep7 {
//     bankAccountName: string;
//     bankAccountNumber: string;
//     bankBranchLocationCd: string;
//     bankCd: string;
//     bankBranchCd: string;
//     isOwner: string
// }

export type modalRegister = {
  informationRegister: any;
  activeStep: string;

  formSubmit: {
    [key: string]: any;
    //step 1
    custType: string;
    foreignType: string;
    channelId: string;
    introducerCd: string;
    brokerCd: string;
    mobileNoSt1: string;
    adrs: string;
    branchSt1: string;
    transactionPointSt1: any;
    // step 2
    approvalCd: string;
    // step 3
    imageUrlFront: any;
    imageUrlBack: any;
    changePhoto: boolean;
    //step  4
    imageFaceFront: any;
    changeImageFaceFront: boolean;
    // step 5
    idNumber: string;
    issueLocationCd: string;
    issueDate: string;
    expiredDate: string;
    //step 5.2
    custFamilyName: string;
    custGivenName: string;
    birthDay: string;
    sex: string;
    mobileNo: string;
    emailAdrs: string;
    address: string;
    //step 7
    dataFormStep7: any;
    //step 8
    activeContactCenter: boolean;
    active: boolean;
    userAuthen: boolean;
    sms: boolean;
    email: boolean;
    payAdvanceAuto: boolean;
    agreeStep8: boolean;
  };
  paramSubmit: {
    //step 1
    custType: string;
    foreignType: string;
    channelId: string;
    introducerCd: string;
    brokerCd: string;
    transactionCd: string;
    //step 2
    approvalCd: string;
    //step 3
    imageFront: string;
    imageBack: string;
    //step 4
    imageFaceFront: string;
    //step 5
    issueDate: string;
    idNumber: string;
    // step 5_2
    custFamilyName: string;
    custGivenName: string;
    sex: string;
    mobileNo: string;
    emailAdrs: string;
    address: string;
    birthDay: string;
    // step 6
    custNo: string | null;
    //
    countryCd: string;
    kycType: string;
    srcChannel: string;
  };
  informationExtraction: any;
};

const initialState: modalRegister = {
  informationRegister: {},
  activeStep: STEPS.STEPS_1,
  formSubmit: {
    //step 1
    custType: "1",
    foreignType: "1",
    channelId: "1",
    introducerCd: "",
    brokerCd: "",
    mobileNoSt1: "",
    adrs: "",
    branchSt1: "100",
    transactionPointSt1: {},
    // step 2
    approvalCd: "",
    // step 3
    imageUrlFront: "",
    imageUrlBack: "",
    changePhoto: false,
    //step 4
    imageFaceFront: "",
    changeImageFaceFront: false,
    // step 5
    idNumber: "",
    issueLocationCd: "",
    issueDate: "",
    expiredDate: "",
    //step 5.2
    custFamilyName: "",
    custGivenName: "",
    birthDay: "",
    sex: "",
    mobileNo: "",
    emailAdrs: "",
    address: "",
    //step 7
    dataFormStep7: [
      {
        bankAccountName: "",
        bankAccountNumber: "",
        bankBranchLocationCd: "",
        bankCd: "",
        bankBranchCd: "",
        isOwner: false,
        listBranch: [],
      },
    ],
    //step 8
    activeContactCenter: false,
    active: false,
    userAuthen: true,
    sms: false,
    email: false,
    payAdvanceAuto: false,
    agreeStep8: false,
  },
  paramSubmit: {
    //step 1
    custType: "",
    foreignType: "",
    channelId: "",
    introducerCd: "",
    brokerCd: "",
    transactionCd: "",
    //step 2
    approvalCd: "",
    //step 3
    imageFront: "",
    imageBack: "",
    //step 4
    imageFaceFront: "",
    //step 5
    issueDate: "",
    idNumber: "",
    // step 5_2
    custFamilyName: "",
    custGivenName: "",
    sex: "",
    mobileNo: "",
    emailAdrs: "",
    address: "",
    birthDay: "",
    // step 6
    custNo: null,
    //
    countryCd: "VN",
    kycType: "1",
    srcChannel: "2",
  },
  informationExtraction: {},
};

const modalRegister = createSlice({
  name: "modalRegister",
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<string>) => {
      state.activeStep = action.payload;
    },
    updateDataCreateUser: (state, action: PayloadAction<any>) => {
      state.informationRegister = action.payload;
    },
    updateFormSubmit: (
      state,
      action: PayloadAction<{ value: any; key: string }>
    ) => {
      state.formSubmit = {
        ...state.formSubmit,
        [action.payload.key]: action.payload.value,
      };
    },
    updateParams: (state, action: PayloadAction<any>) => {
      state.paramSubmit = {
        ...state.paramSubmit,
        ...action.payload,
      };
    },
    updateInformationExtraction: (state, action: PayloadAction<any>) => {
      state.informationExtraction = action.payload;
    },
    updateDataStep7: (state, action: PayloadAction<{ value: any; i: any }>) => {
      state.formSubmit.dataFormStep7[action.payload.i] = action.payload.value;
    },
    updateDataStep7isOwner: (state, action: PayloadAction<{ value: any }>) => {
      state.formSubmit.dataFormStep7 = action.payload.value;
    },
    resetRegister: () => {
      return cloneDeep(initialState);
    },
  },
});

export const {
  updateStep,
  updateDataCreateUser,
  updateFormSubmit,
  updateParams,
  updateInformationExtraction,
  updateDataStep7,
  updateDataStep7isOwner,
  resetRegister,
} = modalRegister.actions;

export default modalRegister.reducer;
