import styled from "styled-components/macro";
import Tooltip from "react-bootstrap/Tooltip";

const TickerContainer = styled.div`
  background: ${({ theme }) => theme.background3};
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 4px;
  margin: 4px;
  max-height: 450px;
  overflow: auto;
`;

const TickerWrappper = styled.div`
  flex: 4;
  position: relative;
  z-index: 4;
`;

const StarContainer = styled.div`
  margin-left: 14px;
`;

const InputLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.textcolorBlack};
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.borderColor};
`;
const VolumnTextColor = styled.div<{ backgroundColor: string }>`
  color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.grey60 : theme.textcolorWhite};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  background-color: ${(props) =>
    props.backgroundColor ? props.theme[props.backgroundColor] : "transparent"};
`;
const TextColor = styled.div<{
  color: string;
  size: string;
  backgroundColor: string;
}>`
  color: ${({ theme, color }) => theme[color]};
  background-color: ${(props) => props.theme[props.backgroundColor]};
  font-weight: ${(props) =>
    props.size === "big"
      ? props.theme.typeFaces.header.weight.semiBold
      : props.theme.typeFaces.caption12.weight.regular};
  font-size: ${(props) =>
    props.size === "big"
      ? props.theme.typeFaces.header.size
      : props.theme.typeFaces.caption12.size};
  line-height: ${(props) =>
    props.size === "big"
      ? props.theme.typeFaces.header.lineHeight
      : props.theme.typeFaces.caption12.lineHeight};
  max-width: ${(props) => (props.size === "big" ? "" : "50px")};
  ${({ size }) => size === "big" && `cursor: pointer;`}
`;

const VolumeTitle = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  color: ${({ theme }) => theme.textSecond};
  white-space: nowrap;
`;

const SearchTicker = styled.div`
  flex: 4;
  position: relative;
  z-index: 4;
  margin: 8px 16px;
  .pl-3 {
    background: ${({ theme }) => theme.grey100};
  }
  .input-ticker-search {
    background: ${({ theme }) => theme.grey100};
  }
  .text-white-neutral1 {
    color: ${({ theme }) => theme.grey0};
  }
  .flex-fill {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TextSearch = styled.ul`
  padding: 0px;
  background: ${({ theme }) => theme.background3};
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: 4px;
  max-height: 450px;
  overflow: auto;
`;

const TextItem = styled.li`
  div {
    .share-code {
      color: ${({ theme }) => theme.textcolorWhite};
    }
    .share-name {
      color: ${({ theme }) => theme.grey40};
    }
    color: ${({ theme }) => theme.textcolorWhite};
  }
  :hover {
    background: ${({ theme }) => theme.background4};
    div {
      color: ${({ theme }) => theme.brandPrimary};
      span {
        color: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
  :focus {
    div {
      color: ${({ theme }) => theme.brandPrimary};
      span {
        color: ${({ theme }) => theme.brandPrimary};
      }
    }
  }
  :focus-visible {
    outline: none;
  }
`;

const IconNotifi = styled.div`
  margin-bottom: 2px;
  cursor: pointer;
`;

const Container = styled.div`
  margin: 8px 16px;
`;

const CurrentTicker = styled.div<{
  size?: string;
  weight?: string | undefined;
  line?: string | undefined;
  colorKey?: string | undefined;
  isTruncate?: boolean;
}>`
  color: ${({ theme, colorKey }) =>
    colorKey ? theme[colorKey] : theme.textcolorBlack};
  font-size: ${({ theme, size }) => (size ? size : theme.typeFaces.title.size)};
  ${({ weight }) => weight && `font-weight: ${weight}`};
  ${({ line }) => line && `line-height: ${line}`};
  margin-right: 2px;
  white-space: nowrap;
  ${({ isTruncate }) =>
    isTruncate &&
    `
  overflow: hidden;
  text-overflow: ellipsis;`};
  font-weight: ${({ theme }) => theme.typeFaces.title.weight.bold};
`;

const RowSearch = styled.div`
  display: flex;
  .flex-4 {
    flex: 4;
  }
  .flex-1 {
    flex: 1;
  }
`;

const TabOrder = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.borderColor};
  margin-left: 12px;
  border-radius: 4px;
`;

const TabOrderItem = styled.div<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  ${({ theme, isActive }) =>
    isActive
      ? `
    background: ${theme.bgElevated3};
    svg {
      path {
        fill: ${theme.brandPrimary};
      }
    }
  `
      : `
    background: ${theme.background1};
    svg {
      path {
        fill: ${theme.neutral4};
      }
    }
  `};
  :last-child {
    border: none;
  }
`;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: rgba(40, 54, 72, 0.8);
    max-width: 276px;
    border-radius: 4px;
    padding: 8px 12px;
    text-align: left;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .arrow {
    &:before {
      border-bottom-color: ${({ theme }) => theme.brandSecondary};
    }
  }
`;

export {
  TickerContainer,
  TickerWrappper,
  StarContainer,
  InputLayer,
  TextColor,
  VolumnTextColor,
  VolumeTitle,
  SearchTicker,
  TextSearch,
  TextItem,
  IconNotifi,
  Container,
  CurrentTicker,
  TabOrder,
  TabOrderItem,
  RowSearch,
  StyledTooltip,
};
