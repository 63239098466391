import styled from "styled-components/macro";
type WrapProps = {
  size?: number;
  isMinWidth?: boolean;
};
export const Wrap = styled.div<WrapProps>`
  width: ${({ theme, isMinWidth }) =>
    isMinWidth ? `100px;` : theme.input.width};
  p {
    margin-bottom: 4px;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    color: ${({ theme }) => theme.grey20};
  }
`;
