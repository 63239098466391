import styled from "styled-components/macro";

export const Container = styled.div`
  height: calc(100%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  overflow: auto;
  position: relative;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100%) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const HeaderCell = styled.div<{
  align?: string;
}>`
  background: ${({ theme }) => theme.background3};
  color: ${({ theme }) => theme.grey20};
  font-weight: 500;
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;

export const IconWrapper = styled.span`
  cursor: pointer;
`;
export const ButtonLiquidate = styled.button<{
  bgcolor?: string;
  color?: string;
}>`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ bgcolor }) => bgcolor};

  color: ${({ color }) => color};
`;
export const ButtonBuy = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.green50};
  color: ${({ theme }) => theme.grey0};
`;

export const ButtonSell = styled.button`
  flex: 1;
  width: 50%;
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ theme }) => theme.red50};
  color: ${({ theme }) => theme.grey0};
`;

export const Table = styled.div`
  height: calc(100%);
  overflow: auto;
  position: relative;
`;
export const TableCellContent = styled.div<{
  color?: string;
  align?: string;
  fixWidth?: number;
  isInput?: boolean;
  noWrap?: boolean;
  fontSize?: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: ${({ noWrap }) => (noWrap ? "normal" : "nowrap")};
  ${({ isInput }) => (isInput ? `padding: 4px 8px;` : `padding: 0 8px;`)}
  align-content: center;
  align-items: center;
  text-align: ${({ noWrap }) => (noWrap ? "left" : "center")};
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ color, theme }) => (color ? color : theme.grey0)};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? fontSize : theme.typeFaces.caption12.size};
  height: ${({ theme }) => theme.tables.content.height};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  &.market-ticker-name {
    padding: 0 12px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.full-company-name {
    height: 36px;
  }
  .input-edit {
    background-color: ${({ theme }) => theme.bgBase};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.text};
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    text-align: right;
  }
`;

export const TableCellContentColor = styled.div<{
  color?: string;
  align?: string;
  fixWidth?: number;
  isInput?: boolean;
  noWrap?: boolean;
  fontSize?: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: ${({ noWrap }) => (noWrap ? "normal" : "nowrap")};
  ${({ isInput }) => (isInput ? `padding: 4px 8px;` : `padding: 0 8px;`)}
  align-content: center;
  align-items: center;
  text-align: ${({ noWrap }) => (noWrap ? "left" : "center")};
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ color, theme }) => (color ? color : "inherit")};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? fontSize : theme.typeFaces.caption12.size};
  height: ${({ theme }) => theme.tables.content.height};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  &.market-ticker-name {
    padding: 0 12px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.full-company-name {
    height: 36px;
  }
  .input-edit {
    background-color: ${({ theme }) => theme.bgBase};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.text};
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    text-align: right;
  }
`;
export const TableHeaderCellContent = styled.div<{
  color?: string;
  align?: string;
  isFooter?: boolean;
  fixWidth?: number;
  setHeight?: number;
}>`
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  ${({ theme, color, isFooter }) =>
    isFooter
      ? `
    color: ${color ? theme[color] : theme.textcolorWhite};
    font-weight: ${theme.typeFaces.caption10.weight.semiBold};
  `
      : `
    color: ${color ? theme[color] : theme.textcolorBlack};
  `}
  text-transform: uppercase;
  background: ${({ theme }) => theme.bgElevated3};
  font-size: ${({ theme, isFooter }) =>
    isFooter ? theme.typeFaces.caption12.size : theme.typeFaces.caption10.size};
  border: none;
  height: ${({ theme, isFooter }) =>
    isFooter ? theme.tables.footer.height : theme.tables.header.height};
  ${({ setHeight }) => setHeight && `height: ${setHeight}px`};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.weight.regular};
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  width: 80%;
  margin: 0 auto;
  align-items: flex-end;
`;
export const ButtonCell = styled.div`
  flex: 1;
  width: 80%;
  text-transform: uppercase;
  margin: 0 auto;
`;
export const ButtonConfirm = styled.div`
  flex: 1;
  text-align: center;
  cursor: pointer;
  user-select: none;

  color: ${({ theme }) => theme.green50};
  margin-right: 15px;
`;
export const ButtonCancel = styled.div`
  flex: 1;
  text-align: center;
  cursor: pointer;
  user-select: none;

  color: ${({ theme }) => theme.red50};
`;
