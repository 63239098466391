/* eslint-disable react-hooks/exhaustive-deps */
import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { columnOpen } from "./constants";
import { useEffect, useState } from "react";
import { findDerTPositionApi } from "core/http/apis/assetsIncurred";
import { toast } from "components/commons/Toast";
import {
  HeaderCell,
  TableCellContent,
  TableHeaderCellContent,
  ContainerTable,
  Icon,
  ButtonRefresh,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
// import moment from "moment";
import { Button } from "components/commons";
import { ReactComponent as IconTrash } from "assets/image/svg/icon_refresh_2.svg";
import { tradeDateSelector } from "modules/auth/selectors";
import { useSelector } from "react-redux";

const TableOpen = () => {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const tradeDate = useSelector(tradeDateSelector);

  const getData = async () => {
    setLoading(true);
    const parrams = {
      custNo: "",
      openDateFrom: tradeDate,
      openDateTo: tradeDate,
      secCd: "",
      status: 1,
      subAccoNo: "",
    };
    const res: any = await findDerTPositionApi(parrams);
    if (res?.statusCode === 0) {
      setLoading(false);

      setData(res?.data);
    } else {
      setLoading(false);

      toast(res?.errorCode, "error", res?.message, true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const fetchMoreData = () => {};

  const columns = [
    {
      Header: (
        <HeaderCell align={"flex-start"}>
          <FormattedMessage id="assets.assetsIncurred.bottom.tab1.table.secCd" />
        </HeaderCell>
      ),
      accessor: "secCd",
      Cell: (props: any) => {
        const currentRow = props?.row?.original;
        return (
          <TableCellContent
            align={"flex-start"}
            color={
              currentRow?.profiltAmt >= 0
                ? currentRow?.profiltAmt === 0
                  ? "white"
                  : "green"
                : "red"
            }
          >
            {props.value}
          </TableCellContent>
        );
      },
      Footer: (props: any) => {
        return (
          <TableHeaderCellContent align={"flex-start"} isFooter>
            <Button color="accept" loading={loading} onClick={getData}>
              <Icon>
                <ButtonRefresh>
                  <IconTrash width={16} height={16} />
                </ButtonRefresh>
              </Icon>
              {intl.formatMessage({
                id: "positionManagement.positionOpen.refresh",
              })}
            </Button>
          </TableHeaderCellContent>
        );
      },
    },
    ...columnOpen,
  ];

  return (
    <ContainerTable id="positionOpen">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data}
        enableInfinity={true}
        scrollableTarget={"positionOpen"}
      />
    </ContainerTable>
  );
};

export default TableOpen;
