import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PutThroughData } from "domain/protoNew/auto_trading_pb";

export type PutThroughState = {
  selectedTypePutThrough: string;
  loading: boolean;
  priceInfoPutThroughList: PutThroughData.AsObject;
  error: string | undefined;
};

const initialState: PutThroughState = {
  selectedTypePutThrough: "",
  loading: false,
  priceInfoPutThroughList: {
    selllistList: [],
    buylistList: [],
    matchptlistList: [],
  },
  error: undefined,
};

const putThroughSlice = createSlice({
  name: "putThroughSlice",
  initialState,
  reducers: {
    changeTypePutThrough: (state, action: PayloadAction<string>) => {
      state.selectedTypePutThrough = action.payload;
    },
    fetchListPriceInfoPutThrough: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    fetchListPriceInfoPutThroughSuccess: (
      state,
      action: PayloadAction<PutThroughData.AsObject>
    ) => {
      state.priceInfoPutThroughList = action.payload;
      state.loading = false;
    },
    fetchListPriceInfoPutThroughFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  changeTypePutThrough,
  fetchListPriceInfoPutThrough,
  fetchListPriceInfoPutThroughSuccess,
  fetchListPriceInfoPutThroughFailure,
} = putThroughSlice.actions;

export default putThroughSlice.reducer;
