import styled from "styled-components/macro";

export const Notification = styled.div<{ status?: boolean }>`
  background: ${({ theme, status }) =>
    status ? theme.background3 : theme.background2};
  color: ${({ theme }) => theme.grey0};
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
`;

export const Icon = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  height: 32px;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  padding: 7px 12px;
`;

export const TitleLeft = styled.div<{ status?: boolean }>`
  color: ${({ theme }) => theme.textcolorWhite};
  text-align: left;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  padding-right: 10px;
`;

export const TitleRight = styled.div`
  color: ${({ theme }) => theme.grey30};
  display: flex;
  align-items: center;
`;

export const Content = styled.div<{
  status?: boolean;
  lengthTextContent?: number;
}>`
  text-align: left;
  color: ${({ theme }) => theme.textcolorWhite};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: ${({ theme }) =>
    theme.typeFaces.body.size *
    theme.typeFaces.body.lineHeight *
    3}; /* Fallback for non-webkit */
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 12px;
`;

export const NotificationParent = styled.div`
  width: 100%;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0px;
  }
`;

export const Time = styled.div`
  flex: 2;
  margin-right: 8px;
`;
