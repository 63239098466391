import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";
import orgInfo from "./orgInfo";
import newsInfo from "./newsInfo";
import financeInfo from "./financeInfo";
// import infoQuoteReducer from "./infoQuote";
import orderFlowDistributionReducer, {
  OrderFlowDistributionState,
} from "./orderFlowDistribution";
import tickerInfoReducer, { TickerInfoState } from "./tickerInfo";
import volumeAndTime from "./volumeAndTime";
import priceListReducer from "./priceList";
// import coverWarrantInfoReducer from "./coverWarrantInfo";
import vn30StatBasketContributionReducer from "./VN30StatBasketContribution";

// TODO
export interface TickerDetailsState {
  root: State;
  orderFlowDistribution: OrderFlowDistributionState;
  tickerInfo: TickerInfoState;
}

export type ChartData = {
  labels: number[];
  thresholdValue: number;
  datasets: any[];
};

export type ChartValueItem = {
  closedValue?: number;
  volumeValue?: number;
  timeValue?: number;
};

const appendChartData = (
  chartData: ChartData,
  appendData: ChartValueItem
): ChartData => {
  const { labels, datasets } = chartData;
  if (
    !appendData.timeValue ||
    !appendData.closedValue ||
    !appendData.volumeValue
  ) {
    return chartData;
  }
  labels.push(appendData.timeValue * 1000);
  const priceLineDataset = datasets[0];
  const volumeBarDataset = datasets[1];

  return {
    thresholdValue: chartData.thresholdValue,
    labels,
    datasets: [
      {
        ...priceLineDataset,
        data: [...priceLineDataset.data, appendData.closedValue],
      },
      {
        ...volumeBarDataset,
        data: [...volumeBarDataset.data, appendData.volumeValue],
      },
    ],
  };
};

export interface State {
  activeInfoTag: string;
  activePriceTag: string;
  chartDataTicker: ChartData;
  widgetTickerDetailName: string;
  widgetTickerDetailDerName: string;
  widgetTickerDetailCWName: string;
}

const initialState: State = {
  activeInfoTag: "news",
  activePriceTag: "price-list",
  chartDataTicker: {
    labels: [],
    thresholdValue: 0,
    datasets: [],
  },
  widgetTickerDetailName: "",
  widgetTickerDetailDerName: "",
  widgetTickerDetailCWName: "",
};

const tickerDetailsSlice = createSlice({
  name: "tickerDetailsSlice",
  initialState,
  reducers: {
    setCurrentInfoTag: (state, action: PayloadAction<string>) => {
      state.activeInfoTag = action.payload;
    },
    setCurrentPriceTag: (state, action: PayloadAction<string>) => {
      state.activePriceTag = action.payload;
    },

    getChartDataTicker: (state, action: PayloadAction<string>) => {
      state.chartDataTicker = {
        labels: [],
        thresholdValue: 0,
        datasets: [],
      };
    },
    getChartDataTickerSuccess: (state, action: PayloadAction<any>) => {
      state.chartDataTicker = action.payload.data;
    },
    getChartDataTickerFailure: (state, action: PayloadAction<string>) => {},
    updateChartDataDerivative: (
      state,
      action: PayloadAction<ChartValueItem>
    ) => {
      if (!state.chartDataTicker.datasets.length) return;
      state.chartDataTicker = appendChartData(
        state.chartDataTicker,
        action.payload
      );
    },
    addWidgetTickerDetailName(state, action: PayloadAction<string>) {
      state.widgetTickerDetailName = action.payload;
    },
    addWidgetTickerDetailDerName(state, action: PayloadAction<string>) {
      state.widgetTickerDetailDerName = action.payload;
    },
    addWidgetTickerDetailCWName(state, action: PayloadAction<string>) {
      state.widgetTickerDetailCWName = action.payload;
    },
  },
});

export const {
  setCurrentInfoTag,
  setCurrentPriceTag,
  getChartDataTicker,
  getChartDataTickerSuccess,
  getChartDataTickerFailure,
  updateChartDataDerivative,
  addWidgetTickerDetailName,
  addWidgetTickerDetailDerName,
  addWidgetTickerDetailCWName,
} = tickerDetailsSlice.actions;

// TODO
export default combineReducers({
  root: tickerDetailsSlice.reducer,
  orgInfo: orgInfo,
  newsInfo: newsInfo,
  financeInfo: financeInfo,
  volumeAndTime: volumeAndTime,
  orderFlowDistribution: orderFlowDistributionReducer,
  // infoQuote: infoQuoteReducer,
  tickerInfo: tickerInfoReducer,
  // coverWarrantInfo: coverWarrantInfoReducer,
  vn30StatBasketContribution: vn30StatBasketContributionReducer,
  priceList: priceListReducer,
});
