import {
  searchContractApi,
  liquidateContractApi,
  findBrokerListApi,
  createContractApi,
} from "./urls";
import {
  ISearchContractResponse,
  ILiquidateContractResponse,
  IFindBrokerListResponse,
  ICreateContractResponse,
} from "domain/models/CreateContract";

import {
  ISearchContractParams,
  ILiquidateContractParams,
  IFindBrokerListParams,
  ICreateContractParams,
} from "./types";
import VgaiaHttp from "core/http/singleton/vgaia";

export const searchContract = async (
  params: ISearchContractParams
): Promise<ISearchContractResponse> => {
  const res = await VgaiaHttp.get<ISearchContractResponse>(
    searchContractApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const liquidateContract = async (
  params: ILiquidateContractParams
): Promise<ILiquidateContractResponse> => {
  const res = await VgaiaHttp.post<ILiquidateContractResponse>(
    liquidateContractApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createContract = async (
  params: ICreateContractParams
): Promise<ICreateContractResponse> => {
  const res = await VgaiaHttp.post<ICreateContractResponse>(
    createContractApi,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
export const findBrokerList = async (
  params: IFindBrokerListParams
): Promise<IFindBrokerListResponse> => {
  const res = await VgaiaHttp.get<IFindBrokerListResponse>(
    findBrokerListApi(params)
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
