import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  getLiquidityData,
  getLiquidityDataFailure,
  getLiquidityDataSuccess,
} from "../redux/liquidity";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { ChartData as ChartDataType } from "modules/market/redux/marketWatch";
import { themeTypeSelector } from "themes/redux";
import { ThemeType } from "themes/types";
import themes from "themes/abstracts/_themes";
import { IntlShape } from "react-intl";
import { intlSelector } from "languages/selectors";
import { MarketLiquidityItem } from "domain/protoNew/auto_trading_pb";
import { getMarketLiquidity } from "core/grpc";
import { setCurrentMarketCode } from "../redux";
import { currentMarketCodeSelector } from "../redux/selectors";
import { EMarketCodeNew } from "helper/consts";

const formatLiquidityChartData = (
  data: MarketLiquidityItem.AsObject[],
  themeType: ThemeType,
  intl: IntlShape
): ChartDataType => {
  if (isEmpty(data)) {
    return {
      labels: [],
      datasets: [],
    };
  }

  const timeListLabel: string[] = [];
  const valueListPresent: number[] = [];
  const valueListOneDayAgo: number[] = [];
  const valueListOneWeekAgo: number[] = [];
  const valueListTwoWeekAgo: number[] = [];
  const valueListOneMonthkAgo: number[] = [];

  data.forEach((item: MarketLiquidityItem.AsObject, index: number) => {
    timeListLabel.push(item.time);

    valueListPresent.push(item.valuet0 * 1000);
    valueListOneDayAgo.push(item.valuet1 * 1000);
    valueListOneWeekAgo.push(item.valuet5 * 1000);
    valueListTwoWeekAgo.push(item.valuet10 * 1000);
    valueListOneMonthkAgo.push(item.valuet30 * 1000);
  });
  return {
    labels: timeListLabel,
    datasets: [
      {
        label: `  ${intl.formatMessage({ id: "widgets.liquidity.present" })}`,
        lineTension: 0,
        data: valueListPresent,
        backgroundColor: get(themes[themeType], "solidNavy"),
        type: "bar",
        categoryPercentage: 0.5,
        fill: true,
        order: 5,
        pointStyle: "rectRounded",
      },
      {
        label: `  ${intl.formatMessage({
          id: "widgets.liquidity.lastSession",
        })}`,
        data: valueListOneDayAgo,
        backgroundColor: get(themes[themeType], "solidGreen"),
        borderColor: get(themes[themeType], "solidGreen"),
        borderWidth: 1,
        lineTension: 0,
        type: "line",
        fill: false,
        order: 4,
        pointStyle: "circle",
      },
      {
        label: `  ${intl.formatMessage({ id: "widgets.liquidity.oneWeek" })}`,
        data: valueListOneWeekAgo,
        backgroundColor: get(themes[themeType], "solidRed"),
        borderColor: get(themes[themeType], "solidRed"),
        borderWidth: 1,
        lineTension: 0,
        type: "line",
        fill: false,
        order: 3,
        pointStyle: "rectRot",
      },
      {
        label: `  ${intl.formatMessage({ id: "widgets.liquidity.twoWeek" })}`,
        data: valueListTwoWeekAgo,
        backgroundColor: get(themes[themeType], "solidYellow"),
        borderColor: get(themes[themeType], "solidYellow"),
        lineTension: 0,
        borderWidth: 1,
        type: "line",
        fill: false,
        order: 2,
        pointStyle: "triangle",
      },
      {
        label: `  ${intl.formatMessage({ id: "widgets.liquidity.oneMonth" })}`,
        data: valueListOneMonthkAgo,
        backgroundColor: get(themes[themeType], "solidViolet"),
        borderColor: get(themes[themeType], "solidViolet"),
        lineTension: 0,
        borderWidth: 1,
        type: "line",
        fill: false,
        order: 1,
        pointStyle: "star",
      },
    ],
  };
};

function* getLiquidityDataSaga() {
  try {
    const currentMarketCode: EMarketCodeNew = yield select(
      currentMarketCodeSelector
    );
    const response: MarketLiquidityItem.AsObject[] = yield call(
      getMarketLiquidity,
      currentMarketCode
    );
    const intl: IntlShape = yield select(intlSelector);
    const themeType: ThemeType = yield select(themeTypeSelector);
    yield put(
      getLiquidityDataSuccess({
        data: formatLiquidityChartData(response, themeType, intl),
      })
    );
  } catch (e: any) {
    yield put(getLiquidityDataFailure(e));
  }
}

function* fetchLiquidityWatcher() {
  yield takeLatest(getLiquidityData.type, getLiquidityDataSaga);
}
function* setCurrentMarketCodeWatcher() {
  yield takeLatest(setCurrentMarketCode.type, getLiquidityDataSaga);
}

export default function* liquidityWidget() {
  yield all([fetchLiquidityWatcher(), setCurrentMarketCodeWatcher()]);
}
