import FlexLayout from "flexlayout-react";
import { IntlShape } from "react-intl";
export const dataFlexLayout: any = {
  blank: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 50,
          children: [],
        },

        {
          type: "row",
          weight: 50,
          children: [],
        },
      ],
    },
  },

  //layout stock order

  normal: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.volumeAndTime.title",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "widgets.portfolio.name",
                },
                {
                  type: "tab",
                  name: "widgets.orderBookOfTheDay.name",
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.stockOrder.name",
            },
          ],
        },
      ],
    },
  },
  normal2: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.stockOrder.name",
            },
          ],
        },
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.watchList.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.volumeAndTime.title",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  //layout stock order small screen

  normalSmallScreen: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "widgets.portfolio.name",
                },
                {
                  type: "tab",
                  name: "widgets.orderBookOfTheDay.name",
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.stockOrder.name",
            },
          ],
        },
      ],
    },
  },

  normalSmallScreen2: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.stockOrder.name",
            },
          ],
        },
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.watchList.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.volumeAndTime.title",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  //layout stock order der

  normalDer: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.volumeAndTime.title",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "widgets.openPosition.name",
                },
                {
                  type: "tab",
                  name: "widgets.closePosition.name",
                },
                {
                  type: "tab",
                  name: "orderBook.title",
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.placeOrderDer.name",
            },
          ],
        },
      ],
    },
  },
  normalDer2: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.placeOrderDer.name",
            },
          ],
        },
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.openPosition.name",
                    },
                    {
                      type: "tab",
                      name: "widgets.closePosition.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.volumeAndTime.title",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  //layout stock order der small screen

  normalDerSmallScreen: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "widgets.openPosition.name",
                },
                {
                  type: "tab",
                  name: "widgets.closePosition.name",
                },
                {
                  type: "tab",
                  name: "orderBook.title",
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.placeOrderDer.name",
            },
          ],
        },
      ],
    },
  },

  normalDerSmallScreen2: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "widgets.placeOrderDer.name",
            },
          ],
        },
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.openPosition.name",
                    },
                    {
                      type: "tab",
                      name: "widgets.closePosition.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.tickerGraph.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.priceList.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.volumeAndTime.title",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  //layout market

  normalMarket: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.adIndex.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.netflow.name",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "widgets.topPrice.left",
                    },
                    {
                      type: "tab",
                      name: "widgets.topPrice.right",
                    },
                    {
                      type: "tab",
                      name: "widgets.topStatistical.name",
                    },
                    {
                      type: "tab",
                      name: "widgets.topForeign.name",
                    },
                  ],
                },
              ],
            },
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "widgets.marketWatch.name",
                },
                {
                  type: "tab",
                  name: "widgets.foreignWatch.name",
                },
                {
                  type: "tab",
                  name: "industry.name",
                },
              ],
            },
          ],
        },
      ],
    },
  },

  //layout default technical analysis

  normalTechAnalysis: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "column",
          weight: 70,
          children: [
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "techAnalysis.widgets.technicalAnalysis",
                },
              ],
            },
          ],
        },

        {
          type: "column",
          weight: 30,
          children: [
            {
              type: "row",
              weight: 30,
              children: [
                {
                  type: "tabset",
                  weight: 30,
                  children: [
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.priceList",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.tickerInfo",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 30,
              children: [
                {
                  type: "tabset",
                  weight: 30,
                  children: [
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.volumeAndTime",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.orderFlowDistribution",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.organizationalInfo",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.news",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.finance",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  normalSmallTechAnalysis: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "column",
          weight: 70,
          children: [
            {
              type: "tabset",
              weight: 50,
              children: [
                {
                  type: "tab",
                  name: "techAnalysis.widgets.technicalAnalysis",
                },
              ],
            },
          ],
        },

        {
          type: "column",
          weight: 30,
          children: [
            {
              type: "row",
              weight: 30,
              children: [
                {
                  type: "tabset",
                  weight: 30,
                  children: [
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.priceList",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.tickerInfo",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 30,
              children: [
                {
                  type: "tabset",
                  weight: 30,
                  children: [
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.volumeAndTime",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.orderFlowDistribution",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.organizationalInfo",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.news",
                    },
                    {
                      type: "tab",
                      name: "techAnalysis.widgets.finance",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  tickerDetail: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.tickerInfo",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.priceList",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.volumeAndTime",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.tickerGraph",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.orderFlowDistribution",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "tickerDetail.widgets.financeInfo",
            },
            {
              type: "tab",
              name: "tickerDetail.widgets.organizationInfo",
            },
            {
              type: "tab",
              name: "tickerDetail.widgets.new",
            },
          ],
        },
      ],
    },
  },

  tickerDerDetail: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.tickerInfo",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.priceList",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.volumeAndTime",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.tickerGraph",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.orderFlowDistribution",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "tickerDetail.widgets.tickerDetailsBasketContribution",
            },
          ],
        },
      ],
    },
  },

  tickerCWDetail: {
    global: {
      tabEnableRename: false,
      tabSetMinWidth: 400,
    },
    layout: {
      type: "row",
      weight: 100,
      children: [
        {
          type: "row",
          weight: 25,
          children: [
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.tickerGraph",
                    },
                  ],
                },
              ],
            },
            {
              type: "row",
              weight: 50,
              children: [
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.priceList",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.volumeAndTime",
                    },
                  ],
                },
                {
                  type: "tabset",
                  weight: 25,
                  children: [
                    {
                      type: "tab",
                      name: "tickerDetail.widgets.orderFlowDistribution",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "tabset",
          weight: 8.25,
          children: [
            {
              type: "tab",
              name: "tickerDetail.widgets.coverWarrantInfo",
            },
          ],
        },
      ],
    },
  },
};

export const getDefaultTab = (id: string, intl: IntlShape) => {
  return {
    name: intl.formatMessage({ id: "stockOrderBoard.chooseABoard" }),
    id,
  };
};

export const formatTextFlexLayout = (
  id: string,
  intl: IntlShape,
  param?: string
) => {
  if (id === FlexLayout.I18nLabel.Move_Tab) {
    return intl.formatMessage({ id: param });
  }
  if (id === FlexLayout.I18nLabel.Overflow_Menu_Tooltip) {
    return intl.formatMessage({ id: "common.hiddenTab" });
  }
  if (id === FlexLayout.I18nLabel.Close_Tab) {
    return intl.formatMessage({ id: "common.close" });
  }
  if (id === FlexLayout.I18nLabel.Maximize) {
    return intl.formatMessage({ id: "common.maximizeTab" });
  }
  if (id === FlexLayout.I18nLabel.Restore) {
    return intl.formatMessage({ id: "common.restoreTab" });
  }
  return undefined;
};

export const parseDataToSaveStorage = (dataStore: any) => {
  const dataClone: any = [];
  dataStore.forEach((item: any) => {
    dataClone.push({
      ...item,
      ...(item.model ? { model: item.model.toJson() } : {}),
    });
  });
  return dataClone;
};

export const parseDataToState = (data: any) => {
  const dataParse = JSON.parse(data).map((item: any) => {
    return {
      ...item,
      ...(item.model ? { model: FlexLayout.Model.fromJson(item.model) } : {}),
    };
  });
  return dataParse;
};
