import { ContentOne, Container, LabelCell, ContentTwo } from "./styles";

import { reset } from "modules/transfer-local/redux";

import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import RequestFilter from "./RequestFilters";
import RequestTable from "./RequestTable";
import ChartProfitLoss from "./ChartProfitLoss";

import { FormattedMessage } from "react-intl";
const ProfitLossContent: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Container>
      <ContentOne>
        <LabelCell>
          <FormattedMessage id="profitLoss.profitLoss" />
        </LabelCell>
        <RequestFilter />
        <RequestTable />
      </ContentOne>
      <ContentTwo>
        <LabelCell>
          <FormattedMessage id="profitLoss.chartProfitLoss" />
        </LabelCell>
        <ChartProfitLoss />
      </ContentTwo>
    </Container>
  );
};
export default ProfitLossContent;
