import {
  GetOrderBookResponse,
  OrderBookDisplay,
  OrderBookItem,
  IOrderBookCondItemDisplay,
  IOrderBookCondItem,
} from "domain/models/OrderBook";
import {
  Channel,
  OrderStatusCondDetailType,
  OrderStatusDerCondDetailType,
  OrderStatusDetailType,
  OrderTypeVal,
} from "helper/consts";
import moment from "moment";
import numeral from "numeral";
import { IntlShape } from "react-intl";
import {
  getOrderCondStatus,
  getOrderDerCondStatus,
  getOrderStatus,
  getOrderStatusCondDisp,
  getOrderStatusDerCondDisp,
  getOrderStatusDisp,
} from "helper/tradingHelper";
import {
  IFindDertOrderByFilterResponse,
  IFindDertRequestCondResponse,
  OrderBookDerCond,
  OrderBookDerCondDisplay,
  OrderBookDerDisplay,
  OrderBookDerItem,
} from "domain/models/OrderBookDer";
// import { ConditionalCommandTypeEnum } from "components/widgets/generals/PlaceOrderDer/ConditionalCommandType";

export const conditionTradeTypes: { [key: string]: string } = {
  N: "orderBookDer.Normal",
  U: "orderBookDer.UP",
  D: "orderBookDer.DOWN",
  V: "orderBookDer.TUP",
  E: "orderBookDer.TDOWN",
  B: "orderBookDer.BULLBEAR",
  O: "orderBookDer.OCO",
};

export const getOrderStatusColor = (
  orderData: OrderBookItem | OrderBookDisplay | OrderBookDerItem
) => {
  const status = getOrderStatus(orderData);
  switch (status) {
    case OrderStatusDetailType.ENTERED:
      return "pending";
    case OrderStatusDetailType.FILLED:
      return "success";
    case OrderStatusDetailType.REJECTED:
    case OrderStatusDetailType.REJECTED_DEPARTMENT:
    case OrderStatusDetailType.CANCELLED:
    case OrderStatusDetailType.PARTIALLY_CANCELLED:
      return "reject";
    case OrderStatusDetailType.PARTIALLY_FILLED:
      return "progress";
    case OrderStatusDetailType.WAIT_FILLED:
      return "wait";
    default:
      return "pending";
  }
};

export const getOrderStatusDerCondColor = (orderData: OrderBookDerCond) => {
  const status = getOrderDerCondStatus(orderData);
  switch (status) {
    case OrderStatusDerCondDetailType.WAIT_ACTIVE:
      return "pending";
    case OrderStatusDerCondDetailType.FINISHED:
      return "success";
    case OrderStatusDerCondDetailType.EXPIRE:
    case OrderStatusDerCondDetailType.CANCELED:
      return "reject";
    default:
      return "pending";
  }
};

export const getOrderStatusCondColor = (orderData: IOrderBookCondItem) => {
  const status = getOrderCondStatus(orderData);
  switch (status) {
    case OrderStatusCondDetailType.EFFECT:
      return "pending";
    case OrderStatusCondDetailType.FINISHED:
      return "success";
    case OrderStatusCondDetailType.EXPIRE:
    case OrderStatusCondDetailType.CANCELED:
      return "reject";
    default:
      return "pending";
  }
};

export const getOrderStatusPercent = (
  item: OrderBookItem | OrderBookDerItem
): number => {
  let percent = 0;
  const orderStatusColor = getOrderStatusColor(item);
  if (orderStatusColor === "progress") {
    percent = (Number(item.matQty) / Number(item.ordQty)) * 100;
    if (Number.isNaN(percent)) {
      percent = 0;
    }
  }

  return percent;
};

export const getCreatedTime = (item: OrderBookItem | OrderBookDerItem) => {
  const createTimeDisp =
    item?.reqTime && moment(item?.reqTime).format("DD/MM/yyyy HH:mm:ss");

  return createTimeDisp;
};

export const getCreatedTimeDerCond = (item: OrderBookDerCond) => {
  const createTimeDisp =
    item?.regDateTime &&
    moment(item?.regDateTime).format("DD/MM/yyyy HH:mm:ss");

  return createTimeDisp;
};

export const getLastMatchedTimeDisp = (
  item: OrderBookItem,
  intl: IntlShape
) => {
  const lastMatchTime =
    item?.updDateTime &&
    moment(item?.updDateTime).format("DD/MM/yyyy HH:mm:ss");

  return lastMatchTime || intl.formatMessage({ id: "orderBookDer.noValue" });
};

export const getTradeTypeDisp = (item: OrderBookItem | IOrderBookCondItem) => {
  return `orderBook.${item.tradeType === 2 ? "BUY" : "SELL"}`;
};

export const getTradeTypeDerDisp = (
  item: OrderBookDerItem | OrderBookDerCond
) => {
  return `common.${item.tradeType === 2 ? "long" : "short"}`;
};

export const getStatusConditionDisPlay = (status: number) => {
  switch (status) {
    case 1:
      return "orderBook.tableCondition.takeEffect";
    case 2:
      return "orderBook.tableCondition.complete";
    case 3:
      return "orderBook.tableCondition.expired";
    case 9:
      return "orderBook.tableCondition.cancel";
    default:
      return "orderBook.tableCondition.noValue";
  }
};

export const getMatMethodDisplay = (matMethod: number) => {
  if (matMethod === 1) {
    return "orderBook.tableCondition.generatedOneTime";
  } else if (matMethod === 2) {
    return "orderBook.tableCondition.matchingEnoughVolume";
  } else {
    return "orderBook.tableCondition.noValue";
  }
};

export const getOptTypeDisplay = (optType: number) => {
  if (optType === 1) {
    return ">=";
  } else if (optType === 2) {
    return "<=";
  } else if (optType === 0) {
    return "widgets.placeOrder.conditionalOrder.conditionsList.noCondition";
  } else {
    return;
  }
};

export const getOptValTypeDisplay = (optType: number) => {
  if (optType === 2) {
    return "widgets.placeOrder.conditionalOrder.byPercent";
  } else if (optType === 1) {
    return "widgets.placeOrder.conditionalOrder.byValue";
  }
  return;
};

export const getCondTypeDisplay = (condType: string) => {
  switch (condType) {
    case "TCO":
      return "orderBook.tableCondition.timeConditionOrder";
    case "PRO":
      return "orderBook.tableCondition.priorityOrder";
    case "TS":
      return "orderBook.tableCondition.trailingStopOrder";
    case "SP":
      return "orderBook.tableCondition.takeProfit";
    case "CL":
      return "orderBook.tableCondition.cutLoss";
    default:
      return "orderBook.tableCondition.noValue";
  }
};

export const getCondTypeDerDisplay = (condType: string) => {
  switch (condType) {
    case "UO":
      return "orderBookDer.condType.orderUp";
    case "DO":
      return "orderBookDer.condType.orderDown";
    case "CL":
      return "orderBookDer.condType.ifDoneStop";
    case "SP":
      return "orderBookDer.condType.ifDoneGain";
    case "OC":
      return "orderBookDer.condType.orderOco";
    case "BB":
      return "orderBookDer.condType.orderBullBear";
    case "TS":
      return "orderBookDer.condType.orderTS";
    default:
      return "orderBook.tableCondition.noValue";
  }
};

export const getConditionOptTypeDisplay = (
  condType: string,
  optVal: number
) => {
  if (condType === "TCO" && optVal !== 0) {
    return "widgets.placeOrder.conditionalOrder.conditionsList.referencePrices";
  } else if ((condType === "CL" || condType === "SP") && optVal !== 0) {
    return "widgets.placeOrder.conditionalOrder.conditionsList.matchedPrice";
  }
  return;
};

// Có phải lệnh thị trường hay không
export const getIsMarketOrder = (item: OrderBookItem) => {
  if (item.ordType === OrderTypeVal.LO) {
    return false;
  }

  // // Lệnh MP khớp 1 phần
  if (item.ordType === OrderTypeVal.MTL || item.ordType === OrderTypeVal.MP) {
    const orderPrice = item.ordPrice ?? 0;
    if (orderPrice > 0) {
      return false;
    }
  }

  return true;
};

export const convertOrderBookData = (
  orderBookList: GetOrderBookResponse,
  intl: IntlShape
): OrderBookDisplay[] => {
  return Object.assign(
    [],
    orderBookList.data.map((item) => {
      return {
        ...item,
        realTimeId: item.clOrdId,
        ticker: item.secCd,
        orderIdDisp: item.clOrdId ?? "-",
        tradeTypeDisplay: getTradeTypeDisp(item),
        tradeTypeColor: item.tradeType === 2 ? "green" : "red",
        orderStatusDisplay: getOrderStatusDisp(item),
        orderStatusColor: getOrderStatusColor(item),
        orderStatusPercent: getOrderStatusPercent(item),
        createdTimeDisp: getCreatedTime(item),
        lastMatchTimeDisp: getLastMatchedTimeDisp(item, intl),
        valueMatch: getValueMatch(item),
        channelDisplay: item.ordChanel
          ? Channel[item.ordChanel] || item.ordChanel
          : intl.formatMessage({ id: "orderBookDer.noValue" }),
        visibleCheckbox:
          (item.status === "O" || item.status === "E") &&
          item.resolvedFlag !== 1 &&
          ["PT", "ATO", "ATC", "PLO"].find((type) => type !== item.ordType) !==
            undefined,
        cancelQty: item.canQty,
        orderPriceDisp: getOrderPriceDisp(item),
        isMarketOrder: getIsMarketOrder(item),
        iscancel:
          (item.status === "O" || item.status === "E") &&
          item.resolvedFlag !== 1 &&
          ["PT", "ATO", "ATC", "PLO"].find((type) => type !== item.ordType) !==
            undefined,
        isadmend:
          item.status === "O" &&
          item.ordType === "LO" &&
          item.resolvedFlag !== 1,
      };
    })
  );
};

export const convertOrderBookDerData = (
  orderBookList: IFindDertOrderByFilterResponse,
  intl: IntlShape,
  tradeDate: string
): OrderBookDerDisplay[] => {
  return Object.assign(
    [],
    orderBookList.data.map((item) => {
      return {
        ...item,
        realTimeId: item.orgOrderNo,
        ticker: item.secCd,
        orderIdDisp: item.orgOrderNo ?? "-",
        tradeTypeDisplay: getTradeTypeDerDisp(item),
        tradeTypeColor: item.tradeType === 2 ? "green" : "red",
        orderStatusDisplay: getOrderStatusDisp(item),
        orderStatusColor: getOrderStatusColor(item),
        orderStatusPercent: getOrderStatusPercent(item),
        createdTimeDisp: getCreatedTime(item),
        channelDisplay: item.ordChanel
          ? Channel[item.ordChanel] || item.ordChanel
          : intl.formatMessage({ id: "orderBookDer.noValue" }),
        visibleCheckbox:
          item.tradeDate.toString() === tradeDate &&
          (item.status === "O" || item.status === "E") &&
          item.ordType !== "PT",
        cancelQty: item.canQty,
        orderPriceDisp: getOrderPriceDisp(item),
        iscancel:
          item.tradeDate.toString() === tradeDate &&
          (item.status === "O" || item.status === "E") &&
          item.ordType !== "PT",
        isadmend:
          item.tradeDate.toString() === tradeDate &&
          item.status === "O" &&
          item.ordType !== "PT",
      };
    })
  );
};

export const convertOrderBookDerCondData = (
  orderBookList: IFindDertRequestCondResponse,
  intl: IntlShape
): OrderBookDerCondDisplay[] => {
  return Object.assign(
    [],
    orderBookList.data.map((item) => {
      return {
        ...item,
        realTimeId: item.refNo,
        ticker: item.secCd,
        orderIdDisp: item.refNo ?? "-",
        tradeTypeDisplay: getTradeTypeDerDisp(item),
        tradeTypeColor: item.tradeType === 2 ? "green" : "red",
        orderStatusDisplay: getOrderStatusDerCondDisp(item),
        orderStatusColor: getOrderStatusDerCondColor(item),
        condTypeDisplay: getCondTypeDerDisplay(item.condType),
        createdTimeDisp: getCreatedTimeDerCond(item),
        channelDisplay: item.ordChanel
          ? Channel[item.ordChanel] || item.ordChanel
          : intl.formatMessage({ id: "orderBookDer.noValue" }),
        visibleCheckbox: item.status === 1,
        orderPriceDisp: numeral(item.ordPrice).format("0,0.0"),
      };
    })
  );
};

export const convertOrderBookConditionData = (
  orderBookConditionList: IOrderBookCondItem[],
  intl: IntlShape
): IOrderBookCondItemDisplay[] => {
  return Object.assign(
    [],
    orderBookConditionList.map((item) => {
      return {
        ...item,
        ticker: item.secCd,
        orderIdDisp: item.id ?? "-",
        tradeTypeDisplay: getTradeTypeDisp(item),
        tradeTypeColor: item.tradeType === 2 ? "green" : "red",
        channelDisplay: item.channel
          ? Channel[item.channel] || item.channel
          : intl.formatMessage({ id: "orderBookDer.noValue" }),
        visibleCheckbox: item.status === 1 ? true : false,
        statusDisplay: getOrderStatusCondDisp(item),
        orderStatusColor: getOrderStatusCondColor(item),
        matMethodDisplay: getMatMethodDisplay(item.matMethod),
        optTypeDisplay: getOptTypeDisplay(item.optType),
        optValTypeDisplay: getOptValTypeDisplay(item.optValType),
        effectDateDisplay: moment(item.fromDate.toString(), "YYYYMMDD").format(
          "DD/MM/YYYY"
        ),
        expiredDateDisplay: moment(item.toDate.toString(), "YYYYMMDD").format(
          "DD/MM/YYYY"
        ),
        condTypeDisplay: getCondTypeDisplay(item.condType),
        conditionOptTypeDisplay: getConditionOptTypeDisplay(
          item.condType,
          item.optVal
        ),
        regDateTimeDisplay: item.reqTime,
        updateDateDisplay: moment(item.updDateTime).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
      };
    })
  );
};

export const getOrderPriceDisp = (
  item: OrderBookItem | OrderBookDisplay | OrderBookDerItem
) => {
  const orderStatusVal = getOrderStatus(item);
  // const isDerAcc = listAcc.find((acc) => acc.subAccoNo === item.subAccoNo);

  if (item.ordType !== OrderTypeVal.LO) {
    // Lệnh MTL khớp 1 phần =>
    if (
      (item.ordType === OrderTypeVal.MTL || item.ordType === OrderTypeVal.MP) &&
      orderStatusVal === OrderStatusDetailType.PARTIALLY_FILLED
    ) {
      const orderPrice = item.ordPrice || 0;
      if (orderPrice > 0) {
        return numeral(orderPrice).value();
      }
    }

    return item.ordType;
  }
  return numeral(item.ordPrice! || 0).format("0,0.00");
};

export const getValueMatch = (item: OrderBookItem | OrderBookDisplay) => {
  if (item.matQty != null && item.matPriceAvg != null) {
    return (item.matQty! || 0) * (item.matPriceAvg! || 0);
  } else {
    return 0;
  }
};
