import { FC } from "react";
import Container from "components/commons/Container";
import OrderHistoryContent from "./components/OrderHistoryContent/index";
import Tabs from "./components/Tabs";
import { useIntl } from "react-intl";

const OrderHistory: FC = () => {
  const intl = useIntl();

  const arrayForm = [OrderHistoryContent];

  return (
    <Container
      headerHistory={Tabs}
      headerForm={intl.formatMessage({
        id: "orderHistory.tabs.orderHistoryTab",
      })}
      arrayForm={arrayForm}
      isShowIconClose={false}
    ></Container>
  );
};

export default OrderHistory;
