import { MrktAdvItem } from "domain/protoNew/auto_trading_pb";
import { columnsDataPutThrough } from "modules/categories/constant";
import { FormattedMessage } from "react-intl";
import GridRow from "./GridRowBidAsk";
import { BidAskHeader, TableWrappper } from "./style";

interface Props {
  isBid?: boolean;
  putThroughOfferList: MrktAdvItem.AsObject[];
}

function BidAskTable({ isBid, putThroughOfferList }: Props) {
  return (
    <TableWrappper>
      <div className="header-wrapper">
        <div className={"title " + (isBid ? "green" : "red")}>
          {isBid ? (
            <FormattedMessage id="category.table.bid" />
          ) : (
            <FormattedMessage id="category.table.ask" />
          )}
        </div>
        <div className="header-content">
          {Object.keys(columnsDataPutThrough.BID_ASK?.children!).map(
            (col, idx) => {
              const item = columnsDataPutThrough.BID_ASK.children![col];
              return (
                <BidAskHeader key={idx} minWidth={item.minWidth || 70}>
                  <FormattedMessage id={item.name} />
                </BidAskHeader>
              );
            }
          )}
        </div>
      </div>
      <div className="body-wrapper">
        <GridRow putThroughList={putThroughOfferList!} />
      </div>
    </TableWrappper>
  );
}

export default BidAskTable;
