import styled from "styled-components/macro";

export const NewsWrapper = styled.div`
  height: 100%;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  scrollbar-color: rgb(94, 102, 115) ${({ theme }) => theme.bgElevated1};
  scrollbar-width: thin;

  overflow: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(94, 102, 115);
    border-radius: 3px;
    height: 90px;
  }

  .new-item {
    padding: 16px;
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner4};
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &:last-child {
      box-shadow: none;
    }
  }

  .main-title {
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    margin-bottom: 8px;
    color: ${({ theme }) => theme.secondaryWhite};
    cursor: pointer;
  }

  .time {
    display: flex;
    align-items: center;

    .date {
      margin-left: 8px;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.greyG3};
    }

    .divide {
      height: 12px;
      width: 1px;
      background-color: ${({ theme }) => theme.greyG3};
      margin: 0 8px;
    }

    .hour {
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.greyG3};
    }

    .clock-icon {
      margin-right: 8px;
    }
  }

  .news-image-empty {
    grid-template-columns: 1fr;
  }

  .news-detail {
    position: relative;
    width: 100%;
    padding: 0 10px;
  }

  .news-details-header {
    margin-bottom: 10px;
    height: 32px;
    position: sticky;
    width: 100%;
    color: ${({ theme }) => theme.solidBlue};
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
    overflow: hidden;
    top: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    background: ${({ theme }) => theme.bgElevated1};
    padding: 5px 0;
  }

  .news-detail-content {
    width: 100%;
    color: ${({ theme }) => theme.greyG3};

    .pTitle {
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.typeFaces.caption12.size};
      font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.semiBold};
    }

    .img-content {
      width: 100% !important;
    }

    img {
      width: 100% !important;
      object-fit: contain;
    }
  }

  .new-detail-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
