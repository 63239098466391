import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetTransferAccountResponse,
  IUpdateTransferContractBankResponse,
  IDeleteTransferContractBankResponse,
  ListBankBranch,
  ListBankName,
  ListBankProvince,
  TransferAccountItem,
  ISetDefaultSubAccoNoResponse,
} from "domain/models/Cash";

import {
  GetListBankBranchParams,
  GetListBankNameParams,
  GetListBankProvinceParams,
  IDeleteTransferContractBankParams,
  ISetDefaultSubAccoNoParams,
  IUpdateTransferContractBankParams,
} from "core/http/apis/account/types";
import {
  IIsAlreadyRegisterAuthenFor134Params,
  IIsAlreadyRegisterAuthenFor134Response,
  IRegisterOTPAuthenParams,
  IRegisterOTPAuthenResponse,
  IUpdateOneTimeAuthenParams,
  IUpdateOneTimeAuthenResponse,
} from "core/http/apis/twofactor/types";

export type TradingAccountState = {
  listBeneficiary: GetTransferAccountResponse;
  beneficiary: TransferAccountItem;
  listBankName: ListBankName;
  listBankProvince: ListBankProvince;
  listBankBranch: ListBankBranch;
  visibleModalAddAccount: boolean;
  visibleModalTradingAccount: boolean;
  loading: boolean;
  isLoginByPassStatic: boolean;
  loadingRegister: boolean;
  statusRegisterOtpType: string;
  loadingOneTimeAuthen: boolean;
  statusOneTimeAuthen: string;
  isRegisterAction: boolean;
  visibleModalForceAdditionAccount: boolean;
  loadingAdd: boolean;
  errorRegister: any;
  errorOneTimeAuthen: any;
  isAlreadyRegisterAuthenFor134: number;
  statusAddAcc: string;
  errorAddAcc: any;
  statusDelAcc: string;
  errorDelAcc: any;
  statusSetDefault: string;
  errorSetDefault: any;
};

const initialState: TradingAccountState = {
  listBeneficiary: {
    data: [],
  },
  beneficiary: {},
  listBankName: {
    status: "",
    data: [],
  },
  listBankProvince: {
    status: "",
    data: [],
  },
  listBankBranch: {
    status: "",
    data: [],
  },
  visibleModalAddAccount: false,
  visibleModalTradingAccount: false,
  loading: false,
  isLoginByPassStatic: true,
  loadingRegister: false,
  statusRegisterOtpType: "",
  loadingOneTimeAuthen: false,
  statusOneTimeAuthen: "",
  isRegisterAction: false,
  visibleModalForceAdditionAccount: false,
  loadingAdd: false,
  errorRegister: {},
  errorOneTimeAuthen: {},
  isAlreadyRegisterAuthenFor134: 0,
  statusAddAcc: "",
  errorAddAcc: {},
  statusDelAcc: "",
  errorDelAcc: {},
  statusSetDefault: "",
  errorSetDefault: {},
};

const tradingAccountSlide = createSlice({
  name: "tradingAccount",
  initialState,
  reducers: {
    getListBeneficiary: (state) => {
      state.loading = false;
    },
    getListBeneficiarySuccess: (
      state,
      action: PayloadAction<GetTransferAccountResponse>
    ) => {
      state.listBeneficiary = action.payload;
    },
    getListBeneficiaryFailed: (state, action: PayloadAction<Error>) => {},

    getListBankName: (state, action: PayloadAction<GetListBankNameParams>) => {
      state.loading = false;
    },
    getListBankNameSuccess: (state, action: PayloadAction<ListBankName>) => {
      state.listBankName = action.payload;
    },
    geListBankNameFailed: (state, action: PayloadAction<Error>) => {},

    getListBankProvince: (
      state,
      action: PayloadAction<GetListBankProvinceParams>
    ) => {
      state.loading = false;
    },
    getListBankProvinceSuccess: (
      state,
      action: PayloadAction<ListBankProvince>
    ) => {
      state.listBankProvince = action.payload;
    },
    geListBankProvinceFailed: (state, action: PayloadAction<Error>) => {},

    getListBankBranch: (
      state,
      action: PayloadAction<GetListBankBranchParams>
    ) => {
      state.loading = false;
    },
    getListBankBranchSuccess: (
      state,
      action: PayloadAction<ListBankBranch>
    ) => {
      state.listBankBranch = action.payload;
    },
    geListBankBranchFailed: (state, action: PayloadAction<Error>) => {},

    requestChangeVisibleModalAddAccount: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.visibleModalAddAccount = action.payload;
    },
    updateTransferContractBank: (
      state,
      action: PayloadAction<{
        params: IUpdateTransferContractBankParams;
      }>
    ) => {
      state.loadingAdd = true;
    },
    updateTransferContractBankSuccess: (
      state,
      action: PayloadAction<IUpdateTransferContractBankResponse>
    ) => {
      state.statusAddAcc =
        action.payload.statusCode === 0 ? "SUCCESSFUL" : "FAILURE";
      state.loadingAdd = false;
      if (action.payload.statusCode !== 0) {
        state.errorAddAcc = {
          code: action.payload.errorCode,
          description: action.payload.message,
        };
      }
    },
    updateTransferContractBankFailed: (state, action: PayloadAction<Error>) => {
      state.statusAddAcc = "FAILURE";
      state.loadingAdd = false;
      state.errorAddAcc = action.payload;
    },
    deleteTransferContractBank: (
      state,
      action: PayloadAction<{
        params: IDeleteTransferContractBankParams;
      }>
    ) => {},
    deleteTransferContractBankSuccess: (
      state,
      action: PayloadAction<IDeleteTransferContractBankResponse>
    ) => {
      state.statusDelAcc =
        action.payload.statusCode === 0 ? "SUCCESSFUL" : "FAILURE";
      if (action.payload.statusCode !== 0) {
        state.errorDelAcc = {
          code: action.payload.errorCode,
          description: action.payload.message,
        };
      }
    },
    deleteTransferContractBankFailed: (state, action: PayloadAction<Error>) => {
      state.statusDelAcc = "FAILURE";
      state.errorDelAcc = action.payload;
    },
    clearStatusTransferContractBank: (state) => {
      state.statusAddAcc = "";
      state.statusDelAcc = "";
    },
    requestChangeVisibleModalTradingAccount: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.visibleModalTradingAccount =
        action.payload || !state.visibleModalTradingAccount;
    },
    changeIsRegisterAction: (state, action: PayloadAction<boolean>) => {
      state.isRegisterAction = action.payload;
    },
    changeIsLoginByPassStatic: (state, action: PayloadAction<boolean>) => {
      state.isLoginByPassStatic = action.payload;
    },
    changeRegisterOtpType: (
      state,
      action: PayloadAction<{
        params: IRegisterOTPAuthenParams;
      }>
    ) => {
      state.loadingRegister = true;
    },
    changeRegisterOtpTypeSuccess: (
      state,
      action: PayloadAction<IRegisterOTPAuthenResponse>
    ) => {
      state.statusRegisterOtpType =
        action.payload.statusCode === 0 ? "SUCCESSFUL" : "FAILURE";
      if (action.payload.statusCode !== 0) {
        state.errorRegister = {
          code: action.payload.errorCode,
          description: action.payload.message,
        };
      }
      state.loadingRegister = false;
    },
    changeRegisterOtpTypeFailed: (state, action: PayloadAction<Error>) => {
      state.statusRegisterOtpType = "FAILURE";
      state.errorRegister = action.payload;
      state.loadingRegister = false;
    },
    updateOneTimeAuthen: (
      state,
      action: PayloadAction<IUpdateOneTimeAuthenParams>
    ) => {
      state.loadingOneTimeAuthen = true;
    },
    updateOneTimeAuthenSuccess: (
      state,
      action: PayloadAction<IUpdateOneTimeAuthenResponse>
    ) => {
      state.statusOneTimeAuthen =
        action.payload.statusCode === 0 ? "SUCCESSFUL" : "FAILURE";
      if (action.payload.statusCode !== 0) {
        state.errorOneTimeAuthen = {
          code: action.payload.errorCode,
          description: action.payload.message,
        };
      }
      state.loadingOneTimeAuthen = false;
    },
    updateOneTimeAuthenFailed: (state, action: PayloadAction<any>) => {
      state.statusOneTimeAuthen = "FAILURE";
      state.loadingOneTimeAuthen = false;
      state.errorOneTimeAuthen = action.payload;
    },
    clearStatusRegisterOtpType: (state) => {
      state.statusRegisterOtpType = "";
      state.statusOneTimeAuthen = "";
    },
    changeVisibleModalForceAdditionAccount: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.visibleModalForceAdditionAccount = action.payload;
    },
    verifyBankAccountStart: (state) => {
      state.loadingAdd = true;
    },
    verifyBankAccountSuccess: (state) => {
      state.loadingAdd = false;
    },
    verifyBankAccountFailed: (state) => {
      state.loadingAdd = false;
    },

    isAlreadyRegisterAuthenFor134Request: (
      state,
      action: PayloadAction<IIsAlreadyRegisterAuthenFor134Params>
    ) => {},
    isAlreadyRegisterAuthenFor134Success: (
      state,
      action: PayloadAction<IIsAlreadyRegisterAuthenFor134Response>
    ) => {
      state.isAlreadyRegisterAuthenFor134 = action.payload.data || 0;
    },
    isAlreadyRegisterAuthenFor134Failed: (
      state,
      action: PayloadAction<any>
    ) => {},

    setDefaultSubAccoNo: (
      state,
      action: PayloadAction<ISetDefaultSubAccoNoParams>
    ) => {},
    setDefaultSubAccoNoSuccess: (
      state,
      action: PayloadAction<ISetDefaultSubAccoNoResponse>
    ) => {
      if (action.payload.statusCode === 0) {
        state.statusSetDefault = "SUCCESSFUL";
      } else {
        state.statusSetDefault = "FAILURE";
        state.errorSetDefault = {
          code: action.payload.errorCode,
          description: action.payload.message,
        };
      }
    },
    setDefaultSubAccoNoFailed: (state, action: PayloadAction<any>) => {
      state.statusSetDefault = "FAILURE";
      state.errorSetDefault = {
        code: action.payload.code,
        description: action.payload.description,
      };
    },
    clearStatusSetDefault: (state) => {
      state.statusSetDefault = "";
    },
  },
});

export const {
  getListBeneficiary,
  getListBeneficiarySuccess,
  getListBeneficiaryFailed,
  setDefaultSubAccoNo,
  setDefaultSubAccoNoSuccess,
  setDefaultSubAccoNoFailed,
  clearStatusSetDefault,
  getListBankName,
  getListBankNameSuccess,
  geListBankNameFailed,
  getListBankProvince,
  getListBankProvinceSuccess,
  geListBankProvinceFailed,
  getListBankBranch,
  getListBankBranchSuccess,
  geListBankBranchFailed,
  requestChangeVisibleModalAddAccount,
  changeIsRegisterAction,
  updateTransferContractBank,
  updateTransferContractBankSuccess,
  updateTransferContractBankFailed,
  deleteTransferContractBank,
  deleteTransferContractBankSuccess,
  deleteTransferContractBankFailed,
  clearStatusTransferContractBank,
  requestChangeVisibleModalTradingAccount,
  changeIsLoginByPassStatic,
  changeRegisterOtpType,
  changeRegisterOtpTypeSuccess,
  changeRegisterOtpTypeFailed,
  updateOneTimeAuthen,
  updateOneTimeAuthenFailed,
  updateOneTimeAuthenSuccess,
  clearStatusRegisterOtpType,
  changeVisibleModalForceAdditionAccount,
  verifyBankAccountStart,
  verifyBankAccountSuccess,
  verifyBankAccountFailed,
  isAlreadyRegisterAuthenFor134Request,
  isAlreadyRegisterAuthenFor134Success,
  isAlreadyRegisterAuthenFor134Failed,
} = tradingAccountSlide.actions;

export default tradingAccountSlide.reducer;
