import queryString from "query-string";
import {
  GetAvailableTradeParams,
  GetOrderLimitedQtyParams,
  GetPortfolioParams,
  GetTransferAccountParams,
  ISetDefaultSubAccoNoParams,
} from "./types";

export const assetsTradingUrl = (masterAccount: string, account: string) =>
  `/assets/${masterAccount}/${account}`;
// export const lastestCash = (masterAccount: string, account: string) =>
//   `/balance/cash/${masterAccount}/${account}`;
// export const lastestShare = (masterAccount: string, account: string) =>
//   `/balance/share/${masterAccount}/${account}`;

export const changeIdCardUrl = "/care/service/info";
export const realization = (masterAccount: string, account: string) =>
  `/portfolio/realization/${masterAccount}/${account}`;
export const statementMoney = "/cashstatement";
export const statementStock = "/stockstatement";

export const profit = (masterAccount: string, account: string) =>
  `/portfolio/profit/${masterAccount}/${account}`;
export const position = (masterAccount: string, account: string) =>
  `/portfolio/${masterAccount}/${account}`;
export const portfolio = (masterAccount: string, account: string) =>
  `/portfolio/${masterAccount}/${account}`;
export const listAccountBeneficiary = (masterAccount: string) =>
  `/cash/beneficiary/${masterAccount}`;

export const portfolioUrl = (masterAccount: string, account: string) =>
  `/portfolio/${masterAccount}/${account}`;
export const infoSms = (masterAccount: string, account: string) =>
  `/care/sms/info/${masterAccount}/${account}`;
export const infoEmail = (masterAccount: string, account: string) =>
  `/care/email/info/${masterAccount}/${account}`;
export const serviceSmsEmail = "/care/smsEmail/service";
export const confirmationOrder = (masterAccount: string, account: string) =>
  `/confirmation/orders/${masterAccount}/${account}`;
export const shareMargin = "/care/shareMargin";
export const careService = (masterAccount: string) =>
  `/care/service/info/${masterAccount}`;
// export const contractInfo = (masterAccount: string) =>
//   `/care/contractInfo/${masterAccount}`;
export const frontuser = (account: string) =>
  `/care/service/frontuser/${account}`;
export const registerService = "/care/service/register";
export const careServiceHistory = (
  masterAccount: string,
  fromDate: string,
  toDate: string,
  statusCode: string
) =>
  `/care/service/info/${masterAccount}?fromDate=${fromDate}&toDate=${toDate}&statusCode=${statusCode}`;
export const serviceRegister = "/care/service/register";
export const serviceSMUT = `/care/service/info`;
export const marginContract = `/care/marginContract`;
export const derivAccount = `/care/deriAccount`;
export const serviceInfo = "/care/service/info";
export const requestConfirmOrder = "/confirmation/orders";
export const oddShareList = (masterAccount: string, account: string) =>
  `/share/odd/${masterAccount}/${account}`;
export const oddShareHistory = (masterAccount: string, account: string) =>
  `/share/odd/history/${masterAccount}/${account}`;
export const sellOddShare = `/share/odd`;
export const requestOrdersDeals = "/orders/deals";
export const loanExtensionList = (masterAccount: string, account: string) =>
  `/care/loan/${masterAccount}/${account}`;
export const extendExpire = "/care/extendExpire";
export const extendHistory = (masterAccount: string, account: string) =>
  `/care/extendHistory/${masterAccount}/${account}`;
export const careFeedback = "/care/feedback";
export const accountRightsRegister = "/rights/register";
export const rightsRegister = (masterAccount: string, account: string) =>
  `/rights/register/${masterAccount}/${account}`;
export const rightsRegisterHistory = (masterAccount: string, account: string) =>
  `/rights/registerHistory/${masterAccount}/${account}`;
export const rightsOther = "/rights/other";
export const marginLimitHistory = (masterAccount: string, account: string) =>
  `/care/creditLimitHistory/${masterAccount}/${account}`;
export const changeMarginLimit = "/care/creditLimit";
export const accountCreditVol = (masterAccount: string, account: string) =>
  `/care/accountCreditVol/${masterAccount}/${account}`;
export const requireCreditLimit = (masterAccount: string) =>
  `/care/creditLimit/${masterAccount}`;
export const stockPortfolio = (masterAccount: string, account: string) =>
  `/portfolio/${masterAccount}/${account}`;
export const assetInfo = `/care/totalAssets`;
export const popupNGT = `/care/popupNGT`;
export const updatePopupLog = `/care/updatePopupLog`;
export const hotNews = "/care/hotNewsLasted";
export const careParams = "/care/params";

export const listTransferShareHistory = (
  masterAccount: string,
  account: string
) => `/share/transfer/history/${masterAccount}/${account}`;

export const listTransferShareForm = (masterAccount: string, account: string) =>
  `/share/transfer/${masterAccount}/${account}`;
export const postSubmitTransferShareForm = `/share/transfer`;
export const appendixApi = (masterAccount: string) =>
  `/care/appendix/${masterAccount}`;
export const appendixSingleApi = (masterAccount: string) =>
  `/care/appendix/single/${masterAccount}`;
export const liquidInfoApi = (masterAccount: string) =>
  `/care/appendix/liquid/${masterAccount}`;
export const liquidationApi = "/care/appendix";
export const feeRateApi = "/care/feeRate";
export const frontUserApi = "/care/frontUser";
export const checkInfoApi = (masterAccount: string) => `/info/${masterAccount}`;
export const contractFrameApi = "/care/contract";
export const bpLinkContractApi = "/care/bpLinkContract";
export const interestTermApi = "/care/interestTerm";

export const navAssetDeltaApi = "/care/nav/getAssetDelta";
export const navAssetReturnApi = "/care/nav/getAssetReturn";
export const shareTransferStatusHistory = (
  masterAccount: string,
  account: string
) => `/share/transferStatusHistory/${masterAccount}/${account}`;
export const shareTransferStatus = `/share/transferStatus/`;
export const careLibApi = `/care/libCode`;

export const navRateApi = "/care/nav";
export const careTermApi = "/care/term";
export const shareOddRegister = "/share/odd/register";
export const onboardApproval = "/onboard/approval";

// Lay thong tin lai lo thuc hien GMC
export const getPNLExecuted = `/getPNLExecuted`;

// Thay doi thong tin tai khoan GMC
export const changeInfoAccountUrl = "/editcfmast";

// API lay so du kha dung GMC
// export const getAvailableTradeUrl = "/getQuotesForOrder";

export const getAvailableTradeUrl = (params: GetAvailableTradeParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/inquiryBuyingPower${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// API lay KL mua/ ban toi da
export const getOrderLimitedQtyUrl = (params: GetOrderLimitedQtyParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getOrderLimitedQty${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// API lấy danh mục chứng khoán
// export const securitiesPortfolio = "/securitiesPortfolio";
export const securitiesPortfolio = (params: GetPortfolioParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findPortfolio${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// API đổi mật khẩu
export const changePassword = "/changePassword";

// API đổi cả 2 mật khẩu
export const changeBothPassword = "/changeBothPassword";

// Lay danh sach nhan vien cham soc
export const getBrokerListUrl = "/getbrokerlist";

// Dang ky nhan vien cham soc
export const registerBrokerUrl = "/regbroker";
// Lấy lịch sử giao dịch CK
export const stockTransferHistory = `/stockTransferHistory`;

// Chuyển khoản chứng khoán
export const stockTransfer = `/stockTransfer`;

// Danh sach tai khoan thu huong
// export const getTransferAccountUrl = "/getSubAccToList";
export const getTransferAccountUrl = (params: GetTransferAccountParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/getSubAccToList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Set tk mac dinh
export const setDefaultSubAccoNoUrl = (params: ISetDefaultSubAccoNoParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  return `/setDefaultSubAccoNo${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Them tai khoan thu huong
export const updateTransferContractBankUrl = "/updateTransferContractBank";

// Xoa tai khoan thu huong
export const deleteTransferContractBankUrl = "/deleteTransferContractBank";

// Lay danh sach ngan hang
export const listBankName = "/getrootbanklist";

// Lay danh sach tinh thanh
export const listBankProvince = "/api/mstt-province";

// Lay danh sach chi nhanh
export const listBankBranch = "/api/bank-branch";

// Đăng ký/Hủy đăng ký dịch vụ online
export const registerOnlineService = "/regservice";

// Quên mật khẩu
export const forgotPassword = "/forgotpassword";

// Lay danh sach xac nhan lenh online
export const confirmOrderOnlineUrl = "/getConfirmOrders";

// Xac nhan lenh online
export const postConfirmOrderOnline = "/listConfirmOrder";

export const accountEditcfmastvsd = `/editcfmastvsd`;
