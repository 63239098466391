import GlobalData from "helper/globalData";
import { FC } from "react";
import { StockNameWrapper } from "components/widgets/generals/TopForeign/Cells/styles";
interface Props {
  dataRow: string;
}

const CompanyNameCell: FC<Props> = ({ dataRow }) => {
  const stockName = GlobalData.getTickerInfoNew(dataRow!)?.secname || "-";

  return (
    <StockNameWrapper title={stockName}>{stockName || "-"}</StockNameWrapper>
  );
};

export { CompanyNameCell };
