import User, { Account, ListingAccount } from "domain/models/User";
import { store } from "redux/store";
import { logOutSuccess } from "modules/auth/redux";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_ACCESS_TOKEN_KEY,
  CHALLENGE_CODE_KEY,
  CHART_DATA_CACHE_KEY,
  TRADING_SESSION_CACHE_KEY,
  DEFAULT_ACCOUNT,
  MASTER_ACCOUNT_KEY,
  MASTER_ACCOUNT_INFO_KEY,
  ACCOUNT_LISTING_KEY,
  ACCOUNT_KEY,
  // AccountType,
  TIME_ACCESS_TOKEN_KEY,
  TIME_REFRESH_ACCESS_TOKEN_KEY,
  TICKER_LIST_ALL_KEY_NEW,
  VERSIONWEB,
  SESSION_STATE,
} from "./consts";
import {
  pushLogData,
  resLogData,
  setVisibleModalConfirmOpenLogin,
} from "modules/system/redux/common";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { remoteConfig } from "core/firebase";
import { loginUrl, resetUrl } from "core/http/apis/auth/urls";
import { changePassword } from "core/http/apis/password/urls";
import { GroupEvent, RealtimeConst } from "./constFirebase";

const Storage = {
  setCookie(name: string, value: string, seconds?: number) {
    var expires = "";
    if (seconds) {
      var date = new Date();
      date.setTime(date.getTime() + seconds * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null ?? "";
  },
  eraseCookie(name: string) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
  get(key: string, defaultValue: string = "") {
    const value = localStorage.getItem(key);

    return value ? value : defaultValue;
  },

  set(key: string, value: string = "") {
    localStorage.setItem(key, value);
  },

  remove(key: string) {
    localStorage.removeItem(key);
  },

  getAccessToken(): string {
    return this.getCookie(ACCESS_TOKEN_KEY);
  },

  getTimeAccessToken(): string {
    return this.getCookie(TIME_ACCESS_TOKEN_KEY);
  },

  setAccessToken(token: string, seconds?: number) {
    this.setCookie(ACCESS_TOKEN_KEY, token, seconds);
  },

  setTimeAccessToken(seconds: string) {
    this.setCookie(TIME_ACCESS_TOKEN_KEY, seconds);
  },

  setRefreshToken(token: string, seconds?: number) {
    this.setCookie(REFRESH_ACCESS_TOKEN_KEY, token, seconds);
  },

  setTimeRefreshToken(seconds: string) {
    this.setCookie(TIME_REFRESH_ACCESS_TOKEN_KEY, seconds);
  },

  getRefreshToken(): string {
    return this.getCookie(REFRESH_ACCESS_TOKEN_KEY);
  },

  getTimeRefreshToken(): string {
    return this.getCookie(TIME_REFRESH_ACCESS_TOKEN_KEY);
  },

  setChallengeCodeVerifier(code: string) {
    this.set(CHALLENGE_CODE_KEY, code);
  },

  getChallengeCodeVerifier(): string {
    return this.get(CHALLENGE_CODE_KEY);
  },

  setVersionWeb(code: string) {
    this.set(VERSIONWEB, code);
  },

  getVersionWeb(): string {
    return this.get(VERSIONWEB);
  },

  setMasterAccount(masterAccount: string) {
    this.set(MASTER_ACCOUNT_KEY, masterAccount);
  },

  getMasterAccount(): string {
    return this.get(MASTER_ACCOUNT_KEY);
  },

  setRememberAccount(account: string) {
    this.set(ACCOUNT_KEY, account);
  },

  getRememberAccount(): string {
    return this.get(ACCOUNT_KEY);
  },

  setMasterAccountInfo(masterAccountInfo: User) {
    this.set(MASTER_ACCOUNT_INFO_KEY, JSON.stringify(masterAccountInfo));
  },

  getMasterAccountInfo(): User {
    const data: any = this.get(MASTER_ACCOUNT_INFO_KEY);
    return JSON.parse(data);
  },

  setAccountList(accoutList: ListingAccount) {
    this.set(ACCOUNT_LISTING_KEY, JSON.stringify(accoutList));
  },

  getAccountList(): Account[] {
    const data: any = this.get(ACCOUNT_LISTING_KEY);
    return JSON.parse(data);
  },

  setTickerListAllNew(tickerList: MrktSecInfoItem.AsObject[]) {
    this.set(TICKER_LIST_ALL_KEY_NEW, JSON.stringify(tickerList));
  },

  getTickerListAllNew(): MrktSecInfoItem.AsObject[] {
    const data: any = this.get(TICKER_LIST_ALL_KEY_NEW);
    return JSON.parse(data || "[]");
  },

  setSessionState(masterAccount: string) {
    this.set(SESSION_STATE, masterAccount);
  },

  getSessionState(): string {
    return this.get(SESSION_STATE);
  },

  removeToken() {
    this.eraseCookie(ACCESS_TOKEN_KEY);
    this.eraseCookie(REFRESH_ACCESS_TOKEN_KEY);
    this.remove(CHALLENGE_CODE_KEY);
    this.remove(MASTER_ACCOUNT_KEY);
    this.remove(MASTER_ACCOUNT_INFO_KEY);
    this.remove(ACCOUNT_LISTING_KEY);
    this.remove(CHART_DATA_CACHE_KEY);
    this.remove(VERSIONWEB);
    this.remove(SESSION_STATE);
  },

  getDataChart() {
    const data: any = this.get(CHART_DATA_CACHE_KEY);
    if (!data) return {};
    const dataParse: {
      [key: string]: {
        time: number;
        list: number[];
        timeListArray: number[];
        ticker: string;
        referencePrice: number;
      };
    } = JSON.parse(data);
    return typeof dataParse === "object" ? dataParse : {};
  },
  getTradingSession() {
    const data: any = this.get(TRADING_SESSION_CACHE_KEY);
    if (!data) return {};
    const dataParse: {
      [key: string]: { id: string; session: string };
    } = JSON.parse(data);
    return typeof dataParse === "object" ? dataParse : {};
  },

  setDataChart(dataChart: string) {
    this.set(CHART_DATA_CACHE_KEY, dataChart);
  },
  setTradingSession(tradingSession: string) {
    this.set(TRADING_SESSION_CACHE_KEY, tradingSession);
  },

  getDefaultAccount() {
    const data: any = this.get(DEFAULT_ACCOUNT);
    if (!data) return {};
    const dataParse: {
      [key: string]: {
        subAccoCd: number;
        subAccoNo: string;
      };
    } = JSON.parse(data);
    return typeof dataParse === "object" ? dataParse : {};
  },

  setDefaultAccount(defaultAccount: string) {
    this.set(DEFAULT_ACCOUNT, defaultAccount);
  },

  clearAll() {
    localStorage.clear();
  },
  logOut() {
    if (this.getAccessToken()) {
      store.dispatch(setVisibleModalConfirmOpenLogin(true));
    }
    this.removeToken();
    store.dispatch(logOutSuccess());
  },

  apiFirebase(data: any) {
    if (!data) return;
    const realtimeApiSuccessConfig = remoteConfig.getValue(
      "realtime_api_success"
    );
    const realtimeApiSlowConfig = remoteConfig.getValue("realtime_api_slow");
    const realtimeApiSlowTimeConfig = remoteConfig.getValue(
      "realtime_api_slow_time"
    );
    if (
      realtimeApiSuccessConfig.asBoolean() === false &&
      realtimeApiSlowConfig.asBoolean() === false &&
      data.status === 200
    )
      return;

    if (
      realtimeApiSuccessConfig.asBoolean() === false &&
      realtimeApiSlowConfig.asBoolean() === true &&
      data.status === 200 &&
      data.request.totalTime < realtimeApiSlowTimeConfig.asNumber()
    )
      return;

    let newData: any = {
      eventGroup: GroupEvent.api,
      time: new Date().getTime(),
      user: this.getMasterAccount() || "",
      status: data.status === 200 ? 0 : 1,
      message:
        data.status === 200 ? "" : data.data.message || data.data.description,
      totalTime: data.request.totalTime,
      url: data.request.responseURL,
      requestId: data.config.headers["x-client-request-id"],
    };
    if (
      ![loginUrl, changePassword, resetUrl].some((str) =>
        data.request.responseURL.includes(str)
      )
    ) {
      newData.data = {
        input: data.config.data || data.config.params,
      };
    }
    store.dispatch(pushLogData(newData));
  },
  marketFirebase(data: resLogData) {
    const realtimeMarketConfig = remoteConfig.getValue("realtime_market");
    if (realtimeMarketConfig.asBoolean() === false) return;

    let newData: any = {
      eventGroup: GroupEvent.market,
      time: new Date().getTime(),
      user: this.getMasterAccount() || "",
      ...data,
    };
    store.dispatch(pushLogData(newData));
  },
  orderFirebase(data: resLogData) {
    const realtimeOrderConfig = remoteConfig.getValue("realtime_order");
    if (realtimeOrderConfig.asBoolean() === false) return;

    let newData: any = {
      eventGroup: GroupEvent.order,
      time: new Date().getTime(),
      user: this.getMasterAccount() || "",
      ...data,
    };
    store.dispatch(pushLogData(newData));
  },
  eventFirebase(data: resLogData) {
    const realtimeEventConfig = remoteConfig.getValue("realtime_event");
    if (realtimeEventConfig.asBoolean() === false) return;
    const realtimeEventExitScreenConfig = remoteConfig.getValue(
      "realtime_event_exit_screen"
    );
    if (
      realtimeEventExitScreenConfig.asBoolean() === false &&
      data.event === RealtimeConst.exitScreen
    )
      return;

    let newData: any = {
      eventGroup: GroupEvent.appState,
      time: new Date().getTime(),
      user: this.getMasterAccount() || "",
      ...data,
    };
    store.dispatch(pushLogData(newData));
  },
  appStateFirebase(data: resLogData) {
    const realtimeAppStateConfig = remoteConfig.getValue("realtime_app_state");
    if (realtimeAppStateConfig.asBoolean() === false) return;

    let newData: any = {
      eventGroup: GroupEvent.appState,
      time: new Date().getTime(),
      user: this.getMasterAccount() || "",
      ...data,
    };
    store.dispatch(pushLogData(newData));
  },
  commonPushLogFirebase(data: resLogData) {
    let newData: any = {
      time: new Date().getTime(),
      user: this.getMasterAccount() || "",
      ...data,
    };
    store.dispatch(pushLogData(newData));
  },
};

export default Storage;
