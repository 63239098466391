import { ReactComponent as IconCancel } from "assets/image/svg/ic_cancel.svg";
import { Button, CheckBox } from "components/commons";
import storage from "helper/storage";
import {
  getAccount,
  getCbot,
  loadingSelector,
} from "modules/register-to-transfer-bonds/selectors";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import ModalConfirm from "../ModalConfirm";
import {
  Body,
  BorderBox,
  BorderRight,
  ColorBlue,
  ColorGreen,
  ColorRed,
  Container,
  DetailRowCheckBox,
  Footer,
  Icon,
  IconNone,
  TextCheckBox,
  Title,
  TitleContainer,
  WrapItem,
} from "./styles";

interface Props {
  visibleModalContainer?: boolean;
  title?: string;
  content?: string;
  handleCloseModalContainer?: () => void;
  size?: any;
  contractSts?: any;
}

function ModalNotification({
  visibleModalContainer,
  title,
  handleCloseModalContainer,
  size,
  contractSts,
}: Props) {
  const intl = useIntl();

  var custNo = storage.getMasterAccount();
  const account = useSelector(getAccount);
  const loading = useSelector(loadingSelector);
  const cbot = useSelector(getCbot);

  const [checked, setChecked] = useState(false);
  const [visibleConfirm, setVisibelModalConfirm] = useState(false);

  const closeModalConfirm = () => {
    if (handleCloseModalContainer) {
      handleCloseModalContainer();
    }
  };

  const handleCloseModalConfirm = () => {
    setVisibelModalConfirm(false);
  };

  const handleSubmit = () => {
    setVisibelModalConfirm(true);
  };

  return (
    <>
      <Modal
        show={visibleModalContainer}
        centered
        backdrop="static"
        size={size}
        keyboard={false}
        dialogClassName="modal-434"
      >
        <Container>
          <TitleContainer>
            <IconNone></IconNone>
            <Title>{title}</Title>
            <Icon onClick={closeModalConfirm}>
              <IconCancel width={16} height={16} />
            </Icon>
          </TitleContainer>
          <BorderBox>
            <Body>
              <WrapItem>
                <FormattedMessage id="registerTransferBonds.modal.accountNo"></FormattedMessage>

                <BorderRight>{custNo}</BorderRight>
              </WrapItem>
              <WrapItem>
                <FormattedMessage id="registerTransferBonds.modal.fullName"></FormattedMessage>

                <BorderRight>
                  {account?.custFamilyName
                    ?.concat(" ")
                    .concat(account?.custGivenName)}
                </BorderRight>
              </WrapItem>
              <WrapItem>
                <FormattedMessage id="registerTransferBonds.modal.status"></FormattedMessage>

                <BorderRight>
                  {contractSts === 1 ? (
                    <ColorGreen>
                      {intl.formatMessage({
                        id: "registerTransferBonds.modal.registered",
                      })}
                    </ColorGreen>
                  ) : (
                    <ColorRed>
                      {intl.formatMessage({
                        id: "registerTransferBonds.modal.unRegistered",
                      })}
                    </ColorRed>
                  )}
                </BorderRight>
              </WrapItem>
              <DetailRowCheckBox>
                <CheckBox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                ></CheckBox>
                <TextCheckBox>
                  {intl.formatMessage({
                    id: "registerTransferBonds.modal.textCheckBoxBefore",
                  })}
                  <ColorBlue href="https://online.psi.vn/download?file=Dieu_khoan_GDTP_Online.pdf">
                    {intl.formatMessage({
                      id: "registerTransferBonds.modal.textCheckBoxMiddle",
                    })}
                  </ColorBlue>
                  {intl.formatMessage({
                    id: "registerTransferBonds.modal.textCheckBoxAfter",
                  })}
                </TextCheckBox>
              </DetailRowCheckBox>
            </Body>
            <Footer>
              <Button
                color="accept"
                className="flex-1"
                onClick={closeModalConfirm}
              >
                <FormattedMessage id="registerTransferBonds.modal.cancel" />
              </Button>
              <Button
                color="accept"
                className="flex-2"
                onClick={handleSubmit}
                disabled={checked === true ? false : true}
                fill={true}
                loading={loading}
              >
                {contractSts === 1 ? (
                  <FormattedMessage id="registerTransferBonds.modal.cancelRegistration" />
                ) : (
                  <FormattedMessage id="registerTransferBonds.modal.register" />
                )}
              </Button>
            </Footer>
          </BorderBox>
        </Container>
      </Modal>
      {visibleConfirm && (
        <ModalConfirm
          visibleModalConfirm={visibleConfirm}
          title={
            cbot?.contractSts === 1
              ? intl.formatMessage({
                  id: "registerTransferBonds.modalConfirm.titleConfirmDelte",
                })
              : intl.formatMessage({
                  id: "registerTransferBonds.modalConfirm.titleConfirmRegister",
                })
          }
          handleCloseModalConfirm={handleCloseModalConfirm}
          contractSts={contractSts}
          setVisibelModalConfirm={setVisibelModalConfirm}
          setChecked={setChecked}
        />
      )}
    </>
  );
}

export default ModalNotification;
