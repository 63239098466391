import styled from "styled-components/macro";

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
`;

export const ButtonExit = styled.button`
  display: flex;
  background: transparent;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 14px;
  height: 14px;
`;

export const HeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
  text-align: center;
  flex: 1;
`;

export const ModalHeader = styled.div`
  height: 40px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  padding: 0px 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const CardTable = styled.div`
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background2};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  #qrcode {
    border-radius: 8px;
    margin: 16px;
  }
  .inputOTP {
    /* min-height: 170px; */
    display: flex;
    align-items: center;
  }
  .inputOTPHeader {
    display: flex;
    justify-content: space-between;
  }
  .blue {
    color: ${({ theme }) => theme.blue50};
    text-decoration: underline;
    cursor: pointer;
  }
  .title {
    color: ${({ theme }) => theme.textcolorWhite};
    .sendCode {
      width: 40%;
      text-align: end;
      font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
      color: ${({ theme }) => theme.grey50};
    }
  }
  .inputSmsOTPContent {
    display: flex;
    align-items: center;
    margin-left: 16px;
    flex: 1;
  }
  .inputOTPContent {
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    background: ${({ theme }) => theme.background2};
    justify-content: center;
    .otpContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .otpInput {
        width: 44px;
        height: 44px;
        margin: 6px;
        text-align: center;
        background: ${({ theme }) => theme.textcolorBlack};
        color: ${({ theme }) => theme.textcolorWhite};
        border: 1px solid ${({ theme }) => theme.bgElevated4};
        border-radius: 8px;
      }
    }
  }
`;
export const ErrorVerify = styled.div`
  color: red;
  text-align: center;
`;

export const AlertText = styled.div`
  margin: 16px;
  text-align: center;
`;

export const BodyQRcode = styled.div<{ disabled?: boolean }>`
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  ${({ disabled }) => disabled && `opacity: 0.2;`}
`;

export const QRcodeExpired = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -33px;
  color: ${({ theme }) => theme.textcolorWhite};
`;

export const LoadingBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: 16px;
  .loader {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgElevated3};
  height: 40px;
  align-items: center;
  z-index: 4;

  .header-left {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .dropdown-menu {
      max-width: 400px;
      width: 90vw;
      background-color: ${({ theme }) => theme.grey70};
    }
  }

  .header-right {
    padding: 4px 8px;
    min-width: 300px;
    display: flex;
    align-items: center;

    .input {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
`;

export const InputOTPContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const InputCell = styled.div`
  flex: 1;
  margin-right: 12px;
`;

export const GetCodeCell = styled.div`
  min-width: 140px;
  text-align: right;
`;

export const LabelInput = styled.div`
  margin-bottom: 4px;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;

export const InputWrapper = styled.div``;

export const SaveOTPWrapper = styled.div<{
  allowSaveOtp?: boolean;
}>`
  margin-top: 16px;
  margin-bottom: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ allowSaveOtp }) =>
    !allowSaveOtp &&
    `
      visibility: hidden;
      user-select: none;
  `}
`;

export const TextSave = styled.div`
  margin-right: 20px;
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
`;

export const SwitchSave = styled.div``;

export const TextWrapper = styled.div`
  user-select: none;
  & > span.allowClick {
    color: ${({ theme }) => theme.brandPrimary};
    cursor: pointer;
  }
`;

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 8px;
`;
