import { useSelector, useDispatch } from "react-redux";

import OrderFlowDistribution from "components/widgets/details/OrderFlowDistribution";
import { fetchOrderFlowDistribution } from "modules/tickerDetails/redux/orderFlowDistribution";
import {
  orderFlowDistributionChartSelector,
  selectedTickerSelector,
} from "modules/market/redux/selectors";

export default function MarketOrderFlowDistribution() {
  const dispatch = useDispatch();

  const selectedTicker = useSelector(selectedTickerSelector);
  const { chartInflow, chartOutflow, pieChartFlow, totalInflow, totalOutflow } =
    useSelector(orderFlowDistributionChartSelector);

  function handleDataFetch(tickerCode: string) {
    dispatch(fetchOrderFlowDistribution(tickerCode));
  }

  return (
    <OrderFlowDistribution
      isHideHeader={true}
      selectedTicker={selectedTicker}
      chartInflow={chartInflow}
      chartOutflow={chartOutflow}
      pieChartFlow={pieChartFlow}
      totalInflow={totalInflow}
      totalOutflow={totalOutflow}
      onFetch={handleDataFetch}
    />
  );
}

export const ConvertMarketOrderFlowDistribution: React.FC = () => {
  return <MarketOrderFlowDistribution />;
};
