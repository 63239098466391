import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const statisticPriceSelector = (state: RootState) =>
  state.statisticPrice;

export const statisticPriceListSelector = createSelector(
  statisticPriceSelector,
  (state) => state.list
);

export const statisticPriceLoadingSelector = createSelector(
  statisticPriceSelector,
  (state) => state.loading
);

export const statisticPriceFilterSelector = createSelector(
  statisticPriceSelector,
  (state) => state.filter
);

export const activeTabSelector = createSelector(
  statisticPriceSelector,
  (state) => state.activeTab
);
