import { Select } from "components/commons";
import { EntrustBusinessCode, GetAccountType, SrcChannel } from "helper/consts";
import {
  changeCondition,
  changeCurrentTicker,
  changeOrderStatus,
  changeOrderStatusCond,
  changeCondType,
  changeSelectedRows,
  changeTradeType,
  clearFilter,
  findDertOrderByFilter,
  findDertRequestCond,
} from "modules/stock-order-der/redux/OrderBookDer";
import React from "react";
import { useMemo } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Label, Control } from "../../OrderBook/style";
import { SearchContainer } from "./styles";
import InputSymbol from "components/commons/InputSymbol";
import {
  conditionOrderBookDerSelector,
  filterOrderBookDerSelector,
} from "modules/stock-order-der/selectors";
import {
  IFindDertOrderByFilterParams,
  IFindDertRequestCondParams,
} from "core/http/apis/tradeinfo/types";
import { tradeDateSelector } from "modules/auth/selectors";
import { GetAccoList } from "helper/sessionData";
import { toast } from "components/commons/Toast";
import { ConditionalCommandTypeEnum } from "../../PlaceOrderDer/ConditionalCommandType";

const getTradeTypes = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "orderBook.all",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "common.long",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "common.short",
    }),
  },
];

const orderStatuses = (intl: IntlShape) => [
  {
    value: "-1",
    label: intl.formatMessage({
      id: "orderBook.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderBook.REJECTED",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderBook.ENTERED",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderBook.WAIT_FILLED",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "orderBook.PARTIALLY_FILLED",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "orderBook.FILLED",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "orderBook.PARTIALLY_CANCELLED",
    }),
  },
  {
    value: "7",
    label: intl.formatMessage({
      id: "orderBook.CANCELLED",
    }),
  },
  {
    value: "8",
    label: intl.formatMessage({
      id: "orderBook.REJECTED_DEPARTMENT",
    }),
  },
];

const conditionList = (intl: IntlShape) => {
  return [
    {
      value: "01",
      label: intl.formatMessage({
        id: "orderBook.normal",
      }),
    },
    {
      value: "02",
      label: intl.formatMessage({
        id: "orderBook.conditionType.title",
      }),
    },
  ];
};

const getCondTypeList = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({ id: "widgets.placeOrderDer.all" }),
  },
  {
    value: ConditionalCommandTypeEnum.UP,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.UP" }),
  },
  {
    value: ConditionalCommandTypeEnum.DOWN,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.DOWN" }),
  },
  {
    value: ConditionalCommandTypeEnum.IFDONESTOP,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.IFDONESTOP" }),
  },
  {
    value: ConditionalCommandTypeEnum.IFDONEGAIN,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.IFDONEGAIN" }),
  },
  {
    value: ConditionalCommandTypeEnum.BULLBEAR,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.BULLBEAR" }),
  },
  {
    value: ConditionalCommandTypeEnum.OCO,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.OCO" }),
  },
  {
    value: ConditionalCommandTypeEnum.TRAILINGSTOP,
    label: intl.formatMessage({ id: "widgets.placeOrderDer.TRAILINGSTOP" }),
  },
];

const getOrderStatusDerCondList = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderBookDer.orderStatusDerCond.waitActive",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderBookDer.orderStatusDerCond.finished",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderBookDer.orderStatusDerCond.expire",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "orderBookDer.orderStatusDerCond.cancelled",
    }),
  },
];

interface Props {
  isShow: boolean;
}
const Filter: React.FC<Props> = ({ isShow }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const TradeTypes = useMemo(() => getTradeTypes(intl), [intl]);
  const OrderStatuses = useMemo(() => orderStatuses(intl), [intl]);
  const listCondition = useMemo(() => conditionList(intl), [intl]);
  const listCondType = useMemo(() => getCondTypeList(intl), [intl]);
  const listOrderStatusCond = useMemo(
    () => getOrderStatusDerCondList(intl),
    [intl]
  );
  const listAcc = GetAccoList(
    GetAccountType.DER,
    EntrustBusinessCode.Derivative
  );
  const {
    currentTicker,
    tradeType,
    orderStatus,
    condType,
    orderStatusCond,
    limit,
    offset,
  } = useSelector(filterOrderBookDerSelector);

  const condition = useSelector(conditionOrderBookDerSelector);
  const tradeDate = useSelector(tradeDateSelector);

  const onFilter = (
    loadmore: boolean,
    tradeTypeChange?: string,
    orderStatusChange?: string,
    symbol?: string,
    orderStatusCondChange?: string,
    condTypeChange?: string
  ) => {
    if (condition.value === "01") {
      let currentOffset = offset ? offset : 0;
      if (!loadmore) {
        currentOffset = 0;
      }

      const params: IFindDertOrderByFilterParams = {
        size: limit,
        page: currentOffset,
        branchCd: "",
        transactionCd: "",
        tradeType:
          tradeTypeChange !== undefined
            ? Number(tradeTypeChange)
            : Number(tradeType.value),
        orderType: "",
        secCd:
          symbol !== undefined
            ? symbol
            : currentTicker === ""
            ? ""
            : currentTicker,
        brokerCd: "",
        custNo: "",
        status:
          orderStatusChange !== undefined
            ? orderStatusChange
            : orderStatus.value,
        regUserId: "",
        ordChanel: SrcChannel.ALL,
        fromDate: Number(tradeDate),
        toDate: Number(tradeDate),
      };

      if (listAcc && listAcc.length > 0) {
        dispatch(findDertOrderByFilter({ params: params, intl: intl }));
      } else {
        toast("orderBookDer.noAccoDer", "error");
      }
    } else {
      let currentOffset = offset ? offset : 0;
      if (!loadmore) {
        currentOffset = 0;
      }
      const params: IFindDertRequestCondParams = {
        custNo: "",
        tradeType:
          tradeTypeChange !== undefined
            ? Number(tradeTypeChange)
            : Number(tradeType.value ? tradeType.value : "-1"),
        secCd:
          symbol !== undefined
            ? symbol
            : currentTicker === ""
            ? ""
            : currentTicker,
        condType:
          condTypeChange !== undefined ? condTypeChange : condType.value,
        regUserId: "",
        status:
          orderStatusCondChange !== undefined
            ? Number(orderStatusCondChange)
            : Number(orderStatusCond.value ? orderStatusCond.value : "-1"),
        ordChanel: "",
        fromDate: Number(tradeDate),
        toDate: Number(tradeDate),
        offset: currentOffset,
        limit: limit,
      };

      if (listAcc && listAcc.length > 0) {
        dispatch(findDertRequestCond({ params: params, intl: intl }));
      } else {
        toast("orderBookDer.noAccoDer", "error");
      }
    }
  };

  const handleChangeSymbol = (value: string) => {
    dispatch(changeCurrentTicker(value));
    onFilter(false, undefined, undefined, value);
  };

  return (
    <SearchContainer isShow={isShow}>
      <Control>
        <Label>
          <FormattedMessage id="orderBook.condition" />
        </Label>
        <div className="flex-fill-select">
          <Select
            options={listCondition}
            value={condition}
            onChange={(item) => {
              dispatch(changeCondition(item));
              dispatch(changeSelectedRows([]));
              dispatch(clearFilter());
            }}
          />
        </div>
      </Control>
      <Control>
        <Label>
          <FormattedMessage id="orderBookDer.secCd" />
        </Label>
        <div className="flex-fill-select">
          <InputSymbol
            value={currentTicker}
            onChange={handleChangeSymbol}
            isOnlyShareCode
            isDerivatives
          />
        </div>
      </Control>
      <Control className="tradeType">
        <Label>
          <FormattedMessage id="orderBook.table.type" />
        </Label>
        <div className="flex-fill-select">
          <Select
            value={tradeType}
            options={TradeTypes}
            onChange={(item) => {
              dispatch(changeTradeType(item));
              onFilter(false, item?.value);
            }}
          />
        </div>
      </Control>
      <Control className="status">
        <Label>
          <FormattedMessage id="orderBook.table.status" />
        </Label>
        <div className="flex-fill-select">
          {condition.value === "01" ? (
            <Select
              options={OrderStatuses}
              value={orderStatus}
              onChange={(item) => {
                dispatch(changeOrderStatus(item));
                onFilter(false, undefined, item?.value);
              }}
            />
          ) : (
            <Select
              options={listOrderStatusCond}
              value={orderStatusCond}
              onChange={(item) => {
                dispatch(changeOrderStatusCond(item));
                onFilter(false, undefined, undefined, undefined, item?.value);
              }}
            />
          )}
        </div>
      </Control>

      {condition.value === "02" && (
        <Control className="condType">
          <Label>
            <FormattedMessage id="orderBookDer.condType.title" />
          </Label>
          <div className="flex-fill-select">
            <Select
              options={listCondType}
              value={condType}
              onChange={(item) => {
                dispatch(changeCondType(item));
                onFilter(
                  false,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  item?.value
                );
              }}
            />
          </div>
        </Control>
      )}
    </SearchContainer>
  );
};
export default Filter;
