import { IntlShape } from "react-intl";

export const dataButton = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "auth.register.step2.cmnd",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "auth.register.step2.passport",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "auth.register.step2.business",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "auth.register.step2.other",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "auth.register.step2.traddingCodeOrganization",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "auth.register.step2.traddingCodeIndividual",
    }),
  },
  {
    value: "7",
    label: intl.formatMessage({
      id: "auth.register.step2.governmentAgencies",
    }),
  },
  {
    value: "8",
    label: intl.formatMessage({
      id: "auth.register.step2.identity",
    }),
  },
];
