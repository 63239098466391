import { AskBidContainer } from "./styles";
import { Spacer } from "components/commons";
import Buy from "./Buy";
import Sell from "./Sell";

function AskBid() {
  return (
    <AskBidContainer>
      <Buy />
      <Spacer size="xs" />
      <Sell />
    </AskBidContainer>
  );
}

export default AskBid;
