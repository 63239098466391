import styled from "styled-components/macro";

export const Container = styled.div`
  height: 85%;
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: 95%;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;

export const TableCellContent = styled.div<{
  align?: string;
  color?: string;
  minWidth?: string;
}>`
  color: ${({ theme, color = "white" }) => {
    switch (color) {
      case "white":
        return theme.grey0;
      case "red":
        return theme.solidRed;
      case "blue":
        return theme.blue;
      case "green":
        return theme.green50;
      case "violet":
        return theme.violet;
      case "orange":
        return theme.solidOrange;
      case "yellow":
        return theme.solidYellow;
    }
  }} !important;
  padding: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "center")};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.regular};
  ${({ minWidth }) => minWidth && `width: ${minWidth}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderCell = styled.div<{
  align?: string;
  minWidth?: string;
}>`
  color: ${({ theme }) => theme.grey0};
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  ${({ minWidth }) => minWidth && `width: ${minWidth}`};
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.caption10.regular};
`;

export const IconWrapper = styled.span`
  cursor: pointer;
`;

export const ButtonLiquidate = styled.button<{
  bgcolor?: string;
  color?: string;
}>`
  height: ${({ theme }) => theme.input.height};
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
`;

export const TextContent = styled.p`
  padding: 0px;
  color: ${({ theme }) => theme.grey0};
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
`;
