import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  .button-filter1 {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
  }
  .marginRight {
    margin-right: 0.8rem !important;
  }
  .marginRight2 {
    margin-right: 1.7rem !important;
  }
  .text-grey {
    color: ${({ theme }) => theme.grey20};
    .text-white .input {
      color: ${({ theme }) => theme.grey0};
    }
    .text-white .input-container,
    .text-white .css-1jpjy99-control,
    .text-white .react-datepicker-wrapper {
      background: ${({ theme }) => theme.grey100} !important;
    }
  }
`;

export const ContentOne = styled.div`
display: flex;
width: 100%;
height: 100%;
flex-direction: column;
flex: 1;
border-radius: 8px;

overflow: hidden;

  background: ${({ theme }) => theme.background1};
.input-bg-dark {
  background: ${({ theme }) => theme.background1};

`;

export const LabelCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${({ theme }) => theme.grey0};
  font-weight: bold;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  padding-left: 12px;
`;
