import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelCell = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ theme }) => theme.grey40};
`;

export const InputCell = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Space = styled.div`
  margin: 0 8px;
`;

export const Suffix = styled.div`
  margin-right: 8px;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.height};
`;

export const RangeRow = styled.div<{
  visibleRange?: boolean;
}>`
  width: 100%;
  margin: 8px 0;
  padding: 0 24px;
  box-sizing: border-box;

  ${({ visibleRange }) =>
    !visibleRange &&
    `
      height: 0px;
      visibility: hidden;
      margin: 0;
    `}

  .rc-slider-handle {
    width: auto;
    height: 24px;
    white-space: nowrap;
    padding: 0 8px;
    margin-top: 0;
    top: 50%;
    border-width: 0;
    transform: translate(-50%, -50%) !important;
    text-align: center;
    border-radius: 24px;
    background-color: ${({ theme }) => theme.solidBlue};
    color: ${({ theme }) => theme.textcolorWhite};
    font-size: ${({ theme }) => theme.typeFaces.caption12.size};
    display: flex;
    justify-content: center;
    align-items: center;

    &.rc-slider-handle-dragging {
      border-width: 0 !important;
      box-shadow: none;
    }
    &.rc-slider-handle-click-focused {
      border-width: 0 !important;
      box-shadow: none;
    }
  }

  .rc-slider-rail,
  .rc-slider-track {
    height: 2px;
    background-color: ${({ theme }) => theme.bgElevated4};
  }
`;
