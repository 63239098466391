/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select } from "components/commons";
import { OptionSelectType } from "components/commons/Select";
import {
  EntrustBusinessCode,
  EOTPTypeSubmit,
  GetAccountType,
  SrcChannel,
} from "helper/consts";
import { GetAccoList, GetParameters } from "helper/sessionData";
import {
  updateItemEdit,
  getAmtCanReceiveRequest,
  getFeeAdvanceRequest,
  updateModalStatusRequest,
  updateModalOtpRequest,
  createAdvanceContractRequest,
  searchAdvanceRequest,
  updateFilter,
  updateBankBrandCd,
  getBankBranchCd2Request,
  updateCurrentPage,
} from "modules/advance/redux";
import {
  getBankBrancdCd2Selector,
  statusCreateContractSelector,
} from "modules/advance/selectors";
import numeral from "numeral";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "./ConfirmModal";
import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailRow,
  DetailWrapper,
  DetailRowTextTop,
  DetailRowPaddingTB,
  DetailLabelAlert,
  RequiredSymbol,
  DetailRowPaddingT,
  DetailColFlexEnd,
} from "./styles";
import {
  advanceEditSelector,
  isShowOtpModalSelector,
  getBankBrancdCdSelector,
} from "modules/advance/selectors";
import {
  IGetAmtCanReceiveParams,
  IGetFeeAdvanceParams,
  IFindAdvanceParams,
  ICustomerRestrictionParams,
  IParameterBankDetailForPayAdvanceParams,
  IGetBankBranchCdParams,
  IGetPayAdvanceLimitRemainParams,
  IGetBankBranchCd2Params,
} from "core/http/apis/advance/types";
import {
  ICustomerRestrictionResponse,
  IGetFindParameterBankDetailForPayAdvanceResponse,
  IGetBankBranchCdResponse,
  IGetPayAdvanceLimitRemainResponse,
} from "domain/models/Advance";
import { EBusinessCd } from "helper/consts";

import { OTPByBusinessCd } from "components/commons";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import moment from "moment";
import { toast } from "components/commons/Toast";
import { Row, Col } from "react-bootstrap";
// import themes from "themes/abstracts/_themes";
// import { themeTypeSelector } from "themes/redux";
import {
  getCustomerRestriction,
  findParameterBankDetailForPayAdvance,
  getBankBranchCd,
  getPayAdvanceLimitRemain,
} from "core/http/apis/advance";
import TextArea from "components/commons/Input/textArea";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

const purposeAdvanceOption = (intl: IntlShape) => [
  {
    value: "1",
    label: intl.formatMessage({
      id: "advance.filter.purpose.buy",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "advance.filter.purpose.withdraw",
    }),
  },
];

function RequestDetail() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showCombo, setShowCombo] = useState("false");
  const statusCreate = useSelector(statusCreateContractSelector);
  const isVisible = useSelector(isShowOtpModalSelector);
  const branchCd = useSelector(getBankBrancdCdSelector);
  const branchCd2 = useSelector(getBankBrancdCd2Selector);
  // const themeType = useSelector(themeTypeSelector);
  const handleCloseOTPModal = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };
  useEffect(() => {
    if (statusCreate) {
      const submitData2: IFindAdvanceParams = {
        currentDate: false,
        bankBranchCd: null,
        status: -1,
        srcChanel: SrcChannel.ALL,
        goalType: 0,

        subAccoNo: itemEdit?.subAccoNo ? itemEdit?.subAccoNo : "",

        fromDate: 20210427,
        toDate: Number(moment().format("YYYYMMDD")),
        page: 0,
        rowPerPage: 30,
      };
      dispatch(searchAdvanceRequest(submitData2));
      dispatch(
        updateFilter({
          key: "fromDate",
          value: new Date(2021, 3, 27, 0, 0, 0, 0),
        })
      );
      dispatch(
        updateFilter({
          key: "toDate",
          value: new Date(),
        })
      );
      dispatch(
        updateFilter({
          key: "subAccoNo",
          value: itemEdit?.subAccoNo,
        })
      );

      dispatch(updateItemEdit({ advanceFee: 0, realRecvAmt: "", remarks: "" }));
      toast("holdUnholdBankAccount.message.success", "success");
    }
  }, [statusCreate]);
  const doSubmitAdvance = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));

    if (itemEdit && Object.keys(itemEdit).length > 0) {
      const submitData: IGetFeeAdvanceParams = {
        subAccoNo: itemEdit?.subAccoNo ? itemEdit?.subAccoNo : "",
        serviceType:
          itemEdit &&
          itemEdit?.subAccoNo &&
          itemEdit?.subAccoNo[itemEdit?.subAccoNo.length - 2] === "1"
            ? 2
            : 1,
        bankBranchCd: branchCd2 ? branchCd2 : "",
        realRecvAmt: itemEdit?.realRecvAmt ? +itemEdit?.realRecvAmt : 0,
        totalCanRecvAmt: itemEdit?.totalAmtCanReceive
          ? itemEdit?.totalAmtCanReceive
          : 0,
        contractType: 2,
        sourceChannel: 2,
        goalType:
          itemEdit &&
          itemEdit.subAccoNo &&
          itemEdit?.subAccoNo[itemEdit?.subAccoNo.length - 2] !== "2"
            ? 0
            : itemEdit?.purposeAdvance === undefined
            ? 1
            : itemEdit && itemEdit.purposeAdvance
            ? itemEdit.purposeAdvance
            : 1,
        mode: 2,
        otpCode: response?.otp ?? "",
        otpType: response?.otpTypeSubmit!,
      };
      dispatch(createAdvanceContractRequest(submitData));
      dispatch(updateCurrentPage(0));

      const resLogData = {
        eventGroup: GroupEvent.advanceMoney,
        event: RealtimeConst.submitAdvance,
        message: JSON.stringify(submitData),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_AND_BOND_SUB_ACCOUNT,
    EntrustBusinessCode.PayAdvance
  );
  const showComboPurposeAdvance = GetParameters(
    "PD00",
    "PD_ALLOW_CHOOSE_GOAL_TYPE"
  );

  // const getBankAdvance = GetParameters("PD00", "PD_DEFAULT_BANK_BRANCH_CD");

  const itemEdit = useSelector(advanceEditSelector);

  const accountOptions = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);
  const purposeAdvanceOptions = useMemo(
    () => purposeAdvanceOption(intl),
    [intl]
  );
  const changeAdvanceAccountHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemEdit({
        subAccoNo: item?.value,
        advanceFee: 0,
        realRecvAmt: "",
        remarks: "",
      })
    );
  };

  const changeAdvancePurposeHandler = (item: OptionSelectType | null) => {
    dispatch(
      updateItemEdit({ purposeAdvance: item && item.value ? +item.value : 0 })
    );
  };
  const changeRealRecvAmtHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;

    dispatch(
      updateItemEdit({ realRecvAmt: convertToNumber ? +convertToNumber : "" })
    );
  };
  const changeRemarksHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateItemEdit({ remarks: e?.target?.value ?? "" }));
  };
  const clickResetHandler = () => {
    dispatch(
      updateItemEdit({
        advanceFee: 0,
        realRecvAmt: "",
        remarks: "",
        subAccoNo: itemEdit?.subAccoNo ? itemEdit?.subAccoNo : "",
        purposeAdvance: 1,
      })
    );
  };
  const clickAcceptHandler = async () => {
    let subAccoNo = itemEdit && itemEdit.subAccoNo ? itemEdit.subAccoNo : "";
    let realRecvAmt =
      itemEdit && itemEdit.realRecvAmt ? +itemEdit.realRecvAmt : 0;
    let totalAmtCanReceive =
      itemEdit && itemEdit.totalAmtCanReceive ? itemEdit.totalAmtCanReceive : 0;
    let advanceFee = itemEdit && itemEdit.advanceFee ? itemEdit.advanceFee : 0;
    if (realRecvAmt === null || realRecvAmt === undefined) {
      return toast("advance.message.amountCheck1", "error");
    } else if (realRecvAmt === 0) {
      return toast("advance.message.amountCheck2", "error");
    } else if (
      realRecvAmt &&
      realRecvAmt !== null &&
      realRecvAmt !== undefined &&
      totalAmtCanReceive !== null &&
      totalAmtCanReceive !== undefined
    ) {
      if (realRecvAmt > totalAmtCanReceive) {
        return toast("advance.message.amountCheck3", "error");
      }
    }
    const params: ICustomerRestrictionParams = {
      subAccoNo: subAccoNo ? subAccoNo : "",
      restrictionCd: 3,
      showLoading: true,
    };
    // dispatch(getCustomerRestrictionRequest(params));
    try {
      const res: ICustomerRestrictionResponse = await getCustomerRestriction(
        params
      );
      if (res?.statusCode === 0) {
      } else {
        return toast(res?.errorCode, "error", res?.message, true);
      }
    } catch (error: any) {
      return toast(error?.code, "error", error?.description, true);
    }

    const params2: IParameterBankDetailForPayAdvanceParams = {
      subAccoNo: subAccoNo ? subAccoNo : "",
      paramGroup: "PD00",
      bankBranchCd: branchCd2 ? branchCd2 : "",
    };
    try {
      const res2: IGetFindParameterBankDetailForPayAdvanceResponse =
        await findParameterBankDetailForPayAdvance(params2);
      if (res2?.statusCode === 0) {
        if (realRecvAmt === null || realRecvAmt === undefined) {
          return toast("advance.message.amountCheck1", "error");
        } else if (
          realRecvAmt &&
          realRecvAmt !== null &&
          realRecvAmt !== undefined
        ) {
          if (realRecvAmt < res2.data.padContractMinValue) {
            return toast(
              intl.formatMessage(
                { id: "advance.message.checkErr1" },
                {
                  number: res2.data.padContractMinValue,
                }
              ),
              "error"
            );
          }
          if (realRecvAmt > res2.data.padContractMaxValue) {
            return toast(
              intl.formatMessage(
                { id: "advance.message.checkErr2" },
                {
                  number: res2.data.padContractMaxValue,
                }
              ),
              "error"
            );
          }
        }
        dispatch(updateBankBrandCd(""));
      } else {
        dispatch(updateBankBrandCd(""));
        return toast(res2?.errorCode, "error", res2?.message, true);
      }
    } catch (error: any) {
      dispatch(updateBankBrandCd(""));
      return toast(error?.code, "error", error?.description, true);
    }

    // dispatch(findParameterBankDetailForPayAdvanceRequest(params2));

    // dispatch(getBankBranchCdRequest(params4));

    const params4: IGetBankBranchCdParams = {
      subAccoNo: subAccoNo ? subAccoNo : "",
      advanceAmount: advanceFee ? advanceFee : 0,
    };

    try {
      const res4: IGetBankBranchCdResponse = await getBankBranchCd(params4);
      if (res4?.statusCode === 0) {
        if (res4.data && res4.data.key) {
          dispatch(updateBankBrandCd(res4.data.key));
        } else {
          dispatch(updateBankBrandCd(""));
        }
      } else {
        return toast(res4?.errorCode, "error", res4?.message, true);
      }
    } catch (error: any) {
      return toast(error?.code, "error", error?.description, true);
    }
  };

  useEffect(() => {
    if (branchCd && branchCd !== "" && branchCd.length > 0) {
      // dispatch(getPayAdvanceLimitRemainRequest(params3));

      const fetchData = async () => {
        try {
          let subAccoNo =
            itemEdit && itemEdit.subAccoNo ? itemEdit.subAccoNo : "";
          let realRecvAmt =
            itemEdit && itemEdit.realRecvAmt ? +itemEdit.realRecvAmt : 0;
          const params3: IGetPayAdvanceLimitRemainParams = {
            subAccoCd: 0,
            subAccoNo: subAccoNo ? subAccoNo : "",
            contractType: 2,
            goalType: 2,
            bankBranchCd: branchCd ? branchCd : "",
          };
          const res3: IGetPayAdvanceLimitRemainResponse =
            await getPayAdvanceLimitRemain(params3);
          if (res3?.statusCode === 0) {
            if (realRecvAmt === null || realRecvAmt === undefined) {
              return toast("advance.message.amountCheck1", "error");
            } else if (
              realRecvAmt &&
              realRecvAmt !== null &&
              realRecvAmt !== undefined
            ) {
              if (
                realRecvAmt > res3.data.comLimitRemain &&
                realRecvAmt > res3.data.custLimitRemain
              ) {
                toast("advance.message.amountCheck4", "error");
                return toast(
                  intl.formatMessage(
                    { id: "advance.message.checkErr3" },
                    {
                      number: res3.data.custLimitRemain,
                    }
                  ),
                  "error"
                );
              }
              if (realRecvAmt > res3.data.comLimitRemain) {
                return toast("advance.message.amountCheck4", "error");
              }
              if (realRecvAmt > res3.data.custLimitRemain) {
                return toast(
                  intl.formatMessage(
                    { id: "advance.message.checkErr3" },
                    {
                      number: res3.data.custLimitRemain,
                    }
                  ),
                  "error"
                );
              }
            }
          } else {
            return toast(res3?.errorCode, "error", res3?.message, true);
          }
        } catch (error: any) {
          return toast(error?.code, "error", error?.description, true);
        }
        dispatch(
          updateModalStatusRequest({
            isVisible: true,
          })
        );
      };
      fetchData();
    }
  }, [branchCd]);

  const getFeeAdvanceHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let convertToNumber =
      e?.target?.value
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replace(/[^0-9.]/g, "") ?? 0;
    let fee = convertToNumber ? +convertToNumber : 0;
    if (fee > 0) {
      const submitData: IGetFeeAdvanceParams = {
        subAccoNo: itemEdit?.subAccoNo
          ? itemEdit?.subAccoNo
          : accountOptions[0].value,
        serviceType: 1,
        bankBranchCd: branchCd2 ?? "",
        realRecvAmt: fee ?? 0,
        totalCanRecvAmt: itemEdit?.totalAmtCanReceive ?? 0,
        contractType: 2,
        sourceChannel: 2,
        goalType:
          showCombo === "false"
            ? 2
            : itemEdit?.purposeAdvance === undefined
            ? 1
            : itemEdit && itemEdit.purposeAdvance
            ? itemEdit.purposeAdvance
            : 1,
        mode: 1,
        otpCode: null,
        otpType: EOTPTypeSubmit.OTP!,
      };

      dispatch(getFeeAdvanceRequest(submitData));
    } else {
      dispatch(updateItemEdit({ advanceFee: 0 }));
    }
    const submitData: IGetBankBranchCd2Params = {
      subAccoNo: itemEdit?.subAccoNo
        ? itemEdit?.subAccoNo
        : accountOptions[0].value,
      advanceAmount: fee ?? 0,
    };
    dispatch(getBankBranchCd2Request(submitData));
  };
  const handleKeyDownGetFeeAdvanceHandler = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.stopPropagation();
      let convertToNumber =
        e?.target?.value
          .replaceAll(",", "")
          .replaceAll(".", "")
          .replace(/[^0-9.]/g, "") ?? 0;
      let fee = +convertToNumber ?? 0;
      if (fee > 0) {
        const submitData: IGetFeeAdvanceParams = {
          subAccoNo: itemEdit?.subAccoNo
            ? itemEdit?.subAccoNo
            : accountOptions[0].value,
          serviceType: 1,
          bankBranchCd: branchCd2 ?? "",
          realRecvAmt: convertToNumber ? +convertToNumber : 0,
          totalCanRecvAmt: itemEdit?.totalAmtCanReceive ?? 0,
          contractType: 2,
          sourceChannel: 2,
          goalType:
            showCombo === "false"
              ? 2
              : itemEdit?.purposeAdvance === undefined
              ? 1
              : itemEdit && itemEdit.purposeAdvance
              ? itemEdit.purposeAdvance
              : 1,
          mode: 1,
          otpCode: null,
          otpType: EOTPTypeSubmit.OTP!,
        };

        dispatch(getFeeAdvanceRequest(submitData));
      } else {
        dispatch(updateItemEdit({ advanceFee: 0 }));
      }
      const submitData: IGetBankBranchCd2Params = {
        subAccoNo: itemEdit?.subAccoNo
          ? itemEdit?.subAccoNo
          : accountOptions[0].value,
        advanceAmount: fee ?? 0,
      };
      dispatch(getBankBranchCd2Request(submitData));
    }
  };

  useEffect(() => {
    const submitData: IGetBankBranchCd2Params = {
      subAccoNo: accountOptions[0]?.value ?? "",
      advanceAmount: 0,
    };
    dispatch(getBankBranchCd2Request(submitData));
  }, []);
  useEffect(() => {
    if (branchCd2 && branchCd2.length > 0) {
      dispatch(updateItemEdit({ subAccoNo: accountOptions[0]?.value }));

      setShowCombo(showComboPurposeAdvance);

      if (accountOptions[0].value[accountOptions[0].value.length - 2] === "2") {
        setShowCombo("true");
        const submitData: IGetAmtCanReceiveParams = {
          subAccoNo: accountOptions[0].value ?? "",
          serviceType: 1,
          bankBranchCd: branchCd2 ?? "",
          goalType:
            itemEdit && itemEdit.purposeAdvance ? itemEdit.purposeAdvance : 1,
        };
        dispatch(getAmtCanReceiveRequest(submitData));
      } else {
        setShowCombo("false");
        const submitData: IGetAmtCanReceiveParams = {
          subAccoNo: accountOptions[0].value ?? "",
          serviceType: 1,
          bankBranchCd: branchCd2 ?? "",
          goalType: 2,
        };
        dispatch(getAmtCanReceiveRequest(submitData));
      }
    }
  }, [branchCd2]);
  useEffect(() => {
    if (itemEdit && itemEdit.subAccoNo) {
      const submitData: IGetBankBranchCd2Params = {
        subAccoNo: itemEdit.subAccoNo ?? "",
        advanceAmount: itemEdit?.realRecvAmt ? +itemEdit?.realRecvAmt : 0,
      };
      dispatch(getBankBranchCd2Request(submitData));
    }
  }, [itemEdit?.subAccoNo]);

  useEffect(() => {
    if (itemEdit && itemEdit.subAccoNo) {
      if (itemEdit?.subAccoNo[itemEdit?.subAccoNo.length - 2] === "2") {
        setShowCombo("true");

        const submitData: IGetAmtCanReceiveParams = {
          subAccoNo: itemEdit?.subAccoNo ?? "",
          serviceType: 1,
          bankBranchCd: branchCd2 ?? "",
          goalType:
            itemEdit && itemEdit.purposeAdvance ? itemEdit.purposeAdvance : 1,
        };

        dispatch(getAmtCanReceiveRequest(submitData));
      } else {
        setShowCombo("false");
        const submitData: IGetAmtCanReceiveParams = {
          subAccoNo: itemEdit?.subAccoNo ?? "",
          serviceType: 1,
          bankBranchCd: branchCd2 ?? "",
          goalType: 2,
        };

        dispatch(getAmtCanReceiveRequest(submitData));
      }
    }
  }, [itemEdit?.subAccoNo, itemEdit?.purposeAdvance]);

  return (
    <DetailWrapper>
      <DetailCol>
        <DetailRowPaddingT>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="advance.subAccount" />
                <RequiredSymbol className="text-danger pl-1 mb-0">
                  *
                </RequiredSymbol>
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper className="text-left">
                <div className="text-white">
                  <Select
                    defaultValue={accountOptions[0]}
                    options={accountOptions}
                    value={accountOptions.find(
                      (item) => item.value === itemEdit?.subAccoNo
                    )}
                    onChange={changeAdvanceAccountHandler}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingT>

        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="advance.advanceableMoney" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {" "}
                  {itemEdit
                    ? numeral(itemEdit.totalAmtCanReceive).format("0,0")
                    : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRow>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="advance.withdrawable" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <Input
                    value={
                      itemEdit?.realRecvAmt &&
                      numeral(itemEdit?.realRecvAmt ?? 0).format("0,0")
                    }
                    onChange={changeRealRecvAmtHandler}
                    onBlur={getFeeAdvanceHandler}
                    onKeyUp={handleKeyDownGetFeeAdvanceHandler}
                    placeholder={intl.formatMessage({
                      id: "moneyTransfer.enterAmount",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRow>

        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="advance.provisionalFee" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>
                  {itemEdit ? numeral(itemEdit.advanceFee).format("0,0") : 0}
                </b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        {showCombo === "true" && (
          <DetailRowPaddingTB>
            <Row
              className=" d-flex align-content-center w-100 w-100"
              style={{ flex: "1" }}
            >
              <Col xs={12} md={5} className="w-100  p-0 px-3  my-auto">
                <DetailLabel>
                  <FormattedMessage id="advance.advancePurpose" />
                </DetailLabel>
              </Col>
              <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
                <DetailInputWrapper className="text-left">
                  <div className="text-white">
                    <Select
                      defaultValue={purposeAdvanceOptions[0]}
                      options={purposeAdvanceOptions}
                      value={purposeAdvanceOptions.find(
                        (item) => +item.value === itemEdit?.purposeAdvance
                      )}
                      onChange={changeAdvancePurposeHandler}
                    />
                  </div>
                </DetailInputWrapper>
              </Col>
            </Row>
          </DetailRowPaddingTB>
        )}
        <DetailRowTextTop>
          <Row
            className=" d-flex align-content-center w-100 w-100 mt-2"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3">
              <DetailLabel>
                <FormattedMessage id="advance.note" />
                <RequiredSymbol className="text-danger pl-1 mb-0">
                  *
                </RequiredSymbol>
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <TextArea
                    textArea={true}
                    value={itemEdit?.remarks}
                    onChange={changeRemarksHandler}
                    placeholder={intl.formatMessage({
                      id: "holdUnholdBankAccount.message.enterNote",
                    })}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowTextTop>

        <DetailRowPaddingT>
          <DetailLabelAlert>
            <FormattedMessage id="moneyTransfer.checkDetail" />
          </DetailLabelAlert>
        </DetailRowPaddingT>
      </DetailCol>
      <DetailColFlexEnd>
        <Row className=" d-flex align-content-center w-100">
          <Col sm={12} lg={6} className="w-100 p-0 px-1">
            <Button
              color="accept"
              className="button-filter w-100"
              onClick={clickResetHandler}
              // style={{
              //   fontSize: `${themes[themeType].typeFaces.headline.size}`,
              // }}
            >
              {intl.formatMessage({
                id: "advance.button.cancel",
              })}
            </Button>
          </Col>
          <Col sm={12} lg={6} className="w-100 p-0 px-1">
            <Button
              className="button-filter w-100"
              onClick={clickAcceptHandler}
              fill={true}
              color="gradientaAccept"

              // style={{
              //   background: `${themes[themeType].buttonGradientColor}`,
              //   fontSize: `${themes[themeType].typeFaces.headline.size}`,
              // }}
            >
              {intl.formatMessage({
                id: "advance.button.confirm",
              })}
            </Button>
          </Col>
        </Row>
      </DetailColFlexEnd>

      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal}
          getResult={doSubmitAdvance}
          businessCd={EBusinessCd.ADVANCE}
        />
      )}

      <ConfirmModal itemEdit={itemEdit} />
    </DetailWrapper>
  );
}

export default RequestDetail;
