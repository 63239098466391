import styled from "styled-components";

export const Container = styled.div<{
  isVisibleSideCard: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;

  border: 1px solid ${({ theme }) => theme.border1};
  /* Shadow/Center/8px */

  filter: drop-shadow(0px 0px 12px rgba(13, 17, 21, 0.4));
  border-radius: 8px;
  overflow: hidden;
`;

export const WrapTab = styled.div`
  .header-left {
    padding: 8px 0px 8px 12px;
    display: flex;
    align-items: center;
    color: white;
    background-color: #2d2d2d;
  }
`;
