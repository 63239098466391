import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFindOrderByFilterParams,
  IFindDertOrderByFilterParams,
  IFindRequestCondParams,
  IFindOrderDetailParams,
  IFindOrderDetailDerParams,
  IFindOrderByRequestCondParams,
  IFindDertRequestCondParams,
  IFindDertOrderCondParams,
  IFindDerOrderDetailParams,
} from "core/http/apis/order-history/types";
import {
  OrderHistory,
  OrderDetailModal,
  OrderDetailNormalModal,
  OrderDetailDerModal,
  IFindOrderByFilterResponse,
  IFindDertOrderByFilterResponse,
  IFindRequestCondResponse,
  IFindDertRequestCondResponse,
  IFindOrderDetailResponse,
  IFindOrderDetailDerResponse,
  IFindOrderByRequestCondResponse,
  OrderHistoryDer,
  OrderHistoryDerCond,
  IFindDertOrderCondResponse,
  IFindDerOrderDetailResponse,
  OrderDetailNormalDerModal,
  requestCondModel,
} from "domain/models/OrderHistory";
import { logOutSuccess } from "modules/auth/redux";
import { ORDER_HISTORY_TABS } from "./constants";
import { cloneDeep } from "lodash";
interface IOrderHistoryFilter {
  orderType: string;
  subAccoNo: string;
  fromDate: Date;
  toDate: Date;
  secCd: string;
  tradeType: number;
  status: number | null;
  condType: string;
  statusCond: number | null;
}
interface IOrderHistoryDerFilter {
  orderType: string;
  fromDate: Date;
  toDate: Date;
  secCd: string;
  subAccoNo: string;
  condType: string;
  status: number | null;
  statusCond: number | null;
  tradeType: number;
}

export interface ReduxData {
  activeTab: string;
  limit: number;
  orderHistory: {
    currentPageOder: number;
    enableInfinityOder: boolean;
    loading: boolean;
    filter: IOrderHistoryFilter;
    list: OrderHistory[];
    listCond: requestCondModel[];
    listDetailModal: OrderDetailModal[];
    listDetailNormalModal: OrderDetailNormalModal[];
    isShowConfirmModal: boolean;
    itemDetail?: OrderHistory;
    itemDetailCond?: requestCondModel;
  };
  orderHistoryDer: {
    currentPage: number;
    enableInfinity: boolean;
    loading: boolean;
    filter: IOrderHistoryDerFilter;
    list: OrderHistoryDer[];
    listCond: OrderHistoryDerCond[];
    listDetailModal: OrderDetailDerModal[];
    listDetailNormalModal: OrderDetailNormalDerModal[];
    isShowConfirmModalDer: boolean;
    itemDetail?: OrderHistoryDer;
    itemDetailCond?: OrderHistoryDerCond;
  };
}

const initialState: ReduxData = {
  activeTab: ORDER_HISTORY_TABS.ORDER_HISTORY,
  limit: 20,
  orderHistory: {
    currentPageOder: 0,
    enableInfinityOder: true,
    loading: false,
    filter: {
      subAccoNo: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      secCd: "",
      orderType: "",
      tradeType: 0,
      status: 0,
      statusCond: 0,
      condType: "",
    },
    list: [],
    listCond: [],
    listDetailModal: [],
    listDetailNormalModal: [],
    isShowConfirmModal: false,
  },

  orderHistoryDer: {
    currentPage: 0,
    enableInfinity: true,
    loading: false,
    filter: {
      subAccoNo: "",
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      secCd: "",
      orderType: "",
      tradeType: 0,
      status: 1,
      statusCond: 0,
      condType: "",
    },
    list: [],
    listCond: [],
    listDetailModal: [],
    listDetailNormalModal: [],
    isShowConfirmModalDer: false,
  },
};

const orderHistorySlice = createSlice({
  name: "orderHistorySlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    findOrderByFilterRequest: (
      state,
      action: PayloadAction<IFindOrderByFilterParams>
    ) => {
      state.orderHistory.loading = true;
    },
    findOrderByFilterSuccess: (
      state,
      action: PayloadAction<IFindOrderByFilterResponse>
    ) => {
      state.orderHistory.loading = false;
      state.orderHistory.list = action.payload.data ?? [];
    },
    findOrderByFilterFailure: (state) => {
      state.orderHistory.loading = false;
    },
    findRequestCondRequest: (
      state,
      action: PayloadAction<IFindRequestCondParams>
    ) => {
      state.orderHistory.loading = true;
    },
    findRequestCondSuccess: (
      state,
      action: PayloadAction<IFindRequestCondResponse>
    ) => {
      state.orderHistory.loading = false;
      state.orderHistory.listCond = action.payload.data ?? [];
      state.orderHistory.currentPageOder = 0;
      state.orderHistory.enableInfinityOder = true;
    },
    findRequestCondFailure: (state) => {
      state.orderHistory.loading = false;
    },
    findOrderDetailRequest: (
      state,
      action: PayloadAction<IFindOrderDetailParams>
    ) => {},
    findOrderDetailSuccess: (
      state,
      action: PayloadAction<IFindOrderDetailResponse>
    ) => {
      state.orderHistory.listDetailNormalModal =
        action.payload.data.filter((i) => i.reqType === 0) ?? [];
    },
    findOrderDetailFailure: (state) => {},

    findOrderByRequestCondRequest: (
      state,
      action: PayloadAction<IFindOrderByRequestCondParams>
    ) => {},
    findOrderByRequestCondSuccess: (
      state,
      action: PayloadAction<IFindOrderByRequestCondResponse>
    ) => {
      state.orderHistory.listDetailModal = action.payload.data ?? [];
    },
    findOrderByRequestCondFailure: (state) => {},

    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: OrderHistory;
        itemDetailCond?: requestCondModel;
      }>
    ) => {},
    updateModalStatusSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: OrderHistory;
        itemDetailCond?: requestCondModel;
      }>
    ) => {
      state.orderHistory.itemDetailCond =
        action.payload.itemDetailCond && action.payload.itemDetailCond;
      state.orderHistory.itemDetail =
        action.payload.itemDetail && action.payload.itemDetail;
      state.orderHistory.isShowConfirmModal = action.payload.isVisible;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IOrderHistoryFilter; value: any }>
    ) => {
      state.orderHistory.filter = {
        ...state.orderHistory.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    updateFilterDer: (
      state,
      action: PayloadAction<{ key: keyof IOrderHistoryDerFilter; value: any }>
    ) => {
      state.orderHistoryDer.filter = {
        ...state.orderHistoryDer.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    findDertOrderByFilterRequest: (
      state,
      action: PayloadAction<IFindDertOrderByFilterParams>
    ) => {
      state.orderHistoryDer.loading = true;
    },
    findDertOrderByFilterSuccess: (
      state,
      action: PayloadAction<IFindDertOrderByFilterResponse>
    ) => {
      state.orderHistoryDer.loading = false;
      state.orderHistoryDer.list = action.payload.data ?? [];
      state.orderHistoryDer.currentPage = 0;
      state.orderHistoryDer.enableInfinity = true;
    },
    findDertOrderByFilterFailure: (state) => {
      state.orderHistoryDer.loading = false;
    },
    findDertRequestCondRequest: (
      state,
      action: PayloadAction<IFindDertRequestCondParams>
    ) => {
      state.orderHistoryDer.loading = true;
    },
    findDertRequestCondSuccess: (
      state,
      action: PayloadAction<IFindDertRequestCondResponse>
    ) => {
      state.orderHistoryDer.loading = false;
      state.orderHistoryDer.listCond = action.payload.data ?? [];
    },
    findDertRequestCondFailure: (state) => {
      state.orderHistoryDer.loading = false;
    },
    updateModalDerStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: OrderHistoryDer;
        itemDetailCond?: OrderHistoryDerCond;
      }>
    ) => {},
    updateModalDerStatusSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: OrderHistoryDer;
        itemDetailCond?: OrderHistoryDerCond;
      }>
    ) => {
      state.orderHistoryDer.itemDetailCond =
        action.payload.itemDetailCond && action.payload.itemDetailCond;
      state.orderHistoryDer.itemDetail =
        action.payload.itemDetail && action.payload.itemDetail;
      state.orderHistoryDer.isShowConfirmModalDer = action.payload.isVisible;
    },
    findOrderDetailDerRequest: (
      state,
      action: PayloadAction<IFindOrderDetailDerParams>
    ) => {},
    findOrderDetailDerSuccess: (
      state,
      action: PayloadAction<IFindOrderDetailDerResponse>
    ) => {
      // console.log(action.payload);
    },
    findOrderDetailDerFailure: (state) => {},

    findDerOrderDetailRequest: (
      state,
      action: PayloadAction<IFindDerOrderDetailParams>
    ) => {},
    findDerOrderDetailSuccess: (
      state,
      action: PayloadAction<IFindDerOrderDetailResponse>
    ) => {
      // console.log(action.payload);
      state.orderHistoryDer.listDetailNormalModal =
        action.payload.data.filter((i) => i.reqType === 0) ?? [];
    },
    findDerOrderDetailFailure: (state) => {},
    findDertOrderCondRequest: (
      state,
      action: PayloadAction<IFindDertOrderCondParams>
    ) => {},
    findDertOrderCondSuccess: (
      state,
      action: PayloadAction<IFindDertOrderCondResponse>
    ) => {
      state.orderHistoryDer.listDetailModal = action.payload.data ?? [];
    },
    findDertOrderCondFailure: (state) => {},
    updateActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },

    updatePageOder: (state, action: PayloadAction<number>) => {
      state.orderHistory.currentPageOder = action.payload;
    },

    updateEnableInfinityOder: (state, action: PayloadAction<boolean>) => {
      state.orderHistory.enableInfinityOder = action.payload;
    },

    fetchMoreCondRequest: (
      state,
      action: PayloadAction<IFindRequestCondParams>
    ) => {
      // state.orderHistory.loading = true;
    },
    fetchMoreCondSuccess: (
      state,
      action: PayloadAction<IFindRequestCondResponse>
    ) => {
      const data = state.orderHistory.listCond.concat(action.payload.data);
      // state.orderHistory.loading = false;
      state.orderHistory.listCond = data ?? [];
    },

    updatePageDer: (state, action: PayloadAction<number>) => {
      state.orderHistoryDer.currentPage = action.payload;
    },

    updateEnableInfinityDer: (state, action: PayloadAction<boolean>) => {
      state.orderHistoryDer.enableInfinity = action.payload;
    },

    fetchMoreOrderByFilterRequest: (
      state,
      action: PayloadAction<IFindDertOrderByFilterParams>
    ) => {},
    fetchMoreOrderByFilterSuccess: (
      state,
      action: PayloadAction<IFindDertOrderByFilterResponse>
    ) => {
      const data = state.orderHistoryDer.list.concat(action.payload.data);
      state.orderHistoryDer.list = data ?? [];
    },
  },
});

export const {
  updateActiveTab,
  reset,

  findOrderByFilterFailure,
  findOrderByFilterRequest,
  findOrderByFilterSuccess,
  findRequestCondRequest,
  findRequestCondSuccess,
  findRequestCondFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  updateFilter,
  findOrderDetailRequest,
  findOrderDetailSuccess,
  findOrderDetailFailure,
  findOrderByRequestCondRequest,
  findOrderByRequestCondSuccess,
  findOrderByRequestCondFailure,

  findDertOrderByFilterRequest,
  findDertOrderByFilterSuccess,
  findDertOrderByFilterFailure,
  findDertRequestCondRequest,
  findDertRequestCondSuccess,
  findDertRequestCondFailure,
  updateModalDerStatusRequest,
  updateModalDerStatusSuccess,
  updateFilterDer,
  findOrderDetailDerRequest,
  findOrderDetailDerSuccess,
  findOrderDetailDerFailure,
  findDertOrderCondRequest,
  findDertOrderCondSuccess,
  findDertOrderCondFailure,
  findDerOrderDetailRequest,
  findDerOrderDetailSuccess,
  findDerOrderDetailFailure,

  updatePageOder,
  updateEnableInfinityOder,
  fetchMoreCondRequest,
  fetchMoreCondSuccess,

  updatePageDer,
  updateEnableInfinityDer,
  fetchMoreOrderByFilterRequest,
  fetchMoreOrderByFilterSuccess,
} = orderHistorySlice.actions;

export default orderHistorySlice.reducer;
