import styled from "styled-components";

export const WrapBorder = styled.div`
  padding: 16px;
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  .button-filter {
    margin-top: 15px;
    height: ${({ theme }) => theme.button.height};
    align-self: flex-end;
    width: ${({ theme }) => theme.button.width};
    @media (min-width: 1800px) {
      margin-left: auto;
    }
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  flex-wrap: wrap;
  p {
    margin-bottom: 4px;
  }
`;
export const ButtonSearch = styled.div`
  margin-top: 10px;
`;
