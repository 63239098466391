import TreemapChart from "components/commons/Charts/TreemapChart";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";
import { getColor } from "helper/utils";
import { get } from "lodash";
import sortBy from "lodash/sortBy";
import { languageTypeSelector } from "modules/categories/redux/selectors";
import { fetchTopIndexIndustry } from "modules/market/redux/topIndexIndustry";
import numeral from "numeral";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "react-use";
import { RootState } from "redux/store";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { MarketCapDataNew } from "../Capitalization/types";
import { ContentContainer } from "./styles";

const topIndexIndustrySelector = (state: RootState) =>
  state.market.industry.topIndexIndustry;

function TopIndexIndustry() {
  const dispatch = useDispatch();
  const languageType = useSelector(languageTypeSelector);
  const { marketCapData } = useSelector(topIndexIndustrySelector);
  const themeType = useSelector(themeTypeSelector);
  const getIndustryName = (industryNameVi: string, industryNameEn: string) => {
    return languageType === "vi" ? industryNameVi : industryNameEn;
  };

  useEffect(() => {
    dispatch(fetchTopIndexIndustry());
  }, []);

  useInterval(() => {
    dispatch(fetchTopIndexIndustry());
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  const generateData = (marketCapData: MarketCapDataNew[]) => {
    let chartData: number[] = [];
    let chartTooltips: string[] = [];
    let chartTooltips2: string[] = [];
    let chartColors: string[] = [];
    let chartLabels: string[] = [];
    let chartLongLabels: string[] = [];
    const sortedMarketCapData = sortBy(marketCapData, [
      function (o) {
        return o.marketCap;
      },
    ]).reverse();
    sortedMarketCapData.forEach((item: MarketCapDataNew) => {
      chartData.push(item.marketCap!);
      chartTooltips.push(`Đóng góp vào INDEX (%):
        ${numeral(item.changedPercentage!).format("0,0.00000")}`);
      chartTooltips2.push(`Đóng góp vào INDEX (điểm):
        ${numeral(item.changedValue!).format("0,0.00000")}`);
      chartColors.push(get(themes[themeType], getColor(item.color!)));
      chartLabels.push(
        getIndustryName(item.industryNameVi, item.industryNameEn) || ""
      );
      chartLongLabels.push(
        `${getIndustryName(item.industryNameVi, item.industryNameEn)}`
      );
    });
    return {
      chartData,
      chartColors,
      chartLabels,
      chartTooltips,
      chartTooltips2,
      chartLongLabels,
    };
  };

  const {
    chartData,
    chartColors,
    chartLabels,
    chartTooltips,
    chartTooltips2,
    chartLongLabels,
  } = generateData(marketCapData);

  return (
    <ContentContainer>
      <div className="chart">
        <TreemapChart
          values={chartData}
          tooltipValues={chartTooltips}
          colors={chartColors}
          treeLabels={chartLabels}
          treeLongLabels={chartLongLabels}
          tooltipValues2={chartTooltips2}
        />
      </div>
    </ContentContainer>
  );
}

export default TopIndexIndustry;
