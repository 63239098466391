import { createSelector } from "@reduxjs/toolkit";
import { Select } from "components/commons";
import { OptionSelectType } from "components/commons/Select";
import {
  convertStringToObject,
  FILTER_GROUP,
  FILTER_TYPE,
} from "modules/screeners/constants";
import {
  changeScreenerFilterItem,
  changeScreenerFilterList,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import { ISampleFilter } from "modules/screeners/types";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Group, InputWrapper, Label, Legend, Wrapper } from "./styles";

const screenersSelector = (state: RootState) => state.screeners;
const rootSelector = (state: RootState) => state;

const languageTypeSelector = createSelector(
  rootSelector,
  (state: RootState) => state.language.type
);

const sampleListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataFilter.sampleFilter.filtersList
);

const screenerListSelector = createSelector(
  screenersSelector,
  (screenersSelector: ScreenersState) =>
    screenersSelector.dataScreener.filterList
);

const MarketField: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const sampleList = useSelector(sampleListSelector);
  const screenersList = useSelector(screenerListSelector);
  const language = useSelector(languageTypeSelector);

  const sampleListFilter = useMemo(() => {
    return sampleList
      .filter(
        (filterItem) =>
          filterItem.group === FILTER_GROUP.MARKET &&
          filterItem.type === FILTER_TYPE.OPTION
      )
      .sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
        return 0;
      });
  }, [sampleList]);

  const screenersListFilter = useMemo(() => {
    return screenersList.filter(
      (filterItem) =>
        filterItem.groupCd === FILTER_GROUP.MARKET &&
        filterItem.type === FILTER_TYPE.OPTION
    );
  }, [screenersList]);

  const handleChangeOption = (
    option: OptionSelectType | null,
    item: ISampleFilter
  ) => {
    if (option?.value) {
      const tempItem: ISampleFilter = { ...item };
      const newOption = { name: option?.label, value: option?.value };
      tempItem.optionsList = [newOption];

      dispatch(changeScreenerFilterItem(tempItem));
    }
    if (
      option?.value === "" &&
      !!screenersListFilter.find((filter) => filter.key === item.key)
    ) {
      dispatch(changeScreenerFilterList(item));
    }
  };

  const generateGroupSelect = useMemo(() => {
    return sampleListFilter.map((item) => {
      const optionList = item.optionsList
        .map((miniItem) => ({
          value: miniItem.value,
          label: miniItem.name,
        }))
        .sort((a, b) => {
          const nameA = a.label.toLowerCase();
          const nameB = b.label.toLowerCase();

          if (nameA > nameB) return 1;
          if (nameA < nameB) return -1;
          return 0;
        });
      optionList.unshift({
        label: intl.formatMessage({
          id: "screeners.tabs.screener.market.select",
        }),
        value: "",
      });
      const currentFilter = screenersListFilter.find(
        (filter) => filter.key === item.key
      );
      let value = optionList?.[0];
      if (!!currentFilter) {
        value = {
          label: currentFilter?.options
            ? convertStringToObject(currentFilter?.options)[0]?.name
            : "",
          value: currentFilter?.options
            ? convertStringToObject(currentFilter?.options)[0]?.value
            : "",
        };
      }

      return (
        <Group>
          <Label>{item.name}</Label>
          <InputWrapper>
            <Select
              className="select"
              value={optionList.find((item) => item.value === value.value)}
              options={optionList}
              onChange={(value) => {
                handleChangeOption(value, item);
              }}
            />
          </InputWrapper>
        </Group>
      );
    });
  }, [sampleListFilter, language, screenersListFilter]);

  return (
    <Wrapper hide={sampleListFilter.length === 0}>
      <Legend>
        <FormattedMessage id="screeners.tabs.screener.market.legend" />
      </Legend>
      {generateGroupSelect}
    </Wrapper>
  );
};

export default MarketField;
