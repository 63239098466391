import styled from "styled-components/macro";

export const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{
  isVisibleSideCard: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
