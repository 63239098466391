import VgaiaHttp from "core/http/singleton/vgaia";
import { IBasePairValueResponse } from "domain/models/BasePairValue";
import { IDepositList } from "domain/models/DepositList";
import { IGetCustomer2, IGetListDeposit } from "./type";
import { getCustomer2Api, getListDepositApi } from "./urls";

export const getCustomer2 = async (
  param: IGetCustomer2
): Promise<IDepositList> => {
  const res = await VgaiaHttp.get<IBasePairValueResponse>(
    getCustomer2Api(param)
  );
  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListDeposit = async (
  param: IGetListDeposit
): Promise<IDepositList> => {
  const res = await VgaiaHttp.get<any>(getListDepositApi(param));

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
