import { FC, memo } from "react";
import { ListPutThrough, PutThroughListType } from "modules/categories/types";
import { ItemCustom, WatchListReadOnlyWrapper } from "./styles";

const ListPutThroughSelect: FC<ListPutThrough> = memo(
  ({ listCode, selectedCode, isOpen, onChangeList }) => {
    if (!isOpen) return null;

    return (
      <WatchListReadOnlyWrapper
        className="menu"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="custom">
          {listCode.map((item: PutThroughListType) => (
            <ItemCustom
              isActive={item.name === selectedCode}
              key={item.name}
              onClick={onChangeList(item.name)}
            >
              <div className="name-item">{item.displayName}</div>
            </ItemCustom>
          ))}
        </div>
      </WatchListReadOnlyWrapper>
    );
  }
);

export default ListPutThroughSelect;
