import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const statisticOndaySelector = (state: RootState) =>
  state.statisticOnday;

export const statisticOndayListSelector = createSelector(
  statisticOndaySelector,
  (state) => state.list
);

export const statisticOndayLoadingSelector = createSelector(
  statisticOndaySelector,
  (state) => state.loading
);

export const statisticOndayFilterSelector = createSelector(
  statisticOndaySelector,
  (state) => state.filter
);

export const activeTabSelector = createSelector(
  statisticOndaySelector,
  (state) => state.activeTab
);
