import queryString from "query-string";
import {
  IGetBondOrderParams,
  IGetBondListParams,
  IGetCustomer2Params,
  IFindCbotBondPriceEstParams,
  IFindCbotBondInfoParams,
  IInquiryBuyingPowerParams,
  IGetBondAvaiSellParams,
  ICalculateBondFeeParams,
  ICalculateTaxAmtParams,
} from "./types";

export const getBondOrderApi = (params: IGetBondOrderParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/getBondOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getBondListApi = (params: IGetBondListParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/findBondList${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findCbotBondInfoApi = (params: IFindCbotBondInfoParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/findCbotBondInfo${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findCbotBondPriceEstApi = (
  params: IFindCbotBondPriceEstParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/findCbotBondPriceEst${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const getCustomer2Api = (params: IGetCustomer2Params) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/getCustomer2${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const inquiryBuyingPowerApi = (params: IInquiryBuyingPowerParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/inquiryBuyingPower${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const getBondAvaiSellApi = (params: IGetBondAvaiSellParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/getBondAvailSell${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const calculateBondFeeApi = (params: ICalculateBondFeeParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/calculateBondFee${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const calculateTaxAmtApi = (params: ICalculateTaxAmtParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: false,
  });
  return `/calculateTaxAmt${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};
export const cancelBondOrderApi = "/cancelBondOrder";
export const placeBondOrderApi = "/placeBondOrder";
