import styled from "styled-components/macro";

export const WidgetContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .headerText {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
    line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
    color: ${({ theme }) => theme.textcolorWhite};
  }
  .sum {
    font-style: normal;
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    color: ${({ theme }) => theme.grey50};
  }
`;

export const ChartSection = styled.div`
  padding-top: 4px;
  flex: 1 1 0;
  position: relative;
  overflow: hidden;
`;

export const HorizontalSection = styled.div`
  margin-top: 8px;
  flex: 0 0 41px;
  .progress-desc {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

    .total-decline-desc {
      color: ${({ theme }) => theme.solidRed};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
    }

    .total-advance-desc {
      color: ${({ theme }) => theme.solidGreen};
      font-size: ${({ theme }) => theme.typeFaces.body.size};
    }
  }
`;

export const TotalTickerContainer = styled.div`
  display: flex;
  height: 8px;
`;
export const TotalTicker = styled.div<{
  value?: number;
  total: number;
  color: string;
}>`
  width: ${({ value = 0, total }) => (value / total) * 100}%;
  background-color: ${({ color }) => color};
  &:first-child {
    border-radius: 4px 0px 0px 4px;
    margin-right: 4px;
  }

  &:nth-child {
    margin-right: 4px;
    margin-left: 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
    margin-left: 4px;
  }
`;
