import styled from "styled-components/macro";

export const ContentB2 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 36px 0;
`;

export const ButtonCamera = styled.div`
  color: ${({ theme }) => theme.brandPrimary};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  font-weight: ${({ theme }) => theme.typeFaces.caption12.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.caption12.lineHeight};
  height: 32px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.brandPrimary};
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
  margin: 10px 0 0;
`;

export const BoxInput = styled.div`
  max-width: 320px;
`;

export const BoxCamera = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 28px;
`;

export const BoxButtonCamera = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BodyCamera = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.brandPrimary};
  padding: 10px;
  align-items: center;
`;

export const NullCamera = styled.div`
  width: 250px;
  height: 170px;
  background-color: ${({ theme }) => theme.brandPrimary};
`;
