import { toast } from "components/commons/Toast";
import { getLimitAccount } from "core/http/apis/change-limit";
import { IDepositList } from "domain/models/DepositList";

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getLimitAccountFailure,
  getLimitAccountRequest,
  getLimitAccountSuccess,
} from "./redux";

function* getLimitAccountWorker(
  action: ReturnType<typeof getLimitAccountRequest>
) {
  try {
    const params: any = action.payload;

    const response: IDepositList = yield call(getLimitAccount, params);

    if (response?.statusCode === 0) {
      yield put(getLimitAccountSuccess(response));
    } else {
      yield put(getLimitAccountFailure());

      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getLimitAccountFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getLimitAccountWatcher() {
  yield takeLatest(getLimitAccountRequest.type, getLimitAccountWorker);
}

export default function* changeLimitSaga() {
  yield all([getLimitAccountWatcher()]);
}
