import HttpManagerDevice from "core/http/singleton/manager-device";
import VgaiaHttp from "core/http/singleton/vgaia";
import queryString from "query-string";

// import storage from "helper/storage";

// import qs from "qs";

/**
 * Lấy phí chuyển tiền
 */
// export const getMoneyTransferFeeApi = async (): Promise<TransFeeInterface> => {
//   const res = await HttpTransferMoney.get<TransFeeInterface>(getTransFee());
//   if (!res || !res.data) throw new Error("Opps");
//   return res.data;
// };

interface IModalRegister {
  data: any;
  errorCode: string;
  errorField: string;
  message: string;
  statusCode: number;
}

export const getDataCreateUser = async () => {
  const res = await VgaiaHttp.get<IModalRegister>("/public/getDataCreateUser");

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findTransactionListApi = async () => {
  const paramsFormatter = queryString.stringify(
    { branchLocaltionCd: 0, transactionCd: null },
    {
      skipNull: false,
    }
  );
  const res = await HttpManagerDevice.get<IModalRegister>(
    `/findTransactionList${paramsFormatter ? `?${paramsFormatter}` : ""}`
    // JSON.stringify({ branchLocaltionCd: 0, transactionCd: null })
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getTransactionListApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.get<IModalRegister>(
    `/getTransactionList${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const findBrokerListApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.get<IModalRegister>(
    `/findBrokerList${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const uploadIdCardApi = async (params: FormData) => {
  const res = await HttpManagerDevice.post<IModalRegister>(
    `/uploadIdCard`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const faceCompareApi = async (params: FormData) => {
  const res = await HttpManagerDevice.post<IModalRegister>(
    `/face-compare`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListCortCustomerRecommend2Api = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.get<IModalRegister>(
    `/getListCortCustomerRecommend2${
      paramsFormatter ? `?${paramsFormatter}` : ""
    }`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getListCortCustomerRecommendApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.get<IModalRegister>(
    `/getListCortCustomerRecommend${
      paramsFormatter ? `?${paramsFormatter}` : ""
    }`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const checkCustNoExistApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.post<IModalRegister>(
    `/checkCustNoExist${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getDataCreateUserApi = async () => {
  const res = await HttpManagerDevice.post<IModalRegister>(
    `/getDataCreateUser
    }`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const validateIdNumberApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.post<IModalRegister>(
    `/validateIdNumber${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankMasterApi = async () => {
  const res = await VgaiaHttp.get<IModalRegister>(`/public/getBankMaster`);

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getBankListApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await HttpManagerDevice.get<IModalRegister>(
    `/getBankList${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAllServiceAndDetailApi = async () => {
  const res = await VgaiaHttp.get<IModalRegister>(
    `/public/getAllServiceAndDetail`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const getAuthenOTPApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.get<IModalRegister>(
    `/public/getAuthenOTP${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const authenOtpValidateApi = async (params: any) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  const res = await VgaiaHttp.post<IModalRegister>(
    `/public/authenOtpValidate${paramsFormatter ? `?${paramsFormatter}` : ""}`
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};

export const createCustomerOnlineApi = async (params: any) => {
  // const paramsFormatter = queryString.stringify(params, {
  //   skipNull: true,
  // });
  const res = await VgaiaHttp.post<IModalRegister>(
    `/public/createCustomerOnline`,
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

  if (!res || !res.data) throw new Error("Opps");
  return res.data;
};
