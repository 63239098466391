import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const registerToTransferBondsSelector = (state: RootState) =>
  state.registerToTransferBonds;

export const loadingSelector = createSelector(
  registerToTransferBondsSelector,
  (state) => state.loading
);

export const getAccount = createSelector(
  registerToTransferBondsSelector,
  (state) => state.account
);
export const getCbot = createSelector(
  registerToTransferBondsSelector,
  (state) => state.cbot
);
