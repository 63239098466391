import { IndustryTab } from "./types";
import MarketWatchButton from "components/widgets/generals/Industry/IndustryButton";
import { FormattedMessage } from "react-intl";
import { memo } from "react";
import { TabsContainer } from "components/widgets/generals/MarketWatch/styles";

export interface TabsProps {
  activeTab: IndustryTab;
  onChangeTabMarketWatch: (tab: IndustryTab) => void;
}

const industryTabs: IndustryTab[] = ["topIndex", "transactionIndustry"];

function Tabs({ activeTab, onChangeTabMarketWatch }: TabsProps) {
  return (
    <TabsContainer>
      <div className="tabs">
        {industryTabs.map((tab: IndustryTab) => {
          return (
            <div key={tab}>
              <MarketWatchButton
                active={activeTab === (tab as IndustryTab)}
                value={tab}
                onClick={onChangeTabMarketWatch}
                tabName={tab}
              >
                <FormattedMessage id={`industry.${tab}`} />
              </MarketWatchButton>
            </div>
          );
        })}
      </div>
    </TabsContainer>
  );
}

export default memo(Tabs);
