import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISearchStatisticPriceParams } from "core/http/apis/statistic-price/types";
import {
  IMarketStatistic,
  IMarketStatisticResponse,
} from "domain/models/MarketStatistic";
import { logOutSuccess } from "modules/auth/redux";
import { STATISTIC_PRICE_TABS } from "./constants";

interface IFilter {
  type?: string;
  marketCd?: string;
}

export interface ReduxData {
  filter: IFilter;
  list: IMarketStatistic[];
  loading: boolean;
  activeTab: string;
}

const initialState: ReduxData = {
  filter: {
    type: "1",
    marketCd: "0",
  },
  list: [],
  loading: false,
  activeTab: STATISTIC_PRICE_TABS.STATISTIC_PRICE,
};

const statisticPriceSlice = createSlice({
  name: "statisticPriceSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchStatisticPriceRequest: (
      state,
      action: PayloadAction<ISearchStatisticPriceParams>
    ) => {
      state.loading = true;
    },
    searchStatisticPriceSuccess: (
      state,
      action: PayloadAction<IMarketStatisticResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchStatisticPriceFailure: (state) => {
      state.loading = false;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchStatisticPriceFailure,
  searchStatisticPriceRequest,
  searchStatisticPriceSuccess,
  updateFilter,
} = statisticPriceSlice.actions;

export default statisticPriceSlice.reducer;
