import paths from "helper/pathRoutes";
import { RouterConfig } from "router/AppRoute";
import TechAnalysis from "./pages/TechAnalysis";

const routes: RouterConfig[] = [
  {
    page: TechAnalysis,
    path: paths.techAnalysis,
    exact: true,
    checkLogin: false,
  },
];

export default routes;
