import styled from "styled-components/macro";

export const Wrap: any = styled.div`
  display: flex;
  justify-content: space-between;
  height: 47%;
  @media (max-width: 1360px) {
    height: 60%;
  } ;
`;

export const WrapItem: any = styled.div`
  width: 33%;
  background: ${({ theme }) => theme.background2};
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  padding: 16px;
`;

export const BodyModalRow = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelCell = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey20};
`;

export const TextCell = styled.div`
  flex: 1;
  text-align: right;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey20};
`;

export const Title = styled.div`
  padding: 10px 5px;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;
