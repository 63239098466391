import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "components/commons/Toast";
import { searchMrgContractDebit } from "core/http/apis/mrg-contract-debit";
import { ISearchMrgContractDebitParams } from "core/http/apis/mrg-contract-debit/types";
import { IMarginDebitContractWatchResponse } from "domain/models/MarginDebitContractWatch";
import {
  searchMrgContractDebitFailure,
  searchMrgContractDebitRequest,
  searchMrgContractDebitSuccess,
} from "./redux";

function* mrgContractDebitWorker(
  action: ReturnType<typeof searchMrgContractDebitRequest>
) {
  try {
    const params: ISearchMrgContractDebitParams = action.payload;
    const response: IMarginDebitContractWatchResponse = yield call(
      searchMrgContractDebit,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchMrgContractDebitSuccess(response));
    } else {
      yield put(searchMrgContractDebitFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchMrgContractDebitFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* mrgContractDebitWatcher() {
  yield takeLatest(searchMrgContractDebitRequest.type, mrgContractDebitWorker);
}

export default function* mrgContractDebitSaga() {
  yield all([mrgContractDebitWatcher()]);
}
