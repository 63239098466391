import { AuthenCodeGrpc } from "AppConfig";
import { GRPC_PORT_NEW, GRPC_URL_NEW } from "AppConfigNew";
import auto_trading_pb, {
  InternalMS,
  InternalMSInput,
  InternalMSResponse,
  MarketBreadthItem,
  MarketDepthInput,
  MarketDepthResponse,
  MarketIndexInfo,
  MarketIndexInput,
  MarketInitDataItem,
  MarketLiquidityItem,
  MarketLiquidityParam,
  MarketNetFlowResponse,
  MrktSecInfoItem,
  NetFlowInput,
  NullableString,
  PutThroughData,
  SecDividendResponse,
  SecIntradayInput,
  SecIntradayResponse,
  SecQuotesDetailResponse,
  SectFinanceData,
  SectNewsDetailItem,
  SectNewsItem,
  SectOwnershipItem,
  SectProfileItem,
  SymbolTotalInfo,
  TimeSaleResponse,
  TopIndexContributionData,
  TopIndexContributionParam,
  TopIndustriesContributionItem,
  TopIndustriesContributionParam,
  TopIndustriesTradeForeignParam,
  TopIndustriesTradeForeignResponse,
  TopIndustriesTradeItem,
  TopIndustriesTradeParam,
  TopMarketCapInput,
  TopMarketCapResponse,
  TopSecChangedInput,
  TopSecChangedResponse,
  TopSecUpDownInput,
  TopSecUpDownResponse,
} from "domain/protoNew/auto_trading_pb";
import {
  MarketApiClient,
  Status,
} from "domain/protoNew/auto_trading_pb_service";
import { StatusCode } from "grpc-web";
import { ETradingDateMapNetFlow } from "helper/consts";
import Storage from "helper/storage";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { pushTickerToMapNew } from "../../helper/globalData";
import { store } from "redux/store";

const hostNew = GRPC_URL_NEW + ":" + GRPC_PORT_NEW;
const hostNewNoLogin = GRPC_URL_NEW + ":" + GRPC_PORT_NEW;

// New
export const marketApiClient = new MarketApiClient(hostNew);
export const marketApiClientNoLogin = new MarketApiClient(hostNewNoLogin);

export const ACCESS_TOKEN_EXPIRED_CODE = 16;
export const ACCESS_TOKEN_EXPIRED_MESSAGE = "Invalid token: Active = false";

const mrktSecInfoParam = new auto_trading_pb.MrktSecInfoParam();
const marketInitDataParam = new auto_trading_pb.MarketInitDataParam();

export const getWatchListsApiNew = (): Promise<MarketInitDataItem.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    marketInitDataParam.setAuthencode(AuthenCodeGrpc);
    marketInitDataParam.setChannel(1);

    // TODO đợi server fix lỗi CORS khi có truyền token
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getMarketInitData(marketInitDataParam, (err, res) => {
      if (res && res?.getItems()) {
        const items = res?.getItems();
        if (items) {
          return resolve(items.toObject());
        }
      }
      return reject(err);
    });
  });
};

export const getListTickersNew = (): Promise<MrktSecInfoItem.AsObject[]> => {
  mrktSecInfoParam.setAuthencode(AuthenCodeGrpc);
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    let tickerList: MrktSecInfoItem.AsObject[] = [];
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getAllMrktSecInfo(mrktSecInfoParam, (err, res) => {
      if (res) {
        const tickers: MrktSecInfoItem[] = res?.getDataList() ?? [];
        tickerList = tickers.map((item) => {
          return item.toObject();
        });
        Storage.setTickerListAllNew(tickerList);
        pushTickerToMapNew(tickerList);
        resolve(tickerList);
      } else {
        tickerList = Storage.getTickerListAllNew() ?? [];
        // Never failed
        resolve(tickerList);
      }
    });
  });
};

export const requestFindMarketDepth = (
  marketCd: number
): Promise<MarketDepthResponse.AsObject | null> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    const request: MarketDepthInput = new MarketDepthInput();
    request.setUserid(uuid());
    request.setIndexcd(marketCd);
    request.setAuthencode(AuthenCodeGrpc);
    let results: MarketDepthResponse.AsObject = {
      status: 0,
      error: "",
      message: "",
    };
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findMarketDepth(request)
      .on("data", (data) => {
        results = data?.toObject();
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const requestFindNetflow = (
  marketCd: number,
  tradingDates: ETradingDateMapNetFlow
): Promise<MarketNetFlowResponse.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    const request: NetFlowInput = new NetFlowInput();
    const userId: NullableString = new NullableString();
    userId.setNull(0);
    userId.setData(uuid());
    request.setUserid(userId);
    request.setIndexcd(marketCd);
    request.setTn(tradingDates);
    request.setAuthencode(AuthenCodeGrpc);
    let results: MarketNetFlowResponse.AsObject = {
      status: 0,
      error: "",
      message: "",
      marketnetflowlistList: [],
    };

    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findNetFlow(request)
      .on("data", (data) => {
        results = data?.toObject();
      })
      .on("status", (status: Status) => {
        if (status?.code !== StatusCode.OK) {
          return reject(new Error(status.details));
        }
      })
      .on("end", (status?: Status) => {
        if (status?.code !== StatusCode.OK) {
          return reject(new Error(status?.details));
        }
        return resolve(results);
      });
  });
};

export const getMarketDistribution = ({
  marketType,
  valueType,
}: {
  marketType: string;
  valueType: number;
}): Promise<TopSecChangedResponse.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    const request: TopSecChangedInput = new TopSecChangedInput();
    request.setUserid(uuid());
    request.setMarketcd(marketType);
    request.setChangedtype(valueType);
    request.setPeriodtype(1);
    request.setOffset(0);
    request.setLimit(500);
    request.setAuthencode(AuthenCodeGrpc);
    let results: TopSecChangedResponse.AsObject = {
      status: 0,
      error: "",
      message: "",
      topsecchangedList: [],
    };
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTopSecChanged(request)
      .on("data", (data) => {
        results = data?.toObject();
      })
      .on("status", (status: Status) => {
        if (status?.code !== StatusCode.OK) {
          return reject(new Error(status.details));
        }
      })
      .on("end", (status?: Status) => {
        if (status?.code !== StatusCode.OK) {
          return reject(new Error(status?.details));
        }
        return resolve(results);
      });
  });
};

export const getStatDividendMovement = (
  marketCd: string
): Promise<InternalMSResponse.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    const request: InternalMSInput = new InternalMSInput();
    const userId: NullableString = new NullableString();
    userId.setNull(0);
    userId.setData(uuid());
    request.setUserid(userId);

    request.setSide(0);
    request.setIndexcdstr(marketCd);
    request.setType(1);

    request.setOffset(0);
    request.setLimit(500);
    request.setAuthencode(AuthenCodeGrpc);
    let results: InternalMSResponse.AsObject = {
      status: 0,
      error: "",
      message: "",
      internalmslistList: [],
    };
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTopForeignTrade(request)
      .on("data", (data) => {
        results = data?.toObject();
      })
      .on("status", (status: Status) => {
        if (status?.code !== StatusCode.OK) {
          return reject(new Error(status.details));
        }
      })
      .on("end", (status?: Status) => {
        if (status?.code !== StatusCode.OK) {
          return reject(new Error(status?.details));
        }
        return resolve(results);
      });
  });
};

export const getTopIndustriesTradeForeign = (
  marketCd: string
): Promise<TopIndustriesTradeForeignResponse.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    const request: TopIndustriesTradeForeignParam =
      new TopIndustriesTradeForeignParam();
    request.setMarketcd(parseInt(marketCd));
    request.setAuthencode(AuthenCodeGrpc);
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getTopIndustriesTradeForeign(request, (err, res) => {
      if (res) return resolve(res?.toObject());
      return reject(err);
    });
  });
};

// Lấy giá danh sách mã
export const getStreamPriceInfoFulls = (
  tickers: string,
  getChart?: boolean
) => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.WatchListInput();

  const userId = new NullableString();
  userId.setData(uuid());
  const isIntraday = new NullableString();
  isIntraday.setData(getChart ? "true" : "false");

  request.setSeclist(tickers);
  request.setUserid(userId);
  request.setIsintraday(isIntraday);
  request.setAuthencode(AuthenCodeGrpc);
  let results: SymbolTotalInfo.AsObject[] = [];
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findWatchList(request)
      .on("data", (data) => {
        results.push(data?.toObject());
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getWatchListOdd = ({
  tickers,
  getChart,
}: {
  tickers: string;
  getChart?: boolean;
}) => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.WatchListInput();

  const userId = new NullableString();
  userId.setData(uuid());
  const isIntraday = new NullableString();
  isIntraday.setData(getChart ? "true" : "false");
  request.setSeclist(tickers);
  request.setUserid(userId);
  request.setIsintraday(isIntraday);
  request.setAuthencode(AuthenCodeGrpc);
  let results: SymbolTotalInfo.AsObject[] = [];
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findWatchListOdd(request)
      .on("data", (data) => {
        results.push(data?.toObject());
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getListPriceInfoPutThrough = (
  marketcd: number
): Promise<PutThroughData.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.PutThroughParam();
  request.setAuthencode(AuthenCodeGrpc);
  request.setMarketcd(marketcd);
  request.setTradedate(parseInt(moment().format("YYYYMMDD")));
  return new Promise((resolve, reject) => {
    let priceInfoPutThroughList: PutThroughData.AsObject = {
      selllistList: [],
      buylistList: [],
      matchptlistList: [],
    };
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin).getPutThrough(
      request,
      (err, res) => {
        if (res) {
          const priceInfos: PutThroughData | undefined = res.getData();
          if (priceInfos) {
            priceInfoPutThroughList = priceInfos.toObject();
          }
          return resolve(priceInfoPutThroughList);
        }
        return reject(err);
      }
    );
  });
};

export const getTopIndustriesContribution = (
  marketcd: number
): Promise<TopIndustriesContributionItem.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new TopIndustriesContributionParam();
  request.setAuthencode(AuthenCodeGrpc);
  request.setMarketcd(marketcd);

  return new Promise((resolve, reject) => {
    let data: TopIndustriesContributionItem.AsObject[] = [];
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getTopIndustriesContribution(request, (err, res) => {
      if (res) {
        const response: TopIndustriesContributionItem[] | undefined =
          res.getDataList();
        if (response) {
          data = response.map((item) => item.toObject());
        }
        return resolve(data);
      }
      return reject(err);
    });
  });
};

export const getTopIndustriesTrade = (
  marketcd: number
): Promise<TopIndustriesTradeItem.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new TopIndustriesTradeParam();
  request.setAuthencode(AuthenCodeGrpc);
  request.setMarketcd(marketcd);

  return new Promise((resolve, reject) => {
    let data: TopIndustriesTradeItem.AsObject[] = [];
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getTopIndustriesTrade(request, (err, res) => {
      if (res) {
        const response: TopIndustriesTradeItem[] | undefined =
          res.getDataList();
        if (response) {
          data = response.map((item) => item.toObject());
        }
        return resolve(data);
      }
      return reject(err);
    });
  });
};

export const getTopIndexContribution = (
  marketCd: number
): Promise<TopIndexContributionData.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new TopIndexContributionParam();
  request.setAuthencode(AuthenCodeGrpc);
  request.setMarketcd(marketCd);

  return new Promise((resolve, reject) => {
    let topIndexContributionData: TopIndexContributionData.AsObject = {
      topdecreaseList: [],
      topincreaseList: [],
    };

    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getTopIndexContribution(request, (err, res) => {
      if (res) {
        const responseData: TopIndexContributionData | undefined =
          res.getData();
        if (responseData) {
          topIndexContributionData = responseData.toObject();
        }
        return resolve(topIndexContributionData);
      }
      return reject(err);
    });
  });
};

export const getMarketBreadth = (
  marketCd: number
): Promise<Array<MarketBreadthItem.AsObject>> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new TopIndexContributionParam();
  request.setAuthencode(AuthenCodeGrpc);
  request.setMarketcd(marketCd);

  return new Promise((resolve, reject) => {
    let marketBreadthData: MarketBreadthItem.AsObject[] = [];

    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getMarketBreadth(request, (err, res) => {
      if (res) {
        const responseData: MarketBreadthItem[] | undefined = res.getDataList();
        if (responseData) {
          marketBreadthData = responseData.map((item) => item.toObject());
        }
        return resolve(marketBreadthData);
      }
      return reject(err);
    });
  });
};

export const getTopSecUpDown = (
  topSecUpDownInput: TopSecUpDownInput
): Promise<TopSecUpDownResponse.TopSecUpDownInfo.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  let results: TopSecUpDownResponse.TopSecUpDownInfo.AsObject[] = [];
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTopSecUpDown(topSecUpDownInput)
      .on("data", (data) => {
        results = data.toObject().topsecupdownList;
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getTopSecChanged = (
  TopSecChangedInput: TopSecChangedInput
): Promise<TopSecChangedResponse.TopSecChangedInfo.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  let results: TopSecChangedResponse.TopSecChangedInfo.AsObject[] = [];
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTopSecChanged(TopSecChangedInput)
      .on("data", (data) => {
        results = data.toObject().topsecchangedList;
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getTopForeignTrade = (
  InternalMSInput: InternalMSInput
): Promise<InternalMS.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  let results: InternalMS.AsObject[] = [];
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTopForeignTrade(InternalMSInput)
      .on("data", (data) => {
        results = data.toObject().internalmslistList;
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getTopMarketCap = (
  requestTopMarketCap: TopMarketCapInput
): Promise<TopMarketCapResponse.TopMarketCapInfo.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  let results: TopMarketCapResponse.TopMarketCapInfo.AsObject[] = [];
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTopMarketCap(requestTopMarketCap)
      .on("data", (data) => {
        results = data.toObject().topmarketcapinfoList;
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getMarketLiquidity = (
  marketCd: number
): Promise<MarketLiquidityItem.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  return new Promise((resolve, reject) => {
    const request = new MarketLiquidityParam();
    request.setMarketcd(marketCd);
    request.setAuthencode(AuthenCodeGrpc);
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getMarketLiquidity(request, (err, res) => {
      if (res) return resolve(res.toObject().dataList);
      return reject(err);
    });
  });
};

export const findMarketIndex = (
  indexCdList: string
): Promise<MarketIndexInfo.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  let results: MarketIndexInfo.AsObject[] = [];
  return new Promise((resolve, reject) => {
    const request = new MarketIndexInput();
    request.setUserid(uuid());
    request.setIndexcdlist(indexCdList);
    request.setAuthencode(AuthenCodeGrpc);
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findMarketIndex(request)
      .on("data", (data) => {
        results.push(data.toObject());
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getSecQuotesDetail = (
  tickers: string
): Promise<SecQuotesDetailResponse.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.SecQuotesDetailInput();
  request.setUserid(uuid());
  request.setSeccd(tickers);
  request.setAuthencode(AuthenCodeGrpc);

  let results = new SecQuotesDetailResponse().toObject();
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findSecQuotesDetail(request)
      .on("data", (data) => {
        results = data.toObject();
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getFindSecIntraday = (secIntradayInput: SecIntradayInput) => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  let results = new SecIntradayResponse().toObject();
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findSecIntraday(secIntradayInput)
      .on("data", (data) => {
        results = data.toObject();
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

export const getFindTimeSale = (tickers: string, tn: number) => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.TimeSaleInput();
  const userId = new NullableString();
  userId.setData(uuid());
  request.setUserid(userId);
  request.setSeccd(tickers);
  request.setAuthencode(AuthenCodeGrpc);
  request.setTn(tn);

  let results = new TimeSaleResponse().toObject();
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findTimeSale(request)
      .on("data", (data) => {
        results = data.toObject();
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

// Hàm lấy thông tin phân bổ lệnh khớp
export const getOrderFlowDistribution = (
  secCd: string
): Promise<SecDividendResponse.AsObject | null> => {
  const request = new auto_trading_pb.SecDividendInput();
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  request.setSeccd(secCd);
  request.setAuthencode(AuthenCodeGrpc);
  request.setUserid(uuid());

  let results: SecDividendResponse.AsObject | null = null;
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin)
      .findSecDividend(request)
      .on("data", (data) => {
        results = data.toObject();
      })
      .on("end", () => {
        return resolve(results);
      });
  });
};

// Lấy danh sách tài chính theo mã
export const getSectFinance = (
  tickers: string,
  duration: number
): Promise<SectFinanceData.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.SectFinanceParam();

  request.setSeccd(tickers);
  request.setDuration(duration);
  request.setAuthencode(AuthenCodeGrpc);
  return new Promise((resolve, reject) => {
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin).getSectFinance(
      request,
      (err, res) => {
        if (res) {
          const response: SectFinanceData | undefined = res.getData();
          if (response) {
            return resolve(response.toObject());
          }
        }
        return reject(err);
      }
    );
  });
};

// Lấy danh sách các nhà đầu tư
export const getSectProfile = (
  tickers: string
): Promise<SectProfileItem.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.SectProfileParam();

  request.setSeccd(tickers);
  request.setAuthencode(AuthenCodeGrpc);
  return new Promise((resolve, reject) => {
    let sectProfileItemData: SectProfileItem.AsObject[] = [];
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin).getSectProfile(
      request,
      (err, res) => {
        if (res) {
          const response: SectProfileItem[] | undefined = res.getDataList();
          if (response) {
            sectProfileItemData = response.map((item) => item.toObject());
          }
          return resolve(sectProfileItemData);
        }
        return reject(err);
      }
    );
  });
};

// Lấy cơ cấu trong và ngoài nước
export const getSectOwnership = (
  tickers: string
): Promise<SectOwnershipItem.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.SectOwnershipParam();

  request.setSeccd(tickers);
  request.setAuthencode(AuthenCodeGrpc);
  return new Promise((resolve, reject) => {
    let sectOwnershipItemData: SectOwnershipItem.AsObject[] = [];
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getSectOwnership(request, (err, res) => {
      if (res) {
        const response: SectOwnershipItem[] | undefined = res.getDataList();
        if (response) {
          sectOwnershipItemData = response.map((item) => item.toObject());
        }
        return resolve(sectOwnershipItemData);
      }
      return reject(err);
    });
  });
};

// Lấy danh sách tin tức
export const getSectNews = (
  tickers: string,
  page: number,
  size: number
): Promise<SectNewsItem.AsObject[]> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.SectNewsParam();

  request.setSeccd(tickers);
  request.setPage(page);
  request.setPagesize(size);
  request.setAuthencode(AuthenCodeGrpc);
  return new Promise((resolve, reject) => {
    let sectNewsItemData: SectNewsItem.AsObject[] = [];
    (isAuthenticated ? marketApiClient : marketApiClientNoLogin).getSectNews(
      request,
      (err, res) => {
        if (res) {
          const response: SectNewsItem[] | undefined = res.getDataList();
          if (response) {
            sectNewsItemData = response.map((item) => item.toObject());
          }
          return resolve(sectNewsItemData);
        }
        return reject(err);
      }
    );
  });
};

// Lấy chi tiết tin tức
export const getSectNewsDetail = (
  id: number
): Promise<SectNewsDetailItem.AsObject> => {
  const isAuthenticated = store.getState().auth.root.data.isAuthenticated;
  const request = new auto_trading_pb.SectNewsDetailParam();

  request.setId(id);
  request.setAuthencode(AuthenCodeGrpc);
  return new Promise((resolve, reject) => {
    (isAuthenticated
      ? marketApiClient
      : marketApiClientNoLogin
    ).getSectNewsDetail(request, (err, res) => {
      if (res) {
        const response: SectNewsDetailItem | undefined = res.getData();
        if (response) {
          return resolve(response.toObject());
        }
      }
      return reject(err);
    });
  });
};
