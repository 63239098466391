import { toast } from "components/commons/Toast";
import {
  searchDepositContract,
  liquidateDepositContract,
  findDepositPayment,
  findDepositContract,
  getEarlyInterestRate,
  getInterestAmount,
  updateDepositPayment,
  updateDepositContract,
  getDepositInquiry,
  getDueDateDepositContract,
  //   createContract,
} from "core/http/apis/deposit-contract";
import {
  ISearchDepositContractParams,
  ILiquidateDepositContractParams,
  IFindDepositPaymentParams,
  IFindDepositContractParams,
  IGetEarlyInterestRateParams,
  IGetInterestAmountParams,
  IUpdateDepositPaymentParams,
  IUpdateDepositContractParams,
  IGetDepositInquiryParams,
  IGetDueDateDepositContractParams,
  //   ICreateContractParams,
} from "core/http/apis/deposit-contract/types";
import {
  ISearchDepositContractResponse,
  ILiquidateDepositContractResponse,
  IFindDepositPaymentResponse,
  IFindDepositContractResponse,
  IGetEarlyInterestRateResponse,
  IGetInterestAmountResponse,
  IUpdateDepositPaymentResponse,
  IUpdateDepositContractResponse,
  IGetDepositInquiryResponse,
  IGetDueDateDepositContractResponse,
  DepositContract,

  //   ICreateContractResponse,
} from "domain/models/DepositContract";

import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  searchDepositContractFailure,
  searchDepositContractRequest,
  searchDepositContractSuccess,
  liquidateDepositContractRequest,
  liquidateDepositContractSuccess,
  liquidateDepositContractFailure,
  updateLiquidateModalStatusRequest,
  updateLiquidateModalStatusSuccess,
  updateDetailModalStatusRequest,
  updateDetailModalStatusSuccess,
  updateModalConfirmRequest,
  updateModalConfirmSuccess,
  findDepositPaymentRequest,
  findDepositPaymentSuccess,
  findDepositPaymentFailure,
  findDepositContractRequest,
  findDepositContractSuccess,
  findDepositContractFailure,
  //   createContractRequest,
  //   createContractSuccess,
  //   createContractFailure,
  getEarlyInterestRateRequest,
  getEarlyInterestRateSuccess,
  getEarlyInterestRateFailure,
  getInterestAmountSuccess,
  getInterestAmountRequest,
  getInterestAmountFailure,
  getInterestAmount2Request,
  getInterestAmount2Success,
  getInterestAmount2Failure,
  updateDepositPaymentRequest,
  updateDepositPaymentSuccess,
  updateDepositPaymentFailure,
  updateDepositContractRequest,
  updateDepositContractSuccess,
  updateDepositContractFailure,
  getDepositInquiryRequest,
  getDepositInquirySuccess,
  getDepositInquiryFailure,
  getDueDateDepositContractRequest,
  getDueDateDepositContractSuccess,
  getDueDateDepositContractFailure,
} from "./redux";

function* searchDepositContractWorker(
  action: ReturnType<typeof searchDepositContractRequest>
) {
  try {
    const params: ISearchDepositContractParams = action.payload;
    const response: ISearchDepositContractResponse = yield call(
      searchDepositContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(searchDepositContractSuccess(response));
    } else {
      yield put(searchDepositContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(searchDepositContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findDepositPaymentWorker(
  action: ReturnType<typeof findDepositPaymentRequest>
) {
  try {
    const params: IFindDepositPaymentParams = action.payload;
    const response: IFindDepositPaymentResponse = yield call(
      findDepositPayment,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findDepositPaymentSuccess(response));
    } else {
      yield put(findDepositPaymentFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findDepositPaymentFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* findDepositContractWorker(
  action: ReturnType<typeof findDepositContractRequest>
) {
  try {
    const params: IFindDepositContractParams = action.payload;
    const response: IFindDepositContractResponse = yield call(
      findDepositContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(findDepositContractSuccess(response));
    } else {
      yield put(findDepositContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(findDepositContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* liquidateDepositContractWorker(
  action: ReturnType<typeof liquidateDepositContractRequest>
) {
  try {
    const params: ILiquidateDepositContractParams = action.payload;
    const response: ILiquidateDepositContractResponse = yield call(
      liquidateDepositContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(liquidateDepositContractSuccess(response));
    } else {
      yield put(liquidateDepositContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(liquidateDepositContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateLiquidateModalStatusWorker(
  action: ReturnType<typeof updateLiquidateModalStatusRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
    }: {
      isVisible: boolean;
      itemDetail?: DepositContract;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateLiquidateModalStatusSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateLiquidateModalStatusSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateDetailModalStatusWorker(
  action: ReturnType<typeof updateDetailModalStatusRequest>
) {
  try {
    const {
      isVisible,
      itemDetail,
    }: {
      isVisible: boolean;
      itemDetail?: DepositContract;
    } = action.payload;

    if (isVisible) {
    }
    if (itemDetail) {
      yield put(updateDetailModalStatusSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateDetailModalStatusSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateModalConfirmWorker(
  action: ReturnType<typeof updateModalConfirmRequest>
) {
  try {
    const {
      isVisible,

      itemDetail,
    }: {
      isVisible: boolean;

      itemDetail?: DepositContract;
    } = action.payload;

    if (itemDetail) {
      yield put(updateModalConfirmSuccess({ isVisible, itemDetail }));
    } else {
      yield put(updateModalConfirmSuccess({ isVisible }));
    }
  } catch (error: any) {
    toast(error?.code, "error", error?.description, true);
  }
}

// function* createContractWorker(
//   action: ReturnType<typeof createContractRequest>
// ) {
//   try {
//     const params: ICreateContractParams = action.payload;
//     const response: ICreateContractResponse = yield call(
//       createContract,
//       params
//     );

//     if (response?.statusCode === 0) {
//       yield put(createContractSuccess(response));
//     } else {
//       yield put(createContractFailure());
//       toast(response?.errorCode, "error", response?.message, true);
//     }
//   } catch (error: any) {
//     yield put(createContractFailure());
//     toast(error?.code, "error", error?.description, true);
//   }
// }

function* getEarlyInterestRateWorker(
  action: ReturnType<typeof getEarlyInterestRateRequest>
) {
  try {
    const params: IGetEarlyInterestRateParams = action.payload;
    const response: IGetEarlyInterestRateResponse = yield call(
      getEarlyInterestRate,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getEarlyInterestRateSuccess(response));
    } else {
      yield put(getEarlyInterestRateFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getEarlyInterestRateFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getInterestAmountWorker(
  action: ReturnType<typeof getInterestAmountRequest>
) {
  try {
    const params: IGetInterestAmountParams = action.payload;
    const response: IGetInterestAmountResponse = yield call(
      getInterestAmount,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getInterestAmountSuccess(response));
    } else {
      yield put(getInterestAmountFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getInterestAmountFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getInterestAmount2Worker(
  action: ReturnType<typeof getInterestAmount2Request>
) {
  try {
    const params: IGetInterestAmountParams = action.payload;
    const response: IGetInterestAmountResponse = yield call(
      getInterestAmount,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getInterestAmount2Success(response));
    } else {
      yield put(getInterestAmount2Failure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getInterestAmount2Failure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* updateDepositPaymentWorker(
  action: ReturnType<typeof updateDepositPaymentRequest>
) {
  try {
    const params: IUpdateDepositPaymentParams = action.payload;
    const response: IUpdateDepositPaymentResponse = yield call(
      updateDepositPayment,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updateDepositPaymentSuccess(response));
    } else {
      yield put(updateDepositPaymentFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(updateDepositPaymentFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* updateDepositContractWorker(
  action: ReturnType<typeof updateDepositContractRequest>
) {
  try {
    const params: IUpdateDepositContractParams = action.payload;
    const response: IUpdateDepositContractResponse = yield call(
      updateDepositContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(updateDepositContractSuccess(response));
    } else {
      yield put(updateDepositContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(updateDepositContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* getDepositInquiryWorker(
  action: ReturnType<typeof getDepositInquiryRequest>
) {
  try {
    const params: IGetDepositInquiryParams = action.payload;
    const response: IGetDepositInquiryResponse = yield call(
      getDepositInquiry,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getDepositInquirySuccess(response));
    } else {
      yield put(getDepositInquiryFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDepositInquiryFailure());
    toast(error?.code, "error", error?.description, true);
  }
}

function* getDueDateDepositContractWorker(
  action: ReturnType<typeof getDueDateDepositContractRequest>
) {
  try {
    const params: IGetDueDateDepositContractParams = action.payload;
    const response: IGetDueDateDepositContractResponse = yield call(
      getDueDateDepositContract,
      params
    );

    if (response?.statusCode === 0) {
      yield put(getDueDateDepositContractSuccess(response));
    } else {
      yield put(getDueDateDepositContractFailure());
      toast(response?.errorCode, "error", response?.message, true);
    }
  } catch (error: any) {
    yield put(getDueDateDepositContractFailure());
    toast(error?.code, "error", error?.description, true);
  }
}
function* searchDepositContractWatcher() {
  yield takeLatest(
    searchDepositContractRequest.type,
    searchDepositContractWorker
  );
}
function* findDepositPaymentWatcher() {
  yield takeLatest(findDepositPaymentRequest.type, findDepositPaymentWorker);
}
function* findDepositContractWatcher() {
  yield takeLatest(findDepositContractRequest.type, findDepositContractWorker);
}

function* liquidateDepositContractWatcher() {
  yield takeLatest(
    liquidateDepositContractRequest.type,
    liquidateDepositContractWorker
  );
}
function* updateLiquidateModalStatusWatcher() {
  yield takeLatest(
    updateLiquidateModalStatusRequest.type,
    updateLiquidateModalStatusWorker
  );
}
function* updateDetailModalStatusWatcher() {
  yield takeLatest(
    updateDetailModalStatusRequest.type,
    updateDetailModalStatusWorker
  );
}
function* updateModalConfirmWatcher() {
  yield takeLatest(updateModalConfirmRequest.type, updateModalConfirmWorker);
}

function* getEarlyInterestRateWatcher() {
  yield takeLatest(
    getEarlyInterestRateRequest.type,
    getEarlyInterestRateWorker
  );
}

function* getInterestAmountWatcher() {
  yield takeLatest(getInterestAmountRequest.type, getInterestAmountWorker);
}
function* getInterestAmount2Watcher() {
  yield takeLatest(getInterestAmount2Request.type, getInterestAmount2Worker);
}
// function* createContractWatcher() {
//   yield takeLatest(createContractRequest.type, createContractWorker);
// }
function* updateDepositPaymentWatcher() {
  yield takeLatest(
    updateDepositPaymentRequest.type,
    updateDepositPaymentWorker
  );
}
function* updateDepositContractWatcher() {
  yield takeLatest(
    updateDepositContractRequest.type,
    updateDepositContractWorker
  );
}

function* getDepositInquiryWatcher() {
  yield takeLatest(getDepositInquiryRequest.type, getDepositInquiryWorker);
}

function* getDueDateDepositContractWatcher() {
  yield takeLatest(
    getDueDateDepositContractRequest.type,
    getDueDateDepositContractWorker
  );
}
export default function* depositContractLimitSaga() {
  yield all([
    searchDepositContractWatcher(),
    liquidateDepositContractWatcher(),
    updateLiquidateModalStatusWatcher(),
    updateDetailModalStatusWatcher(),
    findDepositPaymentWatcher(),
    findDepositContractWatcher(),
    updateModalConfirmWatcher(),
    getEarlyInterestRateWatcher(),
    getInterestAmountWatcher(),
    getInterestAmount2Watcher(),
    updateDepositPaymentWatcher(),
    updateDepositContractWatcher(),
    getDepositInquiryWatcher(),
    getDueDateDepositContractWatcher(),
    // createContractWatcher(),
  ]);
}
