import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { InputPlaceOrder } from "components/commons";
import { SearchTicker, TextSearch, TextItem, HeaderSearch } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { getMarketLabel } from "helper/utils";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { tickerListSelector } from "modules/categories/selectors";
import orderBy from "lodash/orderBy";
import { changeSelectedTicker } from "modules/market/redux";
import { toast } from "components/commons/Toast";

function TickerSearch() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const refInput = useRef<HTMLInputElement>(null);
  const tickerList = useSelector(tickerListSelector);
  const [textSearch, setTextSearch] = useState("");
  const [visibleTextSearch, setVisibleTextSearch] = useState(false);

  const onSearchTicker = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value.toUpperCase());
  };
  const onChangeTicker = (tickerCode: string) => {
    dispatch(changeSelectedTicker(tickerCode));
  };

  const handleKeyDown = (
    e: any,
    tickerSortCodeShare: MrktSecInfoItem.AsObject[]
  ) => {
    if (e.key === "Enter" || e.key === "Tab") {
      if (textSearch.length <= 2) return;
      e.stopPropagation();
      const item = tickerSortCodeShare?.filter((ticker) =>
        ticker.seccd.includes(textSearch.toUpperCase())
      )[0];
      if (item) {
        onChangeTicker(item.seccd);
      } else {
        toast("widgets.placeOrder.errorTickerIllegal", "error");
      }
      setTextSearch("");
      setVisibleTextSearch(false);
    }
  };

  //filter by text input
  const tickerFilter = tickerList.filter((value) =>
    value.seccd.includes(textSearch.toUpperCase())
  );

  const tickerSortCodeShare: MrktSecInfoItem.AsObject[] = orderBy(
    tickerFilter,
    ["sectype", "seccd"]
  );

  return (
    <HeaderSearch>
      <SearchTicker>
        <InputPlaceOrder
          innerRef={refInput}
          placeholder={intl.formatMessage({
            id: "common.tickerTracking",
          })}
          value={textSearch}
          onChange={onSearchTicker}
          onKeyDown={(e) => handleKeyDown(e, tickerSortCodeShare)}
          isSearch
          onFocus={() => {
            setVisibleTextSearch(true);
          }}
          onBlur={() => {
            setTimeout(() => setVisibleTextSearch(false), 150);
          }}
        />
        {(visibleTextSearch || textSearch) && (
          <TextSearch>
            {tickerSortCodeShare.map((item) => (
              <TextItem
                className="ticker-item"
                key={item.seccd}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  setVisibleTextSearch(false);
                  setTextSearch("");
                  onChangeTicker(item.seccd);
                }}
              >
                <div>
                  <span className="share-code">{item.seccd} </span>
                  {item.secname !== "" || item.secnameen !== "" ? " - " : ""}
                  <span>{item.secname || item.secnameen}</span>
                </div>
                <div>{getMarketLabel(item.marketcd)}</div>
              </TextItem>
            ))}
          </TextSearch>
        )}
      </SearchTicker>
    </HeaderSearch>
  );
}

export default TickerSearch;
