import {
  OrderSellAll,
  OrderSingleParams,
} from "core/http/apis/orderexec/types";
import { GetStockPriceInterface } from "domain/models/SecuritiesStatusInterface";
import { getStockPrice } from "core/http/apis/tradeinfo";
import {
  MarketSession,
  OrderActionType,
  OrderStatusDetailType,
  OrderTypeVal,
  EMarketCodeNew,
  TRADING_CONST,
  VolumeLot,
  SecType,
  OrderStatusDerCondDetailType,
  OrderStatusCondDetailType,
} from "./consts";
import {
  IOrderBookCondItem,
  OrderBookDisplay,
  OrderBookItem,
} from "domain/models/OrderBook";
import GlobalData from "./globalData";
import { regPrice, regVolumn } from "helper/consts";
import { IntlShape } from "react-intl";
import numeral from "numeral";
import { SingleOrderParamUpdate } from "modules/order-book/redux/types";
import {
  OrderBookDerCond,
  OrderBookDerCondDisplay,
  OrderBookDerDisplay,
  OrderBookDerItem,
} from "domain/models/OrderBookDer";
// import { values } from "lodash";

// Check đặt lệnh cơ sở
export const checkPlaceOrder = async (
  params: OrderSingleParams | OrderSellAll,
  intl: IntlShape
): Promise<string | null> => {
  try {
    if (params == null) {
      return intl.formatMessage({ id: "tradingHelper.checkPlaceOrder" });
    }

    const symbolData = await getStockPrice(params.symbol);

    if (symbolData.statusCode !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.symbolDataNotFound" },
        { symbol: params.symbol }
      );
    }

    // Check bước giá
    let error = await checkPriceStep(symbolData.data, params.price, intl);
    if (error) {
      return error;
    }

    // Check khối lượng tròn lô
    error = await checkVolumeLot(symbolData.data, params.orderQty, intl);
    if (error) {
      return error;
    }

    // Check phiên
    const orderTypeDisp = params.type;
    error = await checkTradingSession(
      symbolData.data,
      orderTypeDisp,
      params.orderQty,
      intl
    );

    if (error) {
      return error;
    }

    return null;
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Check bước giá
export const checkPriceStepBySymbol = async (
  symbol: string,
  price: number,
  intl: IntlShape
): Promise<string | null> => {
  try {
    const symbolData = await getStockPrice(symbol);

    if (symbolData.statusCode !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.symbolDataNotFound" },
        { symbol }
      );
    }

    return checkPriceStep(symbolData.data, price, intl);
  } catch (error: any) {
    throw error;
  }
};

// Check bước giá
export const checkPriceStep = async (
  symbolData: GetStockPriceInterface,
  price: number,
  intl: IntlShape
): Promise<string | null> => {
  const marketCode: number = symbolData.marketCd;
  const PRICE_STEP = {
    s01: 0.1,
    s1: 1,
    s10: 10,
    s50: 50,
    s100: 100,
  };

  // Check giá cơ sỏ => Cho về int tránh lẻ
  // const resultPrice: number = Number(price);
  // const equityPriceToCheck = Math.round((resultPrice + Number.EPSILON) * 100) / 100;
  const equityPriceToCheck = price;

  // Check giá phái sinh để nguyên
  const derPriceToCheck = price;

  const ticker = GlobalData.getTickerInfoNew(symbolData.secCd);

  if (ticker.sectype === SecType.E) {
    if (equityPriceToCheck % PRICE_STEP.s10 !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.invalidPriceStep" },
        { priceStep: PRICE_STEP.s10 }
      );
    } else {
      return null;
    }
  }

  if (ticker.sectype === SecType.D) {
    if (equityPriceToCheck % PRICE_STEP.s1 !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.invalidPriceStep" },
        { priceStep: PRICE_STEP.s1 }
      );
    } else {
      return null;
    }
  }

  switch (marketCode) {
    case EMarketCodeNew.HSX_IDX:
      // Bước giá theo khoảng
      if (equityPriceToCheck < 10000) {
        // Bước giá 10đ
        if (equityPriceToCheck % PRICE_STEP.s10 !== 0) {
          return intl.formatMessage(
            { id: "tradingHelper.invalidPriceStep" },
            { priceStep: PRICE_STEP.s10 }
          );
        }
      } else if (equityPriceToCheck >= 10000 && equityPriceToCheck < 50000) {
        // Bước giá 50đ
        if (equityPriceToCheck % PRICE_STEP.s50 !== 0) {
          return intl.formatMessage(
            { id: "tradingHelper.invalidPriceStep" },
            { priceStep: PRICE_STEP.s50 }
          );
        }
      } else if (equityPriceToCheck >= 50000.0) {
        // Bước giá 100đ
        if (equityPriceToCheck % PRICE_STEP.s100 !== 0) {
          return intl.formatMessage(
            { id: "tradingHelper.invalidPriceStep" },
            { priceStep: PRICE_STEP.s100 }
          );
        }
      }
      break;
    case EMarketCodeNew.HNX_IDX:
    case EMarketCodeNew.UPCOM_IDX:
      // Bước giá 100đ
      if (equityPriceToCheck % PRICE_STEP.s100 !== 0) {
        return intl.formatMessage(
          { id: "tradingHelper.invalidPriceStep" },
          { priceStep: PRICE_STEP.s100 }
        );
      }
      break;
    case EMarketCodeNew.DER_IDX:
      // nhân 10 rồi check isInteger
      if (!Number.isInteger(derPriceToCheck * 10)) {
        return intl.formatMessage(
          { id: "tradingHelper.invalidPriceStep" },
          { priceStep: PRICE_STEP.s01 }
        );
      }
      break;
    default:
      return intl.formatMessage(
        { id: "tradingHelper.invalidMarketCode" },
        { marketCode }
      );
  }

  return null;
};

// Check tròn lô theo sàn
export const checkVolumeLotBySymbol = async (
  symbol: string,
  volume: any,
  intl: IntlShape
): Promise<string | null> => {
  try {
    const symbolData = await getStockPrice(symbol);
    if (symbolData.statusCode !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.symbolDataNotFound" },
        { symbol }
      );
    }

    return checkVolumeLot(symbolData.data, volume, intl);
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Check tròn lô theo sàn
export const checkVolumeLot = async (
  symbolData: GetStockPriceInterface,
  volume: any,
  intl: IntlShape
): Promise<string | null> => {
  volume = numeral(volume).value();
  if (!regVolumn.test(volume as string)) {
    return intl.formatMessage({ id: "tradingHelper.checkVolumeLot1" });
  }

  const marketCode = symbolData.marketCd;

  switch (marketCode) {
    case EMarketCodeNew.HSX_IDX:
      // Lô chẵn 100, Lô lẻ 1-99
      if (volume > VolumeLot.HSX_VOLUME_LOT) {
        if (volume % VolumeLot.HSX_VOLUME_LOT !== 0) {
          return intl.formatMessage(
            { id: "tradingHelper.checkVolumeLot2" },
            { number: 100 }
          );
        }
      }
      break;
    case EMarketCodeNew.HNX_IDX:
    case EMarketCodeNew.UPCOM_IDX:
      // Lô chẵn 100, Lô lẻ 1-99
      if (volume > VolumeLot.HNX_VOLUME_LOT) {
        if (volume % VolumeLot.HNX_VOLUME_LOT !== 0) {
          return intl.formatMessage(
            { id: "tradingHelper.invalidVolumeLot" },
            { number: 100 }
          );
        }
      }
      break;
    default:
      return intl.formatMessage({ id: "tradingHelper.invalidMarketCode2" });
  }

  return null;
};

// Check phiên khi đặt lệnh
export const checkTradingSessionBySymbol = async (
  symbol: string,
  orderType: string,
  volume: number,
  intl: IntlShape
): Promise<string | null> => {
  try {
    const symbolData = await getStockPrice(symbol);
    if (symbolData.statusCode !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.symbolDataNotFound" },
        { symbol }
      );
    }

    return checkTradingSession(symbolData.data, orderType, volume, intl);
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Check phiên khi đặt lệnh
export const checkTradingSession = async (
  symbolData: GetStockPriceInterface,
  orderType: string,
  volume: number,
  intl: IntlShape
): Promise<string | null> => {
  try {
    if (!symbolData.status) {
      return intl.formatMessage(
        { id: "tradingHelper.invalidTradingSession" },
        { tradingSessionId: symbolData.marketCd }
      );
    }

    // Sàn
    const marketCode = symbolData.marketCd;

    // EOD hoặc CLOSED => Chặn sạch
    if (
      symbolData.status === MarketSession.CLOSED_K ||
      symbolData.status === MarketSession.CLOSED_G ||
      symbolData.status === MarketSession.CLOSED_J ||
      symbolData.status === MarketSession.CLOSED_Z
    ) {
      return intl.formatMessage({ id: "tradingHelper.invalidMarketState" });
    }

    // Phiên RUNOFF => Chỉ cho đặt lệnh PLO với HNX
    if (symbolData.status === MarketSession.RUNOFF) {
      if (marketCode !== EMarketCodeNew.HNX_IDX) {
        if (marketCode === EMarketCodeNew.HSX_IDX) {
          return intl.formatMessage({
            id: "tradingHelper.invalidMarketState19",
          });
        } else {
          return intl.formatMessage({ id: "tradingHelper.invalidMarketState" });
        }
      }
    }

    // Check theo từng sàn
    switch (marketCode) {
      case EMarketCodeNew.HSX_IDX:
        // 9:00 - 9:15: Mở cửa (ATO, LO)
        // 9:15 - 11:30: Khớp lệnh liên tục (LO,MP)
        // 11:30 - 13:00: Nghỉ
        // 13:00 - 14:30: Khớp lệnh liên tục (LO,MP)
        // 14:30 - 14:45: Đóng cửa (ATC, LO)
        if (symbolData.status === MarketSession.BEFORE) {
          if (
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.ATO &&
            orderType !== OrderTypeVal.ATC
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState2",
            });
          }
        } else if (symbolData.status === MarketSession.ATO) {
          if (
            orderType !== OrderTypeVal.ATO &&
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.ATC
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState3",
            });
          }
        } else if (symbolData.status === MarketSession.OPEN) {
          if (
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.MP &&
            orderType !== OrderTypeVal.ATC
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState4",
            });
          }
        } else if (symbolData.status === MarketSession.BREAK) {
          if (
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.ATC &&
            orderType !== OrderTypeVal.MP
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState5",
            });
          }
        } else if (symbolData.status === MarketSession.ATC) {
          if (orderType !== OrderTypeVal.LO && orderType !== OrderTypeVal.ATC) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState6",
            });
          }
        } else if (
          symbolData.status !== MarketSession.BEFORE &&
          symbolData.status !== MarketSession.ATO &&
          symbolData.status !== MarketSession.OPEN &&
          symbolData.status !== MarketSession.BREAK &&
          symbolData.status !== MarketSession.ATC &&
          symbolData.status !== MarketSession.RUNOFF &&
          (symbolData.status === MarketSession.CLOSED_K ||
            symbolData.status === MarketSession.CLOSED_G ||
            symbolData.status === MarketSession.CLOSED_J ||
            symbolData.status === MarketSession.CLOSED_Z)
        ) {
          return intl.formatMessage({
            id: "tradingHelper.invalidSessionStateUnknow",
          });
        }
        break;
      case EMarketCodeNew.HNX_IDX:
        //        9:00 - 11:30: Khớp lệnh liên tục (LO, MOK, MAK, MTL)
        //        11:30 - 13:00: Nghỉ
        //        13:00 - 14:30: Khớp lệnh liên tục (LO, MOK, MAK, MTL)
        //        14:30 - 14:45: Đóng cửa (ATC, LO)
        //        14:45 - 15:00: Sau đóng cửa (PLO)
        if (symbolData.status === MarketSession.BEFORE) {
          if (orderType !== OrderTypeVal.LO && orderType !== OrderTypeVal.ATC) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState7",
            });
          }
        } else if (symbolData.status === MarketSession.OPEN) {
          if (
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.MOK &&
            orderType !== OrderTypeVal.MAK &&
            orderType !== OrderTypeVal.MTL &&
            orderType !== OrderTypeVal.MP &&
            orderType !== OrderTypeVal.PLO &&
            orderType !== OrderTypeVal.ATC
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState8",
            });
          }
        } else if (symbolData.status === MarketSession.BREAK) {
          if (
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.PLO &&
            orderType !== OrderTypeVal.ATC &&
            orderType !== OrderTypeVal.MTL &&
            orderType !== OrderTypeVal.MOK &&
            orderType !== OrderTypeVal.MAK
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState9",
            });
          }
        } else if (symbolData.status === MarketSession.ATC) {
          if (
            orderType !== OrderTypeVal.LO &&
            orderType !== OrderTypeVal.ATC &&
            orderType !== OrderTypeVal.PLO
          ) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState10",
            });
          }
        } else if (symbolData.status === MarketSession.RUNOFF) {
          if (orderType !== OrderTypeVal.PLO) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState11",
            });
          }
        } else if (
          symbolData.status !== MarketSession.BEFORE &&
          symbolData.status !== MarketSession.OPEN &&
          symbolData.status !== MarketSession.BREAK &&
          symbolData.status !== MarketSession.ATC &&
          symbolData.status !== MarketSession.RUNOFF &&
          (symbolData.status === MarketSession.CLOSED_K ||
            symbolData.status === MarketSession.CLOSED_G ||
            symbolData.status === MarketSession.CLOSED_J ||
            symbolData.status === MarketSession.CLOSED_Z)
        ) {
          return intl.formatMessage({
            id: "tradingHelper.invalidSessionStateUnknow",
          });
        }

        // Lô lẻ => Phiên
        const isOddLot: boolean = volume < 100;
        if (isOddLot) {
          // Chỉ được đặt LO
          if (orderType !== OrderTypeVal.LO) {
            return intl.formatMessage({
              id: "tradingHelper.invalidMarketState12",
            });
          }

          // // Lô lẻ => Phiên
          // if (
          //   symbolData.status !== MarketSession.OPEN_HNX &&
          //   symbolData.status !== MarketSession.BREAK_HNX &&
          //   symbolData.status !== MarketSession.BEFORE_HNX
          // ) {
          //   return intl.formatMessage({
          //     id: "tradingHelper.invalidMarketState13",
          //   });
          // }
        }

        break;
      case EMarketCodeNew.UPCOM_IDX:
        // 9:00 - 11:30: Khớp lệnh liên tục (LO)
        // 11:30 - 13:00: Nghỉ
        // 13:00 - 14:30: Khớp lệnh liên tục (LO)
        if (orderType !== OrderTypeVal.LO) {
          return intl.formatMessage({
            id: "tradingHelper.invalidMarketState14",
          });
        } else if (
          symbolData.status !== MarketSession.OPEN &&
          symbolData.status !== MarketSession.BREAK &&
          (symbolData.status === MarketSession.CLOSED_K ||
            symbolData.status === MarketSession.CLOSED_G ||
            symbolData.status === MarketSession.CLOSED_J ||
            symbolData.status === MarketSession.CLOSED_Z)
        ) {
          return intl.formatMessage({
            id: "tradingHelper.invalidSessionStateUnknow",
          });
        }

        break;
      default:
        return intl.formatMessage({ id: "tradingHelper.invalidMarketState18" });
    }

    return null;
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Check khi Sửa lệnh Cơ sở/Phái sinh
export const checkEditOrder = async (
  orderData: OrderBookItem | OrderBookDisplay,
  input: SingleOrderParamUpdate,
  intl: IntlShape
): Promise<string | null> => {
  try {
    if (orderData === null || input === null) {
      return intl.formatMessage({ id: "tradingHelper.invalidOrderData" });
    }

    const session = await getStockPrice(orderData?.secCd!);
    // Sàn
    // const marketCode = getGmcMarketCode(tradeplace);
    if (session.statusCode !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.symbolDataNotFound" },
        { symbol: orderData?.secCd }
      );
    }

    // Phiên đóng cửa => Lượn
    if (
      session?.data?.status === MarketSession.CLOSED_K ||
      session?.data?.status === MarketSession.CLOSED_G ||
      session?.data?.status === MarketSession.CLOSED_J ||
      session?.data?.status === MarketSession.CLOSED_Z
    ) {
      return intl.formatMessage({ id: "tradingHelper.marketSessionCloseErr" });
    }

    const symbolData = await getStockPrice(orderData.secCd!);
    if (symbolData.statusCode !== 0) {
      return intl.formatMessage(
        { id: "tradingHelper.couldNotGetStockCodeInformationErr" },
        { symbol: orderData.secCd }
      );
    }

    // Old
    const oldPrice = numeral(orderData.ordPrice! * 1000).value() ?? 0;
    const oldQty = numeral(orderData.ordQty!).value() ?? 0;

    // New
    const newPrice = numeral(input.price!).value() ?? 0;
    const newQty = numeral(input.qty!).value() ?? 0;

    // Sửa giá, KL
    const isEditPrice = oldPrice !== newPrice;
    const isEditQty = oldQty !== newQty;

    // KL khớp
    const matQty = numeral(orderData.matQty).value() ?? 0;

    // Dữ liệu không đổi
    if (!isEditQty && !isEditPrice) {
      return intl.formatMessage({ id: "tradingHelper.dataDoesNotChange" });
    }

    // Sửa giá
    if (isEditPrice && numeral(input.price).value() !== null) {
      if (newPrice > Number(symbolData?.data?.ceilingPrice)) {
        return intl.formatMessage({ id: "tradingHelper.invalidPrice19" });
      }

      if (newPrice < Number(symbolData?.data?.floorPrice)) {
        return intl.formatMessage({ id: "tradingHelper.invalidPrice20" });
      }
    }

    // Nếu sửa KL
    if (isEditQty) {
      if (newQty <= 0) {
        return intl.formatMessage({ id: "tradingHelper.invalidVolumne" });
      }

      // Sửa KL xuống => check phần đã
      if (newQty < oldQty && newQty <= matQty) {
        return intl.formatMessage({ id: "tradingHelper.invalidVolumne2" });
      }
    }

    // ForceSell, Thỏa thuận => Ko được sửa hủy mọi trường hợp
    // if (
    //   orderData.side === "E" ||
    //   orderData.crossType === 1 ||
    //   orderData.crossType === 2
    // ) {
    //   return intl.formatMessage({
    //     id: "tradingHelper.doNotModifyOrCancelThisOrderErr",
    //   });
    // }

    if (
      orderData.ordType !== OrderTypeVal.MP &&
      orderData.ordType !== OrderTypeVal.LO
    ) {
      return intl.formatMessage({
        id: "tradingHelper.doNotModifyOrCancelThisOrderErr",
      });
    }

    // Ko cho sửa đồng thời Giá và KL
    // if (isEditPrice && isEditQty) {
    //   return intl.formatMessage({ id: "tradingHelper.invalidPriceAndQty" });
    // }

    // HSX => Ko cho sửa KL
    // if (marketCode === EMarketCodeNew.HSX_IDX && isEditQty) {
    //   return intl.formatMessage({ id: "tradingHelper.invalidMarketCd" });
    // }

    // Ko cho sửa KL lên
    // if (newQty > oldQty) {
    //   return intl.formatMessage({ id: "tradingHelper.invalidVolumne8" });
    // }

    // Check bước giá
    if (isEditPrice) {
      let error = await checkPriceStep(symbolData.data, newPrice * 1000, intl);
      if (error) {
        return error;
      }
    }

    //Check khối lượng tròn lô
    if (isEditQty) {
      let error = await checkVolumeLot(symbolData.data, newQty, intl);
      if (error) {
        return error;
      }
      //Check KL lô chẵn, lẻ sàn HNX và UPCOM
      const marketCode = symbolData?.data?.marketCd;
      if (
        marketCode === EMarketCodeNew.HNX_IDX ||
        marketCode === EMarketCodeNew.UPCOM_IDX
      ) {
        if (
          (oldQty < VolumeLot.HNX_VOLUME_LOT &&
            newQty >= VolumeLot.HNX_VOLUME_LOT) ||
          (oldQty >= VolumeLot.HNX_VOLUME_LOT &&
            newQty < VolumeLot.HNX_VOLUME_LOT)
        ) {
          return intl.formatMessage({
            id: "tradingHelper.invalidVolumeLotEdit",
          });
        }
      }
    }

    // Check trạng thái lệnh, phiên
    let error = await checkEditOrderStatus(
      orderData,
      session?.data?.status,
      OrderActionType.EDIT,
      intl
    );

    if (error) {
      return error;
    }

    return null;
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Chặn trạng thái lệnh Cơ sở khi Sửa lệnh
export const checkEditOrderStatus = async (
  orderData:
    | OrderBookItem
    | OrderBookDisplay
    | OrderBookDerItem
    | OrderBookDerDisplay,
  session: string,
  actionType: string,
  intl: IntlShape
): Promise<string | null> => {
  try {
    const status = getOrderStatus(orderData);

    // Phiên KLĐK => Ko cho Sửa/Hủy các lệnh đã vào sàn
    if (session === MarketSession.ATO || session === MarketSession.ATC) {
      if (status !== OrderStatusDetailType.ENTERED) {
        return intl.formatMessage({ id: "tradingHelper.checkEditOrderStatus" });
      }
    }

    switch (status) {
      // Lệnh tại công ty
      case OrderStatusDetailType.ENTERED:
        // Lệnh chờ tại công ty

        // Không cho sửa
        // if (actionType === OrderActionType.EDIT) {
        //   return intl.formatMessage({
        //     id: "tradingHelper.checkEditOrderStatus2",
        //   });
        // }

        // Chỉ cho hủy ở các phiên: BOD, ATO, OPEN, ATC
        if (
          // session !== MarketSession.BEFORE_HSX &&
          // session !== MarketSession.BEFORE_HNX &&
          // session !== MarketSession.ATO &&
          // session !== MarketSession.OPEN_HSX &&
          // session !== MarketSession.OPEN_HNX &&
          // session !== MarketSession.OPEN_UPCOM &&
          // session !== MarketSession.ATC_HSX &&
          // session !== MarketSession.ATC_HNX
          session === MarketSession.CLOSED_K ||
          session === MarketSession.CLOSED_G ||
          session === MarketSession.CLOSED_J ||
          session === MarketSession.CLOSED_Z
        ) {
          return intl.formatMessage({
            id: "tradingHelper.checkEditOrderStatus2",
          });
        }
        break;
      // case OrderStatusDetailType.PENDING_CANCEL:
      //   // Lệnh chờ hủy => Lượn
      //   return intl.formatMessage({
      //     id: "tradingHelper.checkEditOrderStatus2",
      //   });
      case OrderStatusDetailType.CANCELLED:
        // Lệnh đã hủy => Lượn
        return intl.formatMessage({
          id: "tradingHelper.checkEditOrderStatus2",
        });
      // case OrderStatusDetailType.SENT:
      //   // Lệnh đã vào sở
      //   if (
      //     session !== MarketSession.OPEN_HSX &&
      //     session !== MarketSession.OPEN_HNX &&
      //     session !== MarketSession.OPEN_UPCOM &&
      //     session !== MarketSession.BREAK_HSX &&
      //     session !== MarketSession.BREAK_HNX &&
      //     session !== MarketSession.BREAK_UPCOM &&
      //     session !== MarketSession.RUNOFF_HSX &&
      //     session !== MarketSession.RUNOFF_HNX
      //   ) {
      //     return intl.formatMessage({
      //       id: "tradingHelper.checkEditOrderStatus2",
      //     });
      //   }
      //   break;
      case OrderStatusDetailType.PARTIALLY_FILLED:
        // Khớp 1 phần
        if (
          session !== MarketSession.OPEN &&
          session !== MarketSession.BREAK &&
          session !== MarketSession.RUNOFF
        ) {
          return intl.formatMessage({
            id: "tradingHelper.checkEditOrderStatus2",
          });
        }
        break;
      case OrderStatusDetailType.FILLED:
        // Khớp toàn phần  => Lượn
        return intl.formatMessage({
          id: "tradingHelper.checkEditOrderStatus2",
        });
      // case OrderStatusDetailType.EXPIRED:
      //   // Từ chối, hết hiệu lực => Lượn
      //   return intl.formatMessage({
      //     id: "tradingHelper.checkEditOrderStatus2",
      //   });
      default:
        // Pass hết rule trong tài liệu => Ko check, để phần server, kẻo check thừa lại bị kỹ quá
        break;
    }

    return null;
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Chặn trạng thái lệnh Cơ sở khi Sửa lệnh
export const checkEditOrderDerCondStatus = async (
  orderData: OrderBookDerCond | OrderBookDerCondDisplay,
  session: string,
  actionType: string,
  intl: IntlShape
): Promise<string | null> => {
  try {
    const status = getOrderDerCondStatus(orderData);

    // Phiên KLĐK => Ko cho Sửa/Hủy các lệnh đã vào sàn
    if (session === MarketSession.ATO || session === MarketSession.ATC) {
      if (status !== OrderStatusDetailType.ENTERED) {
        return intl.formatMessage({ id: "tradingHelper.checkEditOrderStatus" });
      }
    }

    switch (status) {
      // Lệnh tại công ty
      case OrderStatusDetailType.ENTERED:
        // Lệnh chờ tại công ty

        // Không cho sửa
        // if (actionType === OrderActionType.EDIT) {
        //   return intl.formatMessage({
        //     id: "tradingHelper.checkEditOrderStatus2",
        //   });
        // }

        // Chỉ cho hủy ở các phiên: BOD, ATO, OPEN, ATC
        if (
          // session !== MarketSession.BEFORE_HSX &&
          // session !== MarketSession.BEFORE_HNX &&
          // session !== MarketSession.ATO &&
          // session !== MarketSession.OPEN_HSX &&
          // session !== MarketSession.OPEN_HNX &&
          // session !== MarketSession.OPEN_UPCOM &&
          // session !== MarketSession.ATC_HSX &&
          // session !== MarketSession.ATC_HNX
          session === MarketSession.CLOSED_K ||
          session === MarketSession.CLOSED_G ||
          session === MarketSession.CLOSED_J ||
          session === MarketSession.CLOSED_Z
        ) {
          return intl.formatMessage({
            id: "tradingHelper.checkEditOrderStatus2",
          });
        }
        break;
      // case OrderStatusDetailType.PENDING_CANCEL:
      //   // Lệnh chờ hủy => Lượn
      //   return intl.formatMessage({
      //     id: "tradingHelper.checkEditOrderStatus2",
      //   });
      case OrderStatusDetailType.CANCELLED:
        // Lệnh đã hủy => Lượn
        return intl.formatMessage({
          id: "tradingHelper.checkEditOrderStatus2",
        });
      // case OrderStatusDetailType.SENT:
      //   // Lệnh đã vào sở
      //   if (
      //     session !== MarketSession.OPEN_HSX &&
      //     session !== MarketSession.OPEN_HNX &&
      //     session !== MarketSession.OPEN_UPCOM &&
      //     session !== MarketSession.BREAK_HSX &&
      //     session !== MarketSession.BREAK_HNX &&
      //     session !== MarketSession.BREAK_UPCOM &&
      //     session !== MarketSession.RUNOFF_HSX &&
      //     session !== MarketSession.RUNOFF_HNX
      //   ) {
      //     return intl.formatMessage({
      //       id: "tradingHelper.checkEditOrderStatus2",
      //     });
      //   }
      //   break;
      case OrderStatusDetailType.PARTIALLY_FILLED:
        // Khớp 1 phần
        if (
          session !== MarketSession.OPEN &&
          session !== MarketSession.BREAK &&
          session !== MarketSession.RUNOFF
        ) {
          return intl.formatMessage({
            id: "tradingHelper.checkEditOrderStatus2",
          });
        }
        break;
      case OrderStatusDetailType.FILLED:
        // Khớp toàn phần  => Lượn
        return intl.formatMessage({
          id: "tradingHelper.checkEditOrderStatus2",
        });
      // case OrderStatusDetailType.EXPIRED:
      //   // Từ chối, hết hiệu lực => Lượn
      //   return intl.formatMessage({
      //     id: "tradingHelper.checkEditOrderStatus2",
      //   });
      default:
        // Pass hết rule trong tài liệu => Ko check, để phần server, kẻo check thừa lại bị kỹ quá
        break;
    }

    return null;
  } catch (error: any) {
    //  console.log(error);
    throw error;
  }
};

// Check khi Hủy lệnh
export const checkCancelOrder = async (
  orderData: OrderBookItem | OrderBookDisplay,
  intl: IntlShape
): Promise<string | null> => {
  if (orderData === null) {
    return intl.formatMessage({
      id: "tradingHelper.checkCancelOrder",
    });
  }

  // Lấy lại session mới nhất
  const session = await getStockPrice(orderData.secCd!);
  if (session.statusCode !== 0) {
    return intl.formatMessage(
      { id: "tradingHelper.symbolDataNotFound" },
      { symbol: orderData?.secCd }
    );
  }

  // Phiên đóng cửa => Lượn
  if (
    session?.data?.status === MarketSession.CLOSED_K ||
    session?.data?.status === MarketSession.CLOSED_G ||
    session?.data?.status === MarketSession.CLOSED_J ||
    session?.data?.status === MarketSession.CLOSED_Z
  ) {
    return intl.formatMessage({
      id: "tradingHelper.invalidTradingSession2",
    });
  }

  // Cơ sở
  // Check trạng thái lệnh, phiên
  const checkEditResult = await checkEditOrderStatus(
    orderData,
    session?.data?.status,
    OrderActionType.CANCEL,
    intl
  );

  if (checkEditResult != null) {
    return checkEditResult;
  }

  return null;
};

// Check khi Hủy lệnh phái sinh
export const checkCancelOrderDer = async (
  orderData: OrderBookDerItem | OrderBookDerDisplay,
  intl: IntlShape
): Promise<string | null> => {
  if (orderData === null) {
    return intl.formatMessage({
      id: "tradingHelper.checkCancelOrder",
    });
  }

  // Lấy lại session mới nhất
  const session = await getStockPrice(orderData.secCd!);
  if (session.statusCode !== 0) {
    return intl.formatMessage(
      { id: "tradingHelper.symbolDataNotFound" },
      { symbol: orderData?.secCd }
    );
  }

  // Phiên đóng cửa => Lượn
  if (
    session?.data?.status === MarketSession.CLOSED_K ||
    session?.data?.status === MarketSession.CLOSED_G ||
    session?.data?.status === MarketSession.CLOSED_J ||
    session?.data?.status === MarketSession.CLOSED_Z
  ) {
    return intl.formatMessage({
      id: "tradingHelper.invalidTradingSession2",
    });
  }

  // Cơ sở
  // Check trạng thái lệnh, phiên
  const checkEditResult = await checkEditOrderStatus(
    orderData,
    session?.data?.status,
    OrderActionType.CANCEL,
    intl
  );

  if (checkEditResult != null) {
    return checkEditResult;
  }

  return null;
};

// Check khi Hủy lệnh phái sinh dieu kien
export const checkCancelOrderDerCond = async (
  orderData: OrderBookDerCond | OrderBookDerCondDisplay,
  intl: IntlShape
): Promise<string | null> => {
  if (orderData === null) {
    return intl.formatMessage({
      id: "tradingHelper.checkCancelOrder",
    });
  }

  // Lấy lại session mới nhất
  const session = await getStockPrice(orderData.secCd!);
  if (session.statusCode !== 0) {
    return intl.formatMessage(
      { id: "tradingHelper.symbolDataNotFound" },
      { symbol: orderData?.secCd }
    );
  }

  // Phiên đóng cửa => Lượn
  if (
    session?.data?.status === MarketSession.CLOSED_K ||
    session?.data?.status === MarketSession.CLOSED_G ||
    session?.data?.status === MarketSession.CLOSED_J ||
    session?.data?.status === MarketSession.CLOSED_Z
  ) {
    return intl.formatMessage({
      id: "tradingHelper.invalidTradingSession2",
    });
  }

  // Cơ sở
  // Check trạng thái lệnh, phiên
  const checkEditResult = await checkEditOrderDerCondStatus(
    orderData,
    session?.data?.status,
    OrderActionType.CANCEL,
    intl
  );

  if (checkEditResult != null) {
    return checkEditResult;
  }

  return null;
};

// Lấy trạng thái lệnh
export const getOrderStatus = (
  orderData:
    | OrderBookItem
    | OrderBookDisplay
    | OrderBookDerItem
    | OrderBookDerDisplay
): string => {
  if (orderData === null) {
    return OrderStatusDetailType.NONE;
  }

  switch (orderData.extStatus) {
    case 1:
      return OrderStatusDetailType.REJECTED;
    case 2:
      return OrderStatusDetailType.ENTERED;
    case 3:
      return OrderStatusDetailType.WAIT_FILLED;
    case 4:
      return OrderStatusDetailType.PARTIALLY_FILLED;
    case 5:
      return OrderStatusDetailType.FILLED;
    case 6:
      return OrderStatusDetailType.PARTIALLY_CANCELLED;
    case 7:
      return OrderStatusDetailType.CANCELLED;
    case 8:
      return OrderStatusDetailType.REJECTED_DEPARTMENT;
    default:
      return OrderStatusDetailType.NONE;
  }
};

// Lấy trạng thái lệnh phai sinh dieu kien
export const getOrderDerCondStatus = (
  orderData: OrderBookDerCond | OrderBookDerCondDisplay
): string => {
  if (orderData === null) {
    return OrderStatusDetailType.NONE;
  }

  switch (orderData.status) {
    case 1:
      return OrderStatusDerCondDetailType.WAIT_ACTIVE;
    case 2:
      return OrderStatusDerCondDetailType.FINISHED;
    case 3:
      return OrderStatusDerCondDetailType.EXPIRE;
    case 9:
      return OrderStatusDerCondDetailType.CANCELED;
    default:
      return OrderStatusDerCondDetailType.NONE;
  }
};

// Lấy trạng thái lệnh phai sinh dieu kien
export const getOrderCondStatus = (orderData: IOrderBookCondItem): string => {
  if (orderData === null) {
    return OrderStatusCondDetailType.NONE;
  }

  switch (orderData.status) {
    case 1:
      return OrderStatusCondDetailType.EFFECT;
    case 2:
      return OrderStatusCondDetailType.FINISHED;
    case 3:
      return OrderStatusCondDetailType.EXPIRE;
    case 9:
      return OrderStatusCondDetailType.CANCELED;
    default:
      return OrderStatusCondDetailType.NONE;
  }
};

// Lấy hiển thị trạng thái lệnh
/**
 *   MÀU - HIỂN THỊ
 *   Chờ khớp	  Màu vàng
 *   Khớp một phần	F	1	Theo % Khớp khớp đến đâu xanh đến đó còn lại màu vàng
 *   Đã khớp	  Màu xanh
 *   Đã huỷ	  Màu đỏ
 *   Từ chối	  Màu đỏ
 *   Đã sửa	  Màu vàng
 *   Đang chờ	Màu vàng
 *   Chờ hủy	  Màu vàng
 *   Từ chối sửa	  Màu vàng
 *   Chờ sửa	  Màu vàng
 *   Từ chối hủy	  Màu vàng
 */
export const getOrderStatusDisp = (
  orderData: OrderBookDisplay | OrderBookItem | OrderBookDerItem
): string => {
  const orderStatusVal = getOrderStatus(orderData);
  return `orderBook.${orderStatusVal}`;
};

export const getOrderStatusDerCondDisp = (
  orderData: OrderBookDerCond
): string => {
  const orderStatusVal = getOrderDerCondStatus(orderData);
  return `orderBook.${orderStatusVal}`;
};

export const getOrderStatusCondDisp = (
  orderData: IOrderBookCondItem
): string => {
  const orderStatusVal = getOrderCondStatus(orderData);
  return `orderBook.${orderStatusVal}`;
};

// Các loại giá cho phép đặt theo phiên
export const getSuggestionOrderTypes = (
  tradingSessionId: number, // Sàn
  tradingSession: String // Phiên
): string[] => {
  // Cơ sở
  switch (tradingSessionId) {
    case EMarketCodeNew.HSX_IDX:
      switch (tradingSession) {
        // Trước giờ
        case MarketSession.BEFORE:
          return ["ATO", "ATC"];
        case MarketSession.ATO:
          return ["ATO", "ATC", "MP"];
        case MarketSession.OPEN:
          return ["MP", "ATC"];
        case MarketSession.BREAK:
        case MarketSession.ATC:
        case MarketSession.RUNOFF:
          return ["ATC"];
        default:
          return [];
      }

    case EMarketCodeNew.HNX_IDX:
      switch (tradingSession) {
        case MarketSession.BEFORE:
          return ["ATC", "PLO"];
        case MarketSession.OPEN:
          return ["ATC", "MOK", "MAK", "MTL", "PLO"];
        case MarketSession.BREAK:
          return ["ATC", "PLO", "MTL", "MOK", "MAK"];
        case MarketSession.ATC:
        case MarketSession.RUNOFF:
          return ["PLO"];
        default:
          return [];
      }

    case EMarketCodeNew.UPCOM_IDX:
      return [];

    case EMarketCodeNew.DER_IDX:
      switch (tradingSession) {
        case MarketSession.BEFORE:
        case MarketSession.ATO:
          return ["ATO", "ATC"];
        case MarketSession.OPEN:
          return ["ATC", "MOK", "MAK", "MTL"];
        case MarketSession.ATC:
        case MarketSession.BREAK:
          return ["ATC"];
        default:
          return [];
      }
    default:
      return [];
  }
};

// Nhấn nút + Giá
export const incrementPrice = (
  symbol: string,
  price: number | string,
  max?: number,
  min?: number,
  isOneNumberAfterComma?: boolean
): number | string => {
  if (!symbol) {
    return price;
  }

  if (!regPrice.test(price as string) && numeral(price).value() === null) {
    if (price === "") {
      price = 0;
    } else {
      return price;
    }
  }

  price = numeral(price).value()!;

  if (min) {
    if (price === 0) {
      price = numeral(min).value()!;
      return price;
    }
  }

  // Parse sang number
  let resultPrice: number = Number(price);
  if (max && resultPrice >= max) return resultPrice;

  // Tính chưa
  let isCal = false;
  let isDer = false;

  if (isOneNumberAfterComma) {
    const ticker = GlobalData.getTickerInfoNew(symbol);
    resultPrice = resultPrice + 0.1;
    isCal = true;
    if (ticker != null && ticker.marketcd === EMarketCodeNew.DER_IDX) {
      isDer = true;
    }
  } else {
    if (symbol) {
      const ticker = GlobalData.getTickerInfoNew(symbol);
      if (ticker.sectype === SecType.CW || ticker.sectype === SecType.E) {
        resultPrice = Math.round((resultPrice + Number.EPSILON) * 100) / 100;
        resultPrice = resultPrice + 0.01;
        isCal = true;
      } else {
        if (ticker !== null && ticker.marketcd === EMarketCodeNew.HSX_IDX) {
          if (resultPrice < 10.0) {
            resultPrice =
              Math.round((resultPrice + Number.EPSILON) * 100) / 100;
            resultPrice = resultPrice + 0.01;
          } else if (resultPrice >= 10.0 && resultPrice < 49.95) {
            resultPrice = Math.round((resultPrice + Number.EPSILON) * 20) / 20;
            resultPrice = resultPrice + 0.05;
          } else if (resultPrice >= 49.5 && resultPrice < 50.0) {
            resultPrice = 50.0;
          } else if (resultPrice >= 50.0) {
            resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
            resultPrice = resultPrice + 0.1;
          } else {
            resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
            resultPrice = resultPrice + 0.1;
          }
          isCal = true;
        }

        if (ticker != null && ticker.marketcd === EMarketCodeNew.DER_IDX) {
          isDer = true;
        }
      }
    }
  }

  if (!isCal) {
    resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
    resultPrice = resultPrice + 0.1;
  }

  if (isDer) {
    resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
  } else {
    resultPrice = Math.round((resultPrice + Number.EPSILON) * 100) / 100;
  }

  if (resultPrice <= 0) resultPrice = 0;

  return resultPrice;
};

// Nhấn nút - Giá
export const decrementPrice = (
  symbol: string,
  price: number | string,
  min?: number,
  isOneNumberAfterComma?: boolean
): number | string => {
  if (!symbol) {
    return price;
  }

  if (!regPrice.test(price as string) && numeral(price).value() === null) {
    if (price === "") {
      price = 0;
    } else {
      return price;
    }
  }

  price = numeral(price).value()!;
  if (min) {
    if (price === 0) {
      price = numeral(min).value()!;
      return price;
    }
  }

  // Parse sang number
  let resultPrice: number = Number(price);
  if (min && resultPrice <= min) return resultPrice;

  // Tính chưa
  let isCal = false;
  let isDer = false;

  if (isOneNumberAfterComma) {
    const ticker = GlobalData.getTickerInfoNew(symbol);
    resultPrice = resultPrice - 0.1;
    isCal = true;
    if (ticker != null && ticker.marketcd === EMarketCodeNew.DER_IDX) {
      isDer = true;
    }
  } else {
    if (symbol) {
      const ticker = GlobalData.getTickerInfoNew(symbol);
      if (ticker.sectype === SecType.CW || ticker.sectype === SecType.E) {
        resultPrice = Math.round((resultPrice + Number.EPSILON) * 100) / 100;
        resultPrice = resultPrice - 0.01;
        isCal = true;
      } else {
        if (ticker !== null && ticker.marketcd === EMarketCodeNew.HSX_IDX) {
          if (resultPrice <= 10.0) {
            resultPrice =
              Math.round((resultPrice + Number.EPSILON) * 100) / 100;
            resultPrice = resultPrice - 0.01;
          } else if (resultPrice > 10.0 && resultPrice <= 49.95) {
            resultPrice = Math.round((resultPrice + Number.EPSILON) * 20) / 20;
            resultPrice = resultPrice - 0.05;
          } else if (resultPrice === 50.0) {
            resultPrice = 49.95;
          } else if (resultPrice > 50.0 && resultPrice < 50.1) {
            resultPrice = 50.0;
          } else if (resultPrice > 50.1) {
            resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
            resultPrice = resultPrice - 0.1;
          } else {
            resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
            resultPrice = resultPrice - 0.1;
          }
          isCal = true;
        }

        if (ticker != null && ticker.marketcd === EMarketCodeNew.DER_IDX) {
          isDer = true;
        }
      }
    }
  }

  if (!isCal) {
    resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
    resultPrice = resultPrice - 0.1;
  }

  if (isDer) {
    resultPrice = Math.round((resultPrice + Number.EPSILON) * 10) / 10;
  } else {
    resultPrice = Math.round((resultPrice + Number.EPSILON) * 100) / 100;
  }

  if (resultPrice <= 0) resultPrice = 0;

  return resultPrice;
};

// Nhấn nút + khối lượng
export const incrementVolume = (
  symbol: string,
  volume: number | string,
  maxVolume?: number
): number | string => {
  if (!symbol) {
    return volume;
  }

  let step = 100;
  if (symbol) {
    const ticker = GlobalData.getTickerInfoNew(symbol);

    if (ticker) {
      step = getVolumeStepFromMarketCode(ticker.marketcd);
    }
  }

  if (!volume) {
    return step;
  }

  if (volume && typeof volume === "string") {
    volume = volume.replaceAll(",", "");
  }

  if (!regVolumn.test(volume as string)) {
    return volume;
  }

  // Parse sang number
  let resultVolume: number = Number(volume);
  resultVolume = resultVolume + step;

  // Chặn max
  if (maxVolume && resultVolume > maxVolume) {
    resultVolume = maxVolume;
  }

  return resultVolume;
};

// Nhấn nút - khối lượng
export const decrementVolume = (
  symbol: string,
  volume: number | string
): number | string => {
  if (!symbol) {
    return volume;
  }

  if (volume && typeof volume === "string") {
    volume = volume.replaceAll(",", "");
  }

  if (!regVolumn.test(volume as string)) {
    return volume;
  }

  // Parse sang number
  let resultVolume: number = Number(volume);
  let isDer = false;

  if (symbol) {
    const ticker = GlobalData.getTickerInfoNew(symbol);
    if (ticker) {
      const step = getVolumeStepFromMarketCode(ticker.marketcd);
      resultVolume = resultVolume - step;
    }
    if (ticker != null && ticker.marketcd === EMarketCodeNew.DER_IDX) {
      isDer = true;
    }
  }

  // Chặn min
  if (isDer) {
    if (resultVolume < 1) {
      resultVolume = 1;
    }
  } else {
    if (resultVolume < 100) {
      resultVolume = 100;
    }
  }

  return resultVolume;
};

// Lấy bước khối lượng của mã CK
export const getVolumeStepFromMarketCode = (marketCode: number): number => {
  switch (marketCode) {
    case EMarketCodeNew.HSX_IDX:
      return TRADING_CONST.HSX_VOLUME_LOT;
    case EMarketCodeNew.HNX_IDX:
      return TRADING_CONST.HNX_VOLUME_LOT;
    case EMarketCodeNew.UPCOM_IDX:
      return TRADING_CONST.UPCOM_VOLUME_LOT;
    default:
      return 1;
  }
};
