import { useDispatch, useSelector } from "react-redux";
import { NavItemCard, MenuItemCard, ItemSelect, NavItemMenus } from "./styles";
import { ReactComponent as IconFilter } from "assets/image/svg/filter.svg";
import CheckBox from "./CheckBox";
import { tnTimeSaleSelector } from "modules/market/redux/selectors";
import { setTnTimeSale } from "modules/tickerDetails/redux/volumeAndTime";
import { useInterval } from "react-use";
import { DEFAULT_WIDGET_REFETCH_INTERVAL } from "helper/consts";

export enum DateType {
  T0 = 0,
  T1 = 1,
  T5 = 5,
}

function DateTypeMenu() {
  const dispatch = useDispatch();
  const tnTimeSale = useSelector(tnTimeSaleSelector);

  useInterval(() => {
    dispatch(setTnTimeSale(tnTimeSale));
  }, DEFAULT_WIDGET_REFETCH_INTERVAL);

  return (
    <NavItemMenus>
      <NavItemCard>
        <IconFilter />
      </NavItemCard>
      <MenuItemCard className="menu">
        <ItemSelect className="item-select">
          <CheckBox
            active={DateType.T0 === tnTimeSale}
            onClick={() => dispatch(setTnTimeSale(DateType.T0))}
          />
          T
        </ItemSelect>
        <ItemSelect className="item-select">
          <CheckBox
            active={DateType.T1 === tnTimeSale}
            onClick={() => dispatch(setTnTimeSale(DateType.T1))}
          />
          T-1
        </ItemSelect>
        <ItemSelect className="item-select">
          <CheckBox
            active={DateType.T5 === tnTimeSale}
            onClick={() => dispatch(setTnTimeSale(DateType.T5))}
          />
          T-5
        </ItemSelect>
      </MenuItemCard>
    </NavItemMenus>
  );
}

export default DateTypeMenu;
