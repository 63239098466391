import styled from "styled-components/macro";

export const Wrapper = styled.div`
  border-radius: 4px;
`;
export const Label = styled.div`
  margin-left: 15px;
`;

export const HeaderModal = styled.div`
  height: ${({ theme }) => theme.cardHeader.height};
  background-color: ${({ theme }) => theme.background3};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
  display: flex;

  align-items: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 20px 0px !important;
`;

export const ModalTitle = styled.div`
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
  color: ${({ theme }) => theme.grey0};
  padding-left: 15px;
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
`;

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.background2};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const BodyModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0px;
`;

export const LabelCell = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey20};
`;

export const TextCell = styled.div<{
  color?: string;
}>`
  flex: 1;
  text-align: right;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.bold};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  color: ${({ theme }) => theme.grey0};

  color: ${({ color, theme }) => color || theme.grey0};
`;
export const ConfirmRow = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0 8px;
`;

export const CheckBoxConfirmCell = styled.div`
  user-select: none;
`;

export const TextConfirmCell = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.grey0};
  user-select: none;
  cursor: pointer;
`;

export const ActionsRow = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
`;
export const ButtonCell = styled.div<{
  margin?: string;
}>`
  flex: 1;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px dashed ${({ theme }) => theme.borderDivider};
`;
export const Container = styled.div`
  height: calc(100%);
  width: 100%;
  border: 1px solid ${({ theme }) => theme.background3};
  overflow: auto;

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100%) !important;
      thead {
        tr > th {
          top: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tfoot {
        tr > th {
          bottom: 0px;
          position: sticky;
          white-space: nowrap;
          padding: 8px;
        }
      }
      tr > td {
        padding: 0px 8px;
      }
    }
  }
`;
export const HeaderCell = styled.div<{
  align?: string;
}>`
  background: ${({ theme }) => theme.background3};
  color: ${({ theme }) => theme.grey20};
  font-weight: 500;
  height: ${({ theme }) => theme.tables.header.height};
  display: flex;
  padding: 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ theme }) => theme.typeFaces.caption10.size};
  line-height: ${({ theme }) => theme.typeFaces.caption10.lineHeight};
  box-shadow: ${({ theme }) => theme.shadowLineDown};
`;
export const TableCellContent = styled.div<{
  color?: string;
  align?: string;
  fixWidth?: number;
  isInput?: boolean;
  noWrap?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: ${({ noWrap }) => (noWrap ? "normal" : "nowrap")};
  ${({ isInput }) => (isInput ? `padding: 4px 8px;` : `padding: 0 8px;`)}
  align-content: center;
  align-items: center;
  text-align: ${({ noWrap }) => (noWrap ? "left" : "center")};
  justify-content: ${({ align }) => (align ? align : "flex-start")};
  color: ${({ color, theme }) => (color ? color : theme.grey0)};
  font-size: ${({ theme }) => theme.typeFaces.caption12.size};
  height: ${({ theme }) => theme.tables.content.height};
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${({ theme }) => theme.background1};
  ${({ fixWidth }) =>
    fixWidth &&
    `
    width: ${fixWidth}px;
  `}
  &.market-ticker-name {
    padding: 0 12px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.full-company-name {
    height: 36px;
  }
  .input-edit {
    background-color: ${({ theme }) => theme.bgBase};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.text};
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
    text-align: right;
  }
`;
