// source: auto_trading.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.market_api.AnyMessage', null, global);
goog.exportSymbol('proto.market_api.BasePriceUpdate', null, global);
goog.exportSymbol('proto.market_api.BasketInfo', null, global);
goog.exportSymbol('proto.market_api.CurrencyInfo', null, global);
goog.exportSymbol('proto.market_api.DerivativeStatus', null, global);
goog.exportSymbol('proto.market_api.FindCurrencyInput', null, global);
goog.exportSymbol('proto.market_api.FindCurrencyResponse', null, global);
goog.exportSymbol('proto.market_api.FindTechnicalsInput', null, global);
goog.exportSymbol('proto.market_api.FindTechnicalsResponse', null, global);
goog.exportSymbol('proto.market_api.FindWorldIndexDetailInput', null, global);
goog.exportSymbol('proto.market_api.FindWorldIndexDetailResponse', null, global);
goog.exportSymbol('proto.market_api.FindWorldIndexInput', null, global);
goog.exportSymbol('proto.market_api.FindWorldIndexResponse', null, global);
goog.exportSymbol('proto.market_api.ForeignRoom', null, global);
goog.exportSymbol('proto.market_api.INDEX_COLOR_CODE', null, global);
goog.exportSymbol('proto.market_api.INDEX_STATE', null, global);
goog.exportSymbol('proto.market_api.IndexCapPro', null, global);
goog.exportSymbol('proto.market_api.IndexUpdate', null, global);
goog.exportSymbol('proto.market_api.IndustriesHeatMapItem', null, global);
goog.exportSymbol('proto.market_api.IndustriesHeatMapParam', null, global);
goog.exportSymbol('proto.market_api.IndustriesHeatMapResponse', null, global);
goog.exportSymbol('proto.market_api.IndustryInfo', null, global);
goog.exportSymbol('proto.market_api.InternalMS', null, global);
goog.exportSymbol('proto.market_api.InternalMSInput', null, global);
goog.exportSymbol('proto.market_api.InternalMSResponse', null, global);
goog.exportSymbol('proto.market_api.LastSale', null, global);
goog.exportSymbol('proto.market_api.LastSaleOdd', null, global);
goog.exportSymbol('proto.market_api.MarketBreadthItem', null, global);
goog.exportSymbol('proto.market_api.MarketBreadthParam', null, global);
goog.exportSymbol('proto.market_api.MarketBreadthResponse', null, global);
goog.exportSymbol('proto.market_api.MarketCeilingFloor', null, global);
goog.exportSymbol('proto.market_api.MarketDepth', null, global);
goog.exportSymbol('proto.market_api.MarketDepthInput', null, global);
goog.exportSymbol('proto.market_api.MarketDepthResponse', null, global);
goog.exportSymbol('proto.market_api.MarketIndexInfo', null, global);
goog.exportSymbol('proto.market_api.MarketIndexInfo.IndexTime', null, global);
goog.exportSymbol('proto.market_api.MarketIndexInput', null, global);
goog.exportSymbol('proto.market_api.MarketInitDataItem', null, global);
goog.exportSymbol('proto.market_api.MarketInitDataParam', null, global);
goog.exportSymbol('proto.market_api.MarketInitDataResponse', null, global);
goog.exportSymbol('proto.market_api.MarketLiquidityItem', null, global);
goog.exportSymbol('proto.market_api.MarketLiquidityParam', null, global);
goog.exportSymbol('proto.market_api.MarketLiquidityResponse', null, global);
goog.exportSymbol('proto.market_api.MarketMatching', null, global);
goog.exportSymbol('proto.market_api.MarketMatchingInfo', null, global);
goog.exportSymbol('proto.market_api.MarketMatchingInput', null, global);
goog.exportSymbol('proto.market_api.MarketMatchingResponse', null, global);
goog.exportSymbol('proto.market_api.MarketNetFlow', null, global);
goog.exportSymbol('proto.market_api.MarketNetFlowResponse', null, global);
goog.exportSymbol('proto.market_api.MarketQuotesInput', null, global);
goog.exportSymbol('proto.market_api.MarketQuotesResponse', null, global);
goog.exportSymbol('proto.market_api.MarketQuotesResponse.MarketQuotesInfo', null, global);
goog.exportSymbol('proto.market_api.MarketShareInput', null, global);
goog.exportSymbol('proto.market_api.MarketSharePro', null, global);
goog.exportSymbol('proto.market_api.MarketShareProCapture', null, global);
goog.exportSymbol('proto.market_api.MarketShareResponse', null, global);
goog.exportSymbol('proto.market_api.MarketWorldIndexInfo', null, global);
goog.exportSymbol('proto.market_api.MarketWorldIndexInfo.IndexTime', null, global);
goog.exportSymbol('proto.market_api.MarketWorldIndexInput', null, global);
goog.exportSymbol('proto.market_api.MrktAdvItem', null, global);
goog.exportSymbol('proto.market_api.MrktBasketInfoItem', null, global);
goog.exportSymbol('proto.market_api.MrktBasketInfoList', null, global);
goog.exportSymbol('proto.market_api.MrktMatchPtItem', null, global);
goog.exportSymbol('proto.market_api.MrktSecInfoItem', null, global);
goog.exportSymbol('proto.market_api.MrktSecInfoParam', null, global);
goog.exportSymbol('proto.market_api.MrktSecInfoResponse', null, global);
goog.exportSymbol('proto.market_api.MrktindustriesItem', null, global);
goog.exportSymbol('proto.market_api.MrktindustriesList', null, global);
goog.exportSymbol('proto.market_api.NetFlowInput', null, global);
goog.exportSymbol('proto.market_api.NullableDouble', null, global);
goog.exportSymbol('proto.market_api.NullableDouble.KindCase', null, global);
goog.exportSymbol('proto.market_api.NullableInt32', null, global);
goog.exportSymbol('proto.market_api.NullableInt32.KindCase', null, global);
goog.exportSymbol('proto.market_api.NullableInt64', null, global);
goog.exportSymbol('proto.market_api.NullableInt64.KindCase', null, global);
goog.exportSymbol('proto.market_api.NullableString', null, global);
goog.exportSymbol('proto.market_api.NullableString.KindCase', null, global);
goog.exportSymbol('proto.market_api.ORDER_TYPE', null, global);
goog.exportSymbol('proto.market_api.PriceInfo', null, global);
goog.exportSymbol('proto.market_api.ProjectOpen', null, global);
goog.exportSymbol('proto.market_api.PutThroughData', null, global);
goog.exportSymbol('proto.market_api.PutThroughParam', null, global);
goog.exportSymbol('proto.market_api.PutThroughResponse', null, global);
goog.exportSymbol('proto.market_api.STOCK_COLOR_CODE', null, global);
goog.exportSymbol('proto.market_api.ScreenerInput', null, global);
goog.exportSymbol('proto.market_api.ScreenerResponse', null, global);
goog.exportSymbol('proto.market_api.ScreenerResponse.SecDetailInfo', null, global);
goog.exportSymbol('proto.market_api.SecDividendInfo', null, global);
goog.exportSymbol('proto.market_api.SecDividendInput', null, global);
goog.exportSymbol('proto.market_api.SecDividendResponse', null, global);
goog.exportSymbol('proto.market_api.SecInfo', null, global);
goog.exportSymbol('proto.market_api.SecIntradayInput', null, global);
goog.exportSymbol('proto.market_api.SecIntradayResponse', null, global);
goog.exportSymbol('proto.market_api.SecIntradayResponse.SecIntradayInfo', null, global);
goog.exportSymbol('proto.market_api.SecQuotesCapPro', null, global);
goog.exportSymbol('proto.market_api.SecQuotesDetailInput', null, global);
goog.exportSymbol('proto.market_api.SecQuotesDetailResponse', null, global);
goog.exportSymbol('proto.market_api.SecQuotesDetailResponse.SecDetailInfo', null, global);
goog.exportSymbol('proto.market_api.SecQuotesSameIndustryParam', null, global);
goog.exportSymbol('proto.market_api.SecQuotesSameIndustryResponse', null, global);
goog.exportSymbol('proto.market_api.SecTimeSaleCap', null, global);
goog.exportSymbol('proto.market_api.SecTimeSalePro', null, global);
goog.exportSymbol('proto.market_api.SectFinanceData', null, global);
goog.exportSymbol('proto.market_api.SectFinanceItem', null, global);
goog.exportSymbol('proto.market_api.SectFinanceParam', null, global);
goog.exportSymbol('proto.market_api.SectFinanceResponse', null, global);
goog.exportSymbol('proto.market_api.SectNewsDetailItem', null, global);
goog.exportSymbol('proto.market_api.SectNewsDetailParam', null, global);
goog.exportSymbol('proto.market_api.SectNewsDetailResponse', null, global);
goog.exportSymbol('proto.market_api.SectNewsItem', null, global);
goog.exportSymbol('proto.market_api.SectNewsParam', null, global);
goog.exportSymbol('proto.market_api.SectNewsResponse', null, global);
goog.exportSymbol('proto.market_api.SectOwnershipItem', null, global);
goog.exportSymbol('proto.market_api.SectOwnershipParam', null, global);
goog.exportSymbol('proto.market_api.SectOwnershipResponse', null, global);
goog.exportSymbol('proto.market_api.SectProfileItem', null, global);
goog.exportSymbol('proto.market_api.SectProfileParam', null, global);
goog.exportSymbol('proto.market_api.SectProfileResponse', null, global);
goog.exportSymbol('proto.market_api.SymbolTotalInfo', null, global);
goog.exportSymbol('proto.market_api.SymbolTotalInfo.SecDetailInfo', null, global);
goog.exportSymbol('proto.market_api.SystemState', null, global);
goog.exportSymbol('proto.market_api.SystemState.StatusCode', null, global);
goog.exportSymbol('proto.market_api.TechnicalData', null, global);
goog.exportSymbol('proto.market_api.TechnicalInfo', null, global);
goog.exportSymbol('proto.market_api.TechnicalInfo.Action', null, global);
goog.exportSymbol('proto.market_api.TechnicalInfo.Group', null, global);
goog.exportSymbol('proto.market_api.TimeSaleInput', null, global);
goog.exportSymbol('proto.market_api.TimeSaleResponse', null, global);
goog.exportSymbol('proto.market_api.TopIndexContributionData', null, global);
goog.exportSymbol('proto.market_api.TopIndexContributionItem', null, global);
goog.exportSymbol('proto.market_api.TopIndexContributionParam', null, global);
goog.exportSymbol('proto.market_api.TopIndexContributionResponse', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesContributionItem', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesContributionParam', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesContributionResponse', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesTradeForeignItem', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesTradeForeignParam', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesTradeForeignResponse', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesTradeItem', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesTradeParam', null, global);
goog.exportSymbol('proto.market_api.TopIndustriesTradeResponse', null, global);
goog.exportSymbol('proto.market_api.TopMarketCapInput', null, global);
goog.exportSymbol('proto.market_api.TopMarketCapResponse', null, global);
goog.exportSymbol('proto.market_api.TopMarketCapResponse.TopMarketCapInfo', null, global);
goog.exportSymbol('proto.market_api.TopPrice', null, global);
goog.exportSymbol('proto.market_api.TopPrice.TopPriceDetail', null, global);
goog.exportSymbol('proto.market_api.TopPriceOdd', null, global);
goog.exportSymbol('proto.market_api.TopPriceOdd.TopPriceDetail', null, global);
goog.exportSymbol('proto.market_api.TopSecChangedInput', null, global);
goog.exportSymbol('proto.market_api.TopSecChangedResponse', null, global);
goog.exportSymbol('proto.market_api.TopSecChangedResponse.TopSecChangedInfo', null, global);
goog.exportSymbol('proto.market_api.TopSecUpDownInput', null, global);
goog.exportSymbol('proto.market_api.TopSecUpDownResponse', null, global);
goog.exportSymbol('proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo', null, global);
goog.exportSymbol('proto.market_api.TradingSession', null, global);
goog.exportSymbol('proto.market_api.WatchListInput', null, global);
goog.exportSymbol('proto.market_api.WorldIndexDetailInfo', null, global);
goog.exportSymbol('proto.market_api.WorldIndexInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindWorldIndexInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.FindWorldIndexInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindWorldIndexInput.displayName = 'proto.market_api.FindWorldIndexInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindWorldIndexResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.FindWorldIndexResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.FindWorldIndexResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindWorldIndexResponse.displayName = 'proto.market_api.FindWorldIndexResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.WorldIndexInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.WorldIndexInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.WorldIndexInfo.displayName = 'proto.market_api.WorldIndexInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindWorldIndexDetailInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.FindWorldIndexDetailInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindWorldIndexDetailInput.displayName = 'proto.market_api.FindWorldIndexDetailInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindWorldIndexDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.FindWorldIndexDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.FindWorldIndexDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindWorldIndexDetailResponse.displayName = 'proto.market_api.FindWorldIndexDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.WorldIndexDetailInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.WorldIndexDetailInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.WorldIndexDetailInfo.displayName = 'proto.market_api.WorldIndexDetailInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.WatchListInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.WatchListInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.WatchListInput.displayName = 'proto.market_api.WatchListInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SymbolTotalInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SymbolTotalInfo.repeatedFields_, null);
};
goog.inherits(proto.market_api.SymbolTotalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SymbolTotalInfo.displayName = 'proto.market_api.SymbolTotalInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SymbolTotalInfo.SecDetailInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SymbolTotalInfo.SecDetailInfo.displayName = 'proto.market_api.SymbolTotalInfo.SecDetailInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.PriceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.PriceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.PriceInfo.displayName = 'proto.market_api.PriceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketDepthInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketDepthInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketDepthInput.displayName = 'proto.market_api.MarketDepthInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketMatchingInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketMatchingInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketMatchingInput.displayName = 'proto.market_api.MarketMatchingInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopSecUpDownInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopSecUpDownInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopSecUpDownInput.displayName = 'proto.market_api.TopSecUpDownInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopSecUpDownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopSecUpDownResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopSecUpDownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopSecUpDownResponse.displayName = 'proto.market_api.TopSecUpDownResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.displayName = 'proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TradingSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TradingSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TradingSession.displayName = 'proto.market_api.TradingSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.BasketInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.BasketInfo.repeatedFields_, null);
};
goog.inherits(proto.market_api.BasketInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.BasketInfo.displayName = 'proto.market_api.BasketInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.IndustryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.IndustryInfo.repeatedFields_, null);
};
goog.inherits(proto.market_api.IndustryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.IndustryInfo.displayName = 'proto.market_api.IndustryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketMatching = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketMatching, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketMatching.displayName = 'proto.market_api.MarketMatching';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecInfo.displayName = 'proto.market_api.SecInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketDepth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketDepth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketDepth.displayName = 'proto.market_api.MarketDepth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketDepthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketDepthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketDepthResponse.displayName = 'proto.market_api.MarketDepthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketMatchingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketMatchingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketMatchingResponse.displayName = 'proto.market_api.MarketMatchingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketMatchingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketMatchingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketMatchingInfo.displayName = 'proto.market_api.MarketMatchingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.NetFlowInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.NetFlowInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.NetFlowInput.displayName = 'proto.market_api.NetFlowInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketNetFlowResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketNetFlowResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketNetFlowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketNetFlowResponse.displayName = 'proto.market_api.MarketNetFlowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketNetFlow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketNetFlow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketNetFlow.displayName = 'proto.market_api.MarketNetFlow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecQuotesCapPro = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecQuotesCapPro, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecQuotesCapPro.displayName = 'proto.market_api.SecQuotesCapPro';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.IndexCapPro = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.IndexCapPro, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.IndexCapPro.displayName = 'proto.market_api.IndexCapPro';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecTimeSaleCap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SecTimeSaleCap.repeatedFields_, null);
};
goog.inherits(proto.market_api.SecTimeSaleCap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecTimeSaleCap.displayName = 'proto.market_api.SecTimeSaleCap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecTimeSalePro = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecTimeSalePro, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecTimeSalePro.displayName = 'proto.market_api.SecTimeSalePro';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketShareInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketShareInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketShareInput.displayName = 'proto.market_api.MarketShareInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketShareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketShareResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketShareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketShareResponse.displayName = 'proto.market_api.MarketShareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketSharePro = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketSharePro, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketSharePro.displayName = 'proto.market_api.MarketSharePro';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketShareProCapture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketShareProCapture.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketShareProCapture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketShareProCapture.displayName = 'proto.market_api.MarketShareProCapture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TimeSaleInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TimeSaleInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TimeSaleInput.displayName = 'proto.market_api.TimeSaleInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TimeSaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TimeSaleResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TimeSaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TimeSaleResponse.displayName = 'proto.market_api.TimeSaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.InternalMSInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.InternalMSInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.InternalMSInput.displayName = 'proto.market_api.InternalMSInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.InternalMS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.InternalMS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.InternalMS.displayName = 'proto.market_api.InternalMS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.InternalMSResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.InternalMSResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.InternalMSResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.InternalMSResponse.displayName = 'proto.market_api.InternalMSResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopSecChangedInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopSecChangedInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopSecChangedInput.displayName = 'proto.market_api.TopSecChangedInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopSecChangedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopSecChangedResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopSecChangedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopSecChangedResponse.displayName = 'proto.market_api.TopSecChangedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopSecChangedResponse.TopSecChangedInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopSecChangedResponse.TopSecChangedInfo.displayName = 'proto.market_api.TopSecChangedResponse.TopSecChangedInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecDividendInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecDividendInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecDividendInput.displayName = 'proto.market_api.SecDividendInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecDividendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecDividendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecDividendResponse.displayName = 'proto.market_api.SecDividendResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketIndexInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketIndexInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketIndexInput.displayName = 'proto.market_api.MarketIndexInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketIndexInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketIndexInfo.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketIndexInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketIndexInfo.displayName = 'proto.market_api.MarketIndexInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketIndexInfo.IndexTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketIndexInfo.IndexTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketIndexInfo.IndexTime.displayName = 'proto.market_api.MarketIndexInfo.IndexTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketWorldIndexInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketWorldIndexInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketWorldIndexInput.displayName = 'proto.market_api.MarketWorldIndexInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketWorldIndexInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketWorldIndexInfo.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketWorldIndexInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketWorldIndexInfo.displayName = 'proto.market_api.MarketWorldIndexInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketWorldIndexInfo.IndexTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketWorldIndexInfo.IndexTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketWorldIndexInfo.IndexTime.displayName = 'proto.market_api.MarketWorldIndexInfo.IndexTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecQuotesDetailInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecQuotesDetailInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecQuotesDetailInput.displayName = 'proto.market_api.SecQuotesDetailInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecQuotesDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecQuotesDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecQuotesDetailResponse.displayName = 'proto.market_api.SecQuotesDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecQuotesDetailResponse.SecDetailInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecQuotesDetailResponse.SecDetailInfo.displayName = 'proto.market_api.SecQuotesDetailResponse.SecDetailInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketQuotesInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketQuotesInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketQuotesInput.displayName = 'proto.market_api.MarketQuotesInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketQuotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketQuotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketQuotesResponse.displayName = 'proto.market_api.MarketQuotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketQuotesResponse.MarketQuotesInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketQuotesResponse.MarketQuotesInfo.displayName = 'proto.market_api.MarketQuotesResponse.MarketQuotesInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecDividendInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecDividendInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecDividendInfo.displayName = 'proto.market_api.SecDividendInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecIntradayInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecIntradayInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecIntradayInput.displayName = 'proto.market_api.SecIntradayInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecIntradayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SecIntradayResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.SecIntradayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecIntradayResponse.displayName = 'proto.market_api.SecIntradayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecIntradayResponse.SecIntradayInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecIntradayResponse.SecIntradayInfo.displayName = 'proto.market_api.SecIntradayResponse.SecIntradayInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.ScreenerInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.ScreenerInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.ScreenerInput.displayName = 'proto.market_api.ScreenerInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.ScreenerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.ScreenerResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.ScreenerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.ScreenerResponse.displayName = 'proto.market_api.ScreenerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.ScreenerResponse.SecDetailInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.ScreenerResponse.SecDetailInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.ScreenerResponse.SecDetailInfo.displayName = 'proto.market_api.ScreenerResponse.SecDetailInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopMarketCapInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopMarketCapInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopMarketCapInput.displayName = 'proto.market_api.TopMarketCapInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopMarketCapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopMarketCapResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopMarketCapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopMarketCapResponse.displayName = 'proto.market_api.TopMarketCapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopMarketCapResponse.TopMarketCapInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopMarketCapResponse.TopMarketCapInfo.displayName = 'proto.market_api.TopMarketCapResponse.TopMarketCapInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SystemState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SystemState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SystemState.displayName = 'proto.market_api.SystemState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.IndexUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.IndexUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.IndexUpdate.displayName = 'proto.market_api.IndexUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopPrice.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopPrice.displayName = 'proto.market_api.TopPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopPrice.TopPriceDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopPrice.TopPriceDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopPrice.TopPriceDetail.displayName = 'proto.market_api.TopPrice.TopPriceDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.LastSale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.LastSale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.LastSale.displayName = 'proto.market_api.LastSale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopPriceOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopPriceOdd.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopPriceOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopPriceOdd.displayName = 'proto.market_api.TopPriceOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopPriceOdd.TopPriceDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopPriceOdd.TopPriceDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopPriceOdd.TopPriceDetail.displayName = 'proto.market_api.TopPriceOdd.TopPriceDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.LastSaleOdd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.LastSaleOdd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.LastSaleOdd.displayName = 'proto.market_api.LastSaleOdd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.ForeignRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.ForeignRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.ForeignRoom.displayName = 'proto.market_api.ForeignRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.NullableString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.market_api.NullableString.oneofGroups_);
};
goog.inherits(proto.market_api.NullableString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.NullableString.displayName = 'proto.market_api.NullableString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.NullableInt32 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.market_api.NullableInt32.oneofGroups_);
};
goog.inherits(proto.market_api.NullableInt32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.NullableInt32.displayName = 'proto.market_api.NullableInt32';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.NullableInt64 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.market_api.NullableInt64.oneofGroups_);
};
goog.inherits(proto.market_api.NullableInt64, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.NullableInt64.displayName = 'proto.market_api.NullableInt64';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.NullableDouble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.market_api.NullableDouble.oneofGroups_);
};
goog.inherits(proto.market_api.NullableDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.NullableDouble.displayName = 'proto.market_api.NullableDouble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketInitDataParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketInitDataParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketInitDataParam.displayName = 'proto.market_api.MarketInitDataParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketInitDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketInitDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketInitDataResponse.displayName = 'proto.market_api.MarketInitDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketInitDataItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketInitDataItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketInitDataItem.displayName = 'proto.market_api.MarketInitDataItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktBasketInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MrktBasketInfoList.repeatedFields_, null);
};
goog.inherits(proto.market_api.MrktBasketInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktBasketInfoList.displayName = 'proto.market_api.MrktBasketInfoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktindustriesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MrktindustriesList.repeatedFields_, null);
};
goog.inherits(proto.market_api.MrktindustriesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktindustriesList.displayName = 'proto.market_api.MrktindustriesList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktBasketInfoItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MrktBasketInfoItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktBasketInfoItem.displayName = 'proto.market_api.MrktBasketInfoItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktindustriesItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MrktindustriesItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktindustriesItem.displayName = 'proto.market_api.MrktindustriesItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktSecInfoItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MrktSecInfoItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktSecInfoItem.displayName = 'proto.market_api.MrktSecInfoItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktSecInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MrktSecInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.MrktSecInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktSecInfoResponse.displayName = 'proto.market_api.MrktSecInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktSecInfoParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MrktSecInfoParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktSecInfoParam.displayName = 'proto.market_api.MrktSecInfoParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktAdvItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MrktAdvItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktAdvItem.displayName = 'proto.market_api.MrktAdvItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MrktMatchPtItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MrktMatchPtItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MrktMatchPtItem.displayName = 'proto.market_api.MrktMatchPtItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.PutThroughData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.PutThroughData.repeatedFields_, null);
};
goog.inherits(proto.market_api.PutThroughData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.PutThroughData.displayName = 'proto.market_api.PutThroughData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.PutThroughResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.PutThroughResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.PutThroughResponse.displayName = 'proto.market_api.PutThroughResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.PutThroughParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.PutThroughParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.PutThroughParam.displayName = 'proto.market_api.PutThroughParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndexContributionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndexContributionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndexContributionItem.displayName = 'proto.market_api.TopIndexContributionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndexContributionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopIndexContributionData.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopIndexContributionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndexContributionData.displayName = 'proto.market_api.TopIndexContributionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndexContributionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndexContributionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndexContributionResponse.displayName = 'proto.market_api.TopIndexContributionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndexContributionParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndexContributionParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndexContributionParam.displayName = 'proto.market_api.TopIndexContributionParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketBreadthItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketBreadthItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketBreadthItem.displayName = 'proto.market_api.MarketBreadthItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketBreadthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketBreadthResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketBreadthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketBreadthResponse.displayName = 'proto.market_api.MarketBreadthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketLiquidityItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketLiquidityItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketLiquidityItem.displayName = 'proto.market_api.MarketLiquidityItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketLiquidityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.MarketLiquidityResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.MarketLiquidityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketLiquidityResponse.displayName = 'proto.market_api.MarketLiquidityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesTradeForeignItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndustriesTradeForeignItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesTradeForeignItem.displayName = 'proto.market_api.TopIndustriesTradeForeignItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesTradeForeignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopIndustriesTradeForeignResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopIndustriesTradeForeignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesTradeForeignResponse.displayName = 'proto.market_api.TopIndustriesTradeForeignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesContributionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndustriesContributionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesContributionItem.displayName = 'proto.market_api.TopIndustriesContributionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesContributionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopIndustriesContributionResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopIndustriesContributionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesContributionResponse.displayName = 'proto.market_api.TopIndustriesContributionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesTradeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndustriesTradeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesTradeItem.displayName = 'proto.market_api.TopIndustriesTradeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesTradeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TopIndustriesTradeResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.TopIndustriesTradeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesTradeResponse.displayName = 'proto.market_api.TopIndustriesTradeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketBreadthParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketBreadthParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketBreadthParam.displayName = 'proto.market_api.MarketBreadthParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketLiquidityParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketLiquidityParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketLiquidityParam.displayName = 'proto.market_api.MarketLiquidityParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesTradeForeignParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndustriesTradeForeignParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesTradeForeignParam.displayName = 'proto.market_api.TopIndustriesTradeForeignParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesContributionParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndustriesContributionParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesContributionParam.displayName = 'proto.market_api.TopIndustriesContributionParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TopIndustriesTradeParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TopIndustriesTradeParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TopIndustriesTradeParam.displayName = 'proto.market_api.TopIndustriesTradeParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.IndustriesHeatMapParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.IndustriesHeatMapParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.IndustriesHeatMapParam.displayName = 'proto.market_api.IndustriesHeatMapParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.BasePriceUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.BasePriceUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.BasePriceUpdate.displayName = 'proto.market_api.BasePriceUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.AnyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.AnyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.AnyMessage.displayName = 'proto.market_api.AnyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.ProjectOpen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.ProjectOpen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.ProjectOpen.displayName = 'proto.market_api.ProjectOpen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.IndustriesHeatMapItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.IndustriesHeatMapItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.IndustriesHeatMapItem.displayName = 'proto.market_api.IndustriesHeatMapItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.IndustriesHeatMapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.IndustriesHeatMapResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.IndustriesHeatMapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.IndustriesHeatMapResponse.displayName = 'proto.market_api.IndustriesHeatMapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecQuotesSameIndustryParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecQuotesSameIndustryParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecQuotesSameIndustryParam.displayName = 'proto.market_api.SecQuotesSameIndustryParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SecQuotesSameIndustryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SecQuotesSameIndustryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SecQuotesSameIndustryResponse.displayName = 'proto.market_api.SecQuotesSameIndustryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindCurrencyInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.FindCurrencyInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindCurrencyInput.displayName = 'proto.market_api.FindCurrencyInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.FindCurrencyResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.FindCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindCurrencyResponse.displayName = 'proto.market_api.FindCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.CurrencyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.CurrencyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.CurrencyInfo.displayName = 'proto.market_api.CurrencyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindTechnicalsInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.FindTechnicalsInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindTechnicalsInput.displayName = 'proto.market_api.FindTechnicalsInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.FindTechnicalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.FindTechnicalsResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.FindTechnicalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.FindTechnicalsResponse.displayName = 'proto.market_api.FindTechnicalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TechnicalInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.TechnicalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TechnicalInfo.displayName = 'proto.market_api.TechnicalInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.TechnicalData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.TechnicalData.repeatedFields_, null);
};
goog.inherits(proto.market_api.TechnicalData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.TechnicalData.displayName = 'proto.market_api.TechnicalData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectFinanceItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectFinanceItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectFinanceItem.displayName = 'proto.market_api.SectFinanceItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectFinanceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SectFinanceData.repeatedFields_, null);
};
goog.inherits(proto.market_api.SectFinanceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectFinanceData.displayName = 'proto.market_api.SectFinanceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectFinanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectFinanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectFinanceResponse.displayName = 'proto.market_api.SectFinanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectFinanceParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectFinanceParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectFinanceParam.displayName = 'proto.market_api.SectFinanceParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectNewsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectNewsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectNewsItem.displayName = 'proto.market_api.SectNewsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectNewsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SectNewsResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.SectNewsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectNewsResponse.displayName = 'proto.market_api.SectNewsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectNewsParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectNewsParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectNewsParam.displayName = 'proto.market_api.SectNewsParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectNewsDetailItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectNewsDetailItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectNewsDetailItem.displayName = 'proto.market_api.SectNewsDetailItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectNewsDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectNewsDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectNewsDetailResponse.displayName = 'proto.market_api.SectNewsDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectNewsDetailParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectNewsDetailParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectNewsDetailParam.displayName = 'proto.market_api.SectNewsDetailParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectOwnershipItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectOwnershipItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectOwnershipItem.displayName = 'proto.market_api.SectOwnershipItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectOwnershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SectOwnershipResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.SectOwnershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectOwnershipResponse.displayName = 'proto.market_api.SectOwnershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectOwnershipParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectOwnershipParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectOwnershipParam.displayName = 'proto.market_api.SectOwnershipParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectProfileItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectProfileItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectProfileItem.displayName = 'proto.market_api.SectProfileItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market_api.SectProfileResponse.repeatedFields_, null);
};
goog.inherits(proto.market_api.SectProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectProfileResponse.displayName = 'proto.market_api.SectProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.SectProfileParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.SectProfileParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.SectProfileParam.displayName = 'proto.market_api.SectProfileParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.MarketCeilingFloor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.MarketCeilingFloor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.MarketCeilingFloor.displayName = 'proto.market_api.MarketCeilingFloor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market_api.DerivativeStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market_api.DerivativeStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market_api.DerivativeStatus.displayName = 'proto.market_api.DerivativeStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindWorldIndexInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindWorldIndexInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindWorldIndexInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    symbollist: (f = msg.getSymbollist()) && proto.market_api.NullableString.toObject(includeInstance, f),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindWorldIndexInput}
 */
proto.market_api.FindWorldIndexInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindWorldIndexInput;
  return proto.market_api.FindWorldIndexInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindWorldIndexInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindWorldIndexInput}
 */
proto.market_api.FindWorldIndexInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setSymbollist(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindWorldIndexInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindWorldIndexInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindWorldIndexInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSymbollist();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.FindWorldIndexInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.FindWorldIndexInput} returns this
*/
proto.market_api.FindWorldIndexInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.FindWorldIndexInput} returns this
 */
proto.market_api.FindWorldIndexInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.FindWorldIndexInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NullableString symbolList = 2;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.FindWorldIndexInput.prototype.getSymbollist = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 2));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.FindWorldIndexInput} returns this
*/
proto.market_api.FindWorldIndexInput.prototype.setSymbollist = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.FindWorldIndexInput} returns this
 */
proto.market_api.FindWorldIndexInput.prototype.clearSymbollist = function() {
  return this.setSymbollist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.FindWorldIndexInput.prototype.hasSymbollist = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.FindWorldIndexInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexInput} returns this
 */
proto.market_api.FindWorldIndexInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.FindWorldIndexResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindWorldIndexResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindWorldIndexResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindWorldIndexResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.WorldIndexInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindWorldIndexResponse}
 */
proto.market_api.FindWorldIndexResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindWorldIndexResponse;
  return proto.market_api.FindWorldIndexResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindWorldIndexResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindWorldIndexResponse}
 */
proto.market_api.FindWorldIndexResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.WorldIndexInfo;
      reader.readMessage(value,proto.market_api.WorldIndexInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindWorldIndexResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindWorldIndexResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindWorldIndexResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.WorldIndexInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.FindWorldIndexResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.FindWorldIndexResponse} returns this
 */
proto.market_api.FindWorldIndexResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.FindWorldIndexResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexResponse} returns this
 */
proto.market_api.FindWorldIndexResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.FindWorldIndexResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexResponse} returns this
 */
proto.market_api.FindWorldIndexResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated WorldIndexInfo data = 4;
 * @return {!Array<!proto.market_api.WorldIndexInfo>}
 */
proto.market_api.FindWorldIndexResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.WorldIndexInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.WorldIndexInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.WorldIndexInfo>} value
 * @return {!proto.market_api.FindWorldIndexResponse} returns this
*/
proto.market_api.FindWorldIndexResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.WorldIndexInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.WorldIndexInfo}
 */
proto.market_api.FindWorldIndexResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.WorldIndexInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.FindWorldIndexResponse} returns this
 */
proto.market_api.FindWorldIndexResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.WorldIndexInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.WorldIndexInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.WorldIndexInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.WorldIndexInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tradedate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    open: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    last: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    high: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    low: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    chg: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    chgPct: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    indexname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    flag: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.WorldIndexInfo}
 */
proto.market_api.WorldIndexInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.WorldIndexInfo;
  return proto.market_api.WorldIndexInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.WorldIndexInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.WorldIndexInfo}
 */
proto.market_api.WorldIndexInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChg(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChgPct(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.WorldIndexInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.WorldIndexInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.WorldIndexInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.WorldIndexInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLast();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLow();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getChg();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getChgPct();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getIndexname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFlag();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.market_api.WorldIndexInfo.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tradeDate = 2;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double open = 4;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double last = 5;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getLast = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setLast = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double high = 6;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double low = 7;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double chg = 8;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getChg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setChg = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double chg_pct = 9;
 * @return {number}
 */
proto.market_api.WorldIndexInfo.prototype.getChgPct = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setChgPct = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string indexName = 10;
 * @return {string}
 */
proto.market_api.WorldIndexInfo.prototype.getIndexname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setIndexname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string flag = 11;
 * @return {string}
 */
proto.market_api.WorldIndexInfo.prototype.getFlag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.WorldIndexInfo} returns this
 */
proto.market_api.WorldIndexInfo.prototype.setFlag = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindWorldIndexDetailInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindWorldIndexDetailInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexDetailInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindWorldIndexDetailInput}
 */
proto.market_api.FindWorldIndexDetailInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindWorldIndexDetailInput;
  return proto.market_api.FindWorldIndexDetailInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindWorldIndexDetailInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindWorldIndexDetailInput}
 */
proto.market_api.FindWorldIndexDetailInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindWorldIndexDetailInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindWorldIndexDetailInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexDetailInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromtime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotime();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.FindWorldIndexDetailInput} returns this
*/
proto.market_api.FindWorldIndexDetailInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.FindWorldIndexDetailInput} returns this
 */
proto.market_api.FindWorldIndexDetailInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexDetailInput} returns this
 */
proto.market_api.FindWorldIndexDetailInput.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 fromTime = 3;
 * @return {number}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.getFromtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.FindWorldIndexDetailInput} returns this
 */
proto.market_api.FindWorldIndexDetailInput.prototype.setFromtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 toTime = 4;
 * @return {number}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.getTotime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.FindWorldIndexDetailInput} returns this
 */
proto.market_api.FindWorldIndexDetailInput.prototype.setTotime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string authenCode = 5;
 * @return {string}
 */
proto.market_api.FindWorldIndexDetailInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexDetailInput} returns this
 */
proto.market_api.FindWorldIndexDetailInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.FindWorldIndexDetailResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindWorldIndexDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindWorldIndexDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.WorldIndexDetailInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindWorldIndexDetailResponse}
 */
proto.market_api.FindWorldIndexDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindWorldIndexDetailResponse;
  return proto.market_api.FindWorldIndexDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindWorldIndexDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindWorldIndexDetailResponse}
 */
proto.market_api.FindWorldIndexDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.WorldIndexDetailInfo;
      reader.readMessage(value,proto.market_api.WorldIndexDetailInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindWorldIndexDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindWorldIndexDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindWorldIndexDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.WorldIndexDetailInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.FindWorldIndexDetailResponse} returns this
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexDetailResponse} returns this
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindWorldIndexDetailResponse} returns this
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated WorldIndexDetailInfo data = 4;
 * @return {!Array<!proto.market_api.WorldIndexDetailInfo>}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.WorldIndexDetailInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.WorldIndexDetailInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.WorldIndexDetailInfo>} value
 * @return {!proto.market_api.FindWorldIndexDetailResponse} returns this
*/
proto.market_api.FindWorldIndexDetailResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.WorldIndexDetailInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.WorldIndexDetailInfo}
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.WorldIndexDetailInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.FindWorldIndexDetailResponse} returns this
 */
proto.market_api.FindWorldIndexDetailResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.WorldIndexDetailInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.WorldIndexDetailInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.WorldIndexDetailInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.WorldIndexDetailInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tradedate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    time: jspb.Message.getFieldWithDefault(msg, 3, 0),
    open: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    last: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    high: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    low: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.WorldIndexDetailInfo}
 */
proto.market_api.WorldIndexDetailInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.WorldIndexDetailInfo;
  return proto.market_api.WorldIndexDetailInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.WorldIndexDetailInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.WorldIndexDetailInfo}
 */
proto.market_api.WorldIndexDetailInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.WorldIndexDetailInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.WorldIndexDetailInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.WorldIndexDetailInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.WorldIndexDetailInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLast();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLow();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tradeDate = 2;
 * @return {number}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 time = 3;
 * @return {number}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double open = 4;
 * @return {number}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double last = 5;
 * @return {number}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getLast = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setLast = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double high = 6;
 * @return {number}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double low = 7;
 * @return {number}
 */
proto.market_api.WorldIndexDetailInfo.prototype.getLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.WorldIndexDetailInfo} returns this
 */
proto.market_api.WorldIndexDetailInfo.prototype.setLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.WatchListInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.WatchListInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.WatchListInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.WatchListInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    seclist: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isintraday: (f = msg.getIsintraday()) && proto.market_api.NullableString.toObject(includeInstance, f),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.WatchListInput}
 */
proto.market_api.WatchListInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.WatchListInput;
  return proto.market_api.WatchListInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.WatchListInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.WatchListInput}
 */
proto.market_api.WatchListInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeclist(value);
      break;
    case 3:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setIsintraday(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.WatchListInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.WatchListInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.WatchListInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.WatchListInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSeclist();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsintraday();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.WatchListInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.WatchListInput} returns this
*/
proto.market_api.WatchListInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.WatchListInput} returns this
 */
proto.market_api.WatchListInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.WatchListInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string secList = 2;
 * @return {string}
 */
proto.market_api.WatchListInput.prototype.getSeclist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.WatchListInput} returns this
 */
proto.market_api.WatchListInput.prototype.setSeclist = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString isIntraday = 3;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.WatchListInput.prototype.getIsintraday = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 3));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.WatchListInput} returns this
*/
proto.market_api.WatchListInput.prototype.setIsintraday = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.WatchListInput} returns this
 */
proto.market_api.WatchListInput.prototype.clearIsintraday = function() {
  return this.setIsintraday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.WatchListInput.prototype.hasIsintraday = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.WatchListInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.WatchListInput} returns this
 */
proto.market_api.WatchListInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SymbolTotalInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SymbolTotalInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SymbolTotalInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SymbolTotalInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SymbolTotalInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    secdetailinfo: (f = msg.getSecdetailinfo()) && proto.market_api.SymbolTotalInfo.SecDetailInfo.toObject(includeInstance, f),
    colorcode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    priceinfolistList: jspb.Message.toObjectList(msg.getPriceinfolistList(),
    proto.market_api.PriceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SymbolTotalInfo}
 */
proto.market_api.SymbolTotalInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SymbolTotalInfo;
  return proto.market_api.SymbolTotalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SymbolTotalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SymbolTotalInfo}
 */
proto.market_api.SymbolTotalInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.SymbolTotalInfo.SecDetailInfo;
      reader.readMessage(value,proto.market_api.SymbolTotalInfo.SecDetailInfo.deserializeBinaryFromReader);
      msg.setSecdetailinfo(value);
      break;
    case 2:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 3:
      var value = new proto.market_api.PriceInfo;
      reader.readMessage(value,proto.market_api.PriceInfo.deserializeBinaryFromReader);
      msg.addPriceinfolist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SymbolTotalInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SymbolTotalInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SymbolTotalInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SymbolTotalInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecdetailinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.SymbolTotalInfo.SecDetailInfo.serializeBinaryToWriter
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPriceinfolistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.market_api.PriceInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SymbolTotalInfo.SecDetailInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SymbolTotalInfo.SecDetailInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sectype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    basicprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ceilingprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floorprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    highestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lowestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    avgprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    lastqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    totalptqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    totalptamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totalroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    currentroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    w52high: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    w52low: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    best1bidpricestr: jspb.Message.getFieldWithDefault(msg, 25, ""),
    best1bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    best1bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    best2bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    best2bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    best3bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    best3bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    best4bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    best1offerpricestr: jspb.Message.getFieldWithDefault(msg, 33, ""),
    best1offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    best1offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    best2offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    best2offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    best3offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    best3offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    best4offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    listedqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    tradeqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 42, 0.0),
    tradepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 43, 0.0),
    esp: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    pe: jspb.Message.getFloatingPointFieldWithDefault(msg, 45, 0.0),
    pb: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    ps: jspb.Message.getFloatingPointFieldWithDefault(msg, 47, 0.0),
    bookvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    dividendamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    dividendrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    secnameen: jspb.Message.getFieldWithDefault(msg, 51, ""),
    baseseccd: (f = msg.getBaseseccd()) && proto.market_api.NullableString.toObject(includeInstance, f),
    issuer: (f = msg.getIssuer()) && proto.market_api.NullableString.toObject(includeInstance, f),
    baseprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 54, 0.0),
    execprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    basepricecp: jspb.Message.getFloatingPointFieldWithDefault(msg, 56, 0.0),
    bfprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 57, 0.0),
    bbprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 58, 0.0),
    bcprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 59, 0.0),
    lasttradedate: jspb.Message.getFieldWithDefault(msg, 60, 0),
    turnoverrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 61, 0.0),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 62, 0.0),
    basesecpricecolor: jspb.Message.getFieldWithDefault(msg, 63, 0),
    hasrightexec: jspb.Message.getFieldWithDefault(msg, 64, 0),
    execratio: (f = msg.getExecratio()) && proto.market_api.NullableString.toObject(includeInstance, f),
    roe: jspb.Message.getFloatingPointFieldWithDefault(msg, 66, 0.0),
    roa: jspb.Message.getFloatingPointFieldWithDefault(msg, 67, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 68, 0),
    openinterest: jspb.Message.getFloatingPointFieldWithDefault(msg, 69, 0.0),
    basis: jspb.Message.getFloatingPointFieldWithDefault(msg, 70, 0.0),
    marketcap: jspb.Message.getFloatingPointFieldWithDefault(msg, 71, 0.0),
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 72, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SymbolTotalInfo.SecDetailInfo;
  return proto.market_api.SymbolTotalInfo.SecDetailInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SymbolTotalInfo.SecDetailInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSectype(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasicprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilingprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloorprice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestprice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastqty(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalptqty(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalptamt(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalroom(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentroom(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setW52high(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setW52low(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBest1bidpricestr(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1bidprice(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1bidqty(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2bidprice(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2bidqty(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3bidprice(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3bidqty(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest4bidqty(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBest1offerpricestr(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1offerprice(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1offerqty(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2offerprice(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2offerqty(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3offerprice(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3offerqty(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest4offerqty(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedqty(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeqty(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradepercent(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEsp(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPe(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPb(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPs(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBookvalue(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDividendamt(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDividendrate(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecnameen(value);
      break;
    case 52:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setBaseseccd(value);
      break;
    case 53:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setIssuer(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseprice(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExecprice(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasepricecp(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBfprice(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBbprice(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBcprice(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLasttradedate(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnoverrate(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRange(value);
      break;
    case 63:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setBasesecpricecolor(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHasrightexec(value);
      break;
    case 65:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setExecratio(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoe(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoa(value);
      break;
    case 68:
      var value = /** @type {!proto.market_api.INDEX_STATE} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 69:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpeninterest(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasis(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcap(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SymbolTotalInfo.SecDetailInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SymbolTotalInfo.SecDetailInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSectype();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBasicprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCeilingprice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFloorprice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHighestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLowestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAvgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getLastqty();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getTotalptqty();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getTotalptamt();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getTotalroom();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getCurrentroom();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getW52high();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getW52low();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getBest1bidpricestr();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getBest1bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getBest1bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getBest2bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getBest2bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getBest3bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getBest3bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getBest4bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getBest1offerpricestr();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBest1offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getBest1offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getBest2offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getBest2offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getBest3offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getBest3offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      39,
      f
    );
  }
  f = message.getBest4offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      40,
      f
    );
  }
  f = message.getListedqty();
  if (f !== 0.0) {
    writer.writeDouble(
      41,
      f
    );
  }
  f = message.getTradeqty();
  if (f !== 0.0) {
    writer.writeDouble(
      42,
      f
    );
  }
  f = message.getTradepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      43,
      f
    );
  }
  f = message.getEsp();
  if (f !== 0.0) {
    writer.writeDouble(
      44,
      f
    );
  }
  f = message.getPe();
  if (f !== 0.0) {
    writer.writeDouble(
      45,
      f
    );
  }
  f = message.getPb();
  if (f !== 0.0) {
    writer.writeDouble(
      46,
      f
    );
  }
  f = message.getPs();
  if (f !== 0.0) {
    writer.writeDouble(
      47,
      f
    );
  }
  f = message.getBookvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getDividendamt();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getDividendrate();
  if (f !== 0.0) {
    writer.writeDouble(
      50,
      f
    );
  }
  f = message.getSecnameen();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getBaseseccd();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIssuer();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getBaseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getExecprice();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getBasepricecp();
  if (f !== 0.0) {
    writer.writeDouble(
      56,
      f
    );
  }
  f = message.getBfprice();
  if (f !== 0.0) {
    writer.writeDouble(
      57,
      f
    );
  }
  f = message.getBbprice();
  if (f !== 0.0) {
    writer.writeDouble(
      58,
      f
    );
  }
  f = message.getBcprice();
  if (f !== 0.0) {
    writer.writeDouble(
      59,
      f
    );
  }
  f = message.getLasttradedate();
  if (f !== 0) {
    writer.writeInt32(
      60,
      f
    );
  }
  f = message.getTurnoverrate();
  if (f !== 0.0) {
    writer.writeDouble(
      61,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeDouble(
      62,
      f
    );
  }
  f = message.getBasesecpricecolor();
  if (f !== 0.0) {
    writer.writeEnum(
      63,
      f
    );
  }
  f = message.getHasrightexec();
  if (f !== 0) {
    writer.writeInt32(
      64,
      f
    );
  }
  f = message.getExecratio();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getRoe();
  if (f !== 0.0) {
    writer.writeDouble(
      66,
      f
    );
  }
  f = message.getRoa();
  if (f !== 0.0) {
    writer.writeDouble(
      67,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      68,
      f
    );
  }
  f = message.getOpeninterest();
  if (f !== 0.0) {
    writer.writeDouble(
      69,
      f
    );
  }
  f = message.getBasis();
  if (f !== 0.0) {
    writer.writeDouble(
      70,
      f
    );
  }
  f = message.getMarketcap();
  if (f !== 0.0) {
    writer.writeDouble(
      71,
      f
    );
  }
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      72,
      f
    );
  }
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secName = 3;
 * @return {string}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getSecname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setSecname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 secType = 4;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getSectype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setSectype = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double basicPrice = 5;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBasicprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBasicprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double ceilingPrice = 6;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getCeilingprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setCeilingprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double floorPrice = 7;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getFloorprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setFloorprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double highestPrice = 8;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getHighestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setHighestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double lowestPrice = 9;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getLowestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setLowestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double avgPrice = 10;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getAvgprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setAvgprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double lastPrice = 11;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double lastQty = 12;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getLastqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setLastqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double totalQty = 13;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double totalAmt = 14;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double totalPtQty = 15;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTotalptqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTotalptqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double totalPtAmt = 16;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTotalptamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTotalptamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double sellForeignQty = 17;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double buyForeignQty = 18;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double changePoint = 19;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double changePercent = 20;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double totalRoom = 21;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTotalroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTotalroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double currentRoom = 22;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getCurrentroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setCurrentroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double w52High = 23;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getW52high = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setW52high = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double w52Low = 24;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getW52low = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setW52low = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional string best1BidPriceStr = 25;
 * @return {string}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest1bidpricestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest1bidpricestr = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional double best1BidPrice = 26;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest1bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest1bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double best1BidQty = 27;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest1bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest1bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double best2BidPrice = 28;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest2bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest2bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double best2BidQty = 29;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest2bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest2bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double best3BidPrice = 30;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest3bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest3bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double best3BidQty = 31;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest3bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest3bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double best4BidQty = 32;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest4bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest4bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional string best1OfferPriceStr = 33;
 * @return {string}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest1offerpricestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest1offerpricestr = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional double best1OfferPrice = 34;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest1offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest1offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double best1OfferQty = 35;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest1offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest1offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double best2OfferPrice = 36;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest2offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest2offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double best2OfferQty = 37;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest2offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest2offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double best3OfferPrice = 38;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest3offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest3offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional double best3OfferQty = 39;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest3offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest3offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional double best4OfferQty = 40;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBest4offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBest4offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional double listedQty = 41;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getListedqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setListedqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional double tradeQty = 42;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTradeqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTradeqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 42, value);
};


/**
 * optional double tradePercent = 43;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTradepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 43, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTradepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 43, value);
};


/**
 * optional double esp = 44;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getEsp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setEsp = function(value) {
  return jspb.Message.setProto3FloatField(this, 44, value);
};


/**
 * optional double pe = 45;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getPe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 45, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setPe = function(value) {
  return jspb.Message.setProto3FloatField(this, 45, value);
};


/**
 * optional double pb = 46;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getPb = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setPb = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional double ps = 47;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getPs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 47, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setPs = function(value) {
  return jspb.Message.setProto3FloatField(this, 47, value);
};


/**
 * optional double bookValue = 48;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBookvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBookvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional double dividendAmt = 49;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getDividendamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setDividendamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional double dividendRate = 50;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getDividendrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setDividendrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional string secNameEn = 51;
 * @return {string}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getSecnameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setSecnameen = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional NullableString baseSecCd = 52;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBaseseccd = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 52));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
*/
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBaseseccd = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.clearBaseseccd = function() {
  return this.setBaseseccd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.hasBaseseccd = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional NullableString issuer = 53;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getIssuer = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 53));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
*/
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setIssuer = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.clearIssuer = function() {
  return this.setIssuer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.hasIssuer = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional double basePrice = 54;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBaseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 54, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBaseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double execPrice = 55;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getExecprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setExecprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional double basePriceCp = 56;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBasepricecp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 56, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBasepricecp = function(value) {
  return jspb.Message.setProto3FloatField(this, 56, value);
};


/**
 * optional double bfPrice = 57;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBfprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 57, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBfprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 57, value);
};


/**
 * optional double bbPrice = 58;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBbprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 58, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBbprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 58, value);
};


/**
 * optional double bcPrice = 59;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBcprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 59, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBcprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 59, value);
};


/**
 * optional int32 lastTradeDate = 60;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getLasttradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setLasttradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional double turnoverRate = 61;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getTurnoverrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 61, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setTurnoverrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 61, value);
};


/**
 * optional double range = 62;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 62, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 62, value);
};


/**
 * optional STOCK_COLOR_CODE baseSecPriceColor = 63;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBasesecpricecolor = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 63, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBasesecpricecolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 63, value);
};


/**
 * optional int32 hasRightExec = 64;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getHasrightexec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setHasrightexec = function(value) {
  return jspb.Message.setProto3IntField(this, 64, value);
};


/**
 * optional NullableString execRatio = 65;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getExecratio = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 65));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
*/
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setExecratio = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.clearExecratio = function() {
  return this.setExecratio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.hasExecratio = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional double roe = 66;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getRoe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 66, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setRoe = function(value) {
  return jspb.Message.setProto3FloatField(this, 66, value);
};


/**
 * optional double roa = 67;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getRoa = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 67, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setRoa = function(value) {
  return jspb.Message.setProto3FloatField(this, 67, value);
};


/**
 * optional INDEX_STATE status = 68;
 * @return {!proto.market_api.INDEX_STATE}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getStatus = function() {
  return /** @type {!proto.market_api.INDEX_STATE} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {!proto.market_api.INDEX_STATE} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 68, value);
};


/**
 * optional double openInterest = 69;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getOpeninterest = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 69, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setOpeninterest = function(value) {
  return jspb.Message.setProto3FloatField(this, 69, value);
};


/**
 * optional double basis = 70;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getBasis = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 70, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setBasis = function(value) {
  return jspb.Message.setProto3FloatField(this, 70, value);
};


/**
 * optional double marketCap = 71;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getMarketcap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 71, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setMarketcap = function(value) {
  return jspb.Message.setProto3FloatField(this, 71, value);
};


/**
 * optional double openPrice = 72;
 * @return {number}
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 72, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SymbolTotalInfo.SecDetailInfo} returns this
 */
proto.market_api.SymbolTotalInfo.SecDetailInfo.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 72, value);
};


/**
 * optional SecDetailInfo secDetailInfo = 1;
 * @return {?proto.market_api.SymbolTotalInfo.SecDetailInfo}
 */
proto.market_api.SymbolTotalInfo.prototype.getSecdetailinfo = function() {
  return /** @type{?proto.market_api.SymbolTotalInfo.SecDetailInfo} */ (
    jspb.Message.getWrapperField(this, proto.market_api.SymbolTotalInfo.SecDetailInfo, 1));
};


/**
 * @param {?proto.market_api.SymbolTotalInfo.SecDetailInfo|undefined} value
 * @return {!proto.market_api.SymbolTotalInfo} returns this
*/
proto.market_api.SymbolTotalInfo.prototype.setSecdetailinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SymbolTotalInfo} returns this
 */
proto.market_api.SymbolTotalInfo.prototype.clearSecdetailinfo = function() {
  return this.setSecdetailinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SymbolTotalInfo.prototype.hasSecdetailinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional STOCK_COLOR_CODE colorCode = 2;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.SymbolTotalInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.SymbolTotalInfo} returns this
 */
proto.market_api.SymbolTotalInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated PriceInfo priceInfoList = 3;
 * @return {!Array<!proto.market_api.PriceInfo>}
 */
proto.market_api.SymbolTotalInfo.prototype.getPriceinfolistList = function() {
  return /** @type{!Array<!proto.market_api.PriceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.PriceInfo, 3));
};


/**
 * @param {!Array<!proto.market_api.PriceInfo>} value
 * @return {!proto.market_api.SymbolTotalInfo} returns this
*/
proto.market_api.SymbolTotalInfo.prototype.setPriceinfolistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.market_api.PriceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.PriceInfo}
 */
proto.market_api.SymbolTotalInfo.prototype.addPriceinfolist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.market_api.PriceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SymbolTotalInfo} returns this
 */
proto.market_api.SymbolTotalInfo.prototype.clearPriceinfolistList = function() {
  return this.setPriceinfolistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.PriceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.PriceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.PriceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PriceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    qty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.PriceInfo}
 */
proto.market_api.PriceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.PriceInfo;
  return proto.market_api.PriceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.PriceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.PriceInfo}
 */
proto.market_api.PriceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.PriceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.PriceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.PriceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PriceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getQty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.market_api.PriceInfo.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.PriceInfo} returns this
 */
proto.market_api.PriceInfo.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.market_api.PriceInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.PriceInfo} returns this
 */
proto.market_api.PriceInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double qty = 3;
 * @return {number}
 */
proto.market_api.PriceInfo.prototype.getQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.PriceInfo} returns this
 */
proto.market_api.PriceInfo.prototype.setQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketDepthInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketDepthInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketDepthInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketDepthInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    indexcd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketDepthInput}
 */
proto.market_api.MarketDepthInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketDepthInput;
  return proto.market_api.MarketDepthInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketDepthInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketDepthInput}
 */
proto.market_api.MarketDepthInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketDepthInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketDepthInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketDepthInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketDepthInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.MarketDepthInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketDepthInput} returns this
 */
proto.market_api.MarketDepthInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 indexCd = 2;
 * @return {number}
 */
proto.market_api.MarketDepthInput.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepthInput} returns this
 */
proto.market_api.MarketDepthInput.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.MarketDepthInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketDepthInput} returns this
 */
proto.market_api.MarketDepthInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketMatchingInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketMatchingInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketMatchingInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatchingInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketMatchingInput}
 */
proto.market_api.MarketMatchingInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketMatchingInput;
  return proto.market_api.MarketMatchingInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketMatchingInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketMatchingInput}
 */
proto.market_api.MarketMatchingInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketMatchingInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketMatchingInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketMatchingInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatchingInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTn();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.MarketMatchingInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatchingInput} returns this
 */
proto.market_api.MarketMatchingInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.MarketMatchingInput.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatchingInput} returns this
 */
proto.market_api.MarketMatchingInput.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 tn = 3;
 * @return {number}
 */
proto.market_api.MarketMatchingInput.prototype.getTn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInput} returns this
 */
proto.market_api.MarketMatchingInput.prototype.setTn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.MarketMatchingInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatchingInput} returns this
 */
proto.market_api.MarketMatchingInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopSecUpDownInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopSecUpDownInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopSecUpDownInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecUpDownInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toptype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    marketcd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    industries: jspb.Message.getFieldWithDefault(msg, 4, ""),
    periodtype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderfieldtype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 9, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopSecUpDownInput}
 */
proto.market_api.TopSecUpDownInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopSecUpDownInput;
  return proto.market_api.TopSecUpDownInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopSecUpDownInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopSecUpDownInput}
 */
proto.market_api.TopSecUpDownInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setToptype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustries(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriodtype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderfieldtype(value);
      break;
    case 7:
      var value = /** @type {!proto.market_api.ORDER_TYPE} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopSecUpDownInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopSecUpDownInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopSecUpDownInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecUpDownInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToptype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIndustries();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeriodtype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrderfieldtype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.TopSecUpDownInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 topType = 2;
 * @return {number}
 */
proto.market_api.TopSecUpDownInput.prototype.getToptype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setToptype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string marketCd = 3;
 * @return {string}
 */
proto.market_api.TopSecUpDownInput.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string industries = 4;
 * @return {string}
 */
proto.market_api.TopSecUpDownInput.prototype.getIndustries = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setIndustries = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 periodType = 5;
 * @return {number}
 */
proto.market_api.TopSecUpDownInput.prototype.getPeriodtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setPeriodtype = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 orderFieldType = 6;
 * @return {number}
 */
proto.market_api.TopSecUpDownInput.prototype.getOrderfieldtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setOrderfieldtype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ORDER_TYPE orderType = 7;
 * @return {!proto.market_api.ORDER_TYPE}
 */
proto.market_api.TopSecUpDownInput.prototype.getOrdertype = function() {
  return /** @type {!proto.market_api.ORDER_TYPE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.market_api.ORDER_TYPE} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 offset = 8;
 * @return {number}
 */
proto.market_api.TopSecUpDownInput.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 limit = 9;
 * @return {number}
 */
proto.market_api.TopSecUpDownInput.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string authenCode = 10;
 * @return {string}
 */
proto.market_api.TopSecUpDownInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownInput} returns this
 */
proto.market_api.TopSecUpDownInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopSecUpDownResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopSecUpDownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopSecUpDownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopSecUpDownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecUpDownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    topsecupdownList: jspb.Message.toObjectList(msg.getTopsecupdownList(),
    proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopSecUpDownResponse}
 */
proto.market_api.TopSecUpDownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopSecUpDownResponse;
  return proto.market_api.TopSecUpDownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopSecUpDownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopSecUpDownResponse}
 */
proto.market_api.TopSecUpDownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo;
      reader.readMessage(value,proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.deserializeBinaryFromReader);
      msg.addTopsecupdown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopSecUpDownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopSecUpDownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopSecUpDownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecUpDownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTopsecupdownList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo;
  return proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 7:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totalAmt = 2;
 * @return {number}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double totalQty = 3;
 * @return {number}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double closePrice = 4;
 * @return {number}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double changePoint = 5;
 * @return {number}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double changePercent = 6;
 * @return {number}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 7;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo} returns this
 */
proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.TopSecUpDownResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecUpDownResponse} returns this
 */
proto.market_api.TopSecUpDownResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopSecUpDownResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownResponse} returns this
 */
proto.market_api.TopSecUpDownResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopSecUpDownResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecUpDownResponse} returns this
 */
proto.market_api.TopSecUpDownResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TopSecUpDownInfo topSecUpDown = 4;
 * @return {!Array<!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo>}
 */
proto.market_api.TopSecUpDownResponse.prototype.getTopsecupdownList = function() {
  return /** @type{!Array<!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo>} value
 * @return {!proto.market_api.TopSecUpDownResponse} returns this
*/
proto.market_api.TopSecUpDownResponse.prototype.setTopsecupdownList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo}
 */
proto.market_api.TopSecUpDownResponse.prototype.addTopsecupdown = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopSecUpDownResponse.TopSecUpDownInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopSecUpDownResponse} returns this
 */
proto.market_api.TopSecUpDownResponse.prototype.clearTopsecupdownList = function() {
  return this.setTopsecupdownList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TradingSession.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TradingSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TradingSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TradingSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradingdate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TradingSession}
 */
proto.market_api.TradingSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TradingSession;
  return proto.market_api.TradingSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TradingSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TradingSession}
 */
proto.market_api.TradingSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradingdate(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TradingSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TradingSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TradingSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TradingSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradingdate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 tradingDate = 2;
 * @return {number}
 */
proto.market_api.TradingSession.prototype.getTradingdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TradingSession} returns this
 */
proto.market_api.TradingSession.prototype.setTradingdate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.TradingSession.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TradingSession} returns this
 */
proto.market_api.TradingSession.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.market_api.TradingSession.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TradingSession} returns this
 */
proto.market_api.TradingSession.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.BasketInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.BasketInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.BasketInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.BasketInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.BasketInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seclistList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.BasketInfo}
 */
proto.market_api.BasketInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.BasketInfo;
  return proto.market_api.BasketInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.BasketInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.BasketInfo}
 */
proto.market_api.BasketInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeclist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.BasketInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.BasketInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.BasketInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.BasketInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeclistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 indexCd = 1;
 * @return {number}
 */
proto.market_api.BasketInfo.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.BasketInfo} returns this
 */
proto.market_api.BasketInfo.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string secList = 2;
 * @return {!Array<string>}
 */
proto.market_api.BasketInfo.prototype.getSeclistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.market_api.BasketInfo} returns this
 */
proto.market_api.BasketInfo.prototype.setSeclistList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.market_api.BasketInfo} returns this
 */
proto.market_api.BasketInfo.prototype.addSeclist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.BasketInfo} returns this
 */
proto.market_api.BasketInfo.prototype.clearSeclistList = function() {
  return this.setSeclistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.IndustryInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.IndustryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.IndustryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.IndustryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seclistList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.IndustryInfo}
 */
proto.market_api.IndustryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.IndustryInfo;
  return proto.market_api.IndustryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.IndustryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.IndustryInfo}
 */
proto.market_api.IndustryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeclist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.IndustryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.IndustryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.IndustryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeclistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.IndustryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustryInfo} returns this
 */
proto.market_api.IndustryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string secList = 2;
 * @return {!Array<string>}
 */
proto.market_api.IndustryInfo.prototype.getSeclistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.market_api.IndustryInfo} returns this
 */
proto.market_api.IndustryInfo.prototype.setSeclistList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.market_api.IndustryInfo} returns this
 */
proto.market_api.IndustryInfo.prototype.addSeclist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.IndustryInfo} returns this
 */
proto.market_api.IndustryInfo.prototype.clearSeclistList = function() {
  return this.setSeclistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketMatching.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketMatching.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketMatching} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatching.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0),
    buyrange1: jspb.Message.getFieldWithDefault(msg, 4, 0),
    buypercent1: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    buyrange2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    buypercent2: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    buyrange3: jspb.Message.getFieldWithDefault(msg, 8, 0),
    buypercent3: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    sellrange1: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sellpercent1: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    sellrange2: jspb.Message.getFieldWithDefault(msg, 12, 0),
    sellpercent2: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    sellrange3: jspb.Message.getFieldWithDefault(msg, 14, 0),
    sellpercent3: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    buyamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    sellamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketMatching}
 */
proto.market_api.MarketMatching.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketMatching;
  return proto.market_api.MarketMatching.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketMatching} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketMatching}
 */
proto.market_api.MarketMatching.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuyrange1(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuypercent1(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuyrange2(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuypercent2(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuyrange3(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuypercent3(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellrange1(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellpercent1(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellrange2(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellpercent2(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellrange3(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellpercent3(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketMatching.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketMatching.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketMatching} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatching.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBuyrange1();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBuypercent1();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBuyrange2();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getBuypercent2();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBuyrange3();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getBuypercent3();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getSellrange1();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getSellpercent1();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getSellrange2();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSellpercent2();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getSellrange3();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSellpercent3();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getBuyamt();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getSellamt();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 3;
 * @return {string}
 */
proto.market_api.MarketMatching.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 time = 2;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 buyRange1 = 4;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuyrange1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuyrange1 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double buyPercent1 = 5;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuypercent1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuypercent1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 buyRange2 = 6;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuyrange2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuyrange2 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double buyPercent2 = 7;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuypercent2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuypercent2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 buyRange3 = 8;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuyrange3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuyrange3 = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double buyPercent3 = 9;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuypercent3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuypercent3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int32 sellRange1 = 10;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellrange1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellrange1 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double sellPercent1 = 11;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellpercent1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellpercent1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int32 sellRange2 = 12;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellrange2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellrange2 = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional double sellPercent2 = 13;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellpercent2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellpercent2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int32 sellRange3 = 14;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellrange3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellrange3 = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional double sellPercent3 = 15;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellpercent3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellpercent3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double buyAmt = 16;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getBuyamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setBuyamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double sellAmt = 17;
 * @return {number}
 */
proto.market_api.MarketMatching.prototype.getSellamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatching} returns this
 */
proto.market_api.MarketMatching.prototype.setSellamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ceilingprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    basicprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    floorprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecInfo}
 */
proto.market_api.SecInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecInfo;
  return proto.market_api.SecInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecInfo}
 */
proto.market_api.SecInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilingprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasicprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloorprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCeilingprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBasicprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFloorprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.SecInfo.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecInfo} returns this
 */
proto.market_api.SecInfo.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecInfo} returns this
 */
proto.market_api.SecInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double ceilingPrice = 3;
 * @return {number}
 */
proto.market_api.SecInfo.prototype.getCeilingprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecInfo} returns this
 */
proto.market_api.SecInfo.prototype.setCeilingprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double basicPrice = 4;
 * @return {number}
 */
proto.market_api.SecInfo.prototype.getBasicprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecInfo} returns this
 */
proto.market_api.SecInfo.prototype.setBasicprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double floorPrice = 5;
 * @return {number}
 */
proto.market_api.SecInfo.prototype.getFloorprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecInfo} returns this
 */
proto.market_api.SecInfo.prototype.setFloorprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketDepth.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketDepth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketDepth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketDepth.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0),
    indexcd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    down7: jspb.Message.getFieldWithDefault(msg, 4, 0),
    down57: jspb.Message.getFieldWithDefault(msg, 5, 0),
    down35: jspb.Message.getFieldWithDefault(msg, 6, 0),
    down13: jspb.Message.getFieldWithDefault(msg, 7, 0),
    down01: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nochange: jspb.Message.getFieldWithDefault(msg, 9, 0),
    up01: jspb.Message.getFieldWithDefault(msg, 10, 0),
    up13: jspb.Message.getFieldWithDefault(msg, 11, 0),
    up35: jspb.Message.getFieldWithDefault(msg, 12, 0),
    up57: jspb.Message.getFieldWithDefault(msg, 13, 0),
    up7: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketDepth}
 */
proto.market_api.MarketDepth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketDepth;
  return proto.market_api.MarketDepth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketDepth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketDepth}
 */
proto.market_api.MarketDepth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDown7(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDown57(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDown35(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDown13(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDown01(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNochange(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUp01(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUp13(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUp35(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUp57(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUp7(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketDepth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketDepth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketDepth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketDepth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDown7();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDown57();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDown35();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDown13();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDown01();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNochange();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUp01();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getUp13();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getUp35();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getUp57();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getUp7();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 time = 2;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 indexCd = 3;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 down7 = 4;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getDown7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setDown7 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 down57 = 5;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getDown57 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setDown57 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 down35 = 6;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getDown35 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setDown35 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 down13 = 7;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getDown13 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setDown13 = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 down01 = 8;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getDown01 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setDown01 = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 noChange = 9;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getNochange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setNochange = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 up01 = 10;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getUp01 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setUp01 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 up13 = 11;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getUp13 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setUp13 = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 up35 = 12;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getUp35 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setUp35 = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 up57 = 13;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getUp57 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setUp57 = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 up7 = 14;
 * @return {number}
 */
proto.market_api.MarketDepth.prototype.getUp7 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepth} returns this
 */
proto.market_api.MarketDepth.prototype.setUp7 = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketDepthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketDepthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketDepthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketDepthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    marketdepthinfo: (f = msg.getMarketdepthinfo()) && proto.market_api.MarketDepth.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketDepthResponse}
 */
proto.market_api.MarketDepthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketDepthResponse;
  return proto.market_api.MarketDepthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketDepthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketDepthResponse}
 */
proto.market_api.MarketDepthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketDepth;
      reader.readMessage(value,proto.market_api.MarketDepth.deserializeBinaryFromReader);
      msg.setMarketdepthinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketDepthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketDepthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketDepthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketDepthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMarketdepthinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.MarketDepth.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.MarketDepthResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketDepthResponse} returns this
 */
proto.market_api.MarketDepthResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketDepthResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketDepthResponse} returns this
 */
proto.market_api.MarketDepthResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketDepthResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketDepthResponse} returns this
 */
proto.market_api.MarketDepthResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MarketDepth marketDepthInfo = 4;
 * @return {?proto.market_api.MarketDepth}
 */
proto.market_api.MarketDepthResponse.prototype.getMarketdepthinfo = function() {
  return /** @type{?proto.market_api.MarketDepth} */ (
    jspb.Message.getWrapperField(this, proto.market_api.MarketDepth, 4));
};


/**
 * @param {?proto.market_api.MarketDepth|undefined} value
 * @return {!proto.market_api.MarketDepthResponse} returns this
*/
proto.market_api.MarketDepthResponse.prototype.setMarketdepthinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketDepthResponse} returns this
 */
proto.market_api.MarketDepthResponse.prototype.clearMarketdepthinfo = function() {
  return this.setMarketdepthinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketDepthResponse.prototype.hasMarketdepthinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketMatchingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketMatchingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketMatchingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatchingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    marketmatchinginfo: (f = msg.getMarketmatchinginfo()) && proto.market_api.MarketMatchingInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketMatchingResponse}
 */
proto.market_api.MarketMatchingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketMatchingResponse;
  return proto.market_api.MarketMatchingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketMatchingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketMatchingResponse}
 */
proto.market_api.MarketMatchingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketMatchingInfo;
      reader.readMessage(value,proto.market_api.MarketMatchingInfo.deserializeBinaryFromReader);
      msg.setMarketmatchinginfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketMatchingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketMatchingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketMatchingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatchingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMarketmatchinginfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.MarketMatchingInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.MarketMatchingResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingResponse} returns this
 */
proto.market_api.MarketMatchingResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketMatchingResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatchingResponse} returns this
 */
proto.market_api.MarketMatchingResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketMatchingResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatchingResponse} returns this
 */
proto.market_api.MarketMatchingResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MarketMatchingInfo marketMatchingInfo = 4;
 * @return {?proto.market_api.MarketMatchingInfo}
 */
proto.market_api.MarketMatchingResponse.prototype.getMarketmatchinginfo = function() {
  return /** @type{?proto.market_api.MarketMatchingInfo} */ (
    jspb.Message.getWrapperField(this, proto.market_api.MarketMatchingInfo, 4));
};


/**
 * @param {?proto.market_api.MarketMatchingInfo|undefined} value
 * @return {!proto.market_api.MarketMatchingResponse} returns this
*/
proto.market_api.MarketMatchingResponse.prototype.setMarketmatchinginfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketMatchingResponse} returns this
 */
proto.market_api.MarketMatchingResponse.prototype.clearMarketmatchinginfo = function() {
  return this.setMarketmatchinginfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketMatchingResponse.prototype.hasMarketmatchinginfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketMatchingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketMatchingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketMatchingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatchingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buyqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    buyamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    sellqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sellamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketMatchingInfo}
 */
proto.market_api.MarketMatchingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketMatchingInfo;
  return proto.market_api.MarketMatchingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketMatchingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketMatchingInfo}
 */
proto.market_api.MarketMatchingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyqty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketMatchingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketMatchingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketMatchingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketMatchingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBuyqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBuyamt();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSellqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSellamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MarketMatchingInfo.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 time = 2;
 * @return {number}
 */
proto.market_api.MarketMatchingInfo.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string secCd = 3;
 * @return {string}
 */
proto.market_api.MarketMatchingInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double buyQty = 4;
 * @return {number}
 */
proto.market_api.MarketMatchingInfo.prototype.getBuyqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setBuyqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double buyAmt = 5;
 * @return {number}
 */
proto.market_api.MarketMatchingInfo.prototype.getBuyamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setBuyamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double sellQty = 6;
 * @return {number}
 */
proto.market_api.MarketMatchingInfo.prototype.getSellqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setSellqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double sellAmt = 7;
 * @return {number}
 */
proto.market_api.MarketMatchingInfo.prototype.getSellamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketMatchingInfo} returns this
 */
proto.market_api.MarketMatchingInfo.prototype.setSellamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.NetFlowInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.NetFlowInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.NetFlowInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NetFlowInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    indexcd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.NetFlowInput}
 */
proto.market_api.NetFlowInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.NetFlowInput;
  return proto.market_api.NetFlowInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.NetFlowInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.NetFlowInput}
 */
proto.market_api.NetFlowInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.NetFlowInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.NetFlowInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.NetFlowInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NetFlowInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTn();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional NullableString userId = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.NetFlowInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.NetFlowInput} returns this
*/
proto.market_api.NetFlowInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.NetFlowInput} returns this
 */
proto.market_api.NetFlowInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NetFlowInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 indexCd = 2;
 * @return {number}
 */
proto.market_api.NetFlowInput.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.NetFlowInput} returns this
 */
proto.market_api.NetFlowInput.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 tn = 3;
 * @return {number}
 */
proto.market_api.NetFlowInput.prototype.getTn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.NetFlowInput} returns this
 */
proto.market_api.NetFlowInput.prototype.setTn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.NetFlowInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.NetFlowInput} returns this
 */
proto.market_api.NetFlowInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketNetFlowResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketNetFlowResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketNetFlowResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketNetFlowResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketNetFlowResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    marketnetflowlistList: jspb.Message.toObjectList(msg.getMarketnetflowlistList(),
    proto.market_api.MarketNetFlow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketNetFlowResponse}
 */
proto.market_api.MarketNetFlowResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketNetFlowResponse;
  return proto.market_api.MarketNetFlowResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketNetFlowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketNetFlowResponse}
 */
proto.market_api.MarketNetFlowResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketNetFlow;
      reader.readMessage(value,proto.market_api.MarketNetFlow.deserializeBinaryFromReader);
      msg.addMarketnetflowlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketNetFlowResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketNetFlowResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketNetFlowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketNetFlowResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMarketnetflowlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.MarketNetFlow.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.MarketNetFlowResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlowResponse} returns this
 */
proto.market_api.MarketNetFlowResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketNetFlowResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketNetFlowResponse} returns this
 */
proto.market_api.MarketNetFlowResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketNetFlowResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketNetFlowResponse} returns this
 */
proto.market_api.MarketNetFlowResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated MarketNetFlow marketNetFlowList = 4;
 * @return {!Array<!proto.market_api.MarketNetFlow>}
 */
proto.market_api.MarketNetFlowResponse.prototype.getMarketnetflowlistList = function() {
  return /** @type{!Array<!proto.market_api.MarketNetFlow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketNetFlow, 4));
};


/**
 * @param {!Array<!proto.market_api.MarketNetFlow>} value
 * @return {!proto.market_api.MarketNetFlowResponse} returns this
*/
proto.market_api.MarketNetFlowResponse.prototype.setMarketnetflowlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.MarketNetFlow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketNetFlow}
 */
proto.market_api.MarketNetFlowResponse.prototype.addMarketnetflowlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.MarketNetFlow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketNetFlowResponse} returns this
 */
proto.market_api.MarketNetFlowResponse.prototype.clearMarketnetflowlistList = function() {
  return this.setMarketnetflowlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketNetFlow.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketNetFlow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketNetFlow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketNetFlow.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradingdate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    indexcd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    time: jspb.Message.getFieldWithDefault(msg, 3, 0),
    buyqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    buyamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    sellqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sellamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    netamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketNetFlow}
 */
proto.market_api.MarketNetFlow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketNetFlow;
  return proto.market_api.MarketNetFlow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketNetFlow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketNetFlow}
 */
proto.market_api.MarketNetFlow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradingdate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyqty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketNetFlow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketNetFlow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketNetFlow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketNetFlow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradingdate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBuyqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBuyamt();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSellqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSellamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getNetamt();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 tradingDate = 1;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getTradingdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setTradingdate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 indexCd = 2;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 time = 3;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double buyQty = 4;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getBuyqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setBuyqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double buyAmt = 5;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getBuyamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setBuyamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double sellQty = 6;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getSellqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setSellqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double sellAmt = 7;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getSellamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setSellamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double netAmt = 8;
 * @return {number}
 */
proto.market_api.MarketNetFlow.prototype.getNetamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketNetFlow} returns this
 */
proto.market_api.MarketNetFlow.prototype.setNetamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecQuotesCapPro.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecQuotesCapPro.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecQuotesCapPro} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesCapPro.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tradedate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    captime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    openqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    highestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    lowestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    closeqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecQuotesCapPro}
 */
proto.market_api.SecQuotesCapPro.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecQuotesCapPro;
  return proto.market_api.SecQuotesCapPro.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecQuotesCapPro} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecQuotesCapPro}
 */
proto.market_api.SecQuotesCapPro.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCaptime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestprice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestprice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecQuotesCapPro.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecQuotesCapPro.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecQuotesCapPro} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesCapPro.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCaptime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getOpenqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getHighestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLowestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getCloseqty();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.SecQuotesCapPro.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tradeDate = 2;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string secCd = 3;
 * @return {string}
 */
proto.market_api.SecQuotesCapPro.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 capTime = 4;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getCaptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setCaptime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double openPrice = 5;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double openQty = 6;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getOpenqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setOpenqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double highestPrice = 7;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getHighestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setHighestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double lowestPrice = 8;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getLowestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setLowestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double closePrice = 9;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double closeQty = 10;
 * @return {number}
 */
proto.market_api.SecQuotesCapPro.prototype.getCloseqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesCapPro} returns this
 */
proto.market_api.SecQuotesCapPro.prototype.setCloseqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.IndexCapPro.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.IndexCapPro.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.IndexCapPro} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndexCapPro.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    captime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    openindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    openqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    highestindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lowestindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    closeindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    closeqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.IndexCapPro}
 */
proto.market_api.IndexCapPro.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.IndexCapPro;
  return proto.market_api.IndexCapPro.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.IndexCapPro} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.IndexCapPro}
 */
proto.market_api.IndexCapPro.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCaptime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenindex(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenqty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestindex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestindex(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseindex(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.IndexCapPro.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.IndexCapPro.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.IndexCapPro} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndexCapPro.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaptime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOpenindex();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getOpenqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getHighestindex();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLowestindex();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCloseindex();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCloseqty();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string indexCd = 1;
 * @return {string}
 */
proto.market_api.IndexCapPro.prototype.getIndexcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 capTime = 2;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getCaptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setCaptime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double openIndex = 3;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getOpenindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setOpenindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double openQty = 4;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getOpenqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setOpenqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double highestIndex = 5;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getHighestindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setHighestindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double lowestIndex = 6;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getLowestindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setLowestindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double closeIndex = 7;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getCloseindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setCloseindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double closeQty = 8;
 * @return {number}
 */
proto.market_api.IndexCapPro.prototype.getCloseqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexCapPro} returns this
 */
proto.market_api.IndexCapPro.prototype.setCloseqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SecTimeSaleCap.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecTimeSaleCap.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecTimeSaleCap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecTimeSaleCap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecTimeSaleCap.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sectimesalelistList: jspb.Message.toObjectList(msg.getSectimesalelistList(),
    proto.market_api.SecTimeSalePro.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecTimeSaleCap}
 */
proto.market_api.SecTimeSaleCap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecTimeSaleCap;
  return proto.market_api.SecTimeSaleCap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecTimeSaleCap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecTimeSaleCap}
 */
proto.market_api.SecTimeSaleCap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = new proto.market_api.SecTimeSalePro;
      reader.readMessage(value,proto.market_api.SecTimeSalePro.deserializeBinaryFromReader);
      msg.addSectimesalelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecTimeSaleCap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecTimeSaleCap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecTimeSaleCap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecTimeSaleCap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSectimesalelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.market_api.SecTimeSalePro.serializeBinaryToWriter
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SecTimeSaleCap.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecTimeSaleCap} returns this
 */
proto.market_api.SecTimeSaleCap.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SecTimeSalePro secTimeSaleList = 2;
 * @return {!Array<!proto.market_api.SecTimeSalePro>}
 */
proto.market_api.SecTimeSaleCap.prototype.getSectimesalelistList = function() {
  return /** @type{!Array<!proto.market_api.SecTimeSalePro>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SecTimeSalePro, 2));
};


/**
 * @param {!Array<!proto.market_api.SecTimeSalePro>} value
 * @return {!proto.market_api.SecTimeSaleCap} returns this
*/
proto.market_api.SecTimeSaleCap.prototype.setSectimesalelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.market_api.SecTimeSalePro=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SecTimeSalePro}
 */
proto.market_api.SecTimeSaleCap.prototype.addSectimesalelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.market_api.SecTimeSalePro, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SecTimeSaleCap} returns this
 */
proto.market_api.SecTimeSaleCap.prototype.clearSectimesalelistList = function() {
  return this.setSectimesalelistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecTimeSalePro.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecTimeSalePro.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecTimeSalePro} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecTimeSalePro.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    matprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    buyqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    buyamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    buypercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sellqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    sellamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    sellpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    percentbytotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecTimeSalePro}
 */
proto.market_api.SecTimeSalePro.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecTimeSalePro;
  return proto.market_api.SecTimeSalePro.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecTimeSalePro} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecTimeSalePro}
 */
proto.market_api.SecTimeSalePro.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMatprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyqty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuypercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellqty(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellpercent(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentbytotal(value);
      break;
    case 13:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecTimeSalePro.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecTimeSalePro.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecTimeSalePro} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecTimeSalePro.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMatprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBuyqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBuyamt();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBuypercent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSellqty();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSellamt();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSellpercent();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPercentbytotal();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecTimeSalePro.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double matPrice = 3;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getMatprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setMatprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double buyQty = 4;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getBuyqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setBuyqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double buyAmt = 5;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getBuyamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setBuyamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double buyPercent = 6;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getBuypercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setBuypercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double sellQty = 7;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getSellqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setSellqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double sellAmt = 8;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getSellamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setSellamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double sellPercent = 9;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getSellpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setSellpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double totalQty = 10;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double totalAmt = 11;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double percentByTotal = 12;
 * @return {number}
 */
proto.market_api.SecTimeSalePro.prototype.getPercentbytotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setPercentbytotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 13;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.SecTimeSalePro.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.SecTimeSalePro} returns this
 */
proto.market_api.SecTimeSalePro.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketShareInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketShareInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketShareInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketShareInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    type: (f = msg.getType()) && proto.market_api.NullableInt32.toObject(includeInstance, f),
    industryidstr: (f = msg.getIndustryidstr()) && proto.market_api.NullableString.toObject(includeInstance, f),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketShareInput}
 */
proto.market_api.MarketShareInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketShareInput;
  return proto.market_api.MarketShareInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketShareInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketShareInput}
 */
proto.market_api.MarketShareInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.market_api.NullableInt32;
      reader.readMessage(value,proto.market_api.NullableInt32.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setIndustryidstr(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketShareInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketShareInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketShareInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketShareInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.market_api.NullableInt32.serializeBinaryToWriter
    );
  }
  f = message.getIndustryidstr();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.MarketShareInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.MarketShareInput} returns this
*/
proto.market_api.MarketShareInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketShareInput} returns this
 */
proto.market_api.MarketShareInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketShareInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NullableInt32 type = 2;
 * @return {?proto.market_api.NullableInt32}
 */
proto.market_api.MarketShareInput.prototype.getType = function() {
  return /** @type{?proto.market_api.NullableInt32} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableInt32, 2));
};


/**
 * @param {?proto.market_api.NullableInt32|undefined} value
 * @return {!proto.market_api.MarketShareInput} returns this
*/
proto.market_api.MarketShareInput.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketShareInput} returns this
 */
proto.market_api.MarketShareInput.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketShareInput.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString industryIdStr = 3;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.MarketShareInput.prototype.getIndustryidstr = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 3));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.MarketShareInput} returns this
*/
proto.market_api.MarketShareInput.prototype.setIndustryidstr = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketShareInput} returns this
 */
proto.market_api.MarketShareInput.prototype.clearIndustryidstr = function() {
  return this.setIndustryidstr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketShareInput.prototype.hasIndustryidstr = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.MarketShareInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketShareInput} returns this
 */
proto.market_api.MarketShareInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketShareResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketShareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketShareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketShareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketShareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsharelistList: jspb.Message.toObjectList(msg.getMarketsharelistList(),
    proto.market_api.MarketSharePro.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketShareResponse}
 */
proto.market_api.MarketShareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketShareResponse;
  return proto.market_api.MarketShareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketShareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketShareResponse}
 */
proto.market_api.MarketShareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.MarketSharePro;
      reader.readMessage(value,proto.market_api.MarketSharePro.deserializeBinaryFromReader);
      msg.addMarketsharelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketShareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketShareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketShareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketShareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsharelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.MarketSharePro.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketSharePro marketShareList = 1;
 * @return {!Array<!proto.market_api.MarketSharePro>}
 */
proto.market_api.MarketShareResponse.prototype.getMarketsharelistList = function() {
  return /** @type{!Array<!proto.market_api.MarketSharePro>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketSharePro, 1));
};


/**
 * @param {!Array<!proto.market_api.MarketSharePro>} value
 * @return {!proto.market_api.MarketShareResponse} returns this
*/
proto.market_api.MarketShareResponse.prototype.setMarketsharelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.MarketSharePro=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketSharePro}
 */
proto.market_api.MarketShareResponse.prototype.addMarketsharelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.MarketSharePro, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketShareResponse} returns this
 */
proto.market_api.MarketShareResponse.prototype.clearMarketsharelistList = function() {
  return this.setMarketsharelistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketSharePro.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketSharePro.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketSharePro} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketSharePro.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sectype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    period: jspb.Message.getFieldWithDefault(msg, 4, 0),
    buyqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    buyamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sellqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    sellamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    netamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    highprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    lowprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    buyforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    sellforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketSharePro}
 */
proto.market_api.MarketSharePro.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketSharePro;
  return proto.market_api.MarketSharePro.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketSharePro} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketSharePro}
 */
proto.market_api.MarketSharePro.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSectype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellqty(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetamt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighprice(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignamt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignamt(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketSharePro.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketSharePro.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketSharePro} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketSharePro.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSectype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPeriod();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBuyqty();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBuyamt();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSellqty();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSellamt();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getNetamt();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getHighprice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getBuyforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getSellforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.MarketSharePro.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 secType = 2;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getSectype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setSectype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 type = 3;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 period = 4;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double buyQty = 5;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getBuyqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setBuyqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double buyAmt = 6;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getBuyamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setBuyamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double sellQty = 7;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getSellqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setSellqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double sellAmt = 8;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getSellamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setSellamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double netAmt = 9;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getNetamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setNetamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double openPrice = 10;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double closePrice = 11;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double highPrice = 12;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getHighprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setHighprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double lowPrice = 13;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getLowprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setLowprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double changePercent = 14;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double changePoint = 15;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double buyForeignQty = 16;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double buyForeignAmt = 17;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getBuyforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setBuyforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double sellForeignQty = 18;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double sellForeignAmt = 19;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getSellforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setSellforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double totalQty = 20;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double totalAmt = 21;
 * @return {number}
 */
proto.market_api.MarketSharePro.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketSharePro} returns this
 */
proto.market_api.MarketSharePro.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketShareProCapture.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketShareProCapture.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketShareProCapture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketShareProCapture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketShareProCapture.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    marketshareprolistList: jspb.Message.toObjectList(msg.getMarketshareprolistList(),
    proto.market_api.MarketSharePro.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketShareProCapture}
 */
proto.market_api.MarketShareProCapture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketShareProCapture;
  return proto.market_api.MarketShareProCapture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketShareProCapture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketShareProCapture}
 */
proto.market_api.MarketShareProCapture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = new proto.market_api.MarketSharePro;
      reader.readMessage(value,proto.market_api.MarketSharePro.deserializeBinaryFromReader);
      msg.addMarketshareprolist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketShareProCapture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketShareProCapture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketShareProCapture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketShareProCapture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMarketshareprolistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.market_api.MarketSharePro.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MarketShareProCapture.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketShareProCapture} returns this
 */
proto.market_api.MarketShareProCapture.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated MarketSharePro marketShareProList = 2;
 * @return {!Array<!proto.market_api.MarketSharePro>}
 */
proto.market_api.MarketShareProCapture.prototype.getMarketshareprolistList = function() {
  return /** @type{!Array<!proto.market_api.MarketSharePro>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketSharePro, 2));
};


/**
 * @param {!Array<!proto.market_api.MarketSharePro>} value
 * @return {!proto.market_api.MarketShareProCapture} returns this
*/
proto.market_api.MarketShareProCapture.prototype.setMarketshareprolistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.market_api.MarketSharePro=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketSharePro}
 */
proto.market_api.MarketShareProCapture.prototype.addMarketshareprolist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.market_api.MarketSharePro, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketShareProCapture} returns this
 */
proto.market_api.MarketShareProCapture.prototype.clearMarketshareprolistList = function() {
  return this.setMarketshareprolistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TimeSaleInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TimeSaleInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TimeSaleInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TimeSaleInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TimeSaleInput}
 */
proto.market_api.TimeSaleInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TimeSaleInput;
  return proto.market_api.TimeSaleInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TimeSaleInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TimeSaleInput}
 */
proto.market_api.TimeSaleInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TimeSaleInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TimeSaleInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TimeSaleInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TimeSaleInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTn();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.TimeSaleInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.TimeSaleInput} returns this
*/
proto.market_api.TimeSaleInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.TimeSaleInput} returns this
 */
proto.market_api.TimeSaleInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.TimeSaleInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.TimeSaleInput.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TimeSaleInput} returns this
 */
proto.market_api.TimeSaleInput.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 tn = 3;
 * @return {number}
 */
proto.market_api.TimeSaleInput.prototype.getTn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TimeSaleInput} returns this
 */
proto.market_api.TimeSaleInput.prototype.setTn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.TimeSaleInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TimeSaleInput} returns this
 */
proto.market_api.TimeSaleInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TimeSaleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TimeSaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TimeSaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TimeSaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TimeSaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sectimesalelistList: jspb.Message.toObjectList(msg.getSectimesalelistList(),
    proto.market_api.SecTimeSalePro.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TimeSaleResponse}
 */
proto.market_api.TimeSaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TimeSaleResponse;
  return proto.market_api.TimeSaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TimeSaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TimeSaleResponse}
 */
proto.market_api.TimeSaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.SecTimeSalePro;
      reader.readMessage(value,proto.market_api.SecTimeSalePro.deserializeBinaryFromReader);
      msg.addSectimesalelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TimeSaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TimeSaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TimeSaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TimeSaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectimesalelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.SecTimeSalePro.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SecTimeSalePro secTimeSaleList = 1;
 * @return {!Array<!proto.market_api.SecTimeSalePro>}
 */
proto.market_api.TimeSaleResponse.prototype.getSectimesalelistList = function() {
  return /** @type{!Array<!proto.market_api.SecTimeSalePro>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SecTimeSalePro, 1));
};


/**
 * @param {!Array<!proto.market_api.SecTimeSalePro>} value
 * @return {!proto.market_api.TimeSaleResponse} returns this
*/
proto.market_api.TimeSaleResponse.prototype.setSectimesalelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.SecTimeSalePro=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SecTimeSalePro}
 */
proto.market_api.TimeSaleResponse.prototype.addSectimesalelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.SecTimeSalePro, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TimeSaleResponse} returns this
 */
proto.market_api.TimeSaleResponse.prototype.clearSectimesalelistList = function() {
  return this.setSectimesalelistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.InternalMSInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.InternalMSInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.InternalMSInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.InternalMSInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    side: jspb.Message.getFieldWithDefault(msg, 2, 0),
    indexcdstr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    industryidstr: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderfieldtype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 9, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.InternalMSInput}
 */
proto.market_api.InternalMSInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.InternalMSInput;
  return proto.market_api.InternalMSInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.InternalMSInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.InternalMSInput}
 */
proto.market_api.InternalMSInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSide(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexcdstr(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustryidstr(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderfieldtype(value);
      break;
    case 7:
      var value = /** @type {!proto.market_api.ORDER_TYPE} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.InternalMSInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.InternalMSInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.InternalMSInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.InternalMSInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSide();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIndexcdstr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIndustryidstr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrderfieldtype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.InternalMSInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.InternalMSInput} returns this
*/
proto.market_api.InternalMSInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.InternalMSInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 side = 2;
 * @return {number}
 */
proto.market_api.InternalMSInput.prototype.getSide = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setSide = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string indexCdStr = 3;
 * @return {string}
 */
proto.market_api.InternalMSInput.prototype.getIndexcdstr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setIndexcdstr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string industryIDStr = 4;
 * @return {string}
 */
proto.market_api.InternalMSInput.prototype.getIndustryidstr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setIndustryidstr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 type = 5;
 * @return {number}
 */
proto.market_api.InternalMSInput.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 orderFieldType = 6;
 * @return {number}
 */
proto.market_api.InternalMSInput.prototype.getOrderfieldtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setOrderfieldtype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ORDER_TYPE orderType = 7;
 * @return {!proto.market_api.ORDER_TYPE}
 */
proto.market_api.InternalMSInput.prototype.getOrdertype = function() {
  return /** @type {!proto.market_api.ORDER_TYPE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.market_api.ORDER_TYPE} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 offset = 8;
 * @return {number}
 */
proto.market_api.InternalMSInput.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 limit = 9;
 * @return {number}
 */
proto.market_api.InternalMSInput.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string authenCode = 10;
 * @return {string}
 */
proto.market_api.InternalMSInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.InternalMSInput} returns this
 */
proto.market_api.InternalMSInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.InternalMS.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.InternalMS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.InternalMS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.InternalMS.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    netforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    buyforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    sellforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    netforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.InternalMS}
 */
proto.market_api.InternalMS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.InternalMS;
  return proto.market_api.InternalMS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.InternalMS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.InternalMS}
 */
proto.market_api.InternalMS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 5:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetforeignqty(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignamt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignamt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetforeignamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.InternalMS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.InternalMS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.InternalMS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.InternalMS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getNetforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getBuyforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getSellforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getNetforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.InternalMS.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double changePercent = 2;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double changePoint = 3;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double closePrice = 4;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 5;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.InternalMS.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional double buyForeignQty = 6;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double sellForeignQty = 7;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double netForeignQty = 8;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getNetforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setNetforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double buyForeignAmt = 9;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getBuyforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setBuyforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double sellForeignAmt = 10;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getSellforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setSellforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double netForeignAmt = 11;
 * @return {number}
 */
proto.market_api.InternalMS.prototype.getNetforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMS} returns this
 */
proto.market_api.InternalMS.prototype.setNetforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.InternalMSResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.InternalMSResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.InternalMSResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.InternalMSResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.InternalMSResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    internalmslistList: jspb.Message.toObjectList(msg.getInternalmslistList(),
    proto.market_api.InternalMS.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.InternalMSResponse}
 */
proto.market_api.InternalMSResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.InternalMSResponse;
  return proto.market_api.InternalMSResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.InternalMSResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.InternalMSResponse}
 */
proto.market_api.InternalMSResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.InternalMS;
      reader.readMessage(value,proto.market_api.InternalMS.deserializeBinaryFromReader);
      msg.addInternalmslist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.InternalMSResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.InternalMSResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.InternalMSResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.InternalMSResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInternalmslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.InternalMS.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.InternalMSResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.InternalMSResponse} returns this
 */
proto.market_api.InternalMSResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.InternalMSResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.InternalMSResponse} returns this
 */
proto.market_api.InternalMSResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.InternalMSResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.InternalMSResponse} returns this
 */
proto.market_api.InternalMSResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated InternalMS internalMSList = 4;
 * @return {!Array<!proto.market_api.InternalMS>}
 */
proto.market_api.InternalMSResponse.prototype.getInternalmslistList = function() {
  return /** @type{!Array<!proto.market_api.InternalMS>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.InternalMS, 4));
};


/**
 * @param {!Array<!proto.market_api.InternalMS>} value
 * @return {!proto.market_api.InternalMSResponse} returns this
*/
proto.market_api.InternalMSResponse.prototype.setInternalmslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.InternalMS=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.InternalMS}
 */
proto.market_api.InternalMSResponse.prototype.addInternalmslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.InternalMS, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.InternalMSResponse} returns this
 */
proto.market_api.InternalMSResponse.prototype.clearInternalmslistList = function() {
  return this.setInternalmslistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopSecChangedInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopSecChangedInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopSecChangedInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecChangedInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changedtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    periodtype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderfieldtype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 8, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopSecChangedInput}
 */
proto.market_api.TopSecChangedInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopSecChangedInput;
  return proto.market_api.TopSecChangedInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopSecChangedInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopSecChangedInput}
 */
proto.market_api.TopSecChangedInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChangedtype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriodtype(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderfieldtype(value);
      break;
    case 6:
      var value = /** @type {!proto.market_api.ORDER_TYPE} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopSecChangedInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopSecChangedInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopSecChangedInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecChangedInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangedtype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPeriodtype();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrderfieldtype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.TopSecChangedInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string marketCd = 2;
 * @return {string}
 */
proto.market_api.TopSecChangedInput.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 changedType = 3;
 * @return {number}
 */
proto.market_api.TopSecChangedInput.prototype.getChangedtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setChangedtype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 periodType = 4;
 * @return {number}
 */
proto.market_api.TopSecChangedInput.prototype.getPeriodtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setPeriodtype = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 orderFieldType = 5;
 * @return {number}
 */
proto.market_api.TopSecChangedInput.prototype.getOrderfieldtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setOrderfieldtype = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ORDER_TYPE orderType = 6;
 * @return {!proto.market_api.ORDER_TYPE}
 */
proto.market_api.TopSecChangedInput.prototype.getOrdertype = function() {
  return /** @type {!proto.market_api.ORDER_TYPE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.market_api.ORDER_TYPE} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 offset = 7;
 * @return {number}
 */
proto.market_api.TopSecChangedInput.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 limit = 8;
 * @return {number}
 */
proto.market_api.TopSecChangedInput.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string authenCode = 9;
 * @return {string}
 */
proto.market_api.TopSecChangedInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecChangedInput} returns this
 */
proto.market_api.TopSecChangedInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopSecChangedResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopSecChangedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopSecChangedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopSecChangedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecChangedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    topsecchangedList: jspb.Message.toObjectList(msg.getTopsecchangedList(),
    proto.market_api.TopSecChangedResponse.TopSecChangedInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopSecChangedResponse}
 */
proto.market_api.TopSecChangedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopSecChangedResponse;
  return proto.market_api.TopSecChangedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopSecChangedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopSecChangedResponse}
 */
proto.market_api.TopSecChangedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopSecChangedResponse.TopSecChangedInfo;
      reader.readMessage(value,proto.market_api.TopSecChangedResponse.TopSecChangedInfo.deserializeBinaryFromReader);
      msg.addTopsecchanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopSecChangedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopSecChangedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopSecChangedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecChangedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTopsecchangedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopSecChangedResponse.TopSecChangedInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopSecChangedResponse.TopSecChangedInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    changehighlow: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopSecChangedResponse.TopSecChangedInfo;
  return proto.market_api.TopSecChangedResponse.TopSecChangedInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangehighlow(value);
      break;
    case 8:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopSecChangedResponse.TopSecChangedInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getChangehighlow();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totalAmt = 2;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double totalQty = 3;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double closePrice = 4;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double changePoint = 5;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double changePercent = 6;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double changeHighLow = 7;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getChangehighlow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setChangehighlow = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 8;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo} returns this
 */
proto.market_api.TopSecChangedResponse.TopSecChangedInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.TopSecChangedResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopSecChangedResponse} returns this
 */
proto.market_api.TopSecChangedResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopSecChangedResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecChangedResponse} returns this
 */
proto.market_api.TopSecChangedResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopSecChangedResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopSecChangedResponse} returns this
 */
proto.market_api.TopSecChangedResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TopSecChangedInfo topSecChanged = 4;
 * @return {!Array<!proto.market_api.TopSecChangedResponse.TopSecChangedInfo>}
 */
proto.market_api.TopSecChangedResponse.prototype.getTopsecchangedList = function() {
  return /** @type{!Array<!proto.market_api.TopSecChangedResponse.TopSecChangedInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopSecChangedResponse.TopSecChangedInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.TopSecChangedResponse.TopSecChangedInfo>} value
 * @return {!proto.market_api.TopSecChangedResponse} returns this
*/
proto.market_api.TopSecChangedResponse.prototype.setTopsecchangedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopSecChangedResponse.TopSecChangedInfo}
 */
proto.market_api.TopSecChangedResponse.prototype.addTopsecchanged = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopSecChangedResponse.TopSecChangedInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopSecChangedResponse} returns this
 */
proto.market_api.TopSecChangedResponse.prototype.clearTopsecchangedList = function() {
  return this.setTopsecchangedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecDividendInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecDividendInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecDividendInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecDividendInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecDividendInput}
 */
proto.market_api.SecDividendInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecDividendInput;
  return proto.market_api.SecDividendInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecDividendInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecDividendInput}
 */
proto.market_api.SecDividendInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecDividendInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecDividendInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecDividendInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecDividendInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.SecDividendInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecDividendInput} returns this
 */
proto.market_api.SecDividendInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecDividendInput.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecDividendInput} returns this
 */
proto.market_api.SecDividendInput.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.SecDividendInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecDividendInput} returns this
 */
proto.market_api.SecDividendInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecDividendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecDividendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecDividendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecDividendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secdividendinfo: (f = msg.getSecdividendinfo()) && proto.market_api.SecDividendInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecDividendResponse}
 */
proto.market_api.SecDividendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecDividendResponse;
  return proto.market_api.SecDividendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecDividendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecDividendResponse}
 */
proto.market_api.SecDividendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SecDividendInfo;
      reader.readMessage(value,proto.market_api.SecDividendInfo.deserializeBinaryFromReader);
      msg.setSecdividendinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecDividendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecDividendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecDividendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecDividendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecdividendinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.SecDividendInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.SecDividendResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendResponse} returns this
 */
proto.market_api.SecDividendResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SecDividendResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecDividendResponse} returns this
 */
proto.market_api.SecDividendResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SecDividendResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecDividendResponse} returns this
 */
proto.market_api.SecDividendResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SecDividendInfo secDividendInfo = 4;
 * @return {?proto.market_api.SecDividendInfo}
 */
proto.market_api.SecDividendResponse.prototype.getSecdividendinfo = function() {
  return /** @type{?proto.market_api.SecDividendInfo} */ (
    jspb.Message.getWrapperField(this, proto.market_api.SecDividendInfo, 4));
};


/**
 * @param {?proto.market_api.SecDividendInfo|undefined} value
 * @return {!proto.market_api.SecDividendResponse} returns this
*/
proto.market_api.SecDividendResponse.prototype.setSecdividendinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SecDividendResponse} returns this
 */
proto.market_api.SecDividendResponse.prototype.clearSecdividendinfo = function() {
  return this.setSecdividendinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SecDividendResponse.prototype.hasSecdividendinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketIndexInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketIndexInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketIndexInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketIndexInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    indexcdlist: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketIndexInput}
 */
proto.market_api.MarketIndexInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketIndexInput;
  return proto.market_api.MarketIndexInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketIndexInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketIndexInput}
 */
proto.market_api.MarketIndexInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexcdlist(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketIndexInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketIndexInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketIndexInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketIndexInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndexcdlist();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.MarketIndexInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketIndexInput} returns this
 */
proto.market_api.MarketIndexInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string indexCdList = 2;
 * @return {string}
 */
proto.market_api.MarketIndexInput.prototype.getIndexcdlist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketIndexInput} returns this
 */
proto.market_api.MarketIndexInput.prototype.setIndexcdlist = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.MarketIndexInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketIndexInput} returns this
 */
proto.market_api.MarketIndexInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketIndexInfo.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketIndexInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketIndexInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketIndexInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketIndexInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    marketindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    changeindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 8, 0),
    highestindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    lowestindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totalbuyforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    totalsellforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    advances: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    declenes: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    nochange: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    indextimeList: jspb.Message.toObjectList(msg.getIndextimeList(),
    proto.market_api.MarketIndexInfo.IndexTime.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketIndexInfo}
 */
proto.market_api.MarketIndexInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketIndexInfo;
  return proto.market_api.MarketIndexInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketIndexInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketIndexInfo}
 */
proto.market_api.MarketIndexInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenindex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketindex(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeindex(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 8:
      var value = /** @type {!proto.market_api.INDEX_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestindex(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestindex(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbuyforeignamt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsellforeignamt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdvances(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeclenes(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNochange(value);
      break;
    case 16:
      var value = new proto.market_api.MarketIndexInfo.IndexTime;
      reader.readMessage(value,proto.market_api.MarketIndexInfo.IndexTime.deserializeBinaryFromReader);
      msg.addIndextime(value);
      break;
    case 17:
      var value = /** @type {!proto.market_api.INDEX_STATE} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketIndexInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketIndexInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketIndexInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketIndexInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOpenindex();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMarketindex();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getChangeindex();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getHighestindex();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLowestindex();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getTotalbuyforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getTotalsellforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getAdvances();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDeclenes();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getNochange();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getIndextimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.market_api.MarketIndexInfo.IndexTime.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketIndexInfo.IndexTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketIndexInfo.IndexTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketIndexInfo.IndexTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    indexval: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    qtyval: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketIndexInfo.IndexTime}
 */
proto.market_api.MarketIndexInfo.IndexTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketIndexInfo.IndexTime;
  return proto.market_api.MarketIndexInfo.IndexTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketIndexInfo.IndexTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketIndexInfo.IndexTime}
 */
proto.market_api.MarketIndexInfo.IndexTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndexval(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQtyval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketIndexInfo.IndexTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketIndexInfo.IndexTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketIndexInfo.IndexTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIndexval();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getQtyval();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo.IndexTime} returns this
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double indexVal = 2;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.getIndexval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo.IndexTime} returns this
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.setIndexval = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double qtyVal = 3;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.getQtyval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo.IndexTime} returns this
 */
proto.market_api.MarketIndexInfo.IndexTime.prototype.setQtyval = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 indexCd = 1;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double openIndex = 2;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getOpenindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setOpenindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double marketIndex = 3;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getMarketindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setMarketindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double changeIndex = 4;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getChangeindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setChangeindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double changePercent = 5;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double totalQty = 6;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double totalAmt = 7;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional INDEX_COLOR_CODE colorCode = 8;
 * @return {!proto.market_api.INDEX_COLOR_CODE}
 */
proto.market_api.MarketIndexInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.INDEX_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.market_api.INDEX_COLOR_CODE} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional double highestIndex = 9;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getHighestindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setHighestindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double lowestIndex = 10;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getLowestindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setLowestindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double totalBuyForeignAmt = 11;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getTotalbuyforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setTotalbuyforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double totalSellForeignAmt = 12;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getTotalsellforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setTotalsellforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double advances = 13;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getAdvances = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setAdvances = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double declenes = 14;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getDeclenes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setDeclenes = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double noChange = 15;
 * @return {number}
 */
proto.market_api.MarketIndexInfo.prototype.getNochange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setNochange = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * repeated IndexTime indexTime = 16;
 * @return {!Array<!proto.market_api.MarketIndexInfo.IndexTime>}
 */
proto.market_api.MarketIndexInfo.prototype.getIndextimeList = function() {
  return /** @type{!Array<!proto.market_api.MarketIndexInfo.IndexTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketIndexInfo.IndexTime, 16));
};


/**
 * @param {!Array<!proto.market_api.MarketIndexInfo.IndexTime>} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
*/
proto.market_api.MarketIndexInfo.prototype.setIndextimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.market_api.MarketIndexInfo.IndexTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketIndexInfo.IndexTime}
 */
proto.market_api.MarketIndexInfo.prototype.addIndextime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.market_api.MarketIndexInfo.IndexTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.clearIndextimeList = function() {
  return this.setIndextimeList([]);
};


/**
 * optional INDEX_STATE state = 17;
 * @return {!proto.market_api.INDEX_STATE}
 */
proto.market_api.MarketIndexInfo.prototype.getState = function() {
  return /** @type {!proto.market_api.INDEX_STATE} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.market_api.INDEX_STATE} value
 * @return {!proto.market_api.MarketIndexInfo} returns this
 */
proto.market_api.MarketIndexInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketWorldIndexInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketWorldIndexInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketWorldIndexInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketWorldIndexInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketWorldIndexInput}
 */
proto.market_api.MarketWorldIndexInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketWorldIndexInput;
  return proto.market_api.MarketWorldIndexInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketWorldIndexInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketWorldIndexInput}
 */
proto.market_api.MarketWorldIndexInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketWorldIndexInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketWorldIndexInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketWorldIndexInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketWorldIndexInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.MarketWorldIndexInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketWorldIndexInput} returns this
 */
proto.market_api.MarketWorldIndexInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authenCode = 2;
 * @return {string}
 */
proto.market_api.MarketWorldIndexInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketWorldIndexInput} returns this
 */
proto.market_api.MarketWorldIndexInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketWorldIndexInfo.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketWorldIndexInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketWorldIndexInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketWorldIndexInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketWorldIndexInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    flag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    open: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    last: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    chgpct: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    chg: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    volume: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 11, 0),
    indextimeList: jspb.Message.toObjectList(msg.getIndextimeList(),
    proto.market_api.MarketWorldIndexInfo.IndexTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketWorldIndexInfo}
 */
proto.market_api.MarketWorldIndexInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketWorldIndexInfo;
  return proto.market_api.MarketWorldIndexInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketWorldIndexInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketWorldIndexInfo}
 */
proto.market_api.MarketWorldIndexInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlag(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpen(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChgpct(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChg(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 11:
      var value = /** @type {!proto.market_api.INDEX_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 12:
      var value = new proto.market_api.MarketWorldIndexInfo.IndexTime;
      reader.readMessage(value,proto.market_api.MarketWorldIndexInfo.IndexTime.deserializeBinaryFromReader);
      msg.addIndextime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketWorldIndexInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketWorldIndexInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketWorldIndexInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketWorldIndexInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFlag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLast();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getChgpct();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getChg();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getIndextimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.market_api.MarketWorldIndexInfo.IndexTime.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketWorldIndexInfo.IndexTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketWorldIndexInfo.IndexTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    val: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketWorldIndexInfo.IndexTime}
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketWorldIndexInfo.IndexTime;
  return proto.market_api.MarketWorldIndexInfo.IndexTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketWorldIndexInfo.IndexTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketWorldIndexInfo.IndexTime}
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketWorldIndexInfo.IndexTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketWorldIndexInfo.IndexTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVal();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo.IndexTime} returns this
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double val = 2;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.prototype.getVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo.IndexTime} returns this
 */
proto.market_api.MarketWorldIndexInfo.IndexTime.prototype.setVal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 indexCd = 1;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double time = 3;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string flag = 4;
 * @return {string}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getFlag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setFlag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double open = 5;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double last = 7;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getLast = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setLast = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double chgPct = 8;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getChgpct = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setChgpct = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double chg = 9;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getChg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setChg = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double volume = 10;
 * @return {number}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional INDEX_COLOR_CODE colorCode = 11;
 * @return {!proto.market_api.INDEX_COLOR_CODE}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.INDEX_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.market_api.INDEX_COLOR_CODE} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated IndexTime indexTime = 12;
 * @return {!Array<!proto.market_api.MarketWorldIndexInfo.IndexTime>}
 */
proto.market_api.MarketWorldIndexInfo.prototype.getIndextimeList = function() {
  return /** @type{!Array<!proto.market_api.MarketWorldIndexInfo.IndexTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketWorldIndexInfo.IndexTime, 12));
};


/**
 * @param {!Array<!proto.market_api.MarketWorldIndexInfo.IndexTime>} value
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
*/
proto.market_api.MarketWorldIndexInfo.prototype.setIndextimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.market_api.MarketWorldIndexInfo.IndexTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketWorldIndexInfo.IndexTime}
 */
proto.market_api.MarketWorldIndexInfo.prototype.addIndextime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.market_api.MarketWorldIndexInfo.IndexTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketWorldIndexInfo} returns this
 */
proto.market_api.MarketWorldIndexInfo.prototype.clearIndextimeList = function() {
  return this.setIndextimeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecQuotesDetailInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecQuotesDetailInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecQuotesDetailInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesDetailInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecQuotesDetailInput}
 */
proto.market_api.SecQuotesDetailInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecQuotesDetailInput;
  return proto.market_api.SecQuotesDetailInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecQuotesDetailInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecQuotesDetailInput}
 */
proto.market_api.SecQuotesDetailInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecQuotesDetailInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecQuotesDetailInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecQuotesDetailInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesDetailInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.SecQuotesDetailInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailInput} returns this
 */
proto.market_api.SecQuotesDetailInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecQuotesDetailInput.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailInput} returns this
 */
proto.market_api.SecQuotesDetailInput.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.SecQuotesDetailInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailInput} returns this
 */
proto.market_api.SecQuotesDetailInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecQuotesDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecQuotesDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecQuotesDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secdetailinfo: (f = msg.getSecdetailinfo()) && proto.market_api.SecQuotesDetailResponse.SecDetailInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecQuotesDetailResponse}
 */
proto.market_api.SecQuotesDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecQuotesDetailResponse;
  return proto.market_api.SecQuotesDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecQuotesDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecQuotesDetailResponse}
 */
proto.market_api.SecQuotesDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SecQuotesDetailResponse.SecDetailInfo;
      reader.readMessage(value,proto.market_api.SecQuotesDetailResponse.SecDetailInfo.deserializeBinaryFromReader);
      msg.setSecdetailinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecQuotesDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecQuotesDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecQuotesDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecdetailinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.SecQuotesDetailResponse.SecDetailInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecQuotesDetailResponse.SecDetailInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sectype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    basicprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ceilingprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floorprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    highestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lowestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    avgprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    lastqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    totalptqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    totalptamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totalroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    currentroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    w52high: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    w52low: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    best1bidpricestr: jspb.Message.getFieldWithDefault(msg, 25, ""),
    best1bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    best1bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    best2bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    best2bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    best3bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    best3bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    best4bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    best1offerpricestr: jspb.Message.getFieldWithDefault(msg, 33, ""),
    best1offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    best1offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    best2offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    best2offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    best3offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    best3offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    best4offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    listedqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    tradeqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 42, 0.0),
    tradepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 43, 0.0),
    esp: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    pe: jspb.Message.getFloatingPointFieldWithDefault(msg, 45, 0.0),
    pb: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    ps: jspb.Message.getFloatingPointFieldWithDefault(msg, 47, 0.0),
    bookvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    dividendamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    dividendrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    openprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0),
    closeprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 52, 0.0),
    roe: jspb.Message.getFloatingPointFieldWithDefault(msg, 53, 0.0),
    roa: jspb.Message.getFloatingPointFieldWithDefault(msg, 54, 0.0),
    turnoverrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 56, 0.0),
    secnameen: jspb.Message.getFieldWithDefault(msg, 57, ""),
    baseseccd: (f = msg.getBaseseccd()) && proto.market_api.NullableString.toObject(includeInstance, f),
    issuer: (f = msg.getIssuer()) && proto.market_api.NullableString.toObject(includeInstance, f),
    baseprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 60, 0.0),
    execprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 61, 0.0),
    basepricecp: jspb.Message.getFloatingPointFieldWithDefault(msg, 62, 0.0),
    bfprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 63, 0.0),
    bbprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 64, 0.0),
    bcprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 65, 0.0),
    lasttradedate: jspb.Message.getFieldWithDefault(msg, 66, 0),
    basesecpricecolor: jspb.Message.getFieldWithDefault(msg, 67, 0),
    hasrightexec: jspb.Message.getFieldWithDefault(msg, 68, 0),
    execratio: (f = msg.getExecratio()) && proto.market_api.NullableString.toObject(includeInstance, f),
    marketcap: jspb.Message.getFloatingPointFieldWithDefault(msg, 70, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 71, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecQuotesDetailResponse.SecDetailInfo;
  return proto.market_api.SecQuotesDetailResponse.SecDetailInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSectype(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasicprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilingprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloorprice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestprice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastqty(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalptqty(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalptamt(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalroom(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentroom(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setW52high(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setW52low(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBest1bidpricestr(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1bidprice(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1bidqty(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2bidprice(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2bidqty(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3bidprice(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3bidqty(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest4bidqty(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBest1offerpricestr(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1offerprice(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1offerqty(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2offerprice(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2offerqty(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3offerprice(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3offerqty(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest4offerqty(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedqty(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeqty(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradepercent(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEsp(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPe(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPb(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPs(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBookvalue(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDividendamt(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDividendrate(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoe(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoa(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnoverrate(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRange(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecnameen(value);
      break;
    case 58:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setBaseseccd(value);
      break;
    case 59:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setIssuer(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseprice(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExecprice(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasepricecp(value);
      break;
    case 63:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBfprice(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBbprice(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBcprice(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLasttradedate(value);
      break;
    case 67:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setBasesecpricecolor(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHasrightexec(value);
      break;
    case 69:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setExecratio(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcap(value);
      break;
    case 71:
      var value = /** @type {!proto.market_api.INDEX_STATE} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecQuotesDetailResponse.SecDetailInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSectype();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBasicprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCeilingprice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFloorprice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHighestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLowestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAvgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getLastqty();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getTotalptqty();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getTotalptamt();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getTotalroom();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getCurrentroom();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getW52high();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getW52low();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getBest1bidpricestr();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getBest1bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getBest1bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getBest2bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getBest2bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getBest3bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getBest3bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getBest4bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getBest1offerpricestr();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBest1offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getBest1offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getBest2offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getBest2offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getBest3offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getBest3offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      39,
      f
    );
  }
  f = message.getBest4offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      40,
      f
    );
  }
  f = message.getListedqty();
  if (f !== 0.0) {
    writer.writeDouble(
      41,
      f
    );
  }
  f = message.getTradeqty();
  if (f !== 0.0) {
    writer.writeDouble(
      42,
      f
    );
  }
  f = message.getTradepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      43,
      f
    );
  }
  f = message.getEsp();
  if (f !== 0.0) {
    writer.writeDouble(
      44,
      f
    );
  }
  f = message.getPe();
  if (f !== 0.0) {
    writer.writeDouble(
      45,
      f
    );
  }
  f = message.getPb();
  if (f !== 0.0) {
    writer.writeDouble(
      46,
      f
    );
  }
  f = message.getPs();
  if (f !== 0.0) {
    writer.writeDouble(
      47,
      f
    );
  }
  f = message.getBookvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getDividendamt();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getDividendrate();
  if (f !== 0.0) {
    writer.writeDouble(
      50,
      f
    );
  }
  f = message.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      51,
      f
    );
  }
  f = message.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      52,
      f
    );
  }
  f = message.getRoe();
  if (f !== 0.0) {
    writer.writeDouble(
      53,
      f
    );
  }
  f = message.getRoa();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getTurnoverrate();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeDouble(
      56,
      f
    );
  }
  f = message.getSecnameen();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getBaseseccd();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIssuer();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getBaseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      60,
      f
    );
  }
  f = message.getExecprice();
  if (f !== 0.0) {
    writer.writeDouble(
      61,
      f
    );
  }
  f = message.getBasepricecp();
  if (f !== 0.0) {
    writer.writeDouble(
      62,
      f
    );
  }
  f = message.getBfprice();
  if (f !== 0.0) {
    writer.writeDouble(
      63,
      f
    );
  }
  f = message.getBbprice();
  if (f !== 0.0) {
    writer.writeDouble(
      64,
      f
    );
  }
  f = message.getBcprice();
  if (f !== 0.0) {
    writer.writeDouble(
      65,
      f
    );
  }
  f = message.getLasttradedate();
  if (f !== 0) {
    writer.writeInt32(
      66,
      f
    );
  }
  f = message.getBasesecpricecolor();
  if (f !== 0.0) {
    writer.writeEnum(
      67,
      f
    );
  }
  f = message.getHasrightexec();
  if (f !== 0) {
    writer.writeInt32(
      68,
      f
    );
  }
  f = message.getExecratio();
  if (f != null) {
    writer.writeMessage(
      69,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getMarketcap();
  if (f !== 0.0) {
    writer.writeDouble(
      70,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      71,
      f
    );
  }
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secName = 3;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getSecname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setSecname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 secType = 4;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getSectype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setSectype = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double basicPrice = 5;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBasicprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBasicprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double ceilingPrice = 6;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getCeilingprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setCeilingprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double floorPrice = 7;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getFloorprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setFloorprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double highestPrice = 8;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getHighestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setHighestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double lowestPrice = 9;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getLowestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setLowestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double avgPrice = 10;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getAvgprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setAvgprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double lastPrice = 11;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double lastQty = 12;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getLastqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setLastqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double totalQty = 13;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double totalAmt = 14;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double totalPtQty = 15;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTotalptqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTotalptqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double totalPtAmt = 16;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTotalptamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTotalptamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double sellForeignQty = 17;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double buyForeignQty = 18;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double changePoint = 19;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double changePercent = 20;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double totalRoom = 21;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTotalroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTotalroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double currentRoom = 22;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getCurrentroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setCurrentroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double w52High = 23;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getW52high = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setW52high = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double w52Low = 24;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getW52low = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setW52low = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional string best1BidPriceStr = 25;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest1bidpricestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest1bidpricestr = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional double best1BidPrice = 26;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest1bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest1bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double best1BidQty = 27;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest1bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest1bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double best2BidPrice = 28;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest2bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest2bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double best2BidQty = 29;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest2bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest2bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double best3BidPrice = 30;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest3bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest3bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double best3BidQty = 31;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest3bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest3bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double best4BidQty = 32;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest4bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest4bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional string best1OfferPriceStr = 33;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest1offerpricestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest1offerpricestr = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional double best1OfferPrice = 34;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest1offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest1offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double best1OfferQty = 35;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest1offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest1offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double best2OfferPrice = 36;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest2offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest2offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double best2OfferQty = 37;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest2offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest2offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double best3OfferPrice = 38;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest3offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest3offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional double best3OfferQty = 39;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest3offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest3offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional double best4OfferQty = 40;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBest4offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBest4offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional double listedQty = 41;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getListedqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setListedqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional double tradeQty = 42;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTradeqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTradeqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 42, value);
};


/**
 * optional double tradePercent = 43;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTradepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 43, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTradepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 43, value);
};


/**
 * optional double esp = 44;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getEsp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setEsp = function(value) {
  return jspb.Message.setProto3FloatField(this, 44, value);
};


/**
 * optional double pe = 45;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getPe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 45, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setPe = function(value) {
  return jspb.Message.setProto3FloatField(this, 45, value);
};


/**
 * optional double pb = 46;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getPb = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setPb = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional double ps = 47;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getPs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 47, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setPs = function(value) {
  return jspb.Message.setProto3FloatField(this, 47, value);
};


/**
 * optional double bookValue = 48;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBookvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBookvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional double dividendAmt = 49;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getDividendamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setDividendamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional double dividendRate = 50;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getDividendrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setDividendrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional double openPrice = 51;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getOpenprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setOpenprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional double closePrice = 52;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getCloseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 52, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setCloseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional double roe = 53;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getRoe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 53, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setRoe = function(value) {
  return jspb.Message.setProto3FloatField(this, 53, value);
};


/**
 * optional double roa = 54;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getRoa = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 54, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setRoa = function(value) {
  return jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double turnoverRate = 55;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getTurnoverrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setTurnoverrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional double range = 56;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 56, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 56, value);
};


/**
 * optional string secNameEn = 57;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getSecnameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setSecnameen = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional NullableString baseSecCd = 58;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBaseseccd = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 58));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
*/
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBaseseccd = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.clearBaseseccd = function() {
  return this.setBaseseccd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.hasBaseseccd = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional NullableString issuer = 59;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getIssuer = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 59));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
*/
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setIssuer = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.clearIssuer = function() {
  return this.setIssuer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.hasIssuer = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional double basePrice = 60;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBaseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 60, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBaseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 60, value);
};


/**
 * optional double execPrice = 61;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getExecprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 61, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setExecprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 61, value);
};


/**
 * optional double basePriceCp = 62;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBasepricecp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 62, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBasepricecp = function(value) {
  return jspb.Message.setProto3FloatField(this, 62, value);
};


/**
 * optional double bfPrice = 63;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBfprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 63, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBfprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 63, value);
};


/**
 * optional double bbPrice = 64;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBbprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 64, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBbprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 64, value);
};


/**
 * optional double bcPrice = 65;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBcprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 65, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBcprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 65, value);
};


/**
 * optional int32 lastTradeDate = 66;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getLasttradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setLasttradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 66, value);
};


/**
 * optional STOCK_COLOR_CODE baseSecPriceColor = 67;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getBasesecpricecolor = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setBasesecpricecolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 67, value);
};


/**
 * optional int32 hasRightExec = 68;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getHasrightexec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setHasrightexec = function(value) {
  return jspb.Message.setProto3IntField(this, 68, value);
};


/**
 * optional NullableString execRatio = 69;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getExecratio = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 69));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
*/
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setExecratio = function(value) {
  return jspb.Message.setWrapperField(this, 69, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.clearExecratio = function() {
  return this.setExecratio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.hasExecratio = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional double marketCap = 70;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getMarketcap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 70, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setMarketcap = function(value) {
  return jspb.Message.setProto3FloatField(this, 70, value);
};


/**
 * optional INDEX_STATE status = 71;
 * @return {!proto.market_api.INDEX_STATE}
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.getStatus = function() {
  return /** @type {!proto.market_api.INDEX_STATE} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {!proto.market_api.INDEX_STATE} value
 * @return {!proto.market_api.SecQuotesDetailResponse.SecDetailInfo} returns this
 */
proto.market_api.SecQuotesDetailResponse.SecDetailInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 71, value);
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.SecQuotesDetailResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesDetailResponse} returns this
 */
proto.market_api.SecQuotesDetailResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse} returns this
 */
proto.market_api.SecQuotesDetailResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SecQuotesDetailResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesDetailResponse} returns this
 */
proto.market_api.SecQuotesDetailResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SecDetailInfo secDetailInfo = 4;
 * @return {?proto.market_api.SecQuotesDetailResponse.SecDetailInfo}
 */
proto.market_api.SecQuotesDetailResponse.prototype.getSecdetailinfo = function() {
  return /** @type{?proto.market_api.SecQuotesDetailResponse.SecDetailInfo} */ (
    jspb.Message.getWrapperField(this, proto.market_api.SecQuotesDetailResponse.SecDetailInfo, 4));
};


/**
 * @param {?proto.market_api.SecQuotesDetailResponse.SecDetailInfo|undefined} value
 * @return {!proto.market_api.SecQuotesDetailResponse} returns this
*/
proto.market_api.SecQuotesDetailResponse.prototype.setSecdetailinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SecQuotesDetailResponse} returns this
 */
proto.market_api.SecQuotesDetailResponse.prototype.clearSecdetailinfo = function() {
  return this.setSecdetailinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SecQuotesDetailResponse.prototype.hasSecdetailinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketQuotesInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketQuotesInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketQuotesInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketQuotesInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    indexcd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketQuotesInput}
 */
proto.market_api.MarketQuotesInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketQuotesInput;
  return proto.market_api.MarketQuotesInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketQuotesInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketQuotesInput}
 */
proto.market_api.MarketQuotesInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexcd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketQuotesInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketQuotesInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketQuotesInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketQuotesInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndexcd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.MarketQuotesInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketQuotesInput} returns this
 */
proto.market_api.MarketQuotesInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string indexCd = 2;
 * @return {string}
 */
proto.market_api.MarketQuotesInput.prototype.getIndexcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketQuotesInput} returns this
 */
proto.market_api.MarketQuotesInput.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.MarketQuotesInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketQuotesInput} returns this
 */
proto.market_api.MarketQuotesInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketQuotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketQuotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketQuotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketQuotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    marketquotesinfo: (f = msg.getMarketquotesinfo()) && proto.market_api.MarketQuotesResponse.MarketQuotesInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketQuotesResponse}
 */
proto.market_api.MarketQuotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketQuotesResponse;
  return proto.market_api.MarketQuotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketQuotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketQuotesResponse}
 */
proto.market_api.MarketQuotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketQuotesResponse.MarketQuotesInfo;
      reader.readMessage(value,proto.market_api.MarketQuotesResponse.MarketQuotesInfo.deserializeBinaryFromReader);
      msg.setMarketquotesinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketQuotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketQuotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketQuotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketQuotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMarketquotesinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.MarketQuotesResponse.MarketQuotesInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketQuotesResponse.MarketQuotesInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totaltrade: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totaltradept: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalamtpt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalqtypt: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    marketindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    changeindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    changeindexpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    openindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    highestindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    lowestindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    advances: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    nochange: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    declenes: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    floor: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    ceiling: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totalbuy: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totalsell: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    totalbuyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    totalsellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    totalbuyforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    totalsellforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketQuotesResponse.MarketQuotesInfo;
  return proto.market_api.MarketQuotesResponse.MarketQuotesInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaltrade(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaltradept(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamtpt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqtypt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketindex(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeindex(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeindexpercent(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenindex(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestindex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestindex(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdvances(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNochange(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeclenes(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloor(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeiling(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbuy(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsell(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbuyforeignqty(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsellforeignqty(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbuyforeignamt(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsellforeignamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketQuotesResponse.MarketQuotesInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotaltrade();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotaltradept();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalamtpt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalqtypt();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getMarketindex();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getChangeindex();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getChangeindexpercent();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getOpenindex();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getHighestindex();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getLowestindex();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getAdvances();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getNochange();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getDeclenes();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getCeiling();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getTotalbuy();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getTotalsell();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getTotalbuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getTotalsellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getTotalbuyforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getTotalsellforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
};


/**
 * optional int32 indexCd = 1;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double totalTrade = 3;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotaltrade = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotaltrade = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double totalAmt = 4;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double totalQty = 5;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double totalTradePt = 6;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotaltradept = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotaltradept = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double totalAmtPt = 7;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalamtpt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalamtpt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double totalQtyPt = 8;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalqtypt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalqtypt = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double marketIndex = 9;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getMarketindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setMarketindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double changeIndex = 10;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getChangeindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setChangeindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double changeIndexPercent = 11;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getChangeindexpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setChangeindexpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double openIndex = 12;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getOpenindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setOpenindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double highestIndex = 13;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getHighestindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setHighestindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double lowestIndex = 14;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getLowestindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setLowestindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double advances = 15;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getAdvances = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setAdvances = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double noChange = 16;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getNochange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setNochange = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double declenes = 17;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getDeclenes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setDeclenes = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double floor = 18;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setFloor = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double ceiling = 19;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getCeiling = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setCeiling = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double totalBuy = 20;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalbuy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalbuy = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double totalSell = 21;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalsell = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalsell = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double totalBuyForeignQty = 22;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalbuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalbuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double totalSellForeignQty = 23;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalsellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalsellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double totalBuyForeignAmt = 24;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalbuyforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalbuyforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double totalSellForeignAmt = 25;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.getTotalsellforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse.MarketQuotesInfo} returns this
 */
proto.market_api.MarketQuotesResponse.MarketQuotesInfo.prototype.setTotalsellforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.MarketQuotesResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketQuotesResponse} returns this
 */
proto.market_api.MarketQuotesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketQuotesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketQuotesResponse} returns this
 */
proto.market_api.MarketQuotesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketQuotesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketQuotesResponse} returns this
 */
proto.market_api.MarketQuotesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MarketQuotesInfo marketQuotesInfo = 4;
 * @return {?proto.market_api.MarketQuotesResponse.MarketQuotesInfo}
 */
proto.market_api.MarketQuotesResponse.prototype.getMarketquotesinfo = function() {
  return /** @type{?proto.market_api.MarketQuotesResponse.MarketQuotesInfo} */ (
    jspb.Message.getWrapperField(this, proto.market_api.MarketQuotesResponse.MarketQuotesInfo, 4));
};


/**
 * @param {?proto.market_api.MarketQuotesResponse.MarketQuotesInfo|undefined} value
 * @return {!proto.market_api.MarketQuotesResponse} returns this
*/
proto.market_api.MarketQuotesResponse.prototype.setMarketquotesinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketQuotesResponse} returns this
 */
proto.market_api.MarketQuotesResponse.prototype.clearMarketquotesinfo = function() {
  return this.setMarketquotesinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketQuotesResponse.prototype.hasMarketquotesinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecDividendInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecDividendInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecDividendInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecDividendInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: jspb.Message.getFieldWithDefault(msg, 3, 0),
    buycount1: jspb.Message.getFieldWithDefault(msg, 4, 0),
    buyamt1: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    buycount2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    buyamt2: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    buycount3: jspb.Message.getFieldWithDefault(msg, 8, 0),
    buyamt3: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    sellcount1: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sellamt1: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    sellcount2: jspb.Message.getFieldWithDefault(msg, 12, 0),
    sellamt2: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    sellcount3: jspb.Message.getFieldWithDefault(msg, 14, 0),
    sellamt3: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecDividendInfo}
 */
proto.market_api.SecDividendInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecDividendInfo;
  return proto.market_api.SecDividendInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecDividendInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecDividendInfo}
 */
proto.market_api.SecDividendInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuycount1(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt1(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuycount2(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt2(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuycount3(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyamt3(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellcount1(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt1(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellcount2(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt2(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSellcount3(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellamt3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecDividendInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecDividendInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecDividendInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecDividendInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBuycount1();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBuyamt1();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBuycount2();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getBuyamt2();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBuycount3();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getBuyamt3();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getSellcount1();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getSellamt1();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getSellcount2();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSellamt2();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getSellcount3();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSellamt3();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecDividendInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 time = 3;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 buyCount1 = 4;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getBuycount1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setBuycount1 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double buyAmt1 = 5;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getBuyamt1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setBuyamt1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 buyCount2 = 6;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getBuycount2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setBuycount2 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double buyAmt2 = 7;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getBuyamt2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setBuyamt2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 buyCount3 = 8;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getBuycount3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setBuycount3 = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double buyAmt3 = 9;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getBuyamt3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setBuyamt3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int32 sellCount1 = 10;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getSellcount1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSellcount1 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double sellAmt1 = 11;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getSellamt1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSellamt1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int32 sellCount2 = 12;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getSellcount2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSellcount2 = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional double sellAmt2 = 13;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getSellamt2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSellamt2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int32 sellCount3 = 14;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getSellcount3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSellcount3 = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional double sellAmt3 = 15;
 * @return {number}
 */
proto.market_api.SecDividendInfo.prototype.getSellamt3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecDividendInfo} returns this
 */
proto.market_api.SecDividendInfo.prototype.setSellamt3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecIntradayInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecIntradayInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecIntradayInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecIntradayInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecIntradayInput}
 */
proto.market_api.SecIntradayInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecIntradayInput;
  return proto.market_api.SecIntradayInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecIntradayInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecIntradayInput}
 */
proto.market_api.SecIntradayInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecIntradayInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecIntradayInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecIntradayInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecIntradayInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.SecIntradayInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecIntradayInput} returns this
 */
proto.market_api.SecIntradayInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SecIntradayInput.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecIntradayInput} returns this
 */
proto.market_api.SecIntradayInput.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 offset = 3;
 * @return {number}
 */
proto.market_api.SecIntradayInput.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecIntradayInput} returns this
 */
proto.market_api.SecIntradayInput.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.market_api.SecIntradayInput.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecIntradayInput} returns this
 */
proto.market_api.SecIntradayInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string authenCode = 5;
 * @return {string}
 */
proto.market_api.SecIntradayInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecIntradayInput} returns this
 */
proto.market_api.SecIntradayInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SecIntradayResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecIntradayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecIntradayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecIntradayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecIntradayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secintradayinfoList: jspb.Message.toObjectList(msg.getSecintradayinfoList(),
    proto.market_api.SecIntradayResponse.SecIntradayInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecIntradayResponse}
 */
proto.market_api.SecIntradayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecIntradayResponse;
  return proto.market_api.SecIntradayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecIntradayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecIntradayResponse}
 */
proto.market_api.SecIntradayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SecIntradayResponse.SecIntradayInfo;
      reader.readMessage(value,proto.market_api.SecIntradayResponse.SecIntradayInfo.deserializeBinaryFromReader);
      msg.addSecintradayinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecIntradayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecIntradayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecIntradayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecIntradayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecintradayinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.SecIntradayResponse.SecIntradayInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecIntradayResponse.SecIntradayInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecIntradayResponse.SecIntradayInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    qty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    side: (f = msg.getSide()) && proto.market_api.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecIntradayResponse.SecIntradayInfo;
  return proto.market_api.SecIntradayResponse.SecIntradayInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecIntradayResponse.SecIntradayInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQty(value);
      break;
    case 4:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 5:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecIntradayResponse.SecIntradayInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecIntradayResponse.SecIntradayInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getQty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSide();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string time = 1;
 * @return {string}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo} returns this
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo} returns this
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double qty = 3;
 * @return {number}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.getQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo} returns this
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.setQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 4;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo} returns this
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional NullableString side = 5;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.getSide = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 5));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo} returns this
*/
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.setSide = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo} returns this
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.clearSide = function() {
  return this.setSide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SecIntradayResponse.SecIntradayInfo.prototype.hasSide = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.SecIntradayResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecIntradayResponse} returns this
 */
proto.market_api.SecIntradayResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SecIntradayResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecIntradayResponse} returns this
 */
proto.market_api.SecIntradayResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SecIntradayResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecIntradayResponse} returns this
 */
proto.market_api.SecIntradayResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SecIntradayInfo secIntradayInfo = 4;
 * @return {!Array<!proto.market_api.SecIntradayResponse.SecIntradayInfo>}
 */
proto.market_api.SecIntradayResponse.prototype.getSecintradayinfoList = function() {
  return /** @type{!Array<!proto.market_api.SecIntradayResponse.SecIntradayInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SecIntradayResponse.SecIntradayInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.SecIntradayResponse.SecIntradayInfo>} value
 * @return {!proto.market_api.SecIntradayResponse} returns this
*/
proto.market_api.SecIntradayResponse.prototype.setSecintradayinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.SecIntradayResponse.SecIntradayInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SecIntradayResponse.SecIntradayInfo}
 */
proto.market_api.SecIntradayResponse.prototype.addSecintradayinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.SecIntradayResponse.SecIntradayInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SecIntradayResponse} returns this
 */
proto.market_api.SecIntradayResponse.prototype.clearSecintradayinfoList = function() {
  return this.setSecintradayinfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.ScreenerInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.ScreenerInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.ScreenerInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ScreenerInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: (f = msg.getMarketcd()) && proto.market_api.NullableString.toObject(includeInstance, f),
    industries: (f = msg.getIndustries()) && proto.market_api.NullableString.toObject(includeInstance, f),
    quotesindicators: (f = msg.getQuotesindicators()) && proto.market_api.NullableString.toObject(includeInstance, f),
    financialindicators: (f = msg.getFinancialindicators()) && proto.market_api.NullableString.toObject(includeInstance, f),
    technicalindicators: (f = msg.getTechnicalindicators()) && proto.market_api.NullableString.toObject(includeInstance, f),
    orderfieldtype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 9, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.ScreenerInput}
 */
proto.market_api.ScreenerInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.ScreenerInput;
  return proto.market_api.ScreenerInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.ScreenerInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.ScreenerInput}
 */
proto.market_api.ScreenerInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setMarketcd(value);
      break;
    case 2:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setIndustries(value);
      break;
    case 3:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setQuotesindicators(value);
      break;
    case 4:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setFinancialindicators(value);
      break;
    case 5:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setTechnicalindicators(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderfieldtype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrdertype(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.ScreenerInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.ScreenerInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.ScreenerInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ScreenerInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIndustries();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuotesindicators();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFinancialindicators();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTechnicalindicators();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOrderfieldtype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional NullableString marketCd = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.ScreenerInput.prototype.getMarketcd = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.ScreenerInput} returns this
*/
proto.market_api.ScreenerInput.prototype.setMarketcd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.clearMarketcd = function() {
  return this.setMarketcd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.ScreenerInput.prototype.hasMarketcd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NullableString industries = 2;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.ScreenerInput.prototype.getIndustries = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 2));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.ScreenerInput} returns this
*/
proto.market_api.ScreenerInput.prototype.setIndustries = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.clearIndustries = function() {
  return this.setIndustries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.ScreenerInput.prototype.hasIndustries = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString quotesIndicators = 3;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.ScreenerInput.prototype.getQuotesindicators = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 3));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.ScreenerInput} returns this
*/
proto.market_api.ScreenerInput.prototype.setQuotesindicators = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.clearQuotesindicators = function() {
  return this.setQuotesindicators(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.ScreenerInput.prototype.hasQuotesindicators = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString financialIndicators = 4;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.ScreenerInput.prototype.getFinancialindicators = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 4));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.ScreenerInput} returns this
*/
proto.market_api.ScreenerInput.prototype.setFinancialindicators = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.clearFinancialindicators = function() {
  return this.setFinancialindicators(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.ScreenerInput.prototype.hasFinancialindicators = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString technicalIndicators = 5;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.ScreenerInput.prototype.getTechnicalindicators = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 5));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.ScreenerInput} returns this
*/
proto.market_api.ScreenerInput.prototype.setTechnicalindicators = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.clearTechnicalindicators = function() {
  return this.setTechnicalindicators(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.ScreenerInput.prototype.hasTechnicalindicators = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 orderFieldType = 6;
 * @return {number}
 */
proto.market_api.ScreenerInput.prototype.getOrderfieldtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.setOrderfieldtype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 orderType = 7;
 * @return {number}
 */
proto.market_api.ScreenerInput.prototype.getOrdertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 offset = 8;
 * @return {number}
 */
proto.market_api.ScreenerInput.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 limit = 9;
 * @return {number}
 */
proto.market_api.ScreenerInput.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string authenCode = 10;
 * @return {string}
 */
proto.market_api.ScreenerInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerInput} returns this
 */
proto.market_api.ScreenerInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.ScreenerResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.ScreenerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.ScreenerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.ScreenerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ScreenerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalrecords: jspb.Message.getFieldWithDefault(msg, 4, 0),
    secdetailinfoList: jspb.Message.toObjectList(msg.getSecdetailinfoList(),
    proto.market_api.ScreenerResponse.SecDetailInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.ScreenerResponse}
 */
proto.market_api.ScreenerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.ScreenerResponse;
  return proto.market_api.ScreenerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.ScreenerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.ScreenerResponse}
 */
proto.market_api.ScreenerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalrecords(value);
      break;
    case 5:
      var value = new proto.market_api.ScreenerResponse.SecDetailInfo;
      reader.readMessage(value,proto.market_api.ScreenerResponse.SecDetailInfo.deserializeBinaryFromReader);
      msg.addSecdetailinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.ScreenerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.ScreenerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.ScreenerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ScreenerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalrecords();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSecdetailinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.market_api.ScreenerResponse.SecDetailInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.ScreenerResponse.SecDetailInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.ScreenerResponse.SecDetailInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ScreenerResponse.SecDetailInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sectype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    basicprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ceilingprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floorprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    highestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lowestprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    avgprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    lastqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    totalptqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    totalptamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    totalroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    currentroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    w52high: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    w52low: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    best1bidpricestr: jspb.Message.getFieldWithDefault(msg, 25, ""),
    best1bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    best1bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    best2bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    best2bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    best3bidprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    best3bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    best4bidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    best1offerpricestr: jspb.Message.getFieldWithDefault(msg, 33, ""),
    best1offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    best1offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    best2offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    best2offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    best3offerprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    best3offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    best4offerqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    listedqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    tradeqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 42, 0.0),
    tradepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 43, 0.0),
    esp: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    pe: jspb.Message.getFloatingPointFieldWithDefault(msg, 45, 0.0),
    pb: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    ps: jspb.Message.getFloatingPointFieldWithDefault(msg, 47, 0.0),
    bookvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    dividendamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    dividendrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    secnameen: jspb.Message.getFieldWithDefault(msg, 51, ""),
    marketcap: jspb.Message.getFloatingPointFieldWithDefault(msg, 52, 0.0),
    roa: jspb.Message.getFloatingPointFieldWithDefault(msg, 53, 0.0),
    roe: jspb.Message.getFloatingPointFieldWithDefault(msg, 54, 0.0),
    netsale: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 56, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.ScreenerResponse.SecDetailInfo;
  return proto.market_api.ScreenerResponse.SecDetailInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.ScreenerResponse.SecDetailInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSectype(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasicprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilingprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloorprice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestprice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowestprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastqty(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalptqty(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalptamt(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalroom(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentroom(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setW52high(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setW52low(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBest1bidpricestr(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1bidprice(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1bidqty(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2bidprice(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2bidqty(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3bidprice(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3bidqty(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest4bidqty(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBest1offerpricestr(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1offerprice(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest1offerqty(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2offerprice(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest2offerqty(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3offerprice(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest3offerqty(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBest4offerqty(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedqty(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeqty(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradepercent(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEsp(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPe(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPb(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPs(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBookvalue(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDividendamt(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDividendrate(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecnameen(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcap(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoa(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoe(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetsale(value);
      break;
    case 56:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.ScreenerResponse.SecDetailInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.ScreenerResponse.SecDetailInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ScreenerResponse.SecDetailInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSectype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBasicprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCeilingprice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFloorprice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHighestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLowestprice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAvgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getLastqty();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getTotalptqty();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getTotalptamt();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getTotalroom();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getCurrentroom();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getW52high();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getW52low();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getBest1bidpricestr();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getBest1bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getBest1bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getBest2bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getBest2bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getBest3bidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getBest3bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getBest4bidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getBest1offerpricestr();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBest1offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getBest1offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getBest2offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getBest2offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getBest3offerprice();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getBest3offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      39,
      f
    );
  }
  f = message.getBest4offerqty();
  if (f !== 0.0) {
    writer.writeDouble(
      40,
      f
    );
  }
  f = message.getListedqty();
  if (f !== 0.0) {
    writer.writeDouble(
      41,
      f
    );
  }
  f = message.getTradeqty();
  if (f !== 0.0) {
    writer.writeDouble(
      42,
      f
    );
  }
  f = message.getTradepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      43,
      f
    );
  }
  f = message.getEsp();
  if (f !== 0.0) {
    writer.writeDouble(
      44,
      f
    );
  }
  f = message.getPe();
  if (f !== 0.0) {
    writer.writeDouble(
      45,
      f
    );
  }
  f = message.getPb();
  if (f !== 0.0) {
    writer.writeDouble(
      46,
      f
    );
  }
  f = message.getPs();
  if (f !== 0.0) {
    writer.writeDouble(
      47,
      f
    );
  }
  f = message.getBookvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getDividendamt();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getDividendrate();
  if (f !== 0.0) {
    writer.writeDouble(
      50,
      f
    );
  }
  f = message.getSecnameen();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getMarketcap();
  if (f !== 0.0) {
    writer.writeDouble(
      52,
      f
    );
  }
  f = message.getRoa();
  if (f !== 0.0) {
    writer.writeDouble(
      53,
      f
    );
  }
  f = message.getRoe();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getNetsale();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      56,
      f
    );
  }
};


/**
 * optional string marketCd = 4;
 * @return {string}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secName = 2;
 * @return {string}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getSecname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setSecname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 secType = 3;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getSectype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setSectype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double basicPrice = 5;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBasicprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBasicprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double ceilingPrice = 6;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getCeilingprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setCeilingprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double floorPrice = 7;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getFloorprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setFloorprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double highestPrice = 8;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getHighestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setHighestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double lowestPrice = 9;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getLowestprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setLowestprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double avgPrice = 10;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getAvgprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setAvgprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double lastPrice = 11;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double lastQty = 12;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getLastqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setLastqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double totalQty = 13;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double totalAmt = 14;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double totalPtQty = 15;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTotalptqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTotalptqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double totalPtAmt = 16;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTotalptamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTotalptamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double sellForeignQty = 17;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double buyForeignQty = 18;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double changePoint = 19;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double changePercent = 20;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double totalRoom = 21;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTotalroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTotalroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double currentRoom = 22;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getCurrentroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setCurrentroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double w52High = 23;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getW52high = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setW52high = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double w52Low = 24;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getW52low = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setW52low = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional string best1BidPriceStr = 25;
 * @return {string}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest1bidpricestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest1bidpricestr = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional double best1BidPrice = 26;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest1bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest1bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double best1BidQty = 27;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest1bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest1bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double best2BidPrice = 28;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest2bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest2bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double best2BidQty = 29;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest2bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest2bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double best3BidPrice = 30;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest3bidprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest3bidprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double best3BidQty = 31;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest3bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest3bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double best4BidQty = 32;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest4bidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest4bidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional string best1OfferPriceStr = 33;
 * @return {string}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest1offerpricestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest1offerpricestr = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional double best1OfferPrice = 34;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest1offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest1offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double best1OfferQty = 35;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest1offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest1offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double best2OfferPrice = 36;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest2offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest2offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double best2OfferQty = 37;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest2offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest2offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double best3OfferPrice = 38;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest3offerprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest3offerprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional double best3OfferQty = 39;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest3offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest3offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional double best4OfferQty = 40;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBest4offerqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBest4offerqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional double listedQty = 41;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getListedqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setListedqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional double tradeQty = 42;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTradeqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTradeqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 42, value);
};


/**
 * optional double tradePercent = 43;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getTradepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 43, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setTradepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 43, value);
};


/**
 * optional double esp = 44;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getEsp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setEsp = function(value) {
  return jspb.Message.setProto3FloatField(this, 44, value);
};


/**
 * optional double pe = 45;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getPe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 45, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setPe = function(value) {
  return jspb.Message.setProto3FloatField(this, 45, value);
};


/**
 * optional double pb = 46;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getPb = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setPb = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional double ps = 47;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getPs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 47, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setPs = function(value) {
  return jspb.Message.setProto3FloatField(this, 47, value);
};


/**
 * optional double bookValue = 48;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getBookvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setBookvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional double dividendAmt = 49;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getDividendamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setDividendamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional double dividendRate = 50;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getDividendrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setDividendrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional string secNameEn = 51;
 * @return {string}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getSecnameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setSecnameen = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional double marketCap = 52;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getMarketcap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 52, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setMarketcap = function(value) {
  return jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional double roa = 53;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getRoa = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 53, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setRoa = function(value) {
  return jspb.Message.setProto3FloatField(this, 53, value);
};


/**
 * optional double roe = 54;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getRoe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 54, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setRoe = function(value) {
  return jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double netSale = 55;
 * @return {number}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getNetsale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setNetsale = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 56;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo} returns this
 */
proto.market_api.ScreenerResponse.SecDetailInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 56, value);
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.ScreenerResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse} returns this
 */
proto.market_api.ScreenerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.ScreenerResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse} returns this
 */
proto.market_api.ScreenerResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.ScreenerResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ScreenerResponse} returns this
 */
proto.market_api.ScreenerResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 totalRecords = 4;
 * @return {number}
 */
proto.market_api.ScreenerResponse.prototype.getTotalrecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ScreenerResponse} returns this
 */
proto.market_api.ScreenerResponse.prototype.setTotalrecords = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated SecDetailInfo secDetailInfo = 5;
 * @return {!Array<!proto.market_api.ScreenerResponse.SecDetailInfo>}
 */
proto.market_api.ScreenerResponse.prototype.getSecdetailinfoList = function() {
  return /** @type{!Array<!proto.market_api.ScreenerResponse.SecDetailInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.ScreenerResponse.SecDetailInfo, 5));
};


/**
 * @param {!Array<!proto.market_api.ScreenerResponse.SecDetailInfo>} value
 * @return {!proto.market_api.ScreenerResponse} returns this
*/
proto.market_api.ScreenerResponse.prototype.setSecdetailinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.market_api.ScreenerResponse.SecDetailInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.ScreenerResponse.SecDetailInfo}
 */
proto.market_api.ScreenerResponse.prototype.addSecdetailinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.market_api.ScreenerResponse.SecDetailInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.ScreenerResponse} returns this
 */
proto.market_api.ScreenerResponse.prototype.clearSecdetailinfoList = function() {
  return this.setSecdetailinfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopMarketCapInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopMarketCapInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopMarketCapInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopMarketCapInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopMarketCapInput}
 */
proto.market_api.TopMarketCapInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopMarketCapInput;
  return proto.market_api.TopMarketCapInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopMarketCapInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopMarketCapInput}
 */
proto.market_api.TopMarketCapInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopMarketCapInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopMarketCapInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopMarketCapInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopMarketCapInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.market_api.TopMarketCapInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopMarketCapInput} returns this
 */
proto.market_api.TopMarketCapInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string marketCd = 2;
 * @return {string}
 */
proto.market_api.TopMarketCapInput.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopMarketCapInput} returns this
 */
proto.market_api.TopMarketCapInput.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 offset = 3;
 * @return {number}
 */
proto.market_api.TopMarketCapInput.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopMarketCapInput} returns this
 */
proto.market_api.TopMarketCapInput.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.market_api.TopMarketCapInput.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopMarketCapInput} returns this
 */
proto.market_api.TopMarketCapInput.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string authenCode = 5;
 * @return {string}
 */
proto.market_api.TopMarketCapInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopMarketCapInput} returns this
 */
proto.market_api.TopMarketCapInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopMarketCapResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopMarketCapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopMarketCapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopMarketCapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopMarketCapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    topmarketcapinfoList: jspb.Message.toObjectList(msg.getTopmarketcapinfoList(),
    proto.market_api.TopMarketCapResponse.TopMarketCapInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopMarketCapResponse}
 */
proto.market_api.TopMarketCapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopMarketCapResponse;
  return proto.market_api.TopMarketCapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopMarketCapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopMarketCapResponse}
 */
proto.market_api.TopMarketCapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopMarketCapResponse.TopMarketCapInfo;
      reader.readMessage(value,proto.market_api.TopMarketCapResponse.TopMarketCapInfo.deserializeBinaryFromReader);
      msg.addTopmarketcapinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopMarketCapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopMarketCapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopMarketCapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopMarketCapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTopmarketcapinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopMarketCapResponse.TopMarketCapInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopMarketCapResponse.TopMarketCapInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    marketcap: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopMarketCapResponse.TopMarketCapInfo;
  return proto.market_api.TopMarketCapResponse.TopMarketCapInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcap(value);
      break;
    case 5:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopMarketCapResponse.TopMarketCapInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMarketcap();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} returns this
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double changePoint = 2;
 * @return {number}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} returns this
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double changePercent = 3;
 * @return {number}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} returns this
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double marketCap = 4;
 * @return {number}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.getMarketcap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} returns this
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.setMarketcap = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 5;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo} returns this
 */
proto.market_api.TopMarketCapResponse.TopMarketCapInfo.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.market_api.TopMarketCapResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopMarketCapResponse} returns this
 */
proto.market_api.TopMarketCapResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopMarketCapResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopMarketCapResponse} returns this
 */
proto.market_api.TopMarketCapResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopMarketCapResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopMarketCapResponse} returns this
 */
proto.market_api.TopMarketCapResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TopMarketCapInfo topMarketCapInfo = 4;
 * @return {!Array<!proto.market_api.TopMarketCapResponse.TopMarketCapInfo>}
 */
proto.market_api.TopMarketCapResponse.prototype.getTopmarketcapinfoList = function() {
  return /** @type{!Array<!proto.market_api.TopMarketCapResponse.TopMarketCapInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopMarketCapResponse.TopMarketCapInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.TopMarketCapResponse.TopMarketCapInfo>} value
 * @return {!proto.market_api.TopMarketCapResponse} returns this
*/
proto.market_api.TopMarketCapResponse.prototype.setTopmarketcapinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopMarketCapResponse.TopMarketCapInfo}
 */
proto.market_api.TopMarketCapResponse.prototype.addTopmarketcapinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopMarketCapResponse.TopMarketCapInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopMarketCapResponse} returns this
 */
proto.market_api.TopMarketCapResponse.prototype.clearTopmarketcapinfoList = function() {
  return this.setTopmarketcapinfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SystemState.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SystemState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SystemState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SystemState.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SystemState}
 */
proto.market_api.SystemState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SystemState;
  return proto.market_api.SystemState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SystemState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SystemState}
 */
proto.market_api.SystemState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {!proto.market_api.SystemState.StatusCode} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SystemState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SystemState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SystemState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SystemState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.market_api.SystemState.StatusCode = {
  NONE: 0,
  BOD: 1,
  AVAILABLE: 2,
  EOD: 3,
  ATO: 4,
  OPEN: 5,
  ATC: 6,
  CLOSE: 7
};

/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.SystemState.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SystemState} returns this
 */
proto.market_api.SystemState.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StatusCode status = 2;
 * @return {!proto.market_api.SystemState.StatusCode}
 */
proto.market_api.SystemState.prototype.getStatus = function() {
  return /** @type {!proto.market_api.SystemState.StatusCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.market_api.SystemState.StatusCode} value
 * @return {!proto.market_api.SystemState} returns this
 */
proto.market_api.SystemState.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.IndexUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.IndexUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.IndexUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndexUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    index: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    changeindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totaltrade: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    openindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    highindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lowindex: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    advances: jspb.Message.getFieldWithDefault(msg, 10, 0),
    nochange: jspb.Message.getFieldWithDefault(msg, 11, 0),
    declenes: jspb.Message.getFieldWithDefault(msg, 12, 0),
    ceiling: jspb.Message.getFieldWithDefault(msg, 13, 0),
    floor: jspb.Message.getFieldWithDefault(msg, 14, 0),
    state: jspb.Message.getFieldWithDefault(msg, 15, 0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 16, 0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    updtime: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.IndexUpdate}
 */
proto.market_api.IndexUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.IndexUpdate;
  return proto.market_api.IndexUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.IndexUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.IndexUpdate}
 */
proto.market_api.IndexUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeindex(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotaltrade(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenindex(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighindex(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowindex(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvances(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNochange(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeclenes(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCeiling(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    case 15:
      var value = /** @type {!proto.market_api.INDEX_STATE} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 16:
      var value = /** @type {!proto.market_api.INDEX_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.IndexUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.IndexUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.IndexUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndexUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getChangeindex();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotaltrade();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getOpenindex();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHighindex();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLowindex();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAdvances();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getNochange();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDeclenes();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCeiling();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getUpdtime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional int32 indexCd = 1;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double index = 2;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setIndex = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double changeIndex = 3;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getChangeindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setChangeindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 totalTrade = 4;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getTotaltrade = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setTotaltrade = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double totalQty = 5;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double totalAmt = 6;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double openIndex = 7;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getOpenindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setOpenindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double highIndex = 8;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getHighindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setHighindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double lowIndex = 9;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getLowindex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setLowindex = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int32 advances = 10;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getAdvances = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setAdvances = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 noChange = 11;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getNochange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setNochange = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 declenes = 12;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getDeclenes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setDeclenes = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 ceiling = 13;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getCeiling = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setCeiling = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 floor = 14;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional INDEX_STATE state = 15;
 * @return {!proto.market_api.INDEX_STATE}
 */
proto.market_api.IndexUpdate.prototype.getState = function() {
  return /** @type {!proto.market_api.INDEX_STATE} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.market_api.INDEX_STATE} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional INDEX_COLOR_CODE colorCode = 16;
 * @return {!proto.market_api.INDEX_COLOR_CODE}
 */
proto.market_api.IndexUpdate.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.INDEX_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.market_api.INDEX_COLOR_CODE} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional double changePercent = 17;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional int64 updTime = 18;
 * @return {number}
 */
proto.market_api.IndexUpdate.prototype.getUpdtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndexUpdate} returns this
 */
proto.market_api.IndexUpdate.prototype.setUpdtime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopPrice.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalbidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalofferqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    toppricebidList: jspb.Message.toObjectList(msg.getToppricebidList(),
    proto.market_api.TopPrice.TopPriceDetail.toObject, includeInstance),
    toppriceofferList: jspb.Message.toObjectList(msg.getToppriceofferList(),
    proto.market_api.TopPrice.TopPriceDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopPrice}
 */
proto.market_api.TopPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopPrice;
  return proto.market_api.TopPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopPrice}
 */
proto.market_api.TopPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbidqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalofferqty(value);
      break;
    case 4:
      var value = new proto.market_api.TopPrice.TopPriceDetail;
      reader.readMessage(value,proto.market_api.TopPrice.TopPriceDetail.deserializeBinaryFromReader);
      msg.addToppricebid(value);
      break;
    case 5:
      var value = new proto.market_api.TopPrice.TopPriceDetail;
      reader.readMessage(value,proto.market_api.TopPrice.TopPriceDetail.deserializeBinaryFromReader);
      msg.addToppriceoffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalbidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalofferqty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getToppricebidList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopPrice.TopPriceDetail.serializeBinaryToWriter
    );
  }
  f = message.getToppriceofferList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.market_api.TopPrice.TopPriceDetail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopPrice.TopPriceDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopPrice.TopPriceDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPrice.TopPriceDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    top: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopPrice.TopPriceDetail}
 */
proto.market_api.TopPrice.TopPriceDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopPrice.TopPriceDetail;
  return proto.market_api.TopPrice.TopPriceDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopPrice.TopPriceDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopPrice.TopPriceDetail}
 */
proto.market_api.TopPrice.TopPriceDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTop(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopPrice.TopPriceDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopPrice.TopPriceDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPrice.TopPriceDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTop();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 top = 1;
 * @return {number}
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.getTop = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPrice.TopPriceDetail} returns this
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.setTop = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string price = 2;
 * @return {string}
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopPrice.TopPriceDetail} returns this
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double qty = 3;
 * @return {number}
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.getQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPrice.TopPriceDetail} returns this
 */
proto.market_api.TopPrice.TopPriceDetail.prototype.setQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TopPrice.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopPrice} returns this
 */
proto.market_api.TopPrice.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totalBidQty = 2;
 * @return {number}
 */
proto.market_api.TopPrice.prototype.getTotalbidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPrice} returns this
 */
proto.market_api.TopPrice.prototype.setTotalbidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double totalOfferQty = 3;
 * @return {number}
 */
proto.market_api.TopPrice.prototype.getTotalofferqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPrice} returns this
 */
proto.market_api.TopPrice.prototype.setTotalofferqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated TopPriceDetail topPriceBid = 4;
 * @return {!Array<!proto.market_api.TopPrice.TopPriceDetail>}
 */
proto.market_api.TopPrice.prototype.getToppricebidList = function() {
  return /** @type{!Array<!proto.market_api.TopPrice.TopPriceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopPrice.TopPriceDetail, 4));
};


/**
 * @param {!Array<!proto.market_api.TopPrice.TopPriceDetail>} value
 * @return {!proto.market_api.TopPrice} returns this
*/
proto.market_api.TopPrice.prototype.setToppricebidList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopPrice.TopPriceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopPrice.TopPriceDetail}
 */
proto.market_api.TopPrice.prototype.addToppricebid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopPrice.TopPriceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopPrice} returns this
 */
proto.market_api.TopPrice.prototype.clearToppricebidList = function() {
  return this.setToppricebidList([]);
};


/**
 * repeated TopPriceDetail topPriceOffer = 5;
 * @return {!Array<!proto.market_api.TopPrice.TopPriceDetail>}
 */
proto.market_api.TopPrice.prototype.getToppriceofferList = function() {
  return /** @type{!Array<!proto.market_api.TopPrice.TopPriceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopPrice.TopPriceDetail, 5));
};


/**
 * @param {!Array<!proto.market_api.TopPrice.TopPriceDetail>} value
 * @return {!proto.market_api.TopPrice} returns this
*/
proto.market_api.TopPrice.prototype.setToppriceofferList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.market_api.TopPrice.TopPriceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopPrice.TopPriceDetail}
 */
proto.market_api.TopPrice.prototype.addToppriceoffer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.market_api.TopPrice.TopPriceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopPrice} returns this
 */
proto.market_api.TopPrice.prototype.clearToppriceofferList = function() {
  return this.setToppriceofferList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.LastSale.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.LastSale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.LastSale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.LastSale.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lastqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lastamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    hightprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lowprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    avgprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    side: (f = msg.getSide()) && proto.market_api.NullableString.toObject(includeInstance, f),
    colorcode: jspb.Message.getFieldWithDefault(msg, 14, 0),
    mattime: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.LastSale}
 */
proto.market_api.LastSale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.LastSale;
  return proto.market_api.LastSale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.LastSale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.LastSale}
 */
proto.market_api.LastSale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastamt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHightprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgprice(value);
      break;
    case 13:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setSide(value);
      break;
    case 14:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMattime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.LastSale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.LastSale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.LastSale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.LastSale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLastqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLastamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getHightprice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAvgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getSide();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getMattime();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.LastSale.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.LastSale.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double changePoint = 3;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double changePercent = 4;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double lastPrice = 5;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double lastQty = 6;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getLastqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setLastqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double lastAmt = 7;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getLastamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setLastamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double totalQty = 8;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double totalAmt = 9;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double hightPrice = 10;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getHightprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setHightprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double lowPrice = 11;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getLowprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setLowprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double avgPrice = 12;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getAvgprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setAvgprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional NullableString side = 13;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.LastSale.prototype.getSide = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 13));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.LastSale} returns this
*/
proto.market_api.LastSale.prototype.setSide = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.clearSide = function() {
  return this.setSide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.LastSale.prototype.hasSide = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional STOCK_COLOR_CODE colorCode = 14;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.LastSale.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int32 matTime = 15;
 * @return {number}
 */
proto.market_api.LastSale.prototype.getMattime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSale} returns this
 */
proto.market_api.LastSale.prototype.setMattime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopPriceOdd.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopPriceOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopPriceOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopPriceOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPriceOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalbidqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalofferqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    toppricebidList: jspb.Message.toObjectList(msg.getToppricebidList(),
    proto.market_api.TopPriceOdd.TopPriceDetail.toObject, includeInstance),
    toppriceofferList: jspb.Message.toObjectList(msg.getToppriceofferList(),
    proto.market_api.TopPriceOdd.TopPriceDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopPriceOdd}
 */
proto.market_api.TopPriceOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopPriceOdd;
  return proto.market_api.TopPriceOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopPriceOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopPriceOdd}
 */
proto.market_api.TopPriceOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbidqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalofferqty(value);
      break;
    case 4:
      var value = new proto.market_api.TopPriceOdd.TopPriceDetail;
      reader.readMessage(value,proto.market_api.TopPriceOdd.TopPriceDetail.deserializeBinaryFromReader);
      msg.addToppricebid(value);
      break;
    case 5:
      var value = new proto.market_api.TopPriceOdd.TopPriceDetail;
      reader.readMessage(value,proto.market_api.TopPriceOdd.TopPriceDetail.deserializeBinaryFromReader);
      msg.addToppriceoffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopPriceOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopPriceOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopPriceOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPriceOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalbidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalofferqty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getToppricebidList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopPriceOdd.TopPriceDetail.serializeBinaryToWriter
    );
  }
  f = message.getToppriceofferList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.market_api.TopPriceOdd.TopPriceDetail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopPriceOdd.TopPriceDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopPriceOdd.TopPriceDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPriceOdd.TopPriceDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    top: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopPriceOdd.TopPriceDetail;
  return proto.market_api.TopPriceOdd.TopPriceDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopPriceOdd.TopPriceDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTop(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopPriceOdd.TopPriceDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopPriceOdd.TopPriceDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopPriceOdd.TopPriceDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTop();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 top = 1;
 * @return {number}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.getTop = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail} returns this
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.setTop = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string price = 2;
 * @return {string}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail} returns this
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double qty = 3;
 * @return {number}
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.getQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail} returns this
 */
proto.market_api.TopPriceOdd.TopPriceDetail.prototype.setQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TopPriceOdd.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopPriceOdd} returns this
 */
proto.market_api.TopPriceOdd.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totalBidQty = 2;
 * @return {number}
 */
proto.market_api.TopPriceOdd.prototype.getTotalbidqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPriceOdd} returns this
 */
proto.market_api.TopPriceOdd.prototype.setTotalbidqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double totalOfferQty = 3;
 * @return {number}
 */
proto.market_api.TopPriceOdd.prototype.getTotalofferqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopPriceOdd} returns this
 */
proto.market_api.TopPriceOdd.prototype.setTotalofferqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated TopPriceDetail topPriceBid = 4;
 * @return {!Array<!proto.market_api.TopPriceOdd.TopPriceDetail>}
 */
proto.market_api.TopPriceOdd.prototype.getToppricebidList = function() {
  return /** @type{!Array<!proto.market_api.TopPriceOdd.TopPriceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopPriceOdd.TopPriceDetail, 4));
};


/**
 * @param {!Array<!proto.market_api.TopPriceOdd.TopPriceDetail>} value
 * @return {!proto.market_api.TopPriceOdd} returns this
*/
proto.market_api.TopPriceOdd.prototype.setToppricebidList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopPriceOdd.TopPriceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail}
 */
proto.market_api.TopPriceOdd.prototype.addToppricebid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopPriceOdd.TopPriceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopPriceOdd} returns this
 */
proto.market_api.TopPriceOdd.prototype.clearToppricebidList = function() {
  return this.setToppricebidList([]);
};


/**
 * repeated TopPriceDetail topPriceOffer = 5;
 * @return {!Array<!proto.market_api.TopPriceOdd.TopPriceDetail>}
 */
proto.market_api.TopPriceOdd.prototype.getToppriceofferList = function() {
  return /** @type{!Array<!proto.market_api.TopPriceOdd.TopPriceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopPriceOdd.TopPriceDetail, 5));
};


/**
 * @param {!Array<!proto.market_api.TopPriceOdd.TopPriceDetail>} value
 * @return {!proto.market_api.TopPriceOdd} returns this
*/
proto.market_api.TopPriceOdd.prototype.setToppriceofferList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.market_api.TopPriceOdd.TopPriceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopPriceOdd.TopPriceDetail}
 */
proto.market_api.TopPriceOdd.prototype.addToppriceoffer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.market_api.TopPriceOdd.TopPriceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopPriceOdd} returns this
 */
proto.market_api.TopPriceOdd.prototype.clearToppriceofferList = function() {
  return this.setToppriceofferList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.LastSaleOdd.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.LastSaleOdd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.LastSaleOdd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.LastSaleOdd.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lastqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lastamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    hightprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lowprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    avgprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    side: (f = msg.getSide()) && proto.market_api.NullableString.toObject(includeInstance, f),
    colorcode: jspb.Message.getFieldWithDefault(msg, 14, 0),
    mattime: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.LastSaleOdd}
 */
proto.market_api.LastSaleOdd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.LastSaleOdd;
  return proto.market_api.LastSaleOdd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.LastSaleOdd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.LastSaleOdd}
 */
proto.market_api.LastSaleOdd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastamt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalqty(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalamt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHightprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgprice(value);
      break;
    case 13:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setSide(value);
      break;
    case 14:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMattime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.LastSaleOdd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.LastSaleOdd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.LastSaleOdd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.LastSaleOdd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLastqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLastamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalqty();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalamt();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getHightprice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAvgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getSide();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getMattime();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string marketCd = 1;
 * @return {string}
 */
proto.market_api.LastSaleOdd.prototype.getMarketcd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.LastSaleOdd.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double changePoint = 3;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double changePercent = 4;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double lastPrice = 5;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double lastQty = 6;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getLastqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setLastqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double lastAmt = 7;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getLastamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setLastamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double totalQty = 8;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getTotalqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setTotalqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double totalAmt = 9;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getTotalamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setTotalamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double hightPrice = 10;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getHightprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setHightprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double lowPrice = 11;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getLowprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setLowprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double avgPrice = 12;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getAvgprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setAvgprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional NullableString side = 13;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.LastSaleOdd.prototype.getSide = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 13));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.LastSaleOdd} returns this
*/
proto.market_api.LastSaleOdd.prototype.setSide = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.clearSide = function() {
  return this.setSide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.LastSaleOdd.prototype.hasSide = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional STOCK_COLOR_CODE colorCode = 14;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.LastSaleOdd.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int32 matTime = 15;
 * @return {number}
 */
proto.market_api.LastSaleOdd.prototype.getMattime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.LastSaleOdd} returns this
 */
proto.market_api.LastSaleOdd.prototype.setMattime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.ForeignRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.ForeignRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.ForeignRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ForeignRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currentroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    buyforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sellforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.ForeignRoom}
 */
proto.market_api.ForeignRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.ForeignRoom;
  return proto.market_api.ForeignRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.ForeignRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.ForeignRoom}
 */
proto.market_api.ForeignRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalroom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentroom(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignamt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.ForeignRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.ForeignRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.ForeignRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ForeignRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalroom();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrentroom();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBuyforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSellforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.ForeignRoom.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totalRoom = 2;
 * @return {number}
 */
proto.market_api.ForeignRoom.prototype.getTotalroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setTotalroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double currentRoom = 3;
 * @return {number}
 */
proto.market_api.ForeignRoom.prototype.getCurrentroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setCurrentroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double buyForeignQty = 4;
 * @return {number}
 */
proto.market_api.ForeignRoom.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double buyForeignAmt = 5;
 * @return {number}
 */
proto.market_api.ForeignRoom.prototype.getBuyforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setBuyforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double sellForeignQty = 6;
 * @return {number}
 */
proto.market_api.ForeignRoom.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double sellForeignAmt = 7;
 * @return {number}
 */
proto.market_api.ForeignRoom.prototype.getSellforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ForeignRoom} returns this
 */
proto.market_api.ForeignRoom.prototype.setSellforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.market_api.NullableString.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.market_api.NullableString.KindCase = {
  KIND_NOT_SET: 0,
  NULL: 1,
  DATA: 2
};

/**
 * @return {proto.market_api.NullableString.KindCase}
 */
proto.market_api.NullableString.prototype.getKindCase = function() {
  return /** @type {proto.market_api.NullableString.KindCase} */(jspb.Message.computeOneofCase(this, proto.market_api.NullableString.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.NullableString.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.NullableString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.NullableString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableString.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_null: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.NullableString}
 */
proto.market_api.NullableString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.NullableString;
  return proto.market_api.NullableString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.NullableString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.NullableString}
 */
proto.market_api.NullableString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setNull(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.NullableString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.NullableString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.NullableString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.NullValue null = 1;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.market_api.NullableString.prototype.getNull = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.market_api.NullableString} returns this
 */
proto.market_api.NullableString.prototype.setNull = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableString.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableString} returns this
 */
proto.market_api.NullableString.prototype.clearNull = function() {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableString.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableString.prototype.hasNull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.market_api.NullableString.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.NullableString} returns this
 */
proto.market_api.NullableString.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableString.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableString} returns this
 */
proto.market_api.NullableString.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableString.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableString.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.market_api.NullableInt32.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.market_api.NullableInt32.KindCase = {
  KIND_NOT_SET: 0,
  NULL: 1,
  DATA: 2
};

/**
 * @return {proto.market_api.NullableInt32.KindCase}
 */
proto.market_api.NullableInt32.prototype.getKindCase = function() {
  return /** @type {proto.market_api.NullableInt32.KindCase} */(jspb.Message.computeOneofCase(this, proto.market_api.NullableInt32.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.NullableInt32.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.NullableInt32.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.NullableInt32} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableInt32.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_null: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.NullableInt32}
 */
proto.market_api.NullableInt32.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.NullableInt32;
  return proto.market_api.NullableInt32.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.NullableInt32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.NullableInt32}
 */
proto.market_api.NullableInt32.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setNull(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.NullableInt32.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.NullableInt32.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.NullableInt32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableInt32.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.NullValue null = 1;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.market_api.NullableInt32.prototype.getNull = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.market_api.NullableInt32} returns this
 */
proto.market_api.NullableInt32.prototype.setNull = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableInt32.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableInt32} returns this
 */
proto.market_api.NullableInt32.prototype.clearNull = function() {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableInt32.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableInt32.prototype.hasNull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 data = 2;
 * @return {number}
 */
proto.market_api.NullableInt32.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.NullableInt32} returns this
 */
proto.market_api.NullableInt32.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableInt32.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableInt32} returns this
 */
proto.market_api.NullableInt32.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableInt32.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableInt32.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.market_api.NullableInt64.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.market_api.NullableInt64.KindCase = {
  KIND_NOT_SET: 0,
  NULL: 1,
  DATA: 2
};

/**
 * @return {proto.market_api.NullableInt64.KindCase}
 */
proto.market_api.NullableInt64.prototype.getKindCase = function() {
  return /** @type {proto.market_api.NullableInt64.KindCase} */(jspb.Message.computeOneofCase(this, proto.market_api.NullableInt64.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.NullableInt64.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.NullableInt64.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.NullableInt64} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableInt64.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_null: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.NullableInt64}
 */
proto.market_api.NullableInt64.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.NullableInt64;
  return proto.market_api.NullableInt64.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.NullableInt64} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.NullableInt64}
 */
proto.market_api.NullableInt64.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setNull(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.NullableInt64.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.NullableInt64.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.NullableInt64} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableInt64.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.NullValue null = 1;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.market_api.NullableInt64.prototype.getNull = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.market_api.NullableInt64} returns this
 */
proto.market_api.NullableInt64.prototype.setNull = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableInt64.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableInt64} returns this
 */
proto.market_api.NullableInt64.prototype.clearNull = function() {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableInt64.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableInt64.prototype.hasNull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 data = 2;
 * @return {number}
 */
proto.market_api.NullableInt64.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.NullableInt64} returns this
 */
proto.market_api.NullableInt64.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableInt64.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableInt64} returns this
 */
proto.market_api.NullableInt64.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableInt64.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableInt64.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.market_api.NullableDouble.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.market_api.NullableDouble.KindCase = {
  KIND_NOT_SET: 0,
  NULL: 1,
  DATA: 2
};

/**
 * @return {proto.market_api.NullableDouble.KindCase}
 */
proto.market_api.NullableDouble.prototype.getKindCase = function() {
  return /** @type {proto.market_api.NullableDouble.KindCase} */(jspb.Message.computeOneofCase(this, proto.market_api.NullableDouble.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.NullableDouble.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.NullableDouble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.NullableDouble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableDouble.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_null: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.NullableDouble}
 */
proto.market_api.NullableDouble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.NullableDouble;
  return proto.market_api.NullableDouble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.NullableDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.NullableDouble}
 */
proto.market_api.NullableDouble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setNull(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.NullableDouble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.NullableDouble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.NullableDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.NullableDouble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.NullValue null = 1;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.market_api.NullableDouble.prototype.getNull = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.market_api.NullableDouble} returns this
 */
proto.market_api.NullableDouble.prototype.setNull = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableDouble.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableDouble} returns this
 */
proto.market_api.NullableDouble.prototype.clearNull = function() {
  return jspb.Message.setOneofField(this, 1, proto.market_api.NullableDouble.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableDouble.prototype.hasNull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double data = 2;
 * @return {number}
 */
proto.market_api.NullableDouble.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.NullableDouble} returns this
 */
proto.market_api.NullableDouble.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableDouble.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market_api.NullableDouble} returns this
 */
proto.market_api.NullableDouble.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 2, proto.market_api.NullableDouble.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.NullableDouble.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketInitDataParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketInitDataParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketInitDataParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketInitDataParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketInitDataParam}
 */
proto.market_api.MarketInitDataParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketInitDataParam;
  return proto.market_api.MarketInitDataParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketInitDataParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketInitDataParam}
 */
proto.market_api.MarketInitDataParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketInitDataParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketInitDataParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketInitDataParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketInitDataParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 channel = 1;
 * @return {number}
 */
proto.market_api.MarketInitDataParam.prototype.getChannel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketInitDataParam} returns this
 */
proto.market_api.MarketInitDataParam.prototype.setChannel = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string authenCode = 2;
 * @return {string}
 */
proto.market_api.MarketInitDataParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketInitDataParam} returns this
 */
proto.market_api.MarketInitDataParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketInitDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketInitDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketInitDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketInitDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    items: (f = msg.getItems()) && proto.market_api.MarketInitDataItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketInitDataResponse}
 */
proto.market_api.MarketInitDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketInitDataResponse;
  return proto.market_api.MarketInitDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketInitDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketInitDataResponse}
 */
proto.market_api.MarketInitDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketInitDataItem;
      reader.readMessage(value,proto.market_api.MarketInitDataItem.deserializeBinaryFromReader);
      msg.setItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketInitDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketInitDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketInitDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketInitDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItems();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.MarketInitDataItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.MarketInitDataResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketInitDataResponse} returns this
 */
proto.market_api.MarketInitDataResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketInitDataResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketInitDataResponse} returns this
 */
proto.market_api.MarketInitDataResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketInitDataResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketInitDataResponse} returns this
 */
proto.market_api.MarketInitDataResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MarketInitDataItem items = 4;
 * @return {?proto.market_api.MarketInitDataItem}
 */
proto.market_api.MarketInitDataResponse.prototype.getItems = function() {
  return /** @type{?proto.market_api.MarketInitDataItem} */ (
    jspb.Message.getWrapperField(this, proto.market_api.MarketInitDataItem, 4));
};


/**
 * @param {?proto.market_api.MarketInitDataItem|undefined} value
 * @return {!proto.market_api.MarketInitDataResponse} returns this
*/
proto.market_api.MarketInitDataResponse.prototype.setItems = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketInitDataResponse} returns this
 */
proto.market_api.MarketInitDataResponse.prototype.clearItems = function() {
  return this.setItems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketInitDataResponse.prototype.hasItems = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketInitDataItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketInitDataItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketInitDataItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketInitDataItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    basketinfolist: (f = msg.getBasketinfolist()) && proto.market_api.MrktBasketInfoList.toObject(includeInstance, f),
    mrktindustrieslist: (f = msg.getMrktindustrieslist()) && proto.market_api.MrktindustriesList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketInitDataItem}
 */
proto.market_api.MarketInitDataItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketInitDataItem;
  return proto.market_api.MarketInitDataItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketInitDataItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketInitDataItem}
 */
proto.market_api.MarketInitDataItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.MrktBasketInfoList;
      reader.readMessage(value,proto.market_api.MrktBasketInfoList.deserializeBinaryFromReader);
      msg.setBasketinfolist(value);
      break;
    case 2:
      var value = new proto.market_api.MrktindustriesList;
      reader.readMessage(value,proto.market_api.MrktindustriesList.deserializeBinaryFromReader);
      msg.setMrktindustrieslist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketInitDataItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketInitDataItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketInitDataItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketInitDataItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasketinfolist();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.MrktBasketInfoList.serializeBinaryToWriter
    );
  }
  f = message.getMrktindustrieslist();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.market_api.MrktindustriesList.serializeBinaryToWriter
    );
  }
};


/**
 * optional MrktBasketInfoList basketInfoList = 1;
 * @return {?proto.market_api.MrktBasketInfoList}
 */
proto.market_api.MarketInitDataItem.prototype.getBasketinfolist = function() {
  return /** @type{?proto.market_api.MrktBasketInfoList} */ (
    jspb.Message.getWrapperField(this, proto.market_api.MrktBasketInfoList, 1));
};


/**
 * @param {?proto.market_api.MrktBasketInfoList|undefined} value
 * @return {!proto.market_api.MarketInitDataItem} returns this
*/
proto.market_api.MarketInitDataItem.prototype.setBasketinfolist = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketInitDataItem} returns this
 */
proto.market_api.MarketInitDataItem.prototype.clearBasketinfolist = function() {
  return this.setBasketinfolist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketInitDataItem.prototype.hasBasketinfolist = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MrktindustriesList mrktindustriesList = 2;
 * @return {?proto.market_api.MrktindustriesList}
 */
proto.market_api.MarketInitDataItem.prototype.getMrktindustrieslist = function() {
  return /** @type{?proto.market_api.MrktindustriesList} */ (
    jspb.Message.getWrapperField(this, proto.market_api.MrktindustriesList, 2));
};


/**
 * @param {?proto.market_api.MrktindustriesList|undefined} value
 * @return {!proto.market_api.MarketInitDataItem} returns this
*/
proto.market_api.MarketInitDataItem.prototype.setMrktindustrieslist = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.MarketInitDataItem} returns this
 */
proto.market_api.MarketInitDataItem.prototype.clearMrktindustrieslist = function() {
  return this.setMrktindustrieslist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.MarketInitDataItem.prototype.hasMrktindustrieslist = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MrktBasketInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktBasketInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktBasketInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktBasketInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktBasketInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itembasketinfoList: jspb.Message.toObjectList(msg.getItembasketinfoList(),
    proto.market_api.MrktBasketInfoItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktBasketInfoList}
 */
proto.market_api.MrktBasketInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktBasketInfoList;
  return proto.market_api.MrktBasketInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktBasketInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktBasketInfoList}
 */
proto.market_api.MrktBasketInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.MrktBasketInfoItem;
      reader.readMessage(value,proto.market_api.MrktBasketInfoItem.deserializeBinaryFromReader);
      msg.addItembasketinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktBasketInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktBasketInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktBasketInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktBasketInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItembasketinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.MrktBasketInfoItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MrktBasketInfoItem itemBasketInfo = 1;
 * @return {!Array<!proto.market_api.MrktBasketInfoItem>}
 */
proto.market_api.MrktBasketInfoList.prototype.getItembasketinfoList = function() {
  return /** @type{!Array<!proto.market_api.MrktBasketInfoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MrktBasketInfoItem, 1));
};


/**
 * @param {!Array<!proto.market_api.MrktBasketInfoItem>} value
 * @return {!proto.market_api.MrktBasketInfoList} returns this
*/
proto.market_api.MrktBasketInfoList.prototype.setItembasketinfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.MrktBasketInfoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MrktBasketInfoItem}
 */
proto.market_api.MrktBasketInfoList.prototype.addItembasketinfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.MrktBasketInfoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MrktBasketInfoList} returns this
 */
proto.market_api.MrktBasketInfoList.prototype.clearItembasketinfoList = function() {
  return this.setItembasketinfoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MrktindustriesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktindustriesList.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktindustriesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktindustriesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktindustriesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemmrktindustriesList: jspb.Message.toObjectList(msg.getItemmrktindustriesList(),
    proto.market_api.MrktindustriesItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktindustriesList}
 */
proto.market_api.MrktindustriesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktindustriesList;
  return proto.market_api.MrktindustriesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktindustriesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktindustriesList}
 */
proto.market_api.MrktindustriesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.MrktindustriesItem;
      reader.readMessage(value,proto.market_api.MrktindustriesItem.deserializeBinaryFromReader);
      msg.addItemmrktindustries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktindustriesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktindustriesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktindustriesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktindustriesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemmrktindustriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.MrktindustriesItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MrktindustriesItem itemMrktindustries = 1;
 * @return {!Array<!proto.market_api.MrktindustriesItem>}
 */
proto.market_api.MrktindustriesList.prototype.getItemmrktindustriesList = function() {
  return /** @type{!Array<!proto.market_api.MrktindustriesItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MrktindustriesItem, 1));
};


/**
 * @param {!Array<!proto.market_api.MrktindustriesItem>} value
 * @return {!proto.market_api.MrktindustriesList} returns this
*/
proto.market_api.MrktindustriesList.prototype.setItemmrktindustriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.MrktindustriesItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MrktindustriesItem}
 */
proto.market_api.MrktindustriesList.prototype.addItemmrktindustries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.MrktindustriesItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MrktindustriesList} returns this
 */
proto.market_api.MrktindustriesList.prototype.clearItemmrktindustriesList = function() {
  return this.setItemmrktindustriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktBasketInfoItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktBasketInfoItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktBasketInfoItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktBasketInfoItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    indexname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seccdlist: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktBasketInfoItem}
 */
proto.market_api.MrktBasketInfoItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktBasketInfoItem;
  return proto.market_api.MrktBasketInfoItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktBasketInfoItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktBasketInfoItem}
 */
proto.market_api.MrktBasketInfoItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktBasketInfoItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktBasketInfoItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktBasketInfoItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktBasketInfoItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIndexname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeccdlist();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 indexCd = 1;
 * @return {number}
 */
proto.market_api.MrktBasketInfoItem.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktBasketInfoItem} returns this
 */
proto.market_api.MrktBasketInfoItem.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string indexName = 2;
 * @return {string}
 */
proto.market_api.MrktBasketInfoItem.prototype.getIndexname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktBasketInfoItem} returns this
 */
proto.market_api.MrktBasketInfoItem.prototype.setIndexname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secCdList = 3;
 * @return {string}
 */
proto.market_api.MrktBasketInfoItem.prototype.getSeccdlist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktBasketInfoItem} returns this
 */
proto.market_api.MrktBasketInfoItem.prototype.setSeccdlist = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktindustriesItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktindustriesItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktindustriesItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktindustriesItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    namevn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nameen: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seccdlist: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktindustriesItem}
 */
proto.market_api.MrktindustriesItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktindustriesItem;
  return proto.market_api.MrktindustriesItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktindustriesItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktindustriesItem}
 */
proto.market_api.MrktindustriesItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamevn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameen(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktindustriesItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktindustriesItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktindustriesItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktindustriesItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNamevn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNameen();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeccdlist();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.MrktindustriesItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktindustriesItem} returns this
 */
proto.market_api.MrktindustriesItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.market_api.MrktindustriesItem.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktindustriesItem} returns this
 */
proto.market_api.MrktindustriesItem.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string nameVn = 3;
 * @return {string}
 */
proto.market_api.MrktindustriesItem.prototype.getNamevn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktindustriesItem} returns this
 */
proto.market_api.MrktindustriesItem.prototype.setNamevn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string nameEn = 4;
 * @return {string}
 */
proto.market_api.MrktindustriesItem.prototype.getNameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktindustriesItem} returns this
 */
proto.market_api.MrktindustriesItem.prototype.setNameen = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string secCdList = 5;
 * @return {string}
 */
proto.market_api.MrktindustriesItem.prototype.getSeccdlist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktindustriesItem} returns this
 */
proto.market_api.MrktindustriesItem.prototype.setSeccdlist = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktSecInfoItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktSecInfoItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktSecInfoItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktSecInfoItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sectype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    secname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secnameen: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    listedqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    lasttradedate: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktSecInfoItem}
 */
proto.market_api.MrktSecInfoItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktSecInfoItem;
  return proto.market_api.MrktSecInfoItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktSecInfoItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktSecInfoItem}
 */
proto.market_api.MrktSecInfoItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSectype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecnameen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxrate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedqty(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLasttradedate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktSecInfoItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktSecInfoItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktSecInfoItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktSecInfoItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSectype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSecname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecnameen();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxrate();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getListedqty();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLasttradedate();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.MrktSecInfoItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 secType = 2;
 * @return {number}
 */
proto.market_api.MrktSecInfoItem.prototype.getSectype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setSectype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string secName = 3;
 * @return {string}
 */
proto.market_api.MrktSecInfoItem.prototype.getSecname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setSecname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string secNameEn = 4;
 * @return {string}
 */
proto.market_api.MrktSecInfoItem.prototype.getSecnameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setSecnameen = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 marketCd = 5;
 * @return {number}
 */
proto.market_api.MrktSecInfoItem.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double maxRate = 6;
 * @return {number}
 */
proto.market_api.MrktSecInfoItem.prototype.getMaxrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setMaxrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double listedQty = 7;
 * @return {number}
 */
proto.market_api.MrktSecInfoItem.prototype.getListedqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setListedqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 lastTradeDate = 8;
 * @return {number}
 */
proto.market_api.MrktSecInfoItem.prototype.getLasttradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktSecInfoItem} returns this
 */
proto.market_api.MrktSecInfoItem.prototype.setLasttradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MrktSecInfoResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktSecInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktSecInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktSecInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktSecInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.MrktSecInfoItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktSecInfoResponse}
 */
proto.market_api.MrktSecInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktSecInfoResponse;
  return proto.market_api.MrktSecInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktSecInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktSecInfoResponse}
 */
proto.market_api.MrktSecInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MrktSecInfoItem;
      reader.readMessage(value,proto.market_api.MrktSecInfoItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktSecInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktSecInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktSecInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktSecInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.MrktSecInfoItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.MrktSecInfoResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktSecInfoResponse} returns this
 */
proto.market_api.MrktSecInfoResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MrktSecInfoResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktSecInfoResponse} returns this
 */
proto.market_api.MrktSecInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MrktSecInfoResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktSecInfoResponse} returns this
 */
proto.market_api.MrktSecInfoResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated MrktSecInfoItem data = 4;
 * @return {!Array<!proto.market_api.MrktSecInfoItem>}
 */
proto.market_api.MrktSecInfoResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.MrktSecInfoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MrktSecInfoItem, 4));
};


/**
 * @param {!Array<!proto.market_api.MrktSecInfoItem>} value
 * @return {!proto.market_api.MrktSecInfoResponse} returns this
*/
proto.market_api.MrktSecInfoResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.MrktSecInfoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MrktSecInfoItem}
 */
proto.market_api.MrktSecInfoResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.MrktSecInfoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MrktSecInfoResponse} returns this
 */
proto.market_api.MrktSecInfoResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktSecInfoParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktSecInfoParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktSecInfoParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktSecInfoParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktSecInfoParam}
 */
proto.market_api.MrktSecInfoParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktSecInfoParam;
  return proto.market_api.MrktSecInfoParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktSecInfoParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktSecInfoParam}
 */
proto.market_api.MrktSecInfoParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktSecInfoParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktSecInfoParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktSecInfoParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktSecInfoParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.MrktSecInfoParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktSecInfoParam} returns this
 */
proto.market_api.MrktSecInfoParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktAdvItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktAdvItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktAdvItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktAdvItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    advtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 4, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktAdvItem}
 */
proto.market_api.MrktAdvItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktAdvItem;
  return proto.market_api.MrktAdvItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktAdvItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktAdvItem}
 */
proto.market_api.MrktAdvItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvtime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 6:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktAdvItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktAdvItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktAdvItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktAdvItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdvtime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQty();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MrktAdvItem.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktAdvItem} returns this
 */
proto.market_api.MrktAdvItem.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 advTime = 2;
 * @return {number}
 */
proto.market_api.MrktAdvItem.prototype.getAdvtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktAdvItem} returns this
 */
proto.market_api.MrktAdvItem.prototype.setAdvtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string secCd = 3;
 * @return {string}
 */
proto.market_api.MrktAdvItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktAdvItem} returns this
 */
proto.market_api.MrktAdvItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 qty = 4;
 * @return {number}
 */
proto.market_api.MrktAdvItem.prototype.getQty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktAdvItem} returns this
 */
proto.market_api.MrktAdvItem.prototype.setQty = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double price = 5;
 * @return {number}
 */
proto.market_api.MrktAdvItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktAdvItem} returns this
 */
proto.market_api.MrktAdvItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 6;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.MrktAdvItem.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.MrktAdvItem} returns this
 */
proto.market_api.MrktAdvItem.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MrktMatchPtItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MrktMatchPtItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MrktMatchPtItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktMatchPtItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mattime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    confirmno: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 5, 0),
    matqty: jspb.Message.getFieldWithDefault(msg, 6, 0),
    matprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MrktMatchPtItem}
 */
proto.market_api.MrktMatchPtItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MrktMatchPtItem;
  return proto.market_api.MrktMatchPtItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MrktMatchPtItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MrktMatchPtItem}
 */
proto.market_api.MrktMatchPtItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMattime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmno(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMatqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMatprice(value);
      break;
    case 8:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MrktMatchPtItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MrktMatchPtItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MrktMatchPtItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MrktMatchPtItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMattime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getConfirmno();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMatqty();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMatprice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MrktMatchPtItem.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 matTime = 2;
 * @return {number}
 */
proto.market_api.MrktMatchPtItem.prototype.getMattime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setMattime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string confirmNo = 3;
 * @return {string}
 */
proto.market_api.MrktMatchPtItem.prototype.getConfirmno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setConfirmno = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string secCd = 4;
 * @return {string}
 */
proto.market_api.MrktMatchPtItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 marketCd = 5;
 * @return {number}
 */
proto.market_api.MrktMatchPtItem.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 matQty = 6;
 * @return {number}
 */
proto.market_api.MrktMatchPtItem.prototype.getMatqty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setMatqty = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double matPrice = 7;
 * @return {number}
 */
proto.market_api.MrktMatchPtItem.prototype.getMatprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setMatprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 8;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.MrktMatchPtItem.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.MrktMatchPtItem} returns this
 */
proto.market_api.MrktMatchPtItem.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.PutThroughData.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.PutThroughData.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.PutThroughData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.PutThroughData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PutThroughData.toObject = function(includeInstance, msg) {
  var f, obj = {
    selllistList: jspb.Message.toObjectList(msg.getSelllistList(),
    proto.market_api.MrktAdvItem.toObject, includeInstance),
    buylistList: jspb.Message.toObjectList(msg.getBuylistList(),
    proto.market_api.MrktAdvItem.toObject, includeInstance),
    matchptlistList: jspb.Message.toObjectList(msg.getMatchptlistList(),
    proto.market_api.MrktMatchPtItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.PutThroughData}
 */
proto.market_api.PutThroughData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.PutThroughData;
  return proto.market_api.PutThroughData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.PutThroughData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.PutThroughData}
 */
proto.market_api.PutThroughData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.MrktAdvItem;
      reader.readMessage(value,proto.market_api.MrktAdvItem.deserializeBinaryFromReader);
      msg.addSelllist(value);
      break;
    case 2:
      var value = new proto.market_api.MrktAdvItem;
      reader.readMessage(value,proto.market_api.MrktAdvItem.deserializeBinaryFromReader);
      msg.addBuylist(value);
      break;
    case 3:
      var value = new proto.market_api.MrktMatchPtItem;
      reader.readMessage(value,proto.market_api.MrktMatchPtItem.deserializeBinaryFromReader);
      msg.addMatchptlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.PutThroughData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.PutThroughData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.PutThroughData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PutThroughData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelllistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.MrktAdvItem.serializeBinaryToWriter
    );
  }
  f = message.getBuylistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.market_api.MrktAdvItem.serializeBinaryToWriter
    );
  }
  f = message.getMatchptlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.market_api.MrktMatchPtItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MrktAdvItem sellList = 1;
 * @return {!Array<!proto.market_api.MrktAdvItem>}
 */
proto.market_api.PutThroughData.prototype.getSelllistList = function() {
  return /** @type{!Array<!proto.market_api.MrktAdvItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MrktAdvItem, 1));
};


/**
 * @param {!Array<!proto.market_api.MrktAdvItem>} value
 * @return {!proto.market_api.PutThroughData} returns this
*/
proto.market_api.PutThroughData.prototype.setSelllistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.MrktAdvItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MrktAdvItem}
 */
proto.market_api.PutThroughData.prototype.addSelllist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.MrktAdvItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.PutThroughData} returns this
 */
proto.market_api.PutThroughData.prototype.clearSelllistList = function() {
  return this.setSelllistList([]);
};


/**
 * repeated MrktAdvItem buyList = 2;
 * @return {!Array<!proto.market_api.MrktAdvItem>}
 */
proto.market_api.PutThroughData.prototype.getBuylistList = function() {
  return /** @type{!Array<!proto.market_api.MrktAdvItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MrktAdvItem, 2));
};


/**
 * @param {!Array<!proto.market_api.MrktAdvItem>} value
 * @return {!proto.market_api.PutThroughData} returns this
*/
proto.market_api.PutThroughData.prototype.setBuylistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.market_api.MrktAdvItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MrktAdvItem}
 */
proto.market_api.PutThroughData.prototype.addBuylist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.market_api.MrktAdvItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.PutThroughData} returns this
 */
proto.market_api.PutThroughData.prototype.clearBuylistList = function() {
  return this.setBuylistList([]);
};


/**
 * repeated MrktMatchPtItem matchPtList = 3;
 * @return {!Array<!proto.market_api.MrktMatchPtItem>}
 */
proto.market_api.PutThroughData.prototype.getMatchptlistList = function() {
  return /** @type{!Array<!proto.market_api.MrktMatchPtItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MrktMatchPtItem, 3));
};


/**
 * @param {!Array<!proto.market_api.MrktMatchPtItem>} value
 * @return {!proto.market_api.PutThroughData} returns this
*/
proto.market_api.PutThroughData.prototype.setMatchptlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.market_api.MrktMatchPtItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MrktMatchPtItem}
 */
proto.market_api.PutThroughData.prototype.addMatchptlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.market_api.MrktMatchPtItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.PutThroughData} returns this
 */
proto.market_api.PutThroughData.prototype.clearMatchptlistList = function() {
  return this.setMatchptlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.PutThroughResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.PutThroughResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.PutThroughResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PutThroughResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    data: (f = msg.getData()) && proto.market_api.PutThroughData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.PutThroughResponse}
 */
proto.market_api.PutThroughResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.PutThroughResponse;
  return proto.market_api.PutThroughResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.PutThroughResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.PutThroughResponse}
 */
proto.market_api.PutThroughResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.PutThroughData;
      reader.readMessage(value,proto.market_api.PutThroughData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.PutThroughResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.PutThroughResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.PutThroughResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PutThroughResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.PutThroughData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.PutThroughResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.PutThroughResponse} returns this
 */
proto.market_api.PutThroughResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.PutThroughResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.PutThroughResponse} returns this
 */
proto.market_api.PutThroughResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.PutThroughResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.PutThroughResponse} returns this
 */
proto.market_api.PutThroughResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PutThroughData data = 4;
 * @return {?proto.market_api.PutThroughData}
 */
proto.market_api.PutThroughResponse.prototype.getData = function() {
  return /** @type{?proto.market_api.PutThroughData} */ (
    jspb.Message.getWrapperField(this, proto.market_api.PutThroughData, 4));
};


/**
 * @param {?proto.market_api.PutThroughData|undefined} value
 * @return {!proto.market_api.PutThroughResponse} returns this
*/
proto.market_api.PutThroughResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.PutThroughResponse} returns this
 */
proto.market_api.PutThroughResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.PutThroughResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.PutThroughParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.PutThroughParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.PutThroughParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PutThroughParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tradedate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.PutThroughParam}
 */
proto.market_api.PutThroughParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.PutThroughParam;
  return proto.market_api.PutThroughParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.PutThroughParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.PutThroughParam}
 */
proto.market_api.PutThroughParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.PutThroughParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.PutThroughParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.PutThroughParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.PutThroughParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.PutThroughParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.PutThroughParam} returns this
 */
proto.market_api.PutThroughParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.PutThroughParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.PutThroughParam} returns this
 */
proto.market_api.PutThroughParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 tradeDate = 3;
 * @return {number}
 */
proto.market_api.PutThroughParam.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.PutThroughParam} returns this
 */
proto.market_api.PutThroughParam.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndexContributionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndexContributionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndexContributionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contributeval: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    contributepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndexContributionItem}
 */
proto.market_api.TopIndexContributionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndexContributionItem;
  return proto.market_api.TopIndexContributionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndexContributionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndexContributionItem}
 */
proto.market_api.TopIndexContributionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributeval(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributepercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndexContributionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndexContributionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndexContributionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContributeval();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getContributepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TopIndexContributionItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndexContributionItem} returns this
 */
proto.market_api.TopIndexContributionItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double contributeVal = 2;
 * @return {number}
 */
proto.market_api.TopIndexContributionItem.prototype.getContributeval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndexContributionItem} returns this
 */
proto.market_api.TopIndexContributionItem.prototype.setContributeval = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double contributePercent = 3;
 * @return {number}
 */
proto.market_api.TopIndexContributionItem.prototype.getContributepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndexContributionItem} returns this
 */
proto.market_api.TopIndexContributionItem.prototype.setContributepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopIndexContributionData.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndexContributionData.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndexContributionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndexContributionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    topincreaseList: jspb.Message.toObjectList(msg.getTopincreaseList(),
    proto.market_api.TopIndexContributionItem.toObject, includeInstance),
    topdecreaseList: jspb.Message.toObjectList(msg.getTopdecreaseList(),
    proto.market_api.TopIndexContributionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndexContributionData}
 */
proto.market_api.TopIndexContributionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndexContributionData;
  return proto.market_api.TopIndexContributionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndexContributionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndexContributionData}
 */
proto.market_api.TopIndexContributionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.TopIndexContributionItem;
      reader.readMessage(value,proto.market_api.TopIndexContributionItem.deserializeBinaryFromReader);
      msg.addTopincrease(value);
      break;
    case 2:
      var value = new proto.market_api.TopIndexContributionItem;
      reader.readMessage(value,proto.market_api.TopIndexContributionItem.deserializeBinaryFromReader);
      msg.addTopdecrease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndexContributionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndexContributionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndexContributionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopincreaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.TopIndexContributionItem.serializeBinaryToWriter
    );
  }
  f = message.getTopdecreaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.market_api.TopIndexContributionItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TopIndexContributionItem topIncrease = 1;
 * @return {!Array<!proto.market_api.TopIndexContributionItem>}
 */
proto.market_api.TopIndexContributionData.prototype.getTopincreaseList = function() {
  return /** @type{!Array<!proto.market_api.TopIndexContributionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopIndexContributionItem, 1));
};


/**
 * @param {!Array<!proto.market_api.TopIndexContributionItem>} value
 * @return {!proto.market_api.TopIndexContributionData} returns this
*/
proto.market_api.TopIndexContributionData.prototype.setTopincreaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.TopIndexContributionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopIndexContributionItem}
 */
proto.market_api.TopIndexContributionData.prototype.addTopincrease = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.TopIndexContributionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopIndexContributionData} returns this
 */
proto.market_api.TopIndexContributionData.prototype.clearTopincreaseList = function() {
  return this.setTopincreaseList([]);
};


/**
 * repeated TopIndexContributionItem topDecrease = 2;
 * @return {!Array<!proto.market_api.TopIndexContributionItem>}
 */
proto.market_api.TopIndexContributionData.prototype.getTopdecreaseList = function() {
  return /** @type{!Array<!proto.market_api.TopIndexContributionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopIndexContributionItem, 2));
};


/**
 * @param {!Array<!proto.market_api.TopIndexContributionItem>} value
 * @return {!proto.market_api.TopIndexContributionData} returns this
*/
proto.market_api.TopIndexContributionData.prototype.setTopdecreaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.market_api.TopIndexContributionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopIndexContributionItem}
 */
proto.market_api.TopIndexContributionData.prototype.addTopdecrease = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.market_api.TopIndexContributionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopIndexContributionData} returns this
 */
proto.market_api.TopIndexContributionData.prototype.clearTopdecreaseList = function() {
  return this.setTopdecreaseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndexContributionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndexContributionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndexContributionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    data: (f = msg.getData()) && proto.market_api.TopIndexContributionData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndexContributionResponse}
 */
proto.market_api.TopIndexContributionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndexContributionResponse;
  return proto.market_api.TopIndexContributionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndexContributionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndexContributionResponse}
 */
proto.market_api.TopIndexContributionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopIndexContributionData;
      reader.readMessage(value,proto.market_api.TopIndexContributionData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndexContributionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndexContributionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndexContributionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.TopIndexContributionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.TopIndexContributionResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndexContributionResponse} returns this
 */
proto.market_api.TopIndexContributionResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopIndexContributionResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndexContributionResponse} returns this
 */
proto.market_api.TopIndexContributionResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopIndexContributionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndexContributionResponse} returns this
 */
proto.market_api.TopIndexContributionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TopIndexContributionData data = 4;
 * @return {?proto.market_api.TopIndexContributionData}
 */
proto.market_api.TopIndexContributionResponse.prototype.getData = function() {
  return /** @type{?proto.market_api.TopIndexContributionData} */ (
    jspb.Message.getWrapperField(this, proto.market_api.TopIndexContributionData, 4));
};


/**
 * @param {?proto.market_api.TopIndexContributionData|undefined} value
 * @return {!proto.market_api.TopIndexContributionResponse} returns this
*/
proto.market_api.TopIndexContributionResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.TopIndexContributionResponse} returns this
 */
proto.market_api.TopIndexContributionResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.TopIndexContributionResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndexContributionParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndexContributionParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndexContributionParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndexContributionParam}
 */
proto.market_api.TopIndexContributionParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndexContributionParam;
  return proto.market_api.TopIndexContributionParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndexContributionParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndexContributionParam}
 */
proto.market_api.TopIndexContributionParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndexContributionParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndexContributionParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndexContributionParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndexContributionParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.TopIndexContributionParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndexContributionParam} returns this
 */
proto.market_api.TopIndexContributionParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.TopIndexContributionParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndexContributionParam} returns this
 */
proto.market_api.TopIndexContributionParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketBreadthItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketBreadthItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketBreadthItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketBreadthItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contributeval: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    contributepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    marketcd: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgamt5d: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    marketcapweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketBreadthItem}
 */
proto.market_api.MarketBreadthItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketBreadthItem;
  return proto.market_api.MarketBreadthItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketBreadthItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketBreadthItem}
 */
proto.market_api.MarketBreadthItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributeval(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributepercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgamt5d(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcapweight(value);
      break;
    case 7:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketBreadthItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketBreadthItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketBreadthItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketBreadthItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContributeval();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getContributepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvgamt5d();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMarketcapweight();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.MarketBreadthItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double contributeVal = 2;
 * @return {number}
 */
proto.market_api.MarketBreadthItem.prototype.getContributeval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setContributeval = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double contributePercent = 3;
 * @return {number}
 */
proto.market_api.MarketBreadthItem.prototype.getContributepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setContributepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 marketCd = 4;
 * @return {number}
 */
proto.market_api.MarketBreadthItem.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double avgAmt5d = 5;
 * @return {number}
 */
proto.market_api.MarketBreadthItem.prototype.getAvgamt5d = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setAvgamt5d = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double marketCapWeight = 6;
 * @return {number}
 */
proto.market_api.MarketBreadthItem.prototype.getMarketcapweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setMarketcapweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 7;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.MarketBreadthItem.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.MarketBreadthItem} returns this
 */
proto.market_api.MarketBreadthItem.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketBreadthResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketBreadthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketBreadthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketBreadthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketBreadthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.MarketBreadthItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketBreadthResponse}
 */
proto.market_api.MarketBreadthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketBreadthResponse;
  return proto.market_api.MarketBreadthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketBreadthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketBreadthResponse}
 */
proto.market_api.MarketBreadthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketBreadthItem;
      reader.readMessage(value,proto.market_api.MarketBreadthItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketBreadthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketBreadthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketBreadthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketBreadthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.MarketBreadthItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.MarketBreadthResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthResponse} returns this
 */
proto.market_api.MarketBreadthResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketBreadthResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketBreadthResponse} returns this
 */
proto.market_api.MarketBreadthResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketBreadthResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketBreadthResponse} returns this
 */
proto.market_api.MarketBreadthResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated MarketBreadthItem data = 4;
 * @return {!Array<!proto.market_api.MarketBreadthItem>}
 */
proto.market_api.MarketBreadthResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.MarketBreadthItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketBreadthItem, 4));
};


/**
 * @param {!Array<!proto.market_api.MarketBreadthItem>} value
 * @return {!proto.market_api.MarketBreadthResponse} returns this
*/
proto.market_api.MarketBreadthResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.MarketBreadthItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketBreadthItem}
 */
proto.market_api.MarketBreadthResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.MarketBreadthItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketBreadthResponse} returns this
 */
proto.market_api.MarketBreadthResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketLiquidityItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketLiquidityItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketLiquidityItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketLiquidityItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    indexcd: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    time: jspb.Message.getFieldWithDefault(msg, 3, ""),
    valuet0: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    valuet1: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    valuet5: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    valuet10: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    valuet30: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketLiquidityItem}
 */
proto.market_api.MarketLiquidityItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketLiquidityItem;
  return proto.market_api.MarketLiquidityItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketLiquidityItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketLiquidityItem}
 */
proto.market_api.MarketLiquidityItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndexcd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuet0(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuet1(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuet5(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuet10(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuet30(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketLiquidityItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketLiquidityItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketLiquidityItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketLiquidityItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getIndexcd();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValuet0();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getValuet1();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getValuet5();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getValuet10();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getValuet30();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional double tradeDate = 1;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double indexCd = 2;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string time = 3;
 * @return {string}
 */
proto.market_api.MarketLiquidityItem.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double valueT0 = 4;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getValuet0 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setValuet0 = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double valueT1 = 5;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getValuet1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setValuet1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double valueT5 = 6;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getValuet5 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setValuet5 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double valueT10 = 7;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getValuet10 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setValuet10 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double valueT30 = 8;
 * @return {number}
 */
proto.market_api.MarketLiquidityItem.prototype.getValuet30 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityItem} returns this
 */
proto.market_api.MarketLiquidityItem.prototype.setValuet30 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.MarketLiquidityResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketLiquidityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketLiquidityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketLiquidityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketLiquidityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.MarketLiquidityItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketLiquidityResponse}
 */
proto.market_api.MarketLiquidityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketLiquidityResponse;
  return proto.market_api.MarketLiquidityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketLiquidityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketLiquidityResponse}
 */
proto.market_api.MarketLiquidityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.MarketLiquidityItem;
      reader.readMessage(value,proto.market_api.MarketLiquidityItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketLiquidityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketLiquidityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketLiquidityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketLiquidityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.MarketLiquidityItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.MarketLiquidityResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityResponse} returns this
 */
proto.market_api.MarketLiquidityResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.MarketLiquidityResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketLiquidityResponse} returns this
 */
proto.market_api.MarketLiquidityResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.MarketLiquidityResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketLiquidityResponse} returns this
 */
proto.market_api.MarketLiquidityResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated MarketLiquidityItem data = 4;
 * @return {!Array<!proto.market_api.MarketLiquidityItem>}
 */
proto.market_api.MarketLiquidityResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.MarketLiquidityItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.MarketLiquidityItem, 4));
};


/**
 * @param {!Array<!proto.market_api.MarketLiquidityItem>} value
 * @return {!proto.market_api.MarketLiquidityResponse} returns this
*/
proto.market_api.MarketLiquidityResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.MarketLiquidityItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.MarketLiquidityItem}
 */
proto.market_api.MarketLiquidityResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.MarketLiquidityItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.MarketLiquidityResponse} returns this
 */
proto.market_api.MarketLiquidityResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesTradeForeignItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesTradeForeignItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeForeignItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    industrynamevi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    industrynameen: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buyforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    sellforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    netforeignqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    buyforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    sellforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    netforeignamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesTradeForeignItem}
 */
proto.market_api.TopIndustriesTradeForeignItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesTradeForeignItem;
  return proto.market_api.TopIndustriesTradeForeignItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesTradeForeignItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesTradeForeignItem}
 */
proto.market_api.TopIndustriesTradeForeignItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustrynamevi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustrynameen(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignqty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetforeignqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBuyforeignamt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSellforeignamt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetforeignamt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesTradeForeignItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesTradeForeignItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeForeignItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIndustrynamevi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndustrynameen();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBuyforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSellforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getNetforeignqty();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBuyforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSellforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getNetforeignamt();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional int32 marketCd = 1;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string industryNameVi = 2;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getIndustrynamevi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setIndustrynamevi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string industryNameEn = 3;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getIndustrynameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setIndustrynameen = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double buyForeignQty = 4;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getBuyforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setBuyforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double sellForeignQty = 5;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getSellforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setSellforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double netForeignQty = 6;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getNetforeignqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setNetforeignqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double buyForeignAmt = 7;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getBuyforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setBuyforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double sellForeignAmt = 8;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getSellforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setSellforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double netForeignAmt = 9;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.getNetforeignamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignItem} returns this
 */
proto.market_api.TopIndustriesTradeForeignItem.prototype.setNetforeignamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopIndustriesTradeForeignResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesTradeForeignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesTradeForeignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeForeignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.TopIndustriesTradeForeignItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse}
 */
proto.market_api.TopIndustriesTradeForeignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesTradeForeignResponse;
  return proto.market_api.TopIndustriesTradeForeignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesTradeForeignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse}
 */
proto.market_api.TopIndustriesTradeForeignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopIndustriesTradeForeignItem;
      reader.readMessage(value,proto.market_api.TopIndustriesTradeForeignItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesTradeForeignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesTradeForeignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeForeignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopIndustriesTradeForeignItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse} returns this
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse} returns this
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse} returns this
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TopIndustriesTradeForeignItem data = 4;
 * @return {!Array<!proto.market_api.TopIndustriesTradeForeignItem>}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.TopIndustriesTradeForeignItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopIndustriesTradeForeignItem, 4));
};


/**
 * @param {!Array<!proto.market_api.TopIndustriesTradeForeignItem>} value
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse} returns this
*/
proto.market_api.TopIndustriesTradeForeignResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopIndustriesTradeForeignItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopIndustriesTradeForeignItem}
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopIndustriesTradeForeignItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopIndustriesTradeForeignResponse} returns this
 */
proto.market_api.TopIndustriesTradeForeignResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesContributionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesContributionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesContributionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesContributionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    industrynamevi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    industrynameen: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contributeval: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    contributepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesContributionItem}
 */
proto.market_api.TopIndustriesContributionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesContributionItem;
  return proto.market_api.TopIndustriesContributionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesContributionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesContributionItem}
 */
proto.market_api.TopIndustriesContributionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustrynamevi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustrynameen(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributeval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributepercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesContributionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesContributionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesContributionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesContributionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIndustrynamevi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndustrynameen();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContributeval();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getContributepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 marketCd = 1;
 * @return {number}
 */
proto.market_api.TopIndustriesContributionItem.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesContributionItem} returns this
 */
proto.market_api.TopIndustriesContributionItem.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string industryNameVi = 2;
 * @return {string}
 */
proto.market_api.TopIndustriesContributionItem.prototype.getIndustrynamevi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesContributionItem} returns this
 */
proto.market_api.TopIndustriesContributionItem.prototype.setIndustrynamevi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string industryNameEn = 3;
 * @return {string}
 */
proto.market_api.TopIndustriesContributionItem.prototype.getIndustrynameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesContributionItem} returns this
 */
proto.market_api.TopIndustriesContributionItem.prototype.setIndustrynameen = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double contributeVal = 4;
 * @return {number}
 */
proto.market_api.TopIndustriesContributionItem.prototype.getContributeval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesContributionItem} returns this
 */
proto.market_api.TopIndustriesContributionItem.prototype.setContributeval = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double contributePercent = 5;
 * @return {number}
 */
proto.market_api.TopIndustriesContributionItem.prototype.getContributepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesContributionItem} returns this
 */
proto.market_api.TopIndustriesContributionItem.prototype.setContributepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopIndustriesContributionResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesContributionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesContributionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesContributionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.TopIndustriesContributionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesContributionResponse}
 */
proto.market_api.TopIndustriesContributionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesContributionResponse;
  return proto.market_api.TopIndustriesContributionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesContributionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesContributionResponse}
 */
proto.market_api.TopIndustriesContributionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopIndustriesContributionItem;
      reader.readMessage(value,proto.market_api.TopIndustriesContributionItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesContributionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesContributionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesContributionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopIndustriesContributionItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesContributionResponse} returns this
 */
proto.market_api.TopIndustriesContributionResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesContributionResponse} returns this
 */
proto.market_api.TopIndustriesContributionResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesContributionResponse} returns this
 */
proto.market_api.TopIndustriesContributionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TopIndustriesContributionItem data = 4;
 * @return {!Array<!proto.market_api.TopIndustriesContributionItem>}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.TopIndustriesContributionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopIndustriesContributionItem, 4));
};


/**
 * @param {!Array<!proto.market_api.TopIndustriesContributionItem>} value
 * @return {!proto.market_api.TopIndustriesContributionResponse} returns this
*/
proto.market_api.TopIndustriesContributionResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopIndustriesContributionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopIndustriesContributionItem}
 */
proto.market_api.TopIndustriesContributionResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopIndustriesContributionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopIndustriesContributionResponse} returns this
 */
proto.market_api.TopIndustriesContributionResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesTradeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesTradeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesTradeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketcd: jspb.Message.getFieldWithDefault(msg, 1, 0),
    industrynamevi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    industrynameen: jspb.Message.getFieldWithDefault(msg, 3, ""),
    industrysummarketamt: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    contributepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesTradeItem}
 */
proto.market_api.TopIndustriesTradeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesTradeItem;
  return proto.market_api.TopIndustriesTradeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesTradeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesTradeItem}
 */
proto.market_api.TopIndustriesTradeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustrynamevi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustrynameen(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndustrysummarketamt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContributepercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesTradeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesTradeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesTradeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIndustrynamevi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndustrynameen();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIndustrysummarketamt();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getContributepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 marketCd = 1;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeItem.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeItem} returns this
 */
proto.market_api.TopIndustriesTradeItem.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string industryNameVi = 2;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeItem.prototype.getIndustrynamevi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeItem} returns this
 */
proto.market_api.TopIndustriesTradeItem.prototype.setIndustrynamevi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string industryNameEn = 3;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeItem.prototype.getIndustrynameen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeItem} returns this
 */
proto.market_api.TopIndustriesTradeItem.prototype.setIndustrynameen = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double industrySumMarketAmt = 4;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeItem.prototype.getIndustrysummarketamt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeItem} returns this
 */
proto.market_api.TopIndustriesTradeItem.prototype.setIndustrysummarketamt = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double contributePercent = 5;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeItem.prototype.getContributepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeItem} returns this
 */
proto.market_api.TopIndustriesTradeItem.prototype.setContributepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TopIndustriesTradeResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesTradeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesTradeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.TopIndustriesTradeItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesTradeResponse}
 */
proto.market_api.TopIndustriesTradeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesTradeResponse;
  return proto.market_api.TopIndustriesTradeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesTradeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesTradeResponse}
 */
proto.market_api.TopIndustriesTradeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TopIndustriesTradeItem;
      reader.readMessage(value,proto.market_api.TopIndustriesTradeItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesTradeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesTradeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TopIndustriesTradeItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeResponse} returns this
 */
proto.market_api.TopIndustriesTradeResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeResponse} returns this
 */
proto.market_api.TopIndustriesTradeResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeResponse} returns this
 */
proto.market_api.TopIndustriesTradeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TopIndustriesTradeItem data = 4;
 * @return {!Array<!proto.market_api.TopIndustriesTradeItem>}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.TopIndustriesTradeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TopIndustriesTradeItem, 4));
};


/**
 * @param {!Array<!proto.market_api.TopIndustriesTradeItem>} value
 * @return {!proto.market_api.TopIndustriesTradeResponse} returns this
*/
proto.market_api.TopIndustriesTradeResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TopIndustriesTradeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TopIndustriesTradeItem}
 */
proto.market_api.TopIndustriesTradeResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TopIndustriesTradeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TopIndustriesTradeResponse} returns this
 */
proto.market_api.TopIndustriesTradeResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketBreadthParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketBreadthParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketBreadthParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketBreadthParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketBreadthParam}
 */
proto.market_api.MarketBreadthParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketBreadthParam;
  return proto.market_api.MarketBreadthParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketBreadthParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketBreadthParam}
 */
proto.market_api.MarketBreadthParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketBreadthParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketBreadthParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketBreadthParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketBreadthParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.MarketBreadthParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketBreadthParam} returns this
 */
proto.market_api.MarketBreadthParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.MarketBreadthParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketBreadthParam} returns this
 */
proto.market_api.MarketBreadthParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketLiquidityParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketLiquidityParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketLiquidityParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketLiquidityParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketLiquidityParam}
 */
proto.market_api.MarketLiquidityParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketLiquidityParam;
  return proto.market_api.MarketLiquidityParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketLiquidityParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketLiquidityParam}
 */
proto.market_api.MarketLiquidityParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketLiquidityParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketLiquidityParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketLiquidityParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketLiquidityParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.MarketLiquidityParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.MarketLiquidityParam} returns this
 */
proto.market_api.MarketLiquidityParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.MarketLiquidityParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketLiquidityParam} returns this
 */
proto.market_api.MarketLiquidityParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesTradeForeignParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesTradeForeignParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesTradeForeignParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeForeignParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesTradeForeignParam}
 */
proto.market_api.TopIndustriesTradeForeignParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesTradeForeignParam;
  return proto.market_api.TopIndustriesTradeForeignParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesTradeForeignParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesTradeForeignParam}
 */
proto.market_api.TopIndustriesTradeForeignParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesTradeForeignParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesTradeForeignParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesTradeForeignParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeForeignParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeForeignParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeForeignParam} returns this
 */
proto.market_api.TopIndustriesTradeForeignParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeForeignParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeForeignParam} returns this
 */
proto.market_api.TopIndustriesTradeForeignParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesContributionParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesContributionParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesContributionParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesContributionParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesContributionParam}
 */
proto.market_api.TopIndustriesContributionParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesContributionParam;
  return proto.market_api.TopIndustriesContributionParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesContributionParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesContributionParam}
 */
proto.market_api.TopIndustriesContributionParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesContributionParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesContributionParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesContributionParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesContributionParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.TopIndustriesContributionParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesContributionParam} returns this
 */
proto.market_api.TopIndustriesContributionParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.TopIndustriesContributionParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesContributionParam} returns this
 */
proto.market_api.TopIndustriesContributionParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TopIndustriesTradeParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TopIndustriesTradeParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TopIndustriesTradeParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketcd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TopIndustriesTradeParam}
 */
proto.market_api.TopIndustriesTradeParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TopIndustriesTradeParam;
  return proto.market_api.TopIndustriesTradeParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TopIndustriesTradeParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TopIndustriesTradeParam}
 */
proto.market_api.TopIndustriesTradeParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketcd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TopIndustriesTradeParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TopIndustriesTradeParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TopIndustriesTradeParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TopIndustriesTradeParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketcd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.TopIndustriesTradeParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TopIndustriesTradeParam} returns this
 */
proto.market_api.TopIndustriesTradeParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 marketCd = 2;
 * @return {number}
 */
proto.market_api.TopIndustriesTradeParam.prototype.getMarketcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TopIndustriesTradeParam} returns this
 */
proto.market_api.TopIndustriesTradeParam.prototype.setMarketcd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.IndustriesHeatMapParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.IndustriesHeatMapParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.IndustriesHeatMapParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustriesHeatMapParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    authencode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    industriesid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.IndustriesHeatMapParam}
 */
proto.market_api.IndustriesHeatMapParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.IndustriesHeatMapParam;
  return proto.market_api.IndustriesHeatMapParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.IndustriesHeatMapParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.IndustriesHeatMapParam}
 */
proto.market_api.IndustriesHeatMapParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndustriesid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.IndustriesHeatMapParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.IndustriesHeatMapParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.IndustriesHeatMapParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustriesHeatMapParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndustriesid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string authenCode = 1;
 * @return {string}
 */
proto.market_api.IndustriesHeatMapParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.IndustriesHeatMapParam} returns this
 */
proto.market_api.IndustriesHeatMapParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 industriesId = 2;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapParam.prototype.getIndustriesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapParam} returns this
 */
proto.market_api.IndustriesHeatMapParam.prototype.setIndustriesid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.BasePriceUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.BasePriceUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.BasePriceUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.BasePriceUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseseccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    basepricecp: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.BasePriceUpdate}
 */
proto.market_api.BasePriceUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.BasePriceUpdate;
  return proto.market_api.BasePriceUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.BasePriceUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.BasePriceUpdate}
 */
proto.market_api.BasePriceUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseseccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasepricecp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.BasePriceUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.BasePriceUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.BasePriceUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.BasePriceUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseseccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBasepricecp();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.BasePriceUpdate.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.BasePriceUpdate} returns this
 */
proto.market_api.BasePriceUpdate.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string baseSecCd = 2;
 * @return {string}
 */
proto.market_api.BasePriceUpdate.prototype.getBaseseccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.BasePriceUpdate} returns this
 */
proto.market_api.BasePriceUpdate.prototype.setBaseseccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double basePrice = 3;
 * @return {number}
 */
proto.market_api.BasePriceUpdate.prototype.getBaseprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.BasePriceUpdate} returns this
 */
proto.market_api.BasePriceUpdate.prototype.setBaseprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double basePriceCp = 4;
 * @return {number}
 */
proto.market_api.BasePriceUpdate.prototype.getBasepricecp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.BasePriceUpdate} returns this
 */
proto.market_api.BasePriceUpdate.prototype.setBasepricecp = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.AnyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.AnyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.AnyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.AnyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.AnyMessage}
 */
proto.market_api.AnyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.AnyMessage;
  return proto.market_api.AnyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.AnyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.AnyMessage}
 */
proto.market_api.AnyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeUrl(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.AnyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.AnyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.AnyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.AnyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string type_url = 1;
 * @return {string}
 */
proto.market_api.AnyMessage.prototype.getTypeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.AnyMessage} returns this
 */
proto.market_api.AnyMessage.prototype.setTypeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {!(string|Uint8Array)}
 */
proto.market_api.AnyMessage.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.market_api.AnyMessage.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.market_api.AnyMessage.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.market_api.AnyMessage} returns this
 */
proto.market_api.AnyMessage.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.ProjectOpen.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.ProjectOpen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.ProjectOpen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ProjectOpen.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    qty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    colorcode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updtime: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.ProjectOpen}
 */
proto.market_api.ProjectOpen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.ProjectOpen;
  return proto.market_api.ProjectOpen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.ProjectOpen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.ProjectOpen}
 */
proto.market_api.ProjectOpen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 6:
      var value = /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (reader.readEnum());
      msg.setColorcode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.ProjectOpen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.ProjectOpen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.ProjectOpen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.ProjectOpen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getQty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getColorcode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUpdtime();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.ProjectOpen.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.market_api.ProjectOpen.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double qty = 3;
 * @return {number}
 */
proto.market_api.ProjectOpen.prototype.getQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double changePoint = 4;
 * @return {number}
 */
proto.market_api.ProjectOpen.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double changePercent = 5;
 * @return {number}
 */
proto.market_api.ProjectOpen.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional STOCK_COLOR_CODE colorCode = 6;
 * @return {!proto.market_api.STOCK_COLOR_CODE}
 */
proto.market_api.ProjectOpen.prototype.getColorcode = function() {
  return /** @type {!proto.market_api.STOCK_COLOR_CODE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.market_api.STOCK_COLOR_CODE} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 updTime = 7;
 * @return {number}
 */
proto.market_api.ProjectOpen.prototype.getUpdtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.ProjectOpen} returns this
 */
proto.market_api.ProjectOpen.prototype.setUpdtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.IndustriesHeatMapItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.IndustriesHeatMapItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.IndustriesHeatMapItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustriesHeatMapItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    industryid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    marketcap: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.IndustriesHeatMapItem}
 */
proto.market_api.IndustriesHeatMapItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.IndustriesHeatMapItem;
  return proto.market_api.IndustriesHeatMapItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.IndustriesHeatMapItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.IndustriesHeatMapItem}
 */
proto.market_api.IndustriesHeatMapItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndustryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketcap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.IndustriesHeatMapItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.IndustriesHeatMapItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.IndustriesHeatMapItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustriesHeatMapItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndustryid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMarketcap();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 industryId = 1;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapItem.prototype.getIndustryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapItem} returns this
 */
proto.market_api.IndustriesHeatMapItem.prototype.setIndustryid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.IndustriesHeatMapItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.IndustriesHeatMapItem} returns this
 */
proto.market_api.IndustriesHeatMapItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double lastPrice = 3;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapItem.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapItem} returns this
 */
proto.market_api.IndustriesHeatMapItem.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double changePoint = 4;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapItem.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapItem} returns this
 */
proto.market_api.IndustriesHeatMapItem.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double changePercent = 5;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapItem.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapItem} returns this
 */
proto.market_api.IndustriesHeatMapItem.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double marketCap = 6;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapItem.prototype.getMarketcap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapItem} returns this
 */
proto.market_api.IndustriesHeatMapItem.prototype.setMarketcap = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.IndustriesHeatMapResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.IndustriesHeatMapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.IndustriesHeatMapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustriesHeatMapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.IndustriesHeatMapItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.IndustriesHeatMapResponse}
 */
proto.market_api.IndustriesHeatMapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.IndustriesHeatMapResponse;
  return proto.market_api.IndustriesHeatMapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.IndustriesHeatMapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.IndustriesHeatMapResponse}
 */
proto.market_api.IndustriesHeatMapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.IndustriesHeatMapItem;
      reader.readMessage(value,proto.market_api.IndustriesHeatMapItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.IndustriesHeatMapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.IndustriesHeatMapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.IndustriesHeatMapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.IndustriesHeatMapItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.IndustriesHeatMapResponse} returns this
 */
proto.market_api.IndustriesHeatMapResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.IndustriesHeatMapResponse} returns this
 */
proto.market_api.IndustriesHeatMapResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.IndustriesHeatMapResponse} returns this
 */
proto.market_api.IndustriesHeatMapResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated IndustriesHeatMapItem data = 4;
 * @return {!Array<!proto.market_api.IndustriesHeatMapItem>}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.IndustriesHeatMapItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.IndustriesHeatMapItem, 4));
};


/**
 * @param {!Array<!proto.market_api.IndustriesHeatMapItem>} value
 * @return {!proto.market_api.IndustriesHeatMapResponse} returns this
*/
proto.market_api.IndustriesHeatMapResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.IndustriesHeatMapItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.IndustriesHeatMapItem}
 */
proto.market_api.IndustriesHeatMapResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.IndustriesHeatMapItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.IndustriesHeatMapResponse} returns this
 */
proto.market_api.IndustriesHeatMapResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecQuotesSameIndustryParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecQuotesSameIndustryParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecQuotesSameIndustryParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesSameIndustryParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecQuotesSameIndustryParam}
 */
proto.market_api.SecQuotesSameIndustryParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecQuotesSameIndustryParam;
  return proto.market_api.SecQuotesSameIndustryParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecQuotesSameIndustryParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecQuotesSameIndustryParam}
 */
proto.market_api.SecQuotesSameIndustryParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecQuotesSameIndustryParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecQuotesSameIndustryParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecQuotesSameIndustryParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesSameIndustryParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SecQuotesSameIndustryParam.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesSameIndustryParam} returns this
 */
proto.market_api.SecQuotesSameIndustryParam.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authenCode = 2;
 * @return {string}
 */
proto.market_api.SecQuotesSameIndustryParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesSameIndustryParam} returns this
 */
proto.market_api.SecQuotesSameIndustryParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SecQuotesSameIndustryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SecQuotesSameIndustryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesSameIndustryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    changepoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    changepercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    tradeqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    pe: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    eps: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    bvps: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    pb: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SecQuotesSameIndustryResponse}
 */
proto.market_api.SecQuotesSameIndustryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SecQuotesSameIndustryResponse;
  return proto.market_api.SecQuotesSameIndustryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SecQuotesSameIndustryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SecQuotesSameIndustryResponse}
 */
proto.market_api.SecQuotesSameIndustryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastprice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangepercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeqty(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPe(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEps(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBvps(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SecQuotesSameIndustryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SecQuotesSameIndustryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SecQuotesSameIndustryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastprice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getChangepoint();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getChangepercent();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTradeqty();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPe();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getEps();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBvps();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPb();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double lastPrice = 2;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getLastprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setLastprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double changePoint = 3;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getChangepoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setChangepoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double changePercent = 4;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getChangepercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setChangepercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double tradeQty = 5;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getTradeqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setTradeqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double pe = 6;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getPe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setPe = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double eps = 7;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getEps = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setEps = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double bvps = 8;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getBvps = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setBvps = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double pb = 9;
 * @return {number}
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.getPb = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SecQuotesSameIndustryResponse} returns this
 */
proto.market_api.SecQuotesSameIndustryResponse.prototype.setPb = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindCurrencyInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindCurrencyInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindCurrencyInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindCurrencyInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && proto.market_api.NullableString.toObject(includeInstance, f),
    ma: (f = msg.getMa()) && proto.market_api.NullableString.toObject(includeInstance, f),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindCurrencyInput}
 */
proto.market_api.FindCurrencyInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindCurrencyInput;
  return proto.market_api.FindCurrencyInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindCurrencyInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindCurrencyInput}
 */
proto.market_api.FindCurrencyInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.market_api.NullableString;
      reader.readMessage(value,proto.market_api.NullableString.deserializeBinaryFromReader);
      msg.setMa(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindCurrencyInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindCurrencyInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindCurrencyInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindCurrencyInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getMa();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.market_api.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional NullableString userID = 1;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.FindCurrencyInput.prototype.getUserid = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 1));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.FindCurrencyInput} returns this
*/
proto.market_api.FindCurrencyInput.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.FindCurrencyInput} returns this
 */
proto.market_api.FindCurrencyInput.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.FindCurrencyInput.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NullableString MA = 2;
 * @return {?proto.market_api.NullableString}
 */
proto.market_api.FindCurrencyInput.prototype.getMa = function() {
  return /** @type{?proto.market_api.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.market_api.NullableString, 2));
};


/**
 * @param {?proto.market_api.NullableString|undefined} value
 * @return {!proto.market_api.FindCurrencyInput} returns this
*/
proto.market_api.FindCurrencyInput.prototype.setMa = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.FindCurrencyInput} returns this
 */
proto.market_api.FindCurrencyInput.prototype.clearMa = function() {
  return this.setMa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.FindCurrencyInput.prototype.hasMa = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.FindCurrencyInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindCurrencyInput} returns this
 */
proto.market_api.FindCurrencyInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.FindCurrencyResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.CurrencyInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindCurrencyResponse}
 */
proto.market_api.FindCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindCurrencyResponse;
  return proto.market_api.FindCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindCurrencyResponse}
 */
proto.market_api.FindCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.CurrencyInfo;
      reader.readMessage(value,proto.market_api.CurrencyInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.CurrencyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.FindCurrencyResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.FindCurrencyResponse} returns this
 */
proto.market_api.FindCurrencyResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.FindCurrencyResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindCurrencyResponse} returns this
 */
proto.market_api.FindCurrencyResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.FindCurrencyResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindCurrencyResponse} returns this
 */
proto.market_api.FindCurrencyResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CurrencyInfo data = 4;
 * @return {!Array<!proto.market_api.CurrencyInfo>}
 */
proto.market_api.FindCurrencyResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.CurrencyInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.CurrencyInfo, 4));
};


/**
 * @param {!Array<!proto.market_api.CurrencyInfo>} value
 * @return {!proto.market_api.FindCurrencyResponse} returns this
*/
proto.market_api.FindCurrencyResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.CurrencyInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.CurrencyInfo}
 */
proto.market_api.FindCurrencyResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.CurrencyInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.FindCurrencyResponse} returns this
 */
proto.market_api.FindCurrencyResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.CurrencyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.CurrencyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.CurrencyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.CurrencyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ma: jspb.Message.getFieldWithDefault(msg, 1, ""),
    change: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    perchange: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    tradingdate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.CurrencyInfo}
 */
proto.market_api.CurrencyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.CurrencyInfo;
  return proto.market_api.CurrencyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.CurrencyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.CurrencyInfo}
 */
proto.market_api.CurrencyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMa(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChange(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPerchange(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTradingdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.CurrencyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.CurrencyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.CurrencyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.CurrencyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMa();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChange();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPerchange();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTradingdate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string MA = 1;
 * @return {string}
 */
proto.market_api.CurrencyInfo.prototype.getMa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.CurrencyInfo} returns this
 */
proto.market_api.CurrencyInfo.prototype.setMa = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double change = 2;
 * @return {number}
 */
proto.market_api.CurrencyInfo.prototype.getChange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.CurrencyInfo} returns this
 */
proto.market_api.CurrencyInfo.prototype.setChange = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double perchange = 3;
 * @return {number}
 */
proto.market_api.CurrencyInfo.prototype.getPerchange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.CurrencyInfo} returns this
 */
proto.market_api.CurrencyInfo.prototype.setPerchange = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double value = 4;
 * @return {number}
 */
proto.market_api.CurrencyInfo.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.CurrencyInfo} returns this
 */
proto.market_api.CurrencyInfo.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 tradingDate = 5;
 * @return {number}
 */
proto.market_api.CurrencyInfo.prototype.getTradingdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.CurrencyInfo} returns this
 */
proto.market_api.CurrencyInfo.prototype.setTradingdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindTechnicalsInput.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindTechnicalsInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindTechnicalsInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindTechnicalsInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindTechnicalsInput}
 */
proto.market_api.FindTechnicalsInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindTechnicalsInput;
  return proto.market_api.FindTechnicalsInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindTechnicalsInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindTechnicalsInput}
 */
proto.market_api.FindTechnicalsInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindTechnicalsInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindTechnicalsInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindTechnicalsInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindTechnicalsInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.market_api.FindTechnicalsInput.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindTechnicalsInput} returns this
 */
proto.market_api.FindTechnicalsInput.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.FindTechnicalsInput.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindTechnicalsInput} returns this
 */
proto.market_api.FindTechnicalsInput.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.market_api.FindTechnicalsInput.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindTechnicalsInput} returns this
 */
proto.market_api.FindTechnicalsInput.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.FindTechnicalsInput.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindTechnicalsInput} returns this
 */
proto.market_api.FindTechnicalsInput.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.FindTechnicalsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.FindTechnicalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.FindTechnicalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.FindTechnicalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindTechnicalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.TechnicalData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.FindTechnicalsResponse}
 */
proto.market_api.FindTechnicalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.FindTechnicalsResponse;
  return proto.market_api.FindTechnicalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.FindTechnicalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.FindTechnicalsResponse}
 */
proto.market_api.FindTechnicalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.TechnicalData;
      reader.readMessage(value,proto.market_api.TechnicalData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.FindTechnicalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.FindTechnicalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.FindTechnicalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.FindTechnicalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.TechnicalData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.FindTechnicalsResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.FindTechnicalsResponse} returns this
 */
proto.market_api.FindTechnicalsResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.FindTechnicalsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindTechnicalsResponse} returns this
 */
proto.market_api.FindTechnicalsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.FindTechnicalsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.FindTechnicalsResponse} returns this
 */
proto.market_api.FindTechnicalsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TechnicalData data = 4;
 * @return {!Array<!proto.market_api.TechnicalData>}
 */
proto.market_api.FindTechnicalsResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.TechnicalData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TechnicalData, 4));
};


/**
 * @param {!Array<!proto.market_api.TechnicalData>} value
 * @return {!proto.market_api.FindTechnicalsResponse} returns this
*/
proto.market_api.FindTechnicalsResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.TechnicalData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TechnicalData}
 */
proto.market_api.FindTechnicalsResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.TechnicalData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.FindTechnicalsResponse} returns this
 */
proto.market_api.FindTechnicalsResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TechnicalInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TechnicalInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TechnicalInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TechnicalInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    indid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    indname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timeinterval: jspb.Message.getFieldWithDefault(msg, 4, 0),
    resolution: jspb.Message.getFieldWithDefault(msg, 5, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    action: jspb.Message.getFieldWithDefault(msg, 7, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TechnicalInfo}
 */
proto.market_api.TechnicalInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TechnicalInfo;
  return proto.market_api.TechnicalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TechnicalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TechnicalInfo}
 */
proto.market_api.TechnicalInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeinterval(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolution(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 7:
      var value = /** @type {!proto.market_api.TechnicalInfo.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 8:
      var value = /** @type {!proto.market_api.TechnicalInfo.Group} */ (reader.readEnum());
      msg.setGroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TechnicalInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TechnicalInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TechnicalInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TechnicalInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimeinterval();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getResolution();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.market_api.TechnicalInfo.Group = {
  UNKNOWN_GROUP: 0,
  OSCILLATOR_GROUP: 1,
  MOVING_AVERAGE_GROUP: 2
};

/**
 * @enum {number}
 */
proto.market_api.TechnicalInfo.Action = {
  UNKNOWN_ACTION: 0,
  SELL_ACTION: 1,
  BUY_ACTION: 2,
  NEUTRAL_ACTION: 3
};

/**
 * optional int32 indId = 1;
 * @return {number}
 */
proto.market_api.TechnicalInfo.prototype.getIndid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setIndid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.market_api.TechnicalInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string indName = 3;
 * @return {string}
 */
proto.market_api.TechnicalInfo.prototype.getIndname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setIndname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 timeInterval = 4;
 * @return {number}
 */
proto.market_api.TechnicalInfo.prototype.getTimeinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setTimeinterval = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string resolution = 5;
 * @return {string}
 */
proto.market_api.TechnicalInfo.prototype.getResolution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setResolution = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double value = 6;
 * @return {number}
 */
proto.market_api.TechnicalInfo.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional Action action = 7;
 * @return {!proto.market_api.TechnicalInfo.Action}
 */
proto.market_api.TechnicalInfo.prototype.getAction = function() {
  return /** @type {!proto.market_api.TechnicalInfo.Action} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.market_api.TechnicalInfo.Action} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Group groupId = 8;
 * @return {!proto.market_api.TechnicalInfo.Group}
 */
proto.market_api.TechnicalInfo.prototype.getGroupid = function() {
  return /** @type {!proto.market_api.TechnicalInfo.Group} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.market_api.TechnicalInfo.Group} value
 * @return {!proto.market_api.TechnicalInfo} returns this
 */
proto.market_api.TechnicalInfo.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.TechnicalData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.TechnicalData.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.TechnicalData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.TechnicalData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TechnicalData.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.TechnicalInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.TechnicalData}
 */
proto.market_api.TechnicalData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.TechnicalData;
  return proto.market_api.TechnicalData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.TechnicalData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.TechnicalData}
 */
proto.market_api.TechnicalData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = new proto.market_api.TechnicalInfo;
      reader.readMessage(value,proto.market_api.TechnicalInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.TechnicalData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.TechnicalData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.TechnicalData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.TechnicalData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.market_api.TechnicalInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.TechnicalData.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.TechnicalData} returns this
 */
proto.market_api.TechnicalData.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated TechnicalInfo data = 2;
 * @return {!Array<!proto.market_api.TechnicalInfo>}
 */
proto.market_api.TechnicalData.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.TechnicalInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.TechnicalInfo, 2));
};


/**
 * @param {!Array<!proto.market_api.TechnicalInfo>} value
 * @return {!proto.market_api.TechnicalData} returns this
*/
proto.market_api.TechnicalData.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.market_api.TechnicalInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.TechnicalInfo}
 */
proto.market_api.TechnicalData.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.market_api.TechnicalInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.TechnicalData} returns this
 */
proto.market_api.TechnicalData.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectFinanceItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectFinanceItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectFinanceItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    quarter: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    value1: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    value2: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    value3: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    yoy: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    remarks: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectFinanceItem;
  return proto.market_api.SectFinanceItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectFinanceItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuarter(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue1(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue2(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue3(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setYoy(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectFinanceItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectFinanceItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectFinanceItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getQuarter();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getValue1();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getValue2();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getValue3();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getYoy();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SectFinanceItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 quarter = 4;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getQuarter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setQuarter = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 type = 5;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double value1 = 6;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getValue1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setValue1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double value2 = 7;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getValue2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setValue2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double value3 = 8;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getValue3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setValue3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double yoy = 9;
 * @return {number}
 */
proto.market_api.SectFinanceItem.prototype.getYoy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setYoy = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string remarks = 10;
 * @return {string}
 */
proto.market_api.SectFinanceItem.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectFinanceItem} returns this
 */
proto.market_api.SectFinanceItem.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SectFinanceData.repeatedFields_ = [1,2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectFinanceData.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectFinanceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectFinanceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    listroeList: jspb.Message.toObjectList(msg.getListroeList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listroaList: jspb.Message.toObjectList(msg.getListroaList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listepsList: jspb.Message.toObjectList(msg.getListepsList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listprofitList: jspb.Message.toObjectList(msg.getListprofitList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listturnoverList: jspb.Message.toObjectList(msg.getListturnoverList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listbusinessprofitList: jspb.Message.toObjectList(msg.getListbusinessprofitList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listbalancesheetList: jspb.Message.toObjectList(msg.getListbalancesheetList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listcashflowList: jspb.Message.toObjectList(msg.getListcashflowList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listinvestList: jspb.Message.toObjectList(msg.getListinvestList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance),
    listfinanceList: jspb.Message.toObjectList(msg.getListfinanceList(),
    proto.market_api.SectFinanceItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectFinanceData}
 */
proto.market_api.SectFinanceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectFinanceData;
  return proto.market_api.SectFinanceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectFinanceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectFinanceData}
 */
proto.market_api.SectFinanceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListroe(value);
      break;
    case 2:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListroa(value);
      break;
    case 3:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListeps(value);
      break;
    case 4:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListprofit(value);
      break;
    case 5:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListturnover(value);
      break;
    case 6:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListbusinessprofit(value);
      break;
    case 7:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListbalancesheet(value);
      break;
    case 8:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListcashflow(value);
      break;
    case 9:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListinvest(value);
      break;
    case 10:
      var value = new proto.market_api.SectFinanceItem;
      reader.readMessage(value,proto.market_api.SectFinanceItem.deserializeBinaryFromReader);
      msg.addListfinance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectFinanceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectFinanceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectFinanceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListroeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListroaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListprofitList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListturnoverList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListbusinessprofitList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListbalancesheetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListcashflowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListinvestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
  f = message.getListfinanceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.market_api.SectFinanceItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SectFinanceItem listRoe = 1;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListroeList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 1));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListroeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListroe = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListroeList = function() {
  return this.setListroeList([]);
};


/**
 * repeated SectFinanceItem listRoa = 2;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListroaList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 2));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListroaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListroa = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListroaList = function() {
  return this.setListroaList([]);
};


/**
 * repeated SectFinanceItem listEps = 3;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListepsList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 3));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListeps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListepsList = function() {
  return this.setListepsList([]);
};


/**
 * repeated SectFinanceItem listProfit = 4;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListprofitList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 4));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListprofitList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListprofit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListprofitList = function() {
  return this.setListprofitList([]);
};


/**
 * repeated SectFinanceItem listTurnover = 5;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListturnoverList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 5));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListturnoverList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListturnover = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListturnoverList = function() {
  return this.setListturnoverList([]);
};


/**
 * repeated SectFinanceItem listBusinessProfit = 6;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListbusinessprofitList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 6));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListbusinessprofitList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListbusinessprofit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListbusinessprofitList = function() {
  return this.setListbusinessprofitList([]);
};


/**
 * repeated SectFinanceItem listBalanceSheet = 7;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListbalancesheetList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 7));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListbalancesheetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListbalancesheet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListbalancesheetList = function() {
  return this.setListbalancesheetList([]);
};


/**
 * repeated SectFinanceItem listCashFlow = 8;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListcashflowList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 8));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListcashflowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListcashflow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListcashflowList = function() {
  return this.setListcashflowList([]);
};


/**
 * repeated SectFinanceItem listInvest = 9;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListinvestList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 9));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListinvestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListinvest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListinvestList = function() {
  return this.setListinvestList([]);
};


/**
 * repeated SectFinanceItem listFinance = 10;
 * @return {!Array<!proto.market_api.SectFinanceItem>}
 */
proto.market_api.SectFinanceData.prototype.getListfinanceList = function() {
  return /** @type{!Array<!proto.market_api.SectFinanceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectFinanceItem, 10));
};


/**
 * @param {!Array<!proto.market_api.SectFinanceItem>} value
 * @return {!proto.market_api.SectFinanceData} returns this
*/
proto.market_api.SectFinanceData.prototype.setListfinanceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.market_api.SectFinanceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectFinanceItem}
 */
proto.market_api.SectFinanceData.prototype.addListfinance = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.market_api.SectFinanceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectFinanceData} returns this
 */
proto.market_api.SectFinanceData.prototype.clearListfinanceList = function() {
  return this.setListfinanceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectFinanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectFinanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectFinanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    data: (f = msg.getData()) && proto.market_api.SectFinanceData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectFinanceResponse}
 */
proto.market_api.SectFinanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectFinanceResponse;
  return proto.market_api.SectFinanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectFinanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectFinanceResponse}
 */
proto.market_api.SectFinanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SectFinanceData;
      reader.readMessage(value,proto.market_api.SectFinanceData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectFinanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectFinanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectFinanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.SectFinanceData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.SectFinanceResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceResponse} returns this
 */
proto.market_api.SectFinanceResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SectFinanceResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectFinanceResponse} returns this
 */
proto.market_api.SectFinanceResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SectFinanceResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectFinanceResponse} returns this
 */
proto.market_api.SectFinanceResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SectFinanceData data = 4;
 * @return {?proto.market_api.SectFinanceData}
 */
proto.market_api.SectFinanceResponse.prototype.getData = function() {
  return /** @type{?proto.market_api.SectFinanceData} */ (
    jspb.Message.getWrapperField(this, proto.market_api.SectFinanceData, 4));
};


/**
 * @param {?proto.market_api.SectFinanceData|undefined} value
 * @return {!proto.market_api.SectFinanceResponse} returns this
*/
proto.market_api.SectFinanceResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SectFinanceResponse} returns this
 */
proto.market_api.SectFinanceResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SectFinanceResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectFinanceParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectFinanceParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectFinanceParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectFinanceParam}
 */
proto.market_api.SectFinanceParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectFinanceParam;
  return proto.market_api.SectFinanceParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectFinanceParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectFinanceParam}
 */
proto.market_api.SectFinanceParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectFinanceParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectFinanceParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectFinanceParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectFinanceParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SectFinanceParam.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectFinanceParam} returns this
 */
proto.market_api.SectFinanceParam.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 duration = 2;
 * @return {number}
 */
proto.market_api.SectFinanceParam.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectFinanceParam} returns this
 */
proto.market_api.SectFinanceParam.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string authenCode = 3;
 * @return {string}
 */
proto.market_api.SectFinanceParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectFinanceParam} returns this
 */
proto.market_api.SectFinanceParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectNewsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectNewsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectNewsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicationdate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shortcontent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectNewsItem}
 */
proto.market_api.SectNewsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectNewsItem;
  return proto.market_api.SectNewsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectNewsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectNewsItem}
 */
proto.market_api.SectNewsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPublicationdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortcontent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectNewsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectNewsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectNewsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicationdate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShortcontent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.SectNewsItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SectNewsItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 publicationDate = 3;
 * @return {number}
 */
proto.market_api.SectNewsItem.prototype.getPublicationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setPublicationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.market_api.SectNewsItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail = 5;
 * @return {string}
 */
proto.market_api.SectNewsItem.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string shortContent = 6;
 * @return {string}
 */
proto.market_api.SectNewsItem.prototype.getShortcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setShortcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string remarks = 7;
 * @return {string}
 */
proto.market_api.SectNewsItem.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsItem} returns this
 */
proto.market_api.SectNewsItem.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SectNewsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectNewsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectNewsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectNewsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.SectNewsItem.toObject, includeInstance),
    totalrecords: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectNewsResponse}
 */
proto.market_api.SectNewsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectNewsResponse;
  return proto.market_api.SectNewsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectNewsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectNewsResponse}
 */
proto.market_api.SectNewsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SectNewsItem;
      reader.readMessage(value,proto.market_api.SectNewsItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalrecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectNewsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectNewsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectNewsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.SectNewsItem.serializeBinaryToWriter
    );
  }
  f = message.getTotalrecords();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.SectNewsResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsResponse} returns this
 */
proto.market_api.SectNewsResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SectNewsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsResponse} returns this
 */
proto.market_api.SectNewsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SectNewsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsResponse} returns this
 */
proto.market_api.SectNewsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SectNewsItem data = 4;
 * @return {!Array<!proto.market_api.SectNewsItem>}
 */
proto.market_api.SectNewsResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.SectNewsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectNewsItem, 4));
};


/**
 * @param {!Array<!proto.market_api.SectNewsItem>} value
 * @return {!proto.market_api.SectNewsResponse} returns this
*/
proto.market_api.SectNewsResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.SectNewsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectNewsItem}
 */
proto.market_api.SectNewsResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.SectNewsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectNewsResponse} returns this
 */
proto.market_api.SectNewsResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int32 totalRecords = 5;
 * @return {number}
 */
proto.market_api.SectNewsResponse.prototype.getTotalrecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsResponse} returns this
 */
proto.market_api.SectNewsResponse.prototype.setTotalrecords = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectNewsParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectNewsParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectNewsParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectNewsParam}
 */
proto.market_api.SectNewsParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectNewsParam;
  return proto.market_api.SectNewsParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectNewsParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectNewsParam}
 */
proto.market_api.SectNewsParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectNewsParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectNewsParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectNewsParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SectNewsParam.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsParam} returns this
 */
proto.market_api.SectNewsParam.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.market_api.SectNewsParam.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsParam} returns this
 */
proto.market_api.SectNewsParam.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pageSize = 3;
 * @return {number}
 */
proto.market_api.SectNewsParam.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsParam} returns this
 */
proto.market_api.SectNewsParam.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string authenCode = 4;
 * @return {string}
 */
proto.market_api.SectNewsParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsParam} returns this
 */
proto.market_api.SectNewsParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectNewsDetailItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectNewsDetailItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectNewsDetailItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsDetailItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicationdate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shortcontent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    content: jspb.Message.getFieldWithDefault(msg, 7, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectNewsDetailItem}
 */
proto.market_api.SectNewsDetailItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectNewsDetailItem;
  return proto.market_api.SectNewsDetailItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectNewsDetailItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectNewsDetailItem}
 */
proto.market_api.SectNewsDetailItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPublicationdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortcontent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectNewsDetailItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectNewsDetailItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectNewsDetailItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsDetailItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicationdate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShortcontent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.SectNewsDetailItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SectNewsDetailItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 publicationDate = 3;
 * @return {number}
 */
proto.market_api.SectNewsDetailItem.prototype.getPublicationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setPublicationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.market_api.SectNewsDetailItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail = 5;
 * @return {string}
 */
proto.market_api.SectNewsDetailItem.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string shortContent = 6;
 * @return {string}
 */
proto.market_api.SectNewsDetailItem.prototype.getShortcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setShortcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content = 7;
 * @return {string}
 */
proto.market_api.SectNewsDetailItem.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string remarks = 8;
 * @return {string}
 */
proto.market_api.SectNewsDetailItem.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailItem} returns this
 */
proto.market_api.SectNewsDetailItem.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectNewsDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectNewsDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectNewsDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    data: (f = msg.getData()) && proto.market_api.SectNewsDetailItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectNewsDetailResponse}
 */
proto.market_api.SectNewsDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectNewsDetailResponse;
  return proto.market_api.SectNewsDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectNewsDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectNewsDetailResponse}
 */
proto.market_api.SectNewsDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SectNewsDetailItem;
      reader.readMessage(value,proto.market_api.SectNewsDetailItem.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectNewsDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectNewsDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectNewsDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.market_api.SectNewsDetailItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.SectNewsDetailResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsDetailResponse} returns this
 */
proto.market_api.SectNewsDetailResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SectNewsDetailResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailResponse} returns this
 */
proto.market_api.SectNewsDetailResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SectNewsDetailResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailResponse} returns this
 */
proto.market_api.SectNewsDetailResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SectNewsDetailItem data = 4;
 * @return {?proto.market_api.SectNewsDetailItem}
 */
proto.market_api.SectNewsDetailResponse.prototype.getData = function() {
  return /** @type{?proto.market_api.SectNewsDetailItem} */ (
    jspb.Message.getWrapperField(this, proto.market_api.SectNewsDetailItem, 4));
};


/**
 * @param {?proto.market_api.SectNewsDetailItem|undefined} value
 * @return {!proto.market_api.SectNewsDetailResponse} returns this
*/
proto.market_api.SectNewsDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market_api.SectNewsDetailResponse} returns this
 */
proto.market_api.SectNewsDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market_api.SectNewsDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectNewsDetailParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectNewsDetailParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectNewsDetailParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsDetailParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    authencode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectNewsDetailParam}
 */
proto.market_api.SectNewsDetailParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectNewsDetailParam;
  return proto.market_api.SectNewsDetailParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectNewsDetailParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectNewsDetailParam}
 */
proto.market_api.SectNewsDetailParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectNewsDetailParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectNewsDetailParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectNewsDetailParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectNewsDetailParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.SectNewsDetailParam.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectNewsDetailParam} returns this
 */
proto.market_api.SectNewsDetailParam.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string authenCode = 2;
 * @return {string}
 */
proto.market_api.SectNewsDetailParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectNewsDetailParam} returns this
 */
proto.market_api.SectNewsDetailParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectOwnershipItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectOwnershipItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectOwnershipItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectOwnershipItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    domesticpersonpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    domesticorganizationpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    foreignpersonpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    foreignorganizationpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    remarks: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectOwnershipItem}
 */
proto.market_api.SectOwnershipItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectOwnershipItem;
  return proto.market_api.SectOwnershipItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectOwnershipItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectOwnershipItem}
 */
proto.market_api.SectOwnershipItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomesticpersonpercentage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomesticorganizationpercentage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForeignpersonpercentage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForeignorganizationpercentage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectOwnershipItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectOwnershipItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectOwnershipItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectOwnershipItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDomesticpersonpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDomesticorganizationpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getForeignpersonpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getForeignorganizationpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.SectOwnershipItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SectOwnershipItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double domesticPersonPercentage = 3;
 * @return {number}
 */
proto.market_api.SectOwnershipItem.prototype.getDomesticpersonpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setDomesticpersonpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double domesticOrganizationPercentage = 4;
 * @return {number}
 */
proto.market_api.SectOwnershipItem.prototype.getDomesticorganizationpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setDomesticorganizationpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double foreignPersonPercentage = 5;
 * @return {number}
 */
proto.market_api.SectOwnershipItem.prototype.getForeignpersonpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setForeignpersonpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double foreignOrganizationPercentage = 6;
 * @return {number}
 */
proto.market_api.SectOwnershipItem.prototype.getForeignorganizationpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setForeignorganizationpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string remarks = 7;
 * @return {string}
 */
proto.market_api.SectOwnershipItem.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectOwnershipItem} returns this
 */
proto.market_api.SectOwnershipItem.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SectOwnershipResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectOwnershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectOwnershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectOwnershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectOwnershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.SectOwnershipItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectOwnershipResponse}
 */
proto.market_api.SectOwnershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectOwnershipResponse;
  return proto.market_api.SectOwnershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectOwnershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectOwnershipResponse}
 */
proto.market_api.SectOwnershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SectOwnershipItem;
      reader.readMessage(value,proto.market_api.SectOwnershipItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectOwnershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectOwnershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectOwnershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectOwnershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.SectOwnershipItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.SectOwnershipResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectOwnershipResponse} returns this
 */
proto.market_api.SectOwnershipResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SectOwnershipResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectOwnershipResponse} returns this
 */
proto.market_api.SectOwnershipResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SectOwnershipResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectOwnershipResponse} returns this
 */
proto.market_api.SectOwnershipResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SectOwnershipItem data = 4;
 * @return {!Array<!proto.market_api.SectOwnershipItem>}
 */
proto.market_api.SectOwnershipResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.SectOwnershipItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectOwnershipItem, 4));
};


/**
 * @param {!Array<!proto.market_api.SectOwnershipItem>} value
 * @return {!proto.market_api.SectOwnershipResponse} returns this
*/
proto.market_api.SectOwnershipResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.SectOwnershipItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectOwnershipItem}
 */
proto.market_api.SectOwnershipResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.SectOwnershipItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectOwnershipResponse} returns this
 */
proto.market_api.SectOwnershipResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectOwnershipParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectOwnershipParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectOwnershipParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectOwnershipParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectOwnershipParam}
 */
proto.market_api.SectOwnershipParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectOwnershipParam;
  return proto.market_api.SectOwnershipParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectOwnershipParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectOwnershipParam}
 */
proto.market_api.SectOwnershipParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectOwnershipParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectOwnershipParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectOwnershipParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectOwnershipParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SectOwnershipParam.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectOwnershipParam} returns this
 */
proto.market_api.SectOwnershipParam.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authenCode = 2;
 * @return {string}
 */
proto.market_api.SectOwnershipParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectOwnershipParam} returns this
 */
proto.market_api.SectOwnershipParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectProfileItem.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectProfileItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectProfileItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectProfileItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seccd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shareholdername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shareholdingpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    remarks: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectProfileItem}
 */
proto.market_api.SectProfileItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectProfileItem;
  return proto.market_api.SectProfileItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectProfileItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectProfileItem}
 */
proto.market_api.SectProfileItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShareholdername(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setShareholdingpercentage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectProfileItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectProfileItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectProfileItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectProfileItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShareholdername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShareholdingpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.market_api.SectProfileItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectProfileItem} returns this
 */
proto.market_api.SectProfileItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string secCd = 2;
 * @return {string}
 */
proto.market_api.SectProfileItem.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileItem} returns this
 */
proto.market_api.SectProfileItem.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shareholderName = 3;
 * @return {string}
 */
proto.market_api.SectProfileItem.prototype.getShareholdername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileItem} returns this
 */
proto.market_api.SectProfileItem.prototype.setShareholdername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double shareholdingPercentage = 4;
 * @return {number}
 */
proto.market_api.SectProfileItem.prototype.getShareholdingpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectProfileItem} returns this
 */
proto.market_api.SectProfileItem.prototype.setShareholdingpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string remarks = 7;
 * @return {string}
 */
proto.market_api.SectProfileItem.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileItem} returns this
 */
proto.market_api.SectProfileItem.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market_api.SectProfileResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.market_api.SectProfileItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectProfileResponse}
 */
proto.market_api.SectProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectProfileResponse;
  return proto.market_api.SectProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectProfileResponse}
 */
proto.market_api.SectProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.market_api.SectProfileItem;
      reader.readMessage(value,proto.market_api.SectProfileItem.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.market_api.SectProfileItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 result = 1;
 * @return {number}
 */
proto.market_api.SectProfileResponse.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.SectProfileResponse} returns this
 */
proto.market_api.SectProfileResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.market_api.SectProfileResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileResponse} returns this
 */
proto.market_api.SectProfileResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.market_api.SectProfileResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileResponse} returns this
 */
proto.market_api.SectProfileResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SectProfileItem data = 4;
 * @return {!Array<!proto.market_api.SectProfileItem>}
 */
proto.market_api.SectProfileResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.market_api.SectProfileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.market_api.SectProfileItem, 4));
};


/**
 * @param {!Array<!proto.market_api.SectProfileItem>} value
 * @return {!proto.market_api.SectProfileResponse} returns this
*/
proto.market_api.SectProfileResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.market_api.SectProfileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market_api.SectProfileItem}
 */
proto.market_api.SectProfileResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.market_api.SectProfileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market_api.SectProfileResponse} returns this
 */
proto.market_api.SectProfileResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.SectProfileParam.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.SectProfileParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.SectProfileParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectProfileParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authencode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.SectProfileParam}
 */
proto.market_api.SectProfileParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.SectProfileParam;
  return proto.market_api.SectProfileParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.SectProfileParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.SectProfileParam}
 */
proto.market_api.SectProfileParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthencode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.SectProfileParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.SectProfileParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.SectProfileParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.SectProfileParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthencode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.SectProfileParam.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileParam} returns this
 */
proto.market_api.SectProfileParam.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authenCode = 2;
 * @return {string}
 */
proto.market_api.SectProfileParam.prototype.getAuthencode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.SectProfileParam} returns this
 */
proto.market_api.SectProfileParam.prototype.setAuthencode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.MarketCeilingFloor.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.MarketCeilingFloor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.MarketCeilingFloor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketCeilingFloor.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradedate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0),
    indexcd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ceiling: jspb.Message.getFieldWithDefault(msg, 4, 0),
    floor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.MarketCeilingFloor}
 */
proto.market_api.MarketCeilingFloor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.MarketCeilingFloor;
  return proto.market_api.MarketCeilingFloor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.MarketCeilingFloor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.MarketCeilingFloor}
 */
proto.market_api.MarketCeilingFloor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexcd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCeiling(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.MarketCeilingFloor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.MarketCeilingFloor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.MarketCeilingFloor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.MarketCeilingFloor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIndexcd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCeiling();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 tradeDate = 1;
 * @return {number}
 */
proto.market_api.MarketCeilingFloor.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketCeilingFloor} returns this
 */
proto.market_api.MarketCeilingFloor.prototype.setTradedate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 time = 2;
 * @return {number}
 */
proto.market_api.MarketCeilingFloor.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketCeilingFloor} returns this
 */
proto.market_api.MarketCeilingFloor.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 indexCd = 3;
 * @return {number}
 */
proto.market_api.MarketCeilingFloor.prototype.getIndexcd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketCeilingFloor} returns this
 */
proto.market_api.MarketCeilingFloor.prototype.setIndexcd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 ceiling = 4;
 * @return {number}
 */
proto.market_api.MarketCeilingFloor.prototype.getCeiling = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketCeilingFloor} returns this
 */
proto.market_api.MarketCeilingFloor.prototype.setCeiling = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 floor = 5;
 * @return {number}
 */
proto.market_api.MarketCeilingFloor.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.market_api.MarketCeilingFloor} returns this
 */
proto.market_api.MarketCeilingFloor.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market_api.DerivativeStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.market_api.DerivativeStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market_api.DerivativeStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.DerivativeStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    seccd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market_api.DerivativeStatus}
 */
proto.market_api.DerivativeStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market_api.DerivativeStatus;
  return proto.market_api.DerivativeStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market_api.DerivativeStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market_api.DerivativeStatus}
 */
proto.market_api.DerivativeStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeccd(value);
      break;
    case 2:
      var value = /** @type {!proto.market_api.INDEX_STATE} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market_api.DerivativeStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market_api.DerivativeStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market_api.DerivativeStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market_api.DerivativeStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeccd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string secCd = 1;
 * @return {string}
 */
proto.market_api.DerivativeStatus.prototype.getSeccd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market_api.DerivativeStatus} returns this
 */
proto.market_api.DerivativeStatus.prototype.setSeccd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional INDEX_STATE state = 2;
 * @return {!proto.market_api.INDEX_STATE}
 */
proto.market_api.DerivativeStatus.prototype.getState = function() {
  return /** @type {!proto.market_api.INDEX_STATE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.market_api.INDEX_STATE} value
 * @return {!proto.market_api.DerivativeStatus} returns this
 */
proto.market_api.DerivativeStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.market_api.ORDER_TYPE = {
  ORDER_DEFAULT: 0,
  ORDER_UP: 1,
  ORDER_DOWN: 2
};

/**
 * @enum {number}
 */
proto.market_api.STOCK_COLOR_CODE = {
  BASIC_PRICE: 0,
  CEILING_PRICE: 1,
  FLOOR_PRICE: 2,
  UP_PRICE: 3,
  DOWN_PRICE: 4,
  NO_TRADE_PRICE: 5
};

/**
 * @enum {number}
 */
proto.market_api.INDEX_COLOR_CODE = {
  NONE: 0,
  OPEN_INDEX: 1,
  UP_INDEX: 2,
  DOWN_INDEX: 3
};

/**
 * @enum {number}
 */
proto.market_api.INDEX_STATE = {
  S: 0,
  P: 1,
  O: 2,
  A: 3,
  C: 4,
  I: 5,
  K: 6,
  G: 7,
  J: 8,
  Z: 9
};

goog.object.extend(exports, proto.market_api);
