import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISearchBondOrderParams,
  IGetBondListParams,
  IEnterBondOrderParams,
  IRejectBondOrderParams,
} from "core/http/apis/bid-ask-bond/types";
import { IFindCbotBondPriceEstParams } from "core/http/apis/bid-ask-bond-order/types";
import {
  BondOrder,
  ISearchBondOrderResponse,
  IGetBondListResponse,
  Bond,
  IEnterBondOrderResponse,
  IRejectBondOrderResponse,
} from "domain/models/BidAskBond";
import {
  CbotBondPriceEst,
  IFindCbotBondPriceEstResponse,
} from "domain/models/BidAskBondOrder";
import { logOutSuccess } from "modules/auth/redux";

import { cloneDeep } from "lodash";

interface IFilter {
  status: number;
  tradeType: number;
  secCdBond: string;
}

export interface ReduxData {
  filter: IFilter;
  list: BondOrder[];
  loading: boolean;
  activeTab: string;
  listBond: Bond[];

  isShowConfirmModal: boolean;
  itemDetail?: BondOrder;
  statusEnter: boolean;
  statusReject: boolean;
  listCbotBondPriceEst: CbotBondPriceEst[];
}

const initialState: ReduxData = {
  filter: {
    status: -1,
    tradeType: 0,
    secCdBond: "",
  },
  list: [],
  loading: false,
  activeTab: "BID_ASK_BOND",
  listBond: [],

  isShowConfirmModal: false,
  statusEnter: false,
  statusReject: false,
  listCbotBondPriceEst: [],
};

const bidAskBondSlice = createSlice({
  name: "bidAskBondSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    reset: (state) => {
      return cloneDeep(initialState);
    },
    searchBondOrderRequest: (
      state,
      action: PayloadAction<ISearchBondOrderParams>
    ) => {
      state.loading = true;
    },
    searchBondOrderSuccess: (
      state,
      action: PayloadAction<ISearchBondOrderResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.data ?? [];
    },
    searchBondOrderFailure: (state) => {
      state.loading = false;
    },

    getBondListRequest: (
      state,
      action: PayloadAction<IGetBondListParams>
    ) => {},
    getBondListSuccess: (
      state,
      action: PayloadAction<IGetBondListResponse>
    ) => {
      state.listBond = action.payload.data ?? [];
    },
    getBondListFailure: (state) => {},
    enterBondOrderRequest: (
      state,
      action: PayloadAction<IEnterBondOrderParams>
    ) => {
      state.statusEnter = false;
    },
    enterBondOrderSuccess: (
      state,
      action: PayloadAction<IEnterBondOrderResponse>
    ) => {
      state.statusEnter = true;
    },
    enterBondOrderFailure: (state) => {
      state.statusEnter = false;
    },

    rejectBondOrderRequest: (
      state,
      action: PayloadAction<IRejectBondOrderParams>
    ) => {
      state.statusReject = false;
    },
    rejectBondOrderSuccess: (
      state,
      action: PayloadAction<IRejectBondOrderResponse>
    ) => {
      state.statusReject = true;
    },
    rejectBondOrderFailure: (state) => {
      state.statusReject = false;
    },

    findCbotBondPriceEstRequest: (
      state,
      action: PayloadAction<IFindCbotBondPriceEstParams>
    ) => {},
    findCbotBondPriceEstSuccess: (
      state,
      action: PayloadAction<IFindCbotBondPriceEstResponse>
    ) => {
      state.listCbotBondPriceEst = action.payload.data ?? [];
    },
    findCbotBondPriceEstFailure: (state) => {},
    updateModalStatusRequest: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: BondOrder;
      }>
    ) => {},
    updateModalStatusSuccess: (
      state,
      action: PayloadAction<{
        isVisible: boolean;
        itemDetail?: BondOrder;
      }>
    ) => {
      state.itemDetail = action.payload.itemDetail && action.payload.itemDetail;
      state.isShowConfirmModal = action.payload.isVisible;
    },

    updateFilter: (
      state,
      action: PayloadAction<{ key: keyof IFilter; value: any }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchBondOrderFailure,
  searchBondOrderRequest,
  searchBondOrderSuccess,
  getBondListRequest,
  getBondListSuccess,
  getBondListFailure,
  updateModalStatusRequest,
  updateModalStatusSuccess,
  enterBondOrderRequest,
  enterBondOrderSuccess,
  enterBondOrderFailure,
  rejectBondOrderRequest,
  rejectBondOrderSuccess,
  rejectBondOrderFailure,
  updateFilter,
  findCbotBondPriceEstRequest,
  findCbotBondPriceEstSuccess,
  findCbotBondPriceEstFailure,
} = bidAskBondSlice.actions;

export default bidAskBondSlice.reducer;
