import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { convertNumberToDateFormat } from "helper/utils";
import { mrgContractDebitListSelector } from "modules/mrg-contract-debit/selectors";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  Container,
  HeaderCell,
  PlaceOrderLink,
  TableCellContent,
} from "./styles";

function Table() {
  const data = useSelector(mrgContractDebitListSelector);
  const flexEnd = "flex-end";
  const flexStart = "flex-start";
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="mrgContractDebit.table.contrDateFrom" />
          </HeaderCell>
        ),
        accessor: "contrDateFrom",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="mrgContractDebit.table.contrDateTo" />
          </HeaderCell>
        ),
        accessor: "contrDateTo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="mrgContractDebit.table.subAccoNo" />
          </HeaderCell>
        ),
        accessor: "subAccoNo",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="mrgContractDebit.table.custName" />
          </HeaderCell>
        ),
        accessor: "custName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="mrgContractDebit.table.debitTypeName" />
          </HeaderCell>
        ),
        accessor: "debitTypeName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.amount" />
          </HeaderCell>
        ),
        accessor: "amount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.payedAmount" />
          </HeaderCell>
        ),
        accessor: "payedAmount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.pendingPayment" />
          </HeaderCell>
        ),
        accessor: "pendingPayment",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.currentDebit" />
          </HeaderCell>
        ),
        accessor: "currentDebit",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.payedInterest" />
          </HeaderCell>
        ),
        accessor: "payedInterest",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.todayInterest" />
          </HeaderCell>
        ),
        accessor: "todayInterest",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.endDayInterest" />
          </HeaderCell>
        ),
        accessor: "endDayInterest",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.remainDays" />
          </HeaderCell>
        ),
        accessor: "remainDays",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.inRate" />
          </HeaderCell>
        ),
        accessor: "inRate",
        Cell: (props: any) => {
          const initConfigId = props?.row?.original?.initConfigId;
          const value = initConfigId
            ? null
            : numeral(props.value).format("0,0.00");
          return <TableCellContent align={flexEnd}>{value}</TableCellContent>;
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.outRate" />
          </HeaderCell>
        ),
        accessor: "outRate",
        Cell: (props: any) => {
          const initConfigId = props?.row?.original?.initConfigId;
          const value = initConfigId
            ? null
            : numeral(props.value).format("0,0.00");
          return <TableCellContent align={flexEnd}>{value}</TableCellContent>;
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.payedManager" />
          </HeaderCell>
        ),
        accessor: "payedManager",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.todayManager" />
          </HeaderCell>
        ),
        accessor: "todayManager",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.managerRate" />
          </HeaderCell>
        ),
        accessor: "managerRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.managerOutRate" />
          </HeaderCell>
        ),
        accessor: "managerOutRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="mrgContractDebit.table.initConfigId" />
          </HeaderCell>
        ),
        accessor: "initConfigId",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="mrgContractDebit.table.extendCount" />
          </HeaderCell>
        ),
        accessor: "extendCount",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: <HeaderCell align={flexStart}></HeaderCell>,
        accessor: "decimalPoint",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {
                <PlaceOrderLink>
                  <FormattedMessage id="mrgContractDebit.table.placeOrder" />
                </PlaceOrderLink>
              }
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="mrgContractDebit.table.brokerCd" />
          </HeaderCell>
        ),
        accessor: "brokerCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="mrgContractDebit.table.branchSName" />
          </HeaderCell>
        ),
        accessor: "branchSName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="mrgContractDebitTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data}
        enableInfinity={true}
        scrollableTarget={"mrgContractDebitTable"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
