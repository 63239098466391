import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradingViewSession } from "domain/models/TradingViewSession";
import { logOutSuccess } from "modules/auth/redux";

export type TradingViewState = {
  tradingViewSession: {
    session: string;
    expiredAt: string;
  };
  loading: boolean;
};

const initialState: TradingViewState = {
  tradingViewSession: {
    session: "",
    expiredAt: "",
  },
  loading: false,
};

const tradingViewSlice = createSlice({
  name: "tradingView",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    getTradingViewAccessSession: (state) => {},
    getTradingViewAccessSessionSuccess: (
      state,
      action: PayloadAction<TradingViewSession>
    ) => {
      state.tradingViewSession.session = action.payload.session;
      state.tradingViewSession.expiredAt = action.payload.expireAt;
    },
  },
});

export const {
  getTradingViewAccessSession,
  getTradingViewAccessSessionSuccess,
} = tradingViewSlice.actions;

export default tradingViewSlice.reducer;
