import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetMarginContractParams } from "core/http/apis/margin-contract/types";
import {
  IMarginContractResponse,
  IMarginContractResult,
} from "domain/models/MarginContract";
import { logOutSuccess } from "modules/auth/redux";

export interface ReduxData {
  filter: {
    tranCd: string;
    accNo: string;
    openDateFrom: Date;
    openDateTo: Date;
    broker: string;
    status: string;
    contractState: string;
  };
  listMarginContract: IMarginContractResponse[];
  loadingSearchData: boolean;
  activeTab: string;
  marginAccountInfo: IMarginContractResponse;
}

const initialState: ReduxData = {
  filter: {
    tranCd: "",
    accNo: "",
    openDateFrom: new Date(),
    openDateTo: new Date(),
    broker: "",
    status: "-1",
    contractState: "-1",
  },
  listMarginContract: [],
  loadingSearchData: false,
  activeTab: "MARGIN_CONTRACT",
  marginAccountInfo: {},
};

const marginContractSlice = createSlice({
  name: "marginContractSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    searchMarginContractRequest: (
      state,
      action: PayloadAction<IGetMarginContractParams>
    ) => {
      state.loadingSearchData = true;
    },
    searchMarginContractSuccess: (
      state,
      action: PayloadAction<IMarginContractResult>
    ) => {
      state.loadingSearchData = false;
      if (action.payload.data) {
        state.listMarginContract = action.payload.data;
        if (action.payload.data.length > 0) {
          state.marginAccountInfo = action.payload.data[0];
        }
      }
    },
    searchMarginContractFailure: (state) => {
      state.loadingSearchData = false;
    },
    updateFilter: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const {
  searchMarginContractRequest,
  searchMarginContractSuccess,
  searchMarginContractFailure,
  updateFilter,
} = marginContractSlice.actions;

export default marginContractSlice.reducer;
