import HttpClient from "../../index";
import { RESTFUL_WATCHLIST_URL } from "AppConfigNew";

export default class HttpWatchList extends HttpClient {
  private static classInstance?: HttpWatchList;
  private constructor() {
    super(RESTFUL_WATCHLIST_URL);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HttpWatchList();
    }

    return this.classInstance;
  }
}
