import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { TableHeaderWrapper, HeaderCell, HeaderRowWrapper } from "./styles";
import { RootState } from "redux/store";
import { ReactComponent as IconSortUp } from "assets/image/svg/ic_sort_up_2.svg";
import { ReactComponent as IconSortDown } from "assets/image/svg/ic_sort_down_2.svg";
import { ReactComponent as IconSortDefault } from "assets/image/svg/ic_sort_default_2.svg";
import classNames from "classnames";
import { SORT_BY, ORDER, FLEX } from "./constant";
import { createSelector } from "@reduxjs/toolkit";
import {
  WatchListState,
  changeActiveSort,
} from "modules/stock-order/redux/watchList";
import { useDispatch, useSelector } from "react-redux";

export interface IHeaderCellInfo {
  width?: number;
  name: string;
  position: string;
  maxWidthCell?: number;
  flexCell?: number;
  sortBy?: SORT_BY;
}

export interface Props {
  columns: IHeaderCellInfo[];
}

const watchListSelector = (state: RootState) => state.stockOrder.watchList;

const activeSortSelector = createSelector(
  watchListSelector,
  (watchListState: WatchListState) => watchListState.activeSort
);

const TableHeader: FC<Props> = ({ columns }) => {
  const dispatch = useDispatch();
  const activeSort = useSelector(activeSortSelector);

  const generateSortIcon = (sort: { sortBy: SORT_BY; order: ORDER }) => {
    let icon = <IconSortDefault />;
    if (sort.sortBy === activeSort?.sortBy) {
      if (sort.order === ORDER.DESC) {
        icon = <IconSortDown />;
      }
      if (sort.order === ORDER.ASC) {
        icon = <IconSortUp />;
      }
    }
    return <span>{icon}</span>;
  };

  const clickHeaderHandler = useCallback(
    (sortByCode?: SORT_BY) => {
      if (!sortByCode) return;
      // nếu chưa set sortBy hoặc sortBy trường khác
      if (!activeSort.sortBy || activeSort.sortBy !== sortByCode) {
        dispatch(changeActiveSort({ order: ORDER.DESC, sortBy: sortByCode }));
        return;
      }
      //nếu order = desc thì gán newOrder = asc và ngược lại
      let newOrder = ORDER.UNKNOW;
      let unsetCode = false;
      if (activeSort.order === ORDER.UNKNOW) {
        newOrder = ORDER.DESC;
      } else if (activeSort.order === ORDER.DESC) {
        newOrder = ORDER.ASC;
      } else {
        unsetCode = true;
      }
      dispatch(
        changeActiveSort({
          order: unsetCode ? ORDER.UNKNOW : newOrder,
          sortBy: unsetCode ? SORT_BY.UNKNOW : sortByCode,
        })
      );
    },
    [activeSort]
  );

  return (
    <TableHeaderWrapper>
      <HeaderRowWrapper>
        {columns.map((column) => (
          <HeaderCell
            align={FLEX[column.position as keyof typeof FLEX]}
            setMinWidth={column?.width}
            maxWidthCell={column?.maxWidthCell}
            flexCell={column?.flexCell}
            key={column.name}
            onClick={() => clickHeaderHandler(column?.sortBy)}
            canSort={!!column.sortBy}
            className={classNames({
              "ticker-cell": column?.name === "screeners.card.table.shareCode",
            })}
          >
            <div>
              <span>
                <FormattedMessage id={column?.name} />
              </span>
              {!!column.sortBy &&
                generateSortIcon({
                  sortBy: column.sortBy,
                  order: activeSort?.order,
                })}
            </div>
          </HeaderCell>
        ))}
      </HeaderRowWrapper>
    </TableHeaderWrapper>
  );
};

export default TableHeader;
