import PlaceOrder from "./generals/PlaceOrder";
import OrderBook from "./generals/OrderBook";
import PriceList from "modules/stock-order/components/StockOrderPriceList";
import VolumeAndTime from "modules/stock-order/components/StockOrderVolumeAndTime";
import WatchList from "./generals/WatchList";
import Portfolio from "./generals/Portfolio";
import { Widget } from "./types";
import StockOrderTickerChart from "modules/stock-order/components/StockOrderTickerChart";

const widgets: Widget[] = [
  {
    name: "widgets.stockOrder.name",
    component: PlaceOrder,
  },
  {
    name: "widgets.orderBookOfTheDay.name",
    component: OrderBook,
  },
  {
    name: "widgets.watchList.name",
    component: WatchList,
  },
  {
    name: "widgets.priceList.name",
    component: PriceList,
  },
  {
    name: "widgets.volumeAndTime.title",
    component: VolumeAndTime,
  },
  {
    name: "widgets.portfolio.name",
    component: Portfolio,
  },
  {
    name: "widgets.tickerGraph.name",
    component: StockOrderTickerChart,
  },
];

export default widgets;
