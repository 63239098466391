import React from "react";
import { Bar } from "react-chartjs-2";
import "./tooltip.css";
import cloneDeep from "lodash/cloneDeep";
import { ChartOptions } from "chart.js";
import { ChartData } from "modules/market/redux/marketWatch";

function MarketLineChart({
  dataChart,
  option,
}: {
  dataChart: ChartData;
  option: ChartOptions;
}) {
  return (
    <Bar
      data={{
        labels: dataChart.labels,
        datasets: cloneDeep(dataChart.datasets),
      }}
      options={option}
    />
  );
}

export default MarketLineChart;
