import CommonTableInfinity from "components/commons/CommonTableInfinity";

import {
  listDBondOrderSelector,
  filterDBondOrderSelector,
  statusDeleteSelector,
} from "modules/bid-ask-d-bond-order/selectors";
import { IGetDBondOrderParams } from "core/http/apis/bid-ask-d-bond-order/types";
import {
  getDBondOrderRequest,
  cancelDBondOrderRequest,
  updateFilter,
} from "modules/bid-ask-d-bond-order/redux";

import { memo, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Container, HeaderCell, TableCellContent, IconWrapper } from "./styles";
import numeral from "numeral";
import { convertNumberToDateFormat } from "helper/utils";
// import moment from "moment";
import { ReactComponent as IconTrash } from "assets/image/svg/ic_trash.svg";
import { useSelector, useDispatch } from "react-redux";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";

import { toast } from "components/commons/Toast";

import { DBondOrder } from "domain/models/BidAskDBondOrder";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";

function RequestTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const data = useSelector(listDBondOrderSelector);
  const statusDelete = useSelector(statusDeleteSelector);
  const { secCdDBond } = useSelector(filterDBondOrderSelector);
  const themeType = useSelector(themeTypeSelector);
  useEffect(() => {
    if (statusDelete) {
      const submitData: IGetDBondOrderParams = {
        status: -1,

        bondCd: secCdDBond ? secCdDBond : "",
        custCd: 0,
        custNo: null,
        fromDate: null,
        toDate: null,
        type: 1,
        tradeType: null,
        getDataType: 1,
      };
      dispatch(getDBondOrderRequest(submitData));
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
      toast("bidAskDBondOrder.message.deleteSuccess", "success");
    }
  }, [statusDelete]);
  const center = "center";
  const flexEnd = "flex-end";

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [bondOrderSelected, setBondOrderSelected] = useState<
    DBondOrder | undefined
  >(undefined);

  const clickDeleteHandler = (item: DBondOrder) => {
    setBondOrderSelected(item);
    setVisibleModalConfirm(true);
  };
  const setConfirm = () => {
    if (bondOrderSelected) {
      // console.log(bondOrderSelected, "bondOrderSelected");
      dispatch(
        cancelDBondOrderRequest({
          id: bondOrderSelected.id ?? 0,
          updDateTime: bondOrderSelected.timeStamp ?? "",
        })
      );
    }
  };

  function changeColor(value: string, value2: number) {
    if (value2 === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].red50}>
          <FormattedMessage id="bidAskDBondOrder.table.sell" />
        </TableCellContent>
      );
    else if (value2 === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].green50}>
          <FormattedMessage id="bidAskDBondOrder.table.buy" />
        </TableCellContent>
      );

    return "";
  }
  function changeColor2(value: number, value2: string) {
    if (value === 0)
      return (
        <TableCellContent align="center" color={themes[themeType].solidYellow}>
          <FormattedMessage id="bidAskDBondOrder.status.wait_approve" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 1)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskDBondOrder.status.confirmed" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 2)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskDBondOrder.status.pending" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 3)
      return (
        <TableCellContent align="center" color={themes[themeType].solidGreen}>
          <FormattedMessage id="bidAskDBondOrder.status.complete" />
          {/* {value2} */}
        </TableCellContent>
      );
    else if (value === 9)
      return (
        <TableCellContent align="center" color={themes[themeType].solidRed}>
          <FormattedMessage id="bidAskDBondOrder.status.rejected" />
          {/* {value2} */}
        </TableCellContent>
      );

    return "";
  }
  const getDisplay = (status: number) => {
    switch (status) {
      case 0:
        return "unknown";
      case 1:
        return "sell";

      case 2:
        return "buy";
      case 3:
        return "sellMortage";
    }
  };
  // const getDisplay2 = (status: number) => {
  //   switch (status) {
  //     case 1:
  //       return "sell";

  //     case 2:
  //       return "buy";
  //   }
  // };
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.cancel" />
          </HeaderCell>
        ),
        minWidth: 50,
        accessor: "id",
        Cell: (props: any) => {
          const currentRow = props?.row?.original;
          return (
            <TableCellContent align={center}>
              {currentRow.status === 0 && (
                <IconWrapper onClick={() => clickDeleteHandler(currentRow)}>
                  <IconTrash />
                </IconWrapper>
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.tradingDate" />
          </HeaderCell>
        ),
        accessor: "tradeDate",
        minWidth: 80,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.bondCd" />
          </HeaderCell>
        ),
        accessor: "bondCd",
        minWidth: 120,
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.tradeType" />
          </HeaderCell>
        ),
        accessor: "tradeType",
        minWidth: 50,
        Cell: (props: any) => {
          // const row = props?.row.original;

          return (
            <TableCellContent align={center}>
              {changeColor(
                intl.formatMessage({
                  id:
                    "bidAskDBondOrder.table.traderList." +
                    getDisplay(+props.value ?? 0),
                }),
                props.value
              )}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.qty" />
          </HeaderCell>
        ),
        accessor: "qty",
        minWidth: 50,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>{props.value}</TableCellContent>
          );
        },
        // Footer: "",
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.amount" />
          </HeaderCell>
        ),
        accessor: "price",
        minWidth: 50,

        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.investValue" />
          </HeaderCell>
        ),
        accessor: "investValue",
        minWidth: 50,

        Cell: (props: any) => {
          const row = props?.row?.original;

          if (row && row.qty > 0 && row.price > 0) {
            const sum = row.qty * row.price;
            return (
              <TableCellContent align={flexEnd}>
                {numeral(sum ?? 0).format("0,0")}
              </TableCellContent>
            );
          } else {
            return <TableCellContent align={flexEnd}>0</TableCellContent>;
          }
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.fee" />
          </HeaderCell>
        ),
        accessor: "feeAmt",
        minWidth: 50,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="bidAskDBondOrder.table.tax" />
          </HeaderCell>
        ),
        accessor: "taxAmt",
        minWidth: 50,
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0")}
            </TableCellContent>
          );
        },
      },

      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="bidAskDBondOrder.table.status" />
          </HeaderCell>
        ),
        accessor: "status",
        minWidth: 80,
        Cell: (props: any) => {
          const row = props?.row.original;
          return (
            <TableCellContent align={center}>
              {changeColor2(props.value, row.statusDisp)}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );

  const fetchMoreData = () => {};

  return (
    <Container id="bidAskDBondOrder">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data ?? []}
        enableInfinity={true}
        scrollableTarget={"bidAskDBondOrder"}
        isHasFooter={false}
      />
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage(
          { id: "bidAskDBondOrder.message.deleteContent" },
          {
            number: bondOrderSelected?.id,
          }
        )}
      />
    </Container>
  );
}

export default memo(RequestTable);
