import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const contractDebitSelector = (state: RootState) => state.contractDebit;

export const loanTrackingListSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.list
);

export const loanTrackingLoadingSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.loading
);

export const loanTrackingFilterSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.filter
);

export const loanTrackingDetailSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.itemDetail
);
export const loanTrackingDetailRowSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.itemDetailRow
);
export const loanTrackingItemEditSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.itemEdit
);

export const loanTrackingLoanExtendSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.extendDays
);
export const loanTrackingExtendFeeSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.extendFee
);

export const loanTrackingAvailableBalanceSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.availableBalance
);
export const loanTrackingInfoMrgDebitSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.infoMrgDebit
);
export const loanTrackingStatusUpdateMrgContractPaymentSelector =
  createSelector(
    contractDebitSelector,
    (state) => state.loanTracking.statusUpdateMrgContractPayment
  );
export const loanTrackingStatusMarginDebitExtendDaysSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.statusMarginDebitExtendDays
);

export const paymentHistoryListSelector = createSelector(
  contractDebitSelector,
  (state) => state.paymentHistory.list
);

export const paymentHistoryLoadingSelector = createSelector(
  contractDebitSelector,
  (state) => state.paymentHistory.loading
);

export const paymentHistoryFilterSelector = createSelector(
  contractDebitSelector,
  (state) => state.paymentHistory.filter
);

export const extendHistoryListSelector = createSelector(
  contractDebitSelector,
  (state) => state.extendHistory.list
);

export const extendHistoryLoadingSelector = createSelector(
  contractDebitSelector,
  (state) => state.extendHistory.loading
);

export const extendHistoryFilterSelector = createSelector(
  contractDebitSelector,
  (state) => state.extendHistory.filter
);

export const activeTabSelector = createSelector(
  contractDebitSelector,
  (state) => state.activeTab
);

export const isShowPaymentModalSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.isShowPaymentModal
);
export const isShowLoanExtendModalSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.isShowLoanExtendModal
);
export const isShowConfirmModalSelector = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.isShowConfirmModal
);

export const currentPagePaymenHistory = createSelector(
  contractDebitSelector,
  (state) => state.paymentHistory.currentPage
);

export const enableInfinityPaymenHistory = createSelector(
  contractDebitSelector,
  (state) => state.paymentHistory.enableInfinity
);

export const selectLimitPage = createSelector(
  contractDebitSelector,
  (state) => state.limit
);
//
export const enableInfinityExtendHistory = createSelector(
  contractDebitSelector,
  (state) => state.extendHistory.enableInfinity
);

export const currentPageExtendHistory = createSelector(
  contractDebitSelector,
  (state) => state.extendHistory.currentPage
);
//
export const enableInfinityLoanTracking = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.enableInfinity
);

export const currentPageloanTracking = createSelector(
  contractDebitSelector,
  (state) => state.loanTracking.currentPage
);
