import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { TextOtp } from "../styles";

interface WidgetContentProps {
  otpType: number;
}

function OtpSelect({ otpType }: WidgetContentProps) {
  const intl = useIntl();

  const optionOtpList = [
    {
      value: "0",
      label: intl.formatMessage({ id: "auth.tradingAccount.unknown" }),
    },
    { value: "6", label: "Smart OTP" },
    { value: "3", label: "SMS OTP" },
  ];

  const otpOptions = useMemo(() => optionOtpList, []);

  return (
    <TextOtp>
      {otpOptions.find((item) => item.value === otpType.toString())?.label}
    </TextOtp>
  );
}

export default memo(OtpSelect);
