import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ForeignRoom,
  LastSale,
  SecQuotesDetailResponse,
} from "domain/protoNew/auto_trading_pb";
import { logOutSuccess } from "modules/auth/redux";

export type TickerInfoState = {
  loading: boolean;
  error: string | undefined;
  organizationFundamental?: SecQuotesDetailResponse.SecDetailInfo.AsObject;
};

const initialState: TickerInfoState = {
  loading: false,
  error: undefined,
  organizationFundamental: undefined,
};

const TickerInfoSlice = createSlice({
  name: "TickerInfoSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOutSuccess, (state) => {
      return initialState;
    });
  },
  reducers: {
    fetchOrganizationFundamental: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    fetchOrganizationFundamentalSuccess: (
      state,
      action: PayloadAction<
        SecQuotesDetailResponse.SecDetailInfo.AsObject | undefined
      >
    ) => {
      state.loading = false;
      state.organizationFundamental = action.payload;
    },
    fetchOrganizationFundamentalFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLastSale: (state, action: PayloadAction<LastSale.AsObject>) => {
      if (!state.organizationFundamental) return;
      if (state.organizationFundamental.seccd === action.payload.seccd) {
        state.organizationFundamental.lastprice = action.payload.lastprice;
        state.organizationFundamental.changepoint = action.payload.changepoint;
        state.organizationFundamental.changepercent =
          action.payload.changepercent;
        state.organizationFundamental.avgprice = action.payload.avgprice;
        state.organizationFundamental.lowestprice = action.payload.lowprice;
        state.organizationFundamental.highestprice = action.payload.hightprice;
        state.organizationFundamental.totalqty = action.payload.totalqty;
      }
      if (
        state.organizationFundamental?.baseseccd?.data === action.payload.seccd
      ) {
        state.organizationFundamental.baseprice = action.payload.lastprice;
      }
    },
    updateForeignRoom: (state, action: PayloadAction<ForeignRoom.AsObject>) => {
      if (!state.organizationFundamental) return;
      state.organizationFundamental.sellforeignqty =
        action.payload.sellforeignqty;
      state.organizationFundamental.buyforeignqty =
        action.payload.buyforeignqty;
      state.organizationFundamental.currentroom = action.payload.currentroom;
    },
  },
});

export const {
  fetchOrganizationFundamental,
  fetchOrganizationFundamentalSuccess,
  fetchOrganizationFundamentalFailure,
  updateLastSale,
  updateForeignRoom,
} = TickerInfoSlice.actions;

export default TickerInfoSlice.reducer;
