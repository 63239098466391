/* eslint-disable no-useless-escape */
import { memo, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import {
  Wrapper,
  HeaderModal,
  ModalTitle,
  ModalCloseButton,
  Icon,
  BodyModal,
  BodyModalRow,
  LabelCell,
  WrapInput,
  ActionsRow,
  ButtonCell,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Button } from "components/commons";
import { userChangePasswordApi, UserChangePinCDApi } from "core/http/apis/auth";
import { TypeModal } from "./constants";
import { toast } from "components/commons/Toast";
import { GetParameters } from "helper/sessionData";

interface PROPS {
  isVisible: boolean;
  onClose: () => void;
  typeSetting?: string;
}

function ModalSetting(props: PROPS) {
  const intl = useIntl();
  const { isVisible, onClose, typeSetting } = props;
  const minPass = GetParameters("SYST", "SYSTPASS_MIN");
  const alphaPass = GetParameters("SYST", "SYSTPASS_ALPHA_REQ");
  const reqPass = GetParameters("SYST", "SYSTPASS_CHAR_REQ");

  const [form, setForm] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changeForm = (value: any, type: string) => {
    setForm({
      ...form,
      [type]: value,
    });
  };

  const [error, setError] = useState<string | undefined>("");

  const checkError = () => {
    const value = Object.keys(form).find(
      (key) => form[key] === "" || form[key] === null
    );
    if (value) {
      setError(value);
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const submitForm = async () => {
    if (validateNewPass !== "") return;
    if (checkError()) {
      if (
        form.newPassword === form.confirmPassword &&
        form.newPassword !== ""
      ) {
        if (typeSetting === TypeModal.LOGIN) {
          const res: any = await userChangePasswordApi(form);
          if (res?.statusCode === 0) {
            toast("auth.setting.settingPass.modal.messageSuccess", "success");
            onClose();
          } else {
            toast(res?.errorCode, "error", res?.message, true);
          }
        } else {
          const res: any = await UserChangePinCDApi({
            currentPinCd: form.currentPassword,
            newPassword: form.newPassword,
            confirmPassword: form.confirmPassword,
          });
          if (res?.statusCode === 0) {
            toast("auth.setting.settingPass.modal.messageSuccess", "success");
            onClose();
          } else {
            toast(res?.errorCode, "error", res?.message, true);
          }
        }
      } else {
        toast("auth.setting.settingPass.modal.messErrorConfirmPass", "error");
      }
    }
  };

  const [validateNewPass, setvalidateNewPass] = useState<string>("");
  const checkValidate = (value: string) => {
    if (value.length < Number(minPass)) {
      return setvalidateNewPass("Min");
    }
    if (reqPass === "true") {
      const format = /[!?@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (!format.test(value)) {
        return setvalidateNewPass("Alpha");
      }
    }

    if (alphaPass === "true") {
      const x = value.match(/[A-Z]/g);
      if (!x) {
        return setvalidateNewPass("Char");
      }
    }
    return setvalidateNewPass("");
  };

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={onClose}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage
              id={
                typeSetting === TypeModal.LOGIN
                  ? "auth.setting.settingPass.modal.title"
                  : "auth.setting.settingPass.modal.titlePassTransaction"
              }
            />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={onClose}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <div className="d-flex">
                <FormattedMessage id="auth.setting.settingPass.modal.passNow" />
                <p className="text-danger pl-1 mb-0">*</p>
              </div>
            </LabelCell>
            <WrapInput>
              <Input
                placeholder={intl.formatMessage({
                  id: "auth.setting.settingPass.modal.placeholderPassNow",
                })}
                type="password"
                onChange={(e: any) =>
                  changeForm(e?.target?.value, "currentPassword")
                }
                value={form.currentPassword}
                autoComplete="off"
              />
              {error === "currentPassword" && (
                <span className="text-error text-danger">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                  })}
                </span>
              )}
            </WrapInput>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <div className="d-flex">
                <FormattedMessage id="auth.setting.settingPass.modal.passNew" />
                <p className="text-danger pl-1 mb-0">*</p>
              </div>
            </LabelCell>
            <WrapInput>
              <Input
                placeholder={intl.formatMessage({
                  id: "auth.setting.settingPass.modal.placeholderPassNew",
                })}
                type="password"
                onChange={(e: any) =>
                  changeForm(e?.target?.value, "newPassword")
                }
                value={form.newPassword}
                onBlur={(e: any) => checkValidate(e?.target?.value)}
              />
              {error === "newPassword" && (
                <span className="text-error text-danger">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                  })}
                </span>
              )}
              {error === "" && validateNewPass !== "" && (
                <span className="text-error text-danger">
                  {validateNewPass === "Min"
                    ? intl.formatMessage(
                        {
                          id: `auth.setting.settingPass.modal.error${validateNewPass}`,
                        },
                        { x: minPass }
                      )
                    : intl.formatMessage({
                        id: `auth.setting.settingPass.modal.error${validateNewPass}`,
                      })}
                </span>
              )}
            </WrapInput>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <div className="d-flex">
                <FormattedMessage id="auth.setting.settingPass.modal.againPass" />
                <p className="text-danger pl-1 mb-0">*</p>
              </div>
            </LabelCell>
            <WrapInput>
              <Input
                placeholder={intl.formatMessage({
                  id: "auth.setting.settingPass.modal.placeholderAgainPass",
                })}
                type="password"
                onChange={(e: any) =>
                  changeForm(e?.target?.value, "confirmPassword")
                }
                value={form.confirmPassword}
              />
              {error === "confirmPassword" && (
                <span className="text-error text-danger">
                  {intl.formatMessage({
                    id: "transferInternal.historyTransferBank.modalAddAcount.emptyBank",
                  })}
                </span>
              )}
            </WrapInput>
          </BodyModalRow>

          <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={() => onClose()}
                width="100%"
              >
                <FormattedMessage id="auth.setting.settingPass.modal.btnCancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={() => submitForm()}
                width="100%"
              >
                <FormattedMessage id="auth.setting.settingPass.modal.btnSuccess" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default memo(ModalSetting);
