import styled from "styled-components/macro";

export const Container = styled.div`
  height: calc(100% - 60px);
`;

export const CardTable = styled.div`
  flex: 1;
  margin: 24px;
  display: flex;
  flex-direction: column;
  &:first-child {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.bgElevated4} 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 18px 1px;
    background-repeat: repeat-x;
  }
`;

export const Title = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.cardHeader.height};
  background-color: ${({ theme }) => theme.greyG8};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.shadowInner1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    text-align: center;
    color: ${({ theme }) => theme.textcolorWhite};
    font-weight: ${({ theme }) => theme.typeFaces.headline.weight.semiBold};
    font-size: ${({ theme }) => theme.typeFaces.headline.size};
  }
  .flex-1 {
    flex: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  .apply-btn {
    background-color: ${({ theme }) => theme.solidBlue};
  }
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .w-96 {
    max-width: 96px;
    .buttonReset {
      padding: 8px 12px;
      div {
        color: ${({ theme }) => theme.brandPrimary};
      }
      svg {
        path {
          fill: ${({ theme }) => theme.brandPrimary};
        }
      }
    }
  }
  .w-120 {
    max-width: 120px;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonRight = styled.div`
  flex: 3;
  display: flex;
  flex-direction: row;
  max-width: 252px;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  :last-child {
    padding-bottom: 0;
  }
`;

export const CardLabel = styled.div<{ isSwitch?: boolean }>`
  color: ${({ theme }) => theme.grey30};
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  flex: ${({ isSwitch }) => (isSwitch ? 4 : 2)};
`;

export const CardValue = styled.div<{ isSwitch?: boolean }>`
  flex: ${({ isSwitch }) => (isSwitch ? 1 : 3)};
`;

export const YesNoButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonExit = styled.button`
  background: transparent;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
`;

export const TitleContent = styled.div`
  font-weight: ${({ theme }) => theme.typeFaces.headline.weight.bold};
  font-size: ${({ theme }) => theme.typeFaces.headline.size};
  line-height: ${({ theme }) => theme.typeFaces.headline.lineHeight};
  color: ${({ theme }) => theme.textcolorWhite};
`;
