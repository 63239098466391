import { Button } from "components/commons";
import {
  updateItemEdit,
  getDBondOrderRequest,
  getDBondPriceRequest,
  findCbotDBondInfoDetailRequest,
  updateFilter,
  placeDBondOrderRequest,
  updateButtonActive,
  inquiryBuyingPowerRequest,
} from "modules/bid-ask-d-bond-order/redux";
import {
  IPlaceDBondOrderParams,
  IGetDBondOrderParams,
} from "core/http/apis/bid-ask-d-bond-order/types";
import { accountInfoSelector } from "modules/auth/selectors";
import {
  bidAskDBondOrderEditSelector,
  listDBondPriceSelector,
  statusPlaceDBondOrderSelector,
  activeButtonSelector,
  filterDBondOrderSelector,
} from "modules/bid-ask-d-bond-order/selectors";
import { tradeDateSelector } from "modules/auth/selectors";
// import moment from "moment";

import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import {
  DetailCol,
  DetailInputWrapper,
  DetailLabel,
  DetailWrapper,
  DetailRowPaddingTB,
  Devided,
  BuySellButtonRow,
  BuyButton,
  ButtonItem,
  LabelBuySell,
  SellButton,
  DetailColFlexEnd,
  DetailRowPaddingSelect,
  DetailInputWrapperNoMargin,
} from "./styles";

import { regVolumn } from "helper/consts";

import InputSymbol from "modules/bid-ask-bond/pages/BidAskBond/components/BidAskBondContent/RequestFilters/InputSymbol";
import InputVolume from "components/commons/InputVolume";
import { convertNumberToDateFormat } from "helper/utils";

import numeral from "numeral";
import { toast } from "components/commons/Toast";
import { OTPByBusinessCd } from "components/commons";
import { EBusinessCd } from "helper/consts";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import { updateModalOtpRequest } from "modules/advance/redux";
import { isShowOtpModalSelector } from "modules/advance/selectors";
import { Row, Col } from "react-bootstrap";
import ModalConfirm from "modules/create-contract/pages/CreateContract/components/CreateContractContent/RequestTable/ModalConfirm";

import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";
import { GetParameters } from "helper/sessionData";

function RequestDetail() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { currentUser } = useSelector(accountInfoSelector);

  const listDBondPrice = useSelector(listDBondPriceSelector);
  const itemEdit = useSelector(bidAskDBondOrderEditSelector);
  const tradeDate = useSelector(tradeDateSelector);
  const statusPlaceDBondOrder = useSelector(statusPlaceDBondOrderSelector);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const isVisible = useSelector(isShowOtpModalSelector);

  const themeType = useSelector(themeTypeSelector);

  const activeButton = useSelector(activeButtonSelector);
  const paramHd = GetParameters("CB00", "CBO_FEE_RATE");
  const { secCdDBond } = useSelector(filterDBondOrderSelector);
  useEffect(() => {
    if (itemEdit && itemEdit.secCdDBond) {
      dispatch(
        inquiryBuyingPowerRequest({
          subAccoNo: currentUser.userId + ".60",
          secCd: itemEdit.secCdDBond ?? "",
        })
      );
      dispatch(
        findCbotDBondInfoDetailRequest({
          bondCd: itemEdit.secCdDBond ?? "",
        })
      );

      if (activeButton === "2") {
        const check = listDBondPrice.find(
          (i) => i.bondCd === itemEdit.secCdDBond
        );

        if (check && Object.keys(check).length > 0) {
          dispatch(
            updateItemEdit({
              amount: check.buyPrice ?? 0,
            })
          );
          if (check.buyPrice && itemEdit && itemEdit.qty) {
            const investValue = check.buyPrice * itemEdit.qty;
            if (paramHd) {
              let paramFee = +paramHd ?? 0;
              dispatch(
                updateItemEdit({
                  fee: investValue * paramFee ?? 0,

                  totalAmount: investValue + investValue * paramFee ?? 0,
                })
              );
            } else {
              dispatch(
                updateItemEdit({
                  fee: 0,

                  totalAmount: 0,
                })
              );
            }
          } else {
            dispatch(
              updateItemEdit({
                fee: 0,

                totalAmount: 0,
              })
            );
          }
        }
      } else if (activeButton === "1") {
        const check = listDBondPrice.find(
          (i) => i.bondCd === itemEdit.secCdDBond
        );

        if (check && Object.keys(check).length > 0) {
          dispatch(
            updateItemEdit({
              amount: check.sellPrice ?? 0,
            })
          );
          if (check.sellPrice && itemEdit && itemEdit.qty) {
            const investValue = check.sellPrice * itemEdit.qty;

            if (paramHd) {
              let paramFee = +paramHd ?? 0;
              dispatch(
                updateItemEdit({
                  fee: investValue * paramFee ?? 0,

                  totalAmount: investValue + investValue * paramFee ?? 0,
                })
              );
            } else {
              dispatch(
                updateItemEdit({
                  fee: 0,

                  totalAmount: 0,
                })
              );
            }
          } else {
            dispatch(
              updateItemEdit({
                fee: 0,

                totalAmount: 0,
              })
            );
          }
        }
      }
    } else {
      dispatch(
        updateItemEdit({
          amount: 0,

          maxPrice: 0,
          purchaseAbility: 0,
          fee: 0,

          totalAmount: 0,
          issuerBond: "",
          price: 0,
          releaseDate: 0,
          coupon: 0,
          expiratedDate2: 0,
          period: 0,
          description: "",
        })
      );
    }
  }, [itemEdit?.secCdDBond, activeButton]);
  const clickBuyButtonHandler = () => {
    dispatch(updateButtonActive("2"));
    if (itemEdit) {
      const check = listDBondPrice.find(
        (i) => i.bondCd === itemEdit.secCdDBond
      );

      if (check && Object.keys(check).length > 0) {
        dispatch(
          updateItemEdit({
            amount: check.buyPrice ?? 0,
          })
        );
        if (check.buyPrice && itemEdit.purchaseAbility !== undefined) {
          dispatch(
            updateItemEdit({
              maxPrice: itemEdit.purchaseAbility / check.buyPrice,
            })
          );
          if (itemEdit.qty) {
            const investValue = itemEdit.qty * check.buyPrice;
            if (paramHd) {
              let paramFee = +paramHd ?? 0;
              dispatch(
                updateItemEdit({
                  fee: investValue * paramFee ?? 0,

                  totalAmount: investValue + investValue * paramFee ?? 0,
                })
              );
            } else {
              dispatch(
                updateItemEdit({
                  fee: 0,

                  totalAmount: 0,
                })
              );
            }
          } else {
            dispatch(
              updateItemEdit({
                fee: 0,

                totalAmount: 0,
              })
            );
          }
        } else {
          dispatch(
            updateItemEdit({
              maxPrice: 0,
              fee: 0,

              totalAmount: 0,
            })
          );
        }
      } else {
        dispatch(
          updateItemEdit({
            amount: 0,

            maxPrice: 0,
            purchaseAbility: 0,

            fee: 0,

            totalAmount: 0,
          })
        );
      }
    }
  };

  const clickSellButtonHandler = () => {
    dispatch(updateButtonActive("1"));
    if (itemEdit) {
      const check = listDBondPrice.find(
        (i) => i.bondCd === itemEdit.secCdDBond
      );

      if (check && Object.keys(check).length > 0) {
        dispatch(
          updateItemEdit({
            amount: check.sellPrice ?? 0,
          })
        );
        if (check.sellPrice && itemEdit.purchaseAbility !== undefined) {
          dispatch(
            updateItemEdit({
              maxPrice: itemEdit.purchaseAbility / check.sellPrice,
            })
          );
          if (itemEdit.qty) {
            const investValue = itemEdit.qty * check.sellPrice;
            if (paramHd) {
              let paramFee = +paramHd ?? 0;
              dispatch(
                updateItemEdit({
                  fee: investValue * paramFee ?? 0,

                  totalAmount: investValue + investValue * paramFee ?? 0,
                })
              );
            } else {
              dispatch(
                updateItemEdit({
                  fee: 0,

                  totalAmount: 0,
                })
              );
            }
          } else {
            dispatch(
              updateItemEdit({
                fee: 0,

                totalAmount: 0,
              })
            );
          }
        } else {
          dispatch(
            updateItemEdit({
              maxPrice: 0,
              fee: 0,

              totalAmount: 0,
            })
          );
        }
      } else {
        dispatch(
          updateItemEdit({
            amount: 0,

            maxPrice: 0,
            purchaseAbility: 0,

            fee: 0,

            totalAmount: 0,
          })
        );
      }
    }
  };

  const changeInputHandlerVolume = (value: number) => {
    dispatch(updateItemEdit({ qty: value ?? 0 }));

    if (itemEdit && itemEdit.amount && value) {
      const investValue = itemEdit.amount * value;
      if (paramHd) {
        let paramFee = +paramHd ?? 0;
        dispatch(
          updateItemEdit({
            fee: investValue * paramFee ?? 0,

            totalAmount: investValue + investValue * paramFee ?? 0,
          })
        );
      } else {
        dispatch(
          updateItemEdit({
            fee: 0,

            totalAmount: 0,
          })
        );
      }
    } else {
      dispatch(
        updateItemEdit({
          fee: 0,

          totalAmount: 0,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(updateButtonActive("2"));
    dispatch(
      getDBondPriceRequest({
        transDate: tradeDate ? tradeDate : "",
      })
    );

    dispatch(
      updateItemEdit({
        amount: 0,
        qty: 0,
        secCdDBond: "",

        maxPrice: 0,
        purchaseAbility: 0,

        fee: 0,

        totalAmount: 0,
        issuerBond: "",
        price: 0,
        releaseDate: 0,
        coupon: 0,
        expiratedDate2: 0,
        period: 0,
        description: "",
      })
    );
  }, []);

  const clickResetHandler = () => {
    dispatch(
      getDBondPriceRequest({
        transDate: tradeDate ? tradeDate : "",
      })
    );

    dispatch(
      updateItemEdit({
        amount: 0,
        qty: 0,
        secCdDBond: "",

        maxPrice: 0,
        purchaseAbility: 0,

        fee: 0,

        totalAmount: 0,
        issuerBond: "",
        price: 0,
        releaseDate: 0,
        coupon: 0,
        expiratedDate2: 0,
        period: 0,
        description: "",
      })
    );
  };
  const clickAcceptHandler = () => {
    if (itemEdit && Object.keys(itemEdit).length > 0) {
      if (itemEdit.secCdDBond === null || itemEdit.secCdDBond === undefined) {
        return toast("bidAskBondOrder.message.checkErr3", "error");
      } else if (itemEdit.secCdDBond === "") {
        return toast("bidAskBondOrder.message.checkErr4", "error");
      } else if (itemEdit.qty === null || itemEdit.qty === undefined) {
        return toast("bidAskBondOrder.message.checkErr7", "error");
      } else if (itemEdit.qty === 0) {
        return toast("bidAskBondOrder.message.checkErr8", "error");
      } else if (
        itemEdit.maxPrice !== undefined &&
        itemEdit.qty > itemEdit.maxPrice
      ) {
        return toast("bidAskBondOrder.message.checkErr10", "error");
      }
      setVisibleModalConfirm(true);
    }
  };

  const setConfirm = () => {
    dispatch(updateModalOtpRequest({ isVisible: true }));
  };
  const handleCloseOTPModal2 = () => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
  };

  const doSubmitAdvance2 = (response: IVerifyResponse) => {
    dispatch(updateModalOtpRequest({ isVisible: false }));
    const submitData: IPlaceDBondOrderParams = {
      custd: "",
      custNo: currentUser.userId ? currentUser.userId : "",
      tradeType: parseInt(activeButton),
      bondCd: itemEdit && itemEdit.secCdDBond ? itemEdit.secCdDBond : "",
      qty: itemEdit && itemEdit.qty ? itemEdit.qty : 0,
      price: itemEdit && itemEdit.amount ? itemEdit.amount : 0,
      type: 1,
      expDate: null,

      otpCode: response?.otp ?? "",
      fromCustCd: null,
      fromCustNo: null,
      feePayer: null,
      taxPayer: null,
      otpType: response?.otpTypeSubmit!,
    };

    dispatch(placeDBondOrderRequest(submitData));
  };
  useEffect(() => {
    if (statusPlaceDBondOrder) {
      toast("bidAskDBondOrder.message.success", "success");

      const submitData: IGetDBondOrderParams = {
        status: -1,

        bondCd: secCdDBond ? secCdDBond : "",
        custCd: 0,
        custNo: null,
        fromDate: null,
        toDate: null,
        type: 1,
        tradeType: null,
        getDataType: 1,
      };
      dispatch(getDBondOrderRequest(submitData));
      dispatch(
        updateFilter({
          key: "status",
          value: -1,
        })
      );
      setVisibleModalConfirm(false);
      clickResetHandler();
    }
  }, [statusPlaceDBondOrder]);

  return (
    <DetailWrapper>
      <DetailCol>
        <BuySellButtonRow>
          <ButtonItem className="mr-1" onClick={clickBuyButtonHandler}>
            <BuyButton
              isActive={activeButton === "2" ? true : false}
            ></BuyButton>
            <LabelBuySell isActive={activeButton === "2" ? true : false}>
              {intl.formatMessage({
                id: "bidAskDBondOrder.create.buy",
              })}
            </LabelBuySell>
          </ButtonItem>
          <ButtonItem onClick={clickSellButtonHandler}>
            <SellButton
              isActive={activeButton === "1" ? true : false}
            ></SellButton>
            <LabelBuySell isActive={activeButton === "1" ? true : false}>
              {intl.formatMessage({
                id: "bidAskDBondOrder.create.sell",
              })}
            </LabelBuySell>
          </ButtonItem>
        </BuySellButtonRow>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-3 ">
              <DetailLabel>
                <FormattedMessage id="bidAskDBondOrder.create.account" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{currentUser.userId}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>

        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1", marginTop: "-8px" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.bondCd" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white input-sec">
                  <InputSymbol
                    list={listDBondPrice}
                    showPlaceholder={false}
                    value={itemEdit?.secCdDBond}
                    onChange={(e) => {
                      if (e && e.length > 0) {
                        dispatch(
                          updateItemEdit({
                            secCdDBond: e ? e : "",
                          })
                        );
                      } else {
                        dispatch(
                          updateItemEdit({
                            secCdDBond: "",
                          })
                        );
                      }
                    }}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>

        <DetailRowPaddingSelect>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3 my-auto">
              <DetailLabel>
                <FormattedMessage id="bidAskDBondOrder.create.qty" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <div className="text-white">
                  <InputVolume
                    value={
                      itemEdit && itemEdit.qty ? itemEdit.qty.toString() : "0"
                    }
                    onChange={(num) => changeInputHandlerVolume(+num)}
                    onClickButton={(num) => changeInputHandlerVolume(+num)}
                    placeholder={intl.formatMessage({
                      id: "formPlaceholder.inputVolumne",
                    })}
                    partern={regVolumn}
                    min={0}
                  />
                </div>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingSelect>
        <DetailRowPaddingTB>
          <Row
            className=" d-flex align-content-center w-100 w-100"
            style={{ flex: "1" }}
          >
            <Col xs={12} md={5} className="w-100  p-0 px-1 pl-3">
              <DetailLabel>
                <FormattedMessage id="bidAskDBondOrder.create.amount" />
              </DetailLabel>
            </Col>
            <Col xs={12} md={7} className="w-100  p-0 px-3 text-right pr-4">
              <DetailInputWrapper>
                <b>{numeral(itemEdit ? itemEdit.amount : 0).format("0,0")}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </DetailRowPaddingTB>
        <Devided />

        <Row className="mt-3 pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskDBondOrder.create.maxPrice" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.maxPrice : 0).format("0,0")}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskDBondOrder.create.purchaseAbility" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.purchaseAbility : 0).format(
                      "0,0"
                    )}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className=" pl-2 pr-3">
          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskDBondOrder.create.fee" />
                </DetailLabel>
              </Col>

              <Col xs={12} lg={7} className="w-100 text-left p-0">
                <DetailInputWrapperNoMargin>
                  <b>{numeral(itemEdit ? itemEdit.fee : 0).format("0,0")}</b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>

          <Col
            sm={12}
            xl={6}
            className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
            style={{ flex: 1 }}
          >
            <Row
              className=" d-flex align-content-center  justify-content-between "
              style={{ flex: 1 }}
            >
              <Col xs={12} lg={5} className="w-100  p-0">
                <DetailLabel>
                  <FormattedMessage id="bidAskDBondOrder.create.totalAmount" />
                </DetailLabel>
              </Col>
              <Col xs={12} lg={7} className="w-100 text-right p-0">
                <DetailInputWrapperNoMargin>
                  <b>
                    {numeral(itemEdit ? itemEdit.totalAmount : 0).format("0,0")}
                  </b>
                </DetailInputWrapperNoMargin>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row>
          <Col sm={12} className=" d-flex align-content-center pl-3 pr-4">
            <DetailLabel>
              <FormattedMessage id="bidAskDBondOrder.create.totalAmount" />
            </DetailLabel>
            <DetailInputWrapper>
              <b>
                {numeral(itemEdit ? itemEdit.totalAmount : 0).format("0,0")}
              </b>
            </DetailInputWrapper>
          </Col>
        </Row> */}
      </DetailCol>
      <DetailColFlexEnd>
        <div className="mb-4 w-100">
          <Row className="mt-4 pl-2 pr-3">
            <Col
              sm={12}
              xl={6}
              className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
              style={{ flex: 1 }}
            >
              <Row
                className=" d-flex align-content-center  justify-content-between "
                style={{ flex: 1 }}
              >
                <Col xs={12} lg={8} className="w-100  p-0">
                  <DetailLabel>
                    <FormattedMessage id="bidAskDBondOrder.create.issuerBond" />
                  </DetailLabel>
                </Col>
                <Col xs={12} lg={4} className="w-100 text-left p-0">
                  <DetailInputWrapperNoMargin>
                    <b>{itemEdit ? itemEdit.issuerBond : ""}</b>
                  </DetailInputWrapperNoMargin>
                </Col>
              </Row>
            </Col>

            <Col
              sm={12}
              xl={6}
              className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
              style={{ flex: 1 }}
            >
              <Row
                className=" d-flex align-content-center  justify-content-between "
                style={{ flex: 1 }}
              >
                <Col xs={12} lg={8} className="w-100  p-0">
                  <DetailLabel>
                    <FormattedMessage id="bidAskDBondOrder.create.price" />
                  </DetailLabel>
                </Col>
                <Col xs={12} lg={4} className="w-100 text-right p-0">
                  <DetailInputWrapperNoMargin>
                    <b>
                      {numeral(itemEdit ? itemEdit.price : 0).format("0,0")}
                    </b>
                  </DetailInputWrapperNoMargin>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className=" pl-2 pr-3">
            <Col
              sm={12}
              xl={6}
              className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
              style={{ flex: 1 }}
            >
              <Row
                className=" d-flex align-content-center  justify-content-between "
                style={{ flex: 1 }}
              >
                <Col xs={12} lg={8} className="w-100  p-0">
                  <DetailLabel>
                    <FormattedMessage id="bidAskDBondOrder.create.releaseDate" />
                  </DetailLabel>
                </Col>
                <Col xs={12} lg={4} className="w-100 text-left p-0">
                  <DetailInputWrapperNoMargin>
                    <b>
                      {" "}
                      {convertNumberToDateFormat({
                        date: itemEdit ? itemEdit.releaseDate : 0,
                      })}
                    </b>
                  </DetailInputWrapperNoMargin>
                </Col>
              </Row>
            </Col>

            <Col
              sm={12}
              xl={6}
              className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
              style={{ flex: 1 }}
            >
              <Row
                className=" d-flex align-content-center  justify-content-between "
                style={{ flex: 1 }}
              >
                <Col xs={12} lg={8} className="w-100  p-0">
                  <DetailLabel>
                    <FormattedMessage id="bidAskDBondOrder.create.coupon" />
                  </DetailLabel>
                </Col>
                <Col xs={12} lg={4} className="w-100 text-right p-0">
                  <DetailInputWrapperNoMargin>
                    <b>
                      {numeral(itemEdit ? itemEdit.coupon : 0).format("0,0")}
                      %/Năm
                    </b>
                  </DetailInputWrapperNoMargin>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2 pl-2 pr-3">
            <Col
              sm={12}
              xl={6}
              className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
              style={{ flex: 1 }}
            >
              <Row
                className=" d-flex align-content-center  justify-content-between "
                style={{ flex: 1 }}
              >
                <Col xs={12} lg={8} className="w-100  p-0">
                  <DetailLabel>
                    <FormattedMessage id="bidAskDBondOrder.create.expiratedDate2" />
                  </DetailLabel>
                </Col>
                <Col xs={12} lg={4} className="w-100 text-left p-0">
                  <DetailInputWrapperNoMargin>
                    <b>
                      {" "}
                      {convertNumberToDateFormat({
                        date: itemEdit ? itemEdit.expiratedDate2 : 0,
                      })}
                    </b>
                  </DetailInputWrapperNoMargin>
                </Col>
              </Row>
            </Col>

            <Col
              sm={12}
              xl={6}
              className="mt-2 mb-2 d-flex align-content-center justify-content-between p-0 px-4"
              style={{ flex: 1 }}
            >
              <Row
                className=" d-flex align-content-center  justify-content-between "
                style={{ flex: 1 }}
              >
                <Col xs={12} lg={8} className="w-100  p-0">
                  <DetailLabel>
                    <FormattedMessage id="bidAskDBondOrder.create.period" />
                  </DetailLabel>
                </Col>
                <Col xs={12} lg={4} className="w-100 text-right p-0">
                  <DetailInputWrapperNoMargin>
                    <b>{itemEdit ? itemEdit.period : 0} tháng</b>
                  </DetailInputWrapperNoMargin>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col sm={12} className=" d-flex align-content-center pl-3 pr-4">
              <DetailLabel>
                <FormattedMessage id="bidAskBondOrder.create.description" />
              </DetailLabel>
              <DetailInputWrapper>
                <b>{itemEdit ? itemEdit.description : ""}</b>
              </DetailInputWrapper>
            </Col>
          </Row>
        </div>
        <Row className=" d-flex align-content-center w-100 justify-content-center mx-auto">
          <Col sm={12} lg={6} className="w-100 p-0 px-1">
            <Button
              color="accept"
              className="btn-place-order button-filter w-100 m-1 mx-auto"
              onClick={clickResetHandler}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {intl.formatMessage({
                id: "bidAskDBondOrder.create.reset",
              })}
            </Button>
          </Col>
          <Col sm={12} lg={6} className="w-100 p-0 px-0 pr-2">
            <Button
              color="accept"
              className={
                activeButton === "2"
                  ? "btn-place-order btn-place-order-buy button-filter m-1 w-100"
                  : "btn-place-order btn-place-order-sell button-filter m-1 w-100"
              }
              onClick={clickAcceptHandler}
              fill={true}
              style={{
                fontSize: `${themes[themeType].typeFaces.headline.size}`,
                padding: "18px 0px",
              }}
            >
              {activeButton === "2"
                ? intl.formatMessage({
                    id: "bidAskDBondOrder.create.buy",
                  })
                : intl.formatMessage({
                    id: "bidAskDBondOrder.create.sell",
                  })}
            </Button>
          </Col>
        </Row>
      </DetailColFlexEnd>
      <ModalConfirm
        visibleModalConfirm={visibleModalConfirm}
        setVisibleModalConfirm={setVisibleModalConfirm}
        setConfirm={setConfirm}
        title={intl.formatMessage({ id: "common.confirm" })}
        content={intl.formatMessage({
          id: "bidAskDBondOrder.message.confirmContent",
        })}
      />
      {isVisible && (
        <OTPByBusinessCd
          handleClose={handleCloseOTPModal2}
          getResult={doSubmitAdvance2}
          businessCd={EBusinessCd.TRADING}
        />
      )}
    </DetailWrapper>
  );
}

export default RequestDetail;
