import { IScreenerFilter } from "domain/models/Screener";
import { NullableString, ScreenerInput } from "domain/protoNew/auto_trading_pb";
import { IOptionsList, ISampleFilter } from "./types";

export enum SCREENERS_TAB_CODE {
  SCREENER = "SCREENER",
  MY_SCREENER = "MY_SCREENER",
}

export const LANGUAGE_CODE = {
  VI: 0,
  EN: 1,
  KR: 2,
  JP: 3,
  CN: 4,
};

export const FILTER_GROUP = {
  MARKET: 1,
  QUOTES_INDICATOR: 2,
  FINANCIAL_INDICATOR: 3,
  TECHNICAL_INDICATOR: 4,
};

export const FILTER_TYPE = {
  RANGE: 1,
  OPTION: 2,
  RADIO: 3,
};

export const ORDER_TYPE = {
  ASC: 1,
  DESC: 2,
  UNKNOWN: 3,
};

export const ORDER_FIELD_TYPE = {
  TICKER: 1,
  PRICE: 2,
  CHANGE: 3,
  MARKETCAP: 4,
  PRICETOBOOK: 5,
  PRICETOEARNINGRATIO: 6,
  NETREVENUE: 7,
  RETURNONEQUITY: 8,
  RETURNONASSETS: 9,
  EARNINGPERSHARE: 10,
  UNKNOWN: 12,

  // CHANGEVND: 9,
};

export const MARKET_KEY = {
  MARKET_CODE: "MarketCode",
  INDUSTRY: "Industry",
};

export const FLEX = {
  START: "flex-start",
  CENTER: "center",
  END: "flex-end",
};

// export enum ESortBy {
//   SORTBY_UNKNOWN = 0,
//   PRICE = 1,
//   CHANGE = 2,
//   PRICETOEARNINGRATIO = 3,
//   NETREVENUE = 4,
//   EARNINGPERSHARE = 5,
//   RETURNONASSETS = 6,
//   RETURNONEQUITY = 7,
//   MARKETCAP = 8,
//   CHANGEVND = 9,
//   PRICETOBOOK = 10,
// }

export const COLUMNS = [
  {
    width: 60,
    name: "screeners.card.table.shareCode",
    position: "START",
    flexCell: 3,
  },
  {
    width: 76,
    name: "screeners.card.table.price",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.PRICE,
  },
  {
    width: 66,
    name: "screeners.card.table.change",
    position: "END",
    // sortBy: ORDER_FIELD_TYPE.CHANGEVND,
  },
  {
    width: 86,
    name: "screeners.card.table.changeRate",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.CHANGE,
  },
  {
    width: 140,
    name: "screeners.card.table.marketCap",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.MARKETCAP,
  },
  {
    width: 60,
    name: "screeners.card.table.pe",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.PRICETOEARNINGRATIO,
  },
  {
    width: 60,
    name: "screeners.card.table.pb",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.PRICETOBOOK,
  },
  {
    width: 107,
    name: "screeners.card.table.netMargin",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.NETREVENUE,
  },
  {
    width: 74,
    name: "screeners.card.table.roa",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.RETURNONASSETS,
  },
  {
    width: 74,
    name: "screeners.card.table.roe",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.RETURNONEQUITY,
  },
  {
    width: 74,
    name: "screeners.card.table.eps",
    position: "END",
    sortBy: ORDER_FIELD_TYPE.EARNINGPERSHARE,
  },
];

export const TIME_BLINK = 600;

export const SCREENER_SAMPLE: ISampleFilter[] = [
  // Technical
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "Đúng",
      },
      {
        value: "false",
        name: "Sai",
      },
    ],
    name: "RSI24 Quá Bán",
    key: "4",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "Đúng",
      },
      {
        value: "false",
        name: "Sai",
      },
    ],
    name: "MA5 Cắt MA10",
    key: "6",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "Đúng",
      },
      {
        value: "false",
        name: "Sai",
      },
    ],
    name: "KDJ",
    key: "2",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "Đúng",
      },
      {
        value: "false",
        name: "Sai",
      },
    ],
    name: "RSI6 Quá Bán",
    key: "3",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "Đúng",
      },
      {
        value: "false",
        name: "Sai",
      },
    ],
    name: "MACD Cross",
    key: "1",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "Đúng",
      },
      {
        value: "false",
        name: "Sai",
      },
    ],
    name: "Ba Chàng Lính Trắng",
    key: "5",
    unit: "",
  },

  // Tiêu chí cơ bản
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 500000,
    },
    minvalue: {
      value: 0,
    },
    name: "Giá",
    key: "PRICE",
    unit: "VND",
  },
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 407157,
    },
    minvalue: {
      value: 0,
    },
    name: "Vốn hóa",
    key: "MARKET_CAP",
    unit: "B",
  },
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 100000000,
    },
    minvalue: {
      value: 0,
    },
    name: "Khối lượng",
    key: "VOLUME",
    unit: "",
  },
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 30,
    },
    minvalue: {
      value: -30,
    },
    name: "% Thay đổi",
    key: "CHANGE_PERCENT",
    unit: "%",
  },

  // Chỉ số cơ bản
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 9222.37,
    },
    minvalue: {
      value: 0,
    },
    name: "P/E",
    key: "PE",
    unit: "",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 30606.76,
    },
    minvalue: {
      value: 0,
    },
    name: "EPS",
    key: "EPS",
    unit: "",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 6000,
    },
    minvalue: {
      value: 0,
    },
    name: "Tỷ lệ cổ tức",
    key: "DIVIDEND_YIELD",
    unit: "%",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 897.52,
    },
    minvalue: {
      value: 0,
    },
    name: "ROE",
    key: "ROE",
    unit: "%",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 58.66,
    },
    minvalue: {
      value: 0,
    },
    name: "P/B",
    key: "PB",
    unit: "",
  },

  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 43.55,
    },
    minvalue: {
      value: 0,
    },
    name: "ROA",
    key: "ROA",
    unit: "%",
  },

  // Sàn
  {
    group: 1,
    type: 2,
    optionsList: [
      {
        value: "100",
        name: "HOSE",
      },
      {
        value: "200",
        name: "HNX",
      },
      {
        value: "300",
        name: "UPCOM",
      },
    ],
    name: "Sàn",
    key: "MarketCode",
    unit: "",
  },

  // Ngành
  {
    group: 1,
    type: 2,
    optionsList: [
      {
        value: "1",
        name: "Bán lẻ",
      },
      {
        value: "2",
        name: "Bảo hiểm",
      },
      {
        value: "3",
        name: "Bất động sản",
      },
      {
        value: "4",
        name: "Công nghệ Thông tin",
      },
      {
        value: "5",
        name: "Dầu khí",
      },
      {
        value: "6",
        name: "Dịch vụ Tài chính",
      },
      {
        value: "7",
        name: "Du lịch và Giải trí",
      },
      {
        value: "8",
        name: "Hàng & Dịch vụ Công nghiệp",
      },
      {
        value: "9",
        name: "Hàng cá nhân & Gia dụng",
      },
      {
        value: "10",
        name: "Hóa chất",
      },
      {
        value: "11",
        name: "Ngân hàng",
      },
      {
        value: "12",
        name: "Ô tô và phụ tùng",
      },
      {
        value: "13",
        name: "Tài nguyên Cơ bản",
      },
      {
        value: "14",
        name: "Thực phẩm và đồ uống",
      },
      {
        value: "15",
        name: "Tiện ích",
      },
      {
        value: "16",
        name: "Truyền thông",
      },
      {
        value: "17",
        name: "Viễn thông",
      },
      {
        value: "18",
        name: "Xây dựng và Vật liệu",
      },
      {
        value: "19",
        name: "Y tế",
      },
      {
        value: "20",
        name: "Khác",
      },
    ],
    name: "Ngành",
    key: "Industry",
    unit: "",
  },
];

export const SCREENER_SAMPLE_EN: ISampleFilter[] = [
  // Technical
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "True",
      },
      {
        value: "false",
        name: "False",
      },
    ],
    name: "RSI24",
    key: "4",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "True",
      },
      {
        value: "false",
        name: "False",
      },
    ],
    name: "MA5CrossMA10",
    key: "6",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "True",
      },
      {
        value: "false",
        name: "False",
      },
    ],
    name: "KDJ",
    key: "2",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "True",
      },
      {
        value: "false",
        name: "False",
      },
    ],
    name: "RSI6",
    key: "3",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "True",
      },
      {
        value: "false",
        name: "False",
      },
    ],
    name: "MACD Cross",
    key: "1",
    unit: "",
  },
  {
    group: 4,
    type: 3,
    optionsList: [
      {
        value: "true",
        name: "True",
      },
      {
        value: "false",
        name: "False",
      },
    ],
    name: "ThreeWhiteSolider",
    key: "5",
    unit: "",
  },

  // Tiêu chí cơ bản
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 500000,
    },
    minvalue: {
      value: 0,
    },
    name: "Price",
    key: "PRICE",
    unit: "VND",
  },
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 407157,
    },
    minvalue: {
      value: 0,
    },
    name: "Market Cap",
    key: "MARKET_CAP",
    unit: "B",
  },
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 100000000,
    },
    minvalue: {
      value: 0,
    },
    name: "Volume",
    key: "VOLUME",
    unit: "",
  },
  {
    group: 2,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 30,
    },
    minvalue: {
      value: -30,
    },
    name: "Change Percent",
    key: "CHANGE_PERCENT",
    unit: "%",
  },

  // Chỉ số cơ bản
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 9222.37,
    },
    minvalue: {
      value: 0,
    },
    name: "P/E",
    key: "PE",
    unit: "",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 30606.76,
    },
    minvalue: {
      value: 0,
    },
    name: "EPS",
    key: "EPS",
    unit: "",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 6000,
    },
    minvalue: {
      value: 0,
    },
    name: "Dividend Yield",
    key: "DIVIDEND_YIELD",
    unit: "%",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 897.52,
    },
    minvalue: {
      value: 0,
    },
    name: "ROE",
    key: "ROE",
    unit: "%",
  },
  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 58.66,
    },
    minvalue: {
      value: 0,
    },
    name: "P/B",
    key: "PB",
    unit: "",
  },

  {
    group: 3,
    type: 1,
    optionsList: [],
    maxvalue: {
      value: 43.55,
    },
    minvalue: {
      value: 0,
    },
    name: "ROA",
    key: "ROA",
    unit: "%",
  },

  // Sàn
  {
    group: 1,
    type: 2,
    optionsList: [
      {
        value: "100",
        name: "HOSE",
      },
      {
        value: "200",
        name: "HNX",
      },
      {
        value: "300",
        name: "UPCOM",
      },
    ],
    name: "Market",
    key: "MarketCode",
    unit: "",
  },

  // Ngành
  {
    group: 1,
    type: 2,
    optionsList: [
      {
        value: "1",
        name: "Retail",
      },
      {
        value: "2",
        name: "Insurance",
      },
      {
        value: "3",
        name: "Real Estate",
      },
      {
        value: "4",
        name: "Technology",
      },
      {
        value: "5",
        name: "Oil & Gas",
      },
      {
        value: "6",
        name: "Financial Services",
      },
      {
        value: "7",
        name: "Travel & Leisure",
      },
      {
        value: "8",
        name: "Goods & Industrial Services",
      },
      {
        value: "9",
        name: "Personal & Household Goods",
      },
      {
        value: "10",
        name: "Chemicals",
      },
      {
        value: "11",
        name: "Banks",
      },
      {
        value: "12",
        name: "Automobiles & Parts",
      },
      {
        value: "13",
        name: "Basic Resources",
      },
      {
        value: "14",
        name: "Food & Beverage",
      },
      {
        value: "15",
        name: "Utilities",
      },
      {
        value: "16",
        name: "Media",
      },
      {
        value: "17",
        name: "Telecommunications",
      },
      {
        value: "18",
        name: "Construction & Materials",
      },
      {
        value: "19",
        name: "Medical",
      },
      {
        value: "20",
        name: "Other",
      },
    ],
    name: "Industry",
    key: "Industry",
    unit: "",
  },
];

export const convertFilterToScreenFilter = (
  filter: ISampleFilter
): IScreenerFilter => {
  return {
    key: filter.key,
    groupCd: filter.group,
    type: filter.type,
    name: filter.name,
    minValue: filter.minvalue?.value,
    maxValue: filter.maxvalue?.value,
    options: JSON.stringify(filter.optionsList),
    unit: filter.unit,
  };
};

export const convertStringToObject = (text: string): IOptionsList[] => {
  const optionsItem: IOptionsList[] = JSON.parse(text);

  return optionsItem;
};

export const convertStringToObjectArr = (text: string) => {
  if (!text) return [];

  return JSON.parse(text);
};

export const generateRequestFilterDoFilter = (
  currentFilterList: IScreenerFilter[],
  totalResult?: number
): ScreenerInput => {
  const requestFilter = new ScreenerInput();

  requestFilter.setAuthencode("vgaia-front-market-api");
  requestFilter.setOffset(0);
  requestFilter.setOrdertype(ORDER_TYPE.UNKNOWN);
  requestFilter.setOrderfieldtype(ORDER_FIELD_TYPE.UNKNOWN);

  if (totalResult) {
    requestFilter.setLimit(totalResult);
  }

  const marketArr: string[] = [];
  const industriesArr: string[] = [];
  const quotesindicatorsArr: string[] = [];
  const financialindicatorsArr: string[] = [];
  const technicalindicatorsArr: string[] = [];

  currentFilterList?.forEach((item) => {
    if (item.groupCd === FILTER_GROUP.MARKET) {
      if (item.key === MARKET_KEY.MARKET_CODE) {
        if (item.options) {
          const optionsItem: IOptionsList[] = JSON.parse(item.options);
          if (optionsItem && optionsItem?.length) {
            optionsItem?.forEach((option) => marketArr.push(`${option.value}`));
          }
        }
      }
      if (item.key === MARKET_KEY.INDUSTRY) {
        if (item.options) {
          const optionsItem: IOptionsList[] = JSON.parse(item.options);
          if (optionsItem && optionsItem?.length) {
            optionsItem?.forEach((option) =>
              industriesArr.push(`${option.value}`)
            );
          }
        }
      }
    }

    if (item.groupCd === FILTER_GROUP.QUOTES_INDICATOR) {
      if (item.key) {
        const rangeItem = `${item.key?.toUpperCase()}(${item.minValue},${
          item.maxValue
        })`;
        quotesindicatorsArr.push(`${rangeItem}`);
      }
    }

    if (item.groupCd === FILTER_GROUP.FINANCIAL_INDICATOR) {
      if (item.key) {
        const rangeItem = `${item.key?.toUpperCase()}(${item.minValue},${
          item.maxValue
        })`;
        financialindicatorsArr.push(`${rangeItem}`);
      }
    }

    if (item.groupCd === FILTER_GROUP.TECHNICAL_INDICATOR) {
      if (item.key) {
        if (item.key) {
          technicalindicatorsArr.push(`${item.key}`);
        }
      }
    }
  });

  const marketParam = !!marketArr.length ? marketArr.join(",") : "";
  const industriesParam = !!industriesArr.length ? industriesArr.join(",") : "";
  const quotesindicatorsParam = !!quotesindicatorsArr.length
    ? quotesindicatorsArr.join(";")
    : "";
  const financialindicatorsParam = !!financialindicatorsArr.length
    ? financialindicatorsArr.join(";")
    : "";
  const technicalindicatorsParam = !!technicalindicatorsArr.length
    ? technicalindicatorsArr.join(",")
    : "";

  const marketcd = new NullableString();
  marketcd.setData(marketParam);
  requestFilter.setMarketcd(marketcd);

  const industries = new NullableString();
  industries.setData(industriesParam);
  requestFilter.setIndustries(industries);

  const technicalindicators = new NullableString();
  technicalindicators.setData(technicalindicatorsParam);
  requestFilter.setTechnicalindicators(technicalindicators);

  const financialindicators = new NullableString();
  financialindicators.setData(financialindicatorsParam);
  requestFilter.setFinancialindicators(financialindicators);

  const quotesindicators = new NullableString();
  quotesindicators.setData(quotesindicatorsParam);
  requestFilter.setQuotesindicators(quotesindicators);

  console.warn("marketParam", marketParam);
  console.warn("industriesParam", industriesParam);
  console.warn("technicalindicatorsParam", technicalindicatorsParam);
  console.warn("financialindicatorsParam", financialindicatorsParam);
  console.warn("quotesindicatorsParam", quotesindicatorsParam);

  return requestFilter;
};

export interface IOptionType {
  name: string;
  value: string;
}
