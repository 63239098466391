export const FLEX = {
  START: "flex-start",
  CENTER: "center",
  END: "flex-end",
};

export enum SORT_BY {
  NONE = 0,
  STOCK_ID = 1, //1:Mã CK, 2:GT mua ròng, 3: GT bán ròng, 4: KL mua, 5: KL bán, 6: GT mua, 7: GT bán, 8:Gia khớp, 9:change_point, 10:change_percent
  TOP_VALUE_BUY = 2,
  TOP_VALUE_SELL = 3,
  PRICE = 8,
  CHANGED_PRICE = 9,
  CHANGED_PRICE_PERCENTAGE = 10,
  QTY_SELLFOREIGN = 5,
  QTY_BUYFOREIGN = 4,
  VALUE_BUYFOREIGN = 6,
  VALUE_SELLFOREIGN = 7,
}

export const COLUMNS_BUYFOREIGN_AMT = [
  {
    width: 35,
    name: "widgets.topForeign.table.stockId",
    position: "START",
    sortBy: SORT_BY.STOCK_ID,
  },
  {
    width: 20,
    name: "widgets.topForeign.table.topValue",
    position: "END",
    sortBy: SORT_BY.VALUE_BUYFOREIGN,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_SELLFOREIGN_AMT = [
  {
    width: 35,
    name: "widgets.topForeign.table.stockId",
    position: "START",
    sortBy: SORT_BY.STOCK_ID,
  },
  {
    width: 20,
    name: "widgets.topForeign.table.topValue",
    position: "END",
    sortBy: SORT_BY.VALUE_SELLFOREIGN,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_VALUE_BUY = [
  {
    width: 35,
    name: "widgets.topForeign.table.stockId",
    position: "START",
    sortBy: SORT_BY.STOCK_ID,
  },
  {
    width: 20,
    name: "widgets.topForeign.table.topValue",
    position: "END",
    sortBy: SORT_BY.TOP_VALUE_BUY,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_VALUE_SELL = [
  {
    width: 35,
    name: "widgets.topForeign.table.stockId",
    position: "START",
    sortBy: SORT_BY.STOCK_ID,
  },
  {
    width: 20,
    name: "widgets.topForeign.table.topValue",
    position: "END",
    sortBy: SORT_BY.TOP_VALUE_SELL,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_SELLFOREIGN_QTY = [
  {
    width: 35,
    name: "widgets.topForeign.table.stockId",
    position: "START",
    sortBy: SORT_BY.STOCK_ID,
  },
  {
    width: 20,
    name: "widgets.topForeign.table.topValueMass",
    position: "END",
    sortBy: SORT_BY.QTY_SELLFOREIGN,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const COLUMNS_BUYFOREIGN_QTY = [
  {
    width: 35,
    name: "widgets.topForeign.table.stockId",
    position: "START",
    sortBy: SORT_BY.STOCK_ID,
  },
  {
    width: 20,
    name: "widgets.topForeign.table.topValueMass",
    position: "END",
    sortBy: SORT_BY.QTY_BUYFOREIGN,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.price",
    position: "END",
    sortBy: SORT_BY.PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPrice",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE,
  },
  {
    width: 15,
    name: "widgets.topForeign.table.changedPricePercentage",
    position: "END",
    sortBy: SORT_BY.CHANGED_PRICE_PERCENTAGE,
  },
];

export const TIME_BLINK = 600;
