import styled from "styled-components/macro";
export const Wrap = styled.div<{ mixStyleProInvestor: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  p {
    ${({ mixStyleProInvestor }) =>
      mixStyleProInvestor && `padding-top: 5px; min-width: 90px;`};
    margin: 0px;
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    color: ${({ theme }) => theme.grey20};
    @media screen and (max-width: 1400px) {
      min-width: 140px;
    }
    span {
      font-size: ${({ theme }) => theme.typeFaces.body.size};
      color: ${({ theme }) => theme.solidRed};
    }
  }
  .content {
    width: 100%;
    max-width: 240px;
    ${({ mixStyleProInvestor }) => mixStyleProInvestor && ` max-width: 250px;`};
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    color: ${({ theme }) => theme.textcolorWhite};
    line-height: ${({ theme }) => theme.typeFaces.body.lineHeight};
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    padding: 5px 0px;
    span {
      text-align: right;
    }
  }
  .icon-info {
    width: 19px;
    height: 19px;
    cursor: pointer;
    @media screen and (max-width: 1400px) {
      top: 133px;
      left: 78px;
    }
    @media screen and (max-width: 1062px) {
      top: 144px;
      left: 74px;
    }
  }
`;
