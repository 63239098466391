import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";
import { Button, CheckBox } from "components/commons";
import { EntrustBusinessCode, GetAccountType } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  addTransferInternal,
  closeModalStatusRequest,
  editCheckModal,
  FormSubmit,
} from "modules/transfer-internal/redux";
import {
  statusModal,
  formSubmit,
  transferDEtaiAccountGet,
  selectCheckModal,
} from "modules/transfer-internal/selectors";
import numeral from "numeral";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import {
  ActionsRow,
  BodyModal,
  BodyModalRow,
  ButtonCell,
  CustomHr,
  DivCheckbox,
  HeaderModal,
  Icon,
  LabelCell,
  ModalCloseButton,
  ModalTitle,
  TextCell,
  Wrapper,
} from "./styles";

interface PROPS {
  clickResetHandler: () => void;
}

const ConfirmModal = (props: PROPS) => {
  const { clickResetHandler } = props;
  const dispatch = useDispatch();

  const isVisible = useSelector(statusModal);
  const { transferInternal } = useSelector(selectCheckModal);

  const closeModalHandler = () => {
    dispatch(closeModalStatusRequest());
  };
  const accountGet = useSelector(transferDEtaiAccountGet);
  const form = useSelector(formSubmit);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Cash
  );

  const clickAgreeButtonHandler = () => {
    const childFromFound = listAcc.find(
      (child) => child.subAccoNo === form?.subAccoNo
    );
    const childToFound = listAcc.find(
      (child) => child.subAccoNo === form?.subAccoTo
    );
    const params: FormSubmit = {
      ...form,
      subAccoCd: Number(childFromFound?.subAccoCd),
      toSubAccoCd: Number(childToFound?.subAccoCd),
    };
    dispatch(addTransferInternal(params));
    closeModalHandler();
    clickResetHandler();
  };

  const [checked, setchecked] = useState<boolean>(false);

  const saveCheckModal = () => {
    setchecked(!checked);
    dispatch(
      editCheckModal({
        key: "transferInternal",
        value: !checked,
      })
    );
  };

  useEffectOnce(() => {
    if (transferInternal) {
      clickAgreeButtonHandler();
    }
  });

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle>
            <FormattedMessage id="transferLocal.confirmModal.title" />
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.transferInternal.detail.modal.accountTransfe" />
            </LabelCell>
            <TextCell>{form?.subAccoNo}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.transferInternal.detail.modal.accountGet" />
            </LabelCell>
            <TextCell>
              {/* {itemCalculate?.subAccoToName} */}
              {form?.subAccoTo}
            </TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.transferInternal.detail.modal.nameAccountGet" />
            </LabelCell>
            <TextCell>{accountGet?.[0]}</TextCell>
          </BodyModalRow>
          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.transferInternal.detail.modal.amount" />
            </LabelCell>
            <TextCell>
              {/* {formModal?.remarks} */}
              {numeral(form?.amount).format("0,0")}
            </TextCell>
          </BodyModalRow>

          <BodyModalRow>
            <LabelCell>
              <FormattedMessage id="transferInternal.transferInternal.detail.modal.content" />
            </LabelCell>
            <TextCell>{form?.remarks}</TextCell>
          </BodyModalRow>
          <CustomHr />

          <DivCheckbox>
            <CheckBox checked={checked} onChange={() => saveCheckModal()} />{" "}
            <FormattedMessage id="transferLocal.confirmModal.notes" />
          </DivCheckbox>

          <ActionsRow>
            <ButtonCell>
              <Button
                className="button-confirm"
                color="accept"
                onClick={closeModalHandler}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.cancel" />
              </Button>
            </ButtonCell>

            <ButtonCell>
              <Button
                className="button-confirm"
                color="gradientaAccept"
                onClick={clickAgreeButtonHandler}
                width="100%"
              >
                <FormattedMessage id="transferLocal.confirmModal.accept" />
              </Button>
            </ButtonCell>
          </ActionsRow>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
