import React from "react";
import { useTable, useSortBy, Column } from "react-table";
import { ReactComponent as IconSortUp } from "assets/image/svg/ic_sort_up.svg";
import { ReactComponent as IconSortDown } from "assets/image/svg/ic_sort_down.svg";
import { ReactComponent as IconSortDefault } from "assets/image/svg/ic_sort_default.svg";
import { Table, Header, Body, Row, BoxTable } from "./styles";
import classNames from "classnames";
import NoData from "components/commons/CommonTable/NoData";

export type CommonTableProps<T extends object> = {
  columns: Array<Column<object>>;
  heightRow?: string;
  data: T[];
  loading?: boolean;
  isMarketTable?: boolean;
  fixedLayout?: boolean;
  border?: boolean;
  isLargeContent?: boolean;
  showIconNoData?: boolean;
};

function CommonTable<T extends object>({
  columns,
  heightRow,
  data,
  loading = false,
  isMarketTable,
  fixedLayout,
  border = false,
  isLargeContent,
  showIconNoData = true,
}: CommonTableProps<T>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data,
        initialState: {
          hiddenColumns: columns
            .filter((col: any) => col.hidden === true)
            .map((col) => col.id || col.accessor) as any,
        },
      },
      useSortBy
    );

  return (
    <BoxTable border={border}>
      <Table
        id="table"
        className={classNames(
          { "market-table": isMarketTable },
          { "table-fixed-layout": fixedLayout }
        )}
        striped={false}
        hover={false}
        size="sm"
        {...getTableProps()}
      >
        <Header className="header-widget-table market-header-table">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const SortIcon = (
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <IconSortDown />
                      ) : (
                        <IconSortUp />
                      )
                    ) : (
                      <IconSortDefault />
                    )}
                  </span>
                );
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      minWidth: column?.minWidth,
                    }}
                  >
                    {column.render("Header", { SortIcon })}
                  </th>
                );
              })}
            </tr>
          ))}
        </Header>
        <Body className="bodyWidgetTable" {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Row
                id={"row" + i.toString()}
                {...row.getRowProps()}
                isLarge={isLargeContent}
              >
                {row.cells.map((cell, i) => {
                  let lastChild: string = "";
                  if (i === row.cells.length - 1) {
                    lastChild = "last-child";
                  }
                  return (
                    <td
                      className={"cell" + i.toString() + " " + lastChild}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </Row>
            );
          })}
        </Body>
      </Table>
      {showIconNoData && data.length === 0 && <NoData />}
    </BoxTable>
  );
}

export default React.memo(CommonTable);
