import { FC } from "react";
import Search from "./search";
import ContentPages from "./content";
import { Container } from "./styles";

const AssetsIncurredContent: FC = () => {
  return (
    <Container>
      <Search />
      <ContentPages />
    </Container>
  );
};
export default AssetsIncurredContent;
