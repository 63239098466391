import {
  AnyAction,
  combineReducers,
  createAction,
  createSlice,
  PayloadAction,
  Reducer,
} from "@reduxjs/toolkit";
import { MrktSecInfoItem } from "domain/protoNew/auto_trading_pb";
import { LanguageType } from "languages/redux";
import cloneDeep from "lodash/cloneDeep";
import {
  columnsCoverWarrant,
  columnsCoverWarrantHead,
  columnsDataDefault,
  columnsForeign,
  columnsPrice,
  WATCH_LIST_FIXED_TABS,
} from "modules/categories/constant";
import {
  formatTickerOptionsNew,
  updatedMinWidthColumns,
} from "modules/categories/helpers/utils";
import {
  ChangeCurrentListPayload,
  ColManage,
  ListType,
  TickerOption,
  WATCH_LIST_TAB_NAME,
} from "modules/categories/types";
import { createTransform, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ReduxStateType } from "redux/types";
import categoriesInfoReducer from "./categoriesInfo";
import consoleReducer from "./console";
import coverWarrantReducer from "./coverWarrant";
import derivativeReducer from "./derivative";
import industryReducer from "./industry";
// import infoQuoteReducer from "./infoQuote";
import listedReducer from "./listed";
import placeOrderReducer from "./placeOrder";
import putThroughReducer from "./putThrough";
// import tickerInfoReducer from "./tickerInfo";
import volumeAndTimeReducer from "./volumeAndTime";
import oddLotReducer from "./oddLot";
export interface State {
  tickersNew: MrktSecInfoItem.AsObject[];
  listedNew: ListType[];
  derList: ListType[];
  cwListNew: ListType[];
  industriesListNew: ListType[];
  etfListNew: ListType[];
  oddLotListNew: ListType[];
  tickerOptions: TickerOption[];
  listed: ListType[];
  ownerList: ListType[];
  anonymousFavoriteList: ListType[];
  userFavoriteList: ListType[];
  currentListCode: WATCH_LIST_TAB_NAME;
  columns: ColManage;
  hideAbleColumns: ColManage;
  visibleModalEditCol: boolean;
  loading: boolean;
  status: ReduxStateType;
  isDeleteTicker: boolean;
  industriesList: ListType[];
  tickersListSelected: TickersListSelected;
  isExistingList: boolean;
  firstLoadPage: boolean;
  tickerFocus?: string;
}

export type IndustryOption = {
  label: string;
  value: string;
};

export type TickersListSelected = {
  type: string;
  name: string;
};

const initialState: State = {
  tickersNew: [],
  listedNew: [],
  derList: [],
  cwListNew: [],
  industriesListNew: [],
  etfListNew: [],
  oddLotListNew: [],
  isExistingList: false,
  tickerOptions: [],
  listed: [],

  ownerList: [],
  anonymousFavoriteList: [],
  userFavoriteList: [],
  currentListCode: "Listed",
  columns: cloneDeep(columnsDataDefault),
  hideAbleColumns: cloneDeep(columnsDataDefault),
  visibleModalEditCol: false,
  loading: true,
  isDeleteTicker: false,
  status: ReduxStateType.INIT,
  industriesList: [],
  tickersListSelected: {
    type: "",
    name: "",
  },
  firstLoadPage: true,
};

const TransformHideAbleList = createTransform(
  (inboundState: ColManage) => {
    return { ...inboundState };
  },
  (outboundState: ColManage, key) => {
    const hideAbleColumns = updatedMinWidthColumns(outboundState);
    return { ...hideAbleColumns };
  },
  { whitelist: ["hideAbleColumns"] }
);

function getInitialStateForColumns(activeTabCode: string, state: State) {
  if (activeTabCode === "CoverWarrant") {
    return cloneDeep({
      ...state.hideAbleColumns,
      ...columnsCoverWarrant,
      ...columnsCoverWarrantHead,
    });
  }

  return state.hideAbleColumns;
}

const hydrate = createAction<State>("persist/REHYDRATE");

const categoriesSlice = createSlice({
  name: "categoriesSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action: any) => {
      if (!action.payload) return;
      if (action.key === "categories:root") {
        const columnSetting = getInitialStateForColumns(
          action.payload.currentListCode,
          action.payload
        );
        if (columnSetting) {
          state.columns = columnSetting;
          state.hideAbleColumns = columnSetting;
        }
      }
    });
  },
  reducers: {
    getWatchListsOwnerStart: (state) => {
      state.firstLoadPage = true;
    },
    getWatchListsOwnerSuccess: (
      state,
      action: PayloadAction<{
        ownerList: ListType[];
        userFavoriteList: ListType[];
      }>
    ) => {
      const { ownerList, userFavoriteList } = action.payload;
      state.ownerList = ownerList;
      state.userFavoriteList = userFavoriteList;
    },
    getWatchListsStart: (state) => {
      state.status = ReduxStateType.LOADING;
      state.firstLoadPage = true;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    changeTickersListSelected: (
      state,
      action: PayloadAction<TickersListSelected>
    ) => {
      state.tickersListSelected = action.payload;
    },
    restoreTickersList: (
      state,
      action: PayloadAction<TickersListSelected>
    ) => {},
    synchronizedTableWithCurrentListCode: (state) => {
      state.loading = true;
    },
    getWatchListsSuccessNew: (
      state,
      action: PayloadAction<{
        tickers: MrktSecInfoItem.AsObject[];
        etfList: ListType[];
        listed: ListType[];
        derList: ListType[];
        cwList: ListType[];
        ownerList: ListType[];
        anonymousFavoriteList: ListType[];
        languageType: LanguageType;
        userFavoriteList: ListType[];
        industryTickersList: ListType[];
      }>
    ) => {
      const {
        tickers,
        etfList,
        listed,
        derList,
        cwList,
        languageType,
        industryTickersList,
      } = action.payload;

      state.tickersNew = tickers;
      state.tickerOptions = formatTickerOptionsNew(tickers, languageType);
      state.listedNew = listed;
      state.industriesListNew = industryTickersList;
      state.isExistingList = true;
      state.etfListNew = etfList;
      state.derList = derList;
      state.cwListNew = cwList;
      state.oddLotListNew = listed.slice(0, 3);
      // state.ownerList = ownerList;
      // state.userFavoriteList = userFavoriteList;
      // state.anonymousFavoriteList = [
      //   ...state.anonymousFavoriteList,
      //   ...anonymousFavoriteList,
      // ];
      state.status = ReduxStateType.SUCCESS;
    },
    getWatchListsFailed: (state, action) => {
      state.status = ReduxStateType.ERROR;
    },

    changeCurrentList: (
      state,
      action: PayloadAction<ChangeCurrentListPayload>
    ) => {
      state.loading = true;
      const { isDeleteTicker, name } = action.payload;
      state.isDeleteTicker = isDeleteTicker;
      state.currentListCode = name;

      delete state.hideAbleColumns.COVER_WARRANT;
      delete state.hideAbleColumns.COVER_WARRANT_HEAD;
      delete state.hideAbleColumns.FOREIGN;
      delete state.hideAbleColumns.PRICE;
      if (name !== WATCH_LIST_FIXED_TABS.ODDLOT) {
        const oddLotColumns = cloneDeep({
          ...state.hideAbleColumns,
          ...columnsPrice,
          ...columnsForeign,
        });
        state.hideAbleColumns = oddLotColumns;
      }
      if (name === "CoverWarrant") {
        const coverWarrantColumns = cloneDeep({
          ...state.hideAbleColumns,
          ...columnsCoverWarrant,
          ...columnsCoverWarrantHead,
        });
        state.columns = coverWarrantColumns;
        return;
      }
      state.columns = cloneDeep(state.hideAbleColumns);
    },

    createUserFavoriteListRequest: (state, action: PayloadAction<string>) => {},

    createUserFavoriteListSuccess: (
      state,
      { payload }: PayloadAction<ListType[]>
    ) => {
      state.userFavoriteList = payload;
    },

    createUserFavoriteListFailure: () => {},

    createAnonymousFavoriteList: (
      state,
      { payload }: PayloadAction<ListType>
    ) => {
      state.anonymousFavoriteList.push(payload);
    },

    editUserFavoriteListRequest: (
      state,
      action: PayloadAction<{ index: number; nameEdited: string }>
    ) => {},

    editUserFavoriteListSuccess: (
      state,
      { payload }: PayloadAction<ListType[]>
    ) => {
      state.userFavoriteList = payload;
    },

    editUserFavoriteListFailure: () => {},

    editAnonymousFavoriteList: (
      state,
      {
        payload: { index, nameEdited },
      }: PayloadAction<{ index: number; nameEdited: string }>
    ) => {
      state.anonymousFavoriteList[index].name = nameEdited;
    },

    addTickerStart: (state, action: PayloadAction<string>) => {},

    addTickerToListByName: (
      state,
      action: PayloadAction<{ tickerName: string; listName: string }>
    ) => {},

    addTickersToListByName: (
      state,
      action: PayloadAction<{ tickers: string[]; listName: string }>
    ) => {},

    addTickerToUserFavListSuccess: (
      state,
      action: PayloadAction<ListType[]>
    ) => {
      state.userFavoriteList = action.payload;
    },

    addTickerToUserFavListFailure: (state, action) => {},

    addTickerToAnonymousList: (
      state,
      {
        payload: { tickerName, listName },
      }: PayloadAction<{ tickerName: string; listName: string }>
    ) => {
      const index = state.anonymousFavoriteList.findIndex(
        (item) => item.name === listName
      );
      state.anonymousFavoriteList[index].listTickers.unshift(tickerName);
    },

    addTickersToAnonymousList: (
      state,
      {
        payload: { tickers, listName },
      }: PayloadAction<{ tickers: string[]; listName: string }>
    ) => {
      const index = state.anonymousFavoriteList.findIndex(
        (item) => item.name === listName
      );
      if (index === -1) return;

      const newTickers = tickers?.filter(
        (ticker) =>
          !state.anonymousFavoriteList[index].listTickers.includes(ticker)
      );

      if (newTickers.length === 0) return;

      state.anonymousFavoriteList[index].listTickers.unshift(...newTickers);
    },

    sortTickerList: (
      state,
      action: PayloadAction<{
        tickers: string[];
        listName: string;
        listId?: number[];
      }>
    ) => {},

    sortAnonymousList: (
      state,
      action: PayloadAction<{ tickers: string[]; listName: string }>
    ) => {
      const nameInd = state.anonymousFavoriteList.findIndex(
        (item) => item.name === action.payload.listName
      );

      if (nameInd === -1) return;

      state.anonymousFavoriteList[nameInd].listTickers = action.payload.tickers;
    },

    sortTickerUserFavListSuccess: (
      state,
      action: PayloadAction<ListType[]>
    ) => {
      state.userFavoriteList = action.payload;
    },

    sortTickerUserFavListFailure: (state) => {},

    removeFavoriteListRequest: (state, action: PayloadAction<ListType>) => {},

    removeAnonymousFavoriteList: (
      state,
      { payload }: PayloadAction<ListType>
    ) => {
      const index = state.anonymousFavoriteList.findIndex(
        (favoriteItem) =>
          favoriteItem.name === payload.name &&
          favoriteItem.subCode === payload.subCode
      );
      if (index !== -1) {
        state.anonymousFavoriteList.splice(index, 1);
      }
    },

    syncLocalFavoriteListToServerRequest: () => {},

    syncLocalFavoriteListToServerSuccess: (
      state,
      { payload }: PayloadAction<ListType[]>
    ) => {
      state.anonymousFavoriteList = [];
      state.userFavoriteList = payload;
    },

    removeUserFavoriteListSuccess: (
      state,
      action: PayloadAction<ListType[]>
    ) => {
      state.userFavoriteList = action.payload;
    },

    removeUserFavoriteListFailed: (
      state,
      action: PayloadAction<{ ticker: string; data: any }>
    ) => {},

    changeColumn: (state, action: PayloadAction<{ colummns: ColManage }>) => {
      let colummns = action.payload.colummns;
      // colummns = {
      //   ...colummns,
      //   LAST_SALE: {
      //     ...state.hideAbleColumns.LAST_SALE,
      //     children: {
      //       ...state.hideAbleColumns.LAST_SALE.children,
      //       CHANGE_PRICE: {
      //         ...state.hideAbleColumns.LAST_SALE.children!!.CHANGE_PRICE,
      //         visible: false,
      //       },
      //       CHANGE_PERCENTAGE: {
      //         ...state.hideAbleColumns.LAST_SALE.children!!.CHANGE_PERCENTAGE,
      //         visible: true,
      //       },
      //     },
      //   },
      // };
      if (
        state.currentListCode === "Derivative" ||
        state.currentListCode === "CoverWarrant"
      ) {
        colummns = {
          ...colummns,
          TICKER: {
            ...colummns.TICKER,
            minWidth: 90,
            smallDeviceMinWidth: 90,
          },
        };
        // if (currentListCode === "Derivative") {
        //   colummns = {
        //     ...colummns,
        //     LAST_SALE: {
        //       ...state.hideAbleColumns.LAST_SALE,
        //       children: {
        //         ...state.hideAbleColumns.LAST_SALE.children,
        //         CHANGE_PRICE: {
        //           ...state.hideAbleColumns.LAST_SALE.children!!.CHANGE_PRICE,
        //           visible: true,
        //         },
        //         CHANGE_PERCENTAGE: {
        //           ...state.hideAbleColumns.LAST_SALE.children!!
        //             .CHANGE_PERCENTAGE,
        //           visible: false,
        //         },
        //       },
        //     },
        //   };
        // }
      } else {
        colummns = {
          ...colummns,
          TICKER: {
            ...colummns.TICKER,
            minWidth: 64,
            smallDeviceMinWidth: 48,
          },
        };
      }
      state.hideAbleColumns = cloneDeep(colummns);
      state.columns = { ...state.columns, ...colummns };
    },

    changeVisibleModalEditCol: (state, action: PayloadAction<boolean>) => {
      state.visibleModalEditCol = action.payload;
    },

    removeTicker: (
      state,
      action: PayloadAction<{ tickerName: string; listCode?: string }>
    ) => {},
    removeTickerFromAnonymousList: (
      state,
      { payload: tickerName }: PayloadAction<string>
    ) => {
      const index = state.anonymousFavoriteList.findIndex(
        (item) => item.name === state.currentListCode
      );
      if (index === -1) return;

      const indexToDelete = state.anonymousFavoriteList[
        index
      ].listTickers.findIndex((item) => item === tickerName);

      state.anonymousFavoriteList[index].listTickers.splice(indexToDelete, 1);
    },
    removeTickerFromUserFavListSuccess: (
      state,
      action: PayloadAction<ListType[]>
    ) => {
      state.userFavoriteList = action.payload;
    },

    removeTickerFromUserFavListFailure: (
      state,
      action: PayloadAction<string>
    ) => {},
    switchVisibleLastSaleChangeColumn: (state) => {
      if (
        !state.columns?.LAST_SALE?.children?.CHANGE_PRICE ||
        !state.columns?.LAST_SALE?.children?.CHANGE_PERCENTAGE
      ) {
        return;
      }
      const visibleValue: boolean | undefined =
        state.columns?.LAST_SALE?.children?.CHANGE_PRICE.visible;

      state.columns = {
        ...state.columns,
        LAST_SALE: {
          ...state.columns.LAST_SALE,
          children: {
            ...state.columns.LAST_SALE.children,
            CHANGE_PRICE: {
              ...state.columns?.LAST_SALE?.children?.CHANGE_PRICE,
              visible: !visibleValue,
            },
            CHANGE_PERCENTAGE: {
              ...state.columns?.LAST_SALE?.children?.CHANGE_PERCENTAGE,
              visible: visibleValue,
            },
          },
        },
      };

      state.hideAbleColumns = cloneDeep(state.columns);
    },
    switchVisibleChangeColumnSummaryTotal: (state) => {
      if (!state.columns?.VOLUME || !state.columns?.AMT) {
        return;
      }
      const visibleValue: boolean | undefined = state.columns?.VOLUME.visible;
      state.columns = {
        ...state.columns,
        VOLUME: {
          ...state.columns?.VOLUME,
          visible: !visibleValue,
        },
        AMT: {
          ...state.columns?.AMT,
          visible: visibleValue,
        },
      };

      state.hideAbleColumns = cloneDeep(state.columns);
    },
    changeFirstLoadPage: (state, action: PayloadAction<boolean>) => {
      state.firstLoadPage = action.payload;
    },
    setTickerFocus: (state, action: PayloadAction<string | undefined>) => {
      state.tickerFocus = action.payload;
    },
  },
});

const categoriesPersistConfig = {
  key: "categories:root",
  storage: storage,
  blacklist: [
    "userFavoriteList",
    "columns",
    "firstLoadPage",
    "loading",
    "tickerFocus",
  ],
  transforms: [TransformHideAbleList],
};

export const {
  getWatchListsOwnerStart,
  getWatchListsOwnerSuccess,
  getWatchListsStart,
  getWatchListsFailed,
  changeCurrentList,
  createUserFavoriteListRequest,
  createUserFavoriteListFailure,
  createUserFavoriteListSuccess,
  createAnonymousFavoriteList,
  editUserFavoriteListRequest,
  editUserFavoriteListFailure,
  editUserFavoriteListSuccess,
  editAnonymousFavoriteList,
  removeFavoriteListRequest,
  removeUserFavoriteListSuccess,
  removeUserFavoriteListFailed,
  removeAnonymousFavoriteList,
  addTickerStart,
  addTickerToUserFavListSuccess,
  addTickerToUserFavListFailure,
  addTickerToAnonymousList,
  addTickersToAnonymousList,
  changeColumn,
  changeVisibleModalEditCol,
  changeLoading,
  removeTicker,
  removeTickerFromAnonymousList,
  removeTickerFromUserFavListSuccess,
  removeTickerFromUserFavListFailure,
  synchronizedTableWithCurrentListCode,
  syncLocalFavoriteListToServerRequest,
  syncLocalFavoriteListToServerSuccess,
  addTickerToListByName,
  addTickersToListByName,
  changeTickersListSelected,
  restoreTickersList,
  switchVisibleLastSaleChangeColumn,
  switchVisibleChangeColumnSummaryTotal,
  sortAnonymousList,
  sortTickerList,
  sortTickerUserFavListSuccess,
  sortTickerUserFavListFailure,
  changeFirstLoadPage,
  getWatchListsSuccessNew,
  setTickerFocus,
} = categoriesSlice.actions;

export default combineReducers({
  root: persistReducer(
    categoriesPersistConfig,
    categoriesSlice.reducer
  ) as Reducer<State, AnyAction>,
  placeOrder: placeOrderReducer,
  listed: listedReducer,
  putThrough: putThroughReducer,
  industry: industryReducer,
  derivative: derivativeReducer,
  coverWarrant: coverWarrantReducer,
  // TODO
  // tickerInfo: tickerInfoReducer,
  // infoQuote: infoQuoteReducer,
  volumeAndTime: volumeAndTimeReducer,
  console: consoleReducer,
  categoriesInfo: categoriesInfoReducer,
  oddLot: oddLotReducer,
});
