import CommonTableInfinity from "components/commons/CommonTableInfinity";
import { marginSecuritiesListSelector } from "modules/margin-securities/selectors";
import moment from "moment";
import numeral from "numeral";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Container, HeaderCell, TableCellContent } from "./styles";

function Table() {
  const data = useSelector(marginSecuritiesListSelector);
  const flexStart = "flex-start";
  const flexEnd = "flex-end";
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="marginSecurities.table.secCd" />
          </HeaderCell>
        ),
        accessor: "secCd",
        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginSecurities.table.estimateRate" />
          </HeaderCell>
        ),
        accessor: "estimateRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginSecurities.table.marginRate" />
          </HeaderCell>
        ),
        accessor: "marginRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginSecurities.table.mLoanRate" />
          </HeaderCell>
        ),
        accessor: "mLoanRate",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginSecurities.table.minPrice" />
          </HeaderCell>
        ),
        accessor: "minPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexEnd}>
            <FormattedMessage id="marginSecurities.table.maxPrice" />
          </HeaderCell>
        ),
        accessor: "maxPrice",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexEnd}>
              {numeral(props.value).format("0,0.00")}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginSecurities.table.marketName" />
          </HeaderCell>
        ),
        accessor: "marketName",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>{props.value}</TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={flexStart}>
            <FormattedMessage id="marginSecurities.table.updDateTime" />
          </HeaderCell>
        ),
        accessor: "updDateTime",
        Cell: (props: any) => {
          return (
            <TableCellContent align={flexStart}>
              {moment(props.value).format("DD/MM/YYYY HH:mm:ss")}
            </TableCellContent>
          );
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {};

  return (
    <Container id="marginSecuritiesTable">
      <CommonTableInfinity
        fetchMoreData={fetchMoreData}
        columns={columns}
        data={data}
        enableInfinity={true}
        scrollableTarget={"marginSecuritiesTable"}
        isHasFooter={false}
      />
    </Container>
  );
}

export default memo(Table);
