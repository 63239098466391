import { ChartOptions } from "chart.js";
import { PieChartSingleLayerPolylinePlugin } from "helper/chartPlugins";
import numeral from "numeral";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import themes from "themes/abstracts/_themes";
import { themeTypeSelector } from "themes/redux";

export type FlowPieChartProps = {
  values: number[];
  colors: string[];
  labels: string[];
  format?: string;
  isAssetChart?: boolean;
};

const plugins = (themes: any) => {
  return [PieChartSingleLayerPolylinePlugin(themes)];
};

const FlowPieChart: React.FC<FlowPieChartProps> = (
  props: FlowPieChartProps
) => {
  const { values, colors, labels, format, isAssetChart } = props;
  const themeType = useSelector(themeTypeSelector);
  const data = {
    labels,
    datasets: [
      {
        data: [...values],
        backgroundColor: [...colors],
        borderColor: [...colors],
        polyline: {
          labelColor: themes[themeType].textcolorWhite,
          color: themes[themeType].textcolorWhite,
          formatter: (value: number) => {
            return `${format ? numeral(value).format(format) : value} %`;
          },
        },
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: isAssetChart ? 110 : 20,
        left: 0,
        right: 0,
        bottom: 20,
      },
    },
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (item: any, data: any) {
          const label = data.labels[item.index];
          const dataset = data.datasets[item.datasetIndex];
          const dataItem = dataset.data[item.index] ?? 0;
          return (
            label +
            ": " +
            (format ? numeral(dataItem).format(format) : dataItem) +
            " %"
          );
        },
      },
    },
    mode: "single",
  } as unknown as ChartOptions;

  return (
    <Doughnut
      data={data}
      options={options}
      plugins={plugins(themes[themeType])}
    />
  );
};

export default FlowPieChart;
