/* eslint-disable no-console */
import "firebase/messaging";
import "firebase/analytics";
import "firebase/remote-config";
import "firebase/firestore";
import firebase from "firebase/app";
import {
  VAPID_KEY,
  FIREBASE_apiKey,
  FIREBASE_authDomain,
  FIREBASE_databaseURL,
  FIREBASE_projectId,
  FIREBASE_storageBucket,
  FIREBASE_messagingSenderId,
  REACT_APP_FIREBASE_appId,
  FIREBASE_measurementId,
} from "AppConfig";
// import storage from "helper/storage";
// import { postInitNotification } from "core/http/apis/trans";
import moment from "moment";

const firebaseConfig = {
  apiKey: FIREBASE_apiKey,
  authDomain: FIREBASE_authDomain,
  databaseURL: FIREBASE_databaseURL,
  projectId: FIREBASE_projectId,
  storageBucket: FIREBASE_storageBucket,
  messagingSenderId: FIREBASE_messagingSenderId,
  appId: REACT_APP_FIREBASE_appId,
  measurementId: FIREBASE_measurementId,
};

firebase.initializeApp(firebaseConfig);

export const database = firebase.firestore();

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
const analytics = firebase.analytics();
export const remoteConfig = firebase.remoteConfig();

if (messaging !== null) {
  messaging.onMessage((payload) => {
    try {
      const noteTitle = payload.notification.title;
      const noteOptions = {
        body: payload.notification.body,
        // icon: "typewriter.jpg",
      };

      new Notification(noteTitle, noteOptions).onclick = function (event) {
        // function khi ấn click
        // if (
        //   payload &&
        //   payload.notification &&
        //   payload.notification.click_action &&
        //   payload.notification.click_action.length > 0
        // ) {
        //   window.open(payload.notification.click_action, "_blank");
        // }

        this.close();
      };
    } catch (err) {
      console.warn("Caught error: ", err);
    }
  });
}

export const startApp = () =>
  analytics.logEvent("start_app", { name: "hung", age: 19 });

export const getToken = (): string => {
  let currentToken = "";
  if (messaging === null) return "";
  messaging
    .getToken({
      vapidKey: VAPID_KEY,
    })
    .then(async (firebaseToken) => {
      if (firebaseToken) {
        currentToken = firebaseToken;

        try {
          //gọi initNotice để nhận noti từ server
          // const body = {
          //   masterAccount: storage.getMasterAccount().toString(),
          //   deviceId: "Web-trading",
          //   platform: 3,
          //   pushId: currentToken,
          // };
          // await postInitNotification(body);
        } catch (error: any) {
          console.warn(error);
        }

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.warn(
          "FIREBASE No registration token available. Request permission to generate one."
        );
        return false;
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.error("FIREBASE An error occurred while retrieving token. ", {
        err,
      });
      // catch error while creating client token
    });
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging === null) return "";
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export function writeNewLogFirebase(newData: any) {
  const myRef = database.collection(
    moment().format("YYYYMMDD") +
      "_" +
      newData.eventGroup +
      "_" +
      newData.screen
  );
  myRef.add(newData);
}
