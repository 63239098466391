import styled from "styled-components/macro";

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.neutral4};
  border: 1px solid ${({ theme }) => theme.neutral4};
  border-radius: 50%;
`;
