import styled from "styled-components/macro";
import { convertHexToRGBA } from "helper/utils";

export const DetailWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;

  width: 100%;
  color: ${({ theme }) => theme.grey0};
  .btn-place-order-buy {
    width: 100%;
    background: ${({ theme }) => theme.green50};
    :hover {
      background: ${({ theme }) => convertHexToRGBA(theme.green50, 0.7)};
    }
  }
  .btn-place-order-sell {
    width: 100%;
    background: ${({ theme }) => theme.red50};
    :hover {
      background: ${({ theme }) => convertHexToRGBA(theme.red50, 0.7)};
    }
  }
  .btn-place-order {
    color: ${({ theme }) => theme.grey0};
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typeFaces.body.size};
    flex: 1;
  }
  .text-white .input {
    color: ${({ theme }) => theme.grey0};
  }
  .text-white .input-container,
  .text-white .css-1jpjy99-control,
  .text-white .react-datepicker-wrapper {
    background: ${({ theme }) => theme.grey100} !important;
  }
`;

export const DetailCol = styled.div`
  flex: 1;
  padding: 0 8px;
  width: 100%;
`;
export const DetailColFlexEnd = styled.div`
  padding: 0 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 15px;
`;
export const DetailRow = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailRowButton = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;

export const DetailRowPaddingTB = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
`;
export const DetailRowPaddingSelect = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;
  width: 100%;
  padding: 4px 0px;
`;
export const DetailRowPaddingTBOption = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;
  width: 100%;
  padding: 4px 0px;
`;
export const DetailRowTextTop = styled.div`
  display: flex;
  padding: 8px 0px;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;
export const DetailLabel = styled.div`
  min-width: 120px;
  display: flex;
  color: ${({ theme }) => theme.grey20};
  margin-left: 8px;
`;

export const DetailInputWrapper = styled.div`
  margin-left: 20px;
  flex: 1;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
`;

export const DetailInputWrapperNoMargin = styled.div`
  flex: 1;
  text-align: right;
  color: ${({ theme }) => theme.grey0};
`;
export const DetailLabelAlert = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  line-height: 20px;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.red50};
  margin: 0 0.5rem;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  padding-bottom: 16px;
`;
export const CheckBoxText = styled.span`
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  margin-left: 5px;
`;

export const ColorCheckBoxText = styled.span<{
  color?: string;
}>`
  display: inline;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  color: ${({ color }) => color};
`;
export const Devided = styled.div`
  border: 1px dashed #2b3030;
  width: 100%;
`;
export const BuySellButtonRow = styled.div`
  display: flex;
  overflow: hidden;
  height: 48px;
  padding: 8px;
`;
export const ButtonItem = styled.div`
  flex: 1;
  position: relative;
`;
export const BuyButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 10px);
    color: ${({ isActive, theme }) => (isActive ? theme.grey0 : theme.grey50)};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.green50 : theme.grey90};
    transform: skewX(345deg);
    height: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: calc(100% - 10px);
    border-radius: 4px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.green50 : theme.grey90};
  }
`;

export const SellButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  position: absolute;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 10px);
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.red50 : theme.grey90};
    transform: skewX(345deg);
    height: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 30px;
    width: calc(100% - 10px);
    border-radius: 4px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.textcolorWhite : theme.grey50};
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.red50 : theme.grey90};
  }
`;
export const LabelBuySell = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typeFaces.body.size};
  font-weight: ${({ theme }) => theme.typeFaces.body.weight.regular};
  color: ${({ isActive, theme }) => (isActive ? theme.grey0 : theme.grey50)};

  :hover {
    color: ${({ theme }) => theme.grey0};
  }
`;
