import {
  Button,
  ModalConfirm,
  OTPByBusinessCd,
  Select,
} from "components/commons";
import {
  EBusinessCd,
  EntrustBusinessCode,
  GetAccountType,
} from "helper/consts";
import storage from "helper/storage";
import {
  requestCancelOrderList,
  requestGetOrderBook,
  getOrderBookSuccess,
  updateAccountSelect,
  updateOrderStatus,
  updateTradeType,
  updateSymbol,
  updateOrderBookRealTime,
  onCheckAll,
  changeSelectedRows,
  onReloadPlaceOrder,
  cancelOrderListStatusClear,
  clearFilter,
  updateConditionType,
  changeCondition,
  findRequestCond,
  findRequestPending,
  deleteRequestCondByList,
  cancelRequestPendingList,
} from "modules/order-book/redux/index";
import OrderBookConditionTable from "./OrderBookConditionTable";
import { memo, useEffect, useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import OrderBookTable from "./OrderBookTable";
import { OrderBookDisplay, OrderBookItem } from "domain/models/OrderBook";
import {
  OrderBookWrapper,
  SearchContainer,
  Control,
  Label,
  FooterContainer,
  FilterIcon,
  ActionContainer,
} from "./style";
import {
  isAuthenticatedSelector,
  currentAccountSelector,
} from "modules/auth/selectors";

import { checkCancelOrder } from "helper/tradingHelper";
import { toast } from "components/commons/Toast";
import { ReactComponent as IconFilter } from "assets/image/svg/filter.svg";
import { ReactComponent as IconRefresh } from "assets/image/svg/ic_refresh.svg";
import { useEffectOnce } from "react-use";
import InputSymbol from "components/commons/InputSymbol";
import {
  conditionSelector,
  orderBookListSelector,
  listSelectedRowSelector,
  filtersSelector,
  loadListSelector,
  cancelStatusSelector,
  listSelectedRowConditionSelector,
  listSelectedPendingRowSelector,
  orderBookConditionListSelector,
  orderBookPendingListSelector,
} from "modules/order-book/selectors";
import { IVerifyResponse } from "components/commons/OTPByBusinessCd";
import emitter, { EMMIT_ACTION_TYPE } from "helper/emitterWss";
import { GetAccoList } from "helper/sessionData";
import {
  IFindRequestCondParams,
  IFindRequestPendingParams,
} from "core/http/apis/tradeinfo/types";
import {
  ICancelOrdersParams,
  ICancelRequestPendingListParams,
  IDeleteRequestCondByListParams,
  IDeleteRequestCondParams,
} from "core/http/apis/orderexec/types";
import OrderBookPendingTable from "./OrderBookPendingTable";
import { RealtimeConst } from "helper/constFirebase";

export interface FilterParams {
  custodyCd?: string;
  subAccoNo?: string;
  secCd?: string;
  secType?: number;
  tradeType?: number;
  orderType?: string;
  extStatus?: string;
  searchByUser?: boolean;
  size?: number;
  page?: number;
}

const getTradeTypes = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "orderBook.all",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderBook.buy",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderBook.sell",
    }),
  },
];

const orderStatuses = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "orderBook.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderBook.REJECTED",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderBook.ENTERED",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderBook.WAIT_FILLED",
    }),
  },
  {
    value: "4",
    label: intl.formatMessage({
      id: "orderBook.PARTIALLY_FILLED",
    }),
  },
  {
    value: "5",
    label: intl.formatMessage({
      id: "orderBook.FILLED",
    }),
  },
  {
    value: "6",
    label: intl.formatMessage({
      id: "orderBook.PARTIALLY_CANCELLED",
    }),
  },
  {
    value: "7",
    label: intl.formatMessage({
      id: "orderBook.CANCELLED",
    }),
  },
  {
    value: "8",
    label: intl.formatMessage({
      id: "orderBook.REJECTED_DEPARTMENT",
    }),
  },
];

const orderStatusesCondition = (intl: IntlShape) => [
  {
    value: "0",
    label: intl.formatMessage({
      id: "orderBook.all",
    }),
  },
  {
    value: "1",
    label: intl.formatMessage({
      id: "orderBook.tableCondition.takeEffect",
    }),
  },
  {
    value: "2",
    label: intl.formatMessage({
      id: "orderBook.tableCondition.complete",
    }),
  },
  {
    value: "3",
    label: intl.formatMessage({
      id: "orderBook.tableCondition.expired",
    }),
  },
  {
    value: "9",
    label: intl.formatMessage({
      id: "orderBook.tableCondition.cancel",
    }),
  },
];

const orderStatusesPending = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({
      id: "orderBook.all",
    }),
  },
  {
    value: "P",
    label: intl.formatMessage({
      id: "orderBook.tablePending.status.waitApprove",
    }),
  },
  {
    value: "A",
    label: intl.formatMessage({
      id: "orderBook.tablePending.status.approved",
    }),
  },
  {
    value: "X",
    label: intl.formatMessage({
      id: "orderBook.tablePending.status.cancelled",
    }),
  },
  {
    value: "D",
    label: intl.formatMessage({
      id: "orderBook.tablePending.status.ctckCancel",
    }),
  },
];

const conditionList = (intl: IntlShape) => {
  return [
    {
      value: "01",
      label: intl.formatMessage({
        id: "orderBook.normal",
      }),
    },
    {
      value: "02",
      label: intl.formatMessage({
        id: "orderBook.conditionType.title",
      }),
    },
    {
      value: "03",
      label: intl.formatMessage({
        id: "orderBook.orderPending",
      }),
    },
  ];
};

const conditionTypeList = (intl: IntlShape) => [
  {
    value: "",
    label: intl.formatMessage({
      id: "orderBook.conditionType.all",
    }),
  },
  {
    value: "TCO",
    label: intl.formatMessage({
      id: "orderBook.conditionType.timeConditionOrder",
    }),
  },
  {
    value: "PRO",
    label: intl.formatMessage({
      id: "orderBook.conditionType.priorityOrder",
    }),
  },
  {
    value: "TS",
    label: intl.formatMessage({
      id: "orderBook.conditionType.trailingStopOrder",
    }),
  },
  {
    value: "SP",
    label: intl.formatMessage({
      id: "orderBook.conditionType.takeProfit",
    }),
  },
  {
    value: "CL",
    label: intl.formatMessage({
      id: "orderBook.conditionType.cutLoss",
    }),
  },
];

/**
 * List
 */
function OrderBook() {
  // useSelector
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearFilter());
    };
  }, []);

  const intl = useIntl();
  const TradeTypes = useMemo(() => getTradeTypes(intl), [intl]);
  const statusOptions = useMemo(() => orderStatuses(intl), [intl]);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const listCondition = useMemo(() => conditionList(intl), [intl]);

  const orderBookList = useSelector(orderBookListSelector);
  const orderBookConditionList = useSelector(orderBookConditionListSelector);
  const orderBookPendingList = useSelector(orderBookPendingListSelector);
  const condition = useSelector(conditionSelector);

  const {
    accountSelect,
    tradeType,
    orderStatus,
    symbol,
    conditionType,
    limit,
    offset,
  } = useSelector(filtersSelector);
  const loadList = useSelector(loadListSelector);
  const selectedOrders = useSelector(listSelectedRowSelector);
  const currentAccount = useSelector(currentAccountSelector);
  const cancelStatus = useSelector(cancelStatusSelector);
  const selectedOrdersCondition = useSelector(listSelectedRowConditionSelector);
  const selectedOrdersPending = useSelector(listSelectedPendingRowSelector);

  const listAcc = GetAccoList(
    GetAccountType.NONE_DERT_SUB_ACCOUNT,
    EntrustBusinessCode.Order
  );

  const accounts = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc, intl]);

  const [visibleModalOTP, setVisibleModalOTP] = useState(false);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffectOnce(() => {
    dispatch(updateTradeType(getTradeTypes(intl)[0]));
    dispatch(updateOrderStatus(orderStatuses(intl)[0]));
    dispatch(updateConditionType(conditionTypeList(intl)[0]));
    dispatch(changeCondition(listCondition[0]));
  });

  useEffect(() => {
    if (!confirm) return;
    handleSubmit();
    setConfirm(false);
  }, [confirm]);

  useEffect(() => {
    if (isAuthenticated) {
      if (currentAccount) {
        const accountCurrent = accounts.find(
          (item) => item.value === currentAccount?.subAccoNo
        );
        if (accountCurrent) {
          dispatch(updateAccountSelect(accountCurrent));
          onFilter(false, accountCurrent?.value);
        }
      } else {
        if (accounts && accounts.length > 0) {
          dispatch(updateAccountSelect(accounts[0]));
        }
      }
    } else {
      dispatch(getOrderBookSuccess({ items: [], offset: 0 }));
    }
  }, [currentAccount.subAccoNo, isAuthenticated, loadList, condition?.value]);

  const handleSubmit = () => {
    setVisibleModalOTP(true);
  };

  const doSubmitCancelOrder = async (response: IVerifyResponse) => {
    setVisibleModalOTP(false);
    if (condition.value === "01") {
      if (selectedOrders && selectedOrders.length > 0) {
        let sumSuccess = 0;
        let listParams: string[] = [];
        for (let i = 0; i < selectedOrders.length; i++) {
          if (await checkOrder(selectedOrders[i])) {
            sumSuccess += 1;
            const paramItem =
              selectedOrders[i].subAccoNo +
              ";" +
              selectedOrders[i].orgOrderNo +
              ";" +
              selectedOrders[i].marketCd +
              ";" +
              selectedOrders[i].secCd +
              ";" +
              selectedOrders[i].status;
            listParams.push(paramItem);
          }
        }

        // Huy thanh cong 3/5
        let content = `${intl.formatMessage({
          id: "orderBookDer.cancelSucces",
        })} ${sumSuccess}/${selectedOrders.length}`;

        if (sumSuccess === 0) {
          if (selectedOrders.length > 1 && !isError) {
            toast("common.errorDelete", "error");
          }
          return;
        }

        const ordersListParam: ICancelOrdersParams = {
          input: JSON.stringify(listParams),
          otpCode: response?.otp,
          otpType: response?.otpTypeSubmit!,
        };

        try {
          dispatch(
            requestCancelOrderList({
              params: ordersListParam,
              intl: intl,
              content: content,
            })
          );
        } catch (error: any) {
          toast("orderBookDer.cancelFail", "error");
        }
      } else {
        toast("orderBookDer.cancelFail", "error");
      }
    } else if (condition.value === "03") {
      if (selectedOrdersPending && selectedOrdersPending.length > 0) {
        let sumSuccess = 0;
        let paramsList: string[] = [];
        for (let i = 0; i < selectedOrdersPending.length; i++) {
          const params: string =
            selectedOrdersPending[i].tradeDate?.toString() +
            ":" +
            selectedOrdersPending[i].reqNo?.toString();
          paramsList.push(params);
          sumSuccess += 1;
        }
        // Huy thanh cong 3/5
        let content = `${intl.formatMessage({
          id: "orderBookDer.cancelSucces",
        })} ${sumSuccess}/${selectedOrdersPending.length}`;

        if (sumSuccess === 0) {
          toast("common.errorDelete", "error");
          return;
        }

        const paramCancelList: ICancelRequestPendingListParams = {
          input: paramsList,
          otpCode: response?.otp,
          otpType: response?.otpTypeSubmit!,
        };

        try {
          dispatch(
            cancelRequestPendingList({
              params: paramCancelList,
              intl: intl,
              content: content,
            })
          );

          const resLogData = {
            event: RealtimeConst.cancelOrderList,
            mesage: content,
          };
          storage.orderFirebase(resLogData);
        } catch (error: any) {
          toast("orderBookDer.cancelFail", "error");
        }
      }
    } else {
      if (selectedOrdersCondition && selectedOrdersCondition.length > 0) {
        let sumSuccess = 0;
        let paramsList: IDeleteRequestCondParams[] = [];
        for (let i = 0; i < selectedOrdersCondition.length; i++) {
          const params: IDeleteRequestCondParams = {
            custNo: selectedOrdersCondition[i].custNo,
            tradeDate: selectedOrdersCondition[i].tradeDate,
            id: selectedOrdersCondition[i].id,
            updDateTime: selectedOrdersCondition[i].updDateTime,
          };
          sumSuccess += 1;
          paramsList.push(params);
        }
        // Huy thanh cong 3/5
        let content = `${intl.formatMessage({
          id: "orderBookDer.cancelSucces",
        })} ${sumSuccess}/${selectedOrdersCondition.length}`;

        if (sumSuccess === 0) {
          toast("common.errorDelete", "error");
          return;
        }

        const paramCancelList: IDeleteRequestCondByListParams = {
          delRequestCondModels: paramsList,
          otpCode: response?.otp,
        };

        try {
          dispatch(
            deleteRequestCondByList({
              params: paramCancelList,
              intl: intl,
              content: content,
            })
          );
          const resLogData = {
            event: RealtimeConst.cancelOrderList,
            mesage: content,
          };
          storage.orderFirebase(resLogData);
        } catch (error: any) {
          toast("orderBookDer.cancelFail", "error");
        }
      }
    }
  };

  useEffect(() => {
    if (cancelStatus?.status === "success") {
      dispatch(onCheckAll(false));
      dispatch(changeSelectedRows([]));
      dispatch(onReloadPlaceOrder(true));
      dispatch(cancelOrderListStatusClear());
      onFilter(false);
    }

    if (cancelStatus?.status === "failed") {
      dispatch(cancelOrderListStatusClear());
    }
  }, [cancelStatus]);

  const handleModal = () => {
    setVisibleModalOTP(false);
  };

  const checkOrder = async (item: OrderBookDisplay) => {
    const result = await checkCancelOrder(item, intl);
    if (result === null) return true;
    toast(result, "error", "error.defaultErr", false);
    setIsError(true);
    return false;
  };

  // Lấy dataTable lọc theo điều kiện
  const onFilter = async (
    loadmore: boolean,
    accountChange?: string,
    tradeTypeChange?: string,
    orderStatusChange?: string,
    symbolChange?: string,
    conditionTypeChange?: string
  ) => {
    try {
      if (condition.value === "01") {
        if (!accounts || accounts.length === 0) {
          dispatch(getOrderBookSuccess({ items: [], offset: 0 }));
          return;
        }

        let currentOffset = offset ? offset : 0;
        if (!loadmore) {
          currentOffset = 0;
        }

        const params: FilterParams = {
          size: limit,
          page: currentOffset,
          // custodyCd: storage.getMasterAccount(),
          subAccoNo: accountChange
            ? accountChange
            : accountSelect && accountSelect.value !== ""
            ? accountSelect.value
            : accounts[0]?.value,
          secCd:
            symbolChange !== undefined && symbolChange !== ""
              ? symbolChange
              : symbol,
          secType: 0,
          orderType: "",
          extStatus:
            orderStatusChange !== undefined
              ? orderStatusChange
              : orderStatus.value,
          tradeType:
            tradeTypeChange !== undefined
              ? parseInt(tradeTypeChange)
              : parseInt(tradeType.value),
          searchByUser: true,
        };

        dispatch(
          requestGetOrderBook({
            params: params,
            statusVal: orderStatus.value,
            intl: intl,
            isRefestData: !loadmore,
          })
        );
      }

      if (condition.value === "02") {
        let currentOffset = offset ? offset : 0;
        if (!loadmore) {
          currentOffset = 0;
        }
        const params: IFindRequestCondParams = {
          custNo: storage.getMasterAccount(),
          subAccoNo: accountChange
            ? accountChange === storage.getMasterAccount()?.toString()
              ? ""
              : accountChange
            : accountSelect.value === storage.getMasterAccount()?.toString()
            ? ""
            : accountSelect.value,
          secCd: symbolChange !== undefined ? symbolChange : symbol,
          tradeType:
            tradeTypeChange !== undefined
              ? parseInt(tradeTypeChange)
              : parseInt(tradeType.value),
          condType:
            conditionTypeChange !== undefined
              ? conditionTypeChange
              : conditionType.value,
          fromDate: null,
          toDate: null,
          status:
            orderStatusChange !== undefined
              ? Number(orderStatusChange)
              : orderStatus.value !== ""
              ? Number(orderStatus.value)
              : 0,
          offset: currentOffset,
          limit: limit,
        };

        dispatch(
          findRequestCond({
            params: params,
            intl: intl,
            isRefestData: !loadmore,
          })
        );
      }

      if (condition.value === "03") {
        let currentOffset = offset ? offset : 0;
        if (!loadmore) {
          currentOffset = 0;
        }
        const params: IFindRequestPendingParams = {
          subAccoNo: accountChange
            ? accountChange === storage.getMasterAccount()?.toString()
              ? ""
              : accountChange
            : accountSelect.value === storage.getMasterAccount()?.toString()
            ? ""
            : accountSelect.value,
          secCd: symbolChange !== undefined ? symbolChange : symbol,
          marketCd: null,
          tradeType:
            tradeTypeChange !== undefined
              ? parseInt(tradeTypeChange)
              : parseInt(tradeType.value),
          status:
            orderStatusChange !== undefined
              ? orderStatusChange
              : orderStatus.value !== "" && orderStatus.value !== "0"
              ? orderStatus.value
              : "",
          size: limit,
          page: currentOffset,
        };

        dispatch(
          findRequestPending({
            params: params,
            intl: intl,
            isRefestData: !loadmore,
          })
        );
      }
    } catch (error: any) {}
  };

  useEffectOnce(() => {
    const eventListenerOrder = (data: OrderBookItem) => {
      dispatch(updateOrderBookRealTime(data));
    };
    emitter.on(EMMIT_ACTION_TYPE.EMMIT_Order, eventListenerOrder);
    return () => {
      emitter.off(EMMIT_ACTION_TYPE.EMMIT_Order, eventListenerOrder);
    };
  });

  const handleCancelOrder = () => {
    if (isAuthenticated) {
      if (condition.value === "01") {
        if (selectedOrders && selectedOrders.length > 0) {
          setVisibleModalConfirm(true);
        } else {
          toast("orderBook.errorCheckCancel", "error");
        }
      }

      if (condition.value === "02") {
        if (selectedOrdersCondition && selectedOrdersCondition.length > 0) {
          setVisibleModalConfirm(true);
        } else {
          toast("orderBook.errorCheckCancel", "error");
        }
      }

      if (condition.value === "03") {
        if (selectedOrdersPending && selectedOrdersPending.length > 0) {
          setVisibleModalConfirm(true);
        } else {
          toast("orderBook.errorCheckCancel", "error");
        }
      }
    }
  };

  const handleChangeSymbol = (value: string) => {
    dispatch(updateSymbol(value));
    onFilter(false, undefined, undefined, undefined, value);
  };

  const accValue = () => {
    if (isAuthenticated) {
      return accounts.find((item: any) => item.value === accountSelect.value);
    } else {
      return null;
    }
  };

  return (
    <OrderBookWrapper id="orderBook">
      <SearchContainer isShow={showFilter}>
        <Control>
          <Label>
            <FormattedMessage id="orderBook.condition" />
          </Label>
          <div className="flex-fill-select">
            <Select
              options={listCondition}
              value={condition}
              onChange={(item) => {
                dispatch(changeCondition(item));
                dispatch(changeSelectedRows([]));
                dispatch(clearFilter());
              }}
            />
          </div>
        </Control>
        <Control className="account">
          <Label>
            <FormattedMessage id="orderBook.account" />
          </Label>
          <div className="flex-fill-select">
            <Select
              value={accValue()}
              options={accounts}
              onChange={(item) => {
                dispatch(updateAccountSelect(item));
                onFilter(false, item?.value);
              }}
            ></Select>
          </div>
        </Control>
        <Control>
          <Label>
            <FormattedMessage id="orderBook.symbol" />
          </Label>
          <div className="flex-fill-select">
            <InputSymbol
              value={symbol}
              onChange={handleChangeSymbol}
              isOnlyShareCode
            />
          </div>
        </Control>
        <Control className="tradeType">
          <Label>
            <FormattedMessage id="orderBook.table.type" />
          </Label>
          <div className="flex-fill-select">
            <Select
              value={tradeType}
              options={TradeTypes}
              onChange={(item) => {
                dispatch(updateTradeType(item));
                onFilter(false, undefined, item?.value);
              }}
            />
          </div>
        </Control>
        {condition?.value === "02" && (
          <Control>
            <Label>
              <FormattedMessage id="orderBook.conditionType.title" />
            </Label>
            <div className="flex-fill-select">
              <Select
                options={conditionTypeList(intl)}
                value={conditionType}
                onChange={(item) => {
                  dispatch(updateConditionType(item));
                  onFilter(
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    item?.value
                  );
                }}
              />
            </div>
          </Control>
        )}
        {condition.value === "01" && (
          <Control className="status">
            <Label>
              <FormattedMessage id="orderBook.table.status" />
            </Label>
            <div className="flex-fill-select">
              <Select
                options={statusOptions}
                value={orderStatus}
                onChange={(item) => {
                  dispatch(updateOrderStatus(item));
                  onFilter(false, undefined, undefined, item?.value);
                }}
              />
            </div>
          </Control>
        )}
        {condition.value === "02" && (
          <Control>
            <Label>
              <FormattedMessage id="orderBook.table.status" />
            </Label>
            <div className="flex-fill-select">
              <Select
                options={orderStatusesCondition(intl)}
                value={orderStatus}
                onChange={(item) => {
                  dispatch(updateOrderStatus(item));
                  onFilter(false, undefined, undefined, item?.value);
                }}
              />
            </div>
          </Control>
        )}
        {condition.value === "03" && (
          <Control>
            <Label>
              <FormattedMessage id="orderBook.table.status" />
            </Label>
            <div className="flex-fill-select">
              <Select
                options={orderStatusesPending(intl)}
                value={orderStatus}
                onChange={(item) => {
                  dispatch(updateOrderStatus(item));
                  onFilter(false, undefined, undefined, item?.value);
                }}
              />
            </div>
          </Control>
        )}
      </SearchContainer>

      {condition.value === "01" && (
        <OrderBookTable
          data={orderBookList!}
          fetchMoreData={() => onFilter(true)}
          heightFooter={selectedOrders && selectedOrders.length > 0 ? 50 : 0}
        />
      )}
      {condition.value === "02" && (
        <OrderBookConditionTable
          data={orderBookConditionList!}
          fetchMoreData={() => onFilter(true)}
          heightFooter={
            selectedOrdersCondition && selectedOrdersCondition.length > 0
              ? 50
              : 0
          }
        />
      )}
      {condition.value === "03" && (
        <OrderBookPendingTable
          data={orderBookPendingList!}
          fetchMoreData={() => onFilter(true)}
          heightFooter={
            selectedOrdersPending && selectedOrdersPending.length > 0 ? 50 : 0
          }
        />
      )}

      <FooterContainer>
        <Button color="danger" style={{ flex: 1 }} onClick={handleCancelOrder}>
          <FormattedMessage id="orderBook.cancel" />
        </Button>
        <ActionContainer>
          {isAuthenticated && (
            <FilterIcon onClick={() => onFilter(false)}>
              <IconRefresh />
            </FilterIcon>
          )}

          <FilterIcon onClick={() => setShowFilter(!showFilter)}>
            <IconFilter />
          </FilterIcon>
        </ActionContainer>
      </FooterContainer>

      {visibleModalOTP && (
        <OTPByBusinessCd
          handleClose={handleModal}
          getResult={doSubmitCancelOrder}
          businessCd={EBusinessCd.TRADING}
        />
      )}
      {!visibleModalOTP && (
        <ModalConfirm
          visibleModalConfirm={visibleModalConfirm}
          setVisibleModalConfirm={setVisibleModalConfirm}
          setConfirm={setConfirm}
          title={intl.formatMessage({
            id: "orderBook.confirm",
          })}
          content={intl.formatMessage({
            id: "orderBook.contentConfirm",
          })}
        />
      )}
    </OrderBookWrapper>
  );
}

export default memo(OrderBook);
