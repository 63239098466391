import queryString from "query-string";
import {
  GetOrderBookParam,
  GetStockPriceParam,
  IFindDertOrderByFilterParams,
  IFindDertOrderCondParams,
  IFindDertOrderDetailParams,
  IFindDertRequestCondParams,
  IFindOrderRequestCondParams,
  IFindOrderDetailParams,
  IFindRequestPendingParams,
  IDertCancelMultiOrderParams,
} from "./types";

export const securitiesStatusUrl = "/securitiesStatus/symbols";
export const tradingSessionUrl = "/tradingSession";
export const ordersStatusUrl = "/ordersStatus/account";
export const ordersStatusDetailUrl = "/ordersStatus/transIds";

export const orderBookDerUrl = (account: string, orderCategory: string) =>
  `/ordersStatus/account/${account}/${orderCategory}`;

// API Orderbook
// export const getOrderBookAPI = "/findOrder";
export const getOrderBookAPI = (params: GetOrderBookParam) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// Thong tin ma chung khoan
// export const getStockPriceApi = "/getQuotesForOrder";
export const getStockPriceApi = (params: GetStockPriceParam) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/getQuotesForOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

//API Order detail
export const findOrderDetailUrl = (params: IFindOrderDetailParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrderDetail${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

//API Order detail cond
export const findOrderRequestCondUrl = (
  params: IFindOrderRequestCondParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findOrderRequestCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// API Orderbook pending list
export const findRequestPendingUrl = (params: IFindRequestPendingParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findRequestPending${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

// API Orderbook Cond List
// export const findRequestCondUrl = (params: IFindRequestCondParams) => {
//   const paramsFormatter = queryString.stringify(params, {
//     skipNull: true,
//   });
//   return `/findRequestCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
// };
export const findRequestCondUrl = "/findRequestCond";

// API Orderbook
export const getOrderBookSummaryAPI = "/summaryOrders";

//OrderbookDer --- start ---

export const findDertOrderByFilterUrl = (
  params: IFindDertOrderByFilterParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertOrderByFilter${
    paramsFormatter ? `?${paramsFormatter}` : ""
  }`;
};

export const findDertOrderDetailUrl = (params: IFindDertOrderDetailParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertOrderDetail${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const dertEditOrderUrl = `/dertEditOrder`;

export const dertCancelOrderUrl = `/dertCancelOrder`;

export const dertCancelMultiOrderUrl = (
  params: IDertCancelMultiOrderParams
) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/dertCancelMultiOrder${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findDertRequestCondUrl = (params: IFindDertRequestCondParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertRequestCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const findDertOrderCondUrl = (params: IFindDertOrderCondParams) => {
  const paramsFormatter = queryString.stringify(params, {
    skipNull: true,
  });
  return `/findDertOrderCond${paramsFormatter ? `?${paramsFormatter}` : ""}`;
};

export const cancelDertRequestCondUrl = `/cancelDertRequestCond`;

//OrderbookDer --- start ---
