import { useMemo } from "react";

import { ReactComponent as IconExit } from "assets/image/svg/ic_exit2.svg";

import { updateDetailModalStatusRequest } from "modules/deposit-contract/redux";
import {
  isShowDetailModalSelector,
  depositContractDetailModalSelector,
} from "modules/deposit-contract/selectors";

import numeral from "numeral";

import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { DepositContract } from "domain/models/DepositContract";
import {
  BodyModal,
  HeaderModal,
  Icon,
  ModalCloseButton,
  ModalTitle,
  Wrapper,
  Container,
  HeaderCell,
  TableCellContent,
} from "./styles";

import { convertNumberToDateFormat } from "helper/utils";
import CommonTableInfinity from "components/commons/CommonTableInfinity";

interface Props {
  itemDetailRow: DepositContract;
}

function DetailModal({ itemDetailRow }: Props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isVisible = useSelector(isShowDetailModalSelector);
  const data = useSelector(depositContractDetailModalSelector);
  // const { orderType } = useSelector(filterOrderHistorySelector);
  const closeModalHandler = () => {
    dispatch(updateDetailModalStatusRequest({ isVisible: false }));
  };
  const fetchMoreData = () => {};
  const center = "center";
  const columns = useMemo(
    () => [
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.detailModal.endDate" />
          </HeaderCell>
        ),
        accessor: "endDate",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {convertNumberToDateFormat({ date: props.value })}
            </TableCellContent>
          );
        },

        // Footer: "",
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.detailModal.payedInterestAmt" />
          </HeaderCell>
        ),
        accessor: "payedInterestAmt",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
      {
        Header: (
          <HeaderCell align={center}>
            <FormattedMessage id="depositContract.detailModal.amount" />
          </HeaderCell>
        ),
        accessor: "amount",

        Cell: (props: any) => {
          return (
            <TableCellContent align={center}>
              {numeral(props.value).format("0,0")}{" "}
              {/* {props?.row?.original?.currency} */}
            </TableCellContent>
          );
        },
      },
    ],
    [intl]
  );

  return (
    <Modal
      show={isVisible}
      centered
      backdrop
      keyboard={false}
      onHide={closeModalHandler}
      dialogClassName="modal-450"
    >
      <Wrapper>
        <HeaderModal>
          <ModalTitle className="text-left">
            Chi tiết {itemDetailRow.contractNo}
          </ModalTitle>
          <ModalCloseButton>
            <Icon onClick={closeModalHandler}>
              <IconExit />
            </Icon>
          </ModalCloseButton>
        </HeaderModal>

        <BodyModal>
          <Container id="orderHistoryModalTable">
            <CommonTableInfinity
              fetchMoreData={fetchMoreData}
              columns={columns}
              data={data ?? []}
              enableInfinity={true}
              scrollableTarget={"orderHistoryModalTable"}
              isHasFooter={false}
            />
          </Container>
        </BodyModal>
      </Wrapper>
    </Modal>
  );
}

export default DetailModal;
