import { createSelector } from "@reduxjs/toolkit";
import { IScreenerFilter } from "domain/models/Screener";
// import { EOrder, ESortBy } from "domain/proto/service_screener_pb";
import {
  generateRequestFilterDoFilter,
  ORDER_FIELD_TYPE,
  ORDER_TYPE,
} from "modules/screeners/constants";
import {
  changeActiveSort,
  doFilterRequest,
  getAllTickerToAddWatchlistRequest,
  ReduxData as ScreenersState,
} from "modules/screeners/redux";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import BodyCard from "./BodyCard";
import HeaderCard from "./HeaderCard";
import { Container } from "./styles";

const screenerSelector = (state: RootState) => state.screeners;
const rootSelector = (state: RootState) => state;

const languageTypeSelector = createSelector(
  rootSelector,
  (state: RootState) => state.language.type
);

const screenerFilterListSelector = createSelector(
  screenerSelector,
  (screenerSelector: ScreenersState) => screenerSelector.dataScreener.filterList
);

const myScreenerSelectedSelector = createSelector(
  screenerSelector,
  (screenerSelector: ScreenersState) =>
    screenerSelector.dataMyScreener.screenerSelected
);

const totalResultScreenerSelector = createSelector(
  screenerSelector,
  (screenerSelector: ScreenersState) => screenerSelector.dataResultScreen.total
);

const ResultDisplayCard: FC = () => {
  const dispatch = useDispatch();
  const screenerFilterList = useSelector(screenerFilterListSelector);
  const myScreenerSelected = useSelector(myScreenerSelectedSelector);
  const language = useSelector(languageTypeSelector);
  const totalResultScreener = useSelector(totalResultScreenerSelector);

  useEffect(() => {
    dispatch(
      changeActiveSort({
        order: ORDER_TYPE.UNKNOWN, //EOrder.ORDER_UNKNOWN,
        sortBy: ORDER_FIELD_TYPE.UNKNOWN, //ESortBy.SORTBY_UNKNOWN,
      })
    );

    let currentFilterList: IScreenerFilter[];
    if (myScreenerSelected) {
      currentFilterList =
        myScreenerSelected.screenerFilter as IScreenerFilter[];
    } else {
      currentFilterList = screenerFilterList as IScreenerFilter[];
    }

    const requestFilter = generateRequestFilterDoFilter(currentFilterList);

    dispatch(doFilterRequest(requestFilter));
  }, [screenerFilterList, language, myScreenerSelected]);

  useEffect(() => {
    let currentFilterList: IScreenerFilter[];
    if (myScreenerSelected) {
      currentFilterList =
        myScreenerSelected.screenerFilter as IScreenerFilter[];
    } else {
      currentFilterList = screenerFilterList as IScreenerFilter[];
    }
    const requestFilter = generateRequestFilterDoFilter(
      currentFilterList,
      totalResultScreener
    );

    dispatch(getAllTickerToAddWatchlistRequest(requestFilter));
  }, [totalResultScreener]);

  return (
    <Container>
      <HeaderCard />
      <BodyCard />
    </Container>
  );
};

export default ResultDisplayCard;
