import { Button, DatePicker } from "components/commons";
import FilterItem from "components/commons/FormStyle/Filter/FilterItem";
import CSelect from "components/commons/Select";
import { toast } from "components/commons/Toast";
import { EntrustBusinessCode } from "helper/consts";
import { GetAccoList } from "helper/sessionData";
import {
  searchListSecurityStatementRequest,
  updateSecurityStatementFilter,
} from "modules/account-statement/redux";
import {
  filterSecurityStatementSelector,
  loadingSelector,
} from "modules/account-statement/selectors";
import moment from "moment";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer, WrapBoder } from "../MoneyStatementFilter/styles";
import storage from "helper/storage";
import { GroupEvent, RealtimeConst } from "helper/constFirebase";

function SecuritiesStatmentFilter() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);

  const { subAccoNo, fromDate, toDate } = useSelector(
    filterSecurityStatementSelector
  );

  const listAcc = GetAccoList(
    // GetAccountType.NONE_DERT_SUB_ACCOUNT,
    undefined,
    EntrustBusinessCode.Inquiry
  );

  const parseOptionAccount = useMemo(() => {
    let list = [];
    list = listAcc.map((account: any) => ({
      label: account?.subAccoNo!,
      value: account?.subAccoNo!,
    }));
    return list;
  }, [listAcc]);

  const handleSearch = () => {
    if (moment(fromDate).isAfter(moment(toDate))) {
      toast(
        `${intl.formatMessage({
          id: "accountStatement.errorToDate",
        })}`,
        "error"
      );
    } else {
      const params = {
        filter: {
          fil_accountType: "0",
          fil_subAccoNo: subAccoNo?.value
            ? subAccoNo?.value
            : parseOptionAccount[0]?.value,
          fil_fromDate: moment(fromDate).format("YYYYMMDD"),
          fil_toDate: moment(toDate).format("YYYYMMDD"),
          p_BEGIN_AMT: 0,
          p_LAST_AMT: 0,
        },
      };

      dispatch(searchListSecurityStatementRequest(params));

      const resLogData = {
        eventGroup: GroupEvent.satementMoney,
        event: RealtimeConst.getDataSatementStock,
        message: JSON.stringify(params),
      };

      storage.commonPushLogFirebase(resLogData);
    }
  };
  return (
    <WrapBoder className="d-flex justify-content-between align-items-center">
      <FlexContainer className="d-flex">
        <FilterItem
          filterName={intl.formatMessage({
            id: "accountStatement.account",
          })}
          className="mr-4"
        >
          <CSelect
            defaultValue={parseOptionAccount[0]}
            options={parseOptionAccount}
            onChange={(e) => {
              dispatch(
                updateSecurityStatementFilter({
                  key: "subAccoNo",
                  value: {
                    label: e?.label,
                    value: e?.value ? e.value : "",
                  },
                })
              );
            }}
            value={subAccoNo?.value !== "" ? subAccoNo : parseOptionAccount[0]}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "accountStatement.fromDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={fromDate}
            onChange={(e: any) => {
              dispatch(
                updateSecurityStatementFilter({
                  key: "fromDate",
                  value: e,
                })
              );
            }}
            popperPlacement={"bottom-start"}
          />
        </FilterItem>

        <FilterItem
          filterName={intl.formatMessage({
            id: "accountStatement.toDate",
          })}
          className="mr-4"
        >
          <DatePicker
            selected={toDate}
            onChange={(e: any) => {
              dispatch(
                updateSecurityStatementFilter({
                  key: "toDate",
                  value: e,
                })
              );
            }}
            popperPlacement="bottom-start"
          />
        </FilterItem>

        <Button
          color="accept"
          className="button-filter mr-4"
          loading={loading}
          onClick={handleSearch}
        >
          {intl.formatMessage({
            id: "accountStatement.search",
          })}
        </Button>
      </FlexContainer>
    </WrapBoder>
  );
}

export default memo(SecuritiesStatmentFilter);
